import ServerAction from './ServerAction';

module.exports = ServerAction.extend({
  isApplicable: function (objs) {
    if (!Array.isArray(objs) || objs.length <= 0) {
      return false;
    }

    return objs.every(color => color.type === 'controlpanel/color' && (color.names || []).every(name => !name.default));
  }
});
