import {localization} from 'sandbox';

const {translate} = localization;

export const  DATE_TIME_FORMAT = 'MM/dd hh:mma';
export const  DATE_FORMAT = 'MM/dd';

export const  DEFAULT_STATUS_COLOR = '#AAAAAA';
export const  STATUS_MAP = {
  changed: {color: '#00BEFF', displayName: 'Ad Changed'},
  Waiting: {color: '#FFFF00', displayName: 'Waiting'},
  Error: {color: '#FF0000', displayName: 'Error'},
  Success: {color: '#73DF73', displayName: 'Sent'},
  planned: {color: '#DEFFFF', displayName: 'Planned'},
  readyForMail: {color: '#FFFFFF', displayName: 'Ready For Mail'},
  masked: {color: '#000000', displayName: 'Masked'},
  pickup: {color: '#D66918', displayName: 'Pickup'},
};

export const  XMIT_STATUS_MAP = {  
  waiting: {color: '#FFFF00', displayName: 'Waiting'},
  error: {color: '#FF0000', displayName: 'Error'},
  finished: {color: '#73DF73', displayName: 'Sent'},
  processing: {color: '#cfdf73', displayName: 'Process'}
  
};

export const  ICON_NAME_BY_STATUS = {
  Waiting: 'wait',
  Error: 'error',
  Success: 'finished',
};

export const ALL = 'ALL';
export const NONE = 'NONE';

export const FILTER_COMMON_OPTIONS = [
  {value: ALL, text: translate('All')},
  {value: NONE, text: translate('None')},
];

export const FILTER_BY = [
  {key: 'Coordinator', caption: translate('Coordinators:')},
  {key: 'SoldNetworkIssue', caption: translate('Networks:'), separator: ','},
  {key: 'magazines', caption: translate('Magazines:'), childKey: 'displayName'},
];
