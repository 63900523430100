import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';
import TextInput from 'components/common/inputs/TextInput';
import TextareaInput from 'components/common/inputs/TextareaInput';
import Button from 'components/common/buttons/Button';
import settingsManager from 'core/managers/settings';
import localization from 'sandbox/localization';
import AdaptiveHeightContainer from "./AdaptiveHeightContainer";
import {fromServerDate} from 'core/dates';

const isArray = o => Array.isArray(o);
const isString = str => typeof str === 'string';
const translate = sandbox.localization.translate;
const ROWS = 2;
const MIN_ROWS = 2;
const MAX_ROWS = 5;
const TEXT_AREA_LINE_HEIGHT = 18;
const MAX_TEXTAREA_LENGTH = settingsManager.get('chatMsgLengthLimit');


const labels = {
  enterYourMessage: translate('Enter your message'),
  send: translate('Send'),
  you: translate('You')
};

const MATERIAL_ICONS = {
  DONE: 'done',
  DONE_ALL: 'done_all',
};

const CONTACT_TYPES = {
  user: 'controlpanel/profiles/users/user',
  group: 'controlpanel/profiles/groups/group'
};

const CHANNEL_TYPES = {
  custom: 'chat/channel/custom',
  oneToOne: 'chat/channel/one_to_one',
  oneToGroup: 'chat/channel/group'
};

const CHANNEL_TYPES_TO_REST = {
  oneToOne: 'O',
  oneToGroup: 'G',
  custom: 'C'
};

export default class ChatContent extends Component {
  static propTypes = {
    store: PropTypes.object,
    handleSendMessageButtonClick: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      rows: ROWS,
      minRows: MIN_ROWS,
      maxRows: MAX_ROWS,
    };
  }

  renderHeader = () => {
    const { store } = this.props;
    const { channelsByNwid, allChannelsNwids, selectedId, selectedContact, isChatsView, newChannel, usersByNwid, groupsByNwid } = store.getState();

    const renderTemporaryHeader = (userNames, groups, channel, currentUser) => {
      if (channel.channelType && (channel.channelType === CHANNEL_TYPES_TO_REST.oneToOne || channel.channelType === CHANNEL_TYPES_TO_REST.oneToGroup)) {

        return <div className='crtx-chat-channel-content-header'>
          <div className='crtx-chat-channel-content-subject'>{channel.subject}</div>
        </div>;
      } else {
     
      let groupsList = [];

      if (isArray(groups)) {
        groupsList = groups.map(group => group.groupFullName);
      }

      let userNamesWithoutCurrentUser = userNames.slice(0);
      let index = userNamesWithoutCurrentUser.indexOf(currentUser);
      if (index > -1) {
        userNamesWithoutCurrentUser.splice(index, 1);
      }

      const participants = userNamesWithoutCurrentUser.sort().join(', ') + (groupsList.length > 0 && userNamesWithoutCurrentUser.length > 0 ? ', ' + groupsList.sort().join(', ') :
        (groupsList.length > 0 && userNamesWithoutCurrentUser.length === 0) ? groupsList.sort().join(', ') : '');

      const usersContainer = <div className='crtx-chat-channel-content-details'><div className='crtx-chat-channel-content-users'>{participants + ', ' + labels.you}</div></div>;
      return <div className='crtx-chat-channel-content-header'>
      <div className='crtx-chat-channel-content-subject'>{channel.subject}</div>
      <AdaptiveHeightContainer children={usersContainer} maxRows={5} containerPadding={0} rowHeight={20} />
    </div>;
      }

    }
    const currentUser = settingsManager.settings.user.name;

    if(newChannel) {
     let userNames = newChannel.recipients.map(recipientNwid => usersByNwid[recipientNwid] ? usersByNwid[recipientNwid].userName : '' );
     let groups = newChannel.recipients.map(recipientNwid => groupsByNwid[recipientNwid] ? groupsByNwid[recipientNwid] : undefined );
     return renderTemporaryHeader(userNames.filter(name => !!name ), groups.filter(group => !!group ), newChannel, currentUser);
    }

    if (!selectedId && !selectedContact) return;
    

    if (selectedId) {

      if (!channelsByNwid[selectedId]) return;

      const channel = channelsByNwid[selectedId];

      if (channel.type === CHANNEL_TYPES.oneToOne || channel.type === CHANNEL_TYPES.oneToGroup) {

        return <div className='crtx-chat-channel-content-header'>
          <div className='crtx-chat-channel-content-subject'>{channel.subject}</div>
        </div>;

      } else {

        const { userNames, groups } = channel;        

        return renderTemporaryHeader(userNames, groups, channel, currentUser);
      }

    } else if (selectedContact && isChatsView) {

      let subject = undefined;
      let channelNwid = undefined;

      if (allChannelsNwids.length > 0) {

        if (selectedContact.groupFullName) {
          channelNwid = allChannelsNwids.find(channelNwid => channelsByNwid[channelNwid].userNames.length === 0 &&
            channelsByNwid[channelNwid].groups.find((group) => group.groupFullName === selectedContact.groupFullName) &&
            channelsByNwid[channelNwid].type === CHANNEL_TYPES.oneToGroup);

        } else {
          channelNwid = allChannelsNwids.find(channelNwid => channelsByNwid[channelNwid].groups.length === 0 &&
            channelsByNwid[channelNwid].userNames.find((name) => name === selectedContact.userName) &&
            channelsByNwid[channelNwid].type === CHANNEL_TYPES.oneToOne);

        }
        subject = channelNwid ? channelsByNwid[channelNwid].subject : (selectedContact.userName ? selectedContact.userName : selectedContact.groupFullName);

      }
      else {
        subject = selectedContact.type === CONTACT_TYPES.user ? selectedContact.userName : selectedContact.groupFullName;
      }

      return <div className='crtx-chat-channel-content-header'>
        <div className='crtx-chat-channel-content-subject'>{subject}</div>
      </div>;
    }

    return;

  };

  handleSendClick = (e, newValue) => {
    const { handleSendMessageButtonClick } = this.props;

    handleSendMessageButtonClick(newValue);
  };

  setTextareaHeight = () => {
    const { minRows, maxRows } = this.state;
    const previousRows = event.target.rows;

    event.target.rows = minRows;

    const currentRows = ~~(event.target.scrollHeight / TEXT_AREA_LINE_HEIGHT) + 1;

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }
    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }
    this.setState({
      rows: currentRows < maxRows ? currentRows : maxRows,
    });
  };

  handleChangeNewMessageValue = (event, newValue) => {
    const { store } = this.props;

    store.dispatch({
      type: 'UPDATE_INPUT_VALUE',
      newMessage: newValue
    });

    this.setTextareaHeight();
  };

  handleChangeChannelDraftMessageValue = (channel) => (event, draftMessage) => {
    const { store } = this.props;

    store.dispatch({
      type: 'UPDATE_INPUT_VALUE',
      draftMessage: draftMessage,
      channel: channel
    });

    store.dispatch({
      type: 'UPDATE_CHANNEL_WITH_DRAFT_MESSAGE',
      draftMessage: draftMessage,
      channel: channel
    });

    this.setTextareaHeight();
  };

  handleKeyPress = (e, newValue) => {
    const { store } = this.props;
    const { sendAvailability } = store.getState();

    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!newValue) return;
      const { store } = this.props;
      if (sendAvailability) {
        store.dispatch({
          type: 'UPDATE_SEND_AVAILABILITY',
          sendAvailability: false
        });
        this.handleSendClick(e, newValue);
      } else return;

    }
  };

  handleKeyUp = (e) => {
    if (e.ctrlKey) {
      if (e.keyCode == 65 || e.keyCode == 97) {
        e.preventDefault();
        e.target.select();
      }
    }
  };

  renderAddNewMessageContainer = () => {
    const { store } = this.props;
    const { selectedId, channelsByNwid, selectedContact, newMessage, draftsByChannelNwid, newChannel } = store.getState();

    if(newChannel) {
      const disabled = !newMessage;
      const disabledClassName = !newMessage ? 'disabled' : '';

      return <div className='chat-new-message-container'>

        <TextareaInput className='chat-new-message-input' ref={el => this.textareaRef = el}
          value={newMessage}
          placeholder={labels.enterYourMessage}
          onChange={this.handleChangeNewMessageValue}
          onKeyPress={(e) => this.handleKeyPress(e, newMessage)}
          onKeyUp={(e) => this.handleKeyUp(e)}
          rows={this.state.rows}
          autoFocus={true}
          maxLength={MAX_TEXTAREA_LENGTH}
        />
        <Button disabled={disabled} className={`chat-new-message-container-button ${disabledClassName}`} onClick={(e) => this.handleSendClick(e, newMessage)} >
          {labels.send}
        </Button>
      </div>;
     }

    if (!selectedId && !selectedContact) return;
    if (selectedId && !channelsByNwid[selectedId]) return;
    

    const channel = selectedId ? channelsByNwid[selectedId] : undefined;
    if (!channel) {
      const disabled = !newMessage;
      const disabledClassName = !newMessage ? 'disabled' : '';

      return <div className='chat-new-message-container'>

        <TextareaInput className='chat-new-message-input' ref={el => this.textareaRef = el}
          value={newMessage}
          placeholder={labels.enterYourMessage}
          onChange={this.handleChangeNewMessageValue}
          onKeyPress={(e) => this.handleKeyPress(e, newMessage)}
          onKeyUp={(e) => this.handleKeyUp(e)}
          rows={this.state.rows}
          autoFocus={true}
          maxLength={MAX_TEXTAREA_LENGTH}
        />
        <Button disabled={disabled} className={`chat-new-message-container-button ${disabledClassName}`} onClick={(e) => this.handleSendClick(e, newMessage)} >
          {labels.send}
        </Button>
      </div>;
    } else {
      const disabled = !draftsByChannelNwid[channel.nwid] || draftsByChannelNwid[channel.nwid] === '';
      const disabledClassName = !(draftsByChannelNwid[channel.nwid]) || draftsByChannelNwid[channel.nwid] === '' ? 'disabled' : '';
      const draftMessage = draftsByChannelNwid[channel.nwid] ? draftsByChannelNwid[channel.nwid] : '';

      return <div className='chat-new-message-container'>

        <TextareaInput className='chat-new-message-input' ref={el => this.textareaRef = el}
          value={draftMessage}
          placeholder={labels.enterYourMessage}
          onChange={this.handleChangeChannelDraftMessageValue(channel)}
          onKeyPress={(e) => this.handleKeyPress(e, draftMessage)}
          onKeyUp={(e) => this.handleKeyUp(e)}
          rows={this.state.rows}
          autoFocus={true}
          maxLength={MAX_TEXTAREA_LENGTH}
        />
        <Button disabled={disabled} className={`chat-new-message-container-button ${disabledClassName}`} onClick={(e) => this.handleSendClick(e, draftMessage)} >
          {labels.send}
        </Button>
      </div>;
    }

  };

  renderMessage = (message, index) => {
    let className = 'chat-channel-text-row';    
    const renderUserName = settingsManager.settings.user.name === message.from ? '' : <span className='chat-message-user-name'>{message.from}</span>;
    const messageSentDateTime = isString(message.sentDateTime) ? fromServerDate(message.sentDateTime) : message.sentDateTime;
    const sentDateTime = (messageSentDateTime.getFullYear() !== (new Date).getFullYear() || messageSentDateTime.getMonth() !== (new Date).getMonth() || messageSentDateTime.getDay() !== (new Date).getDay()) ?
      localization.toLocaleShortDate(messageSentDateTime) : localization.toLocaleShortTime(messageSentDateTime);

    className = settingsManager.settings.user.name === message.from ? className.concat(' right') : className;
    const readIsVisible = settingsManager.settings.user.name === message.from;

    return <div className={className} key={index}>
      <div className='chat-message-details'>
        <div>
          {renderUserName}
          <span className='chat-message-date-time'>{sentDateTime}</span>
        </div>

        {readIsVisible ? this.renderIfRead(message) : null}
      </div>
      <div className='chat-message-container'>
        <div className='chat-message'>{message.content}
        </div>

      </div>
    </div>
  }

  renderIfRead = (message) => {

    const indicatorClassName = (message.isReceivedMessage && message.isReadMessage) ? 'read' : '';
    const icon = !message.isReceivedMessage ? MATERIAL_ICONS.DONE : MATERIAL_ICONS.DONE_ALL;

    return <div className={`chat-message-received-indication ${indicatorClassName}`}>
      <i className='material-icons'>{icon}</i>
    </div>;
  }

  renderChannelMessagesContainer = () => {
    const { store } = this.props;
    const { chatContent, selectedId, selectedContact, newChannel, newMessagesByNwid } = store.getState();

    if(newChannel) {
      return <div className='crtx-chat-channel-messages-container' id='chat-channel-messages-container'>
        {chatContent.length > 0 && chatContent.map(this.renderMessage)}
      </div>;
    }

    if (!selectedId && !selectedContact) return;

    let newMessagesByNwidArr = Object.keys(newMessagesByNwid).map(key => newMessagesByNwid[key]);

    return <div className='crtx-chat-channel-messages-container' id='chat-channel-messages-container'>
      {chatContent.length > 0 && chatContent.map(this.renderMessage)}
      {newMessagesByNwid && newMessagesByNwidArr.map(this.renderMessage)}
    </div>;
  }

  scrollToTheEndOfContent() {
    const objDiv = document.getElementsByClassName('crtx-chat-channel-messages-container')[0];
    if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;

  }

  focusTextarea() {
    const objDiv = document.getElementsByClassName('chat-new-message-input')[0];
    if (objDiv) objDiv.focus();
  }

  componentDidUpdate() {
    this.scrollToTheEndOfContent();
  }

  focus() {
    if (this.textareaRef) {
      this.textareaRef.focus();
    }
  }

  render() {

    return <div className='crtx-chat-channel-content-grid'>
      <div className='crtx-chat-channel-grid-container'>
        {this.renderHeader()}
        {this.renderChannelMessagesContainer()}
        {this.renderAddNewMessageContainer()}
      </div>
    </div>;
  }

}
