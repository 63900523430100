define(['jquery'], function ($) {
    'use strict';



    function mapSourceIdToObject(model) {
        var sourceIdMap = {};

        (function mapProps(currentElement, map) {
            if (Array.isArray(currentElement)) {
                currentElement.forEach(function (item) {
                    mapProps(item, map);
                });
            } else if (typeof currentElement == "object") {
                Object.keys(currentElement).forEach(function (key) {
                    if (key === "sourceId") {
                        map[currentElement[key]] = currentElement;
                    } else {
                        var prop = currentElement[key];
                        if (typeof prop == "object" && prop != null) {
                            mapProps(prop, map);
                        }
                    }
                });
            }
        }(model, sourceIdMap));

        return sourceIdMap;

    }

    return {
        //createSectionModel: createSectionModel,
        //convertZonedPagesToArray: convertZonedPagesToArray,
        mapSourceIdToObject: mapSourceIdToObject
    };

})
;
