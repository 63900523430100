/**
 * @name inkArea
 * @file Defines Ink Area element functionality that is used for ink calculations
 *
 * @author Boris
 * @since: 2016-06-23
 */

import React from 'react';
import sandbox from 'sandbox';
import utils from '../utils/utils';
import SimpleForm from 'widgets/SimpleForm/src/index';
import Fabric from 'fabric';
import canvasUtils from '../utils/canvasUtils';
import propertiesCommon from './propertiesCommon';

const { translate } = sandbox.localization;
const labels = {
  flat: translate("Flat"),
  striped: translate("Striped"),
  tiled: translate("Tiled"),
  msg: translate("Multiples of 16 only")
};

const futils = SimpleForm.utils;

const BASE_PATH = 'layout.inkAreas';
const TITLE = translate('Ink Area');

const MASK_FILL_COLOR = 'green';
const MASK_STROKE = 'black';
const MASK_OPACITY = 0.8;
const MARGIN_FILL_COLOR = '';
const MARGIN_STROKE = 'blue';
const ZONE_STROKE = 'black';

const EPS = 0.001;
const MARGIN = 0.5;

const { Row, Label, MeasurementInput, PercentageInput, Checkbox } = SimpleForm;

const { CAPTION_COLUMNS, NUMBER_COLUMNS, CHECKBOX_COLUMNS, UNITS_COLUMNS, UNITS_MARGIN_LEFT, NAME } = propertiesCommon;

const IMAGE_MASK_X = {
  key: 'x',
  caption: translate('Image mask X'),
  type: 'length'
};

const IMAGE_MASK_Y = {
  key: 'y',
  caption: translate('Image mask Y'),
  type: 'length'
};

const IMAGE_MASK_WIDTH = {
  key: 'width',
  caption: translate('Image mask width'),
  type: 'length',
  defaultValue: 2
};

const IMAGE_MASK_HEIGHT = {
  key: 'height',
  caption: translate('Image mask height'),
  type: 'length',
  defaultValue: 1
};

const MARGIN_LEFT = {
  key: 'marginLeft',
  caption: translate('Margin left'),
  type: 'length'
};

const MARGIN_RIGHT = {
  key: 'marginRight',
  caption: translate('Margin right'),
  type: 'length'
};

const MARGIN_TOP = {
  key: 'marginTop',
  caption: translate('Margin top'),
  type: 'length'
};

const MARGIN_BOTTOM = {
  key: 'marginBottom',
  caption: translate('Margin bottom'),
  type: 'length'
};

const ORIENTATION = {
  key: 'orientation',
  caption: translate('Orientation'),
  type: 'orientation'
};

// const OUTPUT_MODE = {
//   key: '_outputMode',
//   caption: translate('Output mode'),
//   type: 'options',
//   options: [
//     {value: 'filePerSeparation', text: translate('File per separation')},
//     {value: 'fileWithAllSeparations', text: translate('File with all separations')}
//   ],
//   disabled: true
// };

const ZONE_COUNT = {
  key: 'zoneCount',
  caption: translate('Number of zones'),
  type: 'int',
  mask: 'posInt',
  defaultValue: 8
};

const UNEQUAL_ZONE_WIDTHS = {
  key: 'unequalZoneWidth',
  caption: translate('Unequal zone width'),
  type: 'bool'
};

const ZONE_WIDTHS = {
  key: 'zoneWidths',
  caption: translate('Zone widths'),
  type: 'array'
};

const ZONE_WIDTH = {
  key: 'zoneWidth',
  caption: translate('Zone width'),
  type: 'length'
};

const CORRECTION_ENABLED = {
  key: 'correctionEnabled',
  caption: translate('Ink curve'),
  type: 'bool'
};

const CORRECTION_FACTORS = {
  key: 'correctionFactors',
  caption: translate('Corrections'),
  type: 'array'
};

const CORRECTION_FACTOR = {
  key: 'correctionFactor',
  caption: translate('Correction factor'),
  type: 'percent'
};

const CORRECTION_ENABLED_SEP = [
  {
    key: 'correctionSepEnabledblack',
    caption: translate('Ink curve Black'),
    type: 'bool',
    correctionFactors: {
      key: 'correctionFactorsblack',
      caption: translate('Corrections Black'),
      type: 'array'
    }
  },
  {
    key: 'correctionSepEnabledcyan',
    caption: translate('Ink curve Cyan'),
    type: 'bool',
    correctionFactors: {
      key: 'correctionFactorscyan',
      caption: translate('Corrections Cyan'),
      type: 'array'
    }
  },
  {
    key: 'correctionSepEnabledmagenta',
    caption: translate('Ink curve Magenta'),
    type: 'bool',
    correctionFactors: {
      key: 'correctionFactorsmagenta',
      caption: translate('Corrections Magenta'),
      type: 'array'
    }
  },

  {
    key: 'correctionSepEnabledyellow',
    caption: translate('Ink curve Yellow'),
    type: 'bool',
    correctionFactors: {
      key: 'correctionFactorsyellow',
      caption: translate('Corrections Yellow'),
      type: 'array'
    }
  }
];

const CIP3_TEMPLATE_FILE = {
  key: 'cip3TemplateFile',
  caption: translate('CIP3 Template file'),
  type: 'string'
};

const CIP3_PREVIEW_IMAGE_ENCODING = {
  key: 'cip3PreviewImageEncoding',
  caption: translate('CIP3 Preview image encoding'),
  type: 'options',
  options: [
    { value: 'Binary', text: translate('Binary') },
    { value: 'ASCIIHexDecode', text: translate('ASCII Hex Decode') }
  ]
};

const CIP3_PREVIEW_IMAGE_COMPRESSION = {
  key: 'cip3PreviewImageCompression',
  caption: translate('CIP3 Preview image compression'),
  type: 'options',
  options: [
    { value: 'None', text: translate('None') },
    { value: 'RunLengthDecode', text: translate('Run Length Decode') }
  ]
};

const CIP3_MONO_TO_GRAY = {
  key: 'cip3MonoToGray',
  caption: translate('CIP3 Mono to gray'),
  type: 'bool'
};

const CIP3_MONO_TO_GRAY_DPI = {
  key: 'cip3MonoToGrayDpi',
  caption: translate('CIP3 Mono to gray DPI'),
  type: 'float',
  suffix: 'dpi',
  defaultValue: 72
};

const CIP3_DEBUG_HEADERS = {
  key: 'cip3DebugHeaders',
  caption: translate('CIP3 Debug headers'),
  type: 'bool'
};

const CIP3_ADM_JOB_NAME = {
  key: 'cip3AdmJobName',
  caption: translate('CIP3 Adm job name'),
  type: 'string'
};

const CIP3_ADM_MAKE = {
  key: 'cip3AdmMake',
  caption: translate('CIP3 Adm make'),
  type: 'string'
};

const CIP3_ADM_Model = {
  key: 'cip3AdmModel',
  caption: translate('CIP3 Adm model'),
  type: 'string'
};

const CIP3_ADM_SHEET_NAME = {
  key: 'cip3AdmSheetName',
  caption: translate('CIP3 Adm sheet name'),
  type: 'string'
};

const CIP3_ADM_SHEET_CODE = {
  key: 'cip3AdmSheetCode',
  caption: translate('CIP3 Adm sheet code'),
  type: 'string'
};


const CIP3_ADM_SEPARATION_NAMES = {
  key: 'cip3AdmSeparationNames',
  caption: translate('CIP3 Adm separation names'),
  type: 'string'
};
const CIP3_ADM_PLATE_EXTENT = {
  key: 'cip3AdmPlateExtent',
  caption: translate('CIP3 Adm plate extent'),
  type: 'string'
};

const CIP3_ADM_PLATE_TRF = {
  key: 'cip3AdmPlateTrf',
  caption: translate('CIP3 Adm plate transform'),
  type: 'string'
};

const CIP3_ADM_PAPER_TRF = {
  key: 'cip3AdmPaperTrf',
  caption: translate('CIP3 Adm paper transform'),
  type: 'string'
};

const CIP3_TRANSFER_FILM_CURVE_DATA = {
  key: 'cip3TransferFilmCurveData',
  caption: translate('CIP3 Film curve'),
  type: 'string'
};

const CIP3_TRANSFER_PLATE_CURVE_DATA = {
  key: 'cip3TransferPlateCurveData',
  caption: translate('CIP3 Plate curve'),
  type: 'string'
};

const CIP3_UNITS = {
  key: 'cip3Units',
  caption: translate('CIP3 Units'),
  type: 'options',
  options: [
    { value: 'inch', text: translate('inch') },
    { value: 'mm', text: translate('mm') },
    { value: 'point', text: translate('point') },
  ]
};

const GRAYSCALE_RESOLUTION = {
  key: 'grayscaleResolution',
  caption: translate('GrayScale Resolution'),
  type: 'float',
  suffix: 'dpi',
  defaultValue: 72
};

const ONE_BIT_TIFF_PREVIEW_IMAGE_COMPRESSION = {
  key: 'oneBitTiffPreviewImageCompression',
  caption: translate('Compression Mode'),
  type: 'options',
  options: [
    { value: 'G4', text: translate('G4') },
    { value: 'NONE', text: translate('None') }
  ]
};

const ONE_BIT_TIFF_FORMAT = {
  key: 'oneBitTiffFormat',
  caption: translate('TIFF Format'),
  type: 'options',
  options: [
    { value: 'flat', text: translate('Flat') },
    { value: 'striped', text: translate('Striped') },
    { value: 'tiled', text: translate('Tiled') }
  ],
};

const ONE_BIT_TIFF_STRIP_ROW = {
  key: 'oneBitTiffStripRow',
  hidden: true,
  caption: translate('Rows Per Strip'),
  type: 'float',
  tooltip: labels.msg,
  defaultValue: 2000
};

const ONE_BIT_TIFF_TILED_WIDTH = {
  key: 'oneBitTiffTiledWidth',
  hidden: true,
  caption: translate('Tile Width'),
  type: 'float',
  tooltip: labels.msg,
  defaultValue: 2000
};

const ONE_BIT_TIFF_TILED_HEIGHT = {
  key: 'oneBitTiffTiledHeight',
  hidden: true,
  caption: translate('Tile Height'),
  type: 'float',
  tooltip: labels.msg,
  defaultValue: 2000
};

const ONE_BIT_TIFF_DOCUMENT_NAME = {
  key: 'oneBitTiffDocumentName',
  caption: translate('Document Name'),
  type: 'string'
};

const ONE_BIT_TIFF_IMAGE_DESCRIPTION = {
  key: 'oneBitTiffImageDescription',
  caption: translate('Image Description'),
  type: 'string'
};

const ONE_BIT_TIFF_PAGE_NAME = {
  key: 'oneBitTiffPageName',
  caption: translate('Page Name'),
  type: 'string'
};

const ONE_BIT_TIFF_PAGE_NUMBER = {
  key: 'oneBitTiffPageNumber',
  caption: translate('Page Number'),
  type: 'string'
};

const ONE_BIT_TIFF_SEPARATION_CODE = {
  key: 'oneBitTiffSeparationCode',
  caption: translate('Separation Code'),
  type: 'string'
};

const ONE_BIT_TIFF_KEEP_ORIGINAL_DPI = {
  key: 'oneBitTiffKeepOriginalDpi',
  caption: translate('Keep Original TIFF DPI'),
  type: 'bool',
  defaultValue: true
};

const ONE_BIT_TIFF_DPI = {
  key: 'oneBitTiffDpi',
  hidden: true,
  caption: translate('TIFF DPI'),
  type: 'int',
  mask: 'posInt',
  defaultValue: 200
};

const CMYK_TIFF_PREVIEW_IMAGE_COMPRESSION = {
  key: 'cmykTiffPreviewImageCompression',
  caption: translate('Compression Mode'),
  type: 'options',
  options: [
    { value: 'LZW', text: translate('LZW') },
    { value: 'NONE', text: translate('None') }
  ]
};

const CMYK_TIFF_FORMAT = {
  key: 'cmykTiffFormat',
  caption: translate('TIFF Format'),
  type: 'options',
  options: [
    { value: 'flat', text: translate('Flat') },
    { value: 'striped', text: translate('Striped') },
    { value: 'tiled', text: translate('Tiled') }
  ],
};

const CMYK_TIFF_STRIP_ROW = {
  key: 'cmykTiffStripRow',
  hidden: true,
  caption: translate('Rows Per Strip'),
  type: 'float',
  tooltip: labels.msg,
  defaultValue: 2000
};

const CMYK_TIFF_TILED_WIDTH = {
  key: 'cmykTiffTiledWidth',
  hidden: true,
  caption: translate('Tile Width'),
  type: 'float',
  tooltip: labels.msg,
  defaultValue: 2000
};

const CMYK_TIFF_TILED_HEIGHT = {
  key: 'cmykTiffTiledHeight',
  hidden: true,
  caption: translate('Tile Height'),
  type: 'float',
  tooltip: labels.msg,
  defaultValue: 2000
};

const CMYK_TIFF_DOCUMENT_NAME = {
  key: 'cmykTiffDocumentName',
  caption: translate('Document Name'),
  type: 'string'
};

const CMYK_TIFF_IMAGE_DESCRIPTION = {
  key: 'cmykTiffImageDescription',
  caption: translate('Image Description'),
  type: 'string'
};

const CMYK_TIFF_PAGE_NAME = {
  key: 'cmykTiffPageName',
  caption: translate('Page Name'),
  type: 'string'
};

const CMYK_TIFF_PAGE_NUMBER = {
  key: 'cmykTiffPageNumber',
  caption: translate('Page Number'),
  type: 'string'
};

const CIP3_MODEL_META = [
  NAME,
  IMAGE_MASK_X,
  IMAGE_MASK_Y,
  IMAGE_MASK_WIDTH,
  IMAGE_MASK_HEIGHT,
  MARGIN_LEFT,
  MARGIN_RIGHT,
  MARGIN_TOP,
  MARGIN_BOTTOM,
  ORIENTATION,
  //OUTPUT_MODE,
  CIP3_TEMPLATE_FILE,
  CIP3_PREVIEW_IMAGE_ENCODING,
  CIP3_PREVIEW_IMAGE_COMPRESSION,
  CIP3_MONO_TO_GRAY,
  CIP3_MONO_TO_GRAY_DPI,
  CIP3_DEBUG_HEADERS,
  CIP3_ADM_JOB_NAME,
  CIP3_ADM_MAKE,
  CIP3_ADM_Model,
  CIP3_ADM_SHEET_NAME,
  CIP3_ADM_SHEET_CODE,
  CIP3_ADM_SEPARATION_NAMES,
  CIP3_ADM_PLATE_EXTENT,
  CIP3_ADM_PLATE_TRF,
  CIP3_ADM_PAPER_TRF,
  CIP3_TRANSFER_FILM_CURVE_DATA,
  CIP3_TRANSFER_PLATE_CURVE_DATA,
  CIP3_UNITS
];

const GRAYSCALE_MODEL_META = [
  NAME,
  IMAGE_MASK_X,
  IMAGE_MASK_Y,
  IMAGE_MASK_WIDTH,
  IMAGE_MASK_HEIGHT,
  MARGIN_LEFT,
  MARGIN_RIGHT,
  MARGIN_TOP,
  MARGIN_BOTTOM,
  ORIENTATION,
  GRAYSCALE_RESOLUTION,
];

const ONE_BIT_TIFF_MODEL_META = [
  NAME,
  IMAGE_MASK_X,
  IMAGE_MASK_Y,
  IMAGE_MASK_WIDTH,
  IMAGE_MASK_HEIGHT,
  MARGIN_LEFT,
  MARGIN_RIGHT,
  MARGIN_TOP,
  MARGIN_BOTTOM,
  ORIENTATION,
  ONE_BIT_TIFF_PREVIEW_IMAGE_COMPRESSION,
  ONE_BIT_TIFF_FORMAT,
  ONE_BIT_TIFF_STRIP_ROW,
  ONE_BIT_TIFF_TILED_WIDTH,
  ONE_BIT_TIFF_TILED_HEIGHT,
  ONE_BIT_TIFF_DOCUMENT_NAME,
  ONE_BIT_TIFF_IMAGE_DESCRIPTION,
  ONE_BIT_TIFF_PAGE_NAME,
  ONE_BIT_TIFF_PAGE_NUMBER,
  ONE_BIT_TIFF_SEPARATION_CODE,
  ONE_BIT_TIFF_KEEP_ORIGINAL_DPI,
  ONE_BIT_TIFF_DPI
];

const CMYK_TIFF_MODEL_META = [
  NAME,
  IMAGE_MASK_X,
  IMAGE_MASK_Y,
  IMAGE_MASK_WIDTH,
  IMAGE_MASK_HEIGHT,
  MARGIN_LEFT,
  MARGIN_RIGHT,
  MARGIN_TOP,
  MARGIN_BOTTOM,
  ORIENTATION,
  CMYK_TIFF_PREVIEW_IMAGE_COMPRESSION,
  CMYK_TIFF_FORMAT,
  CMYK_TIFF_STRIP_ROW,
  CMYK_TIFF_TILED_WIDTH,
  CMYK_TIFF_TILED_HEIGHT,
  CMYK_TIFF_DOCUMENT_NAME,
  CMYK_TIFF_IMAGE_DESCRIPTION,
  CMYK_TIFF_PAGE_NAME,
  CMYK_TIFF_PAGE_NUMBER,
  GRAYSCALE_RESOLUTION
];

const ZONES_MODEL_META1 = [
  NAME,
  IMAGE_MASK_X,
  IMAGE_MASK_Y,
  IMAGE_MASK_WIDTH,
  IMAGE_MASK_HEIGHT,
  MARGIN_LEFT,
  MARGIN_RIGHT,
  MARGIN_TOP,
  MARGIN_BOTTOM,
  ZONE_COUNT
];

const ZONES_MODEL_META2 = [

  UNEQUAL_ZONE_WIDTHS,
  ZONE_WIDTHS,
  CORRECTION_ENABLED,
  CORRECTION_FACTORS

];

const ZONES_MODEL_META = ZONES_MODEL_META1.concat(ZONES_MODEL_META2);

export default (editor) => {

  const canvas = editor.getMainCanvas();
  const cutils = canvasUtils(editor);

  const isCip3 = (element) => {
    return element._inkModel === 'cip3';
  };

  const isGrayscale = (element) => {
    return element._inkModel === 'grayscale';
  };

  const isOneBitTiff = (element) => {
    return element._inkModel === 'onebittiff';
  };

  const isCmykTiff = (element) => {
    return element._inkModel === 'cmyktiff';
  };

  const isZonesModel = (element) => {
    return !element._inkModel || element._inkModel === 'zones';
  };

  const getMeta = (state, element) => {
    let meta;

    if (isCmykTiff(element)) {
      if (element.cmykTiffFormat === 'flat') {
        CMYK_TIFF_STRIP_ROW.hidden = true;
        CMYK_TIFF_TILED_WIDTH.hidden = true;
        CMYK_TIFF_TILED_HEIGHT.hidden = true;
      } else if (element.cmykTiffFormat === 'tiled') {
        CMYK_TIFF_STRIP_ROW.hidden = true;
        CMYK_TIFF_TILED_WIDTH.hidden = false;
        CMYK_TIFF_TILED_HEIGHT.hidden = false;
      } else if (element.cmykTiffFormat === 'striped') {
        CMYK_TIFF_STRIP_ROW.hidden = false;
        CMYK_TIFF_TILED_WIDTH.hidden = true;
        CMYK_TIFF_TILED_HEIGHT.hidden = true;
      }

      if (element.cmykTiffPreviewImageCompression === 'LZW') {
        CMYK_TIFF_FORMAT.hidden = false
      } else {
        CMYK_TIFF_FORMAT.hidden = true;
        CMYK_TIFF_STRIP_ROW.hidden = true;
        CMYK_TIFF_TILED_WIDTH.hidden = true;
        CMYK_TIFF_TILED_HEIGHT.hidden = true;
      }

      meta = CMYK_TIFF_MODEL_META;
    } else if (isOneBitTiff(element)) {
      if (element.oneBitTiffFormat === 'flat') {
        ONE_BIT_TIFF_STRIP_ROW.hidden = true;
        ONE_BIT_TIFF_TILED_WIDTH.hidden = true;
        ONE_BIT_TIFF_TILED_HEIGHT.hidden = true;
      } else if (element.oneBitTiffFormat === 'tiled') {
        ONE_BIT_TIFF_STRIP_ROW.hidden = true;
        ONE_BIT_TIFF_TILED_WIDTH.hidden = false;
        ONE_BIT_TIFF_TILED_HEIGHT.hidden = false;
      } else if (element.oneBitTiffFormat === 'striped') {
        ONE_BIT_TIFF_STRIP_ROW.hidden = false;
        ONE_BIT_TIFF_TILED_WIDTH.hidden = true;
        ONE_BIT_TIFF_TILED_HEIGHT.hidden = true;
      }

      if (element.oneBitTiffPreviewImageCompression === 'G4') {
        ONE_BIT_TIFF_FORMAT.hidden = false
      } else {
        ONE_BIT_TIFF_FORMAT.hidden = true;
        ONE_BIT_TIFF_STRIP_ROW.hidden = true;
        ONE_BIT_TIFF_TILED_WIDTH.hidden = true;
        ONE_BIT_TIFF_TILED_HEIGHT.hidden = true;
      }

      ONE_BIT_TIFF_DPI.hidden = element.oneBitTiffKeepOriginalDpi;

      meta = ONE_BIT_TIFF_MODEL_META;
    } else if (isGrayscale(element)) {
      meta = GRAYSCALE_MODEL_META;
    } else if (isCip3(element)) {
      CIP3_ADM_SEPARATION_NAMES.disabled = element._outputMode === 'fileWithAllSeparations';
      meta = CIP3_MODEL_META;
    } else {
      meta = ZONES_MODEL_META
    }

    return meta;
  };

  const renderInkModelOptionsProperties = (store, element, elementPath) => {
    const state = store.getState();

    return propertiesCommon.renderProperties(store, element, elementPath, getMeta(state, element));
  };

  const getInkAreaWidth = (element) => {
    return element.width + element.marginLeft + element.marginRight;
  };

  const getZoneCount = (element) => {
    return isValidZoneCount(element) ? element.zoneCount : 0;
  };

  const isValidZoneCount = (element) => {
    return element.zoneCount > 0 && element.zoneCount <= 128;
  };

  const calcZoneWidthsSum = (element) => {
    var sum = 0;
    var zoneCount = getZoneCount(element);
    for (var i = 0; i < zoneCount; i++) {
      sum += element.zoneWidths[i];
    }

    return sum;
  };

  const canZonesBeUnequal = (element) => {
    return isValidZoneCount(element) && element.zoneCount > 1;
  };

  const removeZoneWidths = (element) => {
    return { ...element, zoneWidths: utils.createPseudoArray(), unequalZoneWidth: false };
  };

  const equateZoneWidths = (element) => {
    var zoneWidths = utils.createPseudoArray();
    if (isValidZoneCount(element)) {
      var width = getInkAreaWidth(element);
      var zoneCount = element.zoneCount;
      var w = width / zoneCount;
      for (var i = 0; i < zoneCount; i++) {
        zoneWidths[i] = w;
      }
    }

    return { ...element, zoneWidths };
  };

  const adjustZoneWidths = (element, index) => {
    var newElement = element;
    var sum = calcZoneWidthsSum(element);
    var width = getInkAreaWidth(element);
    var delta = sum - width;
    if (Math.abs(delta) > EPS) {
      var zoneCount = getZoneCount(element);
      var zoneWidths = { ...element.zoneWidths };
      if (zoneCount <= 1) {
        zoneWidths[index] = width;
      } else {
        var wi = zoneWidths[index];
        var n = index + 1 < zoneCount ? index + 1 : index - 1;
        var wn = zoneWidths[n];
        if (wn - delta > 0) {
          zoneWidths[n] = wn - delta;
        } else {
          zoneWidths[n] = 0;
          zoneWidths[index] = wi - (delta - wn);
        }
      }

      newElement = { ...element, zoneWidths };
    }

    return newElement;
  };

  const resetCorrectionFactors = (element) => {
    var correctionFactors = utils.createPseudoArray();
    if (element.correctionEnabled) {
      for (var i = 0; i < 10; i++) {
        correctionFactors[i] = 1;
      }
    }

    return { ...element, correctionFactors };
  };

  const resetCorrectionFactorsSeps = (element, sep, sepFactors) => {
    var correctionFactors = utils.createPseudoArray();
    if (element[sep]) {
      for (var i = 0; i < 10; i++) {
        correctionFactors[i] = 1;
      }
    }

    return { ...element, [sepFactors]: correctionFactors };
  };

  const updateZoneWidths = (element, index) => {
    var newElement = element;
    if (!element.unequalZoneWidth || !canZonesBeUnequal(element)) {
      newElement = removeZoneWidths(element);
    } else {
      var sum = calcZoneWidthsSum(element);
      if (!isNaN(sum) && typeof index !== 'undefined') {
        newElement = adjustZoneWidths(element, index);
      } else if (isNaN(sum) || Math.abs(sum - getInkAreaWidth(element)) > EPS) {
        newElement = equateZoneWidths(element);
      }
    }

    return newElement;
  };

  const renderUnequalZoneWidthsRow = (element, path) => {
    var disabled = !canZonesBeUnequal(element);

    return (
      <Row>
        <Label col={CAPTION_COLUMNS}>{UNEQUAL_ZONE_WIDTHS.caption}</Label>
        <Checkbox col={CHECKBOX_COLUMNS} disabled={disabled} bind={futils.compose(path, UNEQUAL_ZONE_WIDTHS.key)} />
      </Row>
    );
  };

  const renderZoneWidthRow = (element, path, index) => {
    const units = utils.getUserUnits();
    const unitsSymbol = utils.getUserUnitsSymbol();

    const locked = element.locked;

    return (
      <Row key={index}>
        <Label col={CAPTION_COLUMNS}>{index}</Label>
        <MeasurementInput col={NUMBER_COLUMNS} units={units} disabled={locked} bind={path} />
        <Label col={UNITS_COLUMNS} style={{ marginLeft: UNITS_MARGIN_LEFT }}>{unitsSymbol}</Label>
      </Row>
    );
  };

  const renderCorrectionFactorsRow = (element, path, key, index) => {
    const locked = element.locked;
    const from = 10 * index;
    const caption = from + ' - ' + (from + 10);
    const rowInd = key + '_' + index;
    return (
      <Row key={rowInd}>
        <Label col={CAPTION_COLUMNS}>{caption}</Label>
        <PercentageInput col={NUMBER_COLUMNS} type="number" step="1" min="0" disabled={locked} bind={path} />
        <Label col={UNITS_COLUMNS} style={{ marginLeft: UNITS_MARGIN_LEFT }}>%</Label>
      </Row>
    );
  };

  const renderZoneWidths = (element, elementPath) => {
    if (!element || !element.unequalZoneWidth) {
      return null;
    }

    var zoneCount = getZoneCount(element);
    var rows = [];
    for (var i = 0; i < zoneCount; i++) {
      var path = futils.compose(elementPath, 'zoneWidths', i);
      rows.push(renderZoneWidthRow(element, path, i + 1));
    }

    return (
      <div>
        {rows}
      </div>
    );
  };

  const renderCorrectionFactors = (element, elementPath) => {
    if (!element || !element.correctionEnabled) {
      return null;
    }

    var rows = [];
    for (var i = 0; i < 10; i++) {
      var path = futils.compose(elementPath, 'correctionFactors', i);
      rows.push(renderCorrectionFactorsRow(element, path, 'correctionFactors', i));
    }

    return (
      <div>
        {rows}
      </div>
    );
  };

  const renderCorrectionFactorsSep = (element, sep, elementPath) => {
    if (!element || !element[sep.key]) {
      return null;
    }

    var rows = [];
    for (var i = 0; i < 10; i++) {
      const k = sep.correctionFactors.key;
      const path = futils.compose(elementPath, k, i);
      rows.push(renderCorrectionFactorsRow(element, path, k, i));
    }

    return (
      <div>
        {rows}
      </div>
    );
  };


  const renderZonesModelProperties1 = (store, element, elementPath) => {
    return propertiesCommon.renderProperties(store, element, elementPath, ZONES_MODEL_META1);
  };

  const renderZonesModelProperties = (store, element, elementPath) => {
    return (
      <div>
        {renderZonesModelProperties1(store, element, elementPath)}
        {renderUnequalZoneWidthsRow(element, elementPath)}
        {renderZoneWidths(element, elementPath)}
        <Row>
          <Label col={CAPTION_COLUMNS}>{CORRECTION_ENABLED.caption}</Label>
          <Checkbox col={CHECKBOX_COLUMNS} bind={futils.compose(elementPath, CORRECTION_ENABLED.key)} />
        </Row>
        {renderCorrectionFactors(element, elementPath)}

        {CORRECTION_ENABLED_SEP.map((sep, index) => {
          return (
            <div>
              <Row>
                <Label col={CAPTION_COLUMNS}>{sep.caption}</Label>
                <Checkbox col={CHECKBOX_COLUMNS} bind={futils.compose(elementPath, sep.key)} />
              </Row>
              {renderCorrectionFactorsSep(element, sep, elementPath)}
            </div>
          );
        })}

      </div>
    );
  };

  const getMaskRectangle = (state, element) => {
    return utils.toCanvasRectangle(state.plateRectangle, element.x, element.y, element.width, element.height);
  };

  const getMarginRectangle = (state, element) => {
    return utils.toCanvasRectangle(
      state.plateRectangle,
      element.x - element.marginLeft,
      element.y - element.marginTop,
      element.width + element.marginLeft + element.marginRight,
      element.height + element.marginTop + element.marginBottom);
  };

  const updateMaskRect = (state, element) => {
    const locked = element.locked || !element._selectable;
    const r = getMaskRectangle(state, element);
    const maskRect = element.shape.maskRect;
    maskRect.set({
      left: r.left,
      top: r.top,
      width: r.width,
      height: r.height,
      hasControls: !locked,
      lockMovementX: locked,
      lockMovementY: locked,
    });
    maskRect.setCoords();
  };

  const updateMarginRect = (state, element) => {
    var r = getMarginRectangle(state, element);
    var marginRect = element.shape.marginRect;
    marginRect.set({
      left: r.left,
      top: r.top,
      width: r.width,
      height: r.height
    });
  };

  const createMaskRect = (element) => {
    const selectable = element._selectable;
    const maskRect = new Fabric.Rect({
      hasRotatingPoint: false,
      selectable: selectable,
      evented: selectable,
      strokeDashArray: [5, 3],
      fill: MASK_FILL_COLOR,
      stroke: MASK_STROKE,
      opacity: MASK_OPACITY,
      originX: 'left',
      originY: 'top'
    });

    return maskRect;
  };

  const createMarginRect = () => {
    var marginRect = new Fabric.Rect({
      strokeDashArray: [5, 10],
      fill: MARGIN_FILL_COLOR,
      stroke: MARGIN_STROKE,
      strokeWidth: 2,
      selectable: false,
      evented: false,
      originX: 'left',
      originY: 'top'
    });

    return marginRect;
  };

  const createZoneLine = () => {
    var line = new Fabric.Line(null, {
      stroke: ZONE_STROKE,
      strokeWidth: 1,
      selectable: false,
      evented: false
    });

    return line;
  };

  const createZoneLines = (element) => {
    if (isCip3(element)) {
      return [];
    }

    var zoneLines = [];
    var zoneCount = getZoneCount(element);
    for (var i = 0; zoneCount > 0 && i <= zoneCount; i++) {
      var line = createZoneLine();
      zoneLines.push(line);
    }

    return zoneLines;
  };

  const addZoneLines = (zoneLines) => {
    zoneLines.forEach(line => canvas.add(line));
  };

  const removeZoneLines = (zoneLines) => {
    zoneLines.forEach(line => canvas.remove(line));
  };

  const reconcileZonesWithLines = (state, element) => {
    if (isCip3(element)) {
      return;
    }

    var zoneCount = getZoneCount(element);
    var zoneLines = element.shape.zoneLines;
    var lineCount = zoneLines.length;
    if (zoneCount <= 0 && lineCount <= 0 || zoneCount > 0 && lineCount === zoneCount + 1) {
      return;
    }

    removeZoneLines(zoneLines);
    var zoneLines = createZoneLines(element);
    element.shape.zoneLines = zoneLines;
    addZoneLines(zoneLines);

  };

  const updateZoneLines = (state, element) => {
    var zoneLines = element.shape.zoneLines;
    if (!Array.isArray(zoneLines) || zoneLines.length <= 0) {
      return;
    }

    var r = getMarginRectangle(state, element);
    var offset = 0;
    var zoneCount = zoneLines.length - 1;
    var w = r.width / zoneCount;
    for (var i = 0; i < zoneLines.length; i++) {
      var left = r.left + offset;
      zoneLines[i].set({
        x1: left,
        y1: r.top,
        x2: left,
        y2: r.top + r.height
      });
      if (element.unequalZoneWidth) {
        offset += utils.systemToCanvasUnits(element.zoneWidths[i]);
      } else {
        offset += w;
      }
    }
  };

  const buildDefaultElement = (state, elementType) => {
    const element = {
      elementType: elementType,
      locked: false,
      _selectable: true
    };

    setDefaultElementValues(state, element);
    return element;
  };

  const setDefaultElementValues = (state, element) => {
    // add private '_inkModel' and _outputMode properties (removed in the saved JSON)
    element._inkModel = state.layout.inkModel;
    element._outputMode = state.layout.outputMode;
    propertiesCommon.setDefaultValues(state, element, getMeta(state, element));
  };

  const updateProperty = (state, element, elementPath, propertyPath, propertyValue) => {
    var newState = propertiesCommon.updateProperty(state, element, elementPath, propertyPath, propertyValue);
    var newElement = futils.get(newState, elementPath);

    var key = futils.pathTail(propertyPath);
    if (!isCip3(newElement)) {
      var changed = true;
      if (key === 'unequalZoneWidth' || key === 'zoneCount' ||
        key === 'width' || key === 'marginLeft' || key === 'marginRight') {
        newElement = updateZoneWidths(newElement);
      } else if (futils.pathTail(futils.pathNoTail(propertyPath)) === 'zoneWidths' && !isNaN(key)) {
        newElement = updateZoneWidths(newElement, Number(key));
      } else if (key === 'correctionEnabled') {
        newElement = resetCorrectionFactors(newElement);
      } else {
        var correctionEnabledSepInd = CORRECTION_ENABLED_SEP.findIndex(el => key == el.key);
        if (correctionEnabledSepInd >= 0) {
          var el = CORRECTION_ENABLED_SEP[correctionEnabledSepInd]
          newElement = resetCorrectionFactorsSeps(newElement, el.key, el.correctionFactors.key);
        } else {
          changed = false;
        }
      }

      if (changed) {
        newState = futils.update(newState, elementPath, newElement);
      }
    }

    updateShape(state, newElement);
    return newState;
  };

  const arrangeElements = (state) => {
    var elements = futils.get(state, BASE_PATH);
    var length = utils.maxKey(elements) + 1;
    if (!elements || length <= 0 || utils.calcRectangleArea(state.plateRectangle) <= 0) {
      return state;
    }

    var newState = state;
    var r = utils.plateRectangleToSystem(newState.plateRectangle);
    var x = r.x;
    var y = r.y + MARGIN;
    var width = r.width / length;
    var height = r.height - 2 * MARGIN;

    var marginLeft = 0;
    var marginRight = 0;
    var marginTop = 0;
    var marginBottom = 0;
    var arranged = utils.createPseudoArray();
    for (var key in elements) {
      var elem = elements[key];
      if (typeof elem === 'object') {
        elem = {
          ...elem,
          x,
          y,
          width,
          height,
          marginLeft,
          marginRight,
          marginTop,
          marginBottom,
          unequalZoneWidth: false
        };
        updateZoneWidths(elem);
        arranged[key] = elem;
        updateShape(newState, elem);
        x += width;
      }
    }

    newState = futils.update(newState, BASE_PATH, arranged);

    return newState;
  };

  const shapeTransforming = (state, element) => {
    var newElement = element;
    var maskRect = newElement.shape.maskRect;
    if (utils.isScaledXShape(maskRect)) {
      newElement = updateZoneWidths(newElement);
    }
    utils.applyConstraints(maskRect, state.plateRectangle);
    var r = utils.toSystemRectangle(state.plateRectangle, maskRect.left, maskRect.top, maskRect.width, maskRect.height);
    newElement = { ...newElement, x: r.x, y: r.y, width: r.width, height: r.height };
    updateShape(state, newElement);

    return newElement;
  };

  const createShape = (state, element, elementPath) => {
    var maskRect = createMaskRect(element);
    var marginRect = createMarginRect();
    var zoneLines = createZoneLines(element);

    var shape = {
      elementPath,
      maskRect,
      marginRect,
      zoneLines
    };

    maskRect.shape = shape;
    element.shape = shape;

    updateShape(state, element);
    addShape(state, element);

    return shape;
  };

  const addShape = (state, element) => {
    if (!element || !element.shape) {
      return;
    }

    var shape = element.shape;
    canvas.add(shape.maskRect);
    canvas.add(shape.marginRect);
    addZoneLines(shape.zoneLines);
  };

  const removeShape = (state, element) => {
    if (!element || !element.shape) {
      return;
    }

    var shape = element.shape;
    canvas.remove(shape.maskRect);
    canvas.remove(shape.marginRect);
    removeZoneLines(shape.zoneLines);
  };


  const updateShape = (state, element) => {
    updateMaskRect(state, element);
    updateMarginRect(state, element);
    reconcileZonesWithLines(state, element);
    updateZoneLines(state, element);
  };

  const activateShape = (state, element) => {
    cutils.setActiveObject(element.shape.maskRect);
  };

  const nudgeShape = (state, element, direction) => {
    let newElement = element;
    if (!element.locked) {
      newElement = propertiesCommon.nudgeElement(state, element, direction);
      updateShape(state, newElement);
    }

    return newElement;
  };

  const renderProperties = (store, element, elementPath) => {

    return isZonesModel(element) ? renderZonesModelProperties(store, element, elementPath) :
      renderInkModelOptionsProperties(store, element, elementPath);
  };

  const getInfo = (state) => {
    return {
      basePath: BASE_PATH,
      title: TITLE
    };
  };

  const getAlignmentInfo = (state, element) => {
    return utils.getAlignmentInfo(element.shape.maskRect);
  };

  return {
    buildDefaultElement,
    setDefaultElementValues,
    updateProperty,
    arrangeElements,
    shapeTransforming,
    createShape,
    updateShape,
    addShape,
    removeShape,
    activateShape,
    nudgeShape,
    renderProperties,
    getInfo,
    getAlignmentInfo
  }

};
