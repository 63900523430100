import React from 'react';
import sandbox from 'sandbox';
import ReactSimpleSetup from 'setups/ReactSimpleSetup';
import panelsDefinitions from 'components/private/info/panelsDefinitions';
import CustomInfo from './components/CustomInfo';

const panels = {
  'default': [],
  'page': ['Info', 'Links', 'Colors', 'Sites', 'Ads'],
  'form': ['Info', 'Links', 'Colors', 'Sites', 'Plates', 'Layout', 'Creep', 'Pages', 'Fanout']
};

const isUndefined = (o) => typeof o === 'undefined';

const getElementType = (model) => {
  return model && model.object && model.object.elementType ? model.object.elementType : 'default';
};

const getPropFromModel = (model, propFromModelPath) => {
  const cursor = propFromModelPath.split('.');
  let propFromModel = model;
  if (propFromModelPath !== 'model') {
    cursor.map((element, index) => {
      propFromModel = propFromModel[element];
    });
  }
  return propFromModel;
};

const getPanelProp = (model, PanelProps) => {
  let props = {};
  for (var prop in PanelProps) {
    const propFromModel = getPropFromModel(model, PanelProps[prop]);
    props[prop] = propFromModel;
  }
  return props;
};

const getPanels = (model) => {
  const panelNames = panels[getElementType(model)];

  return panelNames.map(panelName => {
    const panelDefinitions = panelsDefinitions[panelName];
    const icon = panelDefinitions.icon;
    const title = panelDefinitions.title;
    const PanelComp = panelDefinitions.comp;
    const props = getPanelProp(model, panelDefinitions.props);
    const shouldAdd = typeof panelDefinitions.shouldAdd === 'function' ? panelDefinitions.shouldAdd(model, props) : true;

    if (!shouldAdd) {
      return undefined;
    }

    return {
      panelName,
      icon,
      title,
      PanelComp,
      props
    };
  }).filter(panel => !isUndefined(panel));
};

const getNewItemPanelIndex = (oldItemPanels = [], newItemPanels = [], oldItemSelectedPanelIndex) => {
  if (oldItemSelectedPanelIndex < 0) {
    return 0;
  }

  const oldItemSelectedPanel = oldItemPanels[oldItemSelectedPanelIndex] || {};
  const newItemSelectedPanelIndex = newItemPanels.findIndex(panel => panel.panelName === oldItemSelectedPanel.panelName);

  return newItemSelectedPanelIndex < 0 ? 0 : newItemSelectedPanelIndex;
};

export default ReactSimpleSetup.extend({
  cancelButton: false,

  initDone: function () {
    this._super();
    this.openCustomInfoDialog = this.openCustomInfoDialog.bind(this);
  },

  openCustomInfoDialog: function (nwid) {
    const position = {
      top: this.container.wrapper[0].offsetTop + 20,
      left: this.container.wrapper[0].offsetLeft + 20
    };
    if (typeof this.showCustomInfoAction === 'object' && typeof this.showCustomInfoAction.execute === 'function') {
      this.showCustomInfoAction.execute([{ nwid }], position);
    }
  },

  dataReceived: function (model, refreshDataFunction, position) {
    model.links = {};
    for (var modelElement in model) {
      if (modelElement === 'children' || modelElement === 'masters' || modelElement === 'remoteRelatives' || modelElement === 'siblings' || modelElement === 'contents' || modelElement === 'copies') {
        model.links[modelElement] = model[modelElement];
      }
    }
    model.separations.forEach(function (separation) {
      const colorNameObj = sandbox.colors.getColorByName(separation.name);
      const colorRGB = sandbox.colors.getUIColorRGB(colorNameObj);
      separation['type'] = colorNameObj.colorType;
      separation['UIColorRGB'] = colorRGB;
    });

    const newItemPanels = getPanels(model);

    sandbox.sorting.sort(model.separations, 'cmyk', 'name');
    this.render(
      <CustomInfo model={model}
        panels={newItemPanels}
        refreshData={refreshDataFunction}
        openCustomInfoDialog={this.openCustomInfoDialog}
        headerBreadcrumbs={model.object.name}
      />);
  }

});