define([], function () {
  'use strict';

  var currentGuid = 0;
  var guidPrefix = 'c';

  return function guid () {
    return [guidPrefix, currentGuid++].join('');
  }

});