const SimpleForm = require('widgets/SimpleForm/src/index');

const { utils } = SimpleForm;

const updateCell = (state, action) => {
  const path = action.path;
  const tablePath = action.tablePath;
  const tableChangedPath = `${tablePath}.changed`;
  const tableNwidPath = `${tablePath}.nwid`;
  const tableNwid = utils.get(state, tableNwidPath);
  const value = action.value;

  let newState = utils.update(state, path, value);
  if (utils.get(state, path) !== utils.get(newState, path)) {
    newState = utils.update(newState, tableChangedPath, true);
    newState = utils.update(newState, `modifiedTabs[${tableNwid}]`, true);
  }
  return newState;
};
const cleanChanged = (state) => {
  const newState = {
    ...state,
    modifiedTabs: {}
    //tables: state.tables.map((table) => { return utils.update(table, 'changed', false); })
  };
  return newState;
};

const setProperties = (state, action) => {
  let newState = state;
  newState = utils.set(newState, 'publications', action.publications);
  newState = utils.set(newState, 'sites', action.sites);
  newState = utils.set(newState, 'loaded', action.loaded);
  newState = utils.set(newState, 'folderSecurityGroups', action.folderSecurityGroups);
  return newState;
};

module.exports = function (state = {}, action = {}) {

  switch (action.type) {

    case 'UPDATE_CELL':
      return updateCell(state, action);
    case 'CLEAN_CHANGED':
      return cleanChanged(state);
    case 'SET_PROPERTIES':
      return setProperties(state, action);
    default:
      return state;

  }

};