import React from 'react';
import { createRoot } from 'react-dom/client';
import prefernecesManager from 'core/managers/preferences';
import { parseBreadcrumbs, formatBreadcrumbsCodes } from 'utilities/breadcrumbs';
import { createObjectComparator, COMPARE_TYPE } from 'core/comparators';
import { throttle } from 'sandbox/jsUtils';
import AbstractModule from 'AbstractModule';
import TickableModel from '../TickableModel';
import { extractColumnPreferences, } from 'widgets/ReactDataGrid/utils';
import { STATUS, NO_SORTER, getPlateStatus } from "../BinsView/utils";
import { makeTableColumns } from './columnsCreator';
import View from './View';

const THROTTLE_WAIT = 1000;

const PLATES_ORDER = [STATUS.IN_BIN, STATUS.IN_PROGRESS, STATUS.MISSING, STATUS.ERROR];

const composePressLocation = (plate) => {
  const { press, tower, cylinder, location, sector } = plate;

  return `${press}_${tower}_${cylinder}_${location}_${sector}`;
};

const formatPlateBreadcrumbs = (plate) => {
  // Remove Folder - Edition and Plate ID parts of the plate breadcrumbs.
  const breadcrumbs = parseBreadcrumbs(plate.breadcrumbs) || [];
  const shortBreadcrumbs = breadcrumbs.slice(1, breadcrumbs.length - 4);

  return formatBreadcrumbsCodes(shortBreadcrumbs)
};

const populatePlates = (actualPlates, sorterName, binName) => {
  const unassigned = sorterName === NO_SORTER;

  let special = false;
  let plates = [];
  for (const plate of actualPlates) {
    let repeat = 0;
    let status;
    if (unassigned) {
      if (plate.sorter === NO_SORTER || !plate.sorter || !plate.bin) {
        repeat = 1;
        status = getPlateStatus(plate);
      }
    } else if (plate.sorter === sorterName) {
      if (plate.bin === binName) {
        repeat = 1;
        status = getPlateStatus(plate);
      } else {
        repeat += plate.manualBin === binName ? parseInt(plate.manualBinCounter || 0, 10) : 0;
        repeat += plate.revisionBin === binName ? parseInt(plate.revisionBinCounter || 0, 10) : 0;
        if (repeat > 0) {
          special = true;
          status = STATUS.IN_BIN;
        }
      }
    }

    if (repeat > 0) {
      const pressLocation = composePressLocation(plate);
      const breadcrumbs = formatPlateBreadcrumbs(plate);
      plates.push({
        ...plate,
        status,
        pressLocation,
        breadcrumbs,
        repeat,
      });
    }
  }

  const plateComparator = special ?
    createObjectComparator('pressLocation') :
    createObjectComparator('groupOrder', COMPARE_TYPE.NUMBERS);
  plates = plates.sort(plateComparator);

  return plates;
};

export default AbstractModule.extend({
  initDone: function () {
    this.updates = [];

    this.reactRoot = createRoot(this.domElement);
    this.tickUpdateHandlerThrottled = throttle(this.tickUpdateHandler, THROTTLE_WAIT, {
      leading: false,
      trailing: true
    });
  },

  firstTickReceived: function (data) {
    this.preferences = data.preferences || {};
    this.guiSettings = data.GuiSettings || {};

    this.tickableModel = new TickableModel();

    this.tickableModel.firstTickHandler(data.model);

    this.buildViewModel();
  },

  tickUpdate(data) {
    this.updates = this.updates.concat(data.model);
    this.tickUpdateHandlerThrottled();
  },

  tickUpdateHandler: function () {
    this.tickableModel.tickUpdateHandler(this.updates);
    this.updates = [];
    this.buildViewModel();
  },

  savePreferences: function (preferences) {
    if (!preferences) {
      return;
    }

    this.preferences = Object.assign(this.preferences, preferences);
    prefernecesManager.savePreferences(this.getRequiredParameters(), this.preferences);
  },

  saveColumnPreferences: function (tableColumns) {
    const columns = extractColumnPreferences(tableColumns);
    this.savePreferences({
      columns
    });
  },

  buildViewModel: function () {
    this.model = this.tickableModel.model();
    const { sorterName, binName } = this.startParameters;
    const { plates: allPlates } = this.model;

    const actualPlates = allPlates.filter(plate => plate.isActual);
    const plates = populatePlates(actualPlates, sorterName, binName);
    // console.log('plates ========> plates=', plates);

    this.viewModel = {
      sorterName,
      binName,
      plates,
    };

    this.viewModel.tableColumns = makeTableColumns(this.viewModel, this.preferences);

    this.render();
  },

  updateSelectedRows: function (selectedRows) {
    this.updateSelected(selectedRows.map(row => this.tickableModel.getByNwid(row.nwid)).filter(row => !!row));
  },

  handleRowContextMenu: function (clickedRow, selectedRows, e) {
    clickedRow = this.tickableModel.getByNwid(clickedRow.nwid);
    this.updateSelectedRows(selectedRows);
    if (clickedRow && this.selected.length > 0) {
      this.showContextMenu(clickedRow, this.selected, e);
    }
  },

  handleTableSelect: function (selectedRows) {
    this.updateSelectedRows(selectedRows);
  },

  handleColumnResize: function (columns, columnKey) {
    const { tableColumns } = this.viewModel;
    tableColumns.forEach(col => col.width = columns[col.key].width);

    this.saveColumnPreferences(tableColumns);
  },

  destroy: function () {
    this._super();
    this.reactRoot.unmount();
  },

  render: function () {
    this.reactRoot.render(
      <View
        module={this}
        viewModel={this.viewModel}
      />);
  }

});
