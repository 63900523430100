import localization from 'core/services/localization';
import { arrangeItemsByKeys } from 'utilities/array';
import { cells } from 'widgets/ReactDataGrid';
import { applyColumnPreferences, FILTER } from 'widgets/ReactDataGrid/utils';
import { COMPARE_TYPE } from 'core/comparators';

const { Text, IconCell } = cells;

const { translate } = localization;

export const makeNamingPatternsTableColumns = (viewModel, module) => {
  const columnPrefs = module.preferences.table && module.preferences.table.namingPatternsColumns || [];
  let columns = [
    {
      key: 'name',
      width: 150,
      align: 'left',
      filter: FILTER.TEXT_TEXT,
      caption: translate('Name'),
      cell: Text,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cellDataGetter: (rowIndex, key) => {
        const patternName = viewModel.namingPatterns[rowIndex] && viewModel.namingPatterns[rowIndex].name || '';
        return {
          columnData: patternName,
          title: patternName,
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    },
    {
      key: 'pattern',
      width: 700,
      align: 'left',
      filter: FILTER.TEXT_TEXT,
      caption: translate('Pattern'),
      cell: Text,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cellDataGetter: (rowIndex, key) => {
        const pattern = viewModel.namingPatterns[rowIndex] && viewModel.namingPatterns[rowIndex].pattern || '';
        return {
          columnData: pattern,
          title: pattern,
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    },
    {
      key: 'usage',
      width: 100,
      align: 'left',
      caption: translate('Usage'),
      cell: IconCell,
      cellDataGetter: (rowIndex, key) => {
        const namingPattern = viewModel.namingPatterns[rowIndex];
        const usage = namingPattern && typeof namingPattern.usage === 'undefined' ? 'output' : namingPattern.usage;
        const iconName = `${usage}_naming`;
        return {
          icon: iconName,
          title: usage,
          iconClassName: 'crtx-size-24',
          iconSprite: 'general'
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.icon !== props.icon
    },
  ];

  const keys = columnPrefs.map(col => col.key);
  columns = arrangeItemsByKeys(columns, keys);

  columns = applyColumnPreferences(columns, columnPrefs);

  return columns;
};