import { max } from 'utilities/array';
import { useState, useEffect } from 'react';

export const DEFAULT_MAX_ZINDEX = 10000;

/**
 * Creates fake Kendo Window of class 'k-window'.
 * This fake Kendo Window has highest z-index and allows to place
 * a Kendo Window over Dialogs and Popovers from where it is opened
 * @param document
 * @returns {*} fake window div
 */
function createFakeKendoWindow(document) {
  let fakeWindow = findFakeKendoWindow(document);

  if (!fakeWindow) {
    fakeWindow = document.createElement('div');
    document.body.appendChild(fakeWindow);
    fakeWindow.className = 'k-window crtx-fake-window';
    fakeWindow.style.zIndex = DEFAULT_MAX_ZINDEX;
  }

  return fakeWindow;
}

function findFakeKendoWindow(document) {
  return document.body.querySelector('body > .crtx-fake-window');
}

export function findMaxZIndex(document) {
  const nodeList = document.body.querySelectorAll(`
body > .k-window:not(.crtx-fake-window),
body > .crtx-dialog-wrapper,
body > .crtx-dialog,
body > .crtx-popover`);

  const maxZIndex = max(Array.from(nodeList), node => node.style.zIndex || 0) || 0;
  // console.log('=== findMaxZIndex() => maxZIndex=', maxZIndex);

  return maxZIndex || DEFAULT_MAX_ZINDEX;
}

export function adjustFakeKendoWindowZIndex(document) {
  const fakeWindow = createFakeKendoWindow(document);
  const zIndex = findMaxZIndex(document);
  fakeWindow.style.zIndex = zIndex;
}

export function setFakeKendoWindowZIndex(document, zIndex) {
  const fakeWindow = createFakeKendoWindow(document);
  fakeWindow.style.zIndex = zIndex;
}

export function useFloatingZIndex(portalNode, visible) {
  const [zIndex, setZIndex] = useState(DEFAULT_MAX_ZINDEX);

  const document = portalNode?.ownerDocument;

  const setMaxZIndex = (zIndex) => {
    setFakeKendoWindowZIndex(document, zIndex);
    setZIndex(zIndex);
  };

  useEffect(() => {
    if (!document) {
      return;
    }

    if (visible) {
      const maxZIndex = findMaxZIndex(document);
      setMaxZIndex(maxZIndex + 1);
    } else {
      setZIndex(DEFAULT_MAX_ZINDEX);
      adjustFakeKendoWindowZIndex(document);
    }
  }, [visible]);

  return zIndex;
}

