import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {noop} from 'base/jsUtils';
import NumericInput from '../../common/inputs/NumericInput';
import DatePicker from '../../common/dropdown/DatePicker';
import { formatDate } from 'core/dates';
import localization from 'core/services/localization';
import { Popover, PopoverContent, PopoverTrigger, DropdownArrow } from 'components/common/floating';

class DayTimeOffset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };

    this.currentDate = new Date();
  }

  static propTypes = {
    value: PropTypes.shape({
      days: PropTypes.string.isRequired,
      hours: PropTypes.string.isRequired,
      minutes: PropTypes.string.isRequired
    }),
    timeFormat: PropTypes.string,
    timeTitle: PropTypes.string,
    offsetTitle: PropTypes.string,
    timeIntervals: PropTypes.number,
    min: PropTypes.shape({
      days: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      hours: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      minutes: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }),
    max: PropTypes.shape({
      days: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      hours: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      minutes: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    onChange: PropTypes.func
  };

  static defaultProps = {
    value: { days: '0', hours: '00', minutes: '00' },
    timeFormat: localization.getLocaleTimeFormat(),
    timeTitle: 'Time',
    offsetTitle: 'Days',
    onChange: noop
  };

  getDate(hours, minutes) {
    const date = new Date(this.currentDate.getTime());
    date.setHours(hours || 0, minutes || 0);

    return date;
  }

  togglePopover = () => {
    if (!this.props.disabled) {
      this.setState({
        visible: !this.state.visible
      });
    }
  };

  closePopover = () => {
    this.setState({
      visible: false
    });
  };

  handleDaysChange = (event, days) => {
    const { value, onChange } = this.props;

    const newValue = {
      days: days.toString(),
      hours: value.hours,
      minutes: value.minutes
    };

    onChange(event, newValue);
  };

  handleTimeChange = (event, date) => {
    const { value, onChange } = this.props;
    const newValue = {
      days: value.days,
      hours: date.getHours().toString(),
      minutes: date.getMinutes().toString()
    };

    onChange(event, newValue);
  };

  handleKeyDown = (event) => {
    if (event.code === 'Enter') {
      this.closePopover();
    }
  };

  renderPopoverContent = value => {
    const { timeFormat, timeTitle, offsetTitle, timeIntervals } = this.props;

    const min = this.props.min || {};
    const max = this.props.max || {};

    const selectedTime = this.getDate(value.hours, value.minutes);
    let minTime, maxTime;
    if (min.hours || min.minutes || max.hours || max.minutes) {
      minTime =
        Number(value.days) === Number(min.days)
          ? this.getDate(min.hours, min.minutes)
          : this.getDate(0, 0);
      maxTime =
        Number(value.days) === Number(max.days)
          ? this.getDate(max.hours, max.minutes)
          : this.getDate(23, 59);
    }

    return (
      <div className='crtx-day-time-offset-popover-content'>
        <div>
          <div className='crtx-offset-title'>{offsetTitle}</div>
          <div className='crtx-offset-input'>
            <NumericInput
              style={{ width: '100%' }}
              value={Number(value.days)}
              onChange={this.handleDaysChange}
              onKeyDown={this.handleKeyDown}
            />
          </div>
        </div>
        <div className='crtx-offset-title'>{timeTitle}</div>

        <DatePicker
          displayTimeCaption={false}
          style={{ width: '100%' }}
          showTimeSelect={true}
          showTimeSelectOnly={true}
          value={selectedTime}
          timeFormat={timeFormat}
          minTime={minTime}
          maxTime={maxTime}
          timeIntervals={timeIntervals}
          timeCaption={timeTitle}
          onChange={this.handleTimeChange}
          onKeyDown={this.handleKeyDown}
        />
      </div>
    );
  };

  render() {
    const { value, timeFormat, style, disabled } = this.props;
    const { visible } = this.state;
    const disabledClassName = disabled ? 'disabled' : '';
    const className = `crtx-day-time-offset ${this.props.className} ${disabledClassName}`;
    const text = `${value.days}d - ${formatDate(
      this.getDate(value.hours, value.minutes),
      timeFormat
    )}`;

    return (
      <Popover
        open={visible}
        placement='bottom-start'
        onOpenChange={this.closePopover}
      >
        <PopoverTrigger>
          <div
            className={className}
            style={style}
            title={text}
            onClick={this.togglePopover}
          >
            <div className='crtx-day-time-offset-content'>{text}</div>
            <DropdownArrow opened={visible} />
          </div>
        </PopoverTrigger>

        <PopoverContent>
          {visible && this.renderPopoverContent(value)}
        </PopoverContent>
      </Popover>
    );
  }
}

export default DayTimeOffset;
