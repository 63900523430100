/**
 * @name
 * @fileOverview
 * @author sergey
 */

import AbstractAction from 'AbstractAction';
import request from 'core/managers/request';

export default AbstractAction.extend({

  execute: function (model, callback, context) {
    request.testSetup(
      this.nwid,
      model['nwid'],
      model['type'],
      this.module.projectorId,
      model,
      this.parameters.scriptName,
      callback,
      context
    );
  }
});