import React, { Component } from 'react';
import { translate } from 'core/services/localization';
import Header from 'src/components/common/headers/Header';
import SeperationPreview from 'src/components/private/separations/SeperationPreview';
import PropTypes from 'prop-types';
import { formatBreadcrumbs } from 'utilities/breadcrumbs';
import settingsManager from 'core/managers/settings';
import { LengthOutput } from 'components/common/outputs';

const labels = {
  name: translate('Name'),
  colors: translate('Colors'),
  breadcrumbs: translate('Breadcrumbs'),
  width: translate('Width'),
  height: translate('Height'),
  type: translate('Type'),
  nwid: translate('Nwid'),
  path: translate('Path'),
};

const lengthUnit = settingsManager.getLengthUnit();

module.exports = class FragmentsInfo extends Component {
  constructor(props) {
    super(props);
    this.isColors = this.isColors.bind(this);
    this.getPreview = this.getPreview.bind(this);
    this.getColorsPreview = this.getColorsPreview.bind(this);
    this.getSpotsPreview = this.getSpotsPreview.bind(this);
  }

  static propTypes = {
    name: PropTypes.string,
    breadcrumbs: PropTypes.array,
    type: PropTypes.string,
    title: PropTypes.string,
    nwid: PropTypes.string,
    actualPath: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    advanceInfo: PropTypes.bool,
    openCustomInfoDialog: PropTypes.func,
    separations: PropTypes.array,
    openerType: PropTypes.string//dialog or rightPanel
  };

  isColors(color, index) {
    return color.type === 'Black' || color.type === 'Magenta' || color.type === 'Cyan' || color.type === 'Yellow';
  }

  getPreview(seperationPreview, index) {
    const seperationPreviewComp = <SeperationPreview key={index} colorName={seperationPreview.name} shared={seperationPreview.shared} />;
    return seperationPreviewComp;
  }

  getColorsPreview() {
    const { separations } = this.props;
    const colors = separations.filter(this.isColors);
    const colorsPreview = colors.map(this.getPreview);
    return colorsPreview;
  }

  getSpotsPreview() {
    const { separations } = this.props;
    const spots = separations.reject(this.isColors);
    const spotsPreview = spots.map(this.getPreview);
    return spotsPreview.length !== 0 ? <span>Spots: {spotsPreview}</span> : undefined;
  }

  render() {
    const { name, breadcrumbs, width, height, type, title, nwid, advanceInfo, actualPath } = this.props;

    const formattedBreadcrumbs = formatBreadcrumbs(breadcrumbs);

    const advanceInfoNwid = advanceInfo ?
      <React.Fragment>
        <span className='custom-info-row-title'>{labels.nwid}: </span>
        <span className='custom-info-row-content ellipsis' title={nwid}>{nwid || ''}</span>
      </React.Fragment> : '';

    const path = advanceInfo ?
      <React.Fragment>
        <span className='custom-info-row-title'>{labels.path}: </span>
        <span className='custom-info-row-content ellipsis-before' title={actualPath}>{actualPath || ''}</span>
      </React.Fragment> : '';

    return <div className='info-custom-info'>
      <Header>{title}</Header>
      <div className='custom-info-container'>
        <span className='custom-info-row-title'>{labels.name}:</span>
        <span className='custom-info-row-content'>{name || ''}</span>

        <span className='custom-info-row-title'>{labels.colors}: </span>
        <span className='custom-info-row-content'>
          <span className='regular-colors-content'>{this.getColorsPreview()}</span>
          <span className="spot-colors-content">{this.getSpotsPreview()}</span>
        </span>

        <span className='custom-info-row-title' title={labels.width}>{labels.width}: </span>
        <span className='custom-info-row-content ellipsis' title={width}>
          <LengthOutput value={width} unit={lengthUnit} />
        </span>
        <span className='custom-info-row-title' title={labels.height}>{labels.height}: </span>
        <span className='custom-info-row-content ellipsis' title={height}>
          <LengthOutput value={height} unit={lengthUnit} />
        </span>
        <span className='custom-info-row-title' title={labels.type}>{labels.type}: </span>
        <span className='custom-info-row-content ellipsis' title={type}>{type}</span>

        <span className='custom-info-row-title'>{labels.breadcrumbs}:</span>
        <span className='custom-info-row-content'>
          <span className='custom-info-breadcrumbs ellipsis-before' title={formattedBreadcrumbs}>
            {formattedBreadcrumbs}
          </span>
        </span>
        {advanceInfoNwid}
        {path}
      </div>
    </div>;
  }
};