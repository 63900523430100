import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utilities/classNames';
import { SvgIcon } from 'components/common/buttons';

export function DropdownArrow(
  {
    disabled,
    opened
  }) {

  return (
    <div className={classNames('crtx-dropdown-arrow', { disabled, opened })}>
      <SvgIcon name='arrow_drop_down' />
    </div>
  );
}

DropdownArrow.propTypes = {
  disabled: PropTypes.bool,
  opened: PropTypes.bool,
};
