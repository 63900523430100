/**
 * @fileOverview
 * @author sergey
 */
import AbstractAction from 'AbstractAction';
import sandbox from 'sandbox';
import base from 'base';
import pubsub from 'core/managers/pubsub';

const translate = sandbox.localization.translate;

const labels = {
  dialogConfirmationTitle: translate('Confirmation'),
  singlePage: pageName => translate('Do you want to map this file to page {1}?', pageName),
  multiplePages: pageName => translate('Do you want to map these files to page {1} and the following pages?', pageName),
}

export default AbstractAction.extend({

  mapUnplanned: function (target, source) {

    var nwid, type;
    var viewInstanceNwId = this.module.nwid;
    var deviceNwId = this.module.viewSettings.rootId;
    var projectorId = this.module.projectorId;

    type = target['type'];
    nwid = target['nwid'];
    var request = sandbox.request.mapUnplannedPage(viewInstanceNwId, this.nwid, nwid, type, projectorId,
      {
        config: this.config,
        type, //: source["type"],
        nwid, //: source["nwid"],
        target: target,
        source: source
      }
    );

    return request;
  },

  execute: function (target, source) {
    const deferred = base.data.deferred();
    const sourceArr = Array.isArray(source) ? source : [source];
    const pagesNames = sourceArr.map(s => s.name).join('\r\n');
    let message = `${sourceArr.length <= 1 ? labels.singlePage(target.name) : labels.multiplePages(target.name)} \n ${pagesNames}`;

    if (this.config && this.config.showConfirmationMassage) {
      sandbox.dialog.confirm(message, labels.dialogConfirmationTitle).then(() => {
        pubsub.publish('drop-on-page', { unplannedPages: source, success: true });
        this.mapUnplanned(target, sourceArr).then(res => {
          deferred.resolve(res);
        }).fail(res => deferred.reject(res));
      })
    } else {
      pubsub.publish('drop-on-page', { unplannedPages: source, success: true });
      this.mapUnplanned(target, sourceArr).then(res => {
        deferred.resolve(res);
      }).fail(res => deferred.reject(res));
    }
    return deferred.promise();
  }

});