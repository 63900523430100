import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import settingsManager from 'core/managers/settings';
import dialogService from 'core/services/dialogService';
import toastService from 'core/services/toastService';
import { ICON_BY_FILE_TYPE, FILE_TYPE_OPTIONS } from '../utils/utils';
import { restPut } from 'core/managers/rest2';
import { Dialog, DialogButtonsBar } from 'components/common/dialogs';
import { Button, FlatButton, ToggleFlatButton, SvgIcon } from 'components/common/buttons';
import { LengthOutput } from 'components/common/outputs';
import { COMPARE_TYPE, createObjectComparator, composeComparators } from 'core/comparators';
import Table, { Column, cells, headers, filters } from 'widgets/ReactDataGrid';
import {
  FILTER,
  FILTER_TYPE,
  HEADER_HEIGHT,
  HEADER_HEIGHT_WITH_FILTER,
  ROW_HEIGHT,
  ROW_HEIGHT_INCLUDING_THUMBNAIL,
  checkTextCondition,
  reduceColumnsToSortBy,
  reduceColumnsToFilterBy
} from 'widgets/ReactDataGrid/utils';
import utils from '../utils/utils';
import furnitureCreator from '../elements/furnitureCreator';
import actions from '../redux/actions';

const { Text, IconCell, GenericCell, Thumbnail } = cells;
const { GenericHeader, HeaderCaption } = headers;
const { MultiselectFilter, TextInputFilter } = filters;
const MIN_COLUMN_WIDTH = 20;
const DEFAULT_SORT = [
  {
    key: 'name',
    ascending: true,
  }
];

const getTableColumns = () => {

  return [
    {
      key: 'thumbnail',
      caption: translate('Thumbnail'),
      width: 100,
      align: 'center',
      cell: Thumbnail,
      dataGetter: (row) => {
        const { folderNwid, name, timestamp } = row;
        const imageUrl = utils.getFurnitureImageUrl(folderNwid, name, timestamp);
        return {
          imageUrl
        };
      }
    },
    {
      key: 'name',
      width: 300,
      caption: translate('Name'),
      cell: Text,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      filter: FILTER.TEXT_TEXT,
      dataGetter: (row) => {
        const { name } = row;

        return {
          columnData: name || '',
        };
      }
    },
    {
      caption: translate('Type'),
      key: 'type',
      cell: IconCell,
      width: 80,
      align: 'center',
      filter: {
        options: FILE_TYPE_OPTIONS,
        ...FILTER.MULTISELECT_ICON,
      },
      sortType: COMPARE_TYPE.CASE_SENSITIVE,
      dataGetter: (row) => {
        const fileType = row.type || '';
        if (!fileType) {
          return;
        }

        const { icon, iconSprite, iconClassName, title } = ICON_BY_FILE_TYPE[fileType] || {};
        return {
          icon,
          iconSprite,
          iconClassName,
          title,
        };
      },
    },
    {
      caption: translate('Width'),
      key: 'width',
      cell: GenericCell,
      width: 100,
      sortType: COMPARE_TYPE.NUMBERS,
      dataGetter: (row) => {
        const { width } = row;

        return {
          columnData: <LengthOutput value={width} unit={settingsManager.getLengthUnit()} />
        };
      },
    },
    {
      caption: translate('Height'),
      key: 'height',
      cell: GenericCell,
      width: 100,
      sortType: COMPARE_TYPE.NUMBERS,
      dataGetter: (row) => {
        const { height } = row;

        return {
          columnData: <LengthOutput value={height} unit={settingsManager.getLengthUnit()} />
        };
      },
    },
  ];
};

export const ManageFurnituresDialog = (
  {
    store,
    editor,
    onClose
  }) => {

  const [selectedRows, setSelectedRows] = useState([]);
  const [filtersEnabled, setFiltersEnabled] = useState(false);
  const [filterByKey, setFilterByKey] = useState({});
  const [columnsToSortBy, setColumnsToSortBy] = useState(DEFAULT_SORT);

  const tableRef = useRef();

  const furnitures = useMemo(() => {
    return editor.furnitures.map(f => ({ ...f, fileName: `${f.name}.${f.type}` }));

  }, [editor.furnitures]);

  const tableColumns = useMemo(() => {
    const columns = getTableColumns();

    return columns.reduce((acc, col, index) => {
      const filter = { ...col.filter, ...filterByKey[col.key] };
      acc[index] = { ...col, filter };

      return acc;
    }, Array(columns.length));

  }, [filterByKey]);

  const getHeaderHeight = () => filtersEnabled ? HEADER_HEIGHT_WITH_FILTER : HEADER_HEIGHT;

  const getRowHeight = () => {
    const thumbnailColumn = tableColumns.find(col => col.key === 'thumbnail');
    return thumbnailColumn?.visible ? ROW_HEIGHT_INCLUDING_THUMBNAIL : ROW_HEIGHT;
  };

  const clearSelection = () => {
    setSelectedRows([]);
    tableRef.current?.setSelectedRows([]);
  };

  const sortedRows = useMemo(() => {
    if (columnsToSortBy.length <= 0) {
      return furnitures;
    }

    const comparator = composeComparators(columnsToSortBy.map(col => {
      return createObjectComparator(col.sortValueGetter || col.key, col.sortType, col.ascending);
    }));

    return [...furnitures].sort(comparator);

  }, [furnitures, columnsToSortBy]);

  const filteredRows = useMemo(() => {
    if (!filtersEnabled) {
      return sortedRows;
    }

    const columnsToFilterBy = reduceColumnsToFilterBy(tableColumns);
    return sortedRows.filter(row => {
      let match = true;

      for (const col of columnsToFilterBy) {
        const filter = col.filter;
        if (filter.type === FILTER_TYPE.TEXT) {
          match = checkTextCondition(row[col.key], filter);
        } else if (filter.type === FILTER_TYPE.MULTISELECT) {
          if (Array.isArray(filter.selected) && filter.selected.length > 0) {
            const filterValue = row[col.key];
            match = filter.selected.some(s => s === filterValue);
          }
        }

        if (!match) {
          break;
        }
      }

      return match;
    });

  }, [sortedRows, filterByKey]);

  const handleImportFurniture = () => {
    const { viewInstanceNwid, folderNwid } = store.getState();
    furnitureCreator.importFurniture(viewInstanceNwid, folderNwid)
      .then(furniture => {
        editor.reloadFurnitures()
          .then(() => {
            store.dispatch(actions.addFurnitureOption(furniture));
          })
          .catch(err => {
            toastService.errorToast(translate('Reload Furnitures failed'), err?.statusText);
          });
      })
      .catch(err => {
        if (err !== 'cancel') {
          toastService.errorToast(translate('Furniture Import failed'), err?.statusText);
        }
      });
  };

  const handleDeleteFurnitures = () => {
    const furnitureNames = selectedRows.map(f => f.name);
    const msg = translate('Are you sure you want to delete the selected Furniture? This action may affect Layouts and Plates that use these Furnitures. The following Furnitures will be deleted:') +
      '<br>' + furnitureNames.join(', ');

    dialogService.openConfirmDialog(msg, translate('Delete Furniture'))
      .then(() => {
        const state = store.getState();
        const restData = { furnitures: selectedRows.map(f => f.name) };
        restPut(state.viewInstanceNwid, 'impositions/furnitures/delete', restData)
          .then(() => {
            editor.reloadFurnitures()
              .then(() => {
                clearSelection();
                store.dispatch(actions.cleanupFurnitures());
              })
              .catch(err => {
                toastService.errorToast(translate('Reload Furnitures failed'), err?.statusText);
              });
          })
          .catch(err => {
            toastService.errorToast(translate('Delete Furnitures failed'), err?.statusText);
          });
      });
  };

  const handleTableSelect = (selectedRows) => {
    setSelectedRows(selectedRows);
  };

  const handleTableSort = (columnKey, multiSort) => {
    const columns = reduceColumnsToSortBy(tableColumns, columnsToSortBy, columnKey, multiSort);
    setColumnsToSortBy(columns);
  };

  const handleColumnFilterChange = (column, filterValue) => {
    if (!column?.filter) {
      return;
    }

    clearSelection();
    setFilterByKey({ ...filterByKey, [column.key]: filterValue });
  };

  const toggleFilters = () => {
    const enabled = !filtersEnabled;
    if (enabled) {
      clearSelection();
    }

    setFiltersEnabled(enabled);
  };

  const renderColumnFilter = (column) => {
    const { filter } = column;
    if (!filter) {
      return;
    }

    switch (filter.type) {
      case FILTER_TYPE.MULTISELECT:
        return (
          <MultiselectFilter
            value={filter.selected}
            options={filter.options}
            dataType={filter.dataType}
            onSelect={(event, values) => handleColumnFilterChange(column, { selected: values })}
          />
        );
      case FILTER_TYPE.TEXT:
        return (
          <TextInputFilter
            value={filter.textValue}
            onChange={(e, textValue) => handleColumnFilterChange(column, { textValue })}
          />
        );
    }
  };

  const renderColumnHeaderFilter = column => {
    if (!filtersEnabled) {
      return;
    }

    return (
      <div className='column-header-filter'>
        {renderColumnFilter(column)}
      </div>
    );
  };

  const renderColumnHeaderCaption = (column, handleClick, sortDirection, index) => {
    return (
      <HeaderCaption
        sortDirection={sortDirection}
        sortOrder={index + 1}
        tooltip={column.caption}
        onSort={() => handleClick(column.key, column.sortValueGetter)}
        sortable={!!column.sortType}
      >
        {column.caption}
      </HeaderCaption>
    );
  };

  const renderColumnHeader = (column, handleClick, sortDirection, index) => {
    return (
      <GenericHeader
        captionRenderer={renderColumnHeaderCaption(column, handleClick, sortDirection, index)}
        filterRenderer={renderColumnHeaderFilter(column)}
      />
    );
  };

  return (
    <Dialog
      onClose={onClose}
      title={translate('Manage Furnitures')}
      modal={true}
      initialWidth={1200}
      initialHeight={800}
    >
      <div className='manage-furnitures'>

        <div className='furnitures-toolbar'>
          <FlatButton
            className='toolbar-button'
            tooltip={translate('Import Furnitures')}
            onClick={handleImportFurniture}
          >
            <SvgIcon name='import' className='crtx-size-24' />
          </FlatButton>
          <FlatButton
            className='toolbar-button'
            tooltip={translate('Delete selected Furnitures')}
            disabled={selectedRows.length <= 0}
            onClick={handleDeleteFurnitures}
          >
            <SvgIcon name='delete_outline' className='crtx-size-24' />
          </FlatButton>
          <ToggleFlatButton
            className='toolbar-button'
            tooltip={translate('Filters')}
            checked={filtersEnabled}
            onClick={toggleFilters}
          >
            <SvgIcon name='filter_list' className='crtx-size-24' />
          </ToggleFlatButton>
        </div>

        <div className='furnitures-table'>
          <Table
            ref={tableRef}
            rows={filteredRows}
            columnKey='fileName'
            virtualScroll={true}
            selectableRows={true}
            fixed={false}
            minColumnWidth={MIN_COLUMN_WIDTH}
            headerHeight={getHeaderHeight()}
            rowHeight={getRowHeight()}
            onSelect={handleTableSelect}
          >
            {tableColumns.map(col => {
              const columnKey = col.key || col.caption;
              const idx = columnsToSortBy.findIndex(col => col.key === columnKey);
              const sortDirection = idx < 0 ? '' : columnsToSortBy[idx].ascending ? 'asc' : 'desc';
              const handleClickSort = col.sortType && (() => handleTableSort(columnKey));

              return <Column
                key={col.key}
                title={col.caption}
                columnKey={col.key}
                width={col.width}
                visible={col.visible}
                header={renderColumnHeader(col, handleClickSort, sortDirection, idx)}
                cell={col.cell}
                dataGetter={col.dataGetter}
                align={col.align}
                shouldCellUpdate={() => true}
              />;
            })}
          </Table>
        </div>

      </div>

      <DialogButtonsBar>
        <Button className={'primary'} onClick={e => onClose(e)}>
          {translate('OK')}
        </Button>
      </DialogButtonsBar>
    </Dialog>
  );
};

ManageFurnituresDialog.propTypes = {
  store: PropTypes.shape({
    getState: PropTypes.func,
    dispatch: PropTypes.func
  }),
  editor: PropTypes.object,
  onClose: PropTypes.func,
};