
import $ from 'jquery';
import Ember from 'ember';
import sandbox from 'sandbox';
import dialogService from 'core/services/dialogService';
import wizardCommons from './../../../utils/wizardCommons';
import { restPost } from 'core/managers/rest2';
import toastService from 'core/services/toastService';
import { translate } from 'core/services/localization';

function saveTemplateInternal() {
  var that = this;

  if (this.settings.templateCustomizeUrl) {
    restPost(this.instance, `folders/${this.rootId}/planning-actions/saveTemplate/${this.settings.templateCustomizeUrl}`, {
      data: JSON.stringify({
        model: this.model.publication,
        publicationName: this.model.publication.displayName,
        templateName: Ember.get(this, "templateName")
      })
    })
      .then((result) => {
        var isOk = result.result;
        var errStr = result.error;
        if (errStr) {
          toastService.errorToast('', translate(result));

        }
        that.wizard.disableButton("previous");
        that.wizard.enableButton("cancel");
        that.wizard.enableButton("finish");
      })
      .catch(err => {
        toastService.errorToast('', translate('Failed to save template'));
        that.wizard.disableButton("previous");
        that.wizard.enableButton("cancel");
        that.wizard.enableButton("finish");
      });

  } else {
    var promise = savePlanHandler.call(this, "template");
    promise.always(function (data) {

      that.wizard.enableButton("cancel");
      that.wizard.enableButton("finish");

      if (data.indexOf("Error:") === 0) {
        toastService.errorToast('', translate(data));
        return;
      }

      if (that.model.templates === null || typeof that.model.templates === 'undefined') {
        Ember.set(that.model, "templates", []);
      }

      const isGlobal = data.startsWith("global");
      var publicationName = that.model.publication.displayName;
      if (!that.model.globalTemplates){
        Ember.set(that.model, "globalTemplates", {});
      }
      if (!that.model.templates){
        Ember.set(that.model, "templates", {});
      }
      var templates = isGlobal ? that.model.globalTemplates : that.model.templates[publicationName];
      var newTmplt = Ember.get(that, "templateName");
      if (templates === null || typeof templates === 'undefined') {
        if (isGlobal){
          Ember.set(that.model.globalTemplates, newTmplt, [publicationName]);
        } else {
          Ember.set(that.model.templates, publicationName, [newTmplt]);
        }
        
      } else {
        isGlobal ? templates[newTmplt] = [publicationName] : templates.addObject(newTmplt);
      }
      Ember.set(that.model, "templatesChangeFlag", !Ember.get(that.model, 'templatesChangeFlag'));
      Ember.set(that.model.publication, "inActivationMode", false);
    });
  }

}

function savePlanHandler(mode) {
  var projectorId = this.projectorId;
  var dfd = sandbox.async.deferred();
  var rootId = this.rootId;
  var rootType = this.rootType;
  var instance = this.instance;
  var closeAfterActivation = this.settings.closeAfterActivation;
  var runInQueue = this.model.clientSettings && this.model.clientSettings.runInQueue ? this.model.clientSettings.runInQueue : false;
  Ember.set(this.model.publication, "inActivationMode", true);
  var request = sandbox.request.setPlan(this, rootId, projectorId,
    {
      rootId: rootId,
      rootType: rootType,
      instanceNwId: instance,
      model: this.model.publication,
      publicationName: this.model.publication.displayName,
      mode: mode,
      runInQueue: runInQueue,
      templateName: Ember.get(this, "templateName"),
      isGlobal: Ember.get(this, "isGlobal")
    }
  );

  request.then(function (res) {
    if (res.statusCode === 500) {
      dfd.resolve("Error: " + res.errorMessage);
    } else {
      if (mode === "template") {
        dfd.resolve(res.data && res.data.isGlobal ? "global template created." : "template created.");
      } else if (mode === "removeTemplate") {
        dfd.resolve(res.data);
      } else if (typeof res.data === 'undefined' || res.data === null) {
        dfd.resolve("Plan created.");
      } else {
        if (res.data && res.data.errorMessage) {
          dfd.resolve("Error: " + res.data.errorMessage);
        } else if (closeAfterActivation) {
          window.close();
        }

        dfd.resolve(res.data.object);
      }

    }
  },
    function (res) {
      dfd.reject("fail message");
    });

  return dfd.promise();

}

var isValid = (function () {
  var rg1 = /^[^\\/:\*\?"<>\|]+$/; // forbidden characters \ / : * ? " < > |
  var rg2 = /^\./; // cannot start with dot (.)
  var rg3 = /^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i; // forbidden file names
  return function isValid(fname) {
    return rg1.test(fname) && !rg2.test(fname) && !rg3.test(fname);
  }
})();

module.exports = Ember.ObjectController.extend(wizardCommons.controllerCommons, {
  actions: {
    templateSelected: function (selectedTemplate) {
      if (!selectedTemplate.isReal){
        return;
      }
      //var selectedTemplate = event.srcElement.value;

      //var slcObj = $('.slcTemplatesSaveSelection')[0];
      //var selectedOptions = slcObj.selectedOptions;
      //if (selectedOptions.length === 0) {
      //  return;
      //}
      //var selectedTemplate = slcObj.selectedOptions[0].text;
      Ember.set(this, "templateName", selectedTemplate.label);
      Ember.set(this, "isGlobal", selectedTemplate.isGlobal);
      Ember.set(this.model, "templatesChangeFlag", !Ember.get(this.model, 'templatesChangeFlag'));
      $("#cbxGlobalTemplate")[0].checked = selectedTemplate.isGlobal;

    },
    templateChanged: function (e) {
      console.log(e);
      ////var selectedTemplate = event.srcElement.value;
      //var slcObj = $('.slcTemplatesSaveSelection')[0];
      //var selectedOptions = slcObj.selectedOptions;
      //if (selectedOptions.length === 0){
      //  return;
      //}
      //var selectedTemplate = slcObj.selectedOptions[0].text;
      Ember.set(this.model, "templatesChangeFlag", !Ember.get(this.model, 'templatesChangeFlag'));
      Ember.set(this, "templateToSave", selectedTemplate);
    },
    activatePlane: function () {

      if (Ember.get(this, "activated")) {
        return;
      }
      //var domobj1 = $(".planActivationDoingSomething")[0];
      //var domobj2 = $(".planActivationNotDoingSomething")[0];
      //domobj1.style.display = 'inline';
      //domobj2.style.display = 'none';

      var that = this;
      var promise = savePlanHandler.call(this, "plan");
      promise.always(function (data) {
        //domobj2.style.display = 'inline';
        //domobj1.style.display = 'none';
        Ember.set(that, "activated", true);
        Ember.set(that.model.publication, "inActivationMode", false);
        Ember.set(that.model.publication, "activated", true);

        that.wizard.disableButton("previous");
        that.wizard.enableButton("cancel");
        that.wizard.enableButton("finish");

        //$( "#pwPostActivationPanel" )[0].style.display = 'inline';
        //$( "#pwPreActivationPanel" )[0].style.cursor = 'text';
        //$(".planActivationNotDoingSomething")[0].innerText = "Plan Activated";

        if (data.indexOf("Error:") === 0) {
          toastService.errorToast('', translate(data));
          return;
        }

        var div = $("#planningWizardActivationStepResultMsg")[0];
        div.innerHTML = data.replace(/\\n\\r/g, "<br>");
        //$( "#pwPostActivationPanel" )[0].style.display = 'inline-block';

      });
    },
    saveTemplate: function () {

      var txt = $("#txtTemplateName")[0];
      var cbxGlobal = $("#cbxGlobalTemplate")[0];
      var selectedTemplate = txt.value;
      var isGlobal = cbxGlobal.checked;
      
      if (!selectedTemplate || selectedTemplate.length === 0) {
        return;
      }
      var fileNameValid = isValid(selectedTemplate);
      if (!fileNameValid) {
        toastService.errorToast('', translate('Template name {1} is not valid', selectedTemplate));
        return;
      }
      Ember.set(this, "templateName", selectedTemplate);
      Ember.set(this, "isGlobal", isGlobal);
      var templateExists = false;
      var mytemplates = this.templates();
      for (var i = 0; i < mytemplates.length; i++) {
        if (mytemplates[i] === selectedTemplate) {
          templateExists = true;
          break;
        }
      }
      if (templateExists) {
        dialogService.openConfirmDialog(translate('Are you sure you want to override template {1}?', selectedTemplate), translate('Override template'))
          .then(() => {
            saveTemplateInternal.call(this);
          });
      } else {
        saveTemplateInternal.call(this);
      }

    },

    removeTemplate: function (selectedTemplate) {
      dialogService.openConfirmDialog(translate('Are you sure you want to remove template {1}?', selectedTemplate.label), translate('Remove template'))
        .then(() => {
          
          Ember.set(this, "templateName", selectedTemplate.label);
          var that = this;
          var promise = savePlanHandler.call(this, "removeTemplate");
          promise.always(function (data) {
            Ember.set(that, "templateName", null);
            var removedTemplate = data.templateName;
            var publicationName = data.publicationName;
            // var templates = that.model.templates[publicationName];
            if (selectedTemplate.isGlobal){
              var templates = that.model.globalTemplates;
              delete templates[removedTemplate];
            } else {
              var templates = that.model.templates[publicationName];
              if (templates === null || typeof templates === 'undefined') {
                return;
              }
              templates.removeObject(removedTemplate);
            }
            
            Ember.set(that.model, "templatesChangeFlag", !Ember.get(that.model, 'templatesChangeFlag'));
            Ember.set(that.model.publication, "inActivationMode", false);
          });
        });
    }
  },

  templates: function () {
    
    if (this.model.publication === null || typeof this.model.publication === 'undefined' ||
      this.model.templates === null || typeof this.model.templates === 'undefined') {
      return [];
    }
    var publicationName = this.model.publication.displayName;
    var templates = this.model.templates[publicationName];
    if (templates === null || typeof templates === 'undefined') {
      return [];
    }
    return templates;
  },

  init: function () {
    this._super();

  },

  activated: false

});