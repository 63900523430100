/**
 * Created by tzvikas on 4/30/2015.
 */

define(['sandbox/planSetup', './../../../utils/wizardUtils'],
  function(PlanSetup, wizardUtils){
    'use strict';
    //wizardUtils.getSourceId()
    function buildZonePages(modelZone){
      var ret = {};
      modelZone.sections.forEach(function (section) {
        if (!ret.hasOwnProperty(section.sourceId)){
          ret[section.sourceId] = [];
        }
        section.pages.forEach(function (page){
          var n = parseInt(page.numberInSection, 10);
          var pages = ret[section.sourceId][n-1];
          if (pages === null || typeof pages === 'undefined'){
            pages = [];
            ret[section.sourceId][n-1] = pages;
          }
          pages.push(page.sourceId);
        });
      });
      return ret;
    }

    function getIndex(zone, bookSections){
      var minIndex = 9999;
      for (var i=0;i<bookSections.length;i++){
        var sourceId = bookSections[i].sectionSourceId;
        //var section = wizardUtils.matchBySourceId(sourceId, zone.sections);
        for (var j=0;j<zone.sections.length;j++){
          if (zone.sections[j].sourceId === sourceId){
            if (j < minIndex){
              minIndex = j;
            }
          }
        }
      }
      return minIndex;
    }

    function createDefaultBooks(zone){
      var doneSections = {};
      for (var i=0;i<zone.sections.length;i++){
        var section = zone.sections[i];
        if (doneSections.hasOwnProperty(section.sourceId)){
          continue;
        }
        var book;
        if (section.split) {
          var splitAfter = section.splitAfter;
          var splitNumPages = parseInt(section.splitNumPages, 10);
          var sections = createSplitSectionsStructure(zone, i, doneSections);
          book = createEmptyBook(zone, section, sections);
          //book.sections = sections;
        } else {
          doneSections[section.sourceId] = 1;
          book = createDefaultBook.call(this, zone, section);
        }
        zone.books.push(book);
      }
      return zone.books;
    }

    function createSplitSectionsStructure(zone, start, doneSections){
      var sections = [];
      var splitSectionsToInsert = {};
      for (var i=start;i<zone.sections.length;i++){
        var modelSection = zone.sections[i];
        var splitAfter = modelSection.splitAfter;
        var splitNumPages = parseInt(modelSection.splitNumPages, 10);
        if (isNaN(splitNumPages)){
          splitNumPages = 0;
        }
        var numPages = parseInt(modelSection.numPages, 10) - splitNumPages;

        var startPage = parseInt(modelSection.startPage, 10);
        var lastPage = startPage + numPages - 1;
        var rangeUI = startPage + "-" + lastPage;

        var section = {displayName:modelSection.displayName, sectionSourceId:modelSection.sourceId, range:rangeUI};
        sections.push(section);
        if (modelSection.split) {
          var splitStartPage = parseInt(modelSection.splitStartPage, 10);
          rangeUI = splitStartPage + "-" +  (splitStartPage + splitNumPages - 1);
          var splitSection = {displayName:modelSection.displayName, sectionSourceId:modelSection.sourceId, range:rangeUI};
          splitSection.splitSectionIndex = modelSection.splitSectionIndex;
          splitSectionsToInsert[splitAfter] = splitSection;
        }


        doneSections[modelSection.sourceId] = 1;
      }

      for(var whereToInsert in splitSectionsToInsert) {
        var splitSectionIndex = splitSectionsToInsert[whereToInsert].splitSectionIndex;
        wizardUtils.insertAt(sections, splitSectionsToInsert[whereToInsert], parseInt(splitSectionIndex, 10))
        //for (var k=sections.length-1;k>=0;k--){
        //  if (sections[k].sectionSourceId === whereToInsert){
        //    sections.insertAt(k+1, splitSectionsToInsert[whereToInsert]);
        //  }
        //}
      }
      return sections;
    }

    function createEmptyBook(zone, section, sections){
      var sourceId = (zone.sourceId + "/books/" + section.displayName).toLowerCase();
      var book = {displayName:section.displayName, sourceId,
        parent:zone.sourceId, pages:[], sections:sections, copies:1};

      var zonePages = buildZonePages(zone);
      for (var i=0;i<sections.length;i++){
        for (var j=0;j<zone.sections.length;j++){
          if(zone.sections[j].sourceId === sections[i].sectionSourceId){
            var modelSection = zone.sections[j];
            modelSection.onBooks = [book.sourceId];
          }
        }
      }

      var bookSections = book.sections;
      var bookPages = [];
      bookSections.forEach(function (bookSection){
        var bookSectionSourceId = bookSection.sectionSourceId;
        var range = bookSection.range;
        var params = range.split(";");
        for (var p=0;p<params.length;p++){
          var rangeValue = params[p];
          if (rangeValue.indexOf("-") === -1) {
            var n = parseInt(rangeValue, 10);
            bookPages.push(zonePages[bookSectionSourceId][n-1]);
          } else {
            var rangeValues = rangeValue.split("-");
            var first = parseInt(rangeValues[0], 10);
            var last = parseInt(rangeValues[1], 10);
            for (var n=first;n<=last;n++){
              bookPages.push(zonePages[bookSectionSourceId][n-1]);
            }
          }
        }
      });
      book.pages = bookPages;
      return book;
    }

    function createDefaultBook(zone, section) {
      //var first = section.pages[0].numberInZone;
      //var last =  section.pages[section.pages.length-1].numberInZone;
      var sourceId = (zone.sourceId + "/books/" + section.displayName).toLowerCase();
      var book = {displayName:section.displayName, sourceId:sourceId,
        parent:zone.sourceId, pages:[], copies:1};

      if (section.defaultLayout){
        book.defaultLayout = section.defaultLayout;
        delete section.defaultLayout;
      }

      var numPages = parseInt (section.numPages, 10);
      for (var i=0;i<section.pages.length;i++){
        var n = parseInt(section.pages[i].numInserts, 10);
        if (!isNaN(n)){
          numPages += n;
        }
        delete section.pages[i].isDinky;
        delete section.pages[i].halfWeb;
        delete section.pages[i].eightUpDinky;
      }
      var startPage = parseInt(section.startPage, 10);
      var lastPage = startPage + numPages - 1;
      var rangeUI = startPage + "-" + lastPage;
      book.sections = [{
        code: section.code,
        displayName: section.displayName,
        sectionSourceId: section.sourceId,
        range: rangeUI,
        rangeUI: rangeUI
      }];
      for (var i=0;i<section.pages.length;i++){
        var split = section.pages[i].split;
        var a = [];
        if (split === "A"){
          a = [section.pages[i].sourceId, section.pages[i+1].sourceId];
        } else if (split === "B"){
          continue;
        } else {
          a = [section.pages[i].sourceId];
        }
        book.pages.push(a);
      }
      book.code = section.code === null || typeof section.code === 'undefined' ?  section.displayName : section.code;
      section.onBooks = [book.sourceId];
      return book;
    }

    function validModel (model, mappedModel, defaultsLayouts) {
      var that = this;
      var booksChanged = false;
      var publication = model.publication;
      var editions = publication.editions;
      //var dfltLayout = Object.keys(model.pmtInfo)[0];
      editions.forEach(function(edition){
        var zones = edition.zones;
        var defaultsLayoutsEdition = defaultsLayouts.editions ? defaultsLayouts.editions.find(obj => {
          return obj.name == edition.displayName;
        }) : {};
        for (var j=0;j<zones.length;j++){
        //zones.forEach(function(zone){
          var zone = zones[j];
          var books = zone.books;

          var defaultsLayoutsZone = defaultsLayoutsEdition.zones ? defaultsLayoutsEdition.zones.find(obj => {
            return obj.name == zone.displayName;
          }) : {};

          if (books === null || typeof books === 'undefined' || books.length === 0){
            books = createDefaultBooks.call(this, zone);

           
            
              //defaultLayout
            books.forEach(function(b){
              var defaultsLayoutsSection = defaultsLayoutsZone.sections ? defaultsLayoutsZone.sections.find(obj => {
                return obj.name == b.displayName;
              }) : {};
              b.defaultLayout = defaultsLayoutsSection.dfltLayout;
            });
            

            booksChanged = true;
          } else {
            for (var k=0;k<books.length;k++){
            //books.forEach(function(book){
              var book = books[k];
              for (var i=book.pages.length-1;i>=0;i--){
                var items = book.pages[i];
                for (var p=items.length-1;p>=0;p--){
                  var pageDesc = mappedModel[items[p]];
                  if (pageDesc === null || typeof pageDesc === 'undefined'){
                    items.splice(i, 1);
                  }
                }
                if (items.length === 0){
                  book.pages.splice(i, 1);
                }
              }
              if (book.pages.length === 0){
                zone.books.splice(k, 1);
              }
            };
          }

          zone.sections.forEach(function(section){
            if (section.onBooks === null || typeof section.onBooks === 'undefined' || section.onBooks.length === 0){
              var book = createDefaultBook.call(this, zone, section);
              if (!book.defaultLayout){
                var defaultsLayoutsSection = defaultsLayoutsZone.sections ? defaultsLayoutsZone.sections.find(obj => {
                  return obj.name == book.displayName;
                }) : {};
                book.defaultLayout = defaultsLayoutsSection.dfltLayout;
              }
              

              booksChanged = true;
              zone.books.push(book);
            }
          });
  
  
          if (booksChanged){
            zone.books.sort(function (a, b) {
              var aSections = a.sections;
              var bSections = b.sections;
              var aIndex = getIndex(zone, aSections);
              var bIndex = getIndex(zone, bSections);
              return aIndex - bIndex;
            });
          }

        };

        
        
      });



    }

    return {

      validModel: validModel

    };

  })
;
