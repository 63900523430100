define([], function () {
  var DEFAULT_BATCH_INTERVAL = 1000 / 2;//1000 / 60;

  function BatchedRenderer (options) {
    this.init(options);
  }

  BatchedRenderer.prototype = {
    init: function (options) {
      options = options || {};
      this.batching = false;
      this.batchInterval = options.interval || DEFAULT_BATCH_INTERVAL;
      this.model = undefined;
      this.cmd = undefined;
      this._working = true;
    },
    render: function (cmd, model) {
      var that = this;
      //if (typeof cmd !== 'function') return;
      //
      //this.model = model;
      //this.cmd = cmd;
      //cmd.apply(undefined, [this.model]);
      //that.timeoutRender();
      if (!that.batching) {
        setTimeout(function () {
          that.batching = false;
          cmd.apply(undefined, []);
        }, that.batchInterval);

        that.batching = true;
      }

    },
    timeoutRender: function () {
      if (!this._working) return;

      //console.log(this.a);
      if (this.model.__isDirty) {
        this.cmd.apply(undefined, [this.model]);
      }
      setTimeout(this.timeoutRender.bind(this), this.batchInterval);
    },
    destroy: function () {
      this._working = false;
    }
  };

  return BatchedRenderer;
});