/* @flow weak */
import React, { Component } from 'react';
import ReactDom from 'react-dom';
import PropTypes from 'prop-types';
import jQuery from 'jquery';
import SimpleForm from 'widgets/SimpleForm/src/index';
import ResizeHandler from 'components/common/resizeHandler/resizeHandler';
import Menu from 'components/common/menu/Menu';

const { SimpleFormComponent } = SimpleForm;
const SCROLL_INTERVAL = 30;

const renderTab = (currentTabIndex, modifiedTabs, handleClick) => {
  return (tab, index) => {
    const liClassName = currentTabIndex === index ? 'tab selected-tab' : 'tab';
    const modifiedIndication = '*';
    const didModified = modifiedTabs[tab.nwid] === true;
    const modifiedIndicationStyle = {
      opacity: didModified ? 1 : 0
    };
    return <div key={index} ref={`selectedTab-${index}`} className={liClassName} onClick={ev => handleClick(index)}>
      <span>{tab.name}</span>
      <span className="tab-modified-indicator" style={modifiedIndicationStyle}>{modifiedIndication}</span>
    </div>;
  };
};

const Tabs = SimpleFormComponent(class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasScroll: false,
      isScrollPositionStart: false,
      isScrollPositionEnd: false,
    };
  }

  static propTypes = {
    store: PropTypes.any,
    bind: PropTypes.string,
    modifiedTabs: PropTypes.object,
    currentTabIndex: PropTypes.any,
    className: PropTypes.string,
    onClick: PropTypes.func
  }

  handleClick = tabIndex => {
    if (typeof this.props.onClick === 'function') {
      this.props.onClick(tabIndex);
    }
  };

  handleArrowLeftMouseDown = () => {
    const tabsItemsContainer = ReactDom.findDOMNode(this.refs['tabsItemsContainer']);
    Window.tmp = tabsItemsContainer;
    document.onmouseup = this.handleMouseUp;
    this.scrollInterval = setInterval(function () {
      tabsItemsContainer.scrollLeft -= SCROLL_INTERVAL;
    }, 1000 / 35);
  };

  handleArrowRightMouseDown = () => {
    const tabsItemsContainer = ReactDom.findDOMNode(this.refs['tabsItemsContainer']);
    Window.tmp = tabsItemsContainer;
    document.onmouseup = this.handleMouseUp;
    this.scrollInterval = setInterval(function () {
      tabsItemsContainer.scrollLeft += SCROLL_INTERVAL;
    }, 1000 / 35);
  };

  handleMouseUp = () => {
    clearInterval(this.scrollInterval);
    this.scrollInterval = undefined;
    this.setScrollState();
  };

  shouldScroll = () => {
    const tabsItems = ReactDom.findDOMNode(this.refs['tabsItems']);
    const tabsHeader = ReactDom.findDOMNode(this.refs['tabsHeader']);

    return tabsItems.clientWidth > tabsHeader.clientWidth;
  };

  isScrollPositionStart = () => {
    const tabsItemsContainer = ReactDom.findDOMNode(this.refs['tabsItemsContainer']);
    return tabsItemsContainer.scrollLeft === 0;
  };

  isScrollPositionEnd = (shouldScroll) => {
    const tabsItems = ReactDom.findDOMNode(this.refs['tabsItems']);
    const tabsItemsContainer = ReactDom.findDOMNode(this.refs['tabsItemsContainer']);
    return tabsItemsContainer.scrollLeft + tabsItemsContainer.clientWidth === tabsItems.clientWidth;
  };

  setScrollState = () => {
    const hasScroll = this.shouldScroll();
    const isScrollPositionStart = this.isScrollPositionStart();
    const isScrollPositionEnd = this.isScrollPositionEnd();

    if (this.state.hasScroll !== hasScroll || this.state.isScrollPositionStart !== isScrollPositionStart || this.state.isScrollPositionEnd !== isScrollPositionEnd) {
      this.setState({
        hasScroll,
        isScrollPositionStart,
        isScrollPositionEnd
      });
    }
  };

  scrolledToIndex = (index) => {
    const tabsItems = ReactDom.findDOMNode(this.refs['tabsItems']);
    const tabsItemsContainer = ReactDom.findDOMNode(this.refs['tabsItemsContainer']);

    const selectedTab = ReactDom.findDOMNode(this.refs[`selectedTab-${index}`]);
    if (selectedTab !== null) {
      if (selectedTab.offsetLeft > tabsItemsContainer.clientWidth) {
        jQuery(tabsItemsContainer).stop().animate({
          scrollLeft: selectedTab.offsetLeft + selectedTab.clientWidth - tabsItemsContainer.clientWidth
        }, 200);
      }
      if (selectedTab.offsetLeft < tabsItemsContainer.scrollLeft) {
        jQuery(tabsItemsContainer).stop().animate({
          scrollLeft: selectedTab.offsetLeft
        }, 200);
      }
    }
  };

  componentDidMount() {
    this.scrollInterval;
    this.scrollToIndex = true;

    const { currentTabIndex, children } = this.props;
    this.setScrollState();
    this.scrolledToIndex(currentTabIndex);
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentTabIndex, children } = this.props;

    if (prevProps.currentTabIndex != this.props.currentTabIndex) {
      this.scrolledToIndex(currentTabIndex);
    }
    this.setScrollState();
  }

  handleSelectMenuItem = (e, item, index) => this.handleClick(index);

  render() {
    const store = this.props.store;
    const bind = this.props.bind;
    const modifiedTabs = this.props.modifiedTabs;
    const currentTabIndex = this.props.currentTabIndex;
    const className = this.props.className || '';
    const ulClassName = `crtx-conversion-tables-tabs ${className}`;
    const { hasScroll, isScrollPositionStart, isScrollPositionEnd } = this.state;
    const tabsValue = store.get(bind) || [];
    const tabs = tabsValue.map(renderTab(currentTabIndex, modifiedTabs, this.handleClick));
    const tabMenuItems = tabsValue.map(tab => tab.name);

    let arrowLeftStyle = {
      display: hasScroll ? 'block' : 'none',
      visibility: isScrollPositionStart ? 'hidden' : 'visible'
    };
    let arrowRightStyle = {
      display: hasScroll ? 'block' : 'none',
      visibility: isScrollPositionEnd ? 'hidden' : 'visible'
    };

    return <div className={`${ulClassName}`}>
      <div ref='tabsHeader' className='crtx-conversion-tables-tabs-header' style={{}}>
        <div ref='arrowLeft' className='crtx-conversion-tables-tabs-arrow-left k-icon k-i-arrow-60-left'
             onMouseDown={this.handleArrowLeftMouseDown} onMouseUp={this.handleMouseUp} style={arrowLeftStyle}></div>
        <div ref='tabsItemsContainer' className='crtx-conversion-tables-tabs-items-container' style={{}}>
          <div ref='tabsItems' className='crtx-conversion-tables-tabs-items' style={{}}>
            {tabs}
          </div>
        </div>
        <div ref='arrowRight' className='crtx-conversion-tables-tabs-arrow-right k-icon k-i-arrow-60-right'
             onMouseDown={this.handleArrowRightMouseDown} onMouseUp={this.handleMouseUp} style={arrowRightStyle}></div>
      </div>
      <Menu
        className='crtx-conversion-tables-tabs-menu'
        items={tabMenuItems}
        placement={'bottom-end'}
        onSelect={this.handleSelectMenuItem}>
        <i className='material-icons'>arrow_drop_down</i>
      </Menu>
      <ResizeHandler onResize={this.setScrollState} wait={1000 / 45} />
    </div>;
  }

});

module.exports = Tabs;