const AbstractAction = require('AbstractAction');
const sandbox = require('sandbox');

module.exports = AbstractAction.extend({

  isApplicable: function (obj) {
    // let selected = this && this.module ? this.module.selected : [];
    // if (!Array.isArray(selected)) {
    //   return false;
    // }
    // return selected.length > 0;
    return true;
  },

  execute: function (newModel, oldModel) {
    const module      = this.module;
    let   moduleState = module.apply();
    
    if (typeof moduleState === 'undefined') return;

    return sandbox.request.saveSetup(
      this.nwid,
      moduleState.newModel['nwid'],
      moduleState.newModel['type'],
      this.module.projectorId,
      moduleState.newModel,
      moduleState.oldModel,
      this.parameters.scriptName
    ).then(function (data) {
      if (typeof module !== 'undefined' && typeof module.onSaveActionResponse === 'function') {
        module.onSaveActionResponse.apply(module, arguments);
      }
    });
  }
});