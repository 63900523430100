import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {SORT_DIRECTION} from 'core/comparators';

export default class extends Component {
  static propTypes = {
    sortable: PropTypes.bool,
    sortDirection: PropTypes.string,
    sortOrder: PropTypes.number,
    sortIcon: PropTypes.object,
    tooltip: PropTypes.string,
  };

  static defaultProps = {
    sortable: true,
    sortDirection: SORT_DIRECTION.NONE,
    sortOrder: -1,
    sortIcon: {
      [SORT_DIRECTION.ASC]: {
        className: 'material-icons',
        content: 'arrow_downward',
      },
      [SORT_DIRECTION.DESC]: {
        className: 'material-icons',
        content: 'arrow_upward',
      },
    },
    onSort: () => {
    }
  };

  handleHeaderClick = (e) => {
    const {onSort, sortable} = this.props;

    if (sortable) {
      const multiSort = e.ctrlKey || e.metaKey || e.shiftKey;
      onSort(multiSort);
    }
  };

  handleIconClick = (e) => {
    const {onSort, sortable} = this.props;

    if (sortable) {
      e.stopPropagation();
      const multiSort = true;
      onSort(multiSort);
    }
  };

  renderSortIcon() {
    const {sortable, sortDirection, sortOrder, sortIcon} = this.props;

    if (!sortable) {
      return;
    }

    const icon = sortIcon[sortDirection];
    const className = `sort-icon ${icon ? 'active' : ''}`;

    return (
      <div className={className}
           onClick={this.handleIconClick}>
        {icon && sortOrder > 0 ? <div>{sortOrder}</div> : undefined}
        {icon ? <i className={icon.className}>{icon.content}</i> : undefined}
      </div>
    );
  }

  render() {
    const {sortable, tooltip, children} = this.props;

    const className = `column column-header ${sortable ? 'sortable' : ''}`;

    return (
      <div className={className} onClick={this.handleHeaderClick}>
        <div className={'column-content'} title={tooltip}>
          <div className={'column-header-caption'}>
            {children}
          </div>
          {this.renderSortIcon()}
        </div>
      </div>
    );
  }
}