/**
 * @name furnitureCreator
 * @file Create Furniture from unplanned pages
 *
 * @author Boris
 * @since: 2016-08-15
 */

import utils from '../utils/utils';
import unplannedPages from './unplannedPages';

const importFurniture = (viewInstanceNwid, folderNwid) => {
  return unplannedPages.selectPageImage(viewInstanceNwid)
    .then(imageItem => createFurniture(imageItem, folderNwid));
};

const createFurniture = (imageItem, folderNwid) => {
  if (!imageItem || !imageItem.nwid || !imageItem.width || !imageItem.height) {
    console.error('createFurniture(): cannot create Furniture from ', imageItem);
    throw new Error('Error creating Furniture');
  }

  var params = {
    command: 'createFurniture',
    rootId: folderNwid,
    nwid: imageItem.nwid,
    template: imageItem.template,
    furniturePath: 'impositions\\furnitures'
  };

  return utils.sendGenericRequest(params);
};

export default {
  importFurniture
};

