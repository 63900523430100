/**
 * @name Sidebar
 * @file React component that defines sidebar functionality
 *
 * @author Boris
 * @since: 2016-06-10
 */

import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import dom from 'base/dom';
import ElementsForm from './ElementsForm';
import PropertiesForm from './PropertiesForm';

const Sidebar = ({ store, editor, onChangeLayout }) => {

  const sidebarRef = useRef(null);

  useLayoutEffect(() => {
    const $splitter = dom.find(sidebarRef.current);
    $splitter.kendoSplitter({
      orientation: 'vertical',
      panes: [
        { collapsible: false, size: '46%' },
        { collapsible: false }
      ]
    });

    return () => {
      $splitter.data('kendoSplitter')?.destroy();
    };
  }, []);

  return (
    <div ref={sidebarRef} className='fit-height'>
      <ElementsForm store={store} editor={editor} onChangeLayout={onChangeLayout} />
      <PropertiesForm store={store} editor={editor} />
    </div>
  );
};

Sidebar.propTypes = {
  store: PropTypes.any,
  editor: PropTypes.any,
};

export default Sidebar;
