import React from 'react';

export default class extends React.Component {
  static defaultProps = {
    show: true
  };

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.show === nextProps.show;
  }

  render() {
    return <div className="CollapsiblePanel-Content">
      {this.props.children}
    </div>;
  }
}