import { getMainStatus } from 'utilities/statuses';
import OpenDialogAction from './OpenDialogAction';

const isPassedProgress = (selectedItem = {}, progress) => {
  const status = getMainStatus(selectedItem) || {};
  return status.progress > progress;
}

export default OpenDialogAction.extend({
  isApplicable: function (selectedItems) {
    const config = this.config || {};

    if(!config['gui$enableCheckboxValue-progress']) {
      return true;
    } else {
      const progress = config.progress * 100;
      return selectedItems.length === 1 && isPassedProgress(selectedItems[0], isNaN(progress) ? 0 : progress);
    }   
  }
});


