const React = require('react');
const PropTypes = require('prop-types');
const { Component } = React;

module.exports = class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object,
    onDoubleClick: PropTypes.func,
    viewStyle: PropTypes.string,
    onClick: PropTypes.func,
    isSelected: PropTypes.bool
  }

  constructor(props) {
    super(props);
  }

  handleDoubleClick = (item) => {
    return function (event) {
      this.props.onDoubleClick(event, item);
    }.bind(this);
  };

  handleClick = (item) => {
    return function (event) {
      this.props.onClick(event, item);
    }.bind(this);
  }

  render() {
    const item = this.props.item;
    const viewStyle = this.props.viewStyle;
    const isSelected = this.props.isSelected;
    const selectedItemStyle = isSelected ? 'selected-item-style' : '';
    var handleDoubleClick = item.type === 'folder' || item.type === 'disk' ? this.handleDoubleClick(item) : '';
    const className = ['crtx-item-' + viewStyle, selectedItemStyle].join(' ');

    return <li className={className} onDoubleClick={handleDoubleClick} onClick={this.handleClick(item)} title={item.displayName}>
      <img className='crtx-folder-icon' alt={item.name} />
      <span>{item.displayName}</span>
    </li>;
  }
}