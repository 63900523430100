import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utilities/classNames';

export default class Text extends Component {
  static propTypes = {
    columnData: PropTypes.any,
    title: PropTypes.string,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    className: PropTypes.string,
    rowIndex: PropTypes.number,
    columnKey: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    ellipsisStart: PropTypes.bool,
  };

  static defaultProps = {
    columnData: '',
    style: {},
    disabled: false,
    ellipsisStart: false,
  };

  handleClick = (ev) => {
    const { rowIndex, columnKey, onClick } = this.props;

    if (typeof onClick === 'function') {
      onClick(rowIndex, columnKey, ev);
    }

  };

  shouldComponentUpdate(nextProps) {
    const { props } = this;
    return nextProps.rowIndex !== props.rowIndex || nextProps.columnData !== props.columnData ||
      nextProps.backgroundColor !== props.backgroundColor || nextProps.disabled !== props.disabled ||
      nextProps.className !== props.className || nextProps.textColor !== props.textColor;
  }

  render() {
    const { columnData, title, backgroundColor, textColor, className, style, onClick, disabled, ellipsisStart } = this.props;
    const cellStyle = {
      cursor: typeof onClick === 'undefined' ? null : 'pointer',
      color: textColor,
      backgroundColor,
      ...style
    };

    return <div className={classNames('cell', className, { disabled }, { 'ellipsis-start': ellipsisStart })} style={cellStyle} onClick={this.handleClick}
      title={title || columnData}>
      <div className="cell-content">
        <div className="cell-content-padding disable-text-select ctrx-cell-text-decoration">
          {ellipsisStart ? <bdi>{columnData}</bdi> : columnData}
        </div>
      </div>
    </div>;
  }
}