/**
 * @name barcode
 * @file Defines Barcode element functionality
 *
 * @author Boris
 * @since: 2016-07-19
 */

import React from 'react';
import Fabric from 'fabric';
import sandbox from 'sandbox';
import SimpleForm from 'widgets/SimpleForm/src/index';
import utils from '../utils/utils';
import canvasUtils from '../utils/canvasUtils';
import propertiesCommon from './propertiesCommon';
import customConditions from "./customConditions";

const {translate} = sandbox.localization;
const jsUtils = sandbox.jsUtils;
const futils = SimpleForm.utils;

const BASE_PATH = 'layout.barcodes';
const TITLE = translate('Barcode');

const FILL_COLOR = 'rgba(0,0,0,0)';
const STROKE = 'black';

const DEFAULT_WIDTH = 2; // inch
const BARCODE_PARAMS = {
  symbology: true,
  _matrixSize: true,
  sample: true,
  readableText: true,
  quietZoneX: true,
  quietZoneY: true,
  moduleWidthMode: true,
  moduleWidth: true,
  wideToNarrowRatio: true,
  height: true
};

const {DISPLAY_NAME, X, Y, HEIGHT, _CORNER, _ALIGNMENT, ROTATION, APPLY_ON, BLACK_ONLY} = propertiesCommon;

const SYMBOLOGY = {
  key: 'symbology',
  caption: translate('Symbology'),
  type: 'options',
  options: [
    {value: 'code-39', text: translate('Code 39')},
    {value: 'code-39extended', text: translate('Code 39 Extended')},
    {value: 'code-2of5', text: translate('Code 2of5')},
    {value: 'data-matrix', text: translate('Data Matrix')}
  ]
};

const MATRIX_SIZE = {
  key: '_matrixSize',
  caption: 'Size',
  type: 'options',
  options: [
    {value: 'default', text: translate('Default')},
    {value: '10x10', text: '10 x 10'},
    {value: '12x12', text: '12 x 12'},
    {value: '14x14', text: '14 x 14'},
    {value: '16x16', text: '16 x 16'},
    {value: '18x18', text: '18 x 18'},
    {value: '20x20', text: '20 x 20'},
    {value: '22x22', text: '22 x 22'},
    {value: '24x24', text: '24 x 24'},
    {value: '26x26', text: '26 x 26'},
    {value: '32x32', text: '32 x 32'},
    {value: '36x36', text: '36 x 36'},
    {value: '40x40', text: '40 x 40'},
    {value: '44x44', text: '44 x 44'},
    {value: '48x48', text: '48 x 48'},
    {value: '52x52', text: '52 x 52'},
    {value: '64x64', text: '64 x 64'},
    {value: '72x72', text: '72 x 72'},
    {value: '80x80', text: '80 x 80'},
    {value: '88x88', text: '88 x 88'},
    {value: '96x96', text: '96 x 96'},
    {value: '104x104', text: '104 x 104'},
    {value: '120x120', text: '120 x 120'},
    {value: '132x132', text: '132 x 132'},
    {value: '144x144', text: '144 x 144'},
    {value: '8x18', text: '8 x 18'},
    {value: '8x32', text: '8 x 32'},
    {value: '12x26', text: '12 x 26'},
    {value: '12x36', text: '12 x 36'},
    {value: '16x36', text: '16 x 36'},
    {value: '16x48', text: '16 x 48'}
  ]
};

const MODULE_WIDTH = {
  key: 'moduleWidth',
  caption: translate('Module width'),
  type: 'length',
  defaultValue: 1 / 96
};

const WIDE_TO_NARROW_RATIO = {
  key: 'wideToNarrowRatio',
  caption: translate('Wide to narrow ratio'),
  type: 'float',
  defaultValue: 3
};

const MODULE_WIDTH_MODE = {
  key: 'moduleWidthMode',
  type: 'string',
  defaultValue: 'custom',
  hidden: true
};

const READABLE_TEXT = {
  key: 'readableText',
  caption: translate('Readable text'),
  type: 'bool'
};

const QUIET_ZONE_X = {
  key: 'quietZoneX',
  caption: translate('Quiet zone X'),
  type: 'length',
  hidden: true
};

const QUIET_ZONE_Y = {
  key: 'quietZoneY',
  caption: translate('Quiet zone Y'),
  type: 'length',
  hidden: true
};

const SAMPLE = {
  key: 'sample',
  caption: translate('Sample text'),
  type: 'string',
  defaultValue: '0123456789'
};

const TEXT = {
  key: 'text',
  caption: translate('Text'),
  type: 'text'
};

const TRACKING_BARCODE = {
  key: 'isTrackingBarcode',
  caption: translate('Tracking barcode'),
  type: 'bool',
  defaultValue: true
};

const BARCODE_META = [
  DISPLAY_NAME,
  X,
  Y,
  HEIGHT,
  _CORNER,
  _ALIGNMENT,
  ROTATION,
  SYMBOLOGY,
  MATRIX_SIZE,
  MODULE_WIDTH,
  WIDE_TO_NARROW_RATIO,
  MODULE_WIDTH_MODE,
  READABLE_TEXT,
  QUIET_ZONE_X,
  QUIET_ZONE_Y,
  SAMPLE,
  TEXT,
  TRACKING_BARCODE,
  APPLY_ON,
  BLACK_ONLY
];

export default (editor) => {

  const canvas = editor.getMainCanvas();
  const cutils = canvasUtils(editor);

  const isDataMatrix = (element) => {
    return element.symbology === 'data-matrix';
  };

  const getMeta = (state, element) => {
    if (isDataMatrix(element)) {
      MATRIX_SIZE.hidden = false;
      MODULE_WIDTH.hidden = true;
      WIDE_TO_NARROW_RATIO.hidden = true;
    } else {
      MATRIX_SIZE.hidden = true;
      MODULE_WIDTH.hidden = false;
      WIDE_TO_NARROW_RATIO.hidden = false;
    }

    // hide readable text property till barcode text related bug in tiff processing will be fixed
    READABLE_TEXT.hidden = true;

    return BARCODE_META;
  };

  const buildDefaultElement = (state, elementType) => {
    var element = {
      elementType: elementType,
      locked: false,
      _selectable: true
    };

    setDefaultElementValues(state, element);

    return element;
  };

  const setDefaultElementValues = (state, element) => {
    propertiesCommon.setDefaultValues(state, element, getMeta(state, element));
    element._matrixSize = composeMatrixSize(element.rows, element.columns);
    element.conditions = utils.fromPseudoArrayDeep(element.conditions) || [];
  };

  const setMatrixSizeProperty = (state, element, matrixSize) => {
    var rows = getRows(matrixSize);
    var columns = getColumns(matrixSize);

    return {...element, _matrixSize: matrixSize, rows, columns};
  };

  const updateProperty = (state, element, elementPath, propertyPath, propertyValue) => {
    var newState = state;
    var newElement = element;

    var key = futils.pathTail(propertyPath);
    if (key === '_matrixSize') {
      newElement = setMatrixSizeProperty(newState, newElement, propertyValue);
      newState = futils.update(newState, elementPath, newElement);
    } else {
      newState = propertiesCommon.updateProperty(newState, newElement, elementPath, propertyPath, propertyValue);
    }

    newElement = futils.get(newState, elementPath);

    updateShape(newState, newElement);

    if (BARCODE_PARAMS[key]) {
      loadBarcodeImageDebounced(newState, newElement);
    }

    return newState;
  };

  const shapeTransforming = (state, element) => {
    var barcodeRect = element.shape.barcodeRect;
    var scaled = utils.isScaledShape(barcodeRect);
    //utils.unscaleShape(barcodeRect);
    utils.applyConstraints(barcodeRect, state.plateRectangle);
    var r = utils.toSystemRectangle(state.plateRectangle, barcodeRect.left, barcodeRect.top, barcodeRect.width, barcodeRect.height,
      element.referenceX, element.referenceY);
    var newElement = {...element, x: r.x, y: r.y, height: r.height};

    updateShape(state, newElement);

    if (scaled) {
      loadBarcodeImageDebounced(state, newElement);
    }

    return newElement;
  };

  const createBarcodeRect = (element) => {
    const selectable = element._selectable;
    const barcodeRect = new Fabric.Rect({
      hasRotatingPoint: false,
      centeredRotation: false,
      selectable: selectable,
      evented: selectable,
      lockScalingX: true,
      fill: FILL_COLOR,
      stroke: STROKE,
    });

    return barcodeRect;
  };

  const toBarcodeRectangle = (state, element) => {
    var result = utils.toCanvasRectangle(state.plateRectangle, element.x, element.y, DEFAULT_WIDTH, element.height,
      element.referenceX, element.referenceY);
    var image = element.shape.barcodeImage;
    if (image.width > 0) {
      result.width = image.width;
    }

    return result;
  };

  const updateBarcodeRect = (state, element) => {
    const locked = element.locked || !element._selectable;
    const r = toBarcodeRectangle(state, element);
    const barcodeRect = element.shape.barcodeRect;
    const align = utils.calcAlignmentBeforeRotation(element.alignmentX, element.alignmentY, element.rotation);
    barcodeRect.set({
      left: r.left,
      top: r.top,
      width: r.width,
      height: r.height,
      originX: align.alignmentX,
      originY: align.alignmentY,
      angle: element.rotation,
      hasControls: !locked,
      lockMovementX: locked,
      lockMovementY: locked,
      lockScalingY: locked,
      hoverCursor: locked ? 'default' : 'move',
    });
    barcodeRect.setCoords();
  };

  const createBarcodeImage = (element) => {
    var img = new Image();
    img.src = '';
    var imgOptions = {
      centeredRotation: false,
      visible: false,
      selectable: false,
      evented: false
    };

    return new Fabric.Image(img, imgOptions);
  };

  const updateBarcodeImage = (state, element) => {
    var rect = element.shape.barcodeRect;
    var image = element.shape.barcodeImage;

    image.set({
      left: rect.left,
      top: rect.top,
      originX: rect.originX,
      originY: rect.originY,
      angle: rect.angle
    });
  };

  const loadBarcodeImage = (state, element) => {
    //console.log("--- loadBarcodeImage() ---");
    var image = element.shape.barcodeImage;
    image.visible = false;

    var imageUrl = getBarcodeImageUrl(element);
    if (!imageUrl) {
      return;
    }

    image.setSrc(imageUrl, () => {
      //console.log("loadBarcodeImage() image.width=", image.width);
      if (image.width <= 0 || image.height <= 0) {
        return;
      }

      image.visible = true;

      var barcodeRect = element.shape.barcodeRect;
      barcodeRect.set('width', image.width);
      barcodeRect.setCoords();

      cutils.renderAllDebounced();
    });
  };

  const _loadBarcodeImageDebounced = jsUtils.debounce(loadBarcodeImage, 1000);

  const loadBarcodeImageDebounced = (state, element) => {
    element.shape.barcodeImage.visible = false;
    _loadBarcodeImageDebounced(state, element);
  };

  const getBarcodeImageUrl = (element) => {
    var bcParams = getBarcodeParams(element);
    var lastBarcodeParamsJson = JSON.stringify(bcParams);
    var params = {
      action: 'getBarcodeImage',
      command: 'getLayouManagerActions',
      rootId: editor.getFolderNwid(),
      bcParams: encodeURIComponent(lastBarcodeParamsJson),
      timestamp: new Date().getTime()
    };

    var imageUrl = sandbox.request.getImageUrl(params, true);
    //console.log("barcode.getImageUrl() imageUrl=", imageUrl);
    return imageUrl;
  };

  const getBarcodeParams = (element) => {
    var SYMBOLOGY = {
      'code-39': '8',
      'code-39extended': '9',
      'code-2of5': '3',
      'data-matrix': '71'
    };
    var bcParams = {
      BC_BarcodeText: element.sample,
      BC_Height: element.height.toString(),
      BC_QuiteZone_X: element.quietZoneX.toString(),
      BC_QuiteZone_Y: element.quietZoneY.toString(),
      BC_ReadableText: element.readableText,
      BC_Symbology: SYMBOLOGY[element.symbology],
      rows: getRows(element._matrixSize).toString(),
      columns: getColumns(element._matrixSize).toString(),
      moduleWidthMode: 'custom',
      moduleWidth: element.moduleWidth.toString(),
      wideToNarrowRatio: element.wideToNarrowRatio.toString()
    };

    //console.log("getBarcodeParams() => ", bcParams);
    return bcParams;
  };

  const getRows = (size) => {
    var sz = size.split('x');
    return sz.length === 2 ? Number(sz[0]) : 0;
  };

  const getColumns = (size) => {
    var sz = size.split('x');
    return sz.length === 2 ? Number(sz[1]) : 0;
  };

  const composeMatrixSize = (rows, columns) => {
    return rows > 0 && columns > 0 ? rows + 'x' + columns : 'default';
  };

  const createShape = (state, element, elementPath) => {
    var barcodeRect = createBarcodeRect(element);
    var barcodeImage = createBarcodeImage(element);

    var shape = {
      elementPath,
      barcodeRect,
      barcodeImage
    };

    barcodeRect.shape = shape;
    element.shape = shape;

    updateShape(state, element);
    addShape(state, element);
    loadBarcodeImage(state, element);

    return shape;
  };

  const addShape = (state, element) => {
    if (!element || !element.shape) {
      return;
    }

    var shape = element.shape;
    canvas.add(shape.barcodeRect);
    canvas.add(shape.barcodeImage);
  };

  const removeShape = (state, element) => {
    if (!element || !element.shape) {
      return;
    }

    var shape = element.shape;
    canvas.remove(shape.barcodeRect);
    canvas.remove(shape.barcodeImage);
  };


  const updateShape = (state, element) => {
    updateBarcodeRect(state, element);
    updateBarcodeImage(state, element);
  };

  const activateShape = (state, element) => {
    cutils.setActiveObject(element.shape.barcodeRect);
  };

  const nudgeShape = (state, element, direction) => {
    var newElement = element;
    if (!element.locked) {
      newElement = propertiesCommon.nudgeElement(state, element, direction);
      updateShape(state, newElement);
    }

    return newElement;
  };

  const renderProperties = (store, element, elementPath) => {
    const state = store.getState();
    const meta = getMeta(state, element);

    return (
      <div>
        {propertiesCommon.renderProperties(store, element, elementPath, meta)}
        {customConditions.render(store, element, elementPath)}
      </div>
    );
  };

  const getInfo = (state) => {
    return {
      basePath: BASE_PATH,
      title: TITLE
    };
  };

  const getAlignmentInfo = (state, element) => {
    return utils.getAlignmentInfo(element.shape.barcodeRect);
  };

  return {
    buildDefaultElement,
    setDefaultElementValues,
    updateProperty,
    shapeTransforming,
    createShape,
    updateShape,
    addShape,
    removeShape,
    activateShape,
    nudgeShape,
    renderProperties,
    getInfo,
    getAlignmentInfo
  }

};
