import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utilities/classNames';

function GenericCell({ columnData, title, style = {}, onClick, disabled = false, className, rowIndex, columnKey }) {

  const handleClick = ev => {

    if (typeof onClick === 'function') {
      onClick(rowIndex, columnKey, ev);
    }

  };
  const cellStyle = {
    cursor: typeof onClick === 'undefined' ? null : 'pointer',
    ...style
  };

  return <div className={classNames('cell', 'generic-cell', { disabled }, className)} style={cellStyle} onClick={handleClick} title={title}>
    <div className='cell-content'>
      {columnData}
    </div>
  </div>;
}

GenericCell.propTypes = {
  columnData: PropTypes.any,
  title: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool
}

// const GenericCellMemo = React.memo(GenericCell);
export default GenericCell;