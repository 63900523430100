// @flow
import settingsManager from 'core/managers/settings';
import {fromServerDate, toServerDate} from 'core/dates';

const ReactDataGrid = require('widgets/ReactDataGrid/src/ReactDataGrid');
const sandbox = require('sandbox');
const localization = require('core/services/localization');
const labels = require('./labels')();


const {
  Text, TextInputCell, CheckboxCell, DropdownCell, MultiSelectDropdownCell, LengthInputCell, PercentInputCell,
  DatePickerCell
} = ReactDataGrid.cells;
const dayOptions = [
  {text: labels.everyday, value: '*'},
  {text: labels.sunday, value: 'Sun'},
  {text: labels.monday, value: 'Mon'},
  {text: labels.tuesday, value: 'Tue'},
  {text: labels.wednesday, value: 'Wed'},
  {text: labels.thursday, value: 'Thu'},
  {text: labels.friday, value: 'Fri'},
  {text: labels.saturday, value: 'Sat'}
];

const pageSizeOptions = [
  {text: labels.broadsheet, value: 'broadsheet'},
  {text: labels.tabloid, value: 'tabloid'},
];

const notFreeTextFieldType = ['publication', 'edition', 'zone', 'section', 'broadsheet', 'tabs', '2ups', '4ups', 'siteName', 'siteCode', 'siteListNames', 'siteListCodes', 'securityGroup'];

const isUndefined = (o) => {
  return typeof o === 'undefined';
}

function dayCell(store: Object, rows: Array<Object>, columnType: string, column: Object): Object {
  const editable = column.editable === false ? false : true;

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: true,
    resizable: true,
    width: 140,
    cell: DropdownCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData: rows[rowIndex][columnType][columnKey],
        options: dayOptions,
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.objects', '')}`,
            path: typeof columnType === 'undefined' ? `${bind}[${rowIndex}].${columnKey}` : `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val
          });
        }
      }
    }
  };
}

function dropdown(store: Object, rows: Array<Object>, columnType: string, column: Object): Object {
  const editable = column.editable === false ? false : true;

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: true,
    resizable: true,
    width: 140,
    cell: DropdownCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData: rows[rowIndex][columnType][columnKey],
        options: column.values || [],
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.objects', '')}`,
            path: typeof columnType === 'undefined' ? `${bind}[${rowIndex}].${columnKey}` : `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val
          });
        }
      }
    }
  };
}

function lengthInputCell(store: Object, rows: Array<Object>, columnType: string, column: Object): Object {
  const editable = column.editable !== false;
  const unit = settingsManager.getLengthUnit();

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: true,
    resizable: true,
    width: 140,
    cell: LengthInputCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData: rows[rowIndex][columnType][columnKey],
        unit: unit,
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.objects', '')}`,
            path: typeof columnType === 'undefined' ? `${bind}[${rowIndex}].${columnKey}` : `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val
          });
        }
      }
    }
  };
}

function percentInputCell(store: Object, rows: Array<Object>, columnType: string, column: Object): Object {
  const editable = column.editable === false ? false : true;

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: true,
    resizable: true,
    width: 140,
    cell: PercentInputCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData: rows[rowIndex][columnType][columnKey],
        noConversion: true,
        decimalPlaces: 4,
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.objects', '')}`,
            path: typeof columnType === 'undefined' ? `${bind}[${rowIndex}].${columnKey}` : `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val
          });
        }
      }
    }
  };
}

function multiselect(store: Object, rows: Array<Object>, columnType: string, column: Object): Object {
  const editable = column.editable === false ? false : true;

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: true,
    resizable: true,
    width: 140,
    cell: MultiSelectDropdownCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData: rows[rowIndex][columnType][columnKey].split(','),
        options: column.values || [],
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.objects', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val.join(',')
          });
        }
      }
    }
  };
}


function checkboxCell(store: Object, rows: Array<Object>, columnType: string, column: Object): Object {
  const editable = column.editable === false ? false : true;

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: true,
    resizable: true,
    width: 140,
    cell: CheckboxCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        align: 'center',
        columnData: rows[rowIndex][columnType][columnKey],
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.objects', '')}`,
            path: typeof columnType === 'undefined' ? `${bind}[${rowIndex}].${columnKey}` : `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val.toString()
          });
        }
      }
    }
  };
}

function textCell(store: Object, rows: Array<Object>, columnType: string, column: Object): Object {
  const editable = column.editable === false ? false : true;
  const cell = editable === false ? Text : TextInputCell;
  const textColor = editable === false ? '#999999' : undefined;

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: true,
    resizable: true,
    width: 140,
    cell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData: typeof columnType === 'undefined' ? rows[rowIndex][columnKey] : rows[rowIndex][columnType][columnKey],
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.objects', '')}`,
            path: typeof columnType === 'undefined' ? `${bind}[${rowIndex}].${columnKey}` : `${bind}[${rowIndex}].${columnType}.${columnKey}`,//TODO: Check if it works
            value: val
          });
        },
        textColor
      }
    }
  };
}

function publicationDropdown(store: Object, rows: Array<Object>, columnType: string, column: Object): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const publications = state.publications;

  let valuesPublicationsOptions = !isUndefined(publications) ? publications.reject(function (publication) {
    return publication.value === '*';
  }) : undefined;
  valuesPublicationsOptions = [{value: '', name: '---'}].concat(valuesPublicationsOptions);
  // let publicationsOptions = columnType === 'selectors' ? publications : valuesPublicationsOptions;

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: true,
    resizable: true,
    width: 140,
    cell: DropdownCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData: rows[rowIndex][columnType][columnKey],
        textProp: 'name',
        valueProp: 'value',
        options: valuesPublicationsOptions || [],
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.objects', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val
          });
        }
      }
    }
  };
}

function layoutGroupDropdown(store: Object, rows: Array<Object>, columnType: string, column: Object, fieldType: string): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const layoutGroups = state.layoutGroups;

  var layoutGroupsOptions = !isUndefined(layoutGroups) ? layoutGroups.filter(function (layoutGroup) {
    return layoutGroup[column.fieldType];
  }) : undefined;

  let layoutGroupsOptionsBycolumnType = !isUndefined(layoutGroupsOptions) ? layoutGroupsOptions[0][column.fieldType] : undefined;

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: true,
    resizable: true,
    width: 140,
    cell: DropdownCell,
    props: (bind, rowIndex, columnKey) => {


      return {
        columnData: rows[rowIndex][columnType][columnKey],
        options: layoutGroupsOptionsBycolumnType || [],
        textProp: 'name',
        valueProp: 'value',
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.objects', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val
          });
        }
      }
    }
  };
}

function productSetupDropdown(store: Object, rows: Array<Object>, columnType: string, column: Object): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const publications = state.publications;
  const currentTableIndex = store.get('currentTableIndex');
  const currentTable = state.tables[currentTableIndex];

  const fieldTypes = {
    edition: 'editions',
    section: 'sections',
    zone: 'zones',
  }
  const columnFieldType = fieldTypes[column.fieldType];

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: true,
    resizable: true,
    width: 140,
    cell: DropdownCell,
    props: (bind, rowIndex, columnKey) => {
      let options = [];
      let defaultOption = [];
      const selectedAllPublication = !isUndefined(publications) ? publications.find(function (publication) {
        return publication.value === '*'
      }) : undefined;
      options = !isUndefined(selectedAllPublication) ? selectedAllPublication[columnFieldType] : undefined;
      defaultOption = {value: '', name: '---'}

      return {
        columnData: rows[rowIndex][columnType][columnKey],
        textProp: 'name',
        valueProp: 'value',
        options: options || [],
        defaultOption: defaultOption,
        disabled: !editable,
        shouldCellUpdate: (nextProps, props) => {
          return nextProps.options !== props.options || nextProps.columnData !== props.columnData;
        },
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.objects', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val
          });
        }
      }
    }
  };
}

function pageSizeCell(store: Object, rows: Array<Object>, columnType: string, column: Object): Object {
  const editable = column.editable === false ? false : true;

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: true,
    resizable: true,
    width: 140,
    cell: DropdownCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData: rows[rowIndex][columnType][columnKey],
        options: pageSizeOptions,
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.objects', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val
          });
        }
      }
    }
  };
}

function siteNameDropdown(store: Object, rows: Array<Object>, columnType: string, column: Object, fieldType: string): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const sites = state.sites;
  const filteredSiteName = !isUndefined(sites) ? sites.filter((site) => {
    return !isUndefined(site.name)
  }) : undefined;
  const sitesOptions = !isUndefined(filteredSiteName) ? filteredSiteName.map((site) => {
    return {
      name: site.name,
      value: site.name
    }
  }) : undefined;

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: true,
    resizable: true,
    width: 140,
    cell: DropdownCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData: rows[rowIndex][columnType][columnKey],
        options: sitesOptions || [],
        textProp: 'name',
        valueProp: 'value',
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.objects', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val
          });
        }
      }
    }
  };
}

function siteCodeDropdown(store: Object, rows: Array<Object>, columnType: string, column: Object, fieldType: string): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const sites = state.sites;
  const filteredSiteCode = !isUndefined(sites) ? sites.filter((site) => {
    return !isUndefined(site.code)
  }) : undefined;
  const sitesOptions = !isUndefined(filteredSiteCode) ? filteredSiteCode.map((site) => {
    return {
      name: site.code,
      value: site.code
    }
  }) : undefined;

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: true,
    resizable: true,
    width: 140,
    cell: DropdownCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData: rows[rowIndex][columnType][columnKey],
        options: sitesOptions || [],
        textProp: 'name',
        valueProp: 'value',
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.objects', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val
          });
        }
      }
    }
  };
}

function siteListNamesMultiselect(store: Object, rows: Array<Object>, columnType: string, column: Object): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const sites = state.sites;
  const filteredSiteListName = !isUndefined(sites) ? sites.filter((site) => {
    return !isUndefined(site.name)
  }) : undefined;
  const sitesListOptions = !isUndefined(filteredSiteListName) ? filteredSiteListName.map((site) => {
    return {
      text: site.name,
      value: site.name
    }
  }) : undefined;
  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: true,
    resizable: true,
    width: 140,
    cell: MultiSelectDropdownCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData: !isUndefined(rows[rowIndex][columnType][columnKey]) ? rows[rowIndex][columnType][columnKey].split(',') : [],
        options: sitesListOptions || [],
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.objects', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val.join(',')
          });
        }
      }
    }
  };
}

function siteListCodesMultiselect(store: Object, rows: Array<Object>, columnType: string, column: Object): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const sites = state.sites;
  const filteredSiteListCodes = sites.filter((site) => {
    return !isUndefined(site.code)
  });
  const sitesListOptions = filteredSiteListCodes.map((site) => {
    return {
      text: site.code,
      value: site.code
    }
  });
  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: true,
    resizable: true,
    width: 140,
    cell: MultiSelectDropdownCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData: !isUndefined(rows[rowIndex][columnType][columnKey]) ? rows[rowIndex][columnType][columnKey].split(',') : [],
        options: sitesListOptions || [],
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.objects', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val.join(',')
          });
        }
      }
    }
  };
}

function securityGroupsMultiselect(store: Object, rows: Array<Object>, columnType: string, column: Object): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();

  const securityGroups = state.folderSecurityGroups;
  const securityGroupsOptions = securityGroups.map((securityGroup) => {
    return {
      text: securityGroup.name,
      value: securityGroup.nwid
    }
  });
  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: true,
    resizable: true,
    width: 140,
    cell: MultiSelectDropdownCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData: !isUndefined(rows[rowIndex][columnType][columnKey]) && rows[rowIndex][columnType][columnKey] !== '' ? rows[rowIndex][columnType][columnKey].split(',') : [],
        options: securityGroupsOptions || [],
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.objects', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val.join(',')
          });
        }
      }
    }
  };
}

function deadlineCell(store: Object, rows: Array<Object>, columnType: string, column: Object): Object {
  const editable = column.editable === false ? false : true;
  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: true,
    resizable: true,
    width: 140,
    cell: DatePickerCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData: fromServerDate(rows[rowIndex][columnType][columnKey]),
        timeIntervals: 5,
        showTimeSelect: true,
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.objects', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: toServerDate(val)
          });
        }
      }
    }
  };
}

function cells(fieldType: string, store: Object, rows: Array<Object>, columnType: string, column: Object): Object {
  if (column.freeText === false && Array.isArray(column.values) && column.values.length > 0 && fieldType !== 'multiselect' && fieldType !== 'siteListNames' && fieldType !== 'siteListCodes') {
    return dropdown(store, rows, columnType, column);
  }

  if (!column.freeText && notFreeTextFieldType.findIndex((item) => {
    return item === fieldType
  }) >= 0) {
    switch (fieldType) {
      case 'publication':
        return publicationDropdown(store, rows, columnType, column);
      case 'edition':
        return productSetupDropdown(store, rows, columnType, column);
      case 'zone':
        return productSetupDropdown(store, rows, columnType, column);
      case 'section':
        return productSetupDropdown(store, rows, columnType, column);
      case 'broadsheet':
        return layoutGroupDropdown(store, rows, columnType, column, fieldType);
      case 'tabs':
        return layoutGroupDropdown(store, rows, columnType, column, fieldType);
      case '2ups':
        return layoutGroupDropdown(store, rows, columnType, column, fieldType);
      case '4ups':
        return layoutGroupDropdown(store, rows, columnType, column, fieldType);
      case 'siteName':
        return siteNameDropdown(store, rows, columnType, column, fieldType);
      case 'siteCode':
        return siteCodeDropdown(store, rows, columnType, column, fieldType);
      case 'siteListNames':
        return siteListNamesMultiselect(store, rows, columnType, column, fieldType);
      case 'siteListCodes':
        return siteListCodesMultiselect(store, rows, columnType, column, fieldType);
      case 'securityGroup':
        return securityGroupsMultiselect(store, rows, columnType, column, fieldType);
      default:
        return textCell(store, rows, columnType, column);
    }
  }

  switch (fieldType) {
    case 'day':
      return dayCell(store, rows, columnType, column);
    case 'checkbox':
      return checkboxCell(store, rows, columnType, column);
    case 'measurement':
      return lengthInputCell(store, rows, columnType, column);
    case 'percentage':
      return percentInputCell(store, rows, columnType, column);
    case 'multiselect':
      return multiselect(store, rows, columnType, column);
    case 'pageSize':
      return pageSizeCell(store, rows, columnType, column, fieldType);
    case 'deadline':
      return deadlineCell(store, rows, columnType, column, fieldType);
    default:
      return textCell(store, rows, columnType, column);
  }
}

module.exports = cells;