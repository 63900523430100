import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GridPanel from './gridpanel';
import FormWraper from './formwraper';

export default class Forms extends Component {
  static contextTypes = {
    module: PropTypes.object
  };

  static defaultProps = {
    bookId: undefined,
    forms: [],
    formDefinition: undefined,
    alignmentPoints: undefined
  };

  formDefinition = () => {
    return this.props.formDefinition;
  };

  forms = () => {
    return this.props.forms;
  };

  alignmentPoints = () => {
    return this.props.alignmentPoints;
  };

  getFormsMargin = (pageDefinition) => {
    return pageDefinition.generalProperties.margin;
  };

  onFormClick = (forms, form, e) => {
    this.context.module.ctrl.onClick(forms, form, e);
  };

  onFormContextMenu = (forms, form, e) => {
    this.context.module.ctrl.onContextMenu(forms, form, e);
  };

  onFormDoubleClick = (form, e) => {
    this.context.module.ctrl.onDoubleClick(form, e);
  };

  onAggregatedItemClick = (thumbnail, e) => {
    this.context.module.ctrl.handleAggregatedItemClick(thumbnail, e);
  };

  onOpenChange = () => {
    this.context.module.ctrl.onOpenChange();
  };

  render() {
    var component = this,
      formDefinition = this.formDefinition(),
      margin = this.getFormsMargin(formDefinition),
      forms = this.forms(),
      formsComponent = [];

    forms.forEach(function (form) {
      formsComponent.push(<FormWraper key={form.id}
        form={form}
        onOpenChange={component.onOpenChange}
        formDefinition={formDefinition}
        alignmentPoints={component.alignmentPoints()}
        onClick={(e) => component.onFormClick(forms, form, e)}
        onAggregatedItemClick={(e) => component.onAggregatedItemClick(form, e)}
        onContextMenu={(e) => component.onFormContextMenu(forms, form, e)}
        onDoubleClick={(e) => component.onFormDoubleClick(form, e)}
      >
      </FormWraper>);
    });

    return <GridPanel gridSize={[80, 80]} gridMargin={margin} gridAlign="left">
      {formsComponent}
    </GridPanel>;
  }
}

Forms.propTypes = {
  formDefinition: PropTypes.object,
  alignmentPoints: PropTypes.object,
  forms: PropTypes.array,
};