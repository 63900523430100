import PageViewHires from '../PageViewHires/PageViewHiresNew';
import ApprovalNeighborItems from "../PageViewApproval/ApprovalNeighborItems";

// TODO: Implement navigation controls
export default PageViewHires.extend({

  initDone: function () {
    this._super();

    this.neighborItems = new ApprovalNeighborItems(this);
  },

  firstTickReceived: function (data) {
    this.neighborItems.firstTickReceived(data);

    this._super(data);
  },

  tickUpdate: function (data) {
    this._super(data);

    this.neighborItems.tickUpdate(data);
  },

});