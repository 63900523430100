import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/common/buttons/IconButton';
import { translate } from 'core/services/localization';

export default class GenericHeader extends Component {
  render() {
    const { captionRenderer, filterRenderer, showContextMenuIcon, onContextMenu } = this.props;

    return (
      <div className='column column-header'>
        <div className='column-generic-header'>
          <div className='column-header-content'>
            {showContextMenuIcon && <IconButton
              key='edit'
              className='context-menu-icon'
              iconName='more_vert_12'
              tooltip={translate('Table properties')}
              onClick={onContextMenu} />
            }
            {captionRenderer}
            {filterRenderer}
          </div>
        </div>
      </div>
    );
  }
}

GenericHeader.propTypes = {
  captionRenderer: PropTypes.element,
  filterRenderer: PropTypes.element,
  showContextMenuIcon: PropTypes.bool,
  onContextMenu: PropTypes.func
};