import React, { Component } from 'react';
import { Form } from 'components/private/forms';
import Button from 'components/common/buttons/Button';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';

const isFunction = o => typeof o === 'function';

const translate = sandbox.localization.translate;

class SetupContainer extends Component {

  static propTypes = {
    model: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array
    ]),
    buttons: PropTypes.array,
    loading: PropTypes.bool,
    onClose: PropTypes.func,
    apply: PropTypes.func,
  };

  static defaultProps = {
    buttons: [
      { name: 'ok', label: translate('OK'), shouldSave: true, shouldClose: true, isApplicable: ({ loading }) => !!loading },
      { name: 'cancel', label: translate('Cancel'), shouldSave: false, shouldClose: true }
    ],
    loading: false
  };

  /**
   * The form causes page reload ob submit. To prevent that handleFormSubmit will trigger preventDefault so it won't cause the page reload.
   */
  handleFormSubmit = (event) => {
    event.preventDefault();
  };

  handleButtonClick = (button) => {
    const { onClose } = this.props;

    if (!button.shouldSave && button.shouldClose && typeof onClose === 'function') {
      onClose();
    } else {
      this.props.apply(button.name, button.shouldSave, button.shouldClose);
    }
  };

  renderButton = (button, index) => {
    const disabled = isFunction(button.isApplicable) ? button.isApplicable(this.props) : false;

    return <Button key={index} className={button.name === 'ok' ? 'primary' : ''} onClick={e => this.handleButtonClick(button)} disabled={disabled}>
      {button.label}
    </Button>;
  };

  renderButtons() {
    const { buttons } = this.props;
    return buttons.map(this.renderButton);
  };

  render() {
    const { children } = this.props;

    return <form autoComplete="off" className="crtx-setup-container" onSubmit={this.handleFormSubmit}>
      <div className="crtx-setup-content">{children}</div>
      <div className="crtx-setup-footer crtx-align-content-right">
        {this.renderButtons()}
      </div>
    </form>;
  }

}

export default Form(SetupContainer, model => model, model => model);