import React, { Component } from 'react';
import PropTypes from 'prop-types';
import utils from './utils';

export default (DecoratedComponent) => class extends Component {
  static contextTypes = {

    store: PropTypes.object

  };

  static defaultProps = {
    col: '12',
    offset: '0',
    flex: null,
    visible: true
  };

  render() {
    var { children, col, offset, flex, visible, fullHeight, ...props } = this.props;
    var { store } = this.context;
    var containerClassName = utils.combineClassNames(['col',
      utils.getColumnSizeClassName(col),
      utils.getColumnOffsetClassName(offset),
      utils.getColumnFullHeightClassName(fullHeight)
    ]);
    var containerStyle = {
      display: visible === false ? 'none' : null,
      flex: flex
    };

    return <div className={containerClassName} style={containerStyle}>
      <DecoratedComponent {...this.props} store={store}>
        {children}
      </DecoratedComponent>
    </div>;
  }
};
