import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useBatchAddListener,
  useItemFinalizeListener,
  useItemFinishListener,
  useItemProgressListener,
  useItemStartListener,
  useRequestPreSend
} from '@rpldy/uploady';
import { IconButton } from 'components/common/buttons';
import { translate } from 'core/services/localization';
import { asUploadButton } from '@rpldy/upload-button';

const CustomUploadButton = asUploadButton(props => {
  const { onClick, handleClick } = props;
  return <IconButton
    key='upload'
    iconName='upload'
    tooltip={translate('Upload')}
    onClick={() => handleClick(onClick)}
  />;
});

export const UploadButtonComponent = (
  {
    target,
    targetId,
    itemStartHandler,
    itemProgressHandler,
    itemFinishHandler,
    finalizeHandler,
    buttonClickHandler,
    permissionToUpload
  }) => {

  const [batchIdState, setBatchIdState] = useState('');

  const handleClick = originalClickHandler => {
    buttonClickHandler();
    originalClickHandler();
  };

  useRequestPreSend(({ items, options }) => {
    if (options.params.targetId !== targetId) {
      return Promise.resolve(false);
    }
    const customParams = { ...options.params.customParams, ...target };
    const item = items[0];
    return {
      options: {
        params: {
          customParams: JSON.stringify(customParams),
          qqfilename: item.file.name,
          qqtotalfilesize: item.file.size,
          qquuid: item.id
        }
      }
    };
  });

  useBatchAddListener((batch, options) => {
    if (options.params.targetId === targetId) {
      setBatchIdState(batch.id);
    }
  });

  useItemStartListener((item, options) => {
    if (item.file.type !== 'application/pdf' || !permissionToUpload) {
      return false;
    }

    if (typeof itemStartHandler === 'function' && options.params.targetId === targetId && item.batchId === batchIdState) {
      itemStartHandler();
    }
  });

  useItemProgressListener(item => {
    if (typeof itemProgressHandler === 'function' && item.batchId === batchIdState) {
      itemProgressHandler(item);
    }
  });

  useItemFinishListener((item, options) => {
    if (typeof itemFinishHandler === 'function' && options.params.targetId === targetId && item.batchId === batchIdState) {
      itemFinishHandler(item);
    }
  });

  useItemFinalizeListener((item, options) => {
    if (typeof finalizeHandler === 'function' && options.params.targetId === targetId && item.batchId === batchIdState) {
      finalizeHandler();
      setBatchIdState('');
    }
  });

  return <CustomUploadButton extraProps={{ handleClick }} params={{ targetId }} />;
};

UploadButtonComponent.propTypes = {
  target: PropTypes.object,
  targetId: PropTypes.string,
  itemStartHandler: PropTypes.func,
  itemProgressHandler: PropTypes.func,
  itemFinishHandler: PropTypes.func,
  finalizeHandler: PropTypes.func,
  buttonClickHandler: PropTypes.func,
  onClick: PropTypes.func,
  permissionToUpload: PropTypes.bool,
};