define(["./actions"],
  function(actionsController){
    'use strict';
	function addRemovedColor (page, color){
      if (!page.removedColors){
        page.removedColors = {};
      }
      var removedColors = page.removedColors;
      removedColors[color] = page.separations[color];
    }
    
    function addColor(page, sourcePage, color){
      page.separations[color] = {sourceId: this.modelHandler.sourceIdBreadcrumb ? page.sourceId + "/" + color : page.sourceId + color};
      page.separations[color].sourceId = page.separations[color].sourceId.toLowerCase();
      if (page.sourceId !== sourcePage.sourceId){
        page.separations[color].masterZone = page.masterZone;
        page.separations[color].masterSeparation = sourcePage.separations[color].sourceId;
        page.separations[color].masterPageZone = page.masterPageZone;
      }
    }

    function removeColor(page, color){
      delete page.separations[color];
    }

    function pageColorAction(sourcePage, colorMode){
      var colorObjects = this.modelHandler.model.publication.colorObjects;
      if (sourcePage.masterPage !== null && typeof sourcePage.masterPage !== "undefined"){
        return pageColorAction.call(this.modelHandler.mappedModel[sourcePage.masterPage]);
      }

      var followers = this.findFollowers(sourcePage);
      if (colorMode === "K"){
        var seps = {};
        for (var sepName in sourcePage.separations){
          if (sepName === colorObjects["black"]){
            seps[sepName] = sourcePage.separations[sepName];            
          } else {
            addRemovedColor(sourcePage, sepName);
          }
        }
        sourcePage.separations = seps;
        for (var i=0;i<followers.length;i++){
          seps = {};
          for (var sepName in followers[i].separations){
            if (sepName === colorObjects["black"]){
              seps[sepName] = followers[i].separations[sepName];
            } else {
              addRemovedColor(followers[i], sepName);
            }
          }
          followers[i].separations = seps;
        }
      } else {
        var needed = [colorObjects["cyan"], colorObjects["yellow"], colorObjects["magenta"], colorObjects["black"]]
        for (var i=0;i<needed.length;i++){
          var sepName = needed[i];

          seps = sourcePage.separations;
          if (!sourcePage.separations.hasOwnProperty(sepName)){
            seps[sepName] = {sourceId: this.modelHandler.sourceIdBreadcrumb? sourcePage.sourceId + "/" + sepName : sourcePage.sourceId + sepName};
            seps[sepName].sourceId = seps[sepName].sourceId.toLowerCase();
          }


          for (var j=0;j<followers.length;j++){
            seps = followers[j].separations;
            if (!followers[j].separations.hasOwnProperty(sepName)){
              seps[sepName] = {sourceId:this.modelHandler.sourceIdBreadcrumb ? followers[j].sourceId + "/" + sepName : followers[j].sourceId + sepName};
              seps[sepName].sourceId = seps[sepName].sourceId.toLowerCase();
              seps[sepName].masterZone = followers[j].masterZone;
              seps[sepName].masterSeparation = sourcePage.separations[sepName].sourceId;
              seps[sepName].masterPageZone = followers[j].masterPageZone;

            }
          }

        }
      }

      return [];
    }



    function changeColor (color, state){
      var pages = this.filterSelectedLinks();
      if (pages.length === 0)
        return;
      var ret = [];
      for (var i=0;i<pages.length;i++){
        var sourcePage = pages[i];
        var followers = this.findFollowers(sourcePage);
        followers.unshift(sourcePage);
        for (var j=0;j<followers.length;j++) {
          var page = followers[j];
          if (state) {
            addColor.call(this, page, sourcePage, color);
          } else {
            removeColor.call(this, page, color);
          }
        }
      }
      return [];
    }

    function pageColorActionController(){
      var pages = this.filterSelectedLinks();
      var ret = [], counter = 0, matching = true;
      if (pages.length === 0)
        return;

      for (var i=0;i<pages.length;i++) {
        var page = pages[i];//.props.page;
        var sepsNumber = Object.keys(page.separations).length;
        if (counter > 0 && sepsNumber !== counter){
          matching = false;
          break;
        }
      }
      var colorMode = "CMYK";
      if (matching){
        var page = pages[0];//.props.page
        colorMode = Object.keys(page.separations).length > 1 ? "K" : "CMYK";
      }
      for (var i=0;i<pages.length;i++) {
        var page = pages[i];//.props.page;
        pageColorAction.call(this, page, colorMode);
        ret.push(pages[i]);
      }
      //this.selectedPages[0].fireSectionSizeChange({});
      return [];
    }

    function getColors(){
      var ret = {}
      var pages = this.filterSelectedLinks();
      for (var i=0;i<pages.length;i++) {
        var page = pages[i];//.props.page;
        for (var sepName in page.separations){
          if (!ret.hasOwnProperty(sepName)){
            ret[sepName] = true;
          }
        }
      }
      return ret;
    }


    return {
      pageColorActionController: pageColorActionController,
      changeColor: changeColor,
      getColors: getColors
    };

  })
;
