/**
 * Created by moshemal on 6/2/15.
 */

define(['core/managers/shortcut', 'core/services/localization', 'text!./templates/dialog.html', 'kendo.window'],
function(shortcut, localizationService, dialogTemplate){
  'use strict';

  var DIALOG_MIN_HEIGHT = "150px",
      DIALOG_WIDTH      = "510px",
      CONTENT_CLASS     = ".npg-dialog-content",
      FOOTER_CLASS      = ".npg-dialog-footer";

  function closeHandler(){
    shortcut.unregister('Esc','dialog');
    this.closeCallbacks.forEach(function(func){func();});
    this._dialog.destroy();
  }

  function setDefaults(initObj){
    initObj.minHeight = initObj.minHeight || DIALOG_MIN_HEIGHT;
    initObj.height    = initObj.height || DIALOG_MIN_HEIGHT;
    initObj.width     = initObj.width || DIALOG_WIDTH;
    initObj.title     = initObj.title ? localizationService.translate(initObj.title) : "";
    initObj.model     = initObj.model !== undefined ? initObj.model : true;
    initObj.resizable = initObj.resizable !== undefined ? initObj.resizable : true;
    initObj.animation = initObj.animation !== undefined ? initObj.animation : false;
  }


  function init(initObj){
    initObj = initObj ? initObj : {};
    setDefaults(initObj);

    var $div = $(dialogTemplate);
    initObj.close = closeHandler.bind(this);
    shortcut.register('Esc','dialog', initObj.close);

    this.closeCallbacks = [];
    this.contentElement = $div.find(CONTENT_CLASS)[0];
    this.$Element = $div;
    //creating the kendo window
    $div.kendoWindow(initObj);
    var dialog = this._dialog = $div.data("kendoWindow");
    dialog.open();
    dialog.center();
  }

  function appendButton (name, callback, isClosing, data){
    var button, buttonsContainer;
    var that = this;
    buttonsContainer = this.$Element.find(FOOTER_CLASS);
    //for the first append
    name = localizationService.translate(name);
    button = $('<button class="crtx-button">' + name + '</button>');

    function listenCallback(ev){
      var result = true;
      if (typeof callback === 'function'){
        result = callback(ev);
      }
      if (result === false || ((isClosing !== undefined) && !isClosing)){
        return;
      }
      that._dialog.close();
    }
    button.on("click", null, data, listenCallback);
    this.addCloseCallback(function(){
      button.off();
    });
    buttonsContainer.append(button);
    return button[0];
  }

  return Class.extend({
    init: init,
    addCloseCallback: function(func){
      this.closeCallbacks.push(func);
    },
    appendButton: appendButton,
    open: function(){this._dialog.open()},
    close: function(){this._dialog.close()}
  }, "Dialog");

});
