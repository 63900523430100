import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';

const isUndefined = o => typeof o === 'undefined';

export default class PreApprovedIndicator extends Component {
  static propTypes = {
    preApproved: PropTypes.bool,
    title: PropTypes.string
  };

  static defaultProps = {
    preApproved: false,
    title: ''
  };

  render() {
    const preApproved = this.props.preApproved;
    const src = preApproved ? sandbox.icons.getModuleIcon('MyBirdeye', 'preApproved', '.svg') : undefined,
      title = this.props.title;

    return isUndefined(src) ? <span></span> : <img src={src} title={title} />;
  }
}