/**
 * Created by moshemal on 8/31/14.
 */

define(['core'],function(core){
	'use strict'
	return {
		getSystemIcon: function (iconName) {
			return core.service.icons.getSystemIcon.apply(core, arguments);
		},
		getActionIcon: function() {
			return core.service.icons.getActionIcon.apply(core, arguments);
		},
		getGeneralIcon: function (type, iconName, extension) {
			return core.service.icons.getGeneralIcon.apply(core, arguments);
		},
		getModuleIcon: function (moduleName, iconName, extension) {
			return core.service.icons.getModuleIcon.apply(core, arguments);
		},
		getTemplateIcon: function (type, size, iconName) {
			return core.service.icons.getTemplateIcon.apply(core, arguments);
		},
		getSVGIcons: function (types, callback, ctx) {
			return core.service.icons.getSVGIcons.apply(core, arguments);
		},
		getSVGIconPath: function (type){
			return core.service.icons.getSVGIconPath.apply(core, arguments);
		},
		getTemplateSvgIconHref: function (type){
		return core.service.icons.getTemplateSvgIconHref.apply(core, arguments);
		},
		getModuleSvgIconHref: function (moduleName, iconName){
			return core.service.icons.getModuleSvgIconHref.apply(core, arguments);
		},		
	};
});