/**
 * @name
 * @fileOverview
 * @author sergey & Elad
 */
define(['ember', 'text!../templates/formsMain.hbs', './Book','kendo-ember', './../../../utils/wizardCommons'],
  function (Ember, MainTemplate, bookView, kendoComponents, wizardCommons) {
    'use strict';

    return Ember.View.extend(wizardCommons.viewCommons, {
      onActivate: function(){

			},

      template: Ember.Handlebars.compile(MainTemplate),

      bookView : bookView,
      
      books: function () {
        return this.controller.model.books.filter(function(book) {
          return !book.hidden;
        });;
      }.property ("controller.model.books")




    });
  });