import Input from './Input';
import ConverterDecorator from '../decorators/Converter';
import UnitsConverter from '../converters/UnitsConverter';
import MaskerDecorator from '../decorators/Masker';
import NumericMasker from '../maskers/NumericMasker';

const mmUnits = {
  factor: 25.4,
  precision: 3
};
const inchUnits = {
  factor: 1,
  precision: 4
};
const converterOptions = {
  'mm': mmUnits,
  'in': inchUnits,
  'inch': inchUnits
};
const getConverterOptions = units => {
  return converterOptions[units] || inchUnits;
};

const floatMask = {
  regex: /^-?\d*\.?\d*$/
};
const posFloatMask = {
  regex: /^\d*\.?\d*$/
};

const maskerOptions = {
  'float': floatMask,
  'posFloat': posFloatMask
};
const getMaskerOptions = mask => {
  return maskerOptions[mask] || floatMask;
};

export default MaskerDecorator(
  ConverterDecorator(
    Input,
    UnitsConverter,
    getConverterOptions,
    'units'
  ),
  NumericMasker,
  getMaskerOptions
);
