import * as actionTypes from './actionTypes';

export default function reducer(state, action) {
    switch (action.type) {
        case actionTypes.INITIAL_LOAD_SUCCESS:
            const selectionOptions = action.resData && action.resData.data ? action.resData.data.map(r => ({ text: r.value, value: r.nwid })) : [];
            return { ...state, loading: false, ...action.resData, selectionOptions };
        case actionTypes.ZONE_SELECT:
            return { ...state, selectedValue: action.value };
        case actionTypes.LOAD_TEMPLATE_ON_SELECT:
            return { ...state, htmlToPrint: action.resData };
        default:
            return state;
    }
}
