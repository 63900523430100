/**
 * @name
 * @fileOverview Definition of abstract module class
 * @author sergey
 */

import base from 'base';
import { translate } from 'core/services/localization';
import sandbox from 'sandbox';
import Class from 'class';
import ContextMenu from 'ContextMenuWidget';
import logger from 'logger';
import Selection from 'modules/Selection';
import pubsub from 'core/managers/pubsub';
import Toolbar2 from 'widgets/Toolbar2/Toolbar';
import Footer from 'widgets/Footer/Footer';
import viewManager from 'core/managers/views';
import actionsManager from 'core/managers/actions';
import { placeholderManager, Placeholder } from 'core/placeholders';
import { getDefaultViewsPreferences } from 'core/managers/preferences';
import { startModule } from 'core/managers/module';
import { toViewRootParams, getViewRoot } from 'utilities/view';

const appUtils = sandbox.appUtils;
const log = logger.getDefaultLogger();

const USE_PREDEFINED_PLACEHOLDERS = false;
const AGGREGATED_ACTIONS = ['OpenPageEditorCR'];
const TEST_MODE = false; //*** Enable test mode

// ====================== PRIVATE HELPER FUNCTIONS =================================

function filterAndSortActions(actions, trigger, type) {
  let resultActions = actions;

  if (trigger) {
    resultActions = resultActions.filter(a => a.triggers.indexOf(trigger) >= 0);
  }

  if (type) {
    resultActions = filterActionsByType(resultActions, type);
  }

  resultActions = appUtils.sortByOrderAndLabel(resultActions);

  return resultActions;
}

/**
 * Filters the given array of actions by type
 * @param {Array}   actions   List of actions
 * @param {String}  type      Model item type
 * @returns {Array}           Filtered actions by type
 */
function filterActionsByType(actions, type) {
  // filtering by type is divided into two cases:
  // 1. type of action is an exact match to the type of an item
  // 2. type of action includes type of selected item:
  // e.g.: action is applicable for objects with type 'controlpanel/plates/*',
  // the type of the selected item is 'controlpanel/plates/templates',
  // in this case the action can be applied to the selected object

  var relevantActions = [];

  // sort actions by type
  var sortByType = (function () {
    var sorted = {};
    for (var i = 0; i < actions.length; i++) {
      var types = [],
        action = actions[i];
      if (action.parameters && action.parameters.types) {
        types = action.parameters.types;
      } else if (action.config && action.config.types) {
        types = action.config.types;
      } else {
        // in case action is global
        if (!sorted.global) {
          sorted.global = [];
        }
        sorted.global.push(action);
      }
      types.reduce(function (prev, curr) {
        if (!prev[curr]) {
          prev[curr] = [];
        }
        prev[curr].push(action);
        return prev;
      }, sorted);
    }
    return sorted;
  })();

  for (var key in sortByType) {
    if (key === type) {
      relevantActions = relevantActions.concat(sortByType[key]);
    } else if (key.indexOf('*') !== -1) {
      // "*" check
      var m = key.substring(0, key.indexOf('*') - 1);
      if (type.match(m)) {
        relevantActions = relevantActions.concat(sortByType[key]);
      }
    }
  }
  if (typeof sortByType['global'] !== 'undefined') {
    relevantActions = relevantActions.concat(sortByType['global']);
  }
  return relevantActions;
}

// =============================== PRIVATE FUNCTIONS ===================================

/**
 * Returns the array of parsed view links' objects
 * @param clickedItem
 * @param trigger - 'popup' | 'toolbar', where 'toolbar' is default
 * @returns {*}
 */
function _getRelevantViews(clickedItem, trigger) {
  if (!clickedItem || !clickedItem.viewLinks || clickedItem.viewLinks.length <= 0) {
    return [];
  }

  // in case view links are array-like object, convert it into regular array
  const viewLinks = Array.isArray(clickedItem.viewLinks) ? clickedItem.viewLinks : [...clickedItem.viewLinks];

  let views = viewLinks.map(viewLink => viewManager.getViewInfo(viewLink));
  if (trigger) {
    views = views.filter(v => v.triggers.indexOf(trigger) >= 0);
  }

  return appUtils.sortByOrderAndLabel(views);
}

/**
 * Returns the parsed view link that is default
 */
function getDefaultView(clickedItem) {
  let defaultView = null;
  const viewInfo = getDefaultViewInfo(clickedItem.type);
  const views = _getRelevantViews(clickedItem);
  for (var i = 0; i < views.length; i++) {
    if (viewInfo && views[i].nwid === viewInfo.nwid) {
      defaultView = views[i];
      break;
    }
  }

  if (defaultView == null) {
    for (var i = 0; i < views.length; i++) {
      if (views[i].triggers.indexOf('toolbar') >= 0) {
        defaultView = views[i];
        break;
      }
    }
  }

  return defaultView;
}

function getDefaultViewInfo(rootType) {
  const defaultViews = getDefaultViewsPreferences();

  return defaultViews.find(view => view.rootType === rootType);
}

/**
 * Decorate passed firstTickReceived() function.
 * This method is called whenever the first tick is received
 *
 * @param func Target function to be decorated
 */
function decorateFirstTickReceived(func) {
  return function () {
    func.apply(this, arguments);

    if (TEST_MODE) {
      const model = arguments[0].model;
      let allActions = this.getRelevantActions(model);
      if (model && model.children && model.children[0]) {
        allActions = this.getRelevantActions(model.children[0]);
      }
    }

    if (this.toolbar) {
      const model = arguments[0].model;
      if (model && model.actionLinks) {
        this.updateRootPlaceholders(model);
      } else {
        this.toolbar.refreshIsApplicableProperty();
      }
    }
  }.bind(this);
}

/**
 * Decorate passed tickUpdate() function.
 * This method is called whenever the tick update is received
 *
 * @param func Target function to be decorated
 */
function decorateTickUpdate(func) {
  return function () {
    func.apply(this, arguments);
    if (this.toolbar) {
      this.toolbar.refreshIsApplicableProperty();
    }
  }.bind(this);
}


function getSelectedMainModuleId() {
  return sandbox.getSelectedMainModuleId();
}


function decorateWithIsInStandbyMode(func) {
  var didRender = false;
  return function isInStandModeDecoratedFunction() {
    var selectedMainModuleID = getSelectedMainModuleId();
    var shouldRenderOnFirstTime = this.allowStandbyMode === true && !didRender && !this.shouldRenderOnFirstTime ? true : false;
    if (shouldRenderOnFirstTime || !selectedMainModuleID || this.id === parseInt(selectedMainModuleID)) {
      func.apply(this, arguments);
      didRender = true;
    }
  }.bind(this);
}

// ================================= PUBLIC CLASS DEFINITION ==============================

function isMounted() {
  return sandbox.dom.find(this.element).length > 0 ? true : false;
}

class ModuleTabState {
  constructor(module) {
    this.module = module;
  }

  setState(state) {
    if (!this.module.tab) {
      return;
    }

    const tabState = this.module.tab.getState();
    const moduleStates = tabState.moduleStates || {};
    moduleStates[this.module.id] = { ...moduleStates[this.module.id], ...state };
    tabState.moduleStates = moduleStates;
    this.module.tab.setState(tabState);
  }

  setError(error) {
    if (!this.module.tab) {
      return;
    }

    if (error) {
      this.setState({ error });
    } else {
      this.clearError();
    }
  }

  clearError() {
    if (!this.module.tab) {
      return;
    }

    const state = this.module.tab.getState();
    const moduleState = (state.moduleStates || {})[this.module.id] || {};
    if (moduleState.error) {
      delete moduleState.error;
      this.module.tab.setState(state);
    }
  }

  clearState() {
    if (!this.module.tab) {
      return;
    }

    const state = this.module.tab.getState();
    if (state.moduleStates && state.moduleStates[this.module.id]) {
      delete state.moduleStates[this.module.id];
      this.module.tab.setState(state);
    }

  }
}

module.exports = Class.extend({
  init: function () {
    Object.defineProperty(this, 'selected', {
      configurable: false,
      enumerable: true,
      writable: false,
      value: []
    });

    if (sandbox.jsUtils.isFunction(this.firstTickReceived)) {
      this.firstTickReceived = decorateFirstTickReceived.call(this, this.firstTickReceived);
    }

    if (sandbox.jsUtils.isFunction(this.tickUpdate)) {
      this.tickUpdate = decorateTickUpdate.call(this, this.tickUpdate);
    }

    if ((this.allowStandbyMode === true || this.allowStandbyMode === 'visible_only') && sandbox.jsUtils.isFunction(this.render)) {
      this.render = decorateWithIsInStandbyMode.call(this, this.render);
    }

    this.moduleTabState = new ModuleTabState(this);
  },

  /**
   * Return mandatory parameters for any http
   * request performed from the module
   *
   * @returns {{nwid: *, projectorId: *}}
   */
  getRequiredParameters: function () {
    var requiredParams = {
      nwid: this.nwid,
      projectorId: this.projectorId
    };
    if (typeof this.viewSettings.rootId !== 'undefined') {
      requiredParams.rootId = this.viewSettings.rootId;
    }
    if (typeof this.viewSettings.rootType !== 'undefined') {
      requiredParams.rootType = this.viewSettings.rootType;
    }
    return requiredParams;
  },

  /**
   * Perform navigation is in charge of opening
   * new modules according to the clicked item
   * @param {Object} clickedItem  Model of the clicked item
   * @param {Object} overwrites   Set of properties that should be overridden with new values
   * @param {Object} event        Mouse event
   */
  navigateByViewLink: function (clickedItem, overwrites, event) {
    if (!sandbox.jsUtils.isObject(clickedItem)) {
      log.error('Perform navigation: clickedItem should be an object');
      return;
    }
    if (sandbox.jsUtils.isObject(overwrites) && typeof overwrites.nwid !== "undefined") {
      log.error('Perform navigation: nwid cannot be overwritten');
      return false;
    }

    // 1. ask the desktop facade for associated containers
    // for now it's not implemented, so we will skip this part
    // TODO implement retrieval of associated containers
    let defaultView = getDefaultView(clickedItem);
    if (!defaultView) {
      log.error('Get default view: no default view found');
      return false;
    }

    const startParams = {
      ...defaultView,
      ...toViewRootParams(clickedItem),
      ...overwrites
    };

    return startModule(defaultView.nwid, this.id, startParams, event);
  },

  navigateByViewClass: function (clickedItem, viewClass, overwrites) {
    if (!sandbox.jsUtils.isObject(clickedItem)) {
      log.error('Perform navigation: clickedItem should be an object');
      return;
    }
    var viewLinks = clickedItem.viewLinks.map(function (link) {
      return viewManager.getViewInfo(link);
    });
    var filtered = viewLinks.filter(function (link) {
      return link.viewClass.split(".").indexOf("BinderView") >= 0;
    });

    if (filtered.length === 0) {
      log.error('no viewClass: ' + viewClass + " in viewLinks");
      return false;
    }

    const view = {
      ...filtered[0],
      ...toViewRootParams(clickedItem),
      ...overwrites
    };

    return startModule(view.nwid, this.id, view);
  },

  /**
   * Performs navigation to the particular
   * module based on viewAction array
   * @param {object} - clickedItem
   * @param {string} actionDefinitionName - action definition name by which the relevant action can be found
   */
  navigateByAction: function (clickedItem, actionDefinitionName) {

    var relevantAction, getRelevantAction, deduceRelevantAction;

    getRelevantAction = function (viewActions, actionDefinitionName) {
      return viewActions.reduce(function (prev, curr) {
        if (curr.actionDefinitionName === actionDefinitionName) {
          prev.push(curr);
        }
        return prev;
      }, [])[0];
    };

    deduceRelevantAction = function (viewActions) {
      return viewActions
        .filter(function (item) {
          return item.actionClass === 'OpenModuleAction';
        })
        .reduce(function (prev, curr) {
          if (curr.actionDefinitionName.toLowerCase().search('edit') !== -1) {
            prev.push(curr);
          }
          return prev;
        }, [])[0];
    };

    if (!sandbox.jsUtils.isObject(clickedItem)) {
      log.error('Perform navigation: clickedItem should be an object');
      return;
    }
    if (actionDefinitionName !== void 0) {
      relevantAction = getRelevantAction(this.viewActions, actionDefinitionName);
    } else {
      relevantAction = deduceRelevantAction(this.viewActions);
    }
    if (sandbox.jsUtils.isObject(relevantAction)) {
      return relevantAction.execute([clickedItem]);
    } else {
      log.error('Perform navigation: relevant action is not found');
      return;
    }
  },


  /**
   * Performs navigation to the default view
   * according to view links from the overview
   * @returns {*}
   */
  navigateFromOverview: function (event) {
    const viewRoot = { ...getViewRoot(this), viewLinks: this.viewLinks };

    return this.navigateByViewLink(viewRoot, { target: 'new' }, event);
  },

  createToolbar: function () {
    this.toolbar = new Toolbar2(this);

    const placeholdersInfo = this.getToolbarPlaceholdersInfo();
    placeholdersInfo.forEach(info => {
      const placeholder = new Placeholder(info);
      this.toolbar.addItem(placeholder);
    });

    return this.toolbar;
  },

  createFooter: function () {
    if (!this.footer) {
      this.footer = new Footer(this);
    }

    return this.footer;
  },

  getRelevantViews: function (clickedItem, trigger) {
    return _getRelevantViews(clickedItem, trigger);
  },

  /**
   * This function returns the array of applicable actions given the model item.
   * Relevant actions are determined based on model item action links and provided trigger.
   *
   * @param {object} modelItem - model item, e.g. clicked item
   * @return {Array} List of actions linked to the given item
   */
  getRelevantActions: function (modelItem, trigger) {
    if (!modelItem || !Array.isArray(modelItem.actionLinks)) {
      return [];
    }

    const actionMap = this.updateActionMap([modelItem]);
    const actions = modelItem.actionLinks.reduce((acc, nwid) => {
      const action = actionMap[nwid];
      if (action && (!trigger || action.triggers.indexOf(trigger) >= 0)) {
        acc.push(action);
      }

      return acc;
    }, []);

    // *** TODO: remove sorting, because sorting will be defined by order of placeholders
    const sortedActions = appUtils.sortByOrderAndLabel(actions);

    return sortedActions;
  },

  updateRootPlaceholders: function (model) {
    if (!model || !model.actionLinks || !this.toolbar) {
      return;
    }

    const actions = this.getRelevantActions(model, 'toolbar');
    const actionObjects = actions.map(a => ({ action: a, targetItems: [model] }));
    this.toolbar.updatePlaceholders(actionObjects, 'root');
  },

  /**
   * This method should be called every time the array of selected items is changed.
   * Action links are obtained from the selectedItems array and corresponding action instances are created and
   * added to the actionMap if they are not already present in the actionMap.
   *
   * @param {Array} selectedItems
   */
  updateActionMap: function (selectedItems) {
    this.actionMap = selectedItems.reduce((acc, item) => {
      (item.actionLinks || []).forEach(nwid => {
        if (!acc[nwid]) {
          acc[nwid] = actionsManager.createAction(nwid, this);
        }
      });

      return acc;
    }, this.actionMap || {});

    return this.actionMap;
  },

  groupModelItemsByServerActions: function (modelItems, trigger) {
    this.updateActionMap(modelItems);

    const modelItemsByActionNwids = modelItems.reduce((acc, item) => {
      (item.actionLinks || []).forEach(nwid => {
        const action = this.actionMap[nwid];
        if (action && (!trigger || action.triggers.indexOf(trigger) >= 0)) {
          if (!acc[nwid]) {
            acc[nwid] = [];
          }

          acc[nwid].push(item);
        }

      });

      return acc;
    }, {});

    const actionObjects = Object.keys(modelItemsByActionNwids).map(nwid => {
      return {
        action: this.actionMap[nwid],
        targetItems: modelItemsByActionNwids[nwid]
      };
    });

    return actionObjects;
  },

  groupModelItemsByClientActions: function (modelItems, trigger) {
    const actionObjects = (this.clientActions || []).reduce((acc, action) => {
      if (!trigger || action.triggers.indexOf(trigger) >= 0) {
        const targetItems = modelItems.reduce((acc2, item) => {
          if (!action.types || action.types.indexOf(item.type) >= 0) {
            acc2.push(item);
          }

          return acc2;
        }, []);

        if (targetItems.length > 0) {
          acc.push({
            action,
            targetItems
          });
        }
      }

      return acc;
    }, []);

    return actionObjects;
  },

  getToolbarPlaceholdersInfo: function () {
    let placeholdersInfo = [];
    if (USE_PREDEFINED_PLACEHOLDERS) {
      // New mechanism
      const toolbarName = 'birdeye'; // test
      placeholdersInfo = placeholderManager.getToolbarPlaceholdersInfo(toolbarName);
    } else {
      // TODO: Obsolete mechanism, should be removed
      const actions = filterAndSortActions(this.viewActions, 'toolbar');

      actions.forEach(action => {
        let info = {
          type: 'action',
          name: action.actionDefinitionName,
          context: action.actionContext,
          icon: action.icon,
          label: action.label,
          tooltip: action.label,
          alignRight: action.alignRight, // TODO: remove this property
        };

        let params = action.parameters;
        if (params && params.type === 'toggle') {
          info = {
            ...info,
            itemType: 'toggle',
            iconOn: params.iconOn,
            iconOff: params.iconOff,
            tooltipOn: params.labelOn,
            tooltipOff: params.labelOff,
          };
        }

        if (placeholdersInfo.findIndex(ph => ph.label === info.label) < 0) {
          placeholdersInfo.push(info);
        }
      });
    }

    return placeholdersInfo;
  },

  getContextMenuPlaceholdersInfo: function (clickedItem) {
    let placeholdersInfo = [];
    if (USE_PREDEFINED_PLACEHOLDERS) {
      // New mechanism
      const contextMenuName = 'birdeye'; // test
      placeholdersInfo = placeholderManager.getContextMenuPlaceholdersInfo(contextMenuName, clickedItem.type);
    } else {
      // TODO: Obsolete mechanism, should be removed

      const popupClientActions = (this.clientActions || []).filter(
        a => a.triggers.indexOf('popup') >= 0 && (!a.types || a.types.indexOf(clickedItem.type) >= 0));
      if (popupClientActions.length > 0) {
        popupClientActions.forEach(a => {
          placeholdersInfo.push({
            type: 'clientAction',
            label: a.label
          });
        });

        placeholdersInfo.push({ type: 'separator' });
      }

      const views = _getRelevantViews(clickedItem, 'popup');
      views.forEach(v => {
        placeholdersInfo.push({
          type: 'view',
          label: v.label
        });
      });

      if (views.length > 0) {
        placeholdersInfo.push({ type: 'separator' });
      }

      const actions = filterAndSortActions(this.viewActions, 'popup', clickedItem.type);

      const groups = [['Copy', 'Paste']];
      groups.forEach(group => {
        const actionGroup = [];
        group.forEach(str => {
          const idx = actions.findIndex(a => str === a.label);
          if (idx >= 0) {
            const a = actions[idx];
            actions.splice(idx, 1);
            actionGroup.push(a);
          }
        });

        if (actionGroup.length === group.length) {
          actionGroup.forEach(a => placeholdersInfo.push({
            type: 'action',
            label: a.label
          }));

          placeholdersInfo.push({ type: 'separator' });
        }
      });

      actions.forEach(a => {
        placeholdersInfo.push({
          type: 'action',
          label: a.label
        });
      });
    }

    return placeholdersInfo;
  },

  createContextMenuPlaceholders: function (clickedItem, selectedItems) {
    this.updateActionMap(selectedItems); // ***TEMP, should be removed from here

    const views = _getRelevantViews(clickedItem, 'popup');
    const selectedItemsByServerActions = this.groupModelItemsByServerActions(selectedItems, 'popup');
    const selectedItemsByClientActions = this.groupModelItemsByClientActions(selectedItems, 'popup');

    const placeholdersInfo = this.getContextMenuPlaceholdersInfo(clickedItem);
    let placeholders = placeholdersInfo.map(info => {
      const ph = new Placeholder(info);
      if (ph.type === 'view') {
        const view = views.find(v => v.label === ph.label);
        if (view) {
          ph.view = {
            ...view,
            ...toViewRootParams(clickedItem),
          };
        }
      } else if (ph.type === 'clientAction') {
        const actionObjects = selectedItemsByClientActions.reduce((acc, actionObj) => {
          if (actionObj.action.label === ph.label) {
            acc.push(actionObj);
          }

          return acc;
        }, []);

        ph.setActionObjects(actionObjects);
      } else if (ph.type === 'action') {
        const actionObjects = selectedItemsByServerActions.reduce((acc, actionObj) => {
          if (actionObj.action.label === ph.label) {
            acc.push(actionObj);
          }

          return acc;
        }, []);

        ph.setActionObjects(actionObjects);
      }

      return ph;
    });

    // remove actions that do not have action links (user has no permissions to execute them)
    // in order to reduce number of visible actions in the context menu
    placeholders = placeholders.filter(ph => ph.type !== 'action' || ph.actionObjects.length > 0);

    if (selectedItems.some(item => item.aggregated)) {
      placeholders = selectedItems.length !== 1 ? [] : placeholders.filter(ph =>
        ph.type === 'action' &&
        ph.actionObjects.length === 1 &&
        AGGREGATED_ACTIONS.includes(ph.actionObjects[0].action.actionDefinitionName));
    }

    return placeholders;
  },

  /**
   * Show context menu creates a new instance of context menu widget and append it the DOM
   *
   * @param {Object}  clickedItem     Model of the clicked object
   * @param {Array}   selectedItems   List of selected items in a module
   * @param {Object}  event           jQuery object of event
   */
  showContextMenu: function (clickedItem, selectedItems, event) {
    const mainViews = _getRelevantViews(clickedItem).filter(v => v.target === 'main');

    const placeholders = this.createContextMenuPlaceholders(clickedItem, selectedItems);
    if (mainViews.length <= 0 && placeholders.length <= 0) {
      event.preventDefault();
      return base.data.deferred().reject();
    }

    const contextMenu = new ContextMenu(clickedItem, selectedItems, event);

    // add "open" part of menu that consists of views with target "main"
    if (mainViews.length > 0) {
      contextMenu.addItem(new Placeholder({
        label: translate('Open'),
        _isApplicable: true,
        execute: this.navigateByViewLink.bind(this, clickedItem, { target: 'main' })
      }));

      contextMenu.addItem(new Placeholder({
        label: translate('Open in New Tab'),
        _isApplicable: true,
        execute: this.navigateByViewLink.bind(this, clickedItem, { target: 'new' })
      }));

      contextMenu.addItem(new Placeholder({
        label: translate('Open in New Window'),
        _isApplicable: true,
        execute: this.navigateByViewLink.bind(this, clickedItem, { target: 'window' })
      }));

      contextMenu.addSeparator();
    }

    placeholders.forEach(ph => {
      if (ph.type === 'action' || ph.type === 'clientAction') {
        ph._isApplicable = ph.isApplicable();
        contextMenu.addItem(ph);
      } else if (ph.type === 'view') {
        if (ph.view) {
          ph._isApplicable = true;
          if (ph.view.target === 'main') {
            ph.view.target = 'new';
          }
          ph.execute = startModule.bind(null, ph.view.nwid, this.id, ph.view);
        } else {
          ph._isApplicable = false;
        }

        contextMenu.addItem(ph);
      } else if (ph.type === 'separator') {
        contextMenu.addSeparator();
      }
    });

    return contextMenu.show();
  },

  updateSelected: function (selectedItems) {
    this.selected.length = 0;
    if (Array.isArray(selectedItems)) {
      for (var i = 0; i < selectedItems.length; i++) {
        this.selected.push(selectedItems[i]);
      }
    }

    pubsub.publish('active-selection-changed', { selected: this.selected, moduleTarget: this.viewSettings.target });

    if (this.toolbar) {
      const actionObjects = this.groupModelItemsByServerActions(selectedItems, 'toolbar');
      this.toolbar.updatePlaceholders(actionObjects, 'selected');

      // ***TODO remove this line
      this.toolbar.refreshIsApplicableProperty();
    }
  },

  destroy: function () {
    if (typeof this.toolbar?.destroy === 'function') {
      this.toolbar.destroy();
    }
    if (typeof this.footer?.destroy === 'function') {
      this.footer.destroy();
    }
    if (typeof this.startParameters?.onDestroy === 'function') {
      this.startParameters.onDestroy();
    }

    if (this.moduleTabState) {
      this.moduleTabState.clearState();
    }
  },

  selection: Selection,

  isMounted: isMounted,

  allowStandbyMode: false

});
