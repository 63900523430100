/**
 * @name TableWidget
 * @fileOverview Wrapper over ember table widget with modified columns definitions
 * @author sergey
 */

define(['logger', 'base/dom', 'core/managers/pubsub', 'sandbox/jsUtils', 'class', 'ember',
    './helpers/EventsMixin',
    './helpers/EventsEnum',
    './helpers/TreeTableAdapter',
    './helpers/defineHelpers',
    './components/FluidTableComponent',
    'ember_table'],
  function (logger, dom, pubsub, jsutils, Class, Ember, EventsMixin, EVENTS, TreeTableAdapter, defineHelpers, FluidTableComponent) {
    'use strict';

    var log = logger.getDefaultLogger();

    var GENERAL_TABLE_SETTINGS = {
      hasHeader: true,
      hasFooter: false,
      minHeaderHeight: 35,
      rowHeight: 29 // row-content-height(28px) + border-bottom(1px)
    };

    /**
     * Prepare columns create column definitions
     * from passed object
     * @param {Array} columnsDefinitions
     * @returns {Array}
     */
    function prepareColumns(columnsDefinitions, isTreeTable) {
      if (!Array.isArray(columnsDefinitions)) {
        log.error('Table Widget: failed to create columns. Columns definitions should be array');
        return [];
      }
      if (columnsDefinitions.length === 0) {
        log.error('Table Widget: failed to create columns. Columns definitions is empty');
        return [];
      }
      //if something is wrong with the columns widths set the withs to (100% / # of columns)
      if (columnsDefinitions.some(function (item) {
          return !Boolean(item.width) || parseInt(item.width) <= 0;
        })) {
        var width = 100 / columnsDefinitions.length;
        if (isTreeTable) {
          width += "%";
        }
        columnsDefinitions.forEach(function (item) {
          item.width = width;
        });
      } else {// normalize the widths to %
        var total = 0;
        columnsDefinitions.forEach(function (item) {
          total += parseInt(item.width);
        });
        columnsDefinitions.forEach(function (item) {
          item.width = parseInt(item.width) * 100 / total;
          if (isTreeTable) {
            item.width += "%";
          }
        });
      }
      return columnsDefinitions.map(function (item) {
        return defineHelpers.defineColumn(item);
      });
    }

    function setVisibleRows(element, rowCount, rowHeight, headerHeight) {
      dom.find(element, document).css('height', rowCount * rowHeight + headerHeight);
    }

    return Class.extend({

      init: function (configuration) {
        var localGeneralTableSettings = jsutils.clone(GENERAL_TABLE_SETTINGS);

        var columns = configuration.columns;
        if (configuration.contentHeight !== void 0) {
         // setVisibleRows(configuration.element, configuration.contentHeight, localGeneralTableSettings.rowHeight, localGeneralTableSettings.minHeaderHeight);
        }
        if (configuration.hasHeader !== void 0) {
          localGeneralTableSettings.hasHeader = configuration.hasHeader;
        }
        var isTreeTable = configuration.columns.some(function (def) {
          return (def.treeColumn !== void 0) ? def.treeColumn : false;
        });
        var TableComponent = (function () {
          if (isTreeTable) {
            return Ember.Table.EmberTableComponent.extend(EventsMixin, TreeTableAdapter.create(configuration.columns));
          }
          else {
            return FluidTableComponent;
            //return Ember.Table.EmberTableComponent.extend(EventsMixin);
          }
        })();

        Ember.Table.TableRow.reopen({
          classNameBindings: ['row.content.isRowDisabled: disabled'],
          lastRowFix: function () {
            if (this.get('isLastRow')) {
              Ember.run(function () {
                this.rerender();
              }.bind(this));
            }
          }.observes('isLastRow')
        });


        this._table = TableComponent.create(jsutils.merge({
          isHeaderContextMenu: configuration.isHeaderContextMenu ? true : false,
          columns: prepareColumns(configuration.columns, isTreeTable),
          content: (isTreeTable) ? {root: {children: configuration.data}} : configuration.data,
          enableColumnReorder: (configuration.columnReorder === void 0) ? true : configuration.columnReorder,
          callbacks: {}
        }, localGeneralTableSettings));

        Ember.run.scheduleOnce('render', this, function () {
          var container, containerView, tableView;
          container = dom.find(configuration.element)[0];
          containerView = Ember.View.views[container.id];
          if (containerView === void 0) {
            containerView = Ember.ContainerView.createWithMixins(Ember.AddeparMixins.StyleBindingsMixin, {
              styleBindings: ['height'],
              height: '100%'
            });
            containerView.appendTo(configuration.element);
          }
          tableView = containerView.createChildView(this._table);
          containerView.pushObject(tableView);
        });

        this.unsubscribePaneResize = pubsub.subscribe('pane-resize', function () {
          this._table.elementSizeDidChange();
        }.bind(this));

        this.unsubscribeTabActivated = pubsub.subscribe('tab-activated', function (ev) {
          var caller, current;
          caller = (dom.find('.ember-table-tables-container', ev.contentElement) === void 0) ? void 0 : dom.find('.ember-table-tables-container', ev.contentElement)[0];
          current = (this._table.$() === void 0) ? void 0 : this._table.$()[0];
          if (caller !== void 0 && current !== void 0 && caller.id === current.id) {
            this._table.elementSizeDidChange();
          }
        }.bind(this));
      },

      EVENTS: EVENTS,
      /**
       * Provides mechanism to bind function to set of
       * defined events which is listed in EVENTS:
       * EVENTS.SELECTION_CHANGED    =>    (selection)
       * EVENTS.DBLCLICK             =>    (clickedObject)
       * EVENTS.CONTEXT_MENU         =>    (clickedObject, selection, event)
       * EVENTS.DELETE               =>    (selection)
       * EVENTS.AFTER_EDIT           =>    ()
       *
       * @param {string}    eventName Name of the event, one from the above
       * @param {function}  fn        Function to be called when event occurred
       * @param {object}    ctx       thisArg of the callback function
       */
      bind: function (eventName, fn, ctx) {
        if (!Array.isArray(this._table.get('callbacks')[eventName])) {
          this._table.get('callbacks')[eventName] = [];
        }
        this._table.get('callbacks')[eventName].push(fn.bind(ctx));
      },

      /**
       * Returns the array of currently selected objects
       * @returns {array}
       */
      getSelection: function () {
        return this._table.get('selection');
      },

      /**
       * Sets the array of selection objects
       * @param {array} selected Models of items to be selected
       */
      setSelection: function (selected) {
        this._table.get('selection').pushObjects(selected);
      },

      /**
       * Turns each cell in the row into a edit mode
       * if it's applicable
       */
      editRow: function () {

      },

      setVisibleRows: function (rowCount) {
        var parent = this._table.$().parent();
        if (parent.length > 0) {
          setVisibleRows(parent[0], rowCount, this._table.get('rowHeight'), this._table.get('minHeaderHeight'));
          this._table.elementSizeDidChange();
        }
      },

      /**
       * Cleans the instance of table widgets:
       * callback are removed, and ember-table
       * instance is destroyed
       */
      destroy: function () {
        this._table.destroy();
        this.unsubscribePaneResize();
        this.unsubscribeTabActivated();
      }
    });

  });