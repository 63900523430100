import PageViewCompare from '../PageViewCompare/PageViewCompareNew';
import ApprovalNeighborItems from "../PageViewApproval/ApprovalNeighborItems";

export default PageViewCompare.extend({

  initDone: function () {
    this._super();

    this.neighborItems = new ApprovalNeighborItems(this);
  },

  firstTickReceived: function (data) {
    this.neighborItems.firstTickReceived(data);

    this._super(data);
  },

  tickUpdate: function (data) {
    this._super(data);

    this.neighborItems.tickUpdate(data);
  },

});
