import React, { Component } from 'react';
import Header from 'src/components/common/headers/Header';
import Color from './Color';
import PropTypes from 'prop-types';

module.exports = class Colors extends Component {
  constructor(props) {
    super(props);
    this.createColorComponents = this.createColorComponents.bind(this);
  }

  static propTypes = {
    separations: PropTypes.any,
    links: PropTypes.any,
    model: PropTypes.any,
    title: PropTypes.string,
    advanceInfo: PropTypes.bool,
    openerType: PropTypes.string//dialog or rightPanel
  };

  createColorComponents() {
    const { separations, links = {}, model, advanceInfo, openerType } = this.props;
    const object = model.object || model;
    const colors = separations.map(function (item, index) {
      return <Color key={index} separation={item} links={links} object={object} elementType={object.elementType || object.contentType} advanceInfo={advanceInfo} openerType={openerType} />;
    });
    return colors;
  }

  render() {
    const { title } = this.props;

    return <div className='colors-custom-info'>
      <Header>{title}</Header>
      {this.createColorComponents()}
    </div>;
  }

};