import React from 'react';

export default class DroppableArea extends React.Component {
  state = {
    over: false,
    overCollection: []
  };

  dragHandler = (ev) => {
  };

  dragEnterHandler = (ev) => {
    var overCollection = this.state.overCollection;
    overCollection.push(ev.target);
    if (overCollection.length === 1) {
      this.setState({
        overCollection: overCollection,
        over: true
      });
      if (typeof this.props.onDragEnter === 'function') {
        this.props.onDragEnter(ev);
      }
    }
    this.setState({
      overCollection: overCollection
    });
  };

  dragOverHandler = (ev) => {
    if (typeof this.props.onDragOver === 'function') {
      this.props.onDragOver(ev);
    }
    ev.preventDefault();
  };

  dragLeaveHandler = (ev) => {
    var overCollection = this.state.overCollection;
    overCollection.pop();

    if (overCollection.length === 0) {
      this.setState({
        overCollection: overCollection,
        over: false
      });
      if (typeof this.props.onDragLeave === 'function') {
        this.props.onDragLeave(ev);
      }
    }
    this.setState({
      overCollection: overCollection
    });
  };

  dropHandler = (ev) => {
    this.setState({
      overCollection: [],
      over: false
    });
    if (typeof this.props.onDrop === 'function') {
      this.props.onDrop(ev);
    }
  };

  getClassName = (over) => {
    var classes = ['DroppableArea'];

    if (over) {
      classes.push('over');
    }

    return classes.join(' ');
  };

  render() {
    var className = this.getClassName(this.state.over);
    return <div className={className}
      onDrag={this.dragHandler}
      onDragEnter={this.dragEnterHandler}
      onDragOver={this.dragOverHandler}
      onDragLeave={this.dragLeaveHandler}
      onDrop={this.dropHandler}>

      {this.props.children}

    </div>;
  }
}