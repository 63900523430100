import { restGet } from 'core/managers/rest2';
import { fromServerDateOnly } from 'core/dates';
import localization, { translate } from 'core/services/localization';
import settingsManager from 'core/managers/settings';
import { getParentViewRoot } from 'utilities/view';

/**
 * This function uses a REST GET request to fetch breadcrumbs from the predefined endpoint
 * @param {string} nwid - root object nwid
 * @param {string} type - root object type
 * @param {string} name - root object name
 * @returns {array} - breadcrumbs data as array
 */
export function fetchBreadcrumbs(nwid, type, name) {
  return restGet('', 'global/breadcrumbs', {
    nwid,
    type,
    name,
  })
    .then(res => Array.isArray(res.breadcrumbs) ? res.breadcrumbs : []);
}

export function composeWindowTitle(viewLabel, rootLabel) {
  const labels = [viewLabel];
  rootLabel && labels.push(rootLabel);

  return labels.map(label => translate(label)).join(' - ');
}

export function prepareBreadcrumbsInput(view) {
  const { win, navigator, tab } = view;
  const {
    rootId: nwid,
    rootType: type,
    rootName: name,
    nwid: viewNwid,
    label: viewLabel,
    viewClass
  } = view.viewSettings;

  return { navigator, win, tab, nwid, type, name, viewLabel, viewNwid, viewClass };
}

export async function fetchAndSetBreadcrumbs(
  {
    nwid,
    type,
    name,
    win,
    navigator,
    tab,
    viewNwid,
    viewLabel,
    viewClass,
  }) {

  if (!navigator || type === 'root') {
    return [];
  }

  return fetchBreadcrumbs(nwid, type, name)
    .then(breadcrumbs => {
      if (breadcrumbs.length <= 0) {
        console.error('Breadcrumbs are not defined for this view:', viewClass || viewNwid);
      }

      navigator.setBreadcrumbs(breadcrumbs);
      if (tab) {
        tab.setState({ breadcrumbs });
      } else if (win) {
        win.document.title = composeWindowTitle(viewLabel, breadcrumbs[0]?.name);
      }

      return breadcrumbs;
    });
}

export const fetchAndSetContentBreadcrumbs = (view, content) => {
  let inputParams = prepareBreadcrumbsInput(view);

  const parentRoot = getParentViewRoot(view);
  if (parentRoot?.type === 'edition' && content) {
    const { nwid, type, name } = content;
    inputParams = { ...inputParams, nwid, type, name };
  }

  fetchAndSetBreadcrumbs(inputParams);
};

export function parseBreadcrumbs(breadcrumbsJson) {
  if (!breadcrumbsJson || breadcrumbsJson === '[]') {
    return [];
  }

  if (typeof breadcrumbsJson !== 'string' || !breadcrumbsJson.startsWith('[{')) {
    return breadcrumbsJson;
  }

  const breadcrumbs = JSON.parse(breadcrumbsJson);

  return breadcrumbs;
}

export function breadcrumbsToNames(breadcrumbs) {
  if (!Array.isArray(breadcrumbs)) {
    return [];
  }

  return breadcrumbs.map(b => (
    b.type === 'publicationdate' ?
      toLocaleBreadcrumbsDate(fromServerDateOnly(b.name)) :
      b.name
  )).reverse();
}

export function toLocaleBreadcrumbsDate(date) {
  return localization.toLocaleDate(date, settingsManager.getTreeDateFormat());
}

const breadcrumbToCode = breadcrumb => {
  let code = '';

  if (breadcrumb.code) {
    code = breadcrumb.code;
  } else if (breadcrumb.type === 'publicationdate') {
    code = localization.toLocaleDate(fromServerDateOnly(breadcrumb.name), settingsManager.getTabDateFormat());
  } else if (breadcrumb.type === 'folder' || breadcrumb.name === 'Pages' || breadcrumb.name === 'Formes') {
    code = '';
  } else {
    code = breadcrumb.name;
  }

  return code;
};

export const breadcrumbsToCodes = breadcrumbs => {
  if (!Array.isArray(breadcrumbs)) {
    return [];
  }

  return breadcrumbs.map(breadcrumbToCode).filter(code => !!code).reverse();
};

export function formatBreadcrumbs(breadcrumbs) {
  if (!breadcrumbs) {
    return '';
  }

  if (typeof breadcrumbs === 'string') {
    breadcrumbs = parseBreadcrumbs(breadcrumbs);
  }

  return Array.isArray(breadcrumbs) ? breadcrumbsToNames(breadcrumbs).join(' > ') : breadcrumbs;
}

export function formatBreadcrumbsCodes(breadcrumbs) {
  if (!breadcrumbs) {
    return '';
  }

  if (typeof breadcrumbs === 'string') {
    breadcrumbs = parseBreadcrumbs(breadcrumbs);
  }

  return Array.isArray(breadcrumbs) ? breadcrumbsToCodes(breadcrumbs).join(':') : breadcrumbs;
}
