import React from 'react';
import iconService from 'core/services/iconService';
import { getMainStatus } from 'utilities/statuses';

export default class extends React.Component {
  static defaultProps = {
    separation: undefined,
    definition: undefined
  };

  getSparation = () => this.props.separation;

  getStatus = separation => getMainStatus(separation);

  getFlowStep = status => status?.flowStepType;

  getFlowStepIconName = status => status?.flowStepIconName;

  getStatusType = status => status?.statusType;

  getIconStatusStyle = () => {
    return {
      width: '16px',
      height: '16px'
    };
  };

  getStatusIcon = (flowStep, flowStepIconName, statusType) => {
    var statusTypeLowerCase = typeof statusType !== 'undefined' ? statusType.toLowerCase().trim() : undefined;

    if (!flowStep || typeof statusType === 'undefined') {
      return iconService.getGeneralIcon(null, 'empty');
    }

    if (statusTypeLowerCase === 'error') {
      if (flowStep === 'workflow/step/flow/approval') {
        return iconService.getModuleIcon('Thumbnail', 'reject-small');
      }

      return iconService.getTemplateIcon(flowStep);
    }

    return iconService.getTemplateIcon(flowStep, 'tiny', flowStepIconName);
  };

  render() {
    var separation = this.getSparation(),
      status = this.getStatus(separation),
      statusType = this.getStatusType(status),
      flowStep = this.getFlowStep(status),
      flowStepIconName = this.getFlowStepIconName(status),
      statusIcon = this.getStatusIcon(flowStep, flowStepIconName, statusType),
      style = this.getIconStatusStyle();
    return <div className="separation-item-status">
      <img src={statusIcon} />
    </div>;
  }
}