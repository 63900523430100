import PropTypes from 'prop-types';
import React from 'react';
import { getGeneralIcon, getModuleIcon } from 'core/services/iconService';
import { translate } from 'core/services/localization';
import Indicator from './Indicator';

function IndicatorsBar(
  {
    waitingForApproval,
    held,
    errors
  }) {

  return (
    <div className='alerts'>
      <Indicator
        count={waitingForApproval}
        imageUrl={getModuleIcon('MyBirdeye', 'waitingForApproval')}
        tooltip={translate('Waiting For Approval')}
      />
      <Indicator
        count={held}
        imageUrl={getGeneralIcon('', 'hold_structure', '.svg')}
        tooltip={translate('Hold')}
      />
      <Indicator
        count={errors}
        imageUrl={getModuleIcon('MyBirdeye', 'error')}
        tooltip={translate('Error')}
      />
    </div>
  );
}

IndicatorsBar.propTypes = {
  waitingForApproval: PropTypes.number,
  held: PropTypes.number,
  errors: PropTypes.number
};

export default IndicatorsBar;