/**
 * @name Tab
 * @file Tab class wraps Kendo TabStrip component tab item and provides API for tab item manipulation
 *
 * @author Boris
 * @since: 2019-03-04
 */

import domPurify from 'dompurify';
import { Placeholder } from 'core/placeholders';
import { translate } from '../services/localization';

function defaultComposeTabTitle(state) {
  return {
    titleHtml: state.text,
    tooltip: state.text
  };
}

class Tab {
  /**
   * Constructor
   * @param tabStripInstance - the instance of the BaseTabStrip class
   * @param state - contains properties for composing Tab title HTML and tooltip
   * @param composeTabTitle - composes Tab title HTML and tooltip
  */
  constructor(tabStripInstance, state, composeTabTitle = defaultComposeTabTitle) {
    this.tabStripInstance = tabStripInstance;
    this.composeTabTitle = composeTabTitle;

    this.tabStripInstance.tabStrip.append([{
      text: '',
      encoded: false,
      content: state.content
    }]);

    const tabStripItems = this.tabStripInstance.tabStrip.items();
    this.tabElement = tabStripItems[tabStripItems.length - 1];
    if (this.tabElement.id.includes('main-tabs')) {
      const ContextMenu = require('widgets/ContextMenu/ContextMenu');
      this.tabElement.addEventListener('contextmenu', event => {
        const contextMenu = new ContextMenu(this.tabElement, [this.tabElement], event);
        contextMenu.addItem(new Placeholder({
          label: translate('Close'),
          _isApplicable: true,
          execute: this.closeCurrentTab
        }));
        contextMenu.addItem(new Placeholder({
          label: translate('Close other tabs'),
          _isApplicable: true,
          execute: this.closeAllOtherTabs
        }));

        contextMenu.addItem(new Placeholder({
          label: translate('Close tabs to the right'),
          _isApplicable: true,
          execute: this.closeTabsToTheRight
        }));
        contextMenu.addItem(new Placeholder({
          label: translate('Close tabs to the left'),
          _isApplicable: true,
          execute: this.closeTabsToTheLeft
        }));
        contextMenu.addItem(new Placeholder({
          label: translate('Close all tabs'),
          _isApplicable: true,
          execute: this.closeAllTabs
        }));

        contextMenu.show();
      });
    }

    this.setState(state);

    this.selectHandlers = [];
    this.deselectHandlers = [];
  }

  closeCurrentTab = () => {
    this.tabStripInstance.closeTabHandler(this);
  };

  closeAllOtherTabs = () => {
    const currentTab = this.getTabElement();
    const tabItemsToClose = [];
    this.tabStripInstance._tabArray.forEach(tab => {
      if (tab.tabElement.id !== currentTab.id) {
        tabItemsToClose.push(tab);
      }
    });
    this.closeTabs(tabItemsToClose);
  };

  closeAllTabs = () => this.closeTabs([...this.tabStripInstance._tabArray]);

  closeTabsToTheRight = () => {
    const currentIndex = this.getTabIndex();
    const tabItemsToClose = this.tabStripInstance._tabArray.slice(currentIndex + 1);
    this.closeTabs(tabItemsToClose);
  };

  closeTabsToTheLeft = () => {
    const currentIndex = this.getTabIndex();
    const tabItemsToClose = this.tabStripInstance._tabArray.slice(0, currentIndex);
    this.closeTabs(tabItemsToClose);
  };

  closeTabs = (tabsArr = []) => {
    for (let index = 0; index < tabsArr.length; index++) {
      this.tabStripInstance.closeTabHandler(tabsArr[index]);
    }
  };

  state = {};

  /**
   * Returns the Tab state
   */
  getState() {
    return this.state;
  }

  /**
   * Sets the Tab state
   * @param state - properties object
   */
  setState(state) {
    Object.assign(this.state, state);

    this.updateTabTitle();
  }

  /**
   * Updates the Tab title
   */
  updateTabTitle() {
    const tabTitle = this.composeTabTitle(this.state);
    const elem = $(this.tabElement).find('.k-link');
    const cleanHTML = domPurify.sanitize(tabTitle.titleHtml);
    elem.html(cleanHTML);
    elem.attr('title', tabTitle.tooltip);
  }

  /**
   * Returns the instance of the BaseTabStrip class that this Tab instance belongs to
   * @returns {BaseTabStrip} - instance of the BaseTabStrip class
   */
  getTabStripInstance() {
    return this.tabStripInstance;
  }

  /**
   * Obtains the DOM element that represents the corresponding tab item
   * @returns {object} - DOM element
   */
  getTabElement() {
    return this.tabElement;
  }

  /**
   * Returns the index of the corresponding tab item in the TabStrip
   * @returns {number} - tab index
   */
  getTabIndex() {
    const tabStripItems = this.tabStripInstance.tabStrip.items();
    const tabIndex = Array.from(tabStripItems).findIndex(item => item === this.tabElement);
    return tabIndex;
  }

  /**
   * Obtains the DOM element that encloses tab content
   * @returns {object} - DOM element
   */
  getContentElement() {
    return this.tabStripInstance.tabStrip.contentHolder(this.getTabIndex())[0];
  }

  /**
   * Select the corresponding tab item in the TabStrip
   */
  select() {
    this.tabStripInstance.tabStrip.select($(this.tabElement));
  }

  /**
   * Checks if this Tab is active
   * @returns {boolean} - true if the Tab is active
   */
  isActive() {
    return this.tabStripInstance.tabStrip.select()[0] === this.tabElement;
  }

  /**
   * Removes this Tab from the containing TabStrip component
   * @returns {boolean} - true if this Tab has been removed
   */
  remove() {
    return this.tabStripInstance.removeTab(this);
  }

  /**
   * Shows the corresponding tab item in the TabStrip component
   */
  show() {
    $(this.tabElement).attr('style', 'display:inline-block');
  }

  /**
   * Hides the corresponding tab item in the TabStrip component
   */
  hide() {
    $(this.tabElement).attr('style', 'display:none');
  }

  /**
   * Registers the given selectHandler callback function to be called when the Tab is selected
   * @param selectHandler - callback function
   * @returns {Function} - function to unregister the given selectHandler
   */
  registerSelectHandler(selectHandler) {
    this.selectHandlers = this.selectHandlers.concat(selectHandler);

    return () => {
      this.selectHandlers = this.selectHandlers.filter(handler => handler !== selectHandler);
    };
  }

  /**
   * Calls all registered select handler functions
   */
  triggerSelect() {
    this.selectHandlers.forEach(handler => handler(this));
  }

  /**
   * Registers the given deselectHandler callback function to be called when the Tab is deselected
   * @param deselectHandler - callback function
   * @returns {Function} - function to unregister the given selectHandler
   */
  registerDeselectHandler(deselectHandler) {
    this.deselectHandlers = this.deselectHandlers.concat(deselectHandler);

    return () => {
      this.deselectHandlers = this.deselectHandlers.filter(handler => handler !== deselectHandler);
    };
  }

  /**
   * Calls all registered deselect handler functions
   */
  triggerDeselect() {
    this.deselectHandlers.forEach(handler => handler(this));
  }
}

module.exports = Tab;