import React from 'react';
import { createRoot } from 'react-dom/client';
import AbstractAction from 'AbstractAction';
import request from 'core/managers/request';
import actionUtils from 'actions/actionUtils';
import Button from 'components/common/buttons/Button';
import Header from 'src/components/common/headers/Header';
import { Dialog, DialogButtonsBar } from 'components/common/dialogs';
import { translate } from 'core/services/localization';

const labels = {
  actual: translate('Actual'),
  expectedName: translate('Expected Name'),
  nwid: translate('Nwid'),
  contentNwid: translate('Content Nwid'),
  displayName: translate('Display Name'),
  contentPath: translate('Content Path'),
  sites: translate('Sites'),
  contentSites: translate('Content Sites'),
  inactiveSide: translate('Inactive Side')
};

const capitalizeSeparationName = (sep) => {
  return sep[0].toUpperCase() + sep.slice(1);
};

function View({ model, onClose }) {

  const renderSeparation = (separation, index) => {

    return (
      <div key={index}>
        <Header>{capitalizeSeparationName(separation.name)}</Header>
        <div className='crtx-form-section'>
          <div className='crtx-form-two-columns'>

            <label>{labels.expectedName}:</label>
            <span>{separation.contentExpectedName}</span>

            <label>{labels.nwid}:</label>
            <span>{separation.nwid}</span>

            <label>{labels.contentNwid}:</label>
            <span>{separation.contentNwid}</span>

            <label>{labels.displayName}:</label>
            <span>{separation.displayName}</span>

            <label>{labels.contentPath}:</label>
            <span>{separation.contentPath}</span>
          </div>
        </div>
        <div className='crtx-form-spacer'></div>
      </div>
    );
  };

  return (
    <Dialog
      onClose={onClose}
      title={translate('Object Information')}
      autoFocus={false}
      initialWidth={700}
      initialHeight={600}
      modal={true}>
      <div className='crtx-form-section-container'>
        <Header>{labels.actual}</Header>
        <div className='crtx-form-section'>
          <div className='crtx-form-two-columns'>

            <label>{labels.expectedName}:</label>
            <span>{model.contentExpectedName}</span>

            <label>{labels.nwid}:</label>
            <span>{model.nwid}</span>

            <label>{labels.contentNwid}:</label>
            <span>{model.contentNwid}</span>

            <label>{labels.displayName}:</label>
            <span>{model.displayName}</span>

            <label>{labels.contentPath}:</label>
            <span>{model.contentPath}</span>

            <label>{labels.sites}:</label>
            <span>{model.sites}</span>

            <label>{labels.contentSites}:</label>
            <span>{model.contentSites}</span>
          </div>
        </div>
        <div className='crtx-form-spacer'></div>
        {model.separations?.map(renderSeparation)}

        {model.otherSide && <React.Fragment>
          <Header>{labels.inactiveSide}</Header>
          <div className='crtx-form-section'>
            <div className='crtx-form-two-columns'>

              <label>{labels.expectedName}:</label>
              <span>{model.otherside_contentExpectedName}</span>

              <label>{labels.nwid}:</label>
              <span>{model.otherside_nwid}</span>

              <label>{labels.contentNwid}:</label>
              <span>{model.otherside_contentNwid}</span>

              <label>{labels.displayName}:</label>
              <span>{model.otherside_displayName}</span>

              <label>{labels.contentPath}:</label>
              <span>{model.otherside_contentPath}</span>

              <label>{labels.sites}:</label>
              <span>{model.otherside_sideSites}</span>

              <label>{labels.contentSites}:</label>
              <span>{model.otherside_contentSites}</span>

            </div>
          </div>
          {model.otherside_separations?.map(renderSeparation)}
        </React.Fragment>
        }
      </div>

      <DialogButtonsBar>
        <Button className={'primary'} onClick={onClose}>{translate('OK')}</Button>
      </DialogButtonsBar>
    </Dialog >);
}

module.exports = AbstractAction.extend({

  isApplicable: function () {
    return actionUtils.isPlanned(this.module);
  },

  execute: function (selected) {
    var obj = selected[0];
    request.execAction(
      this.nwid,
      'ObjectInfoAction',
      obj.nwid,
      obj.type,
      this.module.projectorId,
      'exec'
    ).then((res) => {
      const domElement = document.createElement('div');
      this.module.domElement.appendChild(domElement);

      this.reactRoot = createRoot(domElement);
      const handleClose = () => {
        this.reactRoot.unmount();
        domElement.remove();
      };
      this.reactRoot.render(<View model={res.data} module={this.module} onClose={handleClose} />);
    });
  }
});