import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { startInternalModule } from 'core/managers/module';
import { translate } from 'core/services/localization';
import Button from 'components/common/buttons/Button';
import TextInput from "components/common/inputs/TextInput";

const SINGLE_VALUE_TYPE = PropTypes.shape({
  name: PropTypes.string,
  nwid: PropTypes.string
});

class FlowstepsBrowserInput extends Component {

  static propTypes = {
    value: PropTypes.arrayOf(SINGLE_VALUE_TYPE),
    multiselect: PropTypes.bool,
    moduleNwid: PropTypes.string,
    flowstepsBrowserSetupName: PropTypes.string,
    flowstepsBrowserSetupWindowWidth: PropTypes.number,
    flowstepsBrowserSetupWindowHeight: PropTypes.number,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    onSelect: PropTypes.func,
  };

  static defaultProps = {
    value: [],
    multiselect: false,
    flowstepsBrowserSetupName: 'Flowsteps Browser Setup',
    flowstepsBrowserSetupWindowWidth: 700,
    flowstepsBrowserSetupWindowHeight: 330,
    disabled: false,
    className: '',
    onSelect: () => {
    },
  };

  handleSave = (selectedFlowstepsInfo) => {
    const { onSelect } = this.props;
    onSelect(undefined, selectedFlowstepsInfo);
  };

  handleClick = () => {
    const {
      value, multiselect, moduleNwid, flowstepsBrowserSetupName, flowstepsBrowserSetupWindowWidth,
      flowstepsBrowserSetupWindowHeight, disabled
    } = this.props;
    const moduleName = 'FlowstepsBrowserSetup';
    const name = flowstepsBrowserSetupName;
    const target = 'dialog';
    const windowWidth = flowstepsBrowserSetupWindowWidth;
    const windowHeight = flowstepsBrowserSetupWindowHeight;

    if (!disabled) {
      startInternalModule(moduleName, {
        name,
        target,
        windowWidth,
        windowHeight,
        multiselect,
        flowstepsNwids: value.map(fs => fs.nwid),
        instanceNwid: moduleNwid,
        onSave: this.handleSave
      }, this);
    }
  };

  render() {
    const { value, className, placeholder, style, disabled } = this.props;
    const text = value.map(fs => fs.name).join('; ');
    const disabledClassName = disabled ? 'disabled' : '';

    return <div className={`crtx-flowsteps-browser-input ${className} `} style={style}>
      <TextInput className='crtx-flowsteps-browser-input-text' value={text} placeholder={translate('Choose flowstep')} readOnly={true} disabled={disabled} />
      <Button className='crtx-flowsteps-browser-input-button' onClick={this.handleClick} disabled={disabled}>...</Button>
    </div>;
  }
}

export default FlowstepsBrowserInput;