/**
 * @name ProductionRunTreeNode
 * @file Tree Node component that represents Production Run Node of the Tree component
 *
 * @author Boris
 * @since: 2018-11-11
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class ProductionRunTreeNode extends Component {
  static propTypes = {
    node: PropTypes.object.isRequired,
    nodePath: PropTypes.string,
    nodeProps: PropTypes.object.isRequired,
  };

  static defaultProps = {};

  composeKey(id, index) {
    return `${id}_${index}`;
  }

  renderLeadingIcon = (icon, index) => {
    const className = `crtx-tree-node-leading-icon ${icon.className || ''}`;
    const key = this.composeKey(icon.id, index);

    if (icon.type === 'image') {
      return (
        <img key={key} src={icon.url} className={className} style={icon.style}/>
      );
    } else if (icon.type === 'svg') {
      return (
        <svg key={key} className={className} style={icon.style}>
          <title>{icon.title}</title>
          <use xlinkHref={icon.href}/>
        </svg>
      );
    } else {
      return (
        <div key={key} className={className} style={icon.style}/>
      );
    }
  };

  renderLeadingIcons() {
    const {leadingIcons} = this.props.nodeProps;
    if (!Array.isArray(leadingIcons) || leadingIcons.length <= 0) {
      return null;
    }

    return (
      <div className='crtx-tree-node-leading-icons'>
        {leadingIcons.map(this.renderLeadingIcon)}
      </div>
    );
  }

  renderText() {
    const {nodeProps} = this.props;

    if (!nodeProps.text) {
      return null;
    }

    return (
      <div className='crtx-tree-node-text' title={nodeProps.text + ' ' + nodeProps.trailingText}>
        {nodeProps.text}
      </div>
    );
  }

  renderTrailingText()  {
    const {nodeProps} = this.props;

    if (!nodeProps.trailingText) {
      return null;
    }   
    
    return (
      <div className='crtx-tree-node-trailing-text' title={nodeProps.text + ' ' + nodeProps.trailingText}>
        {nodeProps.trailingText}
      </div>
    );
  }

  renderTrailingIcon = (icon, index) => {
    const className = `crtx-tree-node-trailing-icon ${icon.className || ''}`;
    const key = this.composeKey(icon.id, index);

    if (icon.url) {
      return (
        <img key={key} className={className} style={icon.style} src={icon.url} title={icon.tooltip} alt=''/>
      );
    } else {
      return (
        <div key={key} className={className} style={icon.style}/>
      );
    }
  };

  renderTrailingIcons() {
    const {trailingIcons} = this.props.nodeProps;
    return (
      <div className='crtx-tree-node-trailing-icons'>
        {Array.isArray(trailingIcons) && trailingIcons.length > 0 ? trailingIcons.map(this.renderTrailingIcon): null}
      </div>
    );
  }

  render() {
    return (
      <div className={'crtx-tree-node crtx-prt-node'}>
        {this.renderLeadingIcons()}
        {this.renderText()}
        {this.renderTrailingText()}
        {this.renderTrailingIcons()}
      </div>
    );
  }
};
