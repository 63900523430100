import React from 'react';
import PropTypes from 'prop-types';
import ConversionOutput from './ConversionOutput';

const PERCENT_SYMBOL = '%';

export default function PercentOutput(
  {
    value,
    factor,
    className,
    style,
  }) {

  return (
    <ConversionOutput
      value={value}
      factor={factor ?? 100}
      unitSymbol={PERCENT_SYMBOL}
      className={className}
      style={style}
    />
  );
}

PercentOutput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  factor: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
};
