/**
 * @fileOverview Module manager is in charge of loading and unloading
 * modules, keeping the list of loaded modules.
 *
 * @name Module Manager
 * @namespace
 * @author sergey
 */

import base from 'base';
import jsutils from 'base/jsUtils';
import tickFacade from 'core/facades/tick';
import idWorker from 'core/workers/idWorker';
import pubsub from 'core/managers/pubsub';
import windowsManager from 'core/managers/windows';
import desktopManager from 'core/managers/desktop';
import viewManager from 'core/managers/views';
import actionManager from 'core/managers/actions';
import shortcutManager from 'core/managers/shortcut';
import router from 'core/services/router';
import { ModuleContainer } from 'utilities/betaFeatures';
import settingsManager from 'core/managers/settings';
import { getDefaultViewsPreferences, saveDefaultViewsPreferences, saveOpenedViewsPreferences } from './preferences';
import { prepareBreadcrumbsInput, fetchAndSetBreadcrumbs } from 'utilities/breadcrumbs';

const loaded = {};  // dictionary of loaded modules
let selectedMainModuleId = 0;

let pendingProjectorRequests = [];

pubsub.subscribe('selected-main-module-changed', function (moduleId) {
  selectedMainModuleId = moduleId;
  if (loaded[moduleId] && loaded[moduleId].rerender) {
    loaded[moduleId].rerender();
  }
  setTimeout(function () {
    if (loaded[moduleId] && loaded[moduleId].allowStandbyMode === true && jsutils.isFunction(loaded[moduleId].render)) {
      loaded[moduleId].render();
    }
  }, 1);
}.bind(null));

const modulesList = require('modules/modules-list');

function isLoadedModule(moduleId) {
  return loaded[moduleId] && loaded[moduleId].viewSettings && Object.keys(loaded[moduleId].viewSettings).length !== 0;
}

function isMainModule(moduleId) {
  return (loaded[moduleId].viewSettings.target) && loaded[moduleId].viewSettings.target === 'main' ? true : false;
}

function isNewModule(moduleId) {
  return (loaded[moduleId].viewSettings.target) && loaded[moduleId].viewSettings.target === 'new' ? true : false;
}

function isOverviewModule(moduleId) {
  return (loaded[moduleId].viewSettings.target) && loaded[moduleId].viewSettings.target === 'overview' ? true : false;
}

function saveModulesPreferences(startedModuleId) {
  if (settingsManager.get('mode') !== 'standard') {
    return;
  }

  const { duringStartup = false, target = '' } = loaded[startedModuleId]?.viewSettings || {};
  if (duringStartup || target && target !== 'main' && target !== 'new' && target !== 'overview') {
    return;
  }

  let mainPrefs = desktopManager.getMainModuleIds();

  let overviewPrefs = desktopManager.getOverviewsIds();

  for (var moduleId in loaded) {
    let intModuleId = Number(moduleId);

    //if main module load the main prefs into mianPrefs to the ordered location in the array
    if (isLoadedModule(moduleId) && (isMainModule(moduleId) || isNewModule(moduleId))) {
      if (mainPrefs.indexOf(intModuleId) >= 0) {
        const { nwid, rootId: rootNwid, rootType, rootName, shouldChangeDefaultView } = loaded[moduleId].viewSettings;
        mainPrefs[mainPrefs.indexOf(intModuleId)] = {
          nwid,
          rootNwid,
          rootType,
          rootName,
        };

        if (intModuleId === startedModuleId && shouldChangeDefaultView) {
          addOrChangeDefaultView(nwid, rootType, rootName);
        }
      }
    }

    //if overview module push the overview prefs to overviewarray
    if (isLoadedModule(moduleId) && isOverviewModule(moduleId)) {
      if (overviewPrefs.indexOf(intModuleId) >= 0) {
        const { nwid, rootId: rootNwid, rootType, rootName } = loaded[moduleId].viewSettings;
        overviewPrefs[overviewPrefs.indexOf(intModuleId)] = {
          nwid,
          rootNwid,
          rootType,
          rootName,
        };
      }
    }
  }

  //removing items from array mainPrefs if there was no item for it in loaded
  mainPrefs = mainPrefs.filter(pref => typeof pref !== 'number');

  //removing items from array overviewPrefs if there was no item for it in loaded
  overviewPrefs = overviewPrefs.filter(pref => typeof pref !== 'number');

  const openedViews = mainPrefs.concat(overviewPrefs);
  saveOpenedViewsPreferences(openedViews);
}

function addOrChangeDefaultView(nwid, rootType, rootName) {
  const defaultViews = getDefaultViewsPreferences();
  let found = false;
  for (let idx = 0; idx < defaultViews.length; idx++) {
    if (defaultViews[idx].rootType === rootType) {
      defaultViews[idx].nwid = nwid;
      defaultViews[idx].rootName = rootName;
      found = true;
      break;
    }
  }
  if (!found) {
    defaultViews.push({ nwid, rootType, rootName });
  }

  saveDefaultViewsPreferences(defaultViews);
}

function isSetup(name) {
  return name.toLowerCase().indexOf('setup') !== -1;
}

function moduleRequiredClbk(moduleName, moduleId, returnedModule) {
  let moduleInstance;

  let module = typeof returnedModule !== 'undefined' ? returnedModule.default || returnedModule : undefined;
  if (jsutils.isFunction(module)) {   // if a Module is a class
    moduleInstance = new module();
    if (moduleInstance instanceof ModuleContainer) {
      moduleInstance = new (moduleInstance.loadFeture())();
    }
  } else if (jsutils.isObject(module)) {
    moduleInstance = module;
  } else {
    throw new Error('This module has a wrong type: ' + moduleName);
  }

  loaded[moduleId] = moduleInstance;

  return moduleInstance;
}

async function chankArrivedClbk(moduleName, moduleId, waitForChunk) {
  if (waitForChunk === null) {
    throw new Error('This module does not not exist may be you forgot to include it in modules-list.js or setups-list.js: ' + moduleName);
  }

  return new Promise(resolve => {
    waitForChunk(returnedModule => {
      resolve(moduleRequiredClbk(moduleName, moduleId, returnedModule));
    });
  });
}

async function loadModule(moduleId, moduleName) {
  console.log('Loading module: ' + moduleName);
  if (!moduleId || !moduleName) {
    throw new Error('Loading Module: moduleId and moduleName should be defined');
  }

  let waitForChunk;
  if (isSetup(moduleName)) {
    const waitForSetups = require('bundle-loader!setups/modules-list');
    waitForChunk = await new Promise(resolve => {
      waitForSetups(kernelSetups => {
        resolve(kernelSetups.requireModule(moduleName));
      });
    });
  } else {
    waitForChunk = modulesList.requireModule(moduleName);
  }

  const module = await chankArrivedClbk(moduleName, moduleId, waitForChunk);

  return module;
}

function findModule(rootId, rootType, rootName, target, viewClass) {
  let foundModule = null;

  for (const module of Object.values(loaded)) {
    const { viewSettings: vs } = module;
    if (
      vs && vs.rootId === rootId &&
      vs.rootType === rootType &&
      vs.rootName?.toLowerCase() === rootName?.toLowerCase() &&
      (vs.target === target || vs.target === 'main' && target === 'new' || vs.target === 'new' && target === 'main') &&
      (!viewClass || vs.viewClass === viewClass)
    ) {
      foundModule = module;
      break;
    }
  }

  return foundModule;
}

function findViewModules(nwid, rootId, target) {
  return getModules().reduce((acc, m) => {
    if (m.viewSettings && m.viewSettings.nwid === nwid && m.viewSettings.rootId === rootId && m.viewSettings.target === target) {
      acc.push(m);
    }

    return acc;
  }, []);
}

function findInternalModules(name, rootId, target) {
  return getModules().reduce((acc, m) => {
    if (m.name === name && m.rootId === rootId && (m.startParameters || {}).target === target) {
      acc.push(m);
    }

    return acc;
  }, []);
}

async function startModuleByViewLink(view, rootNwid, rootType, params) {
  let startParams = {
    ...params,
    viewClass: view.viewClass,
    target: view.target,
    rootId: rootNwid,
    rootType: rootType
  };

  return startModule(view.nwid, null, startParams);
}

/**
 * Function is in charge of module starting lifecycle:
 * 0. Create container for future module
 *
 * 1. In Parallel:
 * 1.1 Request module definition: view definition, action definition, gui preferences, and module configuration
 * 1.2 Load module assets: javascript file, templates, etc.
 *
 * 2. Load module's actions implementations
 *
 * 3 If applicable, call method's initDone method
 * 4 If applicable, register to tick updates
 *
 * As far as the module author the lifecycle is the following:
 * 0. module is filled with:
 *    id: the id of the module on the client
 *    nwid: the nwid of the root object
 *    name: the name of the module
 *    element: dom selector
 *    viewSettings: the view definition of the module
 *    viewActions: array of the view actions of the modules
 * 1. initDone() method is called if exists
 * 2. if tickUpdate method exists a tick is registered (with rootId if applicable), and tickUpdate(data) is called
 *
 * @param {string} nwid             View instance id for module
 * @param {number} parentModuleId   Id the module from which the current module is about to be opened
 * @param {Object} startParameters  Configuration required to override module's view definition
 * @param {event}  event            Mouse event object
 * @returns {Object}                Promise
 *
 * startParameters object contains the following common properties:
 * rootId - root object nwid; if you open view form the tree the root is the clicked node
 * rootType - root type (folder, workflow, zone etc.)
 * rootName - root name (PDF Imposed, Press 1, etc.)
 * target - view target (main, window, overview, etc.)
 * viewClass - view JavaScript file name
 * duringStartup - indicates whether this function was called from startup.js or by user interaction
 */
async function startModule(nwid, parentModuleId, startParameters = {}, event) {
  if (!nwid) {
    throw new Error('Cannot start module: nwid is not provided');
  }

  if (typeof parentModuleId !== 'number' && parentModuleId !== null) {
    throw new Error('Module manager: parent module id should be specified as number or null');
  }

  const { folderNwid, rootId, rootType, rootName, target, viewClass, duringStartup = false } = startParameters;

  if (target?.toLowerCase() === 'window') {
    return startModuleInWindow(nwid, startParameters, event);
  }

  if (target === 'overview') {
    const module = findModule(rootId, rootType, rootName, target);
    if (module != null) {
      desktopManager.exposeElement(module.element, module.viewSettings.target, !duringStartup);
      return module;
    }
  }

  if (target === 'new') {
    const module = findModule(rootId, rootType, rootName, target, viewClass);
    if (module != null) {
      desktopManager.exposeElement(module, target);
      return module;
    }
  }

  if (target === 'dialog') {
    const dialogModules = findViewModules(nwid, rootId, 'dialog');
    if (dialogModules.length > 0) {
      return dialogModules[0].container.toFront();
    }
  }

  const moduleId = idWorker.generateId();
  const moduleName = viewClass || viewManager.getViewClass(nwid);

  return await Promise.all([
    loadModule(moduleId, moduleName),
    actionManager.getViewActionsInfo(nwid),
    desktopManager.createContainer(moduleId, nwid, startParameters, event)]
  )
    .then(([module, actionDefinitions, moduleContainer]) => {
      module.id = moduleId;
      module.name = moduleName;
      module.nwid = nwid;
      module.folderNwid = folderNwid;
      module.parentModule = loaded[parentModuleId] || null;
      module.tab = moduleContainer.tab;
      module.domElement = moduleContainer.domElement;
      module.element = moduleContainer.selector;
      module.container = moduleContainer.container;
      module.navigator = moduleContainer.navigator;
      module.win = moduleContainer.win || window;
      module.startParameters = startParameters; // Required for reloading
      module.parentModuleId = parentModuleId; // Required for reloading
      module.viewSettings = {
        nwid: nwid
      };

      Object.assign(module.viewSettings, startParameters);

      if (typeof module.setBreadcrumbs !== 'function') {
        fetchAndSetBreadcrumbs(prepareBreadcrumbsInput(module));
      }

      if (typeof module.tickUpdate === 'function') {
        const requestParams = {
          rootId: module.viewSettings.rootId,
          rootType: module.viewSettings.rootType,
          viewDefinitionName: module.viewSettings.viewDefinitionName
        };

        // ask for tick data as soon as possible
        tickFacade.addProjector(module, module.firstTickReceived, module.tickUpdate, module.tickCommit, requestParams);
      }

      if (moduleContainer.type === 'dialog') {
        moduleContainer.container.bind('resize', function () {
          if (typeof module.onResize === 'function') {
            module.onResize.apply(module, arguments);
          }
        });
      }

      if (typeof module.destroy === 'function') {
        base.events.listen(module.win, 'beforeunload', module, module.destroy.bind(module));
      }

      module.viewActions = actionDefinitions.map(actionDef => {
        let action = actionManager.loadAction(actionDef.actionClass);
        if (action !== null) {
          Object.assign(action, actionDef);
          action.module = module;
          action.folderNwid = module.folderNwid;
        }

        return action;
      }).filter(a => a !== null);

      if (typeof module.initDone === 'function') {
        module.initDone();
      }

      module.viewActions.forEach(function (action) {
        action.initDone();
      });

      desktopManager.exposeElement(module.element, module.viewSettings.target, !duringStartup);

      saveModulesPreferences(module.id);

      return module;
    });
}

async function startModuleInWindow(nwid, startParameters = {}, event) {
  const baseUrl = window.location.origin + window.location.pathname;
  const {
    rootId, rootType, rootName, viewClass, label, folderNwid, enableNavigation, initiatingActionNwid, noNavigator,
    windowWidth, windowHeight, onDestroy
  } = startParameters;
  const urlParams = {
    nwid,
    rootId,
    rootType,
    rootName,
    viewClass,
    label,
    folderNwid,
    enableNavigation,
    initiatingActionNwid,
    target: '',
    noNavigator
  };

  const windowFeaturesObj = {
    width: parseInt(windowWidth),
    height: parseInt(windowHeight),
  };

  const url = baseUrl + '#' + router.objToHash({ runningMode: "embedded", main: [urlParams] });

  //// We will not pass the name because dear IE browser break on sending
  //// name as the second parameter. It doesn't accept it.
  //// We all love IE for such bullshit

  const win = await windowsManager.open(url, url, windowFeaturesObj, startParameters, event);
  if (typeof onDestroy === 'function') {
    base.events.listen(win, 'beforeunload', undefined, onDestroy);
  }

  return win;
}

/**
 *
 * @param {*} moduleName            The module to load
 * @param {*} startParameters       Configuration required to override module's view definition
 * @param {*} parentModuleOrAction  Parent view or action
 */
async function startInternalModule(moduleName, startParameters, parentModuleOrAction) {
  const moduleId = idWorker.generateId();
  let moduleContainer;

  if (startParameters && startParameters.target === 'dialog') {
    const dialogModules = findInternalModules(moduleName, startParameters.rootId, 'dialog');
    if (dialogModules.length > 0) {
      dialogModules[0].container.toFront();
      return dialogModules[0];
    }

    moduleContainer = await desktopManager.createContainer(moduleId, undefined, {
      ...startParameters,
      viewClass: moduleName
    });
  }

  const moduleInstance = await loadModule(moduleId, moduleName);

  moduleInstance.name = moduleName;
  moduleInstance.id = moduleId;

  if (typeof startParameters !== 'undefined') {
    moduleInstance.startParameters = startParameters;
    moduleInstance.rootId = startParameters.rootId;
    moduleInstance.rootType = startParameters.rootType;
  }

  if (typeof parentModuleOrAction !== 'undefined') {
    moduleInstance.parentModuleOrAction = parentModuleOrAction;
    moduleInstance.folderNwid = parentModuleOrAction.folderNwid;
  }

  if (typeof moduleContainer !== 'undefined') {
    moduleInstance.tab = moduleContainer.tab;
    moduleInstance.element = moduleContainer.selector;
    moduleInstance.container = moduleContainer.container;
    moduleInstance.win = moduleContainer.win;
    moduleInstance.domElement = moduleContainer.domElement;

    if (moduleContainer.type === 'dialog') {
      moduleContainer.container.bind('resize', function () {
        if (typeof moduleInstance.onResize === 'function') {
          moduleInstance.onResize.apply(moduleInstance, arguments);
        }
      });
    }
  }

  if (jsutils.isFunction(moduleInstance.initDone)) {
    moduleInstance.initDone();
  }

  return moduleInstance;
}

/**
 * Function destroys the module.
 * @param {number} moduleId Unique identifier of the module
 * @param {boolean} keepNavigator Do not destroy Navigator when true
 * @returns {boolean} Returns true if the module was found and destroyed
 */
function stopModule(moduleId, keepNavigator = false) {
  // cached module instance
  const moduleInstance = loaded[moduleId];
  if (!moduleInstance) {
    return false;
  }

  moduleInstance.viewActions && moduleInstance.viewActions.forEach(function (action) {
    action.destroy();
  });

  // if module's destroy method is applicable, call it
  if (jsutils.isFunction(moduleInstance.destroy)) {
    moduleInstance.destroy();
  }

  // clear registered shortcuts
  shortcutManager.unregisterAll(moduleInstance.element);

  // remove projector
  if (moduleInstance.projectorId && jsutils.isFunction(moduleInstance.tickUpdate)) {
    tickFacade.removeProjector(moduleId);
  }

  // remove module
  delete loaded[moduleId];

  if (selectedMainModuleId === moduleId) {
    selectedMainModuleId = 0;
  }

  if (moduleInstance.navigator) {
    if (!keepNavigator) {
      moduleInstance.navigator.destroy();
    }

    moduleInstance.navigator = null;
  }

  if (moduleInstance.viewSettings) {
    saveModulesPreferences();
  }

  return true;
}

/**
 * Stop internal method removes the specified by name module
 * from the system. The following steps are performed:
 * - If module has destroy() method, it's called
 * - The module reference is removed from data structure of loaded modules
 * @param moduleName
 */
function stopInternalModule(moduleName) {
  const modules = getModulesByName(moduleName);

  for (const module of modules) {

    // call module's destroy method is applicable
    if (jsutils.isFunction(module.destroy)) {
      module.destroy();
    }

    // clear the data structure
    // with no references to module's instance it will be garbage collected
    delete loaded[module.id];
  }

}

/**
 * The reload function of the module will only reload a module that was previously loaded
 * @param moduleId {number} moduleId Unique identifier of the module
 */
function reloadModule(moduleId) {
  var moduleInstance = loaded[moduleId];
  if (!moduleInstance) {
    return;
  }

  // In case of reloading a module in a tab container avoid opening a new tab (instead of current tab)
  if (moduleInstance.startParameters?.target?.toLowerCase() === 'new') {
    moduleInstance.startParameters.target = 'main';
  }

  const { nwid, parentModuleId, startParameters } = moduleInstance;

  return startModule(nwid, parentModuleId, startParameters);
}

function replaceModuleProjector(moduleId, { rootId, rootType, rootName, viewDefinitionName, win }) {
  const moduleInstance = getModuleById(moduleId);
  if (!moduleInstance || !moduleInstance.allowReplaceProjector || !rootId || moduleInstance.viewSettings.rootId === rootId) {
    return;
  }

  moduleInstance.viewSettings.rootType = rootType;
  moduleInstance.viewSettings.rootName = rootName;
  moduleInstance.viewSettings.viewDefinitionName = viewDefinitionName;
  moduleInstance.viewSettings.win = win;

  const prevRootId = moduleInstance.viewSettings.rootId;
  moduleInstance.viewSettings.rootId = rootId;
  if (typeof moduleInstance.rootChanged === 'function') {
    moduleInstance.rootChanged(prevRootId, rootId);
  }

  if (pendingProjectorRequests.findIndex(item => item.moduleId === moduleId) < 0) {
    replaceProjector(moduleId, rootId, rootType);
  }

  pendingProjectorRequests.push({ moduleId, rootId, rootType });
}

function replaceProjector(moduleId, rootId, rootType) {
  return require('core/workers/tickWorker').replaceProjector(moduleId, rootId, rootType)
    .then(projectorId => {
      const moduleInstance = getModuleById(moduleId);
      if (moduleInstance) {
        Object.assign(moduleInstance, { projectorId });
      }

      pendingProjectorRequests = pendingProjectorRequests.filter(item => item.moduleId !== moduleId || item.rootId !== rootId);
      let requestsForModule = pendingProjectorRequests.filter(item => item.moduleId === moduleId);
      if (requestsForModule.length > 0) {
        const lastRequest = requestsForModule[requestsForModule.length - 1];
        pendingProjectorRequests = pendingProjectorRequests.filter(item => item.moduleId !== moduleId);
        pendingProjectorRequests.push(lastRequest);
        return replaceProjector(lastRequest.moduleId, lastRequest.rootId, lastRequest.rootType);
      } else {
        if (moduleInstance && typeof moduleInstance.setBreadcrumbs !== 'function') {
          fetchAndSetBreadcrumbs(prepareBreadcrumbsInput(moduleInstance));
        }

        return projectorId;
      }
    });
}

// ====================== Utility functions of module manager ===========================

/**
 * Return the module's instances by its name
 * @param  {string} name Module's name
 * @return {Array}       List of modules' instances
 */
function getModulesByName(name) {
  var res = [];
  for (var i in loaded) {
    if (loaded[i].name === name) {
      res.push(loaded[i]);
    }
  }
  return res;
}

/**
 * Return the module instance by its id
 * @param  {number} id Module's id
 * @return module instance
 */
function getModuleById(id) {
  var res;
  for (var i in loaded) {
    if (loaded[i].id === id) {
      return loaded[i];
    }
  }
  return res;
}

/**
 * Returns array of all currently loaded modules
 */
function getModules() {
  return Object.keys(loaded).map(key => loaded[key]);
}

function getSelectedMainModuleId() {
  return selectedMainModuleId;
}

module.exports = {
  _name: 'module',
  _type: 'manager',
  startModule,
  startModuleByViewLink,
  stopModule,
  reloadModule,
  replaceModuleProjector,
  startInternalModule,
  stopInternalModule,
  getModulesByName,
  getModuleById,
  getModules,
  getSelectedMainModuleId,
  saveModulesPreferences
};
