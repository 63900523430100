import {createStore} from 'redux';
import utils from './utils';
import reducer from './reducers';

export default class Store {

  constructor(content, customReducer, onActionCallback, immutable) {
    if (arguments.length === 1) {
      this.store = arguments[0];
    }
    else if (arguments.length === 2) {
      this.store = arguments[0];
      this.unsubscribe = this.store.subscribe(arguments[1]);
    }
    else {
      const reducers = reducer(customReducer);
      this.immutable = immutable === undefined ? true : immutable;
      this.store = createStore(reducers, content);
      this.unsubscribe = this.store.subscribe(onActionCallback);
      this.actions = {};
    }
  }

  //get function can have multiple paths properties that is why arguments is passed into utils.get
  get(/*paths*/) {
    return utils.get(this.store.getState(), arguments);
  }

  //set(state, bind, value) {
  //  return utils.update(state, bind, value);
  //}
  //
  //add(state, bind, index, value) {
  //  return utils.add(state, bind, index, value);
  //}
  //
  //remove(state, bind, index) {
  //  return utils.remove(state, bind, index);
  //}

  dispatch(action) {
    if (typeof(action) === 'function') {
      return this.store.dispatch(action);
    }

    return this.store.dispatch({
      ...action,
      immutable: this.immutable
    });
  }

  getState() {
    return this.store.getState();
  }

  subscribe(subscriber) {
    return this.store.subscribe(subscriber);
  }

};
