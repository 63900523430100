import { toJS } from 'mobx';
const { isUndefined, isString } = require('./consts');

const required = (value) => {
  if (isString(value)) {

    return !isUndefined(value) && value !== '' && value.trim() !== '';
  }
  if (Array.isArray(toJS(value))) {
    return value && value.length > 0;
  }

  return !isUndefined(value) && value !== '';
};

const number = (value) => {
  return !isNaN(value);
};

const integer = (value) => {
  return Number.isInteger(value);
};

const flowstep = (arr) => {
  const isValid = o => typeof o === 'object' && !isUndefined(o.name) && !isUndefined(o.nwid) && o.name !== '';

  return arr.every(isValid);
};

const localpath = (value) => {
  const patt = (value === '') ? true : /^[a-zA-Z]:[a-zA-Z0-9_\/-\\]+/.test(value);

  return patt;
}

module.exports = {
  required,
  number,
  integer,
  flowstep,
  localpath
};