import PropTypes from 'prop-types';
import React, { Component } from 'react';

const SPACE_KEY = 32;

export default class ColorCheckbox extends Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    color: PropTypes.string,
    title: PropTypes.string,
    onDoubleClick: PropTypes.func,
    onChange: PropTypes.func
  };

  static defaultProps = {
    style: {},
    checked: false,
    disabled: false
  };

  constructor(props) {
    super(props);
  }

  handleClick = (e) => {
    const { onChange, checked, disabled } = this.props;

    if (onChange && !disabled) {
      onChange(e, !checked);
    }
  };

  handleDoubleClick = (e) => {
    const { onDoubleClick, disabled } = this.props;

    if (onDoubleClick && !disabled) {
      onDoubleClick(e);
    }
  };

  handleKeyDown = (e) => {
    const { onChange, checked, disabled } = this.props;

    if (onChange && !disabled && e.keyCode === SPACE_KEY) {
      onChange(e, !checked);
      e.preventDefault();
    }
  };

  render() {
    const { disabled, checked, color, title } = this.props;
    const className = `crtx-color-checkbox material-icons ${this.props.className} ${checked ? 'checked' : ''} ${disabled ? 'disabled' : ''}`;
    const background = checked ? color : 'white';
    const style = { ...this.props.style, backgroundColor: background, border: `1px solid ${color}` };
    const tabIndex = disabled ? undefined : 0;

    return (
      <div className={className}
        style={style}
        tabIndex={tabIndex}
        title={title}
        onClick={this.handleClick}
        onDoubleClick={this.handleDoubleClick}
        onKeyDown={this.handleKeyDown}
      />
    );
  }
};
