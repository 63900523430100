import React from 'react';
import SeparationStatus from './separationStatus';
import SeparationHeader from './separationHeader';
import Separation from './separation';

export default class SeparationsProgress extends React.Component {
  static defaultProps = {
    approvalIndication: undefined,
    approvalIndicationComplete: undefined,
    approvalIndicationReject: undefined,
    approvalCompleteVersion: undefined,
    separations: [],
    statuses: [],
    definition: undefined,
    style: undefined,
    model: {},
  };

  renderStatus = (separations, statuses, definition, approvalIndication, approvalIndicationComplete, approvalIndicationReject, approvalCompleteVersion) => {
    return <SeparationStatus
      separations={separations}
      statuses={statuses}
      definition={definition}
      approvalIndication={approvalIndication}
      approvalIndicationComplete={approvalIndicationComplete}
      approvalIndicationReject={approvalIndicationReject}
      approvalCompleteVersion={approvalCompleteVersion}
    />;
  };

  renderHeader = (separations, statuses, definition, approvalIndication, onSeparationClick) => {
    return <SeparationHeader
      separations={separations}
      statuses={statuses}
      definition={definition}
      approvalIndication={approvalIndication}
      onSeparationClick={onSeparationClick}
    />;
  };

  renderSeparations = (separations, definition, approvalIndication, onSeparationClick, model) => {
    var selectedSeparationIndex = this.props.selectedSeparationIndex;
    return separations.map(function (separation, index) {
      var selected = index === selectedSeparationIndex;
      return <Separation key={index} index={index} selected={selected}
        separation={separation}
        definition={definition}
        approvalIndication={approvalIndication}
        onSeparationClick={onSeparationClick}
        model={model}
      >
      </Separation>;
    });
  };

  render() {
    var component = this,
      {
        approvalIndication,
        approvalIndicationComplete,
        approvalIndicationReject,
        approvalCompleteVersion,
        definition,
        separations,
        statuses,
        onSeparationClick,
        model
      } = this.props,
      style = this.props.style;
    return <ul className="SeparationsList" cellSpacing={0} style={style}>
      {this.renderStatus(separations, statuses, definition, approvalIndication, approvalIndicationComplete, approvalIndicationReject, approvalCompleteVersion)}
      {this.renderHeader(separations, statuses, definition, approvalIndication, onSeparationClick)}
      {this.renderSeparations(separations, definition, approvalIndication, onSeparationClick, model)}
    </ul>;
  }
}