define(['class', 'sandbox'],

	function (Class, sandbox) {
		'use strict';

		const isFunction = o => typeof o === 'function';

		function decorateRender(step, render) {
			step.render = function() {
				console.log('decorated render');
				if (isFunction(step.load)) {
					step.load().then(() => {
						render.call(step);
					});
					
				}
				else {
					render.call(step);
				}
			}.bind(step);
		}

		function init(initObj) {
			decorateRender(this, this.render);

			const translate = sandbox.localization.translate;
			console.log("abstract init Called");
			this.container = initObj.container;
			this.properties = initObj.properties;
			this.properties.title = translate(this.properties.title);
			this.model = initObj.model;
		}



		return Class.extend({
			init: init
		}, "AbstractStep");
	});