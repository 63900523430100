import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AbstractStep from 'widgets/Wizard/AbstractStep';
import { extendObservable } from "mobx";
import FormStep from 'widgets/Wizard/FormStep';
import Button from 'components/common/buttons/Button';
import sandbox from 'sandbox';
import dialogService from 'core/services/dialogService';
import settingsManager from 'core/managers/settings';
import { restDelete } from 'core/managers/rest2';
import toastService from 'core/services/toastService';


const translate = sandbox.localization.translate;
const summaryInfo = {};
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const getPagesInfo = (pages) => {
  return pages.map(function (p) {
    return {
      number: p.numberInZone,
      zone: p.zoneName,
      expectedName: p.expectedName
    }
  })
};



class SanomaSummary extends Component {
  static onDataReceived = (model) => {
    const publication = model.publication;
    const edition = publication.editions[0];
    const baseZone = edition.zones.filter(function (z) {
      return (z.displayName === model.baseZoneName);
    })[0];
    const zones = edition.zones.filter(function (z) {
      return (z.displayName !== model.baseZoneName);
    });
    const sections = baseZone.sections.map(s => s.displayName);
    sections.forEach(function (s) {
      let basePages = baseZone.sections.filter(zs => zs.displayName === s).map(s => s.pages)[0];
      let localPagesInfo = zones.map(z => z.sections.filter(zs => zs.displayName === s).map(s => s.pages.filter(p => (p.masterPageZone === null))))
        .reduce((acc, item) => { return acc.concat(item) }, []).reduce((acc, item) => { return acc.concat(item) }, []);
      let localPages = [];
      if (typeof localPagesInfo !== "undefined") {
        localPages = localPagesInfo;
      }
      //let localPages = zones.map(z => z.sections.filter(zs => zs.displayName === s).map( s => s.pages.filter( p => (p.masterPageZone === null))))[0][0];
      summaryInfo[s] = {
        base: getPagesInfo(basePages),
        localPages: getPagesInfo(localPages)
      };
    });
    model.disableActivate = false;
    model.displaySuccessMessage = 'none';
    model.successMessage = '';
    return {
      ...model,
      publication: {
        ...model.publication,
        comment: model.comment
      }
    };
  };

  static onApply = (model) => {
    return {
      ...model
    };
  };

  static next = () => {
    window.close();
  };

  componentDidMount() {
    this.wizard = this.props.step.initObj.wizard;
    this.stepsProperties = this.props.step.initObj.stepsProperties;
    this.wizard.disableButton("finish");
  }
  activatePlan = () => {
    const { model, step } = this.props;
    const { initObj } = step;
    const { projectorId, startParameters } = initObj;
    const { nwid, rootId, rootType } = startParameters;
    model.disableActivate = true;
    this.props.step.initObj.wizard.disableButton("previous");
    var request = sandbox.request.setPlan(this, rootId, projectorId,
      {
        rootId: rootId,
        rootType: rootType,
        instanceNwId: nwid,
        model: model.publication,
        publicationName: model.publication.displayName,
        mode: "plan"
      })
      .then(this.handleActivationResult.bind(this));
  };

  handleActivationResult = (result) => {
    if (result.statusCode === 500) {
      let title = translate("Can't save publication");
      toastService.errorToast(title, result);
    } else {
      const { model } = this.props;
      let publication = model.publication;
      let edition = publication.editions[0];
      let zones = edition.zones.map(function (z) {
        return publication.issueDate + "/" + publication.displayName + "/" + edition.displayName + "/" + z.displayName;
      });
      let translationMap = {
        zones: zones.join("\n")
      }
      this.props.step.initObj.wizard.enableButton("finish");
      let message = translate("The following editions were created:\n {1}", translationMap.zones);
      model.successMessage = message;
      model.displaySuccessMessage = 'inline';
      let customer = settingsManager.get('user').name;
      let folderId = this.props.step.rootId;
      let url = "planning-wizard/custom/sanoma/pre-orders/" + folderId + "/" + customer + "/" + model.preorderPath;
      return restDelete(this.props.step.initObj.startParameters.nwid, url);
    }

  };

  renderPage = (page) => {
    return <tr>
      <td>{page.number}</td>
      <td>{page.zone}</td>
      <td>{page.expectedName}</td>
    </tr>
  }

  renderPages = (pages) => {
    return <table >
      <tr><td style={{ width: 45 }}><b>{translate('Page')}</b></td>
        <td style={{ width: 85 }}><b>{translate('Zone')}</b></td>
        <td><b>{translate('Expected Name')}</b></td></tr>
      {pages.map(this.renderPage)}
    </table>
  };

  renderLocals(pages) {
    if (pages.length === 0) {
      return <div></div>;
    } else {
      return <td>{this.renderPages(pages)}</td>
    }
  }

  renderSection = (section) => {
    return <div>
      <tr></tr>
      {translate('Section')}: {section}
      <table>
        <tr style={{ verticalAlign: 'top' }}><td>{this.renderPages(summaryInfo[section].base)}</td>
          <td><div style={{ width: 80 }}></div></td>
          <td>{this.renderLocals(summaryInfo[section].localPages)}</td>
        </tr>
      </table><br /><br />
    </div>
  };

  render() {
    const { model } = this.props;
    const publication = model.publication;
    if (typeof publication.editions === "undefined") {
      return (<div></div>);
    }
    const edition = publication.editions[0];
    const baseZone = edition.zones.filter(function (z) {
      return (z.displayName === model.baseZoneName);
    })[0];

    const sections = baseZone.sections.map(s => s.displayName);
    return <div style={{ height: '100%', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      <div style={{ flex: 1, overflow: 'auto' }}>
        <table>
          <tr><td>{translate('Publication name')}:</td><td>{publication.fullName}</td></tr>
          <tr><td>{translate('Publication code')}:</td><td>{publication.code}</td></tr>
          <tr><td>{translate('Publication date')}:</td><td>{publication.issueDate}</td></tr>
          <tr><td>{translate('Zones')}:</td><td>{edition.zones.map(z => z.displayName).join(", ")}</td></tr>
          <tr><td>{translate('Comment')}:</td><td>{decodeURIComponent(publication.comment)}</td></tr>
        </table><br />
        <table>
          <tr><td>{sections.map(this.renderSection)}</td></tr>
        </table>
        <div style={{ display: model.displaySuccessMessage, whiteSpace: 'pre-line', fontWeight: 'bold' }}>{model.successMessage}</div>
        <br /><br />
      </div>
      <div style={{ textAlign: 'right', height: 25 }}>
        <Button disabled={model.disableActivate} style={{ width: 80, height: 20 }} onClick={this.activatePlan}>
          {translate('activate')}
        </Button>
      </div>
    </div>
  };
}
export default FormStep(SanomaSummary);