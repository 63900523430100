import React, { useState } from 'react';
import { Dialog, DialogButtonsBar } from 'components/common/dialogs';
import Button from 'components/common/buttons/Button';
import TextInput from 'components/common/inputs/TextInput';
import { Validator, useValidator } from 'components/private/forms';
import { includesSpecialChars, SPECIAL_CHARS } from 'utilities/regex';
import { translate } from 'core/services/localization';
import { DIALOG_TYPE } from "../constants";

export const CreateEditDuplicateFolderDialog = (
  { onCloseAndDuplicate,
    onCloseAndCreate,
    onCloseAndEdit,
    onCloseDialog,
    dataAdapter,
    dialogType,
    suggestedFolderName }) => {

  const [folderName, setFolderName] = useState(suggestedFolderName);
  const { register, validate } = useValidator();

  const validateIncludesSpecialChars = (folderName) => {

    return !includesSpecialChars(folderName);
  }

  const validateExistingName = (folderName) => {

    return dataAdapter.foldersInfo.findIndex(folder => folder.label.toLowerCase() === folderName.toLowerCase()) === -1;
  };


  const handleNameChange = (event, value) => {
    setFolderName(value);
  };

  const handleClose = () => {
    onCloseDialog();
  }

  const handleCreateEditDuplicate = () => {

    if (!validate()) {
      return;
    }
    if (dialogType === DIALOG_TYPE[0]) {
      onCloseAndCreate(folderName, false);
    } else if (dialogType === DIALOG_TYPE[1]) {
      onCloseAndEdit(folderName, false);
    } else {
      onCloseAndDuplicate(folderName, false);
    }
  }

  const notEmptyMessage = translate('This field must not be empty');
  const includesSpecialCharsMessage = translate('A folder name cannot contain any of the following characters:') + ' ' + SPECIAL_CHARS;
  const folderAlreadyExistMessage = translate('There is already folder with the same name:') + ' ' + folderName;
  const title = dialogType === DIALOG_TYPE[0] ? translate('Create Folder') :
    (dialogType === DIALOG_TYPE[1] ? translate('Rename Folder') : translate('Duplicate Folder'));

  return <Dialog
    onClose={handleClose}
    title={title}
    autoFocus={false}
    initialWidth={420}
    initialHeight={200}
    modal={true}>
    <div className='crtx-form-section-container'>
      <div className='crtx-form-section'>
        <div className='crtx-form-two-columns crtx-form-margin-top-xsmall'>
          <label>{translate('Name:')}</label>
          <Validator className='crtx-form-element-full-width-validator'
            messageBottomAlignment={true}
            rules={['required', validateIncludesSpecialChars, validateExistingName]}
            message={[notEmptyMessage, includesSpecialCharsMessage, folderAlreadyExistMessage]}
            register={register}>
            <TextInput className='crtx-form-element-full-width' value={folderName} onChange={handleNameChange} />
          </Validator>
        </div>
      </div>
    </div>
    <DialogButtonsBar>
      <Button className='primary'
        onClick={handleCreateEditDuplicate}>{translate('OK')}
      </Button>
      <Button
        onClick={handleClose}>{translate('Cancel')}
      </Button>
    </DialogButtonsBar>
  </Dialog>;
}

