/**
 * @file PopoverContext - provides common context for PopoverTrigger and PopoverContent
 *
 * @author Boris
 * @created 2023-02-20
 */
import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const PopoverContext = createContext();

export const usePopoverContext = () => {
  const context = useContext(PopoverContext);

  if (typeof context === 'undefined') {
    throw new Error('Popover components must be wrapped in <Popover />');
  }

  return context;
};

export const PopoverContextProvider = ({ children, value }) => {
  return (
    // The Provider gives access to the context to its children
    <PopoverContext.Provider value={value}>
      {children}
    </PopoverContext.Provider>
  );
};

PopoverContextProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.object,
};
