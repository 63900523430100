import React from 'react';
import { cells } from 'widgets/ReactDataGrid';
import { applyColumnPreferences } from 'widgets/ReactDataGrid/utils';
import { translate } from 'core/services/localization';
import { arrangeItemsByKeys } from 'utilities/array';
import IconButton from 'components/common/buttons/IconButton';

const { Text, GenericCell } = cells;

export const makeCustomLogsTableColumns = (viewModel, module) => {
  const columnPrefs = module.preferences.table && module.preferences.table.customLogsColumns || [];
  let columns = [
    {
      key: 'name',
      width: 150,
      align: 'left',
      caption: translate('Name'),
      cell: Text,
      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: viewModel.logs[rowIndex] && viewModel.logs[rowIndex].name || '',
        };
      },
    },
    {
      key: 'fileName',
      width: 300,
      align: 'left',
      caption: translate('File Name'),
      cell: Text,
      cellDataGetter: (rowIndex, key) => {
        const columnData = viewModel.logs[rowIndex] && viewModel.logs[rowIndex].fileName || '';

        return {
          columnData
        };
      },
    },
    {
      key: 'line',
      width: 300,
      align: 'left',
      caption: translate('Line'),
      cell: Text,
      cellDataGetter: (rowIndex, key) => {
        const columnData = viewModel.logs[rowIndex] && viewModel.logs[rowIndex].line || '';

        return {
          columnData
        };
      },
    },
    {
      key: 'condition',
      width: 300,
      align: 'left',
      caption: translate('Condition'),
      cell: Text,
      cellDataGetter: (rowIndex, key) => {
        const columnData = viewModel.logs[rowIndex] && viewModel.logs[rowIndex].condition || '';

        return {
          columnData
        };
      },
    },
    {
      key: 'edit',
      width: 40,
      align: 'center',
      resizable: false,
      cell: GenericCell,
      cellDataGetter: (rowIndex, key) => {

        return {
          columnData: <IconButton
            key='edit'
            iconName='edit'
            tooltip={translate('Edit Log')}
            onClick={() => module.handleCustomLogsTableEdit(viewModel.logs[rowIndex])}
          />,
          title: translate('Edit Log'),
          className: 'custom-log-table-icon'
        };
      },
    },
    {
      key: 'delete',
      width: 40,
      align: 'center',
      resizable: false,
      cell: GenericCell,
      cellDataGetter: (rowIndex, key) => {

        return {
          columnData: <IconButton
            key='delete_outline'
            iconName='delete_outline'
            tooltip={translate('Remove Log')}
            onClick={() => module.handleCustomLogsTableDeleteButtonClick(viewModel.logs[rowIndex])}
          />,
          title: translate('Remove Log'),
          className: 'custom-log-table-icon'
        };
      },
    },
  ];

  const keys = columnPrefs.map(col => col.key);
  columns = arrangeItemsByKeys(columns, keys);

  columns = applyColumnPreferences(columns, columnPrefs);

  return columns;
};