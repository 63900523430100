/**
 * @name Rotate Image Action
 * @fileOverview Rotates the image on the screen
 */

define(['sandbox', 'AbstractAction', 'logger'], function (sandbox, AbstractAction, logger) {
  'use strict';
  var log = logger.getDefaultLogger();
  return AbstractAction.extend({
    execute: function (viewInstanceNwId, resourceNwId, type, projectorId, state) {
      // check passed arguments
      if (!this.assertArguments(arguments, this.execute.length)) {
        log.error('Rotate action: direction property should be defined');
        return;
      }
//      sandbox.request.resource.toggle(viewInstanceNwId, resourceNwId, type, projectorId, {state: state});
      alert("You made it!");
    }
  });
});