import React from 'react';
import { translate } from 'core/services/localization';


const labels = {
  message: translate('Please configue ICC Profiles in the User Preferences Setup')
};

function View() {

  return (
    <div className='crtx-form-section-container'>
      <div className='crtx-form-section'>
        <div className='crtx-form-full-row'>
          <label>{labels.message}</label>
        </div>
      </div>
    </div>
  );
}

View.propTypes = {

};

export default View;

