/**
 * Editable component is the container view with registered helper
 * which creates the same intuitive behavior experience as an
 * Ember.Component however with minor deviations.
 * Editable Field is a container view which chooses the type
 * of the editor depending on the type of the content
 * that it is provided.
 *
 * Editable Field currently supports 3 types of editing:
 * - regular input fields for the types of string and numbers
 * - checkbox field for the types of boolean values
 * - select (combobox) field for the object with properties {value: null, options: []}
 *
 * API
 * @param {string|number|boolean|object}  content     The underlying model of the editable field
 * @param {string}                        afterEdit   Name of the handler in the hosting controller to be invoked whenever the editing has occurred
 *
 * @author sergey
 */

define(['ember', './controllers/EditableController', './mixins/EditableMixins', 'text!./templates/template.hbs'],
  function (Ember, EditableController, EditableMixins, Template) {
    'use strict';

    var EditableField = Ember.ContainerView.extend({
      init: function () {
        this._super.apply(this, arguments);
        var that = this;
        this.set('controller', EditableController.create({
          contentBinding: '_view.content',
          afterEditHandler: that.get('afterEdit'),
          hostingController: that.get('parentView.controller'),
          _view: that
        }));
      },
      classNames: ['editable-field'],
      childViews: ['editableView'],
      editableView: function () {
        var data = this.get('content'), mixin;
        if (typeof data === 'boolean') {
          mixin = EditableMixins.CheckboxMixin;
        }
        else if (typeof data === 'object' && data.dataType === 'color') {
          mixin = EditableMixins.ColorPickerMixin;
        }
        else if (typeof data === 'object' && data.options !== void 0 && data.value !== void 0) {
          mixin = EditableMixins.SelectMixin;
        }
        else if (typeof data === 'object' && data.options !== void 0 && data.values !== void 0) {
          mixin = EditableMixins.MultiSelectMixin;
        }
        else {
          mixin = EditableMixins.TextMixin;
        }
        return Ember.View.createWithMixins(mixin, {
          template: Ember.Handlebars.compile(Template),
          controllerBinding: 'parentView.controller'
        });
      }.property('content'),
      doubleClick: function () {
        if (this.editableView.InputView) {
          this.switchToEditMode();
        }
      },
      click: function () {
        if (!this.isDraggable() && this.editableView.InputView) {
          this.switchToEditMode();
        }
      },
      focusIn: function () {
        if (!this.isDraggable() && this.editableView.InputView) {
          this.switchToEditMode();
        }
      },
      isDraggable: function () {
        return this.get('parentView').draggable == 'true';
      },
      switchToEditMode: function () {
        this.get('controller').send('edit');
      }
    });

    // Register the handlebars helper
    Ember.Handlebars.helper('editable-field', EditableField);

    return EditableField;
  });