import React from 'react';
import PropTypes from 'prop-types';
import iconService from 'core/services/iconService';
import { getMainStatus } from 'utilities/statuses';

export default class SeparationStatusIcon extends React.Component {
  static defaultProps = {
    separation: undefined,
    definition: undefined
  };

  getSparation = () => this.props.separation;

  getStatus = separation => getMainStatus(separation);

  getFlowStep = status => status?.flowStepType;

  getFlowStepIconName = status => status?.flowStepIconName;

  getStatusType = status => status?.statusType;

  getStatusIcon = (flowStep, flowStepIconName, statusType) => {
    if (!flowStep || typeof statusType === 'undefined') {
      return iconService.getGeneralIcon(null, 'empty');
    }

    return iconService.getTemplateIcon(flowStep, 'tiny', flowStepIconName);
  };

  render() {
    var separation = this.getSparation(),
      status = this.getStatus(separation),
      statusType = this.getStatusType(status),
      flowStep = this.getFlowStep(status),
      flowStepIconName = this.getFlowStepIconName(status),
      statusIcon = this.getStatusIcon(flowStep, flowStepIconName, statusType);
    return <div className="separation-item-status">
      <img src={statusIcon} />
    </div>;
  }
}

SeparationStatusIcon.propTypes = {
  definition: PropTypes.object,
  separation: PropTypes.object,
};