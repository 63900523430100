export const addSites = printingPlant => {
  return { type: "ADD_SITES", sites: printingPlant || [] };
};

export const addItem = page => {
  return { type: "ADD_ITEM", page };
};

export const updateItem = page => {
  return { type: "UPDATE_ITEM", page };
};

export const removeItem = page => {
  return { type: "REMOVE_ITEM", page };
};

export const selectItems = selectedItems => {
  return { type: "SELECT_ITEMS", selectedItems };
};

export const updateFilter = (prop, value) => {
  return { type: "UPDATE_FILTER", prop, value };
};

export const filterItems = () => {
  return { type: "FILTER_ITEMS" };
};

export const sortItems = columnsToSortBy => {
  return {
    type: "SORT_ITEMS",
    columnsToSortBy
  };
};

export const toggleFilters = pushed => {
  return {
    type: "TOGGLE_FILTERS",
    show: !pushed
  };
};

export const columnResize = (columnKey, columnWidth) => {
  return {
    type: "COLUMN_RESIZE",
    columnKey,
    columnWidth
  };
};

export const updatePreferences = (newPreferences) => {
  return {
    type: "UPDATE_PREFERENCES",
    newPreferences
  };
};

export const updateRowHeight = rowHeight => {
  return {
    type: 'UPDATE_ROW_HEIGHT',
    rowHeight
  };
};
