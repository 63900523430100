import React, { Component } from 'react';
import PropTypes from 'prop-types';
import utils from '../utils';
import SimpleFormContainer from '../SimpleFormContainer';

export default SimpleFormContainer(class extends Component {
  static propTypes = {

    //store - The main store of the SimpleForm.
    store: PropTypes.object,

    style: PropTypes.object,

    className: PropTypes.string,

    visible: PropTypes.bool

  };

  render() {

    var { store, style, col, offset, visible, className, children, ...props } = this.props;

    return <div className={utils.combineClassNames([className, 'row'])}
      style={style}
      {...props}>

      {children}

    </div>;

  }
}, true);
