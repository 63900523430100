import PropTypes from 'prop-types';
define(['react'],
  function (React) {
    'use strict';

    const { Component } = React;

    return class ToggleButton extends Component {

      static propTypes = {
        checked: PropTypes.bool,
        text: PropTypes.string,
        textOn: PropTypes.string,
        textOff: PropTypes.string,
        tooltip: PropTypes.string,
        tooltipOn: PropTypes.string,
        tooltipOff: PropTypes.string,
        imageSrc: PropTypes.string,
        imageSrcOn: PropTypes.string,
        imageSrcOff: PropTypes.string,
        className: PropTypes.string,
        classNameOn: PropTypes.string,
        classNameOff: PropTypes.string,
        textClassName: PropTypes.string,
        textClassNameOn: PropTypes.string,
        textClassNameOff: PropTypes.string,
        iconClassName: PropTypes.string,
        iconClassNameOn: PropTypes.string,
        iconClassNameOff: PropTypes.string,
        style: PropTypes.object,
        styleOn: PropTypes.object,
        styleOff: PropTypes.object,
        onClick: PropTypes.func,
        disabled: PropTypes.bool
      };

      static defaultProps = {
        text: '',
        tooltip: '',
        className: 'button',
        textClassName: 'button-text',
        iconClassName: 'button-icon',
        disabled: false
      };

      constructor(props) {
        super(props);
      }

      handleClick = (e) => {
        var { onClick, disabled } = this.props;

        if (typeof onClick === 'function' && !disabled) {
          onClick();
          e.preventDefault();
          e.stopPropagation();
        }
      };

      renderText = () => {
        var { checked, text, textOn, textOff, textClassName, textClassNameOn, textClassNameOff } = this.props;

        text = (checked ? textOn : textOff) || text;
        if (!text) {
          return null;
        }

        textClassName = (checked ? textClassNameOn : textClassNameOff) || textClassName;
        return (
          <span className={textClassName}>
            {text}
          </span>
        );
      };

      renderImage = () => {
        var { checked, imageSrc, imageSrcOn, imageSrcOff } = this.props;

        imageSrc = (checked ? imageSrcOn : imageSrcOff) || imageSrc;

        return !imageSrc ? null : <img src={imageSrc} />;
      };

      render() {
        var { checked, tooltip, tooltipOn, tooltipOff, className, classNameOn, classNameOff,
          iconClassName, iconClassNameOn, iconClassNameOff, disabled } = this.props;

        tooltip = (checked ? tooltipOn : tooltipOff) || tooltip;
        className = (checked ? classNameOn : classNameOff) || className;
        iconClassName = (checked ? iconClassNameOn : iconClassNameOff) || iconClassName;

        var style = (checked ? this.props.styleOn : this.props.styleOff) || this.props.style;

        if (disabled) {
          className += ' disabled';
        }

        if (checked) {
          className += ' checked';
        }

        return (
          <span className={className} title={tooltip} style={style} onClick={this.handleClick}>
            <span className={iconClassName}>
              {this.renderImage()}
            </span>
            {this.renderText()}
          </span>
        );
      }

    }

  });