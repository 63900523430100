import React, { Component } from 'react';
import Button from 'components/common/buttons/Button';
import Breadcrumbs from './breadcrumbs';
import ListItems from './listItems';
import PropTypes from 'prop-types';
// const ToggleButton = require('./toggleButton');

module.exports = class Browser extends Component {

  static propTypes = {
    store: PropTypes.object,
    root: PropTypes.string,
    rest: PropTypes.func
  }

  constructor(props) {
    super(props);
  }


  handleDoubleClick = (event, item, addOrRemove, index) => {
    const store = this.props.store;
    const history = store.getState().history;
    const currnetHistoryIndex = store.getState().currnetHistoryIndex;
    const cursor = addOrRemove === 'add' ? history[currnetHistoryIndex].concat(item) : history[currnetHistoryIndex].slice(0, index + 1);
    store.dispatch({
      type: 'SHOW_LOADER',
    });

    const rest = this.props.rest;
    rest(cursor, undefined, true);
  };

  navHandleClick = (direction) => {
    return function (event) {
      const store = this.props.store;
      var cuurHisIndex = store.getState().currnetHistoryIndex;
      if (direction === 'back') {
        cuurHisIndex = cuurHisIndex <= 0 ? 0 : cuurHisIndex - 1;
      }
      else {
        cuurHisIndex = cuurHisIndex + 1;
      }
      store.dispatch({
        type: 'SET_HISTORY_INDEX',
        currentHistoryIndex: cuurHisIndex,
      });
      const history = store.getState().history;
      const currnetHistoryIndex = store.getState().currnetHistoryIndex;
      const cursor = history[currnetHistoryIndex];
      const rest = this.props.rest;
      rest(cursor, undefined, false);
    }.bind(this);
  }

  handleViewStyleClick = (viewStyle) => {
    return function (event) {
      const store = this.props.store;
      store.dispatch({
        type: 'CHANGE_VIWESTYLE',
        viewStyle: viewStyle
      });
    }.bind(this);
  }

  handleClick = (event, selectedItem) => {
    const store = this.props.store;
    store.dispatch({
      type: 'SET_SELECTED_ITEM',
      selectedItem: selectedItem
    });
  }

  render() {
    const store = this.props.store;
    const history = store.getState().history;
    const currnetHistoryIndex = store.getState().currnetHistoryIndex;
    const cursor = history.length === 0 ? [] : history[currnetHistoryIndex];
    const loading = store.getState().loading;
    const viewStyle = store.getState().viewStyle;
    const checkedList = viewStyle === 'list-style' ? true : false;
    const checkedGrid = viewStyle === 'grid-style' ? true : false;
    const items = store.getState().items;
    const selectedItem = store.getState().selectedItem;
    const backDisabled = currnetHistoryIndex === 0 ? true : false;
    const forwardDisabled = currnetHistoryIndex < history.length - 1 ? false : true;

    return <div className='crtx-browser'>
      <div className='crtx-header'>
        <div className='crtx-navigations'>
          <Button className='back-button' disabled={backDisabled} onClick={this.navHandleClick('back')}>
            <img alt='Back' />
          </Button>
          <Button className='forward-button' disabled={forwardDisabled} onClick={this.navHandleClick('forward')}>
            <img alt='Forward' />
          </Button>
        </div>
        <Breadcrumbs cursor={cursor} onClick={this.handleDoubleClick} loading={loading} />
        {/*<div className={'crtx-viewStyle-button'}>
          <ToggleButton className={'list-style'} checked={checkedList} onClick={this.handleViewStyleClick('list-style') }>
            ListStyle
          </ToggleButton>
          <ToggleButton className={'grid-style'} checked={checkedGrid} onClick={this.handleViewStyleClick('grid-style') }>
            GridStyle
          </ToggleButton>
        </div>*/}
      </div>
      <div className='crtx-content'>
        <ListItems items={items} onDoubleClick={this.handleDoubleClick} viewStyle={viewStyle} onClick={this.handleClick} selectedItem={selectedItem} />
      </div>
    </div>;
  }
}


