import localization from 'core/services/localization';
import { getGeneralIcon, getModuleIcon } from 'core/services/iconService';

const { translate } = localization;

export const WAITING = 'Waiting';
export const SUCCESS = 'Success';
export const ERROR = 'Error';
export const NOT_ASSIGNED = 'NotAssigned';
export const UNPLANNED = 'Unplanned';
export const WAITING_FOR_APPROVAL = 'waiting_for_approval';
export const REJECT = 'reject';
export const EDITORIAL = 'EDITORIAL';
export const AD = 'AD';

export const COLORS_BY_STATUS = {
  [WAITING]: '#ffcd35',
  [SUCCESS]: '#3aea4b',
  [ERROR]: '#f84545',
  [NOT_ASSIGNED]: '#fff',
  [UNPLANNED]: '#aaa',
  [WAITING_FOR_APPROVAL]: '#5eced5',

};
export const STATUS_FILTER_OPTIONS = [
  {
    value: ERROR,
    data: { icon: getGeneralIcon('status', 'error'), title: translate('Error') }
  },
  {
    value: REJECT,
    data: { icon: getGeneralIcon('status', 'reject'), title: translate('Reject') }
  },
  {
    value: SUCCESS,
    data: { icon: getGeneralIcon('status', 'finished'), title: translate('Finished') }
  },
  {
    value: WAITING_FOR_APPROVAL,
    data: { icon: getGeneralIcon('status', 'waiting_for_approval'), title: translate('Waiting for approval') }
  },
  {
    value: NOT_ASSIGNED,
    data: { icon: getGeneralIcon('status', 'wait'), title: translate('Missing') }
  },
];



export const BW_OPT = {
  value: 'bw',
  data: { icon: getModuleIcon('TableView', 'bw'), title: translate('Black and white') }
};

export const COLOR_OPT = {
  value: 'color',
  data: { icon: getModuleIcon('TableView', 'color'), title: translate('Color') }
};

export const PAGE_COLOR_OPTIONS = [
  BW_OPT,
  COLOR_OPT,
];

export const DEFAULT_SORT = [
  {
    ascending: true,
    key: 'name'
  }
];

export const THROTTLE_WAIT = 1000;

export const TOOLBAR_BUTTONS_TO_COLUMN_KEYS_MAP = {
  'editorialFragments': 'contentType',
  'adFragments': 'contentType',
  'missingFragments': 'statusType',
  'errorFragments': 'statusType',
  'waitingForApprovalFragments': 'statusType'
};

export const COLUMN_KEYS_TO_TOOLBAR_BUTTONS_MAP = {
  'contentType': ['editorialFragments', 'adFragments'],
  'statusType': ['missingFragments', 'errorFragments', 'waitingForApprovalFragments']
};

export const TOOLBAR_BUTTONS_TO_COLUMN_FILTER_VALUES_MAP = {
  'editorialFragments': EDITORIAL,
  'adFragments': AD,
  'missingFragments': 'NotAssigned',
  'errorFragments': 'Error',
  'waitingForApprovalFragments': 'waiting_for_approval'
};

export const COLUMN_FILTER_VALUES_TO_TOOLBAR_BUTTONS_MAP = {
  'EDITORIAL': 'editorialFragments',
  'AD': 'adFragments',
  'Error': 'errorFragments',
  'waiting_for_approval': 'waitingForApprovalFragments',
  'NotAssigned': 'missingFragments'
};

export const DEFAULT_TYPE_OPTIONS = [
  {
    value: EDITORIAL,
    data: { columnData: EDITORIAL }
  },
  {
    value: AD,
    data: { columnData: AD }
  }];


