/**
 * @name
 * @fileOverview Editable Mixins that include the display logic and editable view
 * @author sergey
 */
define(['ember', 'widgets/MultiSelection/MultiSelection', 'kendo.colorpicker'], function (Ember, MultiSelectionWidget) {
  'use strict';
  /**
   * Common events mixin for editable view.
   * Support of focus out and enter to commit the
   * changes, as well as esc button to cancel the
   * editing mode
   */
  var EventMixin = Ember.Mixin.create({
    /*
     The instanceof check is a HACK due to inconsistency
     of Ember built-in views API. TextField view inherits from the
     Ember.Component whist other view like Ember.Select or Ember.Checkbox
     extend Ember.View.
     This leads to misuse of controller and their methods as well.
     */
    keyDown: function (event) {
      // esc
      if (event.keyCode === 27) {
        if (this instanceof Ember.Component) {
          this.sendAction('cancel');
        }
        else {
          this.get('controller').send('cancel');
        }
      }
      // enter
      if (event.keyCode === 13) {
        if (this instanceof Ember.Component) {
          this.sendAction('finish');
        }
        else {
          this.get('controller').send('finish');
        }
      }
      event.stopPropagation();
    },
    focusOut: function () {
      if (this instanceof Ember.Component) {
        this.sendAction('finish');
      }
      else {
        this.get('controller').send('finish');
      }
    }
  });

  function insertTabIndex() {
    // insert tabindex into table cell to use TAB key to move the focus on the next cell
    this.$().attr({tabindex: 0});
  }

  /**
   * Editable Mixins that include the
   * display logic and editable view
   */
  var TextMixin = Ember.Mixin.create({
    DisplayView: Ember.View.extend({
      classNames: ['display-view'],
      contentBinding: 'controller.content',
      didInsertElement: function () {
        insertTabIndex.call(this);
      }
    }),
    InputView: Ember.TextField.extend(EventMixin, {
      classNames: ['editable-view'],
      valueBinding: 'parentView.controller.content',
      didInsertElement: function () {
        // place the caret inside the text box
        this.$().focus();
        // move the caret to the end of text end
        var v = this.$().val();
        this.$().val(v);
      },
      finish: 'finish',
      cancel: 'cancel',
      doubleClick: function (event) {
        this._super.apply(this, arguments);
        event.stopImmediatePropagation();
      },
      drop: function (event) {
        event.preventDefault();
      }
    })
  });
  var CheckboxMixin = Ember.Mixin.create({
    DisplayView: Ember.Checkbox.extend({
      classNames: ['display-view'],
      checkedBinding: 'controller.content',
      didInsertElement: function () {
        insertTabIndex.call(this);
      },
      change: function () {
        this._super.apply(this, arguments);
        var hostingController, handlerName;
        hostingController = this.get('controller.hostingController');
        handlerName = this.get('controller.afterEditHandler');
        hostingController.send(handlerName, this.get('checked'), this.get('parentView.parentView'));
      }
    })
  });
  var SelectMixin = Ember.Mixin.create({
    DisplayView: Ember.View.extend({
      classNames: ['display-view'],
      contentBinding: 'controller.content.value',
      didInsertElement: function () {
        insertTabIndex.call(this);
      }
    }),
    valueDidChange: function () {
      if (this.get('controller') !== null && this.get('controller.content.value') === null) {
        Ember.set(this.get('controller.content'), 'value', this.get('controller.content.options.firstObject'));
      }
    }.observes('controller.content.value'),
    InputView: Ember.Select.extend(EventMixin, {
      classNames: ['editable-view'],
      contentBinding: 'parentView.controller.content.options',
      valueBinding: 'parentView.controller.content.value',
      didInsertElement: function () {
        this.$().focus();
      }
    })
  });

  var MultiSelectMixin = Ember.Mixin.create({
    DisplayView: Ember.View.extend({
      classNames: ['display-view'],
      content: function () {
        if (this.get('controller.content.values')) {
          return this.get('controller.content.values').join(', ');
        }
        else {
          return '';
        }
      }.property('controller.content.values.@each'),
      didInsertElement: function () {
        insertTabIndex.call(this);
      }
    }),
    InputView: MultiSelectionWidget.extend({
      classNames: ['editable-view'],
      contentBinding: 'parentView.controller.content.options',
      valuesBinding: 'parentView.controller.content.values',
      didInsertElement: function () {
        this.$().focus();
        this.get('parentView.controller').send('multiSelect');
      }
    }),
    Layer: Ember.View.extend({
      classNames: 'layer',
      click: function (event) {
        this.get('controller').send('finish');
        event.stopImmediatePropagation();
      }
    })
  });

  var ColorPickerMixin = Ember.Mixin.create({
    DisplayView: Ember.View.extend({
      isInputDisplayView: true,
      classNames: ['display-view'],
      valueDidChange: function () {
        var colorPicker = this.$().find('input').data("kendoColorPicker");
        colorPicker.value(this.get('controller.content.value'));
      }.observes('controller.content'),
      didInsertElement: function () {
        insertTabIndex.call(this);
        var content = this.get('controller.content');
        var that = this;
        var props = {
          value: content && content.value ? content.value : "#ffffff",
          change: function (e) {
            that.set('controller.content.value', e.value);
          }
        };
        if (content && Array.isArray(content.palette) && content.palette.length > 0) {
          props.palette = content.palette;
          props.columns = 8;
        }
        this.$().find('input').kendoColorPicker(props);
      }
    })
  });
  return {
    TextMixin: TextMixin,
    CheckboxMixin: CheckboxMixin,
    SelectMixin: SelectMixin,
    MultiSelectMixin: MultiSelectMixin,
    ColorPickerMixin: ColorPickerMixin
  };

});