import React from 'react';

export default class extends React.Component {
  static defaultProps = {
    plates: undefined,
    definition: undefined
  };

  renderPlatesCount = (plates) => {
    return Array.isArray(plates) ? plates.length : 0;
  };

  render() {
    var {
            plates,
      definition
          } = this.props;

    return <div className="separation-plates-count">
      {this.renderPlatesCount(plates)}
    </div>;
  }
}