import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from './FlatButton';

function ToggleFlatButton({
                            checked = false,
                            className = '',
                            ...props
                          }) {

  const cssClass = `crtx-toggle-flat-button ${className} ${checked ? 'checked' : ''}`;

  return (
    <FlatButton className={cssClass} {...props} />
  );
}

ToggleFlatButton.propTypes = {
  ...FlatButton.propTypes,
  checked: PropTypes.bool,
};

export default ToggleFlatButton;
