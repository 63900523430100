export default (state, action) => {
  switch (action.type) {
      case "ADD_URLS":
          return { ...state, imageUrls: action.imageUrls };
      case "UPDATE_COLOR_MANAGEMENT_MODE":
          return { ...state, isInColorManagementMode: action.isInColorManagementMode, imageUrls: {...state.imageUrls, currentPageNumber: action.currentPage }};
      default:
          return state;
  }
}
