import React, { Component } from 'react';
import sandbox from 'sandbox';
import PlateTable from './PlateTable';
import SeperationPreview from 'src/components/private/separations/SeperationPreview';
import Header from 'src/components/common/headers/Header';
import PropTypes from 'prop-types';

const linkIcon = sandbox.icons.getModuleIcon('MyBirdeye', 'link');
const translate = sandbox.localization.translate;

const labels = {
  nwid: translate('Nwid'),
  path: translate('Path'),
  expected: translate('Expected'),
}

module.exports = class Plates extends Component {
  constructor(props) {
    super(props);
    this.getGroupPlatesByProdRun = this.getGroupPlatesByProdRun.bind(this);
    this.getPlatesTabels = this.getPlatesTabels.bind(this);
    this.getNumberOfPlateRow = this.getNumberOfPlateRow.bind(this);
    this.getNumberOfPlates = this.getNumberOfPlates.bind(this);
  }

  static propTypes = {
    separations: PropTypes.any,
    title: PropTypes.string,
    advanceInfo: PropTypes.bool,
    openerType: PropTypes.string//dialog or rightPanel
  }

  getGroupPlatesByProdRun() {
    const { separations } = this.props;
    let groupPlatesByProdRun = {};
    separations.map(function (separation, sepIndex) {
      separation.plates.map(function (plate, plateIndex) {
        groupPlatesByProdRun[plate.productionRun] = groupPlatesByProdRun[plate.productionRun] || { productionRun: plate.productionRun, deadline: plate.presetTime, plates: [] };
        groupPlatesByProdRun[plate.productionRun].plates = groupPlatesByProdRun[plate.productionRun].plates.concat({
          separationName: separation.name,
          separationShared: separation.shared,
          press: plate.press,
          tower: plate.tower,
          cylinder: plate.cylinder,
          cylinderSector: plate.cylinderSector,
          cylinderLocation: plate.cylinderLocation,
          expected: plate.virtual,
          webReduction: plate.webReduction,
        });
      });
    });
    return groupPlatesByProdRun;
  }

  getPlatesTabels() {
    const groupPlatesByProdRun = this.getGroupPlatesByProdRun();
    let platesTabels = [];
    for (var plateTable in groupPlatesByProdRun) {
      platesTabels = platesTabels.concat(<PlateTable key={plateTable} plateTableStore={groupPlatesByProdRun[plateTable]} openerType={this.props.openerType} />);
    }
    return platesTabels;
  }

  getNumberOfPlateRow(separation, index) {
    const { advanceInfo } = this.props;
    const nwid = advanceInfo ? <div className='custom-info-row'><span className='custom-info-row-title'>{labels.nwid}: </span><span className='custom-info-row-content ellipsis' title={separation.nwid}>{separation.nwid || ''}</span></div> : "";
    const path = advanceInfo ? <div className='custom-info-row'><span className='custom-info-row-title'>{labels.path}: </span><span className='custom-info-row-content ellipsis-before' title={separation.actualPath}>{separation.actualPath || ''}</span></div> : "";

    return <div key={index}>
      <div className='custom-info-row'><SeperationPreview colorName={separation.name} /><span className='expected-number-of-plates'>{labels.expected}:</span><span className='number-of-plates'>{separation.numberOfPlates}</span></div>
      {nwid}
      {path}
    </div>;
  }

  getNumberOfPlates() {
    const { separations } = this.props;
    const numberOfPlateRow = separations.map(this.getNumberOfPlateRow);
    return numberOfPlateRow;
  }

  render() {
    const { separations, title } = this.props;
    let getPlatesView = () => { };
    const platesFound = separations.filter(function (separation, index) { return Array.isArray(separation.plates) && separation.plates.length > 0 });//get press data
    const numberOfPlatesFound = separations.filter(function (separation, index) { return separation.numberOfPlates != undefined; });

    if ((Array.isArray(platesFound) && platesFound.length > 0)) {
      getPlatesView = this.getPlatesTabels;
    }
    else if (Array.isArray(numberOfPlatesFound) && numberOfPlatesFound.length > 0) {
      getPlatesView = this.getNumberOfPlates;
    }

    return <div className='plates-custom-info'>
      <Header>{title}</Header>
      {getPlatesView()}
    </div>;
  }

}
