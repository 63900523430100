export const SPECIAL_CHARS = ".*+?^${}()|[]\\";
export const FILE_ILLEGAL_CHARS = '\\/:*?"<>|';

export function escapeRegExp(string) {
  if (!string) {
    return '';
  }

  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function unescapeRegExp(string) {
  if (!string) {
    return '';
  }

  return string.replace(/\\(.)/g, '$1');
}

export function replaceSpecialChars(string, specialChars = SPECIAL_CHARS, replacementChar = '_') {
  if (!string) {
    return '';
  }

  const regex = createRegex(specialChars, 'g');

  return string.replace(regex, replacementChar);
}

export function includesSpecialChars(string, specialChars = SPECIAL_CHARS) {
  if (!string) {
    return false;
  }

  const regex = createRegex(specialChars, '');

  return regex.test(string);
}

function createRegex(specialChars, flags) {
  return new RegExp('[' + escapeRegExp(specialChars) + ']', flags);
}