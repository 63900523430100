export const addItem = item => {
  return {type: 'ADD_ITEM', item};
};

export const updateItem = (item, index) => {
  return {type: 'UPDATE_ITEM', item, index};
};

export const removeItem = item => {
  return {type: 'REMOVE_ITEM', item};
};