module.exports = function (state, action) {

  switch (action.type) {
    case 'UPDATE_USER_NAME':
      return {
        ...state,
        userName: action.value
      };

    case 'UPDATE_PASSWORD':
      return {
        ...state,
        password: action.value
      };

    case 'ADD_POLICIES':
      return {
        ...state,
        policiesLinks: action.value
      };

    default:
      return {
        userName: '',
        password: '',
        policiesLinks: []
      };
  }
};
