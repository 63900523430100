import { Component } from 'react';
import PropTypes from 'prop-types';

class State extends Component {

  static propTypes = {
    name: PropTypes.string,
  };

  render() {
    return null;
  }
}

export default State;