/**
 * @name
 * @fileOverview
 * @author Tzvika
 */
define(['ember', 'text!../templates/activation.hbs'],
  function (Ember, ActivationTemplate) {
    'use strict';

    function getTemplates() {
      var globalTemplatesDscr = this.model.globalTemplates || {};
      var globalTemplates = [];
      if (this.model.publication === null || typeof this.model.publication === 'undefined') {
        return [];
      }
      var ret = [];
      var publicationName = this.model.publication.displayName;
      for (const property in globalTemplatesDscr) {
        const publications = globalTemplatesDscr[property];
        for (var i = 0; i < publications.length; i++) {
          if (publications[i] === publicationName) {
            ret.push({ display: property + '(Global)', label: property, isGlobal: true, isReal: true });
          }
        }
      }

      if (this.model.templates === null || typeof this.model.templates === 'undefined') {
        return ret;
      }

      var templates = this.model.templates[publicationName];
      if (templates === null || typeof templates === 'undefined') {
        return ret;
      }
      // if (ret.length > 0) {
      //   ret.push({ label: '-------' });
      // }
      for (var i = 0; i < templates.length; i++) {
        ret.push({ display: templates[i] + '(Local)', label: templates[i], isGlobal: false, isReal: true });
      }
      return ret;
    }

    return Ember.View.extend({
      layout: Ember.Handlebars.compile(ActivationTemplate),

      templates: function () {
        return getTemplates.call(this);
      }.property("model.publication", "model.templatesChangeFlag"),

      planInActionStyle: function () {
        var ret = "margin-top: 5px;width: 100%;align-items: center;height:15px;";
        if (this.model.publication === null || typeof this.model.publication === 'undefined') {
          return ret += "visibility:hidden;";
        }
        console.log("11111 = " + this.model.publication.inActivationMode);
        return ret += this.model.publication.inActivationMode ? "visibility:visible;" : "visibility:hidden;";
      }.property("model.publication.inActivationMode"),

      templatesOnly: function () {
        return "visibility:" + (this.controller.settings.hideTemplatesList ? "hidden" : "visible");
      }.property(),

      planActivatedStyle: function () {
        var ret = "width: 120px;height: 25px;vertical-align: top;";
        var dsblCss = "color: #afafaf;background-color: #ffffff;pointer-events: none;"
        if (this.model.publication === null || typeof this.model.publication === 'undefined') {
          return ret += "cursor: pointer";
        }
        return this.model.publication.activated || this.model.publication.inActivationMode || this.controller.settings.templateOnly ?
          ret + dsblCss : ret += "cursor: pointer";
      }.property("model.publication.activated", "model.publication.inActivationMode"),

      planActivated: function () {
        if (this.controller.settings.templateOnly) {
          return true;
        }

        if (this.model.publication === null || typeof this.model.publication === 'undefined') {
          return true;
        }
        if (this.model.publication.inActivationMode) {
          return true;
        }
        return this.model.publication.activated;
      }.property("model.publication.activated", "model.publication.inActivationMode"),

      displayStyle: function () {
        if (this.model.publication === null || typeof this.model.publication === 'undefined' ||
          this.model.templates === null || typeof this.model.templates === 'undefined') {
          return "visibility:hidden;width:100%";
        }
        var publicationName = this.model.publication.displayName;
        var templates = this.model.templates[publicationName];
        if (templates === null || typeof templates === 'undefined') {
          return "visibility:hidden;width:100%";
        }
        return "visibility:visible;width:100%";
      }.property("model.publication", "model.templatesChangeFlag"),

      activationPanelStyle: function () {
        console.log("activated " + this.controller.activated);
      }.property('controller.activated', "model.templatesChangeFlag")



    });
  });