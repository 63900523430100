import React, { Component } from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import SortableItem from './SortableItem';
import PropTypes from 'prop-types';
import SimpleForm from 'widgets/SimpleForm/src/index';

const { Checkbox } = SimpleForm;

const isString = (o) => {
  return typeof o === 'string';
};
const emptyFunction = function () { };

module.exports = SortableContainer(class SortableList extends Component {
  constructor(props) {
    super(props);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.renderItems = this.renderItems.bind(this);
  }

  static propTypes = {
    items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    className: PropTypes.string,
    style: PropTypes.object,
    textProp: PropTypes.string,
    valueProp: PropTypes.string,
    selectedValue: PropTypes.any,
    selectedIndex: PropTypes.number,
    onSelect: PropTypes.func,
    disabled: PropTypes.bool
  }

  static defaultProp = {
    textProp: 'text',
    valueProp: 'value',
    onSelect: emptyFunction,
    disabled: false
  }

  handleItemClick(value, index, item) {
    return function (ev) {
      this.props.onSelect(ev, value, index, item);
    }.bind(this);
  }

  isSelected(item, index) {
    const { valueProp, selectedValue, selectedIndex } = this.props;
    const value = isString(item) ? item : item[valueProp];
    if (typeof selectedIndex === 'number') return index === selectedIndex;
    return value === selectedValue;
  }

  renderItem(item, index) {
    const { textProp, valueProp, selectedValue, disabled, tablePath, columnType } = this.props;
    const text = isString(item) ? item : item[textProp];
    const value = isString(item) ? item : item[valueProp];
    const isSelected = this.isSelected(item, index);
    const columnPinForScrollPath = `${tablePath}.${columnType}[${index}].pinColumnForScroll`;

    return <SortableItem key={index} index={index} value={value} selected={isSelected} onClick={this.handleItemClick(value, index, item)} allowToOrder={!disabled}>
      <div className="list-item-content">
        <label className='list-item-content-text-label' title={text}>{text}</label>
        <Checkbox bind={columnPinForScrollPath} actionType="UPDATE_CURRENT_COLUMN_PROPERTY" disabled={disabled} />
      </div>
    </SortableItem>;
  }

  renderItems(items) {
    return items.map(this.renderItem);
  }

  render() {
    const { items, className, style, textProp, valueProp } = this.props;
    const listClassName = ['list', className].join(' ');
    return <ul className={listClassName} style={style}>
      {this.renderItems(items)}
    </ul>;
  }
});
