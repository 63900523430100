/**
 * @name erase
 * @file Defines Erase element functionality
 *
 * @author Boris
 * @since: 2016-07-28
 */

import React from 'react';
import Fabric from 'fabric';
import sandbox from 'sandbox';
import SimpleForm from 'widgets/SimpleForm/src/index';
import utils from '../utils/utils';
import canvasUtils from '../utils/canvasUtils';
import propertiesCommon from './propertiesCommon';
import customConditions from './customConditions';

const {translate} = sandbox.localization;
const futils = SimpleForm.utils;

const BASE_PATH = 'layout.erases';
const TITLE = translate('Erase');

const FILL_COLOR = '#FFCCCC';
const STROKE = 'black';
const OPACITY = 0.8;

const {DISPLAY_NAME, X, Y, WIDTH, HEIGHT, _CORNER, _ALIGNMENT, APPLY_ON, BLACK_ONLY} = propertiesCommon;

const ERASE_META = [
  DISPLAY_NAME,
  X,
  Y,
  WIDTH,
  HEIGHT,
  _CORNER,
  _ALIGNMENT,
  APPLY_ON,
  BLACK_ONLY
];

export default (editor) => {

  const canvas = editor.getMainCanvas();
  const cutils = canvasUtils(editor);

  const getMeta = (state, element) => {
    return ERASE_META;
  };

  const buildDefaultElement = (state, elementType) => {
    const element = {
      elementType: elementType,
      locked: false,
      _selectable: true
    };

    setDefaultElementValues(state, element);

    return element;
  };

  const setDefaultElementValues = (state, element) => {
    propertiesCommon.setDefaultValues(state, element, getMeta(state, element));
    element.conditions = utils.fromPseudoArrayDeep(element.conditions) || [];
  };

  const updateProperty = (state, element, elementPath, propertyPath, propertyValue) => {
    var newState = propertiesCommon.updateProperty(state, element, elementPath, propertyPath, propertyValue);
    var newElement = futils.get(newState, elementPath);

    updateShape(newState, newElement);

    return newState;
  };

  const shapeTransforming = (state, element) => {
    var eraseRect = element.shape.eraseRect;
    utils.applyConstraints(eraseRect, state.plateRectangle);
    var r = utils.toSystemRectangle(state.plateRectangle, eraseRect.left, eraseRect.top, eraseRect.width, eraseRect.height,
      element.referenceX, element.referenceY);
    var newElement = {...element, x: r.x, y: r.y, width: r.width, height: r.height};

    updateShape(state, newElement);

    return newElement;
  };

  const createEraseRect = (element) => {
    const selectable = element._selectable;
    const eraseRect = new Fabric.Rect({
      hasRotatingPoint: false,
      centeredRotation: false,
      selectable: selectable,
      evented: selectable,
      fill: FILL_COLOR,
      stroke: STROKE,
      opacity: OPACITY,
    });

    return eraseRect;
  };

  const updateEraseRect = (state, element) => {
    const locked = element.locked || !element._selectable;
    const r = utils.toCanvasRectangle(state.plateRectangle, element.x, element.y, element.width, element.height,
      element.referenceX, element.referenceY);
    const eraseRect = element.shape.eraseRect;
    eraseRect.set({
      left: r.left,
      top: r.top,
      width: r.width,
      height: r.height,
      originX: element.alignmentX,
      originY: element.alignmentY,
      hasControls: !locked,
      lockMovementX: locked,
      lockMovementY: locked,
      lockScalingX: locked,
      lockScalingY: locked,
      hoverCursor: locked ? 'default' : 'move',
    });
    eraseRect.setCoords();
  };

  const createShape = (state, element, elementPath) => {
    var eraseRect = createEraseRect(element);

    var shape = {
      elementPath,
      eraseRect
    };

    eraseRect.shape = shape;
    element.shape = shape;

    updateShape(state, element);
    addShape(state, element);

    return shape;
  };

  const addShape = (state, element) => {
    if (!element || !element.shape) {
      return;
    }

    canvas.add(element.shape.eraseRect);
  };

  const removeShape = (state, element) => {
    if (!element || !element.shape) {
      return;
    }

    var shape = element.shape;
    canvas.remove(shape.eraseRect);
  };


  const updateShape = (state, element) => {
    updateEraseRect(state, element);
  };

  const activateShape = (state, element) => {
    cutils.setActiveObject(element.shape.eraseRect);
  };

  const nudgeShape = (state, element, direction) => {
    let newElement = element;
    if (!element.locked) {
      newElement = propertiesCommon.nudgeElement(state, element, direction);
      updateShape(state, newElement);
    }

    return newElement;
  };

  const renderProperties = (store, element, elementPath) => {
    const state = store.getState();
    const meta = getMeta(state, element);

    return (
      <div>
        {propertiesCommon.renderProperties(store, element, elementPath, meta)}
        {customConditions.render(store, element, elementPath)}
      </div>
    );
  };

  const getInfo = (state) => {
    return {
      basePath: BASE_PATH,
      title: TITLE
    };
  };

  const getAlignmentInfo = (state, element) => {
    return utils.getAlignmentInfo(element.shape.eraseRect);
  };

  return {
    buildDefaultElement,
    setDefaultElementValues,
    updateProperty,
    shapeTransforming,
    createShape,
    updateShape,
    addShape,
    removeShape,
    activateShape,
    nudgeShape,
    renderProperties,
    getInfo,
    getAlignmentInfo
  }

};
