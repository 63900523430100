import React from 'react';
import PropTypes from 'prop-types';
import ReactDataGrid from 'widgets/ReactDataGrid/src/ReactDataGrid';
import sandbox from 'sandbox';
import { fromServerDate } from 'core/dates';
import ConsoleDialog from './console';


const { Component } = React;
var { Table, Column } = ReactDataGrid;
var { SortableHeader } = ReactDataGrid.headers;
var { sort } = ReactDataGrid.operators;
var { translate } = sandbox.localization;




var rows = (function (count) {
  var _rows = [];
  for (var i = 0; i < count; i++) {
    _rows.push({
      id: i,
      name: i.toString()
    });
  }
  return _rows;
}(164));


class Cell extends Component {
  constructor(props) {
    super(props);
    this.cellStyle = this.cellStyle.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  static propTypes = {

    columnData: PropTypes.any,

    style: PropTypes.any,

    onClick: PropTypes.func

  }

  static defaultProps = {
    style: {}
  }

  cellStyle(cellData) {
    var { importedEditionVersion, editionVersion } = this.props.rowContent;

    return {
      fontWeight: importedEditionVersion !== editionVersion ? 'bold' : null
    };
  }

  handleClick(ev) {
    if (typeof this.props.onClick === 'function') {
      this.props.onClick(ev);
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.rowIndex !== this.props.rowIndex || nextProps.columnData !== this.props.columnData;
  }

  render() {
    var { columnData, onClick } = this.props;
    var cellStyle = this.cellStyle(columnData);
    cellStyle.cursor = cellStyle.cursor || (typeof onClick === 'function' ? 'pointer' : null);

    return <div className="cell" onClick={this.handleClick} style={cellStyle}>
      <div className="cell-content">
        <div className="cell-content-padding">
          {columnData}
        </div>
      </div>
    </div>;
  }
}

class CheckBoxCell extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.rowIndex !== this.props.rowIndex || nextProps.columnData !== this.props.columnData;
  }

  handleChange() {
    var { rowIndex, onChange } = this.props;

    onChange(rowIndex);
  }

  render() {
    var { columnData } = this.props;
    var cellStyle = {
      width: '100%',
      height: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    };

    return <div style={cellStyle}>
      <div className="cell-content">
        <input type="checkbox" checked={columnData} onChange={this.handleChange} />
      </div>
    </div>;
  }
}

class DateCell extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  static propTypes = {

    columnData: PropTypes.any,

    style: PropTypes.any,

    onClick: PropTypes.func

  }

  static defaultProps = {
    style: {}
  }

  handleClick(ev) {
    if (typeof this.props.onClick === 'function') {
      this.props.onClick(ev);
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.columnData !== this.props.columnData;
  }

  render() {
    var { columnData, onClick, style } = this.props;

    return <div className="cell" style={style}>
      <div className="cell-content">
        <div className="cell-content-padding">
          {sandbox.localization.toLocaleShortDateTime(fromServerDate(columnData))}
        </div>
      </div>
    </div>;
  }
}


function isGreaterThan(currentVersion, availableVersion) {
  return currentVersion !== availableVersion;
}

function cellStyle(cellData) {
  return {
    fontWeight: isGreaterThan(cellData.importedEditionVersion, cellData.editionVersion) ? 'bold' : null
  };
}


module.exports =  class CTPImportSelected extends Component {
  constructor(props) {
    super(props);
    this.getSortDirection = this.getSortDirection.bind(this);
    this.toggleImport = this.toggleImport.bind(this);
    this.selectEditionsByDate = this.selectEditionsByDate.bind(this);
    this.selectEditionsByPublication = this.selectEditionsByPublication.bind(this);
    this.toggleSort = this.toggleSort.bind(this);
  }

  static propTypes = {

    showLoadingProgress: PropTypes.bool,

    items: PropTypes.array,

    viewState: PropTypes.string,

    toggleImport: PropTypes.func,

    toggleSort: PropTypes.func,

    selectEditionsByDate: PropTypes.func,

    selectEditionsByPublication: PropTypes.func,

    consoleMessage: PropTypes.string,

    onOKButtonClick: PropTypes.func,

    onCancelButtonClick: PropTypes.func,

    onCloseButtonClick: PropTypes.func

  }

  static defaultProps = {

    items: [],

    toggleImport: function () { },

    toggleSort: function () { },

    selectEditionsByDate: function () { },

    selectEditionsByPublication: function () { }
  }

  getSortDirection(columnKey) {
    const { sortColumnKey, sortOrder } = this.props;

    return sortColumnKey === columnKey ? sortOrder : '';
  }

  toggleImport(rowIndex) {
    this.props.toggleImport(rowIndex);
  }

  selectEditionsByDate(rowIndex) {
    this.props.selectEditionsByDate(this.props.items[rowIndex].date);
  }

  selectEditionsByPublication(rowIndex) {
    var date = this.props.items[rowIndex].date;
    var publicationId = this.props.items[rowIndex].publicationId;

    this.props.selectEditionsByPublication(date, publicationId);
  }

  toggleSort(columnKey) {
    this.props.toggleSort(columnKey);
  }

  render() {
    var that = this;
    var {
      viewState,
      showLoadingProgress,
      items,
      consoleMessage
    } = this.props;
    var headerHeight = 60;
    var rowHeight = 29;
    var tableStyle = {
      display: viewState === 'table' ? '' : 'none'
    };
    var progressConsoleStyle = {
      display: viewState === 'console' ? '' : 'none'
    };


    return <div className="pro-modules-ctpimportselected">

      <div className="table" style={tableStyle}>
        <Table headerHeight={headerHeight}
          rowHeight={rowHeight}
          rows={items}
          selectableRows={false}
          onRowClick={function (rowIndex, rowContent) { }}
          columnKey='editionId'
          autoHeight={false}
          virtualScroll={true}
          showSpinner={showLoadingProgress}
          spinnerMessage={translate('Loading') + "...."}
          getRowClassName={(rowIndex) => { return []; }}
        >

          <Column columnKey="import"
            width={20}
            align="center"
            autoWidth={true}
            header={
              (props) => <SortableHeader {...props} sort={this.getSortDirection('import')} >
                {translate('IMPORT')}
              </SortableHeader>
            }
            cell={
              <CheckBoxCell onChange={that.toggleImport} />
            }
            cellDataGetter={(rowIndex, columnKey) => {
              return {
                columnData: items[rowIndex][columnKey]
              };
            }}
            shouldCellUpdate={(nextProps, props) => {
              return nextProps.columnData !== props.columnData;
            }}
          />

          <Column columnKey="date"
            width={150}
            align="center"
            autoWidth={false}
            onClick={() => { this.toggleSort('date') }}
            header={(props) => <SortableHeader {...props} sort={this.getSortDirection('date')} >
              {translate('DATE')}
            </SortableHeader>
            }
            cell={(props) => <Cell {...props} onClick={() => this.selectEditionsByDate(props.rowIndex)} style={cellStyle(items[props.rowIndex])} />}
            cellDataGetter={(rowIndex, columnKey) => {
              return {
                columnData: items[rowIndex][columnKey]
              };
            }}
            shouldCellUpdate={(nextProps, props) => {
              return nextProps.columnData !== props.columnData;
            }}
          />

          <Column columnKey="publicationName"
            width={20}
            align="center"
            autoWidth={true}
            onClick={() => { this.toggleSort('publicationName') }}
            header={(props) => <SortableHeader {...props} sort={this.getSortDirection('publicationName')} >
              {translate('PUBLICATION')}
            </SortableHeader>
            }
            cell={
              (props) => <Cell {...props} onClick={() => this.selectEditionsByPublication(props.rowIndex)} style={cellStyle(items[props.rowIndex])} />
            }
            cellDataGetter={(rowIndex, columnKey) => {
              return {
                columnData: items[rowIndex][columnKey]
              };
            }}
            shouldCellUpdate={(nextProps, props) => {
              return nextProps.columnData !== props.columnData;
            }}
          />

          <Column columnKey="editionName"
            width={20}
            align="center"
            autoWidth={true}
            header={<SortableHeader sort={this.getSortDirection('editionName')} >
              {translate('PRINT PRODUCT')}
            </SortableHeader>
            }
            cell={<Cell />}
            cellDataGetter={(rowIndex, columnKey) => {
              return {
                columnData: items[rowIndex][columnKey]
              };
            }}
            shouldCellUpdate={(nextProps, props) => {
              return nextProps.columnData !== props.columnData;
            }}
          />

          <Column columnKey="importedEditionVersion"
            width={20}
            align="center"
            autoWidth={true}
            header={(props) => <SortableHeader {...props} sort={this.getSortDirection('importedEditionVersion')} >
              {translate('CURRENT VERSION')}
            </SortableHeader>
            }
            cell={(props) => <Cell {...props} style={cellStyle(items[props.rowIndex])} />}
            cellDataGetter={(rowIndex, columnKey) => {
              return {
                columnData: items[rowIndex][columnKey]
              };
            }}
            shouldCellUpdate={(nextProps, props) => {
              return nextProps.columnData !== props.columnData;
            }}
          />

          <Column columnKey="editionVersion"
            width={20}
            align="center"
            autoWidth={true}
            header={(props) => <SortableHeader {...props} sort={this.getSortDirection('editionVersion')} >
              {translate('AVAILABLE VERSION')}
            </SortableHeader>
            }
            cell={(props) => <Cell {...props} style={cellStyle(items[props.rowIndex])} />}
            cellDataGetter={(rowIndex, columnKey) => {
              return {
                columnData: items[rowIndex][columnKey]
              };
            }}
            shouldCellUpdate={(nextProps, props) => {
              return nextProps.columnData !== props.columnData;
            }}
          />

          <Column columnKey="editionTimeStamp"
            width={20}
            align="left"
            autoWidth={true}
            header={(props) => <SortableHeader {...props} sort={this.getSortDirection('editionTimeStamp')} >
              {translate('LAST MODIFIED IN MPS')}
            </SortableHeader>
            }
            cell={(props) => <Cell {...props} style={cellStyle(items[props.rowIndex])} />}
            cellDataGetter={(rowIndex, columnKey) => {
              return {
                columnData: items[rowIndex][columnKey]
              };
            }}
            shouldCellUpdate={(nextProps, props) => {
              return nextProps.columnData !== props.columnData;
            }}
          />

          <Column columnKey="editionId"
            width={20}
            align="center"
            autoWidth={true}
            header={(props) => <SortableHeader {...props} sort={this.getSortDirection('editionId')} >
              {translate('PRINT PRODUCT ID')}
            </SortableHeader>
            }
            cell={(props) => <Cell {...props} style={cellStyle(items[props.rowIndex])} />}
            cellDataGetter={(rowIndex, columnKey) => {
              return {
                columnData: items[rowIndex][columnKey]
              };
            }}
            shouldCellUpdate={(nextProps, props) => {
              return nextProps.columnData !== props.columnData;
            }}
          />


        </Table>
      </div>

      {(() => {
        if (viewState === 'console') {
          return <div className="progress-console" style={progressConsoleStyle}>
            <ConsoleDialog message={consoleMessage} />
          </div>;
        }
      })()}

      <div className="view-footer">
        {(() => {
          if (viewState === 'table') {
            return <div className="row">
              <button className="primary" onClick={this.props.onOKButtonClick}>{translate('OK')}</button>
              <button onClick={this.props.onCancelButtonClick}>{translate('Cancel')}</button>
            </div>;
          }
        })()}
        {(() => {
          if (viewState === 'console') {
            return <div className="row">
              <button className="primary" onClick={this.props.onCloseButtonClick}>{translate('Close')}</button>
            </div>;
          }
        })()}
      </div>

    </div>;

  }

}


