import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SeperationPreview from 'components/private/separations/SeperationPreview';
import { LengthOutput } from 'components/common/outputs';
import { restGet } from 'core/managers/rest2';
import { translate } from 'core/services/localization';
import settingsManager from 'core/managers/settings';
import { inchesToUserUnitsRounded } from 'utilities/lengthUnits';

const labels = {
  correction: translate('Correction'),
  none: translate('None'),
  direct: translate('Direct'),
};

const COLORS = ['black', 'cyan', 'magenta', 'yellow'];

function ProductionRun({ run, module }) {

  const { direct, shortLabel, correction } = run;
  const isDirect = direct['black'] || direct['cyan'] || direct['magenta'] || direct['yellow'];
  const [tableData, setTableData] = useState();
  const fanoutData = tableData?.rows?.find(row => row.selectors.name === correction)?.values || {};
  const unit = settingsManager.getLengthUnit();

  useEffect(() => {
    restGet(module.nwid, `conversion-tables/${encodeURIComponent('Simple Form Fanout')}`).then((data) => {
      setTableData(data);
    });
  }, []);

  const getColors = () => {
    return <span className='regular-colors-content'>{COLORS.map(renderColor)}</span>;
  };

  const renderColor = (color, index) => {

    if (direct[color]) {
      return <SeperationPreview key={index} colorName={color} />;
    }
  };

  return (
    <React.Fragment>
      {(isDirect || correction) &&
        <React.Fragment>
          <div className='production-run'>
            <div className='custom-info-row'>
              <span className='custom-info-row-main-title'>{shortLabel}</span>
            </div>
            {isDirect && <div className='custom-info-row'>
              <span className='custom-info-row-title'>{labels.direct}: </span>
              <span className='custom-info-row-content'>
                {getColors()}
              </span>
            </div>}
            {correction && <React.Fragment>
              <div className='custom-info-row'>
                <span className='custom-info-row-title'>{labels.correction}: </span>
                <span className='custom-info-row-content'>
                  {correction}
                </span>
              </div>
              <div>
                {fanoutData && <React.Fragment>
                  <div className='crtx-fanout-details-general'>
                    <label>{translate('Scale Anchor X:')}</label>
                    <div>{fanoutData.AnchorPoint}</div>
                    <label>{translate('Rotation Pivot:')}</label>
                    <div>{fanoutData.RotationPivot}</div>
                    <label>{translate('Registration Width:')}</label>
                    <LengthOutput value={fanoutData.regWidth} unit={unit} />
                    <label>{translate('Scale Anchor Y:')}</label>
                    <div>{fanoutData.AnchorPointY}</div>
                  </div>
                  <div className='crtx-fanout-details-table'>
                    <div></div>
                    <div className='column-header' title={translate('Scale X')}> {translate('sX')}</div>
                    <div className='column-header' title={translate('Offset X')}>{translate('oX')}</div>
                    <div className='column-header' title={translate('Rotation')}>{translate('Rot.')}</div>
                    <div className='column-header' title={translate('Offset Y')}>{translate('oY')}</div>
                    <div className='column-header' title={translate('Scale Y')}>{translate('sY')}</div>

                    <SeperationPreview colorName={'black'} />
                    <div className='fanout-correction-value'>{inchesToUserUnitsRounded(fanoutData.black, unit)}</div>
                    <div className='fanout-correction-value'>{inchesToUserUnitsRounded(fanoutData.offset_black_x, unit)}</div>
                    <div className='fanout-correction-value'>{inchesToUserUnitsRounded(fanoutData.rotate_offset_black, unit)}</div>
                    <div className='fanout-correction-value'>{inchesToUserUnitsRounded(fanoutData.offset_black_y, unit)}</div>
                    <div className='fanout-correction-value'>{inchesToUserUnitsRounded(fanoutData.black_y, unit)}</div>

                    <SeperationPreview colorName={'cyan'} />
                    <div className='fanout-correction-value'>{inchesToUserUnitsRounded(fanoutData.cyan, unit)}</div>
                    <div className='fanout-correction-value'>{inchesToUserUnitsRounded(fanoutData.offset_cyan_x, unit)}</div>
                    <div className='fanout-correction-value'>{inchesToUserUnitsRounded(fanoutData.rotate_offset_cyan, unit)}</div>
                    <div className='fanout-correction-value'>{inchesToUserUnitsRounded(fanoutData.offset_cyan_y, unit)}</div>
                    <div className='fanout-correction-value'>{inchesToUserUnitsRounded(fanoutData.cyan_y, unit)}</div>

                    <SeperationPreview colorName={'magenta'} />
                    <div className='fanout-correction-value'>{inchesToUserUnitsRounded(fanoutData.magenta, unit)}</div>
                    <div className='fanout-correction-value'>{inchesToUserUnitsRounded(fanoutData.offset_magenta_x, unit)}</div>
                    <div className='fanout-correction-value'>{inchesToUserUnitsRounded(fanoutData.rotate_offset_magenta, unit)}</div>
                    <div className='fanout-correction-value'>{inchesToUserUnitsRounded(fanoutData.offset_magenta_y, unit)}</div>
                    <div className='fanout-correction-value'>{inchesToUserUnitsRounded(fanoutData.magenta_y, unit)}</div>

                    <SeperationPreview colorName={'yellow'} />
                    <div className='fanout-correction-value'>{inchesToUserUnitsRounded(fanoutData.yellow, unit)}</div>
                    <div className='fanout-correction-value'>{inchesToUserUnitsRounded(fanoutData.offset_yellow_x, unit)}</div>
                    <div className='fanout-correction-value'>{inchesToUserUnitsRounded(fanoutData.rotate_offset_yellow, unit)}</div>
                    <div className='fanout-correction-value'>{inchesToUserUnitsRounded(fanoutData.offset_yellow_y, unit)}</div>
                    <div className='fanout-correction-value'>{inchesToUserUnitsRounded(fanoutData.yellow_y, unit)}</div>
                  </div>
                </React.Fragment>}
              </div>
            </React.Fragment>}
          </div>
        </React.Fragment>
      }
    </React.Fragment>);
}

ProductionRun.propTypes = {
  run: PropTypes.any,
  module: PropTypes.object
};

export default ProductionRun;

