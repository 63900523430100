/**
 * @name
 * @fileOverview
 * @author Moshe Malka
 */

import AbstractAction from 'AbstractAction';

module.exports = AbstractAction.extend({
  isApplicable: function(){
    return !this.module.isLocked || !this.module.isLocked();
  },
  
  execute: function () {
    this.module.autoLayout();
  }
});
