const emptyFunction = function () {};

class Store {

  constructor (state, reducer, onDispach) {
    this.state = reducer(state);
    this.reducer = reducer;
    this.subscribers = typeof onDispach === 'function' ? [onDispach] : [];
  } 

  getState () {
    return this.state;
  }

  subscribe (onDispach) {
    if (typeof onDispach !== 'function') return emptyFunction;
      
    this.subscribers.push(onDispach);

    return () => {
      this.subscribers = this.subscribers.filter((subscriber) => {
        return subscriber !== onDispach;
      });
    };
  }

  dispatch (action) {
    this.state = this.reducer(this.state, action);
    this.subscribers.forEach((subscriber) => {
      subscriber(this.state);
    });
  }

};

module.exports = Store;