/**
 * Created by moshemal on 9/15/14.
 */


define(['ember', 'ember_table'], function (Ember) {
	'use strict';
	var FluidTableHeaderCell = Ember.Table.HeaderCell.extend({
		onColumnStartResize: function() {
			var column = this.get('column'),
					tableColumns = this.get('controller.tableColumns'),
					currentColumnIndex = 0,
					sumWidths = 0;
			tableColumns.find(function(elem, index){
				if(elem === column){
					currentColumnIndex = index;
					return true;
				}
				sumWidths += elem.get('columnWidth');
				return false;
			});
			var nextColumns = tableColumns.slice(currentColumnIndex),
					sumMinWidths = 0;
			nextColumns.forEach(function(elem, index){
				sumMinWidths += elem.get('isResizable') ? (elem.get('minWidth') || 50) : elem.get('columnWidth');

			})
			var maxWidth = this.get('controller._tableContainerWidth') - sumWidths - sumMinWidths;
			this.$().resizable("option", "maxWidth", maxWidth);
		}
	});

	return Ember.Table.ColumnDefinition.extend({
		headerCellViewClass: FluidTableHeaderCell,
		resize: function(pxWidth, tableWidth) {
			var tableWidth = tableWidth || this.get("controller._tableContainerWidth");
			if (!tableWidth) {
				return;
			}

			function percent(val) {
				if ("string" === typeof val) {
					return +(val.replace("%", ""));
				} else {
					return val * 100 / tableWidth;
				}
			}
			var delta,
					tableColumns = this.get('controller.tableColumns'),
			    currentColumnIndex = tableColumns.indexOf(this),
					oldWidth = percent(this.get("columnWidth")),
			    newWidth = 'number' === typeof pxWidth ? percent(pxWidth) : oldWidth,
					resizableColumns =  tableColumns.slice(currentColumnIndex + 1).filter(function (col) {
						return col.get('isResizable');
					});

			if (resizableColumns.length !== 0 && oldWidth !== newWidth) {
				delta = (newWidth - oldWidth) * tableWidth / (100 * resizableColumns.length);
				resizableColumns.forEach(function(elem){
					var last = elem.get('columnWidth');
					elem.set('columnWidth', last - delta );
				});
			}
			this.set("columnWidth", newWidth * tableWidth / 100);
			this.notifyPropertyChange("columnWidth");
		},
		_convertColumnToWidth: Ember.beforeObserver(function() {
			var tableWidth;
			tableWidth = this.get("controller._tableContainerWidth");
			if (tableWidth) {
				return this.set("columnWidth", this.get("columnWidth") / tableWidth * 100 + "%");
			}
		}, "controller._tableContainerWidth"),
		_resizeToTable: Ember.observer(function() {
			return this.resize();
		}, "controller._tableContainerWidth"),
		_resizeToNColumns: Ember.observer(function() {
			var width 				= this.get('columnWidth'),
					sum 					= this.get('controller._sumOfColumnsWidths'),
					percentWidth	= 0,
					tableWidth 		= this.get("controller._tableContainerWidth");
			if (!tableWidth){
				return;
			}
			if ( width === void 0) {
				percentWidth = 100 / this.get('controller.tableColumns.length');
			} else if (sum > 0){
				percentWidth = parseInt(width) / sum ;
			}
			this.set("columnWidth", percentWidth * tableWidth)
		}, 'controller.tableColumns')
	});
});