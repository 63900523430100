import React from 'react';
import AbstractModule from 'AbstractModule';
import sandbox from 'sandbox';
import { createStore } from 'redux';
import reducer from './reducer';
import { createRoot } from 'react-dom/client';
import Chat from './Chat';
import settingsManager from 'core/managers/settings';
import { setChannelMessagesAreRead } from './utils';
import { restPut } from 'core/managers/rest2';

const isFunction = o => typeof o === 'function';

export default AbstractModule.extend({
  initDone: function () {
    for (var key in this) {
      if (key !== '_super' && isFunction(this[key])) {
        this[key] = this[key].bind(this);
      }
    }

    this.reactRoot = createRoot(this.domElement);
    this.store = createStore(reducer);
    this.store.subscribe(this.render);
    this.getRequiredParameters();
    this.timeoutId = 0;
    this.service = sandbox.getService('chat');

    if (this.service) {
      this.service.default.registerModule(this);
      this.unregister = this.service.default.register(this.handleServiceUpdate);
    }

  },

  handleServiceUpdate: function (model) {
    const { isChannelSubjectView, selectedId, channelsByNwid } = this.store.getState();
    const viewInstanceNwId = this.viewSettings.nwid;
    const currentUser = settingsManager.get('user');

    this.store.dispatch({ type: 'UPDATE_MODEL', model: model });
    this.store.dispatch({ type: 'SET_NOT_RECEIVED_MESSAGES', notReceivedMessageNwids: model.notReceivedMessageNwids });

    if (isChannelSubjectView) {
      this.store.dispatch({ type: 'SET_CHATS_VIEW' });
    }
    this.setAllMessagesAreReceived();
    setChannelMessagesAreRead(channelsByNwid[selectedId], viewInstanceNwId, currentUser);

  },

  destroy: function () {
    this.unregister();
    this.reactRoot.unmount();
  },

  setAllMessagesAreReceived: function () {
    const { notReceivedMessageNwids } = this.store.getState();

    if (notReceivedMessageNwids.length === 0) return;

    const viewInstanceNwId = this.viewSettings.nwid;
    const currentUserNwid = settingsManager.get('user').nwid;
    this.store.dispatch({ type: 'SET_NOT_RECEIVED_MESSAGES', notReceivedMessageNwids: [] });

    notReceivedMessageNwids.forEach(nwid => {
      let restData = {
        userId: currentUserNwid,
      };

      return restPut(viewInstanceNwId, `chat/messages/${nwid}/receive`, restData);
    });

  },

  render: function () {
    // const t1 = performance.now();
    this.reactRoot.render(<Chat store={this.store} viewSettings={this.viewSettings} />);
    // const t2 = performance.now();
    // console.log('### Chat: ChatView.render() time = ', t2 - t1, 'ms');
  }
});

