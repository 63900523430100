/**
 * Created by tzvikas on 29/10/2017.
 */
const OpenModuleAction = require('./OpenModuleAction');
const actionUtils = require('actions/actionUtils');

module.exports = OpenModuleAction.extend({
  isApplicable: function (objs) {
    if (!objs || objs.length === 0){
      return false;
    }
    return !objs[0].userLocked;
  }
});

