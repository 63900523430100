import sandbox from 'sandbox';

const groupsAlphaNumericComarator = sandbox.sorting.getComparator('alpha', 'name');

const addItem = (state, action) => {
  return {
    ...state,
    groups: state.groups.concat(action.group).sort(groupsAlphaNumericComarator)
  };
};

const removeItem = (state, action) => {
  return {
    ...state,
    groups: state.groups.filter(group => group.nwid !== action.nwid)
  };
};

const updateItem = (state, action) => {
  const index = state.groups.findIndex(group => group.nwid === action.group.nwid);

  return {
    ...state,
    groups: state.groups.slice(0, index).concat(action.group).concat(state.groups.slice(index + 1)).sort(groupsAlphaNumericComarator)
  };
};

export default function (state = {}, action) {
  switch (action.type) {
    case 'addItem':
      return addItem(state, action);

    case 'removeItem':
      return removeItem(state, action);

    case 'updateItem':
      return updateItem(state, action);

    default:
      return {
        ...state
      };
  }
}