import Mustache from 'mustache';
import jsUtils from 'sandbox/jsUtils';

const MAP_VALUES_DEFAULT_KEY = '!default';

function StyleConverter(mapTags) {
  this._mapTags = mapTags;
  this._mapValues = mapValues;
}

StyleConverter.prototype = {
  toStyle: toStyle
};

function getMapValuesWithDefault(mapValue, templateTagValue) {
  const defaultValue = mapValue[MAP_VALUES_DEFAULT_KEY] || {};
  const templateMapValue = mapValue[templateTagValue] || {};

  return { ...defaultValue, ...templateMapValue };
}

function mapValues(mapValue, templateTagValue) {
  var style = getMapValuesWithDefault(mapValue, templateTagValue);

  if (typeof style === 'undefined') {
    return [];
  }

  return Object.keys(style).map(function (styleTag) {
    return {
      tag: styleTag,
      value: style[styleTag]
    };
  });
}

function renderWithHelper(templateTagValue, model, tag, helperFunctions, context) {
  var helperFunctionName = [tag, templateTagValue.type].join('-');

  if (typeof templateTagValue.type !== 'string') return undefined;

  if (typeof helperFunctions[helperFunctionName] !== 'function') return undefined;

  return helperFunctions[helperFunctionName].apply(context, [templateTagValue, model]);
}

function renderStyleTag(templateTagValue, model, tag, helperFunctions, context) {
  if (typeof templateTagValue === 'object' && helperFunctions !== undefined) {
    return renderWithHelper(templateTagValue, model, tag, helperFunctions, context);
  }

  return Mustache.render(templateTagValue, model);
}

function toStyle(template, model, helperFunctions, context) {
  var style = {},
    _mapTags = this._mapTags,
    _mapValues = this._mapValues;

  helperFunctions = helperFunctions || {};

  if (typeof template === 'undefined') return undefined;


  _mapTags.forEach(function (mapTag) {
    var tag = mapTag.tag,
      styleTags = mapTag.styleTags,
      templateTagValue = template[tag];
    if (typeof templateTagValue === 'undefined') return;

    styleTags.forEach(function (styleTag) {
      if (typeof styleTag === 'string') {
        style[styleTag] = renderStyleTag(templateTagValue, model, tag, helperFunctions, context);
        return;
      } else if (typeof styleTag === 'object') {
        mapValues(styleTag, templateTagValue).forEach(function (styleElement) {
          style[styleElement.tag] = renderStyleTag(styleElement.value, model);
        });
      }
    });
  });

  return style;
}


export default StyleConverter;