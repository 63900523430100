import React from "react";
import { translate } from 'core/services/localization';
import List from 'components/common/list';

const FilesList = ({ selectedFile, items, handleSelectFile }) => {

  function renderFileRow(item, index) {
    const className = `file-viewer-list-item ${(selectedFile && item.fileName === selectedFile.fileName) ? 'selected' : ''}`;
    return (
      <div className={className}
        onClick={() => handleSelectFile(item)}
        title={item.fileName}>
        <span className='file-viewer-list-item-title'>{item.fileName}</span>
      </div>
    );
  }

  function renderList() {
    return (
      <List id='items'
        items={items}
        renderItem={renderFileRow}
      />
    );
  }

  function renderMessage() {
    return (
      <div className="file-viewer-message">
        {translate('There are no matching files in the specified folder. Check the folder exists and file mask is correct.')}
      </div>
    );
  }

  return (
    <div className="file-viewer-list-container">
      {items.length ? renderList() : renderMessage()}
    </div>);
};

export default FilesList;