import React from 'react';
import icons from 'core/services/iconService';
import { translate, toLocaleShortDateTime } from 'core/services/localization';
import { COMPARE_TYPE } from 'core/comparators';
import { cells } from 'widgets/ReactDataGrid/';
import { arrangeItemsByKeys } from 'utilities/array';
import { createObjectComparator } from 'core/comparators';

import { applyColumnPreferences, FILTER } from 'widgets/ReactDataGrid/utils';

const { Text } = cells;

const AUTHENTICATION_METHODS = {
  'BuiltIn': translate('Internal'),
  'OPENID': translate('OpenID'),
  'NTLM': translate('NTLM (Domain)')
};

export const makeUsersTableColumns = (viewModel, module) => {
  const columnPrefs = module.preferences.table?.userColumns || [];

  let columns = [

    {
      key: 'name',
      width: 150,
      align: 'left',
      caption: translate('Name'),
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cellDataGetter: (rowIndex, key) => {
        const item = viewModel.users[rowIndex];
        const icon = item.domainProfile === true ? icons.getGeneralIcon('profile', 'domain_user') : icons.getTemplateIcon(item.type);
        const title = item.domainProfile === true ? translate('Domain Group') : translate('Group');

        return {
          columnData: (<span>
            <img src={icon} title={title} style={{ marginRight: '5px' }} />
            {item.name}
          </span>),
          title: item.name
        };
      },
    },
    {
      key: 'fullName',
      width: 150,
      align: 'left',
      caption: translate('Full Name'),
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,

      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: viewModel.users[rowIndex] && viewModel.users[rowIndex].fullName || '',
        };
      },
    },
    {
      key: 'authenticationMethod',
      width: 250,
      align: 'left',
      caption: translate('Authentication Method'),
      cell: Text,
      filter: {
        options: createAuthMethodOptions(viewModel.users),
        ...FILTER.MULTISELECT_TEXT,
      },
      filterValueGetter: getUserAuthMethod,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,

      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: AUTHENTICATION_METHODS[viewModel.users[rowIndex].authenticationMethod]
        };
      },
    },
    {
      key: 'securityGroups',
      width: 300,
      align: 'left',
      caption: translate('Security Groups'),
      cell: Text,
      filter: {
        options: createGroupsOptions(viewModel.users),
        ...FILTER.MULTISELECT_TEXT,
      },
      filterValueGetter: getUserSecurityGroups,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      sortValueGetter: getUserSecurityGroups,
      cellDataGetter: (rowIndex, key) => {
        const columnData = getUserSecurityGroups(viewModel.users[rowIndex]);
        return {
          columnData
        };
      },
    },

    {
      key: 'apiToken',
      width: 150,
      align: 'left',
      caption: translate('API Token'),
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: viewModel.users[rowIndex] && viewModel.users[rowIndex].apiToken || '',
        };
      },
    },
    {
      key: 'lastLoginTime',
      width: 150,
      align: 'left',
      caption: translate('Last Login Time'),
      cell: Text,
      filter: FILTER.DATE,
      sortType: COMPARE_TYPE.DATES,
      cellDataGetter: (rowIndex, key) => {
        const loginTime = viewModel.users[rowIndex] && viewModel.users[rowIndex].lastLoginTime;

        return {
          columnData: toLocaleShortDateTime(loginTime, true)
        };
      },
    }

  ];

  const keys = columnPrefs.map(col => col.key);
  columns = arrangeItemsByKeys(columns, keys);

  columns = applyColumnPreferences(columns, columnPrefs);

  return columns;
};


const getUserSecurityGroups = user => user && user.securityGroups || '';

const createGroupsOptions = (users) => {

  const mergedGroupNames = users.reduce((acc, user) => {
    const groupList = user.securityGroups.split(',').map(element => element.trim());

    groupList.forEach(group => {
      if (!acc[group]) {
        acc[group] = true;
      }

    });

    return acc;
  }, []);

  const filterOptions = Object.keys(mergedGroupNames).map(groupName => ({
    value: groupName,
    data: { columnData: groupName }
  }));

  return filterOptions.sort(createObjectComparator('value'));
};


const getUserAuthMethod = user => user && AUTHENTICATION_METHODS[user.authenticationMethod] || '';
const createAuthMethodOptions = (users) => {

  const mergedAuthMethods = users.reduce((acc, user) => {

    if (!acc[user.authenticationMethod]) {
      acc[user.authenticationMethod] = true;
    }

    return acc;
  }, {});

  const filterOptions = Object.keys(mergedAuthMethods).map(method => ({
    value: AUTHENTICATION_METHODS[method],
    data: { columnData: AUTHENTICATION_METHODS[method] }
  }));

  return filterOptions.sort(createObjectComparator('value'));
};


