import sandbox from 'sandbox';

var jsUtils = sandbox.jsUtils;

function WindowResizeHandler(onResize, wait) {
  this.init.apply(this, arguments);
}

WindowResizeHandler.prototype = {
  init: function (onResize, wait, win = window) {
    this.win = win;
    this._onResize = onResize;
    this._wait = wait;
    //this._eventListenerWindowResize = null
    this._onResizeHandler = this._onResizeHandler.bind(this);
  },

  captureResize: function () {
    this.win.addEventListener(
      'resize',
      this._onResizeHandler
    );
  },

  releaseResize: function () {
    this.win.removeEventListener(
      'resize',
      this._onResizeHandler
    );
  },

  _onResizeHandler: function (event) {
    if (typeof this._onResize === 'function')
      jsUtils.debounce(this._onResize, this._wait).call(this, event);
  }
};

export default WindowResizeHandler;