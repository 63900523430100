/**
 * @name Tick Manager
 * @fileOverview
 * @namespace
 * @author sergey
 */

import base from 'base';
import jsutils from 'base/jsUtils';
import ProjectorClass from 'core/workers/Projector';
import logger from 'logger';
import settingsManager from 'core/managers/settings';

const log = logger.getDefaultLogger();
const projectorsByModuleId = {}; // dictionary: module id - {projectorId, Projector instance}

/**
 * Request to open projector at the server side for a module in order to get updates.
 * Function issues the request to the server. If successful,
 * adds record to the dictionary {module.id, instanceOfTickProjector},
 * and fires the register callback.
 * Note: The server uses parameter viewId instead of moduleId.
 *
 * @param  {Object} module               Reference to the module that issued the request
 * @param  {Function} firstTickCallback  Function to be invoked when the first tick is received
 * @param  {Function} tickUpdateCallback Callback function to be fired whenever updates come
 * @param  {Function} tickCommitCallback Function to be invoked when the tick has committed all messages
 * @param  {Object} extraParams          Additional parameters of request
 */
function addProjector(module, firstTickCallback, tickUpdateCallback, tickCommitCallback, extraParams) {
  if (arguments.length < 3) {
    log.error('Tick Worker: module, callback function should be defined to register the projector');
    return;
  }
  if (!jsutils.isObject(module)) {
    log.error('Tick Worker: module should be an object type');
    return;
  }
  if (typeof firstTickCallback !== 'undefined' && !jsutils.isFunction(firstTickCallback)) {
    log.error('Tick Worker: first tick callback must be a function');
    return;
  }
  if (!jsutils.isFunction(tickUpdateCallback)) {
    log.error('Tick Worker: callback must be a function');
    return;
  }
  if (extraParams && !jsutils.isObject(extraParams)) {
    log.error('Tick Worker: extraParams should be an object');
    return;
  }

  // create a record in a data structure with callback in case tick message comes first than response from projectorAdd command
  projectorsByModuleId[module.id] = new ProjectorClass(null, firstTickCallback, tickUpdateCallback, tickCommitCallback, module);

  const requestParams = {
    viewId: module.id,
    params: JSON.stringify({ projectorPIs: { sendPreferences: true } }),
    clientId: settingsManager.get('id'),
    requestVersion: settingsManager.get('requestVersion'),
    instanceNwId: module.nwid,
    command: 'ProjectorAdd',
  };

  Object.assign(requestParams, extraParams);

  return base.data.ajax({
    url: settingsManager.get('webAppPath') + '/servlet/GenericCommandServlet',
    type: 'POST',
    dataType: 'json',
    data: requestParams
  }).then(res => {
    if (res.statusCode === 200) {
      // assign the new projectorId to the module instance
      module.projectorId = res.data.projectorId;

      // assign the new projectorId to the instance of the Projector class
      projectorsByModuleId[module.id].projectorId = res.data.projectorId;

      // flush tick messages enqueued before this response have been received
      projectorsByModuleId[module.id].flushMessageQueue();

      return res.data.projectorId;
    } else {
      log.error('tickWorker.addProjector() => module.id & statusCode:', module.id, res.statusCode);
      delete projectorsByModuleId[module.id];
    }
  });
}


/**
 * Request is issued as part of module closing process.
 * Function sends request to the server and deletes
 * record from a dictionary.
 *
 * @param {number} moduleId Module id
 */
function removeProjector(moduleId) {
  if (moduleId === undefined) {
    throw new Error('Tick Worker: moduleId should be defined');
  }
  if (typeof moduleId !== 'number') {
    throw new Error('Tick Worker: moduleId should be a number');
  }

  const projectorId = projectorsByModuleId[moduleId].projectorId;
  delete projectorsByModuleId[moduleId];

  const requestParams = {
    clientId: settingsManager.get('id'),
    requestVersion: settingsManager.get('requestVersion'),
    projectorId,
    command: 'ProjectorRemove'
  };

  return base.data.ajax({
    url: settingsManager.get('webAppPath') + '/servlet/GenericCommandServlet',
    type: 'POST',
    dataType: 'json',
    data: requestParams
  })
    .fail(res => log.error('tickWorker.removeProjector() => moduleId & res:', moduleId, res));
}

function replaceProjector(moduleId, rootId, rootType) {
  const module = require('core/managers/module').getModuleById(moduleId);
  if (!module) {
    return;
  }

  // create Projector in case the tick message comes bofore the response from replaceProjector command
  projectorsByModuleId[module.id] = new ProjectorClass(null, module.firstTickReceived, module.tickUpdate, module.tickCommit, module);

  const requestParams = {
    rootId: rootId || module.viewSettings.rootId,
    rootType: rootType || module.viewSettings.rootType,
    viewDefinitionName: module.viewSettings.viewDefinitionName,
    viewId: module.id,
    params: JSON.stringify({ projectorPIs: { sendPreferences: true } }),
    clientId: settingsManager.get('id'),
    requestVersion: settingsManager.get('requestVersion'),
    instanceNwId: module.nwid,
    command: 'ProjectorReplace',
  };

  return base.data.ajax({
    url: settingsManager.get('webAppPath') + '/servlet/GenericCommandServlet',
    type: 'POST',
    dataType: 'json',
    data: requestParams
  }).then(res => {
    if (res.statusCode === 200) {
      // assign the new projectorId to the module instance
      module.projectorId = res.data.projectorId;

      // assign the new projectorId to the instance of the Projector class
      projectorsByModuleId[module.id].projectorId = res.data.projectorId;

      // flush tick messages enqueued before this response have been received
      projectorsByModuleId[module.id].flushMessageQueue();

      return res.data.projectorId;
    } else {
      log.error('tickWorker.replaceProjector() => module.id & statusCode:', module.id, res.statusCode);
      delete projectorsByModuleId[module.id];
    }
  });
}

/**
 * This function is called by channel manager
 * whenever it receives a new push notification
 * @param {Object} data Push message
 */
function callTickUpdate(data) {
  const projector = projectorsByModuleId[data.viewId];
  if (projector) {
    projector.invokeUpdate(data);
  } else {
    log.warn('Tick Worker: could not find projector for ' + data.viewId);
  }
}

/**
 * This function is called by channel worker
 * whenever the tick has finished all the messages to the view.
 * This function will invoke tick commit in the view (module).
 * @param viewId {Number} the id of the effected view (module)
 */
function callTickCommit(viewId) {
  var projector = projectorsByModuleId[viewId];
  if (projector) {
    projectorsByModuleId[viewId].invokeTickCommit();
  } else {
    log.warn('Tick Worker: could not find projector for ' + viewId);
  }
}

function openView(params) {
  let { nwid = '', folderNwid = '', viewDefinitionName = '' } = params;
  params.target = ''; // always open the given view inside the #main div in 1C layout

  base.dom.hide('#info');

  const viewMgr = require('core/managers/views');

  let viewInfo;
  if (nwid) {
    viewInfo = viewMgr.getViewInfo(nwid);
  } else if (folderNwid && viewDefinitionName) {
    viewInfo = viewMgr.findViewInfoByDefinitionName(folderNwid, viewDefinitionName);
    nwid = viewInfo.nwid
  }

  if (!viewInfo) {
    throw new Error(`tickWorker.openView(): Cannot find view by nwid '${nwid}' or folder nwid '${folderNwid}' and view definition name '${viewDefinitionName}'`);
  }

  return require('core/managers/module').startModule(nwid, null, {
    ...viewInfo,
    ...params,
  });
}

function getCommandFn(command) {
  const fnByCommand = {
    openView,
  };

  if (!fnByCommand[command]) {
    throw new Error(`tickWorker.executeCommand(). Unsupported command '${command}'`);
  }

  return fnByCommand[command];
}

function executeCommand(cmdParams = {}) {
  // const sampleCommandParams = {
  //   "command": "openView",
  //   // "nwid": "355502895587907604",
  //   "folderNwid": "288230376152067659",
  //   "viewDefinitionName": "PageViewCR",
  //   "rootId": "372954344151689613",
  //   "rootType": "page",
  // };
  // cmdParams = sampleCommandParams;

  const { command = '', ...params } = cmdParams;

  try {
    getCommandFn(command)(params);
  } catch (err) {
    console.error(err);
  }
}

module.exports = {
  addProjector,
  removeProjector,
  replaceProjector,
  callTickUpdate,
  callTickCommit,
  executeCommand,
  openView
};
