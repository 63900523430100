import { getMainStatus } from 'utilities/statuses';
import OpenDialogAction from './OpenDialogAction';
const PRE_AD_TYPE = 'pre';

const isRejectedPage = page => {
  const status = getMainStatus(page) || {};
  return status.flowStepType === 'workflow/step/flow/approval' && status.statusType === 'Error';
};

export default OpenDialogAction.extend({
    isApplicable: function (selectedItems) {
        if (selectedItems.some(item => item.blockMail === 'true')) {
            return false;
        }
        return selectedItems.every(selectedItem => selectedItem.deprecated !== "true" && selectedItem.adType !== PRE_AD_TYPE && !isRejectedPage(selectedItem));
    }
});