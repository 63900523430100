import React from 'react';
import jsUtils from 'sandbox/jsUtils';

const DEFAULT_ALIGN = 'left';
const DEFAULT_MARGIN = 0;
const DEFAULT_GRID_SIZE = [100, 100];

export default class extends React.Component {
  static defaultProps = {
    gridMargin: DEFAULT_MARGIN,
    gridAlign: undefined,
    gridSize: [100, 100]
  };

  getGridMargin = () => {
    return jsUtils.get(this, 'props.gridMargin', DEFAULT_MARGIN);
  };

  getGridAlign = () => {
    return jsUtils.get(this, 'props.gridAlign', DEFAULT_ALIGN);
  };

  getGridSize = () => {
    return jsUtils.get(this, 'props.gridSize', DEFAULT_GRID_SIZE);
  };

  render() {
    var { ...props } = this.props,
      gridSize = this.getGridSize(),
      gridMargin = this.getGridMargin(),
      gridAlign = this.getGridAlign();

    return <div className="GridPanel">
      {React.Children.map(this.props.children, function (child) {
        return React.cloneElement(child, {
          gridSize: gridSize,
          gridMargin: gridMargin,
          gridAlign: gridAlign
        });
      })}
      <div style={{ height: 0, clear: 'both' }}></div>
    </div>;
  }
}