import React from 'react';
import PropTypes from 'prop-types';

export default class PrintableHTML extends React.Component {

    static contextTypes = {
        register: PropTypes.func
    }

    componentDidMount() {
        this.context.register(this.printIframe);
        const iframe = this.refs.iframe;
        this.insertHTMLToIframe(iframe);
    }

    componentDidUpdate() {
        const iframe = this.refs.iframe;
        this.insertHTMLToIframe(iframe);
    }

    printIframe = () => {
        const iframe = this.refs.iframe;
        const iframeWindow = iframe.contentWindow || iframe;

        iframe.focus();
        iframeWindow.print();

        return false;
    }

    insertHTMLToIframe = (iframe) => {
        const { htmlToPrint } = this.props;
        if (iframe) {
            const iframeWindow = iframe.contentWindow || iframe;
            iframeWindow.document.body.innerHTML = htmlToPrint;
        }
    }
    render() {
        return (
            <div className='ctrx-printable-component'>
                <iframe ref="iframe" className='ctrx-printable-component-iframe' />
            </div>
        )
    }

}
