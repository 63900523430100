import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class extends Component {
  static propTypes = {
    headerHeight: PropTypes.any,
    headerFontSize: PropTypes.any,
    columnIndex: PropTypes.number,
    columnKey: PropTypes.any,
    width: PropTypes.number,
    title: PropTypes.string,
    sortIcon: PropTypes.string,
    iconTitle: PropTypes.string,
    sort: PropTypes.string,
    sortDefaultProps: PropTypes.any
  };

  static defaultProps = {
    iconTitle: '',
    sort: '',
    sortDefaultProps: {
      asc: {
        className: 'material-icons',
        content: 'arrow_downward',
        title: 'Accending Order'
      },
      desc: {
        className: 'material-icons',
        content: 'arrow_upward',
        title: 'Decending Order'
      },
      none: {
        className: 'material-icons',
        content: 'import_export',
        title: 'Sort'
      }
    }
  };

  render() {
    const { headerFontSize, tooltip, sortIcon, iconTitle, sort, sortDefaultProps, children } = this.props;
    const sortIconClassName = sortIcon !== undefined ? sortIcon :
      sortDefaultProps[sort] !== undefined && sortDefaultProps[sort].className !== undefined ? sortDefaultProps[sort].className :
        '';
    const sortIconTitle = iconTitle !== undefined ? iconTitle :
      sortDefaultProps[sort] !== undefined && sortDefaultProps[sort].title !== undefined ? sortDefaultProps[sort].title :
        undefined;
    const sortIconContent = sortIcon !== undefined ? sortIcon :
      sortDefaultProps[sort] !== undefined && sortDefaultProps[sort].content !== undefined ? sortDefaultProps[sort].content :
        '';
    const className = sortIconClassName === '' ? 'column' : 'column with-sort-icon';

    return <div className={className}>
      <div className="column-content" title={tooltip}>
        {children}
      </div>

      <div className="react-data-grid-column-sort-icon">
        <i className={sortIconClassName} title={sortIconTitle}>{sortIconContent}</i>
      </div>
    </div>;
  }
}