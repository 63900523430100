/**
 * @name centerMarks
 * @file Defines functionality of automatic center marks that are added together to the whole layout
 *
 * @author Boris
 * @since: 2018-07-15
 */

import React from 'react';
import sandbox from 'sandbox';
import SimpleForm from 'widgets/SimpleForm/src/index';
import utils from '../utils/utils';
import propertiesCommon from './propertiesCommon';
import regmarkModule from './regmark';
import canvasUtils from "../utils/canvasUtils";
import actions from "../redux/actions";

const {translate} = sandbox.localization;
const {utils: futils, Row, Label} = SimpleForm;

const BASE_PATH = 'layout.centerMarks';
const TITLE = translate('Center Marks');
const MARK_ROLE = 'centerMark';
const FULLWIDTH_PLUS_SIGN = '\uff0b';
const FULLWIDTH_HYPHEN_MINUS = '\uff0d';
const FULLWIDTH_VERTICAL_LINE = '\uff5c';
const MARK_CHAR_BY_TYPE = {
  hline: FULLWIDTH_HYPHEN_MINUS,
  vline: FULLWIDTH_VERTICAL_LINE,
  cross: FULLWIDTH_PLUS_SIGN
};

const {CAPTION_COLUMNS} = propertiesCommon;
const APPLY_ON = {...propertiesCommon.APPLY_ON};
const BLACK_ONLY = {...propertiesCommon.BLACK_ONLY};

const _NAME = {
  key: '_name',
  caption: translate('Name'),
  type: 'string',
  disabled: true,
};

const ENABLED = {
  key: 'enabled',
  caption: translate('Enabled'),
  type: 'bool',
};

const LINE_LENGTH = {
  key: 'lineLength',
  caption: translate('Line length'),
  type: 'length',
  mask: 'posFloat',
  defaultValue: 0.2,
};

const LINE_WIDTH = {
  key: 'lineWidth',
  caption: translate('Line width'),
  type: 'length',
  mask: 'posFloat',
  defaultValue: 0.02,
};

const OFFSET = {
  key: 'offset',
  caption: translate('Offset'),
  type: 'length',
  mask: 'posFloat',
  defaultValue: 0.125,
};


const CENTER_MARKS_META = [
  _NAME,
  ENABLED,
  LINE_LENGTH,
  LINE_WIDTH,
  OFFSET,
  APPLY_ON,
  BLACK_ONLY,
];

const getCellGrid = (state) => {
  return state.layout.cellGrid;
};

const getCells = (state) => {
  return state.layout.cellGrid.cells;
};

/**
 * Calculate mark surrounding rectangle relative to mark top-left corner.
 * Note: mark (x, y) is located in the center of the mark
 *
 * @param mark - object containing mark type and position
 * @param lineLength - mark line length
 * @param lineWidth - mark line width
 * @returns mark rectangle
 */
const calcMarkRectangle = (mark, lineLength, lineWidth) => {
  let result;
  if (mark.type === 'vline') {
    result = {x: mark.x - lineWidth / 2, y: mark.y - lineLength / 2, width: lineWidth, height: lineLength};
  } else if (mark.type === 'hline') {
    result = {x: mark.x - lineLength / 2, y: mark.y - lineWidth / 2, width: lineLength, height: lineWidth};
  } else {
    result = {x: mark.x - lineLength / 2, y: mark.y - lineLength / 2, width: lineLength, height: lineLength};
  }

  return result;
};

/**
 * Get mark type according to the mark location
 * @param row - row index
 * @param column - column index
 * @param numRows - number of rows in the marks grid
 * @param numColumns - number of columns in the marks grid
 * @returns mark type
 */

const getMarkType = (row, column, numRows, numColumns) => {
  let type;
  if (row > 0 && row < numRows - 1 && column > 0 && column < numColumns - 1) {
    // internal 'cross' marks
    type = 'cross';
  } else if ((row === 0 || row === numRows - 1) && column !== 0 && column !== numColumns - 1) {
    // external 'vline' marks - over the first row and under the last row
    type = 'vline';
  } else if ((column === 0 || column === numColumns - 1) && row !== 0 && row !== numRows - 1) {
    // external 'hline' marks - from the left of the first column and from the right of the last column
    type = 'hline';
  }

  return type;
};


export default (editor) => {

  const Regmark = regmarkModule(editor);
  const cutils = canvasUtils(editor);

  const getMeta = (state, element) => {
    CENTER_MARKS_META.forEach(item => item.disabled = !element.enabled);
    _NAME.disabled = true;
    ENABLED.disabled = !canUseCenterMarks(state, element);

    return CENTER_MARKS_META;
  };

  const buildDefaultElement = (state, elementType) => {
    const element = {
      elementType: elementType,
      locked: false,
    };

    setDefaultElementValues(state, element);

    return element;
  };

  const setDefaultElementValues = (state, element, elementPath) => {
    propertiesCommon.setDefaultValues(state, element, getMeta(state, element));

    element.marks = utils.fromPseudoArray(element.marks) || [];

    element._name = translate('Center Marks');
  };

  const updateMarksVisibility = (state, element, marksInfo) => {
    if (!element.enabled) {
      return element;
    }

    const grid = getCellGrid(state);
    const rows = grid.rows + 1;
    const columns = grid.columns + 1;
    const marks = Array.from({length: rows * columns}, () => '');
    const sizeMatch = element.marks.length === rows * columns;
    marksInfo.forEach(m => {
      const idx = m.row * columns + m.column;
      marks[idx] = sizeMatch && element.marks[idx] === 'off' ? 'off' : 'on';
    });

    return {...element, marks};
  };

  const updateCenterMarks = (state, element, elementPath) => {
    let newState = state;
    let newElement = element;
    newState = Regmark.removeAutoMarks(newState, MARK_ROLE);
    if (newElement.enabled) {
      const grid = getCellGrid(newState);
      let marks = calcMarksInfo(newState, newElement);
      newElement = updateMarksVisibility(newState, newElement, marks);
      newState = futils.update(newState, elementPath, newElement);
      marks = marks.filter(m => newElement.marks[m.row * (grid.columns + 1) + m.column] === 'on');
      newState = Regmark.addAutoMarks(newState, marks);
    } else if (newElement.marks.length > 0) {
      newElement = {...newElement, marks: []};
      newState = futils.update(newState, elementPath, newElement);
    }

    return newState;
  };

  const canUseCenterMarks = (state, element) => {
    const grid = getCellGrid(state);
    return grid.useGaps && (grid.rows > 1 || grid.columns > 1);
  };

  const calcMarksInfo = (state, element) => {
    const grid = getCellGrid(state);
    const cells = utils.fromPseudoArray(getCells(state));
    const rowGaps = utils.fromPseudoArray(grid.rowGaps);
    const columnGaps = utils.fromPseudoArray(grid.columnGaps);
    const {offset, lineWidth, lineLength} = element;
    const marks = [];
    const ofs = offset + lineLength / 2; // adjusted offset - distance from the mark center to the cell grid edge

    let dy = 0; // distance along y-axis from 0 to the row gap y coordinate
    let x, y; // (x, y) - coordinates of mark center
    for (let i = 0; i < rowGaps.length; i++) {
      if (i === 0) {
        y = rowGaps[0] - ofs;
      } else if (i < rowGaps.length - 1) {
        y = dy + rowGaps[i] / 2;
      } else {
        y = dy + ofs;
      }

      dy += rowGaps[i] + grid.cellHeight;

      let dx = 0; // distance along x-axis from 0 to the column gap x coordinate
      for (let j = 0; j < columnGaps.length; j++) {
        if (j === 0) {
          x = columnGaps[0] - ofs;
        } else if (j < columnGaps.length - 1) {
          x = dx + columnGaps[j] / 2;
        } else {
          x = dx + ofs;
        }

        dx += columnGaps[j] + grid.cellWidth;

        let type = getMarkType(i, j, rowGaps.length, columnGaps.length);
        if (type === 'cross') {
          // check that there is enough room for internal cross mark
          for (let k = 0; k < cells.length; k++) {
            const cell = cells[k];
            if (!cell.isSkipped) {
              const rect = {x: x - lineLength / 2, y: y - lineLength / 2, width: lineLength, height: lineLength};
              if (utils.checkRectanglesOverlap(rect, cell, offset)) {
                type = '';
                break;
              }
            }
          }
        }

        if (type) {
          marks.push({row: i, column: j, x, y, type, lineWidth, lineLength, role: MARK_ROLE});
        }
      }
    }

    return marks;
  };

  const updateProperty = (state, element, elementPath, propertyPath, propertyValue) => {
    let newState = state;
    let newElement = element;

    newState = propertiesCommon.updateProperty(newState, newElement, elementPath, propertyPath, propertyValue);
    newElement = futils.get(newState, elementPath);
    if (!utils.areValuesEqual(element, newElement, ['enabled', 'lineWidth', 'lineLength', 'offset']) ||
      propertyPath.indexOf('.marks.' >= 0)) {
      newState = updateCenterMarks(newState, newElement, elementPath);
      newState.repopulate = true;
    }

    return newState;
  };

  const dependencyChanged = (state, element, elementPath, oldSource, newSource) => {
    if (!element.enabled) {
      return state;
    }

    let newState = state;
    let newElement = element;
    if (newSource.elementType === 'cellGrid' && !utils.areValuesEqual(oldSource, newSource, ['useGaps', 'rows', 'columns'])) {
      newElement = {...newElement, marks: [], enabled: newElement.enabled && canUseCenterMarks(newState, newElement)};
      newState = futils.update(newState, elementPath, newElement);
    }

    const stateBeforeUpdate = newState;
    newState = updateCenterMarks(newState, newElement, elementPath);
    if (newState !== stateBeforeUpdate) {
      newState.repopulate = true;
    }

    return newState;
  };

  const activateShape = (state, element) => {
    cutils.setActiveObject(null);
  };

  const handleMarkClick = (store, element, elementPath, markIndex, markValue) => {
    const value = markValue === 'on' ? 'off' : markValue === 'off' ? 'on' : '';
    if (value) {
      const path = futils.compose(elementPath, 'marks', markIndex);
      store.dispatch(actions.update(path, value));
    }
  };

  const renderMarkCell = (store, element, elementPath, row, column) => {
    const state = store.getState();
    const grid = getCellGrid(state);
    const markIndex = row * (grid.columns + 1) + column;
    const markValue = element.marks[markIndex];
    const markType = markValue ? getMarkType(row, column, grid.rows + 1, grid.columns + 1) : '';
    const markChar = markValue === 'on' && markType ? MARK_CHAR_BY_TYPE[markType] : ' ';

    return (
      <td className={markValue} key={column}
          onClick={() => handleMarkClick(store, element, elementPath, markIndex, markValue)}
      >
        {markChar}
      </td>
    );
  };

  const renderMarksRow = (store, element, elementPath, row) => {
    const state = store.getState();
    const grid = getCellGrid(state);
    const tableRow = Array.from({length: grid.columns + 1});

    return (
      <tr key={row}>
        {tableRow.map((item, column) => renderMarkCell(store, element, elementPath, row, column))}
      </tr>
    );
  };

  const renderMarksTable = (store, element, elementPath) => {
    if (!element.enabled) {
      return null;
    }

    const state = store.getState();
    const grid = getCellGrid(state);
    const tableRows = Array.from({length: grid.rows + 1});

    return (
      <div>
        <Row>
          <Label col={CAPTION_COLUMNS}>{'Delete/Restore marks'}</Label>
          <table className='marks-table'>
            <tbody>
            {tableRows.map((item, row) => renderMarksRow(store, element, elementPath, row))}
            </tbody>
          </table>
        </Row>
      </div>
    );
  };

  const renderProperties = (store, element, elementPath) => {
    const state = store.getState();

    return (
      <div>
        {propertiesCommon.renderProperties(store, element, elementPath, getMeta(state, element))}
        {renderMarksTable(store, element, elementPath)}
      </div>
    );

  };

  const getInfo = (state) => {
    return {
      basePath: BASE_PATH,
      title: TITLE
    };
  };

  return {
    buildDefaultElement,
    setDefaultElementValues,
    updateProperty,
    dependencyChanged,
    activateShape,
    renderProperties,
    getInfo,
  }
};
