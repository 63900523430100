import React, { Component } from 'react';
import sandbox from 'sandbox';
import SeperationPreview from 'src/components/private/separations/SeperationPreview';
import StringBreadcrumbs from 'src/components/private/info/StringBreadcrumbs';
import PropTypes from 'prop-types';
import {formatBreadcrumbs} from 'utilities/breadcrumbs';

const infolink = sandbox.icons.getModuleIcon('InfoDialog', 'infolink');
const translate = sandbox.localization.translate;

const labels = {
  nwid: translate('Nwid'),
  path: translate('Path'),
  masters: translate('Masters'),
  children: translate('Children'),
  contents: translate('Contents'),
  siblings: translate('Siblings'),
  shared: translate('Shared'),
  copies: translate('Shared by copy'),
}

module.exports = class LinksGroup extends Component {
  constructor(props) {
    super(props);
    this.getColorsPreview = this.getColorsPreview.bind(this);
    this.openCustomInfoDialog = this.openCustomInfoDialog.bind(this);
    this.createLinksRows = this.createLinksRows.bind(this);

  }

  static propTypes = {
    name: PropTypes.string,
    links: PropTypes.any,
    object: PropTypes.any,
    advanceInfo: PropTypes.bool,
    openCustomInfoDialog: PropTypes.func
  }

  getColorsPreview(color, index) {
    const seperationPreview = <SeperationPreview key={index} colorName={color.name} />;
    return seperationPreview;
  }

  openCustomInfoDialog(nwid) {
    return function () {
      this.props.openCustomInfoDialog(nwid);
    }.bind(this);
  }


  createLinksRows() {
    let { links, object, advanceInfo } = this.props;
    sandbox.sorting.sort(links, 'datedecending', 'deadline');
    const linksRows = links.map((link, index) => {
      link.separations.forEach(function (separation) {
        const colorNameObj = sandbox.colors.getColorByName(separation.name);
        const colorRGB = sandbox.colors.getUIColorRGB(colorNameObj);
        separation['type'] = colorNameObj.colorType;
        separation['UIColorRGB'] = colorRGB;
      });
      sandbox.sorting.sort(link.separations, 'cmyk', 'name');

      const colorsPreview = link.separations.map(this.getColorsPreview);
      const nwid = advanceInfo ? <div className='custom-info-row'><span className='custom-info-row-title'>{labels.nwid}: </span><span className='custom-info-row-content ellipsis' title={link.nwid}>{link.nwid || ''}</span></div> : "";
      const path = advanceInfo ? <div className='custom-info-row'><span className='custom-info-row-title'>{labels.path}: </span><span className='custom-info-row-content ellipsis-before' title={link.actualPath}>{link.actualPath || ''}</span></div> : "";

      return <div key={index}>
        <div className='custom-info-row' ><span className='custom-info-row-title'>{colorsPreview}</span><span className='custom-info-row-content' onClick={this.openCustomInfoDialog(link.nwid)} >
          <span className='custom-info-breadcrumbs-and-icon'>
            <span className='custom-info-breadcrumbs ellipsis-before' title={formatBreadcrumbs(link.breadcrumbs)}>
              <StringBreadcrumbs currentBreadcrumbs={link.breadcrumbs} compareTo={object.breadcrumbs} />
            </span>
            <img className='custom-info-infolink-icon' src={infolink} />
          </span>
        </span>
        </div>
        {nwid}
        {path}
      </div>;
    });
    return linksRows;
  }

  render() {
    const { name, links } = this.props;
    let panelName;
    if (name === 'masters') { panelName = labels.masters }
    else if (name === 'children') { panelName = labels.children }
    else if (name === 'contents') { panelName = labels.contents }
    else if (name === 'siblings') { panelName = labels.siblings }
    else if (name === 'remoteRelatives') { panelName = labels.shared }
    else if (name === 'copies') { panelName = labels.copies }
    else { panelName = name }

    return <div className='link-group'>
      <div className='panel-group-name'>{panelName}</div>
      {this.createLinksRows()}
    </div>;
  }

}