import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';
import Properties from 'src/components/private/info/Properties';
import SeperationPreview from 'src/components/private/separations/SeperationPreview';
import StringBreadcrumbs from 'src/components/private/info/StringBreadcrumbs';
import { formatBreadcrumbs } from 'utilities/breadcrumbs';

const translate = sandbox.localization.translate;
const labels = {
  master: translate('Master'),
  additionalInfo: translate('Additional Info'),
  expectedFileName: translate('Expected File Name'),
  local: translate('Local'),
  inputFileName: translate('Input File Name'),
  nwid: translate('Nwid'),
  path: translate('Path'),
  content: translate('Content'),
  expected: translate('Expected'),
  input: translate('Input')
};

module.exports = class Color extends Component {
  constructor(props) {
    super(props);
    this.isEmpty = this.isEmpty.bind(this);
    this.getMastersLink = this.getMastersLink.bind(this);
    this.getContentsLink = this.getContentsLink.bind(this);
    this.getAdditionalInfo = this.getAdditionalInfo.bind(this);
    this.getExpectedAndInputFileName = this.getExpectedAndInputFileName.bind(this);
  }


  static propTypes = {
    separation: PropTypes.object,
    links: PropTypes.any,
    object: PropTypes.any,
    elementType: PropTypes.string,
    advanceInfo: PropTypes.bool,
    openerType: PropTypes.string//dialog or rightPanel
  };
  isEmpty(obj) {
    for (var prop in obj) {
      return false;
    }
    return true;
  }

  getMastersLink() {
    const { separation, links, object } = this.props;
    const masters = links.masters || [];
    var masterSeperation = [];
    masters.every(function (master) {
      masterSeperation = master.separations.filter(masterSep => masterSep.name === separation.name);

      return masterSeperation.length <= 0;
    });

    if (masterSeperation.length > 0) {

      return (
        <div className='custom-info-row'>
          <span className='custom-info-row-title'>{labels.master}: </span>
          <span className='custom-info-row-content'>
            <span className='custom-info-breadcrumbs ellipsis-before' title={formatBreadcrumbs(masterSeperation[0].breadcrumbs)}>
              <StringBreadcrumbs currentBreadcrumbs={masterSeperation[0].breadcrumbs}
                compareTo={object.breadcrumbs} />
            </span>
          </span>
        </div>
      );
    }
  }

  getContentsLink() {
    const { separation, links, object } = this.props;
    const contents = links.contents || [];
    var contentSeperation = [];
    contents.every(function (content) {
      contentSeperation = content.separations.filter(function (contentSep) {
        return contentSep.name === separation.name;
      });
      if (contentSeperation.length !== 0) {
        return false;
      } else {
        return true;
      }
    });

    if (contentSeperation.length !== 0) {

      return <span className='custom-info-breadcrumbs ellipsis-before' title={formatBreadcrumbs(contentSeperation[0].breadcrumbs)}>
        <StringBreadcrumbs currentBreadcrumbs={contentSeperation[0].breadcrumbs}
          compareTo={object.breadcrumbs} />
      </span>;
    }

    return <span className='custom-info-breadcrumbs ellipsis-before' title={formatBreadcrumbs(separation.breadcrumbs)}>
      <StringBreadcrumbs currentBreadcrumbs={separation.breadcrumbs}
        compareTo={object.breadcrumbs} />
    </span>;
  }

  getAdditionalInfo() {
    const { separation } = this.props;
    const isEmptyObj = this.isEmpty(separation.custom);
    if (!isEmptyObj) {
      return <div className='custom-info-row'><span className='custom-info-row-title'>{labels.additionalInfo}: </span><span className='custom-info-row-content'><Properties properties={separation.custom} /></span></div>;
    }
    return;
  }

  getExpectedAndInputFileName() {
    const { separation, elementType, openerType } = this.props;
    const expectedFileName = openerType === 'dialog' ? labels.expectedFileName : labels.expected;
    const inputFileName = openerType === 'dialog' ? labels.inputFileName : labels.input;

    if (elementType === 'page') {
      return separation.masterExpectedFileName ?
        <div key={separation.name}>
          <div className='custom-info-row'><span className='custom-info-row-title' title={labels.inputFileName}>{inputFileName}: </span><span className='custom-info-row-content ellipsis'>{separation.inputFileName || ''}</span></div>
          <div className='custom-info-row'><span className='custom-info-row-title' title={labels.expectedFileName}>{expectedFileName}: </span><span className='custom-info-row-content ellipsis' title={separation.masterExpectedFileName}>{separation.masterExpectedFileName || ''}</span></div>
          <div className='custom-info-row disabled'><span className='custom-info-row-title' title={labels.local}>{labels.local}: </span><span className='custom-info-row-content ellipsis' title={separation.expectedName}>{separation.expectedName || ''}</span></div>
        </div> :
        <div key={separation.name}>
          <div className='custom-info-row'><span className='custom-info-row-title' title={labels.inputFileName}>{inputFileName}: </span><span className='custom-info-row-content ellipsis'>{separation.inputFileName || ''}</span></div>
          <div className='custom-info-row'><span className='custom-info-row-title' title={labels.expectedFileName}>{expectedFileName}: </span><span className='custom-info-row-content ellipsis' title={separation.expectedName}>{separation.expectedName || ''}</span></div>
        </div>;
    }
    return;
  }

  render() {
    const { separation, advanceInfo, elementType } = this.props;

    const nwid = advanceInfo ? <div className='custom-info-row'><span className='custom-info-row-title'>{labels.nwid}: </span><span className='custom-info-row-content ellipsis' title={separation.nwid}>{separation.nwid || ''}</span></div> : undefined;
    const path = advanceInfo ? <div className='custom-info-row'><span className='custom-info-row-title'>{labels.path}: </span><span className='custom-info-row-content ellipsis-before' title={separation.actualPath}>{separation.actualPath || ''}</span></div> : undefined;

    const expectedAndInputFileName = this.getExpectedAndInputFileName();
    const additionalInfo = this.getAdditionalInfo();
    const mastersLink = this.getMastersLink();
    const contentsLink = this.getContentsLink();


    if (expectedAndInputFileName !== undefined || additionalInfo !== undefined || mastersLink !== undefined || nwid !== undefined || path !== undefined || elementType === 'AD' || elementType === 'EDITORIAL') {
      return <div className='color'>
        <div className='custom-info-row'><SeperationPreview colorName={separation.name} shared={separation.shared} planned={separation.isPlanned} /><span className='color-name'>{separation.name || ''}</span></div>
        {expectedAndInputFileName}
        {additionalInfo}
        {mastersLink}
        <div className='custom-info-row'><span className='custom-info-row-title'>{labels.content}:</span><span className='custom-info-row-content ellipsis-before'>{contentsLink}</span></div>
        {nwid}
        {path}
      </div>;
    }

    return <div className='color' style={{ marginBottom: '0px' }}>

      <div className='custom-info-row'>
        <span className='custom-info-row-title' title={separation.name}>
          <SeperationPreview colorName={separation.name} shared={separation.shared} planned={separation.isPlanned} />
          <span className='color-name' >{separation.name || ''}</span>
        </span>
        <span className='custom-info-row-content'>{contentsLink}</span></div>
    </div>;
  }

};