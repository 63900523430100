/**
 * @name placeholderManager
 * @fileOverview Manages all types of placeholders
 *
 * @author Boris
 * @since: 2019-06-02
 */

import logger from 'logger';

const log = logger.getDefaultLogger();


const placeholdersMap = {
  "toolbar":
  {
    "workflow": [
      {
        "type": "action",
        "icon": "auto_layout",
        "label": "Auto Layout"
      },
      {
        "type": "action",
        "icon": "edit",
        "label": "Edit Workflow"
      },
      {
        "type": "action",
        "icon": "delete_red",
        "label": "Exit Edit Workflow"
      },
      {
        "type": "action",
        "icon": "save",
        "label": "Save Workflow"
      },
    ],
    "birdeye": [
      {
        "type": "action",
        "icon": "approve",
        "label": "Approve"
      },
      {
        "type": "action",
        "icon": "reject",
        "label": "Reject"
      },
      {
        "type": "action",
        "icon": "custom_approval",
        "label": "Custom Approval"
      },
      {
        "type": "action",
        "icon": "expand",
        "label": "Expand All"
      },
      {
        "type": "action",
        "icon": "collapse",
        "label": "Collapse All"
      },
      {
        "type": "action",
        "icon": "missing_pages",
        "label": "Missing Pages"
      },
      {
        "type": "action",
        "icon": "error_pages",
        "label": "Error Pages"
      },
      {
        "type": "action",
        "icon": "waiting_for_approval",
        "label": "Waiting For Approval"
      },
      {
        "type": "action",
        "icon": "actual_plates",
        "label": "Expected"
      },
      {
        "type": "action",
        "icon": "local_pages",
        "label": "Local Pages"
      },
    ]
  },
  "contextMenu":
  {
    "birdeye":
    {
      "page": [
        {
          "type": "view",
          "label": "Page View"
        },
        {
          "type": "view",
          "label": "Hires View"
        },
        {
          "type": "view",
          "label": "Preflight View"
        },
        {
          "type": "view",
          "label": "Compare View"
        },
        {
          "type": "view",
          "label": "PDF View"
        },
        {
          "type": "view",
          "label": "Flip Book View"
        },
        {
          "type": "view",
          "label": "History View"
        },
        {
          "type": "separator",
        },
        {
          "type": "action",
          "label": "Copy"
        },
        {
          "type": "action",
          "label": "Paste"
        },
        {
          "type": "separator",
        },
        {
          "type": "action",
          "label": "Approve"
        },
        {
          "type": "action",
          "label": "Reject"
        },
        {
          "type": "action",
          "label": "Custom Approval"
        },
        {
          "type": "action",
          "label": "Skip Page Approval"
        },
        {
          "type": "action",
          "label": "UnSkip Page Approval"
        },
        {
          "type": "action",
          "label": "Hold"
        },
        {
          "type": "action",
          "label": "Release"
        },
        {
          "type": "action",
          "label": "Delete All Versions"
        },
        {
          "type": "action",
          "label": "Restart From Hotfolder"
        },
        {
          "type": "action",
          "label": "Show Error Message"
        },
        {
          "type": "action",
          "label": "Skip Error"
        },
        {
          "type": "action",
          "label": "Custom Route"
        },
        {
          "type": "action",
          "label": "Change Color"
        },
        {
          "type": "action",
          "label": "Change Expected Name"
        },
        {
          "type": "action",
          "label": "Set Complete"
        },
        {
          "type": "action",
          "label": "Share"
        },
        {
          "type": "action",
          "label": "Clear All Versions"
        },
        {
          "type": "action",
          "label": "Edit PDF"
        },
        {
          "type": "action",
          "label": "Save PDF"
        },
        {
          "type": "action",
          "label": "Panel Info"
        },
        {
          "type": "action",
          "label": "Change Color"
        },
        {
          "type": "action",
          "label": "Object Info"
        }
      ],
      "form": [
        {
          "type": "view",
          "label": "Form View"
        },
        {
          "type": "view",
          "label": "Hires View"
        },
        {
          "type": "view",
          "label": "Compare View"
        },
        {
          "type": "view",
          "label": "PDF View"
        },
        {
          "type": "view",
          "label": "History View"
        },
        {
          "type": "view",
          "label": "Edit Layout"
        },
        {
          "type": "separator",
        },
        {
          "type": "action",
          "label": "Approve"
        },
        {
          "type": "action",
          "label": "Reject"
        },
        {
          "type": "action",
          "label": "Custom Approval"
        },
        {
          "type": "action",
          "label": "Skip Plate Approval"
        },
        {
          "type": "action",
          "label": "UnSkip Plate Approval"
        },
        {
          "type": "action",
          "label": "Hold"
        },
        {
          "type": "action",
          "label": "Release"
        },
        {
          "type": "action",
          "label": "Assign CTP"
        },
        {
          "type": "action",
          "label": "ReOutput Plates"
        },
        {
          "type": "action",
          "label": "Restart From Imposition"
        },
        {
          "type": "action",
          "label": "Show Error Message"
        },
        {
          "type": "action",
          "label": "Custom Route"
        },
        {
          "type": "action",
          "label": "Resend Ink Values"
        },
        {
          "type": "action",
          "label": "Set Complete"
        },
        {
          "type": "action",
          "label": "Save PDF"
        },
        {
          "type": "action",
          "label": "Panel Info"
        },
        {
          "type": "action",
          "label": "Change page type \\ plate template"
        },
        {
          "type": "action",
          "label": "Object Info"
        }
      ]
    }
  }
};


function getToolbarPlaceholdersInfo(toolbarName) {
  return placeholdersMap.toolbar[toolbarName] || [];
}

function getContextMenuPlaceholdersInfo(contextMenuName, itemType) {
  let result = [];

  const menu = placeholdersMap.contextMenu[contextMenuName];
  if (menu) {
    result = menu[itemType] || [];
  }

  return result;
}


module.exports = {
  getToolbarPlaceholdersInfo,
  getContextMenuPlaceholdersInfo
};