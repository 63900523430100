/**
 * Created with JetBrains WebStorm.
 * User: elad
 * Date: 3/9/14
 * Time: 10:07 AM
 * To change this template use File | Settings | File Templates.
 */

import AbstractAction from 'AbstractAction';
import { getMainStatus } from 'utilities/statuses';
import openModule from 'actions/utilities/openModule';

module.exports = AbstractAction.extend({

  isApplicable: function (treeObjs) {
    var objects = this.module.selected;

    if (typeof objects === 'undefined' || objects.length === 0) {
      return typeof treeObjs !== 'undefined';
    }

    for (var i = 0; i < objects.length; i++) {
      var obj = objects[i];
      if (obj.groupPlateType === 'Blanc') {
        return false;
      }
      var content = obj.formContent;
      if (typeof content === 'undefined') {
        content = obj.formSeparation?.separationContent;
      }
      if (typeof content === 'undefined') {
        content = obj.content;//this is for pageview context menu
        if (typeof content === 'undefined') {
          return false;
        }
      }

      var status = getMainStatus(obj);
      if (!status || status.statusType === 'NotAssigned') {
        let separations = obj.separations;
        if (typeof separations === 'undefined' || separations.length === 0) {
          return false;
        } else {
          for (var j = 0; j < separations.length; j++) {
            status = getMainStatus(separations[j]);
            if (status && status.statusType !== 'NotAssigned') {
              return true;
            }
          }
          return false;
        }
      }
    }
    return true;
  },

  execute: function (objects, params) {
    openModule(this, objects, {...this.parameters, ...params});
  }

});
