define([], function () {
  var MAP_VALUES = {
    },
    MAP_TAGS = [
      { tag: 'background-color', styleTags: ['background'] },
      { tag: 'background', styleTags: ['backgroundColor'] },
      { tag: 'border', styleTags: ['borderColor'] }
    ];

  return MAP_TAGS;
});