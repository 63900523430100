/**
 * Created by moshemal on 11/23/14.
 */

import AbstractAction from 'AbstractAction';
import dialog from 'sandbox/dialog';
import request from 'sandbox/request';
import browser from 'base/browser';
import settingsManager from 'core/managers/settings';


const getSeparator = str => {
  if (str.indexOf('/') >= 0) return '/';

  return '\\';
};

const isSep = (chr, sep) => chr === sep;

var globalArgs = null;
var hotFolder = null;

function cancelCallback() {

  var ret = confirm("Are you sure to cancel editing ?");
  if (ret) {

    var customEvent = new CustomEvent('eventCancel', {
      'detail': {},
      bubbles: true,
      cancelable: true
    });

    var hiddenDiv = document.getElementById('container'); //$('#container');//document.getElementById('container');
    hiddenDiv.dispatchEvent(customEvent);
  }

  return ret;
}


function getOriginalFilename() {

  var res = request.simpleGenericCommand(globalArgs[0].nwid, globalArgs[0].type, 'getExpectedFilename');
  return res;
}

//var extensionId = 'knldjmfmopnpolahpmmgbagdohdnhkik';
var extensionId = 'fbmklmohbppnogcjandjkghomdlgkleb';
// Code from http://goo.gl/SnuOJV
function detectExtension(extensionId, success, fail) {

  var img;
  img = new Image();
  img.src = "chrome-extension://" + extensionId + "/test.png";
  img.onload = function () {
    success();
  };
  img.onerror = function () {
    fail();
  };
}

function openNotSupportedDialog() {
  let notSupportedHTML = $('<div>Currently action is supported for Chrome.</div>');

  var progressDialog = dialog.showPlainHtml("Edit PDF", notSupportedHTML, [], this);
}

function openInstallerDialog() {

  let link = "";

  if (browser.getOS() === 'Windows')
    link = "/coretex/resources/chrome/host.zip";
  else
    link = "/coretex/resources/chrome/Proimage EditPDF.pkg";

  let installerHTML = $('<div>Edit PDF Chrome Plugin is not installed.</div><div><a href="' + link + '">EditPDF Chrome Plugin installation download </a></div>');

  var progressDialog = dialog.showPlainHtml("Edit PDF", installerHTML, [], this);
}

function openPluginProgressDialog() {

  var progressId = 'progress-edit-pdf-id';
  var statusLineId = 'status-edit-pdf-id';
  var buttonId = 'cancel-edit-pdf-id';
  var progressHTML = $('<div id="' + statusLineId + '">Please wait...</div><br><div class="progress-horizontal" style="margin-left: 0%; width: 99%; "><div id="' + progressId + '" class="progress-bar" style="width: 0%"></div></div>');
  var progressDialog = dialog.showPlainHtml("Edit PDF", progressHTML, [
    {
      label: "Cancel",
      callback: cancelCallback.bind(this),
      id: buttonId
    }
  ], this);

  document.getElementById(buttonId).addEventListener('uploadFinished', function (e) {
    progressDialog.close();
  });

  var urlParms = {
    imageType: 'pdf',
    nwid: globalArgs[0].nwid,
    pageAction: 4
  };

  var proto = ('https:' === location.protocol ? 'https://' : 'http://')
  var downloadUrl = proto + location.host + request.getImageUrl(urlParms);

  // to do : call to server to define hot folder and original filename

  var originalFilename = getOriginalFilename.call(this).then(function (params) {
    var serverFilename = params.originalFilename;
    var pathToSave = hotFolder;

    var nativeAppInfo =
    {
      server: location.hostname,
      port: location.port,
      uploadUrl: settingsManager.get('webAppPath') + '/servlet/GenericCommandServlet?command=UploadBinaryFile&pathToSave=' + pathToSave + "&filename=" + serverFilename,
      cookieUrl: proto + location.host + settingsManager.get('webAppPath') + '/html/newsway',
      cookie: '',
      filename: ''
    };

    var guiInfo =
    {
      progressId: progressId,
      statusLineId: statusLineId,
      buttonId: buttonId
    };

    var customEvent = new CustomEvent('eventDownloadFile',
      {
        'detail': { downloadUrl: downloadUrl, nativeAppInfo: nativeAppInfo, guiInfo: guiInfo },
        bubbles: false,
        cancelable: true
      });

    var hiddenDiv = document.getElementById('container'); //$('#container');//document.getElementById('container');
    hiddenDiv.dispatchEvent(customEvent);
  });
}

export default AbstractAction.extend({

  execute: function (args) {

    globalArgs = args;
    const separator = getSeparator(this.config.hotfolder) || '';
    hotFolder = isSep(this.config.hotfolder[this.config.hotfolder.lengt - 1], separator) ? this.config.hotfolder : `${this.config.hotfolder}${separator}`;

    if (browser.isChrome())
      detectExtension(extensionId, openPluginProgressDialog, openInstallerDialog);
    else
      openNotSupportedDialog();

  }
});


