import React from 'react';
import { createRoot } from 'react-dom/client';
import AbstractModule from 'AbstractModule';
import View from './components/view';


export default AbstractModule.extend({
  initialModel: null,

  firstTickReceived: function (data) {
    this.reactRoot = createRoot(this.domElement);
    this.render();
  },

  tickUpdate: function (data) {
    this.render();
  },

  destroy: function () {
    this._super();
    this.reactRoot.unmount();
  },

  render: function () {
    this.reactRoot.render(
      <View />);
  }

});