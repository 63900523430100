import React, {Component} from 'react';
import sandbox from 'sandbox';
import PasswordInput from 'components/common/inputs/PasswordInput';
import PropTypes from 'prop-types'

const translate = sandbox.localization.translate;

export default class extends Component {

  constructor(props) {
    super(props);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  static propTypes = {
    store: PropTypes.any
  };

  handlePasswordChange(event, newValue) {
    this.props.store.dispatch({
      type: 'UPDATE_PASSWORD',
      value: newValue
    });
  }

  render() {
    return <PasswordInput className='crtx-text-input-password'
      id='password'
      value={this.props.store.getState().password}
      onChange={this.handlePasswordChange}
      placeholder={translate('Enter Password')} />;
  }
}
