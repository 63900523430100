
import sandbox from 'sandbox';
import OpenModuleAction from './OpenModuleAction';
import { createToastService } from 'core/services/toastService';


export default OpenModuleAction.extend({
  execute: function (args) {
    const rest = sandbox.request.rest(this.nwid);
    const superFunc = this._super;

    const nwid = args[0].nwid;
    const type = args[0].type;

    rest.getBookPlatesInfoOptions(nwid, type).then((res) => {
      if (res.manualPlan) {
        const toastService = createToastService(this.win);
        let toast = toastService.createToast('bottom-right', this.label, 'Please load Planning Wizard and modify the planned production runs', 'error', undefined, () => toast = undefined, false);
      } else {
        superFunc.call(this, args);
      }
    });
  }
});
