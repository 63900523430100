import { fromServerDateOnly, fromServerDate } from 'core/dates';
import settingsManager from 'core/managers/settings';
import { toLocaleBreadcrumbsDate } from 'utilities/breadcrumbs';

const TYPES = {
  PUB_DATE: 'publicationdate',
  PUB_NAME: 'publicationname',
  SECTIONS: 'sections',
  BOOKS: 'books',
  RUNS: 'productionruns'
};

const getAggregatedPubCustomIcons = (publications) => {
  const iconsInfoByName = publications.reduce((acc, pub) => {
    if (pub.customIcons && pub.customIconsPropagation) {
      Object.entries(pub.customIconsPropagation).forEach(([iconName, operator]) => {
        const icon = pub.customIcons[iconName];
        if (icon && (operator === 'and' || operator === 'or')) {
          const count = (acc[iconName]?.count || 0) + 1;
          const op = operator === 'or' && acc[iconName]?.operator === 'and' ? 'and' : operator;
          acc[iconName] = { icon, operator: op, count };
        }
      });
    }

    return acc;
  }, {});

  const customIcons = Object.entries(iconsInfoByName).reduce((acc, [iconName, { icon, operator, count }]) => {
    if (operator === 'or' || operator === 'and' && count === publications.length) {
      acc[iconName] = icon;
    }

    return acc;
  }, {});

  return customIcons;
};

const toPublicationsWithDates = (item, dataAdapter) => {
  const pubDatesMap = {};
  const pubMap = item.publications.reduce((acc, pub) => {
    const label = pub.label;
    if (!acc[label]) {
      acc[label] = [];
    }

    if (!pubDatesMap[pub.date]) {
      const date = fromServerDateOnly(pub.date);
      const formattedDate = toLocaleBreadcrumbsDate(date);
      pubDatesMap[pub.date] = { date, formattedDate };
    }

    const { date, formattedDate } = pubDatesMap[pub.date];
    acc[label].push({ ...pub, role: TYPES.PUB_DATE, pubDate: date, label: formattedDate });

    return acc;
  }, {});

  const type = TYPES.PUB_NAME;
  item.publications = Object.keys(pubMap).map((label, index) => {
    const publications = pubMap[label];

    return {
      viewLinks: dataAdapter.getViewLinks(item.nwid, type),
      virtual: true,
      id: dataAdapter.composeVirtualId(item, index),
      type,
      role: 'publication',
      nwid: item.nwid,
      childrenNames: ['children'],
      children: publications,
      ['children:sorting']: 'pubDate:date',
      label,
      customIcons: getAggregatedPubCustomIcons(publications)
    };
  });

  return item;
};

const toDatesWithPublications = (item, dataAdapter) => {
  const pubMap = item.publications.reduce((acc, pub) => {
    const serverDate = pub.date;
    if (!acc[serverDate]) {
      const pubDate = fromServerDateOnly(serverDate);
      const label = toLocaleBreadcrumbsDate(pubDate);
      acc[serverDate] = { pubDate, label, publications: [] };
    }

    acc[serverDate].publications.push(pub);

    return acc;
  }, {});

  item['publications:sorting'] = 'pubDate:date';
  const type = TYPES.PUB_DATE;
  item.publications = Object.keys(pubMap).map((serverDate, index) => {
    const { pubDate, label, publications } = pubMap[serverDate];

    return {
      viewLinks: dataAdapter.getViewLinks(item.nwid, type),
      virtual: true,
      id: dataAdapter.composeVirtualId(item, index),
      type,
      name: serverDate,
      nwid: item.nwid,
      childrenNames: ['children'],
      children: publications,
      pubDate,
      label,
      customIcons: getAggregatedPubCustomIcons(publications)
    };
  });

  return item;
};

export default {
  publications: (item, dataAdapter) => {
    if (!Array.isArray(item.publications) || item.publications.length <= 0) {
      return item;
    }

    return settingsManager.getFolderInfo().treePubNameFirst ?
      toPublicationsWithDates(item, dataAdapter) : toDatesWithPublications(item, dataAdapter);
  },
  sections: (item, dataAdapter) => {
    if (!Array.isArray(item.sections) || item.sections.length <= 0) {
      return item;
    }

    const type = TYPES.SECTIONS;
    item.sections = [{
      viewLinks: dataAdapter.getViewLinks(item.nwid, type),
      virtual: true,
      id: dataAdapter.composeVirtualId(item, 0),
      type,
      nwid: item.nwid,
      childrenNames: ['children'],
      ['children:sorting']: 'section',
      children: item.sections,
      label: 'Pages'
    }];

    return item;
  },
  books: (item, dataAdapter) => {
    if (!Array.isArray(item.books) || item.books.length <= 0) {
      return item;
    }

    const type = TYPES.BOOKS;
    item.books = [{
      viewLinks: dataAdapter.getViewLinks(item.nwid, type),
      virtual: true,
      id: dataAdapter.composeVirtualId(item, 1),
      type,
      nwid: item.nwid,
      childrenNames: ['children'],
      ['children:sorting']: 'book',
      children: item.books.filter(book => book.productionRunBookSourceId !== 'none'),
      label: 'Forms'
    }];

    return item;
  },
  productionruns: (item, dataAdapter) => {
    if (!Array.isArray(item.productionruns) || item.productionruns.length <= 0) {
      return item;
    }

    if (!settingsManager.get('showRunsInPlanTree')) {
      item.productionruns = [];
      return item;
    }

    const type = TYPES.RUNS;
    item.productionruns = [{
      viewLinks: dataAdapter.getViewLinks(item.nwid, type),
      virtual: true,
      id: dataAdapter.composeVirtualId(item, 2),
      type,
      nwid: item.nwid,
      childrenNames: ['children'],
      ['children:sorting']: 'presetDate:date',
      children: item.productionruns.map(run => {
        run.presetDate = fromServerDate(run.presetTime);
        return run;
      }),
      label: 'Production Runs'
    }];

    return item;
  },
};
