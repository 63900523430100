import controller from "../../../../ThumbnailLayers/controllers/controller";

/**
 * @name
 * @fileOverview
 * @author sergey & Elad
 */
define(['ember', 'text!../templates/structure.hbs', './date', './issue', './edition', './zone', './template' ,'./../../../utils/wizardUtils'],
  function (Ember, MainTemplate, DateView, IssueView, EditionView, ZoneView, TemplatesView, wizardUtils) {
    'use strict';

    return Ember.View.extend({

      init: function() {
        this._super();           
      },

      
      template: Ember.Handlebars.compile(MainTemplate),

      classNames: ['structure-container'],

      dateView: DateView,

      issueView: IssueView,

      editionView: EditionView,

      zoneView: ZoneView,

      templatesView: TemplatesView,

      searchStringValue: "", 

      searchString : function (){
        console.log ("111 " + this.searchStringValue);
        // model.searchStringValue = searchStringValue
        //this.controller.filterPublications(this.searchStringValue);
        this.searchStringValue = this.searchStringValue + "dd";
      }.property('model.searchStringValue'),
      
      editionStyle : function () {
        var manyEditionsPerPublication = this.controller.isManyEditionsPerPublication();        
        var style = manyEditionsPerPublication ? "" : 'height:auto; flex:none; min-height:50px;';
        return style;
      }.property()



    });
  });