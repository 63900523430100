import React from 'react';
import PropTypes from 'prop-types';
import iconService from 'core/services/iconService';
import CircleSeparation from './circleseparation';
import TriangleSeparation from './triangleseparation';
import { getMainStatus } from 'utilities/statuses';

export default class Separation extends React.Component {
  static defaultProps = {
    separation: undefined,
  };

  getSeparation = () => {
    return this.props.separation;
  };

  isLocal = (separation) => {
    return separation.local;
  };

  isLink = (separation) => {
    return !separation.local;
  };

  isPlanned = (separation) => {
    return separation.isPlanned;
  };

  isChase = (separation) => {
    return (separation.baseSeparation !== null);
  };

  getBaseSeparation = (separation) => {
    return separation.baseSeparation;
  };

  getSeparationProgress = (separation) => {
    const status = getMainStatus(separation);
    return typeof status?.progress !== 'undefined' ? status.progress : undefined;
  };

  getSeparationVersion = (separation) => {
    return typeof separation !== 'undefined' && typeof separation.separationContent !== 'undefined' && typeof separation.separationContent.externalVersion !== 'undefined' ?
      separation.separationContent.externalVersion :
      undefined;
  };

  unplannedIcon = (separation) => {
    var isPlanned = this.isPlanned(separation);

    if (!isPlanned) {
      return <img className="UnplannedSeparationIcon" src={iconService.getModuleIcon('MyBirdeye', 'unplanned_separation')} />;
    }
    return undefined;
  };

  linkIcon = (separation) => {
    var isLink = this.isLink(separation);

    if (isLink) {
      return <img className="LinkSeparationIcon" src={iconService.getModuleIcon('MyBirdeye', 'link')} />;
    }
    return undefined;
  };

  separationContent = (separation) => {
    return [
      this.unplannedIcon(separation),
      this.linkIcon(separation)
    ];
  };

  render() {
    var separation = this.getSeparation(),
      baseSeparation = this.getBaseSeparation(separation);

    if (!baseSeparation) {
      return <li>
        <CircleSeparation separation={separation}>
          {this.separationContent(separation)}
        </CircleSeparation>
      </li>;
    }

    return <li>
      <TriangleSeparation separation={separation}>
        {this.separationContent(separation)}
      </TriangleSeparation>
    </li>;
  }
}

Separation.propTypes = {
  separation: PropTypes.object,
};