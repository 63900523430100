/**
 * @name actions
 * @file Redux actions
 *
 * @author Boris
 * @since: 2016-06-23
 */

export default {
  undo: () => {
    return {
      type: 'UNDO'
    };
  },
  redo: () => {
    return {
      type: 'REDO'
    };
  },
  update: (path, value) => {
    return {
      type: 'UPDATE',
      path,
      value
    };
  },
  updateCellImage: (elementPath, imageInfo) => {
    return {
      type: 'UPDATE_CELL_IMAGE',
      elementPath,
      imageInfo
    };
  },
  toggleCollapse: (id) => {
    return {
      type: 'TOGGLE_COLLAPSE',
      id
    };
  },
  selectElement: (elementPath) => {
    return {
      type: 'SELECT_ELEMENT',
      elementPath
    };
  },
  shapeSelected: (shape) => {
    return {
      type: 'SHAPE_SELECTED',
      shape
    };
  },
  moveShape: (canvasShape, event) => {
    return {
      type: 'MOVE_SHAPE',
      canvasShape,
      event
    };
  },
  shapeTransforming: (canvasShape, event) => {
    return {
      type: 'SHAPE_TRANSFORMING',
      canvasShape,
      event
    };
  },
  shapeModified: (canvasShape) => {
    return {
      type: 'SHAPE_MODIFIED',
      canvasShape
    };
  },
  shapeMouseDown: (canvasShape, event) => {
    return {
      type: 'SHAPE_MOUSE_DOWN',
      canvasShape,
      event
    };
  },
  shapeMouseUp: (canvasShape, event, shouldAddToHistory) => {
    return {
      type: 'SHAPE_MOUSE_UP',
      canvasShape,
      event,
      shouldAddToHistory
    };
  },
  mouseMove: (event) => {
    return {
      type: 'MOUSE_MOVE',
      event
    };
  },
  shapeTextEvent: (canvasShape, eventName) => {
    return {
      type: 'SHAPE_TEXT_EVENT',
      canvasShape,
      eventName
    };
  },
  selectAddOption: (groupPath, optionId) => {
    return {
      type: 'SELECT_ADD_OPTION',
      groupPath,
      optionId
    };
  },
  addNewElement: (group) => {
    return {
      type: 'ADD_NEW_ELEMENT',
      group
    };
  },
  importElement: (elementType) => {
    return {
      type: 'IMPORT_ELEMENT',
      elementType
    };
  },
  cleanupFurnitures: () => {
    return {
      type: 'CLEANUP_FURNITURES',
    };
  },
  addFurnitureOption: (furniture) => {
    return {
      type: 'ADD_FURNITURE_OPTION',
      furniture
    };
  },
  deleteElement: (elementPath) => {
    return {
      type: 'DELETE_ELEMENT',
      elementPath
    };
  },
  deleteSelectedElement: () => {
    return {
      type: 'DELETE_SELECTED_ELEMENT'
    };
  },
  duplicateElement: (elementPath) => {
    return {
      type: 'DUPLICATE_ELEMENT',
      elementPath
    };
  },
  nudgeShape: (direction) => {
    return {
      type: 'NUDGE_SHAPE',
      direction
    };
  },
  save: () => {
    return {
      type: 'SAVE'
    };
  },
  zoomOut: () => {
    return {
      type: 'ZOOM_OUT'
    };
  },
  zoomIn: () => {
    return {
      type: 'ZOOM_IN'
    };
  },
  toggleAlignments: () => {
    return {
      type: 'TOGGLE_ALIGNMENTS'
    };
  },
  createGuideline: (orientation, event) => {
    return {
      type: 'CREATE_GUIDELINE',
      orientation,
      event
    };
  },
  setDialogVisible: (visible) => {
    return {
      type: 'SET_DIALOG_VISIBLE',
      visible
    };
  },


};
