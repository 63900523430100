/**
 * Created by moshemal on 4/5/16.
 */
define(['logger', 'base/data'], function (logger, data) {
  'use strict';

  var log = logger.getDefaultLogger();
  var states = ["LOGGED_IN", "CHANNEL_OPENED"];
  var enumStates = states.reduce(function(res, state){
    res[state] = state;
    return res;
  }, {});

  var deferredObjs = states.reduce(function(res, state){
    res[state] = data.deferred();
    return res;
  }, {});

  function getPromise(stateEnum) {
    return deferredObjs[stateEnum] ? deferredObjs[stateEnum].promise() : null;
  }

  function resolveReject(method, stateEnum, args) {
    if(deferredObjs[stateEnum]){
      deferredObjs[stateEnum][method](args);
    } else {
      log.debug("trying to" + method + " a state that is not exist");
    }
  }

  var appPromises = {
    states:     enumStates,
    getPromise: getPromise,
    resolve:    function(stateEnum, args){return resolveReject("resolve", stateEnum, args)},
    reject:     function(stateEnum, args){return resolveReject("reject", stateEnum, args)}
  };

  /*
    Examples
   */



  return appPromises;
});