/**
 * Created by moshemal on 1/8/15.
 */

import Ember from 'ember';
import $ from 'jquery';
import sandbox from 'sandbox';
import AbstractStep from 'widgets/Wizard/AbstractStep';
import SectionsMainView from './views/SectionsMain';
import Controller from './controllers/SectionsController';
import ModelHandler from './model/model';
import {restGet} from 'core/managers/rest2';
import dialogService from 'core/services/dialogService';

var LOADING_URL = "../kernel/assets/img/sys/ajax-loader-big.gif";

function dataRecieved(data) {
  $(this.container).empty();	
  var planContainersIds = data.planContainersIds;
  if (planContainersIds){
    var params = {planContainersIds: planContainersIds};
    var rest = sandbox.request.rest(this.instance);
    rest.post(`global/locking/containers/lock/PlanningWizard`, {data: JSON.stringify(params)}).then((responseLocking) => {          
      if (responseLocking.LockError) {
        
        dialogService.openAlertDialog(responseLocking.LockError).then(() => dataRecievedInternal(data, responseLocking));
        
      } else {
        dataRecievedInternal.call(this, data, responseLocking);
      }
      
    });
  } else {
    dataRecievedInternal.call(this, data);
  }
}

function dataRecievedInternal(data, responseLocking = {}) {

  if (responseLocking.LockError) {
    
      window.close();
    
    return;
  }
  this.model.publication.editions[0] = data.edition;
  this.model.publication.sourceId = data.publicationSourceId;
  this.model.publication.manualPlan = data.manualPlan;
  this.model.publication.LockingGroupId = responseLocking.LockingGroupId;
  var zones = this.model.publication.editions[0].zones;
  for (var i = 0; i < zones.length; i++) {
    var planSetupZone = Ember.get(this, 'model.planSetup').getZone({displayName: this.model.publication.displayName},
      zones[i].displayName);
    if (planSetupZone !== null) {
      var shortName = planSetupZone.shortName;
      zones[i].code = shortName === null || typeof shortName === 'undefined' ? zones[i].displayName : shortName;

      zones[i].share = planSetupZone.share === 'true';
      zones[i].color = planSetupZone.color;
      if (typeof planSetupZone.parent !== 'undefined' && planSetupZone.parent !== null &&
        planSetupZone.parent !== zones[i].displayName) {
        zones[i].masterZone = planSetupZone.parent;
      }
    }
  }

  internalRender.call(this);

}

function load() {
  console.log("load.................");
  return new Promise((resolve, reject) => {

    var emptyTemplate = this.settings.emptyTemplate;
    var loadMode = this.model.editionWasLoaded || this.model.chaseMode || emptyTemplate;
    if (!loadMode) {
      resolve();
      return;
    }
    this.properties.projectorId = this.id;
    var rootType = this.rootType;
    var rootId = this.rootId;
    var instance = this.instance;
    var that = this;

    if (this.templateName) {
      restGet(instance, `folders/${this.rootId}/planning-actions/getTemplate?publicationName=${that.publicationName}&templateName=${that.templateName}`)
        .then(result => {
          that.model.publication = result;
          resolve();
        })

    } else if (emptyTemplate) {
      if (!this.model.publication) {
        var publication = ModelHandler.createEmptyTemplate();
        this.model.publication = publication;
      }

      resolve();

    } else {
      $(this.container).empty();		
      this.model["editionWasLoaded"] = false;
      var editionNwid = this.model["editionToLoad"];
      var zones = this.model["zones"];
      var edition = this.model.publication.editions[0];
      var editionToChase = this.model["editionToChase"]
      var chaseMode = this.model["chaseMode"];
      sandbox.request.getPlan(this, that.rootId, dataRecieved, this.id,
        {
          rootId: rootId,
          rootType: rootType,
          instanceNwId: instance,
          edition: editionNwid,
          zones: zones,
          editionModel: edition,
          editionWasLoaded: true,
          editionToChase: editionToChase,
          chaseMode: chaseMode
        }
      ).then(res => {
        resolve();
      });
    }

  });
}

function init(initObj) {
  this._super(initObj);

  this.properties.projectorId = this.id;
  this.rootType = initObj.startParameters.rootType;
  this.rootId = initObj.startParameters.rootId;
  this.instance = initObj.startParameters.nwid;
  this.wizard = initObj.wizard;
  this.templateName = initObj.startParameters.templateName;
  this.publicationName = initObj.startParameters.publicationName;
  this.settings = this.properties.properties;

}


function back() {
  return this.controller.updateModel(this.model);
}

function next() {
  return this.controller.updateModel(this.model);
}

function destroy() {
  console.log("destroy");
}

function render(){
  var emptyTemplate = this.settings.emptyTemplate;
  var loadMode = this.model.editionToLoad || this.model.editionToChase || emptyTemplate;
  if (!loadMode) {
    internalRender.call(this);
  }
}

function internalRender() {


  $(this.container).empty();
  $(this.container).append($('<div class="wizard-loading-img"><img src="' + LOADING_URL + '"></div>'));
  delete this.model.editionWasLoaded;
  delete this.model.editionNwid;
  delete this.model.zones;
  delete this.model.chaseMode;
  delete this.model.editionToChase;
  //}

  this.wizard.disableButton("cancel");
  //this._view.rerender();
  var that = this;
  var model = ModelHandler.convertToStepModel(that.model);
  if (typeof this.controller === "undefined" || this.controller === null) {
    this.controller = Controller.create({
      model: model,
      settings: that.settings
    });
  } else {
    Ember.set(this.controller, 'model', ModelHandler.convertToStepModel(this.model));
  }
  //Ember.set(this.controller, "settings", this.settings);
  //if (!(this._view instanceof Ember.View)){
  $(this.container).empty();
  this._view = SectionsMainView.create({
    controller: that.controller
  }).appendTo(this.container);
}


module.exports = AbstractStep.extend({
  init: init,
  render: render,
  //back:				back,
  next: next,
  destroy: destroy,
  load: load
}, "Sections");