define(['sandbox', 'ember', 'logger'], function (sandbox, Ember, logger) {
  'use strict';
  var log = logger.getDefaultLogger();

  var timeoutId;

  return Ember.ObjectController.extend({
    init: function () {
      this.set('messages', []);
      this.set('isFiltered', false);
    },

    deleteMessage: function (message) {
      var deleteMessageAction = this.get('module.viewActions').find(a => a.actionDefinitionName === 'DeleteMailActionCR');
      if (typeof deleteMessageAction === 'undefined') {
        log.error('DeleteMailAction instance is not found');
        return;
      }

      if (deleteMessageAction.isApplicable(message)) {
        deleteMessageAction.execute(message);
      }
    },

    actions: {
      filterMessages: function () {
        this.set('isFiltered', true);
        var messages = this.get('messages');
        var content = this.get('content.userSystemMails');
        messages.clear();
        content.forEach(function (msg) {
          if (msg.isRead === false) {
            messages.pushObject(msg);
          }
        }, this);
      },

      showAllMessages: function () {
        this.set('isFiltered', false);
        var messages = this.get('messages');
        var content = this.get('content.userSystemMails');
        messages.clear();
        content.forEach(function (msg) {
          messages.pushObject(msg);
        }, this);
      }
    },

    contentDidChange: function () {
      if (typeof this.get('messages') !== 'undefined' && this.get('messages').length > 0) {
        this.tickUpdateHandler();
        return;
      }
      if (this.get('isFiltered')) {
        this.send('filterMessages');
      }
      else {
        this.send('showAllMessages');
      }
    }.observes('userSystemMails.@each'),


    tickUpdateHandler : function () {
      var messages = this.get('messages');
      var compareProperties = ['nwid','isRead'];
      var keyProperty = 'nwid';
      var content;

      if (this.get('isFiltered')) {
        content = this.get('unreadMessages');
      } else {
        content = this.get('content.userSystemMails');
      }
      content.forEach(function(item) {
        var objToUpdate = messages.findBy(keyProperty, item[keyProperty]);
        if (typeof objToUpdate !== 'undefined') {
          compareProperties.forEach(function (compareProperty) {
            if(objToUpdate[compareProperty] !== item[compareProperty]) {
              Ember.set(objToUpdate, compareProperty, item[compareProperty]);
            }
          });
        } else {
          messages.insertAt(0, item);
        }
      });

      var needToBeRemoved = [];
      messages.forEach(function(item) {
        var found = content.findBy(keyProperty, item[keyProperty]);
        if (typeof found === 'undefined') {
          needToBeRemoved.push(item);
        }
      });
      messages.removeObjects(needToBeRemoved);
    },

    showMailContent : function (selected) {
      var showMailAction = this.get('module.viewActions').find(a => a.actionDefinitionName === 'ShowMailActionCR');
      if (typeof showMailAction === 'undefined') {
        log.error('ShowMailAction instance is not found');
        return;
      }
      if (!Array.isArray(selected)) {
        return;
      }

      if (selected.length !== 1) {
        this.set('message', '');
        return;
      }

      showMailAction.execute(selected).then(function (res)  {

        var message = sandbox.jsUtils.clone(selected[0]);
        message.messageContent = res.data.result;
        this.set('message', message);

        clearTimeout(timeoutId);

        if(selected[0].isRead === false) {
          timeoutId = setTimeout(function(){
            this.markMailAsRead(selected);
          }.bind(this), 200);
        }
      }.bind(this));
    },

    markMailAsRead: function (selected) {
      var markMailAsReadAction = this.get('module.viewActions').find(a => a.actionDefinitionName === 'MarkMailAsReadActionCR');
      if (typeof markMailAsReadAction === 'undefined') {
        log.error('MarkMailAsReadAction instance is not found');
        return;
      }
      markMailAsReadAction.execute(selected);
    },

    unreadMessages: function() {
      var content = this.get('content.userSystemMails');
      return content.filterBy('isRead', false);
    }.property('userSystemMails.@each.isRead')

  });

});
