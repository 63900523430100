import React, { forwardRef } from 'react';
import ConversionInput from './ConversionInput';

function NumericInput(props, ref) {
  return (
    <ConversionInput
      {...props}
      ref={ref}
      factor={1}
    />
  );
}

const NumericInputWithRef = forwardRef(NumericInput);

NumericInputWithRef.propTypes = {...ConversionInput.propTypes};
delete NumericInputWithRef.propTypes.factor;

export default NumericInputWithRef;
