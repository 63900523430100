/**
 * @name Cluster
 * @file Cluster component
 *
 * @author Tanya
 * @since: 2020-06-08
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table, { Column, cells, headers } from 'widgets/ReactDataGrid';
import { ROW_HEIGHT } from 'widgets/ReactDataGrid/utils';


const { GenericHeader, HeaderCaption } = headers;
const { Text } = cells;

export default class Cluster extends Component {
  static propTypes = {
    viewModel: PropTypes.object,
    module: PropTypes.object
  };

  renderColumnHeaderCaption = (props, column, module, sortDirection, index) => {
    return (
      <HeaderCaption {...props} sortDirection={sortDirection} sortOrder={index + 1} sortable={!!column.sortType} tooltip={column.displayName}
        onSort={(multiSort) => module.handleSort(column.key, multiSort)} >
        {column.displayName}
      </HeaderCaption>
    );
  };

  renderColumnHeader = (props, column, module, sortDirection, index) => {
    return (
      <GenericHeader captionRenderer={this.renderColumnHeaderCaption(props, column, module, sortDirection, index)} />
    );
  };

  render() {
    const { module, viewModel } = this.props;

    return <div className='crtx-cluster-view'>
      <div className='crtx-cluster-view-main'>
        <Table
          headerHeight={31}
          rowHeight={ROW_HEIGHT}
          rows={viewModel.clusterTableRows}
          columnKey='nwid'
          virtualScroll={true}
          minColumnWidth={40}
          onSelect={selectedRows => module.handleRowSelect(selectedRows)}
          onRowContextMenu={(rowIndex, rowContent, selectedRows, e) => module.handleClusterTableContextMenu(rowContent, selectedRows, e)}
          onColumnResize={(columns, columnKey) => module.handleClusterTableColumnResize(columns, columnKey)}
        >

          {viewModel.clusterTableColumns.map((col, index) => {
            const columnKey = col.key || col.displayName;
            const idx = module.columnsToSortBy.findIndex(col => col.key === columnKey);
            const sortDirection = idx < 0 ? '' : module.columnsToSortBy[idx].ascending ? 'asc' : 'desc';

            return <Column
              key={index}
              columnKey={columnKey}
              visible={col.visible}
              fixed={col.fixed}
              width={col.width}
              minWidth
              resizable={col.resizable !== false}
              align={col.align || 'left'}
              header={props => this.renderColumnHeader(props, col, module, sortDirection, idx)}
              cell={col.cell || Text}
              cellDataGetter={col.cellDataGetter}
              valueGetter={col.valueGetter}
            />;
          }
          )}
        </Table>
      </div>
    </div>;
  }

}