import { fromServerDate } from 'core/dates';

export default {
  productionRuns: (item, dataAdapter) => {
    if (!Array.isArray(item.productionRuns)) {
      return item;
    }

    item['productionRuns:sorting'] = 'presetDate:date';
    item.productionRuns.forEach(run => {
      run.presetDate = fromServerDate(run.presetTime);
    });

    return item;
  },
};
