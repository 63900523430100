import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Cell from './Cell';
import { classNames } from 'utilities/classNames';

export default class extends Component {
  static propTypes = {
    rowIndex: PropTypes.number,
    rowContent: PropTypes.any,
    rowHeight: PropTypes.number,
    rowPositionTop: PropTypes.number,
    columnsByGroups: PropTypes.any.isRequired,
    columns: PropTypes.object.isRequired,
    rowsLength: PropTypes.number.isRequired,
    group: PropTypes.string,
    groupsWidth: PropTypes.any,
    currentWidth: PropTypes.number,
    scrollTop: PropTypes.number,
    scrollLeft: PropTypes.number,
    hasVerticalScrollbar: PropTypes.bool,
    isSelected: PropTypes.bool,
    isMouseHover: PropTypes.bool,
    showOnlyGroup: PropTypes.string,
    isImmutable: PropTypes.bool,
    allowDragOver: PropTypes.bool,
    onMouseEnter: PropTypes.func,
    onMouseMove: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onContextMenu: PropTypes.func,
    onDragOver: PropTypes.func,
    onDragLeave: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    shouldRowUpdate: PropTypes.func,
    getRowClassName: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.array
    ]),
    borderSelection: PropTypes.bool,
  };

  static defaultProps = {
    isSelected: false,
    onMouseMove: function () {
    },
    onMouseEnter: function () {
    },
    onMouseLeave: function () {
    },
    onClick: function () {
    },
    onDoubleClick: function () {
    },
    onContextMenu: function () {
    },
    borderSelection: false,
  };

  state = {
    isDragOver: false
  };

  onMouseEnterRow = (ev) => {
    var { rowIndex, rowContent, onMouseEnter } = this.props;
    onMouseEnter(rowIndex, rowContent, ev);
  };

  onMouseMove = (ev) => {
    var { rowIndex, rowContent, onMouseMove } = this.props;
    onMouseMove(rowIndex, rowContent, ev);
  };

  onMouseLeaveRow = (ev) => {
    var { rowIndex, rowContent, onMouseLeave } = this.props;
    onMouseLeave(rowIndex, rowContent, ev);
  };

  onClickRow = (ev) => {
    var { rowIndex, rowContent, onClick } = this.props;
    onClick(rowIndex, rowContent, ev);
  };

  onDoubleClickRow = (ev) => {
    var { rowIndex, rowContent, onDoubleClick } = this.props;
    onDoubleClick(rowIndex, rowContent, ev);
  };

  onContextMenuRow = (ev) => {
    var { rowIndex, rowContent, onContextMenu } = this.props;
    onContextMenu(rowIndex, rowContent, ev);
  };

  onDragOver = (ev) => {
    var { rowIndex, rowContent, allowDragOver, onDragOver } = this.props;
    var { isDragOver } = this.state;

    if (!allowDragOver) return;

    if (isDragOver !== true) {
      this.setState({
        isDragOver: true
      });
      onDragOver(rowIndex, rowContent, ev);
    }

    ev.stopPropagation();
    ev.preventDefault();
  };

  onDragLeave = (ev) => {
    var { rowIndex, rowContent, allowDragOver, onDragLeave } = this.props;
    var { isDragOver } = this.state;

    if (!allowDragOver) return;

    if (isDragOver !== false) {
      this.setState({
        isDragOver: false
      });
      onDragLeave(rowIndex, rowContent, ev);
    }
  };

  handleDragStart = e => {
    var { rowIndex, rowContent, onDragStart } = this.props;

    if (typeof onDragStart === 'function') {
      onDragStart(rowIndex, rowContent, e);
    }
  };

  onDrop = (ev) => {
    var { rowIndex, rowContent, allowDragOver, onDrop } = this.props;

    if (!allowDragOver) return;

    this.setState({
      isDragOver: false
    });
    onDrop(rowIndex, rowContent, ev);

    ev.stopPropagation();
    ev.preventDefault();
  };

  cellComponent = (
    index,
    columnKey,
    width,
    cellData,
    align,
    columnData,
    rowIndex,
    rowHeight,
    rowContent,
    props,
    cellComponent,
  ) => {
    if (typeof cellComponent === 'function') {
      let CellComponent = cellComponent;

      return <CellComponent
        key={columnKey}
        rowIndex={rowIndex}
        rowHeight={rowHeight}
        rowContent={rowContent}
        columnIndex={index}
        columnKey={columnKey}
        columnWidth={width}
        align={align}
        columnData={columnData}
        onDragStart={this.props.onDragStart}
        {...props}
        {...cellData} />;
    }

    return React.cloneElement(cellComponent, {
      key: columnKey,
      columnIndex: index,
      columnKey: columnKey,
      columnWidth: width,
      align: align,
      columnData: columnData,
      rowIndex: rowIndex,
      rowHeight: rowHeight,
      rowContent: rowContent,
      ...props,
      ...cellData
    });
  };

  renderCell = (
    columnsByGroups,
    columns,
    rowIndex,
    rowContent,
    rowHeight,
    column,
    columnIndex,
    isEmpty,
  ) => {
    if (typeof column === 'undefined') return null;
    var {
      header,
      cell,
      flex,
      align,
      columnKey,
      onClick,
      cellDataGetter,
      dataGetter,
      fixed,
      title,
      ...props
    } = column.props;
    //var isFixed = fixed === 'left' || fixed === 'right' ? true : false;
    var columnWidth = columns[columnKey].width;
    var cellData = cellDataGetter ? cellDataGetter(rowIndex, columnKey) : dataGetter(rowContent);
    var cellComponent = this.cellComponent(columnIndex, columnKey, columnWidth, cellData, align, rowContent[columnKey], rowIndex, rowHeight, rowContent, props, cell);
    //console.log(columnWidth);
    return <Cell
      key={columnKey}
      rowIndex={rowIndex}
      rowHeight={rowHeight}
      columnIndex={columnIndex}
      columnKey={columnKey}
      columnWidth={columnWidth}
      flex={flex}
      align={align}
      columnData={rowContent[columnKey]}
      rowContent={rowContent}
      {...props}
      {...cellData}
    >

      {!isEmpty ? cellComponent : null}

    </Cell>;
  };

  renderCells = (columnsByGroups, columns, rowIndex, rowContent, rowHeight, group) => {
    const groupName = typeof group === 'string' ? group : 'center';
    const groupColumns = (columnsByGroups[groupName] || []).filter(column => column.visible);

    return groupColumns.map((column) => {
      return this.renderCell(groupColumns, columns, rowIndex, rowContent, rowHeight, column.column, column.index, false);
    });
  };

  rowClassName = (
    getRowClassName,
    defaultClassName,
    isSelected,
    isMouseHover,
    isDragOver,
    rowIndex,
    borderSelection
  ) => {
    var rowClassName = [defaultClassName];

    if (isSelected && !borderSelection) rowClassName.push('selected');
    if (isMouseHover && !borderSelection) rowClassName.push('hover');
    if (isDragOver) rowClassName.push('dragover');
    if (rowIndex % 2 === 0) rowClassName.push('alt');
    if (typeof getRowClassName === 'function') rowClassName = [...rowClassName, ...(getRowClassName(rowIndex))];

    return rowClassName.join(' ');
  };

  //shouldComponentUpdate: function (nextProps) {
  //  return (
  //  //(nextProps.isImmutable === false  ) ||
  //    nextProps.rowIndex !== this.props.rowIndex ||
  //    nextProps.rowContent !== this.props.rowContent ||
  //    nextProps.rowPositionTop !== this.props.rowPositionTop ||
  //    nextProps.isSelected !== this.props.isSelected ||
  //    nextProps.isMouseHover !== this.props.isMouseHover ||
  //    nextProps.columns !== this.props.columns ||
  //    nextProps.shouldRowUpdate(nextProps, this.props)
  //  );
  //},

  render() {
    var {
      rowIndex,
      rowContent,
      rowHeight,
      rowPositionTop,
      columnsByGroups,
      columns,
      rowsLength,
      group,
      groupsWidth,
      currentWidth,
      scrollTop,
      scrollLeft,
      hasVerticalScrollbar,
      isSelected,
      isMouseHover,
      getRowClassName,
      onDragStart,
      borderSelection,
    } = this.props;
    var { isDragOver } = this.state;

    var rowStyle = {
      transform: 'translate3d(0px, ' + rowPositionTop + 'px, 0)',
      paddingLeft: typeof group === 'string' ? 0 : groupsWidth.left,
      paddingRight: typeof group === 'string' ? 0 : groupsWidth.right,
      height: rowHeight
    };
    var rowClassName = this.rowClassName(getRowClassName, 'react-data-grid-body-row', isSelected, isMouseHover, isDragOver, rowIndex, borderSelection);
    var rowCells = rowIndex >= 0 && rowIndex < rowsLength ? this.renderCells(columnsByGroups, columns, rowIndex, rowContent, rowHeight, group) : null;

    return <div
      className={rowClassName}
      style={rowStyle}
      onMouseEnter={this.onMouseEnterRow}
      onMouseMove={this.onMouseMove}
      onMouseLeave={this.onMouseLeaveRow}
      onClick={this.onClickRow}
      onDoubleClick={this.onDoubleClickRow}
      onContextMenu={this.onContextMenuRow}
      onDragOver={this.onDragOver}
      onDragLeave={this.onDragLeave}
      onDrop={this.onDrop}
      draggable={typeof onDragStart === 'function'}
      onDragStart={this.handleDragStart}
    >
      {borderSelection && isSelected ?
        <div className={classNames('selection-rectangle', { right: group === 'right' })} /> : null}
      {borderSelection && isMouseHover ?
        <div className={classNames('hover-rectangle', { right: group === 'right' })} /> : null}
      {rowCells}
    </div>;
  }
}
