import AbstractModule from 'AbstractModule';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';

import MainView from './components/MainView';
import Constants from './Constants';
import reducer from './Reducer';
import { restGet } from 'core/managers/rest2';


function getCreateTemplateLink(links) {
  let createTemplate = links.filter(l => l.toLowerCase().includes("label=create template"));
  if (createTemplate.length > 0) {
    return createTemplate[0];
  }
}

module.exports = AbstractModule.extend({

  initiateStore: function () {
    this.store = createStore(reducer,
      { [Constants.TYPE]: "", [Constants.TEMPLATES]: [], [Constants.FILTER]: "", [Constants.FILTERED]: [], [Constants.ACTION_NWID]: this.nwid, [Constants.FOLDER_NWID]: this.folderNwid },
      applyMiddleware(ReduxThunk));
    this.store.subscribe(this.render.bind(this));
  },

  initDone: function () {
    this.initStore = this.initiateStore.bind(this);
    this.reactRoot = createRoot(this.domElement);
    this.initStore();
  },

  firstTickReceived: function (data) {
    this.data = data;
    this.loadTemplates();
  },

  loadTemplates: function () {
    let url = "planning-wizard/custom/sanoma/templates?" + Constants.FOLDER_NWID + "=" + this.folderNwid;

    return restGet(this.nwid, url)
      .then(res => {
        this.store.dispatch({
          [Constants.TYPE]: Constants.ADD_TEMPLATES,
          [Constants.TEMPLATES]: res[Constants.TEMPLATES],
          [Constants.CREATE_TEMPLATE_LINK]: getCreateTemplateLink(this.data.model.viewLinks),
          [Constants.MODULE_NWID]: this.nwid
        });
      });
  },

  tickUpdate: function () {

  },

  tickCommit: function () {
    // this.render();
  },

  apply: function () {

  },

  render: function () {
    this.reactRoot.render(<MainView store={this.store} loadTemplates={() => this.loadTemplates()} />);
  }
});