/**
 * @name
 * @fileOverview
 * @author sergey & Elad
 */
define(['ember', 'text!../templates/issue.hbs', 'sandbox'],
  function (Ember, IssueTemplate, sandbox) {
    'use strict';

    return Ember.View.extend({
      layout: Ember.Handlebars.compile(IssueTemplate),
      //
      //isChecked: function (){
      //  return this.get("controller").isPublicationSelected(this, this.get('model'));
      //}.property("this.controller.templateWasLoaded", "this.controller.model.publication"),

      //publicationChecked: function () {
      //  console.log ("!!!!!!!!!!!!! " + this.publication);
      //  if (this.model.publication === null || typeof this.model.publication === 'undefined')
      //    return false;
      //  return this.publication === this.model.publication.displayName;
      //}.property('this.controller.publicationChanged'),
      isDisabled: function (){
        //return this.get("controller").isLoadMode();
        return false;
      }.property("this.controller.isLoadMode"),

      isLoadOption: function (){
        if (this.model.loadOption){
          return "visibility:visible;padding-right: 5px";
        }
        return "visibility:hidden;padding-right: 5px";
      }.property('this.model.loadOption'),

      okIconState: function () {

        return this.model.selected ? sandbox.icons.getModuleIcon("PlanningWizard", "ok_selected") :
          sandbox.icons.getModuleIcon("PlanningWizard", "ok");
      }.property('this.model.selected'),

      click: function (evt) {
        var checkBox = evt.target;
        if (checkBox.tagName !== "INPUT"){
          return;
        }
        var controller = this.get("controller");
        if (checkBox.checked){
          controller.publicationActionDone(this.get('model'), checkBox.checked);
        }
      }

    });
  });