import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utilities/classNames';
import { toLocaleShortDateTime } from 'core/services/localization';
import ProgressBar from 'components/common/progress/ProgressBar';

export default function PlatesProgressBar({ module, run }) {
  const { press, aggregate: { totalFinishedPlates = 0, totalPlates = 0, progressBarValue = 0 }, deadlinePassed, deadline } = run;

  const isPassed = () => {

    return deadlinePassed && totalFinishedPlates !== totalPlates;
  };

  const handleDoubleClick = (event) => {
    module.openPlates();
  };

  const handleContextMenu = (event) => {
    module.contextMenuPlates(event);
  };

  return (
    <div
      onContextMenu={handleContextMenu}
      onDoubleClick={handleDoubleClick}
      className={classNames('production-run-progressbar', { 'production-run-progressbar-red': isPassed() })}>
      <div className='press-name'> {press}</div>
      <ProgressBar min={0}
        max={1}
        value={progressBarValue}
        vertical={true}
        animated={true}
        title={toLocaleShortDateTime(deadline)}
      />
      <div className='plates-description'>
        {totalFinishedPlates + '/' + totalPlates}
      </div>
    </div>
  );
}

PlatesProgressBar.propTypes = {
  module: PropTypes.object,
  run: PropTypes.object,

};
