import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Content extends Component {

  static defaultProps = {
    show: true,
  };

  render() {
    const { show, children } = this.props;
    return <div className="CollapsiblePanel-Content">
      {show && children}
    </div>;
  }
}

Content.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node
};