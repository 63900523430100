import React from 'react';
import { createRoot } from 'react-dom/client';
import settingsManager from 'core/managers/settings';
import requestManager from 'core/managers/request';
import AbstractModule from 'AbstractModule';
import View from './components/view';
import { createObjectComparator, composeComparators } from 'core/comparators';
import { createRoleComparator } from './components/constants';

export default AbstractModule.extend({
  initDone: function () {
    this.reactRoot = createRoot(this.domElement);
  },

  firstTickReceived: function (data) {
    this.buildViewModel(data);
    this.render();
  },

  tickUpdate(data) {
  },

  buildViewModel: function (data) {
    const { model } = data;

    const comparator = composeComparators([createRoleComparator, createObjectComparator('name')]);
    const { layoutGroups = [] } = model;

    layoutGroups.forEach(group => {
      group.layoutLinks = group.layoutLinks ? group.layoutLinks.sort(comparator) : [];
    });

    this.model = {
      nwid: model.nwid,
      folderNwid: model.folderNwid,
      formLayoutOnly: model.editLayout === 'true',
      plates: model.plates,
      layouts: model.layouts,
      layoutGroups: layoutGroups,
      inks: model.inks,
    };

    this.settings = {
      userUnits: settingsManager.getLengthUnit(),
      folderNwid: model.folderNwid,
      fonts: model.fonts.fonts,
      regmarksOptions: model.marks.marks,
      furnitures: model.furnitures,
    };
  },

  reloadFurnitures: function () {
    const params = {
      action: 'getAvailableFurnitures',
      command: 'getLayouManagerActions',
      rootId: this.viewSettings.rootId
    };
    return requestManager.genericRequestPromise(params)
      .then(furnitures => {
        if (Array.isArray(furnitures)) {
          this.settings.furnitures = furnitures;
        }
        return furnitures;
      });
  },
  destroy: function () {
    // Do nothing
  },

  render: function () {
    this.reactRoot.render(<View initialModel={this.model} module={this} settings={this.settings} />);
  }

});



