import React from 'react';

export const Toolbar = ({title, onClick}) => {

  const handleAddButtonClick = () => {
    onClick();
  };

  return (
    <div className="crtx-form-button-link-with-text" onClick={handleAddButtonClick}>
      <i className="material-icons" title={title}>add</i>{title}
    </div>);
};
