import React from 'react';
import PropTypes from 'prop-types';

export function SeparatorItem(
  {
    children,
  }) {

  return (
    <li
      role='separator'
      className='crtx-dropdown-separator'
    >
      {children ?? <div className='hr'/>}
    </li>
  );
}

SeparatorItem.propTypes = {
  children: PropTypes.node,
};
