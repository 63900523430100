/**
 * @name Widgets
 * @fileOverview Boostrapping file for all widgets.
 * It should return the object according to the naming convention of Ember
 * for Ember.Components.
 * @see <a href="http://emberjs.com/guides/components/customizing-a-components-element/">Ember Components</a>
 * @author sergey
 */
define(['widgets/SelectionLists/SelectionLists', 'widgets/EditableField/EditableField', 'widgets/ResourceLed/ResourceLed', 
  'widgets/MultiSelection/MultiSelection', 'widgets/ValidatedInput/ValidatedInput'],
function (SelectionLists, EditableField, ResourceLed, MultiSelection, ValidatedInput) {
  'use strict';
  return {
    EditableField: EditableField,
    SelectionList: SelectionLists,
    ResourceLed: ResourceLed,
    MultiSelection: MultiSelection,
    ValidatedInput: ValidatedInput
  };
});