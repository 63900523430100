import React, { Component } from 'react';
import PropTypes from 'prop-types';
import utils from '../utils';
import SimpleFormComponent from '../SimpleFormComponent';
import actions from '../actions';

export default SimpleFormComponent(class extends Component {
  static propTypes = {
    //store - The main store of the SimpleForm that the input is child of.
    store: PropTypes.object,
    bind: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    visible: PropTypes.bool,
    className: PropTypes.string,
    col: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    offset: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    actionType: PropTypes.string
  };

  static defaultProps = {
    bind: '',
    actionType: 'UPDATE'
  };

  handleChange = (ev) => {
    var { store, bind, actionType } = this.props;

    //store.actions.update(bind, ev.target.checked);
    store.dispatch(actions.update(bind, ev.target.checked, actionType));
  };

  render() {

    var { store, bind, label, style, disabled, visible, className, ...props } = this.props;

    return <label className={utils.combineClassNames(['input-checkbox', className, disabled ? 'disabled' : null])}
      style={style}>
      <input type="checkbox"
        checked={store.get(bind) || false}
        onChange={this.handleChange}
        disabled={disabled} />
      {label}
    </label>;
  }
});
