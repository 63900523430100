/**
 * Created by elad on 1/3/2016.
 */
define(['react', 'react-dom/client', 'sandbox', 'AbstractModule', './src/CTPImportSelected', 'text!./model.json'],
  function (React, ReactDOM, sandbox, AbstractModule, CTPImportSelected) {
    'use strict';

    const alphaCompare = (order = 'asc', columnKey) => {
      if (order === 'desc') return sandbox.sorting.getComparator('alphadecending', columnKey);
      return sandbox.sorting.getComparator('alpha', columnKey);
    };
    const numericCompare = (order = 'asc', columnKey) => {
      if (order === 'desc') return sandbox.sorting.getComparator('numericdecending', columnKey);
      return sandbox.sorting.getComparator('numeric', columnKey);
    };
    const stringNumericCompare = (order = 'asc', columnKey) => {
      return (item1, item2) => {
        return order === 'asc' ? item1[columnKey].localeCompare(item2[columnKey]) : 0 - item1[columnKey].localeCompare(item2[columnKey]);
      };
    };
    const dateCompare = (order = 'asc', columnKey = 'date') => {
      if (order === 'desc') return sandbox.sorting.getComparator('datedecending', columnKey);
      return sandbox.sorting.getComparator('date', columnKey);
    };
    const publicationCompare = (order = 'asc') => {
      if (order === 'desc') return sandbox.sorting.getComparator('alphadecending', 'publicationName');
      return sandbox.sorting.getComparator('alpha', 'publicationName');
    };
    const eidtionNameCompare = (order = 'asc') => {
      if (order === 'desc') return sandbox.sorting.getComparator('alphadecending', 'editionName');
      return sandbox.sorting.getComparator('alpha', 'editionName');
    };


    function sortItemsByOrder(items, key, order, fncSort) {

      return items.sort(function (a, b) {
        var resultOrder;
        var sortOrder = order === 'asc' ? 1 : -1;

        if (typeof fncSort === 'function') resultOrder = fncSort(a, b, order);
        else if (a[key] < b[key]) resultOrder = 0 - sortOrder;
        else if (a[key] > b[key]) resultOrder = sortOrder;
        else resultOrder = 0;

        return resultOrder;
      });

    }

    function isDateEqual(item, date) {
      return item.date === date;
    }

    function isPublicationEqual(item, date, publicationId) {
      return item.date === date && item.publicationId === publicationId;
    }

    function isImportSelected(item) {
      return item.import === true;
    }

    return AbstractModule.extend({

      folderNwid: null,

      runImportWith: 'tomcat',

      items: [],

      viewState: 'table',

      showLoadingProgress: true,

      sortColumnKey: null,

      sortOrder: null,

      triggerTime: null,

      consolePollingTimeoutHandler: null,

      consolePollingInterval: 1000,

      consoleMessage: '',



      firstTickReceived: function (data) {
        this.reactRoot = ReactDOM.createRoot(this.domElement);
        this.toggleImport = this.toggleImport.bind(this);
        this.toggleSort = this.toggleSort.bind(this);
        this.selectEditionsByDate = this.selectEditionsByDate.bind(this);
        this.selectEditionsByPublication = this.selectEditionsByPublication.bind(this);
        this.getSelectedItems = this.getSelectedItems.bind(this);
        this.onOKButtonClick = this.onOKButtonClick.bind(this);
        this.onCancelButtonClick = this.onCancelButtonClick.bind(this);
        this.onCloseButtonClick = this.onCloseButtonClick.bind(this);
        this.consolePolling = this.consolePolling.bind(this);
        this.startConsolePolling = this.startConsolePolling.bind(this);
        this.stopConsolePolling = this.stopConsolePolling.bind(this);
        this.switchViewState = this.switchViewState.bind(this);
        this.folderNwid = data.model.nwid;
        this.runImportWith = data.config.runImportWith || 'tomcat';
        this.flowStepId = data.config.flowStepId || '0';
      },

      tickUpdate: function (data) {

      },

      tickCommit: function () {
        this.fetchModel();
        this.render();
      },

      getServletUrl: function (command, params) {
        var baseUrl = window.location.origin;
        var genericCommandServlet = '/coretex/servlet/GenericCommandServlet';
        var queryString = ['?command=', command, '&params=', encodeURIComponent(params)].join('');
        return [baseUrl, genericCommandServlet, queryString].join('');
      },

      createEditionItem: function (itemsIndex, edition, date, publicationId, publicationName) {
        return {
          id: itemsIndex,
          import: false,
          date: date,
          publicationName: publicationName,
          publicationId: publicationId,
          editionId: edition.id,
          editionVersion: edition.editionVersion,
          importedEditionVersion: edition.importedEditionVersion,
          editionName: edition.name,
          editionTimeStamp: edition.timeStamp
        };
      },

      dataToItems: function (data) {
        if (!data || !data.available || !data.available.dates) {
          return [];
        }

        var that = this;
        var items = [];
        var itemsIndex = 0;

        data.available.dates.forEach(function (date) {
          date.publications.forEach(function (publication) {
            publication.editions.forEach(function (edition) {
              var editionItem = that.createEditionItem(itemsIndex, edition, date.name, publication.publicationId, publication.name);
              items.push(editionItem);

              itemsIndex = itemsIndex + 1;
            });
          });

        });

        items = items.sort(function (obj1, obj2) {
          var dateCompareResult = dateCompare()(obj1, obj2);
          var publicationCompareResult = publicationCompare()(obj1, obj2);
          var eidtionNameCompareResult = eidtionNameCompare()(obj1, obj2);

          return dateCompareResult || publicationCompareResult || eidtionNameCompareResult;
        }).map(function (item, index) {
          item.id = index;
          return item;
        });
        items.inProcess = data.inProcess;
        return items;
      },

      toggleSort: function (key) {
        if (key === this.sortColumnKey) {

          this.sortOrder = this.sortOrder === 'asc' ? 'desc' :
            this.sortOrder === 'desc' ? null :
              'asc';

          if (this.sortOrder === null) {
            this.sortColumnKey = 'id';
            this.sortOrder = 'asc';
          }

        }
        else if (key !== this.sortColumnKey) {
          this.sortColumnKey = key;
          this.sortOrder = 'asc';
        }
        var sortFunc;
        // if (this.sortColumnKey === 'editionId') sortFunc = numericCompare(this.sortOrder, this.sortColumnKey);
        if (this.sortColumnKey === 'date') {
          sortFunc = (obj1, obj2) => {
            return dateCompare(this.sortOrder)(obj1, obj2) || publicationCompare()(obj1, obj2) || eidtionNameCompare()(obj1, obj2);
          };
        }
        if (this.sortColumnKey === 'publicationName') {
          sortFunc = (obj1, obj2) => {
            return publicationCompare(this.sortOrder)(obj1, obj2) || dateCompare()(obj1, obj2) || eidtionNameCompare()(obj1, obj2);
          };
        }
        // if (this.sortColumnKey === 'importedEditionVersion') sortFunc = stringNumericCompare(this.sortOrder, this.sortColumnKey);
        // if (this.sortColumnKey === 'editionVersion') sortFunc = stringNumericCompare(this.sortOrder, this.sortColumnKey);
        // if (this.sortColumnKey === 'editionTimeStamp') sortFunc = dateCompare(this.sortOrder, this.sortColumnKey);

        sortItemsByOrder(this.items, this.sortColumnKey, this.sortOrder, sortFunc);
        this.render();
      },

      unselectAllItems: function () {
        this.items.forEach(function (item) {
          item.import = false;
        });
      },

      shouldSelect: function (date, publicationId) {
        var items = arguments.length === 2 ? this.items.filter(function (item) { return isPublicationEqual(item, date, publicationId); }) :
          arguments.length === 1 ? this.items.filter(function (item) { return isDateEqual(item, date); }) :
            [];

        var selectedItemsLength = items.filter(isImportSelected).length;

        return !(selectedItemsLength === items.length);
      },

      selectEditionsByDate: function (date) {
        var shouldSelect = this.shouldSelect(date);

        this.items.filter(function (item) { return isDateEqual(item, date); }).forEach(function (item) {
          item.import = shouldSelect;
        });

        this.render();
      },

      selectEditionsByPublication: function (date, publicationId) {
        var shouldSelect = this.shouldSelect(date, publicationId);

        this.items.filter(function (item) { return isPublicationEqual(item, date, publicationId); }).forEach(function (item) {
          item.import = shouldSelect;
        });

        this.render();
      },

      getSelectedItems: function () {
        return this.items.filter(isImportSelected);
      },

      fetchModel: function () {
        var that = this;
        //var data = JSON.parse(dataModel).data;

        //setTimeout(function () {
        //  that.items = that.dataToItems(data);
        //  that.showLoadingProgress = false;
        //  that.render();
        //}, 5000);

        var params = JSON.stringify({ 'folderNWId': this.folderNwid.toString(), 'runImportWith': this.runImportWith, "flowStepId": this.flowStepId.toString()});
        var url = this.getServletUrl('ctpImportSelected', params);

        sandbox.async.ajax({
          url: url,
          type: 'GET',
          dataType: 'json',
          success: function (model) {
            if (model.data) {
              that.items = that.dataToItems(model.data);
              that.showLoadingProgress = model.data.inProcess;
              if (that.showLoadingProgress) {
                that.switchViewState('console');
              }
            }

            that.render();
          }
        });

        this.render();
      },

      triggetInitialImport: function (selectedItems) {
        var triggerName = Date.now().toString();
        var strEditionIds = selectedItems.map(function (item) { return item.editionId; }).join(';');
        var params = JSON.stringify({ 'selected': '1', 'selectedEditions': strEditionIds, 'folderNWId': this.folderNwid.toString(), 'importId': triggerName, 'runImportWith': this.runImportWith, "flowStepId": this.flowStepId.toString() });
        var url = this.getServletUrl('ctpImport', params);
        this.triggerTime = triggerName;
        //console.log(url);
        sandbox.async.ajax({
          url: url,
          type: 'GET',
          dataType: 'json',
          success: function (model) {
            //var data = model.data;
            //that.items = that.dataToItems(data);
            //that.showLoadingProgress = false;
            //that.render();
          }
        });
      },

      consolePolling: function () {
        var that = this;
        //http://localhost:8080/coretex/servlet/GenericCommandServlet?command=ctpImportProgress&&params={"folderNWId":"288230376152067659", 'runImportWith': this.runImportWith}
        var params = JSON.stringify({ 'folderNWId': this.folderNwid.toString(), 'runImportWith': this.runImportWith });
        var url = this.getServletUrl('ctpImportProgress', params);
        //var data = 'dsfdsfdsfds\nImport ID= ' + this.triggerTime;
        //data = data.replace(new RegExp('\n', 'g'), '<br />');
        //this.consoleMessage = data;
        //console.log(url);
        sandbox.async.ajax({
          url: url,// 'http://10.0.0.26:8080/coretex/servlet/GenericCommandServlet?command=ctpImportSelected&&params={"folderNWId":"288230376152067659"}',
          type: 'GET',
          dataType: 'json',
          success: function (model) {
            var data = model.data;
            that.consoleMessage = data;
            if (that.consolePollingTimeoutHandler !== null && data.indexOf('Import ID= ' + that.triggerTime) < 0) {
              that.consolePollingTimeoutHandler = setTimeout(that.consolePolling, that.consolePollingInterval);
            }
            else {
              that.consolePollingTimeoutHandler = null;
            }
            that.render();
          }
        });
        //if (this.consolePollingTimeoutHandler !== null && data.indexOf('Import ID= ' + this.triggerTime) < 0) {
        //  this.consolePollingTimeoutHandler = setTimeout(this.consolePolling, this.consolePollingInterval);
        //}
        //else {
        //  this.consolePollingTimeoutHandler = null;
        //}
        //
        //this.render();
        //sandbox.async.ajax({
        //  url: url,
        //  type: 'GET',
        //  dataType: 'json',
        //  success: function (model) {
        //
        //    that.render();
        //  }
        //});
      },

      startConsolePolling: function () {
        this.consolePollingTimeoutHandler = setTimeout(this.consolePolling, this.consolePollingInterval);
      },

      stopConsolePolling: function () {
        if (this.consolePollingTimeoutHandler !== null) {
          clearTimeout(this.consolePollingTimeoutHandler);
          this.consolePollingTimeoutHandler = null;
        }
      },

      switchViewState: function (viewState) {
        this.viewState = viewState;
        if (viewState === 'console') {
          this.startConsolePolling();
        }
        else {
          this.stopConsolePolling();
        }
        this.render();
      },

      toggleImport: function (rowIndex) {
        this.items[rowIndex].import = !this.items[rowIndex].import;
        this.render();
      },

      onOKButtonClick: function () {
        this.triggetInitialImport(this.getSelectedItems());
        this.switchViewState('console');
      },

      onCancelButtonClick: function () {
        this.reactRoot.unmount();
        window.close();
      },

      onCloseButtonClick: function () {
        this.reactRoot.unmount();
        window.close();
      },

      render: function () {
        var ctpImportSelected = React.createElement(CTPImportSelected, {
          viewState: this.viewState,
          showLoadingProgress: this.showLoadingProgress,
          items: this.items,
          sortColumnKey: this.sortColumnKey,
          sortOrder: this.sortOrder,
          toggleImport: this.toggleImport,
          toggleSort: this.toggleSort,
          selectEditionsByDate: this.selectEditionsByDate,
          selectEditionsByPublication: this.selectEditionsByPublication,
          consoleMessage: this.consoleMessage,
          onOKButtonClick: this.onOKButtonClick,
          onCancelButtonClick: this.onCancelButtonClick,
          onCloseButtonClick: this.onCloseButtonClick
        });

        this.reactRoot.render(ctpImportSelected);
      }

    });

  });