/**
 * @name Skip Device Action
 * @fileOverview Makes request to the server to change the skip-state of a given device: turn on, turn off
 */

define(['sandbox', 'AbstractAction', 'core/services/localization'], function(sandbox, AbstractAction, localization) {
  'use strict';
  return AbstractAction.extend({
    execute: function (objs) {
      const viewInstanceNwId = this.module.nwid;
      const projectorId = this.module.projectorId;
      const step = objs[0];
      if (step.isSkipOn) {
        sandbox.request.toggleSkipDevice(viewInstanceNwId, this.nwid, step.nwid, step.type, projectorId, {state: null});
      }
      else {
        const msg = localization.translate("You are about to mark this step for skipping. Future events will be auto-routed to the next flow step(s). Continue?");
        sandbox.dialog.confirm(msg, 'Confirmation').then(function () {
          sandbox.request.toggleSkipDevice(viewInstanceNwId, this.nwid, step.nwid, step.type, projectorId, {state: null});
        }.bind(this));
      }
    }
  });
});