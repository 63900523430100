import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { fromServerDate } from 'core/dates';
import sandbox from 'sandbox';
import ReactDataGrid from 'widgets/ReactDataGrid/src/ReactDataGrid';
import IndicationCounter from '../BirdEyeLayers/src/indicationCounter';
import ProgressBar from 'components/common/progress/ProgressBar';
import { translate } from 'core/services/localization';

const { Table, Column } = ReactDataGrid;

const headerHeight = 31;
const rowHeight = 29;

const counterStyle = {
  height: 26,
  minWidth: 40,
  paddingRight: '10px',
  verticalAlign: 'bottom',
  display: 'table-cell'
};
const counterImageStyle = {
  paddingRight: 2,
  verticalAlign: 'bottom'
};
const counterTextStyle = {
  height: 26,
  verticalAlign: 'middle'
};

const editIcon = sandbox.icons.getGeneralIcon(null, 'edit');

function shouldCellUpdate(column, nextProps, props) {
  let result = false;

  if (column.key === 'approvalIndication') {
    result = nextProps.approvers.length !== props.approvers.length ||
      nextProps.approvers.some((a, idx) => a.name !== props.approvers[idx].name || a.icon !== props.approvers[idx].icon);
  } else {
    result = nextProps.columnData !== props.columnData || nextProps.backgroundColor !== props.backgroundColor ||
      nextProps.title !== props.title || nextProps.icon !== props.icon || nextProps.name !== props.name;
  }

  return result;
}

export default function View({
  tableName,
  preferences,
  columns,
  columnKey,
  rows,
  selectedRows,
  viewType,
  rootType,
  sortColumnKey,
  sortOrder,
  allowDrop,
  counters,
  filter,
  deadline,
  handleSelectionChanged,
  handleDoubleClickRow,
  handleDrop,
  handleColumnClick,
  handleConextMenu,
  handleColumnFilter,
  handleColumnOrder,
  handleColumnResize,
  handleTableViewKeyDown,
  handleChangeDeadlineAction }) {

  const tableRef = useRef(null);

  useEffect(() => {
    if (typeof filter !== 'undefined' && typeof tableRef.current !== 'undefined' && tableRef.current !== null) {
      tableRef.current.unselectAllRows();
    }
  }, [filter]);

  const handleRowSelection = selectedRows => {
    handleSelectionChanged(selectedRows);
  };

  const renderColumn = () => {
    return columnDefinition => {
      const column = columnDefinition(rows, sortColumnKey, sortOrder);
      const { visible, width, ...restColumnDefinition } = column;
      const columnsPref = preferences.columns[column.columnKey] || {};

      return <Column
        key={column.columnKey}
        visible={columnsPref.visible === false ? false : true}
        width={isNaN(columnsPref.width) ? width : columnsPref.width}
        onClick={(columnIndex, columnKey) => handleColumnClick(columnKey)}
        shouldCellUpdate={(nextProps, props) => shouldCellUpdate(column, nextProps, props)}
        {...restColumnDefinition}
      />;
    }
  };

  const handleColumnsOrder = (orderedColumnKeys, oldIndex, newIndex) => {
    handleColumnOrder(orderedColumnKeys, oldIndex, newIndex);
  };

  const handleRowContextMenu = (rowIndex, rowContent, selectedRows, ev) => {
    handleConextMenu(rowContent, selectedRows, ev);
  };

  const renderColumns = () => columns.map(renderColumn());

  const renderCounter = (showFilteredCount = false, showIcon = true) => {
    return (counter, index) => {
      const counterContent = counter.filteredCount < counter.count && showFilteredCount ?
        [counter.filteredCount, '/', counter.count].join(' ') :
        counter.count;
      const icon = <img src={counter.imgSrc} style={counterImageStyle} />;
      const text = <span style={counterTextStyle}>{counter.title} : </span>;
      const content = showIcon ? icon : text;

      return <span key={index} style={counterStyle} title={counter.title}>
        {content}
        <span style={counterTextStyle}>
          {counterContent}
        </span>
      </span>;
    }
  };

  const renderCounters = () => counters.filter(counter => counter.name !== 'total').map(renderCounter(false));

  const renderTotalCounter = () => counters.filter(counter => counter.name === 'total').map(renderCounter(true, false));

  const handleChangeDeadline = () => {
    if (handleChangeDeadlineAction) {
      handleChangeDeadlineAction();
    }
  };

  // var colDef = columnDefinition(rows, viewType, sortColumnKey, sortOrder);
  // if (typeof colDef === 'undefined') return null;

  if (typeof columns === 'undefined') {
    return null;
  }

  const finishedCounter = counters.find(c => c.name === 'finished');
  const rejectCounter = counters.find(c => c.name === 'reject');
  const errorCounter = counters.find(c => c.name === 'error');
  const waitCounter = counters.find(c => c.name === 'wait');
  const holdCounter = counters.find(c => c.name === 'hold');
  const deadlineText = sandbox.localization.toLocaleShortDateTime(fromServerDate(deadline));

  return <div className='structures-table-view' tabIndex='0' onKeyDown={e => handleTableViewKeyDown(e)}>
    <div className='panel'>
      <div className='title'>
        <div className='table-name'>
          {tableName}
        </div>
        <div className='progress-bar'>
          <ProgressBar min={0} max={rows.length} value={finishedCounter.filteredCount} style={{ height: 17 }} >
            {finishedCounter.filteredCount} / {rows.length}
          </ProgressBar>
        </div>
        {
          viewType === 'plates' && rootType === 'productionrun' ? <div className='deadline'>
            <div className='deadline-date'>{deadlineText}</div>
            {
              !!handleChangeDeadlineAction ? <span className='edit-deadline'>
                <img className='edit-deadline-icon' src={editIcon} title={translate('Change deadline')} onClick={handleChangeDeadline} />
              </span> : undefined
            }
          </div> : undefined
        }
        <div className='indicators'>
          <IndicationCounter icon='reject' count={rejectCounter.count} title={rejectCounter.title} />
          <IndicationCounter icon='error' count={errorCounter.count} title={errorCounter.title} />
          <IndicationCounter icon='waitingForApproval' count={waitCounter.count} title={waitCounter.title} />
          <IndicationCounter module='Thumbnail' icon='holdCounter' count={holdCounter.count} title={holdCounter.title} />
        </div>
      </div>
      <div className='container'>
        <Table ref={tableRef}
          columnKey={columnKey}
          headerHeight={headerHeight}
          rowHeight={rowHeight}
          rows={rows || []}
          selectableRows={true}
          multiSelect={true}
          selectedRow={selectedRows}
          onRowClick={(rowIndex, rowContent) => { }}
          fixed={false}
          autoHeight={false}
          virtualScroll={true}
          showSpinner={false}
          spinnerMessage='Loading....'
          getRowClassName={rowIndex => []}
          onSelect={handleRowSelection}
          onDoubleClickRow={(rowIndex, rowContent, ev) => handleDoubleClickRow(rowIndex, rowContent, ev)}
          onRowContextMenu={handleRowContextMenu}
          onDrop={allowDrop ? (rowIndex, rowContent, ev) => handleDrop(rowIndex, rowContent, ev) : undefined}
          onColumnsFilter={(columnKey, isVisible, columns) => handleColumnFilter(columnKey, isVisible, columns)}
          onColumnsOrder={handleColumnsOrder}
          onColumnResize={() => handleColumnResize(columns)}
        >
          {renderColumns()}
        </Table>
      </div>
    </div>
  </div>;
}

View.propTypes = {
  tableName: PropTypes.string,
  preferences: PropTypes.any,
  columnKey: PropTypes.string,
  columns: PropTypes.array,
  rows: PropTypes.any,
  selectedRows: PropTypes.any,
  viewType: PropTypes.string,
  sortColumnKey: PropTypes.string,
  sortOrder: PropTypes.string,
  allowDrop: PropTypes.bool,
  counters: PropTypes.any,
  filter: PropTypes.any,
  handleSelectionChanged: PropTypes.func,
  handleDoubleClickRow: PropTypes.func,
  handleDrop: PropTypes.func,
  handleColumnClick: PropTypes.func,
  handleColumnFilter: PropTypes.func,
  handleColumnOrder: PropTypes.func,
  handleColumnResize: PropTypes.func,
  handleConextMenu: PropTypes.func
};