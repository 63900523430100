import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utilities/classNames';

const DialogTitleBar = forwardRef(({ title, className, style, onClose, onDoubleClick }, ref) => {
  return (
    <div
      ref={ref}
      className={classNames('crtx-dialog-title-bar', className)}
      style={style}
      onDoubleClick={onDoubleClick}
    >
      <div className='crtx-dialog-title'>{title}</div>
      {onClose ?
        <button className='crtx-dialog-close-button' onClick={onClose}>
          <span className='material-icons md-18'>close</span>
        </button> : null
      }
    </div>
  );
});

DialogTitleBar.propTypes = {
  title: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
  onClose: PropTypes.func,
  onDoubleClick: PropTypes.func,
};

export default DialogTitleBar;