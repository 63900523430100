/**
 * Created by elad on 12/9/2015.
 */

define(['sandbox', './ServerAction'], function(sandbox, ServerAction) {
  'use strict';

  return ServerAction.extend({

    isApplicable: function () {
      var obj = this.module.selected;
      if(typeof obj === "undefined" || obj.length === 0) {
        return false;
      }

      for (var i = 0; i < obj.length; i++) {
        if (!obj[i].ignore) {
          return false;
        }
      }
      return true;
    }
  });
});
