/**
 * @name
 * @fileOverview
 * @author sergey & Elad
 */
define(['ember', 'text!../templates/date.hbs'],
  function (Ember, DateTemplate) {
    'use strict';
    var i = 0;
    return Ember.View.extend({

      init: function() {
        this._super();
        this.addObserver("issueDate", this ,this.dateChangeHandler);
      },
      template: Ember.Handlebars.compile(DateTemplate),

      dateChangeHandler: function (){

      }
    });
  });