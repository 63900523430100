/**
 * @name OpenModuleAction
 * @fileOverview This action is created for the sake of
 * permission management, meaning whether the certain module is allowed to
 * open other modules.
 * @author sergey
 */
import AbstractAction from 'AbstractAction';
import { startModule } from 'core/managers/module';
import actionUtils from 'actions/actionUtils';

export default AbstractAction.extend({
  isApplicable: function () {
    if (this.module.viewModel === null) {
      return false;
    }
    if (this.module.viewModel.managers.length === 2) {
      return false;
    }
    return true;
  },
  execute: function (args) {
    const obj = (arguments.length > 0) ? args[0] : void 0;
    const viewDefName = actionUtils.deduceViewDefinitionName(obj, this.parameters);
    const viewInfo = actionUtils.findViewInfoForAction(this.nwid, viewDefName);
    const label = this.parameters.label;

    startModule(viewInfo.nwid, this.module.id, {
      ...viewInfo,
      label,
      rootId: obj ? obj.nwid : this.module.viewSettings.rootId,
      rootType: obj ? obj.type : this.module.viewSettings.rootType
    });
  }
});
