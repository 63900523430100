import React from "react";
import sandbox from "sandbox";
import { Column, headers, cells } from "widgets/ReactDataGrid/";
import { COMPARE_TYPE } from 'core/comparators';

const { SortableHeader } = headers;
const { Text, Thumbnail, IconCell, CheckboxCell } = cells;
const { translate } = sandbox.localization;
const isUndefined = o => typeof o === "undefined";
const isNull = o => o === null;
const isString = o => typeof o === "string";

const sitesColumnDefinition = (sites, items, legend) => {
  return sites.map(({ Code, Name, nwid }) => {
    return {
      key: nwid,
      width: 168,
      align: "left",
      autoWidth: false,
      title: Name,
      cell: Text,
      cellDataGetter: (rowIndex, key) => {
        const transmitionStatus = items[rowIndex].transmissionStatuses[
          nwid
        ] || { status: "waiting", time: "", localizedTime: "" };
        const plannedSite = items[rowIndex].plannedSites[nwid];
        return {
          columnData:
            plannedSite !== true ? "" : transmitionStatus.localizedTime,
          backgroundColor:
            plannedSite !== true
              ? legend.notPlanned
              : legend[transmitionStatus.status]
        };
      },
      shouldCellUpdate: (nextProps, props) =>
        nextProps.columnData !== props.columnData ||
        nextProps.backgroundColor !== props.backgroundColor
    };
  });
};

export const singlePDFColumnsDefinition = (sites, items, legend) =>
  [
    {
      key: "pageContentNwid",
      width: 90,
      align: "center",
      autoWidth: false,
      resizable: false,
      title: translate("Thumbnail"),
      cell: Thumbnail,
      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: items[rowIndex].pageContentNwid,
          iconUID: items[rowIndex].iconUID
        };
      },
      shouldCellUpdate: (nextProps, props) =>
        nextProps.columnData !== props.columnData ||
        nextProps.iconUID !== props.iconUID
    },
    {
      key: "advertiser",
      width: 150,
      align: "left",
      autoWidth: false,
      title: translate("Advertiser"),
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cell: Text,
      cellDataGetter: (rowIndex, key) => {
        const disabled = items[rowIndex].deprecated;
        const textColor = items[rowIndex].rejected ? '#ff0000' : undefined;
        return {
          columnData: items[rowIndex].advertiser,
          disabled,
          textColor
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.disabled !== props.disabled || nextProps.textColor !== props.textColor
    },
    {
      key: "publication",
      width: 200,
      align: "left",
      autoWidth: false,
      title: translate("Publication"),
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cell: Text,
      cellDataGetter: (rowIndex, key) => {
        const disabled = items[rowIndex].deprecated;
        const textColor = items[rowIndex].rejected ? '#ff0000' : undefined;
        return {
          columnData: items[rowIndex].publication,
          disabled,
          textColor
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.disabled !== props.disabled || nextProps.textColor !== props.textColor
    },
    {
      key: 'issueDate',
      width: 94,
      align: 'left',
      autoWidth: false,
      title: translate('Issue Date'),
      sortType: COMPARE_TYPE.DATES,
      cell: Text,
      valueGetter: item => item.issueDate.time,
      cellDataGetter: (rowIndex, key) => {
        const disabled = items[rowIndex].deprecated;
        const textColor = items[rowIndex].rejected ? '#ff0000' : undefined;
        return {
          columnData: items[rowIndex].issueDate.localizedTime,
          disabled,
          textColor
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.disabled !== props.disabled || nextProps.textColor !== props.textColor
    },
    {
      key: "tmiNumber",
      width: 113,
      align: "left",
      autoWidth: false,
      title: translate("CW Number"),
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cell: Text,
      cellDataGetter: (rowIndex, key) => {
        const disabled = items[rowIndex].deprecated;
        const textColor = items[rowIndex].rejected ? '#ff0000' : undefined;
        return {
          columnData: items[rowIndex].tmiNumber,
          disabled,
          textColor
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.disabled !== props.disabled || nextProps.textColor !== props.textColor
    },
    {
      key: 'adSpecification',
      width: 200,
      align: 'left',
      autoWidth: false,
      title: translate('Ad Specification'),
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cell: Text,
      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: items[rowIndex].adSpecification
        };
      }
    },
    {
      key: "inputFileName",
      width: 125,
      align: "left",
      autoWidth: false,
      title: translate("Input File Name"),
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cell: Text,
      cellDataGetter: (rowIndex, key) => {
        const disabled = items[rowIndex].deprecated;
        const textColor = items[rowIndex].rejected ? '#ff0000' : undefined;
        return {
          columnData: items[rowIndex].inputFileName,
          disabled,
          textColor
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.disabled !== props.disabled || nextProps.textColor !== props.textColor
    },
    {
      key: "inputTime",
      width: 144,
      align: "left",
      autoWidth: false,
      title: translate("Input Time"),
      sortType: COMPARE_TYPE.DATES,
      cell: Text,
      valueGetter: item => item.inputTime.time,
      cellDataGetter: (rowIndex, key) => {
        const disabled = items[rowIndex].deprecated;
        const textColor = items[rowIndex].rejected ? '#ff0000' : undefined;
        return {
          columnData: items[rowIndex].inputTime.localizedTime,
          disabled,
          textColor
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.disabled !== props.disabled || nextProps.textColor !== props.textColor
    },
    {
      key: "materialsId",
      width: 150,
      align: "left",
      autoWidth: false,
      title: translate("Materials Id"),
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cell: Text,
      cellDataGetter: (rowIndex, key) => {
        const disabled = items[rowIndex].deprecated;
        const textColor = items[rowIndex].rejected ? '#ff0000' : undefined;
        return {
          columnData: items[rowIndex].materialsId,
          disabled,
          textColor
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.disabled !== props.disabled || nextProps.textColor !== props.textColor
    },
    {
      key: "adType",
      width: 150,
      align: "left",
      autoWidth: false,
      title: translate("AD State"),
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cell: Text,
      cellDataGetter: (rowIndex, key) => {
        const disabled = items[rowIndex].deprecated;
        const textColor = items[rowIndex].rejected ? '#ff0000' : undefined;
        return {
          columnData: items[rowIndex].adType,
          disabled,
          textColor
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.disabled !== props.disabled || nextProps.textColor !== props.textColor
    },
    {
      key: "Pickup",
      width: 55,
      align: "center",
      autoWidth: false,
      title: translate("Pickup"),
      cell: CheckboxCell,
      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: items[rowIndex].pickup,
          disabled: true,
          onChange: () => { }
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    },
    {
      key: "Stitched",
      width: 55,
      align: "center",
      autoWidth: false,
      title: translate("Stitched"),
      cell: CheckboxCell,
      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: items[rowIndex].stitched,
          disabled: true,
          onChange: () => { }
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    },
    {
      key: "BlockMail",
      width: 100,
      align: "center",
      autoWidth: false,
      title: translate("Do Not Use"),
      cell: CheckboxCell,
      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: items[rowIndex].blockMail,
          disabled: true,
          onChange: () => { }
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
    },
    {
      key: "step",
      width: 55,
      align: "center",
      autoWidth: false,
      title: translate("Step"),
      cell: IconCell,
      cellDataGetter: (rowIndex, key) => {
        return {
          icon: items[rowIndex].flowStepIconName,
          iconStyle: { verticalAlign: "middle" }
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.icon !== props.icon
    },
    {
      key: "status",
      width: 66,
      align: "center",
      autoWidth: false,
      title: translate("Status"),
      cell: IconCell,
      cellDataGetter: (rowIndex, key) => {
        return {
          icon: items[rowIndex].statusIconName,
          iconStyle: { verticalAlign: "middle" }
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.icon !== props.icon
    },
    {
      key: "version",
      width: 73,
      align: "left",
      autoWidth: false,
      title: translate("Version"),
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cell: Text,
      cellDataGetter: (rowIndex, key) => {
        const disabled = items[rowIndex].deprecated;
        const textColor = items[rowIndex].rejected ? '#ff0000' : undefined;
        return {
          columnData: items[rowIndex].version,
          disabled,
          textColor
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.disabled !== props.disabled || nextProps.textColor !== props.textColor
    }
  ].concat(sitesColumnDefinition(sites, items, legend));

export const stichedPDFSColumnsDefinition = (sites, items, legend) => [
  {
    key: "advertiser",
    width: 150,
    align: "left",
    autoWidth: false,
    title: translate("Advertiser"),
    sortType: COMPARE_TYPE.CASE_INSENSITIVE,
    cell: Text,
    cellDataGetter: (rowIndex, key) => {
      return {
        columnData: items[rowIndex].advertiser
      };
    }
  },
  {
    key: "publication",
    width: 200,
    align: "left",
    autoWidth: false,
    title: translate("Publication"),
    sortType: COMPARE_TYPE.CASE_INSENSITIVE,
    cell: Text,
    cellDataGetter: (rowIndex, key) => {
      return {
        columnData: items[rowIndex].publication
      };
    }
  },
  {
    key: 'issueDate',
    width: 94,
    align: 'left',
    autoWidth: false,
    title: translate('Issue Date'),
    sortType: COMPARE_TYPE.DATES,
    cell: Text,
    valueGetter: item => item.issueDate.time,
    cellDataGetter: (rowIndex, key) => {
      const disabled = items[rowIndex].deprecated;
      const textColor = items[rowIndex].rejected ? '#ff0000' : undefined;
      return {
        columnData: items[rowIndex].issueDate.localizedTime,
        disabled,
        textColor
      };
    },
    shouldCellUpdate: (nextProps, props) => nextProps.disabled !== props.disabled || nextProps.textColor !== props.textColor
  },
  {
    key: "pages",
    width: 150,
    align: "left",
    autoWidth: false,
    title: translate("Stitched PDF Content"),
    sortType: COMPARE_TYPE.CASE_INSENSITIVE,
    cell: Text,
    valueGetter: item => item.title,
    cellDataGetter: (rowIndex, key) => {
      return {
        columnData: items[rowIndex].pages,
        title: items[rowIndex].title,
        backgroundColor: items[rowIndex].hasDeprecatedSources ? 'lightpink' : undefined
      };
    }
  },
  {
    key: "name",
    width: 150,
    align: "left",
    autoWidth: false,
    title: translate("Stiched PDF Name"),
    sortType: COMPARE_TYPE.CASE_INSENSITIVE,
    cell: Text,
    cellDataGetter: (rowIndex, key) => {
      return {
        columnData: items[rowIndex].name
      };
    }
  },
  {
    key: "version",
    width: 73,
    align: "left",
    autoWidth: false,
    title: translate("Version"),
    sortType: COMPARE_TYPE.CASE_INSENSITIVE,
    cell: Text,
    cellDataGetter: (rowIndex, key) => {
      return {
        columnData: items[rowIndex].version,
      };
    },
  },
  {
    key: "adType",
    width: 150,
    align: "left",
    autoWidth: false,
    title: translate("AD State"),
    sortType: COMPARE_TYPE.CASE_INSENSITIVE,
    cell: Text,
    cellDataGetter: (rowIndex, key) => {
      const disabled = items[rowIndex].deprecated;
      const textColor = items[rowIndex].rejected ? '#ff0000' : undefined;
      return {
        columnData: items[rowIndex].adType,
        disabled,
        textColor
      };
    },
    shouldCellUpdate: (nextProps, props) => nextProps.disabled !== props.disabled || nextProps.textColor !== props.textColor
  },
  {
    key: "BlockMail",
    width: 100,
    align: "center",
    autoWidth: false,
    title: translate("Block Mail"),
    cell: CheckboxCell,
    cellDataGetter: (rowIndex, key) => {
      return {
        columnData: items[rowIndex].blockMail,
        disabled: true,
        onChange: () => { }
      };
    },
    shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData
  },
  {
    key: "stitchStatusTime",
    width: 200,
    align: "left",
    autoWidth: false,
    title: translate("Stitch Complete"),
    sortType: COMPARE_TYPE.DATES,
    cell: Text,
    valueGetter: item => item.stitchStatusTime.time,
    cellDataGetter: (rowIndex, key) => {
      return {
        columnData: items[rowIndex].stitchStatusTime.localizedTime,
        backgroundColor: legend[items[rowIndex].stitchStatusTime.status]
      };
    },
    shouldCellUpdate: (nextProps, props) =>
      nextProps.columnData !== props.columnData ||
      nextProps.backgroundColor !== props.backgroundColor
  },
  {
    key: "archiveStatusTime",
    width: 200,
    align: "left",
    autoWidth: false,
    title: translate("Archive Complete"),
    sortType: COMPARE_TYPE.DATES,
    cell: Text,
    valueGetter: item => item.archiveStatusTime.time,
    cellDataGetter: (rowIndex, key) => {
      return {
        columnData: items[rowIndex].archiveStatusTime.localizedTime,
        backgroundColor: legend[items[rowIndex].archiveStatusTime.status]
      };
    },
    shouldCellUpdate: (nextProps, props) =>
      nextProps.columnData !== props.columnData ||
      nextProps.backgroundColor !== props.backgroundColor
  },
  {
    key: "mailStatusTime",
    width: 200,
    align: "left",
    autoWidth: false,
    title: translate("Mail Complete"),
    sortType: COMPARE_TYPE.DATES,
    cell: Text,
    valueGetter: item => item.mailStatusTime.time,
    cellDataGetter: (rowIndex, key) => {
      return {
        columnData: items[rowIndex].mailStatusTime.localizedTime,
        backgroundColor: legend[items[rowIndex].mailStatusTime.status]
      };
    },
    shouldCellUpdate: (nextProps, props) =>
      nextProps.columnData !== props.columnData ||
      nextProps.backgroundColor !== props.backgroundColor
  }
];

export const renderColumn = (sortBy, handleColumnClick) => column => {
  const sort =
    !isUndefined(column.sortType) && sortBy.prop === column.key
      ? sortBy.direction
      : undefined;
  const handleClick = !isUndefined(column.sortType)
    ? handleColumnClick(column.sortType)
    : undefined;

  return (
    <Column
      key={column.key}
      width={column.width}
      align={column.align}
      autoWidth={column.autoWidth}
      resizable={column.resizable}
      header={props => (
        <SortableHeader {...props} sort={sort}>
          {column.title}
        </SortableHeader>
      )}
      onClick={handleClick}
      cell={column.cell}
      cellDataGetter={column.cellDataGetter}
      shouldCellUpdate={column.shouldCellUpdate}
    />
  );
};

export default (multiple, items, sites, columnsToSortBy, legend, handleColumnClick) => {
  const columnsDefinition =
    multiple === "0"
      ? singlePDFColumnsDefinition
      : stichedPDFSColumnsDefinition;
  return columnsDefinition(sites, items, legend); //.map(renderColumn(sortBy, handleColumnClick));
};
