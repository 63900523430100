import React from 'react';

export default class extends React.Component {
  render() {
    var {
      style,
      version,
      ...props
    } = this.props;

    if (version <= 1) {
      return <div></div>;
    }

    return <div className="Text" style={style}>{this.props.children}</div>

  }
}