import {isPlainObject} from 'base/jsUtils';
import {coretexPath, appendParameters} from 'utilities/url';

function translate(key, params) {
  // load localization module dynamically to avoid circular dependency
  const localization = require('core/services/localization');

  return localization.translate(key, ...params);
}

async function getResponseData(response) {
  let data;

  const contentType = response.headers.get('content-type') || '';
  if (response.ok) {
    if (contentType) {
      if (contentType.includes('application/json')) {
        data = response.json();
      } else if (contentType.includes('text/plain') || contentType.includes('text/html')) {
        data = response.text();
      } else {
        data = Promise.reject({
          status: '501',
          statusText: 'Invalid response header'
        });
      }
    }
  } else {
    let errorMessage = '';
    if (contentType.includes('application/json')) {
      const error = await response.json();
      errorMessage = translate(error.message, error.params || []);
    }

    data = Promise.reject({
      status: response.status,
      statusText: errorMessage || response.statusText
    });
  }

  return data;
}

async function invokeRest(viewOrActionNwid, method, relativeUrl, data = {}, options = {}) {
  if (!isPlainObject(data)) {
    return Promise.reject({status: 'Invalid parameter', statusText: "The 'data' parameter should be a Plain Object"});
  }

  let fullUrl = coretexPath('rest', options.version || 'v2', relativeUrl);
  const init = {
    method,
    headers: {
      'Content-Type': 'application/json',
      viewOrActionNwid,
      ...options.headers
    },
  };

  if (method === 'GET') {
    fullUrl = appendParameters(fullUrl, data);
  } else {
    init.body = JSON.stringify(data)
  }

  const response = await fetch(fullUrl, init);

  return getResponseData(response);
}

export async function restGet(viewOrActionNwid = '', relativeUrl = '', data = {}, options = {}) {
  return invokeRest(viewOrActionNwid, 'GET', relativeUrl, data, options)
}

export async function restPost(viewOrActionNwid = '', relativeUrl = '', data = {}, options = {}) {
  return invokeRest(viewOrActionNwid, 'POST', relativeUrl, data, options)
}

export async function restPut(viewOrActionNwid = '', relativeUrl = '', data = {}, options = {}) {
  return invokeRest(viewOrActionNwid, 'PUT', relativeUrl, data, options)
}

export async function restDelete(viewOrActionNwid = '', relativeUrl = '', data = {}, options = {}) {
  return invokeRest(viewOrActionNwid, 'DELETE', relativeUrl, data, options)
}
