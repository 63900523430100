/**
 * @name Navigator
 * @file Main panel navigation bar
 *
 * @author Boris
 * @since: 2017-09-07
 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import viewManager from 'core/managers/views';
import appUtils from 'core/appUtils';
import base from 'base';
import { openModuleHelp } from 'core/services/helpService';
import NavigatorComponent from './NavigatorComponent';

const TYPES_WITH_OPTIONS = ['page', 'page/separation', 'form', 'form/separation'];
const MODULE_NAVIGATOR_CLASS = 'crtx-module-navigator';

function canHaveOntions(rootType) {
  return TYPES_WITH_OPTIONS.indexOf(rootType) >= 0;
}

function findViewLinkByNwid(selectedContent) {
  if (!selectedContent) {
    return;
  }

  let viewLink = null;

  const { viewLinks, viewNwid } = selectedContent;
  const viewInfo = viewManager.getViewInfo(viewNwid);
  if (viewLinks.includes(viewNwid)) {
    viewLink = viewInfo;
  } else {
    for (let link of viewLinks) {
      const linkInfo = viewManager.getViewInfo(link);
      if (linkInfo.viewDefinitionName === viewInfo.viewDefinitionName) {
        viewLink = linkInfo;
        break;
      }
    }
  }

  if (viewLink) {
    const { nwid, type, name, label } = selectedContent;

    viewLink.rootId = nwid;
    viewLink.rootType = type; // type: page, form, etc.
    viewLink.rootName = name || label;
    viewLink.target = 'main'; // open in the same window
  }

  return viewLink;
}

module.exports = class {
  constructor(moduleDomElement, settings) {
    this.navigationOptions = [];
    this.selectedIndex = -1;
    this.navigationDisabled = false;

    this.breadcrumbs = [];
    this.viewLinksGroups = [];
    this.win = moduleDomElement.ownerDocument.defaultView;

    this.updateSettings(settings);

    const parentElement = moduleDomElement.parentNode;
    this.domElement = base.dom.find(`.${MODULE_NAVIGATOR_CLASS}`, parentElement)[0];
    if (!this.domElement) {
      const windowRef = this.win;
      this.domElement = windowRef.document.createElement('div');
      this.domElement.classList.add(MODULE_NAVIGATOR_CLASS);
      parentElement.insertBefore(this.domElement, moduleDomElement)
    }

    this.reactRoot = createRoot(this.domElement);
  }

  updateSettings(settings) {
    this.rootType = settings.rootType;
    this.moduleName = settings.moduleName;
    this.moduleId = settings.moduleId;

    if (!canHaveOntions(this.rootType)) {
      this.navigationOptions = [];
    }
  }

  filterChanged(filters = []) {
    const moduleInstance = require('core/managers/module').getModuleById(this.moduleId);
    if (typeof moduleInstance.navigatorFilterChanged === 'function') {
      filters = filters.map(filter => ({ name: filter.name, active: filter.active }));
      moduleInstance.navigatorFilterChanged(filters);
    }
  }

  /**
   * Sets selected index in the drop-down list
   * @param {int} index - zero-based index of the selected item
   */
  setSelectedIndex(index) {
    if (this.selectedIndex === index) {
      return;
    }

    this.selectedIndex = index;

    this.render();
  }

  /**
   * Sets breadcrumbs
   * @param {array} breadcrumbs - array of items with name property
   */
  setBreadcrumbs(breadcrumbs) {
    this.breadcrumbs = breadcrumbs;

    this.render();
  }

  /**
   * Sets View Links
   * @param {array} viewLinks - list of the links to views related to the given view instance
   * @param {string} nwid - the given view instance nwid
   *
   */
  setViewLinks(viewLinks, nwid) {
    const moduleInstance = require('core/managers/module').getModuleById(this.moduleId);
    const moduleInstanceStartParameters = moduleInstance ? moduleInstance.startParameters || {} : {};
    const defaultViewLinks = moduleInstanceStartParameters.enableNavigation === false ? [] :
      viewLinks.filter(viewLink => viewLink.triggers && viewLink.triggers.indexOf('toolbar') >= 0);
    this.viewLinksGroups = [];
    if (defaultViewLinks.length > 1) {
      const viewLinks = appUtils.sortByOrderAndLabel(defaultViewLinks);
      this.viewLinksGroups = viewLinks.reduce((groups, viewLink) => {
        const groupName = viewLink.linksGroup || viewLink.label;
        const groupIndex = groups.findIndex(group => group.label === groupName);
        if (groupIndex < 0) {
          groups.push({ label: groupName, icon: groupName, links: [viewLink] });
        } else {
          groups[groupIndex].links.push(viewLink);
        }

        return groups;
      }, []);
    }

    this.render();
  }

  setControls(data) {
    this.navigationOptions = [];
    this.filters = data.filters || [];
    if (Array.isArray(data.items) && canHaveOntions(this.rootType)) {
      this.navigationOptions = data.items;
    }

    this.render();
  }

  doesItemMatchFilter = (item, activeFilters) => activeFilters.every(af => item.filterData[af.name]);

  findMatchingItemIndex = forward => {
    const activeFilters = this.filters.filter(filter => filter.active);

    const optionsCount = this.navigationOptions.length;
    const startIndex = forward ? this.selectedIndex + 1 : this.selectedIndex - 1;
    let currentIndex = this.selectedIndex;

    for (let i = 0; i < optionsCount; i++) {
      currentIndex = forward ? startIndex + i : startIndex - i;
      currentIndex = (optionsCount + currentIndex) % optionsCount;
      const currentItem = this.navigationOptions[currentIndex];
      if (this.doesItemMatchFilter(currentItem, activeFilters)) {
        break;
      }
    }

    return currentIndex;
  }

  handlePreviousClick = (event) => {
    if (this.navigationDisabled) {
      return;
    }

    const index = this.findMatchingItemIndex(false);

    if (index !== this.selectedIndex) {
      this.navigationSelectionChanged(index);
    }
  };

  handleNextClick = (event) => {
    if (this.navigationDisabled) {
      return;
    }

    const index = this.findMatchingItemIndex(true);

    if (index !== this.selectedIndex) {
      this.navigationSelectionChanged(index);
    }
  };

  navigationSelectionChanged(index) {
    if (this.navigationDisabled) {
      return;
    }

    const viewLink = findViewLinkByNwid(this.navigationOptions[index]);
    if (viewLink) {
      this.setSelectedIndex(index);

      viewLink.win = this.win;
      const moduleInstance = require('core/managers/module').getModuleById(this.moduleId);
      if (moduleInstance && moduleInstance.allowReplaceProjector) {
        this.replaceModuleProjector(this.moduleId, viewLink);
      } else {
        this.startModule(viewLink);
      }
    }
  }

  diselectAllViewsViews() {
    this.viewLinksGroups.forEach((group) => {
      group.selected = false;
    });
  }

  handleHelpClick = () => {
    openModuleHelp(this.moduleName, this.rootType, this.win);
  };

  handleViewLinkClick = (viewLink, event) => {
    this.startModule(viewLink, event);
  };

  handleNavigationOptionSelect = index => {
    if (index !== this.selectedIndex) {
      this.navigationSelectionChanged(index);
    }
  };

  startModule(viewLink, event) {
    if (!viewLink) {
      return;
    }

    this.navigationDisabled = true;
    this.render();

    const module = require('core/managers/module').getModuleById(this.moduleId) || {};
    const parentModuleId = module.parentModule ? module.parentModule.id : null;
    const startParameters = {
      ...viewLink,
      win: this.win,
      shouldChangeDefaultView: true
    };

    require('core/managers/module').startModule(viewLink.nwid, parentModuleId, startParameters, event)
      .then(result => {
        this.navigationDisabled = false;
        this.render();
      })
      .catch(() => {
        this.navigationDisabled = false;
        this.render();
      });
  }

  replaceModuleProjector(moduleId, viewLink) {
    require('core/managers/module').replaceModuleProjector(moduleId, viewLink);
  }

  handleFilterClick = (e, checked, name) => {
    this.filters = this.filters.map(filter => filter.name === name ? { ...filter, active: !checked } : filter);
    this.filterChanged(this.filters);
    this.render();
  };

  render() {
    if (!this.reactRoot) {
      return null;
    }

    this.reactRoot.render(
      <NavigatorComponent
        breadcrumbs={this.breadcrumbs}
        viewLinksGroups={this.viewLinksGroups}
        navigationOptions={this.navigationOptions}
        selectedNavigationIndex={this.selectedIndex}
        navigationDisabled={this.navigationDisabled}
        onHelpClick={this.handleHelpClick}
        onViewLinkClick={this.handleViewLinkClick}
        onPreviousClick={this.handlePreviousClick}
        onNextClick={this.handleNextClick}
        onNavigationOptionSelect={this.handleNavigationOptionSelect}
        onFilterClick={this.handleFilterClick}
        filters={this.filters}
      />);
  }

  destroy() {
    if (this.domElement) {
      this.reactRoot.unmount();
      this.domElement.remove();
      this.domElement = null;
      this.reactRoot = null;
    }
  }
};
