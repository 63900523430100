/* @flow weak */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox, { localization, dialog } from 'sandbox';
import toastService from 'core/services/toastService';
import Form from './Form';
import actions from '../actions';
import FlatButton from 'components/common/buttons/FlatButton';
import { utils as futils, Panel } from 'widgets/SimpleForm/src/index';
import { ComboboxWithButtons } from 'components/common/floating';

const { translate } = localization;

var testTemplate = {
  "name": "template 1",
  "layoutType": "2ups",
  "pageCount": 8,
  "forms": [
    {
      "name": "form 1",
      "columns": 2,
      "rows": 2,
      "separations": [
        {
          "colorName": "black",
          "direct": false
        },
        {
          "colorName": "cyan",
          "direct": true
        },
        {
          "colorName": "magenta",
          "direct": false
        },
        {
          "colorName": "yellow",
          "direct": false
        }
      ],
      "pages": [
        {
          "name": "page 0",
          "numberInSection": 0,
          "row": 0,
          "column": 0,
          "rowSpan": 1,
          "columnSpan": 2,
          "anchorPoint": "top-left",
          "separations": [
            {
              "colorName": "black",
              "offsetX": 0.1,
              "offsetY": 0.1,
              "scaleX": 0.1,
              "scaleY": 0.1,
              "scaleByPercentage": false
            }
          ]
        },
        {
          "name": "page 1",
          "numberInSection": 1,
          "row": 1,
          "column": 0,
          "rowSpan": 1,
          "columnSpan": 1,
          "anchorPoint": "top-left",
          "separations": [
            {
              "colorName": "cyan",
              "offsetX": 0.1,
              "offsetY": 0.1,
              "scaleX": 0.1,
              "scaleY": 0.1,
              "scaleByPercentage": false
            },
            {
              "colorName": "magenta",
              "offsetX": 0.1,
              "offsetY": 0.1,
              "scaleX": 0.1,
              "scaleY": 0.1,
              "scaleByPercentage": false
            },
            {
              "colorName": "yellow",
              "offsetX": 0.1,
              "offsetY": 0.1,
              "scaleX": 0.1,
              "scaleY": 0.1,
              "scaleByPercentage": false
            },
            {
              "colorName": "black",
              "offsetX": 0.1,
              "offsetY": 0.1,
              "scaleX": 0.1,
              "scaleY": 0.1,
              "scaleByPercentage": false
            }
          ]
        },
        {
          "name": "page 2",
          "numberInSection": 2,
          "row": 1,
          "column": 1,
          "rowSpan": 1,
          "columnSpan": 1,
          "anchorPoint": "top-left",
          "separations": [
            {
              "colorName": "black",
              "offsetX": 0.1,
              "offsetY": 0.1,
              "scaleX": 0.1,
              "scaleY": 0.1,
              "scaleByPercentage": false
            }
          ]
        }
      ]
    }
  ]
};

const styles = {
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  bookCaption: {
    marginRight: 'auto',
    whiteSpace: 'nowrap'
  },
  templateCaption: {
    marginLeft: '10px',
    marginRight: '10px',
    whiteSpace: 'nowrap'
  },
  titleActions: {
    display: 'flex',
    alignItems: 'center'
  },
  combobox: {
    width: 200
  },
  saveButton: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '5px'
  },
  buttonImage: {
    margin: 'auto'
  }
};

module.exports = class Book extends Component {
  constructor(props) {
    super(props);
    this.composeTemplatesKey = this.composeTemplatesKey.bind(this);
    this.composeTemplatesPath = this.composeTemplatesPath.bind(this);
    this.updateCurrentTemplate = this.updateCurrentTemplate.bind(this);
    this.handleTemplateChange = this.handleTemplateChange.bind(this);
    this.handleTemplateSelect = this.handleTemplateSelect.bind(this);
    this.handleTemplateDelete = this.handleTemplateDelete.bind(this);
    this.handleSaveAsTemplateClick = this.handleSaveAsTemplateClick.bind(this);
    this.saveAsTemplate = this.saveAsTemplate.bind(this);
    this.addTemplate = this.addTemplate.bind(this);
    this.loadTemplate = this.loadTemplate.bind(this);
    this.deleteTemplate = this.deleteTemplate.bind(this);
    this.bookFromTemplate = this.bookFromTemplate.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderPanelTitle = this.renderPanelTitle.bind(this);
  }

  static propTypes = {
    store: PropTypes.any,
    book: PropTypes.object,
    path: PropTypes.string
  };

  composeTemplatesKey(layoutType, pageCount) {
    if (!layoutType || !pageCount) {
      return '';
    }

    return layoutType + '^' + pageCount + 'p';
  }

  composeTemplatesPath(layoutType, pageCount) {
    var key = this.composeTemplatesKey(layoutType, pageCount);
    return !key ? '' : futils.compose('model', 'templates', key);
  }

  updateCurrentTemplate(value) {
    const { store } = this.props;
    const path = futils.compose(this.props.path, 'currentTemplate');
    store.dispatch(actions.update(path, value));
  }

  handleTemplateChange(event, value) {
    //console.log("handleTemplateChange => value=", value);
    this.updateCurrentTemplate(value);
  }

  handleTemplateSelect(event, item, index) {
    //console.log("handleTemplateSelect => item=", item);
    this.updateCurrentTemplate(item);
    const _TEST_ = false;
    if (_TEST_) {
      this.bookFromTemplate(testTemplate);
    } else {
      this.loadTemplate(item);
    }
  }

  handleTemplateDelete(event, item) {
    //console.log("handleTemplateDelete => item=", item);

    const msg = translate('Are you sure you want to delete the Template') + ` '${item}'?`;
    const title = translate('Delete Template');

    dialog.confirm(msg, title).then(() => {
      this.deleteTemplate(item);
    })
      .catch(res => console.log(res));
  }

  handleSaveAsTemplateClick() {
    //console.log("handleSaveAsTemplateClick ---");
    this.saveAsTemplate();
  }

  saveAsTemplate() {
    //console.log("saveAsTemplate() ---");
    const { store, book } = this.props;
    const state = store.getState();
    var params = {
      action: 'saveAsFanoutTemplate',
      command: 'getLayouManagerActions',
      rootId: state.nwid,
      type: state.type,
      layoutType: book.layoutType,
      pageCount: book.pageCount,
      templateName: book.currentTemplate,
      book: JSON.stringify({ ...book, name: book.currentTemplate })
    };
    sandbox.request.genericRequestPromise(params)
      .then((data) => {
        toastService.createToast('top-right', 'The book was saved as template', '', 'success', undefined, undefined, 2000);
        const path = this.composeTemplatesPath(book.layoutType, book.pageCount);
        this.addTemplate(path, book.currentTemplate);
      });
    //.catch((reason) => console.error('Save Book as Fanout Template: ' + reason));
  }

  addTemplate(path, templateName) {
    this.props.store.dispatch(actions.addTemplate(path, templateName));
  }

  loadTemplate(templateName) {
    //console.log("saveAsTemplate() ---");
    const { store, book } = this.props;
    const state = store.getState();
    var params = {
      action: 'loadFanoutTemplate',
      command: 'getLayouManagerActions',
      rootId: state.nwid,
      type: state.type,
      layoutType: book.layoutType,
      pageCount: book.pageCount,
      templateName: templateName
    };
    sandbox.request.genericRequestPromise(params)
      .then((template) => this.bookFromTemplate(template));
    //.catch((reason) => console.error('Load Fanout Template: ' + reason));
  }

  deleteTemplate(templateName) {
    //console.log("deleteTemplate() ---");
    const { store, book } = this.props;
    const state = store.getState();
    var params = {
      action: 'deleteFanoutTemplate',
      command: 'getLayouManagerActions',
      rootId: state.nwid,
      type: state.type,
      layoutType: book.layoutType,
      pageCount: book.pageCount,
      templateName: templateName
    };

    sandbox.request.genericRequestPromise(params)
      .then((data) => {
        toastService.createToast('top-right', 'The template ' + templateName + ' has been deleted', '', 'success', undefined, undefined, 2000);
        const path = this.composeTemplatesPath(book.layoutType, book.pageCount);
        this.props.store.dispatch(actions.deleteTemplate(path, templateName));
      });
  }

  bookFromTemplate(template) {
    //toastService.createToast('top-right', 'Template data loaded to book', '', 'success', undefined, undefined, 2000);

    const { store, path } = this.props;
    store.dispatch(actions.bookFromTemplate(path, template));
  }

  renderForm(form, index) {
    const { store, book, path } = this.props;
    const formPath = futils.compose(this.props.path, 'forms', index);
    const key = `${form.nwid}_${index}`;

    return (
      <Form key={key} store={store} book={book} form={form} path={formPath} />
    );
  }

  renderPanelTitle(book) {
    const { store } = this.props;
    const state = store.getState();
    const changed = book.nwid && futils.get(state, futils.compose('changedItems', book.nwid));
    const changedMark = changed ? '*' : '';
    const bookCaption = translate('Book:') + ' ' + book.name + changedMark + ' - ' + state.units;
    const originalTemplate = book.originalTemplate || translate('--none--');
    //const sameAsTemplateText = !book.originalTemplate || book.sameAsTemplate ? '' : ' (' + translate('modified') + ')';
    const templateCaption = translate('Fanout template:') + ' ' + originalTemplate; // + sameAsTemplateText;
    const titleActionsStyle = { ...styles.titleActions, display: book.collapsed ? 'none' : 'flex' };

    const templatesKey = this.composeTemplatesKey(book.layoutType, book.pageCount);
    const templates = state.model.templates ? state.model.templates[templatesKey] || [] : [];

    return (
      <div style={styles.title}>
        <div style={styles.bookCaption}>
          {bookCaption}
        </div>
        <div style={styles.templateCaption}>
          {templateCaption}
        </div>
        <div
          style={titleActionsStyle}
          onClick={event => event.stopPropagation()}
        >
          <ComboboxWithButtons
            style={styles.combobox}
            value={book.currentTemplate}
            options={templates}
            onChange={this.handleTemplateChange}
            onSelect={this.handleTemplateSelect}
            onRemove={this.handleTemplateDelete}
          />
          <FlatButton
            style={styles.saveButton}
            tooltip={translate('Save as Template')}
            disabled={!book.currentTemplate}
            onClick={this.handleSaveAsTemplateClick}
          >
            <img style={styles.buttonImage} src={sandbox.icons.getGeneralIcon(null, 'save')} />
          </FlatButton>
        </div>
      </div>
    );
  }

  render() {
    const { book, path } = this.props;
    const forms = book.forms || [];
    //console.log("book.forms =>", book.forms);

    const collapsedPath = futils.compose(path, 'collapsed');

    return (
      <Panel
        title={this.renderPanelTitle(book)} collapsible={true} bind={collapsedPath}
        style={{ position: 'relative' }}>
        {forms.map(this.renderForm)}
      </Panel>
    );
  }

};
