import { cells } from 'widgets/ReactDataGrid';
import { translate } from 'core/services/localization';

const { Text } = cells;

export const makeRenamingRulesTableColumns = (viewModel, module) => {
  const columns = [
    {
      key: 'name',
      width: 150,
      align: 'left',
      caption: translate('Renaming rule name'),
      cell: Text,
      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: viewModel.renamingRules[rowIndex] && viewModel.renamingRules[rowIndex].name || '',
        };
      },
    },
  ];

  return columns;
};