import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getFolderCurrentDate } from 'core/dates';
import { translate } from 'core/services/localization';
import DateSelector from '../components/DateSelector';
import PublicationSelector from '../components/PublicationSelector';
import TemplateSelector from '../components/TemplateSelector';
import EditionSelector from '../components/EditionSelector';
import ZoneSelector from '../components/ZoneSelector';

const getDefaultDate = () => {
  const date = getFolderCurrentDate();
  date.setDate(date.getDate() + 1); // tomorrow

  const issueDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  return issueDate;
};

const getZonesSignature = (zones = []) => {
  const signature = zones.filter(z => z.selected).map(z => z.displayName).join();

  return signature;
};

const getInitialStepModel = () => {
  const stepModel = {
    date: getDefaultDate(),
    publication: null,
    edition: null,
    zones: [],
  };

  return stepModel;
};

function CreateEdition({ visible, modelHandler, onUpdate }) {

  const [stepModel, setStepModel] = useState(() => getInitialStepModel());
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [loading, setLoading] = useState(false);

  const zonesSignature = useRef('');

  const publications = useMemo(() => {
    return modelHandler.getPublications(stepModel.date);
  }, [stepModel.date, modelHandler.model.newPublicationsInfo]);

  const editions = useMemo(() => {
    return modelHandler.getEditions(stepModel.publication);
  }, [stepModel.publication]);

  const updateStepModel = partialModel => {
    const newStepModel = { ...stepModel, ...partialModel };
    setStepModel(newStepModel);
    onUpdate(newStepModel);
  };

  const updateSelectedTemplate = template => {
    modelHandler.clearTemplate();

    setSelectedTemplate(template);
  };

  const getStyle = () => {
    if (!visible) {
      return { display: 'none' };
    }
  };

  const isTemplateEnabled = () => {    
    let isPlannedPublication = stepModel.publication && stepModel.publication.plannedPublication ? true : false;
    let ret = false;
    if (isPlannedPublication && stepModel.publication.standAloneEditions && stepModel.edition){
      ret = !stepModel.publication.plannedPublication.editions.some(e => e.name === stepModel.edition.displayName);      
    } else {
      ret = !isPlannedPublication;
    }
    return ret;
  };

  const isPlannedMessageVisible = () => {
    return !!(selectedTemplate && !isTemplateEnabled());
  };

  const areZonesChanged = () => {
    if (loading || !selectedTemplate || isPlannedMessageVisible()) {
      return false;
    }

    return zonesSignature.current !== getZonesSignature(stepModel.zones);
  };

  const handleSelectDate = (e, value) => {
    if (stepModel.date.getTime() !== value.getTime()) {
      const date = value;

      updateStepModel({ date, publication: null, edition: null, zones: [] });
    }
  };

  const handleSelectPublication = publication => {
    if (publication !== stepModel.publication) {
      updateSelectedTemplate(null);

      updateStepModel({ publication, edition: null, zones: [] });
    }
  };

  const handleSelectTemplate = template => {
    if (template?.name === selectedTemplate?.name) {
      return;
    }

    updateSelectedTemplate(template);

    if (!template || !isTemplateEnabled()) {
      updateStepModel({ edition: null, zones: [] });
      zonesSignature.current = '';
      return;
    }

    setLoading(true);
    modelHandler.loadTemplate(template.name)
      .then(res => {
        updateStepModel(res);
        zonesSignature.current = getZonesSignature(res?.zones);
      }).finally(() => {
      setLoading(false);
    });
  };

  const handleActivateTemplate = () => {
    if (!selectedTemplate || !stepModel.publication) {
      return;
    }

    setLoading(true);
    modelHandler.activateTemplate(selectedTemplate.name)
      .finally(() => {
        setLoading(false);
        handleSelectPublication(null);
      });
  };

  const handleSelectEdition = async edition => {
    if (edition.displayName !== stepModel.edition?.displayName) {
      if (selectedTemplate) {
        updateSelectedTemplate(null);

        updateStepModel({ edition: null, zones: [] });
      } else {
        const zones = await modelHandler.getZones(stepModel.date, stepModel.publication, edition);
        updateStepModel({ edition, zones });
      }
    }
  };

  const handleSelectZone = zone => {
    zone.selected = !zone.selected;

    updateStepModel({ zones: stepModel.zones });
  };

  const handleChangeDeadline = (zone, deadline) => {
    zone.deadline = deadline;

    updateStepModel({ zones: stepModel.zones });
  };

  return (
    <div className='crtx-create-edition' style={getStyle()}>

      <DateSelector date={stepModel.date} onSelect={handleSelectDate} />

      <PublicationSelector
        publications={publications}
        selectedPublication={stepModel.publication}
        onSelect={handleSelectPublication}
      />

      <TemplateSelector
        visible={!!stepModel.publication}
        canActivate={!loading && !isPlannedMessageVisible()}
        templates={modelHandler.getTemplates(stepModel.publication?.displayName)}
        selectedTemplate={selectedTemplate}
        onSelectTemplate={handleSelectTemplate}
        onActivateTemplate={handleActivateTemplate}
      />

      {isPlannedMessageVisible() ?
        <div className={'planned-message validation-message'}>
          {translate('The selected publication is planned already for the given date. Activate this template will override exists publication')}
        </div>
        :
        <EditionSelector
          visible={!!stepModel.publication}
          editions={editions}
          selectedEdition={stepModel.edition}
          onSelect={handleSelectEdition}
        />
      }
      <ZoneSelector
        visible={!isPlannedMessageVisible() && !!stepModel.edition}
        changed={areZonesChanged()}
        zones={stepModel.zones}
        deadlineMode={modelHandler.settings.zoneDeadlineMode}
        onSelect={handleSelectZone}
        onChangeDeadline={handleChangeDeadline}
      />

    </div>
  );
}

CreateEdition.propTypes = {
  visible: PropTypes.bool,
  modelHandler: PropTypes.any,
  onUpdate: PropTypes.func,
};

export default CreateEdition;
