/**
 * @name Placeholder
 * @fileOverview Placeholder class
 *
 * @author Boris
 * @since: 2019-06-02
 */

import logger from 'logger';

const log = logger.getDefaultLogger();

export default class Placeholder {
  constructor(info) {
    Object.assign(this, info);
  }

  getActionObjects() {
    return this.actionObjects;
  }

  setActionObjects(actionObjects) {
    this.actionObjects = actionObjects;
  }

  isApplicable() {
    let result = false;

    if (this.type === 'view') {
      result = true;
    } else if (this.type === 'action' || this.type === 'clientAction') {
      if (this.action) {
        result = typeof this.action.isApplicable === 'function' ? this.action.isApplicable() : this.action._isApplicable || false;
      } else if (Array.isArray(this.actionObjects)) {
        result = this.actionObjects.some(obj => typeof obj.action.isApplicable === 'function' ?
          obj.action.isApplicable(obj.targetItems) : obj.action._isApplicable || false);
      }
    }

    return result;
  }

  execute() {
    if (this.action) {
      if (typeof this.action.execute === 'function') {
        this.action.execute();
      }
    } else if (Array.isArray(this.actionObjects)) {
      this.actionObjects.forEach(obj => obj.action.execute(obj.targetItems));
    }
  }
}
