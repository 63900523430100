function planSelected(state, action) {
	const nwid = action.nwid;

	return {
		...state,
		selected: state.selected.contains(nwid) ?
			state.selected.filter(item => item !== nwid) :
			state.selected.length < 2 ? state.selected.concat(nwid).sort() : state.selected
	};
}

function hasChild(treeNode, name) {
	if (typeof treeNode === 'undefined') return false;
	if (typeof treeNode.children === 'undefined') return false;

	return treeNode.children.filter(child => child.name === name).length > 0;
}
function getChild(treeNode, name) {
	return treeNode.children.filter(child => child.name === name)[0];
}
function createChild(name) {
	return {
		name: name,
		children: [],
		changes: [],
		positivity: ''
	};
}
function breadcrumbToTree(breadcrumb , tree, diff) {
	let treeNode = tree;
	breadcrumb.forEach((item, index) => {
		let name = item;
		if (index === 0) name = 'root';
		if(!/virtual|sourceId|commonId\[.+\]/.test(name)) {
			if (!hasChild(treeNode, name)) {
				treeNode.children = (treeNode.children || []).concat(createChild(name));
			}
			treeNode = getChild(treeNode, name);
		}
	});
	treeNode.changes = (treeNode.changes || []).concat({
		changeType: diff['change type'],
		oldValue: diff['old value 2'] ? diff['old value'].concat(' , ').concat(diff['old value 2']) : diff['old value'] ,
		newValue: diff['new value 2'] ? diff['new value'].concat(' , ').concat(diff['new value 2']) : diff['new value']
	});
}

function createTree(tree = {}, diff) {
	if (typeof diff['bread crumb'] === 'undefined')
		return undefined;
	const breadcrumb = diff['bread crumb'].split(/\\|[*]{1,2}/);
	breadcrumbToTree(breadcrumb , tree, diff);
	if(diff['bread crumb 2'])
		breadcrumbToTree(diff['bread crumb 2'].split(/\\|[*]{1,2}/) , tree, diff);
	return tree;
}
function isVirtual(treeNode) {
	if (hasChild(treeNode, 'virtual[true]'))
		return getChild(treeNode, 'virtual[true]').changes
			.filter(change => change.changeType === 'PROPERTY_ADDED')
				.length > 0;
	if(treeNode.changes && treeNode.changes.filter(change => change.changeType === 'VIRTUAL_ENTITY_ADDED').length > 0)
		return true;
	return false;
}
function filterVirtuals(treeNode) {
	let newTreeNode = createChild(treeNode.name);
	if (isVirtual(treeNode)) return undefined;
	newTreeNode.changes = treeNode.changes;
	newTreeNode.children = treeNode.children.filter(c=>!isVirtual(c));
	newTreeNode.children = newTreeNode.children.map(filterVirtuals);
	return newTreeNode;

	// if (isVirtual(treeNode)) {
	// 	return undefined;
	// }
	// let newTreeNode = createChild(treeNode.name);
	// newTreeNode.changes = treeNode.changes;
	// newTreeNode.children = treeNode.children.filter(c=>!isVirtual(c));
	// newTreeNode.children = newTreeNode.children.map(filterVirtuals);
	// return newTreeNode;
}
function getNativeNodePositivity(treeNode) {
	if (typeof treeNode.changes === 'undefined')
		return 'none';
	let positive = treeNode.changes
			.filter(change => change.changeType === 'PROPERTY_ADDED' 
				|| change.changeType === 'ENTITY_ADDED' || change.changeType === 'PANORAMA_ADDED').length>0;
	let negative = treeNode.changes
			.filter(change => change.changeType === 'PROPERTY_REMOVED' 
				|| change.changeType === 'ENTITY_REMOVED' || change.changeType === 'PANORAMA_REMOVED').length>0;
	let neutral = treeNode.changes
			.filter(change => change.changeType ==='panorama' || change.changeType ==='CHILD_NODELIST_LENGTH' || change.changeType ==='TEXT_VALUE').length>0;
	if (neutral || (positive && negative))
		return 'neutral';
	else if (positive)
		return 'positive';
	else if (negative)
		return 'negative';
	else
		return 'none';
}
function setPositivity(treeNode) {
	let childrenPositivity = treeNode.children.map(setPositivity);
	let nativePositivity = getNativeNodePositivity(treeNode);
	treeNode.positivity = nativePositivity;
	return treeNode.positivity;
}

function setDiffs(state, action) {
	const diffs = filterVirtuals(action.diffs.reduce(createTree, {}));
	if (diffs)
		setPositivity(diffs);
	const source = action.source;
	const destination = action.destination;

	return {
		...state,
		diffs,
		source,
		destination
	};
}

function emptyDiffs(state, action) {
	return {
		...state,
		diffs: undefined,
		source: '',
		destination: ''
	};
}

function noDiffs(state, action) {
	const source = action.source;
	const destination = action.destination;
	const diffs = [];
	return {
		...state,
		diffs: undefined,
		source,
		destination
	};
}

module.exports = (state = {}, action) => {
	switch (action.type) {
		case 'TOGGLE_PALN_SELECTED':
			return planSelected(state, action);
		case 'SET_DIFFS':
			return setDiffs(state, action);
		case 'EMPTY_DIFFS':
			return emptyDiffs(state, action);
		case 'NO_DIFFS':
			return noDiffs(state, action);
		default:
			return state;
	}
};