import React from 'react';
import PropTypes from 'prop-types';
import Table, { Column, headers, filters } from 'widgets/ReactDataGrid';
import { FILTER_TYPE, ROW_HEIGHT, HEADER_HEIGHT, HEADER_HEIGHT_WITH_FILTER } from 'widgets/ReactDataGrid/utils';
import { defaultShouldCellUpdate } from './columnsCreator';
const { GenericHeader, HeaderCaption } = headers;
const { TextInputFilter } = filters;

const MIN_COLUMN_WIDTH = 20;

export default function View({ module, viewModel, monitorProfilesTableColumns = [] }) {

  const renderMonitorProfileColumnFilter = column => {

    const { filter } = column;

    if (!filter) {
      return;
    }

    switch (filter.type) {

      case FILTER_TYPE.TEXT:
        return (
          <TextInputFilter
            value={filter.textValue}
            onChange={(e, textValue) => module.handleMonitorProfileColumnFilterChange(column, { textValue })}
          />
        );
    }
  };

  const handleMonitorProfileColumnHeaderFilter = column => {

    if (!module.filtersEnabled) {
      return;
    }

    return (
      <div className='column-header-filter'>
        {renderMonitorProfileColumnFilter(column)}
      </div>
    );
  };

  const handleMonitorProfileColumnHeaderCaption = (column, handleClick, sortDirection, index) => {
    return (
      <HeaderCaption sortDirection={sortDirection} sortOrder={index + 1} tooltip={column.caption}
        onSort={multiSort => handleClick(column.key, column.sortValueGetter, multiSort)} sortable={typeof column.sortType !== 'undefined'}
      >
        {column.caption}
      </HeaderCaption>
    );
  };

  const handleMonitorProfileColumnHeader = (column, handleClick, sortDirection, index) => {
    return (
      <GenericHeader
        captionRenderer={handleMonitorProfileColumnHeaderCaption(column, handleClick, sortDirection, index)}
        filterRenderer={handleMonitorProfileColumnHeaderFilter(column)}
      />
    );
  };

  const renderMonitorProfilesTable = () => {

    const { columnsToSortBy, filtersEnabled } = module;

    const headerHeight = filtersEnabled ? HEADER_HEIGHT_WITH_FILTER : HEADER_HEIGHT;

    return (
      <Table
        rows={viewModel.filteredMonitorProfiles}
        columnKey='nwid'
        virtualScroll={true}
        selectableRows={true}
        fixed={false}
        minColumnWidth={MIN_COLUMN_WIDTH}
        headerHeight={headerHeight}
        rowHeight={ROW_HEIGHT}
        onColumnsFilter={(columns, columnKey) => module.handleMonitorProfilesTableColumnsFilter(columns)}
        onSelect={selectedRows => module.handleTableSelect(selectedRows)}
        onRowContextMenu={(rowIndex, rowContent, selectedRows, e) => module.handleRowContextMenu(rowContent, selectedRows, e)}
        onColumnsOrder={(columns, oldIndex, newIndex) => module.handleMonitorProfilesTableColumnOrder(columns, oldIndex, newIndex)}
        onColumnResize={(columns, columnKey) => module.handleMonitorProfilesTableColumnResize(columns)}
        showFilterOptionInHeaderMenu={true}
        filtersEnabled={filtersEnabled}
        onToggleFiltersClick={(e, newValue) => module.toggleFilters(newValue)}
        onDoubleClickRow={(rowIndex, rowContent, e) => module.handleDoubleClickRow(rowContent)}
      >
        {monitorProfilesTableColumns.map(col => {
          const index = columnsToSortBy.findIndex(column => column.key === col.key);
          const sortDirection =
            col.sortType && index !== -1
              ? columnsToSortBy[index].ascending ? 'asc' : 'desc'
              : undefined;
          const handleClick = col.sortType
            ? module.handleMonitorProfileColumnClick(col.sortType)
            : undefined;
          return <Column
            key={col.key}
            title={col.caption}
            columnKey={col.key}
            width={col.width}
            visible={col.visible}
            header={handleMonitorProfileColumnHeader(col, handleClick, sortDirection, index)}
            cell={col.cell}
            cellDataGetter={col.cellDataGetter}
            shouldCellUpdate={col.shouldCellUpdate || defaultShouldCellUpdate}
          />;
        })}
      </Table>
    );
  };

  return (
    <div className='crtx-profile-view' >
      {renderMonitorProfilesTable()}
    </div>
  );
}

View.propTypes = {
  module: PropTypes.object,
  viewModel: PropTypes.object,
  monitorProfilesTableColumns: PropTypes.array,
};
