import React, { Component } from 'react';
import PropTypes from 'prop-types';


export default class AdaptiveHeightContainer extends Component {
  static propTypes = {
    rowHeight: PropTypes.number,
    maxRows: PropTypes.number,
    containerPadding: PropTypes.number

  };

  setContainerHeight = (containerRef) => {
    const { rowHeight, maxRows, containerPadding } = this.props;
    const currentRows = ~~(containerRef.scrollHeight / rowHeight);

    if (currentRows > maxRows) {
      containerRef.style.height = `${maxRows * rowHeight + containerPadding}px`;
      containerRef.scrollTop = containerRef.scrollHeight;
    } else {
      containerRef.style.height = `${currentRows * rowHeight + containerPadding}px`;
    }
  };

  componentDidUpdate() {
    if (this.containerRef) {
      this.setContainerHeight(this.containerRef);
    }
  }

  render() {
    const { children } = this.props;

    return <div ref={el => this.containerRef = el} className='crtx-chat-adaptive-height-container'>
      {children}
    </div>
  }
}