import React, { useState } from 'react';
import { Dialog, DialogButtonsBar } from 'components/common/dialogs';
import TextInput from 'components/common/inputs/TextInput';
import Button from 'components/common/buttons/Button';
import Dropdown from 'components/common/dropdown/Dropdown';
import { translate } from 'core/services/localization';
import { Validator, useValidator } from 'components/private/forms';
import { ROLE_OPTIONS, ROTATION_OPTIONS } from './constants';

export const AddEditLayoutDialog = (
  { plates, title, targetLayoutGroup, onClose, onAddEditLayout, targetLayout, editMode }) => {

  const groupName = targetLayoutGroup.name;
  const getRoleNote = (role) => {
    let note = '';
    if (editMode) {
      note = role === 'general' ? translate('Note: Defines the odd forme') :
        (role === 'panorama' ? translate('Note: Defines the even Panorama forme') : '');
    }
    return note;
  }
  const [role, setRole] = useState(editMode ? targetLayout.role : '');
  const [layoutName, setLayoutName] = useState(editMode ? targetLayout.name : '');
  const [plate, setPlate] = useState(editMode ? targetLayout.plateNwid : '');
  const [rotation, setRotation] = useState(editMode ? targetLayout.viewRotation : -1);
  const [roleIsChoosen, setRoleIsChoosen] = useState(editMode);
  const [roleNote, setRoleNote] = useState(getRoleNote(role));

  const { register, validate } = useValidator();

  const platesOptions = [{ "value": '', "text": '-- Select Plate --' }, ...plates.map(plate => {
    return { "value": plate.nwid, "text": plate.name }
  })];

  const handleCreateEditLayout = () => {
    if (!validate()) {
      return;
    }

    let layout = {};
    if (editMode) {
      layout = {
        ...targetLayout,
        name: layoutName,
        plateNwid: plate,
        viewRotation: rotation || -1,
        role: role
      }
    } else {
      layout = {
        elementType: 'layout',
        name: layoutName,
        plateNwid: plate,
        viewRotation: rotation || -1,
        role: role
      };
    }

    onAddEditLayout(layout);
    onClose();
  }

  const handleOnSelectRole = (event, value) => {
    setRole(value);

    if (value !== '') {
      setRoleIsChoosen(true);
      setLayoutName(ROLE_OPTIONS[ROLE_OPTIONS.findIndex(item => item.value === value)].text);
    } else {
      setRoleIsChoosen(false);
    }
    setRoleNote(getRoleNote(value));

  }

  const handleOnSelectRotation = (event, value) => {
    setRotation(value);
  };

  const handleOnSelectPlate = (event, value) => {
    setPlate(value);
  };

  return <Dialog
    onClose={onClose}
    title={title}
    initialWidth={360}
    modal={true}
  >

    <div className='crtx-form-section-container'>
      <div className='crtx-form-section'>
        <div className='crtx-form-two-columns'>
          <label>{translate('Layout Group')}:</label>
          <TextInput className='crtx-form-element-full-width' value={groupName} disabled={true} />

          <label>{translate('Role')}:</label>
          <Validator register={register} rules={['required']} className={'crtx-form-element-full-width-validator'}>
            <Dropdown className='crtx-form-element-full-width' options={ROLE_OPTIONS} value={role}
                      onSelect={handleOnSelectRole} />
          </Validator>

          {!!roleNote && <div className='crtx-form-full-row crtx-dialog-info-text'>{roleNote}</div>}


          <label>{translate('Name')}:</label>
          <Validator register={register} rules={['required']} className={'crtx-form-element-full-width-validator'}>
            <TextInput className='crtx-form-element-full-width' value={layoutName} disabled={!roleIsChoosen}
                       onChange={(e, value) => {
                         setLayoutName(value)
                       }} />
          </Validator>

          <label>{translate('View Rotation (clockwise)')}:</label>
          <Dropdown className='crtx-form-element-full-width' options={ROTATION_OPTIONS} value={rotation}
                    onSelect={handleOnSelectRotation} />

          <label>{translate('Plate')}:</label>
          <Validator register={register} rules={['required']} className={'crtx-form-element-full-width-validator'}>
            <Dropdown className='crtx-form-element-full-width' options={platesOptions} value={plate}
                      onSelect={handleOnSelectPlate} />
          </Validator>

        </div>
      </div>
    </div>
    <DialogButtonsBar>
      <Button className={'primary'} onClick={handleCreateEditLayout}>
        {editMode ? translate('Save Layout') : translate('Create Layout')}
      </Button>
    </DialogButtonsBar>
  </Dialog>
};