import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import iconService from 'core/services/iconService';

export default function StatusComponent({ model }) {


  const { finished, error, hold, wait } = model;

  return (
    <div className="crtx-flowstep-overview-status" >
      <div title={translate('Finished')}>
        <img src={iconService.getGeneralIcon('status', 'finished')} />
        {finished}
      </div>
      <div title={translate('Error')}>
        <img src={iconService.getGeneralIcon('status', 'error_small')} />
        {error}
      </div>
      <div title={translate('Hold')}>
        <img src={iconService.getGeneralIcon('status', 'hold')} />
        {hold}
      </div>
      <div title={translate('Waiting')}>
        <img src={iconService.getGeneralIcon('status', 'wait')} />
        {wait}
      </div>
    </div>
  );
}


StatusComponent.propTypes = {
  model: PropTypes.object,

};

