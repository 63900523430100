import React, { Component } from 'react';
import sandbox from 'sandbox';
import LayoutPage from './LayoutPage';
import Header from 'src/components/common/headers/Header';
import PropTypes from 'prop-types';

const layoutMatrixSizeMultiplePlates = { width: 90, height: 75 };
const layoutMatrixSizeSinglePlate = { width: 57, height: 72 };
const translate = sandbox.localization.translate;
const labels = {
  layoutGroupName: translate('Layout group'),
  layoutRole: translate('Layout role'),
  plateTemplate: translate('Plate template'),
  theLayoutWasModified: translate('The layout was modified'),
  nwid: translate('Nwid'),
  path: translate('Path'),
}

module.exports = class Layout extends Component {
  constructor(props) {
    super(props);
    this.getLayoutGroupName = this.getLayoutGroupName.bind(this);
    this.getLayoutRole = this.getLayoutRole.bind(this);
    this.getPlateTemplate = this.getPlateTemplate.bind(this);
    this.getManualLayoutChanged = this.getManualLayoutChanged.bind(this);
    this.getPagesByContentNwid = this.getPagesByContentNwid.bind(this);
    this.getPageRotationByContentNwid = this.getPageRotationByContentNwid.bind(this);
    this.getPanoramaPageSizeFromOrietations = this.getPanoramaPageSizeFromOrietations.bind(this);
    this.getPagesObject = this.getPagesObject.bind(this);
    this.getLayoutPages = this.getLayoutPages.bind(this);
    this.getLayoutMatrixSize = this.getLayoutMatrixSize.bind(this);
    this.getLayoutMatrix = this.getLayoutMatrix.bind(this);

  }

  static propTypes = {
    title: PropTypes.string,
    object: PropTypes.any,
    pages: PropTypes.any,
    advanceInfo: PropTypes.bool,
    openerType: PropTypes.string//dialog or rightPanel
  }

  getLayoutGroupName() {
    const { object } = this.props;
    const layoutGroupName = object.layoutGroup != undefined ? <div className='custom-info-row'><span className='custom-info-row-title'>{labels.layoutGroupName}:</span><span className='custom-info-row-content ellipsis' title={object.layoutGroup}>{object.layoutGroup}</span></div> : '';
    return layoutGroupName;
  }
  getLayoutRole() {
    const { object } = this.props;
    const layoutRole = object.layoutName != undefined ? <div className='custom-info-row'><span className='custom-info-row-title'>{labels.layoutRole}:</span><span className='custom-info-row-content ellipsis' title={object.layoutName}>{object.layoutName}</span></div> : '';
    return layoutRole;
  }

  getPlateTemplate() {
    const { object } = this.props;
    const plateTemplate = object.pmt != undefined ? <div className='custom-info-row'><span className='custom-info-row-title'>{labels.plateTemplate}:</span><span className='custom-info-row-content ellipsis' title={object.pmt}>{object.pmt}</span></div> : '';
    return plateTemplate;
  }
  getManualLayoutChanged() {
    let { object } = this.props;
    const manualLayoutChanged = object.manualLayoutChanged ? <div className='custom-info-row'><span className='custom-info-manual-layout-changed ellipsis' title={labels.theLayoutWasModified}>* {labels.theLayoutWasModified}</span></div> : '';
    return manualLayoutChanged;
  }

  getPagesByContentNwid() {
    const { pages } = this.props;
    let pagesBycontentNwid = {};
    pages.forEach(function (page, index) {
      pagesBycontentNwid[page.contentNwid] = page;
    });
    return pagesBycontentNwid; // return object {contentNwid: {pageObj},contentNwid: {pageObj}...}
  }

  getPageRotationByContentNwid(pageContentNwid) {
    const { object } = this.props;
    const PagesRotationOnForm = object.PagesRotationOnForm;
    const rotationDegrees = ['0deg', '90deg', '180deg', '270deg'];
    return rotationDegrees[PagesRotationOnForm[pageContentNwid]];
  }



  getPanoramaPageSizeFromOrietations(orietation, pageContentNwid, singlePageSize) {
    const { object } = this.props;
    const PageInfoOnFormOrietations = object.PageInfoOnForm.orietations;
    return PageInfoOnFormOrietations[pageContentNwid] === orietation ? (singlePageSize * 2) : singlePageSize;
  }


  getPagesObject(singlePageWidth, singlePageHeight, numberOfRow, numberOfCol) {
    const { object, pages } = this.props;
    const PageInfoOnFormIndexes = object.PageInfoOnForm.indexes;
    const pagesBycontentNwid = this.getPagesByContentNwid();//get each pageObject by contentNwid
    let pagesObject = {};
    for (var pageContentNwid in PageInfoOnFormIndexes) {//build object that contain pages objects, each page object contain all the data for layout.

      const pageRow = Math.floor(PageInfoOnFormIndexes[pageContentNwid] / numberOfCol);
      const pageCol = PageInfoOnFormIndexes[pageContentNwid] % numberOfCol;
      const pagesPosition = {
        top: pageRow * singlePageHeight,
        left: pageCol * singlePageWidth
      }

      pagesObject[pageContentNwid] = {};
      pagesObject[pageContentNwid]["index"] = PageInfoOnFormIndexes[pageContentNwid];
      pagesObject[pageContentNwid]["name"] = pagesBycontentNwid[pageContentNwid].name;
      pagesObject[pageContentNwid]["position"] = pagesPosition;
      pagesObject[pageContentNwid]["isPanorama"] = pagesBycontentNwid[pageContentNwid].isPanoramaOnForm;
      pagesObject[pageContentNwid]["rotation"] = this.getPageRotationByContentNwid(pageContentNwid);
      pagesObject[pageContentNwid]["pageWidth"] = !pagesBycontentNwid[pageContentNwid].isPanoramaOnForm ? singlePageWidth : this.getPanoramaPageSizeFromOrietations("horizontal", pageContentNwid, singlePageWidth);//if is not panorama, page get the regular singlePageWidth, else call function taht check if the page orietation is horizontal.
      pagesObject[pageContentNwid]["pageHeight"] = !pagesBycontentNwid[pageContentNwid].isPanoramaOnForm ? singlePageHeight : this.getPanoramaPageSizeFromOrietations("vertical", pageContentNwid, singlePageHeight);//if is not panorama, page get the regular singlePageHeight, else call function taht check if the page orietation is vertical.
    }
    return pagesObject;
  }

  getLayoutPages(numberOfRow, numberOfCol, layoutMatrixSize) {
    const { object, pages } = this.props;

    const singlePageWidth = numberOfCol > 1 ? layoutMatrixSize.width / numberOfCol : layoutMatrixSize.width;
    const singlePageHeight = numberOfRow > 1 ? layoutMatrixSize.height / numberOfRow : layoutMatrixSize.height;

    let pagesObject = this.getPagesObject(singlePageWidth, singlePageHeight, numberOfRow, numberOfCol);
    let pagesComponent = [];

    for (var page in pagesObject) {
      const layoutPage = <LayoutPage key={page} pagesObject={pagesObject[page]} pageContentNwid={page} />;
      pagesComponent.push(layoutPage);
    }
    return pagesComponent;
  }

  getLayoutMatrixSize(numberOfRow, numberOfCol) {
    if (numberOfRow === 1 && numberOfCol === 1) return layoutMatrixSizeSinglePlate;
    if (numberOfRow === 2 && numberOfCol === 1) return layoutMatrixSizeSinglePlate;
    return layoutMatrixSizeMultiplePlates;
  }

  getLayoutMatrix() {
    const { object } = this.props;
    const PageInfoOnFormIndexes = object.PageInfoOnForm.indexes;
    const numberOfRow = object.PageInfoOnForm.matrix.verticalImageCount;
    const numberOfCol = object.PageInfoOnForm.matrix.horizontalImageCount;
    const layoutMatrixSize = this.getLayoutMatrixSize(numberOfRow, numberOfCol);
    const layoutMatrixStyle = {
      width: layoutMatrixSize.width + 'px',
      height: layoutMatrixSize.height + 'px',
      position: 'relative'
    }

    return <div className='custom-info-row'>
      <span className='custom-info-row-title'></span>
      <span className='custom-info-row-content'><div className="layoutMatrix" style={layoutMatrixStyle}>{this.getLayoutPages(numberOfRow, numberOfCol, layoutMatrixSize)}</div></span>
    </div>

  }

  render() {
    const { title, object, advanceInfo } = this.props;

    const nwid = advanceInfo ? <div className='custom-info-row'><span className='custom-info-row-title'>{labels.nwid}: </span><span className='custom-info-row-content'>{object.nwid || ''}</span></div> : "";
    const path = advanceInfo ? <div className='custom-info-row'><span className='custom-info-row-title'>{labels.path}: </span><span className='custom-info-row-content'>{object.actualPath || ''}</span></div> : "";

    return <div className='layout-custom-info'>
      <Header>{title}</Header>
      {this.getLayoutGroupName()}
      {this.getLayoutRole()}
      {this.getPlateTemplate()}
      {this.getLayoutMatrix()}
      {this.getManualLayoutChanged()}
      {nwid}
      {path}
    </div>;
  }

}