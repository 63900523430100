define(
  [
    './Button/Icon',
    './Button/Button',
    './Button/SplitButton',
    './Button/ToggleButton',
    './List/List',
    './Panel/CollapsiblePanel',
    './Accordion/Accordion',
    './Accordion/AccordionPanel'
  ],
  function (
    Icon,
    Button,
    SplitButton,
    ToggleButton,
    List,
    CollapsiblePanel,
    Accordion,
    AccordionPanel) {

    return {
      Icon,
      Button,
      SplitButton,
      ToggleButton,
      List,
      CollapsiblePanel,
      Accordion,
      AccordionPanel
    };

  });
