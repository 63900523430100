define(['react', 'prop-types'],
  function (React, PropTypes) {
    const { Component } = React;

    return class Accordion extends Component {
      static propTypes = {
        expandedId: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ]).isRequired,
        onToggleCollapse: PropTypes.func
      }

      static defaultProps = {
        onToggleCollapse: () => { }
      }

      constructor(props) {
        super(props);
        this.handleToggleCollapse = this.handleToggleCollapse.bind(this);
        this.isPanelCollapsed = this.isPanelCollapsed.bind(this);
        this.renderChild = this.renderChild.bind(this);
      }

      handleToggleCollapse(id) {
        this.props.onToggleCollapse(id);
      }

      isPanelCollapsed(id) {
        return id != this.props.expandedId;
      }

      //renderGroup: function (group) {
      //  return (
      //    <CollapsiblePanel key={group.id}
      //                      title={group.title}
      //                      id={group.id}
      //                      collapsed={this.isPanelCollapsed(group)}
      //                      onToggleCollapse={() => this.handleToggleCollapse(group)}
      //    >
      //      {group.panelContent}
      //    </CollapsiblePanel>
      //  );
      //},

      renderChild(child, index) {
        let id = typeof child.props.id === 'undefined' ? index : child.props.id;
        return React.cloneElement(child, {
          key: index,
          id: id,
          collapsed: this.isPanelCollapsed(id),
          onToggleCollapse: this.handleToggleCollapse
        });
      }

      render() {

        return (
          <div className="Accordion">
            {React.Children.map(this.props.children, this.renderChild)}
          </div>
        );
      }
    }
  });