import React, { useState } from 'react';
import { Dialog, DialogButtonsBar } from 'components/common/dialogs';
import { translate } from 'core/services/localization';
import Checkbox from 'components/common/inputs/Checkbox';
import Button from 'components/common/buttons/Button';
import { restPost } from 'core/managers/rest2';
import toastService from 'core/services/toastService';

export const MediaTypeDialog = ({ onClose, title, resource, moduleNwid }) => {

  const { mediaState: { mediaCounts = {} } = {} } = resource;

  const [disabledMediaTypes, setDisabledMediaTypes] = useState(resource.disabledMediaTypes ?? []);

  const mediaTypes = Object.keys(mediaCounts);

  const handleMediaTypesCheckboxChange = (e, checked, mediaType) => {
    const arr = checked ? disabledMediaTypes.filter(item => item !== mediaType) : [...disabledMediaTypes, mediaType];
    setDisabledMediaTypes(arr);
  };

  const handleSaveMediaTypesState = () => {
    restPost(moduleNwid, `que-manager/resources/${resource.nwid}/disable-media-types`, { disabledMediaTypes })
      .catch(() => toastService.errorToast('', translate('Failed to update media type status')))
      .finally(() => onClose());
  };

  return <Dialog title={title}
    autoFocus={false}
    onClose={onClose}
    initialWidth={500}
    initialHeight={200}
    modal={true}>
    <div className='crtx-form-section-container crtx-form-full-height'>
      {mediaTypes.map(mt => {
        const checked = !disabledMediaTypes.some(item => item === mt);
        return <label key={mt} className='crtx-form-label-with-checkbox'>
          <Checkbox checked={checked} onChange={(e, value) => handleMediaTypesCheckboxChange(e, value, mt)} />
          {mt}
        </label>
      })}
    </div>
    <DialogButtonsBar>
      <Button className='primary'
        onClick={handleSaveMediaTypesState}>{translate('Save')}
      </Button>
      <Button
        onClick={onClose}>{translate('Cancel')}
      </Button>
    </DialogButtonsBar>
  </Dialog>
};