import React from 'react';
import PropTypes from 'prop-types';

export default function FragmentsListItemComponent({ fragment }) {
  const { color, size, name } = fragment;

  return (
    <li className={color} style={{ width: size, height: size }} title={name}>
      {fragment.type === 'separation' && <div className='separator'></div>}
    </li>
  );
}

FragmentsListItemComponent.propTypes = {
  fragment: PropTypes.object
};
