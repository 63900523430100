/* @flow weak */
import { translate } from 'core/services/localization';
import { cloneDeep } from 'sandbox/jsUtils';

const SimpleForm = require('widgets/SimpleForm/src/index');
const helpers = require('./helpers');
import { swapItems } from 'utilities/array';
import {
  checkTextCondition,
  checkDateCondition,
  checkNumberCondition
} from 'widgets/ReactDataGrid/utils';
import { createObjectComparator, COMPARE_TYPE, composeComparators } from 'core/comparators';
import { appUtils } from 'sandbox';

const { utils } = SimpleForm;
const isUndefined = o => typeof o === 'undefined';

const isNewSelectorColumnPinned = (columnType, state, currentTablePath) => {
  const values = utils.get(state, `${currentTablePath}.values`) || [];
  return columnType === 'selectors' && values.length > 0 ? !!values[0].pinColumnForScroll : false;
};

const DEPENDENT_FIELDS = {
  publication: {
    selectors: ['edition', 'zone', 'section'],
    values: ['allLayoutGroups', 'broadsheet', 'tabs', '2ups', '4ups', '8ups', 'customLayoutGroup']
  }
};

const addTable = (state, action) => {
  const tablesPath = 'tables';
  const tabsPath = 'tabs';
  const modifiedTabsPath = 'modifiedTabs';
  const tables = utils.get(state, tabsPath);
  const tabs = utils.get(state, tabsPath);
  const modifiedTabs = utils.get(state, modifiedTabsPath);
  const tablesLength = tables.length;
  const tabsLength = tabs.length;
  const defaultTableName = 'Table';
  const lastTableNumber = helpers.lastTableNumber(tables, defaultTableName);
  const newTableName = lastTableNumber === 0 ? defaultTableName : `${defaultTableName} ${lastTableNumber}`;
  const newTable = helpers.emptyTable(newTableName);

  const newTab = { name: newTable.name, nwid: newTable.nwid };
  let newState = utils.add(state, tablesPath, tablesLength, { ...newTable, overridable: true });
  newState = utils.add(newState, tabsPath, tabsLength, newTab);
  newState = utils.update(newState, modifiedTabsPath, Object.assign({}, modifiedTabs, { [newTable.nwid]: true }));
  newState = utils.update(newState, 'currentTableIndex', tablesLength);
  newState = utils.update(newState, 'currentColumnIndex', -1);
  newState = utils.update(newState, 'currentColumnType', '');
  newState = utils.update(newState, 'userPreferences.tables', {
    ...newState.userPreferences.tables,
    [newTableName]: { columns: {} }
  });

  return newState;
};
const addTableByDef = (state, action) => {
  const definition = action.definition;
  const tableType = action.tableType;
  const tablesPath = 'tables';
  const tabsPath = 'tabs';
  const modifiedTabsPath = 'modifiedTabs';
  const tables = utils.get(state, tabsPath);
  const tabs = utils.get(state, tabsPath);
  const modifiedTabs = utils.get(state, modifiedTabsPath);
  const tablesLength = tables.length;
  const tabsLength = tabs.length;
  const defaultTableName = definition.name;
  const lastTableNumber = helpers.lastTableNumber(tables, defaultTableName);
  const newTableName = tableType === 'systems' ? defaultTableName : lastTableNumber === 0 ? defaultTableName : `${defaultTableName} ${lastTableNumber}`;
  const newTable = helpers.newTable(definition, tableType, newTableName);
  const availableSystemTableNames = utils.get(state, 'AvailableSystemTableNames');
  const tableIndexInAvailableSystemTables = tableType === 'systems' ? availableSystemTableNames.findIndex((item) => {
    return item.name === definition.name;
  }) : -1;
  const currentColumnType = helpers.getTableFirstColumnType(newTable);
  const currentColumnIndex = helpers.getTableFirstColumnIndex(newTable, currentColumnType);

  const newTab = { name: newTable.name, nwid: newTable.nwid };
  let newState = utils.add(state, tablesPath, tablesLength, {
    ...newTable,
    overridable: isUndefined(newTable.overridable) ? true : newTable.overridable
  });
  newState = utils.add(newState, tabsPath, tabsLength, newTab);
  newState = utils.update(newState, modifiedTabsPath, Object.assign({}, modifiedTabs, { [newTable.nwid]: true }));
  newState = utils.update(newState, 'currentTableIndex', tablesLength);
  newState = utils.update(newState, 'currentColumnIndex', currentColumnIndex);
  newState = utils.update(newState, 'currentColumnType', currentColumnType);
  if (tableIndexInAvailableSystemTables >= 0) {
    newState = utils.update(newState, `AvailableSystemTableNames[${tableIndexInAvailableSystemTables}].loaded`, true);
  }
  newState = utils.update(newState, 'userPreferences.tables', {
    ...newState.userPreferences.tables,
    [newTableName]: { columns: {} }
  });
  return newState;
};
const removeTable = (state, action) => {
  const tableIndex = action.tableIndex;
  const tablesPath = 'tables';
  const tablePath = `${tablesPath}[${tableIndex}]`;
  const tabsPath = 'tabs';
  const modifiedTabsPath = 'modifiedTabs';
  const modifiedTabs = utils.get(state, `${modifiedTabsPath}`);
  const table = utils.get(state, `${tablePath}`);
  const tableNwid = table.nwid;
  const tablesLength = utils.get(state, `${tablesPath}`).length;
  const availableSystemTableNamesPath = 'AvailableSystemTableNames';
  const availableSystemTableNames = utils.get(state, availableSystemTableNamesPath);
  const currentTableIndex = utils.get(state, 'currentTableIndex');
  let deletedTables = state.deletedTables;

  const tableExistsInDeletedTables = state.deletedTables.some(item => item === table.name);
  if (!tableExistsInDeletedTables && !isNaN(table.nwid)) {
    deletedTables = deletedTables.concat([state.tables[currentTableIndex].name]);
  }

  delete state.userPreferences.tables[table.name];
  let newState = utils.remove(state, tablesPath, tableIndex);
  newState = utils.remove(newState, tabsPath, tableIndex);
  // newState = utils.update(newState, `${modifiedTabsPath}[${tableNwid}]`, true);
  const newTablesLength = utils.get(newState, 'tables').length;
  newState = utils.update(newState, 'currentTableIndex', newTablesLength <= tableIndex ? newTablesLength - 1 : tableIndex);
  newState = utils.update(newState, 'currentColumnIndex', -1);
  newState = utils.update(newState, 'currentColumnType', helpers.getFirstColumnType(newState, utils.get(newState, 'currentTableIndex')));
  newState = utils.update(newState, 'deletedTables', deletedTables);
  if (isNaN(table.nwid)) {
    delete modifiedTabs[table.nwid];
    newState = utils.update(newState, 'modifiedTabs', modifiedTabs);
    delete newState[`${modifiedTabsPath}[${tableNwid}]`];
  }
  if (table.system === true) {
    const tableIndexInAvailableSystemTables = availableSystemTableNames.findIndex((item) => {
      return item.name === table.name;
    });
    if (tableIndexInAvailableSystemTables >= 0) {
      newState = utils.update(newState, `${availableSystemTableNamesPath}[${tableIndexInAvailableSystemTables}].loaded`, false);
    }
  }

  return newState;
};
const updateCurrentTable = (state, action) => {
  const currentTableIndex = action.currentTableIndex;
  const currentColumnType = helpers.getFirstColumnType(state);
  const currentColumnIndex = helpers.getFirstColumnIndex(state, currentTableIndex, currentColumnType);

  let newState = utils.update(state, 'currentTableIndex', currentTableIndex);
  newState = utils.update(newState, 'currentColumnType', currentColumnType);
  newState = utils.update(newState, 'currentColumnIndex', currentColumnIndex);

  newState = filterAndSortTableRows(newState);

  return newState;
};

const filterAndSortTableRows = state => {
  let newState = state;
  const tablePath = `tables[${newState.currentTableIndex}]`;
  const table = utils.get(newState, tablePath);

  if (!table) {
    return newState;
  };
  const replicaTable = getReplicaTable(newState, table.nwid);
  if (replicaTable) {
    newState = utils.update(newState, `${tablePath}.rows`, replicaTable.rows);
  };

  if (table.sortAndFilterEnabled) {
    const filters = getTableFilters(table);
    const columnsToSort = getColumnsToSort(table);
    if (filters.length > 0 || columnsToSort.length > 0) {
      newState = makeReplicaTable(newState, table);
      newState = filterTableRows(newState, filters);
      newState = sortTableRows(newState, columnsToSort);
    };
  }

  return newState;
};

const updateCurrentTableName = (state, action) => {
  const currentTableName = action.value;
  const currentTableIndex = utils.get(state, 'currentTableIndex');
  const currentTablePath = `tables[${currentTableIndex}]`;
  const currentTableNwidPath = `${currentTablePath}.nwid`;
  const currentTableNwid = utils.get(state, currentTableNwidPath);
  const oldName = utils.get(state, `tables[${currentTableIndex}].name`);
  const tmpUserPreferencesTable = state.userPreferences.tables[oldName];
  delete state.userPreferences.tables[oldName];
  state.userPreferences.tables[currentTableName] = tmpUserPreferencesTable;

  let newState = utils.update(state, `tables[${currentTableIndex}].name`, currentTableName);
  newState = utils.update(newState, `tabs[${currentTableIndex}].name`, currentTableName);
  newState = utils.update(newState, `modifiedTabs[${currentTableNwid}]`, true);

  return newState;
};
const updateCurrentColumn = (state, action) => {
  let newState = utils.update(state, 'currentColumnType', action.currentColumnType);
  newState = utils.update(newState, 'currentColumnIndex', action.currentColumnIndex);

  return newState;
};
const updateCurrentColumnName = (state, action) => {
  const path = action.path;
  const currentTableIndex = action.currentTableIndex;
  const currentTablePath = `tables[${currentTableIndex}]`;
  const currentTableNwidPath = `${currentTablePath}.nwid`;
  const currentTableNwid = utils.get(state, currentTableNwidPath);
  const currentColumnType = action.currentColumnType;
  const currentColumnIndex = action.currentColumnIndex;
  const currentColumnPath = `${currentTablePath}.${currentColumnType}[${currentColumnIndex}]`;
  const currentColumnDisplayNamePath = `${currentColumnPath}.displayName`;
  const oldColumnName = action.oldColumnName;
  const newColumnName = action.newColumnName;
  const currentColumnDisplayName = utils.get(state, currentColumnDisplayNamePath) || '';
  const tables = utils.get(state, 'tables') || [];
  const currentTable = tables[currentTableIndex];

  let newState = utils.update(state, path, newColumnName);
  if (currentColumnDisplayName === '') {
    newState = utils.update(newState, currentColumnDisplayNamePath, newColumnName);
  }

  if (typeof currentTable !== 'undefined') {
    currentTable.rows.forEach((row, rowIndex) => {
      const columnsPath = `tables[${currentTableIndex}].rows[${rowIndex}].${currentColumnType}`;
      const columnValue = row[currentColumnType][oldColumnName];
      const columns = utils.removeItem(utils.get(newState, columnsPath), oldColumnName);
      const newColumns = Object.assign({}, columns, { [newColumnName]: columnValue });
      newState = utils.update(newState, columnsPath, newColumns);
    });
  }
  newState = utils.update(newState, `modifiedTabs[${currentTableNwid}]`, true);

  return newState;
};
const updateCurrentColumnProperty = (state, action) => {
  const path = action.path;
  const value = action.value;
  const currentTableIndex = utils.get(state, 'currentTableIndex');
  const currentColumnType = utils.get(state, 'currentColumnType');
  const currentTablePath = `tables[${currentTableIndex}]`;
  const currentTableNwidPath = `${currentTablePath}.nwid`;
  const currentTableNwid = utils.get(state, currentTableNwidPath);
  const rows = utils.get(state, `${currentTablePath}.rows`);

  let newState = utils.update(state, path, value);

  const cursorPath = path.split('.');
  if (cursorPath[cursorPath.length - 1] === 'fieldType' && rows.length > 0) {
    const columnNameToChange = utils.get(state, `${cursorPath[0]}.${cursorPath[1]}.name`);
    const columnType = cursorPath[1].indexOf("selectors") > -1 ? 'selectors' : cursorPath[1].indexOf("values") > -1 ? 'values' : '';
    rows.forEach((row, rowIndex) => {
      let updatePath = `${currentTablePath}.rows[${rowIndex}].${columnType}.${columnNameToChange}`;
      newState = utils.update(newState, updatePath, '');
    });
  }
  if (cursorPath[cursorPath.length - 1] === 'pinColumnForScroll') {
    const columns = utils.get(newState, `${currentTablePath}.${currentColumnType}`);
    const currentColumnName = utils.get(newState, `${cursorPath[0]}.${cursorPath[1]}.name`);
    const indexOfCurrentColumn = columns.findIndex(s => s.name === currentColumnName);
    const updatedColumns = helpers.updatePinnedColumns(value, indexOfCurrentColumn, columns);
    newState = utils.update(newState, `${currentTablePath}.${currentColumnType}`, updatedColumns);
    if (currentColumnType === 'values' && value) {
      const selectors = utils.get(newState, `${currentTablePath}.selectors`);
      const updatedSelectors = helpers.updatePinnedColumns(value, selectors.length, selectors);
      newState = utils.update(newState, `${currentTablePath}.selectors`, updatedSelectors);
    };
    if (currentColumnType === 'selectors' && !value) {
      const values = utils.get(newState, `${currentTablePath}.values`);
      const updatedValues = helpers.updatePinnedColumns(value, -1, values);
      newState = utils.update(newState, `${currentTablePath}.values`, updatedValues);
    };
  };
  newState = utils.update(newState, `modifiedTabs[${currentTableNwid}]`, true);

  return newState;
};
const addColumn = (state, action) => {
  const columnType = action.columnType;
  const currentTableIndex = action.currentTableIndex;
  const currentTablePath = `tables[${currentTableIndex}]`;
  const currentTableNwidPath = `${currentTablePath}.nwid`;
  const currentTableNwid = utils.get(state, currentTableNwidPath);
  const columnsPath = `${currentTablePath}[${columnType}]`;
  const columnsLength = (utils.get(state, columnsPath) || []).length;
  const columns = utils.get(state, columnsPath);
  const pinColumnForScroll = isNewSelectorColumnPinned(columnType, state, currentTablePath);
  const newColumnNumber = columnType === 'selectors' ? helpers.lastColumnNumber(columns, 'Selector') : helpers.lastColumnNumber(columns, 'Value');
  const newColumnTypeName = columnType === 'selectors' ? 'Selector' : 'Value';
  const newColumnName = newColumnNumber > 0 ? `${newColumnTypeName} ${newColumnNumber}` : newColumnTypeName;
  const newColumn = columnType === 'selectors' ? { ...helpers.emptySelector(newColumnName, newColumnName), pinColumnForScroll } : helpers.emptyValue(newColumnName, newColumnName);

  let newState = utils.add(state, columnsPath, columnsLength, newColumn);
  newState = utils.update(newState, 'currentColumnType', columnType);
  newState = utils.update(newState, 'currentColumnIndex', columnsLength);
  newState = utils.update(newState, `modifiedTabs[${currentTableNwid}]`, true);

  return newState;
};
const removeCurrentColumn = (state, action) => {
  const currentTableIndex = action.currentTableIndex;
  const currentColumnType = action.currentColumnType;
  const currentColumnIndex = action.currentColumnIndex;
  const currentTablePath = `tables[${currentTableIndex}]`;
  const currentColumnPath = `${currentTablePath}.${currentColumnType}[${currentColumnIndex}]`;
  const currentTableNwidPath = `${currentTablePath}.nwid`;
  const currentTableNwid = utils.get(state, currentTableNwidPath);
  const columnsPath = `${currentTablePath}[${currentColumnType}]`;
  const columnsLength = (utils.get(state, columnsPath) || []).length;
  const tables = utils.get(state, 'tables') || [];
  const currentColumnName = utils.get(state, `${currentColumnPath}.name`);

  let newState = utils.remove(state, columnsPath, currentColumnIndex);
  const newColumnsLength = utils.get(newState, columnsPath).length;
  newState = utils.update(
    newState,
    'currentColumnIndex',
    newColumnsLength <= currentColumnIndex ? newColumnsLength - 1 : currentColumnIndex
  );

  tables.forEach((table, tableIndex) => {
    table.rows.forEach((row, rowIndex) => {
      const tableColumnsPath = `tables[${tableIndex}].rows[${rowIndex}].${currentColumnType}`;
      const tableColumnPath = `${tableColumnsPath}.${currentColumnName}`;
      newState = utils.removeItem(newState, tableColumnPath);
    });
  });

  newState = utils.update(newState, `modifiedTabs[${currentTableNwid}]`, true);

  return newState;
};
const updateColumnPosition = (state, action) => {
  const currentTableIndex = action.currentTableIndex;
  const currentColumnType = action.currentColumnType;
  const oldIndex = action.oldIndex;
  const newIndex = action.newIndex;
  const currentTablePath = `tables[${currentTableIndex}]`;
  const currentColumnsPath = `${currentTablePath}.${currentColumnType}`;
  const currentTableNwidPath = `${currentTablePath}.nwid`;
  const currentTableNwid = utils.get(state, currentTableNwidPath);
  const columns = swapItems([...utils.get(state, currentColumnsPath)], oldIndex, newIndex);
  let updatedColumns = columns;

  const pinColumnForScroll = columns[newIndex].pinColumnForScroll;
  updatedColumns = helpers.updatePinnedColumns(pinColumnForScroll, newIndex, columns);

  let newState = utils.update(state, currentColumnsPath, updatedColumns);
  newState = utils.update(newState, 'currentColumnType', currentColumnType);
  newState = utils.update(newState, 'currentColumnIndex', newIndex);
  newState = utils.update(newState, `modifiedTabs[${currentTableNwid}]`, true);

  return newState;
};
const addDropdownOption = (state, action) => {
  const currentTableIndex = utils.get(state, 'currentTableIndex');
  const currentColumnType = utils.get(state, 'currentColumnType');
  const currentColumnIndex = utils.get(state, 'currentColumnIndex');
  const tables = utils.get(state, 'tables');
  const currentTableNwid = utils.get(state, `tables[${currentTableIndex}].nwid`);
  const index = action.index;

  let newState = utils.add(state, `tables[${currentTableIndex}].${currentColumnType}[${currentColumnIndex}].values`, index, {
    text: '',
    value: ''
  });
  newState = utils.update(newState, `modifiedTabs[${currentTableNwid}]`, true);

  return newState;
};
const changeDropdownProp = (state, action) => {
  const currentTableIndex = utils.get(state, 'currentTableIndex');
  const currentColumnType = utils.get(state, 'currentColumnType');
  const currentColumnIndex = utils.get(state, 'currentColumnIndex');
  const tables = utils.get(state, 'tables');
  const currentTableNwid = utils.get(state, `tables[${currentTableIndex}].nwid`);
  const index = action.index;
  const propName = action.propName;
  const value = action.value;

  let newState = utils.update(state, `tables[${currentTableIndex}].${currentColumnType}[${currentColumnIndex}].values[${index}].${propName}`, value);
  newState = utils.update(newState, `modifiedTabs[${currentTableNwid}]`, true);

  return newState;
};
const removeDropdownOption = (state, action) => {
  const currentTableIndex = utils.get(state, 'currentTableIndex');
  const currentColumnType = utils.get(state, 'currentColumnType');
  const currentColumnIndex = utils.get(state, 'currentColumnIndex');
  const tables = utils.get(state, 'tables');
  const currentTableNwid = utils.get(state, `tables[${currentTableIndex}].nwid`);
  const index = action.index;

  let newState = utils.remove(state, `tables[${currentTableIndex}].${currentColumnType}[${currentColumnIndex}].values`, index);
  newState = utils.update(newState, `modifiedTabs[${currentTableNwid}]`, true);

  return newState;
};
const updateTable = (state, action) => {
  const path = action.path;
  const value = action.value;
  const currentTableIndex = utils.get(state, 'currentTableIndex');
  const currentTablePath = `tables[${currentTableIndex}]`;
  const currentTableNwidPath = `${currentTablePath}.nwid`;
  const currentTableNwid = utils.get(state, currentTableNwidPath);

  let newState = utils.update(state, path, value);
  newState = utils.update(newState, `modifiedTabs[${currentTableNwid}]`, true);

  const indexOfIsPublicationTable = action.path.indexOf('isPublicationTable');
  if (indexOfIsPublicationTable >= 0 && value) {
    newState = utils.update(newState, `${currentTablePath}.publication`, newState.publications[1].name);
  }
  else if (indexOfIsPublicationTable >= 0 && !value) {
    newState = utils.update(newState, `${currentTablePath}.publication`, '');
  }

  return newState;
};

const getReplicaTablePath = (nwid) => {
  return `replicaTables[${nwid}]`;
};

const getReplicaTable = (state, nwid) => {
  return utils.get(state, getReplicaTablePath(nwid));
};

const makeReplicaTable = (state, table) => {
  if (!table) {
    return state;
  }

  let newState = state;
  const replicaTable = getReplicaTable(newState, table.nwid);
  if (!replicaTable) {
    const clonedTable = cloneDeep(table);
    newState = utils.update(newState, `replicaTables[${table.nwid}]`, clonedTable);
  }

  return newState;
};

const updateDependencies = (state, table, action) => {

  let newState = state;
  const pathArr = action.path.split('.');
  const updatedFieldName = pathArr[pathArr.length - 1];

  const updatedField = table.selectors.find(s => s.name === updatedFieldName) || table.values.find(s => s.name === updatedFieldName);

  const dependentFields = DEPENDENT_FIELDS[updatedField?.fieldType];

  if (dependentFields) {

    const composePath = (key, name) => {
      return pathArr[0] + '.' + pathArr[1] + '.' + key + '.' + name;
    };

    dependentFields.selectors.forEach(dep => {
      const obj = table.selectors.find(s => s.fieldType === dep);

      if (obj) {
        newState = utils.update(newState, composePath('selectors', obj.name), obj.defaultValue);
      }
    });

    dependentFields.values.forEach(dep => {
      const obj = table.values.find(s => s.fieldType === dep);

      if (obj) {
        newState = utils.update(newState, composePath('values', obj.name), obj.defaultValue);
      }
    });
  }

  return newState;
};

const updateCell = (state, action) => {
  const table = utils.get(state, `${action.tablePath}`);
  const tableNwid = table.nwid;

  let newState = utils.update(state, action.path, action.value);

  newState = updateDependencies(newState, table, action);

  if (utils.get(newState, action.path) !== utils.get(state, action.path)) {
    newState = utils.update(newState, `modifiedTabs[${tableNwid}]`, true);

    const replicaTable = getReplicaTable(newState, tableNwid);
    if (replicaTable) {
      const replicaTablePath = getReplicaTablePath(tableNwid);
      const parts = action.path.split('.');
      const rowPath = parts.slice(0, 2).join('.');
      const row = utils.get(newState, `${rowPath}`);
      const replicaIndex = replicaTable.rows.findIndex(r => r.index === row.index);
      const valuePath = parts.slice(2).join('.');

      newState = utils.update(newState, `${replicaTablePath}.rows[${replicaIndex}].${valuePath}`, action.value);
    }
  }

  return newState;
};

const addRow = (state, action) => {
  let newState = state;

  const table = utils.get(newState, `${action.tablePath}`);
  const tableNwid = table.nwid;

  newState = utils.add(newState, action.path, action.index, action.value);
  newState = utils.update(newState, `modifiedTabs[${tableNwid}]`, true);

  const replicaTable = getReplicaTable(newState, tableNwid);
  if (replicaTable) {
    const replicaTablePath = getReplicaTablePath(tableNwid);
    let replicaIndex = 0;
    if (table.rows.length > 0) {
      if (action.index < table.rows.length) {
        const row = table.rows[action.index];
        replicaIndex = replicaTable.rows.findIndex(r => r.index === row.index);
      } else {
        const row = table.rows[table.rows.length - 1];
        replicaIndex = replicaTable.rows.findIndex(r => r.index === row.index) + 1;
      }
    }

    newState = utils.add(newState, `${replicaTablePath}.rows`, replicaIndex, action.value);
  }

  return newState;
};

const removeRow = (state, action) => {
  let newState = state;

  const table = utils.get(newState, `${action.tablePath}`);
  const tableNwid = table.nwid;

  newState = utils.remove(newState, action.path, action.index);
  newState = utils.update(newState, `modifiedTabs[${tableNwid}]`, true);

  const replicaTable = getReplicaTable(newState, tableNwid);
  if (replicaTable) {
    const replicaTablePath = getReplicaTablePath(tableNwid);
    const row = table.rows[action.index];
    let replicaIndex = replicaTable.rows.findIndex(r => r.index === row.index);

    newState = utils.remove(newState, `${replicaTablePath}.rows`, replicaIndex);
  }

  return newState;
};
const cleanChanged = (state) => {
  let newState = utils.update(state, `modifiedTabs`, {});
  newState = utils.update(newState, `deletedTables`, []);
  return newState;
};
const setViewMode = (state, action) => {
  let newState = state;

  newState = utils.update(newState, 'viewMode', action.viewMode);

  const tablePath = `tables[${newState.currentTableIndex}]`;
  const table = utils.get(newState, tablePath);
  const replicaTable = getReplicaTable(newState, table?.nwid);
  if (action.viewMode === 'manage') {
    newState = restoreTableFromReplica(newState, tablePath);
  } else {
    newState = filterAndSortTableRows(newState);
  }

  return newState;
};

const saveNewTablesNwid = (state, action) => {
  const tables = utils.get(state, 'tables');
  const tabs = utils.get(state, 'tabs');
  const data = action.data || {};
  let newState = state;

  tables.forEach((table, index) => {
    if (isNaN(table.nwid)) {
      const newTable = data.find((dataTable) => {
        return Object.keys(dataTable)[0] === table.name;
      });
      newState = utils.update(newState, `tables[${index}].nwid`, newTable[table.name]);
    }
  });

  tabs.forEach((tab, index) => {
    if (isNaN(tab.nwid)) {
      const newTable = data.find((dataTable) => {
        return Object.keys(dataTable)[0] === tab.name;
      });
      newState = utils.update(newState, `tabs[${index}].nwid`, newTable[tab.name]);
    }
  });

  return newState;
};


const initState = (state, action) => {
  let newState = state;

  for (let i in newState) {
    newState = utils.update(newState, i, action.baseState[i]);
  }

  newState = utils.set(newState, 'moduleNwid', action.moduleNwid);
  newState = utils.set(newState, 'userPreferences', action.userPreferences);
  newState = utils.set(newState, 'publications', action.publications);
  newState = utils.set(newState, 'layoutGroups', action.layoutGroups);
  newState = utils.set(newState, 'pubsMap', action.pubsMap);
  newState = utils.set(newState, 'layoutGroupOptions', action.layoutGroupOptions);
  newState = utils.set(newState, 'allLayoutGroupOptions', action.allLayoutGroupOptions);
  newState = utils.set(newState, 'allLayoutGroupOptionsByPublicationMap', action.allLayoutGroupOptionsByPublicationMap);
  newState = utils.set(newState, 'sites', action.sites);
  newState = utils.set(newState, 'folderSecurityGroups', action.folderSecurityGroups);
  newState = utils.set(newState, 'flowStepsByNwid', action.flowStepsByNwid);
  newState = utils.set(newState, 'resources', action.resources);

  newState = filterAndSortTableRows(newState);

  return newState;
};

const duplicateTable = (state, action) => {
  const tablesPath = `tables`;
  const tabsPath = `tabs`;
  const modifiedTabsPath = `modifiedTabs`;
  const tables = utils.get(state, tablesPath);
  const tabs = utils.get(state, tabsPath);
  const currentDuplicateTab = tabs[action.duplicateTableIndex];
  const currentDuplicateTable = tables.find((table) => {
    return table.name === currentDuplicateTab.name;
  });
  const modifiedTabs = utils.get(state, modifiedTabsPath);
  const tablesLength = tables.length;
  const tabsLength = tabs.length;
  const defaultTableName = `${currentDuplicateTable.name} - ${translate('Copy')}`;
  const lastTableNumber = helpers.lastTableNumber(tables, defaultTableName);
  const newTableName = lastTableNumber === 0 ? defaultTableName : `${defaultTableName} ${lastTableNumber}`;
  const newTable = helpers.duplicateTable(newTableName, currentDuplicateTable);

  const newTab = { name: newTable.name, nwid: newTable.nwid };
  let newState = utils.add(state, tablesPath, tablesLength, newTable);
  newState = utils.add(newState, tabsPath, tabsLength, newTab);
  newState = utils.update(newState, modifiedTabsPath, Object.assign({}, modifiedTabs, { [newTable.nwid]: true }));
  newState = utils.update(newState, 'currentTableIndex', tablesLength);
  newState = utils.update(newState, 'currentColumnIndex', -1);
  newState = utils.update(newState, 'currentColumnType', '');

  return newState;
};

const setUserPreferences = (state, action) => {
  const currentTableName = state.tables[action.currentTableIndex].name;
  const userPreferencesTable = state.userPreferences.tables[currentTableName] || {};

  return utils.update(state, 'userPreferences', {
    ...state.userPreferences,
    tables: {
      ...state.userPreferences.tables,
      [currentTableName]: {
        ...userPreferencesTable,
        columns: {
          ...action.columns
        }
      }
    }
  });
};

const updateSavedTablesOldName = (state, action) => {
  const tables = utils.get(state, 'tables');
  const savedTables = action.data;
  let newState = state;
  savedTables.forEach(savedTable => {
    const savedTableName = Object.keys(savedTable)[0];
    const tableIndex = tables.findIndex(table => table.name === savedTableName);
    newState = utils.update(newState, `tables[${tableIndex}].oldName`, savedTableName);
  });

  return newState;
};

const restoreTableFromReplica = (state, tablePath) => {
  let newState = state;

  const table = utils.get(newState, tablePath);
  if (table) {
    const replicaTable = newState.replicaTables?.[table.nwid];
    if (replicaTable) {
      newState = utils.update(newState, tablePath, replicaTable);
      delete newState.replicaTables[table.nwid];
    }
  }
  return newState;
};

const getColumnPath = (state, tablePath, columnName) => {
  let columnPath;

  let columnType;
  const table = utils.get(state, tablePath);
  let columnIndex = table.selectors.findIndex(col => col.name === columnName);
  if (columnIndex >= 0) {
    columnType = 'selectors';
  } else {
    columnIndex = table.values.findIndex(col => col.name === columnName);
    if (columnIndex >= 0) {
      columnType = 'values';
    }
  }

  if (columnType) {
    columnPath = `${tablePath}.${columnType}[${columnIndex}]`;
  }

  return columnPath;
};

const updateFilterValue = (state, columnPath, action) => {
  if (!columnPath) {
    return state;
  }

  let newState = state;

  const { filterType } = action.column;

  if (!action.filter || (filterType === 'date' && action.filter.option === 'nofilter') || (filterType !== 'date' && !action.filter.textValue)) {
    newState = utils.remove(newState, columnPath, 'filter');
  } else {
    newState = utils.update(newState, `${columnPath}.filter`, action.filter);
  }

  return newState;
};

const getNumOfLastSort = table => {
  const numOfActiveSorts = (table, columnType) => {
    return table[columnType].reduce((acc, col) => {
      if (col.sort?.order > acc) {
        acc = col.sort?.order;
      };

      return acc;
    }, 0);
  };

  const selectorsLastSort = numOfActiveSorts(table, 'selectors');
  const valuesLastSort = numOfActiveSorts(table, 'values');

  return selectorsLastSort > valuesLastSort ? selectorsLastSort : valuesLastSort;
};

const arrangeSortOrder = (table, sortRemoved, tablePath, state) => {

  let newState = state;

  const updateSortOrder = columnType => {
    table[columnType].forEach(col => {
      if (col.sort?.order > sortRemoved) {
        const columnPath = getColumnPath(newState, tablePath, col.name);
        newState = utils.update(newState, `${columnPath}.sort`, { ...col.sort, order: col.sort.order - 1 });
      };
    });
  };

  updateSortOrder('selectors');
  updateSortOrder('values');

  return newState;
};

const updateSortValue = (state, columnPath, action, table, tablePath) => {
  if (!columnPath) {
    return state;
  }
  let newState = state;
  const { column = {}, multiSort } = action;
  if (!multiSort) {
    if (!column.sort) {
      newState = utils.update(newState, `${columnPath}.sort`, { key: column.columnKey, ascending: true, order: 1 });
    } else if (column.sort.ascending) {
      newState = utils.update(newState, `${columnPath}.sort`, { key: column.columnKey, ascending: false, order: 1 });
    } else {
      newState = utils.remove(newState, columnPath, 'sort');
    };
    newState = removeOldSort(newState, table, column.columnKey, tablePath);
  } else {
    const numOfLastSort = getNumOfLastSort(table);
    const col = utils.get(newState, columnPath);
    if (!column.sort) {
      newState = utils.update(newState, `${columnPath}.sort`, { key: column.columnKey, ascending: true, order: numOfLastSort + 1 });
    } else if (column.sort.ascending) {
      newState = utils.update(newState, `${columnPath}.sort`, { ...col.sort, ascending: false });
    } else {
      if (numOfLastSort !== 1) {
        newState = arrangeSortOrder(table, col.sort.order, tablePath, newState);
      };
      newState = utils.remove(newState, columnPath, 'sort');
    };
  };

  return newState;
};

const updateColumnFilter = (state, action) => {
  // console.log('handleColumnFilterChange ===> column=', action.column);
  // console.log('handleColumnFilterChange ===> filter=', action.filter);

  let newState = state;

  const tablePath = `tables[${newState.currentTableIndex}]`;
  const columnPath = getColumnPath(newState, tablePath, action.column.columnKey);
  console.log('handleColumnFilterChange ===> columnPath=', columnPath);
  if (columnPath) {
    const table = utils.get(newState, tablePath);
    newState = makeReplicaTable(newState, table);

    const replicaTablePath = getReplicaTablePath(table.nwid);
    const replicaColumnPath = getColumnPath(newState, replicaTablePath, action.column.columnKey);
    console.log('handleColumnFilterChange ===> replicaColumnPath=', replicaColumnPath);

    newState = updateFilterValue(newState, columnPath, action);
    newState = updateFilterValue(newState, replicaColumnPath, action);
    newState = utils.update(newState, `modifiedTabs[${table.nwid}]`, true);

    newState = filterAndSortTableRows(newState);
  }

  return newState;
};

const removeOldSort = (state, table, columnKey, tablePath) => {
  let newState = state;
  const removeSortFromTableColumns = columnType => table[columnType].forEach(col => {
    if (col.name !== columnKey) {
      const columnPath = getColumnPath(newState, tablePath, col.name);
      newState = utils.remove(newState, columnPath, 'sort');
    };
  });

  removeSortFromTableColumns('selectors');
  removeSortFromTableColumns('values');

  return newState;
};

const updateColumnSort = (state, action) => {

  let newState = state;

  const tablePath = `tables[${newState.currentTableIndex}]`;
  const columnPath = getColumnPath(newState, tablePath, action.column.columnKey);
  console.log('handleColumnSortChange ===> columnPath=', columnPath);
  if (columnPath) {
    const table = utils.get(newState, tablePath);
    newState = makeReplicaTable(newState, table);

    const replicaTablePath = getReplicaTablePath(table.nwid);
    const replicaColumnPath = getColumnPath(newState, replicaTablePath, action.column.columnKey);
    console.log('handleColumnFilterChange ===> replicaColumnPath=', replicaColumnPath);

    const replicaTable = utils.get(newState, replicaTablePath);
    newState = updateSortValue(newState, columnPath, action, table, tablePath);
    newState = updateSortValue(newState, replicaColumnPath, action, replicaTable, replicaTablePath);
    newState = utils.update(newState, `modifiedTabs[${table.nwid}]`, true);

    newState = filterAndSortTableRows(newState);
  }

  return newState;
};

const getTableFilters = (table) => {
  if (!table) {
    return [];
  }

  const reduceFilters = (table, columnType) => {
    return table[columnType].reduce((acc, col) => {
      if (col.filter) {
        acc.push({
          columnType,
          filterType: helpers.getFilterType(col.fieldType),
          columnName: col.name,
          filter: col.filter
        });
      }

      return acc;
    }, []);
  };

  return reduceFilters(table, 'selectors').concat(reduceFilters(table, 'values'));
};

const getRowValue = (state, rowValue, type) => {
  let updatedRowValue = rowValue;
  if (type === 'flowstep') {
    const flowStep = appUtils.composeFullName(state.flowStepsByNwid, rowValue).split('/');
    updatedRowValue = flowStep[flowStep.length - 1];
  } else if (type === 'flowsteps') {
    const flowStepsNwids = rowValue.split(';');
    const flowStepsArr = flowStepsNwids.map(nwid => appUtils.composeFullName(state.flowStepsByNwid, nwid).split('/'));
    const flowSteps = flowStepsArr.map(fs => fs[fs.length - 1]);
    updatedRowValue = flowSteps.join(';');
  } else if (type === 'resources') {
    const resourcesNwids = rowValue.split(',');
    const resourcesNames = resourcesNwids.map(nwid => {
      let name = '';
      const resource = state.resources.find(r => r.nwid === nwid);
      if (resource) {
        name = resource.name;
      };

      return name;
    });
    updatedRowValue = resourcesNames.join(',');
  } else if (type === 'securityGroup') {
    const securityGroupNwids = rowValue.split(',');
    const securityGroupNames = securityGroupNwids.map(nwid => {
      let name = '';
      const securityGroup = state.folderSecurityGroups.find(sg => sg.nwid === nwid);
      if (securityGroup) {
        name = securityGroup.name;
      };

      return name;
    });
    updatedRowValue = securityGroupNames.join(',');
  };

  return updatedRowValue;
};

const filterTableRows = (state, filters) => {
  if (filters.length === 0) {
    return state;
  };

  let newState = state;

  const tablePath = `tables[${newState.currentTableIndex}]`;
  const table = utils.get(newState, tablePath);

  let match = true;

  const rows = table.rows.filter(row => {
    for (const filter of filters) {
      const rowValue = getRowValue(state, row[filter.columnType][filter.columnName], filter.filterType);
      if (filter.filterType === 'date') {
        match = checkDateCondition(rowValue, filter.filter);
      } else if (filter.filterType === 'number') {
        match = checkNumberCondition(rowValue, filter.filter);
      } else {
        match = checkTextCondition(rowValue, filter.filter);
      };
      if (!match) {
        break;
      }
    }

    return match;
  });

  newState = utils.update(newState, `${tablePath}.rows`, rows);

  return newState;
};

const getColumnsToSort = table => {

  const reduceColumnsToSort = columnType => {
    return table[columnType].reduce((acc, col) => {
      if (col.sort) {
        acc.push({ ...col, columnType });
      };

      return acc;
    }, []);
  };

  const sortValueGetter = item => item.sort.order;
  const columnsToSort = reduceColumnsToSort('selectors')
    .concat(reduceColumnsToSort('values'))
    .sort(createObjectComparator(sortValueGetter, COMPARE_TYPE.NUMBERS));

  return columnsToSort;
};

const sortTableRows = (state, columnsToSort) => {
  if (columnsToSort.length === 0) {
    return state;
  };

  let newState = state;

  const tablePath = `tables[${newState.currentTableIndex}]`;
  const table = utils.get(newState, tablePath);

  const sortValueGetter = (colIndex, sortType) => item => {
    const rowValue = getRowValue(state, item[columnsToSort[colIndex].columnType][columnsToSort[colIndex].sort.key], sortType);

    return rowValue;
  };
  const comparator = composeComparators(columnsToSort.map((col, index) => {
    const sortType = helpers.getSortType(col.fieldType);
    const compareType = sortType === 'date' ? COMPARE_TYPE.DATES
      : sortType === 'number' ? COMPARE_TYPE.NUMBERS
        : COMPARE_TYPE.CASE_INSENSITIVE;
    return createObjectComparator(sortValueGetter(index, sortType), compareType, col.sort.ascending);
  }));

  const rows = [...table.rows].sort(comparator);

  newState = utils.update(newState, `${tablePath}.rows`, rows);

  return newState;
};

const updateSortAndFilterMode = (state, action) => {
  let newState = state;
  const tablePath = `tables[${newState.currentTableIndex}]`;
  const table = utils.get(newState, tablePath);
  if (!table) {
    return newState;
  };
  newState = makeReplicaTable(newState, table);
  const replicaTablePath = getReplicaTablePath(table.nwid);
  newState = utils.update(newState, `${tablePath}.sortAndFilterEnabled`, action.sortAndFilterEnabled);
  newState = utils.update(newState, `${replicaTablePath}.sortAndFilterEnabled`, action.sortAndFilterEnabled);
  newState = utils.update(newState, `modifiedTabs[${table.nwid}]`, true);
  newState = filterAndSortTableRows(newState);

  return newState;
};

module.exports = function (state = {}, action = {}) {
  switch (action.type) {
    case 'ADD_TABLE':
      return addTable(state, action);
    case 'ADD_TABLE_BY_DEF':
      return addTableByDef(state, action);
    case 'REMOVE_TABLE':
      return removeTable(state, action);
    case 'UPDATE_CURRENT_TABLE':
      return updateCurrentTable(state, action);
    case 'UPDATE_CURRENT_TABLE_NAME':
      return updateCurrentTableName(state, action);
    case 'UPDATE_CURRENT_TABLE_VALUE':
      return updateTable(state, action);
    case 'UPDATE_CURRENT_COLUMN':
      return updateCurrentColumn(state, action);
    case 'UPDATE_CURRENT_COLUMN_NAME':
      return updateCurrentColumnName(state, action);
    case 'UPDATE_CURRENT_COLUMN_PROPERTY':
      return updateCurrentColumnProperty(state, action);
    case 'ADD_COLUMN':
      return addColumn(state, action);
    case 'REMOVE_CURRENT_COLUMN':
      return removeCurrentColumn(state, action);
    case 'UPDATE_COLUMN_POSITION':
      return updateColumnPosition(state, action);
    case 'ADD_DROPDOWN_OPTION':
      return addDropdownOption(state, action);
    case 'CHANGE_DROPDOWN_PROP':
      return changeDropdownProp(state, action);
    case 'REMOVE_DROPDOWN_OPTION':
      return removeDropdownOption(state, action);
    case 'UPDATE_CELL':
      return updateCell(state, action);
    case 'ADD_ROW':
      return addRow(state, action);
    case 'REMOVE_ROW':
      return removeRow(state, action);
    case 'CLEAN_CHANGED':
      return cleanChanged(state);
    case 'SET_VIEW_MODE':
      return setViewMode(state, action);
    case 'SAVE_NEW_TABLES_NWID':
      return saveNewTablesNwid(state, action);
    case 'INIT_STATE':
      return initState(state, action);
    case 'DUPLICATE_TABLE':
      return duplicateTable(state, action);
    case 'SET_USER_PREFERENCES':
      return setUserPreferences(state, action);
    case 'UPDATE_SAVED_TABLES_OLD_NAME':
      return updateSavedTablesOldName(state, action);
    case 'UPDATE_COLUMN_FILTER':
      return updateColumnFilter(state, action);
    case 'SORT_COLUMN_CHANGE':
      return updateColumnSort(state, action);
    case 'UPDATE_SORT_AND_FILTER_MODE':
      return updateSortAndFilterMode(state, action);
    default:
      return state;
  }
};
