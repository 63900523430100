import React, { Component } from 'react';
import Rule from './Rule';
import Header from 'src/components/common/headers/Header';
import PropTypes from 'prop-types';

module.exports = class Normalization extends Component {

  constructor(props) {
    super(props);
    this.renderRule = this.renderRule.bind(this);

  }
  static propTypes = {
    normalizationInfo: PropTypes.array,
    title: PropTypes.string,

  };

  renderRule = (rule, index) => {
    return <Rule key={index} rule={rule}></Rule>;
  };

  render() {
    const { title, normalizationInfo = [] } = this.props;

    return <div className='normalization-custom-info'>
      <Header>{title}</Header>
      {normalizationInfo.map(this.renderRule)}
    </div>;
  }

};