/**
 * @name Tick Facade
 * @fileOverview
 * @author sergey
 */
define(['core/workers/tickWorker', 'core/workers/channelWorker'],
function (tickWorker, channelWorker) {
  'use strict';
  return {
    _name: 'tick',
    _type: 'facade',
    startChannel: function () {
      return channelWorker.startChannel.apply(channelWorker, arguments);
    },
    stopChannel: function () {
      return channelWorker.stopChannel.apply(channelWorker, arguments);
    },
    addProjector: function () {
      return tickWorker.addProjector.apply(tickWorker, arguments);
    },
    removeProjector: function () {
      return tickWorker.removeProjector.apply(tickWorker, arguments);
    },
    replaceProjector: function() {
      return tickWorker.replaceProjector.apply(tickWorker, arguments);
    }
  };
});