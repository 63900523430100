import sandbox from 'sandbox';
import OpenDialogAction from './OpenDialogAction';

const t = sandbox.localization.translate;

module.exports = OpenDialogAction.extend({
    dialogAllowedMessage: function (rootId, obj) {
         const rest = sandbox.request.rest(rootId);
        return rest.get(`creeping/dialog_not_allowed_message/${obj.nwid}`, {dataType: 'text'}, 'v2')
    }
    
});
