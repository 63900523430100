/**
 * Created by elad on 03/04/2018.
 */
define(['sandbox', './ServerAction', 'actions/actionUtils'], function (sandbox, ServerAction, actionUtils) {
  'use strict';

  return ServerAction.extend({

    isApplicable: function (objs) {
      if (typeof objs === "undefined" || objs.length === 0) {
        return false;
      }
      let isSkip = false;
      let flowSteps = this.config.flowStepNwIds || [];
      let unApplicableObj = objs.filter(function(o) {
        let skipState;
        skipState = (o.skipSteps === "true");
        if (typeof skipState === "undefined") {
          skipState = false;
        }
        if (skipState) {
          let skipStepsInfo = o.skipStepIds || {};
          if (skipStepsInfo.skippedSteps){
            let skipSteps = skipStepsInfo.skippedSteps.map (s => s.nwid) || [];
            let intersection = flowSteps.filter( x => skipSteps.includes(x));
            return (intersection.length === 0);
          }          
        }
        return (isSkip === skipState);
      });
      return (unApplicableObj.length === 0);
    }
  });
});
