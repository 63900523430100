import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'components/common/buttons/SvgIcon';
import TextInput from 'components/common/inputs/TextInput';
import { classNames } from 'utilities/classNames';

export const SearchInput = forwardRef(
  function SearchInput({ className, style, ...props }, ref) {
    return (
      <div className={classNames('crtx-search-input', className)} style={style}>
        <SvgIcon
          key='search'
          name='search'
          className={classNames('crtx-search-input-icon')}
        />
        <TextInput
          ref={ref}
          className={classNames('crtx-search-input-filter')}
          autoFocus={true}
          {...props}
        />
      </div>
    );

  }
);

SearchInput.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
};
