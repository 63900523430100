/**
 * Created by tzvikas on 5/20/2015.
 */

define(['sandbox', 'ember', 'text!../templates/book.hbs', './Zone','kendo-ember', './../../../utils/wizardCommons'],
  function (sandbox, Ember, MainTemplate, zoneView, kendoComponents, wizardCommons) {
    'use strict';

    return Ember.View.extend(wizardCommons.viewCommons, {
      onActivate: function(){

      },

      template: Ember.Handlebars.compile(MainTemplate),
      
      imgDisplay: function () {
        var zones = this.model.zones;
        var dinkyError = false;
        var zonesNames = [];
        for (var i=0; i<zones.length; i++){
          var zoneDinkyState = zones[i].dinkyState;
          if (!zoneDinkyState){
            dinkyError = true;
            //zonesNames += zones[i].displayName + " ";
            zonesNames.push(zones[i].displayName);
          }
        }
        return dinkyError ? "visibility:visible" : "visibility:hidden";
      }.property("model.dinkyState"),

      errImgSrc : function (){
        return sandbox.icons.getModuleIcon("PlanningWizard", "error");
      }.property(),

      
      clazzName: function () {
        return "clazz" + this.model.displayName;
      }.property(),

      zoneView: zoneView,

      zones: function (){
        //return this.model.zones;
        var zones = this.model.zones;
        var that = this;
        var tabs = zones.map(function(zone){
          return {
            viewClass: that.get("zoneView"),
            text: zone.displayName,
            viewInitObj: {
              model: zone,
              controller: that.controller
            }
          };
        });
        return tabs;
      }.property(),

      dinkyState : function () {
        console.log (this);
        var zones = this.model.zones;
        var dinkyError = false;
        var zonesNames = [];
        for (var i=0; i<zones.length; i++){
          var zoneDinkyState = zones[i].dinkyState;
          if (!zoneDinkyState){
            dinkyError = true;
            //zonesNames += zones[i].displayName + " ";
            zonesNames.push(zones[i].displayName);
          }
        }
        
        if (dinkyError){          
          return " Number of forms is not legal in " + (zonesNames.length == 1 ? "zone " : "zones ") + zonesNames.join();
        }
        
        return "";
      }.property('model.dinkyState')



    });
  });