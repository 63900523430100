import React, { Component } from 'react';
import ToastItem from './ToastItem';
import PropTypes from 'prop-types';

const isFunction = o => typeof o === 'function';

class ToastsList extends Component {
  constructor(props) {
    super(props);
    this.renderToast = this.renderToast.bind(this);
    this.renderToasts = this.renderToasts.bind(this);
    this.onToastClose = this.onToastClose.bind(this);
    this.onHideComplete = this.onHideComplete.bind(this);
  }

  onToastClose(toast) {
    return (containerName, id) => {
      if (isFunction(toast.onClose)) toast.onClose();
      this.props.store.dispatch({
        type: 'HIDE_TOAST',
        container: containerName,
        id: id
      });
    };
  }

  onHideComplete(containerName, id) {
    this.props.store.dispatch({
      type: 'REMOVE_TOAST',
      container: containerName,
      id: id
    });
  }

  renderToast(toast) {
    let { store } = this.props;
    return (<li key={toast.id}>
      <ToastItem onClick={this.onToastClick}
        container={toast.containerName}
        id={toast.id}
        type={toast.type}
        title={toast.title}
        message={toast.message}
        imageUrl={toast.imageUrl}
        visible={toast.visible}
        delay={toast.delay}
        onClick={toast.onClick}
        onClose={this.onToastClose(toast)}
        onHideComplete={this.onHideComplete}
      />
    </li>);
  }

  renderToasts(toasts) {
    return toasts.map(this.renderToast);
  }

  render() {
    const { toasts } = this.props;
    return (<ul>
      {this.renderToasts(toasts)}
    </ul>);
  }

}

ToastsList.propTypes = {
  name: PropTypes.string,
  toasts: PropTypes.array,
  store: PropTypes.any
};
ToastsList.defaultProps = {
  toasts: []
};


module.exports = ToastsList;