import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class SelectedElement extends Component {
  static contextTypes = {
    controller: PropTypes.object
  };

  static defaultProps = {
    model: {},
    style: {}
  };

  state = {
    selected: false
  };

  isSelected = (model) => {
    var selectedItems = this.context.controller.getSelectedItems();

    if (this.state.selected || typeof selectedItems[model.id] !== 'undefined') {
      return true;
    }
    return false;
  };

  getClassNames = (model) => {
    var classNames = []
    if (this.isSelected(model)) {
      classNames.push('Selected');
    }
    return ' ' + classNames.join(' ');
  };

  getSelectedStyle = (model) => {
    var style = this.props.style;
    if (this.isSelected(model)) {
      return style;
    }
    return undefined;
  };

  render() {
    var model = this.props.model,
      style = this.getSelectedStyle(model),
      classNames = this.getClassNames(model);

    return <div className={"SelectableElement" + classNames} style={style}>
    </div>;
  }

  onSelectionChanged = (selectedItems, selectedItemsParent) => {
    if (typeof selectedItems[this.props.model.id] === 'undefined' || this.props.model.__parent !== selectedItemsParent) {
      this.setState({
        selected: false
      });
      return;
    };

    this.setState({
      selected: true
    });
  };

  componentDidMount() {
    var ctrl = this.context.controller;

    ctrl.registerSelectionChangedHandler(this.props.model.id, this.onSelectionChanged, this);
  }

  componentWillUnmount() {
    var ctrl = this.context.controller;

    ctrl.removeSelectionChangedHandler(this.props.model.id);
  }
}