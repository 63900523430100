import { min, max } from 'utilities/array';

export const STATUS = {
  IN_BIN: 'inBin',
  IN_PROGRESS: 'inProgress',
  MISSING: 'missing',
  ERROR: 'error'
};

export const PLATE_SIZE = {
  SINGLE: 'SINGLE',
  DOUBLE: 'DOUBLE',
  BOTH: 'BOTH',
};

export const BIN_SIZE = {
  SINGLE: 'single',
  DOUBLE: 'double',
  BOTH: 'both',
};

export const NO_SORTER = 'no sorter';

const CLASS_NAMES_BY_STATUS = {
  [STATUS.IN_BIN]: 'in-bin-plates',
  [STATUS.IN_PROGRESS]: 'in-progress-plates',
  [STATUS.MISSING]: 'missing-plates',
  [STATUS.ERROR]: 'error-plates'
};

const BIN_WIDTH = 50;
const BIN_HEIGHT = 100;
const BIN_BORDER_WIDTH = 1;
const BIN_MARGIN = 4;

const MIN_BIN_POSITION = 0;
const MAX_BIN_POSITION = 100;

export const getBinWidth = (scale = 1, double = false) => {
  return Math.floor(double ? scale * 2 * (BIN_WIDTH + BIN_BORDER_WIDTH + BIN_MARGIN) : scale * BIN_WIDTH);
};

export const getBinHeight = (scale = 1) => {
  return Math.floor(scale * BIN_HEIGHT);
};

export const getBinMargin = (scale = 1) => {
  return Math.floor(scale * BIN_MARGIN);
};

export const getPlateStatus = (plate) => {
  let status = STATUS.MISSING;

  const plateStatus = plate.completionState;
  const plateCount = parseInt(plate.regularBinCounter || 0, 10);

  if (plate.isSetComplete === 'true') {
    status = STATUS.IN_BIN;
  } else if (plateStatus === 'none') {
    status = STATUS.MISSING;
  } else if (plateCount > 0) {
    status = STATUS.IN_BIN;
  } else {
    status = plateStatus === 'error' ? STATUS.ERROR : STATUS.IN_PROGRESS;
  }

  return status;
};

export const getPlateClassName = (status) => {
  return CLASS_NAMES_BY_STATUS[status];
};

export const calcStartPosition = (bins) => {
  const start = min(bins, bin => {
    const pos = toIntPosition(bin.position);

    return isValidPosition(pos) ? pos : NaN;
  });

  return start;
};

export const calcEndPosition = (bins) => {
  const end = max(bins, bin => {
    const pos = toIntPosition(bin.position);

    return isValidPosition(pos) ? pos : NaN;
  });

  return end;
};

export const isValidPosition = (position) => {
  const pos = toIntPosition(position);

  return !isNaN(pos) && pos >= MIN_BIN_POSITION && pos <= MAX_BIN_POSITION;
};

export const toIntPosition = (position) => {
  return isNaN(position) ? NaN : parseInt(position, 10);
};

export const isDoubleSizeBin = (binSize, plateSize) => {
  return binSize === BIN_SIZE.DOUBLE || binSize === BIN_SIZE.BOTH && plateSize === PLATE_SIZE.DOUBLE;
};

export const isSizeMismatch = (binSize, plateSize) => {
  return plateSize === PLATE_SIZE.BOTH ||
    binSize === BIN_SIZE.SINGLE && plateSize === PLATE_SIZE.DOUBLE ||
    binSize === BIN_SIZE.DOUBLE && plateSize === PLATE_SIZE.SINGLE;
};

export const arrayTrimEnd = (array = []) => {
  const lastIdx = array.findLastIndex(item => !!item);

  return lastIdx === array.length - 1 ? array : array.slice(0, lastIdx + 1)
};
