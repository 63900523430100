/**
 * @name idWorker
 * @fileOverview
 * @namespace
 * @author
 */

define(function () {
  'use strict';

  var counter = 0;

  /**
  * Generating unique IDs
  * The identifiers generated by this function are unique on the host on which they are generated
  */
  function generateId() {
    return ++counter;
  }

  return {
    generateId: generateId
  };

});


