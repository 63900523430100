const React = require('react');
const PropTypes = require('prop-types');
const ListItem = require('./listItem');

const { Component } = React;

module.exports = class ListItems extends Component {
  static propTypes = {
    items: PropTypes.array,
    onDoubleClick: PropTypes.func,
    viewStyle: PropTypes.string,
    onClick: PropTypes.func,
    selectedItem: PropTypes.object
  }

  constructor(props) {
    super(props);
  }

  handleDoubleClick = (event, item) => {
    this.props.onDoubleClick(event, item, 'add');
  };

  handleClick = (event, selectedItem) => {
    this.props.onClick(event, selectedItem);
  };

  renderItem = (item, index) => {
    const viewStyle = this.props.viewStyle;
    const selectedItem = this.props.selectedItem;
    const isSelected = typeof selectedItem !== 'undefined' && selectedItem.name === item.name ? true : false;
    if (item.type === 'folder' || item.type === 'disk') {
      return <ListItem key={index} item={item} onDoubleClick={this.handleDoubleClick} viewStyle={viewStyle} onClick={this.handleClick} isSelected={isSelected} />
    }
    return <ListItem key={index} item={item} viewStyle={viewStyle} onClick={this.handleClick} isSelected={isSelected} />
  };

  render() {
    const items = this.props.items;
    const viewStyle = this.props.viewStyle;
    const selectedItem = this.props.selectedItem;

    return <ul className='crtx-list-items'>
      {items.map(this.renderItem)}
    </ul>;
  }
}