import React, { useState, useEffect, useRef, Fragment } from "react";
import { translate } from 'core/services/localization';
import { restGet } from 'core/managers/rest2';
import VirtualLines from './VirtualLines';
import { render } from "../../../core/services/toastService";

const FETCH_DELAY_AFTER_SCROLL = 2000;
const FETCH_DELAY_AFTER_LOAD = 4000;

const FileContent = ({ fileName, modified, actionNwid }) => {
  const [loading, setLoading] = useState(false);
  const [fileLines, setFileLines] = useState([]);
  const [failedFetch, setFailedFetch] = useState(false);
  const [failErrMsg, setFailErrMsg] = useState('');
  const lastScrollTime = useRef(0);
  const lastLoadTime = useRef(0);
  const autoScroll = useRef(false);
  const prevFileName = useRef('');
  const prevModified = useRef('');


  function splitFileContent(content) {
    if (!content) {
      return [];
    }

    // split by lines preserving newlines (\n)
    const lines = content.match(/([^\r\n]*(?:\r?\n)|[^\r\n]+)/g) || [];

    return lines;
  }

  function fetchFileContent() {
    restGet(actionNwid, `/file-viewer/files/${fileName}`, { modified: modified })
      .then(data => {
        if (prevFileName.current === fileName && prevModified.current === modified) {
          const contentLines = splitFileContent(data);
          setFileLines(contentLines);
          setFailedFetch(false);
        }

      }).catch(err => {
        setFailedFetch(true);
        setFailErrMsg(err.message);

      }).finally(() => {
        setLoading(false);
        lastLoadTime.current = Date.now();
      });
  }

  useEffect(() => {
    if (prevFileName.current !== fileName) { // new file selection
      autoScroll.current = true;
      prevFileName.current = fileName;
      prevModified.current = modified;
      setLoading(true);
      fetchFileContent();

    } else if (!failedFetch && prevModified.current !== modified) { // same file updated
      const scrollElapsed = Date.now() - lastScrollTime.current;
      const loadElapsed = Date.now() - lastLoadTime.current;

      if (!loading && (scrollElapsed > FETCH_DELAY_AFTER_SCROLL) && (loadElapsed > FETCH_DELAY_AFTER_LOAD)) {
        prevModified.current = modified;
        fetchFileContent();
      }
    }
  });

  function renderFailMsg() {
    return (
      <div id='msg-container' className="file-viewer-msg-container" >
        <div className='file-viewer-err-msg'>
          {translate('Failed to fetch content.',)}
        </div>
        {failErrMsg && <div>{translate(failErrMsg)}</div>}
      </div>);
  }


  function onScrollList(isScrollBarDown) {
    lastScrollTime.current = Date.now();
    autoScroll.current = isScrollBarDown;
  }

  function spinner() {
    return (
      <div className="file-viewer-spinner-container">
        <img className="loadingIcon" src="../kernel/assets/img/sys/ajax-loader-big.gif" alt="Waiting" />
      </div>);
  }

  if (loading){
    return spinner();
  }
  if (failedFetch){
    return renderFailMsg();
  }
  return <VirtualLines lines={fileLines} onScrollList={onScrollList} autoScroll={autoScroll.current} />;
};

export default FileContent;