import {COLUMNS_DEFINITIONS, TEMPLATE_GROUP} from "./Constants";

const Constants = require('./Constants');
import sandbox from 'sandbox';
const { translate } = sandbox.localization;
import dialogService from 'core/services/dialogService'

function addTemplates (state, action) {
  let rows = [];
  let templates = action[Constants.TEMPLATES];
  templates.sort(function(t1, t2) {
    return t1[Constants.NAME].localeCompare(t2[Constants.NAME]);
  });
  templates.forEach(function(t) {
     let templateName = t[Constants.NAME];
     let configType = t.configType;
     let sections = t[Constants.SECTIONS];
     let groupId = templateName + "^" + configType;
     rows.push({
       [Constants.TEMPLATE_GROUP]: templateName,
       [Constants.UID]: groupId,
       [Constants.GROUP_ID]: groupId,
       [Constants.IS_TEMPLATE]: true,
       [Constants.TEMPLATE_NAME]: templateName,
       [Constants.CONFIG_TYPE]: configType,
       [Constants.SELECTED]: false
     });
     sections.forEach(function(s) {
        let sectionName = s[Constants.NAME];
        let NOfHeatsetPages = s[Constants.NUMBER_OF_HEAT_PAGES];
        let configType = s[Constants.CONFIG_TYPE];
        let numPages = s[Constants.NUM_PAGES];
        let printRunType = s[Constants.PRINT_RUN_MODE];
        let format = s[Constants.FORMAT];
        rows.push({
          [Constants.TEMPLATE_GROUP]: templateName,
          [Constants.UID]: groupId + "_" + sectionName,
          [Constants.GROUP_ID]: groupId,
          [Constants.SECTION_NAME]: sectionName,
          [Constants.NUMBER_OF_HEAT_PAGES]: NOfHeatsetPages,
          [Constants.NUM_PAGES]: numPages,
          [Constants.PRINT_RUN_MODE]: printRunType,
          [Constants.FORMAT]: format,
          [Constants.SELECTED]: false
        });
     });
  });
  return {
    ...state,
    [Constants.TEMPLATES]: rows,
    [Constants.FILTERED]: rows,
    [Constants.CREATE_TEMPLATE_LINK]: action[Constants.CREATE_TEMPLATE_LINK],
    [Constants.MODULE_NWID]: action[Constants.MODULE_NWID]
  };
}

function changeSelection(state, action) {
  let templates = state[Constants.FILTERED];
  let index = action.rowIndex
  let row = templates.slice(index, index + 1)[0];
  row[Constants.SELECTED] = !row[Constants.SELECTED];
  return [
    ...templates.slice(0, index),
    row,
    ...templates.slice(index+1)
  ];
}

function applyFilter(state, action) {
  let filteredTemplates;
  let value =  action[Constants.VALUE];
  if (value !== "") {
    filteredTemplates = state[Constants.TEMPLATES].filter(function(t) {
      return t[Constants.GROUP_ID].toLowerCase().startsWith(value.toLowerCase());
    })
  } else {
    filteredTemplates = state[Constants.TEMPLATES];
  }
  return filteredTemplates;
}

function applyRemoveTemplates(state, action) {
  let deleted = state[Constants.TEMPLATES].filter(function(t) {
    return t[Constants.SELECTED];
  });
  let templates = state[Constants.TEMPLATES];
  let filtered = state[Constants.FILTERED];
  if (deleted.length > 0) {
    let deletedNames = deleted.map(d => d[Constants.TEMPLATE_NAME]);
    let newTemplates = templates.filter( t => !(deletedNames.includes(t[Constants,TEMPLATE_GROUP])));
    let newFiltered = filtered.filter( t => !(deletedNames.includes(t[Constants,TEMPLATE_GROUP])));
    return [newTemplates, newFiltered];
  } else {
    return [templates, filtered];
  }
}

function applyRenameTemplate(state, action) {
  let renamed  = state[Constants.TEMPLATES].filter(t => t[Constants.TEMPLATE_GROUP] === action[Constants.OLD_NAME]);
  let newName = action[Constants.NEW_NAME];
  let renamedTemplate =  state[Constants.TEMPLATES].filter(t => t[Constants.TEMPLATE_NAME] === action[Constants.OLD_NAME]);
  let groupId = newName + "^" + renamedTemplate[0][Constants.CONFIG_TYPE];
  renamed.forEach(function(r) {
        if (r[Constants.IS_TEMPLATE]) {
            r[Constants.TEMPLATE_NAME] = newName;
            r[Constants.TEMPLATE_GROUP] = newName;
            r[Constants.UID] = groupId;
            r[Constants.GROUP_ID] = groupId;
        }  else {
            r[Constants.TEMPLATE_GROUP] = newName;
            r[Constants.UID] = groupId + "_" + r[Constants.SECTION_NAME];
            r[Constants.GROUP_ID] = groupId;
        }
  });
  return state;
}

function  sortColumn(state, action) {
  let column = action[Constants.NAME];
  let sortables = action[Constants.SORTABLES];
  if (!sortables.includes(column)) {
    return state;
  }
  let columnDefinition = action[Constants.COLUMNS_DEFINITIONS].filter(d => d[Constants.NAME] === column)[0];
  let direction = columnDefinition[Constants.SORT_DIRECTION];
  columnDefinition[Constants.SORT_DIRECTION] = direction === "asc" ? "desc" : "asc";
  let templatesOnly = state[Constants.FILTERED].filter(t => t[Constants.IS_TEMPLATE]);
  let comparatorName = direction === "asc" ? "caseinsensitivedecending" : "caseinsensitive";
  let sortedTemplatesOnly = sandbox.sorting.sort(templatesOnly, comparatorName, column);
  let sortedState = [];
  sortedTemplatesOnly.forEach(function(t) {
    let rows = state[Constants.FILTERED].filter( r => r[Constants.TEMPLATE_GROUP] === t[Constants.TEMPLATE_GROUP])
    sortedState = sortedState.concat(rows);
  });
  return {
    ...state,
    [Constants.FILTERED] : sortedState
  }
}

module.exports = (state = {}, action) => {
  switch (action[Constants.TYPE]) {
    case Constants.ADD_TEMPLATES:
      return addTemplates.apply(this, [state, action]);
    case Constants.CHANGE_SELECTION:
      return Object.assign({}, state, {[Constants.TEMPLATES]: state[Constants.TEMPLATES]}, {[Constants.FILTERED]: changeSelection.apply(this, [state, action])});
    case Constants.FILTER:
      return {...state, [Constants.FILTERED]: applyFilter.apply(this, [state, action]), [Constants.TEMPLATES]: state[Constants.TEMPLATES], [Constants.FILTER]: action[Constants.VALUE]};
    case Constants.REMOVE_TEMPLATES:
        let news = applyRemoveTemplates.apply(this, [state, action]);
        return Object.assign({}, state, {[Constants.TEMPLATES]: news[0]}, {[Constants.FILTERED]: news[1]});
    case Constants.RENAME_TEMPLATE:
      action[Constants.VALUE] = state[Constants.FILTER];
      return {...applyRenameTemplate.apply(this, [state, action]), [Constants.FILTERED]: applyFilter.apply(this, [state, action])};
    case Constants.SORT_COLUMN:
      return sortColumn.apply(this, [state, action]);
    default:
      return state;
  }
};