import base from 'base';

export const MODULE_CONTAINER_CLASS = 'crtx-module-container';

export const selectorFromId = id => {
  return `#${id}`;
};

export const getModuleId = contentElement => {
  return extractModuleId(getModuleContainerId(contentElement));
};

export const getModuleContainerId = contentElement => {
  const moduleContainer = contentElement ? base.dom.find(`.${MODULE_CONTAINER_CLASS}`, contentElement)[0] : null;
  return moduleContainer ? moduleContainer.id : '';
};

export const extractModuleId = moduleSelector => {
  return !moduleSelector || moduleSelector.indexOf('-') < 0 ? -1 : Number(moduleSelector.split('-')[1]);
};
