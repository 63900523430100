import sandbox from 'sandbox';

const hasClass = (el, selector) => {
  let className = ' ' + selector + ' ';

  if ((' ' + el.className + ' ').replace(/[\n\t]/g, ' ').indexOf(className) > -1)
    return true;

  return false;
};

const getNodeSize = (node) => {
  return {
    height: node.clientHeight,
    width: node.clientWidth
  };
};

const getHeaderColumn = (
  tableNode, /*DOMNode - the table DOMNode*/
  columnKey /*string - the wanted column key*/
) => {
  let foundHeaderColumns = sandbox.dom.find("[data-columnkey='" + columnKey + "']", tableNode)

  return foundHeaderColumns.length > 0 ? foundHeaderColumns[0] : null;
};

export default {
  hasClass,
  getNodeSize,
  getHeaderColumn
};