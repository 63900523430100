const React = require('react');
const PropTypes = require('prop-types');

const styles = {
  row: { height: 20 },
};

const { Component } = React;

module.exports = class LockEventsSetup extends Component {

  static propTypes = {
    model: PropTypes.any,
    selected: PropTypes.any,
    onChange: PropTypes.func
  };
  constructor(props) {
    super(props);
    this.state = {
      selected: 'res2'
    };
    this.onChange = this.onChange.bind(this);
    this.renderRadioButtons = this.renderRadioButtons.bind(this);
  }

  onChange(ev) {
    this.props.onChange(ev.target.value);
    // this.setState({
    //   selected: ev.target.value
    // });
  }

  renderRadioButtons(availableResources, selected) {
    return availableResources.map(availableResource => {
      const resourceName = availableResource.name;
      return <div key={availableResource.nwid} className='crtx-form-one-column' style={styles.row}>
        <label className='crtx-form-label-with-checkbox'>
          <input style={styles.input} type="radio" value={resourceName} checked={selected === resourceName} onChange={this.onChange} />
          {resourceName}
        </label>
      </div>;
    });
  }

  render() {
    var model = this.props.model;
    var selected = this.props.selected;

    return <div className='crtx-form-section-container'>
      {this.renderRadioButtons(model.availableResources, selected)}
    </div>;
  }

};