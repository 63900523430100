import React, { Component } from 'react';
import SimpleForm from 'widgets/SimpleForm/src/index';
import SortableList from './SortableList';
import PropTypes from 'prop-types';

const { SimpleFormComponent } = SimpleForm;

const emptyFunction = function () { };

module.exports = SimpleFormComponent(class index extends Component {
  constructor(props) {
    super(props);
    // this.handleItemClick = this.handleItemClick .bind(this);
    // this.isSelected = this.isSelected .bind(this);
    // this.renderItem = this.renderItem .bind(this);
    // this.renderItems = this.renderItems .bind(this);
  }

  static propTypes = {
    store: PropTypes.any,
    bind: PropTypes.string,
    className: PropTypes.string,
    helperClass: PropTypes.string,
    style: PropTypes.object,
    textProp: PropTypes.string,
    valueProp: PropTypes.string,
    selectedValue: PropTypes.any,
    selectedIndex: PropTypes.number,
    onSelect: PropTypes.func,
    onSortEnd: PropTypes.func,
    disabled: PropTypes.bool
  }

  static defaultProps() {
    return {
      onSelect: emptyFunction,
      onSortEnd: emptyFunction
    };
  }

  // handleItemClick(value, index, item) {
  //   return function (ev) {
  //     this.props.onSelect(ev, value, index, item);
  //   }.bind(this);
  // }
  //
  // isSelected(item, index) {
  //   const { valueProp, selectedValue, selectedIndex } = this.props;
  //   const value = isString(item) ? item : item[valueProp];
  //   if (typeof selectedIndex === 'number') return index === selectedIndex;
  //   return value === selectedValue;
  // }
  //
  // renderItem(item, index) {
  //   const { textProp, valueProp, selectedValue } = this.props;
  //   const text = isString(item) ? item : item[textProp];
  //   const value = isString(item) ? item : item[valueProp];
  //   const isSelected = this.isSelected(item, index);
  //   const className = isSelected ? 'selected' : undefined;
  //   return <li key={index} className={className} onClick={this.handleItemClick(value, index, item)}>
  //     {text}
  //   </li>
  // }
  //
  // renderItems(items) {
  //   return items.map(this.renderItem);
  // }
  //
  render() {
    const { store, bind, className, helperClass, style, textProp, valueProp, onSelect, onSortEnd, selectedValue, selectedIndex, disabled, tablePath, columnType } = this.props;
    // const listClassName = ['list', className].join(' ');
    const items = store.get(bind) || [];
    return <SortableList items={items}
      className={className}
      helperClass={helperClass}
      style={style}
      textProp={textProp}
      valueProp={valueProp}
      selectedValue={selectedValue}
      selectedIndex={selectedIndex}
      onSelect={onSelect}
      onSortEnd={onSortEnd}
      disabled={disabled}
      useDragHandle={true}
      tablePath={tablePath}
      columnType={columnType}
    />;
    // return <ul className={listClassName} style={style}>
    //   {this.renderItems(items)}
    // </ul>;
  }

});
