import { DataSource } from 'components/private/planeView';
import sandbox from 'sandbox';

const comparator = sandbox.sorting.getComparator([{ type: 'date', prop: 'deadline' }, { type: 'alpha', prop: 'title' }]);

const getItemIndexAfterChange = (items, page, currentIndex) => {
  if (items.length === 1) {
    return currentIndex;
  }
  else if (currentIndex === 0) {
    if (items.length === 1) return currentIndex;
    if (comparator(page, items.get(currentIndex + 1)) <= 0) return currentIndex;
  }
  else if (currentIndex === items.length - 1) {
    if (items.length === 1) return currentIndex;
    if (comparator(items.get(currentIndex - 1), page) <= 0) return currentIndex;
  }
  else {
    if (comparator(items.get(currentIndex - 1), page) <= 0 && comparator(page, items.get(currentIndex + 1)) <= 0) return currentIndex;
  }
  return items.findIndex(item => comparator(item, page) > 0);
};

const addItem = (state, action) => {
  const index = state.items.findIndex(item => comparator(item, action.item) > 0);

  return {
    ...state,
    items: index < 0 ? state.items.add(action.item) : state.items.addAt(action.item, index)
  };
};

const updateItem = (state, action) => {
  const itemIndex = state.items.findIndex(item => item.nwid === action.item.nwid);
  const itemIndexAfterChange = getItemIndexAfterChange(state.items, action.item, itemIndex);

  if (itemIndex < 0) return addItem(state, action);

  if (itemIndex !== itemIndexAfterChange) {
    if (itemIndexAfterChange < 0) {
      return {
        ...state,
        items: state.items.removeAt(itemIndex).add(action.item)
      };
    }

    return {
      ...state,
      items: state.items.removeAt(itemIndex).addAt(action.item, itemIndexAfterChange > itemIndex ? itemIndexAfterChange - 1 : itemIndexAfterChange)
    };
  }

  return {
    ...state,
    items: state.items.updateAt(action.item, itemIndex)
  };
};

const removeItem = (state, action) => {
  const pageIndex = state.items.findIndex(item => item.nwid === action.nwid);

  return {
    ...state,
    items: pageIndex < 0 ? state.items : state.items.removeAt(pageIndex)
  };
};

const select = (state, action) => {
  const { nwid, selectNwids } = action;

  return {
    ...state,
    selectNwids: selectNwids,
    lastSelectedNwid: nwid
  };
};

const shouldUpdatePaddedItems = (state, { shouldUpdatePaddedItems }) => {
  return {
    ...state,
    items: state.items.setShouldUpdatePaddedItems(shouldUpdatePaddedItems)
  };
};

const changeFilter = (state, { checked, filterName }) => {
  const filterPropToChange = filterName === 'warning_in_preflight' ? 'warning' : 'error';
  return {
    ...state,
    activeFilter: { ...state.activeFilter, [filterPropToChange]: checked ? filterName : '' }
  };
};

export default function (state = {}, action) {
  switch (action.type) {
    case 'addItem': return addItem(state, action);
    case 'updateItem': return updateItem(state, action);
    case 'removeItem': return removeItem(state, action);
    case 'select': return select(state, action);
    case 'shouldUpdatePaddedItems': return shouldUpdatePaddedItems(state, action);
    case 'changeFilter': return changeFilter(state, action);
    default:
      return {
        ...state
      };
  }
}