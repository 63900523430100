import React, {Component} from 'react';
import PropTypes from 'prop-types';

class IndeterminateProgressBar extends Component {

  static propTypes = {
    disabled: PropTypes.bool,
    vertical: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
  };

  static defaultProps = {
    disabled: false,
    vertical: false,
  };

  render() {
    const {vertical, color, style, disabled} = this.props;

    const className = `crtx-indeterminate-progress-bar ${this.props.className} ${disabled ? 'disabled' : ''}`;
    const baseBarClassName = `ipb-base-bar ${vertical ? 'vertical' : ''}`;
    const increaseClassName = `ipb-bar ipb-increase ${vertical ? 'vertical' : ''}`;
    const decreaseClassName = `ipb-bar ipb-decrease ${vertical ? 'vertical' : ''}`;

    return (
      <div className={className} style={style}>
        <div className={baseBarClassName} style={{backgroundColor: color}}/>
        <div className={increaseClassName} style={{backgroundColor: color}}/>
        <div className={decreaseClassName} style={{backgroundColor: color}}/>
      </div>
    )
  }
}

export default IndeterminateProgressBar;
