import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import { getFolderCurrentDate } from 'core/dates';
import { toLocaleShortTime, toLocaleShortDateTime } from 'core/services/localization';
import ProgressBar from 'components/common/progress/ProgressBar';
import jsUtils from 'base/jsUtils';
import qq from 'fine-uploader';

const labels = {
  success: translate('Success'),
  failed: translate('Failed'),
  rejected: translate('Rejected'),
};

export default class FileListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired
  };

  static defaultProps = {};

  calcPercent(item) {
    return item.uploadedSize / item.size * 100;
  }

  handleClick = (e) => {
    const { onItemClick, item } = this.props;

    if (typeof onItemClick == 'function') {
      onItemClick(e, item);
    }
  };

  handleMouseDown = (e) => {
    const { onItemMouseDown, item } = this.props;

    if (typeof onItemMouseDown == 'function') {
      onItemMouseDown(e, item);
    }
  };

  handleContextMenu = (e) => {

    const { onContextMenu, item } = this.props;

    if (typeof onContextMenu == 'function') {
      onContextMenu(e, item);
    }
  };

  handleDragStart = (e) => {
    const { item, onDragStart } = this.props;
    if (onDragStart) {
      onDragStart(e, item);
    }
  };
  handleUploadPageDoubleClick = (e) => {
    const { item, onDoubleClick } = this.props;
    if (onDoubleClick) {
      onDoubleClick(e, item);
    }
  };
  renderIcon() {
    const { item } = this.props;
    if (!item.icon) {
      return null;
    }

    const className = `uv-file-list-item-icon ${item.icon.className || ''}`;

    return (
      <div className={className} style={item.icon.style} />
    );
  }

  renderName() {
    const { item } = this.props;
    let draggable = false;
    let handleDragStart = null;
    if (item.status === 'Unplanned' || item.status === "MultiplePages") {
      draggable = true;
      handleDragStart = this.handleDragStart;
    }

    return (
      <div className='uv-file-list-item-name unselectable'
        title={item.name}
        draggable={draggable}
        onDragStart={handleDragStart}>
        {item.name}
      </div>
    );
  }

  renderExpectedName() {
    const { item } = this.props;

    if (!item.expectedName || item.expectedName === item.name) {
      return null;
    }

    return (
      <div className='uv-file-list-item-name unselectable' title={item.expectedName}>
        {item.expectedName}
      </div>
    );
  }

  renderUploadDate() {
    const d = this.props.item.uploadDate;
    const now = getFolderCurrentDate();
    const formattedDate = d.getDate() === now.getDate() && d.getMonth() === now.getMonth() && d.getFullYear() === now.getFullYear() ?
      toLocaleShortTime(d) : toLocaleShortDateTime(d);

    return (
      <div className='uv-upload-date'>
        {formattedDate}
      </div>
    );
  }

  renderUploadedSize() {
    const { item } = this.props;

    return (
      <div className='uv-uploaded-size'>
        {jsUtils.formatFileSize(item.uploadedSize)}
      </div>
    );
  }

  renderProgress() {
    const { item } = this.props;
    const percent = this.calcPercent(item);
    if (percent < 0) {
      return null;
    }

    return (
      <ProgressBar
        min={0}
        max={100}
        value={percent}
        animated={true} />
    );
  }


  // SUBMITTING: "submitting",
  // SUBMITTED: "submitted",
  // REJECTED: "rejected",
  // QUEUED: "queued",
  // CANCELED: "canceled",
  // PAUSED: "paused",
  // UPLOADING: "uploading",
  // UPLOAD_RETRYING: "retrying upload",
  // UPLOAD_SUCCESSFUL: "upload successful",
  // UPLOAD_FAILED: "upload failed",
  // DELETE_FAILED: "delete failed",
  // DELETING: "deleting",
  // DELETED: "deleted"

  renderStatus() {
    const { item } = this.props;

    let cls = '';
    let title = item.status;
    switch (item.status) {
      //case qq.status.UPLOAD_SUCCESSFUL:
      case 'Unplanned':
        title = labels.success;
        cls = 'uv-status-icon-success';
        break;
      case 'Error':
      case qq.status.UPLOAD_FAILED:
        title = item.message || labels.failed;
        cls = 'uv-status-icon-error';
        break;
      case qq.status.REJECTED:
        title = labels.rejected;
        cls = 'uv-status-icon-rejected';
        break;
    }

    const className = `uv-status-icon ${cls}`;
    return (
      <div className={className} title={title} />
    );
  }

  render() {
    const { item } = this.props;
    const className = `uv-file-list-item ${item.selected ? 'selected' : ''}`;

    return (
      <div className={className}
        onClick={this.handleClick}
        onMouseDown={this.handleMouseDown}
        onContextMenu={this.handleContextMenu}
        onDoubleClick={this.handleUploadPageDoubleClick}
      >
        {this.renderIcon()}
        {this.renderName()}
        {this.renderExpectedName()}
        {this.renderUploadDate()}
        {this.renderUploadedSize()}
        {this.renderProgress()}
        {this.renderStatus()}
      </div>
    );
  }

};
