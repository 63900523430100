
define(['ember'],
  function (Ember) {
    'use strict';

    return Ember.Checkbox.extend({
      //placeholderBinding: this.get('key'),
      _setRemoteValue: (function() {
        var val;
        val = this.get('checked');
        if (val !== null) {
          return this.set("model." + (this.get('key')), val);
        }
      }).observes('checked'),
      didInsertElement: function() {
        var updateCurrentValue;
        updateCurrentValue = (function(_this) {
          return function() {
            var currentValue;
            currentValue = _this.get("model." + (_this.get('key')));
            console.log(currentValue);
            if (currentValue !== null) {
              return _this.set('checked', currentValue);
            }
          };
        })(this);
        updateCurrentValue();
        return this.addObserver("model." + (this.get('key')), updateCurrentValue);
      }
    });
  });