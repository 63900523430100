import React, { useState, useRef } from 'react';
import { Dialog, DialogButtonsBar } from 'components/common/dialogs';
import { translate } from 'core/services/localization';
import { restPost, restGet } from 'core/managers/rest2';
import TextInput from 'components/common/inputs/TextInput';
import CheckBox from 'components/common/inputs/Checkbox';
import CircularProgress from 'components/common/progress/CircularProgress';
import Button from 'components/common/buttons/Button';
import { openConfirmDialog } from 'core/services/dialogService';
import { createToastService } from 'core/services/toastService';
import { Validator, useValidator } from 'components/private/forms';

export const ImportFolderDialog = ({ onClose, importAction }) => {

  const [importZipPath, setImportZipPath] = useState('');
  const [deleteTemporarySchema, setDeleteTemporarySchema] = useState(true);
  const [dryRun, setDryRun] = useState(false);
  const [progress, setProgress] = useState({});
  const [warningMessage, setWarningMessage] = useState('');
  const [importFolderTaskNwId, setImportFolderTaskNwId] = useState();
  const [isSpecificStepProblem, setIsSpecificStepProblem] = useState(false);
  const [importEnabled, setImportEnabled] = useState(true);

  const intervalIdRef = useRef(null);
  const buttons = [
    {
      id: 'yes',
      title: translate('Yes'),
    },
    {
      id: 'no',
      title: translate('No'),
      reject: true
    }
  ];

  const importFolder = (approved) => {
    setProgress({});
    let restProps = approved ? {
      importZipPath: importZipPath,
      dryRun: dryRun,
      keepTemporarySchema: !deleteTemporarySchema,
      purgeExistingFolder: true
    } : {
      importZipPath: importZipPath,
      dryRun: dryRun,
      keepTemporarySchema: !deleteTemporarySchema
    };

    restPost(importAction.nwid, `folders/import`, restProps)
      .then(res => {
        setProgress({ isCancelled: false });
        setImportFolderTaskNwId(res.importFolderTaskNwId);

        intervalIdRef.current = setInterval(() => restGet(importAction.nwid, `folders/importProgress/${res.importFolderTaskNwId}`)
          .then(p => {
            if (p) {
              setProgress({ ...progress, ...p });
              setIsSpecificStepProblem(!!p.Steps.find(step => step.Progress === 1) && p.Status === 'Failed');

              if (p.Steps.every(step => step.Progress === 1) || p.Status === 'Completed' || p.Status === 'Failed') {
                clearInterval(intervalIdRef.current);
              }
            }
          }), 500);
      })
      .catch(err => {
        console.error('Import folder failed');
      });

  };

  const checkIsValid = () => {

    restPost(importAction.nwid, `folders/validateImportFile`, { importZipPath: importZipPath })
      .then(res => {
        if (res.validImportZip) {
          importFolder();
        }
        else {
          if (res.failureCode === -8) {
            openConfirmDialog(getWarningMessageContent(res), translate('Replace Primary Folder Alert'), { buttons })
              .then(res => {
                importFolder(true);
              });
          }
          else {
            setWarningMessage(res.failedMessage);
            setImportEnabled(false);
          }
        }

      }).catch(err => {
        console.error('Failed to validate the import zip file');
      });
  };

  const getWarningMessageContent = (res) => {
    return (
      <div className='crtx-form-section-container'>
        <div className='crtx-form-section'>
          <div className='crtx-form-one-column crtx-form-margin-bottom-xsmall'>
            <div>{translate('You are trying to import the primary folder')}</div>
            <div>{res.importedFolderName}.</div>
            <div className='crtx-form-margin-top-xxsmall'>{translate('This will override your existing {1} folder.', res.importedFolderName)}</div>
            <div>{translate('If you continue, your existing {1} folder will be replaced.', res.importedFolderName)}</div>
            <div className='crtx-form-margin-top-xsmall'>{translate('Are you sure?')}</div>
          </div>
        </div>
      </div>
    );
  };

  const { register, validate } = useValidator();
  const handleImportFolder = () => {
    if (!validate()) {
      return;
    }
    checkIsValid();
  };

  const handleCancel = () => {
    clearInterval(intervalIdRef.current);
    restPost(importAction.nwid, `folders/cancelImport/${importFolderTaskNwId}`)
      .then(res => {
        setProgress({ ...progress, isCancelled: true });
      });
  };

  const handleClose = () => {
    clearInterval(intervalIdRef.current);
    onClose();
  };

  const handleChangeZipPath = (event, value) => {
    setImportEnabled(true);
    setImportZipPath(value);
  };

  const renderFailedMessage = (failedMessage) => {

    const rows = failedMessage?.split('\n');

    if (rows) {
      return <div className='warning-message'>
        {rows.map((row, index) => <div key={index}>{row}</div>)}
      </div>;
    } else {
      return <div className='warning-message'>{translate('Import failed')}</div>;
    }
  };

  const renderSteps = () => {

    return <div className='crtx-progress-steps'>{progress.Steps.map(renderStep)}</div>;
  };

  const renderStep = (step, index) => {

    return <div className='crtx-export-import-step' key={'step' + index}>
      <div className='crtx-status-indicator'>
        {step.Progress === 0 ?
          (isSpecificStepProblem ? <i className="material-icons warning md-24 ">warning_amber</i> : undefined) :

          (step.Progress < 1 ?
            <CircularProgress
              min={0}
              max={100}
              strokeWidth={4}
              value={Math.round(step.Progress * 100)}
              percentVisible={true}
            />
            :
            <i className="material-icons success md-24 ">check_circle</i>)
        }
      </div>
      <label className='crtx-export-import-step-name'>{step.Step}</label>
    </div>;
  };

  const renderImportFolderProgressStatus = () => {
    return <React.Fragment>
      {progress.Status === 'Started' && !progress.isCancelled &&
        <div className='crtx-progress-status'>
          <div className='icon-cont' />
          <div className='regular-message'>{translate('Importing folder')}</div>
          <button className='inner-button' onClick={handleCancel}>{translate('Cancel Import')}</button>
        </div>
      }
      {progress.Status === 'Failed' && progress.failedMessage &&
        <div className='crtx-progress-status'>
          <div className='icon-cont'>
            <i className='material-icons warning md-24 '>warning_amber</i>
          </div>
          {renderFailedMessage(progress.failedMessage)}
        </div>
      }
      {progress.Status === 'Complete' &&
        <React.Fragment>
          <div className='crtx-progress-status'>
            <div className='icon-cont'>
              <i className='material-icons success md-24 '>check_circle</i>
            </div>
            <div className='message'>{translate('Import folder completed.')}</div>
            <div className='warning-message crtx-form-margin-left-xsmall'>{translate('You must restart Tomcat now!')}</div>
          </div>
        </React.Fragment>
      }
    </React.Fragment>;
  };


  return <Dialog
    onClose={handleClose}
    title={translate('Import Folder')}
    autoFocus={false}
    initialWidth={550}
    modal={true}
  > {progress.Steps ? <div className='crtx-export-import-dialog'>
    <div className='crtx-steps-container'>
      {!(progress.Status === 'Failed' && !isSpecificStepProblem && progress.failedMessage) && renderSteps()}
      {renderImportFolderProgressStatus()}
      {progress.isCancelled && <div className='crtx-progress-status'>
        <div className='icon-cont'>
          <i className='material-icons warning md-24 '>warning_amber</i>
        </div>
        <div className='warning-message'>{translate('Import canceled by user')}</div>
      </div>
      }
    </div>
  </div>
    : <div className='crtx-form-section-container crtx-import-config-step'>
      <div className='crtx-form-section'>
        <div className='crtx-form-two-columns crtx-form-margin-bottom-xsmall'>
          <label>{translate('Import a folder from:')}</label>
          <Validator rules={['required']} register={register} className='crtx-form-element-full-width-validator'>
            <TextInput
              className='crtx-form-element-full-width'
              value={importZipPath}
              onChange={handleChangeZipPath} />
          </Validator>
          <div className='crtx-form-full-row'>
            <label className='crtx-form-label-with-checkbox'>
              <CheckBox
                checked={deleteTemporarySchema}
                onChange={(event, value) => {
                  setDeleteTemporarySchema(value);
                }}
              />
              {translate('Delete temp schema')}</label>
          </div>
          <div className='crtx-form-full-row'>
            <label className='crtx-form-label-with-checkbox'>
              <CheckBox
                checked={dryRun}
                onChange={(event, value) => {
                  setDryRun(value);
                }}
              />
              {translate('Dry run')}</label>
          </div>

          {!!warningMessage &&
            <div className='crtx-form-full-row'>
              <div className='crtx-validation-warning-message'>
                <div className='icon-cont'>
                  <i className='material-icons warning md-24 '>warning_amber</i>
                </div>
                <div className='warning-message'>{warningMessage}</div>
              </div>
            </div>}
        </div>
      </div>
    </div>
    }
    <DialogButtonsBar>
      {progress.Steps ?
        <Button className='primary' onClick={handleClose}>
          {translate('OK')}
        </Button>
        :
        <Button className='primary' disabled={!importEnabled} onClick={handleImportFolder}>{translate('Import')}
        </Button>}
    </DialogButtonsBar>
  </Dialog>;
};