/**
 * @name
 * @fileOverview
 * @author sergey
 */
define(['ember', './ItemView', 'text!../templates/menu.hbs'], function (Ember, ItemView, menuTemplate) {
  'use strict';
  return Ember.View.extend({
    tagName: 'ul',
    template: Ember.Handlebars.compile(menuTemplate),
    attributeBindings: ['style'],
    style: function () {
      var top = this.get('top');
      var left = this.get('left');
      var width = this.get('width');
      var height = this.get('height');
      var styleArray = [];
      
      if (typeof top !== 'undefined') styleArray.push({attr: 'top', value:  this.get('top') + 'px'});
      if (typeof left !== 'undefined') styleArray.push({attr: 'left', value:  this.get('left') + 'px'});
      if (typeof width !== 'undefined') styleArray.push({attr: 'width', value:  this.get('width') + 'px'});
      if (typeof height !== 'undefined') styleArray.push({attr: 'height', value:  this.get('height') + 'px'});
      
      return styleArray.map(sa => `${sa.attr}: ${sa.value}`).join(';') + ';overflow: auto;';
    }.property('top', 'left', 'width', 'height'),
    ItemView: ItemView,
    didInsertElement: function () {
      var el = this.$()[0];
      var domRect = el.getBoundingClientRect();
      var { width, height } = this.get('controller').getFixedContextMenuSize(domRect.width, domRect.height);
      var { top, left } = this.get('controller').getFixedContextMenuPosition(this.get('top'), this.get('left'), width, height);
      // this.set('width', width);
      this.set('height', height);
      this.set('top', top);
      this.set('left', left);
    }
  });
});