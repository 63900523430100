/**
 * @name
 * @matrixView
 * @author Liat
 */
define(['sandbox', 'ember', 'logger'], function (sandbox, Ember, logger) {
  'use strict';
  var log = logger.getDefaultLogger();
  return Ember.ObjectController.extend({

    toggleResource: function (resource) {
      var toggleAction = this.get('module.viewActions').find(a => a.actionDefinitionName === 'ToggleResourceActionCR');
      if (typeof toggleAction === 'undefined') {
        log.error('Toggle resource action instance is not found');
        return;
      }

      if (toggleAction.isApplicable()) {
        toggleAction.execute([resource]);
      }
    },

    abortResource: function (resource) {
      var abortResourcesAction = this.get('module.viewActions').find(a => a.actionDefinitionName === 'AbortResourcesActionCR');
      if (typeof abortResourcesAction === 'undefined') {
        log.error('Abort Resources Action instance is not found');
        return;
      }
      if (abortResourcesAction.isApplicable()) {
        abortResourcesAction.execute(resource);
      }
    },

    toggleDevice: function (device) {
      var toggleAction = this.get('module.viewActions').find(a => a.actionDefinitionName === 'ToggleDeviceInMatrixActionCR');
      if (typeof toggleAction === 'undefined') {
        log.error('Toggle Device action instance is not found');
        return;
      }
      if (toggleAction.isApplicable()) {
        toggleAction.execute([device]);
      }
    },

    toggleSkipDevice: function (device) {
      var toggleSkipAction = this.get('module.viewActions').find(a => a.actionDefinitionName === 'ToggleSkipDeviceInMatrixAction');
      if (typeof toggleSkipAction === 'undefined') {
        log.error('Toggle Skip Device action instance is not found');
        return;
      }
      if (toggleSkipAction.isApplicable()) {
        toggleSkipAction.execute([device]);
      }
    },

	  showRateGraph: function (rates) {
		  var rateGraphAction = this.get('module.viewActions').find(a => a.actionDefinitionName === 'ShowRateGraphActionCR');
		  if (typeof rateGraphAction === 'undefined') {
			  log.error('Rate Graph action instance is not found');
			  return;
		  }
		  if (rateGraphAction.isApplicable()) {
			  rateGraphAction.execute([rates]);
		  }
	  }
  });
});