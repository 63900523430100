import React, { Component } from 'react';
import PropTypes from 'prop-types';

const styles = {
  cell: {
    width: '100%',
    height: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  checkbox: {
    marginTop: 9
  }
};

export default class extends Component {
  static propTypes = {
    rowIndex: PropTypes.any,
    columnData: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.number
    ]),
    onChange: PropTypes.func,
    disabled: PropTypes.bool
  };

  handleChange = (ev) => {
    var { rowIndex, columnKey, columnData, onChange } = this.props;

    onChange(rowIndex, columnKey, ev.target.checked);
  };

  handleClick = (ev) => {
    ev.stopPropagation();
  };

  render() {
    var { rowIndex, columnData, disabled } = this.props;
    const checked = columnData === 'true' || columnData === 'True' || columnData === true || columnData === 1 ? true : false;

    return <div style={styles.cell}>
      <div className="cell-content">
        <input type="checkbox"
          onChange={this.handleChange}
          onClick={this.handleClick}
          disabled={disabled}
          checked={checked}
          style={styles.checkbox}
        />
      </div>
    </div>;
  }
}