/**
 * @name Toolbar
 * @file Layout Editor toolbar
 *
 * @author Boris
 * @since: 2016-09-25
 */

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import { getModuleIcon, getGeneralSvgIconHref } from 'core/services/iconService';
import actions from '../redux/actions';
import FlatButton from 'components/common/buttons/FlatButton';

const BUTTON_CLASS = 'editor-toolbar-button';

const getImageSource = (name) => {
  return getModuleIcon('LayoutEditor', name);
};

const buttons = [
  {
    id: 'save',
    imageSrc: getImageSource('save'),
    tooltip: translate('Save')
  },
  {
    id: 'zoomOut',
    tooltip: translate('Zoom out'),
    iconUrl: getGeneralSvgIconHref('zoom_out'),
    iconStyle: { width: 18, height: 18 },
  },
  {
    id: 'zoomIn',
    tooltip: translate('Zoom in'),
    iconUrl: getGeneralSvgIconHref('zoom_in'),
    iconStyle: { width: 18, height: 18 },
  },
  {
    id: 'alignments',
    buttonType: 'toggle',
    imageSrcOn: getImageSource('alignments_on'),
    imageSrcOff: getImageSource('alignments_off'),
    tooltip: translate('Show/Hide alignments')
  },
  {
    id: 'undo',
    tooltip: translate('Undo (Ctrl+Z)')
  },
  {
    id: 'redo',
    tooltip: translate('Redo (Ctrl+Shift+Z)')
  }
];

const Toolbar = ({ store, editor, }) => {

  const handleButtonClick = buttonId => {
    switch (buttonId) {
      case 'save':
        store.dispatch(actions.save());
        break;
      case 'zoomOut':
        store.dispatch(actions.zoomOut());
        break;
      case 'zoomIn':
        store.dispatch(actions.zoomIn());
        break;
      case 'alignments':
        store.dispatch(actions.toggleAlignments());
        break;
      case 'undo':
        store.dispatch(actions.undo());
        break;
      case 'redo':
        store.dispatch(actions.redo());
        break;
    }
  };

  const isButtonDisabled = buttonId => {
    if (!store) {
      return true;
    }

    return buttonId === 'undo' && !editor.canUndo() || buttonId === 'redo' && !editor.canRedo() ||
      buttonId === 'zoomIn' && !editor.canZoomIn() || buttonId === 'zoomOut' && !editor.canZoomOut();
  };

  const isButtonChecked = buttonId => {
    if (!store) {
      return false;
    }

    const state = store.getState();
    let checked = false;

    switch (buttonId) {
      case 'alignments':
        checked = state.showAlignments;
        break;
    }

    return checked;
  };

  const renderButton = button => {
    const disabled = isButtonDisabled(button.id);

    if (button.buttonType === 'toggle') {
      const checked = isButtonChecked(button.id);

      return (
        <FlatButton key={button.id}
                    className={BUTTON_CLASS}
                    tooltip={button.tooltip}
                    disabled={disabled}
                    onClick={(e) => handleButtonClick(button.id)}
        >
          <img src={checked ? button.imageSrcOn : button.imageSrcOff} />
        </FlatButton>
      );
    } else {
      return (
        <FlatButton key={button.id}
                    className={BUTTON_CLASS}
                    tooltip={button.tooltip}
                    disabled={disabled}
                    onClick={(e) => handleButtonClick(button.id)}
        >
          {button.imageSrc ?
            <img src={button.imageSrc} /> :
            button.iconUrl ?
              <svg style={button.iconStyle}>
                <use xlinkHref={button.iconUrl} />
              </svg> :
              <i className='material-icons'>{button.id}</i>}

        </FlatButton>
      );
    }
  };

  return (
    <div id="toolbarPanel" className="editor-toolbar-container">
      <div className='editor-toolbar'>
        {buttons.map((button) => renderButton(button))}
      </div>
    </div>
  );

};

Toolbar.propTypes = {
  store: PropTypes.any,
  editor: PropTypes.any,
};

export default Toolbar;
