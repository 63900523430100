import React from 'react';
import { createRoot } from 'react-dom/client';
import { createStore } from 'redux';
import Browser from './components/browser';
import reducer from './reducer';
import helpers from './helpers';
import restRequester from './restRequester';
import dialogService from 'core/services/dialogService';

const rootName = 'Root';

function initState(separator) {
  return {
    loading: true,
    items: [],
    history: [],
    currnetHistoryIndex: 0,
    viewStyle: 'list-style',
    selectedItem: {},
    separator: separator
  };
}

function render(reactRoot, root, store, rest) {

  reactRoot.render(<Browser store={store} root={root} rest={rest} />);
}

function show(title, viewOrActionNwid, rootUrl, root, onSelect, onCancel, currentRelativePath = '', separator = '\\') {
  const parentRelativePath = helpers.getParentFolderPath(currentRelativePath, separator);
  const currentCursor = helpers.createCursorFromPath(rootName, parentRelativePath, separator);
  const selectedItem = helpers.getSelectedItem(currentRelativePath, separator);
  const store = createStore(reducer, initState(separator));

  const rest = restRequester(root, separator, viewOrActionNwid, rootUrl, store);
  rest(currentCursor, selectedItem, true);

  dialogService.show({
    title: title,
    content: $('<div style="width: 100%; height: 100%;"></div>')[0],
    width: 710,
    height: 450,
    init: function (content) {
      const reactRoot = createRoot(content);
      store.subscribe(() => {
        render(reactRoot, root, store, rest);
      });
      render(reactRoot, root, store, rest);
    },
    buttons: [
      {
        title: 'Ok', onClick: function () {
          const lastHistory = store.getState().history[store.getState().currnetHistoryIndex];
          const selectedItem = store.getState().selectedItem;
          const cursor = selectedItem === undefined ? lastHistory : lastHistory.concat(store.getState().selectedItem);
          const relativeCursor = cursor.slice(1, cursor.length);
          if (typeof onSelect !== 'function') return;
          onSelect(event, relativeCursor, store.getState().selectedItem);
        }
      },
      {
        title: 'Cancel', onClick: function () {
          if (typeof onCancel !== 'function') return;
          onCancel();
        }
      }
    ]
  });
}

module.exports = {
  show: show
};