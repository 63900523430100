import { parse, format } from 'date-fns';
import settings from 'core/managers/settings';

// The following formats are taken from the server java class: NWDateTimeUtilties
const NEWSWAY_DATETIME_FORMAT = "MMM-dd-yyyy-H-mm-ss";
const NEWSWAY_DATE_FORMAT = "MMM-dd-yyyy";
const NEWSWAY_TIME_FORMAT = "H-mm-ss";

const MONTH_INDEX_BY_NAME = {
  Jan: 0,
  Feb: 1,
  Mar: 2,
  Apr: 3,
  May: 4,
  Jun: 5,
  Jul: 6,
  Aug: 7,
  Sep: 8,
  Oct: 9,
  Nov: 10,
  Dec: 11
};

const MONTH_NAME_BY_INDEX = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec'
};

export const MSEC_IN_MINUTE = 60 * 1000;
export const MSEC_IN_HOUR = 60 * MSEC_IN_MINUTE;
export const MSEC_IN_DAY = 24 * MSEC_IN_HOUR;
export const MSEC_BY_TIME_UNITS = {
  min: MSEC_IN_MINUTE,
  hour: MSEC_IN_HOUR,
  day: MSEC_IN_DAY,
};

export const DATE_FORMAT_TYPE = {
  FULL: 'full',
  LONG: 'long',
  MEDIUM: 'medium',
  SHORT: 'short'
};

const MSEC_IN_350_DAYS = 350 * MSEC_IN_DAY; // one year minus two weeks

const localeByCode = {};

function padZero(num, targetLength = 2) {
  return String(num).padStart(targetLength, '0')
}

function createDateInfo(year, month, day, hour = 0, minute = 0, second = 0, millisecond = 0) {
  return {
    year,
    month,
    day,
    hour,
    minute,
    second,
    millisecond,
  };
}

function dateInfoToDate(dateInfo) {
  return new Date(dateInfo.year, dateInfo.month, dateInfo.day, dateInfo.hour, dateInfo.minute, dateInfo.second, dateInfo.millisecond);
}

/**
 * Converts the given time in the given Time Zone to the UTC time.
 *
 * Note: Conversion is done precisely only for dates in the interval of ± 0.5 years from the current date.
 * For other dates the regular Time Zone offset is used (DST is ignored).
 *
 * @param {Number} time - time in the given Time Zone (milliseconds)
 * @param {Object} timeZone - Time Zone object
 * @returns {Number} - UTC time (milliseconds)
 */
function toUtc(time, timeZone) {
  let utc = time - timeZone.offset * MSEC_IN_MINUTE;
  if (!isNaN(timeZone.dstOffset)) {
    const dstServerTimeAsUtc = time - timeZone.dstOffset * MSEC_IN_MINUTE;
    if (timeZone.dstBegin < timeZone.dstEnd) {
      if (dstServerTimeAsUtc >= timeZone.dstBegin && dstServerTimeAsUtc < timeZone.dstEnd) {
        utc = dstServerTimeAsUtc;
      }
    } else if (dstServerTimeAsUtc < timeZone.dstEnd) {
      if (dstServerTimeAsUtc > timeZone.dstBegin - MSEC_IN_350_DAYS) {
        utc = dstServerTimeAsUtc;
      }
    } else if (dstServerTimeAsUtc >= timeZone.dstBegin) {
      if (dstServerTimeAsUtc < timeZone.dstEnd + MSEC_IN_350_DAYS) {
        utc = dstServerTimeAsUtc;
      }
    }
  }

  return utc;
}

/**
 * Converts the given UTC time to the time in the given Time Zone.
 *
 * Note: Conversion is done precisely only for dates in the interval of ± 0.5 years from the current date.
 * For other dates the regular Time Zone offset is used (DST is ignored).
 *
 * @param {Number} utc - UTC time (milliseconds)
 * @param {Object} timeZone - Time Zone object
 * @returns {Number} - time in the given Time Zone (milliseconds)
 */
function fromUtc(utc, timeZone) {
  let offset = timeZone.offset;
  if (!isNaN(timeZone.dstOffset)) {
    if (timeZone.dstBegin < timeZone.dstEnd) {
      if (utc >= timeZone.dstBegin && utc < timeZone.dstEnd) {
        offset = timeZone.dstOffset;
      }
    } else if (utc < timeZone.dstEnd) {
      if (utc > timeZone.dstBegin - MSEC_IN_350_DAYS) {
        offset = timeZone.dstOffset;
      }
    } else if (utc >= timeZone.dstBegin) {
      if (utc < timeZone.dstEnd + MSEC_IN_350_DAYS) {
        offset = timeZone.dstOffset;
      }
    }
  }

  const time = utc + offset * MSEC_IN_MINUTE;

  return time;
}

/**
 * Converts the given Date object in the given source time zone to the Date object in the target time zone.
 * Note: This function should be used from other modules only for the testing purposes.
 *
 * @param {Date} date - Date object
 * @param {Object} sourceTimeZone - the source Time Zone object
 * @param {Object} targetTimeZone - the target Time Zone object
 * @returns {Date} - Date object in the target Time Zone
 */
export function changeTimeZone(date, sourceTimeZone, targetTimeZone) {
  if (!date) {
    return;
  }

  if (!sourceTimeZone || !targetTimeZone || sourceTimeZone.name === targetTimeZone.name) {
    return date;
  }

  const sourceTime = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(),
    date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());

  const utc = toUtc(sourceTime, sourceTimeZone);
  const targetTime = fromUtc(utc, targetTimeZone);

  const d = new Date(targetTime);
  const targetDate = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(),
    d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds(), d.getUTCMilliseconds());

  return targetDate;
}

function splitDate(date) {
  let dateInfo;

  let arr = date.split('-');
  if (arr.length === 6) {
    // NEWSWAY_DATETIME_FORMAT: MMM-dd-yyyy-H-mm-ss
    dateInfo = createDateInfo(arr[2], MONTH_INDEX_BY_NAME[arr[0]], arr[1], arr[3], arr[4], arr[5]);
  } else if (arr.length === 3) {
    if (date.length === NEWSWAY_DATE_FORMAT.length) {
      // NEWSWAY_DATE_FORMAT: MMM-dd-yyyy
      dateInfo = createDateInfo(arr[2], MONTH_INDEX_BY_NAME[arr[0]], arr[1]);
    } else if (arr[0].length === 4) {
      // yyyy-MM-dd
      dateInfo = createDateInfo(arr[0], arr[1] - 1, arr[2]);
    } else {
      // NEWSWAY_TIME_FORMAT: H-mm-ss
      const now = new Date();
      dateInfo = createDateInfo(now.getFullYear(), now.getMonth(), now.getDate(), arr[0], arr[1], arr[2]);
    }
  } else if (arr.length === 7) {
    // yyyy-MM-dd-H-mm-ss-SSS
    dateInfo = createDateInfo(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5], arr[6]);
  }

  if (!dateInfo) {
    arr = date.split(':');
    if (arr.length >= 2) {
      // H:mm:ss
      const now = new Date();
      dateInfo = createDateInfo(now.getFullYear(), now.getMonth(), now.getDate(), arr[0], arr[1], arr[2]);
    }
  }

  return dateInfo;
}

// ===================================== PUBLIC API =====================================

/**
 * Converts the browser current time to the Date object in the specified Time Zone
 *
 * Note: The getTimezoneOffset() function returns the value with the opposite sign you might expect.
 * So GMT+3 (Jerusalem) is -180 and GMT-4 (New York) is 240.
 *
 * @param {Object} timeZone - Time Zone object that contains name, offset and other time zone info
 * @returns {Date} - instance of Date object
 */
export function getCurrentDateInTimeZone(timeZone) {
  const now = new Date();
  const utc = now.getTime() + now.getTimezoneOffset() * MSEC_IN_MINUTE;
  const currentDate = new Date(fromUtc(utc, timeZone));

  return currentDate;
}

/**
 * Converts the browser current time to the Date object in the Server's Time Zone
 *
 * @returns {Date} - instance of Date object
 */
export function getServerCurrentDate() {
  return getCurrentDateInTimeZone(settings.getServerTimeZone());
}


/**
 * Converts the browser current time to the Date object in the Folder's Time Zone
 *
 * @returns {Date} - instance of Date object
 */
export function getFolderCurrentDate() {
  return getCurrentDateInTimeZone(settings.getFolderTimeZone());
}

export function parseNewswayDateTime(date) {
  if (date instanceof Date) {
    return date;
  }

  if (!date || typeof date !== 'string') {
    return;
  }

  const dateInfo = splitDate(date);

  return dateInfoToDate(dateInfo);
}

export function formatNewswayDateTime(date) {
  if (!(date instanceof Date)) {
    return '';
  }

  // NEWSWAY_DATETIME_FORMAT: MMM-dd-yyyy-HH-mm-ss
  return `${MONTH_NAME_BY_INDEX[date.getMonth()]}-${padZero(date.getDate())}-${date.getFullYear()}` +
    `-${padZero(date.getHours())}-${padZero(date.getMinutes())}-${padZero(date.getSeconds())}`;
}

/**
 * Converts a string representation of the given date in the server time zone to the Date object
 * in the folder time zone.
 * Note: If the input date is already an instance of the Date object then no conversion is done.
 * In this case it is assumed that the conversion already performed.
 *
 * @param {String} date - string representation of a date or Date object
 * @returns {Date} - instance of Date object or undefined
 */
export function fromServerDate(date) {
  if (date instanceof Date) {
    return date;
  }

  if (!date || typeof date !== 'string') {
    return;
  }

  const dateInfo = splitDate(date);
  const folderDate = changeTimeZone(dateInfoToDate(dateInfo), settings.getServerTimeZone(), settings.getFolderTimeZone());

  return folderDate;
}

/**
 * Converts the given date object to its string representation in the server time zone.
 *
 * @param {Date} date - instance of the Date object
 * @returns {String} - string representation of the Date object in the server time zone
 */
export function toServerDate(date) {
  if (!(date instanceof Date)) {
    return '';
  }

  const d = changeTimeZone(date, settings.getFolderTimeZone(), settings.getServerTimeZone());

  return formatNewswayDateTime(d);
}

/**
 * Converts a string representation of the given date (time part only) to the Date object
 * without regard to time zone.
 * Note: If the input date is already an instance of the Date object then no conversion is done.
 * In this case it is assumed that the conversion already performed.
 *
 * @param {String} date - string representation of a date (time part only) or Date object
 * @returns {Date} - instance of Date object or undefined
 */
export function fromServerTime(date) {
  return parseNewswayDateTime(date);
}

/**
 * Converts the given date object to its string representation (only the time part) without regard to time zone.
 * The returned date is formatted as NEWSWAY_TIME_FORMAT
 *
 * @param {Date} date - instance of the Date object
 * @returns {String} - time part of date formatted as HH-mm-ss
 */
export function toServerTime(date) {
  if (!(date instanceof Date)) {
    return '';
  }

  return `${padZero(date.getHours())}-${padZero(date.getMinutes())}-${padZero(date.getSeconds())}`;
}

/**
 * Converts a string representation of the given date (not including the time part) to the Date object
 * without regard to the server time zone
 * Note: If the input date is already an instance of the Date object then no conversion is done.
 * In this case it is assumed that the conversion already performed.
 *
 * @param {String} date - string representation of a date (not including the time part) or Date object
 * @returns {Date} - instance of Date object or undefined
 */
export function fromServerDateOnly(date) {
  return parseNewswayDateTime(date);
}

/**
 * Converts the given date object to its string representation (not including the time part)
 * without regard to the server time zone.
 *
 * @param {Date} date - instance of the Date object
 * @returns {String} - string representation of the Date object (not including the time part)
 */
export function toServerDateOnly(date) {
  if (!(date instanceof Date)) {
    return '';
  }

  // NEWSWAY_DATE_FORMAT: MMM-dd-yyyy
  return `${MONTH_NAME_BY_INDEX[date.getMonth()]}-${padZero(date.getDate())}-${date.getFullYear()}`;
}

export function getDateLocale(localeCode) {
  if (!localeByCode[localeCode]) {
    localeByCode[localeCode] = require(`date-fns/locale/${localeCode}/index.js`);
  }

  return localeByCode[localeCode];
}

export function getDateFormat(localeCode = 'en-US', dateFormatType = DATE_FORMAT_TYPE.SHORT) {
  const locale = getDateLocale(localeCode);
  const formatString = locale.formatLong.date({ width: dateFormatType });
  return formatString;
}

export function getTimeFormat(localeCode = 'en-US', dateFormatType = DATE_FORMAT_TYPE.SHORT) {
  const locale = getDateLocale(localeCode);
  const formatString = locale.formatLong.time({ width: dateFormatType });
  return formatString;
}

/**
 * Parses a string representation of a date and returns the js Date object or undefined
 * if the string is unrecognized or contains illegal date values
 *
 * @param date - string representation of a date
 * @param dateFormat - date format string, e.g. 'dd/MM/yyyy'
 * @param localeCode - locale string, e.g 'fr', 'de', 'ru'. The default locale is 'en-US' (United States English)
 * @returns {Date} - instance of Date object or undefined
 */
export function parseDate(date, dateFormat, localeCode = 'en-US') {
  if (date instanceof Date) {
    return date;
  }

  if (!date || typeof date !== 'string') {
    return;
  }

  let parsedDate;
  try {
    parsedDate = parse(date, dateFormat, Date.now(), { locale: getDateLocale(localeCode) });
  } catch (err) {
    console.error(`Can't parse date '${date}' using the format '${dateFormat}'`, err);
  }

  return parsedDate;
}


/**
 * Formats the given date according to the specified dateFormat
 *
 * @param date - JavaScript date object
 * @param dateFormat - date format string, e.g. 'dd-MMM-yyyy'
 * @param localeCode - locale code string, e.g 'en-GB', 'es', 'fr', 'de', 'ru'. The default locale is 'en-US' (United States English)
 * @returns {string} - formatted date string, e.g. formatDate({Date object - 19 March 1964}, 'dd-MMM-yyyy') => 19-Mar-1964
 */
export function formatDate(date, dateFormat, localeCode = 'en-US') {
  if (!(date instanceof Date)) {
    return '';
  }

  let formattedDate = '';
  try {
    formattedDate = format(date, dateFormat, { locale: getDateLocale(localeCode) });
  } catch (err) {
    console.error(`Can't format date '${date}' using the format '${dateFormat}'`, err);
  }

  return formattedDate;
}

export function addDays(date, days) {
  let result = new Date(date.getTime());
  result.setDate(result.getDate() + Number(days));

  return result;
}

export function getMaxDate() {
  return new Date(8640000000000000);
}

export function getMinDate() {
  return new Date(-8640000000000000);
}

export function isSameDay(d1, d2) {
  let result = false;

  if (d1 && d2) {
    result =
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  } else if (!d1 && !d2) {
    result = true;
  }

  return result;
}

export function formatDuration(milliseconds) {
  if (isNaN(milliseconds)) {
    return milliseconds;
  }

  const ms = String(milliseconds % 1000).padStart(3, '0');
  const seconds = Math.floor(milliseconds / 1000);
  const ss = String(seconds % 60).padStart(2, '0');
  const minutes = Math.floor(seconds / 60);
  const mm = String(minutes % 60).padStart(2, '0');
  const hours = Math.floor(minutes / 60);
  const hh = String(hours).padStart(2, '0');

  // ***TEST
  // const sum = Number(hh) * 3600 * 1000 + Number(mm) * 60 * 1000 + Number(ss) * 1000 + Number(ms);
  // console.log('sum=', sum, Number(milliseconds) !== sum ? 'ERROR' : 'OK');

  return (hours > 0 ? `${hh}:` : '') + `${mm}:${ss}.${ms}`;
}
