import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { noop } from 'base/jsUtils';
import HeaderColumn from './HeaderColumn';
import scrollbars from './dom/ScrollBars';
import utils from './core/utils';
import Checkbox from 'components/common/inputs/Checkbox';
import { translate } from 'core/services/localization';
import { DragHandle } from 'components/private/sortableHoc/DragHandle';
import { Popover, PopoverContent, PopoverTrigger } from 'components/common/floating';

const headerStyleWidth = '100%';
const headerStyleWidthWithScrollbar = 'calc(100% - ' + scrollbars.getWidth() + 'px)';

const SortableItem = SortableElement(({ value, columns, allowToOrder, onPopoverItemClick }) => {
  const columnKey = value.props.columnKey;
  const columnTitle = value.props.title;
  const checked = utils.isVisible(columns[columnKey]);

  return <li onClick={ev => onPopoverItemClick(ev, columnKey, !checked)}>
    {allowToOrder ? <DragHandle /> : undefined}
    <input type='checkbox' onChange={noop} checked={checked} />
    <span>{columnTitle || columnKey}</span>
  </li>;
});
const SortableList = SortableContainer((
  {
    items,
    allowToOrder,
    columns,
    onPopoverItemClick,
    filtersEnabled,
    onToggleFiltersClick,
    showFilterOptionInHeaderMenu
  }) => (

  <ul className='grid-column-options'>
    {showFilterOptionInHeaderMenu && <React.Fragment>
      <li className='filter-option'>
        <label>
          <Checkbox checked={filtersEnabled} onChange={(e, newValue) => onToggleFiltersClick(e, newValue)} />
          <span>{translate('Enable filters')}</span>
        </label>
      </li>
      <hr />
    </React.Fragment>}
    {items.map((item, index) => <SortableItem
      key={index}
      index={index}
      value={item.column}
      columns={columns}
      allowToOrder={allowToOrder}
      onPopoverItemClick={onPopoverItemClick}
    />)}
  </ul>
));

const isContextMenuDisabled = (onColumnsFilter, onColumnsOrder) => typeof onColumnsFilter !== 'function' && typeof onColumnsOrder !== 'function';

export default class Header extends Component {
  static propTypes = {
    height: PropTypes.number.isRequired,
    totalColumnsWidth: PropTypes.number.isRequired,
    columns: PropTypes.any.isRequired,
    filterableColumns: PropTypes.array,
    groupsWidth: PropTypes.any,
    fixed: PropTypes.bool.isRequired,
    columnsByGroups: PropTypes.any,
    fontSize: PropTypes.number,
    resizableColumns: PropTypes.any,
    currentHeight: PropTypes.number,
    isTableVerticalScrollbarVisible: PropTypes.bool,
    win: PropTypes.object,
    onColumnsFilter: PropTypes.func,
    onColumnsOrder: PropTypes.func,
    columnResizeHandler: PropTypes.func,
    columnResizeEndHandler: PropTypes.func,
    columnResizeHandlerDoubleClick: PropTypes.func,
    filtersEnabled: PropTypes.bool,
    onToggleFiltersClick: PropTypes.func,
    showFilterOptionInHeaderMenu: PropTypes.bool,
  };

  state = {
    popoverVisible: false
  };

  renderHeaderColumn = (columns, resizableColumns, currentHeight, headerHeight, headerFontSize, column, columnIndex, lastColumn) => {
    if (!column) {
      return null;
    }

    var {
      win,
      columnResizeHandler,
      columnResizeEndHandler,
      columnResizeHandlerDoubleClick,
      fixed: fixedTable,
      onColumnsFilter,
      onColumnsOrder
    } = this.props;
    /* cell, onResuze, onResizeDoubleClicked, fixed are decelared just not to be passed with ...props to the HeaderColumn component */
    // eslint-disable-next-line no-unused-vars
    var { columnKey, flex, resizable, header, cell, onResize, onResizeDoubleClick, fixed, ...props } = column.props;
    var columnWidth = columns[columnKey].width;
    var isResizable = resizable && !(fixedTable && lastColumn);

    return <HeaderColumn
      key={columnKey}
      {...props}
      index={columnIndex}
      columnKey={columnKey}
      tableHeight={currentHeight}
      headerHeight={headerHeight}
      headerFontSize={headerFontSize}
      width={columnWidth}
      flex={flex}
      resizable={isResizable}
      resizableColumns={resizableColumns}
      win={win}
      onResize={isResizable ? columnResizeHandler : noop}
      onResizeEnd={isResizable ? columnResizeEndHandler : noop}
      onResizeDoubleClick={isResizable ? columnResizeHandlerDoubleClick : noop}
      showContextMenuIcon={!isContextMenuDisabled(onColumnsFilter, onColumnsOrder)}
      onContextMenu={this.handleContextMenu}
    >

      {header}

    </HeaderColumn>;
  };

  getGroupColumnsWidth = (columnsByGroups = [], columns = {}) => {
    return columnsByGroups.reduce((width, column) => {
      return width + (columns[column.column.props.columnKey].width || 0);
    }, 1);
  };

  renderColumns = (columnsByGroups, currentHeight, group) => {
    var that = this;
    var { height, fontSize, resizableColumns, columns } = this.props;

    if (typeof group === 'string') {
      if (!Array.isArray(columnsByGroups[group])) {
        return [];
      }

      return columnsByGroups[group].filter(column => column.visible).map((column) => {
        return that.renderHeaderColumn(columns, resizableColumns, currentHeight, height, fontSize, column.column, column.index);
      });
    }

    return columnsByGroups.center.filter(column => column.visible).map((column, index, arr) => {
      return that.renderHeaderColumn(columns, resizableColumns, currentHeight, height, fontSize, column.column, column.index, index === arr.length - 1);
    });
  };

  handleContextMenu = e => {
    e.preventDefault();

    this.openPopover(e.clientX, e.clientY);
  };

  openPopover = (clientX, clientY) => {
    this.setState({
      popoverVisible: true,
      clientX,
      clientY,
    });
  };

  closePopover = () => {
    this.setState({
      popoverVisible: false
    });
  };

  handlePopoverItemClick = (ev, columnKey, value) => {
    const { onColumnsFilter } = this.props;

    if (typeof onColumnsFilter === 'function') {
      onColumnsFilter(columnKey, value);
    }
  };

  handleToggleFiltersClick = (ev, value) => {
    const { onToggleFiltersClick } = this.props;

    if (typeof onToggleFiltersClick === 'function') {
      onToggleFiltersClick(ev, value);
    }
  };

  handlePopoverItemOrder = (oldIndex, newIndex) => {
    const { onColumnsOrder } = this.props;

    if (typeof onColumnsOrder === 'function') {
      onColumnsOrder(oldIndex, newIndex);
    }
  };

  render() {
    const {
      columns,
      columnsByGroups,
      totalColumnsWidth,
      groupsWidth,
      fixed,
      currentHeight,
      isTableVerticalScrollbarVisible,
      onColumnsFilter,
      onColumnsOrder,
      filtersEnabled,
      showFilterOptionInHeaderMenu
    } = this.props;
    const style = {
      width: isTableVerticalScrollbarVisible ? headerStyleWidthWithScrollbar : headerStyleWidth
    };
    const contentStyle = {
      paddingLeft: groupsWidth.left,
      paddingRight: groupsWidth.right,
      width: fixed ? '100%' : totalColumnsWidth
    };
    const fixedLeftColumnsStyle = {
      width: groupsWidth.left,
      position: 'absolute',
      top: 0,
      left: 0
    };
    const fixedRightColumnsStyle = {
      width: groupsWidth.right,
      position: 'absolute',
      top: 0,
      right: isTableVerticalScrollbarVisible ? scrollbars.getWidth() : 0
    };
    const scrollbarColumnStyle = {
      width: isTableVerticalScrollbarVisible ? scrollbars.getWidth() : 0,
    };

    const allColumns = Object.keys(columnsByGroups).reduce((accColumns, columnsGroup) => accColumns.concat(columnsByGroups[columnsGroup]), []);
    const allowToOrder = allColumns.length === columnsByGroups.center.length;
    const columnsToSort = allColumns.filter(item => item.column.key !== 'dragHandle');

    const { popoverVisible, clientX, clientY } = this.state;

    return (
      <Popover
        placement={'bottom-start'}
        clientX={clientX}
        clientY={clientY}
        open={popoverVisible}
        onOpenChange={this.closePopover}
      >
        <PopoverTrigger onContextMenu={this.handleContextMenu}>
          <div className='react-data-grid-header' style={style}>
            <div className='react-data-grid-header-content' style={contentStyle}>
              {this.renderColumns(columnsByGroups, currentHeight)}
            </div>
            <div className='react-data-grid-header-fixed-content fixed-left' style={fixedLeftColumnsStyle}>
              {this.renderColumns(columnsByGroups, currentHeight, 'left')}
            </div>
            <div className='react-data-grid-header-fixed-content fixed-right' style={fixedRightColumnsStyle}>
              {this.renderColumns(columnsByGroups, currentHeight, 'right')}
            </div>
            <div className='react-data-grid-header-scrollbar-empty-column' style={scrollbarColumnStyle}>
            </div>
          </div>
        </PopoverTrigger>

        <PopoverContent>
          {popoverVisible && !isContextMenuDisabled(onColumnsFilter, onColumnsOrder) &&
            <SortableList
              lockAxis='y'
              useDragHandle={true}
              helperClass='grid-column-options-draggable-item'
              items={columnsToSort || []}
              allowToOrder={allowToOrder}
              columns={columns}
              onPopoverItemClick={this.handlePopoverItemClick}
              onSortEnd={({ oldIndex, newIndex }) => this.handlePopoverItemOrder(oldIndex, newIndex)}
              filtersEnabled={filtersEnabled}
              onToggleFiltersClick={this.handleToggleFiltersClick}
              showFilterOptionInHeaderMenu={showFilterOptionInHeaderMenu}
            />
          }
        </PopoverContent>
      </Popover>
    );
  }
}