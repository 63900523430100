import React from 'react';
import SeparationsComposite from './separationsComposite';
import StatusIcon from '../statusIcon/statusicon';

export default class extends React.Component {
  static defaultProps = {
    separations: undefined,
    status: undefined,
    definition: undefined
  };

  renderElements = (separations, status, definition) => {
    var elements = [];
    if (definition.showComposite) {
      elements.push(<SeparationsComposite separations={separations} />);
    }
    if (definition.showStatus) {
      elements.push(<StatusIcon status={status} size="tiny" />);
    }
    return elements;
  };

  render() {
    var {
            separations,
      status,
      definition
          } = this.props;

    return <li className="separation-progress-header">
      {this.renderElements(separations, status, definition)}
    </li>;
  }
}