import React from 'react';
import PropTypes from 'prop-types';
import Table, { Column, headers, filters } from 'widgets/ReactDataGrid';
import { FILTER_TYPE, ROW_HEIGHT, HEADER_HEIGHT, HEADER_HEIGHT_WITH_FILTER } from 'widgets/ReactDataGrid/utils';

const { GenericHeader, HeaderCaption } = headers;
const { TextInputFilter, DateFilter, MultiselectFilter } = filters;

const MIN_COLUMN_WIDTH = 20;

const FILTER_SORTING_COLUMNS = ['folders'];

export default function View({ module, viewModel, auditsTableColumns = [] }) {

  const renderAuditColumnFilter = column => {

    const { filter } = column;

    if (!filter) {
      return;
    }

    switch (filter.type) {
      case FILTER_TYPE.MULTISELECT:
      {
        const selectedValues = Array.isArray(filter.selected) && filter.selected || [];

        return (
          <MultiselectFilter
            value={selectedValues}
            options={filter.options}
            dataType={filter.dataType}
            sortingEnabled={FILTER_SORTING_COLUMNS.includes(column.key)}
            onSelect={(event, values) => module.handleAuditColumnFilterChange(column, { selected: values })}
          />
        );
      }
      case FILTER_TYPE.TEXT:
        return (
          <TextInputFilter
            value={filter.textValue}
            onChange={(e, textValue) => module.handleAuditColumnFilterChange(column, { textValue })}
          />
        );
      case FILTER_TYPE.DATE:
        return (
          <DateFilter
            filter={filter}
            onApply={filter => module.handleAuditColumnFilterChange(column, filter)}
          />
        );
    }
  }

  const renderAuditsColumnHeaderFilter = column => {

    if (!module.filtersEnabled) {
      return;
    }

    return (
      <div className='column-header-filter'>
        {renderAuditColumnFilter(column)}
      </div>
    );
  }
  const renderAuditsColumnHeaderCaption = (column, handleClick, sortDirection, index) => {
    return (
      <HeaderCaption sortDirection={sortDirection} sortOrder={index + 1} tooltip={column.caption}
        onSort={multiSort => handleClick(column.key, column.sortValueGetter, multiSort)} sortable={typeof column.sortType !== 'undefined'}
      >
        {column.caption}
      </HeaderCaption>
    );
  }

  const renderAuditsColumnHeader = (column, handleClick, sortDirection, index) => {
    return (
      <GenericHeader
        captionRenderer={renderAuditsColumnHeaderCaption(column, handleClick, sortDirection, index)}
        filterRenderer={renderAuditsColumnHeaderFilter(column)}
      />
    );
  }

  const renderAuditsTable = () => {

    const { columnsToSortBy, filtersEnabled } = module;

    const headerHeight = filtersEnabled ? HEADER_HEIGHT_WITH_FILTER : HEADER_HEIGHT;

    return (
      <Table
        rows={viewModel.filteredAudits}
        columnKey='index'
        virtualScroll={true}
        selectableRows={false}
        fixed={false}
        minColumnWidth={MIN_COLUMN_WIDTH}
        headerHeight={headerHeight}
        rowHeight={ROW_HEIGHT}
        onColumnsFilter={(columnKey, visible, columns) => module.handleAuditsTableColumnsFilter(columns)}
        onColumnsOrder={(columns, oldIndex, newIndex) => module.handleAuditsTableColumnOrder(columns, oldIndex, newIndex)}
        onColumnResize={(columns, columnKey) => module.handleAuditsTableColumnResize(columns)}
        showFilterOptionInHeaderMenu={true}
        filtersEnabled={filtersEnabled}
        onToggleFiltersClick={(e, newValue) => module.toggleFilters(newValue)}
      >
        {auditsTableColumns.map(col => {
          const index = columnsToSortBy.findIndex(column => column.key === col.key)
          const sortDirection =
            col.sortType && index !== -1
              ? columnsToSortBy[index].ascending ? 'asc' : 'desc'
              : undefined;
          const handleClick = col.sortType
            ? module.handleAuditColumnClick(col.sortType)
            : undefined;
          return <Column
            key={col.key}
            title={col.caption}
            columnKey={col.key}
            width={col.width}
            visible={col.visible}
            header={renderAuditsColumnHeader(col, handleClick, sortDirection, index)}
            cell={col.cell}
            cellDataGetter={col.cellDataGetter}
          />;
        })}
      </Table>
    );
  }

  return (
    <div className='crtx-form-element-full-height'>
      {renderAuditsTable()}
    </div>
  );
}

View.propTypes = {
  module: PropTypes.object,
  viewModel: PropTypes.object,
  auditsTableColumns: PropTypes.array,
};
