import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class extends Component {
  static propTypes = {
    rowIndex: PropTypes.number.isRequired,
    rowHeight: PropTypes.number.isRequired,
    columnIndex: PropTypes.number.isRequired,
    columnKey: PropTypes.any.isRequired,
    columnWidth: PropTypes.number.isRequired,
    flex: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    align: PropTypes.string,
    columnData: PropTypes.any,
    rowContent: PropTypes.any,
    shouldCellUpdate: PropTypes.func
  };

  static defaultProps = {
    shouldCellUpdate: function (nextProps, props) { return nextProps.columnData !== props.columnData; }
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.rowIndex !== this.props.rowIndex ||
      nextProps.rowHeight !== this.props.rowHeight ||
      nextProps.columnWidth !== this.props.columnWidth ||
      nextProps.align !== this.props.align ||
      nextProps.shouldCellUpdate(nextProps, this.props) ? true : false;
  }

  //shouldComponentUpdate: function (nextProps, nextState) {
  //  //return nextProps.rowIndex !== this.props.rowIndex || nextProps.rowHeight !== this.props.rowHeight || nextProps.columnIndex !== this.props.columnIndex ||
  //  //nextProps.columnKey !== this.props.columnKey || nextProps.columnWidth !== this.props.columnWidth || nextProps.align !== this.props.align ||
  //  //nextProps.columnData !== this.props.columnData || this.props.shouldCellUpdate(nextProps, nextState, this.props, this.state) ? true : false;
  //},

  render() {
    var {
      rowHeight,
      columnKey,
      columnWidth,
      flex,
      align,
      children,
      ...props
    } = this.props;
    var cellStyle = {
      width: columnWidth,
      height: rowHeight - 1,
      lineHeight: (rowHeight - 1) + "px",
      textAlign: typeof align === 'undefined' ? 'left' : align
      //,
      //flex: flex
    };

    return <div className="react-data-grid-cell" style={cellStyle} data-cellcolumnkey={columnKey}>
      {children}
    </div>;
  }
}