import { startInternalModule } from 'core/managers/module';
import AbstractAction from 'AbstractAction';
import { translate } from 'core/services/localization';
import { isItemWaitingForApproval, isSeprationWaitingForApproval } from '../../utilities/approvals';

const isUndefined = o => typeof o === 'undefined';
const APPROVAL_FLOW_STEP_TYPE = 'workflow/step/flow/approval';

function getApprovalFlowStepNwid(module) {
  if (module.startParameters && module.startParameters.rootType === APPROVAL_FLOW_STEP_TYPE) {
    return module.startParameters.rootId;
  }

  if (module.parentModule && module.parentModule.startParameters && module.parentModule.startParameters.rootType === APPROVAL_FLOW_STEP_TYPE) {
    return module.parentModule.startParameters.rootId;
  }

  return undefined;
}

function getIsViewDescendentOfApprovalView(actionInstance) {
  if (actionInstance.module.startParameters && actionInstance.module.startParameters.rootType === APPROVAL_FLOW_STEP_TYPE) {
    return true;
  }
  if (actionInstance.module.parentModule && actionInstance.module.parentModule.startParameters && actionInstance.module.parentModule.startParameters.rootType === APPROVAL_FLOW_STEP_TYPE) {
    return true;
  }

  return false;
}

export default AbstractAction.extend({
  isApplicable: function (clickedItems = []) {
    const config = this.config || {};
    const allowedFlowstepIds = config.flowStepIds || [];
    const isViewDescendentOfApprovalView = getIsViewDescendentOfApprovalView(this);
    const approvalFlowStepNwid = getApprovalFlowStepNwid(this.module);

    if (isViewDescendentOfApprovalView && allowedFlowstepIds.length > 0 && !allowedFlowstepIds.includes(approvalFlowStepNwid)) {
      return false;
    }

    return clickedItems.some(selectedItem => {
      if (isUndefined(selectedItem) || selectedItem.aggregated) {
        return false;
      }

      if (selectedItem.type === 'page') {
        return isItemWaitingForApproval(selectedItem, allowedFlowstepIds);
      }

      if (selectedItem.type === 'form') {
        return (selectedItem.separations || []).some(separation => isSeprationWaitingForApproval(selectedItem, separation, allowedFlowstepIds));
      }

      return false;
    });
  },

  execute: function (selectedItems) {
    const moduleName = 'CustomApprovalSetup';
    const name = translate('Custom Approval');
    const target = 'dialog';
    const windowWidth = 420;
    const windowHeight = 300;
    const win = this.module.win;

    startInternalModule(moduleName, { name, win, target, windowWidth, windowHeight, selected: selectedItems }, this);
  }
});