define(['react', 'sandbox', 'widgets/SimpleForm/src/index', 'widgets/ReactDataGrid/src/ReactDataGrid'], function (React, sandbox, SimpleForm, ReactDataGrid) {
  'use strict';

  var { DropdownCell, TextInputCell } = ReactDataGrid.cells;
  var { translate } = sandbox.localization;

  return function columnsDefinition (store) {

    var options = store.get('model.options') || {};

    var days = [
      {text: 'Every day', value: '*'},
      {text: 'Sunday', value: 'Sun'},
      {text: 'Monday', value: 'Mon'},
      {text: 'Tuesday', value: 'Tue'},
      {text: 'Wednesday', value: 'Wed'},
      {text: 'Thursday', value: 'Thu'},
      {text: 'Friday', value: 'Fri'},
      {text: 'Saturday', value: 'Sat'}
    ];

    return [

      {
        title: translate('Name'),
        columnKey: 'name',
        width: 130,
        sortable: false,
        autowidth: false,
        cell: TextInputCell
      },

      {
        title: translate('Publication'),
        columnKey: 'publication',
        width: 130,
        sortable: false,
        autowidth: false,
        cell: TextInputCell
      },

      {
        title: translate('Zone'),
        columnKey: 'zone',
        width: 130,
        sortable: false,
        autowidth: false,
        cell: TextInputCell
      },

      {
        title: translate('Book'),
        columnKey: 'book',
        width: 130,
        sortable: false,
        autowidth: false,
        cell: TextInputCell
      },

      {
        title: translate('Day'),
        columnKey: 'day',
        width: 130,
        sortable: false,
        autowidth: false,
        cell: DropdownCell,
        props: function (bind, rowIndex, columnKey) {
          return {
            options: days
          };
        }
      },

      {
        title: translate('BroadSheet'),
        columnKey: 'ruleLayoutGroupBroadSheet',
        width: 130,
        sortable: false,
        autowidth: false,
        cell: DropdownCell,
        props: function (bind, rowIndex, columnKey) {
          return {
            options: options.ruleLayoutGroupBroadSheet || []
          }
        }
      },

      {
        title: translate('Tabs'),
        columnKey: 'ruleLayoutGroupTabloid',
        width: 130,
        sortable: false,
        autowidth: false,
        cell: DropdownCell,
        props: function (bind, rowIndex, columnKey) {
          return {
            options: options.ruleLayoutGroupTabloid || []
          }
        }
      },

      {
        title: translate('2 Ups'),
        columnKey: 'ruleLayoutGroup2UP',
        width: 130,
        sortable: false,
        autowidth: false,
        cell: DropdownCell,
        props: function (bind, rowIndex, columnKey) {
          return {
            options: options.ruleLayoutGroup2UP || []
          }
        }
      },

      {
        title: translate('4 Ups'),
        columnKey: 'ruleLayoutGroup4UP',
        width: 130,
        sortable: false,
        autowidth: false,
        cell: DropdownCell,
        props: function (bind, rowIndex, columnKey) {
          return {
            options: options.ruleLayoutGroup4UP || []
          }
        }
      }

    ];

  };

});