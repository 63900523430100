
define(['ember', 'kendo-ember', './wizardUtils'],
  function (Ember, kendoComponents, wizardUtils) {
    'use strict';

    var zoneTabs = function(){
      var ctrl = this.get('controller');
      var zones = ctrl.zones();//this.get('allZones');
      
      zones = zones.filter(function(zone) {
        return !zone.hidden;
      });
      var that = this;
      var tabs = zones.map(function(zone){
        return {
          viewClass: that.get("zoneView"),
          text: zone.displayName,
          viewInitObj: {
            model: zone,
            controller: ctrl
          }
        };
      });
      return tabs;
    }.property();

//    var allZones = function(){
//      return this.get("controller").zones();
////      var res = [];
////      var editions = this.get("controller.model.editions") || [];
////      editions.forEach(function(edition){
////        var zones = edition.zones || [];
////        zones.forEach(function(zone){
////          res.push(zone);
////        });
////      });
////      return res;
//    }.property('controller.model.editions.@each.zoneChangeFlag')

    var tabsView = kendoComponents.kendoTabStrip.component.extend(
      {
        activate: function(event){
          var index = this.getCurrentIndex();
          if (index < 0){
            return;
          }
          var tabView = this.get("dataSource")[index];
          var zoneView = tabView.view;
          zoneView.get("controller").selectedZoneChanged.call(zoneView.get("controller"), zoneView.get("model"));
        }
      }
    );

    var viewCommons = Ember.Mixin.create({

      /*
        handling zone tabs model
       */
//      allZones: allZones,
      zoneTabs: zoneTabs,
      tabsView: tabsView

    });

    var getSourceId = function (separator) {
      /// <summary>
      ///    Creates a unique id for identification purposes.
      /// </summary>
      /// <param name="separator" type="String" optional="true">
      /// The optional separator for grouping the generated segmants: default "-".
      /// </param>

      var delim = separator || "-";

      function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      }
      var sourceId = (S4() + S4() + delim + S4() + delim + S4() + delim + S4() + delim + S4() + S4() + S4());
      console.log ("return source id = " + sourceId);
      return sourceId;
    };

    var getSourceId1 = function() {
      var id = this.get("model.sourceId");
      if (this.get("model.sourceId") === void 0){
        //this.set("model.sourceId", (new Date()).getTime());
        id = (new Date()).getTime();
      }
      //console.log ("read source id = " + id);
      id++;
      this.set("model.sourceId", id);
      //console.log ("return source id = " + id);
      return id;
    };

    var controllerCommons = Ember.Mixin.create({

      /*
       handling source id
       */
      sourceId: getSourceId




    });

    return{
      viewCommons: viewCommons,
      controllerCommons: controllerCommons
    };
  });