import { startInternalModule } from 'core/managers/module';
import request from 'sandbox/request';
import AbstractAction from 'AbstractAction';
import { translate } from 'core/services/localization';

export default AbstractAction.extend({
  save: function (name, selectedItem) {
    if (name === "") {
      alert("Please enter the workflow name");
      return false;
    }

    var actionArgs = [
      this.nwid,
      "",
      "",
      "",
      this.module.projectorId,
      "exec",
      {
        nwid: selectedItem.nwid,
        type: selectedItem.type,
        name: name
      }
    ];
    request.execServerAction.apply(null, actionArgs);
  },

  execute: function (selectedItems) {
    const moduleName = 'CreateNewWorkflowSetup';
    const name = translate('Create Workflow');
    const win = this.module.win;
    const target = 'dialog';
    const windowWidth = 400;
    const windowHeight = 200;
    const params = {};

    startInternalModule(moduleName, { name, win, target, windowWidth, windowHeight, params }, this)
      .then(moduleInstance => {
        moduleInstance.onApply((action, state) => {
          console.log(action);
          console.log(state);
          this.save(state.name, selectedItems[0]);
        })
      });
  }
});