import { createObjectComparator, COMPARE_TYPE } from 'core/comparators';
import AbstractModule from 'AbstractModule';
import sandbox from 'sandbox';
import TableWidget from 'TableWidget';
import Controller from './controllers/VillageViewController';
import Selection from './../Selection';

/**
 * Created by Elad on 8/4/2015. 
 * updated by Yael on 20/4/2021
 */

function sortModelArrays(model) {
  const sortingKeys = ["sites", "externalSites"];
  for (const key of sortingKeys) {
    if (Array.isArray(model[key])) {
      model[key] = model[key].sort(createObjectComparator("name"));
    }
  }
}

function getInsertIndex(model) {
  var tableModel = this.tableModel;
  for (var i = 0; i < tableModel.length; i++) {
    if (tableModel[i].name.toLowerCase() > model.name.toLowerCase()) {
      return i;
    }
  }
  return -1;
}

function getColsDescriptors() {
  return [
    { title: "", field: "type", width: '10%', render: renderType },
    { title: "Name", field: "name", width: '17%' },
    { title: "Code", field: "code", width: '10%' },
    { title: "properties", field: "properties", width: '73%', render: renderProperties }
  ];
}

function renderType(data) {
  var type = data.siteType;
  var iconName;
  if (type.toUpperCase() === "INTERNAL") {
    iconName = "site";
  } else {
    iconName = "external_site";
  }
  return '<img class="content-centered" src="' + sandbox.icons.getModuleIcon("VillageView", iconName) + '"/>';
}

function renderProperties(data) {
  var properties = data.properties;
  if (typeof properties === "undefined") {
    return "";
  }
  var ret = "";
  for (var p in properties) {
    ret += ", " + p + ": " + properties[p];
  }
  return ret.substr(2);
}

function tickUpdateHandler(model) {
  var action = model["action"];
  if (!action) { //this is not part of the model
    return;
  }
  switch (action.toLowerCase()) {
    case "remove":
      handleDeleteObjects.call(this, model);
      break;
    case "add":
      handleAddObject.call(this, model);
      break;
    case "update":
      handleUpdateObject.call(this, model);
      break;
  }
}

function handleUpdateObject(model) {
  Ember.setProperties(this._map[model["id"]], model);
  sortModelArrays.apply(this, [this.ctrl.get('content')]);
}

function handleAddObject(model) {
  var id = model["id"];
  var parent = this._map[model["parentId"]];
  sandbox.appUtils.flattenModel(model, this._map);
  var childProperty = model["childPropertyId"];
  var objToUpdate = parent[childProperty];
  var updateArray = Array.isArray(objToUpdate);
  if (updateArray) {
    objToUpdate.push(model);
    if (childProperty === "externalSites" || childProperty === "sites") {
      var index = getInsertIndex.call(this, model);
      if (index === -1) {
        this.tableModel.pushObject(model);
      } else {
        this.tableModel.insertAt(index, model);
      }
    }
  }

}

function handleDeleteObjects(model) {
  var idsToDelete = model["ids"];
  var parent = this._map[model["parentId"]];
  var childProperty = model["childPropertyId"];
  var objToUpdate = parent[childProperty];

  if (idsToDelete) {
    for (var i = 0; i < idsToDelete.length; i++) {
      var idToDelete = idsToDelete[i];
      var o = this._map[idToDelete];
      var updateArray = Array.isArray(objToUpdate);
      if (updateArray) {
        var index = this.tableModel.indexOf(o);
        if (index > -1) { //need to delete object from table model
          this.tableModel.removeObject(o);
        } else {
          var separations = this.ctrl.findSeparations(o);
          for (var j = 0; j < separations.length; j++) {
            var sep = this._map[separations[j]];
            index = this.tableModel.indexOf(sep);
            if (index === -1) {
              var plates = this.ctrl.findPlates(sep);
              for (var k = 0; k < plates.length; k++) {
                var plate = this._map[plates[k]];
                index = this.tableModel.indexOf(plate);
                if (index > -1) { //need to delete object from table model
                  this.tableModel.removeObject(plate);
                }
              }
            } else {
              this.tableModel.removeObject(sep);
            }
            this._map[separations[j]] = null;
          }
        }
        objToUpdate.removeObject(o);
        this._map[idToDelete] = null;
      } else {
        this._map[idToDelete] = null;
        var existingObj = Ember.get(parent, childProperty);
        if (existingObj) {
          var existingId = existingObj["id"];
          if (existingId === idToDelete) {
            Ember.set(parent, childProperty, null);
          }
        }
      }
    }
  }
}


function initialTickHandler(model, preferences) {

  sortModelArrays.apply(this, [model]);
  this.viewType = model.viewType;

  // build map between id and model for updates
  this._map = sandbox.appUtils.flattenModel(model, {});

  // set content to the controller
  this.ctrl.set('content', model);
  this.ctrl.set('module', this);

  sandbox.dom.empty(this.element);

  var that = this;

  var tableModel = model.sites.concat(model.externalSites);

  this.tableModel = tableModel;

  this.view = new TableWidget({
    element: this.element,
    data: this.tableModel,
    columns: getColsDescriptors.call(this)

  });

  this.view.bind('contextmenu', function (clicked, selected, ev) {

    this.showContextMenu(clicked, selected, ev);
  }, this);
}

module.exports = AbstractModule.extend({
  init: function () {
    this._super();
    this.viewType = '';
    this.tableModel = null;

  },

  initDone: function () {
    this.ctrl = Controller.create();
    this.toolbar = this.createToolbar();
  },

  firstTickReceived: function (data) {
    initialTickHandler.call(this, data.model, data.preferences);
    this.selection.applyTableSelection(this, this.view);

  },

  tickUpdate: function (data) {
    for (var m in data.model) {
      if (sandbox.jsUtils.isObject(data.model[m])) {
        tickUpdateHandler.call(this, data.model[m]);
      }

    }
  },

  destroy: function () {
    this._super();
    this.ctrl.destroy();
    if (this.view) {
      this.view.destroy();
    }
  }
});
  // });
