define([], function () {
  var MAP_VALUES = {
      'style': {
        'bold': {
          'fontWeight': 'bold'
        },
        'underline': {
          'textDecoration': 'underline'
        },
        'italic': {
          'fontStyle': 'italic'
        }
      }
    },
    MAP_TAGS = [
      { tag: 'size', styleTags: ['fontSize'] },
      { tag: 'align', styleTags: ['textAlign'] },
      { tag: 'style', styleTags: [MAP_VALUES.style] },
      { tag: 'color', styleTags: ['color'] },
      { tag: 'width', styleTags: ['width'] },
      { tag: 'font', styleTags: ['fontFamily'] }
    ];

  return MAP_TAGS;
});