/**
 * Created by elad on 3/28/2017.
 */

import Ember from 'ember';
import sandbox from 'sandbox';
import MainTemplate from 'text!../templates/zone.hbs';
import runTemplate from 'text!../templates/run.hbs';
import bookTemplate from 'text!../templates/book.hbs';
import { restGet } from 'core/managers/rest2';

var headerTemplate =
  '<div class="column">' + sandbox.localization.translate("Press") + '</div>' +
  '<div class="column">' + sandbox.localization.translate('Deadline') + '</div>' +
  '<div class="column">' + sandbox.localization.translate('Mode') + '</div>' +
  '<div class="column">' + sandbox.localization.translate('Hold') + '</div>' +
  '<div class="column">' + sandbox.localization.translate('Release') + '</div>' +
  '<div class="column">' + sandbox.localization.translate('Lock To') + '</div>';

var HeaderView = Ember.View.extend({
  tagName: 'li',
  classNames: ['pw-row', 'section'],
  layout: Ember.Handlebars.compile(headerTemplate)
});

var longTextView = Ember.TextField.extend({
  classNames: ['k-textbox'],
  attributeBindings: ['readonly', 'title']
});

var BookView = Ember.View.extend({
  tagName: 'li',
  classNames: ['pw-row', 'section'],
  template: Ember.Handlebars.compile(bookTemplate),
  nameView: longTextView,
  init: function () {
    this.addObserver("model.formFanout", function () {
      if (this.model.formFanout !== "" && this.model.productionRunBookFanoutTemplate !== "") {
        Ember.set(this.model, "productionRunBookFanoutTemplate", "");
      }
    });

    this.addObserver("model.productionRunBookFanoutTemplate", function () {
      if (this.model.formFanout !== "" && this.model.productionRunBookFanoutTemplate !== "") {
        Ember.set(this.model, "formFanout", "");
      }
    });

    this.addObserver("model.selected", function () {
      let model = this._parentView._parentView._parentView.content;
      if (model.pressName !== "") {
        let params = {};
        let runBooks = model.books.filter(function (b) {
          return b.selected;
        }).map(function (book) {
          return book.displayName;
        });

        restGet(this.controller.module.nwid, `folders/${this.controller.model.folder}/planning-actions/default-presettime-info?publicationDate=${this.controller.publicationDate}&publication=${this.controller.publicationName}&edition=${this.controller.editionName}&zone=${model.zone}&press=${model.pressName}&books=${runBooks.join(";")}`).then((data) => {
          this.controller.updateDefaultPreset(model, data);
        });
      }
    });

  },

  disableProductionRuns: function () {
    if (this.controller.disableProductionRuns) {
      return "disply:none";
    }
    return "";
  }.property(),
  isOverrideStyle: function () {
    var s = "vertical-align: top;";
    return this.controller.model.isOverrideTable ? s + "display: inline" : s + "display: none";
  }.property(),
  isOverride: function () {
    return this.controller.model.isOverrideTable;
  }.property(),
  isSimpleFormFanout: function () {
    return (this.controller.fanoutMode.toLowerCase() === "simpleformfanout" || this.controller.fanoutMode === "TemplateBasedFormFanout");
  }.property(),
  customPropertyName: function () {
    return this.controller.module.tableValue;
  }.property(),
  overrideOptions: function () {
    var overrideCustomData = this.controller.model.overrideCustomData;
    if (typeof overrideCustomData === "undefined") {
      return {};
    }
    return overrideCustomData.options;
  }.property(),
  availableFanoutTemplates: function () {
    if (this.controller.fanoutMode === "TemplateBasedFormFanout") {
      const templates = this.controller.model.availableFanouts[this.model.numForms] || [];
      let ret = templates.map(function (t) {
        return {
          value: t.name,
          text: t.name
        }
      });
      ret.unshift({
        text: sandbox.localization.translate("--none--"),
        value: ""
      });
      return ret;
      return [];
    } else if (this.controller.fanoutMode.toLowerCase() === "simpleformfanout") {
      let ret = this.controller.model.availableFanouts.map(function (t) {
        return {
          value: t,
          text: t
        }
      });
      ret.unshift({
        text: sandbox.localization.translate("--none--"),
        value: ""
      });
      return ret;
    } else {
      var that = this;
      var zones = this.controller.model.availableFanouts.fanouts;
      var options;
      for (var z = 0; z < zones.length; z++) {
        var book = zones[z].books.filter(function (b) {
          if (typeof that.model.findId === "undefined") {
            return b.displayName === that.model.displayName;
          } else {
            return b.sourceId === that.model.findId;
          }
        });
        if (book.length > 0) {
          options = book[0].availableFanouts.sort().map(function (fan) {
            return {
              text: fan.replace(".json", ""),
              value: fan
            }
          });
          break;
        } else {
          options = [];
        }
      }

      options.unshift({
        text: sandbox.localization.translate("--none--"),
        value: ""
      });
      return options;
    }


  }.property(),

  fanoutTitle: function() {
    if (this.controller.fanoutMode === "TemplateBasedFormFanout"){
      return sandbox.localization.translate("Fanout Template:");
    }
    return sandbox.localization.translate("Fanout Correction:");
  }.property(),

  availableFormFanouts: function () {
    if (this.controller.fanoutMode.toLowerCase() === "simpleformfanout" || this.controller.fanoutMode === "TemplateBasedFormFanout") {
      let ret = [];
      ret.unshift({
        text: sandbox.localization.translate("--none--"),
        value: ""
      });
      return ret;
    } else {
      var that = this;
      var zones = this.controller.model.availableFanouts.fanouts;
      var options;
      for (var z = 0; z < zones.length; z++) {
        var book = zones[z].books.filter(function (b) {
          return b.sourceId === that.model.findId;
        });
        if (book.length > 0) {
          options = book[0].availableFormFanouts.sort().map(function (fan) {
            return {
              text: fan.replace(".json", ""),
              value: fan
            }
          });
          break;
        } else {
          options = [];
        }
      }

      options.unshift({
        text: sandbox.localization.translate("--none--"),
        value: ""
      });
      return options;
    }


  }.property(),

  productionRunBookFanoutTemplate: function () {
    let tmplt = this.model.productionRunBookFanoutTemplate;
    if (typeof tmplt === "undefined" || "" === tmplt) {
      return "";
    }
    return tmplt.replace(".json", "");
  }.property(),

  manuallyChangedToolTip: function () {
    return sandbox.localization.translate("Manually Changed");
  }.property()
});

var RunView = Ember.View.extend({
  tagName: 'li',
  classNames: ['pw-row', 'section'],
  template: Ember.Handlebars.compile(runTemplate),
  init: function () {
    this.addObserver("model.pressName", function () {
      if (this.model.pressName !== "") {
        let params = {};
        let runBooks = this.model.books.filter(function (b) {
          return b.selected;
        }).map(function (book) {
          return book.displayName;
        });

        restGet(this.controller.module.nwid, `folders/${this.controller.model.folder}/planning-actions/default-presettime-info?publicationDate=${this.controller.publicationDate}&publication=${this.controller.publicationName}&edition=${this.controller.editionName}&zone=${this.model.zone}&press=${this.model.pressName}&books=${runBooks.join(";")}`).then((data) => {
          this.controller.updateDefaultPreset(this.model, data);
        });
      }
      if (typeof this.model.pressName === "undefined" || this.model.pressName === "") {
        Ember.set(this.model, "pressName", this.controller.presses[0]);
      }
    });
  },

  modes: [{
    text: sandbox.localization.translate("Collect"),
    value: "collect"
  },
  {
    text: sandbox.localization.translate("Straight"),
    value: "straight"
  },
  {
    text: sandbox.localization.translate("Double Straight"),
    value: "double"
  }
  ],

  presses: function () {
    return this.controller.presses.map(function (p) {
      let text = p;
      if (text === "") {
        text = sandbox.localization.translate("--Select Press--");
      }
      return {
        text: text,
        value: p
      }
    });
  }.property(),

  isOldPlan: function () {
    let press = this.model.pressName;
    return (typeof press !== "undefined" && press !== "" && !this.controller.presses.includes(press));
  }.property(),

  actions: {
    deleteRun: function () {
      this.controller.deleteRun(this.model);
    }
  }
});



module.exports = Ember.View.extend({
  template: Ember.Handlebars.compile(MainTemplate),
  headerView: HeaderView,
  RunView: RunView,
  BookView: BookView,
  actions: {
    addRun: function () {
      this.controller.addRun(this.model);
    },
    applyAllAction: function () {
      this.controller.applyToAllZones(this.model);
    }
  },
  disableProductionRuns: function () {
    if (!this.controller.editProductionRuns) {
      return "display:none";
    }
    return "";
  }.property()
});