const { restGet } = require('core/managers/rest2');
const { combineUrl } = require('utilities/url');

module.exports = function (root, separator, viewOrActionNwid, rootUrl, store) {
  return function (cursor: Array<Object>, selectedItem: String, changeHistory: boolean) {
    const currCursor = cursor.length > 1 ? cursor.slice(1, cursor.length) : [];
    var cursorForRest = currCursor.map(function (item) {
      return item.name;
    });

    const requestFolderPath = `${root}${separator}${cursorForRest.join(separator)}${separator}`;

    return restGet(viewOrActionNwid, combineUrl(rootUrl, requestFolderPath), {}, { version: 'v1' }).then(function (response) {
      if (typeof response !== 'object' || !Array.isArray(response.items)) return response;

      return {
        ...response,
        items: response.items.map(function (item) {
          return {
            ...item,
            name: item.type === 'disk' ? item.name.replace('\\', '').replace('/', '') : item.name,
            displayName: item.type === 'disk' ? item.name.replace(':\\', '').replace(':/', '') : item.name
          };
        })
      };
    }).then(function (result) {
      var type_orders = ['disk', 'folder', 'file'];
      var sorted_item = result.items.sort(function (a, b) {
        return type_orders.indexOf(a.type) - type_orders.indexOf(b.type) || a.name.localeCompare(b.name);
      });
      store.dispatch({
        type: 'SET_ITEMES',
        items: sorted_item,
        cursor: cursor,
        changeHistory: changeHistory,
        selectedItem: selectedItem
      });

    }).catch(function () {
      store.dispatch({
        type: 'SET_ITEMES',
        items: [],
        cursor: cursor,
        changeHistory: changeHistory,
        selectedItem: selectedItem
      });
    });
  };
};


