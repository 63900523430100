import AbstractAction from 'AbstractAction';
import sandbox from 'sandbox';
import CustomInfoSetup from 'setups/CustomInfoSetup/CustomInfoSetup';

const translate = sandbox.localization.translate;

let infoUID = 0;

export default AbstractAction.extend({

  execute: function (selected, position = {}) {
    let elementId = 'CustomInfo-' + (infoUID++);
    let simpleSetup = new CustomInfoSetup(() => { }, () => { }, this);
    let actionInstanceNwId = this.nwid;

    function getDataFromRest() {
      sandbox.request.rest(actionInstanceNwId).getCustomInfo(selected[0].nwid).then((res) => {
        simpleSetup.dataReceived(res, getDataFromRest);
      });
    }

    simpleSetup.showCustomInfoAction = this;
    simpleSetup.win = this.module.win;

    simpleSetup.container = sandbox.dialog.show({
      title: translate('Info Dialog'),
      width: 1000,
      height: 800,
      top: position.top,
      left: position.left,
      buttons: [],
      win: this.module.win,
      init: (content) => {
        sandbox.dom.attr(content, 'id', elementId);
        simpleSetup.element = '#' + elementId;
        simpleSetup.initDone();
        getDataFromRest();
      }
    });

  }
});