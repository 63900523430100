import Ember from 'ember';
import KendoEmber from 'kendo-ember';
import Dialog from 'widgets/Dialog/Dialog';
import chartist from 'chartist';
import { MSEC_IN_HOUR, formatDate } from 'core/dates';
import AbstractAction from 'AbstractAction';

var template = '<br/><div style="text-align: center" class="ct-chart ct-square"></div>';

var Form = Ember.Component.extend({
  layout: Ember.Handlebars.compile(template),
  didInsertElement: function () {
    var rates = this.get('rates');
    var data2 = {
      series: [{
        data: []
      }]
    };
    rates.forEach(function (e) {
      var rateDate = new Date(Number(e.time));
      if (rateDate.getTime() > Date.now() - MSEC_IN_HOUR) {
        var data3 = {};
        data3.x = rateDate;
        data3.y = e.rate;
        data2.series[0].data.push(data3);
      }
    });
    var options = {
      width: '450px',
      height: '200px',
      fullWidth: true,
      //showArea: true,//this will fill in area below graph
      lineSmooth: chartist.Interpolation.simple({
        divisor: 5
      }),
      axisX: {
        type: chartist.FixedScaleAxis,
        divisor: 6,
        fullWidth: true,
        //type: Chartist.AutoScaleAxis, enable to show last line on graph
        //scaleMinSpace: 50,
        labelInterpolationFnc: function (value) {
          return formatDate(new Date(value), 'hh:mm:ss');
        }
      },
      axisY: {
        onlyInteger: true,
        low: 0
      }
    };

    if (data2.series[0].data.length === 0) {
      var data3 = {};
      data3.x = new Date();
      data3.y = 0;
      data2.series[0].data.push(data3);
      var data4 = {}
      data4.x = new Date(Date.now() - MSEC_IN_HOUR);
      data4.y = 0;
      data2.series[0].data.push(data4);
    }

    new chartist.Line('.ct-chart', data2, options);

  }
});

module.exports = AbstractAction.extend({
  execute: function (args) {
    var dialog = new Dialog({
      title: "Rate",
      height: "300px",
      modal: true
    });

    //var el = document.getElementsByClassName('.ct-square');
    //console.log(this, args);
    var form = Form.create({
      rates: args[0]
    });

    form.appendTo(dialog.contentElement);
    var that = this;
    dialog.appendButton("Close");
    Ember.bind(this, 'controller.content', 'parentView.controller.content');

    //form.on('didLoad', function(){
    // dialog.append("<p>should appear after/below chart!</p>")
    //});

  }
}, "ShowRateGraphAction");
