import React, { Component } from 'react';
import PropTypes from 'prop-types';

import sandbox from 'sandbox';

const translate = sandbox.localization.translate;
const labels = {
    Page: translate('Page'),
    Direction : translate(' '),
    PlannedOffset: translate('Planned Offset'),
    ActualOffset: translate('Actual Offset'),
    Crop: translate('Crop'),
    Scale: translate('Scale')
};

module.exports = class CreepValuesHeader extends Component {

  static propTypes = {
    applied : PropTypes.bool,
    cropped: PropTypes.bool,
  }

  render() {

      const { applied, cropped } = this.props;

      if (!applied) {
          return (
              <tr className='creep-thead-row'>
                <th></th>
                <th className='creep-thead-col'>{ labels.Page } </th>
                <th className='creep-thead-col'>{ labels.Direction } </th>
                <th className='creep-thead-col'>{ labels.PlannedOffset }</th>
              </tr>
          );
      } else {


        const lastColumnHeader = cropped ? labels.Crop : labels.Scale;
        return (
            <tr className='creep-thead-row'>
              <th></th>
              <th className='creep-thead-col'>{ labels.Page } </th>
              <th className='creep-thead-col'>{ labels.Direction } </th>
              <th className='creep-thead-col'>{ labels.PlannedOffset }</th>
              <th className='creep-thead-col'>{ labels.ActualOffset }</th>
              <th className='creep-thead-col'>{ lastColumnHeader }</th>
            </tr>
        );
      }
    }
}