import OpenDialogAction from './OpenDialogAction';

const isUndefined = o => typeof o === 'undefined';

export default OpenDialogAction.extend({
  isApplicable: function (args) {
    if (!isUndefined(args) && args.length > 0 && !args[0].derivedSecurity) {
      return true;
    }
  }
});