import React from 'react';
import { createRoot } from 'react-dom/client';
import AbstractModule from 'AbstractModule';
import { arrayToObject, moveItem } from 'utilities/array';
import View from './View';
import TickableModel from '../TickableModel';
import jsutils from 'base/jsUtils';
import { makeFragmentsTableColumns } from './columnsCreator';
import { createObjectComparator, composeComparators } from 'core/comparators';
import { fromServerDate } from 'core/dates';
import { savePreferences } from 'core/managers/preferences';
import { applyToolbarButtonsStatusFromColumnFilters } from './utils';
import {
  DEFAULT_SORT,
  THROTTLE_WAIT,
  TOOLBAR_BUTTONS_TO_COLUMN_FILTER_VALUES_MAP,
  TOOLBAR_BUTTONS_TO_COLUMN_KEYS_MAP
} from './constants';

import {
  checkDateCondition,
  checkTextCondition,
  extractColumnPreferences,
  reduceColumnsToFilterBy,
  FILTER_DATA_TYPE,
  FILTER_TYPE,
  reduceColumnsToSortBy,
  applyColumnSortPreferences,
  extractColumnSortPreferences
} from 'widgets/ReactDataGrid/utils';

import { translate } from 'core/services/localization';

export default AbstractModule.extend({

  initDone: function () {
    this.toolbar = this.createToolbar();
    this.updates = [];
    this.tickUpdateHandlerThrottled = jsutils.throttle(this.tickUpdateHandler, THROTTLE_WAIT, {
      leading: false,
      trailing: true
    });
    this.reactRoot = createRoot(this.domElement);
  },

  firstTickReceived: function (data) {
    this.preferences = data.preferences || {};
    this.columnsToSortBy = data.preferences.columnsToSortBy || DEFAULT_SORT;
    this.filtersEnabled = this.preferences.table?.filtersEnabled || false;
    this.initToolbar();
    this.tickableModel = new TickableModel();

    this.tickableModel.firstTickHandler(data.model);
    this.buildViewModel(data.model);

  },

  tickUpdate: function (data) {
    this.updates = this.updates.concat(data.model);
    this.tickUpdateHandlerThrottled();
  },

  tickUpdateHandler: function () {
    this.tickableModel.tickUpdateHandler(this.updates);
    this.updates = [];
    this.buildViewModel();
  },


  initToolbar: function () {
    this.toolbar.addItem({
      label: translate('Toggle Filters'),
      name: 'toggleFilters',
      _isApplicable: true,
      icon: 'filter_list',
      iconSprite: 'general',
      itemType: 'push',
      checked: this.filtersEnabled,
      execute: this.toggleFilters.bind(this)
    });

    this.toolbar.addItem({
      label: translate('Editorial Fragments'),
      name: 'editorialFragments',
      checked: false,
      _isApplicable: true,
      icon: 'editorial',
      iconSprite: 'general',
      tooltip: translate('Show Editorial Fragments Only'),
      itemType: 'push',
      groupName: 'fragmentType',
      execute: this.handleToolbarButtonsClick.bind(this)
    });

    this.toolbar.addItem({
      label: translate('Ad Fragments'),
      name: 'adFragments',
      checked: false,
      _isApplicable: true,
      icon: 'ad',
      iconSprite: 'general',
      tooltip: translate('Show Ad Fragments Only'),
      itemType: 'push',
      groupName: 'fragmentType',
      execute: this.handleToolbarButtonsClick.bind(this)
    });

    this.toolbar.addItem({
      label: translate('Missing Fragments'),
      name: 'missingFragments',
      checked: false,
      _isApplicable: true,
      icon: 'missing_pages',
      tooltip: translate('Show Missing Fragments'),
      itemType: 'push',
      groupName: 'fragmentState',
      execute: this.handleToolbarButtonsClick.bind(this)
    });

    this.toolbar.addItem({
      label: translate('Error Fragments'),
      name: 'errorFragments',
      checked: false,
      _isApplicable: true,
      icon: 'error_pages',
      tooltip: translate('Show Error Fragments'),
      itemType: 'push',
      groupName: 'fragmentState',
      execute: this.handleToolbarButtonsClick.bind(this)
    });

    this.toolbar.addItem({
      label: translate('Waiting For Approval'),
      name: 'waitingForApprovalFragments',
      checked: false,
      _isApplicable: true,
      icon: 'waiting_for_approval',
      tooltip: translate('Show Waiting For Approval'),
      itemType: 'push',
      groupName: 'fragmentState',
      execute: this.handleToolbarButtonsClick.bind(this)
    });

  },

  buildViewModel: function () {
    const model = this.tickableModel.model();

    this.viewModel = {
      fragments: model.fragments.map((fragment, index) => {
        return {
          ...fragment,
          completionTime: fromServerDate(fragment.completionTime),
          index
        };
      }),
    };

    this.viewModel.sortedFragments = [...this.viewModel.fragments];
    this.viewModel.filteredFragments = [...this.viewModel.sortedFragments];

    this.fragmentsTableColumns = makeFragmentsTableColumns(this.viewModel, this);
    this.sortFragments();
    this.filterFragments();
    this.updateSelectedRows();
    this.render();
  },

  sortFragments: function () {
    if (this.columnsToSortBy.length > 0) {
      const preferencesColumnsToSortBy = applyColumnSortPreferences(this.fragmentsTableColumns, this.columnsToSortBy);

      const comparator = composeComparators(preferencesColumnsToSortBy.map(col => {
        return createObjectComparator(col.sortValueGetter || col.key, col.sortType, col.ascending);
      }));
      this.viewModel.sortedFragments = this.viewModel.sortedFragments.sort(comparator);
    }
  },

  filterFragments: function () {
    this.toolbar.items.forEach(item => {
      if (typeof TOOLBAR_BUTTONS_TO_COLUMN_KEYS_MAP[item.name] !== 'undefined') {
        item.checked = false;
      } else if (item.name === 'toggleFilters') {
        item.checked = this.filtersEnabled;
      }
    });

    if (!this.filtersEnabled) {
      this.viewModel.filteredFragments = this.viewModel.sortedFragments;

    } else {
      const columnsToFilterBy = reduceColumnsToFilterBy(this.fragmentsTableColumns);
      applyToolbarButtonsStatusFromColumnFilters(this, columnsToFilterBy);
      this.viewModel.filteredFragments = this.viewModel.sortedFragments.filter(fragment => {

        let match = true;
        for (const col of columnsToFilterBy) {
          const filter = col.filter;

          if (filter.type === FILTER_TYPE.DATE) {
            const time = typeof col.filterValueGetter === 'function' ? col.filterValueGetter(fragment) : fragment[col.key];
            if (time) {
              match = checkDateCondition(time, filter);
            }
          } else if (filter.type === FILTER_TYPE.MULTISELECT) {
            if (Array.isArray(filter.selected) && filter.selected.length > 0) {
              const filterValue = typeof col.filterValueGetter === 'function' ? col.filterValueGetter(fragment) : fragment[col.key];
              match = filter.selected.some(s => s === filterValue);
            }
          } else if (filter.type === FILTER_TYPE.TEXT && filter.textValue) {
            if (filter.dataType === FILTER_DATA_TYPE.TEXT) {
              const text = typeof col.filterValueGetter === 'function' ? col.filterValueGetter(fragment) : fragment[col.key];
              match = checkTextCondition(text, filter);

            }
          }
          if (!match) {
            break;
          }
        }
        return match;
      });
    }
  },

  toggleFilters: function (pushed) {

    this.filtersEnabled = pushed;

    this.toolbar.setItemChecked('toggleFilters', pushed);

    this.filterFragments();

    this.saveFragmentsColumnPreferences();

    this.buildViewModel();
  },

  handleToolbarButtonsClick: function (checked, buttonName) {

    if (checked) {
      this.toolbar.setItemChecked('toggleFilters', checked);
      this.filtersEnabled = checked;
    }

    const column = this.fragmentsTableColumns.find(col => col.key === TOOLBAR_BUTTONS_TO_COLUMN_KEYS_MAP[buttonName]);
    if (checked) {
      column.filter.selected = [TOOLBAR_BUTTONS_TO_COLUMN_FILTER_VALUES_MAP[buttonName]];

    } else {
      column.filter.selected = [];
    }

    this.filterFragments();

    const columns = extractColumnPreferences(this.fragmentsTableColumns);

    this.savePreferences({
      ...this.preferences,

      table: {
        ...this.preferences.table,
        filtersEnabled: this.filtersEnabled,
        fragmentColumns: columns
      }
    });
    this.render();
  },

  handleFragmentColumnClick: function (sortType) {
    return (columnKey, sortValueGetter, multiSort) => {
      this.columnsToSortBy = reduceColumnsToSortBy(this.fragmentsTableColumns, this.columnsToSortBy, columnKey, multiSort);
      this.sortFragments();
      this.filterFragments();
      this.savePreferences({
        ...this.preferences,
        columnsToSortBy: extractColumnSortPreferences(this.columnsToSortBy)
      });
      this.render();
    };
  },

  handleDoubleClickRow: function (rowIndex, rowContent, e) {
    this.navigateByViewLink(rowContent);
  },


  saveFragmentsColumnPreferences: function () {
    const fragmentColumns = extractColumnPreferences(this.fragmentsTableColumns);
    this.savePreferences({
      table: {
        ...this.preferences.table,
        filtersEnabled: this.filtersEnabled,
        fragmentColumns,
      }
    });
  },

  savePreferences: function (preferences) {
    this.preferences = Object.assign(this.preferences, preferences);
    savePreferences(this.getRequiredParameters(), this.preferences);
  },

  handleFragmentsTableColumnsFilter: function (columns) {
    this.fragmentsTableColumns.forEach(col => {
      if (columns[col.key]) {
        col.visible = columns[col.key].visible;
      }
    });

    this.saveFragmentsColumnPreferences();
  },

  handleFragmentsTableColumnOrder: function (columns, oldIndex, newIndex) {
    moveItem(this.fragmentsTableColumns, oldIndex, newIndex);
    this.saveFragmentsColumnPreferences();
    this.render();
  },

  handleFragmentsTableColumnResize: function (columns) {
    this.fragmentsTableColumns.forEach(col => {
      if (columns[col.key]) {
        col.width = columns[col.key].width;
      }
    });

    this.saveFragmentsColumnPreferences();
  },

  handleFragmentColumnFilterChange: function (column, columnFilter) {
    if (!column || !column.filter || !column.filter.type) {
      return;
    }

    column.filter = {
      ...column.filter,
      ...columnFilter
    };

    this.saveFragmentsColumnPreferences();
    this.filterFragments();
    this.render();
  },

  updateSelectedRows: function () {
    const rowsByNwid = arrayToObject(this.viewModel.fragments, 'nwid');

    const selectedRows = this.selected.reduce((acc, row) => {
      const updatedRow = rowsByNwid[row.nwid];
      if (updatedRow) {
        acc.push(updatedRow);
      }

      return acc;
    }, []);

    this.updateSelected(selectedRows);
  },

  handleTableSelect: function (selectedRows) {

    this.updateSelected(selectedRows);
  },

  handleRowContextMenu: function (clickedRow, selectedRows, e) {
    this.showContextMenu(clickedRow, selectedRows, e);
  },

  // handleTableViewKeyDown: function (e) {
  //   if (this.selected.length <= 0 || this.viewModel.rowType !== 'publication') {
  //     return;
  //   }

  //   if ((e.ctrlKey || e.metaKey) && e.code === 'KeyC') { // CTRL + C
  //     const actions = this.getRelevantActions(this.selected[0]);
  //     const copyAction = actions.find(a => a.actionDefinitionName === 'CopyActionCR');
  //     if (copyAction && copyAction.isApplicable(this.selected)) {
  //       copyAction.execute(this.selected);
  //     }
  //   } else if ((e.ctrlKey || e.metaKey) && e.code === 'KeyV') { // CTRL + V
  //     const actions = this.getRelevantActions(this.selected[0]);
  //     const copyAction = actions.find(a => a.actionDefinitionName === 'PasteActionCR');
  //     if (copyAction && copyAction.isApplicable(this.selected)) {
  //       copyAction.execute(this.selected);
  //     }
  //   }
  // },

  destroy: function () {
    this._super();
    this.reactRoot?.unmount();
    this.reactRoot = null;
  },

  render: function () {
    this.reactRoot.render(<View module={this}
      viewModel={this.viewModel}
      fragmentsTableColumns={this.fragmentsTableColumns}
    />);
  },
});
