/**
 * Created by moshemal on 9/4/14.
 */

import sandbox from 'sandbox';
import wizardUtils from './../../../utils/wizardUtils';
import PlanSetup from 'sandbox/planSetup';
import { translate } from 'core/services/localization';

'use strict';

function clone(model) {
  var ret = {};
  for (var propertyName in model) {
    ret[propertyName] = model[propertyName];
  }
  return ret;
}

function findBookWithSection(zone, sourceId) {
  for (var k = zone.books.length - 1; k >= 0; k--) {
    var bookSections = zone.books[k].sections;
    for (var b = 0; b < bookSections.length; b++) {
      if (bookSections[b].sectionSourceId === sourceId) {
        return k;
      }
    }
  }
  return -1;
}

function findLinks(zones, master, links) {
  for (var i = 0; i < zones.length; i++) {
    if (zones[i].masterZoneName == master.displayName) {
      links.push(zones[i]);
      findLinks(zones, zones[i], links);
    }
  }
  return links;
}

function getSectionPagination(section) {
  var thisSectionNumPages = isNaN(section.numPages) ? 0 : parseInt(section.numPages, 10);

  if (thisSectionNumPages === 0) {
    return "";
  }

  var start = isNaN(section.startPage) ? 1 : parseInt(section.startPage, 10);
  var last = start + thisSectionNumPages - 1;
  if (isNaN(start) || isNaN(last)) {
    return "";
  }
  return start + "-" + last;

}

function validModel(zones, enableEmptySection) {
  var message = "";
  for (var i = 0; i < zones.length; i++) {
    var sections = zones[i].sections;
    if (sections.length === 0) {
      message = ("Please enter section name in zone " + zones[i].displayName);
      break;
    }
    var namesUsed = [];
    for (var j = 0; j < sections.length; j++) {
      var displayName = sections[j].displayName;
      var startPage = sections[j].startPage;
      var sourceSection = sections[j].sourceSection;
      if (displayName === null || typeof displayName === "undefined" || displayName.length === 0) {
        message = translate('Please enter section name in zone {1}', zones[i].displayName)
        break;
      }
      if (!sourceSection && namesUsed.indexOf(displayName) > -1) {
        message = translate('Section {1} was defined more then once.', displayName)
        break;
      }
      var numPages = parseFloat(sections[j].numPages, 10);
      if (!enableEmptySection && (isNaN(numPages) || numPages <= 0)) {
        message = translate('Please enter number of pages in section {1}', sections[j].displayName)
        break;
      }
      if (numPages % 1 != 0){
       message = ("Number of pages in section " + sections[j].displayName + " is not valid");
       break;
      }
      if (startPage === null || typeof startPage === "undefined") {
        sections[j].startPage = 1;
      }
      namesUsed.push(displayName);
    }
  }
  if (message === "") {
    return true;
  }
  sandbox.dialog.alert(message);
  return false;
}

module.exports = {

  selectedZone: null,

  selectedEdition: null,

  mappedModel: {},

  convertToStepModel: function (model) {
    var stepModel = { editions: [] };
    var publication = Ember.get(model, "publication");
    this.mappedModel[publication.sourceId] = publication;
    var editions = Ember.get(publication, "editions");
    var k, i, zones;
    for (i = 0; i < editions.length; i++) {
      this.mappedModel[editions[i].sourceId] = editions[i];
      var stepEdition = { type: 'edition', displayName: editions[i].displayName, sourceId: editions[i].sourceId, zones: [] };
      zones = Ember.get(editions[i], "zones");
      for (var j = 0; j < zones.length; j++) {
        var splitSectionsToInsert = {};
        this.mappedModel[zones[j].sourceId] = zones[j];
        var links = findLinks(zones, zones[j], []);
        var stepZone = { type: 'zone', parent: editions[i].sourceId, displayName: zones[j].displayName, sourceId: zones[j].sourceId, sections: [] };
        stepZone.links = links;
        stepZone.wrapped = zones[j].wrapped;
        var sections = Ember.get(zones[j], "sections");

        if (sections && sections.length === 1) {
          var displayName = sections[0].displayName;
          if (!displayName) {
            var optionalSections = model.publication.sectionsOptions || [];
            if (optionalSections.length > 0) {
              sections[0].displayName = optionalSections[0].name;
              sections[0].code = optionalSections[0].shortName;
              sections[0].sourceId = model.clientSettings.sourceIdBreadcrumb ? (stepZone.sourceId + "/" + sections[0].displayName).toLowerCase() : sections[0].sourceId;
            }
          }
        }

        for (k = 0; k < sections.length; k++) {
          this.mappedModel[sections[k].sourceId] = sections[k];
          var stepSection = {};
          for (var propertyName in sections[k]) {
            if (propertyName === "pages") {
              continue;
            }
            stepSection[propertyName] = sections[k][propertyName];
          }

          stepSection.wrapped = zones[j].wrapped
          if (sections[k].split) {
            var splitAfter = sections[k].splitAfter;
            var splitNumPages = parseInt(sections[k].splitNumPages, 10);
            var splitSection = clone(sections[k]);
            var splitStartPage = sections[k].splitStartPage;
            var folderNwId = model.folderPreFix ? model.folderPreFix + model.folder + "_" : "";
            splitSection.sourceId = folderNwId + wizardUtils.getSourceId();
            stepSection.numPages = parseInt(sections[k].numPages, 10) - splitNumPages;
            splitSection.numPages = splitNumPages;
            splitSection.sourceSection = stepSection.sourceId;
            splitSection.split = false;
            splitSection.splitSectionIndex = stepSection.splitSectionIndex;
            splitSection.startPage = splitStartPage ? splitStartPage : sections[k].startPage + splitNumPages;
            splitSectionsToInsert[splitAfter] = splitSection;
            splitSection.wrapped = stepZone.wrapped;
            // stepZone.wrapped = true;
          }
          //{type:'section', parent:zones[j].sourceId, displayName:sections[k].displayName, sourceId:sections[k].sourceId,numPages:sections[k].numPages};
          stepZone.sections.addObject(stepSection);
        }

        for (var whereToInsert in splitSectionsToInsert) {
          var splitSectionIndex = splitSectionsToInsert[whereToInsert].splitSectionIndex;
          wizardUtils.insertAt(stepZone.sections, splitSectionsToInsert[whereToInsert], parseInt(splitSectionIndex, 10))
          //for (k=stepZone.sections.length-1;k>=0;k--){
          //  if (stepZone.sections[k].sourceId === whereToInsert){
          //    stepZone.sections.insertAt(k+1, splitSectionsToInsert[whereToInsert]);
          //  }
          //}
        }

        stepEdition.zones.addObject(stepZone);
      }
      stepModel.editions.addObject(stepEdition);
    }
    Ember.set(this, "model", stepModel);

    zones = this.zones();

    stepModel.sections = publication.sectionsOptions || [];
    stepModel.continueNumbers = model.continueNumbers;
	stepModel.sourceIdBreadcrumb = model.clientSettings.sourceIdBreadcrumb;
		
      for (i=0;i<zones.length;i++){
      	if (zones[i].sections.length == 0){
	        var zoneSourceId = zones[i].sourceId;
	        var sectionSourceId = stepModel.sourceIdBreadcrumb ? null : folderNwId + wizardUtils.getSourceId();
	        var sections = [{ type: 'section', parent: zoneSourceId.toLowerCase(), startPage: 1, oldStartPage: 1, numPages: 0, sourceId: sectionSourceId }];
	        zones[i].sections = sections;
	    }
        this.notifyPropertyChange(zones[i].sections[0], "order", 0);
      }
    stepModel.sourceIdBreadcrumb = model.clientSettings.sourceIdBreadcrumb;
    return stepModel;
  },

  convertFromStepModel: function (model, settings) {
    var enableEmptySection = settings.enableEmptySection;
    var customFields = settings.customFields || [];
    var fields = [];
    customFields.forEach(function (h) {
      fields.push(h.name);
    });
    var zones = this.zones();
    if (!validModel(zones, enableEmptySection)) {
      return false;
    }
    var publication = Ember.get(model, "publication");
    var planSetupSections = publication.sectionsOptions || [];


    for (var i = 0; i < zones.length; i++) {
      var modelzone = this.mappedModel[zones[i].sourceId];
      modelzone.wrapped = zones[i].wrapped;
      var sections = zones[i].sections;
      var modelSections = this.mappedModel[zones[i].sourceId].sections.slice();
      for (var j = 0, sectionsCounter = 0; j < sections.length; j++) {
        if (sections[j].sourceSection !== void 0) {
          continue;
        }
        var modelSection = (this.mappedModel.hasOwnProperty(sections[j].sourceId)) ?
          this.mappedModel[sections[j].sourceId] : {};
        var prevName = modelSection.displayName;
        modelSections.removeObject(modelSection);
        modelSection.customFields = fields;




        var modelSectionIndex = this.mappedModel[zones[i].sourceId].sections.indexOf(modelSection);
        if (modelSectionIndex !== sectionsCounter) {
          this.mappedModel[zones[i].sourceId].sections.removeObject(modelSection);
          this.mappedModel[zones[i].sourceId].sections.insertAt(sectionsCounter, modelSection);
        }
        var numPagesChanged = false, startWithChanged = false, splitStartPageChanged = false;
        var prevNumPages = Ember.get(modelSection, 'numPages');
        var prevStartWith = Ember.get(modelSection, 'startPage');
        var prevSplitStartPage = Ember.get(modelSection, 'splitStartPage') || 0;

        for (var propertyName in sections[j]) {
          Ember.set(modelSection, propertyName, sections[j][propertyName]);
        }
        modelSection.contentTypes = ["EDITORIAL"];

        //publication.sectionsOptions[s].contentTypes
        for (var s = 0; s < publication.sectionsOptions.length; s++) {
          if (publication.sectionsOptions[s].name === modelSection.displayName) {
            if (!publication.sectionsOptions[s].contentTypes) {
              continue;
            }
            var contentTypes = publication.sectionsOptions[s].contentTypes.contentType;
            if (!(contentTypes instanceof Array)) {
              contentTypes = [contentTypes];
            }
            modelSection.contentTypes = contentTypes;
            break;
          }
        }

        var currentNumPages = Ember.get(modelSection, 'numPages');
        var currentStartWith = Ember.get(modelSection, 'startPage');
        
        numPagesChanged = (prevNumPages !== currentNumPages);
        startWithChanged = (prevStartWith !== currentStartWith);
        	        
        if (modelSection.code === null || typeof modelSection.code === 'undefined') {
          modelSection.code = modelSection.displayName;
        }
        var numPages = isNaN(sections[j].numPages) ? 0 : parseInt(sections[j].numPages, 10);

        modelSection.uiOrder = j;

        // modelSection.split = false;
        delete modelSection.splitAfter;
        delete modelSection.splitNumPages;

        if (sections[j].split) {

          for (var k = j + 1; k < sections.length; k++) {
            if (sections[k].sourceSection !== void 0 && sections[k].sourceSection === sections[j].sourceId) {
              var prevSection = sections[k - 1];
              var splitNumPages = parseInt(sections[k].numPages, 10);
              Ember.set(modelSection, "splitAfter", prevSection.sourceSection ? prevSection.sourceSection : prevSection.sourceId);
              Ember.set(modelSection, "splitNumPages", splitNumPages);
              Ember.set(modelSection, "splitStartPage", parseInt(sections[k].pagination.split("-")[0]));
              Ember.set(modelSection, "splitSectionIndex", k);
              modelSection.splitUiOrder = k;
              numPages += splitNumPages;
              break;
            }
          }
          

          Ember.set(modelSection, 'numPages', numPages);
          currentNumPages = Ember.get(modelSection, 'numPages');
          numPagesChanged = (prevNumPages !== currentNumPages);
        }
        var currentSplitStartWith = Ember.get(modelSection, 'splitStartPage') || 0;
        splitStartPageChanged = (prevSplitStartPage !== currentSplitStartWith);	
        modelSection.colors = [];
        for (var plansetupSectionsCounter = 0; plansetupSectionsCounter < planSetupSections.length; plansetupSectionsCounter++) {
          if (planSetupSections[plansetupSectionsCounter].name === modelSection.displayName) {
            var planSetupColors = planSetupSections[plansetupSectionsCounter].colors || { color: [] };
            planSetupColors = planSetupColors.color;
            if (!(planSetupColors instanceof Array)) {
              planSetupColors = [planSetupColors];
            }
            modelSection.colors = planSetupColors;
          }
        }

        var sourceIdBreadcrumb = this.model.sourceIdBreadcrumb;
        var rename = prevName && modelSection.displayName != prevName;

        if (!sourceIdBreadcrumb && rename) {
          var zone = this.mappedModel[zones[i].sourceId];
          if (zone.books) {
            for (var k = 0; k < zone.books.length; k++) {
              if (zone.books[k].displayName == prevName) {
                zone.books[k].displayName = modelSection.displayName;
                break;
              }
            }
          }
        }
        sectionsCounter++;
        if (numPagesChanged || (sourceIdBreadcrumb && rename) || startWithChanged || splitStartPageChanged) {
          var zone = this.mappedModel[zones[i].sourceId];
          if (zone.books) {
            for (var k = zone.books.length - 1; k >= 0; k--) {
              var bookSections = zone.books[k].sections;
              var removeBook = false;
              for (var b = 0; b < bookSections.length; b++) {
                if (bookSections[b].sectionSourceId === modelSection.sourceId) {
                  removeBook = true;
                  break;
                }
              }
              if (removeBook) {
                if ((splitStartPageChanged || startWithChanged) && !numPagesChanged){
                  zone.books[k].startWithChanged = true;
                } else {
                  var defaultLayout = zone.books[k].defaultLayout;
                  zone.books.splice(k, 1);
                  delete modelSection.onBooks;
                  modelSection.defaultLayout = defaultLayout;
                }
              }
            }
          }
        }
        Ember.set(modelSection, 'numPagesChanged', numPagesChanged);
      }
      var deletesBooks = {};
      for (j = 0; j < modelSections.length; j++) {
        var zone = this.mappedModel[zones[i].sourceId];
        var bookIndex = findBookWithSection(zone, modelSections[j].sourceId);
        this.mappedModel[zones[i].sourceId].sections.removeObject(modelSections[j]);
        if (bookIndex > -1) {
          deletesBooks[zone.books[bookIndex].sourceId] = 1;
          zone.books.splice(bookIndex, 1);
        }
      }
      var zone = this.mappedModel[zones[i].sourceId];
      zone.sections.forEach(function (section) {
        if (section.onBooks) {
          for (var key in deletesBooks) {
            var index = section.onBooks.indexOf(key);
            if (index > -1) {
              section.onBooks.splice(index, 1);
            }
          }

        }
      });
    }
    return true;

  },

  setModel: function (model) {
    Ember.set(this, 'model', model);

  },

  isValid: function () {

    return true;
  },

  zones: function () {
    var ret = [];
    var editions = Ember.get(this, 'model.editions');
    for (var i = 0; i < editions.length; i++) {
      var zones = editions[i].zones;
      ret.pushObjects(zones);
    }
    return ret;
  },

  addSection: function (zone, index) {
    var folderNwId = this.model.folderPreFix ? this.model.folderPreFix + this.model.folder + "_" : "";
    var sourceId = this.model.sourceIdBreadcrumb ? null : folderNwId + wizardUtils.getSourceId();
    var section = { type: 'section', sourceId: sourceId, parent: zone.sourceId, numPages: 0 };
    var optionalSections = this.model.sections || [];
    for (var i = 0; i < optionalSections.length; i++) {
      var found = false;
      for (var j = 0; j < zone.sections.length; j++) {
        if (zone.sections[j].displayName === optionalSections[i].name) {
          found = true;
          break;
        }
      }
      if (!found) {
        section.displayName = optionalSections[i].name;
        section.code = optionalSections[i].shortName;
        if (this.model.sourceIdBreadcrumb) {
          section.sourceId = (zone.sourceId + "/" + section.displayName).toLowerCase();
        }
        break;
      }
    }
    var continueNumbers = this.model.continueNumbers;
    var startPage = 1;
    if (index > -1) {
      zone.sections.insertAt(index, section);
    } else {
      if (continueNumbers && zone.sections.length > 0) {
        var lastSection = zone.sections[zone.sections.length - 1];
        var lastSectionStartPage = parseInt(lastSection.startPage, 10);
        var lastSectionNumPages = isNaN(lastSection.numPages) ? 0 : parseInt(lastSection.numPages, 10);
        startPage = lastSectionStartPage + lastSectionNumPages;
      }
      zone.sections.addObject(section);
    }
    section.oldStartPage = startPage;
    section.startPage = startPage;

    return section;
  },

  removeSection: function (zone, section) {
    zone.sections.removeObject(section);
    this.calculatePagination(zone);
  },

  notifyPropertyChange: function (section, property, value) {
    if (property === "numPages" || property === "order") {
      this.calculateOrder(section);
      this.calculatePagination(section, true);



    } else if (property === "startPage") {

      var oldStartPage = Ember.get(section, "oldStartPage");
      if (oldStartPage != Ember.get(section, "startPage")) {
        Ember.set(section, "manualPagination", true);
        this.calculatePagination(section);
        Ember.set(section, "oldStartPage", Ember.get(section, "startPage"));
      }

    } else if (property === "split") {
      var myValue = Ember.get(section, 'split');
      if (myValue) {
        this.splitSection(section);
      } else {
        this.unSplitSection(section);
      }
    } else if (property === "displayName") {
      for (var i = 0; i < this.model.sections.length; i++) {
        var secobj = this.model.sections[i];
        if (secobj.name === value) {
          Ember.set(section, "code", secobj.shortName !== null && typeof secobj.shortName !== 'undefined' ? secobj.shortName : "");
          break;
        }
      }
    }
  },

  splitSection: function (section) {
    if (section.sourceSection !== void 0) {
      Ember.set(section, "split", false);
      return;
    }

    var zone = wizardUtils.matchBySourceId(section.parent, this.zones());
    var newSection = this.addSection(zone, zone.sections.indexOf(section) + 1);

    Ember.set(newSection, "sourceSection", section.sourceId);
    Ember.set(newSection, "wrapped", zone.wrapped);
    var numPages, startPage = 1;
    for (var propertyName in section) {
      if (propertyName === "split") {
        continue;
      } else if (propertyName === "numPages") {
        numPages = parseInt(Ember.get(section, 'numPages'), 10);
        if (numPages % 2 == 0){
          Ember.set(section, "numPages", numPages / 2);
          Ember.set(newSection, "numPages", numPages / 2);
        } else {
          Ember.set(section, "numPages", numPages / 2 + 0.5);
          Ember.set(newSection, "numPages", numPages / 2 - 0.5);
        }
        

        Ember.set(section, "numPagesBeforeSplit", numPages);
        Ember.set(newSection, "numPagesBeforeSplit", numPages);

        if (numPages % 2 == 0){
          startPage = parseInt(section.startPage, 10) + numPages / 2;
        } else {
          startPage = parseInt(section.startPage, 10) + numPages / 2 + 0.5;
        }

      } else {
        Ember.set(newSection, propertyName, section[propertyName]);
      }
    }

    Ember.set(newSection, "oldStartPage", startPage);
    Ember.set(newSection, "startPage", startPage);
    this.notifyPropertyChange(section, "numPages", numPages);

    Ember.set(newSection, "code", section.code);

    // var sectionsCopy = [...this.selectedZone.sections];
    // Ember.set(this.selectedZone, "sections", sectionsCopy);

  },

  unSplitSection: function (section) {
    var sections = this.selectedZone.sections;
    var srcId = section.sourceId;
    var numPages = parseInt(section.numPages, 10);
    for (var i = sections.length - 1; i >= 0; i--) {
      if (sections[i].sourceSection === srcId) {
        numPages += parseInt(sections[i].numPages, 10);
        sections.removeObject(sections[i]);
      }
    }
    delete section.numPagesBeforeSplit;
    delete section.splitNumPages;
    Ember.set(section, "numPages", numPages);
    this.notifyPropertyChange(section, "numPages", numPages);

    var sectionsCopy = [...this.selectedZone.sections];
    Ember.set(this.selectedZone, "sections", sectionsCopy);
  },

  applyAll: function (autoLinks) {
    var zones = this.zones();
    var affectedZones = [];
    var prevSectionsSourceIds = {};
    var i, j, key;
    for (i = 0; i < zones.length; i++) {
      if (zones[i].sourceId === this.selectedZone.sourceId) {
        continue;
      }
      if (autoLinks) {
        var found = false;
        var links = this.selectedZone.links;
        for (var f = 0; f < links.length; f++) {
          if (links[f].displayName === zones[i].displayName) {
            found = true;
            break;
          }
        }
        if (!found) {
          continue;
        }
      }


      var sections = zones[i].sections;
      for (j = sections.length - 1; j >= 0; j--) {
        key = zones[i].sourceId + sections[j].displayName;
        prevSectionsSourceIds[key] = sections[j].sourceId;
        this.removeSection(zones[i], sections[j]);
      }
    }
	var copyZoneProps = false;
    for (i = 0; i < this.selectedZone.sections.length; i++) {
      var sourceSection = this.selectedZone.sections[i];
      for (j = 0; j < zones.length; j++) {
        if (zones[j].sourceId === this.selectedZone.sourceId) {
          continue;
        }
		//need to rework on this...
        if (!copyZoneProps){
      	  Ember.set(zones[j], "applyAllAction", true);
          Ember.set(zones[j], "wrapped", this.selectedZone.wrapped);
          
          //zones[j].applyAllAction = true;
          copyZoneProps = true;
        }
        if (autoLinks) {
          var found = false;
          var links = this.selectedZone.links;
          for (var f = 0; f < links.length; f++) {
            if (links[f].displayName === zones[j].displayName) {
              found = true;
              break;
            }
          }
          if (!found) {
            continue;
          }
        }

        var targetSection = {};
        for (var propertyName in sourceSection) {
          var value = sourceSection[propertyName];
          if (propertyName === "sourceSection") {
            if (value !== null && typeof value !== "undefined") {
              value = "$" + sourceSection.displayName + "$";
            }
          }
          targetSection[propertyName] = value;

        }
        key = zones[j].sourceId + targetSection.displayName;
        if (prevSectionsSourceIds.hasOwnProperty(key)) {
          targetSection.sourceId = prevSectionsSourceIds[key];
        } else {
          var folderNwId = this.model.folderPreFix ? this.model.folderPreFix + this.model.folder + "_" : "";
          targetSection.sourceId = this.model.sourceIdBreadcrumb ? (zones[j].sourceId + "/" + targetSection.displayName).toLowerCase() : folderNwId + wizardUtils.getSourceId();
        }
        targetSection.parent = zones[j].sourceId;
        zones[j].sections.addObject(targetSection);
        affectedZones.push(zones[j]);
      }
    }

    // need to update sourceSection property
    for (j = 0; j < zones.length; j++) {
      if (zones[j].sourceId === this.selectedZone.sourceId) {
        continue;
      }
      if (autoLinks) {
        var found = false;
        var links = this.selectedZone.links;
        for (var f = 0; f < links.length; f++) {
          if (links[f].displayName === zones[j].displayName) {
            found = true;
            break;
          }
        }
        if (!found) {
          continue;
        }
      }
      var sections = zones[j].sections;
      sections.forEach(function (section) {
        if (section.split) {
          for (var i = 0; i < sections.length; i++) {
            if (sections[i].sourceSection === "$" + section.displayName + "$") {
              sections[i].sourceSection = section.sourceId;
              break;
            }
          }
        }
      });
    }
    var ret = "";
    affectedZones.forEach(function (z) {
      ret += z.displayName + ",";
    });
    return ret;
  },

  calculateOrder: function (object) {
    var zone = object.type === 'zone' ? object : wizardUtils.matchBySourceId(object.parent, this.zones());
    for (var i = 1; i < zone.sections.length; i++) {
      if (zone.sections[i].splitAfter) {
        zone.sections[i].splitAfter = zone.sections[i - 1].sourceId;
      }
    }
    // var sectionsCopy = [...zone.sections];
    // Ember.set(zone, "sections", sectionsCopy);
  },

  calculatePagination: function (object, orderEvent) {

    var zone = object.type === 'zone' ? object : wizardUtils.matchBySourceId(object.parent, this.zones());
    //var edition = wizardUtils.matchBySourceId(zone.parent, Ember.get(this.model, 'editions'));
    var continueNumbers = this.model.continueNumbers;
    var n = 0;
    for (var i = 0; i < zone.sections.length; i++) {

      if (continueNumbers && !zone.sections[i].manualPagination) {

        Ember.set(zone.sections[i], "oldStartPage", parseInt(n + 1, 10));
        Ember.set(zone.sections[i], "startPage", parseInt(n + 1, 10));
      }
      var pagination = getSectionPagination(zone.sections[i]);

      Ember.set(zone.sections[i], "pagination", pagination);
      if (continueNumbers && pagination !== "") {
        n = parseInt(pagination.split("-")[1]);
      }

    }
  },

  createEmptyTemplate: function () {
    var publication = { type: 'publication' };
    var issueRawDate = new Date();
    var issueDate = issueRawDate.getFullYear() + "-" + (issueRawDate.getMonth() + 1) + "-" + issueRawDate.getDate();
    var folderNwId = this.model.folderPreFix ? this.model.folderPreFix + this.model.folder + "_" : "";
    publication.sourceId = folderNwId + wizardUtils.getSourceId();
    publication.manualPlan = true;
    publication.issueDate = issueDate;
    publication.displayName = "Publication";


    var edition = {
      type: 'edition', displayName: "Edition", code: "E", sourceId: wizardUtils.getSourceId(),
      parent: publication.sourceId
    };

    var zoneSourceId = folderNwId + wizardUtils.getSourceId();
    var sections = [{
      type: 'section', parent: zoneSourceId, sourceId: folderNwId + wizardUtils.getSourceId(),
      startPage: 1, oldStartPage: 1, numPages: 0
    }];
    var zone = {
      type: 'zone', displayName: "Zone", order: 0,
      sections: sections, books: [], sourceId: zoneSourceId,
      parent: edition.sourceId
    };

    edition.zones = [zone];
    publication.editions = [edition];
    return publication;

  }


};

