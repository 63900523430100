/**
 * @name
 * @fileOverview
 * @author sergey
 */
define(['sandbox', 'class', './controllers/ContextMenuController', './views/ContainerView', 'base'],
function (sandbox, Class, Controller, ContainerView, base) {
  'use strict';

  return Class.extend({

    init: function (clickedItem, selectedItems, event) {
      event.preventDefault();

      this._ctrl = Controller.create({
        content: [],
        event: event,
        clickedItem: clickedItem,
        selectedItems: selectedItems
      });
      sandbox.shortcut.register('esc', 'contextmenu', this._ctrl.close, this._ctrl);
    },
    addItem: function (item) {
      this._ctrl.pushObject(item);
    },
    removeItem: function (label) {
      var item = this._ctrl.findBy('label', label);
      if (item) {
        this._ctrl.removeObject(item);
      }
    },
    addSeparator: function () {
      this._ctrl.pushObject({_isSeparator: true});
    },
    show: function () {
      this._view = ContainerView.create({
        controller: this._ctrl
      });
      this._ctrl.set('_view', this._view);
      this._view.append();

      const deferred = base.data.deferred();
      this._ctrl.set('deferred', deferred);

      return deferred.promise();
    },
    close: function () {
      this._ctrl.close();
    }
  });
});