import React, { Component } from 'react';
import sandbox from 'sandbox';
import CreepValues from './CreepValues';
import CreepValuesHeader from './CreepValuesHeader';
import Header from 'src/components/common/headers/Header';
import PropTypes from 'prop-types';

const translate = sandbox.localization.translate;
const labels = {
  creepInfoGroupName: translate('Creep Info'),
  creepMode: translate('Creep Mode'),
  paperType: translate('Paper Type'),
  noCreepPlanned: translate('No creep set in planning'),
  creepValues: translate('Values'),
};

const creepModes = {
  'inside_crop': translate('Inside Crop'),
  'inside_scale': translate('Inside Scale'),
  'inside_stop': translate('Inside Stop'),
  'outside': translate('outside'),
};

module.exports = class CreepInfo extends Component {

  static propTypes = {

    title: PropTypes.string,
    object: PropTypes.any,
    creepInfo: PropTypes.any,

  }

  creepInfoExists = () => {

    const { creepInfo } = this.props;
    return creepInfo;
  }

  creepIsApplied = () => {

    const { object } = this.props;

    return object.PageInfoOnForm.creep !== undefined &&
      object.PageInfoOnForm.creep[0].actual !== undefined;
  }

  cropped = () => {
    const { object } = this.props;

    return this.creepIsApplied() ? object.PageInfoOnForm.creep[0].actual.scale === 1
      : false;
  }

  renderGeneralCreepInfo = () => {

    const { creepInfo } = this.props;

    if (this.creepInfoExists()) {

      const creepMode = creepModes[creepInfo.creepMode.toLowerCase()];

      return <div style={{ height: '0' }}>
        <div className='custom-info-row'>
          <span className='custom-info-row-title'>{labels.paperType}:</span>
          <span className='custom-info-row-content ellipsis' title={creepInfo.paperType}>{creepInfo.paperType}</span>
        </div>

        <div className='custom-info-row'>
          <span className='custom-info-row-title'>{labels.creepMode}:</span>
          <span className='custom-info-row-content ellipsis' title={creepMode}>{creepMode}</span>
        </div>

      </div>
    }
    else {

      return <div className='custom-info-row'>
        <span className='custom-info-row-title'>{labels.creepInfoGroupName}:</span>
        <span className='custom-info-row-content ellipsis' title={labels.noCreepPlanned}>{labels.noCreepPlanned}</span>
      </div>
    }
  }

  renderCreepValues = () => {

    if (!this.creepInfoExists())
      return '';

    const { object, pages } = this.props;

    const applied = this.creepIsApplied();
    const cropped = this.cropped();

    return (
      <div className='custom-info-row'>
        <span className='custom-info-row-title'>{labels.creepValues}:</span>
        <span className='creep custom-info-row-content ellipsis' title={labels.creepValues}>
          <table cellSpacing={1} cellPadding={1} className='creep-table'>
            <thead className='creep-thead'>
              <CreepValuesHeader applied={applied} cropped={cropped} />
            </thead>
            <tbody className='creep-tbody'>
              <CreepValues applied={applied} cropped={cropped} creepValues={object.PageInfoOnForm.creep} pages={pages} />
            </tbody>
          </table>
        </span>
      </div>

    );
  }

  render() {
    const { title } = this.props;

    return <div className='creep-custom-info'>
      <Header>{title}</Header>
      {this.renderGeneralCreepInfo()}
      {this.renderCreepValues()}
    </div>;
  }

}