import { fromServerDate } from 'core/dates';

export function convertUploadDate(files) {
  files.forEach((file) => {
    if (file.uploadTime) {
      file.uploadDate = fromServerDate(file.uploadTime);
    }
  });
}

export function convertAssignDate(files) {
  files.forEach((file) => {
    if (file.associationTime) {
      file.assignDate = fromServerDate(file.associationTime);
    }
  });
}
