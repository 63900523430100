import React from 'react';
import PropTypes from 'prop-types';
import { toLocaleShortDateTime } from 'core/services/localization';
import { fromServerDate } from 'core/dates';
import { translate } from 'core/services/localization';


function PlanImportInfo({ planImportFileName, planImportTime }) {
  const importDateTime = toLocaleShortDateTime(fromServerDate(planImportTime));

  return (
    <div className='crtx-footer-container'>
      <label className='crtx-header-submission-info-label'>{translate('Plan import:')}</label>
      <div className='crtx-header-submission-info-details ellipsis-before ' title={planImportFileName}>
        <bdi>
          {planImportFileName}
        </bdi>
      </div>
      <label className='crtx-header-submission-info-label crtx-form-margin-left'>{translate('Received')}:</label>
      <div title={importDateTime}>
        {importDateTime}
      </div>
    </div>
  );
}

PlanImportInfo.propTypes = {
  planImportFileName: PropTypes.string,
  planImportTime: PropTypes.string
};

export default PlanImportInfo;

