import React from 'react';
import PropTypes from 'prop-types';
import Separation from './separation';

function Separations({
  separations = [],
  style,
}) {

  return <ul className='SeparationsList' style={style}>
    {separations.map((separation, index) => <Separation key={index} separation={separation} />)}
  </ul>;
}

Separations.propTypes = {
  separations: PropTypes.array,
  style: PropTypes.object,
};

export default Separations;