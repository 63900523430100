
import React from 'react';
import { createRoot } from 'react-dom/client';
import jsutils from 'base/jsUtils';
import View from './components/View';
import TickableModel from '../TickableModel';
import TitleComponent from './components/TitleComponent';
import { fetchBreadcrumbs, formatBreadcrumbsCodes } from 'utilities/breadcrumbs';
import { fromServerDate } from 'core/dates';
import AbstractModule from 'AbstractModule';
import { createObjectComparator, COMPARE_TYPE } from 'core/comparators';
import requestManager from 'core/managers/request';
import viewManager from 'core/managers/views';
import settingsManager from 'core/managers/settings';
import { startModule } from 'core/managers/module';
import { translate } from 'core/services/localization';

const THROTTLE_WAIT = 1000;

const fragmentSizes = [
  { fragments: 39, size: 8 },
  { fragments: 100, size: 4 },
  { fragments: 156, size: 2 }
];

const resolveViewLink = (viewLinks, viewClass, icon) => {
  for (var i = 0; i < viewLinks.length; i++) {
    const params = viewManager.getViewInfo(viewLinks[i]);
    if (params.viewClass === viewClass) {
      if (icon) {
        if (params.icon.indexOf(icon) >= 0) {

          return viewLinks[i];
        }
      } else {

        return viewLinks[i];
      }
    }
  }

  return undefined;
};

const composeLinkName = (rootType, rootClass, icon) => {
  return rootType + ':' + rootClass + (icon ? ':' + icon : '');
};

const requestViewLink = (module, model, rootType, rootClass, icon) => {
  const nwid = model.nwid;
  const linkName = composeLinkName(rootType, rootClass, icon);

  return requestManager.retrieveViewLinks(nwid, rootType, module.nwid).then(res => {
    module.linksByTypes[linkName] = resolveViewLink(res.data.viewLinks, rootClass, icon);

    return module.linksByTypes[linkName];
  });
};

const initViewLinks = (module, model) => {
  Object.keys(module.linksByTypes).forEach(linkName => {
    if (module.linksByTypes[linkName] === undefined) {
      const linkParams = linkName.split(':');
      requestViewLink(module, model, ...linkParams);
    }
  });
};

const getFragmentDomNeededSize = (fragments) => {
  const fragmentsLength = fragments.length;
  const acceptableFragmentSizes = fragmentSizes.filter(diffSize => fragmentsLength <= diffSize.fragments);

  if (acceptableFragmentSizes.length === 0) {

    return fragmentSizes[fragmentSizes.length - 1].size;
  }

  return acceptableFragmentSizes[0].size + 2;
};

function openView(module, rootType, viewClass, icon) {
  const rootId = module.viewModel.nwid;
  const linkName = composeLinkName(rootType, viewClass, icon);
  const viewNwid = module.linksByTypes[linkName];
  if (viewNwid) {
    startModule(viewNwid, null, {
      nwid: viewNwid,
      rootId,
      rootType,
      viewClass,
      target: 'main',
      default: true,
      isSecondary: false,
      showCloseButton: true
    });
  }
}

function openContextMenu(module, ev, rootType, rootClass, icon) {
  const nwid = module.viewModel.nwid;
  const linkName = composeLinkName(rootType, rootClass, icon);
  const viewNwid = module.linksByTypes[linkName];

  if (viewNwid) {
    const contextMenuModel = {
      type: rootType,
      nwid: nwid,
      viewLinks: [viewNwid]
    };

    module.showContextMenu(contextMenuModel, [contextMenuModel], ev);
  }
}

const getDeadline = (productionRuns) => {

  return productionRuns && productionRuns.length > 0 ? productionRuns[0].deadline : null;
};

const isNotVirtual = (model) => {

  return model.virtual !== true;
};

const getItemColor = (item) => {
  if (item.type === 'separation') {
    return 'separator';
  }
  const completionState = item.completionState;
  let status = '';

  if (completionState === undefined || completionState === 'none') {
    status = 'empty';
  } else if (completionState === 'error' || completionState === 'reject') {
    status = 'error';
  } else if (completionState === 'waiting') {
    status = 'waiting';
  } else if (completionState === 'inProgress') {
    status = 'inwork';
  } else if (completionState === 'success') {
    status = 'ok';
  }

  return status;
};

const getPages = (sections) => {

  let arr = [];

  sections.filter(isNotVirtual).forEach((section, index) => {
    const size = getFragmentDomNeededSize(section.pages);
    section.pages.sort(createObjectComparator('numberInSection'));

    arr = arr.concat(section.pages).filter(isNotVirtual).map(page => { return { ...page, color: getItemColor(page), size }; });
    if (index + 1 < sections.length) {
      arr.push({ id: -1, nwid: -1, type: 'separation' });
    }
  });

  return arr;
};

const getForms = (books) => {
  let arr = [];

  books.filter(isNotVirtual).forEach((book, index) => {
    book.forms.sort(createObjectComparator('numberInBook'));
    const size = getFragmentDomNeededSize(book.forms);
    arr = arr.concat(book.forms).filter(isNotVirtual).map(form => { return { ...form, color: getItemColor(form), size }; });

    if (index + 1 < books.length) {
      arr.push({ id: -1, nwid: -1, type: 'separation' });
    }
  });

  return arr;
};


const getSections = (sections) => {
  const comparator = settingsManager.get('productSorting').section === 'byPlan' ? createObjectComparator('index', COMPARE_TYPE.NUMBER) : createObjectComparator('name');

  return sections.sort(comparator);
};

const getBooks = (books) => {
  const comparator = settingsManager.get('productSorting').book === 'byPlan' ? createObjectComparator('index', COMPARE_TYPE.NUMBER) : createObjectComparator('name');

  return books.sort(comparator);
};

const getAllPages = (sections) => {
  var arr = [];

  sections.forEach(section => {
    arr = arr.concat(section.pages.filter(isNotVirtual));
  });

  return arr;
};

const getFinishedPages = (sections) => {
  var arr = [];

  sections.forEach(section => {
    arr = arr.concat(section.pages.filter(page => isNotVirtual && page.completionState === 'success'));
  });

  return arr;
};

const getStatusPages = (sections) => {
  let status = '';
  if (getAllPages(sections).length > 0) {
    status = translate('Pages') + ': ' + getFinishedPages(sections).length + ' / ' + getAllPages(sections).length;
  }

  return status;
};

const getAllForms = (books) => {
  var arr = [];

  books.forEach(book => {
    arr = arr.concat(book.forms.filter(isNotVirtual));
  });

  return arr;
};

const getFinishedForms = (books) => {
  var arr = [];

  books.forEach(book => {
    arr = arr.concat(book.forms.filter(form => isNotVirtual && form.completionState === 'success'));
  });

  return arr;
};

const getStatusForms = (books) => {
  let status = '';

  if (getAllForms(books).length > 0) {
    status = translate('Forms') + ': ' + getFinishedForms(books).length + ' / ' + getAllForms(books).length;
  }

  return status;
};

const getProgressBarValue = (aggregate) => {
  if (!aggregate || !aggregate.totalFinishedPlates || !aggregate.totalPlates) {
    return 0;
  }


  return aggregate.totalFinishedPlates / aggregate.totalPlates;
};

const getProductionRuns = (productionRuns) => {

  let runs = productionRuns.map(run => {
    return {
      ...run,
      deadline: fromServerDate(run.deadline),
      aggregate: {
        ...run.aggregate,
        progressBarValue: getProgressBarValue(run.aggregate)
      }
    };
  });

  return runs.sort(createObjectComparator('deadline', COMPARE_TYPE.DATES));
};

module.exports = AbstractModule.extend({

  initDone: function () {

    const title = this.domElement.querySelector('.item-title');
    let content = this.domElement.querySelector('.item-content');
    content.classList.add('crtx-zone-overview');

    this.reactRootTitle = createRoot(title);
    this.reactRootContent = createRoot(content);

    this.updates = [];
    this.tickUpdateHandlerThrottled = jsutils.throttle(this.tickUpdateHandler, THROTTLE_WAIT, {
      leading: false,
      trailing: true
    });

    this.linksByTypes = {
      'sections:BirdEyeLayers': undefined,
      'books:BirdEyeLayers': undefined,
      'books:StructuresTableView:PlatesTable': undefined
    };

  },

  firstTickReceived: function (data) {

    let model = data.model;

    this.viewLinks = model.viewLinks;
    if (model.type === 'productionrun') {
      model = model.zone;
    }

    this.tickableModel = new TickableModel();
    this.tickableModel.firstTickHandler(model);

    initViewLinks(this, model);
    this.buildViewModel();

    fetchBreadcrumbs(model.nwid, model.type).then(breadcrumbs => {
      const title = formatBreadcrumbsCodes(breadcrumbs);
      if (title) {
        this.viewModel.name = title;
      }
      this.renderBreadcrumbs();
    });
  },

  buildViewModel: function () {
    const model = this.tickableModel.model();

    const { name, id, nwid, type, deadlinePassed, books, sections, productionRuns, aggregate } = model;
    const sortedSections = getSections(sections);
    const sortedBooks = getBooks(books);

    this.viewModel = {
      name,
      id,
      nwid,
      type,
      deadline: getDeadline(productionRuns),
      deadlinePassed,
      books: sortedBooks,
      pages: getPages(sortedSections),
      forms: getForms(sortedBooks),
      sections: sortedSections,
      statusPages: getStatusPages(sections),
      statusForms: getStatusForms(books),
      productionRuns: getProductionRuns(productionRuns),
      plates: aggregate,
      module: this
    };

    this.render();
  },

  tickUpdate: function (data) {
    this.updates = this.updates.concat(data.model);
    this.tickUpdateHandlerThrottled();
  },

  tickUpdateHandler: function () {
    this.tickableModel.tickUpdateHandler(this.updates);
    this.updates = [];
    this.buildViewModel();
  },

  openPages: function () {
    openView(this, 'sections', 'BirdEyeLayers');
  },
  openForms: function () {
    openView(this, 'books', 'BirdEyeLayers');
  },
  openPlates: function () {
    openView(this, 'books', 'StructuresTableView', 'PlatesTable');
  },
  contextMenuPages: function (ev) {
    openContextMenu(this, ev, 'sections', 'BirdEyeLayers');
  },
  contextMenuForms: function (ev) {
    openContextMenu(this, ev, 'books', 'BirdEyeLayers');
  },
  contextMenuPlates: function (ev) {
    openContextMenu(this, ev, 'books', 'StructuresTableView', 'PlatesTable');
  },

  destroy: function () {
    this._super();
    this.reactRootTitle.unmount();
    this.reactRootContent.unmount();
  },

  renderBreadcrumbs: function () {
    this.reactRootTitle.render(<TitleComponent model={this.viewModel}></TitleComponent>);
  },

  render: function () {

    this.reactRootContent.render(
      <View
        module={this}
        model={this.viewModel}
      />);
  }

});
