import BaseTreeView from '../BaseTreeView/BaseTreeView';
import childrenTransforms from './childrenTransforms';

export default BaseTreeView.extend({
  initDone: function () {
    this._super();

    this.dataAdapter.setChildrenTransforms(childrenTransforms);
  }
}, "PressTree");

