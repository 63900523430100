/* @flow weak */

const sandbox = require('sandbox');
const { translate } = sandbox.localization;

const Labels = {
  addTable: translate('Add Table'),
  table: translate('Table'),
  removeTable: translate('Remove Table'),
  douplicateTable: translate('Duplicate Table'),
  columns: translate('Columns'),
  name: translate('Name'),
  targetType: translate('Target Type'),
  matchingMode: translate('Matching Mode'),
  overridable: translate('Overridable'),
  singleValue: translate('Only one non-empty value in a row'),
  publication: translate('Publication'),
  flowstep: translate('Flow Step'),
  flowsteps: translate('Flow Steps (multiselect)'),
  resources: translate('Resources (multiselect)'),
  tolerance: translate('Tolerance'),
  displayName: translate('Display Name'),
  defaultValue: translate('Default Value'),
  fieldType: translate('Field Type'),
  resourceType: translate('Resource Type'),
  selectors: translate('Selectors'),
  addSelector: translate('Add Selector'),
  value: translate('Value'),
  values: translate('Values'),
  addValue: translate('Add Value'),
  removeColumn: translate('Remove Column'),
  removeTableMessage: (tableName) => translate('Are you sure you want to remove table {1}?', tableName),
  removeTableTitle: (tableName) => translate('Remove table {1}', tableName),
  removeColumnMessage: translate('Are you sure you want to remove column'),
  removeColumnTitle: translate('Remove column'),
  saveError: translate('Save Error'),
  saveErrorinASingleValueTable: translate('Save Error in a Single Value Table'),
  atLeastOneRowWithMultipleValuesIn: translate('At least one row with multiple values in'),
  noEmptyTableName: translate("Can't save table with empty name"),
  noExistingTableName: translate('There is already a table with the same name'),
  duplicationInUniqueColumn: (tableName, displayName, value) => translate('The value "{1}" in column "{2}" of table "{3}" appears more than once', value, displayName, tableName),
  noValidSelectorsOrValues: translate('All tables must have valid selectors and values'),
  freeText: translate('Free text'),
  unique: translate('Unique'),
  dropdownOptions: translate('Dropdown Options'),
  addOption: translate('Add option'),
  removeOption: translate('Remove option'),
  maxCharacters: translate('Max characters'),

  string: translate('String'),
  multiselect: translate('Multiselect'),
  matchlist: translate('Match List'),
  checkbox: translate('Checkbox'),
  number: translate('Number'),
  measurement: translate('Measurement'),
  percentage: translate('Percentage'),
  degrees: translate('Degrees'),
  dayInWeek: translate('Day In Week'),
  //publication: translate('Publication'),
  edition: translate('Edition'),
  zone: translate('Zone'),
  section: translate('Section'),
  book: translate('Book'),
  form: translate('Form'),
  page: translate('Page'),
  plate: translate('Plate'),
  productionRun: translate('Production run'),
  broadsheet: translate('Broadsheet'),
  tabs: translate('Tabs'),
  twoUps: translate('2 ups'),
  fourUps: translate('4 ups'),
  eightUps: translate('8 ups'),
  customLayoutGroup: translate('Custom Layout Group'),
  pageSize: translate('Page size'),
  siteListNames: translate('Site list (Names)'),
  siteName: translate('Site (Name)'),
  siteListCodes: translate('Site list (Codes)'),
  siteCode: translate('Site (Code)'),
  deadline: translate('Deadline'),
  date: translate('Date'),
  securityGroup: translate('Security Group'),
  releaseOffset: translate('Release Offset'),
  hold: translate('Hold'),
  everyday: translate('Everyday'),
  sunday: translate('Sunday'),
  monday: translate('Monday'),
  tuesday: translate('Tuesday'),
  wednesday: translate('Wednesday'),
  thursday: translate('Thursday'),
  friday: translate('Friday'),
  saturday: translate('Saturday'),

  //broadsheet: translate('Broadsheet'),
  tabloid: translate('Tabloid'),

  sampleTables: translate('Sample tables'),
  systemTables: translate('System tables'),
  all: translate('All'),
  allLayoutGroups: translate('All Layout Groups'),
  press: translate('Press'),
  pinColumnForScroll: translate('Pinned column'),
  columnsFromTheLeft: translate('All columns from the left will be pinned as well'),
  pinColumn: translate('Pin column'),
};

module.exports = Labels;
