import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Radio extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  static propsTypes = {
    name: PropTypes.string,
    checked: PropTypes.bool,
    value: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    onChange: PropTypes.func
  }

  static defaultProps = {
    disabled: false
  }

  onChange(event) {
    this.props.onChange(event, event.target.value);
  }

  render() {
    const { name, checked, value, className, disabled, style, onChange, ...props } = this.props;
    const disabledClassName = disabled ? 'disabled' : '';

    return <input type='radio' value={value} className={`crtx-radio ${className} ${disabledClassName}`} name={name} style={style} checked={checked} disabled={disabled} onChange={this.onChange} />
  }

};
module.exports = Radio;
