import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class FilterToggleButton extends Component {
  static propTypes = {
    textButton: PropTypes.string,
    checked: PropTypes.bool,
    onClick: PropTypes.func
  };

  handleButtonClick = () => {
    const { onClick } = this.props;
    onClick();
  }

  render() {
    const { textButton, checked } = this.props;
    const checkedClassName = checked ? 'checked' : undefined;

    return <div className={`filter-toggle-button ${checkedClassName}` /*this.getClassName(checked)*/} onClick={this.handleButtonClick}>
      {textButton}
    </div>
  }

}