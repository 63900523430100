import { round } from 'utilities/math';
import { translate } from 'core/services/localization';

export const INCH = 'inch';
export const MM = 'mm';
export const PT = 'pt';

const FACTOR_BY_UNIT = {
  inch: 1,
  mm: 25.4,
  pt: 72
};

const SYMBOL_BY_UNIT = {
  inch: translate('in'),
  mm: translate('mm'),
  pt: translate('pt'),
};

const DECIMALS_BY_UNIT = {
  inch: 3,
  mm: 2,
  pt: 1
};

export const PRECISION = {
  LOW: -1,
  MEDIUM: 0,
  HIGH: 1
};

export const getConversionFactor = (lengthUnit = 'inch') => {
  return FACTOR_BY_UNIT[lengthUnit] || 1;
};

export const getUnitSymbol = (lengthUnit = 'inch') => {
  return SYMBOL_BY_UNIT[lengthUnit] || '';
};

export const getDecimalPlaces = (lengthUnit = 'inch', precision = PRECISION.MEDIUM) => {
  return DECIMALS_BY_UNIT[lengthUnit] + precision;
};

export const roundUserUnits = (value, lengthUnit = INCH, precision = PRECISION.MEDIUM) => {
  if (isNaN(value)) {
    return value;
  }

  return round(value, getDecimalPlaces(lengthUnit, precision));
};

export const userUnitsToPixels = (userUnits, resolution, lengthUnit, zoom = 1) => {
  if (isNaN(userUnits)) {
    return userUnits;
  }

  return userUnits / getConversionFactor(lengthUnit) * resolution * zoom;
};

export const inchesToPixels = (inches, resolution, zoom = 1) => {
  if (isNaN(inches)) {
    return inches;
  }

  return inches * resolution * zoom;
};

export const pixelsToUserUnits = (pixels, resolution, lengthUnit, zoom = 1) => {
  if (isNaN(pixels)) {
    return pixels;
  }

  return pixels / resolution / zoom * getConversionFactor(lengthUnit);
};

export const pixelsToUserUnitsRounded = (pixels, resolution, lengthUnit, zoom = 1, precision = PRECISION.MEDIUM) => {
  return roundUserUnits(pixelsToUserUnits(pixels, resolution, lengthUnit, zoom), lengthUnit, precision);
};

export const pointPixelsToUserUnits = (point, resolution, lengthUnit, zoom = 1) => {
  if (!point) {
    return point;
  }

  const { x, y } = point;
  const { xResolution, yResolution } = resolution;

  return {
    x: pixelsToUserUnits(x, xResolution, lengthUnit, zoom),
    y: pixelsToUserUnits(y, yResolution, lengthUnit, zoom),
  };
};

export const pointPixelsToUserUnitsRounded = (point, resolution, lengthUnit, zoom = 1, precision = PRECISION.MEDIUM) => {
  if (!point) {
    return point;
  }

  const { x, y } = pointPixelsToUserUnits(point, resolution, lengthUnit, zoom);

  return {
    x: roundUserUnits(x, lengthUnit, precision),
    y: roundUserUnits(y, lengthUnit, precision)
  };
};

export const inchesToUserUnits = (inches, lengthUnit) => {
  if (isNaN(inches)) {
    return inches;
  }

  return inches * getConversionFactor(lengthUnit);
};

export const inchesToUserUnitsRounded = (inches, lengthUnit, precision = PRECISION.MEDIUM) => {
  if (isNaN(inches)) {
    return inches;
  }

  return roundUserUnits(inchesToUserUnits(inches, lengthUnit), lengthUnit, precision);
};
