/**
 * Created by moshemal on 7/6/15.
 */

/**
 * Created by moshemal on 7/5/15.
 */

define(['AbstractAction', './SaveBinder'], function (AbstractAction, Save) {

  'use strict'

  return AbstractAction.extend({
    isApplicable: function(){
      return this.module._status.isEditable;
    },
    execute: function () {
      var module = this.module;
      Save.prototype.execute.call(this).then(function(){
        module._exitEditBinder();
      });
    }
  });
});
