import tickWorker from 'core/workers/tickWorker';
import appPromises from 'core/managers/appPromises';

async function openView(params) {
  let result;

  try {
    const view = await tickWorker.openView(params);
    result = view.id;
  } catch (err) {
    result = err.message;
    console.error('### automation.openView()', err);
  }

  return result;
}

function waitForLoggedIn() {
  const loginPromise = appPromises.getPromise(appPromises.states.LOGGED_IN);

  return new Promise((resolve, reject) => {
    loginPromise.then(res => resolve(res));
    loginPromise.fail(res => {
      console.error('### automation.waitForLoggedIn() - Login Failed', res);
      resolve(res);
    });
  });
}

window.automation = {
  openView,
  waitForLoggedIn,
};