/**
 * @name rulers
 * @file Creates and draws rules on Fabric canvas
 *
 * @author Boris
 * @since: 2016-09-27
 */

import utils from '../utils/utils';
import Fabric from 'fabric';

const MIN_PIXELS_BETWEEN_MARKERS = 96; // pixels
const STEPS_BETWEEN_INCH_MARKERS = 16;
const STEPS_BETWEEN_CM_MARKERS = 10;
const L1_TICK = 18; // tallest tick length in pixels
const L2_TICK = 12; // pixels
const L4_TICK = 8; // pixels
const L8_TICK = 4; // pixels
const L16_TICK = 2; // shortest tick length in pixels
const RULER_COLOR = 'white';
const TICK_COLOR = '#888888';
const FONT_SIZE = 10; // pixels
const FONT_FAMILY = 'Verdana';
const FONT_COLOR = '#888888';

export default (hrulerCanvas, vrulerCanvas) => {

  const getStepsBetweenMarkers = () => {
    return utils.getUserUnits() === 'inch' ? STEPS_BETWEEN_INCH_MARKERS : STEPS_BETWEEN_CM_MARKERS;
  };

  const createRulerRect = (width, height) => {
    return new Fabric.Rect({
      width: width,
      height: height,
      fill: RULER_COLOR,
      selectable: false,
      evented: false
    });
  };

  const createHRulerRect = (length, thickness) => {
    return createRulerRect(length, thickness);
  };

  const createVRulerRect = (length, thickness) => {
    return createRulerRect(thickness, length);
  };

  const createLine = (coords) => {
    return new Fabric.Line(coords, {
      stroke: TICK_COLOR,
      selectable: false,
      evented: false
    });
  };

  const createHRulerTick = (distance, from, to) => {
    return createLine([distance, from, distance, to]);
  };

  const createVRulerTick = (distance, from, to) => {
    return createLine([from, distance, to, distance]);
  };

  const createMarker = (text, left, top, extraOptions) => {
    var options = {
      left: left,
      top: top,
      fontFamily: FONT_FAMILY,
      fontSize: FONT_SIZE,
      fill: FONT_COLOR,
      centeredRotation: false,
      selectable: false,
      evented: false
    };

    if (extraOptions) {
      for (var key in extraOptions) {
        options[key] = extraOptions[key];
      }
    }

    return new Fabric.Text(text, options);
  };

  const createHRulerMarker = (markerValue, distance) => {
    return createMarker(markerValue.toString(), distance + 2, 1);
  };

  const createVRulerMarker = (markerValue, distance) => {
    var extraOptions = {
      angle: -90,
      originX: 'right',
      originY: 'top'
    };

    return createMarker(markerValue.toString(), 1, distance + 2, extraOptions);
  };

  const roundUnitsBetweenMarkers = (units) => {
    var result = 1;
    if (units > 1 && units <= 10) {
      result = Math.floor(units);
    } else if (units > 10 && units <= 100) {
      result = Math.floor(units / 10) * 10;
    } else if (units > 100) {
      result = Math.floor(units / 100) * 100;
    }

    return result;
  };

  const calcRulerOptions = (length, thickness, start, zoom) => {
    var unitsBetweenMarkers = roundUnitsBetweenMarkers(utils.canvasToUserUnits(MIN_PIXELS_BETWEEN_MARKERS) / zoom);
    var stepsBetweenMarkers = getStepsBetweenMarkers();
    var step = utils.userToCanvasUnits(unitsBetweenMarkers / stepsBetweenMarkers) * zoom;

    return {
      length,
      thickness,
      start,
      zoom,
      unitsBetweenMarkers,
      stepsBetweenMarkers,
      step
    };
  };

  const getTickLength = (stepIndex, stepsBetweenMarkers) => {
    let tickLength = L16_TICK;

    if (stepIndex % stepsBetweenMarkers === 0) {
      tickLength = L1_TICK;
    } else if (stepsBetweenMarkers === STEPS_BETWEEN_INCH_MARKERS) {
      if (stepIndex % (stepsBetweenMarkers / 2) === 0) {
        tickLength = L2_TICK;
      } else if (stepIndex % (stepsBetweenMarkers / 4) === 0) {
        tickLength = L4_TICK;
      } else if (stepIndex % (stepsBetweenMarkers / 8) === 0) {
        tickLength = L8_TICK;
      }
    } else if (stepIndex % (stepsBetweenMarkers / 2) === 0) {
      tickLength = L2_TICK;
    } else {
      tickLength = L8_TICK;
    }

    return tickLength;
  };

  const drawRuler = (rulerOptions) => {
    const markers = [];
    const ticks = [];
    const o = rulerOptions;

    let stepIndex = 0;
    for (let d = o.start; d < o.length; d += o.step) {
      if (d >= o.thickness) {
        if (stepIndex % o.stepsBetweenMarkers === 0) {
          const markerValue = o.unitsBetweenMarkers * Math.round(stepIndex / o.stepsBetweenMarkers);
          markers.push(o.createMarker(markerValue, d));
        }

        const tickLength = getTickLength(stepIndex, o.stepsBetweenMarkers);
        ticks.push(o.createTick(d, o.thickness - tickLength, o.thickness));
      }
      stepIndex++;
    }

    o.canvas.add.apply(o.canvas, [o.createRulerRect(o.length, o.thickness)].concat(ticks, markers));

    o.canvas.renderAll();
  };

  const updateHRuler = (length, thickness, start, zoom) => {
    hrulerCanvas.clear();

    hrulerCanvas.setDimensions({ width: length, height: thickness });

    var o = calcRulerOptions(length, thickness, start, zoom);
    o.createTick = createHRulerTick;
    o.createMarker = createHRulerMarker;
    o.createRulerRect = createHRulerRect;
    o.canvas = hrulerCanvas;
    drawRuler(o);
  };

  const updateVRuler = (length, thickness, start, zoom) => {
    vrulerCanvas.clear();

    vrulerCanvas.setDimensions({ width: thickness, height: length });

    var o = calcRulerOptions(length, thickness, start, zoom);
    o.createTick = createVRulerTick;
    o.createMarker = createVRulerMarker;
    o.createRulerRect = createVRulerRect;
    o.canvas = vrulerCanvas;
    drawRuler(o);
  };

  return {
    updateHRuler,
    updateVRuler
  };

};
