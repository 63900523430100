const PropTypes = require('prop-types');
const React = require('react');
const { isFunction, isString } = require('./consts');
const defaultRules = require('./ValidatorDefaultRules');
const { Component } = React;

class Validator extends Component {
  static propTypes = {
    register: PropTypes.func,
    rules: PropTypes.array,
    validate: PropTypes.string,
    message: PropTypes.any,
    validatorIcon: PropTypes.string,
    validatorIconStyle: PropTypes.object,
    children: PropTypes.element,
    messageBottomAlignment: PropTypes.bool,
    disabled: PropTypes.bool
  }
  static contextTypes = {
    store: PropTypes.any,
    registerValidator: PropTypes.func
  }
  static defaultProps = {
    rules: [],
    validate: 'value',
    validatorIcon: '*',
    disabled: false
  }

  constructor(props, context) {
    super(props);

    this.state = {
      isValid: true,
      messageToShow: ''
    };

    if (typeof props.register === 'function') {
      this.unregister = props.register(this);
    } else {
      this.unregister = this.registerToParentForm(context);
    }
  }

  hasChildren = () => {
    return typeof this.props.children !== 'undefined';
  };

  registerToParentForm = (context) => {
    return context.registerValidator(this);
  };

  validateRules = (value, rules) => {
    let valid = true;

    for (let index = 0; index < rules.length; index++) {
      valid = this.validateRule(value, rules[index]);
      if (!valid) {
        const { message } = this.props;
        let messageToShow = Array.isArray(message) ? message[index] : message;
        this.setState({ messageToShow });
        break;
      }
    }

    if (valid) {
      this.setState({ messageToShow: '' });
    }

    return valid;
  };

  validateRule = (value, rule) => {
    let valid = true;

    if (isString(rule) && isFunction(defaultRules[rule])) {
      valid = defaultRules[rule](value);
    } else if (isFunction(rule)) {
      valid = rule(value);
    }

    return valid;
  };

  isValid = (value, rules) => {
    const { disabled } = this.props;
    if (disabled) {
      return true;
    }

    return this.validateRules(value, rules);
  };



  validate = () => {
    const value = this.hasChildren() ? this.props.children.props[this.props.validate] : undefined;
    const { rules } = this.props;
    const isValid = this.isValid(value, rules);

    this.setState({ isValid });
    return isValid;
  };

  onSelect = (...args) => {
    const value = args[1];
    const onSelect = this.props.children.props.onSelect;
    const rules = this.props.rules;

    if (isFunction(onSelect)) onSelect(...args);
    this.setState({
      isValid: this.isValid(value, rules)
    });
  };

  onBlur = (event) => {
    const value = this.props.children.props[this.props.validate];
    const onBlur = this.props.children.props.onBlur;
    const rules = this.props.rules;

    if (isFunction(onBlur)) onBlur(event);
    this.setState({
      isValid: this.isValid(value, rules)
    });
  };

  componentWillUnmount() {
    if (isFunction(this.unregister)) {
      this.unregister();
    }
  }

  renderValidatorWithChildren = () => {
    const isValid = this.state.isValid;
    const { rules, disabled, children } = this.props;

    let childrenClassName = this.props.children.props.className;
    let className = this.props.className;
    let message = this.state.messageToShow;
    const messageBottomAlignment = this.props.messageBottomAlignment;
    const validatorIcon = this.props.validatorIcon;
    const validatorIconStyle = this.props.validatorIconStyle;
    const invalidMessageClassName = isValid && typeof message === 'string' ? 'crtx-validator-invalid-message' : 'crtx-validator-invalid-message invalid';
    const validatorClassName = messageBottomAlignment ? `crtx-validator crtx-validator-bottom-message-alignment ${className || ''} ` : `crtx-validator ${className || ''}`;
    const showValidatorIcon = rules.includes('required');

    childrenClassName = `${childrenClassName || ''} validating ${!isValid && 'invalid' || ''}`;

    return disabled ?
      children :
      <div className={validatorClassName}>
        <div className='crtx-validator-row'>
          {React.cloneElement(this.props.children, { onBlur: this.onBlur, onSelect: this.onSelect, className: childrenClassName })}
          {showValidatorIcon && <div className="crtx-validator-icon" style={validatorIconStyle}>{validatorIcon}</div>}
        </div>
        {message && <div className={invalidMessageClassName}>{message}</div>}
      </div>
  };

  renderEmptyValidator = () => {
    const { isValid } = this.state;
    let className = this.props.className;
    let message = this.state.messageToShow;
    const invalidMessageClassName = isValid && typeof message === 'string' ? 'crtx-empty-validator-invalid-message' : 'crtx-empty-validator-invalid-message invalid';

    return <div className={`crtx-validator ${className || ''}`}>
      <div className={invalidMessageClassName}>{message}</div>
    </div>;
  };

  render() {
    return this.hasChildren() ? this.renderValidatorWithChildren() : this.renderEmptyValidator();
  }
}

module.exports = Validator;

