/**
 * @name TreeDataAdapter
 * @file Transforms data received from the Tick to the presentation form required by the Tree, i.e.
 *       Tick Model => View Model
 *       Note: A tree node in the View Model is referenced as 'node' and a data node in the Tick Model - as 'item'.
 *
 * @author Boris
 * @since: 2019-04-23
 */

import jsUtils from 'base/jsUtils';
import { translate } from 'core/services/localization';
import {
  getModuleIcon,
  getGeneralIcon,
  getTemplateIcon,
  getTemplateSvgIconHref,
  getCustomIconUrl
} from 'core/services/iconService';
import requestManager from 'core/managers/request';
import { sorting } from 'sandbox';
import TickableModel from '../TickableModel';
import settingsManager from 'core/managers/settings';
import { getHoldIconName, getHoldIconTooltip, getHoldType } from 'utilities/hold';
import {
  OVERRIDE_AGGREGATION_STATE,
  COLORS_BY_STATE,
  COLORS_BY_TYPE,
  DEFAULT_TYPE_COLOR,
  SVG_ICONS_BY_TYPE,
  WAITING_FOR_APPROVAL_COLOR
} from './constants';


const SELECTION_TIMEOUT = 300;
const THROTTLE_WAIT = 1000;

const defaultComparator = sorting.getComparator('caseInsensitive', 'label');

export default class TreeDataAdapter {
  constructor(params) {
    this.module = params.module;

    this.viewLinksRequestQueue = [];
    this.viewLinksMap = {};
    this.filterOptions = {};
    this.expandedNodeIds = {};
    this.selectedNodeId = '';
    this.selectedNode = null;
    this.updates = [];
  }

  firstTickReceived(data) {
    this.tickableModel = new TickableModel({});

    this.tickableModel.firstTickHandler(data.model);

    this.filterOptions = this.getInitialFilterOptions(data);

    this.buildViewModel();
  }

  tickUpdate(data) {
    this.updates = this.updates.concat(data.model);
    this.tickUpdateHandlerThrottled();
  }

  tickUpdateHandler() {
    this.tickableModel.tickUpdateHandler(this.updates);
    this.updates = [];
    this.buildViewModel();
  }

  tickUpdateHandlerThrottled = jsUtils.throttle(this.tickUpdateHandler, THROTTLE_WAIT, {
    leading: false,
    trailing: true
  });

  composeVirtualId(item, index) {
    return item.id + '_' + index;
  }

  requestViewLinks(nwid, type) {
    this.viewLinksRequestQueue.push({ nwid, type });

    clearTimeout(this.requestTimeoutId);
    this.requestTimeoutId = setTimeout(() => {
      this.doRequestViewLinks();
    }, 0);
  }

  doRequestViewLinks() {
    if (this.viewLinksRequestQueue.length > 0) {
      const items = [...this.viewLinksRequestQueue];
      this.viewLinksRequestQueue = [];
      requestManager.retrieveViewLinksBatch(items, this.module.nwid)
        .then(res => {
          if (res.errorMessage) {
            console.error('Cannot retrieve View Links: ', res.errorMessage);
          } else {
            res.data.forEach(item => {
              this.viewLinksMap[item.type] = this.viewLinksMap[item.type] || {};
              this.viewLinksMap[item.type][item.nwid] = item.viewLinks;
            });

            this.updateViewLinks(this.transformedModel);
          }
        });
    }
  }

  getViewLinks(nwid, type) {
    if (this.viewLinksMap[type] && this.viewLinksMap[type][nwid]) {
      return this.viewLinksMap[type][nwid];
    }

    this.requestViewLinks(nwid, type);
  }

  updateViewLinks(item) {
    if (this.viewLinksMap[item.type] && this.viewLinksMap[item.type][item.nwid]) {
      item.viewLinks = this.viewLinksMap[item.type][item.nwid];
    }

    if (item.isLeaf === 'true' || !item.childrenNames || item.childrenNames.length <= 0) {
      return;
    }

    item.childrenNames.forEach(childrenKey => {
      if (Array.isArray(item[childrenKey])) {
        item[childrenKey].map(child => this.updateViewLinks(child));
      }
    });
  }

  findNode(nodeId, parent = this.rootNode) {
    if (!nodeId || !parent) {
      return null;
    }

    if (parent.id === nodeId) {
      return parent;
    }

    let result = null;
    const children = parent.children || [];
    for (let child of children) {
      result = this.findNode(nodeId, child);
      if (result) {
        break;
      }
    }

    return result;
  }

  expandNode(nodeId) {
    const node = this.findNode(nodeId);
    if (node) {
      node.expanded = true;
      this.expandedNodeIds[nodeId] = true;
      if (this.areChildNodesLoaded(node)) {
        this.filterViewModel();
      } else {
        this.loadChildNodes(node);
      }
    }
  }

  expandAllNodes(nodeId) {
    const node = this.findNode(nodeId);
    if (node) {
      node.expanded = true;
      this.expandedNodeIds[nodeId] = true;
      if (this.areDescendantNodesLoaded(node)) {
        this.expandAllLoadedNodes(node);
      } else {
        this.loadDescendantNodes(node);
      }
    }
  }

  expandAllLoadedNodes(parentNode) {
    const expandAllLoadedNodesInner = node => {
      node = node || this.rootNode;
      if (!this.areChildNodesLoaded(node)) {
        return;
      }

      node.expanded = true;
      this.expandedNodeIds[node.id] = true;
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => expandAllLoadedNodesInner(child));
      }
    };

    expandAllLoadedNodesInner(parentNode);
    this.filterViewModel();
  }

  collapseNode(nodeId) {
    const node = this.findNode(nodeId);
    if (node) {
      node.expanded = false;
      delete this.expandedNodeIds[nodeId];
      this.filterViewModel();
    }
  }

  collapseAllNodes(nodeId) {
    const collapseAllNodesInner = node => {
      node.expanded = false;
      delete this.expandedNodeIds[node.id];

      if (node.children && node.children.length > 0) {
        node.children.forEach(child => {
          collapseAllNodesInner(child);
        });
      }
    };

    const node = this.findNode(nodeId);
    if (node) {
      collapseAllNodesInner(node);
      this.filterViewModel();
    }
  }

  loadChildNodes(node) {
    if (node.status === 'loading') {
      return;
    }

    node.status = 'loading';
    return requestManager.loadChildNodes(this.module.projectorId, node.id)
      .then(model => {
        this.tickableModel.tickUpdateHandler(model);
        this.buildViewModel();
      })
      .fail(result => {
        console.error('Cannot load child nodes: ', `${result.statusText} (${result.status})`);
        node.status = 'failed';
        this.module.render();
      });
  }

  loadDescendantNodes(node) {
    if (node.status === 'loading') {
      return;
    }

    //console.log('### loadDescendantNodes()');
    node.status = 'loading';
    const nodeIds = node.modelItem.virtual ? (node.children || []).map(child => child.id) : [node.id];
    return requestManager.loadDescendantNodes(this.module.projectorId, nodeIds, true)
      .then(model => {
        this.tickableModel.tickUpdateHandler(model);
        this.buildViewModel();
        const parentNode = this.findNode(node.id);
        if (parentNode) {
          this.expandAllLoadedNodes(parentNode);
        }
      })
      .fail(result => {
        console.error('Cannot load descendant nodes: ', `${result.statusText} (${result.status})`);
        node.status = 'failed';
        this.module.render();
      });
  }

  computeAggregateState(node) {
    let aggregateState;

    for (let child of node.children) {
      let state;
      const item = child.modelItem;
      if (typeof item.overrideAggregationColor === 'string') {
        state = item.overrideAggregationColor;
        if (item.forceParentColor === 'true') {
          aggregateState = state;
          break;
        }
      } else {
        state = item.state;
      }

      if (state === 'error') {
        aggregateState = 'error';
        break;
      }

      if (aggregateState && state && aggregateState !== state) {
        aggregateState = 'inProgress';
      } else {
        aggregateState = state;
      }
    }

    return aggregateState;
  }

  computeVirtualHoldType(node) {
    if (node.modelItem.children.length === 0) {
      return 'none';
    }

    const childrenHoldTypes = node.modelItem.children.map(child => getHoldType(child));
    const holdType = childrenHoldTypes[0];
    if (childrenHoldTypes.every(childHoldType => holdType === childHoldType)) {
      if (holdType.includes('_aggregated')) {
        node.modelItem.aggregatedHoldType = holdType.replace('_aggregated', '');
      } else {
        node.modelItem.holdType = holdType;
      }
    } else {
      // This object is for checking quickly if there is any kind of partial scheduled hold
      const partialScheduledHoldTypes = {
        scheduled_partial: true,
        scheduled_partial_aggregated: true,
      };
      // This logic is because structure and scheduled holds are practically the same,
      // except that the scheduled hold will be released automatically and showing partial hold in this case will be incorrect.
      const structureHoldTypes = {
        scheduled: true,
        scheduled_aggregated: true,
        structure: true,
        structure_aggregated: true,
      };
      let aggregatedHoldType = 'structure';
      for (const childHoldType of childrenHoldTypes) {
        // If there is any kind of partial scheduled hold, we assign it to the variable, and stop the iteration since if it exists in one
        // of the children that should be the parent's hold type.
        if (partialScheduledHoldTypes[childHoldType]) {
          aggregatedHoldType = childHoldType.replace('_aggregated', '');
          break;
        } else if (!structureHoldTypes[childHoldType]) {
          if (aggregatedHoldType === 'scheduled') { // same logic as comment above
            aggregatedHoldType = 'scheduled_partial';
            break;
          } else {
            aggregatedHoldType = 'partial';
          }
        } else if (aggregatedHoldType !== 'scheduled' && aggregatedHoldType !== 'partial' && childHoldType.includes('scheduled')) {
          aggregatedHoldType = 'scheduled';
        }
      }
      node.modelItem.aggregatedHoldType = aggregatedHoldType;
    }
  }

  updateAggregateProperties(node) {
    if (!Array.isArray(node.children)) {
      return;
    }

    node.modelItem.waitingForApproval = node.children.some(child => child.modelItem.waitingForApproval);

    node.modelItem.deadlinePassed = node.children.some(child => child.modelItem.deadlinePassed);

    const userNames = node.children.reduce((acc, child) => {
      if (child.modelItem.descendantUserLocked) {
        node.modelItem.descendantUserLocked = true;
        acc = (child.modelItem.descendantLockUserNames || []).reduce((acc2, name) => {
          acc2[name] = true;
          return acc2;
        }, acc);
      }

      return acc;
    }, {});

    if (node.modelItem.descendantUserLocked) {
      node.modelItem.descendantLockUserNames = Object.keys(userNames);
    }

    node.modelItem.state = this.computeAggregateState(node);

    this.computeVirtualHoldType(node);
  }

  getTickableModel() {
    return this.tickableModel.model();
  }

  getInitialFilterOptions(data) {
    // implement in the derived class if needed
  }

  getFilterOptions() {
    return this.filterOptions;
  }

  setFilterOptions(filterOptions) {
    this.filterOptions = { ...this.filterOptions, ...filterOptions };

    this.filterViewModel();
  }

  getViewModel() {
    return this.viewModel;
  }

  getItemById(id) {
    return this.tickableModel.get(id);
  }

  buildViewModel() {
    //***TEST BEGIN
    // this.t1 = performance.now();
    // this.nodeCount = 0;
    //***TEST END

    this.selectedNode = null;
    const modelRoot = this.getTickableModel();
    this.foldersInfo = this.buildFoldersInfo(modelRoot.children);
    this.transformedModel = this.transformModelItem(modelRoot);
    this.rootNode = this.buildNode(this.transformedModel);

    this.filterViewModel();

    //***TEST BEGIN
    // this.t2 = performance.now();
    // console.log('### TreeDataAdapter.buildViewModel() ===', this.nodeCount, '=== nodes');
    // console.log('### TreeDataAdapter.buildViewModel() time = ', this.t2 - this.t1, 'ms');
    //***TEST END

    // render only after executing all functions (called after the following method)
    this.module.render();
  }

  getChildrenTransforms() {
    return this.childrenTransforms;
  }

  setChildrenTransforms(childrenTransforms) {
    this.childrenTransforms = childrenTransforms;
  }

  childrenTransforms = {};

  buildFoldersInfo(folders) {
    if (!Array.isArray(folders)) {
      return [];
    }

    return folders.map(folder => ({
      nwid: folder.nwid,
      label: folder.label,
    }));
  }

  transformModelItem(item) {
    if (item.isLeaf === 'true' || !item.childrenNames || item.childrenNames.length <= 0) {
      return item;
    }

    // do not change the oriiginal model
    item = { ...item };

    if (item.type === 'root' && Array.isArray(item.children) && item.children.length > 1) {
      // Use only the current folder and ignore others
      const currentFolderNwid = settingsManager.get('currentFolderNwid');
      for (let folder of item.children) {
        if (folder.nwid === currentFolderNwid) {
          item.children = [folder];
          break;
        }
      }
    }

    item.childrenNames.forEach(childrenKey => {
      const transform = this.childrenTransforms[childrenKey];
      if (transform) {
        item = transform(item, this);
      }

      if (Array.isArray(item[childrenKey])) {
        item[childrenKey] = item[childrenKey].map(child => this.transformModelItem(child));
      }
    });

    return item;
  }

  buildNode(item) {
    //***TEST BEGIN
    // this.nodeCount++;
    //***TEST END

    const node = {
      modelItem: item,
      id: item.id,
      type: item.type,
      text: item.label,
      children: this.buildChildNodes(item),
      expanded: this.expandedNodeIds[item.id] || false,
      selected: item.id === this.selectedNodeId,
    };

    if (node.modelItem.virtual) {
      this.updateAggregateProperties(node);
    }

    node.icon = this.getIcon(node);
    node.trailingIcons = this.getTrailingIcons(node);

    if (node.type === 'folder' && this.module.folderMenu) {
      node.menuItems = this.module.folderMenu.buildMenuItems(this.foldersInfo);
    }

    return node;
  }

  /**
   * Build child nodes of the given parent node.
   * Returns null if the given node is leaf (defined as leaf on the server or children are loaded
   * but the number of children is zero) or array of child nodes otherwise.
   *
   * @param item - parent node of tickable model
   * @returns {*} - null or array of child nodes
   */
  buildChildNodes(item) {
    if (item.isLeaf === 'true') {
      // the given node is a leaf node and therefore cannot have children
      return null;
    }

    if (!item.childrenNames || item.childrenNames.length <= 0) {
      // the given node children is not yet loaded from the server and therefore can have children
      return [];
    }

    let sorted = false;
    const children = item.childrenNames.reduce((acc, childrenKey) => {
      let result = acc;

      if (Array.isArray(item[childrenKey])) {
        result = acc.concat(item[childrenKey].sort(this.getChildrenComparator(item, childrenKey)));
        sorted = true;
      } else if (jsUtils.isObject(item[childrenKey])) {
        result = acc.concat(item[childrenKey]);
      }

      return result;
    }, []);

    if (!sorted) {
      children.sort(defaultComparator);
    }

    return children.length <= 0 ? null : children.map(child => this.buildNode(child));
  }

  filterViewModel() {
    this.viewModel = this.rootNode;
    if (this.isFilterActive()) {
      this.viewModel = this.filterChildNodes(this.rootNode);
      this.module.render();
    }
  }

  isFilterActive() {
    return false;
  }

  getChildrenComparator(item, childrenKey) {
    const children = item[childrenKey];
    if (!children || children.length <= 0) {
      return defaultComparator;
    }

    let comparator;
    const sortingValue = item[childrenKey + ':sorting'];
    if (sortingValue) {
      const sortInfo = sortingValue.split(':');
      const sortKey = sortInfo[0];
      let sortType = sortInfo[1];
      if (sortType) {
        if (sortKey !== 'label') {
          comparator = sorting.getComparator(sortType, sortKey);
        }
      } else {
        const childType = children[0].type;
        let sortBy = settingsManager.get('productSorting')[childType];
        if (childType === 'edition' && !item.standAloneEditions) {
          sortBy = 'byPlan';
        }

        if (sortBy === 'byPlan') {
          comparator = sorting.getComparator('numeric', 'index');
        }
      }
    }

    return comparator ? sorting.getComparator([comparator, defaultComparator]) : defaultComparator;
  }

  getIcon(node) {
    const item = node.modelItem || node;

    let icon;
    const type = item.role || item.type;
    if (this.getSvgIconId(type)) {
      icon = this.getSvgIcon(item.state, type, item.overrideAggregationColor, this.isWaitingForApproval(item));
    } else {
      icon = this.getImageIcon(item.state, type, item.overrideAggregationColor);
    }

    return icon;
  }

  getCustomIcons(item) {
    if (!item.customIcons) {
      return [];
    }

    const keys = Object.keys(item.customIcons).filter(key => !!item.customIcons[key]).sort();
    const icons = keys.map(key => ({
      url: getCustomIconUrl(this.module.nwid, item.customIcons[key]),
      tooltip: key
    }));

    return icons;
  }

  getTrailingIcons(node) {
    const item = node.modelItem || node;
    const showDeadlineBellInTree = settingsManager.get('showDeadlineBellInTree');
    const complete = item.state ? item.state === 'success' : false;
    const holdType = getHoldType(item);
    let result = [];

    if (this.isWaitingForApproval(item)) {
      result.push({
        url: getModuleIcon('BaseTreeView', 'waiting'),
        tooltip: translate('Waiting')
      });
    }

    if (holdType && holdType !== 'none') {
      result.push({
        url: getGeneralIcon('', getHoldIconName(holdType), '.svg'),
        tooltip: getHoldIconTooltip(holdType)
      });
    }

    if (item.locked) {
      result.push({
        url: getModuleIcon('BaseTreeView', 'lock'),
        tooltip: item.allowedResources?.length > 0 ? item.allowedResources.join(', ') : translate('Locked')
      });
    }

    if (item.userLocked) {
      result.push({
        url: getModuleIcon('BaseTreeView', 'user_locked'),
        tooltip: translate('Locked by user')
      });
    }

    if (item.descendantUserLocked) {
      result.push({
        url: getModuleIcon('BaseTreeView', 'descendant_user_locked'),
        tooltip: translate('Locked by:') + ' ' + (item.descendantLockUserNames || []).join(', ')
      });
    }

    if (item.skipSteps === true || item.skipSteps === 'true') {
      result.push({
        url: getModuleIcon('BaseTreeView', 'skip_flow_step'),
        tooltip: translate('Skip flow step')
      });
    }

    if (item.deadlinePassed && showDeadlineBellInTree && !complete) {
      result.push({
        url: getModuleIcon('BaseTreeView', 'bell'),
        tooltip: translate('Deadline Passed')
      });
    }

    const hasFanout = item.hasFanout === 'true';
    const hasDirect = item.hasDirect === 'true';
    if (hasFanout || hasDirect) {
      let iconName = 'fanout';
      let iconTooltip = translate('Fanout');
      if (hasFanout && hasDirect) {
        iconName = 'direct_print_and_fanout';
        iconTooltip = translate('Direct print and Fanout');
      } else if (hasDirect) {
        iconName = 'direct_print';
        iconTooltip = translate('Direct print');
      }

      result.push({
        url: getGeneralIcon('', iconName, '.svg'),
        tooltip: iconTooltip
      });
    }

    if (item.customIcons) {
      result = result.concat(this.getCustomIcons(item));
    }

    return result;
  }

  isWaitingForApproval(item) {
    return item.waitingForApproval || false;
  }

  areChildNodesLoaded(node) {
    return !node.children || node.children.length > 0;
  }

  areDescendantNodesLoaded(node) {
    if (!this.areChildNodesLoaded(node)) {
      return false;
    }

    let result = true;
    const children = node.children || [];
    for (let child of children) {
      if (!this.areDescendantNodesLoaded(child)) {
        result = false;
        break;
      }
    }

    return result;
  }

  selectNode(node, temporarily) {
    if (this.selectedNode) {
      this.selectedNode.selected = false;
      this.selectedNode = null;
    }

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = 0;
    }

    this.selectedNodeId = node.id;
    node.selected = true;
    this.selectedNode = node;

    if (temporarily) {
      this.discardSelectedNode();
    }
  }

  discardSelectedNode() {
    this.timeoutId = window.setTimeout(() => {
      this.selectedNodeId = '';
      if (this.selectedNode) {
        this.selectedNode.selected = false;
        this.selectedNode = null;
        this.module.render();
      }
    }, SELECTION_TIMEOUT);
  }

  getNodeName(node) {
    if (!node || !node.modelItem) {
      return '';
    }

    return node.modelItem.name || node.modelItem.label;
  }

  getImageIcon(state, type, customColor) {
    let iconName;
    if (customColor) {
      iconName = OVERRIDE_AGGREGATION_STATE[customColor];
    } else if (typeof state === 'string') {
      iconName = state.toLowerCase();
    }

    return {
      type: 'image',
      url: getTemplateIcon(type, 'small', iconName),
    };
  }

  getSvgIconId(type) {
    const icon = SVG_ICONS_BY_TYPE[type];

    return icon ? icon.id : '';
  }

  getSvgIconColor(state, type, customColor, waitingForApproval) {
    let color;
    if (customColor) {
      color = customColor;
    } else if (state) {
      const stateString = (typeof state === 'object' ? state.state || '' : state).toLowerCase();
      if (waitingForApproval && stateString !== 'error') {
        color = WAITING_FOR_APPROVAL_COLOR;
      } else {
        color = COLORS_BY_STATE[stateString] || stateString;
      }
    } else {
      color = COLORS_BY_TYPE[type] || DEFAULT_TYPE_COLOR;
    }

    return color;
  }

  getSvgIconTitle(state, type) {
    let title = '';

    const icon = SVG_ICONS_BY_TYPE[type];
    if (icon) {
      title = icon.title;
      if (state) {
        const total = Number(state.total);
        const finished = Number(state.finished) || 0;
        if (total > 0) {
          title += ` ${finished} / ${total}`;
        }
      }
    }

    return title;
  }

  getSvgIcon(state, type, customColor, waitingForApproval) {
    const color = this.getSvgIconColor(state, type, customColor, waitingForApproval);

    return {
      type: 'svg',
      href: getTemplateSvgIconHref(this.getSvgIconId(type)),
      style: { color },
      title: this.getSvgIconTitle(state, type)
    };
  }
}
