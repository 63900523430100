/**
 * @name ResourceLed Widget
 * @description a led displaying a resource's state as an image.
 */
define(['ember', 'text!./template.hbs'],

  function (Ember, Template) {

    'use strict';

    var ResourceLedComponent = Ember.Component.extend({
      layout: Ember.Handlebars.compile(Template),
      resource: {},
      isUp: function() {
        return this.resource.humanStateDescription === "Up";
      }.property('resource.humanStateDescription'),
      isDown: function() {
        return this.resource.humanStateDescription === "Down";
      }.property('resource.humanStateDescription')
    });

    Ember.Handlebars.helper('resource-led', ResourceLedComponent);
    return ResourceLedComponent;
  });