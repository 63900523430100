import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import { getGeneralSvgIconHref } from 'core/services/iconService';
import { classNames } from 'utilities/classNames';
import Header from 'components/common/headers/Header';
import DatePicker from 'components/common/dropdown/DatePicker';
import { DEADLINE_MODE } from './../model/constants';

const labels = {
  chooseZones: translate('Zones'),
  chooseZonesChanged: translate('Zones (changed from Template)'),
  planned: translate('Planned'),
  removedFromPlan: translate('Removed from Plan'),
};

function ZoneSelector({ visible, changed, zones = [], deadlineMode = DEADLINE_MODE.DISABLED, onSelect, onChangeDeadline }) {

  const getStyle = () => {
    if (!visible || zones.length <= 0) {
      return { visibility: 'hidden' };
    }
  };

  const handleSelectZone = zone => {
    onSelect(zone);
  };

  const handleChangeDeadline = (zone, deadline) => {
    onChangeDeadline(zone, deadline);
  };

  return (
    <div className='zone-selector' style={getStyle()}>
      <Header className={classNames({ changed })}>
        {changed ? labels.chooseZonesChanged : labels.chooseZones}
      </Header>
      <ul className='structure-list zone-list'>
        {zones.map(zone => {
            const { selected } = zone;
            let icon;
            if (zone.planned) {
              icon = {
                selected,
                name: selected ? 'check_circle' : 'unpublished',
                tooltip: selected ? labels.planned : labels.removedFromPlan,
              };
            }

            return (
              <li
                key={zone.displayName}
                className={classNames('structure-list-item', { selected })}
              >
                <div className='item-caption' onClick={() => handleSelectZone(zone)}>
                  <div className='caption-text' title={zone.displayName}>
                    {zone.displayName}
                  </div>

                  {icon ?
                    <svg className='caption-icon'>
                      <title>{icon.tooltip}</title>
                      <use xlinkHref={getGeneralSvgIconHref(icon.name)} />
                    </svg>
                    : null}
                </div>

                {deadlineMode !== DEADLINE_MODE.DISABLED ? <DatePicker
                  className='zone-deadline'
                  value={zone.deadline}
                  showTimeSelect={true}
                  onChange={(e, deadline) => handleChangeDeadline(zone, deadline)}
                /> : null}
              </li>
            );
          }
        )}
      </ul>
    </div>
  );
}

ZoneSelector.propTypes = {
  visible: PropTypes.bool,
  changed: PropTypes.bool,
  zones: PropTypes.array,
  deadlineMode: PropTypes.string,
  onSelect: PropTypes.func,
  onChangeDeadline: PropTypes.func,
};

export default ZoneSelector;
