/**
 * @name canvasUtils
 * @file Helper functions that extend Fabric canvas functionality
 *
 * @author Boris
 * @since: 2016-07-03
 */

import sandbox from 'sandbox';
import utils from './utils';

const jsUtils = sandbox.jsUtils;

const OFFSET = 20;

export default(editor) => {

  const canvas = editor.getMainCanvas();

  const removeObject = (object) => {
    var objects = canvas.getObjects();
    var idx = objects.indexOf(object);
    if (idx >= 0) {
      objects.splice(idx, 1);
    }
  };

  const removeAllObjects = (state) => {
    var objects = canvas.getObjects();
    var length = objects.length;
    for (var i = length - 1; i >= 0; i--) {
      var obj = objects[i];
      objects.splice(i, 1);
    }
  };

  const insertAfter = (object, refObject) => {
    var objects = canvas.getObjects();
    var idx = objects.indexOf(refObject);
    if (idx >= 0) {
      objects.splice(idx + 1, 0, object);
    }
  };

  const setActiveObject = (object) => {
    if (object) {
      canvas._setActiveObject(object);
    } else {
      canvas._discardActiveObject();
    }
  };

  const renderAllDebounced = jsUtils.debounce(() => {
    //console.log("renderAllDebounced()");
    canvas.renderAll();
  }, 50);

  const shiftElementCoords = (plateRectangle, element) => {
    var found;
    var delta = OFFSET / 2;
    var o = utils.toCanvasLeftTop(plateRectangle, element.x, element.y);
    var shapes = canvas.getObjects();
    do {
      o.left += OFFSET;
      o.top += OFFSET;
      found = shapes.some(shape => Math.abs(o.left - shape.left) < delta || Math.abs(o.top - shape.top) < delta);
    } while (found);

    var p = utils.toSystemXY(plateRectangle, o.left, o.top);
    element.x = p.x;
    element.y = p.y;
  };

  return {
    removeObject,
    removeAllObjects,
    insertAfter,
    setActiveObject,
    renderAllDebounced,
    shiftElementCoords
  }

};
