const COLORS = {
  COMPLETED: '#85ec85',
  WAITING_FOR_APPROVAL: '#5eced5',
  ERROR: '#ff4437',
};

export default {
  'background-progress': function (value, model) {

    if (this.isCompleted(model)) {
      return COLORS.COMPLETED;
    }

    if (this.isError(model) || this.isRejectedItem(model)) {
      return COLORS.ERROR;
    }

    if (this.isWaitingForApproval(model)) {
      return COLORS.WAITING_FOR_APPROVAL;
    }
    return undefined;
  }
};