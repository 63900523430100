const getScrollbarSize = () => {
  let scrollDiv = document.createElement('div');
  let scrollbarWidth = 0;
  let scrollbarHeight = 0;

  scrollDiv.style.visibility = 'hidden';
  scrollDiv.style.width = '100px';
  scrollDiv.style.height = '100px';
  scrollDiv.style.overflow = 'scroll';
  scrollDiv.style.position = 'absolute';
  scrollDiv.style.top = '-9999px';
  document.body.appendChild(scrollDiv);
  scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
  scrollbarHeight = scrollDiv.offsetHeight - scrollDiv.clientHeight;
  document.body.removeChild(scrollDiv);

  return {
    width: scrollbarWidth,
    height: scrollbarHeight
  };
};

let { width, height } = getScrollbarSize();

export default {
  getWidth: () => {
    return width;
  },
  getHeight: () => {
    return height;
  }
};