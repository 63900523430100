'use strict';

import jQuery from 'jquery';

jQuery.migrateMute = true;
require('jquery-migrate');

window.$ = window.jQuery = jQuery;

//TODO: if we would like to add less to webpack uncomment the next 2 lines (don't forget to handle css relative urls)
//require('assets/less/kernel.less');

import { getDefaultLogger } from 'core/services/logger';

var log = getDefaultLogger();
log.info("startup loaded");
log.timeStamp("startup loaded");

require('object-observe');
log.warn('Object.observe is Obsolete and should be avoided, see https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Object/observe');

import { parseUrlParams } from 'utilities/url';
import sys from 'core/sys';
import emberPromise from 'loading-hacks/ember-loader';
import routerService from 'core/services/router';
import requestManager from 'core/managers/request';
import { restPost } from 'core/managers/rest2';
import settingsManager from 'core/managers/settings';
import viewManager from 'core/managers/views';
import {
  initGenericPreferences,
  getOpenedViewsPreferences,
  saveOpenedViewsPreferences
} from 'core/managers/preferences';
import sorting from 'core/sorting';
import { arrayToObject } from "utilities/array";

const urlParams = parseUrlParams(window.location.href);
const preLoginPromise = requestManager._getPreLoginInfo(urlParams);

/**
 * Context menu is used in plain JS w/o jQuery or any DOM library
 * in order to be independent on them.
 */
disableDefaultContextMenu();
disableDefaultDrop();
const runningMode = getRunningMode();
log.info('Running mode: ' + runningMode);

emberPromise.then(Ember => {
  const baseData = require('base/data');
  const dom = require('base/dom');
  const localization = require('core/services/localization');
  const dialogService = require('core/services/dialogService');
  const desktop = require('core/managers/desktop');
  const auth = require('core/managers/authentication');
  const moduleManager = require('core/managers/module');
  const tickFacade = require('core/facades/tick');
  const pubsub = require('core/managers/pubsub');
  const appPromises = require('core/managers/appPromises');
  const loginPromise = appPromises.getPromise(appPromises.states.LOGGED_IN);
  require('widgets/widgetsLoader');
  require('./handlebarsHelpers');

  createApplication(Ember);

  loginPromise.then(loggedinCallBack);

  preLoginPromise.then(res => {
    localization.init(res.languages)
      .then(() => {
        if (res.error) {
          const { message, params } = res.error;
          sys.inform('Error', localization.translate(message, params));
          dom.hide('#splash');
        } else {
          settingsManager.set('siteName', res.siteName);
          settingsManager.set('customerName', res.customerName);
          settingsManager.set('serverName', res.serverName);
          settingsManager.set('version', res.version);
          settingsManager.set('mode', runningMode);
          settingsManager.set('requestVersion', 1);
          settingsManager.set('webAppPath', '/' + window.location.pathname.split('/')[1]);
          settingsManager.set('openIdSettings', res.openIdSettings);
          settingsManager.set('subProductName', res.subProductName);
          settingsManager.set('productSkinName', res.productSkinName);

          if (res.loggedIn) {
            auth.getInitialInformation()
              .then(() => {
                // in case of success the loggedinCallBack() function is called by
                // authentication.responseHandler() that resolves the loginPromise
              }, startLogin);
          } else {
            startLogin();
          }
        }
      });
  });

  function loggedinCallBack(info) {
    setInfo(info);

    switch (runningMode) {
      case 'standard':
        startStandard(info);
        break;
      case 'embedded':
        startEmbedded(info);
        break;
      case 'viewer':
        startViewer(info);
        break;
      case 'test':
        startTest();
        break;
    }
  }

  function initInfoPanel(info) {
    const viewInfo = viewManager.findViewInfoByDefinitionName(info.currentFolderNwid, 'RightPanelInfoViewCR');
    moduleManager.startModule(viewInfo.nwid, null, { ...viewInfo, duringStartup: true });
  }

  function startStandard(info) {

    initGenericPreferences(info.genericPreferences);

    moduleManager.stopInternalModule('Login');

    baseData.when(desktop.createDesktop(), tickFacade.startChannel(info.useWebsocket))
      .then(() => {
        // start initial modules
        const initialViews = sorting.sort(info.initialViews.map(nwid => viewManager.getViewInfo(nwid)), 'alpha', 'order');
        const foundUserInfoView = initialViews.some(view => view.viewDefinitionName === 'UserInfoViewCR');
        if (!foundUserInfoView) {
          dialogService.openAlertDialog(localization.translate('There are no views to show for this user, please contact the administrator')).then(() => auth.logout());
          return;
        } else {
          initialViews.forEach(view => {
            let startParams = { duringStartup: true };

            if (view.viewDefinitionName === 'UserInfoViewCR') {
              startParams = {
                ...startParams,
                rootId: settingsManager.get('user').nwid,
                rootType: 'controlpanel/profiles/users/user'
              };
            }

            moduleManager.startModule(view.nwid, null, startParams);
          });
        }

        const openedViewsPrefs = getOpenedViewsPreferences();
        if (openedViewsPrefs.length > 0) {
          const objectsToCheck = openedViewsPrefs.map(v => ({ type: v.rootType, nwid: v.rootNwid, name: v.rootName }));

          restPost('', 'global/check-objects-exist', { objects: objectsToCheck })
            .then(data => {
              const { objects = [] } = data;
              const objectsByNwid = arrayToObject(objects, 'nwid');

              const openedViews = openedViewsPrefs.filter(v => objectsByNwid[v.rootNwid]?.exists);
              openedViews.forEach(v => {
                const view = viewManager.getViewInfo(v.nwid);
                const startParams = {
                  ...view,
                  target: view.target === 'main' ? 'new' : view.target,
                  rootId: v.rootNwid,
                  rootType: v.rootType,
                  rootName: v.rootName,
                  duringStartup: true,
                };

                moduleManager.startModule(view.nwid, null, startParams);
              });

              desktop.selectMainTab(0);

              if (openedViews.length < openedViewsPrefs.length) {
                // remove stale views from Generic Preferences file
                saveOpenedViewsPreferences(openedViews);
              }
            });
        }

        initInfoPanel(info);

        log.info("logged in");
      });
  }

  function startEmbedded(info) {
    return baseData.when(desktop.createDesktop('1C'), tickFacade.startChannel(info.useWebsocket))
      .then(() => {
        const { main = [] } = routerService.getHashObj();
        const startParams = main[0];
        if (startParams) {
          // if the module is opened in the new window, its parent module is null,
          // even if it's opened from any other module due to the fact that
          // new window leads to new client instance that do not communicate
          // with its parent
          startParams.duringStartup = true;
          return moduleManager.startModule(startParams.nwid, null, startParams);
        }
      });
  }

  function startViewer(info) {
    moduleManager.stopInternalModule('Login');

    return baseData.when(desktop.createDesktop('1C'), tickFacade.startChannel(info.useWebsocket))
      .then(() => {
        sys.inform('InfoView', localization.translate('Waiting for press console page selection...'));
      });
  }

  function startTest() {
    moduleManager.stopInternalModule('Login');

    return baseData.when(desktop.createDesktop('1C'), tickFacade.startChannel(true))
      .then(() => {
        console.log('=== Login finished. Waiting for test to start ===');
        sys.inform('InfoView', localization.translate('Login finished. Waiting for test to start.'));
      });
  }

  function setInfo(info) {
    settingsManager.set('id', info.clientId);
    settingsManager.set('user', getUserInfo(info));
    settingsManager.set('folderInfo', getFolderInfo(info));
    settingsManager.set('currentFolderNwid', info.currentFolderNwid);
    settingsManager.set('currentFolderName', info.currentFolderName);
    settingsManager.set('passwordPolicy', info.passwordPolicy);
    settingsManager.set('uniqueZoneCodeInPublication', info.uniqueZoneCodeInPublication);
    settingsManager.set('uniqueZoneCodeInAllPublications', info.uniqueZoneCodeInAllPublications);
    settingsManager.setTreeDateFormat(info['treeDateFormat']);
    settingsManager.setTabDateFormat(info['tabDateFormat']);
    settingsManager.setProductionRunDateFormat(info.productionRunDateFormat);
    settingsManager.set('showRunsInPlanTree', info.showRunsInPlanTree);
    settingsManager.set('showDeadlineBellInTree', info.showDeadlineBellInTree);
    settingsManager.set('clientConfiguration', info.clientConfiguration || {});
    settingsManager.set('productSorting', info.productSorting);
    settingsManager.set('resourceFeatures', info.resourceFeatures || {});
    settingsManager.set('softproofShowSections', info.softproofShowSections);
    settingsManager.set('chatMsgLengthLimit', info.chatMsgLengthLimit);
    settingsManager.set('afterLogin', info.afterLogin);
    settingsManager.set('serverTimeZone', info.serverTimeZone);
    settingsManager.set('folderTimeZone', info.folderTimeZone);
    settingsManager.set('treeExpandAllLimit', info.treeExpandAllLimit);
    settingsManager.set('skinBackgroundColor', info.skinBackgroundColor);
  }

  function startLogin() {
    moduleManager.startInternalModule('Login');
    dom.hide('#splash');
  }

  function createApplication(Ember) {
    Ember.Application.create({}).Router.reopen({
      location: 'none'
    });
  }
}); // end of emberPromise.then


function disableDefaultContextMenu() {
  var bodyEl = document.getElementsByTagName('body')[0];
  bodyEl.addEventListener('contextmenu', function (ev) {
    ev.preventDefault();
  }, false);
}

function disableDefaultDrop() {
  document.addEventListener('dragover', e => {
    e.dataTransfer.dropEffect = 'none';
  });
}

function getRunningMode() {
  const hashObj = routerService.getHashObj();
  return hashObj.runningMode ? hashObj.runningMode : 'standard';
}

function getUserInfo(info) {
  const userInfo = info.userInfo || {};
  if (!userInfo.measurUnit) {
    userInfo.measurUnit = 'inch';
  }

  return userInfo;
}

function getFolderInfo(info) {
  const { currentFolderInfo: { allowChat, treePubNameFirst, statusLanes }, enhancePreflightIndications } = info;

  return {
    allowChat: typeof allowChat === 'undefined' ? true : allowChat,
    treePubNameFirst: treePubNameFirst || false,
    statusLanes,
    enhancePreflightIndications
  };
}

log.info("startup finished");
log.timeStamp("startup finished");

if (runningMode === 'test') {
  require('./automation');
}
