import sandbox from 'sandbox';
import { arrayToObject } from 'utilities/array';
import { COMPARE_TYPE, createObjectComparator, composeComparators } from 'core/comparators';
import colorService from 'core/services/colorService';
import {
  pixelsToUserUnitsRounded,
} from 'utilities/lengthUnits';
import {
  RULER_THICKNESS,
  transformPoint,
  getPointByRotation,
  getPointRelativeToOrigin,
  getImageTopLeft,
} from 'components/common/canvas/utilities';

const { async } = sandbox;

export const VIEW_TYPE_PAGE_VIEW = 'PageView';
export const VIEW_TYPE_FORM_VIEW = 'FormView';
export const VIEW_TYPE_HIRES_VIEW = 'HiresView';
export const VIEW_TYPE_COMPARE = 'Compare';
export const MISSING_PAGE_URL = '../kernel/assets/img/module/PageView/missing_page.png';
export const DEFAULT_DENSITY_AREA_SIZE = 3;

export const getColorsByNameDict = () => arrayToObject(colorService.getAllColors(), color => color.name.toLowerCase());

const getImagesByKeyDict = images => arrayToObject(images, 'key');

const getOrderByColorType = colorType => {
  const orderByColorType = {
    Cyan: 1,
    Magenta: 2,
    Yellow: 3,
    Black: 4,
  };

  return orderByColorType[colorType] || 5;
};

const getColorCode = color => {
  return (color.code || color.name).toUpperCase();
};

export const sortImagesByColor = (imagesByKey = {}) => {
  const colors = colorService.getAllColors();

  const images = Object.entries(imagesByKey).reduce((acc, [key, image]) => {
    const color = colors.find(c => c.colorType.toLowerCase() === key || c.name.toLowerCase() === key);
    if (color) {
      acc.push({
        ...image,
        colorName: color.name,
        colorCode: getColorCode(color),
        order: getOrderByColorType(color.colorType)
      });
    }

    return acc;
  }, []);

  return images.sort(composeComparators([createObjectComparator(image => image.order, COMPARE_TYPE.NUMBERS),
    createObjectComparator(image => image.colorName)]));
};

export const getContent = (model) => {
  return model.type === 'fragment/content' ? model : model.content;
};

export const getSepContent = (model, separation) => {
  return model.type === 'fragment/content' ? separation : separation.separationContent;
};

export const getSelectedImage = ({ images = {}, selectedImageKey, viewType }) => {
  return viewType === VIEW_TYPE_HIRES_VIEW ? images[selectedImageKey] : images[selectedImageKey]?.image;
};

export const getImageSize = image => {
  if (!image) {
    return;
  }

  const { width, height } = image;

  return { width, height };
};

export const getImageResolution = (image, defaultResolution) => {
  if (!image) {
    return;
  }

  return image.resolution || defaultResolution;
};

export const getImagePrintSize = (image, defaultResolution, measurementUnit) => {
  if (!image) {
    return;
  }

  const { width, height } = image;
  const { xResolution, yResolution } = getImageResolution(image, defaultResolution);

  return {
    width: pixelsToUserUnitsRounded(width, xResolution, measurementUnit),
    height: pixelsToUserUnitsRounded(height, yResolution, measurementUnit),
  };
};

export const getInitialMeasurementData = () => {
  return {
    x1: undefined,
    y1: undefined,
    x2: undefined,
    y2: undefined,
    w: undefined,
    h: undefined,
    d: undefined,
  };
};

export const getInitialDensityData = () => {
  return {
    point: undefined,
    areaSize: DEFAULT_DENSITY_AREA_SIZE,
    colors: [],
    totalDensity: 0,
  };
};

export const getInitialToolsData = () => {

  return {
    densityData: getInitialDensityData(),
    distanceToolPoints: {
      point: undefined,
      pointTo: undefined,
      measurementData: getInitialMeasurementData()
    },
  };
};

const compare = (a, b) => {
  return a < b ? -1 :
    a > b ? 1 :
      0;
};

const getTopLeftPoint = (...points) => {
  return points.sort((a, b) => compare(a.x, b.x) || compare(a.y, b.y))[0];
};

const getBottomRightPoint = (...points) => {
  return points.sort((a, b) => compare(a.x, b.x) || compare(a.y, b.y))[points.length - 1];
};

export const loadFlipbookImage = (url, id, ref, isPano, pageNumber) => {
  const dfd = async.deferred();
  const image = new Image();
  image.id = id;
  image.ref = ref;
  image.isPano = isPano;
  image.pageNumber = pageNumber;
  image.onload = function () {
    dfd.resolve(image);
  };
  image.onerror = dfd.reject;
  image.src = url;
  return dfd.promise();
};

export const computeDensity = (images = [], point, areaSize, windowRef) => {
  let result;

  if (point) {
    const canvas = windowRef.document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const colors = [];
    let totalDensity = 0;
    images.forEach(img => {
      const { image, colorCode, colorName } = img;
      if (image) {
        const offset = Math.trunc((areaSize - 1) / 2);
        let x = Math.trunc(point.x + image.width / 2) - offset;
        let y = Math.trunc(point.y + image.height / 2) - offset;
        x = Math.min(Math.max(0, x), image.width - areaSize);
        y = Math.min(Math.max(0, y), image.height - areaSize);

        ctx.drawImage(image, x, y, areaSize, areaSize, 0, 0, areaSize, areaSize);
        const data = ctx.getImageData(0, 0, areaSize, areaSize).data;

        let d = 0;
        for (let i = 0; i < data.length; i += 4) {
          d += (255 - data[i]) / 255 * 100;
        }

        const density = Math.round(d / (data.length / 4));
        colors.push({
          density,
          name: colorName,
          code: colorCode,
        });
        totalDensity += density;
      }
    });

    result = {
      point,
      areaSize,
      colors,
      totalDensity
    };

  }

  return result || getInitialDensityData();
};

//return the source of the merged image
export const mergeImages = (imagesToMerge, documentRef, grayMerge) => {
  //no images passed get out now
  if (!imagesToMerge || imagesToMerge.length === 0) {
    return null;
  }

  const canvas_t = documentRef.createElement('canvas');
  const ctx_t = canvas_t.getContext('2d');
  canvas_t.width = imagesToMerge[0].image.width;
  canvas_t.height = imagesToMerge[0].image.height;

  imagesToMerge.forEach(img => {
    if (!img.data) {
      ctx_t.drawImage(img.image, 0, 0);
      img.data = ctx_t.getImageData(0, 0, img.image.width, img.image.height).data;
    }
  });

  let mergedImage = ctx_t.getImageData(0, 0, canvas_t.width, canvas_t.height);
  const bytesLength = mergedImage.data.length;

  const mappedImagesToMerge = getImagesByKeyDict(imagesToMerge);

  const cyanSep = mappedImagesToMerge['cyan'];
  const magentaSep = mappedImagesToMerge['magenta'];
  const yellowSep = mappedImagesToMerge['yellow'];
  const blackSep = mappedImagesToMerge['black'];


  for (let i = 0; i < bytesLength; i += 4) {

    let k = blackSep ? 255 - blackSep.data[i] : 0;

    if (grayMerge) {
      k /= 2;
    }

    const factor = 1 - k / 255;

    const r = cyanSep ? cyanSep.data[i] : 255;
    const g = magentaSep ? magentaSep.data[i + 1] : 255;
    const b = yellowSep ? yellowSep.data[i + 2] : 255;

    mergedImage.data[i] = factor * r;
    mergedImage.data[i + 1] = factor * g;
    mergedImage.data[i + 2] = factor * b;
    mergedImage.data[i + 3] = 255;
  }

  ctx_t.putImageData(mergedImage, 0, 0);
  return canvas_t.toDataURL('image/png');
};

export const getViewportPoints = (
  imageSize, flipHorizontal, flipVertical, rotation, zoom,
  offsetPoint, mainCanvasWidth, mainCanvasHeight, mainCanvasInstance) => {

  const { width: imageWidth, height: imageHeight } = imageSize || {};
  const canvasHelper = mainCanvasInstance?.canvasHelper;
  if (!imageSize || isNaN(imageWidth) || isNaN(imageHeight) || !canvasHelper) {
    return;
  }

  const { offsetX, offsetY } = canvasHelper;
  const flipHorizontalValue = flipHorizontal ? -1 : 1;
  const flipVerticalValue = flipVertical ? -1 : 1;
  const topLeftPointOnCanvas = canvasHelper.getCanvasByScreenPoint(
    offsetX - offsetPoint.x + RULER_THICKNESS,
    offsetY - offsetPoint.y + RULER_THICKNESS
  );
  const bottomRightPointOnCanvas = canvasHelper.getCanvasByScreenPoint(
    offsetX + mainCanvasWidth - offsetPoint.x,
    offsetY + mainCanvasHeight - offsetPoint.y
  );

  const mainCanvasTopLeftPoint = getPointByRotation({
    x: topLeftPointOnCanvas.x / zoom * flipHorizontalValue,
    y: topLeftPointOnCanvas.y / zoom * flipVerticalValue
  }, { x: 0, y: 0 }, -rotation);
  const mainCanvasBottomRightPoint = getPointByRotation({
    x: bottomRightPointOnCanvas.x / zoom * flipHorizontalValue,
    y: bottomRightPointOnCanvas.y / zoom * flipVerticalValue
  }, { x: 0, y: 0 }, -rotation);

  const imageHalfWidth = imageWidth / 2;
  const imageHalfHeight = imageHeight / 2;

  const imagePointA = { x: -imageHalfWidth * flipHorizontalValue, y: -imageHalfHeight * flipVerticalValue };
  const imagePointB = { x: imageHalfWidth * flipHorizontalValue, y: -imageHalfHeight * flipVerticalValue };
  const imagePointC = { x: imageHalfWidth * flipHorizontalValue, y: imageHalfHeight * flipVerticalValue };
  const imagePointD = { x: -imageHalfWidth * flipHorizontalValue, y: imageHalfHeight * flipVerticalValue };
  const pointA = getPointByRotation(imagePointA, { x: 0, y: 0 }, rotation);
  const pointB = getPointByRotation(imagePointB, { x: 0, y: 0 }, rotation);
  const pointC = getPointByRotation(imagePointC, { x: 0, y: 0 }, rotation);
  const pointD = getPointByRotation(imagePointD, { x: 0, y: 0 }, rotation);

  const calculatedTopLeftPoint = getTopLeftPoint(pointA, pointB, pointC, pointD);
  const calculatedTopLeftPointOnStage = getPointByRotation(calculatedTopLeftPoint, { x: 0, y: 0 }, -rotation);

  const calculatedBottomRightPoint = getBottomRightPoint(pointA, pointB, pointC, pointD);
  const calculatedBottomRightPointOnStage = getPointByRotation(calculatedBottomRightPoint, {
    x: 0,
    y: 0
  }, -rotation);

  const viewportTopLeft = {
    x: Math.abs(calculatedTopLeftPointOnStage.x) < Math.abs(mainCanvasTopLeftPoint.x) ? calculatedTopLeftPointOnStage.x * flipHorizontalValue : mainCanvasTopLeftPoint.x,
    y: Math.abs(calculatedTopLeftPointOnStage.y) < Math.abs(mainCanvasTopLeftPoint.y) ? calculatedTopLeftPointOnStage.y * flipVerticalValue : mainCanvasTopLeftPoint.y
  };

  const viewportBottomRight = {
    x: Math.abs(calculatedBottomRightPointOnStage.x) < Math.abs(mainCanvasBottomRightPoint.x) ? calculatedBottomRightPointOnStage.x * flipHorizontalValue : mainCanvasBottomRightPoint.x,
    y: Math.abs(calculatedBottomRightPointOnStage.y) < Math.abs(mainCanvasBottomRightPoint.y) ? calculatedBottomRightPointOnStage.y * flipVerticalValue : mainCanvasBottomRightPoint.y
  };

  const width = viewportBottomRight.x - viewportTopLeft.x;
  const height = viewportBottomRight.y - viewportTopLeft.y;

  const viewportTopRight = {
    x: viewportTopLeft.x + width,
    y: viewportTopLeft.y
  };

  const viewportBottomLeft = {
    x: viewportBottomRight.x - width,
    y: viewportBottomRight.y
  };

  return {
    topLeft: viewportTopLeft,
    topRight: viewportTopRight,
    bottomLeft: viewportBottomLeft,
    bottomRight: viewportBottomRight,
    viewportWidth: width,
    viewportHeight: height,
    imageWidth,
    imageHeight,
  };
};

export const centerPoint = (x, y, width, height) => {
  return { x: x + width / 2, y: y + height / 2 };
};

export const mousePointToRuler = (mouseMovePoint, image, rulerOriginPoint, canvasHelper) => {
  let rulerPoint;

  let rulerOrigin;
  if (rulerOriginPoint) {
    rulerOrigin = transformPoint(rulerOriginPoint, canvasHelper.getTransformMatrix());
  } else {
    rulerOrigin = getImageTopLeft(image, canvasHelper.getTransformMatrix());
  }

  const canvasCenter = canvasHelper.getCanvasCenterPoint();
  rulerOrigin = getPointRelativeToOrigin(rulerOrigin, canvasCenter);

  const { x, y } = mouseMovePoint;
  const mousePoint = canvasHelper.getCanvasByScreenPoint(x, y);
  rulerPoint = getPointRelativeToOrigin(mousePoint, rulerOrigin);

  return rulerPoint;
};
