import React from 'react';
import { createRoot } from 'react-dom/client';
import { startInternalModule } from 'core/managers/module';
import { createStore, applyMiddleware } from 'redux';
import AbstractModule from 'AbstractModule';
import TickableModel from '../TickableModel';
import { columnsDefinition, renderColumn } from './columnsCreator';
import { isUndefined, autoBindProps, getModelItemsByType, pageCreator, sorterCreator, SORT_ORDER } from './utils';
import labels from './labels';
import reducer from './redux/reducer';
import initialState from './redux/initialState';
import preferencesMiddleware from './redux/preferencesMiddleware';
import TableLayout from 'components/private/mni/TableLayout';
import Table from 'widgets/ReactDataGrid';
import Dropdown from 'components/common/dropdown/Dropdown';
import Button from 'components/common/buttons/Button';

export default AbstractModule.extend({

  init: function () {
    this._super();
    autoBindProps(this);
  },

  initDone: function () {
    this.tickableModel = new TickableModel();
    this.reactRoot = createRoot(this.domElement);
    this.initToolbar();
  },

  initToolbar: function () {
    this.toolbar = this.createToolbar();
    this.toolbar.addItem({
      label: labels.manageTableColumns,
      name: 'manage_table_columns',
      _isApplicable: true,
      icon: 'mni_table_preferences',
      execute: () => {
        startInternalModule('ManageTableColumnsSetup', {
          rootId: '',
          rootType: '',
          name: labels.manageTableColumns,
          target: 'dialog',
          windowWidth: 400,
          windowHeight: 550,
          columns: this.getColumnsByOrder(),
          onSave: (columns) => {
            this.store.dispatch({ type: 'SET_COLUMNS', columns });
          },
        }, this);
      }
    });
  },

  getColumnsByOrder: function () {
    const { filteredPages, fixToLeftColumnsCount, columnsOrder, hiddenColumns } = this.store.getState();
    const columns = columnsDefinition(filteredPages);

    return columnsOrder.map((columnKey, columnIndex) => {
      const column = columns[columnKey];
      return {
        ...column,
        visible: isUndefined(hiddenColumns[column.columnKey]) ? column.visible : !hiddenColumns[column.columnKey],
        fixed: columnIndex < fixToLeftColumnsCount ? 'left' : column.fixed
      };
    });
  },

  getPageIndexInState: function (page) {
    return this.store.getState().pages.findIndex(statePage => statePage.nwid === page.nwid);
  },

  handlePage: function (action, modelPage) {
    const page = pageCreator(modelPage);

    if (action === 'add') this.store.dispatch({ type: 'ADD_PAGE', page });
    if (action === 'update') this.store.dispatch({ type: 'UPDATE_PAGE', page });
    if (action === 'remove') this.store.dispatch({ type: 'REMOVE_PAGE', page });
  },

  handlePageContent: function (action, modelPageContent) {
    if (action === "remove") return;

    const modelPage = modelPageContent.getParent('page');
    const pageStateIndex = this.getPageIndexInState(modelPage);

    if (pageStateIndex < 0) return;

    this.handlePage('update', modelPage);
  },

  handlePageStatus: function (action, modelPageStatus) {
    if (action === "remove") return;

    const modelPage = modelPageStatus.getParent('page');
    const pageStateIndex = this.getPageIndexInState(modelPage);

    if (pageStateIndex < 0) return;

    this.handlePage('update', modelPage);
  },

  firstTickReceived: function (data) {
    const pages = getModelItemsByType(data.model, 'page').map(page => pageCreator(page));
    const defaultSorter = sorterCreator('mniNumber', { [SORT_ORDER.ASC]: 'caseinsensitive', [SORT_ORDER.DESC]: 'caseinsensitivedecending' }, 'mniNumber');
    const preferences = data.preferences;

    this.store = new createStore(reducer, initialState(preferences), applyMiddleware(preferencesMiddleware(this, preferences)));

    pages.forEach(page => this.store.dispatch({ type: 'ADD_PAGE', page }));

    this.tickableModel.firstTickHandler(data.model);

    this.tickableModel.register(["page"], this.handlePage);
    this.tickableModel.register(["page", "page/content"], this.handlePageContent);
    this.tickableModel.register(["page", "status_queue/value"], this.handlePageStatus);
    this.store.subscribe(this.render);
    this.render();
  },

  tickUpdate: function (data) {
    this.tickableModel.tickUpdateHandler(data.model);
  },

  handleRowSelect: function (selectedPages) {
    this.updateSelected(selectedPages.map(row => this.tickableModel.getByNwid(row.nwid)));
    this.store.dispatch({ type: 'SELECT_PAGES', selectedPages });
  },

  handleRowContextMenu: function (rowIndex, rowContent, selectedRows, ev) {
    this.showContextMenu.apply(this, [
      this.tickableModel.getByNwid(rowContent.nwid),
      selectedRows.map(row => this.tickableModel.getByNwid(row.nwid)),
      ev
    ]);
  },

  handleColumnResize: function (columns, columnKey) {
    this.store.dispatch({ type: 'SET_COLUMNS_WIDTH', columns });
  },

  handleSort: function (columnKey, sortType, sortProp, multiSort) {
    const type = multiSort ? 'SET_MULTIPLE_SORTER' : 'SET_SORT';
    this.store.dispatch({ type, columnKey, sortType, sortProp });
  },

  handleResetFiltersClick: function () {
    this.store.dispatch({ type: 'RESET_FILTERS' });
  },

  handleThumbnailDoubleClick: function (rowIndex) {
    const { filteredPages } = this.store.getState();
    const modelPage = this.tickableModel.getByNwid(filteredPages[rowIndex].nwid);

    this.navigateByViewLink(modelPage);
  },

  renderFilter: function (filter) {
    return <div className="crtx-mni-folder-unplanned-pages-filter">
      <div className="crtx-mni-folder-unplanned-pages-filter-label">{filter.label}:</div>
      <Dropdown
        value={filter.value}
        onSelect={(event, value) => {
          this.store.dispatch({ type: 'SET_FILTER_VALUE', filterName: filter.name, value });
        }}
        options={filter.options}
      />
    </div>;
  },

  renderFilters: function () {
    const { filters } = this.store.getState();

    return <div className="crtx-mni-folder-unplanned-pages-filters">

      {filters.map(this.renderFilter)}

      <Button className="crtx-mni-folder-unplanned-pages-filters-reset-button" onClick={this.handleResetFiltersClick}>
        {labels.reset}
      </Button>

    </div>;
  },

  render: function () {
    const { filteredPages, sorters, tablePreferencesTimeStamp, fixToLeftColumnsCount, columnsOrder, hiddenColumns, columnsWidth, currentRowHeight } = this.store.getState();
    const columnsByKey = columnsDefinition(filteredPages, this.handleThumbnailDoubleClick);

    this.reactRoot.render((
      <TableLayout className="crtx-mni-folder-unplanned-pages" renderFilters={this.renderFilters()}>
        <Table
          key={tablePreferencesTimeStamp}
          columnKey="nwid"
          rows={filteredPages}
          rowHeight={currentRowHeight}
          selectableRows={true}
          virtualScroll={false}
          onSelect={this.handleRowSelect}
          onDoubleClickRow={() => { }}
          onRowContextMenu={this.handleRowContextMenu}
          onColumnResize={this.handleColumnResize}
        >
          {columnsOrder.map((columnKey, columnIndex) => renderColumn(sorters, this.handleSort, fixToLeftColumnsCount, hiddenColumns, columnsWidth)(columnsByKey[columnKey], columnIndex))}

        </Table>
      </TableLayout>));
  }

}, 'MNIFolderUnplanPagesView');