/**
 * @name
 * @fileOverview
 * @author sergey
 */
define(['ember', './MenuView'], function (Ember, MenuView) {
  'use strict';
  return Ember.ContainerView.extend({
    init: function () {
      this._super();
      var position = this.get('controller').calculatePosition(this.get('controller.event'), this.get('controller.content.length'));
      this.pushObject(MenuView.create({
        top: position.y,
        left: position.x,
        level: 0
      }));
    },
    classNames: ['pro-widgets-contextmenu'],
    childViews: ['layerView'],
    layerView: Ember.View.create({
      classNames: ['layer'],
      click: function () {
        this.get('controller').close();
      },
      touchStart: function () {
        this.click();
      },
      contextMenu: function (ev) {
        this.get('controller').close();
      }
    })
  });
});