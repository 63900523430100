/**
 * Created by tzvikas on 4/27/2015.
 */
define(["./actions"],
  function(actionsController){
    'use strict';
    var LOCAL = 0;
    var COMMON = 1;
    var COLOR_SHARE = 2;
    var MONO_SHARE = 3;

    function findVirtual(panorama){
      var map = this.modelHandler.pagesPerRow
      var virtualSrcId = panorama.virtualPage;
      var virtualRowData = map[virtualSrcId];
      var zoneMapKeys = Object.keys(virtualRowData);
      for (var i=0;i<zoneMapKeys.length;i++) {
        if (virtualRowData[zoneMapKeys[i]].sourceId === virtualSrcId) {
          return virtualRowData[zoneMapKeys[i]];
        }
      }
      return null;
    }

    function isInList(list, item){
      for (var i=0;i<list.length;i++){
        if (list[i] === item){
          return i;
        }
      }
      return -1;
    }

    function removeSeparations(page, sepsToRemove){
      var seps = {};
      for (var sepName in page.separations){
        if (sepsToRemove.hasOwnProperty(sepName)) {
          continue;
        }
        seps[sepName] = page.separations[sepName];
      }
      page.separations = seps;
    }

    function pageShareActionControllerInternal (page, shareMode, directMasterSourceId){
      makeLocal.call(this, page);
      var colorObjects = this.modelHandler.model.publication.colorObjects;
      var followers = this.findFollowers (page);
      var sepsToLink = null;
      switch (shareMode) {
        case LOCAL:
          break;
        case MONO_SHARE :
          sepsToLink = [colorObjects["black"]];
        case COLOR_SHARE :
          if (sepsToLink === null) {
            sepsToLink = [colorObjects["cyan"], colorObjects["yellow"], colorObjects["magenta"]];
          }
        case COMMON :
          if (sepsToLink === null) {
            sepsToLink = [colorObjects["cyan"], colorObjects["yellow"], colorObjects["magenta"], colorObjects["black"]];
          }

          // link the page to the master content and direct master
          if (!directMasterSourceId){
            directMasterSourceId = page.directMaster;
          } else {
            page.jumpShare = true;
            page.jumpShareDirectMaster = directMasterSourceId;
          }
          var directMasterPage = this.modelHandler.mappedModel[directMasterSourceId];
          var prevBasePage = page.prevBasePage;// !== null && typeof page.prevBasePage !== 'undefined';
          if (prevBasePage !== null && typeof page.prevBasePage !== 'undefined') {
            page.prevBasePage = null;
            page.basePage = prevBasePage;
            page.isChase = true;
          }

          var masterPageValue = page.masterPage;
          var masterPageZoneValue = page.masterPageZone;

          if ((directMasterPage === null || typeof directMasterPage === 'undefined') &&
            page.basePage !== null && typeof page.basePage !== 'undefined'){
              // this is master page in chase edition
            directMasterPage = page;
            masterPageValue = page.sourceId;
            masterPageZoneValue = page.myZone;
            updateChaseSeparations(page, sepsToLink);
          } else {
            var isLocal = updatePageMasters (directMasterPage, page, sepsToLink);
            if (isLocal){
              masterPageValue = page.sourceId;
              masterPageZoneValue = page.myZone;
            } else {
              masterPageValue = page.masterPage;
              masterPageZoneValue = page.masterPageZone;
            }

            //addFollower(directMasterPage, page);
            //page.masterPageName = directMasterPage.title;
            //page.masterPage = directMasterPage.masterPage ? directMasterPage.masterPage : directMasterPage.sourceId;
            //page.masterZone = directMasterPage.myZone;
            //page.masterPageZone = directMasterPage.masterPage ? directMasterPage.masterPageZone : directMasterPage.myZone;
            //
            //// link the page separations and clear the non-link separations
            //validLinkSeparations(page, directMasterPage, sepsToLink);
            //for (var sepName in page.separations){
            //  if (isInList(sepsToLink, sepName) > -1){
            //    var directMasterSeparation = directMasterPage.separations[sepName];
            //    page.separations[sepName].masterSeparation = directMasterSeparation.masterSeparation ?
            //      directMasterSeparation.masterSeparation : directMasterSeparation.sourceId;
            //    page.separations[sepName].masterZone = page.masterZone;
            //    page.separations[sepName].masterPageZone = page.masterPageZone;
            //  } else {
            //    page.separations[sepName].masterSeparation = null;
            //    page.separations[sepName].masterZone = null;
            //    page.separations[sepName].masterPageZone = null;
            //  }
            //}
          }

          //or (var )


          for (var i=0;i<followers.length;i++){
            var follower = this.modelHandler.mappedModel[followers[i].sourceId];
            // if this follower was assigned in jump share, we need to override the natural direct master
            var followerDirectMaster = follower.jumpShare? this.modelHandler.mappedModel[follower.jumpShareDirectMaster]:
              this.modelHandler.mappedModel[followers[i].directMaster];
            follower.masterPageName = followerDirectMaster.title;
            follower.masterPage = masterPageValue;
            follower.masterZone = followerDirectMaster.myZone;
            follower.masterPageZone = masterPageZoneValue;
            follower.masterSection = followerDirectMaster.mySection;

            prevBasePage = follower.prevBasePage;// !== null && typeof page.prevBasePage !== 'undefined';
            if (prevBasePage !== null && typeof page.prevBasePage !== 'undefined'){
              follower.prevBasePage = null;
              follower.basePage = prevBasePage;
              follower.isChase = true;
            }


            // link the page separations and clear the non-link separations
            validLinkSeparations(follower, followerDirectMaster, sepsToLink);
            for (var sepName in follower.separations){
              if (isInList(sepsToLink, sepName) > -1){
                var directMasterSeparation = followerDirectMaster.separations[sepName];
                follower.separations[sepName].masterSeparation = directMasterSeparation.masterSeparation ?
                  directMasterSeparation.masterSeparation : directMasterSeparation.sourceId;
                follower.separations[sepName].masterZone = follower.masterZone;
                follower.separations[sepName].masterPageZone = follower.masterPageZone;
                follower.separations[sepName].masterSection = follower.mySection;
              } else if (!follower.masterPage) {
                follower.separations[sepName].masterSeparation = null;
                follower.separations[sepName].masterZone = null;
                follower.separations[sepName].masterPageZone = null;
                follower.separations[sepName].masterSection = null;
              }
            }
          }



          var chaseMode =  (page.prevBasePage !== null && typeof page.prevBasePage !== 'undefined' ||
          page.basePage !== null && typeof page.basePage !== 'undefined');

          
      }
      
    }



    function addFollower(directMasterPage, page){
      if (!directMasterPage.followers){
        directMasterPage.followers = [];
      }
      for (var i=0;i<directMasterPage.followers.length;i++){
        if (directMasterPage.followers[i].sourceId === page.sourceId){
          return;
        }
      }
      directMasterPage.followers.push(page.sourceId);
    }

    function removeFollower(directMasterPage, page){
      if (!directMasterPage.followers){
        directMasterPage.followers = [];
      }
      var i = directMasterPage.followers.indexOf(page.sourceId);
      if(i > -1) {
        directMasterPage.followers.splice(i, 1);
      }
    }

    function validLinkSeparations(page, master, sepsToLink){
      for (var sepName in master.separations){
        if (!page.separations[sepName] && isInList(sepsToLink, sepName) > -1){
          //need to create separation
          page.separations[sepName] = {sourceId:page.sourceId + sepName};
        }
      }

      // TODO need some logic to remove redundant seps
    }




    function makeLocal(page){
      var prevMasterPage = page.masterPage;
      var prevBasePage = page.prevBasePage;
      var followers = this.findFollowers(page);
      var isChaseMode = false;
      var baseEditionPage = page.basePage;
      if (baseEditionPage !== null && typeof baseEditionPage !== 'undefined'){
        page.prevBasePage = baseEditionPage;
        page.basePage = null;
        page.isChase = false;
        isChaseMode = true;
      } else if (prevBasePage && (prevMasterPage === null || typeof prevMasterPage === 'undefined')){
        isChaseMode = true;
      }
      if (page.jumpShare){
        page.jumpShare = false;
        var masterPage = this.modelHandler.mappedModel[prevMasterPage];
        if (masterPage){
          this.removeFollower(masterPage, page);
        }
      } else {        
        var directMaster = this.modelHandler.mappedModel[page.directMaster];
        if (directMaster){
          this.removeFollower(directMaster, page);
        }
      }

      page.masterPage = null;
      page.masterZone = null;
      page.masterPageZone = null;
      page.masterSection = null;

      var prevSepsMasters = {};
      for (var sepName in page.separations){
        prevSepsMasters[sepName] = page.separations[sepName].masterSeparation;
        page.separations[sepName].masterSeparation = null;
        page.separations[sepName].masterZone = null;
        page.separations[sepName].masterSection = null;
        page.separations[sepName].masterPageZone = null;

        var baseEditionSep = page.separations[sepName].baseSeparation;
        if (baseEditionSep !== null && typeof baseEditionSep !== 'undefined'){
          page.separations[sepName].prevBaseSeparation = baseEditionSep;
          page.separations[sepName].baseSeparation = null;
          page.separations[sepName].isChase = false;
        }
      }


      for (var i=0;i<followers.length;i++){
        var follower = followers[i];
        if (isChaseMode || (prevMasterPage && follower.masterPage === prevMasterPage)){
          follower.masterPage = page.sourceId;
          follower.masterPageZone = page.myZone;
          follower.masterSection = page.mySection;
          for (var sepName in follower.separations){
            var separation = follower.separations[sepName];
            var masterPagePrevMasterSeparation = prevSepsMasters[sepName];
            var currentMasterSeparation = separation.masterSeparation;
            if (masterPagePrevMasterSeparation === currentMasterSeparation){
              separation.masterSeparation = page.separations[sepName].sourceId
              separation.masterPageZone = page.myZone;
              separation.masterSection = page.mySection;
            }
          }
          baseEditionPage = follower.basePage;
          if (baseEditionPage !== null && typeof baseEditionPage !== 'undefined'){
            follower.prevBasePage = baseEditionPage;
            follower.basePage = null;
            follower.isChase = false;
            isChaseMode = true;
          }
        }
      }
      if (page.virtualPage){
        var virtual = findVirtual.call(this, page);
        if (virtual != null)
          makeLocal.call(this, virtual);
      }
      

    }


    ////(event, "jumpShareAction", [this.state.mode, "zone", this.state.selectedZone, this.state.selectedPage]);
    function pageJumpShareActionController(mode, selectedZone, masterSourceId){
      var pages = this.selectedPages;
      var ret = [];
      for (var i=0;i<pages.length;i++){
        ret.push(pages[i]);
        if (pages[i].sourceId === masterSourceId){
          alert("You can't link a page to itself.")
        } else {
          pageShareActionControllerInternal.call(this, pages[i], parseInt(mode, 10), masterSourceId);
        }
      }
      //this.selectedPages[0].fireSectionSizeChange({});
      return [];
    }

    function pageJumpShareActionControllerNew(mode, selectedZone, selectedSection, selectedPage){
      var masterSourceId = null;
      if (selectedZone && selectedSection && selectedPage){
        var originalStructure = this.modelHandler.originalStructure;
        for (var k=0;k<originalStructure.length;k++){
          if (originalStructure[k].title === selectedZone.title){         
            var sections = originalStructure[k].sections;
            for (var s=0;s<sections.length;s++){
              if (sections[s].title === selectedSection.title){             
                var pages = sections[s].pages;
                for (var p=0;p<pages.length;p++){               
                  if (pages[p].title === selectedPage.title){
                    masterSourceId = pages[p].sourceId;
                    break;        
                  }
                }
                if (masterSourceId != null){
                  break;
                }   
              }            
            }  
            if (masterSourceId != null){
              break;
            }      
          }
          if (masterSourceId != null){
            break;
          }
        }
      }

      // lets find the master sourceId
      
      

     
      var pages = this.selectedPages;
      var ret = [];
      for (var i=0;i<pages.length;i++){
        ret.push(pages[i]);
        if (masterSourceId === null){
          makeLocal.call(this, pages[i]);
        } else if (pages[i].sourceId === masterSourceId){
          alert("You can't link a page to itself.")
        } else {
          pageShareActionControllerInternal.call(this, pages[i], parseInt(mode, 10), masterSourceId);
        }
      }
      //this.selectedPages[0].fireSectionSizeChange({});
      return [];
    }
	
	function chaseColorShareAction (mode){
      var page = this.selectedPages[0];
      return pageShareActionController.call (this, page, mode);
    }
    
    function pageShareActionController (page, mode){
      var ret = [];
      var pages = this.selectedPages;
      if (mode !== null && typeof mode !== 'undefined'){
        for (var i=0;i<pages.length;i++){
          ret.push(pages[i]);
          pageShareActionControllerInternal.call(this, pages[i], parseInt(mode, 10));
        }
        return ret;
      } else if (page.masterPage === null || typeof page.masterPage === "undefined"){
        var m = COMMON;
        if (page.basePage !== null && typeof page.basePage !== "undefined"){
          m = LOCAL;
        }
        pageShareActionControllerInternal.call(this, page, m);
        for (var i=0;i<pages.length;i++){
          var p = pages[i];
          if (p === page){
            continue;
          }
          if (page.masterPage === null || typeof page.masterPage === "undefined") {
            var pm = COMMON;
            if (page.basePage !== null && typeof page.basePage !== "undefined") {
              pm = LOCAL;
            }
          } else {
            pm = LOCAL;
          }

          if (pm !== m){
            pageShareActionControllerInternal.call(this, p, m);
          }
        }


        //return pageShareActionControllerInternal.call(this, page, m);
      } else {
        pageShareActionControllerInternal.call(this, page, LOCAL);
        for (var i=0;i<pages.length;i++){
          var p = pages[i];
          if (p === page){
            continue;
          } else if (p.masterPage !== null && typeof p.masterPage !== "undefined"){
            pageShareActionControllerInternal.call(this, p, LOCAL);
          }
        }
        //return pageShareActionControllerInternal.call(this, page, LOCAL);
      }

    }

    function hasDefaultMaster(){
      var page = this.selectedPages[0];
      return (page.directMaster !== null && typeof page.directMaster !== 'undefined') || page.prevBasePage || page.basePage ;
    }

    function getShareMode(){
      var ret = {};
      var mode = LOCAL;
      var page = this.selectedPages[0];
      var localSeps = [], numSeps = 0;
      for (var sepName in page.separations){
        var masterSeparation = page.separations[sepName].masterSeparation;
        var baseSeparation = page.separations[sepName].baseSeparation;
        if (!masterSeparation && !baseSeparation) {
          localSeps.push(sepName);
        }
        numSeps++;
      }

      if (localSeps.length === 0){
        mode = COMMON;
      } else if (localSeps.length === numSeps){
        mode = LOCAL;
      } else if (localSeps.length === 1){
        mode = COLOR_SHARE;
      } else {
        mode = MONO_SHARE;
      }

      ret.mode = mode;
      return ret;
    }

    function updatePageMasters(directMasterPage, follower, sepsToLink){
      addFollower(directMasterPage, follower);
      follower.masterPageName = directMasterPage.title;
      follower.masterPage = directMasterPage.masterPage ? directMasterPage.masterPage : directMasterPage.sourceId;
      follower.masterZone = directMasterPage.myZone;
      follower.masterSection = directMasterPage.mySection;
      follower.masterPageZone = directMasterPage.masterPage ? directMasterPage.masterPageZone : directMasterPage.myZone;
      validLinkSeparations(follower, directMasterPage, sepsToLink);
      var isLocal = false;
      for (var sepName in follower.separations){
        if (isInList(sepsToLink, sepName) > -1){
          var directMasterSeparation = directMasterPage.separations[sepName];
          follower.separations[sepName].masterSeparation = directMasterSeparation.masterSeparation ?
            directMasterSeparation.masterSeparation : directMasterSeparation.sourceId;
          follower.separations[sepName].masterZone = follower.masterZone;
          follower.separations[sepName].masterSection = follower.mySection;
          follower.separations[sepName].masterPageZone = follower.masterPageZone;
        } else {
          follower.separations[sepName].masterSeparation = null;
          follower.separations[sepName].masterZone = null;
          follower.separations[sepName].masterSection = null;
          follower.separations[sepName].masterPageZone = null;
          isLocal = true;
        }
      }
      if (isLocal){
        follower.masterPageName = null;
        follower.masterPage = null;
        follower.masterZone = null;
        follower.masterSection = null;
        follower.masterPageZone = null;
        if (follower.basePage){
          follower.prevBasePage = follower.basePage;
          follower.basePage = null;        
          follower.isChase = false;
        }
        
      }
      return isLocal;
    }

    function updateChasePageMasters(directMasterPage, follower, sepsToLink){
      addFollower(directMasterPage, follower);

      var isDirectLocal = !directMasterPage.basePage;
      
      if (isDirectLocal){
        // connect page to 
      }

      follower.masterPageName = directMasterPage.title;
      follower.masterPage = directMasterPage.masterPage ? directMasterPage.masterPage : directMasterPage.sourceId;
      follower.masterZone = directMasterPage.myZone;
      follower.masterSection = directMasterPage.mySection;
      follower.masterPageZone = directMasterPage.masterPage ? directMasterPage.masterPageZone : directMasterPage.myZone;
      validLinkSeparations(follower, directMasterPage, sepsToLink);
      var isLocal = false;
      for (var sepName in follower.separations){
        if (isInList(sepsToLink, sepName) > -1){
          var directMasterSeparation = directMasterPage.separations[sepName];
          follower.separations[sepName].masterSeparation = directMasterSeparation.masterSeparation ?
            directMasterSeparation.masterSeparation : directMasterSeparation.sourceId;
          follower.separations[sepName].masterZone = follower.masterZone;
          follower.separations[sepName].masterSection = follower.mySection;
          follower.separations[sepName].masterPageZone = follower.masterPageZone;
        } else {
          follower.separations[sepName].masterSeparation = null;
          follower.separations[sepName].masterZone = null;
          follower.separations[sepName].masterSection = null;
          follower.separations[sepName].masterPageZone = null;
          isLocal = true;
        }
      }
      if (isLocal){
        follower.masterPageName = null;
        follower.masterPage = null;
        follower.masterZone = null;
        follower.masterSection = null;
        follower.masterPageZone = null;
      }
      return isLocal;
    }

    function updateChaseSeparations(page, sepsToLink){      
      var isLocal = false;
      var isColorShare = false;
      for (var sepName in page.separations){
        var prevBaseSeparation = page.separations[sepName].prevBaseSeparation;
        var baseSeparation = page.separations[sepName].baseSeparation;        
        if (isInList(sepsToLink, sepName) > -1){                    
          if (prevBaseSeparation !== null && typeof prevBaseSeparation !== 'undefined'){
            page.separations[sepName].prevBaseSeparation = null;
            page.separations[sepName].baseSeparation = prevBaseSeparation;
            page.separations[sepName].isChase = true;
            isColorShare = true;
          }
        } else {
          isLocal = true;
          if (baseSeparation !== null && typeof baseSeparation !== 'undefined'){
            page.separations[sepName].baseSeparation = null;
            page.separations[sepName].isChase = false;
            page.separations[sepName].prevBaseSeparation = baseSeparation;
          }
        }
      }
      
      for (var i=0;i<sepsToLink.length;i++){
        var sepToLink = sepsToLink[i];
        if (!page.separations[sepToLink]){
          var removedColor =page.removedColors[sepToLink];
          if (removedColor){
            page.separations[sepToLink] = {sourceId:removedColor.sourceId};
            page.separations[sepToLink].prevBaseSeparation = null;
            page.separations[sepToLink].baseSeparation = removedColor.baseSeparation? removedColor.baseSeparation :  removedColor.prevBaseSeparation;
            page.separations[sepToLink].isChase = true;
            isColorShare = true;
          }
        }
      }
      
		
      if (isLocal){
        page.prevBasePage = page.basePage;
        page.basePage = null;        
        page.isChase = false;
        if (isColorShare){
          page.neCS = true;
        }
      }

    }
    
    function getBaseSep(baseSeps, sepToLink){
      if (baseSeps){
        for (var i = 0; i<baseSeps.length;i++){
          if (baseSeps[i] && baseSeps[i].color === sepToLink ){
            return baseSeps[i];
          }
        }
      }
      
      return null;
    }

    return {
      pageShareActionController: pageShareActionController,
      pageJumpShareActionController: pageJumpShareActionController,
      pageJumpShareActionControllerNew: pageJumpShareActionControllerNew,
      updatePageMasters: updatePageMasters,
      makeLocal: makeLocal,
      getShareMode: getShareMode,
      hasDefaultMaster: hasDefaultMaster,
      chaseColorShare: chaseColorShareAction
    };

  })

;

