/**
 * Created by moshem on 11/11/13.
 */

import Handlebars from 'handlebars';
import base from 'base';
import { translate } from 'core/services/localization';
import jsUtils from 'base/jsUtils';
import formTemplate from 'text!./templates/form.html';
import inputTemplate from 'text!./templates/input.hbs';
import selectTemplate from 'text!./templates/select.hbs';
import checkBoxTemplate from 'text!./templates/checkbox.hbs';
import emptyFormTemplate from 'text!./templates/emptyform.html';
import { openModuleHelp } from 'core/services/helpService';
import { createRoot } from 'react-dom/client';

/**********************************************************
 PRIVATE
 ***********************************************************/

const DIALOG_MIN_HEIGHT = '150px';
const DIALOG_WIDTH = '510px';
const DIALOG_HTML = `
<div class="dialog-container">
  <div class="dialog-body dialog-body-full-height"/>
</div>`;
const DIALOG_BODY_CONTENT_HTML = '<div class="dialog-body-content"></div>';

function createDialog(options) {
  var dialog;
  var closeCallbacks = [];

  function close() {
    closeCallbacks.forEach(function (func) {
      func();
    });
    dialog.destroy();
  }

  const appendTo = options.win ? options.win.document.body : undefined;
  const growWithContent = !(parseInt(options.height, 10) > 0);

  const $dialogNode = $(DIALOG_HTML);
  const $dialogBodyNode = $dialogNode.find('.dialog-body');
  $dialogBodyNode.append($(options.content));
  if (growWithContent) {
    base.dom.removeClass($dialogBodyNode, 'dialog-body-full-height');
  }

  $dialogNode.kendoWindow({
    minHeight: DIALOG_MIN_HEIGHT,
    height: options.height,
    width: options.width || DIALOG_WIDTH,
    title: options.title || translate(options.defaultTitle),
    modal: options.modal,
    resizable: true,
    animation: false,
    actions: options.includeHelp ? ["Help", "Close"] : ["Close"],
    open: function (e) {
      e.sender.wrapper.css({ display: 'flex', flexDirection: 'column' });
      (typeof options.top !== 'undefined') && e.sender.wrapper.css({ top: options.top });
      (typeof options.left !== 'undefined') && e.sender.wrapper.css({ left: options.left });
    },
    close: close,
    appendTo
  });

  dialog = $dialogNode.data('kendoWindow');
  dialog.center().open();
  dialog.addCloseCallback = function (func) {
    closeCallbacks.push(func);
  };

  return dialog;
}

function appendButton(title, dialog, callback) {
  var button, buttonsContainer;
  var containerElem = $(dialog.element);
  var dialogBody = base.dom.find(".dialog-body", containerElem);
  buttonsContainer = base.dom.find(".dialog-footer", containerElem);
  //for the first append
  if (buttonsContainer.length === 0) {
    buttonsContainer = base.dom.createNode('<div class="dialog-footer crtx-align-content-right"></div>');
    base.dom.append(containerElem, buttonsContainer);
  }
  var translatedTitle = translate(title);
  const className = title?.toLowerCase() === 'ok' ? 'crtx-button primary' : 'crtx-button';
  button = base.dom.createNode(`<button class="${className}">${translatedTitle}</button>`);

  function listenCallback(ev) {
    var result;
    if (typeof callback !== 'undefined') {
      result = callback.call(this, ev);
    }
    if (result === false) {
      return;
    }
    dialog.close();
  }

  base.events.listen(button, "click", listenCallback);
  dialog.addCloseCallback(function () {
    base.events.unlisten(button, "click", listenCallback);
  });
  base.dom.append(buttonsContainer, button);
  return button;
}

/**********************************************************
 PUBLIC
 ***********************************************************/

/**
 * Create and show dialog function
 * @param {String} title - dialog title
 * @param {Object} content - HTML Node Element
 * @param {Boolean} modal - whether dialog is modal or not
 * @param {String | Number} width - dialog width
 * @param {String | Number} height - dialog height
 * @param {String | Number} top - dialog top
 * @param {String | Number} left - dialog left
 * @param {Object} win - opener window
 * @param {Array} buttons - array of buttons objects with these keys:
 *    title {String}
 *    onClick {Function} - onClick function with params:: event (onClick event Object)
 * @param {Boolean} includeHelp - whether to include the help button
 * @param {String} helpModuleName - help module name
 * @param {String} helpRootType - help root object type
 * @param {Function} init - init function with params: content (Node Element)
 * @param {Function} onClose - function that is called the dialog 'X' button is clicked
 */
function show({
  title = '',
  content = base.dom.createNode(DIALOG_BODY_CONTENT_HTML),
  modal = false,
  width,
  height,
  top,
  left,
  win,
  buttons = [],
  includeHelp = false,
  helpModuleName = '',
  helpRootType = '',
  init = () => {
  },
  onClose = () => {
  },
}) {

  width = typeof width === 'number' ? `${width}px` : width;
  height = typeof height === 'number' ? `${height}px` : height;
  top = typeof top === 'number' ? `${top}px` : top;
  left = typeof left === 'number' ? `${left}px` : left;

  const dialog = createDialog({
    content,
    title,
    modal,
    width,
    height,
    top,
    left,
    win,
    includeHelp
  });

  if (includeHelp) {
    const helpDomIcon = base.dom.find('.k-i-help', base.dom.parent(dialog.element));
    base.events.listen(base.dom.parent(helpDomIcon), 'click', function () {
      openModuleHelp(helpModuleName, helpRootType, win);
    });
  }

  dialog.bind('close', onClose);

  buttons.forEach(function (button) {
    const title = button.title || '';
    const onClick = button.onClick || function () {
    };
    appendButton(title, dialog, onClick);
  });

  init(content, dialog);

  return dialog;
}

function showInfoDialog(view, title, win) {
  var node, dialog;
  node = base.dom.createNode("<div class='dialog-container'><div class='dialog-body'></div></div>");
  dialog = createDialog({
    content: node,
    title,
    modal: true,
    win
  });
  view.create({
    didInsertElement: function () {
      appendButton('Close', dialog);
      this.off('didInsertElement');
    }
  }).appendTo(node.find('.dialog-body'));
}

var formCounter = 0;
var inputTempCompiled = Handlebars.compile(inputTemplate);
var selectTempCompiled = Handlebars.compile(selectTemplate);
var checkboxTempCompiled = Handlebars.compile(checkBoxTemplate);

function showFormDialog(rows, submitCallback, callbackContext, title, header, text) {
  //TODO: localization
  var formContainer,
    dialog,
    formTemplateArgs = {
      header: header || "",
      text: text || "",
      formId: "form-" + formCounter++
    };


  formTemplateArgs.rows = rows.map(function (curr, idx) {
    curr.rowId = "form-input-" + idx;
    if (curr.tag === "select") {
      return selectTempCompiled(curr);
    } else if (curr.type === "checkbox") {
      return checkboxTempCompiled(curr);
    }
    return inputTempCompiled(curr);
  }).join("\n");

  formContainer = base.dom.createNode(jsUtils.format(formTemplate, formTemplateArgs));
  formContainer.find("#" + formTemplateArgs.formId).append($(formTemplateArgs.rows));
  dialog = createDialog({
    content: formContainer,
    title: title || 'Form',
    modal: true,
    width: '400px'
  });

  function submit() {
    var form = base.dom.find("#" + formTemplateArgs.formId)[0],
      values = [];
    for (var i = 0, leni = form.length; i < leni; i++) {
      if (form[i].type == "checkbox") {
        values[i] = {
          isSelected: form[i].checked,
          value: form[i].value,
          placeholder: form[i].placeholder//use this value to filter data back to server
        };
      }
      else {
        values[i] = {
          value: form[i].value,
          placeholder: form[i].placeholder
        };
      }
    }
    if (typeof submitCallback === 'function') {
      return submitCallback.apply(callbackContext, values);
    }
    return true;
  }

  var submitB = appendButton('OK', dialog, submit);
  appendButton('Cancel', dialog);
  base.events.listen("#" + formTemplateArgs.formId, 'submit', null, function (a, b) {
    console.log(a, b);
    submitB.click();
    return false;
  });
}

function showPlainHtml(title, htmlElement, buttons, callbackContext) {
  const formId = "form-" + formCounter++;
  const formTemplateArgs = {
    formId: formId
  };
  const formContainer = base.dom.createNode(jsUtils.format(emptyFormTemplate, formTemplateArgs));
  const dialog = createDialog({
    content: formContainer,
    title: title || 'Form',
    modal: true,
    width: '400px'
  });

  formContainer.find("#" + formId).append(htmlElement);


  var button;
  for (var i = 0, leni = buttons.length; i < leni; i++) {
    if (typeof buttons[i].callback != 'undefined') {
      var buttonfunc = createSubmitFunction(formId, buttons[i].callback, callbackContext);
      button = appendButton(buttons[i].label, dialog, buttonfunc);
    } else {
      button = appendButton(buttons[i].label, dialog);
    }
    if (typeof buttons[i].id != 'undefined')
      button.attr('id', buttons[i].id);
  }

  return dialog;
}

function createSubmitFunction(formId, submitCallback, callbackContext) {
  var submit = function submit(ev) {
    var form = base.dom.find("#" + formId)[0],
      inputs = [];
    for (var i = 0, leni = form.length; i < leni; i++) {
      inputs.push(form[i]);
    }
    if (typeof submitCallback === 'function') {
      return submitCallback.call(callbackContext, ev, inputs);
    }
    return true;
  };
  return submit;
}

function extendRow(row, idx, formId) {
  var inputId = formId + "-" + idx;
  row.inputId = inputId;
  row.inputAttrs = row.inputAttrs || {};
  row.labelAttrs = row.labelAttrs || {};
  row.inputAttrs.class = row.inputAttrs.class || "";
  row.labelAttrs.class = row.labelAttrs.class || "";

  switch (row.inputAttrs.type) {
    case "radio":
      row.inputFirst = true;
      row.inputAttrs.class += "k-radio";
      row.labelAttrs.class += "k-radio-label";
      break;
    case "checkbox":
      row.inputFirst = true;
      row.inputAttrs.class += "k-checkbox";
      row.labelAttrs.class += "k-checkbox-label";
      break;
  }
}

function createFormRows(rows, formId) {
  return rows.map(function (curr, idx) {
    extendRow(curr || {}, idx, formId);
    var row = $(inputTempCompiled(curr));
    row.find('label').attr(curr.labelAttrs);
    row.find('input').attr(curr.inputAttrs);
    return row;
  });
}

function showFormHelper(rows, submitCallback, callbackContext, title, header, text) {
  const formId = "form-" + formCounter++;
  const formTemplateArgs = {
    header: header || "",
    text: text || "",
    formId: formId
  };
  const formContainer = base.dom.createNode(jsUtils.format(formTemplate, formTemplateArgs));
  const dialog = createDialog({
    content: formContainer,
    title: title || 'Form',
    modal: true,
    width: '400px'
  });

  formContainer.find("#" + formId).append(createFormRows(rows || [], formId));
  var submit = createSubmitFunction(formId, submitCallback, callbackContext);
  appendButton('OK', dialog, submit);
  appendButton('Cancel', dialog);

}

function showCustomDialog(message, submitCallback, callbackContext, title, header, text) {
  const translatedMsg = translate(message || "");
  const content = base.dom.createNode('<div class="dialog-msg dialog-msg-confirm">' +
    translatedMsg.replace(/\.|\,/g, '\n') + '</div>');

  const dialog = createDialog({
    content,
    title: title || 'Confirm',
    modal: true
  });

  function submit() {
    var form = base.dom.find(".cpClass"),
      values = [];

    for (var i = 0, leni = form.length; i < leni; i++) {
      if ((form[i].type == "radio" || form[i].type == "checkbox")) {
        //if checked return the value else return blank to clear it from property if it was set.
        var foundProp = false;
        for (var j = 0, lenj = values.length; j < lenj; j++) {
          if ((values[j].name == form[i].name)) {
            foundProp = true;
            if (values[j].value == "" && (form[i].checked)) {//is value from before ""
              values[j].value = form[i].value;// yes update current value
            }
          }
        }
        if (!foundProp) {
          ((form[i].checked) ? values.push({
            name: form[i].name,
            value: form[i].value
          }) : values.push({ name: form[i].name, value: "" }));
        }
      }
      else if (form[i].type == "select-one") {
        values.push({ name: form[i].name, value: form[i].selectedOptions[0].value });
      }
      else if (form[i].type == "select-multiple") {
        var vals = "";
        for (var j = 0, lenj = form[i].selectedOptions.length; j < lenj; j++) {
          vals += form[i].selectedOptions[j].value + ",";//comma separated values
        }
        values.push({ name: form[i].name, value: vals });
      }
    }
    if (typeof submitCallback === 'function') {
      return submitCallback.apply(callbackContext, [values]);
    }
    return true;
  }

  var submitB = appendButton('Apply', dialog, submit);
  appendButton('Cancel', dialog);
  base.events.listen("#" + content.formId, 'submit', null, function () {
    submitB.click();
    return false;
  });
}

function createSetupDialog(view, title, width, submitCallback, callbackContext, helpModuleName, helpRootType) {
  const selector = "form-" + formCounter++;
  const formContainer = $('<div id="' + selector + '" class="simple-setup-dialog"></div>');
  view.appendTo($(formContainer));
  const dialog = createDialog({
    content: formContainer,
    title,
    modal: true,
    width,
    includeHelp: true,
    top: 10
  });

  const helpDomIcon = base.dom.find('.k-i-help', base.dom.parent(dialog.element));
  base.events.listen(base.dom.parent(helpDomIcon), 'click', function () {
    openModuleHelp(helpModuleName, helpRootType, helpDomIcon[0].ownerDocument.defaultView);
  });

  dialog.bind('resize', function () {
    if (typeof view.onResize === 'function') {
      view.onResize.apply(view, arguments);
    }
  });

  function submit() {
    var values = [];
    if (typeof submitCallback === 'function') {
      return submitCallback.apply(callbackContext, [view]);
    }
    return true;
  }

  if (typeof submitCallback === 'function') {
    appendButton('Apply', dialog, submit);
    appendButton('Cancel', dialog);
  }

  return dialog;
}

function createSimpleSetupDialog(SimpleSetup, title, width, height, submitCallback, closeCallback, callbackContext, helpModuleName, helpRootType) {
  const selector = "form-" + formCounter++;
  const formContainer = $('<div id="' + selector + '" style="height: ' + height + '"></div>');
  const dialog = createDialog({
    content: formContainer,
    title,
    modal: true,
    width,
    includeHelp: true,
  });

  const simpleSetup = new SimpleSetup(submitCallback, closeCallback, callbackContext);
  simpleSetup.element = '#' + selector;
  simpleSetup.container = dialog;

  const helpDomIcon = base.dom.find('.k-i-help', base.dom.parent(dialog.element));
  base.events.listen(base.dom.parent(helpDomIcon), 'click', function () {
    openModuleHelp(helpModuleName, helpRootType, helpDomIcon[0].ownerDocument.defaultView);
  });

  dialog.bind('close', onClose);

  function onClose(ev) {
    if (typeof callbackContext !== 'undefined') {
      closeCallback.call(callbackContext, ev);
    }
    else {
      callbackContext(ev);
    }
  }

  function submit() {
    var values = [];
    if (typeof submitCallback === 'function') {
      return submitCallback.call(callbackContext);
    }
    return true;
  }

  return simpleSetup;
}


function createSelectionDialog(title, objs, submitCallback, callbackContext, isMultiSelect, header, text) {
  var rows = objs.map(function (obj) {
    return {
      tag: "input",
      label: obj.label,
      inputAttrs: {
        type: isMultiSelect ? "checkbox" : "radio",
        name: obj.name || obj.label,
        value: obj.value || obj.label
      }
    };
  });
  var submit = function (ev, inputs) {
    var selected = inputs.find(function (elem) {
      return elem.checked === true;
    });
    submitCallback.call(this, selected && selected.value ? selected.value : "");
  };
  return showFormHelper(rows, submit, callbackContext, title, header, text);
}

/**
 * Shows modal dialog with OK and Cancel buttons
 * @param {object} contentProps - properties passed to the content component
 * @param {function} contentGetter - function that returns React element to be rendered into the dialog content node
 * @param {object} options - optional additional dialog parameters
 * @returns {promise} Promise resolved to the model of the content component
 */
const showOkCancelDialog = (contentProps, contentGetter, options = {}) => {
  return new Promise((resolve, reject) => {
    let getModel;

    const handleOkClick = () => {
      const model = getModel();
      if (model === false) {
        return false;
      }

      resolve(model);
    };

    const handleCancelClick = () => {
      reject('cancel');
    };

    show({
      modal: true,
      width: options.width || 300,
      height: options.height || 150,
      title: options.title,
      buttons: [
        {
          title: 'OK', onClick: handleOkClick
        },
        {
          title: 'Cancel', onClick: handleCancelClick
        }
      ],

      onClose: handleCancelClick,

      init: content => {
        const contentNode = content[0];
        const reactRoot = createRoot(contentNode);

        contentNode.style.width = '100%';
        contentNode.style.height = '100%';
        reactRoot.render(
          contentGetter({ ...contentProps, getModelSetter: getModelFunc => getModel = getModelFunc }));
      }
    });
  });
};

/**
 * Open dialog window
 * @param {string | object} message - dialog content that can be a message string or a React element
 * @param {string} title - dialog title
 * @param {Array} buttons - array of buttons objects with these keys:
 *    id {String} - button id that is passed back to the resolve or reject functions
 *    title {String} - pre-translated button label
 *    reject {Boolean} - call reject(button.id) if true and resolve(button.id) otherwise
 * @param {object} options - optional additional dialog parameters
 * @returns {promise} Promise that is resolved when dialog button is clicked
 */
function openDialog(message, title, buttons, options = {}) {
  if (typeof message === 'string') {
    message = message.replace(/<br>/g, '\n');
  }

  return new Promise((resolve, reject) => {
    show({
      ...options,
      title,
      modal: typeof options.modal === 'undefined' ? true : options.modal,
      content: base.dom.createNode(`<div class="dialog-body-content dialog-msg ${options.cssClass || ''}"/>`),
      buttons: buttons.map(btn => ({ ...btn, onClick: () => btn.reject ? reject(btn.id) : resolve(btn.id) })),
      onClose: () => buttons.length <= 1 ? resolve('close') : reject('close'),
      init: $contentNode => {
        const reactRoot = createRoot($contentNode[0]);
        reactRoot.render(message);
      }
    });
  });
}

// ================================= NEW API =================================

function openAlertDialog(message, title, options = {}) {
  title = title || translate('Alert');

  const buttons = options.buttons || [
    {
      id: 'ok',
      title: translate('OK')
    }];

  return openDialog(message, title, buttons, options);
}

function openConfirmDialog(message, title, options = {}) {
  const buttons = options.buttons || [
    {
      id: 'ok',
      title: translate('OK')
    },
    {
      id: 'cancel',
      title: translate('Cancel'),
      reject: true
    }];

  return openDialog(message, title, buttons, options);
}

function openConfirmSaveDialog(message, title, options = {}) {
  const buttons = [
    {
      id: 'yes',
      title: translate('Save')
    },
    {
      id: 'no',
      title: translate("Don't Save")
    },
    {
      id: 'cancel',
      title: translate('Cancel'),
      reject: true
    }
  ];

  return openDialog(message, title, buttons, options);
}

module.exports = {
  _name: 'dialog',
  _type: 'service',

  show: show,
  showCustomDialog,
  showInfoDialog,
  showFormDialog,
  createSelectionDialog,
  createSetupDialog,
  createSimpleSetupDialog,
  showPlainHtml,
  showOkCancelDialog,
  openAlertDialog,
  openConfirmDialog,
  openConfirmSaveDialog,
};
