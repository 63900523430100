define(['ember', 'logger'], function (Ember, logger) {
  'use strict';
  var log = logger.getDefaultLogger();

  return Ember.ObjectController.extend({

    deletePreflightSetup: function (preflightSetup) {
      var deletePreflightSetupAction = this.get('module.viewActions').find(a => a.actionDefinitionName === 'DeletePreflightSetupActionCR');
      if (typeof deletePreflightSetupAction === 'undefined') {
        log.error('DeletePreflightSetupAction instance is not found');
        return;
      }

      if (deletePreflightSetupAction.isApplicable(preflightSetup)) {
    	  deletePreflightSetupAction.execute(preflightSetup);
      }
    },

    savePreflightSetup: function () {
      var editPreflightSetupAction = this.get('module.viewActions').find(a => a.actionDefinitionName === 'EditPreflightConfigSetupActionCR');
      if (typeof editPreflightSetupAction === 'undefined') {
        log.error('EditPreflightSetupAction instance is not found');
        return;
      }

      var preflightSetup = this.get('content');
      if (editPreflightSetupAction.isApplicable(preflightSetup)) {
    	  editPreflightSetupAction.execute(preflightSetup);
      }
    },

    cannotSubmit: function () {
      return !this.get("validName");
    }.property("validName"),

    validName: function () {
      return this.get("name").length >= 1;
    }.property("name")


  });
});