import React from 'react';
import PropTypes from 'prop-types';
import ConversionOutput from './ConversionOutput';

const DEGREE_SYMBOL = '°';

export default function DegreesOutput(
  {
    value,
    className,
    style,
  }) {

  return (
    <ConversionOutput
      value={value}
      unitSymbol={DEGREE_SYMBOL}
      className={className}
      style={style}
    />
  );
}

DegreesOutput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  style: PropTypes.object,
};
