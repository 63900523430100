import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ScrollablePanels from 'src/components/common/ScrollablePanels/ScrollablePanels';
import Panel from 'src/components/common/ScrollablePanels/Panel';
import Button from 'src/components/common/buttons/Button';
import ToggleButton from 'src/components/common/buttons/ToggleButton';
import sandbox from 'sandbox';
import { formatBreadcrumbs } from 'utilities/breadcrumbs';

const refreshIcon = sandbox.icons.getModuleIcon('InfoDialog', 'refresh');
const translate = sandbox.localization.translate;

const labels = {
  lastUpdate: translate('Last update'),
  advanced: translate('Advanced'),
  page: translate('Page'),
  form: translate('Form')
};

export default class CustomInfo extends Component {

  static propTypes = {
    model: PropTypes.any,
    className: PropTypes.string,
    panels: PropTypes.array,
    refreshData: PropTypes.func,
    openCustomInfoDialog: PropTypes.func,
    headerBreadcrumbs: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      toggleChecked: false,
    };
  }

  getPanel = (panel, index) => {
    const { className, openCustomInfoDialog } = this.props;
    const customInfoComponent = <panel.PanelComp {...panel.props} title={panel.title} advanceInfo={this.state.toggleChecked} openCustomInfoDialog={openCustomInfoDialog} openerType='dialog' />;

    return <Panel key={index} className={`crtx-custom-info-panel ${className}`} icon={panel.icon} title={panel.title}>
      {customInfoComponent}
    </Panel>;
  };

  getPanelsComponents = () => {
    const { panels } = this.props;
    const panelsComponents = panels.map(this.getPanel).filter((panel) => { return panel != undefined; });
    return panelsComponents;
  };

  handleRefresh = (event) => {
    this.props.refreshData();
  };

  handleClick = () => {
    this.setState({
      toggleChecked: !this.state.toggleChecked
    });
  };

  setPanelIndex = (index) => {
    this.setState({
      selectedIndex: index
    });
  };

  render() {
    const { model, className, headerBreadcrumbs } = this.props;
    const date = new Date();
    const shortTime = sandbox.localization.toLocaleShortTime(date);
    const transElementType = model.contentType === 'AD' || model.contentType === 'EDITORIAL' ? model.name : model.object.elementType === 'page' ? labels.page : labels.form;

    return <div className='crtx-custom-info' style={{ width: '100%', height: '100%' }}>
      <div className='crtx-custom-info-header'>
        <div className='crtx-advance-and-refresh'>
          <div className='crtx-buffer'></div>
          <ToggleButton className='crtx-advance-toggle-button' onClick={this.handleClick} checked={this.state.toggleChecked}>{labels.advanced}</ToggleButton>
          <div className='crtx-buffer'></div>
          <Button className='crtx-refresh-button' onClick={this.handleRefresh}><img src={refreshIcon} /></Button>
          <span className='crtx-date'>{labels.lastUpdate} {shortTime}</span>
        </div>

        <div className='crtx-outer-info-breadcrumbs' title={formatBreadcrumbs(model.object.breadcrumbs)}>
          <span className='crtx-inner-info-breadcrumbs ellipsis'>{`${transElementType}: ${headerBreadcrumbs}`}</span>
        </div>

      </div>
      <ScrollablePanels className='custom-info-content' style={{ width: '100%' }} >
        {this.getPanelsComponents()}
      </ScrollablePanels>
    </div>;

  }
}
//►
