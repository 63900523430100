/**
 * @fileOverview
 * @author sergey
 */

import sandbox from 'sandbox';
import AbstractAction from 'AbstractAction';
import { getHoldType } from 'utilities/hold';

export default AbstractAction.extend({

  isApplicable: function (objs) {
    if (!Array.isArray(objs) || objs.length === 0) {
      return false;
    }

    for (const obj of objs) {
      const holdType = getHoldType(obj);

      if (obj.type === 'page') {
        if (holdType === 'content') {
          return false;
        } else {
          return true;
        }
      }


      if (holdType === 'none' || (holdType !== 'structure' && holdType !== 'scheduled')) {
        return true;
      }

      /*if (obj.holdByExternalGrpoup !== 'true' &&
        (typeof holdType === 'undefined' ||
          holdType === 'none' ||
          (obj.type === 'page' && holdType !== 'content'))) {
        isOnHold = false;
        break;
      }*/
    }
    return false;
  },

  execute: function (objs) {
    var viewInstanceNwId = this.module.nwid;
    var deviceNwId = this.module.viewSettings.rootId;
    var projectorId = this.module.projectorId;


    for (var index = 0; index < objs.length; index++) {
      var obj = objs[index];
      if (typeof obj !== 'undefined') {
        const type = obj.type;
        const nwid = obj.nwid;
        var request = sandbox.request.holdElement(viewInstanceNwId, this.nwid, nwid, type, projectorId,
          {
            config: this.config,
            type,
            nwid,
            purge: true,
            parameters: this.parameters
          }
        );
      }
    }

    if (this.module.name.toLowerCase() === 'pageview') {
      setTimeout(function () {
        window.close();
      }, 1500);
    }
  }
});