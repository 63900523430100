/**
 * Created by moshemal on 6/16/14.
 */

import helpMap from 'assets/help/map.json';
import { coretexPath } from 'utilities/url';

function getHelpRootPath() {
  //TODO: when there will be more languages get Language Code from localization:
  const lang = 'en-us';

  return coretexPath('/html/kernel/assets/help', lang, 'index.htm#t=');
}

export function openModuleHelp(moduleName, rootType, domWindow) {
  let relativeUrl = helpMap['homePage'];
  const modulesMap = helpMap['modules'];

  if (modulesMap[moduleName] && modulesMap[moduleName][rootType]) {
    relativeUrl = modulesMap[moduleName][rootType];
  } else if (modulesMap[moduleName] && modulesMap[moduleName]['default']) {
    relativeUrl = modulesMap[moduleName]['default'];
  }

  const url = getHelpRootPath() + relativeUrl;
  console.log(`Opening help => moduleName: ${moduleName}, rootType: ${rootType}, url: ${url}`);

  (domWindow || window).open(url, '_blank', 'width=1600, height=900');
}
