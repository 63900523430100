import React, { Component } from 'react';
import Loader from 'src/components/common/loader/Loader';
import sandbox from 'sandbox';
import PropTypes from 'prop-types';
const translate = sandbox.localization.translate;

class EmptyComponent extends Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    loading: PropTypes.bool
  }

  render() {
    const { loading } = this.props;
    const content = !loading ? <div className='crtx-empty-component-content'>
      <span>{translate('No information to show')}</span>
    </div> : undefined;
    return <div className='crtx-empty-component'>
      <Loader loading={loading} />
      {content}
    </div>
  }

}

export default EmptyComponent;