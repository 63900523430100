/**
 * Created by moshem on 2/4/14.
 */
define(['ember', 'core/services/localization'], function (Ember, localization) {
  'use strict';

  Ember.Handlebars.registerHelper('toLocaleShortDateTime', localization.toLocaleShortDateTime);
  Ember.Handlebars.registerHelper('toLocaleShortDate', localization.toLocaleShortDate);
  Ember.Handlebars.registerHelper('toLocaleShortTime', localization.toLocaleShortTime);

  Ember.Handlebars.registerBoundHelper('boundToLocaleShortDateTime', localization.toLocaleShortDateTime);
  Ember.Handlebars.registerBoundHelper('boundToLocaleShortDate', localization.toLocaleShortDate);
  Ember.Handlebars.registerBoundHelper('boundToLocaleShortTime', localization.toLocaleShortTime);

  Ember.Handlebars.registerBoundHelper('breaklines', function(text) {
    text = Handlebars.Utils.escapeExpression(text);
    text = text.split('\\n').join('<br />').split('\\r').join('<br />');
    return new Handlebars.SafeString(text);
  });
});