/**
 * Created by elad on 4/3/2017.
 */

import $ from 'jquery';
import sandbox from 'sandbox';
import AbstractStep from 'widgets/Wizard/AbstractStep';
import MainView from './views/ProductionRunsMainView';
import Controller from './controllers/ProductionRunsController';
import { restGet } from 'core/managers/rest2';
import { parseDate} from 'core/dates';

function getCriterions() {
  var publication = this.model.publication.displayName;
  var edition = this.model.publication.editions[0].displayName;
  var zones = this.model.publication.editions[0].zones;
  var criterions = "";
  for (var z = 0; z < zones.length; z++) {
    var books = zones[z].books;
    for (var b = 0; b < books.length; b++) {
      var criteria = publication + "," + edition + "," + zones[z].displayName + "," + books[b].displayName;
      criterions += criteria + ";";
    }
  }
  return criterions;
}

function init(initObj) {
  this._super(initObj);

  this.wizard = initObj.wizard;
  this.rootId = initObj.startParameters.rootId;
  this.nwid = initObj.startParameters.nwid;
  var productionRunsProperties = initObj.stepProperties;
  this.fanoutMode = "PageFanout";
  this.printMode = "collect";
  this.presses = [];
  this.fanoutsTableName = 'Simple Form Fanout';
  this.fanoutNameCriteria = 'name';
  // if (stepsProperties !== null && typeof stepsProperties !== "undefined") {
    // var productionRunsProperties = stepsProperties.steps.productionRuns;
    if (typeof productionRunsProperties !== "undefined") {
      this.tableName = productionRunsProperties.tableName;
      this.tableValue = productionRunsProperties.tableValue;
      this.fanoutMode = productionRunsProperties.fanoutMode || "PageFanout";
      this.printMode = productionRunsProperties.printMode || "collect";
      this.printMode = this.printMode.toLowerCase();
      if (typeof productionRunsProperties.booksSelected === 'undefined') {
		  this.booksSelected = true;
	  } else {
		  this.booksSelected = productionRunsProperties.booksSelected;
	  }
    }
  // }
}


function back() {
  return this.controller.updateModel(this.model);
}

function next() {
  return this.controller.updateModel(this.model);
}

function destroy() {
  console.log("destroy");
}

function render() {
  this.wizard.disableButton("cancel");
  const issueDate = parseDate(this.model.publication.issueDate, 'yyyy-M-d', 'en-US');
  if (typeof this.controller === "undefined" || this.controller === null) {
    this.controller = Controller.create({
      fanoutMode: this.fanoutMode,
      presses: this.presses,
      printMode: this.printMode,
      clientSettings: this.model.clientSettings || {},
      issueDate: issueDate,
      booksSelected: this.booksSelected
    });
  }
  var isOverrideTable = (typeof this.tableName !== "undefined" && typeof this.tableValue !== "undefined");
  this.controller.updateUiModel(this.model, this, this.fanouts, this.overrideCusomData, isOverrideTable, this.fanoutsTemplates);
  $(this.container).empty();
  this._view = MainView.create({
    controller: this.controller
  }).appendTo(this.container);
}

module.exports = AbstractStep.extend({
  init: init,
  load: async function () {
    var folderId = this.model.folder;
    const presses = await restGet(this.nwid, `press-info/pressNames`).then(response => response.presses);
    this.presses = [''].concat(presses.map(press => press.name));
    const publicationFanoutModeData = await restGet(this.nwid, `conversion-tables/PublicationFanoutMode/value?value=fanoutMode&folder=${folderId}&criterions=${this.model.publication.displayName}`);
    console.log("publicationFanoutMode=" + publicationFanoutModeData);

    if (typeof publicationFanoutModeData !== "undefined" && typeof publicationFanoutModeData.values !== "undefined" && publicationFanoutModeData.values.length > 0) {
      let fanoutMode = publicationFanoutModeData.values[0].value;
      if (fanoutMode !== "") {
        this.fanoutMode = fanoutMode;
      }
    }
    if (this.fanoutMode === "TemplateBasedFormFanout") {
      const fanoutTemplates = await restGet(this.nwid, `/fanouts/${this.rootId}/getAllFanoutTemplatesInfo`);
      //console.log ("----------------------" + fanoutTemplates);
      const templates = fanoutTemplates.templates || [];
      this.fanoutsTemplates = {};
      templates.forEach(template => {
       let list = this.fanoutsTemplates[template.formCount] || [];
       list.push(template);
       this.fanoutsTemplates[template.formCount] = list;       
      });
      //console.log ("----------------------" + this.fanoutsTemplates);
      if (typeof this.tableName !== "undefined" && typeof this.tableValue !== "undefined") {
        var criterions = getCriterions.call(this);
        this.overrideCusomData = await restGet(this.nwid, `conversion-tables/${this.tableName}/value?value=${this.tableValue}&folder=${this.rootId}&criterions=${criterions}`);
      }
    } else if (this.fanoutMode.toLowerCase() === "simpleformfanout") {
      const fanoutsTableNames = await restGet(this.nwid, `conversion-tables/${this.fanoutsTableName}/data`);
      const names = typeof fanoutsTableNames !== "undefined" ? fanoutsTableNames.rows.map(name => name.selectors[this.fanoutNameCriteria]) : [];

      names.map(item => item).filter((value, index, self) => self.indexOf(value) === index)
      this.fanouts = names;
      if (typeof this.tableName !== "undefined" && typeof this.tableValue !== "undefined") {
        var criterions = getCriterions.call(this);
        this.overrideCusomData = await restGet(this.nwid, `conversion-tables/${this.tableName}/value?value=${this.tableValue}&folder=${this.rootId}&criterions=${criterions}`);
      }
    } else {
      let edition = this.model.publication.editions[0];
      let fanouts = await sandbox.request.getPlan(this, this.rootId, null, this.id,
        {
          rootId: this.model.folder,
          rootType: 'folder',
          edition: edition.nwid,
          editionModel: edition,
          mode: "fanouts"
        });
      this.fanouts = fanouts.data;
      if (typeof this.tableName !== "undefined" && typeof this.tableValue !== "undefined") {
        var criterions = getCriterions.call(this);
        this.overrideCusomData = await restGet(this.nwid, `conversion-tables/${this.tableName}/value?value=${this.tableValue}&folder=${this.rootId}&criterions=${criterions}`);
      }
    }
    

    return Promise.resolve();

  },
  render: render,
  back: back,
  next: next,
  destroy: destroy
}, "ProductionRuns");