/**
 * @name textBurnFabric
 * @file Extends Fabric Object class to define text rendering for each Text Burn segment
 *
 * @author Boris
 * @since: 2016-08-04
 */

import Fabric from 'fabric';
import utils from '../utils/utils';

export default Fabric.util.createClass(Fabric.Object, {
  initialize: function (options) {
    options || (options = {});

    this.callSuper('initialize', options);
    this.set('segments', options.segments || []);
  },

  _render: function (ctx) {
    //console.log("textBurnFabric._render()");
    ctx.textBaseline = "alphabetic";
    ctx.textAlign = 'left';
    var height = this.get('height');
    var width = this.get('width');
    var x = -width / 2;
    var segments = this.get('segments');
    var color = this.whiteText ? "#ffffff" : "#000000";

    segments.forEach(function (seg) {
      ctx.font = utils.composeCanvasFont(seg.fontStyle, seg.fontWeight, seg.fontSize, seg.fontFamily);
      ctx.fillStyle = color;
      ctx.fillText(seg.sample, x, height / 2);
      x += ctx.measureText(seg.sample).width;
    });
  }
});
