import { localization } from 'sandbox';

const { translate } = localization;

export const ERROR_STATE = 'error';
export const FINISHED_STATE = 'success';
export const IN_PROGRESS_STATE = 'inprogress';
export const NONE_STATE = 'none';

export const OVERRIDE_AGGREGATION_STATE = {
  '#ff0800': ERROR_STATE,
  '#ffcd35': IN_PROGRESS_STATE,
  '#0ab006': FINISHED_STATE
};

export const COLORS_BY_STATE = {
  [ERROR_STATE]: '#ff0800',
  [IN_PROGRESS_STATE]: '#ffcd35',
  [FINISHED_STATE]: '#0ab006',
  [NONE_STATE]: '#e7e7e7'
};

export const DEFAULT_TYPE_COLOR = '#e7e7e7';
export const COLORS_BY_TYPE = {
  folder: '#175d89',
  workflow: '#175d89',
};

export const WAITING_FOR_APPROVAL_COLOR = '#5eced5';

export const SVG_ICONS_BY_TYPE = {
  folder: { id: 'folder', title: translate('Folder') },
  workflow: { id: 'workflow', title: translate('Workflow') },
  publicationdate: { id: 'publicationdate', title: translate('Publication date') },
  publicationname: { id: 'folder', title: translate('Publication') },
  publication: { id: 'folder', title: translate('Publication') },
  edition: { id: 'folder', title: translate('Edition') },
  zone: { id: 'zone', title: translate('Zone') },
  section: { id: 'pages', title: translate('Section') },
  sections: { id: 'pages', title: translate('Sections') },
  page: { id: 'page', title: translate('Page') },
  pages: { id: 'pages', title: translate('Pages') },
  form: { id: 'form', title: translate('Form') },
  forms: { id: 'forms', title: translate('Forms') },
  book: { id: 'forms', title: translate('Book') },
  books: { id: 'forms', title: translate('Books') },
  planpress: { id: 'planpress', title: translate('Press') },
  plate: { id: 'plate', title: translate('Plate') },
  plates: { id: 'plates', title: translate('Plates') },
  productionrun: { id: 'productionrun', title: translate('Production run') },
  productionruns: { id: 'productionruns', title: translate('Production runs') },
  ['productionrun/tower']: { id: 'tower', title: translate('Tower') },
  ['productionrun/cylinder']: { id: 'cylinder', title: translate('Cylinder') },
};

export const DIALOG_TYPE = ['NEW_FOLDER', 'RENAME_FOLDER', 'DUPLICATE_FOLDER'];
