import React from 'react';
import PropTypes from 'prop-types';
import Uploady from '@rpldy/uploady';
import settingsManager from 'core/managers/settings';

const defaultUrl = `${settingsManager.get('webAppPath')}/rest/v1/fileUploader/upload`;
const MAX_CONCURRENT_UPLOADS = 10;

export const UploadComponent = (
  {
    url = defaultUrl,
    viewOrActionNwId,
    children,
    customParams,
    fileInputId,
    inputFieldName = 'qqfile'
  }) => {

  return <Uploady
    destination={{ url, headers: { viewOrActionNwId } }}
    multiple={false}
    concurrent={true}
    maxConcurrent={MAX_CONCURRENT_UPLOADS}
    inputFieldName={inputFieldName}
    params={{ customParams }}
    fileInputId={fileInputId}
  >
    {children}
  </Uploady>;
};

UploadComponent.propTypes = {
  viewOrActionNwId: PropTypes.string,
  children: PropTypes.node,
  customParams: PropTypes.object,
  url: PropTypes.string,
  inputFieldName: PropTypes.string,
  fileInputId: PropTypes.string,
};