const ServerAction = require('./ServerAction');

module.exports = ServerAction.extend({
  isApplicable: function (objs) {
    if (typeof objs === 'undefined') {
      return false;
    }
    let applicable = true;
    for (let i = 0; i < objs.length; i++) {
      const element = objs[i];
      if (element.blockPlans === "true") {
        applicable = false;
        break;        
      }
    }
    return applicable;
  }
});
