import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropdown from "components/common/dropdown/Dropdown";

export default class extends Component {
  static propTypes = {
    columnData: PropTypes.any,
    options: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]),
    onChange: PropTypes.func
  };

  static defaultProps = {
    onChange: () => { }
  };

  handleChange = (event, value, index) => {
    var { rowIndex, columnKey, onChange } = this.props;

    if (typeof this.props.onChange === 'function')
      onChange(rowIndex, columnKey, value);
  };

  render() {
    var { columnData, columnKey, options, onChange, ...props } = this.props;

    return <div className="cell">
      <div className="cell-content cell-full-size">
        <Dropdown
          options={options}
          {...props}
          value={columnData}
          onSelect={this.handleChange}
          onClick={event => event.stopPropagation()}
          onDoubleClick={event => event.stopPropagation()}
        />
      </div>
    </div>;
  }
}
