import React, { Component } from 'react';
import * as ReactSortableHoc from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import { DragHandle } from 'components/private/sortableHoc/DragHandle';

const { SortableElement } = ReactSortableHoc;

module.exports = SortableElement(class SortableItem extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  static propTypes = {
    value: PropTypes.any,
    selected: PropTypes.bool,
    index: PropTypes.number,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
  }


  static defaultProps = {
    className: '',
    onClick: () => { },
    allowToOrder: true
  }

  handleClick(ev) {
    const { value, onClick } = this.props;
    onClick(ev, value);
  }

  render() {
    const { selected, allowToOrder, children } = this.props;
    const disabledClassName = allowToOrder ? '' : 'disabled';
    const className = selected ? 'selected' : '';
    const title = typeof children === 'string' ? children : undefined;

    return <li className={`list-item ${className} ${disabledClassName}`} onClick={this.handleClick} title={title}>
      {allowToOrder ? <DragHandle /> : undefined}
      {children}
    </li>;
  }

});
