import { userSecurityGroups } from './securityGroups';

const isUndefined = o => typeof o === 'undefined';
const isEmpty = o => (isUndefined(o) || o === null || o === '');
const isArray = o => Array.isArray(o);
const hasSimilarItems = (arr1, arr2) => arr1.some(item => arr2.indexOf(item) >= 0);
const getContent = item => {
  if (item.type === 'page') return item.pageContent || item.content || item;
  if (item.type === 'form') return item.formContent || item.content || item;
  if (item.type === 'page/separation') return item.separationContent || item.content || item;
  if (item.type === 'form/separation') return item.separationContent || item.content || item;

  return undefined;
};

export const isInApprovalFlowStep = ({ approvalFlowSteps }, allowedFlowstepIds = []) => {
  if (isEmpty(approvalFlowSteps) || approvalFlowSteps.length === 0) return false;

  if (allowedFlowstepIds.length > 0 && !approvalFlowSteps.some(approvalFlowStep => allowedFlowstepIds.includes(approvalFlowStep))) return false;

  return true;
};

export const getItemApprovalIndications = ({ approvalIndication }) => {
  if (isUndefined(approvalIndication)) return undefined;
  if (isArray(approvalIndication)) return approvalIndication;

  return approvalIndication.split(',');
};

export const getItemCompletedIndications = (item) => {
  if (isUndefined(item)) return [];

  const content = getContent(item) || {};
  const externalVersion = content.externalVersion;
  const approvalCompleteVersion = item.approvalCompleteVersion;
  const approvalIndicationComplete = item.approvalIndicationComplete || '';

  if (externalVersion == approvalCompleteVersion) {
    return approvalIndicationComplete.replace('[', '').replace(']', '').split(',').map(indication => indication.trim());
  }

  return [];
};

export const getItemRejectedIndications = (item) => {
  if (isUndefined(item)) return [];

  const content = getContent(item) || {};
  const externalVersion = content.externalVersion;
  const approvalCompleteVersion = item.approvalCompleteVersion;
  const approvalIndicationReject = item.approvalIndicationReject || '';

  if (externalVersion == approvalCompleteVersion) {
    return approvalIndicationReject.replace('[', '').replace(']', '').split(',').map(indication => indication.trim());
  }

  return [];
};

export const getItemUserIndications = ({ approvalSecurityGroups }) => {
  if (isEmpty(approvalSecurityGroups)) return [];
  
  const userSecurityGroupsNwids = userSecurityGroups();

  return Object.keys(approvalSecurityGroups).reduce((userIndications, approvalSecurityGroupIndication) => {
    if (hasSimilarItems(approvalSecurityGroups[approvalSecurityGroupIndication], userSecurityGroupsNwids)) {
      return userIndications.concat(approvalSecurityGroupIndication);
    }
    return userIndications;
  }, []);
};

export const getItemWaitingIndicationsPerUser = (item) => {
  const itemUserIndications = getItemUserIndications(item);
  const approvalIndications = getItemApprovalIndications(item);
  const completedIndications = getItemCompletedIndications(item);
  const rejectedIndications = getItemRejectedIndications(item);
  const approvalIndicationsPerUser = isUndefined(approvalIndications) ? [] : approvalIndications.filter(indication => itemUserIndications.includes(indication));

  return approvalIndicationsPerUser.filter(indication => !completedIndications.includes(indication) && !rejectedIndications.includes(indication));
};

export const getSeparationWaitingIndicationsPerUser = (item, separation) => {
  const itemUserIndications = getItemUserIndications(item);
  const approvalIndications = getItemApprovalIndications(item);
  const completedIndications = getItemCompletedIndications(separation);
  const rejectedIndications = getItemRejectedIndications(separation);
  const approvalIndicationsPerUser = isUndefined(approvalIndications) ? [] : approvalIndications.filter(indication => itemUserIndications.includes(indication));

  return approvalIndicationsPerUser.filter(indication => !completedIndications.includes(indication) && !rejectedIndications.includes(indication));
};

export const areSeparationsInApprovalFlowStep = (separations = [], allowedFlowstepIds) => {
  return separations.some(sep => isInApprovalFlowStep(sep, allowedFlowstepIds));
};

export const isItemWaitingForUserApprovalIndications = (item) => {
  const waitingIndicationsPerUser = getItemWaitingIndicationsPerUser(item);

  return waitingIndicationsPerUser.length > 0;
};

export const isSeparationWaitingForUserApprovalIndications = (item, separation) => {
  const waitingIndicationsPerUser = getSeparationWaitingIndicationsPerUser(item, separation);

  return waitingIndicationsPerUser.length > 0;
};

export const isSeprationWaitingForApproval = (item, separation, allowedFlowstepIds) => {
  const itemUserIndications = getItemUserIndications(item);

  if (!isInApprovalFlowStep(separation, allowedFlowstepIds)) return false;
  if (isEmpty(getItemApprovalIndications(item))) return true;
  if (itemUserIndications.length === 0 && !isEmpty(item.approvalIndication)) return false;

  return isSeparationWaitingForUserApprovalIndications(item, separation);
};

export const isItemWaitingForApproval = (item, allowedFlowstepIds) => {
  const itemUserIndications = getItemUserIndications(item);

  if (isInApprovalFlowStep(item, allowedFlowstepIds)) {
    if (isEmpty(item.approvalIndication)) return true;
    if (itemUserIndications.length === 0 && !isEmpty(item.approvalIndication)) return false;

    return isItemWaitingForUserApprovalIndications(item);
  }

  if (areSeparationsInApprovalFlowStep(item.separations, allowedFlowstepIds)) {
    if (isEmpty(item.approvalIndication)) return true;
    if (itemUserIndications.length === 0 && !isEmpty(item.approvalIndication)) return false;

    return item.separations.some(sep => isSeparationWaitingForUserApprovalIndications(item, sep));
  }

  return false;
};