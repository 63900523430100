export function getAllDistinctFolders(resources = [], getFolders) {

  const distinctFolders = resources.reduce((acc, res) => {
    const folders = getFolders(res);
    folders.reduce((acc2, folder) => {
      if (!acc2[folder]) {
        acc2[folder] = true;
      }

      return acc2;
    }, acc)

    return acc;
  }, {});

  return Object.keys(distinctFolders);
}
