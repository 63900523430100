import React, { useState } from 'react';
import { translate } from 'core/services/localization';
import { Dialog, DialogButtonsBar } from 'components/common/dialogs';
import TextInput from 'components/common/inputs/TextInput';
import Button from 'components/common/buttons/Button';
import Dropdown from 'components/common/dropdown/Dropdown';
import { Validator, useValidator } from 'components/private/forms';
import { INK_MODELS, OUTPUT_MODES, CIP3_MODEL } from './constants';

export const AddEditInkDialog = (
  { title, onClose, onAddEditInk, editMode, targetInk, plates }) => {

  const [inkName, setInkName] = useState(editMode ? targetInk.name : 'New Ink Settings');
  const [plate, setPlate] = useState(editMode ? targetInk.plateNwid : '');
  const [inkModel, setInkModel] = useState(editMode ? targetInk.inkModel : CIP3_MODEL);
  const [outputMode, setOutputMode] = useState(editMode ? targetInk.outputMode : 'filePerSeparation');

  const { register, validate } = useValidator();

  const platesOptions = [{ "value": '', "text": '-- Select Plate --' }, ...plates.map(plate => {
    return { "value": plate.nwid, "text": plate.name }
  })];

  const handleCreateEditInk = () => {
    if (!validate()) {
      return;
    }
    let ink = {};

    if (editMode) {
      ink = {
        ...targetInk,
        name: inkName,
        inkModel: inkModel,
        plateNwid: plate,
        outputMode: outputMode
      }
    } else {
      ink = {
        elementType: 'ink',
        name: inkName,
        inkModel: inkModel,
        plateNwid: plate,
        outputMode: outputMode
      };
    }

    onAddEditInk(ink);
    onClose();
  };

  return <Dialog
    onClose={onClose}
    title={title}
    modal={true}
    initialWidth={360}>

    <div className='crtx-form-section-container'>
      <div className='crtx-form-section'>
        <div className='crtx-form-two-columns'>

          <label>{translate('Name')}:</label>
          <TextInput
            className='crtx-form-element-full-width'
            value={inkName}
            onChange={(e, value) => setInkName(value)}
          />

          <label>{translate('Plate')}:</label>
          <Validator register={register} rules={['required']} className={'crtx-form-element-full-width-validator'}>
            <Dropdown
              className='crtx-form-element-full-width'
              options={platesOptions}
              value={plate}
              onSelect={(event, value) => setPlate(value)}
            />
          </Validator>

          <label>{translate('Ink Model')}:</label>
          <Dropdown
            className='crtx-form-element-full-width'
            options={INK_MODELS} value={inkModel}
            onSelect={(event, value) => setInkModel(value)}
          />

          {inkModel === CIP3_MODEL && <React.Fragment>
            <label>{translate('Output Mode')}:</label>
            <Dropdown
              className='crtx-form-element-full-width'
              options={OUTPUT_MODES} value={outputMode}
              onSelect={(event, value) => setOutputMode(value)}
            />
          </React.Fragment>}
        </div>
      </div>
    </div>
    <DialogButtonsBar>
      <Button className='primary' onClick={handleCreateEditInk}>
        {editMode ? translate('Save Ink Settings') : translate('Create Ink Settings')}
      </Button>
    </DialogButtonsBar>
  </Dialog>
};