/**
 * @name furniture
 * @file Defines Furniture element functionality
 *
 * @author Boris
 * @since: 2016-08-01
 */

import React from 'react';
import Fabric from 'fabric';
import sandbox from 'sandbox';
import SimpleForm from 'widgets/SimpleForm/src/index';
import { SvgIcon } from 'components/common/buttons';
import utils from '../utils/utils';
import canvasUtils from '../utils/canvasUtils';
import propertiesCommon from './propertiesCommon';
import customConditions from './customConditions';

const { Row, Label, Input, Container } = SimpleForm;
const { translate } = sandbox.localization;
const futils = SimpleForm.utils;

const BASE_PATH = 'layout.furnitures';
const TITLE = translate('Furniture');

const { X, Y, _CORNER, ROTATION, APPLY_ON } = propertiesCommon;
const DISPLAY_NAME = { ...propertiesCommon.DISPLAY_NAME, disabled: false };
const WIDTH = { ...propertiesCommon.WIDTH, hidden: true };
const HEIGHT = { ...propertiesCommon.HEIGHT, hidden: true };
const BLACK_ONLY = { ...propertiesCommon.BLACK_ONLY };
const _ALIGNMENT = { ...propertiesCommon._ALIGNMENT, defaultValue: 'center-center' };

const IMAGE_NAME = {
  key: 'imageName',
  caption: 'File name',
  type: 'string',
  disabled: true
};

const IMAGE_TYPE = {
  key: 'imageType',
  caption: '',
  type: 'string',
  hidden: true
};

const USE_IMAGE_NAME_PATTERN = {
  key: 'useImageNamePattern',
  caption: translate('Use furniture name pattern'),
  type: 'bool'
};

const IMAGE_NAME_PATTERN = {
  key: 'imageNamePattern',
  caption: translate('Furniture name pattern'),
  type: 'string'
};

const CONSTRAIN_PROPORTIONS = {
  key: 'constrainProportions',
  caption: translate('Constrain proportions'),
  type: 'bool',
  defaultValue: true
};

const _IMAGE_WIDTH = {
  key: '_imageWidth',
  caption: translate('Image width'),
  type: 'length'
};

const _IMAGE_HEIGHT = {
  key: '_imageHeight',
  caption: translate('Image height'),
  type: 'length'
};

const IMAGE_WIDTH_SCALE = {
  key: 'imageWidthScale',
  caption: translate('Image width scale'),
  type: 'percent',
  defaultValue: 1
};

const IMAGE_HEIGHT_SCALE = {
  key: 'imageHeightScale',
  caption: translate('Image height scale'),
  type: 'percent',
  defaultValue: 1
};

const _HIDE_IMAGE = {
  key: '_hideImage',
  caption: translate('Hide image (UI only)'),
  type: 'bool',
  alwaysEnabled: true
};

const ON_TOP_OF_CELLS = {
  key: 'onTopOfCells',
  caption: translate('On top of pages'),
  type: 'bool',
  defaultValue: true
};

const OPAQUE = {
  key: 'opaque',
  caption: translate('Opaque (TIFF only)'),
  type: 'bool',
  defaultValue: false
};

const OVER_WHITE_AREA = {
  key: 'overWhiteArea',
  caption: translate('Over white area (PDF only)'),
  type: 'bool',
  defaultValue: false
};

const FURNITURE_META = [
  DISPLAY_NAME,
  IMAGE_NAME,
  IMAGE_TYPE,
  USE_IMAGE_NAME_PATTERN,
  IMAGE_NAME_PATTERN,
  X,
  Y,
  WIDTH,
  HEIGHT,
  CONSTRAIN_PROPORTIONS,
  IMAGE_WIDTH_SCALE,
  IMAGE_HEIGHT_SCALE,
  _IMAGE_WIDTH,
  _IMAGE_HEIGHT,
  _CORNER,
  _ALIGNMENT,
  ROTATION,
  APPLY_ON,
  ON_TOP_OF_CELLS,
  OPAQUE,
  OVER_WHITE_AREA,
  _HIDE_IMAGE,
  BLACK_ONLY,
];

export default (editor) => {

  const canvas = editor.getMainCanvas();
  const cutils = canvasUtils(editor);

  const isPdf = (element) => {
    return element.imageType === 'pdf' || element.imageType === 'both';
  };

  const getMeta = (state, element) => {
    IMAGE_NAME_PATTERN.disabled = !element.useImageNamePattern;
    BLACK_ONLY.disabled = isPdf(element);
    ON_TOP_OF_CELLS.disabled = element.applyOn !== 'process';
    OPAQUE.disabled = !element.onTopOfCells;

    return FURNITURE_META;
  };

  const buildDefaultElement = (state, elementType, optionId) => {
    var element = {
      elementType: elementType,
      locked: false,
      _selectable: true
    };

    setDefaultElementValues(state, element, '', optionId);

    return element;
  };

  const setDefaultElementValues = (state, element, elementPath, imageName) => {
    if (imageName) {
      element.name = imageName;
    }

    if (typeof element.onTopOfCells === 'undefined') {
      element.onTopOfCells = (state.layoutType === 'layout' && !element.locked) || element.applyOn !== 'process';
    }

    propertiesCommon.setDefaultValues(state, element, getMeta(state, element));

    element.conditions = utils.fromPseudoArrayDeep(element.conditions) || [];

    imageName = imageName || element.imageName;
    var furniture = utils.findFurnitureByImageName(editor.furnitures, imageName);
    if (furniture) {
      element.imageName = furniture.name;
      element._imageTimestamp = furniture.timestamp;
      element.imageType = furniture.type || element.imageType;
      element.width = furniture.width || element.width;
      element.height = furniture.height || element.height;
    }

    element.imageNamePattern = element.imageNamePattern || imageName;

    element._imageWidth = element.width * element.imageWidthScale;
    element._imageHeight = element.height * element.imageHeightScale;
  };

  const updateFurnitureImageScale = (state, element, key) => {
    var imageWidthScale = element.imageWidthScale;
    var imageHeightScale = element.imageHeightScale;
    var _imageWidth = element._imageWidth;
    var _imageHeight = element._imageHeight;

    switch (key) {
      case 'imageWidthScale':
        _imageWidth = element.width * element.imageWidthScale;
        break;
      case 'imageHeightScale':
        _imageHeight = element.height * element.imageHeightScale;
        break;
      case '_imageWidth':
        imageWidthScale = element._imageWidth / element.width;
        break;
      case '_imageHeight':
        imageHeightScale = element._imageHeight / element.height;
        break;
    }

    if (element.constrainProportions) {
      if (key === 'imageWidthScale' || key === '_imageWidth') {
        imageHeightScale = imageWidthScale;
        _imageHeight = element.height * imageHeightScale;
      } else if (key === 'imageHeightScale' || key === '_imageHeight') {
        imageWidthScale = imageHeightScale;
        _imageWidth = element.width * imageWidthScale;
      } else if (key === 'constrainProportions') {
        var minScale = Math.min(imageWidthScale, imageHeightScale);
        imageWidthScale = minScale;
        imageHeightScale = minScale;
        _imageWidth = element.width * imageWidthScale;
        _imageHeight = element.height * imageHeightScale;
      }
    }

    var newElement = element;
    if (imageWidthScale !== element.imageWidthScale || imageHeightScale !== element.imageHeightScale
      || _imageWidth !== element._imageWidth || _imageHeight !== element._imageHeight) {
      newElement = { ...element, imageWidthScale, imageHeightScale, _imageWidth, _imageHeight };
    }

    return newElement;
  };

  const updateProperty = (state, element, elementPath, propertyPath, propertyValue) => {
    let newState = state;
    let newElement = element;

    newState = propertiesCommon.updateProperty(newState, newElement, elementPath, propertyPath, propertyValue);
    newElement = futils.get(newState, elementPath);

    const key = futils.pathTail(propertyPath);
    let updatedElement = updateFurnitureImageScale(newState, newElement, key);
    if (key === 'applyOn') {
      if (!updatedElement.onTopOfCells && updatedElement.applyOn !== 'process') {
        updatedElement = { ...updatedElement, onTopOfCells: true };
      }
    }

    if (updatedElement !== newElement) {
      newElement = updatedElement;
      newState = futils.update(newState, elementPath, newElement);
    }

    updateShape(newState, newElement);

    if (element.onTopOfCells !== newElement.onTopOfCells) {
      newState.repopulate = true;
    }

    return newState;
  };

  const shapeTransforming = (state, element) => {
    var image = element.shape.furnitureImage;
    var scaled = utils.isScaledShape(image);
    utils.applyConstraints(image, state.plateRectangle);
    var r = utils.toSystemRectangle(state.plateRectangle, image.left, image.top, image.width, image.height,
      element.referenceX, element.referenceY);
    var newElement = { ...element, x: r.x, y: r.y };
    if (scaled) {
      newElement._imageWidth = r.width;
      newElement._imageHeight = r.height;
      var key = Math.abs(r.width - element._imageWidth) >= Math.abs(r.height - element._imageHeight) ? '_imageWidth' : '_imageHeight';
      newElement = updateFurnitureImageScale(state, newElement, key);
    }

    updateShape(state, newElement);

    return newElement;
  };

  const createFurnitureImage = (element) => {
    const selectable = element._selectable;
    const img = new Image();
    img.src = '';
    const imgOptions = {
      centeredRotation: false,
      hasRotatingPoint: false,
      selectable: selectable,
      evented: selectable,
      hasControls: false,
      lockScalingX: true,
      lockScalingY: true,
      visible: false,
    };

    return new Fabric.Image(img, imgOptions);
  };

  const updateFurnitureImage = (state, element) => {
    const locked = element.locked || !element._selectable;
    const image = element.shape.furnitureImage;
    const r = utils.toCanvasRectangle(state.plateRectangle, element.x, element.y, element.width, element.height,
      element.referenceX, element.referenceY);
    const align = utils.calcAlignmentBeforeRotation(element.alignmentX, element.alignmentY, element.rotation);

    image.set({
      left: r.left,
      top: r.top,
      width: r.width * element.imageWidthScale,
      height: r.height * element.imageHeightScale,
      originX: align.alignmentX,
      originY: align.alignmentY,
      angle: element.rotation,
      lockUniScaling: element.constrainProportions,
      visible: !element._hideImage,
      lockMovementX: locked,
      lockMovementY: locked,
      hoverCursor: locked ? 'default' : 'move'
    });

    image.setCoords();
  };

  const loadFurnitureImage = (state, element) => {
    //console.log("--- loadFurnitureImage() ---");
    var image = element.shape.furnitureImage;
    image.visible = false;
    var imageUrl = utils.getFurnitureImageUrl(editor.getFolderNwid(), element.imageName, element._imageTimestamp);
    image.setSrc(imageUrl, () => {
      //console.log("loadFurnitureImage() image.width=", image.width);
      //if (image.width <= 0 || image.height <= 0) {
      //  return;
      //}

      updateFurnitureImage(state, element);

      canvas.renderAll();
    });
  };

  const createShape = (state, element, elementPath) => {
    var furnitureImage = createFurnitureImage(element);

    var shape = {
      elementPath,
      furnitureImage
    };

    furnitureImage.shape = shape;
    element.shape = shape;

    //updateShape(state, element);
    addShape(state, element);
    loadFurnitureImage(state, element);

    return shape;
  };

  const addShape = (state, element) => {
    if (!element || !element.shape) {
      return;
    }

    canvas.add(element.shape.furnitureImage);
  };

  const removeShape = (state, element) => {
    if (!element || !element.shape) {
      return;
    }

    var shape = element.shape;
    canvas.remove(shape.furnitureImage);
  };


  const updateShape = (state, element) => {
    updateFurnitureImage(state, element);
  };

  const activateShape = (state, element) => {
    cutils.setActiveObject(element.shape.furnitureImage);
  };

  const nudgeShape = (state, element, direction) => {
    var newElement = element;
    if (!element.locked) {
      newElement = propertiesCommon.nudgeElement(state, element, direction);
      updateShape(state, newElement);
    }

    return newElement;
  };

  const renderImageNamePattern = (store, element, elementPath, meta) => {
    const disabled = propertiesCommon.isPropertyDisabled(element, meta);
    const path = futils.compose(elementPath, meta.key);
    const icons = utils.getFurnitureIcons(element.imageType);

    return (
      <Row key={meta.key}>
        <Label col={propertiesCommon.CAPTION_COLUMNS}>{meta.caption}</Label>
        <Container col={propertiesCommon.TEXTBOX_COLUMNS} direction='row'>
          <Container>
            <Input disabled={disabled} bind={path} title={element[meta.key]} />
          </Container>
          {icons.map((icon, index) =>
            <SvgIcon key={index} name={icon.icon} tooltip={icon.title} className={icon.iconClassName} />)}
        </Container>
      </Row>
    );
  };

  const renderProperty = (store, element, elementPath, meta) => {
    switch (meta.key) {
      case 'imageNamePattern':
        return renderImageNamePattern(store, element, elementPath, meta);
      default:
        return propertiesCommon.renderProperty(store, element, elementPath, meta);
    }
  };

  const renderProperties = (store, element, elementPath) => {
    const state = store.getState();
    const meta = getMeta(state, element);

    return (
      <div>
        {meta.map((meta, index) => renderProperty(store, element, elementPath, meta))}
        {customConditions.render(store, element, elementPath)}
      </div>
    );
  };

  const getInfo = (state) => {
    return {
      basePath: BASE_PATH,
      title: TITLE
    };
  };

  const getAlignmentInfo = (state, element) => {
    return utils.getAlignmentInfo(element.shape.furnitureImage);
  };

  return {
    buildDefaultElement,
    setDefaultElementValues,
    updateProperty,
    shapeTransforming,
    createShape,
    updateShape,
    addShape,
    removeShape,
    activateShape,
    nudgeShape,
    renderProperties,
    getInfo,
    getAlignmentInfo
  };

};
