import React, { Component } from 'react';
import PropTypes from 'prop-types';

const isUndefined = o => typeof o === 'undefined';
const isNull = o => o === null;

export default class AccordionItem extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: true };
  }
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.object
  }

  static defaultProps = {
    title: 'TITLE'
  };

  onClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { className, title, children } = this.props;
    const isActive = this.state.isOpen ? 'crtx-accordion-item active ' + className : 'crtx-accordion-item ' + className;

    return <div ref='accordionItem' className={isActive}>
      <button className="title" onClick={this.onClick}>
        {title}
      </button>
      <div className="panel">{children}</div>
    </div>;
  }
};