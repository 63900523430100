import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';

export default class extends Component {
  static propTypes = {
    columnData: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      color: PropTypes.string
    })),
    icon: PropTypes.string
  };

  static defaultProps = {
    columnData: [],
    icon: sandbox.icons.getTemplateIcon('page', 'tiny', 'separation'),
  };

  handleClick = (ev) => {
    var { rowIndex, columnKey, onClick } = this.props;

    if (typeof onClick === 'function') {
      onClick(rowIndex, columnKey, ev);
    }
  };

  shouldComponentUpdate(nextProps) {
    var { props } = this;
    return nextProps.rowIndex !== props.rowIndex || nextProps.columnData !== props.columnData || nextProps.icon !== props.icon;
  }

  renderSeparation = ({ name, color }) => {
    const { icon } = this.props;
    const style = {
      backgroundColor: color,
      verticalAlign: 'middle',
      marginRight: 3
    };

    return <img src={icon} title={name} style={style} />;
  };

  render() {
    const { columnData } = this.props;

    return <div className="cell" >
      <div className="cell-content">
        <div className="cell-content-padding">
          {columnData.map(this.renderSeparation)}
        </div>
      </div>
    </div>;
  }
}