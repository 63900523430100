import React from 'react';
import PropTypes from 'prop-types';
import { toLocaleShortDateTime, toLocaleWeekday, toLocaleShortTime } from 'core/services/localization';
import { getFolderCurrentDate, isSameDay, MSEC_IN_MINUTE } from 'core/dates';
import { classNames } from 'utilities/classNames';
import { getHoldIconName, getHoldIconTooltip } from 'utilities/hold';
import IconButton from 'components/common/buttons/IconButton';

function DeadlineBar(
  {
    deadlineDate,
    deadlinePassed = false,
    holdType = 'none',
    holdUntilDate,
    releaseOffset = 0,
    onChangeDeadline,
  }) {

  const getLocaleShortHoldUntilDate = () => {
    if (!holdUntilDate) {
      return;
    }

    return isSameDay(holdUntilDate, getFolderCurrentDate()) ?
      toLocaleShortTime(holdUntilDate) : toLocaleWeekday(holdUntilDate);
  };

  const getReleaseOffsetDate = () => {
    if (!deadlineDate || !releaseOffset) {
      return;
    }

    return new Date(deadlineDate - Number(releaseOffset) * MSEC_IN_MINUTE);
  };

  const localeDeadlineDate = toLocaleShortDateTime(deadlineDate);
  const localeHoldUntilDate = toLocaleShortDateTime(holdUntilDate);
  const localeShortHoldUntilDate = getLocaleShortHoldUntilDate();
  const localeReleaseOffsetDate = toLocaleShortDateTime(getReleaseOffsetDate());

  return (
    <div className='crtx-deadline-bar'>
      <div className='deadline-container'>
        {deadlineDate && <div
          key='deadline-date'
          onClick={onChangeDeadline}
          className={classNames('deadline-date', { 'deadline-passed': deadlinePassed })}>
          {localeDeadlineDate}
        </div>}

        {!deadlineDate && <IconButton
          key='deadline-icon'
          className={classNames('deadline-icon')}
          iconName='notifications_none'
          onClick={onChangeDeadline}
        />}

        {deadlineDate && deadlinePassed && <IconButton
          key='deadline-passed-icon'
          className='deadline-icon deadline-passed'
          iconName='notifications_active'
          onClick={onChangeDeadline}
        />}
      </div>

      <div className='hold-container'>
        {holdType !== 'none' && <React.Fragment>
          <IconButton
            key='hold-icon'
            className='hold-icon crtx-size-12'
            iconName={getHoldIconName(holdType)}
            tooltip={getHoldIconTooltip(holdType)}
          />

          {holdUntilDate ?
            <div key='hold-until-date' className='hold-until-date'>
              <span title={localeHoldUntilDate}>{localeShortHoldUntilDate}</span>
            </div>
            :
            releaseOffset ?
              <div key='release-offset' className='release-offset'>
                <span title={localeReleaseOffsetDate}>{releaseOffset}</span>
                <span className='minutes-label'>{"′"}</span>
              </div> : undefined}
        </React.Fragment>}
      </div>
    </div>
  );
}


DeadlineBar.propTypes = {
  deadlineDate: PropTypes.instanceOf(Date),
  deadlinePassed: PropTypes.bool,
  holdType: PropTypes.string,
  holdUntilDate: PropTypes.instanceOf(Date),
  releaseOffset: PropTypes.number,
  onChangeDeadline: PropTypes.func
};

export default DeadlineBar;