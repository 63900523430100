import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class extends Component {
  static propTypes = {
    approvers: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.string
    })).isRequired,
    iconClassName: PropTypes.string,
    iconStyle: PropTypes.object
  };

  static defaultProps = {
    title: '',
  };

  handleClick = (ev) => {
    var { rowIndex, columnKey, onClick } = this.props;

    if (typeof onClick === 'function') {
      onClick(rowIndex, columnKey, ev);
    }
  };

  isApproversDifferent = (approvers, nextApprovers) => {
    if (approvers.length !== nextApprovers.length) return true;

    return nextApprovers.some((approver, index) => {
      return approver.name !==  approvers[index].name || approver.icon !== approvers[index].icon;
    });
  };

  shouldComponentUpdate(nextProps) {
    var { props } = this;
    return nextProps.rowIndex !== props.rowIndex || this.isApproversDifferent(props.approvers, nextProps.approvers);
  }

  renderApprover = (iconClassName, iconStyle) => approver => {
    return <span key={approver.name}>
      <label>{approver.name}</label>
      <img src={approver.icon}
        title={approver.name}
        className={['disable-text-select', iconClassName].join(' ')}
        style={{marginLeft: 3, marginRight: 10, ...iconStyle}} />
    </span>;
  };

  renderApprovers = (approvers, iconClassName, iconStyle) => {
    return approvers.map(this.renderApprover(iconClassName, iconStyle));
  };

  render() {
    const { approvers, iconClassName, iconStyle } = this.props;
    const cellStyle = {
      cursor: typeof onClick === 'function' ? 'pointer' : null
    };

    return <div className="cell" style={cellStyle} >
      <div className="cell-content">
        <div className="cell-content-padding">
          {this.renderApprovers(approvers, iconClassName, iconStyle)}
        </div>
      </div>
    </div>;
  }
}