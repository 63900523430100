/**
 * @file PopoverContent - container for generic floating controls
 *
 * Note: The '.crtx-popover' CSS marker class should be used for all floating controls
 * to ensure proper z-order
 *
 * @author Boris
 * @created 2023-02-20
 */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  useMergeRefs,
  FloatingPortal,
  FloatingFocusManager
} from '@floating-ui/react';
import { useFloatingZIndex } from 'utilities/zindex';
import { classNames } from 'utilities/classNames';
import { usePopoverContext } from './PopoverContext';
import { getFloatingPortalNode } from '../utils';

export const PopoverContent = forwardRef(
  function PopoverContent({ children, className, style, ...props }, propRef) {
    const { context: floatingContext, ...context } = usePopoverContext();
    const setRef = useMergeRefs([context.refs.setFloating, propRef]);

    const portalNode = getFloatingPortalNode(floatingContext);

    const zIndex = useFloatingZIndex(portalNode, context.open);

    return (
      <FloatingPortal root={portalNode}>
        {context.open && (
          <FloatingFocusManager context={floatingContext} modal={context.modal} initialFocus={context.initialFocus}>
            <div
              ref={setRef}
              className={classNames('crtx-popover', className)}
              style={{
                position: context.strategy,
                top: context.y ?? 0,
                left: context.x ?? 0,
                zIndex: zIndex,
                ...style
              }}
              aria-labelledby={context.labelId}
              aria-describedby={context.descriptionId}
              {...context.getFloatingProps(props)}
            >
              {children}
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    );
  });

PopoverContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
};
