import { startInternalModule } from 'core/managers/module';
import sandbox, { localization } from 'sandbox';
import toastService from 'core/services/toastService';

module.exports = function (action, selected) {
  const moduleName = action.parameters.moduleName;
  const selObj = Array.isArray(selected) ? selected[0] : selected;
  const rootId = selObj.rootId || selObj.nwid;
  const rootType = selObj.type;
  const rootLabel = selObj.label;
  const name = localization.translate(action.parameters.title);
  const target = 'dialog';
  const windowWidth = action.parameters.windowWidth;
  const windowHeight = action.parameters.windowHeight;
  const win = action.module.win;
  const modal = action.parameters.modal || false;

  action.selected = selected;
  if (sandbox.jsUtils.isFunction(action.dialogAllowedMessage)) {
    action.dialogAllowedMessage(action.nwid, selObj)
      .done((res) => {
        if (typeof res === 'undefined' || res === '') {
          startInternalModule(moduleName, {
            rootId,
            rootType,
            rootLabel,
            name,
            target,
            windowWidth,
            windowHeight,
            win,
            modal
          }, action);
        } else {
          toastService.createToast('top-right', res, res, 'error', undefined, 5000);
        }
      })
      .fail(res => {
        toastService.createToast('top-right', res, res, 'error', undefined, 5000);
      });
  } else {
    startInternalModule(moduleName, {
      rootId,
      rootType,
      rootLabel,
      name,
      target,
      windowWidth,
      windowHeight,
      win,
      modal
    }, action);
  }
};
