export function webAppPath() {
  return '/' + window.location.pathname.split('/')[1];
}

export function combineUrl() {
  const parts = [];

  for (let i = 0; i < arguments.length; i++) {
    let part = arguments[i];
    if (typeof part !== 'undefined' && part !== null && part !== '') {
      part = part + '';
      if (i > 0) {
        // remove the starting slashes for each part but the first
        part = part.replace(/^[\\/]+/, '');
      }

      if (i < arguments.length - 1) {
        // remove the ending slashes for each part but the last
        part = part.replace(/[\\/]+$/, '');
      } else {
        // combine multiple slashes to a single one for the last part
        part = part.replace(/[\\/]+$/, '/');
      }

      parts.push(part);
    }
  }

  return parts.join('/');
}

export function coretexPath(...args) {
  return combineUrl(webAppPath(), ...args);
}

export function buildQueryString(parameters = {}, uriEncoded = true) {
  const urlParams = Object.entries(parameters).map(entry => {
    let [key, value] = entry;
    if (uriEncoded) {
      key = encodeURIComponent(key);
      value = encodeURIComponent(value);
    }

    return `${key}=${value}`;
  });

  return urlParams.join('&');
}

export function appendParameters(url = '', parameters = {}, uriEncoded = false) {
  const urlParams = buildQueryString(parameters, uriEncoded);
  if (!urlParams) {
    return url;
  }

  let delim = '';
  if (!url.includes('?')) {
    delim = '?';
  } else if (!url.endsWith('?') && !url.endsWith('&')) {
    delim = '&';
  }

  return url + delim + urlParams;
}

export function parseUrlParams(url = '') {
  const entries = new URL(url).searchParams.entries();

  const params = {};
  for (const [key, value] of entries) {
    params[key] = decodeURIComponent(value);
  }

  return params;
}

export function stripUrlParams(url = '') {
  const idx = url.indexOf('?');

  return idx >= 0 ? url.substring(0, idx) : url;
}
