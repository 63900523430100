import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tree from 'components/common/tree/Tree';
import LayoutsTreeNode from './LayoutsTreeNode';
import { translate } from 'core/services/localization';
import request from 'core/managers/request';

import { AddEditLayoutDialog } from './AddEditLayoutDialog';
import { AddEditLayoutGroupDialog } from './AddEditLayoutGroupDialog';
import { Toolbar } from './Toolbar';
import dialogService from 'core/services/dialogService';

const labels = {
  add: translate('Add'),
  edit: translate('Edit'),
  copy: translate('Copy'),
  paste: translate('Paste'),
  duplicate: translate('Duplicate'),
  delete: translate('Delete'),
  deleteLayout: translate('Are you sure you want to delete this Layout?'),
  deleteLayoutTitle: translate('Delete Layout'),
  deleteLayoutGroup: translate('Are you sure you want to delete this Layout Group?'),
  deleteLayoutGroupTitle: translate('Delete Layout Group'),
};

function LayoutsTab({
  model,
  module,
  onSelect,
  selectedItem,
  buttonTitle,
  onAddOrDuplicateItem,
  onDeleteItem,
  onEditItem
}) {


  const [visibleAddEditLayoutDialog, setVisibleAddEditLayoutDialog] = useState(false);
  const [visibleAddEditLayoutGroupDialog, setVisibleAddEditLayoutGroupDialog] = useState(false);
  const [targetLayoutGroup, setTargetLayoutGroup] = useState({});
  const [targetLayout, setTargetLayout] = useState({});
  const [editGroupMode, setEditGroupMode] = useState(false);
  const [editLayoutMode, setEditLayoutMode] = useState(false);
  const [expandedGroupsMap, setExpandedGroupsMap] = useState({});
  const [clippedItem, setClippedItem] = useState();

  const findLayoutGroup = (layoutNwid) => {
    const layoutGroup = model.layoutGroups.find(group => group.layoutLinks.find(link => link.nwid === layoutNwid));

    return layoutGroup;
  };

  const layoutDisplayName = (node) => {
    return node.role ? (node.name.toLowerCase() === node.role ? node.name : node.name + ' (' + node.role + ')') : node.name;
  };

  const layoutGroupDisplayName = (node) => {
    return node.name + ' (' + node.impositionType + ')';
  };

  const handleAddLayoutClick = (e, node, index) => {
    setVisibleAddEditLayoutDialog(true);
    setEditLayoutMode(false);
    setTargetLayoutGroup(node);
    onSelect(node);
  };

  const handleEditLayoutGroupClick = (e, node) => {
    setVisibleAddEditLayoutGroupDialog(true);
    setTargetLayoutGroup(node);
    setEditGroupMode(true);
  };

  const handleEditLayoutClick = (e, node) => {
    setTargetLayout(node);
    setVisibleAddEditLayoutDialog(true);
    setEditLayoutMode(true);

    const layoutGroup = findLayoutGroup(node.nwid);

    setTargetLayoutGroup(layoutGroup);
  };

  const handleAddEditLayout = (layout) => {
    let jsonLayout = JSON.stringify(layout);
    if (!editLayoutMode) {
      request.createLayout(module.viewSettings.rootId, jsonLayout, targetLayoutGroup.nwid, createDuplicateLayoutResult);
    } else {
      request.saveLayoutLink(module.viewSettings.rootId, jsonLayout, targetLayoutGroup.nwid, saveLayoutLinkResult);
    }
  };

  const handleAddEditLayoutGroup = (group) => {
    let jsonLayoutGroup = JSON.stringify(group);

    if (!editGroupMode) {
      request.createLayoutGroup(module.viewSettings.rootId, jsonLayoutGroup, createDuplicateLayoutGroupResult);
    } else {
      request.saveLayoutGroup(module.viewSettings.rootId, jsonLayoutGroup, editLayoutGroupResult);
    }
  };

  const handleDuplicateLayoutGroupClick = (e, node) => {
    setTargetLayoutGroup(node);

    request.duplicateLayoutGroup(module.viewSettings.rootId, node.nwid, createDuplicateLayoutGroupResult);
  };

  const handleDuplicateLayoutClick = (e, node) => {
    const layoutGroup = findLayoutGroup(node.nwid);
    setTargetLayoutGroup(layoutGroup);

    request.duplicateLayout(module.viewSettings.rootId, node.nwid, layoutGroup.nwid, createDuplicateLayoutResult);
  };

  const handleCopyLayoutClick = (e, node) => {
    setClippedItem(node);
  };

  const handlePasteLayoutClick = (e, layoutGroup) => {
    if (!clippedItem) {
      return;
    }

    request.duplicateLayout(module.viewSettings.rootId, clippedItem.nwid, layoutGroup.nwid, createDuplicateLayoutResult);
  };

  const handleDeleteLayoutGroupClick = (e, node) => {


    let msg = labels.deleteLayoutGroup + "<br>" + layoutGroupDisplayName(node);

    dialogService.openConfirmDialog(msg, labels.deleteLayoutGroupTitle)
      .then(() => {
        request.deleteLayoutGroup(module.viewSettings.rootId, node.nwid, deleteLayoutGroupResult);
      });

  };

  const handleDeleteLayoutClick = (e, node) => {
    const layoutGroup = findLayoutGroup(node.nwid);

    let msg = labels.deleteLayout + "<br>" + layoutDisplayName(node);

    dialogService.openConfirmDialog(msg, labels.deleteLayoutTitle)
      .then(() => {
        request.deleteLayout(module.viewSettings.rootId, node.nwid, layoutGroup.nwid, deleteLayoutResult);
      });

  };

  const createDuplicateLayoutGroupResult = (group, params) => {
    if (!group || !params) {
      return console.error("*** ERROR: cannot duplicate Layout Group");
    }
    group.layoutLinks = group.layoutLinks || [];
    onAddOrDuplicateItem(group);
    onSelect(group);
  };

  const editLayoutGroupResult = (layoutGroup, params) => {
    if (!layoutGroup || !params) {
      return console.error("*** ERROR: cannot edit Layout Group");
    }

    const layoutGroupIndex = model.layoutGroups.findIndex(group => group.nwid === layoutGroup.nwid);

    onEditItem(layoutGroup, layoutGroupIndex);
    onSelect(layoutGroup);

  };

  const createDuplicateLayoutResult = (layout, params) => {

    if (!layout || !params) {
      return console.error("*** ERROR: cannot duplicate layout");
    }

    let layoutGroup = model.layoutGroups.find(group => group.nwid === params.layoutGroupNwid);

    onAddOrDuplicateItem(layout, layoutGroup);
    expandGroup(layoutGroup, true);
    onSelect(layout);
  };

  const saveLayoutLinkResult = (layout, params) => {
    if (!layout || !layout.nwid || !params) {
      return;
    }
    const layoutGroup = model.layoutGroups.find(group => group.nwid === params.layoutGroupNwid);
    const layoutIndex = layoutGroup.layoutLinks.findIndex(link => link.nwid === layout.nwid);

    onEditItem(layout, layoutIndex, layoutGroup);
    expandGroup(layoutGroup, true);
    onSelect(layout);
  };

  const deleteLayoutGroupResult = (data, params) => {

    if (!data || !params) {
      return;
    }

    let groupIndex = model.layoutGroups.findIndex((group) => group.nwid === params.nwid);

    onDeleteItem({ elementType: 'layoutGroup' }, groupIndex);
    onSelect(model.layoutGroups[groupIndex - 1] ? model.layoutGroups[groupIndex - 1] : model.layoutGroups[0]);

    if (clippedItem) {
      const layoutGroup = findLayoutGroup(clippedItem.nwid);
      if (!layoutGroup) {
        setClippedItem(null);
      }
    }
  };

  const deleteLayoutResult = (layout, params) => {

    if (!layout || !params) {
      return console.error("*** ERROR: cannot delete layout");
    }

    let layoutGroup = model.layoutGroups.find(group => group.nwid === params.layoutGroupNwid);
    const layoutIndex = layoutGroup.layoutLinks.findIndex(link => link.nwid === params.nwid);
    onDeleteItem({ elementType: 'layout' }, layoutIndex, layoutGroup);
    onSelect(layoutGroup.layoutLinks[layoutIndex - 1 >= 0 ? layoutIndex - 1 : 0]);

    if (clippedItem?.nwid === params.nwid) {
      setClippedItem(null);
    }
  };

  const getButtons = (node) => {
    const layoutGroupButtons = [
      {
        id: 'add',
        title: labels.add,
        onClick: handleAddLayoutClick,
      },
      {
        id: 'edit',
        title: labels.edit,
        onClick: handleEditLayoutGroupClick,
      },
      {
        id: 'content_duplicate',
        title: labels.duplicate,
        onClick: handleDuplicateLayoutGroupClick,
      },
      {
        id: 'content_paste',
        title: labels.paste,
        disabled: !clippedItem,
        onClick: handlePasteLayoutClick,
      },
      {
        id: 'delete_outline',
        title: labels.delete,
        onClick: handleDeleteLayoutGroupClick,
      },
    ];

    const layoutButtons = [
      {
        id: 'edit',
        title: labels.edit,
        onClick: handleEditLayoutClick,
      },
      {
        id: 'content_duplicate',
        title: labels.duplicate,
        onClick: handleDuplicateLayoutClick,
      },
      {
        id: 'content_copy',
        title: labels.copy,
        onClick: handleCopyLayoutClick,
      },
      {
        id: 'delete_outline',
        title: labels.delete,
        onClick: handleDeleteLayoutClick,
      },
    ];

    return node.elementType === 'layout' ? layoutButtons : layoutGroupButtons;
  };

  const treeNodeGetter = (node, nodePath, nodeProps) => {

    return <LayoutsTreeNode
      key={node.nwid}
      node={node}
      nodeProps={nodeProps}
      trailingIcons={getButtons(node)}
    />;
  };

  const handleExpanderClick = (event, node, nodePath, expanded) => {

    expandGroup(node, expanded);
  };

  const expandGroup = (group, expanded) => {

    let updatedMap = { ...expandedGroupsMap };

    updatedMap[group.nwid] = typeof expanded !== 'undefined' ? expanded : !expandedGroupsMap[group.nwid];
    setExpandedGroupsMap(updatedMap);
    onSelect(group);
  };

  const handleKeyDown = (e) => {
    if ((e.ctrlKey || e.metaKey) && e.code === 'KeyC') {
      if (selectedItem?.elementType === 'layout') {
        setClippedItem(selectedItem);
      }
    } else if ((e.ctrlKey || e.metaKey) && e.code === 'KeyV') {
      if (selectedItem?.elementType === 'layoutGroup' && clippedItem) {
        request.duplicateLayout(module.viewSettings.rootId, clippedItem.nwid, selectedItem.nwid, createDuplicateLayoutResult);
      }
    }
  };

  const handleNodeClick = (event, node, nodePath, expanded) => {
    if (node.elementType === 'layoutGroup') {
      handleExpanderClick(event, node, nodePath, expanded);
    } else {
      onSelect(node);
    }
  };

  const handleCloseAddEditLayoutDialog = () => {
    setVisibleAddEditLayoutDialog(false);
  };

  const handleCloseAddEditLayoutGroupDialog = () => {
    setVisibleAddEditLayoutGroupDialog(false);
  };

  const handleCreateNewLayoutGroup = () => {
    setEditGroupMode(false);
    setVisibleAddEditLayoutGroupDialog(true);
  };

  const nodePropsGetter = (node) => {
    if (node.elementType === 'layoutGroup') {
      return {
        id: node.nwid,
        expanded: expandedGroupsMap[node.nwid],
        text: layoutGroupDisplayName(node),
        children: node.layoutLinks,
        selected: node.nwid === selectedItem?.nwid,
        elementType: 'layoutGroup'
      };
    } else if (node.elementType === 'layout') {
      return {
        id: node.nwid,
        text: layoutDisplayName(node),
        selected: node.nwid === selectedItem?.nwid,
        elementType: 'layout'
      }
    }
  };

  return (<div className='crtx-form-section-container crtx-layout-manager-list-side'>
    <Toolbar title={buttonTitle} onClick={handleCreateNewLayoutGroup} />
    <div className='crtx-layout-manager-list-container' tabIndex={-1} onKeyDown={handleKeyDown}>
      <div className='crtx-layout-manager-list'>
        <div>
          <Tree
            className="crtx-layouts-tree-container"
            nodes={model.layoutGroups}
            treeNodeGetter={treeNodeGetter}
            onExpanderClick={handleExpanderClick}
            onNodeClick={handleNodeClick}
            nodePropsGetter={nodePropsGetter}
          />
          {visibleAddEditLayoutDialog && <AddEditLayoutDialog
            title={!editLayoutMode ? translate('Add New Layout') : translate('Edit Layout')}
            targetLayoutGroup={targetLayoutGroup}
            onClose={handleCloseAddEditLayoutDialog}
            onAddEditLayout={handleAddEditLayout}
            plates={model.plates}
            targetLayout={targetLayout}
            editMode={editLayoutMode}
          />}
          {visibleAddEditLayoutGroupDialog && <AddEditLayoutGroupDialog
            title={!editGroupMode ? translate('New Layout Group') : translate('Edit Layout Group')}
            targetLayoutGroup={editGroupMode ? targetLayoutGroup : {}}
            onClose={handleCloseAddEditLayoutGroupDialog}
            onAddEditLayoutGroup={handleAddEditLayoutGroup}
            editMode={editGroupMode}
          />}</div>
      </div>
    </div>

  </div>);
}

LayoutsTab.propTypes = {
  model: PropTypes.any,
  module: PropTypes.any,
  onSelect: PropTypes.func,
  selectedItem: PropTypes.any,
  buttonTitle: PropTypes.string,
  onAddOrDuplicateItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  onEditItem: PropTypes.func,

};

export default LayoutsTab;