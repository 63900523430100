import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {translate} from 'core/services/localization';
import LoginUserName from './LoginUserName';

export default class extends Component {

  static propTypes = {
    store: PropTypes.any
  };

  render() {

    return (<React.Fragment>
      <label className="login-label">{translate('User Name')}</label>
      <LoginUserName store={this.props.store} />
    </React.Fragment>
    );
  }
}

