import React from 'react';
import PropTypes from 'prop-types';
import Table, { Column, headers, filters } from 'widgets/ReactDataGrid';
import { FILTER_TYPE, ROW_HEIGHT } from 'widgets/ReactDataGrid/utils';

const { GenericHeader, HeaderCaption } = headers;
const { TextInputFilter } = filters;

const TABLE_WIDTH = 1600;

const MIN_COLUMN_WIDTH = 20;

export default function View({ module, viewModel, namingPatternsTableColumns }) {

  const renderNamingPatternColumnFilter = column => {

    const { filter } = column;

    if (!filter) {
      return;
    }

    switch (filter.type) {
      case FILTER_TYPE.TEXT:
        return (
          <TextInputFilter
            value={filter.textValue}
            onChange={(e, textValue) => module.handleNamingPatternColumnFilterChange(column, { textValue })}
          />
        );
    }
  };

  const renderNamingPatternColumnHeaderFilter = column => {

    if (!module.filtersEnabled) {
      return;
    }

    return (
      <div className='column-header-filter'>
        {renderNamingPatternColumnFilter(column)}
      </div>
    );
  };

  const renderNamingPatternColumnHeaderCaption = (column, handleClick, sortDirection, index) => {
    return (
      <HeaderCaption sortDirection={sortDirection} sortOrder={index + 1} tooltip={column.caption}
        onSort={multiSort => handleClick(column.key, column.sortValueGetter, multiSort)} sortable={typeof column.sortType !== 'undefined'}
      >
        {column.caption}
      </HeaderCaption>
    );
  };

  const renderNamingPatternColumnHeader = (column, handleClick, sortDirection, index) => {
    return (
      <GenericHeader
        captionRenderer={renderNamingPatternColumnHeaderCaption(column, handleClick, sortDirection, index)}
        filterRenderer={renderNamingPatternColumnHeaderFilter(column)}
      />
    );
  };
  const renderNamingPatternTable = () => {

    const { columnsToSortBy, filtersEnabled } = module;

    const defaultWidth = TABLE_WIDTH / (namingPatternsTableColumns.length || 1);
    const headerHeight = filtersEnabled ? 61 : 31;

    return (
      <Table
        rows={viewModel.namingPatterns}
        columnKey='id'
        virtualScroll={true}
        fixed={true}
        multiSelect={true}
        minColumnWidth={MIN_COLUMN_WIDTH}
        headerHeight={headerHeight}
        rowHeight={ROW_HEIGHT}
        onColumnsFilter={(columnKey, visible, columns) => module.handleNamingPatternTableColumnsFilter(columns)}
        onColumnsOrder={(columns, oldIndex, newIndex) => module.handleNamingPatternTableColumnOrder(columns, oldIndex, newIndex)}
        onSelect={selectedRows => module.handleNamingPatternTableSelect(selectedRows)}
        onRowContextMenu={(rowIndex, rowContent, selectedRows, e) => module.handleNamingPatternTableContextMenu(rowContent, selectedRows, e)}
        onColumnResize={(columns, columnKey) => module.handleNamingPatternTableColumnResize(columns)}
        onDeleteKey={(selectedRows, e) => module.handleNamingPatternTableDeleteKey(selectedRows, e)}
        onDoubleClickRow={(rowIndex, pattern, e) => module.handleNamingPatternDoubleClick(pattern, e)}
        showFilterOptionInHeaderMenu={true}
        filtersEnabled={filtersEnabled}
        onToggleFiltersClick={(e, newValue) => module.toggleFilters(newValue)}
      >
        {namingPatternsTableColumns && namingPatternsTableColumns.map(col => {
          const index = columnsToSortBy.findIndex(column => column.key === col.key);
          const sortDirection =
            col.sortType && index !== -1
              ? columnsToSortBy[index].ascending ? 'asc' : 'desc'
              : undefined;
          const handleClick = col.sortType
            ? module.handleNamingPatternColumnClick(col.sortType)
            : undefined;
          return <Column
            key={col.key}
            title={col.caption}
            columnKey={col.key}
            width={col.width || defaultWidth}
            align={col.align}
            visible={col.visible}
            header={renderNamingPatternColumnHeader(col, handleClick, sortDirection, index)}
            cell={col.cell}
            cellDataGetter={col.cellDataGetter}
            shouldCellUpdate={col.shouldCellUpdate}
          />;
        })}
      </Table>
    );
  };

  return (
    <div className='pro-modules-naming-pattern'>
      {renderNamingPatternTable()}
    </div>
  );
}

View.propTypes = {
  module: PropTypes.object,
  viewModel: PropTypes.object,
  namingPatternsTableColumns: PropTypes.array,
};