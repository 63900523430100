import React from 'react';
import { icons } from 'sandbox';
import { fromServerDate, formatDate } from 'core/dates';
import { cells } from 'widgets/ReactDataGrid';
import { COMPARE_TYPE } from 'core/comparators';
import {
  STATUS_MAP,
  XMIT_STATUS_MAP,
  DEFAULT_STATUS_COLOR,
  ICON_NAME_BY_STATUS,
  DATE_TIME_FORMAT,
  DATE_FORMAT
} from './constants';
import { toLocaleShortDateTime } from 'core/services/localization';
import { getMainStatus } from 'utilities/statuses';


const { IconCell, Thumbnail } = cells;

const getTransmissionStatus = (page) => {
  return Object.values(page.transmissionStatuses)[0];
};

const getDefaultStatus = item => getMainStatus(item);

export const defaultCellDataGetter = (viewModel) => {
  return (rowIndex, columnKey) => {
    const page = viewModel.pages[rowIndex];

    let columnData, title, backgroundColor, textColor;
    if (viewModel.magazineMap[columnKey]) {
      const magazine = page.magazineMap[columnKey];
      backgroundColor = DEFAULT_STATUS_COLOR;
      if (magazine) {
        if (magazine.isPlanned !== false) {
          if (page.WaitingForApproval === '1' || !magazine.status && page.pageContent && page.pageContent.versionNwid !== '000') {
            backgroundColor = STATUS_MAP.readyForMail.color;
          } else {
            backgroundColor = (STATUS_MAP[magazine.status] || STATUS_MAP.planned).color;
          }
        }

        // magazine.status = 'Success'; //***TEST
        // magazine.isPlanned = false; //***TEST
        // magazine.statusTime = 'Dec-04-2019-14-04-33'; //***TEST
        if (magazine.statusTime) {
          const statusDate = fromServerDate(magazine.statusTime);
          title = toLocaleShortDateTime(statusDate);
          columnData = formatDate(statusDate, DATE_FORMAT);
          if (magazine.isPlanned === false && magazine.status === 'Error') {
            textColor = STATUS_MAP[magazine.status].color;
          } else if (backgroundColor === STATUS_MAP.masked.color) {
            textColor = 'white';
          }
        }
      }
    } else {
      if (Array.isArray(page[columnKey])) {
        columnData = page[columnKey].join(', ');
      } else {
        columnData = page[columnKey] || '';
      }
    }

    return {
      columnData,
      title,
      backgroundColor,
      textColor,
    };
  }
};

export const defaultShouldCellUpdate = (nextProps, props) => {
  return nextProps.columnData !== props.columnData || nextProps.backgroundColor !== props.backgroundColor ||
    nextProps.textColor !== props.textColor || nextProps.icon !== props.icon;
};

export const getPredefinedColumns = (viewModel, module) => {
  return [
    {
      displayName: 'Thumbnail',
      key: 'thumbnail',
      align: 'center',
      width: 86,
      cell: Thumbnail,
      compareType: COMPARE_TYPE.NUMBERS,

      valueGetter: (page) => page.pageContent && page.pageContent.versionNwid !== '000' ? 1 : 0,

      cellDataGetter: (rowIndex, columnKey) => {
        const page = viewModel.pages[rowIndex];
        const pageContent = page.pageContent || {};

        return page.pageContent.versionNwid === '000' ? {} : {
          columnData: pageContent.nwid,
          iconUID: page.pageContent.versionNwid,
          onDoubleClick: (rowIndex, columnKey, e) => module.handleAdsTableThumbnailDoubleClick(page)
        };
      },
      shouldCellUpdate: (nextProps, props) => {
        //console.log('nextProps.columnData=', nextProps.columnData);
        return nextProps.columnData !== props.columnData || nextProps.iconUID !== props.iconUID;
      }
    },
    {
      displayName: 'MNI Number',
      key: 'label',
      compareType: COMPARE_TYPE.CASE_SENSITIVE,

      cellDataGetter: (rowIndex, columnKey) => {
        const page = viewModel.pages[rowIndex];

        let backgroundColor;
        if (page.pagechanged === 'true') {
          backgroundColor = STATUS_MAP['changed'].color;
        }

        if (page.pageContent && page.pageContent.versionNwid !== '000') {
          if (page.magazines.some(mag => mag.status !== 'Success' && mag.status !== 'Error' &&
            mag.status !== 'pickup' && mag.status !== 'masked')) {
            backgroundColor = STATUS_MAP['Waiting'].color;
          }
        }

        return {
          columnData: page[columnKey],
          backgroundColor
        };
      },

    },
    {
      displayName: 'Advertiser',
      key: 'Advertiser',
    },
    {
      displayName: 'Network',
      key: 'SoldNetworkIssue',
    },
    {
      displayName: 'Markets',
      key: 'markets',
    },
    {
      displayName: 'Localization',
      key: 'Localization',
    },
    {
      displayName: 'Description',
      key: 'Description',
    },
    {
      displayName: 'Pickup',
      key: 'Pickup',
    },
    {
      displayName: 'Contract Notes',
      key: 'ContractNotes',
    },
    {
      displayName: 'Coordinator',
      key: 'Coordinator',
    },
    {
      displayName: 'Critical Color',
      key: 'CriticalColor',
    },
    {
      displayName: 'Work Order Notes',
      key: 'WorkOrderNotes',
    },
    {
      displayName: 'File Name',
      key: 'FileName',

      cellDataGetter: (rowIndex, columnKey) => {
        const page = viewModel.pages[rowIndex];
        const pageContent = page.pageContent || {};

        return {
          columnData: pageContent.mainVersionInputFileName
        };
      },
    },
    {
      displayName: 'Input Time',
      key: 'InputTime',
      width: 115,
      compareType: COMPARE_TYPE.DATES,
      valueGetter: (page) => fromServerDate((page.pageContent || {}).InputTime),

      cellDataGetter: (rowIndex, columnKey) => {
        const page = viewModel.pages[rowIndex];
        const inputTime = (page.pageContent || {}).InputTime;

        return {
          columnData: inputTime ? formatDate(fromServerDate(inputTime), DATE_TIME_FORMAT) : ''
        };
      },

    },
    {
      displayName: 'Step',
      key: 'Step',
      cell: IconCell,
      align: 'center',
      width: 49,
      compareType: COMPARE_TYPE.CASE_SENSITIVE,
      valueGetter: page => {
        const status = getDefaultStatus(page);
        return status?.flowStepType;
      },

      cellDataGetter: (rowIndex, columnKey) => {
        const page = viewModel.pages[rowIndex];
        let icon;
        const status = getDefaultStatus(page);
        if (status?.flowStepType) {
          icon = icons.getTemplateIcon(status.flowStepType, 'tiny', status.flowStepIconName);
        }

        return {
          icon,
        };
      },
    },
    {
      displayName: 'Status',
      key: 'StepStatus',
      cell: IconCell,
      align: 'center',
      width: 62,
      compareType: COMPARE_TYPE.CASE_SENSITIVE,
      valueGetter: page => {
        const status = getDefaultStatus(page);
        return status?.statusType;
      },

      cellDataGetter: (rowIndex, columnKey) => {
        const page = viewModel.pages[rowIndex];
        let icon;
        const status = getDefaultStatus(page);
        if (status?.statusType && status.statusType !== 'NotAssigned') {
          if (status.statusType === 'error' && status.flowStepType === 'workflow/step/flow/approval') {
            icon = icons.getModuleIcon('Thumbnail', 'reject-small');
          } else {
            icon = icons.getGeneralIcon('status', ICON_NAME_BY_STATUS[status.statusType]);
          }
        }

        return {
          icon,
        };
      },
    },
    {
      displayName: 'Comment',
      key: 'comment',
    },
    {
      displayName: 'Plant',
      key: 'xmitStatusTime',
      width: 115,
      compareType: COMPARE_TYPE.DATES,
      valueGetter: (page) => {
        let txStatus = page.xmitStatusTime;
        if (txStatus) {
          return fromServerDate(txStatus);
        }
        txStatus = getTransmissionStatus(page);
        return fromServerDate(txStatus && txStatus.time);
      },

      cellDataGetter: (rowIndex, columnKey) => {
        const page = viewModel.pages[rowIndex];
        let txStatus = page.xmitStatus;
        let columnData, backgroundColor;
        if (txStatus) {
          txStatusTime = page.xmitStatusTime;
          backgroundColor = txStatus && XMIT_STATUS_MAP[txStatus] ?
            XMIT_STATUS_MAP[txStatus].color : DEFAULT_STATUS_COLOR;
          columnData = txStatusTime ?
            formatDate(fromServerDate(txStatusTime), DATE_TIME_FORMAT) : '';
        } else {
          txStatus = getTransmissionStatus(page);
          backgroundColor = txStatus && STATUS_MAP[txStatus.statusType] ?
            STATUS_MAP[txStatus.statusType].color : DEFAULT_STATUS_COLOR;
          columnData = txStatus && txStatus.time ?
            formatDate(fromServerDate(txStatus.time), DATE_TIME_FORMAT) : '';
        }

        return {
          columnData,
          backgroundColor
        };
      },
    },
  ];
};

export const getMagazineColumns = (viewModel) => {
  return viewModel.magazines.map(mag => {
    const idx = mag.displayName.lastIndexOf(' ');
    const lines = idx > 0 && idx < mag.displayName.length - 1 ?
      [mag.displayName.substring(0, idx), mag.displayName.substring(idx + 1)] : [mag.displayName];

    return {
      key: mag.displayName,
      displayName: lines.map(line => <div>{line}</div>),
      tooltip: mag.fullName,
      width: 72,
      resizable: false,
      sortable: false
    };
  });
};
