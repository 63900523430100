/**
 * @name Breadcrumbs
 * @file Breadcrumbs shown inside main panel navigation bar
 *
 * @author Boris
 * @since: 2017-09-14
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utilities/classNames';
import { formatBreadcrumbs, breadcrumbsToNames } from 'utilities/breadcrumbs';

const isLastItem = (array = [], index) => index === array.length - 1;

export default function Breadcrumbs({ breadcrumbs = [] }) {

  return (
    <div className='crtx-mn-breadcrumbs' title={formatBreadcrumbs(breadcrumbs)}>
      {breadcrumbsToNames(breadcrumbs).map((name, index, array) => {
        const className = classNames('crtx-mn-breadcrumbs-text', { last: isLastItem(array, index)});
        return (
          <Fragment key={index}>
            <div className={className}>{name}</div>
            {!isLastItem(array, index) ? <div className='crtx-mn-breadcrumbs-arrow'>{'>'}</div> : null}
          </Fragment>
        );
      })}
    </div>
  );
}

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array,
};
