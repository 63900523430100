// @flow

import React from 'react';

function isUndefined(o) {
  return typeof o === 'undefined';
}

function isArray(o) {
  return Array.isArray(o);
}

function isObject(o) {
  return typeof o === 'object';
}

function isFunction(f) {
  return typeof f === 'function';
}

function isString(s) {
  return typeof s === 'string';
}

function isNumber(n) {
  return typeof n === 'number';
}

function isBoolean(b) {
  return typeof b === 'boolean';
}

function emptyObject() {
  return {};
}

function join(arr, str) {
  return Array.prototype.join.call(arr, str);
}

// compose multiple paths into dot separated path: path1.path2 ...
function compose() {
  var paths = [];

  for (var i = 0; i < arguments.length; i++) {
    var path = arguments[i];
    if (typeof path !== 'undefined' && path !== '') {
      paths.push(path);
    }
  }

  return paths.join('.');
}

function pathHead(path) {
  if (!path) {
    return '';
  }

  var idx = path.indexOf('.');
  return idx < 0 ? path : path.substring(0, idx);
}

function pathTail(path) {
  if (!path) {
    return '';
  }

  var idx = path.lastIndexOf('.');
  return idx < 0 ? path : path.substring(idx + 1);
}

function pathNoTail(path) {
  if (!path) {
    return '';
  }

  var idx = path.lastIndexOf('.');
  return idx < 0 ? path : path.substring(0, idx);
}

function pathContains(path, key) {
  if (!path || !key) {
    return false;
  }

  var keys = path.split('.');
  return keys.indexOf(key) >= 0;
}

function slice(arr, startIndex, endIndex) {
  return Array.prototype.slice.apply(arr, [startIndex, endIndex]);
}


function hasChildren(children) {
  return React.Children.count(children) > 0;
}

function getCursorFromStringKey(str) {
  return str.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
    .replace(/^\./, '')           // strip a leading dot
    .split('.');
}

function getCursor(path) {
  if (isString(path)) {
    if (path === '') return [];
    return getCursorFromStringKey(path);
  }
  return path.length === 0 ? [] : getCursorFromStringKey(join(path, '.'));
}

function path(/*paths*/) {
  if (arguments.length === 0) {
    return undefined;
  }

  return join(arguments, '.');
}

function get(model, path) {
  if (typeof path === 'undefined') {
    return;
  }

  var cursor = getCursor(path);//isString(path) ? getCursorFromStringKey(path) : getCursorFromStringKey(join(path, '.'));

  for (var i = 0, n = cursor.length; i < n; ++i) {
    var k = cursor[i];
    if (k in model) {
      model = model[k];
    } else {
      return;
    }
  }
  return model;
}

function set(model, path, value) {
  var cursor = getCursor(path);//getCursorFromStringKey(join(path, '.'));
  var item = model;

  for (var i = 0; i < cursor.length - 1; i++) {
    var key = cursor[i];
    if (!item[key]) item[key] = {};
    item = item[key];
  }

  item[cursor[cursor.length - 1]] = value;
  return model;
}

function calcStyle(style, visible) {
  return {
    ...style,
    display: visible !== false ? ( !style ? undefined : style.display ) : 'none'
  };
}

function renderChildrenWithProps(children, props) {
  return React.Children.map(children, function (child) {
    return !isFunction(child.type) ? child : React.cloneElement(child, props);
  });
}

function combineClassNames(classNames) {
  var className = null;

  if (Array.isArray(classNames)) {
    className = classNames.join(' ');
  }
  if (isString(classNames)) {
    className = classNames;
  }

  return className;
}

function getColumnSizeClassName(col) {
  return col > 0 ? ['col-', col].join('') : null;
}

function getColumnOffsetClassName(offset) {
  return offset > 0 ? ['col-offset-', offset].join('') : null;
}

const getColumnFullHeightClassName = (fullHeight) => {
  return fullHeight === true ? 'col-full-height' : null;
};


const update = (state = {}, path, value) => {
  let cursor = getCursor(path);// typeof path === 'string' ? getCursorFromStringKey(path) : path;
  if (cursor.length === 0) return value;
  if (typeof state !== 'object') return state;

  let key = cursor[0];
  let child = state[key];

  if (Array.isArray(state)) {
    let index = key | 0;
    return [
      ...state.slice(0, index),
      update(child, cursor.slice(1), value),
      ...state.slice(index + 1)
    ];
  }

  return {
    ...state,
    [key]: update(child, cursor.slice(1), value)
  };
};

const add = (state = {}, path, index, value) => {
  let cursor = getCursor(path);//typeof path === 'string' ? getCursorFromStringKey(path) : path;

  if (cursor.length === 0) {
    if (Array.isArray(state)) {
      return [
        ...state.slice(0, index),
        value,
        ...state.slice(index)
      ];
    }
    if (typeof state === 'object') {
      return {
        ...state,
        [index]: value
      };
    }
    return state;
  }

  if (typeof state !== 'object') return state;

  let key = cursor[0];
  let child = state[key];

  if (Array.isArray(state)) {
    return [
      ...state.slice(0, key | 0),
      add(child, cursor.slice(1), index, value),
      ...state.slice((key | 0) + 1)
    ];
  }

  return {
    ...state,
    [key]: add(child, cursor.slice(1), index, value)
  };
};

const remove = (state = {}, path, index) => {
  let cursor = getCursor(path);//typeof path === 'string' ? getCursorFromStringKey(path) : path;

  if (cursor.length === 0) {
    if (Array.isArray(state)) {
      return [
        ...state.slice(0, index),
        ...state.slice(index + 1)
      ];
    }
    else if (isObject(state)) {
      let retState = {...state};
      delete retState[index];
      return retState;
    }
    return state;
  }

  if (typeof state !== 'object') return state;

  let key = cursor[0];
  let child = state[key];

  if (Array.isArray(state)) {
    return [
      ...state.slice(0, key | 0),
      remove(child, cursor.slice(1), index),
      ...state.slice((key | 0) + 1)
    ];
  }

  return {
    ...state,
    [key]: remove(child, cursor.slice(1), index)
  };
};

const removeItem = (state = {}, path) => {
  let cursor = getCursor(path);
  let index = cursor[cursor.length - 1];
  cursor.pop();
  return remove(state, cursor, index);
};

const roundTo = (value, decimalPlaces, defaultValue) => {
  var defValue = typeof defaultValue === 'undefined' ? value : defaultValue;
  return isNaN(value) ? defValue : +Number(value).toFixed(decimalPlaces);
};

const toNumber = (value, defaultValue) => {
  return isNaN(value) ? defaultValue : Number(value);
};

const replaceLineBreaks = (text, replaceText) => {
  return (text || '').replace(/(\r?\n)/g, replaceText || '');
};

export default {
  isUndefined,
  isObject,
  isFunction,
  isString,
  isNumber,
  isBoolean,
  emptyObject,
  isArray,
  join,
  compose,
  pathHead,
  pathTail,
  pathNoTail,
  pathContains,
  slice,
  hasChildren,
  getCursorFromStringKey,
  path,
  get,
  set,
  calcStyle,
  renderChildrenWithProps,
  combineClassNames,
  getColumnSizeClassName,
  getColumnOffsetClassName,
  getColumnFullHeightClassName,
  update, //Immutable update
  add, //Immutable add
  remove, //Immutable remove
  removeItem, //Immutable removeItem
  roundTo,
  toNumber,
  replaceLineBreaks
};
