import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'components/common/buttons/FlatButton';
import { classNames } from 'utilities/classNames';

export default class MenuItem extends Component {
  static propTypes = {
    item: PropTypes.object
  };

  renderButton = (button, index) => {
    const { item } = this.props;

    return (
      <FlatButton key={index}
        className={button.className}
        onClick={(e) => button.onClick(e, item)}>
        <i className='material-icons'>{button.iconName}</i>
      </FlatButton>
    );
  };

  render() {
    const { item } = this.props;
    const buttons = item.buttons || [];
    const className = classNames('crtx-menu-item', { disabled: item.disabled });

    return (
      <div className={className}>
        <div className='crtx-menu-item-text'>{item.text}</div>
        <div className='crtx-menu-item-buttons'>
          {buttons.map(this.renderButton)}
        </div>
      </div>
    );
  }
}