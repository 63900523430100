/**
 * Compare two numbers or two arrays of numbers with the given tolerance.
 * @param {number | array} a - First number or array of numbers to compare
 * @param {number | array} b - Second number or array of numbers to compare
 * @param {number} tolerance - Tolerance of comparison
 * @returns {boolean} The result of comparison (true or false)
 */
export const areNumbersEqual = (a, b, tolerance = 0) => {
  let equal = false;

  if (typeof a === 'number' && typeof b === 'number') {
    equal = Math.abs(a - b) <= tolerance;
  } else if (Array.isArray(a) && Array.isArray(b) && a.length === b.length) {
    equal = a.every((num, idx) => Math.abs(num - b[idx]) <= tolerance);
  }

  return equal;
};

export const constrainValue = (value, min, max) => {
  return value < min ? min : value > max ? max : value;
};

export const projectPointOntoRect = (x, y, rectangle) => {
  const { left, top, width, height } = rectangle;

  return [
    x < left ? left : x > left + width ? left + width : x,
    y < top ? top : y > top + height ? top + height : y
  ];
};

/**
 * Obtain viewport size without scrollbars
 * @param {Document} document - The HTML Document object
 * @returns {array} The width and height of the viewport
 */
export const getViewportSize = (document) => {
  if (!document) {
    return null;
  }

  const { documentElement } = document;

  return [documentElement.clientWidth, documentElement.clientHeight];
};

/**
 * Obtain viewport rectangle relative to the document element without scrollbars
 * @param {Document} document - The HTML Document object
 * @returns {object} The viewport rectangle
 */
export const getViewportRect = (document) => {
  if (!document) {
    return null;
  }

  const { documentElement } = document;
  const { left, top } = documentElement.getBoundingClientRect();
  const [width, height] = getViewportSize(document);

  return { left: -left, top: -top, width, height };
};

/**
 * Obtain the given element bounding client rectangle.
 * If no container is provided then return bounding client rectangle relative to the viewport,
 * otherwise - relative to the container element. 
 * @param {Element} element - The HTML element object
 * @param {Element} container - The HTML element object
 * @returns {object} The element rectangle
 */
export const getElementRect = (element, container) => {
  if (!document) {
    return null;
  }

  let { left, top, width, height } = element.getBoundingClientRect();

  if (container) {
    const { left: offsetX, top: offsetY } = container.getBoundingClientRect();
    left -= offsetX;
    top -= offsetY;
  }

  return { left, top, width, height };
};

/**
 * Center the given element rectangle in the viewport.
 * If no container is provided then center element rectangle in the viewport
 * relative to the viewport (fixed element position, without regard to scroll),
 * otherwise - relative to the container element (absolute element position, with regard to scroll). 
 * @param {Element} element - The HTML element object
 * @param {Element} container - The HTML element object
 * @param {Object} options - Options that may affect the element size and position:
 *  width, height, minWidth, minHeight, left, top.
 * @returns {object} The centered element rectangle
 */
export const centerElementInViewport = (element, container, options = {}) => {
  let { left, top, width, height } = element.getBoundingClientRect();
  const { left: offsetX, top: offsetY } = container ? container.getBoundingClientRect() : { left: 0, top: 0 };
  const [viewportWidth, viewportHeight] = getViewportSize(element.ownerDocument);

  width = constrainValue(options.width || width, options.minWidth, viewportWidth);
  height = constrainValue(options.height || height, options.minHeight, viewportHeight);

  if (typeof options.left !== 'undefined') {
    left = constrainValue(options.left, -offsetX, -offsetX + viewportWidth - width);
  } else {
    left = (viewportWidth - width) / 2 - offsetX;
  }

  if (typeof options.top !== 'undefined') {
    top = constrainValue(options.top, -offsetY, -offsetY + viewportHeight - height);
  } else {
    top = (viewportHeight - height) / 2 - offsetY;
  }

  return { left, top, width, height };
};
