/**
 * Created by moshemal on 3/1/15.
 * Layout patterns:
 * http://dhtmlx.com/docs/products/dhtmlxLayout/samples/02_conf/01_patterns.html
 */
import KendoSplitter from 'kendo.splitter';
import dom from 'base/dom';
import pubsub from 'core/managers/pubsub';
import { getLayoutPanesPreferences, saveLayoutPanesPreferences } from './preferences';
const NS = '.kendoSplitter';
const MOUSEENTER = 'mouseenter';
const MOUSELEAVE = 'mouseleave';


const TYPE_1C = '1C';
const TYPE_3U = '3U';
const DEFAULT_LAYOUT_TYPE = '3U';
let currentLayoutType = undefined;

const kendo = window.kendo;
const ui = kendo.ui;
const Splitter = ui.Splitter;

const FixedSplitter = Splitter.extend({

  init: function (element, options) {

    // The base call to the component initialization.
    Splitter.fn.init.call(this, element, options);

    const splitbar = this.element.children('.k-splitbar');

    splitbar.on(MOUSEENTER + NS, () => { splitbar.addClass('k-splitbar-' + this.orientation + '-hover'); })
      .on(MOUSELEAVE + NS, () => { splitbar.removeClass('k-splitbar-' + this.orientation + '-hover'); });
  }
});

ui.plugin(FixedSplitter);

// Important: do not remove the div closing tag "</div>" required by jquery
const LAYOUT_HTML_1C = `
  <div id="main-wrapper" style="height: calc(100% - 2px)" tabindex="2">
    <div id="top-messages-bar"></div>
    <div id="center" class="window k-pane">
      <div id="main" style="position: relative; margin-right: 10px;"></div>
      <div id="right"></div>
    </div>
  </div>
`;

const LAYOUT_HTML_3U = `
  <div id="top">
    <div id="left">
      <div id="user"></div>
      <div id="navigation" tabindex="1"></div>
    </div>
    <div id="main-wrapper" tabindex="2">
      <div id="top-messages-bar"></div>
      <div id="center">
        <div id="main"></div>
        <div id="right"></div>
      </div>
      <div id="secondary"></div>
    </div>
  </div>
`;

const OVERVIEWS_SINGLINE_LINE_HEIGHT = '236px';

function setPanesPreferences(panes) {
  const panesPreferences = getLayoutPanesPreferences();

  const newPanesPreferences = panes.reduce(function (accPanes, pane) {
    accPanes[pane.name] = { size: pane.size, collapsed: pane.collapsed };
    return accPanes;
  }, panesPreferences);

  saveLayoutPanesPreferences(newPanesPreferences);
}

function alignOverviewsContainer(height) {
  const $overviewContent = dom.find('#overview');
  const secondaryContainer = document.getElementById('overview');

  if (parseInt(height) > parseInt(OVERVIEWS_SINGLINE_LINE_HEIGHT) ||
    secondaryContainer && secondaryContainer.offsetHeight > parseInt(OVERVIEWS_SINGLINE_LINE_HEIGHT)) {
    $overviewContent.addClass('overview-multiline');
  } else {
    $overviewContent.removeClass('overview-multiline');
  }
}

function createLayout1C() {
  dom.append('#container', LAYOUT_HTML_1C);
  $('#main-wrapper').kendoSplitter({
    orientation: 'vertical',
    panes: [
      { // #top-messages-bar
        size: '25px',
        collapsible: true,
        collapsed: true,
        resizable: false
      },
      { // #center
        collapsible: false,
        resizable: true
      }
    ]
  });


  dom.find('#center').kendoSplitter({
    panes: [
      { // #main
        collapsible: false,
      },
      { // #right
        size: '30%',
        resizable: true,
        collapsible: true,
        collapsed: true
      }
    ]
  });
}

function createLayout3U() {
  const prefs = getLayoutPanesPreferences();
  dom.append('#container', LAYOUT_HTML_3U);

  dom.find('#top').kendoSplitter({
    resize: function (event) {
      const { panes = [] } = event.sender?.options || {};
      if (panes.length === 2) {
        const navigationPane = panes[0]; // #left
        const mainPane = panes[1]; // #main-wrapper
        setPanesPreferences([
          { name: 'navigation', size: navigationPane.size, collapsed: navigationPane.collapsed },
          { name: 'main' }
        ]);
      }
    },
    panes: [
      { // #left
        size: parseInt(prefs.navigation?.size, 10) >= 0 ? prefs.navigation.size : '20%',
        collapsed: prefs.navigation?.collapsed || false,
        collapsible: true,
        resizable: true,
      },
      { // #main-wrapper
        resizable: true,
        collapsible: false,
        scrollable: false,
      }
    ]
  });

  dom.find('#left').kendoSplitter({
    orientation: 'vertical',
    panes: [
      { // #user
        size: '35px',
        collapsible: false,
        resizable: false
      },
      { // #navigation
        collapsible: false,
        resizable: false,
        scrollable: false // pane scrolling is disabled, content handles scrolling
      }
    ]
  });

  let secondarySize = parseInt(prefs.secondary?.size, 10);
  secondarySize = secondarySize >= 0 ? `${secondarySize}px` : OVERVIEWS_SINGLINE_LINE_HEIGHT;

  dom.find('#main-wrapper').kendoSplitter({
    orientation: 'vertical',
    resize: function (event) {
      pubsub.publish('pane-resize');
      const { panes = [] } = event.sender?.options || {};
      if (panes.length === 3) {
        const centerPane = panes[1];
        const secondaryPane = panes[2];
        alignOverviewsContainer(parseInt(secondaryPane.size, 10));
        setPanesPreferences([
          { name: 'center', collapsed: centerPane.collapsed },
          { name: 'secondary', size: secondaryPane.size, collapsed: secondaryPane.collapsed }
        ]);
      }
    },
    panes: [
      { // #top-messages-bar
        size: '25px',
        collapsible: true,
        collapsed: true,
        resizable: false
      },
      { // #center
        collapsible: true,
        collapsed: prefs.center && prefs.center.collapsed || false
      },
      { // #secondary
        size: secondarySize,
        min: OVERVIEWS_SINGLINE_LINE_HEIGHT,
        resizable: true,
        collapsible: true,
        collapsed: prefs.secondary && prefs.secondary.collapsed || false
      }
    ]
  });

  dom.find('#center').kendoSplitter({
    resize: function (event) {
      const { panes = [] } = event.sender?.options || {};
      if (panes.length === 2) {
        // const mainPane = panes[0];
        const rightPane = panes[1];
        setPanesPreferences([
          { name: 'right', collapsed: rightPane.collapsed }
        ]);
      }
    },
    panes: [
      { // #main
        collapsible: false,
      },
      { // #right
        size: '30%',
        resizable: true,
        collapsible: true,
        collapsed: prefs.right?.collapsed ?? true,
      }
    ]
  });
}

/**
 * Generates HTML layout from the given layout type.
 *
 * @param layoutType {string} - layout type ('3U' or '1C')
 */
export function createLayout(layoutType = DEFAULT_LAYOUT_TYPE) {
  dom.hide('#info');
  dom.show('#container');

  currentLayoutType = layoutType;
  switch (currentLayoutType) {
    case TYPE_1C:
      createLayout1C();
      hideInfoPanelSplitbar();
      break;
    case TYPE_3U:
    default:
      currentLayoutType = TYPE_3U;
      createLayout3U();
      break;
  }
}

export function showInfoPanelSplitbar() {
  dom.find('#center > .k-splitbar').removeClass('crtx-hide-element');
}

export function hideInfoPanelSplitbar() {
  dom.find('#center > .k-splitbar').addClass('crtx-hide-element');
}

export function getCurrentLayout() {
  return currentLayoutType;
}

export default {
  _name: 'layout',
  _type: 'manager',

  createLayout,
  getCurrentLayout,
  showInfoPanelSplitbar,
  hideInfoPanelSplitbar,
};