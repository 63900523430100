define(['sandbox', 'ember', 'logger'], function (sandbox, Ember, logger) {
  'use strict';
  var log = logger.getDefaultLogger();

  var timeoutId;

  return Ember.ObjectController.extend({

    deleteColor: function (color) {
      var deleteColorAction = this.get('module.viewActions').find(a => a.actionDefinitionName === 'DeleteColorActionCR');
      if (typeof deleteColoreAction === 'undefined') {
        log.error('DeleteColorAction instance is not found');
        return;
      }

      if (deleteColorAction.isApplicable(color)) {
        deleteColorAction.execute(color);
      }
    }
  });

});
