import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import iconService from 'core/services/iconService';

export default function ResourceLed({ humanStateDescription }) {
  let led = <img src={iconService.getGeneralIcon('resource', 'led_unknown')} alt={translate('Unknown')}></img>;

  if (humanStateDescription === 'Up') {
    led = <img src={iconService.getGeneralIcon('resource', 'led_on')} alt={translate('Alive')}></img>;
  } else if (humanStateDescription === 'Down') {
    led = <img src={iconService.getGeneralIcon('resource', 'led_off')} alt={translate('Dead')}></img>;
  }

  return led;
}

ResourceLed.propTypes = {
  humanStateDescription: PropTypes.string,

};

