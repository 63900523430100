import React, {Component} from 'react';
import PropTypes from 'prop-types';
import utils from '../utils';
import actions from '../actions';
import SimpleFormComponent from '../SimpleFormComponent';

export default SimpleFormComponent(class extends Component {
  static propTypes = {
    //store - The main store of the SimpleForm that the input is child of.
    store: PropTypes.object,
    bind: PropTypes.string,
    placeholder: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    visible: PropTypes.bool,
    col: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onKeyDown: PropTypes.func,
    onChange: PropTypes.func,
    liveUpdate: PropTypes.bool,
    actionType: PropTypes.string,
    autoTrim: PropTypes.bool
  };

  static defaultProps = {
    bind: '',
    liveUpdate: false,
    actionType: 'UPDATE',
    autoTrim: true
  };

  constructor(props, context) {
    super(props, context);
    var storeValue = this.getStoreValue(props);
    var userValue = this.toUserValue(storeValue);

    this.state = {
      storeValue,
      userValue
    };
  }

  toUserValue = (storeValue) => {
    var {converter} = this.props;

    return !converter ? storeValue : converter.toUserValue(storeValue);
  };

  fromUserValue = (userValue) => {
    var {converter} = this.props;

    return !converter ? userValue : converter.fromUserValue(userValue);
  };

  getStoreValue = (props) => {
    var {store, bind} = props;

    return store.get(bind);
  };

  matchesMask = (userValue) => {
    var {masker} = this.props;

    return !masker ? true : masker.matchesMask(userValue);
  };

  handleChange = (event) => {
    const liveUpdate = this.props.liveUpdate;
    var userValue = event.target.value;
    if (!this.matchesMask(userValue)) {
      return;
    }
    var storeValue = this.fromUserValue(userValue);
    this.setState({
      storeValue,
      userValue
    });
    if (liveUpdate) this.dispatch(event, storeValue);
  };

  handleBlur = (event) => {
    const {autoTrim} = this.props;
    let {storeValue, userValue} = this.state;

    if (autoTrim) {
      if (typeof storeValue === 'string') {
        storeValue = storeValue.trim();
      }
      if (typeof userValue === 'string') {
        userValue = userValue.trim();
      }

      this.setState({
        storeValue,
        userValue
      });
    }

    this.dispatch(event, storeValue);
  };

  handleContextMenu = (event) => {
    const {storeValue} = this.state;
    this.dispatch(event, storeValue);
  };

  handleKeyDown = (event) => {
    var {onKeyDown} = this.props;
    var {storeValue} = this.state;

    if (utils.isFunction(onKeyDown)) {
      return onKeyDown(event);
    }

    if (event.key === 'Enter') {
      event.stopPropagation();
      event.preventDefault();

      this.dispatch(event, storeValue);
    }
  };

  dispatch = (event, storeValue) => {
    var {store, bind, onChange, actionType} = this.props;

    if (utils.isFunction(onChange)) {
      onChange(event, bind, storeValue);
    } else {
      store.dispatch(actions.update(bind, storeValue, actionType));
    }
  };

  componentWillReceiveProps(nextProps) {
    var {storeValue, userValue} = this.state;

    var newStoreValue = this.getStoreValue(nextProps);
    var newUserValue = this.toUserValue(newStoreValue);
    if (storeValue !== newStoreValue || userValue !== newUserValue) {
      this.setState({
        storeValue: newStoreValue,
        userValue: newUserValue
      });
    }
  }

  render() {
    var {store, bind, style, visible, className, col, offset, flex, onChange, liveUpdate, actionType, valueType, ...props} = this.props;
    var userValue = typeof this.state.userValue === 'undefined' ? '' : this.state.userValue + '';

    return (
      <input className={utils.combineClassNames(['input-text', className])}
             style={style}
             value={userValue}
             onChange={this.handleChange}
             onBlur={this.handleBlur}
             onContextMenu={this.handleContextMenu}
             onKeyDown={this.handleKeyDown}
        {...props}
      />
    );
  }
});
