import React from 'react';
import { createRoot } from 'react-dom/client';
import FileViewerMain from './components/FileViewerMain';
import AbstractModule from 'AbstractModule';
import prefernecesManager from 'core/managers/preferences';

export default AbstractModule.extend({
  firstTickReceived: function (data) {
    this.preferences = data.preferences || {};
    this.reactRoot = createRoot(this.domElement);
    this.data = data;
    this.actionNwid = this.startParameters.initiatingActionNwid;
    this.render();
  },

  tickUpdate(data) {
  },

  savePreferences: function (preferences) {
    if (!preferences) {
      return;
    }
    this.preferences = Object.assign(this.preferences, preferences);
    prefernecesManager.savePreferences(this.getRequiredParameters(), this.preferences);
  },

  destroy: function () {
    this._super();
    this.reactRoot.unmount();
  },

  render: function () {
    this.reactRoot.render(<FileViewerMain actionNwid={this.actionNwid} module={this} />);
  }
});
