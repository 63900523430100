import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { translate, toLocaleShortDate } from 'core/services/localization';
import { createValueComparator } from 'core/comparators';
import Header from 'components/common/headers/Header';
import Dropdown from 'components/common/dropdown/Dropdown';
import Button from 'components/common/buttons/Button';

const NO_TEMPLATE_OPTION = {
  name: '',
  value: '',
  text: translate('No Template'),
};

const getValue = template => {
  if (!template) {
    return '';
  }

  return `${template.global ? 'global' : 'local'}:${template.name}`;
};

const getTemplateOptions = (templates = []) => {
  const global = templates.filter(t => t.global).map(t => t.name).sort(createValueComparator()).map(name => {
    return {
      name,
      global: true,
      value: getValue({ name, global: true }),
      text: `${name} (${translate('global')})`,
    };
  });


  const local = templates.filter(t => !t.global).map(t => t.name).sort(createValueComparator()).map(name => {
    return {
      name,
      global: false,
      value: getValue({ name, global: false }),
      text: `${name} (${translate('local')})`,
    };
  });

  return [NO_TEMPLATE_OPTION].concat(global).concat(local);
};

function TemplateSelector({ visible, canActivate, templates = [], selectedTemplate, onSelectTemplate, onActivateTemplate }) {

  const templateOptions = useMemo(() => {
    return getTemplateOptions(templates);
  }, [templates]);

  const getStyle = () => {
    if (!visible) {
      return { visibility: 'hidden' };
    }
  };

  const findTemplate = value => {
    const option = value && templateOptions.find(o => o.value === value);

    return option ? { name: option.name, global: option.global } : null;
  };

  const handleSelectTemplate = (event, value) => {
    const template = findTemplate(value);

    onSelectTemplate(template);
  };

  const handleActivateTemplate = () => {
    onActivateTemplate();
  };

  return (
    <div className='template-selector' style={getStyle()}>
      <Header>{translate('Template')}</Header>
      <div className='template-section'>

        <>
          <Dropdown
            value={getValue(selectedTemplate)}
            options={templateOptions}
            onSelect={handleSelectTemplate}
          />
          <Button
            
            onClick={handleActivateTemplate}>{translate('Activate Plan from Template')}
          </Button>
        </>

      </div>
    </div>
  );
}

TemplateSelector.propTypes = {
  visible: PropTypes.bool,
  canActivate: PropTypes.bool,
  templates: PropTypes.array,
  selectedTemplate: PropTypes.object,
  onSelectTemplate: PropTypes.func,
  onActivateTemplate: PropTypes.func,
};

export default TemplateSelector;
