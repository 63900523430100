import React from 'react';
import PropTypes from 'prop-types';

export default function TitleComponent({ model }) {
  const { name } = model;

  return (
    <div className='title-text'>{name}</div>
  );
}

TitleComponent.propTypes = {
  model: PropTypes.object,

};