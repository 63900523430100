import PropTypes from 'prop-types';
import React, { Component } from 'react';
import sandbox from 'sandbox';
import { translate } from 'core/services/localization';

export default class NewContentVersionIndicator extends Component {
  static propTypes = {
    notCompletedPagesLabels: PropTypes.arrayOf(PropTypes.string),
    onClick: PropTypes.func
  };

  render() {
    const { notCompletedPagesLabels, onClick } = this.props;
    const style = {
      width: 12,
      height: 12,
    };
    const src = sandbox.icons.getModuleIcon('MyBirdeye', 'NewPageVersion');
    const title = notCompletedPagesLabels.length ? translate('Upcoming version for: {1}', notCompletedPagesLabels.join(', ')) : undefined;

    return notCompletedPagesLabels.length > 0 ? <img src={src} title={title} style={style} onClick={onClick} /> : <span></span>;
  }
}