define(["./actions"],
  function(actionsController){
  'use strict';
  var FALSE_PANORAMA = 0;
  var REAL_PANORAMA = 1;
  var REVERT_PANORAMA = 2;

  function findRequestedPagesForPanoramaAction() {
    var pages = this.filterSelectedLinks();
    var pagesByZones = {};
    var map = this.modelHandler.pagesPerSection;
    var mapRows = this.modelHandler.pagesPerRow;
    for (var i = 0; i < pages.length; i++) {
      var page = pages[i];//.props.page;
      if (page.panorama){
        return {};
      }
      var rowData = mapRows[page.sourceId];
      var zone = this.findZone.call(this, rowData, page);
      //var key = this.getTitle(page) + ":" + zone;
      var pairs = [];
      if (pagesByZones.hasOwnProperty(zone)) {
        pairs = pagesByZones[zone];
      } else {
        pagesByZones[zone] = pairs;
      }
      pairs.push(page);
    }
    return pagesByZones;
  }

  function findPotentialVirtual(virtual, zone){
    var map = this.modelHandler.pagesPerRow;
    var row = map[virtual.sourceId];
    var ret = row[zone];
    if (ret.masterPage === virtual.sourceId){
      return ret;
    }
    return null;
  }

  function clearSelectionFromVirtuals(){
    for (var i=this.selectedPages.length-1;i>=0;i--){
      var page = this.selectedPages[i];//.props.page;
      if (page.virtual){
        this.selectedPages.splice(i, 1);
      }
    }
  }

    ////////////////////////////////////////////////////// Public
  function setFalsePanoram(page){

  }

  function setPageAsPanorama(panoPage, virtualPage){
    var mapRows = this.modelHandler.pagesPerRow;
    setPanoramaInternal.call(this, panoPage, virtualPage);
    var rowData = mapRows[panoPage.sourceId];
    var commons = this.findFollowers (panoPage);//this.findAllCommonPages(rowData, panoPage.sourceId);
    for (var i=0;i<commons.length;i++){
      var link = commons[i];
      //var zone = this.findZone(rowData, link);
      var zone = this.findZone(mapRows[link.sourceId], link);
      var potentialVirtual = findPotentialVirtual.call(this, virtualPage, zone);
      if (potentialVirtual === null){
        this.makeLocal(link);
      } else {
        setPanoramaInternal.call(this, link, potentialVirtual);
      }
    }
  }
  
  function setPanorama(){
    var pagesByZones = findRequestedPagesForPanoramaAction.call(this);
    var mapRows = this.modelHandler.pagesPerRow;

    for (var zone in pagesByZones){
      var x = parseInt(pagesByZones[zone][0].pagination, 10);
      var y = parseInt(pagesByZones[zone][1].pagination, 10);
      var a = pagesByZones[zone][0].insertChar;
      var b = pagesByZones[zone][1].insertChar;

      if (x == y && a && b){
        x = a.toUpperCase().charCodeAt(0);
        y = b.toUpperCase().charCodeAt(0);
      } else if (x === y){
        if (a){
          y = 64;
          x = a.toUpperCase().charCodeAt(0);
        } else if (b){
          x = 64;
          y = b.toUpperCase().charCodeAt(0);
        }
      } 
      var panoPage = null, virtualPage = null;
      if (x < y){
        panoPage = pagesByZones[zone][0];
        virtualPage = pagesByZones[zone][1];
      } else {
        panoPage = pagesByZones[zone][1];
        virtualPage = pagesByZones[zone][0];
      }
      //panoPage.virtualPage = virtualPage.sourceId;
      //panoPage.panorama = true;
      //panoPage.virtualTitle = this.getTitle(virtualPage);
      //panoPage.title = panoPage.title + "-" + virtualPage.title;
      //virtualPage.virtual = true;
      //virtualPage.panoramaPage = panoPage.sourceId;
      setPanoramaInternal.call(this, panoPage, virtualPage);
      var rowData = mapRows[panoPage.sourceId];
      var commons = this.findFollowers (panoPage);//this.findAllCommonPages(rowData, panoPage.sourceId);
      for (var i=0;i<commons.length;i++){
        var link = commons[i];
        //var zone = this.findZone(rowData, link);
        var zone = this.findZone(mapRows[link.sourceId], link);
        var potentialVirtual = findPotentialVirtual.call(this, virtualPage, zone);
        if (potentialVirtual === null){
          this.makeLocal(link);
        } else {
          setPanoramaInternal.call(this, link, potentialVirtual);
        }
      }
    }
  }

  function findVirtual(panorama){
    var map = this.modelHandler.pagesPerRow
    var virtualSrcId = panorama.virtualPage;
    var virtualRowData = map[virtualSrcId];
    var zoneMapKeys = Object.keys(virtualRowData);
    for (var i=0;i<zoneMapKeys.length;i++) {
      if (virtualRowData[zoneMapKeys[i]].sourceId === virtualSrcId) {
        return virtualRowData[zoneMapKeys[i]];
      }
    }
    return null;
  }

  function revertPanorama(){
    var map = this.modelHandler.pagesPerRow
    var pages = this.filterSelectedLinks();
    for (var i=0;i<pages.length;i++){
      var panoramaPage =  pages[i];//.props.page;
      var virtualPage = findVirtual.call(this, panoramaPage);
      if (virtualPage === null) {
        continue;
      }
      var rowData = map[panoramaPage.sourceId];
      revertPanoramaInternal.call(this, panoramaPage, virtualPage);
      var commons = this.findFollowers (panoramaPage);// this.findAllCommonPages(rowData, panoramaPage.sourceId);
      for (var j=0;j<commons.length;j++){
        if (commons[j].panorama){
          virtualPage = findVirtual.call(this, commons[j]);
          revertPanoramaInternal.call(this, commons[j], virtualPage);
        }
      }


    }
  }

  function setPanoramaInternal(panorama, virtual){
    panorama.virtualPage = virtual.sourceId;
    panorama.panorama = true;
    panorama.virtualTitle = this.getTitle(virtual);
    var prevTitle = panorama.title;
    panorama.title = this.getTitle(panorama);//panorama.title + "-" + virtual.title;
    virtual.virtual = true;

    var section = this.modelHandler.pagesPerSection[panorama.sourceId];
    for (var i=0;i<section.zonedPages.length;i++){
      if (section.zonedPages[i].displayName === (prevTitle + "")){
        section.zonedPages[i].displayName = panorama.title;
        break;
      }
    } 

    virtual.panoramaPage = panorama.sourceId;
  }

  function revertPanoramaInternal(panorama, virtual){
    delete panorama.virtualPage;
    panorama.panorama = false;
    delete panorama.virtualTitle;
    var prevTitle = panorama.title;
    panorama.title = this.getTitle(panorama);
    virtual.virtual = false;

    var section = this.modelHandler.pagesPerSection[panorama.sourceId];
    for (var i=0;i<section.zonedPages.length;i++){
      if (section.zonedPages[i].displayName === (prevTitle + "")){
        section.zonedPages[i].displayName = panorama.title;
        break;
      }
    } 
    delete virtual.panoramaPage;
  }

  function isValid(){
    var pagesByZones = findRequestedPagesForPanoramaAction.call(this);
    if (Object.keys(pagesByZones).length === 0){
      return false;
    }
    for (var zone in pagesByZones){
      if (pagesByZones[zone].length !== 2){
        return false;
      }

      var page1 = pagesByZones[zone][0];
      var page2 = pagesByZones[zone][1];
      var chasePage1 = typeof page1.basePage !== "undefined" && page1.basePage !== null;
      var chasePage2 = typeof page2.basePage !== "undefined" && page2.basePage !== null;
      if (chasePage1 || chasePage2){
        return false;
      }
      var p1 = pagesByZones[zone][0].pagination;
      var p2 = pagesByZones[zone][1].pagination;
      //page1.insertChar.charCodeAt(0) - 65 + 1 + p1
      if (page1.insertChar && page2.insertChar){
        p1 = page1.insertChar.toUpperCase().charCodeAt(0) - 64 + p1;
        p2 = page2.insertChar.toUpperCase().charCodeAt(0) - 64 + p2;
      } else if (p1 === p2){
        if (page2.insertChar){
          p1 = 64;
          p2 = page2.insertChar.toUpperCase().charCodeAt(0) - 64 + p2;
        } else if (page1.insertChar){
          p2 = 64;
          p1 = page1.insertChar.toUpperCase().charCodeAt(0) - 64 + p1;
        }
      } 

      if (p1 === p2){
        return false;
      }
      if (p1 % 2 === 0 && p2 % 2 === 0){
        return false;
      }
      if (p1 % 2 > 0 && p2 % 2 > 0){
        return false;
      }
    }
    return true;
  }

  function isApplicableForPanorama() {
    if (isValid.call(this)){
      return true;
    }
    return isPanorama.call(this);
  }

  function isPanorama(){
    var pages = this.filterSelectedLinks();
    for (var i=0;i<pages.length;i++){
      var page = pages[i];//.props.page;
      if (!page.panorama){
        return false;
      }
    }
    return pages.length === 0 ? false : true;
  }

  return {
    panoramaActionController: function (panoramaType){
      //var pages = this.filterSelectedLinks();
      //for (var i=0;i<pages.length;i++) {
      //  var page = pages[i].props.page;
      //  if (panoramaType === FALSE_PANORAMA){
      //    setFalsePanoram.call(this, page);
      //  } else {
      //    setPanorama.call(this, page);
      //  }
      //}
      if (panoramaType === FALSE_PANORAMA){
        setFalsePanoram.call(this);
      } else if (panoramaType === REAL_PANORAMA){
        setPanorama.call(this);
      } else if (panoramaType === REVERT_PANORAMA){
        revertPanorama.call(this);
      }
      clearSelectionFromVirtuals.call(this);
      //this.selectedPages[0].fireSectionSizeChange({});
      return [];
    },

    isApplicableForPanorama: isApplicableForPanorama,
    isPanorama: isPanorama,
    setPanorama: setPageAsPanorama
  };

})
;
