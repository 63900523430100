import { getPointByRotation } from './utilities';

export class StageHelper {
  constructor() {
    this.offsetX = 0;
    this.offsetY = 0;
    this.zoom = 1;
    this.rotation = 0;
    this.flipHorizontalValue = 1;
    this.flipVerticalValue = 1;
  }

  getOffset = () => {
    return {
      x: this.offsetX,
      y: this.offsetY
    };
  };

  setOffset = (x, y) => {
    this.offsetX = x;
    this.offsetY = y;

    return this;
  };

  getZoom = () => this.zoom;

  setZoom = (zoom) => {
    this.zoom = zoom;

    return this;
  };

  getRotation = () => this.rotation;

  setRotation = (rotation) => {
    this.rotation = rotation;

    return this;
  };

  getFlipHorizontal = () => this.flipHorizontalValue === -1;

  setFlipHorizontal = (flipHorizontal) => {
    this.flipHorizontalValue = flipHorizontal ? -1 : 1;

    return this;
  };

  getFlipVertical = () => this.flipVerticalValue === -1;

  setFlipVertical = (flipVertical) => {
    this.flipVerticalValue = flipVertical ? -1 : 1;

    return this;
  };

  getStageByCanvasPoint = (x, y) => {
    return getPointByRotation({
      x: (x - this.offsetX) / this.zoom * this.flipHorizontalValue,
      y: (y - this.offsetY) / this.zoom * this.flipVerticalValue
    }, { x: 0, y: 0 }, -this.rotation * this.flipHorizontalValue * this.flipVerticalValue);
  };

  getCanvasByStagePoint = (x, y) => {
    const p = getPointByRotation({
      x: x * this.zoom * this.flipHorizontalValue,
      y: y * this.zoom * this.flipVerticalValue
    }, { x: 0, y: 0 }, this.rotation * this.flipHorizontalValue * this.flipVerticalValue);

    return {x: p.x + this.offsetX, y: p.y + this.offsetY};
  };

  getStageByCanvasPoint_Limited = (x, y, imageWidth, imageHeight) => {

    const halfImageWidth = imageWidth / 2;
    const halfImageHeight = imageHeight / 2;
    const pointOnStage = this.getStageByCanvasPoint(x, y);

    if ((-halfImageWidth < pointOnStage.x) === (pointOnStage.x > halfImageWidth)) {
      pointOnStage.x = pointOnStage.x > 0 ? halfImageWidth : -halfImageWidth;
    }
    if ((-halfImageHeight < pointOnStage.y) === (pointOnStage.y > halfImageHeight)) {
      pointOnStage.y = pointOnStage.y > 0 ? halfImageHeight : -halfImageHeight;
    }

    return pointOnStage;
  };
}