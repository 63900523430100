define(['sandbox', 'ember', './../../../utils/wizardUtils', "./../model/model", './../../../utils/wizardCommons', '../../../n-up/NupPanel'],
  function (sandbox, Ember, wizardUtils, ModelHandler, wizardCommons, NupPanel) {
    'use strict';

    const translate = sandbox.localization.translate;

    function findZone(model, bookSourceId) {
      for (var i = 0; i < model.editions.length; i++) {
        for (var j = 0; j < model.editions[i].zones.length; j++) {
          for (var k = 0; k < model.editions[i].zones[j].books.length; k++) {
            if (model.editions[i].zones[j].books[k].sourceId === bookSourceId) {
              return model.editions[i].zones[j];
            }
          }
        }
      }
      return null;
    }

    function buildHeaders(model) {
      var ret = [
        {name: "displayName", label: sandbox.localization.translate("Name"), type: "text", style: "width:10em"},
      ]
        //{name:"code", label:"Code", type:"text", style:"width:4em"},
        //{name:"range", label:"Pages", type:"text", style:"width:10em"},
      if (!Ember.get(this.settings, "hideSkip", false)){
        ret.push({name: "skip", label: sandbox.localization.translate("Skip Approval"), type: "checkbox"});
      }

      ret.push({name: "layoutType", label: sandbox.localization.translate("Layout Group"), type: "checkbox"});
      ret.push({name: "pmiTemplate", label: sandbox.localization.translate("Pmi Template"), type: "checkbox"});


      //if (model.pmiTemplates.length > 0) {
      //  ret.push({name: "pmiTemplate", label: sandbox.localization.translate("Pmi Template"), type: "checkbox"});
      //}
      if (model.paperTypeOptions.length > 0) {
        ret.push({name: "paperType", label: sandbox.localization.translate("Paper Type"), type: "checkbox"});
      }
      ret.push({name: "sections", label: sandbox.localization.translate("Sections"), type: "text", style: "width:10em"});
      var that = this;
      var customFields = Ember.get(this.settings, "customFields") || [];
        customFields.forEach(function (h){
          ret.push(h);
          that.customHeaders.push(h);
        });

      return ret;

    }

    return Ember.ObjectController.extend(wizardCommons.controllerCommons, {
      element: null,
      tabpanel: null,
      tabpanelContent: null,

      actions: {
        addBook: function () {
          this.selectedZone.books.addObject({
            sourceId: wizardUtils.getSourceId(),
            parent: this.selectedZone.sourceId, sections: [{isLastSection: true}]
          });
        },
        addSection: function (model) {
          for (var i = 0; i < model.sections.length; i++) {
            Ember.set(model.sections[i], 'isLastSection', false);
          }
          model.sections.addObject({isLastSection: true});

        },
        removeSection: function (book, section) {
          book.sections.removeObject(section);
          if (book.sections.length === 0) {
            this.selectedZone.books.removeObject(book);
            return;
          }

          for (var i = 0; i < book.sections.length; i++) {
            Ember.set(book.sections[i], 'isLastSection',  i === book.sections.length - 1);
          }
        },
        applyAllAction: function () {
          ModelHandler.applyAll();
        },

        editBookPmi: function (book, view) {
          //console.log('###editBookPmi() book=', book);
          if (!book.defaultLayout) {
            sandbox.dialog.alert(translate('Choose Layout Group to continue'), translate('Edit PMI Template'));
            return false;
          }

          this.element = view.element;

          this.hideWizardButtons();

          this.getOtherTabs().hide();
          this.tabpanel = $(this.element).closest("[role='tabpanel']");
          this.tabpanelContent = this.tabpanel.children().detach();

          this.addCodeToSections(book);
          const model = this.convertToNupModel(book);
          const props = {
            ...model,
            book,
            onOkClick: this.handleNupPanelOkClick.bind(this),
            onCancelClick: this.handleNupPanelCancelClick.bind(this)
          };
          //Ember.set(this.model, "uglyHack", "hide");

          this.renderNupPanel(props, this.tabpanel[0]);
        }
      },

      selectedZoneChanged: function (model) {
        try {
          this.selectedZone = model;
          this.selectedEdition = wizardUtils.matchBySourceId(model.parent, Ember.get(this.model, 'editions'));
          ModelHandler.selectedZone = this.selectedZone;
          ModelHandler.selectedEdition = this.selectedEdition;
        } catch (err) {
          console.log("Error " + err);
        }

      },

      addCodeToSections: function (book) {
        book.sections = book.sections.map(s => {
            s.code = this.mappedModel[s.sectionSourceId].code;
            return s;
          }
        );
      },

      sectionsOptions: function (book) {
        var ret = [];
        var zone = findZone(this.model, book.sourceId);
        if (zone === null) {
          return ret;
        }
        ret.addObject({text: "---", value: ""});
        zone.sections.forEach(function (section) {
          ret.addObject({text: section.displayName, value: section.sourceId});
        });
        return ret;
      },

      copies: function () {
        return [1, 2, 3, 4];
      }.property(),

      selectedZone: null,

      selectedEdition: null,

      getLayouts: function (layoutGroup) {
        return this.model.optionalImpositions[layoutGroup] || [];
      },

      headers: function () {
        return buildHeaders.apply(this, [this.model]);
      }.property(),

      customHeaders: [],

      layoutOptions: function () {
        var ret = [];
        var that = this;

        var pmtMode = this.model.pmtMode;
        ret.addObject({text: "---", value: ""});
        if (pmtMode) {
          Object.keys(this.model.pmtInfo).forEach(function (key) {
            var pmt = that.model.pmtInfo[key];
            ret.addObject({text: key, value: key});
          });
        } else {
          this.model.groups.forEach(function (group) {
            ret.addObject({text: group.name, value: group.name});
          });
        }
        return ret;

      }.property('model.pmtInfo'),

      resolveLayoutType: function (groupName) {
        for (var i = 0; i < this.model.groups.length; i++) {
          var group = this.model.groups[i];
          if (groupName === group.name) {
            return group.impositionType;
          }
        }
        return "";
      },

      propertyChange: function (section, property, newValue) {
        Ember.set(section, property, newValue);
      },

      isPmiEnabled1: function () {
        return this.model.pmiTemplates.length > 0;
      }.property(),

      isPaperTypeEnabled: function () {
        return this.model.paperTypeOptions.length > 0;
      }.property(),

      pmiTemplates: function () {
        var ret = [];
        var that = this;

        ret.addObject({text: "---", value: ""});

        this.model.pmiTemplates.forEach(function (pmi) {
          ret.addObject({text: pmi.name, value: pmi.name, numPages: pmi.numPages});
        });

        return ret;
      }.property('model.pmiTemplates'),

      filterPmiTemplates: function (book) {
        const layoutGroup = book.defaultLayout;
        const layouts = this.getLayouts(layoutGroup);
        const options = this.getFormSizeOptions(layouts);
        const pageCount = this.convertPages(book).length;
        const pmiTemplates = this.model.pmiTemplates || [];

        return pmiTemplates.filter(
          t => t.numPages === pageCount &&
          t.sheets.every(s => options.some(o => o.rows === s.rows && o.columns === s.columns)));
      },

      paperTypeOptions: function () {
        var ret = [];
        var that = this;

        ret.addObject({text: "---", value: ""});

        this.model.paperTypeOptions.forEach(function (pt) {
          ret.addObject({text: pt, value: pt});
        });

        return ret;
      }.property('model.paperTypeOptions'),

      updateModel: function (model) {
        // maybe need to backup model before update
        return ModelHandler.convertFromStepModel(model, this.settings);
      },

      zones: function () {
        return ModelHandler.zones();
      },

// --- N-UP related methods ---

      renderNupPanel: function (props, node) {
        NupPanel.renderComponent(props, node);
      },

      hideWizardButtons: function () {
        $('.actions').css('display', 'none');
      },

      unhideWizardButtons: function () {
        $('.actions').css('display', 'inline-block');
      },

      getOtherTabs: function () {
        return $(this.element).closest("[role='tablist']").children("ul").children("li").not("[aria-selected='true']");
      },

      getFormSizeOptions: function (layouts) {
        //*TEST
        // return [
        //   {
        //     rows: 1,
        //     columns: '1'
        //   },
        //   {
        //     rows: 2,
        //     columns: '2'
        //   },
        // ];

        const result = layouts.reduce((options, layout) => {
          var opt = {
            rows: layout.cellGrid.rows,
            columns: layout.cellGrid.columns
          };

          if (!options.find((o) => o.rows === opt.rows && o.columns === opt.columns)) {
            options.push(opt);
          }

          return options;
        }, []);

        return result.sort((a, b) => a.rows !== b.rows ? a.rows - b.rows : a.columns - b.columns);
      },

      convertToNupModel: function (book) {
        const nwid = this.model.nwid;
        const rootId = this.model.rootId;
        const pmiTemplates = this.filterPmiTemplates(book);
        const selectedPmiTemplateName = book.pmiTemplate;

        const layoutGroup = book.defaultLayout;
        const layouts = this.getLayouts(layoutGroup);
        const formSizeOptions = this.getFormSizeOptions(layouts);
        const pages = this.convertPages(book);
        const selectedPageNumber = pages.length > 0 ? pages[0].number : 0;

        const forms = [];

        return {
          nwid,
          rootId,
          forms,
          formSizeOptions,
          pages,
          selectedPageNumber,
          pmiTemplates,
          selectedPmiTemplateName
        };
      },

      updatePmiTemplates: function (output) {
        //console.log('###updatePmiTemplates() output=', output);
        const pmiTemplates = [...this.model.pmiTemplates];
        output.pmiTemplates.forEach(pmi => {
          const idx = pmiTemplates.findIndex(t => t.name.toLowerCase() === pmi.name.toLowerCase());
          if (idx < 0) {
            pmiTemplates.push(pmi);
          } else {
            pmiTemplates[idx] = pmi;
          }
        });

        Ember.set(this.model, 'pmiTemplates', pmiTemplates);

        // hack to force refresh selected PMI Template
        var booksPmis = {};

        for (var i = 0; i < this.model.editions.length; i++) {
          for (var j = 0; j < this.model.editions[i].zones.length; j++) {
            var z = this.model.editions[i].zones[j];
            var isSelectedZone = z.sourceId === this.selectedZone.sourceId;
            for (var k = 0; k < this.model.editions[i].zones[j].books.length; k++) {
              var b = this.model.editions[i].zones[j].books[k];
              var isSelectedBook = isSelectedZone && b.displayName === output.book.displayName;
              if (isSelectedBook){
                booksPmis[b.sourceId] = output.selectedPmiTemplateName;
              } else {
                booksPmis[b.sourceId] = b.pmiTemplate;
              }
              Ember.set(b, 'pmiTemplate', '');
            }
          }
        }



        //for (var i=0;i<this.selectedZone.books.length;i++){
        //  var bookName = this.selectedZone.books[i].displayName;
        //  if (bookName === output.book.displayName){
        //    booksPmis[bookName] = output.selectedPmiTemplateName;
        //  } else {
        //    booksPmis[bookName] = this.selectedZone.books[i].pmiTemplate;
        //  }
        //  Ember.set(this.selectedZone.books[i], 'pmiTemplate', '');
        //}


        Ember.set(output.book, 'pmiTemplateSaved', true);
        setTimeout(() => {

          for (var i = 0; i < this.model.editions.length; i++) {
            for (var j = 0; j < this.model.editions[i].zones.length; j++) {
              var z = this.model.editions[i].zones[j];
              var isSelectedZone = z.sourceId === this.selectedZone.sourceId;
              for (var k = 0; k < this.model.editions[i].zones[j].books.length; k++) {
                var b = this.model.editions[i].zones[j].books[k];
                var bookSourceId = b.sourceId;
                Ember.set(b, 'pmiTemplate', booksPmis[bookSourceId]);
              }
            }
          }



          //for (var i=0;i<this.selectedZone.books.length;i++){
          //  var bookSourceId = this.selectedZone.books[i].sourceId;
          //  Ember.set(this.selectedZone.books[i], 'pmiTemplate', booksPmis[bookSourceId]);
          //}
        }, 0)
      },

      findLayout: function (layouts, layoutId) {
        const layout = layouts.find((l) => l.value === layoutId);

        return layout;
      },

      expandRanges: function (rangesString) {
        if (!rangesString) {
          return [];
        }

        const result = [];
        const ranges = rangesString.split(';');
        ranges.forEach((r) => {
          if (r.indexOf('-') > 0) {
            var numbers = r.split('-');
            var from = parseInt(numbers[0], 10);
            var to = parseInt(numbers[1], 10);
            for (var i = from; i <= to && i > 0; i++) {
              result[i] = true;
            }
          } else {
            var num = parseInt(r, 10);
            if (num > 0) {
              result[num] = true;
            }
          }
        });

        return result;
      },

      convertPages: function (book) {
        if (!book || !book.sections) {
          return [];
        }

        const pages = [];
        var number = 1;
        book.sections.forEach((s) => {
          var nums = this.expandRanges(s.range);
          for (var i = 0; i < nums.length; i++) {
            if (nums[i]) {
              pages.push({
                number,
                name: s.code + i,
                assignedCount: 0
              });
              number++;
            }
          }
        });

        return pages;
      },

      getBookPages: function (model) {
        return model.pages.map((p) => p[0]);
      },

      assignPagesToCells: function (srcPages, cells, pages) {
        for (var i = 0; i < srcPages.length; i++) {
          var p = srcPages[i];
          if (p && p.index && p.index.length > 0) {
            var cell = cells[p.index[0]];
            var page = pages[p.numberInBook - 1];
            if (cell && page) {
              cell.pageNumber = p.numberInBook;
              page.assignedCount = 1;
            }
          }
        }
      },

      editBookPmiDone: function () {
        //console.log('###editBookPmiDone');
        if (!this.tabpanel || !this.tabpanelContent) {
          return;
        }

        NupPanel.unmountComponent(this.tabpanel[0]);

        this.tabpanel.append(this.tabpanelContent);

        this.getOtherTabs().show();
        this.unhideWizardButtons();

        this.tabpanel = null;
        this.tabpanelContent = null;
        this.element = null;

        //Ember.set(this.model, "uglyHack", "show");
      },

      handleNupPanelOkClick: function (output) {
        //console.log('###handleNupPanelOkClick output=', output);

        this.updatePmiTemplates(output);

        this.editBookPmiDone();
      },

      handleNupPanelCancelClick: function () {
        //console.log('###handleNupPanelCancelClick');

        this.editBookPmiDone();
      }

    });

  });

