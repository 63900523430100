import React, { Component } from 'react';
import { debounce } from '../../utils';
import { isIE } from '../../../base/browser';
import PropTypes from 'prop-types';

class ResizeHandler extends Component {

  static propTypes = {
    onResize: PropTypes.func,
    wait: PropTypes.number,
    observe: PropTypes.string
  }

  static defaulProps = {
    onResize: function () { },
    wait: 1,
    observe: 'both'
  }

  constructor(props) {
    super(props);
    this.handleResize = this.handleResize.bind(this);
  }

  handleResize(ev) {
    var { onResize } = this.props;
    onResize(ev);
  }

  componentDidMount() {
    var iframeNode = this.refs.iframe;
    iframeNode.contentWindow.addEventListener(
      'resize',
      debounce(this.handleResize, this.props.wait)
    );

    if (isIE()) {
      setTimeout(this.handleResize, 1);
    }
  }

  componentWillUnmount() {
    window.removeEventListener(
      'resize',
      this.handleResize
    );
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    var { observe } = this.props;
    var className = ['resize-handler'];

    if (observe === 'vertical' || observe === 'horizontal') {
      className.push(observe + '-observer');
    }

    return <div className={className.join(' ')}>
      <iframe ref="iframe"></iframe>
    </div>;
  }

}

module.exports = ResizeHandler;