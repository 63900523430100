import React, {Component} from 'react';
import LoginPassword from './LoginPassword';
import PropTypes from 'prop-types'
import {translate} from 'core/services/localization';

export default class extends Component {

  static propTypes = {
    store: PropTypes.any
  };

  render() {

    return (<React.Fragment>
      <label className="login-label">{translate('Password')}</label>
      <LoginPassword store={this.props.store} />
    </React.Fragment>
    );
  }
}