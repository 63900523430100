/**
 * @name
 * @fileOverview
 * @author sergey & Elad
 */
define(['ember', 'text!../templates/templates.hbs'],
  function (Ember, EditionTemplate) {
    'use strict';

    function getTemplates(){
      var globalTemplatesDscr = this.model.globalTemplates || {};
        var globalTemplates = [];
        if (this.model.publication === null || typeof this.model.publication === 'undefined'){
          return [];
        }
        var ret = [];
        var publicationName = this.model.publication.displayName;
        for (const property in globalTemplatesDscr){
          const publications = globalTemplatesDscr[property];
          for (var i=0;i<publications.length;i++){
            if (publications[i] === publicationName){
              ret.push(property); 
            }
          }
        }
        
        if (this.model.templates === null || typeof this.model.templates === 'undefined'){
          return ret;
        }
        
        var templates = this.model.templates[publicationName];
        if (templates === null || typeof templates === 'undefined'){
          return ret;
        }
        if (ret.length > 0){
          ret.push("---");
        }
        return ret.concat(templates);
    }

    return Ember.View.extend({
      layout: Ember.Handlebars.compile(EditionTemplate),

      templates: function (){
        return getTemplates.call(this);
        
        // var globalTemplatesDscr = this.model.globalTemplates || {};
        // var globalTemplates = [];
        // if (this.model.publication === null || typeof this.model.publication === 'undefined'){
        //   return [];
        // }
        // var ret = [];
        // var publicationName = this.model.publication.displayName;
        // for (const property in globalTemplatesDscr){
        //   const publications = globalTemplatesDscr[property];
        //   for (var i=0;i<publications.length;i++){
        //     if (publications[i] === publicationName){
        //       ret.push(property); 
        //     }
        //   }
        // }
        // if (ret.length > 0){
        //   ret.push("---");
        // }
        // if (this.model.templates === null || typeof this.model.templates === 'undefined'){
        //   return ret;
        // }
        
        // var templates = this.model.templates[publicationName];
        // if (templates === null || typeof templates === 'undefined'){
        //   return ret;
        // }
        // return ret.concat(templates);
      }.property("model.publication"),

      displayStyle: function () {    
        if (!(Ember.get(this.controller, "templateVisible"))){
          return "visibility:hidden";
        }  
        const visible = getTemplates.call(this).length > 0;  
        return visible ? "visibility:visible" : "visibility:hidden";

        // if (!(Ember.get(this.controller, "templateVisible"))){
        //   return "visibility:hidden";
        // }
        // if (this.model.publication === null || typeof this.model.publication === 'undefined' ||
        //   this.model.templates === null || typeof this.model.templates === 'undefined'){
        //   return "visibility:hidden";
        // }
        // var publicationName = this.model.publication.displayName;
        // var templates = this.model.templates[publicationName];
        // if (templates === null || typeof templates === 'undefined'){
        //   return "visibility:hidden";
        // }
        // return "visibility:visible";
      }.property("model.publication", 'controller.templateVisible'),

      planInActionStyle: function (){        
        if (this.model.publication === null || typeof this.model.publication === 'undefined'){
          return "";
        }        
        return this.model.publication.inActivationMode ? "background: gray;cursor: default;" : "";
      }.property("model.publication.inActivationMode"),

    });
  });