import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'base/jsUtils';
import { classNames } from 'utilities/classNames';
import { CanvasHelper } from './canvasHelper';

export const Canvas = forwardRef(
  function Canvas(
    {
      children,
      id,
      style,
      className = '',
      width,
      height,
      rulerVisible = false,
      onLoad = noop,
      onMouseDown = noop,
      onMouseWheel = noop,
      onDoubleClick = noop,
    }, ref) {

    const childInstances = useRef([]);
    const canvasRef = useRef();
    const canvasHelperRef = useRef(null);
    const [canvasStyle, setCanvasStyle] = useState(style);

    useEffect(() => {
      canvasHelperRef.current = new CanvasHelper(rulerVisible);
    }, []);

    useEffect(() => {
      const { left, top } = getBoundingClientRect();

      canvasHelperRef.current.setCanvasSize(width, height)
        .setCanvasOffset(left, top);

      draw();
    });

    useEffect(() => {
      onLoad();
    }, []);

    useEffect(() => {
      if (style !== canvasStyle) {
        setCanvasStyle(style);
      }
    }, [style]);

    const subscribeToCanvas = child => {
      childInstances.current = childInstances.current.concat(child);

      return () => childInstances.current = childInstances.current.filter(childInstance => childInstance !== child);
    };

    useImperativeHandle(ref, () => ({
      get canvasHelper() {
        return canvasHelperRef.current;
      },
    }));

    const draw = () => {
      if (!canvasRef.current) {
        return;
      }

      const ctx = canvasRef.current.getContext('2d');

      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

      childInstances.current.forEach(child => child.draw(ctx, canvasHelperRef.current));
    };

    const getBoundingClientRect = () => {
      if (!canvasRef.current) {
        return;
      }

      return canvasRef.current.getBoundingClientRect();
    };

    return <canvas
      ref={canvasRef}
      id={id}
      style={canvasStyle}
      className={classNames('crtx-canvas', className)}
      width={width}
      height={height}
      onMouseDown={onMouseDown}
      onWheel={onMouseWheel}
      onDoubleClick={onDoubleClick}
    >
      {React.Children.map(children, child => !child ? child : React.cloneElement(child, {
        subscribe: subscribeToCanvas,
        canvasDraw: draw
      }))}
    </canvas>;

  });

Canvas.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  rulerVisible: PropTypes.bool,
  onLoad: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseWheel: PropTypes.func,
  onDoubleClick: PropTypes.func,
};
