/**
 * Created by moshemal on 9/30/14.
 */

import base from 'base';
import localization from 'core/services/localization';
import BaseTabStrip from "./BaseTabStrip";
import iconService from 'core/services/iconService';

const translate = localization.translate;

const SCROLL_STEP = 100;
const SCROLL_DELAY = 100;
const DRAG_INTERVAL = 100;

function cloneOverview(element) {
  var clone = element.clone(),
    elementItemsList = base.dom.find('*', element),
    cloneItemsList = base.dom.find('*', clone);

  //check if there is nested canvas
  cloneItemsList.each(function (i, elm) {
    if (elm.tagName === "CANVAS") {
      var newCanvas = base.dom.createNode('<canvas>'),
        context;

      newCanvas.attr({
        width: elm.width,
        height: elm.height,
        style: $(elm).attr('style')
      });
      context = newCanvas[0].getContext('2d');
      context.drawImage(elementItemsList[i], 0, 0);
      base.dom.replaceWith(elm, newCanvas);
    }
  });

  return clone;
}

function scrollOnDragTimer(element, position, width, height, overview, overviewWidth, overviewHeight) {
  var shouldScroll = false;
  var scrollLeft = overview.scrollLeft();
  var scrollTop = overview.scrollTop();

  if (position.left <= 0) {
    shouldScroll = true;
    scrollLeft = scrollLeft - SCROLL_STEP;
  }
  else if (position.left + width >= overviewWidth) {
    shouldScroll = true;
    scrollLeft = scrollLeft + SCROLL_STEP;
  }

  if (position.top <= 0) {
    shouldScroll = true;
    scrollTop = scrollTop - SCROLL_STEP;
  }
  else if (position.top + height >= overviewHeight) {
    shouldScroll = true;
    scrollTop = scrollTop + SCROLL_STEP;
  }

  if (shouldScroll) {
    overview.stop(false, false).animate({
      scrollLeft: scrollLeft,
      scrollTop: scrollTop
    }, { "duration": SCROLL_DELAY, "easing": "linear" });
  }
}

function sortableOverviews(element) {
  if (!element) {
    return;
  }

  var scrollOnDragInterval, hint;

  const overview = base.dom.find('#overview', element);
  const overviewContent = base.dom.find('.overview-content', element);

  overviewContent.kendoSortable({
    filter: ".overview-item",
    // axis: "x",
    container: "#overview",
    handler: '.title-text',
    autoScroll: true,
    hint: function (element) {
      hint = base.dom.createNode('<div class="dragged-overview"></div> ').append(base.dom.createNode('<div class="overview-content"></div>').append(cloneOverview(element)));
      return hint;
    },
    placeholder: function (element) {
      var height = element.height();
      var width = element.width();

      return element.clone().empty().css({
        "opacity": 0.3,
        "border": "1px dashed #000000",
        "height": height + "px",
        "width": width + "px"
      });
    },
    start: function (e) {
      var overviewOffset = overview.offset(),
        overviewWidth = overview.width(),
        overviewHeight = overview.height(),
        hintWidth = hint.width(),
        hintHeight = hint.height();

      scrollOnDragInterval = setInterval(function () {
        scrollOnDragTimer.call(this, hint, {
          left: hint.offset().left - overviewOffset.left,
          top: hint.offset().top - overviewOffset.top
        }, hintWidth, hintHeight, overview, overviewWidth, overviewHeight);
      }, DRAG_INTERVAL);
    },
    change: function (e) {
      const arrSortedOverviews = e.item.parent().children().toArray().map(function (item, i) {
        if (item.id.indexOf('-') < 0) return '-1';
        return item.id.split('-')[1];
      });

      require('core/managers/module').saveModulesPreferences();
    },
    end: function () {
      clearInterval(scrollOnDragInterval);
    }
  });
}

function hasError(state) {
  if (!state.moduleStates) {
    return false;
  }

  return Object.keys(state.moduleStates).some(key => state.moduleStates[key].error);
}

function composeTabTitle(state) {
  let text = state.text;
  if (state.itemCount > 0) {
    text += ` (${state.itemCount})`
  }

  let titleHtml = text;
  if (typeof state.openInNewWindowHandler === 'function') {

    titleHtml = `<span>${text}</span><img id="tabMinMaxIcon" class="tab-min-max-icon" src="${iconService.getGeneralIcon('overviews', 'show_in_mainview')}">`
    /*
      We use setTimeout here because we do not append this html here, but in the Tab.js where we call this function
      and we can add / remove event listeners only after the html created here is appended to the document
    */
    setTimeout(() => {
      const minMaxElem = document.getElementById('tabMinMaxIcon');
      minMaxElem.removeEventListener('click', state.openInNewWindowHandler)
      minMaxElem.addEventListener('click', state.openInNewWindowHandler)
    }, 0);
  }

  if (hasError(state)) {
    titleHtml = `<img src="${iconService.getGeneralIcon('status', 'error_small')}"><span>${text}</span>`;
  }

  return {
    titleHtml,
    tooltip: text
  };
}

class SecondaryTabStrip extends BaseTabStrip {
  constructor(containerId) {
    super(containerId);

    this.unplannedTab = this.appendTab({
      text: translate('Unplanned'),
      content: '<div id="unplanned"></div>'
    }, composeTabTitle);
    this.unplannedTab.hide();

    this.overviewsTab = this.appendTab({
      text: translate('Overviews'),
      content: '<div id="overview"><div class="overview-content"></div></div>'
    }, composeTabTitle);

    this.uploadTab = this.appendTab({
      text: translate('Upload'),
      content: '<div id="upload"></div>'
    }, composeTabTitle);
    this.uploadTab.hide();

    sortableOverviews(this.getTabStripElement());
  }

  getUnplannedTab() {
    return this.unplannedTab;
  }

  getOverviewsTab() {
    return this.overviewsTab;
  }

  getUploadTab() {
    return this.uploadTab;
  }

}

module.exports = SecondaryTabStrip;