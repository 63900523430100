/**
 * @fileOverview Implements the facade pattern and set up
 * a standard interface for modules.
 * This is a subset of the core functionality, as well as
 * boilerplate functionality for modules.
 * @author sergey
 * */


define(['core', './icons', './request', './colors', './dialog', './jsUtils', './appUtils', './sorting',
  'core/managers/views'],
  function (core, icons, request, colors, dialog, jsUtils, appUtils, sorting, viewManager) {
    'use strict';

    /**
     * @namespace sandbox
     */
    var sandbox = {
      jsUtils: jsUtils,
      appUtils: appUtils,
      colors: colors,
      icons: icons,
      dialog: dialog,
      sorting: sorting,
      clipboard: core.service.clipboard,
      localization: core.service.localization,
      pubsub: core.manager.pubsub,
      preferences: core.manager.preferences
    };

    /**
     * Performs login with specified credentials
     * @memberOf sandbox
     * @param {string} username Name of a user
     * @param {string} password
     */
    sandbox.login = function (username, password, updatedPassword, oldPassword) {
      core.manager.auth.login.apply(core, arguments);
    };

    /**
     * Perform logout
     * @function logout
     * @memberOf sandbox
     */
    sandbox.logout = function () {
      core.manager.auth.logout();
    };

    //TODO: warning: overriding the Object.observe
    sandbox.observe = function (O, callback) {
      core.worker.observer.observe(O, callback);
    };
    sandbox.unobserve = function (O, callback) {
      core.worker.observer.unobserve(O, callback);
    };

    sandbox.getModuleInstance = function(moduleId) {
      core.manager.module.getModuleById.apply(core.manager.module, arguments);
    };

    /**
     * Reload coretex module
     * @memberOf sandbox
     * @param {string} moduleId             module Id
     */
    sandbox.reloadModule = function (moduleId) {
      core.manager.module.reloadModule.apply(core.manager.module, arguments);
    };


    /**
     * Function getSelectedMainModuleId returns the id of the main selected module (which is the visible module).
     * @memberOf sandbox
     * @returns {number} moduleId
     */
    sandbox.getSelectedMainModuleId = function () {
      return core.manager.module.getSelectedMainModuleId();
    };


    /**
     * Register Service Name
     * @memberOf sandbox
     * @param {string} serviceName
     * @param {object} module
     */
    sandbox.register = function (serviceName, module) {
      core.service[serviceName].registerModule(module);
    };

    /**
     * Get Service
     * @memberOf sandbox
     * @param {string} serviceName
     */
    sandbox.getService = function (serviceName) {
      return core.service[serviceName];
    };


    /**
     * Refers to dom property base worker {@link base:dom}
     * @memberOf sandbox
     */
    sandbox.dom = core.manager.base.dom;

    /**
     * Refers to data property base worker {@link base:dom}
     * @memberOf sandbox
     */
    sandbox.async = core.manager.base.data;

    /**
     * @namespace sandbox:events
     * @memberOf sandbox
     */
    sandbox.events = {
      /**
       * Register dom event listener
       * @memberOf sandbox:events
       * @param {string} selector DOM selector
       * @param {string} event Name of the event
       * @param {object} context This value in callback
       * @param {function} callback Callback function
       */
      listen: function (selector, event, context, callback) {
        core.manager.base.events.listen(selector, event, context, callback);
      },
      /**
       * Remove dom event listener
       * @memberOf sandbox:events
       * @param {string} selector DOM selector
       * @param {string} event Name of the event
       * @param {function} callback Callback function
       */
      unlisten: function (selector, event, callback) {
        core.manager.base.events.unlisten(selector, event, callback);
      }
    };

    /**
     * @namespace sandbox:request
     * @memberOf sandbox
     */
    sandbox.request = request;

    /**
     * @namespace sandbox:shortcut
     * @memberOf sandbox
     */
    sandbox.shortcut = {
      /**
       * External function provided for the rest of the application
       * to be able to register shortcuts
       * @memberOf sandbox:shortcut
       * @param  {string}   combo    Shortcut combination without any delimiters
       * @param  {string}   selector    Owner of the shortcut - module's element property
       * @param  {Function} callback Function to be called when shortcut is identified
       * @param  {Object}   context
       */
      register: function (combo, selector, callback, context) {
        core.manager.shortcut.register.apply(core, arguments);
      }
    };

    return sandbox;

  });
