/**
 * Created by elad on 10/20/2015.
 */
define(['sandbox', 'ember', 'text!../templates/main.hbs', 'TableWidget'], function (sandbox, Ember, Template, TableWidget) {
  'use strict';

  function getTableConfig() {
    return [
      {title: "Name", field: "name", width: '90%', editable: false},
      {title: "Activate", field: "", width: '10%', render: renderActivate, editable: false, handlers: [{
        event: 'click',
        execute: activateTemplateHandler.bind(this)
      }]
      }
    ];
  }

  function activateTemplateHandler(template) {
    var activateAction = this.module.viewActions.findBy('actionClass', 'ActivateActivationTemplateAction');
    if (activateAction !== void 0) {
      activateAction.execute({nwid: template.nwid, folder: this.model.nwid});
    }
  }

  function renderActivate() {
    return '<img class="content-centered" src="' + sandbox.icons.getModuleIcon("activationTemplates", "activate") + '" title="Activate Template"/>';
  }

  return Ember.View.extend({
    template: Ember.Handlebars.compile(Template),

    didInsertElement: function() {
      var templates = this.model.templates;
      var table = new TableWidget({
        element: this.$('.templates-table')[0],
        container: this,
        data: templates,
        columns: getTableConfig.call(this),
        columnReorder: false,
        contentHeight: templates.length
      });
      //this.controller.module.selection.applyTableSelection(this.controller.module, table);
    },
  });
});