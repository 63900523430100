import React from 'react';

export default class extends React.Component {

  constructor(props) {
    super(props);
  }

  renderPolicyLink = (policyLink, index) => {

    const { linkStyle } = this.props;

    return <div
      className='crtx-login-policy-link'
      key={index}
    >
      <a
        style={linkStyle}
        href={`/custom/assets/${policyLink.file}`}
        target='_blank'>{policyLink.name}
      </a>
    </div>;
  };


  render() {
    const { style, store } = this.props;
    const { policiesLinks } = store.getState();

    return <div className='crtx-login-policies-links' style={style}>
      {policiesLinks.map(this.renderPolicyLink)}
    </div>;
  }
}
