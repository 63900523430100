import React from 'react';
import Mustache from 'mustache';
import StyleConverter from '../styleConverter';
import colorsManager from '../managers/colorsManager';
import componentsStyleMap from '../models/componentstylemap';
import TextComponent from './elements/text/textcomponent';
import Version from './elements/text/version';
import SeparationBox from './elements/separationsBox/separationbox';
import Separations from './elements/separations/separations';
import SeparationsProgress from './elements/separationsProgress/separationsProgress';
import StatusIcon from './elements/statusIcon/statusicon';
import Indicator from './elements/indicator/indicator';
import PagesThumbnail from './elements/pagesThumbnail/pagesThumbnail';
import { AdditionalStatuses } from './elements/additionalStatuses/additionalStatuses';
import { getMainStatus } from 'utilities/statuses';

const styleConverter = new StyleConverter(componentsStyleMap);

export default class BirdeyeElement extends React.Component {
  elementStyle = (definition, model) => {
    if (typeof definition === 'undefined' && definition === null) {
      return undefined;
    }

    return styleConverter.toStyle(definition, model);
  };

  textElement = (model, definition, point, parentsStyle, style) => {
    var className = model.ignore === true ? 'ignored-text' : '';
    var parentBakground = parentsStyle.find(function (style) {
      return typeof style !== 'undefined' && typeof style.background !== 'undefined' && style.background !== '';
    });
    if (typeof style.color === 'undefined') {
      style.color = typeof parentBakground !== 'undefined' ?
        colorsManager.getContrastColor(colorsManager.toRGB(parentBakground.background)) :
        colorsManager.black;
    }

    return <TextComponent point={point} style={style} className={className}>
      {Mustache.render(definition.value, model)}
    </TextComponent>;
  };

  version = (model, definition, point, parentsStyle, style) => {
    var parentBakground = parentsStyle.find(function (style) {
      return typeof style !== 'undefined' && typeof style.background !== 'undefined' && style.background !== '';
    }),
      content = model.pageContent || model.formContent,
      version = content.externalVersion;
    if (typeof style === 'undefined') {
      style = {};
    }
    if (typeof style.color === 'undefined') {
      style.color = typeof parentBakground !== 'undefined' ?
        colorsManager.getContrastColor(colorsManager.toRGB(parentBakground.background)) :
        colorsManager.black;
    }

    return <Version point={point} style={style} version={content.externalVersion}>
      {Mustache.render(definition.value, content)}
    </Version>;
  };

  separationBox = (model, definition, point, style) => {
    var separations = model.separations || [],
      style = this.elementStyle(definition, model);

    return <SeparationBox point={point} separations={separations} style={style} />;
  };

  separations = (model, style) => {
    var separations = model.separations;

    return <Separations separations={separations} style={style} model={model} />;
  };

  separationProgress = (model, definition, style) => {
    var separations = model.separations,
      status = getMainStatus(model);
    return <SeparationsProgress separations={separations} status={status} definition={definition} style={style} />;
  };

  status = (model, style) => {
    const status = getMainStatus(model) || {};

    return <StatusIcon status={status} style={style} />;
  };

  indicator = (model, definition, point, style) => {
    return <Indicator model={model} definition={definition} flow={point.flow} style={style} />;
  };

  additionalStatuses = model => {
    return <AdditionalStatuses item={model} />;
  };

  thumbnail = (model, definition, containerSize) => {
    var thumbnailSize = [containerSize[0] - 110, containerSize[1] - 54];

    return <PagesThumbnail thumbnail={model} thumbnailSize={thumbnailSize} definition={definition} />;
  };

  render() {
    var {
      point,
      model,
      definition,
      //element,
      containerSize,
      parentsStyle
    } = this.props,
      style = this.elementStyle(definition, model),
      type = typeof definition.type === 'string' ? definition.type.trim().toLowerCase() : undefined;

    if (model.ignore === true && type !== 'text') {
      return null;
    }

    switch (type) {
      case 'text':
        return this.textElement(model, definition, point, parentsStyle, style);
        break;
      case 'version':
        return this.version(model, definition, point, parentsStyle, style);
        break;
      case 'separation-box':
        return this.separationBox(model, definition, point, style);
        break;
      case 'separations':
        return this.separations(model, style);
        break;
      case 'separations-progress':
        return this.separationProgress(model, definition, style);
        break;
      case 'status':
        return this.status(model, style);
        break;
      case 'indicator':
        return this.indicator(model, definition, point, style);
        break;
      case 'additional-statuses':
        return this.additionalStatuses(model);
        break;
      case 'thumbnail':
        return this.thumbnail(model, definition, containerSize);
        break;
      default:
        return <div></div>;
    }

    return <div></div>;
  }
}