import React, { Children, useRef, useEffect, useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { noop } from 'base/jsUtils';
import { classNames } from 'utilities/classNames';
import { DEFAULT_MAX_ZINDEX, findMaxZIndex, adjustFakeKendoWindowZIndex, setFakeKendoWindowZIndex } from 'utilities/zindex';
import Draggable from './Draggable';
import DialogTitleBar from './DialogTitleBar';
import DialogButtonsBar from './DialogButtonsBar';
import ResizeHandlers from './ResizeHandlers';
import {
  areNumbersEqual,
  centerElementInViewport,
  getElementRect,
  getViewportRect,
  projectPointOntoRect
} from './utils';

const DEFAULT_MIN_WIDTH = 150;
const DEFAULT_MIN_HEIGHT = 120;
const POSITION_OFFSET = 10;

const dialogs = {};
let lastId = 0;

const getNextId = () => ++lastId;

const register = (info) => {
  const id = info.id;
  dialogs[id] = info;

  return function unregister(document) {
    delete dialogs[id];

    adjustFakeKendoWindowZIndex(document);
  };
};

const getDialogById = (id) => {
  return dialogs[id];
};

const adjustDialogPosition = (left, top) => {
  const eps = 5;
  const positions = Object.values(dialogs).map(({ left, top }) => ({ left, top }));

  const isInSamePosition = (left, top) => positions.some(p => areNumbersEqual([p.left, p.top], [left, top], eps));

  while (isInSamePosition(left, top)) {
    left += POSITION_OFFSET;
    top += POSITION_OFFSET;
  }

  return [left, top];
};

const Dialog = (
  {
    children,
    win,
    modal = false,
    title,
    autoFocus = true,
    initialLeft,
    initialTop,
    initialWidth,
    initialHeight,
    minWidth = DEFAULT_MIN_WIDTH,
    minHeight = DEFAULT_MIN_HEIGHT,
    className,
    style,
    contentClassName,
    onClose,
    onChangeBounds = noop
  }) => {

  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [width, setWidth] = useState(initialWidth);
  const [height, setHeight] = useState(initialHeight);
  const [storedRect, setStoredRect] = useState(null);
  const [zIndex, setZIndex] = useState(DEFAULT_MAX_ZINDEX);
  const [visible, setVisible] = useState(false);

  const dialogRef = useRef(null);
  const dragPointRef = useRef(null);
  const idRef = useRef();

  useEffect(() => {
    const document = getDocument();
    const activeElement = document.activeElement;

    let {
      left: x,
      top: y,
      width: w,
      height: h
    } = centerElementInViewport(dialogRef.current, modal ? null : getDialogContainer(),
      { minWidth, minHeight, left: initialLeft, top: initialTop, width: initialWidth, height: initialHeight });

    [x, y] = adjustDialogPosition(x, y);
    setLeft(x);
    setTop(y);

    if (modal) {
      // By default, the modal dialog is aligned and sized in the center of the viewport by its
      // parent element which has fixed position and flex layout.
      // To allow the dialog to adjust its size depending on content we should not fix the size if it fits the viewport.

      let { width: w0, height: h0 } = dialogRef.current.getBoundingClientRect();
      !areNumbersEqual(w0, w, 1) && setWidth(w);
      !areNumbersEqual(h0, h, 1) && setHeight(h);
    } else {
      setWidth(w);
      setHeight(h);
    }

    idRef.current = getNextId();
    const unregister = register({ left: x, top: y, id: idRef.current });

    setMaxZIndex(findMaxZIndex(document) + 1);
    setVisible(true);

    return () => {
      activeElement && activeElement.focus();
      unregister(document);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (visible) {
      autoFocus && dialogRef.current.focus();
    }
  }, [visible]);

  const childArray = Children.toArray(children);

  const getDocument = () => {
    return (win || window).document;
  };

  const getDialogContainer = () => {
    return getDocument().body;
  };

  const setMaxZIndex = (zIndex) => {
    const dialog = getDialogById(idRef.current);
    if (dialog) {
      dialog.zIndex = zIndex;
    }

    setFakeKendoWindowZIndex(getDocument(), zIndex);
    setZIndex(zIndex);
  };

  const maximize = () => {
    setStoredRect({ left, top, width, height });

    const { left: x, top: y, width: w, height: h } = getViewportRect(getDocument());
    modal ? setLeft(0) : setLeft(x);
    modal ? setTop(0) : setTop(y);
    setWidth(w);
    setHeight(h);
  };

  const restore = () => {
    if (!storedRect) {
      return;
    }

    const { left: x, top: y, width: w, height: h } = storedRect;
    setLeft(x);
    setTop(y);
    setWidth(w);
    setHeight(h);

    setStoredRect(null);
  };

  const bringToFront = () => {
    const maxZIndex = findMaxZIndex(getDocument());
    if (zIndex !== maxZIndex) {
      setMaxZIndex(maxZIndex + 1);
    }
  };

  const handleMouseDown = () => {
    !modal && bringToFront();
  };

  const handleKeyDown = e => {
    if (e.code === 'Escape' && onClose) {
      e.preventDefault();
      onClose();
    } else if (e.code === 'Tab' && dialogRef.current && getDocument()) {
      const selectors = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
      const focusableElements = dialogRef.current.querySelectorAll(selectors);
      const firstFocusableElement = focusableElements[0];
      const lastFocusableElement = focusableElements[focusableElements.length - 1];
      if (e.shiftKey) {
        if (getDocument().activeElement === firstFocusableElement) {
          lastFocusableElement.focus();
          e.preventDefault();
        }
      } else if (getDocument().activeElement === lastFocusableElement) {
        firstFocusableElement.focus();
        e.preventDefault();
      }
    }
  };

  const handleDrag = (e, { end }) => {
    if (!dialogRef.current || storedRect) {
      return;
    }

    let {
      left: x,
      top: y,
      width: w,
      height: h
    } = getElementRect(dialogRef.current, modal ? null : getDialogContainer());

    let [pageX, pageY] = projectPointOntoRect(e.pageX, e.pageY, getViewportRect(getDocument()));

    if (!dragPointRef.current) {
      dragPointRef.current = { x: pageX, y: pageY };

      // Update the dialog box width to prevent the dialog from shrinking near
      // the right edge of the viewport in case no initial width has been specified
      setWidth(w);
    }

    x = x + (pageX - dragPointRef.current.x);
    y = y + (pageY - dragPointRef.current.y);

    setLeft(x);
    setTop(y);

    dragPointRef.current = end ? null : { x: pageX, y: pageY };

    Object.assign(getDialogById(idRef.current), { left: x, top: y });

    onChangeBounds({ left: x, top: y, width: w, height: h, end });
  };

  const handleResize = (e, { direction, end }) => {
    if (!dialogRef.current || storedRect) {
      return;
    }

    let {
      left: x,
      top: y,
      width: w,
      height: h
    } = getElementRect(dialogRef.current, modal ? null : getDialogContainer());

    let [pageX, pageY] = projectPointOntoRect(e.pageX, e.pageY, getViewportRect(getDocument()));

    if (!dragPointRef.current) {
      dragPointRef.current = { x: pageX, y: pageY };
    }

    let dx = pageX - dragPointRef.current.x;
    let dy = pageY - dragPointRef.current.y;

    if (direction.indexOf('n') >= 0) { // north
      if (h - dy < minHeight) {
        dy = h - minHeight;
      }

      y += dy;
      h -= dy;
      pageY = dragPointRef.current.y + dy;
    }

    if (direction.indexOf('s') >= 0) { // south
      if (h + dy < minHeight) {
        dy = minHeight - h;
      }

      h += dy;
      pageY = dragPointRef.current.y + dy;
    }

    if (direction.indexOf('w') >= 0) { // west
      if (w - dx < minWidth) {
        dx = w - minWidth;
      }

      x += dx;
      w -= dx;
      pageX = dragPointRef.current.x + dx;
    }

    if (direction.indexOf('e') >= 0) { // east
      if (w + dx < minWidth) {
        dx = minWidth - w;
      }

      w += dx;
      pageX = dragPointRef.current.x + dx;
    }

    dragPointRef.current = end ? null : { x: pageX, y: pageY };

    setLeft(x);
    setTop(y);
    setWidth(w);
    setHeight(h);

    Object.assign(getDialogById(idRef.current), { left: x, top: y });

    onChangeBounds({ left: x, top: y, width: w, height: h, end });
  };

  const handleDoubleClick = () => {
    storedRect ? restore() : maximize();
  };

  const renderTitleBar = () => {
    return title ? <DialogTitleBar title={title} onClose={onClose} onDoubleClick={handleDoubleClick} /> : null;
  };

  const renderContent = () => {
    return childArray.filter(child => child && child.type !== DialogButtonsBar);
  };

  const renderButtonsBar = () => {
    return childArray.filter(child => child && child.type === DialogButtonsBar);
  };

  const renderDialog = () => (
    <div
      ref={dialogRef}
      tabIndex={-1}
      className='crtx-dialog'
      style={{
        left,
        top,
        width,
        height,
        zIndex: modal ? undefined : zIndex,
        visibility: visible ? 'visible' : 'hidden',
      }}
      onMouseDown={handleMouseDown}
      onKeyDown={handleKeyDown}
    >
      <Draggable onDrag={handleDrag}>
        {renderTitleBar()}
      </Draggable>

      <div className={classNames('crtx-dialog-content', contentClassName)}>
        {renderContent()}
      </div>

      {renderButtonsBar()}

      <ResizeHandlers onResize={handleResize} />
    </div>
  );

  const renderDialogWrapper = () => {
    if (modal) {
      return (
        <div
          tabIndex={-1}
          className={classNames('crtx-dialog-wrapper', className)}
          style={{ ...style, zIndex }}
        >
          <div className='crtx-overlay' />
          {renderDialog()}
        </div>
      );
    }


    return renderDialog();
  };

  return createPortal(renderDialogWrapper(), getDialogContainer());
};

Dialog.propTypes = {
  children: PropTypes.node,
  modal: PropTypes.bool,
  title: PropTypes.any,
  autoFocus: PropTypes.bool,
  initialLeft: PropTypes.number,
  initialTop: PropTypes.number,
  initialWidth: PropTypes.number,
  initialHeight: PropTypes.number,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
  win: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
  contentClassName: PropTypes.string,
  onClose: PropTypes.func,
  onChangeBounds: PropTypes.func,
};

export default Dialog;