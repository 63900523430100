/**
 * @name Windows Manager
 *
 *
 * @fileOverview Windows Manager is in charge of opening, closing and keeping
 * the list of all child windows of the root window.
 * The Windows Manager is also in charge of saving the windows preferences and loading them.
 *
 * For now the windowsManager is visible to all by assigning it as a global var inside window
 * so child windows could notify parents they have closed.
 * Creating a messaging manager between windows, might be a better solution.
 * TODO: If MessagingManager will be created, remove the windows Manager from window as a global var and communicate between windows via MessagingManager.
 *
 *
 * @author Guy Behar
 * @author Boris Svetlitsky
 */

import {
  getWindowRectanglesPreferences,
  saveWindowRectanglesPreferences,
  getPageViewPreferences
} from './preferences';
import { isPageView } from 'core/managers/views';
import browser from 'base/browser';
import { intersectRect, normalizeRect } from 'utilities/geometry';

let windowsInfo = [];
let windowRectangles = [];
let initialized = false;
let manageWindowsPermissionGranted = false;

function initRootPropertiesIfNeeded() {
  const rootWindow = getRootWindow();
  if (!initialized && rootWindow === window) {
    initialized = true;
    windowRectangles = getWindowRectanglesPreferences();
    rootWindow.addEventListener('beforeunload', onRootWindowUnload);
  }
}

function getRootWindow() {
  let rootWindow = window;
  while (rootWindow.opener) {
    rootWindow = rootWindow.opener;
  }

  return rootWindow;
}

function getWindowTarget(name, viewInfo = {}, event) {
  let target = name;

  const { nwid, rootId } = viewInfo;
  if (name !== '_blank') {
    if (isPageView(nwid)) {
      target = event?.shiftKey ? '_blank' : 'PageView';
    } else if (nwid && rootId) {
      target = `${nwid}-${rootId}`;
    }
  }

  return target;
}

function findWindowInfoByTarget(target) {
  return windowsInfo.find(winInfo => winInfo.target === target);
}

function findWindowInfo(win) {
  return windowsInfo.find(winInfo => winInfo.window === win);
}

function removeWindowInfo(winInfo) {
  const index = windowsInfo.indexOf(winInfo);
  if (index >= 0) {
    windowsInfo.splice(index, 1);
  }
}

function findWindowRect(viewInfo) {
  const { nwid, rootType } = viewInfo;

  return windowRectangles.find(r => r.nwid === nwid && r.rootType === rootType);
}

function updateWindowRectangles(winInfo) {
  if (!winInfo || winInfo.target === 'PageView') {
    return;
  }

  const { window: win, viewInfo: { nwid, rootType }, viewInfo } = winInfo;

  const winRect = {
    nwid,
    rootType,
    left: win.screenX,
    top: win.screenY,
    width: win.innerWidth,
    height: win.innerHeight
  };

  const wr = findWindowRect(viewInfo);
  if (wr) {
    Object.assign(wr, winRect);
  } else {
    windowRectangles.push(winRect);
  }
}

function savePreferences() {
  saveWindowRectanglesPreferences(windowRectangles);
}

function objectToFeatures(obj) {
  return Object.entries(obj).map(([key, value]) => `${key}=${value}`).join(',');
}

function getScreenRect() {
  return {
    left: 0,
    top: 0,
    width: (screen.availWidth || screen.width),
    height: (screen.availHeight || screen.height),
  };
}

function getWindowRect(target, featuresObj, viewInfo) {
  let rect;

  if (target !== '_blank') {
    if (isPageView(viewInfo.nwid)) {
      rect = getPageViewPreferences().windowRect;
    } else {
      const winRect = findWindowRect(viewInfo);
      if (winRect) {
        const { left, top, width, height } = winRect;
        rect = { left, top, width, height };
      } else {
        const { width, height } = featuresObj;
        if (width > 0 && height > 0) {
          const screen = getScreenRect();
          rect = { left: (screen.width - width) / 2, top: (screen.height - height), width, height };
        }
      }
    }
  }

  if (!rect) {
    rect = getScreenRect();
  }

  return rect;
}

function onRootWindowUnload() {
  closeAll();
}

function onWindowUnload(event) {
  const rootWindow = getRootWindow();
  if (rootWindow !== window && rootWindow.childWindowsManager?.handleWindowUnload) {
    rootWindow.childWindowsManager.handleWindowUnload(event.currentTarget);
  } else {
    handleWindowUnload(event.currentTarget);
  }
}

function handleWindowUnload(win) {
  const winInfo = findWindowInfo(win);
  if (winInfo) {
    updateWindowRectangles(winInfo);

    savePreferences();

    removeWindowInfo(winInfo);
  }
}

/**
 * Asks user for permission to use Window Management API.
 * Calling window.getScreenDetails() method will show a permission prompt that asks the user
 * whether the site may "Manage windows on all your displays".
 * After the user grants this permission, we'll be able to place a window on the second screen.
 *
 * @returns {Promise<boolean>}
 */
async function askForManageWindowsPermission() {
  let granted = false;

  try {
    if (window.getScreenDetails) {
      const screenDetails = await window.getScreenDetails();
      granted = true;
    }
  } catch {
    // Nothing
  }

  return granted;
}

async function open(url, name, featuresObj, viewInfo = {}, event) {
  if (!featuresObj.sourceWindow) {
    // remember the source window where the event is originated
    featuresObj.sourceWindow = event?.view || window;
  }

  initRootPropertiesIfNeeded();

  const rootWindow = getRootWindow();
  if (rootWindow !== window && rootWindow.childWindowsManager) {
    return rootWindow.childWindowsManager.open(url, name, featuresObj, viewInfo, event);
  }

  // Note: The following code should run only in the context of the root window
  let result;

  const sourceWindow = featuresObj.sourceWindow;
  delete featuresObj.sourceWindow;

  const target = getWindowTarget(name, viewInfo, event);
  if (target !== '_blank') {
    const winInfo = findWindowInfoByTarget(target);
    if (winInfo) {
      // The window with the given target has been opened already.
      // In order to bring the window to front we should set focus to it.
      // This works if the focus is set from the opener window.
      // Our JavaScript code that opens new windows runs in the root window.
      // When the opener windows is not the root window the window.focus() may fail.

      if (winInfo.window.opener !== rootWindow) {
        console.log('=== windows.open() => Opener is not the root window');
      }

      winInfo.window.focus();
      winInfo.window._found = true;

      result = winInfo.window;
    }
  }

  const checkIfCovers = lightWindow => {
    const lightWindowRect = normalizeRect(lightWindow, 'screenLeft', 'screenTop', 'outerWidth', 'outerHeight');
    const mainWindowRect = normalizeRect(window, 'screenLeft', 'screenTop', 'outerWidth', 'outerHeight');
    const rect = intersectRect(mainWindowRect, lightWindowRect);
    return (window.outerHeight - rect.height < 20) && (window.outerWidth - rect.width < 20);
  };

  const reduceWindowSize = win => win.resizeTo(win.outerWidth - 20, win.outerHeight - 20);

  if (!result) {
    let rect = getWindowRect(target, featuresObj, viewInfo);

    Object.assign(featuresObj, rect);

    if (!manageWindowsPermissionGranted) {
      manageWindowsPermissionGranted = await askForManageWindowsPermission();
    }

    // open the new window using the source window where the event is originated to avoid the popup blocker
    const win = sourceWindow.open(url, target, objectToFeatures(featuresObj));

    result = new Promise(resolve => {
      win.addEventListener('load', () => {
        // add 'unload' Event Listener only after the window has been loaded already
        win.addEventListener('beforeunload', onWindowUnload);

        windowsInfo.push({ window: win, name, target, viewInfo });

        if (name === 'light_window' && browser.isFirefox()) {
          const cover = checkIfCovers(win);
          if (cover) {
            reduceWindowSize(win);
          }
          win.addEventListener('resize', () => {
            const cover = checkIfCovers(win);
            if (cover) {
              reduceWindowSize(win);
            }
          });
        }
        resolve(win);
      });
    });
  }

  return result;
}

function closeAll() {
  windowsInfo.forEach(winInfo => {
    updateWindowRectangles(winInfo);
  });

  savePreferences();

  const windowsInfoTemp = [...windowsInfo];
  windowsInfo = [];
  windowsInfoTemp.forEach(winInfo => {
    // close each window without saving Preferences (windowsInfo is empty so
    // savePreferences() is not called from the handleWindowUnload() function)
    winInfo.window.close();
  });
}

window.childWindowsManager = {
  open,
  handleWindowUnload,
  closeAll,
};

module.exports = window.childWindowsManager;
