import React, { Component } from 'react';
import PropTypes from 'prop-types';
import base from 'base';
import { translate } from 'core/services/localization';
import Page from './Page';
import Cell from './Cell';
import TextInput from 'components/common/inputs/TextInput';
import Dropdown from 'components/common/dropdown/Dropdown';
import { Button } from 'widgets/ReactComponents/src/index';
import { ComboboxWithButtons } from 'components/common/floating';
import toastService from 'core/services/toastService';
import sandbox from 'sandbox';
import { restPost, restDelete } from 'core/managers/rest2';
import SimpleForm from 'widgets/SimpleForm/src/index';

const { utils: futils } = SimpleForm;

const PAGE_WIDTH = 36;
const PAGE_HEIGHT = 50;
const PAGE_ROW_GAP = 5;
const PAGE_COLUMN_GAP = 5;

//if you have another AudioContext class use that one, as some browsers have a limit
let audioCtx;

//All arguments are optional:

//duration of the tone in milliseconds. Default is 500
//frequency of the tone in hertz. default is 440
//volume of the tone. Default is 1, off is 0.
//type of tone. Possible values are sine, square, sawtooth, triangle, and custom. Default is sine.
//callback to use on end of tone
const beep = (duration, frequency, volume, type, callback) => {
  if (!base.browser.isChrome()) {
    return;
  }

  if (!audioCtx) {
    audioCtx = new (window.AudioContext || window.webkitAudioContext || window.audioContext);
  }

  const oscillator = audioCtx.createOscillator();
  const gainNode = audioCtx.createGain();

  oscillator.connect(gainNode);
  gainNode.connect(audioCtx.destination);

  if (volume) {
    gainNode.gain.value = volume;
  }

  if (frequency) {
    oscillator.frequency.value = frequency;
  }

  if (type) {
    oscillator.type = type;
  }

  if (callback) {
    oscillator.onended = callback;
  }

  oscillator.start();
  setTimeout(() => {
    oscillator.stop();
  }, (duration ? duration : 300));
};

const removeIllegalFileChars = (fileName) => {
  if (!fileName) {
    return fileName;
  }

  return fileName.replace(/[\\\/:\*\?"<>\|]/g, '');
};

class Nup extends Component {
  constructor(props) {
    super(props);

    this.pageRefs = {};
    this.pageByCellRefs = {};

    const pmiTemplates = props.pmiTemplates || [];
    const selectedPmiTemplate = pmiTemplates.find(t => t.name === props.selectedPmiTemplateName);
    const { forms, pages } = this.convertFromPmiTemplate(selectedPmiTemplate, props.pages);

    this.state = {
      forms,
      formCount: forms.length,
      formSizeOptions: props.formSizeOptions || [],
      formattedFormSizeOptions: this.formatSizeOptions(props.formSizeOptions),
      pages,
      selectedPageNumber: props.selectedPageNumber,
      pmiTemplates,
      selectedPmiTemplateName: props.selectedPmiTemplateName || ''
    };
  }

  getOutput() {
    const { pmiTemplates, selectedPmiTemplateName } = this.state;

    return {
      pmiTemplates,
      selectedPmiTemplateName
    };
  }

  componentDidMount() {
    //window.addEventListener('keydown', this.move);
  }

  componentWillUnmount() {
    //window.removeEventListener('keydown', this.move);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.dropped) {
      return;
    }

    const formCount = Math.min(this.state.forms.length, prevState.forms.length);
    for (var i = 0; i < formCount; i++) {
      var cells = this.state.forms[i].cells;
      var prevCells = prevState.forms[i].cells;
      var cellCount = Math.min(cells.length, prevCells.length);
      var oldNode, newNode;
      for (var j = 0; j < cellCount; j++) {
        var cell = cells[j];
        var prevCell = prevCells[j];
        if (cell.pageNumber > 0 && !(prevCell.pageNumber > 0)) { // page assigned
          newNode = this.pageByCellRefs[cell.id];
          oldNode = this.pageRefs[cell.pageNumber];
          this.animateTransform(oldNode, newNode);
        } else if (!(cell.pageNumber > 0) && prevCell.pageNumber > 0) { // page unassigned
          newNode = this.pageRefs[prevCell.pageNumber];
          oldNode = this.pageByCellRefs[prevCell.id];
          this.animateTransform(oldNode, newNode);
        }
      }
    }
  }

  animateTransform(oldNode, newNode) {
    if (!oldNode || !newNode) {
      return;
    }

    const oldRect = oldNode.getBoundingClientRect();
    const newRect = newNode.getBoundingClientRect();
    const dx = oldRect.left - newRect.left;
    const dy = oldRect.top - newRect.top;

    requestAnimationFrame(() => {
      // Before the DOM paints, Invert it to its old position
      newNode.style.transform = `translate(${dx}px, ${dy}px)`;
      // Ensure it inverts it immediately
      newNode.style.transition = 'transform 0s';

      requestAnimationFrame(() => {
        // In order to get the animation to play, we'll need to wait for
        // the 'invert' animation frame to finish, so that its inverted
        // position has propagated to the DOM.
        // Then, we just remove the transform, reverting it to its natural
        // state, and apply a transition so it does so smoothly.
        newNode.style.transform = '';
        newNode.style.transition = 'transform 200ms ease-out';
      });
    });
  }

  convertFromPmiTemplate(pmiTemplate, pages) {
    const result = {
      forms: [],
      pages: []
    };

    if (pages) {
      result.pages = pages.map(p => ({ ...p, assignedCount: 0 }));
    }

    if (pmiTemplate && pmiTemplate.sheets) {
      result.forms = pmiTemplate.sheets.map((s, index) => {
        var form = this.initForm(index, s.rows, s.columns);
        s.pages.forEach(p => {
          if (p.number > 0 && p.number <= result.pages.length) {
            var idx = (p.row - 1) * s.columns + (p.column - 1);
            if (idx >= 0 && idx < form.cells.length) {
              form.cells[idx].pageNumber = p.number;
              result.pages[p.number - 1].assignedCount++;
            }
          }
        });

        return form;
      });
    }

    return result;
  }

  convertToPmiTemplate(name, forms, pages) {
    const pmiTemplate = {
      name,
      numPages: 0,
      sheets: []
    };

    if (!forms || !pages) {
      return pmiTemplate;
    }

    pmiTemplate.numPages = pages.length;
    for (var i = 0; i < forms.length; i++) {
      var form = forms[i];
      var sheet = {
        number: i + 1,
        rows: form.rows,
        columns: form.columns,
        pages: []
      };
      pmiTemplate.sheets.push(sheet);

      for (var j = 0; j < form.cells.length; j++) {
        var cell = form.cells[j];
        if (cell.pageNumber > 0) {
          sheet.pages.push({
            row: cell.row + 1,
            column: cell.column + 1,
            number: cell.pageNumber
          });
        }
      }
    }

    return pmiTemplate;
  }

  formatSizeOptions(options) {
    if (!options) {
      return [];
    }

    return options.map((o) => {
      const value = `${o.rows}x${o.columns}`;
      const text = `${o.rows} x ${o.columns}`;
      return { value, text };
    });
  }

  calcCellsRect(form) {
    const left = 0;
    const top = 0;
    const width = form.columns * (PAGE_WIDTH + PAGE_COLUMN_GAP) - PAGE_COLUMN_GAP;
    const height = form.rows * (PAGE_HEIGHT + PAGE_ROW_GAP) - PAGE_ROW_GAP;

    return { left, top, width, height };
  }

  calcCellRect(cell) {
    const left = cell.column * (PAGE_WIDTH + PAGE_COLUMN_GAP);
    const top = cell.row * (PAGE_HEIGHT + PAGE_ROW_GAP);

    return { left, top, width: PAGE_WIDTH, height: PAGE_HEIGHT };
  }

  pixelify(value) {
    if (typeof value === 'undefined' || value === null) {
      return value;
    }

    if (typeof value !== 'object') {
      return value + 'px';
    }

    const result = {};
    for (var key in value) {
      result[key] = value[key] + 'px';
    }

    return result;
  }

  getPageIndex(pageNumber) {
    return pageNumber > 0 ? pageNumber - 1 : -1;
  }

  getPagePath(pageNumber) {
    var result;
    const index = this.getPageIndex(pageNumber);
    if (index >= 0) {
      result = `pages.${index}`;
    }

    return result;
  }

  getPage(pageNumber) {
    const pagePath = this.getPagePath(pageNumber);

    return pagePath ? futils.get(this.state, pagePath) : null;
  }

  getNextSelectedPage() {
    const { pages, selectedPageNumber } = this.state;
    const index = this.getPageIndex(selectedPageNumber);
    if (index < 0) {
      return;
    }

    var result;
    for (var j = 1; j < pages.length; j++) {
      var page = pages[(index + j) % pages.length];
      if (page.assignedCount <= 0) {
        result = page;
        break;
      }
    }

    return result;
  }

  handleSelectPage = (e, page) => {
    this.setState({
      selectedPageNumber: page ? page.number : 0,
      dropped: false
    });
  };

  handleCellClick(event, formIndex, cellIndex) {
    //console.log("###handleCellClick()");
    const { forms, selectedPageNumber } = this.state;
    const cell = forms[formIndex].cells[cellIndex];

    var newState = this.state;
    if (!(cell.pageNumber > 0) && selectedPageNumber > 0) {
      const pagePath = this.getPagePath(selectedPageNumber);
      const page = futils.get(newState, pagePath);
      if (page) {
        newState = this.assignPageToCell(newState, pagePath, formIndex, cellIndex);
        if (event.ctrlKey || event.metaKey) {
          newState = this.selectPage(newState, this.getNextSelectedPage());
        }
      }
    }

    this.setState({ ...newState, dropped: false });
  }

  handleRomovePageClick(event, formIndex, cellIndex) {
    //console.log("###handleRomovePageClick()");

    event.stopPropagation();

    var newState = this.state;
    newState = this.unassignPageFromCell(newState, formIndex, cellIndex);

    this.setState({ ...newState, dropped: false });
  }

  selectPage(state, page) {
    return { ...state, selectedPageNumber: page ? page.number : 0 };
  }

  assignPageToCell(state, pagePath, formIndex, cellIndex) {
    const { pages } = this.state;
    var newState = state;

    const cell = newState.forms[formIndex].cells[cellIndex];
    const page = futils.get(newState, pagePath);
    if (page && page.number > 0 && page.number <= pages.length) {
      newState = futils.update(newState, `forms.${formIndex}.cells.${cellIndex}`, { ...cell, pageNumber: page.number });
      newState = futils.update(newState, `${pagePath}.assignedCount`, page.assignedCount + 1);
    }

    return newState;
  }

  unassignPageFromCell(state, formIndex, cellIndex) {
    var newState = state;

    const cell = newState.forms[formIndex].cells[cellIndex];
    if (cell.pageNumber > 0) {
      newState = futils.update(newState, `forms.${formIndex}.cells.${cellIndex}`, { ...cell, pageNumber: 0 });
      const pagePath = this.getPagePath(cell.pageNumber);
      const page = futils.get(newState, pagePath);
      newState = futils.update(newState, `${pagePath}.assignedCount`, page.assignedCount - 1);
    }

    return newState;
  }

  unassignPagesFromForm(state, formIndex) {
    var newState = state;

    const form = newState.forms[formIndex];
    if (form) {
      for (var i = 0; i < form.cells.length; i++) {
        newState = this.unassignPageFromCell(newState, formIndex, i);
      }
    }

    return newState;
  }

  unassignAllPages(state) {
    var newState = state;

    const pages = [...this.state.pages];
    for (var i = 0; i < pages.length; i++) {
      pages[i] = { ...pages[i], assignedCount: 0 };
      newState = { ...newState, pages };
    }

    return newState;
  }

  initForms(n, rows, columns) {
    var forms = [];
    for (var i = 0; i < n; i++) {
      forms.push(this.initForm(i, rows, columns));
    }

    return forms;
  }

  initForm(formIndex, rows, columns) {
    //*TEST
    // if (formIndex === 1) {
    //   rows = 1;
    //   columns = 2;
    // } else {
    //   rows = 2;
    //   columns = 3;
    // }

    const form = {
      id: (formIndex + 1).toString(),
      rows: rows,
      columns: columns,
      cells: this.initCells(formIndex, rows, columns)
    };
    return form;
  }

  initCells(formIndex, rows, columns) {
    const cells = [];
    for (var i = 0; i < rows; i++) {
      for (var j = 0; j < columns; j++) {
        var id = `c${formIndex}_${i}_${j}`;
        cells.push({
          id,
          row: i,
          column: j
        });
      }
    }

    return cells;
  }

  tryParseJSON(jsonString) {
    try {
      const o = JSON.parse(jsonString);

      // Handle non-exception-throwing cases:
      // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
      // but... JSON.parse(null) returns null, and typeof null === "object",
      // so we must check for that, too. Thankfully, null is falsey, so this suffices:
      if (o && typeof o === 'object') {
        return o;
      }
    } catch (e) {
    }

    return null;
  }

  handleDrop(event, formIndex, cellIndex) {
    //console.log('###handleDrop()', event.dataTransfer.getData('text'));
    const data = this.tryParseJSON(event.dataTransfer.getData('text'));
    if (data && data.type === 'page' && data.number > 0) {
      const cell = this.state.forms[formIndex].cells[cellIndex];
      if (!(cell.pageNumber > 0)) {
        var newState = this.state;
        const pagePath = this.getPagePath(data.number);
        const page = futils.get(newState, pagePath);
        newState = this.assignPageToCell(newState, pagePath, formIndex, cellIndex);
        newState = this.selectPage(newState, page);
        this.setState({ ...newState, dropped: true });
      }
    }
  }

  setPageByCellRef = (node, cell) => {
    this.pageByCellRefs[cell.id] = node;
  };

  setPageRef = (node, page) => {
    this.pageRefs[page.number] = node;
  };

  handleFormSizeSelect = (value, formIndex) => {
    //console.log('###handleFormSizeSelect()', value, formIndex);

    var newState = this.state;
    newState = this.unassignPagesFromForm(newState, formIndex);
    const size = value.split('x');
    const rows = parseInt(size[0]);
    const columns = parseInt(size[1]);
    const form = this.state.forms[formIndex];
    const cells = this.initCells(formIndex, rows, columns);
    newState = futils.update(newState, `forms.${formIndex}`, { ...form, cells, rows, columns });

    //newState = this.replaceFormLayout(newState, formIndex, layoutId);

    this.setState(newState);
  };

  renderPage(page) {
    const { selectedPageNumber } = this.state;

    return (
      <Page
        key={page.number}
        page={page}
        selectedPageNumber={selectedPageNumber}
        onClick={this.handleSelectPage}
        refCallback={this.setPageRef}
        draggable={true}
      >
      </Page>
    );
  }

  renderPages() {
    return (
      <div className='pages'>
        {this.state.pages.map(this.renderPage, this)}
      </div>
    );
  }

  renderPagesPane() {
    return (
      <div className='pages-pane'>
        <div className='pages-pane-header'>
          {translate('Assign Pages')}
        </div>
        {this.renderPages()}
      </div>
    );
  }

  renderCell(formIndex, cellIndex) {
    const { forms, pages } = this.state;
    const cell = forms[formIndex].cells[cellIndex];
    const page = this.getPage(cell.pageNumber);

    const style = this.pixelify(this.calcCellRect(cell));
    if (page) {
      style.border = 'none';
    }

    return (
      <Cell
        key={cell.id}
        style={style}
        onClick={(e) => this.handleCellClick(e, formIndex, cellIndex)}
        onDrop={page ? null : (e, data) => this.handleDrop(e, formIndex, cellIndex)}
      >
        <Page
          page={page}
          hideAssignedCount={true}
          onRemoveClick={(e) => this.handleRomovePageClick(e, formIndex, cellIndex)}
          refCallback={node => this.setPageByCellRef(node, cell)}
        >
        </Page>
      </Cell>
    );
  }

  renderCells(form, formIndex) {
    const style = this.pixelify(this.calcCellsRect(form));

    return (
      <div className='cells' style={style}>
        {form.cells.map((cell, index) => this.renderCell(formIndex, index))}
      </div>
    );
  }

  renderFormSizeOption(sizeOption, index) {
    const value = `${sizeOption.rows}x${sizeOption.columns}`;

    return (
      <option key={value} value={value}>{value}</option>
    );
  }

  renderFormSizeOptions(form, formIndex) {
    const { formattedFormSizeOptions } = this.state;
    const value = `${form.rows}x${form.columns}`;

    return (
      <Dropdown
        className='crtx-form-size-dropdown'
        popoverClassName='crtx-form-size-dropdown-popover'
        options={formattedFormSizeOptions}
        value={value}
        onSelect={(e, value) => {
          this.handleFormSizeSelect(value, formIndex)
        }}
      />
    );
  }

  renderFormFooter(form, formIndex) {
    const { formattedFormSizeOptions } = this.state;
    const formNumber = formIndex + 1;
    const formNumberOnly = formattedFormSizeOptions.length <= 1;

    return (
      <div className='form-footer'>
        <div className='form-number'>{formNumber}</div>
        {formNumberOnly ? null : this.renderFormSizeOptions(form, formIndex)}
      </div>
    );
  }

  renderForm(form, formIndex) {
    return (
      <div className='form' key={formIndex}>
        {this.renderCells(form, formIndex)}
        {this.renderFormFooter(form, formIndex)}
      </div>
    );
  }

  renderForms() {
    const { forms } = this.state;
    if (forms.length <= 0) {
      return (
        <div className='no-forms'>
          {translate('No Formes are defined on this Book. Please, specify Number of Formes.')}
        </div>
      );
    }

    return (
      <div className='forms'>
        {this.state.forms.map(this.renderForm, this)}
      </div>
    );
  }

  renderFormsPane() {
    return (
      <div className='forms-pane'>
        {this.renderForms()}
      </div>
    );
  }

  getPmiTemplateNames() {
    return this.state.pmiTemplates.map(t => t.name);
  }

  findPmiTemplateByName(name) {
    if (!name) {
      return null;
    }

    return this.state.pmiTemplates.find(t => t.name.toLowerCase() === name.toLowerCase());
  }

  updatePmiTemplates(pmiTemplate) {
    if (!pmiTemplate || !pmiTemplate.name) {
      return;
    }

    const pmiTemplates = [...this.state.pmiTemplates];

    const idx = pmiTemplates.findIndex(t => t.name.toLowerCase() === pmiTemplate.name.toLowerCase());
    if (idx < 0) {
      pmiTemplates.push(pmiTemplate);
    } else {
      pmiTemplates[idx] = pmiTemplate;
    }

    this.setState({ ...this.state, pmiTemplates });
  }

  deletePmiTemplate(pmiTemplateName) {
    if (!pmiTemplateName) {
      return;
    }

    const { nwid, rootId } = this.props;

    return restDelete(nwid, `folders/${rootId}/planning-actions/imposition-template/${pmiTemplateName}`, {
      data: {}
    })
      .then((result) => {
        var newState = this.state;
        const pmiTemplates = this.state.pmiTemplates.filter(t => t.name !== pmiTemplateName);
        newState = { ...newState, pmiTemplates };
        if (this.state.selectedPmiTemplateName.toLowerCase() === pmiTemplateName.toLowerCase()) {
          const { pages } = this.convertFromPmiTemplate(null, this.state.pages);
          newState = { ...newState, selectedPmiTemplateName: '', forms: [], formCount: 0, pages };
        }

        this.setState(newState);
      })
      .catch(err => {
        console.log('Error:', err);
      });
  }

  saveAsPmiTemplate() {
    const { nwid, rootId } = this.props;
    const { selectedPmiTemplateName, forms, pages } = this.state;
    const pmiTemplate = this.convertToPmiTemplate(selectedPmiTemplateName, forms, pages);

    return restPost(nwid, 'folders/' + rootId + '/planning-actions/imposition-template', {

      impositionName: pmiTemplate.name,
      pageCount: pmiTemplate.numPages,
      forms

    })
      .then((result) => {
        toastService.createToast('top-right', translate('The PMI template was saved successfully'), '', 'success', undefined, undefined, 2000);
        this.updatePmiTemplates(pmiTemplate);
      })
      .catch(err => {
        toastService.createToast('top-right', translate('Cannot save the PMI template'), '', 'error', undefined, undefined, 2000);
        console.log('Error:', err);
      });
  }

  areNotAssignedPages() {
    return this.state.pages.some(p => p.assignedCount <= 0);
  }

  areEmptyForms() {
    return this.state.forms.some(f => {
      return f.cells.every(c => !(c.pageNumber > 0));
    });
  }

  validateModel() {
    var valid = false;

    if (this.state.selectedPmiTemplateName.trim() === '') {
      sandbox.dialog.alert(translate('Please specify the PMI template name'));
    } else if (this.areNotAssignedPages()) {
      sandbox.dialog.alert(translate('PMI template is not valid: some pages are not assigned'));
    } else if (this.areEmptyForms()) {
      sandbox.dialog.alert(translate('PMI template is not valid: some forms are empty'));
    } else {
      valid = true;
    }

    return valid;
  }

  handleSaveAsPmiTemplateClick = () => {
    //console.log("###handleSaveAsPmiTemplateClick, selectedPmiTemplateName=", this.state.selectedPmiTemplateName);
    if (this.validateModel()) {
      this.saveAsPmiTemplate();
    }
  };

  handlePmiTemplateChange = (e, name) => {
    //console.log("handlePmiTemplateChange => name=", name);
    const validName = removeIllegalFileChars(name);
    this.setState({ selectedPmiTemplateName: validName });

    if (validName !== name) {
      beep();
    }
  };

  handlePmiTemplateSelect = (e, name) => {
    //console.log("handlePmiTemplateSelect => name=", name);
    const pmiTemplate = this.findPmiTemplateByName(name);
    if (!pmiTemplate) {
      return;
    }

    var newState = this.state;
    const { forms, pages } = this.convertFromPmiTemplate(pmiTemplate, newState.pages);
    newState = { ...newState, selectedPmiTemplateName: name, forms, formCount: forms.length, pages };
    this.setState(newState);
  };

  handlePmiTemplateDelete = (e, name) => {
    //console.log("handlePmiTemplateDelete => name=", name);

    const msg = translate('Are you sure you want to delete this PMI template:') + ' ' + name + '?';
    sandbox.dialog.confirm(msg, translate('Delete PMI Template')).then(() => {
      this.deletePmiTemplate(name);
    });
  };

  handleFormCountChange = (e) => {
    //console.log("###handleFormCountChange() value=", e.target.value);
    this.setState({ formCount: e.target.value });
  };

  handleFormCountKeyDown = (e) => {
    //console.log("###handleFormCountKeyDown");
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();

      this.updateFormCount();
    }
  };

  handleFormCountBlur = (e) => {
    //console.log("###handleFormCountBlur");
    this.updateFormCount();
  };

  updateFormCount() {
    //console.log("###updateFormCount");
    const { forms, formSizeOptions } = this.state;
    var newState = this.state;
    const formCount = parseInt(this.state.formCount) || 0;
    newState = { ...newState, formCount };

    var newForms = [];
    if (formCount >= 0 && formCount < forms.length) {
      newForms = forms.slice(0, formCount);
      for (var i = formCount; i < forms.length; i++) {
        newState = this.unassignPagesFromForm(newState, i);
      }

      newState = { ...newState, forms: newForms };
    } else if (formCount > forms.length) {
      const opt = formSizeOptions[0];
      const rows = opt ? opt.rows : 0;
      const columns = opt ? opt.columns : 0;
      newForms = this.initForms(formCount - forms.length, rows, columns);
      newState = { ...newState, forms: forms.concat(newForms) };
    }

    this.setState(newState);
  }

  render() {
    const { formCount, selectedPmiTemplateName } = this.state;
    const pmiTemplateNames = this.getPmiTemplateNames();

    return (
      <div className='n-up'>
        <div className='n-up-toolbar'>
          <label id='formCountLabel' htmlFor='formCount' className='form-count-label'>
            {translate('Number of Formes:')}
          </label>
          <TextInput
            id='formCount'
            className='form-count'
            value={formCount}
            onChange={this.handleFormCountChange}
            onKeyDown={this.handleFormCountKeyDown}
            onBlur={this.handleFormCountBlur} />
          <ComboboxWithButtons
            className='pmi-template-names'
            value={selectedPmiTemplateName}
            options={pmiTemplateNames}
            onChange={this.handlePmiTemplateChange}
            onSelect={this.handlePmiTemplateSelect}
            onRemove={this.handlePmiTemplateDelete}
          />
          <Button
            className='save-button'
            imageSrc={sandbox.icons.getGeneralIcon(null, 'save')}
            disabled={!selectedPmiTemplateName}
            tooltip={translate('Save as PMI Template')}
            onClick={this.handleSaveAsPmiTemplateClick}
          />
        </div>
        <div className='edit-book'>
          {this.renderFormsPane()}
          {this.renderPagesPane()}
        </div>
      </div>
    );
  }
}

export default Nup;
