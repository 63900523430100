/**
 * @name
 * @fileOverview
 * @author sergey
 */
define(['AbstractAction', 'sandbox'], function (AbstractAction, sandbox) {
  'use strict';

  return AbstractAction.extend({

    execute: function (newModel, oldModel) {
      return sandbox.request.saveSetup(
        this.nwid,
        newModel['nwid'],
        newModel['type'],
        this.module.projectorId,
        newModel,
        oldModel,
        this.parameters.scriptName
      );
    }
  });
});