import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import IndicatorsBar from './IndicatorsBar';
import MultiProgressBars from 'components/common/progress/MultiProgressBars';
import { translate } from 'core/services/localization';

const PROGRESS_COLORS = {
  FINISHED: '#4cd272',
  IN_PROGRESS: '#ffe843'
};


/**
 * Creates an alert element that shows an icon and a count.
 * @param {number} count Number of items in this state.
 * @param {string} iconName Use module icon with this name.
 * @param {string} titleKey Translation key for the title (tooltip).
 * @returns {boolean|XML} React element or false to render nothing when count is 0.
 */


export default class AggregateObject extends Component {
  static propTypes = {

    icon: PropTypes.string,
    title: PropTypes.string,
    total: PropTypes.number,
    inProgress: PropTypes.number,
    finished: PropTypes.number,
    waitingForApproval: PropTypes.number,
    held: PropTypes.number,
    errors: PropTypes.number,
    viewInfo: PropTypes.any,
    isSummary: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.clickHandler = this.clickHandler.bind(this);
  }

  /**
   * Opens the appropriate view in new window.
   * @private
   */
  clickHandler() {
    this.props.viewInfo.openView();
  }

  render() {
    const { icon, title, total, inProgress, finished, errors, isSummary } = this.props;
    const completedTotal = finished;
    const inProgressTotal = inProgress + errors;
    const missingTotal = total - finished - inProgress - errors;

    const progressTitle = [
      translate('Completed: {1}', completedTotal),
      translate('In Progress: {1}', inProgressTotal),
      translate('Missing: {1}', missingTotal)
    ].join('\n');

    const renderProgressBar = () => {
      return <MultiProgressBars onClick={isSummary ? this.clickHandler : () => { }} min={0} max={total} title={progressTitle} values={[{ color: PROGRESS_COLORS.FINISHED, value: completedTotal }, { color: PROGRESS_COLORS.IN_PROGRESS, value: inProgressTotal }]} >
        {completedTotal} - {inProgressTotal} - {missingTotal}
      </ MultiProgressBars>
    }

    return isSummary ? renderProgressBar() :
      <Fragment>
        <div className='aggregate' onClick={this.clickHandler} >
          <div className='aggregate-panel'>
            <div className='col1'>
              <div className='centered'>
                <img title={title} src={icon} />
                <h3 className='total-count'>{total}</h3>
              </div>
            </div>
            <div className='crtx-dashboard-status'>
              {renderProgressBar()}
              <IndicatorsBar waitingForApproval={this.props.waitingForApproval} held={this.props.held} errors={this.props.errors} />
            </div>
          </div>
        </div>
      </Fragment>;
  }
}
