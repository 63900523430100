import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import DatePicker from 'components/common/dropdown/DatePicker';
import Header from 'components/common/headers/Header';

function DateSelector({ date, onSelect }) {

  return (
    <div className='date-selector'>
      <Header>{translate('Date')}</Header>

      <DatePicker
        value={date}
        onChange={onSelect}
      />

    </div>
  );
}

DateSelector.propTypes = {
  date: PropTypes.instanceOf(Date),
  onSelect: PropTypes.func,
};

export default DateSelector;
