/**
 * @name MenuItem
 * @file Default Menu Item component
 *
 * @author Boris
 * @since: 2017-07-18
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utilities/classNames';

function MenuItem({ item }) {
  if (typeof item === 'string') {
    return (
      <div className='crtx-menu-item'>{item}</div>
    );
  }

  const className = classNames('crtx-menu-item', {disabled: item.disabled});
  if (item.iconUrl) {
    return (
      <div className={className}>
        <img className='crtx-menu-item-icon' src={item.iconUrl} />
        <div className='crtx-menu-item-text'>{item.text}</div>
      </div>
    );
  }

  if (item.iconName) {
    return (
      <div className={className}>
        <i className='crtx-menu-item-icon material-icons'>{item.iconName}</i>
        <div className='crtx-menu-item-text'>{item.text}</div>
      </div>
    );
  }

  return (
    <div className={className}>
      <div className='crtx-menu-item-text'>{item.text}</div>
    </div>
  );
}

MenuItem.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
};

export default MenuItem;
