import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Duration from 'components/common/dropdown/Duration';

export default class DurationCell extends Component {
  static propTypes = {
    columnData: PropTypes.any,
    onChange: PropTypes.func
  };

  handleChange = (event, value, prop) => {
    const { rowIndex, columnKey, onChange } = this.props;

    if (typeof onChange === 'function') {
      onChange(rowIndex, columnKey, value, prop);
    }
  };

  render() {
    var { columnData, ...props } = this.props;

    return <div className='cell'>
      <div className='cell-content'>
        <Duration
          {...props}
          value={columnData}
          onChange={this.handleChange}
        />
      </div>
    </div>;
  }
}
