export default {

  update: function update(path, value, actionType = 'UPDATE') {
    return {
      type: actionType,
      path,
      value
    };
  },

  add: function update(path, index, value, actionType = 'ADD') {
    return {
      type: actionType,
      path,
      index,
      value
    };
  },

  remove: function update(path, index, actionType = 'REMOVE') {
    return {
      type: actionType,
      path,
      index
    };
  }

};
