import React from 'react';
import sandbox from 'sandbox';
import { fromServerDate } from 'core/dates';
import { Column, headers, cells } from 'widgets/ReactDataGrid/';
import labels from './labels';
import { isUndefined, findSorterIndex } from './utils';
import { SORT_DIRECTION } from 'core/comparators';


const { MultiSortHeader } = headers;
const { Text, Thumbnail, IconCell } = cells;

const BACKGROUND_COLOR_NO_MNI_NUMBER = '#FFFF00';

export const columnsDefaultOrder = [
  'pageContentNwid',
  'filename',
  'step',
  'status',
  'mniNumber',
  'category',
  'categoryDate',
  'inputTime',
  'advertiser',
  'adInstructions',
  'otherInformation'
];

export const columnsDefinition = (pages, handleThumbnailDoubleClick) => {
  return {
    pageContentNwid: {
      columnKey: 'pageContentNwid',
      name: labels.thumbnail,
      title: labels.thumbnail,
      width: 90,
      align: 'center',
      autoWidth: false,
      resizable: false,
      cell: Thumbnail,
      visible: true,
      sortable: false,
      cellDataGetter: (rowIndex, columnKey) => {
        return {
          columnData: pages[rowIndex].pageContentNwid,
          iconUID: pages[rowIndex].iconUID,
          onDoubleClick: handleThumbnailDoubleClick
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData || nextProps.iconUID !== props.iconUID
    },
    filename: {
      columnKey: 'filename',
      name: labels.filename,
      title: labels.filename,
      width: 150,
      align: 'left',
      autoWidth: false,
      cell: Text,
      visible: true,
      sortable: true,
      sortType: {
        [SORT_DIRECTION.ASC]: 'caseinsensitive',
        [SORT_DIRECTION.DESC]: 'caseinsensitivedecending',
      },
      sortProp: 'filename',
      cellDataGetter: (rowIndex, columnKey) => {
        let backgroundColor

        if (pages[rowIndex].mniNumber === "") {
          backgroundColor = BACKGROUND_COLOR_NO_MNI_NUMBER;
        }

        return {
          columnData: pages[rowIndex].filename,
          backgroundColor
        };
      }
    },
    step: {
      columnKey: 'step',
      name: labels.step,
      title: labels.step,
      width: 55,
      align: 'center',
      autoWidth: false,
      cell: IconCell,
      resizable: false,
      visible: true,
      sortable: false,
      cellDataGetter: (rowIndex, columnKey) => {
        return {
          icon: pages[rowIndex].flowStepIconName,
          iconStyle: { verticalAlign: 'middle' }
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.icon !== props.icon
    },
    status: {
      columnKey: 'status',
      name: labels.status,
      title: labels.status,
      width: 66,
      align: 'center',
      autoWidth: false,
      cell: IconCell,
      visible: true,
      sortable: false,
      cellDataGetter: (rowIndex, columnKey) => {
        return {
          icon: pages[rowIndex].statusIconName,
          iconStyle: { verticalAlign: 'middle' }
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.icon !== props.icon
    },
    mniNumber: {
      columnKey: 'mniNumber',
      name: labels.mniNumber,
      title: labels.mniNumber,
      width: 150,
      align: 'left',
      autoWidth: false,
      cell: Text,
      visible: true,
      sortable: true,
      sortType: {
        [SORT_DIRECTION.ASC]: 'caseinsensitive',
        [SORT_DIRECTION.DESC]: 'caseinsensitivedecending',
      },
      sortProp: 'mniNumber',
      cellDataGetter: (rowIndex, columnKey) => {
        return {
          columnData: pages[rowIndex].mniNumber
        };
      }
    },
    category: {
      columnKey: 'category',
      name: labels.category,
      title: labels.category,
      width: 200,
      align: 'left',
      autoWidth: false,
      cell: Text,
      visible: true,
      sortable: true,
      sortType: {
        [SORT_DIRECTION.ASC]: 'alpha',
        [SORT_DIRECTION.DESC]: 'alphadecending',
      },
      sortProp: 'category',
      cellDataGetter: (rowIndex, columnKey) => {
        return {
          columnData: pages[rowIndex].category
        };
      }
    },
    categoryDate: {
      columnKey: 'categoryDate',
      name: labels.categoryDate,
      title: labels.categoryDate,
      width: 115,
      align: 'left',
      autoWidth: false,
      cell: Text,
      visible: true,
      sortable: true,
      sortType: {
        [SORT_DIRECTION.ASC]: 'date',
        [SORT_DIRECTION.DESC]: 'datedecending',
      },
      //Hack alert: sortProp: 'time' is a hack that relays on the getPropertyRecursive in sorting.js, time is a sub property of categoryDate and the getPropertyRecursive will find it
      //            but if there will be more then one property with a nested time propety it might brake
      //            a way to solve it is to add a path functionality to sorting.js so it could get a path 'parent.child.child' so it will know which proprety to take
      sortProp: 'time',
      cellDataGetter: (rowIndex, columnKey) => {
        return {
          columnData: pages[rowIndex].categoryDate.localizedDate
        };
      }
    },
    inputTime: {
      columnKey: 'inputTime',
      name: labels.inputTime,
      title: labels.inputTime,
      width: 115,
      align: 'left',
      autoWidth: false,
      cell: Text,
      visible: true,
      sortable: true,
      sortType: {
        [SORT_DIRECTION.ASC]: 'date',
        [SORT_DIRECTION.DESC]: 'datedecending',
      },
      sortProp: 'inputTime',
      cellDataGetter: (rowIndex, columnKey) => {
        return {
          columnData: sandbox.localization.toLocaleShortDateTime(fromServerDate(pages[rowIndex].inputTime))
        };
      }
    },
    advertiser: {
      columnKey: 'advertiser',
      name: labels.advertiser,
      title: labels.advertiser,
      width: 150,
      align: 'left',
      autoWidth: false,
      cell: Text,
      visible: true,
      sortable: true,
      sortType: {
        [SORT_DIRECTION.ASC]: 'caseinsensitive',
        [SORT_DIRECTION.DESC]: 'caseinsensitivedecending',
      },
      sortProp: 'advertiser',
      cellDataGetter: (rowIndex, columnKey) => {
        return {
          columnData: pages[rowIndex].advertiser
        };
      }
    },
    adInstructions: {
      columnKey: 'adInstructions',
      name: labels.adInstructions,
      title: labels.adInstructions,
      width: 150,
      align: 'left',
      autoWidth: false,
      cell: Text,
      visible: true,
      sortable: false,
      cellDataGetter: (rowIndex, columnKey) => {
        return {
          columnData: pages[rowIndex].adInstructions
        };
      }
    },
    otherInformation: {
      columnKey: 'otherInformation',
      name: labels.otherInformation,
      title: labels.otherInformation,
      width: 150,
      align: 'left',
      autoWidth: false,
      cell: Text,
      visible: true,
      sortable: false,
      cellDataGetter: (rowIndex, columnKey) => {
        return {
          columnData: pages[rowIndex].otherInformation
        };
      }
    }
  };
};

export const renderColumn = (sorters, onSort, fixToLeftColumnsCount, hiddenColumns, columnsWidth) => (column, columnIndex) => {
  const sorterIndex = findSorterIndex(sorters, column.columnKey);
  const foundSorter = sorterIndex >= 0;

  return <Column
    columnKey={column.columnKey}
    width={columnsWidth[column.columnKey] || column.width}
    align={column.align}
    autoWidth={column.autoWidth}
    resizable={column.resizable}
    header={props => <MultiSortHeader
      {...props}
      sortable={column.sortable}
      sortDirection={foundSorter ? sorters[sorterIndex].direction : SORT_DIRECTION.NONE}
      sortOrder={foundSorter ? `${sorterIndex + 1}` : ''}
      onSort={column.sortable ? multiSort => onSort(column.columnKey, column.sortType, column.sortProp, multiSort) : () => { }}
    >
      {column.title}
    </MultiSortHeader>}
    cell={column.cell}
    cellDataGetter={column.cellDataGetter}
    fixed={columnIndex < fixToLeftColumnsCount ? 'left' : column.fixed}
    visible={isUndefined(hiddenColumns[column.columnKey]) ? column.visible : !hiddenColumns[column.columnKey]}
    shouldCellUpdate={column.shouldCellUpdate}
  />;
};