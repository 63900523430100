import React from 'react';
import PropTypes from 'prop-types';

export default class SeparationVersion extends React.Component {
  static defaultProps = {
    version: 0,
    definition: undefined
  };

  renderVersion = (version) => {
    return version > 1 ? <span className="SeparationVersionLabel">{version}</span> :
      <span className="SeparationVersionLabel">&nbsp;</span>;
  };

  render() {
    var {
      version,
    } = this.props;

    return <div className="SeparationVersion">
      {this.renderVersion(version)}
    </div>;
  }
}

SeparationVersion.propTypes = {
  definition: PropTypes.object,
  version: PropTypes.number,
};