/**
 * @name
 * @fileOverview
 * @author sergey & Elad
 */
define(['ember', 'text!../templates/sectionsMain.hbs', './zone', 'kendo-ember', './../../../utils/wizardCommons'],
  function (Ember, MainTemplate, ZoneView, kendoComponents, wizardCommons) {
    'use strict';

    return Ember.View.extend(wizardCommons.viewCommons, {
      onActivate: function(){

			},
			template: Ember.Handlebars.compile(MainTemplate),

      classNames: ['structure-container'],

			zoneView: ZoneView,

      shmokes: function (){
        if (this.get("controller.model.publication") === void 0 || this.get("controller.model.publication.editions") === void 0){
          this.selectedZone = null;
          return [];
        }
        var edition = this.get("controller.model.publication.editions")[0];
        if (edition === void 0 || edition.zones === void 0){
          this.selectedZone = null;
          return [];
        }
        this.selectedZone = edition.zones.length === 0 ? null : [0];
        return edition.zones;
      }.property("controller.model.publicationx.editions.@each.zones.length")




    });
  });