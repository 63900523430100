import AbstractAction from 'AbstractAction';
import sandbox from 'sandbox';
import toastMaster from 'core/services/toastService';
import { getMainStatus } from 'utilities/statuses';

const DEFAULT_CONFIG = { progress: 0, imageSubType: 'mono', propertyName: '', useLastVersion: true };
const isUndefined = o => typeof o === 'undefined';
const isUndefinedString = o => typeof o === 'undefined' || o.length === 0;

const NUMBER_OF_DOWNLOADS_WITHOUT_DELAY = 6;

const pause = () => {
  return new Promise(
    (resolve, reject) => {
      setTimeout(resolve, 1000);
    }
  );
};

const download = async (module, newModel, originalConfig) => {
  let count = 0;
  for (let i = 0; i < newModel.length; i++) {
    let selectedItem = newModel[i];
    let content = selectedItem.type === 'fragment/content' ?  selectedItem :
          selectedItem.pageContent || selectedItem.formContent || selectedItem.content;
    if (!content) {
      return;
    }

    let versionNumber = content.contentVersionMajorIndex;
    if (isUndefined(versionNumber)) {
      versionNumber = content.activeMainVersion;
    }
    if (versionNumber === 0) {
      continue;
    }
    const config = originalConfig || DEFAULT_CONFIG;
    let params = {
      rootType: content.type,
      imageType: 'pdf',
      imageSubType: config.imageSubType,
      viewId: module.id,
      pageAction: 4,
      nwid: content.nwid,
      projectorId: module.projectorId,
      propertyName: config.propertyName || '',
      useLastVersion: config.useLastVersion,
      progress: config.progress
    };
    var rest = sandbox.request.rest(module.nwid);

    rest.availableVersion(params).then(function (selectedItem) {
      if (selectedItem && selectedItem.isAvailable) {
        let inputFileName = selectedItem.nameToSave;
        if (!inputFileName) {
          let inputFileName = content.mainVersionInputFileName;
          if (isUndefinedString(inputFileName) && !isUndefined(content.mainVersions)) {
            inputFileName = content.mainVersions[0].inputFileName;
          }

          if (isUndefinedString(inputFileName)) {
            inputFileName = 'download.pdf';
          }
        }
        if (selectedItem.version) {
          params.version = selectedItem.version;
        }

        var link = module.win.document.createElement('a');
        link.href = rest.readImage(params, inputFileName);
        link.download = inputFileName;
        link.dispatchEvent(new MouseEvent('click'));
      } else {
        toastMaster.createToast('bottom-right', this.toastMessageError, '', 'error');
      }
    }.bind(this));
    if (++count >= NUMBER_OF_DOWNLOADS_WITHOUT_DELAY) {
      await pause();
      count = 0;
    }
  }
};

module.exports = AbstractAction.extend({

  isApplicable: function (selectedItem) {
    let selected = this && this.module ? this.module.selected : [];
    const config = this.config || DEFAULT_CONFIG;

    if (isUndefined(selectedItem)) {
      return false;
    }

    if (!Array.isArray(selected)) {
      return false;
    }

    if (isUndefined(config.progress) || config.progress === 0) {
      return true;
    } else {
      if (isUndefined(selectedItem[0])) {
        return false;
      } else {
        const status = getMainStatus(selectedItem[0]);
        if (!status) {
          return false;
        } else {
          return (config.progress * 100 <= status.progress) ? true : false;
        };
      };
    };

    let isAtLeastOneSelectionWithVersion = false;

    for (let i = 0; i < selected.length; i++) {
      let structure = selected[i];
      let content = structure.pageContent;
      if (isUndefined(content)) {
        content = structure.formContent;
      }
      if (isUndefined(content)) {
        content = structure.content;
      }
      if (isUndefined(content)) {
        return false;
      }
      let versionNumber = content.contentVersionMajorIndex;
      if (isUndefined(versionNumber)) {
        versionNumber = content.activeMainVersion;
      }
      if (versionNumber > 0) {
        isAtLeastOneSelectionWithVersion = true;
        break;
      }
    }
    return isAtLeastOneSelectionWithVersion;
  },

  execute: function (newModel, oldModel) {
    const module = this.module;
    download(module, newModel, this.config).then(() => console.log('FINISHED DOWNLOADING!!!!!'));
  }
});