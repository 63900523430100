/**
 * Created by moshemal on 1/8/15.
 */


define(['ember', 'jquery', 'sandbox', 'widgets/Wizard/AbstractStep', './views/FormsMain', './controllers/FormsController', 'kendo-ember',
    "./model/model", "./model/preStepModel", "./../../utils/wizardUtils", "./model/modelExmp"],
  function (Ember, $, sandbox, AbstractStep, BooksMainView, Controller, Componnents, ModelHandler, PreStepModel, wizardUtils, modelExmp){
    'use strict';

    function init(initObj){
      this._super(initObj);
      //this.rootType = initObj.startParameters.rootType;
      //this.rootId = initObj.startParameters.rootId;
      //this.nwid = initObj.properties.properties.startParameters.nwid;
      this.rootType = initObj.startParameters && initObj.startParameters.rootType ?
        initObj.startParameters.rootType : initObj.properties.properties.startParameters.rootType;
      this.rootId = initObj.startParameters && initObj.startParameters.rootId ?
        initObj.startParameters.rootId : initObj.properties.properties.startParameters.rootId;
      this.nwid = initObj.startParameters && initObj.startParameters.nwid ?
        initObj.startParameters.nwid : initObj.properties.properties.startParameters.nwid;
      this.wizard = initObj.wizard;
      this.settings = this.properties.properties;
    }


    function back(){
      return this.controller.updateModel(this.model);
    }

    function next(){
      var ret = this.controller.updateModel(this.model);
      if (!ret){
        return false;
      }
      return true;
    }

    function destroy(){
      console.log("destroy");
    }

    function render(){
      //this._view.rerender();
      this.wizard.disableButton("cancel");
      //this.model = JSON.parse(modelExmp.model);
      var mappedModel = wizardUtils.mapSourceIdToObject(this.model);
      var modelHandler = new ModelHandler();
      modelHandler.setModel(this.model);
      modelHandler.customFields = this.settings.customFields || [];
      PreStepModel.validModel(this.model, mappedModel);
      var model = modelHandler.convertToStepModel(this.model);
      model.pmiTemplates = this.model.pmiTemplates;
      var that = this;
      if (typeof this.controller === "undefined" || this.controller === null){
        this.controller = Controller.create({
          model: model,
          rootId: this.rootId,
          nwid: this.nwid,
          modelHandler: modelHandler,
          mappedModel: mappedModel,
          settings: that.settings
        });
      } else {
        Ember.set(this.controller, 'model', model);
        Ember.set(this.controller, 'modelHandler', modelHandler);
      }

      //if (!(this._view instanceof Ember.View)){
      $(this.container).empty();
      this._view =  BooksMainView.create({
        controller: this.controller
      }).appendTo(this.container);
      //}
    }






    return AbstractStep.extend({
      init:       init,
      render:     render,
      back:				back,
      next:       next,
      destroy:    destroy

    }, "Forms");
  }
);