import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GridItem from './griditem';
import Thumbnail from './thumbnail';

export default class ThumbnailWraper extends Component {
  static contextTypes = {
    module: PropTypes.object
  };

  static propTypes = {
    thumbnail: PropTypes.object
  };

  static defaultProps = {
    thumbnail: undefined,
    thumbnailDefinition: {},
    alignmentPoints: [],
    gridSize: undefined,
    gridMargin: undefined,
    gridAlign: undefined
  };

  isPreflightFilterVisible = (thumbnail, preflightFilter) => {
    let visible = true;
    if (typeof preflightFilter.warning !== 'undefined' && typeof preflightFilter.error !== 'undefined') {
      visible = preflightFilter.warning(thumbnail) || preflightFilter.error(thumbnail);
    } else if (typeof preflightFilter.warning !== 'undefined') {
      visible = preflightFilter.warning(thumbnail);
    } else if (typeof preflightFilter.error !== 'undefined') {
      visible = preflightFilter.error(thumbnail);
    }

    return visible;
  };

  isVisible = (thumbnail) => {
    const { filterArgument, preflightFilter } = this.context.module.ctrl;
    if (typeof filterArgument !== 'undefined' && !filterArgument(thumbnail) || !this.isPreflightFilterVisible(thumbnail, preflightFilter)) {
      return false;
    }
    return true;
  };

  onClick = (thumbnail, e) => {
    if (typeof this.props.onClick !== 'function') {
      return;
    }
    this.props.onClick(thumbnail, e);
  };

  onContextMenu = (thumbnail, e) => {
    if (typeof this.props.onContextMenu !== 'function') {
      return;
    }
    this.props.onContextMenu(thumbnail, e);
  };

  onDoubleClick = (thumbnail, e) => {
    if (typeof this.props.onDoubleClick !== 'function') {
      return;
    }
    this.props.onDoubleClick(thumbnail, e);
  };

  render() {
    var {
      thumbnail,
      showOnlyPagesInfo,
      thumbnailDefinition,
      viewConfiguration,
      alignmentPoints,
      gridSize,
      gridMargin,
      gridAlign,
      onClick,
      onContextMenu,
      onDoubleClick,
      onAggregatedItemClick,
      onOpenChange
    } = this.props,
      { ctrl } = this.context.module,
      scale = ctrl.getScale(),
      thumbnailSize = ctrl.getThumbnailSize(thumbnail, thumbnailDefinition),
      thumbnailScaledSize = thumbnailSize.map(size => size * scale),
      gridScaledSize = gridSize.map(size => size * scale),
      isVisible = this.isVisible(thumbnail);

    return <GridItem
      scale={scale}
      gridSize={gridScaledSize}
      gridMargin={gridMargin}
      gridAlign={gridAlign}
      size={thumbnailScaledSize}
      isVisible={isVisible}
      aggregated={thumbnail.aggregated}
      thumbnailNwid={thumbnail.nwid}
      onClick={onClick}
      onOpenChange={onOpenChange}
      onAggregatedItemClick={onAggregatedItemClick}
      viewConfiguration={viewConfiguration}
      thumbnailDefinition={thumbnailDefinition}
      alignmentPoints={alignmentPoints}
      numOfRelatedPages={thumbnail.aggregated ? thumbnail.relatedPages.length : 0}
    >
      <Thumbnail thumbnail={thumbnail}
        showOnlyPagesInfo={showOnlyPagesInfo}
        scale={scale}
        thumbnailSize={thumbnailSize}
        gridAlign={gridAlign}
        thumbnailDefinition={thumbnailDefinition}
        viewConfiguration={viewConfiguration}
        alignmentPoints={alignmentPoints}
        onClick={onClick}
        onContextMenu={onContextMenu}
        onDoubleClick={onDoubleClick}
      />

    </GridItem>;
  }
}

ThumbnailWraper.propTypes = {
  onClick: PropTypes.func,
  onContextMenu: PropTypes.func,
  onDoubleClick: PropTypes.func,
  thumbnail: PropTypes.object,
  showOnlyPagesInfo: PropTypes.bool,
  thumbnailDefinition: PropTypes.object,
  viewConfiguration: PropTypes.object,
  alignmentPoints: PropTypes.object,
  gridSize: PropTypes.array,
  gridMargin: PropTypes.number,
  gridAlign: PropTypes.string,
  onAggregatedItemClick: PropTypes.func,
  onOpenChange: PropTypes.func,
};