import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import { getGeneralSvgIconHref } from 'core/services/iconService';
import { classNames } from 'utilities/classNames';
import Header from 'components/common/headers/Header';
import TextInput from 'components/common/inputs/TextInput';
import Checkbox from 'components/common/inputs/Checkbox';

function PublicationSelector({ publications = [], selectedPublication, onSelect }) {

  const [name, setName] = useState('');
  const [plannedOnly, setPlannedOnly] = useState(false);

  const isSelected = (publication) => {
    return publication.displayName === selectedPublication?.displayName;
  };

  const filterPublications = () => {
    let ret = publications.filter(p =>
      !p.plannedPublication || p.validLoad);

    if (!name && !plannedOnly) {
      return ret;
    }

    return ret.filter(p =>
      (!name || p.displayName.toLowerCase().includes(name.toLowerCase())) &&
      (!plannedOnly || p.plannedPublication));
  };

  const handleChangeName = (event, name) => {
    setName(name);
  };

  const handleChangePlannedOnly = () => {
    setPlannedOnly(!plannedOnly);
  };

  return (
    <div className='publication-selector'>
      <Header>{translate('Publication')}</Header>
      <div className='publication-filter'>
        <TextInput
          inputType='search'
          value={name}
          placeholder={translate('Search')}
          title={translate('Publication name')}
          onChange={handleChangeName}
        />

        <label>
          <Checkbox checked={plannedOnly} onChange={handleChangePlannedOnly} />
          {translate('Planned only')}
        </label>
      </div>

      <ul className='structure-list'>
        {filterPublications().map(pub =>
          <li
            key={pub.displayName}
            className={classNames('structure-list-item', { selected: isSelected(pub) })}
            onClick={() => onSelect(pub)}
          >
            <div className='item-caption'>
              <div className='caption-text' title={pub.displayName}>
                {pub.displayName}
              </div>
              {pub.plannedPublication ?
                <svg className='caption-icon'>
                  <title>{translate('Planned')}</title>
                  <use xlinkHref={getGeneralSvgIconHref('check_circle')} />
                </svg>
                : null}
            </div>
          </li>
        )}
      </ul>
    </div>
  );
}

PublicationSelector.propTypes = {
  publications: PropTypes.array,
  selectedPublication: PropTypes.object,
  onSelect: PropTypes.func,
};

export default PublicationSelector;
