import React from 'react';
import PropTypes from 'prop-types';
import ResourceComponent from './ResourceComponent';

export default function FlowStepOverviewComponent({ module, model }) {
  const { resources } = model;

  const renderResource = (resource, index) => {
    return <ResourceComponent module={module} key={index} resource={resource} />;
  };

  return (
    <div className='crtx-flow-step-overview-resource'>
      {resources.map(renderResource)}
    </div>
  );
}

FlowStepOverviewComponent.propTypes = {
  module: PropTypes.object,
  model: PropTypes.object,

};