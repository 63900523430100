
import OpenDialogAction from './OpenDialogAction';
const isUndefined = o => typeof o === 'undefined';

export default OpenDialogAction.extend({
  isApplicable: function () {
    if (this.module.selected) {
      if (this.module.selected[0].blockMail == "true"){
        return false;
      }
      return this.module.selected.length === 1 && !isUndefined(this.module.selected[0].multiple) ? true : false;
    }
  }
});