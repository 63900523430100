import React from 'react';
import PropTypes from 'prop-types';

function TabStripContent({ children, className = '', style }) {
  const contentCss = `crtx-tabstrip-content ${className}`

  return (
    <div className={contentCss} style={style}>
      {children}
    </div>
  );

}

TabStripContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
};

export default TabStripContent;