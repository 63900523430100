import appPromises from 'core/managers/appPromises';
import viewManager from 'core/managers/views';
import idWorker from 'core/workers/idWorker';
import settingsManager from 'core/managers/settings';
import tickFacade from 'core/facades/tick';
import TickableModel from '../../modules/TickableModel';
import jsutils from 'base/jsUtils';
import pubsub from 'core/managers/pubsub';
import toastService from 'core/services/toastService';
import { fromServerDate } from 'core/dates';
import request from 'core/managers/request';
import { createObjectComparator, COMPARE_TYPE } from 'core/comparators';
import { restPut } from 'core/managers/rest2';

const CHAT_TOAST_DELAY = 10000;
const isString = str => typeof str === 'string';
const SERVICE_NAME = 'chatService';
const THROTTLE_WAIT = 1000;
const WINDOWS_NOTIFICATION = false;

const CHANNEL_TYPES = {
  custom: 'chat/channel/custom',
  oneToOne: 'chat/channel/one_to_one',
  oneToGroup: 'chat/channel/group'
};

let chatViewLink = {};
let chatServiceLink = {};

let model = {
  channels: []
};

const Chat = {

  tickableModel: new TickableModel(),
  isFirstTick: true,

  start: function () {

    this.updates = [];
    this.tickUpdateHandlerThrottled = jsutils.throttle(this.tickUpdateHandler, THROTTLE_WAIT, {
      leading: false,
      trailing: true
    });

    if (typeof chatServiceLink === 'undefined') {
      log.info("ChatService.startService: ChatService instance not found, couldn't start ChatService");
      return;
    }
    this.nwid = chatServiceLink.nwid;
    this.id = idWorker.generateId();
    var requestParams = {
      rootId: settingsManager.get('user').nwid,
      rootType: 'controlpanel/profiles/users/user'
    };

    tickFacade.addProjector(this, this.firstTickReceived, this.tickUpdate, this.tickCommit, requestParams);
  },

  firstTickReceived: function (data) {

    this.tickableModel.firstTickHandler(data.model);
    this.buildViewModel();

  },

  tickUpdate: function (data) {
    this.isFirstTick = false;
    this.updates = this.updates.concat(data.model);
    this.tickUpdateHandlerThrottled();
  },

  tickUpdateHandler: function () {
    this.tickableModel.tickUpdateHandler(this.updates);
    this.updates = [];
    this.buildViewModel();
  },

  launchToast: function (model, notReceivedMessageNwids, totalNewMessagesCount) {
    const moduleInstance = require('core/managers/module').getModulesByName("ChatView");

    if (totalNewMessagesCount > 0 && (moduleInstance.length === 0)) {
      if (notReceivedMessageNwids.length > 0) {
        if (!this.isFirstTick) {
          const channelByMessageNwid = (messageNwid) => model.channelsByNwid[model.messagesByNwid[messageNwid].channelNwid];

          if (WINDOWS_NOTIFICATION) {
            notReceivedMessageNwids.every(messageNwid => this.windowsNotification(messageNwid, model));
          } else {
            notReceivedMessageNwids.every(messageNwid => {
              if (channelByMessageNwid(messageNwid).type === CHANNEL_TYPES.oneToOne) {

                return toastService.chatToast(channelByMessageNwid(messageNwid).subject, model.messagesByNwid[messageNwid].content, {delay: CHAT_TOAST_DELAY});
              } else {

                return toastService.chatToast(channelByMessageNwid(messageNwid).subject, model.messagesByNwid[messageNwid].from + ': ' + model.messagesByNwid[messageNwid].content, {delay: CHAT_TOAST_DELAY});
              }
            });
          }
        }

      }
    }

  },

  setAllMessagesAreReceived: function (notReceivedMessageNwids) {

    if (notReceivedMessageNwids.length === 0) return;

    const currentUserNwid = settingsManager.get('user').nwid;

    notReceivedMessageNwids.forEach(nwid => {
      let restData = {
        userId: currentUserNwid,
      };

      return restPut(this.nwid, `chat/messages/${nwid}/receive`, restData);     

    });
  },

  setAllMessagesAreReceivedInOneCall: function (notReceivedMessageNwids) {

    if (notReceivedMessageNwids.length === 0) return;

    const currentUserNwid = settingsManager.get('user').nwid;
    let restData = {
      userId: currentUserNwid,
      receivedMessagesNwids: notReceivedMessageNwids
    };

    return restPut(this.nwid, `chat/messages/receive`, restData);
  },

  windowsNotification: function (nwid, model) {

    const notify = (title, content) => {
      return new Notification(title, {
        body: content,

      });
    };

    const sendNotification = () => {
      let notification;
      const channelByMessageNwid = (messageNwid) => model.channelsByNwid[model.messagesByNwid[messageNwid].channelNwid];

      if (channelByMessageNwid(nwid).type === CHANNEL_TYPES.oneToOne) {
        notification = notify(channelByMessageNwid(nwid).subject, model.messagesByNwid[nwid].content);
      } else {
        notification = notify(channelByMessageNwid(nwid).subject, model.messagesByNwid[nwid].from + ': ' + model.messagesByNwid[nwid].content);
      }
    };


    if (window.isSecureContext && window.Notification) {
      if (Notification.permission === "granted") {
        sendNotification();
      }

      else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
          sendNotification();
        });
      }

    }
  },

  buildViewModel: function () {
    //***TEST BEGIN
    //  const t1 = performance.now();
    //***TEST END
    const model = this.tickableModel.model();
    const notReceivedMessageNwids = [];
    const currentUser = settingsManager.get('user').name;

    let isTheSameFolder = model.groups.every(group => group.folderName === model.groups[0].folderName);

    model.groups.forEach(group => {
      group.groupFullName = isTheSameFolder ? group.groupName : group.folderName + '\/' + group.groupName;
    });

    model.channels.forEach(channel => {
      channel.groups.forEach(group => {
        group.groupFullName = isTheSameFolder ? group.groupName : group.folderName + '\/' + group.name;
      });
    });

    const isReadByUser = (message, userName) => message.readBy.find(readBy => readBy.userName === userName);

    model.channels.forEach(channel => {
      channel.lastUpdatedDateTime = isString(channel.lastUpdatedDateTime) ? fromServerDate(channel.lastUpdatedDateTime) : channel.lastUpdatedDateTime;
    });

    model.channels.forEach(channel => {
      channel.messages.forEach(message => {
        message.sentDateTime = isString(message.sentDateTime) ? fromServerDate(message.sentDateTime) : message.sentDateTime;
      });
    });

    model.channels.forEach(channel => {
      channel.newMessagesCount = channel.messages.reduce((newMessagesCount, message) => {
        if (!message.receivedBy.find(user => user.userName === currentUser)) {
          notReceivedMessageNwids.push(message.nwid);
        }

        if (!isReadByUser(message, currentUser)) {
          return newMessagesCount + 1;
        }

        return newMessagesCount;
      }, 0);
    });

    const totalNewMessagesCount = model.channels.reduce((count, channel) => channel.newMessagesCount == 0 ? count : count + channel.newMessagesCount, 0);


    model.channels.forEach(channel => {
      channel.subject = channel.subject ? channel.subject : (channel.type === CHANNEL_TYPES.oneToOne ? channel.userNames.find(name => name !== currentUser) : channel.groups[0].groupFullName);
    });

    model.channels.forEach(channel => {
      channel.messages.forEach(message => {

        const readBy = message.readBy.map(user => user.userName);
        const receivedBy = message.receivedBy.map(user => user.userName);

        message.isReadMessage = this.calculateMessageStatus(readBy, channel, model.groups, message.from);
        message.isReceivedMessage = this.calculateMessageStatus(receivedBy, channel, model.groups, message.from);

      });
    });

    model.channels = [...model.channels].sort(createObjectComparator('lastUpdatedDateTime', COMPARE_TYPE.DATES, false));

    model.channelsByNwid = model.channels.reduce(function (map, channel) {
      map[channel.nwid] = channel;
      return map;
    }, {});

    model.messagesByNwid = model.channels.reduce((accChannels, channel) => {
      channel.messages.reduce((accMessages, message) => {
        message.channelNwid = channel.nwid;
        accMessages[message.nwid] = message;

        return accMessages;
      }, accChannels);

      return accChannels;
    }, {});

    model.usersByNwid = model.users.reduce(function (map, user) {
      map[user.nwid] = user;
      return map;
    }, {});

    model.groupsByNwid = model.groups.reduce(function (map, group) {
      map[group.nwid] = group;
      return map;
    }, {});

    if (!this.isFirstTick) {
      this.setAllMessagesAreReceived(notReceivedMessageNwids);
    } else {
      this.setAllMessagesAreReceivedInOneCall(notReceivedMessageNwids);
    }


    this.launchToast(model, notReceivedMessageNwids, totalNewMessagesCount);
    notReceivedMessageNwids.length = 0;


    const newModel = {
      totalNewMessagesCount: totalNewMessagesCount,
      users: model.users,
      groups: model.groups,
      notReceivedMessageNwids: notReceivedMessageNwids,
      channelsByNwid: model.channelsByNwid,
      messagesByNwid: model.messagesByNwid,
      usersByNwid: model.usersByNwid,
      groupsByNwid: model.groupsByNwid
    };

    pubsub.publish(SERVICE_NAME, newModel);

    //***TEST BEGIN
    // const t2 = performance.now();
    //  console.log(`### ChatService.buildViewModel() => type='${model.type}', ${this.viewModel.events.length} events`);
    // console.log('### Chat: ChatService.buildViewModel() time = ', t2 - t1, 'ms');
    //***TEST END
  },

  calculateMessageStatus: function (arr, selectedChannel, groups, fromUser) {

    // channel information - must be read/received by:
    let usersChannel = selectedChannel.userNames.slice(0);
    let groupsChannel = selectedChannel.groups.map(group => group.name).slice(0);

    let usersTemp = [];
    let groupTemp = [];

    // if specific user is a participant of specific group
    const foundInGroups = (index, name) => {

      return groups.find(group => group.folderName === selectedChannel.groups[index].folderName && group.groupName === selectedChannel.groups[index].name).userNames.find(userName => userName === name && name !== fromUser)
    };

    // building the array of all users that read/received this message
    usersChannel.forEach(user => {
      if (arr.find(userName => userName === user)) {
        usersTemp.push(user);
      }
    });

    // building the array of all groups that contain at least one participant, that is not the sender of this message,
    // that read/received this message
    if ((usersChannel.length === usersTemp.length)) {
      arr.forEach(name => {
        for (let i = 0; i < selectedChannel.groups.length; i++) {
          if (foundInGroups(i, name)) {
            if (groupTemp.indexOf(selectedChannel.groups[i].name) === -1) {
              groupTemp.push(selectedChannel.groups[i].name);
            }
          }
        }
      });
      if (groupTemp.length === groupsChannel.length) {

        return true;
      }

      return false;
    }

    return false;
  }
};

function registerModule(module) {

  module.model = model;
  module.projectorId = Chat.projectorId;
}

// ===================== Event Subscription ===========================
appPromises.getPromise(appPromises.states.CHANNEL_OPENED).then(function () {
  if (settingsManager.getFolderInfo().allowChat) {
    console.log('CHANNEL_OPENED => starting chat');
    Chat.start();
  }
});

appPromises.getPromise(appPromises.states.LOGGED_IN).then(function (res) {

  const serviceLinkNwid = res.serviceLinks['ChatServiceCR'];
  const viewLinkNwid = res.serviceLinks['ChatViewCR'];

  if (typeof serviceLinkNwid !== 'undefined') {
    chatServiceLink = viewManager.getViewInfo(serviceLinkNwid);
  }
  if (typeof viewLinkNwid !== 'undefined') {
    chatViewLink = viewManager.getViewInfo(viewLinkNwid);
  }

});

function register(callback) {

  return pubsub.subscribe(SERVICE_NAME, callback, true)
}

export default {
  _name: 'chat',
  _type: 'service',
  registerModule: registerModule,
  register,
  tickableModel: Chat.tickableModel
};