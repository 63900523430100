/**
 * @name
 * @fileOverview Context menu item view
 * @author sergey
 */
define(['ember', 'sandbox'], function (Ember, sandbox) {
  'use strict';
  return Ember.View.extend({
    label: function(){
      var label = this.get('content.label');
      return sandbox.localization.translate(label);
    }.property(),
    tagName: 'li',
    classNameBindings: ['selected:k-state-selected', 'hover:k-state-hover', 'sub', 'disabled'],
    selected: false,
    hover: false,
    sub: function () {
      /*
        sub menu was design for open views when multiple options exists,
        this functionality was changed and now only default view will be opened by clicking the
          main menu, other option by clicking the button
       */
      //return this.get('content.items.length') >= 2;
      return false;
    }.property(),
    disabled: function () {
      return !this.get('content._isApplicable');
    }.property(),
    mouseEnter: function (ev) {
      if (this.get('disabled')) {
        return false;
      }
      //
      if (this.get('parentView.level') === 0 && this.get('controller.subIsOpen')) {
        this.get('controller').send('closeSub', ev);
      }

      // open submenu
      if (this.get('sub')) {
        this.get('controller').send('openSub', ev, this.get('content'), this.get('controller'));
      }
      return this.set('hover', true);
    },
    mouseLeave: function () {
      return this.set('hover', false);
    },
    click: function (event) {
      if (!this.get('disabled')) {
        this.get('controller').send('execute', this.get('content'), event);
      }
    },
    touchStart: function () {
      this.click();
    }
  });
});