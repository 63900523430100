/**
 * Created by moshemal on 11/26/14.
 */
import AbstractAction from 'AbstractAction';
import request from 'sandbox/request';

function toStep(node) {
  var step = {
    type: node.type,
    label: node.label,
    category: node.category,
  };
  if (node.fileName) {
    step.fileName = node.fileName;
  }
  if (node.nwid.indexOf('new') === 0) {
    step.tempId = node.nwid;
    step.nwid = '';
    step.copiedFrom = node.copiedFrom.trim();
  } else {
    step.nwid = node.nwid;
  }
  if (typeof node.group === 'string') {
    step.group = node.group;
  }
  return step;
}

export default AbstractAction.extend({
  isApplicable: function () {
    return !this.module.view ? false : (this.module.view.isEditable || false);
  },
  execute: function () {
    var module = this.module;
    module.view.applyLocalChanges();
    var requiredParameters = module.getRequiredParameters();

    var objToSave = {
      nwid: requiredParameters.rootId,
      type: requiredParameters.rootType,
      steps: module.view.exportNodesData().map(toStep),  //toSteps(nodesMap),
      transitions: module.view.exportTransitions().map(function (e) {
        return {
          source: e.sourceStep,
          target: e.targetStep
        };
      })
    };
    return request.execServerAction(this.nwid, '', requiredParameters.rootId,
      requiredParameters.rootType, module.projectorId, this.parameters.scriptName, objToSave)
      .then(function (res) {
        if (res.data) {
          module.view.updateNodesIds(res.data); //the result data is dict of tmpId 2 nwid
          module._savePreferencesNow();
        }
      });
  }
});