import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DatePicker from "components/common/dropdown/DatePicker";

export default class extends Component {
  static propTypes = {
    columnData: PropTypes.any,
    onChange: PropTypes.func
  };

  static defaultProps = {
    onChange: () => {
    }
  };

  handleChange = (event, value, strValue) => {
    var {rowIndex, columnKey, onChange} = this.props;
    if (typeof this.props.onChange === 'function') {
      onChange(rowIndex, columnKey, value, strValue);
    }
  };

  render() {
    var {columnData, columnKey, options, onChange, ...props} = this.props;

    return <div className="cell">
      <div className="cell-content">
        <DatePicker {...props}
                    value={columnData}
                    onChange={this.handleChange}
        />
      </div>
    </div>;
  }
}
