import React, { Component } from 'react';
import PropTypes from 'prop-types';

module.exports = class LayoutPage extends Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    pagesObject: PropTypes.any,
    pageContentNwid: PropTypes.any
  };

  render() {
    const { pagesObject, pageContentNwid } = this.props;

    const style = {
      ...pagesObject.position,
      position: 'absolute',
      width: pagesObject.pageWidth + 'px',
      height: pagesObject.pageHeight + 'px',
      border: '1px solid #fff',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    };

    const pageNameStyle = {
      transform: 'rotate(' + pagesObject.rotation + ')',
      position: 'absolute',
      maxWidth: '40px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    };

    const pageNameLabel = pagesObject.name;
    const pageName = <span style={pageNameStyle}>{pageNameLabel}</span>;

    return <div key={pageContentNwid} title={pageNameLabel} style={style}>{pageName}</div>;
  }

};