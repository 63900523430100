// @flow
/**
 * Request Manager
 * @fileOverview File contains methods which are addressed to
 * GenericServlet with different types of commands.
 *
 * @name Request manager
 * @namespace
 * @author sergey
 */

import base from 'base';
import baseData from 'base/data';
import logger from 'logger';
import rest from './rest';
import settingsManager from 'core/managers/settings';

var log = logger.getDefaultLogger();
var requestVersion = 1;
var requestOrigin = 'coretex';
var webAppPath = '/' + window.location.pathname.split('/')[1];
var productPath = window.location.pathname;

if (window.location.pathname.split('/').pop().indexOf(".html") > 0) {
  //in case there is an html file in the path remove it
  var tmpArrPath = window.location.pathname.split('/');
  tmpArrPath.pop();
  productPath = tmpArrPath.join("/") + "/";
}

function _getPreLoginInfo(params = {}) {
  const requestParams = {
    requestVersion: requestVersion,
    requestOrigin: requestOrigin,
    ...params
  };

  return baseData.when(
    baseData.ajax({
      url: webAppPath + '/preLoginService',
      type: 'POST',
      dataType: 'json',
      data: requestParams
    }).then(res => res)
    
  )
}

function retrieveViewLinks(nwid, type, containerViewNwid) {
  const requestParams = {
    command: 'generateViewLinks',
    clientId: settingsManager.get('id'),
    requestVersion: requestVersion,
    nwid: nwid,
    type: type,
    containerViewNwid: containerViewNwid
  };

  return baseData.ajax({
    url: webAppPath + '/servlet/GenericCommandServlet',
    type: 'POST',
    dataType: 'json',
    data: requestParams
  });
}

/**
 * Retrieve View Links for each item in the items array
 * @param items - array of objects {nwid, type}
 * @param viewNwid - View module nwid (optional)
 * @returns Promise
 */
function retrieveViewLinksBatch(items, viewNwid) {
  const requestParams = {
    command: 'generateViewLinks',
    clientId: settingsManager.get('id'),
    requestVersion: requestVersion,
    containerViewNwid: viewNwid,
    params: JSON.stringify({objects: items})
  };

  return baseData.ajax({
    url: webAppPath + '/servlet/GenericCommandServlet',
    type: 'POST',
    dataType: 'json',
    data: requestParams
  });
}



/**
 * Request children for a node with a given id
 * @param {number}    projectorId   Projector id of a module
 * @param {string}    nodeId        Id of the node
 */
function loadChildNodes(projectorId, nodeId) {
  if (!projectorId || !nodeId) {
    const msg = 'projectorId and nodeIds must be specified';
    console.error(msg);
    return base.data.deferred().reject(msg);
  }

  return loadDescendantNodes(projectorId, [nodeId], false);
}

/**
 * Request descendants of the nodes with the specified ids
 * @param {number}   projectorId   Projector id of a module
 * @param {array}    nodeIds       Array of the node ids
 * @param {boolean}  allLevels     If true then load descendants from all levels (children of children etc.)
 */
function loadDescendantNodes(projectorId, nodeIds, allLevels) {
  if (!projectorId || !Array.isArray(nodeIds) || nodeIds.length <= 0) {
    const msg = 'projectorId and nodeIds must be specified';
    console.error(msg);
    return base.data.deferred().reject(msg);
  }

  const treeExpandAllLimit = settingsManager.get('treeExpandAllLimit');
  const hints = nodeIds.map(id => {
    return {
      id,
      depth: treeExpandAllLimit,
      hint: allLevels ? 'expandAll' : 'showProperty',
      key: 'children'
    }
  });

  const requestParams = {
    clientId: settingsManager.get('id'),
    requestVersion: requestVersion,
    projectorId: projectorId,
    command: 'ProjectorHint',
    params: JSON.stringify({hints})
  };

  return baseData.ajax({
    url: webAppPath + '/servlet/GenericCommandServlet',
    type: 'POST',
    dataType: 'json',
    data: requestParams
  }).then(function (res) {
    if (res.statusCode === 200) {
      log.info('Server response on Projector Hint', res);
      return res.data;
    }
    else {
      log.warn('Projector Hint error: ' + res.errorMessage);
      return [];
    }
  }).fail(function (res) {
    log.warn('Projector Hint error: ' + res);
    return res;
  });
}

/**
 * Request is fired whenever to enable or disable resource
 *
 * @param  {number} resourceNwId        Newsway id of the resource
 * @param  {string} type        Template name for a resource
 * @param  {number} projectorId ProjectorId which is opened for the particular module on the server side to issue updates
 * @param  {Object} params      Additional request parameters
 * @param  {number} viewInstanceNwid the Newsway id of the
 */
function toggleResource(viewInstanceNwId, actionInstanceNwId, resourceNwId, type, projectorId, params) {
  var requestParams;
  requestParams = {
    actionName: 'ToggleResource',
    params: JSON.stringify(params),
    clientId: settingsManager.get('id'),
    requestVersion: requestVersion,
    instanceNwId: viewInstanceNwId,
    actionInstanceNwId: actionInstanceNwId,
    scriptName: 'exec',
    type: type,
    projectorId: projectorId,
    command: 'ExecAction',
    nwid: resourceNwId
  };

  baseData.ajax({
    url: webAppPath + '/servlet/GenericCommandServlet',
    type: 'POST',
    dataType: 'json',
    data: requestParams,
    success: function (res) {
      log.info('Server response on Toggle Resource', res);
    }
  });

}

/**
 * Request to abort all currently active resources on the module
 * @param  {number} nwid        nwid for a workflow
 * @param  {string} type        Template name for a workflow
 * @param  {number} projectorId ProjectorId which is opened for the particular module on the server side to issue updates
 * @param  {Object} params      Additional request parameters
 */
function abortAllResources(actionInstanceNwId, nwid, type, projectorId, params) {
  execAction(actionInstanceNwId, 'AbortResources', nwid, type, projectorId, 'exec', params);
}

/**
 * Request is fired whenever to enable or disable device
 *
 * @param  {number} viewInstanceNwId    Newsway Id of the view instance
 * @param  {number} actionInstanceNwId    Newsway Id of the action instance
 * @param  {number} deviceNwId          Newsway id of a module
 * @param  {string} type                Template name for a resource
 * @param  {number} projectorId         ProjectorId which is opened for the particular module on the server side to issue updates
 * @param  {Object} params              Additional request parameters
 */
function toggleDevice(viewInstanceNwId, actionInstanceNwId, deviceNwId, type, projectorId, params) {

  var requestParams;
  requestParams = {
    command: 'ExecAction',
    instanceNwId: viewInstanceNwId,
    actionInstanceNwId: actionInstanceNwId,
    actionName: 'ToggleDevice',
    projectorId: projectorId,
    nwid: deviceNwId,
    clientId: settingsManager.get('id'),
    requestVersion: requestVersion,
    params: JSON.stringify(params),
    type: type,
    scriptName: 'exec'
  };

  return baseData.ajax({
    url: webAppPath + '/servlet/GenericCommandServlet',
    type: 'POST',
    dataType: 'json',
    data: requestParams,
    success: function (res) {
      log.info('Server response on Toggle Device', res);
    }
  });
}

/**
 * Request is fired whenever to enable or disable the skip-device mode
 *
 * @param  {number} viewInstanceNwId    Newsway Id of the view instance
 * @param  {number} actionInstanceNwId    Newsway Id of the action instance
 * @param  {number} deviceNwId          Newsway id of a module
 * @param  {string} type                Template name for a resource
 * @param  {number} projectorId         ProjectorId which is opened for the particular module on the server side to issue updates
 * @param  {Object} params              Additional request parameters
 */
function toggleSkipDevice(viewInstanceNwId, actionInstanceNwId, deviceNwId, type, projectorId, params) {

  var requestParams;
  requestParams = {
    command: 'ExecAction',
    instanceNwId: viewInstanceNwId,
    actionInstanceNwId: actionInstanceNwId,
    actionName: 'ToggleSkipDevice',
    projectorId: projectorId,
    nwid: deviceNwId,
    clientId: settingsManager.get('id'),
    requestVersion: requestVersion,
    params: JSON.stringify(params),
    type: type,
    scriptName: 'exec'
  };

  baseData.ajax({
    url: webAppPath + '/servlet/GenericCommandServlet',
    type: 'POST',
    dataType: 'json',
    data: requestParams,
    success: function (res) {
      log.info('Server response on Toggle Skip Device', res);     
    }
  });
}

function eventsActionRequest(actionName, viewInstanceNwId, actionInstanceNwId, deviceNwId, type, projectorId, params, script) {
  var scriptName = script || "exec";
  var requestParams = {
    command: 'ExecAction',
    instanceNwId: viewInstanceNwId,
    actionInstanceNwId: actionInstanceNwId,
    actionName: actionName,
    projectorId: projectorId,
    nwid: deviceNwId,
    clientId: settingsManager.get('id'),
    requestVersion: requestVersion,
    params: JSON.stringify(params),
    type: type,
    scriptName: scriptName
  };

  return baseData.ajax({
    url: webAppPath + '/servlet/GenericCommandServlet',
    type: 'POST',
    dataType: 'json',
    data: requestParams,
    success: function (res) {
      log.info('Server response to ' + actionName, res);
    }
  });
}

/**
 * Start all resources and workflow steps
 *
 * @param  {number} actionInstanceNwId    Newsway Id of the action instance
 * @param  {number} nwid    Newsway id of a workflow
 * @param  {string} type    Template name for a workflow
 * @param  {number} projectorId   ProjectorId which is opened for the particular module on the server side to issue updates
 */
function startAllWorkflow(actionInstanceNwId, nwid, type, projectorId) {
  const params = {objectType: "all", nwid: "0", action: "on"};
  return execAction(actionInstanceNwId, 'StartAllWorkflowAction', nwid, type, projectorId, 'exec', params);
}

/**
 * Stop all resources and workflow steps
 *
 * @param  {number} actionInstanceNwId    Newsway Id of the action instance
 * @param  {number} nwid    Newsway id of a workflow
 * @param  {string} type    Template name for a workflow
 * @param  {number} projectorId   ProjectorId which is opened for the particular module on the server side to issue updates
 */
function stopAllWorkflow(actionInstanceNwId, nwid, type, projectorId) {
  const params = {objectType: "all", nwid: "0", action: "off"};
  return execAction(actionInstanceNwId, 'StopAllWorkflowAction', nwid, type, projectorId, 'exec', params);
}

/**
 * rename un plan page in unplanpages table
 *
 * @param  {number} actionInstanceNwId    Newsway Id of the action instance
 * @param  {number} nwid    Newsway id of a workflow
 * @param  {string} type    Template name for a workflow
 * @param  {number} projectorId   ProjectorId which is opened for the particular module on the server side to issue updates
 */
function renameUnplanPage(viewInstanceNwId, actionInstanceNwId, nwid, type, projectorId, params) {
  return execAction(actionInstanceNwId, 'RenameUnplanAction', nwid, type, projectorId, 'exec', params);
}

function getDynPropOptions(viewInstanceNwId, actionInstanceNwId, nwid, type, projectorId, params) {
  return execAction(actionInstanceNwId, 'DynamicPropertiesActionCR', nwid, type, projectorId, 'getProperties', params);
}

function changeDynProperties(viewInstanceNwId, actionInstanceNwId, nwid, type, projectorId, params) {
  return execAction(actionInstanceNwId, 'DynamicPropertiesActionCR', nwid, type, projectorId, 'exec', params);
}

function getPageTypeOptions(viewInstanceNwId, actionInstanceNwId, nwid, type, projectorId, params) {
  return execAction(actionInstanceNwId, 'ChangePageTypeActionCR', nwid, type, projectorId, 'getProperties', params);
}

function savePageTypeOptions(viewInstanceNwId, actionInstanceNwId, nwid, type, projectorId, params) {
  return execAction(actionInstanceNwId, 'ChangePageTypeActionCR', nwid, type, projectorId, 'exec', params);
}

function getPmtOptions(viewInstanceNwId, actionInstanceNwId, nwid, type, projectorId, params) {
  return execAction(actionInstanceNwId, 'ChangePmtActionCR', nwid, type, projectorId, 'getPmtOptions', params);
}

function changePmt(viewInstanceNwId, actionInstanceNwId, nwid, type, projectorId, params) {
  return execAction(actionInstanceNwId, 'ChangePmtActionCR', nwid, type, projectorId, 'exec', params);
}

function getLockAllowedResources(viewInstanceNwId, actionInstanceNwId, nwid, type, projectorId, params) {
  return execAction(actionInstanceNwId, 'LockActionCR', nwid, type, projectorId, 'getAllowedResources', params);
}

function lock(viewInstanceNwId, actionInstanceNwId, nwid, type, projectorId, params) {
  return execAction(actionInstanceNwId, 'LockActionCR', nwid, type, projectorId, 'exec', params);
}

function deleteObjects(actionInstanceNwId, nwid, type, projectorId, params) {
  return execAction(actionInstanceNwId, 'DeleteAction', nwid, type, projectorId, 'exec', params);
}

function holdElement(viewInstanceNwId, actionInstanceNwId, nwid, type, projectorId, params) {
  return execAction(actionInstanceNwId, 'HoldElementAction', nwid, type, projectorId, 'exec', params);
}

function releaseElement(viewInstanceNwId, actionInstanceNwId, nwid, type, projectorId, params) {
  return execAction(actionInstanceNwId, 'ReleaseElementAction', nwid, type, projectorId, 'exec', params);
}

function mapUnplannedPage(viewInstanceNwId, actionInstanceNwId, nwid, type, projectorId, params) {
  return execAction(actionInstanceNwId, 'MapUnplannedPageAction', nwid, type, projectorId, 'exec', params);
}

function route(viewInstanceNwId, actionInstanceNwId, nwid, type, projectorId, params) {
  return execAction(actionInstanceNwId, 'Route', nwid, type, projectorId, 'exec', params);
}

function sendMail(viewInstanceNwId, actionInstanceNwId, nwid, type, projectorId, params) {
  return execAction(actionInstanceNwId, 'SendMail', nwid, type, projectorId, 'exec', params);
}

function getScriptContent(viewInstanceNwId, actionInstanceNwId, nwid, type, projectorId, params) {
  return execAction(actionInstanceNwId, 'EditScriptActionCR', nwid, type, projectorId, 'getScriptContent', params);
}

function saveScriptContent(viewInstanceNwId, actionInstanceNwId, nwid, type, projectorId, params) {
  return execAction(actionInstanceNwId, 'EditScriptActionCR', nwid, type, projectorId, 'exec', params);
}

function uploadICCProfile(viewInstanceNwId, actionInstanceNwId, nwid, type, projectorId, params) {
  return execAction(actionInstanceNwId, 'UploadICCProfileFileActionCR', nwid, type, projectorId, 'exec', params);
}

function unlockContainer(containerNwId, actionInstanceNwId, nwid, type, projectorId, params) {
  return execAction(actionInstanceNwId, 'UnlockContainerActionCR', nwid, type, projectorId, 'exec', params);
}

function execAction(actionInstanceNwId, actionName, nwid, type, projectorId, scriptName, params) {

  var requestParams = {
    actionInstanceNwId: actionInstanceNwId,
    actionName: actionName,
    nwid: nwid,
    type: type,
    scriptName: scriptName,
    params: JSON.stringify(params),
    command: 'ExecAction',

    projectorId: projectorId,
    clientId: settingsManager.get('id'),
    requestVersion: requestVersion
  };

  return baseData.ajax({
    url: webAppPath + '/servlet/GenericCommandServlet',
    type: 'POST',
    dataType: 'json',
    data: requestParams,
    success: function (res) {
      log.info('Server response to GenericCommandServlet ExecAction Hit');     
    }
  });
}

/**
 * Request is fired whenever to delete events
 *
 * @param  {number} deviceNwId        Newsway id of a module
 * @param  {number} viewInstanceNwId Newsway Id of the view instance
 * @param  {number} actionInstanceNwId newsway Id of the action
 * @param  {string} type        Template name for a resource
 * @param  {number} projectorId ProjectorId which is opened for the particular module on the server side to issue updates
 * @param  {Object} params      Additional request parameters  (including the event nwids)
 */
function deleteEvents(viewInstanceNwId, actionInstanceNwId, deviceNwId, type, projectorId, params) {
  eventsActionRequest('Delete Events', viewInstanceNwId, actionInstanceNwId, deviceNwId, type, projectorId, params);
}

/**
 * Request is fired whenever to redo events
 *
 * @param  {number} deviceNwId        Newsway id of a module
 * @param  {number} viewInstanceNwId Newsway Id of the view instance
 * @param  {number} actionInstanceNwId newsway Id of the action
 * @param  {string} type        Template name for a resource
 * @param  {number} projectorId ProjectorId which is opened for the particular module on the server side to issue updates
 * @param  {Object} params      Additional request parameters  (including the event nwids)
 */
function redoEvents(viewInstanceNwId, actionInstanceNwId, deviceNwId, type, projectorId, params) {
  return eventsActionRequest('Redo Events', viewInstanceNwId, actionInstanceNwId, deviceNwId, type, projectorId, params);
}

/**
 * Request is fired whenever to hold events
 *
 * @param  {number} deviceNwId        Newsway id of a module
 * @param  {number} viewInstanceNwId Newsway Id of the view instance
 * @param  {number} actionInstanceNwId newsway Id of the action
 * @param  {string} type        Template name for a resource
 * @param  {number} projectorId ProjectorId which is opened for the particular module on the server side to issue updates
 * @param  {Object} params      Additional request parameters  (including the event nwids)
 */
function holdEvents(viewInstanceNwId, actionInstanceNwId, deviceNwId, type, projectorId, params) {
  eventsActionRequest('Hold Events', viewInstanceNwId, actionInstanceNwId, deviceNwId, type, projectorId, params);
}


/**
 * Request is fired whenever to unhlod events
 *
 * @param  {number} deviceNwId        Newsway id of a module
 * @param  {number} viewInstanceNwId Newsway Id of the view instance
 * @param  {number} actionInstanceNwId newsway Id of the action
 * @param  {string} type        Template name for a resource
 * @param  {number} projectorId ProjectorId which is opened for the particular module on the server side to issue updates
 * @param  {Object} params      Additional request parameters  (including the event nwids)
 */
function unholdEvents(viewInstanceNwId, actionInstanceNwId, deviceNwId, type, projectorId, params) {
  eventsActionRequest('Release Events', viewInstanceNwId, actionInstanceNwId, deviceNwId, type, projectorId, params);
}

/**
 * Request is fired whenever to lock events
 *
 * @param  {number} deviceNwId        Newsway id of a module
 * @param  {number} viewInstanceNwId Newsway Id of the view instance
 * @param  {number} actionInstanceNwId newsway Id of the action
 * @param  {string} type        Template name for a resource
 * @param  {number} projectorId ProjectorId which is opened for the particular module on the server side to issue updates
 * @param  {Object} params      Additional request parameters  (including the event nwids)
 */
function lockEvents(viewInstanceNwId, actionInstanceNwId, deviceNwId, type, projectorId, params) {
  eventsActionRequest('Lock Events', viewInstanceNwId, actionInstanceNwId, deviceNwId, type, projectorId, params);
}

/**
 * Request is fired whenever to unlock events
 *
 * @param  {number} deviceNwId        Newsway id of a module
 * @param  {number} viewInstanceNwId Newsway Id of the view instance
 * @param  {number} actionInstanceNwId newsway Id of the action
 * @param  {string} type        Template name for a resource
 * @param  {number} projectorId ProjectorId which is opened for the particular module on the server side to issue updates
 * @param  {Object} params      Additional request parameters  (including the event nwids)
 */
function unlockEvents(viewInstanceNwId, actionInstanceNwId, deviceNwId, type, projectorId, params) {
  eventsActionRequest('Unlock Events', viewInstanceNwId, actionInstanceNwId, deviceNwId, type, projectorId, params);
}

function highPrioritisedEvents(viewInstanceNwId, actionInstanceNwId, deviceNwId, type, projectorId, params) {
  eventsActionRequest('Unlock Events', viewInstanceNwId, actionInstanceNwId, deviceNwId, type, projectorId, params, "SaveHighPrioritisedEvent");
}

function getImageData(params) {
  const requestParams = {
    clientId: settingsManager.get('id'),
    requestVersion: requestVersion,
    command: 'ReadImage',
    ...params
  };

  return new Promise((resolve, reject) => {
    baseData.ajax({
      url: webAppPath + '/servlet/GenericCommandServlet',
      type: 'GET',
      data: requestParams,
      success: function (result, status) {
        resolve(result);
      },
      error: function (xhr, status, err) {
        reject(err.message);
      }
    });
  });
}

function getImage(params, callback, context) {
  const requestParams = {
    clientId: settingsManager.get('id'),
    requestVersion: requestVersion,
    command: 'ReadImage',
    ...params
  };

  baseData.ajax({
    url: webAppPath + '/servlet/GenericCommandServlet',
    type: 'GET',
    data: requestParams
  }).done(function (data) {
    if (context && typeof context === 'object') {
      return callback.call(context, data);
    } else {
      return callback(data);
    }
  });
}

/**
 * get url to receive an image from the server
 * @param  {Object}   params   List of module specific parameters, e.g. nwid, rootId, etc.
 * @return  URL String
 */

function getImageUrl(params, isNewService, isRequestVersion) {
  const requestParams = {
    clientId: settingsManager.get('id'),
    requestVersion: isRequestVersion ? requestVersion : '',
    command: isNewService ? 'getImage' : 'ReadImage',
    ...params
  };

  let url = webAppPath + '/servlet/GenericCommandServlet?';
  for (let param in requestParams) {
    if (typeof requestParams[param] !== 'undefined') {
      url += param + "=" + requestParams[param] + "&";
    }
  }
  return url;
}


function getIconsPath(folder, type, fileName) {
  var dfd = baseData.deferred(),
    requestParams = {
      clientId: settingsManager.get('id'),
      requestVersion: requestVersion,
      command: 'getIconsPath',
      folder: folder,
      type: type,
      fileName: fileName
    };
  baseData.ajax({
    url: webAppPath + '/servlet/GenericCommandServlet',
    dataType: 'json',
    data: requestParams,
    success: function (res) {
      dfd.resolve(res.data.iconsPath);
    },
    error: function () {
      dfd.resolve([]);
      log.warn('Get Template Icons Path failed');
    }
  });
  return dfd.promise();
}


/**
 * Request is fired whenever to enable or disable resource
 *
 * @param  {number} operation        approve/reject
 * @param  {string} objectNWId        NW Id of the object to be approved or rejected
 * @param  {string} flowStepNWIDs     NW Id fo the flow steps the object should be approved/rejected at
 * @param  {number} projectorId ProjectorId which is opened for the particular module on the server side to issue updates
 * @param  {Object} params      Additional request parameters
 */
function approveRejectObject(operation, objectNWId, objectType, flowStepNWIDs, projectorId, params) {
  var requestParams;
  requestParams = {
    params: JSON.stringify(params),
    clientId: settingsManager.get('id'),
    requestVersion: requestVersion,
    projectorId: projectorId,
    command: 'approveRejectObject',
    nwid: objectNWId,
    type: objectType,
    operation: operation,
    flowStepNWIds: flowStepNWIDs.join(";")
  };

  baseData.ajax({
    url: webAppPath + '/servlet/GenericCommandServlet',
    type: 'POST',
    dataType: 'json',
    data: requestParams,
    success: function (res) {
      log.info('Server response on Approve/Reject', res);     
    }
  });
}

function approveRejectObjects(objects, projectorId, actionInstanceNwId, params) {
  var requestParams;
  requestParams = {
    params: JSON.stringify(params),
    actionInstanceNwId: actionInstanceNwId,
    clientId: settingsManager.get('id'),
    requestVersion: requestVersion,
    projectorId: projectorId,
    command: 'approveRejectObject',
    objects: JSON.stringify(objects)
  };

  return baseData.ajax({
    url: webAppPath + '/servlet/GenericCommandServlet',
    type: 'POST',
    dataType: 'json',
    data: requestParams,
    success: function (res) {
      log.info('Server response on Approve/Reject', res);      
    }
  });
}


function getGenericCommand(ajaxParams, data) {

  if (typeof ajaxParams === 'undefined' || (typeof ajaxParams.data === 'undefined' && typeof data === 'undefined'))
    log.warn('MISSING PARAMS: getGenericCommand');

  return baseData.ajax({
    url: webAppPath + '/servlet/GenericCommandServlet',
    type: ajaxParams.type || 'GET',
    dataType: ajaxParams.dataType || 'json',
    data: data || ajaxParams.data,
    success: ajaxParams.success,
    error: ajaxParams.error
  });

}

function sendGenericRequest(params, callback) {
  baseData.ajax({
    url: webAppPath + '/servlet/GenericCommandServlet',
    type: 'POST',
    dataType: 'json',
    data: params,
    success: function (result) {
      if (typeof callback == 'function') {
        callback(result.data, params);
      }
    }
  });
}

function genericRequestPromise(params) {
  return new Promise((resolve, reject) => {
    baseData.ajax({
      url: webAppPath + '/servlet/GenericCommandServlet',
      type: 'POST',
      dataType: 'json',
      data: params,
      success: function (result, status) {
        resolve(result.data);
      },
      error: function (xhr, status, err) {
        reject(err.message);
      }
    });
  });
}

/////layout manager functions

function loadFormLayout(nwid, type, callback) {
  var params = {
    command: 'loadLayout',
    nwid: nwid,
    type: type
  };

  if (typeof callback === 'function') {
    sendGenericRequest(params, callback);
  } else {
    return genericRequestPromise(params);
  }
}

function saveFormLayout(nwid, type, layout) {
  var params = {
    command: 'saveLayout',
    nwid: nwid,
    type: type,
    layout: layout
  };
  sendGenericRequest(params);
}

function createPlate(rootId, plate, callback) {
  var params = {
    action: 'createPlate',
    command: 'getLayouManagerActions',
    rootId: rootId,
    plate: plate
  };
  sendGenericRequest(params, callback);
}

function duplicatePlate(rootId, nwid, callback) {
  var params = {
    action: 'duplicatePlate',
    command: 'getLayouManagerActions',
    rootId: rootId,
    nwid: nwid
  };
  sendGenericRequest(params, callback);
}

function loadPlate(rootId, nwid, callback) {
  var params = {
    action: 'loadPlate',
    command: 'getLayouManagerActions',
    rootId: rootId,
    nwid: nwid
  };
  sendGenericRequest(params, callback);
}

function savePlate(rootId, plate, callback) {
  var params = {
    action: 'savePlate',
    command: 'getLayouManagerActions',
    rootId: rootId,
    plate: plate
  };
  sendGenericRequest(params, callback);
}

function savePlateInfo(rootId, plate, callback) {
  var params = {
    action: 'savePlateInfo',
    command: 'getLayouManagerActions',
    rootId: rootId,
    plate: plate
  };
  sendGenericRequest(params, callback);
}

function deletePlate(rootId, nwid, callback) {
  var params = {
    action: 'deletePlate',
    command: 'getLayouManagerActions',
    rootId: rootId,
    nwid: nwid
  };
  sendGenericRequest(params, callback);
}

function createInk(rootId, ink, callback) {
  var params = {
    action: 'createInk',
    command: 'getLayouManagerActions',
    rootId: rootId,
    ink: ink
  };
  sendGenericRequest(params, callback);
}

function duplicateInk(rootId, nwid, callback) {
  var params = {
    action: 'duplicateInk',
    command: 'getLayouManagerActions',
    rootId: rootId,
    nwid: nwid
  };
  sendGenericRequest(params, callback);
}

function loadInk(rootId, nwid, callback) {
  var params = {
    action: 'loadInk',
    command: 'getLayouManagerActions',
    rootId: rootId,
    nwid: nwid
  };
  sendGenericRequest(params, callback);
}

function saveInk(rootId, ink, callback) {
  var params = {
    action: 'saveInk',
    command: 'getLayouManagerActions',
    rootId: rootId,
    ink: ink
  };
  sendGenericRequest(params, callback);
}

function saveInkInfo(rootId, ink, callback) {
  var params = {
    action: 'saveInkInfo',
    command: 'getLayouManagerActions',
    rootId: rootId,
    ink: ink
  };
  sendGenericRequest(params, callback);
}

function deleteInk(rootId, nwid, callback) {
  var params = {
    action: 'deleteInk',
    command: 'getLayouManagerActions',
    rootId: rootId,
    nwid: nwid
  };
  sendGenericRequest(params, callback);
}

function createLayout(rootId, layout, layoutGroupNwid, callback) {
  var params = {
    action: 'createLayout',
    command: 'getLayouManagerActions',
    rootId: rootId,
    layout: layout,
    layoutGroupNwid: layoutGroupNwid
  };
  sendGenericRequest(params, callback);
}

function duplicateLayout(rootId, nwid, layoutGroupNwid, callback) {
  var params = {
    action: 'duplicateLayout',
    command: 'getLayouManagerActions',
    rootId: rootId,
    nwid: nwid,
    layoutGroupNwid: layoutGroupNwid
  };
  sendGenericRequest(params, callback);
}

function loadLayout(rootId, nwid, callback) {
  var params = {
    action: 'loadLayout',
    command: 'getLayouManagerActions',
    rootId: rootId,
    nwid: nwid
  };
  sendGenericRequest(params, callback);
}

function saveLayout(rootId, layout, callback) {
  var params = {
    action: 'saveLayout',
    command: 'getLayouManagerActions',
    rootId: rootId,
    layout: layout
  };
  sendGenericRequest(params, callback);
}

function saveLayoutLink(rootId, layout, layoutGroupNwid, callback) {
  var params = {
    action: 'saveLayoutLink',
    command: 'getLayouManagerActions',
    rootId: rootId,
    layout: layout,
    layoutGroupNwid: layoutGroupNwid
  };
  sendGenericRequest(params, callback);
}

function deleteLayout(rootId, nwid, layoutGroupNwid, callback) {
  var params = {
    action: 'deleteLayout',
    command: 'getLayouManagerActions',
    rootId: rootId,
    nwid: nwid,
    layoutGroupNwid: layoutGroupNwid
  };
  sendGenericRequest(params, callback);
}

function createLayoutGroup(rootId, layoutGroup, callback) {
  var params = {
    action: 'createLayoutGroup',
    command: 'getLayouManagerActions',
    rootId: rootId,
    layoutGroup: layoutGroup
  };
  sendGenericRequest(params, callback);
}

function duplicateLayoutGroup(rootId, nwid, callback) {
  var params = {
    action: 'duplicateLayoutGroup',
    command: 'getLayouManagerActions',
    rootId: rootId,
    nwid: nwid
  };
  sendGenericRequest(params, callback);
}

function saveLayoutGroup(rootId, layoutGroup, callback) {
  var params = {
    action: 'saveLayoutGroup',
    command: 'getLayouManagerActions',
    rootId: rootId,
    layoutGroup: layoutGroup
  };
  sendGenericRequest(params, callback);
}

function deleteLayoutGroup(rootId, nwid, callback) {
  var params = {
    action: 'deleteLayoutGroup',
    command: 'getLayouManagerActions',
    rootId: rootId,
    nwid: nwid
  };
  sendGenericRequest(params, callback);
}

function getRectangles(pageNWID, pageType, command) {
  var dfd = baseData.deferred(),
    requestParams = {
      command: 'getRectangles',
      commandType: command,
      pageNWID: pageNWID,
      pageType: pageType
    };
  baseData.ajax({
    url: webAppPath + '/servlet/GenericCommandServlet',
    //type: 'POST',
    dataType: 'json',
    data: requestParams,
    success: function (res) {
      dfd.resolve(res.data);
    },
    error: function () {
      dfd.resolve([]);
      log.warn('Get Rectangles failed');
    }
  });
  return dfd.promise();
}

function simpleGenericCommand(rootId, rootType, command) {
  var dfd = baseData.deferred(),
    requestParams = {
      command: command,
      breadcrumbNwid: rootId,
      breadcrumbType: rootType
    };
  baseData.ajax({
    url: webAppPath + '/servlet/GenericCommandServlet',
    dataType: 'json',
    data: requestParams,
    success: function (res) {
      dfd.resolve(res.data);
    },
    error: function () {
      dfd.resolve({});
      log.warn(command + ' failed for module');
    }
  });
  return dfd.promise();
}

function getMockTickData(viewNwid, testName, testFileNumber) {
  var dfd = baseData.deferred(),
    requestParams = {
      command: 'getTestTickReponse',
      viewNwid: viewNwid,
      testName: testName,
      fileNumber: testFileNumber
    };
  baseData.ajax({
    url: webAppPath + '/servlet/GenericCommandServlet',
    dataType: 'json',
    data: requestParams,
    success: function (res) {
      dfd.resolve(res.data.testResponse);
    },
    error: function () {
      dfd.resolve([]);
      log.warn('Get Mock Tick Data failed for module');
    }
  });
  return dfd.promise();
}

function saveSetup(actionInstanceNwId, nwid, type, projectorId, newModel, oldModel, scriptName) {
  return execAction(actionInstanceNwId, 'SaveSetup', nwid, type, projectorId, scriptName, {
    newModel: newModel,
    oldModel: oldModel
  });
}

function getPlan(context, nwid, callback, projectorId, values) {
  var requestParams;
  requestParams = {
    rootId: values.rootId,
    rootType: values.rootType,
    instanceNwId: values.instanceNwId,
    clientId: settingsManager.get('id'),
    mode: values.mode,
    edition: values.edition,
    editionModel: JSON.stringify(values.editionModel),
    zones: values.zones,
    editionWasLoaded: values.editionWasLoaded,
    templateName: values.templateName,
    firstStep: values.firstStep,
    publicationName: values.publicationName,
    publicationDate: values.publicationDate,
    editionToChase: values.editionToChase,
    pagesOnly: values.pagesOnly,
    chaseMode: values.chaseMode,
    chaseName: values.chaseName,
    requestVersion: requestVersion,
    projectorId: projectorId,
    command: 'getPlan'
  };


  return baseData.ajax({
    url: webAppPath + '/servlet/GenericCommandServlet',
    type: 'POST',
    dataType: 'json',
    data: requestParams,
    success: function (res) {
      if (callback) {
        callback.call(context, res.data);
      }

      log.info('Server response on Approve/Reject', res);
    }
  });
}

function setPlan(context, nwid, projectorId, values) {
  var requestParams;
  requestParams = {
    rootId: values.rootId,
    rootType: values.rootType,
    instanceNwId: values.instanceNwId,
    model: JSON.stringify(values.model),
    mode: values.mode,
    templateName: values.templateName,
    publicationName: values.publicationName,
    isGlobal: values.isGlobal,
    clientId: settingsManager.get('id'),
    runInQueue: values.runInQueue,
    requestVersion: requestVersion,
    projectorId: projectorId,
    command: 'savePlan'
  };


  return baseData.ajax({
    url: webAppPath + '/servlet/GenericCommandServlet',
    type: 'POST',
    dataType: 'json',
    data: requestParams,
    timeout: 300000
  });
}

function testSetup(actionInstanceNwId, nwid, type, projectorId, params, scriptName, callback, context) {
  var request, requestParams;
  requestParams = {
    actionInstanceNwId: actionInstanceNwId,
    actionName: 'TestSetup',
    nwid: nwid,
    type: type,
    scriptName: scriptName,
    params: JSON.stringify(params),
    command: 'ExecAction',
    projectorId: projectorId,
    clientId: settingsManager.get('id'),
    requestVersion: requestVersion
  };
  request = baseData.ajax({
    url: webAppPath + '/servlet/GenericCommandServlet',
    type: 'POST',
    dataType: 'json',
    data: requestParams
  });
  request.done(function (data) {
    if (context && typeof context === 'object') {
      return callback.call(context, data);
    }
    else {
      return callback(data);
    }
  });
}

module.exports = {
  _name: 'request',
  _type: 'manager',
  _getPreLoginInfo: _getPreLoginInfo,
  retrieveViewLinks,
  retrieveViewLinksBatch,
  getRequestVersion: function () {
    return requestVersion;
  },

  getGenericCommand: getGenericCommand,
  loadChildNodes: loadChildNodes,
  loadDescendantNodes: loadDescendantNodes,
  toggleDevice: toggleDevice,
  toggleSkipDevice: toggleSkipDevice,
  toggleResource: toggleResource,
  abortAllResources: abortAllResources,
  getImage: getImage,
  getImageData: getImageData,
  getImageUrl: getImageUrl,
  getIconsPath: getIconsPath,
  approveRejectObject: approveRejectObject,
  approveRejectObjects: approveRejectObjects,
  deleteEvents: deleteEvents,
  redoEvents: redoEvents,
  holdEvents: holdEvents,
  unholdEvents: unholdEvents,
  mapUnplannedPage: mapUnplannedPage,
  lockEvents: lockEvents,
  unlockEvents: unlockEvents,
  highPrioritisedEvents: highPrioritisedEvents,
  getMockTickData: getMockTickData,
  renameUnplanPage: renameUnplanPage,
  getDynPropOptions: getDynPropOptions,
  changeDynProperties: changeDynProperties,
  getPageTypeOptions: getPageTypeOptions,
  savePageTypeOptions: savePageTypeOptions,
  uploadICCProfile: uploadICCProfile,
  unlockContainer: unlockContainer,

  getPmtOptions: getPmtOptions,
  changePmt: changePmt,
  getLockAllowedResources: getLockAllowedResources,
  lock: lock,

  getScriptContent: getScriptContent,
  saveScriptContent: saveScriptContent,

  deleteObjects: deleteObjects,
  route: route,
  sendMail: sendMail,
  holdElement: holdElement,
  releaseElement: releaseElement,
  startAllWorkflow: startAllWorkflow,
  stopAllWorkflow: stopAllWorkflow,
  getPlan: getPlan,
  setPlan: setPlan,

  saveSetup: saveSetup,

  execAction: execAction,
  simpleGenericCommand: simpleGenericCommand,

  loadFormLayout: loadFormLayout,
  saveFormLayout: saveFormLayout,

  createPlate: createPlate,
  duplicatePlate: duplicatePlate,
  loadPlate: loadPlate,
  savePlate: savePlate,
  savePlateInfo: savePlateInfo,
  deletePlate: deletePlate,

  createInk: createInk,
  duplicateInk: duplicateInk,
  loadInk: loadInk,
  saveInk: saveInk,
  saveInkInfo: saveInkInfo,
  deleteInk: deleteInk,

  createLayout: createLayout,
  duplicateLayout: duplicateLayout,
  loadLayout: loadLayout,
  saveLayout: saveLayout,
  saveLayoutLink: saveLayoutLink,
  deleteLayout: deleteLayout,

  createLayoutGroup: createLayoutGroup,
  duplicateLayoutGroup: duplicateLayoutGroup,
  saveLayoutGroup: saveLayoutGroup,
  deleteLayoutGroup: deleteLayoutGroup,

  sendGenericRequest: sendGenericRequest,
  genericRequestPromise: genericRequestPromise,

  getRectangles: getRectangles,

  testSetup: testSetup,

  rest: rest()

};
