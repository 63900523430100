define(['react', 'react-dom/client', 'sandbox', 'AbstractModule', 'widgets/SimpleForm/src/index', './src/convertor', './src/view'],
  function (React, ReactDOMClient, sandbox, AbstractModule, SimpleForm, convertor, View) {
    'use strict';

    var DEFAULT_DATA = {
      availableLayoutGroups: [],
      availableRules: {
        rules: []
      }
    };

    return AbstractModule.extend({

      folderNwid: null,

      store: null,

      elementNode: null,

      initDone: function () {
        this.reactRoot = ReactDOMClient.createRoot(this.domElement);
        this.onOKButtonClick = this.onOKButtonClick.bind(this);
        this.onCancelButtonClick = this.onCancelButtonClick.bind(this);
      },

      firstTickReceived: function (data) {

        this.folderNwid = data.model.nwid;
        this.store = new SimpleForm.Store({model: {}}, null, this.render.bind(this));

        this.fetchModel();
        this.render();
      },

      tickUpdate: function (data) {

      },

      tickCommit: function () {

      },

      fetchModel: function () {

        var data = {
          command: 'layoutGroupRules',
          params: JSON.stringify({
            folderNWId: this.folderNwid.toString(),
            action: 'get'
          })
        };

        sandbox.request.getGenericCommand({
          data: data,
          type: 'post',
          success: function (res) {
            this.store.dispatch({
              type: 'UPDATE',
              bind: 'model',
              value: convertor.toViewModel(Object.assign({}, DEFAULT_DATA, res.data))
            });
            //this.store.set('model', convertor.toViewModel(Object.assign({}, DEFAULT_DATA, res.data)));
            //this.render();
          }.bind(this)
        });

      },

      onOKButtonClick: function () {
        this.store.dispatch({
          type: 'UPDATE',
          bind: 'okButtonDisabled',
          value: true
        });
        //this.store.set('okButtonDisabled', true);
        var data = {
          command: 'layoutGroupRules',
          params: JSON.stringify({
            folderNWId: this.folderNwid.toString(),
            action: 'set',
            data: convertor.toData(this.store.get('model'))
          })
        };

        sandbox.request.getGenericCommand({
          data: data,
          type: 'post',
          success: function (res) {
            //this.store.set('okButtonDisabled', false);
            this.store.dispatch({
              type: 'UPDATE',
              bind: 'okButtonDisabled',
              value: false
            });
            window.close();
            //console.log(arguments);
          }.bind(this),
          error: function () {
            //console.log(arguments);
            //this.store.set('okButtonDisabled', false);
            this.store.dispatch({
              type: 'UPDATE',
              bind: 'okButtonDisabled',
              value: false
            });
            //this.render();
          }
        });
        this.render();
      },

      onCancelButtonClick: function () {
        window.close();
      },

      render: function () {

        if (this.elementNode === null) {
          this.elementNode = sandbox.dom.find(this.element)[0];
        }

        var view = React.createElement(View, {
          store: this.store,
          onOKButtonClick: this.onOKButtonClick,
          onCancelButtonClick: this.onCancelButtonClick
        });

        this.reactRoot.render(view);

      }

    });

});