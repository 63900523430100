/**
 * @name List
 * @file List component
 *
 * @author Boris
 * @since: 2017-02-19
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ListItem from './ListItem';

export default class List extends Component {
  static propTypes = {
    /**
     * Array of list items containing the following fields:
     *   id (string): item unique ID
     *   name (string): item name displayed as item label in the list
     *   icon (object): item icon (placed before the label) that contains the following properties:
     *     className (string): icon CSS class name
     *     style (object): icon CSS style
     *   selected (bool): defines whether list item is selected
     */
    items: PropTypes.array.isRequired,

    /**
     * CSS class name of root element
     */
    className: PropTypes.string,

    /**
     * CSS style of root element
     */
    style: PropTypes.object,

    /**
     * React component responsible for rendering individual list items
     * Defaults to ListItem.js
     */
    ListItem: PropTypes.func,

    /**
     * Field name of the ID property (optional)
     */
    idField: PropTypes.string,

    /**
     * Callback event handling functions with the following common paramaters:
     *   item (object) - the target list item
     */
    onItemClick: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    ListItem,
    idField: 'id'
  };

  render() {
    const { items, className, style, ListItem, onKeyDown, ...props } = this.props;
    const clsName = `crtx-list ${className}`;

    return (
      <ul className={clsName}
        style={style}
        tabIndex='0'
        onKeyDown={onKeyDown}
      >
        {items.map((item, index) => {
          //console.log('###List.item =>', item[this.props.idField], item.name);
          return <ListItem key={item[this.props.idField]} item={item} {...props} />
        }
        )}
      </ul>
    );
  }

};
