import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogButtonsBar } from 'components/common/dialogs';
import { translate } from 'core/services/localization';
import { Validator, useValidator } from 'components/private/forms';
import NumericInput from 'components/common/inputs/NumericInput';
import Button from 'components/common/buttons/Button';
import { reducer } from './reducer';
import BrowserInput from 'components/common/inputs/BrowserInput';

const initialState = {
  logSize: '',
  saveAt: '',
};

const logSizeValidation = logSize => logSize > 0 && logSize <= 100000;

export const EditCommonSettingsDialog = ({ title, onClose, moduleNwid, logSize, saveAt, onEditCommonSettings }) => {

  const init = initialState => {

    const state = {
      ...initialState,
      logSize,
      saveAt
    };

    return state;
  };

  const [state, dispatch] = useReducer(reducer, initialState, init);

  const { register, validate } = useValidator();

  const handleEditCommonSettings = () => {
    if (!validate()) {
      return;
    }

    onEditCommonSettings(state);
    onClose();
  };

  const handlePathSelect = (e, value) => {
    dispatch({ type: 'UPDATE_PATH_SELECT', path: value });
  };

  const handleLogSizeChange = (e, value) => {
    dispatch({ type: 'UPDATE_LOG_SIZE', size: value });
  };

  return <Dialog title={title}
    autoFocus={false}
    onClose={onClose}
    initialWidth={700}
    initialHeight={300}
    modal={true}>
    <div className='crtx-form-section-container crtx-form-full-height'>
      <div className='crtx-form-two-columns crtx-form-margin-bottom-xsmall'>
        <label>{translate('Path to save the logs:')}</label>
        <Validator register={register} className='crtx-form-element-full-width-validator' rules={['required']}>
          <BrowserInput className='crtx-form-element-xlarge'
            value={state.saveAt}
            viewOrActionNwid={moduleNwid}
            rootUrl={'filesystem'}
            onSelect={handlePathSelect}
            onChange={handlePathSelect}
          />
        </Validator>
        <label>{translate('Log size:')}</label>
        <div className='crtx-form-two-columns'>
          <Validator register={register} rules={['required', () => logSizeValidation(state.logSize)]} message={['', translate('Log size should be between 1 and 100,000')]}>
            <NumericInput
              className='crtx-form-element-under-medium'
              value={state.logSize}
              onChange={handleLogSizeChange}
              allowEmptyValue={true}
              decimalPlaces={0}
            />
          </Validator>
          <label>KB</label>
        </div>
      </div>
    </div>
    <DialogButtonsBar>
      <Button className='primary'
        onClick={handleEditCommonSettings}>{translate('Save')}
      </Button>
      <Button
        onClick={onClose}>{translate('Cancel')}
      </Button>
    </DialogButtonsBar>
  </Dialog>;
};

EditCommonSettingsDialog.propTypes = {
  title: PropTypes.string,
  moduleNwid: PropTypes.string,
  onClose: PropTypes.func,
  logSize: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  saveAt: PropTypes.string,
  onEditCommonSettings: PropTypes.func
};