define(['react', 'prop-types'], function (React, PropTypes) {
  'use strict';

  const { Component } = React;

  return class AccordionPanel extends Component {

    static propTypes = {
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      title: PropTypes.any,
      collapsed: PropTypes.bool,
      style: PropTypes.object,
      className: PropTypes.string,
      onToggleCollapse: PropTypes.func
    }

    static defaultProps = {
      collapsed: false,
      className: '',
      style: {},
      onToggleCollapse: () => { }
    }
    constructor(props) {
      super(props);
      this.animateCollapse = this.animateCollapse.bind(this);
      this.animateExpand = this.animateExpand.bind(this);
      this.expandBodyHeightToContent = this.expandBodyHeightToContent.bind(this);
      this.collapseTransitionEnded = this.collapseTransitionEnded.bind(this);
      this.expandTransitionEnded = this.expandTransitionEnded.bind(this);
      this.addCollapseTransitionListener = this.addCollapseTransitionListener.bind(this);
      this.addExpandTransitionListener = this.addExpandTransitionListener.bind(this);
      this.removeCollapseTransitionListener = this.removeCollapseTransitionListener.bind(this);
      this.removeExpandTransitionListener = this.removeExpandTransitionListener.bind(this);
      this.handleToggleCollapse = this.handleToggleCollapse.bind(this);
      this.renderTitle = this.renderTitle.bind(this);
      this.renderHeader = this.renderHeader.bind(this);
    }


    componentDidMount() {
      if (this.props.collapsed) {
        var { panelBody } = this.refs;
        panelBody.style.display = 'none';
      }
    }

    componentWillReceiveProps(nextProps) {
      if (this.props.collapsed != nextProps.collapsed) {
        this.removeCollapseTransitionListener();
        this.removeExpandTransitionListener();
        nextProps.collapsed ? this.animateCollapse() : this.animateExpand();
      }
    }

    animateCollapse() {
      var { panelBody } = this.refs;
      this.addCollapseTransitionListener();
      this.expandBodyHeightToContent();
      setTimeout(function () {
        panelBody.style.height = '0px';
      }, 0);
    }

    animateExpand() {
      var { panelBody } = this.refs;
      this.addExpandTransitionListener();
      panelBody.style.display = '';
      panelBody.style.height = '0px';
      this.expandBodyHeightToContent();
    }

    expandBodyHeightToContent() {
      var { panelBody, panelContent } = this.refs;
      panelBody.style.height = panelContent.offsetHeight + 'px';
    }

    collapseTransitionEnded() {
      var { panelBody } = this.refs;
      this.removeCollapseTransitionListener();
      panelBody.style.display = 'none';
    }

    expandTransitionEnded() {
      var { panelBody } = this.refs;
      this.removeExpandTransitionListener();
      panelBody.style.height = '';
    }

    addCollapseTransitionListener() {
      //console.log('addCollapseTransitionListener() => transitionend');
      var { panelBody } = this.refs;
      panelBody.addEventListener('transitionend', this.collapseTransitionEnded, false);
    }

    addExpandTransitionListener() {
      //console.log('addExpandTransitionListener() => transitionend');
      var { panelBody } = this.refs;
      panelBody.addEventListener('transitionend', this.expandTransitionEnded, false);
    }

    removeCollapseTransitionListener() {
      //console.log('removeCollapseTransitionListener() => transitionend');
      var { panelBody } = this.refs;
      panelBody.removeEventListener('transitionend', this.collapseTransitionEnded, false);
    }

    removeExpandTransitionListener() {
      //console.log('removeExpandTransitionListener() => transitionend');
      var { panelBody } = this.refs;
      panelBody.removeEventListener('transitionend', this.expandTransitionEnded, false);
    }

    handleToggleCollapse(e) {
      this.props.onToggleCollapse(this.props.id);

      e.preventDefault();
      e.stopPropagation();
    }

    renderTitle() {
      var { title } = this.props;

      return (
        <div className="collapsible-panel-title-content">
          {title}
        </div>
      );
    }

    renderHeader() {
      return (
        <div className="collapsible-panel-title" onClick={this.handleToggleCollapse}>
          {this.renderTitle()}
        </div>
      );
    }

    render() {
      var { style, className, children, collapsed } = this.props;

      var css = 'collapsible-panel' + (collapsed ? ' collapsed' : '');
      return (
        <div ref="panel"
          className={[css, className].join(' ')}
          style={style}>

          {this.renderHeader()}
          <div ref="panelBody" className="collapsible-panel-body">
            <div ref="panelContent" className="collapsible-panel-content">
              {children}
            </div>
          </div>

        </div>
      );
    }

  }

});
