import React from 'react';
import NumericInput from 'components/common/inputs/NumericInput';
import InputCell from './InputCell';

function NumericInputCell({ allowEmptyValue = true, size = 4, ...props }) {
  return (
    <InputCell InputComponent={NumericInput} allowEmptyValue={allowEmptyValue} size={size} {...props} />
  );
}

export default NumericInputCell;