import React, { Component } from 'react';
import {getFolderCurrentDate} from 'core/dates';
import {translate} from 'core/services/localization';
import ChatContent from './ChatContent';
import FilterToggleButton from "./FilterToggleButton";
import AdaptiveHeightContainer from "./AdaptiveHeightContainer";
import settingsManager from 'core/managers/settings';
import TextInput from 'components/common/inputs/TextInput';
import toastService from 'core/services/toastService';
import PropTypes from 'prop-types';
import ContactsList from './ContactsList';
import ChannelsList from './ChannelsList';
import { setChannelMessagesAreRead } from './utils';
import { restPost } from 'core/managers/rest2';

const labels = {
  all: translate('1:1'),
  group: translate('1:Group'),
  custom: translate('Custom'),
  noMailsMessage: translate("We didn't find anything to show here"),
  addChannelParticipants: translate('Add channel participants'),
  newChat: translate('New Chat'),
  newChannel: translate('New Channel'),
  enterTheChannelSubject: translate('Enter the channel subject'),
  next: translate('Next'),
  error: translate('Error'),
  failed: translate('Failed'),
  success: translate('Success'),
  subject: translate('Subject'),
  enterSearchKeywordsHere: translate('Enter search keywords here...'),
  createNewChannel: translate('Create new channel'),
  clickThePlusToStartChat: translate('Click the PLUS to start chat')
};

const CHANNEL_TYPES_TO_REST = {
  oneToOne: 'O',
  oneToGroup: 'G',
  custom: 'C'
};

const MATERIAL_ICONS = {
  ARROW_BACK: 'arrow_back',
  ADD: 'add',
  CHECK: 'check',
  CLOSE: 'close',
  ARROW_FORWARD: 'arrow_forward',
  SEARCH: 'search'
};

const CHANNEL_TYPES = {
  custom: 'chat/channel/custom',
  oneToOne: 'chat/channel/one_to_one',
  oneToGroup: 'chat/channel/group'
};

const CONTACT_TYPES = {
  user: 'controlpanel/profiles/users/user',
  group: 'controlpanel/profiles/groups/group'
};

export default class Chat extends Component {
  static propTypes = {
    store: PropTypes.object,
    viewSettings: PropTypes.object

  };

  constructor(props) {
    super(props);
    this.store = this.props.store;
  }

  handleShowChannelContent = (selectedId) => {

    const { channelsByNwid } = this.store.getState();
    const { viewSettings } = this.props;
    const viewInstanceNwId = viewSettings.nwid;    
    const currentUser = settingsManager.get('user');

    this.store.dispatch({ type: 'SET_SELECTED_ID', selectedId: selectedId });
    setChannelMessagesAreRead(channelsByNwid[selectedId], viewInstanceNwId, currentUser);
    this.onShowChannelContent(selectedId);
  }

  handleShowChatContent = (selectedContact) => {
    this.store.dispatch({ type: 'SET_SELECTED_CONTACT', selectedContact: selectedContact });

    const { allChannelsNwids, channelsByNwid } = this.store.getState();
    let selectedChannelId = undefined;

    if (selectedContact.groupFullName) {
      selectedChannelId = allChannelsNwids.find(channelNwid => channelsByNwid[channelNwid].userNames.length === 0 &&
        channelsByNwid[channelNwid].groups.find((group) => group.groupFullName === selectedContact.groupFullName) &&
        channelsByNwid[channelNwid].type === CHANNEL_TYPES.oneToGroup);

    } else {
      selectedChannelId = allChannelsNwids.find(channelNwid => channelsByNwid[channelNwid].groups.length === 0 &&
        channelsByNwid[channelNwid].userNames.find((name) => name === selectedContact.userName) &&
        channelsByNwid[channelNwid].type === CHANNEL_TYPES.oneToOne);
    }

    if (selectedChannelId) {
      this.store.dispatch({ type: 'SET_SELECTED_ID', selectedId: selectedChannelId });
    }
    if (selectedChannelId && channelsByNwid[selectedChannelId].type !== CHANNEL_TYPES.custom) {
      this.onShowChannelContent(selectedChannelId);
    } else this.showEmptyContent();

  }

  handleShowAllMailsClick = () => {
    this.store.dispatch({ type: 'TOGGLE_USER_SYSTEM_MAILS' });
  }

  handleUnreadMailsClick = () => {
    this.store.dispatch({ type: 'TOGGLE_FILTERED_USER_SYSTEM_MAILS' });
  }

  handleAddNewChatClick = () => {
    this.store.dispatch({ type: 'SET_SELECTED_CONTACT' });
    this.store.dispatch({ type: 'SET_CONTACTS_VIEW' });
    this.store.dispatch({ type: 'UPDATE_CHANNEL_SUBJECT' });
  }

  handleBackToChannelsListClick = () => {
    this.store.dispatch({ type: 'SET_CHATS_VIEW_BACK' });
  }

  handleBackToNewChatClick = () => {
    this.store.dispatch({ type: 'SET_NEW_CHAT_VIEW' });
    this.store.dispatch({ type: 'GET_ALL_CONTACTS' });

  }

  handleNewChannelClick = () => {
    this.store.dispatch({ type: 'SET_PARTICIPANTS_VIEW' });
  }

  handleAddParticipant = (user) => {
    const { participants, contactListToView } = this.store.getState();
    let removeIndex;

    participants.push(user);
    if (user.type === CONTACT_TYPES.user) {
      removeIndex = contactListToView.users.map(contact => contact.nwid).indexOf(user.nwid);
      contactListToView.users.splice(removeIndex, 1);
    } else {
      removeIndex = contactListToView.groups.map(contact => contact.nwid).indexOf(user.nwid);
      contactListToView.groups.splice(removeIndex, 1);
    }

    this.store.dispatch({ type: 'UPDATE_PARTICIPANTS_AND_CONTACTS', participants: participants, contacts: contactListToView });
  }

  handleRemoveParticipant = (user) => {

    const { participants, contactListToView } = this.store.getState();
    let removeIndex = participants.map(contact => contact.nwid).indexOf(user.nwid);

    participants.splice(removeIndex, 1);
    if (user.type === CONTACT_TYPES.user) {
      contactListToView.users.push(user);
    } else {
      contactListToView.groups.push(user);
    }

    this.store.dispatch({ type: 'UPDATE_PARTICIPANTS_AND_CONTACTS', participants: participants, contacts: contactListToView });

  }

  handleGoNextButtonClick = () => {
    this.store.dispatch({ type: 'SET_CHANNEL_SUBJECT_VIEW' });
  }

  handleSetChannelSubject = (e, subject) => {
    this.store.dispatch({ type: 'UPDATE_CHANNEL_SUBJECT', subject: subject });
  }

  handleCreateChannelButtonClick = () => {
    this.createChannel().then(result => {
      this.store.dispatch({ type: 'UPDATE_NEW_CHANNEL_WITH_NWID', newChannelNwid: result.newChannelNwid });
      this.store.dispatch({ type: 'SET_SELECTED_ID', selectedId: result.newChannelNwid });
      this.focusTextarea();
    }).catch(() => {
      toastService.createToast('bottom-right', labels.error, `Channel not created`, 'error', undefined, false);
    });
  }

  sendMessage = (channelNwid) => {
    const { newMessage, draftsByChannelNwid, channelsByNwid, newMessagesByNwid } = this.store.getState();
    const { viewSettings } = this.props;
    const viewInstanceNwId = viewSettings.nwid;   
    const channel = channelsByNwid[channelNwid];

    let restData = {};

    if (channel) {
      restData = {
        from: settingsManager.get('user').nwid,
        content: draftsByChannelNwid[channel.nwid] ? draftsByChannelNwid[channel.nwid] : newMessage
      };
    } else {
      restData = {
        from: settingsManager.get('user').nwid,
        content: newMessage

      };
    }
    this.store.dispatch({ type: 'SET_EMPTY_SEARCHAREA' });
    this.store.dispatch({ type: 'SET_EMPTY_DRAFT' });

    let newMessageRestData = {
      ...restData,
      from: settingsManager.get('user').name,
      messageNwid: new Date().getTime(),
      sentDateTime: getFolderCurrentDate(),
    };

    this.store.dispatch({
      type: 'ADD_NEW_MESSAGE',
      newMessageRestData: newMessageRestData
    });

    return restPost(viewInstanceNwId, `chat/channels/${channelNwid}/messages`, restData).then((res) => {

      this.store.dispatch({
        type: 'UPDATE_NEW_MESSAGE',
        newMessageNwid: res.newMessageNwid,
        newMessageRestData: newMessageRestData
      });

      this.store.dispatch({
        type: 'UPDATE_SEND_AVAILABILITY',
        sendAvailability: true
      });
    }).catch(() => {
      toastService.createToast('bottom-right', labels.error, `Message not sent`, 'error', undefined, false);
    });

  }

  createChannel = () => {
    const { participants, channelSubject, selectedContact, isNewChatView } = this.store.getState();
    const { viewSettings } = this.props;
    const viewInstanceNwId = viewSettings.nwid;
    let recipients = participants.length > 0 ? participants.map((participant) => participant.nwid) : [selectedContact.nwid];
    
    this.store.dispatch({ type: 'SET_EMPTY_CHAT' });
    this.store.dispatch({ type: 'SET_SELECTED_ID', selectedId: undefined });
    const restData = {
      recipients: recipients,
      subject: channelSubject ? channelSubject : (selectedContact.type === CONTACT_TYPES.user ? selectedContact.userName : selectedContact.groupFullName),
      userId: settingsManager.get('user').nwid,
      channelType: (recipients.length === 1 && selectedContact && selectedContact.type === CONTACT_TYPES.user) ? CHANNEL_TYPES_TO_REST.oneToOne :
        (recipients.length === 1 && selectedContact && selectedContact.type === CONTACT_TYPES.group) ? CHANNEL_TYPES_TO_REST.oneToGroup : CHANNEL_TYPES_TO_REST.custom,

    };

    this.store.dispatch({ type: 'ADD_NEW_CHANNEL', newChannel: restData });

    return restPost(viewInstanceNwId, `chat/channels`, restData);    
  }

  handleSearchValueChange = (e, searchValue) => {
    this.store.dispatch({ type: 'UPDATE_SEARCH_VALUE', searchValue: searchValue });
    this.store.dispatch({ type: 'GET_FILTERED_CHANNELS' });
  }

  handleSendMessageButtonClick = () => {
    const { selectedId, selectedContact, isChatsView, allChannelsNwids, channelsByNwid } = this.store.getState();
    let channelNwid = selectedId;

    if (selectedId) {
      this.sendMessage(channelNwid).then((res) => {
        this.focusTextarea();
      });
    } else if (isChatsView && !(selectedId)) {
      channelNwid = allChannelsNwids.find(function (channelNwid) {
        return channelsByNwid[channelNwid].userNames.length === 1 && channelsByNwid[channelNwid].type !== CHANNEL_TYPES.custom && channelsByNwid[channelNwid].userNames[0] === selectedContact.userName ;
      });

      if (channelNwid) {
        this.sendMessage(channelNwid).then((res) => {
          this.focusTextarea();
        });
      } else {
        this.createChannel().then((res) => {
          this.store.dispatch({ type: 'UPDATE_NEW_CHANNEL_WITH_NWID', newChannelNwid: res.newChannelNwid });
          this.store.dispatch({ type: 'SET_SELECTED_ID', selectedId: res.newChannelNwid });
          setTimeout(() => {
            this.sendMessage(res.newChannelNwid).then((res) => {
              this.focusTextarea();
            });
          }, 500);
        }).catch(() => {
          toastService.createToast('bottom-right', labels.error, `Channel not created`, 'error', undefined, false);
        });
      }
    }
  }


  focusTextarea = () => {
    if (this.textareaRef) {
      this.textareaRef.focus();
    }
  }

  showEmptyContent = () => {
    this.store.dispatch({ type: 'SET_EMPTY_CONTENT' });
    this.store.dispatch({ type: 'SET_CHATS_VIEW' });
    this.focusTextarea();
  }

  onShowChannelContent = (selectedId) => {
    this.store.dispatch({ type: 'SET_CHAT_CONTENT', selectedId: selectedId });
    this.store.dispatch({ type: 'SET_CHATS_VIEW' });
    this.focusTextarea();

  }

  renderList = () => {
    const { userChannelsNwids } = this.store.getState();

    return <ChannelsList ref={el => this.listRef = el} channelNwids={userChannelsNwids} store={this.store} onRowClick={this.handleShowChannelContent} />;

  }

  renderChatContent = () => {

    return <ChatContent ref={el => this.textareaRef = el} handleSendMessageButtonClick={this.handleSendMessageButtonClick} store={this.store} />;

  }

  renderFilters = () => {
    const { isAllChecked, isUnreadChecked, isFiltersAvailable } = this.store.getState();

    if (!isFiltersAvailable) return;

    return <div className='crtx-chat-channels-filters-line'>
      <FilterToggleButton checked={isAllChecked} onClick={this.handleShowAllMailsClick} textButton={labels.all} />
      <FilterToggleButton checked={isUnreadChecked} onClick={this.handleUnreadMailsClick} textButton={labels.group} />
      <FilterToggleButton checked={false} textButton={labels.custom} />
    </div>;
  }

  renderParticipantsContactList = () => {
    const { contactListToView, isParticipantsView } = this.store.getState();

    return <ContactsList contacts={contactListToView} store={this.store} onRowClick={isParticipantsView ? this.handleAddParticipant : this.handleShowChatContent} />;
  }

  renderNewChatToolbar = () => {
    const { isChannelSubjectView } = this.store.getState();

    return <div className="ctrx-new-chat-toolbar">
      <div className="crtx-chat-back" onClick={isChannelSubjectView ? this.handleNewChannelClick : this.handleBackToChannelsListClick}>
        <i className='material-icons'>{MATERIAL_ICONS.ARROW_BACK}</i>
      </div>
      <div className="crtx-new-chat-title">{labels.newChat}</div>
    </div>;
  }

  renderParticipant = (participant) => {

    return <div key={participant.nwid} className="ctrx-new-chat-participant">
      <div>{participant.userName ? participant.userName : participant.groupFullName}</div>
      <div className="ctrx-chat-participant-remove" onClick={(e) => this.handleRemoveParticipant(participant)}>
        <i className='material-icons'>{MATERIAL_ICONS.CLOSE}</i>
      </div>
    </div>;
  }

  renderAddParticipantsToolbar = () => {

    return <div className="ctrx-new-chat-toolbar">
      <div className="crtx-chat-back" onClick={this.handleBackToNewChatClick}>
        <i className='material-icons'>{MATERIAL_ICONS.ARROW_BACK}</i>
      </div>
      <div className="crtx-new-chat-title">{labels.addChannelParticipants}</div>
    </div>;
  }

  renderAddNewChannelButton = () => {

    return <div className='crtx-chat-add-new-channel'>
      <div className='crtx-chat-add-new-channel-button' onClick={this.handleNewChannelClick}>{labels.newChannel}</div>
    </div>;
  }

  renderParticipantsList = () => {
    const { participants } = this.store.getState();
    const participantsContainer = <div className="crtx-new-chat-participants">{participants.map(this.renderParticipant)}</div>;

    return <AdaptiveHeightContainer children={participantsContainer} maxRows={5} containerPadding={14} rowHeight={32} />
  }

  renderNextStep = () => {
    return <div className='crtx-chat-go-next-step'>
      <div className='crtx-chat-go-next-button' onClick={this.handleGoNextButtonClick}>
        <i className='material-icons'>{MATERIAL_ICONS.ARROW_FORWARD}</i>
      </div>
    </div>;
  }

  renderGetChannelSubjectStep = () => {
    const { channelSubject } = this.store.getState();

    return <React.Fragment>
      <div className='chat-new-chat-subject-title'>{labels.subject}</div>
      <div className='crtx-chat-go-next-step'>
        <TextInput className='chat-new-message-input'
          inputType='text'
          value={channelSubject}
          maxLength={255}
          placeholder={labels.enterTheChannelSubject}
          onChange={this.handleSetChannelSubject}
          autoFocus={true}
        />
      </div></React.Fragment>;
  }

  renderCreateChannelButton = () => {

    return <div className='crtx-chat-go-next-step'>
      <div className='crtx-chat-go-next-button' onClick={this.handleCreateChannelButtonClick}>
        <i className='material-icons'>{MATERIAL_ICONS.CHECK}</i>
      </div>
    </div>;
  }

  renderChatsToolbar = () => {
    const { searchValue, allChannelsNwids } = this.store.getState();

    if (allChannelsNwids.length > 0) {
      return <React.Fragment>
        <div className='crtx-chat-search-text'><TextInput className='crtx-chat-search-text-input'
          inputType='text'
          value={searchValue}
          maxLength={255}
          placeholder={labels.enterSearchKeywordsHere}
          onChange={this.handleSearchValueChange} /></div>
        <i className='material-icons'>{MATERIAL_ICONS.SEARCH}</i>
        <div className="crtx-chat-add-new-button" title={labels.createNewChannel} onClick={this.handleAddNewChatClick}>
          <i className='material-icons'>{MATERIAL_ICONS.ADD}</i></div>
      </React.Fragment>;
    } else {
      return <React.Fragment><div className="crtx-chat-add-new-button-title">{labels.clickThePlusToStartChat}</div>
        <div className="crtx-chat-add-new-button" title={labels.createNewChannel} onClick={this.handleAddNewChatClick}>
          <i className='material-icons'>{MATERIAL_ICONS.ADD}</i>
        </div></React.Fragment>;
    }
  }

  componentDidUpdate() {
    const { isChatsView, selectedId } = this.store.getState();

    if (isChatsView) {
      if (selectedId) {
        if (this.listRef) {
          this.listRef.setScrollPosition();
        }
      }
    }
  }

  render() {

    const { isNewChatView, isParticipantsView, isChannelSubjectView, goToChannelSubject, isChatsView, channelSubject } = this.store.getState();

    return <div className='crtx-chat-container'>
      <div className="crtx-chat-container-left-side-main">
        <div className="crtx-chat-container-left-side">

          <div className="crtx-chat-toolbar">
            {isChatsView && this.renderChatsToolbar()}
            {(isNewChatView) && this.renderNewChatToolbar()}
            {isChannelSubjectView && this.renderNewChatToolbar()}
            {(isParticipantsView) && this.renderAddParticipantsToolbar()}
          </div>
          {isNewChatView && this.renderAddNewChannelButton()}
          {isParticipantsView && this.renderParticipantsList()}
          {isChannelSubjectView && this.renderGetChannelSubjectStep()}
          <div className='crtx-chat-channels-list'>

            {this.renderFilters()}
            {isChatsView && this.renderList()}
            {(isParticipantsView || isNewChatView) && this.renderParticipantsContactList()}

          </div>
          {goToChannelSubject && isParticipantsView && this.renderNextStep()}
          {channelSubject && channelSubject !== '' && channelSubject.trim() !== '' && isChannelSubjectView && this.renderCreateChannelButton()}

        </div>
      </div>
      <div className='crtx-chat-channel-content'>
        {this.renderChatContent()}
      </div>
    </div>;
  }

}