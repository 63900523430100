import { min } from 'utilities/array';

export const RULER_THICKNESS = 18;

export const CANVAS_AREA = {
  RULER_CORNER: 'RULER_CORNER',
  LEFT_RULER: 'LEFT_RULER',
  TOP_RULER: 'TOP_RULER',
  INSIDE: 'INSIDE',
  OUTSIDE: 'OUTSIDE',
};

export const deg2rad = deg => deg * Math.PI / 180;

export const rad2deg = rad => rad * 180 / Math.PI;

export const getPointRelativeToOrigin = (point, origin) => {
  if (!point || !origin) {
    return;
  }

  return { x: point.x - origin.x, y: point.y - origin.y };
};

export const getDistanceToPoint = (point) => Math.sqrt(Math.pow(point.x, 2) + Math.pow(point.y, 2));

export const getDistanceBetweenPoints = (a, b) => getDistanceToPoint(getPointRelativeToOrigin(b, a));

export const getPointByRotation = ({ x, y }, { x: centerX, y: centerY }, angle) => {
  const rad = deg2rad(angle);
  const offsetX = x - centerX;
  const offsetY = y - centerY;
  const rotatedX = offsetX * Math.cos(rad) - offsetY * Math.sin(rad);
  const rotatedY = offsetX * Math.sin(rad) + offsetY * Math.cos(rad);

  return {
    x: rotatedX + centerX,
    y: rotatedY + centerY
  };
};

export const measureTextSize = (ctx, text = '') => {
  const metrics = ctx.measureText(text);

  return {
    width: metrics.actualBoundingBoxLeft + metrics.actualBoundingBoxRight,
    height: metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent,
  };
};

export const toPixelCenter = (coord) => {
  return Math.trunc(coord) + Math.sign(coord) * 0.5;
};

export const translatePoint = (point, dx, dy) => {
  if (!point) {
    return;
  }

  const { x, y } = point;

  return { x: x + dx, y: y + dy };
};

export const translatePoints = (points, dx, dy) => {
  return points.map(p => translatePoint(p, dx, dy));
};

export const transformPoint = (point, domMatrix) => {
  const { x, y } = point;
  const domPoint = new DOMPoint(x, y);

  const p = domPoint.matrixTransform(domMatrix);

  return { x: p.x, y: p.y };
};

export const transformPointArray = (points = [], domMatrix) => {
  return points.map(p => transformPoint(p, domMatrix));
};

export const getImageTopLeft = (image, domMatrix) => {
  const { x, y, width, height } = image;

  let imagePoints = [{ x, y }, { x: x + width, y }, { x, y: y + height }, { x: x + width, y: y + height }];
  imagePoints = translatePoints(imagePoints, -width / 2, -height / 2);
  const transformedPoints = transformPointArray(imagePoints, domMatrix);

  return { x: min(transformedPoints, p => p.x), y: min(transformedPoints, p => p.y) };
};
