/**
 * Created by elad on 10/20/2015.
 */
define(['sandbox', 'ember', './views/MainView', 'AbstractModule'], function (sandbox, Ember, MainView, AbstractModule) {
  'use strict';

  function firstTickHandler(model, preferences) {
    sortModelArrays.apply(this, [model]);
    this.view.set('model', model);
    this.view.set('module', this);
    this.view.replaceIn(this.element);
  }

  function sortModelArrays(model) {
    var excludedKeys = ["links", "selected"];
    for (var i in model) {
      if (Array.isArray(model[i])) {
        if (excludedKeys.indexOf(i) !== -1) {
          continue;
        }
        var sortingDescription = model[i + ":sorting"];
        if (sortingDescription) {
          var sortingTokens = sortingDescription.split(":");
          if (sortingTokens.length === 2) {
            sandbox.sorting.sort(model[i], sortingTokens[1], sortingTokens[0]);
          }
        }
        sortModelArrays.apply(this, [model[i]]);
      } else if (sandbox.jsUtils.isObject(model[i])) {
        sortModelArrays.apply(this, [model[i]]);
      }
    }
  }


  return AbstractModule.extend({
    initDone: function () {
      var that = this;
      this.view = MainView.create({
      });
      this.toolbar = this.createToolbar();
    },

    destroy: function () {
      this._super();
      this.view.destroy();
    },

    tickUpdate: function(data) {
      for (var i=0; i < data.model.length; i++) {
        var action = data.model[i].action;
        switch (action.toLowerCase()) {
          case "add":
            //handleAdd.call(this, data.model[i]);
            break;
          case "update":
            //handleUpdate.call(this, data.model[i]);
            break;
          case "remove":
            //handleDelete.call(this, data.model[i]);
            break;
        }
      }
    },

    firstTickReceived: function (data) {
      var that = this;
      Ember.run(function () {
        firstTickHandler.call(that, data.model, data.preferences);
      });
    }
  });
});