import React, { Component } from 'react';
import iconService from 'core/services/iconService';

export default class extends Component {
  static defaultProps = {
    module: undefined,
    icon: undefined,
    count: 0
  };

  getIconSrc = (module, icon) => {
    return iconService.getModuleIcon(module || 'MyBirdeye', icon);
  };

  getStyle = (count) => {
    var opacity = count > 0 ? '1' : '.1';
    return {
      opacity: opacity,
    };
  };

  render() {
    var { module, icon, count, title } = this.props,
      iconSrc = this.getIconSrc(module, icon),
      style = this.getStyle(count);
      
    return <div className="IndicationCounter" style={style} title={title}>
      <img src={iconSrc} />
      <label className="IndicationCounterLabel">{count}</label>
    </div>;
  }
}