import React from 'react';
import { createRoot } from 'react-dom/client';
import sandbox from 'sandbox';
import { Store, Form } from 'components/private/forms';
import SetupContainer from 'components/private/setups/SetupContainer';
import { toJS } from 'mobx';

const isUndefined = o => typeof o === 'undefined';
const isFunction = o => typeof o === 'function';

const FormSetup = (DecoratedComponent) => {
  const afterSaving = DecoratedComponent.afterSaving;
  const FormDecoratedComponent = Form(DecoratedComponent, DecoratedComponent.onDataReceived, DecoratedComponent.onApply);

  return class Setup {

    handleApplyCallback = () => {
    };

    loading = false;

    constructor(callback = () => {
    }) {
      this.store = undefined;
      this.callback = callback;
    }

    initDone = () => {
      if (!this.domElement) {
        this.domElement = (this.win || window).document.getElementById(this.element.slice(1));
      }

      this.reactRoot = createRoot(this.domElement);

      if (typeof DecoratedComponent.load === 'string') {
        this.restLoad();
      }

      if (typeof DecoratedComponent.load === 'function') {
        this.loadIsAFunction();
      }
    };

    createStoreAndRender = (data) => {
      this.store = new Store(data);
      this._render();
    };

    loadIsAFunction = () => {
      let loadedResult = DecoratedComponent.load(this);

      if (loadedResult && typeof loadedResult.then === 'function') {
        loadedResult.then(data => {
          this.createStoreAndRender(data);
        });
      } else if (typeof loadedResult === 'object') {
        this.createStoreAndRender(loadedResult);
      }
    };

    restLoad = () => {
      const rest = sandbox.request.rest(this.nwid);

      rest.get(DecoratedComponent.load, {
        success: function (data) {
          this.createStoreAndRender(data);
        },
        error: function (ex) {
          console.error('FormSetup ERROR: function restLoad', ex);
        }
      });
    };

    setLoading = (loading) => {
      this.loading = loading;
      this._render();
    };

    executeRest = (state) => {
      const rest = sandbox.request.rest(this.nwid);
      rest.post(DecoratedComponent.save, {
        data: state,
        success: function () {
          if (typeof afterSaving === 'function') {
            afterSaving.call(undefined, state, this);
          }
        },
        error: function (ex) {
          console.error('FormSetup ERROR: function restSave', ex);
        }
      });
    };

    executeSaveAsFunction = (buttonName, state) => {
      let resultOfSave = DecoratedComponent.save(state, this, buttonName);

      if (resultOfSave && typeof resultOfSave.then === 'function') {
        resultOfSave.then(data => {
          if (data !== false) {
            if (typeof afterSaving === 'function') {
              afterSaving.call(undefined, state, this, buttonName);
            }
          }
        });
      } else if (typeof afterSaving === 'function') {
        afterSaving.call(undefined, state, this, buttonName);
      }

      return resultOfSave;
    };

    executeSaveAsAction = (state) => {
      let result;

      try {
        let oldModel = toJS(this.store.getInitialState('model'));
        let saveAction = typeof this.viewActions === 'undefined' ? undefined : this.viewActions.findBy('actionClass', 'SaveSetupAction');
        if (saveAction && saveAction.isApplicable && saveAction.isApplicable()) {
          result = saveAction.execute(state.model, oldModel);
        }

        if (typeof afterSaving === 'function') {
          if (typeof result?.then === 'function') {
            result.then(() => {
              afterSaving.call(undefined, state.model, this);
            });
          } else {
            afterSaving.call(undefined, state.model, this);
          }
        }
      } catch (ex) {
        console.error('FormSetup ERROR: function saveUsingSaveAction', ex);
      }

      return result;
    };

    onApply = (applyCallback = () => {
    }) => {
      this.handleApplyCallback = applyCallback;

      return this;
    };

    save = (buttonName, state) => {
      let result;

      if (typeof DecoratedComponent.save === 'string') {
        result = this.executeRest(state);
      } else if (typeof DecoratedComponent.save === 'function') {
        result = this.executeSaveAsFunction(buttonName, state);
      } else {
        result = this.executeSaveAsAction(state);
      }

      return result;
    };

    handleApply = (state, isValid, buttonName = '', shouldSave = true, shouldClose = true) => {
      let resultOfSave;
      if (shouldSave && isValid) {
        this.setLoading(true);
        resultOfSave = this.save(buttonName, state);
        this.callback(buttonName, state);
        this.handleApplyCallback(buttonName, state, resultOfSave);
      }

      if ((shouldSave && shouldClose && isValid) || (!shouldSave && shouldClose)) {
        if (resultOfSave && typeof resultOfSave.then === 'function') {
          resultOfSave.then(response => {
            this.setLoading(false);
            if (isUndefined(response) || isUndefined(response.error)) {
              this.handleClose();
            }
          }).catch(() => this.setLoading(false));
        } else {
          this.handleClose();
        }
      }
    };

    handleClose = () => {
      this.reactRoot.unmount();
      this.container.close();
    };

    firstTickReceived = (data) => {
      if (typeof DecoratedComponent.load === 'undefined') {
        this.createStoreAndRender(data);
      }
    };

    tickUpdate = () => {
    };

    destroy = () => {
      this.handleClose();
    };

    _render = () => {
      const path = typeof DecoratedComponent.load === 'undefined' ? 'model' : '';

      this.reactRoot.render(
        <SetupContainer
          store={this.store}
          loading={this.loading}
          buttons={isFunction(DecoratedComponent.buttons) ? DecoratedComponent.buttons(this) : undefined}
          onApply={this.handleApply}
          onClose={this.handleClose}
        >
          <FormDecoratedComponent
            path={path}
            store={this.store}
            element={this.domElement}
            setupNwid={this.nwid}
            module={this}
            setLoading={this.setLoading}
          />
        </SetupContainer>);
    };

  };
};

module.exports = FormSetup;
