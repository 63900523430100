/**
 * @name TreeDataAdapter
 * @file Inherits from TreeDataAdapter of BaseTreeView and adds specific to the Plan Tree functionality.
 *
 * @author Boris
 * @since: 2021-08-03
 */

import TreeDataAdapterBase from '../BaseTreeView/TreeDataAdapter';
import { getFolderCurrentDate, addDays } from 'core/dates';

export default class TreeDataAdapter extends TreeDataAdapterBase {
  getInitialFilterOptions(data) {
    const {daysBefore = '', daysAfter = '', daysBeforeEnabled = false, daysAfterEnabled = false} = data.GuiSettings || {};
    const prefs = data.preferences || {};

    const options = {
      daysBefore: daysBeforeEnabled ? String(daysBefore) : prefs.daysBefore,
      daysAfter: daysAfterEnabled ? String(daysAfter) : prefs.daysAfter,
      daysBeforeReadOnly: daysBeforeEnabled,
      daysAfterReadOnly: daysAfterEnabled,
    };

    return options;
  }

  filterChildNodes(node) {
    if (!node.children || node.children.length <= 0) {
      return node;
    }

    const { name = '', daysBefore = '', daysAfter = '' } = this.getFilterOptions() || {};

    node = { ...node }; // we do not want to change the source node
    const children = node.children.filter(node => {
      const { modelItem } = node;
      let pubName, pubDate;
      if (node.type === 'publicationname' || node.type === 'publication' && modelItem.role !== 'publicationdate') {
        pubName = node.text;
      } else if (node.type === 'publicationdate' || node.type === 'publication' && modelItem.role === 'publicationdate') {
        pubDate = modelItem.pubDate;
      }

      let nameMatch = true;
      let dateMatch = true;
      if (name && pubName) {
        const strings = name.trim().toLowerCase().split(/\s+/);
        const pubNameLower = pubName.toLowerCase();
        nameMatch = strings.every(s => pubNameLower.indexOf(s) >= 0);
      }

      if (pubDate && (daysBefore || daysAfter)) {
        const d = getFolderCurrentDate();
        const today = new Date(d.getFullYear(), d.getMonth(), d.getDate()); // discard time part of date
        dateMatch = !daysBefore || pubDate >= addDays(today, -daysBefore);
        dateMatch = dateMatch && (!daysAfter || pubDate <= addDays(today, daysAfter));
      }

      return nameMatch && dateMatch;
    });

    node.children = children.map(child => this.filterChildNodes(child));

    if (node.type === 'folder') {
      node.children = node.children.filter(child =>
        child.type === 'publicationdate' || child.type === 'publicationname' ? child.children.length > 0 : true);
    }

    return node;
  }

  isFilterActive() {
    const { name = '', daysBefore = '', daysAfter = '' } = this.getFilterOptions() || {};

    return !!(name || daysBefore || daysAfter);
  }
}
