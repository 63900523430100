import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { INCH, PRECISION, getConversionFactor, getUnitSymbol, getDecimalPlaces } from 'utilities/lengthUnits';
import ConversionInput from './ConversionInput';

const LengthInput = forwardRef(
  function LengthInput(
    {
      unit = INCH,
      ...props
    }, ref) {

    return (
      <ConversionInput
        {...props}
        ref={ref}
        unitSymbol={getUnitSymbol(unit)}
        factor={getConversionFactor(unit)}
        decimalPlaces={getDecimalPlaces(unit, PRECISION.HIGH)}
      />
    );
  });

LengthInput.propTypes = {
  ...ConversionInput.propTypes,
  unit: PropTypes.string,
};

export default LengthInput;
