/**
 * Created by moshemal on 12/14/14.
 */

define(['class', 'sandbox/icons', 'go'], function (Class, icons, go) {
	'use strict'
	var make = go.GraphObject.make;

	function iteratorToData(iterator){
		var res = [];
		while (iterator.next()) {
			res.push(iterator.value.data);
		}
		return res;
	}

	function createPalettes (initObj, div) {
		this.palettes =
				make(go.Palette, div,  // must name or refer to the DIV HTML element
						{
							maxSelectionCount: 1,
							nodeTemplateMap: initObj.nodeTemplateMap,  // share the templates used by this.graph
							model: new go.GraphLinksModel(initObj.palettes),
							layout: make(go.LayeredDigraphLayout),
							scale: 0.85
						});
    this.palettes.layout.columnSpacing = 5;
	}
	function init(initObj){
		this._initObj = initObj;
		var input = document.createElement("input");
		var palettesDiv = document.createElement("div");
		var inputContainer = document.createElement("div");
		inputContainer.setAttribute('id', "wf-input-container");
		inputContainer.setAttribute('style', "display: flex; flex-direction: row; justify-content: space-between;");

		input.type = "text";
		var filter = this.filter.bind(this);
		input.onkeyup = function(event){
			filter(event.target.value);
		};
		var container = document.getElementById(initObj.element);
		input.setAttribute('style', "width: calc(100% - 22px);");
		palettesDiv.setAttribute('id', "wf-canvas-container");
		palettesDiv.setAttribute('style', "width: 100%; height: calc(100% - 20px); display: flex; flex: 1; flex-direction: column; justify-content: flex-start;");
		

		inputContainer.appendChild(input);
		var close = document.createElement("a");
		close.innerHTML = "X";
		close.setAttribute('style', "cursor: pointer; padding: 0px 4px;display: flex; align-items: center;");
		var destroy = this.destroy.bind(this);
		close.onclick = function(){
			if(initObj && initObj.closeFunction){
				if (initObj.closeFunction() === false){
					return;
				}
			}
			destroy();
		};

		inputContainer.appendChild(close);
		container.appendChild(inputContainer);
		container.appendChild(palettesDiv);
		createPalettes.call(this, initObj, palettesDiv);
	}

	function filter (str) {
		var paletteArr  = this._initObj.palettes;
		var fieldName = this._initObj.labelProperty;
		var filtered = paletteArr.filter(function(e){
			return e[fieldName].toLowerCase().search(str.toLowerCase()) >= 0;
		});
		this.palettes.animationManager.isEnabled = false;
		this.palettes.model = new go.GraphLinksModel(filtered);
	}
	function destroy () {
		if (this.destroyed){
			return;
		}
		this.palettes.clear();
		var container = document.getElementById(this._initObj.element);
    if (container){
      container.innerHTML = "";
    }
		this._initObj = null;
		this.palettes = null;
		this.destroyed = true;
	};

	return Class.extend({
		init: init,
		filter: filter,
		destroy: destroy
	}, "Palettes");
});