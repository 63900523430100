import React from 'react';
import { createRoot } from 'react-dom/client';
import { translate } from 'core/services/localization';
import sandbox from 'sandbox';
import AbstractModule from 'AbstractModule';
import TreeDataAdapter from './TreeDataAdapter';
import Tree from 'components/common/tree/Tree';
import TreeNode from "./TreeNode";
import { ExportFolderDialog } from './dialogs/ExportFolderDialog';
import { ImportFolderDialog } from './dialogs/ImportFolderDialog';
import { CreateEditDuplicateFolderDialog } from './dialogs/CreateEditDuplicateFolderDialog';
import { DIALOG_TYPE } from "./constants";

const APPLICABLE_TYPES = ['folder', 'publicationdate', 'publicationname', 'publication', 'edition', 'zone', 'productionruns', 'productionrun', 'planpress'];

export default AbstractModule.extend({
  initDone: function () {
    this.treeNodeGetter = this.treeNodeGetter.bind(this);
    this.handleExpanderClick = this.handleExpanderClick.bind(this);
    this.handleNodeClick = this.handleNodeClick.bind(this);
    this.handleNodeContextMenu = this.handleNodeContextMenu.bind(this);
    this.handleSelectMenuItem = this.handleSelectMenuItem.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleFilterRefresh = this.handleFilterRefresh.bind(this);
    this.handleCloseExportFolderDialog = this.handleCloseExportFolderDialog.bind(this);
    this.handleCloseImportFolderDialog = this.handleCloseImportFolderDialog.bind(this);
    this.handleCloseDialogAndDuplicateFolder = this.handleCloseDialogAndDuplicateFolder.bind(this);
    this.handleCloseDialogAndCreateFolder = this.handleCloseDialogAndCreateFolder.bind(this);
    this.handleCloseDialogAndEditFolder = this.handleCloseDialogAndEditFolder.bind(this);

    this.dataAdapter = this.createTreeDataAdapter();
    this.folderMenu = this.createFolderMenu(this.dataAdapter);
    this.duplicateFolderProps = {};
    this.importFolderProps = {};
    this.exportFolderProps = {};
    this.createFolderProps = {};
    this.editFolderProps = {};
    this.dialogType = '';
    this.rootNwid = null;

    this.reactRoot = createRoot(this.domElement);

    this.clientActions = [
      {
        name: 'expand_all_tree_nodes',
        label: translate('Expand All Tree Nodes'),
        triggers: ['popup'],
        types: APPLICABLE_TYPES,
        _isApplicable: true,
        execute: function (nodes) {
          if (nodes && nodes[0]) {
            this.expandAllNodes(nodes[0]);
          }
        }.bind(this)
      },
      {
        name: 'collapse_all_tree_nodes',
        label: translate('Collapse All Tree Nodes'),
        triggers: ['popup'],
        types: APPLICABLE_TYPES,
        _isApplicable: true,
        execute: function (nodes) {
          if (nodes && nodes[0]) {
            this.collapseAllNodes(nodes[0]);
          }
        }.bind(this)
      },
    ];
  },

  createTreeDataAdapter: function () {
    return new TreeDataAdapter({
      module: this
    });
  },

  createFolderMenu: function (dataAdapter) {
    // create Folder Menu in the derived class if needed
    return null;
  },

  destroy: function () {
    this._super();
    this.reactRoot.unmount();
  },

  firstTickReceived: function (data) {
    this.preferences = data.preferences || {};

    this.dataAdapter.firstTickReceived(data);

    this.dataAdapter.expandAllLoadedNodes();
  },

  tickUpdate: function (data) {
    this.dataAdapter.tickUpdate(data);
  },

  savePreferences: function (preferences) {
    if (!preferences) {
      return;
    }

    this.preferences = Object.assign(this.preferences, preferences);
    sandbox.preferences.savePreferences({
      nwid: this.nwid,
      projectorId: this.projectorId,
    }, this.preferences);
  },

  getTreeNodeComponent: function (node) {
    return TreeNode;
  },

  treeNodeGetter: function (node, nodePath, nodeProps) {
    const TreeNodeComponent = this.getTreeNodeComponent(node);

    return (
      <TreeNodeComponent node={node}
                         nodePath={nodePath}
                         nodeProps={nodeProps}
                         onSelectMenuItem={this.handleSelectMenuItem}
      />
    );
  },

  expandAllNodes: function (modelItem) {
    this.dataAdapter.expandAllNodes(modelItem.id);
    this.render();
  },

  collapseAllNodes: function (modelItem) {
    this.dataAdapter.collapseAllNodes(modelItem.id);
    this.render();
  },

  handleExpanderClick: function (event, node, nodePath, expanded) {
    if (expanded) {
      if (event.ctrlKey || event.metaKey) {
        this.dataAdapter.expandAllNodes(node.id);
      } else {
        this.dataAdapter.expandNode(node.id);
      }
    } else {
      if (event.ctrlKey || event.metaKey) {
        this.dataAdapter.collapseAllNodes(node.id);
      } else {
        this.dataAdapter.collapseNode(node.id);
      }
    }

    this.render();
  },

  handleNodeClick: function (event, node, nodePath) {

    this.dataAdapter.selectNode(node, true);
    this.render();

    node.modelItem.name = this.dataAdapter.getNodeName(node);
    this.navigateByViewLink(node.modelItem);
  },

  handleNodeContextMenu: function (event, node, nodePath) {

    this.dataAdapter.selectNode(node);
    this.render();

    node.modelItem.name = this.dataAdapter.getNodeName(node);
    this.showContextMenu(node.modelItem, [node.modelItem], event).then(selectedAction => {
      this.dataAdapter.discardSelectedNode();
    });
  },

  handleSelectMenuItem(event, node, nodePath, menuItemId) {

    console.log('handleSelectMenuItem() => event, node, nodePath, menuItemId:', event, node, nodePath, menuItemId);
    if (menuItemId === 'new') {
      this.folderMenu.openCreateEditDuplicateFolderDialog(event, DIALOG_TYPE[0]);
    } else if (menuItemId === 'duplicate') {
      this.folderMenu.openCreateEditDuplicateFolderDialog(event, DIALOG_TYPE[2], node.modelItem);
    } else if (menuItemId === 'importFolder') {
      this.folderMenu.importFolder(node.modelItem.nwid);
    } else if (menuItemId === 'exportFolder') {
      this.folderMenu.exportFolder(node.modelItem);
    } else {
      this.folderMenu.changeActiveFolder(menuItemId);
    }
  },

  getFilterComponent: function () {
  },

  handleFilterChange: function (event, filterOptions, shouldPersistChanges) {
    this.dataAdapter.setFilterOptions(filterOptions);

    if (shouldPersistChanges) {
      this.savePreferences(filterOptions);
    }

    this.render();
  },

  handleFilterRefresh: function () {
    this.dataAdapter.filterViewModel();
  },

  handleCloseExportFolderDialog: function () {
    this.exportFolderProps.showExportDialog = false;
    this.render();
  },

  handleCloseDialogAndDuplicateFolder: function (folderName) {
    this.duplicateFolderProps.showDuplicateFolderDialog = false;
    const { duplicateAction, folderNwid } = this.duplicateFolderProps;
    this.folderMenu.duplicateFolder(folderName, duplicateAction, folderNwid);
    this.render();
  },

  handleCloseDialogAndCreateFolder: function (folderName) {
    this.createFolderProps.showCreateFolderDialog = false;
    const { createAction } = this.createFolderProps;
    this.folderMenu.createFolder(folderName, createAction);
    this.render();
  },

  handleCloseDialogAndEditFolder: function (folderName) {
    this.editFolderProps.showEditFolderDialog = false;
    const { editAction, folderNwid } = this.editFolderProps;
    this.folderMenu.editFolder(folderName, editAction, folderNwid);
    this.render();
  },

  handleCloseDialog: function () {
    this.createFolderProps.showCreateFolderDialog = false;
    this.editFolderProps.showEditFolderDialog = false;
    this.duplicateFolderProps.showDuplicateFolderDialog = false;

    this.render();
  },


  handleCloseImportFolderDialog: function () {
    this.importFolderProps.showImportDialog = false;
    this.render();
  },

  renderFilter: function () {
    const Filter = this.getFilterComponent();
    if (Filter) {
      const filterOptions = this.dataAdapter.getFilterOptions();

      return (
        <Filter
          filterOptions={filterOptions}
          onChange={this.handleFilterChange}
          onRefresh={this.handleFilterRefresh}
        />
      );
    }
  },

  renderTree: function () {
    const viewModel = this.dataAdapter.getViewModel();

    return (
      <Tree
        nodes={viewModel.children}
        treeNodeGetter={this.treeNodeGetter}
        onExpanderClick={this.handleExpanderClick}
        onNodeClick={this.handleNodeClick}
        onNodeContextMenu={this.handleNodeContextMenu}
      />
    );
  },

  renderExportFolderDialog: function () {
    return <ExportFolderDialog
      onClose={this.handleCloseExportFolderDialog}
      folderName={this.exportFolderProps.folderToExportName}
      exportAction={this.exportFolderProps.exportAction}
      rootNwid={this.rootNwid}
    />;
  },

  renderImportFolderDialog: function () {
    return <ImportFolderDialog
      onClose={this.handleCloseImportFolderDialog}
      importAction={this.importFolderProps.importAction}
    />;
  },

  renderCreateEditDuplicateFolderDialog: function () {
    return <CreateEditDuplicateFolderDialog
      onCloseAndDuplicate={(folderName) => this.handleCloseDialogAndDuplicateFolder(folderName)}
      onCloseAndCreate={(folderName) => this.handleCloseDialogAndCreateFolder(folderName)}
      onCloseAndEdit={(folderName) => this.handleCloseDialogAndEditFolder(folderName)}
      onCloseDialog={() => this.handleCloseDialog()}
      dataAdapter={this.dataAdapter}
      dialogType={this.dialogType}
      suggestedFolderName={this.dialogType === DIALOG_TYPE[0] ? this.createFolderProps.suggestedFolderName :
        (this.dialogType === DIALOG_TYPE[1] ? this.editFolderProps.suggestedFolderName : this.duplicateFolderProps.suggestedFolderName)}
    />;
  },

  render: function () {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.doRender();
    }, 0);
  },

  doRender: function () {
    //***TEST BEGIN
    // var t1 = performance.now();
    //***TEST END

    this.reactRoot.render(
      <div className='crtx-tree-panel'>
        {this.renderFilter()}
        {this.renderTree()}
        {this.exportFolderProps.showExportDialog && this.renderExportFolderDialog()}
        {this.importFolderProps.showImportDialog && this.renderImportFolderDialog()}'
        {(this.createFolderProps.showCreateFolderDialog ||
            this.editFolderProps.showEditFolderDialog ||
            this.duplicateFolderProps.showDuplicateFolderDialog) &&
          this.renderCreateEditDuplicateFolderDialog()}
      </div>);

    //***TEST BEGIN
    // var t2 = performance.now();
    // console.log('### BaseTreeView.doRender() =>', this.name, t2 - t1);
    //***TEST END
  }

});
