/**
 * @name Graph
 * @author Moshe Malka
 */

import Class from 'class';
import go from 'go';
import icons from 'core/services/iconService';
import jsUtils from 'base/jsUtils';
import { replaceSpecialChars } from 'utilities/regex';
import graphUtils from './graphUtils';

var make = go.GraphObject.make,  // for conciseness in defining templates
  DEFAULT_ICON_LOC = new go.Point(16, 17),
  NODE_MIN_SIZE = new go.Size(54, 54),
  ICON_MARGIN = new go.Margin(3, 0, 0, 3),
  COLORS = {
    original: 'transparent',
    hover: '#eff5ff',
    selected: '#b4d2ff',
    line: '#aec7d5'
  };

///////////////////////////////////////////////////////////////////////
//    STATIC FUNCTIONS
///////////////////////////////////////////////////////////////////////

function chackAndmergeNodesPreferences(nodes, preferences) {
  if (typeof preferences === 'undefined' || Object.keys(preferences).length === 0 ||
    nodes.length !== Object.keys(preferences.nodes).length) {
    return false;
  }
  var allGood = true;

  nodes.forEach(function (curr) {
    if (preferences.nodes[curr.nwid] && preferences.nodes[curr.nwid].deltaX &&
      preferences.nodes[curr.nwid].deltaY) {
      curr.deltaX = preferences.nodes[curr.nwid].deltaX;
      curr.deltaY = preferences.nodes[curr.nwid].deltaY;
    } else {
      curr.deltaX = undefined;
      curr.deltaY = undefined;
      allGood = false;
    }
  });
  return allGood;
}

function toLocation(data, node) {
  if (node.data.deltaX !== undefined && node.data.deltaY !== undefined) {
    return new go.Point(node.data.deltaX, node.data.deltaY);
  } else {
    return new go.Point(NaN, NaN);//false;
  }
  //return new go.Point(node.data.deltaX, node.data.deltaY);
};

function fromLocation(loc, data) {
  //		data.x = loc.x;
  //		data.y = loc.y;
  data.deltaX = loc.x;
  data.deltaY = loc.y;
};

function toIconPath(data, node) {

}

function nodeToIconUrl(type, picture) {
  var e = picture.panel.panel.part.data,
    url = "";
  if (e.iconName && e.iconName !== "void") {
    url = type + "/" + e.iconName;
  } else {
    url = type;
  }
  return icons.getSVGIconPath(url);
}

//#########################################################################################
//########               Private members                         ##########################
//#########################################################################################

function defineGraphContextMenu(contextMenuFunc) {
  var cxMenu = this.graph.toolManager.contextMenuTool;
  var graph = this.graph;
  cxMenu.showContextMenu = function (contextmenu, object) {
    var selected = graph.selection.toArray().map(function (e) {
      return e.data;
    });

    contextMenuFunc(graph.lastInput.event, object.data, selected);

    // Remember
    this.currentContextMenu = contextmenu;
  };
  cxMenu.hideContextMenu = function () {
    if (this.currentContextMenu === null) {
      return;
    }
    this.currentContextMenu = null;

    // InputEvent object NOT js object
    var inputEvent = graph.lastInput;

    //the key is a String
    if (inputEvent.key !== "Esc") {
      return;
    }
    inputEvent.bubbles = true;
  };
}

function makePort(portId, maxLinks) {
  var L = (portId === "L"),
    R = (portId === "R");
  // the port is basically just a small transparent square
  var port = make(go.Shape, "Circle", {
    fill: null,  // not seen, by default; set to a translucent gray by showSmallPorts, defined below
    stroke: null,
    desiredSize: new go.Size(8, 8),
    position: L ? new go.Point(0, 23) : new go.Point(45, 23),
    portId: portId,
    toSpot: go.Spot.Left,
    fromSpot: go.Spot.Right,
    toLinkable: L ? true : false,
    fromLinkable: R ? true : false,
    cursor: "pointer"  // show a different cursor to indicate potential link point
  });
  if (L && maxLinks !== undefined) {
    port.toMaxLinks = maxLinks;
  }
  if (R && maxLinks !== undefined) {
    port.fromMaxLinks = maxLinks;
  }
  return port;
}

function isExist(label, node, nodes, labelProperty) {
  return nodes.some(function (curr) {
    return label === curr[labelProperty] && curr.nwid !== node.nwid;
  });
}

function labelUpdate(label, node) {
  var nodes = this.exportNodesData(),
    counter = 1,
    name = label,
    labelProperty = this._initObj.node.labelProperty,
    curr = {},
    iterator = this.graph.nodes;

  while (iterator.next()) {
    curr = iterator.value;
    if (curr.data.nwid === node.nwid) {
      break;
    }
  }

  while (isExist(name, node, nodes, labelProperty)) {
    name = label + "_" + counter;
    counter += 1;
  }
  if (node[labelProperty] !== name) {
    name = replaceSpecialChars(name);
    node[labelProperty] = name;
  }

  if (typeof node.nwid !== "undefined" && typeof node.copiedFrom === "undefined") {
    node['copiedFrom'] = label;
  }
  curr.updateTargetBindings();
  return name;
}

function createNodeTemplate(initObj) {
  initObj = initObj || {};
  var iconContainer = make(go.Panel, { name: "container" }, go.Panel.Vertical,
    make(go.Panel, {
      name: 'Icon',
      minSize: NODE_MIN_SIZE
    },
      make(go.Shape, {
        figure: "RoundedRectangle",
        position: DEFAULT_ICON_LOC,
        width: 20, height: 20,
        fill: "LightGray",
        stroke: "LightSlateGray",
        strokeWidth: 2
      }),
      make(go.Picture, { margin: ICON_MARGIN }, new go.Binding("source", "type", nodeToIconUrl)),
      new go.Binding("background", "isSelected", function (s) {
        return (s ? COLORS.selected : COLORS.original);
      }).ofObject(""),
      makePort("L", initObj.toMaxLinks),
      makePort("R", initObj.fromMaxLinks)
    )
  );
  if (initObj.labelProperty) {
    iconContainer.add(
      make(go.TextBlock, {
        name: "label",
        margin: 5,
        isMultiline: false,
        editable: initObj.editableLabel ? true : false

      },

        new go.Binding("text", initObj.labelProperty).makeTwoWay(labelUpdate.bind(this))
      )
    );
  }
  if (initObj.typeProperty) {
    iconContainer.insertAt(0,
      make(go.TextBlock, {
        margin: 5
      },
        new go.Binding("text", initObj.typeProperty)
      )
    );
  }
  var node = make(go.Node,
    { // We make a dummy context menu so that the contextMenuTool will activate,
      // but we don't use this adornment
      contextMenu: make(go.Adornment, go.Panel.Horizontal),
      //doubleClick: nodeDoubleClick.bind(this),
      selectionAdorned: false, // no Adornment: instead change panel background color by binding to Node.isSelected
      mouseEnter: function (input, node) {
        if (node.isSelected) {
          return;
        }
        node.findObject("Icon").background = COLORS.hover;
      },
      mouseLeave: function (input, node) {
        if (node.isSelected) {
          return;
        }
        node.findObject("Icon").background = COLORS.original;
      }
    },
    //	new go.Binding("icon", "type", nodeToIconName),
    new go.Binding("location", "", toLocation).makeTwoWay(fromLocation),
    iconContainer
  );
  if (initObj.doubleClick) {
    node.doubleClick = function (event, node) {
      initObj.doubleClick(event.event, node.data);
    };

  }
  return node;
}

function createLinkTemplate() {
  return make('Link', {
    routing: go.Link.AvoidsNodes,
    corner: 2,
    curve: go.Link.JumpOver,
    curviness: 1,
    selectable: false,
    fromPortId: "R",
    toPortId: "L"
  },
    make(go.Shape, {
      strokeWidth: 2,
      stroke: COLORS.line
    }
    ),
    make(go.Shape, {
      toArrow: "OpenTriangle",
      strokeWidth: 2,
      stroke: COLORS.line
    }
    )
  );
}

function makeGroupPort(portId, maxLinks) {
  var L = (portId === "L"),
    R = (portId === "R");
  // the port is basically just a small transparent square
  var port = make(go.Shape, "Circle", {
    fill: "white",  // not seen, by default; set to a translucent gray by showSmallPorts, defined below
    stroke: null,
    desiredSize: new go.Size(8, 8),
    alignment: L ? go.Spot.Left : go.Spot.Right,  // align the port on the main Shape
    alignmentFocus: L ? go.Spot.Left : go.Spot.Right,  // just inside the Shape
    portId: portId,
    toSpot: go.Spot.Left,
    fromSpot: go.Spot.Right,
    toLinkable: L ? true : false,
    fromLinkable: R ? true : false,
    cursor: "pointer"  // show a different cursor to indicate potential link point
  });
  if (L && maxLinks !== undefined) {
    port.toMaxLinks = maxLinks;
  }
  if (R && maxLinks !== undefined) {
    port.fromMaxLinks = maxLinks;
  }
  return port;
}

function gridGroupNodes(groups) {
  if (groups) {
    groups = Array.isArray(groups) || [groups];
  } else {
    return;

    //var g = this.graph;
    //groups = g.selection.toArray().filter(function(elem){
    //	return elem.data && elem.data.isGroup;
    //});
  }
  groups.forEach(function (group) {
    var partsIter = group.memberParts;
    var maxWidth = 0;
    var maxHeight = 0;
    partsIter.each(function (node) {
      if (node instanceof go.Node) {
        var currWidth = node.measuredBounds.width;
        var currHeight = node.measuredBounds.height;
        maxWidth = currWidth > maxWidth ? currWidth : maxWidth;
        maxHeight = currHeight > maxHeight ? currHeight : maxHeight;
      }
    });
    partsIter.each(function (node) {
      if (node instanceof go.Node) {
        node.findObject("container").minSize = new go.Size(maxWidth, maxHeight);
      }
    });
    var layout = new go.GridLayout();
    layout.wrappingColumn = Math.ceil(Math.sqrt(partsIter.count));

    layout.comparer = function (n1, n2) {
      return n1.data["label"].localeCompare(n2.data["label"]);
    };
    layout.doLayout(partsIter);
  });
}

function groupMemberRemoved(g, p) {
  if (p instanceof go.Node) {
    p.findObject("container").minSize = new go.Size(0, 0);
  }
}

function createGroupTemplate(initObj) {
  initObj = initObj ? initObj : {};
  return make(go.Group, "Auto",
    {
      memberAdded: gridGroupNodes,
      memberRemoved: groupMemberRemoved
    },
    make(go.Shape, "RoundedRectangle", // surrounds everything
      { fill: "rgba(128,128,128,0.33)", minSize: new go.Size(40, 50) }),
    make(go.Panel, "Horizontal",
      makeGroupPort("L"),
      make(go.Panel, "Vertical",  // position header above the subgraph
        { defaultAlignment: go.Spot.Left },
        make(go.Panel, "Horizontal",  // the header
          { defaultAlignment: go.Spot.Top }
          //  make("SubGraphExpanderButton"),  // this Panel acts as a Button
          //  make(go.TextBlock,     // group title near top, next to button
          //    {
          //		margin: 5,
          //		isMultiline: false,
          //		editable: initObj && initObj.editableLabel ? true : false
          //	},
          //    new go.Binding("text", initObj.labelProperty || "label").makeTwoWay())
        ),
        make(go.Placeholder,     // represents area for all member parts
          { padding: new go.Margin(0, 0), background: "white" })
      ),
      makeGroupPort("R")
    ),
    new go.Binding("location", "", toLocation).makeTwoWay(fromLocation)
  );
}

function createSimpleDiagram(initObj) {
  return make(go.Diagram, initObj.element, {
    initialContentAlignment: go.Spot.Center,
    hasHorizontalScrollbar: false,
    hasVerticalScrollbar: false,
    allowDelete: false,
    allowCopy: false,
    //allowMove: false,
    //allowClipboard: false,
    nodeTemplate: this._createNodeTemplate(initObj.node),
    linkTemplate: this._createLinkTemplate(),
    groupTemplate: createGroupTemplate(initObj.group)
  });
}

function iteratorToElems(iterator, prop) {
  var res = [];
  while (iterator.next()) {
    res.push(prop ? iterator.value[prop] : iterator.value);
  }
  return res;
}

function iteratorToData(iterator) {
  return iteratorToElems(iterator, "data");
}

//#########################################################################################
//#################                 PUBLIC                         ########################
//#########################################################################################

function addChangedListener(callback) {
  this.graph.model.addChangedListener(callback);
}

function exportTransitions() {
  var model = this.graph.model,
    graph = this.graph,
    fromProp = model.linkFromKeyProperty,
    toProp = model.linkToKeyProperty,
    nodeProp = model.nodeKeyProperty;

  var res = [];
  var transitions = this.graph.links;
  transitions.each(function (currLink) {
    //link with two regular(not group) nodes
    if (!currLink.toNode.data.isGroup && !currLink.fromNode.data.isGroup) {
      res.push(currLink.data);
      return;
    }
    if (currLink.toNode.data.isGroup) {
      var members = currLink.toNode.memberParts;
      members.each(function (member) {
        var linksIntoMember = member.findLinksInto ? member.findLinksInto() : [];
        if (member.findLinksInto && !member.findLinksInto().any(function (l) {
          //the link is from a node inside the group
          return l.fromNode.data.group === currLink.toNode.data[nodeProp];
        })) {
          var newLink = {};
          newLink[fromProp] = currLink.data[fromProp];
          newLink[toProp] = member.data[nodeProp];
          res.push(newLink);
        }
      });
    }
    if (currLink.fromNode.data.isGroup) {
      var members = currLink.fromNode.memberParts;
      members.each(function (member) {
        if (member.findLinksOutOf && !member.findLinksOutOf().any(function (l) {
          //the link is from a node inside the group
          return l.toNode.data.group === currLink.fromNode.data[nodeProp];
        })) {
          var newLink = {};
          newLink[fromProp] = member.data[nodeProp];
          newLink[toProp] = currLink.data[toProp];
          res.push(newLink);
        }
      });
    }
  });

  return res;
}

function exportNodesData() {
  return iteratorToData(this.graph.nodes).filter(function (n) {
    return !n.isGroup;
  });
}

function getPreferences() {
  if (this.graph.nodes.count === 0) {
    return;
  }

  let preferences = {
    scale: this.graph.scale,
    nodes: {},
    groups: {}
  };

  const nodeIterator = this.graph.nodes;

  while (nodeIterator.next()) {
    var node = nodeIterator.value;
    preferences.nodes[node.data.nwid] = {
      deltaX: node.location.x,
      deltaY: node.location.y
    };
    if (node.data.group) {
      preferences.nodes[node.data.nwid].group = node.data.group;
    }
    if (node.data.isGroup) {
      var groupeNodeData = jsUtils.cloneDeep(node.data);
      groupeNodeData.inLinks = iteratorToData(node.findLinksInto());
      groupeNodeData.outLinks = iteratorToData(node.findLinksOutOf());
      groupeNodeData.members = iteratorToData(node.memberParts).map(function (n) {
        return n.nwid;
      });
      preferences.groups[groupeNodeData.nwid] = groupeNodeData;
    }
  }

  return preferences;
}

function _toInternalModel(dataObj) {
  var nodes = dataObj.nodeDataArray,
    links = dataObj.linkDataArray,
    nodeProp = dataObj.nodeKeyProperty,
    preferences = dataObj.preferences,
    fromProp = dataObj.linkFromKeyProperty,
    toProp = dataObj.linkToKeyProperty,
    res = jsUtils.cloneDeep(dataObj);

  var dictionaries = graphUtils.createClusteredLinksDictionaries(nodes, links, nodeProp, toProp, fromProp);
  var newLinks = dictionaries.links;
  var nodeDict = dictionaries.nodeDict;
  var groupDict = dictionaries.groupDict;

  var newNodes = [];

  Object.keys(nodeDict).forEach(function (nId) {
    var currNode = nodeDict[nId].data;
    //var currPrefs = preferences.nodes ? preferences.nodes[currNode[nodeProp]] : null;
    //if (currPrefs){
    //  //add positions
    //  currNode.deltaX = currPrefs.deltaX ? currPrefs.deltaX : undefined;
    //  currNode.deltaY = currPrefs.deltaY ? currPrefs.deltaY : undefined;
    //}
    newNodes.push(currNode);
  });

  Object.keys(groupDict).forEach(function (nId) {
    var currNode = groupDict[nId].data;
    //var currPrefs = preferences.nodes ? preferences.nodes[currNode[nodeProp]] : null;
    //if (currPrefs){
    //  //add positions
    //  currNode.deltaX = currPrefs.deltaX ? currPrefs.deltaX : undefined;
    //  currNode.deltaY = currPrefs.deltaY ? currPrefs.deltaY : undefined;
    //}
    newNodes.push(currNode);
  });

  res.nodeDataArray = newNodes;
  res.linkDataArray = newLinks;
  return res;
}

function insertData(dataObj) {
  var nodes = dataObj.nodeDataArray,
    preferences = dataObj.preferences;
  var graph = this.graph;

  if (preferences && preferences.scale) {
    graph.initialScale = preferences.scale;
  } else {
    graph.initialAutoScale = go.Diagram.Uniform;
  }

  var model = this._toInternalModel(dataObj);

  graph.model = make(go.GraphLinksModel,
    {
      nodeKeyProperty: dataObj.nodeKeyProperty,
      linkFromKeyProperty: dataObj.linkFromKeyProperty,
      linkToKeyProperty: dataObj.linkToKeyProperty,
      nodeDataArray: model.nodeDataArray, //nodes,
      linkDataArray: model.linkDataArray //define the links
    });
  // enable Ctrl-Z to undo and Ctrl-Y to redo
  // (should do this after assigning Diagram.model)
  graph.undoManager.isEnabled = true;

  if (preferences && preferences.nodes) {
    graph.nodes.each(function (n) {
      var nodePreferences = n.data && n.data[dataObj.nodeKeyProperty] ? preferences.nodes[n.data[dataObj.nodeKeyProperty]] : null;
      if (nodePreferences) {
        n.location = new go.Point(nodePreferences.deltaX, nodePreferences.deltaY);
      }
    });
  } else {
    this.autoLayout();
  }
}

function init(initObj) {
  this._initObj = initObj;
  this.graph = this._createDiagram(initObj);
  this.graph.animationManager.isEnabled = false;

  if (initObj.contextMenu) {
    this._defineGraphContextMenu(initObj.contextMenu);
  }
  this.lock();
}

function zoomIn() {
  if (this.graph.autoScale === go.Diagram.Uniform) {
    this.graph.autoScale = go.Diagram.None;
  }
  this.graph.commandHandler.increaseZoom();
};

function zoomOut() {
  if (this.graph.autoScale === go.Diagram.Uniform) {
    this.graph.autoScale = go.Diagram.None;
  }
  this.graph.commandHandler.decreaseZoom();
};

function moveLeftSingleNodes(graph) {
  var minDeltaX = Infinity;
  var maxDeltaY = 0;
  graph.nodes.each(function (n) {
    if (n.data.deltaX < minDeltaX) {
      minDeltaX = n.data.deltaX;
      maxDeltaY = n.data.deltaY;
    } else if (n.data.deltaX === minDeltaX) {
      maxDeltaY = n.data.deltaY > maxDeltaY ? n.data.deltaY : maxDeltaY;
    }
  });
  graph.nodes.each(function (n) {
    if (n.linksConnected.count === 0 && !n.data.group && !n.data.isGroup) {
      n.location = new go.Point(minDeltaX, n.location.y);
    }
  });
}

function autoLayout() {
  var graph = this.graph;
  var minScale = 0.7;
  graph.startTransaction('layout');
  //var layout = new go.LayeredDigraphLayout();
  //layout.columnSpacing = 15;
  //layout.doLayout(g);

  graph.nodes.each(function (n) {
    if (n instanceof go.Group) {
      gridGroupNodes(n);
    }
  });
  var layout = this.graph.layout = make(go.LayeredDigraphLayout, {
    iterations: 24,
    aggressiveOption: go.LayeredDigraphLayout.AggressiveMore,
    layeringOption: go.LayeredDigraphLayout.LayerLongestPathSource
  });
  layout.columnSpacing = 15;
  //layout.doLayout(graph);
  setTimeout(function () {
    graph.zoomToFit();
  }, 0);
  graph.layoutDiagram(true);
  if (graph.scale < minScale) {
    graph.scale = minScale;
  }

  //	moveLeftSingleNodes(graph);
  graph.commitTransaction('layout');
}

function destroy() {
  this._initObj = null;
  this.graph.div = null;
  this.graph = null;
}

function redraw() {
  this.graph.redraw();
}


var Graph = Class.extend({
  _createDiagram: createSimpleDiagram,
  _createLinkTemplate: createLinkTemplate,
  _createNodeTemplate: createNodeTemplate,
  _createGroupTemplate: createGroupTemplate,
  _defineGraphContextMenu: defineGraphContextMenu,
  _getInternalNodesData: function () {
    return iteratorToData(this.graph.nodes);
  },
  _getInternalTransitions: function () {
    return iteratorToData(this.graph.links);
  },
  _toInternalModel: _toInternalModel,
  init: init,
  addChangedListener: addChangedListener,
  insertData: insertData,
  zoomOut: zoomOut,
  zoomIn: zoomIn,
  zoomToFit: function () {
    this.graph.zoomToFit();
    this.graph.contentAlignment = new this.graph.contentAlignment.constructor(0.5, 0.5);
    this.graph.contentAlignment = new this.graph.contentAlignment.constructor(NaN, NaN);
  },
  lock: function () {
    this.graph.allowMove = false;
    this.isLocked = true;
  },
  unlock: function () {
    this.graph.layout = new go.Layout();
    this.graph.allowMove = true;
    this.isLocked = false;
  },
  autoLayout: autoLayout,
  redraw: redraw,
  getPreferences: getPreferences,
  exportNodesData: exportNodesData,
  exportTransitions: exportTransitions,
  destroy: destroy
}, "Graph");

// static methods
Graph.iteratorToData = iteratorToData;

module.exports = Graph;