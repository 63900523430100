/**
 * Created by moshemal on 8/31/14.
 * Updated by Yael on 13/01/21.
 */
import toastService from 'core/services/toastService';
import { translate } from 'core/services/localization';

define(['core'], function (core) {
  'use strict';
  return {
    /**
     * @param {string} msg
     * @param {string} title (optional)
     */
    alert: function (msg, title) {
      return core.service.dialog.openAlertDialog.apply(core, arguments);
    },
    /**
     *
     * @param {string} msg
     * @param {string} title (optional)
     * @returns {promise}
     * @example
     * sandbox.dialog.confirm("a message to show inside the dialog",
     *      " a title on top of the dialog").then(successFunc, failFunc);
     */
    confirm: function (msg, title) {
      return core.service.dialog.openConfirmDialog.apply(core, arguments);
    },
    info: function (view, title, win) {
      return core.service.dialog.showInfoDialog.apply(core, arguments);
    },
    showFormDialog: function () {
      return core.service.dialog.showFormDialog.apply(core, arguments);
    },
    createSelectionDialog: function () {
      return core.service.dialog.createSelectionDialog.apply(core, arguments);
    },
    customDialog: function (msg, title) {
      return core.service.dialog.showCustomDialog.apply(core, arguments);
    },
    createSetupDialog: function (View, title, width, submitCallback, callbackContext, helpModuleName, helpRootType) {
      return core.service.dialog.createSetupDialog(View, title, width, submitCallback, callbackContext, helpModuleName, helpRootType);
    },
    createSimpleSetupDialog: function (SimpleSetup, title, width, height, submitCallback, closeCallback, callbackContext, helpModuleName, helpRootType) {
      return core.service.dialog.createSimpleSetupDialog(SimpleSetup, title, width, height, submitCallback, closeCallback, callbackContext, helpModuleName, helpRootType);
    },
    showPlainHtml: function () {
      return core.service.dialog.showPlainHtml.apply(core, arguments);
    },
    show: function () {
      return core.service.dialog.show.apply(core, arguments);
    }

  };
});