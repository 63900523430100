/**
 * Created by moshemal on 6/3/14.
 */

define(function(){
	'use strict';
	var EVENTS = {
		SELECTION_CHANGED:    'onSelectionChanged',
		DBLCLICK:             'dblclick',
		DELETE:               'delete',
		CONTEXT_MENU:         'contextmenu',
		AFTER_EDIT:           'afterEdit',
		COLUMN_WIDTH_CHANGED: 'onColumnWidthChanged'
	};

	return EVENTS;
});
