/**
 * @name RenamingRulesView Module
 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import AbstractModule from 'AbstractModule';
import jsutils from 'base/jsUtils';
import TickableModel from '../TickableModel';
import { makeRenamingRulesTableColumns } from './columnsCreator';
import { View } from './View';
import { createObjectComparator, COMPARE_TYPE } from 'core/comparators';

const THROTTLE_WAIT = 1000;

export default AbstractModule.extend({
  initDone: function () {
    this.updates = [];
    this.tickUpdateHandlerThrottled = jsutils.throttle(this.tickUpdateHandler, THROTTLE_WAIT, {
      leading: false,
      trailing: true
    });
    this.toolbar = this.createToolbar();
    this.reactRoot = createRoot(this.domElement);
  },

  destroy: function () {
    this._super();
    this.reactRoot.unmount();
  },

  firstTickReceived: function (data) {
    this.preferences = data.preferences || {};

    this.tickableModel = new TickableModel();

    this.tickableModel.firstTickHandler(data.model);

    this.buildViewModel();
  },

  tickUpdate: function (data) {
    this.updates = this.updates.concat(data.model);
    this.tickUpdateHandlerThrottled();
  },

  tickUpdateHandler: function () {
    this.tickableModel.tickUpdateHandler(this.updates);
    this.updates = [];
    this.buildViewModel();
  },

  buildViewModel: function () {
    const model = this.tickableModel.model();
    this.viewModel = model;
    this.viewModel.renamingRules = this.viewModel.renamingRules.sort(createObjectComparator('name', COMPARE_TYPE.CASE_INSENSITIVE));
    this.renamingRulesTableColumns = makeRenamingRulesTableColumns(this.viewModel, this);

    this.render();
  },

  handleRenamingRulesTableDeleteKey: function (selectedRows, e) {
    const rule = this.selected[0];
    const actions = this.getRelevantActions(rule);
    const deleteAction = actions.find(a => a.actionClass === 'DeleteRenamingRuleActionCR');
    if (deleteAction) {
      const res = deleteAction.execute(rule);
      res.then(() => this.updateSelected([]));
    };
  },

  handleRenamingRulesTableSelect: function (selectedRows) {
    const rules = selectedRows.map(row => row && this.tickableModel.getByNwid(row.nwid));
    this.updateSelected(rules.filter(e => !!e));
  },

  handleRenamingRulesTableContextMenu: function (rule, selected, e) {
    this.showContextMenu(rule, selected, e);
  },

  handleRuleDoubleClick: function (rule) {
    this.navigateByAction(rule);
  },

  render: function () {
    this.reactRoot.render(<View module={this}
      viewModel={this.viewModel}
      renamingRulesTableColumns={this.renamingRulesTableColumns}
    />);
  },
});
