/**
 * @name ShowEventError
 * @file ShowEventError
 * @author Yael
 * @since: 2020-12-22
 */
import React from 'react';
import { openAlertDialog } from 'core/services/dialogService';
import { fromServerDate } from 'core/dates';
import { toLocaleShortDateTime } from 'core/services/localization';
import iconService from 'core/services/iconService';
import { translate } from 'core/services/localization';
import EventError from 'components/private/info/EventError';
import AbstractAction from 'AbstractAction';
import { getMainStatus } from 'utilities/statuses';

const getStatus = item => getMainStatus(item);

module.exports = AbstractAction.extend({
  isApplicable: function (events) {
    if (typeof events === 'undefined' || events.length !== 1) {
      return false;
    };
    if (events[0].type === 'workflow/event') {
      return (
        events[0].type === 'workflow/event' &&
        typeof events[0].error !== 'undefined' &&
        events[0].error !== ''
      );
    };
    const status = getStatus(events[0]);
    return (
      status &&
      status !== '' &&
      typeof status.statusType !== 'undefined' &&
      status.statusType === 'Error' &&
      typeof status.message !== 'undefined' &&
      status.message !== ''
    );
  },

  execute: function (events) {
    if (events && events.length > 0) {
      let message;
      if (events[0].type === 'workflow/event') {
        message = events[0].error;
      } else {
        const status = getStatus(events[0]);
        if (status) {
          message = status.message;
        };
      };

      let error = JSON.parse(message);
      let eventTime = fromServerDate(error.ExceptionTime);
      let props = {
        title: translate(error.ExceptionTitle),
        time: toLocaleShortDateTime(eventTime, true),
        description: translate(error.ExceptionDescription),
        details: translate(error.CauseName),
        errorIcon: iconService.getGeneralIcon('status', 'error_large'),
      };

      let jsxElem = (<EventError {...props} />);
      openAlertDialog(jsxElem, translate('Event Error'), { width: 665, win: this.module.win });
    };
  },
});