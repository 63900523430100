import React, { Component } from 'react';
import PropTypes from 'prop-types';
import vars from './core/vars';
import Row from './Row';

var BUFFRED_ROWS = vars.BUFFRED_ROWS;

export default class extends Component {
  static propTypes = {
    rows: PropTypes.any,
    rowsLength: PropTypes.number,
    rowHeight: PropTypes.number,
    scrollTop: PropTypes.number,
    scrollLeft: PropTypes.number,
    rowsInPage: PropTypes.number,
    columnsByGroups: PropTypes.any,
    columnKey: PropTypes.any,
    columns: PropTypes.object,
    totalColumnsWidth: PropTypes.number,
    group: PropTypes.string,
    groupsWidth: PropTypes.any,
    currentWidth: PropTypes.number,
    hasVerticalScrollbar: PropTypes.bool,
    hoverRowIndex: PropTypes.number,
    fixed: PropTypes.bool,
    virtualScroll: PropTypes.bool,
    selectedRows: PropTypes.array,
    isImmutable: PropTypes.bool,
    allowDragOver: PropTypes.bool,
    onMouseEnter: PropTypes.func,
    onMouseMove: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onClickRow: PropTypes.func,
    onDoubleClickRow: PropTypes.func,
    onRowContextMenu: PropTypes.func,
    onDragOver: PropTypes.func,
    onDragLeave: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    shouldRowUpdate: PropTypes.func,
    getRowClassName: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.array
    ]),
    borderSelection: PropTypes.bool,
  };

  static defaultProps = {
    onMouseEnter: function () { },
    onMouseMove: function () { },
    onMouseLeave: function () { },
    onClickRow: function () { },
    onDoubleClickRow: function () { },
    onRowContextMenu: function () { },
    selectedRows: [],
    borderSelection: false
  };

  _virtualRows = (rowsInPage) => {
    var virtualRows = [];
    for (var i = 0; i < rowsInPage; i++) {
      virtualRows.push(i);
    }
    return virtualRows;
  };

  _rowPositionTop = (rowIndex, currentPage, scrollTop, scrollLeft, rowHeight, rowsInPage) => {
    return (currentPage * rowHeight * rowsInPage) + (rowIndex * rowHeight);
  };

  _rowVirtualPositionTop = (rowIndex, currentPage, scrollTop, scrollLeft, rowHeight, rowsInPage) => {
    var rowPositionTop = this._rowPositionTop(rowIndex, currentPage, scrollTop, scrollLeft, rowHeight, rowsInPage);//(currentPage * rowHeight * rowsInPage) + (rowIndex * rowHeight);

    return rowPositionTop < scrollTop - (rowHeight * parseInt(BUFFRED_ROWS / 2)) ? rowPositionTop + (rowsInPage * rowHeight) : rowPositionTop;
  };

  //onClickRow: function (rowIndex, rowContent, ev) {
  //  ev.preventDefault();
  //  ev.stopPropagation();
  //  this.props.onClickRow(rowIndex, rowContent, ev);
  //},

  isSelectedRow = (rowIndex, rowContent, selectedRows) => {
    var { columnKey } = this.props;

    if (typeof rowContent === 'undefined') return false;

    if (typeof columnKey === 'string')
      return selectedRows.some(row => row[columnKey] === rowContent[columnKey]);

    return selectedRows[rowIndex] === rowContent[rowIndex];
  };

  _renderRow = (
    index,
    rowIndex,
    rowContent,
    rowHeight,
    rowPositionTop,
    columnsByGroups,
    rowsLength,
  ) => {
    var {
          onMouseMove,
      onMouseEnter,
      onMouseLeave,
      onClickRow,
      onDoubleClickRow,
      onRowContextMenu,
      onDragOver,
      onDragLeave,
      onDrop,
      selectedRows,
      columns,
      shouldRowUpdate,
      getRowClassName,
      columnKey,
      group,
      groupsWidth,
      currentWidth,
      scrollTop,
      scrollLeft,
      hasVerticalScrollbar,
      hoverRowIndex,
      isImmutable,
      allowDragOver,
      onDragStart,
      borderSelection,
        } = this.props;
    var isSelected = this.isSelectedRow(rowIndex, rowContent, selectedRows);
    var isMouseHover = hoverRowIndex === rowIndex ? true : false;
    var rowKey = typeof rowContent === 'object' ? rowContent[columnKey] : rowIndex;

    if (rowContent === undefined || rowContent === null) return null;

    return <Row key={rowKey}
      rowIndex={rowIndex}
      rowContent={rowContent}
      rowHeight={rowHeight}
      rowPositionTop={rowPositionTop}
      columnsByGroups={columnsByGroups}
      columns={columns}
      rowsLength={rowsLength}
      groupsWidth={groupsWidth}
      currentWidth={currentWidth}
      scrollTop={scrollTop}
      scrollLeft={scrollLeft}
      hasVerticalScrollbar={hasVerticalScrollbar}
      isSelected={isSelected}
      isMouseHover={isMouseHover}
      group={group}
      isImmutable={isImmutable}
      allowDragOver={allowDragOver}
      onMouseMove={onMouseMove}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClickRow}
      onDoubleClick={onDoubleClickRow}
      onContextMenu={onRowContextMenu}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDragStart={onDragStart}
      onDrop={onDrop}
      shouldRowUpdate={shouldRowUpdate}
      borderSelection={borderSelection}
      getRowClassName={getRowClassName} />;
  };

  _renderVirtualRows = (rows, rowHeight, scrollTop, scrollLeft, rowsInPage, columnsByGroups) => {
    var that = this;
    var virtualRows = this._virtualRows(rowsInPage);
    var currentPage = Math.floor(scrollTop / (rowHeight * rowsInPage));
    var rowsLength = rows.length;

    return virtualRows.map((row, index) => {
      var realRowIndex = (currentPage * rowsInPage) + index < Math.floor(scrollTop / rowHeight) ?
        index + ((currentPage + 1) * rowsInPage) :
        index + (currentPage * rowsInPage);
      var rowContent = rows[realRowIndex];
      var rowPositionTop = that._rowVirtualPositionTop(index, currentPage, scrollTop, scrollLeft, rowHeight, rowsInPage);
      return that._renderRow(index, realRowIndex, rowContent, rowHeight, rowPositionTop, columnsByGroups, rowsLength);
    });
  };

  _renderRealRows = (rows, rowHeight, scrollTop, scrollLeft, rowsInPage, columnsByGroups) => {
    var that = this;
    var rowsLength = rows.length;

    return rows.map(function (row, index) {
      var rowContent = rows[index];
      var rowPositionTop = that._rowPositionTop(index, 0, scrollTop, scrollLeft, rowHeight, rowsInPage);
      return that._renderRow(index, index, rowContent, rowHeight, rowPositionTop, columnsByGroups, rowsLength);
    });
  };

  _renderRows = () => {
    var {
        rows,
      rowHeight,
      scrollTop,
      scrollLeft,
      rowsInPage,
      columnsByGroups,
      virtualScroll
      } = this.props;

    if (virtualScroll === true) {
      return this._renderVirtualRows(rows, rowHeight, scrollTop, scrollLeft, rowsInPage, columnsByGroups);
    }
    return this._renderRealRows(rows, rowHeight, scrollTop, scrollLeft, rowsInPage, columnsByGroups);
  };

  //TODO: shouldComponentUpdate works only with immutable data structures. For now tick data is mutable so shouldComponentUpdate is disabled for Rows.
  //shouldComponentUpdate: function (nextProps) {
  //  return (
  //    //nextProps.isImmutable === false ||
  //    //(
  //      nextProps.rows !== this.props.rows ||
  //      nextProps.rowsLength !== this.props.rowsLength ||
  //      nextProps.scrollTop !== this.props.scrollTop ||
  //      nextProps.selectedRows !== this.props.selectedRows ||
  //      nextProps.hoverRowIndex !== this.props.hoverRowIndex ||
  //      nextProps.columns !== this.props.columns
  //    //)
  //  );
  //},

  render() {
    var {
        rows,
      rowHeight,
      totalColumnsWidth,
      fixed
      } = this.props;

    var emptyContainerStyle = {
      height: rows.length * rowHeight,
      width: fixed ? '100%' : totalColumnsWidth
    };

    return <div ref="rowsContainer" className="react-data-grid-body-rows-container" style={emptyContainerStyle}>
      {this._renderRows()}
    </div>;
  }
}