import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';
import Properties from 'src/components/private/info/Properties';
import StringBreadcrumbs from 'src/components/private/info/StringBreadcrumbs';
import { formatBreadcrumbs } from 'utilities/breadcrumbs';
import settingsManager from 'core/managers/settings';
import { AdUnitDisplay } from './AdUnitDisplay';

const translate = sandbox.localization.translate;
const bwColor = sandbox.icons.getModuleIcon('TableView', 'bw');
const colorsColor = sandbox.icons.getModuleIcon('TableView', 'color');
const infolink = sandbox.icons.getModuleIcon('InfoDialog', 'infolink');

const labels = {
  name: translate('Name:'),
  color: translate('Color:'),
  adSize: translate('Ad size:'),
  height: translate('Height'),
  width: translate('Width'),
  adLocation: translate('Ad location:'),
  sharedPages: translate('Shared pages'),
  customProperties: translate('Custom properties:'),
};

const lengthUnit = settingsManager.getLengthUnit();

module.exports = class Colors extends Component {
  constructor(props) {
    super(props);
    this.getAdSize = this.getAdSize.bind(this);
    this.getAdLocation = this.getAdLocation.bind(this);
    this.openCustomInfoDialog = this.openCustomInfoDialog.bind(this);
    this.getCustomProperties = this.getCustomProperties.bind(this);
  }

  static propTypes = {
    adInfo: PropTypes.any,
    object: PropTypes.any,
    openCustomInfoDialog: PropTypes.func

  };
  getAdSize() {
    let { adInfo } = this.props;
    const adHeight = adInfo.height.value;
    const adWidth = adInfo.width.value;
    return <div className='custom-info-row'>
      <span className='custom-info-row-title'>
        {labels.adSize}
      </span>
      <span className='custom-info-row-content'>
        <AdUnitDisplay label={labels.height} value={adHeight} lengthUnit={lengthUnit} />
        <AdUnitDisplay label={labels.width} value={adWidth} lengthUnit={lengthUnit} />
      </span>
    </div>;
  }
  getAdLocation() {
    let { adInfo } = this.props;
    const adX = adInfo.x.value;
    const adY = adInfo.y.value;
    return <div className='custom-info-row'>
      <span className='custom-info-row-title'>
        {labels.adLocation}
      </span>
      <span className='custom-info-row-content'>
        <AdUnitDisplay label='X' value={adX} lengthUnit={lengthUnit} />
        <AdUnitDisplay label='Y' value={adY} lengthUnit={lengthUnit} />
      </span>
    </div>;
  }

  openCustomInfoDialog(nwid) {
    return function () {
      this.props.openCustomInfoDialog(nwid);
    }.bind(this);
  }

  getSharedPagesAd() {
    const { adInfo, object } = this.props;
    const linkContent = adInfo.links.map(function (link, index) {

      return <div className='custom-info-row-link' key={index} >
        <span className='custom-info-row-content-link' onClick={this.openCustomInfoDialog(link.nwid)} >
          <span className='custom-info-breadcrumbs-and-icon'>
            <span className='custom-info-breadcrumbs ellipsis-before' title={formatBreadcrumbs(link.breadcrumbs)}>
              <StringBreadcrumbs currentBreadcrumbs={link.breadcrumbs}
                compareTo={object.breadcrumbs} />
            </span>
            <img className="custom-info-infolink-icon" src={infolink} />
          </span>
        </span>
      </div>;
    }.bind(this));

    return <div className='custom-info-row'>
      <div className='custom-info-row-title'>{labels.sharedPages}</div>
      <div className='custom-info-row-content'>
        {linkContent}
      </div>
    </div>;
  }

  getCustomProperties() {
    const { adInfo } = this.props;
    return <div className='custom-info-row'><span className='custom-info-row-title'>{labels.customProperties} </span><span className='custom-info-row-content'><Properties properties={adInfo.custom} /></span></div>;
  }

  render() {
    const { adInfo } = this.props;
    const customProperties = adInfo.custom !== undefined ? this.getCustomProperties() : undefined;
    const colorImg = adInfo.colorType === 'Color' ? <img className='ad-color-type-img' src={colorsColor} /> : <img className='ad-color-type-img' src={bwColor} />;
    return <div className='ad'>
      <div className='custom-info-row'><span className='custom-info-row-title'>{labels.name} </span><span className='custom-info-row-content ellipsis' title={adInfo.name}>{adInfo.name}</span></div>
      <div className='custom-info-row'><span className='custom-info-row-title'>{labels.color} </span><span className='custom-info-row-content ellipsis' title={adInfo.colorType}>{colorImg}</span></div>
      {this.getAdSize()}
      {this.getAdLocation()}
      {this.getSharedPagesAd()}
      {customProperties}
    </div>;
  }

};