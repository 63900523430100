/**
 * @name LockSetup
 * @file Lock Setup
 *
 * @author Alex Hu
 * @since: 2017-09-05
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormSetup from 'setups/FormSetup';
import sandbox from 'sandbox';
import Header from 'components/common/headers/Header';
import Checkbox from 'components/common/inputs/Checkbox';

const { translate } = sandbox.localization;


class LockSetup extends Component {

  static propTypes = {
    store: PropTypes.any,
    apply: PropTypes.func
  };

  static onDataReceived(model) {
    return model;
  }

  static onApply = (model) => {
    return model;
  }

  constructor(props) {
    super(props);
  }

  renderPanelHeader(title) {
    return (
      <Header>{title}</Header>
    );
  }

  handleCheckboxChecked(item) {
    return (ev) => {
      item.selected = !item.selected;
    };
  }

  renderAvailableResources(availableResources) {
    return availableResources.map((item, index) => {
      return <React.Fragment key={index}>

        <label className='crtx-form-label-with-checkbox'>
          <Checkbox name="AvailableResources" checked={item.selected} value={item.name} onChange={this.handleCheckboxChecked(item)} />
          {item.name}
        </label>
      </React.Fragment>;
    });
  }

  renderAvailableResources(availableResources) {
    return availableResources.map((item, index) => {
      return <React.Fragment key={index}>
        <label className='crtx-form-label-with-checkbox'>
          <Checkbox name="crtx-lock-setup-available-resource" checked={item.selected} value={item.name} onChange={this.handleCheckboxChecked(item)} />
          {item.name}
        </label>
      </React.Fragment>;
    });
  }

  renderAvailableGroupResourceNames(availableGroupResourceNames) {
    return availableGroupResourceNames.map((item, index) => {
      return <React.Fragment key={index}>
        <label className='crtx-form-label-with-checkbox'>
          <Checkbox name="crtx-lock-setup-available-resource-group" checked={item.selected} value={item.name} onChange={this.handleCheckboxChecked(item)} />
          {item.name}
        </label>
      </React.Fragment>;
    });
  }

  render() {
    return (
      <div className='crtx-form-section-container'>
        {this.renderPanelHeader(translate("Available resources"))}
        <div className='crtx-form-section'>
          <div className='crtx-form-one-column'>
            {this.renderAvailableResources(this.props.model.availableResources)}
          </div>
        </div>
        {this.renderPanelHeader(translate("Available resource groups"))}
        <div className='crtx-form-section'>
          <div className='crtx-form-one-column'>
            {this.renderAvailableGroupResourceNames(this.props.model.availableGroupResourceNames)}
          </div>
        </div>
      </div>
    );
  }
}

export default FormSetup(LockSetup);
