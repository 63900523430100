/**
 * Created by tzvikas on 4/28/2015.
 */

define(["./actions", './../../../utils/wizardUtils'],
  function(actionsController, wizardUtils){
    'use strict';

    function copyObject(obj){
      var copy = obj.constructor();
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)){
          copy[attr] = obj[attr];
        }
      }
      return copy;
    }

    function findRowByTitle(section, title){
      for (var i=0;i<section.zonedPages.length;i++){
        if (section.zonedPages[i].displayName === (title + "")){
          return section.zonedPages[i];
        }
      }
      return null;
    }

    function removeRow(section, rowData){
      var idx = -1;
      for (var i=0;i<section.zonedPages.length;i++){
        if (section.zonedPages[i].zoneMap === rowData){
          idx = i;
          break;
        }
      }
      if (idx === -1){
        return;
      }
      section.zonedPages.splice(idx, 1);
    }

    function removePage(rowData, zone, section, page){
      var map = this.modelHandler.pagesPerSection;
      var mapRows = this.modelHandler.pagesPerRow;

      delete rowData[zone];
      delete map[page.sourceId];
      delete mapRows[page.sourceId];
      if (Object.keys(rowData).length === 0){
        removeRow.call(this, section, rowData);
      }
    }

    function createNewRow(section, title){
      var idx = section.zonedPages.length;
      for (var i=0;i<section.zonedPages.length-1;i++){
        var curDisplayName = section.zonedPages[i].displayName.split("-")[0];
        var nextDisplayName = section.zonedPages[i+1].displayName.split("-")[0]
        var n =  isNaN(title) ? parseInt(title.split("-")[0].replace(/[^0-9]/g, ""), 10) : title;
        var n1 = isNaN(curDisplayName) ? parseInt(curDisplayName.replace(/[^0-9]/g, ""), 10) : curDisplayName;
        var n2 = isNaN(nextDisplayName) ? parseInt(nextDisplayName.replace(/[^0-9]/g, ""), 10) : nextDisplayName;
        if (n < n2){
          idx = i+1;
          break;
        }
      }
      var newRow = {displayName:title, zoneMap:{}};
      section.zonedPages.splice(idx, 0, newRow);
      return newRow;
    }

    function splitPage(page) {

      var map = this.modelHandler.pagesPerSection;
      var mapRows = this.modelHandler.pagesPerRow;
      var ret = {};

      var rowData = mapRows[page.sourceId];
      var zone = this.findZone.call(this, rowData, page);
      var section = map[page.sourceId];
      var existsTitle = page.title;
      //page.split = "A";
      //page.title = this.getTitle(page);
      //var existsRow = findRowByTitle(section, page.title)
      //if (existsRow)
      //  existsRow.displayName = page.title;
      //else{
      //  existsRow = createNewRow(section, page.title);
      //}
      //
      //existsRow.zoneMap[zone] = page;
      //mapRows[page.sourceId] = existsRow.zoneMap;
      //
      //page.displayName = this.getTitle(page);

      var titles = [existsTitle + "A", existsTitle + "B"];
      var splits = ["A", "B"];

      for (var i=0;i<splits.length;i++) {
        

        var newPage = {
          pagination: page.pagination, number: page.number, sourceId: page.sourceId + splits[i].toLowerCase(), separations: {},
          customProperties: {}, sectionCode: section.code, paginationInZone: page.paginationInZone, folioNumber: page.folioNumber
        };
        newPage.mySection = page.mySection;
        if (newPage.masterSection){
          newPage.masterSection = page.masterSection;
        }
        
        newPage.isMasterOptional = page.isMasterOptional;

          newPage.originators = [page.sourceId];
          newPage.newSplit = true;



        newPage.sectionCode = page.sectionCode;
        newPage.split = i === 0 ? "A" : "B";
        newPage.myZone = page.myZone;
        for (var sepName in page.separations) {
          newPage.separations[sepName] = {sourceId: this.modelHandler.sourceIdBreadcrumb ? newPage.sourceId + "/" + sepName.toLowerCase() : newPage.sourceId + sepName.toLowerCase()};
          // newPage.separations[sepName].sourceId = newPage.separations[sepName].sourceId.toLowerCase()
        }

        newPage.title = this.getTitle(newPage);
        newPage.displayName = this.getTitle(newPage);
        var row = findRowByTitle(section, newPage.title);
        if (row === null) {
          row = createNewRow(section, newPage.title);
        }

        row.zoneMap[zone] = newPage;
        
        map[newPage.sourceId] = section;
        mapRows[newPage.sourceId] = row.zoneMap;
        this.modelHandler.mappedModel[newPage.sourceId] = newPage;
        ret[newPage.title] = newPage;
      }

      removePage.call(this, rowData, zone, section, page);
      return ret;
    }

    function splitLinkAction (splitMasterPages, link){
      var sepsToLink = [];
      for (var sepName in link.separations){
        var masterSep = link.separations[sepName].masterSeparation;
        if (masterSep !== null && typeof masterSep !== "undefined"){
          sepsToLink.push(sepName);
        }
      }

      var links = this.findFollowers (link);
      var splitLinksPages = splitPage.call(this, link);
      //this.sharePage(splitMasterPage, splitLinksPage, sepsToLink);

      for (var title in splitMasterPages){
        var masterPage = splitMasterPages[title];
        var pageToLink = splitLinksPages[title];
        this.sharePage(masterPage, pageToLink, sepsToLink);
        pageToLink.directMaster = masterPage.sourceId;
      }



      for (var j=0;j<links.length;j++){
        if (links[j].masterZone === link.myZone)
          splitLinkAction.call(this, splitLinksPages, links[j]);
      }
    }

    function dinkyPageAction (page, dinky) {
      var links = page.followers || [];
      page.dinky = dinky;
      for (var j=0;j<links.length;j++){
        dinkyPageAction.call(this, this.modelHandler.mappedModel[links[j]], dinky);
      }
    }

    function splitPageAction (page){
      var virtualPage = null , isVirtual = page.virtual;
      if (page.panorama){
        var virtualSourceId = page.virtualPage;
        virtualPage = this.modelHandler.pagesPerRow[virtualSourceId][page.myZone];
      }
      var mapRows = this.modelHandler.pagesPerRow;
      var map = this.modelHandler.pagesPerSection;
      var rowData = mapRows[page.sourceId];
      //var followers = this.findFollowers (page);

      var links = this.findFollowers (page);
      var splitMasterPages = splitPage.call(this, page);


      for (var j=0;j<links.length;j++){
        if (links[j].masterZone === page.myZone)
          splitLinkAction.call(this, splitMasterPages, links[j]);
      }

      if (virtualPage !== null){
        splitPageAction.call(this, virtualPage);     
        for (var p in splitMasterPages){
          splitMasterPages[p].splitPanorama = true;
        }   
      } else if (isVirtual){
        for (var p in splitMasterPages){
          splitMasterPages[p].splitVirtual = true;
        }
      }
      
     

    }

    

    function unsplitPage(page){
      var isVirtual = page.virtual, isPano = page.panorama;
      var map = this.modelHandler.pagesPerSection;
      var mapRows = this.modelHandler.pagesPerRow;
      var section = map[page.sourceId];
      if (section === null || typeof section === 'undefined'){
        return null;
      }

      var src1 = this.getTitle(page);
      var lastChar = src1.substr(src1.length - 1);
      var otherChar = lastChar === "A" ? "B" : "A";
      var sourceId = page.sourceId.substring(0,page.sourceId.length - 1);
      var src2 = src1.substring(0,src1.length - 1) + otherChar;
      if (src1.indexOf("-") > 0){
        var a = [];
        var items = src1.split("-");
        for (var i=0;i<items.length;i++){
          a.push(items[i].substring(0,items[i].length - 1) + otherChar);
        }
        src2 = a.join("-");
      }      
      var aRow = findRowByTitle(section, src1);
      var bRow = findRowByTitle(section, src2);
      var followers = this.findAllCommonPages(aRow.zoneMap, page.sourceId);
      var zone = this.findZone.call(this, aRow.zoneMap, page);
      var secondSourceId = bRow.zoneMap[zone].sourceId;
      var removedItems = [aRow.zoneMap[zone].sourceId, bRow.zoneMap[zone].sourceId];
      removePage.call(this, aRow.zoneMap, zone, section, aRow.zoneMap[zone]);
      removePage.call(this, bRow.zoneMap, zone, section, bRow.zoneMap[zone]);
      var sectionSourceId;
      for (var i=0;i<section.zones.length;i++){
        if (section.zones[i].displayName === zone){
          sectionSourceId = section.zones[i].sectionSourceId;
          break;
        }
      }

      var newPage = {pagination:page.pagination,number:page.number,separations:{},customProperties: {}, sectionCode:section.code, 
          paginationInZone: page.paginationInZone, folioNumber: page.folioNumber};
      newPage.sourceId = sourceId;
      newPage.isMasterOptional = page.isMasterOptional;
      for (var sepName in page.separations){        
        newPage.separations[sepName] = {sourceId: this.modelHandler.sourceIdBreadcrumb ? newPage.sourceId + "/" + sepName : newPage.sourceId + sepName};
        //newPage.separations[sepName].sourceId = newPage.separations[sepName].sourceId.toLowerCase();
      }


      var row = findRowByTitle(section, this.getTitle(newPage));
      if (row === null){
        row = createNewRow(section, this.getTitle(newPage));
      }
      newPage.originators = (typeof page.originators !== 'undefined') ? [page.originators] : [page.sourceId];
      if (typeof page.originator !== 'undefined'){
        newPage.originators.push(secondSourceId);
      }
      newPage.sectionCode = page.sectionCode;
      newPage.removedItems = removedItems;
      newPage.title = this.getTitle(newPage);
      newPage.myZone = page.myZone;
      newPage.displayName = newPage.title;
      if (isVirtual){
        newPage.unsplitVirtual = true;
      } else if (isPano){
        newPage.unsplitPanorama = true;
      }
      row.zoneMap[zone] = newPage;
      map[newPage.sourceId] = section;
      mapRows[newPage.sourceId] = row.zoneMap;
      this.modelHandler.mappedModel[newPage.sourceId] = newPage;
      //this.setPanorama
      return newPage;
    }

    function unsplitLinkAction (master, link){
      var sepsToLink = [];
      for (var sepName in link.separations){
        var masterSep = link.separations[sepName].masterSeparation;
        if (masterSep !== null && typeof masterSep !== "undefined"){
          sepsToLink.push(sepName);
        }
      }

      var links = this.findFollowers (link);
      var unsplitedLink = unsplitPage.call(this, link);
      this.sharePage(master, unsplitedLink, sepsToLink);





      for (var j=0;j<links.length;j++){
        if (links[j].masterZone === link.myZone)
          unsplitLinkAction.call(this, unsplitedLink, links[j]);
      }
    }

    function unsplitPageAction (page){

      var mapRows = this.modelHandler.pagesPerRow;
      var map = this.modelHandler.pagesPerSection;
      var rowData = mapRows[page.sourceId];

      var unsplitedMaster = unsplitPage.call(this, page);
      if (unsplitedMaster === null){
        return;
      }
      var followers = this.findFollowers (page);

      for (var j=0;j<followers.length;j++){
        if (followers[j].masterZone === page.myZone){
          var link = followers[j];
          var sepsToLink = [];
          for (var sepName in link.separations){
            var masterSep = link.separations[sepName].masterSeparation;
            if (masterSep !== null && typeof masterSep !== "undefined"){
              sepsToLink.push(sepName);
            }
          }

          unsplitLinkAction.call(this, unsplitedMaster, followers[j]);
          //this.sharePage(unsplitedMaster, unsplitLinksPage, sepsToLink);
          //var unsplitLinksPage = unsplitPageAction.call(this, link);
          //this.sharePage(mapRows[unsplitedMaster.sourceId], unsplitLinksPage, unsplitedMaster, sepsToLink);
        }

      }
    }


    function insertPages(page, numInserts){
      if (page.hasOwnProperty("insertChar")){
        return;
      }

      var map = this.modelHandler.pagesPerSection;
      var mapRows = this.modelHandler.pagesPerRow;
      var rowData = mapRows[page.sourceId];
      var zone = this.findZone.call(this, rowData, page);
      var section = map[page.sourceId];
      var prevNumInserts = page.numInserts !== null && typeof page.numInserts !== "undefined" ? page.numInserts : 0;
      page.numInserts = numInserts;

      if (prevNumInserts >= numInserts){
        for (i=prevNumInserts-1;i>=numInserts;i--){
          var insertChar = String.fromCharCode(65 + i);
          var pageToCheck = copyObject(page);
          pageToCheck.insertChar = insertChar;
          var title = this.getTitle(pageToCheck);
            //page.split !== null && typeof page.split !== 'undefined' ? page.pagination + insertChar + page.split : page.pagination + insertChar;
          var row = findRowByTitle(section, title);
          if (row === null){
            continue;
          }
          var pageToRemove = row.zoneMap[zone];
          removePage.call(this, row.zoneMap, zone, section, pageToRemove);
        }
      } else {
        for (var i=prevNumInserts;i<numInserts;i++){
          var insertChar = String.fromCharCode(65 + i);
          //var title = page.hasOwnProperty("split") ? page.pagination + insertChar + page.split : page.pagination + insertChar;

          var newPage = {pagination:page.pagination,paginationInZone:page.paginationInZone,sourceId:page.sourceId + insertChar.toLowerCase(),separations:{}, customProperties: {},
            sectionCode:section.code, folioNumber: page.folioNumber};
          newPage.isMasterOptional = page.isMasterOptional;
          newPage.insertChar = insertChar;
          newPage.sectionCode = page.sectionCode;
          newPage.originators = [page.sourceId];
          for (var sepName in page.separations){            
            newPage.separations[sepName] = {sourceId: this.modelHandler.sourceIdBreadcrumb ? newPage.sourceId + "/" + sepName : newPage.sourceId + sepName};
            newPage.separations[sepName].sourceId = newPage.separations[sepName].sourceId.toLowerCase();
          }
          var title = this.getTitle(newPage);
          var row = findRowByTitle(section, title);
          if (row === null){
            row = createNewRow(section, title);
          }
          newPage.title = title;
          row.zoneMap[zone] = newPage;
          map[newPage.sourceId] = section;
          mapRows[newPage.sourceId] = row.zoneMap

        }
      }



    }

    /*

     Public Functions

    */

    function dinkyPageActionController (dinky){
      var pages = this.filterSelectedLinks();
      for (var i=0;i<pages.length;i++) {
        var page = pages[i];
        //var splitChar = page.split;

        dinkyPageAction.call(this, page, dinky);

      }

      //this.selectedPages[0].fireSectionSizeChange({});
      return [];
    }

    function splitPageActionController (split){
      var pages = this.filterSelectedLinks();
      for (var i=0;i<pages.length;i++) {
        var page = pages[i];
        var isPano = page.panorama;        
        //var splitChar = page.split;
        if (split){
          splitPageAction.call(this, page);
        } else {
          var virtualPage = page.virtualPage;
          unsplitPageAction.call(this, page);
          if (virtualPage){
            var virtual = this.modelHandler.pagesPerRow[virtualPage][page.myZone]
            unsplitPageAction.call(this, virtual);
          }
        }

        if (isPano){
          var panoPages = [], virtualPages = [];
          for (var sourceId in this.modelHandler.pagesPerRow){
            var p = this.modelHandler.pagesPerRow[sourceId][page.myZone];
            if (p.splitPanorama){
              panoPages.push(p);
              delete p["splitPanorama"];
            } else if (p.splitVirtual){
              virtualPages.push(p);
              delete p["splitVirtual"];  
            } else if (p.unsplitPanorama){
              panoPages.push(p);
              delete p["unsplitPanorama"];  
            } else if (p.unsplitVirtual){
              virtualPages.push(p);
              delete p["unsplitVirtual"];  
            }

            if (split && panoPages.length === 2 && virtualPages.length === 2){
              this.setPanorama(panoPages[0], virtualPages[0]);
              this.setPanorama(panoPages[1], virtualPages[1]);
              break;
            } else if (!split && panoPages.length === 1 && virtualPages.length === 1){
              this.setPanorama(panoPages[0], virtualPages[0]);
              break;
            }
          }
        }
      }

      //this.selectedPages[0].fireSectionSizeChange({});
      return [];
    }

    function insertPageActionController (numInserts) {
      for (var i=0;i<this.selectedPages.length;i++) {
        var page = this.selectedPages[i];
        insertPages.call(this, page, numInserts);
      }
      //this.selectedPages[0].fireSectionSizeChange({});
      return [];
    }

    function isApplicableForDinky(){
      var pages = this.filterSelectedLinks();
      if (pages.length === 0 || pages.length > 1 || this.selectedPages.length > pages.length){
        return false;
      }
      for (var i=0;i<pages.length;i++){
        var page = pages[i];//.props.page;
        if (!page.dinky){
          return false;
        }
      }
      return true;
    }

    function isApplicableForSplits() {
      var pages = this.filterSelectedLinks();
      if (pages.length === 0 || this.selectedPages.length > pages.length){
        return -1;
      }
      var state = false, prev = false;
      for (var i=0;i<pages.length;i++) {
        var page = pages[i];
        var splitChar = page.split;
        state = (splitChar === null || typeof splitChar === "undefined") ? false : true;
        if (i === 0){
          prev = state;
        }
        if (state !== prev){
          return -1;
        }
        prev = state;
      }
      return state ? 1 : 0;
    }

    function isApplicableForInserts(){
      var pages = this.filterSelectedLinks();
      var numInsertsProp = {};
      for (var i=0;i<pages.length;i++) {
        var page = pages[i];
        var insertChar = page.insertChar;
        var numInserts = parseInt(page.numInserts, 10);
        if (typeof insertChar !== "undefined" && insertChar !== null){
          return -1;
        }
        if (!isNaN(numInserts)){
          numInsertsProp[numInserts] = 1;
        }
      }
      var keys = Object.keys(numInsertsProp);
      return keys.length === 0 ? 0 : keys.length === 1 ? keys[0] : -1;
    }

    function changeProperty(property, value){
      var mapRows = this.modelHandler.pagesPerRow;
      var pages = this.selectedPages;
      var ret = [];
      for (var i=0;i<pages.length;i++) {
        var page = pages[i];
        var rowData = mapRows[page.sourceId];
        page = findMyMaster(page, rowData, page.sourceId);
        var followers = this.findAllCommonPages(rowData, page.sourceId);

        if (page.customProperties === null || typeof page.customProperties === 'undefined'){
          page.customProperties = {};
        }
        page.customProperties[property] = value;
        for (var j=0;j<followers.length;j++){
          if (followers[j].customProperties === null || typeof followers[j].customProperties === 'undefined'){
            followers[j].customProperties = {};
          }
          followers[j].customProperties[property] = value;
        }
      }
      //this.selectedPages[0].fireSectionSizeChange({});
      return this.selectedPages;
    }

    function addCustomPropsValues(obj, props){
      for (var i=0;i<this.selectedPages.length;i++) {
        var page = this.selectedPages[i];
        for (var j=0;j<props.length;j++){
          if (page.customProperties[props[j].name]){
            obj[props[j].name] = true;
          } else {
            obj[props[j].name] = false;
          }
        }
      }

    }

    function findMyMaster(page, row, sourceId){
      if (page.masterPage === null || typeof page.masterPage === 'undefined'){
        return page;
      }
      var zoneMapKeys = Object.keys(row);
      for (var i=0;i<zoneMapKeys.length;i++){
        if (row[zoneMapKeys[i]].sourceId === page.masterPage){
          return row[zoneMapKeys[i]];
        }
      }
      return null;
    }

    return {
      splitPageActionController: splitPageActionController,
      dinkyPageActionController: dinkyPageActionController,
      insertPageActionController: insertPageActionController,
      isApplicableForInserts: isApplicableForInserts,
      isApplicableForSplits: isApplicableForSplits,
      isApplicableForDinky: isApplicableForDinky,
      addCustomPropsValues: addCustomPropsValues,
      changeProperty: changeProperty
    };

  })
;

