import React, { Component } from 'react';
import PropTypes from 'prop-types';

module.exports = class Loader extends Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    loading: PropTypes.bool,
  }

  render() {
    const loaderDiv = this.props.loading ? 'crtx-showLoader' : 'crtx-hideLoader';
    return <div className={loaderDiv}></div>;
  }
}