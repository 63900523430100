import React from 'react';
import PropTypes from 'prop-types';
import ZoneOverviewComponent from './ZoneOverviewComponent';

export default function View({ module, model }) {

  return (<ZoneOverviewComponent module={module} model={model}></ZoneOverviewComponent>);
}

View.propTypes = {
  module: PropTypes.object,
  model: PropTypes.object

};