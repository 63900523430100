/**
 * Created with JetBrains WebStorm.
 * User: elad
 * Date: 11/20/13
 * Time: 11:16 AM
 * To change this template use File | Settings | File Templates.
 */

import AbstractAction from 'AbstractAction';
import { startModule } from 'core/managers/module';
import actionUtils from 'actions/actionUtils';

export default AbstractAction.extend({
  isApplicable: function () {
    // After the selection is implemented this should change for selected items instead of the clicked item
    const objs = this.module.selected;

    return !objs || objs.length <= 0 || objs.length > 1 ? false : true;
  },
  execute: function (args) {
    const objs = this.module.selected;
    if (!objs || objs.length <= 0 || !objs[0]) {
      return;
    }

    const obj = objs[0];
    const viewDefName = obj.type === 'controlpanel/site/manager' ? 'EditManagerViewCR' : 'EditMachineViewCR';
    const viewInfo = actionUtils.findViewInfoForAction(this.nwid, viewDefName);
    startModule(viewInfo.nwid, this.module.id, {
      ...viewInfo,
      rootId: obj.nwid,
      rootType: obj.type
    });
  }
});
