import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class extends Component {
  static propTypes = {
    title: PropTypes.string,
    width: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    flex: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    resizable: PropTypes.bool,
    align: PropTypes.string,
    autoWidth: PropTypes.bool,
    header: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
    cellDataGetter: PropTypes.func,
    dataGetter: PropTypes.func,
    cell: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.object,
      PropTypes.func
    ]).isRequired,
    fixed: PropTypes.oneOf([undefined, 'left', 'right']),
    visible: PropTypes.oneOf(['always', 'true', 'false', true, false]),
    shouldCellUpdate: PropTypes.func
  };

  static defaultProps = {
    resizable: true,
    visible: true
  };

  render() {
    return null;
  }
}