import React, { Component } from 'react';
import PropTypes from 'prop-types';
import jsUtils from 'base/jsUtils';

const MAX_RETRY_COUNT = 3;

export default class extends Component {
  static propTypes = {
    onResize: PropTypes.func,
    wait: PropTypes.number,
    observe: PropTypes.string
  };

  static defaultProps = {
    wait: 1,
    observe: 'both'
  };

  constructor(props) {
    super(props);

    this.handleResize = jsUtils.throttle(this.handleResize, this.props.wait, { leading: false, trailing: true });
    this.mounted = false;
    this.retryCount = 0;
  }

  handleResize = (ev) => {
    const { onResize } = this.props;

    onResize && onResize(ev);
  };

  retryAddResizeListener = () => {
    if (++this.retryCount > MAX_RETRY_COUNT) {
      return;
    }

    this.timeoutId = setTimeout(() => {
      this.addResizeListener();
    }, 10);
  };

  addResizeListener = () => {
    if (!this.mounted) {
      return;
    }

    // Add Event Listener after some delay because sometimes the contentWindow of iframe is null
    if (!this.iframeRef?.contentWindow) {
      this.retryAddResizeListener();
      return;
    }

    this.iframeRef.contentWindow.addEventListener(
      'resize',
      this.handleResize
    );

    this.handleResize();
  };

  componentDidMount() {
    this.mounted = true;

    this.addResizeListener();
  }

  componentWillUnmount() {
    this.mounted = false;
    clearTimeout(this.timeoutId);

    if (this.iframeRef.contentWindow) {
      this.iframeRef.contentWindow.removeEventListener(
        'resize',
        this.handleResize
      );
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { observe } = this.props;
    const className = ['react-data-grid-resize-handler'];

    if (observe === 'vertical' || observe === 'horizontal') {
      className.push(observe + '-observer');
    }

    return <div className={className.join(' ')}>
      <iframe ref={element => this.iframeRef = element}/>
    </div>;
  }
}