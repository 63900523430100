import React from 'react';
import sandbox from 'sandbox';
import { Column, headers, cells } from 'widgets/ReactDataGrid/';
import { COMPARE_TYPE } from 'core/comparators';

const { SortableHeader } = headers;
const { Text, Thumbnail, IconCell, SeparationsCell } = cells;
const { translate } = sandbox.localization;
const isUndefined = o => typeof o === 'undefined';
const isNull = o => o === null;
const isString = o => typeof o === 'string';


const labels = {
  preflightWarning: translate('Preflight warning'),
  preflightError: translate('Preflight Error')
};

export const columnsDefinition = (items) => {
  return [
    {
      key: 'pageContentNwid',
      width: 90,
      align: 'center',
      autoWidth: false,
      resizable: false,
      title: translate('Thumbnail'),
      cell: Thumbnail,
      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: items[rowIndex].pageContentNwid,
          iconUID: items[rowIndex].iconUID
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData || nextProps.iconUID !== props.iconUID
    },
    {
      key: 'advertiser',
      width: 150,
      align: 'left',
      autoWidth: false,
      title: translate('Advertiser'),
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cell: Text,
      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: items[rowIndex].advertiser
        };
      }
    },
    {
      key: 'publication',
      width: 200,
      align: 'left',
      autoWidth: false,
      title: translate('Publication'),
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cell: Text,
      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: items[rowIndex].publication
        };
      }
    },
    {
      key: 'issueDate',
      width: 94,
      align: 'left',
      autoWidth: false,
      title: translate('Issue Date'),
      sortType: COMPARE_TYPE.DATES,
      cell: Text,
      valueGetter: item => item.issueDate.time,
      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: items[rowIndex].issueDate.localizedDate
        };
      }
    },
    {
      key: 'originalName',
      width: 150,
      align: 'left',
      autoWidth: false,
      title: translate('Name'),
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cell: Text,
      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: items[rowIndex].originalName
        };
      }
    },
    {
      key: 'adSpecification',
      width: 200,
      align: 'left',
      autoWidth: false,
      title: translate('Ad Specification'),
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cell: Text,
      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: items[rowIndex].adSpecification
        };
      }
    },
    {
      key: 'version',
      width: 73,
      align: 'left',
      autoWidth: false,
      title: translate('Version'),
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cell: Text,
      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: items[rowIndex].version
        };
      }
    },
    {
      key: 'separations',
      width: 100,
      align: 'left',
      autoWidth: false,
      title: translate('Separations'),
      cell: SeparationsCell,
      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: items[rowIndex].separations
        }
      }
    },
    {
      key: 'preflightTitle',
      width: 121,
      align: 'center',
      autoWidth: false,
      title: translate('Preflight Flaw'),
      cell: IconCell,
      cellDataGetter: (rowIndex, key) => {
        const preflightTitle = items[rowIndex].preflightTitle;
        const title = preflightTitle === 'warning' ? labels.preflightWarning : preflightTitle === 'error' ? labels.preflightError : ''

        return {
          icon: items[rowIndex].preflightIconName,
          iconStyle: { verticalAlign: 'middle' },
          title
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.icon !== props.icon
    },
    {
      key: 'step',
      width: 55,
      align: 'center',
      autoWidth: false,
      title: translate('Step'),
      cell: IconCell,
      cellDataGetter: (rowIndex, key) => {
        return {
          icon: items[rowIndex].flowStepIconName,
          iconStyle: { verticalAlign: 'middle' }
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.icon !== props.icon
    },
    {
      key: 'status',
      width: 66,
      align: 'center',
      autoWidth: false,
      title: translate('Status'),
      cell: IconCell,
      cellDataGetter: (rowIndex, key) => {
        return {
          icon: items[rowIndex].statusIconName,
          iconStyle: { verticalAlign: 'middle' }
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.icon !== props.icon
    },
    {
      key: 'statusTime',
      width: 94,
      align: 'left',
      autoWidth: false,
      title: translate('Status Time'),
      sortType: COMPARE_TYPE.DATES,
      cell: Text,
      valueGetter: item => item.statusTime.time,
      cellDataGetter: (rowIndex, key) => {
        return {
          columnData: items[rowIndex].statusTime.localizedDate
        };
      }
    }
  ];
};

export const renderColumn = (sortBy, handleColumnClick) => (column) => {
  const sort = !isUndefined(column.sortType) && sortBy.prop === column.key ? sortBy.direction : undefined;
  const handleClick = !isUndefined(column.sortType) ? handleColumnClick(column.sortType) : undefined;

  return <Column
    key={column.key}
    width={column.width}
    align={column.align}
    autoWidth={column.autoWidth}
    resizable={column.resizable}
    header={props => <SortableHeader {...props} sort={sort} >{column.title}</SortableHeader>}
    onClick={handleClick}
    cell={column.cell}
    cellDataGetter={column.cellDataGetter}
    shouldCellUpdate={column.shouldCellUpdate}
  />;
};

export default (multiple, items, sites, sortBy, handleColumnClick) => {
  return columnsDefinition(items);//.map(renderColumn(sortBy, handleColumnClick));
};  