/**
 * Created by moshemal on 11/9/14.
 */

import dom from 'base/dom';
import AbstractModule from 'AbstractModule';
import Class from 'class';
import sandbox from 'sandbox';
import jsUtils from 'sandbox/jsUtils';
import Graph from 'widgets/Graph/Graph';
import EditableGraph from 'widgets/EditableGraph/EditableGraph';
import template from 'text!./templates/fourCells.txt';
import iconService from 'core/services/iconService';
import { translate } from 'core/services/localization';

var SAVE_TIMEOUT = 1000;
var names = ["flowDispatch", "flowRealTime", "flowExec", "flowError"];

function toSubId(elementId, name) {
  //if changing this, html nodes ids should be changed too
  return elementId + "-" + name;
}

var SmallToolbar = Class.extend({
  //TODO: I copied all the css class from the main toolbar, seems that it can be refactored
  init: function (context) {
    this._element = dom.createNode("<ul class='k-widget k-menu'></ul>");
    this._context = context;
  },
  addItem: function (props) {
    props.icon = iconService.getActionIcon(props.icon);
    var button = dom.createNode(sandbox.jsUtils.format(this._itemTemplate, props));
    button.on('click', props.execute.bind(this._context));
    this._element.append(button);
  },
  _itemTemplate: "<li class='k-item button-margin' title='{label}'><img src='{icon}' style='{style}'></li>"
}, "SmallToolbar");

function createSmallToolbar(graph) {
  var toolbar = new SmallToolbar(graph);
  toolbar.addItem({
    label: translate('Zoom out'),
    icon: 'zoom_out.svg',
    style: 'width: 16px; height: 16px',
    execute: function () {
      this.zoomOut();
    }
  });
  toolbar.addItem({
    label: translate('Zoom in'),
    icon: 'zoom_in.svg',
    style: 'width: 16px; height: 16px',
    execute: function () {
      this.zoomIn();
    }
  });
  toolbar.addItem({
    label: translate('Zoom to fit screen'),
    icon: 'fit_to_window',
    execute: function () {
      this.zoomToFit();
    }
  });
  toolbar.addItem({
    label: translate('Auto layout'),
    icon: 'auto_layout',
    execute: function () {
      this.autoLayout();
    }
  });
  $(toolbar._element).insertBefore("#" + graph._initObj.element);
}

function addToolbarItems() {
  this.toolbar.addItem({
    action: 'LockWorkflow',
    itemType: 'toggle',
    _isApplicable: true,
    isApplicable: function () {
      return this._status.isLocked ? true : !this._status.isEditable;
    }.bind(this),
    checked: true,
    tooltipOn: 'Unlock Workflow',
    tooltipOff: 'Lock Workflow',
    iconOn: 'lock',
    iconOff: 'unlock',
    execute: function (checked) {
      this._forEachGraph(function (g) {
        checked ? g.lock() : g.unlock();
      });

      this._status.isLocked = checked;
    }.bind(this)
  }
  );

  var iconsNames = ["top_left", "top_right", "bottom_left", "bottom_right"];
  ['flowExec', 'flowRealTime', 'flowDispatch', 'flowError'].forEach(function (flowName, index) {
    this.toolbar.addItem({
      label: 'Show ' + flowName,
      name: 'Show ' + flowName,
      _isApplicable: true,
      icon: `flow_${iconsNames[index]}.svg`,
      execute: function () {
        var table = dom.find(".flex-table", this.element);
        var rows = dom.find(".flex-row", table);
        var cells = dom.find(".flex-cell", table);
        var isOneCell = table[0].classList.contains("one-cell");
        dom.removeClass(rows, "one-cell");
        dom.removeClass(cells, "one-cell");
        dom.addClass(table, "one-cell");
        dom.addClass(rows[parseInt(index / 2)], "one-cell");
        dom.addClass(cells[index], "one-cell");
        this.views[flowName].redraw();
        var graph = this.views[flowName].graph;
        if (!isOneCell) {
          this._forEachGraph(function (g) {
            g.graph.scale = g.graph.scale * 2;
          });
        }
        graph.contentAlignment = new graph.contentAlignment.constructor(0.5, 0.5);
        graph.contentAlignment = new graph.contentAlignment.constructor(NaN, NaN);
      }.bind(this)
    });
  }.bind(this));
  this.toolbar.addItem({
    label: 'Show All',
    name: 'Show All',
    _isApplicable: true,
    icon: 'flow_all.svg',
    execute: function () {
      var table = dom.find(".flex-table", this.element);
      var rows = dom.find(".flex-row", table);
      var cells = dom.find(".flex-cell", table);
      var isOneCell = table[0].classList.contains("one-cell");
      dom.removeClass(table, "one-cell");
      dom.removeClass(rows, "one-cell");
      dom.removeClass(cells, "one-cell");
      if (isOneCell) {
        this._forEachGraph(function (g) {
          g.graph.scale = g.graph.scale / 2;
          g.graph.contentAlignment = new g.graph.contentAlignment.constructor(0.5, 0.5);
          g.graph.contentAlignment = new g.graph.contentAlignment.constructor(NaN, NaN);
        });
      }
      this.rerender();
    }.bind(this)
  });

}

function createHtml(elementId, editable) {
  var template_ = !!editable ? '<div style="display: flex; flex: 1;">' : "";
  template_ += template;
  template_ += !editable ? "" : '</div><div style="width:250px; border: 1px solid;" id="'
    + elementId + '-palettes"></div>';

  return sandbox.jsUtils.format(template_,
    names.reduce(function (result, curr, idx) {
      result["name" + idx] = curr;
      return result;
    }, { elementId: elementId }));
}

function getElementIndexWithClass(arr, cssClass) {
  let resIndex = -1;
  [].some.call(arr, function (elem, index) {
    if (elem.classList.contains(cssClass)) {
      resIndex = index;
      return true;
    }
    return false;
  });
  return resIndex;
}

function editBinder() {
  var nodes = [],
    preferences = [],
    transitions = [],
    elementId = this.element.slice(1),
    editTemplate = createHtml(elementId, true),
    editableGraph = null,
    prms = sandbox.request.simpleGenericCommand(this.getRequiredParameters().rootId,
      this.getRequiredParameters().rootType, 'getRegisteredAdapters');

  var module = this;
  //before creating the editable graphs dom elements
  var table = dom.find(".flex-table", this.element);
  var rows = dom.find(".flex-row", table);
  var cells = dom.find(".flex-cell", table);
  var isOneCell = table[0].classList.contains("one-cell");
  var cellIndex = getElementIndexWithClass(cells, "one-cell"),
    rowIndex = getElementIndexWithClass(rows, "one-cell");

  sandbox.dom.html(this.element, editTemplate);

  //after creating the editable graphs dom elements
  if (isOneCell) {
    table = dom.find(".flex-table", this.element);
    rows = dom.find(".flex-row", table);
    cells = dom.find(".flex-cell", table);
    dom.addClass(table, "one-cell");
    dom.addClass(rows[rowIndex], "one-cell");
    dom.addClass(cells[cellIndex], "one-cell");
  }

  for (var p in this.views) {
    var graph = this.views[p];

    if (graph) {
      nodes = graph.exportNodesData();
      preferences = graph.getPreferences();
      transitions = graph.exportTransitions();
      graph.destroy();
    }

    editableGraph = new EditableGraph({
      element: toSubId(elementId, p),
      node: {
        labelProperty: "name",
        editableLabel: true,
        typeProperty: "className"
      }
    });
    editableGraph.insertData({
      nodeKeyProperty: "nwid",
      linkFromKeyProperty: "sourceNwId",
      linkToKeyProperty: "targetNwId",
      nodeDataArray: nodes,
      linkDataArray: transitions,
      preferences: preferences
    });
    createSmallToolbar(editableGraph);
    this.views[p] = editableGraph;
  }

  prms.then(function (binderPalettes) {
    var palettesArr = [];
    for (var p in binderPalettes) {
      var palette = binderPalettes[p];
      palette.name = p;
      palettesArr.push(palette);
    }

    var myPalette = editableGraph.createPalettes({
      element: elementId + "-palettes",
      palettes: palettesArr,
      closeFunction: function () {
        module._exitEditBinder();
      }
    });
  });
}

function exitEditBinder() {
  var moduleId = this.id;
  sandbox.reloadModule(moduleId);
}

module.exports = AbstractModule.extend({
  _editBinder: editBinder,
  _exitEditBinder: exitEditBinder,
  _forEachGraph: function (func) {
    for (var p in this.views) {
      var graph = this.views[p];
      if (graph) {
        func(graph, p);
      }
    }
  },
  autoLayout: function () {
    this._forEachGraph(function (g) { g.autoLayout(); });
  },
  isEditable: function () {
    return this._status.isEditable;
  },
  exitEditMode: function () {
    this._exitEditBinder();
  },
  initDone: function () {
    var module = this,
      elementId = this.element.slice(1),
      moduleElem = sandbox.dom.find(this.element),
      html = createHtml(elementId, false);

    sandbox.dom.addClass(moduleElem, 'pro-modules-binder');
    sandbox.dom.html(moduleElem, html);

    function createGraph(element) {
      var graph = new Graph({
        element: element,
        contextMenu: function (event, clicked, selected) {
          module.showContextMenu(clicked, selected, event);
        },
        node: {
          doubleClick: function (event, node) {
            module.navigateByViewLink(node);
          },
          labelProperty: "name",
          typeProperty: "className"
        }
      });
      createSmallToolbar(graph);
      return graph;
    }

    var views = {};
    names.forEach(function (curr) {
      views[curr] = createGraph(toSubId(elementId, curr));
    });
    this.views = views;

    this.toolbar = this.createToolbar();


    var savePreferences = function () {
      var preferences = {};
      this._forEachGraph(function (g, name) {
        preferences[name] = g.getPreferences();
      });
      var requiredParameters = module.getRequiredParameters();
      sandbox.preferences.savePreferences(requiredParameters, preferences);
    }.bind(this);
    this._savePreferencesNow = savePreferences;
    this._savePreferences = jsUtils.debounce(savePreferences, SAVE_TIMEOUT);
    this._status = {
      isEditable: false,
      isLocked: false
    };
  },
  insertData: function (views, data, module) {
    data.model.adapterFlows.forEach(function (curr) {
      var model = {
        nodeKeyProperty: "nwid",
        linkFromKeyProperty: "sourceNwId",
        linkToKeyProperty: "targetNwId",
        nodeDataArray: curr.adapters,
        linkDataArray: curr.transitions,
        preferences: data.preferences[curr.name]
      };
      views[curr.name].insertData(model);
      views[curr.name].addChangedListener(module._savePreferences);
    });
  },

  firstTickReceived: function firstTickReceived(data) {
    var views = this.views;

    if (this.tab.isActive()) {
      this.insertData(views, data, this);
    } else {
      this.data = data;
    }

    addToolbarItems.call(this);
  },
  tickUpdate: function (data) {
    //TODO: implement tickUpdate

  },
  rerender: function () {
    if (this.views) {
      if (this.data) {
        this.insertData(this.views, this.data, this);
        this.data = null;
      }
      Object.values(this.views).forEach(function (view) {
        view.redraw();
      });
    }
  },

  destroy: function () {
    this._super();

  }
}, "BinderView");
