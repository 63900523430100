import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Multiselect } from 'components/common/floating';

export default class extends Component {
  static propTypes = {
    columnData: PropTypes.any,
    options: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]),
    onChange: PropTypes.func
  };

  static defaultProps = {
    onChange: () => { }
  };

  handleSelect = (e, value) => {
    var { rowIndex, columnKey, onChange } = this.props;

    if (typeof this.props.onChange === 'function')
      onChange(rowIndex, columnKey, value);
  };

  render() {
    var { columnData, options, ...props } = this.props;

    return <div className="cell">
      <div className="cell-content cell-full-size">
        <Multiselect options={options} {...props} value={columnData} onSelect={this.handleSelect} />
      </div>
    </div>;
  }
}
