import React from 'react';
import colorService from 'core/services/colorService';
import iconService from 'core/services/iconService';
import { getMainStatus } from 'utilities/statuses';

export default class extends React.Component {
  static defaultProps = {
    separation: undefined
  };

  getSeparation = () => {
    return this.props.separation;
  };

  getSeparationProgress = (separation) => {
    const status = getMainStatus(separation);
    return typeof status?.progress !== 'undefined' ? status.progress : undefined;
  };

  getSeparationVersion = (separation) => {
    return typeof separation !== 'undefined' && typeof separation.separationContent !== 'undefined' && typeof separation.separationContent.externalVersion !== 'undefined' ?
      separation.separationContent.externalVersion :
      undefined;
  };

  getSeparationStyle = (rgb) => {
    return {
      borderTop: 'solid 6px transparent',
      borderBottom: 'solid 6px transparent',
      borderLeft: 'solid 12px ' + rgb,
      width: '0px',
      height: '0px',
      marginBottom: '2px'
    };
  };

  getSeparationContentStyle = (progress, version, rgb) => {
    return {
      borderTop: 'solid 4px transparent',
      borderBottom: 'solid 4px transparent',
      borderLeft: 'solid 8px ' + ((progress === -1 || progress === '-1') && version === 0 ? '#eeeeee' : rgb),
      marginTop: '-4px',
      marginLeft: '-11px'
    };
  };

  render() {
    var separation = this.getSeparation();

    if (typeof separation === 'undefined') return undefined;

    var separationName = separation.name,
      separationColor = colorService.getColorByName(separationName),
      separationRGB = colorService.getUIColorRGB(separationColor),
      separationVersion = this.getSeparationVersion(separation),
      separationProgress = this.getSeparationProgress(separation),
      separationStyle = this.getSeparationStyle(separationRGB),
      separationContentStyle = this.getSeparationContentStyle(separationProgress, separationVersion, separationRGB);

    return <div className={"Separation Linked-Separation-" + separationName} style={separationStyle}>
      <div className="Separation-Content" style={separationContentStyle}>
        {this.props.children}
      </div>
    </div>;
  }
}