import React from 'react';

/*
 * @param DecoratedComponent - component from the 'components' folder
 * @param Masker - masker function from the 'maskers' folder
 * @param getOptions - callback function to obtain options argument for the Masker
 * @param propName - property name that used to obtain argument value for getOptions callback
 */
export default (DecoratedComponent, Masker, getOptions, propName = 'mask') => class extends React.Component {
  render() {
    var {children, ...props} = this.props;
    var propValue = this.props[propName];

    var options = getOptions(propValue);

    return (
      <DecoratedComponent
        {...props}
        masker={Masker(options)}
      >
        {children}
      </DecoratedComponent>
    );
  }
};
