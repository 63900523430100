/**
 * @name EditableGraph
 * @author Moshe Malka
 */

define(['go', 'widgets/Graph/Graph', './Palettes', 'sandbox/jsUtils'], function (go, Graph, Palettes, jsUtils) {
  'use strict';

  var make = go.GraphObject.make;


  function showSmallPorts(node, show) {
    node.ports.each(function(port) {
      if (port.portId !== "") {  // don't change the default port, which is the big shape
        port.fill = show ? "rgba(0,0,0,.3)" : null;
      }
    });
  }

  function InitialLayoutCompleted(e){
    e.diagram.layout = make(go.Layout);
  }

//#########################################################################################
//#################                 PUBLIC                         ########################
//#########################################################################################

  function applyLocalChanges(){
    var newCount 		= 0,
      newIds			= {};
    var model						= this.graph.model,
      nKP							= model.nodeKeyProperty,
      nodesMap 		= this._modelBefore.nodeDataArray.reduce(function(last, curr){
        if (!(typeof curr[nKP] === 'number' && curr[nKP] < 0) ) {
          last[curr[nKP]] = curr;
        }
        return last;
      }, {});

    this.exportNodesData().forEach(function(curr){
      if (!nodesMap[curr[nKP]]){
        newIds[curr[nKP]] = "new_" + newCount;
        newCount += 1;
      }
    });
    this.updateNodesIds(newIds);
  }

  function updateNodesIds(IdsMap){
    if(!IdsMap || Object.keys(IdsMap).length === 0){
      return;
    }
    var model						= this.graph.model,
      nKP							= model.nodeKeyProperty,
      fromP						=	model.linkFromKeyProperty,
      toP							= model.linkToKeyProperty,
      nodes 					= this._getInternalNodesData().map(function(e){return jsUtils.cloneDeep(e);}),
      transitions 		= this._getInternalTransitions().map(function(e){return jsUtils.cloneDeep(e);});

    nodes.forEach(function(node){
      if ( !IdsMap[node[nKP]] ){
        return;
      }
      var lastId	= node[nKP];
      node[nKP]		= IdsMap[node[nKP]];

      transitions.forEach(function(t){
        if (t[fromP] === lastId){t[fromP] = node[nKP];}
        if (t[toP] === lastId){t[toP] = node[nKP];}
      });
      node.tempId = undefined;
    });
    this._updateModel({
      nodeDataArray: nodes,
      linkDataArray: transitions
    })
  }

  function updateModel(dataObj){
    var model = this.graph.model;
    this._modelBefore = {
      nodeDataArray:        dataObj.nodeDataArray.map(function(e){return jsUtils.cloneDeep(e);}),
      linkDataArray: 				dataObj.linkDataArray.map(function(e){return jsUtils.cloneDeep(e);})
    };

    this.graph.model = make(go.GraphLinksModel,
      {
        nodeKeyProperty: 			dataObj.nodeKeyProperty || model.nodeKeyProperty,
        linkFromKeyProperty:	dataObj.linkFromKeyProperty || model.linkFromKeyProperty,
        linkToKeyProperty:    dataObj.linkToKeyProperty || model.linkToKeyProperty,
        nodeDataArray:        dataObj.nodeDataArray,
        linkDataArray: 				dataObj.linkDataArray
      });
    //this.graph.model.addChangedListener(modelChanged.bind(this));
  }

  function createEditableDiagram(initObj){
    return make(go.Diagram, initObj.element, {
      initialContentAlignment:  go.Spot.Center,
      hasHorizontalScrollbar:   false,
      hasVerticalScrollbar:     false,
      allowDelete:              true,
      allowCopy:                true,
      allowMove:                true,
      nodeTemplate:             this._createNodeTemplate(initObj.node),
      linkTemplate:             this._createLinkTemplate(),
      groupTemplate:            this._createGroupTemplate(initObj.group),


      allowDrop: true,  // must be true to accept drops from the Palette
      "linkingTool.portGravity": 40,
      "relinkingTool.portGravity": 20,
      "relinkingTool.fromHandleArchetype":
        make(go.Shape, "Diamond", { segmentIndex: 0, cursor: "pointer", desiredSize: new go.Size(8, 8), fill: "tomato", stroke: "darkred" }),
      "relinkingTool.toHandleArchetype":
        make(go.Shape, "Diamond", { segmentIndex: -1, cursor: "pointer", desiredSize: new go.Size(8, 8), fill: "darkred", stroke: "tomato" }),
      "linkReshapingTool.handleArchetype":
        make(go.Shape, "Diamond", { desiredSize: new go.Size(7, 7), fill: "lightblue", stroke: "deepskyblue" }),
      "rotatingTool.snapAngleMultiple": 15,
      "rotatingTool.snapAngleEpsilon": 15,
      // don't set some properties until after a new model has been loaded
      "InitialLayoutCompleted": InitialLayoutCompleted,  // this DiagramEvent listener is defined below
      "undoManager.isEnabled": true
    });
  }

  function createNodeTemplate(){
    var template = this._super.apply(this, arguments),
      oldMouseEnter = template.mouseEnter,
      oldMouseLeave = template.mouseLeave;
    template.mouseEnter = function(e, node) {
      oldMouseEnter(e, node);
      showSmallPorts(node, true);
    };
    template.mouseLeave = function(e, node) {
      oldMouseLeave(e, node);
      showSmallPorts(node, false);
    };
    return template;
  }
  function createLinkTemplate(){
    var template = this._super.apply(this, arguments);
    template.relinkableFrom = true;
    template.relinkableTo = true;
    template.selectable = true;
    return template;
  }

  function destroy(){
    if (this.palettes){
      this.palettes.destroy();
      this.palettes = null;
    }
    this._super();
  }

  function insertData(dataObj) {
    this._modelBefore = {
      nodeDataArray: 				dataObj.nodeDataArray.map(function(e){return jsUtils.cloneDeep(e);}),
      linkDataArray: 				dataObj.linkDataArray.map(function(e){return jsUtils.cloneDeep(e);})
    };
    this._super(dataObj);
  }

  function createPalettes(initObj) {
    initObj.nodeTemplateMap = this.graph.nodeTemplateMap;
    initObj.labelProperty = this._initObj.node.labelProperty;
    this.palettes = new Palettes(initObj);
  }

  function init (initObj) {
    this._super(initObj);
    this.isEditable = true;
    this._modelBefore = {
      nodeDataArray: [],
      linkDataArray: []
    };
    this.unlock();
  }

  function deleteLinks (nodes, graph) {
    var linksToRemove = [];
    nodes.forEach(function(node){
      var iter = node.findLinksInto();
      while(iter.next()){
        var link = iter.value;
        if (!nodes.any(function(n){
            return link.fromNode === n;
          })){
          linksToRemove.push(link.data);
        }
      }
      iter = node.findLinksOutOf();
      while(iter.next()){
        var link = iter.value;
        if (!nodes.any(function(n){
            return link.toNode === n;
          })){
          linksToRemove.push(link.data);
        }
      }
    });
    graph.model.removeLinkDataCollection(linksToRemove);
  }

  function exportInsertData(){
    var g = this.graph;

    var model = {
      nodeKeyProperty: 			g.model.nodeKeyProperty,
      linkFromKeyProperty: 	g.model.linkFromKeyProperty,
      linkToKeyProperty: 		g.model.linkToKeyProperty,
      nodeDataArray: 				this.exportNodesData(), //nodes,
      linkDataArray: 				this.exportTransitions(), //define the links
      preferences:          this.getPreferences()
    };
    this.insertData(model);
  }

  function groupNodes () {
    var g = this.graph;
    var selected = g.selection.toArray();
    var selectedNodes = selected.filter(function(n){return n instanceof go.Node});
    if (selected.length === 0){
      return;
    }
    var groupNwid = "g_" + Date.now();

    selectedNodes.forEach(function(node){
      node.data.group = groupNwid;
    })
    exportInsertData.call(this);
  }

  function unGroupNodes () {
    var g = this.graph;
    var selected = g.selection.toArray().filter(function(node){
      return node instanceof go.Group;
    });

    selected.forEach(function(group){
      group.memberParts.each(function(node){
        node.data.group = undefined;
      });
    });
    exportInsertData.call(this);
  }



  var EditableGraph =  Graph.extend({
    _createDiagram:       createEditableDiagram,
    _createLinkTemplate:  createLinkTemplate,
    _createNodeTemplate:  createNodeTemplate,
    init: 								init,
    insertData: 					insertData,
    applyLocalChanges: 		applyLocalChanges,
    updateNodesIds:       updateNodesIds,
    _updateModel:					updateModel,
    createPalettes: 			createPalettes,
    destroy: 							destroy,
    //actions
    groupNodes:   groupNodes,
    unGroupNodes: unGroupNodes
  }, "EditableGraph");

  return EditableGraph;
});