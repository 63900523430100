import React, { Component } from 'react';
import ThumbnailImage from './thumbnailImage';
import PropTypes from 'prop-types';

export default class extends Component {
  static contextTypes = {
    controller: PropTypes.object
  };

  static defaultProps = {
    thumbnail: undefined,
    thumbnailSize: undefined,
    definition: undefined
  };

  getPages = (thumbnail) => {
    if (thumbnail.type === 'form') {
      return thumbnail.pages;
    }
    return [thumbnail];
  };

  getPageCoords = (page, singlePageSize, matrix, indexInForm, pageOrietation, orietationDelta) => {
    var pageRow = (indexInForm / matrix.horizontalImageCount) | 0,
      pageCol = (indexInForm % matrix.horizontalImageCount);

    return [
      (pageCol * singlePageSize[0]),
      (pageRow * singlePageSize[1])
    ];
  };

  singlePage = (pageSize) => {
    return [pageSize[0] - 3, pageSize[1] - 3];
  };

  doublePage = (pageSize) => {
    return [pageSize[0] * 2 - 3, pageSize[1] - 3];
  };

  doubleVerticalPage = (pageSize) => {
    return [pageSize[0] - 3, pageSize[1] * 2 - 3];
  };

  getPageSize = (page, thumbnail, singlePageSize, matrix, pageOrietation, orietationDelta) => {
    if (!page.pageContent.isPanorama) return this.singlePage(singlePageSize);

    if (thumbnail.type === 'page') return this.doubleVerticalPage(singlePageSize);
    if (matrix.horizontalImageCount * matrix.verticalImageCount === 1) return this.singlePage(singlePageSize);
    if (pageOrietation === 'vertical') return this.doubleVerticalPage(singlePageSize);
    if (pageOrietation === 'horizontal') return this.doublePage(singlePageSize);

    return this.doublePage(singlePageSize);
  };

  renderPage = (page, thumbnail, thumbnailSize, matrix, indexInForm, pageOrietation) => {
    var controller = this.context.controller,
      singlePageSize = [
        thumbnailSize[0] / matrix.horizontalImageCount,
        thumbnailSize[1] / matrix.verticalImageCount
      ],
      orietationDelta = (singlePageSize[0] / 7) | 0,
      pageSize = this.getPageSize(page, thumbnail, singlePageSize, matrix, pageOrietation, orietationDelta),
      pageCoords = this.getPageCoords(page, singlePageSize, matrix, indexInForm, pageOrietation, orietationDelta),
      pageStatusDescription = controller.getPageStatusDescription(page),
      style = {
        position: "absolute",
        left: pageCoords[0],
        top: pageCoords[1],
        width: pageSize[0],
        height: pageSize[1]
      };

    return <div className={['thumbnail-page', pageStatusDescription].join(' ')} style={style}>
      <span className="thumbnail-page-text">
        {page.numberInSection}
      </span>
    </div>;
  };

  renderPages = (pages, thumbnail, thumbnailSize) => {
    var that = this,
      controller = this.context.controller,
      matrix = controller.getMatrix(thumbnail),
      formIndexes = controller.getFormIndexes(thumbnail),
      orietations = controller.getOrietations(thumbnail);
    return pages.map(function (page, index) {
      var nwid = page.pageContent.nwid,
        indexInForm = formIndexes[nwid],
        pageOrietation = orietations[nwid];
      return that.renderPage(page, thumbnail, thumbnailSize, matrix, indexInForm, pageOrietation);
    });
  };

  renderThumbnailContent = (pages, thumbnail, thumbnailSize) => {
    var controller = this.context.controller;
    var version = controller.getThumbnailContent(thumbnail).versionNwid;

    if (controller.showThumbnailImage(thumbnail)) {
      return <ThumbnailImage version={version} thumbnail={thumbnail} />;
    }

    return this.renderPages(pages, thumbnail, thumbnailSize);
  };

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.thumbnail.__isDirty === true;
  }

  render() {
    var {
          thumbnail,
      thumbnailSize,
      definition
          } = this.props,
      controller = this.context.controller,
      pages = this.getPages(thumbnail);

    return <div className="thumbnail" style={{ width: thumbnailSize[0], height: thumbnailSize[1] }}>
      {this.renderThumbnailContent(pages, thumbnail, thumbnailSize)}
    </div>;
  }
}