import React from 'react';
import PercentInput from 'components/common/inputs/PercentInput';
import InputCell from './InputCell';

function PercentInputCell({allowEmptyValue = true, size = 4, ...props}) {
  return (
    <InputCell InputComponent={PercentInput} allowEmptyValue={allowEmptyValue} size={size} {...props}/>
  );
}

export default PercentInputCell;
