/* @flow weak */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SimpleForm from 'widgets/SimpleForm/src/index';
import actions from '../actions';
import AnchorPoint from 'components/private/anchorPoint/AnchorPoint';
import utils from '../utils';

const { MeasurementInput } = SimpleForm;
const futils = SimpleForm.utils;

module.exports = class Page extends Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    store: PropTypes.any,
    form: PropTypes.object,
    page: PropTypes.object,
    path: PropTypes.string,
    style: PropTypes.object,
  };

  handleAnchorChange = (event, anchorPoint) => {
    //console.log("handleAnchorChange => anchorPoint=", anchorPoint);
    const { store } = this.props;
    const path = futils.compose(this.props.path, 'anchorPoint');
    store.dispatch(actions.update(path, anchorPoint));
  };

  renderSeparation = (separation, index) => {
    const { store } = this.props;
    const state = store.getState();
    const units = state.units;
    //const path = 'model.books.0.forms.0.pages.0.separations.0.offsetX';
    const path = futils.compose(this.props.path, 'separations', index);
    const color = utils.getColorValue(state.model.colorTable, separation.colorName);
    const key = `${separation.colorName}_${index}`;

    return (
      <tr key={key} className="separation-table-row">
        <td>
          <div className="separation-color" style={{backgroundColor: color}}></div>
        </td>
        <td><MeasurementInput className="separation-table-input" units={units} bind={futils.compose(path, 'offsetX')} /></td>
        <td><MeasurementInput className="separation-table-input" units={units} bind={futils.compose(path, 'offsetY')} /></td>
        <td><MeasurementInput className="separation-table-input" units={units} bind={futils.compose(path, 'scaleX')} /></td>
        <td><MeasurementInput className="separation-table-input" units={units} bind={futils.compose(path, 'scaleY')} /></td>
      </tr>
    );
  };

  render() {
    const { store, form, page, path, style } = this.props;
    const separations = page.separations || [];
    //console.log("separations =>", separations);
    //console.log("page.anchorPoint =>", page.anchorPoint);

    return (
      <div className='page' style={style}>
        <table className='separation-table' cellSpacing={0} cellPadding={0}>
          <thead>
            <tr className="separation-table-row">
              <th></th>
              <th>Offset X</th>
              <th>Offset Y</th>
              <th>Scale X</th>
              <th>Scale Y</th>
            </tr>
          </thead>
          <tbody>
            {separations.map(this.renderSeparation)}
          </tbody>
        </table>
        <AnchorPoint text={page.name}
                     group={form.nwid + '_' + page.nwid}
                     value={page.anchorPoint}
                     onChange={this.handleAnchorChange}
        />
      </div>
    );
  }
};
