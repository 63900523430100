/**
 * Created by moshemal on 7/6/15.
 */

import AbstractAction from 'AbstractAction';



module.exports = AbstractAction.extend({
  isApplicable: function () {
    return this.module.isEditable();
  },

  execute: function () {
    this.module.exitEditMode();
  }
});
