import PropTypes from 'prop-types';
import React, { Component } from 'react';

const isUndefined = o => typeof o === 'undefined';
const INDICATOR_CLASS_NAME = 'crtx-multi-status-indicators';

export default class MultiStatusIndicator extends Component {
  static propTypes = {
    values: PropTypes.arrayOf(PropTypes.oneOf([-1, 0, 1])).isRequired,
    title: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    onClick: PropTypes.func
  };

  renderStatusContent = (value, index) => {
    const className = `${INDICATOR_CLASS_NAME}-content${value < 0 ? ' error' : value === 0 ? ' waiting' : ''}`;

    return <div key={index} className={className}>
    </div>;
  };

  renderStatusesContent = (values) => {
    return values.map(this.renderStatusContent)
  };

  render() {
    const { values, title, style, className, onClick } = this.props;
    const indicatorClassName = `${INDICATOR_CLASS_NAME}${' ' + className || ''}`;

    return <div className={indicatorClassName} style={style} title={title} onClick={onClick}>
      {this.renderStatusesContent(values)}
    </div>;
  }
}