/**
 * Created by moshemal on 8/26/14.
 */

define(['jquery'],function(){
	'use strict'

	return {
		listen: function (selector, event, context, callback) {
			return $(selector).on(event, context, callback);
		},
		/**
		 *
		 * @param selector
		 * @param event
		 * @param callback
		 */
		unlisten: function(selector, event, callback){
			$(selector).off(event, callback);
		},
		trigger: function (event, context) {
			return $(context).trigger(event);
		},
		focus: function (selector, handler) {
			return $(selector).focus(handler);
		}
	};
});