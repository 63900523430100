import AbstractAction from 'AbstractAction';
import sandbox from 'sandbox';

export default AbstractAction.extend({

  isApplicable: function (selectedItems) {
    return (selectedItems || []).every(selectedItem => selectedItem.pageContent['MAIL:status'] !== 'complete');
  },

  execute: function (selectedItems) {
    const viewInstanceNwId = this.module.nwid;
    const msg = sandbox.localization.translate("You are about to delete this item. \nIt cannot be restored at later time! Continue?");

    sandbox.dialog.confirm(msg, 'Confirmation').then(() => {
      const model = {
        pages: Array.isArray(selectedItems) ? selectedItems.map(selectedItem => selectedItem.nwid ): []
      };
      
      const rest = sandbox.request.rest(viewInstanceNwId);
      rest.post(`cw/delete `, {
        data: JSON.stringify(model),
        dataType: 'text',
      });
    });
  }
});


