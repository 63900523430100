import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';

const isUndefined = o => typeof o === 'undefined';

export default class PreflightIndication extends Component {
  static propTypes = {
    reportType: PropTypes.number,
    title: PropTypes.string
  };

  static defaultProps = {
    title: ''
  };

  getSrcByReportType = (reportType) => {
    if (reportType === 1) {
      return sandbox.icons.getModuleIcon('MyBirdeye', 'preflight_report_warning', '.svg');
    }
    if (reportType === 2) {
      return sandbox.icons.getModuleIcon('MyBirdeye', 'preflight_report_error', '.svg');
    }

    return undefined;
  };

  render() {
    var reportType = this.props.reportType,
      src = this.getSrcByReportType(reportType),
      title = this.props.title;

    return isUndefined(src) ? <span></span> : <img src={src} title={title} />;
  }
}