/**
 * Created by moshemal on 1/8/15.
 */

import Ember from 'ember';
import $ from 'jquery';
import sandbox from 'sandbox';
import AbstractStep from 'widgets/Wizard/AbstractStep';
import BooksMainView from './views/BooksMain';
import Controller from './controllers/BooksController';
import ModelHandler from './model/model';
import PreStepModel from './model/preStepModel';
import wizardUtils from './../../utils/wizardUtils';
import { restPost } from 'core/managers/rest2';

function init(initObj) {
  this._super(initObj);
  //this.rootType = initObj.startParameters.rootType;
  //this.rootId = initObj.startParameters.rootId;
  //this.nwid = initObj.properties.properties.startParameters.nwid;

  var stepsProperties = initObj.wizard.initObj.stepsProperties;
  this.rootType = initObj.startParameters && initObj.startParameters.rootType ?
    initObj.startParameters.rootType : initObj.properties.properties.startParameters.rootType;
  this.rootId = initObj.startParameters && initObj.startParameters.rootId ?
    initObj.startParameters.rootId : initObj.properties.properties.startParameters.rootId;
  this.nwid = initObj.startParameters && initObj.startParameters.nwid ?
    initObj.startParameters.nwid : initObj.properties.properties.startParameters.nwid;
  this.wizard = initObj.wizard;
  this.settings = stepsProperties != null && stepsProperties.steps.books != null ? stepsProperties.steps.books : this.properties.properties;
}


function back() {
  return this.controller.updateModel(this.model);
}

function next() {
  return this.controller.updateModel(this.model);
}

function destroy() {
  console.log("destroy");
}

function render() {
  //console.log("###this.model =>", JSON.stringify(this.model));
  //***TEST
  //this.model = JSON.parse(modelExmp.model)
  this.wizard.disableButton("cancel");
  var mappedModel = wizardUtils.mapSourceIdToObject(this.model);
  ModelHandler.setModel(this.model);
  PreStepModel.validModel(this.model, mappedModel, this.defaultsLayouts);
  var model = ModelHandler.convertToStepModel(this.model, this.settings);
  var pmtMode = this.model.pmtMode;
  model.pmtMode = pmtMode;
  if (pmtMode) {
    model.pmtInfo = this.model.pmtInfo;
  } else {
    model.groups = this.model.groups;
  }
  model.resources = this.model.resources;
  model.resourceGroups = this.model.resourceGroups;
  this.model.pmiTemplates = (this.model.pmiTemplates || []).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  model.pmiTemplates = this.model.pmiTemplates;
  model.paperTypeOptions = this.model.paperTypeOptions;
  model.nwid = this.nwid;
  model.rootId = this.rootId;
  var folderNwId = this.model.folderPreFix ? this.model.folderPreFix + this.model.folder + "_" : "";
  model.folderNwId = folderNwId;
  if (this.model.clientSettings) {
    model.sourceIdBreadcrumb = this.model.clientSettings.sourceIdBreadcrumb
  }

  var that = this;
  if (typeof this.controller === "undefined" || this.controller === null) {
    this.controller = Controller.create({
      model: model,
      mappedModel: mappedModel,
      settings: that.settings
    });
  } else {
    Ember.set(this.controller, 'model', model);
  }

  //if (!(this._view instanceof Ember.View)){
  $(this.container).empty();
  this._view = BooksMainView.create({
    controller: this.controller
  }).appendTo(this.container);
  //}
}


function load() {
  console.log("load.................");

  let restModel = { name: this.model.publication.displayName, date: this.model.publication.issueDate }
  let editions = [];
  this.model.publication.editions.forEach(function (e) {
    let edition = { name: e.displayName };
    let zones = [];
    e.zones.forEach(function (z) {
      let zone = { name: z.displayName };
      let sections = [];
      z.sections.forEach(function (s) {
        let section = { name: s.displayName };
        sections.push(section);
      });
      zone.sections = sections;
      zones.push(zone);
    });
    edition.zones = zones;
    editions.push(edition);
  });
  restModel.editions = editions;
  var that = this;

  return restPost(this.nwid, `folders/${this.rootId}/planning-actions/getDefaultLayout`, restModel)
    .then((result) => {
      that.defaultsLayouts = result;
    })
}

module.exports = AbstractStep.extend({
  init: init,
  render: render,
  back: back,
  next: next,
  destroy: destroy,
  load: load
}, "Sections");