import { startInternalModule } from 'core/managers/module';
import AbstractAction from '../AbstractAction';
import {translate} from 'core/services/localization';

export default AbstractAction.extend({
  execute: function (selectedItems) {

    const moduleName = 'UserSetup';
    const name = translate(this.parameters.title);
    const win = this.module.win;
    const target = 'dialog';
    const windowWidth = this.parameters.windowWidth;
    const windowHeight = this.parameters.windowHeight;

    startInternalModule(moduleName, { name, win, target, windowWidth, windowHeight, selected: selectedItems }, this);
  }
});
