import sandbox from 'sandbox';
import OpenDialogAction from './OpenDialogAction';

const t = sandbox.localization.translate;

export default OpenDialogAction.extend({
    isApplicable: function (objs) {
        if (typeof objs === "undefined" || objs.length !== 1) {
            return false;
        }
        return "true" === objs[0].holdByExternalGrpoup;
      }
    
});