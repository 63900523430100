import iconService from 'core/services/iconService';
import request from 'core/managers/request';
import sandbox from 'sandbox';
import { getHoldIconName, getHoldType } from 'utilities/hold';

const isUndefined = o => typeof o === 'undefined';
const TABLOID = 'Tabloid';

export const SIZES = { SINGLE: 'single', DOUBLE: 'double' };

export const pageSize = ({ isPanorama, format }) => {
  return isPanorama && format !== TABLOID ? SIZES.DOUBLE : SIZES.SINGLE;
};

export const formSize = ({ horizontalImageCount, verticalImageCount }) => {
  return horizontalImageCount === 1 && verticalImageCount === 1 ? SIZES.SINGLE :
    horizontalImageCount === 1 && verticalImageCount === 1 ? SIZES.SINGLE :
      SIZES.DOUBLE;
};

export const isColor = (separations = []) => {
  return separations.length > 1;
};

export const getImageUrl = ({ contentNwid, contentType, versionNwid, contentVersionMajorIndex = 0, viewId, projectorId, action = 'icon' }) => {
  const requiredContentTyped = ['form/separation/content', 'page/separation/content'];

  if (!contentVersionMajorIndex && requiredContentTyped.indexOf(contentType) < 0) return undefined;

  return request.getImageUrl({
    template: contentType,
    action: action,
    viewId: viewId,
    nwid: contentNwid,
    iconUrlCounter: versionNwid,
    projectorId: projectorId
  }, true);
};

export const getIsPlanedSeparation = (progress, version) => {
  if ((progress === -1 || progress === '-1') && version === 0) return false;

  return true;
}

export const getFlowStepImageUrl = ({ flowStepType, flowStepIconName, statusType }) => {
  if (isUndefined(flowStepType) || isUndefined(statusType)) return undefined;

  return iconService.getTemplateIcon(flowStepType, 'tiny', flowStepIconName);
};

export const getHoldImageUrl = item => {
  const holdType = getHoldType(item);
  const iconName = getHoldIconName(holdType);
  if (iconName) {
    return sandbox.icons.getGeneralIcon('', iconName, '.svg');
  }
};

export const getPreFlightImageUrl = (reportType) => {
  const preflightTypesImageName = {
    1: 'preflight_report_warning',
    2: 'preflight_report_error'
  };

  if (isUndefined(preflightTypesImageName[reportType])) return undefined;

  return sandbox.icons.getModuleIcon('MyBirdeye', preflightTypesImageName[reportType], '.svg');
};

export const getLockImageUrl = (locked) => {
  return locked === true ? sandbox.icons.getModuleIcon('MyBirdeye', 'lock', '.svg') : undefined;
};

export const getPreApprovedImageUrl = (preApproved) => {
  return preApproved ? sandbox.icons.getModuleIcon('MyBirdeye', 'preApproved', '.svg') : undefined;
};

export const getSkipFlowStepImageUrl = (skipFlowStep) => {
  return skipFlowStep === 'true' || skipFlowStep === true ? sandbox.icons.getModuleIcon('MyBirdeye', 'SkipFlowstep', '.svg') : undefined;
};

export const getMultiApproversImageUrl = (multiApprovers = '', approver) => {
  const approverImageUrl = {
    'a': sandbox.icons.getModuleIcon('MyBirdeye', 'Advertising'),
    'e': sandbox.icons.getModuleIcon('MyBirdeye', 'Editorial')
  };

  return (multiApprovers.toLowerCase().indexOf(approver.toLowerCase()) >= 0) ? approverImageUrl[approver.toLowerCase()] : undefined;
};