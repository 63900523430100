/**
 * @name Redo Events
 * @fileOverview
 * @author sergey
 */

import sandbox from 'sandbox';
import AbstractAction from 'AbstractAction';

module.exports = AbstractAction.extend({
  isApplicable: function (events) {
    if (!events || this.module.isApprovalStep() || this.module.isHotFolderStep() || this.module.isStatusStep()) {
      return false;
    }

    var i = 0, len = events.length;
    if (len === 0) {
      return false;
    }
    for (i; i < len; i += 1) {
      if (events[i].state !== 'queError' &&
        events[i].state !== 'queFinished') {
        return false;
      }
    }
    return true;
  },

  execute: function (events) {
    var viewInstanceNwId = this.module.nwid;
    var deviceNwId = this.module.viewSettings.rootId;
    var type = this.module.viewSettings.rootType;
    var projectorId = this.module.projectorId;
    var request = sandbox.request.redoEvents(viewInstanceNwId, this.nwid, deviceNwId, type, projectorId,
      {
        config: this.config,
        items: events.map(function (e) {
          return {nwid: e.nwid};
        }),
        parameters: this.parameters
      }
    );
    return request.then(function (res) {
		if (res.data.success === false) {
			res.data.error = {message: "see event's error message for details"};
		}			
	  	return res;
    });
  }
});
