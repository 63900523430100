// @flow

/**
 * Rest manager
 * author: Guy Behar
 * date: 18/09/2016
 * 
 * Important:
 * Public function are declared in module.exports so they can use viewOrActionNwid and appear in intellisense without viewOrActionNwid param 
 */

import {coretexPath} from 'utilities/url';
const baseData = require('base/data');
const log = require('logger').getDefaultLogger();

function getHeaders(viewOrActionNwId, options) {
  const headers = options.headers;
  if (typeof viewOrActionNwId === 'string') {
    if (typeof headers === 'object') {
      headers['Content-Type'] = headers['Content-Type'] || 'application/json';
      options.headers = Object.assign({}, headers, { viewOrActionNwId });
    }
    else {
      options.headers = Object.assign({}, { 'Content-Type': 'application/json', viewOrActionNwId });
    }
  }
  return options.headers || {};
}

module.exports = function () {
  return function (viewOrActionNwId) {
    /**
     * rest function call to rest api on the server.
     * Params: 
     * command (String)                       - the command to run in the rest
     * values (String)                        - the values to add to the url reaching the end point
     * options: (Object) (Optional)      - object containing extra options for the ajax request
     *  headers                             - object containing the headers to send with the request (viewOrActionNwId will be added to the headers) 
     *  data                                - object containing the data to send if there is one 
     *  type                                - string representing the type of the ajax request (GET/POST/PUT/DELETE) default value GET
     *  dataType                            - string representing the dataType of the ajax request default value json
     *  success                             - callback function triggered when there is a success callback
     *  error                               - callback function triggered when there is an error callback
     */
    function rest(command, values, options = {}, version = 'v1') {
      const url = coretexPath('rest', version, command, values);
      const headers = getHeaders(viewOrActionNwId, options);
      const data = options.data;
      const type = options.type || 'GET';
      const dataType = options.dataType || 'json';
      const success = options.success || function () { };
      const error = options.error || function () { };

      return baseData.ajax({
        url,
        type,
        dataType,
        data,
        headers,
        error: function () {
          log.warn('Warning: Rest request failed');
          error.apply({}, arguments);
        },
        success
      });
    }

    function restv2(command, values, options = {}) {
      const url = coretexPath('rest', 'v2', command, values);
      const headers = getHeaders(viewOrActionNwId, options);
      const data = options.data;
      const type = options.type || 'GET';
      const dataType = options.dataType || 'json';
      const success = options.success || function () { };
      const error = options.error || function () { };

      return baseData.ajax({
        url,
        type,
        dataType,
        data,
        headers,
        error: function () {
          log.warn('Warning: Rest request failed');
          error.apply({}, arguments);
        },
        success
      });
    }

    function rest2(command, restUrl, options = {}, version = 'v2') {
      const url = coretexPath('rest', version, restUrl);
      const headers = getHeaders(viewOrActionNwId, options);
      const data = options.data;
      const type = command;
      const dataType = options.dataType || 'json';
      const success = options.success || function () { };
      const error = options.error || function () { };
      const async = typeof options.async === 'undefined' ? true : options.async;

      return baseData.ajax({
        url,
        type,
        dataType,
        data,
        headers,
        async,
        error: function () {
          log.warn('Warning: Rest request failed');
          error.apply({}, arguments);
        },
        success
      });
    }

    function sendBeacon(restUrl, data, version = 'v2'){
      const url = coretexPath('rest', version, restUrl);      
      navigator.sendBeacon(url, JSON.stringify(data));
    }

    function del(restUrl, options = {}, version) {
      return rest2('DELETE', restUrl, options, version);
    }

    function get(restUrl, options = {}, version) {
      return rest2('GET', restUrl, options, version);
    }

    function post(restUrl, options = {}, version) {
      return rest2('POST', restUrl, options, version);
    }

    function put(restUrl, options = {}, version) {
      return rest2('PUT', restUrl, options, version);
    }

    function getConversionSystemTableDefinition(name, folderNwid) {
      const command = 'ConversionTable';
      const values = `getSystemTableDefinition?name=${name}&folder=${folderNwid}`;
      return rest('ConversionTable', values);
    }

    function getConversionSampleTableDefinition(name, folderNwid) {
      const command = 'ConversionTable';
      const values = `getSampleTableDefinition?name=${name}&folder=${folderNwid}`;
      return rest('ConversionTable', values);
    }

    /**
     * rest function to call filesystem on the server
     * Params: 
     * values (String)
     * options (Object) (Optional)
     */
    function filesystem(values, options = {}) {
      return rest('filesystem', values, options);
    }

    function getCustomInfo(nwid, name = 'default', options = {}) {
      return rest('CustomInfo', `getCustomInfo?nwid=${nwid}&name=${name}`, options);
    }

    function readImage(params, filename, options = {}) {
      let values = `read-image/${filename}?viewOrActionNwId=${viewOrActionNwId}&`;
      for (var param in params) {
        values += param + "=" + params[param] + "&";
      }

      return coretexPath('rest', 'v1', 'image-actions', values);
    }

    function availableVersion(params, options = {}) {
      let values = `available-version?viewOrActionNwId=${viewOrActionNwId}&`;
      for (var param in params) {
        values += param + "=" + params[param] + "&";
      }
      return rest('image-actions', values);
    }

    function getPlanningWizardSettings(rootNwid, rootType, instanceNwid, options) {
      return rest('planningwizard', `getSettings?nwid=${rootNwid}&type=${rootType}&instanceNwId=${instanceNwid}`, options);
    }

    function getPageTypeSettings(nwid, type, options) {
      return rest('planningactions', `getPageTypeSettings?nwid=${nwid}&type=${type}`, options);
    }

    function getSharingOptions(nwid, type, options) {
      return rest('planningactions', `getSharingOptions?nwid=${nwid}&type=${type}`, options);
    }

    function getBookPlatesInfoOptions(nwid, type, options) {
      return rest('planningactions', `getBookPlatesInfoOptions?nwid=${nwid}&type=${type}`, options);
    }
    function getBookLayoutGroupsInfoOptions(nwid, type, options) {
      return rest('planningactions', `getBookLayoutGroupsInfoOptions?nwid=${nwid}&type=${type}`, options);
    }

    function getTableValue(name, criterions, value, folderNwid) {
      const command = 'conversion-tables';
      const values = `${name}/value?value=${value}&folder=${folderNwid}&criterions=${criterions}`;
      return restv2(command, values);
    }

    function getUsersGroups(name) {
      const command = 'profiles';
      const values = `${name}/association`;
      return restv2(command, values);
    }

    function getMacroSnippets() {
      const command = 'macros';
      const values = `snippets`;
      return restv2(command, values);
    }

    return {
      rest: rest,

      get: get,
      post: post,
      put: put,
      del: del,
      sendBeacon: sendBeacon,

      getConversionSystemTableDefinition,
      getConversionSampleTableDefinition,

      filesystem,

      getCustomInfo,

      readImage,

      availableVersion,

      getPlanningWizardSettings,

      getPageTypeSettings,

      getSharingOptions,

      getBookPlatesInfoOptions,

      getBookLayoutGroupsInfoOptions,

      getTableValue,

      getMacroSnippets

    };
  };
};