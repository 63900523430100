import React from 'react';
import PropTypes from 'prop-types';
import FragmentsListComponent from './FragmentsListComponent';
import PlatesProgressBar from './PlatesProgressBar';
import { classNames } from 'utilities/classNames';

export default function ZoneOverviewComponent({ module, model }) {
  const { pages, forms, statusPages, statusForms, productionRuns } = model;
  const renderProductionRuns = (run, index) => {
    return <PlatesProgressBar module={module} key={index} run={run} />;
  };

  const handlePagesDoubleClick = (event) => {
    module.openPages();
  };
  const handleFormsDoubleClick = (event) => {
    module.openForms();
  };

  const handlePagesContextMenu = (event) => {
    module.contextMenuPages(event);
  };
  const handleFormsContextMenu = (event) => {
    module.contextMenuForms(event);
  };

  const fragmentsClass = classNames('item-block', { 'item-block-left-side-half-height': pages.length > 0 && forms.length > 0 });

  return (
    <React.Fragment>
      <div className='left-side'>
        {pages.length > 0 && <div className={fragmentsClass}>
          <FragmentsListComponent
            onContextMenu={handlePagesContextMenu}
            onDoubleClick={handlePagesDoubleClick}
            fragments={pages}
            model={model}
            status={statusPages} />
        </div>}

        {forms.length > 0 && <div className={fragmentsClass}>
          <FragmentsListComponent
            onContextMenu={handleFormsContextMenu}
            onDoubleClick={handleFormsDoubleClick}
            fragments={forms}
            model={model}
            status={statusForms} />

        </div>}
      </div>
      {productionRuns.length > 0 &&
        <div className='right-side'>
          <div className='item-block item-block-right-side production-runs'>
            <div className='production-runs-progress'>
              {productionRuns.map(renderProductionRuns)}
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
}

ZoneOverviewComponent.propTypes = {
  module: PropTypes.object,
  model: PropTypes.object,

};