import {fromServerDateOnly, toServerDateOnly} from 'core/dates';

const isUndefined = o => typeof o === "undefined";

const deadlineOnLoad = value => {
  const daysOffset = Math.floor(value / (24 * 60));
  const deltaTime = value - daysOffset * 24 * 60;
  const hour = Math.floor(deltaTime / 60);
  const hourStr = hour.toString().length === 1 ? `0${hour}` : `${hour}`;
  const minutes = deltaTime % 60;
  const minutesStr =
    minutes.toString().length === 1 ? `0${minutes}` : `${minutes}`;
  return {
    days: daysOffset.toString(),
    hours: hourStr,
    minutes: minutesStr
  };
};

const deadlineOnSave = valueObj => {
  return (
    Number(valueObj.hours || 0) * 60 +
    Number(valueObj.minutes || 0) +
    Number(valueObj.days || 0) * 24 * 60
  );
};

const cellsConversion = {
  date: {
    onLoad: value => {
      return value ? fromServerDateOnly(value) : undefined;
    },
    onSave: value => {
      return value ? toServerDateOnly(value) : "";
    }
  },
  deadline: {
    onLoad: deadlineOnLoad,
    onSave: deadlineOnSave
  },
  releaseOffset: {
    onLoad: value => {
      return parseInt(value, 10) || 0;
    },
    onSave: value => {
      return parseInt(value, 10) || 0;
    }
  }
};

export default cellsConversion;
