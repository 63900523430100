/**
 * @name UserInfoPanel
 * @file UserInfoPanel component
 *
 * @author Boris
 * @since: 2017-07-16
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';
import FlatButton from 'components/common/buttons/FlatButton';
import BadgeButton from 'components/common/buttons/BadgeButton';
import Menu from 'components/common/menu/Menu';
import settingsManager from 'core/managers/settings';
import iconsService from 'core/services/iconService';

const { translate } = sandbox.localization;

const isFunction = o => typeof o === 'function';

export default class UserInfoPanel extends Component {
  static propTypes = {
    model: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      model: props.initialModel,
    };
  }



  renderGlobalSetupButton() {
    const { initialModel, onGlobalSetupButtonClick } = this.props;

    if (!initialModel.globalSetupLink) {
      return null;
    }

    return (
      <FlatButton className='user-info-global-setup-button'
        tooltip={translate('Global setup')}
        onClick={(e) => onGlobalSetupButtonClick(e)}
      >
        <i className='material-icons'>menu</i>
      </FlatButton>
    );
  }



  renderLogo() {
    let logo = '';

    if (settingsManager.get('productSkinName') === 'newsway') {
      logo = <img className='user-info-logo' src={iconsService.getModuleIcon('Userinfo', 'logo_ProImage')} />;
    } else {
      logo = <img className='user-info-logo eco3-logo' src={iconsService.getModuleIcon('Userinfo', 'eco3_logo', '.svg')} />;
    }

    return logo;
  }

  renderUserName() {
    const { user } = this.props;

    const nameLabel = `${user.name} (${user.site})`;

    return (
      <div className='user-info-name-label'>
        {nameLabel}
      </div>
    );
  }

  renderCommCenterButton() {
    const { unreadMailCount, onCommCenterButtonClick } = this.props;

    return (
      <BadgeButton className='user-info-comm-center-button'
        tooltip={unreadMailCount > 0 ? translate('Communication Center: {1} unread message(s)', unreadMailCount) : translate('Communication Center')}
        counter={unreadMailCount}
        onClick={(e) => onCommCenterButtonClick(e)}
      >
        <i className='material-icons'>email</i>
      </BadgeButton>
    );
  }

  renderChatButton() {
    const { unreadMessages, onChatButtonClick } = this.props;

    return (
      <BadgeButton className='user-info-comm-center-button'
        tooltip={unreadMessages > 0 ? translate('Chat: {1} unread message(s)', unreadMessages) : translate('Chat')}
        counter={unreadMessages}
        onClick={(e) => onChatButtonClick(e)}
      >
        <i className='material-icons'>chat</i>
      </BadgeButton>
    );
  }

  handleSelectMenuItem = (e, item, index) => {
    if (isFunction(item.onSelect)) item.onSelect();
  };

  renderMenu() {
    const { menuItems } = this.props;

    return (
      <Menu className='user-info-menu'
        items={menuItems}
        onSelect={this.handleSelectMenuItem}
      >
        <i className='material-icons'>arrow_drop_down</i>
      </Menu>
    );
  }

  render() {

    return (
      <div className='user-info'>
        {this.renderGlobalSetupButton()}
        {this.renderLogo()}
        {this.renderUserName()}
        {this.renderCommCenterButton()}
        {settingsManager.getFolderInfo().allowChat ? this.renderChatButton() : null}
        {this.renderMenu()}
      </div>
    );
  }

}
