/**
 * Created by moshemal on 8/31/14.
 */

define([], function () {
	const getScrollbarSize = () => {
		let scrollDiv = document.createElement('div');
		let scrollbarWidth = 0;
		let scrollbarHeight = 0;

		scrollDiv.style.visibility = 'hidden';
		scrollDiv.style.width = '100px';
		scrollDiv.style.height = '100px';
		scrollDiv.style.overflow = 'scroll';
		scrollDiv.style.position = 'absolute';
		scrollDiv.style.top = '-9999px';
		document.body.appendChild(scrollDiv);
		scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
		scrollbarHeight = scrollDiv.offsetHeight - scrollDiv.clientHeight;
		document.body.removeChild(scrollDiv);

		return {
			scrollbarWidth,
			scrollbarHeight
		};
	};

	let { scrollbarWidth, scrollbarHeight } = getScrollbarSize();


	return {
		setCookie: function (cname, cvalue, exdays) {
			var d = new Date();
			d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
			var expires = "expires=" + d.toUTCString();
			document.cookie = cname + "=" + cvalue + "; " + expires;
		},
		getCookie: function (cname) {
			var name = cname + "=";
			var ca = document.cookie.split(';');
			for (var i = 0; i < ca.length; i++) {
				var c = ca[i].trim();
				if (c.indexOf(name) === 0) {
					return c.substring(name.length, c.length);
				}
			}
			return "";
		},
		reload: function () {
			location.reload();
		},
		getLang: function () {
			return window.navigator.language || window.navigator.userLanguage;
		},
		isChrome: function () {
			return window.navigator.userAgent.indexOf('Chrome') !== -1;
		},
		isFirefox: function () {
			return window.navigator.userAgent.indexOf('Firefox') !== -1;
		},
		isOpera: function () {
			return window.navigator.userAgent.indexOf('Opera') !== -1;
		},
		isIE: function () {
			return window.navigator.userAgent.indexOf('MSIE') !== -1 || !!window.navigator.userAgent.match(/Trident.*rv\:11\./);
		},
		isSafari: function () {
			return window.navigator.userAgent.indexOf('Safari') !== -1;
		},
		getOS: function () {
			var OSName = "Unknown OS";
			if (window.navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";
			if (window.navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";
			if (window.navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
			if (window.navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";
			return OSName;
		},
		requestAnimationFrame: (function () {
			return window.requestAnimationFrame ||
				window.webkitRequestAnimationFrame ||
				window.mozRequestAnimationFrame ||
				function (callback) {
					window.setTimeout(callback, 1000 / 60);
				};
		})(),

		getScrollbarWidth: function () {
			return scrollbarWidth;
		},

		getScrollbarHeight: function () {
			return scrollbarHeight;
		}
	};
});