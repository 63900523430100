import React from 'react';

import AbstractStep from 'widgets/Wizard/AbstractStep';
import { Store, Form } from 'components/private/forms';
import { createRoot } from 'react-dom/client';

const isObject = o => typeof o === 'object';
const isFunction = o => typeof o === 'function';

export default (DecoratedComponent) => {

  const next = DecoratedComponent.next;
  const back = DecoratedComponent.back;
  const FormDecoratedComponent = Form(DecoratedComponent);

  return AbstractStep.extend({
    init: function (initObj) {
      this._super(initObj);

      this.back = this.back.bind(this);
      this.next = this.next.bind(this);
      this.render = this.render.bind(this);

      this.rootType = initObj.startParameters.rootType;
      this.rootId = initObj.startParameters.rootId;
      this.nwid = initObj.properties.properties.startParameters.nwid;
      this.wizard = initObj.wizard;
      this.initObj = initObj;
      this.formInstance = undefined;
      this.reactRoot = createRoot(this.container);
    },
    render: function () {
      const store = new Store(this.model);
      this.reactRoot.render(
        <FormDecoratedComponent store={store} path="" step={this} ref={formInstance => this.formInstance = formInstance} />
      );
    },
    back: function () {
      const { model, isValid } = this.formInstance.triggerApply(true);

      if (!isValid || !model) return false;

      if (isObject(model)) {
        Object.keys(model).forEach(function (key) {
          Ember.set(this.model, key, model[key]);
        }, this);
      }

      if (isFunction(DecoratedComponent.back)) return DecoratedComponent.back(model, this);

      this.reactRoot.unmount();

      return true;
    },
    next: function () {
      const { model, isValid } = this.formInstance.triggerApply(true);

      if (!isValid || !model) return false;

      if (isObject(model)) {
        Object.keys(model).forEach(function (key) {
          Ember.set(this.model, key, model[key]);
        }, this);
      }

      if (isFunction(DecoratedComponent.next)) return DecoratedComponent.next(model, this);

      this.reactRoot.unmount();

      return true;
    },
    destroy: function () {
    }
  });
};