import React from 'react';
import PropTypes from 'prop-types';

const isFunction = o => typeof o === 'function';

export default class PrintProvider extends React.Component {

    static childContextTypes = {
        onClick: PropTypes.func,
        register: PropTypes.func
    }

    static propTypes = {
        style: PropTypes.object,
        className: PropTypes.string
    }

    handlers = []

    runHandlers = () => {
        this.handlers.map(handler => {
            if (isFunction(handler)) {
                handler();
            }
        })
    }

    getChildContext() {
        return { onClick: this.runHandlers, register: (handler) => this.handlers.push(handler) }
    }

    render() {
        const { children } = this.props;
        return children;
    }
}