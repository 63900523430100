import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Checkbox extends Component {
  static propsTypes = {
    checked: PropTypes.bool,
    indeterminate: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    title: PropTypes.string,
    onChange: PropTypes.func
  }

  static defaultProps = {
    indeterminate: false,
    disabled: false,
    title: undefined,
  }

  handleChange = (event) => {
    const {onChange} = this.props;
    if (typeof onChange === 'function') {
      onChange(event, event.target.checked, this.props.indeterminate);
    }
  };

  componentDidMount() {
    if (this.props.checked && this.props.indeterminate) this.refs.checkbox.indeterminate = true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.checked === this.props.checked && prevProps.indeterminate === this.props.indeterminate) return;

    if (this.props.checked && this.props.indeterminate) this.refs.checkbox.indeterminate = true;
    else this.refs.checkbox.indeterminate = false;
  }

  render() {
    const { checked, indeterminate, disabled, style, title, ...props } = this.props;
    const className = `crtx-checkbox ${this.props.className} ${disabled ? 'disabled' : ''}`;

    return <input ref="checkbox"
                  type='checkbox'
                  className={className}
                  title={title}
                  style={style}
                  checked={checked}
                  disabled={disabled}
                  onChange={this.handleChange}
    />
  }
}

module.exports = Checkbox;
