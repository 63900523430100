define(['react', 'prop-types', 'react-dom'], function (React, PropTypes, ReactDOM) {
  'use strict'

  const { Component } = React;
  
  return class Icon extends Component {
    static propTypes = {
      id: PropTypes.string,
      icon: PropTypes.string.isRequired,
      title: PropTypes.string,
      iconClassName: PropTypes.string,
      text: PropTypes.string,
      style: PropTypes.object,
      disabled: PropTypes.bool,
      onClick: PropTypes.func
    }

    static defaultProps = {
      id: undefined,
      icon: null,
      title: '',
      disabled: false
    }

    constructor(props) {
      super(props);
      this.handleClick = this.handleClick.bind(this);
    }


    handleClick(e) {
      var { id, disabled, onClick } = this.props;
      if (disabled) return;
      if (typeof onClick == 'function') {
        onClick(id);
      }
      e.preventDefault();
      e.stopPropagation();
    }

    render() {
      const { icon, title, iconClassName, text, style, disabled, onClick } = this.props;
      const buttonStyle = {
        cursor: typeof onClick === 'function' ? 'pointer' : null,
        ...style
      };
      const disabledClassName = disabled === true ? 'disabled' : '';
      const className = ['icon', disabledClassName, iconClassName].join(' ');
      return <span className={className} style={buttonStyle} onClick={this.handleClick}>
        <img src={icon}
          title={title} />
        <span>{text}</span>
      </span>;
    }

  }
});
