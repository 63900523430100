/**
 * @name FlowStepView
 * @file FlowStepView module
 *
 * @author Boris
 * @since: 2019-11-07
 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import { translate } from 'core/services/localization';
import jsUtils from 'sandbox/jsUtils';
import iconService from 'core/services/iconService';
import prefernecesManager from 'core/managers/preferences';
import { parseBreadcrumbs } from 'utilities/breadcrumbs';
import AbstractModule from 'AbstractModule';
import TickableModel from '../TickableModel';
import FlowStep from './FlowStep';
import { EVENTS_ORDER, MAX_TRACE_LENGTH, ADJUSTED_PROGRESS_FACTOR } from './constants';
import { makeEventTableColumns, createMultiselectFilterOptions } from './columnsCreator';
import { hasCurrentDateCondition, getColumnFilter } from './utils';
import { createObjectComparator } from 'core/comparators';
import { fromServerDate, fromServerDateOnly, formatNewswayDateTime, MSEC_IN_MINUTE } from 'core/dates';
import { arrayToObject, moveItem } from 'utilities/array';
import {
  checkDateCondition,
  FILTER_TYPE,
  checkTextCondition,
  checkNumberCondition,
  reduceColumnsToFilterBy,
  FILTER_DATA_TYPE
} from 'widgets/ReactDataGrid/utils';

const TEST_EVENTS_COUNT = 0;
const THROTTLE_WAIT = 1000;
const EMPTY_EVENT = {
  progress: 0,
  empty: true
};
const EDIT_RESOURCE_ACTION_BY_TYPE = {
  'physical/image/inkc': 'EditResourceSetupActionCR',
  'physical/image/pdfprocessor': 'EditResourceSetupActionCR',
  'physical/input/hotfolder': 'EditHotFolderSetupActionCR',
  'physical/input/status': 'EditStatusStepSetupActionCR',
  'physical/image/rip': 'EditRipSetupActionCR',
  'physical/output/proout': 'EditProoutSetupActionCR',
  'physical/rip2ctp': 'EditRipOutSetupActionCR',
  'physical/comm/tx': 'EditTXSetupActionCR',
  'physical/image/tiffprocessor': 'EditTiffProcessoSetupActionCR',
  'physical/image/preflight': 'EditPreflightSetupActionCR',
  'physical/image/optimization': 'EditOptimizationSetupActionCR',
  'physical/comm/externalprotocol': 'EditExternalProtocolSetupActionCR',
  'physical/output/proofer': 'EditProoferSetupActionCR',
  'physical/output/cache': 'EditCacheSetupActionCR',
  'physical/flow/cmdline': 'EditCmdLineSetupActionCR',
  'physical/flow/fb': 'EditFBSetupActionCR',

  // demons
  'physical/image/composelowres': 'EditResourceSetupActionCR',
  'physical/image/hirestile': 'EditResourceSetupActionCR',
  'physical/image/pagelowres': 'EditResourceSetupActionCR',
  'physical/general/reporting': 'EditResourceSetupActionCR',
  'physical/image/seplowres': 'EditResourceSetupActionCR',
  'physical/output/mail': 'EditMailSetupActionCR'
};

const REMOVE_RESOURCE_ACTION_BY_TYPE = {
  "physical/input/hotfolder": "DeleteResourceActionCR",
  "physical/input/status": "DeleteResourceActionCR"
};

const STEPS_WITH_RESOURCE_PATH = [
  'workflow/step/output/proout',
  'workflow/step/output/cache',
  'workflow/step/image/preflight',
  'workflow/step/image/optimization',
  'workflow/step/flow/fb',
  'workflow/step/output/transmission',
  'workflow/step/output/ripdrive',
  'workflow/step/input',
  'workflow/step/input/hotfolder',
  'workflow/step/input/status',
  'workflow/step/input/proin',
  'workflow/step/input/import',
  'workflow/step/comm/externalprotocol',
  'workflow/step/flow/cmdline'
];

const STEPS_WITH_MEDIA_TYPE = [
  'workflow/step/output/proout',
  'workflow/step/output/ripdrive',
];

function createTestEvents(model) {
  if (TEST_EVENTS_COUNT <= 0) {
    return;
  }

  const t1 = performance.now();

  function createEventList(event, queue, count) {
    const events = [];

    event = jsUtils.cloneDeep(event);
    event.nwid = queue.queueType + '_';
    for (let i = 0; i < count; i++) {
      const nwid = queue.queueType + '_' + i;
      event.next = nwid;
      const prev = event.nwid;
      event = { ...jsUtils.cloneDeep(event), id: nwid, nwid, prev, next: '-1' };
      events.push(event);
    }

    queue.events = events;
  }

  const queFinished = model.queues.find(q => q.queueType === 'queFinished');
  let event = queFinished.events.find(e => e.prev === '-1');
  if (event) {
    createEventList(event, queFinished, TEST_EVENTS_COUNT);

    const queProcess = model.queues.find(q => q.queueType === 'queProcess');
    createEventList(event, queProcess, 5);
  }

  const t2 = performance.now();
  console.log('### FlowStepView.createTestEvents() time = ', t2 - t1, 'ms');
}

const cleanupProgress = (model) => {

  let finished = 0;

  model.resources.forEach(r => {
    if (!r.isProgressable) {
      return false;
    }

    r.events.forEach((e, index) => {
      if (e.finished) {
        r.events[index] = EMPTY_EVENT;
        finished++;
      }
    });

  });

  return finished > 0;
};

export default AbstractModule.extend({
  initDone: function () {
    this.reactRoot = createRoot(this.domElement);
    this.toolbar = this.createToolbar();

    this.updates = [];

    this.tickUpdateHandlerThrottled = jsUtils.throttle(this.tickUpdateHandler, THROTTLE_WAIT, {
      leading: false,
      trailing: true
    });
  },

  firstTickReceived: function (data) {
    this.preferences = data.preferences || {};
    this.guiSettings = data.GuiSettings || {};

    this.eventTableColumns = makeEventTableColumns(this.guiSettings, this.preferences);
    this.filtersEnabled = (this.preferences.table || {}).filtersEnabled || false;

    this.stepType = data.model.type;

    this.initToolbar(data.model);

    this.tickableModel = new TickableModel();

    createTestEvents(data.model);

    this.tickableModel.firstTickHandler(data.model);

    this.buildViewModel();

    this.startFilterAutoRefresh();
  },

  tickUpdate(data) {
    // console.log('### FlowStep.tickUpdate())');
    this.updates = this.updates.concat(data.model);
    this.tickUpdateHandlerThrottled();
  },

  tickUpdateHandler() {
    // console.log('### FlowStep.tickUpdateHandler()) => updates=', this.updates);

    this.updates.forEach(item => {
      switch (item.action) {
        case 'Add':
          this.handleAdd(item);
          break;
        case 'Update':
          this.handleUpdate(item);
          break;
        case 'Remove':
          this.handleRemove(item);
          break;
      }

      this.tickableModel.tickUpdateHandler([item]);
    });

    this.updates = [];
    this.buildViewModel();
  },

  handleAdd: function (item) {
    if (item.type === 'workflow/event') {
      const prevEvent = item.prev !== '-1' ? this.tickableModel.getByNwid(item.prev) : null;
      if (prevEvent) {
        prevEvent.next = item.nwid;
      }

      const nextEvent = item.next !== '-1' ? this.tickableModel.getByNwid(item.next) : null;
      if (nextEvent) {
        nextEvent.prev = item.nwid;
      }
    }
  },

  handleUpdate: function (item) {
    if (item.type.startsWith('workflow/step/') || item.type.startsWith('demons/demon/')) {
      if (item.enabled !== undefined) {
        this.toolbar.setItemChecked('ToggleDeviceActionCR', item.enabled);
      }
      if (item.isSkipOn !== undefined) {
        this.toolbar.setItemChecked('ToggleSkipDeviceActionCR', item.isSkipOn);
      }
    }
  },

  handleRemove: function (item) {
    (item.ids || []).forEach(id => {
      const event = this.tickableModel.get(id);
      if (event && event.type === 'workflow/event') {
        const prevEvent = event.prev !== '-1' ? this.tickableModel.getByNwid(event.prev) : null;
        if (prevEvent) {
          prevEvent.next = event.next;
        }

        const nextEvent = event.next !== '-1' ? this.tickableModel.getByNwid(event.next) : null;
        if (nextEvent) {
          nextEvent.prev = event.prev;
        }
      }
    });
  },

  buildViewModel: function () {
    //***TEST BEGIN
    // const t1 = performance.now();
    //***TEST END

    const model = this.tickableModel.model();
    this.parseDatesAndBreadcrumbs(model);

    this.viewModel = {};
    this.viewModel.events = [];
    this.viewModel.eventCountByQueueType = {};
    this.viewModel.queues = model.queues;
    this.viewModel.folderNwid = model.folderNwid;
    this.viewModel.queuesByType = arrayToObject(model.queues, 'queueType');

    this.updateResourceEvents(model.resources, this.viewModel.queuesByType['queProcess']);
    this.viewModel.resources = [...model.resources].sort(createObjectComparator('name'));
    this.viewModel.resourcesByNwid = arrayToObject(model.resources, 'nwid');

    EVENTS_ORDER.forEach(queueType => {
      const queue = this.viewModel.queuesByType[queueType] || {};
      const events = this.arrangeEvents(queue.events, queueType);
      this.viewModel.eventCountByQueueType[queueType] = events.length;
      this.viewModel.events = this.viewModel.events.concat(events);
    });

    this.viewModel.sortedEvents = [...this.viewModel.events];

    this.updateFiltersByKey();

    //***TEST BEGIN
    // const t2 = performance.now();
    // console.log(`### FlowStepView.buildViewModel() => type='${model.type}', ${this.viewModel.events.length} events`);
    // console.log('### FlowStepView.buildViewModel() time = ', t2 - t1, 'ms');
    //***TEST END

    this.filterEvents();

    setTimeout(() => {
      if (cleanupProgress(this.viewModel)) {
        this.render();
      }
    }, 500);
  },

  parseDatesAndBreadcrumbs: function (model) {
    const keys = ['Publication Date', 'EventTime', 'Breadcrumbs']
      .filter(key => this.eventTableColumns.some(col => col.key === key));

    for (let queue of model.queues) {
      for (let event of queue.events) {
        for (let key of keys) {
          let value = event[key];
          if (typeof value === 'string') {
            if (key === 'Breadcrumbs') {
              event[key] = parseBreadcrumbs(value);
            } else if (key === 'Publication Date') {
              event[key] = fromServerDateOnly(value);
            } else {
              event[key] = fromServerDate(value);
            }
          }
        }
      }
    }
  },

  updateFiltersByKey: function () {
    if (!this.filtersEnabled) {
      return;
    }

    this.eventTableColumns.forEach(column => {
      const columnFilter = getColumnFilter(column.key);
      const filter = {
        ...column.filter,
        ...columnFilter
      };

      if (filter.type === FILTER_TYPE.MULTISELECT) {
        // console.log('########## column key=>', column.key);
        filter.options = createMultiselectFilterOptions(this.viewModel.events, column, this.viewModel);
      }

      column.filter = filter;
    });
  },

  filterEvents: function () {
    if (!this.filtersEnabled) {
      this.viewModel.events = this.viewModel.sortedEvents;
    } else {
      //***TEST BEGIN
      // const t1 = performance.now();
      //***TEST END

      const columnsToFilterBy = reduceColumnsToFilterBy(this.eventTableColumns);

      this.viewModel.events = this.viewModel.sortedEvents.filter(event => {
        //console.log(`###event=> objectType=${event.objectType} colorName=${event.colorName}`);

        let match = true;
        for (const col of columnsToFilterBy) {
          const filter = col.filter;
          if (filter.type === FILTER_TYPE.MULTISELECT) {
            if (Array.isArray(filter.selected) && filter.selected.length > 0) {
              const filterValue = col.filterValueGetter(event);
              match = filter.selected.some(s => s === filterValue);
            }
          } else if (filter.type === FILTER_TYPE.DATE) {
            match = checkDateCondition(event[col.key], filter);
          } else if (filter.type === FILTER_TYPE.TEXT && filter.textValue) {
            if (filter.dataType === FILTER_DATA_TYPE.TEXT) {
              const text = typeof col.filterValueGetter === 'function' ? col.filterValueGetter(event) : event[col.key];
              match = checkTextCondition(text, filter);
            } else if (filter.dataType === FILTER_DATA_TYPE.NUMBER) {
              const number = typeof col.filterValueGetter === 'function' ? col.filterValueGetter(event) : event[col.key];
              match = checkNumberCondition(number, filter);
            }
          }

          if (!match) {
            break;
          }
        }

        return match;
      });

      //***TEST BEGIN
      // const t2 = performance.now();
      // console.log('### FlowStepView.filterEvents() time = ', t2 - t1, 'ms');
      //***TEST END
    }

    this.render();
  },

  startFilterAutoRefresh() {
    if (Date.now() - this.lastRenderTime >= MSEC_IN_MINUTE) {
      if (this.filtersEnabled && hasCurrentDateCondition(this.eventTableColumns)) {
        this.filterEvents();
      }
    }

    clearTimeout(this.filterTimeoutId);
    this.filterTimeoutId = setTimeout(() => {
      this.startFilterAutoRefresh();
    }, MSEC_IN_MINUTE);
  },

  updateResourceEvents: function (resources, queProcess) {
    resources.forEach(resource => {
      const { maxInProgressEvents, isProgressable } = resource;

      if (resource.events && resource.events.length !== maxInProgressEvents) {
        resource.events = [];
      }

      for (let i = 0; i < maxInProgressEvents; i++) {
        if (!resource.events) {
          resource.events = [];
        }
        if (!resource.events[i]) {
          resource.events[i] = EMPTY_EVENT;
        }
      }

      const inProcessEvents = queProcess.events.filter(e => e.resource === resource.nwid);

      resource.events.forEach((event, index) => {
        const foundInProcessEvents = inProcessEvents.find(e => e.id === event.id);

        if (foundInProcessEvents) {
          resource.events[index] = foundInProcessEvents;
        } else if (event.empty !== true) {

          const updated = isProgressable ? {
            ...resource.events[index],
            progress: 100,
            finished: true
          } : EMPTY_EVENT;
          resource.events[index] = updated;
        }
      });



      inProcessEvents.forEach((event) => {
        const indexInSlot = resource.events.findIndex(e => e.id === event.id);

        if (indexInSlot >= 0) {
          resource.events[indexInSlot] = event;
        } else {
          const indexEmptyInSlot = resource.events.findIndex(e => !e || e.empty);

          if (indexEmptyInSlot >= 0) {
            resource.events[indexEmptyInSlot] = event;
          }
        }
      });
    });
  },

  arrangeEvents: function (events, queueType) {
    if (!events) {
      return [];
    }

    const result = [];

    const reverse = queueType === 'queError' || queueType === 'queFinished';
    const prev = reverse ? 'next' : 'prev';
    let event = events.find(e => e[prev] === '-1');
    for (let i = 0; i < events.length && event; i++) {
      result.push(event);
      const next = reverse ? 'prev' : 'next';
      event = this.tickableModel.getByNwid(event[next]);
    }

    return result;
  },

  getFlowStepNwid: function () {
    return this.tickableModel.model().nwid;
  },

  isApprovalStep: function () {
    return this.stepType === 'workflow/step/flow/approval';
  },

  isPreflightStep: function () {
    return this.stepType === 'workflow/step/image/preflight';
  },

  isOptimizationStep: function () {
    return this.stepType === 'workflow/step/image/optimization';
  },

  isHotFolderStep: function () {
    return this.stepType === 'workflow/step/input/hotfolder';
  },

  isStatusStep: function () {
    return this.stepType === 'workflow/step/input/status';
  },

  isRemoteCacheStep: function () {
    return this.stepType === 'workflow/step/output/cache';
  },

  isSkippableStep: function () {
    return this.isApprovalStep() || this.isPreflightStep() || this.isOptimizationStep() || this.isRemoteCacheStep();
  },

  isPurgeDemon: function () {
    return this.stepType === 'demons/demon/purge';
  },

  isMailDemon: function () {
    return this.stepType === 'demons/demon/mail';
  },

  isTaskDemon: function () {
    return this.stepType === 'demons/demon/task';
  },

  initToolbar: function (model) {
    const actions = this.getRelevantActions(model);

    if (this.isApprovalStep() || this.isStatusStep()) {
      this.toolbar.removeItem('ToggleDeviceActionCR');
    } else {
      // Hack: always show Toggle Device Action indicator (red/green led) even if the action was not found
      const toggleDeviceAction = actions.find(a => a.actionDefinitionName === 'ToggleDeviceActionCR');
      this.toolbar.removeItem('ToggleDeviceActionCR');
      this.toolbar.addItem({
        itemType: 'toggle',
        name: 'ToggleDeviceActionCR',
        labelOn: translate('Stop Workflow Step'),
        labelOff: translate('Start Workflow Step'),
        iconOn: 'on',
        iconOff: 'off',
        unshift: true,
        _isApplicable: true,
        execute: checked => {
          if (toggleDeviceAction) {
            toggleDeviceAction.execute();
          }

          // wait for tick update to show the real device state (green or red led indicator)
          this.toolbar.setItemChecked('ToggleDeviceActionCR', !checked);
        }
      });

      this.toolbar.setItemChecked('ToggleDeviceActionCR', model.enabled);
    }

    if (this.isStatusStep()) {
      this.toolbar.removeItem('HighPrioritisedEventAction');
      this.toolbar.removeItem('RedoEventAction');
      this.toolbar.removeItem('HoldEvents');
      this.toolbar.removeItem('UnholdEvents');
    }

    if (!this.isSkippableStep()) {
      this.toolbar.removeItem('ToggleSkipDeviceActionCR');
    } else {
      this.toolbar.setItemChecked('ToggleSkipDeviceActionCR', model.isSkipOn);
    }

    let addResourceAction, assignResourcesAction;
    if (this.isHotFolderStep()) {
      addResourceAction = actions.find(a => a.actionDefinitionName === 'AddHotFolderSetupActionCR');
      if (addResourceAction) {
        addResourceAction.config = { folderName: model.folderName, folderNwid: model.folderNwid };
      }

    } else if (this.isStatusStep()) {
      addResourceAction = actions.find(a => a.actionDefinitionName === 'AddStatusStepSetupActionCR');
    } else {
      assignResourcesAction = actions.find(a => a.actionDefinitionName === 'AssignResourcesActionCR');
      if (assignResourcesAction) {
        assignResourcesAction.config = { folderName: model.folderName, folderNwid: model.folderNwid };
      }
    }

    if (addResourceAction) {
      this.toolbar.addItem({
        label: 'Add Resource',
        name: 'Add Resource',
        alignRight: true,
        _isApplicable: true,
        icon: 'add_resources',
        tooltip: translate('Add Resource'),
        execute: () => addResourceAction.execute()
      });
    } else if (assignResourcesAction) {
      this.toolbar.addItem({
        label: 'Assign Resources',
        name: 'Assign Resources',
        alignRight: true,
        _isApplicable: true,
        icon: 'assign_resources',
        tooltip: translate('Assign Resources'),
        execute: () => assignResourcesAction.execute()
      });
    }

    this.toolbar.addItem({
      label: translate('Toggle Filters'),
      name: 'toggleFilters',
      _isApplicable: true,
      icon: 'filter_list.svg',
      itemType: 'push',
      checked: this.filtersEnabled,
      execute: this.toggleFilters.bind(this)
    });
  },

  toggleFilters: function (pushed) {
    //console.log('########toggleFilters() pushed=', pushed);
    this.filtersEnabled = pushed;

    this.toolbar.setItemChecked('toggleFilters', pushed);

    this.buildViewModel();

    this.saveColumnPreferences();
  },

  savePreferences: function (preferences) {
    if (!preferences) {
      return;
    }

    this.preferences = Object.assign(this.preferences, preferences);
    prefernecesManager.savePreferences(this.getRequiredParameters(), this.preferences);
  },

  saveColumnPreferences: function () {
    this.savePreferences({
      table: {
        ...this.preferences.table,
        filtersEnabled: this.filtersEnabled,
        columns: this.eventTableColumns.map(col => {
          let filter = col.filter;
          if (filter && filter.option === 'between') {
            filter = {
              ...filter,
              dateFrom: formatNewswayDateTime(filter.dateFrom),
              dateTo: formatNewswayDateTime(filter.dateTo)
            };
          } else if (filter && filter.selected) {
            filter = { selected: filter.selected };
          }
          return {
            id: col.id,
            isVisible: col.visible,
            width: col.width,
            filter
          };
        })
      }
    });
  },

  handleEventTableColumnsFilter: function (columns) {
    this.eventTableColumns.forEach(col => {
      if (columns[col.key]) {
        col.visible = columns[col.key].visible;
      }
    });

    this.saveColumnPreferences();
  },

  handleEventTableColumnOrder: function (columns, oldIndex, newIndex) {
    moveItem(this.eventTableColumns, oldIndex, newIndex);

    this.saveColumnPreferences();

    this.render();
  },


  handleEventTableSelect: function (selectedRows) {
    const events = selectedRows.map(row => row && this.tickableModel.getByNwid(row.nwid));
    this.updateSelected(events.filter(e => !!e));
  },

  handleEventTableContextMenu: function (clickedRow, selectedRows, e) {
    this.showContextMenu(this.tickableModel.getByNwid(clickedRow.nwid), this.selected, e);
  },

  handleEventTableColumnResize: function (columns) {
    this.eventTableColumns.forEach(col => {
      if (columns[col.key]) {
        col.width = columns[col.key].width;
      }
    });

    this.saveColumnPreferences();
  },

  handleEventTableDeleteKey: function (selectedRows, e) {
    const actions = this.getRelevantActions(this.selected[0]);
    const deleteAction = actions.find(a => a.actionDefinitionName === 'DeleteEventsCR');
    if (deleteAction) {
      deleteAction.execute(this.selected);
      this.updateSelected([]);
    }
  },

  handleResourceTableContextMenu: function (resource, selected, e) {
    this.showContextMenu(resource, selected, e);
  },

  handleColumnFilterChange: function (column, columnFilter) {
    //console.log('### handleColumnFilterChange()', columnKey, columnFilter);

    if (!column || !column.filter || !column.filter.type) {
      return;
    }

    column.filter = {
      ...column.filter,
      ...columnFilter
    };

    this.filterEvents();

    this.saveColumnPreferences();
  },

  findResourceAction: function (resource, actionDefinitionName) {
    const actions = this.getRelevantActions(resource);
    return actions.find(a => a.actionDefinitionName === actionDefinitionName);
  },

  toggleResource: function (resource) {
    const toggleAction = this.findResourceAction(resource, 'ToggleResourceActionCR');
    if (toggleAction) {
      toggleAction.execute([resource]);
    }
  },

  abortResource: function (resource) {
    const abortAction = this.findResourceAction(resource, 'AbortResourcesActionCR');
    if (abortAction) {
      abortAction.execute([resource]);
    }
  },

  hasResourcePathColumn: function () {
    return STEPS_WITH_RESOURCE_PATH.indexOf(this.stepType) >= 0;
  },

  hasMediaTypeColumn: function () {
    return STEPS_WITH_MEDIA_TYPE.indexOf(this.stepType) >= 0;
  },

  getResourceLedIcon: function (resource) {
    let icon;

    if (resource.humanStateDescription === 'Up') {
      icon = {
        url: iconService.getGeneralIcon('resource', 'led_on'),
        title: translate('Alive')
      };
    } else if (resource.humanStateDescription === 'Down') {
      icon = {
        url: iconService.getGeneralIcon('resource', 'led_off'),
        title: translate('Dead')
      };
    } else {
      icon = {
        url: iconService.getGeneralIcon('resource', 'led_unknown'),
        title: translate('Unknown')
      };
    }

    return icon;
  },

  canEditResource: function (resource) {
    return !!this.getEditResourceAction(resource);
  },

  getEditResourceAction: function (resource) {
    const actions = this.getRelevantActions(resource);

    return actions.find(a => a.actionDefinitionName === EDIT_RESOURCE_ACTION_BY_TYPE[resource.type]);
  },

  editResource: function (resource) {
    const editAction = this.getEditResourceAction(resource);
    if (editAction) {
      editAction.execute([resource]);
    }
  },

  hasRemoveResourceColumn: function () {
    return this.isHotFolderStep() || this.isStatusStep();
  },

  canRemoveResource: function (resource) {
    return !!this.getRemoveResourceAction(resource);
  },

  getRemoveResourceAction: function (resource) {
    return this.viewActions.find(a => a.actionDefinitionName === REMOVE_RESOURCE_ACTION_BY_TYPE[resource.type]);
  },

  removeResource: function (resource) {
    const removeAction = this.getRemoveResourceAction(resource);
    if (removeAction) {
      removeAction.execute([resource]);
    }
  },

  destroy: function () {
    this._super();
    this.reactRoot.unmount();
  },

  render: function () {
    this.lastRenderTime = Date.now();
    // const t1 = performance.now();

    this.reactRoot.render(
      <FlowStep
        module={this}
        viewModel={this.viewModel}
        eventTableColumns={this.eventTableColumns}
      />);

    // const t2 = performance.now();
    // console.log('### FlowStepView.render() time = ', t2 - t1, 'ms');
  }

});
