/**
 * Created by moshemal on 9/11/14.
 */

define(['ember', '../helpers/EventsMixin', 'ember_table'], function (Ember, EventsMixin) {

  var TableComponent = Ember.Table.EmberTableComponent.extend(EventsMixin, {
    _tableColumnsWidth: "100%",
    _sumOfColumnsWidths: function () {
      var tableColumns = this.get('tableColumns');
      if (tableColumns) {
        return tableColumns.reduce(function (prev, curr) {
          return prev + parseInt(curr.get('columnWidth'))
        }, 0);
      }
    }.property('tableColumns.length'),
    init: function () {
      this._super();
      var tableColumns = this.get('columns');
      var columns = tableColumns.filter(function (col) {
        return col.isVisible !== false;
      });
      var totalWidth = columns.reduce(function (total, col) {
        return total + col.get('columnWidth');
      }, 0);
      var total = 0;
      for (var i = 0; i < columns.length; i++) {
        var width = 0;
        if (i < columns.length - 1) {
          width = Math.round(columns[i].get('columnWidth') / totalWidth * 100);
          total = total + width;
        } else {
          width = 100 - total;
        }
        columns[i].set('columnWidth', width + "%");
      }
    }
  });

  return TableComponent;
});