import { startInternalModule } from 'core/managers/module';
import { translate } from 'core/services/localization';
import AbstractAction from 'AbstractAction';
import request from 'sandbox/request';
import { fromServerDate, toServerDate } from 'core/dates';
import { formatBreadcrumbs } from 'utilities/breadcrumbs';

const ACTION_NAME = 'ChangeDeadlineAction';
const isUndefined = o => typeof o === 'undefined';

export default AbstractAction.extend({
  execute: function (objs) {
    let obj = Array.isArray(objs) ? objs[0] : objs;
    const formattedBreadcrumbs = formatBreadcrumbs(obj.breadcrumbs);
    const moduleName = 'ChangeDeadlineSetup';
    const name = translate('Change Deadline');
    const target = 'dialog';
    const windowWidth = 500;
    const windowHeight = 235;
    const win = this.module.win;
    const params = {
      dateTimeValue: obj.deadline ? fromServerDate(obj.deadline) : undefined,
      breadcrumbs: formattedBreadcrumbs
    };
    const config = this.config || {};

    const holdStepsNwids = config.holdStepsNwids || [];

    params.structureHoldUntil = obj.structureHoldUntil ? fromServerDate(obj.structureHoldUntil) : undefined;

    params.enableReleaseOffset = !!obj.releaseOffset && obj.releaseOffset !== '0';
    params.releaseOffset = obj.releaseOffset;

    startInternalModule(moduleName, { name, win, target, windowWidth, windowHeight, params }, this)
      .then(moduleInstance => {
        moduleInstance.onApply((action, state) => {
          const actionInstanceNwId = this.nwid;
          const nwid = this.module.nwid;
          const type = this.module.type;
          const projectorId = this.module.projectorId;
          const scriptName = this.parameters.scriptName;
          const paramsToSave = {
            nwid: obj.nwid,
            type: obj.type,
            deadline: toServerDate(state.dateTimeValue),
            holdStepsNwids

          };

          if (!isUndefined(state.structureHoldUntil)) {
            paramsToSave.structureHoldUntil = toServerDate(state.structureHoldUntil);
          } else {
            if (state.enableReleaseOffset) {
              paramsToSave.releaseOffset = state.releaseOffset + '';
            } else {
              paramsToSave.releaseOffset = '0';
            }
          }

          request.execServerAction(actionInstanceNwId, ACTION_NAME, nwid, type, projectorId, scriptName, paramsToSave);
        });
      });
  }
});