import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';
import iconService from 'sandbox/icons';
import CollapsiblePanelContainer from './containers/collapsiblePanelContainer';
import Pages from './pages';
import ProgressBar from 'components/common/progress/ProgressBar';
import IndicationCounter from './indicationCounter';

export default class Sections extends Component {
  static contextTypes = {
    module: PropTypes.object
  };

  static defaultProps = {
    model: {},
    pageDefinition: undefined,
    alignmentPoints: undefined,
    selectedItems: {}
  };

  state = {
    selectedItems: {}
  };

  getSectionStyle = (controller) => {
    var display = controller.filterZoneViewType === undefined || controller.filterZoneViewType === controller.zoneViewTypes.sections ?
      '' : 'none';

    return {
      display: display
    };
  };

  sections = () => {
    var sections = [],
      props = this.props,
      model = typeof props.model === 'undefined' ? 'undefined' : props.model,
      type = typeof model === 'undefined' || typeof model.type !== 'string' ?
        undefined :
        model.type.toLowerCase();

    if (typeof model === 'undefined') {
      return sections;
    }
    if (typeof type === 'undefined') {
      return sections;
    }

    if (type === 'zone') {
      sections = model.sections || [];
    } else if (type === 'section') {
      sections.push(model);
    } else {
      sections = model;
    }
    return sections;
  };

  pages = (section) => {
    var pages = typeof section === 'undefined' ? undefined : section.pages;

    if (!Array.isArray(pages)) {
      return [];
    }

    return pages.filter(function (page) {
      return page.virtual === false;
    });
  };

  pageDefinition = () => {
    return this.props.pageDefinition;
  };

  alignmentPoints = () => {
    return this.props.alignmentPoints;
  };

  renderProgressBar = (controller, pages) => {
    const pageDefinition = this.pageDefinition();
    const generalProperties = pageDefinition.generalProperties || {};
    const progressCount = controller.getProgressCount(pages);

    if (generalProperties.showProgressBar === false) {
      return undefined;
    }
    return <ProgressBar
      min={0}
      max={progressCount.total}
      value={progressCount.completed}
      animated={true}>
      {progressCount.completed} / {progressCount.total}
    </ProgressBar>;
  };

  render() {
    var component = this,
      controller = this.context.module.ctrl,
      sectionStyle = this.getSectionStyle(controller),
      sections = this.sections().map(function (section) {
        var pages = component.pages(section),
          progressCount = controller.getProgressCount(pages),
          title = (<div className="Section-Title">
            <div className="Section-Title-Content" title={section.name}>
              {section.name} | {sandbox.localization.translate('Pages')}
            </div>
            <div className="Section-Title-Slider">
              {component.renderProgressBar(controller, pages)}
            </div>
            <div className="Section-Title-Icons">
              <IndicationCounter icon="reject" count={progressCount.rejected} />
              <IndicationCounter icon="error" count={progressCount.error} />
              <IndicationCounter icon="waitingForApproval" count={progressCount.waitingForApproval} />
              <IndicationCounter module="Thumbnail" icon="holdCounter" count={progressCount.onHold} />
            </div>
          </div>);

        return <div key={section.id}>
          <div className="Section" tabIndex="0" style={sectionStyle}>
            <CollapsiblePanelContainer title={title} handlerIcon={iconService.getSystemIcon('arrow')}>
              <Pages sectionId={section.id}
                pages={pages}
                pageDefinition={component.pageDefinition()}
                alignmentPoints={component.alignmentPoints()}
                selectedItems={component.state.selectedItems}>
              </Pages>
            </CollapsiblePanelContainer>
          </div>
        </div>;
      });

    return <div className="Sections">
      {sections}
    </div>;
  }
}

Sections.propTypes = {
  model: PropTypes.object,
  pageDefinition: PropTypes.object,
  alignmentPoints: PropTypes.object,
};