import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Calendar, { registerLocale } from 'react-datepicker';
import { parseDate, formatDate, getDateLocale } from 'core/dates';
import localization, { translate } from 'core/services/localization';
import { classNames } from 'utilities/classNames';
import { Popover, PopoverContent, PopoverTrigger } from 'components/common/floating';
import TextInput from '../inputs/TextInput';

const labels = {
  timeCaption: translate('Time')
};

function getDateFormatOrDefault(dateFormat, showTimeSelect, showTimeSelectOnly) {
  if (dateFormat) {
    return dateFormat;
  }

  let currentDateFormat;
  if (showTimeSelect) {
    currentDateFormat = showTimeSelectOnly
      ? localization.getLocaleTimeFormat()
      : localization.getLocaleDateFormat() + ' ' + localization.getLocaleTimeFormat();
  } else {
    currentDateFormat = localization.getLocaleDateFormat();
  }

  return currentDateFormat;
}

const isDateValid = date => date && date instanceof Date && !isNaN(date.getTime());

function DatePicker(
  {
    value,
    className,
    style,
    disabled = false,
    placeholder,
    title,
    inline = false,
    onChange = () => {
    },
    dateFormat,
    shouldCloseOnSelect = true,
    minDate,
    maxDate,
    excludeDates,
    includeDates,
    filterDate,
    highlightDates,
    languageCode,
    openToDate,
    displayTimeCaption = true,
    showTimeSelect,
    showTimeSelectOnly,
    timeCaption = labels.timeCaption,
    timeFormat = localization.getLocaleTimeFormat(),
    timeIntervals,
    minTime,
    maxTime,
    injectTimes,
    includeTimes,
    excludeTimes,
    onBlur,
    onKeyDown
  }) {

  const [visible, setVisible] = useState(false);
  const [localeCode, setLocaleCode] = useState(() => localization.getDateLocaleCode(languageCode));
  const [locale, setLocale] = useState(() => getDateLocale(localeCode));
  const [strValue, setStrValue] = useState(() => inline ? '' : formatDate(value, getDateFormatOrDefault(dateFormat, showTimeSelect, showTimeSelectOnly), localeCode));
  const [invalid, setInvalid] = useState(false);
  const pendingClose = useRef(false);
  const inputRef = useRef(null);
  const datePickerRef = useRef(null);

  useEffect(() => {
    registerLocale(localeCode, locale);
  }, []);

  useEffect(() => {
    if (inline) {
      return;
    }

    const currentDateFormat = getDateFormatOrDefault(dateFormat, showTimeSelect, showTimeSelectOnly);

    const strValue = formatDate(value, currentDateFormat, localeCode);
    setStrValue(strValue);
    setInvalid(false);
  }, [value, dateFormat, showTimeSelect, showTimeSelectOnly]);

  const togglePopover = () => {
    if (!disabled) {
      const newVisible = !visible;
      setVisible(newVisible);
    }
  };

  const closePopover = () => {
    pendingClose.current = false;
    if (!inline) {
      setVisible(false);
    }
  };

  const closePopoverAfterSelect = () => {
    setTimeout(() => {
      if (pendingClose.current && shouldCloseOnSelect) {
        closePopover();
      }
    }, 0);
  };

  const handleChange = (e, draftValue) => {
    const currentDateFormat = getDateFormatOrDefault(dateFormat, showTimeSelect, showTimeSelectOnly);
    const value = parseDate(draftValue, currentDateFormat, localeCode);
    const invalid = !isDateValid(value);
    setStrValue(draftValue);
    setInvalid(invalid);
  };

  const handleBlur = e => {
    const draftValue = e.target.value;
    let strValue = '';
    if (draftValue) {
      const currentDateFormat = getDateFormatOrDefault(dateFormat, showTimeSelect, showTimeSelectOnly);
      const value = parseDate(draftValue, currentDateFormat, localeCode);
      if (isDateValid(value)) {
        strValue = formatDate(value, currentDateFormat, localeCode);
        setStrValue(strValue);
        setInvalid(false);
        if (typeof onChange === 'function') {
          onChange(e, value, strValue);
        }
        if (typeof onBlur === 'function') {
          onBlur(e, value, strValue);
        }
      } else {
        strValue = draftValue;
        setStrValue(strValue);
        setInvalid(true);
      }
    }
  };

  /**
   * This callback function is called by react-datepicker after handleDateTimeChange()
   * when day is selected
   * @param date
   */

  const handleDaySelect = date => {
    if (showTimeSelect && !showTimeSelectOnly) {
      pendingClose.current = false;
    }
  };

  const handleKeyDown = e => {
    if (e.code === 'Enter') {
      handleBlur(e);
    }

    if (typeof onKeyDown === 'function') {
      onKeyDown(e);
    }
  };

  /**
   * This callback function is called by react-datepicker every time a day or time is changed
   * @param date - the date object
   */
  const handleDateTimeChange = date => {
    pendingClose.current = true;
    onChange(undefined, date);
    closePopoverAfterSelect();
    focus();
  };

  const focus = () => {
    if (inputRef) {
      inputRef.current.focus();
    }
  };

  const getDatePicker = () => {
    const selectedValue = inline
      ? value
      : parseDate(strValue, getDateFormatOrDefault(dateFormat, showTimeSelect, showTimeSelectOnly), localeCode);

    const className = classNames('crtx-react-datepicker', { 'time-caption-hidden': !displayTimeCaption });

    return (
      <Calendar
        calendarClassName={className}
        inline
        selected={isDateValid(selectedValue) ? selectedValue : value}
        onSelect={handleDaySelect}
        onChange={handleDateTimeChange}
        locale={locale}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        excludeDates={excludeDates}
        includeDates={includeDates}
        filterDate={filterDate}
        highlightDates={highlightDates}
        openToDate={openToDate}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        timeCaption={timeCaption}
        timeFormat={timeFormat}
        timeIntervals={timeIntervals}
        minTime={minTime}
        maxTime={maxTime}
        injectTimes={injectTimes}
        includeTimes={includeTimes}
        excludeTimes={excludeTimes}
        shouldCloseOnSelect={false}
        disableOnClickOutside={true}
      />
    );
  };

  if (inline) {
    return getDatePicker();
  }

  const datePickerClassName = classNames({
    time: showTimeSelect && showTimeSelectOnly,
    'date-time': showTimeSelect && !showTimeSelectOnly,
    date: !showTimeSelect && !showTimeSelectOnly
  });
  const datePickerComponentClassName = classNames('crtx-datepicker', className, { disabled }, datePickerClassName);
  const htmlTitle = title || strValue ? title : getDateFormatOrDefault(dateFormat, showTimeSelect, showTimeSelectOnly);
  const iconName = showTimeSelect && showTimeSelectOnly ? 'access_time' : 'date_range';

  return (
    <Popover
      open={visible}
      placement='bottom-start'
      onOpenChange={closePopover}
    >
      <PopoverTrigger>
        <div ref={datePickerRef} className={datePickerComponentClassName} style={style}>
          <div className='crtx-datepicker-content'>
            <TextInput
              ref={inputRef}
              className='crtx-datepicker-input'
              disabled={disabled}
              placeholder={placeholder}
              title={htmlTitle}
              value={strValue}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
            />
          </div>
          {invalid ? <i className='material-icons warning'>warning</i> : null}
          <div className='crtx-datepicker-handler' onClick={togglePopover}>
            <i className='material-icons'>{iconName}</i>
          </div>
        </div>
      </PopoverTrigger>

      <PopoverContent>
        {visible && getDatePicker()}
      </PopoverContent>
    </Popover>
  );


}

DatePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  className: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  title: PropTypes.string, // tooltip
  inline: PropTypes.bool,
  onChange: PropTypes.func,
  dateFormat: PropTypes.string,
  shouldCloseOnSelect: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  excludeDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  includeDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  filterDate: PropTypes.func,
  highlightDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  languageCode: PropTypes.string,
  openToDate: PropTypes.instanceOf(Date),
  displayTimeCaption: PropTypes.bool,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,

  // --- time related properties ---
  showTimeSelect: PropTypes.bool,
  showTimeSelectOnly: PropTypes.bool,
  timeCaption: PropTypes.string,
  timeFormat: PropTypes.string,
  timeIntervals: PropTypes.number,
  minTime: PropTypes.instanceOf(Date),
  maxTime: PropTypes.instanceOf(Date),
  injectTimes: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  includeTimes: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  excludeTimes: PropTypes.arrayOf(PropTypes.instanceOf(Date))
};

export default DatePicker;
