import { translate } from 'core/services/localization';

export const ROLE_OPTIONS = [
  { value: '', text: '-- Select Role --' },
  { value: 'general', text: translate('General') },
  { value: 'odd', text: translate('Odd') },
  { value: 'even', text: translate('Even') },
  { value: 'panorama', text: translate('Panorama') },
  { value: 'first', text: translate('First') },
  { value: 'last', text: translate('Last') },
  { value: 'custom', text: translate('Custom') }
];

const rolesOrder = ROLE_OPTIONS.reduce((acc, role, index) => {
  acc[role.value] = index;

  return acc;
}, {});

export function createRoleComparator(a, b) {
  const i1 = rolesOrder[a.role];
  const i2 = rolesOrder[b.role];

  return i1 < i2 ? -1 : i1 > i2 ? 1 : 0;
}

export const CIP3_MODEL = 'cip3';
const ZONES_MODEL = 'zones';
const GRAYSCALE_MODEL = 'grayscale';
const ONE_BIT_TIFF_MODEL = 'onebittiff';
const CMYK_TIFF_MODEL = 'cmyktiff';

export const ROTATION_OPTIONS = [
  { value: -1, text: translate('None') },
  { value: 0, text: '0\u00B0' },
  { value: 90, text: '90\u00B0' },
  { value: 180, text: '180\u00B0' },
  { value: 270, text: '270\u00B0' }
];

export const INK_MODELS = [
  { value: CIP3_MODEL, text: translate('CIP3') },
  { value: ZONES_MODEL, text: translate('Ink Zones') },
  { value: GRAYSCALE_MODEL, text: translate('GrayScale') },
  { value: ONE_BIT_TIFF_MODEL, text: translate('1 Bit TIFF') },
  { value: CMYK_TIFF_MODEL, text: translate('CMYK TIFF') },
];

export const OUTPUT_MODES = [
  { value: 'filePerSeparation', text: translate('File per separation') },
  { value: 'fileWithAllSeparations', text: translate('File with all separations') }
];


export const IMPOSITION_TYPES = [
  { value: '', text: translate('-- Select Imposition Type --') },
  { value: 'broadsheet', text: translate('Broadsheet') },
  { value: 'tabs', text: translate('Tabs') },
  { value: '2ups', text: translate('2UPs') },
  { value: '4ups', text: translate('4UPs') },
  { value: '8ups', text: translate('8UPs') },
  { value: 'custom', text: translate('Custom') }
];

export const ORIENTATION = [
  { value: 'horizontal', text: translate('Horizontal') },
  { value: 'vertical', text: translate('Vertical') },
];