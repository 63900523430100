import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AbstractStep from 'widgets/Wizard/AbstractStep';
import { extendObservable } from "mobx";
import FormStep from 'widgets/Wizard/FormStep';
import Dropdown from 'components/common/dropdown/Dropdown';
import Textarea from 'components/common/inputs/TextareaInput';
import sandbox from 'sandbox';
import settingsManager from 'core/managers/settings';
import { restGet, restPost } from 'core/managers/rest2';

const translate = sandbox.localization.translate;
const numPagesOptions = [];
const preOrders = {};

class SanomaPreOrderSelection extends Component {
  static onDataReceived = (model) => {
    for (var i = 1; i <= 48; i++) {
      numPagesOptions.push((i * 2));
    }
    return {
      ...model,
      preOrders: {},
      preorder: model.preorder || '',
      prodCodeNames: [],
      publicationDate: model.publicationDate || '',
      productName: model.productName || '',
      comment: model.comment || '',
      sections: model.sections || [],
      format: model.format || '',
      planTemplateName: model.planTemplateName || '',
      foundTemplates: model.foundTemplates || []
    };
  };

  static onApply = (model) => {
    return {
      ...model,
      comment: encodeURIComponent(model.comment)
    };
  };

  getSectionNumberOfPages = (section) => {
    let existSections = this.props.model.sections.filter(s => section.name == s.name);
    if (existSections.length > 0) {
      return existSections[0].numPages;
    }
    return 4;
  };

  handleRestResault = (response) => {
    let prods = response["preOrders"];
    if (typeof prods !== "undefined") {
      /////// this is to support next/previous buttons
      let savedRespose = {};
      savedRespose["preOrders"] = prods.map(r => r);
      this.props.model.preOrderOptions = savedRespose;
      /////////////////////////////////////////////////
      prods.forEach(function (p) {
        preOrders[p["product-info"]["code"]] = p;
        //if (this.props.model.sections.length > 0) {
        p.sections.forEach(s => s.numPages = this.getSectionNumberOfPages(s));
        //}
      }.bind(this));
    }
    extendObservable(this.props.model.preOrders, preOrders);
    this.props.model.prodCodeNames.push(...(Object.keys(preOrders)));
    if (this.props.model.preorder !== '') {
      this.handleProductCodeSelection({}, this.props.model.preorder);

    }
  };

  handleProductCodeSelection = (event, newValue) => {
    this.props.model.preorder = newValue;
    let info = this.props.model.preOrders[newValue];
    this.props.model.productName = info["product-info"]['fullName'];
    this.props.model.publicationDate = info["product-info"]['publicationDate'];
    this.props.model.baseZoneCode = info["product-info"]['baseZone'];
    this.props.model.sections = info["sections"];
    this.props.model.sites = info["sites"];
    this.props.model.preorderPath = info["path"];
  };

  handleNumberOfPagesSelection = (section) => (event, newValue) => {
    if (typeof newValue === "undefined") { return; } //the preorder selection has changed
    section.numPages = newValue;
    let selected = this.props.model.preOrders[this.props.model.preorder]
    if (typeof selected !== "undefined") {
      let numPages = this.props.model.sections.map(function (s) {
        return {
          [s.name]: s.numPages
        }
      });
      selected["numPages"] = numPages;
      selected["folderNwId"] = this.props.step.rootId;
      let url = "planning-wizard/custom/sanoma/templates/match";
      let model = {
        data: JSON.stringify(selected)
      }
      return restPost(this.props.step.initObj.startParameters.nwid, url, model)
        .then(this.handleTemplateRestResault.bind(this));
    }
  };

  handleTemplateRestResault = (result) => {
    const foundTemplates = Object.keys(result).filter(k => k !== 'ready').map(k => { return { key: k, value: result[k] }; });
    this.props.model.foundTemplates.splice(0, this.props.model.foundTemplates.length, ...foundTemplates);
    if (result.ready) {
      this.wizard.enableButton("next");
    } else {
      this.wizard.disableButton("next");
    }
  };

  componentDidMount() {
    this.wizard = this.props.step.initObj.wizard;
    this.stepsProperties = this.props.step.properties.properties;
    let customer = settingsManager.get('user').name;
    let folderId = this.props.step.rootId;
    if (Object.keys(this.props.model.preOrders).length > 0) {
      this.handleRestResault(this.props.model.preOrderOptions);
      return;
    }
    this.wizard.disableButton("next");
    this.wizard.disableButton("cancel");
    let url = "planning-wizard/custom/sanoma/pre-orders?customer=" + customer + "&folderNwId=" + folderId + "&allowPastDate=" + this.stepsProperties.allowPastDate;

    return restGet(this.props.step.initObj.startParameters.nwid, url)
      .then(this.handleRestResault.bind(this));
  }

  renderSection = (section) => {
    return (
      <div className="pw-row">
        <div className='container block' style={{ display: 'inline-block', minWidth: '320px' }}>
          <div className="pw-row">
            <div className="column"><label style={{ minWidth: '50px' }}>{translate('Name:')}</label></div>
            <div className="column">{section.name}</div>
          </div>
          <div className="pw-row">
            <div className="column"><label style={{ minWidth: '50px' }}>{translate('Format:')}</label></div>
            <div className="column">{section.format}</div>
          </div>
          <div className="pw-row">
            <div className="column"><label style={{ minWidth: '50px' }}>{translate('Number Of Pages:')}</label></div>
            <div className="column"><Dropdown style={{ width: '100px', verticalAlign: 'middle' }}
              onSelect={this.handleNumberOfPagesSelection(section)}
              options={numPagesOptions} value={section.numPages} /></div>
          </div>
        </div>
        <hr />
      </div>
    )
  };

  renderFoundTemplates = (foundTemplate) => {
    return <div className="pw-row">
      <div className="column" style={{ verticalAlign: 'top' }}></div>
      <div className="column">
        <div className="pw-row">
          <div className="column"><label style={{ minWidth: '50px' }}>{foundTemplate.key}:</label></div>
          <div className="column">{foundTemplate.value}</div>
        </div>
      </div>
    </div>;
  };

  render() {
    const { model } = this.props;
    const preorder = model.preorder;
    const prodCodeNames = model.prodCodeNames;
    const productName = model.productName;
    const publicationDate = model.publicationDate;
    const comment = model.comment;
    const planTemplateName = model.planTemplateName;

    if (typeof model.preOrders === "undefined") {
      return (<div></div>);
    }
    return (
      <div className='container block' style={{ display: 'inline-block', minWidth: '320px' }}>
        <div className="pw-row">
          <div className="column"><label style={{ minWidth: '50px' }}>{translate('Product Code:')}</label></div>
          <div className="column"><Dropdown style={{ width: '200px', verticalAlign: 'middle' }}
            onSelect={this.handleProductCodeSelection}
            options={prodCodeNames} value={preorder} /></div>
        </div>
        <div className="pw-row">
          <div className="column"><label style={{ minWidth: '50px' }}>{translate('Product Name:')}</label></div>
          <div className="column">{productName}</div>
        </div>
        <div className="pw-row">
          <div className="column"><label style={{ minWidth: '50px' }}>{translate('Issue Date:')}</label></div>
          <div className="column">{publicationDate}</div>
        </div>
        <div className="pw-row">
          <div className="column" style={{ verticalAlign: 'top' }}><label style={{ minWidth: '50px' }}>{translate('Comment:')}</label></div>
          <div className="column"><Textarea value={comment}
            onChange={(event, value) => { model.comment = value; }} /></div>
        </div>
        <div className="pw-row">
          <div className="column" style={{ verticalAlign: 'top' }}><label style={{ minWidth: '50px' }}>{translate('Sections:')}</label></div>
        </div>
        <div className="pw-row">
          <div className="column"></div>
          {model.sections.map(this.renderSection)}
        </div>
        <div className="pw-row">
          <div className="column" style={{ verticalAlign: 'top' }}><label style={{ minWidth: '50px' }}>{translate('Found Templates:')}</label></div>
          <div className="column"></div>
        </div>
        {model.foundTemplates.map(this.renderFoundTemplates)}

      </div>


    );
  }
}

export default FormStep(SanomaPreOrderSelection);