/**
 * @name SimpleSetup
 * @fileOverview Simple Setup contains the default behavior of the setup.
 * @author Guy Behar
 */

define(['class', 'sandbox', './simpleSetupUtils'], function (Class, sandbox, utils) {
  'use strict';

  return Class.extend({

    _actions: {},

    _buttons: [],

    applyButton: false,

    init: function (submitCallback = () => {}, closeCallback = () => {}, callbackContext) {
      this._actions.close = function () {
        utils.close(this, closeCallback, callbackContext);
      };
      this._actions.ok = function () {
        utils.apply(this, true, submitCallback, callbackContext);
      };
      this._actions.apply = function () {
        utils.apply(this, false, submitCallback, callbackContext);
      };
    },

    initDone: function () {
    },

    firstTickReceived: function (data) {
      this._oldModel = sandbox.jsUtils.cloneDeep(data.model);

      if (typeof this.dataReceived === 'function') {
        this.dataReceived.call(this, data);
      }

    },

    tickUpdate: function () {

    },

    tickCommit: function () {

    },

    render: function () {

    },

    destroy: function () {
      utils.removeActionsFromButtons(this._buttons);
    }

  });

});