/* NOTE: Do not try to modify this file.
* It is auto generated by grunt task.
* To change it change grunt-tasks/options/create-modules-list.js
* and run create-modules-list grunt task.
**/

function syncGet (module) {
  return clbk => clbk(module);
}

const requireModule = (name) => {
  switch(name){
    case 'ActivationTemplates':
      return syncGet(require('modules/ActivationTemplates/ActivationTemplates'));
  case 'ApprovalFlowStepOverview':
      return syncGet(require('modules/ApprovalFlowStepOverview/ApprovalFlowStepOverview'));
  case 'ApprovalThumbnailView':
      return syncGet(require('modules/ApprovalThumbnailView/ApprovalThumbnailView'));
  case 'AuditView':
      return syncGet(require('modules/AuditView/AuditView'));
  case 'BaseTreeView':
      return syncGet(require('modules/BaseTreeView/BaseTreeView'));
  case 'BinderView':
      return syncGet(require('modules/BinderView/BinderView'));
  case 'BinPlatesDetails':
      return syncGet(require('modules/BinPlatesDetails/BinPlatesDetails'));
  case 'BinsView':
      return syncGet(require('modules/BinsView/BinsView'));
  case 'BirdEyeLayers':
      return syncGet(require('modules/BirdEyeLayers/BirdEyeLayers'));
  case 'ChatView':
      return syncGet(require('modules/ChatView/ChatView'));
  case 'ClusterView':
      return syncGet(require('modules/ClusterView/ClusterView'));
  case 'ColorManagementView':
      return syncGet(require('modules/ColorManagementView/ColorManagementView'));
  case 'ColorTableView':
      return syncGet(require('modules/ColorTableView/ColorTableView'));
  case 'CommCenterView':
      return syncGet(require('modules/CommCenterView/CommCenterView'));
  case 'ControlPanel':
      return syncGet(require('modules/ControlPanel/ControlPanel'));
  case 'CoretexTablesView':
      return syncGet(require('modules/CoretexTablesView/CoretexTablesView'));
  case 'CTPImportSelected':
      return syncGet(require('modules/CTPImportSelected/CTPImportSelected'));
  case 'CustomLogsView':
      return syncGet(require('modules/CustomLogsView/CustomLogsView'));
  case 'DashboardView':
      return syncGet(require('modules/DashboardView/DashboardView'));
  case 'EditableWorkflowView':
      return syncGet(require('modules/EditableWorkflowView/EditableWorkflowView'));
  case 'FanoutView':
      return syncGet(require('modules/FanoutView/FanoutView'));
  case 'FileViewer':
      return syncGet(require('modules/FileViewer/FileViewer'));
  case 'FlowStepOverview':
      return syncGet(require('modules/FlowStepOverview/FlowStepOverview'));
  case 'FlowStepView':
      return syncGet(require('modules/FlowStepView/FlowStepView'));
  case 'FragmentTableView':
      return syncGet(require('modules/FragmentTableView/FragmentTableView'));
  case 'LayoutGroupRules':
      return syncGet(require('modules/LayoutGroupRules/LayoutGroupRules'));
  case 'LayoutManager':
      return syncGet(require('modules/LayoutManager/LayoutManager'));
  case 'Login':
      return syncGet(require('modules/Login/Login'));
  case 'MapErrorPlansView':
      return syncGet(require('modules/MapErrorPlansView/MapErrorPlansView'));
  case 'MatrixView':
      return syncGet(require('modules/MatrixView/MatrixView'));
  case 'MNIAdsTableView':
      return syncGet(require('modules/MNIAdsTableView/MNIAdsTableView'));
  case 'MNIFolderUnplanPagesView':
      return syncGet(require('modules/MNIFolderUnplanPagesView/MNIFolderUnplanPagesView'));
  case 'MonitorProfilesView':
      return syncGet(require('modules/MonitorProfilesView/MonitorProfilesView'));
  case 'NamingPatternsView':
      return syncGet(require('modules/NamingPatternsView/NamingPatternsView'));
  case 'NewPageView':
      return syncGet(require('modules/NewPageView/NewPageView'));
  case 'OverridesView':
      return syncGet(require('modules/OverridesView/OverridesView'));
  case 'PageHistoryView':
      return syncGet(require('modules/PageHistoryView/PageHistoryView'));
  case 'PageView':
      return syncGet(require('modules/PageView/PageView'));
  case 'PageViewApproval':
      return syncGet(require('modules/PageViewApproval/PageViewApproval'));
  case 'PageViewCompare':
      return syncGet(require('modules/PageViewCompare/PageViewCompare'));
  case 'PageViewCompareApproval':
      return syncGet(require('modules/PageViewCompareApproval/PageViewCompareApproval'));
  case 'PageViewFlipbook':
      return syncGet(require('modules/PageViewFlipbook/PageViewFlipbook'));
  case 'PageViewHires':
      return syncGet(require('modules/PageViewHires/PageViewHires'));
  case 'PageViewHiresApproval':
      return syncGet(require('modules/PageViewHiresApproval/PageViewHiresApproval'));
  case 'PageViewPDF':
      return syncGet(require('modules/PageViewPDF/PageViewPDF'));
  case 'PageViewPDFApproval':
      return syncGet(require('modules/PageViewPDFApproval/PageViewPDFApproval'));
  case 'PasswordResetView':
      return syncGet(require('modules/PasswordResetView/PasswordResetView'));
  case 'PlanHistoryView':
      return syncGet(require('modules/PlanHistoryView/PlanHistoryView'));
  case 'PlanningWizard':
      return syncGet(require('modules/PlanningWizard/PlanningWizard'));
  case 'PlanTree':
      return syncGet(require('modules/PlanTree/PlanTree'));
  case 'PreflightConfigView':
      return syncGet(require('modules/PreflightConfigView/PreflightConfigView'));
  case 'PreflightView':
      return syncGet(require('modules/PreflightView/PreflightView'));
  case 'PreflightViewApproval':
      return syncGet(require('modules/PreflightViewApproval/PreflightViewApproval'));
  case 'PressProfilesView':
      return syncGet(require('modules/PressProfilesView/PressProfilesView'));
  case 'PressTree':
      return syncGet(require('modules/PressTree/PressTree'));
  case 'ProductionRunsTree':
      return syncGet(require('modules/ProductionRunsTree/ProductionRunsTree'));
  case 'RenamingRulesView':
      return syncGet(require('modules/RenamingRulesView/RenamingRulesView'));
  case 'ReportingView':
      return syncGet(require('modules/ReportingView/ReportingView'));
  case 'ResourcesView':
      return syncGet(require('modules/ResourcesView/ResourcesView'));
  case 'RightPanelInfoView':
      return syncGet(require('modules/RightPanelInfoView/RightPanelInfoView'));
  case 'SanomaTemplatesIndexView':
      return syncGet(require('modules/SanomaTemplatesIndexView/SanomaTemplatesIndexView'));
  case 'SecurityGroupsView':
      return syncGet(require('modules/SecurityGroupsView/SecurityGroupsView'));
  case 'SessionsView':
      return syncGet(require('modules/SessionsView/SessionsView'));
  case 'StructuresTableView':
      return syncGet(require('modules/StructuresTableView/StructuresTableView'));
  case 'ThumbnailLayers':
      return syncGet(require('modules/ThumbnailLayers/ThumbnailLayers'));
  case 'TMIAdsTableView':
      return syncGet(require('modules/TMIAdsTableView/TMIAdsTableView'));
  case 'TMIBaseTableView':
      return syncGet(require('modules/TMIBaseTableView/TMIBaseTableView'));
  case 'TMIFolderUnplanPagesView':
      return syncGet(require('modules/TMIFolderUnplanPagesView/TMIFolderUnplanPagesView'));
  case 'UnplanTableView':
      return syncGet(require('modules/UnplanTableView/UnplanTableView'));
  case 'UploadHistoryView':
      return syncGet(require('modules/UploadHistoryView/UploadHistoryView'));
  case 'UploadView':
      return syncGet(require('modules/UploadView/UploadView'));
  case 'UserInfo':
      return syncGet(require('modules/UserInfo/UserInfo'));
  case 'UsersView':
      return syncGet(require('modules/UsersView/UsersView'));
  case 'VillageView':
      return syncGet(require('modules/VillageView/VillageView'));
  case 'WorkflowOverview':
      return syncGet(require('modules/WorkflowOverview/WorkflowOverview'));
  case 'WorkflowView':
      return syncGet(require('modules/WorkflowView/WorkflowView'));
  case 'ZoneOverview':
      return syncGet(require('modules/ZoneOverview/ZoneOverview'));
  case 'ZoneTxTableView':
      return syncGet(require('modules/ZoneTxTableView/ZoneTxTableView'));
  
    default: return null;
  }
};

const importModule = (name) => {
  const resolve = requireModule(name);

  return resolve && resolve(module => module.default || module);
};

module.exports = {
  requireModule,
  importModule
};
