import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import Form from './Form';
import Validator from './Validator';
import TextInput from '../../common/inputs/TextInput';
import PercentInput from 'components/common/inputs/PercentInput';
import Checkbox from '../../common/inputs/Checkbox';
import List from '../../common/list/List';
import Button from '../../common/buttons/Button';
import FlowstepsBrowserInput from "../../private/inputs/FlowstepsBrowserInput";
import TextList from "../../private/TextList/TextList";
import Dropdown from "components/common/dropdown/Dropdown";
// import Header from '../../../widgets/ReactDataGrid/src/Header';
import Header from "components/common/headers/Header";
import { translate } from 'core/services/localization';
import BrowserInput from 'components/common/inputs/BrowserInput';
import { StatusCondtions } from '../StatusConditions/StatusConditions';
import { CustomIcons } from '../CustomIcons/CustomIcons';

const ObserverList = observer(List);
const isUndefined = o => typeof o === 'undefined';
// const isFunction = o => typeof o === 'function';
// const isArray = o => Array.isArray(o);
const defaultChildTypeValues = {
  text: ''
};

const getPropToValidate = type => type === 'editableTextList' ? 'items' : 'value';

const handleAddItemToItems = (items, childType) => (data) => {
  items.push(defaultChildTypeValues[childType]);
};

const newItemGetter = (items, childType, disabled) => (data) => {
  return <Button onClick={handleAddItemToItems(items, childType)} title={'Add'} disabled={disabled}>Add</Button>;
};

const handleRemoveItemFromItems = items => index => {
  items.splice(index, 1);
};

const ArrayItemGetterComponent = observer((props) => {
  let { index, items, childType, childValidators, disabled, placeholder, moduleNwid, itemDefinition } = props;

  return isUndefined(childValidators) ?
    renderComponent(items, childType, index, childValidators, childType, disabled, placeholder, moduleNwid, itemDefinition)
    : renderComponentWithValidator(items, childType, index, childValidators, childType, disabled, placeholder, moduleNwid, itemDefinition);

});

const handleItemGetter = (items, childType, validator, disabled, placeholder, moduleNwid, itemDefinition) => {
  return (item, index) => <ArrayItemGetterComponent
    index={index}
    items={items}
    childType={childType}
    childValidators={validator}
    disabled={disabled}
    placeholder={placeholder}
    moduleNwid={moduleNwid}
    itemDefinition={itemDefinition}
  />
};

//key param: should receive or index or property
const renderComponent = (model, type, key, validator, childType, disabled, placeholder, moduleNwid, itemDefinition) => {

  switch (type) {

    case 'text':
      return <TextInput
        className='crtx-form-element-full-width'
        value={model[key]}
        onChange={(event, value) => {
          model[key] = value;
        }}
        disabled={disabled}
        placeholder={placeholder}
      />;

    case 'checkbox':
      return <Checkbox
        checked={model[key]}
        onChange={(event, value) => {
          model[key] = value;
        }}
        disabled={disabled}
      />;

    case 'percentage':
      return <PercentInput
        style={{ width: '70px' }}
        value={model[key]}
        disabled={disabled}
        onChange={(event, value) => {
          model[key] = value;
        }}
      />;

    case 'array':
      return <ObserverList
        items={model[key]}
        itemGetter={handleItemGetter(model[key], childType, validator, disabled, placeholder, moduleNwid, itemDefinition)}
        newItemGetter={newItemGetter(model[key], childType, disabled)}
        onRemove={handleRemoveItemFromItems(model[key])}
      />;

    case 'flowstepsBrowser':
      return <FlowstepsBrowserInput
        className='crtx-form-element-full-width'
        value={toJS(model[key])}
        moduleNwid={moduleNwid}
        multiselect={true}
        onSelect={(event, selectedFlowstepsInfo) => {
          model[key] = selectedFlowstepsInfo || [];
        }} />;

    case 'flowstepBrowser':
      return <FlowstepsBrowserInput
        className='crtx-form-element-full-width'
        value={[model[key]]}
        moduleNwid={moduleNwid}
        onSelect={(event, selectedFlowstepsInfo) => {
          model[key] = selectedFlowstepsInfo[0] || {};
        }} />;

    case 'dropdown':
      return <Dropdown
        className='crtx-form-element-full-width'
        value={model[key]}
        onSelect={(event, value) => {
          model[key] = value;
        }}
        options={itemDefinition.options}
        disabled={disabled}
      />;

    case 'separator':
      return <Header
        value={model[key]}
      />;

    case 'editableTextList':
      return <TextList
        className='crtx-form-element-full-width'
        items={model[key]}
        onSelect={(event, items) => {
          model[key] = items || [];
        }}
        windowTitle={translate(itemDefinition.title)}
        target='dialog'
        windowWidth={350}
        windowHeight={350}
        editButtonTooltip={translate('Edit Items')}
      />;

    case 'statusConditions':
      return <StatusCondtions
        model={model[key]}
        onChange={data => {
          model[key] = data || {};
        }}
      />;

    case 'customIcons':
      return <CustomIcons
        model={model[key]}
        availableCustomIcons={model.availableCustomIcons}
        moduleNwid={moduleNwid}
        onSelect={data => {
          model[key] = data || '';
        }}
      />;

    case 'browserInput':
      return <BrowserInput className='crtx-form-element-full-width'
        viewOrActionNwid={moduleNwid}
        value={model[key]}
        rootUrl={'filesystem'}
        onSelect={(event, value) => {
          model[key] = value;
        }}
        onChange={(event, value) => {
          model[key] = value;
        }}
      />;
  }
};

const renderComponentWithValidator = (model, type, index, validator, childType, disabled, placeholder, moduleNwid, itemDefinition) => {
  return <Validator className='crtx-form-element-full-width-validator' rules={validator} validate={getPropToValidate(type)}>
    {renderComponent(model, type, index, validator, childType, disabled, placeholder, moduleNwid, itemDefinition)}
  </Validator>;
};

const getDefaultValues = (schemaItem) => {
  if (!isUndefined(schemaItem.defaultValue)) return schemaItem.defaultValue;
  else if (schemaItem.type === 'array') return [];
  else if (schemaItem.type === 'text') return '';
  else if (schemaItem.type === 'percentage') return 0;
  else if (schemaItem.type === 'checkbox') return false;
  else if (schemaItem.type === 'flowstepsBrowser') return [];
  else if (schemaItem.type === 'flowstepBrowser') return {};
  else if (schemaItem.type === 'dropdown') return '';
  else if (schemaItem.type === 'editableTextList') return [];
  else if (schemaItem.type === 'browserInput') return '';
};

class SchemaForm extends Component {

  static propTypes = {
    model: PropTypes.any,
    schema: PropTypes.any,
    definition: PropTypes.any
  }

  static defaultProps = {
    schema: []
  };

  static onDataReceived = (model, props) => {
    return props.schema.reduce((accModel, schemaItem) => {
      if (!!schemaItem.showEnableCheckbox) {
        const gui$enableCheckboxValue = !isUndefined(model[`gui$enableCheckboxValue-${schemaItem.name}`]) ? model[`gui$enableCheckboxValue-${schemaItem.name}`] : !isUndefined(model[schemaItem.name]);
        const value = model[schemaItem.name] || model[`gui$${schemaItem.name}`] || getDefaultValues(schemaItem);

        return {
          ...accModel,
          [`gui$enableCheckboxValue-${schemaItem.name}`]: gui$enableCheckboxValue,
          [schemaItem.name]: value
        };
      };

      if (schemaItem.name === 'statusConditions') {
        const statusConditions = schemaItem.schemaProps.reduce((acc, sp) => {
          acc[sp.name] = typeof (model[sp.name]) !== 'undefined' ? model[sp.name] : getDefaultValues(sp);
          return acc;
        }, {});

        return {
          ...accModel,
          [schemaItem.name]: statusConditions
        };
      };

      return {
        ...accModel,
        [schemaItem.name]: typeof (model[schemaItem.name]) !== 'undefined' ? model[schemaItem.name] : getDefaultValues(schemaItem)
      };
    }, { ...model });
  };

  static onApply = (model) => {
    for (var prop in model) {
      const indexOfEnableCheckboxValue = prop.indexOf('gui$enableCheckboxValue');
      if (indexOfEnableCheckboxValue >= 0) {
        const propName = prop.split('-')[1];
        model[`gui$${propName}`] = model[`${propName}`];
        model[prop] === true ? delete model[`gui$${propName}`] : delete model[`${propName}`];
      }
    }
    model = { ...model, ...model.statusConditions };
    delete model.statusConditions;
    delete model.availableCustomIcons;
    return {
      ...model
    }
  };

  constructor(props) {
    super(props);
  }

  renderSchemaItem = (itemDefinition, index) => {
    const { model, schema, moduleNwid } = this.props;
    const disabledItemClassName = isUndefined(model[`gui$enableCheckboxValue-${itemDefinition.name}`]) ? '' : model[`gui$enableCheckboxValue-${itemDefinition.name}`] === true ? '' : 'disabled';
    const disabledInput = disabledItemClassName === 'disabled';
    const isCheckboxInputType = itemDefinition.type === 'checkbox';
    const isStatusConditionsType = itemDefinition.type === 'statusConditions';
    return <React.Fragment key={index} >
      {itemDefinition.showEnableCheckbox ?
        (<React.Fragment>
          <label className='crtx-form-label-with-checkbox' title={itemDefinition.tooltip || itemDefinition.displayName}>
            <Checkbox className='crtx-enable-checkbox' title={'Enable'}
              checked={model[`gui$enableCheckboxValue-${itemDefinition.name}`]} onChange={(event, value) => {
                model[`gui$enableCheckboxValue-${itemDefinition.name}`] = value;
              }} />
            {itemDefinition.displayName}
          </label>
          {
            isUndefined(itemDefinition.validators) ?
              renderComponent(model, itemDefinition.type, itemDefinition.name, itemDefinition.validators || itemDefinition.childValidators, itemDefinition.childType, disabledInput, itemDefinition.placeholder, moduleNwid, itemDefinition) :
              renderComponentWithValidator(model, itemDefinition.type, itemDefinition.name, itemDefinition.validators || itemDefinition.childValidators, itemDefinition.childType, disabledInput, itemDefinition.placeholder, moduleNwid, itemDefinition)
          }
        </React.Fragment>)
        :
        (<React.Fragment>
          {!itemDefinition.validators ?
            (isCheckboxInputType ?
              <div className='crtx-form-full-row'>
                <label className='crtx-form-label-with-checkbox' title={itemDefinition.tooltip || itemDefinition.displayName}>
                  {renderComponent(model, itemDefinition.type, itemDefinition.name, itemDefinition.validators || itemDefinition.childValidators, itemDefinition.childType, disabledInput, itemDefinition.placeholder, moduleNwid, itemDefinition)}
                  {itemDefinition.displayName}
                </label>
              </div>
              :
              isStatusConditionsType ?
                renderComponent(model, itemDefinition.type, itemDefinition.name, itemDefinition.validators || itemDefinition.childValidators, itemDefinition.childType, disabledInput, itemDefinition.placeholder, moduleNwid, itemDefinition)
                :
                <React.Fragment>
                  <label className='crtx-form-label-top-alignment' title={itemDefinition.tooltip || itemDefinition.displayName}>{itemDefinition.displayName}:</label>
                  {renderComponent(model, itemDefinition.type, itemDefinition.name, itemDefinition.validators || itemDefinition.childValidators, itemDefinition.childType, disabledInput, itemDefinition.placeholder, moduleNwid, itemDefinition)}
                </React.Fragment>)
            : (isCheckboxInputType ?
              <div className='crtx-form-full-row'>
                <label className='crtx-form-label-with-checkbox' title={itemDefinition.tooltip || itemDefinition.displayName}>
                  {renderComponentWithValidator(model, itemDefinition.type, itemDefinition.name, itemDefinition.validators || itemDefinition.childValidators, itemDefinition.childType, disabledInput, itemDefinition.placeholder, moduleNwid, itemDefinition)}
                  {itemDefinition.displayName}
                </label>
              </div> :
              <React.Fragment>
                <label className='crtx-form-label-top-alignment' title={itemDefinition.tooltip || itemDefinition.displayName}>{itemDefinition.displayName}:</label>
                {renderComponentWithValidator(model, itemDefinition.type, itemDefinition.name, itemDefinition.validators || itemDefinition.childValidators, itemDefinition.childType, disabledInput, itemDefinition.placeholder, moduleNwid, itemDefinition)}
              </React.Fragment>)
          }
        </React.Fragment>)}

    </React.Fragment>

  };

  render() {
    const { schema } = this.props;

    return <React.Fragment>
      {schema.map(this.renderSchemaItem)}
    </React.Fragment>;
  }
}

export default Form(SchemaForm);