import AbstractAction from 'AbstractAction';
import {coretexPath} from 'utilities/url'

module.exports = AbstractAction.extend({
	execute: function () {
			var link = document.createElement('a');
			link.href = coretexPath('rest', 'v2', `users-info/users?viewOrActionNwId=${this.module.nwid}&actionDefinitionName=${this.actionDefinitionName}`);
			link.download = "usersInformation.xlsx";
			link.dispatchEvent(new MouseEvent('click'));
	}	
});