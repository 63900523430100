/**
 * @name ZoneTxTable
 * @file ZoneTxTable component
 *
 * @author Boris
 * @since: 2019-12-10
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table, { Column, cells, headers, filters } from 'widgets/ReactDataGrid';
import { defaultCellDataGetter, defaultShouldCellUpdate } from './columnsCreator';
import { FILTER_TYPE } from 'widgets/ReactDataGrid/utils';
import ProgressBar from 'components/common/progress/ProgressBar';
import IndicationCounter from '../BirdEyeLayers/src/indicationCounter';
import { translate } from 'core/services/localization';
import { ROW_HEIGHT, ROW_HEIGHT_INCLUDING_THUMBNAIL, HEADER_HEIGHT, HEADER_HEIGHT_WITH_FILTER } from 'widgets/ReactDataGrid/utils';

const { Text } = cells;
const { GenericHeader, HeaderCaption } = headers;
const { MultiselectFilter, TextInputFilter } = filters;

const getRowHeight = tableColumns => {
  const thumbnailColumn = tableColumns.find(col => col.key === 'thumbnail');
  return thumbnailColumn?.visible ? ROW_HEIGHT_INCLUDING_THUMBNAIL : ROW_HEIGHT;
};

export default class ZoneTxTable extends Component {
  static propTypes = {
    model: PropTypes.object
  };

  state = {
    rowHeight: getRowHeight(this.props.tableColumns)
  };

  renderColumnFilter(column) {
    const { filter } = column;

    if (!filter) {
      return;
    }

    const { module } = this.props;

    switch (filter.type) {
      case FILTER_TYPE.MULTISELECT:
        return (
          <MultiselectFilter
            value={filter.selected}
            options={filter.options}
            dataType={filter.dataType}
            onSelect={(event, values, index) => module.handleColumnFilterChange(column, { selected: values })}
          />
        );
      case FILTER_TYPE.TEXT:
        return (
          <TextInputFilter
            value={filter.textValue}
            onChange={(e, textValue) => module.handleColumnFilterChange(column, { textValue })}
          />
        );
    }
  }

  renderColumnHeaderFilter(column) {
    const { filtersEnabled } = this.props;

    if (!filtersEnabled) {
      return;
    }

    return (
      <div className='column-header-filter'>
        {this.renderColumnFilter(column)}
      </div>
    );
  }

  renderColumnHeaderCaption = (column, handleClick, sortDirection, index) => {
    return (
      <HeaderCaption sortDirection={sortDirection} sortOrder={index + 1} tooltip={column.caption}
        onSort={handleClick}
        sortable={typeof column.sortType !== 'undefined'}
      >
        {column.caption}
      </HeaderCaption>
    );
  };

  renderColumnHeader = (column, handleClick, sortDirection, index) => {
    return (
      <GenericHeader
        captionRenderer={this.renderColumnHeaderCaption(column, handleClick, sortDirection, index)}
        filterRenderer={this.renderColumnHeaderFilter(column)}
      />
    );
  };

  handleTableColumnsFilter = (columnKey, visible, columns) => {
    this.props.module.handleColumnsFilter(columns);
    const currentRowHeight = getRowHeight(this.props.tableColumns);
    this.setState({ rowHeight: currentRowHeight });
  };

  renderTable() {
    const { module, viewModel, filtersEnabled, tableColumns, tableKey, tableName } = this.props;

    const { counters: { finished, rejected, hold, waitingForApproval, error, total } } = module;

    const allowDrop = viewModel.rowType === 'page';
    const headerHeight = filtersEnabled ? HEADER_HEIGHT_WITH_FILTER : HEADER_HEIGHT;

    const height = '100%';

    return (
      <React.Fragment>
        <div className='zone-tx-table-view-title-bar'>
          <div className='table-name'>
            {tableName}
          </div>
          <div className='progress-bar'>
            <ProgressBar min={0} max={total} value={finished} style={{ height: 17 }} >
              {finished} / {total}
            </ProgressBar>
            <label className='total-filtered-items-label'>{translate('Rows: {1}', viewModel.rows.length)}</label>
          </div>
          <div className='indicators'>
            <IndicationCounter icon='reject' count={rejected} title={translate('Rejected')} />
            <IndicationCounter icon='error' count={error} title={translate('Error')} />
            <IndicationCounter icon='waitingForApproval' count={waitingForApproval} title={translate('Waiting for approval')} />
            <IndicationCounter module='Thumbnail' icon='holdCounter' count={hold} title={translate('Hold')} />
          </div>
        </div>
        <div className='zone-tx-table' style={{ height }} onKeyDown={e => module.handleTableViewKeyDown(e)}>
          <Table
            key={tableKey}
            rows={viewModel.rows}
            columnKey='id'
            rowHeight={this.state.rowHeight}
            headerHeight={headerHeight}
            virtualScroll={true}
            onColumnsFilter={this.handleTableColumnsFilter}
            onColumnsOrder={(columns, oldIndex, newIndex) => module.handleColumnsOrder(columns, oldIndex, newIndex)}
            onSelect={selectedRows => module.handleTableSelect(selectedRows)}
            onRowContextMenu={(rowIndex, rowContent, selectedRows, e) => module.handleRowContextMenu(rowContent, selectedRows, e)}
            onColumnResize={(columns, columnKey) => module.handleColumnResize(columns, columnKey)}
            onDoubleClickRow={(rowIndex, rowContent, e) => module.handleTableDoubleClick(rowContent)}
            onDrop={allowDrop ? (rowIndex, rowContent, e) => module.handleTableDrop(rowIndex, rowContent, e) : undefined}
            filtersEnabled={filtersEnabled}
            onToggleFiltersClick={(e, newValue) => module.handleToggleFilters(newValue)}
            showFilterOptionInHeaderMenu={true}
          >

            {tableColumns.map(col => {
              const columnKey = col.key || col.caption;
              const idx = viewModel.columnsToSortBy.findIndex(col => col.key === columnKey);
              const sortDirection = idx < 0 ? '' : viewModel.columnsToSortBy[idx].ascending ? 'asc' : 'desc';
              const handleClick = col.sortType && (multiSort => module.handleTableSort(columnKey, multiSort));

              return <Column
                key={columnKey}
                columnKey={columnKey}
                visible={col.visible}
                fixed={col.fixed}
                width={col.width || 100}
                resizable={col.resizable !== false}
                align={col.align || 'left'}
                title={col.caption}
                header={this.renderColumnHeader(col, handleClick, sortDirection, idx)}
                cell={col.cell || Text}
                cellDataGetter={col.cellDataGetter || defaultCellDataGetter(viewModel)}
                shouldCellUpdate={col.shouldCellUpdate || defaultShouldCellUpdate}
              />
            }
            )}
          </Table>

        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className='zone-tx-table-view'>
        {this.renderTable()}
      </div>
    );
  }
}