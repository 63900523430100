define(['sandbox', 'logger'], function (sandbox, logger) {
  'use strict';

  var log = logger.getDefaultLogger();

  function close(simpleSetup, closeCallback, callbackContext) {
    closeCallback.call(callbackContext || simpleSetup);
    if (simpleSetup && simpleSetup.container && typeof simpleSetup.container.close === 'function')
      simpleSetup.container.close.call(simpleSetup.container);
  }

  function apply(simpleSetup, shouldClose, submitCallback, callbackContext) {
    var model, resultOfSave;

    try {
      if (typeof simpleSetup.apply === 'function') {
        model = simpleSetup.apply();
      }

      submitCallback.call(callbackContext || simpleSetup, model);

      if (model && simpleSetup.viewActions) {
        const clique = simpleSetup.viewSettings.configuredClique;
        const saveAction = simpleSetup.viewActions.filter(a => a.actionClass === 'SaveSetupAction' &&
          (!clique || a.configuredClique === clique))[0];
        if (saveAction && saveAction.isApplicable && saveAction.isApplicable()) {
          resultOfSave = saveAction.execute(model, simpleSetup._oldModel);
        }
      }
    }
    catch (ex) {
      log.error(ex);
    }

    if ((shouldClose && typeof model !== 'undefined') || (shouldClose && typeof simpleSetup.apply !== 'function')) {
      if (resultOfSave && typeof resultOfSave.then === 'function') {
        resultOfSave.then(res => {
          simpleSetup._actions.close.call(simpleSetup);
        })
      } else {
        simpleSetup._actions.close.call(simpleSetup);
      }
    }
  }

  function isStandaloneView (simpleSetup) {
    return simpleSetup.element !== void 0;
  }

  function applyActionsToButtons (buttons) {
    if (buttons) {
      buttons.forEach(function (btn) {
        sandbox.events.listen(btn.button, 'click', btn.action);
      });
    }
  }

  function removeActionsFromButtons (buttons) {
    if (buttons) {
      buttons.forEach(function (btn) {
        sandbox.events.unlisten(btn.button, 'click', btn.action);
      });
    }
  }

  function createSetupContainer (simpleSetup) {
    const doc = simpleSetup.win ? simpleSetup.win.document : window.document;
    var setupDOM = sandbox.dom.find(simpleSetup.element, doc);
    var setupProDOM = sandbox.dom.createNode('<div class="pro-setup"></div>');
    var setupContainerDOM = sandbox.dom.createNode('<div class="row setup-container"></div>')[0];
    var setupContentDOM = sandbox.dom.createNode('<div class="row setup-content k-pane"></div>')[0];
    var setupFooterDOM = sandbox.dom.createNode('<div class="row setup-footer crtx-align-content-right"></div>')[0];
    var setupOKButton = sandbox.dom.createNode('<button class="crtx-button primary">' + sandbox.localization.translate('OK') + '</button>');
    var setupApplyButton = sandbox.dom.createNode('<button class="crtx-button">' + sandbox.localization.translate('Apply') + '</button>');
    var setupCancelButton = sandbox.dom.createNode('<button class="crtx-button">' + sandbox.localization.translate('Cancel') + '</button>');

    simpleSetup._buttons = [
      {action: simpleSetup._actions['ok'].bind(simpleSetup), button: setupOKButton[0], args: [true]},
      {action: simpleSetup._actions['apply'].bind(simpleSetup), button: setupApplyButton[0], args: [false]},
      {action: simpleSetup._actions['close'].bind(simpleSetup), button: setupCancelButton[0]}
    ];


    if (simpleSetup.okButton !== false) sandbox.dom.append(setupFooterDOM, setupOKButton);
    if (simpleSetup.applyButton === true) sandbox.dom.append(setupFooterDOM, setupApplyButton);
    if (simpleSetup.cancelButton !== false) sandbox.dom.append(setupFooterDOM, setupCancelButton);

    if (simpleSetup.showFooter !== false) sandbox.dom.append(setupContainerDOM, setupFooterDOM);
    sandbox.dom.append(setupContainerDOM, setupContentDOM);

    sandbox.dom.append(setupProDOM, setupContainerDOM);

    sandbox.dom.empty(setupDOM);
    sandbox.dom.append(setupDOM, setupProDOM);

    applyActionsToButtons(simpleSetup._buttons);
  }

  return {
    close: close,
    apply: apply,
    isStandaloneView: isStandaloneView,
    applyActionsToButtons: applyActionsToButtons,
    removeActionsFromButtons: removeActionsFromButtons,
    createSetupContainer: createSetupContainer
  };

});