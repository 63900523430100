/**
 * Created by Guy Behar on 17/03/2016.
 */
define([], function () {
  'use strict';

  function getFlowsteps (flowsteps, flowstepsNwids) {
    return (flowsteps || []).filter(function (flowstepItem) {
      return flowstepsNwids.indexOf(flowstepItem.nwid) >= 0;
    });
  }

  function getFlowstep (flowsteps, flowstepNwid) {
    var filteredFlowsteps = getFlowsteps(flowsteps, [flowstepNwid]);

    return filteredFlowsteps.length > 0 ? filteredFlowsteps[0] : null;
  }

  function getTransitions (transitions, flowstepNwid, prop) {

    return (transitions || []).filter(function (transition) {
      return transition[prop] === flowstepNwid;
    });

  }

  function isLastFlowStep (nextTransitions) {
    return !(Array.isArray(nextTransitions) && nextTransitions.length > 0) ? true : false;
  }

  function isDisconnectedTransition (transition, flowsteps) {
    var sourceFlowstep = getFlowstep(flowsteps, transition.sourceStep);
    return sourceFlowstep === null;
  }

  function isBrokenTransition (transition, flowsteps) {
    var targetFlowstep = getFlowstep(flowsteps, transition.targetStep);
    return targetFlowstep === null;
  }

  function bridgedTransitions (transitions, flowsteps, firstBrokenTransition, transition) {
    var flowstepNwid = transition.targetStep;
    var nextTransitions = getTransitions(transitions, flowstepNwid, 'sourceStep');
    var flowstep = getFlowstep(flowsteps, flowstepNwid);

    if (isLastFlowStep(nextTransitions) && flowstep === null) {
      return null;
    }

    if (flowstep !== null) {
      return Object.assign({}, firstBrokenTransition, {
        targetStep: flowstepNwid
      });
    }

    return nextTransitions
      .map(function (nextTransition) {
        return bridgedTransitions(transitions, flowsteps, firstBrokenTransition, nextTransition);
      })
      .reduce(function (flattenTransitions, nextTransitions) {
        return flattenTransitions.concat(nextTransitions);
      }, [])
      .filter(function (nextTransition) {
        return nextTransition !== null;
      });

  }

  function transitionsWithBridgesReducer (transitions, flowsteps) {
    return function (transitionsWithBridges, transition) {

      if (isDisconnectedTransition(transition, flowsteps)) {
        return transitionsWithBridges;
      }

      if (isBrokenTransition(transition, flowsteps)) {
        return transitionsWithBridges.concat(bridgedTransitions(transitions, flowsteps, transition, transition));
      }

      return transitionsWithBridges.concat(transition);
    }
  }

  function filterNull (transition) {
    return transition !== null;
  }

  function filterDuplications (transition, index, arrTransitions) {
    var foundIndex = arrTransitions.findIndex(function (t) {
      return t.sourceStep === transition.sourceStep && t.targetStep === transition.targetStep;
    });
    return foundIndex === index;
  }

  function fixedTransitions (transitions, flowSteps) {
    return transitions
      .reduce(transitionsWithBridgesReducer(transitions, flowSteps), [])
      .filter(filterNull)
      .filter(filterDuplications);

  }

  return {
    fixedTransitions: fixedTransitions
  };

});