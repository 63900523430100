/**
 * @name PasteAction
 * @author Guy Behar
 */
import AbstractAction from 'AbstractAction';
import sandbox from 'sandbox';

const isUndefined = o => typeof o === 'undefined';
const isArray = o => Array.isArray(o);
const translate = sandbox.localization.translate;

const allowedTypesToPasteMap = {
  'page': ['page']
};

const isAllowedType = (copyType, pasteType) => {
  return isArray(allowedTypesToPasteMap[pasteType]) && allowedTypesToPasteMap[pasteType].indexOf(copyType) >= 0 ? true : false;
};

export default AbstractAction.extend({

  isApplicable: function (selectedItems) {
    if (isUndefined(selectedItems) || selectedItems.length === 0) return false;

    const clipboardLatestItem = sandbox.clipboard.getLatestItemFromClipbaord();

    if (isUndefined(clipboardLatestItem)) return false;

    const selectedItemsType = selectedItems[0].type;

    return isAllowedType(clipboardLatestItem.type, selectedItemsType);
  },

  execute: function (selectedItems) {
    const latestItemFromClipboard = sandbox.clipboard.getLatestItemFromClipbaord();
    const itemToPaste = {
      nwid: latestItemFromClipboard.nwid,
      type: latestItemFromClipboard.type
    };
    sandbox.request.rest(this.nwid).post('global/actions/paste-action'
      , {
        data: JSON.stringify({
          sourceId: itemToPaste.nwid,
          sourceTemplate: itemToPaste.type,
          targetId: selectedItems[0].nwid,
          targetTemplate: selectedItems[0].type
        }),
        dataType: 'text'
      }
    ).then((response, textStatus, jqXHR) => {
      if (jqXHR.status === 204) {
        sandbox.clipboard.setErrorToastNotification(translate('The page is no longer valid'));
      }
    });
  }

});