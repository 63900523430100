/**
 * @name
 * @fileOverview
 * @author sergey
 */
define(['sandbox/jsUtils', 'ember', './EventsEnum', 'ember_table'],
		function (jsutils, Ember, EVENTS) {
  'use strict';

  return Ember.Mixin.create({

    actions: {
      editHandler: function (newVal, editableField) {
        var cellView = editableField.get('parentView'),
            cbs = this.get('callbacks')[EVENTS.AFTER_EDIT],
            hasCustomSave = jsutils.isFunction(cellView.get('save'));
        if (hasCustomSave) {
          cellView.get('save')(newVal, cellView.get('row.content'));
        }
        else {
          cellView.set('row.content.' + cellView.get('column.contentPath'), newVal);
          if (Array.isArray(cbs)) {
            cbs.forEach(function(cb){
              cb(cellView.get('row.content'), cellView);
            });
          }
        }
      }
    },

    contextMenu: function (ev) {
      this._super.apply(this, arguments);
      var rowView = this.getRowForEvent(ev),
          selection = this.get('selection'),
          cbs = this.get('callbacks')[EVENTS.CONTEXT_MENU];
      if (rowView !== void 0 && Array.isArray(cbs)) {
        cbs.forEach(function (cb) {
          cb(rowView.get('content'), selection, ev);
        });
      }
    },

    doubleClick: function (ev) {
      this._super.apply(this, arguments);
      var rowView = this.getRowForEvent(ev),
          cbs = this.get('callbacks')[EVENTS.DBLCLICK];
      if (rowView !== void 0 && Array.isArray(cbs)) {
        cbs.forEach(function (cb) {
          cb(rowView.get('content'));
        });
      }
    },

    keyDown: function (ev) {
      this._super.apply(this, arguments);
      var selection = this.get('selection'),
          cbs = this.get('callbacks')[EVENTS.DELETE];
      if (ev.keyCode === 46 && this.get('selection.length') !== 0) {
        if (Array.isArray(cbs)) {
          cbs.forEach(function (cb) {
            cb(selection);
          });
        }
      }
    },

    selectionDidChange: function () {
      var selection = this.get('selection'),
          cbs = this.get('callbacks')[EVENTS.SELECTION_CHANGED];
      if (Array.isArray(cbs)) {
        cbs.forEach(function (cb) {
          cb(selection);
        });
      }
    }.observes('selection.@each'),

    columnWidthDidChange: jsutils.debounce(function () {
      var columns = this.get('columns'),
          cbs = this.get('callbacks')[EVENTS.COLUMN_WIDTH_CHANGED],
          hundred, prefs;
      // the width of columns is stored in pixels; but
      // the pref should be stored in percents;
      // so, we have to calculate hundred percent of width
      hundred = columns.reduce(function (acc, c) {
        return acc += c.columnWidth;
      }, 0);
      prefs = columns.map(function (c) {
        var res = {
					id: c.id,
					isVisible: c.isVisible,
          width: (c.columnWidth / hundred) * 100
        };
				return res;
      });

      if (Array.isArray(cbs)) {
        cbs.forEach(function (cb) {
          cb(prefs);
        });
      }
    }, 500).observes('tableColumns.@each.columnWidth')
  });
});