/**
 * @file Dropdown list with checkboxes for multiple selection
 *
 * @author Boris
 * @created 2023-08-23
 */
import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import { classNames } from 'utilities/classNames';
import { Dropdown } from '../dropdown/Dropdown';
import { Checkbox } from 'components/common/inputs';
import { IconButton } from 'components/common/buttons';

export function Multiselect(
  {
    value = [],
    popoverClassName,
    placeholder,
    disabled = false,
    withClearButton = true,
    getOptionProps: getOptionPropsProp,
    renderOption: renderOptionProp,
    renderContent: renderContentProp,
    onSelect,
    onOpenChange,
    ...props
  }) {

  const openedRef = useRef(false);

  const valueDict = useMemo(() => {
    return value.reduce((acc, v) => {
      acc[v] = true;

      return acc;
    }, {});

  }, [value]);

  const isOptionSelected = (optionValue) => {
    return valueDict[optionValue] || false;
  };

  const getOptionProps = (option) => {
    let result;
    if (typeof getOptionPropsProp === 'function') {
      result = getOptionPropsProp(option);
    } else if (typeof option === 'object' && option !== null) {
      result = option;
    } else {
      result = {
        value: option,
        text: option
      };
    }

    return result;
  };

  const handleOpenChange = (opened) => {
    openedRef.current = opened;

    if (typeof onOpenChange === 'function') {
      onOpenChange(opened);
    }
  };

  const handleClearButtonClick = (e) => {
    onSelect(e, []);
  };

  const renderOption = (option) => {
    let result;
    if (typeof renderOptionProp === 'function') {
      result = renderOptionProp(option);
    } else {
      const { text } = getOptionProps(option);
      result = <div className='option-text'>{text}</div>;
    }

    return result;
  };

  const renderContent = (selectedOptions) => {
    let result;
    if (typeof renderContentProp === 'function') {
      result = renderContentProp(selectedOptions);
    } else {
      let content;
      if (selectedOptions.length > 0) {
        content = selectedOptions.map(option => {
          const { text = '' } = getOptionProps(option);

          return text;
        }).join(', ');

        if (selectedOptions.length > 1) {
          content = `(${selectedOptions.length}) ${content}`;
        }
      }

      result = (
        <>
          <div className={classNames('content-text', { placeholder: selectedOptions.length <= 0 })}>
            {selectedOptions.length > 0 ? content : placeholder}
          </div>
          {withClearButton && value.length > 0 && !disabled &&
            <IconButton
              className='clear-button'
              iconName={'close'}
              scale={false}
              bubbles={openedRef.current}
              tooltip={translate('Clear')}
              onClick={handleClearButtonClick}
            />}
        </>
      );
    }

    return result;
  };

  const renderComposedOption = (option) => {
    const { value: optionValue, disabled: optionDisabled } = getOptionProps(option);

    return (
      <>
        <Checkbox
          checked={isOptionSelected(optionValue)}
          disabled={optionDisabled}
        />
        {renderOption(option)}
      </>
    );
  };

  return (
    <Dropdown
      {...props}
      value={value}
      disabled={disabled}
      multiple={true}
      popoverClassName={classNames('crtx-multiselect-popover', popoverClassName)}
      getOptionProps={getOptionProps}
      renderOption={renderComposedOption}
      renderContent={renderContent}
      onSelect={onSelect}
      onOpenChange={handleOpenChange}
    />
  );
}

Multiselect.propTypes = {
  ...Dropdown.propTypes,
  multiple: PropTypes.bool,
  withClearButton: PropTypes.bool,
};