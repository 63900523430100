/* @flow weak */

import React, { Component } from 'react';
import sandbox from 'sandbox';
import ReactComponents from 'widgets/ReactComponents/src/index';
import SimpleForm from 'widgets/SimpleForm/src/index';
import labels from '../labels';
import DropdownOptionCell from './DropdownOptionCell';
import PropTypes from 'prop-types';

const { Container } = SimpleForm;
const { Icon } = ReactComponents;

module.exports = class DropdownOptions extends Component {
  constructor(props) {
    super(props);
    this.handleAddOption = this.handleAddOption.bind(this);
    this.handleRemoveOption = this.handleRemoveOption.bind(this);
    this.handleCellChange = this.handleCellChange.bind(this);
    this.renderOption = this.renderOption.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
  }

  static propTypes = {
    store: PropTypes.object.isRequired,
    isDisabled: PropTypes.bool
  }

  static defaultProperties = {
    isDisabled: false
  }

  handleAddOption(index) {
    return function (event) {
      const store = this.props.store;
      store.dispatch({
        type: 'ADD_DROPDOWN_OPTION',
        index: index
      });
    }.bind(this);
  }

  handleRemoveOption(index) {
    return function (event) {
      const store = this.props.store;
      store.dispatch({
        type: 'REMOVE_DROPDOWN_OPTION',
        index: index
      });
    }.bind(this);
  }

  handleCellChange(index, propName) {
    return function (event, value) {
      const store = this.props.store;
      store.dispatch({
        type: 'CHANGE_DROPDOWN_PROP',
        index: index,
        propName: propName,
        value: value
      });
    }.bind(this);
  }

  renderOption(option, index) {
    const isDisabled = this.props.isDisabled;

    return <tr key={index}>
      <td><DropdownOptionCell value={option.text} placeholder={labels.displayName} onChange={this.handleCellChange(index, 'text')} /></td>
      <td><DropdownOptionCell value={option.value} placeholder={labels.value} onChange={this.handleCellChange(index, 'value')} /></td>
      <td style={{ textAlign: 'center' }}>
        <Icon icon={sandbox.icons.getGeneralIcon(null, 'add')} onClick={this.handleAddOption(index + 1)} title={labels.addOption} disabled={isDisabled} />
      </td>
      <td style={{ textAlign: 'center' }}>
        <Icon icon={sandbox.icons.getGeneralIcon(null, 'delete')} onClick={this.handleRemoveOption(index)} title={labels.removeOption} disabled={isDisabled} />
      </td>
    </tr>;
  }

  renderOptions() {
    const store = this.props.store;
    const currentTableIndex = store.get('currentTableIndex');
    const currentColumnType = store.get('currentColumnType');
    const currentColumnIndex = store.get('currentColumnIndex');
    const tablePath = `tables[${currentTableIndex}]`;
    const table = store.get(tablePath);
    const columns = store.get(`${tablePath}.${currentColumnType}`);
    const columnValues = store.get(`${tablePath}.${currentColumnType}[${currentColumnIndex}].values`) || [];
    return columnValues.map(this.renderOption);
  }

  render() {
    const disabled = this.props.disabled;
    const headerClassName = disabled ? 'disabled' : '';

    return <Container col="10" className="dropdown-options-container">
      <table className="dropdown-options-table">
        <thead>
          <tr>
            <th className={headerClassName}>{labels.displayName}</th>
            <th className={headerClassName}>{labels.value}</th>
            <th style={{ width: 30, textAlign: 'center' }}>
              <Icon icon={sandbox.icons.getGeneralIcon(null, 'add')} onClick={this.handleAddOption(0)} title={labels.addOption} disabled={disabled} />
            </th>
            <th style={{ width: 30, textAlign: 'center' }}>
            </th>
          </tr>
        </thead>
        <tbody>
          {this.renderOptions()}
        </tbody>
      </table>
    </Container>;
  }

}