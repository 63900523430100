import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import Table, { Column, cells, headers, filters } from 'widgets/ReactDataGrid';
import { ROW_HEIGHT, HEADER_HEIGHT } from 'widgets/ReactDataGrid/utils';
import { NO_SORTER } from "../BinsView/utils";

const { Text } = cells;
const { GenericHeader, HeaderCaption } = headers;

const renderColumnHeaderCaption = (column) => {
  return (
    <HeaderCaption tooltip={column.caption}>
      {column.caption}
    </HeaderCaption>
  );
};

const renderColumnHeader = (column) => {
  return (
    <GenericHeader
      captionRenderer={renderColumnHeaderCaption(column)}
    />
  );
};

function View({ module, viewModel }) {
  const { sorterName, binName, plates, tableColumns } = viewModel;

  return (
    <div className='crtx-bin-plates-details'>
      <div className='bin-caption'>
        {sorterName === NO_SORTER ?
          <div>{translate('Unassigned Plates')}</div>
          :
          <div>{translate('Bin name:')} {binName}</div>}
      </div>
      <div className='bin-plates-table'>
        <Table
          autoHeight={false}
          rows={plates}
          columnKey='id'
          fixed={true}
          minColumnWidth={30}
          rowHeight={ROW_HEIGHT}
          headerHeight={HEADER_HEIGHT}
          virtualScroll={true}
          onSelect={selectedRows => module.handleTableSelect(selectedRows)}
          onRowContextMenu={(rowIndex, rowContent, selectedRows, e) => module.handleRowContextMenu(rowContent, selectedRows, e)}
          onColumnResize={(columns, columnKey) => module.handleColumnResize(columns, columnKey)}
        >

          {tableColumns.map(col => {
              const columnKey = col.key || col.caption;

              return <Column
                key={columnKey}
                columnKey={columnKey}
                fixed={col.fixed}
                // autoWidth={true}
                width={col.width}
                resizable={col.resizable !== false}
                title={col.caption}
                header={renderColumnHeader(col)}
                cell={col.cell || Text}
                cellDataGetter={col.cellDataGetter}
                shouldCellUpdate={col.shouldCellUpdate}
              />
            }
          )}
        </Table>

      </div>
    </div>

  );
}

View.propTypes = {
  module: PropTypes.object,
  viewModel: PropTypes.object,
};

export default View;