import { translate } from 'core/services/localization';
import { getModuleIcon } from 'core/services/iconService';

const infoIcon = getModuleIcon('InfoDialog', 'info');
const linksIcon = getModuleIcon('InfoDialog', 'link');
const colorIcon = getModuleIcon('InfoDialog', 'colors');
const sitesIcon = getModuleIcon('InfoDialog', 'sites');
const plateIcon = getModuleIcon('InfoDialog', 'plates');
const layoutIcon = getModuleIcon('InfoDialog', 'layout');
const creepIcon = getModuleIcon('InfoDialog', 'creep');
const pagesIcon = getModuleIcon('InfoDialog', 'pages');
const fanoutIcon = getModuleIcon('InfoDialog', 'fanout', '.svg');
const normalizeIcon = getModuleIcon('InfoDialog', 'normalize');

const labels = {
  info: translate('Info'),
  links: translate('Links'),
  colors: translate('Colors'),
  sites: translate('Sites'),
  plates: translate('Plates'),
  layout: translate('Layout'),
  creep: translate('Creep'),
  pages: translate('Pages'),
  ads: translate('Ads'),
  fanout: translate('Fanout'),
  fragmentsInfo: translate('Fragments Info'),
  variants: translate('Variants'),
  normalization: translate('Normalization Info'),
  nrm: translate('NRM')
};


const panelsDefinitions = {

  'Info': {
    props: {
      'object': 'object',
      'separations': 'separations',
      'links': 'links',
      'properties': 'properties',
      'skipStepsInfo': 'skipStepsInfo',
      'customProperties': 'customProperties'
    },//"propsName":"cursorInModel"
    icon: infoIcon,
    comp: require('src/components/private/info/panels/info/Info'),
    title: labels.info
  },

  'Links': {
    props: { 'links': 'links', 'model': 'model' },
    icon: linksIcon,
    comp: require('src/components/private/info/panels/links/Links'),
    title: labels.links,
    shouldAdd: (model, props) => {
      let linksFound = [];
      for (var link in props.links) {
        if (Array.isArray(props.links[link]) && props.links[link].length > 0) {
          linksFound.push(props.links[link]);
        }
      }
      if (linksFound.length > 0) {
        return true;
      }
      return false;
    }
  },

  'Colors': {
    props: { 'separations': 'separations', 'links': 'links', 'model': 'model' },
    icon: colorIcon,
    comp: require('src/components/private/info/panels/colors/Colors'),
    title: labels.colors
  },

  'Sites': {
    props: { 'sites': 'object.sites', 'contentSites': 'object.contentSites', 'extraInfo': 'object.extraInfo' },
    icon: sitesIcon,
    comp: require('src/components/private/info/panels/sites/Sites'),
    title: labels.sites,
    shouldAdd: (model, props) => {
      if (Array.isArray(props.sites) && props.sites.length > 0) {
        return true;
      }
      return false;
    }
  },

  'Plates': {
    props: { 'separations': 'separations' },
    icon: plateIcon,
    comp: require('src/components/private/info/panels/plates/Plates'),
    title: labels.plates,
    shouldAdd: (model, props) => {
      const platesFound = props.separations.filter(function (separation, index) {
        return Array.isArray(separation.plates) && separation.plates.length > 0;
      });//get press data
      const numberOfPlatesFound = props.separations.filter(function (separation, index) {
        return separation.numberOfPlates != undefined;
      });

      if ((Array.isArray(platesFound) && platesFound.length > 0) || (Array.isArray(numberOfPlatesFound) && numberOfPlatesFound.length > 0)) {
        return true;
      }
      return false;
    }
  },

  'Layout': {
    props: { 'object': 'object', 'pages': 'pages' },
    icon: layoutIcon,
    comp: require('src/components/private/info/panels/layout/Layout'),
    title: labels.layout
  },

  'Creep': {
    props: { 'creepInfo': 'creepInfo', 'object': 'object', 'pages': 'pages' },
    icon: creepIcon,
    comp: require('src/components/private/info/panels/creep/CreepInfo'),
    title: labels.creep
  },

  'Pages': {
    props: { 'object': 'object', 'pages': 'pages' },
    icon: pagesIcon,
    comp: require('src/components/private/info/panels/pages/Pages'),
    title: labels.pages
  },

  'Fanout': {
    props: { 'fanoutAndDirectInfo': 'fanoutAndDirectInfo' },
    icon: fanoutIcon,
    comp: require('src/components/private/info/panels/fanout/FanoutAndDirectInfo'),
    title: labels.fanout,
    shouldAdd: (model, props) => {
      const fanoutInfo = props.fanoutAndDirectInfo || {};
      const pressNwids = Object.keys(fanoutInfo) || [];

      return pressNwids.some(pressNwid => {
        const press = fanoutInfo[pressNwid];
        return press.correction || (press.direct.black || press.direct.cyan || press.direct.magenta || press.direct.yellow);
      });
    }
  },

  'Ads': {
    props: { 'object': 'object', 'ads': 'ads' },
    icon: pagesIcon,
    comp: require('src/components/private/info/panels/ads/Ads'),
    title: labels.ads,
    shouldAdd: (model, props) => {
      if (Array.isArray(props.ads) && props.ads.length > 0) {
        return true;
      }
      return false;
    }
  },

  'Fragments Info': {
    props: { name: 'name', breadcrumbs: 'breadcrumbs', separations: 'separations', width: 'width', height: 'height', type: 'contentType', nwid: 'nwid', actualPath: 'actualPath' },
    icon: infoIcon,
    comp: require('src/components/private/info/panels/fragmentsInfo/FragmentsInfo'),
    title: labels.info,
    shouldAdd: (model, props) => {
      if (props.type === 'AD' || props.type === 'EDITORIAL') {
        return true;
      }
      return false;
    }
  },

  'Variants': {
    props: { variants: 'variants' },
    icon: pagesIcon,
    comp: require('src/components/private/info/panels/variants/Variants'),
    title: labels.variants,
    shouldAdd: (model, props) => {
      if (Array.isArray(props.variants) && props.variants.length > 0) {
        return true;
      }
      return false;
    }
  },

  'Normalization': {
    props: { normalizationInfo: 'normalizationInfo' },
    icon: normalizeIcon,
    comp: require('src/components/private/info/panels/normalization/Normalization'),
    title: labels.normalization,
    menuTitle: labels.nrm,
    shouldAdd: (model, props) => {
      if (Array.isArray(props.normalizationInfo) && props.normalizationInfo.length > 0) {
        return true;
      }
      return false;
    }
  },

};

module.exports = panelsDefinitions;


