import React, { Component } from 'react';
import Nup from './Nup';
import sandbox from 'sandbox';

const { translate } = sandbox.localization;

class NupPanel extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    //console.log('###NupPanel.componentDidMount');
    this.initSplitter();

  }

  componentWillUnmount() {
    console.log('###NupPanel.componentWillUnmount');
  }

  initSplitter() {
    $('.edit-book').kendoSplitter({
      panes: [{}, { size: '300px', collapsible: true }]
    });
  };

  handleOkClick = (e) => {
    //console.log("###handleOkClick");
    if (this.props.onOkClick) {
      if (this.nup.validateModel()) {
        this.nup.saveAsPmiTemplate()
          .then(() => {
            const output = this.nup.getOutput();
            output.book = this.props.book;
            this.props.onOkClick(output);
          });
      }
    }
  };

  handleCancelClick = (e) => {
    //console.log("###handleCancelClick");
    if (this.props.onCancelClick) {
      const output = this.nup.getOutput();
      this.props.onCancelClick(output);
    }
  };

  setNupRef = (nup) => {
    this.nup = nup;
  };

  render() {
    const { book, onOkClick, onCancelClick, ...props } = this.props;

    return (
      <div className='n-up-panel'>
        <Nup {...props} ref={this.setNupRef} />
        <div className='footer'>
          <button onClick={this.handleOkClick}>{translate('Save & Return')}</button>
          <button onClick={this.handleCancelClick}>{translate('Discard & Return')}</button>
        </div>
      </div>
    );
  }
}

module.exports = {
  renderComponent: (props, reactRoot) => {

    reactRoot.render(<NupPanel {...props} />);
  }
};