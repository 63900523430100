/**
 * @name Delete Events
 * @fileOverview
 * @author sergey
 */

define(['sandbox', 'AbstractAction', 'logger'], function(sandbox, AbstractAction, logger) {
  'use strict';

  var log = logger.getDefaultLogger();

  return AbstractAction.extend({

    isApplicable: function (events) {
      if (typeof events === 'undefined') { return false; }
      var i = 0, len = events.length;
      if (len === 0) { return false; }
      for (i; i < len; i += 1) {
        if (events[i].type !== 'workflow/event') { return false; }
        if (events[i].flowStepType === "workflow/step/input/hotfolder" && events[i].state === "queWait") {return false;}
      }
      return true;
    },

    execute: function (events) {
      var viewInstanceNwId = this.module.nwid;
      var deviceNwId = this.module.viewSettings.rootId;
      var type = this.module.viewSettings.rootType;
      var projectorId = this.module.projectorId;
      sandbox.request.deleteEvents(viewInstanceNwId, this.nwid, deviceNwId, type, projectorId,
        {
          config: this.config,
          items: events.map(function (e) {
            return {nwid: e.nwid};
          }),
          parameters: this.parameters
        }
      );
    }
  });
});