const DEFAULT_COUNTER_COMPARATOR = (item) => {
  return true;
};

const DEFAULT_FILTER = (item) => {
  return true;
};

const counterNameComparator = (name) => {
  return (counter) => {
    return counter.name === name;
  };
};

const counterCreator = (name, title, comparator, imgSrc) => {
  return {
    name,
    title,
    comparator,
    imgSrc,
    idMaps: {},
    count: 0,
    filteredIdMaps: {},
    filteredCount: 0
  };
};

const setCountersItemsCount = (id, item, filter) => {
  const getUpdatedCounterCount = (idMaps, count, comparator, filter = DEFAULT_FILTER) => {
    const didPassComparator = comparator(item) && filter(item);
    const hasId = typeof idMaps[id] !== 'undefined';

    if (hasId && !didPassComparator) {
      delete idMaps[id];
      return count - 1;
    }

    if (!hasId && didPassComparator) {
      idMaps[id] = item;
      return count + 1;
    }

    return count;
  };

  return (counter) => {
    counter.count = getUpdatedCounterCount(counter.idMaps, counter.count, counter.comparator);
    counter.filteredCount = getUpdatedCounterCount(counter.filteredIdMaps, counter.filteredCount, counter.comparator, filter);
    //const didPassComparator = counter.comparator(item);
    //const hasId = typeof counter.idMaps[id] !== 'undefined';

    //if ( hasId && !didPassComparator ) {
    //  counter.count -= 1;
    //  delete counter.idMaps[id];
    //}
    //else if (!hasId && didPassComparator) {
    //  counter.count += 1;
    //  counter.idMaps[id] = item;
    //}
  };
};

const removeCountersItemsCount = (id) => {
  const removeCounterCount = (idMaps, count) => {
    const hasId = typeof idMaps[id] !== 'undefined';
    if (hasId) {
      delete idMaps[id];
      return count - 1;
    }
    return count;
  };

  return (counter) => {
    counter.count = removeCounterCount(counter.idMaps, counter.count);
    counter.filteredCount = removeCounterCount(counter.filteredIdMaps, counter.filteredCount);
    //const hasId = typeof counter.idMaps[id] !== 'undefined';
    //
    //if (hasId) {
    //  counter.count -= 1;
    //  delete counter.idMaps[id];
    //}
  };
};

const filterCountersItemsCount = (filter) => {
  const filterIdMaps = (idMaps, filter = DEFAULT_FILTER) => {
    return Object.keys(idMaps).reduce((filteredIdMaps, id) => {
      if (filter(idMaps[id])) {
        filteredIdMaps[id] = idMaps[id];
      }
      return filteredIdMaps;
    }, {});
  };

  return (counter) => {
    counter.filteredIdMaps = filterIdMaps(counter.idMaps, filter);
    counter.filteredCount = Object.keys(counter.filteredIdMaps).length;
  };
};

export default class Counters {

  constructor(counters = [], filter = DEFAULT_FILTER) {
    this.counters = counters;
    this.filterFunc = filter;
  }

  setFilter(filter = DEFAULT_FILTER) {
    this.filterFunc = filter;

    return this;
  }

  filter() {
    this.counters.forEach(filterCountersItemsCount(this.filterFunc));

    return this;
  }

  hasCounter(name) {
    return this.counters.filter(counterNameComparator(name)).length > 0;
  }

  getCounters() {
    return this.counters;
  }

  getCounterIndex(name) {
    return this.counters.findIndex(counterNameComparator(name));
  }

  getCounter(name) {
    return this.counters.find(counterNameComparator(name));
  }

  createCounter(name, title, comparator = DEFAULT_COUNTER_COMPARATOR, imgSrc) {
    if (typeof name === 'undefined') {
      if (console && console.log) console.log('Counter must have a name.');
      return this;
    }

    if (this.hasCounter(name)) {
      if (console && console.log) console.log('Counters already has a counter with the name: ', name);
      return this;
    }

    this.counters.push(counterCreator(name, title, comparator, imgSrc));

    return this;
  }

  removeCounter(name) {
    if (typeof name === 'undefined') {
      if (console && console.log) console.log('Counter must have a name.');
      return this;
    }

    this.counters.splice(this.getCounterIndex.call(this, name), 1);

    return this;
  }

  setItem(id, item) {
    this.counters.forEach(setCountersItemsCount.call(this, id, item, this.filterFunc));

    return this;
  }

  removeItem(id) {
    this.counters.forEach(removeCountersItemsCount.call(this, id));

    return this;
  }

};