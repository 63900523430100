/**
 * Created by tzvikas on 4/19/2015.
 */
/**
 * Created with JetBrains WebStorm.
 * User: tzvikas
 * Date: 4/12/15
 * Time: 9:43 AM
 * To change this template use File | Settings | File Templates.
 */

define(['jquery', 'react-dom', 'sandbox/planSetup', './../../../utils/wizardUtils', "./colorAction", "./shareAction",
    "./structureActions", "./panoramaAction"],
  function($ , ReactDOM, PlanSetup, wizardUtils, colorAction, shareAction, structureActions,
           panoramaActions){
    'use strict';

    //////////////////////////////////// TOOLBAR HANDLING  /
    function updateToolbar(){
      //this.toolbar.setState({singleSelection:this.selectedPages.length === 1})
      //this.toolbar.setState({panoramaState:panoramaActions.isValid.call(this)})
      //if (this.selectedPages.length === 1){
      //  var page = this.selectedPages[0].props.page;
      //  var numInserts = page.numInserts !== null && typeof page.numInserts !== "undefined" ? page.numInserts : 0;
      //  this.toolbar.setState({numInserts:numInserts});
      //}
    }

    ///////////////////////////////////////////////////////
    function isInList(list, item){
      for (var i=0;i<list.length;i++){
        if (list[i] === item){
          return i;
        }
      }
      return -1;
    }

    function colorModeChange(mode){
      this.colorMode = mode;
      this.shareMode = null;
    }

    function shareModeChange(mode){
      this.shareMode = mode;
      this.colorMode = null;
    }

    function findIndexInRows(stepModel, selectedSection, page, myZone){
      var stepSection = null;
      for (var i=0;i<stepModel.sections.length;i++){
        if (stepModel.sections[i].displayName === selectedSection.displayName){
          stepSection = stepModel.sections[i];
          break;
        }
      }
      if (stepSection !== null){
        var zonedPages = stepSection.zonedPages;
        for (var i=0;i<zonedPages.length;i++){
          var zoneMapPage = zonedPages[i].zoneMap[myZone];
          if (zoneMapPage && zoneMapPage.sourceId === page.sourceId){
            return i;
          }
        }
      }
      return -1;
    }

    function doException(baseAction, value, state){
      if (baseAction === "nwxLocal"){
        for (var i=0;i<this.selectedPages.length;i++){
          //var page = this.selectedPages[i].props.page;
          var page = this.selectedPages[i];
          page.nwxLocal = value;
        }
      } else {
        var pages = filterSelectedLinks.call(this);
        var isText = baseAction.indexOf("Text") > -1;
        var actions = [baseAction];
        if (isText && !value){
          actions = [baseAction, baseAction.substring(0, 4)]
        } else if (!isText){
          actions = [baseAction, baseAction + "Text"];
        }      
        for (var i=0;i<pages.length;i++){
          actions.forEach(function (action) {
            var page = pages[i];
            if ((!page[action] && value) || (page[action] && page[action] !== value)){
              if (!value){
                state.killInfo.exceptionPages--;
              } else {
                state.killInfo.exceptionPages++;
              }
            }
            
            page[action] = value;  
          });
          console.log(state.killInfo.exceptionPages);
              
        }
      }
      
      // if (state.killInfo.exceptionPages === 0){
      //   state.killInfo.exceptionType = "";
      // }
    }

    function collectPages(stepModel, selectedSection, myZone, myIndex, lastSelectedItemIndex){
      var start = Math.min(myIndex, lastSelectedItemIndex);
      var end = Math.max(myIndex, lastSelectedItemIndex);
      var stepSection = null;
      for (var i=0;i<stepModel.sections.length;i++){
        if (stepModel.sections[i].displayName === selectedSection.displayName){
          stepSection = stepModel.sections[i];
          break;
        }
      }
      var pages = [];
      if (stepSection !== null){
        var zonedPages = stepSection.zonedPages;
        for (var i=start;i<=end;i++){
          var zoneMapPage = zonedPages[i].zoneMap[myZone];
          if (zoneMapPage){
            pages.push(zoneMapPage);
          }          
        }
      }
      return pages;
    }

    function selectionDone(event, page, reactComp){

      var mySection = this.modelHandler.pagesPerSection[page.sourceId];
      if (this.selectedSection === null){
        this.selectedSection = mySection;
      }
      if (this.selectedSection !== mySection){
        clearSelection.call(this);
        reactComp.props.sectionsChangeListener();
      }
      this.selectedSection = mySection;

      if (event.ctrlKey || event.metaKey || event.shiftKey){
        var exist = clearSelectedComp.call(this, page);
        if (exist)
          return;
      }


      var myZone = this.findZone.call(this, this.modelHandler.pagesPerRow[page.sourceId], page);
      var myIndex = findIndexInRows(this.modelHandler.stepModel, this.selectedSection, page, myZone);
      var shiftKey = event.shiftKey;
      var pages = [page];
      if (shiftKey){
        var lastZone = this.lastSelectedItemZone;
        shiftKey = myZone === lastZone;

        if (shiftKey){
          pages = collectPages(this.modelHandler.stepModel, this.selectedSection, myZone, myIndex, this.lastSelectedItemIndex);
        }

      }
      if (!(event.ctrlKey || event.metaKey || shiftKey) && !this.rowAction){
        clearSelection.call(this);
      }

      for (var i=0;i<pages.length;i++){
        pages[i].selectedState = true;
        if (isInList(this.selectedPages, pages[i]) === -1)
          this.selectedPages.push(pages[i]);
      }


      this.lastSelectedItem = page;
      this.lastSelectedItemZone = myZone;
      this.lastSelectedItemIndex = myIndex;
    }

    function hidePropertiesDialog(){
      var dialog = this.nukDialog ? this.nukDialog : this.propertiesDialog;      
      if (dialog.state.display === 'none' )
        return;
      dialog.setState({display : 'none', x: "0", y: "0", customProps:[]});
    }

    function hideCustomColorDialog(){
      if (!this.customColorDialog){
        return;
      }
      if (this.customColorDialog.state.display === 'none' )
        return;
      this.customColorDialog.setState({display : 'none', x: "0", y: "0"});
    }

    function hideAdvanceSharingDialog(){
      if (!this.advanceSharingDialog){
        return;
      }
      if (this.advanceSharingDialog.state.display === 'none' )
        return;
        this.advanceSharingDialog.setState({display : 'none', x: "0", y: "0"});
    }

    function getDialogInfo(){
      var panoramaApplicable = panoramaActions.isApplicableForPanorama.call(this);
      var insertsApplicable = structureActions.isApplicableForInserts.call(this);
      var isPanorama = panoramaActions.isPanorama.call(this);
      var splitApplicable = structureActions.isApplicableForSplits.call(this);
      var dinkyState = structureActions.isApplicableForDinky.call(this);

      for (var i=0;i<this.selectedPages.length;i++) {
        var page = this.selectedPages[i];
        var section = this.modelHandler.pagesPerSection[page.sourceId];
        var zones = section.zones;
        var zone = "";
        for (var j=0;j<zones.length;j++){
          if (zones[j].code === page.myZone){
            zone = zones[j].title;
          }
        }        
        break;
      }

      var defEd = "Ed", defAd = "Ad";
      if (this.approversTableData && this.approversTableData[zone + "/" + section.displayName + "/editorial"]) {
        defEd = this.approversTableData[zone + "/" + section.displayName + "/editorial"];
      }
      if (this.approversTableData && this.approversTableData[zone + "/" + section.displayName + "/ad"]) {
        defAd = this.approversTableData[zone + "/" + section.displayName + "/ad"];
      }
      var props = this.stepProperties.customProperties;
      if (!props){
        props = [{name: "ED", title : "Editorial(" + defEd + ")"},
          {name: "AD", title : "Ad(" + defAd + ")"}, {name: "CO", title : "Cover(CO)"},
          {name: "HP", title : "Hard Proof(HP)"},
          {name: "CA", title : "customA(CA)"},
          {name: "CB", title : "customB(CB)"}]
      }


      
     
      var ret = {customProps: props, panoramaApplicable:panoramaApplicable,
        hideInserts: this.stepProperties.hideInserts,
        showDinky: this.stepProperties.showDinky,
        insertsApplicable:insertsApplicable,
        isPanorama:isPanorama,
        isDinky: dinkyState,
        splitApplicable: splitApplicable > -1,
        isSplit: (splitApplicable === 1),
        killInfo: this.selectedSection ? this.selectedSection.killInfo : null,
        singleSelection:this.selectedPages.length === 1};
      structureActions.addCustomPropsValues.call(this, ret, props);
      return ret;
    }

    function calcDialogPosition (dialogDomNode, event){
      var popupWidth  = parseInt(dialogDomNode.style.width, 10);// 200
      var popupHeight =  parseInt(dialogDomNode.style.height, 10);//300;

      var x = event.target.offsetLeft;
      var y = event.target.offsetTop;
      if (y + popupHeight > this.modelHandler.container[0].scrollHeight) {
        y = y - popupHeight;
      }

      if (x + popupWidth > this.modelHandler.container[0].scrollWidth) {
        x = x - popupWidth;
      }
      return {x: x, y: y};
    }

    function showCustomColorDialog(event, page){
      var position = calcDialogPosition.call(this, ReactDOM.findDOMNode(this.customColorDialog), event);
      var dialogInfo = colorAction.getColors.call(this);
      dialogInfo.display = 'inline';
      dialogInfo.x = position.x;
      dialogInfo.y = position.y + 10;
      this.customColorDialog.setState(dialogInfo);
    }

    function showNukAdvanceSharingDialog(event, page){
      var position = calcDialogPosition.call(this, ReactDOM.findDOMNode(this.nukDialog), event);
      var dialogInfo = shareAction.getShareMode.call(this);
      var propsDialogInfo = getDialogInfo.call(this);
      Object.assign (dialogInfo, propsDialogInfo);
      dialogInfo.display = 'inline';
      dialogInfo.x = position.x;
      dialogInfo.y = position.y + 10;
      var exceptionType = this.nukDialog.state.exceptionType || dialogInfo.killInfo.exceptionType;
      dialogInfo.killInfo.exceptionType = exceptionType;
      var exceptions = ["slip", "slipText", "kill", "killText"];
      var pages = filterSelectedLinks.call(this);
      var selectedPage = getSingleSelectedPage.call(this);
      for (var i=0;i<exceptions.length;i++){
        dialogInfo[exceptions[i]] = !dialogInfo[exceptions[i]];
        for (var j=0;j<pages.length;j++){
          var page = pages[j];
          dialogInfo[exceptions[i]] = page[exceptions[i]];       
        }
      }
      dialogInfo.nwxLocal = page.nwxLocal;
      dialogInfo.isMaster = pages.length > 0;
      dialogInfo.clearSelected = true;
      this.nukDialog.setState(dialogInfo);
    }
    
    function showAdvanceSharingDialog(event, page){
      var position = calcDialogPosition.call(this, ReactDOM.findDOMNode(this.advanceSharingDialog), event);
      var dialogInfo = shareAction.getShareMode.call(this);
      dialogInfo.display = 'inline';
      dialogInfo.x = position.x;
      dialogInfo.y = position.y + 10;
      this.advanceSharingDialog.setState(dialogInfo);
    }

    function showPropertiesDialog(event, page, fireChangeFunction){

      var position = calcDialogPosition.call(this, ReactDOM.findDOMNode(this.propertiesDialog), event);

      var dialogInfo = getDialogInfo.call(this);
      dialogInfo.display = 'inline';
      dialogInfo.x = position.x;
      dialogInfo.y = position.y;
      this.propertiesDialog.setState(dialogInfo);


    }

    /*

     Public Functions

     */
    function clearSelectedComp(page) {
      for (var i=this.selectedPages.length-1;i>=0;i--){
        var existPage = this.selectedPages[i];
        if (existPage.sourceId === page.sourceId){
          this.selectedPages[i].selectedState = false;//({selected:false});
          this.selectedPages.splice(i, 1);
          return true;
        }
      }
      return false;
    }

    function clearAllButSelectedComp(page) {
      var exists = false;
      for (var i=this.selectedPages.length-1;i>=0;i--){
        var existPage = this.selectedPages[i];
        if (existPage.sourceId === page.sourceId){
          exists = true;
        } else {
          this.selectedPages[i].selectedState = false;//({selected:false});
          this.selectedPages.splice(i, 1);
        }
      }
      return exists;
    }

    function setSelection(items){
      clearSelection.call(this);
      for (var i=0;i<items.length;i++){
        //items[i].setState({selected:true});
        items[i].selectedState = true;
        if (isInList(this.selectedPages, items[i]) === -1)
          this.selectedPages.push(items[i]);
      }
    }

    function clearSelection(){
      for (var i=0;i<this.selectedPages.length;i++){
        //this.selectedPages[i].setState({selected:false});
        this.selectedPages[i].selectedState = false;
      }
      this.lastSelectedItem = null;
      this.lastSelectedItemZone = null;
      this.lastSelectedItemIndex = -1;
      this.selectedPages = [];
    }

    function findAllCommonPages(row, sourceId){
      var zoneMapKeys = Object.keys(row);
      var ret = [];
      var masterPageObj = null;
      for (var i=0;i<zoneMapKeys.length;i++){
        if (row[zoneMapKeys[i]].masterPage === sourceId){
          ret.push(row[zoneMapKeys[i]]);
        } else if (row[zoneMapKeys[i]].sourceId === sourceId){
          masterPageObj = row[zoneMapKeys[i]];
        }
      }

      if (masterPageObj === null){
        return ret;
      } else if (masterPageObj.links === null || typeof masterPageObj.links === 'undefined'){
        masterPageObj.links = [];
      }
      var that = this;
      masterPageObj.links.forEach(function (link) {
        var map = that.modelHandler.pagesPerRow;
        var rowData = map[link];
        var zoneMapKeys = Object.keys(rowData);
        for (var i=0;i<zoneMapKeys.length;i++) {
          if (rowData[zoneMapKeys[i]].sourceId === link) {
            if (ret.indexOf(rowData[zoneMapKeys[i]]) === -1){
              ret.push(rowData[zoneMapKeys[i]]);
            }
            break;
          }
        }
      });


      return ret;
    }

    function findZone (rowData, page){
      if (page === null || typeof page === 'undefined'){
        return null;
      }
      var zoneMapKeys = Object.keys(rowData);
      for (var i=0;i<zoneMapKeys.length;i++) {
        if (rowData[zoneMapKeys[i]].sourceId === page.sourceId) {
          return zoneMapKeys[i];
        }
      }
      return null;
    }

    function findFollowers (page, basePage, followers){
      if (!followers){
        followers = [];
        basePage = page;
      }
      if (page.followers){
        for (var i=0;i<page.followers.length;i++){
          var followerSourceId = page.followers[i];
          var follower = this.modelHandler.mappedModel[followerSourceId];
          if (page.masterPage === follower.sourceId){
            continue;
          }
          if (isMyFollower(follower, basePage))
            followers.push(follower);
          findFollowers.call(this, follower, basePage, followers);
        }
      }
      return followers;
    }

    function isMyFollower(follower, page){
      if (!follower.masterPage){
        return false;
      }
      return follower.masterPage === page.sourceId || follower.masterPage === page.masterPage;
    }

    function findMasterContentPage(page){
      if (!page.masterPage){
        return null;
      }
      return this.modelHandler.mappedModel[page.masterPage];
    }

    function findPotentialFollowers (rowData, baseZone){
      var zoneMapKeys = Object.keys(rowData);
      var ret = [];
      for (var i=0;i<zoneMapKeys.length;i++) {
        var masterZone = rowData[zoneMapKeys[i]].masterZone;
        if (zoneMapKeys[i] === baseZone || masterZone === null || typeof masterZone === 'undefined'){
          // page is local
          continue;
        }



        var currentZone = zoneMapKeys[i];
        while (masterZone !== null && typeof masterZone !== "undefined"){
          masterZone = this.modelHandler.zonesBaseZonesData[currentZone];
          if (masterZone === baseZone){
            ret.push(rowData[zoneMapKeys[i]]);
            break;
          }
          currentZone = masterZone;
        }
      }
      return ret;
    }

    
    function getTitle (page){
      if (this.modelHandler.namingPattern.length > 0){
        return wizardUtils.resolveNaming(page, this.modelHandler.namingPattern, this.modelHandler.fullMappedModel);
      }
      var continueNumbers = this.modelHandler.continueNumbers;
      //var title = continueNumbers ? page.paginationInZone :
      //(page.sectionCode !== null && typeof page.sectionCode !== 'undefined') ? page.sectionCode + page.pagination : page.pagination;
      var title = continueNumbers ? page.number : page.sectionCode + page.number;
      title = continueNumbers ? page.pagination : page.sectionCode + page.pagination;
      var insertChar = page.insertChar;
      if (insertChar !== null && typeof insertChar !== "undefined"){
        title = continueNumbers ? page.pagination : page.sectionCode + page.pagination;
        title += insertChar;
      }
      var splitChar = page.split;
      if (splitChar !== null && typeof splitChar !== "undefined"){
        title += splitChar;
      }
      var virtualTitle = page.virtualTitle;
      if (virtualTitle !== null && typeof virtualTitle !== "undefined"){
        title = title + "-" + virtualTitle
      }
      return title;
    }

    function sharePage(directMasterPage, follower, sepsToLink) {
      shareAction.updatePageMasters.call(this, directMasterPage, follower, sepsToLink);
    }

    function makeLocal(page){
      shareAction.makeLocal.call(this, page);
    }

    function filterSelectedLinks(){
      var ret = [];
      for (var i=0;i<this.selectedPages.length;i++){
        //var page = this.selectedPages[i].props.page;
        var page = this.selectedPages[i];
        if (page.masterPage === null || typeof page.masterPage === "undefined") {
          ret.push(this.selectedPages[i]);
        }
      }
      return ret;
    }

    function getSingleSelectedPage(){      
      for (var i=0;i<this.selectedPages.length;i++){
        //var page = this.selectedPages[i].props.page;
        var page = this.selectedPages[i];
        return page;
      }
      return null;
    }

    function findMasterPage (page){
      if (page === null){
        for (var i=0;i<this.selectedPages.length;i++) {
          page = this.selectedPages[i];
          //page = this.selectedPages[i].props.page;
          break;
        }
      }
      if (page === null){
        return null;
      }
      var masterZone = page.masterZone;
      if (!masterZone){
        return null;
      }

      var mySection = page.masterSection || page.mySection;
      var key = masterZone + ":" + mySection;
      var mapPages = this.modelHandler.mapPages[key];
      for (var i=0;i<mapPages.length;i++){
        var o = this.modelHandler.mappedModel[mapPages[i].sourceId];
        if (o.masterPage === page.masterPage || page.masterPage === o.sourceId){
          return o;
        } else {
          for (var sepName in o.separations) {

            if (page.separations[sepName] && o.separations[sepName].sourceId === page.separations[sepName].masterSeparation){
              return o;
            }

          }
        }
      }
      return null;
    }

    function getSelectedPage(selectedZone, pagesMap){
      for (var i=0;i<this.selectedPages.length;i++){
        //var page = this.selectedPages[i].props.page;
        var page = this.selectedPages[i];
        var mySection = this.modelHandler.pagesPerSection[page.sourceId];
        var key = selectedZone + ":" + mySection.displayName;
        for (var j=0;j<pagesMap[key].length;j++){
          if (pagesMap[key][j].title === page.title){
            return pagesMap[key][j].sourceId;
          }
        }
        break;
      }
      return null;
    }

    function getSectionOptionsForSelectedPage(selectedZone, pagesMap){
      var ret = [], masterSourceId = null;
      var mappedModel = this.modelHandler.mappedModel;
      for (var i=0;i<this.selectedPages.length;i++){
        //var page = this.selectedPages[i].props.page;
        var page = this.selectedPages[i];
        var mySection = this.modelHandler.pagesPerSection[page.sourceId];
        var masterPage = findMasterPage.call(this, page);
        if (masterPage){
          masterSourceId = masterPage.sourceId;
        } else {
          for (var sepName in page.separations) {
            var masterSeparation = page.separations[sepName].masterSeparation;
            if (masterSeparation !== null && typeof masterSeparation !== 'undefined') {
              console.log(page.separations[sepName]);
              var masterZone = page.separations[sepName].masterZone;
              var optionalPages = this.modelHandler.mapPages[masterZone + ":" + mySection.displayName];
              for (var o=0;i<optionalPages.length;i++){
                var optPage = this.modelHandler.mappedModel[optionalPages[o].sourceId];
                for (var optSepName in optPage.separations) {
                  if (masterSeparation === optPage.separations[optSepName].sourceId){
                    masterSourceId = optPage.sourceId;
                    break;
                  }
                }
                break;
              }
              break;
            }
          }
        }

        var key = selectedZone + ":" + mySection.displayName;
        pagesMap[key].forEach(function (p) {
          var tempPage = mappedModel[p.sourceId];
          var dsbl = tempPage.adsKey !== page.adsKey;
          var obj = {title:p.title, sourceId:p.sourceId, selected:p.sourceId === masterSourceId, disabled: dsbl};
          ret.push(obj);
        });

      }
      return ret;
    }

    function getZonesOptionsForSelectedPage(selectedZone, isNew = false){
      if (isNew){
        return getZonesOptionsForSelectedPageNew.call(this, selectedZone);
      }
      var ret = [{title:"---"}];
      for (var i=0;i<this.selectedPages.length;i++){
        //var page = this.selectedPages[i].props.page;
        var page = this.selectedPages[i];
        if (!selectedZone){
          var masterPage = findMasterPage.call(this, page);
          if (masterPage){
            selectedZone = findZone.call(this, this.modelHandler.pagesPerRow[masterPage.sourceId], masterPage);
          } else {
            for (var sepName in page.separations){
              var masterSeparation = page.separations[sepName].masterSeparation;
              if (masterSeparation !== null && typeof masterSeparation !== 'undefined') {
                selectedZone = page.separations[sepName].masterZone;
                break;
              }
            }

          }
        }
        var mySection = this.modelHandler.pagesPerSection[page.sourceId];
        if (typeof mySection !== "undefined" && mySection !== null){
          var rowData = this.modelHandler.pagesPerRow[page.sourceId];
          var myZone = findZone.call(this, rowData, page);
          var zones = mySection.zones;
          for (var j=0;j<zones.length;j++){
            if (myZone !== zones[j].displayName){
              ret.push({title:zones[j].displayName, selected:selectedZone === zones[j].displayName});
            }
          }
        }
        break;
      }
      return ret;
    }

    function getPotentialSelectedObject(type, zoneName, sectionName, pageName){
      var originalStructure = JSON.parse(JSON.stringify(this.modelHandler.originalStructure));
      var adsKey = "";
      if (type === "page"){
        for (var i=0;i<this.selectedPages.length;i++){
          //var page = this.selectedPages[i].props.page;
          var page = this.selectedPages[i];
          adsKey = page.adsKey;
          if (!pageName){
            pageName =page.title;
          }          
          break;
        }
      } else if (type === "section" && !sectionName){
        for (var i=0;i<this.selectedPages.length;i++){
          //var page = this.selectedPages[i].props.page;
          var page = this.selectedPages[i];
          sectionName = this.modelHandler.pagesPerSection[page.sourceId].displayName;
          break;
        }
      }

      for (var k=0;k<originalStructure.length;k++){
        if (originalStructure[k].title === zoneName){
          if (type === "zone"){
            return originalStructure[k];
          }         
          var sections = originalStructure[k].sections;
          for (var s=0;s<sections.length;s++){
            if (sections[s].title === sectionName){             
              if (type === "section"){
                return sections[s];
              }
              var pages = sections[s].pages;
              for (var p=0;p<pages.length;p++){               
                if (pages[p].title === pageName && pages[p].adsKey === adsKey){
                  return pages[p];
                }
              }                 
            }            
          }                  
        }        
      }

      return null;
    }

    function isValidPageForShare(selectedPage){
      for (var i=0;i<this.selectedPages.length;i++){
        //var page = this.selectedPages[i].props.page;
        var page = this.selectedPages[i];
        return page.adsKey === selectedPage.adsKey && !page.virtual;
      }
    }

    function getZonesOptionsForSelectedPageNew(selectedZoneObj, selectedSectionObj){
      var selectedZone = selectedZoneObj ? selectedZoneObj.title : null;
      var ret = [{title:"---"}];
      for (var i=0;i<this.selectedPages.length;i++){
        //var page = this.selectedPages[i].props.page;
        var page = this.selectedPages[i];


        var masterPage = findMasterPage.call(this, page);
        var isLocal = !masterPage;
        if (masterPage){
          selectedZone = !selectedZone ? findZone.call(this, this.modelHandler.pagesPerRow[masterPage.sourceId], masterPage) : selectedZone;
        } else {
          for (var sepName in page.separations){
            var masterSeparation = page.separations[sepName].masterSeparation;
            if (masterSeparation !== null && typeof masterSeparation !== 'undefined') {
              selectedZone = !selectedZone ? page.separations[sepName].masterZone : selectedZone;
              isLocal = false;
              break;
            }
          }

        }
        
        var masterTitle = masterPage ? masterPage.title : page.title;
        var mySection = "---";
        if (selectedSectionObj){
          mySection = selectedSectionObj;
        } else if (masterPage) {
          mySection = this.modelHandler.pagesPerSection[masterPage.sourceId];
        } else {
          mySection = this.modelHandler.pagesPerSection[page.sourceId];
        }
        //var mySection = isLocal && selectedSectionObj? selectedSectionObj.title : this.modelHandler.pagesPerSection[masterPage ? masterPage.sourceId : page.sourceId];
        if (typeof mySection !== "undefined" && mySection !== null){
          var rowData = this.modelHandler.pagesPerRow[page.sourceId];
          var myZone = findZone.call(this, rowData, page);
          
          var originalStructure = JSON.parse(JSON.stringify(this.modelHandler.originalStructure));
          originalStructure.unshift({ title: "---", sourceId: "", sections:[]});
          var fromNowDisabled = false, fromNowVirtual = false;
          for (var k=0;k<originalStructure.length;k++){             
             if (fromNowVirtual || originalStructure[k].title === myZone){
              originalStructure[k].virtual = true;
              fromNowDisabled = true;
              fromNowVirtual = true;
             } else if (selectedZone === originalStructure[k].title){
              originalStructure[k].selected = true;
              var sections = originalStructure[k].sections;              
              for (var s=0;s<sections.length;s++){                
                if (sections[s].title === mySection.displayName){
                  sections[s].selected = true;
                  var pages = sections[s].pages;
                  for (var p=0;p<pages.length;p++){
                    pages[p].disabled = fromNowDisabled || pages[p].adsKey !== page.adsKey || pages[p].virtual;
                    if (pages[p].title === masterTitle && pages[p].adsKey === page.adsKey){
                      pages[p].selected = true;                      
                    }
                  } 
                  sections[s].pages.unshift({ title: "---", sourceId: ""});  
                }
              }
              originalStructure[k].sections.unshift({ title: "---", sourceId: "", pages:[]}); 
             }
          }
          return originalStructure;
          


          // var zones = mySection.zones;
          // for (var j=0;j<zones.length;j++){
          //   if (myZone !== zones[j].displayName){
          //     var z = {title:zones[j].displayName, selected:selectedZone === zones[j].displayName};
          //     var originalModel = this.modelHandler.stepModel.originalModel;
          //     for (var k=0;k<originalModel.length;k++){
          //       if (originalModel[k].title === z.title){
          //         z.sections = 
          //       }
          //     }
          //     ret.push(z);
          //   }
          // }
        }
        break;
      }
      return ret;
    }

    function addFollower(master, follower){
      if (!master.followers){
        master.followers = [];
      }

      for (var i=0;i<master.followers.length;i++){
        if (master.followers[i] === follower.sourceId){
          return;
        }
      }
      master.followers.push(follower.sourceId);
    }

    function removeFollower(master, follower){
      if (!master.followers){
        return;
      }
      var followers = [];
      for (var i=0;i<master.followers.length;i++){
        if (master.followers[i] !== follower.sourceId){
          followers.push(master.followers[i]);
        }
      }
      master.followers = followers;
    }

    function setPanorama(pano, virtual){
      panoramaActions.setPanorama.call(this, pano, virtual);
    }
    
    return {
      modelHandler : null,

      selectedSection: null,

      selectedPages : [],

      toolbar: null,

      rowAction: false,

      approversTableData : {},

      stepProperties: {
        customProperties: [{name: "ED", title : "Editorial(Ed)"},
          {name: "AD", title : "Ad"}, {name: "CO", title : "Cover(CO)"},
          {name: "HP", title : "Hard Proof(HP)"},
          {name: "CA", title : "customA(CA)"},
          {name: "CB", title : "customB(CB)"}],
        hideInserts: false,
        showDinky: false
      },


      actionController : function (event, action, args){
        var key = "";
        var changedObjects = [];
        var prevSelectionToKeep = [];
        if (action === "findZone") {
          var rowData = this.modelHandler.pagesPerRow[args[0].sourceId];
          return findZone.call(this, rowData, args[0]);
        } else if (action === "colorChangeAction") {
          changedObjects = colorAction.pageColorActionController.call(this, args[0]);
          key = "colorChangeFlag";
        } else if (action === "shareAction") {
          changedObjects.push(shareAction.pageShareActionController.call(this, args[0]));
          key = "sharingChangeFlag";
        } else if (action === "colorShareAction") {
          changedObjects = shareAction.pageShareActionController.call(this, args[0], args[1]);
          key = "sharingChangeFlag";
        } else if (action === "selectionDone") {
          selectionDone.call(this, event, args[0], args[1]);
        } else if (action === "rowAction") {
          if (args[1])
            this.clearSelection();
          this.rowAction = args[0];
        } else if (action === "PropertiesDialogInit") {
          this.propertiesDialog = args[0];
        } else if (action === "CustomColorDialogInit"){
          this.customColorDialog = args[0];
        } else if (action === "NukDialogInit"){
          this.nukDialog = args[0];          
        } else if (action === "AdvanceSharingDialogInit") {
          this.advanceSharingDialog = args[0];
        } else if (action === "ShowPropertiesDialog") {
          showPropertiesDialog.call(this, event, args[0]);
        } else if (action === "hideAllDialogs"){ 
          hidePropertiesDialog.call(this);
          hideCustomColorDialog.call(this);         
          hideAdvanceSharingDialog.call(this);
        } else if (action === "hidePropertiesDialog"){
          hidePropertiesDialog.call(this);
        } else if (action === "ShowCustomColorDialog"){
          showCustomColorDialog.call(this, event, args[0]);
        } else if (action === "hideCustomColorDialog") {
          hideCustomColorDialog.call(this);
        } else if (action === "ShowAdvanceSharingDialog"){
          showAdvanceSharingDialog.call(this, event, args[0]);
        } else if (action === "ShowNukAdvanceSharingDialog"){
          showNukAdvanceSharingDialog.call(this, event, args[0]);
        } else if (action === "hideAdvanceSharingDialog"){
          hideAdvanceSharingDialog.call(this);
        } else if (action === "panoramaAction"){
          changedObjects = (panoramaActions.panoramaActionController.call(this, args[1]));
          key = "panoramaAction";
        } else if (action === "doException"){
          changedObjects = (doException.call(this, args[0], args[1], args[2]));
          key = "doException";  
        } else if (action === "insertAction") {
          prevSelectionToKeep = this.selectedPages;
          changedObjects = structureActions.insertPageActionController.call(this, args[1]);
          key = "insertPagesFlag";
        } else if (action === "splitPage") {
          changedObjects = (structureActions.splitPageActionController.call(this, args[0]));
          key = "splitPageFlag";
          this.clearSelection();
        } else if (action === "dinkyPage") {
          changedObjects = (structureActions.dinkyPageActionController.call(this, args[0]));
          key = "splitPageFlag";
          this.clearSelection();
        } else if (action === "customColorChange"){
          changedObjects = colorAction.changeColor.call(this, args[0], args[1]);
          key = "colorChangeFlag";
        } else if (action === "customPropertyChange"){
          changedObjects = structureActions.changeProperty.call(this, args[0], args[1]);
          key = "customPropertyChange";
        } else if (action === "cleanUi"){
          hidePropertiesDialog.call(this);
          hideCustomColorDialog.call(this);
          hideAdvanceSharingDialog.call(this);
        } else if (action === "jumpShareAction"){
          changedObjects.push(shareAction.pageJumpShareActionController.call(this, args[0], args[1], args[2]));
          key = "sharingChangeFlag";
        } else if (action === "jumpShareActionNew"){
          changedObjects.push(shareAction.pageJumpShareActionControllerNew.call(this, args[0], args[1], args[2], args[3]));
          key = "sharingChangeFlag";
        } else if (action === "getPotentialSelectedObject"){
            return getPotentialSelectedObject.call(this, args[0], args[1], args[2], args[3]);            
        } else if (action === "getZonesOptionsForSelectedPage"){
          return getZonesOptionsForSelectedPage.call(this, args[0], args.length >= 2 ? args[1] : false);
        } else if (action === "getZonesOptionsForSelectedPageNew"){
          return getZonesOptionsForSelectedPageNew.call(this, args[0], args[1]);
        } else if (action === "getSectionOptionsForSelectedPage") {
          return getSectionOptionsForSelectedPage.call(this, args[0], args[1]);
        } else if (action === "getMasterPage") {
          return findMasterPage.call(this, args[0]);
        } else if (action === "getSelectedPage") {
          return getSelectedPage.call(this, args[0], args[1]);
        } else if (action === "isSingleSelection") {
          return this.selectedPages.length === 1;
        } else if (action === "hasDefaultMaster") {
          return shareAction.hasDefaultMaster.call(this);
        } else if (action === "isValidPageForShare"){
          return isValidPageForShare.call(this, args[0]);
        } else if (action === "chaseColorShare"){
          changedObjects.push(shareAction.chaseColorShare.call(this, args[0]));
          key = "sharingChangeFlag";
        } else if (action === "useCode"){
            return this.modelHandler.useCode;
          }

        if (prevSelectionToKeep.length > 0)
          setSelection.call(this, prevSelectionToKeep);
        return {changeKey:key, cells:changedObjects};
      },



      findAllCommonPages: findAllCommonPages,

      findPotentialFollowers: findPotentialFollowers,

      findZone: findZone,

      getTitle: getTitle,

      sharePage: sharePage,

      makeLocal: makeLocal,

      filterSelectedLinks: filterSelectedLinks,

      clearSelection: clearSelection,

      findMasterPage: findMasterPage,

      findFollowers : findFollowers,

      addFollower : addFollower,

      removeFollower : removeFollower,

      setPanorama : setPanorama

    };
  });

