import React, {Component} from 'react';
import PropTypes from 'prop-types';

const emptyFunction = () => { };
const ctrlKey = 17, ctrlKey2 = 91, vKey = 86, cKey = 67, zKey = 90;

class CustomPasswordInput extends Component {
  constructor(props) {
    super(props);
  }
  static propTypes = {
    value: PropTypes.any,
    maskChar: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    onChange: PropTypes.func,
    onInput: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
  }

  static defaultProps = {
    value: '',
    maskChar: '●',
    disabled: false,
    onFocus: emptyFunction,
    onChange: emptyFunction,
    onInput: emptyFunction
  }

  selectionStart = undefined;

  selectionEnd = undefined;

  handleSelect = event => {
    this.selectionStart = event.target.selectionStart;
    this.selectionEnd = event.target.selectionEnd;
  };

  handleKeyDown = event => {
    this.selectionStart = event.target.selectionStart;
    this.selectionEnd = event.target.selectionEnd;

    if ((event.ctrlKey || event.metaKey) && (event.keyCode === zKey || event.keyCode === cKey)) {
      event.preventDefault();
    }
  };

  handleKeyUp = event => {
    this.selectionStart = event.target.selectionStart;
    this.selectionEnd = event.target.selectionEnd;
  };

  handleClick = event => {
    this.selectionStart = event.target.selectionStart;
    this.selectionEnd = event.target.selectionEnd;
  };

  handleFocus = event => {
    const {onFocus} = this.props;

    this.selectionStart = event.target.selectionStart;
    this.selectionEnd = event.target.selectionEnd;

    onFocus(event);
  };

  getValue = (newValue, value, selectionStart, selectionEnd, newSelectionStart, newSelectionEnd) => {
    const fromStart = Math.min(selectionStart, newSelectionStart);

    return value.substring(0, fromStart) +
      newValue.substring(fromStart, newSelectionEnd) +
      value.substring(selectionEnd, selectionEnd + (newValue.length - newSelectionEnd));
  };


  handleInput = event => {
    const {value, onInput, onChange} = this.props;
    const newValue = this.getValue(event.target.value, value, this.selectionStart, this.selectionEnd, event.target.selectionStart, event.target.selectionEnd);

    this.selectionStart = event.target.selectionStart;
    this.selectionEnd = event.target.selectionEnd;

    onInput(event, newValue);
    onChange(event, newValue);
  };

  componentDidUpdate = () => {
    this.instance.setSelectionRange(this.selectionStart, this.selectionEnd);
  };

  render() {
    const {value, maskChar, placeholder, className, title, disabled, style, onBlur} = this.props;
    const disabledClassName = disabled ? 'disabled' : '';

    return <input type='text'
      ref={instance => this.instance = instance}
      className={`crtx-password-input ${className} ${disabledClassName}`}
      title={title}
      style={style}
      placeholder={placeholder}
      value={maskChar.repeat(value.length)}
      disabled={disabled}
      autoComplete="false"
      onSelect={this.handleSelect}
      onKeyDown={this.handleKeyDown}
      onKeyUp={this.handleKeyUp}
      onClick={this.handleClick}
      onBlur={onBlur}
      onFocus={this.handleFocus}
      onChange={emptyFunction}
      onInput={this.handleInput}
    />
  }
};

module.exports = CustomPasswordInput;