import React from 'react';
import { createRoot } from 'react-dom/client';
import AbstractModule from 'AbstractModule';
import sandbox from 'sandbox';
import TickableModel from '../TickableModel';
import { createStore } from 'redux';
import reducer from './reducer';
import Table, { Column, cells, headers } from '../../widgets/ReactDataGrid';
import { ROW_HEIGHT } from 'widgets/ReactDataGrid/utils';

const { SortableHeader } = headers;
const { Text } = cells;

const translate = sandbox.localization.translate;
const labels = {
  name: translate('Name'),
  members: translate('Members'),
  group: translate('Group'),
  domainGroup: translate('Domain Group')
};

const isUndefined = o => typeof o === 'undefined';
const isFunction = o => typeof o === 'function';

const headerHeight = 35;

const itemCreator = (item) => {
  return {
    nwid: item.nwid,
    name: `${item.folderName}\\${item.name}`,
    type: item.type,
    domainProfile: item.domainProfile,
    members: item.memberUsers.map(item => item.name).sort().join(', ')
  };
};

const folderGroupItemCreator = (item) => {
  return {
    nwid: item.nwid,
    name: item.name,
    type: item.type,
    domainProfile: item.domainProfile,
    members: item.memberUsers.map(item => item).sort().join(', ')
  };
};

export default AbstractModule.extend({
  init: function () {
    this._super();
    for (var prop in this) {
      if (prop !== '_super' && isFunction(this[prop])) this[prop] = this[prop].bind(this);
    }
  },

  initDone: function () {
    this.createToolbar();

    this.tickableModel = new TickableModel();

    this.tickableModel.register(['controlpanel/profiles/groups/group'], this.handleGroupChange);
    this.tickableModel.register(['controlpanel/profiles/groups/group', 'controlpanel/profiles/users/user'], this.handleGroupMembersChange);

    this.tickableModel.register(['folder/groupsecurityspec'], this.handleGroupSecuritySpecChange);
    this.reactRoot = createRoot(this.domElement);
  },

  handleAddItem: function (item) {
    this.store.dispatch({
      type: 'addItem',
      group: itemCreator(item)
    });
  },

  handleRemoveItem: function (item) {
    const action = {
      type: 'removeItem',
      nwid: item.nwid
    };
    this.store.dispatch(action);
  },

  handleUpdateItem: function (item) {
    this.store.dispatch({
      type: 'updateItem',
      group: itemCreator(item)
    });
  },

  handleAddFolderGroupItem: function (item) {
    this.store.dispatch({
      type: 'addItem',
      group: folderGroupItemCreator(item)
    });
  },

  handleRemoveFolderGroupItem: function (item) {
    const action = {
      type: 'removeItem',
      nwid: item.nwid
    };
    this.store.dispatch(action);
  },

  handleUpdateFolderGroupItem: function (item) {
    const action = {
      type: 'updateItem',
      group: folderGroupItemCreator(item)
    };
    this.store.dispatch(action);
  },

  handleAddMemberGroupItem: function (item) {
    this.store.dispatch({
      type: 'addMemberItem',
      group: item
    });
  },

  handleGroupChange: function (action, group) {
    if (action === 'add') this.handleAddItem(group);
    if (action === 'remove') this.handleRemoveItem(group);
    if (action === 'update') this.handleUpdateItem(group);
  },

  handleGroupSecuritySpecChange: function (action, group) {
    if (action === 'add') this.handleAddFolderGroupItem(group);
    if (action === 'remove') this.handleRemoveFolderGroupItem(group);
    if (action === 'update') this.handleUpdateFolderGroupItem(group);
  },

  handleGroupMembersChange: function (action, groupMember) {
    const group = groupMember.getParent();
    const isGroupInState = this.store.getState().groups.findIndex(groupInState => groupInState.nwid === group.nwid) >= 0;

    if (!isGroupInState) return;

    if (action === 'add') this.handleUpdateItem(group);
    if (action === 'update') this.handleUpdateItem(group);
    if (action === 'remove') {
      this.handleUpdateItem({
        ...group,
        memberUsers: group.memberUsers.filter(memberUser => memberUser.nwid !== groupMember.nwid)
      });
    }
  },

  firstTickReceived: function (data) {
    this.store = createStore(reducer, { groups: [] });
    this.tickableModel.firstTickHandler(data.model);

    this.store.subscribe(() => this.render());
    this.render();
  },

  tickUpdate: function (data) {
    this.tickableModel.tickUpdateHandler(data.model);
  },

  handleRowSelect: function (selectedRows) {
    this.updateSelected(selectedRows.map(row => this.tickableModel.getByNwid(row.nwid)));
  },

  handleRowDoubleClick: function (selectedRowsIndex, selectedRows) {
    if (!isUndefined(this.viewActions.find(va => va.actionDefinitionName === 'EditFolderSecurityGroupActionCR'))) {
      this.navigateByAction(this.tickableModel.getByNwid(selectedRows.nwid));
    }
  },

  render: function () {
    const { groups } = this.store.getState();

    this.reactRoot.render(
      <div style={{ width: '100%', height: '100%' }}>
        <Table
          headerHeight={headerHeight}
          rowHeight={ROW_HEIGHT}
          fixed={true}
          rows={groups}
          columnKey='nwid'
          virtualScroll={true}
          onSelect={this.handleRowSelect}
          onDoubleClickRow={this.handleRowDoubleClick}
        >
          <Column key="name"
            columnKey="name"
            width={150}
            flex="50"
            align="left"
            header={<SortableHeader>{labels.name}</SortableHeader>}
            cell={Text}
            cellDataGetter={(rowIndex, columnKey) => {
              const item = groups[rowIndex];
              const icon = item.domainProfile === true ? sandbox.icons.getGeneralIcon('profile', 'domain_user') : sandbox.icons.getTemplateIcon(item.type);
              const title = item.domainProfile === true ? labels.domainGroup : labels.group;

              return {
                columnData: (<span>
                  <img src={icon} title={title} style={{ marginRight: '5px' }} />
                  {item.name}
                </span>),
                title: item.name
              };
            }}
          />

          <Column key="members"
            columnKey="members"
            width={150}
            flex="50"
            align="left"
            header={<SortableHeader>{labels.members}</SortableHeader>}
            cell={Text}
            cellDataGetter={(rowIndex, columnKey) => {
              return {
                columnData: groups[rowIndex].members
              };
            }}
          />
        </Table>
      </div>
    );
  }
});