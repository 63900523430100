import React, {Component} from 'react';
import PropTypes from 'prop-types';

const emptyFunction = () => {
};

class PasswordInput extends Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    focus: PropTypes.func,
  };

  static defaultProps = {
    value: '',
    disabled: false,
    onChange: emptyFunction
  };

  focus() {
    if (this.input) {
      this.input.focus();
    }
  }

  handleChange = event => {
    this.props.onChange(event, event.target.value);
  };

  render() {
    const {id, name, value, placeholder, className, title, disabled, style, onBlur, onFocus, ...props} = this.props;
    const disabledClassName = disabled ? 'disabled' : '';

    return <input ref={elem => this.input = elem}
                  type='password'
                  className={`crtx-password-input ${className} ${disabledClassName}`}
                  id={id}
                  name={name}
                  title={title}
                  style={style}
                  placeholder={placeholder}
                  value={value}
                  disabled={disabled}
                  onChange={this.handleChange}
                  onBlur={onBlur}
                  onFocus={onFocus}/>
  }
}

module.exports = PasswordInput;