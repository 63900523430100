/* @flow weak */

import React from 'react';
import { createRoot } from 'react-dom/client';
import sandbox from 'sandbox';
import AbstractModule from 'AbstractModule';
import Fanout from './components/Fanout';
import reducer from './reducer';
import actions from './actions';
import utils from './utils';
import SimpleForm from 'widgets/SimpleForm/src/index';

const { Store } = SimpleForm;

var testData = {
  "books": [
    {
      "nwid": "book1",
      "name": "book 1",
      "layoutType": "2ups",
      "pageCount": 8,
      "originalTemplate": "template 1",
      "forms": [
        {
          "nwid": "form1",
          "name": "form 1",
          "columns": 2,
          "rows": 2,
          "separations": [
            {
              "colorName": "black",
              "direct": false
            },
            {
              "colorName": "cyan",
              "direct": false
            },
            {
              "colorName": "magenta",
              "direct": false
            },
            {
              "colorName": "yellow",
              "direct": true
            }
          ],
          "pages": [
            {
              "nwid": "page0",
              "name": "page 0",
              "numberInSection": 0,
              "row": 0,
              "column": 0,
              "rowSpan": 1,
              "columnSpan": 2,
              "anchorPoint": "top-left",
              "separations": [
                {
                  "colorName": "black",
                  "offsetX": 0.1,
                  "offsetY": 0.1,
                  "scaleX": 0.1,
                  "scaleY": 0.1,
                  "scaleByPercentage": false,
                  "direct": false
                }
              ]
            },
            {
              "nwid": "page1",
              "name": "page 1",
              "numberInSection": 1,
              "row": 1,
              "column": 0,
              "rowSpan": 1,
              "columnSpan": 1,
              "anchorPoint": "top-left",
              "separations": [
                {
                  "colorName": "cyan",
                  "offsetX": 0.1,
                  "offsetY": 0.1,
                  "scaleX": 0.1,
                  "scaleY": 0.1,
                  "scaleByPercentage": false,
                  "direct": false
                },
                {
                  "colorName": "magenta",
                  "offsetX": 0.1,
                  "offsetY": 0.1,
                  "scaleX": 0.1,
                  "scaleY": 0.1,
                  "scaleByPercentage": false,
                  "direct": false
                },
                {
                  "colorName": "yellow",
                  "offsetX": 0.1,
                  "offsetY": 0.1,
                  "scaleX": 0.1,
                  "scaleY": 0.1,
                  "scaleByPercentage": false,
                  "direct": false
                },
                {
                  "colorName": "black",
                  "offsetX": 0.1,
                  "offsetY": 0.1,
                  "scaleX": 0.1,
                  "scaleY": 0.1,
                  "scaleByPercentage": false,
                  "direct": false
                }
              ]
            },
            {
              "nwid": "page2",
              "name": "page 2",
              "numberInSection": 3,
              "row": 1,
              "column": 1,
              "rowSpan": 1,
              "columnSpan": 1,
              "anchorPoint": "top-left",
              "separations": [
                {
                  "colorName": "black",
                  "offsetX": 0.1,
                  "offsetY": 0.1,
                  "scaleX": 0.1,
                  "scaleY": 0.1,
                  "scaleByPercentage": false,
                  "direct": false
                }
              ]
            }
          ]
        },
        {
          "nwid": "form2",
          "name": "form 2",
          "columns": 2,
          "rows": 2,
          "separations": [
            {
              "colorName": "black",
              "direct": true
            },
            {
              "colorName": "cyan",
              "direct": false
            },
            {
              "colorName": "magenta",
              "direct": false
            },
            {
              "colorName": "yellow",
              "direct": false
            }
          ],
          "pages": [
            {
              "nwid": "page2-1",
              "name": "p 2-1",
              "numberInSection": 1,
              "row": 0,
              "column": 0,
              "rowSpan": 1,
              "columnSpan": 1,
              "anchorPoint": "top-left",
              "separations": [
                {
                  "colorName": "cyan",
                  "offsetX": 0.1,
                  "offsetY": 0.1,
                  "scaleX": 0.1,
                  "scaleY": 0.1,
                  "scaleByPercentage": false,
                  "direct": false
                },
                {
                  "colorName": "magenta",
                  "offsetX": 0.1,
                  "offsetY": 0.1,
                  "scaleX": 0.1,
                  "scaleY": 0.1,
                  "scaleByPercentage": false,
                  "direct": false
                },
                {
                  "colorName": "yellow",
                  "offsetX": 0.1,
                  "offsetY": 0.1,
                  "scaleX": 0.1,
                  "scaleY": 0.1,
                  "scaleByPercentage": false,
                  "direct": false
                },
                {
                  "colorName": "black",
                  "offsetX": 0.1,
                  "offsetY": 0.1,
                  "scaleX": 0.1,
                  "scaleY": 0.1,
                  "scaleByPercentage": false,
                  "direct": false
                }
              ]
            },
            {
              "nwid": "page2-2",
              "name": "p 2-2",
              "numberInSection": 2,
              "row": 0,
              "column": 1,
              "rowSpan": 1,
              "columnSpan": 1,
              "anchorPoint": "top-left",
              "separations": [
                {
                  "colorName": "black",
                  "offsetX": 0.1,
                  "offsetY": 0.1,
                  "scaleX": 0.1,
                  "scaleY": 0.1,
                  "scaleByPercentage": false,
                  "direct": false
                }
              ]
            },
            {
              "nwid": "page2-3",
              "name": "p 2-3",
              "numberInSection": 1,
              "row": 1,
              "column": 0,
              "rowSpan": 1,
              "columnSpan": 1,
              "anchorPoint": "top-left",
              "separations": [
                {
                  "colorName": "cyan",
                  "offsetX": 0.1,
                  "offsetY": 0.1,
                  "scaleX": 0.1,
                  "scaleY": 0.1,
                  "scaleByPercentage": false,
                  "direct": false
                },
                {
                  "colorName": "magenta",
                  "offsetX": 0.1,
                  "offsetY": 0.1,
                  "scaleX": 0.1,
                  "scaleY": 0.1,
                  "scaleByPercentage": false,
                  "direct": false
                },
                {
                  "colorName": "yellow",
                  "offsetX": 0.1,
                  "offsetY": 0.1,
                  "scaleX": 0.1,
                  "scaleY": 0.1,
                  "scaleByPercentage": false,
                  "direct": false
                },
                {
                  "colorName": "black",
                  "offsetX": 0.1,
                  "offsetY": 0.1,
                  "scaleX": 0.1,
                  "scaleY": 0.1,
                  "scaleByPercentage": false,
                  "direct": false
                }
              ]
            },
            {
              "nwid": "page2-4",
              "name": "p 2-4",
              "numberInSection": 2,
              "row": 1,
              "column": 1,
              "rowSpan": 1,
              "columnSpan": 1,
              "anchorPoint": "top-left",
              "separations": [
                {
                  "colorName": "black",
                  "offsetX": 0.1,
                  "offsetY": 0.1,
                  "scaleX": 0.1,
                  "scaleY": 0.1,
                  "scaleByPercentage": false,
                  "direct": false
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "templates": {
    "2ups^8p": [
      "template 1",
      "template 2"
    ],
    "4ups^16p": [
      "template 10",
      "template 11"
    ]
  },
  "formTemplates": [
    {
      name: 'Form Template 1',
      forms: [
        {
          "name": "form 1",
          "role": "panorama",
          "columns": 2,
          "rows": 2,
          "numberInBook": 1,
          "pages": [
            {
              "name": "page 0",
              "row": 0,
              "column": 0,
              "rowSpan": 1,
              "columnSpan": 2,
            },
            {
              "name": "page 1",
              "row": 1,
              "column": 0,
              "rowSpan": 1,
              "columnSpan": 1,
            },
            {
              "name": "page 2",
              "row": 1,
              "column": 1,
              "rowSpan": 1,
              "columnSpan": 1,
            }
          ]
        },
        {
          "name": "form 2",
          "role": "even",
          "columns": 2,
          "rows": 2,
          "numberInBook": 1,
          "pages": [
            {
              "name": "page 0",
              "row": 0,
              "column": 0,
              "rowSpan": 1,
              "columnSpan": 1,
            },
            {
              "name": "page 1",
              "row": 0,
              "column": 1,
              "rowSpan": 1,
              "columnSpan": 1,
            },
            {
              "name": "page 2",
              "row": 1,
              "column": 0,
              "rowSpan": 1,
              "columnSpan": 1,
            },
            {
              "name": "page 3",
              "row": 1,
              "column": 1,
              "rowSpan": 1,
              "columnSpan": 1,
            },
          ]
        },
      ]
    }
  ],
  "colorTable": [
    {
      "colorName": "black",
      "colorType": "black",
      "color": {
        "cyan": 0,
        "magenta": 0,
        "yellow": 0,
        "black": 0.4,
      },
    },
    {
      "colorName": "s1",
      "colorType": "spot",
      "color": {
        "cyan": 0.1,
        "magenta": 0.2,
        "yellow": 0.3,
        "black": 0.4,
      },
    },
  ]
};

module.exports = AbstractModule.extend({

  store: null,


  selected: [],

  // IMPORTANT: Do not dorget to call this._super() when overriding the init() function
  // init: function () {
  //   this._super();
  // },

  initDone: function () {
    this.toolbar = this.createToolbar();
    this.reactRoot = createRoot(this.domElement);
  },

  createStore: function (data) {
    var state = {
      units: utils.getUnits(),
      nwid: data.model.nwid,
      type: data.model.type,
      model: {}
    };
    this.store = new Store(state, reducer, this.render.bind(this));

    return this.store;
  },

  firstTickReceived: function (data) {

    this.createStore(data);
    const state = this.store.getState();
    const _TEST_ = false;
    if (_TEST_) {
      this.updateBooks(testData);
    } else {
      this.loadBooks();
    }
  },

  loadBooks: function () {
    const state = this.store.getState();
    var params = {
      action: 'getFanoutInfo',
      command: 'getLayouManagerActions',
      rootId: state.nwid,
      type: state.type
    };
    sandbox.request.genericRequestPromise(params)
      .then(data => this.updateBooks(data));
    //.catch((reason) => console.error('Request Fanout Info: ' + reason));
  },

  updateBooks: function (data) {
    this.store.dispatch(actions.updateBooks(data));
  },

  handleSaveAction: function () {
    //console.log("handleSaveAction() ---");
    this.store.dispatch(actions.updateOriginalTemplate());

    const state = this.store.getState();
    var params = {
      action: 'setFanoutInfo',
      command: 'getLayouManagerActions',
      rootId: state.nwid,
      type: state.type,
      fanouts: JSON.stringify(state.model)
    };
    sandbox.request.genericRequestPromise(params)
      .then(() => this.loadBooks());
    //.catch((reason) => console.error('Save Fanout Info: ' + reason));
  },

  tickUpdate: function () {
  },

  tickCommit: function () {
  },

  render: function () {
    this.reactRoot.render(<Fanout store={this.store} />);
  }

});