import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GridPanel from './gridpanel';
import ThumbnailWraper from './thumbnailWraper';

export default class Thumbnails extends Component {
  static contextTypes = {
    module: PropTypes.object,
  };

  static defaultProps = {
    parentId: undefined,
    thumbnails: [],
    scale: undefined,
    thumbnailDefinition: undefined,
    alignmentPoints: undefined
  };

  thumbnailDefinition = () => {
    return this.props.thumbnailDefinition;
  };

  thumbnails = () => {
    return this.props.thumbnails;
  };

  alignmentPoints = () => {
    return this.props.alignmentPoints;
  };

  getThumbnailsMargin = (thumbnailDefinition) => {
    return thumbnailDefinition.generalProperties.margin;
  };

  onThumbnailClick = (thumbnails, thumbnail, e) => {
    this.context.module.ctrl.onClick(thumbnails, thumbnail, e);
  };

  onOpenChange = () => {
    this.context.module.ctrl.onOpenChange();
  };

  onThumbnailContextMenu = (thumbnails, thumbnail, e) => {
    this.context.module.ctrl.onContextMenu(thumbnails, thumbnail, e);
  };

  onThumbnailDoubleClick = (thumbnail, e) => {
    this.context.module.ctrl.onDoubleClick(thumbnail, e);
  };

  onAggregatedItemClick = (thumbnail, e) => {
    this.context.module.ctrl.handleAggregatedItemClick(thumbnail, e);
  };

  render() {
    var component = this,
      controller = this.context.module.ctrl,
      thumbnailDefinition = this.thumbnailDefinition(),
      viewConfiguration = this.props.viewConfiguration,
      margin = this.getThumbnailsMargin(thumbnailDefinition),
      thumbnails = this.thumbnails(),
      thumbnailsComponent = [],
      thumbnailsGridSize = controller.getMaxThumbnailSize(thumbnails, thumbnailDefinition),
      scale = this.props.scale;

    thumbnails.forEach(function (thumbnail) {
      thumbnailsComponent.push(<ThumbnailWraper key={thumbnail.id}
        thumbnail={thumbnail}
        thumbnailDefinition={thumbnailDefinition}
        viewConfiguration={viewConfiguration}
        alignmentPoints={component.alignmentPoints()}
        scale={scale}
        onOpenChange={component.onOpenChange}
        onClick={(e) => component.onThumbnailClick(thumbnails, thumbnail, e)}
        onAggregatedItemClick={(e) => component.onAggregatedItemClick(thumbnail, e)}
        onContextMenu={(e) => component.onThumbnailContextMenu(thumbnails, thumbnail, e)}
        onDoubleClick={(e) => component.onThumbnailDoubleClick(thumbnail, e)}
        showOnlyPagesInfo={controller.showOnlyPagesInfo}
      >
      </ThumbnailWraper>);
    });


    return <GridPanel gridSize={thumbnailsGridSize} gridMargin={margin} gridAlign="left">
      {thumbnailsComponent}
    </GridPanel>;
  }
}
Thumbnails.propTypes = {
  thumbnailDefinition: PropTypes.object,
  alignmentPoints: PropTypes.object,
  viewConfiguration: PropTypes.object,
  scale: PropTypes.number,
  thumbnails: PropTypes.array,
};