/* @flow weak */

import React, { Component } from 'react';
import sandbox from 'sandbox';
import { Column, headers, cells } from 'widgets/ReactDataGrid/';
import { HEADER_HEIGHT_WITH_FILTER, HEADER_HEIGHT } from 'widgets/ReactDataGrid/utils';
// import { Form, Row, Table, Container } from 'widgets/SimpleForm/src/index';
import { Form, Row, Container } from 'widgets/SimpleForm/src/index';
import Table from 'widgets/SimpleForm/src/components/Table';
import idWorker from 'core/workers/idWorker';
import Tabs from './Tabs';
import CoretexTablesManager from './CoretexTablesManager';
import cellsDefinition from '../cellsDefinition';
import helpers from '../helpers';
import PropTypes from 'prop-types';

const { SeparatorHeader } = headers;
const { SeparatorCell } = cells;
const { translate } = sandbox.localization;
// const headerHeight = 31;
const rowHeight = 30;

const dispatchAddRow = (store, path, index, newRowData) => {
  const tablePath = path.replace('.rows', '');
  store.dispatch({
    type: 'ADD_ROW',
    path,
    index,
    tablePath,
    value: newRowData(index)
  });
};
const dispatchRemoveRow = (store, path, index) => {
  const tablePath = path.replace('.rows', '');
  store.dispatch({
    type: 'REMOVE_ROW',
    path,
    index,
    tablePath
  });
};
const columnDefinition = (store, rows, columnType) => {
  return (column) => {
    const fieldType = column.fieldType;
    const def = cellsDefinition(fieldType, store, rows, columnType, column);

    def.sortType = helpers.getSortType(fieldType);
    def.filterType = helpers.getFilterType(fieldType);
    if (column.filter) {
      def.filter = column.filter;
    };
    if (column.sort) {
      def.sort = column.sort;
    };
    def.pinColumnForScroll = column.pinColumnForScroll;
    def.fieldType = fieldType;

    return def;
  };
};
const separatorColumn = () => {
  return {
    title: '',
    columnKey: '_separator_columnKey',
    sortable: false,
    autowidth: false,
    resizable: false,
    width: 5,
    header: <SeparatorHeader />,
    cell: SeparatorCell,
    backgroundColor: '#a7aeb7'
  };
};
const columnsDefinition = (store, rows, selectors, values) => {
  let columns = [];
  columns = selectors.map(columnDefinition(store, rows, 'selectors'));
  columns = columns.concat(separatorColumn());
  columns = columns.concat(values.map(columnDefinition(store, rows, 'values')));
  return columns;
};

const getColumnDefaultValue = (column) => {
  let defaultValue = column.defaultValue;

  if (column.fieldType === 'day') {
    defaultValue = typeof column.defaultValue === 'undefined' || column.defaultValue === '' ? '*' : defaultValue;
  }

  return defaultValue;
};

const newRowDataGetter = (selectors, values) => {
  const reduceToObjectSelector = (acc, item) => {
    acc[item.name] = getColumnDefaultValue(item);
    if (item.fieldType === 'publication' || item.fieldType === 'edition' || item.fieldType === 'zone' || item.fieldType === 'section' || item.fieldType === 'broadsheet' || item.fieldType === 'tabs' || item.fieldType === '2ups' || item.fieldType === '4ups' || item.fieldType === 'broadsheet' || item.fieldType === 'customLayoutGroup') {
      acc[item.name] = '*';
    }

    return acc;
  };

  const reduceToObjectValues = (acc, item) => {
    acc[item.name] = getColumnDefaultValue(item);

    return acc;
  };

  return () => {
    const rowSelectors = selectors.reduce(reduceToObjectSelector, {});
    const rowValues = values.reduce(reduceToObjectValues, {});
    return {
      index: `c-${idWorker.generateId()}`,
      selectors: rowSelectors,
      values: rowValues
    };
  };
};


module.exports = class CoretexTables extends Component {
  constructor(props) {
    super(props);
    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleDuplicateTableClick = this.handleDuplicateTableClick.bind(this);
    this.renderEmpty = this.renderEmpty.bind(this);
    this.renderManageMode = this.renderManageMode.bind(this);
    this.renderTable = this.renderTable.bind(this);
  }

  static propTypes = {
    store: PropTypes.any
  }

  handleTabClick(tabIndex) {
    const store = this.props.store;

    store.dispatch({
      type: 'UPDATE_CURRENT_TABLE',
      'currentTableIndex': tabIndex
    });
  }

  handleDuplicateTableClick(tabIndex) {
    const store = this.props.store;

    store.dispatch({
      type: 'DUPLICATE_TABLE',
      'duplicateTableIndex': tabIndex
    });
  }

  renderEmpty() {
    return null;
  }

  renderManageMode() {
    const store = this.props.store;
    return <CoretexTablesManager store={store} />
  }

  handleColumnResize = (currentTableIndex) => {
    return (columns) => {
      this.props.onColumnResize(currentTableIndex, columns);
    }
  }

  handleColumnFilterChange = (column, filter) => {
    const { store } = this.props;

    store.dispatch({
      type: 'UPDATE_COLUMN_FILTER',
      column,
      filter,
    });
  };

  handleColumnSortChange = (column, multiSort) => {
    this.props.store.dispatch({
      type: 'SORT_COLUMN_CHANGE',
      column,
      multiSort
    });
  }

  renderTable() {
    const store = this.props.store;
    var state = store.getState();
    const currentTableIndex = store.get('currentTableIndex');
    const tablePath = `tables[${currentTableIndex}]`;
    const rowsPath = `${tablePath}.rows`;
    const tableNwid = `${tablePath}.nwid`;
    const selectors = store.get(`${tablePath}.selectors`) || [];
    const values = store.get(`${tablePath}.values`) || [];
    const rows = store.get(rowsPath) || [];
    const sortAndFilterEnabled = store.get(`${tablePath}.sortAndFilterEnabled`);
    const tables = store.get('tables');
    const showTabs = tables.length > 1;
    const tableContainerStyle = showTabs ? undefined : { height: '100%' };
    const headerHeight = sortAndFilterEnabled ? HEADER_HEIGHT_WITH_FILTER : HEADER_HEIGHT;

    return <Container className="table-simple-form-container" style={tableContainerStyle}>
      <Table
        key={tableNwid}
        columnKey={'index'}
        headerHeight={headerHeight}
        rowHeight={rowHeight}
        bind={rowsPath}
        virtualScroll={true}
        selectableRows={true}
        multiSelect={false}
        columnDefinitions={columnsDefinition(store, rows, selectors, values)}
        addRow={true}
        onAddRow={(path, index) => {
          dispatchAddRow(store, path, index, newRowDataGetter(selectors, values))
        }}
        removeRow={true}
        onRemoveRow={(path, index) => {
          dispatchRemoveRow(store, path, index)
        }}
        newRowData={newRowDataGetter(selectors, values)}
        fullHeight={true}
        onColumnResize={this.handleColumnResize(currentTableIndex)}
        onColumnFilterChange={this.handleColumnFilterChange}
        onColumnSortChange={this.handleColumnSortChange}
        sortAndFilterEnabled={sortAndFilterEnabled}
        borderSelection={true}
      />
    </Container>;
  }

  render() {
    const store = this.props.store;
    const viewMode = store.get('viewMode');
    const currentTableIndex = store.get('currentTableIndex');
    const modifiedTabs = store.get('modifiedTabs') || {};
    const content = currentTableIndex === -1 ? this.renderEmpty() : viewMode == 'view' ? this.renderTable() : this.renderManageMode();
    const tables = store.get('tables') || [];

    if (tables.length === 0) {
      return <div className='conversion-tables-view-no-tables'>{translate('No tables')}</div>
    }
    return <Form className="conversion-tables-view" store={store}>
      <Container className="tabs-simple-form-container">
        <Tabs bind="tabs" modifiedTabs={modifiedTabs} currentTabIndex={currentTableIndex}
          onClick={this.handleTabClick} />
      </Container>
      {content}
    </Form>;
  }
}
