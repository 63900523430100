import { fromServerDate } from 'core/dates';
import { composeComparators, createObjectComparator, COMPARE_TYPE } from 'core/comparators';
import { createNavigatorFilter, findNewSelectedIndex, getItemFilterData } from '../NewPageView/navigatorControls';

export default class ApprovalNeighborItems {
  constructor(module) {
    this.module = module;
    this.neighborItems = [];

    this.module.tickableModel.register(['eventqueue', 'page'], this.handleEventQueueItemTick.bind(this));
    this.module.tickableModel.register(['eventqueue', 'form'], this.handleEventQueueItemTick.bind(this));
  }

  getApprovalFlowStepNwid(item) {
    const approvalFlowStep = item.getParent().getParent();

    return approvalFlowStep.nwid;
  }

  createNeighborItem(item) {
    const approvalFlowStepNwid = this.getApprovalFlowStepNwid(item);

    const deadline = fromServerDate(item.DeadLine);
    const eventTime = fromServerDate(item.EventTime[approvalFlowStepNwid]);
    const itemTitle = item.EventTitle[approvalFlowStepNwid] || '';
    const filterData = getItemFilterData(item);

    return {
      ...item,
      label: itemTitle,
      deadline,
      eventTime,
      viewNwid: this.module.nwid,
      filterData
    };
  }

  handleEventQueueItemTick(action, item) {
    const parentStartParams = (this.module.parentModule || {}).startParameters;
    if (!parentStartParams || parentStartParams.rootType !== 'workflow/step/flow/approval') {
      return;
    }

    const approvalFlowStepNwid = this.getApprovalFlowStepNwid(item);
    if (!approvalFlowStepNwid || approvalFlowStepNwid !== parentStartParams.rootId) {
      return;
    }

    if (action === 'add') {
      this.neighborItems = this.neighborItems.concat(this.createNeighborItem(item));
    } else if (action === 'update') {
      this.neighborItems = this.neighborItems.filter(neighborItem => neighborItem.nwid !== item.nwid)
        .concat(this.createNeighborItem(item));
    } else if (action === 'remove') {
      this.neighborItems = this.neighborItems.filter(neighborItem => neighborItem.nwid !== item.nwid);
    }

    this.neighborItems.sort(composeComparators(
      [createObjectComparator('deadline', COMPARE_TYPE.DATES), createObjectComparator('label')]));

    const filters = createNavigatorFilter(this.preferences.filters, this.neighborItems[0]);

    const navigatorData = {
      items: this.neighborItems,
      filters
    };

    this.module.navigator.setControls(navigatorData);

    const viewModel = item.getRoot();
    const selectedIndex = findNewSelectedIndex(this.neighborItems, viewModel.nwid);
    if (selectedIndex >= 0) {
      this.module.navigator.setSelectedIndex(selectedIndex);
    }
  }

  firstTickReceived(data) {
    this.neighborItems = [];
    this.preferences = data.preferences || {};
    this.preferences.filters = data.preferences.filters || [];
  }

  tickUpdate(data) {
    if (this.shouldMoveToNextItem()) {
      this.moveToNextApprovedItem();
    }
  }

  shouldMoveToNextItem() {
    const actions = this.module.getRelevantActions(this.module.selected[0]);
    const approveAllAction = actions.find(a => a.actionDefinitionName === 'ApproveAllActionCR');

    // TEST
    // const selectedItem = this.module.selected[0];
    // console.log('isApplicable() =>', JSON.stringify(selectedItem), approveAllAction.isApplicable());

    return approveAllAction && !approveAllAction.isApplicable();
  }

  moveToNextApprovedItem() {
    const selectedItem = this.module.selected[0];
    if (!selectedItem || this.neighborItems.length <= 1) {
      return;
    }

    const itemNwid = selectedItem.nwid;
    const itemIndex = this.neighborItems.findIndex(ni => ni.nwid === itemNwid);

    if (itemIndex === this.neighborItems.length - 1) {
      this.module.navigator.navigationSelectionChanged(itemIndex - 1);
    } else {
      this.module.navigator.navigationSelectionChanged(itemIndex + 1);
    }
  }

}
