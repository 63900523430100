import { getPassedFilters, filtersHasTheSameValues, sorterCreator, findSorterIndex, switchSorterDirection, getSortComparator, getFilteredPagesWithPageInRightPosition, getColumnsWidth } from '../utils';
import { ROW_HEIGHT, ROW_HEIGHT_INCLUDING_THUMBNAIL } from 'widgets/ReactDataGrid/utils';

const getFilteredPages = (pages, filters, sorters) => {
  const sortComparator = getSortComparator(sorters);

  return pages.filter(getPassedFilters(filters)).sort(sortComparator);
};

const findFilterOptionIndex = (filter, page) => {
  const pageFilterValue = filter.getOptionValue(page);
  return filter.options.findIndex(option => option.value === pageFilterValue);
};

const getNewFilterOption = (filter, page) => {
  return {
    value: filter.getOptionValue(page),
    text: filter.getOptionText(page),
    count: 1
  };
};

const getFilterOptionWithUpdatedCount = (filter, optionIndex, value) => {
  if (optionIndex < 0) return undefined;

  const option = filter.options[optionIndex];

  return {
    ...option,
    count: option.count + value
  };
};

const getFilterWithAddedOption = page => (filter) => {
  const filterOptionIndex = findFilterOptionIndex(filter, page);

  return {
    ...filter,
    options: filterOptionIndex < 0 ?
      filter.options.concat(getNewFilterOption(filter, page)) :
      filter.options.slice(0, filterOptionIndex).concat(getFilterOptionWithUpdatedCount(filter, filterOptionIndex, 1)).concat(filter.options.slice(filterOptionIndex + 1))
  };
};

const getFiltersAfterPageAdd = (filters = [], page) => {
  return filters.map(getFilterWithAddedOption(page));
};



const getFiltersAfterPageRemove = (filters = [], page) => {
  return filters.map(filter => {
    const optionIndex = findFilterOptionIndex(filter, page);

    if (optionIndex < 0) return filter;

    const filterOption = filter.options[optionIndex];
    const updatedFilterOption = getFilterOptionWithUpdatedCount(filter, optionIndex, -1);
    const isGoingToRemoveTheOption = updatedFilterOption.count <= 0;
    const filterValue = isGoingToRemoveTheOption && updatedFilterOption.value === filter.value ? '' : filter.value;

    return {
      ...filter,
      value: filterValue,
      options: isGoingToRemoveTheOption ?
        filter.options.slice(0, optionIndex).concat(filter.options.slice(optionIndex + 1)) :
        filter.options.slice(0, optionIndex).concat(updatedFilterOption).concat(filter.options.slice(optionIndex + 1))
    };
  });
};

const addPage = (state, { page }) => {
  const { pages, filteredPages, filters } = state;
  const isPassedFilters = getPassedFilters(filters)(page);

  return {
    ...state,
    pages: pages.concat(page),
    filteredPages: isPassedFilters ? getFilteredPagesWithPageInRightPosition(filteredPages, state.sortComparator, page) : filteredPages,
    filters: getFiltersAfterPageAdd(state.filters, page)
  };
}

const updatePage = (state, { page }) => {
  const statePageIndex = state.pages.findIndex(statePage => statePage.nwid === page.nwid);
  const foundStatePage = statePageIndex >= 0;

  if (!foundStatePage) return state;

  const filters = getFiltersAfterPageAdd(getFiltersAfterPageRemove(state.filters, state.pages[statePageIndex]), page);
  const pages = state.pages.slice(0, statePageIndex).concat(page).concat(state.pages.slice(statePageIndex + 1));
  const passedFiltersStatePage = getPassedFilters(state.filters)(state.pages[statePageIndex]);
  const passedFiltersPage = getPassedFilters(filters)(page);
  const isFiltersTheSame = filtersHasTheSameValues(state.filters, filters);
  const filteredPages = !passedFiltersStatePage && !passedFiltersPage && isFiltersTheSame ?
    state.filteredPages :
    getFilteredPagesWithPageInRightPosition(state.filteredPages, state.sortComparator, page);

  return {
    ...state,
    pages,
    filteredPages,
    filters
  };
};

const removePage = (state, { page }) => {
  const pages = state.pages.filter(statePage => statePage.nwid !== page.nwid);
  const filters = getFiltersAfterPageRemove(state.filters, page);
  const filteredPages = getFilteredPages(pages, filters, state.sorters);

  return {
    ...state,
    pages,
    filteredPages,
    filters
  };
};

const selectPages = (state, { selectedPages }) => {
  return {
    ...state,
    selectedPages
  };
};

const setFilterValue = (state, { filterName, value }) => {
  const filterIndex = state.filters.findIndex(filter => filter.name === filterName);

  if (filterIndex < 0) return state;

  const filter = {
    ...state.filters[filterIndex],
    value
  };
  const newFilters = state.filters.slice(0, filterIndex).concat(filter).concat(state.filters.slice(filterIndex + 1));

  return {
    ...state,
    filters: newFilters,
    filteredPages: getFilteredPages(state.pages, newFilters, state.sorters)
  };
};

const resetFilters = (state) => {
  const newFilters = state.filters.map(filter => ({ ...filter, value: '' }));

  return {
    ...state,
    filters: newFilters,
    filteredPages: getFilteredPages(state.pages, newFilters, state.sorters)
  };
};

const setMultipleSorter = (state, { columnKey, sortType, sortProp }) => {
  const sorterIndex = findSorterIndex(state.sorters, columnKey);
  const foundSorter = sorterIndex >= 0;
  const sorters = foundSorter ?
    [...state.sorters.slice(0, sorterIndex), switchSorterDirection(state.sorters[sorterIndex]), ...state.sorters.slice(sorterIndex + 1)] :
    [...state.sorters, sorterCreator(columnKey, sortType, sortProp)];
  const sortComparator = getSortComparator(sorters);

  return {
    ...state,
    sorters,
    sortComparator,
    filteredPages: [...state.filteredPages].sort(sortComparator)
  };
};

const setSort = (state, { columnKey, sortType, sortProp }) => {
  const sorterIndex = findSorterIndex(state.sorters, columnKey);
  const foundSorter = sorterIndex >= 0;
  const hasOnlyOneSorter = state.sorters.length === 1;
  const sorters = foundSorter && hasOnlyOneSorter ?
    [switchSorterDirection(state.sorters[0])] :
    [sorterCreator(columnKey, sortType, sortProp)];
  const sortComparator = getSortComparator(sorters);

  return {
    ...state,
    sorters,
    sortComparator,
    filteredPages: [...state.filteredPages].sort(sortComparator)
  };
};

const setColumns = (state, { columns }) => {
  const columnsOrder = columns.map(column => column.columnKey);
  const fixToLeftColumnsCount = columns.filter(column => column.fixed === 'left').length;
  const hiddenColumns = columns.reduce((hiddenColumns, column) => {
    if (column.visible === false) {
      hiddenColumns[column.columnKey] = true;
    }
    return hiddenColumns;
  }, {});

  return {
    ...state,
    tablePreferencesTimeStamp: Date.now(),
    fixToLeftColumnsCount,
    columnsOrder,
    hiddenColumns,
    currentRowHeight: typeof (hiddenColumns || {}).pageContentNwid !== 'undefined' ? ROW_HEIGHT : ROW_HEIGHT_INCLUDING_THUMBNAIL
  };
};

const setColumnsWidth = (state, { columns }) => {
  return {
    ...state,
    columnsWidth: getColumnsWidth(columns)
  };
};

const reducer = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_PAGE':
      return addPage(state, action);

    case 'UPDATE_PAGE':
      return updatePage(state, action);

    case 'REMOVE_PAGE':
      return removePage(state, action);

    case 'SELECT_PAGES':
      return selectPages(state, action);

    case 'SET_FILTER_VALUE':
      return setFilterValue(state, action);

    case 'RESET_FILTERS':
      return resetFilters(state, action);

    case 'SET_MULTIPLE_SORTER':
      return setMultipleSorter(state, action);

    case 'SET_SORT':
      return setSort(state, action);

    case 'SET_COLUMNS':
      return setColumns(state, action);

    case 'SET_COLUMNS_WIDTH':
      return setColumnsWidth(state, action);

    default:
      return state
  }
}

export default reducer;