/**
 * Created by elad on 4/3/2017.
 */
import { fromServerDate, toServerDate, MSEC_IN_MINUTE} from 'core/dates';
import sandbox from 'sandbox';
import Ember from 'ember';
import localization from 'core/services/localization';
import { toKendoDateTimeFormat } from 'utilities/datesConvertor';
import wizardCommons from './../../../utils/wizardCommons';
import wizardUtils from './../../../utils/wizardUtils';
import { restGet } from 'core/managers/rest2';

function getDefaultDeadline(issueDate, interval = 0) {
  const deadline = new Date(issueDate.getTime() + interval * MSEC_IN_MINUTE);

  return deadline;
}

function getDefaultproductionRunOverrrideValue(key, overrideCustomData) {
  if (typeof overrideCustomData === "undefined") { return ""; }
  var defaults = overrideCustomData.values;
  var match = defaults.filter(function (d) {
    return d.criteria === key;
  })
  if (match.length === 0) {
    return "";
  }
  return match[0].value;
}

function isZoneApplicable(sourceZone, destinationZone) {
  let sourceBooks = sourceZone.books;
  let destinationBooks = destinationZone.books;
  if (sourceBooks.length !== destinationBooks.length) {
    let translateParams = {
      srcZone: sourceZone.displayName,
      destZone: destinationZone.displayName
    }
    let message = sandbox.localization.translate("Zone {1} has a different number of books than Zone {2}, therefore runs information will not be copied", translateParams.srcZone, translateParams.destZone);
    sandbox.dialog.alert(message);
    return false;
  }
  let destinationBooksNames = destinationBooks.map(function (b) {
    return b.displayName;
  });
  let missingBooks = sourceBooks.filter(function (book) {
    return !destinationBooksNames.includes(book.displayName);
  });
  if (missingBooks.length > 0) {
    let translateParams = {
      missing: missingBooks.map(function (b) { return b.displayName }).join(" "),
      destZone: destinationZone.displayName
    }
    let message = sandbox.localization.translate("Books {1} are missing from zone {2}", translateParams.missing, translateParams.destZone);
    sandbox.dialog.alert(message);
    return false;
  }
  return true;
}

function cloneRuns(sourceZone, destinationZone) {
  var runs = sourceZone.runs;
  let destinationZoneName = destinationZone.displayName;
  let cloned = runs.map(function (r) {
    let zoneRun = sandbox.jsUtils.cloneDeep(r);
    zoneRun.displayName = zoneRun.displayName.replace("_" + zoneRun.zone + "_", "_" + destinationZoneName + "_");
    zoneRun.zone = destinationZoneName;
    return zoneRun;
  });
  Ember.set(destinationZone, "runs", cloned);
}

function isABSplit(zone) {
  let sections = zone.sections;
  for (let i = 0; i < sections.length; i++) {
    let pages = sections[i].pages;
    for (let j = 0; j < pages.length; j++) {
      if (typeof pages[j].split !== "undefined") {
        return true;
      }
    }
  }
  return false;
}

module.exports = Ember.ObjectController.extend(wizardCommons.controllerCommons, {

  selectedZone: null,
  selectedEdition: null,
  pressNamesCounter: {},
  zones: function () {
    return this.model.zones;
  },

  selectedZoneChanged: function (model) {

  },

  applyToAllZones: function (zoneModel) {
    let runs = zoneModel.runs;
    let that = this;
    let zones = this.model.zones;
    let myid = zoneModel.sourceId;
    for (let i = 0; i < zones.length; i++) {
      if (myid !== zones[i].sourceId) {
        if (isZoneApplicable.apply(this, [zoneModel, zones[i]])) {
          cloneRuns.apply(this, [zoneModel, zones[i]]);
        }
      }
    }
  },

  dateTimeFormat: function () {
    return toKendoDateTimeFormat();
  }.property(),

  disableProductionRuns: function () {
    return !this.editProductionRuns
  }.property(),

  enableProductionRuns: function () {
    return this.editProductionRuns
  }.property(),

  addRun: function (zoneModel) {
    var runs = zoneModel.runs;
    var that = this;
    var pressCounter = this.pressNamesCounter[zoneModel.displayName] + 1;
    this.pressNamesCounter[zoneModel.displayName] = pressCounter;
    var key = this.publicationName + "," + this.editionName + "," + zoneModel.displayName;
    let printMode = isABSplit.call(that, zoneModel) && that.printMode === "collect" ? "straight" : that.printMode;
    runs.pushObject({
      displayName: that.planningModel.publication.editions[0].displayName + "_" + zoneModel.displayName + "_" + (runs.length + 1),
      zone: zoneModel.displayName,
      deadline: (typeof zoneModel.deadline === "undefined" || zoneModel.deadline === "") ? getDefaultDeadline(this.issueDate, this.clientSettings.presetTimeOffset) : fromServerDate(zoneModel.deadline),
      hold: false,
      releaseOffset: 0,
      pressName: this.presses[0],
      fanoutMode: that.fanoutMode,
      mode: printMode,
      books: zoneModel.books.map(function (book) {
        var bookKey = key + "," + book.displayName;
        return {
          displayName: book.displayName,
          selected: that.booksSelected,
          findId: book.sourceId,
          numForms: book.forms.length,
          productionRunOverrrideValue: book.productionRunOverrrideValue || getDefaultproductionRunOverrrideValue.apply(that, [bookKey, that.model.overrideCustomData])
        }
      })
    });
  },

  updateDefaultPreset: function (runModel, data) {
    let presetTme = fromServerDate(data.defaultPresettime);
    let hold = data.hold;
    let releaseOffset = data.releaseOffset;
    if ((typeof presetTme !== "undefined") && presetTme !== null && presetTme !== "") {
      Ember.set(runModel, "deadline", presetTme);
      Ember.set(runModel, "hold", hold);
      Ember.set(runModel, "releaseOffset", releaseOffset);
    }
  },

  deleteRun: function (runModel) {
    var zoneName = runModel.zone;
    var zone = this.model.zones.filter(function (zone) {
      return (zone.displayName === zoneName);
    })[0];
    var runToRemove = zone.runs.filter(function (run) {
      return (run.displayName === runModel.displayName);
    })[0];
    zone.runs.removeObject(runToRemove);
  },

  resources: function () {
    var ret = [];
    var that = this;
    ret.addObject({ text: "", value: "" });
    this.model.resources.forEach(function (resource) {
      ret.addObject({ text: resource.name + "(R)", value: resource.nwid });
    });
    this.model.resourceGroups.forEach(function (group) {
      ret.addObject({ text: group.name + "(G)", value: group.nwid });
    });
    return ret;
  }.property('model.resources'),

  updateModel: function (planningModel) {
    var planningZones = planningModel.publication.editions[0].zones;
    for (var i = 0; i < planningZones.length; i++) {
      let abSplit = isABSplit.call(this, planningZones[i]);
      var runs = planningZones[i].runs;
      let presses = [];
      for (var j = 0; j < runs.length; j++) {
        ///need to validate that no 2 runs in the same zone usses the same press
        if (typeof runs[j].pressName === "undefined" || runs[j].pressName === "") {
          sandbox.dialog.alert("Please fill Press field =>" + planningZones[i].displayName);
          return false;
        }
        if (presses.includes(runs[j].pressName)) {
          //sandbox.dialog.alert("More than one in this zone run is using the same press=>" + planningZones[i].displayName + " - " + runs[j].pressName);
          //return false;
        } else {
          presses.push(runs[j].pressName)
        }
        if (!this.clientSettings.enableSplitOnCollect && abSplit && runs[j].mode === "collect") {
          sandbox.dialog.alert("AB Split cannot be printed in Collect mode => " + planningZones[i].displayName + " - " + runs[j].pressName);
          return false;
        }
        if (typeof runs[j].deadline === "undefined" || runs[j].deadline === "") {
          runs[j].deadline = "";
        } else {
          runs[j].deadline = (typeof runs[j].deadline === "string") ? runs[j].deadline : toServerDate(runs[j].deadline);
        }

      }
    }
    return true;
  },

  updateUiModel: function (planningModel, module, fanouts, overrideCustomData, isOverrideTable, fanoutTemplates) {
    this.module = module;
    this.model = {};
    if (typeof fanouts !== "undefined") {
      this.model.availableFanouts = fanouts;
    } else if (typeof fanoutTemplates !== "undefined") {
      this.model.availableFanouts = fanoutTemplates;
    }
    if (typeof overrideCustomData !== "undefined") {
      this.model.overrideCustomData = overrideCustomData;
    }
    if (typeof isOverrideTable !== "undefined") {
      this.model.isOverrideTable = isOverrideTable;
    }

    var generalSettings = planningModel.generalSettings || {};
    var edition = planningModel.publication.editions[0];
    this.planningModel = planningModel;
    this.model.folder = planningModel.folder;
    this.model.resources = planningModel.resources;
    this.model.resourceGroups = planningModel.resourceGroups;
    var zones = planningModel.publication.editions[0].zones;
    this.publicationName = planningModel.publication.displayName;
    this.publicationDate = planningModel.publication.issueDate;
    this.editionName = planningModel.publication.editions[0].displayName;
    this.isChase = (edition.baseEdition) ? true : false;
    this.chaseProductionRuns = generalSettings.chaseProductionRuns;
    this.editProductionRuns = !this.isChase || !this.chaseProductionRuns;
    this.model.zones = zones;
    var that = this;
    let booksSelected = this.booksSelected;
    for (var i = 0; i < zones.length; i++) {
      var runs = zones[i].runs;
      if (typeof runs === "undefined") {
        runs = [];
        var numForms = 0;
        let printMode = isABSplit.call(that, zones[i]) && that.printMode === "collect" ? "straight" : that.printMode;
        runs.push({
          displayName: planningModel.publication.editions[0].displayName + "_" + zones[i].displayName + "_1",
          zone: zones[i].displayName,
          deadline: (typeof zones[i].deadline === "undefined" || zones[i].deadline === "") ? getDefaultDeadline(this.issueDate, this.clientSettings.presetTimeOffset) : fromServerDate(zones[i].deadline),
          mode: printMode,
          hold: false,
          releaseOffset: 0,
          pressName: this.presses[1],
          fanoutMode: that.fanoutMode,
          books: zones[i].books.map(function (book) {
            var key = planningModel.publication.displayName + "," + planningModel.publication.editions[0].displayName + "," + zones[i].displayName + "," + book.displayName;
            numForms+= book.forms.length;
            return {
              displayName: book.displayName,
              selected: booksSelected,
              findId: book.sourceId,
              numForms: book.forms.length,
              productionRunOverrrideValue: book.productionRunOverrrideValue || getDefaultproductionRunOverrrideValue.apply(this, [key, overrideCustomData])
            }
          })
        })

        //console.log ("1111111111111111111111111111z1numForms:" + numForms);
        this.model.zones[i].runs = runs;
        let runModel = this.model.zones[i].runs[0];

        let params = {};
        let runBooks = zones[i].books.map(function (book) {
          return book.displayName;
        })

        restGet(this.module.nwid, `folders/${this.model.folder}/planning-actions/default-presettime-info?publicationDate=${this.publicationDate}&publication=${this.publicationName}&edition=${this.editionName}&zone=${zones[i].displayName}&press=${this.presses[1]}&books=${runBooks.join(";")}`).then((data) => {
          this.updateDefaultPreset(runModel, data);
        });
      } else {
        var runsToRemove = [];
        var booksMap = {};
        zones[i].books.forEach (function (book) {
          booksMap[book.displayName] = book.forms.length;
        });
        for (var j = 0; j < runs.length; j++) {
          runs[j].deadline = (typeof runs[j].deadline === "undefined" || runs[j].deadline === "") ? "" : fromServerDate(runs[j].deadline);
          runs[j].fanoutMode = that.fanoutMode;
          if (typeof runs[j].books === "undefined") {
            runsToRemove.push(runs[j]);
            continue;
          }
          var existsBookNames;
          if (typeof runs[j].books === "undefined") {
            existsBookNames = [];
            runs[j].books = [];
          } else {
            existsBookNames = runs[j].books.map(function (book) {
              return book.displayName;
            });
          }

          ///remove deleted books
          runs[j].books = runs[j].books.filter(function (runBook) {
            var zoneBookNames = zones[i].books.map(function (book) {
              return book.displayName;
            });
            return zoneBookNames.includes(runBook.displayName);
          });

          runs[j].books.forEach (book => {
            book.numForms = booksMap[book.displayName];
            if (this.fanoutMode === "TemplateBasedFormFanout" && book.productionRunBookFanoutTemplate
                && book.productionRunBookFanoutTemplate != '') {
              let templates = this.model.availableFanouts[book.numForms] || [];
              var existTemplates = templates.filter(function (template) {
                return template.name === book.productionRunBookFanoutTemplate;
              });
              if (existTemplates.length === 0){
                delete book.productionRunBookFanoutTemplate;
              }
              
            }
          });

          var missingBooks = zones[i].books.filter(function (book) {
            return !existsBookNames.includes(book.displayName);
          });

          if (missingBooks.length > 0) {
            for (var q = 0; q < missingBooks.length; q++) {
              var key = planningModel.publication.displayName + "," + planningModel.publication.editions[0].displayName + "," + zones[i].displayName + "," + missingBooks[q].displayName;
              runs[j].books.push({
                displayName: missingBooks[q].displayName,
                selected: true,
                findId: missingBooks[q].sourceId,
                numForms: missingBooks[q].forms.length,
                productionRunOverrrideValue: missingBooks[q].productionRunOverrrideValue || getDefaultproductionRunOverrrideValue.apply(this, [key, overrideCustomData])
              });
            }
          }
        }
        for (var q = 0; q < runsToRemove.length; q++) {
          var index = runs.indexOf(runsToRemove[q]);
          if (index > -1) {
            runs.splice(index, 1);
          }
        }
      }
    }
  }
});