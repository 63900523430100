import PropTypes from 'prop-types';
define(
  [
    'react',
    './ListItem'
  ],
  function (React,
    ListItem) {

    "use strict";

    const { Component } = React;

    return class List extends Component {

      static propTypes = {

        items: PropTypes.array, // array of { id, title or el }

        itemDataGetter: PropTypes.func,
        multiSelect: PropTypes.bool,

        selectedItem: PropTypes.string,

        selectedItems: PropTypes.array,

        style: PropTypes.string,

        buttons: PropTypes.array
      }

      static defaultProps = {
        multiSelect: false,
        selectedItem: null,
        selectedItems: [],
        itemDataGetter: item => item
      };

      constructor(props) {
        super(props);
      }

      isItemSelected = (item) => {

        var { multiSelect, selectedItems, selectedItem } = this.props;

        var selected = false;
        if (multiSelect) {

          var selectedItems = selectedItems || [];
          selected = selectedItems.filter((selItem) => item.id == selItem).length > 0;

        } else if (selectedItem) {

          selected = (item.id == selectedItem);
        }

        return selected;
      };


      render() {

        const { items, itemDataGetter, style, className, buttons, ...props } = this.props;
        const listClassName = ['list', className].join(' ');
        const listItems = items.map((item, index) => {
          var itemData = itemDataGetter(item);
          let isSelected = this.isItemSelected(itemData);
          let itemButtons = typeof itemData.buttons === 'undefined' ? buttons : itemData.buttons;
          return <ListItem key={itemData.id} item={itemData} isSelected={isSelected} buttons={itemButtons} {...props}>
          </ListItem>;
        });

        return <ul className={listClassName} style={style}>
          {listItems}
        </ul>;

      }
    }
  });