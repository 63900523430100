/**
 * Created by moshemal on 9/2/15.
 */
define(['logger'], function (logger) {
  'use strict';

  var log = logger.getDefaultLogger();
  log.log("graphUtils Loaded");

  function values(o){
    return Object.keys(o).map(function(p){return o[p];});
  }
  /**
   *
   * @param {Array}  N nodes data array
   * @param {String} nProp the unique key property
   * @returns {Array}
   */
  function deduceGroups(N, nProp) {

    if (!Array.isArray(N)){
      log.warn("N is not an array");
      return [];
    }
    var groupDict = {};
    N.forEach(function(node){
      if(node.group){
        groupDict[node.group] = true;
      }
    });

    return Object.keys(groupDict).map(function(p){
      var groupNodeData = {
        isGroup: true
      };
      groupNodeData[nProp] = p;
      return groupNodeData;
    });
  }

  function createGraphDictionaries (N, E, nProp, toProp, fromProp) {
    var nodeDict  = {};
    var groupDict = {};

    N.forEach(function(curr){
      var node = {
        fromNodes: {},
        toNodes: {},
        data: curr
      };
      if(!curr.isGroup){
        if(curr.group) {
          //add curr to the group it belongs
          var groupObject = groupDict[curr.group] = groupDict[curr.group] || {
              fromNodes: {},
              toNodes: {},
              members: {},
              data: {
                isGroup: true
              }
            };
          groupObject.members[curr[nProp]] = node;
          groupObject[nProp] = curr.group;
          groupObject.data[nProp] = curr.group;
          node.groupObject = groupObject;
        }
        nodeDict[curr[nProp]] = node;
      }else{
        log.warn("there is a group node in the data: ", curr);
      }
    });

    E.forEach(function(e){
      if (nodeDict[e[fromProp]] && nodeDict[e[toProp]]){
        nodeDict[e[fromProp]].toNodes[e[toProp]]    = nodeDict[e[toProp]];
        nodeDict[e[toProp]].fromNodes[e[fromProp]]  = nodeDict[e[fromProp]];
      }
    });

    return {
      nodeDict: nodeDict,
      groupDict: groupDict
    }
  }


  function removeSubSet(target, subset) {
    if (Object.keys(subset).every(function(key){
      return target[key];
    })){
      Object.keys(subset).forEach(function(key){
        return delete target[key];
      });
      return true;
    }
    return false;
  }

  function clusterGroupToGroupLinks(groupDict){
    var groupKeys = Object.keys(groupDict);

    groupKeys.forEach(function(groupId){
      var group = groupDict[groupId];
      groupKeys.forEach(function(gToClusterId){
        var gToCluster = groupDict[gToClusterId];
        if (removeSubSet(group.fromNodes, gToCluster.members)){
          group.fromNodes[gToClusterId] = gToCluster;
        }
        if (removeSubSet(group.toNodes, gToCluster.members)){
          group.toNodes[gToClusterId] = gToCluster;
        }
      });
    });
  }
  function createClusteredLinksDictionaries(N, E, nProp, toProp, fromProp) {
    var graphDictionaries = createGraphDictionaries(N, E, nProp, toProp, fromProp);
    var nodeDict  = graphDictionaries.nodeDict;
    var groupDict = graphDictionaries.groupDict;

    var filteredLinks = E.filter(function(currLink){
      var toNode = nodeDict[currLink[toProp]];
      var fromNode = nodeDict[currLink[fromProp]];
      var res = true;
      //let currLink = fromNode -----> toNode
      //if toNode is in a group and all the group members has a link from fromNode
      //add a link fromNode -----> group
      if (toNode && toNode.groupObject && values(toNode.groupObject.members).every(function(member){
          return member.fromNodes[currLink[fromProp]] === fromNode;
        })){
        toNode.groupObject.fromNodes[currLink[fromProp]] = fromNode;
        res = false;
      }

      //if fromNode is in a group and all the group members has a link to toNode
      //add a link group -----> toNode
      if (fromNode && fromNode.groupObject && values(fromNode.groupObject.members).every(function(member){
          return member.toNodes[currLink[toProp]] === toNode;
        })){
        fromNode.groupObject.toNodes[currLink[toProp]] = toNode;
        res = false;
      }

      return res;
    });

    clusterGroupToGroupLinks(groupDict);

    var groupLinks = [];
    Object.keys(groupDict).forEach(function(groupId){
      var toLinks = Object.keys(groupDict[groupId].toNodes).map(function(to){
        var link = {};
        link[fromProp]  = groupId;
        link[toProp]    = to;
        return link;
      });
      // the reason of using reduce instead of map is that
      // if the link is from group to another group we already added it
      var fromLinks = Object.keys(groupDict[groupId].fromNodes).reduce(function(res, from){
        if(groupDict[from]){
          return res;
        } else {
          var link = {};
          link[fromProp]  = from;
          link[toProp]    = groupId;
          res.push(link);
        }
        return res;
      }, []);
      groupLinks = groupLinks.concat(toLinks, fromLinks);
    });

    return {
      nodeDict: nodeDict,
      groupDict: groupDict,
      links: filteredLinks.concat(groupLinks)
    }
  }


  return {
    deduceGroups:                     deduceGroups,
    createClusteredLinksDictionaries: createClusteredLinksDictionaries,
    createGraphDictionaries:          createGraphDictionaries
  };
});