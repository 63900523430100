import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'base/jsUtils';
import WindowResizeHandler from './dom/WindowResizeHandler';


export default class ScrollableArea extends Component {
  static propTypes = {
    win: PropTypes.object,
    onScroll: PropTypes.func
  };

  static defaultProps = {
    onScroll: noop
  };

  _windowResizeHandler = null;

  scrollTo = (top, left) => {
    this.scrollableAreaRef.scrollTop = top;
    this.scrollableAreaRef.scrollLeft = left;
    this._triggerOnScroll(this.scrollableAreaRef.scrollTop, this.scrollableAreaRef.scrollLeft);
  };

  _triggerOnScroll = (scrollTop, scrollLeft) => {
    this.props.onScroll(scrollTop, scrollLeft);
  };

  _onScrollHandler = (event) => {
    this.props.onScroll(event.target.scrollTop, event.target.scrollLeft);
  };

  _setScrollableStateOnResize = () => {
    const { scrollTop, scrollLeft } = this.scrollableAreaRef;
    this._triggerOnScroll(scrollTop, scrollLeft);
  };

  getScrollPosition = () => {
    return {
      top: this.scrollableAreaRef.scrollTop,
      left: this.scrollableAreaRef.scrollLeft
    };
  };

  componentDidMount() {
    const { win } = this.props;
    this._windowResizeHandler = new WindowResizeHandler(this._setScrollableStateOnResize, 10, win);
    this._windowResizeHandler.captureResize();
  }

  componentWillUnmount() {
    this._windowResizeHandler.releaseResize();
    this._windowResizeHandler = null;
  }

  render() {
    const { children } = this.props;

    return (
      <div
        ref={node => this.scrollableAreaRef = node}
        className='scrollable-area'
        onScroll={this._onScrollHandler}>

        {children}
      </div>
    );
  }
}