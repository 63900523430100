import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'components/common/floating';
import utils from '../utils';
import SimpleFormComponent from '../SimpleFormComponent';
import actions from '../actions';

export default SimpleFormComponent(class extends Component {
  static propTypes = {
    //store - The main store of the SimpleForm that the input is child of.
    store: PropTypes.object,
    bind: PropTypes.string,
    bound: PropTypes.string,
    textProp: PropTypes.string,
    valueProp: PropTypes.string,
    options: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]),
    emptyValue: PropTypes.any,
    size: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    style: PropTypes.object,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    visible: PropTypes.bool,
    col: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    offset: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    onChange: PropTypes.func,
    actionType: PropTypes.string
  };

  static defaultProps = {
    bind: '',
    col: 12,
    offset: 0,
    actionType: 'UPDATE'
  };

  state = {
    cursor: utils.getCursorFromStringKey(this.props.bind),
    boundValue: ''
  };

  getValue = () => {
    const { store, bind } = this.props;
    const model = store.get(bind);

    return utils.isObject(model) ? model.value : model;
  };

  handleSelect = (e, val) => {
    if (val === this.getValue()) {
      return;
    }

    const { store, bind, onChange, actionType } = this.props;
    if (typeof onChange === 'function') {
      return onChange(bind, val);
    }

    const model = store.get(bind);
    const newValue = !utils.isObject(model) ? val : {
      ...model,
      value: val
    };

    store.dispatch(actions.update(bind, newValue, actionType));
  };

  getOptions = (model, options) => {

    var { store, bound, emptyValue } = this.props;
    var boundValue = utils.isString(bound) ? store.get(bound) : null;

    var opts = utils.isObject(options) || Array.isArray(options) ? options :
      model && utils.isObject(model.options) || Array.isArray(model.options) ?
        model.options : [];

    opts = boundValue !== null && Array.isArray(opts[boundValue]) ? opts[boundValue] :
      Array.isArray(opts) ? opts :
        [];

    var ret = Object.assign([], opts);

    if (!utils.isUndefined(emptyValue)) {
      ret.splice(0, 0, emptyValue);
    }

    return ret;

  };

  clearStoreValue = () => {

    var { store, bind, actionType } = this.props;

    store.dispatch(actions.update(bind, '', actionType));

  };

  componentWillReceiveProps(nextProps) {

    var { store, bind, bound } = this.props;

    if (utils.isString(bound)) {
      let boundValue = store.get(bound);
      if (this.state.boundValue !== boundValue) {
        this.setState({
          boundValue: boundValue
        });
      }
    }

  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.boundValue !== prevState.boundValue) {
      this.clearStoreValue();
    }

  }

  render() {

    const {
      store,
      bind,
      bound,
      textProp,
      valueProp,
      options,
      visible,
      style,
      className,
      col,
      offset,
      disabled,
      ...props
    } = this.props;
    const model = store.get(bind);
    const value = this.getValue();

    return <div className={utils.combineClassNames(['input-dropdown', className])} style={style}>
      <Dropdown options={this.getOptions(model, options)}
                {...props}
                textProp={textProp}
                valueProp={valueProp}
                value={value}
                style={{ width: '100%' }}
                disabled={disabled}
                onSelect={this.handleSelect}
      />
    </div>;

  }
});
