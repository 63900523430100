const isUndefined = o => typeof o === 'undefined';
const isString = str => typeof str === 'string';

const getItemByPath = (path, obj) => {
  return path.reduce((acc, key) => {
    return isUndefined(acc) ? undefined : acc[key];
  }, obj);
};

class SettingsManager {

  settings = {
    id: undefined,
    user: {}/*{
            measurUnit: undefined,
            name: undefined,
            nwid: undefined,
            securityGroups: [],
            site: undefined
        }*/,
    currentFolderNwid: undefined,
    passwordPolicy: {}/*{
            AllowEmptyPassword: undefined,
            DiffrentFromLastN: undefined,
            Expiration: undefined,
            MinimumNumberOfCharacters: undefined,
            MustContainLowerCase: undefined,
            MustContainNumber: undefined,
            MustContainUpperCase: undefined,
            NotIncrementPrevious: undefined,
            minimumPasswordLength: undefined,
            passwordDifferentFromLast: undefined,
            passwordExpirationDays: undefined,
        }*/,
    uniqueZoneCodeInPublication: undefined,
    uniqueZoneCodeInAllPublications: undefined,
    showRunsInPlanTree: undefined,
    clientConfiguration: {}/*{
            PageViewTarget: undefined,
            PdfViewTarget: undefined,
            allButBlackPreloading: undefined,
            betaFeatures: {},
            defaultLoadViewTimeoutSec: undefined,
            pageViewActiveBox: undefined,
            pageViewShowBoxCombo: undefined
        }*/,
    productSorting: {}/*{
            book: undefined,
            edition: undefined,
            section: undefined,
            zone: undefined
        }*/,
    resourceFeatures: {}/*{
            undefined: undefined
        }*/,
    siteName: undefined,
    customerName: undefined,
    serverName: undefined,
    showSoftProofSections: undefined,
    version: undefined,
    mode: undefined,
    requestVersion: undefined,
    webAppPath: undefined,
    subProductName: undefined,
    'update-password-message': undefined
  };

  get(path) {
    if (isString(path)) return this.settings[path];

    if (Array.isArray(path)) {
      return getItemByPath(path, this.settings);
    }
  }

  set(path, value) {
    if (isString(path)) this.settings[path] = value;

    if (Array.isArray(path)) {
      const key = path[path.length - 1];
      const itemToModify = getItemByPath(path.slice(0, -1), this.settings);
      itemToModify[key] = value;
    }
  }

  getLengthUnit() {
    return this.settings.user.measurUnit;
  }

  getTreeDateFormat() {
    return this.settings.treeDateFormat;
  }

  setTreeDateFormat(treeDateFormat) {
    this.settings.treeDateFormat = treeDateFormat;
  }

  getTabDateFormat() {
    return this.settings.tabDateFormat;
  }

  setTabDateFormat(tabDateFormat) {
    this.settings.tabDateFormat = tabDateFormat;
  }

  getProductionRunDateFormat() {
    return this.settings.productionRunDateFormat;
  }

  setProductionRunDateFormat(productionRunDateFormat) {
    this.settings.productionRunDateFormat = productionRunDateFormat;
  }

  getServerTimeZone() {
    return this.settings.serverTimeZone;
  }

  getFolderTimeZone() {
    return this.settings.folderTimeZone;
  }

  getFolderInfo() {
    return this.settings.folderInfo;
  }
}

export default new SettingsManager();
