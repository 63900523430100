import React, { Component } from 'react';
import PropTypes from 'prop-types';
import utils from '../utils';
import SimpleFormComponent from '../SimpleFormComponent';
import actions from '../actions';

export default SimpleFormComponent(class extends Component {
  static propTypes = {

    //store - The main store of the SimpleForm that the input is child of.
    store: PropTypes.object,

    bind: PropTypes.string,

    bound: PropTypes.string,

    // { 'text': '', 'label': ''}
    options: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ]),

    size: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),

    style: PropTypes.object,

    className: PropTypes.string,

    disabled: PropTypes.bool,

    visible: PropTypes.bool,

    col: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),

    offset: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ])

  };

  static defaultProps = {
    bind: '',
    col: 12,
    offset: 0
  };

  state = {
    cursor: utils.getCursotFromStringKey(this.props.bind),
    boundValue: ''
  };

  handleChange = (ev) => {

    var { store, bind } = this.props;
    var model = store.get(bind);
    var newValue = !utils.isObject(model) ? ev.target.value : {
      ...model,
      value: ev.target.value
    };

    //store.actions.update(bind, newValue);
    store.dispatch(actions.update(bind, newValue));

  };

  getOptions = (model, options) => {

    var { store, bound } = this.props;
    var boundValue = utils.isString(bound) ? store.get(bound) : null;

    var opts = utils.isObject(options) || Array.isArray(options) ? options :
      model && utils.isObject(model.options) || Array.isArray(model.options) ?
        model.options : [];

    return boundValue !== null && Array.isArray(opts[boundValue]) ? opts[boundValue] :
      Array.isArray(opts) ? opts :
        [];

  };

  renderOptions = (model, options) => {

    var opts = this.getOptions(model, options);

    return opts.map(function (opt, index) {
      let optValue = utils.isObject(opt) ? opt.value : opt;
      let optText = utils.isObject(opt) ? opt.text : opt;

      return <option key={index} value={optValue}>
        {optText}
      </option>;
    });

  };

  clearStoreValue = () => {

    var { store, bind } = this.props;

    //store.actions.update(bind, '');
    store.dispatch(actions.update(bind, ''));

  };

  componentWillReceiveProps(nextProps) {

    var { store, bind, bound } = this.props;

    if (utils.isString(bound)) {
      let boundValue = store.get(bound);
      if (this.state.boundValue !== boundValue) {
        this.setState({
          boundValue: boundValue
        });
      }
    }

  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.boundValue !== prevState.boundValue) {
      this.clearStoreValue();
    }

  }

  render() {

    var { store, bind, bound, options, visible, style, className, col, offset, ...props } = this.props;
    var model = store.get(bind);
    var value = !utils.isObject(model) ? model : model.value;

    return <div className={utils.combineClassNames(['input-select', className])} style={style}>
      <select value={value}
        onChange={this.handleChange}
        {...props}>
        {this.renderOptions(model, options)}
      </select>
    </div>;

  }
});
