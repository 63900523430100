define(['react', 'prop-types', 'widgets/ReactDataGrid/src/ReactDataGrid', 'widgets/SimpleForm/src/index', 'sandbox', './columnsDefinition', './Guid', './utils'], function (React, PropTypes, ReactDataGrid, SimpleForm, sandbox, columnsDefinition, guid, utils) {
  'use strict';

  const { Component } = React;
  var { Form, Table, Row, Container } = SimpleForm;
  var { translate } = sandbox.localization;

  return class view extends Component {
    constructor(props) {
      super(props);
      this.onAddRow = this.onAddRow.bind(this);
      this.onRemoveRow = this.onRemoveRow.bind(this);
    }

    static propTypes = {

      showLoadingProgress: PropTypes.bool,

      store: PropTypes.any,

      onOKButtonClick: PropTypes.func,

      onCancelButtonClick: PropTypes.func

    }

    onAddRow(bind, row) {

      var { store } = this.props;
      var nextNameIndex = utils.getNextNameIndex(store.get('model.availableRules.rules'), 'New Rule');


      var newRow = {
        guid: guid(),
        additionalConditions: [],
        book: '*',
        day: '*',
        edition: '*',
        name: nextNameIndex > 0 ? ['New Rule', nextNameIndex].join(' ') : 'New Rule',
        press: '*',
        publication: '*',
        ruleLayoutGroup2UP: '',
        ruleLayoutGroup4UP: '',
        ruleLayoutGroupBroadSheet: '',
        ruleLayoutGroupTabloid: '',
        zone: '*'
      };

      //store.actions.add(bind, newRow, row);
      store.dispatch({
        type: 'ADD',
        bind: bind,
        index: row,
        value: newRow
      });
    }

    onRemoveRow(bind, row) {

      var { store } = this.props;

      //store.actions.remove(bind, row);
      store.dispatch({
        type: 'REMOVE',
        bind: bind,
        index: row
      });

    }

    render() {

      var { store } = this.props;
      var columns = columnsDefinition(store);

      return <div className="pro-modules-layout-group-rules">

        <Form store={store}>

          <Row className="full-height"  >
            <Table bind="model.availableRules.rules"
              columnKey="guid"
              columnDefinitions={columns}
              onAddRow={this.onAddRow}
              onRemoveRow={this.onRemoveRow}
              headerHeight={40}
              virtualScroll={true}
              fullHeight={true}
            />
          </Row>

        </Form>

        <div className="view-footer">
          <button className="primary" onClick={this.props.onOKButtonClick} disabled={store.get('okButtonDisabled')} >{translate('OK')}</button>
          <button onClick={this.props.onCancelButtonClick}>{translate('Cancel')}</button>
        </div>

      </div>;

    }

  }

});