import sandbox from 'sandbox';
import { fromServerDateOnly, parseDate } from 'core/dates';
import { getMainStatus } from 'utilities/statuses';

export const SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc',
  NONE: 'none',
};

const getDefaultStatus = item => getMainStatus(item) || {};

export const isUndefined = o => typeof o === 'undefined';
export const isObject = o => typeof o === 'object';
export const isFunction = o => typeof o === 'function';

export const autoBindProps = (module) => {
  for (var prop in module) {
    if (prop === "_super") continue;
    if (isFunction(module[prop])) module[prop] = module[prop].bind(module);
  }
};

export const getModelItemsByType = (model, type) => {
  if (isUndefined(model)) return [];

  let itemsToReturn = [];

  if (isObject(model)) {
    for (let key in model) {
      let item = model[key];

      if (isObject(item) && item.type === type) {
        itemsToReturn = itemsToReturn.concat(item);
      }
      else {
        let returnedPages = getModelItemsByType(item, type);
        if (returnedPages.length > 0) itemsToReturn = itemsToReturn.concat(returnedPages);
      }
    }
  }
  return itemsToReturn;
};

export const flowStepIconNameGetter = page => {
  const status = getDefaultStatus(page);
  return status?.time &&
    status.statusType !== 'NotAssigned' &&
    status.flowStepType
    ? sandbox.icons.getTemplateIcon(
      status.flowStepType,
      'tiny',
      status.flowStepIconName
    )
    : '';
};

export const statusIconNameGetter = page => {
  const status = getDefaultStatus(page);
  if (!status || !status.time || status.statusType === 'NotAssigned')
    return '';

  const statusType = status.statusType.toLowerCase();

  if (statusType === 'waiting') return sandbox.icons.getGeneralIcon('status', 'wait');
  if (statusType === 'success') return sandbox.icons.getGeneralIcon('status', 'finished');
  if (statusType === 'error' && status.flowStepType === 'workflow/step/flow/approval')
    return sandbox.icons.getModuleIcon('Thumbnail', 'reject-small');

  return sandbox.icons.getGeneralIcon('status', statusType);
};

export const convertIssueDate = time => {
  const date = parseDate(time, 'MM/dd/yyyy');
  const localizedDate = date ? sandbox.localization.toLocaleShortDate(date) : "";

  return { time: date, localizedDate };
};

export const pageCreator = (page = {}) => {
  return {
    nwid: page.nwid,
    pageContentNwid: page.pageContent.nwid,
    iconUID: Date.now(),
    filename: page.originalName,
    flowStepIconName: flowStepIconNameGetter(page),
    statusIconName: statusIconNameGetter(page),
    mniNumber: page.mniNumber || '',
    category: page.category,
    categoryDate: convertIssueDate(page.issueDate),
    inputTime: page.pageContent.inputTime,
    advertiser: page.advertiser,
    adInstructions: page.adInstructions,
    otherInformation: page.otherInformation,
  };
};

export const magazineComparator = filterValue => item => {
  if (filterValue === '') return true;

  return item.category === filterValue;
};

export const categoryDateComparator = filterValue => item => {
  if (filterValue === '') return true;

  return item.categoryDate.time === filterValue;
};

export const getFilterOptionValue = (path = []) => item => {
  return path.reduce((acc, pathItem) => {
    if (isUndefined(acc)) return undefined;

    return acc[pathItem];
  }, item);
};

export const getFilterOptionText = (path = []) => item => {
  return path.reduce((acc, pathItem) => {
    if (isUndefined(acc)) return undefined;

    return acc[pathItem];
  }, item);
};

export const getPassedFilters = (filters = []) => item => {
  return filters.every(filter => {
    return filter.comparator(filter.value)(item);
  });
};

export const filtersHasTheSameValues = (filtersA, filtersB) => {
  if (filtersA.length !== filtersB.length) return false;

  return filtersA.every((filterA, filterAIndex) => {
    const filterB = filtersB[filterAIndex];

    return filterA.name === filterB.name && filterA.value === filterB.value ? true : false;
  });
};

export const sorterCreator = (columnKey, type, prop, direction = SORT_ORDER.ASC) => {
  return {
    columnKey,
    direction,
    type,
    prop
  };
};

export const findSorterIndex = (sorters, columnKey) => {
  return sorters.findIndex(sorter => sorter.columnKey === columnKey);
}

export const switchSorterDirection = (sorter) => {
  return {
    ...sorter,
    direction: sorter.direction === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC
  };
};

export const getSortComparator = (sorters) => {
  const comparator = sandbox.sorting.getComparator(sorters.map(sorter => ({
    type: sorter.type[sorter.direction],
    prop: sorter.prop
  })));

  return (itemA, itemB) => {
    return comparator(itemA, itemB);
  }
};

export const getIsInRightSortPosition = (sortComparator, lowerItem, item, higherItem) => {
  const lowerItemCompare = isUndefined(lowerItem) ? 0 : sortComparator(lowerItem, item);
  const higherItemCompare = isUndefined(higherItem) ? 0 : sortComparator(higherItem, item);

  return (lowerItemCompare == -1 || lowerItemCompare === 0) && (higherItemCompare === 1 || higherItemCompare === 0) ? true : false;
};

const findNewIndexBySort = (items, sortComparator, itemToFind) => {
  var low = 0, high = items.length - 1, i, comparison, idx = 0;
  while (low <= high) {
    i = Math.floor((low + high) / 2);
    comparison = sortComparator(items[i], itemToFind);
    if (comparison < 0) { low = i + 1; idx = low; continue; }
    if (comparison > 0) { high = i - 1; continue; }
    idx = i;
    break;
  }
  return idx;
};

const addPageToRightPosition = (items, sortComparator, item) => {
  const newIndex = findNewIndexBySort(items, sortComparator, item);

  return [...items.slice(0, newIndex), item, ...items.slice(newIndex)];
}

export const getFilteredPagesWithPageInRightPosition = (filteredPages, sortComparator, page) => {
  const pageIndex = filteredPages.findIndex(filteredPage => filteredPage.nwid === page.nwid);

  if (pageIndex < 0) return addPageToRightPosition(filteredPages, sortComparator, page);

  const lowerPage = pageIndex > 0 ? filteredPages[pageIndex - 1] : undefined;
  const higherPage = pageIndex < filteredPages.length ? filteredPages[pageIndex + 1] : undefined;

  const isInRightSortPosition = getIsInRightSortPosition(sortComparator, lowerPage, page, higherPage);

  if (isInRightSortPosition) {
    return [...filteredPages.slice(0, pageIndex), page, ...filteredPages.slice(pageIndex + 1)];
  }

  const filteredPagesWithoutOldPage = filteredPages.filter(filteredPage => filteredPage.nwid !== page.nwid);

  return addPageToRightPosition(filteredPagesWithoutOldPage, sortComparator, page);

};

export const getColumnsWidth = (columns) => {
  return Object.keys(columns).reduce((columnsWidth, column) => {
    return {
      ...columnsWidth,
      [column]: columns[column].width
    };
  }, {});
};