/**
 * @name plate
 * @file Defines Plate element functionality
 *
 * @author Boris
 * @since: 2016-07-17
 */

import React from 'react';
import Fabric from 'fabric';
import sandbox from 'sandbox';
import SimpleForm from 'widgets/SimpleForm/src/index';
import utils from '../utils/utils';
import canvasUtils from '../utils/canvasUtils';
import propertiesCommon from './propertiesCommon';

const { translate } = sandbox.localization;
const futils = SimpleForm.utils;

const BASE_PATH = 'layout.plate';
const TITLE = translate('Plate');

const FILL_COLOR = '#F0F0F0';
const STROKE = 'grey';
const DEFAULT_RESOLUTION = 1000;

const { WIDTH, HEIGHT } = propertiesCommon;

const NAME = { ...propertiesCommon.NAME, disabled: true };

const MEDIA_TYPE = {
  key: 'mediaType',
  caption: translate('Media type'),
  type: 'string'
};

const PLATE_META = [
  NAME,
  WIDTH,
  HEIGHT,
  MEDIA_TYPE
];

const USE_IMAGE_RESOLUTION = {
  key: 'useImageResolution',
  caption: translate('Use image resolution'),
  type: 'bool',
  defaultValue: false
};

const RESOLUTION_X = {
  key: 'resolutionX',
  caption: translate('Resolution X'),
  type: 'int',
  mask: 'posInt',
  defaultValue: DEFAULT_RESOLUTION
};

const RESOLUTION_Y = {
  key: 'resolutionY',
  caption: translate('Resolution Y'),
  type: 'int',
  mask: 'posInt',
  defaultValue: DEFAULT_RESOLUTION
};

const LAYOUT_META = [
  USE_IMAGE_RESOLUTION,
  RESOLUTION_X,
  RESOLUTION_Y
];

export default (editor) => {

  const cutils = canvasUtils(editor);
  const canvas = editor.getMainCanvas();

  const getPlateMeta = () => {
    return PLATE_META;
  };

  const getLayoutMeta = (element) => {
    const meta = LAYOUT_META;

    RESOLUTION_X.hidden = element.useImageResolution === true;
    RESOLUTION_Y.hidden = element.useImageResolution === true;

    return meta;
  };

  const hasLayoutProperties = (state) => {
    return state.layoutType === 'layout';
  };

  const isLayoutProperty = (propertyPath) => {
    var key = futils.pathTail(propertyPath);
    return !!LAYOUT_META.find(p => p.key === key);
  };

  const setDefaultElementValues = (state, element) => {
    propertiesCommon.setDefaultValues(state, element, getPlateMeta());
    if (hasLayoutProperties(state)) {
      propertiesCommon.setDefaultValues(state, state.layout, getLayoutMeta(state.layout));
    }
  };

  const updateLayoutProperty = (state, element, elementPath, propertyPath, propertyValue) => {
    var newState = propertiesCommon.updateProperty(state, element, elementPath, propertyPath, propertyValue);
    return newState;
  };

  const updatePlateProperty = (state, element, elementPath, propertyPath, propertyValue) => {
    var newState = propertiesCommon.updateProperty(state, element, elementPath, propertyPath, propertyValue);
    var newElement = futils.get(newState, elementPath);

    updateShape(newState, newElement);

    return newState;
  };

  const updateProperty = (state, element, elementPath, propertyPath, propertyValue) => {
    var newState = state;
    if (isLayoutProperty(propertyPath)) {
      newState = updateLayoutProperty(newState, element, elementPath, propertyPath, propertyValue)
    } else {
      newState = updatePlateProperty(newState, element, elementPath, propertyPath, propertyValue)
    }

    return newState;
  };

  const shapeTransforming = (state, element) => {
    var shape = element.shape;
    var plateRect = shape.plateRect;
    utils.unscaleShape(plateRect);
    var r = utils.plateRectangleToSystem(plateRect);
    var newElement = { ...element, width: r.width, height: r.height };

    return newElement;
  };

  const createPlateRect = (element) => {
    var plateRect = new Fabric.Rect({
      hasRotatingPoint: false,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
      fill: FILL_COLOR,
      stroke: STROKE,
      originX: 'left',
      originY: 'top',
      hoverCursor: 'default',
      moveCursor: 'default'
    });

    return plateRect;
  };

  const toPlateRectangle = (state, element) => {
    return utils.toCanvasRectangle(state.plateRectangle, 0, 0, element.width, element.height);
  };

  const updatePlateRect = (state, element) => {
    var r = toPlateRectangle(state, element);
    var plateRect = element.shape.plateRect;
    plateRect.set({
      left: r.left,
      top: r.top,
      width: r.width,
      height: r.height,
      scaleX: 1,
      scaleY: 1
    });
    plateRect.setCoords();
  };

  const createShape = (state, element, elementPath) => {
    var plateRect = createPlateRect(element);

    var shape = {
      elementPath,
      plateRect
    };

    plateRect.shape = shape;
    element.shape = shape;

    updateShape(state, element);
    addShape(state, element);

    return shape;
  };

  const addShape = (state, element) => {
    if (!element || !element.shape) {
      return;
    }

    canvas.add(element.shape.plateRect);
  };

  const removeShape = (state, element) => {
    if (!element || !element.shape) {
      return;
    }

    var shape = element.shape;
    canvas.remove(shape.plateRect);
  };


  const updateShape = (state, element) => {
    updatePlateRect(state, element);
  };

  const activateShape = (state, element) => {
    cutils.setActiveObject(element.shape.plateRect);
  };

  const renderPlateProperties = (store, element, elementPath) => {
    return propertiesCommon.renderProperties(store, element, elementPath, getPlateMeta());
  };

  const renderLayoutProperties = (store, element, elementPath) => {
    var state = store.getState();
    return !hasLayoutProperties(state) ? null : propertiesCommon.renderProperties(store, state.layout, 'layout', getLayoutMeta(state.layout));
  };

  const renderProperties = (store, element, elementPath) => {
    return (
      <div>
        {renderPlateProperties(store, element, elementPath)}
        {renderLayoutProperties(store, element, elementPath)}
      </div>
    );
  };

  const getInfo = (state) => {
    return {
      basePath: BASE_PATH,
      title: TITLE
    };
  };

  const getAlignmentInfo = (state, element) => {
    return utils.getAlignmentInfo(element.shape.plateRect);
  };

  return {
    setDefaultElementValues,
    updateProperty,
    shapeTransforming,
    createShape,
    updateShape,
    addShape,
    removeShape,
    activateShape,
    renderProperties,
    getInfo,
    getAlignmentInfo
  }

};
