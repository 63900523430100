import React from 'react';
import TextInput from 'components/common/inputs/TextInput';
import InputCell from './InputCell';

function TextInputCell(props) {
  return (
    <InputCell InputComponent={TextInput} {...props}/>
  );
}

export default TextInputCell;
