/**
 * Created by moshemal on 11/26/14.
 */
import AbstractAction from 'AbstractAction';
import request from 'sandbox/request';
import toastService from 'core/services/toastService';
import { translate } from 'core/services/localization';

function toAdapter(node) {
  var step = {
    clazz: node.clazz || '',
    label: node.label || '',
    name: node.name || '',
    description: node.description || '',
    properties: node.properties || {}
  }
  if (node.nwid.indexOf('new') === 0) {
    step.tempId = node.nwid;
    step.nwid = '';
  } else {
    step.nwid = node.nwid;
  }
  return step;
}

module.exports = AbstractAction.extend({
  isApplicable: function () {
    console.log(this);
    return this.module._status.isEditable;
  },
  execute: function () {
    var module = this.module,
      adapterFlows = [];
    module._forEachGraph(function (graph, name) {
      graph.applyLocalChanges();
      var adapters = graph.exportNodesData().map(toAdapter);
      var transitions = graph.exportTransitions().map(function (e) {
        return {
          source: e['sourceNwId'],
          target: e['targetNwId']
        }
      });
      adapterFlows.push({
        name: name,
        adapters: adapters,
        transitions: transitions
      });
    });

    var requiredParameters = module.getRequiredParameters()

    var objToSave = {
      nwid: requiredParameters.rootId,
      type: requiredParameters.rootType,
      adapterFlows: adapterFlows
    };
    return request.execServerAction(this.nwid, '', requiredParameters.rootId,
      requiredParameters.rootType, module.projectorId, this.parameters.scriptName, objToSave)
      .then(res => {
        if (res.data) {
          if (typeof res.data.error === 'undefined') {
            module._forEachGraph(function (graph, name) {
              graph.updateNodesIds(res.data[name]);
            });
            module._savePreferencesNow();
          } else {
            toastService.errorToast(translate('Add adapter failed'), res.data.message);
          }
        }
      });
  }
});