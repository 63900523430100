const sandbox = require('sandbox');
const { translate } = sandbox.localization;

function planToContext(plan) {
  var context = {
    ...plan,
    'received-time': plan['received-time'] ? sandbox.localization.toLocaleShortDate(new Date(plan['received-time'])) : 'N/A',
    'import-time': plan['import-time'] ? sandbox.localization.toLocaleShortDate(new Date(plan['import-time'])) : 'N/A'
  };
  return context;
}

export const title_plans = translate('Plans');
export const title_changes = translate('Changes');

export const msg_select_files = translate('Please select 2 plan files to compare.');
export const msg_no_change = translate('No relevant changes found.');

export const lbl_from = translate('FROM:');
export const lbl_to = translate('TO:');

export function btn_tooltip_plan_uploaded(plan) {
  const context = planToContext(plan);
  return translate('Plan was uploaded at {1}', context['received-time']);
}

export function btn_tooltip_plan_commited(plan) {
  const context = planToContext(plan);
  return translate('Plan was uploaded at {1} and Commited at {2}', context['received-time'], context['import-time']);
}

export function btn_tooltip_plan_approved(plan) {
  const context = planToContext(plan);
  return translate('Plan was uploaded at {1}, Commited at {2} and Approved', context['received-time'], context['import-time']);
}

export function entity_number_change_title(context) {
  return translate('The number of {1} changed from {2} to {3}', context['itemName'], context['change.oldValue'], context['change.newValue']);
}

export function entity_number_change_text(context) {
  return translate('The number of {1} changed from {2} to {3}', context['itemName'], context['change.oldValue'], context['change.newValue']);
}

export function property_number_change_title(context) {
  return translate('The number of properties of {1} changed from {2} to {3}', context['itemName'], context['change.oldValue'], context['change.newValue']);
}

export function property_number_change_text(context) {
  return translate('The number of properties of {1} changed from {2} to {3}', context['itemName'], context['change.oldValue'], context['change.newValue']);
}

export const entity_added_title = translate('Added');
export const entity_added_text = translate('Added');
export const entity_removed_title = translate('Removed');
export const entity_removed_text = translate('Removed');
export const property_added_title = translate('Property added');
export const property_added_text = translate('Property added');
export const property_removed_title = translate('Property removed');
export const property_removed_text = translate('Property removed');

export function sequence_change_title(context) {
  return translate('found an indexing change from {1} to {2}', context['change.oldValue'], context['change.newValue']);
}

export function sequence_change_text(context) {
  return translate('found an indexing change from {1} to {2}', context['change.oldValue'], context['change.newValue']);
}

export function position_x_change_title(context) {
  return translate('The fragment x property changed from {1} to {2}', context['change.oldValue'], context['change.newValue']);
}

export function position_x_change_text(context) {
  return translate('The fragment x property changed from {1} to {2}', context['change.oldValue'], context['change.newValue']);
}

export function position_y_change_title(context) {
  return translate('The fragment y property changed from {1} to {2}', context['change.oldValue'], context['change.newValue']);
}

export function position_y_change_text(context) {
  return translate('The fragment y property changed from {1} to {2}', context['change.oldValue'], context['change.newValue']);
}

export function position_width_change_title(context) {
  return translate('The fragment width property changed from {1} to {2}', context['change.oldValue'], context['change.newValue']);
}

export function position_width_change_text(context) {
  return translate('The fragment width property changed from {1} to {2}', context['change.oldValue'], context['change.newValue']);
}

export function position_height_change_title(context) {
  return translate('The fragment height property changed from {1} to {2}', context['change.oldValue'], context['change.newValue']);
}

export function position_height_change_text(context) {
  return translate('The fragment height property changed from {1} to {2}', context['change.oldValue'], context['change.newValue']);
}

export function to_panorama_title(context) {
  return translate('Changed to Panorama : {1} changed to {2}', context['change.oldValue'], context['change.newValue']);
}

export function to_panorama_text(context) {
  return translate('Changed to Panorama : {1} changed to {2}', context['change.oldValue'], context['change.newValue']);
}

export function to_single_title(context) {
  return translate('Changed to Single : {1} changed to {2}', context['change.oldValue'], context['change.newValue']);
}

export function to_single_text(context) {
  return translate('Changed to Single : {1} changed to {2}', context['change.oldValue'], context['change.newValue']);
}

export const content_change_title = translate('Content changed');
export const content_change_text = translate('Content changed');

export function content_change_2_title(context) {
  return translate('changed to {1}', context['change.newValue']);
}

export function content_change_2_text(context) {
  return translate('changed to {1}', context['change.newValue']);
}

export function default_change_title(context) {
  return translate('{1} , {2} , {3}', context['change.changeType'], context['change.oldValue'], context['change.newValue']);
}

export function default_change_text(context) {
  return translate('{1} , {2} , {3}', context['change.changeType'], context['change.oldValue'], context['change.newValue']);
}

export const replaced_title = translate('Replaced');
export const replaced_text = translate('Replaced');
