function sort(items, columnKey, order, fncSort) {
  return items.concat().sort(function (a, b) {
    var resultOrder;
    var sortOrder = order === 'asc' ? 1 : -1;

    if (typeof fncSort === 'function') resultOrder = fncSort(a, b, order);
    if (a[columnKey] < b[columnKey]) resultOrder = 0 - sortOrder;
    if (a[columnKey] > b[columnKey]) resultOrder = sortOrder;
    if (a[columnKey] == b[columnKey]) resultOrder = 0;

    return resultOrder;

  });
}

export default sort;