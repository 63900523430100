/**
 * Created by tzvikas on 5/20/2015.
 */
define(['ember', 'text!../templates/zone.hbs', './Form','kendo-ember', './../../../utils/wizardCommons'],
  function (Ember, MainTemplate, formView, kendoComponents, wizardCommons) {
    'use strict';

    return Ember.View.extend(wizardCommons.viewCommons, {
      onActivate: function(){

      },

      classNames: ['planningWizardForms'],

      template: Ember.Handlebars.compile(MainTemplate),

      formView : formView,

      forms: function (){
        return this.model.forms;
      }.property('model.forms'),

      numPages: function(){
        return this.model.pages.length;
      }.property()




    });
  });