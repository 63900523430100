/**
 * Created by moshem on 1/21/14.
 */
/**
 * @name Localization Service
 * @fileOverview  Localization service is in charge of loading
 * the relevant localization file, storing it in Ember.I18n, changing global lang property
 * and providing localization API
 */

import Ember from 'ember';
import i18n from 'i18n';
import base from 'base';
import baseData from 'base/data';
import browser from 'base/browser';
import { parseDate, formatDate, getDateFormat, getTimeFormat, DATE_FORMAT_TYPE } from 'core/dates';
import pubsub from 'core/managers/pubsub';

// enable attributes in handlebars to be translatable
Ember.TextField.reopen.call(Ember.TextField, Ember.I18n.TranslateableAttributes);

const DEFAULT_LANGUAGE_CODE = 'en-us';

let languagesMap = {};
let currentLanguageCode = DEFAULT_LANGUAGE_CODE;
let defaultLanguageCode = DEFAULT_LANGUAGE_CODE;

function getLanguagesMap(languages) {
  return Object.keys(languages).reduce(function (availableLanguagesMap, languageCode) {
    return {
      ...availableLanguagesMap,
      [languageCode]: {
        dateLocaleCode: languages[languageCode].dateLocaleCode,
        name: languages[languageCode].name
      }
    };
  }, {});
}

function getBrowserLangauge(availableLanguages) {
  const browserLang = browser.getLang().toLowerCase();
  return availableLanguages[browserLang];
}

function getInitialCurrentLanguageCode(defaultLanguageCode, availableLanguages) {
  const cookieLang = browser.getCookie('lang');
  //TODO: langugeageBrowser and DEFAULT_LANGUAGE_CODE should be removed. There should always be a value in defaultLanguageCode.
  return cookieLang ? cookieLang : (defaultLanguageCode || getBrowserLangauge(availableLanguages) || DEFAULT_LANGUAGE_CODE);
}

function init(languagesInfo) {
  defaultLanguageCode = languagesInfo.defaultLanguage;
  currentLanguageCode = DEFAULT_LANGUAGE_CODE;

  let deferred = base.data.deferred();

  if (languagesInfo.availableLanguages) {
    languagesMap = getLanguagesMap(languagesInfo.availableLanguages);
    deferred = loadLocalization(getInitialCurrentLanguageCode(defaultLanguageCode, languagesInfo.availableLanguages));
  }

  return deferred;
}

/**
 * Parses a string representation of a date using the current locale
 * @param date - string representation of a date
 * @param dateFormat - date format string
 * @returns {Date} - instance of Date object or undefined
 */
export function parseLocaleDate(date, dateFormat) {
  return parseDate(date, dateFormat, getLanguage().dateLocaleCode);
}

/**
 * Formats the given date according to the specified dateFormat using the current locale
 *
 * @param {Date} date - instance of the Date object
 * @param {String} dateFormat - date format string
 * @returns {String} - formatted date string
 */
export function toLocaleDate(date, dateFormat) {
  return formatDate(date, dateFormat, getLanguage().dateLocaleCode);
}

export function toLocaleShortDateTime(date, includeSeconds = false) {
  const dateFormat = getLocaleDateFormat();
  const timeFormat = getLocaleTimeFormat(includeSeconds ? DATE_FORMAT_TYPE.MEDIUM : DATE_FORMAT_TYPE.SHORT);

  return toLocaleDate(date, `${dateFormat} ${timeFormat}`);
}

export function toLocaleShortDate(date) {
  return toLocaleDate(date, getLocaleDateFormat());
}

export function toLocaleWeekday(date) {
  const options = { weekday: 'short' };
  return date.toLocaleDateString(getLanguage().dateLocaleCode, options);
}

export function toLocaleShortTime(date, includeSeconds = false) {
  const timeFormat = getLocaleTimeFormat(includeSeconds ? DATE_FORMAT_TYPE.MEDIUM : DATE_FORMAT_TYPE.SHORT);

  return toLocaleDate(date, timeFormat);
}

export function loadLocalization(lang) {
  const deferred = base.data.deferred();

  baseData.ajax({
    url: '/coretex/rest/v2/global/localization/' + lang.toLowerCase(),
    type: 'GET',
    dataType: 'json'
  }).done(data => {
    Ember.I18n.translations = data;
    browser.setCookie("lang", lang, 3);
    currentLanguageCode = lang;
    pubsub.publish('language-changed', lang);
  }).always(() => {
    deferred.resolve(currentLanguageCode);
  });

  return deferred;
}

export function getLanguageCode() {
  return currentLanguageCode;
}

function getLanguage() {
  return languagesMap[getLanguageCode()];
}

export function getLocaleDateFormat(formatType = DATE_FORMAT_TYPE.SHORT) {
  return getDateFormat(getDateLocaleCode(), formatType);
}

export function getLocaleTimeFormat(formatType = DATE_FORMAT_TYPE.SHORT) {
  return getTimeFormat(getDateLocaleCode(), formatType);
}

export function getDateLocaleCode(languageCode = getLanguageCode()) {
  const localeCode = (languagesMap[languageCode] || {}).dateLocaleCode || 'en-US';

  return localeCode;
}

/**
 * Finds translated text by key in the localization dictionary and replace variable parameters by their values.
 * Example: translate("I was born on {1}. My age is {2}.", '24/12/1994', 25) returns
 * "I was born on 24/12/1994. My age is 25."
 *
 * @param {String} key - text to be translated
 * @param {*} context (optional) - variable parameters passed as arguments after the key
 * @returns {String} - translated text
 */

export function translate(key, ...context) {
  if (!key) {
    return '';
  }

  let message = '';
  if (context.length <= 0) {
    message = Ember.I18n.t(key);
  } else {
    message = Ember.I18n.t(key);

    message = message.replace(/({\d+})/g, i => context[i.replace(/[{}]/g, '') - 1]);
  }

  return getLanguageCode() === 'test' && !Ember.I18n.exists(key) ? `++${message}` : message;
}

export function getAvailableLanguages() {
  const availableLanguagesArray = [];

  for (let languageCode in languagesMap) {
    availableLanguagesArray.push({
      code: languageCode,
      name: languagesMap[languageCode].name
    });
  }

  return availableLanguagesArray;
}

module.exports = {
  _name: 'localization',
  _type: 'service',
  init: init,
  loadLocalization,
  getLanguageCode,
  getAvailableLanguages,
  translate,
  parseLocaleDate,
  toLocaleDate,
  toLocaleShortDateTime,
  toLocaleShortDate,
  toLocaleShortTime,
  getLocaleDateFormat,
  getLocaleTimeFormat,
  getDateLocaleCode,
  toLocaleWeekday
};