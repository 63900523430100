import React from 'react';
import PropTypes from 'prop-types';
import Draggable from './Draggable';

const directions = ['n', 'e', 's', 'w', 'se', 'sw', 'ne', 'nw'];

const ResizeHandlers = ({ onResize, boundsRect }) => {

  return (
    <div>
      {directions.map(direction =>
        <Draggable
          key={direction}
          boundsRect={boundsRect}
          onDrag={(e, { end }) => {
            onResize(e, { direction, end });
          }}
        >
          <div className={'crtx-resize-handle-' + direction}/>
        </Draggable>
      )}
    </div>
  );
};

ResizeHandlers.propTypes = {
  onResize: PropTypes.func.isRequired,
  boundsRect: PropTypes.shape({
    left: PropTypes.number,
    top: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};

export default ResizeHandlers;