import React, { forwardRef, useRef, useCallback, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import {noop} from 'base/jsUtils';

const Draggable = forwardRef(({ children, onDrag }, ref) => {

  const onDragRef = useRef(onDrag);
  onDragRef.current = onDrag || noop;

  const dragPointRef = useRef(null);

  const childRef = useRef(null);
  React.useImperativeHandle(ref, () => childRef.current);

  const setChildRef = useCallback(node => {

    if (childRef.current) {
      const prevNode = childRef.current;
      const doc = prevNode.ownerDocument;

      prevNode.removeEventListener('mousedown', handleMouseDown);
      doc.removeEventListener('mousemove', handleMouseMove);
      doc.removeEventListener('mouseup', handleMouseUp)
    }

    if (node) {
      const doc = node.ownerDocument;

      node.addEventListener('mousedown', handleMouseDown);
      doc.addEventListener('mousemove', handleMouseMove);
      doc.addEventListener('mouseup', handleMouseUp);
    }

    childRef.current = node;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMouseDown = useCallback(e => {
    if (!childRef.current) {
      return;
    }

    dragPointRef.current = { x: e.pageX, y: e.pageY };

    onDragRef.current(e, { end: false });
  }, []);

  const handleMouseMove = useCallback(e => {
    if (!dragPointRef.current) {
      return;
    }

    e.preventDefault();

    onDragRef.current(e, { end: false });
  }, []);

  const handleMouseUp = useCallback(e => {
    if (!dragPointRef.current) {
      return;
    }

    dragPointRef.current = null;

    onDragRef.current(e, { end: true });
  }, []);

  return (
    children ?
      cloneElement(Children.only(children), { ref: setChildRef }) :
      null
  );
});

Draggable.propTypes = {
  children: PropTypes.any,
  onDrag: PropTypes.func,
};

export default Draggable;