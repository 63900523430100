/**
 * Created by moshem on 12/23/13.
 */

define([], function () {
  'use strict';
  function applyTableSelection(module, table){
    table.bind('onSelectionChanged', function(selected) {
      //TODO: may be change selected to polyfill convention {oldValue: ...}
      module.updateSelected(selected);
    });
  }

  function applyPlainViewSelection(module, controller){
    var setSelectedItem = controller.setSelectedItem,
        setAllSelected = controller.setAllSelected;
    function updateSelected(){
      module.updateSelected(controller.getSelectedItems());
    }
    controller.setSelectedItem = function(){
      setSelectedItem.apply(this, arguments);
      updateSelected();
    };
		controller.setAllSelected = function(){
			setAllSelected.apply(this, arguments);
			updateSelected();
		};
    updateSelected();
  }

  function applyGraphSelection(module, graph){
    function onSelectionChanged(e){
      module.updateSelected(e.diagram.selection.toArray());
    }
    graph.graph.addDiagramListener("ChangedSelection", onSelectionChanged);
  }
  return {
    applyTableSelection: applyTableSelection,
    applyPlainViewSelection: applyPlainViewSelection,
    applyGraphSelection: applyGraphSelection
  };
});