/* @flow weak */

import React, { Component } from 'react';
import sandbox from 'sandbox';
import PropTypes from 'prop-types';

const { events } = sandbox;

module.exports = class DropdownOptionCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleContentClick = this.handleContentClick.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
  }

  static propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func
  }

  static defaultProperties = {
    placeholder: '',
    onChange: () => { }
  }

  handleChange(event) {
    this.props.onChange(event, event.target.value);
  }

  handleContentClick() {
    if (!this.state.isEditMode) {
      this.setState({
        isEditMode: true
      });
    }
  }

  handleInputFocus() {
    this.refs.cellInput.select();
  }

  handleInputBlur() {
    this.setState({
      isEditMode: false
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isEditMode !== this.state.isEditMode && this.state.isEditMode === true) {
      this.refs.cellInput.select();
    }
  }

  render() {
    const value = this.props.value;
    const placeholder = this.props.placeholder;
    const isEditMode = this.state.isEditMode;
    const contentStyle = {
      display: isEditMode ? 'none' : ''
    };
    const ipnutStyle = {
      display: isEditMode ? '' : 'none'
    };
    const contentValue = value === '' || typeof value === 'undefined' ? <span className="placeholder">{placeholder}</span> : value;
    return <div className="dropdown-option-cell">
      <div className="dropdown-option-cell-content" style={contentStyle} onClick={this.handleContentClick} onFocus={this.handleContentClick} tabIndex="0"><span>{contentValue}</span></div>
      <input ref="cellInput" type="text" value={value} placeholder={placeholder} onFocus={this.handleInputFocus} onBlur={this.handleInputBlur} onChange={this.handleChange} className="dropdown-option-cell-input" style={ipnutStyle} />
    </div>;
  }

}