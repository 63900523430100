import settingsManager from 'core/managers/settings';

export const getMainStatus = item => item?.defaultStatus;

export const DIFF_STATUS_TYPES_ICONS_SUCCESS = {
  success: { moduleName: 'MyBirdeye', icon: 'success_tiny' },
  waiting: { moduleName: 'MyBirdeye', icon: 'wait_tiny' },
  hold: { moduleName: 'MyBirdeye', icon: 'hold' },
  error: { moduleName: 'MyBirdeye', icon: 'reject_tiny' },
  processing: { moduleName: 'MyBirdeye', icon: 'in_progress_tiny' }
};

const STATUS_NAME_TO_INDEX_MAP = {
  'pageLane1': 0,
  'pageLane2': 1,
  'pageLane3': 2,
  'formLane1': 0,
  'formLane2': 1,
  'formLane3': 2,
};

export const getStatusIndex = status => STATUS_NAME_TO_INDEX_MAP[status.name];

export const arrangeAdditionalStatuses = statuses => {
  if (statuses.length === 0) {
    return [];
  }

  let sortedStatuses = Array.from({ length: 3 });
  statuses.forEach(status => {
    if (typeof sortedStatuses[status.statusIndex] === 'undefined') {
      sortedStatuses[status.statusIndex] = status;
    } else {
      sortedStatuses[status.statusIndex] = { type: 'error' };
    }
  });

  return sortedStatuses;
};

export const getLaneNameToDisplayNameMap = () => {

  const folderLanes = settingsManager.getFolderInfo().statusLanes || [];

  return folderLanes.reduce((acc, lane) => {
    if (!acc[lane.name]) {
      acc[lane.name] = lane.displayName;
    }

    return acc;
  }, {});
};

export const ALL_LANES = ['defaultStatus', 'pageLane1Status', 'pageLane2Status', 'pageLane3Status', 'formLane1Status', 'formLane2Status', 'formLane3Status'];

const LANES_NAMES_MAP = {
  'defaultStatus': 'default',
  'pageLane1Status': 'pageLane1',
  'pageLane2Status': 'pageLane2',
  'pageLane3Status': 'pageLane3',
  'formLane1Status': 'formLane1',
  'formLane2Status': 'formLane2',
  'formLane3Status': 'formLane3'
};

export const getExistingStatuses = item => ALL_LANES.filter(lane => item[lane]).map(s => ({ ...item[s], name: LANES_NAMES_MAP[s] }));

export const getExistingStatusesObj = item => ALL_LANES.filter(lane => item[lane]).reduce((acc, s) => {
  acc[s] = { ...item[s], name: LANES_NAMES_MAP[s] };
  return acc;
}, {});