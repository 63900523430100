import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'components/common/floating';
import { INCH, MM, PT } from 'utilities/lengthUnits';
import { getImagePrintSize } from '../utilities';

const UNIT_OPTIONS = [
  { value: INCH, text: 'in' },
  { value: MM, text: 'mm' },
  { value: PT, text: 'pt' },
];

export default function MeasurementsUnit(
  {
    image,
    resolution,
    measurementUnit,
    rulerMousePoint,
    rulerVisible,
    onChangeMeasurementUnit
  }) {

  const { width, height } = getImagePrintSize(image, resolution, measurementUnit) || {};
  const { x, y } = rulerMousePoint || {};

  return <div className='crtx-measurements-unit'>
    <div>
      W:
    </div>
    <div>
      {width}
    </div>
    <div>
      H:
    </div>
    <div>
      {height}
    </div>
    <Dropdown
      className='crtx-units'
      options={UNIT_OPTIONS}
      value={measurementUnit}
      onSelect={(e, value) => onChangeMeasurementUnit(value)}
    />
    {rulerVisible &&
      <>
        <div>
          X:
        </div>
        <div>
          {x}
        </div>
        <div>
          Y:
        </div>
        <div>
          {y}
        </div>
        <div className='crtx-units' />
      </>}
  </div>;
}

MeasurementsUnit.propTypes = {
  image: PropTypes.object,
  resolution: PropTypes.object,
  measurementUnit: PropTypes.string,
  rulerMousePoint: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }),
  rulerVisible: PropTypes.bool,
  onChangeMeasurementUnit: PropTypes.func,
};