import PropTypes from 'prop-types';
import React from 'react';
import ZoneDetails from './ZoneDetails';
import ZoneSummary from './ZoneSummary';
import Expander from 'components/common/buttons/Expander';

function Zone({
  module,
  zone,
  viewLinksMap,
  onChangeDeadline,
  onContextMenu,
  expand,
  expandedZones
}) {
  const expanded = expandedZones[zone.nwid] === undefined || expandedZones[zone.nwid] === true;

  const renderZoneContent = () => {

    return (
      <React.Fragment>
        <div className='dashboard-zone-container-left-side'>
          <Expander
            expanded={expanded}
            onClick={handleClickExpander}
          />
        </div>
        <div className='dashboard-zone-container-right-side'>
          {expanded ?
            <ZoneDetails
              zone={zone}
              module={module}
              onChangeDeadline={onChangeDeadline}
              onContextMenu={onContextMenu}
              key={'details'}
              viewLinksMap={viewLinksMap}
              expandedZones={expandedZones}
              expand={expand}
            /> :
            <ZoneSummary
              zone={zone}
              module={module}
              onChangeDeadline={onChangeDeadline}
              onContextMenu={onContextMenu}
              key={'summary'}
              viewLinksMap={viewLinksMap}
              expandedZones={expandedZones}
              expand={expand}
            />
          }
        </div>
      </React.Fragment>
    );
  };

  const handleClickExpander = () => {
    expand(zone, !expanded);
  };

  return (
    <div className='dashboard-zone-container'>
      {renderZoneContent()}
    </div>
  );
}

Zone.propTypes = {
  module: PropTypes.any,
  zone: PropTypes.object,
  expandedZones: PropTypes.object,
  viewLinksMap: PropTypes.object,
  onChangeDeadline: PropTypes.func,
  onContextMenu: PropTypes.func,
  expand: PropTypes.func,
};

export default Zone;