import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import dialogService from 'core/services/dialogService';
import { classNames } from 'utilities/classNames';
import Expander from 'components/common/buttons/Expander';
import IconButton from 'components/common/buttons/IconButton';
import DeadlineBar from 'components/private/deadline/DeadlineBar';
import Sorter from './Sorter';

function Run(
  {
    module,
    scale = 1,
    run,
    nwid,
    type,
    breadcrumbs,
    label,
    deadline,
    deadlineDate,
    deadlinePassed,
    holdType,
    structureHoldUntil,
    holdUntilDate,
    releaseOffset,
    sorters = [],
    totalCount,
    inBinCount,
    inProgressCount,
    missingCount,
    errorCount,
    onOpenPlatesDetails,
  }) {
  const [expanded, setExpanded] = useState(true);

  const getSortersStyle = () => {
    if (!expanded) {
      return { display: 'none' };
    }
  };

  const hasPlateDetailsView = () => {
    return typeof onOpenPlatesDetails === 'function';
  };

  const handleClickExpander = () => {
    setExpanded(!expanded);
  };

  const handleChangeDeadline = () => {
    const changeDeadlineAction = module.viewActions.find(a => a.actionClass === 'ChangeDeadlineAction');
    if (changeDeadlineAction) {
      changeDeadlineAction.execute({ nwid, type, breadcrumbs, deadline, structureHoldUntil, releaseOffset });
    } else {
      dialogService.openAlertDialog(translate('You have no permissions to change the deadline'), translate('Change deadline'));
    }
  };

  const handleMenuClick = (event) => {
    module.showContextMenu(run, [run], event);
  };

  return (
    <div className='run'>
      <div className='run-header'>
        <Expander
          expanded={expanded}
          onClick={handleClickExpander}
        />
        <div className='run-label ellipsis-before' title={label}>
          <bdi>{label}</bdi>
        </div>
        <DeadlineBar
          deadlineDate={deadlineDate}
          deadlinePassed={deadlinePassed}
          holdType={holdType}
          holdUntilDate={holdUntilDate}
          releaseOffset={parseInt(releaseOffset)}
          onChangeDeadline={handleChangeDeadline}
        />
        <IconButton
          key='menu'
          iconName={'menu'}
          tooltip={translate('Menu')}
          onClick={handleMenuClick}
        />
        <div className='run-menu'>
        </div>
        <div className='plate-counts'>
          {translate('Total Plates:')}
          <div className='count-box'>
            <div className={classNames({ 'total-count': totalCount > 0 })}>{totalCount}</div>
          </div>
          {translate('Missing:')}
          <div className='count-box'>
            <div className={classNames({ 'missing-count': missingCount > 0 })}>{missingCount}</div>
          </div>
          {translate('In progress:')}
          <div className='count-box'>
            <div className={classNames({ 'in-progress-count': inProgressCount > 0 })}>{inProgressCount}</div>
          </div>
          {translate('In Bin:')}
          <div className='count-box'>
            <div className={classNames({ 'in-bin-count': inBinCount > 0 })}>{inBinCount}</div>
          </div>
          {translate('Error:')}
          <div className='count-box'>
            <div className={classNames({ 'error-count': errorCount > 0 })}>{errorCount}</div>
          </div>
        </div>
      </div>
      <div className='sorters' style={getSortersStyle()}>
        {
          sorters.map((sorter) => {
            const { name } = sorter;

            return (
              <Sorter
                key={name}
                module={module}
                scale={scale}
                runNwid={nwid}
                runBreadcrumbs={breadcrumbs}
                {...sorter}
              />
            );
          })
        }

        {hasPlateDetailsView() && <div className='run-error'>
          <div>{translate('Some plates could not be assigned to a sorter or a bin.')}</div>
          <div className='show-details'>
            <div onClick={onOpenPlatesDetails}>{translate('Show details')}</div>
            <IconButton
              key='details-icon'
              className='crtx-size-12'
              iconName={'details'}
              tooltip={translate('Plates details')}
              onClick={onOpenPlatesDetails}
            />
          </div>
        </div>}
      </div>

    </div>
  );
}

Run.propTypes = {
  module: PropTypes.object,
  scale: PropTypes.number,
  run: PropTypes.object,
  nwid: PropTypes.string,
  type: PropTypes.string,
  breadcrumbs: PropTypes.string,
  label: PropTypes.string,
  deadline: PropTypes.string,
  deadlineDate: PropTypes.instanceOf(Date),
  deadlinePassed: PropTypes.bool,
  holdType: PropTypes.string,
  structureHoldUntil: PropTypes.string,
  holdUntilDate: PropTypes.instanceOf(Date),
  releaseOffset: PropTypes.string,
  sorters: PropTypes.array,
  totalCount: PropTypes.number,
  inBinCount: PropTypes.number,
  inProgressCount: PropTypes.number,
  missingCount: PropTypes.number,
  errorCount: PropTypes.number,
  onOpenPlatesDetails: PropTypes.func,
};

export default Run;