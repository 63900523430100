import React from 'react';
import { createRoot } from 'react-dom/client';
import jsutils from 'base/jsUtils';
import AbstractModule from 'AbstractModule';
import TickableModel from '../TickableModel';
import View from './components/View';
import { fromServerDate, getMaxDate } from 'core/dates';
import { composeComparators, createObjectComparator, COMPARE_TYPE } from 'core/comparators';
import preferencesManager from 'core/managers/preferences';
import { translate } from 'core/services/localization';
import requestManager from 'core/managers/request';
import { parseBreadcrumbs } from 'utilities/breadcrumbs';

const THROTTLE_WAIT = 1000;

export default AbstractModule.extend({

  initDone: function () {
    this.updates = [];
    this.reactRoot = createRoot(this.domElement);
    this.tickUpdateHandlerThrottled = jsutils.throttle(this.tickUpdateHandler, THROTTLE_WAIT, {
      leading: false,
      trailing: true
    });

  },

  buildToolbar: function () {
    this.toolbar = this.createToolbar();
    this.toolbar.addItem({
      label: 'Expand All',
      name: 'Expand All',
      _isApplicable: true,
      icon: 'expand',
      tooltip: translate('Expand All'),
      execute: () => {
        this.expandAll(true);
      }
    });

    this.toolbar.addItem({
      label: 'Collapse All',
      name: 'Collapse All',
      _isApplicable: true,
      icon: 'collapse',
      tooltip: translate('Collapse All'),
      execute: () => {
        this.expandAll(false);
      }
    });

    this.toolbar.addItem({
      label: translate('Show Expected Plates Only'),
      name: 'showExpectedPlatesOnly',
      _isApplicable: true,
      icon: 'actual_plates',
      itemType: 'push',
      tooltip: translate('Show Expected Plates Only'),
      checked: !!this.preferences.showExpectedPlatesOnly,
      execute: checked => {
        this.applyFilter(checked);
      }
    });
  },

  expand: function (zone, isExpanded) {
    this.expandedZones[zone.nwid] = isExpanded;

    this.render();
  },

  expandAll: function (isExpand) {
    this.zones.map(zone => {
      this.expandedZones[zone.nwid] = isExpand;
    });
    this.render();
  },
  firstTickReceived: function (data) {

    this.tickableModel = new TickableModel();
    this.tickableModel.firstTickHandler(data.model);
    this.preferences = data.preferences || {};
    this.buildToolbar();
    this.viewLinksMap = {};
    this.expandedZones = {};
    this.buildViewModel();
    this.applyFilter(!!this.preferences.showExpectedPlatesOnly);
    this.expandAll(true);
  },

  tickUpdate: function (data) {
    this.updates = this.updates.concat(data.model);
    this.tickUpdateHandlerThrottled();
  },

  tickUpdateHandler: function () {
    this.tickableModel.tickUpdateHandler(this.updates);
    this.updates = [];

    this.buildViewModel();
  },

  savePreferences: function (preferences) {
    if (!preferences) {
      return;
    }
    this.preferences = Object.assign(this.preferences, preferences);
    preferencesManager.savePreferences(this.getRequiredParameters(), this.preferences);
  },

  applyFilter: function (pushed) {
    this.savePreferences({ showExpectedPlatesOnly: pushed });
    this.render();
  },

  buildViewModel: function () {

    let viewModel = this.tickableModel.model();

    const getZones = (publications, rootType, rootName) => {

      let zones = [];

      for (let pub of publications) {
        if (rootType === 'publicationdate' && pub.date === rootName ||
          rootType === 'publicationname' && pub.name.toLowerCase() === rootName.toLowerCase() ||
          rootType === 'folder' ||
          rootType === 'publication') {
          for (let edition of pub.editions) {
            for (let zone of edition.zones) {
              zones.push(zone);
            }
          }
        } else if (rootType === 'planpress') {
          for (let edition of pub.editions) {
            for (let zone of edition.zones) {
              const runs = [];
              for (let run of zone.productionruns) {
                if (run.press === rootName) {
                  runs.push(run);
                }
              }
              if (runs.length > 0) {
                zone.productionruns = runs;
                zones.push(zone);
              }
            }
          }
        }
      }

      return zones;
    };

    const comparator = composeComparators([
      createObjectComparator('earlestDeadlineDate', COMPARE_TYPE.DATES),
      createObjectComparator('folder'),
      createObjectComparator('publicationdate', COMPARE_TYPE.DATES),
      createObjectComparator('publication'),
      createObjectComparator('edition'),
      createObjectComparator('zone')
    ]);

    const resolveZones = (viewModel) => {

      let publications = viewModel.publications ? viewModel.publications : [{ ...viewModel }];
      let zones = getZones(publications, this.viewSettings.rootType, this.viewSettings.rootName);

      const getZoneAndRunsEarlestDeadline = (runs, zoneDeadline = getMaxDate()) => {

        let runsDealineArr = runs.map(run => run.deadlineDate);

        let earlestRunsDate = runsDealineArr.reduce((date1, date2) => date1 < date2 ? date1 : date2, getMaxDate());

        return earlestRunsDate < zoneDeadline ? earlestRunsDate : zoneDeadline;
      };

      zones.forEach(zone => {
        zone.deadlineDate = zone.nativeDeadLine ? fromServerDate(zone.deadline) : undefined;
        if (zone.structureHoldUntil) {
          zone.holdUntilDate = fromServerDate(zone.structureHoldUntil);
        }
        zone.productionruns.forEach(run => {
          run.deadlineDate = fromServerDate(run.deadline);
          if (run.structureHoldUntil) {
            run.holdUntilDate = fromServerDate(run.structureHoldUntil);
          }
        });
        zone.productionruns.sort(createObjectComparator('deadlineDate', COMPARE_TYPE.DATES));
        zone.earlestDeadlineDate = getZoneAndRunsEarlestDeadline(zone.productionruns, zone.deadlineDate);

        let breadcrumbs = parseBreadcrumbs(zone.breadcrumbs);

        breadcrumbs.forEach(b => {
          zone[b.type] = b.type === 'publicationdate' ? fromServerDate(b.name) : (b.code || b.name);
        });
      });

      zones = [...zones].sort(comparator);

      return zones;
    };

    const requestViewLinks = () => {

      let viewLinksRequestQueue = this.zones.reduce((links, zone) => {

        if (zone.aggregate.totalPages > 0 && !(this.viewLinksMap['sections'] && this.viewLinksMap['sections'][zone.nwid])) {
          links.push({ nwid: zone.nwid, type: 'sections' });
        }

        if (zone.aggregate.totalForms > 0 && !(this.viewLinksMap['books'] && this.viewLinksMap['books'][zone.nwid])) {
          links.push({ nwid: zone.nwid, type: 'books' });
        }

        return links;
      }, []);


      if (viewLinksRequestQueue.length > 0) {
        const items = [...viewLinksRequestQueue];
        viewLinksRequestQueue = [];
        requestManager.retrieveViewLinksBatch(items, this.nwid)
          .then(res => {
            if (res.errorMessage) {
              console.error('Cannot retrieve View Links: ', res.errorMessage);
            } else {
              res.data.forEach(item => {
                this.viewLinksMap[item.type] = this.viewLinksMap[item.type] || {};
                this.viewLinksMap[item.type][item.nwid] = item.viewLinks;
              });
              this.render();
            }
          });
      }
    };

    this.zones = resolveZones(viewModel);

    requestViewLinks();

    this.render();

  },

  handleContextMenu: function (event, item) {
    this.showContextMenu(item, [item], event).then(selectedAction => {
      this.render();
    });
  },

  destroy: function () {
    this._super();
    this.reactRoot.unmount();
  },

  render: function () {
    this.reactRoot.render(
      <View
        module={this}
        zones={this.zones}
        onContextMenu={(event, item) => this.handleContextMenu(event, item)}
        viewLinksMap={this.viewLinksMap}
        expandedZones={this.expandedZones}
        expand={(zone, expanded) => this.expand(zone, expanded)}
      />);
  }
});
