export default (options) => {

  const matchesMask = (userValue) => {
    return !options ? true : options.regex.test(userValue);
  };

  return {
    matchesMask
  }

};
