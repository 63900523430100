/**
 * Created by moshemal on 8/31/14.
 */

define(['core'], function (core) {
  'use strict';

  return {
    /**
     * Performs request to expand the node
     * @memberOf sandbox:request
     */

    getGenericCommand: function () {
      return core.manager.request.getGenericCommand.apply(core, arguments);
    },

    simpleGenericCommand: function () {
      return core.manager.request.simpleGenericCommand.apply(core, arguments);
    },
    loadChildNodes: function () {
      return core.manager.request.loadChildNodes.apply(core, arguments);
    },
    loadDescendantNodes: function () {
      return core.manager.request.loadDescendantNodes.apply(core, arguments);
    },
    getImage: function () {
      core.manager.request.getImage.apply(core, arguments);
    },
    getImageData: function () {
      return core.manager.request.getImageData.apply(core, arguments);
    },
    getImageUrl: function () {
      return core.manager.request.getImageUrl.apply(core, arguments);
    },
    getIconsPath: function () {
      return core.manager.request.getIconsPath.apply(core, arguments);
    },
    approveRejectObject: function () {
      core.manager.request.approveRejectObject.apply(core, arguments);
    },
    approveRejectObjects: function () {
      return core.manager.request.approveRejectObjects.apply(core, arguments);
    },
    deleteObjects: function () {
      return core.manager.request.deleteObjects.apply(core, arguments);
    },
    holdElement: function () {
      return core.manager.request.holdElement.apply(core, arguments);
    },
    releaseElement: function () {
      return core.manager.request.releaseElement.apply(core, arguments);
    },
    mapUnplannedPage: function () {
      return core.manager.request.mapUnplannedPage.apply(core, arguments);
    },
    route: function () {
      return core.manager.request.route.apply(core, arguments);
    },
    sendMail: function () {
      return core.manager.request.sendMail.apply(core, arguments);
    },
    renameUnplanPage: function () {
      return core.manager.request.renameUnplanPage.apply(core, arguments);
    },
    getDynPropOptions: function () {
      return core.manager.request.getDynPropOptions.apply(core, arguments);
    },
    changeDynProperties: function () {
      return core.manager.request.changeDynProperties.apply(core, arguments);
    },
    getPmtOptions: function () {
      return core.manager.request.getPmtOptions.apply(core, arguments);
    },
    savePageTypeOptions: function () {
      return core.manager.request.savePageTypeOptions.apply(core, arguments);
    },
    getPageTypeOptions: function () {
      return core.manager.request.getPageTypeOptions.apply(core, arguments);
    },
    changePmt: function () {
      return core.manager.request.changePmt.apply(core, arguments);
    },
    getLockAllowedResources: function () {
      return core.manager.request.getLockAllowedResources.apply(core, arguments);
    },
    lock: function () {
      return core.manager.request.lock.apply(core, arguments);
    },
    uploadICCProfile: function () {
      return core.manager.request.uploadICCProfile.apply(core, arguments);
    },
    getPlan: function () {
      return core.manager.request.getPlan.apply(core, arguments);
    },
    setPlan: function () {
      return core.manager.request.setPlan.apply(core, arguments);
    },

    sendGenericRequest: function (params, callback) {
      core.manager.request.sendGenericRequest(params, callback);
    },

    genericRequestPromise: function (params) {
      return core.manager.request.genericRequestPromise(params);
    },

    getRectangles: function () {
      return core.manager.request.getRectangles.apply(core, arguments);
    },

    resource: {
      toggle: function () {
        core.manager.request.toggleResource.apply(core, arguments);
      },
      abortAll: function () {
        core.manager.request.abortAllResources.apply(core, arguments);
      }
    },

    workflow: {
      startAll: function () {
        return core.manager.request.startAllWorkflow.apply(core, arguments);
      },
      stopAll: function () {
        return core.manager.request.stopAllWorkflow.apply(core, arguments);
      }
    },

    toggleDevice: function () {
      return core.manager.request.toggleDevice.apply(core, arguments);
    },
    toggleSkipDevice: function () {
      core.manager.request.toggleSkipDevice.apply(core, arguments);
    },
    deleteEvents: function () {
      core.manager.request.deleteEvents.apply(core, arguments);
    },
    redoEvents: function () {
      return core.manager.request.redoEvents.apply(core, arguments);
    },
    holdEvents: function () {
      core.manager.request.holdEvents.apply(core, arguments);
    },
    unholdEvents: function () {
      core.manager.request.unholdEvents.apply(core, arguments);
    },
    lockEvents: function () {
      core.manager.request.lockEvents.apply(core, arguments);
    },
    unlockEvents: function () {
      core.manager.request.unlockEvents.apply(core, arguments);
    },
    highPrioritisedEvents: function () {
      core.manager.request.highPrioritisedEvents.apply(core, arguments);
    },
    saveSetup: function () {
      return core.manager.request.saveSetup.apply(core, arguments);
    },
    execServerAction: function () {
      return core.manager.request.execAction.apply(core, arguments);
    },
    retrieveViewLinks: function () {
      return core.manager.request.retrieveViewLinks.apply(core, arguments);
    },
    retrieveViewLinksBatch: function () {
      return core.manager.request.retrieveViewLinksBatch.apply(core, arguments);
    },
    testSetup: function () {
      return core.manager.request.testSetup.apply(core, arguments);
    },
    getScriptContent: function () {
      return core.manager.request.getScriptContent.apply(core, arguments);
    },
    saveScriptContent: function () {
      return core.manager.request.saveScriptContent.apply(core, arguments);
    },
    rest: function () {
      return core.manager.request.rest.apply(core, arguments);
    },
    unlockContainer: function () {
      return core.manager.request.unlockContainer.apply(core, arguments);
    }
  };
});