/**
 * @name NamingPatternsView Module
 */
import React from 'react';
import { createRoot } from 'react-dom/client';
import AbstractModule from 'AbstractModule';
import TickableModel from '../TickableModel';
import jsutils from 'base/jsUtils';
import { DEFAULT_SORT } from './constants';
import { arrayToObject, moveItem } from 'utilities/array';
import { savePreferences } from 'core/managers/preferences';
import { makeNamingPatternsTableColumns } from './columnsCreator';
import {
  extractColumnPreferences,
  applyColumnSortPreferences,
  checkTextCondition,
  extractColumnSortPreferences,
  FILTER_DATA_TYPE,
  FILTER_TYPE,
  reduceColumnsToFilterBy,
  reduceColumnsToSortBy
} from 'widgets/ReactDataGrid/utils';
import { composeComparators, createObjectComparator } from 'core/comparators';
import { translate } from 'core/services/localization';
import View from './View';

const THROTTLE_WAIT = 1000;

export default AbstractModule.extend({

  initDone: function () {
    this.reactRoot = createRoot(this.domElement);
    this.updates = [];
    this.tickUpdateHandlerThrottled = jsutils.throttle(this.tickUpdateHandler, THROTTLE_WAIT, {
      leading: false,
      trailing: true
    });
    this.toolbar = this.createToolbar();
  },

  firstTickReceived: function (data) {
    this.preferences = data.preferences || {};
    this.columnsToSortBy = (typeof data.preferences.columnsToSortBy === 'undefined' || data.preferences.columnsToSortBy.length === 0) ?
      DEFAULT_SORT : data.preferences.columnsToSortBy;

    this.filterName = 'output';
    this.filtersEnabled = (this.preferences.table || {}).filtersEnabled || false;

    this.initToolbar();

    this.tickableModel = new TickableModel();

    this.tickableModel.firstTickHandler(data.model);

    this.buildViewModel();
  },

  initToolbar: function () {

    this.toolbar.addItem({
      label: 'Input Naming Patterns',
      name: 'input',
      _isApplicable: true,
      _alwaysOneSelected: true,
      icon: 'input_naming',
      iconSprite: 'general',
      tooltip: translate('Input Naming Patterns'),
      itemType: 'push',
      groupName: 'usageType',
      execute: checked => {
        if (checked) {
          this.applyPatternFilter(true, 'input');
        } else {
          this.toolbar.setItemChecked('input', true);
        }
      }


    });

    this.toolbar.addItem({
      label: 'Output Naming Patterns',
      name: 'output',
      _isApplicable: true,
      _alwaysOneSelected: true,
      icon: 'output_naming',
      iconSprite: 'general',
      tooltip: translate('Output Naming Patterns'),
      itemType: 'push',
      groupName: 'usageType',
      checked: true,
      execute: checked => {
        if (checked) {
          this.applyPatternFilter(true, 'output');
        } else {
          this.toolbar.setItemChecked('output', true);
        }
      }

    });

    this.toolbar.addItem({
      label: 'Approval Naming Patterns',
      name: 'approval',
      _isApplicable: true,
      _isPushed: true,
      _alwaysOneSelected: true,
      icon: 'approval_naming',
      iconSprite: 'general',
      tooltip: translate('Approval Naming Patterns'),
      itemType: 'push',
      groupName: 'usageType',
      execute: checked => {
        if (checked) {
          this.applyPatternFilter(true, 'approval');
        } else {
          this.toolbar.setItemChecked('approval', true);
        }
      }

    });

    this.toolbar.addItem({
      label: 'Production Run Patterns',
      name: 'productionrun',
      _isApplicable: true,
      _isPushed: true,
      _alwaysOneSelected: true,
      icon: 'productionrun_naming',
      iconSprite: 'general',
      tooltip: translate('Production Run Patterns'),
      itemType: 'push',
      groupName: 'usageType',
      execute: checked => {
        if (checked) {
          this.applyPatternFilter(true, 'productionrun');
        } else {
          this.toolbar.setItemChecked('productionrun', true);
        }
      }

    });
    this.toolbar.addItem({
      label: 'Page Patterns',
      name: 'page',
      _isApplicable: true,
      _isPushed: true,
      _alwaysOneSelected: true,
      icon: 'page_naming',
      iconSprite: 'general',
      tooltip: translate('Page Patterns'),
      itemType: 'push',
      groupName: 'usageType',
      execute: checked => {
        if (checked) {
          this.applyPatternFilter(true, 'page');
        } else {
          this.toolbar.setItemChecked('page', true);
        }
      }

    });
    this.toolbar.addItem({
      label: 'Form Patterns',
      name: 'form',
      _isApplicable: true,
      _isPushed: true,
      _alwaysOneSelected: true,
      icon: 'form_naming',
      iconSprite: 'general',
      tooltip: translate('Form Patterns'),
      itemType: 'push',
      groupName: 'usageType',
      execute: checked => {
        if (checked) {
          this.applyPatternFilter(true, 'form');
        } else {
          this.toolbar.setItemChecked('form', true);
        }
      }
    });

    this.toolbar.addItem({
      label: translate('Toggle Filters'),
      name: 'toggleFilters',
      _isApplicable: true,
      icon: 'filter_list.svg',
      itemType: 'push',
      checked: this.filtersEnabled,
      execute: this.toggleFilters.bind(this)
    });
  },

  tickUpdate: function (data) {
    this.updates = this.updates.concat(data.model);
    this.tickUpdateHandlerThrottled();
  },

  tickUpdateHandler: function () {
    this.tickableModel.tickUpdateHandler(this.updates);
    this.updates = [];
    this.buildViewModel();
  },

  buildViewModel: function () {
    const model = this.tickableModel.model();
    this.viewModel = {};

    this.viewModel.namingPatterns = model.namingPatterns.filter(pattern => {
      const usage = typeof pattern.usage === 'undefined' ? 'output' : pattern.usage;
      return usage === this.filterName;
    });

    this.viewModel.namingPatternsByNwid = arrayToObject(this.viewModel.namingPatterns, 'nwid');
    this.viewModel.sortedNamingPatterns = [...this.viewModel.namingPatterns];

    this.namingPatternsTableColumns = makeNamingPatternsTableColumns(this.viewModel, this);
    this.namingPatternsTableColumnsByKeys = arrayToObject(this.unplanTableColumns);

    this.sortNamingPatterns();

    this.filterNamingPatterns();
  },

  sortNamingPatterns: function () {
    if (this.columnsToSortBy && this.columnsToSortBy.length > 0) {
      const preferencesColumnsToSortBy = applyColumnSortPreferences(this.namingPatternsTableColumns, this.columnsToSortBy);

      const comparator = composeComparators(preferencesColumnsToSortBy.map(col => {
        return createObjectComparator(col.sortValueGetter || col.key, col.sortType, col.ascending);
      }));

      this.viewModel.sortedNamingPatterns = this.viewModel.sortedNamingPatterns.sort(comparator);

      this.savePreferences({
        ...this.preferences,
        columnsToSortBy: extractColumnSortPreferences(this.columnsToSortBy)
      });
    }
  },

  filterNamingPatterns: function () {
    if (!this.filtersEnabled) {
      this.viewModel.namingPatterns = this.viewModel.sortedNamingPatterns;
    } else {
      const columnsToFilterBy = reduceColumnsToFilterBy(this.namingPatternsTableColumns);

      this.viewModel.namingPatterns = this.viewModel.sortedNamingPatterns.filter(pattern => {

        let match = true;
        for (const col of columnsToFilterBy) {
          const filter = col.filter;
          if (FILTER_TYPE.TEXT && filter.textValue) {
            if (filter.dataType === FILTER_DATA_TYPE.TEXT) {
              const text = typeof col.filterValueGetter === 'function' ? col.filterValueGetter(pattern) : pattern[col.key];
              match = checkTextCondition(text, filter);
            }
          }

          if (!match) {
            break;
          }
        }

        return match;
      });
    }

    this.render();
  },

  applyPatternFilter: function (pushed, filterName) {

    this.filterName = filterName;

    this.buildViewModel();
  },

  toggleFilters: function (pushed) {

    this.filtersEnabled = pushed;

    this.toolbar.setItemChecked('toggleFilters', pushed);

    this.buildViewModel();

    this.saveNamingPatternsColumnPreferences();
  },

  saveNamingPatternsColumnPreferences: function () {
    const namingPatternsColumns = extractColumnPreferences(this.namingPatternsTableColumns);
    this.savePreferences({
      table: {
        ...this.preferences.table,
        filtersEnabled: this.filtersEnabled,
        namingPatternsColumns,
      }
    });
  },

  savePreferences: function (preferences) {
    if (!preferences) {
      return;
    }

    this.preferences = Object.assign(this.preferences, preferences);
    savePreferences(this.getRequiredParameters(), this.preferences);
  },

  handleNamingPatternTableColumnsFilter: function (columns) {
    this.namingPatternsTableColumns.forEach(col => {
      if (columns[col.key]) {
        col.visible = columns[col.key].visible;
      }
    });

    this.saveNamingPatternsColumnPreferences();
  },

  handleNamingPatternTableColumnOrder: function (columns, oldIndex, newIndex) {
    moveItem(this.namingPatternsTableColumns, oldIndex, newIndex);

    this.saveNamingPatternsColumnPreferences();

    this.render();
  },

  handleNamingPatternTableSelect: function (selectedRows) {
    const patterns = selectedRows.map(row => row && this.tickableModel.getByNwid(row.nwid));
    this.updateSelected(patterns.filter(e => !!e));
  },

  handleNamingPatternTableColumnResize: function (columns) {
    this.namingPatternsTableColumns.forEach(col => {
      if (columns[col.key]) {
        col.width = columns[col.key].width;
      }
    });

    this.saveNamingPatternsColumnPreferences();
  },

  handleNamingPatternTableContextMenu: function (pattern, selected, e) {
    this.showContextMenu(pattern, selected, e);
  },

  handleNamingPatternColumnFilterChange: function (column, columnFilter) {
    if (!column || !column.filter || !column.filter.type) {
      return;
    }

    column.filter = {
      ...column.filter,
      ...columnFilter
    };

    this.filterNamingPatterns();

    this.saveNamingPatternsColumnPreferences();
  },

  handleNamingPatternColumnClick: function (sortType) {
    return (columnKey, sortValueGetter, multiSort) => {
      this.columnsToSortBy = reduceColumnsToSortBy(this.namingPatternsTableColumns, this.columnsToSortBy, columnKey, multiSort);
      this.sortNamingPatterns();
      this.filterNamingPatterns();
    };
  },

  handleNamingPatternTableDeleteKey: function (selectedRows, e) {
    const actions = this.getRelevantActions(this.selected[0]);
    const deleteAction = actions.find(a => a.actionClass === 'ServerAction');
    if (deleteAction) {
      const res = deleteAction.execute(this.selected);
      res.then(() => this.updateSelected([]));
    }
  },

  handleNamingPatternDoubleClick(pattern, e) {
    this.navigateByAction(pattern, 'EditNamingPatternActionCR');
  },

  destroy: function () {
    this._super();
    this.reactRoot.unmount();
  },

  render: function () {
    this.reactRoot.render(
      <View module={this}
        viewModel={this.viewModel}
        namingPatternsTableColumns={this.namingPatternsTableColumns} />);
  }
});