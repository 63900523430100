import React from 'react';
import PropTypes from 'prop-types';
import { LengthOutput } from 'components/common/outputs';

export const AdUnitDisplay = ({ label, value, lengthUnit }) => {
  return <span className='property-row-content'>
    <span className='property-row-content-name ellipsis' title={label}>
      {label}
    </span>
    <span className='property-row-content-value ellipsis' title={value}>
      <LengthOutput value={value} unit={lengthUnit} />
    </span>
  </span>;
};

AdUnitDisplay.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  lengthUnit: PropTypes.string
};