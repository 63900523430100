import React, { Component } from 'react';
import { translate } from 'core/services/localization';
import { startModule, startModuleByViewLink } from 'core/managers/module';
import ReactDataGrid from 'widgets/ReactDataGrid/src/ReactDataGrid';
import iconService from 'core/services/iconService';
const { Table, Column } = ReactDataGrid;
const { SortableHeader } = ReactDataGrid.headers;
const { Text, IconCell } = ReactDataGrid.cells;
const { sort } = ReactDataGrid.operators;
import TextInput from 'components/common/inputs/TextInput';
import Button from 'components/common/buttons/Button';
import PropTypes from 'prop-types';
import dialogService from 'core/services/dialogService'
import { CONFIG_TYPE } from "../Constants";
import { restDelete, restPut } from 'core/managers/rest2';
import toastService from 'core/services/toastService';

const Constants = require('../Constants');
const sortables = [Constants.TEMPLATE_NAME, Constants.CONFIG_TYPE];
const columnsDefinition = [{
  [Constants.NAME]: Constants.SELECTED,
  [Constants.TITLE]: "",
  type: Constants.BOOLEAN
},
{
  [Constants.NAME]: Constants.EDIT,
  [Constants.TITLE]: "",
  type: Constants.IMAGE
},
{
  [Constants.NAME]: Constants.TEMPLATE_NAME,
  [Constants.TITLE]: translate("Name"),
  [Constants.TYPE]: Constants.STRING,
  [Constants.SORT_DIRECTION]: "asc"
},
{
  [Constants.NAME]: Constants.CONFIG_TYPE,
  [Constants.TITLE]: translate("Config Type"),
  [Constants.TYPE]: Constants.STRING,
  [Constants.SORT_DIRECTION]: "asc"
},
{
  [Constants.NAME]: Constants.SECTION_NAME,
  [Constants.TITLE]: translate("Section"),
  [Constants.TYPE]: Constants.STRING
},
{
  [Constants.NAME]: Constants.NUM_PAGES,
  [Constants.TITLE]: translate("No. Pages"),
  [Constants.TYPE]: Constants.STRING,
},
{
  [Constants.NAME]: Constants.FORMAT,
  [Constants.TITLE]: translate("Format"),
  [Constants.TYPE]: Constants.STRING
},
{
  [Constants.NAME]: Constants.PRINT_RUN_MODE,
  [Constants.TITLE]: translate("Print Run Type"),
  [Constants.TYPE]: Constants.STRING,
  [Constants.SORT_DIRECTION]: "asc"
},
{
  [Constants.NAME]: Constants.NUMBER_OF_HEAT_PAGES,
  [Constants.TITLE]: translate("No.Heatset Pages"),
  [Constants.TYPE]: Constants.STRING
}
]

const removeTemplates = () => {
  return (dispatch, getState) => {
    let state = getState();
    let deleted = state.templates.filter(function (t) {
      return t[Constants.SELECTED];
    });
    if (deleted.length > 0) {
      let message = translate("Are you sure you want to delete these templates?");

      dialogService.openConfirmDialog(message, translate('Confirmation')).then(() => {
        let model = {
          [Constants.FOLDER_NWID]: state[Constants.FOLDER_NWID],
          [Constants.TEMPLATES]: deleted
        }
        let url = "planning-wizard/custom/sanoma/templates/delete";

        restDelete(state[Constants.ACTION_NWID], url, model)
          .then(() => {
            dispatch({
              [Constants.TYPE]: Constants.REMOVE_TEMPLATES,
              [Constants.TEMPLATES]: deleted
            })
          })
          .catch(res => toastService.errorToast('', translate(res.textMessage)));

      });
    }
  }
}



const cancelCallback = () => {

}

class CheckboxCell extends Component {

  static defaultProps = {
    disabled: false
  }

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.rowIndex !== this.props.rowIndex || nextProps.columnData !== this.props.columnData;
  }

  handleChange() {
    var { rowIndex, onChange } = this.props;
    onChange(rowIndex);
  }

  render() {
    var { columnData, display } = this.props;
    var cellStyle = {
      width: '100%',
      height: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    };
    if (!display) {
      return <div style={cellStyle}>
        <div className="cell-content">
        </div>
      </div>;
    }
    var checked = columnData
    return <div style={cellStyle}>
      <div className="cell-content">
        <input type="checkbox" checked={checked} onChange={this.handleChange} />
      </div>
    </div>;
  }

}

module.exports = class ConversionTables extends Component {
  static propTypes = {
    store: PropTypes.any,
    loadTemplates: PropTypes.func
  };

  renderColumns = (items, store) => (column) => {
    let columnDefinition = columnsDefinition.filter(d => d[Constants.NAME] === column[Constants.NAME])[0];
    const sort = sortables.includes(column[Constants.NAME]) ? columnDefinition[Constants.SORT_DIRECTION] : undefined;
    let cell = "<CheckboxCell/>";
    return <Column columnKey={column[Constants.NAME]}
      width={column[Constants.TYPE] === (Constants.BOOLEAN || Constants.IMAGE) ? 6 : 20}
      align={column[Constants.TYPE] === (Constants.BOOLEAN || Constants.IMAGE) ? "center" : "left"}
      valign
      autoWidth={true}
      header={<SortableHeader sort={sort}>
        {column[Constants.TITLE]}
      </SortableHeader>}
      onClick={(rowIndex, columnKey) => {
        store.dispatch({
          [Constants.TYPE]: Constants.SORT_COLUMN,
          [Constants.COLUMNS_DEFINITIONS]: columnsDefinition,
          [Constants.SORTABLES]: sortables,
          [Constants.NAME]: columnKey
        })
      }}
      cell={column[Constants.TYPE] === Constants.BOOLEAN ? <CheckboxCell /> : column[Constants.TYPE] === Constants.IMAGE ? <IconCell /> : <Text />}
      cellDataGetter={(rowIndex, columnKey) => {
        return {
          icon: items[rowIndex][Constants.IS_TEMPLATE] ? iconService.getGeneralIcon(null, 'edit') : "",
          columnData: items[rowIndex][column.name],
          display: items[rowIndex][Constants.IS_TEMPLATE],
          onChange: (newValue) => {
            store.dispatch({
              [Constants.TYPE]: Constants.CHANGE_SELECTION,
              [Constants.VALUE]: newValue,
              rowIndex: rowIndex
            })
          },
          onClick: (rowIndex, columnKey) => { store.dispatch(this.editTemplate(rowIndex, columnKey)) }
        };
      }}
      shouldCellUpdate={(nextProps, props) => {
        return nextProps.columnData !== props.columnData;
      }}
    />
  };

  handleChange() {
    var { rowIndex, onChange } = this.props;

    onChange(rowIndex);
  }

  handleAddedTemplates = (link) => {
    const store = this.props.store;
    const state = store.getState();
    startModuleByViewLink(link, state[Constants.FOLDER_NWID], 'folder', { onDestroy: () => this.addedTemplates() });
  }

  addedTemplates() {
    const store = this.props.store;
    const state = store.getState();
    this.props.loadTemplates();
  }

  editTemplate = (rowIndex, columnKey) => {
    return (dispatch, getState) => {
      if (columnKey === Constants.EDIT) {
        let link = getState()[Constants.CREATE_TEMPLATE_LINK];
        const state = getState();
        let name = state[Constants.FILTERED][rowIndex][Constants.TEMPLATE_NAME];
        startModuleByViewLink(link, state[Constants.FOLDER_NWID], 'folder', { onDestroy: () => this.addedTemplates(), [Constants.START_PARAMETERS]: { [Constants.TEMPLATE_NAME]: name, [Constants.PUBLICATION_NAME]: 'Publication' } });
      } else if (columnKey === Constants.TEMPLATE_NAME) {
        let desc = translate("New Name");
        let oldName = getState()[Constants.FILTERED][rowIndex][Constants.TEMPLATE_NAME];
        if (typeof oldName === "undefined" || oldName === "") { //this is a section not a template
          return;
        }
        let HTML = $('<div style="margin: 5px;margin-bottom: 75px"><label>' + desc + ': </label><Input value="' + oldName + '" id="newName" style="width: 78%"/></div>');
        let buttons = [
          {
            label: "Cancel",
            callback: cancelCallback.bind(this),
            id: "cancelRename"
          },
          {
            label: "OK",
            callback: (dialog, inputs) => {
              let newName = inputs[0].value;
              if ("" === newName) { return; }
              let model = {
                [Constants.FOLDER_NWID]: getState()[Constants.FOLDER_NWID],
                [Constants.OLD_NAME]: oldName,
                [Constants.NEW_NAME]: newName
              }
              let url = "planning-wizard/custom/sanoma/templates/rename";
              restPut(getState()[Constants.ACTION_NWID], url, model)
                .then(() => {
                  dispatch({
                    [Constants.TYPE]: Constants.RENAME_TEMPLATE,
                    [Constants.OLD_NAME]: oldName,
                    [Constants.NEW_NAME]: newName
                  })
                })
                .catch(res => toastService.errorToast('', translate(res.textMessage)));

            },
            id: "okRename"
          }
        ]
        let title = translate("Rename Template");
        var dialog = dialogService.showPlainHtml(title, HTML, buttons, this);
      }
    }
  }

  render() {
    const store = this.props.store;
    const state = store.getState();
    if (state === "undefined") {
      return <div>ccc</div>
    }

    var rowHeight = 20;
    let outerStyle = {
      display: "flex",
      flexDirection: "row"
    }
    let filterStyle = {
      flex: 10,
      width: 300
    }
    let buttonDivStyle = {
      flex: 1,
      marginLeft: 5
    }
    let buttonStyle = {
      width: 102
    }
    let wrraperStyle = {
      height: '100%',
      overflow: 'hidden'
    }
    let filterInnerStyle = {
      width: 300
    }
    return <div style={wrraperStyle}><div style={outerStyle}>
      <div style={filterStyle}><label>{translate("Filter")}:</label>
        <TextInput style={filterInnerStyle}
          value={state[Constants.FILTER]}
          onChange={(event, newValue) => {
            store.dispatch(
              {
                [Constants.TYPE]: Constants.FILTER,
                [Constants.VALUE]: newValue
              }
            )
          }}
        /></div>
      <div style={buttonDivStyle}><Button style={buttonStyle}
        onClick={() => { store.dispatch(removeTemplates()) }}
      >
        {translate("Delete")}
      </Button></div>
      <div style={buttonDivStyle}><Button style={buttonStyle}
        onClick={() => this.handleAddedTemplates(state[Constants.CREATE_TEMPLATE_LINK])}
      >
        {translate("New Template")}
      </Button></div></div><br />
      <div className="tableArea">
        <Table
          rowHeight={rowHeight}
          rows={state[Constants.FILTERED]}
          columnKey={Constants.UID}
          virtualScroll={true}
          getRowClassName={(rowIndex) => state[Constants.FILTERED][rowIndex][Constants.IS_TEMPLATE] ? ['templateBackGround'] : ['sectionBackGround']}
        >
          {columnsDefinition.map(this.renderColumns(state[Constants.FILTERED], store))}
        </Table></div><br />
    </div>;
  }
};