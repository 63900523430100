import { arrayToObject } from 'utilities/array';
import { StageHelper } from 'components/common/canvas';
import {
  roundUserUnits,
  inchesToPixels,
  pointPixelsToUserUnitsRounded,
  inchesToUserUnitsRounded,
} from 'utilities/lengthUnits';
import {
  RULER_THICKNESS,
  getPointRelativeToOrigin,
  getDistanceToPoint,
} from 'components/common/canvas/utilities';
import {
  VIEW_TYPE_FORM_VIEW,
  sortImagesByColor,
  getInitialToolsData,
  getSelectedImage,
  getImageSize,
  getImageResolution,
  getViewportPoints,
  getInitialMeasurementData,
  computeDensity,
} from '../utilities';

const isUndefined = o => typeof o === 'undefined';

const MEDIA_BOX_COLOR = 'purple';
const CROP_BOX_COLOR = 'black';
const BLEED_BOX_COLOR = 'blue';
const TRIM_BOX_COLOR = 'green';
const ART_BOX_COLOR = 'red';
const DEFAULT_COLOR = '#FE0056';
const PDF_BOXES_ORDER = ['mediabox', 'artbox', 'cropbox', 'trimbox', 'bleedbox'];
const BOTTOM_LEFT_REFERENCE_POINT = 'bottom-left';

const getRenderingWidth = (canvasWidth) => {
  return canvasWidth - RULER_THICKNESS;
};

const getRenderingHeight = (canvasHeight) => {
  return canvasHeight - RULER_THICKNESS;
};

function getBoxColor(box) {
  if (box.color) {
    return box.color;
  }

  switch (box.label) {
    case 'mediabox':
      return MEDIA_BOX_COLOR;
    case 'cropbox':
      return CROP_BOX_COLOR;
    case 'bleedbox':
      return BLEED_BOX_COLOR;
    case 'trimbox':
      return TRIM_BOX_COLOR;
    case 'artbox':
      return ART_BOX_COLOR;
    default:
      return DEFAULT_COLOR;
  }
}

function arrangePdfBoxes(pdfBoxes) {
  const boxesByName = arrayToObject(pdfBoxes, 'label');
  const arrangedBoxes = PDF_BOXES_ORDER.reduce((acc, boxName) => {
    if (boxesByName[boxName]) {
      acc.push(boxesByName[boxName]);
      delete boxesByName[boxName];
    }

    return acc;
  }, []);

  for (let name in boxesByName) {
    arrangedBoxes.push(boxesByName[name]);
  }

  return arrangedBoxes;
}

const updateOverlayData = (state, overlayData) => {
  const imageSize = getImageSize(getSelectedImage(state));

  if (!overlayData || !imageSize) {
    return state;
  }

  const { viewType, resolution: { xResolution, yResolution }, measurementUnit } = state;

  let pdfBoxes = [], pdfOverlays = [];
  if (overlayData.Rectangles) {
    pdfBoxes = overlayData.Rectangles.filter(box => !box.type || box.type === 'GENERAL');
    pdfBoxes = arrangePdfBoxes(pdfBoxes);
    pdfBoxes.forEach((box, index) => {
      box.referencePoint = box.referencePoint || BOTTOM_LEFT_REFERENCE_POINT;
      box.calWidth = inchesToPixels(box.width, xResolution);
      box.calcHeight = inchesToPixels(box.height, yResolution);
      box.calcLeft = -imageSize.width / 2 + inchesToPixels(box.left, xResolution);
      box.calcTop = box.referencePoint === BOTTOM_LEFT_REFERENCE_POINT ? imageSize.height / 2 - inchesToPixels(box.top, yResolution) - box.calcHeight : -imageSize.height / 2 + inchesToPixels(box.top, yResolution);
      box.color = getBoxColor(box);
      box.fill = false;
      box.legendData = {
        x: inchesToUserUnitsRounded(box.left, measurementUnit),
        y: inchesToUserUnitsRounded(box.top, measurementUnit),
        width: inchesToUserUnitsRounded(box.width, measurementUnit),
        height: inchesToUserUnitsRounded(box.height, measurementUnit),
        xResolution,
        yResolution
      };
      box.isSelected = true;
    });

    pdfOverlays = overlayData.Rectangles.filter(box => box.type === 'OVERLAY');
    pdfOverlays.forEach((box) => {
      box.referencePoint = box.referencePoint || BOTTOM_LEFT_REFERENCE_POINT;
      box.calcLeft = -imageSize.width / 2 + inchesToPixels(box.left, xResolution);
      box.calcTop = box.referencePoint === BOTTOM_LEFT_REFERENCE_POINT ? imageSize.height / 2 - inchesToPixels(box.top, yResolution) - box.calcHeight : -imageSize.height / 2 + inchesToPixels(box.top, yResolution);
      box.calWidth = inchesToPixels(box.width, xResolution);
      box.calcHeight = inchesToPixels(box.height, yResolution);

      box.color = getBoxColor(box);
      box.fill = true;
      box.legendData = {
        x: inchesToUserUnitsRounded(box.left, measurementUnit),
        y: inchesToUserUnitsRounded(box.top, measurementUnit),
        width: inchesToUserUnitsRounded(box.width, measurementUnit),
        height: inchesToUserUnitsRounded(box.height, measurementUnit),
      };
      box.isSelected = true;
    });
  }

  if (viewType === VIEW_TYPE_FORM_VIEW) {
    const guidelineHorizontalStart = -imageSize.width / 2 + inchesToPixels(0, xResolution);
    const guidelineHorizontalEnd = imageSize.width / 2 + inchesToPixels(0, xResolution);
    const guidelineVerticalStart = -imageSize.height / 2 + inchesToPixels(0, yResolution);
    const guidelineVerticalEnd = imageSize.height / 2 + inchesToPixels(0, yResolution);
    overlayData.guidelines && overlayData.guidelines.length > 0 && overlayData.guidelines.forEach(guideline => {
      guideline.isSelected = true;
      if (guideline.orientation === 'vertical') {
        const imageWidth = guideline.referenceX === 'right' ? imageSize.width : -imageSize.width;
        const guidelineXInPixel = (imageWidth / 2) + inchesToPixels(guideline.x, xResolution);
        guideline.point = { x: guidelineXInPixel, y: guidelineVerticalStart };
        guideline.pointTo = { x: guidelineXInPixel, y: guidelineVerticalEnd };
        guideline.displayPosition = inchesToUserUnitsRounded(guideline.x, measurementUnit);
      } else {
        const imageHeight = guideline.referenceY === 'bottom' ? imageSize.height : -imageSize.height;
        const guidelineYInPixel = (imageHeight / 2) + inchesToPixels(guideline.y, yResolution);
        guideline.point = { x: guidelineHorizontalStart, y: guidelineYInPixel };
        guideline.pointTo = { x: guidelineHorizontalEnd, y: guidelineYInPixel };
        guideline.displayPosition = inchesToUserUnitsRounded(guideline.y, measurementUnit);
      }
    });
  }

  state = {
    ...state,
    overlayData,
    guidelines: overlayData.guidelines,
    overlayBoxes: {
      mediaOverlayBoxes: pdfBoxes,
      pdfOverlayBoxes: pdfOverlays
    },
  };

  return state;
};

const updateMeasurementUnit = (state, measurementUnit) => {
  state = { ...state, measurementUnit };

  const { distanceToolPoints, mouseLastPoint, densityData = {}, overlayData } = state;

  state = updateMeasurementData(state, distanceToolPoints);
  state = updateDensityData(state, mouseLastPoint, densityData.areaSize);
  state = updateOverlayData(state, overlayData);

  return state;
};

const updateMeasurementData = (state, distanceToolPoints) => {
  const { point, pointTo } = distanceToolPoints;
  const image = getSelectedImage(state);
  let measurementData = getInitialMeasurementData();
  if (image && point && pointTo) {
    const { measurementUnit, resolution: defaultResolution } = state;
    const { width, height } = image;
    const resolution = getImageResolution(image, defaultResolution);

    const origin = { x: -width / 2, y: -height / 2 };
    const p1 = getPointRelativeToOrigin(point, origin);
    const p2 = getPointRelativeToOrigin(pointTo, origin);
    const { x: x1, y: y1 } = pointPixelsToUserUnitsRounded(p1, resolution, measurementUnit);
    const { x: x2, y: y2 } = pointPixelsToUserUnitsRounded(p2, resolution, measurementUnit);
    const p0 = getPointRelativeToOrigin(pointTo, point);
    const { x: w, y: h } = pointPixelsToUserUnitsRounded(p0, resolution, measurementUnit);
    const d = getDistanceToPoint({ x: w, y: h });
    measurementData = {
      ...measurementData,
      x1,
      y1,
      x2,
      y2,
      w: roundUserUnits(w, measurementUnit),
      h: roundUserUnits(h, measurementUnit),
      d: roundUserUnits(d, measurementUnit),
    };
  }

  return {
    ...state,
    distanceToolPoints: {
      point,
      pointTo,
      measurementData
    },
  };
};

const updateDensityData = (state, mouseLastPoint, densityAreaSize) => {
  const { images = {}, windowRef, resolution: defaultResolution, measurementUnit, densityData = {} } = state;

  const image = getSelectedImage(state);
  const { width, height } = image || {};
  const resolution = getImageResolution(image, defaultResolution);

  const origin = { x: -width / 2, y: -height / 2 };
  const p0 = getPointRelativeToOrigin(mouseLastPoint, origin);
  const densityPoint = pointPixelsToUserUnitsRounded(p0, resolution, measurementUnit);

  const sortedImages = sortImagesByColor(images);
  const areaSize = densityAreaSize ?? densityData.areaSize;
  const density = computeDensity(sortedImages, mouseLastPoint, areaSize, windowRef);

  return {
    ...state,
    mouseLastPoint,
    densityData: {
      ...density,
      pointOnStage: mouseLastPoint,
      point: densityPoint
    },
  };
};

const updateSelectedBox = (state, action) => {
  return {
    ...state,
    overlayBoxes: {
      ...state.overlayBoxes,
      [action.overlayBoxesName]: [
        ...state.overlayBoxes[action.overlayBoxesName].slice(0, action.boxIndex),
        {
          ...state.overlayBoxes[action.overlayBoxesName][action.boxIndex],
          isSelected: action.checked
        },
        ...state.overlayBoxes[action.overlayBoxesName].slice(action.boxIndex + 1)
      ]
    }
  };
};

const updateSelectedGuideline = (state, action) => {
  return {
    ...state,
    guidelines: state.guidelines.map(guideline => guideline.name === action.guidelineName ? {
      ...guideline,
      isSelected: action.checked
    } : guideline)
  };
};

const addImageHires = (state, action) => {
  if (isUndefined(state.images[action.imageTiles.key])) {
    state.images[action.imageTiles.key] = [];
  }
  if (isUndefined(state.images[action.imageTiles.key].imageTiles)) {
    state.images[action.imageTiles.key].imageTiles = [];
  }
  const tilePositionRowCol = action.imageTiles.tilePositionRowCol;

  const newLoadingTiles = !isUndefined(state.loadingTiles[action.imageTiles.key]) ?
    {
      ...state.loadingTiles,
      [action.imageTiles.key]: state.loadingTiles[action.imageTiles.key].filter(loadingTile => !(loadingTile.row === tilePositionRowCol.row && loadingTile.column === tilePositionRowCol.column))
    } :
    state.loadingTiles;
  return {
    ...state,
    images: {
      ...state.images,
      [action.imageTiles.key]: {
        ...state.images[action.imageTiles.key],
        imageTiles: [...state.images[action.imageTiles.key].imageTiles, action.imageTiles],
        resolution: action.resolution || state.resolution,
        key: action.imageTiles.key || state.key,
        height: action.height || state.height,
        width: action.width || state.width
      }
    },
    loadingTiles: newLoadingTiles
  };
};


const addImagesHires = (state, action) => {
  if (isUndefined(state.images[action.key])) {
    state.images[action.key] = [];
  }
  if (isUndefined(state.images[action.key].imageTiles)) {
    state.images[action.key].imageTiles = [];
  }

  const newLoadingTiles = !isUndefined(state.loadingTiles[action.key]) ?
    {
      ...state.loadingTiles,
      [action.key]: state.loadingTiles[action.key].filter(loadingTile => {
        const tileToRemove = action.images.find(tile => tile.imageTiles.tilePositionRowCol.row === loadingTile.row && tile.imageTiles.tilePositionRowCol.column === loadingTile.column);
        return isUndefined(tileToRemove);
      })
    } :
    state.loadingTiles;

  return {
    ...state,
    images: {
      ...state.images,
      [action.key]: {
        ...state.images[action.key],
        imageTiles: [...state.images[action.key].imageTiles, ...action.images.map(image => image.imageTiles)],
        resolution: action.resolution || state.resolution,
        key: action.key || state.key,
        height: action.height || state.height,
        width: action.width || state.width
      }
    },
    loadingTiles: newLoadingTiles
  };
};

const addLoadingTile = (state, action) => {
  if (isUndefined(state.loadingTiles[action.key])) {
    state.loadingTiles[action.key] = [];
  }
  const newLoadingTiles = {
    ...state.loadingTiles,
    [action.key]: [...state.loadingTiles[action.key], action.loadingTile]
  };
  return {
    ...state,
    loadingTiles: newLoadingTiles,
    loading: newLoadingTiles[action.key].length > 0
  };
};

const removeLoadingTile = (state, action) => {
  const newLoadingTiles = !isUndefined(state.loadingTiles[action.key]) ?
    {
      ...state.loadingTiles,
      [action.key]: state.loadingTiles[action.key].filter(loadingTile => !(loadingTile.row === action.loadingTile.row && loadingTile.column === action.loadingTile.column))
    } :
    state.loadingTiles;
  return {
    ...state,
    loadingTiles: newLoadingTiles
  };
};

const clearMergeImage = (state, action) => {
  if (isUndefined(state.images.mergedImage)) {
    return { ...state };
  }
  if (isUndefined(state.images.mergedImage.imageTiles)) {
    return { ...state };
  }
  return {
    ...state,
    images: {
      ...state.images,
      mergedImage: {
        ...state.images.mergedImage,
        imageTiles: []
      }
    }
  };
};

const addHiresNavigatorImage = (state, action) => {
  return {
    ...state,
    hiresNavigatorImage: action.hiresNavigatorImageData
  };
};

const getImagePoints = (image, rotation) => {
  const halfImageWidth = image.width / 2;
  const halfImageHeight = image.height / 2;
  const stageHelper = (new StageHelper()).setRotation(rotation);
  const pointA = stageHelper.getCanvasByStagePoint(-halfImageWidth, -halfImageHeight);
  const pointB = stageHelper.getCanvasByStagePoint(halfImageWidth, -halfImageHeight);
  const pointC = stageHelper.getCanvasByStagePoint(halfImageWidth, halfImageHeight);
  const pointD = stageHelper.getCanvasByStagePoint(-halfImageWidth, halfImageHeight);

  return { pointA, pointB, pointC, pointD };
};

const updateViewportPoints = (state, imageSize) => {
  imageSize = imageSize ?? getImageSize(getSelectedImage(state));
  if (!imageSize) {
    return state;
  }

  const {
    flipHorizontal,
    flipVertical,
    rotation,
    zoom,
    offsetPoint,
    mainCanvasWidth,
    mainCanvasHeight,
    mainCanvasInstance
  } = state;

  const viewportPoints = getViewportPoints(imageSize, flipHorizontal, flipVertical, rotation, zoom, offsetPoint, mainCanvasWidth, mainCanvasHeight, mainCanvasInstance);

  return {
    ...state,
    viewportPoints
  };
};

const fitToWidth = (state) => {
  const image = getSelectedImage(state);
  if (!image) {
    return state;
  }

  const { mainCanvasWidth, rotation } = state;

  const { pointA, pointB, pointC, pointD } = getImagePoints(image, rotation);

  const zoom = getRenderingWidth(mainCanvasWidth) / (Math.max(pointA.x, pointB.x, pointC.x, pointD.x) - Math.min(pointA.x, pointB.x, pointC.x, pointD.x));

  const offsetPoint = { x: 0, y: 0 };

  return updateViewportPoints({ ...state, zoom, offsetPoint });
};

const fitToHeight = (state) => {
  const image = getSelectedImage(state);
  if (!image) {
    return state;
  }

  const { mainCanvasHeight, rotation } = state;

  const { pointA, pointB, pointC, pointD } = getImagePoints(image, rotation);

  const zoom = getRenderingHeight(mainCanvasHeight) / (Math.max(pointA.y, pointB.y, pointC.y, pointD.y) - Math.min(pointA.y, pointB.y, pointC.y, pointD.y));

  const offsetPoint = { x: 0, y: 0 };

  return updateViewportPoints({ ...state, zoom, offsetPoint });
};

const fitToView = (state) => {
  const image = getSelectedImage(state);
  if (!image) {
    return state;
  }

  const { mainCanvasWidth, mainCanvasHeight, rotation } = state;

  const { pointA, pointB, pointC, pointD } = getImagePoints(image, rotation);

  const fitWidthZoom = getRenderingWidth(mainCanvasWidth) / (Math.max(pointA.x, pointB.x, pointC.x, pointD.x) - Math.min(pointA.x, pointB.x, pointC.x, pointD.x));
  const fitHeightZoom = getRenderingHeight(mainCanvasHeight) / (Math.max(pointA.y, pointB.y, pointC.y, pointD.y) - Math.min(pointA.y, pointB.y, pointC.y, pointD.y));
  const zoom = Math.min(fitWidthZoom, fitHeightZoom);

  const offsetPoint = { x: 0, y: 0 };

  return updateViewportPoints({ ...state, zoom, offsetPoint });
};

const toActualSize = (state) => {
  const image = getSelectedImage(state);
  if (!image) {
    return state;
  }

  const zoom = 1;

  const offsetPoint = { x: 0, y: 0 };

  return updateViewportPoints({ ...state, zoom, offsetPoint });
};

const updateZoom = (state) => {
  const { fitMode } = state;

  switch (fitMode) {
    case 'fit_to_width':
      state = fitToWidth(state);
      break;
    case 'fit_to_height':
      state = fitToHeight(state);
      break;
    case 'fit_to_view':
      state = fitToView(state);
      break;
    case 'actual_size':
      state = toActualSize(state);
      break;
  }

  return state;
};

const addGuide = (state, guide) => {
  const guides = [...state.guides];
  guides.push(guide);

  return { ...state, guides };
};

const updateGuide = (state, guide) => {
  const guides = [...state.guides];
  const idx = guides.findIndex(g => g.id === guide.id);
  if (idx >= 0) {
    guides[idx] = { ...guides[idx], ...guide };
  }

  return { ...state, guides };
};

const removeGuide = (state, id) => {
  const guides = [...state.guides];
  const idx = guides.findIndex(g => g.id === id);
  if (idx >= 0) {
    guides.splice(idx, 1);
  }

  return { ...state, guides };
};

export default (state, action) => {
  const { type, ...payload } = action;
  // console.log('=== action.type=', action.type);

  switch (type) {
    case 'STARTUP_STATE':
      return {
        ...state,
        ...payload,
      };
    case 'ADD_IMAGE':
      return {
        ...state,
        images: { ...state.images, [action.imageData.key]: action.imageData },
        resolution: action.resolution || state.resolution
      };
    case 'LOAD_IMAGE':
      return updateZoom({ ...state, ...payload });
    case 'UPDATE_VERSIONS':
      //note: the key is the versionNumber
      return { ...state, versions: { ...state.versions, [action.versionData.key]: action.versionData } };
    case 'LOAD_OVERLAY_DATA':
      return updateOverlayData(state, action.overlayData);
    case 'ADD_GUIDE':
      return addGuide(state, payload);
    case 'UPDATE_GUIDE':
      return updateGuide(state, payload);
    case 'REMOVE_GUIDE':
      return removeGuide(state, action.id);
    case 'LOAD_GRID_CELLS':
      return { ...state, gridCells: action.gridCells };
    case 'CANVAS_RESIZE':
      return updateZoom({ ...state, ...payload });
    case 'FIT_TO_WIDTH':
      return state.fitMode === 'fit_to_width' ?
        { ...state, fitMode: '' } :
        fitToWidth({ ...state, fitMode: 'fit_to_width' });
    case 'FIT_TO_HEIGHT':
      return state.fitMode === 'fit_to_height' ?
        { ...state, fitMode: '' } :
        fitToHeight({ ...state, ...payload, fitMode: 'fit_to_height' });
    case 'FIT_TO_VIEW':
      return state.fitMode === 'fit_to_view' ?
        { ...state, fitMode: '' } :
        fitToView({ ...state, ...payload, fitMode: 'fit_to_view' });
    case 'ACTUAL_SIZE':
      return state.fitMode === 'actual_size' ?
        { ...state, fitMode: '' } :
        toActualSize({ ...state, ...payload, fitMode: 'actual_size' });
    case 'ZOOM':
      return { ...state, ...payload, fitMode: '' };
    case 'ROTATE':
      return { ...state, rotation: action.rotation };
    case 'UPDATE_ROTATION_DEGREE':
      return { ...state, rotationDegree: action.rotationDegree };
    case 'FLIP_HORIZONTAL':
      return { ...state, flipHorizontal: action.flipHorizontal };
    case 'FLIP_VERTICAL':
      return { ...state, flipVertical: action.flipVertical };
    case 'RULER_MOUSE_POINT_CHANGE':
      return { ...state, rulerMousePoint: action.rulerMousePoint };
    case 'MOVE_TOOL':
      return {
        ...state,
        ...getInitialToolsData(),
        isDistanceTool: action.isDistanceTool,
        isMoveTool: action.isMoveTool,
        isDensityTool: action.isDensityTool
      };
    case 'DISTANCE_TOOL':
      return {
        ...state,
        ...getInitialToolsData(),
        isDistanceTool: action.isDistanceTool,
        isMoveTool: action.isMoveTool,
        isDensityTool: action.isDensityTool
      };
    case 'DENSITY_TOOL':
      return {
        ...state,
        ...getInitialToolsData(),
        isDistanceTool: action.isDistanceTool,
        isMoveTool: action.isMoveTool,
        isDensityTool: action.isDensityTool
      };
    case 'TOGGLE_NAVIGATOR':
      return { ...state, showNavigator: action.showNavigator };
    case 'NAVIGATOR_MOUSE_DOWN':
      return { ...state, isNavigatorMouseDown: action.isNavigatorMouseDown };
    case 'DISTANCE_POINT_CHANGE':
      return updateMeasurementData(state, action.distanceToolPoints);
    case 'MOVE_POINT_CHANGE':
      return {
        ...state,
        offsetPoint: action.offsetPoint,
        mouseLastPoint: action.mouseLastPoint
      };
    case 'CANVAS_MOUSE_DOWN':
      return {
        ...state,
        isCanvasMouseDown: action.isCanvasMouseDown,
        mouseDownPoint: action.mouseDownPoint,
      };
    case 'MOUSE_MOVE_POINT':
      return {
        ...state,
        mouseMovePoint: action.mouseMovePoint
      };
    case 'RULER_ORIGIN_POINT':
      return {
        ...state,
        rulerOriginPoint: action.rulerOriginPoint
      };
    case 'DENSITY_POINT_CHANGE':
      return updateDensityData(state, action.mouseLastPoint, action.areaSize);
    case 'MEASUREMENT_UNIT_CHANGE':
      return updateMeasurementUnit(state, action.measurementUnit);
    case 'MEDIA_BOXES_CHANGED':
      return { ...state, showMediaBoxes: action.showMediaBoxes };
    case 'GUIDELINES_CHANGED':
      return { ...state, showGuidelines: action.showGuidelines };
    case 'PAGE_LABELS_CHANGED':
      return { ...state, showPageLabels: action.showPageLabels };
    case 'PDF_BOXES_CHANGED':
      return { ...state, showPDFBoxes: action.showPDFBoxes };
    case 'COMPARE_DATA':
      return { ...state, compareData: action.compareData };
    case 'UPDATE_ACTIVE_AND_SELECTED_INDEX':
      return {
        ...state,
        activeVersionNumber: action.activeVersionNumber,
        selectedVersionNumber: action.selectedVersionNumber
      };
    case 'UPDATE_SELECTED_VERSION_INDEX':
      return { ...state, selectedVersionNumber: action.selectedVersionNumber };
    case 'UPDATE_ACTIVE_VERSION_INDEX':
      return { ...state, activeVersionNumber: action.activeVersionNumber };
    case 'UPDATE_LOADING':
      return { ...state, loading: action.loading };
    case 'LOAD_INITIAL_DATA':
      return {
        ...state,
        ...payload,
      };
    case 'UPDATE_HOLD':
      return { ...state, isHold: action.isHold, };
    case 'UPDATE_COMPARE_VERSIONS_NUMBERS_SELECTED':
      return { ...state, compareVersionsNumbersSelected: action.compareVersionsNumbersSelected };
    case 'UPDATE_TOOGLING_BETWEEN_COMPARE_VERSIONS_INDEX':
      return { ...state, togglingBetweenCompareVersionsIndex: action.togglingBetweenCompareVersionsIndex };
    case 'UPDATE_SELECTED_BOX':
      return updateSelectedBox(state, action);
    case 'UPDATE_SELECTED_GUIDELINE':
      return updateSelectedGuideline(state, action);
    case 'COLOR_MANAGEMENT_CHANGED':
      return { ...state, colorManagement: action.colorManagement };
    case 'LOAD_HIRES_DATA':
      return { ...state, hiresData: action.hiresData };
    case 'ADD_IMAGE_HIRES':
      return addImageHires(state, action);
    case 'UPDATE_SHOW_VERSIONS':
      return { ...state, showVersions: action.showVersions };
    case 'SET_SEPARATIONS':
      return { ...state, separations: action.separations };
    case 'ADD_IMAGES_HIRES':
      return addImagesHires(state, action);
    case 'UPDATE_VIEWPORT_POINTS':
      return updateViewportPoints(state, action.imageSize);
    case 'ADD_LOADING_TILE':
      return addLoadingTile(state, action);
    case 'REMOVE_LOADING_TILE':
      return removeLoadingTile(state, action);
    case 'CLEAR_MERGE_IMAGES':
      return clearMergeImage(state, action);
    case 'ADD_NAVIGATOR_IMAGE'://for hires view
      return addHiresNavigatorImage(state, action);
    case 'LOADING_STATUS':
      return { ...state, loadingStatus: action.loadingStatus };
    case 'SET_MAIN_CANVAS_INSTANCE':
      return { ...state, ...payload };
    case 'UPDATE_NAVIGATOR_FILTER':
      return { ...state, ...payload };
    default:
      return state;
  }
};