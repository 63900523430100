/**
 * @name UploadHistoryView
 * @file Upload History View entry point
 *
 * @author Boris
 * @since: 2017-07-02
 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import { convertUploadDate, convertAssignDate } from '../UploadView/utils';
import SimpleForm from 'widgets/SimpleForm/src/index';
import UploadHistory from './components/UploadHistory';
import { COMPARE_TYPE, createObjectComparator } from 'core/comparators';
import AbstractModule from 'AbstractModule';

const futils = SimpleForm.utils;

export default AbstractModule.extend({

  model: null,

  init: function () {

  },

  initDone: function () {
    this.reactRoot = createRoot(this.domElement);
  },

  destroy: function () {
    this._super();
  },

  firstTickReceived: function (data) {
    console.log('###UploadHistoryView.firstTickReceived=>', data);
    const folder = futils.get(data, 'model.children.0');
    if (!folder) {
      return;
    }

    const uploadedFiles = folder.uploadedfile || [];
    convertUploadDate(uploadedFiles);
    convertAssignDate(uploadedFiles);
    uploadedFiles.sort(createObjectComparator('uploadDate', COMPARE_TYPE.DATES, false));

    this.initialModel = data.model;
    this.model = {
      folderNwid: folder.nwid,
      viewNwid: this.nwid,
      links: folder.viewLinks || [],
      uploadedFiles,
      filesFromTickUpdate: [],
      module: this
    };

    this.render();
  },

  tickUpdate: function (data) {
    console.log('###UploadHistoryView.tickUpdate=>', data);
    const filesFromTickUpdate = data.model || [];
    convertUploadDate(filesFromTickUpdate);
    convertAssignDate(filesFromTickUpdate);
    this.model.filesFromTickUpdate = filesFromTickUpdate;

    this.render();
  },

  fileToPage: function (item) {
    return {
      nwid: item.objectNwId,
      type: item.objectTemplate,
      links: item.viewLinks
    };
  },

  handleContextMenu: function (e, clickedFile, selectedFiles) {
    console.log('###UploadView.handleContextMenu() ===', e, clickedFile, selectedFiles);
    if (clickedFile && selectedFiles && selectedFiles.length > 0) {
      const clickedPage = this.fileToPage(clickedFile);
      const selectedPages = selectedFiles.map(f => this.fileToPage(f));
      this.showContextMenu(clickedPage, selectedPages, e);
    }
  },

  render: function () {
    this.reactRoot.render(
      <UploadHistory model={this.model}
        onContextMenu={(e, file, files) => this.handleContextMenu(e, file, files)}
      />
    );
  }
});
