/* @flow weak */

import AbstractModule from 'AbstractModule';
import React from 'react';
import { createRoot } from 'react-dom/client';
import sandbox from 'sandbox';
import SimpleForm from 'widgets/SimpleForm/src/index';
import CoretexTables from './components/CoretexTables';
import reducer from './reducer';
import toastService from 'core/services/toastService';
import helpers from './helpers';
import labels from './labels';
import cellsConversion from './cellsConversion';
import newTableDialog from './newTableDialog';
import appUtils from 'sandbox/appUtils';
import { restGet, restDelete, restPut } from 'core/managers/rest2';
import { createToastService } from 'core/services/toastService';
import { createObjectComparator } from 'core/comparators';
import { translate } from 'core/services/localization';
import { findDuplicates } from 'utilities/array';


const { Store } = SimpleForm;

const isPredefinedTable = (model) => {
  return model.table && model.table.predefined === true;
};

const isUndefined = (obj) => typeof obj === 'undefined';
const hasValue = (obj) => !isUndefined(obj) && !(obj === '');
const isEmpty = (obj) => isUndefined(obj) || Object.keys(obj).length === 0;

const SELECTORS_ALL_FIELD_TYPES = ['publication', 'edition', 'zone', 'section', 'broadsheet', 'tabs', '2ups', '4ups', '8ups', 'customLayoutGroup', 'siteName', 'siteCode', 'siteListNames', 'siteListCodes', 'pageSize'];

const toolbarButtons = (module) => {
  return {
    view: [],
    manage: [
      {
        label: 'Add Table',
        name: 'Add Table',
        _isApplicable: true,
        icon: 'add',
        tooltip: 'Add Table',
        groupName: 'Manage Tables Group',
        execute: function () {
          const store = module.store;
          const sampleTables = typeof store !== 'undefined' ? store.get('AvailableSampleTableNames') : [];
          const systemTables = typeof store !== 'undefined' ? store.get('AvailableSystemTableNames') : [];

          const folderNwid = store.get('folderNwid');
          newTableDialog.show(['samples', 'systems'], sampleTables, systemTables, function (selectedCategory, selectedTable) {

            if (typeof selectedCategory === 'undefined' || typeof selectedTable === 'undefined') return;
            const command = selectedCategory === 'samples' ? 'sample' : selectedCategory === 'systems' ? 'system' : undefined;
            const tableName = selectedTable.name;
            const isBlankTable = selectedTable.isBlank || false;
            if (typeof command === 'undefined') return;

            if (isBlankTable) {
              module.store.dispatch({
                type: 'ADD_TABLE'
              });
            }
            else {
              restGet(module.nwid, `conversion-tables/${tableName}/definition?type=${command}&folder=${folderNwid}`)
                .then(tableDefinition => {
                  console.log('rest resault: ', tableDefinition);
                  module.store.dispatch({
                    type: 'ADD_TABLE_BY_DEF',
                    definition: tableDefinition,
                    tableType: selectedCategory
                  });
                });
            }

          }.bind(this));
        }.bind(module)
      }
    ]
  };
};
const addModeButtons = (module, toolbar, mode) => {
  const buttons = toolbarButtons(module)[mode] || [];
  buttons.forEach((button) => {
    toolbar.addItem(button);
  });
};
const getButtonsGroup = (module, mode) => {
  const buttons = toolbarButtons(module)[mode] || [];
  const groups = buttons.reduce((accGroups, button) => {
    if (typeof button.groupName === 'string' && accGroups.indexOf(button.groupName) < 0) {
      return [...accGroups, button.groupName];
    }
    return accGroups;
  }, []);
  return groups;
};
const removeAllModeButtons = (module, toolbar) => {
  Object.keys(toolbarButtons(module)).forEach(function (toolbarMode) {
    let groups = getButtonsGroup(module, toolbarMode);
    groups.forEach((groupName) => {
      toolbar.removeGroup(groupName);
    });
  });

};
const setToolbarMode = (module, toolbar, mode) => {
  removeAllModeButtons(module, toolbar);
  addModeButtons(module, toolbar, mode);
};
const initTabs = (tables) => {
  return tables.map((table) => {
    return {
      nwid: table.nwid,
      name: table.name
    };
  });
};

const assignDefaultValuesToEmptySelectors = (selectors, selectorsDefinition) => {
  return selectorsDefinition.reduce((acc, selectorDefinition) => {
    const selectorName = selectorDefinition.name;
    const selectorDefaultValue = selectorDefinition.defaultValue;
    if (typeof selectors[selectorName] === 'undefined') {
      return Object.assign({}, acc, { [selectorName]: selectorDefaultValue });
    }

    return acc;
  }, {});
};

const initTableRowSelector = (table, selectors = {}) => {
  const selectorsDefinition = table.selectors || [];

  return Object.assign({}, selectors, assignDefaultValuesToEmptySelectors(selectors, selectorsDefinition));
};
const initTableRowValue = (table, values = {}) => {
  const valuesDefinition = table.values || [];

  return Object.assign({}, values, assignDefaultValuesToEmptySelectors(values, valuesDefinition));
};
const initTableRows = (table) => {
  const rows = table.rows || [];
  return rows.map((row) => {
    return {
      ...row,
      selectors: initTableRowSelector(table, row.selectors),
      values: initTableRowValue(table, row.values)
    };
  });
};
const initTable = (tables) => {
  return tables.map((table) => {
    return {
      ...table,
      oldName: table.name,
      rows: initTableRows(table)
    };
  });
};
const initPredefinedState = (model) => {
  const { table, ...restModel } = model;
  return initState({ tables: [table], predefined: true, ...restModel });
};
const initState = (state) => {
  const currentColumnType = helpers.getFirstColumnType(state);
  const currentTableIndex = state.tables.length > 0 ? 0 : -1;
  const currentColumnIndex = currentTableIndex >= 0 ? helpers.getFirstColumnIndex(state, 0, currentColumnType) : -1;
  state.tables.forEach((table) => {
    table.isPublicationTable = table.publication === '' || isUndefined(table.publication) ? false : true;
  });
  return {
    ...state,
    tabs: initTabs(state.tables),
    modifiedTabs: {},
    currentTableIndex: currentTableIndex,
    tables: initTable(state.tables),
    viewMode: 'view',
    currentColumnType: currentColumnType,
    currentColumnIndex: currentColumnIndex,
    folderNwid: state.nwid,
    AvailableSampleTableNames: state.AvailableSampleTableNames || [],
    AvailableSystemTableNames: state.AvailableSystemTableNames || [],
    deletedTables: state.deletedTables || [],
  };
};

const getEmptyState = () => {
  return {
    tabs: [],
    modifiedTabs: {},
    currentTableIndex: 0,
    tables: [],
    viewMode: 'view',
    currentColumnType: 0,
    currentColumnIndex: 0,
    folderNwid: undefined,
    AvailableSampleTableNames: [],
    AvailableSystemTableNames: [],
    deletedTables: []
  };
};

const createUserPreferencesStructure = (model, dataPreferences) => {
  const modelTables = !isUndefined(model.tables) ? model.tables : !isUndefined(model.table) ? [model.table] : [];
  const tables = modelTables.reduce((acc, table) => {
    return Object.keys(dataPreferences).length === 0 || isUndefined(dataPreferences.tables) || isUndefined(dataPreferences.tables[table.name]) ? {
      ...acc,
      [table.name]: { columns: {} }
    } : { ...acc, [table.name]: dataPreferences.tables[table.name] };
  }, {});
  return { tables };
};
const assignRowsIndex = (row, index) => {
  let stateRow = Object.assign({}, row, { index });
  return stateRow;
};
const convertTablesToState = (table) => {
  let stateTable = Object.assign({}, table);
  // if (isNaN(stateTable.nwid)) delete stateTable.nwid;
  stateTable.rows = stateTable.rows.map(assignRowsIndex);
  return stateTable;
};

const saveState = (state) => {
  let ret = Object.assign({}, state);
  ret.tables = state.tables.map(convertTablesToState);
  ret.tables.forEach(function (table) {
    if (!table.matchingMode) table['matchingMode'] = 'text';
    if (!table.singleValue) table['singleValue'] = false;
  });

  return ret;
};
const hasTablesWithEmptyName = (tables = []) => {
  return !tables.every(table => typeof table.name === 'string' && table.name !== '');
};

const hasTablesWithExistingName = (tables) => {

  let names = tables.map(table => table.name);

  return names.some((name, i) => names.indexOf(name) !== i);

};

const getDuplicates = (table, columns, columnType) => {
  let duplicate;
  for (const col of columns) {
    const duplicates = findDuplicates(table.rows, row => row[columnType][col.name]);

    if (duplicates.length > 0) {
      duplicate = {
        columnName: col.displayName,
        duplicateName: duplicates[0][0],
        tableName: table.name,
      };
      break;
    }
  }

  return duplicate;
};

const getDuplicationInUniqueColumn = tables => {
  let duplicate;
  const uniqueTables = tables.filter(table => table.selectors.some(s => s.unique) || table.values.some(v => v.unique));
  for (const table of uniqueTables) {
    const uniqueSelectors = table.selectors.filter(s => s.unique);
    duplicate = getDuplicates(table, uniqueSelectors, 'selectors');
    if (duplicate) {
      break;
    }
    const uniqueValues = table.values.filter(v => v.unique);
    duplicate = getDuplicates(table, uniqueValues, 'values');
    if (duplicate) {
      break;
    }
  }
  return duplicate;
};

const validColumn = (column) => {
  return column && typeof column.name === 'string' && column.name !== '' && typeof column.displayName === 'string' && column.displayName !== '';
};
const tableHasValidSelctorsOrValues = (table) => {
  const selectors = table.selectors || [];
  const values = table.values || [];

  return selectors.length > 0 && values.length > 0 && selectors.every(selector => validColumn(selector)) && values.every(value => validColumn(value));
};
const hasTablesWithNoValidSelectorsOrValues = (tables = []) => {
  return !tables.every(table => tableHasValidSelctorsOrValues(table));
};

const hasPublicationTablesWithPublicationSelector = (tables = []) => {
  const publicationTablesWithPublicationSelector = tables.filter((table) => {
    const hasPublicationSelector = table.selectors.filter(selector => selector.fieldType === 'publication');
    return table.isPublicationTable && hasPublicationSelector.length > 0;
  });
  return publicationTablesWithPublicationSelector;
};

const hasSingleValueTableWithMoreThenOneValue = (tables = []) => {
  let singleValueTableWithMoreThenOneValue = [];
  tables.forEach((table, index) => {
    if (table.singleValue) {
      const moreThenOneValue = table.rows.find((row) => {
        let valuesCounter = 0;
        for (var value in row.values) {
          if (hasValue(row.values[value])) {
            valuesCounter++;
          }
        }
        if (valuesCounter > 1) {
          return true;
        }
        return false;
      });
      if (!isUndefined(moreThenOneValue)) {
        singleValueTableWithMoreThenOneValue.push(table.name);
      }
    }
  });
  return singleValueTableWithMoreThenOneValue;
};

/*
* The logic of the showAllTables supports backward compatibility, which is in the case
* that if it was not configured yet, then it will behave like before. (show all tables if no table was selected).
*/

const shouldShowAllTables = (showAllTables, relevantTabels) => {
  return showAllTables === true || typeof showAllTables === 'undefined' && relevantTabels.length === 0;
};

const getTabels = async (moduleNwid, relevantTabels, showAllTables) => {
  if (shouldShowAllTables(showAllTables, relevantTabels)) {
    const res = await restGet(moduleNwid, `conversion-tables/names`);
    relevantTabels = res.names;
  }

  return Promise.all(relevantTabels.map(tableName => restGet(moduleNwid, `conversion-tables/${encodeURIComponent(tableName)}`)));
};

const sortingList = listToSort => listToSort.sort(createObjectComparator('name'));

const apply = (state, store) => {
  let newModel = saveState(state);

  const tablesToSave = Object.keys(newModel.modifiedTabs).reduce((acc, modifiedTab) => {
    let tableToSave = newModel.tables.find(table => table.nwid === modifiedTab);
    if (tableToSave) {
      const replicaTable = state.replicaTables?.[tableToSave.nwid];
      tableToSave = replicaTable || tableToSave;
      acc.push(tableToSave);
    }

    return acc;
  }, []).map((table) => {
    const selectorsAllFieldType = table.selectors.filter(function (selector) {
      return !selector.freeText && (SELECTORS_ALL_FIELD_TYPES.findIndex((item) => {
        return item === selector.fieldType;
      }) >= 0);
    });

    const rows = table.rows.map(row => {
      // selectorsAllFieldType.forEach(function (selector) {
      //   if (row.selectors[selector.name] === "") {
      //     row.selectors[selector.name] = '*';
      //   }
      // });

      return {
        ...row,
        selectors: Object.keys(row.selectors).reduce((acc, selectorKey) => {
          if (!isUndefined(selectorsAllFieldType.find(selector => selector.name === selectorKey)) && row.selectors[selectorKey] === '') {
            acc[selectorKey] = '*';
          }
          else {
            const fieldType = (table.selectors.find(selector => selector.name === selectorKey) || {}).fieldType;
            acc[selectorKey] = getFieldValueToSave(fieldType, row.selectors[selectorKey]);
          }

          return acc;
        }, {}),

        values: Object.keys(row.values).reduce((acc, valueName) => {
          const fieldType = (table.values.find(value => value.name === valueName) || {}).fieldType;
          const valueToSave = getFieldValueToSave(fieldType, row.values[valueName]);
          acc[valueName] = valueToSave;
          return acc;
        }, {}),
      };
    });

    if (!table.isPublicationTable) {
      table.publication = '';
    }
    delete table.isPublicationTable;

    if (table.oldName !== table.name) {
      const indexOfTableInDeletedTables = newModel.deletedTables.join(',').indexOf(table.oldName);
      if (indexOfTableInDeletedTables < 0) {
        newModel.deletedTables.push(table.oldName);
      };

    }
    delete table.oldName;

    return {
      ...table,
      rows
    };
  });

  if (hasTablesWithEmptyName(tablesToSave)) {
    toastService.errorToast(labels.saveError, labels.noEmptyTableName);
    return;
  }
  if (hasTablesWithExistingName(state.tables)) {
    toastService.errorToast(labels.saveError, labels.noExistingTableName);
    return;
  }
  const duplicationInUniqueColumn = getDuplicationInUniqueColumn(state.tables);
  if (duplicationInUniqueColumn) {
    toastService.errorToast(labels.saveError, labels.duplicationInUniqueColumn(duplicationInUniqueColumn.tableName, duplicationInUniqueColumn.columnName, duplicationInUniqueColumn.duplicateName));
    return;
  }
  if (hasTablesWithNoValidSelectorsOrValues(tablesToSave)) {
    toastService.errorToast(labels.saveError, labels.noValidSelectorsOrValues);
    return;
  }
  const publicationTablesWithPublicationSelector = hasPublicationTablesWithPublicationSelector(tablesToSave);

  if (publicationTablesWithPublicationSelector.length > 0) {
    toastService.errorToast(labels.saveError, translate('The {1} table belongs to a publication and cannot include a Publication selector.', publicationTablesWithPublicationSelector[0].name));
    return;
  }

  const singleValueTableWithMoreThenOneValue = hasSingleValueTableWithMoreThenOneValue(tablesToSave);

  if (singleValueTableWithMoreThenOneValue.length > 0) {
    const tableName = singleValueTableWithMoreThenOneValue[0];
    toastService.errorToast(labels.saveErrorinASingleValueTable, `${labels.atLeastOneRowWithMultipleValuesIn} ${tableName}`);
    const tableIndex = newModel.tables.findIndex(table => table.name === tableName);
    store.dispatch({
      type: 'UPDATE_CURRENT_TABLE',
      'currentTableIndex': tableIndex
    });
    return;
  }

  return {
    tablesToSave,
    deletedTables: newModel.deletedTables
  };
};

const initiateTablesState = (tables) => {
  const newTables = tables.map((table) => {
    if (typeof table.rows === 'undefined') {
      table.rows = [];
    }
    table.rows.forEach((row) => {
      Object.keys(row.selectors).forEach((selectorName) => {
        const tableSelector = table.selectors.find(selector => selector.name === selectorName) || {};
        const fieldType = tableSelector.fieldType;
        if (!isUndefined(fieldType) && !isUndefined(cellsConversion[fieldType])) {
          row.selectors[selectorName] = cellsConversion[fieldType].onLoad(row.selectors[selectorName]);
        }
      });
      Object.keys(row.values).forEach((valueName) => {
        const tableValue = table.values.find(value => value.name === valueName) || {};
        const fieldType = tableValue.fieldType;
        if (!isUndefined(fieldType) && !isUndefined(cellsConversion[fieldType])) {
          row.values[valueName] = cellsConversion[fieldType].onLoad(row.values[valueName]);
        }
      });
    });
    return {
      ...table,
      overridable: isUndefined(table.overridable) ? true : table.overridable
    };
  });
  return newTables;
};

const getFieldValueToSave = (fieldType, value) => {
  if (!isUndefined(cellsConversion[fieldType])) {
    return cellsConversion[fieldType].onSave(value);
  }
  return value;
};

module.exports = AbstractModule.extend({

  allowStandbyMode: true,

  shouldRenderOnFirstTime: true,

  store: undefined,

  domNode: undefined,

  selected: [],

  manageViewMode: function (pushed) {
    const viewMode = pushed ? 'manage' : 'view';
    setToolbarMode(this, this.toolbar, viewMode);
    if (typeof this.store !== 'undefined') {
      this.store.dispatch({
        type: 'SET_VIEW_MODE',
        viewMode: viewMode
      });
    }
  },

  saveTables: function (list) {
    if (list.length === 0) {
      return Promise.resolve();
    }

    const restRequests = list.map(item => {
      if (isNaN(item.nwid)) {
        delete item.nwid;
      }

      return restPut(this.nwid, `conversion-tables/set-table`, item);
    });

    return Promise.all(restRequests)
      .catch(err => toastService.errorToast('', err.statusText));
  },

  deleteTables: function (list) {
    if (list.length === 0) {
      return Promise.resolve();
    }

    const restRequests = list.map(item => restDelete(this.nwid, `conversion-tables/${item}`));

    return Promise.all(restRequests);
  },

  handleSaveTabels: function () {
    let state = this.store.getState();

    state = apply(state, this.store);
    if (!isUndefined(state)) {
      Promise.all([
        this.saveTables(state.tablesToSave),
        this.deleteTables(state.deletedTables)
      ]).then(([savedTables, deletedTables]) => {
        this.store.dispatch({
          type: 'CLEAN_CHANGED'
        });
        this.onSaveActionResponse(savedTables);
      });
    }
  },

  toggleSortAndFilters: function (pushed) {
    this.store.dispatch({
      type: 'UPDATE_SORT_AND_FILTER_MODE',
      sortAndFilterEnabled: pushed
    });
  },

  handleChangeIsApplicable: function () {
    const state = this.store.getState();
    if (Object.keys(state.modifiedTabs).length > 0 || state.deletedTables.length > 0) {
      return true;
    }
    return false;
  },

  initDone: function () {
    this.store = undefined;
    this.viewMode = 'view';
    this.render = this.render.bind(this);
    this.reactRoot = createRoot(this.domElement);
  },

  firstTickReceived: function (data) {
    let model = data.model;
    this.store = new Store(getEmptyState(), reducer, this.render.bind(this));
    this.store.subscribe(this.handleDispatch.bind(this));
    this.toolbar = this.createToolbar();



    const showAllTables = data.config && data.config.showAllTables;
    const relevantTabels = data.config && data.config.tables || [];
    if (shouldShowAllTables(showAllTables, relevantTabels)) {
      this.toolbar.addItem({
        label: 'Manage Tables',
        name: 'View Manage Mode',
        alignRight: true,
        _isApplicable: true,
        icon: 'edit',
        tooltip: translate('Manage Tables'),
        itemType: 'push',
        execute: this.manageViewMode.bind(this)
      });
    }

    this.toolbar.addItem({
      label: translate('Save'),
      name: 'save',
      isApplicable: this.handleChangeIsApplicable.bind(this),
      icon: 'save',
      tooltip: translate('Save'),
      execute: this.handleSaveTabels.bind(this)
    });

    this.toolbar.addItem({
      label: translate('Toggle Sort and Filters'),
      name: 'toggleSortAndFilters',
      _isApplicable: true,
      icon: 'filter_list.svg',
      itemType: 'push',
      execute: this.toggleSortAndFilters.bind(this)
    });

    const toastService = createToastService(module.win);

    Promise.all([
      getTabels(this.nwid, relevantTabels, showAllTables),
      restGet(this.nwid, 'conversion-tables/names/system').then(res => res.names),
      restGet(this.nwid, 'conversion-tables/names/sample').then(res => res.names),
      restGet(this.nwid, `planning/product-setup?nwid=${model.nwid}`, {}, { version: 'v1' }).then(res => res.publications),
      restGet(this.nwid, `planning/layout-groups?nwid=${model.nwid}`, {}, { version: 'v1' }),
      restGet(this.nwid, 'village/sites').then(res => res.sites),
      restGet(this.nwid, `profiles/groups/${this.folderNwid}`).then(res => res.groups),
      restGet(this.nwid, 'workflow-browse/user-workflows-info').then(res => res.folders),
      restGet(this.nwid, 'que-manager/resources').then(res => res.resources)
    ])
      .then(res => {
        let tablesData = res[0];
        let AvailableSystemTableNames = res[1].sort(createObjectComparator('name'));
        let AvailableSampleTableNames = res[2];
        let publications = res[3];
        let layoutGroups = res[4];
        let sites = res[5];
        let folderSecurityGroups = res[6];
        let flowSteps = res[7];
        let resources = res[8];

        tablesData = tablesData.filter(data => !isEmpty(data));
        const baseState = isUndefined(tablesData) ? initState({ tables: [], nwid: '' }) : initState({
          tables: initiateTablesState(tablesData),
          AvailableSystemTableNames,
          AvailableSampleTableNames,
          nwid: this.folderNwid
        });
        const userPreferences = createUserPreferencesStructure(baseState, data.preferences);

        baseState.tables = sortingList(baseState.tables);
        baseState.tabs = sortingList(baseState.tabs);

        sites.sort(createObjectComparator('name'));
        publications.sort(createObjectComparator('name'));

        let allEditionsOptions = [];
        let allSectionsOptions = [];
        let allZoneOptions = [];

        publications.forEach(function (publication) {
          publication.value = publication.name;
          for (let item in publication) {
            if (item === 'editions') {
              publication[item].sort(createObjectComparator('name'));
              publication[item].forEach(function (edition) {
                edition.value = edition.name;
                if (isUndefined(allEditionsOptions.find(editionOption => editionOption.name === edition.name))) {
                  allEditionsOptions.push({
                    name: edition.name,
                    value: edition.name
                  });
                }
              });
            }
            if (item === 'sections') {
              publication[item].sort(createObjectComparator('name'));
              publication[item].forEach(function (section) {
                section.value = section.name;
                if (isUndefined(allSectionsOptions.find(sectionOption => sectionOption.name === section.name))) {
                  allSectionsOptions.push({
                    name: section.name,
                    value: section.name
                  });
                }
              });
            }
            if (item === 'zones') {
              publication[item].sort(createObjectComparator('name'));
              publication[item].forEach(function (zone) {
                zone.value = zone.name;
                if (isUndefined(allZoneOptions.find(zoneOption => zoneOption.name === zone.name))) {
                  allZoneOptions.push({
                    name: zone.name,
                    value: zone.name
                  });
                }
              });
            }
          }
        });
        allEditionsOptions.sort(createObjectComparator('name'));
        allSectionsOptions.sort(createObjectComparator('name'));
        allZoneOptions.sort(createObjectComparator('name'));

        let allPubOption = [{
          name: labels.all,
          value: '*',
          editions: allEditionsOptions,
          sections: allSectionsOptions,
          zones: allZoneOptions
        }];
        let publicationsOption = allPubOption.concat(publications);

        const pubsMap = Object.entries(layoutGroups).reduce((acc, [type, arr]) => {
          arr.forEach(group => {
            group.publications.forEach(pubName => {
              if (!acc[pubName]) {
                acc[pubName] = {};

              }
              if (!acc[pubName][type]) {
                acc[pubName][type] = [];

              }
              acc[pubName][type].push({ name: group.name, value: group.name });
            });
          });
          return acc;
        }, {});

        Object.entries(pubsMap).forEach(([pub, typesObj]) => {
          Object.keys(typesObj).forEach(type => {
            typesObj[type].sort(createObjectComparator('name'));
          });
        });

        const layoutGroupOptions = Object.entries(layoutGroups).reduce((acc, [type, arr]) => {
          if (type === 'custom') {
            type = 'customLayoutGroup';
          }
          acc[type] = arr.map(group => {
            return { name: group.name, value: group.name };
          }).sort(createObjectComparator('name'));

          return acc;
        }, {});


        const allLayoutGroupOptions = Object.entries(layoutGroups).reduce((acc, [type, arr]) => {
          arr.forEach(group => {
            acc.push({ name: group.name, value: group.name });
          });

          return acc;
        }, []);

        allLayoutGroupOptions.sort(createObjectComparator('name'));

        const allLayoutGroupOptionsByPublicationMap = Object.entries(layoutGroups).reduce((acc, [type, arr]) => {
          arr.forEach(group => {
            group.publications.forEach(pubName => {
              if (!acc[pubName]) {
                acc[pubName] = [];

              }
              acc[pubName].push({ name: group.name, value: group.name });
            });
          });
          return acc;
        }, {});

        Object.entries(allLayoutGroupOptionsByPublicationMap).forEach(([pub, groupsArr]) => {
          groupsArr.sort(createObjectComparator('name'));
        });


        this.store.dispatch({
          type: 'INIT_STATE',
          moduleNwid: this.nwid,
          baseState,
          userPreferences,
          publications: publicationsOption,
          layoutGroupOptions,
          layoutGroups,
          allLayoutGroupOptionsByPublicationMap,
          pubsMap,
          allLayoutGroupOptions,
          sites: sites,
          folderSecurityGroups,
          flowStepsByNwid: appUtils.flattenModelByNwid(flowSteps),
          resources: resources.sort(createObjectComparator('name'))
        });
      })
      .catch(resultErrorMessage => {
        toastService.createToast('top', 'Coretex Tables Setup', resultErrorMessage, 'error', undefined, undefined);
      });
  },

  tickUpdate: function () {

  },

  tickCommit: function () {
  },

  handleDispatch: function () {
    const state = this.store.getState();
    this.toolbar.refreshIsApplicableProperty();
    const table = state.tables[state.currentTableIndex];
    if (table) {
      this.toolbar.setItemDisabled('toggleSortAndFilters', false);
      this.toolbar.setItemChecked('toggleSortAndFilters', table.sortAndFilterEnabled);
    } else {
      this.toolbar.setItemDisabled('toggleSortAndFilters', true);
    };
  },

  onSaveActionResponse: function (response = []) {
    const data = response || [];
    const store = this.store;

    if (response.length > 0) {
      store.dispatch({
        type: 'SAVE_NEW_TABLES_NWID',
        data
      });

      store.dispatch({
        type: 'UPDATE_SAVED_TABLES_OLD_NAME',
        data
      });
    }
    this.toolbar.refreshIsApplicableProperty();
  },

  handleColumnResize: function (currentTableIndex, columns) {

    this.store.dispatch({
      type: 'SET_USER_PREFERENCES',
      columns,
      currentTableIndex,
    });

    sandbox.preferences.savePreferences(this.getRequiredParameters(), this.store.get('userPreferences'));
  },

  render: function () {
    if (!isUndefined(this.store)) {
      this.reactRoot.render(<CoretexTables store={this.store}
        onColumnResize={this.handleColumnResize.bind(this)} />);
    }
  }

});
