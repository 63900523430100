import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utilities/classNames';
import { Canvas, Stage, CanvasImage, Rect } from 'components/common/canvas';
import { getPointByRotation } from 'components/common/canvas/utilities';
import AccordionItem from './AccordionItem';
import { Checkbox } from 'components/common/inputs';
import { IconButton } from 'components/common/buttons';
import { Dropdown } from 'components/common/floating';
import MeasurementsUnit from './MeasurementsUnit';
import sandbox, { localization } from 'sandbox';
import { fromServerDate } from 'core/dates';
import {
  VIEW_TYPE_HIRES_VIEW,
  VIEW_TYPE_COMPARE,
} from '../utilities';

const translate = localization.translate;
const labels = {
  navigator: translate('NAVIGATOR'),
  distance: translate('MEASUREMENTS'),
  density: translate('DENSITY'),
  legend: translate('LEGEND'),
  versions: translate('VERSIONS'),
  total: translate('Total:'),
  input: translate('Input'),
  referencePoint: translate('Reference point:'),
  referenceSide: translate('Reference side:'),
};

const activeVersionIcon = sandbox.icons.getModuleIcon('PageView', 'activeVersion');

const CANVAS_NAV_HEIGHT = 270;
const CANVAS_NAV_WIDTH = 250;

const DENSITY_AREA_OPTIONS = [
  { value: 1, text: '1 x 1' },
  { value: 3, text: '3 x 3' },
  { value: 5, text: '5 x 5' },
];

export default class NavigationPanel extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    image: PropTypes.object,
    resolution: PropTypes.object,
    versions: PropTypes.object,
    rotation: PropTypes.number,
    zoom: PropTypes.number,
    flipHorizontal: PropTypes.bool,
    flipVertical: PropTypes.bool,
    isDistanceTool: PropTypes.bool,
    isMoveTool: PropTypes.bool,
    isDensityTool: PropTypes.bool,
    densityData: PropTypes.object,
    showMediaBoxes: PropTypes.bool,
    showPDFBoxes: PropTypes.bool,
    showVersions: PropTypes.bool,
    offsetPoint: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    }),
    imagePoint: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    }),
    rulerMousePoint: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    }),
    rulerVisible: PropTypes.bool,
    distanceToolPoints: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      measurementData: PropTypes.shape({
        x1: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        y1: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        x2: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        y2: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        w: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        h: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        d: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      })
    }),
    selectedVersionNumber: PropTypes.number,
    activeVersionNumber: PropTypes.number,
    compareVersionsNumbersSelected: PropTypes.array,
    viewType: PropTypes.string,
    onVersionClick: PropTypes.func,
    onNavCanvasMouseDown: PropTypes.func,
    onChangeMeasurementUnit: PropTypes.func,
    onChangeDensityAreaSize: PropTypes.func,
    onMainStageLoad: PropTypes.func,
    compareData: PropTypes.object,
    onLoad: PropTypes.func,
    onChangeBoxesSelected: PropTypes.func,
    hiresNavigatorImage: PropTypes.object,
    onChangeGuidelineSelected: PropTypes.func,
    guidelines: PropTypes.array,
    guides: PropTypes.array,
    onGuideVisible: PropTypes.func,
    onRemoveGuide: PropTypes.func,
    showGuidelines: PropTypes.bool,
    overlayBoxes: PropTypes.object,
    measurementUnit: PropTypes.string,
    mainCanvasInstance: PropTypes.object,
    viewportPoints: PropTypes.object,
    isNavigatorMouseDown: PropTypes.bool,
  };

  static defaultProps = {
    guides: [],
    onLoad: () => {
    }
  };

  componentDidMount() {
    const { onLoad } = this.props;
    onLoad(this);
  }

  handleNavCanvasMouseDown = (event) => {
    this.props.onNavCanvasMouseDown(event);
  };

  handleVersionClick = (versionKey) => {

    return () => {
      const { versions } = this.props;
      const selectedVersion = versions[versionKey];

      if (selectedVersion) {
        this.props.onVersionClick(selectedVersion);//handle the action
      }
    };
  };

  getImage = () => {
    const { image, hiresNavigatorImage, viewType } = this.props;

    return viewType === VIEW_TYPE_HIRES_VIEW ? hiresNavigatorImage : image;
  };

  getCanvasStyle = () => {
    const { isNavigatorMouseDown } = this.props;

    let cursor = 'default';
    if (this.getImage()) {
      cursor = isNavigatorMouseDown ? 'grabbing' : 'grab';
    }

    return {
      width: CANVAS_NAV_WIDTH,
      height: CANVAS_NAV_HEIGHT,
      marginTop: '2px',
      cursor
    };
  };

  renderImage = () => {
    const { imagePoint } = this.props;

    const image = this.getImage();
    if (!image) {
      return;
    }

    return <CanvasImage image={image} point={imagePoint} />;
  };

  getNavZoom = () => {
    const { image } = this.props;
    const navZoom = this.getNavFitToContentZoom2(image);

    return navZoom;
  };

  getViewportPointsRelativeToNavImage = () => {
    let result;

    const { hiresNavigatorImage, viewportPoints, viewType } = this.props;

    if (viewType === VIEW_TYPE_HIRES_VIEW && hiresNavigatorImage) {

      const percentageViewportPoints = Object.keys(viewportPoints).reduce((acc, viewportPointKey) => {
        const p = viewportPoints[viewportPointKey];
        const { imageWidth, imageHeight } = viewportPoints;
        if (!isNaN(p?.x) && !isNaN(p?.y) && !isNaN(imageWidth) && !isNaN(imageHeight)) {
          acc[viewportPointKey] = {
            x: (p.x * 100) / imageWidth,
            y: (p.y * 100) / imageHeight
          };
        } else {
          acc[viewportPointKey] = p;
        }

        return acc;

      }, {});

      const viewportPointsRelativeToNavImage = Object.keys(percentageViewportPoints).reduce((acc, viewportPointKey) => {
        const p = percentageViewportPoints[viewportPointKey];
        const { width, height } = hiresNavigatorImage;

        if (!isNaN(p?.x) && !isNaN(p?.y) && !isNaN(width) && !isNaN(height)) {
          acc[viewportPointKey] = {
            x: (p.x * width) / 100,
            y: (p.y * height) / 100
          };
        } else {
          acc[viewportPointKey] = p;
        }

        return acc;
      }, {});

      viewportPointsRelativeToNavImage.viewportWidth = viewportPointsRelativeToNavImage.bottomRight?.x - viewportPointsRelativeToNavImage.topLeft?.x;
      viewportPointsRelativeToNavImage.viewportHeight = viewportPointsRelativeToNavImage.bottomRight?.y - viewportPointsRelativeToNavImage.topLeft?.y;

      result = viewportPointsRelativeToNavImage;

    } else {
      result = viewportPoints;
    }

    return result;
  };

  renderViewport2 = () => {
    const { image, hiresNavigatorImage, viewType } = this.props;
    if (!image || viewType === VIEW_TYPE_HIRES_VIEW && !hiresNavigatorImage) {
      return;
    }

    const navZoom = viewType === VIEW_TYPE_HIRES_VIEW ? this.getNavFitToContentZoom2(hiresNavigatorImage) : this.getNavFitToContentZoom2(image);
    const key = Array.isArray(image.imageTiles) ? image.imageTiles.length : undefined;
    const { topLeft, viewportWidth, viewportHeight } = this.getViewportPointsRelativeToNavImage();

    if (topLeft) {
      return (
        <Rect
          key={key}
          strokeStyle='red'
          lineWidth={1 / navZoom}
          point={topLeft}
          width={viewportWidth}
          height={viewportHeight}
        />
      );
    }
  };

  getNavFitToContentZoom2 = (image) => {
    const { rotation, mainCanvasInstance } = this.props;

    if (!image || !mainCanvasInstance) {
      return 1;
    }

    const canvasNavHalfWidth = CANVAS_NAV_WIDTH / 2;
    const canvasNavHalfHeight = CANVAS_NAV_HEIGHT / 2;
    const imageHalfWidth = image.width / 2;
    const imageHalfHeight = image.height / 2;
    const canvasNavCenterPoint = {
      x: canvasNavHalfWidth,
      y: canvasNavHalfHeight
    };

    const pointA = getPointByRotation({
      x: canvasNavHalfWidth - imageHalfWidth,
      y: canvasNavHalfHeight - imageHalfHeight
    }, canvasNavCenterPoint, rotation);
    const pointB = getPointByRotation({
      x: canvasNavHalfWidth + imageHalfWidth,
      y: canvasNavHalfHeight - imageHalfHeight
    }, canvasNavCenterPoint, rotation);
    const pointC = getPointByRotation({
      x: canvasNavHalfWidth + imageHalfWidth,
      y: canvasNavHalfHeight + imageHalfHeight
    }, canvasNavCenterPoint, rotation);
    const pointD = getPointByRotation({
      x: canvasNavHalfWidth - imageHalfWidth,
      y: canvasNavHalfHeight + imageHalfHeight
    }, canvasNavCenterPoint, rotation);

    return Math.min(
      CANVAS_NAV_WIDTH / (Math.max(pointA.x, pointB.x, pointC.x, pointD.x) - Math.min(pointA.x, pointB.x, pointC.x, pointD.x)),
      CANVAS_NAV_HEIGHT / (Math.max(pointA.y, pointB.y, pointC.y, pointD.y) - Math.min(pointA.y, pointB.y, pointC.y, pointD.y))
    );
  };

  handleChangeBoxSelected = (boxIndex, overlayBoxesName) => (event, checked) => {
    const { onChangeBoxesSelected } = this.props;
    onChangeBoxesSelected(boxIndex, overlayBoxesName, checked);
  };

  renderOverlay = (box, index, overlayBoxesName) => {
    return <div key={`${box.label} ${index}`} className='crtx-overlay'>
      <div className='crtx-overlay-checkBox-container'>
        <Checkbox
          checked={box.isSelected} title={box.label}
          onChange={this.handleChangeBoxSelected(index, overlayBoxesName)}
        />
      </div>
      <div className='crtx-overlay-data-container'>

        <div className='crtx-overlay-data-full-row'>
          <div className='crtx-overlay-data-label'>{box.label}</div>
          <div className='crtx-overlay-data-input'>
            <hr className='crtx-overlay-data-input-hr-line' style={{ border: `1px solid ${box.color}` }} />
          </div>
        </div>

        <div className='crtx-overlay-data-label'>X:</div>
        <div className='crtx-overlay-data-input'>{box.legendData.x}</div>

        <div className='crtx-overlay-data-label'>Y:</div>
        <div className='crtx-overlay-data-input'>{box.legendData.y}</div>

        <div className='crtx-overlay-data-label'>W:</div>
        <div className='crtx-overlay-data-input'>{box.legendData.width}</div>

        <div className='crtx-overlay-data-label'>H:</div>
        <div className='crtx-overlay-data-input'>{box.legendData.height}</div>

        <div className='crtx-overlay-data-full-row'>
          <div className='crtx-overlay-data-label'>{labels.referencePoint}</div>
          <div className='crtx-overlay-data-input'>{translate(box.referencePoint)}</div>
        </div>
      </div>

    </div>;


  };

  //this renders the data to go into the navigator box
  renderOverlays = () => {
    const { overlayBoxes, showMediaBoxes, showPDFBoxes } = this.props;
    let mediaOverlayBoxes = [];
    let pdfOverlayBoxes = [];

    if (overlayBoxes?.mediaOverlayBoxes && showMediaBoxes) {
      mediaOverlayBoxes = overlayBoxes.mediaOverlayBoxes.map((box, index) => this.renderOverlay(box, index, 'mediaOverlayBoxes'));
    }

    if (overlayBoxes?.pdfOverlayBoxes && showPDFBoxes) {
      pdfOverlayBoxes = overlayBoxes.pdfOverlayBoxes.map((box, index) => this.renderOverlay(box, index, 'pdfOverlayBoxes'));
    }

    return mediaOverlayBoxes.concat(pdfOverlayBoxes);
  };

  handleChangeGuidelineSelected = guidelineName => (event, checked) => {
    const { onChangeGuidelineSelected } = this.props;
    onChangeGuidelineSelected(guidelineName, checked);
  };

  renderGuidelineData = (guideline, index) => {
    const guidelineDataObj = {};
    if (guideline.orientation === 'vertical') {
      guidelineDataObj.positionLabel = translate('X:');
      guidelineDataObj.position = guideline.displayPosition;
      guidelineDataObj.referenceSide = guideline.referenceX;
    } else {
      guidelineDataObj.positionLabel = translate('Y:');
      guidelineDataObj.position = guideline.displayPosition;
      guidelineDataObj.referenceSide = guideline.referenceY;
    }
    return <div key={`${guideline.name} ${index}`} className='crtx-overlay'>
      <div className='crtx-overlay-checkBox-container'>
        <Checkbox
          checked={guideline.isSelected}
          title={guideline.name}
          onChange={this.handleChangeGuidelineSelected(guideline.name)}
        />
      </div>
      <div className='crtx-overlay-data-container'>
        <div className='crtx-overlay-data-full-row'>
          <div className='crtx-overlay-data-label'>{guideline.name}</div>
          <div className='crtx-overlay-data-input'>
            <hr className='crtx-overlay-data-input-hr-line' style={{ border: `1px solid ${guideline.color}` }} />
          </div>
        </div>

        <div className='crtx-overlay-data-full-row'>
          <div className='crtx-overlay-data-label'>{guidelineDataObj.positionLabel}</div>
          <div className='crtx-overlay-data-input'>{guidelineDataObj.position} {`(${guidelineDataObj.referenceSide})`}</div>
        </div>

        {/*<div className='crtx-overlay-data-full-row'>*/}
        {/*  <div className='crtx-overlay-data-label'>{labels.referenceSide}</div>*/}
        {/*  <div className='crtx-overlay-data-input'>{guidelineDataObj.referenceSide}</div>*/}
        {/*</div>*/}
      </div>
    </div>;
  };

  renderGuidelinesData = () => {
    const { guidelines, showGuidelines } = this.props;
    let guidelinesDataArr = [];

    if (guidelines && showGuidelines) {
      guidelinesDataArr = guidelines.map((guideline, index) => this.renderGuidelineData(guideline, index));
    }

    return guidelinesDataArr;
  };

  handleGuideVisible = (guideId, visible) => {
    const { onGuideVisible } = this.props;

    onGuideVisible && onGuideVisible(guideId, visible);
  };

  handleRemoveGuide = (guideId) => {
    const { onRemoveGuide } = this.props;

    onRemoveGuide && onRemoveGuide(guideId);
  };

  renderGuideData = (guide, index) => {
    const guideName = translate('Guide {1}', index + 1);
    return <div key={guide.id} className='crtx-overlay'>
      <div className='crtx-overlay-checkBox-container'>
        <Checkbox
          checked={guide.visible}
          onChange={(e, visible) => this.handleGuideVisible(guide.id, visible)}
        />
      </div>
      <div className='crtx-overlay-data-container'>
        <div className='crtx-overlay-data-full-row'>
          <div className='crtx-overlay-data-label'>{guideName}</div>
          <div className='crtx-overlay-right-button'>
            <IconButton
              iconName={'close'}
              className={'crtx-size-14'}
              tooltip={translate('Remove guide')}
              onClick={() => this.handleRemoveGuide(guide.id)}
            />
          </div>
        </div>
      </div>
    </div>;
  };

  renderGuidesData = () => {
    const { guides } = this.props;

    return guides.map(this.renderGuideData);
  };

  render() {
    const {
      image,
      resolution,
      rotation,
      flipHorizontal,
      flipVertical,
      isDistanceTool,
      isDensityTool,
      showGuidelines,
      guides,
      distanceToolPoints,
      densityData,
      measurementUnit,
      mainCanvasInstance,
      versions,
      showMediaBoxes,
      showPDFBoxes,
      showVersions,
      selectedVersionNumber,
      activeVersionNumber,
      compareVersionsNumbersSelected,
      viewType,
      hiresNavigatorImage,
      rulerMousePoint,
      rulerVisible,
      loading,
      onChangeMeasurementUnit,
      onChangeDensityAreaSize
    } = this.props;

    if (!mainCanvasInstance) {
      return <div>{translate('Loading...')}</div>;
    }

    const { measurementData } = distanceToolPoints || {};

    return <div className="crtx-new-page-view-navigation-panel">
      <div className="crtx-content-accordion">
        <div className='accordion'>
          <MeasurementsUnit
            image={image}
            resolution={resolution}
            measurementUnit={measurementUnit}
            rulerMousePoint={rulerMousePoint}
            rulerVisible={rulerVisible}
            onChangeMeasurementUnit={onChangeMeasurementUnit}
          />
          <AccordionItem title={labels.navigator} className='show'>
            <div>
              <Canvas
                width={CANVAS_NAV_WIDTH}
                height={CANVAS_NAV_HEIGHT}
                style={this.getCanvasStyle()}
                onMouseDown={this.handleNavCanvasMouseDown}
              >
                <Stage
                  point={{ x: 0, y: 0 }}
                  rotation={rotation}
                  flipHorizontal={flipHorizontal}
                  flipVertical={flipVertical}
                  zoom={viewType === VIEW_TYPE_HIRES_VIEW ? this.getNavFitToContentZoom2(hiresNavigatorImage) : this.getNavFitToContentZoom2(image)}
                >
                  {!loading && this.renderImage()}
                  {!loading && this.renderViewport2()}
                </Stage>
              </Canvas>
            </div>
          </AccordionItem>
          {isDistanceTool && measurementData &&
            <AccordionItem title={labels.distance}>
              <div className='distance-panel'>
                <div>X<sub>1</sub>:</div>
                <div>{measurementData.x1 ?? ''}</div>
                <div>X<sub>2</sub>:</div>
                <div>{measurementData.x2 ?? ''}</div>
                <div>Y<sub>1</sub>:</div>
                <div>{measurementData.y1 ?? ''}</div>
                <div>Y<sub>2</sub>:</div>
                <div>{measurementData.y2 ?? ''}</div>
                <div className='row-3'>W:</div>
                <div className='row-3'>{measurementData.w ?? ''}</div>
                <div className='row-3'>D:</div>
                <div className='row-3'>{measurementData.d ?? ''}</div>
                <div>H:</div>
                <div>{measurementData.h ?? ''}</div>
              </div>
            </AccordionItem>
          }
          {isDensityTool && densityData &&
            <AccordionItem title={labels.density}>
              <div className='ink-coverage-panel'>
                <div className='left'>
                  {densityData.colors.map(c => {
                    return (
                      <Fragment key={c.name}>
                        <div className='color-code' title={c.name}>{c.code}:</div>
                        <div>{c.density}%</div>
                      </Fragment>);
                  })}
                  <div className='color-code' title={labels.total}>{labels.total}</div>
                  <div>{densityData.totalDensity}%</div>
                </div>
                <div className='right'>
                  <div>W x H:</div>
                  <Dropdown
                    className={classNames('density-area', { disabled: !densityData.point })}
                    options={DENSITY_AREA_OPTIONS}
                    value={densityData.areaSize}
                    onSelect={(e, value) => onChangeDensityAreaSize(value)}
                  />
                  <div>X:</div>
                  <div>{densityData.point?.x}</div>
                  <div>Y:</div>
                  <div>{densityData.point?.y}</div>
                </div>
              </div>
            </AccordionItem>
          }
          <AccordionItem
            title={labels.legend}
            className={(showMediaBoxes || showPDFBoxes || showGuidelines || guides.length > 0) ? 'show' : 'hide'}
          >
            <div
              className='crtx-legend'
              style={{ height: '200px' }}
            >
              {this.renderGuidesData()}
              {showGuidelines && this.renderGuidelinesData()}
              {(showMediaBoxes || showPDFBoxes) && this.renderOverlays()}
            </div>
          </AccordionItem>
          <AccordionItem
            title={labels.versions}
            className={`accordion-item-versions-container ${(showVersions) ? 'show' : 'hide'}`}
          >
            <div className='crtx-versions'>
              {
                Object.keys(versions).map((versionKey, index) => {
                  const isSelected = viewType === VIEW_TYPE_COMPARE ? compareVersionsNumbersSelected.findIndex(compareVersion => compareVersion === versions[versionKey].key) >= 0 : versions[versionKey].key === selectedVersionNumber;
                  const isActive = versions[versionKey].key === activeVersionNumber;
                  const formattedDate = localization.toLocaleShortDateTime(fromServerDate(versions[versionKey].versionData.time), true);

                  return <div
                    className={`crtx-version-row ${isSelected ? 'selected' : ''}`}
                    key={index}
                    onClick={this.handleVersionClick(versionKey)}
                  >
                    <div
                      className='crtx-version-row-external-version'>{versions[versionKey].versionData.externalVersion}
                    </div>
                    <div className='crtx-version-row-page-image-container'>
                      <img className='crtx-version-row-page-image-tag' src={versions[versionKey].iconURL} />
                    </div>
                    <div className='crtx-version-row-verdion-data'>
                      <span title={`${labels.input}:`}>{`${labels.input}:`}</span>
                      <span title={formattedDate}>{formattedDate}</span>
                      <span title={versions[versionKey].versionData.inputFileName}>
                        {versions[versionKey].versionData.inputFileName}
                      </span>
                    </div>
                    {
                      isActive ?
                        <div className='crtx-version-row-active-icon'><img src={activeVersionIcon} /></div> : undefined
                    }
                  </div>;
                })
              }
            </div>
          </AccordionItem>
        </div>
      </div>
    </div>;
  }
}