import React from 'react';

export default class extends React.Component {
  static defaultProps = {
    className: ''
  };

  render() {
    const { children, style, className } = this.props;
    const cssClass = `Text ${className || ''}`;

    return (
      <div title={children} className={cssClass} style={style}>
        {children}
      </div>
    );
  }
}