/**
 * @name textBurn
 * @file Defines textBurn element functionality
 *
 * @author Boris
 * @since: 2016-08-04
 */

import React from 'react';
import sandbox from 'sandbox';
import ReactComponents from 'widgets/ReactComponents/src/index';
import SimpleForm from 'widgets/SimpleForm/src/index';
import utils from '../utils/utils';
import canvasUtils from '../utils/canvasUtils';
import propertiesCommon from './propertiesCommon';
import TextBurnFabric from './textBurnFabric';
import customConditions from "./customConditions";

const { translate } = sandbox.localization;
const futils = SimpleForm.utils;

const BASE_PATH = 'layout.textBurns';
const TITLE = translate('Text Burn');

const MIN_TEXT_WIDTH = 5;
const MIN_TEXT_HEIGHT = 5;

const MIN_FONT_SIZE = 6;
const MAX_FONT_SIZE = 96;

const XSMALL_BUTTON_CLASS = 'btn btn-default btn-xs';

const { Button } = ReactComponents;
const { Row, Label, Input, Textarea, Dropdown, Panel } = SimpleForm;

const { DISPLAY_NAME, X, Y, _CORNER, ROTATION, BLACK_ONLY, WHITE_TEXT } = propertiesCommon;

const APPLY_ON = {
  ...propertiesCommon.APPLY_ON, options: [
    { value: 'process', text: translate('Process') },
    { value: 'output', text: translate('Output') },
    { value: 'newsdrive', text: translate('NewsDrive') }
  ]
};

const ALIGNMENT_X = {
  key: 'alignmentX',
  caption: 'Align',
  type: 'options',
  options: [
    { value: 'left', text: translate('left') },
    { value: 'center', text: translate('center') },
    { value: 'right', text: translate('right') }
  ]
};

const TRACKING_BARCODE = {
  key: 'isTrackingBarcode',
  caption: translate('Tracking barcode'),
  type: 'bool'
};

const OVER_WHITE_AREA = {
  key: 'overWhiteArea',
  caption: translate('Over white area (PDF only)'),
  type: 'bool',
  defaultValue: false
};

const TEXT_BURN_META = [
  DISPLAY_NAME,
  X,
  Y,
  _CORNER,
  ALIGNMENT_X,
  ROTATION,
  TRACKING_BARCODE,
  OVER_WHITE_AREA,
  APPLY_ON,
  BLACK_ONLY,
  WHITE_TEXT
];

export default (editor) => {

  const canvas = editor.getMainCanvas();
  const cutils = canvasUtils(editor);

  const getFontOptions = () => {
    var fonts = editor.getAvailableFonts();
    var result = fonts.map(font => ({ value: font.name, text: font.name }));

    return result;
  };

  const getFontSizeOptions = () => {
    var result = [];
    for (var fontSize = MIN_FONT_SIZE; fontSize <= MAX_FONT_SIZE; fontSize += (fontSize < 36 ? 1 : 4)) {
      var option = {value: fontSize, text: fontSize + 'pt'};
      result.push(option);
    }

    return result;
  };

  const fontOptions = getFontOptions();
  const fontSizeOptions = getFontSizeOptions();

  const getMeta = (state, element) => {
    return TEXT_BURN_META;
  };

  const buildDefaultElement = (state, elementType, optionId) => {
    const element = {
      elementType: elementType,
      locked: false,
      _selectable: true
    };

    setDefaultElementValues(state, element);

    return element;
  };

  const buildDefaultSegment = () => {
    return {
      fontName: 'Arial',
      fontFamily: 'Arial',
      fontPsName: 'Arial',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 20,
      sample: 'Abc',
      text: ''
    };
  };

  const setDefaultElementValues = (state, element) => {
    propertiesCommon.setDefaultValues(state, element, getMeta(state, element));

    element.segments = utils.fromPseudoArray(element.segments) || [];
    if (element.segments.length <= 0) {
      element.segments = [buildDefaultSegment()];
    }

    element.conditions = utils.fromPseudoArrayDeep(element.conditions) || [];
  };

  const updateFontProperties = (segment, fontName) => {
    var newSegment = segment;
    var font = editor.findFontByName(fontName);
    if (font) {
      newSegment = {
        ...segment,
        fontName: fontName,
        fontFamily: font.family,
        fontPsName: font.psName,
        fontWeight: font.weight,
        fontStyle: font.style,
      };
    }

    return newSegment;
  };

  const updateProperty = (state, element, elementPath, propertyPath, propertyValue) => {
    var newState = state;
    var newElement = element;

    var key = futils.pathTail(propertyPath);
    if (key === 'fontName') {
      var segmentPath = futils.pathNoTail(propertyPath);
      var segment = futils.get(newState, segmentPath);
      var newSegment = updateFontProperties(segment, propertyValue);
      newState = futils.update(newState, segmentPath, newSegment);
    } else {
      newState = propertiesCommon.updateProperty(newState, newElement, elementPath, propertyPath, propertyValue);
    }

    newElement = futils.get(newState, elementPath);

    updateShape(newState, newElement);

    return newState;
  };

  const shapeTransforming = (state, element) => {
    var text = element.shape.text;
    utils.applyConstraints(text, state.plateRectangle);
    var r = utils.toSystemRectangle(state.plateRectangle, text.left, text.top, text.width, text.height,
      element.referenceX, element.referenceY);
    var newElement = { ...element, x: r.x, y: r.y };

    updateShapeLeftTop(state, newElement);

    return newElement;
  };

  const createText = (element) => {
    const selectable = element._selectable;
    const text = new TextBurnFabric({
      segments: element.segments,
      hasRotatingPoint: false,
      centeredRotation: false,
      selectable: selectable,
      evented: selectable,
      hasControls: false,
      lockScalingX: true,
      lockScalingY: true,
      whiteText: element.whiteText,
    });


    return text;
  };

  const calcTextWidth = (segments) => {
    var ctx = canvas.getElement().getContext('2d');
    var width = segments.reduce((sum, seg) => {
      ctx.font = utils.composeCanvasFont(seg.fontStyle, seg.fontWeight, seg.fontSize, seg.fontFamily);
      return sum + ctx.measureText(seg.sample).width;
    }, 0);

    return width || 0;
  };

  const calcTextHeight = (segments) => {
    var height = segments.reduce((max, seg) => {
      return Math.max(max, parseFloat(utils.systemToCanvasFontSize(seg.fontSize)));
    }, 0);

    return height || 0;
  };

  const updateText = (state, element) => {
    const locked = element.locked || !element._selectable;
    let width = calcTextWidth(element.segments);
    width = Math.max(width, MIN_TEXT_WIDTH);
    let height = calcTextHeight(element.segments);
    height = Math.max(height, MIN_TEXT_HEIGHT);

    let text = element.shape.text;
    text.set({
      width: width,
      height: height,
      originX: element.alignmentX,
      originY: 'bottom',
      angle: element.rotation,
      whiteText: element.whiteText,
      segments: element.segments,
      lockMovementX: locked,
      lockMovementY: locked,
      hoverCursor: locked ? 'default' : 'move'
    });

    updateTextLeftTop(state, element);
  };

  const updateTextLeftTop = (state, element) => {
    var text = element.shape.text;
    var p = utils.toCanvasLeftTop(state.plateRectangle, element.x, element.y, element.referenceX, element.referenceY);
    text.set({
      left: p.left,
      top: p.top
    });

    text.setCoords();
  };

  const createShape = (state, element, elementPath) => {
    var text = createText(element);

    var shape = {
      elementPath,
      text
    };

    text.shape = shape;
    element.shape = shape;

    updateShape(state, element);

    addShape(state, element);

    return shape;
  };

  const addShape = (state, element) => {
    if (!element || !element.shape) {
      return;
    }

    canvas.add(element.shape.text);
  };

  const removeShape = (state, element) => {
    if (!element || !element.shape) {
      return;
    }

    var shape = element.shape;
    canvas.remove(shape.text);
  };


  const updateShape = (state, element) => {
    updateText(state, element);
  };

  const updateShapeLeftTop = (state, element) => {
    updateTextLeftTop(state, element);
  };

  const activateShape = (state, element) => {
    cutils.setActiveObject(element.shape.text);
  };

  const nudgeShape = (state, element, direction) => {
    let newElement = element;
    if (!element.locked) {
      newElement = propertiesCommon.nudgeElement(state, element, direction);
      updateShape(state, newElement);
    }

    return newElement;
  };

  const handleAddSegmentButtonClick = (store, segmentPath) => {
    //console.log("handleAddSegmentButtonClick() segmentPath=", segmentPath);
    var path = futils.pathNoTail(segmentPath);
    var index = Number(futils.pathTail(segmentPath)) + 1;
    var segment = futils.get(store.getState(), segmentPath);
    var newSegment = { ...segment, sample: '', text: '' };

    store.dispatch({
      type: 'ADD',
      path: path,
      index: index,
      value: newSegment
    });
  };

  const handleDeleteSegmentButtonClick = (store, segmentPath) => {
    //console.log("handleDeleteSegmentButtonClick() segmentPath=", segmentPath);
    var path = futils.pathNoTail(segmentPath);
    var index = Number(futils.pathTail(segmentPath));

    store.dispatch({
      type: 'REMOVE',
      path: path,
      index: index
    });
  };

  const renderSegment = (store, element, elementPath, index, segments) => {
    var disabled = element.locked;
    var segmentPath = futils.compose(elementPath, 'segments', index);
    var count = segments.length;

    return (
      <Panel key={index} col='12'>
        <Row>
          <Label col={'2'}>{'Font'}</Label>
          <Dropdown col={'6'} disabled={disabled} bind={futils.compose(segmentPath, 'fontName')} options={fontOptions} />
          <Dropdown col={'2'} disabled={disabled} bind={futils.compose(segmentPath, 'fontSize')} options={fontSizeOptions} />
          <Button col={'1'}
                  disabled={disabled}
                  text={''}
                  className={XSMALL_BUTTON_CLASS}
                  iconName={'add'}
                  tooltip={'Add'}
                  onClick={() => handleAddSegmentButtonClick(store, segmentPath)}
          />
          <Button col={'1'}
                  disabled={disabled || count <= 1}
                  text={''}
                  className={XSMALL_BUTTON_CLASS}
                  iconName={'close'}
                  tooltip={'Delete'}
                  onClick={() => handleDeleteSegmentButtonClick(store, segmentPath)}
          />
        </Row>
        <Row>
          <Label col={'2'}>{'Sample'}</Label>
          <Input col={'10'} disabled={disabled} bind={futils.compose(segmentPath, 'sample')} />
        </Row>
        <Row>
          <Label col={'2'}>{'Text'}</Label>
          <Textarea col={'10'} disabled={disabled} ignoreNewLine={true} bind={futils.compose(segmentPath, 'text')} />
        </Row>
      </Panel>
    );
  };

  const renderSegments = (store, element, elementPath) => {
    return (
      <div>
        <Row>
          <Label offset={'4'} col={'4'}>{'Segments'}</Label>
        </Row>
        {element.segments.map((seg, index, segments) => renderSegment(store, element, elementPath, index, segments))}
      </div>
    );
  };

  const renderProperties = (store, element, elementPath) => {
    const state = store.getState();
    const meta = getMeta(state, element);

    return (
      <div>
        {propertiesCommon.renderProperties(store, element, elementPath, meta)}
        {customConditions.render(store, element, elementPath)}
        {renderSegments(store, element, elementPath)}
      </div>
    );
  };

  const getInfo = (state) => {
    return {
      basePath: BASE_PATH,
      title: TITLE
    };
  };

  const getAlignmentInfo = (state, element) => {
    return utils.getAlignmentInfo(element.shape.text);
  };

  return {
    buildDefaultElement,
    setDefaultElementValues,
    updateProperty,
    shapeTransforming,
    createShape,
    updateShape,
    addShape,
    removeShape,
    activateShape,
    nudgeShape,
    renderProperties,
    getInfo,
    getAlignmentInfo
  }

};

