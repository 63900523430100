/* @flow weak */

import ReactDataGrid from 'widgets/ReactDataGrid/src/ReactDataGrid';
import labels from './labels';
import FlowstepsBrowserInput from 'components/private/inputs/FlowstepsBrowserInput';
import sandbox, { appUtils, localization } from 'sandbox';
import settingsManager from 'core/managers/settings';
import { createObjectComparator } from 'core/comparators';

const { translate } = localization;

const LIST_DELIMITER = ',';

const {
  Text,
  TextInputCell,
  CheckboxCell,
  DropdownCell,
  MultiSelectDropdownCell,
  LengthInputCell,
  PercentInputCell,
  ToleranceCell,
  DurationCell,
  DayTimeOffsetCell,
  DatePickerCell,
  DegreesInputCell
} = ReactDataGrid.cells;

const dayOptions = [
  { text: labels.everyday, value: '*' },
  { text: labels.sunday, value: 'Sun' },
  { text: labels.monday, value: 'Mon' },
  { text: labels.tuesday, value: 'Tue' },
  { text: labels.wednesday, value: 'Wed' },
  { text: labels.thursday, value: 'Thu' },
  { text: labels.friday, value: 'Fri' },
  { text: labels.saturday, value: 'Sat' }
];

let pageSizeOptions = [
  { name: labels.broadsheet, value: 'broadsheet' },
  { name: labels.tabloid, value: 'tabloid' }
];

const notFreeTextFieldType = [
  'flowstep',
  'flowsteps',
  'resources',
  'publication',
  'edition',
  'zone',
  'section',
  'broadsheet',
  'tabs',
  '2ups',
  '4ups',
  '8ups',
  'customLayoutGroup',
  'siteName',
  'siteCode',
  'siteListNames',
  'siteListCodes',
  'securityGroup',
  'allLayoutGroups',
];

const isUndefined = o => {
  return typeof o === 'undefined';
};

const getWidth = (state, columnName) => {
  const userPreferences = state.userPreferences;
  const currentTableIndex = state.currentTableIndex;
  const currentTableName = state.tables[currentTableIndex].name;
  return userPreferences.tables &&
    userPreferences.tables[currentTableName] &&
    !isUndefined(userPreferences.tables[currentTableName].columns[columnName])
    ? userPreferences.tables[currentTableName].columns[columnName].width
    : 140;
};

function dayCell(
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object
): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const width = getWidth(state, column.name);

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: isUndefined(width),
    resizable: true,
    width: !isUndefined(width) ? width : 140,
    cell: DropdownCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData: rows[rowIndex][columnType][columnKey],
        options: dayOptions,
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.rows', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val
          });
        }
      };
    }
  };
}

function dropdown(
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object
): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const width = getWidth(state, column.name);

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: isUndefined(width),
    resizable: true,
    width: !isUndefined(width) ? width : 140,
    cell: DropdownCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData: rows[rowIndex][columnType][columnKey],
        options: column.values || [],
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.rows', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val
          });
        }
      };
    }
  };
}

function multiselect(
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object
): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const width = getWidth(state, column.name);

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: isUndefined(width),
    resizable: true,
    width: !isUndefined(width) ? width : 140,
    cell: MultiSelectDropdownCell,
    props: (bind, rowIndex, columnKey) => {
      const columnData = rows[rowIndex][columnType][columnKey] || '';

      return {
        columnData: columnData ? columnData.split(LIST_DELIMITER) : [],
        options: column.values || [],
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.rows', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val.join(LIST_DELIMITER)
          });
        }
      };
    }
  };
}

function checkboxCell(
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object
): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const width = getWidth(state, column.name);

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: isUndefined(width),
    resizable: true,
    width: !isUndefined(width) ? width : 140,
    cell: CheckboxCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        align: 'center',
        columnData: rows[rowIndex][columnType][columnKey],
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.rows', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val.toString()
          });
        }
      };
    }
  };
}

function lengthInputCell(
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object
): Object {
  const editable = column.editable === false ? false : true;
  const unit = settingsManager.getLengthUnit();
  const state = store.getState();
  const width = getWidth(state, column.name);

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: isUndefined(width),
    resizable: true,
    width: !isUndefined(width) ? width : 140,
    cell: LengthInputCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData: rows[rowIndex][columnType][columnKey],
        unit: unit,
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.rows', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val
          });
        }
      };
    }
  };
}

function toleranceCell(
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object
): Object {
  const editable = column.editable === false ? false : true;
  const unit = settingsManager.getLengthUnit();
  const state = store.getState();
  const width = getWidth(state, column.name);

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: isUndefined(width),
    resizable: true,
    width: !isUndefined(width) ? width : 140,
    cell: ToleranceCell,
    props: (bind, rowIndex, columnKey) => {
      const arr = String(rows[rowIndex][columnType][columnKey] || ':').split(
        ':'
      );
      const columnData = {
        value: arr[0],
        tolerance: arr.length > 1 ? arr[1] : ''
      };

      return {
        columnData,
        unit: unit,
        disabled: !editable,
        onChange: (rowIndex, columnKey, data) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.rows', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: data.value + ':' + data.tolerance
          });
        }
      };
    }
  };
}

function percentInputCell(
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object
): Object {
  const editable = column.editable !== false;
  const state = store.getState();
  const width = getWidth(state, column.name);

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: isUndefined(width),
    resizable: true,
    width: !isUndefined(width) ? width : 140,
    cell: PercentInputCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData: rows[rowIndex][columnType][columnKey],
        noConversion: true,
        decimalPlaces: 4,
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.rows', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val
          });
        }
      };
    }
  };
}

function textCell(
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object
): Object {
  const editable = column.editable === false ? false : true;
  const cell = editable === false ? Text : TextInputCell;
  const textColor = editable === false ? '#999999' : undefined;
  const state = store.getState();
  const width = getWidth(state, column.name);

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: isUndefined(width),
    resizable: true,
    width: !isUndefined(width) ? width : 140,
    cell,
    props: (bind, rowIndex, columnKey) => {
      const column = store
        .get(bind.replace('.rows', `.${columnType}`))
        .find(column => column.name === columnKey);
      const columnMaxCharacters = isUndefined(column)
        ? undefined
        : column.maxCharacters;
      return {
        columnData: rows[rowIndex][columnType][columnKey],
        maxLength:
          columnMaxCharacters === '0' ||
            columnMaxCharacters === '' ||
            isUndefined(columnMaxCharacters)
            ? undefined
            : columnMaxCharacters,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.rows', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val
          });
        }
      };
    }
  };
}

function degreesInputCell(
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object
): Object {
  const editable = column.editable !== false;
  const state = store.getState();
  const width = getWidth(state, column.name);

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: isUndefined(width),
    resizable: true,
    width: !isUndefined(width) ? width : 140,
    cell: DegreesInputCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData: rows[rowIndex][columnType][columnKey],
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.rows', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val
          });
        }
      };
    }
  };
}

function publicationDropdown(
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object
): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const publications = state.publications;
  const width = getWidth(state, column.name);

  let valuesPublicationsOptions = publications.reject(function (publication) {
    return publication.value === '*';
  });
  valuesPublicationsOptions = [{ value: '', name: '---' }].concat(
    valuesPublicationsOptions
  );
  let publicationsOptions =
    columnType === 'selectors' ? publications : valuesPublicationsOptions;
  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: isUndefined(width),
    resizable: true,
    width: !isUndefined(width) ? width : 140,
    cell: DropdownCell,
    props: (bind, rowIndex, columnKey) => {
      const defaultColumnData = columnType === 'selectors' ? '*' : '';
      return {
        columnData:
          rows[rowIndex][columnType][columnKey] === ''
            ? defaultColumnData
            : rows[rowIndex][columnType][columnKey],
        textProp: 'name',
        valueProp: 'value',
        options: publicationsOptions || [],
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.rows', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val
          });
        }
      };
    }
  };
}

const getLayoutGroupOptionsByTypeAndPublication = (pubsMap, fieldType, selectedPublicationName) => {
  let options = [];

  if (fieldType === 'customLayoutGroup') {
    fieldType = 'custom';
  }
  if (pubsMap[selectedPublicationName] && pubsMap[selectedPublicationName][fieldType]) {
    options = pubsMap[selectedPublicationName][fieldType];
  }

  return options;
};

const getAllLayoutGroupOptionsByPublication = (allLayoutGroupOptionsByPublicationMap, selectedPublicationName) => {
  let options = [];

  if (allLayoutGroupOptionsByPublicationMap[selectedPublicationName]) {
    options = allLayoutGroupOptionsByPublicationMap[selectedPublicationName];
  }

  return options;
};


const getLayoutGroupOptionsByType = (layoutGroupOptions, fieldType) => {

  const allLayoutGroupsOptions = layoutGroupOptions[fieldType] || [];
  const allOption = { name: "All", value: "*" };
  const filteredAllLayoutGroupsOptions = [allOption, ...allLayoutGroupsOptions];
  return filteredAllLayoutGroupsOptions;
};

function layoutGroupDropdown(
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object,
  fieldType: string
): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const layoutGroups = state.layoutGroups;
  const pubsMap = state.pubsMap;
  const layoutGroupOptions = state.layoutGroupOptions;
  const allLayoutGroupOptions = state.allLayoutGroupOptions;
  const allLayoutGroupOptionsByPublicationMap = state.allLayoutGroupOptionsByPublicationMap;
  const width = getWidth(state, column.name);


  const getLayoutGroupsOptions = (rowIndex) => {

    const currentTableIndex = store.get('currentTableIndex');
    const currentTable = state.tables[currentTableIndex];
    const selectors = state.tables[currentTableIndex].selectors;
    const publicationSelector = selectors.filter(function (selector) {
      return selector.fieldType === 'publication';
    });

    let layoutGroupsOptions = [];
    let selectedPublicationName = '';

    if (publicationSelector.length > 0) {
      const publicationSelectorName = publicationSelector[0].name;
      selectedPublicationName =
        currentTable.rows[rowIndex].selectors[publicationSelectorName];
    }

    if (selectedPublicationName === '*' || !selectedPublicationName || !pubsMap[selectedPublicationName]) {
      if (fieldType === 'allLayoutGroups') {
        layoutGroupsOptions = allLayoutGroupOptions;
      } else {
        layoutGroupsOptions = getLayoutGroupOptionsByType(layoutGroupOptions, fieldType);
      }
    } else if (fieldType === 'allLayoutGroups') {
      layoutGroupsOptions = getAllLayoutGroupOptionsByPublication(allLayoutGroupOptionsByPublicationMap, selectedPublicationName);
    } else {

      layoutGroupsOptions = getLayoutGroupOptionsByTypeAndPublication(pubsMap, fieldType, selectedPublicationName);
    }

    if (columnType === 'values') {
      layoutGroupsOptions = layoutGroupsOptions.filter(layoutGroup => layoutGroup.value !== '*');
      layoutGroupsOptions = [{ value: '', name: '---' }].concat(layoutGroupsOptions);
    }

    return layoutGroupsOptions;
  };

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: isUndefined(width),
    resizable: true,
    width: !isUndefined(width) ? width : 140,
    cell: DropdownCell,
    props: (bind, rowIndex, columnKey) => {
      const defaultColumnData = columnType === 'selectors' ? '*' : '';
      return {
        columnData:
          rows[rowIndex][columnType][columnKey] === ''
            ? defaultColumnData
            : rows[rowIndex][columnType][columnKey],
        options: getLayoutGroupsOptions(rowIndex),
        textProp: 'name',
        valueProp: 'value',
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.rows', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val
          });
        },
        shouldCellUpdate: (nextProps, props) => {
          return (
            nextProps.options !== props.options ||
            nextProps.columnData !== props.columnData
          );
        },
      };
    }
  };
}

function productSetupDropdown(
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object
): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const publications = state.publications;
  const currentTableIndex = store.get('currentTableIndex');
  const currentTable = state.tables[currentTableIndex];
  const selectors = state.tables[currentTableIndex].selectors;

  const publicationSelector = selectors.filter(function (selector) {
    return selector.fieldType === 'publication';
  });

  const fieldTypes = {
    edition: 'editions',
    section: 'sections',
    zone: 'zones'
  };
  const columnFieldType = fieldTypes[column.fieldType];
  const width = getWidth(state, column.name);

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: isUndefined(width),
    resizable: true,
    width: !isUndefined(width) ? width : 140,
    cell: DropdownCell,
    props: (bind, rowIndex, columnKey) => {
      let options = [];
      let defaultOption = {};
      if (columnType === 'selectors') {
        if (currentTable.isPublicationTable) {
          let selectedPublication = publications.find(function (publication) {
            return publication.value === currentTable.publication;
          });
          selectedPublication = !isUndefined(selectedPublication)
            ? selectedPublication
            : publications.find(function (publication) {
              return publication.value === '*';
            });
          options = [{ value: '*', name: labels.all }].concat(
            selectedPublication[columnFieldType]
          );
          defaultOption = { value: '*', name: labels.all };
        } else if (publicationSelector.length > 0) {
          const publicationSelectorName = publicationSelector[0].name;
          const selectedPublicationName =
            currentTable.rows[rowIndex].selectors[publicationSelectorName];
          let selectedPublication = publications.find(function (publication) {
            return publication.value === selectedPublicationName;
          });
          selectedPublication = !isUndefined(selectedPublication)
            ? selectedPublication
            : publications.find(function (publication) {
              return publication.value === '*';
            });
          options = [{ value: '*', name: labels.all }].concat(
            selectedPublication[columnFieldType]
          );
          defaultOption = { value: '*', name: labels.all };
        } else {
          const selectedAllPublication = publications.find(function (
            publication
          ) {
            return publication.value === '*';
          });
          options = [{ value: '*', name: labels.all }].concat(
            selectedAllPublication[columnFieldType]
          );
          defaultOption = { value: '*', name: labels.all };
        }
      } else {
        const selectedAllPublication = publications.find(function (publication) {
          return publication.value === '*';
        });
        options = [{ value: '', name: '---' }].concat(
          selectedAllPublication[columnFieldType]
        );
        defaultOption = { value: '', name: '---' };
      }

      return {
        columnData:
          rows[rowIndex][columnType][columnKey] === ''
            ? defaultOption.value
            : rows[rowIndex][columnType][columnKey],
        textProp: 'name',
        valueProp: 'value',
        options: options || [],
        disabled: !editable,
        shouldCellUpdate: (nextProps, props) => {
          return (
            nextProps.options !== props.options ||
            nextProps.columnData !== props.columnData
          );
        },
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.rows', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val
          });
        }
      };
    }
  };
}

function pageSizeCell(
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object
): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const width = getWidth(state, column.name);

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: isUndefined(width),
    resizable: true,
    width: !isUndefined(width) ? width : 140,
    cell: DropdownCell,
    props: (bind, rowIndex, columnKey) => {
      const selectorsPageSizeOptions = [
        { value: '*', name: labels.all }
      ].concat(pageSizeOptions);
      const defaultColumnData = columnType === 'selectors' ? '*' : '';
      return {
        columnData:
          rows[rowIndex][columnType][columnKey] === ''
            ? defaultColumnData
            : rows[rowIndex][columnType][columnKey],
        options:
          columnType === 'selectors'
            ? selectorsPageSizeOptions
            : pageSizeOptions,
        disabled: !editable,
        textProp: 'name',
        valueProp: 'value',
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.rows', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val
          });
        }
      };
    }
  };
}

function siteNameDropdown(
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object,
  fieldType: string
): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const width = getWidth(state, column.name);
  const sites = state.sites;
  const filteredSiteName = sites.filter(site => {
    return !isUndefined(site.name);
  });
  let sitesOptions = filteredSiteName.map(site => {
    return {
      name: site.name,
      value: site.name
    };
  });
  sitesOptions = !isUndefined(sitesOptions) ? sitesOptions : [];

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: isUndefined(width),
    resizable: true,
    width: !isUndefined(width) ? width : 140,
    cell: DropdownCell,
    props: (bind, rowIndex, columnKey) => {
      const selectorsSiteNameOptions = [
        { value: '*', name: labels.all }
      ].concat(sitesOptions);
      const defaultColumnData = columnType === 'selectors' ? '*' : '';
      return {
        columnData:
          rows[rowIndex][columnType][columnKey] === ''
            ? defaultColumnData
            : rows[rowIndex][columnType][columnKey],
        options:
          columnType === 'selectors' ? selectorsSiteNameOptions : sitesOptions,
        textProp: 'name',
        valueProp: 'value',
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.rows', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val
          });
        }
      };
    }
  };
}

function siteCodeDropdown(
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object,
  fieldType: string
): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const width = getWidth(state, column.name);
  const sites = state.sites;
  const filteredSiteCode = sites.filter(site => {
    return !isUndefined(site.code);
  });
  let sitesOptions = filteredSiteCode.map(site => {
    return {
      name: site.code,
      value: site.code
    };
  });
  sitesOptions = !isUndefined(sitesOptions) ? sitesOptions : [];

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: isUndefined(width),
    resizable: true,
    width: !isUndefined(width) ? width : 140,
    cell: DropdownCell,
    props: (bind, rowIndex, columnKey) => {
      const selectorsSiteCodeOptions = [
        { value: '*', name: labels.all }
      ].concat(sitesOptions);
      const defaultColumnData = columnType === 'selectors' ? '*' : '';
      return {
        columnData:
          rows[rowIndex][columnType][columnKey] === ''
            ? defaultColumnData
            : rows[rowIndex][columnType][columnKey],
        options:
          columnType === 'selectors' ? selectorsSiteCodeOptions : sitesOptions,
        textProp: 'name',
        valueProp: 'value',
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.rows', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val
          });
        }
      };
    }
  };
}

function siteListNamesMultiselect(
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object
): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const width = getWidth(state, column.name);
  const sites = state.sites;
  const filteredSiteListName = sites.filter(site => {
    return !isUndefined(site.name);
  });
  const sitesListOptions = filteredSiteListName.map(site => {
    return {
      text: site.name,
      value: site.name
    };
  });
  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: isUndefined(width),
    resizable: true,
    width: !isUndefined(width) ? width : 140,
    cell: MultiSelectDropdownCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData: !isUndefined(rows[rowIndex][columnType][columnKey])
          ? rows[rowIndex][columnType][columnKey].split(LIST_DELIMITER)
          : [],
        options: sitesListOptions || [],
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.rows', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val.join(LIST_DELIMITER)
          });
        }
      };
    }
  };
}

function siteListCodesMultiselect(
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object
): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const width = getWidth(state, column.name);
  const sites = state.sites;
  const filteredSiteListCodes = sites.filter(site => {
    return !isUndefined(site.code);
  });
  const sitesListOptions = filteredSiteListCodes.map(site => {
    return {
      text: site.code,
      value: site.code
    };
  });
  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: isUndefined(width),
    resizable: true,
    width: !isUndefined(width) ? width : 140,
    cell: MultiSelectDropdownCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData: !isUndefined(rows[rowIndex][columnType][columnKey])
          ? rows[rowIndex][columnType][columnKey].split(LIST_DELIMITER)
          : [],
        options: sitesListOptions || [],
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.rows', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val.join(LIST_DELIMITER)
          });
        }
      };
    }
  };
}

function securityGroupsMultiselect(
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object
): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const width = getWidth(state, column.name);

  const securityGroups = state.folderSecurityGroups;
  const securityGroupsOptions = securityGroups.map(securityGroup => {
    return {
      text: securityGroup.name,
      value: securityGroup.nwid
    };
  });
  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: isUndefined(width),
    resizable: true,
    width: !isUndefined(width) ? width : 140,
    cell: MultiSelectDropdownCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData:
          !isUndefined(rows[rowIndex][columnType][columnKey]) &&
            rows[rowIndex][columnType][columnKey] !== ''
            ? rows[rowIndex][columnType][columnKey].split(LIST_DELIMITER)
            : [],
        options: securityGroupsOptions || [],
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.rows', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val.join(LIST_DELIMITER)
          });
        }
      };
    }
  };
}

function flowstepsBrowserInput(
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object,
  multiselect = false
): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const width = getWidth(state, column.name);

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: isUndefined(width),
    resizable: true,
    width: !isUndefined(width) ? width : 220,
    cell: FlowstepsBrowserInput,
    props: (bind, rowIndex, columnKey) => {
      const columnData = rows[rowIndex][columnType][columnKey] || '';
      const nwids = columnData ? columnData.split(';') : [];
      const flowstepsInfo = nwids.map(nwid => ({
        nwid,
        name: appUtils.composeFullName(state.flowStepsByNwid, nwid)
      }));

      return {
        columnData,
        moduleNwid: state.moduleNwid,
        multiselect,
        value: flowstepsInfo,
        disabled: !editable,
        className: 'flowsteps-browser-cell',
        onSelect: (event, selectedFlowstepsInfo) => {
          //console.log('### rowIndex, columnType, columnKey, bind', rowIndex, columnType, columnKey, bind);
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.rows', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: selectedFlowstepsInfo.map(fs => fs.nwid).join(';')
          });
        }
      };
    }
  };
}

function resourcesMultiselect(
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object
): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const width = getWidth(state, column.name);
  const resources = state.resources
    .filter(r => r.type === column.resourceType)
    .map(r => ({
      value: r.nwid,
      text: r.name
    }));

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: isUndefined(width),
    resizable: true,
    width: !isUndefined(width) ? width : 140,
    cell: MultiSelectDropdownCell,
    props: (bind, rowIndex, columnKey) => {
      let columnData = [];
      let selectedResources = rows[rowIndex][columnType][columnKey];
      if (selectedResources) {
        columnData = selectedResources
          .split(LIST_DELIMITER)
          .filter(nwid => resources.find(r => r.value === nwid));
      }

      return {
        columnData,
        options: resources || [],
        disabled: !editable,
        onChange: (rowIndex, columnKey, val) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.rows', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val.join(LIST_DELIMITER)
          });
        }
      };
    }
  };
}

function deadlineCell(
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object
): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const width = getWidth(state, column.name);

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: isUndefined(width),
    resizable: true,
    width: !isUndefined(width) ? width : 140,
    cell: DayTimeOffsetCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData:
          !isUndefined(rows[rowIndex][columnType][columnKey]) &&
            rows[rowIndex][columnType][columnKey] !== ''
            ? rows[rowIndex][columnType][columnKey]
            : {
              days: '0',
              hours: '00',
              minutes: '00'
            },
        disabled: !editable,
        style: { width: '100%', height: '100%' },
        timeTitle: translate('Production time'),
        offsetTitle: translate('Days offset'),
        timeIntervals: 15,
        min: { days: -365 },
        onChange: (rowIndex, columnKey, val, prop) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.rows', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val,
            prop
          });
        }
      };
    }
  };
}

function releaseOffsetCell(
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object
): Object {
  const editable = column.editable === false ? false : true;
  const state = store.getState();
  const width = getWidth(state, column.name);

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: isUndefined(width),
    resizable: true,
    width: !isUndefined(width) ? width : 140,
    cell: DurationCell,
    props: (bind, rowIndex, columnKey) => {
      return {
        columnData: !isUndefined(rows[rowIndex][columnType][columnKey])
          ? Number(rows[rowIndex][columnType][columnKey])
          : 0,
        disabled: !editable,
        options: ['hour', 'minute'],
        disabeldNegativePositive: true,
        minMaxOption: { hour: { min: 0 }, minute: { min: 0 } },
        onChange: (rowIndex, columnKey, val, prop) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.rows', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val,
            prop
          });
        }
      };
    }
  };
}

function dateCell(
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object
): Object {
  const editable = column.editable !== false;
  const state = store.getState();
  const width = getWidth(state, column.name);

  return {
    title: column.displayName,
    columnKey: column.name,
    sortable: false,
    autowidth: isUndefined(width),
    resizable: true,
    width: !isUndefined(width) ? width : 140,
    cell: DatePickerCell,
    props: (bind, rowIndex, columnKey) => {
      const columnData = rows[rowIndex][columnType][columnKey] || undefined;

      return {
        columnData,
        disabled: !editable,
        onChange: (rowIndex, columnKey, val, strValue) => {
          store.dispatch({
            type: 'UPDATE_CELL',
            tablePath: `${bind.replace('.rows', '')}`,
            path: `${bind}[${rowIndex}].${columnType}.${columnKey}`,
            value: val
          });
        }
      };
    }
  };
}

function cellsDefinition(
  fieldType: string,
  store: Object,
  rows: Array<Object>,
  columnType: string,
  column: Object
): Object {
  if (
    column.freeText === false &&
    Array.isArray(column.values) &&
    column.values.length > 0 &&
    fieldType !== 'multiselect' &&
    fieldType !== 'matchlist' &&
    fieldType !== 'siteListNames' &&
    fieldType !== 'siteListCodes'
  ) {
    return dropdown(store, rows, columnType, column);
  }

  if (
    !column.freeText &&
    notFreeTextFieldType.findIndex(item => {
      return item === fieldType;
    }) >= 0
  ) {
    switch (fieldType) {
      case 'publication':
        return publicationDropdown(store, rows, columnType, column);
      case 'edition':
        return productSetupDropdown(store, rows, columnType, column);
      case 'zone':
        return productSetupDropdown(store, rows, columnType, column);
      case 'section':
        return productSetupDropdown(store, rows, columnType, column);
      case 'broadsheet':
        return layoutGroupDropdown(store, rows, columnType, column, fieldType);
      case 'tabs':
        return layoutGroupDropdown(store, rows, columnType, column, fieldType);
      case '2ups':
        return layoutGroupDropdown(store, rows, columnType, column, fieldType);
      case '4ups':
        return layoutGroupDropdown(store, rows, columnType, column, fieldType);
      case '8ups':
        return layoutGroupDropdown(store, rows, columnType, column, fieldType);
      case 'customLayoutGroup':
        return layoutGroupDropdown(store, rows, columnType, column, fieldType);
      case 'allLayoutGroups':
        return layoutGroupDropdown(store, rows, columnType, column, fieldType);
      case 'siteName':
        return siteNameDropdown(store, rows, columnType, column, fieldType);
      case 'siteCode':
        return siteCodeDropdown(store, rows, columnType, column, fieldType);
      case 'siteListNames':
        return siteListNamesMultiselect(
          store,
          rows,
          columnType,
          column,
          fieldType
        );
      case 'siteListCodes':
        return siteListCodesMultiselect(
          store,
          rows,
          columnType,
          column,
          fieldType
        );
      case 'securityGroup':
        return securityGroupsMultiselect(
          store,
          rows,
          columnType,
          column,
          fieldType
        );
      case 'flowstep':
        return flowstepsBrowserInput(store, rows, columnType, column);
      case 'flowsteps':
        return flowstepsBrowserInput(store, rows, columnType, column, true);
      case 'resources':
        return resourcesMultiselect(store, rows, columnType, column);
      default:
        return textCell(store, rows, columnType, column);
    }
  }

  switch (fieldType) {
    case 'day':
      return dayCell(store, rows, columnType, column);
    case 'checkbox':
      return checkboxCell(store, rows, columnType, column);
    case 'measurement':
      return lengthInputCell(store, rows, columnType, column);
    case 'tolerance':
      return toleranceCell(store, rows, columnType, column);
    case 'percentage':
      return percentInputCell(store, rows, columnType, column);
    case 'multiselect':
    case 'matchlist':
      return multiselect(store, rows, columnType, column);
    case 'pageSize':
      return pageSizeCell(store, rows, columnType, column, fieldType);
    case 'deadline':
      return deadlineCell(store, rows, columnType, column, fieldType);
    case 'date':
      return dateCell(store, rows, columnType, column, fieldType);
    case 'releaseOffset':
      return releaseOffsetCell(store, rows, columnType, column, fieldType);
    case 'hold':
      return checkboxCell(store, rows, columnType, column);
    case 'degrees':
      return degreesInputCell(store, rows, columnType, column);
    default:
      return textCell(store, rows, columnType, column);
  }
}

module.exports = cellsDefinition;
