import React from 'react';
import colorService from 'core/services/colorService';
import iconService from 'core/services/iconService';
import SeparationVersion from './separationVersion';
import SeparationStatusIcon from './separationStatusIcon';
import SeparationStatusType from './separationStatusType';
import SeparationPlates from './separationPlates';
import SeparationPlatesProgress from './separationPlatesProgress';
import { getMainStatus } from 'utilities/statuses';

const DIFF_INVISIBLE_FLOW_STEPS = ['workflow/step/output/proout'];

export default class Separation extends React.Component {
  static defaultProps = {
    separation: undefined,
    definition: undefined
  };

  getSeparation = () => {
    return this.props.separation;
  };

  getDefinition = () => {
    return this.props.definition;
  };

  isLocal = (separation) => {
    return separation.local;
  };

  isLink = (separation) => {
    return !separation.local;
  };

  isPlanned = (separation) => {
    return separation.isPlanned;
  };

  isChase = (separation) => {
    return (separation.baseSeparation !== null);
  };

  getBaseSeparation = (separation) => {
    return separation.baseSeparation;
  };

  getSeparationProgress = (separation) => {
    const status = getMainStatus(separation);
    return typeof status?.progress !== 'undefined' ? status.progress : undefined;
  };

  getSeparationVersion = (separation) => {
    return typeof separation !== 'undefined' && typeof separation.separationContent !== 'undefined' && typeof separation.separationContent.externalVersion !== 'undefined' ?
      separation.separationContent.externalVersion :
      undefined;
  };

  getSeparationStyle = (rgb) => {
    return {
      border: 'solid 2px ' + rgb,
      width: '8px',
      height: '12px'//,
      //marginBottom: '6px'
    };
  };

  getSeparationContentStyle = (progress, version, rgb) => {
    return {
      width: '100%',
      height: '100%',
      backgroundColor: (progress === -1 || progress === '-1') && version === 0 ? '#eeeeee' : rgb
    };
  };

  unplannedIcon = (separation) => {
    var isPlanned = this.isPlanned(separation);

    if (!isPlanned) {
      return <img className="UnplannedSeparationIcon" src={iconService.getModuleIcon('MyBirdeye', 'unplanned_separation')} />;
    }
    return undefined;
  };

  linkIcon = (separation) => {
    var isLink = this.isLink(separation);

    if (isLink) {
      return <img className="LinkSeparationIcon" src={iconService.getModuleIcon('MyBirdeye', 'link')} />;
    }
    return undefined;
  };

  separationContent = (separation) => {
    return [
      this.unplannedIcon(separation),
      this.linkIcon(separation)
    ];
  };

  renderSeparation = (definition, separation) => {//function (separationName, separationStyle, separationContentStyle, content) {
    var separationName = separation.name,
      separationColor = colorService.getColorByName(separationName),
      separationRGB = colorService.getUIColorRGB(separationColor),
      separationVersion = this.getSeparationVersion(separation),
      separationProgress = this.getSeparationProgress(separation),
      separationStyle = this.getSeparationStyle(separationRGB),
      separationContentStyle = this.getSeparationContentStyle(separationProgress, separationVersion, separationRGB);

    return <div className={'Separation Separation-' + separationName} style={separationStyle}>
      <div className="Separation-Content" style={separationContentStyle}>
        {this.separationContent(separation)}
      </div>
    </div>;
  };

  renderVersion = (definition, separation) => {
    var version = separation.separationContent.externalVersion;

    return <SeparationVersion definition={definition} version={version} />;
  };

  renderStatus = (definition, separation) => {
    return <SeparationStatusIcon definition={definition} separation={separation} />;
  };

  renderStatusType = (definition, separation) => {
    var status = getMainStatus(separation) || {};

    return <SeparationStatusType definition={definition} status={status} />
  };

  renderPlates = (definition, separation) => {
    var plates = separation.plates;

    return <SeparationPlates definition={definition} plates={plates} />;
  };

  renderPlatesProgress = (definition, separation) => {
    var plates = separation.plates,
      separationName = separation.name,
      separationColor = colorService.getColorByName(separationName),
      separationRGB = colorService.getUIColorRGB(separationColor);

    return <SeparationPlatesProgress definition={definition} plates={plates} color={separationRGB} />;
  };

  renderSeparationElement = (elementDefinition, separation) => {
    var type = elementDefinition.type,
      status = getMainStatus(separation) || {},
      flowStepType = status.flowStepType;

    switch (type) {
      case 'separation':
        return this.renderSeparation(elementDefinition, separation);
        break;
      case 'separation-version':
        return this.renderVersion(elementDefinition, separation);
        break;
      case 'separation-status':
        return this.renderStatus(elementDefinition, separation);
        break;
      case 'separation-status-type':
        if (DIFF_INVISIBLE_FLOW_STEPS.indexOf(flowStepType) >= 0)
          return null;
        return this.renderStatusType(elementDefinition, separation);
        break;
      case 'separation-plates':
        if (DIFF_INVISIBLE_FLOW_STEPS.indexOf(flowStepType) >= 0)
          return null;
        return this.renderPlates(elementDefinition, separation);
        break;
      case 'separation-plates-progress':
        if (DIFF_INVISIBLE_FLOW_STEPS.indexOf(flowStepType) < 0)
          return null;
        return this.renderPlatesProgress(elementDefinition, separation);
        break;
      default:
        return <div></div>;
    }
  };

  renderSeparationElements = (definition, separation) => {
    var that = this,
      elements = definition.elements || [];
    return elements.map(function (elementDefinition) {
      return <div className="SeparationProgressItemElement">
        {that.renderSeparationElement(elementDefinition, separation)}
      </div>;
    });
  };

  render() {
    var separation = this.getSeparation(),
      definition = this.getDefinition();

    return <li className="separation-progress-item">
      {this.renderSeparationElements(definition, separation)}
    </li>;
  }
}