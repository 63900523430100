import React from 'react';
import { fromServerDate, fromServerDateOnly } from 'core/dates';
import ReactDataGrid from 'widgets/ReactDataGrid/src/ReactDataGrid';
import ApproversCell from './customCells/ApproversCell';
import { getItemApprovalIndications, getItemCompletedIndications, getItemRejectedIndications } from 'utilities/approvals';
import iconService from 'core/services/iconService';
import colorService from 'core/services/colorService';
import localization from 'core/services/localization';
import { getMainStatus } from 'utilities/statuses';
import { translate } from 'core/services/localization';
import { classNames } from 'utilities/classNames';
import { getHoldIconName, getHoldType } from 'utilities/hold';

const isUndefined = o => typeof o === 'undefined';

const { SortableHeader } = ReactDataGrid.headers;
const { Text, IconCell } = ReactDataGrid.cells;

const cellStatusBackground = {
  red: '#f84545',
  yellow: '#ffcd35',
  green: '#3aea4b',
  blue: '#5eced5'
};

const cellStatusTextColor = {
  black: '#000000',
  white: '#FFFFFF'
};


const approvalStatusIcons = {
  'completed': iconService.getModuleIcon('MyBirdeye', 'success'),
  'rejected': iconService.getModuleIcon('Thumbnail', 'reject-small'),
  'waiting': iconService.getModuleIcon('MyBirdeye', 'wait')
};

const header = (content, columnKey, sortColumnKey, sortOrder) => {
  const sort = columnKey === sortColumnKey ? sortOrder : undefined;
  return (props) => <SortableHeader {...props} sort={sort}>
    {translate(content)}
  </SortableHeader>;
};

const cellDataGetter = (...args) => {
  const rows = args[0],
    parent = args.length === 3 ? args[1] : null,
    columnKey = args.length === 3 ? args[2] : args[1];

  return (rowIndex) => {
    let columnData = parent === null ? rows[rowIndex][columnKey] : rows[rowIndex][parent][columnKey];
    return {
      columnData
    };
  };
};

const numberCellDataGetter = (rows) => {
  return (rowIndex) => {
    let row = rows[rowIndex],
      number = row.number === undefined ?
        row.parent === undefined ? '' :
          row.parent.number === null || row.parent.number === undefined ? row.parent.physicalNumber :
            row.parent.number :
        row.number;

    return {
      columnData: number
    };
  };
};

const layoutGroupCellDataGetter = (rows) => {
  return (rowIndex) => {
    let row = rows[rowIndex],
      layoutGroupName = row.book.layoutGroupName;

    return {
      columnData: layoutGroupName
    };
  };
};

const layoutCellDataGetter = (rows) => {
  return (rowIndex) => {
    let row = rows[rowIndex],
      layoutName = row.formContent.impositionName || '',
      manualLayoutChanged = row.manualLayoutChanged || false;

    return {
      columnData: <span className={classNames({ 'bold': manualLayoutChanged })}>{layoutName}</span>,
      title: layoutName
    };
  };
};

const versionCellDataGetter = (rows) => {
  return (rowIndex) => {
    let row = rows[rowIndex],
      rowContent = row.pageContent || row.formContent || row.separationContent || {},
      version = rowContent.contentVersionMajorIndex || rowContent.externalVersion || '';

    return {
      columnData: version
    };
  };
};

const sectionCellDataGetter = (rows) => {
  return (rowIndex) => {
    let sectionName = rows[rowIndex].section === undefined ?
      rows[rowIndex].parent === undefined || rows[rowIndex].parent.section === undefined ? '' :
        rows[rowIndex].parent.section.sectionName :
      rows[rowIndex].section.sectionName;
    return {
      columnData: sectionName
    };
  };
};

const pageNameCellDataGetter = (rows, columnKey) => {
  return (rowIndex) => {
    return {
      columnData: rows[rowIndex][columnKey].pageName
    };
  };
};

const bookCellDataGetter = (rows) => {
  return (rowIndex) => {
    let bookName = '';

    if (typeof rows[rowIndex].book === 'string') bookName = rows[rowIndex].book;
    else if (typeof rows[rowIndex].bookName === 'string') bookName = rows[rowIndex].bookName;
    else if (rows[rowIndex].book && typeof rows[rowIndex].book.bookName === 'string') bookName = rows[rowIndex].book.bookName;
    else if (rows[rowIndex].parent && rows[rowIndex].parent.book && typeof rows[rowIndex].parent.book.bookName === 'string') bookName = rows[rowIndex].parent.book.bookName;

    return {
      columnData: bookName
    };
  };
};

const fileNameCellDataGetter = (rows, columnKey) => {
  return (rowIndex) => {
    return {
      columnData: rows[rowIndex][columnKey]//.expectedName
    };
  };
};

const inputFileNameCellDataGetter = (rows, columnKey) => {
  return (rowIndex) => {
    const row = rows[rowIndex];
    let columnData = '';
    if (columnKey === 'formSeparation') {
      columnData = row && row[columnKey] && row[columnKey].separationContent ? row[columnKey].separationContent.mainVersionInputFileName : '';
    } else {
      columnData = row && row[columnKey] ? row[columnKey].mainVersionInputFileName : '';
    }
    return {
      columnData
    };
  };
};

const dateCellDataGetter = (...args) => {
  const rows = args[0],
    parent = args.length === 3 ? args[1] : null,
    columnKey = args.length === 3 ? args[2] : args[1];

  return (rowIndex) => {
    let columnData = parent === null ? rows[rowIndex][columnKey] : rows[rowIndex][parent][columnKey];
    return {
      columnData: localization.toLocaleShortDate(fromServerDateOnly(columnData))
    };
  };
};

const separationCellDataGetter = (rows, columnKey) => {
  return (rowIndex) => {
    let row = rows[rowIndex],
      separations = row.separations,
      color,
      icon = iconService.getModuleIcon("TableView", "color"),
      iconStyle,
      title = translate('Color');

    if (Array.isArray(separations)) {
      if (separations.length === 1) {
        icon = iconService.getModuleIcon("TableView", "bw");
        title = translate('Black and White');
      }
    }
    else {
      color = colorService.getUIColorRGB(colorService.getColorByName(row[columnKey]));
      icon = iconService.getTemplateIcon(row.type, "tiny");
      iconStyle = { backgroundColor: color };
      title = row[columnKey];
    }

    return {
      icon,
      title,
      iconStyle
    };
  };
};

const actualCellDataGetter = (rows, columnKey) => {
  return (rowIndex) => {
    let isActual = rows[rowIndex][columnKey];
    let text = isActual ? 'Yes' : 'No';

    return {
      columnData: translate(text)
    };
  };
};

const flowstepCellDataGetter = rows => {
  return rowIndex => {
    let status = getMainStatus(rows[rowIndex]),
      step,
      flowStepIconName,
      icon = '';

    if (status !== null && typeof status !== 'undefined' && typeof status.time !== 'undefined' && status.statusType !== 'NotAssigned') {
      step = status.flowStepType;
      if (typeof step !== 'undefined') {
        flowStepIconName = status.flowStepIconName;
        icon = iconService.getTemplateIcon(step, 'tiny', flowStepIconName);
      }
    }

    return {
      icon
    };
  };
};

const preflightIndicationCellDataGetter = rows => {
  return rowIndex => {
    const reportType = getReportType(rows[rowIndex]);

    let icon;
    if (reportType === 1) {
      icon = iconService.getModuleIcon('MyBirdeye', 'preflight_report_warning', '.svg');
    } else if (reportType === 2) {
      icon = iconService.getModuleIcon('MyBirdeye', 'preflight_report_error', '.svg');
    }

    return {
      icon
    };
  };
};

const getReportType = row => row?.pageContent?.preflightReportInfo?.preflightReportType ?? 0;

const outputtedOnCellDataGetter = (rows, columnKey) => {
  return (rowIndex) => {
    let data = rows[rowIndex][columnKey];

    if (typeof data === "undefined" || data === "") {
      return {
        columnData: ""
      };
    }

    let resourceInfo = JSON.parse(data);
    let status = rows[rowIndex][columnKey];
    if (status === null || typeof status !== 'undefined') {
      return {
        columnData: resourceInfo.outputtedOn
      };
    }

    if (status.progress === resourceInfo.progress) {
      if (status.statusType === "waiting") {
        return {
          columnData: ""
        };
      }
    }

    return {
      columnData: resourceInfo.outputtedOn
    };
  };
};

const statusCellDataGetter = rows => {
  return rowIndex => {
    let status = getMainStatus(rows[rowIndex]),
      statusType,
      icon = '';

    if (status !== null && typeof status !== 'undefined' && typeof status.time !== 'undefined' && status.statusType !== 'NotAssigned') {
      statusType = status.statusType.toLowerCase();

      if (statusType === 'waiting') {
        icon = iconService.getGeneralIcon('status', 'wait');
      } else if (statusType === 'success') {
        icon = iconService.getGeneralIcon('status', 'finished');
      } else if (statusType === 'error' && status.flowStepType === 'workflow/step/flow/approval') {
        icon = iconService.getModuleIcon('Thumbnail', 'reject-small');
      } else if (statusType === 'processing') {
        icon = iconService.getGeneralIcon('status', 'in_progress');
      } else {
        icon = iconService.getGeneralIcon('status', statusType);
      }
    }

    return {
      icon
    };
  };
};

const holdCellDataGetter = (rows, columnKey) => {
  return rowIndex => {
    const row = rows[rowIndex];

    const holdType = getHoldType(row);
    const iconName = getHoldIconName(holdType);
    const icon = iconName ? iconService.getGeneralIcon('', iconName, '.svg') : undefined;

    return {
      icon
    };
  };
};

const startTimeCellDataGetter = rows => {
  return rowIndex => {
    let status = getMainStatus(rows[rowIndex]),
      time = '',
      columnData = '';

    if (status !== null && typeof status !== 'undefined' && typeof status.time !== 'undefined' && status.statusType !== 'NotAssigned') {
      time = status.startTime;
      if (time.length > 0) {
        columnData = localization.toLocaleShortDateTime(fromServerDate(time));
      }
    }

    return {
      columnData
    };
  };
};

const statusTimeCellDataGetter = rows => {
  return rowIndex => {
    let status = getMainStatus(rows[rowIndex]),
      time = '',
      columnData = '',
      textColor,
      backgroundColor;

    if (status !== null && typeof status !== 'undefined' && typeof status.time !== 'undefined' && status.statusType !== 'NotAssigned') {
      time = status.time;
      if (time.length > 0) {
        columnData = localization.toLocaleShortDateTime(fromServerDate(time), true);
      }

      backgroundColor = cellStatusBackground.yellow;
      if (status.progress === '100' && status.statusType === 'Success') {
        backgroundColor = cellStatusBackground.green;
        textColor = cellStatusTextColor.white;
      } else if (status.statusType === 'Error') {
        backgroundColor = cellStatusBackground.red;
        textColor = cellStatusTextColor.white;
      } else if (status.statusType === 'Waiting' && status.flowStepType === 'workflow/step/flow/approval') {
        backgroundColor = cellStatusBackground.blue;
      }
    }

    return {
      columnData,
      backgroundColor,
      textColor
    };
  };
};

const finishedTimeCellDataGetter = rows => {
  return rowIndex => {
    let status = getMainStatus(rows[rowIndex]),
      time = '',
      columnData = '';

    if (status !== null && typeof status !== 'undefined' && typeof status.time !== 'undefined' && status.statusType !== 'NotAssigned') {
      if (status.statusType === 'Success' || status.statusType === 'Error') {
        time = status.time;
        if (time.length > 0) {
          columnData = localization.toLocaleShortDateTime(fromServerDate(time));
        }
      }
    }

    return {
      columnData
    };
  };
};

const durationTimeCellDataGetter = rows => {
  return rowIndex => {
    let status = getMainStatus(rows[rowIndex]),
      time = '',
      columnData = '';

    if (status !== null && typeof status !== 'undefined' && typeof status.time !== 'undefined' && status.statusType !== 'NotAssigned') {
      if (status.statusType === 'Success') {
        time = status.duration;
        if (time.length > 0 || time === '0' || time === 0) {
          columnData = parseFloat(time) / 1000;
        }
      }
    }

    return {
      columnData
    };
  };
};

const resourceCellDataGetter = rows => {
  return (rowIndex) => {
    let row = rows[rowIndex],
      status = getMainStatus(row);

    if (status !== null && typeof status !== 'undefined' && typeof status.time !== 'undefined' && status.statusType !== 'NotAssigned') {
      const parentStatus = getMainStatus(row?.parent);
      if (typeof parentStatus?.time !== 'undefined') {
        status = parentStatus;
      };
    };

    return {
      columnData: status && status.resource !== undefined ? status.resource : ''
    };
  };
};

const assignedCTPCellDataGetter = (rows, columnKey) => {
  return (rowIndex) => {
    let columnData = '';
    let title = '';
    const assignedCTP = rows[rowIndex][columnKey];
    if (assignedCTP.resources && !isUndefined(assignedCTP.groups)) {
      columnData = assignedCTP.resources.length > 0 ? assignedCTP.resources.join(', ') : '';
      columnData = assignedCTP.groups.length > 0 ? `${(columnData ? `${columnData} | ` : '')}${assignedCTP.groups.join(', ')}` : columnData;

      title = assignedCTP.resources.length > 0 ? `${translate('Resources: ')} ${assignedCTP.resources.join(', ')}` : '';
      title = assignedCTP.groups.length > 0 ? `${(title ? `${title} | ` : '')}${translate('Groups: ')}${assignedCTP.groups.join(', ')}` : title;
    }
    return {
      columnData,
      title
    };
  };
};

const approversCellDataGetter = (rows, columnKey) => {
  return (rowIndex) => {
    const item = rows[rowIndex];
    const approvalIndications = item.type === 'page/separation' ? (getItemApprovalIndications(item.parent) || []).sort() : (getItemApprovalIndications(item) || []).sort();
    return {
      approvers: approvalIndications.map(approvalIndication => {
        const page = item.type === 'page' ? item : undefined;
        const approvalIndicationStatus = isUndefined(page) ? 'waiting' :
          getItemCompletedIndications(page).indexOf(approvalIndication) >= 0 ? 'completed' :
            getItemRejectedIndications(page).indexOf(approvalIndication) >= 0 ? 'rejected' :
              'waiting';

        if (approvalIndicationStatus !== 'waiting') return {
          name: approvalIndication,
          icon: approvalStatusIcons[approvalIndicationStatus]
        };

        const separations = item.type === 'page/separation' ? [item] : item.separations;
        const approvalIndicationSeparationsStatus = separations.reduce((accStatus, separation) => {
          if (accStatus === 'rejected') return 'rejected';

          const separationStatus = getItemCompletedIndications(separation).indexOf(approvalIndication) >= 0 ? 'completed' :
            getItemRejectedIndications(separation).indexOf(approvalIndication) >= 0 ? 'rejected' :
              'waiting';

          if (separationStatus === 'rejected') return 'rejected';
          if (accStatus === 'waiting') return 'waiting';

          return separationStatus;
        }, 'completed');

        return {
          name: approvalIndication,
          icon: approvalStatusIcons[approvalIndicationSeparationsStatus]
        };
      })
    };
  };

  // const getSeparationsStatus = (separations = [], indication) => {
  //   if (separations.length === 0) return 'waiting';

  //   const completedSeparations = separations.filter(separation => getApprovalIndication.call(this, separation, 'approvalIndicationComplete').indexOf(indication) > -1);
  //   const rejectedSeparations = separations.filter(separation => getApprovalIndication.call(this, separation, 'approvalIndicationReject').indexOf(indication) > -1);

  //   return completedSeparations.length === separations.length ? 'completed' : rejectedSeparations.length > 0 ? 'rejected' : 'waiting';
  // };

  // return (rowIndex) => {
  //   const approvalIndication = rows[rowIndex]['type'] === 'page/separation' ? rows[rowIndex].parent.approvalIndication : rows[rowIndex].approvalIndication;
  //   const completedIndications = getApprovalIndication.call(this, rows[rowIndex], 'approvalIndicationComplete');
  //   const rejectedIndications = getApprovalIndication.call(this, rows[rowIndex], 'approvalIndicationReject');
  //   const approvers = (approvalIndication || '').split(',').filter(indication => indication !== '').reduce((accApprovers, indication) => {
  //     const name = indication;
  //     const completed = completedIndications.indexOf(indication) > -1;
  //     const rejected = rejectedIndications.indexOf(indication) > -1;
  //     const separationsStatus = rows[rowIndex]['type'] === 'page' ? getSeparationsStatus(rows[rowIndex].separations, indication) : undefined;
  //     const status = completed ? 'completed' : rejected ? 'rejected' : separationsStatus || 'waiting';
  //     const icon = approvalStatusIcons[status];

  //     return accApprovers.concat({ name, icon });
  //   }, []);

  //   return {
  //     approvers
  //   };
  // }
};


const pagesColumns = () => {
  return {
    columnKey: 'sortingValue',
    columns: [
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'date', title: 'Publication Date', width: 126, align: 'center', resizable: true, header: header('Publication Date', 'date', sortColumnKey, sortOrder), cell: Text, cellDataGetter: dateCellDataGetter(rows, 'date') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'publication', title: 'Publication', width: 163, align: 'left', resizable: true, header: header('Publication', 'publication', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'publication') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'edition', title: 'Edition', width: 103, align: 'left', resizable: true, header: header('Edition', 'edition', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'edition') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'zone', title: 'Zone', width: 103, align: 'left', resizable: true, header: header('Zone', 'zone', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'zone') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'section', title: 'Section', width: 103, align: 'left', resizable: true, header: header('Section', 'section', sortColumnKey, sortOrder), cell: Text, cellDataGetter: sectionCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'number', title: 'Number', width: 100, align: 'left', resizable: true, header: header('Number', 'number', sortColumnKey, sortOrder), cell: Text, cellDataGetter: numberCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'name', title: 'Name', width: 103, align: 'left', resizable: true, header: header('Name', 'name', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'name') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'version', title: 'Version', width: 100, align: 'left', resizable: true, header: header('Version', 'version', sortColumnKey, sortOrder), cell: Text, cellDataGetter: versionCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'filename', title: 'File Name', width: 150, align: 'left', resizable: true, header: header('File Name', 'filename', sortColumnKey, sortOrder), cell: Text, cellDataGetter: fileNameCellDataGetter(rows, 'expectedName') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'separation', title: 'Color', width: 60, align: 'center', resizable: false, header: header('Color', 'separation', sortColumnKey, sortOrder), cell: IconCell, cellDataGetter: separationCellDataGetter(rows, 'separation') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'flowstep', title: 'Step', width: 56, align: 'center', resizable: false, header: header('Step', 'flowstep', sortColumnKey, sortOrder), cell: IconCell, cellDataGetter: flowstepCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'preflightIndication', title: 'Preflight', width: 80, align: 'center', resizable: true, header: header('Preflight', 'preflightIndication', sortColumnKey, sortOrder), cell: IconCell, cellDataGetter: preflightIndicationCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'status', title: 'Status', width: 67, align: 'center', resizable: false, header: header('Status', 'status', sortColumnKey, sortOrder), cell: IconCell, cellDataGetter: statusCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'approvalIndication', title: 'Approvers', width: 100, align: 'center', resizable: true, header: header('Approvers', 'approvalIndication', sortColumnKey, sortOrder), cell: ApproversCell, cellDataGetter: approversCellDataGetter(rows, 'approvalIndication') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'holdType', title: 'Hold', width: 56, align: 'center', resizable: false, header: header('Hold', 'holdType', sortColumnKey, sortOrder), cell: IconCell, cellDataGetter: holdCellDataGetter(rows, 'holdType') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'statustime', title: 'Status Time', width: 100, align: 'left', resizable: true, header: header('Status Time', 'statustime', sortColumnKey, sortOrder), cell: Text, cellDataGetter: statusTimeCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'inputFileName', title: 'Input File Name', width: 150, align: 'left', resizable: true, header: header('Input File Name', 'inputFileName', sortColumnKey, sortOrder), cell: Text, cellDataGetter: inputFileNameCellDataGetter(rows, 'pageContent') }),
    ]
  };
};

const formsColumns = () => {
  return {
    columnKey: 'sortingValue',
    columns: [
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'date', title: 'Publication Date', width: 126, align: 'center', resizable: true, header: header('Publication Date', 'date', sortColumnKey, sortOrder), cell: Text, cellDataGetter: dateCellDataGetter(rows, 'date') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'publication', title: 'Publication', width: 163, align: 'left', resizable: true, header: header('Publication', 'publication', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'publication') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'edition', title: 'Edition', width: 103, align: 'left', resizable: true, header: header('Edition', 'edition', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'edition') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'zone', title: 'Zone', width: 103, align: 'left', resizable: true, header: header('Zone', 'zone', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'zone') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'book', title: 'Book', width: 103, align: 'left', resizable: true, header: header('Book', 'book', sortColumnKey, sortOrder), cell: Text, cellDataGetter: bookCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'layout', title: 'Layout', width: 103, align: 'left', resizable: true, header: header('Layout', 'layout', sortColumnKey, sortOrder), cell: Text, cellDataGetter: layoutCellDataGetter(rows, 'layout') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'layoutgroup', title: 'Layout Group', width: 103, align: 'left', resizable: true, header: header('Layout Group', 'layoutgroup', sortColumnKey, sortOrder), cell: Text, cellDataGetter: layoutGroupCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'number', title: 'Number', width: 100, align: 'left', resizable: true, header: header('Number', 'number', sortColumnKey, sortOrder), cell: Text, cellDataGetter: numberCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'name', title: 'Name', width: 103, align: 'left', resizable: true, header: header('Name', 'name', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'name') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'version', title: 'Version', width: 100, align: 'left', resizable: true, header: header('Version', 'version', sortColumnKey, sortOrder), cell: Text, cellDataGetter: versionCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'separation', title: 'Color', width: 60, align: 'center', resizable: false, header: header('Color', 'separation', sortColumnKey, sortOrder), cell: IconCell, cellDataGetter: separationCellDataGetter(rows, 'separation') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'flowstep', title: 'Step', width: 56, align: 'center', resizable: false, header: header('Step', 'flowstep', sortColumnKey, sortOrder), cell: IconCell, cellDataGetter: flowstepCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'status', title: 'Status', width: 67, align: 'center', resizable: false, header: header('Status', 'status', sortColumnKey, sortOrder), cell: IconCell, cellDataGetter: statusCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'holdType', title: 'Hold', width: 56, align: 'center', resizable: false, header: header('Hold', 'holdType', sortColumnKey, sortOrder), cell: IconCell, cellDataGetter: holdCellDataGetter(rows, 'holdType') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'starttime', title: 'Start Time', width: 100, align: 'left', resizable: true, header: header('Start Time', 'starttime', sortColumnKey, sortOrder), cell: Text, cellDataGetter: startTimeCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'statustime', title: 'Status Time', width: 100, align: 'left', resizable: true, header: header('Status Time', 'statustime', sortColumnKey, sortOrder), cell: Text, cellDataGetter: statusTimeCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'finishedtime', title: 'Finished', width: 100, align: 'left', resizable: true, header: header('Finished', 'finishedtime', sortColumnKey, sortOrder), cell: Text, cellDataGetter: finishedTimeCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'duration', title: 'Duration', width: 100, align: 'left', resizable: true, header: header('Duration', 'duration', sortColumnKey, sortOrder), cell: Text, cellDataGetter: durationTimeCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'resource', title: 'Resource', width: 100, align: 'left', resizable: true, header: header('Resource', 'resource', sortColumnKey, sortOrder), cell: Text, cellDataGetter: resourceCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'inputFileName', title: 'Input File Name', width: 150, align: 'left', resizable: true, header: header('Input File Name', 'inputFileName', sortColumnKey, sortOrder), cell: Text, cellDataGetter: inputFileNameCellDataGetter(rows, 'formContent') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'fanout', title: 'Fanout', width: 126, align: 'left', resizable: true, header: header('Fanout', 'fanout', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'fanout') })
    ]
  };
};

const pageSeparationsColumns = () => {
  return {
    columnKey: 'sortingValue',
    columns: [
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'date', title: 'Publication Date', width: 126, align: 'center', resizable: true, header: header('Publication Date', 'date', sortColumnKey, sortOrder), cell: Text, cellDataGetter: dateCellDataGetter(rows, 'parent', 'date') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'publication', title: 'Publication', width: 163, align: 'left', resizable: true, header: header('Publication', 'publication', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'parent', 'publication') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'edition', title: 'Edition', width: 103, align: 'left', resizable: true, header: header('Edition', 'edition', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'parent', 'edition') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'zone', title: 'Zone', width: 103, align: 'left', resizable: true, header: header('Zone', 'zone', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'parent', 'zone') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'section', title: 'Section', width: 103, align: 'left', resizable: true, header: header('Section', 'section', sortColumnKey, sortOrder), cell: Text, cellDataGetter: sectionCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'pagename', title: 'Page', width: 103, align: 'left', resizable: true, header: header('Page', 'pagename', sortColumnKey, sortOrder), cell: Text, cellDataGetter: pageNameCellDataGetter(rows, 'parent') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'number', title: 'Number', width: 100, align: 'left', resizable: true, header: header('Number', 'number', sortColumnKey, sortOrder), cell: Text, cellDataGetter: numberCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'version', title: 'Version', width: 100, align: 'left', resizable: true, header: header('Version', 'version', sortColumnKey, sortOrder), cell: Text, cellDataGetter: versionCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'filename', title: 'File Name', width: 150, align: 'left', resizable: true, header: header('File Name', 'filename', sortColumnKey, sortOrder), cell: Text, cellDataGetter: fileNameCellDataGetter(rows, 'expectedName') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'separation', title: 'Color', width: 60, align: 'center', resizable: false, header: header('Color', 'separation', sortColumnKey, sortOrder), cell: IconCell, cellDataGetter: separationCellDataGetter(rows, 'separation') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'flowstep', title: 'Step', width: 56, align: 'center', resizable: false, header: header('Step', 'flowstep', sortColumnKey, sortOrder), cell: IconCell, cellDataGetter: flowstepCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'status', title: 'Status', width: 67, align: 'center', resizable: false, header: header('Status', 'status', sortColumnKey, sortOrder), cell: IconCell, cellDataGetter: statusCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'approvalIndication', title: 'Approvers', width: 100, align: 'center', resizable: true, header: header('Approvers', 'approvalIndication', sortColumnKey, sortOrder), cell: ApproversCell, cellDataGetter: approversCellDataGetter(rows, 'approvalIndication') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'statustime', title: 'Status Time', width: 100, align: 'left', resizable: true, header: header('Status Time', 'statustime', sortColumnKey, sortOrder), cell: Text, cellDataGetter: statusTimeCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'inputFileName', title: 'Input File Name', width: 150, align: 'left', resizable: true, header: header('Input File Name', 'inputFileName', sortColumnKey, sortOrder), cell: Text, cellDataGetter: inputFileNameCellDataGetter(rows, 'separationContent') }),
    ]
  };
};

const formSeparationsColumns = () => {
  return {
    columnKey: 'sortingValue',
    columns: [
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'date', title: 'Publication Date', width: 126, align: 'center', resizable: true, header: header('Publication Date', 'date', sortColumnKey, sortOrder), cell: Text, cellDataGetter: dateCellDataGetter(rows, 'parent', 'date') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'publication', title: 'Publication', width: 163, align: 'left', resizable: true, header: header('Publication', 'publication', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'parent', 'publication') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'edition', title: 'Edition', width: 103, align: 'left', resizable: true, header: header('Edition', 'edition', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'parent', 'edition') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'zone', title: 'Zone', width: 103, align: 'left', resizable: true, header: header('Zone', 'zone', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'parent', 'zone') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'book', title: 'Book', width: 103, align: 'left', resizable: true, header: header('Book', 'book', sortColumnKey, sortOrder), cell: Text, cellDataGetter: bookCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'number', title: 'Number', width: 100, align: 'left', resizable: true, header: header('Number', 'number', sortColumnKey, sortOrder), cell: Text, cellDataGetter: numberCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'version', title: 'Version', width: 100, align: 'left', resizable: true, header: header('Version', 'version', sortColumnKey, sortOrder), cell: Text, cellDataGetter: versionCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'separation', title: 'Color', width: 60, align: 'center', resizable: false, header: header('Color', 'separation', sortColumnKey, sortOrder), cell: IconCell, cellDataGetter: separationCellDataGetter(rows, 'separation') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'flowstep', title: 'Step', width: 56, align: 'center', resizable: false, header: header('Step', 'flowstep', sortColumnKey, sortOrder), cell: IconCell, cellDataGetter: flowstepCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'status', title: 'Status', width: 67, align: 'center', resizable: false, header: header('Status', 'status', sortColumnKey, sortOrder), cell: IconCell, cellDataGetter: statusCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'starttime', title: 'Start Time', width: 100, align: 'left', resizable: true, header: header('Start Time', 'starttime', sortColumnKey, sortOrder), cell: Text, cellDataGetter: startTimeCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'statustime', title: 'Status Time', width: 100, align: 'left', resizable: true, header: header('Status Time', 'statustime', sortColumnKey, sortOrder), cell: Text, cellDataGetter: statusTimeCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'finishedtime', title: 'Finished', width: 100, align: 'left', resizable: true, header: header('Finished', 'finishedtime', sortColumnKey, sortOrder), cell: Text, cellDataGetter: finishedTimeCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'duration', title: 'Duration', width: 100, align: 'left', resizable: true, header: header('Duration', 'duration', sortColumnKey, sortOrder), cell: Text, cellDataGetter: durationTimeCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'resource', title: 'Resource', width: 100, align: 'left', resizable: true, header: header('Resource', 'resource', sortColumnKey, sortOrder), cell: Text, cellDataGetter: resourceCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'inputFileName', title: 'Input File Name', width: 150, align: 'left', resizable: true, header: header('Input File Name', 'inputFileName', sortColumnKey, sortOrder), cell: Text, cellDataGetter: inputFileNameCellDataGetter(rows, 'separationContent') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'fanout', title: 'Fanout', width: 126, align: 'left', resizable: true, header: header('Fanout', 'fanout', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'fanout') })
    ]
  };
};

const platesColumns = () => {
  return {
    columnKey: 'sortingValue',
    columns: [
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'date', title: 'Publication Date', width: 126, align: 'center', resizable: true, header: header('Publication Date', 'date', sortColumnKey, sortOrder), cell: Text, cellDataGetter: dateCellDataGetter(rows, 'date') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'publication', title: 'Publication', width: 100, align: 'left', resizable: true, header: header('Publication', 'publication', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'publication') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'edition', title: 'Edition', width: 103, align3: 'left', resizable: true, header: header('Edition', 'edition', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'edition') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'zone', title: 'Zone', width: 103, align: 'left', resizable: true, header: header('Zone', 'zone', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'zone') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'book', title: 'Book', width: 103, align: 'left', resizable: true, header: header('Book', 'book', sortColumnKey, sortOrder), cell: Text, cellDataGetter: bookCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'form', title: 'Form', width: 103, align: 'left', resizable: true, header: header('Form', 'form', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'form') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'physicalNumber', title: 'Form', width: 103, align: 'left', resizable: true, header: header('Number', 'physicalNumber', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'physicalNumber') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'formSeparationName', title: 'Color', width: 60, align: 'center', resizable: false, header: header('Color', 'formSeparationName', sortColumnKey, sortOrder), cell: IconCell, cellDataGetter: separationCellDataGetter(rows, 'formSeparationName') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'productionRun', title: 'Production Run', width: 150, align: 'left', resizable: true, header: header('Production Run', 'productionRun', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'productionRun') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'cylinderName', title: 'Cylinder', width: 100, align: 'left', resizable: true, header: header('Cylinder', 'cylinderName', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'cylinderName') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'cylinderSector', title: 'Sector', width: 100, align: 'left', resizable: true, header: header('Sector', 'cylinderSector', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'cylinderSector') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'locationOnCylinder', title: 'Location', width: 100, align: 'left', resizable: true, header: header('Location', 'locationOnCylinder', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'locationOnCylinder') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'towerName', title: 'Tower', width: 100, align: 'left', resizable: true, header: header('Tower', 'towerName', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'towerName') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'pressName', title: 'Press', width: 100, align: 'left', resizable: true, header: header('Press', 'pressName', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'pressName') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'holdType', title: 'Hold', width: 100, align: 'left', resizable: true, header: header('Hold', 'holdType', sortColumnKey, sortOrder), cell: IconCell, cellDataGetter: holdCellDataGetter(rows, 'holdType') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'isActual', title: 'Expected', width: 126, align: 'left', resizable: true, header: header('Expected', 'isActual', sortColumnKey, sortOrder), cell: Text, cellDataGetter: actualCellDataGetter(rows, 'isActual') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'outputResourceData', title: 'Ouputted On', width: 100, align: 'left', resizable: true, header: header('Outputted On', 'outputtedon', sortColumnKey, sortOrder), cell: Text, cellDataGetter: outputtedOnCellDataGetter(rows, 'outputResourceData') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'flowstep', title: 'Step', width: 56, align: 'center', resizable: false, header: header('Step', 'flowstep', sortColumnKey, sortOrder), cell: IconCell, cellDataGetter: flowstepCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'status', title: 'Status', width: 67, align: 'center', resizable: false, header: header('Status', 'status', sortColumnKey, sortOrder), cell: IconCell, cellDataGetter: statusCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'statustime', title: 'Status Time', width: 100, align: 'left', resizable: true, header: header('Status Time', 'statustime', sortColumnKey, sortOrder), cell: Text, cellDataGetter: statusTimeCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'resource', title: 'Resource', width: 100, align: 'left', resizable: true, header: header('Resource', 'resource', sortColumnKey, sortOrder), cell: Text, cellDataGetter: resourceCellDataGetter(rows) }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'groupName', title: 'Group Name', width: 100, align: 'left', resizable: true, header: header('Group', 'groupName', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'groupName') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'groupOrder', title: 'group Order', width: 60, align: 'left', resizable: true, header: header('Order', 'groupOrder', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'groupOrder') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'assignedCTP', title: 'Assigned CTP', width: 60, align: 'left', resizable: true, header: header('Assigned CTP', 'assignedCTP', sortColumnKey, sortOrder), cell: Text, cellDataGetter: assignedCTPCellDataGetter(rows, 'assignedCTP') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'inputFileName', title: 'Input File Name', width: 150, align: 'left', resizable: true, header: header('Input File Name', 'inputFileName', sortColumnKey, sortOrder), cell: Text, cellDataGetter: inputFileNameCellDataGetter(rows, 'formSeparation') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'bin', title: 'Bin', width: 126, align: 'left', resizable: true, header: header('Bin', 'bin', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'bin') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'sorter', title: 'Sorter', width: 126, align: 'left', resizable: true, header: header('Sorter', 'sorter', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'sorter') }),
      (rows, sortColumnKey, sortOrder) => ({ columnKey: 'fanout', title: 'Fanout', width: 126, align: 'left', resizable: true, header: header('Fanout', 'fanout', sortColumnKey, sortOrder), cell: Text, cellDataGetter: cellDataGetter(rows, 'fanout') }),
    ]
  };
};

// function getApprovalIndication(model, prop) {
//   let content = model.type === "page/separation" ? model.separationContent : model.pageContent
//   let activeVersion = content.versionNumber;
//   let completeVersion = model.approvalCompleteVersion || "0";
//   if (activeVersion == completeVersion) {
//     return model[prop] || [];
//   }
//   return [];
// }

export default (viewType) => {
  if (viewType === 'pages') return pagesColumns();
  if (viewType === 'forms') return formsColumns();
  if (viewType === 'pageSeparations') return pageSeparationsColumns();
  if (viewType === 'formSeparations') return formSeparationsColumns();
  if (viewType === 'plates') return platesColumns();
};