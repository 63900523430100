class Toast {

  constructor (id, title, message, type, containerName, containerDirection, onClick, onClose, delay, store, defaultToastDelay) {
    this.id = id;
    this.containerName = containerName;
    this.store = store;
    this.defaultToastDelay = defaultToastDelay;

    this.type = this.type.bind(this);
    this.title = this.title.bind(this);
    this.message = this.message.bind(this);
    this.delay = this.delay.bind(this);
    this.update = this.update.bind(this);
    this.remove = this.remove.bind(this);

    this.store.dispatch({
      type: 'ADD_TOAST',
      container: containerName,
      direction: containerDirection,
      toast: {
        id,
        title,       
        message,
        type,
        containerName,
        containerDirection,
        visible: true,
        onClick,
        onClose,
        delay
      }
    });
  }

  type (type) {
    this.store.dispatch({
      type: 'UPDATE_TOAST',
      id: this.id,
      container: this.containerName,
      toast: {
        type
      }
    });
    return this;
  }

  title (title) {
    this.store.dispatch({
      type: 'UPDATE_TOAST',
      id: this.id,
      container: this.containerName,
      toast: {
        title
      }
    });
    return this;
  }

  message (message) {
    this.store.dispatch({
      type: 'UPDATE_TOAST',
      id: this.id,
      container: this.containerName,
      toast: {
        message
      }
    });
    return this;
  }

  delay (delay) {
    this.store.dispatch({
      type: 'UPDATE_TOAST',
      id: this.id,
      container: this.containerName,
      toast: {
        delay: delay === true ? this.defaultToastDelay : delay
      }
    });
    return this;
  }

  update (type, title, message) {
    this.store.dispatch({
      type: 'UPDATE_TOAST',
      id: this.id,
      container: this.containerName,
      toast: {
        type,
        title,
        message
      }
    });
    return this;
  }

  image = (imageUrl) => {
    this.store.dispatch({
      type: 'UPDATE_TOAST',
      id: this.id,
      container: this.containerName,
      toast: {
        imageUrl
      }
    });
  };

  remove () {
    this.store.dispatch({
      type: 'HIDE_TOAST',
      id: this.id,
      container: this.containerName
    });
  }



}

module.exports = Toast;