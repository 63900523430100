/**
 * @fileOverview
 * @author elad
 */

define(['sandbox', 'AbstractAction'], function (sandbox, AbstractAction) {
  'use strict';

  return {
    isApplicable: function () {
      ///After the selection is implemented this should change for selected items instead of the clicked item
      var obj = this.module.selected;
      if (typeof obj !== 'undefined') {

        //here I should check if the object is killed then return false
        return true;

      }
      return false;
    }
  };
});