import React from 'react';
import { Combobox } from 'components/common/floating';
import InputCell from './InputCell';

function ComboboxCell(props) {

  const handleDropdownSelect = (e, option, index) => {
    const { onChange, rowIndex, columnKey } = props;

    if (typeof onChange === 'function') {
      onChange(rowIndex, columnKey, option);
    }
  };

  return (
    <InputCell
      {...props}
      InputComponent={Combobox}
      onSelect={handleDropdownSelect}
    />
  );
}

export default ComboboxCell;
