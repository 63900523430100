const isUndefined = o => typeof o === 'undefined';
const isFunction = o => typeof o === 'function';

const DEFAULT_ITEM_COLUMNS_GETTER = (item) => 1;

// const getPaddedItems = (items, columns, itemColumnsGetter) => {
//   return items.reduce((paddedArray, item, index) => {
//     const itemColumns = itemColumnsGetter(item, index);
//     const paddedItem = { item, columns: isUndefined(itemColumns) ? 1 : itemColumns };
//     if (isUndefined(itemColumns) || itemColumns === 1) return paddedArray.concat(paddedItem);

//     const currentIndex = paddedArray.length;
//     const currentRow = Math.floor(currentIndex / columns);
//     const rowLastIndex = (currentRow * columns) + columns - 1;
//     const isFirstInRow = Math.floor(currentIndex / columns) === currentIndex / columns;
//     const isOverlapping = currentIndex + itemColumns > rowLastIndex + 1;
//     const itemWithPadding = [].concat(paddedItem).concat(Array(itemColumns - 1).fill(undefined));

//     return isOverlapping ?
//       isFirstInRow ?
//         paddedArray.concat(itemWithPadding) :
//         paddedArray.concat(Array(rowLastIndex + 1 - currentIndex).fill(undefined)).concat(itemWithPadding) :
//       paddedArray.concat(itemWithPadding);
//   }, []);
// };

const getItemsMapByKey = (items, keyProperty) => {
  return items.reduce((itemsMap, item) => {
    if (isUndefined(item[keyProperty])) return itemsMap;

    itemsMap[item[keyProperty]] = item;
    return itemsMap;
  }, {});
};

const updatedItemInItemsMapByKey = (itemsMapByKey, item, keyProperty) => {
  const key = item[keyProperty];

  if (!isUndefined(key)) {
    itemsMapByKey[key] = item;
  }

  return itemsMapByKey;
};

const removeItemFromItemsMapByKey = (itemsMapByKey, item, keyProperty) => {
  const key = item[keyProperty];

  if (!isUndefined(key)) {
    delete itemsMapByKey[key];
  }

  return itemsMapByKey;
};

export const EMPTY_ITEM = {};
export const EMPTY_ITEM_WITH_SPACE = {};

export default class DataSource {

  items = [];   //The default items without the padding of empty columns

  columns = undefined;

  keyProperty = undefined;

  itemColumnsGetter = DEFAULT_ITEM_COLUMNS_GETTER;    //A function to define how many columns an item should take

  paddedItems = [];   //An array of the padded items with empty columns

  //TODO: change the name to mapItems
  itemsMapByKey = {};

  mapItemsColumns = new Map();

  constructor({
    items,              //(Array) - Default items
    columns,            //(Number) - How many columns are in the grid
    keyProperty,        //(String) - A key property for each item
    itemColumnsGetter,  //(Function) - An item columns getter function to define how many columns an item should take
  }) {
    this.items = items || [];
    this.columns = columns || 0;
    this.keyProperty = keyProperty;
    this.itemColumnsGetter = isFunction(itemColumnsGetter) ? itemColumnsGetter : this.itemColumnsGetter;
    this.updatePaddedItems();
    this.itemsMapByKey = !isUndefined(keyProperty) ? getItemsMapByKey(items, keyProperty) : this.itemsMapByKey;
  }

  get length() {
    return this.items.length;
  }

  list = () => {
    return this.items;
  };

  renderedList = () => {
    return this.paddedItems;
  };

  //updatePaddedItems(Function) - updates the paddedItems and mapItemsColumns according to the items array
  updatePaddedItems = () => {
    const columns = this.columns;
    const itemColumnsGetter = this.itemColumnsGetter;

    //IMPORTANT: the reduce also updates this.mapItemsColumns (Map) with the size of the columns for each item in this.items
    this.paddedItems = this.items.reduce((paddedArray, item, index) => {

      const itemColumns = itemColumnsGetter(item, index);
      if (!isUndefined(item)) this.mapItemsColumns.set(item, isUndefined(itemColumns) ? 0 : itemColumns);

      if (isUndefined(itemColumns) || itemColumns === 1) return paddedArray.concat(item);

      const currentIndex = paddedArray.length;
      const currentRow = Math.floor(currentIndex / columns);
      const rowLastIndex = (currentRow * columns) + columns - 1;
      const isFirstInRow = Math.floor(currentIndex / columns) === currentIndex / columns;
      const isOverlapping = currentIndex + itemColumns > rowLastIndex + 1;
      const itemWithPadding = [].concat(item).concat(Array(itemColumns - 1).fill(EMPTY_ITEM));

      return isOverlapping ?
        isFirstInRow ?
          paddedArray.concat(itemWithPadding) :
          paddedArray.concat(Array(rowLastIndex + 1 - currentIndex).fill(EMPTY_ITEM_WITH_SPACE)).concat(itemWithPadding) :
        paddedArray.concat(itemWithPadding);

    }, []);

    return this.paddedItems;
  };

  getColumns = () => {
    return this.columns;
  };

  setColumns = (columns) => {
    this.columns = columns;
    this.updatePaddedItems();

    return this;
  };

  get = (index) => {
    return this.items[index];
  };

  getByKey = (keyValue) => {
    return this.itemsMapByKey[keyValue];
  };

  add = (item) => {
    this.items = this.items.concat(item);
    this.itemsMapByKey = updatedItemInItemsMapByKey(this.itemsMapByKey, item, this.keyProperty);
    this.updatePaddedItems();

    return this;
  };

  addAt = (item, index) => {
    if (index > this.items.length || index < 0) return this;

    this.items = this.items.slice(0, index).concat(item, this.items.slice(index, this.items.length));
    this.itemsMapByKey = updatedItemInItemsMapByKey(this.itemsMapByKey, item, this.keyProperty);
    this.updatePaddedItems();

    return this;
  };

  update = (item) => {
    const key = isUndefined(item) || isUndefined(this.keyProperty) ? undefined : item[this.keyProperty];
    const foundedItemInKeyMap = this.itemsMapByKey[key];
    const index = isUndefined(foundedItemInKeyMap) ? -1 : this.items.findIndex(i => i === foundedItemInKeyMap);
    const paddedItemIndex = isUndefined(foundedItemInKeyMap) ? -1 : this.paddedItems.findIndex(i => i === foundedItemInKeyMap);
    const oldItemColumns = this.mapItemsColumns.get(foundedItemInKeyMap);
    const newItemColumns = this.itemColumnsGetter(item);

    if (index < 0) return this;

    this.items = this.items.slice(0, index).concat(item, this.items.slice(index + 1, this.items.length));
    this.itemsMapByKey = updatedItemInItemsMapByKey(this.itemsMapByKey, item, this.keyProperty);

    if (newItemColumns !== oldItemColumns) {
      this.updatePaddedItems();
    }
    else {
      this.paddedItems = this.paddedItems.slice(0, paddedItemIndex).concat(item, this.paddedItems.slice(paddedItemIndex + 1, this.paddedItems.length));
      this.mapItemsColumns.delete(foundedItemInKeyMap);
      this.mapItemsColumns.set(item, newItemColumns);
    }

    return this;
  };

  updateAt = (item, index) => {
    const foundedItem = this.items[index];
    const paddedItemIndex = isUndefined(foundedItem) ? -1 : this.paddedItems.findIndex(i => i === foundedItem);
    const oldItemColumns = this.mapItemsColumns.get(foundedItem);
    const newItemColumns = this.itemColumnsGetter(item);

    this.items = this.items.slice(0, index).concat(item, this.items.slice(index + 1, this.items.length));
    this.itemsMapByKey = updatedItemInItemsMapByKey(this.itemsMapByKey, item, this.keyProperty);
    
    if (newItemColumns !== oldItemColumns) {
      this.updatePaddedItems();
    }
    else {
      this.paddedItems = this.paddedItems.slice(0, paddedItemIndex).concat(item, this.paddedItems.slice(paddedItemIndex + 1, this.paddedItems.length));
      this.mapItemsColumns.delete(foundedItem);
      this.mapItemsColumns.set(item, newItemColumns);
    }

    return this;
  };

  remove = (item) => {
    this.items = this.items.filter(i => i !== item);
    this.itemsMapByKey = removeItemFromItemsMapByKey(this.itemsMapByKey, item, this.keyProperty);
    this.updatePaddedItems();

    return this;
  };

  removeAt = (index) => {
    const item = this.items[index];

    this.items = this.items.slice(0, index).concat(this.items.slice(index + 1, this.items.length));
    this.itemsMapByKey = removeItemFromItemsMapByKey(this.itemsMapByKey, item, this.keyProperty);
    this.updatePaddedItems();

    return this;
  };

  clear = () => {
    this.items = [];
    this.paddedItems = [];
    this.itemsMapByKey = {};
    this.mapItemsColumns = new Map();

    return this;
  };

  forEach = (...args) => {
    return this.items.forEach(...args);
  };

  map = (...args) => {
    return this.items.map(...args);
  };

  reduce = (...args) => {
    return this.items.reduce(...args);
  };

  find = (...args) => {
    return this.items.find(...args);
  };

  findIndex = (...args) => {
    return this.items.findIndex(...args);
  };

  indexOf = (...args) => {
    return this.items.indexOf(...args);
  };

  slice = (...args) => {
    return this.items.slice(...args);
  }

  filter = (...args) => {
    return this.items.filter(...args);
  };

}