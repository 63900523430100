/**
 * @name WorkflowView
 * @fileOverview
 * @author sergey
 */

define(['sandbox', 'sandbox/jsUtils', 'AbstractModule', 'widgets/Graph/Graph', 'mixins/WorkflowMixin'],
  function (sandbox, jsUtils, AbstractModule, Graph, workflowMixin) {
    'use strict';
    var SAVE_TIMEOUT = 1000;

    //#########################################################################################
    //#################                 PRIVATE                        ########################
    //#########################################################################################


    function addToolbarItems() {
      // Note: Currently it is impossible to remove the action icon from the toolbar via configuration because
      // the action definition has triggers="toolbar,popup" (popup for Workflow View and toolbar for Flow Step View)
      this.toolbar.removeItem('EditFlowStepSetupActionCR');

      var module = this;
      this.toolbar.addItem({
        label: sandbox.localization.translate('Zoom out'),
        name: 'Zoom Out',
        _isApplicable: true,
        icon: 'zoom_out',
        iconSprite: 'general',
        iconStyle: { width: 18, height: 18 },
        execute: function () {
          this.view.zoomOut();
          this._savePreferences();
        }.bind(this)
      });
      this.toolbar.addItem({
        label: sandbox.localization.translate('Zoom In'),
        name: 'Zoom In',
        _isApplicable: true,
        icon: 'zoom_in',
        iconSprite: 'general',
        iconStyle: { width: 18, height: 18 },
        execute: function () {
          module.view.zoomIn();
          module._savePreferences();
        }
      });
      this.toolbar.addItem({
        label: sandbox.localization.translate('Zoom to fit screen'),
        name: 'Zoom To Fit Screen',
        _isApplicable: true,
        icon: 'fit_to_window',
        execute: function () {
          this.view.zoomToFit();
          this._savePreferences();
        }.bind(this)
      });
      this.toolbar.addItem({
        action: 'LockWorkflow',
        itemType: 'toggle',
        _isApplicable: true,
        checked: true,
        tooltipOn: sandbox.localization.translate('Unlock Workflow'),
        tooltipOff: sandbox.localization.translate('Lock Workflow'),
        iconOn: 'lock',
        iconOff: 'unlock',
        execute: function (checked) {
          checked ? module.view.lock() : module.view.unlock();
        }.bind(this)
      }
      );
    }

    function createGraph(module) {
      return new Graph({
        element: module.element.slice(1),
        contextMenu: function (event, clicked, selected) {
          module.showContextMenu(clicked, selected, event);
        },
        group: {
          labelProperty: "label",
          editableLabel: false
        },
        node: {
          doubleClick: function (event, node) {
            module.navigateByViewLink(node);
          },
          labelProperty: "label"
        }
      });
    }

    function createSavePreferences(module) {
      return function savePreferences() {
        if (!module.view) {
          return;
        }
        const preferences = module.view.getPreferences();

        if (preferences && !jsUtils.isEqual(module.preferences, preferences)) {
          module.preferences = jsUtils.cloneDeep(preferences);
          const requiredParameters = module.getRequiredParameters();
          sandbox.preferences.savePreferences(requiredParameters, preferences);
        }
      };
    }

    function getFlowsteps(flowsteps, flowstepsNwids) {
      return (flowsteps || []).filter(function (flowstepItem) {
        return flowstepsNwids.indexOf(flowstepItem.nwid) >= 0;
      });
    }

    function getFlowstep(flowsteps, flowstepNwid) {
      var filteredFlowsteps = getFlowsteps(flowsteps, [flowstepNwid]);

      return filteredFlowsteps.length > 0 ? filteredFlowsteps[0] : null;
    }

    function getTransitions(transitions, flowstepNwid, prop) {

      return (transitions || []).filter(function (transition) {
        return transition[prop] === flowstepNwid;
      });

    }

    function isLastFlowStep(nextTransitions) {
      return !(Array.isArray(nextTransitions) && nextTransitions.length > 0) ? true : false;
    }

    function isDisconnectedTransition(transition, flowsteps) {
      var sourceFlowstep = getFlowstep(flowsteps, transition.sourceStep);
      return sourceFlowstep === null;
    }

    function isBrokenTransition(transition, flowsteps) {
      var targetFlowstep = getFlowstep(flowsteps, transition.targetStep);
      return targetFlowstep === null;
    }

    function bridgedTransitions(transitions, flowsteps, firstBrokenTransition, transition) {
      var flowstepNwid = transition.targetStep;
      var nextTransitions = getTransitions(transitions, flowstepNwid, 'sourceStep');
      var flowstep = getFlowstep(flowsteps, flowstepNwid);

      if (isLastFlowStep(nextTransitions) && flowstep === null) {
        return null;
      }

      if (flowstep !== null) {
        return Object.assign({}, firstBrokenTransition, {
          targetStep: flowstepNwid
        });
      }

      return nextTransitions
        .map(function (nextTransition) {
          return bridgedTransitions(transitions, flowsteps, firstBrokenTransition, nextTransition);
        })
        .reduce(function (flattenTransitions, nextTransitions) {
          return flattenTransitions.concat(nextTransitions);
        }, [])
        .filter(function (nextTransition) {
          return nextTransition !== null;
        });

    }

    function transitionsWithBridgesReducer(transitions, flowsteps) {
      return function (transitionsWithBridges, transition) {

        if (isDisconnectedTransition(transition, flowsteps)) {
          return transitionsWithBridges;
        }

        if (isBrokenTransition(transition, flowsteps)) {
          //TODO: return the transitionsWithBridges concat with bridgedTransitions array.
          return transitionsWithBridges.concat(bridgedTransitions(transitions, flowsteps, transition, transition));
        }

        return transitionsWithBridges.concat(transition);
      };
    }

    function fixedTransitions(transitions, flowSteps) {
      return transitions
        .reduce(transitionsWithBridgesReducer(transitions, flowSteps), [])
        .filter(function (transition) {
          return transition !== null;
        });
    }

    //#########################################################################################
    //#################                 PUBLIC                         ########################
    //#########################################################################################

    function initDone() {
      sandbox.dom.addClass(this.element, 'pro-modules-workflow');
      this.view = createGraph(this);
      this._savePreferencesNow = createSavePreferences(this);
      this._savePreferences = jsUtils.debounce(this._savePreferencesNow, SAVE_TIMEOUT);
    }

    function insertData(view, data) {
      view.insertData({
        nodeKeyProperty: "nwid",
        linkFromKeyProperty: "sourceStep",
        linkToKeyProperty: "targetStep",
        nodeDataArray: data.model.flowSteps,
        linkDataArray: workflowMixin.fixedTransitions(data.model.transitions, data.model.flowSteps),
        preferences: data.preferences
      });
    }

    function firstTickReceived(data) {

      this.toolbar = this.createToolbar();
      addToolbarItems.call(this);

      if (this.tab.isActive()) {
        insertData(this.view, data);
      } else {
        this.data = data;
      }

      this.view.addChangedListener(this._savePreferences);
      this.selection.applyGraphSelection(this, this.view);
      this.preferences = jsUtils.cloneDeep(data.preferences);
    }

    return AbstractModule.extend({
      _savePreferences: null, //initialized in initDone
      initDone: initDone,
      firstTickReceived: firstTickReceived,
      tickUpdate: function (data) {
        //TODO: implement tickUpdate
        // this.view.render();
      },
      isLocked: function () {
        return !this.view || this.view.isLocked;
      },
      isEditable: function () {
        return this.view && this.view.isEditable;
      },
      autoLayout: function () {
        this.view && this.view.autoLayout();
      },
      rerender: function () {
        if (this.view) {
          if (this.data) {
            insertData(this.view, this.data);
            this.data = null;
          }
          this.view.redraw();
        }
      },
      destroy: function () {
        this._super();
        this.view.destroy();
        delete this.view;
      }
    }, "WorkflowView");
  });