import {startInternalModule} from 'core/managers/module';
import {translate} from 'core/services/localization';
import AbstractAction from 'AbstractAction';
import sandbox from 'sandbox';

// const MOCKUPDATA = {
// 	contentName: 'A1',
// 	contentNwid: '372954344148009365',
// 	contentPath: 'D:\\workspace\\NewswayX\\NWDataNewsway\\Actuals\\372954344148009365',
// 	crosStepProperties: {
// 		originRules: ['BW Page', '*'],
// 		originatorVersions: {
// 			'372954344148009377': '372954344148028231',
// 			'372954344148009387': '372954344148028704',
// 		},
// 		ripSetupName: 'COLOR',
// 		'subType-pdf': 'mono',
// 		'subType-png': 'full',
// 	},
// 	groupOrder: -1,
// 	inputs: [
// 		'D:\\workspace\\NewswayX\\NWDataNewsway\\Actuals\\372954344148009387\\mono_00000001606915394640_00372954344148028704.tif',
// 		'D:\\workspace\\NewswayX\\NWDataNewsway\\Actuals\\372954344148009377\\mono_00000001606915383912_00372954344148028231.tif',
// 	],
// 	next: '372954344148029244',
// 	nwid: '372954344148029232',
// 	outputs: [
// 		'D:\\workspace\\NewswayX\\NWDataNewsway\\Actuals\\372954344148009432\\icon_00000001606915420337_00372954344148029236.png',
// 		'D:\\workspace\\NewswayX\\NWDataNewsway\\Actuals\\372954344148009432\\medium_00000001606915420337_00372954344148029237.png',
// 		'D:\\workspace\\NewswayX\\NWDataNewsway\\Actuals\\372954344148009432\\mono_00000001606915420327_00372954344148029234.tif',
// 		'D:\\workspace\\NewswayX\\NWDataNewsway\\Actuals\\372954344148009432\\full_00000001606915420336_00372954344148029235.png',
// 	],
// 	prev: '372954344148028372',
// 	priority: '0',
// 	properties: {
// 		actionName: 'dnd',
// 		auditAction: 'Map unplan',
// 		auditInfo: 'Mapped version named 0815_pub_e_c_a_002.pdf',
// 		auditTime: 'Dec-02-2020-15-27-35',
// 		auditUser: '288230376155289082',
// 		column_Edition: 'Edition',
// 		column_EventTime: 'Dec-02-2020-15-27-35',
// 		column_FileName: '1127_pub_e_c_a_001.pdf',
// 		column_Name: 'A1',
// 		column_Publication: 'Publication',
// 		'column_Publication Date': 'Nov-27-2020',
// 		column_Version: '2',
// 		column_Zone: 'Common',
// 		columnsCalculated: true,
// 		contentType: 'pdf',
// 		fullPath: '0815_pub_e_c_a_002.pdf',
// 		majorVersion: '1606915655735',
// 		overwriteInputFilename: '0815_pub_e_c_a_002.pdf',
// 		page: '1127_pub_e_c_a_001.pdf',
// 		renameAction: true,
// 		sourcePageNwid: '372954344148016898',
// 		subType: 'mono',
// 		updateUnplannedVersion: true,
// 	},
// 	structureBreadCrumbs: '/Customer/Nov-27-2020/Publication/Edition/Common/Pages/A/A1',
// 	structureName: 'A1',
// 	structureNwid: '372954344148009364',
// 	unitProperties: {
// 		'deletePath:remoteFile': 'D:/WorkflowFolders/Input\\0815_pub_e_c_a_002.pdf.1606915159482.372954344147691016.nw',
// 		dstDir: 'd:/temp',
// 		expectedName: '1127_pub_e_c_a_001.pdf',
// 		fileSize: '9387',
// 		filename: '1127_pub_e_c_a_001.pdf',
// 		fullPath: 'd:/temp\\1127_pub_e_c_a_001.pdf',
// 		imgProp: {
// 			byteInRow: 0,
// 			filename: 'd:/temp\\0815_pub_e_c_a_002.pdf',
// 			height: 900,
// 			listParam: 0,
// 			lowResProps: {},
// 			metaData: {
// 				metaDataVersion: 3,
// 				rectangles: [
// 					{
// 						height: 900,
// 						label: 'artbox',
// 						left: 0,
// 						referencePoint: 'bottom-left',
// 						top: 0,
// 						width: 756,
// 					},
// 					{
// 						height: 900,
// 						label: 'bleedbox',
// 						left: 0,
// 						referencePoint: 'bottom-left',
// 						top: 0,
// 						width: 756,
// 					},
// 					{
// 						height: 900,
// 						label: 'cropbox',
// 						left: 0,
// 						referencePoint: 'bottom-left',
// 						top: 0,
// 						width: 756,
// 					},
// 					{
// 						height: 900,
// 						label: 'mediabox',
// 						left: 0,
// 						referencePoint: 'bottom-left',
// 						top: 0,
// 						width: 756,
// 					},
// 					{
// 						height: 900,
// 						label: 'trimbox',
// 						left: 0,
// 						referencePoint: 'bottom-left',
// 						top: 0,
// 						width: 756,
// 					},
// 				],
// 				units: 'points',
// 			},
// 			normalizeHeight: 12.5,
// 			normalizeWidth: 10.5,
// 			numberOfPages: 1,
// 			oneIsBlack: true,
// 			pagename: '1',
// 			scanResX: 72,
// 			scanResY: 72,
// 			separation: 'UnKnown',
// 			size: '9.4 kB',
// 			sizeInBytes: '9387',
// 			sprtChar: '\u0000',
// 			type: 'L_imagePdf',
// 			width: 756,
// 		},
// 		infilename: 'D:/WorkflowFolders/Input\\0815_pub_e_c_a_002.pdf.1606915159482.372954344147691016.nw',
// 		outFilename: '0815_pub_e_c_a_002.pdf',
// 		'passThrough:remoteFile': false,
// 		readFullPath: 'D:/WorkflowFolders/Input\\1127_pub_e_c_a_001.pdf',
// 		rename: false,
// 		srcDir: '',
// 	},
// };

module.exports = AbstractAction.extend({
  execute: function (selected) {
    const obj = selected[0];
    sandbox.request
      .execServerAction(this.nwid, 'EventInfoAction', obj.nwid, obj.type, this.module.projectorId, 'exec')
      .then(res => {
        const startParameters = {
          model: res.data,
          target: 'dialog',
          windowWidth: 600,
          windowHeight: 700,
          name: translate('Event Information'),
        };

        startInternalModule('EventInfoSetup', startParameters, this);
      })
  }
});
