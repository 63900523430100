import { translate } from 'core/services/localization';

export const labels = {
  normalizationParameters: translate('Normalization Parameters'),
  action: translate('Action'),
  ifPageMatches: translate('If Page Matches:'),
  normalizeSetupName: translate('Normalize setup name'),
  ifPageDoesNotMatch: translate('If page does not match'),
  skipToNextStep: translate('Skip to next step'),
  skipToNextStepAndWarn: translate('Skip to next step and warn'),
  moveToUnplanned: translate('Move to unplanned'),
  moveToUnplannedAndWarn: translate('Move to unplanned and warn'),
  fail: translate('Fail'),
  failAndWarn: translate('Fail and warn'),
  normalize: translate('Normalize'),
  none: translate('None'),
  warn: translate('Warn'),
  imageOriginBox: translate('Image Origin Box'),
  baseOn: translate('Base On:'),
  crop: translate('Crop'),
  x: translate('X:'),
  y: translate('Y:'),
  width: translate('Width:'),
  height: translate('Height:'),
  referencePoint: translate('Reference Point:'),
  rotate: translate('Rotate'),
  angle: translate('Angle:'),
  scale: translate('Scale'),
  keepProportions: translate('Keep Proportions'),
  impose: translate('Impose'),
  enableCrop: translate('Enable Crop'),
  enableRotate: translate('Enable Rotate'),
  enableScale: translate('Enable Scale'),
  enableImpose: translate('Enable Impose'),
  pleaseSelectARule: translate('Please select a rule in order to view/change Normalization Parameters'),
  cropToTrimBox: translate('Crop To Trim Box'),
};


export const IMAGE_ORIGIN_BOX_OPTIONS = [
  { text: translate('Media box'), value: 'mediabox' },
  { text: translate('Trim box'), value: 'trimbox' }
];

export const REFERENCE_POINT_OPTIONS = [
  { text: translate('Top-Left'), value: 'T L' },
  { text: translate('Top-Center'), value: 'T C' },
  { text: translate('Top-Right'), value: 'T R' },
  { text: translate('Center-Left'), value: 'C L' },
  { text: translate('Center-Center'), value: 'C C' },
  { text: translate('Center-Right'), value: 'C R' },
  { text: translate('Bottom-Left'), value: 'B L' },
  { text: translate('Bottom-Center'), value: 'B C' },
  { text: translate('Bottom-Right'), value: 'B R' }
];

export const ROTATE_OPTIONS = [
  { text: '90°', value: '1' },
  { text: '180°', value: '2' },
  { text: '270°', value: '3' }
];

const getTextByValue = (arr, value) => {
  return arr.find(item => item.value === value)?.text || '';
};

export const getReferencePointByValue = (value) => {

  return getTextByValue(REFERENCE_POINT_OPTIONS, value);
};

export const getRotateByValue = (value) => {
  return getTextByValue(ROTATE_OPTIONS, value);
};

export const getImageOriginBoxByValue = (value) => {

  return getTextByValue(IMAGE_ORIGIN_BOX_OPTIONS, value);
};