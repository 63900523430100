/**
 * @name MNIAdsTableView
 * @file MNIAdsTableView module
 *
 * @author Boris
 * @since: 2019-11-21
 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import { startInternalModule } from 'core/managers/module';
import sandbox, { jsUtils, localization } from 'sandbox';
import { fromServerDate } from 'core/dates';
import AbstractModule from 'AbstractModule';
import TickableModel from '../TickableModel';
import {
  createValueComparator,
  createObjectComparator,
  composeComparators,
  COMPARE_TYPE,
} from 'core/comparators';
import MNIAdsTable from './MNIAdsTable';
import { ALL, NONE, FILTER_BY } from './constants';
import { getPredefinedColumns, getMagazineColumns } from './columnsCreator';
import { ROW_HEIGHT, ROW_HEIGHT_INCLUDING_THUMBNAIL } from 'widgets/ReactDataGrid/utils';

const { translate } = localization;

const THROTTLE_WAIT = 1000;

const defaultComparator = createValueComparator();

const DEFAULT_COLUMNS_TO_SORT = [
  {
    key: 'label',
    ascending: true,
    compareType: COMPARE_TYPE.CASE_SENSITIVE,
  }
];

function mapColumnsByKeys(columns) {
  return (columns || []).reduce((acc, col) => {
    acc[col.key] = col;
    return acc;
  }, {});
}

function orderColumnsByKeys(keys, columns) {
  if (!Array.isArray(keys) || keys.length <= 0) {
    return columns;
  }

  const columnsByKeys = mapColumnsByKeys(columns);

  const orderedColumns = keys.reduce((acc, key) => {
    if (columnsByKeys[key]) {
      acc.push(columnsByKeys[key]);
      columnsByKeys[key] = false;
    }

    return acc;
  }, []);

  return orderedColumns.concat(columns.filter(col => !!columnsByKeys[col.key]));
}

module.exports = AbstractModule.extend({

  init: function () {
    this._super();
    this.render = this.render.bind(this);
  },

  initDone: function () {
    this.toolbar = this.createToolbar();
    this.reactRoot = createRoot(this.domElement);
    this.initToolbar();

    this.buildViewModelThrottled = jsUtils.throttle(this.buildViewModel, THROTTLE_WAIT, {
      leading: false,
      trailing: true
    });
  },

  firstTickReceived: function (data) {
    this.preferences = data.preferences || {};

    this.tickableModel = new TickableModel({});
    this.tickableModel.firstTickHandler(data.model);

    this.tableKey = 1;

    this.buildViewModel();
  },

  tickUpdate: function (data) {
    this.tickableModel.tickUpdateHandler(data.model);

    this.buildViewModelThrottled();
  },

  initToolbar: function () {
    this.toolbar.addItem({
      label: translate('Manage Table Columns'),
      name: 'manage_table_columns',
      _isApplicable: true,
      icon: 'mni_table_preferences',
      execute: () => {
        startInternalModule('ManageTableColumnsSetup', {
          rootId: '',
          rootType: '',
          name: 'Manage Table Columns',
          target: 'dialog',
          windowWidth: 400,
          windowHeight: 530,
          columns: this.predefinedColumns.map(c => ({
            columnKey: c.key,
            name: c.displayName,
            visible: c.visible,
            fixed: c.fixed,
          })),
          onSave: (columns) => {
            const columnsByKeys = mapColumnsByKeys(this.predefinedColumns);
            this.savePreferences({
              predefinedColumns: columns.map(c => {
                let width;
                if (columnsByKeys[c.columnKey]) {
                  width = columnsByKeys[c.columnKey].width;
                }

                return {
                  key: c.columnKey,
                  visible: c.visible,
                  fixed: c.fixed,
                  width
                };
              })
            });

            this.buildAdsTableColumns();
            this.tableKey++;
            this.render();
          },
        }, this);
      }
    });

    this.toolbar.addItem({
      label: translate('Manage Magazines Columns'),
      name: 'mni_magazine_columns',
      _isApplicable: true,
      icon: 'mni_magazine_columns',
      execute: () => {
        startInternalModule('ManageMagazinesColumnsSetup', {
          rootId: '',
          rootType: '',
          name: 'Manage Magazine Columns',
          target: 'dialog',
          windowWidth: 400,
          windowHeight: 550,
          magazines: this.magazineColumns.map(c => c.key),
          onSave: (magazines) => {
            const columnsByKeys = mapColumnsByKeys(this.magazineColumns);
            this.savePreferences({
              magazineColumns: magazines.map(m => {
                return {
                  key: m,
                };
              })
            });

            this.buildAdsTableColumns();
            this.tableKey++;
            this.render();
          },
        }, this);
      }
    });

  },

  buildAdsTableColumns: function () {
    this.adsTableColumns = this.buildPredefinedColumns().concat(this.buildMagazineColumns());
    const thumbnailColumnVisible = this.adsTableColumns.find(col => col.key === 'thumbnail')?.visible || false;
    this.rowHeight = thumbnailColumnVisible ? ROW_HEIGHT_INCLUDING_THUMBNAIL : ROW_HEIGHT;
    return this.adsTableColumns;
  },

  buildPredefinedColumns: function () {
    const prefKeys = (this.preferences.predefinedColumns || []).map(col => col.key);
    const prefColumnsByKeys = mapColumnsByKeys(this.preferences.predefinedColumns);

    this.predefinedColumns = orderColumnsByKeys(prefKeys, getPredefinedColumns(this.viewModel, this)).map(col => {
      const c = prefColumnsByKeys[col.key] || {};
      return {
        ...col,
        visible: typeof c.visible !== 'undefined' ? c.visible : true,
        width: typeof c.width !== 'undefined' ? c.width : col.width,
        fixed: c.fixed,
      };
    });

    return this.predefinedColumns;
  },

  buildMagazineColumns: function () {
    const prefKeys = (this.preferences.magazineColumns || []).map(col => col.key);
    this.magazineColumns = orderColumnsByKeys(prefKeys, getMagazineColumns(this.viewModel));

    return this.magazineColumns;
  },

  savePreferences: function (preferences) {
    if (!preferences) {
      return;
    }

    this.preferences = Object.assign(this.preferences, preferences);
    sandbox.preferences.savePreferences(this.getRequiredParameters(), this.preferences);
  },

  buildViewModel: function () {
    //***TEST BEGIN
    const t1 = performance.now();
    //***TEST END

    const model = this.tickableModel.model();

    const publication = {
      inputTime: model.InputTime ? fromServerDate(model.InputTime) : '',
      planVersion: model.PlanVersion,
      purgeAfter: model.Purge ? model.Purge + translate('days') : '90 days', //***TODO: fix it
    };

    const magazines = model.magazines ? JSON.parse(model.magazines).magazines : [];
    const magazineMap = magazines.reduce((acc, magazine) => {
      acc[magazine.displayName] = magazine;

      return acc;
    }, {});

    if (this.viewModel && this.viewModel.magazines.join('') !== magazines.join('')) {
      this.tableKey++;
      console.log('MNIAdsTableView.buildViewModel() => List of magazines has beed changed - re-render table!');
    }

    this.viewModel = {
      publication,
      pages: [],
      magazines,
      magazineMap,
      filterBy: this.viewModel ? this.viewModel.filterBy : [...FILTER_BY],
      columnsToSort: this.viewModel ? this.viewModel.columnsToSort : [...DEFAULT_COLUMNS_TO_SORT],
    };

    model.editions.forEach(edition => {
      edition.zones.forEach(zone => {
        zone.sections.forEach(section => {
          section.pages.forEach(p => {
            const page = { ...p };
            page.Coordinator = page.Coordinator.trim();
            page.magazines = page.magazines ? JSON.parse(page.magazines).magazines : [];
            page.magazineMap = page.magazines.reduce((acc, magazine) => {
              acc[magazine.displayName] = magazine;
              return acc;
            }, {});

            this.viewModel.pages.push(page);
          });
        });
      });
    });

    this.updateFilterBy();

    this.sortPages();

    this.filterPages();

    this.buildAdsTableColumns();

    //***TEST BEGIN
    const t2 = performance.now();
    console.log('### MNIAdsTableView.buildViewModel() ===', this.viewModel.pages.length, '=== pages');
    console.log('### MNIAdsTableView.buildViewModel() time = ', t2 - t1, 'ms');
    //***TEST END
  },

  updateFilterBy: function () {
    this.viewModel.filterBy.forEach(item => {
      item.selected = item.selected || ALL;
      if (item.key === 'magazines') {
        item.values = this.viewModel.magazines.map(mag => mag.displayName);
      } else {
        const valuesMap = this.viewModel.pages.reduce((acc, p) => {
          const value = p[item.key];
          if (item.separator) {
            (value ? value.split(item.separator) : []).reduce((acc2, val) => {
              acc2[val] = true;
              return acc2;
            }, acc);
          } else if (value) {
            acc[value] = true;
          }

          return acc;
        }, {});

        item.values = Object.keys(valuesMap);
      }

      item.values = item.values.sort(defaultComparator);
    });
  },

  sortPages: function () {
    const t1 = performance.now(); //***TEST

    this.viewModel.sortedPages = this.viewModel.sortedPages || this.viewModel.pages;
    if (this.viewModel.columnsToSort.length > 0) {
      const comparator = composeComparators(this.viewModel.columnsToSort.map(col =>
        createObjectComparator(col.valueGetter || col.key, col.compareType, col.ascending)
      ));

      this.viewModel.sortedPages = this.viewModel.sortedPages.sort(comparator);
    }

    //***TEST BEGIN
    const t2 = performance.now();
    console.log('### MNIAdsTableView.sortPages() time = ', t2 - t1, 'ms');
    //***TEST END
  },

  filterPages: function () {
    const t1 = performance.now(); //***TEST

    this.viewModel.pages = this.viewModel.sortedPages.filter(page => {
      let match = true;
      for (let item of this.viewModel.filterBy) {
        if (item.selected !== ALL) {
          let value = page[item.key];
          if (item.separator) {
            value = value ? value.split(item.separator) : [];
          }

          if (Array.isArray(value)) {
            match = match && (item.selected === NONE && value.length <= 0 ||
              item.childKey && value.findIndex(child => child[item.childKey] === item.selected) >= 0 ||
              value.indexOf(item.selected) >= 0);
          } else {
            match = match && (item.selected === NONE && value === '' || value === item.selected);
          }
        }

        if (!match) {
          break;
        }
      }

      return match;
    });

    //***TEST BEGIN
    const t2 = performance.now();
    console.log('### MNIAdsTableView.filterPages() time = ', t2 - t1, 'ms');
    //***TEST END

    // this.render();

    setTimeout(() => {
      const t1 = performance.now(); //***TEST

      this.render();

      //***TEST BEGIN
      const t2 = performance.now();
      console.log('### MNIAdsTableView.render() time = ', t2 - t1, 'ms');
      //***TEST END
    }, 0);
  },

  handleFilterSelect: function (filterKey, value) {
    //console.log('--- handleFilterSelect ---', filterKey, value);

    for (let item of this.viewModel.filterBy) {
      if (item.key === filterKey) {
        item.selected = value;
        break;
      }
    }

    this.updateSelected([]);

    this.filterPages();
  },

  handleResetFiltersClick: function () {
    this.updateSelected([]);

    this.viewModel.filterBy.forEach(item => {
      item.selected = ALL;
    });

    this.filterPages();
  },

  handleAdsTableSelect: function (selectedRows) {
    //console.log('--- handleAdsTableSelect ---', selectedRows);

    const pages = selectedRows.map(row => this.tickableModel.getByNwid(row.nwid));
    this.updateSelected(pages.filter(p => p));
  },

  handleAdsTableContextMenu: function (clickedRow, selectedRows, e) {
    //console.log('--- handleAdsTableContextMenu ---', clickedRow);

    this.showContextMenu(this.tickableModel.getByNwid(clickedRow.nwid), this.selected, e);
  },

  handleAdsTableColumnResize: function (columnsByKeys, columnKey) {
    //console.log('--- handleAdsTableColumnResize ---', columnsByKeys);

    this.savePreferences({
      predefinedColumns: this.predefinedColumns.map(c => ({
        key: c.key,
        visible: c.visible,
        fixed: c.fixed,
        width: columnsByKeys[c.key] ? columnsByKeys[c.key].width : undefined
      }))
    });
  },

  handleSort: function (columnKey, multiSort) {
    //console.log('--- handleSort ---', columnKey, multiSort);
    let column = this.viewModel.columnsToSort.find(col => col.key === columnKey);
    if (column) {
      column.ascending = !column.ascending;
      if (!multiSort) {
        this.viewModel.columnsToSort = [column];
      }
    } else {
      const col = this.predefinedColumns.find(col => col.key === columnKey);
      column = {
        key: col.key,
        valueGetter: col.valueGetter,
        compareType: col.compareType || COMPARE_TYPE.CASE_INSENSITIVE,
        ascending: true,
      };

      if (multiSort) {
        this.viewModel.columnsToSort.push(column);
      } else {
        this.viewModel.columnsToSort = [column];
      }
    }

    this.sortPages();
    this.filterPages();
  },

  handleAdsTableThumbnailDoubleClick(page) {
    //console.log('--- handleDoubleClickRow ---', page);
    this.navigateByViewLink(page);
  },

  render: function () {
    this.reactRoot.render(
      <MNIAdsTable
        module={this}
        viewModel={this.viewModel}
        adsTableColumns={this.adsTableColumns}
        tableKey={this.tableKey}
        currentRowHeight={this.rowHeight}
      />);
  }

});