import PropTypes from 'prop-types';

define(
  [
    'react',
    '../Button/Icon',
    '../Button/Button'
  ],
  function (React,
    Icon,
    Button) {

    "use strict";

    const {Component} = React;

    return class ListItem extends Component {

      static propTypes = {

        item: PropTypes.object,
        isSelected: PropTypes.bool,
        onClick: PropTypes.func,
        buttons: PropTypes.array,
        textProperty: PropTypes.string,
        valueProperty: PropTypes.string

      };

      static defaultProps = {
        isSelected: false,
        buttons: []
      };

      constructor(props) {
        super(props);
      }

      findButtonById = (buttonId) => {
        var {buttons} = this.props;
        var found = buttons.filter(function (button) {
          return button.id == buttonId;
        });

        return found[0];
      };

      handleClick = (e) => {

        var {onClick, item} = this.props;

        if (typeof onClick == 'function') {
          onClick(item.id);
        }

        e.preventDefault();
      };

      handleButtonClick = (buttonId) => {

        var {item} = this.props;
        var button = this.findButtonById(buttonId);
        if (typeof button.onClick === 'function') {
          button.onClick(item.id, buttonId);
        }
      };

      renderIcon = () => {
        var {item} = this.props;
        if (item.iconType === 'none') {
          return;
        }

        if (item.iconType === 'svg') {
          return (
            <svg className={item.iconClassName}>
              <use xlinkHref={item.iconUrl} />
            </svg>
          );
        }

        return (
          <span className={item.iconClassName}>
            {!item.imageSrc ? null : <img src={item.imageSrc} />}
          </span>
        );
      };

      renderButton = (button) => {
        var {icon, id, onClick, text, tooltip, imageSrc, className, textClassName, iconClassName, style, disabled, iconName, ...props} = button;

        if (icon) {
          return <Icon id={id} key={id} icon={icon} onClick={this.handleButtonClick}
            iconClassName="list-item-button" {...props} />;
        } else {
          return (
            <Button key={id}
              text={text}
              tooltip={tooltip}
              imageSrc={imageSrc}
              className={className}
              textClassName={textClassName}
              iconClassName={iconClassName}
              style={style}
              onClick={() => this.handleButtonClick(id)}
              disabled={disabled}
              iconName={iconName}
            />
          );
        }
      };

      renderButtons = () => {
        const {buttons} = this.props;
        if (buttons.length <= 0) {
          return null;
        }

        return (
          <div className='list-item-buttons'>
            {buttons.map((button) => this.renderButton(button))}
          </div>
        );
      };

      renderContent = () => {

        var {item} = this.props;
        var content = (typeof item.el === 'object' ? item.el : (typeof item.title !== 'undefined' ? item.title : null));

        return (
          <div className='list-item-content'>
            {content}
          </div>
        );

      }

      render() {
        var {item, isSelected} = this.props;

        var itemClassName = ['list-item'];
        if (isSelected) itemClassName.push('active');

        return (
          <li key={item.id} className={itemClassName.join(' ')} onClick={this.handleClick}>
            {this.renderIcon()}
            {this.renderContent()}
            {this.renderButtons()}
          </li>
        );
      }
    }
  });