define([], function () {
  var MAP_VALUES = {
      'flow': {
        '!default':{
          'flexDirection': 'row'
        },
        'left': {
          'flexDirection': 'row'
        },
        'right': {
          'flexDirection': 'row-reverse'
        }
      },
    },
    MAP_TAGS = [
      { tag: 'top', styleTags: ['top'] },
      { tag: 'left', styleTags: ['left'] },
      { tag: 'bottom', styleTags: ['bottom'] },
      { tag: 'right', styleTags: ['right'] },
      { tag: 'background', styleTags: ['background'] },
      //{ tag: 'transform', styleTags: ['transform', 'WebkitTransform'] },
      { tag: 'flow', styleTags: [MAP_VALUES.flow] },
      { tag: 'width', styleTags: ['width'] },
      { tag: 'height', styleTags: ['height'] },
      { tag: 'maxWidth', styleTags: ['maxWidth'] },
      { tag: 'maxHeight', styleTags: ['maxHeight'] }
    ];

  return MAP_TAGS;
});