import { BackgroundWorker } from './BackgroundWorker'

const intervalWorker = `self.onmessage = e => {
  const data = JSON.parse(e.data);
  if (data.message === 'start') {
    self.interval = setInterval(() => {
      postMessage(e.data);
    }, data.interval);
  } else {
    clearInterval(self.interval);
  }
}`

export class IntervalWorker {
  constructor() {
    this.worker = new BackgroundWorker(intervalWorker);
  };
  
  start = (callback, interval) => {
    this.stop();
    this.worker.onmessage(callback);
    this.worker.postMessage({ message: 'start', interval });
  };

  stop = () => this.worker.postMessage({ message: 'stop' });
};

