/**
 * Created by moshemal on 11/26/14.
 */


define(['AbstractAction'],
function (AbstractAction) {
	'use strict'


	return AbstractAction.extend({
		isApplicable: function(){
			return !this.module._status.isEditable;
		},
		execute: function () {
			this.module._editBinder();
			this.module._status.isEditable = true;
		}
	});
});