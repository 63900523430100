
export const measureMaxTextWidth = (strItems = [], font = '11px Verdana') => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  ctx.font = font;
  let maxWidth = 0;
  strItems.forEach(item => {
    const metrics = ctx.measureText(item);
    if (metrics.width > maxWidth) {
      maxWidth = metrics.width;
    }
  });
  
  canvas.remove();

  return Math.ceil(maxWidth);
}