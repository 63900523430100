import React from 'react';
import iconService from 'core/services/iconService';
import { translate } from 'core/services/localization';
import { DIFF_STATUS_TYPES_ICONS_SUCCESS, getStatusIndex, arrangeAdditionalStatuses, getLaneNameToDisplayNameMap, getExistingStatuses } from 'utilities/statuses';

const lanesMap = getLaneNameToDisplayNameMap();

export const AdditionalStatuses = ({ item }) => {
  const additionalStatuses = getExistingStatuses(item).filter(s => s.name !== 'default').map(s => {
    let icon, typeIcon;
    if (!s.flowStepType || typeof s.statusType === 'undefined') {
      icon = iconService.getGeneralIcon(null, 'empty');
    } else {
      icon = iconService.getTemplateIcon(s.flowStepType, 'tiny', s.flowStepIconName);
    };

    const statusTypeLowerCase = typeof s.statusType !== 'undefined' ? s.statusType.toLowerCase().trim() : undefined;

    if (!s.flowStepType || typeof s.statusType === 'undefined') {
      typeIcon = undefined;
    } else if (typeof DIFF_STATUS_TYPES_ICONS_SUCCESS[statusTypeLowerCase] !== 'undefined') {
      typeIcon = iconService.getModuleIcon(DIFF_STATUS_TYPES_ICONS_SUCCESS[statusTypeLowerCase].moduleName, DIFF_STATUS_TYPES_ICONS_SUCCESS[statusTypeLowerCase].icon);
    } else {
      typeIcon = undefined;
    };

    const statusIndex = getStatusIndex(s);

    return { icon, typeIcon, statusIndex, tooltip: lanesMap[s.name] };

  });

  const arrangedAdditionalStatuses = arrangeAdditionalStatuses(additionalStatuses).slice(0, 2);

  return arrangedAdditionalStatuses.length > 0 && <div className='additional-statuses-container'>
    {arrangedAdditionalStatuses.map((s, i) => <div key={i} className='additional-statuses'>
      {typeof s === 'undefined' || typeof s.typeIcon === 'undefined' ? <div className='additional-statuses-flowstep-icon' />
        : s.type === 'error' ?
          <svg className='additional-statuses-flowstep-icon'>
            <title>{translate('Error: Multiple statuses in the same position')}</title>
            <image href={iconService.getSVGIconPath('unsupported')} height={16} width={16} />
          </svg>
          : <React.Fragment>
            <img className='additional-statuses-flowstep-icon' title={s.tooltip} src={s.icon} />
            <img className='additional-statuses-status-icon' src={s.typeIcon} />
          </React.Fragment>
      }
    </div>
    )}
  </div>
};