import React from 'react';
import PropTypes from 'prop-types';
import { INCH, getConversionFactor, getUnitSymbol, getDecimalPlaces } from 'utilities/lengthUnits';
import ConversionOutput from './ConversionOutput';

export default function LengthOutput(
  {
    value,
    unit = INCH,
    className,
    style,
  }) {

  return (
    <ConversionOutput
      value={value}
      unitSymbol={getUnitSymbol(unit)}
      factor={getConversionFactor(unit)}
      decimalPlaces={getDecimalPlaces(unit)}
      className={className}
      style={style}
    />
  );
}

LengthOutput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  unit: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
};
