
define(['sandbox', 'AbstractAction'], function(sandbox, AbstractAction) {
  'use strict';
  function submitCallback(value, value2){
    var viewInstanceNwId = this.module.nwid;
    var deviceNwId = this.module.viewSettings.rootId;
    var projectorId = this.module.projectorId;
    var obj = this.module.selected[0];
    var type = obj["type"];
    var nwid = obj["nwid"];
    var request = sandbox.request.changePmt(viewInstanceNwId, this.nwid, deviceNwId, type, projectorId,
      {
        config: this.config,
        nwid: nwid,
        type: type,
        pmt: value,
        reloadPmts: value2.isSelected
      }
    );

    request.then(function (res) {
      var code = res.statusCode;
      if (code !== 200){
        var message = res.errorMessage;
        if (res !== null){
          sandbox.dialog.alert(message);
        }
      } else {
        sandbox.dialog.alert(res.data.message);
      }
    });
  }

  return AbstractAction.extend({

    isApplicable: function () {
      var objects = this.module.selected;
      if (typeof objects === 'undefined' || objects.length !== 1) {
        return false;
      }
      return (objects[0].isManualPlanChangeEnabled);
    },
    execute: function (objs) {
      var nwid, type;
      var viewInstanceNwId = this.module.nwid;
      var deviceNwId = this.module.viewSettings.rootId;
      var projectorId = this.module.projectorId;
      var obj = this.module.selected[0];
      type = obj["type"];
      nwid = obj["nwid"];
      var request = sandbox.request.getPmtOptions(viewInstanceNwId, this.nwid, deviceNwId, type, projectorId,
        {
          config: this.config,
          nwid: nwid,
          type: type
        }
      );
      var that = this;
      request.then(function (res) {
        var code = res.statusCode;
        if (code === 200){
          var options = res.data.options;
          var selected = res.data.selected;
          options.some(function(element){
            if(element.value === selected){
              element.selected = true;
              return true;
            }
            return false;
          });

          var rows = [
            {
              tag: "select",
  			      label: sandbox.localization.translate("select Pmt"),
              options: options
            },
            {
               tag: "input",
               type: "checkbox",
               label: sandbox.localization.translate("Reload Pmts"),
               checked: false,
               value: "reloadPmts"
            }
          ];
          var str = sandbox.localization.translate("Change plate template");
		  var name;
		  if (type === "form/separation") {
			name = obj.parent["formName"] + ' ' + obj["separation"];
		  }
		  else {
			name = obj["name"];
		  }
          sandbox.dialog.showFormDialog(rows, submitCallback, that, str + ' - ' + name, sandbox.localization.translate('Plate Template'), '');
        }
      });

    }
  });
});