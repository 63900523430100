import React, { Component } from 'react';
import sandbox from 'sandbox';
import PropTypes from 'prop-types';

const linkIcon = sandbox.icons.getModuleIcon('MyBirdeye', 'link');

module.exports = class SeperationPreview extends Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    colorName: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    shared: PropTypes.bool,
    planned: PropTypes.bool,
    shape: PropTypes.string, //square or circle
    filled: PropTypes.bool
  }

  static defaultProps = {
    shared: false,
    planned: true,
    shape: 'square',
    filled: true
  }

  render() {
    const { colorName, className, style, shared, planned, shape, filled } = this.props;
    const colorNameObj = sandbox.colors.getColorByName(colorName);
    const colorRGB = sandbox.colors.getUIColorRGB(colorNameObj);
    const sharedIcon = shared ? <img className='sharedIcon' src={linkIcon} /> : "";
    const plannedIcon = !planned ? <span className="plannedIcon">?</span> : "";
    const shapeClassName = shape === 'square' ? 'square' : 'circle';
    let inlineStyle = filled ? { backgroundColor: colorRGB, borderColor: colorRGB } : { borderColor: colorRGB };

    for (var i in style) {
      inlineStyle[i] = style[i];
    }

    return <span className={`crtx-separation-preview separation-preview-component ${className}`}>
      {sharedIcon}
      <span className={`separation-preview ${shapeClassName}`} style={inlineStyle} title={colorName || ''}>
        {plannedIcon}
      </span>
    </span>;
  }

}