import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { isUndefined, isFunction, absolutePath, get, set } from './consts';
import Store from './Store';

const defaultDataConverter = model => model;

const FormFactory = (DecoratedComponent) => {//, handleDataReceived = (model) => model, handleApply = (model) => model) => {
  const ObserverDecoratedComponent = observer(DecoratedComponent);
  class Form extends Component {
    static propTypes = {
      store: PropTypes.any,
      path: PropTypes.string,
      onApply: PropTypes.func
    }
    static contextTypes = {
      store: PropTypes.any,
      path: PropTypes.string,
      register: PropTypes.func
    }
    static childContextTypes = {
      store: PropTypes.any,
      path: PropTypes.string,
      register: PropTypes.func,
      registerValidator: PropTypes.func
    }
    static defaultProps = {
      path: '',
      onApply: () => { }
    }

    constructor(props, context) {
      super(props);

      this.setBinders.call(this);

      this.store = props.store || context.store;
      this.path = absolutePath(context.path, props.path);
      this.decoratedComponent = undefined;
      this.childForms = [];
      this.validators = [];
      this.unregister = this.registerToParentForm(props, context);
      this.state = {
        mounted: false
      };

      // this.store.setState(this.path, handleDataReceived(this.store.getState(this.path)));
    }

    setBinders() {
      this.registerToParentForm = this.registerToParentForm.bind(this);
      this.removeChildForm = this.removeChildForm.bind(this);
      this.registerChildForm = this.registerChildForm.bind(this);
      this.removeValidator = this.removeValidator.bind(this);
      this.registerValidator = this.registerValidator.bind(this);
      this.validateForm = this.validateForm.bind(this);
      this.validateChildForms = this.validateChildForms.bind(this);
      this.validate = this.validate.bind(this);
      this.triggerApply = this.triggerApply.bind(this);
    }

    registerToParentForm(props, context) {
      const register = context.register;

      if (!isFunction(register)) return;

      return register(this);
    }

    removeChildForm(formHOC) {
      return function () {
        this.childForms = this.childForms.filter(f => f !== formHOC);
      }.bind(this);
    }

    registerChildForm(formHOC) {
      this.childForms = this.childForms.concat(formHOC);
      return this.removeChildForm(formHOC);
    }

    removeValidator(validator) {
      return function () {
        this.validators = this.validators.filter(v => v !== validator);
      }.bind(this);
    }

    registerValidator(validator) {
      this.validators = this.validators.concat(validator);
      return this.removeValidator(validator);
    }

    validateForm() {
      return this.validators.reduce((isValid, validator) => {
        return validator.validate() && isValid;
      }, true);
    }

    validateChildForms() {
      return this.childForms.reduce((isChildformValid, childFormHOC) => {
        return !isChildformValid ? false : childFormHOC.validate();
      }, true);
    }

    validate() {
      return this.validateForm() && this.validateChildForms();
    }

    triggerApply(returnIsValid = false) {
      const { onApply } = this.props;
      const decoratedComponent = this.decoratedComponent;
      const handleApply = DecoratedComponent.onApply || defaultDataConverter;
      const isValid = this.validate();
      if (!isValid) {
        onApply(undefined, false, ...arguments);
        return undefined;
      }

      let model = handleApply(
        this.childForms.reduce((accModel, childFormHOC) => {
          const childFormPath = childFormHOC.path;
          return set(accModel, childFormPath, childFormHOC.triggerApply());
        }, toJS(this.store.getState(this.path)))
      );

      onApply(model, true, ...arguments);

      if (returnIsValid) {
        return {
          model,
          isValid
        };
      }

      return model;
    }

    getChildContext() {
      return {
        store: this.store,
        path: this.path,
        register: this.registerChildForm,
        registerValidator: this.registerValidator
      };
    }
    
    componentDidMount() {
      const handleDataReceived = DecoratedComponent.onDataReceived || defaultDataConverter;
      this.store.setState(this.path, handleDataReceived(this.store.getState(this.path), this.props));
      this.setState({
        mounted: true
      });
    }

    componentWillUnmount() {
      if (isFunction(this.unregister)) {
        this.unregister();
      }
    }

    render() {
      const { onApply, children, ...props } = this.props;
      const model = this.store.getState(this.path);

      if (!this.state.mounted) return null;

      return <ObserverDecoratedComponent ref={(comp) => { this.decoratedComponent = comp; }} {...props} model={model} apply={this.triggerApply}>
        {children}
      </ObserverDecoratedComponent>;
    }
  }

  return Form;
};

module.exports = FormFactory;