import React, { useReducer } from 'react';
import { translate } from 'core/services/localization';
import settingsManager from 'core/managers/settings';
import { Dialog, DialogButtonsBar } from 'components/common/dialogs';
import TextInput from 'components/common/inputs/TextInput';
import Button from 'components/common/buttons/Button';
import Dropdown from 'components/common/dropdown/Dropdown';
import Checkbox from 'components/common/inputs/Checkbox';
import LengthInput from 'components/common/inputs/LengthInput';
import { Validator, useValidator } from 'components/private/forms';
import { IMPOSITION_TYPES, ORIENTATION } from './constants';

const initialState = {
  elementType: 'layoutGroup',
  layoutLinks: [],
  name: translate('New Layout Group'),
  impositionType: '',
  orientation: 'vertical',
  enableCreep: false,
  creepLimit: '',
};

export const AddEditLayoutGroupDialog = (
  {
    title,
    editMode,
    targetLayoutGroup,
    onClose,
    onAddEditLayoutGroup,
  }) => {

  const init = initialState => {
    const state = {
      ...initialState,
      ...targetLayoutGroup,
    };

    state.enableCreep = !isNaN(state.creepLimit) && state.creepLimit !== '';

    return state;
  };

  const [state, setFieldValue] = useReducer((state, action) => {
    return {
      ...state,
      ...action
    }
  }, initialState, init);

  const { register, validate } = useValidator();

  const handleCreateEditLayoutGroup = () => {
    if (!validate()) {
      return;
    }

    let group = {
      ...state,
      orientation: state.impositionType === 'tabs' ? state.orientation : undefined,
      creepLimit: state.enableCreep && state.creepLimit !== '' ? Number(state.creepLimit) : undefined,
      enableCreep: undefined,
    };

    onAddEditLayoutGroup(group);
    onClose();
  };

  return <Dialog
    onClose={onClose}
    title={title}
    modal={true}
    initialWidth={360}>

    <div className='crtx-form-section-container'>
      <div className='crtx-form-section'>
        <div className='crtx-form-two-columns'>

          <label>{translate('Name')}:</label>
          <Validator register={register} rules={['required']} className={'crtx-form-element-full-width-validator'}>
            <TextInput className='crtx-form-element-full-width' value={state.name} onChange={(e, value) => {
              setFieldValue({ name: value });
            }} />
          </Validator>

          <label>{translate('Imposition Type')}:</label>
          <Validator register={register} rules={['required']} className={'crtx-form-element-full-width-validator'}>
            <Dropdown
              className='crtx-form-element-full-width' options={IMPOSITION_TYPES} value={state.impositionType}
              onSelect={(event, value) => {
                setFieldValue({ impositionType: value });
              }} />
          </Validator>

          {state.impositionType === 'tabs' &&
          <React.Fragment>
            <label>{translate('Orientation')}:</label>
            <Dropdown
              className='crtx-form-element-full-width' options={ORIENTATION} value={state.orientation}
              onSelect={(event, value) => {
                setFieldValue({ orientation: value });
              }} />
          </React.Fragment>
          }

          <label className='crtx-form-label-with-checkbox'>
            <Checkbox
              checked={state.enableCreep}
              onChange={(e, value) => {
                setFieldValue({ enableCreep: value });
              }} />
            {translate('Creep Limit')}
          </label>

          {state.enableCreep &&
          <LengthInput
            className='crtx-form-element-full-width'
            unit={settingsManager.getLengthUnit()}
            value={state.creepLimit}
            allowEmptyValue={true}
            onChange={(event, value) => {
              setFieldValue({ creepLimit: value })
            }}
          />
          }

        </div>
      </div>
    </div>
    <DialogButtonsBar>
      <Button className={'primary'} onClick={handleCreateEditLayoutGroup}>
        {editMode ? translate('Save Layout Group') : translate('Create Layout Group')}
      </Button>
    </DialogButtonsBar>
  </Dialog>
};