/**
 * @name reducersCommon
 * @file Defines reducers common functionality
 *
 * @author Boris
 * @since: 2016-09-22
 */

export const groupElementsByType = (state, typesToExclude) => {
  var elementsByType = {};
  const groupElementsByTypeInner = (parent) => {
    if (!parent || typeof parent !== 'object') {
      return;
    }

    var elementType = parent.elementType;
    if (typeof elementType !== 'undefined') {
      if (!elementsByType[elementType]) {
        elementsByType[elementType] = []
      }

      elementsByType[elementType].push(parent);
    }

    if (!typesToExclude || typesToExclude.indexOf(elementType) < 0) {
      for (var key in parent) {
        if (key !== 'shape') {
          var obj = parent[key];
          if (obj && typeof obj === 'object') {
            groupElementsByTypeInner(obj);
          }
        }
      }
    }
  };

  groupElementsByTypeInner(state.layout);

  return elementsByType;
};

