import { startModule } from 'core/managers/module';
import actionUtils from 'actions/actionUtils';

module.exports = function (action, objects, parameters) {
  const obj = Array.isArray(objects) && objects[0];
  const viewDefName = actionUtils.deduceViewDefinitionName(obj, action.parameters);
  const viewInfo = actionUtils.findViewInfoForAction(action.nwid, viewDefName);
  const root = obj || {};

  startModule(viewInfo.nwid, action.module.id, {
    ...viewInfo,
    ...parameters,
    target: viewInfo.target === 'main' ? 'new' : viewInfo.target,
    rootId: root.nwid || action.module.viewSettings.rootId,
    rootType: root.type || action.module.viewSettings.rootType,
    rootName: root.name,
    rootLabel: root.label,
    initiatingActionNwid: action.nwid,
    win: action.module.win,
    actionConfig: action.config
  });
};
