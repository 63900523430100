/**
 * @name
 * @fileOverview
 * @author sergey & Elad
 */
define(['ember', 'text!../templates/edition.hbs', 'sandbox'],
  function (Ember, EditionTemplate, sandbox) {
    'use strict';

    return Ember.View.extend({
      layout: Ember.Handlebars.compile(EditionTemplate),

      //isChecked: function (){
      //  return this.get("controller").isEditionSelected(this, this.get('model'));
      //}.property(),

      disabledStyle: function (){
        if (this.model.disabled){
          return "color:silver;pointer-events: none";
        }
        return "";
      }.property(),

      okIconState: function () {
        return this.model.selected ? sandbox.icons.getModuleIcon("PlanningWizard", "ok_selected") :
          sandbox.icons.getModuleIcon("PlanningWizard", "ok");
      }.property('this.model.selected'),

      disabledDivStyle: function () {
        if (this.model.disabled){
          return "pointer-events: none";
        }
        return "";
      }.property(),

      isLoadOption: function (){
        if (this.model.loadOption){
          return "visibility:visible;padding-right: 5px";
        }
        return "visibility:hidden;padding-right: 5px";
      }.property('this.model.loadOption'),

      click: function (evt) {
        var checkBox = evt.target;
        if (checkBox.tagName !== "INPUT"){
          return;
        }
        var controller = this.get("controller");

        controller.editionActionDone(this.get('model'), checkBox.checked);
      }

    });
  });