import React, { Component } from 'react';
import sandbox from 'sandbox';
import ReactDataGrid from 'widgets/ReactDataGrid/src/ReactDataGrid';
import SimpleForm from 'widgets/SimpleForm/src/index';
import idWorker from 'core/workers/idWorker';
import Tabs from './Tabs';
import cells from '../cells';
import PropTypes from 'prop-types';

const { Form, Row, Table, Container } = SimpleForm;
const { Column } = ReactDataGrid;
const { SortableHeader, SeparatorHeader } = ReactDataGrid.headers;
const { SeparatorCell } = ReactDataGrid.cells;
const { translate } = sandbox.localization;
const headerHeight = 31;
const rowHeight = 30;

const createDisabledColumn = (name, displayName, editable) => {
  return {
    defaultValue: '',
    displayName,
    editable,
    fieldType: 'text',
    freeText: true,
    name,
    values: []
  };
};

const breadcrumbsColumns = (table) => {
  return table.breadcrumbs;
};

const columnDefinition = (store, objects, columnType, width = 140) => {
  return (column) => {
    const fieldType = column.fieldType;
    return cells(fieldType, store, objects, columnType, column);
  };
};
const separatorColumn = () => {
  return {
    title: '',
    columnKey: '_separator_columnKey',
    sortable: false,
    autowidth: false,
    resizable: false,
    width: 5,
    header: <SeparatorHeader></SeparatorHeader>,
    cell: SeparatorCell,
    backgroundColor: '#a7aeb7'
  };
};
const columnsDefinition = (store, table, breadcrumbs, objects, values) => {
  let columns = breadcrumbs.map(columnDefinition(store, objects, 'breadcrumbsColumns', 300));
  columns = columns.concat(separatorColumn());
  columns = columns.concat(values.map(columnDefinition(store, objects, 'values')));
  return columns;
};

module.exports = class OverridesTables extends Component {
  constructor(props) {
    super(props);
    this.handleTabClick = this.handleTabClick.bind(this);
  }

  static propTypes = {
    store: PropTypes.any
  }

  handleTabClick(tabIndex) {
    const store = this.props.store;

    store.dispatch({
      type: 'UPDATE',
      path: 'currentTableIndex',
      value: tabIndex
    });
  }

  render() {
    const store = this.props.store;
    const tables = store.get('tables') || [];
    if(tables.length === 0){
      return <div className='conversion-tables-view-no-tables'>{sandbox.localization.translate('No tables to override')}</div>
    }
    const currentTableIndex = store.get('currentTableIndex');
    const tabs = store.get('tabs') || [];
    const modifiedTabs = store.get('modifiedTabs') || {};
    const tablePath = `tables[${currentTableIndex}]`;
    // const tableNwid = `${tablePath}.nwid`;
    const objectsPath = `${tablePath}.objects`;
    const table = store.get(tablePath);
    const breadcrumbs = store.get(`${tablePath}.breadcrumbs`) || [];
    const values = store.get(`${tablePath}.values`) || [];
    const objects = store.get(objectsPath) || [];

    return <Form className="conversion-tables-view" store={store}>
      <Container className="tabs-simple-form-container">
        <Tabs bind="tabs" modifiedTabs={modifiedTabs} currentTabIndex={currentTableIndex} onClick={this.handleTabClick} />
      </Container>
      <Container className="table-simple-form-container">
        <Table key={currentTableIndex}
          columnKey={'breadcrumbs'}
          headerHeight={headerHeight}
          rowHeight={rowHeight}
          virtualScroll={true}
          bind={objectsPath}
          selectableRows={false}
          multiSelect={false}
          columnDefinitions={columnsDefinition(store, table, breadcrumbs, objects, values)}
          fullHeight={true}
        />
      </Container>
    </Form>;

  }

}
