/**
 * @name actions
 * @file Actions manager is in charge of loading and managing actions
 *
 * @author Boris
 * @since: 2019-06-25
 */

import appPromises from 'core/managers/appPromises';
import viewManager from 'core/managers/views';
import { ActionContainer } from 'utilities/betaFeatures';
import logger from 'logger';

let actionInfoMap;
const log = logger.getDefaultLogger();

/**
 * Initialize actions map from the data received from the server
 * Action map provides quick access to the action info by action nwid.
 */
appPromises.getPromise(appPromises.states.LOGGED_IN).then(data => {
  actionInfoMap = Object.keys(data.folders).reduce((acc, folderNwid) => {
    data.folders[folderNwid].actions.reduce((acc2, action) => {
      action.folderNwid = folderNwid;
      acc2[action.nwid] = action;

      return acc2;
    }, acc);

    return acc;
  }, {});
});

/**
 * Returns action class by specified action JS file name
 * @param  {string} actionClass - file name of an action
 * @return {object} action class
 */
function requireAction(actionClass) {
  //log.info("loading: " + actionClass);

  let action = null;

  try {
    action = require('actions/server/' + actionClass);
  } catch (err) {
    log.warn('actions.requireAction(): action fiile ' + actionClass + ' has been not found');
    action = null;
  }

  return action !== null ? action.default || action : null;
}

/**
 * Loads action instance by specified action JS file name
 * @param  {string} actionClass - file name of an action to be loaded
 * @return {object} action instance
 */
function loadAction(actionClass) {
  let actionInstance = null;

  const Action = requireAction(actionClass);
  if (typeof Action === 'function') {
    actionInstance = new Action();

    if (actionInstance instanceof ActionContainer) {
      const actionInstanceFromBetaFeature = actionInstance.loadFeture();
      if (actionInstanceFromBetaFeature) {
        actionInstance = new actionInstanceFromBetaFeature();
      }
    }
  } else {
    log.warn('actions.loadAction(): ' + actionClass + ' is not a class');
  }

  return actionInstance;
}

/**
 * Creates action instance by the given action nwid.
 * Returns action instance enhanced with the action info properties and reference to the corresponding module.
 * @param  {string} nwid - action nwid
 * @param  {object} module - reference to the module object
 * @return {object} action instance
 */
function createAction(nwid, module) {
  const actionInfo = actionInfoMap[nwid];
  if (!actionInfo || !actionInfo.actionClass) {
    log.warn('actions.createAction(): Action with nwid ' + nwid + ' has been not found');
    return null;
  }

  let action = loadAction(actionInfo.actionClass);
  if (action !== null) {
    Object.assign(action, actionInfo);
    action.module = module;
    action.initDone();
  }

  return action;
}


/**
 * Get action info by the given action nwid.
 * Returns cloned action info to ensure that the original data won't be changed.
 *
 * @param  {string} nwid - action nwid
 * @return {object} - cloned action info or null if the action was not found
 */
function getActionInfo(nwid) {
  const actionInfo = actionInfoMap[nwid];

  return actionInfo ? { ...actionInfo } : null;
}

/**
 * Returns all actions info that fit the provided view.
 * @param  {string} viewNwid - view nwid
 * @return {array} - array of all actions info that can be applied from the given View
 */
function getViewActionsInfo(viewNwid) {
  let result = {
    local: [],
    global: []
  };

  const viewInfo = viewManager.getViewInfo(viewNwid);
  if (viewInfo && viewInfo.folderNwid) {
    result = Object.values(actionInfoMap).reduce((acc, actionInfo) => {
      if (actionInfo.folderNwid === viewInfo.folderNwid && actionInfo.views) {
        if (actionInfo.views.indexOf(viewNwid) >= 0) {
          acc.local.push({ ...actionInfo });
        } else if (actionInfo.views === 'all') {
          acc.global.push({ ...actionInfo });
        }
      }

      return acc;
    }, result);
  }

  return result.local.concat(result.global);
}

module.exports = {
  _name: 'actions',
  _type: 'manager',
  loadAction,
  createAction,
  getActionInfo,
  getViewActionsInfo
};