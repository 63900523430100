import { createObjectComparator, COMPARE_TYPE } from 'core/comparators';
import { ALL_LANES } from 'utilities/statuses';
import { fromServerDate } from 'core/dates';
import settingsManager from 'core/managers/settings';
import { getColorOrderByNameMapping } from 'core/services/colorService';

const getPageContentProps = ({
  contentVersionMajorIndex,
  externalVersion,
  hasKillMark,
  id,
  isPanorama,
  isPreApproved,
  isSetComplete,
  mainVersionInputFileName,
  nwid,
  preflightReportInfo,
  type,
  versionNwid,
  viewLinks,
  customIcons,
  completionState,
  defaultStatus,
  pageLane1Status,
  pageLane2Status,
  pageLane3Status,
  aggregatedHoldType
}) => ({
  contentVersionMajorIndex,
  externalVersion,
  hasKillMark,
  id,
  isPanorama,
  isPreApproved,
  isSetComplete,
  mainVersionInputFileName,
  nwid,
  preflightReportInfo,
  type,
  versionNwid,
  viewLinks,
  customIcons,
  completionState,
  defaultStatus,
  pageLane1Status,
  pageLane2Status,
  pageLane3Status,
  aggregatedHoldType
});

const getFormContentProps = ({
  contentVersionMajorIndex,
  externalVersion,
  id,
  isPreApproved,
  isSetComplete,
  mainVersionInputFileName,
  nwid,
  plateSize,
  type,
  versionNwid,
  viewLinks,
  customIcons,
  completionState,
  defaultStatus,
  formLane1Status,
  formLane2Status,
  formLane3Status,
  aggregatedHoldType
}) => ({
  contentVersionMajorIndex,
  externalVersion,
  id,
  isPreApproved,
  isSetComplete,
  mainVersionInputFileName,
  nwid,
  plateSize,
  type,
  versionNwid,
  viewLinks,
  customIcons,
  completionState,
  defaultStatus,
  formLane1Status,
  formLane2Status,
  formLane3Status,
  aggregatedHoldType
});

const getPagePropsFromContent = ({
  completionState,
  completionTime,
  defaultStatus,
  pageLane1Status,
  pageLane2Status,
  pageLane3Status,
  aggregatedHoldType
}) => ({
  completionState,
  completionTime,
  defaultStatus,
  pageLane1Status,
  pageLane2Status,
  pageLane3Status,
  aggregatedHoldType
});

const getFormPropsFromContent = ({
  completionState,
  completionTime,
  defaultStatus,
  formLane1Status,
  formLane2Status,
  formLane3Status,
  aggregatedHoldType
}) => ({
  completionState,
  completionTime,
  defaultStatus,
  formLane1Status,
  formLane2Status,
  formLane3Status,
  aggregatedHoldType
});

const createUpdatedPage = originalPage => {
  const updatedPage = { ...originalPage, ...getPagePropsFromContent(originalPage.pageContent) };
  if (typeof originalPage.__parent !== 'undefined') {
    Object.defineProperty(updatedPage, '__parent', {
      enumerable: false,
      value: originalPage.__parent,
      writable: false,
      configurable: false
    });
  }
  Object.defineProperty(updatedPage, '__isDirty', {
    enumerable: false,
    writable: true,
    value: originalPage.pageContent.__isDirty
  });

  return updatedPage;
};

const createUpdatedForm = originalForm => {
  const updatedForm = { ...originalForm, ...getFormPropsFromContent(originalForm.formContent) };
  if (typeof originalForm.__parent !== 'undefined') {
    Object.defineProperty(updatedForm, '__parent', {
      enumerable: false,
      value: originalForm.__parent,
      writable: false,
      configurable: false
    });
  }
  Object.defineProperty(updatedForm, '__isDirty', {
    enumerable: false,
    writable: true,
    value: originalForm.formContent.__isDirty
  });

  return updatedForm;
};

const getAggregatedCompletionState = samePagesArr => {
  let aggregatedCompletionState = 'none';
  const firstPage = samePagesArr[0];
  if (samePagesArr.every(sp => sp.completionState === firstPage.completionState)) {
    aggregatedCompletionState = firstPage.completionState;
  } else if (firstPage.completionState === 'error' || samePagesArr.some(sp => sp.completionState === 'error')) {
    aggregatedCompletionState = 'error';
  } else if (firstPage.completionState === 'reject' || samePagesArr.some(sp => sp.completionState === 'reject')) {
    aggregatedCompletionState = 'reject';
  } else if (firstPage.completionState === 'waiting' || samePagesArr.some(sp => sp.completionState === 'waiting')) {
    aggregatedCompletionState = 'waiting';
  } else if (firstPage.completionState === 'inProgress' || samePagesArr.some(sp => sp.completionState === 'inProgress')) {
    aggregatedCompletionState = 'inProgress';
  }

  return aggregatedCompletionState;
};

const getPageAggregatedCustomIcons = samePagesArr => {
  const aggregatedCustomIcons = samePagesArr.reduce((acc, sp) => {
    if (typeof sp.pageContent?.customIcons !== 'undefined') {
      acc = { ...acc, ...sp.pageContent.customIcons };
    }
    return acc;
  }, {});

  return aggregatedCustomIcons;
};

const getFormsAggregatedCustomIcons = sameFormsArr => {
  const aggregatedCustomIcons = sameFormsArr.reduce((acc, sp) => {
    if (typeof sp.formContent?.customIcons !== 'undefined') {
      acc = { ...acc, ...sp.formContent.customIcons };
    }
    return acc;
  }, {});

  return aggregatedCustomIcons;
};

const getAggregatedSeparations = (samePagesArr, separations) => {
  const aggregatedSeparations = samePagesArr.reduce((acc, page) => {
    const filteredSeparations = page.separations.filter(sep => acc.every(a => a.name !== sep.name));
    return [...acc, ...filteredSeparations];
  }, separations);

  return aggregatedSeparations.map(sep => ({ ...sep, defaultStatus: undefined })).sort(createObjectComparator('name'));
};

const getThumbnailToDisplay = samePagesArr => {
  let thumbnailToDisplay = samePagesArr[0];
  const completionStatesPriority = {
    reject: 4,
    error: 4,
    waiting: 3,
    inProgress: 2,
    success: 1,
    none: 0,
  };
  for (const element of samePagesArr) {
    if (completionStatesPriority[element.completionState] > completionStatesPriority[thumbnailToDisplay.completionState] ||
      (completionStatesPriority[element.completionState] !== 0 &&
        completionStatesPriority[element.completionState] === completionStatesPriority[thumbnailToDisplay.completionState] &&
        fromServerDate(element.completionTime) > fromServerDate(thumbnailToDisplay.completionTime))) {
      thumbnailToDisplay = element;
    }
  }
  return cloneModel(thumbnailToDisplay);
};

const areDifferentVersions = (contentItems, version) =>
  contentItems.some(content => content.externalVersion !== version && (version > 1 || content.externalVersion > 1));

const getAggregatedVersion = (contentItems, content) => {
  let aggregatedVersion = content.externalVersion;

  if (areDifferentVersions(contentItems, aggregatedVersion)) {
    aggregatedVersion = '*';
  }

  return aggregatedVersion;
};

const getAggregatedIndications = (item, indications, variants, controller) => {
  indications.forEach(indication => {
    const indicationName = indication.name;

    if (indicationName === 'hold') {
      item.holdType = variants.find(v => v.holdType !== 'none')?.holdType || '';
      item.aggregatedHoldType = variants.find(v => v.aggregatedHoldType && v.aggregatedHoldType !== 'none')?.aggregatedHoldType || '';
    } else if (indicationName === 'preflight') {
      variants.forEach(v => {
        const reportType = v.pageContent && v.pageContent.preflightReportInfo ?
          v.pageContent.preflightReportInfo.preflightReportType :
          undefined;
        if (reportType && !item.pageContent.preflightReportInfo.preflightReportType) {
          item.pageContent.preflightReportInfo.preflightReportType = reportType;
        }
      });
    } else if (indicationName === 'lock') {
      if (!controller.isLocked(item)) {
        const lockedVariant = variants.find(v => controller.isLocked(v));
        if (lockedVariant) {
          item.allowedResourcesAndGroups = lockedVariant.allowedResourcesAndGroups;
        }
      }
    } else if (indicationName === 'preapproved') {
      if (!controller.isPreApproved(item)) {
        const preApprovedVariant = variants.find(v => controller.isPreApproved(v));
        if (preApprovedVariant) {
          if (item.type === 'page') {
            item.pageContent.isPreApproved = preApprovedVariant.pageContent.isPreApproved;
          } else {
            item.formContent.isPreApproved = preApprovedVariant.formContent.isPreApproved;
          }
        }
      }
    } else if (indicationName === 'skipFlowStep') {
      const skipSteps = item.skipSteps !== 'false' || item.skipSteps === 'true' ? true : false;
      if (!skipSteps) {
        const skipstepsVariant = variants.find(v => v.skipSteps === true || v.skipSteps === 'true' ? true : false);
        if (skipstepsVariant) {
          const { skippedSteps = [] } = skipstepsVariant.skipStepIds || {};
          item.skipSteps = skipstepsVariant.skipSteps;
          item.skipStepIds = skippedSteps;
        }
      }
    } else if (indicationName === 'newContentVersionIndicator') {
      const variantWithNewContentVersion = variants.find(v => controller.pagesWithNewContentVersion(v).length > 0);
      if (variantWithNewContentVersion) {
        item.pagesLabelsArr = controller.pagesWithNewContentVersion(variantWithNewContentVersion);
        item.showNewContentVersionIndicator = true;
      }
    } else if (indicationName === 'multiApprovers') {
      const variantWithApprovalIndication = variants.find(v => v.approvalIndication?.length > 0);
      if (variantWithApprovalIndication) {
        item.approvalIndication = variantWithApprovalIndication.approvalIndication;
      }
    }
  });

  return item;
};

const getAggregatedId = items => items.map(item => item.id).join('-');

const getAggregatedHoldType = holdTypes => {
  let holdType = holdTypes[0];
  for (let index = 1; index < holdTypes.length; index++) {
    if (holdTypes[index] !== holdType) {
      holdType = 'partial';
      break;
    }
  }
  return holdType;
};
const createAggregatePage = (samePagesArr, controller, pageDefinition) => {
  const completionState = getAggregatedCompletionState(samePagesArr);
  const pagesWithPreflightIndication = samePagesArr.filter(p => p.pageContent?.preflightReportInfo?.preflightReportType === 1 || p.pageContent?.preflightReportInfo?.preflightReportType === 2);
  let preflightReportInfo = {};
  if (pagesWithPreflightIndication.length > 0 && !(completionState === 'error' || completionState === 'reject')) {
    for (const p of pagesWithPreflightIndication) {
      preflightReportInfo = p.pageContent?.preflightReportInfo;
      if (preflightReportInfo.preflightReportType === 2) {
        break;
      }
    }
  }
  const indications = pageDefinition.single.segments.find(seg => seg.location === 'top-right').elements[0].indications;
  const customIcons = getPageAggregatedCustomIcons(samePagesArr);
  let pageToDisplay = getThumbnailToDisplay(samePagesArr);
  pageToDisplay = getAggregatedIndications(pageToDisplay, indications, samePagesArr, controller);
  const statusesObj = ALL_LANES.reduce((acc, lane) => {
    if (pageToDisplay[lane]) {
      acc[lane] = undefined;
    }
    return acc;
  }, {});
  const aggregatedId = getAggregatedId(samePagesArr);
  const aggregatedSeparations = getAggregatedSeparations(samePagesArr, pageToDisplay.separations);
  const externalVersion = getAggregatedVersion(samePagesArr.map(page => page.pageContent), pageToDisplay.pageContent);
  const aggregatedHoldType = getAggregatedHoldType(samePagesArr.map(page => page.aggregatedHoldType ?? 'none'));
  const aggregatedItem = {
    ...pageToDisplay,
    aggregatedHoldType,
    label: pageToDisplay.multipleName,
    id: aggregatedId,
    completionState,
    ...statusesObj,
    pageContent: { ...pageToDisplay.pageContent, preflightReportInfo, customIcons, externalVersion },
    aggregated: true,
    nwid: `aggregated-${pageToDisplay.nwid}`,
    rootId: pageToDisplay.nwid,
    separations: aggregatedSeparations,
    relatedPages: [...samePagesArr]
  };
  return aggregatedItem;
};

const createAggregateForm = (sameFormsArr, controller, formDefinition) => {
  const completionState = getAggregatedCompletionState(sameFormsArr);
  const indications = formDefinition.single.segments.find(seg => seg.location === 'top-right').elements[0].indications;
  const customIcons = getFormsAggregatedCustomIcons(sameFormsArr);
  let formToDisplay = getThumbnailToDisplay(sameFormsArr);
  formToDisplay = getAggregatedIndications(formToDisplay, indications, sameFormsArr, controller);
  const statusesObj = ALL_LANES.reduce((acc, lane) => {
    if (formToDisplay[lane]) {
      acc[lane] = undefined;
    }
    return acc;
  }, {});
  const aggregatedId = getAggregatedId(sameFormsArr);
  const aggregatedSeparations = getAggregatedSeparations(sameFormsArr, formToDisplay.separations);
  const externalVersion = getAggregatedVersion(sameFormsArr.map(form => form.formContent), formToDisplay.formContent);
  const aggregatedHoldType = getAggregatedHoldType(sameFormsArr.map(form => form.aggregatedHoldType ?? 'none'));
  const aggregatedItem = {
    ...formToDisplay,
    aggregatedHoldType,
    id: aggregatedId,
    label: formToDisplay.multipleName,
    completionState,
    ...statusesObj,
    formContent: { ...formToDisplay.formContent, customIcons, externalVersion },
    aggregated: true,
    nwid: `aggregated-${formToDisplay.nwid}`,
    rootId: formToDisplay.nwid,
    separations: aggregatedSeparations,
    relatedPages: [...sameFormsArr]
  };
  return aggregatedItem;
};

const getPageSeparationsContents = (separations, separationContent) => {
  separations.forEach(sep => {
    const sepContent = separationContent.find(sepCon => sepCon.colorName.toLowerCase() === sep.name);
    sep.defaultStatus = sepContent.defaultStatus;
    sep.pageLane1Status = sepContent.pageLane1Status;
    sep.pageLane2Status = sepContent.pageLane2Status;
    sep.pageLane3Status = sepContent.pageLane3Status;
    sep.__isDirty = sepContent.__isDirty;
    delete sepContent.defaultStatus;
    delete sepContent.pageLane1Status;
    delete sepContent.pageLane2Status;
    delete sepContent.pageLane3Status;
    sep.separationContent = sepContent;
  });
  separations.__isDirty = separationContent.__isDirty;
  return separations.sort(createObjectComparator('name'));
};

const getFormSeparationsContents = (separations, separationContent) => {
  separations.forEach(sep => {
    const sepContent = separationContent.find(sepCon => sepCon.colorName.toLowerCase() === sep.name);
    sep.defaultStatus = sepContent.defaultStatus;
    sep.formLane1Status = sepContent.formLane1Status;
    sep.formLane2Status = sepContent.formLane2Status;
    sep.formLane3Status = sepContent.formLane3Status;
    sep.plates = sepContent.plateGroup.map(pg => {
      const updatedPlate = { ...pg.plate };
      Object.defineProperty(updatedPlate, '__parent', {
        enumerable: false,
        value: pg.plate.__parent,
        writable: false,
        configurable: false
      });
      Object.defineProperty(updatedPlate, '__isDirty', {
        enumerable: false,
        writable: true,
        value: pg.plate.__isDirty
      });

      return updatedPlate;
    });
    sep.__isDirty = sepContent.__isDirty;
    delete sepContent.defaultStatus;
    delete sepContent.formLane1Status;
    delete sepContent.formLane2Status;
    delete sepContent.formLane3Status;
    delete sepContent.plateGroup;
    sep.separationContent = sepContent;
  });
  separations.__isDirty = separationContent.__isDirty;
  return separations.sort(createObjectComparator('name'));
};

const getSectionOfEditionModel = originalModel => {
  const editionNameLower = originalModel.name.toLowerCase();

  const sectionsObj = originalModel.publication.pageContents.reduce((acc, content) => {
    const page = content.significantPage || {};
    const sectionName = page.section?.sectionName;
    if (page.edition?.toLowerCase() === editionNameLower) {
      const updatedPage = {
        ...page,
        numberInSection: page.number || page.numberInSection,
        numberInZone: page.physicalNumber,
        ...getPagePropsFromContent(content),
        publication: originalModel.publication.name,
        pageContent: getPageContentProps(content),
        separations: getPageSeparationsContents(page.separations, content.pageSeparationContents.map(sc => ({ ...sc })))
      };
      Object.defineProperty(updatedPage, '__parent', {
        enumerable: false,
        value: page.__parent,
        writable: false,
        configurable: false
      });
      Object.defineProperty(updatedPage, '__isDirty', {
        enumerable: false,
        writable: true,
        value: page.__isDirty || content.__isDirty
      });
      Object.defineProperty(updatedPage.pageContent, '__parent', {
        enumerable: false,
        value: content.__parent,
        writable: false,
        configurable: false
      });
      Object.defineProperty(updatedPage.pageContent, '__isDirty', {
        enumerable: false,
        writable: true,
        value: content.__isDirty
      });
      if (typeof updatedPage.numberInZone === 'undefined') {
        delete updatedPage.numberInZone;
      }
      if (acc[sectionName]) {
        acc[sectionName].pages.push(updatedPage);
      } else {
        delete page.section.actionLinks;
        delete page.section.viewLinks;
        acc[sectionName] = { ...page.section, pages: [updatedPage], name: page.section.sectionName, childrenNames: ['pages'] };
      }
    }
    return acc;
  }, {});
  const sections = Object.values(sectionsObj).map(v => ({ ...v, pages: v.pages.sort(createObjectComparator('numberInSection', COMPARE_TYPE.NUMBERS)) }));
  return sections;
};

const getBooksOfEditionModel = originalModel => {
  const editionNameLower = originalModel.name.toLowerCase();

  const booksObj = originalModel.publication.formContents.reduce((acc, content) => {
    const form = content.significantForm || {};
    const bookName = form.book?.name;
    if (form.edition?.toLowerCase() === editionNameLower) {
      const updatedFormSeparationContents = content.formSeparationContents.map(sc => {
        const updatedContent = { ...sc };
        Object.defineProperty(updatedContent, '__parent', {
          enumerable: false,
          value: sc.__parent,
          writable: false,
          configurable: false
        });
        Object.defineProperty(updatedContent, '__isDirty', {
          enumerable: false,
          writable: true,
          value: sc.__isDirty
        });

        return updatedContent;
      });
      Object.defineProperty(updatedFormSeparationContents, '__parent', {
        enumerable: false,
        value: content.formSeparationContents.__parent,
        writable: false,
        configurable: false
      });
      Object.defineProperty(updatedFormSeparationContents, '__isDirty', {
        enumerable: false,
        writable: true,
        value: content.formSeparationContents.__isDirty
      });
      const updatedForm = {
        ...form,
        numberInBook: form.numberInBook,
        numberInZone: form.numberInZone,
        ...getFormPropsFromContent(content),
        publication: originalModel.publication.name,
        formContent: getFormContentProps(content),
        separations: getFormSeparationsContents(form.separations, updatedFormSeparationContents)
      };
      Object.defineProperty(updatedForm, '__parent', {
        enumerable: false,
        value: form.__parent,
        writable: false,
        configurable: false
      });
      Object.defineProperty(updatedForm, '__isDirty', {
        enumerable: false,
        writable: true,
        value: form.__isDirty || content.__isDirty
      });
      Object.defineProperty(updatedForm.formContent, '__parent', {
        enumerable: false,
        value: content.__parent,
        writable: false,
        configurable: false
      });
      Object.defineProperty(updatedForm.formContent, '__isDirty', {
        enumerable: false,
        writable: true,
        value: content.__isDirty
      });
      if (typeof updatedForm.numberInZone === 'undefined') {
        delete updatedForm.numberInZone;
      }
      if (acc[bookName]) {
        acc[bookName].forms.push(updatedForm);
      } else {
        delete form.book?.actionLinks;
        delete form.book?.viewLinks;
        acc[bookName] = { ...form.book, forms: [updatedForm], name: form.book?.name, childrenNames: ['forms'] };
      }
    }
    return acc;
  }, {});
  const books = Object.values(booksObj).map(v => ({ ...v, forms: v.forms.sort(createObjectComparator('numberInBook', COMPARE_TYPE.NUMBERS)) }));
  return books;
};

const cloneModel = model => {
  if (!model || typeof model !== 'object') {
    return model;
  }
  const clonedModel = Array.isArray(model) ? [] : {};
  if (typeof model.__isDirty !== 'undefined') {
    Object.defineProperty(clonedModel, '__parent', {
      enumerable: false,
      value: model.__parent,
      writable: false,
      configurable: false
    });
    Object.defineProperty(clonedModel, '__isDirty', {
      enumerable: false,
      writable: true,
      value: model.__isDirty || false
    });
  }

  for (const key of Object.keys(model)) {
    clonedModel[key] = cloneModel(model[key]);
  }
  return clonedModel;
};

const sortSectionsAndBooks = model => {
  const sectionsComparator = settingsManager.get('productSorting').section === 'byPlan' ? createObjectComparator('index', COMPARE_TYPE.NUMBERS) : createObjectComparator('name');
  const booksComparator = settingsManager.get('productSorting').book === 'byPlan' ? createObjectComparator('index', COMPARE_TYPE.NUMBERS) : createObjectComparator('name');

  model.sections?.sort(sectionsComparator);
  model.books?.sort(booksComparator);

  return model;
};

const sortSeparations = (separations, colorOrderByName) => {
  return separations.sort(createObjectComparator(sep => colorOrderByName[sep.name] || 99, COMPARE_TYPE.NUMBERS));
};
const sortPagesAndForms = model => {
  const colorOrderByName = getColorOrderByNameMapping();
  if (model.type === 'section') {
    model.pages.sort(createObjectComparator('numberInSection', COMPARE_TYPE.NUMBERS)).forEach(page => {
      page.separations = sortSeparations(page.separations, colorOrderByName);
    });
  } else if (model.type === 'book') {
    model.forms.sort(createObjectComparator('numberInBook', COMPARE_TYPE.NUMBERS)).forEach(form => {
      form.separations = sortSeparations(form.separations, colorOrderByName);
    });
  } else {
    model.sections?.forEach(section => {
      section.pages.sort(createObjectComparator('numberInSection', COMPARE_TYPE.NUMBERS)).forEach(page => {
        page.separations = sortSeparations(page.separations, colorOrderByName);
      });
    });
    model.books?.forEach(book => {
      book.forms.sort(createObjectComparator('numberInBook', COMPARE_TYPE.NUMBERS)).forEach(form => {
        form.separations = sortSeparations(form.separations, colorOrderByName);
      });
    });
  }

  return model;
};

const buildPagesAndFormsForEditionView = (clonedModel, controller, aggregateMode, currentAggregatedItem, pageDefinition, formDefinition) => {
  let itemsToRenderInDialog = [];
  if (clonedModel.sections) {
    clonedModel.sections.forEach(section => {
      for (let index = 0; index < section.pages.length;) {
        const page = section.pages[index];
        const samePages = [page, ...section.pages.filter(p => p.multipleName === page.multipleName && p.nwid !== page.nwid)];
        if (samePages.length > 1) {
          const updatedSamePages = samePages.map(sp => {
            const updatedSamePage = { ...sp, label: sp.variantName, variant: true };
            Object.defineProperty(updatedSamePage, '__parent', {
              enumerable: false,
              value: sp.__parent,
              writable: false,
              configurable: false
            });
            Object.defineProperty(updatedSamePage, '__isDirty', {
              enumerable: false,
              writable: true,
              value: sp.__isDirty || false
            });

            return updatedSamePage;
          }).sort(createObjectComparator('variantName'));
          if (aggregateMode) {
            const aggregatedItem = createAggregatePage(updatedSamePages, controller, pageDefinition);
            section.pages.splice(index, updatedSamePages.length, aggregatedItem);
            if (currentAggregatedItem?.relatedPages?.[0].nwid === aggregatedItem?.relatedPages?.[0].nwid) {
              itemsToRenderInDialog = aggregatedItem.relatedPages;
            }
            index++;
          } else {
            updatedSamePages.forEach(up => {
              section.pages[index] = createUpdatedPage(up);
              index++;
            });
          }
        } else {
          section.pages[index] = createUpdatedPage(page);
          index++;
        }
      }
    });
  }
  if (clonedModel.books) {
    clonedModel.books.forEach(book => {
      for (let index = 0; index < book.forms.length;) {
        const form = book.forms[index];
        const sameForms = [form, ...book.forms.filter(f => f.multipleName === form.multipleName && f.nwid !== form.nwid)];
        if (sameForms.length > 1) {
          const updatedSameForms = sameForms.map(sf => {
            const updatedSameForm = { ...sf, label: sf.variantName, variant: true };
            Object.defineProperty(updatedSameForm, '__parent', {
              enumerable: false,
              value: sf.__parent,
              writable: false,
              configurable: false
            });
            Object.defineProperty(updatedSameForm, '__isDirty', {
              enumerable: false,
              writable: true,
              value: sf.__isDirty || false
            });

            return updatedSameForm;
          }).sort(createObjectComparator('variantName'));
          if (aggregateMode) {
            const aggregatedItem = createAggregateForm(updatedSameForms, controller, formDefinition);
            book.forms.splice(index, updatedSameForms.length, aggregatedItem);
            if (currentAggregatedItem?.relatedPages?.[0].nwid === aggregatedItem?.relatedPages?.[0].nwid) {
              itemsToRenderInDialog = aggregatedItem.relatedPages;
            }
            index++;
          } else {
            updatedSameForms.forEach(uf => {
              book.forms[index] = createUpdatedForm(uf);
              index++;
            });
          }
        } else {
          book.forms[index] = createUpdatedForm(form);
          index++;
        }
      }
    });
  }

  return { ...clonedModel, itemsToRenderInDialog };
};

export const transformViewModel = (model, controller, aggregateMode, currentAggregatedItem, pageDefinition, formDefinition) => {
  let clonedModel = cloneModel(model);
  if (clonedModel.publication?.pageContents) {
    clonedModel.sections = getSectionOfEditionModel(model);
    delete clonedModel.publication.pageContents;
  }
  if (clonedModel.publication?.formContents) {
    clonedModel.books = getBooksOfEditionModel(model);
    delete clonedModel.publication.formContents;
  }
  clonedModel = sortSectionsAndBooks(clonedModel);
  if (model.type === 'edition') {
    clonedModel = buildPagesAndFormsForEditionView(clonedModel, controller, aggregateMode, currentAggregatedItem, pageDefinition, formDefinition);
  }
  clonedModel = sortPagesAndForms(clonedModel);

  return clonedModel;
};