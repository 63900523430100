/**
 * @name Menu
 * @file Menu component
 *
 * @author Boris
 * @since: 2017-07-18
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utilities/classNames';
import MenuItem from './MenuItem';
import { Popover, PopoverContent, PopoverTrigger } from 'components/common/floating';

const defaultMenuItemGetter = item => <MenuItem item={item} />;

export default function Menu(
  {
    children,
    items,
    className,
    style,
    disabled,
    placement,
    tooltip,
    menuItemGetter = defaultMenuItemGetter,
    onSelect,
    onTriggerClick,
    closeOnSelect = true
  }) {
  const [visible, setVisible] = useState(false);

  const handleTriggerClick = e => {
    e.stopPropagation();

    if (disabled) {
      return;
    }

    const vis = !visible;
    setVisible(vis);
    // console.log('===handleTriggerClick() visible=', visible, vis);

    if (typeof onTriggerClick === 'function') {
      onTriggerClick(e, vis);
    }
  };

  const handleSelect = (e, item, index) => {
    e.stopPropagation();

    if (disabled) {
      return;
    }

    if (closeOnSelect) {
      // console.log('===handleSelect() visible=', visible);
      setVisible(false);
    }

    if (typeof onSelect === 'function') {
      onSelect(e, item, index);
    }
  };

  const renderItem = (item, index) => {
    if (item.type === 'separator') {
      return (<li key={index} className='separator' />);
    }

    return (
      <li
        key={index}
        onClick={(e) => handleSelect(e, item, index)}
      >
        {menuItemGetter(item, index)}
      </li>
    );
  };

  const renderItems = () => {
    return (
      <ul className='crtx-menu-items'>
        {items.map(renderItem)}
      </ul>
    );
  };

  return (
    <div
      className={classNames('crtx-menu', className)}
      style={style}
    >
      <Popover
        placement={placement}
        open={visible}
        onOpenChange={() => setVisible(false)}>

        <PopoverTrigger>
          <div
            className={classNames('crtx-menu-title', { disabled, expanded: visible })}
            title={tooltip}
            onClick={handleTriggerClick}
          >
            {children}
          </div>
        </PopoverTrigger>

        <PopoverContent>
          {visible ? renderItems() : null}
        </PopoverContent>

      </Popover>
    </div>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  items: PropTypes.array.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  placement: PropTypes.string,
  tooltip: PropTypes.string,
  menuItemGetter: PropTypes.func,
  onSelect: PropTypes.func,
  onTriggerClick: PropTypes.func,
  closeOnSelect: PropTypes.bool
};
