import React from 'react';

export default class extends React.Component {
  state = {
    over: false
  };

  mouseEnterHandler = (e) => {
    this.setState({
      over: true
    });
  };

  mouseLeaveHandler = (e) => {
    this.setState({
      over: false
    });
  };

  mouseMoveHandler = (e) => {
    this.setState({
      over: true
    });
  };

  getClassName = (over) => {
    var classes = ['ClickableElement'];

    if (over) {
      classes.push('over');
    }

    return classes.join(' ');
  };

  getStyle = (over, overColor) => {
    if (over) {
      return {
        borderColor: overColor
      }
    }
    return undefined;
  };

  render() {
    var onClick = this.props.onClick || function () { },
      selectionColor = this.props.selectionColor,
      overColor = this.props.overColor,
      onContextMenu = this.props.onContextMenu || function () { },
      onDoubleClick = this.props.onDoubleClick || function () { },
      className = this.getClassName(this.state.over),
      style = this.getStyle(this.state.over, overColor);

    return <div style={style}
      className={className}
      onMouseEnter={this.mouseEnterHandler}
      onMouseLeave={this.mouseLeaveHandler}
      onMouseMove={this.mouseMoveHandler}
      onClick={onClick}
      onContextMenu={onContextMenu}
      onDoubleClick={onDoubleClick}>
      {this.props.children}
    </div>;
  }
}