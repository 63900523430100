import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getHoldIconName, getHoldIconTooltip } from 'utilities/hold';
import { getGeneralIcon } from 'core/services/iconService';

export default class HoldIndication extends Component {
  static propTypes = {
    holdType: PropTypes.string,
    title: PropTypes.string
  };

  static defaultProps = {
    holdType: undefined,
    title: ''
  };

  getSrcByHoldType = holdType => {
    const iconName = getHoldIconName(holdType);
    if (!iconName) {
      return undefined;
    }

    return getGeneralIcon('', iconName, '.svg');
  };

  render() {
    const holdType = this.props.holdType,
      src = this.getSrcByHoldType(holdType),
      title = getHoldIconTooltip(holdType);

    return typeof src === 'undefined' ? <span></span> : <img src={src} title={title} />;
  }
}