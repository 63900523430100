import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'core/services/localization';
import iconService from 'core/services/iconService';
import { formatBreadcrumbsCodes, formatBreadcrumbs } from 'utilities/breadcrumbs';
import AggregateObject from './AggregateObject';
import AggregateViewInfo from './AggregateViewLink';
import ProductionRun from './ProductionRun';
import DeadlineBar from 'components/private/deadline/DeadlineBar';
import IconButton from 'components/common/buttons/IconButton';
import { getHoldType } from 'utilities/hold';

const labels = {
  hold: translate('Hold'),
  min: translate('min'),
  changeDeadLine: translate('Change deadline'),
  pages: translate('Pages'),
  formes: translate('Formes'),
};

function ZoneDetails({
  zone,
  module,
  onChangeDeadline,
  onContextMenu,
  viewLinksMap
}) {

  const pagesViewInfo = new AggregateViewInfo(zone.nwid,
    'sections',
    'BirdEyeLayers',
    module.nwid,
    viewLinksMap['sections'] && viewLinksMap['sections'][zone.nwid] ? viewLinksMap['sections'][zone.nwid] : []);


  const formesViewInfo = new AggregateViewInfo(zone.nwid,
    'books',
    'BirdEyeLayers',
    module.nwid,
    viewLinksMap['books'] && viewLinksMap['books'][zone.nwid] ? viewLinksMap['books'][zone.nwid] : []);


  const platesViewInfo = new AggregateViewInfo(zone.nwid,
    'books',
    'StructuresTableView',
    module.nwid,
    viewLinksMap['books'] && viewLinksMap['books'][zone.nwid] ? viewLinksMap['books'][zone.nwid] : []);

  const zoneAggregate = zone.aggregate || {};

  const breadcrumbs = formatBreadcrumbsCodes(zone.breadcrumbs);
  const breadcrumbsTitle = formatBreadcrumbs(zone.breadcrumbs);

  const handleChangeRunDeadline = (run) => {
    onChangeDeadline(run);
  };
  const handleChangeZoneDeadline = (zone) => {
    onChangeDeadline(zone);
  };

  const handleMenuClick = (event) => {
    onContextMenu(event, zone);
  };

  const renderProductionRun = (run, index) => {

    return (
      <ProductionRun
        productionRun={run}
        showExpectedPlatesOnly={module.preferences.showExpectedPlatesOnly}
        moduleNwid={module.nwid}
        onChangeDeadline={() => handleChangeRunDeadline(run)}
        onContextMenu={onContextMenu}
        key={run.id}
        viewInfo={platesViewInfo}
      />
    );
  };

  return (<div className='crtx-zone-details-container'>

    <div className='crtx-dashboard-view-left-side' onContextMenu={(e) => onContextMenu(e, zone)} >
      <div className='dashboard-view-left-side-top-menu-cont '>
        <div className='crtx-dashboard-view-left-side-top'>
          <div className='crtx-dashboard-breadcrumbs' title={breadcrumbsTitle}>{breadcrumbs}</div>
        </div>
        <IconButton
          key='menu'
          iconName={'menu'}
          tooltip={translate('Menu')}
          onClick={handleMenuClick}
        />
      </div>

      <div className='aggregates'>
        <AggregateObject
          icon={iconService.getTemplateIcon('page', 'large')}
          title={labels.pages}
          total={Number(zoneAggregate.totalPages) || 0}
          inProgress={Number(zoneAggregate.totalInProcessPages) || 0}
          finished={Number(zoneAggregate.totalFinishedPages) || 0}
          waitingForApproval={Number(zoneAggregate.totalWaitingForApprovalPages) || 0}
          held={Number(zoneAggregate.totalHoldPages) || 0}
          errors={Number(zoneAggregate.totalErrorPages) || 0}
          viewInfo={pagesViewInfo}
        />

        {Number(zoneAggregate.totalForms) > 0 &&
          <AggregateObject
            icon={iconService.getTemplateIcon('form', 'large')}
            title={labels.formes}
            total={Number(zoneAggregate.totalForms) || 0}
            inProgress={Number(zoneAggregate.totalInProcessForms) || 0}
            finished={Number(zoneAggregate.totalFinishedForms) || 0}
            waitingForApproval={Number(zoneAggregate.totalWaitingForApprovalForms) || 0}
            held={Number(zoneAggregate.totalHoldForms) || 0}
            errors={Number(zoneAggregate.totalErrorForms) || 0}
            viewInfo={formesViewInfo}
          />
        }

      </div>
      <div className='crtx-info-tools-container'>
        <DeadlineBar
          deadlineDate={zone.deadlineDate}
          deadlinePassed={zone.deadlinePassed}
          holdType={getHoldType(zone)}
          holdUntilDate={zone.holdUntilDate}
          releaseOffset={parseInt(zone.releaseOffset)}
          onChangeDeadline={() => handleChangeZoneDeadline(zone)}
        />
      </div>
    </div>

    <div className='crtx-production-runs-side'>
      {zone.productionruns.map(renderProductionRun)}
    </div>
  </div>
  );
}


ZoneDetails.propTypes = {
  zone: PropTypes.object,
  module: PropTypes.any,
  onChangeDeadline: PropTypes.func,
  onContextMenu: PropTypes.func,
  viewLinksMap: PropTypes.object
};

export default ZoneDetails;