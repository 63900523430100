import React from 'react';
import TMIBaseTableView from "../TMIBaseTableView/TMIBaseTableView.js";
import columnsCreator from "./columnsCreator";
import sandbox from "sandbox";
import { fromServerDate, parseDate } from 'core/dates';
import { updateItem, addItem, removeItem } from "./actionsCreator";
import TextInput from "components/common/inputs/TextInput";
import { getMainStatus } from 'utilities/statuses.js';

const { translate } = sandbox.localization;
const labels = {
  'publication': translate('Publication'),
  'advertiser': translate('Advertiser'),
};

const getDefaultStatus = item => getMainStatus(item);

const preflightIconNameGetter = page => {
  if (page.pageContent.preflightReportInfo.preflightReportType === 0)
    return '';
  if (page.pageContent.preflightReportInfo.preflightReportType === 1)
    return sandbox.icons.getModuleIcon("MyBirdeye", "preflight_report_warning", ".svg");
  if (page.pageContent.preflightReportInfo.preflightReportType === 2)
    return sandbox.icons.getModuleIcon("MyBirdeye", "preflight_report_error", ".svg");
};

const preflightTitleGetter = page => {
  if (page.pageContent.preflightReportInfo.preflightReportType === 0)
    return '';
  if (page.pageContent.preflightReportInfo.preflightReportType === 1)
    return 'warning';
  if (page.pageContent.preflightReportInfo.preflightReportType === 2)
    return 'error';
};

const flowStepIconNameGetter = status => {
  return status?.time &&
    status.statusType !== 'NotAssigned' &&
    status.flowStepType
    ? sandbox.icons.getTemplateIcon(
      status.flowStepType,
      'tiny',
      status.flowStepIconName
    )
    : '';
};

const statusIconNameGetter = status => {

  if (!status?.time || status.statusType === 'NotAssigned') {
    return '';
  }

  const statusType = status.statusType.toLowerCase();

  if (statusType === 'waiting')
    return sandbox.icons.getGeneralIcon('status', 'wait');
  if (statusType === 'success')
    return sandbox.icons.getGeneralIcon('status', 'finished');
  if (
    statusType === 'error' &&
    status.flowStepType === 'workflow/step/flow/approval'
  )
    return sandbox.icons.getModuleIcon('Thumbnail', 'reject-small');

  return sandbox.icons.getGeneralIcon('status', statusType);
};

const colorBySeparationName = name => {
  const separationColor = sandbox.colors.getColorByName(name);
  return sandbox.colors.getUIColorRGB(separationColor);
};

const separation = sep => {
  return {
    name: sep.separation,
    color: colorBySeparationName(sep.separation)
  };
};

const statusDate = time => {
  const jsDateTime = time && parseDate(time.replace(/'/g, ''), 'MMMM yyyy');
  const localizedDate =
    time ? sandbox.localization.toLocaleShortDate(jsDateTime) : '';

  return { time: jsDateTime, localizedDate };
};

const statusDateTime = time => {
  const jsDateTime = fromServerDate(time);
  const localizedDate =
    time ? sandbox.localization.toLocaleShortDateTime(jsDateTime) : '';

  return { time: jsDateTime, localizedDate };
};

const pageCreator = (page = {}) => {
  const status = getDefaultStatus(page);
  return {
    nwid: page.nwid,
    originalName: page.originalName,
    pageContentNwid: page.pageContent.nwid,
    version: page.pageContent ? page.pageContent.versionIndex : "1",
    advertiser: page.advertiser,
    publication: page.publication,
    adSpecification: page.adSpecification,
    preflightIconName: preflightIconNameGetter(page),
    preflightTitle: preflightTitleGetter(page),
    flowStepIconName: flowStepIconNameGetter(status),
    statusIconName: statusIconNameGetter(status),
    statusTime: statusDateTime(status ? status.time : undefined),
    iconUID: Date.now(),
    separations: sandbox.sorting
      .sort(
        page.separations.map(sep => {
          return { ...sep };
        }),
        "cmyk",
        "separation"
      )
      .map(separation),
    issueDate: statusDate(page.issueDate)
  };
};

export default TMIBaseTableView.extend({
  initDone: function () {
    this.tickableModel.register(["page"], this.handlePage);
    this.tickableModel.register(
      ["page", "page/content"],
      this.handlePageContent
    );
    this.tickableModel.register(
      ["page", "status_queue/value"],
      this.handlePageStatus
    );
  },

  getPageIndexInState: function (page) {
    return this.store.getState().items.findIndex(p => p.nwid === page.nwid);
  },

  renderFilters: function () {
    const { filterBy } = this.store.getState();
    return (
      <div className="title-content">
        {labels.publication}:
        <TextInput className='crtx-Publication-filter'
          value={filterBy.publication}
          onChange={this.handleFilterChange('publication')}
        />
        {labels.advertiser}:
        <TextInput className='crtx-Advertiser-filter'
          value={filterBy.advertiser}
          onChange={this.handleFilterChange('advertiser')}
        />
      </div>
    );
  },

  handlePage: function (action, page) {
    const item = pageCreator(page);

    if (action === "add") this.store.dispatch(addItem(item));
    if (action === "update")
      this.store.dispatch(updateItem(item, this.getPageIndexInState(item)));
    if (action === "remove") this.store.dispatch(removeItem(item));
  },

  handlePageContent: function (action, pageContent) {
    if (action === "remove") return;

    const page = pageContent.getParent("page");
    const itemIndex = this.getPageIndexInState(page);
    const item = pageCreator(page);

    if (itemIndex < 0) return;

    this.store.dispatch(updateItem(item, itemIndex));
  },

  handlePageStatus: function (action, status) {
    if (action === "remove") return;

    const page = status.getParent("page");
    const itemIndex = this.getPageIndexInState(page);
    const item = pageCreator(page);

    if (itemIndex < 0) return;

    this.store.dispatch(updateItem(item, itemIndex));
  },

  tableName: translate("Unplanned"),

  columnsCreator: function ({ multiple, items, sites, sortBy }) {
    return columnsCreator(
      multiple,
      items,
      sites,
      sortBy,
      this.handleColumnClick
    );
  }
});
