import SimpleForm from 'widgets/SimpleForm/src/index';

const futils = SimpleForm.utils;
const PATH_DELIM = '.';
const excludedKeys = ['collapsed', 'currentTemplate'];

const getBook = (state, path) => {
};

const markItemAsChanged = (state, valuePath, itemsName) => {
  var newState = state;
  var chanks = valuePath.split(PATH_DELIM);
  var idx = chanks.indexOf(itemsName);
  if (idx >= 0) {
    var itemPath = chanks.slice(0, idx + 2).join(PATH_DELIM);
    var item = futils.get(newState, itemPath) || {};
    if (item.nwid && (!newState.changedItems || !newState.changedItems[item.nwid])) {
      newState = futils.update(newState, futils.compose('changedItems', item.nwid), true);
    }
  }

  return newState;
};

const markAsChanged = (state, path, newValue) => {
  if (futils.get(state, path) === newValue || excludedKeys.indexOf(futils.pathTail(path)) >= 0) {
    return state;
  }

  var newState = state;
  newState = markItemAsChanged(newState, path, 'forms');
  newState = markItemAsChanged(newState, path, 'books');
  return newState;
};

const updateFormProperty = (state, path, value) => {
  //console.log("reducer.updateFormProperty() path=", path + ", value=" + value);
  var newState = state;

  newState = markAsChanged(newState, path, value);

  newState = futils.update(newState, path, value);

  return newState;
};

const updateBooks = (state, model) => {
  //console.log("reducer.updateBooks() model=", model);
  var newState = state;
  addGuiValuesToModel(model);
  newState = futils.update(newState, 'model', model);
  newState = futils.update(newState, 'changedItems', {});

  return newState;
};

const updateOriginalTemplate = (state) => {
  //console.log("reducer.updateOriginalTemplate()");
  let books = state.model.books.map(book => {
    if (!book.currentTemplate || book.currentTemplate === book.originalTemplate) {
      return book;
    }

    return {...book, originalTemplate: book.currentTemplate};
  });

  return {...state, model: {...state.model, books}};
};

const copyValuesToOtherFormsInBook = (state, srcForm, destBookPath) => {
  let newState = state;
  let destBook = futils.get(newState, destBookPath);
  let changedItems = newState.changedItems || {};
  let bookChanged = changedItems[destBook.nwid] || false;
  for (let i = 0; i < destBook.forms.length; i++) {
    let destForm = destBook.forms[i];
    if (destForm != srcForm) {
      let formChanged = changedItems[destForm.nwid] || false;
      formChanged = copyForm(srcForm, destForm) || formChanged;
      changedItems[destForm.nwid] = formChanged;
      bookChanged = bookChanged || formChanged;
    }
  }

  if (bookChanged) {
    newState = futils.update(newState, destBookPath, destBook);
    newState = futils.update(newState, 'changedItems', changedItems);
  }

  return newState;
};

const copyValuesToOtherForms = (state, formPath, option) => {
  //console.log("reducer.copyValuesToOtherForms() formPath & option=", formPath, option);
  let newState = state;
  let srcForm = futils.get(newState, formPath);
  let bookPath = futils.pathNoTail(futils.pathNoTail(formPath));
  let book = futils.get(newState, bookPath);
  if (!srcForm || !srcForm.nwid || !book || !book.nwid) {
    return newState;
  }

  if (option === 'all_forms_in_book') {
    newState = copyValuesToOtherFormsInBook(state, srcForm, bookPath);
  } else if (option === 'all_forms_in_all_books') {
    for (let i = 0; i < newState.model.books.length; i++) {
      newState = copyValuesToOtherFormsInBook(state, srcForm, futils.compose('model.books', i));
    }
  }

  return newState;
};

const addTemplate = (state, path, templateName) => {
  //console.log("reducer.addTemplate() path & templateName=>", path, templateName);
  var newState = state;
  var templates = futils.get(newState, path) || [];
  var templatesLower = templates.map(function (name) {
    return name.toLowerCase();
  });
  if (templatesLower.indexOf(templateName.toLowerCase()) < 0) {
    templates.push(templateName);
    newState = futils.update(newState, path, templates);
  }

  return newState;
};

const deleteTemplate = (state, path, templateName) => {
  //console.log("reducer.deleteTemplate() path & templateName=>", path, templateName);
  if (!path || !templateName) {
    return state;
  }

  var newState = state;
  var templates = futils.get(newState, path) || [];
  var idx = templates.indexOf(templateName);
  if (idx >= 0) {
    templates.splice(idx, 1);
    newState = futils.update(newState, path, templates);
  }

  return newState;
};

const bookFromTemplate = (state, path, template) => {
  //console.log("reducer.bookFromTemplate() path=", path);
  var newState = state;
  var book = futils.get(newState, path);
  var bookChanged = false;
  var formChanged = false;
  var changedItems = newState.changedItems || {};
  for (var i = 0; i < book.forms.length; i++) {
    var destForm = book.forms[i];
    var srcForm = findFormInTemplate(destForm, template);
    formChanged = copyForm(srcForm, destForm);
    changedItems[destForm.nwid] = formChanged;
    bookChanged = bookChanged || formChanged;
  }
  changedItems[book.nwid] = bookChanged;

  newState = futils.update(newState, path, book);
  newState = futils.update(newState, 'changedItems', changedItems);
  return newState;
};

const addFormTemplate = (state, name, layoutType, form) => {
  //console.log("reducer.addFormTemplate() name=", name, form);
  let newState = state;
  
  const formTemplates = [...newState.model.formTemplates];
  let index = formTemplates.findIndex(t => t.name.toLowerCase() === name.toLowerCase() && t.layoutType === layoutType);
  if (index < 0) {
    formTemplates.push({name, layoutType, forms: []});
    index = formTemplates.length - 1;
  } else {
    formTemplates[index] = {...formTemplates[index]};
  }

  const template = formTemplates[index];
  template.forms = [...template.forms, form];
  newState = {...newState, model: {...newState.model, formTemplates}};

  return newState;
};

const copyForm = (srcForm, destForm) => {
  if (srcForm === destForm) {
    return false;
  }

  var changed = false;
  var i, j, pages, srcPage, destPage, separations, srcSep, destSep;

  separations = destForm.separations || [];
  for (i = 0; i < separations.length; i++) {
    destSep = separations[i];
    srcSep = !srcForm || !srcForm.separations ? null : srcForm.separations[i];
    if (srcSep && srcSep.colorName !== destSep.colorName) {
      srcSep = null;
    }
    changed = copyValue(srcSep, destSep, 'direct', false) || changed;
  }

  pages = destForm.pages || [];
  for (i = 0; i < pages.length; i++) {
    destPage = pages[i];
    srcPage = !srcForm || !srcForm.pages ? null : srcForm.pages[i];
    changed = copyValue(srcPage, destPage, 'anchorPoint', 'top-left') || changed;
    separations = destPage.separations || [];
    for (j = 0; j < separations.length; j++) {
      destSep = separations[j];
      srcSep = !srcPage || !srcPage.separations ? null : srcPage.separations[j];
      if (srcSep && srcSep.colorName !== destSep.colorName) {
        srcSep = null;
      }
      changed = copyValue(srcSep, destSep, 'offsetX', 0) || changed;
      changed = copyValue(srcSep, destSep, 'offsetY', 0) || changed;
      changed = copyValue(srcSep, destSep, 'scaleX', 0) || changed;
      changed = copyValue(srcSep, destSep, 'scaleY', 0) || changed;
    }
  }

  return changed;
};

const copyValue = (src, dest, key, defaultValue) => {
  var changed = false;
  var value = src ? src[key] : defaultValue;
  if (dest[key] !== value) {
    dest[key] = value;
    changed = true;
  }

  return changed;
};

const findFormInTemplate = (formInBook, template) => {
  var result;
  for (var i = 0; i < template.forms.length; i++) {
    var form = template.forms[i];
    if (form.numberInBook === formInBook.numberInBook) {
      if (form.pages.length === formInBook.pages.length) {
        var match = true;
        for (var j = 0; j < form.pages.length; j++) {
          var p1 = form.pages[j];
          var p2 = formInBook.pages[j];
          //if (p1.numberInSection !== p2.numberInSection || 
          if (p1.rowSpan !== p2.rowSpan || p1.columnSpan !== p2.columnSpan) {
            match = false;
            break;
          }
        }
        if (match) {
          result = form;
        }
      }
      break;
    }
  }

  return result;
};

const addGuiValuesToModel = (model) => {
  if (!model || !model.books) {
    return;
  }

  for (var i = 0; i < model.books.length; i++) {
    model.books[i].collapsed = false;
  }
};

module.exports = function (state, action) {
  switch (action.type) {
    case 'UPDATE':
      return updateFormProperty(state, action.path, action.value);
    case 'UPDATE_BOOKS':
      return updateBooks(state, action.model);
    case 'UPDATE_ORIGINAL_TEMPLATE':
      return updateOriginalTemplate(state);
    case 'COPY_VALUES_TO_OTHER_FORMS':
      return copyValuesToOtherForms(state, action.formPath, action.option);
    case 'ADD_TEMPLATE':
      return addTemplate(state, action.path, action.templateName);
    case 'DELETE_TEMPLATE':
      return deleteTemplate(state, action.path, action.templateName);
    case 'BOOK_FROM_TEMPLATE':
      return bookFromTemplate(state, action.path, action.template);
    case 'ADD_FORM_TEMPLATE':
      return addFormTemplate(state, action.name, action.layoutType, action.form);
    default:
      return state;
  }
};