/**
 * @name CopyAction
 * @author Guy Behar
 */
import AbstractAction from 'AbstractAction';
import sandbox from 'sandbox';

const itemHasContent = clickedItem => {
  if (clickedItem.type !== 'page') {
    return false;
  }

  const itemContent = clickedItem.pageContent || clickedItem.content || clickedItem;

  return itemContent.contentVersionMajorIndex > 0;
};

const allItemsHasContent = (clickedItems = []) => {
  return clickedItems.every(itemHasContent);
};

export default AbstractAction.extend({
  isApplicable: function (clickedItems) {
    return Array.isArray(clickedItems) && clickedItems.length === 1 && allItemsHasContent(clickedItems);
  },

  execute: function (selectedItems) {
    sandbox.clipboard.setItemsToClipboard((selectedItems || []).concat(selectedItems));
  }

});