import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getFolderCurrentDate, fromServerDateOnly, toServerDateOnly, isSameDay } from 'core/dates';
import { translate } from 'core/services/localization';
import { restPost } from 'core/managers/rest2';
import toastService from 'core/services/toastService';
import Header from 'components/common/headers/Header';
import DatePicker from 'components/common/dropdown/DatePicker';
import Button from 'components/common/buttons/Button';
import EditionsTable from '../components/EditionsTable';

const getDefaultDate = () => {
  const today = getFolderCurrentDate();
  today.setDate(today.getDate() + 1); // tomorrow

  return today;
};

const getRelevantEditions = (model) => {
  let editions = model.editions.filter(edition => edition.index === 0 || edition.standalone);
  editions = editions.map(edition => {
    return {
      ...edition,
      publicationDate: fromServerDateOnly(edition.publicationDate),
    }
  });

  return editions;
};

function DuplicateEdition({ visible, modelHandler, onUpdate }) {
  const [loading, setLoading] = useState(false);
  const [selectedNwid, setSelectedNwid] = useState();
  const [duplicatedNwid, setDuplicatedNwid] = useState();
  const [date, setDate] = useState(getDefaultDate);

  const relevantEditions = useMemo(() => {
    return getRelevantEditions(modelHandler.model);
  }, [modelHandler.model.editions]);

  const getStyle = () => {
    if (!visible) {
      return { display: 'none' };
    }
  };

  const getSelectedEditions = () => {
    const editions = relevantEditions.filter(edition => edition.nwid === selectedNwid);

    return editions;
  };

  const checkEditionExists = () => {
    const selectedEdition = getSelectedEditions()[0];
    if (!selectedEdition) {
      return false;
    }

    return relevantEditions.some(edition => isSameDay(edition.publicationDate, date) &&
      edition.publicationName === selectedEdition.publicationName && edition.name === selectedEdition.name);
  };

  const handleSelect = (nwid) => {
    setSelectedNwid(nwid)
  };

  const handleChangeDate = (e, value) => {
    setDate(value);
  };

  const handleDuplicate = () => {
    const editions = getSelectedEditions();

    if (editions.length > 0) {
      const params = { editions, date: toServerDateOnly(date), wizard: modelHandler.model.wizardFolder };

      setLoading(true);
      const { viewNwid, rootId } = modelHandler.settings;
      restPost(viewNwid, `folders/${rootId}/planning-actions/duplicateEdition`, params)
        .then(res => {
          console.log('=====> Duplicate Edition result=', res);

          modelHandler.appendEditions(res.editions, res.newPublicationsInfo);

          const duplicatedEditions = res.editions.map(edition => {
            return { ...edition, publicationDate: fromServerDateOnly(edition.publicationDate) };
          });

          const duplNwid = duplicatedEditions[0].nwid;
          setDuplicatedNwid(duplNwid);
          setSelectedNwid('');

          const newRelevantEditions = relevantEditions.concat(duplicatedEditions);
          onUpdate(newRelevantEditions);

          toastService.successToast('', translate('The selected editions have been successfully duplicated and added to the Plan Tree.'));
        })
        .catch(err => {
          toastService.errorToast(translate('Duplicate failed'), err.statusText);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className='crtx-duplicate-edition' style={getStyle()}>
      <Header>{translate('Editions')}</Header>

      <EditionsTable
        editions={relevantEditions}
        duplicatedNwid={duplicatedNwid}
        selectedNwid={selectedNwid}
        onSelect={handleSelect}
      />

      <div className='choose-date'>
        <div>{translate('Choose new date:')}</div>
        <DatePicker
          value={date}
          onChange={handleChangeDate}
        />

        {checkEditionExists() &&
        <div className='validation-message'>
          {translate('The Edition already exists for the requested date')}
        </div>
        }

      </div>

      <div>
        <Button
          disabled={loading || getSelectedEditions().length <= 0 || checkEditionExists()}
          onClick={handleDuplicate}>{translate('Duplicate')}
        </Button>

        {loading &&
        <img className='loading' src='../kernel/assets/img/sys/loading_data.gif' alt={translate('Waiting')} />}
      </div>
    </div>
  );
}

DuplicateEdition.propTypes = {
  visible: PropTypes.bool,
  modelHandler: PropTypes.any,
  onUpdate: PropTypes.func,
};

export default DuplicateEdition;
