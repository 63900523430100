import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utilities/classNames';
import Popup from 'components/common/popup/Popup';
import MenuItem from './MenuItem';

const defaultItemRenderer = item => <MenuItem item={item} />;

function ContextMenu(
  {
    visible = false,
    items,
    className,
    style,
    disabled = false,
    left,
    top,
    width = 'auto',
    height,
    menuItemGetter = defaultItemRenderer,
    onSelect,
    onClose
  }) {

  const handleSelectItem = (e, item, index) => {
    if (item.disabled) {
      return;
    }

    onClose && onClose();

    if (typeof onSelect === 'function') {
      onSelect(e, item, index);
    }
  };

  const renderItem = (item, index) => {
    if (item.type === 'separator') {
      return (<li key={index} className='separator' />);
    }

    return (
      <li
        key={index}
        onClick={e => handleSelectItem(e, item, index)}
      >
        {menuItemGetter(item, index)}
      </li>
    );
  };

  return (
    <Popup
      visible={visible}
      className={classNames('crtx-menu', className)}
      onClickedOutside={onClose}
      left={left}
      top={top}
      width={width}
      height={height}
    >
      {visible ?
        <ul className='crtx-menu-items'>
          {items.map(renderItem)}
        </ul>
        : null}
    </Popup>
  );
}

ContextMenu.propTypes = {
  visible: PropTypes.bool,
  items: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  left: PropTypes.number,
  top: PropTypes.number,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  menuItemGetter: PropTypes.func,
  onSelect: PropTypes.func,
};

export default ContextMenu;
