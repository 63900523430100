import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';
import iconService from 'core/services/iconService';
import StyleConverter from '../styleConverter';
import thumbnailStyleMap from '../models/thumbnailstylemap';
import segmentStyleMap from '../models/segmentStyleMap';
// import AlignmentPanel from './alignmentpanel';
import BirdeyeElement from './birdeyeelement';
import SelectedElement from './selectedElement';
import ClickableElement from './clickableElement';
import AlignmentPoint from './alignmentPoint';
import DroppableArea from './droppableArea';
import pubsub from 'core/managers/pubsub';
import toastService from 'core/services/toastService';
import { classNames } from 'utilities/classNames';
import { UploadButton, UploadDropZone } from 'components/private/upload';
import ProgressBar from 'components/common/progress/ProgressBar';
import IndeterminateProgressBar from 'components/common/progress/IndeterminateProgressBar';

const translate = sandbox.localization.translate;
// const DropErrorMessages = {
//   'DROP_OLD_VERSION': 'The file is older then existing one',
//   'DROP_SAME_VERSION': 'The file has the same version as existing one',
//   'DROP_VERSION_FINISHED_FLOW_PROGRESS': 'Drop is disabled due to drop version policy'
// };

var thumbnailStyleConverter = new StyleConverter(thumbnailStyleMap),
  segmentStyleConverter = new StyleConverter(segmentStyleMap);

/**
 * Upload flags description:
 * 
 * hideUploadButton => Toolbar button Flag to show or hide the upload button on mouse over the element
 * uploadButtonVisible => A state flag that indicates whether the upload button is rendered or not
 * dropAreaVisible => A state flag that indicates whether the upload drop zone (not the original (unplanned) drop zone) is rendered or not
 * progressBarVisible => A state flag that indicates an upload process has began and to show a regular progress bar
 * progressFinishedTime => A state flag that indicates an upload process has ended and to replace the regular progress bar with the indeterminate progress bar
 * itemProgress => A state flag that indicates the uploaded file's progress (used by the regular progress bar)
 */

export default class Thumbnail extends Component {
  static contextTypes = {
    controller: PropTypes.object,
    module: PropTypes.object
  };

  static defaultProps = {
    thumbnail: undefined,
    scale: undefined,
    thumbnailSize: undefined,
    gridAlign: undefined,
    thumbnailDefinition: undefined,
    alignmentPoints: undefined,
    onClick: undefined,
    onContextMenu: undefined,
    onDoubleClick: undefined
  };

  state = {
    selectedSeparationIndex: null,
    showOnlyPagesInfo: false,
    uploadButtonVisible: false,
    dropAreaVisible: false,
    progressFinishedTime: undefined,
    progressBarVisible: false,
    itemProgress: 0,
  };

  itemTotalSize = 0;

  handleSeparationClicked = (ev, separationIndex) => {
    if (this.props.thumbnail.aggregated) {
      return;
    }
    this.setState({
      selectedSeparationIndex: separationIndex
    });

    ev.preventDefault();
    ev.stopPropagation();
  };

  handleIndicatorNewVersionClicked = (ev) => {
    this.setState({
      showOnlyPagesInfo: !this.state.showOnlyPagesInfo
    });

    ev.preventDefault();
    ev.stopPropagation();
  };

  birdeyeElementHandlers = () => {
    return {
      handleSeparationClicked: this.handleSeparationClicked,
      handleIndicatorNewVersionClicked: this.handleIndicatorNewVersionClicked
    };
  };

  getGeneralProperties = (thumbnailDefinition) => {
    return thumbnailDefinition.generalProperties || {};
  };

  getFormViewportDefinition = (thumbnailDefinition, form) => {
    var pageInfoOnForm = form.PageInfoOnForm,
      matrix = pageInfoOnForm.matrix,
      horizontalImageCount = matrix.horizontalImageCount,
      verticalImageCount = matrix.verticalImageCount,
      defaultViewport = typeof thumbnailDefinition.double === 'undefined' ? thumbnailDefinition : thumbnailDefinition.double;

    if (verticalImageCount > horizontalImageCount) {
      return typeof thumbnailDefinition['double-vertical'] === 'undefined' ? defaultViewport : thumbnailDefinition['double-vertical'];
    }

    return defaultViewport;
  };

  getPageViewportDefinition = (thumbnailDefinition, page) => {
    var isPanorama = page.pageContent.isPanorama,
      pageFormat = page.pageContent.format,
      defaultViewport = typeof thumbnailDefinition.single === 'undefined' ? thumbnailDefinition : thumbnailDefinition.single;

    if (isPanorama) {
      if (pageFormat === 'Tabloid') {
        return typeof thumbnailDefinition['double-vertical'] === 'undefined' ? defaultViewport : thumbnailDefinition['double-vertical'];
      }
      return typeof thumbnailDefinition['double'] === 'undefined' ? defaultViewport : thumbnailDefinition['double'];
    }

    return defaultViewport;
  };

  getThumbnailViewportDefinition = (thumbnailDefinition, thumbnail) => {
    if (thumbnail.type === 'form') {
      return this.getFormViewportDefinition(thumbnailDefinition, thumbnail);
    }
    return this.getPageViewportDefinition(thumbnailDefinition, thumbnail);
  };

  getThumbnailStyleDefinition = (thumbnail, generalProperties, controller) => {
    var styleHelperFunctions = controller.styleHelperFunctions || {};

    return thumbnailStyleConverter.toStyle(generalProperties, thumbnail, styleHelperFunctions, controller);
  };

  getThumbnailContentStyle = (scale, size, align, ignore, styleDefinition) => {
    var style = styleDefinition;
    style.transform = scale !== 1 ? `scale(${scale})` : undefined;// translate(${(scale - 1) * 100}%, ${(scale - 1) * 100}%)` : undefined;
    style.left = scale < 1 ? `-${(size[0] * (1 - scale)) / 2}px` : undefined;
    style.top = scale < 1 ? `-${(size[1] * (1 - scale)) / 2}px` : undefined;
    style.width = size[0];
    style.height = size[1];
    style.float = align;
    style.backgroundImage = ignore ? 'url(' + iconService.getModuleIcon('MyBirdeye', 'ignore_xl') + ')' : null;
    style.backgroundPosition = ignore ? '50% 50%' : null;
    style.backgroundRepeat = ignore ? 'no-repeat' : null;

    return style;
  };

  getPreflightIndicationStyle = (size, align) => ({ width: size[0], height: size[1], float: align });

  getElements = (
    alignmentPointDefinition,
    thumbnailContentStyle,
    thumbnail,
    thumbnailSize,
    segmentStyle,
    viewConfiguration,
  ) => {
    var that = this;
    return alignmentPointDefinition.elements.map(function (elementDefinition, elementIndex) {
      return <BirdeyeElement key={[thumbnail.id, alignmentPointDefinition.location, elementIndex].join('.')}
        point={alignmentPointDefinition}
        model={thumbnail}
        containerSize={thumbnailSize}
        definition={elementDefinition}
        viewConfiguration={viewConfiguration}
        parentsStyle={[segmentStyle, thumbnailContentStyle]}
        thumbnailState={that.state}
        selectedSeparationIndex={that.state.selectedSeparationIndex}
        handlers={that.birdeyeElementHandlers()}
      >
      </BirdeyeElement>;
    });
  };

  getAlignedElements = (
    alignmentPointDefinition,
    thumbnailContentStyle,
    thumbnail,
    thumbnailSize,
    segmentLocation,
    segmentStyle,
    viewConfiguration,
  ) => {
    var elements = this.getElements(alignmentPointDefinition, thumbnailContentStyle, thumbnail, thumbnailSize, segmentStyle, viewConfiguration);

    return <AlignmentPoint key={[thumbnail.id, alignmentPointDefinition.location].join('.')} style={segmentStyle} location={segmentLocation} definition={alignmentPointDefinition}>
      {elements}
    </AlignmentPoint>;
  };

  getSegments = (
    thumbnailDefinition,
    alignmentPoints,
    thumbnail,
    thumbnailContentStyle,
    thumbnailSize,
    viewConfiguration,
  ) => {
    var that = this;
    var alignedSegments = [];
    var notAlignedSegments = {};

    if (typeof thumbnailDefinition.segments === 'undefined') {
      return {
        alignedSegments,
        notAlignedSegments
      };
    }

    thumbnailDefinition.segments.forEach(function (segmentDefinition) {
      var segmentLocation = segmentDefinition.location,
        alignmentPointDefinition = Object.assign(segmentDefinition, alignmentPoints[segmentLocation]),
        alignmentPoint,
        segmentStyle = segmentStyleConverter.toStyle(alignmentPointDefinition, thumbnail);

      if (typeof alignmentPoints[segmentLocation] !== 'undefined') {
        alignmentPoint = that.getAlignedElements(alignmentPointDefinition, thumbnailContentStyle, thumbnail, thumbnailSize, segmentLocation, segmentStyle, viewConfiguration);
        alignedSegments.push(alignmentPoint);
      } else {
        alignmentPoint = that.getElements(alignmentPointDefinition, thumbnailContentStyle, thumbnail, thumbnailSize, {}, viewConfiguration);
        notAlignedSegments[segmentLocation] = alignmentPoint;
      }
    });

    return {
      alignedSegments,
      notAlignedSegments
    };
  };

  droppableArea = (thumbnail, alignedSegments) => {
    if (thumbnail.type === 'page') {
      return <DroppableArea onDrop={this.onDrop}>
        {alignedSegments}
      </DroppableArea>;
    }
    return alignedSegments;
  };

  onDrop = (ev) => {

    ev.preventDefault();
    const { thumbnail } = this.props;
    if (thumbnail.aggregated) {
      return;
    }
    const unplannedPages = JSON.parse(ev.dataTransfer.getData('Text'));
    if (unplannedPages[0].publicationName && unplannedPages[0].publicationName.toLowerCase() !== thumbnail.publication.toLowerCase()) {
      sandbox.dialog.alert(translate('The source and target publications do not match'), translate('Cannot drop file'));
    } else {
      this.context.controller.mapUnplannedPage(thumbnail, unplannedPages).then((result) => {
        if (!result.data.success) {
          pubsub.publish('drop-on-page', { unplannedPages, success: false });
          toastService.errorToast(result.data.subject, result.data.message);
        }
      });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      (nextProps.thumbnail.aggregated && nextProps.thumbnail.relatedPages.some(rp => rp.__isDirty === true)) ||
      //if has changed
      nextProps.thumbnail.__isDirty === true ||
      //if selectedSeparationIndex has changed
      this.state.selectedSeparationIndex !== nextState.selectedSeparationIndex ||
      this.state.progressBarVisible !== nextState.progressBarVisible ||
      this.state.progressFinishedTime !== nextState.progressFinishedTime ||
      this.state.itemProgress !== nextState.itemProgress ||
      this.props.showOnlyPagesInfo !== nextProps.showOnlyPagesInfo ||
      this.state.showOnlyPagesInfo !== nextState.showOnlyPagesInfo ||
      this.state.uploadButtonVisible !== nextState.uploadButtonVisible ||
      this.state.dropAreaVisible !== nextState.dropAreaVisible ||
      this.props.scale !== nextProps.scale
    );
  }

  // In this function there are flags and conditions whether to show the upload button on mouse over
  handleMouseEnter = () => {
    this.setState({ dropAreaVisible: false });
    if (!this.props.thumbnail.aggregated && this.hasUploadPermission() && !this.context.module.hideUploadButton) {
      this.setState({ uploadButtonVisible: true });
    }
  };

  handleMouseLeave = () => {
    if (this.props.thumbnail.aggregated) {
      return;
    }
    this.setState({ uploadButtonVisible: false });
  };

  handleDragEnter = e => {
    if (e.dataTransfer.types.some(type => type === 'text/plain') || this.props.thumbnail.aggregated) {
      this.setState({ dropAreaVisible: false });
    } else {
      this.setState({ dropAreaVisible: true });
    }
  };

  handleDragOver = e => {
    e.stopPropagation();
    if (e.dataTransfer.types.some(type => type === 'text/plain')) {
      e.dataTransfer.dropEffect = 'move';
    } else {
      e.dataTransfer.dropEffect = 'copy';
    }
  };

  handleDragLeave = () => this.setState({ dropAreaVisible: false });

  handleUploadFileFinalize = () => this.setState({ dropAreaVisible: false });

  // When the file has finished uploading, the progressFinishedTime flag is initialized to that time unless there is an error
  handleItemFinish = item => {
    const { uploadResponse: { data: { error, errorMessage } = {} } = {} } = item;
    this.setState({ progressBarVisible: false });
    if (error) {
      if (!this.errorMessageShown) {
        toastService.errorToast(translate('Upload Error'), translate(errorMessage));
        this.errorMessageShown = true;
      }
    } else {
      this.setState({ progressFinishedTime: Date.now() });
    }
  };

  handleItemStart = () => this.setState({ progressBarVisible: true, itemProgress: 0 });

  // Updates the upload file progress which reflects in the regular progress bar
  handleItemProgress = item => {
    this.itemTotalSize = item.total;
    this.setState({ itemProgress: item.loaded });
  };

  hasUploadPermission = () => typeof this.context.module.actionConfig !== 'undefined';

  // A callback function which shows an error toast message to indicate an upload process has been cancelled
  handleBatchCancelled = () => {
    toastService.errorToast(translate('Drop Error'), translate('Dropping multiple pages is not allowed'));
    this.setState({ dropAreaVisible: false });
  };

  handleFileDrop = () => {
    this.errorMessageShown = false;
    if (this.hasUploadPermission()) {
      this.setState({ progressFinishedTime: undefined });
    } else {
      toastService.errorToast(translate('Upload Files'), translate('You do not have permission to upload files'));
      this.setState({ progressBarVisible: false, dropAreaVisible: false });
    }
  };

  handleUploadButtonClick = () => {
    this.errorMessageShown = false;
    this.setState({ progressFinishedTime: undefined });
  };

  // A callbck function that checks whether to show the indeterminate progress bar
  // It is shown right after the upload process ended and before the tick updates begin
  isIndeterminateProgressBarVisible = () => {
    const { thumbnail } = this.props;
    if (thumbnail.aggregated) {
      return false;
    }

    return (typeof this.state.progressFinishedTime !== 'undefined' && typeof thumbnail.defaultStatus?.statusUpdateTime === 'undefined') ||
      (this.state.progressFinishedTime > thumbnail.defaultStatus?.statusUpdateTime);
  };

  isPageTumbnail = thumbnail => thumbnail?.type === 'page';

  render() {
    var {
      thumbnail,
      scale,
      thumbnailSize,
      gridAlign,
      thumbnailDefinition,
      viewConfiguration,
      alignmentPoints,
      onClick,
      onContextMenu,
      onDoubleClick
    } = this.props,
      controller = this.context.controller,
      { enhancePreflightIndications } = this.context.module,
      thumbnailViewportDefinition = this.getThumbnailViewportDefinition(thumbnailDefinition, thumbnail),
      generalProperties = this.getGeneralProperties(thumbnailDefinition),
      thumbnailContentStyleDefinition = this.getThumbnailStyleDefinition(thumbnail, generalProperties, controller),
      thumbnailContentStyle = this.getThumbnailContentStyle(scale, thumbnailSize, gridAlign, thumbnail.ignore, thumbnailContentStyleDefinition),
      preflightIndicationStyle = this.getPreflightIndicationStyle(thumbnailSize, gridAlign),
      selectionColor = generalProperties.selectionColor,
      {
        alignedSegments,
        notAlignedSegments
      } = this.getSegments(thumbnailViewportDefinition, alignmentPoints, thumbnail, thumbnailContentStyle, thumbnailSize, viewConfiguration),
      preflightReportInfo = this.isPageTumbnail(thumbnail) ? thumbnail?.pageContent?.preflightReportInfo : undefined,
      reportType = enhancePreflightIndications ? preflightReportInfo?.preflightReportType : 0,
      shouldShowIndication = reportType === 1 || reportType === 2;

    return <div className='Form' id={thumbnail.id} key={thumbnail.id}
      onMouseEnter={this.isPageTumbnail(thumbnail) ? this.handleMouseEnter : undefined}
      onDragOver={this.isPageTumbnail(thumbnail) ? this.handleDragOver : undefined}
      onDragEnter={this.isPageTumbnail(thumbnail) ? this.handleDragEnter : undefined}
      onMouseLeave={this.isPageTumbnail(thumbnail) ? this.handleMouseLeave : undefined}
    >
      {this.isPageTumbnail(thumbnail) ? <div className={classNames('upload-button-container', { 'none': !this.state.uploadButtonVisible })}>
        <UploadButton
          target={{ targetNwid: thumbnail.nwid }}
          targetId={thumbnail.nwid}
          permissionToUpload={this.hasUploadPermission()}
          itemStartHandler={this.handleItemStart}
          itemProgressHandler={this.handleItemProgress}
          itemFinishHandler={this.handleItemFinish}
          finalizeHandler={this.handleUploadFileFinalize}
          buttonClickHandler={this.handleUploadButtonClick}
        />
      </div> : null}
      {this.state.progressBarVisible ? <div className='upload-progress-bar-container'>
        <ProgressBar
          min={0}
          max={this.itemTotalSize}
          value={this.state.itemProgress}
          animated={true} />
      </div> :
        this.isIndeterminateProgressBarVisible() ? <div className='upload-progress-bar-container'>
          <IndeterminateProgressBar />
        </div>
          : null}
      <div className='FormHeader'>
        {notAlignedSegments['above']}
      </div>

      <div className='FormContent' style={thumbnailContentStyle}>
        {shouldShowIndication && <div className='preflight-indication-container' style={preflightIndicationStyle}>
          <div className={classNames('preflight-indication', { warning: reportType === 1, error: reportType === 2 })}></div>
        </div>}

        {this.isPageTumbnail(thumbnail) ? <UploadDropZone
          target={{ targetNwid: thumbnail.nwid }}
          targetId={thumbnail.nwid}
          permissionToUpload={this.hasUploadPermission()}
          className={classNames('uploady-file-drop-area', { 'none': !this.state.dropAreaVisible })}
          style={preflightIndicationStyle}
          itemStartHandler={this.handleItemStart}
          itemProgressHandler={this.handleItemProgress}
          dragLeaveHandler={this.handleDragLeave}
          fileDropHandler={this.handleFileDrop}
          batchCancelledHandler={this.handleBatchCancelled}
          itemFinishHandler={this.handleItemFinish}
          finalizeHandler={this.handleUploadFileFinalize}
        />
          : null}

        <ClickableElement onClick={onClick}
          onContextMenu={onContextMenu}
          onDoubleClick={onDoubleClick}>

          <SelectedElement model={thumbnail} style={{ borderColor: selectionColor }}>
          </SelectedElement>
          {this.droppableArea(thumbnail, alignedSegments)}

        </ClickableElement>
      </div>

      <div className='FormFooter'>
        {notAlignedSegments['under']}
      </div>
    </div>;
  }
}

Thumbnail.propTypes = {
  thumbnail: PropTypes.object,
  scale: PropTypes.number,
  thumbnailDefinition: PropTypes.object,
  viewConfiguration: PropTypes.object,
  alignmentPoints: PropTypes.object,
  onClick: PropTypes.func,
  onContextMenu: PropTypes.func,
  onDoubleClick: PropTypes.func,
  thumbnailSize: PropTypes.array,
  gridAlign: PropTypes.string,
  showOnlyPagesInfo: PropTypes.bool,
};