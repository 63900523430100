import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';
import utils from './utils';

const translate = sandbox.localization.translate;

module.exports = class CreepValues extends Component {

  static propTypes = {
    object: PropTypes.any,
    applied: PropTypes.bool,
    cropped: PropTypes.bool,
    pages: PropTypes.any
  }

  renderDirection = (direction) => {
    switch (direction) {
      case "up":
        return "\u2191";
      case "down":
        return "\u2193";
      case "left":
        return "\u2190";
      case "right":
        return "\u2192";
    }
  };

  renderCreepRowForPage = (creepValue, index) => {

    const { applied, pages, cropped } = this.props;

    const pageName = pages.find(p => p.contentNwid === creepValue.contentNwid).name;
    const value = utils.formatNumber(Math.abs(creepValue.planned));
    const dirSymbol = this.renderDirection(creepValue.direction);

    if (creepValue.actual) {

      const actual = creepValue.actual;
      const actual_creep = utils.formatNumber(Math.abs(actual.offset));
      const last_column = cropped ? utils.formatNumber(actual.crop) : actual.scale;

      return (
        <tr className='creep-tbody-row' key={index}>
          <td></td>
          <td className='creep-tbody-col'>{pageName}</td>
          <td className='creep-tbody-col'>{dirSymbol}</td>
          <td className='creep-tbody-col'>{value}</td>

          <td className='creep-tbody-col'>{actual_creep}</td>
          <td className='creep-tbody-col'>{last_column}</td>

        </tr>
      );
    } else {

      return (
        <tr className='creep-tbody-row' key={index}>
          <td></td>
          <td className='creep-tbody-col'>{pageName}</td>
          <td className='creep-tbody-col'>{dirSymbol}</td>
          <td className='creep-tbody-col'>{value}</td>
        </tr>
      );
    }
  };

  render() {

    const { creepValues } = this.props;

    return (
      creepValues.map(this.renderCreepRowForPage)
    );
  }

}