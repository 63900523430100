import React from 'react';
import dialogService from 'core/services/dialogService';
import DialogContent from './dialogContent';
import { translate } from 'core/services/localization';
import { createRoot } from 'react-dom/client';

/**
 * Shows Select Image dialog
 * @param {array} imageItems Array of image items with two required properties:
 *   nwid {string} - page content nwid
 *   name {string} - image name displayed in the list
 * @param {object} options Optional additional parameters
 * @returns {promise} Promise resolved to the selected image item
 */
const show = (imageItems, options = {}) => {
  return new Promise((resolve, reject) => {
    var selectedImageItem;

    const handleOkButtonClick = () => {
      if (selectedImageItem && selectedImageItem.nwid) {
        resolve(selectedImageItem);
      } else {
        reject('cancel');
      }
    };

    const handleClose = () => {
      reject('cancel');
    };

    const handleSelectImageItem = (imageItem) => {
      selectedImageItem = imageItem;
    };

    var props = {
      imageItems: imageItems,
      onSelectImageItem: handleSelectImageItem
    };

    dialogService.show({
      modal: true,
      width: options.width || 0.6 * window.innerWidth,
      height: options.height || 0.8 * window.innerHeight,
      title: options.title || translate('Select Image'),
      buttons: [
        {
          title: 'OK', onClick: handleOkButtonClick
        }
      ],
      onClose: handleClose,
      init: function (content) {
        content[0].style.width = '100%';
        content[0].style.height = '100%';
        if (content[0]) {
          const reactRoot = createRoot(content[0]);
          reactRoot.render(<DialogContent {...props} />);
        }

      }
    });
  });
};

module.exports = {
  show: show
};