import { startInternalModule } from 'core/managers/module';
import AbstractAction from 'AbstractAction';
import sandbox from 'sandbox';
import toastService from 'core/services/toastService';

const ERROR_STATUS = 'Error'

const translate = sandbox.localization.translate;
const labels = {
  error: translate('Error')
}

export default AbstractAction.extend({
  isApplicable: function (selectedItems) {
    for (var item of selectedItems){
      if (!item.isPlanned){
        return false;
      }
    }
    return true;
  },

  execute: function (selectedItems) {    
    const rest = sandbox.request.rest(this.nwid);
    let items = [];
    for (let item of selectedItems){
      items.push({nwid:item.nwid, type:item.type});
    }
    const data = { data: JSON.stringify({items:items}) };    
    rest.post(`/folders/${this.folderNwid}/planning-actions/getExtraInfo`, data).then((res) => {
      const moduleName = this.parameters.moduleName;
      const name = this.label;
      const target = 'dialog';
      const windowWidth = this.parameters.windowWidth;
      const windowHeight = this.parameters.windowHeight;
      const win = this.module.win;

      startInternalModule(moduleName, {
        name,
        win,
        target,
        windowWidth,
        windowHeight,
        model:{
          ...res
        },
        onSave: (model) => {
          const newModel = {
            ...model,
            config: this.config
          }
          const rest = sandbox.request.rest(this.nwid);          
          return rest.post(`/folders/${this.folderNwid}/planning-actions/setExtraInfo`, { data: JSON.stringify(newModel) }).then(response => {
            if (response.state === ERROR_STATUS) {
              toastService.createToast('bottom-right', labels.error, translate(response.message), 'error', undefined, undefined, false);
              return {
                error: response.message
              };
            }
          });
        }
      }, this);
    });
  }
});