import localization from 'core/services/localization';
import icons from 'core/services/iconService';

const { translate } = localization;

export const TOGGLE_RESOURCE_OPTIONS = [
  {
    value: 'on',
    data: { icon: icons.getGeneralIcon('resource', 'on'), title: translate('On') }
  },
  {
    value: 'off',
    data: { icon: icons.getGeneralIcon('resource', 'off'), title: translate('Off') }
  },
];

export const RESOURCE_STATE_OPTIONS = [
  {
    value: 'alive',
    data: { icon: icons.getGeneralIcon('resource', 'led_on'), title: translate('Alive') }
  },
  {
    value: 'dead',
    data: { icon: icons.getGeneralIcon('resource', 'led_off'), title: translate('Dead') }
  },
  {
    value: 'unknown',
    data: { icon: icons.getGeneralIcon('resource', 'led_unknown'), title: translate('Unknown') }
  },
];

export const GLOBAL_RESOURCE_OPTIONS = [
  {
    value: 'Global',
    data: { columnData: translate('Global') }
  },
  {
    value: 'Local',
    data: { columnData: translate('Local') }
  },
];

export const DEFAULT_SORT = [
  {
    ascending: true,
    key: 'type'
  },
  {
    ascending: true,
    key: 'name'
  }
];

export const DEVICE_TYPE_DISPLAY_MAP = {
  'physical/image/inkc': 'Ink C',
  'physical/image/pdfprocessor': 'PDF Processor',
  'physical/input/hotfolder': 'Hot Folder',
  'physical/input/status': 'Status',
  'physical/image/rip': 'RIP',
  'physical/output/proout': 'Pro Out',
  'physical/rip2ctp': 'RIP 2 CTP',
  'physical/comm/tx': 'TX',
  'physical/image/tiffprocessor': 'Tiff Processor',
  'physical/image/preflight': 'Preflight',
  'physical/image/optimization': 'Optimization',
  'physical/comm/externalprotocol': 'External Protocol',
  'physical/output/proofer': 'Proofer',
  'physical/output/cache': 'Cache',
  'physical/flow/cmdline': 'CMD Line',
  'physical/general/logic': 'Logic',
  'physical/flow/fb': 'Folder Balance',

  // demons
  'physical/image/composelowres': 'Compose Low Res',
  'physical/image/hirestile': 'Hires Tile',
  'physical/image/pagelowres': 'Page Low Res',
  'physical/general/reporting': 'Reporting',
  'physical/image/seplowres': 'Sep Low Res',
  'physical/output/mail': 'Mail',
  'physical/general/purge': 'Purge',
  'physical/general/task': 'Task',
};

export const RESOURCES_NOT_PERMITTED_TO_DELETE = {
  'physical/output/mail': 'Mail',
  'physical/general/purge': 'Purge',
  'physical/general/task': 'Task',
};