/**
 * @name guideline
 * @file Defines Guideline element functionality. Guidelines appear as non-printing lines that help to position elements precisely.
 *
 * @author Boris
 * @since: 2018-08-26
 */

import React from 'react';
import Fabric from 'fabric';
import { translate } from 'core/services/localization';
import SimpleForm from 'widgets/SimpleForm/src/index';
import utils from '../utils/utils';
import canvasUtils from '../utils/canvasUtils';
import propertiesCommon from './propertiesCommon';

const futils = SimpleForm.utils;

const BASE_PATH = 'layout.guidelines';
const TITLE = translate('Guideline');

const LINE_WIDTH = 0;
const DRAG_WIDTH = 9; //px

const {DISPLAY_NAME} = propertiesCommon;
const X = {...propertiesCommon.X, caption: translate('Position')};
const Y = {...propertiesCommon.Y, caption: translate('Position')};
const REFERENCE_X = {...propertiesCommon.REFERENCE_X};
const REFERENCE_Y = {...propertiesCommon.REFERENCE_Y};

const ORIENTATION = {
  key: 'orientation',
  caption: translate('Orientation'),
  type: 'options',
  options: [
    {value: 'horizontal', text: translate('Horizontal')},
    {value: 'vertical', text: translate('Vertical')},
  ]
};

const COLOR = {
  key: 'color',
  caption: translate('Color'),
  type: 'color',
  defaultValue: '#00ffff'
};

const SHOW_IN_SOFT_PROOF = {
  key: 'showInSoftProof',
  caption: translate('Show in soft proof'),
  type: 'bool',
  defaultValue: true
};

const GUIDELINE_META = [
  DISPLAY_NAME,
  ORIENTATION,
  X,
  Y,
  COLOR,
  REFERENCE_X,
  REFERENCE_Y,
  SHOW_IN_SOFT_PROOF,
];

export default (editor) => {

  const canvas = editor.getMainCanvas();
  const cutils = canvasUtils(editor);

  const getMeta = (state, element) => {
    if (element.orientation === 'horizontal') {
      X.hidden = true;
      Y.hidden = false;
      REFERENCE_X.hidden = true;
      REFERENCE_Y.hidden = false;
    } else {
      X.hidden = false;
      Y.hidden = true;
      REFERENCE_X.hidden = false;
      REFERENCE_Y.hidden = true;
    }

    return GUIDELINE_META;
  };

  const buildDefaultElement = (state, elementType) => {
    const element = {
      elementType: elementType,
      locked: false,
      _selectable: true
    };

    setDefaultElementValues(state, element);

    return element;
  };

  const setDefaultElementValues = (state, element) => {
    propertiesCommon.setDefaultValues(state, element, getMeta(state, element));
  };

  const updateProperty = (state, element, elementPath, propertyPath, propertyValue) => {
    let newState = state;
    let newElement = element;

    newState = propertiesCommon.updateProperty(newState, newElement, elementPath, propertyPath, propertyValue);
    newElement = futils.get(newState, elementPath);

    updateShape(newState, newElement);

    return newState;
  };

  const moveShape = (state, element, canvasShape, event) => {
    const p = canvas.getPointer(event);
    if (element.orientation === 'horizontal') {
      canvasShape.top = p.y;
    } else {
      canvasShape.left = p.x;
    }

    return shapeTransforming(state, element, canvasShape, event);
  };

  const shapeTransforming = (state, element, canvasShape, event) => {
    utils.constrainPoint(canvasShape, getBoundsRectangle(state));
    const p = utils.toSystemXY(state.plateRectangle, canvasShape.left, canvasShape.top, element.referenceX, element.referenceY);
    const newElement = {...element, x: p.x, y: p.y};
    //console.log('shapeTransforming() => ', p);

    updateShape(state, newElement);

    return newElement;
  };

  const createGuidelineRect = (element) => {
    return createRect(element, false);
  };

  const createDragRect = (element) => {
    return createRect(element, element._selectable);
  };

  const createRect = (element, selectable) => {
    const rect = new Fabric.Rect({
      hasRotatingPoint: false,
      centeredRotation: false,
      selectable: selectable,
      evented: selectable,
      hasControls: false,
      lockScalingX: true,
      lockScalingY: true,
    });

    return rect;
  };

  const getBoundsRectangle = (state) => {
    const r = state.plateRectangle;

    return {left: 0, top: 0, width: r.width + 2 * r.left, height: r.height + 2 * r.top};
  };

  const getCommonRectProperties = (state, element, selectable, width) => {
    let rect;
    const bounds = getBoundsRectangle(state);
    const p = utils.toCanvasLeftTop(state.plateRectangle, element.x, element.y, element.referenceX, element.referenceY);
    const locked = element.locked || !element._selectable;
    const hoverCursor = locked ? 'default' : 'move';
    if (element.orientation === 'horizontal') {
      rect = {
        left: 0,
        top: p.top,
        width: bounds.width,
        height: width,
        originX: 'left',
        originY: 'center',
        lockMovementX: true,
        lockMovementY: locked,
        hoverCursor
      };
    } else {
      rect = {
        left: p.left,
        top: 0,
        width: width,
        height: bounds.height,
        originX: 'center',
        originY: 'top',
        lockMovementX: locked,
        lockMovementY: true,
        hoverCursor
      };
    }

    return rect;
  };

  const updateGuidelineRect = (state, element) => {
    const rect = getCommonRectProperties(state, element, false, LINE_WIDTH);
    const guidelineRect = element.shape.guidelineRect;
    guidelineRect.set({...rect, fill: element.color, stroke: element.color});
    guidelineRect.setCoords();
  };

  const updateDragRect = (state, element) => {
    const rect = getCommonRectProperties(state, element, element._selectable, DRAG_WIDTH);
    const dragRect = element.shape.dragRect;
    dragRect.set({...rect, fill: '', stroke: ''});
    dragRect.setCoords();
  };


  const createShape = (state, element, elementPath) => {
    const guidelineRect = createGuidelineRect(element);
    const dragRect = createDragRect(element);

    const shape = {
      elementPath,
      guidelineRect,
      dragRect,
    };

    dragRect.shape = shape;
    element.shape = shape;

    updateShape(state, element);
    addShape(state, element);

    return shape;
  };

  const addShape = (state, element) => {
    if (!element || !element.shape) {
      return;
    }

    canvas.add(element.shape.guidelineRect);
    canvas.add(element.shape.dragRect);
  };

  const removeShape = (state, element) => {
    if (!element || !element.shape) {
      return;
    }

    canvas.remove(element.shape.guidelineRect);
    canvas.remove(element.shape.dragRect);
  };


  const updateShape = (state, element) => {
    updateGuidelineRect(state, element);
    updateDragRect(state, element);
  };

  const activateShape = (state, element) => {
    cutils.setActiveObject(element.shape.dragRect);
  };

  const renderProperties = (store, element, elementPath) => {
    const state = store.getState();
    const meta = getMeta(state, element);

    return (
      <div>
        {propertiesCommon.renderProperties(store, element, elementPath, meta)}
      </div>
    );
  };

  const getInfo = (state) => {
    return {
      basePath: BASE_PATH,
      title: TITLE
    };
  };

  const getAlignmentInfo = (state, element) => {
    let result;

    const r = element.shape.dragRect;
    if (element.orientation === 'horizontal') {
      result = {
        boundingBox: {...r, height: 0},
        alignments: {ht: r.top, hm: r.top, hb: r.top}
      };
    } else {
      result = {
        boundingBox: {...r, width: 0},
        alignments: {vl: r.left, vm: r.left, vr: r.left}
      };
    }

    return result;
  };

  return {
    buildDefaultElement,
    setDefaultElementValues,
    updateProperty,
    moveShape,
    shapeTransforming,
    createShape,
    updateShape,
    addShape,
    removeShape,
    activateShape,
    renderProperties,
    getInfo,
    getAlignmentInfo,
  };

};
