import { toJS, action, observable, makeObservable } from 'mobx';
import { isUndefined, isFunction, get, set } from './consts';
import { cloneDeep } from 'base/jsUtils';

class Store {
  @observable
  initialState = {};

  @observable
  state = {};

  constructor(initialState = {}) {
    makeObservable(this);

    this.initialState = cloneDeep(initialState);
    this.state = cloneDeep(initialState);

    this.setState = this.setState.bind(this);
    this.getState = this.getState.bind(this);
  }

  @action
  setState(path, value, overrideInitialState = false) {
    if (overrideInitialState) {
      this.initialState = set(this.state, path, value);
    }
    else {
      this.state = set(this.state, path, value);
    }
  }

  getState(path) {
    if (isUndefined(path)) return this.state;

    return get(this.state, path);
  }

  getInitialState(path) {
    if (isUndefined(path)) return this.initialState;

    return get(this.initialState, path);
  }

}

export default Store;