import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Page extends Component {
  static propTypes = {
    page: PropTypes.object,
    selectedPageNumber: PropTypes.number,
    hideAssignedCount: PropTypes.bool,
    draggable: PropTypes.bool,
    onClick: PropTypes.func,
    refCallback: PropTypes.func
  };

  static defaultProps = {
  };

  constructor(props) {
    super(props);
  }

  handleClick = (e) => {
    const { onClick, page } = this.props;

    if (onClick) {
      onClick(e, page);
    }
  };

  handleRemoveClick = (e) => {
    const { onRemoveClick, page } = this.props;

    if (onRemoveClick) {
      onRemoveClick(e, page);
    }
  };

  handleRefCallback = (node) => {
    const { refCallback, page } = this.props;

    if (refCallback) {
      refCallback(node, page);
    }
  };

  handleDragStart = (e) => {
    //console.log('###Page.handleDragStart()', this.props.draggable);
    const { page } = this.props;

    const data = {
      type: 'page',
      number: page.number
    };

    e.dataTransfer.setData('text', JSON.stringify(data));
  };

  renderRemoveButton(page) {
    if (!this.props.onRemoveClick) {
      return null;
    }

    return (
      <div className='remove-page-button'
        onClick={this.handleRemoveClick}
      >
      </div>
    );
  }

  renderAssignedCount(page) {
    if (this.props.hideAssignedCount || page.assignedCount < 2) {
      return null;
    }

    return (
      <div className='page-assigned-count'>
        {page.assignedCount}
      </div>
    );
  }

  renderPageName(page) {
    if (!page.name) {
      return null;
    }

    return (
      <div className='page-name' title={page.name}>
        {page.name}
      </div>
    );
  }

  renderPage(page) {
    if (!page) {
      return null;
    }

    const { selectedPageNumber, draggable } = this.props;
    const className = `page ${page.number === selectedPageNumber ? 'selected' : ''} ${page.assignedCount > 0 ? 'assigned' : ''}`;
    // const handleDragStart = draggable ? this.handleDragStart : null;
    // console.log('###Page.renderPage()', handleDragStart);
    const dragProps = {
      draggable: draggable || false,
    };
    if (draggable) {
      dragProps.onDragStart = this.handleDragStart;
    }

    return (
      <div className={className}
        {...dragProps}
      >
        {this.renderPageName(page)}
        {this.renderAssignedCount(page)}
        {this.renderRemoveButton(page)}
        <div className='page-number'>
          {page.number}
        </div>
      </div>
    );
  }

  render() {
    const { page } = this.props;

    return (
      <div className='page-placeholder'
        onClick={this.handleClick}
        ref={this.handleRefCallback}
      >
        {this.renderPage(page)}
      </div>
    );
  }
}


export default Page;
