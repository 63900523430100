/**
 * @fileOverview
 * @author elad
 */

define(['sandbox', 'AbstractAction'], function (sandbox, AbstractAction) {
  'use strict';

  function isObjectInApproval(obj, property) {
    var flowSteps = obj[property];
    if (typeof flowSteps !== 'undefined') {
      if (flowSteps !== null) {
        if (flowSteps.length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  function isObjectApplicable (obj){
    if (isObjectInApproval(obj)) {
      return true;
    }

    var separations =  obj["separations"];
    if (typeof separations === 'undefined') {
      return false;
    }
    for (var i = 0; i < separations.length; i++) {
      if (isObjectInApproval(separations[i])) {
        return true;
      }
    }
    return false;
  }

  return AbstractAction.extend({
    //TODO: uncomment this

    command: 'approveRejectObject',

    isApplicable: function (objs) {
      if (typeof objs === 'undefined') {
        return false;
      }
      for (var index = 0; index < objs.length ; index++) {
        var obj = objs[index];
        if (typeof obj !== 'undefined') {
          var result = isObjectApplicable(obj);
          if (result === false) {
            return false;
          }
        } else {
          return false;
        }
      }

      return objs.length === 0 ? false : true;
    },

    execute: function (objs) {
      var nwid, type;
      var operation = this.parameters.operation;
      var result = false;
      var approvedObjects = [];
      for (var index = 0; index < objs.length ; index++) {
        var obj = objs[index];
        if (typeof obj !== 'undefined') {
          if (isObjectInApproval(obj)) {
            var flowSteps = obj["approvalFlowSteps"];
            var flowStepNWIds = [];
            for (var i = 0; i < flowSteps.length; i++) {
              flowStepNWIds.push(flowSteps[i]);
            }
            var content = null;
            type = obj["type"];
            if (type === "page") {
              content = obj["pageContent"];
            } else if (type === "page/separation") {
              content = obj["separationContent"];
            } else if (type === "form/separation") {
              content = obj["separationContent"];
            } else {
              content = obj["formContent"];
            }
            nwid = content === null ? content["nwid"] : obj["nwid"];
            type = content === null ? content["type"] : obj["type"];
            //sandbox.request.approveRejectObject(operation, nwid, type, flowStepNWIds, {});
            approvedObjects.push ({operation: operation, nwid : nwid, type: type, flowStepNWIds: flowStepNWIds});
          }
          var separations =  obj["separations"];
          if (typeof separations === 'undefined') {
            separations = [];
          }
          for (var j = 0; j < separations.length; j++) {
            var sep = separations[j];
            if (isObjectInApproval(sep)) {
              flowSteps = sep["approvalFlowSteps"];
              flowStepNWIds = [];
              for (var k = 0; k < flowSteps.length; k++) {
                flowStepNWIds.push(flowSteps[k]);
              }
              content = sep["separationContent"];
              nwid = content === null ? content["nwid"] : sep["nwid"];
              type = content === null ? content["type"] : sep["type"];
              //sandbox.request.approveRejectObject(operation, nwid, type, flowStepNWIds, {});
              approvedObjects.push ({operation: operation, nwid : nwid, type: type, flowStepNWIds: flowStepNWIds});
            }
          }

        }
      }
      sandbox.request.approveRejectObjects({operation: operation, approvedObjects : approvedObjects}, {command:this.command});

      if (this.module.name.toLowerCase() === 'pageview') {
        setTimeout(function () {
          window.close();
        }, 1500);
      }
    }
  });
});