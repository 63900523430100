import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';

const localization = sandbox.localization;
const CHANNEL_TYPES = {
  custom: 'chat/channel/custom',
  oneToOne: 'chat/channel/one_to_one',
  oneToGroup: 'chat/channel/group'
};
const MATERIAL_ICONS = {
  PEOPLE: 'people',
  PERSON: 'person',
  STAR: 'star'
};


export default class ChannelsList extends Component {
  static propTypes = {
   
    channelNwids: PropTypes.array,
    store: PropTypes.any,
    onRowClick: PropTypes.func,
  };

  handleRowClick = (index, id) => (event) => {
    const { onRowClick } = this.props;

    onRowClick(id);
  };

  setScrollPosition = () => {
    const { store } = this.props;
    const { userChannelsNwids, selectedId } = store.getState();
    const selectedIndex = userChannelsNwids.findIndex(channelNwid => channelNwid === selectedId);

    if (selectedIndex === -1) return;
    else {
      const itemHeight = this.listRef.childNodes[0].offsetHeight;
      const containerHeight = this.listRef.offsetHeight;
      let itemScrollYPosition = 0;
      const containerScrollYPosition = this.listRef.getBoundingClientRect().top;

      itemScrollYPosition = this.listRef.childNodes[selectedIndex].getBoundingClientRect().top;

      if (itemScrollYPosition - containerScrollYPosition + itemHeight > containerHeight) {
        this.listRef.childNodes[selectedIndex].scrollIntoView(false);
      }
      if (itemScrollYPosition - containerScrollYPosition + itemHeight < itemHeight) {
        this.listRef.childNodes[selectedIndex].scrollIntoView(true);
      }
    }
  };


  renderRow = (channelNwid, index) => {    
    const { store } = this.props;
    const { selectedId, channelsByNwid } = store.getState();
    const isSelectedClassName = selectedId && selectedId === channelNwid ? 'selected' : undefined;
    const channelType = channelsByNwid[channelNwid].type === CHANNEL_TYPES.oneToOne ? MATERIAL_ICONS.PERSON : channelsByNwid[channelNwid].type === CHANNEL_TYPES.oneToGroup ? MATERIAL_ICONS.PEOPLE : MATERIAL_ICONS.STAR;

    const newMessageCounterClass = channelsByNwid[channelNwid].nwid !== selectedId && channelsByNwid[channelNwid].newMessagesCount > 0 ? 'crtx-chat-has-new-messages' : '';
    const lastUpdatedDateTimeFormated = channelsByNwid[channelNwid].lastUpdatedDateTime;
    const lastUpdatedDateTime = (lastUpdatedDateTimeFormated.getFullYear() !== (new Date).getFullYear() || lastUpdatedDateTimeFormated.getMonth() !== (new Date).getMonth() || lastUpdatedDateTimeFormated.getDay() !== (new Date).getDay()) ?
      localization.toLocaleShortDate(lastUpdatedDateTimeFormated) : localization.toLocaleShortTime(lastUpdatedDateTimeFormated);

    return <div key={index} className={`crtx-chat-list-row  ${isSelectedClassName}`} onClick={this.handleRowClick(index, channelNwid)}>
      <div className='crtx-chat-type'>
        <div className='crtx-chat-type-icon-container'>
          <i className='material-icons'>{channelType}</i>
        </div>
      </div>
      <div className='crtx-chat-subject'>
        <span className='crtx-chat-subject-title'>{channelsByNwid[channelNwid].subject}</span>
      </div>
      <div className='crtx-chat-last-message'>
        <span>{channelsByNwid[channelNwid].messages.length > 0 ? channelsByNwid[channelNwid].messages[channelsByNwid[channelNwid].messages.length - 1].from + ':' : ''} </span>
        <span>{channelsByNwid[channelNwid].messages.length > 0 ? channelsByNwid[channelNwid].messages[channelsByNwid[channelNwid].messages.length - 1].content : ''}</span>
      </div>
      <div className='crtx-chat-last-updated-time'>{lastUpdatedDateTime}</div>
      <div className='crtx-chat-new-message-counter-container'>
        <div className={newMessageCounterClass}>{channelNwid !== selectedId && channelsByNwid[channelNwid].newMessagesCount > 0 ? channelsByNwid[channelNwid].newMessagesCount : ''}</div>
      </div>
    </div>;
  };


  render() {
    const { channelNwids } = this.props;


    return <div className='crtx-chat-channels-list-container' ref={el => this.listRef = el} >
      {channelNwids.map(this.renderRow)}
    </div>;
  }

}