import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';

export default class LockIndicator extends Component {
  static propTypes = {
    locked: PropTypes.bool,
    title: PropTypes.string
  };

  static defaultProps = {
    locked: false,
    title: ''
  };

  getSrcByLockType = (locked) => {
    return locked === true ? sandbox.icons.getModuleIcon('MyBirdeye', 'lock', '.svg') : undefined;
  };

  render() {
    const locked = this.props.locked,
      src = this.getSrcByLockType(locked),
      title = this.props.title;

    return locked ? <img src={src} title={title} /> : <span></span>;
  }
}