import sandbox from 'sandbox';

function DOMMouseMoveHandler(onMove, onMoveEnd, domNode) {
  this.init.apply(this, arguments);
}

DOMMouseMoveHandler.prototype = {

  init: function (onMove, onMoveEnd, domNode) {
    this._isDragging = false;
    this._domNode = domNode;
    this._onMove = onMove;
    this._onMoveEnd = onMoveEnd;
    this._startX = null;
    this._startY = null;
    this._x = null;
    this._y = null;
    this._deltaX = null;
    this._deltaY = null;
    this._onMouseMove = this._onMouseMove.bind(this);
    this._onMouseUp = this._onMouseUp.bind(this);
  },

  captureMouseMove: function (event) {
    this._domNode.addEventListener(
      'mousemove',
      this._onMouseMove
    );
    this._domNode.addEventListener(
      'mouseup',
      this._onMouseUp
    );

    if (!this._isDragging) {
      this._deltaX = 0;
      this._deltaY = 0;
      this._isDragging = true;
      this._x = event.clientX;
      this._y = event.clientY;
      this._startX = event.clientX;
      this._startY = event.clientY;
    }

    event.preventDefault();
  },

  releaseMouseMove: function () {
    this._domNode.removeEventListener(
      'mousemove',
      this._onMouseMove
    );
    this._domNode.removeEventListener(
      'mouseup',
      this._onMouseUp
    );

    if (this._isDragging) {
      this._isDragging = false;
      this._x = null;
      this._y = null;
      this._startX = null;
      this._startY = null;
    }
  },

  isDragging: function () {
    return this._isDragging;
  },

  _mouseMove: function (event) {
    this._onMove(event.clientX, event.clientY, this._startX, this._startY);
    this._deltaX = 0;
    this._deltaY = 0;
  },



  _onMouseMove: function (event) {
    var x = event.clientX,
      y = event.clientY;

    this._deltaX += (x - this._x);
    this._deltaY += (y - this._y);

    this._mouseMove(event);

    this._x = x;
    this._y = y;
    event.preventDefault();
  },

  _onMouseUp: function (event) {
    var x = event.clientX,
      y = event.clientY;

    this._deltaX += (x - this._x);
    this._deltaY += (y - this._y);

    this._mouseMove(event);

    this._onMoveEnd(x, y, this._startX, this._startY);
  }

};

export default DOMMouseMoveHandler;