/**
 * @name
 * @fileOverview Define helper contains helper
 * function to define columns and cells of
 * the ember table
 * @author sergey
 */

define(['logger', 'core/services/localization', 'base/jsUtils', '../columnDefinitions/Fluid', './../views/TreeTableCell',
	'mixins/DragEmberMixin', 'ember_table'],
function (logger, localization, jsutils, FluidColumn, TreeTableCellView, DragEmberMixin) {
  'use strict';

  var log = logger.getDefaultLogger();

  var GENERAL_COLUMN_SETTINGS = {
    textAlign: 'text-align-left',
    minWidth: 65,
    maxWidth: Infinity
  };

  var chooseTableCellBase = function (definition) {
    var type;
    type = (definition.treeColumn) ? 'tree' : '';
    switch (type) {
      case 'tree':
        return TreeTableCellView;
      default:
        return Ember.Table.TableCell.extend({
          init: function () {
            this._super.apply(this, arguments);
            var contentProperty = this.get('column.contentPath');
            return this.addObserver("row.content." + contentProperty, this, this.contentDidChange);
          },
          contentDidChange: function () {
            return this.notifyPropertyChange('cellContent');
          },
          attributeBindings: ['title'],
          title: function () {
            var contentObj = (this.get('cellContent')); // ? this.get('cellContent').toString() : '';
            var content = '';
            if (contentObj) {
              if (jsutils.isObject(contentObj)) {
                var value = contentObj.value;
                if (typeof value === "undefined") {
                  value = contentObj.values;
                  if (typeof value !== "undefined") {
                    value = value.toString();
                  } else if (typeof contentObj.toString() !== "undefined"){
                    value = contentObj.toString();
                  }
                }
                if (typeof value !== "undefined") {
                  content = value;
                }
              } else {
                content = contentObj.toString();
              }
            }

            var tooltip = (jsutils.isHTML(content)) ? '' : content;
            return tooltip;
          }.property('cellContent')
        });
    }
  };

  var addRenderer = function (CellView, definition) {
    return CellView.reopen({
      template: Ember.Handlebars.compile(definition.render)
    });
  };

  var addEditor = function (CellView, definition) {
    return CellView.extend({
      isEnabled: Ember.computed(function(){
        if (definition.isEnabled === undefined){
          return true;
        } else if (typeof definition.isEnabled === 'string'){
          return this.get('row.content.' + definition.isEnabled);
        } else if (typeof definition.isEnabled === 'function'){
          return definition.isEnabled.call(this, this.get('row.content'));
        }
        return true;
      }),
      isDisabled: Ember.computed.not('isEnabled'),
      classNameBindings: ['isDisabled: disabled'],
      template: Ember.Handlebars.compile('<div class="ember-table-content">{{editable-field content=view.cellContent afterEdit="editHandler"}}</div>'),
      save: definition.save
    });
  };

  var addDrag = function (CellView) {
    return CellView.reopen(DragEmberMixin, {
      sendDragContent: function () {
        return this.get('row.content');
      }
    });
  };

  var addHandlers = function (CellView, definition) {
    var events = {};
    definition.handlers.forEach(function (handler) {
      events[handler.event.camelize()] = function () {
        handler.execute(this.get('row.content'));
        return this._super.apply(this, arguments);
      };
    });
    return CellView.reopen(events);
  };



  /**
   * Creates ember table column
   * definition object
   * @param {object} definition Column definition object provided by the user
   */
  function defineColumn(definition) {
    if (!jsutils.isObject(definition)) {
      log.error('Table Widget: failed to define column. The argument should be an object type');
      return;
    }
    var columnDefinition = {
      headerCellName: definition.translatable === false ?
					definition.title : localization.translate(definition.title, definition.translationParams),
			originalHeaderName: definition.title,
			translatable: definition.translatable,
			id: definition.id,
      contentPath: definition.field,
			columnWidth: definition.width,
      tableCellViewClass: chooseTableCellBase(definition),
      isResizable: (definition.resizable === void 0) ? true : definition.resizable
    };
		if (typeof definition.isVisible === 'boolean'){
			columnDefinition.isVisible = definition.isVisible;
		}
    //var FluidColumn = FluidColumnClass.create(definition);
    var CellView = chooseTableCellBase(definition);

    // enable editing if applicable
    if (definition.editable !== void 0 && definition.editable === true) {
      CellView = addEditor(CellView, definition);
    }

    // append renderer is applicable
    if (jsutils.isFunction(definition.render)) {
      columnDefinition.getCellContent = function (row) {
				var model = row.get ? row.get('content') : row,
						str = definition.render(model);
        return Ember.String.htmlSafe(str);
      };
    }
    if (typeof definition.render === 'string') {
      CellView = addRenderer(CellView, definition);
    }

    if (definition.draggable === true) {
      CellView = addDrag(CellView);
    }

    // append event handlers to the cell if applicable
    if (Array.isArray(definition.handlers)) {
      CellView = addHandlers(CellView, definition);
    }

    columnDefinition.tableCellViewClass = CellView;

    return FluidColumn.create(jsutils.merge(columnDefinition, GENERAL_COLUMN_SETTINGS));
  }

  return {
    defineColumn: defineColumn
  };

});