import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import { getGeneralSvgIconHref } from 'core/services/iconService';
import { classNames } from 'utilities/classNames';
import Header from 'components/common/headers/Header';

function EditionSelector({ visible, editions = [], selectedEdition, onSelect }) {

  const getStyle = () => {
    if (!visible || editions.length <= 0) {
      return { visibility: 'hidden' };
    }
  };

  const isSelected = (edition) => {
    return edition.displayName === selectedEdition?.displayName;
  };

  const handleSelect = (edition) => {
    if (edition.plannable) {
      onSelect(edition);
    }
  };

  return (
    <div className='edition-selector' style={getStyle()}>
      <Header>{translate('Edition')}</Header>
      <ul className='structure-list'>
        {editions.map(edition =>
          <li
            key={edition.displayName}
            className={classNames('structure-list-item', {
              selected: isSelected(edition),
              disabled: !edition.plannable
            })}
            onClick={() => handleSelect(edition)}
          >
            <div className='item-caption'>
              <div className='caption-text' title={edition.displayName}>
                {edition.displayName}
              </div>
              {edition.planned ?
                <svg className='caption-icon'>
                  <title>{translate('Planned')}</title>
                  <use xlinkHref={getGeneralSvgIconHref('check_circle')} />
                </svg>
                : null}
            </div>
          </li>
        )}
      </ul>
    </div>
  );
}

EditionSelector.propTypes = {
  visible: PropTypes.bool,
  editions: PropTypes.array,
  selectedEdition: PropTypes.object,
  onSelect: PropTypes.func,
};

export default EditionSelector;
