import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class List extends Component {
  static propTypes = {
    items: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.any
    ]),
    renderItem: PropTypes.any,
    style: PropTypes.object,
    className: PropTypes.string,
  }
  constructor(props) {
    super(props);
  }
  render() {
    const { items, renderItem, style, className } = this.props;
    return <ul className={`crtx-list ${className}`} style={style}>
      {items.map((listItem, index) => (
        <li key={index} className='crtx-list-item'>
          {renderItem(listItem, index)}
        </li>
      ))}
    </ul>
  };
}