import PropTypes from 'prop-types';
import React from 'react';
import CanvasComponent from './canvasComponent';
import { StageHelper } from './stageHelper';
import { deg2rad } from './utilities';

export class Stage extends CanvasComponent {
  static propTypes = {
    rotation: PropTypes.number,
    zoom: PropTypes.number,
    point: PropTypes.shape({ // offset point
      x: PropTypes.number,
      y: PropTypes.number
    }),
    flipHorizontal: PropTypes.bool,
    flipVertical: PropTypes.bool,
    canvasDraw: PropTypes.func
  };

  static defaultProps = {
    rotation: 0,
    zoom: 1,
    point: { x: 0, y: 0 },
    flipHorizontal: false,
    flipVertical: false
  };

  childInstances = [];

  stageHelper = new StageHelper();

  constructor(props) {
    super(props);

    this.updateStageHelper(props);
  }

  updateStageHelper(props) {
    const { point, rotation, zoom, flipHorizontal, flipVertical } = props;

    this.stageHelper
      .setOffset(point.x, point.y)
      .setRotation(rotation)
      .setZoom(zoom)
      .setFlipHorizontal(flipHorizontal)
      .setFlipVertical(flipVertical);
  }

  subscribeToStage = (child) => {
    this.childInstances = this.childInstances.concat(child);

    return () => {
      this.childInstances = this.childInstances.filter(childInstance => childInstance !== child);
    };
  };

  draw = (ctx, canvasHelper) => {
    if (!canvasHelper) {
      return;
    }

    const { rotation, zoom, point, flipHorizontal, flipVertical } = this.props;
    const flipHorizontalValue = flipHorizontal ? -1 : 1;
    const flipVerticalValue = flipVertical ? -1 : 1;

    const canvasCenterPoint = canvasHelper.getCanvasCenterPoint();

    this.childInstances.forEach(child => {
      ctx.save();

      //rotation
      ctx.translate(canvasCenterPoint.x + point.x, canvasCenterPoint.y + point.y);
      ctx.rotate(deg2rad(rotation));

      //offset
      ctx.translate(child.props.point.x * zoom * flipHorizontalValue, child.props.point.y * zoom * flipVerticalValue);

      //zoom
      ctx.scale(zoom * flipHorizontalValue, zoom * flipVerticalValue);

      child.draw(ctx, canvasHelper);

      ctx.restore();
    });
  };

  componentDidUpdate(...args) {
    this.updateStageHelper(this.props);

    super.componentDidUpdate(...args);
  }

  render() {
    const { canvasDraw, children } = this.props;

    return React.Children.map(children, child => !child ? child : React.cloneElement(child, {
      subscribe: this.subscribeToStage,
      canvasDraw
    }));
  }
}