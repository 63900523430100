import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Panel extends Component {
  constructor(props) {
    super(props);
  }
  static propTypes = {
    className: PropTypes.string,
    icon: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.any
    ]),
    title:  PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.any
    ]),
  }
  render() {
    const { className, icon, title, children } = this.props;
    return <div className={`crtx-content-panel ${className}`}>
      {children}
    </div>;
  }
};

export default Panel;