import Filter from 'components/private/filter/ProductionRunsFilter';
import BaseTreeView from '../BaseTreeView/BaseTreeView';
import ProductionRunTreeNode from "./ProductionRunTreeNode";
import TreeDataAdapter from "./TreeDataAdapter";
import childrenTransforms from './childrenTransforms';
import FolderMenu from "../BaseTreeView/FolderMenu";

export default BaseTreeView.extend({
  initDone: function () {
    this._super();

    this.dataAdapter.setChildrenTransforms(childrenTransforms);
  },
  createTreeDataAdapter: function () {
    return new TreeDataAdapter({
      module: this
    });
  },
  createFolderMenu: function (dataAdapter) {
    return new FolderMenu(this, dataAdapter);
  },
  getTreeNodeComponent: function (node) {
    const TreeNodeComponent = node.type === 'productionrun' ? ProductionRunTreeNode : this._super(node);

    return TreeNodeComponent;
  },
  getFilterComponent: function () {
    return Filter;
  },
}, "ProductionRunsTree");
