/**
 * @name BadgeButton
 * @file Facebook style Badge button
 *
 * @author Boris
 * @since: 2017-03-07
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FlatButton from './FlatButton';

const THREE_DOTS = '\u2026';
const MAX_COUNTER = 99;

export default class BadgeButton extends Component {
  static propTypes = {
    counter: PropTypes.number,
    className: PropTypes.string,
    style: PropTypes.object,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
    onClick: PropTypes.func
  };

  static defaultProps = {
    disabled: false,
    checked: false
  };

  constructor(props) {
    super(props);
  }

  isAlternativeValue = () => {
    const { counter } = this.props;
    return counter > MAX_COUNTER;
  };

  renderCounter() {
    const { counter } = this.props;

    if (!counter || counter <= 0) {
      return null;
    }

    const badgeCounterValue = this.isAlternativeValue() ? THREE_DOTS : counter;    

    return (
      <div className='crtx-badge-button-counter' title={counter} >{badgeCounterValue}</div>
    );
  }

  render() {
    const { counter, children, className, checked, tooltip, ...props } = this.props;   
    const badgeClassName = `crtx-badge-button ${className || ''} ${checked ? 'checked' : ''} ${this.isAlternativeValue() ? 'alternative-counter' : ''}`;

    return (
      <FlatButton className={badgeClassName} {...props} tooltip={tooltip}>
        {children}
        {this.renderCounter()}
      </FlatButton>
    );
  }

}
