/**
 * @name Upload
 * @file Upload component
 *
 * @author Boris
 * @since: 2017-02-19
 */

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getFolderCurrentDate } from 'core/dates';
import requestManager from 'core/managers/request';
import { translate } from 'core/services/localization';
import qq from 'fine-uploader';
import List from './List';
import FileListItem from './FileListItem';
import FlatButton from 'components/common/buttons/FlatButton';
import CircularProgress from 'components/common/progress/CircularProgress';
import Spinner from 'components/common/progress/Spinner';
import settingsManager from 'core/managers/settings';
import { composeComparators, createObjectComparator, COMPARE_TYPE } from 'core/comparators';
import { useForceUpdate } from 'utilities/hooks';
import { arrayToObject } from 'utilities/array';

const DELETE_KEY = 46;

const ALLOWED_EXTENSIONS = ['pdf'];

const STATUSES_TO_REMOVE = {
  DeletedPage: true,
  Planned: true,
  DroppedPage: true
};

function translateServerError(error) {
  if (!error || typeof error !== 'string') {
    return '';
  }

  if (error.startsWith('{')) {
    const err = JSON.parse(error);
    return translate(err.message, ...(err.params || []));
  }

  return translate(error);
}

export default function Upload({ model, onContextMenu, onFileCountChange, onHistoryButtonClick, onDeleteFiles, onDoubleClick }) {

  const uploader = useRef(null);
  const dnd = useRef(null);
  const dropZoneNode = useRef(null);
  const uploadFilesRef = useRef([]);
  const selectedPublicationNameRef = useRef(model.publications[0]?.name || '');
  const [readyToDrag, setReadyToDrag] = useState(false);
  const [thumbnailView, setThumbnailView] = useState(false);
  const [lastClickedFile, setLastClickedFile] = useState(null);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const dndCallbacks = {
      processingDroppedFiles,
      processingDroppedFilesComplete
    };

    const uploaderCallbacks = {
      onComplete,
      onProgress,
      onStatusChange,
      onError,
      onSubmit,
      onUpload,
      onValidateBatch,
      onValidate
    };

    initSplitter();

    initUploader(dndCallbacks, uploaderCallbacks);
  }, []);

  useEffect(() => {
    const { unplannedFiles } = model;
    const modelFilesObj = arrayToObject(unplannedFiles, 'fileId');
    const uploadFilesObj = arrayToObject(uploadFilesRef.current, 'fileId');
    Object.keys(modelFilesObj).forEach(fileId => {
      if (STATUSES_TO_REMOVE[modelFilesObj[fileId].status]) {
        delete uploadFilesObj[fileId];
      } else if (uploadFilesObj[fileId]) {
        let uploadFile = uploadFilesObj[fileId];
        let modelFile = modelFilesObj[fileId];
        let uploadDate = uploadFile.uploadDate || modelFile.uploadDate;
        if (!modelFile.uploadedSize) {
          modelFile.uploadedSize = parseInt(modelFile.size);
        }
        if (!modelFile.expectedName && modelFile.targetObject) {
          modelFile.expectedName = modelFile.targetObject.name;
        }
        let versionNwid = modelFile?.targetObject?.pageContent?.versionNwid;
        if (versionNwid && versionNwid.replace(/0/g, '')) {
          modelFile.timestamp = versionNwid;
        }
        uploadFilesObj[fileId] = { ...uploadFile, ...modelFile, uploadDate };
      } else {
        uploadFilesObj[fileId] = modelFilesObj[fileId];
      }
    });

    const uploadFiles = Object.values(uploadFilesObj).sort(composeComparators([createObjectComparator('uploadDate', COMPARE_TYPE.DATES, false), createObjectComparator('name')]));
    uploadFilesRef.current = [...uploadFiles];
    forceUpdate();
  }, [model]);

  useEffect(() => {
    if (onFileCountChange) {
      onFileCountChange(uploadFilesRef.current.length);
    }
  }, [uploadFilesRef.current.length]);

  const getFileCountByPublication = () => {
    return uploadFilesRef.current.reduce((acc, f) => {
      if (acc[f.publicationName]) {
        acc[f.publicationName]++;
      } else {
        acc[f.publicationName] = 1;
      }
      return acc;
    }, {});
  };

  const attachDropZoneRef = (node) => {
    if (node) {
      dropZoneNode.current = node;
      dropZoneNode.current.setAttribute('qq-hide-dropzone', '');
    }
  };

  const initSplitter = () => {
    $('.upload-view').kendoSplitter({
      panes: [{ size: '400px', collapsible: true }, {}]
    });
  };

  const processingDroppedFiles = () => {
    //TODO: display some sort of a "processing" or spinner graphic
    //console.log('###processingDroppedFiles=>');
  };

  const processingDroppedFilesComplete = (files, dropTarget) => {
    //TODO: hide spinner/processing graphic
    //console.log('###processingDroppedFilesComplete=> files & dropTarget=', files, dropTarget);

    // const {model, selectedPublication} = this.state;
    // const publicationName = !selectedPublication ? '' : selectedPublication.name;
    // const customParams = {
    //   publicationName,
    //   hotFolder: model.hotFolder
    // };
    //
    // const params = {
    //   customParams: JSON.stringify(customParams)
    // };

    files.forEach(f => f.dropTarget = dropTarget);

    //this.uploader.addFiles(files, params); //this submits the dropped files to Fine Uploader
    uploader.current.addFiles(files); //this submits the dropped files to Fine Uploader
  };

  const onComplete = (id, name, responseJSON, maybeXhr) => {
    //console.log('###onComplete=>', id, name, responseJSON, maybeXhr);
    // TODO: remove progress
  };

  const onProgress = (id, name, loaded, total) => {
    //console.log('###onProgress=>', id, name, loaded, total);

    updateFileProp(id, 'uploadedSize', loaded);
  };

  const onSubmit = (id, name) => {
    //console.log('###onSubmit=>', id, name);

  };

  const onUpload = (id, name) => {
    //console.log('###onUpload=>', id, name);
  };

  const onValidateBatch = fileOrBlobData => {
    //console.log('###onValidateBatch=>', fileOrBlobData);

    const folderNwid = model.folderNwid;
    const publicationName = selectedPublicationNameRef.current || '';
    const customParams = {
      folderNwid,
      publicationName,
      hotFolder: model.hotFolder,
      autoTurnOn: model.autoTurnOn
    };

    const params = {
      customParams: JSON.stringify(customParams)
    };

    const uploads = uploader.current.getUploads();

    uploads.forEach(item => {
      uploader.current.setParams(params, item.id);
    });

    prependNewFiles(uploads, publicationName);

    //***TEST
    // const uploadId = uploads[uploads.length - 1].id;
    // const button = this.uploader.getButton(uploadId);
    // const file = this.uploader.getFile(uploadId);
  };

  const onValidate = (fileOrBlobData) => {
    //console.log('###onValidate=>', fileOrBlobData);
  };

  const onStatusChange = (id, oldStatus, newStatus) => {
    //console.log('###onStatusChange=>', id, oldStatus, newStatus);

    updateFileProp(id, 'status', newStatus);
  };

  const onError = (id, name, reason, maybeXhrOrXdr) => {
    //console.error('###onError => id, name, reason', id, name, reason);

    updateFileProp(id, 'message', translateServerError(reason));
  };

  const updateFileProp = (id, propName, propValue) => {
    const updatedUploadFiles = [...uploadFilesRef.current];
    const idx = updatedUploadFiles.findIndex(f => f.uploadId === id);
    if (idx >= 0) {
      updatedUploadFiles[idx] = { ...updatedUploadFiles[idx], [propName]: propValue };
    }
    uploadFilesRef.current = updatedUploadFiles;
    forceUpdate();
  };

  const initUploader = (dndCallbacks, uploaderCallbacks) => {
    uploader.current = new qq.FineUploaderBasic({
      //debug: true,
      request: {
        endpoint: `${settingsManager.get('webAppPath')}/rest/v1/fileUploader/upload`
      },
      chunking: {
        enabled: true,
      },
      retry: {
        enableAuto: false
      },
      resume: {
        enabled: true
      },
      // validation: {
      //   acceptFiles: ALLOWED_EXTENSIONS.map((ext) => '.' + ext).join(', '),
      //   allowedExtensions: ALLOWED_EXTENSIONS,
      //   stopOnFirstInvalidFile: false,
      //   title: 'Upload file'
      // },
      text: {
        fileInputTitle: 'Upload files',
      },
      showMessage: function (message) { //show message if any error occur during uplaod process
        console.error('Uploader.showMessage=> message=', message);
      },
      // paste: {
      //   targetElement: document.getElementById('pasteTarget'),
      //   promptForName: true,
      //   namePromptMessage: "Please name this image"
      // },

      extraButtons: [
        {
          element: document.getElementById('uploadButton'),
          // validation: {
          //   allowedExtensions: ALLOWED_EXTENSIONS,
          //   title: 'Upload file'
          // }
        }
      ],

      callbacks: uploaderCallbacks

      // onSubmit: function(id, name) {},
      // onSubmitted: function(id, name) {},
      // onComplete: function(id, name, responseJSON, maybeXhr) {},
      // onAllComplete: function(successful, failed) {},
      // onCancel: function(id, name) {},
      // onUpload: function(id, name) {},
      // onUploadChunk: function(id, name, chunkData) {},
      // onUploadChunkSuccess: function(id, chunkData, responseJSON, xhr) {},
      // onResume: function(id, fileName, chunkData) {},
      // onProgress: function(id, name, loaded, total) {},
      // onTotalProgress: function(loaded, total) {},
      // onError: function(id, name, reason, maybeXhrOrXdr) {},
      // onAutoRetry: function(id, name, attemptNumber) {},
      // onManualRetry: function(id, name) {},
      // onValidateBatch: function(fileOrBlobData) {},
      // onValidate: function(fileOrBlobData) {},
      // onSubmitDelete: function(id) {},
      // onDelete: function(id) {},
      // onDeleteComplete: function(id, xhrOrXdr, isError) {},
      // onPasteReceived: function(blob) {},
      // onStatusChange: function(id, oldStatus, newStatus) {},
      // onSessionRequestComplete: function(response, success, xhrOrXdr) {}
    });

    uploader.current.setCustomHeaders({
      viewOrActionNwId: model.viewNwid
    });

    dnd.current = new qq.DragAndDrop({
      dropZoneElements: [dropZoneNode.current],
      classes: {
        dropActive: 'uv-drop-zone-active'
      },
      callbacks: dndCallbacks
    });

    //this.dnd.setupExtraDropzone(this.dropZoneNode);
  };

  const prependNewFiles = (files, publicationName) => {
    const uploadDate = getFolderCurrentDate();
    let updatedUploadFiles = [];
    files.forEach(f => {
      if (f.status === qq.status.SUBMITTING) {
        updatedUploadFiles.push({
          uploadId: f.id,
          fileId: f.uuid,
          name: f.name,
          size: f.size,
          uploadedSize: 0,
          status: f.status,
          publicationName,
          uploadDate
        });

        //***TEST
        //setTimeout(() => this.uploader.cancel(f.id), 200);
      }
    });

    updatedUploadFiles.sort(createObjectComparator('name'));
    uploadFilesRef.current = updatedUploadFiles.concat(uploadFilesRef.current);
    forceUpdate();
  };

  const countReadyToDrag = files => {
    return files.reduce((c, f) => isReadyToDrag(f) ? c + 1 : c, 0);
  };

  const isReadyToDrag = file => {
    //return file.status === qq.status.UPLOAD_SUCCESSFUL;
    return file.status === 'Unplanned' || file.status === 'MultiplePages';
  };

  const filterFiles = () => {
    const publicationName = selectedPublicationNameRef.current || '';

    const files = [];

    uploadFilesRef.current.forEach(f => {
      if (f.publicationName === publicationName) {
        files.push(f);
      }
    });

    return files;
  };


  const getThumbnailUrl = file => {
    if (!file || !file.objectContentNwId || !file.objectContentTemplate || !file.timestamp) {
      return '';
    }

    const { module } = model;

    const params = {
      template: file.objectContentTemplate,
      action: 'icon',
      viewId: module.id,
      nwid: file.objectContentNwId,
      iconUrlCounter: file.timestamp,
      projectorId: module.projectorId
    };

    return requestManager.getImageUrl(params, true);
  };

  const getSelectedFiles = () => {
    const files = [];
    uploadFilesRef.current.forEach(f => {
      if (f.selected) {
        files.push(f);
      }
    });

    return files;
  };

  const selectFile = (file, selected) => {
    if (!selected) {
      file.selected = false;
    } else if (file.targetObject && file.targetObject.nwid || file.nwid) {
      file.selected = true;
    }
  };

  const calcPercent = file => {
    return Math.round(file.uploadedSize / file.size * 100);
  };

  const handlePublicationMouseDown = (e, publication) => {
    //console.log("handlePublicationMouseDown=> publication=", publication);

    uploadFilesRef.current.forEach(f => {
      f.selected = false;
    });

    selectedPublicationNameRef.current = publication.name;
    forceUpdate();
    setLastClickedFile(null);
  };

  const handleFileMouseDown = (e, file) => {
    //console.log("handleFileMouseDown() => file=", file);
    if (e.button === 1 || !file) {
      return;
    }

    let updatedLastClickedFile = lastClickedFile;
    const updatedUploadFiles = uploadFilesRef.current;
    if (e.ctrlKey) {
      if (e.button === 0) {
        updatedLastClickedFile = file;
        selectFile(file, !file.selected);
      }
    } else if (e.shiftKey) {
      var idx1 = 0;
      if (lastClickedFile) {
        idx1 = updatedUploadFiles.findIndex(f => f.fileId === lastClickedFile.fileId);
        if (idx1 < 0) {
          idx1 = 0;
        }
      }

      var idx2 = updatedUploadFiles.findIndex(f => f.fileId === file.fileId);
      if (idx2 >= 0 && idx2 < idx1) {
        [idx1, idx2] = [idx2, idx1];
      }

      updatedUploadFiles.forEach((f, idx) => {
        selectFile(f, idx >= idx1 && idx <= idx2 && f.publicationName === selectedPublicationNameRef.current);
      });
    } else {
      updatedLastClickedFile = file;
      if (e.button === 0 || !file.selected) {
        updatedUploadFiles.forEach(f => {
          selectFile(f, f.fileId === file.fileId);
        });
      }
    }

    uploadFilesRef.current = updatedUploadFiles;
    setLastClickedFile(updatedLastClickedFile);
    forceUpdate();
  };

  const handleFileContextMenu = (e, file) => {
    //console.log("handleFileContextMenu() => file=", file);
    if (!file.selected) {
      return;
    }

    if (onContextMenu) {
      onContextMenu(e, file, getSelectedFiles());
    }
  };

  const handleKeyDown = e => {
    //console.log("handleKeyDown() => e=", e);

    if (e.keyCode === DELETE_KEY && onDeleteFiles) {
      onDeleteFiles(e, getSelectedFiles());
    }
  };

  const handleThumbnailViewButtonClick = e => {
    //console.log("handleThumbnailViewButtonClick() ---");
    setThumbnailView(true);
  };

  const handleTableViewButtonClick = e => {
    //console.log("handleTableViewButtonClick() ---");
    setThumbnailView(false);
  };

  const handleHistoryButtonClick = e => {
    if (onHistoryButtonClick) {
      onHistoryButtonClick(e);
    }
  };

  const handleUploadPageDoubleClick = (e, file) => {
    if (onDoubleClick) {
      onDoubleClick(file);
    }
  };

  const handleDropZoneDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = 'copy';
  };

  const handleBadgeButtonClick = () => {
    //console.log("handleBadgeButtonClick() ---");

    setReadyToDrag(!readyToDrag);
  };

  const handleDragStart = (e, file) => {
    const data = {
      nwid: file.targetObject.nwid,
      type: file.targetObject.type,
      name: file.name,
      publication: file.publicationName,
      uploaded: true
    };
    e.dataTransfer.setData('text', JSON.stringify([data]));
  };

  const renderUploadTitlebar = readyToDragCount => {
    const title = selectedPublicationNameRef.current || '';
    const unplannedBtnCls = 'unplanned-button';
    const thumbnailBtnCls = `thumbnail-view-button ${thumbnailView ? 'checked' : ''}`;
    const tableBtnCls = `table-view-button ${thumbnailView ? '' : 'checked'}`;

    return (
      <div className='uv-upload-panel-titlebar'>
        <div className='uv-upload-panel-title'>{title}</div>
        {/*
         <BadgeButton className={unplannedBtnCls}
         counter={readyToDragCount}
         checked={readyToDrag}
         onClick={this.handleBadgeButtonClick}
         />
         */}
        <FlatButton className={thumbnailBtnCls}
          tooltip={translate('Thumbnail view')}
          onClick={handleThumbnailViewButtonClick}
        />
        <FlatButton className={tableBtnCls}
          tooltip={translate('Table view')}
          onClick={handleTableViewButtonClick}
        />
        <div id='uploadButton' role='button' className='uv-upload-button'></div>
        <FlatButton className='history-button'
          tooltip={translate('Upload history')}
          onClick={handleHistoryButtonClick}
        >
          <i className='material-icons'>history</i>
        </FlatButton>
      </div>
    );
  };

  const renderPublicationsPanelTitlebar = () => {
    return (
      <div className='uv-publications-panel-titlebar'>
        <div className='uv-publications-panel-title'>
          {translate('Publications')}
        </div>
      </div>
    );
  };

  const getUpdatedPublications = () => {
    const publicationFileCount = getFileCountByPublication();
    return model.publications.map(p => {
      const updatedPublication = {
        ...p,
        selected: p.name === selectedPublicationNameRef.current,
        fileCount: publicationFileCount[p.name],
        icon: { className: 'uv-folder-icon' }
      };
      return updatedPublication;
    });
  };

  const renderPublicationsPanel = () => {
    const updatedPublications = getUpdatedPublications();
    return (
      <div className='uv-publications-panel'>
        {renderPublicationsPanelTitlebar()}

        <List items={updatedPublications}
          className='uv-publications'
          idField='nwid'
          nameField='name'
          countField='fileCount'
          onItemMouseDown={handlePublicationMouseDown}
        />
      </div>
    );
  };

  const renderDropLabel = () => {
    return (
      <div className='uv-drop-label'>{translate('Drop files here')}</div>
    );
  };

  const renderThumbnailItem = file => {
    const thumbnailUrl = getThumbnailUrl(file);
    //console.log('###Upload.renderThumbnailItem() thumbnailUrl=', thumbnailUrl);
    let draggable = false;
    let dragStartHandler = null;
    let percent = 0;
    if (file.status === 'Unplanned') {
      draggable = true;
      dragStartHandler = handleDragStart;
      percent = 100;
    } else if (file.status === 'Uploaded') {
      percent = 100;
    } else {
      percent = calcPercent(file);
    }

    const className = `thumbnail-item ${file.selected ? 'selected' : ''}`;

    return (
      <div key={file.fileId}
        className={className}
        tabIndex='0'
        onMouseDown={e => handleFileMouseDown(e, file)}
        onContextMenu={e => handleFileContextMenu(e, file)}
        onKeyDown={e => handleKeyDown(e)}
        onDoubleClick={e => handleUploadPageDoubleClick(e, file)}
      >
        <div className='thumbnail'
          draggable={draggable}
          onDragStart={e => dragStartHandler(e, file)}
        >
          {thumbnailUrl ? <img src={thumbnailUrl} alt="" /> :
            percent < 100 ? <CircularProgress value={percent} /> :
              <Spinner />}
        </div>
        <div className='thumbnail-name' title={file.name}>
          {file.name}
        </div>
      </div>
    );
  };

  const renderFileThumbnails = files => {
    return files.map((file) => renderThumbnailItem(file));
  };

  const renderFileTable = files => {
    //const { onDoubleClick } = this.props;
    return (
      <List ListItem={FileListItem}
        idField='fileId'
        nameField='name'
        items={files}
        onItemMouseDown={handleFileMouseDown}
        onDragStart={handleDragStart}
        onContextMenu={handleFileContextMenu}
        onKeyDown={handleKeyDown}
        onDoubleClick={handleUploadPageDoubleClick}
      />
    );
  };

  const renderFiles = files => {
    if (thumbnailView) {
      return (
        <div className='uv-file-thumbnails'>
          {renderFileThumbnails(files)}
        </div>
      );
    } else {
      return (
        <div className='uv-file-table'>
          {renderFileTable(files)}
        </div>
      );
    }
  };

  const renderDropZone = files => {
    return (
      <div className='uv-drop-zone-container'>
        {files.length > 0 ? renderFiles(files) : renderDropLabel()}
        <div onDragOver={handleDropZoneDragOver} className='uv-drop-zone' ref={attachDropZoneRef}>
          {renderDropLabel()}
        </div>
      </div>
    );
  };

  const renderUploadPanel = () => {

    let files = filterFiles();
    let readyToDragCount = 0;
    if (readyToDrag) {
      files = files.filter(f => isReadyToDrag(f));
      readyToDragCount = files.length;
    } else {
      readyToDragCount = countReadyToDrag(files);
    }

    return (
      <div className='uv-upload-panel'>
        {renderUploadTitlebar(readyToDragCount)}
        {renderDropZone(files)}
      </div>
    );
  };

  return (
    <div className='upload-view'>
      {renderPublicationsPanel()}
      {renderUploadPanel()}
    </div>
  );
}

Upload.propTypes = {
  model: PropTypes.object,
  onContextMenu: PropTypes.func,
  onFileCountChange: PropTypes.func,
  onHistoryButtonClick: PropTypes.func,
  onDeleteFiles: PropTypes.func,
  onDoubleClick: PropTypes.func
};