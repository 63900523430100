import React from 'react';
import PropTypes from 'prop-types';
import Button from 'widgets/ReactComponents/src/Button/Button';
import Dropdown from 'components/common/dropdown/Dropdown';

const { Component } = React;

module.exports = class SplitButton extends Component {

  static propTypes = {
    // common props
    className: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,

    // button props
    tooltip: PropTypes.string,
    imageSrc: PropTypes.string,
    iconClassName: PropTypes.string,
    onClick: PropTypes.func,
    iconName: PropTypes.string,

    // dropdown props
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    options: PropTypes.array,
    optionContentGetter: PropTypes.func,
    popupHeight: PropTypes.number,
    onSelect: PropTypes.func
  };

  static defaultProps = {
    className: 'split-button',
    disabled: false,
    tooltip: '',
    iconClassName: 'button-icon'
  };

  handleClick = (event) => {
    const { disabled, onClick } = this.props;

    if (disabled || typeof onClick !== 'function') {
      return;
    }

    onClick(event);
  };

  handleSelect = (event, value) => {
    const { disabled, onSelect } = this.props;

    if (disabled || typeof onSelect !== 'function') {
      return;
    }

    onSelect(event, value);
  };

  render() {
    const {
      style,
      disabled,
      iconClassName,
      imageSrc,
      tooltip,
      value,
      options,
      optionContentGetter,
      popupHeight,
      iconName
    } = this.props;
    const className = disabled ? `${this.props.className} disabled` : this.props.className;

    return (
      <div className={className} style={style}>
        <Button
          iconClassName={iconClassName}
          imageSrc={imageSrc}
          tooltip={tooltip}
          onClick={this.handleClick}
          iconName={iconName}
        />
        <Dropdown
          value={value}
          options={options}
          itemGetter={optionContentGetter}
          height={popupHeight}
          onSelect={this.handleSelect}
        />
      </div>
    );
  }
};

