import AbstractModule from 'AbstractModule';
import React from 'react';
import { createRoot } from 'react-dom/client';
import icons from 'core/services/iconService';
import localization from 'core/services/localization';
import jsutils from 'base/jsUtils';
import TickableModel from '../TickableModel';
import Resources from './Resources';
import { arrayToObject, moveItem } from 'utilities/array';
import { makeResourcesTableColumns } from './columnsCreator';
import { createObjectComparator, composeComparators } from 'core/comparators';
import { DEFAULT_SORT, RESOURCES_NOT_PERMITTED_TO_DELETE } from './constants';
import { startInternalModule } from 'core/managers/module';
import { savePreferences } from 'core/managers/preferences';
import { openConfirmDialog, openAlertDialog } from 'core/services/dialogService';
import { formatBreadcrumbs } from 'utilities/breadcrumbs';
import { restPost } from '../../core/managers/rest2';
import { createToastService } from 'core/services/toastService';
import {
  checkNumberCondition,
  checkTextCondition,
  extractColumnPreferences,
  reduceColumnsToFilterBy,
  FILTER_DATA_TYPE,
  FILTER_TYPE,
  reduceColumnsToSortBy,
  applyColumnSortPreferences,
  extractColumnSortPreferences
} from 'widgets/ReactDataGrid/utils';

const THROTTLE_WAIT = 1000;

const { translate } = localization;

const EDIT_RESOURCE_ACTION_BY_TYPE = {
  'physical/image/inkc': 'EditResourceSetupActionCR',
  'physical/image/pdfprocessor': 'EditResourceSetupActionCR',
  'physical/input/hotfolder': 'EditHotFolderSetupActionCR',
  'physical/input/status': 'EditStatusStepSetupActionCR',
  'physical/image/rip': 'EditRipSetupActionCR',
  'physical/output/proout': 'EditProoutSetupActionCR',
  'physical/rip2ctp': 'EditRipOutSetupActionCR',
  'physical/comm/tx': 'EditTXSetupActionCR',
  'physical/image/tiffprocessor': 'EditTiffProcessoSetupActionCR',
  'physical/image/preflight': 'EditPreflightSetupActionCR',
  'physical/image/optimization': 'EditOptimizationSetupActionCR',
  'physical/comm/externalprotocol': 'EditExternalProtocolSetupActionCR',
  'physical/output/proofer': 'EditProoferSetupActionCR',
  'physical/output/cache': 'EditCacheSetupActionCR',
  'physical/flow/cmdline': 'EditCmdLineSetupActionCR',
  'physical/flow/fb': 'EditFBSetupActionCR',

  // demons
  'physical/image/composelowres': 'EditResourceSetupActionCR',
  'physical/image/hirestile': 'EditResourceSetupActionCR',
  'physical/image/pagelowres': 'EditResourceSetupActionCR',
  'physical/general/reporting': 'EditResourceSetupActionCR',
  'physical/image/seplowres': 'EditResourceSetupActionCR',
  'physical/output/mail': 'EditMailSetupActionCR'
};

const APPLICABLE_TYPES = [
  'physical/image/inkc',
  'physical/image/pdfprocessor',
  'physical/input/hotfolder',
  'physical/input/status',
  'physical/image/rip',
  'physical/output/proout',
  'physical/rip2ctp',
  'physical/comm/tx',
  'physical/image/tiffprocessor',
  'physical/image/preflight',
  'physical/image/optimization',
  'physical/comm/externalprotocol',
  'physical/output/proofer',
  'physical/output/cache',
  'physical/flow/cmdline',
  'physical/general/logic',
  'physical/flow/fb',

  // demons
  'physical/image/composelowres',
  'physical/image/hirestile',
  'physical/image/pagelowres',
  'physical/general/reporting',
  'physical/image/seplowres',
  'physical/output/mail',
  'physical/general/purge',
  'physical/general/task',
];

const REMOVE_RESOURCE_ACTION_BY_TYPE = {
  'physical/input/hotfolder': 'DeleteResourceActionCR',
  'physical/input/status': 'DeleteResourceActionCR'
};

export default AbstractModule.extend({

  initDone: function () {
    this.toolbar = this.createToolbar();
    this.updates = [];
    this.tickUpdateHandlerThrottled = jsutils.throttle(this.tickUpdateHandler, THROTTLE_WAIT, {
      leading: false,
      trailing: true
    });
    this.clientActions = [
      {
        name: 'flow_steps',
        label: 'Flow Steps',
        triggers: ['popup'],
        types: APPLICABLE_TYPES,
        _isApplicable: true,
        execute: function (resource) {
          if (resource && Array.isArray(resource) && resource[0]) {
            const currentResource = resource[0];
            const startParameters = {
              rootId: currentResource.nwid,
              rootType: currentResource.type,
              name: translate('{1} - Flow Steps', currentResource.name),
              target: 'dialog',
              windowWidth: 600,
              windowHeight: 300,
              win: this.win,
              resource: currentResource,
              navigateToFlowStep: (flowStep, target) => this.navigateToFlowStep(flowStep, target)
            };
            startInternalModule('ResourceFlowStepsSetup', startParameters, this);
          }
        }.bind(this)
      },
    ];
    this.reactRoot = createRoot(this.domElement);
  },

  firstTickReceived: function (data) {
    this.preferences = data.preferences || {};
    this.columnsToSortBy = (typeof data.preferences.columnsToSortBy === 'undefined' || data.preferences.columnsToSortBy.length === 0) ?
      DEFAULT_SORT : data.preferences.columnsToSortBy;
    this.viewType = data.model.type;

    this.filtersEnabled = (this.preferences.table || {}).filtersEnabled || false;

    this.initToolbar(data.model);

    this.tickableModel = new TickableModel();

    this.tickableModel.firstTickHandler(data.model);

    this.buildViewModel();
  },

  tickUpdate: function (data) {
    this.updates = this.updates.concat(data.model);
    this.tickUpdateHandlerThrottled();
  },

  tickUpdateHandler: function () {
    this.tickableModel.tickUpdateHandler(this.updates);
    this.updates = [];
    this.buildViewModel();
  },

  buildViewModel: function () {
    const model = this.tickableModel.model();

    this.viewModel = {};
    this.viewModel.resources = [...model.resources];
    this.viewModel.resourcesByNwid = arrayToObject(model.resources, 'nwid');

    this.viewModel.sortedResources = [...this.viewModel.resources];

    this.resourcesTableColumns = makeResourcesTableColumns(this.viewModel, this);

    this.resourcesTableColumnsByKeys = arrayToObject(this.resourcesTableColumns);

    this.sortResources();

    this.filterResouces();
  },

  savePreferences: function (preferences) {
    if (!preferences) {
      return;
    }

    this.preferences = Object.assign(this.preferences, preferences);
    savePreferences(this.getRequiredParameters(), this.preferences);
  },

  sortResources: function () {
    if (this.columnsToSortBy && this.columnsToSortBy.length > 0) {
      const preferencesColumnsToSortBy = applyColumnSortPreferences(this.resourcesTableColumns, this.columnsToSortBy);

      const comparator = composeComparators(preferencesColumnsToSortBy.map(col => {
        return createObjectComparator(col.sortValueGetter || col.key, col.sortType, col.ascending);
      }));

      this.viewModel.sortedResources = this.viewModel.sortedResources.sort(comparator);

      this.savePreferences({
        ...this.preferences,
        columnsToSortBy: extractColumnSortPreferences(this.columnsToSortBy)
      });
    }
  },

  filterResouces: function () {
    if (!this.filtersEnabled) {
      this.viewModel.resources = this.viewModel.sortedResources;
    } else {
      const columnsToFilterBy = reduceColumnsToFilterBy(this.resourcesTableColumns);

      this.viewModel.resources = this.viewModel.sortedResources.filter(resource => {

        let match = true;
        for (const col of columnsToFilterBy) {
          const filter = col.filter;
          if (filter.type === FILTER_TYPE.MULTISELECT) {
            if (Array.isArray(filter.selected) && filter.selected.length > 0) {
              const filterValue = col.filterValueGetter(resource);
              if (col.key === 'folders') { // in case of folders column filterValueGetter will return an array of folders
                match = filterValue.some(value => filter.selected.some(s => s === value));
              } else {
                match = filter.selected.some(s => s === filterValue);
              }
            }
          } else if (FILTER_TYPE.TEXT && filter.textValue) {
            if (filter.dataType === FILTER_DATA_TYPE.TEXT) {
              const text = typeof col.filterValueGetter === 'function' ? col.filterValueGetter(resource) : resource[col.key];
              match = checkTextCondition(text, filter);
            } else if (filter.dataType === FILTER_DATA_TYPE.NUMBER) {
              const number = typeof col.filterValueGetter === 'function' ? col.filterValueGetter(resource) : resource[col.key];
              match = checkNumberCondition(number, filter);
            }
          }

          if (!match) {
            break;
          }
        }

        return match;
      });
    }

    this.render();
  },

  initToolbar: function () {

    this.toolbar.addItem({
      label: translate('Toggle Filters'),
      name: 'toggleFilters',
      _isApplicable: true,
      icon: 'filter_list.svg',
      itemType: 'push',
      checked: this.filtersEnabled,
      execute: this.toggleFilters.bind(this)
    });
  },

  toggleFilters: function (pushed) {
    //console.log('########toggleFilters() pushed=', pushed);
    this.filtersEnabled = pushed;

    this.toolbar.setItemChecked('toggleFilters', pushed);

    this.buildViewModel();

    this.saveResourcesColumnPreferences();
  },

  saveResourcesColumnPreferences: function () {
    const resourceColumns = extractColumnPreferences(this.resourcesTableColumns);
    this.savePreferences({
      table: {
        ...this.preferences.table,
        filtersEnabled: this.filtersEnabled,
        resourceColumns,
      }
    });
  },

  handleResourcesTableColumnsFilter: function (columns) {
    this.resourcesTableColumns.forEach(col => {
      if (columns[col.key]) {
        col.visible = columns[col.key].visible;
      }
    });

    this.saveResourcesColumnPreferences();
  },

  handleResourcesTableColumnOrder: function (columns, oldIndex, newIndex) {
    moveItem(this.resourcesTableColumns, oldIndex, newIndex);

    this.saveResourcesColumnPreferences();

    this.render();
  },

  handleResourcesTableSelect: function (selectedRows) {
    const resources = selectedRows.map(row => row && this.tickableModel.getByNwid(row.nwid));
    this.updateSelected(resources.filter(e => !!e));
  },

  handleResourcesTableColumnResize: function (columns) {
    this.resourcesTableColumns.forEach(col => {
      if (columns[col.key]) {
        col.width = columns[col.key].width;
      }
    });

    this.saveResourcesColumnPreferences();
  },

  handleResourceTableContextMenu: function (resource, selected, e) {
    this.showContextMenu(resource, selected, e);
  },

  handleResourceColumnFilterChange: function (column, columnFilter) {
    if (!column || !column.filter || !column.filter.type) {
      return;
    }

    column.filter = {
      ...column.filter,
      ...columnFilter
    };

    this.filterResouces();

    this.saveResourcesColumnPreferences();
  },

  handleResourceColumnClick: function (sortType) {
    return (columnKey, sortValueGetter, multiSort) => {
      this.columnsToSortBy = reduceColumnsToSortBy(this.resourcesTableColumns, this.columnsToSortBy, columnKey, multiSort);
      this.sortResources();
      this.filterResouces();
    };
  },

  findResourceAction: function (resource, actionDefinitionName) {
    const actions = this.getRelevantActions(resource);
    return actions.find(a => a.actionDefinitionName === actionDefinitionName);
  },

  toggleResource: function (resource) {
    const unAssigned = resource.flowSteps && resource.flowSteps.length === 0 ? true : false;
    if (unAssigned) {
      openAlertDialog(translate('Unable to turn on an unassigned resource'), translate('Start Resource'));
      return;
    }
    const toggleAction = this.findResourceAction(resource, 'ToggleResourceActionCR');
    if (toggleAction) {
      toggleAction.execute([resource]);
    }
  },

  abortResource: function (resource) {
    const abortAction = this.findResourceAction(resource, 'AbortResourcesActionCR');
    if (abortAction) {
      abortAction.execute([resource]);
    }
  },

  getResourceLedIcon: function (resource) {
    let icon;

    if (!resource) {
      return;
    }

    if (resource.humanStateDescription === 'Up') {
      icon = {
        url: icons.getGeneralIcon('resource', 'led_on'),
        title: translate('Alive'),
        priority: 2
      };
    } else if (resource.humanStateDescription === 'Down') {
      icon = {
        url: icons.getGeneralIcon('resource', 'led_off'),
        title: translate('Dead'),
        priority: 3
      };
    } else {
      icon = {
        url: icons.getGeneralIcon('resource', 'led_unknown'),
        title: translate('Unknown'),
        priority: 1
      };
    }

    return icon;
  },

  canEditResource: function (resource) {
    return !!this.getEditResourceAction(resource);
  },

  getEditResourceAction: function (resource) {
    const actions = this.getRelevantActions(resource);

    return actions.find(a => a.actionDefinitionName === EDIT_RESOURCE_ACTION_BY_TYPE[resource.type]);
  },

  editResource: function (resource) {
    const editAction = this.getEditResourceAction(resource);
    if (editAction) {
      editAction.execute([resource]);
    }
  },

  canRemoveResource: function (resource) {
    return this.viewType === 'folder' ? !!this.getRemoveResourceAction(resource) : true;
  },

  getRemoveResourceAction: function (resource) {
    const actions = this.getRelevantActions(resource);

    return actions.find(a => a.actionDefinitionName === REMOVE_RESOURCE_ACTION_BY_TYPE[resource.type]);
  },

  getDeleteResouceDialogContent: function (resource) {
    return (
      <div>
        <div>{translate('Are you sure you want to delete this resource?')}</div>
        <div>{resource.name}</div>
        {resource && resource.flowSteps && resource.flowSteps.length > 0 &&
          <div>
            <br />
            <div>{translate('It is still assigned to the following flow steps:')}</div>
            {resource.flowSteps.map(flowStep => {
              const formattedBreadcrumbs = formatBreadcrumbs(flowStep.breadcrumbs);
              return (
                <div key={flowStep.nwid} title={formattedBreadcrumbs}>
                  {formattedBreadcrumbs}
                </div>);
            })}
          </div>
        }
      </div>
    );
  },

  removeResource: function (resource) {
    if (RESOURCES_NOT_PERMITTED_TO_DELETE[resource.type]) {
      openAlertDialog(translate('The {1} demon resource cannot be deleted', RESOURCES_NOT_PERMITTED_TO_DELETE[resource.type]), translate('Delete Resource'));
    } else {
      if (this.viewType === 'folder') {
        const removeAction = this.getRemoveResourceAction(resource);
        if (removeAction) {
          removeAction.execute([resource]);
        }
      } else {
        openConfirmDialog(this.getDeleteResouceDialogContent(resource), translate('Delete Resource'))
          .then(res => {
            const toastService = createToastService(this.win);
            restPost(this.nwid, `/que-manager/resources/${resource.nwid}/delete`)
              .then(response => {
                toastService.createToast('top', translate('Remove Resouce'), translate('The resource has been successfully deleted'), 'success', undefined, undefined);
              })
              .catch(err => {
                toastService.createToast('top', translate('Remove Resouce'), translate('Failed to delete resource: {1}', err), undefined, undefined, false);
              });
          });
      }
    }
  },

  navigateToFlowStep: function (flowStep, target) {
    this.navigateByViewLink(flowStep, { target });
  },

  destroy: function () {
    this._super();
    this.reactRoot.unmount();
  },

  render: function () {


    this.reactRoot.render(
      <Resources module={this}
        viewModel={this.viewModel}
        resourcesTableColumns={this.resourcesTableColumns} />);
  }
});