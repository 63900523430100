import React from 'react';
import PropTypes from 'prop-types';

function Indicator(
  {
    count,
    imageUrl,
    tooltip
  }) {

  return (count > 0 ?
      <div className='alert'>
        <img src={imageUrl} title={tooltip} />
        <span>{count}</span>
      </div> : null
  );
}

Indicator.propTypes = {
  count: PropTypes.number,
  imageUrl: PropTypes.string,
  tooltip: PropTypes.string
};

export default Indicator;