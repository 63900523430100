import React from 'react';
import Version from './Version';
import Language from './Language';
import LoginPoliciesLinks from './LoginPoliciesLinks';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

function Footer({ store, color }) {

  return <React.Fragment>
    <LoginPoliciesLinks
      linkStyle={{ color: color }}
      style={{ color: color }}
      store={store}
    />
    <div>
      <Language
        style={{ borderColor: color }}
        color={color}
        withGlobeIcon={true}
        store={store}
      />
      <div
        className='crtx-login-powered-by'
        style={{ color: color }}
      >
        Powered by ProImage
      </div>
      <Version
        style={{ color: color }}
        store={store}
      />
    </div>
  </React.Fragment>;
}

Footer.propTypes = {
  store: PropTypes.any,
  color: PropTypes.string
};

export default observer(Footer);



