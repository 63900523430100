/**
 * @name customConditions
 * @file Render custom conditions to be used by the other elements
 *
 * @author Boris
 * @since: 2018-05-02
 */

import React from 'react';
import sandbox from 'sandbox';
import ReactComponents from 'widgets/ReactComponents/src/index';
import SimpleForm from 'widgets/SimpleForm/src/index';
import Dropdown from 'components/common/dropdown/Dropdown';
import actions from '../redux/actions';
import propertiesCommon from "./propertiesCommon";

const {Button} = ReactComponents;
const {Row, Label, Input, Panel} = SimpleForm;
const {translate} = sandbox.localization;
const futils = SimpleForm.utils;

const XSMALL_BUTTON_CLASS = 'btn btn-default btn-xs';

const IS_BLACK_MACRO = '$IsBlack()';
const IS_FULL_CMYK_MACRO = '$IsFullCmyk()';
const NUMBER_OF_SEPARATIONS_MACRO = '$NumberOfSeparations()';
const CONTAINS_SPOT_MACRO = '$ContainsSpot()';

const IS_FORM_EVEN_MACRO = '$IsFormEven()';
const IS_FIRST_PAGE_IN_SECTION_MACRO = '$IsFirstPageInSection()';
const IS_LAST_PAGE_IN_SECTION_MACRO = '$IsLastPageInSection()';
const IS_FIRST_PAGE_IN_ZONE_MACRO = '$IsFirstPageInZone()';
const IS_LAST_PAGE_IN_ZONE_MACRO = '$IsLastPageInZone()';

const COLOR_CONDITION = {
  category: 'color',
  caption: translate('Forme content'),
  type: 'options',
  options: [
    {value: 'all', text: translate('All')},
    {value: 'black', text: translate('Black'), leftOperand: IS_BLACK_MACRO, operator: '==', rightOperand: 'true'},
    {
      value: 'notBlack',
      text: translate('Not black'),
      leftOperand: IS_BLACK_MACRO,
      operator: '==',
      rightOperand: 'false'
    },
    {
      value: 'fullCmyk',
      text: translate('Full CMYK'),
      leftOperand: IS_FULL_CMYK_MACRO,
      operator: '==',
      rightOperand: 'true'
    },
    {
      value: 'singleSeparation',
      text: translate('Single separation'),
      leftOperand: NUMBER_OF_SEPARATIONS_MACRO,
      operator: '==',
      rightOperand: '1'
    },
    {
      value: 'containsSpot',
      text: translate('Contains spot'),
      leftOperand: CONTAINS_SPOT_MACRO,
      operator: '==',
      rightOperand: 'true'
    }
  ]
};

const NUMBERING_CONDITION = {
  category: 'numbering',
  caption: translate('Numbering'),
  type: 'options',
  options: [
    {value: 'all', text: translate('All')},
    {
      value: 'odd',
      text: translate('Odd lower page number'),
      leftOperand: IS_FORM_EVEN_MACRO,
      operator: '==',
      rightOperand: 'false'
    },
    {
      value: 'even',
      text: translate('Even lower page number'),
      leftOperand: IS_FORM_EVEN_MACRO,
      operator: '==',
      rightOperand: 'true'
    },
    {
      value: 'firstPageInSection',
      text: translate('First page in section'),
      leftOperand: IS_FIRST_PAGE_IN_SECTION_MACRO,
      operator: '==',
      rightOperand: 'true'
    },
    {
      value: 'lastPageInSection',
      text: translate('Last page in section'),
      leftOperand: IS_LAST_PAGE_IN_SECTION_MACRO,
      operator: '==',
      rightOperand: 'true'
    },
    {
      value: 'firstPageInZone',
      text: translate('First page in zone'),
      leftOperand: IS_FIRST_PAGE_IN_ZONE_MACRO,
      operator: '==',
      rightOperand: 'true'
    },
    {
      value: 'lastPageInZone',
      text: translate('Last page in zone'),
      leftOperand: IS_LAST_PAGE_IN_ZONE_MACRO,
      operator: '==',
      rightOperand: 'true'
    }
  ]
};

const PREDEFINED_CONDITIONS_META = [
  COLOR_CONDITION,
  NUMBERING_CONDITION
];

const OPERATOR_OPTIONS = [
  {value: '==', text: '='},
  {value: '!=', text: '<>'},
  {value: '>', text: '>'},
  {value: '>=', text: '>='},
  {value: '<', text: '<'},
  {value: '<=', text: '<='},
  {value: 'IN', text: 'IN'},
  {value: 'NOT_EMPTY', text: 'Not Empty'},
];

const OPERAND_TOOLTIP = translate('Put text and/or macros here');

const buildPredefinedCondition = (value, element, meta) => {
  if (!value || value === 'all') {
    return null;
  }

  let result = null;
  const option = meta.options.find(o => o.value === value);
  if (option) {
    result = {
      value,
      category: meta.category,
      leftOperand: option.leftOperand,
      operator: option.operator,
      rightOperand: option.rightOperand,
      nextOperator: '&&'
    };
  }

  return result;
};

const handlePredefinedConditionSelect = (value, store, element, elementPath, meta) => {
  const conditions = element.conditions.filter(c => c.category !== meta.category);
  const cond = buildPredefinedCondition(value, element, meta);
  if (cond) {
    conditions.unshift(cond);
  }

  const path = futils.compose(elementPath, 'conditions');
  store.dispatch(actions.update(path, conditions));
};

const getPredefinedConditionValue = (element, meta) => {
  let result = 'all';

  let cond = element.conditions.find(c => c.category === meta.category);
  if (cond) {
    result = cond.value;
  }

  return result;
};

const renderPredefinedCondition = (store, element, elementPath, meta) => {
  const disabled = propertiesCommon.isPropertyDisabled(element, meta);

  return (
    <Row key={meta.category}>
      <Label offset={1} col={5}>{meta.caption}</Label>
      <div className='col col-6'>
        <Dropdown style={{width: '100%'}}
          value={getPredefinedConditionValue(element, meta)}
          options={meta.options}
          disabled={disabled}
          onSelect={(e, value) => handlePredefinedConditionSelect(value, store, element, elementPath, meta)} />
      </div>
    </Row>
  );
};

const renderPredefinedConditions = (store, element, elementPath) => {
  return (
    <div>
      <Row>
        <Label col={4}>{'Conditions:'}</Label>
      </Row>
      {PREDEFINED_CONDITIONS_META.map(meta => renderPredefinedCondition(store, element, elementPath, meta))}
    </div>
  );
};

const handleConditionOperatorSelect = (value, store, condition, conditionPath) => {
  if (value === condition.operator) {
    return;
  }

  let newCondition = {...condition, operator: value};
  if (value === 'IN') {
    newCondition.rightOperand = [condition.rightOperand || ''];
  } else if (condition.operator === 'IN') {
    newCondition.rightOperand = Array.isArray(condition.rightOperand) && condition.rightOperand.length > 0 ?
      condition.rightOperand[0] : '';
  }

  store.dispatch(actions.update(conditionPath, newCondition));
};

const handleAddConditionClick = (store, element, elementPath) => {
  const path = futils.compose(elementPath, 'conditions');

  const condition = {
    category: 'custom',
    leftOperand: '',
    operator: '==',
    rightOperand: '',
    nextOperator: '&&'
  };

  store.dispatch({
    type: 'ADD',
    path: path,
    index: element.conditions.length,
    value: condition
  });
};

const handleDeleteConditionClick = (store, elementPath, index) => {
  const path = futils.compose(elementPath, 'conditions');

  store.dispatch({
    type: 'REMOVE',
    path: path,
    index: index
  });
};

const handleAddOperandItemClick = (store, operandPath, index) => {
  store.dispatch({
    type: 'ADD',
    path: operandPath,
    index: index + 1,
    value: ''
  });
};

const handleDeleteOperandItemClick = (store, operandPath, index) => {
  store.dispatch({
    type: 'REMOVE',
    path: operandPath,
    index: index
  });
};

const renderOperandItem = (store, element, operand, operandPath, index) => {
  const disabled = element.locked;

  return (
    <Row>
      {index <= 0 ? <Label col={2}>{translate('Exp2')}</Label> : <Label col={2} />}
      <Input col={10} disabled={disabled} bind={futils.compose(operandPath, index)} style={{marginRight: 'auto'}}
        title={OPERAND_TOOLTIP} />
      <Button disabled={disabled}
        text={''}
        style={{marginLeft: '5px'}}
        className={XSMALL_BUTTON_CLASS}
        tooltip={translate('Add')}
        iconName={'add'}
        onClick={() => handleAddOperandItemClick(store, operandPath, index)}
      />
      <Button disabled={disabled || operand.length <= 1}
        text={''}
        style={{marginLeft: '5px', marginRight: '2px'}}
        className={XSMALL_BUTTON_CLASS}
        tooltip={translate('Delete')}
        iconName={'close'}
        onClick={() => handleDeleteOperandItemClick(store, operandPath, index)}
      />
    </Row>
  );
};

const renderExp2 = (store, element, condition, conditionPath) => {
  if (condition.operator === 'NOT_EMPTY') {
    return;
  }

  const disabled = element.locked;
  const operandPath = futils.compose(conditionPath, 'rightOperand');

  if (condition.operator !== 'IN') {
    return (
      <Row>
        <Label col={2}>{translate('Exp2')}</Label>
        <Input col={8} disabled={disabled} bind={operandPath} title={OPERAND_TOOLTIP} />
      </Row>
    );
  }

  const operand = Array.isArray(condition.rightOperand) && condition.rightOperand.length > 0 ?
    condition.rightOperand : [''];

  return (
    <Panel>
      {operand.map((condition, index) => renderOperandItem(store, element, operand, operandPath, index))}
    </Panel>
  );
};

const renderCondition = (store, element, elementPath, condition, index) => {
  if (condition.category !== 'custom') {
    return null;
  }

  const disabled = element.locked;
  const conditionPath = futils.compose(elementPath, 'conditions', index);
  const operator = condition.operator || '==';

  return (
    <div key={index} style={{display: 'flex'}}>
      <Panel>
        <Row>
          <Label col={2}>{translate('Exp1')}</Label>
          <Input col={10} disabled={disabled} bind={futils.compose(conditionPath, 'leftOperand')}
            title={OPERAND_TOOLTIP} />
        </Row>
        <Row>
          <Label col={2}>{translate('Operator')}</Label>
          <Dropdown style={{width: '110px'}}
            disabled={disabled}
            value={condition.operator}
            options={OPERATOR_OPTIONS}
            onSelect={(e, value) => handleConditionOperatorSelect(value, store, condition, conditionPath)}
          />
        </Row>
        {renderExp2(store, element, condition, conditionPath)}
      </Panel>
      <Button disabled={disabled}
        text={''}
        style={{marginLeft: '5px', marginTop: '3px', alignSelf: 'flex-start'}}
        className={XSMALL_BUTTON_CLASS}
        iconName={'close'}
        tooltip={translate('Delete')}
        onClick={() => handleDeleteConditionClick(store, elementPath, index)}
      />
    </div>
  );
};

const render = (store, element, elementPath) => {
  const disabled = element.locked;

  return (
    <div>
      {renderPredefinedConditions(store, element, elementPath)}
      <Row>
        <Label col={11}>{translate('Custom conditions:')}</Label>
        <Button disabled={disabled}
          text={''}
          style={{marginLeft: 'auto'}}
          className={XSMALL_BUTTON_CLASS}
          iconName={'add'}
          tooltip={translate('Add')}
          onClick={() => handleAddConditionClick(store, element, elementPath)}
        />
      </Row>
      {element.conditions.map((condition, index) => renderCondition(store, element, elementPath, condition, index))}
    </div>
  );
};

export default {
  render
};