import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import iconService from 'core/services/iconService';
import ProgressBar from 'components/common/progress/ProgressBar';
import IndeterminateProgressBar from 'components/common/progress/IndeterminateProgressBar';
import ResourceLed from './ResourceLed';
import logger from 'logger';
const log = logger.getDefaultLogger();

export default function ResourceComponent({ module, resource }) {

  const { isOn, eventsInfo, resourceName, humanStateDescription, lock, isProgressable } = resource;

  const toggleResource = () => {
    const toggleAction = module.viewActions.find(a => a.actionDefinitionName === 'ToggleResourceActionCR');
    if (typeof toggleAction === 'undefined') {
      log.error('Toggle resource action instance is not found');
      return;
    }
    toggleAction.execute([resource]);
  };


  const renderEventProgress = (event, index) => {

    return (
      <div key={index}>
        {isProgressable || event.empty ?
          < ProgressBar min={0}
            max={100}
            value={event.progress}
            vertical={true}
            animated={!event.empty}
          />
          :
          <IndeterminateProgressBar
            className='vertical-progress-bar'
            vertical={true}
          />
        }
      </div >
    );
  };


  return (
    <React.Fragment>
      {isOn ?
        <div>
          <a href="#" onClick={() => toggleResource()} title={translate('Toggle Resource')} alt=''>
            <img src={iconService.getGeneralIcon('resource', 'on')} />
          </a>
        </div> :
        <div>
          <a href="#" onClick={() => toggleResource()} title={translate('Toggle Resource')} alt=''>
            <img src={iconService.getGeneralIcon('resource', 'off')} />
          </a>
        </div >
      }
      <div><ResourceLed humanStateDescription={humanStateDescription} /></div>
      <div className='crtx-resource-name' title={resourceName}>{resourceName}</div>
      <div>
        <img src={iconService.getGeneralIcon('status', 'locked')} alt='' />
      </div>
      <div>{lock} </div>
      <div className='crtx-progress-container'>{eventsInfo.map(renderEventProgress)}</div>
    </React.Fragment >
  );
}

ResourceComponent.propTypes = {
  module: PropTypes.object,
  resource: PropTypes.object
};