import React from "react";
import { createRoot } from 'react-dom/client';
import { startModule } from 'core/managers/module';
import AbstractModule from "AbstractModule";
import TickableModel from "../TickableModel";
import sandbox from "sandbox";
import jsUtils from "base/jsUtils";
import { createStore } from "redux";
import reducer from "./reducer";
import {
  addSites,
  selectItems,
  updateFilter,
  filterItems,
  sortItems,
  toggleFilters,
  columnResize,
  updatePreferences,
  updateRowHeight
} from "./actionsCreator";
import Table, { Column, headers, cells } from "widgets/ReactDataGrid/";
import Cards from "components/common/cards/Cards";
import Card from "components/common/cards/Card";
import { reduceColumnsToSortBy, ROW_HEIGHT, ROW_HEIGHT_INCLUDING_THUMBNAIL } from 'widgets/ReactDataGrid/utils';
import { restGet } from 'core/managers/rest2';

const { GenericHeader, HeaderCaption } = headers;

const { translate } = sandbox.localization;

const isUndefined = o => typeof o === "undefined";
const isFunction = o => typeof o === "function";

const getRowHeight = columns => {
  const thumbnailColumn = columns['pageContentNwid'];
  return thumbnailColumn.visible ? ROW_HEIGHT_INCLUDING_THUMBNAIL : ROW_HEIGHT;
};

export default AbstractModule.extend({
  columnsCreator: () => {
    return [];
  },
  legend: {},
  init: function () {
    this._super();
    for (var prop in this) {
      if (prop === "_super") continue;
      if (isFunction(this[prop])) this[prop] = this[prop].bind(this);
    }
    this.debouncedFilterItems = jsUtils.debounce(
      this.debouncedFilterItems,
      500
    );

    this.tickableModel = new TickableModel();

  },

  initDone: function () {
    this.reactRoot = createRoot(this.domElement);
  },

  getSites: function () {
    return restGet(this.nwid, `cw/tmiSites`);
  },

  showToolbar: function () {
    const { config } = this.store.getState();

    this.createToolbar();
    this.toolbar.addItem({
      label: translate('Toggle Filters'),
      name: 'toggleFilters',
      _isApplicable: true,
      icon: 'missing_pages',
      tooltip: translate('filters'),
      itemType: 'push',
      execute: pushed => {
        this.store.dispatch(toggleFilters(pushed));
      }
    });
  },

  firstTickReceived: function (data) {
    const multiple = data.config.multiple || "0";
    this.store = createStore(reducer, {
      multiple,
      preferences: {
        columnsWidth: !isUndefined(data.preferences.columnsWidth) ? data.preferences.columnsWidth : {},
        columnsToSortBy: Array.isArray(data.preferences.columnsToSortBy) ? data.preferences.columnsToSortBy : [],
        orderedColumnsKeys: !isUndefined(data.preferences.orderedColumnsKeys) ? data.preferences.orderedColumnsKeys : [],
        filteredColumnsKeys: !isUndefined(data.preferences.filteredColumnsKeys) ? data.preferences.filteredColumnsKeys : []
      },
    });
    this.store.subscribe(this.render);
    this.getSites().then(res =>
      this.store.dispatch(addSites(res.printingPlant))
    );
    this.tickableModel.firstTickHandler(data.model);
    this.updateColumnsToSortBy();
  },

  updateColumnsToSortBy: function () {
    const columns = this.columnsCreator(this.store.getState());
    const { preferences: { columnsToSortBy } } = this.store.getState();
    const preferencesColumnsToSortBy = columnsToSortBy.map(col => {
      const columnToSortBy = columns.find(column => column.key === col.key);
      if (columnToSortBy) {
        col.sortType = columnToSortBy.sortType;
        if (columnToSortBy.valueGetter) {
          col.valueGetter = columnToSortBy.valueGetter;
        }
      }
      return col;
    });
    this.store.dispatch(sortItems(preferencesColumnsToSortBy));
    const { preferences } = this.store.getState();
    sandbox.preferences.savePreferences(this.getRequiredParameters(), {
      ...preferences,
    });
  },

  tickUpdate: function (data) {
    this.tickableModel.tickUpdateHandler(data.model);
  },

  handleRowSelect: function (selectedRows) {
    this.updateSelected(
      selectedRows.map(row => this.tickableModel.getByNwid(row.nwid))
    );
    this.store.dispatch(selectItems(selectedRows));
  },

  handleRowDoubleClick: function (rowIndex, rowContent, event) {
    /**TODO: Create an override function to be overreided by the extended view */
    const storeState = this.store.getState();
    const pageModel = this.tickableModel.getByNwid(rowContent.nwid);
    const multiple = storeState.multiple;
    const relevantView = this.getRelevantViews(pageModel).find(
      rv =>
        (multiple === "0" && rv.label === "Page View") ||
        (multiple === "1" && rv.label === "Multiple PDF View")
    );

    if (!relevantView) return;

    const relevanViewArgs = {
      ...relevantView,
      rootId: pageModel.nwid,
      rootType: pageModel.type
    };

    startModule(relevanViewArgs.nwid, this.id, relevanViewArgs);
  },

  handleRowContextMenu: function (rowIndex, rowContent, selectedRows, ev) {
    this.showContextMenu.apply(this, [
      this.tickableModel.getByNwid(rowContent.nwid),
      selectedRows.map(row => this.tickableModel.getByNwid(row.nwid)),
      ev
    ]);
  },

  debouncedFilterItems: function () {
    this.store.dispatch(filterItems());
  },

  handleFilterChange: function (propName) {
    return (event, value) => {
      this.store.dispatch(updateFilter(propName, value));
      this.debouncedFilterItems();
    };
  },

  handleColumnClick: function (sortType) {
    return (columnKey, valueGetter, multiSort) => {
      const { columnsToSortBy } = this.store.getState();
      const columns = this.columnsCreator(this.store.getState());
      const updatedColumnsToSortBy = reduceColumnsToSortBy(columns, columnsToSortBy, columnKey, multiSort);
      this.store.dispatch(sortItems(updatedColumnsToSortBy));
      // get state from preferences
      const { preferences } = this.store.getState();
      sandbox.preferences.savePreferences(this.getRequiredParameters(), {
        ...preferences,
      });
    };
  },

  handleCardsClick: function (event) {
    if (event.target.nodeName.toLowerCase() !== "div") return;

    this.store.dispatch(selectItems([]));
    this.tableInstance.setSelectedRows([]);
  },

  handleColumnResize: function (columnsSizes, columnKey) {
    this.store.dispatch(columnResize(columnKey, columnsSizes[columnKey].width));
    const { preferences } = this.store.getState();
    sandbox.preferences.savePreferences(this.getRequiredParameters(), {
      ...preferences,
    });
  },

  handleColumnsOrder: function (orderedColumnsKeys, oldIndex, newIndex) {
    const { preferences } = this.store.getState();
    const newPreferences = {
      ...preferences,
      orderedColumnsKeys,
    };
    this.store.dispatch(updatePreferences(newPreferences));
    sandbox.preferences.savePreferences(this.getRequiredParameters(), newPreferences);
  },

  handleColumnFilter: function (columnKey, isVisible, columns) {
    const { preferences } = this.store.getState();
    const filteredColumnsKeys = Object.keys(columns).filter(column => !columns[column].visible);
    const newPreferences = {
      ...preferences,
      filteredColumnsKeys,
    };

    const currentRowHeight = getRowHeight(columns);
    this.store.dispatch(updatePreferences(newPreferences));
    this.store.dispatch(updateRowHeight(currentRowHeight));
    sandbox.preferences.savePreferences(this.getRequiredParameters(), newPreferences);
  },

  renderTableName: function () {
    if (isFunction(this.tableName))
      return this.tableName(this.store.getState());
    return this.tableName || "tableName";
  },

  renderLegend: function () {
    return undefined;
  },

  renderFilters: function () {
    return undefined;
  },

  renderColumnHeaderCaption: function (props, column, handleClick, sortDirection, index) {
    return (
      <HeaderCaption {...props} sortDirection={sortDirection} sortOrder={index + 1} tooltip={column.title}
        onSort={(multiSort) => handleClick(column.key, column.valueGetter, multiSort)} sortable={!isUndefined(column.sortType)}>
        {column.title}
      </HeaderCaption>
    );
  },

  renderColumnHeader: function (props, column, handleClick, sortDirection, index) {
    return (
      <GenericHeader captionRenderer={this.renderColumnHeaderCaption(props, column, handleClick, sortDirection, index)} />
    );
  },

  renderColumns: function () {
    const columns = this.columnsCreator(this.store.getState());
    const { preferences, columnsToSortBy } = this.store.getState();
    const preferencesOrderedColumnsKeys = preferences.orderedColumnsKeys;

    const orderedColumn = columns.sort((a, b) => {
      let aIndex = preferencesOrderedColumnsKeys.indexOf(a.key);
      let bIndex = preferencesOrderedColumnsKeys.indexOf(b.key);
      if (aIndex === -1) aIndex = Number.MAX_SAFE_INTEGER;
      if (bIndex === -1) bIndex = Number.MAX_SAFE_INTEGER;
      return aIndex - bIndex;
    });

    return orderedColumn.map(column => {
      const index = columnsToSortBy.findIndex(col => col.key === column.key);
      const sortDirection =
        !isUndefined(column.sortType) && index !== -1
          ? columnsToSortBy[index].ascending ? 'asc' : 'desc'
          : undefined;
      const handleClick = !isUndefined(column.sortType)
        ? this.handleColumnClick(column.sortType)
        : undefined;
      const columnsWidthPreferences = (preferences || {}).columnsWidth || {};
      const columnWidth = columnsWidthPreferences[column.key];
      const columnVisible = preferences.filteredColumnsKeys.length !== 0 && !isUndefined(preferences.filteredColumnsKeys.find(filteredColumn => filteredColumn === column.key)) ? false : true;

      return (
        <Column
          key={column.key}
          columnKey={column.key}
          width={isUndefined(columnWidth) ? column.width : columnWidth}
          align={column.align}
          autoWidth={isUndefined(columnWidth) ? column.autoWidth : false}
          resizable={column.resizable}
          header={props => this.renderColumnHeader(props, column, handleClick, sortDirection, index)}
          title={column.title}
          cell={column.cell}
          cellDataGetter={column.cellDataGetter}
          shouldCellUpdate={column.shouldCellUpdate}
          visible={columnVisible}
        />
      );
    });
  },

  render: function () {
    const {
      multiple,
      items,
      loaded,
      rowHeight
    } = this.store.getState();
    const cardsState = multiple === "1" || loaded ? "table" : "loading";
    const tableName = this.renderTableName();

    this.reactRoot.render(
      <div className="crtx-tmi-ads-table-view">
        <div className="panel">
          <div className="title">
            <div className="title-name">{tableName}</div>
            <div className="title-content legend">{this.renderLegend()}</div>
          </div>
          <div className="title">
            <div className="title-name filyer-by">{translate('Filter by')}</div>
            {this.renderFilters()}
          </div>
          <Cards
            state={cardsState}
            className="container"
            tabIndex="0"
            onClick={this.handleCardsClick}
          >
            <Card name="loading">
              <div>{translate("Loading")}...</div>
            </Card>
            <Card name="table">
              <Table
                ref={tableInstance => (this.tableInstance = tableInstance)}
                columnKey="nwid"
                rowHeight={rowHeight}
                rows={items}
                selectableRows={true}
                virtualScroll={false}
                onSelect={this.handleRowSelect}
                onDoubleClickRow={this.handleRowDoubleClick}
                onRowContextMenu={this.handleRowContextMenu}
                onColumnResize={this.handleColumnResize}
                onColumnsOrder={this.handleColumnsOrder}
                onColumnsFilter={this.handleColumnFilter}
              >
                {this.renderColumns()}
              </Table>
            </Card>
          </Cards>
        </div>
      </div>
    );
  }
});
