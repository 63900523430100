import { CANVAS_AREA, RULER_THICKNESS } from './utilities';

export class CanvasHelper {
  constructor(rulerVisible = false) {
    this.rulerVisible = rulerVisible;
    this.canvasWidth = 0;
    this.canvasHeight = 0;
    this.offsetX = 0;
    this.offsetY = 0;
    this.transformMatrix = new DOMMatrix();
  }

  getCanvasSize = () => {
    return {
      width: this.canvasWidth,
      height: this.canvasHeight
    };
  };

  setCanvasSize = (width, height) => {
    this.canvasWidth = width;
    this.canvasHeight = height;

    return this;
  };

  getCanvasOffset = () => {
    return {
      x: this.offsetX,
      y: this.offsetY
    };
  };

  setCanvasOffset = (offsetX = 0, offsetY = 0) => {
    this.offsetX = offsetX;
    this.offsetY = offsetY;

    return this;
  };

  getTransformMatrix = () => {
    return this.transformMatrix;
  };

  setTransformMatrix = (domMatrix) => {
    this.transformMatrix = domMatrix;

    return this;
  };

  getCanvasCenterPoint = () => {
    const rulerThickness = this.rulerVisible ? RULER_THICKNESS : 0;

    return {
      x: (this.canvasWidth + rulerThickness) / 2,
      y: (this.canvasHeight + rulerThickness) / 2,
    };
  };

  toCanvasTopLeft = (point) => {
    if (!point) {
      return;
    }

    const c = this.getCanvasCenterPoint();

    return {
      x: point.x + c.x,
      y: point.y + c.y
    };
  };

  getCanvasByScreenPointFromTopLeft = (mousePoint) => {
    if (!mousePoint) {
      return;
    }

    return {
      x: mousePoint.x - this.offsetX,
      y: mousePoint.y - this.offsetY,
    };
  };

  getRulerByCanvasPoint = (mousePoint) => {
    if (!mousePoint) {
      return;
    }

    let { x, y } = mousePoint;
    if (this.rulerVisible) {
      x -= RULER_THICKNESS;
      y -= RULER_THICKNESS;
    }

    return { x, y };
  };

  getCanvasByScreenPoint = (x, y) => {
    const center = this.getCanvasCenterPoint();

    return {
      x: x - (center.x + this.offsetX),
      y: y - (center.y + this.offsetY)
    };
  };

  getScreenByCanvasPoint = (x, y) => {
    const center = this.getCanvasCenterPoint();

    return {
      x: x + center.x + this.offsetX,
      y: y + center.y + this.offsetY
    };
  };

  detectCanvasArea = (mousePoint) => {
    if (!mousePoint) {
      return CANVAS_AREA.OUTSIDE;
    }

    let area = CANVAS_AREA.OUTSIDE;

    const { x, y } = this.getCanvasByScreenPointFromTopLeft(mousePoint);

    if (x >= 0 && x <= this.canvasWidth && y >= 0 && y <= this.canvasHeight) {
      area = CANVAS_AREA.INSIDE;

      if (this.rulerVisible) {
        const leftRuler = x <= RULER_THICKNESS;
        const topRuler = y <= RULER_THICKNESS;

        if (leftRuler && topRuler) {
          area = CANVAS_AREA.RULER_CORNER;
        } else if (leftRuler) {
          area = CANVAS_AREA.LEFT_RULER;
        } else if (topRuler) {
          area = CANVAS_AREA.TOP_RULER;
        }
      }
    }

    return area;
  };
}
