import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Cards extends Component {
    constructor(props) {
        super(props);
    }
    static propTypes = {
        state: PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.object,
    }
    render() {
        const { state, className, style, children, ...props } = this.props;
        const child = children.find(function (childElenent) {
            return state === childElenent.props.name;
        });
        return <div className={`crtx-card ${className}`} style={style} {...props}>
            {typeof child === 'undefined' ? child : child.props.children}
        </div>
    }
}
module.exports = Cards;