/**
 * @name regmark
 * @file Defines Regmark element functionality
 *
 * @author Boris
 * @since: 2016-08-03
 */

import React from 'react';
import Fabric from 'fabric';
import sandbox from 'sandbox';
import SimpleForm from 'widgets/SimpleForm/src/index';
import utils from '../utils/utils';
import canvasUtils from '../utils/canvasUtils';
import propertiesCommon from './propertiesCommon';
import customConditions from "./customConditions";

const {translate} = sandbox.localization;
const futils = SimpleForm.utils;

const ELEMENT_TYPE = 'regmark';
const BASE_PATH = 'layout.regmarks';
const TITLE = translate('Regmark');
const MIN_REGMARK_LENGTH = 10;
const SMALL_VALUE_KEYS = ['lineWidth', 'width', 'height', 'radius'];
const MIN_SMALL_VALUE = 1; // pixel
const RECT_FILL_COLOR = 'black';

const {DISPLAY_NAME, X, Y, _CORNER, APPLY_ON, BLACK_ONLY, FANOUT_AS_CELL} = propertiesCommon;

const REGMARK_TYPE = {
  key: 'regmarkType',
  caption: translate('Regmark type'),
  type: 'string',
  disabled: true
};

const REGMARK_META1 = [
  DISPLAY_NAME,
  REGMARK_TYPE,
  X,
  Y,
  _CORNER
];

const REGMARK_META2 = [
  APPLY_ON,
  BLACK_ONLY,
  FANOUT_AS_CELL
];

export default (editor) => {

  const canvas = editor.getMainCanvas();
  const cutils = canvasUtils(editor);

  const getParamsMeta = (element) => {
    var result = [];
    for (var key in element.params) {
      if (key !== 'otype') {
        var param = element.params[key];
        result.push({
          key: param.name,
          caption: param.title,
          tooltip: param.tooltip,
          type: param.type === 'number' ? 'length' : 'string'
        });
      }
    }

    return result;
  };

  const getMeta = (state, element) => {
    REGMARK_TYPE.hidden = !element.regmarkType;
    const result = REGMARK_META1.concat(getParamsMeta(element)).concat(REGMARK_META2);

    return result;
  };

  const buildDefaultElement = (state, elementType, optionId) => {
    const element = {
      elementType: elementType,
      locked: false,
      _selectable: true
    };

    setDefaultElementValues(state, element);
    const mark = editor.regmarksOptions.find((item) => item['name'] === optionId);
    if (mark) {
      const name = mark.title || mark.name;
      element.name = name;
      element.regmarkType = name;
      element.expression = utils.toPseudoArray(mark.expression);
      element.params = utils.toPseudoArray(mark.params);
      for (var key in mark.defaults) {
        element[key] = mark.defaults[key];
      }
    }

    return element;
  };

  const setDefaultElementValues = (state, element) => {
    propertiesCommon.setDefaultValues(state, element, getMeta(state, element));
    element.conditions = utils.fromPseudoArrayDeep(element.conditions) || [];
  };

  const updateProperty = (state, element, elementPath, propertyPath, propertyValue) => {
    let newState = propertiesCommon.updateProperty(state, element, elementPath, propertyPath, propertyValue);
    const newElement = futils.get(newState, elementPath);

    const paramNames = getParamNames(newElement);
    const key = futils.pathTail(propertyPath);
    if (paramNames[key]) {
      recreateShape(newState, newElement, elementPath);
      newState.repopulate = true;
    } else {
      updateShape(newState, newElement);
    }

    return newState;
  };

  const shapeTransforming = (state, element) => {
    var regmarkGroup = element.shape.regmarkGroup;
    utils.applyConstraints(regmarkGroup, state.plateRectangle);
    var p = utils.toSystemXY(state.plateRectangle, regmarkGroup.left, regmarkGroup.top, element.referenceX, element.referenceY);
    var newElement = {...element, x: p.x, y: p.y};

    updateShape(state, newElement);

    return newElement;
  };

  const createRegmarkGroupObjects = (element) => {
    var result = [];
    var params = resolveParams(element);
    var expressions = element.expression;
    for (var key in expressions) {
      if (key !== 'otype') {
        var expr = expressions[key];
        var props = evalExpressions(expr, params);
        props = toCanvasValues(props);
        var obj;
        switch (expr.type) {
          case 'rect':
            obj = new Fabric.Rect({...props, fill: RECT_FILL_COLOR});
            break;
          case 'circle':
            obj = new Fabric.Circle({...props});
            break;
        }

        if (obj) {
          result.push(obj);
        }
      }
    }

    return result;
  };

  const createRegmarkGroup = (element) => {
    const groupObjects = createRegmarkGroupObjects(element);
    const selectable = element._selectable && !element.role;
    const locked = element.locked || !selectable;
    const group = new Fabric.Group(groupObjects, {
        hasRotatingPoint: false,
        centeredRotation: false,
        selectable: selectable,
        evented: selectable,
        hasControls: false,
        lockMovementX: locked,
        lockMovementY: locked,
        lockScalingX: true,
        lockScalingY: true,
        originX: 'center',
        originY: 'center',
        hoverCursor: locked ? 'default' : 'move'
      }
    );
    group.set({
      width: Math.max(group.width, MIN_REGMARK_LENGTH),
      height: Math.max(group.height, MIN_REGMARK_LENGTH)
    });

    return group;
  };

  const toCanvasValues = (props) => {
    const result = {};
    for (let propName in props) {
      let key = propName;
      switch (propName) {
        case 'x':
          key = 'left';
          break;
        case 'y':
          key = 'top';
          break;
      }

      let value = props[propName];
      if (propName === 'fill' && value === 'transparent') {
        value = '';
      }

      if (!isNaN(value)) {
        value = utils.systemToCanvasUnits(value);
        if (value > 0 && value < MIN_SMALL_VALUE && SMALL_VALUE_KEYS.indexOf(key) >= 0) {
          // set close to zero values to MIN_SMALL_VALUE pixels to prevent regmark be invisible
          value = MIN_SMALL_VALUE;
        }
      }

      result[key] = value;
    }

    return result;
  };

  const getParamNames = (element) => {
    var result = {};
    var params = element.params;
    for (var key in params) {
      if (key !== 'otype') {
        var param = params[key];
        result[param.name] = true;
      }
    }

    return result;
  };

  const resolveParams = (element) => {
    var result = {};
    var params = element.params;
    for (var key in params) {
      if (key !== 'otype') {
        var param = params[key];
        result[param.name] = element[param.name];
      }
    }

    return result;
  };

  const evalExpressions = (expressions, params) => {
    var result = {};
    for (var exprName in expressions) {
      if (exprName !== 'type') {
        var expr = expressions[exprName];
        for (var paramName in params) {
          expr = expr.replace('%' + paramName, params[paramName]);
        }
        var exprValue;
        try {
          exprValue = eval(expr);
        } catch (e) {
          // an option to add a string that cannot be evaluated
          exprValue = expr;
        }
        result[exprName] = exprValue;
      }
    }
    return result;
  };

  const updateRegmarkGroup = (state, element) => {
    const selectable = element._selectable && !element.role;
    const locked = element.locked || !selectable;
    const p = utils.toCanvasLeftTop(state.plateRectangle, element.x, element.y, element.referenceX, element.referenceY);
    const regmarkGroup = element.shape.regmarkGroup;
    regmarkGroup.set({
      left: p.left,
      top: p.top,
      lockMovementX: locked,
      lockMovementY: locked,
      hoverCursor: locked ? 'default' : 'move'
    });
    regmarkGroup.setCoords();
  };

  const recreateShape = (state, element, elementPath) => {
    const active = element.shape.regmarkGroup.active;
    cutils.removeObject(element.shape.regmarkGroup);
    createShape(state, element, elementPath);

    if (active) {
      cutils.setActiveObject(element.shape.regmarkGroup);
    }
  };

  const createShape = (state, element, elementPath) => {
    var regmarkGroup = createRegmarkGroup(element);

    var shape = {
      elementPath,
      regmarkGroup
    };

    regmarkGroup.shape = shape;
    element.shape = shape;

    addShape(state, element);

    updateShape(state, element);

    return shape;
  };

  const addShape = (state, element) => {
    if (!element.shape) {
      return;
    }

    canvas.add(element.shape.regmarkGroup);
  };

  const removeShape = (state, element) => {
    if (!element.shape || !element.shape.regmarkGroup) {
      return;
    }

    canvas.remove(element.shape.regmarkGroup);
  };


  const updateShape = (state, element) => {
    updateRegmarkGroup(state, element);
  };

  const activateShape = (state, element) => {
    cutils.setActiveObject(element.shape.regmarkGroup);
  };

  const nudgeShape = (state, element, direction) => {
    let newElement = element;
    if (!element.locked) {
      newElement = propertiesCommon.nudgeElement(state, element, direction);
      updateShape(state, newElement);
    }

    return newElement;
  };

  const renderProperties = (store, element, elementPath) => {
    const state = store.getState();
    const meta = getMeta(state, element);

    return (
      <div>
        {propertiesCommon.renderProperties(store, element, elementPath, meta)}
        {customConditions.render(store, element, elementPath)}
      </div>
    );
  };

  const getInfo = (state) => {
    return {
      basePath: BASE_PATH,
      title: TITLE
    };
  };

  const getAlignmentInfo = (state, element) => {
    return element.role ? null : utils.getAlignmentInfo(element.shape.regmarkGroup);
  };

  const cloneRegmarks = (state) => {
    return state.layout.regmarks ? {...state.layout.regmarks} : utils.createPseudoArray();
  };

  const addAutoMarks = (state, marks) => {
    const regmarks = cloneRegmarks(state);
    let index = utils.maxKey(regmarks) + 1;
    marks.forEach(mark => {
      if (mark.type && mark.role) {
        const regmark = buildDefaultElement(state, ELEMENT_TYPE, mark.type);
        regmark.role = mark.role;
        regmark.x = mark.x;
        regmark.y = mark.y;
        regmark.l = mark.lineWidth;
        regmark.xLength = mark.lineLength;
        createShape(state, regmark, futils.compose(BASE_PATH, index));
        regmarks[index++] = regmark;
      }
    });

    return {...state, layout: {...state.layout, regmarks}};
  };

  const removeAutoMarks = (state, role) => {
    if (!role) {
      return state;
    }

    let removed = false;
    const regmarks = cloneRegmarks(state);
    for (let key in regmarks) {
      const mark = regmarks[key];
      if (mark && mark.role === role) {
        removeShape(state, mark);
        delete regmarks[key];
        removed = true;
      }
    }

    return removed ? {...state, layout: {...state.layout, regmarks}} : state;
  };

  const getAutoMarks = (state, role) => {
    if (!role) {
      return [];
    }

    const marks = utils.fromPseudoArray(state.layout.regmarks) || [];

    return marks.filter(m => m.role === role);
  };

  return {
    buildDefaultElement,
    setDefaultElementValues,
    updateProperty,
    shapeTransforming,
    createShape,
    updateShape,
    addShape,
    removeShape,
    activateShape,
    nudgeShape,
    renderProperties,
    getInfo,
    getAlignmentInfo,
    addAutoMarks,
    removeAutoMarks,
    getAutoMarks,
  }

};
