import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Loader from 'components/common/loader/Loader';

const isUndefined = o => typeof o === 'undefined';

export default class Iframe extends Component {
  static propTypes = {
    windowRef: PropTypes.any,
    imageUrl: PropTypes.string,
    pageMissing: PropTypes.bool,
  };

  static defaultProps = {
    imageUrl: '',
    pageMissing: false
  };


  render() {
    const { imageUrl, windowRef, pageMissing } = this.props;

    // if (this.props.hasErrored) {
    //   return <p>Sorry! There was an error loading the items</p>;
    // }

    if (isUndefined(imageUrl)) {
      return <div>
        Loading...
        <br />
        <div style={{ paddingTop: '10px' }}>
          <Loader loading={isUndefined(imageUrl)} />
        </div>
      </div>;
    }

    const missingPageClassName = pageMissing ? 'missing-page' : '';

    return (
      <div className="crtx-new-page-view" >
        <div id='myWindow' className={`pdfWindow ${missingPageClassName}`}>
          {!pageMissing ? <iframe ref="myIframe" className='pdfIframe' src={imageUrl} frameBorder="0" scrolling="no">
            <p>Your browser does not support iframes.</p>
          </iframe> : <img src={imageUrl} />}
        </div>
      </div>
    );
  }
}