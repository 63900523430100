import React, { Component } from 'react';
import PropTypes from 'prop-types';
import utils from './utils';

export default (DecoratedComponent, fullRow) => class extends Component {
  static propTypes = {

    store: PropTypes.object

  };

  static contextTypes = {

    store: PropTypes.object

  };

  static childContextTypes = {

    store: PropTypes.object

  };

  static defaultProps = {
    style: {},
    col: '12',
    offset: '0',
    flex: null,
    visible: true
  };

  getStore = () => {
    return typeof this.props.store !== 'undefined' ? this.props.store : this.context.store;
  };

  getChildContext() {
    var store = this.getStore();

    return {
      store: store
    };
  }

  render() {
    var { children, className, col, offset, flex, visible, fullHeight, style, ...props } = this.props;
    var store = this.getStore();
    var containerClassName = fullRow !== true ? utils.combineClassNames(['col',
      utils.getColumnSizeClassName(col),
      utils.getColumnOffsetClassName(offset),
      utils.getColumnFullHeightClassName(fullHeight),
      className
    ]) :
      utils.combineClassNames([utils.getColumnFullHeightClassName(fullHeight), className]);
    var containerStyle = {
      ...style,
      display: visible === false ? 'none' : null,
      flex: flex
    };

    return <DecoratedComponent {...props}
      className={containerClassName}
      style={containerStyle}
      store={store}>
      {children}
    </DecoratedComponent>;
  }
};
