import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AbstractStep from 'widgets/Wizard/AbstractStep';
import { extendObservable } from "mobx";
import FormStep from 'widgets/Wizard/FormStep';
import Checkbox from 'components/common/inputs/Checkbox';
import sandbox from 'sandbox';
import { restGet } from 'core/managers/rest2';

const translate = sandbox.localization.translate;
const sharingChanged = {};

const saveSharingInfo = (objs) => {
  objs.forEach(function (o) {
    sharingChanged[o.sourceId] = {};
    if (o.type === "page") { //page only
      sharingChanged[o.sourceId]["masterPageName"] = o.masterPageName;
      sharingChanged[o.sourceId]["masterPage"] = o.masterPage;
    } else {
      sharingChanged[o.sourceId]["masterSeparation"] = o.masterSeparation;
    }
    sharingChanged[o.sourceId]["masterPageZone"] = o.masterPageZone;
    sharingChanged[o.sourceId]["masterZone"] = o.masterZone;

  });
};

const restoreSharingInfo = (objs) => {
  objs.forEach(function (o) {
    if (o.type === "page") { //page only
      o.masterPageName = sharingChanged[o.sourceId]["masterPageName"];
      o.masterPage = sharingChanged[o.sourceId]["masterPage"];
    } else {
      o.masterSeparation = sharingChanged[o.sourceId]["masterSeparation"];
    }
    o.masterPageZone = sharingChanged[o.sourceId]["masterPageZone"];
    o.masterZone = sharingChanged[o.sourceId]["masterZone"];

  });
};

const removeSharingInfo = (objs) => {
  objs.forEach(function (o) {
    if (o.type === "page") { //page only
      o.masterPageName = null;
      o.masterPage = null;
    } else {
      o.masterSeparation = null;
    }
    o.masterPageZone = null;
    o.masterZone = null;
  });
};

class SanomaSharing extends Component {
  static onDataReceived = (model) => {
    return {
      ...model,
      publication: model.publication || {},
      baseZoneName: model.sites[0][model.baseZoneCode] || "Base"
    };
  };

  static onApply = (model) => {
    return {
      ...model
    };
  };

  handleRestResault = (response) => {
    this.props.model.publication = response;
  };

  componentDidMount() {
    this.wizard = this.props.step.initObj.wizard;
    this.stepsProperties = this.props.step.initObj.stepsProperties;
    this.wizard.disableButton("cancel");

    if (Object.keys(this.props.model.publication).length > 0) return;

    let model = {
      publicationDate: this.props.model.publicationDate,
      productCode: this.props.model.preorder,
      productName: this.props.model.productName,
      templateNames: this.props.model.foundTemplates,
      sites: this.props.model.sites,
      sections: this.props.model.sections,
      comment: this.props.model.comment,
      folderNwId: this.props.step.rootId
    }
    let url = "planning-wizard/custom/sanoma/templates/load?model=" + encodeURIComponent(JSON.stringify(model));

    return restGet(this.props.step.initObj.startParameters.nwid, url)
      .then(this.handleRestResault.bind(this));
  }

  handleLocalSelection = (page) => (event, newValue) => {
    let objs = [];
    objs.push(page);
    objs.push(...page.separations);
    if (newValue) {
      saveSharingInfo(objs);
      removeSharingInfo(objs);
    } else {
      restoreSharingInfo(objs);
    }
  };

  renderNumberInZone = (page) => {
    return <div className="column"><label style={{ minWidth: '12px' }}>{page.numberInZone}</label></div>
  };

  renderPagesInZone = (page, disabled) => {
    let checked = (page.masterPageZone === null || (typeof page.masterPageZone === "undefined"));
    return <div className="column">
      <label style={{ minWidth: '12px' }}>
        <Checkbox disabled={disabled} checked={checked} onChange={this.handleLocalSelection(page)} />
      </label>
    </div>;
  };

  renderLinkZone = (zone) => {
    return (<div className="pw-row"><div className="column">{zone.displayName}</div>{zone.sections.map(function (s) {
      return s.pages.map(function (p) {
        return this.renderPagesInZone(p, false);
      }.bind(this));
    }.bind(this))}</div>);
  };

  renderHeatSet = (page) => {
    let checked = page.isHeatset;
    return <div className="column">
      <label style={{ minWidth: '12px' }}>
        <Checkbox disabled={true} checked={checked} />
      </label>
    </div>;
  };

  render() {
    const { model } = this.props;
    const publication = model.publication;
    if (typeof publication.editions === "undefined") {
      return (<div></div>);
    }
    const edition = publication.editions[0];
    const baseZone = edition.zones.filter(function (z) {
      return (z.displayName === model.baseZoneName);
    })[0];
    const zones = edition.zones.filter(function (z) {
      return (z.displayName !== model.baseZoneName);
    });
    return <div className='container block' style={{ display: 'inline-block', minWidth: '320px' }}>
      <div className="pw-row">
        <div className="column"></div>
        {baseZone.sections.map(function (s) {
          return s.pages.map(this.renderNumberInZone);
        }.bind(this))}
      </div>
      <div className="pw-row">
        <div className="column">{baseZone.displayName}</div>
        {baseZone.sections.map(function (s) {
          return s.pages.map(function (p) {
            return this.renderPagesInZone(p, true);
          }.bind(this));
        }.bind(this))}
      </div>

      {zones.map(this.renderLinkZone)}

      <div className="pw-row">
        <div className="column">{translate('Heatset')}</div>
        {baseZone.sections.map(function (s) {
          return s.pages.map(this.renderHeatSet);
        }.bind(this))}
      </div>
    </div>;

  }
}

export default FormStep(SanomaSharing);
