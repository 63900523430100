import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import DataSource, { EMPTY_ITEM, EMPTY_ITEM_WITH_SPACE } from './dataSource';
// Importing a css file doesn't support yet. For now the following line only works in the playground.
// import 'react-virtualized/styles.css';

const isUndefined = o => typeof o === 'undefined';

export default class PlaneView extends Component {

  static propTypes = {
    gridItemWidth: PropTypes.number.isRequired,             //a number representing the width of the grid in pixels
    gridItemHeight: PropTypes.number.isRequired,            //a number representing the height of the grid in pixels
    items: PropTypes.instanceOf(DataSource).isRequired, //an array for the items in the plane view
    itemRenderer: PropTypes.func.isRequired,            //a function/React Component for each item in the list
    itemPadding: PropTypes.number,                      //a number representing the padding for each item
    onResize: PropTypes.func,                           //(optional) - a function that handles resize of the component
    style: PropTypes.object,
    className: PropTypes.string
  };

  constructor(props) {
    super(props);
  }

  itemRenderer = ({
    items,
    pagingIndex,
    gridItemWidth,
    mapItemsColumns,
    itemPadding
  }) => (filledItem, index) => {
    const itemIndex = pagingIndex + index;
    const item = items[itemIndex];
    const itemColumns = itemIndex >= items.length ? 1 : item === EMPTY_ITEM || isUndefined(item) ? 0 : item === EMPTY_ITEM_WITH_SPACE ? 1 : mapItemsColumns.get(item);
    const style = {
      display: 'flex',
      flexDirection: 'column',
      width: (gridItemWidth * itemColumns),
      position: 'relative',
      boxSizing: 'border-box',
      padding: itemPadding
    };

    return item === EMPTY_ITEM ?
      undefined :
      <div
        key={itemIndex}
        style={style}
      >
        {item === EMPTY_ITEM_WITH_SPACE || isUndefined(item) ? undefined : this.props.itemRenderer(item)}
      </div>;
  };

  rowRenderer = ({
    items,
    columns,
    gridItemWidth,
    mapItemsColumns,
    itemPadding
  }) => ({
    key,         // Unique key within array of rows
    index,       // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible,   // This row is visible within the List (eg it is not an overscanned row)
    style        // Style object to be applied to row (to position it)
  }) => {
      const pagingIndex = columns * index;
      const rowStyle = {
        ...style,
        display: 'flex',
        flexDirection: 'row'
      };

      return (
        <div key={key} style={rowStyle}>
          {(Array(columns).fill(0).map(this.itemRenderer({ items, pagingIndex, gridItemWidth, mapItemsColumns, itemPadding })))}
        </div>
      );
    };

  render() {
    const { gridItemWidth, gridItemHeight, items, itemPadding, onResize, style, className } = this.props;

    return <AutoSizer onResize={onResize}>
      {({ height, width }) => {
        const renderedList = items.renderedList();
        const mapItemsColumns = items.mapItemsColumns;
        const columns = Math.floor(width / gridItemWidth);
        const rowCount = Math.ceil(renderedList.length / columns);

        return <List
          width={width}
          height={height}
          rowCount={rowCount}
          rowHeight={gridItemHeight}
          rowRenderer={this.rowRenderer({ items: renderedList, columns, gridItemWidth, mapItemsColumns, itemPadding })}
        />;
      }}
    </AutoSizer>;
  }

}