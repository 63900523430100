import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { translate, toLocaleShortTime } from 'core/services/localization';
import { MSEC_IN_MINUTE, getFolderCurrentDate } from 'core/dates';
import TextInput from 'components/common/inputs/TextInput';
import NumericInput from 'components/common/inputs/NumericInput';

function Filter(
  {
    filterOptions: { name = '', hoursBefore = '', hoursAfter = '' } = {},
    onChange,
    onRefresh
  }) {

  const [lastRefreshTime, setLastRefreshTime] = useState(0);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLastRefreshTime(Date.now());
      if (hoursBefore || hoursAfter) {
        onRefresh && onRefresh();
      }
    }, MSEC_IN_MINUTE);

    return () => {
      clearTimeout(timeoutId);
    };
  });

  const handleNameChange = (event, name) => {
    onChange && onChange(event, { name });
  };

  const handleHoursBeforeChange = (event, hoursBefore) => {
    onChange && onChange(event, { hoursBefore }, true);
  };

  const handleHoursAfterChange = (event, hoursAfter) => {
    onChange && onChange(event, { hoursAfter }, true);
  };

  return (
    <div className='crtx-production-runs-filter'>
      <TextInput
        className='filter-name'
        inputType='search'
        value={name}
        placeholder={translate('Search')}
        title={translate('Production Run name')}
        onChange={handleNameChange}
      />
      <NumericInput
        value={hoursBefore}
        allowEmptyValue={true}
        notNegative={true}
        decimalPlaces={2}
        size={2}
        placeholder={'-h'}
        title={translate('Hours before')}
        onChange={handleHoursBeforeChange}
      />
      <div className='filter-current-time'>
        {toLocaleShortTime(getFolderCurrentDate())}
      </div>
      <NumericInput
        value={hoursAfter}
        allowEmptyValue={true}
        notNegative={true}
        decimalPlaces={2}
        size={2}
        placeholder={'+h'}
        title={translate('Hours after')}
        onChange={handleHoursAfterChange}
      />
    </div>
  );
}

Filter.propTypes = {
  filterOptions: PropTypes.shape({
    name: PropTypes.string, // node name (label)
    hoursBefore: PropTypes.string, // hours before the current folder time
    hoursAfter: PropTypes.string, // hours after the current folder time
  }),
  onChange: PropTypes.func,
  onRefresh: PropTypes.func,
};

export default Filter;
