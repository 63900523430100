import React from 'react';

export default class extends React.Component {
  render() {
    const {definition, children} = this.props;
    let style = {...this.props.style};
    let offset;

    style.zIndex = 2;
    if (definition.extend === "true") {
      offset = typeof definition.offset !== 'undefined' && Array.isArray(definition.offset) ? definition.offset[1] : "0";
      style.transform = ["translate(-50%, ", offset, ")"].join('');
      style.WebkitTransform = ["translate(-50%, ", offset, ")"].join('');
      style.width = "100%";
      style.zIndex = 1;
    }
    else if (typeof definition.offset !== 'undefined') {
      offset = definition.offset;
      style.transform = ["translate(", offset[0], ", ", offset[1], ")"].join('');
      style.WebkitTransform = ["translate(", offset[0], ", ", offset[1], ")"].join('');
    }

    return <div className="AlignmentPoint" style={style}>
      {children}
    </div>;
  }
}