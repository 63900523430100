import React from 'react';
import { translate } from 'core/services/localization';
import Checkbox from 'components/common/inputs/Checkbox';
import PercentInput from 'components/common/inputs/PercentInput';
import Dropdown from 'components/common/dropdown/Dropdown';
import { action, computed } from 'mobx';
import { observer } from 'mobx-react';
import { Validator } from 'components/private/forms';

const PROGRESS_OPTIONS = [
  { value: 'equal', text: '=' },
  { value: 'equalLarge', text: '>=' },
  { value: 'equalSmall', text: '<=' }
];

const labels = {
  enableCondition: translate('Enable condition: '),
  enableOnFlowstepTitle: translate('Enable On Flowsteps'),
  stateValidationMessage: translate('At least one state should be checked'),
  progress: translate('Progress'),
  state: translate('State'),
  error: translate('Error'),
  waiting: translate('Waiting'),
  finished: translate('Finished'),
};

export const StatusCondtions = observer(({ model, onChange }) => {

  const handleStatusChanged = action((checked, status) => {
    model.progressStatuses = checked ? [...model.progressStatuses, status] : model.progressStatuses.filter(ps => ps !== status);

    onChange(model);
  });

  const isError = computed(() => model.progressStatuses.some(s => s === 'Error')).get();
  const isWaiting = computed(() => model.progressStatuses.some(s => s === 'Waiting')).get();
  const isFinished = computed(() => model.progressStatuses.some(s => s === 'Success')).get();

  return <React.Fragment>
    <div className='crtx-form-full-row'>
      <label className='crtx-form-label-with-checkbox'>
        <Checkbox
          title={labels.enableOnFlowstepTitle}
          checked={model.enableIfProgress}
          onChange={action((event, checked) => {
            model.enableIfProgress = checked
            onChange(model);
          })}
        />
        {labels.enableCondition}</label>
    </div>

    <label>{labels.progress}:</label>
    <div className='crtx-form-composed-element'>
      <Dropdown
        className='crtx-form-element-xxsmall'
        options={PROGRESS_OPTIONS}
        textProp='text'
        valueProp='value'
        value={model.progressComparator}
        onSelect={action((event, value) => {
          model.progressComparator = value;
          onChange(model);
        })}
        disabled={!model.enableIfProgress}
      />
      <PercentInput
        className='crtx-form-element-xxsmall crtx-form-margin-left-xsmall'
        value={model.progressValue}
        noConversion={true}
        disabled={!model.enableIfProgress}
        onChange={action((event, newValue) => {
          model.progressValue = newValue;
          onChange(model);
        })}
      />
    </div>
    <label className='crtx-form-label-top-alignment'>{labels.state}:</label>
    <div className='crtx-form-composed-element-vertical-alignment'>
      {model.enableIfProgress ? <Validator rules={[() => model.progressStatuses.length !== 0]} message={labels.stateValidationMessage} /> : undefined}
      <label className='crtx-form-label-with-checkbox'>
        <Checkbox title={labels.error}
          checked={isError}
          onChange={(event, checked) => {
            handleStatusChanged(checked, 'Error');
          }}
          disabled={!model.enableIfProgress || model.progressComparator === ''}
        />
        {labels.error}</label>

      <label className='crtx-form-label-with-checkbox'>
        <Checkbox title={labels.waiting}
          checked={isWaiting}
          onChange={(event, checked) => {
            handleStatusChanged(checked, 'Waiting')
          }}
          disabled={!model.enableIfProgress || model.progressComparator === ''}
        />
        {labels.waiting}</label>


      <label className='crtx-form-label-with-checkbox'>
        <Checkbox title={labels.finished}
          checked={isFinished}
          onChange={(event, checked) => {
            handleStatusChanged(checked, 'Success')
          }}
          disabled={!model.enableIfProgress || model.progressComparator === ''}
        />
        {labels.finished}</label>
    </div>
  </React.Fragment>
})