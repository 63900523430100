
import { getMainStatus } from 'utilities/statuses';
import OpenDialogAction from './OpenDialogAction';

const propertyComparator = propertyName => (item1, item2) => {
  if (isUndefined(item1[propertyName]) || isUndefined(item2[propertyName])) return false;

  return item1[propertyName].toLowerCase() === item2[propertyName].toLowerCase();
};
const samePublication = propertyComparator('publication');
const sameAdvertiser = propertyComparator('advertiser');
const ItemsFromSamePubAndAdvComparator = (item, index, arr) => {
  return samePublication(arr[0], item) && sameAdvertiser(arr[0], item);
};
const isUndefined = o => typeof o === 'undefined';
const isRejectedPage = page => {
  const status = getMainStatus(page) || {};
  return status.flowStepType === 'workflow/step/flow/approval' && status.statusType === 'Error';
};

export default OpenDialogAction.extend({
  isApplicable: function () {
    const selected = this.module.selected || [];
    const areAllItemsFromTheSamePubAndAdv = selected.every(ItemsFromSamePubAndAdvComparator);
    const areAllItemsAdTypeEquel = selected.every((page, index) => {
      if (index === selected.length - 1) return true;

      return page.adType === selected[index + 1].adType;
    });
    if (selected.length > 1 && selected.some(item => (item.blockMail === 'true' && !item.stitched))) {
      return false;
    }
    const arrAllItemsHaveDeprecated = !selected.some(item => item.deprecated === 'true');
    const arrAllItemsNotRejected = !selected.some(item => isRejectedPage(item));
    const arrAllItemsSuccessDownsample = selected.every(item => item.pageContent.downsampleStatus === 'success');

    return selected.length > 1 && areAllItemsFromTheSamePubAndAdv && areAllItemsAdTypeEquel && arrAllItemsHaveDeprecated && arrAllItemsNotRejected && arrAllItemsSuccessDownsample ? true : false;
  }
});