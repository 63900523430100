import React from 'react';
import toastService from 'core/services/toastService';
import localization from './localization';
import requestManager from '../managers/request';
import {fetchBreadcrumbs, formatBreadcrumbs} from 'utilities/breadcrumbs';

const isUndefined = o => typeof o === 'undefined';
const isArray = o => Array.isArray(o);
const translate = localization.translate;

let clipboard = [];
let toastNotification;
let toastErrorNotification;

export const isEmpty = () => clipboard.length > 0;

export const clear = () => {
  clipboard = [];
  toastNotification = undefined;
};

export const clearError = () => {
  toastErrorNotification = undefined;
};

const handleToastClose = () => {
  if (isUndefined(toastNotification)) return;

  toastNotification.remove();
  clear();

  toastService.createToast('bottom-right', translate('Clipboard'), translate('Cleared'), 'regular');
};

const getItemContent = item => {
  return item[`${item.type}Content`];
}

const handleToastErrorClose = () => {
  if (isUndefined(toastErrorNotification)) return;

  toastErrorNotification.remove();
  clearError();
}

const setToastNotification = () => {
  const item = clipboard[0];
  const nwid = item.nwid;
  const type = item.type;
  const itemContent = getItemContent(item);
  fetchBreadcrumbs(nwid, type).then(breadcrumbs => {
    const message = formatBreadcrumbs(breadcrumbs);
    if (isUndefined(toastNotification)) {
      toastNotification = toastService.createToast('bottom-right', translate('Clipboard'), message, 'regular', undefined, handleToastClose, false);
      if (!isUndefined(toastErrorNotification)) { handleToastErrorClose(); }
    }
    toastNotification.message(message);
    if (!isUndefined(itemContent)) {
      toastNotification.image(
        requestManager.getImageUrl({
          command: "getImage",
          template: `${type}/content`,
          action: "icon",
          iconUID: Date.now(),
          nwid: itemContent.nwid,
        },
          true)
      );
    }
  });
};

export const setItemsToClipboard = (items) => {
  if (isUndefined(items)) return;
  clipboard = (isArray(items) ? items : [items]);//.map((item) => { return { nwid: item.nwid, type: item.type } });
  setToastNotification();
};

export const getItemsFromClipboard = () => {
  return [...clipboard];
};

export const removeToastNotification = () => {
  if (isUndefined(toastNotification)) return;

  toastNotification.remove();
  toastNotification = undefined;
}

export const getLatestItemFromClipbaord = () => {
  if (clipboard.length === 0) return undefined;

  return clipboard[clipboard.length - 1];
};

export const setErrorToastNotification = (errorMessage) => {

  removeToastNotification();
  clear();

  if (isUndefined(toastErrorNotification)) {
    toastErrorNotification = toastService.createToast('bottom-right', translate('Error'), errorMessage, 'error', undefined, handleToastErrorClose, false)
  } else {
    toastErrorNotification.message(errorMessage);
  }
};

export default {
  _name: 'clipboard',
  _type: 'service',
  isEmpty,
  clear,
  clearError,
  setItemsToClipboard,
  getItemsFromClipboard,
  removeToastNotification,
  getLatestItemFromClipbaord,
  setErrorToastNotification
};