/**
 * @name stateInitializer
 * @file Builds initial state object
 *
 * @author Boris
 * @since: 2016-06-23
 */

import React from 'react';
import { translate } from 'core/services/localization';
import SimpleForm from 'widgets/SimpleForm/src/index';
import { SvgIcon } from 'components/common/buttons';
import utils from '../utils/utils';
import setupElementBase from '../elements/elementBase';

const futils = SimpleForm.utils;

const XSMALL_BUTTON_CLASS = 'btn btn-default btn-xs';

const GROUPS_WITHOUT_BUTTONS = ['plateGrp', 'printAreaGrp', 'cellGridGrp', 'cellGrp', 'autoMarksGrp'];

const BUTTONS_BY_ID = {
  add: {
    id: 'add',
    className: XSMALL_BUTTON_CLASS,
    tooltip: translate('Add'),
    iconName: 'add'
  },
  delete: {
    id: 'delete',
    className: XSMALL_BUTTON_CLASS,
    tooltip: translate('Delete'),
    iconName: 'close'
  },
  duplicate: {
    id: 'duplicate',
    className: XSMALL_BUTTON_CLASS,
    tooltip: translate('Duplicate'),
    iconName: 'file_copy'
  },
  manage: {
    id: 'manage',
    className: XSMALL_BUTTON_CLASS,
    text: translate('Manage'),
  }
};

const TYPES_TO_GROUPS_MAP = {
  plate: 'plateGrp',
  printArea: 'printAreaGrp',
  cellGrid: 'cellGridGrp',
  cell: 'cellGrp',
  erase: 'eraseGrp',
  regmark: 'regmarkGrp',
  centerMarks: 'autoMarksGrp',
  cropMarks: 'autoMarksGrp',
  furniture: 'furnitureGrp',
  textBurn: 'textBurnGrp',
  barcode: 'barcodeGrp',
  inkArea: 'inkAreaGrp',
  guideline: 'guidelineGrp',
};

const GROUPS_BY_LAYOUT_TYPE = {
  layout: ['plateGrp', 'printAreaGrp', 'cellGridGrp', 'cellGrp', 'eraseGrp', 'regmarkGrp',
    'autoMarksGrp', 'furnitureGrp', 'textBurnGrp', 'barcodeGrp', 'guidelineGrp'],
  plate: ['plateGrp', 'printAreaGrp', 'eraseGrp', 'regmarkGrp', 'furnitureGrp', 'textBurnGrp', 'barcodeGrp', 'guidelineGrp'],
  ink: ['plateGrp', 'inkAreaGrp']
};

const GROUPS_INFO = [
  {
    elementType: 'plate',
    id: 'plateGrp',
    title: translate('Plate')
  },
  {
    elementType: 'printArea',
    id: 'printAreaGrp',
    title: translate('Print Area')
  },
  {
    elementType: 'cellGrid',
    id: 'cellGridGrp',
    title: translate('Cell Grid')
  },
  {
    elementType: 'cell',
    id: 'cellGrp',
    title: translate('Cells')
  },
  {
    elementType: 'erase',
    id: 'eraseGrp',
    title: translate('Erase Rectangles')
  },
  {
    elementType: 'regmark',
    id: 'regmarkGrp',
    title: translate('Regmarks')
  },
  {
    elementType: 'autoMarks', // this type is a pseudo type for all auto marks (crop, fold, etc.)
    id: 'autoMarksGrp',
    title: translate('Auto Marks'),
  },
  {
    elementType: 'furniture',
    id: 'furnitureGrp',
    title: translate('Furnitures')
  },
  {
    elementType: 'textBurn',
    id: 'textBurnGrp',
    title: translate('Text Burns')
  },
  {
    elementType: 'barcode',
    id: 'barcodeGrp',
    title: translate('Barcodes')
  },
  {
    elementType: 'inkArea',
    id: 'inkAreaGrp',
    title: translate('Ink Areas')
  },
  {
    elementType: 'guideline',
    id: 'guidelineGrp',
    title: translate('Guidelines')
  },
];

function getGroupButtons(state, groupId, editor) {
  const result = [];

  const btnAdd = getButtonAdd(groupId, editor);
  if (btnAdd) {
    result.push(btnAdd);
  }

  const btnImport = getButtonImport(state, groupId);
  if (btnImport) {
    result.push(btnImport);
  }

  const btnManage = getButtonManage(state, groupId);
  if (btnManage) {
    result.push(btnManage);
  }

  return result;
}

function getButtonAdd(groupId, editor) {
  if (GROUPS_WITHOUT_BUTTONS.some(t => t === groupId)) {
    return null;
  }

  let btnAdd = BUTTONS_BY_ID.add;

  const btnOptions = getButtonOptions(groupId, editor);
  if (btnOptions) {
    btnAdd = { ...btnAdd };
    btnAdd.className = 'split-button';
    btnAdd.options = btnOptions.options;
    btnAdd.optionContentGetter = btnOptions.optionContentGetter;
    btnAdd.value = btnOptions.value;
  }
  return btnAdd;
}

function furnitureOptionContentGetter(option, index) {
  if (!option) {
    return null;
  }

  const icons = utils.getFurnitureIcons(option.imageType);

  return (
    <>
      <span className='furniture-option-text'>{option.text}</span>
      {icons.map((icon, index) =>
        <SvgIcon key={index} name={icon.icon} tooltip={icon.title} className={icon.iconClassName} />)}
    </>
  );
}

function getButtonOptions(groupId, editor) {
  let options, optionContentGetter;
  switch (groupId) {
    case 'regmarkGrp':
      options = editor.regmarksOptions.map(mark => ({ value: mark.name, text: mark.title }));
      break;
    case 'furnitureGrp':
      options = editor.furnitures.map(furn => ({ value: furn.name, text: furn.name, imageType: furn.type }));
      optionContentGetter = furnitureOptionContentGetter;
      break;
  }

  if (options) {
    return {
      options,
      optionContentGetter,
      value: getDefaultOptionValue(options)
    };
  }
}

function getDefaultOptionValue(options) {
  return options && options.length > 0 ? options[0].value : undefined;
}

function getButtonImport(state, groupId) {
  return state.rootType === 'form' || groupId !== 'furnitureGrp' ? null : BUTTONS_BY_ID.import;
}

function getButtonManage(state, groupId) {
  return state.rootType === 'form' || groupId !== 'furnitureGrp' ? null : BUTTONS_BY_ID.manage;
}

function getPlateRectangle(layout, editor) {
  const plate = layout.plate || layout;
  const result = {
    left: editor.getStartLeft(),
    top: editor.getStartTop(),
    width: !plate.width ? 0 : utils.systemToCanvasUnits(plate.width),
    height: !plate.height ? 0 : utils.systemToCanvasUnits(plate.height)
  };

  return result;
}

function filterElementGroups(state, layoutType) {
  const groupIds = GROUPS_BY_LAYOUT_TYPE[layoutType];
  const groups = state.elementGroups.filter(g => groupIds.indexOf(g.id) >= 0);
  groups.forEach((g, index) => {
    g.path = futils.compose('elementGroups', index);
    if (g.elementType === 'regmark') {
      g.children = g.children.filter(child => {
        const element = futils.get(state, child.elementPath);
        return !element.role;
      });
    }
  });

  state.elementGroups = groups;
}

function createElementShapes(state, elementBase) {
  const groups = state.elementGroups;
  for (let i = 0; i < groups.length; i++) {
    const group = groups[i];
    if (group && group.elementType !== 'cell') {
      for (let j = 0; j < group.children.length; j++) {
        const elementPath = group.children[j].elementPath;
        const element = futils.get(state, elementPath);
        elementBase.createShape(state, element, elementPath);
      }
    }
  }
}

const isElementDisabled = (elementPath) => {
  return futils.pathContains(elementPath, 'plate');
};

const isElementSelectable = (elementType, layoutType) => {
  return layoutType !== 'ink' || elementType === 'plate' || elementType === 'inkArea';
};

const findGroupByType = (groups, elementType) => {
  const groupId = TYPES_TO_GROUPS_MAP[elementType];
  return groups.find(g => g.id === groupId);
};

function getPlateElementPath(groups) {
  const plateGroup = findGroupByType(groups, 'plate');
  return !plateGroup || !plateGroup.children[0] ? '' : plateGroup.children[0].elementPath;
}

function createElementGroups(state, editor) {
  const result = [];
  for (let i = 0; i < GROUPS_INFO.length; i++) {
    const buttons = getGroupButtons(state, GROUPS_INFO[i].id, editor);
    const path = futils.compose('elementGroups', i);
    const group = { ...GROUPS_INFO[i], buttons, path, children: [] };
    result.push(group);
  }

  return result;
}

function populateElementGroups(state, layoutType, elementBase) {
  const groups = state.elementGroups;
  const populateElementGroupsInner = (parent, parentPath) => {
    if (!parent || typeof parent !== 'object') {
      return;
    }

    if (typeof parent.elementType !== 'undefined' && parent.elementType !== 'cell') {
      const element = parent;
      const elementType = element.elementType;
      const elementPath = parentPath;

      element.locked = element.locked || isElementDisabled(elementPath);
      element._selectable = isElementSelectable(elementType, layoutType);

      elementBase.setDefaultElementValues(state, element, elementPath);

      const group = findGroupByType(groups, elementType);
      if (group) {
        group.children.push({
          elementPath
        });
      }
    }

    for (let key in parent) {
      const obj = parent[key];
      if (obj && typeof obj === 'object') {
        populateElementGroupsInner(obj, futils.compose(parentPath, key));
      }
    }
  };

  populateElementGroupsInner(state.layout, 'layout');
}

function addAutoMarksIfNeeded(layout) {
  if (layout.elementType === 'layout') {
    layout.centerMarks = layout.centerMarks || { elementType: 'centerMarks' };
    layout.cropMarks = layout.cropMarks || { elementType: 'cropMarks' };
  }
}

export function initializeState(editor) {
  const layoutNwid = editor.getLayoutNwid();
  const layoutType = editor.getLayoutType();
  const folderNwid = editor.getFolderNwid();
  const viewInstanceNwid = editor.getViewInstanceNwid();
  const rootType = editor.getRootType();
  const initialData = editor.getInitialData();

  const layout = utils.toPseudoArrayDeep(initialData);
  layout.layoutGroup = utils.fromPseudoArrayDeep(layout.layoutGroup);
  addAutoMarksIfNeeded(layout);
  const plateRectangle = getPlateRectangle(layout, editor);
  const units = utils.getUserUnits();

  const state = {
    layoutNwid,
    layoutType,
    folderNwid,
    viewInstanceNwid,
    rootType,
    units,
    layout,
    plateRectangle,
    sidebar: {
      buttons: BUTTONS_BY_ID
    },
    expandedGroupPath: 'elementGroups.0',
    showAlignments: false
  };

  const elementBase = setupElementBase(editor);

  state.elementGroups = createElementGroups(state, editor);
  populateElementGroups(state, layoutType, elementBase);
  createElementShapes(state, elementBase);
  filterElementGroups(state, layoutType);

  state.selectedElementPath = getPlateElementPath(state.elementGroups);
  const element = futils.get(state, state.selectedElementPath);
  elementBase.activateShape(state, element);

  return state;
}

