import React from 'react';
import sandbox from 'sandbox';

export default class extends React.Component {
  static defaultProps = {
    separations: []
  };

  iconSrc = (separations) => {
    if (Array.isArray(separations) && separations.length > 1) {
      return sandbox.icons.getModuleIcon("Thumbnail", "composite-color-square");
    }
    return sandbox.icons.getModuleIcon("Thumbnail", "composite-bw-square");
  };

  handleSeparationClick = (ev) => {
    this.props.onSeparationClick(ev, null);
  };

  render() {
    var {
            separations,
      onSeparationClick
          } = this.props;

    return <div className="separations-composite-square" onClick={this.handleSeparationClick}>
      <img src={this.iconSrc(separations)} />
    </div>;
  }
}