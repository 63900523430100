/**
 * @name
 * @fileOverview
 * @author sergey & Elad
 */
define(['ember', 'text!../templates/booksMain.hbs', './zone', 'kendo-ember', './../../../utils/wizardCommons'],
  function (Ember, MainTemplate, ZoneView, kendoComponents, wizardCommons) {
    'use strict';

    return Ember.View.extend(wizardCommons.viewCommons, {
      onActivate: function(){

			},
			template: Ember.Handlebars.compile(MainTemplate),

      classNames: ['structure-container'],

			zoneView: ZoneView




    });
  });