import viewManager from 'core/managers/views';
import actionManager from 'core/managers/actions';

export function getViewRootParams(view) {
  if (!view?.viewSettings) {
    return;
  }

  const { rootId, rootType, rootName, rootLabel } = view.viewSettings;

  return { rootId, rootType, rootName, rootLabel };
}

export function getViewRoot(view) {
  const viewRootParams = getViewRootParams(view);
  if (viewRootParams) {
    return fromViewRootParams(viewRootParams);
  }
}

export function fromViewRootParams(viewRootParams) {
  const { rootId: nwid, rootType: type, rootName: name, rootLabel: label } = viewRootParams;

  return { nwid, type, name, label };
}

export function toViewRootParams(viewRoot) {
  const { nwid: rootId, type: rootType, name: rootName, label: rootLabel } = viewRoot;

  return { rootId, rootType, rootName, rootLabel };
}

export function getParentViewRoot(view) {
  return getViewRoot(view.parentModule);
}

export function hasEditionRoot(view) {
  return getViewRoot(view)?.type === 'edition' || getParentViewRoot(view)?.type === 'edition';
}

export function isPlanned(view) {
  let result = false;
  if (view.selected) {
    const selected = view.selected[0];
    if (selected && (typeof selected.isPlanned === 'undefined' || selected.isPlanned === true)) {
      result = true;
    }
  }

  return result;
}

export function findViewInfoForAction(actionNwid, viewDefinitionName) {
  let viewInfo;
  const actionInfo = actionManager.getActionInfo(actionNwid);
  if (actionInfo) {
    viewInfo = viewManager.findViewInfoByDefinitionName(actionInfo.folderNwid, viewDefinitionName, actionInfo.configuredClique);
  }

  return viewInfo;
}

export function deduceViewDefinitionName(clickedItem, actionParams) {
  if (clickedItem !== void 0 && actionParams[clickedItem.type] !== void 0) {
    return actionParams[clickedItem.type];
  } else {
    return actionParams.viewDefinitionName;
  }
}
