module.exports = {

  CONTAINERS: [
    {name: 'top',           direction: 'down'},
    {name: 'top-right',     direction: 'down'},
    {name: 'bottom-right',  direction: 'up'},
    {name: 'bottom-left',   direction: 'up'},
    {name: 'top-left',      direction: 'down'} 
  ],

  DEFAULT_CONTAINER_DIRECTION: 'down',

  DEFAULT_TOAST_DELAY: 5000

};