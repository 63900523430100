import PropTypes from 'prop-types';

function Tab() {
  return null;
}

Tab.propTypes = {
  caption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
  contentClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Tab;