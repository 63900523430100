/**
 * Obtains the document of the window containing the reference element (also known as anchor or trigger),
 * which is vital for creating portals in the Light Window.
 * Note: This code is implementation-dependent.
 *
 * @param {object} floatingContext - contains the context data provided to relevant Hooks and components
 * @returns {object} - body of the document element
 */
export function getFloatingPortalNode(floatingContext) {
  const ownerDocument = floatingContext?.elements?.domReference?.ownerDocument;

  return ownerDocument?.body;
}

/**
 * Focus the Popover (floating element) if it exists
 *
 * @param {object} floatingContext - contains the context data provided to relevant Hooks and components
 */
export function focusPopover(floatingContext) {
  floatingContext?.elements?.floating?.focus();
}
