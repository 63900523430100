import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class extends Component {
  render() {
    var { children } = this.props;
    //var bodyStyle = {
    //  //transform: 'translate3d(0, 0, 0)'
    //};

    return <div className="react-data-grid-body"
    /*style={bodyStyle}*/
    >
      {children}
    </div>;
  }
}