import React from 'react';
import PropTypes from 'prop-types';
import iconService from 'core/services/iconService';
import { translate } from 'core/services/localization';
import logger from 'logger';
const log = logger.getDefaultLogger();

export default function TitleComponent({ model, module }) {
  const { notOnOfToggableStep, isOn, isSkippableStep, isSkipOn, flowStepName, type, iconName } = model;

  const stepIcon = () => {
    let iconType = type;

    if (iconName && iconName !== 'void') {
      iconType += '/' + iconName;
    }

    return iconService.getTemplateIcon(iconType);
  };

  const toggleDevice = () => {
    const toggleAction = module.viewActions.find(a => a.actionDefinitionName === 'ToggleDeviceActionCR');
    if (typeof toggleAction === 'undefined') {
      log.error('Toggle Device action instance is not found');
      return;
    }
    toggleAction.execute([model]);
  };

  const toggleSkipDevice = () => {
    const toggleSkipAction = module.viewActions.find(a => a.actionDefinitionName === 'ToggleSkipDeviceActionCR');
    if (typeof toggleSkipAction === 'undefined') {
      log.error('Toggle Skip Device action instance is not found');
      return;
    }
    toggleSkipAction.execute([model]);
  };

  return (
    <React.Fragment>
      {!notOnOfToggableStep && <React.Fragment>
        {isOn ?
          <div title={translate('Stop Workflow Step')}>
            <a href="#" onClick={() => toggleDevice()} >
              <img src={iconService.getGeneralIcon('flowStep', 'on')} ></img>
            </a>
          </div> :
          <div title={translate('Start Workflow Step')}>
            <a href="#" onClick={() => toggleDevice()} >
              <img src={iconService.getGeneralIcon('flowStep', 'off')} ></img>
            </a>
          </div>
        }
      </React.Fragment>}
      {isSkippableStep && <React.Fragment>
        {isSkipOn ?
          <div title={translate('Process Workflow Step')}>
            <a href="#" onClick={() => toggleSkipDevice()} >
              <img src={iconService.getGeneralIcon('flowStep', 'skipon')} ></img>
            </a>
          </div> :
          <div title={translate('Skip Workflow Step')}>
            <a href="#" onClick={() => toggleSkipDevice()} >
              <img src={iconService.getGeneralIcon('flowStep', 'skipoff')} ></img>
            </a>
          </div>
        }
      </React.Fragment>}
      <div><img src={stepIcon()} /></div>
      <div className='title-text'>
        <div title={flowStepName} className="title-text">{flowStepName}</div>
      </div>
    </React.Fragment>
  );
}

TitleComponent.propTypes = {
  model: PropTypes.object,
  module: PropTypes.object
};