/**
 * @fileOverview
 * @author sergey
 */

import sandbox from 'sandbox';
import AbstractAction from 'AbstractAction';
import settingsManager from 'core/managers/settings';
import base from 'base';

export default AbstractAction.extend({
  isApplicable: function (objs) {
    if (!Array.isArray(objs) || objs.length <= 0) {
      return false;
    }

    if (objs[0].type === 'folder' && objs[0].nwid === settingsManager.get('currentFolderNwid')) {
      return false;
    }

    return true;
  },

  execute: function (objs) {
    var nwid, type;
    var projectorId = this.module.projectorId;
    const deferred = base.data.deferred();

    var msg = "You are about to delete this item. \nIt cannot be restored at later time! Continue?";
    sandbox.dialog.confirm(msg, 'Confirmation').then(function () {
      var targetObjects = Array.isArray(objs) ? objs.map(function (elem) {
        return {
          type: elem["type"],
          nwid: elem["nwid"]
        };
      }) : [];
      sandbox.request.deleteObjects(this.nwid, nwid, type, projectorId,
        {
          config: this.config,
          targetObjects: targetObjects,
          purge: true,
          parameters: this.parameters
        }
        ).then(res => deferred.resolve(res)).fail(err => deferred.reject(err));
    }.bind(this)).catch(() => deferred.reject);
    return deferred.promise();
  }
});
