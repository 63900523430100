import React from 'react';
import DegreesInput from 'components/common/inputs/DegreesInput';
import InputCell from './InputCell';

function DegreesInputCell({ allowEmptyValue = true, size = 4, ...props }) {
  return (
    <InputCell InputComponent={DegreesInput} decimalPlaces={4} allowEmptyValue={allowEmptyValue} size={size} {...props} />
  );
}

export default DegreesInputCell;
