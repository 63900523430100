import React from 'react';
import { isEqual } from 'base/jsUtils';
import { translate, toLocaleShortDateTime, toLocaleShortTime } from 'core/services/localization';
import { getAllColors, getUIColorRGB, getColorByName } from 'core/services/colorService';
import { getTemplateIcon, getGeneralIcon, getModuleIcon } from 'core/services/iconService';
import { arrangeItemsByKeys } from 'utilities/array';
import { cells } from 'widgets/ReactDataGrid';
import { COMPARE_TYPE } from 'core/comparators';
import ApproversCell from '../StructuresTableView/customCells/ApproversCell';
import {
  getItemApprovalIndications,
  getItemCompletedIndications,
  getItemRejectedIndications
} from 'utilities/approvals';
import {
  WAITING,
  ERROR,
  SUCCESS,
  NOT_ASSIGNED,
  PROCESSING,
  UNPLANNED,
  WAITING_FOR_APPROVAL,
  COLORS_BY_STATUS,
  STATUS_FILTER_OPTIONS,
  PAGE_COLOR_OPTIONS,
  STATUS_TIME_FILTER_OPTIONS,
  SITE_FILTER_OPTIONS,
  LOCAL_OPTIONS,
  HAS_PLATES_OPTIONS,
  PREFLIGHT_INDICATION_OPTIONS,
} from './constants';
import { fromServerDate } from 'core/dates';
import {
  FILTER,
  applyColumnPreferences,
} from 'widgets/ReactDataGrid/utils';
import { isItemWithActualPlates, isLocalItemFilter } from './utils';
import { getMainStatus } from 'utilities/statuses';
import IconButton from 'components/common/buttons/IconButton';
import { getHoldType, getHoldIconName } from 'utilities/hold';

const { IconCell, GenericCell, Thumbnail } = cells;

const COLUMN_KEYS_BY_VIEW = {
  zone_page: ['thumbnail', 'section', 'physicalNumber', 'name', 'version', 'expectedName', 'inputFileName', 'separation', 'flowstep', 'status', 'approvalIndication', 'holdType', 'statustime', 'local', 'preflightIndication'],
  edition_page: ['thumbnail', 'zone', 'section', 'physicalNumber', 'name', 'version', 'expectedName', 'inputFileName', 'separation', 'flowstep', 'status', 'approvalIndication', 'holdType', 'statustime', 'preflightIndication'],
  edition_page_separation: ['zone', 'section', 'pageName', 'physicalNumber', 'name', 'version', 'expectedName', 'inputFileName', 'separation', 'flowstep', 'status', 'approvalIndication', 'holdType', 'statustime'],
  edition_form: ['thumbnail', 'zone', 'book', 'physicalNumber', 'name', 'version', 'expectedName', 'inputFileName', 'separation', 'flowstep', 'status', 'holdType', 'statustime', 'hasPlates'],
  edition_form_separation: ['zone', 'book', 'physicalNumber', 'name', 'version', 'expectedName', 'inputFileName', 'separation', 'flowstep', 'status', 'holdType', 'statustime', 'hasPlates'],
};

const SORT_BY = {
  physicalNumber: {
    key: 'physicalNumber',
    ascending: true
  },
  separation: {
    key: 'separation',
    ascending: true
  },
};

const approvalStatusIcons = {
  'completed': getModuleIcon('MyBirdeye', 'success'),
  'rejected': getModuleIcon('Thumbnail', 'reject-small'),
  'waiting': getModuleIcon('MyBirdeye', 'wait')
};

export const getDefaultColumnsToSortBy = (viewModel) => {
  let result = [];

  if (viewModel.rowType === 'page/separation' || viewModel.rowType === 'form/separation') {
    result = [SORT_BY.physicalNumber, SORT_BY.separation];
  } else {
    result = [SORT_BY.physicalNumber];
  }

  return result;
};

const getSiteCellData = (statusTime, siteHold, holdType) => {
  if (!siteHold && holdType !== 'content') {
    return statusTime;
  }

  const updatedHoldType = siteHold && holdType === 'none' ? 'structure' : holdType;

  const iconName = getHoldIconName(updatedHoldType);
  const icon = getGeneralIcon('', iconName, '.svg');
  return <div className='text-icon-cell'>
    {statusTime}
    <img src={icon} />
  </div>;
};

export const defaultCellDataGetter = viewModel => {
  return (rowIndex, columnKey) => {
    const row = viewModel.rows[rowIndex];

    let columnData, backgroundColor, title, style;
    if (viewModel.siteMap[columnKey]) {
      const statusType = getSiteStatusType(row, columnKey);
      style = { backgroundColor: COLORS_BY_STATUS[statusType] };
      if (statusType !== UNPLANNED) {
        const { transmissionStatuses = {}, holdSites = [], holdType } = row;
        const { time } = transmissionStatuses[columnKey];
        let statusTime;
        if (time) {
          const date = fromServerDate(time);
          statusTime = toLocaleShortTime(date);
          title = toLocaleShortDateTime(date, true);
        }

        const siteHold = viewModel.type === 'zone' && holdType !== 'none' ? true : holdSites.includes(columnKey);
        columnData = getSiteCellData(statusTime, siteHold, holdType);
      }
    } else if (Array.isArray(row[columnKey])) {
      columnData = row[columnKey].join(', ');
    } else {
      columnData = row[columnKey] || undefined;
    }

    return {
      columnData,
      title,
      style
    };
  };
};

export const defaultShouldCellUpdate = (nextProps, props) => {
  return nextProps.columnData !== props.columnData || nextProps.backgroundColor !== props.backgroundColor ||
    nextProps.title !== props.title || nextProps.icon !== props.icon || nextProps.name !== props.name ||
    !isEqual(nextProps.style, props.style) || nextProps.iconUID !== props.iconUID;
};

const getPredefinedColumns = (viewModel) => {
  const columns = {
    thumbnail: () => ({
      caption: translate('Thumbnail'),
      key: 'thumbnail',
      cell: Thumbnail,
      width: 103,
      align: 'center',
      cellDataGetter: (rowIndex, columnKey) => {
        const row = viewModel.rows[rowIndex];

        const content = typeof row.pageContent !== 'undefined' ? row.pageContent : row.content;

        return {
          columnData: content.nwid,
          iconUID: content.versionNwid,
          template: content.type
        };
      },
    }),
    zone: () => ({
      caption: translate('Zone'),
      key: 'zone',
      width: 103,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      filter: FILTER.TEXT_TEXT,
    }),
    section: () => ({
      caption: translate('Section'),
      key: 'section',
      width: 103,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      filter: FILTER.TEXT_TEXT,
    }),
    book: () => ({
      caption: translate('Book'),
      key: 'book',
      width: 103,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      filter: FILTER.TEXT_TEXT,
    }),
    pageName: () => ({
      caption: translate('Page'),
      key: 'pageName',
      width: 103,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      filter: FILTER.TEXT_TEXT,
    }),
    physicalNumber: () => ({
      caption: translate('Number'),
      key: 'physicalNumber',
      width: 100,
      sortType: COMPARE_TYPE.NUMBERS,
      filter: FILTER.TEXT_NUMBER,
    }),
    name: () => ({
      caption: translate('Name'),
      key: 'name',
      width: 103,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      filter: FILTER.TEXT_TEXT,
      cellDataGetter: (rowIndex, columnKey) => {
        const row = viewModel.rows[rowIndex];
        const shouldShowVariantName = viewModel.rowsByPhysicalNumber[row.physicalNumber].length > 1;

        return {
          columnData: shouldShowVariantName ? row.variantName : row.name,
        };
      }
    }),
    version: () => ({
      caption: translate('Version'),
      key: 'version',
      width: 100,
      sortType: COMPARE_TYPE.NUMBERS,
      filter: FILTER.TEXT_NUMBER,
      sortValueGetter: getVersion,
      filterValueGetter: getVersion,
      cellDataGetter: (rowIndex, columnKey) => {
        const row = viewModel.rows[rowIndex];

        return {
          columnData: getVersion(row)
        };
      },
    }),
    expectedName: () => ({
      caption: translate('File Name'),
      key: 'expectedName',
      width: 150,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      filter: FILTER.TEXT_TEXT,
    }),
    inputFileName: () => ({
      caption: translate('Input File Name'),
      key: 'inputFileName',
      width: 150,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      filter: FILTER.TEXT_TEXT,
      sortValueGetter: getInputFileName,
      filterValueGetter: getInputFileName,
      cellDataGetter: (rowIndex, columnKey) => {
        const row = viewModel.rows[rowIndex];

        return {
          columnData: getInputFileName(row)
        };
      },
    }),
    separation: (viewModel) => ({
      caption: translate('Color'),
      key: 'separation',
      cell: IconCell,
      width: 60,
      align: 'center',
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      filter: {
        options: getColorOptions(viewModel),
        ...FILTER.MULTISELECT_ICON,
      },

      sortValueGetter: getColorValue,

      filterValueGetter: getColorValue,

      cellDataGetter: (rowIndex, columnKey) => {
        const row = viewModel.rows[rowIndex];

        return separationCellDataGetter(row);
      },
    }),
    flowstep: (viewModel) => ({
      caption: translate('Step'),
      key: 'flowstep',
      cell: IconCell,
      align: 'center',
      width: 56,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,

      sortValueGetter: row => (getMainStatus(row) || {}).flowStepType,

      cellDataGetter: (rowIndex, columnKey) => {
        const row = viewModel.rows[rowIndex];
        const status = getMainStatus(row) || {};
        const { statusType = NOT_ASSIGNED, flowStepType, flowStepIconName } = status;

        let icon;
        if (flowStepType && statusType !== NOT_ASSIGNED) {
          icon = getTemplateIcon(flowStepType, 'tiny', flowStepIconName);
        }

        return {
          icon
        };
      },
    }),
    status: (viewModel) => ({
      caption: translate('Status'),
      key: 'status',
      cell: IconCell,
      align: 'center',
      width: 56,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      filter: {
        options: STATUS_FILTER_OPTIONS,
        ...FILTER.MULTISELECT_ICON,
      },

      sortValueGetter: row => (getMainStatus(row) || {}).statusType,

      filterValueGetter: row => (getMainStatus(row) || {}).statusType,

      cellDataGetter: (rowIndex, columnKey) => {
        const row = viewModel.rows[rowIndex];
        const status = getMainStatus(row) || {};
        const { statusType = NOT_ASSIGNED, flowStepType } = status;

        let icon;
        if (statusType !== NOT_ASSIGNED) {
          if (statusType === WAITING) {
            icon = getGeneralIcon('status', 'wait');
          } else if (statusType === SUCCESS) {
            icon = getGeneralIcon('status', 'finished');
          } else if (statusType === ERROR && flowStepType === 'workflow/step/flow/approval') {
            icon = getModuleIcon('Thumbnail', 'reject-small');
          } else if (statusType === PROCESSING) {
            icon = getGeneralIcon('status', 'in_progress');
          } else {
            icon = getGeneralIcon('status', statusType.toLowerCase());
          }
        }

        return {
          icon
        };
      },
    }),
    approvalIndication: (viewModel) => ({
      caption: translate('Approvers'),
      key: 'approvalIndication',
      cell: ApproversCell,
      align: 'center',
      width: 100,

      cellDataGetter: (rowIndex, columnKey) => {
        const row = viewModel.rows[rowIndex];
        const approvalIndications = row.type === 'page/separation' ? (getItemApprovalIndications(row.page) || []).sort() :
          (getItemApprovalIndications(row) || []).sort();

        return {
          approvers: approvalIndications.map(approvalIndication => {
            const page = row.type === 'page' ? row : undefined;
            const approvalIndicationStatus = !page ? 'waiting' :
              getItemCompletedIndications(page).indexOf(approvalIndication) >= 0 ? 'completed' :
                getItemRejectedIndications(page).indexOf(approvalIndication) >= 0 ? 'rejected' :
                  'waiting';

            if (approvalIndicationStatus !== 'waiting') return {
              name: approvalIndication,
              icon: approvalStatusIcons[approvalIndicationStatus]
            };

            const separations = row.type === 'page/separation' ? [row] : row.separations;
            const approvalIndicationSeparationsStatus = separations.reduce((accStatus, separation) => {
              if (accStatus === 'rejected') return 'rejected';

              const separationStatus = getItemCompletedIndications(separation).indexOf(approvalIndication) >= 0 ? 'completed' :
                getItemRejectedIndications(separation).indexOf(approvalIndication) >= 0 ? 'rejected' :
                  'waiting';

              if (separationStatus === 'rejected') return 'rejected';
              if (accStatus === 'waiting') return 'waiting';

              return separationStatus;
            }, 'completed');

            return {
              name: approvalIndication,
              icon: approvalStatusIcons[approvalIndicationSeparationsStatus]
            };
          })
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.approvers.length !== props.approvers.length ||
        nextProps.approvers.some((a, idx) => a.name !== props.approvers[idx].name || a.icon !== props.approvers[idx].icon),
    }),
    holdType: (viewModel) => ({
      caption: translate('Hold'),
      key: 'holdType',
      cell: IconCell,
      width: 56,
      align: 'center',

      cellDataGetter: (rowIndex, columnKey) => {
        const row = viewModel.rows[rowIndex];

        const holdType = getHoldType(row);
        const iconName = getHoldIconName(holdType);
        const icon = iconName ? getGeneralIcon('', iconName, '.svg') : undefined;

        return {
          icon
        };
      },
    }),
    statustime: (viewModel) => ({
      caption: translate('Status Time'),
      key: 'statustime',
      width: 100,
      align: 'center',
      sortType: COMPARE_TYPE.DATES,
      filter: {
        options: STATUS_TIME_FILTER_OPTIONS,
        ...FILTER.MULTISELECT_ICON,
      },

      sortValueGetter: row => {
        const statusType = getStatusType(row);
        const { time } = getMainStatus(row) || {};
        if (time && statusType !== NOT_ASSIGNED) {
          return fromServerDate(time);
        }
      },

      filterValueGetter: row => getStatusType(row),

      cellDataGetter: (rowIndex, columnKey) => {
        const row = viewModel.rows[rowIndex];
        const status = getMainStatus(row) || {};
        const { time } = status;
        const statusType = getStatusType(row);

        let statusTime, title, backgroundColor;
        if (time && statusType !== NOT_ASSIGNED) {
          const date = fromServerDate(time);
          statusTime = toLocaleShortTime(date);
          title = toLocaleShortDateTime(date, true);
          backgroundColor = COLORS_BY_STATUS[statusType];
        }

        return {
          columnData: statusTime || '',
          backgroundColor,
          title
        };
      },
    }),
    local: viewModel => ({
      caption: translate('Local'),
      key: 'local',
      width: 100,
      align: 'center',
      cell: GenericCell,
      sortType: COMPARE_TYPE.NUMBERS,
      filter: {
        options: LOCAL_OPTIONS,
        ...FILTER.MULTISELECT_TEXT,
      },

      sortValueGetter: row => isLocalItemFilter(row),

      filterValueGetter: row => isLocalItemFilter(row) ? 'local' : '',

      cellDataGetter: (rowIndex, columnKey) => {
        const row = viewModel.rows[rowIndex];

        return {
          columnData: isLocalItemFilter(row) ? <IconButton key='check' iconName='check' className='full-width' /> : '',
          title: ''
        };
      },
    }),
    hasPlates: viewModel => ({
      caption: translate('Has Plates'),
      key: 'hasPlates',
      width: 100,
      align: 'center',
      cell: GenericCell,
      sortType: COMPARE_TYPE.NUMBERS,
      filter: {
        options: HAS_PLATES_OPTIONS,
        ...FILTER.MULTISELECT_TEXT,
      },

      sortValueGetter: row => isItemWithActualPlates(row),

      filterValueGetter: row => isItemWithActualPlates(row) ? 'hasPlates' : '',

      cellDataGetter: (rowIndex, columnKey) => {
        const row = viewModel.rows[rowIndex];

        return {
          columnData: isItemWithActualPlates(row) ? <IconButton key='check' iconName='check' className='full-width' /> : '',
          title: ''
        };
      },
    }),
    preflightIndication: (viewModel) => ({
      caption: translate('Preflight'),
      key: 'preflightIndication',
      cell: IconCell,
      align: 'center',
      width: 56,
      sortType: COMPARE_TYPE.NUMBERS,
      filter: {
        options: PREFLIGHT_INDICATION_OPTIONS,
        ...FILTER.MULTISELECT_ICON,
      },

      sortValueGetter: row => getReportType(row),

      filterValueGetter: row => getReportType(row),

      cellDataGetter: (rowIndex, columnKey) => {
        const row = viewModel.rows[rowIndex];
        const reportType = getReportType(row);

        let icon;
        if (reportType === 1) {
          icon = getModuleIcon('MyBirdeye', 'preflight_report_warning', '.svg');
        } else if (reportType === 2) {
          icon = getModuleIcon('MyBirdeye', 'preflight_report_error', '.svg');
        }


        return {
          icon
        };
      },
    }),
  };

  const viewType = `${viewModel.type}_${viewModel.rowType.split('/').join('_')}`;

  return COLUMN_KEYS_BY_VIEW[viewType].map(key => columns[key](viewModel));
};

const getSiteColumns = (viewModel) => {
  return viewModel.sites.map(site => {
    return {
      key: site.nwid,
      caption: site.name,
      tooltip: site.code,
      width: 94,
      resizable: false,
      cell: GenericCell,
      filter: {
        options: SITE_FILTER_OPTIONS,
        ...FILTER.MULTISELECT_ICON,
      },
      filterValueGetter: row => getSiteStatusType(row, site.nwid)
    };
  });
};

export function makeTableColumns(viewModel, preferences) {
  const columnPreferences = preferences.columns || [];
  const keys = columnPreferences.map(col => col.key);
  const columns = arrangeItemsByKeys(getPredefinedColumns(viewModel), keys)
    .concat(arrangeItemsByKeys(getSiteColumns(viewModel), keys));

  applyColumnPreferences(columns, columnPreferences);

  return columns;
}

function separationCellDataGetter(row) {
  let icon, title, iconStyle;

  const colorValue = getColorValue(row);
  if (isSeparation(row)) {
    const color = getUIColorRGB(getColorByName(colorValue));
    icon = getTemplateIcon(row.type, 'tiny');
    iconStyle = { backgroundColor: color };
    title = colorValue;
  } else {
    if (colorValue === 'bw') {
      icon = getModuleIcon('TableView', 'bw');
      title = translate('Black and white');
    } else if (colorValue === 'color') {
      icon = getModuleIcon('TableView', 'color');
      title = translate('Color');
    }
  }

  return {
    icon,
    title,
    iconStyle
  };
}

function isSeparation(row) {
  return row.type === 'page/separation' || row.type === 'form/separation';
}

function getColorValue(row) {
  let colorValue;

  if (isSeparation(row)) {
    colorValue = row.name;
  } else {
    const { separations = [] } = row;

    if (separations.length === 1) {
      colorValue = 'bw';
    } else if (separations.length >= 1) {
      colorValue = 'color';
    }
  }

  return colorValue;
}

function getColorOptions(viewModel) {
  let colorOptions = [];

  if (viewModel.rowType === 'page' || viewModel.rowType === 'form') {
    colorOptions = PAGE_COLOR_OPTIONS;
  } else if (viewModel.rowType === 'page/separation' || viewModel.rowType === 'form/separation') {
    colorOptions = getAllColors().map(color => ({
      value: color.name,
      data: {
        icon: getTemplateIcon(viewModel.rowType, 'tiny'),
        title: color.name,
        iconStyle: { backgroundColor: getUIColorRGB(color) }
      }
    }));
  }

  return colorOptions;
}

function getSiteStatusType(row, columnKey) {
  const txStatus = (row.transmissionStatuses || {})[columnKey];

  if (txStatus?.statusType === PROCESSING) {
    txStatus.statusType = WAITING;
  }

  return txStatus ? txStatus.statusType : UNPLANNED;
}

function getStatusType(row) {
  const status = getMainStatus(row) || {};
  let { statusType, progress, flowStepType } = status;

  if (statusType === SUCCESS && Number(progress) < 100) {
    // this should not happen only if something get wrong
    statusType = NOT_ASSIGNED;
  } else if (statusType === WAITING && flowStepType === 'workflow/step/flow/approval') {
    statusType = WAITING_FOR_APPROVAL;
  }

  return statusType || NOT_ASSIGNED;
}

function getVersion(row) {
  return row.content.externalVersion || undefined;
}

function getInputFileName(row) {
  return row.content.mainVersionInputFileName;
}

const getReportType = row => row?.content?.preflightReportInfo?.preflightReportType ?? 0;
