/**
 * @name ServerAction
 * @fileOverview Server action is the action for
 * general purpose with doesn't have any client implementation
 * but the server one. Its purpose on the client is to make a
 * hit with appropriate parameters to the server
 *
 * @author sergey
 */

import AbstractAction from 'AbstractAction';
import sandbox from 'sandbox';
import data from 'base/data';

module.exports = AbstractAction.extend({
  execute: function (args) {
    var dfd = data.deferred();
    var objects = args ? Array.prototype.map.call(args, function (item) {
      return {
        nwid: item.nwid,
        type: item.type
      };
    }) : [];

    var actionArgs = [
      this.nwid,
      this.actionName,
      this.module.nwid,
      this.module.type,
      this.module.projectorId,
      this.parameters.scriptName,
      { objects: objects }
    ];

    if (this.parameters.confirmation) {
      sandbox.dialog.confirm(this.parameters.message, 'Confirmation').then(function () {
        if (this.module.name.toLowerCase() === 'pageview') {
          setTimeout(function () {
            window.close();
          }, 1500);
        }

        sandbox.request.execServerAction.apply(this, actionArgs)
          .done(function () {
            dfd.resolve.apply(this, arguments);
          }.bind(this))
          .fail(function () {
            dfd.reject.apply(this, arguments);
          }.bind(this));
      }.bind(this));
    } else {
      if (this.module.name.toLowerCase() === 'pageview') {
        setTimeout(function () {
          window.close();
        }, 1500);
      }
      sandbox.request.execServerAction.apply(this, actionArgs)
        .done(function () {
          dfd.resolve.apply(this, arguments);
        }.bind(this))
        .fail(function () {
          dfd.reject.apply(this, arguments);
        }.bind(this));
    }

    return dfd.promise();
  }
});