module.exports = {
  update: (path, value) => {
    return {
      type: 'UPDATE',
      path,
      value
    }
  },
  updateBooks: (model) => {
    return {
      type: 'UPDATE_BOOKS',
      model
    }
  },
  updateOriginalTemplate: () => {
    return {
      type: 'UPDATE_ORIGINAL_TEMPLATE'
    }
  },
  copyValuesToOtherForms: (formPath, option) => {
    return {
      type: 'COPY_VALUES_TO_OTHER_FORMS',
      formPath,
      option
    }
  },
  addTemplate: (path, templateName) => {
    return {
      type: 'ADD_TEMPLATE',
      path,
      templateName
    }
  },
  deleteTemplate: (path, templateName) => {
    return {
      type: 'DELETE_TEMPLATE',
      path,
      templateName
    }
  },
  bookFromTemplate: (path, template) => {
    return {
      type: 'BOOK_FROM_TEMPLATE',
      path,
      template
    }
  },
  addFormTemplate: (name, layoutType, form) => {
    return {
      type: 'ADD_FORM_TEMPLATE',
      name,
      layoutType,
      form
    }
  },
};
