import PropTypes from 'prop-types';
import CanvasComponent from './canvasComponent';
import { CANVAS_AREA, RULER_THICKNESS, transformPoint, toPixelCenter } from './utilities';

const STROKE_COLOR = 'red';

export class Guide extends CanvasComponent {
  static propTypes = {
    guides: PropTypes.array,
    mouseDownPoint: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    }),
    mouseMovePoint: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    }),
    rotation: PropTypes.number,
    point: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    })
  };

  static defaultProps = {
    guides: [],
    rotation: 0,
    point: {
      x: 0,
      y: 0
    },
  };

  transformGuide = (guide, domMatrix) => {
    const { rotation } = this.props;

    const { x, y } = transformPoint(guide.point, domMatrix);

    let vertical = guide.vertical;
    let position = vertical ? x : y;
    if ((guide.rotation - rotation) % 180 !== 0) {
      vertical = !vertical;
      position = vertical ? x : y;
    }

    return { vertical, position };
  };

  drawHGuide = (ctx, canvasHelper, y) => {
    const { canvasWidth } = canvasHelper;

    y = toPixelCenter(y);
    ctx.moveTo(RULER_THICKNESS, y);
    ctx.lineTo(canvasWidth, y);
  };

  drawVGuide = (ctx, canvasHelper, x) => {
    const { canvasHeight } = canvasHelper;

    ctx.beginPath();

    x = toPixelCenter(x);
    ctx.moveTo(x, RULER_THICKNESS);
    ctx.lineTo(x, canvasHeight);

    ctx.stroke();
  };

  drawNewGuide = (ctx, canvasHelper) => {
    const { mouseDownPoint, mouseMovePoint } = this.props;
    if (!mouseDownPoint || !mouseMovePoint) {
      return;
    }

    const mouseDownArea = canvasHelper.detectCanvasArea(mouseDownPoint);
    if (mouseDownArea !== CANVAS_AREA.LEFT_RULER && mouseDownArea !== CANVAS_AREA.TOP_RULER ||
      canvasHelper.detectCanvasArea(mouseMovePoint) !== CANVAS_AREA.INSIDE) {
      return;
    }

    let { x, y } = canvasHelper.getCanvasByScreenPointFromTopLeft(mouseMovePoint);

    ctx.strokeStyle = STROKE_COLOR;
    ctx.setLineDash([1, 1]);

    ctx.beginPath();

    if (mouseDownArea === CANVAS_AREA.LEFT_RULER) {
      // console.log('=== drawNewGuide()= LEFT_RULER');
      this.drawVGuide(ctx, canvasHelper, x);
    } else {
      // console.log('=== drawNewGuide()= TOP_RULER');
      this.drawHGuide(ctx, canvasHelper, y);
    }

    ctx.stroke();
  };

  drawGuide = (ctx, canvasHelper, domMatrix, guide) => {
    const { vertical, position } = this.transformGuide(guide, domMatrix);

    ctx.strokeStyle = STROKE_COLOR;
    ctx.setLineDash([]);

    ctx.beginPath();

    if (vertical) {
      this.drawVGuide(ctx, canvasHelper, position);
    } else {
      this.drawHGuide(ctx, canvasHelper, position);
    }

    ctx.stroke();
  };

  draw = (ctx, canvasHelper) => {
    const { guides } = this.props;

    const domMatrix = ctx.getTransform();

    ctx.save();

    // reset the current transformation to the identity matrix
    ctx.setTransform(1, 0, 0, 1, 0, 0);

    this.drawNewGuide(ctx, canvasHelper);

    guides.forEach(guide => {
      if (guide.visible) {
        this.drawGuide(ctx, canvasHelper, domMatrix, guide);
      }
    });

    ctx.restore();
  };
}