/**
 * @name
 * @fileOverview
 * @author sergey
 */
define(['ember', 'base/browser', 'base/jsUtils', 'base/dom', 'sandbox/icons'],
    function (Ember, browser, jsUtils, dom, icons) {
  'use strict';

  /**
   * This is a common function between all browser mixins
   * @param ev
   */
  var basicDragStart = function (ev) {
    var data;
    if (jsUtils.isFunction(this.sendDragContent)) {
      data = this.sendDragContent();
    }
    ev.dataTransfer.effectAllowed = 'move';
    ev.dataTransfer.setData('Text', JSON.stringify(data));
  };

  var ChromeMixin = (function () {
    var dummyNode = document.createElement('div');
    var draggedItem = document.createElement('div');
    draggedItem.className = 'dragged-item';
    draggedItem.innerHTML = '<img src="' + icons.getSystemIcon('dragged-page') + '"><span></span>';

    return {
      addGhostImage: function (ev) {
        draggedItem.querySelector('span').innerHTML = this.$().text().trim();
        dom.append('body', draggedItem);
        dom.append('body', dummyNode);
        ev.dataTransfer.setDragImage(dummyNode, 0, 0);
      },
      dragStart: function (ev) {
        basicDragStart.call(this, ev);
        this.addGhostImage(ev);
      },
      drag: function (ev) {
        if (ev.originalEvent.pageY > 0 && ev.originalEvent.pageX > 0) {
          draggedItem.style.top = (ev.originalEvent.pageY + 3) + 'px';
          draggedItem.style.left = (ev.originalEvent.pageX + 3) + 'px';
        }
      },
      dragEnd: function (ev) {
        dom.remove(draggedItem);
        dom.remove(dummyNode);
      }
    };
  })();

  var OthersMixin = (function () {
    return {
      dragStart: function (ev) {
        basicDragStart.call(this, ev);
      }
    };
  })();


  return Ember.Mixin.create({
    init: function () {
      this._super.apply(this, arguments);

      // factory that turn on the appropriate event handlers
      // depending on the browser.
      // we need it because of the behavior of browsers in
      // drag and drop is not consistent.
      // E.g. see https://bugzilla.mozilla.org/show_bug.cgi?id=505521

//      if (browser.isChrome()) {
//        jsUtils.merge(this, ChromeMixin);
//      }
//      else {
//        jsUtils.merge(this, OthersMixin);
//      }
			jsUtils.merge(this, OthersMixin);
    },

    attributeBindings: ['draggable'],
    draggable: 'true'

  });
});