
export class BackgroundWorker {

  constructor(worker) {

    const url = window.URL;

    const blob = new Blob([`${worker}`], { type: 'application/javascript' });

    this.worker = new Worker(url.createObjectURL(blob));
  };

  postMessage = message => this.worker.postMessage(JSON.stringify(message));

  onmessage = callback => this.worker.onmessage = callback;
};
