import { translate } from 'core/services/localization';

const ICON_NAME_BY_HOLD_TYPE = {
  content: 'hold_content',
  partial: 'hold_partial',
  scheduled: 'hold_scheduled',
  sorting: 'hold_sorter',
  structure: 'hold_structure',
  scheduled_partial: 'hold_scheduled_partial',
  content_aggregated: 'hold_content_aggregated',
  structure_aggregated: 'hold_structure_aggregated',
  scheduled_aggregated: 'hold_scheduled_aggregated',
  partial_aggregated: 'hold_partial_aggregated',
  sorting_aggregated: 'hold_sorter_aggregated',
  scheduled_partial_aggregated: 'hold_scheduled_partial_aggregated',
};

const ICON_TOOLTIP_BY_HOLD_TYPE = {
  content: translate('Page content is on hold'),
  partial: translate('Object is on structure hold, but some children have been released'),
  scheduled: translate('Object is on hold, but can be automatically released'),
  sorting: translate('Object is on sorter hold'),
  structure: translate('Object is on structure hold'),
  scheduled_partial: translate('Object is on scheduled hold, but some children have been released'),
  content_aggregated: translate('Page content is on hold in all the children'),
  partial_aggregated: translate('Some of the children are on hold'),
  scheduled_aggregated: translate('All the children are on hold, and some are on scheduled hold'),
  sorting_aggregated: translate('All the children are on sorter hold'),
  structure_aggregated: translate('All the children are on structure hold'),
  scheduled_partial_aggregated: translate('At least one of the children is on scheduled hold'),
};

export const getHoldIconName = holdType => ICON_NAME_BY_HOLD_TYPE[holdType] || '';

export const getHoldIconTooltip = holdType => ICON_TOOLTIP_BY_HOLD_TYPE[holdType] || '';

export const getHoldType = item => {
  let holdType = 'none';
  if (item.type === 'page' && item.holdType === 'content') {
    holdType = item.holdType;
  } else if (item.holdType && item.holdType !== 'none') {
    if (!item.aggregatedHoldType || item.aggregatedHoldType === 'none' || item.holdType === item.aggregatedHoldType) {
      holdType = item.holdType;
    } else if ((item.holdType === 'scheduled' && item.aggregatedHoldType === 'structure') || (item.holdType === 'structure' && item.aggregatedHoldType === 'scheduled')) {
      holdType = 'scheduled';
    } else if (item.holdType === 'partialScheduled' || item.aggregatedHoldType === 'partialScheduled' || (item.holdType === 'scheduled' && item.aggregatedHoldType !== 'scheduled') || (item.holdType !== 'scheduled' && item.aggregatedHoldType === 'scheduled')) {
      holdType = 'scheduled_partial';
    } else {
      holdType = 'partial';
    }
  } else if (item.aggregatedHoldType && item.aggregatedHoldType !== 'none') {
    if (item.aggregatedHoldType === 'partialScheduled') {
      holdType = 'scheduled_partial_aggregated';
    } else {
      holdType = `${item.aggregatedHoldType}_aggregated`;
    }
  }

  return holdType;
};

export const getEditionHoldType = item => {
  let holdType = '';
  if (item.type === 'page' && item.aggregatedHoldType === 'content') {
    holdType = 'content';
  } else {
    holdType = `${item.aggregatedHoldType}_aggregated`;
  }
  return holdType;
};