/**
 * @name OpenModuleAction
 * @fileOverview This action is created for the sake of
 * permission management, meaning whether the certain module is allowed to
 * open other modules.
 * @author sergey
 */

import AbstractAction from 'AbstractAction';
import openModule from 'actions/utilities/openModule';

module.exports = AbstractAction.extend({

  execute: function (objects) {

    openModule(this, objects, this.parameters);
  }
});
