import React, { Component } from 'react';
import Header from 'components/common/headers/Header';
import PropTypes from 'prop-types';
import ProductionRun from './ProductionRun';

import { translate } from 'core/services/localization';

const labels = {
  fanoutAndDirect: translate('Fanout and Direct'),
  fanout: translate('Fanout'),
};

module.exports = class FanoutAndDirectInfo extends Component {

  static propTypes = {
    object: PropTypes.any,
    fanoutAndDirectInfo: PropTypes.any,
    advanceInfo: PropTypes.bool,
    openCustomInfoDialog: PropTypes.func,
    openerType: PropTypes.string,
    module: PropTypes.object
  };

  getRuns() {
    const { fanoutAndDirectInfo, module } = this.props;

    return Object.entries(fanoutAndDirectInfo).map(([key, run]) => {
      return <ProductionRun key={key} run={run} module={module} />;
    });

  }

  render() {

    return <div className='fanout-and-direct-info'>
      <Header>{labels.fanoutAndDirect}</Header>
      {this.getRuns()}
    </div>;
  }

};
