import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import TextInput from 'components/common/inputs/TextInput';
import FlatButton from 'components/common/buttons/FlatButton';
import { translate } from 'core/services/localization';
import iconsService from 'core/services/iconService';
import vars from '../core/vars';

function EditableCell({
  columnData,
  rowIndex,
  columnKey,
  disabled = false,
  onChange,
  onFocus,
  onBlur,
  enabledRename,
  rowContent,
  onDragStart,
  onDeleteKey,
  ...props
}) {
  const [value, setValue] = useState(columnData);
  const valueRef = useRef(value);
  const [valueChanged, setValueChanged] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    return () => {
      if (!disabled) {
        endEditing();
      }
    }
  }, []);

  useEffect(() => {
    if (editMode) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [editMode]);

  const endEditing = () => {
    if (valueChanged) {
      setValueChanged(false);

      if (typeof onChange === 'function') {
        onChange(rowIndex, columnKey, valueRef.current);
      }
    }
  };

  const updateValue = (newValue) => {
    setValue(newValue);
    setValueChanged(true);
    valueRef.current = newValue;
  };

  const handleInputChange = (e, newValue) => {
    updateValue(newValue);
  };

  const handleInputBlur = () => {
    const hasValueChanged = valueChanged;
    endEditing();
    setEditMode(false);

    if (typeof onBlur === 'function' && hasValueChanged) {
      onBlur(rowIndex, columnKey, valueRef.current);
    }
  };

  const handleInputFocus = () => {
    if (typeof onFocus === 'function') {
      onFocus(rowIndex, columnKey);
    }
  };

  const handleEditButtonClick = () => {
    setEditMode(true);
    setButtonVisible(false);
  }

  const handleDragStart = e => {
    if (editMode) {
      e.stopPropagation();
      e.preventDefault();
    } else {
      if (typeof onDragStart === 'function') {
        onDragStart(rowIndex, rowContent, e);
      }
    }
  }

  const handleDoubleClick = e => {
    if (editMode) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  const handleKeyDown = e => {
    if (!enabledRename) {
      return;
    }

    if (e.key === 'F2') {
      setEditMode(true);
      setButtonVisible(false);
    } else if (e.key === 'Enter') {
      if (editMode) {
        setEditMode(false);
        handleInputBlur();
      }
    } else if (e.key === 'Delete') {
      if (editMode) {
        e.stopPropagation();
      };
    } else if (e.keyCode === vars.KEYS.A && (e.metaKey || e.ctrlKey)) {
      if (editMode) {
        e.stopPropagation();
      }
    }
  }

  return (
    <div className='cell' onDragStart={handleDragStart} draggable={true} onDoubleClick={handleDoubleClick}
      style={{ cursor: 'grab' }} onKeyDown={handleKeyDown} tabIndex='0'>
      <div className='cell-content' >
        <div className='editable-cell-content-container'
          onMouseEnter={() => enabledRename && !editMode && setButtonVisible(true)}
          onMouseLeave={() => setButtonVisible(false)}>
          {editMode ? <TextInput ref={inputRef}
            value={value}
            disabled={disabled}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            {...props}
          /> : <label title={value} style={{ cursor: 'grab' }}>{value}</label>}
          <FlatButton style={{ display: buttonVisible ? 'flex' : 'none' }} className='crtx-form-button-link' onClick={handleEditButtonClick} tooltip={translate('Edit file name')} disabled={disabled}>
            <img className='material-icons' src={iconsService.getGeneralIcon(null, 'edit')} />
          </FlatButton>
        </div>
      </div>
    </div>
  );
}

EditableCell.propTypes = {
  columnData: PropTypes.any,
  rowIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onDragStart: PropTypes.func,
  onDeleteKey: PropTypes.func,
};

export default EditableCell;
