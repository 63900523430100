/**
 * Created by moshemal on 7/5/15.
 */

define(['AbstractAction', './SaveWorkflow'], function (AbstractAction, SaveWorkflow) {

  'use strict'

  return AbstractAction.extend({
    isApplicable: function(){
      return !this.module.view ? false : this.module.view.isEditable;
    },
    execute: function () {
      var module = this.module;
      SaveWorkflow.prototype.execute.call(this).then(function(){
          module._exitEditWorkflow();
      });
    }
  });
});
