import React, { Component } from 'react';
import PropTypes from 'prop-types';
import utils from '../utils';
import SimpleFormComponent from '../SimpleFormComponent';

export default SimpleFormComponent(class extends Component {
  static propTypes = {

    //store - The main store of the SimpleForm that the input is child of.
    store: PropTypes.object,

    bind: PropTypes.string,

    value: PropTypes.any,

    style: PropTypes.object,

    className: PropTypes.string,

    visible: PropTypes.bool,

    disabled: PropTypes.bool,

    col: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),

    offset: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ])

  };

  static defaultProps = {
    bind: ''
  };

  renderContent = (store, bind, value, children) => {

    return typeof children !== 'undefined' ? children :
      typeof value !== 'undefined' ? value :
        typeof bind === 'string' ? store.get(bind) :
          null;

  };

  render() {

    var { store, bind, value, style, visible, className, disabled, col, offset, flex, children, ...props } = this.props;

    return <label className={utils.combineClassNames(['text', className, disabled ? 'disabled' : null])}
      style={style}
      {...props}>
      {this.renderContent(store, bind, value, children)}
    </label>;

  }
});
