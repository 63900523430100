/**
 * @name CommCenterView Module
 * @author Liat
 */
import sandbox from 'sandbox';
import AbstractModule from 'AbstractModule';
import Controller from './controllers/ColorTableViewController';
import TableWidget from 'TableWidget';
import logger from 'logger';
import colorService from 'core/services/colorService';

'use strict';

var log = logger.getDefaultLogger();

function getDefaultColumns() {
  return [

    {title: "Color", field: "names", width: '20%', render: colorRenderer},

    {title: "Separation Code", field: "names", width: '20%', render: codeRenderer},

    {title: "Type", field: "colorType", width: '12%'},

    {title: "C", field: "cyan", width: '12%', render: percentageRenderer.bind(this, 'cyan')},

    {title: "M", field: "magenta", width: '12%', render: percentageRenderer.bind(this, 'magenta')},

    {title: "Y", field: "yellow", width: '12%', render: percentageRenderer.bind(this, 'yellow')},

    {title: "K", field: "black", width: '12%', render: percentageRenderer.bind(this, 'black')}

  ];
}

function percentageRenderer(color, data) {
  return Math.round(data[color] * 100);
}

function colorRenderer(data) {
  const {cyan, magenta, yellow, black} = data;
  var background = 'background-color: ' + sandbox.colors.getUIColorRGB({cyan, magenta, yellow, black});

  return "<table style='width 100%; height: 100%'><tr><td><div class='color-square' style='" + background + "'></div></td><td>" + getColorProperty('name', data) + "</td></tr></table>";
}

function codeRenderer(data) {
  return getColorProperty('code', data);
}

function getColorProperty(property, data) {
  var names = [];
  for (var j = 0, lenj = data.names.length; j < lenj; j += 1) {
    names.push(data.names[j][property]);
  }
  return names.toString();
}

function init() {

  this.ctrl.set('content', this.model.colors.sort(colorService.createColorComparator()));
  this.ctrl.set('module', this);

  sandbox.dom.addClass(this.element, 'pro-modules-colortable');
  sandbox.dom.empty(this.element);

  this.colorTable = new TableWidget({
    element: this.element,
    data: this.ctrl.get('content'),
    columns: getDefaultColumns.call(this)
  });

  this.selection.applyTableSelection(this, this.colorTable);

  this.colorTable.bind('delete', function (selected) {
    this.ctrl.deleteColor(selected);
  }, this);

  this.colorTable.bind('dblclick', function (selected) {
    this.navigateByViewLink(selected);
  }, this);

  this.updateRootPlaceholders(this.model);
}

module.exports = AbstractModule.extend({

  initDone: function () {
    this.ctrl = Controller.create();
    this.toolbar = this.createToolbar();
    sandbox.register('colorTable', this);
    init.call(this);
  },

  destroy: function () {
    this._super();
    if (typeof this.colorTable !== 'undefined') {
      this.colorTable.destroy();
    }
    this.ctrl.destroy();
  }
});
