/**
 * Created by tzvikas on 4/30/2015.
 */

define(['sandbox/planSetup', './../../../utils/wizardUtils', 'core/services/colorService'],
  function(PlanSetup, wizardUtils, colorService){
    'use strict';



    function findMasterZone(edition, zone){
      if (zone.hasOwnProperty("masterZoneName") && zone.masterZoneName !== null){
        for (var i=0;i<edition.zones.length;i++){
          if (edition.zones[i].displayName === zone.masterZoneName){
            return edition.zones[i];
          }
        }
      }
      //else if (zone.order > 0){
      //  //return edition.zones[zone.order - 1];
      //}
      return null;
    }

    function findBaseZone(edition, zone){
      var masterZone = findMasterZone(edition, zone);
      return (masterZone === null) ? zone : findBaseZone(edition, masterZone);
    }

    function mappedPagesByTitle(edition){
      var ret = {};
      for (var i=0;i<edition.zones.length;i++){
        var zone = edition.zones[i];
        var masterZone = findMasterZone(edition, zone);
        if (masterZone !== null){
          ret["masterZone:" + zone.code] = masterZone;
          ret["baseZone:" + zone.code] = findBaseZone(edition, masterZone);
        }

        for (var j=0;j<zone.sections.length;j++){
          var section = zone.sections[j];
          if (typeof section.pages === "undefined" || section.pages === null){
            continue;
          }
          for (var k=0;k<section.pages.length;k++){
            var page = section.pages[k];
            var key = zone.code + ":" + section.displayName + ":" + page.title;
            ret[key] = page;
          }
        }
      }
      return ret;
    }

    function createDefaultPage(section, numberInSection, pagination, colorObjects, clientSettings = {} ){      
      var pageSourceId = clientSettings.sourceIdBreadcrumb ? section.sourceId + "/" + pagination : section.sourceId + numberInSection;
      pageSourceId = pageSourceId.toLowerCase();
      //var sectionStartWith = section.startPage;
      //var calcPagination = sectionStartWith + (pagination-1);
      var colors = section.colors.length === 0 ? [colorObjects.cyan,colorObjects.magenta,colorObjects.yellow,colorObjects.black] : section.colors;
      var separations = [];
      colors.forEach(function(color){
        var sepSourceId = clientSettings.sourceIdBreadcrumb ? pageSourceId + "/" + color : pageSourceId + color;
        var sep = {displayName:color, sourceId:sepSourceId, parent:pageSourceId};
        separations.push(sep);
      });
      //var page =  {type:'page', displayName:calcPagination, sourceId:pageSourceId, separations: separations, pagination:calcPagination,
      //  parent:section.sourceId, title:calcPagination, sectionCode:section.code, index:calcPagination};
      var page =  {type:'page', displayName:pagination, sourceId:pageSourceId, separations: separations, pagination:pagination,
        parent:section.sourceId, title:pagination, sectionCode:section.code, numberInSection:numberInSection, folioNumber:pagination};

      page.customProperties = {};
      if (section.contentTypes){
        for (var i=0;i<section.contentTypes.length;i++){
          var ct = section.contentTypes[i];
          if (ct === "EDITORIAL"){
            page.customProperties.ED = true    
          } else if (ct === "AD"){
            page.customProperties.AD = true    
          }
        }
      }
      
         
      return page;
    }

    function resolveLastFolio(pages){
      if (pages.length === 0){
        return 0;
      }
      var lastPage = pages[pages.length-1];
      return lastPage.folioNumber ? lastPage.folioNumber : lastPage.numberInSection;
    }
    function adjustSectionSize(numPages, section, titlesMap, keyPrefix, localMap){
      var pages = section.pages;
      var startWith = parseInt(section.startPage, 10);
      var ret = [];
      if (pages.length === 0){
        return ret;
      }
      var virtualsMap = {};
      for (var i=pages.length-1;i>=0;i--){
        var n = parseInt(pages[i].numberInSection, 10);
        if (n > parseInt(numPages,10)){
          if (pages[i].virtual){
            virtualsMap[pages[i].sourceId] = '1';
          }
          pages.splice(i, 1);
        }

      }

      var sectionCounter = 0, paginationCounter = 0;
      var sectionCounter1 = 0, paginationCounter1 = 0;
      var startWith = parseInt(section.startPage, 10);
      var splitNumPages = section.split ?  parseInt(section.splitNumPages, 10) : 9999;
      for (var n=0;n<section.pages.length;n++){
        var page = section.pages[n];
        if (virtualsMap[page.virtualPage] === '1'){
          page.panorama = false;
          page.virtualTitle = null;
        }
        var splitChar = page.split === null || typeof page.split === 'undefined' ? "" : page.split;
        var insertChar = page.insertChar === null || typeof page.insertChar === 'undefined' ? "" : page.insertChar;
        if (splitChar !== "B"){
          sectionCounter++;
          sectionCounter1++;
        }
        if (insertChar === "" && splitChar !== "B"){
          paginationCounter++;
          paginationCounter1++;
        }

        if (sectionCounter == splitNumPages + 1){
          startWith = parseInt(section.splitStartPage, 10);
          paginationCounter1 = 1;
          sectionCounter1 = 1;
        }

        //page.number = startWith + sectionCounter - 1;
        //page.pagination = startWith + paginationCounter - 1;


        page.numberInSection = sectionCounter;

        page.number = startWith + sectionCounter1 - 1;
        page.pagination = startWith + paginationCounter1 - 1;        
      }


      for (var i=0, j=0;i<pages.length;i++){
        var n = pages[i].numberInSection;
        var splitChar = pages[i].split === null || typeof pages[i].split === 'undefined' ? "" : pages[i].split;
        if (n === null || typeof n === 'undefined'){
          n = j;
        }
        if (splitChar !== "B"){
          ret[parseInt(j,10)] = pages[i];
          j++;
        } 
        var key = keyPrefix + ":" + pages[i].pagination;
        titlesMap[key] = pages[i];
        localMap[pages[i].sourceId] = pages[i];

      }
      return ret;
    }

    function resolveRealNumPages(section){
      var counter = parseInt(section.numPages, 10);
      if (section.pages === null || typeof section.pages === "undefined"){
        return counter;
      }
      section.pages.forEach(function(page){
        var numInserts = page.numInserts !== null && typeof page.numInserts !== "undefined" ?
          parseInt(page.numInserts, 10) : 0;

        counter += numInserts;
      });
      return counter;

    }

    function validModel (model, colorObjects) {
      //var mappedModel = wizardUtils.mapSourceIdToObject(this.stepModel);
      var that = this;
      var ret = [];
      var publication = model.publication;

      that.isDefaultColor = PlanSetup.isDefaultColor(publication);
      var sectionsMap = {};
      var editions = publication.editions;
      var localMap = {};
      editions.forEach(function(edition){
        var titlesMap = {};
        var doneZones = {};

        var newCreatedPages = {};
        var zones = edition.zones;
        zones.forEach(function(zone){
          doneZones[zone.sourceId] = zone;
          var sections = zone.sections;
          sections.forEach(function(section){
            sectionsMap[section.sourceId] = section;
            doneZones[section.sourceId] = zone;
            var numPages = resolveRealNumPages(section);
            section.pages = section.pages || [];
            var splitNumPages = section.split ?  numPages - parseInt(section.splitNumPages, 10) : numPages;
            var startPage = parseInt(section.startPage, 10);
            var mappedPages = adjustSectionSize(numPages, section, titlesMap, zone.code + ":" + section.displayName, localMap);
            var lastFolio = resolveLastFolio(section.pages);
            for (var i=1,j=lastFolio;i<=numPages;i++){
              if (mappedPages.length < i ){
                j++;
                if (i == splitNumPages + 1){
                  startPage = parseInt(section.splitStartPage, 10);
                  j = 1;
                }                
                var number =  startPage + j - 1;
                console.log("number:" + number);
                var page = createDefaultPage.call(that, section, i, number, colorObjects, model.clientSettings || {});
                ret.push(page);
                newCreatedPages[page.sourceId] = 1;
                var key = zone.code + ":" + section.displayName + ":" + page.pagination;
                titlesMap[key] = page;
                section.pages.push(page);
                localMap[page.sourceId] = page;
              }
            }
          });
        });

        zones.sort(function(z1, z2){
          return parseInt(z1.sharingOrder, 10) - parseInt(z2.sharingOrder, 10);
        });


        zones.forEach(function(zone) {
          var doneSections = {};
          var counter = 0;
          var masterZone = findMasterZone(edition, zone);

          if (masterZone !== null && typeof masterZone !== "undefined"){
            zone.masterZoneName = masterZone.displayName;
            zone.masterZone = masterZone.sourceId;
            //zone.share = true;
          }

          var sections = zone.sections;
          sections.forEach(function (section) {
            //doneZones[section.sourceId] = zone;
            //var numPages = resolveRealNumPages(section);
            counter = updateSection(doneSections, sectionsMap, section, counter);
            section.pages = section.pages || [];
            //var mappedPages = adjustSectionSize(numPages, section.pages);
            section.pages.forEach(function (page) {
              if (typeof newCreatedPages[page.sourceId] === 'undefined' || newCreatedPages[page.sourceId] === null) {
                return;
              }
              var masterPage = null, directMaster = null;
              if (masterZone !== null) {
                var masterKey = masterZone.code + ":" + section.displayName + ":" + page.title;
                masterPage = titlesMap[masterKey];
                directMaster = titlesMap[masterKey];
                while (masterPage !== null && typeof masterPage !== 'undefined' &&
                  masterPage.masterPage !== null && typeof masterPage.masterPage !== 'undefined') {
                  masterKey = masterPage.masterZone + ":" + section.displayName + ":" + page.title;
                  masterPage = titlesMap[masterKey];
                }
                if (directMaster != null){
                  page.directMaster = directMaster.sourceId;
                  if (!directMaster.followers){
                    directMaster.followers = [];
                  }
                  directMaster.followers.push(page.sourceId);
                }
                if (masterPage !== null && typeof masterPage !== "undefined" && zone.share) {
                  page.masterZone = masterZone.code;
                  page.masterPage = masterPage.sourceId;
                  page.masterPageZone = doneZones[masterPage.parent].code;
                  for (var j = 0; j < page.separations.length; j++) {
                    for (var k = 0; k < masterPage.separations.length; k++) {
                      if (masterPage.separations[k].displayName === page.separations[j].displayName) {
                        page.separations[j].masterZone = page.masterZone;
                        page.separations[j].masterSeparation = masterPage.separations[k].sourceId;
                        page.separations[j].masterPageZone = page.masterPageZone;
                        break;
                      }
                    }
                  }
                }
              }

            });

          });
        });

        zones.sort(function(z1, z2){
          return parseInt(z1.order, 10) - parseInt(z2.order, 10);
        });
      });


      return ret;
    }

    function updateSection(doneSections, sectionsMap, section, counter){
      if (doneSections[section.sourceId] === 1){
        return counter;
      }
      var split = section.split;
      var numPages = parseInt(section.numPages, 10);
      if (!split)
      {

        section.pages.forEach(function(page){
          page.paginationInZone =  parseInt(page.pagination, 10) + counter;
        });

        counter += numPages;
      } else {
        var splitNumPages = parseInt(section.splitNumPages, 10);
        var numberBeforeSplit = numPages - splitNumPages;

        for (var j=1;j<=section.pages.length;j++){
          var pagination = parseInt(section.pages[j-1].pagination, 10);
          if (pagination <= numberBeforeSplit){
            section.pages[j-1].paginationInZone =  parseInt(section.pages[j-1].pagination, 10) + counter;
          }
        }
        counter += numberBeforeSplit;
        var splitSection = sectionsMap[section.splitAfter];
        if (splitSection.sourceId !== section.sourceId){
          counter = updateSection(doneSections, sectionsMap, splitSection, counter)
        }   

        for (var j=1;j<=section.pages.length;j++){
          var pagination = parseInt(section.pages[j-1].pagination, 10);
          if (pagination > numberBeforeSplit){
            section.pages[j-1].paginationInZone =  pagination - numberBeforeSplit + counter;
          }
        }
        counter += splitNumPages;
      }
      doneSections[section.sourceId] = 1;
      return counter;
    }

    return {

      validModel: validModel

    };

  })
;
