import { includesSpecialChars } from 'utilities/regex';

const ILLEGAL_CHARS = '\\/:*?"<>|';

export default () => {

  const matchesMask = userValue => {
    return !includesSpecialChars(userValue, ILLEGAL_CHARS);
  };

  return {
    matchesMask
  }

};
