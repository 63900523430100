/**
 * @name BaseTabStrip
 * @file BaseTabStrip class wraps Kendo TabStrip component and provides API for Tabs manipulation
 *
 * @author Boris
 * @since: 2019-03-04
 */

import {selectorFromId} from 'utilities/desktop';
import pubsub from 'core/managers/pubsub';
import Tab from './Tab';
import KendoTabStrip from 'kendo.tabstrip';
import { getModuleId } from 'utilities/desktop';

const DEFAULT_TAB_STRIP_OPTIONS = {
  animation: false
};

function activateTabHandler(event, tabStripInstance) {
  pubsub.publish('tab-activated', event);

  const activeTab = tabStripInstance.getActiveTab();
  if (activeTab) {
    activeTab.triggerSelect();
  }

  const lastActiveTab = tabStripInstance.findTabByIndex(tabStripInstance.lastActiveTabIndex);
  if (lastActiveTab && lastActiveTab !== activeTab) {
    lastActiveTab.triggerDeselect();
  }

  tabStripInstance.lastActiveTabIndex = activeTab ? activeTab.getTabIndex() : -1;
}

class BaseTabStrip {
  /**
   * Constructor
   * @param containerId - the id of the DOM container for the TabStrip component
   * @param tabStripOptions - Kendo TabStrip options
   */
  constructor(containerId, tabStripOptions = {}) {
    const tabStripElement = $(`<div id="${containerId}-tabs"></div>`);
    tabStripElement.appendTo(selectorFromId(containerId));

    const options = {...DEFAULT_TAB_STRIP_OPTIONS, ...tabStripOptions};
    const derivedActivate = options.activate;
    options.activate = event => {
      activateTabHandler(event, this);
      if (typeof derivedActivate === 'function') {
        derivedActivate(event, this);
      }
    };

    this.tabStrip = tabStripElement.kendoTabStrip(options).data('kendoTabStrip');

    this.lastActiveTabIndex = -1;
  }

  // Private Array of Tab instances in the order of their creation
  _tabArray = [];

  /**
   * Returns array of Tab instances in the order that they appear in the TabStrip
   * @returns {Tab[]}
   */
  getTabs() {
    const tabs = new Array(this._tabArray.length);
    this._tabArray.forEach(tab => tabs[tab.getTabIndex()] = tab);

    return tabs;
  }

  /**
   * Obtains the DOM element that represents the TabStrip component
   * @returns {object}
   */
  getTabStripElement() {
    return this.tabStrip.element[0];
  }

  /**
   * Creates Tab instance and appends it to the internal _tabArray
   * @returns {Tab}
   */
  appendTab(tabState, composeTabTitle) {
    const tab = new Tab(this, tabState, composeTabTitle);
    this._tabArray.push(tab);

    return tab;
  }

  /**
   * Removes Tab instance from the internal _tabArray and removes corresponding tab item from the TabStrip component
   * @param tab - Tab instance
   * @returns {boolean} - true if the Tab has been removed
   */
  removeTab(tab) {
    if (!tab) {
      return false;
    }

    const tabIndex = tab.getTabIndex();
    const activeTabRemoved = tabIndex === this.getActiveTabIndex();

    this.tabStrip.remove(tabIndex);
    this._tabArray.splice(this._tabArray.findIndex(t => t === tab), 1);

    this.lastActiveTabIndex = this.getActiveTabIndex();
    if (activeTabRemoved) {
      if (this.getTabCount() > 0) {
        this.selectTab(tabIndex > 0 ? tabIndex - 1 : 0);
      }
    }

    return true;
  }

  /**
   * Returns number of tabs in the TabStrip component
   * @returns {number} - number of tabs
   */
  getTabCount() {
    return this._tabArray.length;
  }

  /**
   * Returns the active Tab instance that corresponds to the selected tab item
   * @returns {Tab} - active Tab instance
   */
  getActiveTab() {
    return this._tabArray.find(tab => tab.isActive());
  }

  /**
 * Remove the given tab from the KendoUI tabStrip widget and from the given array of tab instances.
 * After removing the tab stop containing module and publish 'active-selection-changed' message.
 * @param {object} tab - instance of the Tab class
 */
  closeTabHandler(tab) {
    const moduleId = getModuleId(tab.getContentElement());
    require('core/managers/module').stopModule(moduleId);
  
    this.removeTab(tab);
  
    if (this.getTabCount() <= 0) {
      pubsub.publish('active-selection-changed', { selected: [], moduleTarget: 'main' });
    }
  }

  /**
   * Returns the index of the active Tab instance or -1 if no tab item is selected
   * @returns {number} - active Tab instance index
   */
  getActiveTabIndex() {
    const activeTab = this.getActiveTab();

    return activeTab ? activeTab.getTabIndex() : -1;
  }

  /**
   * Returns the Tab instance that corresponds to the given tab item index
   * @returns {Tab} - Tab instance
   */
  findTabByIndex(index) {
    return this._tabArray.find(tab => tab.getTabIndex() === index);
  }

  /**
   * Select Tab that corresponds to the given tab item index
   * @param tabIndex
   */
  selectTab(tabIndex) {
    const tab = this.findTabByIndex(tabIndex);
    tab && tab.select();
  }
}

module.exports = BaseTabStrip;