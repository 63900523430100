import localization from 'core/services/localization';

export function toKendoDateFormat(){
  const format = localization.getLocaleDateFormat();
  return format.replace(/y+/g, 'yyyy');
}

export function toKendoTimeFormat(){
  const format = localization.getLocaleTimeFormat();
  return format.replace(/a/g, 'tt');
}

export function toKendoDateTimeFormat(){
  return toKendoDateFormat() + ' ' + toKendoTimeFormat();
}

export function toKendoDateTimeFormatWithoutYear(){
  let format = toKendoDateTimeFormat();
  format = format.replace(/y/g, '');
  const index = format.search(/^\W|\W$|\W\W/);
  format = format.slice(0, index) + format.slice(index + 1);
  return format;
}
