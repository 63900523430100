import React, {useState} from 'react';
import PropTypes from 'prop-types';
import TabStrip from 'components/common/tabstrip/TabStrip';
import Tab from 'components/common/tabstrip/Tab';
import LayoutsTab from './LayoutsTab';
import InksTab from './InksTab';
import PlatesTab from './PlatesTab';
import {translate} from 'core/services/localization';

function LeftPanel({model, module, onSelect, selectedItem, onAddOrDuplicateItem, onDeleteItem, onEditItem}) {

  const [selectedTab, setSelectedTab] = useState(0);

  const renderLayoutGroups = () => {

    return <LayoutsTab model={model}
      module={module}
      onSelect={onSelect}
      selectedItem={selectedItem}
      buttonTitle={translate('New Layout Group')}
      onAddOrDuplicateItem={onAddOrDuplicateItem}
      onDeleteItem={onDeleteItem}
      onEditItem={onEditItem}>
    </LayoutsTab>;
  };

  const renderPlates = () => {

    return <PlatesTab model={model}
      module={module}
      onSelect={onSelect}
      selectedItem={selectedItem}
      buttonTitle={translate('New Plate')}
      onAddOrDuplicateItem={onAddOrDuplicateItem}
      onDeleteItem={onDeleteItem}
      onEditItem={onEditItem}>
    </PlatesTab>;
  };

  const renderInkSettings = () => {

    return <InksTab model={model}
      module={module}
      onSelect={onSelect}
      selectedItem={selectedItem}
      buttonTitle={translate('New Ink Settings')}
      onAddOrDuplicateItem={onAddOrDuplicateItem}
      onDeleteItem={onDeleteItem}
      onEditItem={onEditItem}>
    </InksTab>;
  };

  const tabs = [
    {
      id: 'layoutGroups',
      title: translate('Layout Groups'),
      buttonTitle: translate('New Layout Group'),
      renderData: renderLayoutGroups
    },
    {
      id: 'plates',
      title: translate('Plates'),
      buttonTitle: translate('New Plate'),
      renderData: renderPlates
    },
    {
      id: 'inks',
      title: translate('Ink Settings'),
      buttonTitle: translate('New Ink Settings'),
      renderData: renderInkSettings
    }
  ];

  const renderTab = (tab) => {
    return (
      <Tab key={tab.id} caption={tab.title}>
        {tab.renderData()}
      </Tab>);
  };


  return (
    <TabStrip selected={selectedTab} onSelect={(e, tabIndex) => {setSelectedTab(tabIndex); onSelect(null)}}>
      {tabs.map(renderTab)}
    </TabStrip>
  );
}

LeftPanel.propTypes = {
  model: PropTypes.any,
  module: PropTypes.any,
  onSelect: PropTypes.func,
  selectedItem: PropTypes.any,
  onAddOrDuplicateItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  onEditItem: PropTypes.func
};

export default LeftPanel;