/* @flow weak */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import labels from '../labels';

const categories = [
  { name: 'samples', text: labels.sampleTables },
  { name: 'systems', text: labels.systemTables }
];
const categoryMapping = {
  'samples': 'sampleTables',
  'systems': 'systemTables'
};
const emptyTableToAdd = {
  name: 'Blank Table',
  description: '',
  isBlank: true
};

module.exports = class NewTable extends Component {
  constructor(props) {
    super(props);
    this.handleCategoryClick = this.handleCategoryClick.bind(this);
    this.renderCategory = this.renderCategory.bind(this);
    this.renderCategories = this.renderCategories.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.renderTables = this.renderTables.bind(this);
  }

  static propTypes = {
    store: PropTypes.any
  }

  handleCategoryClick(category) {
    const store = this.props.store;
    store.dispatch({
      type: 'SELECT_CATEGORY',
      category: category
    });
  }

  renderCategory(selectedCategory) {
    return function (category, index) {
      const isSelected = category.name === selectedCategory;
      const selectedClassName = isSelected ? 'selected' : '';
      const className = `category ${selectedClassName}`;
      return <div key={index} className={className} onClick={() => { this.handleCategoryClick(category.name) }}>
        {category.text}
      </div>;
    }.bind(this);
  }

  renderCategories() {
    const store = this.props.store;
    const state = store.getState();
    const selectedCategory = state.selectedCategory;
    return categories.map(this.renderCategory(selectedCategory));
  }

  handleTableClick(table, loaded) {
    if (loaded) return () => { };
    return function () {
      const store = this.props.store;
      store.dispatch({
        type: 'SELECT_TABLE',
        table: table
      });
    }.bind(this);
  }

  renderTable(tableCategory, selectedTable) {
    return function (table, index) {
      const isSelected = table === selectedTable;
      const selectedClassName = isSelected ? 'selected' : '';
      const loadedContent = table.loaded ? 'Loaded' : '';
      const disabledClassName = table.loaded && tableCategory === 'systems' ? 'disabled' : '';
      const className = `table ${selectedClassName} ${disabledClassName}`;
      const loadedStyle = { display: tableCategory === 'systems' ? '' : 'none' };
      return <div key={index} className={className} onClick={this.handleTableClick(table, table.loaded)} title={table.name}>
        <div className="new-table-name">{table.name}</div>
        <div className="new-table-loaded" style={loadedStyle}>{loadedContent}</div>
        <div className="new-table-description">{table.description}</div>
      </div>
    }.bind(this);
  }

  renderTables(selectedCategory) {
    const store = this.props.store;
    const state = store.getState();
    const tables = state[categoryMapping[selectedCategory]] || [];
    const selectedTable = state.selectedTable;
    const tablesToRender = selectedCategory === 'samples' ? [emptyTableToAdd].concat(tables) : tables;

    return tablesToRender.map(this.renderTable(selectedCategory, selectedTable));
  }

  render() {
    const store = this.props.store;
    const state = store.getState();
    const selectedCategory = state.selectedCategory;

    return <div className="new-table-container">
      <div className="tables-categories">
        {this.renderCategories()}
      </div>
      <div className="new-tables">
        {this.renderTables(selectedCategory)}
      </div>
    </div>;
  }
}