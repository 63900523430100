/**
 * Created with JetBrains WebStorm.
 * User: elad
 * Date: 3/9/14
 * Time: 10:07 AM
 * To change this template use File | Settings | File Templates.
 */
define(['sandbox', '../server/OpenModuleAction'], function (sandbox, OpenModuleAction) {
  'use strict';

  return OpenModuleAction.extend({

    isApplicable: function () {
      var objects = this.module.selected;
      if (typeof objects === 'undefined') {
        return false;
      }

      for (var i = 0; i < objects.length; i++) {
        if (objects[i].type.indexOf("workflow/step") === 0) {
          return true;
        }
      }
      return false;
    }
  });

});