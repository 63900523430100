// @flow

const AbstractAction = require('AbstractAction');
const sandbox = require('sandbox');

module.exports = AbstractAction.extend({

  execute: function (newModel, oldModel) {
    this.module.handleSaveAction();
  }
});