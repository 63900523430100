import { translate } from 'core/services/localization';
import icons from 'core/services/iconService';

export const BYTES_MULTIPLIER = 1048576;

export const PAGE_STATUS_OPTIONS = [
  {
    value: 'error',
    data: { icon: icons.getGeneralIcon('status', 'error'), title: translate('Error') }
  },
  {
    value: 'waiting',
    data: { icon: icons.getGeneralIcon('status', 'wait'), title: translate('Waiting') }
  },
  {
    value: 'success',
    data: { icon: icons.getGeneralIcon('status', 'finished'), title: translate('Finished') }
  },
];

export const ICON_BY_FILE_TYPE = {
  pdf: { icon: 'pdf_16', iconSprite: 'general', title: translate('PDF'), iconClassName: 'crtx-size-16' },
  tif: { icon: 'tiff_16', iconSprite: 'general', title: translate('TIFF'), iconClassName: 'crtx-size-16' },
};

export const FILE_TYPE_OPTIONS = [
  {
    value: 'pdf',
    data: { ...ICON_BY_FILE_TYPE['pdf'] }
  },
  {
    value: 'tif',
    data: { ...ICON_BY_FILE_TYPE['tif'] }
  }
];

export const DEFAULT_SORT = [
  {
    ascending: true,
    key: 'inputTime'
  }
];