/**
 * @name
 * @fileOverview
 * @author sergey & Elad
 */
define(['ember', 'text!../templates/zone.hbs', 'text!../templates/book.hbs', 'text!../templates/bookSections.hbs',
    'text!../templates/bookSection.hbs', './DynamicTextField', './DynamicCheckBox', './DynamicSelect'],
  function (Ember, MainTemplate, bookTemplate, bookSectionsTemplate, bookSectionTemplate, DynamicText, DynamicCheckbox, DynamicSelect) {
    'use strict';

    var headerTemplate =
      '{{#each header in headers}}' +
      '<div class="column">{{header.label}}</div>' +
      '{{/each}}';

    var HeaderView = Ember.View.extend({
      tagName: 'li',
      classNames: ['pw-row', 'section'],

      layout: Ember.Handlebars.compile(headerTemplate)
    });

    var longTextView = Ember.TextField.extend({
      classNames: ['k-textbox']
    });

    var shortTextView = Ember.TextField.extend({
      classNames: ['k-textbox'],
      attributeBindings: ['style'],
      style: 'width:4em'
    });

    function getInput(colDescriptor, controller, book){
			if (colDescriptor.type === "text" || colDescriptor.type === "numeric"){

				var dsbl = false;
				var readonly = colDescriptor['readonly'];				

				return DynamicText.create({
					controller: controller,					
					numeric: (colDescriptor.type === "numeric"),
					model: book,
					key: colDescriptor.name,
					classNames: ['k-textbox'],
					style: colDescriptor['style'],
					disabled: dsbl,
          readonly: readonly
				});
			} else if (colDescriptor.type === "checkbox"){
				var dsbl = false;
				
				return DynamicCheckbox.create({
					controller: controller,
					model: book,
					disabled: dsbl,
					key: colDescriptor.name
				});
			} else if (colDescriptor.type === "select") {

				//return Ember.Select.create({
				//	content: colDescriptor.options,
				//	optionLabelPath: 'content.label',
				//	optionValuePath: 'content.name',
				//	//value: section[colDescriptor.name],
				//	selected: section[colDescriptor.name],
				//	model: section,
				//	onchange: 'alert(2)'
        //
				//});
				//section[colDescriptor.name] = section[colDescriptor.name] || colDescriptor.options[0].name;
				return DynamicSelect.create({
					content: colDescriptor.options,
					optionLabelPath: 'content.label',
					optionValuePath: 'content.name',
					controller: controller,
					model: book,
					key: colDescriptor.name,
					value: book[colDescriptor.name]
					//value: section[colDescriptor.name],


				});
			}
    }
    
    var InputView = Ember.ContainerView.extend({
			init: function() {
				this._super();
				var colDescriptor = this.get('colDescriptor');
				var controller = this.get('controller');
				var section = this.get('model');
				var inp = getInput(colDescriptor, controller, section);
				this.pushObject(
					inp
        );
    }});




    var sectionView = Ember.View.extend({

      init: function () {
        this._super();
        this.addObserver("model.sectionSourceId", function () {
          var allSections = Ember.get(this, "controller").sectionsOptions(Ember.get(this, "book"));
          var sectionSourceId = Ember.get(this.model, 'sectionSourceId');
          for (var i = 0; i < allSections.length; i++) {
            if (allSections[i].value === sectionSourceId) {
              Ember.set(this.model, "displayName", allSections[i].text);
            }
          }
        });
      },

      tagName: 'li',

      rangeView: longTextView,

      template: Ember.Handlebars.compile(bookSectionTemplate),

      sectionsOptions: function () {
        return Ember.get(this, "controller").sectionsOptions(Ember.get(this, "book"));
      }.property(),

      iconVisibility: function () {
        var isLastSection = Ember.get(this.model, 'isLastSection');
        return isLastSection ? "visibility:visible" : "visibility:hidden";
      }.property("model.isLastSection")

      //sectionSourceIdChange : function () {
      //	console.log (Ember.get(this, "controller").sectionsOptions(Ember.get(this, "book")));
      //	console.log (Ember.get(this.model, 'sectionSourceId'));
      //}.property("model.sectionSourceId")
    });

    var sectionsView = Ember.View.extend({
      template: Ember.Handlebars.compile(bookSectionsTemplate),

      sectionView: sectionView

    });

    var BookView = Ember.View.extend({
      tagName: 'li',
      classNames: ['pw-row', 'section'],
      inputView: InputView,
      template: Ember.Handlebars.compile(bookTemplate),

      sectionsView: sectionsView,

      nameView: longTextView,

      codeView: shortTextView,

      filteredPmiTemplates: function () {
        const ret = [];

        const pmiTemplates = this.controller.filterPmiTemplates(this.model);

        ret.addObject({text: "---", value: ""});
        pmiTemplates.forEach(function (pmi) {
          ret.addObject({text: pmi.name, value: pmi.name, numPages: pmi.numPages});
        });

        return ret;
      }.property("model.defaultLayout", "controller.model.pmiTemplates.length", "controller.model.pmiTemplates.@each", "model.sections.@each.range"),

      isPmiEnabled: function () {
        var layoutType = this.controller.resolveLayoutType(this.model.defaultLayout);
        //var hack = this.controller.model.uglyHack;
        //if (hack === "hide") {
        //  return false;
        //}
        var ret = layoutType === "custom";
        if (!ret){
          delete this.model.pmiTemplate;
        }
        return ret;
      }.property("model.defaultLayout", "controller.model.uglyHack"),
    });

    
        
    return Ember.View.extend({
      template: Ember.Handlebars.compile(MainTemplate),

      HeaderView: HeaderView,

      BookView: BookView


      //layoutOptions : function (){
      //	return this.controller.layoutOptions();
      //}.property()

    });
  });