export const isUndefined = (o) => {
  return typeof o === 'undefined';
};

export const isFunction = (o) => {
  return typeof o === 'function';
};

export const isString = (o) => {
  return typeof o === 'string';
};

export const absolutePath = (parentsPath, path) => {
  return isString(parentsPath) && parentsPath !== '' ? `${parentsPath}.${path}` : path;
};

const getCursorFromStringKey = (str) => {
  return str.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
    .replace(/^\./, '')           // strip a leading dot
    .split('.');
};

const getCursor = (path = '') => {
  return path === '' ? [] : getCursorFromStringKey(path);
};

export const get = (state = {}, path = '') => {
  const cursor = getCursor(path);

  return cursor.reduce((stateNode, cursorItem) => {
    if (isUndefined(stateNode) || isUndefined(stateNode[cursorItem])) return undefined;
    return stateNode[cursorItem];
  }, state);
};

export const set = (state = {}, path = '', value) => {
  const cursor = getCursor(path);
  const parentsCursor = cursor.slice(0, cursor.length - 1);
  const key = cursor[cursor.length - 1];
  let node = parentsCursor.reduce((stateNode, cursorItem) => {
    if (isUndefined(stateNode[cursorItem])) stateNode[cursorItem] = {};
    return stateNode[cursorItem];
  }, state);
  if (isUndefined(key)) {
    state = value;
  }
  else {
    node[key] = value;
  }
  return state;
}

// compose multiple paths into dot separated path: path1.path2 ...
export const composePath = (...paths) => {
  return paths.filter(path => typeof path !== 'undefined' && path !== '').join('.');
};

export default {
  isUndefined,
  isFunction,
  isString,
  absolutePath,
  get,
  set
};