import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';
import settingsManager from 'core/managers/settings';

const localization = sandbox.localization;
const CONTACT_TYPES = {
  user: 'controlpanel/profiles/users/user',
  group: 'controlpanel/profiles/groups/group',
};
const MATERIAL_ICONS = {
  PEOPLE: 'people',
  PERSON: 'person',
  STAR: 'star'
};
const translate = localization.translate;
const labels = {
  users: translate('Users'),
  myGroups: translate('My Groups'),
  groups: translate('Groups')
};
const isArray = o => Array.isArray(o);


export default class ContactsList extends Component {
  static propTypes = {
    ref: PropTypes.func,
    channelNwids: PropTypes.array,
    store: PropTypes.any,
    onRowClick: PropTypes.func,
  };

  handleRowClick = (index, id) => (event) => {
    const { onRowClick } = this.props;

    onRowClick(id);
  };

  renderContactRow = () => (contact, index) => {

    const { store } = this.props;
    const { selectedContact, isNewChatView } = store.getState();
    const isSelectedClassName = selectedContact && selectedContact.nwid === contact.nwid ? 'selected' : undefined;
    const contactType = contact.type === CONTACT_TYPES.user ? MATERIAL_ICONS.PERSON : MATERIAL_ICONS.PEOPLE;
    const currentUser = settingsManager.settings.user.name;

    if (isNewChatView && contact.userNames) {
      if (!(contact.userNames.find((name) => name === currentUser))) return;
    }

    return <div key={index} className={`crtx-chat-contact-row  ${isSelectedClassName}`} onClick={this.handleRowClick(index, contact)}>
      <div className='crtx-chat-contact-type'>
        <div className='crtx-chat-contact-type-icon-container'>
          <i className='material-icons'>{contactType}</i>
        </div>
      </div>

      <div className='crtx-chat-contact-name-row'>{contact.type === CONTACT_TYPES.user ? contact.userName : contact.groupFullName}</div>
    </div>;
  };



  render() {
    const { contacts, store } = this.props;
    const { isNewChatView } = store.getState();
    const groupsTitle = isNewChatView ? labels.myGroups : labels.groups;

    return <div className='crtx-chat-contacts-list-container' >
      {isArray(contacts.users) && <div className='crtx-chat-contacts-list-container-title'>{labels.users}</div>}
      {isArray(contacts.users) && contacts.users.map(this.renderContactRow())}
      {isArray(contacts.groups) && <div className='crtx-chat-contacts-list-container-title'>{groupsTitle}</div>}
      {isArray(contacts.groups) && contacts.groups.map(this.renderContactRow())}
    </div>;
  }

}