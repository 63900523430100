import React, { Component } from 'react';
import PropTypes from 'prop-types';

function Spinner(props) {
  if (props.show !== true) return <div style={{ display: 'none' }}></div>;

  return <div className="spinner-container">
    <div className="spinner-message">
      <span>{props.message}</span>
      <br />
      <div className="loader"></div>
    </div>
  </div>;
}

Spinner.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string
};

Spinner.defaultProps = {
  show: false,
  message: ''
};

export default Spinner;