import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'core/services/localization';
import iconService from 'core/services/iconService';
import { formatBreadcrumbsCodes, formatBreadcrumbs } from 'utilities/breadcrumbs';
import AggregateObject from './AggregateObject';
import AggregateViewInfo from './AggregateViewLink';
import DeadlineBar from 'components/private/deadline/DeadlineBar';
import MultiProgressBars from 'components/common/progress/MultiProgressBars';
import IndicatorsBar from './IndicatorsBar';
import { getHoldType } from 'utilities/hold';


const labels = {
  hold: translate('Hold'),
  min: translate('min'),
  changeDeadLine: translate('Change deadline'),
  pages: translate('Pages'),
  formes: translate('Formes'),
  plates: translate('Plates'),
};

const PROGRESS_COLORS = {
  FINISHED: '#4cd272',
  IN_PROGRESS: '#ffe843'
};


function ZoneSummary({
  zone,
  module,
  onChangeDeadline,
  onContextMenu,
  viewLinksMap,
  expand

}) {


  let indicators = {
    waitingForApproval: 0,
    held: 0,
    errors: 0
  };

  const pagesViewInfo = new AggregateViewInfo(zone.nwid,
    'sections',
    'BirdEyeLayers',
    module.nwid,
    viewLinksMap['sections'] && viewLinksMap['sections'][zone.nwid] ? viewLinksMap['sections'][zone.nwid] : []);


  const formesViewInfo = new AggregateViewInfo(zone.nwid,
    'books',
    'BirdEyeLayers',
    module.nwid,
    viewLinksMap['books'] && viewLinksMap['books'][zone.nwid] ? viewLinksMap['books'][zone.nwid] : []);


  const zoneAggregate = zone.aggregate || {};

  const calculateIndicators = (item) => {

    if (item.totalWaitingForApprovalPages && Number(item.totalWaitingForApprovalPages) !== 0) {
      indicators.waitingForApproval += Number(item.totalWaitingForApprovalPages);
    }
    if (item.totalHoldPages && Number(item.totalHoldPages !== 0)) {
      indicators.held += Number(item.totalHoldPages);
    }
    if (item.totalWaitingForApprovalForms && Number(item.totalWaitingForApprovalForms !== 0)) {
      indicators.waitingForApproval += Number(item.totalWaitingForApprovalForms);
    }
    if (item.totalHoldForms && Number(item.totalHoldForms !== 0)) {
      indicators.held += Number(item.totalHoldForms);
    }
    if (item.totalErrorPages && Number(item.totalErrorPages !== 0)) {
      indicators.errors += Number(item.totalErrorPages);
    }
    if (item.totalErrorForms && Number(item.totalErrorForms !== 0)) {
      indicators.errors += Number(item.totalErrorForms);
    }

  };

  calculateIndicators(zone.aggregate);

  const breadcrumbs = formatBreadcrumbsCodes(zone.breadcrumbs);
  const breadcrumbsTitle = formatBreadcrumbs(zone.breadcrumbs);


  const handleExpandZone = () => {
    expand(zone, true);
  };

  const renderPlates = () => {

    let summaryPlates = {
      totalPlates: 0,
      inProgressPlates: 0,
      finishedPlates: 0,
      errorsPlates: 0,
      held: false
    };

    zone.productionruns.forEach(run => {
      const showExpectedPlatesOnly = module.preferences.showExpectedPlatesOnly;
      const runAggregate = run.aggregate;
      const holdType = getHoldType(run);
      if (typeof holdType !== 'undefined' && holdType !== 'none') {
        summaryPlates.held = true;
      }

      summaryPlates.totalPlates += showExpectedPlatesOnly ? Number(runAggregate.totalActualPlates || 0) : Number(runAggregate.totalPlates || 0);
      summaryPlates.inProgressPlates += showExpectedPlatesOnly ? Number(runAggregate.totalActualPlatesInProgress || 0) : Number(runAggregate.totalPlatesInProgress || 0);
      summaryPlates.finishedPlates += showExpectedPlatesOnly ? Number(runAggregate.totalActualPlatesFinished || 0) : Number(runAggregate.totalFinishedPlates || 0);
      summaryPlates.errorsPlates += showExpectedPlatesOnly ? Number(runAggregate.totalActualErrorPlates || 0) : Number(runAggregate.totalErrorPlates || 0);

    });

    if (summaryPlates.errorsPlates !== 0) {
      indicators.errors += summaryPlates.errorsPlates;
    }

    const completedTotal = summaryPlates.finishedPlates;
    const inProgressTotal = summaryPlates.inProgressPlates + summaryPlates.errorsPlates;
    const missingTotal = summaryPlates.totalPlates - summaryPlates.finishedPlates - summaryPlates.inProgressPlates - summaryPlates.errorsPlates;

    const progressTitle = [
      translate('Completed: {1}', completedTotal),
      translate('In Progress: {1}', inProgressTotal),
      translate('Missing: {1}', missingTotal)
    ].join('\n');



    return (
      <React.Fragment>
        <MultiProgressBars
          min={0}
          max={summaryPlates.totalPlates}
          title={progressTitle}
          values={[{ color: PROGRESS_COLORS.FINISHED, value: completedTotal }, { color: PROGRESS_COLORS.IN_PROGRESS, value: inProgressTotal }]}
          onClick={handleExpandZone}
        >
          {completedTotal} - {inProgressTotal} - {missingTotal}
        </ MultiProgressBars>
        <div className='hold-indicator'>
          {summaryPlates.held && <img src={iconService.getGeneralIcon('', 'hold_structure', '.svg')} title={labels.hold} />}
        </div>
      </React.Fragment>
    );
  };

  const renderIndicators = () => {
    return <IndicatorsBar waitingForApproval={indicators.waitingForApproval} held={indicators.held} errors={indicators.errors} />;
  };

  return (<div className='crtx-zone-summury-container' onContextMenu={(e) => onContextMenu(e, zone)}>

    <div className='crtx-dashboard-breadcrumbs' title={breadcrumbsTitle}>{breadcrumbs}</div>
    <div className='crtx-info-tools-container'>
      <DeadlineBar
        deadlineDate={zone.deadlineDate}
        deadlinePassed={zone.deadlinePassed}
        holdType={getHoldType(zone)}
        holdUntilDate={zone.holdUntilDate}
        releaseOffset={parseInt(zone.releaseOffset)}
        onChangeDeadline={onChangeDeadline}
      />
    </div>


    <div className='crtx-dashboard-status-all'>
      <label>{labels.pages}:</label>
      <AggregateObject
        isSummary={true}
        icon={iconService.getTemplateIcon('page', 'large')}
        title={labels.pages}
        total={Number(zoneAggregate.totalPages) || 0}
        inProgress={Number(zoneAggregate.totalInProcessPages) || 0}
        finished={Number(zoneAggregate.totalFinishedPages) || 0}
        waitingForApproval={Number(zoneAggregate.totalWaitingForApprovalPages) || 0}
        held={Number(zoneAggregate.totalHoldPages) || 0}
        errors={Number(zoneAggregate.totalErrorPages) || 0}
        viewInfo={pagesViewInfo} />

      {Number(zoneAggregate.totalForms) > 0 && <React.Fragment>
        <label>{labels.formes}:</label>
        <AggregateObject
          isSummary={true}
          icon={iconService.getTemplateIcon('form', 'large')}
          title={labels.formes}
          total={Number(zoneAggregate.totalForms) || 0}
          inProgress={Number(zoneAggregate.totalInProcessForms) || 0}
          finished={Number(zoneAggregate.totalFinishedForms) || 0}
          waitingForApproval={Number(zoneAggregate.totalWaitingForApprovalForms) || 0}
          held={Number(zoneAggregate.totalHoldForms) || 0}
          errors={Number(zoneAggregate.totalErrorForms) || 0}
          viewInfo={formesViewInfo} />
      </React.Fragment>
      }
      {zone.productionruns.length > 0 && <React.Fragment>
        <label>{labels.plates}:</label>
        {renderPlates()}
      </React.Fragment>
      }
    </div>
    {renderIndicators()}
  </div>
  );
}

ZoneSummary.propTypes = {
  zone: PropTypes.object,
  module: PropTypes.any,
  onChangeDeadline: PropTypes.func,
  onContextMenu: PropTypes.func,
  viewLinksMap: PropTypes.object,
  expand: PropTypes.func,
};

export default ZoneSummary;