import React from 'react';
import { createRoot } from 'react-dom/client';
import dom from 'base/dom';
import AbstractStep from 'widgets/Wizard/AbstractStep';
import View from './View';
import { restGet } from 'core/managers/rest2';
import ModelHandler from './model/modelHandler';
import toastService from 'core/services/toastService';
import { translate } from 'core/services/localization';

const PRESS_SYSTEM_OPTIONS = ['mps', 'kba', 'both'];

function init(initObj) {
  console.log('MpsCatalogs.init()');
  this._super(initObj);
  const { wizard, projectorId, startParameters: { rootType, rootId, nwid: viewNwid } } = initObj;
  this.settings = { wizard, projectorId, rootType, rootId, viewNwid, ...this.model.clientSettings };

  this.instance = initObj.startParameters.nwid;
  this.modelHandler = new ModelHandler(this.model, this.settings);
}

function next() {
  console.log('MpsCatalogs next..................');

  let ret = this.modelHandler.convertFromStepModel();
  const model = this.modelHandler.getStepModel();


  if (!PRESS_SYSTEM_OPTIONS.find(o => o === model.pressSystem)) {
    ret = { isValid: false, error: translate('At least one option should be selected') };
    toastService.errorToast('', ret.error);
  }
  if (model.mpsEnabled && (!model.mpsCatalog || !model.mpsFormat)) {
    ret = { isValid: false, error: translate('Both catalog and format should be selected') };
    toastService.errorToast('', ret.error);
  }


  return ret.isValid;
}


function back() {
  return true;
}

function destroy() {
  if (this.reactRoot) {
    this.reactRoot.unmount();
  }

}

function render() {
  console.log('MpsCatalogs render.................');

}

function internalRender() {
  console.log('MpsCatalogs internalRender.................');

  // 
  this.settings.wizard.enableButton('next');
  this.settings.wizard.disableButton('cancel');
  this.containerElement = dom.find(this.container)[0];
  if (!this.reactRoot) {
    this.reactRoot = createRoot(this.containerElement);
  }

  const ignoreMps = this.model.clientSettings.ignoreMps;
  const catalogs = this.catalogs?.catalogues || [];
  catalogs.unshift({ id: '', name: '' });

  this.reactRoot.render(<View modelHandler={this.modelHandler} catalogs={catalogs} ignoreMps={ignoreMps} />);
}

function load() {
  console.log('MpsCatalogs load.................');
  const ignoreMps = this.model.clientSettings.ignoreMps;
  const that = this;

  return new Promise((resolve, reject) => {
    if (ignoreMps) {
      internalRender.call(that);
      resolve();
    } else {
      restGet(that.instance, `planning-wizard/custom/dnex/catalogues`)
        .then(result => {
          console.log('-------------------->' + result);
          that.catalogs = result;
          internalRender.call(that);
          resolve();
        });
    }
  });
}


module.exports = AbstractStep.extend({
  init,
  render,
  next,
  back,
  destroy,
  load
}, 'MpsCatalogs');