import React from 'react';
import Column from './Column';
import GenericHeader from './headers/GenericHeader';
import DragHandleCell from './cells/DragHandleCell';

export default function DragHandleColumnHoc(onDragHandleDragStart) {

  const handleDrag = () => {
    return {
      columnData: <span>::</span>,
      onDragHandleDragStart,
    };
  };

  return <Column
    key='dragHandle'
    columnKey='dragHandle'
    width={40}
    align='center'
    visible={true}
    resizable={false}
    header={<GenericHeader />}
    cell={<DragHandleCell />}
    cellDataGetter={handleDrag}
    dataGetter={handleDrag}
  />;
}