import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {translate} from 'core/services/localization';
import Checkbox from 'components/common/inputs/Checkbox';
import {observer} from 'mobx-react';
import BookList from './BookList';
import {toJS} from 'mobx';
import {BOOKS_MODE} from './Book';
import wizardUtils from './../../../utils/wizardUtils';

const labels = {
  addBook: translate('Add Book'),
  saddleStitch: translate('Saddle stitch mode'),
};

class Zone extends Component {

  static propTypes = {
    model: PropTypes.object,
  };

  handleAddBook = () => {
    const {model} = this.props;

    const newBook = {
      displayName: '',
      code: '',
      sourceId: wizardUtils.getSourceId(),
      defaultLayout: '',
      layoutType: undefined,
      paperType: undefined,
      pmiTemplate: undefined,
      skip: undefined,
      numPages: 0,
      sections: [{
        code: '',
        displayName: '',
        sectionSourceId: '',
        range: ''
      }]
    }

    if (model.customFields && model.customFields.length > 0) {
      model.customFields.forEach(element => {
        newBook[element.name] = undefined;
      });
    }

    model.books.push(newBook);
    if (model.booksMode === BOOKS_MODE.saddleStitch) {
      model.updateZoneInStepModel(model);
    }
  };

  handlePmiClick = (book, index) => {
    const {model} = this.props;

    model.onPmiClick(book, index, model);
  };

  handleChangeBooksMode = (value) => {
    const {model} = this.props;
    model.modeWasChanged = true;

    if (value) {
      model.perfectBindingLastModel = toJS(model.books);
      model.booksMode = BOOKS_MODE.saddleStitch;
      if (model.saddleStitchLastModel) {
        model.books = [...model.saddleStitchLastModel];
        model.isFirstSaddleTransform = false;
      } else {
        model.isFirstSaddleTransform = true;
      }
    } else {
      model.saddleStitchLastModel = toJS(model.books);
      model.booksMode = BOOKS_MODE.perfectBinding;
      if (model.perfectBindingLastModel) {
        model.books = [...model.perfectBindingLastModel];
        model.isFirstPerfectBindingTransform = false;
      } else {
        model.isFirstPerfectBindingTransform = true;
      }
    }
    model.updateZoneInStepModel(model);
  };

  render() {
    const {model} = this.props;

    return (<div className='crtx-wizard-step-container-books'>
      <div className='crtx-form-section-container'>
        <div className='crtx-form-element-full-height ctrx-inner-grid-two-rows'>
          <div className='crtx-form-two-columns'>
            <div className='crtx-form-button-link-with-text' onClick={this.handleAddBook}>
              <i className="material-icons" title={labels.addBook}>add</i>
              {labels.addBook}
            </div>
            <label className='crtx-form-label-with-checkbox'>
              <Checkbox checked={model.booksMode === BOOKS_MODE.saddleStitch || false} onChange={(e, value) => this.handleChangeBooksMode(value)} />
              {labels.saddleStitch}</label>
          </div>
          <BookList model={model} booksMode={model.booksMode} onPmiClick={(book, index) => this.handlePmiClick(book, index)}></BookList>
        </div>
      </div>
    </div>
    );
  }
}

export default observer(Zone);
