import { getMainStatus } from './statuses';

const TYPES_WITH_STATUS = ['page', 'form', 'page/separation', 'form/separation'];

export const canRetransmit = (obj, config) => {
  
  if (obj.type === 'page' && typeof obj.pageContent !== 'undefined') {
    if (obj.pageContent.isSetComplete === 'true') {
      return false;
    }
  } else if (obj.type === 'form' && typeof obj.formContent !== 'undefined') {
    if (obj.formContent.isSetComplete === 'true') {
      return false;
    }     
  }
  return checkCommonConditions(obj, config);
};

export const canRoute = (obj, config) => checkCommonConditions(obj, config);

const checkCommonConditions = (obj, config) => {

  if (!config.enableIfProgress || !TYPES_WITH_STATUS.includes(obj.type)) {
    return true;
  }

  const status = getMainStatus(obj);

  if (!status || typeof status.progress === 'undefined') {
    return false;
  }

  const requiredProgress = config.progressValue;
  const progress = parseInt(status.progress, 10);
  const statusType = status.statusType;
  const progressComparator = config.progressComparator;
  const progressStatuses = config.progressStatuses;

  let result = false;
  const equals = progress === requiredProgress && progressStatuses.some(status => status === statusType);

  if (progressComparator === 'equal') {
    result = equals;
  } else if (progressComparator === 'equalLarge') {
    result = progress > requiredProgress || equals;
  } else if (progressComparator === 'equalSmall') {
    result = progress < requiredProgress || equals;
  }

  return result;
};