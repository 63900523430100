/**
 * @fileOverview
 * @author elad
 */

define(['sandbox', 'AbstractAction', './ApproveRejectAll'], function (sandbox, AbstractAction, ApproveRejectAll) {
  'use strict';

  function getCommand(){
    return this.parameters.operation + this.parameters.partialtype;
  }

  return AbstractAction.extend({
    //TODO: uncomment this
    command: 'approveRejectAllContents',


    isApplicable: function (objs) {
      var objects = this.module.selected;
      if (typeof objects === 'undefined' || objects.length === 0) {
        return false;
      }

      for (var i=0;i<objects.length;i++){
        
        var isApprovalEnabled = objects[i].isApprovalEnabled;
        var applicable = false;
        if (isApprovalEnabled === null || typeof isApprovalEnabled === 'undefined' || isApprovalEnabled === "none"){
          applicable = false;
        } else if (this.parameters.partialType === "both" && isApprovalEnabled === "single"){
          applicable = true;
        } else if (this.parameters.partialType === "text" && (isApprovalEnabled === "both" ||
          isApprovalEnabled === "lateText")){
          applicable = true;
        } else if (this.parameters.partialType === "color" && (isApprovalEnabled === "both" ||
          isApprovalEnabled === "color")){
          applicable = true;
        } else if (this.parameters.partialType === "both" && (isApprovalEnabled === "both" ||
          isApprovalEnabled === "lateText" || isApprovalEnabled === "color")){
          applicable = true;
        }

        if (!applicable){
          return false;
        }
      }
      return true;
      
    },

    execute: function () {

      var objects = this.module.selected;
      if (typeof objects === 'undefined') {
        return;
      }

      if (objects.length === 0){
        return;
      }

      var items = [];
      for (var i=0;i<objects.length;i++){
        var obj = objects[i];
        items.push({nwid: obj.nwid, type: obj.type});
      }

      var actionArgs = [
        this.nwid,
        this.actionName,
        this.module.nwid,
        this.module.type,
        this.module.projectorId,
        "exec",
        {
          operation: this.parameters.operation,
          partialType: this.parameters.partialType,
          items: items
        }
      ];

      sandbox.request.execServerAction.apply(this, actionArgs);



      

    }
  });
});