import PropTypes from 'prop-types';
import React, { Component } from 'react';

const isUndefined = o => typeof o === 'undefined';
const INDICATOR_CLASS_NAME = 'crtx-colors-indicators';

export const BLACK_AND_WHITE = 'blackAndWhite';
export const COLORS = 'colors';

export default class ColorsIndicator extends Component {
  static propTypes = {
    colors: PropTypes.oneOf([BLACK_AND_WHITE, COLORS]).isRequired,
    name: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    onClick: PropTypes.func
  };

  static defaultProps = {
    colors: COLORS
  };

  renderBlackAndWhite = () => {
    return [
      <div key="crtx-indicate-black" className="crtx-indicate-black"></div>,
      <div key="crtx-indicate-white" className="crtx-indicate-white"></div>
    ];
  };

  renderColors = () => {
    return [
      <div key="crtx-indicate-black" className="crtx-indicate-black"></div>,
      <div key="crtx-indicate-magenta" className="crtx-indicate-magenta"></div>,
      <div key="crtx-indicate-yellow" className="crtx-indicate-yellow"></div>,
      <div key="crtx-indicate-cyan" className="crtx-indicate-cyan"></div>
    ];
  };

  render() {
    const { colors, name, style, className, onClick } = this.props;
    const indicatorClassName = `${INDICATOR_CLASS_NAME} ${colors === COLORS ? 'crtx-indicate-colors' : colors === BLACK_AND_WHITE ? 'crtx-indicate-black-and-white' : ''} ${className || ''}`

    return <div className={indicatorClassName} style={style} title={name} onClick={onClick}>
      {colors === COLORS ? this.renderColors() : colors === BLACK_AND_WHITE ? this.renderBlackAndWhite() : undefined}
    </div>;
  }
}