import AbstractModule from 'AbstractModule';
import React from 'react';
import { createRoot } from 'react-dom/client';
import sandbox from 'sandbox';
import PrintableHTML from '../../components/common/print/PrintableHTML';
import Dropdown from "components/common/dropdown/Dropdown";
import { createStore } from 'redux';
import reducer from './reducer';
import Loader from 'components/common/loader/Loader';
import * as actionTypes from './actionTypes';
import PrintProvider from '../../components/common/print/PrintProvider';
import PrintButton from '../../components/common/print/PrintButton';
import { restGet } from 'core/managers/rest2';

const isFunction = o => typeof o === 'function';

export default AbstractModule.extend({

  init: function () {
    this._super();
    for (var prop in this) {
      if (prop !== '_super' && isFunction(this[prop])) this[prop] = this[prop].bind(this);
    }

  },

  firstTickReceived: function (data) {
    this.report = data.config.report;
    this.store = createStore(reducer, { loading: true, selectedValue: '', report: this.report, htmlToPrint: '' });
    this.store.subscribe(this.render);
    this.reactRoot = createRoot(this.domElement);

    restGet(this.nwid, `simple-reports/selectors/${this.report}`)
      .then(res => this.store.dispatch({ type: actionTypes.INITIAL_LOAD_SUCCESS, resData: res }));

    this.render();
  },

  //Must be here so firstTickReceived will work
  tickUpdate: function (data) {
  },

  handleSelect: function (event, value, index) {
    const state = this.store.getState();
    if (state.selectedValue !== value) {
      this.store.dispatch({ type: actionTypes.ZONE_SELECT, value });

      restGet(this.nwid, `simple-reports/data/${this.report}/${value}`)
        .then(res => this.store.dispatch({ type: actionTypes.LOAD_TEMPLATE_ON_SELECT, resData: res }));
    }
  },

  render: function () {
    const state = this.store.getState();
    this.reactRoot.render(
      state.loading ? <Loader loading={state.loading} /> : (
        <div className='crtx-reporting-view'>
          <PrintProvider className='crtx-reporting-view-printable-html'>
            <div className='crtx-reporting-view-zones'>
              <h2>{`${state.title}:`}</h2>
              {state.inputType === 'flat-product' &&
                <Dropdown options={state.selectionOptions} value={state.selectedValue} onSelect={this.handleSelect} className='crtx-reporting-view-zones-dropdown' />}
              <PrintButton className='crtx-printable-component-print-button' disabled={state.htmlToPrint === ''} />
            </div>
            <hr className='crtx-reporting-view-separator' />
            <PrintableHTML htmlToPrint={state.htmlToPrint} />
          </PrintProvider>
        </div>));
  }
});
