/**
 * @name
 * @fileOverview
 * @author sergey & Elad
 */
define(['ember'],
  function (Ember) {
    'use strict';


    function updateCurrentValue (){
      var currentValue;
      currentValue = this.get("model." + (this.get('key')));

      if (currentValue !== void 0 && currentValue !== null) {
        return this.set('value', this.numeric && isNaN(currentValue) ? currentValue.replace(/[^\d.]/g, "") : currentValue);
      }
    }

    return Ember.TextField.extend({
      //placeholderBinding: this.get('key'),

      _setRemoteValue: (function() {
        var val;
        val = this.get('value');
        if (val !== null) {
          return this.set("model." + (this.get('key')), val);
        }
      }).observes('value'),
      didInsertElement: function() {
        updateCurrentValue.call(this);
        return this.addObserver("model." + (this.get('key')), updateCurrentValue.bind(this));
      }
    });
  });