/**
 * @name propertiesCommon
 * @file Defines common functionality for element properties
 *
 * @author Boris
 * @since: 2016-06-29
 */

import React from 'react';
import sandbox from 'sandbox';
import SimpleForm from 'widgets/SimpleForm/src/index';
import CellMatrix from 'components/private/dropdowns/CellMatrix';
import Orientation from 'components/private/dropdowns/Orientation';
import ColorPicker from 'components/common/dropdown/ColorPicker';
import utils from '../utils/utils';
import actions from '../redux/actions';

const {translate} = sandbox.localization;
const {Row, Label, Input, Textarea, MeasurementInput, NumericInput, Dropdown, Checkbox, PercentageInput} = SimpleForm;
const futils = SimpleForm.utils;

const CAPTION_COLUMNS = 6;
const TEXTBOX_COLUMNS = 6;
const DROPDOWN_COLUMNS = 6;
const NUMBER_COLUMNS = 3;
const CHECKBOX_COLUMNS = 1;
const UNITS_COLUMNS = 2;
const UNITS_MARGIN_LEFT = '3px';

const DEFAULTS_BY_TYPE = {
  string: '',
  text: '',
  length: 0,
  bool: false,
  percent: 1,
  int: 0,
  float: 0,
  cellMatrix: -1
};

const NAME = {
  key: 'name',
  caption: translate('Name'),
  type: 'string'
};

const DISPLAY_NAME = {
  key: 'name',
  caption: translate('Display name'),
  type: 'string'
};

const X = {
  key: 'x',
  caption: translate('X'),
  type: 'length'
};

const Y = {
  key: 'y',
  caption: translate('Y'),
  type: 'length'
};

const WIDTH = {
  key: 'width',
  caption: translate('Width'),
  type: 'length',
  defaultValue: 2
};

const HEIGHT = {
  key: 'height',
  caption: translate('Height'),
  type: 'length',
  defaultValue: 1
};

const REFERENCE_X = {
  key: 'referenceX',
  caption: translate('Plate side'),
  type: 'options',
  options: [
    {value: 'left', text: translate('left')},
    {value: 'right', text: translate('right')},
  ]
};

const REFERENCE_Y = {
  key: 'referenceY',
  caption: translate('Plate side'),
  type: 'options',
  options: [
    {value: 'top', text: translate('top')},
    {value: 'bottom', text: translate('bottom')}
  ]
};

const _CORNER = {
  key: '_corner',
  caption: translate('Plate corner'),
  type: 'options',
  options: [
    {value: 'top-left', text: translate('top-left')},
    {value: 'top-right', text: translate('top-right')},
    {value: 'bottom-left', text: translate('bottom-left')},
    {value: 'bottom-right', text: translate('bottom-right')}
  ]
};

const _ALIGNMENT = {
  key: '_alignment',
  caption: translate('Align'),
  type: 'options',
  options: [
    {value: 'top-left', text: translate('top-left')},
    {value: 'top-center', text: translate('top-center')},
    {value: 'top-right', text: translate('top-right')},
    {value: 'center-left', text: translate('center-left')},
    {value: 'center-center', text: translate('center-center')},
    {value: 'center-right', text: translate('center-right')},
    {value: 'bottom-left', text: translate('bottom-left')},
    {value: 'bottom-center', text: translate('bottom-center')},
    {value: 'bottom-right', text: translate('bottom-right')}
  ]
};

const ROTATION = {
  key: 'rotation',
  caption: 'Rotate',
  type: 'options',
  options: [
    {value: 0, text: '0\u00B0'},
    {value: 90, text: '90\u00B0'},
    {value: 180, text: '180\u00B0'},
    {value: 270, text: '270\u00B0'}
  ],
  col: NUMBER_COLUMNS
};

const APPLY_ON = {
  key: 'applyOn',
  caption: translate('Apply on'),
  type: 'options',
  options: [
    {value: 'process', text: 'Process'},
    {value: 'output', text: 'Output'}
  ]
};

const BLACK_ONLY = {
  key: 'blackOnly',
  caption: translate('Black only'),
  type: 'bool'
};


const WHITE_TEXT = {
  key: 'whiteText',
  caption: translate('White Text'),
  type: 'bool'
};


const FANOUT_AS_CELL = {
  key: 'fanoutAsCell',
  caption: translate('Fanout as Cell'),
  type: 'cellMatrix'
};

const getCellGridSize = (state) => {
  var cellGrid = state.layout.cellGrid;
  var rows = !cellGrid ? 0 : cellGrid.rows;
  var columns = !cellGrid ? 0 : cellGrid.columns;
  return {rows, columns};
};

const getDefaultValue = (propMeta) => {
  let result;
  if (typeof propMeta.defaultValue !== 'undefined') {
    result = propMeta.defaultValue;
  } else if (propMeta.type === 'options' && propMeta.options.length > 0) {
    const firstOption = propMeta.options[0];
    result = typeof firstOption.value !== 'undefined' ? firstOption.value : firstOption;
  } else if (propMeta.type === 'array') {
    result = utils.createPseudoArray();
  } else {
    result = DEFAULTS_BY_TYPE[propMeta.type];
  }

  return result;
};

const setDefaultAlignment = (element, defaultValue) => {
  var align = utils.composePosition(element.alignmentX, element.alignmentY);
  if (typeof align === 'undefined') {
    element.alignmentX = utils.getPositionX(defaultValue);
    element.alignmentY = utils.getPositionY(defaultValue);
    align = defaultValue;
  }
  element[_ALIGNMENT.key] = align;
};

const setDefaultCorner = (element, defaultValue) => {
  var corner = utils.composePosition(element.referenceX, element.referenceY);
  if (typeof corner === 'undefined') {
    element.referenceX = utils.getPositionX(defaultValue);
    element.referenceY = utils.getPositionY(defaultValue);
    corner = defaultValue;
  }
  element[_CORNER.key] = corner;
};

const setAlignmentProperty = (state, element, alignment) => {
  var alignmentX = utils.getPositionX(alignment);
  var alignmentY = utils.getPositionY(alignment);
  return {...element, _alignment: alignment, alignmentX, alignmentY};
};

const handleSelectCellMatrix = (store, path, value) => {
  store.dispatch(actions.update(path, value));
};

const handleSelectOrientation = (store, path, value) => {
  store.dispatch(actions.update(path, value));
};

const handleSelectColor = (store, path, value) => {
  store.dispatch(actions.update(path, value));
};

const setReferenceXProperty = (state, element, referenceX) => {
  const p = utils.calcElementNewXY(state.plateRectangle, element, referenceX, element.referenceY);

  return {...element, x: p.x, y: p.y, referenceX};
};

const setReferenceYProperty = (state, element, referenceY) => {
  const p = utils.calcElementNewXY(state.plateRectangle, element, element.referenceX, referenceY);

  return {...element, x: p.x, y: p.y, referenceY};
};

const setCornerProperty = (state, element, corner) => {
  const referenceX = utils.getPositionX(corner);
  const referenceY = utils.getPositionY(corner);
  const p = utils.calcElementNewXY(state.plateRectangle, element, referenceX, referenceY);

  return {...element, _corner: corner, x: p.x, y: p.y, referenceX, referenceY};
};

const isPropertyDisabled = (element, meta) => {
  return meta.alwaysEnabled ? false : element.locked || meta.disabled;
};

const renderProperty = (store, element, elementPath, meta) => {
  if (meta.hidden) {
    return null;
  }

  const state = store.getState();
  const disabled = isPropertyDisabled(element, meta);
  const path = futils.compose(elementPath, meta.key);

  switch (meta.type) {
    case 'length':
      const units = utils.getUserUnits();
      const unitsSymbol = utils.getUserUnitsSymbol();

      return (
        <Row key={meta.key}>
          <Label col={CAPTION_COLUMNS}>{meta.caption}</Label>
          <MeasurementInput col={NUMBER_COLUMNS} units={units} mask={meta.mask} disabled={disabled} bind={path}
                            title={meta.tooltip}/>
          <Label col={UNITS_COLUMNS} style={{marginLeft: UNITS_MARGIN_LEFT}}>{unitsSymbol}</Label>
        </Row>
      );
    case 'float':
      return (
        <Row key={meta.key}>
          <Label col={CAPTION_COLUMNS}>{meta.caption}</Label>
          <NumericInput col={NUMBER_COLUMNS} valueType="float" mask={meta.mask} disabled={disabled} bind={path}
                        title={meta.tooltip}/>
          {(typeof meta.suffix !== 'undefined') ?
            <Label col={UNITS_COLUMNS} style={{marginLeft: UNITS_MARGIN_LEFT}}>{meta.suffix}</Label> : undefined}
        </Row>
      );
    case 'int':
      return (
        <Row key={meta.key}>
          <Label col={CAPTION_COLUMNS}>{meta.caption}</Label>
          <NumericInput col={NUMBER_COLUMNS} valueType="int" mask={meta.mask} disabled={disabled}
                        bind={path} title={meta.tooltip}/>
        </Row>
      );
    case 'string':
      return (
        <Row key={meta.key}>
          <Label col={CAPTION_COLUMNS}>{meta.caption}</Label>
          <Input col={TEXTBOX_COLUMNS} disabled={disabled} bind={path} title={meta.tooltip}/>
        </Row>
      );
    case 'text':
      return (
        <Row key={meta.key}>
          <Label col={CAPTION_COLUMNS}>{meta.caption}</Label>
          <Textarea col={TEXTBOX_COLUMNS} disabled={disabled} ignoreNewLine={true} bind={path} title={meta.tooltip}/>
        </Row>
      );
    case 'options':
      return (
        <Row key={meta.key}>
          <Label col={CAPTION_COLUMNS}>{meta.caption}</Label>
          <Dropdown col={meta.col || DROPDOWN_COLUMNS} disabled={disabled} bind={path} options={meta.options}
                    title={meta.tooltip}/>
        </Row>
      );
    case 'cellMatrix':
      var size = getCellGridSize(state);
      return (
        <Row key={meta.key}>
          <Label col={CAPTION_COLUMNS}>{meta.caption}</Label>
          <div className={'col col-' + DROPDOWN_COLUMNS}>
            <CellMatrix disabled={disabled} value={element[meta.key]} rows={size.rows} columns={size.columns}
                        onSelect={(value) => handleSelectCellMatrix(store, path, value)}
                        title={meta.tooltip}/>
          </div>
        </Row>
      );
    case 'orientation':
      return (
        <Row key={meta.key}>
          <Label col={CAPTION_COLUMNS}>{meta.caption}</Label>
          <Orientation disabled={disabled} value={element[meta.key]}
                       onSelect={(value) => handleSelectOrientation(store, path, value)}
                       title={meta.tooltip}/>
        </Row>
      );
    case 'bool':
      return (
        <Row key={meta.key}>
          <Label col={CAPTION_COLUMNS}>{meta.caption}</Label>
          <Checkbox col={CHECKBOX_COLUMNS} disabled={disabled} bind={path} title={meta.tooltip}/>
        </Row>
      );
    case 'percent':
      return (
        <Row key={meta.key}>
          <Label col={CAPTION_COLUMNS}>{meta.caption}</Label>
          <PercentageInput col={NUMBER_COLUMNS} disabled={disabled} bind={path} title={meta.tooltip}/>
          <Label col={UNITS_COLUMNS} style={{marginLeft: UNITS_MARGIN_LEFT}}>%</Label>
        </Row>
      );
    case 'color':
      return (
        <Row key={meta.key}>
          <Label col={CAPTION_COLUMNS}>{meta.caption}</Label>
          <ColorPicker disabled={disabled} value={element[meta.key]}
                       onChange={(value) => handleSelectColor(store, path, value)}
                       title={meta.tooltip}/>
        </Row>
      );
  }
};

const renderProperties = (store, element, elementPath, elementMeta) => {
  return (
    <div>
      {elementMeta.map((meta, index) => renderProperty(store, element, elementPath, meta))}
    </div>
  );
};

const setDefaultValues = (state, element, elementMeta) => {
  for (var i = 0; i < elementMeta.length; i++) {
    var propMeta = elementMeta[i];
    var key = propMeta.key;
    if (key === _ALIGNMENT.key) {
      setDefaultAlignment(element, getDefaultValue(propMeta));
    } else if (key === _CORNER.key) {
      setDefaultCorner(element, getDefaultValue(propMeta));
    } else if (typeof element[key] === 'undefined') {
      element[key] = getDefaultValue(propMeta);
    }
  }

  return element;
};

const updateProperty = (state, element, elementPath, propertyPath, propertyValue) => {
  var newState = state;
  var newElement = element;
  var key = futils.pathTail(propertyPath);
  if (key === _ALIGNMENT.key) {
    newElement = setAlignmentProperty(newState, newElement, propertyValue);
  } else if (key === _CORNER.key) {
    newElement = setCornerProperty(newState, newElement, propertyValue);
  } else if (key === REFERENCE_X.key) {
    newElement = setReferenceXProperty(newState, newElement, propertyValue);
  } else if (key === REFERENCE_Y.key) {
    newElement = setReferenceYProperty(newState, newElement, propertyValue);
  } else {
    newState = futils.update(newState, propertyPath, propertyValue);
  }

  if (newElement !== element) {
    newState = futils.update(newState, elementPath, newElement);
  }

  return newState;
};

const nudgeElement = (state, element, direction) => {
  var step = utils.calcNudge(direction);
  return {...element, x: element.x + step.x, y: element.y + step.y};
};

export default {
  CAPTION_COLUMNS,
  TEXTBOX_COLUMNS,
  DROPDOWN_COLUMNS,
  NUMBER_COLUMNS,
  CHECKBOX_COLUMNS,
  UNITS_COLUMNS,
  UNITS_MARGIN_LEFT,
  NAME,
  DISPLAY_NAME,
  X,
  Y,
  WIDTH,
  HEIGHT,
  REFERENCE_X,
  REFERENCE_Y,
  _CORNER,
  _ALIGNMENT,
  ROTATION,
  APPLY_ON,
  BLACK_ONLY,
  WHITE_TEXT,
  FANOUT_AS_CELL,
  isPropertyDisabled,
  renderProperty,
  renderProperties,
  setDefaultValues,
  updateProperty,
  nudgeElement
};
