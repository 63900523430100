/**
 * @name
 * @fileOverview
 * @author sergey & Elad
 */
define(['ember', 'text!../templates/zone.hbs', 'text!../templates/section.hbs', './DynamicTextField', './DynamicCheckBox', './DynamicSelect'],
  function (Ember, MainTemplate, sectionTemplate, DynamicText, DynamicCheckbox, DynamicSelect) {
    'use strict';

		var DragAndDrop = Ember.View.extend({
			tagName: 'li',
			classNames: ['pw-row', 'section'],
			classNameBindings: ['isDragOver:drag-over'],
			isDragOver: false,
			dragOver : function(e){
				if (this.controller && this.controller.selectedZone && this.controller.selectedZone.wrapped){					
					return;
				}
				e.preventDefault();
				e.stopPropagation();
				this.set('isDragOver', true);
			},
			dragEnter: function(e){
				if (this.controller && this.controller.selectedZone && this.controller.selectedZone.wrapped){					
					return;
				}
				this.set('isDragOver', true);
			},
			dragLeave: function(e){
				if (this.controller && this.controller.selectedZone && this.controller.selectedZone.wrapped){					
					return;
				}
				e.preventDefault();
				e.stopPropagation();
				this.set('isDragOver', false);
			},
			dragStart: function(e){
				if (this.controller && this.controller.selectedZone && this.controller.selectedZone.wrapped){					
					return;
				}
				e.dataTransfer.setData("Text", this.elementId);
			},
			drop: function(e){
				if (this.controller && this.controller.selectedZone && this.controller.selectedZone.wrapped){					
					return;
				}
				e.preventDefault();
				e.stopPropagation();
				this.set('isDragOver', false);
				var droppedId = e.dataTransfer.getData("Text");
				var parent = this.get('parentView');
				parent.changeSectionsOrder(droppedId, this.elementId);
			//	this.controller.calculatePagination(this.model);
			//	Ember.set(this.controller.get("model.publication"), "pagesFlag", !Ember.get(this.controller.get("model.publication"), "pagesFlag"));
			}
		});
		var selectTemplate =
			'{view "select"' +
			'content=programmers ' +
			'optionValuePath="content.id" ' +
			'optionLabelPath="content.firstName" ' +
			'value=currentProgrammer.id}}'

			;
		var headerTemplate =
				'<div class="column">&nbsp;</div>' +
				'<div class="column">{{t "Name"}}</div>' +
				'<div class="column">{{t "Code"}}</div>' +
				'{{#each header in headers}}' +
					'<div class="column">{{header.label}}</div>' +
        '{{/each}}';


		var HeaderView = DragAndDrop.extend({
			classNames: ['header'],
			layout: Ember.Handlebars.compile(headerTemplate),
			stam: function (){

			}.property()
		});

		function getInput(colDescriptor, controller, section){
			var selectAsText = false;
			if (section && section.wrapped && colDescriptor.type === "select"){
				selectAsText = true;
			}
			// console.log (colDescriptor.name);
			var disabledStyle = colDescriptor["style"] + ";background: #f8f8f9;color: #aaaaaa;border: 1px solid #81929d";
			if (colDescriptor.type === "text" || colDescriptor.type === "numeric" || selectAsText){

				var dsbl = section && section.wrapped;
				var readonly = colDescriptor['readonly'] || section && section.wrapped;
				if (colDescriptor.name === 'startPage'){
					if (section && section.sourceSection && section.wrapped){
						dsbl = false;
						readonly = false;
					}
				} 

				return DynamicText.create({
					controller: controller,
					attributeBindings: ['style', 'sourceSection', 'wrapped'],
					numeric: (colDescriptor.type === "numeric"),
					model: section,
					key: colDescriptor.name,
					classNames: ['k-textbox'],
					style: dsbl && colDescriptor["name"] !== "pagination" ? disabledStyle : colDescriptor['style'],
					disabled: dsbl,
          			readonly: readonly
				});
			} else if (colDescriptor.type === "checkbox"){
				var dsbl = section && section.wrapped && colDescriptor.name !== 'skip' && 
						colDescriptor.name !== 'wrapped';
				var readonly = colDescriptor['readonly'] || section && section.wrapped;
				if (colDescriptor.name === 'split' || colDescriptor.name === 'skip'){
					if (section && section.sourceSection){
						dsbl = true;
					}
				} 
				
				return DynamicCheckbox.create({
					controller: controller,
					model: section,
					disabled: dsbl,
					key: colDescriptor.name
				});
			} else if (colDescriptor.type === "select") {
				var dsbl = section && section.wrapped;
				var readonly = colDescriptor['readonly'] || section && section.wrapped;

				//return Ember.Select.create({
				//	content: colDescriptor.options,
				//	optionLabelPath: 'content.label',
				//	optionValuePath: 'content.name',
				//	//value: section[colDescriptor.name],
				//	selected: section[colDescriptor.name],
				//	model: section,
				//	onchange: 'alert(2)'
        //
				//});
				//section[colDescriptor.name] = section[colDescriptor.name] || colDescriptor.options[0].name;
				return DynamicSelect.create({
					content: colDescriptor.options,
					attributeBindings: ['disabled'],
					optionLabelPath: 'content.label',
					optionValuePath: 'content.name',
					controller: controller,
					model: section,
					key: colDescriptor.name,
					value: section[colDescriptor.name],
					disabled: dsbl,
          			readonly: readonly
					//value: section[colDescriptor.name],


				});
			}
		}

		var InputView = Ember.ContainerView.extend({
			init: function() {
				this._super();
				var colDescriptor = this.get('colDescriptor');
				var controller = this.get('controller');
				var section = this.get('model');
				var inp = getInput(colDescriptor, controller, section);
				console.log (InputView + ":" + section.sourceId);
				this.pushObject(
					inp
				);
   }});

		var SectionView = DragAndDrop.extend({
			attributeBindings: ['draggable'],
			draggable: true,
			inputView: InputView,
			template: Ember.Handlebars.compile(sectionTemplate),

			numPagesObserver: function() {
				this.controller.propertyChangedListener(this.model, "numPages");
			}.observes('model.numPages'),

			nameModeAsText: function (){
				 return this.controller.settings.nameMode === "text" || this.model.wrapped;
			}.property('model.wrapped'),
			isSplit: function () {
				return false;
			}.property(),
			displayNameObserver: function() {
				this.controller.propertyChangedListener(this.model, "displayName");
			}.observes('model.displayName'),

			splitObserver: function() {
				this.controller.propertyChangedListener(this.model, "split");
			}.observes('model.split'),

			startPageObserver: function() {
				this.controller.propertyChangedListener(this.model, "startPage");
			}.observes('model.startPage'),

			enabledStyle: function (){
				return this.model.wrapped ? "" : "cursor:pointer;";
			  }.property("this.model.wrapped"),

		});


		var wrappedCheckBoxView = Ember.ContainerView.extend({

			classNames: ["checkBox-container"],
	  
			init: function() {
			  this._super();			  
			  var controller = this.get('controller');
			  var zone = this.get('model');
			  var inp = getInput({type:"checkbox", name:"wrapped"}, controller, zone);
			  this.pushObject(
				inp
			  );
			}});
	  

		return Ember.View.extend({
	  template: Ember.Handlebars.compile(MainTemplate),
	  
	  wrappedCheckBoxView: wrappedCheckBoxView,

	  wrappedState: false,

	  wrappedCheckBoxViewObserver: function() {
			 this.wrappedState = this.model.wrapped;
			 if (this.model.applyAllAction){
			 	this.model.applyAllAction = false;
			 	return;
			 }			 
			 if (this.model.wrapped){
				this.controller.unWrap();
			 }
			this.controller.wrapAction(this.model);
		}.observes('this.model.wrapped'),

      headerView: HeaderView,
			sectionView: SectionView,

	  enabledStyle: function (){
		return this.model.wrapped ? "" : "cursor: pointer";
	  }.property("this.model.wrapped"),

      changeSectionsOrder: function(dragged, to){
        if (dragged === to){
          return;
        }
        var draggedModel = Ember.View.views[dragged].get('model');
				var sections = this.get('model.sections');
				sections.removeObject(draggedModel);

				var arr = this.$().find('li.section');
				for (var i = 0, leni = arr.length; i < leni; i++){
					if (arr[i].id === to){
						sections.insertAt(i, draggedModel);
						break;
					}
				}
        this.controller.propertyChangedListener(this.model, "order");
      }
    });
  });