
import React from 'react';
import { createRoot } from 'react-dom/client';
import { translate } from 'core/services/localization';
import AbstractAction from 'AbstractAction';
import { Dialog, DialogButtonsBar } from 'components/common/dialogs';
import Button from 'components/common/buttons/Button';
import List from 'components/common/list';

function View({ holders, onClose }) {

  const renderHolder = (holder, index) => {
    return <div key={index}>{holder}</div>;
  };

  return (
    <Dialog
      onClose={onClose}
      title={translate('Event Holders')}
      autoFocus={false}
      initialWidth={300}
      initialHeight={300}
      modal={true}>
      <div className='crtx-form-section-container'>
        <List className='crtx-list-plain'
          items={holders}
          renderItem={renderHolder}
        />
      </div>
      <DialogButtonsBar>
        <Button className={'primary'} onClick={onClose}>{translate('OK')}</Button>
      </DialogButtonsBar>
    </Dialog>);
}

module.exports = AbstractAction.extend({

  isApplicable: function (events) {
    if (typeof events === 'undefined' || events.length !== 1) { return false; }
    return (events[0].type === 'workflow/event' && typeof events[0].holders !== "undefined" && events[0].holders.length > 0);
  },

  execute: function (events) {
    if (events && events.length > 0) {
      const holders = events[0].holders;

      const domElement = document.createElement('div');
      this.module.domElement.appendChild(domElement);
      this.reactRoot = createRoot(domElement);

      const handleClose = () => {
        this.reactRoot.unmount();
        domElement.remove();
      };
      this.reactRoot.render(<View holders={holders} module={this.module} onClose={handleClose} />);
    }
  }
});
