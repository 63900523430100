import {localization} from 'sandbox';
import {icons} from 'sandbox';

const {translate} = localization;

export const MAX_TRACE_LENGTH = 3;
export const ADJUSTED_PROGRESS_FACTOR = 2.0;

export const EVENTS_ORDER = ['queError', 'queWait', 'queHold', 'queFinished'];
export const EVENT_COUNTS_ORDER = ['queFinished', 'queError', 'queHold', 'queWait'];

export const LABEL_BY_QUEUE_TYPE = {
  queError: translate('Error'),
  queWait: translate('Wait'),
  queHold: translate('Hold'),
  queFinished: translate('Finished'),
  queProcess: translate('In Progress'),
};

export const STATUS_BY_QUEUE_TYPE = {
  queError: 'error',
  queWait: 'wait',
  queHold: 'hold',
  queFinished: 'finished',
  queProcess: 'process',
};

export const ALIGN_BY_COLUMN_KEY = {
  objectType: 'center',
  state: 'center',
  Duration: 'right',
};

export const DATA_TYPE_BY_COLUMN_KEY = {
  objectType: 'icon',
  state: 'icon',
  'Publication Date': 'shortDate',
  'EventTime': 'shortDateTime'
};

export const FILTER_TYPE_BY_COLUMN_KEY = {
  Name: 'text',
  FileName: 'text',
  OutFileName: 'text',
  EventTime: 'date',
};

export const STATE_OPTIONS = [
  {
    value: 'queError',
    data: {icon: icons.getGeneralIcon('status', STATUS_BY_QUEUE_TYPE.queError), title: LABEL_BY_QUEUE_TYPE.queError}
  },
  {
    value: 'queWait',
    data: {icon: icons.getGeneralIcon('status', STATUS_BY_QUEUE_TYPE.queWait), title: LABEL_BY_QUEUE_TYPE.queWait}
  },
  {
    value: 'queHold',
    data: {icon: icons.getGeneralIcon('status', STATUS_BY_QUEUE_TYPE.queHold), title: LABEL_BY_QUEUE_TYPE.queHold}
  },
  {
    value: 'queFinished',
    data: {
      icon: icons.getGeneralIcon('status', STATUS_BY_QUEUE_TYPE.queFinished),
      title: LABEL_BY_QUEUE_TYPE.queFinished
    }
  },
];


