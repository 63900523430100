/**
 * Created by elad on 1/26/2017.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormSetup from 'setups/FormSetup';
import Radio from 'components/common/inputs/Radio';
import Header from 'components/common/headers/Header';
import {translate} from 'sandbox/localization';

class FolderPropertySetup extends Component {

  static propTypes = {
    model: PropTypes.any,
    apply: PropTypes.func
  };

  static onDataReceived(model) {
    return {
      ...model,
      availableFolderProperties: model.availableFolderProperties || ['aaaa', 'bbbb'],
      selectedProperty: model.availableFolderProperties && model.availableFolderProperties.length > 0 ? model.availableFolderProperties[0] : ''
    };
  }

  static onApply(model) {
    return model;
  }

  constructor(props) {
    super(props);
    this.handleCheckboxChecked = this.handleCheckboxChecked.bind(this);
    this.renderAvailableFolderProperties = this.renderAvailableFolderProperties.bind(this);
  }

  handleCheckboxChecked(item) {
    return (ev) => {
      this.props.model.selectedProperty = item;
    };
  }

  renderAvailableFolderProperties(availableFolderProperties, selectedProperty) {
    return availableFolderProperties.map((item, index) => {
      return <div key={index} className="property">
        <label>
          <Radio name="AvailableFolderProperties" checked={item === selectedProperty} value={item} onChange={this.handleCheckboxChecked(item)} />
          {item}
        </label>
      </div>;
    });
  }

  render() {
    const model = this.props.model;
    return <div className="crtx-folder-property-setup">
      <Header>{translate('Folder Property')}</Header>
      {this.renderAvailableFolderProperties(model.availableFolderProperties, model.selectedProperty)}
    </div>;
  }
}

export default FormSetup(FolderPropertySetup);