import { restGet } from 'core/managers/rest2';
import { translate } from 'core/services/localization';
import settingsManager from 'core/managers/settings';
import { composeComparators, createObjectComparator, COMPARE_TYPE } from 'core/comparators';

const DEFAULT_FILTERS = [
  {
    name: 'colored',
    displayName: translate('Color pages only'),
    active: false
  },
  {
    name: 'content',
    displayName: translate('Skip pages without content'),
    active: false
  }];

export const getItemFilterData = item => {
  const filterData = {};
  if (item.content) {
    filterData.content = item.content.versionNwidJpg !== '000' || item.content.versionNwidPng !== '000';
  }
  if (item.separations) {
    filterData.colored = item.separations.length > 1;
  }
  return filterData;
};
export const createNavigatorFilter = (preferencesFilters = [], item) => {
  if (typeof item === 'undefined' || typeof item.filterData === 'undefined') {
    return [];
  }
  const navigatorFilter = [];
  for (let index = 0; index < DEFAULT_FILTERS.length; index++) {
    const filter = DEFAULT_FILTERS[index];
    if (typeof item.filterData[filter.name] !== 'undefined') {
      const prefFilter = preferencesFilters.find(pf => pf.name === filter.name);
      const active = prefFilter ? prefFilter.active : false;
      navigatorFilter.push({ ...filter, active });
    }
  }
  return navigatorFilter;
};

export const findNewSelectedIndex = (navigatorItems, rootNwid) => {
  let selectedIndex = -1;
  if (navigatorItems !== undefined && navigatorItems !== null) {
    for (var i = 0; i < navigatorItems.length; i++) {
      if (rootNwid === navigatorItems[i].nwid) {
        selectedIndex = i;
        break;
      }
    }
  }
  return selectedIndex;
};

const removeVirtualPages = (items, viewNwid) => {
  const navigatorItems = [];
  items.forEach((item, index, object) => {
    item.viewNwid = viewNwid; //get the id of the view, in Navigator use this to find the viewLink
    if (!item.isVirtualPage) {
      item.filterData = getItemFilterData(item);
      navigatorItems.push(item);
    }
  });

  return navigatorItems;
};

const setNavigatorControls = (module, model, navigatorData) => {
  module.navigator.setControls(navigatorData);
  const rootNwid = model.formNwId || model.pageNwId || model.nwid;
  const selectedIndex = findNewSelectedIndex(navigatorData.items, rootNwid);

  if (selectedIndex >= 0) {
    module.navigator.setSelectedIndex(selectedIndex);
  }
};

export const retriveAndSetEditionNavigatorItems = (module, model, parentRoot) => {


  const editionNwid = parentRoot.nwid;

  getEditionNavigatorItems(model.type, module.nwid, editionNwid)
    .then(result => {
      const { pages, forms } = result;

      const sortBy = pages ? 'section' : 'book';

      let navigatorItems = pages ?? forms ?? [];

      navigatorItems = navigatorItems.map(item => {
        return {
          ...item,
          viewLinks: [module.nwid],
          viewNwid: module.nwid,
          parentIndex: item.sectionIndex ?? item.bookIndex,
          parentName: item.sectionName ?? item.bookName,
          numberInParent: item.numberInSection ?? item.numberInBook,
          label: item.name
        };
      });

      const comparator = composeComparators(
        [
          settingsManager.get('productSorting')[sortBy] === 'byPlan' ?
            createObjectComparator('parentIndex', COMPARE_TYPE.NUMBER) : createObjectComparator('parentName'),
          createObjectComparator('numberInParent'),
          createObjectComparator('variantName')
        ]
      );

      navigatorItems.sort(comparator);

      for (let i = 0; i < navigatorItems.length - 1; i++) {
        let item = navigatorItems[i];
        let nextItem = navigatorItems[i + 1];

        if (item.parentName === nextItem.parentName && item.numberInParent === nextItem.numberInParent) {
          item.label = item.variantName;
          nextItem.label = nextItem.variantName;
        }
      }

      const navigatorData = {
        items: navigatorItems,
        filters: []
      };
      setNavigatorControls(module, model, navigatorData);
    });

};

export const retriveAndSetSectionNavigatorItems = (module, navigatorFilters, model, allSections, currentSectionName) => {

  const navigatorItems = getSectionNavigatorItems(model.section.zone.sections, module.nwid, allSections, currentSectionName);
  const filters = createNavigatorFilter(navigatorFilters, navigatorItems[0]);

  const navigatorData = {
    items: navigatorItems,
    filters
  };

  setNavigatorControls(module, model, navigatorData);
};

const getSectionNavigatorItems = (sections, viewNwid, allSections, currentSectionName) => {
  let navigatorItems = [];

  sections.forEach(s => {
    if ((!allSections && s.name === currentSectionName) || (allSections)) {
      let items = [];
      items.push(...s.neighborItems);
      items.sort((a, b) => {
        if ((a.hasOwnProperty('splitAb') && b.hasOwnProperty('splitAb')) && (a.index === b.index)) {
          return a.splitAb.toLowerCase() < b.splitAb.toLowerCase() ? -1 : 1;
        }
        return (a.index - b.index);
      });
      navigatorItems = navigatorItems.concat(removeVirtualPages(items, viewNwid));
    }
  });

  return navigatorItems;
};

const getEditionNavigatorItems = async (rootType, viewNwid, editionNwid) => {
  let result = [];
  if (rootType === 'page') {
    result = restGet(viewNwid, `coretex-objects/edition/${editionNwid}/significant-pages/${viewNwid}`);

  } else if (rootType === 'form') {
    result = restGet(viewNwid, `coretex-objects/edition/${editionNwid}/significant-forms/${viewNwid}`);
  }

  return result;
};

