import React from 'react';
import { createRoot } from 'react-dom/client';
import AbstractModule from 'AbstractModule';
import TickableModel from '../TickableModel';
import { arrayToObject, moveItem } from 'utilities/array';
import View from './View';
import { savePreferences } from 'core/managers/preferences';
import { fromServerDate, toServerDate } from 'core/dates';

import jsutils from 'base/jsUtils';
import { makeUsersTableColumns } from './columnsDefinition';
import { createObjectComparator, composeComparators } from 'core/comparators';
import {
  checkDateCondition,
  checkTextCondition,
  extractColumnPreferences,
  reduceColumnsToFilterBy,
  FILTER_DATA_TYPE,
  FILTER_TYPE,
  reduceColumnsToSortBy,
  applyColumnSortPreferences,
  extractColumnSortPreferences
} from 'widgets/ReactDataGrid/utils';

import { translate } from 'core/services/localization';

const THROTTLE_WAIT = 1000;

const ACTIONS_TO_ORDER_MAP = {
  AddFolderProfileActionCR: 1,
  EditFolderProfileActionCR: 2,
  DeleteFolderProfileActionCR: 3,
  GenerateFolderTokenActionCR: 4,
  RemoveFolderTokenActionCR: 5,
  PublishFolderUsersInfoActionCR: 6,
};

const DEFAULT_SORT = [
  {
    ascending: true,
    key: 'name'
  }
];

const AUTHENTICATION_METHODS = {
  'BuiltIn': translate('Internal'),
  'OPENID': translate('OpenID'),
  'NTLM': translate('NTLM (Domain)')
};

const isFunction = o => typeof o === 'function';

const itemCreator = (user) => {
  const comparator = composeComparators([
    createObjectComparator('folderName'),
    createObjectComparator('name')
  ]);
  const sortedItems = (user.parents || []).sort(comparator);

  return {
    ...user,
    securityGroups: sortedItems.map(user => user.folderName + '\\' + user.name).join(', '),
  };
};

export default AbstractModule.extend({

  initDone: function () {
    if (this.startParameters.rootType === 'folder') {
      this.viewActions.forEach(va => {
        const actionOrder = ACTIONS_TO_ORDER_MAP[va.actionDefinitionName];
        if (actionOrder) {
          va.order = actionOrder;
        }
      });
    }
    this.toolbar = this.createToolbar();
    this.updates = [];
    this.tickUpdateHandlerThrottled = jsutils.throttle(this.tickUpdateHandler, THROTTLE_WAIT, {
      leading: false,
      trailing: true
    });
    this.reactRoot = createRoot(this.domElement);
  },

  sortUsers: function () {
    if (this.columnsToSortBy && this.columnsToSortBy.length > 0) {
      const preferencesColumnsToSortBy = applyColumnSortPreferences(this.usersTableColumns, this.columnsToSortBy);

      const comparator = composeComparators(preferencesColumnsToSortBy.map(col => {
        return createObjectComparator(col.sortValueGetter || col.key, col.sortType, col.ascending);
      }));

      this.viewModel.sortedUsers = this.viewModel.sortedUsers.sort(comparator);

      this.savePreferences({
        ...this.preferences,
        columnsToSortBy: extractColumnSortPreferences(this.columnsToSortBy)
      });
    }
  },

  convertLoginTime: function () {
    this.viewModel.users.forEach((user) => {
      user.lastLoginTime = fromServerDate(user.lastLoginTime);
    });
  },

  filterUsers: function () {
    if (!this.filtersEnabled) {
      this.viewModel.users = this.viewModel.sortedUsers;
    } else {
      const columnsToFilterBy = reduceColumnsToFilterBy(this.usersTableColumns);

      this.viewModel.users = this.viewModel.sortedUsers.filter(user => {

        let match = true;
        for (const col of columnsToFilterBy) {
          const filter = col.filter;
          if (filter.type === FILTER_TYPE.DATE) {
            const time = typeof col.filterValueGetter === 'function' ? col.filterValueGetter(user) : user[col.key];
            match = checkDateCondition(time, filter);
          }
          if (filter.type === FILTER_TYPE.MULTISELECT) {
            if (Array.isArray(filter.selected) && filter.selected.length > 0) {
              const filterValue = col.filterValueGetter(user);
              match = filter.selected.some(s => filterValue.split(',').map(element => element.trim()).find(groupName => groupName === s));
            }
          } else if (FILTER_TYPE.TEXT && filter.textValue) {
            if (filter.dataType === FILTER_DATA_TYPE.TEXT) {
              const text = typeof col.filterValueGetter === 'function' ? col.filterValueGetter(user) : user[col.key];
              match = checkTextCondition(text, filter);
            }
          }
          if (!match) {
            break;
          }
        }

        return match;
      });
    }

    this.render();
  },

  initToolbar: function (model) {

    this.toolbar.addItem({
      label: translate('Toggle Filters'),
      name: 'toggleFilters',
      _isApplicable: true,
      icon: 'filter_list.svg',
      itemType: 'push',
      checked: this.filtersEnabled,
      execute: this.toggleFilters.bind(this)
    });
  },

  toggleFilters: function (pushed) {

    this.filtersEnabled = pushed;

    this.toolbar.setItemChecked('toggleFilters', pushed);

    this.buildViewModel();

    this.saveUsersColumnPreferences();
  },

  handleUserColumnClick: function (sortType) {
    return (columnKey, sortValueGetter, multiSort) => {
      this.columnsToSortBy = reduceColumnsToSortBy(this.usersTableColumns, this.columnsToSortBy, columnKey, multiSort);
      this.sortUsers();
      this.filterUsers();
    };
  },

  saveUsersColumnPreferences: function () {
    const userColumns = extractColumnPreferences(this.usersTableColumns);
    this.savePreferences({
      table: {
        ...this.preferences.table,
        filtersEnabled: this.filtersEnabled,
        userColumns,
      }
    });
  },

  firstTickReceived: function (data) {
    this.preferences = data.preferences || {};
    this.columnsToSortBy = (typeof data.preferences.columnsToSortBy === 'undefined' || data.preferences.columnsToSortBy.length === 0) ?
      DEFAULT_SORT : data.preferences.columnsToSortBy;
    this.viewType = data.model.type;
    this.filtersEnabled = (this.preferences?.table || {}).filtersEnabled || false;
    this.initToolbar(data.model);
    this.tickableModel = new TickableModel();
    this.tickableModel.firstTickHandler(data.model);
    this.buildViewModel();
  },

  savePreferences: function (preferences) {
    if (!preferences) {
      return;
    }
    this.preferences = Object.assign(this.preferences, preferences);
    savePreferences(this.getRequiredParameters(), this.preferences);
  },

  tickUpdate: function (data) {
    this.updates = this.updates.concat(data.model);
    this.tickUpdateHandlerThrottled();
  },

  tickUpdateHandler: function () {
    this.tickableModel.tickUpdateHandler(this.updates);
    this.updates = [];
    this.buildViewModel();
  },

  buildViewModel: function () {
    const model = this.tickableModel.model();
    const newModel = { ...model, users: model.users.map(itemCreator) };

    this.viewModel = {};
    this.viewModel.users = [...newModel.users];

    const updatedSelectedUsers = this.selected.reduce((acc, user) => {
      const updatedUser = this.viewModel.users.find(u => u.nwid === user.nwid);

      if (updatedUser) {
        acc.push(updatedUser);
      }

      return acc;
    }, []);

    this.updateSelected(updatedSelectedUsers);

    this.convertLoginTime();

    this.viewModel.sortedUsers = [...this.viewModel.users];

    this.usersTableColumns = makeUsersTableColumns(this.viewModel, this);

    this.usersTableColumnsByKeys = arrayToObject(this.usersTableColumns);

    this.sortUsers();

    this.filterUsers();
  },


  handleUsersTableSelect: function (selectedRows) {
    const users = selectedRows.map(row => row && this.tickableModel.getByNwid(row.nwid));
    this.updateSelected(users.filter(e => !!e));
  },

  handleUsersTableColumnsFilter: function (columns) {
    this.usersTableColumns.forEach(col => {
      if (columns[col.key]) {
        col.visible = columns[col.key].visible;
      }
    });

    this.saveUsersColumnPreferences();
  },

  handleUsersTableColumnOrder: function (columns, oldIndex, newIndex) {
    moveItem(this.usersTableColumns, oldIndex, newIndex);

    this.saveUsersColumnPreferences();

    this.render();
  },

  handleUsersTableColumnResize: function (columns) {
    this.usersTableColumns.forEach(col => {
      if (columns[col.key]) {
        col.width = columns[col.key].width;
      }
    });

    this.saveUsersColumnPreferences();
  },

  handleUserColumnFilterChange: function (column, columnFilter) {
    if (!column || !column.filter || !column.filter.type) {
      return;
    }

    column.filter = {
      ...column.filter,
      ...columnFilter
    };

    this.filterUsers();

    this.saveUsersColumnPreferences();
  },

  handleRowDoubleClick: function (selectedRow) {

    const actionToNavigate = this.viewType === 'folder' ? 'EditFolderProfileActionCR' : 'EditProfileActionCR';

    const editProfileAction = this.viewActions.find(va => va.actionDefinitionName === actionToNavigate);

    if (editProfileAction) {
      this.navigateByAction(this.tickableModel.getByNwid(selectedRow.nwid), actionToNavigate);
    }
  },

  destroy: function () {
    this._super();
    this.reactRoot.unmount();
  },

  render: function () {
    this.reactRoot.render(
      <View module={this}
        viewModel={this.viewModel}
        usersTableColumns={this.usersTableColumns} />
    );
  }
});