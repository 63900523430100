/**
 * @name CommCenterView Module
 * @author Liat
 */

import sandbox from 'sandbox';
import Ember from 'ember';
import AbstractModule from 'AbstractModule';
import { fromServerDate } from 'core/dates';
import Controller from './controllers/CommCenterViewController';
import View from './views/CommCenterView';
import TableWidget from 'TableWidget';
import logger from 'logger';

var log = logger.getDefaultLogger();

function getDefaultColumns() {
  return [

    { title: "From", field: "isRead", width: '25%', render: markMailAsReadRenderer.bind(this, 'from') },

    { title: "Subject", field: "isRead", width: '50%', render: markMailAsReadRenderer.bind(this, 'subject') },

    { title: "Time", field: "isRead", width: '25%', render: markMailAsReadRenderer.bind(this, 'time') }

  ];
}

function markMailAsReadRenderer(field, model) {
  var value = model[field];

  if (field === 'time') {
    var date = value;
    if (!value instanceof Date) {
      date = fromServerDate(value);
    }
    value = sandbox.localization.toLocaleShortDateTime(date);
  }

  if (model.isRead === false) {
    return '<div class="mail-not-read mail-title" title="' + value.trim() + '">' + value + '</div>';
  } else {
    return '<div class="mail-title" title="' + value.trim() + '">' + value + '</div>';
  }

}

function init() {

  this.ctrl.set('content', this.model);
  this.ctrl.set('module', this);

  sandbox.dom.empty(this.element);
  this.view.appendTo(this.element);

  this.messagesTable = new TableWidget({
    element: this.element + ' .commcenter-messages-table',
    data: this.ctrl.get('messages'),
    columns: getDefaultColumns.call(this),
    hasHeader: false
  });
  this.selection.applyTableSelection(this, this.messagesTable);

  this.messagesTable.bind('onSelectionChanged', function (selected) {
    this.ctrl.showMailContent(selected);
  }, this);

  this.messagesTable.bind('delete', function (selected) {
    this.ctrl.deleteMessage(selected);
  }, this);

  if (Array.isArray(this.ctrl.get('messages')) && this.ctrl.get('messages').length > 0) {
    var selected = this.ctrl.get('messages').slice(0, 1);
    this.messagesTable.setSelection(selected);
  }

}

module.exports = AbstractModule.extend({
  initDone: function () {
    this.ctrl = Controller.create();
    this.view = View.create({
      controller: this.ctrl
    });

    this.toolbar = this.createToolbar();
    sandbox.register('commCenter', this);
    init.call(this);
  },

  destroy: function () {
    this._super();
    if (typeof this.messagesTable !== 'undefined') {
      this.messagesTable.destroy();
    }
    this.ctrl.destroy();
    this.view.destroy();
  }
});
