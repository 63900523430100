import React, { Component } from 'react';
import Header from 'src/components/common/headers/Header';
import PropTypes from 'prop-types';
import settingsManager from 'core/managers/settings';
import { LengthOutput } from 'components/common/outputs';

const lengthUnit = settingsManager.getLengthUnit();

module.exports = class Variants extends Component {

  static propTypes = {
    variants: PropTypes.array,
    title: PropTypes.string,
    openerType: PropTypes.string//dialog or rightPanel
  };

  render() {
    const { variants, title } = this.props;
    return <div>
      <Header>{title}</Header>
      <div style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
        {variants.map((variant, i) => <div key={`${variant.name}-${i}`} style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
          <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', columnGap: 20 }}>
            <span style={{ fontWeight: 'bold' }}>{variant.edition} : {variant.section} : {variant.name}</span>
            <div style={{ display: 'flex', columnGap: 3 }}>
              <span style={{ fontWeight: 'bold' }}>x: </span>
              <span>
                <LengthOutput value={variant.x} unit={lengthUnit} />
              </span>
              <span style={{ fontWeight: 'bold' }}>y: </span>
              <span>
                <LengthOutput value={variant.y} unit={lengthUnit} />
              </span>
            </div>
          </div>
          <div style={{ display: 'flex', gap: 10, flexWrap: 'wrap' }}>
            {variant.zones.map((zc, i) => <div key={i} style={{ display: 'flex', flexDirection: 'column', border: '1px solid black' }}>
              <div style={{ padding: '2px 5px' }}>{zc.code}</div>
              <div style={{ height: 3, backgroundColor: zc.color, borderTop: '1px solid black' }} />
            </div>)}
          </div>
        </div>)}
      </div>
    </div>;
  }
};