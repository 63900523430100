import React from 'react';
import PropTypes from 'prop-types';
import Zone from './Zone';

function View({ zones, module, viewLinksMap, expandedZones, expand, onContextMenu }) {

  const handleChangeDeadline = (zoneOrProductionRun) => {
    const changeDeadlineAction = module.viewActions.find(a => a.actionClass === 'ChangeDeadlineAction');
    if (changeDeadlineAction) {
      changeDeadlineAction.execute(zoneOrProductionRun);
    }
  };


  const renderZoneContent = (zone, index) => {

    return (
      <Zone
        key={zone.id}
        zone={zone}
        module={module}
        onChangeDeadline={(zoneOrRun) => handleChangeDeadline(zoneOrRun)}
        onContextMenu={onContextMenu}
        viewLinksMap={viewLinksMap}
        expandedZones={expandedZones}
        expand={expand}
      />

    );
  };

  return (
    <div className='crtx-dashboard-view crtx-form-element-full-height'>
      {zones.map(renderZoneContent)}
    </div>
  );


}

View.propTypes = {
  zones: PropTypes.array,
  module: PropTypes.any,
  viewLinksMap: PropTypes.object,
  expandedZones: PropTypes.object,
  expand: PropTypes.func,
  onContextMenu: PropTypes.func,
};

export default View;


