import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';
import { Combobox } from 'components/common/floating';
import Dropdown from 'components/common/dropdown/Dropdown';
import { Validator } from 'components/private/forms';
import { Form, Store } from 'components/private/forms';

const { translate } = sandbox.localization;

const ROLE_OPTIONS = [
  { text: 'General', value: 'general' },
  { text: 'Odd', value: 'odd' },
  { text: 'Even', value: 'even' },
  { text: 'Panorama', value: 'panorama' },
];

const filterRoleOptions = (form) => {
  const hasPanorama = form.pages.some(p => p.columnSpan > 1 || p.rowSpan > 1);
  return ROLE_OPTIONS.filter(o => {
    let fit = false;
    if (hasPanorama) {
      fit = o.value === 'panorama';
    } else if (form.numberInBook % 2 === 1) {
      fit = o.value === 'general' || o.value === 'odd';
    } else {
      fit = o.value === 'general' || o.value === 'even';
    }

    return fit;
  });
};

const getTemplateNames = (formTemplates) => {
  const names = formTemplates.map(t => t.name);
  sandbox.sorting.sort(names, 'caseinsensitive');
  return names;
};

class FormTemplate extends Component {
  static propTypes = {
    model: PropTypes.object.isRequired,
  };

  static onDataReceived = (model) => {
    const roleOptions = filterRoleOptions(model.form);
    return {
      form: model.form,
      formTemplates: model.formTemplates,
      templateNames: getTemplateNames(model.formTemplates),
      roleOptions: roleOptions,
      role: roleOptions[0].value,
      name: ''
    };
  };



  templateExists = () => {
    const { model } = this.props;
    if (!model.name || !model.role) {
      return false;
    }

    let result = false;

    const template = model.formTemplates.find(t => t.name.toLowerCase() === model.name.toLowerCase());
    if (template && template.forms) {
      const forms = template.forms.filter(f => f.role === model.role);
      const matchedForm = forms.find(f => {
        const notMatch = f.pages.some((p, index) => {
          const page = model.form.pages[index];
          return !page || p.row !== page.row || p.column !== page.column || p.rowSpan !== page.rowSpan || p.columnSpan !== page.columnSpan;
        });

        return !notMatch;
      });

      if (matchedForm) {
        result = true;
      }
    }

    return result;
  };

  handleNameChange = (event, value) => {
    //console.log("handleNameChange => value=", value);
    const { model } = this.props;
    model.name = value;
    model.templateExists = this.templateExists();
  };

  handleNameSelect = (event, value, index) => {
    //console.log("handleNameSelect => value=", value);
    const { model } = this.props;
    model.name = value;
    model.templateExists = this.templateExists();
  };

  handleRoleChange = (event, value) => {
    const { model } = this.props;
    model.role = value;
    model.templateExists = this.templateExists();
  };

  renderTemplateExistsWarning() {
    const { model } = this.props;

    if (!model.templateExists) {
      return null;
    }

    return (
      <div className='crtx-fanout-dialog-warning'>
      {translate('Note: The group with the same name and role already exists, the previous values will be overwritten.')}
      </div>
    );
  }

  render() {
    const { model } = this.props;

    return (
      <div className='crtx-fanout-dialog-content'>
        <div className='crtx-fanout-dialog-row'>
          <div className='crtx-fanout-dialog-row-caption'>{translate('Group name:')}</div>
          <Validator rules={['required']}>
            <Combobox
              className='crtx-fanout-dialog-row-field'
              value={model.name}
              options={model.templateNames}
              onChange={this.handleNameChange}
              onSelect={this.handleNameSelect}
            />
          </Validator>

        </div>
        <div className='crtx-fanout-dialog-row'>
          <div className='crtx-fanout-dialog-row-caption'>{translate('Role:')}</div>
          <Dropdown
            className='crtx-fanout-dialog-row-field'
            value={model.role}
            options={model.roleOptions}
            onSelect={this.handleRoleChange}
          />
        </div>
        {this.renderTemplateExistsWarning()}
      </div>
    );
  }
}

const DecoratedFormTemplate = Form(FormTemplate);

class FormTemplateContainer extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    formTemplates: PropTypes.array,
    getModelSetter: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.store = new Store({ form: props.form, formTemplates: props.formTemplates });
    props.getModelSetter(this.getModel);
  }

  getModel = () => {
    const model = this.formTemplateInstance.triggerApply();
    return typeof model === 'undefined' ? false : model;
  };

  render() {
    return <DecoratedFormTemplate store={this.store} ref={instance => this.formTemplateInstance = instance} />;
  }
}

export default FormTemplateContainer;