/**
 * @name Projector Class
 * @fileOverview
 * @author sergey
 */

import logger from 'logger';

const log = logger.getDefaultLogger();

module.exports = class Projector {
  /**
   * @param {Number}    projectorId   ID of a projector created on the server for a module
   * @param {Function}  firstTickCb   Callback function to be called for the first tick message
   * @param {Function}  tickCb        Callback function for the tick message
   * @param {Function}  tickCommitCb  Function to be invoked when the tick has committed all messages
   * @param {Object}    moduleInstance    Module Instance object
   */
  constructor(projectorId, firstTickCb, tickCb, tickCommitCb, moduleInstance) {
    this.projectorId = projectorId;
    this.ticksReceived = 0;
    this.firstTickCallback = typeof firstTickCb === 'function' ? firstTickCb.bind(moduleInstance) : undefined;
    this.tickUpdateCallback = typeof tickCb === 'function' ? tickCb.bind(moduleInstance) : undefined;
    this.tickCommitCallback = typeof tickCommitCb === 'function' ? tickCommitCb.bind(moduleInstance) : undefined;
    this.moduleInstance = moduleInstance;
    this._queue = [];
  }

  /**
   * Returns true if the queue is empty.
   * @returns {boolean}
   * @private
   */
  _isQueueEmpty() {
    return this._queue.length <= 0;
  }

  /**
   * Returns true if the queue can be flushed
   * @returns {boolean}
   * @private
   */
  _canFlush() {
    return typeof this.moduleInstance.projectorId !== 'undefined';
  }

  /**
   * Calls _invokeUpdate method for each data message in a queue.
   * @private
   */
  _flushQueue() {
    for (const tickMessage of this._queue) {
      if (tickMessage.projectorId === this.projectorId) {
        this._invokeUpdate(tickMessage);
      }
    }

    this._queue = [];

    if (this.shouldTickCommit) {
      this._invokeTickCommit();
    }
  }

  /**
   * Calls the relevant callback function of the module
   * @param {Object} data Push message
   * @private
   */
  _invokeUpdate(data) {
    this.shouldTickCommit = false;
    try {
      if (this.ticksReceived === 0 && typeof this.firstTickCallback === 'function') {
        this.ticksReceived++;
        this.firstTickCallback(data);
      } else {
        this.ticksReceived++;
        this.tickUpdateCallback(data);
      }
    } catch (err) {
      log.error(err);
    }
  }

  /**
   * Calls the tickCommit() callback function of the module
   */
  _invokeTickCommit() {
    if (typeof this.tickCommitCallback === 'function') {
      this.tickCommitCallback();
    }
  }

  /**
   * API provided for the tick worker to invoke the update.
   * Message is added to the queue. If the module already received the projectorId
   * then the queue is flushed immediately.
   * @public
   * @param {Object} data Push message
   */
  invokeUpdate(data) {
    this.shouldTickCommit = false;
    this._queue.push(data);
    if (this._canFlush()) {
      this._flushQueue();
    }
  }

  /**
   * API provided for the tick worker to invoke the tick commit.
   * If the queue is empty and the projectedId is alredy assigned then
   * call tickCommit() else set the shouldTickCommit flag to true in order to commit later.
   */
  invokeTickCommit() {
    this.shouldTickCommit = true;
    try {
      if (this._canFlush() && this._isQueueEmpty()) {
        this.shouldTickCommit = false;
        this._invokeTickCommit();
      }
    } catch (err) {
      log.error(err);
    }
  }

  flushMessageQueue() {
    if (this._canFlush()) {
      this._flushQueue();
    }
  }
};
