import { parseDate, fromServerDate, toServerDate, MSEC_IN_MINUTE } from 'core/dates';
import $ from 'jquery';
import Ember from 'ember';
import sandbox from 'sandbox';
import wizardCommons from './../../../utils/wizardCommons';
import wizardUtils from './../../../utils/wizardUtils';
import localization from 'core/services/localization';
import ModelHandler from './../model/model';
import { toKendoDateTimeFormatWithoutYear, toKendoDateFormat, toKendoTimeFormat } from 'utilities/datesConvertor';
import Dialog from 'widgets/Dialog/Dialog';
import { restGet, restPost } from 'core/managers/rest2';
import toastService from 'core/services/toastService';
import { translate } from 'core/services/localization';
import dialogService from 'core/services/dialogService';

function clone(model) {
  var ret = {};
  for (var propertyName in model) {
    ret[propertyName] = model[propertyName];
  }
  return ret;
}

function getDefaltDeadLine(issueDate, interval) {
  if (!interval) {
    interval = 0;
  }

  let d = parseDate(issueDate, 'yyyy-M-d');
  d = new Date(d.getTime() + interval * MSEC_IN_MINUTE);
  return d;
}

function sortZones(zones) {
  var root = { children: [], unAssignNodes: [] };
  zones.forEach(function (zone) {
    if (zone.parent === null || zone.parent === zone.name) {
      var node = { children: [], unAssignNodes: [], data: zone };
      root.children.push(node);
    } else {
      var parent = searchParent(root, zone);
      var newNode = { children: [], unAssignNodes: [], data: zone };
      if (parent === null) {
        root.unAssignNodes.push(newNode);
      } else {
        parent.children.push(newNode);
        for (var i = root.unAssignNodes.length - 1; i >= 0; i--) {
          var node = root.unAssignNodes[i];
          if (node.data.parent === newNode.data.name) {
            newNode.children.push(node);
          }
        }
      }
    }
  });

  var ret = [];
  root.children.forEach(function (node) {
    collectChildren(ret, node);
  });
  return ret;
}

function collectChildren(ret, node) {
  ret.push(node.data);
  node.children.forEach(function (child) {
    collectChildren(ret, child);
  });
}

function searchParent(root, zone) {
  var parent = zone.parent;
  for (var i = 0; i < root.children.length; i++) {
    var node = root.children[i];
    if (node.data.name === parent) {
      return node;
    } else {
      var ret = searchParent(node, zone);
      if (ret !== null) {
        return ret;
      }
    }
  }
  return null;
}

function updateSelectedPublication(publicationName) {
  var optionalPublications = this.get('allPublications');
  var that = this;
  optionalPublications.forEach(function (pub) {
    if (publicationName !== null && pub.displayName === publicationName) {
      Ember.set(pub, "selected", true);
    } else {
      Ember.set(pub, "selected", false);
    }
    Ember.set(pub, "loadOption", that.modelHandler.isPublicationExist(pub));
  });
  filterPublications.call(this);
}

function updateSelectedEdition(editionName) {
  var optionalEditions = this.get('optionalEditions');
  optionalEditions.forEach(function (edt) {
    if (editionName !== null && edt.displayName === editionName) {
      Ember.set(edt, "selected", true);
    } else {
      Ember.set(edt, "selected", false);
    }
  });
}

function getSelectedEdition(editionName) {
  var optionalEditions = this.get('optionalEditions');
  for (var i = 0; i < optionalEditions.length; i++) {
    if (editionName !== null && optionalEditions[i].displayName === editionName) {
      return optionalEditions[i];
    }
  }
  return null;
}

function updateSelectedZone(zoneName, selected) {
  var optionalZones = this.get('optionalZones');
  optionalZones.forEach(function (z) {
    if (z.displayName === zoneName) {
      Ember.set(z, "selected", selected);
    }
  });
}

function resolveOptionalEditions(publicationName, standAloneEditions) {
  var optionalEditions = [];
  var order = 0;
  var myEditions = this.planSetup.getEditions(publicationName);
  var firstEditionIndex = this.modelHandler.getLastPlanEdition(myEditions);
  var that = this;
  var templateVisible = true;
  myEditions.forEach(function (editionDescriptor) {
    var obj = { displayName: editionDescriptor.name, shortName: editionDescriptor.shortName, order: order, selected: false };

    obj.disabled = standAloneEditions ? false : order > firstEditionIndex;
    obj.loadOption = that.modelHandler.isEditionExist(editionDescriptor.name);
    if (obj.loadOption && !standAloneEditions) {
      templateVisible = false;
    }
    if (!standAloneEditions && order > 0 && !obj.disabled) {
      that.modelHandler.getPrevEditionZones(myEditions, obj);
    }
    order++;
    optionalEditions.push(obj);
  });
  Ember.set(this, "templateVisible", templateVisible);
  return optionalEditions;
}

var template = '<h3>{{breadcrumbs}}</h3><h2>{{t "Choose the deadline date and time please"}}</h2>' +
  '<br/><div style="text-align: center;">{{kendo-date-time-picker value=dateTimeValue interval=15}}</div>';

var Form = Ember.Component.extend({
  breadcrumbsArr: Ember.computed('breadcrumbs', function () { return this.get('breadcrumbs').split("/"); }),
  layout: Ember.Handlebars.compile(template),
  saveDeadline: function (zone, model) {
    zone.deadline = toServerDate(this.get('dateTimeValue'));
    var zones = model.publication.editions[0].zones;
    for (var i = 0; i < zones.length; i++) {
      if (zones[i].displayName === zone.displayName) {
        zones[i].deadline = toServerDate(this.get('dateTimeValue'));
        break;
      }
    }
  }
});

function filterPublications() {
  var list = Ember.get(this, "allPublications").slice();
  if (!this.searchStringValue || this.searchStringValue.length === 0) {
    list = list.filter(item => !this.onlyLoad || item.loadOption);
    Ember.set(this, "optionalPublications", list);
    return;
  }
  list = list.filter(item => item.displayName.toLowerCase().indexOf(this.searchStringValue.toLowerCase()) > -1 && (!this.onlyLoad || item.loadOption))
  Ember.set(this, "optionalPublications", list);
}

module.exports = Ember.ObjectController.extend(wizardCommons.controllerCommons, {
  actions: {
    doSomething: function (zone) { },

    loadTemplate: function () {
      var slcObj = $('.slcTemplatesSelection')[0];
      var selectedOptions = slcObj.selectedOptions;
      if (selectedOptions.length === 0 || this.get('model').publication.inActivationMode) {
        return;
      }
      var selectedTemplate = slcObj.selectedOptions[0].text;
      var request = sandbox.request.getPlan(this, this.rootId, this.templateLoaded, this.projectorId,
        {
          rootId: this.rootId,
          rootType: this.rootType,
          instanceNwId: this.instance,
          mode: "template",
          publicationName: this.get('model').publication.displayName,
          publicationDate: this.get('model').publication.issueDate,
          templateName: selectedTemplate
        }
      );

    },

    loadAndActivate: function () {
      var slcObj = $('.slcTemplatesSelection')[0];
      var selectedOptions = slcObj.selectedOptions;
      if (selectedOptions.length === 0 || this.get('model').publication.inActivationMode) {
        return;
      }
      this.wizard.disableButton("back");
      this.wizard.disableButton("next");
      Ember.set(this.get('model').publication, "inActivationMode", true);
      var selectedTemplate = slcObj.selectedOptions[0].text;
      var publicationName = this.get('model').publication.displayName;
      var publicationDate = this.get('model').publication.issueDate;
      var wizardFolder = this.get('model').wizardFolder;

      restPost(this.instance, `/folders/${this.rootId}/planning-actions/activate-template?publicationDate=${publicationDate}&publication=${publicationName}&template=${selectedTemplate}&wizardFolder=${wizardFolder}`) // Todo - need testing
        .then((result) => {
          if (result.error) {
            toastService.errorToast('', translate('Failed to activate template {1}', selectedTemplate));


            this.wizard.enableButton("back");
            this.wizard.enableButton("next");
            Ember.set(this.get('model').publication, "inActivationMode", false);
            return;
          }
          //window.close();
          this.wizard.initObj.onCanceled();
          // },
        })
        .catch(err => {
          console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!ERROR " + err);
          toastService.errorToast('', translate('Failed to activate template {1}', selectedTemplate));
          this.wizard.enableButton("back");
          this.wizard.enableButton("next");
          Ember.set(this.get('model').publication, "inActivationMode", false);
        });
    }
  },

  planSetup: null,

  templateWasLoaded: false,

  templateWasLoadedProp: function () {
    return Ember.get(this, "templateWasLoaded")
  }.property('this.templateWasLoaded'),
  //selectedEditionView: null,

  isManyEditionsPerPublication: function () {
    return this.manyEditionsPerPublication;
  },

  init: function () {
    this._super();
    this.modelHandler = new ModelHandler();
    this.modelHandler.setModel(this.get('model'));
    this.searchStringValue = "";
    this.onlyLoad = false;
    this.addObserver("searchStringValue", function () {

      filterPublications.call(this);
    });

    this.addObserver("onlyLoad", function () {
      filterPublications.call(this);

    });
  },

  dateFormat: function () {
    return toKendoDateFormat();
  }.property(),

  dateTimeFormatRemoveYear: function () {
    return toKendoDateTimeFormatWithoutYear();
  }.property(),

  timeFormat: function () {
    return toKendoTimeFormat();
  }.property(),

  setPlanSetup: function (PlanSetup) {
    this.planSetup = PlanSetup;
    var optionalPublications = [];
    var that = this;
    this.manyEditionsPerPublication = false;
    this.planSetup.getPublications().forEach(function (pub) {
      var publicationDescriptor = { displayName: pub, selected: false };
      var planSetupPublication = that.planSetup.getPublication(pub);
      publicationDescriptor.standAloneEditions = (planSetupPublication.standAloneEditions === "true");
      publicationDescriptor.loadOption = that.modelHandler.isPublicationExist(publicationDescriptor);
      optionalPublications.push(publicationDescriptor);

      var myEditions = that.planSetup.getEditions(pub);
      if (myEditions.length > 10) {
        that.manyEditionsPerPublication = true;
      }
    });

    optionalPublications.sort(function compare(a, b) {
      if (a.displayName < b.displayName) {
        return -1;
      } else if (a.displayName > b.displayName) {
        return 1;
      } else {
        return 0;
      }
    });
    this.set('optionalPublications', optionalPublications);
    this.set('allPublications', optionalPublications);
    this.set('optionalEditions', []);
    this.set('optionalZones', []);
    this.addObserver('model.issueDate', this.updateIssueDate);
    Ember.set(Ember.get(this, 'model'), 'planSetup', PlanSetup);
  },

  updateIssueDate: function () {
    this.modelHandler.updateIssueDate(this.get('model.issueDate'));

    if (typeof this.modelHandler.getPublication() === "undefined" || this.modelHandler.getPublication() === null) {
      updateSelectedPublication.call(this, null);
      filterPublications.call(this);
      return;
    }

    this.publicationActionDone({ displayName: this.modelHandler.getPublication().displayName }, false);
    updateSelectedPublication.call(this, null);
    filterPublications.call(this);
    //var myEditions = this.planSetup.getEditions(this.modelHandler.getPublication().displayName);
    //var firstEditionIndex = this.modelHandler.getLastPlanEdition(myEditions);
    //var optionalEditions = Ember.get(this, 'optionalEditions');
    //var order = 0;
    //var that = this;
    //var templateVisible = true;
    //optionalEditions.forEach(function(optEdt){
    //  Ember.set(optEdt, "disabled", order > firstEditionIndex);
    //  var exist = that.modelHandler.isEditionExist(optEdt.displayName);
    //  if (exist){
    //    templateVisible = false;
    //  }
    //  Ember.set(optEdt, "loadOption", exist);
    //});
    //Ember.set(this, "templateVisible", templateVisible);
  },

  handleWizardSteps: function () {
    var publication = this.modelHandler.getPublication();
    var show = false;
    if (publication === null || typeof publication === 'undefined') {
      show = true;
    } else {
      var edition = this.modelHandler.getEdition();
      if (edition === null || typeof edition === 'undefined') {
        //this.wizard.showSteps([1,3,4]);
        show = true;
        //return;
      } else {
        var index = this.planSetup.getEditionIndex(publication.displayName, edition.displayName);
        var standAloneEditions = publication.standAloneEditions;
        console.log("standAloneEditions " + standAloneEditions);
        if (index > 0 && !standAloneEditions) {
          //this.wizard.hideSteps([1,3,4]);
          show = false;
        } else {
          //this.wizard.showSteps([1,3,4]);
          show = true;
        }
      }
    }
    var chaseSteps = this.model.clientSettings.hideChaseSteps || [1, 3, 4];
    try {
      if (show) {
        this.wizard.showSteps(chaseSteps);
      } else {
        this.wizard.hideSteps(chaseSteps);
      }
    } catch (e) {
      console.log("failed to hide/show steps");
    }
  },

  publicationActionDone: function (publicationDescriptor, add) {
    var publicationName = publicationDescriptor.displayName;
    Ember.set(this.model, "publicationChanged", !Ember.get(this.model, "publicationChanged"));
    updateSelectedPublication.call(this, publicationName);
    var that = this;

    if (add) {
      this.modelHandler.setPublication(publicationName, publicationDescriptor.standAloneEditions);

      Ember.set(this, 'optionalZones', []);

      var optionalEditions = resolveOptionalEditions.call(this, publicationName, publicationDescriptor.standAloneEditions);
      Ember.set(this, 'optionalEditions', optionalEditions);


    } else {
      if (this.modelHandler.isPublicationNeedApprove(publicationName)) {
        if (!confirm("Are you sure?")) {
          return;
        }
      }
      this.modelHandler.clearPublication();
      Ember.set(this, 'optionalEditions', []);
      Ember.set(this, 'optionalZones', []);
    }
    this.handleWizardSteps();
  },

  editionActionDone: function (editionDescriptor, add) {
    var that = this;
    var publicationDate = this.modelHandler.getPublication().issueDate;
    var publicationName = this.modelHandler.getPublication().displayName;
    var editionName = editionDescriptor.displayName;

    restGet(this.instance, `/folders/${this.rootId}/planning-actions/default-zones-deadline-info?publicationDate=${publicationDate}&publication=${publicationName}&edition=${editionName}`)
      .then((result) => {
        that.editionActionDoneInternal.call(that, editionDescriptor, add, result);
      })
      .catch(args => {
        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!ERROR " + args);
        that.editionActionDoneInternal.call(that, editionDescriptor, add, {});
      });
  },

  editionActionDoneInternal: function (editionDescriptor, add, zonesDeadlines) {
    var editionName = editionDescriptor.displayName;
    updateSelectedEdition.call(this, editionName)
    if (add) {
      this.modelHandler.addEdition(editionName, editionDescriptor.shortName);
      var zones = this.getOptionalZones(zonesDeadlines);
      Ember.set(this, 'optionalZones', zones);

    } else {
      if (this.modelHandler.isEditionNeedApprove(editionName)) {
        if (!confirm("Are you sure?")) {
          return;
        }
      }
      this.modelHandler.removeEdition(editionName);
      updateSelectedEdition.call(this, null);
      Ember.set(this, 'optionalZones', []);
    }
    this.handleWizardSteps();
  },

  templateLoaded: function (data) {

    var publicationName = this.model.publication.displayName;
    var optionalEditions = resolveOptionalEditions.call(this, publicationName, data.publication.standAloneEditions);
    for (var i = 0; i < optionalEditions.length; i++) {
      if (optionalEditions[i].loadOption) {
        if (optionalEditions[i].displayName === data.publication.editions[0].displayName) {

          dialogService.openAlertDialog((translate('Edition {1} was planned. Please load it to make changes', data.publication.editions[0].displayName)), translate('Load Template'));
          return;
        }
      }
    }

    Ember.set(this, "templateWasLoaded", !Ember.get(this, "templateWasLoaded"));

    data.publication.inActivationMode = false;
    data.publication.activated = false;

    Ember.set(this.model, "publication", data.publication);

    publicationName = data.publication.displayName;
    updateSelectedPublication.call(this, publicationName);
    optionalEditions = resolveOptionalEditions.call(this, publicationName, data.publication.standAloneEditions);

    Ember.set(this, 'optionalEditions', optionalEditions);

    updateSelectedEdition.call(this, data.publication.editions[0].displayName);
    Ember.set(this, 'optionalZones', this.getOptionalZones({}));
    var optionalZones = Ember.get(this, 'optionalZones');
    var plannedZones = data.publication.editions[0].zones;
    for (var i = 0; i < plannedZones.length; i++) {
      for (var j = 0; j < optionalZones.length; j++) {
        if (plannedZones[i].displayName === optionalZones[j].displayName) {
          Ember.set(optionalZones[j], "selected", true);
          var deadline = plannedZones[i].deadline;
          if (!deadline) {
            deadline = getDefaltDeadLine(this.model.publication.issueDate, this.model.clientSettings.presetTimeOffset)
          } else {
            deadline = fromServerDate(deadline);
          }
          Ember.set(optionalZones[j], "deadline", deadline);
          break;
        }
      }
    }
    this.modelHandler.updateIssueDate(this.get('model.issueDate'));
  },

  updateDeadline: function (zone) {
    var zones = this.model.publication.editions[0].zones;
    for (var i = 0; i < zones.length; i++) {
      if (zones[i].displayName === zone.displayName) {
        zones[i].deadline = toServerDate(zone.deadline);
        break;
      }
    }
  },

  zoneActionDone: function (zoneDescriptor, add) {
    var zoneName = zoneDescriptor.displayName;
    updateSelectedZone.call(this, zoneName, add)
    if (add) {
      console.log("zoneDescriptor.order:" + zoneName + ":" + zoneDescriptor.order);
      var zone = this.modelHandler.addZone(zoneName, zoneDescriptor.order, zoneDescriptor.sharingOrder, zoneDescriptor.shortName);
      //zone.deadline = zoneDescriptor.deadline;
      if (zoneDescriptor.deadline) {
        this.updateDeadline(zoneDescriptor);
      }

    } else {
      if (zoneDescriptor.loadOption){
        var msg = "You are about to delete exist zone " + zoneName;
        alert (msg);
      } else if (this.modelHandler.isZoneNeedApprove(zoneName)) {
        if (!ret) {
          return;
        }
      }
      this.modelHandler.removeZone(zoneName);
    }

    Ember.set(this.modelHandler.getEdition(), "zoneChangeFlag", !Ember.get(this.modelHandler.getEdition(), "zoneChangeFlag"));
  },

  validData: function () {
    return this.modelHandler.isValid();
  },

  reload: function (model) {
    this.modelHandler = new ModelHandler();
    this.modelHandler.setModel(this.get('model'));
  },
  ////////////////////////////////////////////////////////
  getPlanSetupMainEdition: function () {
    return this.get("model.planSetup").getMainEdition(this.get('model.publication'));
  },

  getOptionalZones: function (zonesDeadlines) {
    var planSetupZonesDescriptors = this.planSetup.getZones(this.modelHandler.getPublication(), this.modelHandler.getEdition());
    var index = this.planSetup.getEditionIndex(this.modelHandler.getPublication().displayName, this.modelHandler.getEdition().displayName);
    var zonesDescriptors = sortZones(planSetupZonesDescriptors);
    //create_tree(planSetupZonesDescriptors, zonesDescriptors);
    //var myZones = [];
    //for (var i=0;i<zonesDescriptors.length;i++ )
    //{
    //  addChildren(myZones, zonesDescriptors[i]);
    //}
    var selectedEdition = getSelectedEdition.apply(this, [this.modelHandler.getEdition().displayName]);
    var ret = [];
    for (var i = 0; i < planSetupZonesDescriptors.length; i++) {
      //console.log ("getOptionalZones " + planSetupZonesDescriptors[i].name + ":" + i);
      var z = { changeFlag: "0", displayName: planSetupZonesDescriptors[i].name, order: i };
      z.sharingOrder = zonesDescriptors.indexOf(planSetupZonesDescriptors[i]);
      var loadOption = this.modelHandler.isZoneExist(this.modelHandler.getPublication(), this.modelHandler.getEdition(), z);
      var prevZone = this.modelHandler.isPrevZone(selectedEdition, z);
      var deadline = this.modelHandler.getZoneDeadline(this.modelHandler.getPublication(), this.modelHandler.getEdition(), z);
      if (!deadline || deadline.length === 0) {
        if (zonesDeadlines.hasOwnProperty(planSetupZonesDescriptors[i].name)) {
          deadline = fromServerDate(zonesDeadlines[planSetupZonesDescriptors[i].name]);
        }
      }
      z.selected = loadOption || prevZone;
      z.loadOption = loadOption;
      z.shortName = planSetupZonesDescriptors[i].shortName ? planSetupZonesDescriptors[i].shortName : z.displayName;
      if (loadOption) {
        this.zoneActionDone(z, true);
      } else if (index > 0 && prevZone) {
        //          z.disabled = true;
        this.zoneActionDone(z, true);
      }

      var prevZones = (typeof selectedEdition.prevZones !== "undefined" && selectedEdition.prevZones !== null) ?
        selectedEdition.prevZones : [];
      if (index > 0 && prevZones.length > 0) {
        var parent = planSetupZonesDescriptors[i].parent;
        if (typeof parent !== "undefined" && parent !== null) {
          z.disabled = (prevZones.indexOf(parent) === -1);
        } else {
          z.disabled = (prevZones.indexOf(z.displayName) === -1);
        }
      }
      // deadline == null ? get date object from publication, then change it to date with interval
      if (!z.deadline) {
        z.deadline = deadline ? deadline : getDefaltDeadLine(this.model.publication.issueDate, this.model.clientSettings.presetTimeOffset);
      }

      ret.push(z);
    }
    return ret;
  },

  isEnableDeadlines: function (model, type) {
    return this.get("model.planSetup").isEnableDeadlines(model, type);
  }
});