import React from 'react';
import { getCustomIconUrl } from 'core/services/iconService';
import { removefileExtensionFromFileName } from 'utilities/files';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import { translate } from 'core/services/localization';
import Dropdown from 'components/common/dropdown/Dropdown';

const DEFAULT_CUSTOM_ICONS_OPTION = { value: '', text: translate('None') };

export const CustomIcons = observer(({ model, availableCustomIcons, onSelect, moduleNwid }) => {

  const handleCustomIconSelect = action((e, value, index) => {
    onSelect(value);
  });

  const getCustomIconOption = (option, index) => {
    if (typeof option === 'undefined') {
      return;
    }

    return <div className='crtx-custom-icons-dropdown-option'>
      <label>{option.text}</label>
      {option.value && <img title={option.text} src={option.url} />}
    </div>
  };

  const getCustomIconsOptions = [DEFAULT_CUSTOM_ICONS_OPTION].concat((availableCustomIcons || []).map(icon => ({
    url: getCustomIconUrl(moduleNwid, icon),
    value: icon,
    text: removefileExtensionFromFileName(icon)
  })));

  return <Dropdown
    className='crtx-form-element-under-medium'
    options={getCustomIconsOptions}
    value={model}
    textProp='text'
    valueProp='value'
    onSelect={handleCustomIconSelect}
    itemGetter={getCustomIconOption}
  />
});
