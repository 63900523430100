/**
 * Created by moshemal on 9/4/14.
 */
import { fromServerDate } from 'core/dates';
import sandbox from 'sandbox';
import Class from 'class';
import {getFolderCurrentDate} from 'core/dates';
import wizardUtils from './../../../utils/wizardUtils';
import settingsManager from 'core/managers/settings';

function getIssueDate(PlanSetup) {
  var ret = null;
  var today = getFolderCurrentDate();
  var interval = 1; // need to get this from setup
  ret = today.setDate(today.getDate() + interval);
  return new Date(ret);
}

function zonesToString(zones) {
  var ret = "";
  zones.forEach(function (zone) {
    //ret.push(zone.displayName);
    ret += zone.displayName + ";";
  });
  return ret;
}

function createPublication(model) {
  var publication = { type: 'publication' };
  var issueRawDate = Ember.get(this, 'model.issueDate');
  var issueDate = issueRawDate.getFullYear() + "-" + (issueRawDate.getMonth() + 1) + "-" + issueRawDate.getDate();
  if (model === null) {
    var folderNwId = this.model.folderPreFix ? this.model.folderPreFix + this.model.folder + "_" : "";
    publication.sourceId = this.model.clientSettings.sourceIdBreadcrumb ? null : folderNwId + wizardUtils.getSourceId();
    publication.manualPlan = true;
    publication.issueDate = issueDate;
    publication.editions = [];
    return publication;
  }
  publication.displayName = model.displayName;
  publication.issueDate = model.issueDate;
  publication.sourceId = model.sourceId;
  if (model.publication.hasOwnProperty("editions")) {
    for (var i = 0; i < model.publication.editions.length; i++) {
      var modelEdition = model.publication.editions[i];
      var edition = { displayName: modelEdition.displayName, zones: [], sourceId: modelEdition.sourceId };
      if (modelEdition.hasOwnProperty("zones")) {
        for (var j = 0; j < modelEdition.zones.length; j++) {
          edition.zones.addObject(modelEdition.zones[j].displayName);
        }
      }
      publication.editions.addObject(edition);
    }
  } else {
    publication.editions = [];
  }
  return publication;
}

module.exports = Class.extend({

  model: null,
  clientSettings: null,
  //    convertToStepModel: function (model){
  //      //this._super(model);
  //      this.model = {};
  //      if (model.hasOwnProperty("publication")){
  //        this.model.publication = createPublication.call(this, model.publication)
  //      }
  //      return this.model;
  //    },
  //
  //    convertFromStepModel: function (model){
  //      //this._super(model);
  //
  //    },

  setModel: function (model) {
    Ember.set(this, 'model', model);
    var publication = this.getPublication();
    if (publication === null || typeof publication === 'undefined') {
      Ember.set(Ember.get(this, 'model'), "issueDate", getIssueDate(Ember.get(this, 'model.planSetup')));
    }
  },

  structureChangeHandler: function () {
    var publication = this.getPublication();
    if (publication !== null && typeof publication !== 'undefined' && !publication.hasOwnProperty("myNwId")) {
      var key = publication.displayName + ":" + publication.issueDate;
      //if (this.model.publicationsInfo.indexOf(key) > -1){
      if (this.model.publicationsInfo.hasOwnProperty(key.toLowerCase())) {
        //alert("Publication " + publication.displayName + " already planned in " + publication.issueDate +"." +
        //"Please use load option.");
        return false;
      }
    }
    return true;
    //var request = sandbox.request.getPlan(this, rootId, dataRecieved, projectorId,
    //  {
    //    rootId: rootId,
    //    rootType: rootType
    //  }
    //);
  },

  getLastPlanEdition: function (editions) {
    var publication = this.getPublication();
    if (publication !== null && typeof publication !== 'undefined' && !publication.hasOwnProperty("myNwId")) {
      var key = publication.displayName + ":" + publication.issueDate;
      for (var i = 0; i < editions.length; i++) {
        var edtKey = key + ":" + editions[i].name;
        //if (this.model.publicationsInfo.indexOf(edtKey) === -1) {
        if (!this.model.publicationsInfo.hasOwnProperty(edtKey.toLowerCase())) {
          return i;
        }
      }
      return editions.length;
    }
    return 0;
  },

  getPrevEditionZones: function (editions, edition) {
    var publication = this.getPublication();
    if (publication !== null && typeof publication !== 'undefined' && !publication.hasOwnProperty("myNwId")) {
      var key = publication.displayName + ":" + publication.issueDate;
      var myOrder = edition.order;
      var prevEdition = null;
      for (var i = 0; i < editions.length; i++) {
        var currIndex = parseInt(editions[i]['@index'], 10);
        if (currIndex === myOrder - 1) {
          var edtKey = key + ":" + editions[i].name;
          var prevZones = [];
          Object.keys(this.model.publicationsInfo).forEach(function (editionKey) {
            if (editionKey.slice(0, edtKey.length) == edtKey) {
              if (editionKey.length > edtKey.length) {
                var params = editionKey.split(":");
                prevZones.push(params[3]);
              }
            }

          });
          edition.prevZones = prevZones;
          return;
        }
      }
    }
  },

  isPublicationExist: function (publicationDescriptor) {
    var issueRawDate = Ember.get(this, 'model.issueDate');
    var issueDate = issueRawDate.getFullYear() + "-" + (issueRawDate.getMonth() + 1) + "-" + issueRawDate.getDate();
    var key = publicationDescriptor.displayName + ":" + issueDate;
    return this.model.publicationsInfo.hasOwnProperty(key.toLowerCase());
  },

  isEditionExist: function (editionName) {
    var publication = this.getPublication();
    if (publication !== null && typeof publication !== 'undefined' && !publication.hasOwnProperty("myNwId")) {
      var key = publication.displayName + ":" + publication.issueDate + ":" + editionName;
      //return (this.model.publicationsInfo.indexOf(key) > -1);
      return this.model.publicationsInfo.hasOwnProperty(key.toLowerCase());
    }
    return false;
  },

  isZoneExist: function (publicationDescriptor, editionDescriptor, zoneDescriptor) {
    var key = publicationDescriptor.displayName + ":" + publicationDescriptor.issueDate + ":" + editionDescriptor.displayName + ":";
    key += zoneDescriptor.displayName;
    //return (this.model.publicationsInfo.indexOf(key) > -1);
    return this.model.publicationsInfo.hasOwnProperty(key.toLowerCase());
  },

  getZoneDeadline: function (publicationDescriptor, editionDescriptor, zoneDescriptor) {
    var key = publicationDescriptor.displayName + ":" + publicationDescriptor.issueDate + ":" + editionDescriptor.displayName + ":";
    key += zoneDescriptor.displayName;
    //return (this.model.publicationsInfo.indexOf(key) > -1);
    return this.model.deadlines.hasOwnProperty(key) ? fromServerDate(this.model.deadlines[key]) : "";
  },

  isPrevZone: function (editionDescriptor, zoneDescriptor) {
    var prevZones = (typeof editionDescriptor.prevZones !== "undefined" && editionDescriptor.prevZones !== null) ?
      editionDescriptor.prevZones : [];
    for (var i = 0; i < prevZones.length; i++) {
      if (zoneDescriptor.displayName === prevZones[i]) {
        return true;
      }
    }
    return false;
  },


  isValid: function () {
    if (typeof this.getPublication() === "undefined" || this.getPublication() === null) {
      return false;
    }
    if (typeof this.getEdition() === "undefined" || this.getEdition() === null) {
      return false;
    }

    //if (!this.structureChangeHandler()){
    //  sandbox.dialog.alert("Publication " + this.getPublication().displayName + " already planned in " + this.getPublication().issueDate +"." +
    //  "Please use load option.");
    //  return false;
    //}
    var zones = this.getZones();
    if (!zones || zones.length === 0) {
      return false;
    }
    var goingToLoad = this.isEditionExist(this.getEdition().displayName);
    if (goingToLoad) {
      var key = this.getPublication().displayName + ":" + this.getPublication().issueDate + ":" + this.getEdition().displayName;
      var editionNwid = this.model.publicationsInfo[key.toLowerCase()];
      //if (confirm("You are going to load exists edition. Continue?")){
      this.model["editionWasLoaded"] = true;
      this.model["editionToLoad"] = editionNwid;
      this.model["zones"] = zonesToString(zones);
      //} else {
      //  return false;
      //}
    }

    var publicationName = this.getPublication().displayName;
    var editionName = this.getEdition().displayName;
    var planSetupPublication = Ember.get(this, 'model.planSetup').getPublication(publicationName);
    var planSetupEdition = Ember.get(this, 'model.planSetup').getEdition(publicationName, editionName);
    var index = Ember.get(this, 'model.planSetup').getEditionIndex(publicationName, editionName);
    if (!this.getPublication().standAloneEditions) {
      if (!goingToLoad && index > 0) {
        var prevPlanSetupEdition = Ember.get(this, 'model.planSetup').getEditionByIndex(publicationName, index - 1);
        if (prevPlanSetupEdition !== null) {
          var baseEditionkey = this.getPublication().displayName + ":" + this.getPublication().issueDate + ":" + prevPlanSetupEdition.name;
          if (this.model.publicationsInfo.hasOwnProperty(baseEditionkey.toLowerCase())) {
            this.model["editionToChase"] = this.model.publicationsInfo[baseEditionkey.toLowerCase()];
            this.model["chaseMode"] = true;
            if (this.model["editionToLoad"]) {
              delete this.model.editionToLoad;
            }
          }
        }
      }
    }

    this.getPublication().code = planSetupPublication.shortName;
    this.getEdition().code = planSetupEdition.shortName;

    var continueNumbers = planSetupPublication.continuousNumberingBetweenSections;
    //continueNumbers = "true";
    if (continueNumbers === "false") {
      continueNumbers = false;
    } else if (continueNumbers === "true") {
      continueNumbers = true;
    }
    //continueNumbers = true;
    this.model.continueNumbers = continueNumbers;
    var sections = planSetupPublication.Sections;
    if (!(planSetupPublication.sections instanceof Array)) {
      sections = planSetupPublication.Sections.Section;
      if (!(sections instanceof Array)) {
        sections = [sections];
      }

      if (sections instanceof Array) {
            var productSorting = this.sectionSorting = settingsManager.get('productSorting').section;
        if (productSorting === "alphanumeric") {
          sections.sort(function (a, b) { return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0); });
        } else {
          sections.sort(function (s1, s2) {
            var n1 = parseInt(s1['@index'], 10);
            var n2 = parseInt(s2['@index'], 10);
            return n1 - n2;
          });
        }

        this.getPublication().sectionsOptions = sections;
      }
    }

    if (this.model["chaseMode"] || this.model["editionWasLoaded"]) {
      return true;
    }
    if (zones.length === 0) {
      return false;
    }
    var zoneCodes = {};
    for (var i = 0; i < zones.length; i++) {
      var planSetupZone = Ember.get(this, 'model.planSetup').getZone({ displayName: this.getPublication().displayName },
        zones[i].displayName);
      if (planSetupZone !== null) {
        var shortName = planSetupZone.shortName;
        if (shortName && zoneCodes[shortName]) {
          sandbox.dialog.alert("Zone code " + shortName + " is not unique.");
          return false;
        }
        zoneCodes[shortName] = 1;
        zones[i].code = shortName === null || typeof shortName === 'undefined' ? zones[i].displayName : shortName;
        zones[i].share = planSetupZone.share === 'true';
        zones[i].color = planSetupZone.color;
        if (typeof planSetupZone.parent !== 'undefined' && planSetupZone.parent !== null &&
          planSetupZone.parent !== zones[i].displayName) {
          zones[i].masterZoneName = planSetupZone.parent;
          for (var k = 0; k < zones.length; k++) {
            if (zones[k].displayName === planSetupZone.parent) {
              zones[i].masterZone = zones[k].sourceId;
            }
          }
        }
      }
    }

    return true;
  },

  updateIssueDate: function (issueRawDate) {
    this.issueDate = issueRawDate;
    var publication = this.getPublication();
    if (publication === null || typeof publication === 'undefined') {
      return;
    }
    var issueDate = issueRawDate.getFullYear() + "-" + (issueRawDate.getMonth() + 1) + "-" + issueRawDate.getDate();
    publication.issueDate = issueDate;
    this.structureChangeHandler();
  },

  setPublication: function (publicationName, standAloneEditions) {
    Ember.set(Ember.get(this, 'model'), 'publication', createPublication.call(this, null));
    Ember.set(Ember.get(this, 'model.publication'), 'displayName', publicationName);
    Ember.set(Ember.get(this, 'model.publication'), 'standAloneEditions', standAloneEditions);

    var publication = Ember.get(this, 'model.publication');
    if (!publication.sourceId) {
      var folderNwId = this.model.folderPreFix + this.model.folder + "_";
      publication.sourceId = (folderNwId + "/" + publication.issueDate + "/" + publication.displayName).toLowerCase();

    }

    var key = publication.displayName + ":" + publication.issueDate;
    if (this.model.publicationsSourceIds.hasOwnProperty(key)) {
      publication.sourceId = this.model.publicationsSourceIds[key];

    }
    this.structureChangeHandler();
  },

  clearPublication: function () {
    Ember.set(Ember.get(this, 'model'), 'publication', null);
  },

  addEdition: function (editionName, editionCode) {
    var editions = Ember.get(this.getPublication(), 'editions');

    var shortName = editionCode === null || typeof editionCode === 'undefined' ? editionName : editionCode;

    if (editions.length > 0 && editions[0].displayName === editionName) {
      return;
    }
    //var edition = wizardUtils.matchByName(editions, editionName);
    //if (edition === null){
    //console.log ("sourceId:" + this.getPublication().displayName + )  
    var folderNwId = this.model.folderPreFix + this.model.folder + "_";
    var sourceId = this.model.clientSettings.sourceIdBreadcrumb ? this.getPublication().sourceId + "/" + editionName : folderNwId + wizardUtils.getSourceId();
    var edition = {
      type: 'edition', displayName: editionName, code: shortName, zones: [], sourceId: sourceId.toLowerCase(),
      parent: this.model.publication.sourceId
    };
    editions.removeObject(editions[0]);
    editions.addObject(edition);
    //}
    //this.structureChangeHandler();
  },

  removeEdition: function (editionName) {
    var editions = Ember.get(this.getPublication(), 'editions');
    if (typeof editionName === "undefined" || editionName === null) {
      editions = [];
    } else {
      var edition = wizardUtils.matchByName(editions, editionName);
      if (edition !== null) {
        editions.removeObject(edition);
      }
    }
    this.structureChangeHandler();
  },

  isPublicationNeedApprove: function (editionName) {
    if (this.getPublication() !== null) {
      var editions = this.getPublication().editions;
      for (var i = 0; i < editions.length; i++) {
        if (this.isEditionNeedApprove(editions[i].displayName)) {
          return true;
        }
      }
    }
    return false;
  },

  isEditionNeedApprove: function (editionName) {
    var edition = wizardUtils.matchByName(this.getPublication().editions, editionName);
    if (edition !== null) {
      var zones = this.getZones();
      for (var i = 0; i < zones.length; i++) {
        if (this.isZoneNeedApprove(zones[i].displayName)) {
          return true;
        }
      }
    }
    return false;
  },

  isZoneNeedApprove: function (zoneName) {
    var zones = this.getZones();
    var zone = wizardUtils.matchByName(zones, zoneName);
    if (zone !== null && zone.sections.length > 0) {
      var sectionName = zone.sections[0].displayName;
      return (typeof sectionName !== "undefined" && sectionName !== null);
    }
    return false;
  },

  addZone: function (zoneName, zoneOrder, sharingOrder, shortName) {
    var zones = this.getZones();
    var zone = wizardUtils.matchByName(zones, zoneName);
    if (zone === null) {
      //var sourceId = this.model.clientSettings.sourceIdBreadcrumb ?  this.getEdition().sourceId + "/" + shortName: wizardUtils.getSourceId();
      var folderNwId = this.model.folderPreFix + this.model.folder + "_";
      var zoneSourceId = this.model.clientSettings.sourceIdBreadcrumb ? this.getEdition().sourceId + "/" + zoneName : folderNwId + wizardUtils.getSourceId();
      var sectionSourceId = this.model.clientSettings.sourceIdBreadcrumb ? null : folderNwId + wizardUtils.getSourceId();
      var sections = [{ type: 'section', parent: zoneSourceId.toLowerCase(), startPage: 1, oldStartPage: 1, numPages: 0, sourceId: sectionSourceId }];
      zone = {
        type: 'zone', displayName: zoneName, order: zoneOrder,
        sharingOrder: sharingOrder, sections: sections, books: [], sourceId: zoneSourceId.toLowerCase(),
        parent: this.getEdition().sourceId
      };
      wizardUtils.insert(zones, "order", "numeric", zone);

    }
    return zone;
  },


  removeZone: function (zoneName) {
    var zones = this.getZones();
    var zone = wizardUtils.matchByName(zones, zoneName);
    if (zone !== null) {
      zones.removeObject(zone);
    }
  },

  getZones: function () {
    var zones = Ember.get(this.getEdition(), "zones");
    if (zones === void 0) {
      Ember.set(this.getEdition(), "zones", []);
    }
    return Ember.get(this.getEdition(), "zones");
  },

  getPublication: function () {
    return Ember.get(this, 'model.publication');
  },

  getEdition: function () {
    return Ember.get(this, 'model.publication.editions')[0];
  },

  getPlanInfo: function () {
    if (typeof this.getPublication() === "undefined" || this.getPublication() === null) {
      return {};
    }
    if (typeof this.getEdition() === "undefined" || this.getEdition() === null) {
      return {};
    }

    var publication = this.getPublication();
    var edition = this.getEdition();
    return { publication: publication.displayName, date: publication.issueDate, edition: edition.displayName };
  }

});