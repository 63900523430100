import React from 'react';
import utils from './utils';
import Store from './store';
import Toast from './toast';
import { CONTAINERS, DEFAULT_CONTAINER_DIRECTION, DEFAULT_TOAST_DELAY } from './consts';

import ToastsList from './components/ToastsList';
import reducer from './reducer';

const initiateContainersState = (containers = []) => {
  return containers.reduce((state, container = {}) => {
    const containerName = container.name || '';
    const containerDirection = container.direction || DEFAULT_CONTAINER_DIRECTION;
    return Object.assign(state, {
      [containerName]: {
        name: containerName,
        direction: containerDirection,
        toasts: []
      }
    });
  }, {});
};
const initiateState = (containers) => {
  return {
    containers: initiateContainersState(containers)
  };
};

class ToastService {

  constructor(win, containers, toastDelay) {
    this.win = win;
    this.store = new Store(initiateState(containers), reducer, this.render);
    this.containersRoots = utils.createContainersRoots(containers, this.win.document.body);
    this.toastUniqueId = 0;
    this.toastDelay = toastDelay;
  }

  createToast = (containerName, title, message, type, onClick, onClose, delay) => {
    let containers = this.store.getState().containers;
    if (typeof containers[containerName] === 'undefined') {
      console.warn(`Error: There is no Toast container with the name: ${containerName}!`);
      return;
    }
    const container = containers[containerName];
    return new Toast(
      this.toastUniqueId++,
      title,
      message,
      type,
      container.name,
      container.direction,
      onClick,
      onClose,
      typeof delay === 'undefined' ? this.toastDelay : delay,
      this.store,
      this.toastDelay
    );
  };

  successToast = (header = '', body = '', options = {}) => {
    const { onClick, onClose, delay } = options;
    return this.createToast('bottom-right', header, body, 'success', onClick, onClose, delay);
  };

  errorToast = (header = '', body = '', options = {}) => {
    const { onClick, onClose } = options;
    const delay = false;
    return this.createToast('bottom-right', header, body, 'error', onClick, onClose, delay);
  };

  commCenterToast = (header = '', body = '', options = {}) => {
    const { onClick, onClose, delay } = options;
    return this.createToast('bottom-right', header, body, 'commcenter', onClick, onClose, delay);
  };

  chatToast = (header = '', body = '', options = {}) => {
    const { onClick, onClose, delay } = options;
    return this.createToast('bottom-right', header, body, 'chat', onClick, onClose, delay);
  };

  render = () => {
    let state = this.store.getState();
    let containers = state.containers;
    Object.keys(containers).forEach((containerName) => {
      let toasts = containers[containerName].toasts || [];
      this.renderContainer(containerName, this.containersRoots[containerName], toasts);
    });
  };

  renderContainer = (containerName, reactRoot, toasts) => {

    reactRoot.render(<ToastsList name={containerName} toasts={toasts} store={this.store} />);
  };

  destroy = () => {
    Object.keys(this.containersRoots)
      .forEach((containerName) => {
        this.containersRoots[containerName].unmount();
      });
  };

}

const defaultToastService = new ToastService(window, CONTAINERS, DEFAULT_TOAST_DELAY);
module.exports = defaultToastService;

module.exports.createToastService = function (win = window, containers = CONTAINERS, toastDelat = DEFAULT_TOAST_DELAY) {
  if (win === window) return defaultToastService;

  return new ToastService(win, containers, toastDelat);
};