
export const createGhostElement = (itemsToTransfer, cssClass = 'default-ghost-element') => {
  const ghostElement = document.createElement('div');
  ghostElement.classList.add(cssClass);
  if (Array.isArray(itemsToTransfer)) {
    itemsToTransfer.forEach(item => {
      const label = document.createElement('label');
      const br = document.createElement('br');
      label.innerHTML = item.name;
      ghostElement.appendChild(label);
      ghostElement.appendChild(br);
    });
  } else {
    ghostElement.innerHTML = itemsToTransfer.name;
  }

  document.body.appendChild(ghostElement);

  setTimeout(() => {
    document.body.removeChild(ghostElement);
  }, 2000);

  return ghostElement;
}