import React from 'react';
import PropTypes from 'prop-types';
import iconService from 'core/services/iconService';

const DIFF_STATUS_TYPES_ICONS_SUCCESS = {
  'success': { icon: 'success' },
  'waiting': { icon: 'wait' },
  'hold': { icon: 'hold' },
  'processing': { icon: 'in_progress' }
};

function StatusIcon({
  status: { statusType, flowStepType, flowStepIconName } = {},
  style
}) {

  const getStatusIcon = (flowStep, flowStepIconName, statusType) => {

    const statusTypeLowerCase = typeof statusType !== 'undefined' ? statusType.toLowerCase().trim() : undefined;

    let icon = '';

    if (!flowStep || typeof statusType === 'undefined') {
      icon = iconService.getGeneralIcon(null, 'empty');
    } else if (statusTypeLowerCase === 'error') {
      if (flowStep === 'workflow/step/flow/approval') {
        icon = iconService.getModuleIcon('Thumbnail', 'reject');
      } else {
        icon = iconService.getModuleIcon('Thumbnail', 'error');
      }
    } else {
      icon = iconService.getTemplateIcon(flowStep, 'large', flowStepIconName);
    }
    return icon;
  };

  const getStatusTypeIcon = (flowStep, flowStepIconName, statusType) => {

    let statusTypeIcon;

    if (flowStep && typeof statusType !== 'undefined') {
      const statusTypeLowerCase = statusType.toLowerCase().trim();
      if (statusTypeLowerCase === 'error') {
        statusTypeIcon = iconService.getTemplateIcon(flowStep, 'tiny', flowStepIconName);
      } else if (typeof DIFF_STATUS_TYPES_ICONS_SUCCESS[statusTypeLowerCase] !== 'undefined') {
        statusTypeIcon = iconService.getModuleIcon('MyBirdeye', DIFF_STATUS_TYPES_ICONS_SUCCESS[statusTypeLowerCase].icon);
      }
    }

    return statusTypeIcon;
  };

  const statusIcon = getStatusIcon(flowStepType, flowStepIconName, statusType);
  const statusTypeIcon = getStatusTypeIcon(flowStepType, flowStepIconName, statusType);

  return <div className='item-status' style={style}>
    <img className='item-status-icon' src={statusIcon} />
    <div className='item-status-type'>
      <img src={statusTypeIcon} />
    </div>
  </div>;
}

StatusIcon.propTypes = {
  status: PropTypes.object,
  style: PropTypes.object
};

export default StatusIcon;