/**
 * Created by elad on 1/26/2017.
 */
const AbstractAction = require('AbstractAction');
const FolderPropertySetup = require('setups/FolderPropertySetup/FolderPropertySetup').default;
const sandbox = require('sandbox');
const logger = require('logger');
const data = require('base/data');
const translate = sandbox.localization.translate;
let dialogUID = 0;

function isAvailablePropertiesRelevant(availableProperties) {
  let relevant = availableProperties.filter(function(a) {
     return a !== "none";
  });
  return relevant.length > 0;
}

module.exports = AbstractAction.extend({
  isApplicable: function (objs) {
    if (typeof objs === 'undefined') {
      return false;
    }

    return true;
  },
  handleSetupClose: function (dfd, actionArgs) {
    return function (buttonName, state) {
      actionArgs[6].selectedProperty = state.model.selectedProperty;
      sandbox.request.execServerAction.apply(this, actionArgs)
        .done(function () {
          dfd.resolve.apply(this, arguments);
        }.bind(this))
        .fail(function () {
          dfd.reject.apply(this, arguments);
        }.bind(this));
      return;
    }
  },
  execute: function (args) {
    const rest = sandbox.request.rest(this.nwid).rest;
    let obj = (arguments.length > 0) ? args[0] : undefined;
    let dfd = data.deferred();

    if (typeof obj === 'undefined') return;

    rest('folderProperties', `availableProperties?nwid=${obj.nwid}`).then(function (result, status) {
      let availableFolderProperties = result.availableFolderProperties || [];
      let objects = args ? Array.prototype.map.call(args, function (item) {
        return {
          nwid: item.nwid,
          type: item.type
        };
      }) : [];
      let actionArgs = [
        this.nwid,
        this.actionName,
        this.module.nwid,
        this.module.type,
        this.module.projectorId,
        this.parameters.scriptName,
        {
          selectedProperty: undefined,
          objects: objects
        }
      ];
      if (availableFolderProperties.length === 0 || !isAvailablePropertiesRelevant.call(this, availableFolderProperties)) {
        sandbox.request.execServerAction.apply(this, actionArgs)
          .done(function () {
            dfd.resolve.apply(this, arguments);
          }.bind(this))
          .fail(function () {
            dfd.reject.apply(this, arguments);
          }.bind(this));
        return;
      }

      let folderPropertySetup = new FolderPropertySetup(this.handleSetupClose(dfd, actionArgs));
      sandbox.dialog.show({
        title: translate('Restart With Folder Property'),
        width: 500,
        height: 300,
        init: function (content, container) {
          let elementId = 'RestartForm_FolderProperty-' + (dialogUID++);
          sandbox.dom.attr(content, 'id', elementId);
          sandbox.dom.css(content, 'height', '100%');
          folderPropertySetup.element = '#' + elementId;
          folderPropertySetup.container = container;
          folderPropertySetup.initDone();
          folderPropertySetup.firstTickReceived({ model: result });
        }
      });
    }.bind(this));
    return dfd;
  }
});