const React = require('react');
const PropTypes = require('prop-types');
const { Component } = React;

module.exports = class BreadcrumbsLink extends Component {

  static propTypes = {
    item: PropTypes.object,
    index: PropTypes.number,
    onClick: PropTypes.func,
    showArrow: PropTypes.bool
  }

  handleClick = (event) => {
    const item = this.props.item;
    const index = this.props.index;
    this.props.onClick(event, item, index);
  };

  render() {
    const item = this.props.item;
    const index = this.props.index;
    const showArrow = this.props.showArrow;
    const arrowDiv = showArrow ? <div className='crtx-arrow'><img alt='arrow' /></div> : '';

    return <div className='crtx-breadcrumb-link' onClick={this.handleClick} >
      <div className='crtx-link'>
        <a key={index}>{item.displayName}</a>
      </div>
      {arrowDiv}
    </div >
  }
}