/**
 * @fileOverview
 * @author sergey
 */
import AbstractAction from 'AbstractAction';
import sandbox from 'sandbox';
import { getMainStatus } from 'utilities/statuses';

function isObjectApplicable(obj, config) {

  const status = getMainStatus(obj);
  if (typeof status?.progress === 'undefined') {
    return '';
  }
  var statusType = status.statusType;
  var enableErrorObjects = (config.enableErrorObjects === null || typeof config.enableErrorObjects === 'undefined' || config.enableErrorObjects === true);
  if (!enableErrorObjects && statusType === 'Error') {
    return false;
  }
  var progress = parseInt(status.progress, 10);
  var requiredProgress = !config.progress ? 0 : config.progress * 100;
  
  return progress >= requiredProgress;
}

export default AbstractAction.extend({
  dependencies: {
    isApplicable: 'module.selected'
  },

  isApplicable: function (objs) {
    if (typeof objs === 'undefined') {
      return false;
    }
    for (var index = 0; index < objs.length; index++) {
      var obj = objs[index];
      if (typeof obj !== 'undefined') {
        var result = isObjectApplicable(obj, this.config);
        if (result === false) {
          return false;
        }
      } else {
        return false;
      }
    }
    return objs.length === 0 ? false : true;
  },

  execute: function (objs) {
    const rest = sandbox.request.rest(this.nwid);
    var flowStepIds = this.config.flowStepIds;
    var defaultState = this.config.defaultStateChecked;
    const restData = {
      data: flowStepIds.map(flowStepId => ({
        nwid: flowStepId.nwId ? flowStepId.nwId : flowStepId,
        type: 'workflow/step',
        properties: ['name']
      }))
    };

    rest.post('coretex-objects/objects-description', {
      data: JSON.stringify(restData)
    }).then(res => {     
      this.objs = objs;
      var rows = res.data.map(function (item) {        
        return {
          tag: "input",
          type: "checkbox",
          label: item.name,
          checked: defaultState,
          value: item.nwid
        };
    });
    sandbox.dialog.showFormDialog(rows, this.submitSelection, this, sandbox.localization.translate("Multi Route"), sandbox.localization.translate("Route To"), "");
    });   
  },

  submitSelection: function () {
    var nwid, type;
    var viewInstanceNwId = this.module.nwid;
    var deviceNwId = this.module.viewSettings.rootId;
    var projectorId = this.module.projectorId;
    var objs = this.objs;
    var steps = [];
    var j = 0;
    for (var i = 0; i < arguments.length; i++) {
      if (arguments[i].isSelected) {
        steps[j] = arguments[i].value;
        j++;
      }
    }

    var request = sandbox.request.route(viewInstanceNwId, this.nwid, null, null, projectorId,
      {
        config: this.config,
        items: objs,
        parameters: this.parameters,
        name: this.name,
        flowStepIds: steps
      }
    );
    request.then(function (res) {
      var code = res.statusCode;
      if (code !== 200) {
        var message = res.errorMessage;
        if (res !== null) {
          sandbox.dialog.alert(message);
        }
      }
    });
  }
});