/**
 * @fileOverview
 * @author liatz
 */
define(['sandbox', 'AbstractAction', 'logger'], function(sandbox, AbstractAction, logger) {
  'use strict';
  var log = logger.getDefaultLogger();
  return AbstractAction.extend({
    execute: function () {
      var nwid = this.module.viewSettings.rootId;
      var type = this.module.viewSettings.rootType;
      var projectorId = this.module.projectorId;
      return sandbox.request.workflow.startAll(this.nwid, nwid, type, projectorId);
    }
  });
});