import React, { Component } from 'react';
import PropTypes from 'prop-types';
import jsUtils from 'sandbox/jsUtils';
import GridItem from './griditem';
import Form from './form';

// const DEFAULT_SINGALE_FORM_SIZE = [80, 80];
// const DEFAULT_DOUBLE_PAGE_SIZE = [160, 80];
// const DEFAULT_DOUBLE_VERTICAL_PAGE_SIZE = [80, 160];

export default class FormWraper extends Component {
  static contextTypes = {
    module: PropTypes.object
  };

  static propTypes = {
    form: function (props, propName) {
      var value = props[propName];

      if (!jsUtils.isObject(value) || typeof value.type !== 'string' || value.type.toLowerCase() !== 'form') {
        return new Error(propName + ', is not a valid form');

      }
    }
  };

  static defaultProps = {
    form: undefined,
    formDefinition: {},
    alignmentPoints: [],
    gridSize: undefined,
    gridMargin: undefined,
    gridAlign: undefined
  };

  isVisible = (form) => {
    var filterArgument = this.context.module.ctrl.filterArgument;
    if (typeof filterArgument !== 'undefined' && !filterArgument(form)) {
      return false;
    }
    return true;
  };

  onClick = (form, e) => {
    if (typeof this.props.onClick !== 'function') {
      return;
    }
    this.props.onClick(form, e);
  };

  onContextMenu = (form, e) => {
    if (typeof this.props.onContextMenu !== 'function') {
      return;
    }
    this.props.onContextMenu(form, e);
  };

  onDoubleClick = (form, e) => {
    if (typeof this.props.onDoubleClick !== 'function') {
      return;
    }
    this.props.onDoubleClick(form, e);
  };

  render() {
    var {
        form,
        formDefinition,
        alignmentPoints,
        gridSize,
        gridMargin,
        gridAlign,
        onClick,
        onContextMenu,
        onDoubleClick,
        onOpenChange,
        onAggregatedItemClick
      } = this.props,
      controller = this.context.module.ctrl,
      formSize = controller.getFormSize(form),
      isVisible = this.isVisible(form);

    return <GridItem gridSize={gridSize}
      gridMargin={gridMargin}
      gridAlign={gridAlign}
      size={formSize}
      isVisible={isVisible}
      aggregated={form.aggregated}
      itemNwid={form.nwid}
      onOpenChange={onOpenChange}
      onClick={onClick}
      onAggregatedItemClick={onAggregatedItemClick}
      itemDefinition={formDefinition}
      alignmentPoints={alignmentPoints}
      numOfRelatedPages={form.aggregated ? form.relatedPages.length : 0}>

      <Form form={form}
        formSize={formSize}
        gridAlign={gridAlign}
        formDefinition={formDefinition}
        alignmentPoints={alignmentPoints}
        onClick={onClick}
        onContextMenu={onContextMenu}
        onDoubleClick={onDoubleClick}>
      </Form>

    </GridItem>;
  }
}

FormWraper.propTypes = {
  form: PropTypes.object,
  formDefinition: PropTypes.object,
  alignmentPoints: PropTypes.object,
  onOpenChange: PropTypes.func,
  onClick: PropTypes.func,
  onAggregatedItemClick: PropTypes.func,
  onContextMenu: PropTypes.func,
  onDoubleClick: PropTypes.func,
  gridSize: PropTypes.array,
  gridMargin: PropTypes.number,
  gridAlign: PropTypes.string,
};