/**
 * @name TreeNode
 * @file Tree Node component that represents single node of the Tree component
 *
 * @author Boris
 * @since: 2016-12-21
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utilities/classNames';
import Menu from 'components/common/menu/Menu';
import MenuItem from './MenuItem';

const composeKey = (id, index) => {
  return `${id}_${index}`;
};

export default class TreeNode extends Component {
  static propTypes = {
    /**
     * Tree model node object
     */
    node: PropTypes.object.isRequired,

    /**
     * Node path in the tree
     */
    nodePath: PropTypes.string,

    /**
     * Tree node data object that contains properties required for rendering:
     *   text (string): text displayed for the node in the tree
     *   icon (object): node icon (placed before node text) that contains the following properties:
     *     className (string): icon CSS class name
     *     style (object): icon CSS style
     *   trailingIcons (array): node trailing icons (placed after text) with the following properties:
     *     className (string): icon CSS class name
     *     style (object): icon CSS style
     */
    nodeProps: PropTypes.object.isRequired,

    /**
     * Node CSS class name
     */
    className: PropTypes.string,

    /**
     * Select menu item callback function attached to the node
     */
    onSelectMenuItem: PropTypes.func
  };

  static defaultProps = {};

  handleSelectMenuItem = (event, item, index) => {
    const { node, nodePath, onSelectMenuItem } = this.props;

    if (typeof onSelectMenuItem === 'function') {
      onSelectMenuItem(event, node, nodePath, item.id);
    }
  };

  menuItemGetter = (item, index) => {
    return <MenuItem item={item} />;
  };

  renderIcon() {
    const { icon } = this.props.nodeProps;
    if (!icon) {
      return null;
    }
    const className = classNames('crtx-tree-node-icon', icon.className);
    if (icon.type === 'image') {
      return (
        <img src={icon.url} className={className} style={icon.style} />
      );
    } else if (icon.type === 'svg') {
      return (
        <svg className={className} style={icon.style}>
          <title>{icon.title}</title>
          <use xlinkHref={icon.href} />
        </svg>
      );
    } else {
      return (
        <div className={className} style={icon.style} />
      );
    }
  }

  renderLabel() {
    const { nodeProps } = this.props;

    return (
      <div className='crtx-tree-node-label'>
        <div className='crtx-tree-node-label-content' title={nodeProps.text}>
          {nodeProps.text}
        </div>
        {this.renderTrailingIcons()}
      </div>
    );
  }

  renderTrailingIcon = (icon, index) => {
    const key = composeKey(icon.id, index);
    const className = classNames('crtx-tree-node-trailing-icon', icon.className);

    if (icon.url) {
      return (
        <img key={key} className={className} style={icon.style} src={icon.url} title={icon.tooltip} alt='' />
      );
    } else {
      return (
        <div key={key} className={className} style={icon.style} />
      );
    }
  };

  renderTrailingIcons() {
    const { trailingIcons } = this.props.nodeProps;
    if (!Array.isArray(trailingIcons) || trailingIcons.length <= 0) {
      return null;
    }

    return (
      <div className='crtx-tree-node-trailing-icons'>
        {trailingIcons.map(this.renderTrailingIcon)}
      </div>
    );
  }

  renderMenu() {
    const { nodeProps, className } = this.props;
    if (!Array.isArray(nodeProps.menuItems) || nodeProps.menuItems.length <= 0) {
      return null;
    }

    return (
      <Menu
        className={classNames('crtx-tree-node-menu', className)}
        items={nodeProps.menuItems}
        menuItemGetter={this.menuItemGetter}
        onSelect={this.handleSelectMenuItem}
      >
        <i className='material-icons'>arrow_drop_down</i>
      </Menu>
    );
  }

  render() {
    const { className } = this.props;

    return (
      <div className={classNames('crtx-tree-node', className)}>
        {this.renderIcon()}
        {this.renderLabel()}
        {this.renderMenu()}
      </div>
    );
  }

}
