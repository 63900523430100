import utils from '../utils';

export default (options) => {

  const toUserValue = (storeValue) => {
    if (!options) {
      return storeValue;
    }

    var {factor, precision} = options;

    return utils.roundTo(storeValue * factor, precision, 0);
  };

  const fromUserValue = (stateValue) => {
    if (!options) {
      return stateValue;
    }

    var {factor} = options;

    return utils.toNumber(stateValue / factor, 0);
  };

  return {
    toUserValue,
    fromUserValue
  }

};
