/**
 * Returns intersection of two given rectangles.
 *
 * @param {object} r1 - rectangle object with properties: left, top, width, height
 * @param  {object} r2 - rectangle object with properties: left, top, width, height
 * @returns {object} - rectangle that represents the intersection of r1 and r2
 *
 */
export function intersectRect(r1, r2) {
  const left = Math.max(r1.left, r2.left);
  const top = Math.max(r1.top, r2.top);
  const right = Math.min(r1.left + r1.width, r2.left + r2.width);
  const bottom = Math.min(r1.top + r1.height, r2.top + r2.height);

  return {
    left,
    top,
    width: Math.max(0, right - left),
    height: Math.max(0, bottom - top)
  };
}

export function normalizeRect(r = {}, leftKey = 'x', topKey = 'y', widthKey = 'width', heightKey='height') {
  return {left: r[leftKey], top: r[topKey], width: r[widthKey], height: r[heightKey]};
}