/**
 * Created by moshemal on 1/8/15.
 */

import Ember from 'ember';
import $ from 'jquery';
import sandbox from 'sandbox';
import AbstractStep from 'widgets/Wizard/AbstractStep';
import PlanActionsView from './views/PlanActions';
import Controller from './controllers/PlanActionsController';
import { restPut } from 'core/managers/rest2';

function init(initObj) {
  this._super(initObj);
  this.properties.projectorId = this.id;
  this.rootType = initObj.startParameters.rootType;
  this.rootId = initObj.startParameters.rootId;
  this.instance = initObj.startParameters.nwid;
  this.wizard = initObj.wizard;
  this.settings = this.properties.properties;
}

function back() {
  this.wizard.disableButton("cancel");
}

function next() {
  this.wizard.disableButton("cancel");
  window.close();
}

function destroy() { }

function render() {
  var that = this;
  if (this.settings.templateCustomizeNameUrl) {
    restPut(this.instance, `folders/${this.rootId}/planning-actions/getTemplateName/${this.settings.templateCustomizeNameUrl}`, {
      data: JSON.stringify({
        model: this.model.publication
      })
    })
      .then((result) => {
        internalRender.call(that, result);
      })
      .catch(err => {
        internalRender.call(that);
      });
  } else {
    internalRender.call(that);
  }

}

function internalRender(options = {}) {
  var that = this;
  if (typeof this.controller === "undefined" || this.controller === null) {
    this.controller = Controller.create({
      model: that.model,
      projectorId: that.projectorId,
      rootType: that.rootType,
      rootId: that.rootId,
      instance: that.instance,
      wizard: this.wizard,
      templateName: options && options.templateName ? options.templateName : "",
      settings: that.settings || {}
    });
  } else {
    Ember.set(this.controller, 'templateName', options && options.templateName ? options.templateName : "");
    Ember.set(this.controller, 'model', this.model);
  }

  this.wizard.disableButton("cancel");
  this.wizard.disableButton("finish");

  $(this.container).empty();
  this._view = PlanActionsView.create({
    controller: that.controller,
    model: that.model
  }).appendTo(this.container);
}

module.exports = AbstractStep.extend({
  init: init,
  render: render,
  //back:				back,
  next: next,
  destroy: destroy
}, "Structure");