// @flow

const sandbox = require('sandbox');
const { translate } = sandbox.localization;

const Labels = () => {
  return {
    'everyday'  : translate('Everyday'),
    'sunday'    : translate('Sunday'),
    'monday'    : translate('Monday'),
    'tuesday'   : translate('Tuesday'),
    'wednesday' : translate('Wednesday'),
    'thursday'  : translate('Thursday'),
    'friday'    : translate('Friday'),
    'saturday'  : translate('Saturday'),

    'all': translate('All'),
    'broadsheet': translate('Broadsheet'),
    'tabloid': translate('Tabloid'),
  };
};

module.exports = Labels;
