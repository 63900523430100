import React from 'react';
import { createRoot } from 'react-dom/client';
import { startInternalModule } from 'core/managers/module';
import AbstractModule from 'AbstractModule';
import sandbox from 'sandbox';
import { createStore } from 'redux';
import reducer from './reducer';
import Table, { Column, cells, headers } from '../../widgets/ReactDataGrid';
import { createToastService } from 'core/services/toastService';
import Loader from 'components/common/loader/Loader';
import { fromServerDate } from 'core/dates';
import { restGet } from 'core/managers/rest2';
import { ROW_HEIGHT } from 'widgets/ReactDataGrid/utils';

const { SortableHeader } = headers;
const { Text, IconCell } = cells;

const translate = sandbox.localization.translate;
const labels = {
  fileName: translate('File name'),
  receivedTime: translate('Received time'),
  breadcrumb: translate('Breadcrumb'),
  edit: translate('Edit'),
  ignore: translate('Ignore'),
  confirmationIgnoreHeader: translate('Ignore Plan Confirmation'),
  confirmationIgnoreMessage: translate('Are you sure you want to remove this plan file from the list?'),
  missMatched: translate('Miss Matched'),
  partialMissMatched: translate('Partial Miss Matched'),
  error: translate('Error'),
  matched: translate('Matched'),
  matchedByUser: translate('Matched by user')
};

const isUndefined = o => typeof o === 'undefined';
const isFunction = o => typeof o === 'function';

const headerHeight = 35;

const emptyImageSrc = '//:0';
const getErrorIcon = ({ errorType }) => {
  if (errorType === 'MissMatch' || errorType === 'PartialMatching') return sandbox.icons.getModuleIcon('MyBirdeye', 'warning_small');
  if (!isUndefined(errorType)) return sandbox.icons.getModuleIcon('MyBirdeye', 'error_small');

  return emptyImageSrc;
};
const getErrorTitle = ({ errorType }) => {
  if (errorType === 'MissMatch') return labels.missMatched;
  if (errorType === 'PartialMatching') return labels.partialMissMatched;
  if (!isUndefined(errorType)) return labels.error + ', ' + errorType;

  return undefined;
};

const getManualActionIcon = ({ manualAction }) => {
  if (!!manualAction) return sandbox.icons.getModuleIcon('CustomApproval', 'check');

  return emptyImageSrc;
};

const getManualActionTitle = ({ manualAction }) => {
  if (!!manualAction) return labels.matchedByUser;

  return undefined;
};

export default AbstractModule.extend({
  init: function () {
    this._super();
    for (var prop in this) {
      if (prop !== '_super' && isFunction(this[prop])) this[prop] = this[prop].bind(this);
    }
  },

  initDone: function () {
    this.reactRoot = createRoot(this.domElement);
  },

  firstTickReceived: function (data) {
    const mode = data.preferences.mode || 'error';
    this.preferences = data.preferences;

    this.createToolbar();
    this.toolbar.addItem({
      label: labels.missMatched,
      name: 'errorMode',
      checked: mode === 'error',
      _isApplicable: true,
      icon: 'warning_pages',
      tooltip: labels.missMatched,
      itemType: 'push',
      groupName: 'mode',
      execute: this.handleMissMatchExecute
    });

    this.store = createStore(reducer, {
      mode,
      files: [],
      dbRoot: {},
      wizardName: data.config.wizardName,
      loading: true,
      sortKey: 'receivedTime',
      sortOrder: 'asc'
    });
    this.store.subscribe(() => this.render());
    this.render();

    return Promise.all([
      this.getPressPlans(mode),
      this.getDataBaseStructure()
    ])
      .then(res => {
        let files = res[0];
        let publications = res[1];
        this.store.dispatch({
          type: 'FIRST_LOAD',
          files,
          dbRoot: { template: 'root', publications },
          sortKey: 'receivedTime',
          sortOrder: 'asc'
        });
      });
  },

  tickUpdate: function (data) {

  },

  savePreferences: function (preferences) {
    if (!preferences) return;

    this.preferences = Object.assign(this.preferences, preferences);
    sandbox.preferences.savePreferences(this.getRequiredParameters(), this.preferences);
  },

  getPressPlans: function (mode) {
    return restGet(this.nwid, `folders/${this.folderNwid}/match-press-plan/getPressPlans?mode=${mode}`)
      .then(response => response.files);
  },

  getDataBaseStructure: function () {
    return restGet(this.nwid, `folders/${this.folderNwid}/match-press-plan/getDataBaseStructure`)
      .then(response => response.publications);
  },

  handleMissMatchExecute: function (pushed) {
    const mode = pushed ? 'error' : 'all';

    this.store.dispatch({ type: 'SET_LOADING', loading: true });

    this.getPressPlans(mode).then(files => {
      this.store.dispatch({
        type: 'LOAD_FILES',
        files,
        mode
      });
    });

    this.savePreferences({ mode });
  },

  commonBreadcrumbsValue: function (breadcrumbPaths = []) {
    const breadcrumbs = breadcrumbPaths.map(breadcrumbPath => breadcrumbPath.split('/'));

    if (breadcrumbs.length === 0) return '';

    const commonBreadcrumbsLength = breadcrumbs[0].findIndex((breadcrumb, index) => {
      return !breadcrumbs.every(breadcrumbToCheckEqual => breadcrumbToCheckEqual[index] == breadcrumb);
    });

    return commonBreadcrumbsLength < 0 ? breadcrumbs[0].join('/') : breadcrumbs[0].slice(0, commonBreadcrumbsLength).join('/');
  },

  openSetup: function (file) {
    const dataModel = this.store.getState();

    if (file.disabled) return;

    startInternalModule('MapErrorPlanSetup', {
      model: { file, dbRoot: dataModel.dbRoot },
      rootId: '',
      rootType: '',
      name: translate('Map Error Plan'),
      target: 'dialog',
      windowWidth: 575,
      windowHeight: 500,
      moduleNwid: this.nwid,
      win: this.win,
    }, this).then(moduleInstance => {
      moduleInstance.onApply((action, state, response) => {
        const toastService = createToastService(this.win);
        let toast = toastService.createToast('top-right', `Saving plan...`, '', 'regular', undefined, () => toast = undefined, false);

        response.then(result => {
          if (result.status === 'error') {
            const expresionToRemove = 'java.lang.';
            const resultErrorMessage = (result.message.indexOf(expresionToRemove) === 0) ? result.message.slice(result.message.indexOf(':') + 1, result.message.length) : result.message;
            if (toast) toast.update('error', 'Error saving plan', resultErrorMessage);
          }
          else {
            if (toast) toast.update('success', 'Plan saved successfully', '').delay(3000);
            this.store.dispatch({ type: 'SET_LOADING', loading: true });

            this.getPressPlans(this.store.getState().mode).then(files => {
              this.store.dispatch({
                type: 'LOAD_FILES',
                files,
                mode: this.store.getState().mode
              });
            });
          }
        });
      });
    });
  },

  handleColumnClick: function (columnIndex, columnKey) {
    const { sortKey, sortOrder } = this.store.getState();

    this.store.dispatch({
      type: 'SORT_FILES',
      sortKey: columnKey,
      sortOrder: sortKey === columnKey ? sortOrder === 'asc' ? 'desc' : 'asc' : 'asc'
    });
  },

  render: function () {
    const { loading, files, sortKey, sortOrder } = this.store.getState();

    this.reactRoot.render(
      <div className="crtx-map-error-plans">

        <Loader loading={loading} />

        <Table headerHeight={headerHeight}
          rowHeight={ROW_HEIGHT}
          fixed={true}
          rows={files}
          columnKey='nwid'
          selectableRows={true}
          virtualScroll={true}
          win={this.win}
        >

          <Column key="ErrorType"
            columnKey="errorType"
            width={44}
            align="center"
            header={<SortableHeader></SortableHeader>}
            cell={IconCell}
            cellDataGetter={(rowIndex, columnKey) => {
              const item = files[rowIndex];
              const errorIcon = getErrorIcon(item);
              const errorTitle = getErrorTitle(item);

              return {
                icon: errorIcon,
                title: errorTitle,
                iconStyle: { verticalAlign: 'middle' }
              };
            }}
          />

          <Column key="manualAction"
            columnKey="manualAction"
            width={79}
            align="center"
            header={<SortableHeader>{labels.matched}</SortableHeader>}
            cell={IconCell}
            cellDataGetter={(rowIndex, columnKey) => {
              const item = files[rowIndex];
              const icon = getManualActionIcon(item);
              const title = getManualActionTitle(item);

              return {
                icon,
                title,
                iconStyle: { verticalAlign: 'middle' }
              };
            }}
          />

          <Column key="receivedTime"
            columnKey="receivedTime"
            width={200}
            align="left"
            header={<SortableHeader sort={sortKey === 'receivedTime' ? sortOrder : ''}>{labels.receivedTime}</SortableHeader>}
            cell={Text}
            cellDataGetter={(rowIndex, columnKey) => {
              const item = files[rowIndex];

              return {
                columnData: (<span>
                  {isUndefined(item.recievedTime) ? '' : sandbox.localization.toLocaleShortDateTime(fromServerDate(item.recievedTime))}
                </span>),
                title: isUndefined(item.recievedTime) ? ' ' : sandbox.localization.toLocaleShortDateTime(fromServerDate(item.recievedTime))
              };
            }}
            onClick={this.handleColumnClick}
          />

          <Column key="sourceFile"
            columnKey="sourceFile"
            width={150}
            flex="50"
            align="left"
            header={<SortableHeader sort={sortKey === 'sourceFile' ? sortOrder : ''}>{labels.fileName}</SortableHeader>}
            cell={Text}
            cellDataGetter={(rowIndex, columnKey) => {
              const item = files[rowIndex];

              return {
                columnData: (<span>
                  {isUndefined(item.sourceFile) ? '' : item.sourceFile}
                </span>),
                title: isUndefined(item.sourceFile) ? ' ' : item.sourceFile
              };
            }}
            onClick={this.handleColumnClick}
          />

          <Column key="commonBreadcrumbs"
            columnKey="commonBreadcrumbs"
            width={150}
            flex="50"
            align="left"
            header={<SortableHeader sort={sortKey === 'commonBreadcrumbs' ? sortOrder : ''}>{labels.breadcrumb}</SortableHeader>}
            cell={Text}
            cellDataGetter={(rowIndex, columnKey) => {
              const item = files[rowIndex];

              return {
                columnData: item.commonBreadcrumbs
              };
            }}
            onClick={this.handleColumnClick}
          />

          <Column key="edit"
            columnKey="edit"
            width={50}
            align="center"
            header={<SortableHeader></SortableHeader>}
            fixed="right"
            cell={Text}
            cellDataGetter={(rowIndex, columnKey) => {
              const icon = sandbox.icons.getGeneralIcon(null, 'edit');
              const item = files[rowIndex];

              return {
                columnData: (<span>
                  <img src={icon} title={labels.edit} className={item.disabled ? 'disabled' : ''} />
                </span>),
                onClick: (e) => this.openSetup(item),
                title: ' '
              };
            }}
          />

          {/* <Column key="ignore"
            columnKey="ignore"
            width={50}
            align="center"
            header={<SortableHeader></SortableHeader>}
            fixed="right"
            cell={Text}
            cellDataGetter={(rowIndex, columnKey) => {
              const icon = sandbox.icons.getGeneralIcon(null, 'delete');
              const item = files[rowIndex];

              return {
                columnData: (<span>
                  <img src={icon} title={labels.ignore} className={item.disabled ? 'disabled' : ''} />
                </span>),
                onClick: (e) => this.ignoreFile(item),
                title: ' '
              };
            }}
          /> */}

        </Table>

      </div>
    );
  }
});