/**
 * @name
 * @fileOverview
 * @author Moshe Malka
 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import AbstractModule from 'AbstractModule';
import GraphOverview from './views/GraphOverview';
import { TitleView } from './views/TitleView';
import workflowMixin from 'mixins/WorkflowMixin';
import { fetchBreadcrumbs } from 'utilities/breadcrumbs';

module.exports = AbstractModule.extend({
  model: {},
  getTitleName: function (breadcrumbs) {
    const workflowBreadcrumb = breadcrumbs.find(b => b.type === 'workflow');

    return workflowBreadcrumb ? workflowBreadcrumb.name : 'Workflow Overview';
  },
  initDone: function () {
    this.views = {};
  },
  firstTickReceived: function (data) {

    const titleEl = this.domElement.querySelector('.item-title');
    this.reactRoot = createRoot(titleEl);
    const contentEl = this.domElement.querySelector('.item-content');

    this.model = data.model;


    fetchBreadcrumbs(this.model.nwid, this.model.type).then(breadcrumbs => {
      var model = {
        name: this.getTitleName(breadcrumbs)
      };
      const titleView = React.createElement(TitleView, { model });
      this.reactRoot.render(titleView);
    });

    this.views.content = new GraphOverview(
      Object.assign(
        data.model,
        {
          transitions: workflowMixin.fixedTransitions(data.model.transitions, data.model.flowSteps)
        }
      ),
      data.preferences,
      contentEl
    );
    this.views.content.module = this;
    this.views.content.render();
    this.selection.applyGraphSelection(this, this.views.content);
    this.viewLinks = data.model.viewLinks;
  },
  tickUpdate: function (data) {
    //TODO: implement tickUpdate
    //this.view.render();
  },

  destroy: function () {
    this._super();
    this.views.content.destroy();
    delete this.views.content;
  }
});
