import React, { useRef, useCallback } from 'react';
import {noop} from 'base/jsUtils';

export const useValidator = () => {

  const validatorsRef = useRef([]);

  const register = useCallback(validator => {
    let unregister = noop;

    if (validator) {
      validatorsRef.current.push(validator);
      unregister = () => {
        validatorsRef.current = validatorsRef.current.filter(v => v !== validator);
      }
    }

    return unregister;
  }, []);

  const validate = useCallback(() => {
    return validatorsRef.current.every(v => v.validate());
  }, []);

  return { register, validate };
};