/**
 * Created by moshemal on 1/8/15.
 */
import toastService from 'core/services/toastService';
import { translate } from 'core/services/localization';
import React from 'react';
import { createRoot } from 'react-dom/client';
import $ from 'jquery';
import sandbox from 'sandbox';
import AbstractStep from 'widgets/Wizard/AbstractStep';
import manipulator from './modelUtils';
import ReactDOM from 'react-dom';
import ReactComps from './jsx/table';
import ReactNukComps from './jsx/table_nuk';
import ModelHandler from "./model/model";
import PreStepModel from "./model/preStepModel";
import ActionsController from "./actions/actions";
import modelExmp from "./model/modelExmp";
import colorService from "core/services/colorService";
import WizardUtils from './../../utils/wizardUtils';

'use strict';
var LOADING_URL = "../kernel/assets/img/sys/ajax-loader-big.gif";
function resolveDefaultColor(colors) {
  for (var i = 0; i < colors.length; i++) {
    if (colors[i].default) {
      return colors[i];
    }
  }
  return colors[0];
}

function dataRecieved(data) {
  var lockedError = "";
  if (data.firstStep) {
    WizardUtils.initData(this.model, data);
    if (data.LockError) {
      lockedError = data.LockError;
    }
  } else {
    this.model.publication.editions[0] = data.edition;
    this.model.publication.sourceId = data.publicationSourceId;
  }

  if (lockedError) {
    sandbox.dialog.alert(lockedError).then(() => window.close());
    return;
  }

  this.model.publication.LockingGroupId = data.LockingGroupId;
  var zones = this.model.publication.editions[0].zones;
  for (var i = 0; i < zones.length; i++) {
    var planSetupZone = Ember.get(this, 'model.planSetup').getZone({ displayName: this.model.publication.displayName },
      zones[i].displayName);
    if (planSetupZone !== null) {
      zones[i].code = planSetupZone.shortName;
      zones[i].share = planSetupZone.share === 'true';
      zones[i].color = planSetupZone.color;
      if (typeof planSetupZone.parent !== 'undefined' && planSetupZone.parent !== null &&
        planSetupZone.parent !== zones[i].displayName) {
        zones[i].masterZone = planSetupZone.parent;
      }
    }
  }


  internalRender.call(this);
}

function updateZoneInfo() {
  var planSetup = Ember.get(this, 'model.planSetup');
  var publicationDesc = planSetup.getPublication(this.model.publication.displayName);
  var zones = this.model.publication.editions[0].zones;
  for (var i = 0; i < zones.length; i++) {
    var planSetupZone = planSetup.getZone({ displayName: this.model.publication.displayName },
      zones[i].displayName);
    if (planSetupZone !== null) {
      zones[i].code = planSetupZone.shortName;
      zones[i].share = planSetupZone.share === 'true';
      zones[i].color = planSetupZone.color;
      if (typeof planSetupZone.parent !== 'undefined' && planSetupZone.parent !== null &&
        planSetupZone.parent !== zones[i].displayName) {
        zones[i].masterZone = planSetupZone.parent;
      }
    }
  }
}

function init(initObj) {
  this._super(initObj);
  this.wizard = initObj.wizard;
  this.settings = initObj.stepProperties;

  this.properties.projectorId = this.id;
  this.rootType = initObj.startParameters && initObj.startParameters.rootType ?
    initObj.startParameters.rootType : initObj.properties.properties.startParameters.rootType;
  this.rootId = initObj.startParameters && initObj.startParameters.rootId ?
    initObj.startParameters.rootId : initObj.properties.properties.startParameters.rootId;

  this.instance = initObj.startParameters && initObj.startParameters.nwid ? initObj.startParameters.nwid :
    initObj.properties.properties.startParameters.nwid;
}

function applyChanges() {
  return ModelHandler.convertFromStepModel(this.model, this.properties.properties);
}

function back() {
  var ret = applyChanges.call(this);
  if (!ret) {
    return false;
  }
  ActionsController.actionController({}, "cleanUi", []);
  this.destroy();
  return true;
}

function next() {
  var ret = applyChanges.call(this);
  if (!ret) {
    return false;
  }
  ActionsController.actionController({}, "cleanUi", []);
  this.destroy();
  return true;
}

function destroy() {
  if (ModelHandler.reactRoot) {
    ModelHandler.reactRoot.unmount();
    ModelHandler.reactRoot = null;
  }
}

function handleClick(event, action, args) {
  return ActionsController.actionController(event, action, args);
}

function render() {
  if (!this.model.publication) {
    $(this.container).empty();
    $(this.container).append($('<div class="wizard-loading-img"><img src="' + LOADING_URL + '"></div>'));

    return;
  }

  updateZoneInfo.call(this);
  this.wizard.disableButton("cancel");
  //this.model = JSON.parse(modelExmp.model);
  var that = this;
  var k = colorService.getColorsByColorType("Black");
  var c = colorService.getColorsByColorType("Cyan");
  var m = colorService.getColorsByColorType("Magenta");
  var y = colorService.getColorsByColorType("Yellow");
  var colorObjects = { "black": resolveDefaultColor(k).name, "cyan": resolveDefaultColor(c).name, "magenta": resolveDefaultColor(m).name, "yellow": resolveDefaultColor(y).name };
  ModelHandler.setModel(that.model);
  ModelHandler.container = $(this.container);
  ModelHandler.reactRoot = createRoot(this.container);
  PreStepModel.validModel(that.model, colorObjects);
  var model = ModelHandler.convertToStepModel(that.model, this.properties.properties);
  this.model.publication.wizardCustomProps = this.properties.properties.customProperties;

  var chaseMode = this.model.publication.editions[0].baseEdition !== null &&
    typeof this.model.publication.editions[0].baseEdition !== 'undefined';



  this.model.publication.colorObjects = colorObjects;
  ActionsController.modelHandler = ModelHandler;
  //if (this.properties.properties.customProperties)
  ActionsController.stepProperties = this.properties.properties;
  var that = this;
  //ReactComps.contents = model.contents;
  console.log(this.settings);
  var nukMode = this.settings.pagesView === "nuk";
  var reactDomComps = nukMode ? ReactNukComps : ReactComps;
  var obj = ModelHandler.reactRoot.render(
    React.createElement(reactDomComps.PagesTable, {
      sections: model.sections,
      zones: model.zones,
      colorMode: "K",
      actionController: handleClick,
      chaseMode: chaseMode,
      mapPages: model.mapPages,
      colorObjects: colorObjects
    }));
}

module.exports = AbstractStep.extend({
  init: init,
  load: async function () {

    var loadMode = this.model.editionWasLoaded || this.model.chaseMode;
    if (!loadMode) {

    } else {
      var that = this;

      this.model["editionWasLoaded"] = false;
      var editionNwid = this.model["editionToLoad"];
      var edition = this.model.publication.editions[0];
      var editionToChase = this.model["editionToChase"];
      var chaseMode = this.model["chaseMode"];
      var chaseName = this.model.publication.editions[0].displayName;
      var pagesOnly = this.model.clientSettings.pagesOnly || false;
      var params = {
        rootId: that.rootId,
        rootType: that.rootType,
        edition: editionNwid,
        editionModel: edition,
        editionWasLoaded: true,
        editionToChase: editionToChase,
        chaseMode: chaseMode,
        chaseName: chaseName,
        pagesOnly: pagesOnly
      };

      delete this.model.editionWasLoaded;
      delete this.model.editionNwid;
      delete this.model.zones;
      delete this.model.chaseMode;
      delete this.model.editionToChase;
      var res = await sandbox.request.getPlan(this, that.rootId, null, this.id, params);


      if (res.data.error) {
        toastService.errorToast(translate('Planning Wizard'), res.data.error);
        return;
      }


      this.model.publication.editions[0] = res.data.edition;
      this.model.publication.sourceId = res.data.publicationSourceId;
    }

    return new Promise((resolve, reject) => {
      resolve();
    });

  },
  render: render,
  back: back,
  next: next,
  // load: load,
  destroy: destroy
}, "Pages");
