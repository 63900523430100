import ServerAction from './ServerAction';

export default ServerAction.extend({

  isApplicable: function (objs) {
    let ret = false;
    const { objectsType = 'all' } = this.config || {};
    if (typeof objs !== 'undefined' && objs.length > 0) {
      if (objs[0].type !== 'page' && objs[0].type !== 'form') {
        ret = true;
      } else if (objectsType === 'all' ||
        objs[0].type === 'page' && objectsType === 'pages' ||
        objs[0].type === 'form' && objectsType === 'forms') {
        for (const obj of objs) {
          if (obj.pageContent && obj.pageContent.isPreApproved || obj.formContent && obj.formContent.isPreApproved || obj.content && obj.content.isPreApproved) {
            ret = true;
            break;
          }
        }
      }
    }
    return ret;
  }
});