import React from 'react';
import PropTypes from 'prop-types';
import Table, { Column, headers } from 'widgets/ReactDataGrid';
import { ROW_HEIGHT, HEADER_HEIGHT } from 'widgets/ReactDataGrid/utils';

const { GenericHeader, HeaderCaption } = headers;

const MIN_COLUMN_WIDTH = 20;

export const View = ({ module, viewModel, renamingRulesTableColumns = [] }) => {

  const renderRuleColumnHeaderCaption = column => {
    return (
      <HeaderCaption tooltip={column.caption} sortable={typeof column.sortType !== 'undefined'}>
        {column.caption}
      </HeaderCaption>
    );
  };

  const renderRuleColumnHeader = column => {
    return (
      <GenericHeader
        captionRenderer={renderRuleColumnHeaderCaption(column)}
      />
    );
  };

  return (
    <div className='crtx-form-section-container crtx-form-full-height'>
      <Table
        rows={viewModel.renamingRules}
        columnKey='id'
        virtualScroll={true}
        fixed={true}
        minColumnWidth={MIN_COLUMN_WIDTH}
        headerHeight={HEADER_HEIGHT}
        rowHeight={ROW_HEIGHT}
        onSelect={selectedRows => module.handleRenamingRulesTableSelect(selectedRows)}
        onRowContextMenu={(rowIndex, rowContent, selectedRows, e) => module.handleRenamingRulesTableContextMenu(rowContent, selectedRows, e)}
        onDoubleClickRow={(rowIndex, rule, e) => module.handleRuleDoubleClick(rule)}
        onDeleteKey={(selectedRows, e) => module.handleRenamingRulesTableDeleteKey(selectedRows, e)}
      >
        {
          renamingRulesTableColumns.map(col => {
            return <Column
              key={col.key}
              title={col.caption}
              columnKey={col.key}
              resizable={col.resizable}
              width={col.width}
              align={col.align}
              visible={col.visible}
              header={renderRuleColumnHeader(col)}
              cell={col.cell}
              cellDataGetter={col.cellDataGetter}
            />
          })
        }
      </Table>
    </div>
  );
};

View.propTypes = {
  module: PropTypes.object,
  viewModel: PropTypes.object,
  renamingRulesTableColumns: PropTypes.array
};