define([], function() {
  "use strict";

  var WHITE_COLOR = '#ffffff',
    BLACK_COLOR = '#000000';

  function getRgbFromHex (hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16)
    ] : null;
  }

  function getRgbFromString (str) {
    return str.replace(/[^\d,]/g, '').split(',');
  }

  function toRGB (str) {
    if (str.indexOf('#') >= 0){
      return this.getRgbFromHex(str.replace('#', ''));
    }
    return this.getRgbFromString(str);
  }

  function getContrastColor (rgb) {
    var contrast = Math.round(((parseInt(rgb[0]) * 299) + (parseInt(rgb[1]) * 587) + (parseInt(rgb[2]) * 114)) /1000);

    if (contrast > 125) {
      return BLACK_COLOR;
    }
    return WHITE_COLOR;
  }

  return {
    black: BLACK_COLOR,
    white: WHITE_COLOR,
    getRgbFromHex: getRgbFromHex,
    getRgbFromString: getRgbFromString,
    toRGB: toRGB,
    getContrastColor: getContrastColor
  };
});