import PropTypes from 'prop-types';
import React, { Component } from 'react';

class TextareaInput extends Component {
    static propTypes = {
        id: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        placeholder: PropTypes.string,
        className: PropTypes.string,
        title: PropTypes.string,
        disabled: PropTypes.bool,
        style: PropTypes.object,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        onKeyDown: PropTypes.func,
        onKeyPress: PropTypes.func,
        rows: PropTypes.number,
        cols: PropTypes.number,
        focus: PropTypes.func,
        autoFocus: PropTypes.bool,
        maxLength: PropTypes.number,
    }

    static defaultProps = {
        id: '',
        name: '',
        value: '',
        title: '',
        disabled: false,
        onChange: () => { },
    }

    constructor(props) {
        super(props);
    }

    handleChange = (event) => {
        const { onChange } = this.props;
        onChange(event, event.target.value);
    };

    focus() {
        if (this.textareaRef) {
          this.textareaRef.focus();
        }
      }

      componentDidMount() {
        const {autoFocus} = this.props;
        if (autoFocus) {           
          this.focus();
        }
      }

    render() {
        const { id, name, value, placeholder, className, title, disabled, style, onBlur, onFocus, onKeyDown, onKeyPress, onKeyUp, rows, cols, maxLength, ...props } = this.props;
        const disabledClassName = disabled ? 'disabled' : '';
        return (
            <textarea
                ref={el => this.textareaRef = el}
                id={id}
                name={name}
                value={value}
                placeholder={placeholder}
                className={`crtx-textarea-input ${className} ${disabledClassName}`}
                title={title}
                disabled={disabled}
                style={style}
                onBlur={onBlur}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                onKeyPress={onKeyPress}
                onChange={this.handleChange}
                rows={rows}
                cols={cols}
                maxLength={maxLength}
            />
        )
    }
};

module.exports = TextareaInput;