/**
 * @name Toggle Device Action
 * @fileOverview Makes request to the server to change
 * the state of a given device: turn on, turn off
 * @author sergey
 */

define(['sandbox', 'AbstractAction', 'logger'], function(sandbox, AbstractAction, logger) {
  'use strict';
  var log = logger.getDefaultLogger();
  return AbstractAction.extend({
    execute: function (objs) {
      var result;
      var viewInstanceNwId = this.module.nwid;
      var projectorId = this.module.projectorId;
      for (var i= 0, leni = objs.length; i<leni; i++){
        result = sandbox.request.toggleDevice(viewInstanceNwId, this.nwid, objs[i].nwid,
            objs[i].type, projectorId, {state: null});
      }

      return result;
    }
  });
});