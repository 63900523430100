import React from 'react';
import PropTypes from 'prop-types';
import { round } from 'utilities/math';
import { classNames } from 'utilities/classNames';

const toDisplayValue = (value, factor, decimalPlaces) => {
  return round(Number(value) * factor, decimalPlaces);
};

export default function ConversionOutput(
  {
    value = 0,
    unitSymbol,
    factor = 1,
    decimalPlaces = 4,
    className,
    style,
  }) {

  const displayValue = toDisplayValue(value, factor, decimalPlaces);

  return (
    <div className={classNames('crtx-conversion-output', className)} style={style}>
      <div>{displayValue}</div>
      {unitSymbol && <div>{unitSymbol}</div>}
    </div>
  );
}

ConversionOutput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  unitSymbol: PropTypes.string,
  factor: PropTypes.number,
  decimalPlaces: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
};
