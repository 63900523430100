import { COMPARE_TYPE } from 'core/comparators';
import { cells } from "widgets/ReactDataGrid/";
import { toLocaleShortDateTime } from 'core/services/localization';
import { applyColumnPreferences, FILTER } from 'widgets/ReactDataGrid/utils';
import { createObjectComparator } from 'core/comparators';
import { translate } from 'core/services/localization';

const { Text } = cells;

export const makeAuditsTableColumns = (model, module) => {
  const columnPrefs = module.preferences.table?.auditColumns || [];

  const createActionOptions = (filteredAudits) => {
    const mergedActions = filteredAudits.reduce((acc, audit) => {

      if (audit.action && !acc[audit.action]) {
        acc[audit.action] = true;
      }
      return acc;
    }, []);
    const filterOptions = Object.keys(mergedActions).map(name => ({
      value: name,
      data: { columnData: name }
    }));

    return filterOptions.sort(createObjectComparator('value'));
  };

  let columns = [

    {
      key: 'time',
      caption: translate('Time'),
      width: 150,
      resizable: true,
      cell: Text,
      sortType: COMPARE_TYPE.DATES,
      filter: FILTER.DATE,

      cellDataGetter: (rowIndex, key) => {
        const date = model.filteredAudits[rowIndex]?.time || '';

        return {
          columnData: toLocaleShortDateTime(date),
        };
      }
    },
    {
      key: 'userName',
      width: 150,
      caption: translate('User Name'),
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      cellDataGetter: (rowIndex, key) => {
        const userName = model.filteredAudits[rowIndex]?.userName || '';

        return {
          columnData: userName,
        };
      }
    },
    {
      key: 'action',
      width: 200,
      caption: translate('Action Type'),
      cell: Text,
      filter: {
        options: createActionOptions(model.filteredAudits),
        ...FILTER.MULTISELECT_TEXT,
      },
      cellDataGetter: (rowIndex, key) => {
        const action = model.filteredAudits[rowIndex]?.action || '';

        return {
          columnData: action,
        };
      }
    },
    {
      key: 'breadcrumbs',
      width: 300,
      caption: translate('Breadcrumbs'),
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      cellDataGetter: (rowIndex, key) => {
        const breadcrumbs = model.filteredAudits[rowIndex]?.breadcrumbs || '';

        return {
          columnData: breadcrumbs,
        };
      }
    },
    {
      key: 'comment',
      width: 300,
      caption: translate('Comment'),
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      cellDataGetter: (rowIndex, key) => {
        const comment = model.filteredAudits[rowIndex]?.comment || '';

        return {
          columnData: comment,
        };
      }
    },
    {
      key: 'userIP',
      width: 150,
      caption: translate('User IP'),
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      cellDataGetter: (rowIndex, key) => {
        const userIP = model.filteredAudits[rowIndex]?.userIP || '';

        return {
          columnData: userIP,
        };
      }
    },
    {
      key: 'flowStepName',
      width: 150,
      caption: translate('Flow Step'),
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      cellDataGetter: (rowIndex, key) => {
        const flowStepName = model.filteredAudits[rowIndex]?.flowStepName || '';

        return {
          columnData: flowStepName,
        };
      }
    },
    {
      key: 'folders',
      width: 300,
      caption: translate('Folders'),
      cell: Text,
      filter: {
        options: createFoldersOptions(model.filteredAudits),
        ...FILTER.MULTISELECT_TEXT,
      },
      sortValueGetter: getFolders,
      cellDataGetter: (rowIndex, key) => {
        const folders = getFolders(model.filteredAudits[rowIndex]);

        return {
          columnData: folders,
        };
      }
    }
  ];

  columns = applyColumnPreferences(columns, columnPrefs);

  return columns;
};

const getFolders = audit => audit && audit.folders.join(', ');

const createFoldersOptions = (audits) => {

  const mergedFolders = audits.reduce((acc, audit) => {

    audit.folders.forEach(folder => {
      if (!acc[folder]) {
        acc[folder] = true;
      }
    });

    return acc;
  }, []);

  const filterOptions = Object.keys(mergedFolders).map(folderName => ({
    value: folderName,
    data: { columnData: folderName }
  }));

  return filterOptions.sort(createObjectComparator('value'));
}



