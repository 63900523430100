import React, { Component } from 'react';
import PropTypes from 'prop-types';

// The radius of the spinner in pixels
const RADIUS = 20;

class Spinner extends Component {
  static propTypes = {
    /**
     * CSS class name
     */
    className: PropTypes.string,

    /**
     * Override the inline-style
     */
    style: PropTypes.object,

    /**
     * Stroke width in pixels
     */
    strokeWidth: PropTypes.number,
  };

  static defaultProps = {
    strokeWidth: 4
  };

  render() {
    const { style, strokeWidth } = this.props;
    const className = `crtx-spinner ${this.props.className || ''}`;
    const diameter = RADIUS * 2 + strokeWidth;
    const viewBox = `0 0 ${diameter} ${diameter}`;

    return (
      <svg className={className} style={style} viewBox={viewBox}>
        <circle cx={diameter / 2}
          cy={diameter / 2}
          r={RADIUS}
          fill='none'
          strokeWidth={strokeWidth}
          strokeMiterlimit='10'
        />
      </svg>
    );
  }
}

export default Spinner;
