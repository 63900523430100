import React from 'react';
import PropTypes from 'prop-types';

export default class TextComponent extends React.Component {
  static defaultProps = {
    className: ''
  };

  render() {
    const { children, style, className } = this.props;
    const cssClass = `Text ${className || ''}`;

    return (
      <div title={children} className={cssClass} style={style}>
        {children}
      </div>
    );
  }
}

TextComponent.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string
};