define(['react', 'prop-types'],
  function (React, PropTypes) {
    'use strict';

    const {Component} = React;

    return class Button extends Component {

      static propTypes = {
        text: PropTypes.string,
        tooltip: PropTypes.string,
        imageSrc: PropTypes.string,
        className: PropTypes.string,
        textClassName: PropTypes.string,
        iconClassName: PropTypes.string,
        style: PropTypes.object,
        onClick: PropTypes.func,
        disabled: PropTypes.bool,
        iconName: PropTypes.string,
      }

      static defaultProps = {
        tooltip: '',
        text: '',
        className: 'button',
        textClassName: 'button-text',
        iconClassName: 'button-icon',
        disabled: false
      }

      constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.renderText = this.renderText.bind(this);
        this.renderImage = this.renderImage.bind(this);
      }

      handleClick(e) {
        var {onClick, disabled} = this.props;

        if (typeof onClick === 'function' && !disabled) {
          onClick();
          e.preventDefault();
          e.stopPropagation();
        }
      }

      renderText() {
        var {text, textClassName} = this.props;

        if (!text) {
          return null;
        }

        return (
          <span className={textClassName}>
            {text}
          </span>
        );
      }

      renderImage() {
        var {imageSrc, iconName} = this.props;

        return !imageSrc ? (!iconName ? null : <i className="material-icons">{iconName}</i>) : <img src={imageSrc} />;
      }

      render() {
        var {tooltip, className, iconClassName, style, disabled} = this.props;

        if (disabled) {
          className += ' disabled';
        }

        return (
          <span className={className} title={tooltip} style={style} onClick={this.handleClick}>
            <span className={iconClassName}>
              {this.renderImage()}
            </span>
            {this.renderText()}
          </span>
        );
      }

    }

  });