import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utilities/classNames';
import SvgIcon from './SvgIcon';

function IconButton(
  {
    iconName,
    iconClassName,
    iconStyle,
    className,
    style,
    disabled = false,
    scale = true,
    bubbles = true,
    tooltip,
    onClick
  }) {

  const isInactive = () => {
    return typeof onClick !== 'function';
  };

  const handleClick = event => {
    if (disabled || isInactive()) {
      return;
    }

    if (!bubbles) {
      event.stopPropagation();
    }

    onClick(event);
  };

  return (
    <div
      tabIndex={-1}
      title={tooltip}
      className={classNames('crtx-icon-button', {
        disabled,
        inactive: isInactive(),
        scale: scale && !disabled && !isInactive()
      }, className)}
      style={style}
      onClick={handleClick}
    >
      <SvgIcon name={iconName} className={iconClassName} style={iconStyle} />
    </div>
  );
}

IconButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  iconClassName: PropTypes.string,
  iconStyle: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  scale: PropTypes.bool,
  bubbles: PropTypes.bool,
  tooltip: PropTypes.string,
  onClick: PropTypes.func
};

export default IconButton;
