import React, { Component } from 'react';
import PropTypes from 'prop-types';

module.exports = class Properties extends Component {
  constructor(props) {
    super(props);
  }
  static propTypes = {
    properties: PropTypes.any
  }

  render() {
    const { properties } = this.props;
    const propertiesArray = [];
    for (var prop in properties) {
      const property = <span className='property-row-content' key={prop}><span className='property-row-content-name ellipsis' title={prop}>{prop} </span><span className='property-row-content-value ellipsis' title={properties[prop]}>{properties[prop]}</span></span>;
      propertiesArray.push(property);
    }
    return <span>{propertiesArray}</span>;
  }

}