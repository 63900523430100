/* @flow weak */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import actions from '../actions';
import Popup from 'components/common/popup/Popup';
import Page from './Page';
import ColorCheckbox from 'components/common/inputs/ColorCheckbox';
import SimpleForm from 'widgets/SimpleForm/src/index';
import utils from '../utils';
import * as UI from '.././constants/ui';
import FormTemplate from './FormTemplate';
import dialogService from 'core/services/dialogService';
import toastService from 'core/services/toastService';
import sandbox from 'sandbox';

const futils = SimpleForm.utils;
const {translate} = sandbox.localization;

const COPY_VALUES_IN_BOOK_OPTIONS = [
  {value: 'all_forms_in_book', text: translate('Copy values to all formes')},
  // {value: 'all_odd_forms_in_book', text: translate('Copy values to all odd formes')},
  // {value: 'all_even_forms_in_book', text: translate('Copy values to all even formes')},
];

const COPY_VALUES_IN_ALL_BOOKS_OPTIONS = [
  {value: 'all_forms_in_book', text: translate('Copy values to all formes in this book')},
  {value: 'all_forms_in_all_books', text: translate('Copy values to all formes in all books')},
  // {value: 'all_odd_forms_in_book', text: translate('Copy values to all odd formes in this book')},
  // {value: 'all_odd_forms_in_all_books', text: translate('Copy values to all odd formes in all books')},
  // {value: 'all_even_forms_in_book', text: translate('Copy values to all even formes in this book')},
  // {value: 'all_even_forms_in_all_books', text: translate('Copy values to all even formes in all books')},
];

const SAVE_VALUES_OPTIONS = [
  {value: 'save_values_to_correction_group', text: translate('Save values to a correction group')},
];

module.exports = class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuVisible: false,
      menuLeft: 0,
      menuTop: 0
    };
  }

  static propTypes = {
    store: PropTypes.any,
    form: PropTypes.object,
    path: PropTypes.string
  };

  calcHLineRect(page) {
    const left = UI.FORM_BORDER_WIDTH + UI.FORM_PADDING_LEFT + page.column * (UI.PAGE_WIDTH + UI.PAGE_COLUMN_GAP);
    const top = UI.FORM_BORDER_WIDTH + UI.FORM_HEADER_HEIGHT + (page.row + 1) * (UI.PAGE_HEIGHT + UI.PAGE_ROW_GAP) - UI.PAGE_ROW_GAP / 2;
    const width = page.columnSpan * (UI.PAGE_WIDTH + UI.PAGE_COLUMN_GAP) - UI.PAGE_COLUMN_GAP;
    const height = 1;

    return {left, top, width, height};
  }

  renderHLine(form, page) {
    if (page.row + page.rowSpan >= form.rows) {
      return null;
    }

    const r = utils.addPx(this.calcHLineRect(page));

    return (

      <div
        style={{position: 'absolute', left: r.left, top: r.top, width: r.width, borderTop: r.height + ' solid white'}}>
      </div>
    );
  }

  calcVLineRect(page) {
    const left = UI.FORM_BORDER_WIDTH + UI.FORM_PADDING_LEFT + (page.column + 1) * (UI.PAGE_WIDTH + UI.PAGE_COLUMN_GAP) - UI.PAGE_COLUMN_GAP / 2;
    const top = UI.FORM_BORDER_WIDTH + UI.FORM_HEADER_HEIGHT + page.row * (UI.PAGE_HEIGHT + UI.PAGE_ROW_GAP);
    const height = page.rowSpan * (UI.PAGE_HEIGHT + UI.PAGE_ROW_GAP) - UI.PAGE_ROW_GAP;
    const width = 1;

    return {left, top, width, height};
  }

  renderVLine(form, page) {
    if (page.column + page.columnSpan >= form.columns) {
      return null;
    }

    const r = utils.addPx(this.calcVLineRect(page));

    return (

      <div style={{
        position: 'absolute',
        left: r.left,
        top: r.top,
        height: r.height,
        borderLeft: r.width + ' solid white'
      }}>
      </div>
    );
  }

  calcPageRect(page) {
    const left = UI.FORM_BORDER_WIDTH + UI.FORM_PADDING_LEFT + page.column * (UI.PAGE_WIDTH + UI.PAGE_COLUMN_GAP);
    const top = UI.FORM_BORDER_WIDTH + UI.FORM_HEADER_HEIGHT + page.row * (UI.PAGE_HEIGHT + UI.PAGE_ROW_GAP);

    return {left: left, top: top, width: UI.PAGE_WIDTH, height: UI.PAGE_HEIGHT};
  }

  renderPage = (page, index) => {
    const {store, form} = this.props;
    const path = futils.compose(this.props.path, 'pages', index);
    const key = `${page.nwid}_${index}`;

    const pr = utils.addPx(this.calcPageRect(page));
    const style = {
      left: pr.left,
      top: pr.top,
      width: pr.width,
      height: pr.height
    };

    return (
      <div key={key}>
        <Page store={store} form={form} page={page} path={path} style={style}/>
        {this.renderHLine(form, page)}
        {this.renderVLine(form, page)}
      </div>
    );
  };

  calcFormContentRect(form) {
    const left = UI.FORM_BORDER_WIDTH + UI.FORM_PADDING_LEFT;
    const top = UI.FORM_BORDER_WIDTH + UI.FORM_HEADER_HEIGHT;
    const width = form.columns * (UI.PAGE_WIDTH + UI.PAGE_COLUMN_GAP) - UI.PAGE_COLUMN_GAP;
    const height = form.rows * (UI.PAGE_HEIGHT + UI.PAGE_ROW_GAP) - UI.PAGE_ROW_GAP;

    return {left, top, width, height};
  }

  calcFormRect(contentRect) {
    const left = 0;
    const top = 0;
    const width = contentRect.width + UI.FORM_PADDING_LEFT + UI.FORM_PADDING_RIGHT + 2 * UI.FORM_BORDER_WIDTH;
    const height = contentRect.height + UI.FORM_HEADER_HEIGHT + UI.FORM_FOOTER_HEIGHT + 2 * UI.FORM_BORDER_WIDTH;

    return {left, top, width, height};
  }

  handleContextMenu = (event) => {
    //console.log("handleContextMenu => event", event);
    this.setState(
      {
        menuVisible: true,
        menuLeft: event.clientX + 1,
        menuTop: event.clientY + 1
      }
    );
  };

  handleCopyValuesClick = (option) => {
    this.props.store.dispatch(actions.copyValuesToOtherForms(this.props.path, option));
    this.closePopup();
  };

  handleSaveValuesClick = () => {
    const {store, form, book} = this.props;
    const state = store.getState();
    const formTemplates = (state.model.formTemplates || []).filter(t => t.layoutType === book.layoutType);

    dialogService.showOkCancelDialog({
      form,
      formTemplates
    }, this.formTemplateGetter, {title: translate('Default fanout values group'), width: 400, height: 180})
      .then(model => {
        return this.saveAsFormFanoutTemplate(model);
      })
      .catch(reason => {
        if (reason !== 'cancel') {
          console.error('Cannot save default fanout group:', reason);
        }
      });

    this.closePopup();
  };

  formTemplateGetter(props) {
    return <FormTemplate {...props} />;
  }

  saveAsFormFanoutTemplate(model) {
    console.log('saveAsFormFanoutTemplate() => model=', model);
    const {store, book} = this.props;
    const form = {...this.props.form, role: model.role};
    const state = store.getState();

    var params = {
      action: 'saveAsFormFanoutTemplate',
      command: 'getLayouManagerActions',
      rootId: state.nwid,
      type: state.type,
      layoutType: book.layoutType,
      name: model.name,
      form: JSON.stringify(form)
    };
    return sandbox.request.genericRequestPromise(params)
      .then(data => {
        toastService.createToast('top-right', translate('The default fanout group has been saved'), '', 'success', undefined, undefined, 2000);

        if (!model.templateExists) {
          this.addFormTemplate(model.name, book.layoutType, form);
        }
      });
  }

  addFormTemplate(name, layoutType, form) {
    this.props.store.dispatch(actions.addFormTemplate(name, layoutType, form));
  }

  closePopup = () => {
    this.setState(
      {menuVisible: false}
    );
  };

  handleDirectChange = (separation, index, checked) => {
    //console.log("handleDirectChange =>", checked);
    const {store, path} = this.props;
    store.dispatch(actions.update(futils.compose(path, 'separations', index, 'direct'), checked));
  };

  renderSeparation = (separation, index) => {
    const {store} = this.props;
    const state = store.getState();

    const key = separation.colorName || index;
    const color = utils.getColorValue(state.model.colorTable, separation.colorName);
    const checked = separation.direct || false;
    const className = 'direct-color-checkbox';

    return (
      <ColorCheckbox key={key}
                     className={className}
                     checked={checked}
                     color={color}
                     title={separation.colorName}
                     onChange={(e, checked) => this.handleDirectChange(separation, index, checked)}
      />
    );
  };

  renderMenuItem = (item, index) => {
    const onClick = () => {
      return item.value === 'save_values_to_correction_group' ?
        this.handleSaveValuesClick(item.value) :
        this.handleCopyValuesClick(item.value);
    };

    return (
      <li key={item.value} className='menu-item' onClick={onClick}>{item.text}</li>
    );
  };

  renderContextMenu = () => {
    const {store, book} = this.props;
    const state = store.getState();
    const copyOptions = state.model.books.length <= 1 ? COPY_VALUES_IN_BOOK_OPTIONS : COPY_VALUES_IN_ALL_BOOKS_OPTIONS;
    const menuOptions = book.layoutType === 'custom' ? copyOptions : copyOptions.concat(SAVE_VALUES_OPTIONS);

    return (
      <ul className='fanout-view-context-menu'>
        {menuOptions.map(this.renderMenuItem)}
      </ul>
    );
  };

  render() {
    const {store, form, path} = this.props;
    const state = store.getState();
    const pages = form.pages || [];
    const separations = form.separations || [];

    const fcr = this.calcFormContentRect(form);
    const fr = utils.addPx(this.calcFormRect(fcr));
    const changed = form.nwid && futils.get(state, futils.compose('changedItems', form.nwid));
    const changedMark = changed ? '*' : '';
    const title = form.name + changedMark;

    return (
      <div className='form' style={{width: fr.width, height: fr.height}} onContextMenu={this.handleContextMenu}>
        <div className='form-header' style={{width: fr.width, height: UI.FORM_HEADER_HEIGHT + 'px'}}>
          <div className='direct-label'>{translate('Direct:')}</div>
          {separations.map(this.renderSeparation)}
        </div>
        {pages.map(this.renderPage)}
        <div className='form-footer' style={{width: fr.width}}>
          {title}
        </div>
        <Popup left={this.state.menuLeft} top={this.state.menuTop} width={300} visible={this.state.menuVisible}
               onClickedOutside={this.closePopup}>
          {this.state.menuVisible ? this.renderContextMenu() : null}
        </Popup>
      </div>
    );
  }

};
