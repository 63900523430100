/**
 * @name cell
 * @file Single cell on the Cell Grid
 *
 * @author Boris
 * @since: 2016-08-11
 */

import React from 'react';
import Fabric from 'fabric';
import ReactComponents from 'widgets/ReactComponents/src/index';
import sandbox from 'sandbox';
import SimpleForm from 'widgets/SimpleForm/src/index';
import utils from '../utils/utils';
import canvasUtils from '../utils/canvasUtils';
import propertiesCommon from './propertiesCommon';
import unplannedPages from './unplannedPages';
import actions from '../redux/actions';

const {translate} = sandbox.localization;
const futils = SimpleForm.utils;

const BASE_PATH = 'layout.cellGrid.cells';
const TITLE = translate('Cell');

const CELL_FILL_COLOR = '#e2e1d4';
const CELL_STROKE = '1px solid grey';
const TRIM_BOX_STROKE = '#880015';
const BLEED_BOX_STROKE = '#145000';
const XSMALL_BUTTON_CLASS = 'btn btn-default btn-xs';

const CELL_OPACITY = 0.8;
const DRAG_OPACITY = 0.5;

const {Button} = ReactComponents;
const {Row} = SimpleForm;

const {NUMBER_COLUMNS} = propertiesCommon;

const X = {...propertiesCommon.X, disabled: true};
const Y = {...propertiesCommon.Y, disabled: true};
const WIDTH = {...propertiesCommon.WIDTH, disabled: true};
const HEIGHT = {...propertiesCommon.HEIGHT, disabled: true};
const _ALIGNMENT = {...propertiesCommon._ALIGNMENT, caption: translate('Align Image'), defaultValue: 'center-center'};
const ROTATION = {...propertiesCommon.ROTATION, caption: translate('Rotate Image')};
const DEFAULT_SPAN_OPTIONS = [1];

const _NAME = {
  key: '_name',
  caption: translate('Name'),
  type: 'string',
  disabled: true
};

const ELEMENT_ID = {
  key: 'elementId',
  type: 'string',
  hidden: true
};

const NWID = {
  key: 'nwid',
  type: 'string',
  hidden: true
};

const _CONTENT_NWID = {
  key: '_contentNwid',
  type: 'string',
  hidden: true
};

const ROW = {
  key: 'row',
  type: 'int',
  mask: 'posInt',
  hidden: true
};

const COLUMN = {
  key: 'column',
  type: 'int',
  mask: 'posInt',
  hidden: true
};

const IS_SKIPPED = {
  key: 'isSkipped',
  type: 'bool',
  hidden: true
};

const FLOW_ORDER = {
  key: 'flowOrder',
  caption: translate('Flow order'),
  type: 'int',
  mask: 'posInt',
  hidden: true
};

const ROW_SPAN = {
  key: 'rowSpan',
  caption: translate('Row span'),
  type: 'options',
  options: DEFAULT_SPAN_OPTIONS,
  col: NUMBER_COLUMNS
};

const COLUMN_SPAN = {
  key: 'columnSpan',
  caption: translate('Column span'),
  type: 'options',
  options: DEFAULT_SPAN_OPTIONS,
  col: NUMBER_COLUMNS
};

const IMAGE_OFFSET_X = {
  key: 'imageOffsetX',
  caption: translate('Image offset X'),
  type: 'length'
};

const IMAGE_OFFSET_Y = {
  key: 'imageOffsetY',
  caption: translate('Image offset Y'),
  type: 'length'
};

const IMAGE_FIT_CELL = {
  key: 'imageFitCell',
  caption: translate('Scale image to fit cell'),
  type: 'bool'
};

const CONSTRAIN_PROPORTIONS = {
  key: 'constrainProportions',
  caption: translate('Constrain proportions'),
  type: 'bool',
  defaultValue: true
};

const IMAGE_WIDTH_SCALE = {
  key: 'imageWidthScale',
  caption: translate('Image width scale'),
  type: 'percent',
  defaultValue: 1
};

const IMAGE_HEIGHT_SCALE = {
  key: 'imageHeightScale',
  caption: translate('Image height scale'),
  type: 'percent',
  defaultValue: 1
};

const BLEED_LEFT = {
  key: 'bleedLeft',
  caption: translate('Bleed left'),
  type: 'length',
  mask: 'posFloat'
};

const BLEED_RIGHT = {
  key: 'bleedRight',
  caption: translate('Bleed right'),
  type: 'length',
  mask: 'posFloat'
};

const BLEED_TOP = {
  key: 'bleedTop',
  caption: translate('Bleed top'),
  type: 'length',
  mask: 'posFloat'
};

const BLEED_BOTTOM = {
  key: 'bleedBottom',
  caption: translate('Bleed bottom'),
  type: 'length',
  mask: 'posFloat'
};

const IMAGE_TRIM_X = {
  key: 'imageTrimX',
  caption: translate('Image trim X'),
  type: 'length',
  disabled: true
};

const IMAGE_TRIM_Y = {
  key: 'imageTrimY',
  caption: translate('Image trim Y'),
  type: 'length',
  disabled: true
};

const IMAGE_TRIM_WIDTH = {
  key: 'imageTrimWidth',
  caption: translate('Image trim width'),
  type: 'length',
  disabled: true
};

const IMAGE_TRIM_HEIGHT = {
  key: 'imageTrimHeight',
  caption: translate('Image trim height'),
  type: 'length',
  disabled: true
};

const HIDE_IMAGE = {
  key: 'hideImage',
  caption: translate('Hide image (UI only)'),
  type: 'bool'
};

const IMAGE_PATH = {
  key: 'imagePath',
  type: 'string',
  hidden: true
};

const IMAGE_WIDTH = {
  key: 'imageWidth',
  type: 'length',
  hidden: true
};

const IMAGE_HEIGHT = {
  key: 'imageHeight',
  type: 'length',
  hidden: true
};

const CELL_META = [
  ELEMENT_ID,
  NWID,
  _CONTENT_NWID,
  ROW,
  COLUMN,
  IS_SKIPPED,
  _NAME,
  FLOW_ORDER,
  ROW_SPAN,
  COLUMN_SPAN,
  X,
  Y,
  WIDTH,
  HEIGHT,
  IMAGE_TRIM_X,
  IMAGE_TRIM_Y,
  IMAGE_TRIM_WIDTH,
  IMAGE_TRIM_HEIGHT,
  _ALIGNMENT,
  ROTATION,
  IMAGE_OFFSET_X,
  IMAGE_OFFSET_Y,
  IMAGE_FIT_CELL,
  CONSTRAIN_PROPORTIONS,
  IMAGE_WIDTH_SCALE,
  IMAGE_HEIGHT_SCALE,
  BLEED_LEFT,
  BLEED_RIGHT,
  BLEED_TOP,
  BLEED_BOTTOM,
  HIDE_IMAGE,
  IMAGE_PATH,
  IMAGE_WIDTH,
  IMAGE_HEIGHT
];

export default (editor) => {

  const canvas = editor.getMainCanvas();
  const cutils = canvasUtils(editor);

  const arrowUpUrl = editor.getRelativeImageSource('arrow-up');

  const getMeta = (state, element) => {
    const formLayout = isFormLayout(state);
    const useCellBox = shouldUseCellBox(state);
    const uniform = isUniformGrid(state);

    X.disabled = uniform;
    Y.disabled = uniform;
    WIDTH.disabled = uniform;
    HEIGHT.disabled = uniform;
    ROW_SPAN.disabled = formLayout;
    ROW_SPAN.hidden = formLayout;
    COLUMN_SPAN.disabled = formLayout;
    COLUMN_SPAN.hidden = formLayout;
    IMAGE_WIDTH_SCALE.disabled = element.imageFitCell;
    IMAGE_HEIGHT_SCALE.disabled = element.imageFitCell;
    IMAGE_OFFSET_X.hidden = !useCellBox;
    IMAGE_OFFSET_Y.hidden = !useCellBox;
    IMAGE_OFFSET_X.disabled = element.imageFitCell;
    IMAGE_OFFSET_Y.disabled = element.imageFitCell;
    IMAGE_TRIM_X.hidden = useCellBox;
    IMAGE_TRIM_Y.hidden = useCellBox;
    IMAGE_TRIM_WIDTH.hidden = useCellBox;
    IMAGE_TRIM_HEIGHT.hidden = useCellBox;

    // prevent empty span options when the element is not initialized
    ROW_SPAN.options = DEFAULT_SPAN_OPTIONS;
    COLUMN_SPAN.options = DEFAULT_SPAN_OPTIONS;
    if (typeof (element.row) !== 'undefined' && typeof (element.column) !== 'undefined') {
      const grid = getCellGrid(state);
      ROW_SPAN.options = utils.range(grid.rows - element.row);
      COLUMN_SPAN.options = utils.range(grid.columns - element.column);
    }

    return CELL_META;
  };

  const isFormLayout = (state) => {
    return state.rootType === 'form';
  };

  const shouldUseCellBox = (state) => {
    const grid = getCellGrid(state);
    return !grid.cropMode || grid.cropMode === 'cellBox';
  };

  /**
   * Update image width and height using image size encoded in the image name
   * Note: Used only for backward compatibility
   */

  const updateImageSizeFromImagePath = (element) => {
    if (!element._contentNwid && element.imagePath) {
      const imageSize = extractImageSizeFromImagePath(element.imagePath);
      if ((!element.imageWidth || !element.imageHeight) && imageSize.width > 0 && imageSize.height > 0) {
        element.imageWidth = imageSize.width;
        element.imageHeight = imageSize.height;
      }
    }
  };

  const extractImageSizeFromImagePath = (imagePath) => {
    const imageName = utils.extractFileName(imagePath);
    const indexOfX = imageName.lastIndexOf('x');
    const width = Number(imageName.substring(imageName.lastIndexOf('=') + 1, indexOfX));
    const height = Number(imageName.substr(indexOfX + 1));

    return {
      width,
      height
    };
  };

  const formatCellName = (state, element) => {
    var result = 'Cell';
    if (typeof element.row !== 'undefined' && typeof element.column !== 'undefined') {
      const grid = getCellGrid(state);
      result += ' ' + (element.column + element.row * grid.columns);
    } else if (element.index !== 'undefined') {
      result += ' ' + element.index;
    }

    return result;
  };

  const composeElementId = (element) => {
    var result;
    if (typeof element.row !== 'undefined' && typeof element.column !== 'undefined') {
      result = 'cell-' + element.row + '-' + element.column;
    } else if (typeof element.index !== 'undefined') {
      result = 'cell-' + element.index;
    }

    return result;
  };

  const buildDefaultElement = (state, elementType) => {
    const element = {
      elementType: elementType,
      locked: false,
      _selectable: true
    };

    setDefaultElementValues(state, element);

    return element;
  };

  const setDefaultElementValues = (state, element) => {
    element.locked = element.locked || false;
    element._selectable = true;

    if (!element.elementId) {
      element.elementId = composeElementId(element);
    }

    propertiesCommon.setDefaultValues(state, element, getMeta(state, element));

    element._name = formatCellName(state, element);

    element._contentNwid = element._contentNwid || element.contentNwid;

    updateImageSizeFromImagePath(element);

    element._fullImage = false;
    element._readyToDrag = false;
    element._dragStartPoint = null;
    element._offsetStartPoint = null;
  };

  const updateProperty = (state, element, elementPath, propertyPath, propertyValue) => {
    let newState = state;
    let newElement = element;

    const key = futils.pathTail(propertyPath);
    if (key !== 'cropMode') {
      newState = propertiesCommon.updateProperty(newState, newElement, elementPath, propertyPath, propertyValue);
      newElement = futils.get(newState, elementPath);
    }

    let updatedElement = updateImageScale(newState, newElement, key);
    if (updatedElement !== newElement) {
      newElement = updatedElement;
      newState = futils.update(newState, elementPath, newElement);
    }

    if (key === 'imagePath') {
      loadImage(newState, newElement);
    } else {
      updateShape(newState, newElement);
    }

    return newState;
  };

  const updateReadyToDrag = (state, element, event) => {
    let newElement = element;

    const _readyToDrag = event && (event.ctrlKey || event.metaKey) &&
      isImageVisible(element) && shouldUseCellBox(state) && !element.imageFitCell || false;
    if (element._readyToDrag !== _readyToDrag) {
      newElement = {...element, _readyToDrag};
    }

    return newElement;
  };

  const resetDragProperties = (state, element) => {
    let newElement = element;

    if (element._readyToDrag || element._dragStartPoint || element._offsetStartPoint || element._fullImage) {
      newElement = {
        ...element,
        _readyToDrag: false,
        _dragStartPoint: null,
        _offsetStartPoint: null,
        _fullImage: false
      };
    }

    return newElement;
  };

  const updateImageOffsetWhileDragging = (state, element, event) => {
    var newElement = element;
    if (element._dragStartPoint && event) {
      var zoom = canvas.getZoom();
      var imageOffsetX = utils.canvasToSystemUnits(element._offsetStartPoint.x + (event.pageX - element._dragStartPoint.x) / zoom);
      var imageOffsetY = utils.canvasToSystemUnits(element._offsetStartPoint.y + (event.pageY - element._dragStartPoint.y) / zoom);
      newElement = {...newElement, imageOffsetX, imageOffsetY};
    }

    return newElement;
  };

  const updateCellMovement = (state, element) => {
    if (!element.locked && !isUniformGrid(state) && !element._readyToDrag) {
      unlockCellMovement(element);
    } else {
      lockCellMovement(element);
    }
  };

  const lockCellMovement = (element) => {
    if (!element.shape) {
      return;
    }

    element.shape.cellRect.set(
      {
        hasControls: false,
        lockScalingX: true,
        lockScalingY: true,
        lockMovementX: true,
        lockMovementY: true,
        hoverCursor: 'default'
      });
  };


  const unlockCellMovement = (element) => {
    if (!element.shape) {
      return;
    }

    element.shape.cellRect.set(
      {
        hasControls: true,
        lockScalingX: false,
        lockScalingY: false,
        lockMovementX: false,
        lockMovementY: false,
        hoverCursor: 'move'
      });
  };

  const getCellGrid = (state) => {
    return state.layout.cellGrid;
  };

  const isUniformGrid = (state) => {
    return getCellGrid(state).useGaps;
  };

  const shapeTransforming = (state, element, canvasShape, event) => {
    //console.log("--- shapeTransforming() ---");
    let newElement = element;

    if (!newElement._readyToDrag) {
      newElement = updateReadyToDrag(state, newElement, event);
    }

    if (newElement._readyToDrag) {
      lockCellMovement(newElement);
      if (event && (event.ctrlKey || event.metaKey)) {
        if (!newElement._dragStartPoint) {
          const _dragStartPoint = {x: event.pageX, y: event.pageY};
          const _offsetStartPoint = {
            x: utils.systemToCanvasUnits(newElement.imageOffsetX),
            y: utils.systemToCanvasUnits(newElement.imageOffsetY)
          };

          newElement = {...newElement, _dragStartPoint, _offsetStartPoint}
        }

        newElement = updateImageOffsetWhileDragging(state, newElement, event);
      }
    } else if (!isUniformGrid(state)) {
      const cellRect = newElement.shape.cellRect;
      //console.log("--- applyConstraints() BEFORE--- cellRect=", cellRect.left, cellRect.top, cellRect.width, cellRect.height);
      utils.applyConstraints(cellRect, state.plateRectangle);
      //console.log("--- applyConstraints() AFTER--- cellRect=", cellRect.left, cellRect.top, cellRect.width, cellRect.height);
      const r = utils.toSystemRectangle(state.plateRectangle, cellRect.left, cellRect.top, cellRect.width, cellRect.height);
      if (!utils.areRectanglesEqual(newElement, r, 0)) {
        newElement = {...newElement, x: r.x, y: r.y, width: r.width, height: r.height};
        newElement = updateImageScale(state, newElement);
      }
    }

    if (!event) {
      // no event is provided so the drag operation is finished
      newElement = resetDragProperties(state, newElement);
    }

    updateShape(state, newElement);

    return newElement;
  };

  const shapeMouseDown = (state, element, canvasShape, event) => {
    let newElement = element;
    const _fullImage = isImageVisible(element);
    if (element._fullImage !== _fullImage) {
      newElement = {...element, _fullImage};
      updateShape(state, newElement);
    }

    return newElement;
  };

  const shapeMouseUp = (state, element, canvasShape, event) => {
    const newElement = resetDragProperties(state, element);
    if (newElement !== element) {
      updateShape(state, newElement);
    }

    return newElement;
  };

  const createCellRect = (element) => {
    const selectable = element._selectable;
    const locked = element.locked || !selectable;
    const cellRect = new Fabric.Rect({
      hasRotatingPoint: false,
      centeredRotation: false,
      selectable: selectable,
      evented: selectable,
      hasControls: !locked,
      lockMovementX: locked,
      lockMovementY: locked,
      lockScalingX: locked,
      lockScalingY: locked,
      fill: CELL_FILL_COLOR,
      opacity: CELL_OPACITY,
      stroke: CELL_STROKE
    });

    return cellRect;
  };

  const createImage = (element) => {
    var img = new Image();
    img.src = '';
    var imgOptions = {
      selectable: false,
      evented: false,
      visible: false
    };

    return new Fabric.Image(img, imgOptions);
  };

  const createAlignmentArrow = (element) => {
    var img = new Image();
    img.src = '';
    var imgOptions = {
      centeredRotation: false,
      evented: false,
      hasControls: false,
      originX: 'center',
      originY: 'top',
      visible: false
    };

    return new Fabric.Image(img, imgOptions);
  };

  const createTrimBoxRect = (element) => {
    var trimBoxRect = new Fabric.Rect({
      evented: false,
      fill: '',
      stroke: TRIM_BOX_STROKE,
      strokeDashArray: [5, 3],
      visible: false
    });

    return trimBoxRect;
  };

  const createBleedBoxRect = (element) => {
    var bleedBoxRect = new Fabric.Rect({
      evented: false,
      fill: '',
      stroke: BLEED_BOX_STROKE,
      strokeDashArray: [5, 3],
      visible: false
    });

    return bleedBoxRect;
  };

  const loadAlignmentArrowImage = (state, element) => {
    //console.log("--- loadAlignmentArrowImage() ---");
    var image = element.shape.alignmentArrow;
    image.setSrc(arrowUpUrl, () => {
      updateAlignmentArrow(state, element);

      cutils.renderAllDebounced();
    });
  };

  const getImageUrl = (state, element) => {
    var params;
    if (isFormLayout(state)) {
      if (element._contentNwid) {
        params = {
          nwid: element._contentNwid,
          template: 'page/content',
          action: 'full'
        };
      }
    } else if (element.imagePath) {
      params = {
        action: 'getCellSampleImage',
        command: 'getLayouManagerActions',
        rootId: state.folderNwid,
        imageRelativePath: encodeURIComponent(element.imagePath),
        timestamp: element.imageTimestamp
      };
    }

    var imageUrl = !params ? '' : sandbox.request.getImageUrl(params, true);
    //console.log("cell.getImageUrl() imageUrl=", imageUrl);
    return imageUrl;
  };

  const loadImage = (state, element) => {
    element.shape.image.visible = false;
    var imageUrl = getImageUrl(state, element);
    if (!imageUrl) {
      return;
    }

    element.shape.image.setSrc(imageUrl, () => {
      updateImageAndBoxes(state, element);

      cutils.renderAllDebounced();
    });
  };

  const getCellRectangle = (state, element) => {
    return {left: element.x, top: element.y, width: element.width, height: element.height};
  };

  const getCanvasCellRectangle = (state, element) => {
    return utils.toCanvasRectangle(state.plateRectangle, element.x, element.y, element.width, element.height);
  };

  const getBleedRectangle = (state, element) => {
    return {
      left: element.x - element.bleedLeft,
      top: element.y - element.bleedTop,
      width: element.width + element.bleedLeft + element.bleedRight,
      height: element.height + element.bleedTop + element.bleedBottom
    };
  };

  const getCanvasBleedRectangle = (state, element) => {
    const r = getBleedRectangle(state, element);

    return utils.toCanvasRectangle(state.plateRectangle, r.left, r.top, r.width, r.height);
  };

  const getScaledImageSize = (element) => {
    return {
      width: element.imageWidthScale * element.imageWidth,
      height: element.imageHeightScale * element.imageHeight
    };
  };

  const getCanvasScaledImageSize = (element) => {
    const size = getScaledImageSize(element);

    return utils.toCanvasDimensions(size.width, size.height);
  };

  const getImageTrimRectangle = (element) => {
    const r = utils.rectanglesIntersection(
      {
        left: element.imageTrimX,
        top: element.imageTrimY,
        width: element.imageTrimWidth,
        height: element.imageTrimHeight
      },
      {
        left: 0,
        top: 0,
        width: element.imageWidth,
        height: element.imageHeight
      });

    return {
      left: r.left,
      top: r.top,
      width: r.width,
      height: r.height
    };
  };

  const getCanvasImageTrimRectangle = (element) => {
    const r = getImageTrimRectangle(element);

    return {
      left: element.imageWidthScale * utils.systemToCanvasUnits(r.left),
      top: element.imageHeightScale * utils.systemToCanvasUnits(r.top),
      width: element.imageWidthScale * utils.systemToCanvasUnits(r.width),
      height: element.imageHeightScale * utils.systemToCanvasUnits(r.height),
    };
  };

  const getCanvasImageBleedRectangle = (element) => {
    const r = getCanvasImageTrimRectangle(element);
    const margins = {
      left: utils.systemToCanvasUnits(element.bleedLeft),
      right: utils.systemToCanvasUnits(element.bleedRight),
      top: utils.systemToCanvasUnits(element.bleedTop),
      bottom: utils.systemToCanvasUnits(element.bleedBottom)
    };

    const bleeds = utils.rotateMargins(margins, -element.rotation);

    return {
      left: r.left - bleeds.left,
      top: r.top - bleeds.top,
      width: r.width + bleeds.left + bleeds.right,
      height: r.height + bleeds.top + bleeds.bottom
    };
  };

  const isImageVisible = (element) => {
    var image = element.shape.image;
    return image && !element.isSkipped && !element.hideImage && image.width > 0 && image.height > 0;
  };

  const arrowLeftFromAlignmentX = (cellRectangle, alignmentX) => {
    var left = 0;
    switch (alignmentX) {
      case 'left':
        left = cellRectangle.left;
        break;
      case 'center':
        left = cellRectangle.left + cellRectangle.width / 2;
        break;
      case 'right':
        left = cellRectangle.left + cellRectangle.width;
        break;
    }

    return left;
  };

  const arrowTopFromAlignmentY = (cellRectangle, alignmentY) => {
    var top = 0;
    switch (alignmentY) {
      case 'top':
        top = cellRectangle.top;
        break;
      case 'center':
        top = cellRectangle.top + cellRectangle.height / 2;
        break;
      case 'bottom':
        top = cellRectangle.top + cellRectangle.height;
        break;
    }

    return top;
  };

  const arrowAngleFromAlignment = (alignment) => {
    var idx = utils.ALIGNMENTS_CLOCKWISE.indexOf(alignment);
    return idx < 0 ? 0 : -45 + idx * 45;
  };

  const hasImageTrimBox = (element) => {
    const eps = 0.00005;
    return Math.abs(element.imageTrimX) > eps || Math.abs(element.imageTrimY) > eps ||
      Math.abs(element.imageWidth - element.imageTrimWidth) > eps ||
      Math.abs(element.imageHeight - element.imageTrimHeight) > eps;
  };

  const hasBleeds = (element) => {
    return element.bleedLeft > 0 || element.bleedRight > 0 || element.bleedTop > 0 || element.bleedBottom > 0;
  };

  /**
   * Calculate the image position and crop properties by the cell bleed box.
   * - The intersection of the cell box and the image defines the image trim box.
   * - The intersection of the bleed rectangle (cell box with the bleed margins) and the image defines the
   *   image bleed box.
   * - The image alignment point corresponds to the cell alignment point, e.g. the image top-right corner (defined
   *   after image rotation) is combined with the cell top-right corner when the alignment property is top-right and
   *   offset is 0.
   * - When 'Fit to box' is checked the image fits the bleed box and the image offset fields are disabled (the image
   *   cannot be moved). In this case the image offset is adjusted to accommodate the shift from the cell box.
   * - Image width/height scale is always defined in the image coordinate system and does not depend on the image
   *   rotation.
   *
   * The algorithm:
   * 1. Calculate alignment point (image origin) before rotation (it is used as image rotation point).
   *    Note: Fabric.js image origin point is placed in the (image.left, image.top) coordinates on the canvas,
   *    e.g. if image.originX='right' and image.originY='top' then the top-right corner of the image is
   *    placed in the (image.left, image.top) coordinates and the top-left image corner will be in the
   *    (image.left - image.width, image.top) coordinates.
   *    Image origin values (left, center, right, top, bottom) correspond to the not rotated image (image.angle=0) and
   *    does not change when the image is rotated.
   * 2. In order to find the image crop box rotate cell bleed box by the given rotation angle but in opposite direction.
   * 3. Adjust image crop box top-left point by bleeds and image offset (recalculated using negative rotation angle).
   *    Note: Fabric.js image crop rectangle is defined relative to the center of the not rotated image, i.e.
   *    (0, 0) of the crop rectangle is located in the center of the original image and the crop rectangle
   *    is rotated together with the original image.
   * 4. Move image origin to the alignment point on the cell and shift it by the given image offset.
   *    Note: Image origin is always combined with the corresponding cell alignment point,
   *    e.g. if image.originX='right' and image.originY='bottom' then the image bottom-right corner is placed at
   *    the coordinates of the cell bottom-right corner when the image offset is equal to zero.
   */
  const calcImageCropPropsByCellBleedBox = (trimBox, bleedBox, imageSize, element) => {
    const align = utils.calcAlignmentBeforeRotation(element.alignmentX, element.alignmentY, element.rotation);

    const imageCrop = utils.rotateDimensions(bleedBox.width, bleedBox.height, -element.rotation);

    const alignmentPoint = utils.calcVectorToAlignmentPoint(0, 0, trimBox, element.alignmentX, element.alignmentY);

    const imageOffset = {
      left: utils.systemToCanvasUnits(element.imageOffsetX),
      top: utils.systemToCanvasUnits(element.imageOffsetY)
    };

    const props = {
      imageLeft: alignmentPoint.left + imageOffset.left,
      imageTop: alignmentPoint.top + imageOffset.top,
      imageWidth: imageSize.width,
      imageHeight: imageSize.height,
      cropWidth: imageCrop.width,
      cropHeight: imageCrop.height,
      originX: align.alignmentX,
      originY: align.alignmentY,
      trimBoxRect: {...trimBox, angle: 0},
      bleedBoxRect: {...bleedBox, angle: 0}
    };

    props.cropLeft = calcImageCropLeft(props, trimBox, bleedBox, imageOffset, element);
    props.cropTop = calcImageCropTop(props, trimBox, bleedBox, imageOffset, element);

    return props;
  };

  /**
   * Calculate image crop rectangle left coordinate
   */
  const calcImageCropLeft = (cropProps, trimBox, bleedBox, imageOffset, element) => {
    let left = 0;

    const offset = utils.rotateVector(imageOffset.left, imageOffset.top, -element.rotation);
    const margins = utils.rotateMargins(utils.calcMargins(trimBox, bleedBox), -element.rotation);

    switch (cropProps.originX) {
      case 'left':
        left = -cropProps.imageWidth / 2 - margins.left;
        break;
      case 'center':
        left = -cropProps.cropWidth / 2 - margins.left / 2 + margins.right / 2;
        break;
      case 'right':
        left = cropProps.imageWidth / 2 - cropProps.cropWidth + margins.right;
        break;
    }

    return left - offset.left;
  };

  /**
   * Calculate image crop rectangle top coordinate
   */
  const calcImageCropTop = (cropProps, trimBox, bleedBox, imageOffset, element) => {
    let top = 0;

    const offset = utils.rotateVector(imageOffset.left, imageOffset.top, -element.rotation);
    const margins = utils.rotateMargins(utils.calcMargins(trimBox, bleedBox), -element.rotation);


    switch (cropProps.originY) {
      case 'top':
        top = -cropProps.imageHeight / 2 - margins.top;
        break;
      case 'center':
        top = -cropProps.cropHeight / 2 - margins.top / 2 + margins.bottom / 2;
        break;
      case 'bottom':
        top = cropProps.imageHeight / 2 - cropProps.cropHeight + margins.bottom;
        break;
    }

    return top - offset.top;
  };

  /**
   * Calculate the image position and crop properties by the image trim and bleed boxes and the alignment point
   * on the cell.
   * - The image trim box can obtained from the PDF page or attached as a metadata to the TIFF image.
   * - The image trim box is bound to the image and defined in the image coordinate system.
   * - The image trim box together with the bleed margins (defined as the cell properties and added after image
   *   scale and rotation) compose the image bleed box.
   * - The image trim box alignment point corresponds to the cell alignment point, e.g. the image trim box top-right
   *   corner (defined after image rotation) is combined with the cell top-right corner when the alignment property
   *   is top-right.
   * - The image offset fields have no effect on image position and are hidden in the 'Use image trim box' mode.
   *   Therefore, the image position is defined only by the image alignment and the image cannot be moved.
   * - The trim box values are not changed when the image is rotated or scaled because they are defined in the image
   *   coordinate system.
   * - The bleed margins are added to the image trim box after image scale and rotation operations and
   *   therefore the image scale and rotation has no effect on the bleed margins.
   * - The bleed position is relative to the plate and does not depend on image rotation, e.g. the top bleed
   *   is added to the image right hand side when the image is rotated 90° in clockwise direction.
   * - The trim box fields are visible only when the 'Use image trim box' mode is selected and are disabled
   *   in this mode because are obtained from the image itself.
   * - When Fit to box is checked the image trim box fits the cell box unlike the 'Use cell box' mode when
   *   the whole image fits the bleed box.
   * - When Fit to box is checked and image is rotated by 90° the trim box width will be equal to the cell box height
   *   and the trim box height will be equal to the cell box width.
   * - The image width/height scale is always defined in the image coordinate system (the same as in the
   *   'Use cell box' mode).
   *
   * The algorithm:
   * 1. Calculate vector to the alignment point on the cell (it is used also as image rotation point).
   * 2. Move the image top-left corner to this point.
   * 3. Find vector from the image top-left corner to the image trim box alignment point before rotation.
   * 4. Rotate the found vector to the given angle; name the resulting vector 'vo'.
   * 5. Shift image to the vector 'vo' but in opposite direction to combine alignment points on the image trim box
   *    and cell.
   */
  const calcImageCropPropsByImageBleedBox = (cellBox, trimBox, bleedBox, imageSize, element) => {
    // o - vector to the cell alignment point
    const o = utils.calcVectorToAlignmentPoint(0, 0, cellBox, element.alignmentX, element.alignmentY);

    // vo - rotated vector to the image alignment point from the image top-left
    const angle = element.rotation;
    const align = utils.calcAlignmentBeforeRotation(element.alignmentX, element.alignmentY, angle);
    let vo = utils.calcVectorToAlignmentPoint(0, 0, trimBox, align.alignmentX, align.alignmentY);
    vo = utils.rotateVector(vo.left, vo.top, angle);

    // shift image top-left by vector vo
    const imageLeft = o.left - vo.left;
    const imageTop = o.top - vo.top;

    // recalculate image trim box coordinates in the plate coordinate system:
    // trim box top-left = image top-left + trim box top-left from the image top-left
    const vt = utils.rotateVector(trimBox.left, trimBox.top, angle);
    const trimBoxRect = {
      left: imageLeft + vt.left,
      top: imageTop + vt.top,
      width: trimBox.width,
      height: trimBox.height,
      angle
    };

    // recalculate image bleed box coordinates in the plate coordinate system:
    // bleed box top-left = image top-left + bleed box top-left from the image top-left
    const vb = utils.rotateVector(bleedBox.left, bleedBox.top, angle);
    const bleedBoxRect = {
      left: imageLeft + vb.left,
      top: imageTop + vb.top,
      width: bleedBox.width,
      height: bleedBox.height,
      angle
    };

    const props = {
      imageLeft,
      imageTop,
      imageWidth: imageSize.width,
      imageHeight: imageSize.height,
      cropLeft: -imageSize.width / 2 + bleedBox.left,
      cropTop: -imageSize.height / 2 + bleedBox.top,
      cropWidth: bleedBox.width,
      cropHeight: bleedBox.height,
      originX: 'left',
      originY: 'top',
      trimBoxRect,
      bleedBoxRect
    };

    return props;
  };

  const updateCropMode = (state, element) => {
    let newElement = updateImageScale(state, element);

    return newElement;
  };

  const updateImageScale = (state, element, key) => {
    let imageWidthScale = element.imageWidthScale;
    let imageHeightScale = element.imageHeightScale;
    let imageOffsetX = element.imageOffsetX;
    let imageOffsetY = element.imageOffsetY;

    if (element.imageFitCell) {
      if (shouldUseCellBox(state)) {
        // Adjust offset to accommodate difference between cell box and bleed box alignment points
        const cellRect = getCellRectangle(state, element);
        const cellAlignmentPoint = utils.calcVectorToAlignmentPoint(0, 0, cellRect, element.alignmentX, element.alignmentY);
        const bleedRect = getBleedRectangle(state, element);
        const bleedAlignmentPoint = utils.calcVectorToAlignmentPoint(0, 0, bleedRect, element.alignmentX, element.alignmentY);
        imageOffsetX = bleedAlignmentPoint.left - cellAlignmentPoint.left;
        imageOffsetY = bleedAlignmentPoint.top - cellAlignmentPoint.top;

        if (element.imageWidth > 0 && element.imageHeight > 0) {
          // Fit image to bleed box instead of trim box in order to add bleeds to the image
          if (element.rotation === 90 || element.rotation === 270) {
            imageWidthScale = bleedRect.height / element.imageWidth;
            imageHeightScale = bleedRect.width / element.imageHeight;
          } else {
            imageWidthScale = bleedRect.width / element.imageWidth;
            imageHeightScale = bleedRect.height / element.imageHeight;
          }
        }
      } else if (element.imageWidth > 0 && element.imageHeight > 0) {
        // Fit image trim box after image rotation to the cell box
        const imageTrimRect = getImageTrimRectangle(element);
        if (imageTrimRect.width > 0 && imageTrimRect.height > 0) {
          if (element.rotation === 90 || element.rotation === 270) {
            imageWidthScale = element.height / imageTrimRect.width;
            imageHeightScale = element.width / imageTrimRect.height;
          } else {
            imageWidthScale = element.width / imageTrimRect.width;
            imageHeightScale = element.height / imageTrimRect.height;
          }
        }
      }
    }

    if (element.constrainProportions) {
      if (key === 'imageWidthScale') {
        imageHeightScale = imageWidthScale;
      } else if (key === 'imageHeightScale') {
        imageWidthScale = imageHeightScale;
      } else {
        const scale = Math.min(imageWidthScale, imageHeightScale);
        imageWidthScale = scale;
        imageHeightScale = scale;
      }
    }

    let newElement = element;
    if (imageWidthScale !== element.imageWidthScale || imageHeightScale !== element.imageHeightScale ||
      imageOffsetX !== element.imageOffsetX || imageOffsetY !== element.imageOffsetY) {
      newElement = {...element, imageWidthScale, imageHeightScale, imageOffsetX, imageOffsetY};
    }

    return newElement;
  };

  const updateCellRect = (state, element) => {
    const cellRect = element.shape.cellRect;
    cellRect.visible = !element.isSkipped;
    cellRect.set(getCanvasCellRectangle(state, element));
    cellRect.setCoords();
  };

  const updateAlignmentArrow = (state, element) => {
    var alignmentArrow = element.shape.alignmentArrow;
    alignmentArrow.visible = !element.isSkipped && element._alignment != 'center-center' &&
      alignmentArrow.width > 0 && alignmentArrow.height > 0;
    if (alignmentArrow.visible) {
      var cellRectangle = getCanvasCellRectangle(state, element);
      alignmentArrow.set({
        left: arrowLeftFromAlignmentX(cellRectangle, element.alignmentX),
        top: arrowTopFromAlignmentY(cellRectangle, element.alignmentY),
        angle: arrowAngleFromAlignment(element._alignment)
      });
    }
  };

  const updateTrimBoxRect = (state, element, rect) => {
    const trimBoxRect = element.shape.trimBoxRect;
    trimBoxRect.visible = element._fullImage || !shouldUseCellBox(state) && hasImageTrimBox(element);
    if (trimBoxRect.visible) {
      trimBoxRect.set(rect);
    }
  };

  const updateBleedBoxRect = (state, element, rect) => {
    const bleedBoxRect = element.shape.bleedBoxRect;
    bleedBoxRect.visible = hasBleeds(element);
    if (bleedBoxRect.visible) {
      bleedBoxRect.set(rect);
    }
  };

  const updateImage = (state, element, cropProps) => {
    let opacity = 1;
    let clipToFunc;
    if (element._fullImage) {
      opacity = DRAG_OPACITY;
    } else {
      clipToFunc = function (ctx) {
        ctx.rect(cropProps.cropLeft, cropProps.cropTop, cropProps.cropWidth, cropProps.cropHeight);
      };
    }

    element.shape.image.set({
      left: cropProps.imageLeft,
      top: cropProps.imageTop,
      width: cropProps.imageWidth,
      height: cropProps.imageHeight,
      originX: cropProps.originX,
      originY: cropProps.originY,
      angle: element.rotation,
      clipTo: clipToFunc,
      selectable: false,
      opacity: opacity,
      evented: false,
      lockMovementX: true,
      lockMovementY: true
    });
  };

  const updateImageAndBoxes = (state, element) => {
    let visible = isImageVisible(element);
    element.shape.image.visible = visible;
    element.shape.trimBoxRect.visible = visible;
    element.shape.bleedBoxRect.visible = visible;
    const useCellBox = shouldUseCellBox(state);

    if (visible) {
      let cropProps;
      const cellBox = getCanvasCellRectangle(state, element);
      const scaledImageSize = getCanvasScaledImageSize(element);
      if (useCellBox) {
        const cellBleedBox = getCanvasBleedRectangle(state, element);
        cropProps = calcImageCropPropsByCellBleedBox(cellBox, cellBleedBox, scaledImageSize, element);
      } else {
        const imageTrimBox = getCanvasImageTrimRectangle(element);
        const imageBleedBox = getCanvasImageBleedRectangle(element);
        cropProps = calcImageCropPropsByImageBleedBox(cellBox, imageTrimBox, imageBleedBox, scaledImageSize, element);
      }

      updateImage(state, element, cropProps);
      updateTrimBoxRect(state, element, cropProps.trimBoxRect);
      updateBleedBoxRect(state, element, cropProps.bleedBoxRect);
    } else if (useCellBox && hasBleeds(element)) {
      const bleedRect = getCanvasBleedRectangle(state, element);
      updateBleedBoxRect(state, element, {...bleedRect, angle: 0});
    }
  };

  const createShape = (state, element, elementPath) => {
    const cellRect = createCellRect(element);
    const image = createImage(element);
    const alignmentArrow = createAlignmentArrow(element);
    const trimBoxRect = createTrimBoxRect(element);
    const bleedBoxRect = createBleedBoxRect(element);

    const shape = {
      elementPath,
      cellRect,
      image,
      alignmentArrow,
      trimBoxRect,
      bleedBoxRect
    };

    cellRect.shape = shape;
    element.shape = shape;

    //updateShape(state, element);

    //addShape(state, element);

    loadAlignmentArrowImage(state, element);
    loadImage(state, element);

    return shape;
  };

  const addShape = (state, element) => {
    if (!element || !element.shape) {
      return;
    }

    canvas.add(element.shape.cellRect);
    canvas.add(element.shape.image);
    canvas.add(element.shape.alignmentArrow);
    canvas.add(element.shape.trimBoxRect);
    canvas.add(element.shape.bleedBoxRect);
  };

  const removeShape = (state, element) => {
    if (!element || !element.shape) {
      return;
    }

    var shape = element.shape;
    canvas.remove(shape.cellRect);
    canvas.remove(shape.image);
    canvas.remove(shape.alignmentArrow);
    canvas.remove(shape.trimBoxRect);
    canvas.remove(shape.bleedBoxRect);
  };

  const updateShape = (state, element) => {
    updateCellMovement(state, element);
    updateCellRect(state, element);
    updateImageAndBoxes(state, element);
    updateAlignmentArrow(state, element);
  };

  const activateShape = (state, element) => {
    cutils.setActiveObject(element.shape.cellRect);
  };

  const nudgeShape = (state, element, direction) => {
    var newElement = element;
    if (!element.locked && !isUniformGrid(state)) {
      newElement = propertiesCommon.nudgeElement(state, element, direction);
      updateShape(state, newElement);
    }

    return newElement;
  };

  const selectImage = (elementPath) => {
    return (dispatch, getState) => {
      let imageProps;
      const { viewInstanceNwid } = getState();

      return unplannedPages.selectPageImage(viewInstanceNwid)
        .then(imageItem => {
          imageProps = imageItem;
          return saveCellSampleImage(imageProps, getState, elementPath);
        })
        .then(imageFileInfo => loadSampleImage(imageProps, imageFileInfo, dispatch, getState, elementPath))
        .catch(reason => console.log(reason));
    };
  };

  const saveCellSampleImage = (imageItem, getState, elementPath) => {
    if (!imageItem || !imageItem.nwid || !imageItem.width || !imageItem.height) {
      console.error("saveCellSampleImage(): cannot save image from ", imageItem);
      throw new Error("Error saving sample image");
    }

    var state = getState();
    var element = futils.get(state, elementPath);
    var params = {
      action: 'saveCellSampleImage',
      command: 'getLayouManagerActions',
      rootId: state.folderNwid,
      contentNwid: imageItem.nwid,
      layoutNwid: state.layoutNwid,
      cellId: element.elementId

    };

    return utils.sendGenericRequest(params);
  };

  const loadSampleImage = (imageProps, imageFileInfo, dispatch, getState, elementPath) => {
    const imageWidth = utils.roundUnits(imageProps.width, 'inch');
    const imageHeight = utils.roundUnits(imageProps.height, 'inch');
    let trimBox = {
      imageTrimX: 0,
      imageTrimY: 0,
      imageTrimWidth: imageWidth,
      imageTrimHeight: imageHeight,
    };

    const tb = imageProps.trimBox;
    if (imageProps.trimBox) {
      trimBox = {
        imageTrimX: tb.left,
        imageTrimY: tb.top,
        imageTrimWidth: tb.width,
        imageTrimHeight: tb.height
      };
    }

    const imageInfo = {
      imageWidth,
      imageHeight,
      imagePath: imageFileInfo.imagePath,
      imageTimestamp: imageFileInfo.imageTimestamp,
      ...trimBox,
    };

    dispatch(actions.updateCellImage(elementPath, imageInfo));
  };

  const handleSelectImageButtonClick = (store, elementPath) => {
    store.dispatch(selectImage(elementPath));
  };

  const renderProperties = (store, element, elementPath) => {
    const state = store.getState();

    return (
      <div>
        {propertiesCommon.renderProperties(store, element, elementPath, getMeta(state, element))}
        <Row>
          <Button col={'1'}
            text={translate('Select image')}
            className={XSMALL_BUTTON_CLASS}
            iconName={'image'}
            disabled={element.locked}
            onClick={() => handleSelectImageButtonClick(store, elementPath)}
          />
        </Row>
      </div>
    );

  };

  const getInfo = (state) => {
    return {
      basePath: BASE_PATH,
      title: TITLE
    };
  };

  const getAlignmentInfo = (state, element) => {
    return utils.getAlignmentInfo(element.shape.cellRect);
  };

  return {
    buildDefaultElement,
    setDefaultElementValues,
    updateProperty,
    shapeTransforming,
    shapeMouseDown,
    shapeMouseUp,
    createShape,
    updateShape,
    addShape,
    removeShape,
    activateShape,
    nudgeShape,
    renderProperties,
    getInfo,
    getAlignmentInfo,
    updateCropMode
  }

}
  ;
