/**
 * @name Desktop Manager
 *
 * @fileOverview Desktop manager serves two main purposes:
 * a) creates initial layout which is read from the settings json;
 * b) creates and removes the html container for the loading module
 *
 * @namespace
 * @author sergey
 */

import base from 'base';
import localization from 'core/services/localization';
import requestManager from 'core/managers/request';
import { composeWindowTitle } from 'utilities/breadcrumbs';
import pubsub from 'core/managers/pubsub';
import viewManager from 'core/managers/views';
import windowsManager from 'core/managers/windows';
import { openModuleHelp } from 'core/services/helpService';
import licenseService from 'core/services/license';
import Navigator2 from 'widgets/Navigator2/Navigator';
import NavigationTabStrip from './NavigationTabStrip';
import MainTabStrip from './MainTabStrip';
import SecondaryTabStrip from './SecondaryTabStrip';
import { createLayout, getCurrentLayout, showInfoPanelSplitbar } from './layout';
import settingsManager from 'core/managers/settings';
import {
  MODULE_CONTAINER_CLASS,
  selectorFromId,
  getModuleId,
  getModuleContainerId,
  extractModuleId
} from 'utilities/desktop';
import KendoTabStrip from 'kendo.tabstrip';
import KendoSplitter from 'kendo.splitter';
import KendoWindow from 'kendo.window';
import KendoSortable from 'kendo.sortable';

const { translate } = localization;

const OVERVIEW_HTML = `
<div class="item-header">
  <div class="item-title"></div>
  <div class="item-controls">   
    <a href="#"><img class="min-max" src="../kernel/assets/img/general/overviews/show_in_mainview.png" /></a>
    <a class="close" href="#">&times</a>    
  </div>
</div>
<div class="item-content">
  <img class="loader" src="../kernel/assets/img/sys/ajax-loader.gif" alt="Waiting" />
</div>
<div class="item-statusbar"></div>
`;

const TOP_LEVEL_CONTAINER_ID = 'container';
const MODULE_PARENT_CONTAINER_CLASS = 'crtx-module-parent-container';
const MODULE_RIGHT_CONTAINER_CLASS = 'crtx-module-right-container';

const DIALOG_TITLE_HEIGHT = 32;
const DIALOG_MARGIN = {
  LEFT: 20,
  TOP: 32,
  RIGHT: 10,
  BOTTOM: 10
};

const MIN_DIALOG_DISTANCE = 10;

var navigationTabStrip,
  mainTabStrip,
  secondaryTabStrip,
  focus;

// Keep Kendo UI Window open during dropdown list interaction, even if ESC is pressed
window.kendo.ui.Window.fn._keydownContent = function (originalFn) {
  return function (e) {
    if (e.code !== 'Escape') {
      originalFn.call(this, e);
    }
  };
}(window.kendo.ui.Window.fn._keydownContent);

function createDesktop(specificLayout) {

  createLayout(specificLayout);

  //3U only
  if (getCurrentLayout() === '3U') {
    mainTabStrip = new MainTabStrip('main');
    navigationTabStrip = new NavigationTabStrip('navigation');
    secondaryTabStrip = new SecondaryTabStrip('secondary');

    base.events.focus('#navigation', function (ev) {
      focus = ev.currentTarget;
    });

    base.events.focus('#main', function (ev) {
      focus = ev.currentTarget;
    });

  }


  ////////// adding license message node //////////////////////////
  new licenseService(function () {//hide function
    $("#main-wrapper").data("kendoSplitter").collapse("#top-messages-bar");
  }, function () { //show function
    $("#main-wrapper").data("kendoSplitter").expand("#top-messages-bar");
  }, base.dom.find("#top-messages-bar"));

  base.events.listen('#overview', 'click', function (ev) {
    if (ev.target && ev.target.className === 'close') {
      if (ev.target.attributes && ev.target.attributes['data-module-element'] !== void 0) {
        secondaryCloseHandler(base.dom.data(ev.target, 'moduleElement'));
      }
    }
    if (ev.target && ev.target.className === 'min-max') {
      maximizeModuleHandler(ev);
    }
  });
  base.events.listen('#right', 'click', function (ev) {
    if (ev.target && ev.target.className === 'close') {
      rightCloseHandler();
    }
  });

  base.dom.hide('#splash');
}

function getModuleSettings(nwid, config = {}) {
  const settings = { ...viewManager.getViewInfo(nwid), ...config };
  settings.target = settings.target ? settings.target.toLowerCase() : '';

  return settings;
}

function getModuleName(nwid, settings) {
  return settings.viewClass || viewManager.getViewClass(nwid);
}

function composeModuleContainerId(moduleName, moduleId) {
  return moduleName + '-' + moduleId;
}

function composeModuleContainerDiv(containerId, containerClass = MODULE_CONTAINER_CLASS, withoutWaiting = false) {
  const waitingImg = withoutWaiting ? '' : '<img class="loader" src="../kernel/assets/img/sys/ajax-loader.gif" alt="Waiting"/>';

  return composeDiv({ id: containerId, class: containerClass }, waitingImg);
}

function composeDiv(attributeHash, content) {
  let attributes = '';
  for (let name in attributeHash) {
    attributes += ` ${name}="${attributeHash[name]}"`;
  }

  return `<div${attributes}>${content || ''}</div>`;
}

/**
 * The purpose of the function is to create div container for an initiated module.
 * The function adds tab to the main part of the screen with an empty div as a container.
 * @param  {string}   moduleId    Id of the module
 * @param  {string}   nwid        Newsway id of a view in the dictionary
 * @param  {object}   settings    Additional parameters
 */
function prepareNewTab(moduleId, nwid, settings) {
  const moduleName = getModuleName(nwid, settings);
  const moduleContainerId = composeModuleContainerId(moduleName, moduleId);
  const selector = selectorFromId(moduleContainerId);

  const tabContent = composeDiv({ class: MODULE_PARENT_CONTAINER_CLASS }, composeModuleContainerDiv(moduleContainerId));
  const tab = mainTabStrip.appendTab({
    text: settings.label || settings.name,
    rootType: settings.rootType,
    content: tabContent
  });

  tab.select();

  const domElement = document.getElementById(moduleContainerId);
  const navigator = createNavigator(domElement, moduleId, moduleName, settings);

  requestViewLinks(settings).then(viewLinks => navigator.setViewLinks(viewLinks, nwid));

  return {
    tab,
    selector,
    domElement,
    container: $(domElement.parentNode),
    navigator
  };
}

function prepareNavigationTab(moduleId, nwid, settings) {
  const moduleName = getModuleName(nwid, settings);
  const moduleContainerId = composeModuleContainerId(moduleName, moduleId);
  const selector = selectorFromId(moduleContainerId);

  const tabContent = composeModuleContainerDiv(moduleContainerId);
  const tab = navigationTabStrip.appendTab({
    text: translate(settings.label),
    content: tabContent
  });

  navigationTabStrip.selectTab(0);

  const domElement = document.getElementById(moduleContainerId);

  return {
    tab,
    selector,
    domElement,
    container: $(domElement.parentNode)
  };
}

function prepareContainer(moduleId, nwid, settings) {
  const wrapperSelector = '#main';
  const wrapper = base.dom.find(wrapperSelector);
  if (wrapper.children().length !== 0) {
    const oldContainerId = base.dom.find(' > div[id]', wrapper)[0].id;
    require('core/managers/module').stopModule(extractModuleId(oldContainerId));

    //removing everything from the parent container
    base.dom.html(base.dom.find(selectorFromId(oldContainerId)).parent(), '');
  }

  const moduleName = getModuleName(nwid, settings);
  const moduleContainerId = composeModuleContainerId(moduleName, moduleId);
  const selector = selectorFromId(moduleContainerId);

  // append content
  const domElement = base.dom.createNode(composeModuleContainerDiv(moduleContainerId))[0];
  base.dom.append(wrapper, domElement);
  wrapper.addClass(MODULE_PARENT_CONTAINER_CLASS);

  let navigator;

  if (!settings.noNavigator) {
    navigator = createNavigator(domElement, moduleId, moduleName, settings);
    requestViewLinks(settings).then(viewLinks => navigator.setViewLinks(viewLinks, nwid));
  }

  return {
    selector: selector,
    domElement: domElement,
    container: window,
    navigator
  };
}

function requestViewLinks(settings) {
  return requestManager.retrieveViewLinks(settings.rootId, settings.rootType, settings.nwid)
    .then(result => {
      const views = result.data && result.data.viewLinks && result.data.viewLinks.map(nwid => {
        const viewInfo = viewManager.getViewInfo(nwid);
        viewInfo.rootId = settings.rootId;
        viewInfo.rootType = settings.rootType;
        viewInfo.rootName = settings.rootName;
        return viewInfo;
      }) || [];

      return views;
    });
}

function createNavigator(moduleDomElement, moduleId, moduleName, settings) {
  const nav = new Navigator2(moduleDomElement, {
    rootType: settings.rootType,
    moduleName: moduleName,
    moduleId: moduleId
  });

  return nav;
}

function updateNavigatorSettings(navigator, moduleId, moduleName, settings) {
  navigator.updateSettings({
    moduleId: moduleId,
    rootType: settings.rootType,
    moduleName: moduleName
  });
}

function stopModuleInParentElement(parentElement) {
  const moduleId = getModuleId(parentElement);
  return require('core/managers/module').stopModule(moduleId);
}

async function prepareNewLightWindow(moduleId, nwid, settings, event) {
  const url = window.location.href.substring(0, window.location.href.lastIndexOf('/')) +
    `/window.html?v=${settingsManager.get('version')}`;
  const featuresObj = {
    width: parseInt(settings.windowWidth),
    height: parseInt(settings.windowHeight),
  };

  const win = await windowsManager.open(url, 'light_window', featuresObj, settings, event);

  if (win._found) {
    settings.win = win;
    return prepareCurrentLightWindow(moduleId, nwid, settings);
  }

  const moduleName = getModuleName(nwid, settings);
  const moduleContainerId = composeModuleContainerId(moduleName, moduleId);
  const parentElement = win.document.getElementById(TOP_LEVEL_CONTAINER_ID);
  const moduleContainerDiv = composeModuleContainerDiv(moduleContainerId);
  base.dom.append(parentElement, moduleContainerDiv);
  const domElement = win.document.getElementById(moduleContainerId);
  domElement.parentNode.classList.add(MODULE_PARENT_CONTAINER_CLASS);

  const navigator = createNavigator(domElement, moduleId, moduleName, settings);
  requestViewLinks(settings).then(viewLinks => navigator.setViewLinks(viewLinks, nwid));

  win.addEventListener('unload', () => {
    stopModuleInParentElement(parentElement);
  });

  return {
    domElement,
    container: $(domElement.parentNode), //TODO: Change to work with DOMElement instead of jQuery element.
    navigator,
    win
  };
}

function prepareCurrentLightWindow(moduleId, nwid, settings) {
  const win = settings.win || window;
  const parentElement = win.document.getElementById(TOP_LEVEL_CONTAINER_ID);

  const moduleMgr = require('core/managers/module');
  const oldModuleId = getModuleId(parentElement);
  const oldModule = moduleMgr.getModuleById(oldModuleId);
  let navigator;
  if (oldModule) {
    navigator = oldModule.navigator;
    moduleMgr.stopModule(oldModuleId, true);
    base.dom.remove(oldModule.domElement);
  }

  const moduleName = getModuleName(nwid, settings);
  const moduleContainerId = composeModuleContainerId(moduleName, moduleId);
  const moduleContainerDiv = composeModuleContainerDiv(moduleContainerId);
  base.dom.append(parentElement, moduleContainerDiv);
  const domElement = win.document.getElementById(moduleContainerId);
  parentElement.classList.add(MODULE_PARENT_CONTAINER_CLASS);

  if (navigator) {
    // Reuse old navigator to prevent flickering
    updateNavigatorSettings(navigator, moduleId, moduleName, settings);
  } else {
    navigator = createNavigator(domElement, moduleId, moduleName, settings);
  }

  requestViewLinks(settings).then(viewLinks => navigator.setViewLinks(viewLinks, nwid));

  return {
    domElement,
    container: $(parentElement), //TODO: Change to work with DOMElement instead of jQuery element.
    navigator,
    win
  };
}

function adjustDialogRectangle(dialogWidth, dialogHeight) {
  let left = DIALOG_MARGIN.LEFT;
  let top = DIALOG_MARGIN.TOP;
  let width = 'auto';
  let height = 'auto';

  if (typeof dialogWidth !== 'undefined') {
    width = Math.min(dialogWidth, window.innerWidth - DIALOG_MARGIN.LEFT - DIALOG_MARGIN.RIGHT);
    left = Math.max(DIALOG_MARGIN.LEFT, (window.innerWidth - width) / 2);
  }

  if (typeof dialogHeight !== 'undefined') {
    height = Math.min(dialogHeight, window.innerHeight - DIALOG_TITLE_HEIGHT - DIALOG_MARGIN.TOP - DIALOG_MARGIN.BOTTOM);
    top = Math.max(DIALOG_MARGIN.TOP, (window.innerHeight - height - DIALOG_TITLE_HEIGHT) / 2);
  }

  const rectangles = require('core/managers/module').getModules().reduce((acc, m) => {
    const target = m.viewSettings && m.viewSettings.target || m.startParameters && m.startParameters.target;
    if (target === 'dialog' && m.container && m.container.options && m.container.options.position) {
      acc.push({
        left: Number(m.container.options.position.left) || 0,
        top: Number(m.container.options.position.top) || 0,
        width: m.container.options.width || 0,
        height: m.container.options.height || 0,
      });
    }

    return acc;
  }, []);

  let found;
  do {
    found = false;
    for (let rect of rectangles) {
      if (Math.abs(left - rect.left) < MIN_DIALOG_DISTANCE || Math.abs(top - rect.top) < MIN_DIALOG_DISTANCE) {
        found = true;
        left += MIN_DIALOG_DISTANCE;
        top += MIN_DIALOG_DISTANCE;
        break;
      }
    }
  } while (found);

  if (width > 0) {
    width += Math.min(window.innerWidth - left - width - DIALOG_MARGIN.RIGHT, 0);
  }

  if (height > 0) {
    height += Math.min(window.innerHeight - top - DIALOG_TITLE_HEIGHT - height - DIALOG_MARGIN.BOTTOM, 0);
  }

  return { left, top, width, height };
}

/**
 * @param moduleId
 * @param nwid
 * @param settings
 * @returns {object}
 */
function prepareNewDialog(moduleId, nwid, settings) {
  const win = settings.win || window;
  const moduleName = getModuleName(nwid, settings);
  const moduleContainerId = composeModuleContainerId(moduleName, moduleId);
  const moduleSelector = selectorFromId(moduleContainerId);

  const dialogTitle = composeWindowTitle(settings.label || settings.name, settings.rootLabel);
  const moduleContainerDiv = composeModuleContainerDiv(moduleContainerId, 'dialog');
  base.dom.append(win.document.body.children[0], moduleContainerDiv);
  const domElement = win.document.getElementById(moduleContainerId);
  const moduleContent = $(domElement);

  const rect = adjustDialogRectangle(settings.windowWidth, settings.windowHeight);

  moduleContent.kendoWindow({
    resizable: true,
    modal: !!settings.modal,
    minWidth: 300,
    minHeight: 120,
    position: { left: rect.left, top: rect.top },
    width: rect.width,
    height: rect.height,
    title: dialogTitle,
    animation: false,
    actions: ["Help", "Close"],
    appendTo: win.document.body
  });

  const dialog = moduleContent.data('kendoWindow');
  dialog.bind('resize', function () {
    pubsub.publish('pane-resize');
  });
  dialog.bind('close', function (ev) {
    ev.sender.destroy();
    require('core/managers/module').stopModule(Number(moduleId));
  });

  dialog.open();

  // adding help action
  // this hack is because we can't add specific actions for kendo window
  let helpDomIcon = base.dom.find('.k-i-help', base.dom.parent(dialog.element));
  base.events.listen(base.dom.parent(helpDomIcon), 'click', function () {
    openModuleHelp(settings.viewClass, settings.rootType, helpDomIcon[0].ownerDocument.defaultView);
  });

  if (settings.maximize) {
    dialog.toggleMaximization();
  }
  return {
    selector: moduleSelector,
    domElement,
    container: dialog,
    type: 'dialog',
    win
  };
}


/**
 * Prepare current tab function is triggered to prepare the dom container
 * to open the new module at the currently active tab.
 * The processes includes the following steps:
 *
 * 1. Get view definition of new module
 * 2. Find active tab at tabStrip
 * 3. Retrieve the module-element value of a tag
 * 4. Stop the currently opened module
 * 5. Remove the container
 * 6. Change the tab title
 * 7. Append new container
 *
 * @param {number} moduleId Unique identifier of the module
 * @param {string} nwid     View instance id of the view (given by server)
 * @return {string}         DOM selector for the new container
 */
function prepareCurrentTab(moduleId, nwid, settings) {
  if (settings.win && settings.win !== window) {
    return prepareCurrentLightWindow(moduleId, nwid, settings);
  }

  if (!mainTabStrip) {
    return prepareContainer(moduleId, nwid, settings);
  }

  const activeTab = mainTabStrip.getActiveTab();
  if (!activeTab) {
    return prepareNewTab(moduleId, nwid, settings);
  }

  const moduleName = getModuleName(nwid, settings);
  const moduleContainerId = composeModuleContainerId(moduleName, moduleId);
  const selector = selectorFromId(moduleContainerId);

  const moduleMgr = require('core/managers/module');
  const oldSelector = selectorFromId(mainTabStrip.getActiveModuleContainerId());
  const oldContainer = base.dom.find(oldSelector);
  const parentContainer = oldContainer.parent();
  const oldModuleId = extractModuleId(oldSelector);
  const oldModule = moduleMgr.getModuleById(oldModuleId);
  let navigator;
  if (oldModule) {
    navigator = oldModule.navigator;
    moduleMgr.stopModule(oldModuleId, true);
  }

  base.dom.remove(oldContainer);

  activeTab.setState({
    rootType: settings.rootType,
    text: settings.label || settings.name
  });

  base.dom.append(parentContainer, composeModuleContainerDiv(moduleContainerId));
  parentContainer.addClass(MODULE_PARENT_CONTAINER_CLASS);
  const domElement = document.getElementById(moduleContainerId);

  if (navigator) {
    // Reuse old navigator to prevent flickering
    updateNavigatorSettings(navigator, moduleId, moduleName, settings);
  } else {
    navigator = createNavigator(domElement, moduleId, moduleName, settings);
  }

  requestViewLinks(settings).then(viewLinks => navigator.setViewLinks(viewLinks, nwid));

  pubsub.publish('selected-main-module-changed', moduleId);
  pubsub.publish('active-selection-changed', { selected: [], moduleTarget: 'main' });

  return {
    tab: activeTab,
    selector,
    domElement,
    container: $(domElement.parentNode),
    navigator
  };
}

function prepareOverview(moduleId, nwid, settings) {
  const name = getModuleName(nwid, settings);
  const containerId = composeModuleContainerId(name, moduleId);
  const selector = selectorFromId(containerId);
  const overviewsTab = secondaryTabStrip.getOverviewsTab();
  const relevantTab = base.dom.find('.overview-content', overviewsTab.getContentElement());
  const layoutTemplate = base.dom.createNode(composeModuleContainerDiv(containerId, 'overview-item', true));
  const overviewNode = base.dom.createNode(OVERVIEW_HTML);
  const label = settings.label || settings.name;
  const splitter = base.dom.data('#main-wrapper', 'kendoSplitter');
  const overviewsWithoutMaxButton = [];

  // append module element to know which overview to close later
  base.dom.find('.min-max', overviewNode).attr('data-module-element', selector);
  base.dom.find('.close', overviewNode).attr('data-module-element', selector);

  // set title of the overview
  base.dom.find('.item-title', overviewNode).text(label);
  base.dom.attr(base.dom.find('.close', overviewNode), "title", translate("Close"));
  base.dom.attr(base.dom.find('.min-max', overviewNode), "title", translate("Open In Main Tab"));

  // append template to layout
  base.dom.append(layoutTemplate, overviewNode);

  // This is only a hack. For now we search to see if overview name is in overviewsWithoutMaxButton array.
  // If so we hide the max button.
  // TODO: Create a permanent solution to know which overview shouldn't have max button.
  if (overviewsWithoutMaxButton.indexOf(settings.name.toLowerCase()) > -1) {
    base.dom.css(base.dom.find('.min-max', overviewNode), 'display', 'none');
  }

  base.dom.append(relevantTab, layoutTemplate);

  return {
    tab: overviewsTab,
    selector: selector,
    domElement: base.dom.find(selector)[0],
    container: relevantTab
  };
}

function prepareUnplanned(moduleId, nwid, settings) {
  const name = getModuleName(nwid, settings);
  const containerId = composeModuleContainerId(name, moduleId);
  const selector = selectorFromId(containerId);
  const wrapperSelector = '#unplanned';
  const wrapper = base.dom.find(wrapperSelector);
  wrapper.addClass(MODULE_PARENT_CONTAINER_CLASS);
  const unplannedModules = wrapper.children();

  // find opened modules in unplanned
  const len = unplannedModules.length;
  for (var i = 0; i < len; i++) {
    secondaryCloseHandler(unplannedModules[i].id);
  }

  base.dom.append(wrapper, composeModuleContainerDiv(containerId, MODULE_CONTAINER_CLASS, true));

  const unplannedTab = secondaryTabStrip.getUnplannedTab();
  unplannedTab.select();
  unplannedTab.show();

  return {
    tab: unplannedTab,
    selector: selector,
    domElement: base.dom.find(selector)[0],
    container: $(selector)
  };
}

function prepareUpload(moduleId, nwid, settings) {
  const name = getModuleName(nwid, settings);
  const containerId = composeModuleContainerId(name, moduleId);
  const selector = selectorFromId(containerId);
  const wrapperSelector = '#upload';
  const wrapper = base.dom.find(wrapperSelector);
  wrapper.addClass(MODULE_PARENT_CONTAINER_CLASS);

  base.dom.append(wrapperSelector, composeModuleContainerDiv(containerId, MODULE_CONTAINER_CLASS));

  const uploadTab = secondaryTabStrip.getUploadTab();
  uploadTab.show();

  // ***TEST
  // const splitter = base.dom.data('#main-wrapper', 'kendoSplitter');
  // splitter.expand('#secondary');
  // uploadTab.select();

  return {
    tab: uploadTab,
    selector: selector,
    domElement: base.dom.find(selector)[0],
    container: wrapper
  };
}

function prepareUserInfo(moduleId, nwid, settings) {
  const name = getModuleName(nwid, settings);
  const containerId = composeModuleContainerId(name, moduleId);
  const selector = selectorFromId(containerId);
  const wrapperSelector = '#user';
  const color = settingsManager.get('skinBackgroundColor');
  if (color) {
    base.dom.css(wrapperSelector, 'backgroundColor', color);
  }
  base.dom.append(wrapperSelector, composeModuleContainerDiv(containerId, '', true));

  return {
    selector: selector,
    domElement: base.dom.find(selector)[0],
    container: base.dom.parent(wrapperSelector)
  };
}

function prepareInfoPanel(moduleId, nwid, settings) {
  const name = getModuleName(nwid, settings);
  const containerId = composeModuleContainerId(name, moduleId);
  const selector = selectorFromId(containerId);
  const rightSelector = '#right';

  const rightContainer = base.dom.find(rightSelector, '#main-wrapper');
  const moduleContainer = base.dom.find(`.${MODULE_RIGHT_CONTAINER_CLASS}`, rightContainer);
  const oldModuleId = extractModuleId(moduleContainer[0] ? moduleContainer[0].id : '');
  if (oldModuleId > 0) {
    require('core/managers/module').stopModule(oldModuleId);
  }
  rightContainer.empty();

  const header = (
    `<div class="header">
          <div class="title">${translate('Info Panel')}</div>
          <a class="close" data-module-element="${selector}">&times</a>
     <div>`
  );
  base.dom.append(rightContainer, header);
  base.dom.append(rightContainer, composeModuleContainerDiv(containerId, MODULE_RIGHT_CONTAINER_CLASS, true));

  if (settings.rootId) {
    showInfoPanelSplitbar();
    const splitter = base.dom.data('#center', 'kendoSplitter');
    splitter.expand('#right');
  }

  return {
    selector: selector,
    domElement: base.dom.find(selector)[0],
    container: rightContainer
  };
}

function secondaryCloseHandler(moduleSelector) {
  base.dom.remove(moduleSelector);

  const moduleId = extractModuleId(moduleSelector);
  require('core/managers/module').stopModule(moduleId);
}

function rightCloseHandler(moduleSelector) {
  const splitter = base.dom.data('#center', 'kendoSplitter');
  splitter.collapse('#right');
}

function maximizeModuleHandler(event) {
  const moduleSelector = event.target.dataset.moduleElement;
  const moduleId = extractModuleId(moduleSelector);
  const module = require('core/managers/module').getModuleById(moduleId);
  module.navigateFromOverview(event);
}

function getActive() {
  if (base.dom.find('.pro-widgets-contextmenu').length) {
    return 'contextmenu';
  }
  if (base.dom.find('.k-widget .k-window').length) {
    return 'dialog';
  }
  if (focus) {
    const tabStrip = $(focus).find(".k-widget.k-tabstrip").data("kendoTabStrip");
    const activeTabIndex = tabStrip.select().index();
    return getModuleContainerId(tabStrip.contentElement(activeTabIndex));
  }
}

function exposeElement(selector, target, animate) {
  if (!selector || !target) {
    return;
  }

  if (target === 'overview') {
    // when a user adds item to the overviews tab (not from startup call) then expand the 'secondary' panel
    animate && base.dom.data('#main-wrapper', 'kendoSplitter').expand('#secondary');
    exposeOverviewElement(selector, animate);
  } else if (target === 'main' || target === 'new') {
    exposeMainNavElement(selector);
  }
}

function exposeOverviewElement(selector, animate) {
  secondaryTabStrip.getOverviewsTab().select();

  const element = $(selector);
  const targetView = $('#overview');
  if (typeof element.offset() !== 'undefined' && typeof targetView.offset() !== 'undefined') {
    if (animate) {
      // Note: The $.animate() method allows us to create animation effects only on numeric CSS property
      base.dom.animate(targetView, {
        // The next expression scrolls item to the left or right depending on item position
        scrollLeft: element.offset().left - targetView.offset().left + targetView.scrollLeft()
      });

      element.addClass('highlite');
      setTimeout(() => {
        element.removeClass('highlite');
      }, 1000);
    }
  }
}

function exposeMainNavElement(module) {
  const index = mainTabStrip.getModuleIds().findIndex(id => id === module.id);
  if (index >= 0) {
    selectMainTab(index);
  }
}

function getMainModuleIds() {
  return mainTabStrip.getModuleIds();
}

function getOverviewsIds() {
  return base.dom.find('.overview-content', secondaryTabStrip.getOverviewsTab().getContentElement())
    .children().toArray().map(function (item, i) {
      return extractModuleId(item.id);
    });
}

function selectMainTab(index) {
  mainTabStrip.selectTab(index);
}

module.exports = {

  _name: 'desktop',
  _type: 'manager',

  /**
   * Create layout function loads configuration of
   * future layout. Configuration is loaded according to
   * one of the 3 options:
   * a) User provided configuration file
   * b) The desired layout is specified as the parameter of the called function
   * c) If none of the above, the default layout is loaded
   *
   * @param  {string} specificLayout Code of specified layout. Optional.
   */
  createDesktop,

  createContainer: async function (moduleId, nwid, config, event) {
    console.log('createContainer() => config:', config);
    const settings = getModuleSettings(nwid, config);
    switch (settings.target) {
      case 'navigation':
        base.events.trigger('focus', '#navigation');
        return prepareNavigationTab(moduleId, nwid, settings);
      case 'new':
        return prepareNewTab(moduleId, nwid, settings);
      case 'main':
        return prepareCurrentTab(moduleId, nwid, settings);
      case 'dialog':
        return prepareNewDialog(moduleId, nwid, settings);
      case 'overview':
        return prepareOverview(moduleId, nwid, settings);
      case 'unplanned':
        return prepareUnplanned(moduleId, nwid, settings);
      case 'upload':
        return prepareUpload(moduleId, nwid, settings);
      case 'user':
        return prepareUserInfo(moduleId, nwid, settings);
      case 'right':
        return prepareInfoPanel(moduleId, nwid, settings);
      case 'light_window':
        return prepareNewLightWindow(moduleId, nwid, settings, event);
      default:
        return prepareContainer(moduleId, nwid, settings);
    }
  },

  getActive,

  getMainModuleIds,

  getOverviewsIds,

  exposeElement,
  selectMainTab
};
