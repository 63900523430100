import {fromServerDate} from 'core/dates';

export default {
  productionRuns: (item, dataAdapter) => {
    if (!Array.isArray(item.productionRuns)) {
      return item;
    }

    const runMap = item.productionRuns.reduce((acc, run) => {
      if (!acc[run.press]) {
        acc[run.press] = [];
      }

      acc[run.press].push(run);

      return acc;
    }, {});

    const type = 'planpress';

    item['productionRuns:sorting'] = 'label:caseInsensitive';

    item.productionRuns = Object.keys(runMap).map((press, index) => {
      return {
        viewLinks: dataAdapter.getViewLinks(item.nwid, type),
        virtual: true,
        id: dataAdapter.composeVirtualId(item, index),
        type,
        nwid: item.nwid,
        childrenNames: ['children'],
        ['children:sorting']: 'presetDate:date',
        children: runMap[press].filter(run => run.dummyPress !== 'true').map(run => {
          run.presetDate = fromServerDate(run.presetTime);
          return run;
        }),
        label: press
      };
    });

    return item;
  },
};
