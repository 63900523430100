/**
 * @name reducers
 * @file Redux reducers functionality
 *
 * @author Boris
 * @since: 2016-06-23
 */

import SimpleForm from 'widgets/SimpleForm/src/index';
import utils from '../utils/utils';
import canvasUtils from '../utils/canvasUtils';
import Fabric from 'fabric';
import { initializeState } from './stateInitializer';
import ElementBase from '../elements/elementBase';
import { groupElementsByType } from './reducersCommon';
import alignmentsModule from '../elements/alignments';
import rulerGuidesModule from '../canvas/rulerGuides';

const futils = SimpleForm.utils;

const TYPES_ZORDER = ['plate', 'printArea', 'cellGrid', 'erase', 'regmark', 'furniture', 'textBurn', 'barcode', 'inkArea', 'guideline'];
const DISALLOW_DELETE_TYPES = ['plate', 'cellGrid', 'cell'];

const BORDER_ANIMATION_DURATION = 500;
const BORDER_COLOR = 'red';
const BORDER_SCALE_FACTOR = 0.2;
var defaultBorderColor;
var defaultBorderScaleFactor;

export default (editor) => {

  const elementBase = ElementBase(editor);
  const Alignments = alignmentsModule(editor);
  const RulerGuides = rulerGuidesModule(editor);
  const cutils = canvasUtils(editor);
  const canvas = editor.getMainCanvas();

  const init = () => {
    const state = initializeState(editor);

    repopulateShapes(state);

    return initHistory(state);
  };

  const initHistory = (state) => {
    state.repopulate = false;
    editor.history = [{ state, repopulate: false }];
    editor.historyCurrent = 0;

    return state;
  };

  /**
   * Add the given state after the current item to the state history array.
   * Copy the repopulate flag from the given state into the added history item.
   * Reset the repopulate flag to false in order to prepare the state for the future changes.
   *
   * @param state - the last state
   * @returns The new state
   */
  const addToHistory = (state) => {
    if (editor.historyCurrent < editor.history.length - 1) {
      editor.history = editor.history.slice(0, editor.historyCurrent + 1);
    }

    const newState = { ...state, repopulate: false };
    editor.history.push({ state: newState, repopulate: state.repopulate });
    editor.historyCurrent = editor.history.length - 1;

    return newState;
  };

  const undo = (state) => {
    if (editor.historyCurrent <= 0) {
      return state;
    }

    const current = editor.history[editor.historyCurrent];
    const previous = editor.history[--editor.historyCurrent];
    if (current.repopulate) {
      removeAllShapes(current.state);
    }

    updateShapes(previous.state, previous.state.layout);

    if (current.repopulate) {
      addAllShapes(previous.state);
    }

    activateSelected(previous.state);

    return previous.state;
  };

  const redo = (state) => {
    if (editor.historyCurrent >= editor.history.length - 1) {
      return state;
    }

    const current = editor.history[editor.historyCurrent];
    const next = editor.history[++editor.historyCurrent];
    if (next.repopulate) {
      removeAllShapes(current.state);
    }

    updateShapes(next.state, next.state.layout);

    if (next.repopulate) {
      addAllShapes(next.state);
    }

    activateSelected(next.state);

    return next.state;
  };

  const updateShapes = (state, parent) => {
    if (!parent || typeof parent !== 'object') {
      return;
    }

    if (parent.elementType && parent.shape && !parent.locked) {
      elementBase.updateShape(state, parent);
    }

    for (var key in parent) {
      if (key !== 'shape' && parent[key] && typeof parent[key] === 'object') {
        updateShapes(state, parent[key]);
      }
    }
  };

  const activateSelected = (state) => {
    if (state.selectedElementPath) {
      var element = futils.get(state, state.selectedElementPath);
      if (element && element.elementType && element.shape) {
        elementBase.activateShape(state, element);
      }
    }
  };

  const addAllShapes = (state) => {
    //var t0 = performance.now();

    var elements = sortElementsByZorder(state);
    for (var i = 0; i < elements.length; i++) {
      elementBase.addShape(state, elements[i]);
    }

    // var t1 = performance.now();
    // console.log("addAllShapes() => " + (t1 - t0) + " milliseconds.")
  };

  const removeAllShapes = (state) => {
    cutils.removeAllObjects();
  };

  const repopulateShapes = (state) => {
    removeAllShapes(state);
    addAllShapes(state);
  };

  const sortElementsByZorder = (state) => {
    const sortedElements = [];

    const elementsByType = groupElementsByType(state, ['cellGrid']);
    const furnituresUp = [];
    const furnituresDown = [];
    const furnitures = elementsByType['furniture'] || [];
    furnitures.forEach(f => {
      f.onTopOfCells ? furnituresUp.push(f) : furnituresDown.push(f);
    });
    elementsByType['furniture0'] = furnituresDown;
    elementsByType['furniture'] = furnituresUp;

    const typesZorder = [...TYPES_ZORDER];
    typesZorder.splice(TYPES_ZORDER.indexOf('cellGrid'), 0, 'furniture0');

    for (var i = 0; i < typesZorder.length; i++) {
      var elements = elementsByType[typesZorder[i]];
      if (elements) {
        for (var j = 0; j < elements.length; j++) {
          sortedElements.push(elements[j]);
        }
      }
    }

    return sortedElements;
  };

  const findGroupInfoById = (groups, id) => {
    var idx = groups.findIndex(g => g.id === id);
    if (idx >= 0) {
      return {
        group: groups[idx],
        groupIndex: idx
      };
    }
  };

  const findGroupInfoByType = (groups, type) => {
    var idx = groups.findIndex(g => g.elementType === type);
    if (idx >= 0) {
      return {
        group: groups[idx],
        groupIndex: idx
      };
    }
  };

  const findItemInfoById = (groups, id) => {
    for (var i = 0; i < groups.length; i++) {
      var itemIndex = groups[i].children.findIndex(item => item.id === id);
      if (itemIndex >= 0) {
        return {
          group: groups[i],
          groupIndex: i,
          item: groups[i].children[itemIndex],
          itemIndex: itemIndex
        };
      }
    }
  };

  const findButtonIndexById = (group, id) => {
    var idx = group.buttons.findIndex(b => b.id === id);
    return idx;
  };

  const findButtonInfoById = (group, id) => {
    var idx = group.buttons.findIndex(b => b.id === id);
    if (idx >= 0) {
      return {
        button: group.buttons[idx],
        buttonIndex: idx
      };
    }
  };

  const findGroupByType = (groups, type) => {
    return groups.find(g => g.elementType === type);
  };

  const toggleCollapse = (state, groupPath) => {
    //console.log("reducers.toggleCollapse() => groupPath=", groupPath);
    if (!groupPath) {
      return state;
    }

    var newState = state;
    var expandedGroupPath = futils.get(newState, 'expandedGroupPath');
    expandedGroupPath = expandedGroupPath === groupPath ? '' : groupPath;
    newState = futils.update(newState, 'expandedGroupPath', expandedGroupPath);

    return newState;
  };

  const shapeSelected = (state, shape) => {
    //console.log("reducers.shapeSelected() => shape=", shape);
    var elementPath = !shape ? '' : shape.elementPath;

    return selectElement(state, elementPath, false, false);
  };

  const selectElement = (state, elementPath, shouldActivateShape = true, shouldScrollToShape = true) => {
    //console.log("reducers.selectElement() => elementPath=", elementPath);
    if (state.selectedElementPath === elementPath) {
      if (shouldScrollToShape) {
        editor.scrollToSelectedObject();
        animateSelectedElement();
      }

      return state;
    }

    var newState = state;
    newState = futils.update(newState, 'selectedElementPath', elementPath);

    var element = !elementPath ? null : futils.get(newState, elementPath);
    if (element) {
      if (shouldActivateShape) {
        elementBase.activateShape(newState, element);
        if (shouldScrollToShape) {
          editor.scrollToSelectedObject();
          animateSelectedElement();
        }
      }

      var group = findGroupByType(newState.elementGroups, element.elementType);
      if (group) {
        newState = futils.update(newState, 'expandedGroupPath', group.path);
      }
    }

    return newState;
  };

  const animateSelectedElement = () => {
    var activeObject = canvas.getActiveObject();
    if (!activeObject) {
      return;
    }

    defaultBorderColor = defaultBorderColor || activeObject.get('borderColor');
    defaultBorderScaleFactor = defaultBorderScaleFactor || activeObject.get('borderScaleFactor');
    Fabric.util.animate({
      startValue: BORDER_SCALE_FACTOR,
      endValue: defaultBorderScaleFactor,
      duration: BORDER_ANIMATION_DURATION,
      onChange: function (value) {
        activeObject.set('borderColor', BORDER_COLOR);
        activeObject.set('borderScaleFactor', value);
        canvas.renderAll();
      },
      onComplete: function () {
        activeObject.set('borderColor', defaultBorderColor);
        activeObject.set('borderScaleFactor', defaultBorderScaleFactor);
        canvas.renderAll();
      }
    });
  };

  const getReferencedElement = (state, canvasShape) => {
    if (canvasShape && canvasShape.shape && canvasShape.shape.elementPath) {
      var elementPath = canvasShape.shape.elementPath;
      var element = futils.get(state, elementPath);
      if (element && element.shape) {
        return element;
      }
    }
  };

  const moveShape = (state, canvasShape, event) => {
    const element = getReferencedElement(state, canvasShape);
    if (!element || element.locked) {
      return state;
    }

    if (Alignments.registerAlignments(state, element)) {
      Alignments.snapAndDrawAlignments(state, element, canvasShape, event);
    }

    let newState = state;
    const newElement = elementBase.moveShape(newState, element, canvasShape, event);
    if (newElement !== element) {
      newState = futils.update(newState, canvasShape.shape.elementPath, newElement);
      newState = elementChanged(newState, element, newElement);
    }

    return newState;
  };

  const shapeTransforming = (state, canvasShape, event) => {
    //console.log("reducers.shapeTransforming()");
    const element = getReferencedElement(state, canvasShape);
    if (!element || element.locked) {
      return state;
    }


    if (Alignments.registerAlignments(state, element)) {
      Alignments.snapAndDrawAlignments(state, element, canvasShape, event);
    }

    let newState = state;
    const newElement = elementBase.shapeTransforming(newState, element, canvasShape, event);
    if (newElement !== element) {
      newState = futils.update(newState, canvasShape.shape.elementPath, newElement);
      newState = elementChanged(newState, element, newElement);
    }

    return newState;
  };

  /**
   * Handle canvas modified event.
   * Note: the cell image modified event is not fired by fabric so we have to handle it in the
   * shapeMouseUp() method
   *
   * @param state
   * @param canvasShape
   * @returns new state
   */
  const shapeModified = (state, canvasShape) => {
    //console.log("reducers.shapeModified()");
    const element = getReferencedElement(state, canvasShape);
    if (!element) {
      return state;
    }

    let newState = shapeTransforming(state, canvasShape);

    return addToHistory(newState);
  };

  const shapeMouseDown = (state, canvasShape, event) => {
    //console.log("reducers.shapeMouseDown() 0");

    const element = getReferencedElement(state, canvasShape);
    if (!element) {
      return state;
    }

    let newState = state;
    const newElement = elementBase.shapeMouseDown(newState, element, canvasShape, event);
    //console.log("reducers.shapeMouseDown() 1");
    if (newElement !== element) {
      //console.log("reducers.shapeMouseDown() 2");
      newState = futils.update(newState, canvasShape.shape.elementPath, newElement);
    }

    return newState;
  };

  const shapeMouseUp = (state, canvasShape, event, shouldAddToHistory) => {
    //console.log("reducers.shapeMouseUp()");
    Alignments.unregisterAlignments();

    const element = getReferencedElement(state, canvasShape);
    if (!element) {
      return state;
    }

    let newState = state;
    const newElement = elementBase.shapeMouseUp(newState, element, canvasShape, event);
    if (newElement !== element || shouldAddToHistory) {
      newState = futils.update(newState, canvasShape.shape.elementPath, newElement);
      newState = addToHistory(newState);
    }

    return newState;
  };

  const mouseMove = (state, event) => {
    //console.log("reducers.mouseMove()");

    RulerGuides.drawRulerBars(state, event);

    return state;
  };

  const shapeTextEvent = (state, canvasShape, eventName) => {
    //console.log("reducers.shapeTextEvent()");
    const element = getReferencedElement(state, canvasShape);
    if (!element) {
      return state;
    }

    let newState = state;
    const newElement = elementBase.shapeTextEvent(newState, element, canvasShape, eventName);
    if (newElement !== element) {
      newState = futils.update(newState, canvasShape.shape.elementPath, newElement);
      newState = elementChanged(newState, element, newElement);

      newState = addToHistory(newState);
    }

    return newState;
  };

  const getSelectedAddOption = (group) => {
    var optionId = '';
    var button = group.buttons.find(b => b.id === 'add');
    if (button && button.options) {
      optionId = button.value;
    }

    return optionId;
  };

  const selectAddOption = (state, groupPath, optionId) => {
    var newState = state;
    var group = futils.get(newState, groupPath);
    var buttonIndex = findButtonIndexById(group, 'add');
    var path = futils.compose(groupPath, 'buttons', buttonIndex, 'value');
    newState = futils.update(newState, path, optionId);

    return addToHistory(newState);
  };

  const addNewElement = (state, group) => {
    //console.log("@@@@@reducers.addNewElement()=> group=", group);
    const element = elementBase.buildDefaultElement(state, group.elementType, getSelectedAddOption(group));
    cutils.shiftElementCoords(state.plateRectangle, element);

    return addElement(state, group, element);
  };

  const addElement = (state, group, element, shouldActivateShape = true, shouldScrollToShape = true) => {
    //console.log("@@@@@reducers.addElement()=> group=", group);
    let newState = state;

    const info = elementBase.getInfo(newState, element.elementType);
    element.name = makeDefaultName(newState, element.elementType, element.name || info.title);
    const elements = futils.get(newState, info.basePath);
    if (!elements) {
      newState = futils.update(newState, info.basePath, utils.createPseudoArray());
    }
    const index = utils.maxKey(elements) + 1;
    const elementPath = futils.compose(info.basePath, index);
    elementBase.createShape(newState, element, elementPath);
    newState = futils.update(newState, elementPath, element);
    newState = elementBase.arrangeElements(newState, element.elementType);

    newState = addChildToGroup(newState, group, element, elementPath);
    newState = selectElement(newState, elementPath, shouldActivateShape, shouldScrollToShape);
    newState.repopulate = true;

    repopulateShapes(newState);

    return addToHistory(newState);
  };

  const addChildToGroup = (state, group, element, elementPath) => {
    var newState = state;
    var child = {
      elementPath: elementPath
    };
    var childrenPath = futils.compose(group.path, 'children');
    var children = futils.get(newState, childrenPath);
    var index = children.length;
    newState = futils.add(newState, childrenPath, index, child);
    return newState;
  };

  const makeDefaultName = (state, elementType, name) => {
    var elements = findElementsOfType(state.layout, elementType);
    var names = elements.map(element => element.name);
    var defaultName = utils.makeUniqueName(names, name);

    return defaultName;
  };

  const findElementsOfType = (parent, elementType) => {
    var result = [];
    for (var key in parent) {
      var obj = parent[key];
      if (obj && typeof obj === 'object' && key !== 'shape') {
        if (obj.elementType === elementType) {
          result.push(obj);
        } else {
          result = result.concat(findElementsOfType(obj, elementType));
        }
      }
    }

    return result;
  };

  const addFurnitureOption = (state, furniture) => {
    //console.log("reducers.addFurnitureOption() furniture=", furniture);
    if (!furniture || !furniture.name) {
      return state;
    }

    editor.reloadFurnitures();

    var newState = state;
    var group = findGroupByType(newState.elementGroups, 'furniture');
    if (group) {
      var buttonInfo = findButtonInfoById(group, 'add');
      if (buttonInfo) {
        var optionId = furniture.name;
        var options = buttonInfo.button.options;
        var idx = options.findIndex(o => o.value === optionId);
        if (idx < 0) {
          var path = futils.compose(group.path, 'buttons', buttonInfo.buttonIndex, 'options');
          newState = futils.add(newState, path, options.length, {
            value: optionId,
            text: optionId,
            imageType: furniture.type
          });
        }

        newState = selectAddOption(newState, group.path, optionId);
      }
    }

    return newState;
  };

  const cleanupFurnitures = (state) => {
    const group = findGroupByType(state.elementGroups, 'furniture');
    if (!group) {
      return state;
    }

    let newState = state;
    const children = group.children.filter(f => {
      let found = true;
      const element = futils.get(newState, f.elementPath);
      if (element && !utils.findFurnitureByImageName(editor.furnitures, element.imageName)) {
        newState = futils.removeItem(newState, f.elementPath);
        elementBase.removeShape(newState, element);
        found = false;
      }

      return found;
    });

    newState = futils.update(newState, futils.compose(group.path, 'children'), children);

    const buttonInfo = findButtonInfoById(group, 'add');
    const buttonPath = futils.compose(group.path, 'buttons', buttonInfo.buttonIndex);
    const options = buttonInfo.button.options.filter(o => editor.furnitures.some(f => f.name === o.text));
    newState = futils.update(newState, futils.compose(buttonPath, 'options'), options);
    if (!editor.furnitures.some(f => f.name === buttonInfo.button.value)) {
      newState = futils.update(newState, futils.compose(buttonPath, 'value'), options[0]?.text || '');
    }

    newState.repopulate = true;

    return addToHistory(newState);
  };

  const deleteElement = (state, elementPath) => {
    const element = futils.get(state, elementPath);
    if (!element || element.locked || !element.elementType || DISALLOW_DELETE_TYPES.indexOf(element.elementType) >= 0) {
      return state;
    }

    let newState = state;
    newState = futils.removeItem(newState, elementPath);
    elementBase.removeShape(newState, element);
    const groupInfo = findGroupInfoByType(newState.elementGroups, element.elementType);
    if (groupInfo) {
      const index = groupInfo.group.children.findIndex(item => item.elementPath === elementPath);
      const groupPath = futils.compose('elementGroups', groupInfo.groupIndex, 'children');
      newState = futils.remove(newState, groupPath, index);
    }

    newState.repopulate = true;

    return addToHistory(newState);
  };

  const deleteSelectedElement = (state) => {
    if (!state.selectedElementPath) {
      return state;
    }

    return deleteElement(state, state.selectedElementPath);
  };

  const duplicateElement = (state, elementPath) => {
    //console.log("reducers.duplicateElement()=> elementPath=", elementPath);
    const element = futils.get(state, elementPath);
    if (!element) {
      return state;
    }

    let newState = state;
    const elementType = element.elementType;
    const group = findGroupByType(newState.elementGroups, elementType);

    const clone = utils.cloneElement(element);
    clone.locked = false;
    clone._selectable = true;
    cutils.shiftElementCoords(newState.plateRectangle, clone);
    const proposedName = element.name + ' - Copy';
    clone.name = makeDefaultName(newState, elementType, proposedName);
    const info = elementBase.getInfo(newState, elementType);
    const elements = futils.get(newState, info.basePath);
    if (!elements) {
      newState = futils.update(newState, info.basePath, utils.createPseudoArray());
    }
    const index = utils.maxKey(elements) + 1;
    const clonePath = futils.compose(info.basePath, index);
    elementBase.createShape(newState, clone, clonePath);
    newState = futils.update(newState, clonePath, clone);
    newState = elementBase.arrangeElements(newState, elementType);

    newState = addChildToGroup(newState, group, clone, clonePath);
    newState = selectElement(newState, clonePath);
    newState.repopulate = true;

    return addToHistory(newState);
  };

  const getCellGrid = (state) => {
    var info = elementBase.getInfo(state, 'cellGrid');
    var cellGrid = futils.get(state, info.basePath);
    return cellGrid;
  };

  const updateFormProperty = (state, path, value, forceUpdate) => {
    //console.log("reducers.updateFormProperty() path=", path + ", value=" + value);
    if (futils.get(state, path) === value && !forceUpdate) {
      // return the same state because the value has not been changed and forceUpdate is not true
      return state;
    }

    let newState = state;
    const element = futils.get(newState, newState.selectedElementPath);
    if (element && futils.pathHead(path) === 'layout') {
      newState = elementBase.updateProperty(newState, element, newState.selectedElementPath, path, value);
      const key = futils.pathTail(path);
      if (element.elementType === 'plate') {
        if (key === 'width' || key === 'height') {
          newState = futils.update(newState, futils.compose('plateRectangle', key), utils.systemToCanvasUnits(value));
          updateShapes(newState, newState.layout);
        }
      } else if (element.elementType === 'cell') {
        if (key === 'rowSpan' || key === 'columnSpan' || key === 'rotation') {
          const info = elementBase.getInfo(newState, 'cellGrid');
          const cellGrid = futils.get(newState, info.basePath);
          newState = elementBase.updateProperty(newState, cellGrid, info.basePath, path, value);
        }
      }

      const newElement = futils.get(newState, newState.selectedElementPath);
      newState = elementChanged(newState, element, newElement);

      if (newState.repopulate) {
        repopulateShapes(newState);
      }
    } else {
      newState = futils.update(newState, path, value);
    }

    return addToHistory(newState);
  };

  const elementChanged = (state, oldSource, newSource) => {
    let newState = state;

    const cellChanged = oldSource.elementType === 'cell' && !utils.areValuesEqual(oldSource, newSource,
      ['x', 'y', 'width', 'height']);
    const cellSpanChanged = oldSource.elementType === 'cell' && !utils.areValuesEqual(oldSource, newSource,
      ['rowSpan', 'columnSpan']);
    const gridChanged = oldSource.elementType === 'cellGrid' && !utils.areValuesEqual(oldSource, newSource,
      ['useGaps', 'rows', 'columns', 'cellWidth', 'cellHeight', 'rowGaps', 'columnGaps']);

    if (cellChanged || gridChanged) {
      const cropMarksPath = elementBase.getInfo(newState, 'cropMarks').basePath;
      const cropMarks = futils.get(newState, cropMarksPath);
      newState = elementBase.dependencyChanged(newState, cropMarks, cropMarksPath, oldSource, newSource);
    }

    if (gridChanged || cellSpanChanged) {
      const centerMarksPath = elementBase.getInfo(newState, 'centerMarks').basePath;
      const centerMarks = futils.get(newState, centerMarksPath);
      newState = elementBase.dependencyChanged(newState, centerMarks, centerMarksPath, oldSource, newSource);
    }

    return newState;
  };

  const updateCellImage = (state, elementPath, imageInfo) => {
    let newState = state;

    const element = futils.get(newState, elementPath);
    if (element) {
      const newElement = {
        ...element,
        ...imageInfo
      };

      newState = futils.update(newState, elementPath, newElement);

      // update image path and load image
      newState = updateFormProperty(newState, futils.compose(elementPath, 'imagePath'), imageInfo.imagePath, true);
    }

    return newState;
  };

  const addFormProperty = (state, path, index, value) => {
    //console.log("reducers.addFormProperty() path=", path + ", index=" + index + ", value=" + value);

    const newState = futils.add(state, path, index, value);

    return addToHistory(newState);
  };

  const removeFormProperty = (state, path, index, value) => {
    //console.log("reducers.removeFormProperty() path=", path + ", index=" + index + ", value=" + value);

    const newState = futils.remove(state, path, index, value);

    return addToHistory(newState);
  };

  const nudgeShape = (state, direction) => {
    //console.log("reducers.nudgeShape() direction=", direction);
    let newState = state;
    const elementPath = newState.selectedElementPath;
    if (elementPath) {
      const element = futils.get(newState, elementPath);
      if (element && element.elementType && element.shape) {
        const newElement = elementBase.nudgeShape(newState, element, direction);
        if (newElement !== element) {
          newState = futils.update(newState, elementPath, newElement);
          newState = elementChanged(newState, element, newElement);
          newState = addToHistory(newState);
        }
      }
    }

    return newState;
  };

  const save = (state) => {
    editor.save(state);

    return state;
  };

  const zoomChanged = (state) => {
    var cellGrid = getCellGrid(state);
    if (cellGrid) {
      elementBase.updateShape(state, cellGrid);
    }
  };

  const zoomOut = (state) => {
    var oldZoom = canvas.getZoom();
    var newZoom = editor.canvasZoomOut();
    if (newZoom !== oldZoom) {
      zoomChanged(state);
    }

    return state;
  };

  const zoomIn = (state) => {
    var oldZoom = canvas.getZoom();
    var newZoom = editor.canvasZoomIn();
    if (newZoom !== oldZoom) {
      zoomChanged(state);
    }

    return state;
  };

  const toggleAlignments = (state) => {
    return { ...state, showAlignments: !state.showAlignments };
  };

  const createGuideline = (state, orientation, event) => {
    let newState = state;

    const elementType = 'guideline';
    const element = elementBase.buildDefaultElement(newState, elementType);
    element.orientation = orientation;
    const ptr = canvas.getPointer(event);
    const p = utils.toSystemXY(newState.plateRectangle, ptr.x, ptr.y, element.referenceX, element.referenceY);
    if (orientation === 'horizontal') {
      element.y = p.y;
    } else {
      element.x = p.x;
    }

    const group = findGroupByType(newState.elementGroups, elementType);
    newState = addElement(newState, group, element, true, false);

    return newState;
  };

  const setDialogVisible = (state, visible) => {
    return { ...state, dialogVisible: visible };
  };

  return {
    elementsReducer: (state, action) => {
      switch (action.type) {
        case 'UNDO':
          return undo(state);
        case 'REDO':
          return redo(state);
        case 'UPDATE':
          return updateFormProperty(state, action.path, action.value);
        case 'UPDATE_CELL_IMAGE':
          return updateCellImage(state, action.elementPath, action.imageInfo);
        case 'ADD':
          return addFormProperty(state, action.path, action.index, action.value);
        case 'REMOVE':
          return removeFormProperty(state, action.path, action.index, action.value);
        case 'TOGGLE_COLLAPSE':
          return toggleCollapse(state, action.id);
        case 'SHAPE_SELECTED':
          return shapeSelected(state, action.shape);
        case 'SELECT_ELEMENT':
          return selectElement(state, action.elementPath);
        case 'MOVE_SHAPE':
          return moveShape(state, action.canvasShape, action.event);
        case 'SHAPE_TRANSFORMING':
          return shapeTransforming(state, action.canvasShape, action.event);
        case 'SHAPE_MODIFIED':
          return shapeModified(state, action.canvasShape);
        case 'SHAPE_MOUSE_DOWN':
          return shapeMouseDown(state, action.canvasShape, action.event);
        case 'SHAPE_MOUSE_UP':
          return shapeMouseUp(state, action.canvasShape, action.event, action.shouldAddToHistory);
        case 'MOUSE_MOVE':
          return mouseMove(state, action.event);
        case 'SHAPE_TEXT_EVENT':
          return shapeTextEvent(state, action.canvasShape, action.eventName);
        case 'SELECT_ADD_OPTION':
          return selectAddOption(state, action.groupPath, action.optionId);
        case 'ADD_NEW_ELEMENT':
          return addNewElement(state, action.group);
        case 'DELETE_ELEMENT':
          return deleteElement(state, action.elementPath);
        case 'DELETE_SELECTED_ELEMENT':
          return deleteSelectedElement(state);
        case 'DUPLICATE_ELEMENT':
          return duplicateElement(state, action.elementPath);
        //case 'IMPORT_ELEMENT':
        //  return importElement(state, action.elementType);
        case 'ADD_FURNITURE_OPTION':
          return addFurnitureOption(state, action.furniture);
        case 'CLEANUP_FURNITURES':
          return cleanupFurnitures(state, action.furnitures);
        case 'NUDGE_SHAPE':
          return nudgeShape(state, action.direction);
        case 'SAVE':
          return save(state);
        case 'ZOOM_OUT':
          return zoomOut(state);
        case 'ZOOM_IN':
          return zoomIn(state);
        case 'TOGGLE_ALIGNMENTS':
          return toggleAlignments(state);
        case 'CREATE_GUIDELINE':
          return createGuideline(state, action.orientation, action.event);
        case 'SET_DIALOG_VISIBLE':
          return setDialogVisible(state, action.visible);
        default:
          if (!state) {
            return init();
          }
      }

      return state;
    }
  }

};
