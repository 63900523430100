import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utilities/classNames';

function DialogButtonsBar({ children, align = 'right', className, style }) {
  const barClassName = classNames('crtx-dialog-buttons-bar', className, {
    'crtx-align-content-left': align === 'left',
    'crtx-align-content-center': align === 'center',
    'crtx-align-content-right': align === 'right',
    'crtx-align-content-stretch': align === 'stretch'
  });

  return (
    <div className={barClassName} style={style}>
      {children}
    </div>
  );

}

DialogButtonsBar.propTypes = {
  children: PropTypes.any,
  align: PropTypes.oneOf(['left', 'center', 'right', 'stretch']),
  className: PropTypes.string,
  style: PropTypes.object,
};

export default DialogButtonsBar;