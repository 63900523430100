import React, { Component } from 'react';
import sandbox from 'sandbox';
import Header from 'src/components/common/headers/Header';
import PropTypes from 'prop-types';

const translate = sandbox.localization.translate;
const labels = {
  contentSites: translate('Content Sites')
}

module.exports = class Sites extends Component {
  constructor(props) {
    super(props);
    this.getSites = this.getSites.bind(this);
    this.getContentSites = this.getContentSites.bind(this);
  }

  static propTypes = {
    sites: PropTypes.any,
    title: PropTypes.string,
    openerType: PropTypes.string//dialog or rightPanel
  }

  getSites() {
    const { sites } = this.props;
    const sitesList = sites.join(', ');
    return <span className='siteRow ellipsis' title={sitesList}>{sitesList}</span>;
  };

  getContentSites() {
    const { contentSites } = this.props;
    const contentSitesList = contentSites.join(', ');
    return <span className='siteRow ellipsis' title={contentSitesList}>{contentSitesList}</span>;
  };

  render() {
    const { title, extraInfo = {} } = this.props;
    const bold = extraInfo.significant ? 'bold' : '';
    return <div className='sites-custom-info'>
      {!extraInfo.significant && <React.Fragment>
        <Header>{title}</Header>
        <div className='custom-info-row bold'>{this.getSites()}</div>
      </React.Fragment>}
      <Header>{labels.contentSites}</Header>
      <div className={`custom-info-row ${bold}`}>{this.getContentSites()}</div>
    </div>;
  }
}