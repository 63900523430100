import React, {Component} from 'react';
import {breadcrumbsToNames} from 'utilities/breadcrumbs';

export default function StringBreadcrumbs(props) {
  const {currentBreadcrumbs, compareTo} = props;
  const currentBreadcrumbsNames = breadcrumbsToNames(currentBreadcrumbs);
  const compareToNames = breadcrumbsToNames(compareTo);

  const breadcrumbsElem = currentBreadcrumbsNames.reduce(
    (acc, name, index, arr) => {
      const nameElem = name === compareToNames[index] ? name : <b>{name}</b>;
      acc.push(<span key={index}>{nameElem}</span>);
      if (index < arr.length - 1) {
        acc.push(' > ');
      }

      return acc;
    }, []);

  return (
    <div className='ellipsis-before'>
      {breadcrumbsElem}
    </div>
  );
};

