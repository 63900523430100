/**
 * @name Tree
 * @file Tree component
 *
 * @author Boris
 * @since: 2016-12-21
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import { classNames } from 'utilities/classNames';
import Expander from 'components/common/buttons/Expander';

const DOUBLE_CLICK_INTERVAL = 300;

const composeKey = (id, index) => {
  return `${id}_${index}`;
};

const getIndentCount = (nodePath = '') => {
  return Math.max(0, nodePath.split('.').length - 1);
};

const hasExpander = (node, nodeProps = {}) => {
  return !!(node && nodeProps.children);
};

module.exports = class Tree extends Component {
  static propTypes = {
    /**
     * Tree model containing array of root nodes
     */
    nodes: PropTypes.array.isRequired,

    /**
     * CSS class name of the roots container
     */
    className: PropTypes.string,

    /**
     * CSS style of the roots container
     */
    style: PropTypes.object,

    /**
     * Single indent width in pixels
     */
    indentWidth: PropTypes.number,

    /**
     * Tree node render function responsible for rendering individual tree nodes
     */
    treeNodeGetter: PropTypes.func.isRequired,

    /**
     * Optional callback function for mapping tree model node object fields to node properties expected by Tree component
     * Should be used only when the tree model node object fields do not match to the properties expected by Tree component
     * Returnes tree node data object that contains properties required for rendering:
     *   id (string): node unique ID
     *   children (array): children nodes
     *   expanded (bool): defines whether tree node is expanded
     *   selected (bool): defines whether tree node is selected
     *   status (bool): node children loading status ('loading' | 'loaded | 'failed')
     */
    nodePropsGetter: PropTypes.func,

    /**
     * Callback event handling functions with the following common paramaters:
     *   event (object) - original event object
     *   node (object) - the target tree node
     *   nodePath (string) - zero based target node path in the tree (e.g. '0.1' - the second child of the root node)
     */
    onExpanderClick: PropTypes.func,
    onNodeClick: PropTypes.func,
    onNodeDoubleClick: PropTypes.func,
    onNodeContextMenu: PropTypes.func
  };

  static defaultProps = {
    className: '',
    indentWidth: 16,
    nodePropsGetter: node => node
  };

  isDoubleClick(id) {
    const lastClickTime = this.lastClickTime;
    const lastClickId = this.lastClickId;

    const now = Date.now();
    this.lastClickTime = now;
    this.lastClickId = id;

    return (now - lastClickTime < DOUBLE_CLICK_INTERVAL && lastClickId === id);
  }

  handleExpanderClick = (event, node, nodePath, nodeProps) => {
    if (this.isDoubleClick(nodePath)) {
      return;
    }

    const { onExpanderClick } = this.props;
    if (typeof onExpanderClick === 'function') {
      onExpanderClick(event, node, nodePath, !nodeProps.expanded);
    }

    event.stopPropagation();
  };

  handleNodeClick(event, node, nodePath) {
    if (this.isDoubleClick(nodePath)) {
      return;
    }

    const { onNodeClick } = this.props;
    if (typeof onNodeClick === 'function') {
      onNodeClick(event, node, nodePath);
    }
  }

  handleNodeDoubleClick(event, node, nodePath) {
    const { onNodeDoubleClick } = this.props;

    if (typeof onNodeDoubleClick === 'function') {
      onNodeDoubleClick(event, node, nodePath);
    }
  }

  handleNodeContextMenu(event, node, nodePath) {
    const { onNodeContextMenu } = this.props;

    if (typeof onNodeContextMenu === 'function') {
      onNodeContextMenu(event, node, nodePath);
    }
  }

  renderIndents(node, nodePath, nodeProps) {
    const indentCount = getIndentCount(nodePath);
    if (indentCount <= 0) {
      return null;
    }

    const { indentWidth } = this.props;
    const style = { width: indentCount * indentWidth };

    return (
      <div
        className='crtx-tree-node-indents'
        style={style}
        onClick={hasExpander(node, nodeProps) ? event => this.handleExpanderClick(event, node, nodePath, nodeProps) : undefined}
      />
    );
  }

  renderExpander(node, nodePath, nodeProps) {
    if (!hasExpander(node, nodeProps)) {
      return (
        <div className='crtx-tree-expander-placeholder' />
      );
    }

    return (
      <Expander
        expanded={nodeProps.expanded}
        className={'crtx-tree-expander'}
        onClick={event => this.handleExpanderClick(event, node, nodePath, nodeProps)}
      />
    );
  }

  renderChildren(node, index, nodePath, nodeProps) {
    if (!nodeProps.expanded || !nodeProps.children) {
      return null;
    }

    const key = composeKey(nodeProps.id, index);
    if (nodeProps.status === 'loading') {
      return (
        <div key={key} className='crtx-tree-node-loading'>
          {this.renderIndents(node, nodePath)}
          {this.renderExpander(node, nodePath)}
          {translate('Loading...')}
        </div>
      );
    }

    return (
      <ul key={key} className='crtx-tree-node-children'>
        {nodeProps.children.map((node, index) => this.renderSubtree(node, index, nodePath))}
      </ul>
    );
  }

  renderChildrenDrawer(node, index, nodePath, nodeProps) {
    return !nodeProps.expanded ? null : this.renderChildren(node, index, nodePath, nodeProps);
  }

  renderTreeNode(node, nodePath, nodeProps) {
    return this.props.treeNodeGetter(node, nodePath, nodeProps);
  }

  renderSubtree(node, index, nodePath) {
    const nodeProps = this.props.nodePropsGetter(node);
    nodePath = !nodePath ? `${index}` : `${nodePath}.${index}`;
    const key = composeKey(nodeProps.id, index);

    return (
      <li key={key} className='crtx-subtree'>
        <div className={classNames('crtx-tree-node-header', { selected: nodeProps.selected })}
             onClick={event => this.handleNodeClick(event, node, nodePath)}
             onDoubleClick={event => this.handleNodeDoubleClick(event, node, nodePath)}
             onContextMenu={event => this.handleNodeContextMenu(event, node, nodePath)}
        >
          {this.renderIndents(node, nodePath, nodeProps)}
          {this.renderExpander(node, nodePath, nodeProps)}
          {this.renderTreeNode(node, nodePath, nodeProps)}
        </div>
        {this.renderChildrenDrawer(node, index, nodePath, nodeProps)}
      </li>
    );
  }

  render() {
    const { nodes, className, style } = this.props;

    return (
      <ul className={classNames('crtx-tree', className)} style={style}>
        {nodes.map((node, index) => this.renderSubtree(node, index))}
      </ul>
    );
  }

};
