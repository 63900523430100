import sandbox from 'sandbox';
import utils from './core/utils';

const getInitialColumns = (
  children /*object - representing the current columns by the table children */
) => {
  return children.toArray().reduce((initialColumns, column) => {
    initialColumns[column.props.columnKey] = {
      width: column.props.width,
      visible: column.props.visible
    };

    return initialColumns;
  }, {});
};

const getTotalColumnsWidth = (
  columns /*object - representing the current columns */
) => {
  var totalColumnsWidth = 0;

  for (var columnKey in columns) {
    if (utils.isVisible(columns[columnKey])) {
      totalColumnsWidth += columns[columnKey].width;
    }
  }

  return totalColumnsWidth;
};

const getNonFlexColumnsWidth = (
  columns, /*object - representing the current columns */
  children
) => {
  return children.filter(utils.getVisibleNotFlexChilds(columns)).reduce((columnsWidth, child) => {
    return columnsWidth + columns[child.props.columnKey].width;
  }, 0);
};

const getFlexColumnsWidth = (
  currentTableWidth,
  columns,
  children
) => {
  const totalColumnsWidth = getNonFlexColumnsWidth(columns, children);
  const flexSharedWidth = currentTableWidth - totalColumnsWidth;
  const flexChildren = children.filter(utils.isVisibleFlexChild(columns));
  let flexColumns = {};

  if (flexChildren.length === 1) {
    flexColumns[flexChildren[0].props.columnKey] = columns[flexChildren[0].props.columnKey];
    flexColumns[flexChildren[0].props.columnKey].width = flexSharedWidth;

    return flexColumns;
  }

  return flexChildren.reduce((accFlexColumns, child) => {
    const columnKey = child.props.columnKey;

    return {
      ...accFlexColumns,
      [columnKey]: {
        ...columns[columnKey],
        width: flexSharedWidth * parseInt(child.props.flex) / 100
      }
    };
  }, flexColumns);
};

const getVisibleColumnsInfo = (children, columns) => {
  return children.reduce((acc, child) => {
    const columnKey = child.props.columnKey;
    const col = columns[columnKey];
    if (utils.isVisible(col)) {
      const flex = Number(child.props.flex);
      acc.push({
        columnKey,
        visible: col.visible,
        width: col.width > 0 ? col.width : 1,
        flex: flex > 0 ? flex : 0
      });
    }

    return acc;
  }, []);
};

const countVisibleColumns = columns => columns.reduce((sum, col) => sum + utils.isVisible(col) ? 1 : 0, 0);

const calcRealMinColumnWidth = (columnsByKey, tableWidth, minColumnWidth) => {
  const columnsCount = countVisibleColumns(toColumnsArray(columnsByKey));

  return Math.min(minColumnWidth, tableWidth / (columnsCount || 1));
};

const toColumnsArray = columnsByKey => Object.keys(columnsByKey).map(key => ({...columnsByKey[key], columnKey: key}));

const calcTotalColumnsWidth = columnsByKey => toColumnsArray(columnsByKey).reduce((sum, col) => sum + col.width, 0);

const resolveColumnsWidth = (columns, targetWidth, minColumnWidth) => {
  if (columns.length <= 0) {
    return;
  }

  const totalWidth = calcTotalColumnsWidth(columns);
  columns.forEach(col => {
    col.width = (col.width / totalWidth) * targetWidth;
    if (col.width < minColumnWidth) {
      col.width = minColumnWidth;
    }
  });

  const remainingColumns = columns.filter(col => col.width > minColumnWidth);
  if (remainingColumns.length < columns.length) {
    const remainingWidth = targetWidth - minColumnWidth * (columns.length - remainingColumns.length);
    resolveColumnsWidth(remainingColumns, remainingWidth, minColumnWidth);
  }
};

const resetColumnsWidth = (columnsByKey, children) => {
  children.forEach(child => {
    const columnKey = child.props.columnKey;
    const col = columnsByKey[columnKey];
    col.width = child.props.width;
  });
};

const adjustColumnsWidth = (
  columnsByKey, /* object - columns width based on columnKey */
  children, /* array of table children columns */
  tableWidth, /* number - table's width */
  minColumnWidth
) => {
  if (tableWidth <= 0) {
    return;
  }

  //console.log('###adjustColumnsWidth() => tableWidth=', tableWidth);

  const visibleColumns = getVisibleColumnsInfo(children, columnsByKey);
  const flexColumns = visibleColumns.filter(col => col.flex > 0);
  const nonFlexColumns = visibleColumns.filter(col => !(col.flex > 0));
  const minColWidth = Math.min(minColumnWidth, tableWidth / (visibleColumns.length || 1));

  if (flexColumns.length <= 0) {
    resolveColumnsWidth(nonFlexColumns, tableWidth, minColWidth);
  } else {
    nonFlexColumns.forEach(col => col.width = col.width < minColWidth ? minColWidth : col.width);
    const nonFlexWidth = calcTotalColumnsWidth(nonFlexColumns);
    const totalWidth = nonFlexWidth + flexColumns.length * minColWidth;
    if (totalWidth < tableWidth) {
      flexColumns.forEach(col => col.width = col.flex);
      resolveColumnsWidth(flexColumns, tableWidth - nonFlexWidth, minColWidth);
    } else {
      flexColumns.forEach(col => col.width = minColWidth);
      if (totalWidth > tableWidth) {
        resolveColumnsWidth(nonFlexColumns, tableWidth - flexColumns.length * minColWidth, minColWidth);
      }
    }
  }

  visibleColumns.forEach(col => columnsByKey[col.columnKey].width = col.width);

  return columnsByKey;
};

const getGroupColumnsWidth = (
  group,
  columns
) => {
  return group.reduce(function (width, column) {
    return width += columns[column.column.props.columnKey].width;
  }, 0);
};

const getGroupsWidth = (
  children,
  columns
) => {
  var groupsWidth = {
      left: 0,
      cetner: 0,
      right: 0
    },
    i = 0;

  for (; i < children.length; i++) {
    if (utils.isVisible(columns[children[i].props.columnKey])) {
      groupsWidth[children[i].props.fixed === 'left' || children[i].props.fixed === 'right' ? children[i].props.fixed : 'center'] += columns[children[i].props.columnKey].width;
    }
  }
  return groupsWidth;
};

const getColumnHeaderContentWidth = (
  tableNode, /*DOMNode - the table DOMNode*/
  columnKey, /*string - the wanted column key*/
  extraColumnWidth = 0 /*number - extra width to add to the column's content width if needed*/
) => {
  let foundColumnContent = sandbox.dom.find("[data-columnkey='" + columnKey + "'] .column-content", tableNode);

  return foundColumnContent.length === 0 ? 0 : foundColumnContent[0].offsetWidth + extraColumnWidth;
};

const geColumnMaxContentWidth = (
  tableNode, /*DOMNode - the table DOMNode*/
  columnKey /*string - the wanted column key*/
) => {
  var rowsDOMNode = sandbox.dom.find(".react-data-grid-body-row [data-cellcolumnkey='" + columnKey + "'] .cell-content", tableNode),
    maxWidth = 0,
    currentCellContentWidth = 0,
    i = 0;

  for (; i < rowsDOMNode.length; i++) {
    currentCellContentWidth = rowsDOMNode[i].offsetWidth;
    if (maxWidth < currentCellContentWidth) {
      maxWidth = currentCellContentWidth;
    }
  }

  return maxWidth;
};

const adjustNextColumnWidth = (
  columns,
  columnKey,
  nextColumnKey,
  width,
  minColumnWidth,
  fixed
) => {
  const column = columns[columnKey];
  let columnWidth = width < minColumnWidth ? minColumnWidth : width;
  const result = {
    [columnKey]: {...column, width: columnWidth}
  };

  const nextColumn = columns[nextColumnKey];
  if (fixed === true && nextColumn) {
    let nextColumnWidth = nextColumn.width - (columnWidth - column.width);
    if (nextColumnWidth < minColumnWidth) {
      nextColumnWidth = minColumnWidth;
      columnWidth = column.width - (nextColumnWidth - nextColumn.width);
    }

    result[columnKey].width = columnWidth;
    result[nextColumnKey] = {...nextColumn, width: nextColumnWidth};
  }

  return result;
};

const columnFitContentWidth = (
  children,
  columns,
  columnIndex,
  columnKey,
  tableNode,
  minColumnWidth,
  fixed
) => {
  var headerContentWidth = 0;
  var cellsContentMaxWidth = 0;
  var maxColumnWidth = 0;
  var tableDefaultClassName = tableNode.className;
  var nextColumn = utils.getNextColumn(children, columns, columnIndex);
  var nextColumnKey = nextColumn ? nextColumn.props.columnKey : null;
  var nextColumnWidth;

  tableNode.className += ' column-width-adjustment';

  headerContentWidth = getColumnHeaderContentWidth(
    tableNode,
    columnKey,
    8 //The extra value to add to the column content
  );

  cellsContentMaxWidth = geColumnMaxContentWidth(
    tableNode,
    columnKey
  ) + 8; //The extra value to add to the column content

  maxColumnWidth = headerContentWidth > cellsContentMaxWidth ? headerContentWidth : cellsContentMaxWidth;

  const adjustedColumns = adjustNextColumnWidth(
    columns,
    columnKey,
    nextColumnKey,
    maxColumnWidth,
    minColumnWidth,
    fixed
  );

  tableNode.className = tableDefaultClassName;

  return {
    ...columns,
    ...adjustedColumns
  };
};

const updateColumn = (
  columns,
  columnKey,
  key,
  value
) => {
  return {
    ...columns,
    [columnKey]: {
      ...columns[columnKey],
      [key]: value
    }
  };
};

export default {
  getInitialColumns,
  getTotalColumnsWidth,
  getNonFlexColumnsWidth,
  getFlexColumnsWidth,
  resetColumnsWidth,
  adjustColumnsWidth,
  getGroupColumnsWidth,
  getGroupsWidth,
  getColumnHeaderContentWidth,
  geColumnMaxContentWidth,
  adjustNextColumnWidth,
  columnFitContentWidth,
  updateColumn,
  calcRealMinColumnWidth
};