import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utilities/classNames';
import { useId } from '@floating-ui/react';

export const Item = forwardRef(function Item(
  {
    children,
    active,
    selected,
    disabled = false,
    style,
    ...rest
  }, ref) {
  const id = useId();

  return (
    <li
      ref={ref}
      role='option'
      id={id}
      aria-selected={active}
      aria-disabled={disabled}
      {...rest}
      className={classNames('crtx-dropdown-option', { active }, { selected }, { disabled })}
      style={style}
    >
      {children}
    </li>
  );
});

Item.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
};
