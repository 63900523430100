
define(['sandbox', 'AbstractAction', 'core/services/dialogService', 'setups/LockEventsSetup/LockEventsSetup'], function(sandbox, AbstractAction, dialogService, LockSetup) {

  'use strict';

  var loaded = {}, // dictionary of loaded modules;
    LOCK_SETUP_ID = 'LockSetup';


  return AbstractAction.extend({

    isApplicable: function (objs) {
      if (!objs){
        return false;
      }
      return true;
    },

    execute: function (objs) {
      var that = this;
      var viewInstanceNwId = this.module.nwid;
      var projectorId = this.module.projectorId;
      var flowStepNwid = this.module.getFlowStepNwid();
      var actionConfig = {
        flowStepNwids: [
          flowStepNwid
        ]
      }


      if (typeof objs !== "undefined"  && objs.length > 0) {
        var obj = objs[0];
        this.selectedItems = objs;
        var request = sandbox.request.getLockAllowedResources(viewInstanceNwId, this.nwid, obj.nwid, obj.type, projectorId,
          {
            config: actionConfig,
            type: obj["type"],
            nwid: obj["nwid"],
            parameters: this.parameters
          }
        );
      }

      // Open Dialog
      request.then(function (res) {
        var code = res.statusCode;
        if (code === 200){

          // Init
          var lockedResources = res.data.lockedResources;
          var availableResources = res.data.availableResources;
          availableResources.forEach(function(resource){
            var found = (lockedResources.find(function(a){
              return resource.nwid === a.nwid;
            }));
            if(found) {
              resource.selected = true;
            }else {
              resource.selected = false;
            }
          });

          var oldLockedResources =  lockedResources.filter(function (item) {
            return !availableResources.find(function (resource) {
              return item.nwid === resource.nwid;
            }, item);
          });
          oldLockedResources.forEach(function(resource) {
            resource.selected = true;
            availableResources.pushObject(resource);
          });

          res.data.selectedItems = that.selectedItems;
          var title = sandbox.localization.translate(that.label);
          var lockSetup = sandbox.dialog.createSimpleSetupDialog(LockSetup, title, "500px", "300px", submitCallback, () => {}, that, 'LockSetup');
          var dialog = lockSetup.container;
          loaded[LOCK_SETUP_ID] = lockSetup;
          lockSetup.initDone();
          lockSetup.firstTickReceived(res.data);
        }
      });


      ////////////////////////////////////////////////////////////////

      function submitCallback(selected){
        var lockSetup = loaded[LOCK_SETUP_ID];
        var viewInstanceNwId = this.module.nwid;
        var projectorId = this.module.projectorId;
        var flowStepNwid = this.module.getFlowStepNwid();
        var content = lockSetup.model;
        var type = content.selectedItems[0]["type"];
        var nwid = content.selectedItems[0]["nwid"];
        var nwids = content.selectedItems.map(function (item) {
          return item.nwid;
        });

        // Lock
        var request = sandbox.request.lock(viewInstanceNwId, this.nwid, nwid, type, projectorId,
          {
            config: this.config,
            type: type,
            nwids: nwids,
            resource: selected.nwid,
            flowStepNwid: flowStepNwid
          }
        );
        request.then(function (res) {
          var code = res.statusCode;
          if (code !== 200){
            var message = res.errorMessage;
            if (res !== null){
              sandbox.dialog.alert(message);
            }
          }
        });
      }

    }
  });
});