import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GridPanel from './gridpanel';
import PageWrapper from './pagewrapper';

export default class Pages extends Component {
  static contextTypes = {
    module: PropTypes.object
  };

  static defaultProps = {
    sectionId: undefined,
    pages: [],
    pageDefinition: undefined,
    alignmentPoints: undefined
  };

  pageDefinition = () => {
    return this.props.pageDefinition;
  };

  pages = () => {
    return this.props.pages;
  };

  alignmentPoints = () => {
    return this.props.alignmentPoints;
  };

  getPagesMargin = (pageDefinition) => {
    return pageDefinition.generalProperties.margin || 0;
  };

  onPageClick = (pages, page, e) => {
    this.context.module.ctrl.onClick(pages, page, e);
  };

  onPageContextMenu = (pages, page, e) => {
    this.context.module.ctrl.onContextMenu(pages, page, e);
  };

  onPageDoubleClick = (page, e) => {
    this.context.module.ctrl.onDoubleClick(page, e);
  };

  onAggregatedItemClick = (thumbnail, e) => {
    this.context.module.ctrl.handleAggregatedItemClick(thumbnail, e);
  };

  onOpenChange = () => {
    this.context.module.ctrl.onOpenChange();
  };

  render() {
    var component = this,
      pageDefinition = this.pageDefinition(),
      margin = this.getPagesMargin(pageDefinition),
      pagesComponents = [],
      pages = this.pages();

    pages.forEach(function (page) {
      pagesComponents.push(<PageWrapper key={page.id}
        page={page}
        onOpenChange={component.onOpenChange}
        pageDefinition={pageDefinition}
        alignmentPoints={component.alignmentPoints()}
        onClick={(e) => component.onPageClick(pages, page, e)}
        onAggregatedItemClick={(e) => component.onAggregatedItemClick(page, e)}
        onContextMenu={(e) => component.onPageContextMenu(pages, page, e)}
        onDoubleClick={(e) => component.onPageDoubleClick(page, e)}
      >
      </PageWrapper>);
    });

    return <GridPanel gridSize={[80, 80]} gridMargin={margin} gridAlign="left">
      {pagesComponents}
    </GridPanel>;
  }
}

Pages.propTypes = {
  sectionId: PropTypes.string,
  pages: PropTypes.array,
  pageDefinition: PropTypes.object,
  alignmentPoints: PropTypes.object,
  selectedItems: PropTypes.object,
};