import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

function InputCell({
                     InputComponent,
                     columnData,
                     rowIndex,
                     columnKey,
                     disabled = false,
                     onChange,
                     onFocus,
                     onBlur,
                     ...props
                   }) {
  const valueRef = useRef(columnData);
  const [valueChangeCount, setValueChangeCount] = useState(0);
  const [editing, setEditing] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    return () => {
      // console.log('=== InputCell.destroy ===');
      if (!disabled) {
        endEditing();
      }
    }
  }, []);

  useEffect(() => {
    // console.log('=== InputCell.useEffect() rowIndex=', rowIndex);
    endEditing();
  }, [rowIndex]);

  useEffect(() => {
    // console.log('=== InputCell.useEffect() columnData=', columnData);
    updateValue(columnData);
  }, [columnData]);

  const beginEditing = () => {
    // console.log('=== InputCell.beginEditing() editing=', editing);
    if (editing) {
      return;
    }

    setEditing(true);
    setValueChangeCount(0);

    if (inputRef.current) {
      typeof inputRef.current.focus === 'function' && inputRef.current.focus();
      typeof inputRef.current.select === 'function' && inputRef.current.select();
    }
  };

  const endEditing = () => {
    // console.log(`=== InputCell.endEditing() editing=${editing}, valueRef.current="${valueRef.current}"`);
    if (!editing) {
      return;
    }

    setEditing(false);

    if (valueChangeCount > 0) {
      setValueChangeCount(0);

      if (typeof onChange === 'function') {
        onChange(rowIndex, columnKey, valueRef.current);
      }
    }
  };

  const updateValue = (newValue) => {
    // increment valueChangeCount and force render
    setValueChangeCount(count => count + 1);

    valueRef.current = newValue;
  };

  const handleCellClick = (e) => {
    // console.log('=== InputCell.handleCellClick() event=', e);
    if (!disabled) {
      beginEditing();
    }
  };

  const handleCellFocus = (e) => {
    // console.log('=== InputCell.handleCellFocus() event=', e);
    if (!disabled) {
      beginEditing();
    }
  };

  const handleInputChange = (e, newValue) => {
    // console.log(`=== InputCell.handleInputChange() BEGIN newValue="${newValue}", valueRef.current="${valueRef.current}"`);

    updateValue(newValue);

    // console.log(`=== InputCell.handleInputChange() END newValue="${newValue}", valueRef.current="${valueRef.current}"`);
  };

  const handleInputBlur = () => {
    // console.log(`=== InputCell.handleInputBlur() valueRef.current="${valueRef.current}"`);
    endEditing();

    if (typeof onBlur === 'function') {
      onBlur(rowIndex, columnKey, valueRef.current);
    }
  };

  const handleInputFocus = () => {
    if (typeof onFocus === 'function') {
      onFocus(rowIndex, columnKey);
    }
  };

  // console.log('=== InputCell.render() valueRef.current=', valueRef.current);

  return (
    <div className='cell'
         onClick={handleCellClick}
         onFocus={handleCellFocus}>
      <div className='cell-content'>
        <InputComponent ref={inputRef}
                        value={valueRef.current}
                        disabled={disabled}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        {...props}
        />
      </div>
    </div>
  );
}

InputCell.propTypes = {
  InputComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  columnData: PropTypes.any,
  rowIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

export default InputCell;
