define(['react', 'prop-types', 'react-dom'], function (React, PropTypes, ReactDOM) {
  const {Component} = React;

  return class console extends Component {
    constructor(props) {
      super(props);
    }

    static propTypes = {
      message: PropTypes.string.isRequired
    }

    componentWillUpdate() {
      var node = this.refs.consoleDialog;
      this.shouldScrollBottom = node.scrollTop + node.offsetHeight >= node.scrollHeight;
    }

    componentDidUpdate() {
      if (this.shouldScrollBottom) {
        var node = this.refs.consoleDialog;
        node.scrollTop = node.scrollHeight;
      }
    }

    render() {
      var { message } = this.props;

      return <div className="console-dialog" ref="consoleDialog">
        {message}
      </div>;
    }
  }

});

