/**
 * @file MultiselectFilter component utilized within the ReactDataGrid column header
 * empowers users to filter column data according to their chosen selections
 *
 * @author Boris
 * @created 2023-08-31
 */

import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import { classNames } from 'utilities/classNames';
import { createObjectComparator, COMPARE_TYPE } from 'core/comparators';
import { Multiselect } from 'components/common/floating';
import { SvgIcon, IconButton } from 'components/common/buttons';

export default function MultiselectFilter(
  {
    value = [],
    options,
    disabled = false,
    sortingEnabled = false,
    dataType,
    getOptionProps: getOptionPropsProp,
    onSelect,
    ...props
  }) {

  const openedRef = useRef(false);

  const handleOpenChange = (opened) => {
    openedRef.current = opened;
  };

  const handleClearButtonClick = (e) => {
    onSelect(e, []);
  };

  const getOptionProps = (option) => {
    let result;
    if (typeof getOptionPropsProp === 'function') {
      result = getOptionPropsProp(option);
    } else {
      const { value, data: { title, columnData } } = option;
      result = {
        value,
        text: title ?? columnData
      };
    }

    return result;
  };

  const sortedOptions = useMemo(() => {
    if (!sortingEnabled) {
      return options;
    }

    const emptyOptions = [], regularOptions = [];
    for (const option of options) {
      if (!option.value && typeof option.columnData === 'undefined') {
        emptyOptions.push({
          ...option,
          data: { ...option.data, columnData: translate('Blank') }
        });
      } else {
        regularOptions.push(option);
      }
    }

    let comparator;
    if (dataType === 'number' || dataType === 'shortDate' || dataType === 'shortDateTime') {
      comparator = createObjectComparator(option => option.value, COMPARE_TYPE.NUMBERS);
    } else if (dataType === 'icon' || dataType === 'generic') {
      comparator = createObjectComparator(option => option.value, COMPARE_TYPE.CASE_SENSITIVE);
    } else {
      comparator = createObjectComparator(option => getOptionProps(option).text);
    }

    if (comparator) {
      regularOptions.sort(comparator);
    }

    return emptyOptions.concat(regularOptions);
  }, [options]);

  const renderIcon = (option, index = 0) => {
    const { icon, title, iconSprite, iconClassName, iconStyle } = option.data;

    if (iconSprite) {
      return (
        <SvgIcon
          key={`${index}-${icon}`}
          name={icon}
          title={title}
          sprite={iconSprite}
          className={iconClassName}
          style={iconStyle}
        />
      );
    }

    return (
      <img key={`${index}-${icon}`} src={icon} className={iconClassName} title={title} style={iconStyle} alt='' />
    );
  };

  const renderOption = (option) => {
    const { data: { columnData } } = option;

    if (dataType === 'generic') {
      return columnData;
    }

    const { text } = getOptionProps(option);

    return (
      <>
        {dataType === 'icon' && renderIcon(option)}
        <div className='option-text'>{text}</div>
      </>
    );
  };

  const renderContent = (selectedOptions) => {
    let content;
    let className = 'content-text';

    if (selectedOptions.length > 0) {
      if (dataType === 'generic') {
        if (selectedOptions.length > 1) {
          content = translate('{1} options selected', selectedOptions.length);
        } else {
          content = selectedOptions[0].data.columnData;
        }
      } else if (dataType === 'icon') {
        className = 'content-icon';
        content = selectedOptions.map((option, index) => {
          return renderIcon(option, index);
        });
      } else {
        content = selectedOptions.map(option => {
          const { text = '' } = getOptionProps(option);

          return text;
        }).join(', ');

        if (selectedOptions.length > 1) {
          content = `(${selectedOptions.length}) ${content}`;
        }
      }
    }

    return (
      <>
        <div className={className}>
          {content}
        </div>
        {value.length > 0 && !disabled &&
          <IconButton
            className='clear-button'
            iconName={'close'}
            scale={false}
            bubbles={openedRef.current}
            onClick={handleClearButtonClick}
          />}
        {value.length <= 0 &&
          <SvgIcon
            name='filter_list'
            className='filter-button'
          />}
      </>
    );
  };

  return (
    <Multiselect
      {...props}
      className={classNames('crtx-filter-multiselect', { 'filter-applied': value.length > 0 })}
      options={sortedOptions}
      value={value}
      disabled={disabled}
      placement='bottom-end'
      withArrow={false}
      withSearch={dataType !== 'text' ? false : undefined}
      selectedAtTop={sortingEnabled}
      autoWidth={true}
      getOptionProps={getOptionProps}
      renderOption={renderOption}
      renderContent={renderContent}
      onSelect={onSelect}
      onOpenChange={handleOpenChange}
    />
  );
}

MultiselectFilter.propTypes = {
  ...Multiselect.propTypes,
  sortingEnabled: PropTypes.bool,
  dataType: PropTypes.string,
};