import React, { Component } from 'react';
import PropTypes from 'prop-types';
import utils from '../utils';
import SimpleFormContainer from '../SimpleFormContainer';

export default SimpleFormContainer(class extends Component {
  static propTypes = {

    //store - The main store of the SimpleForm.
    store: PropTypes.object,

    style: PropTypes.object,

    className: PropTypes.string,

    visible: PropTypes.bool,

    col: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),

    offset: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),

    direction: PropTypes.string

  };

  static defaultProps = {
    direction: 'col'
  };

  render() {

    var { store, style, visible, className, col, offset, direction, children } = this.props;
    var colSizeClassName = utils.getColumnSizeClassName(col);
    var colOffsetClassName = utils.getColumnOffsetClassName(offset);
    var directionClassName = 'container-' + direction;

    return <div
      className={utils.combineClassNames([className, 'container-fluid', colSizeClassName, colOffsetClassName, directionClassName])}
      style={utils.calcStyle(style, visible)}>

      {children}

    </div>;

  }
});
