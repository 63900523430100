import OpenModuleAction from './OpenModuleAction';
import openDialog from 'actions/utilities/openDialog';

export default OpenModuleAction.extend({

  isApplicable: function () {

    return this.module.selected.every(item => (item.defaultStatus?.progress || 0) > (this.config?.progress*100 || 0));
  },
  execute: function (selected) {

    openDialog(this, selected);
  }

});

