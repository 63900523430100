import React, { Component } from 'react';
import PropTypes from 'prop-types';
import request from 'core/managers/request';
import { Popover, PopoverContent, PopoverTrigger } from 'components/common/floating';

export default class Thumbnail extends Component {
  static propTypes = {
    columnData: PropTypes.any,
    imageUrl: PropTypes.string,
    iconUID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    template: PropTypes.string,
    title: PropTypes.string,
    style: PropTypes.object,
    rowIndex: PropTypes.number,
    columnKey: PropTypes.string,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
  };

  static defaultProps = {
    columnData: '',
    template: 'page/content'
  };

  state = {
    visible: false
  };

  handleClick = (e) => {
    const { rowIndex, columnKey, onClick } = this.props;

    if (typeof onClick === 'function') {
      onClick(rowIndex, columnKey, e);
    }
  };

  handleDoubleClick = (e) => {
    const { rowIndex, columnKey, onDoubleClick } = this.props;

    if (typeof onDoubleClick === 'function') {
      onDoubleClick(rowIndex, columnKey, e);
    }
  };

  handleImageLoad = () => {
    this.setState({ visible: true });
  };

  handleImageError = () => {
    this.setState({ visible: false });
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { props, state } = this;
    return nextProps.rowIndex !== props.rowIndex || nextProps.columnData !== props.columnData ||
      nextProps.iconUID !== props.iconUID || nextProps.imageUrl !== props.imageUrl ||
      state.visible !== nextState.visible;
  }

  render() {
    const { columnData, imageUrl, iconUID, title, style, onClick, onDoubleClick, template } = this.props;
    const cellStyle = {
      ...style,
      cursor: (typeof onClick === 'undefined' && typeof onDoubleClick === 'undefined') ? 'default' : 'pointer'
    };

    let imageSrc;
    if (imageUrl) {
      imageSrc = imageUrl;
    } else if (columnData) {
      const params = {
        command: 'getImage',
        template,
        action: 'icon',
        iconUID,
        nwid: columnData,
      };

      imageSrc = request.getImageUrl(params, true);
    }

    const { visible } = this.state;

    return (
      <div
        className='cell'
        style={cellStyle}
        title={title}
        onClick={this.handleClick}
        onDoubleClick={this.handleDoubleClick}
      >
        <Popover
        >
          <PopoverTrigger>
            <div className='cell-content cell-thumbnail'>
              {imageSrc &&
                <img
                  style={{ visibility: visible ? 'visible' : 'hidden' }}
                  onLoad={(e) => this.handleImageLoad(e)}
                  onError={(e) => this.handleImageError(e)}
                  src={imageSrc}
                  alt=''
                />
              }
            </div>
          </PopoverTrigger>

          <PopoverContent>
            {imageSrc && visible &&
              <div className='crtx-cell-thumbnail-popover'>
                {<img src={imageSrc} alt='' />}
              </div>
            }
          </PopoverContent>
        </Popover>
      </div>
    );
  }
}