define(['base/jsUtils', 'sandbox/jsUtils', 'sandbox/planSetup'], function (jsutils, utils, PlanSetup) {
  'use strict';

  

  function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  function resolveNamePart(page, descr, map){
    var attr = descr.attr;
    var type = descr.type;
    var padding = descr.padding;
    var obj = type === "parent" ? map[page.parent] : page;
    var ret = obj[attr] || "";
    return padding ? pad(ret, padding) : ret;
  }


  var resolveNaming = function (page, patterns, map){
    var name = "";
    for (var i=0;i<patterns.length;i++){
      name += resolveNamePart(page, patterns[i], map);
    }
    if (page.panorama){
      name += "-" + page.virtualTitle;
    }
    return name;
  }

  var matchByName = function (list, name){
    for (var i=0;i<list.length;i++){
      if(list[i].displayName === name){
        return list[i];
      }
    }
    return null;
  };

  var insertAt = function (arr, item, index){
    if (arr.length  < index)
    {
      for (var i=arr.length;i<index ;i++ )
      {
        arr.push("");
      }
    }

    arr.splice(index, 1, item);

  }


  var insert = function (arr, propertyName, sortMode, obj, min, max )	{
    min = min === void 0 ? 0 : min;
    max = max === void 0? arr.length - 1 : max;
    var key = obj[propertyName];
    //if (max < min || arr[0][propertyName] > key) {
    if (max < min || ordered(key, arr[0][propertyName])) {
      arr.insertAt(0, obj);
      return 0;
    } //else if (arr[max][propertyName] < key){
    else if (ordered(arr[max][propertyName], key)){
      arr.insertAt(max+1, obj);
      return max;
    } else {
      var mid =  parseInt(min + ((max - min) / 2),10);
      //if (arr[mid][propertyName] < key && arr[mid + 1][propertyName] > key) {
      if (ordered(arr[mid][propertyName],key) && ordered(key, arr[mid + 1][propertyName])) {
        arr.insertAt(mid + 1, obj);
        return (mid + 1);
      }
      if (ordered(key, arr[mid][propertyName])) {
        return insert(arr, propertyName, sortMode, obj, min, mid - 1);
      } else if (ordered(arr[mid][propertyName],key)){
        return insert(arr, propertyName, sortMode, obj, mid + 1, max);
      } else {
        return mid;
      }
    }
  };

  var removeObject = function(list, obj){

  }

  var matchBySourceId = function (sourceId, list){
    for (var i=0;i<list.length;i++){
      if (list[i].sourceId === sourceId){
        return list[i];
      }
    }
    return null;
  };

  var ordered = function (value1, value2, mode){
    if (mode === "numeric"){
      return parseInt(value1, 10) <= parseInt(value2, 10);
    }
    return value1 < value2;
  };

  var commons = Ember.Mixin.create({
    sayHello: function() {
      console.log("!!!!!!!!!!!!!!!!! Hello World " + this);
    }
  });

  var getSourceId = function (separator) {
    /// <summary>
    ///    Creates a unique id for identification purposes.
    /// </summary>
    /// <param name="separator" type="String" optional="true">
    /// The optional separator for grouping the generated segmants: default "-".
    /// </param>

    var delim = separator || "-";

    function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    var sourceId = (S4() + S4() + delim + S4() + delim + S4() + delim + S4() + delim + S4() + S4() + S4());
    
    return sourceId;
  };

  var matchBySourceId = function (sourceId, list){
    for (var i=0;i<list.length;i++){
      if (list[i].sourceId === sourceId){
        return list[i];
      }
    }
    return null;
  };

  var mapSourceIdToObject = function (model) {
    var sourceIdMap = {};

    (function mapProps(currentElement, map) {
      if (Array.isArray(currentElement)) {
        currentElement.forEach(function (item) {
          mapProps(item, map);
        });
      } else if (typeof currentElement == "object") {
        Object.keys(currentElement).forEach(function (key) {
          if (key === "sourceId") {
            if (currentElement[key] != "$empty$"){
              map[currentElement[key]] = currentElement;
            }   
          } else {
            var prop = currentElement[key];
            if (typeof prop == "object" && prop != null) {
              mapProps(prop, map);
            }
          }
        });
      }
    }(model, sourceIdMap));

    return sourceIdMap;

  }

  var initData = function (model, data, responseLocking = {}) {
    var jsonString = utils.xml2json(utils.parseXml(data.planSetupXml), "");
    var planSetupJson = JSON.parse(jsonString);
    PlanSetup.setPlanSetup(planSetupJson.PlanSetup);

    model.publicationsInfo = data.publicationsInfo;
    model.newPublicationsInfo = data.newPublicationsInfo;
    model.publicationsSourceIds = data.publicationsSourceIds;
    model.deadlines = data.deadlines;
    model.resources = data.resources;
    model.resourceGroups = data.resourceGroups;
    model.templates = data.templates;
    model.globalTemplates = data.globalTemplates;
    model.clientSettings = data.clientSettings;
    model.generalSettings = data.generalSettings;
    if (!model.clientSettings)
      model.clientSettings = {sourceIdBreadcrumb: true}
    else {
      model.clientSettings.sourceIdBreadcrumb = true;
    }

    model.pmiTemplates = data.pmiTemplates;
    model.paperTypeOptions = data.paperTypeOptions;
    model.folder = data.folder;
    model.folderPreFix = data.folderPreFix;
    model.wizardFolder = data.wizardFolder;
    if (data.impositions !== null && typeof data.impositions !== 'undefined'){
      model.impositions = data.impositions;
      model.groups = data.groups;
      model.groupsByPublications = data.groupsByPublications;
      model.pmtMode = false;
    } else {
      model.pmtInfo = data.pmtInfo;
      model.pmtDescription = data.pmtDescription;
      model.pmtMode = true;
    }

    if (data.publication !== null || typeof data.publication !== 'undefined'){
      model.publication = data.publication;
    }
    Ember.set(model, 'planSetup', PlanSetup);

    if (responseLocking.LockError) {
      model.lockedError = data.LockError;
    }
    if (model.publication){
      model.publication.LockingGroupId = responseLocking.LockingGroupId;
    }
    
    model.checkZoneDeadLines = data.checkZoneDeadLines;
  }

  var isSaddelStitch = function (zone){
    if (zone.booksMode == "saddle stitch"){
      return true;
    }
    for (var i=0;i<zone.sections.length-1;i++){
      if (!zone.sections[i].split){
        return false;
      }
    }
    zone.booksMode == "saddle stitch";
    return true;
  }

  return {
    matchByName: matchByName,
    getSourceId: getSourceId,
    removeObject: removeObject,
    insert: insert,
    matchBySourceId: matchBySourceId,
    mapSourceIdToObject: mapSourceIdToObject,
    commons: commons,
    insertAt: insertAt,
    initData: initData,
    resolveNaming: resolveNaming,
    isSaddelStitch: isSaddelStitch
  };

});