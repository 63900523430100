import { COMPARE_TYPE } from 'core/comparators';
import { cells } from 'widgets/ReactDataGrid/';
import { applyColumnPreferences, FILTER } from 'widgets/ReactDataGrid/utils';
import { translate } from 'core/services/localization';

const { Text } = cells;

export const defaultShouldCellUpdate = (nextProps, props) => {
  return nextProps.columnData !== props.columnData;
};

export const makeMonitorProfilesTableColumns = (model, module) => {
  const columnPrefs = module.preferences.table?.monitorProfileColumns || [];


  let columns = [
    {
      key: 'monitorName',
      caption: translate('Monitor Name'),
      width: 300,
      align: 'center',
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cellDataGetter: (rowIndex, key) => {
        const name = model.filteredMonitorProfiles[rowIndex]?.monitorName || '';

        return {
          columnData: name,
        };
      }
    },
    {
      key: 'monitorProfileName',
      width: 300,
      caption: translate('Monitor Profile File'),
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cellDataGetter: (rowIndex, key) => {
        const monitorProfileName = model.filteredMonitorProfiles[rowIndex]?.monitorProfileName || '';

        return {
          columnData: monitorProfileName,
        };
      }
    }
  ];

  columns = applyColumnPreferences(columns, columnPrefs);

  return columns;
};





