import React from 'react';
import { SvgIcon } from 'components/common/buttons';
import { labels, getReferencePointByValue, getRotateByValue, getImageOriginBoxByValue } from 'setups/NormalizeSetup/constants';
import { classNames } from 'utilities/classNames';
import settingsManager from 'core/managers/settings';
import { LengthOutput } from 'components/common/outputs';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';

export default function Rule({ rule }) {
  const unit = settingsManager.getLengthUnit();

  const renderRuleTitle = () => {
    let ruleTitleArr = [];

    if (rule.setupName) {
      ruleTitleArr.push(rule.setupName);
    }
    if (rule.name) {
      ruleTitleArr.push(rule.name);
    }

    return (
      <div className='crtx-form-full-row subtitle'>
        <div className={classNames({ 'overwritten': rule.manual })}>
          {ruleTitleArr.join(' > ')}
        </div>
        {rule.manual && <div className={classNames({ 'crtx-form-margin-left-xsmall': ruleTitleArr.length > 0 })}>
          {translate('Manual Action')}
        </div>}
      </div >
    );
  };

  return <React.Fragment><div className='crtx-form-section-container'>
    {renderRuleTitle()}
    <div className='crtx-rule-container'>
      <div className='crtx-form-two-columns'>

        <div className='crtx-form-full-row'>
          <div className='subsubtitle'>{labels.imageOriginBox}</div>
        </div>
        <label>{labels.baseOn}</label>
        <div>{getImageOriginBoxByValue(rule.imagebox)} </div>

        {rule.crop.checked ?
          <React.Fragment>
            <div className='crtx-form-full-row'>

              <div className='subsubtitle '>{labels.crop}</div>
            </div>
            {rule.crop.cropToTrimBox ? <React.Fragment>
              <label>{labels.cropToTrimBox}:</label>
              <SvgIcon className='green' name='check' ></SvgIcon>
            </React.Fragment>
              :
              <React.Fragment>
                <label>{labels.x}</label>
                <LengthOutput value={rule.crop.x} unit={unit} />

                <label>{labels.y}</label>
                <LengthOutput value={rule.crop.y} unit={unit} />

                <label>{labels.width}</label>
                <LengthOutput value={rule.crop.width} unit={unit} />

                <label>{labels.height}</label>
                <LengthOutput value={rule.crop.height} unit={unit} />

                <label>{labels.referencePoint}</label>
                <div>{getReferencePointByValue(rule.crop.refPoint)} </div>
              </React.Fragment>
            }
          </React.Fragment> :
          undefined
        }

        {rule.rotate.checked ?
          <React.Fragment>
            <div className='crtx-form-full-row'>
              <div className='subsubtitle '>{labels.rotate}</div>
            </div>
            <label>{labels.angle}</label>
            <div>{getRotateByValue(rule.rotate.rotate)} </div>
          </React.Fragment>
          : undefined
        }

        {rule.scale.checked ?
          <React.Fragment>
            <div className='crtx-form-full-row'>
              <div className='subsubtitle '>{labels.scale}</div>
            </div>
            <label>{labels.width}</label>
            <LengthOutput value={rule.scale.width} unit={unit} />

            <label>{labels.height}</label>
            <LengthOutput value={rule.scale.height} unit={unit} />

            {rule.scale.keepProportions ? <React.Fragment>
              <label>{labels.keepProportions}:</label>

              <SvgIcon className='green' name='check' ></SvgIcon>
            </React.Fragment> :
              undefined
            }
          </React.Fragment>
          : undefined
        }

        {rule.impose.checked ?
          <React.Fragment>
            <div className='crtx-form-full-row'>
              <div className='subsubtitle '>{labels.impose}</div>
            </div>
            <label>{labels.x}</label>
            <LengthOutput value={rule.impose.x} unit={unit} />

            <label>{labels.y}</label>
            <LengthOutput value={rule.impose.y} unit={unit} />

            <label>{labels.width}</label>
            <LengthOutput value={rule.impose.width} unit={unit} />

            <label>{labels.height}</label>
            <LengthOutput value={rule.impose.height} unit={unit} />

            <label>{labels.referencePoint}</label>
            <div>{getReferencePointByValue(rule.impose.refPoint)} </div>
          </React.Fragment>
          : undefined
        }

      </div>
    </div>
  </div >
  </React.Fragment>;

}

Rule.propTypes = {
  rule: PropTypes.object,

};

