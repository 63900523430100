import React from 'react';
import { createRoot } from 'react-dom/client';
import SimpleSetup from './SimpleSetup';
import utils from './simpleSetupUtils';

module.exports = SimpleSetup.extend({

  okButton: true,
  applyButton: false,
  cancelButton: true,
  showFooter: true,

  initDone: function () {
    utils.createSetupContainer(this);
    let setupContent;
    if (this.domElement) {
      setupContent = this.domElement.querySelector('.setup-content');
    } else {
      const doc = this.win ? this.win.document : window.document;
      setupContent = doc.querySelectorAll(`${this.element} .setup-content`)[0];
    }
    this.reactRoot = createRoot(setupContent);
  },

  destroy: function () {
    this._super();

    this.reactRoot && this.reactRoot.unmount();
  },

  render: function (content) {
    this.reactRoot && this.reactRoot.render(content);
  }

});