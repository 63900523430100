import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import localization, { translate } from 'core/services/localization';
import { Canvas, Stage, Ruler, Guide, Line, Rect, Cross, Text } from 'components/common/canvas';
import NavigationPanel from './navigation';
import Loader from 'components/common/loader/Loader';
import { selectCursorStyle } from '../reducers/selectors';
import { fromServerDate } from 'core/dates';
import { AutoSizer } from 'react-virtualized';
import {
  VIEW_TYPE_PAGE_VIEW,
  VIEW_TYPE_FORM_VIEW,
  VIEW_TYPE_HIRES_VIEW,
  VIEW_TYPE_COMPARE,
} from '../utilities';

const labels = {
  version: translate('Version'),
  input: translate('Input'),
  active: translate('Active'),
  notActive: translate('Not Active'),
  versionInformation: translate('Version Information'),
  pleaseSelect2VersionsToCompare: translate('Please select 2 versions to compare'),
  comparingVersions: translate('Comparing Versions')
};
const CANVAS_NAV_WIDTH = 250;

const isUndefined = o => typeof o === 'undefined';

export default function PageView(
  {
    image,
    images,
    mainCanvasHeight,
    mainCanvasWidth,
    showNavigator,
    versions,
    rotation,
    zoom,
    flipHorizontal,
    flipVertical,
    isDistanceTool,
    isMoveTool,
    isDensityTool,
    densityData,
    showMediaBoxes,
    showPDFBoxes,
    offsetPoint,
    imagePoint,
    distanceToolPoints,
    onCanvasResize,
    onVersionClick,
    onCanvasMouseDown,
    onCanvasMouseWheel,
    onCanvasDoubleClick,
    onNavCanvasMouseDown,
    onChangeMeasurementUnit,
    onChangeDensityAreaSize,
    onMainStageLoad,
    onMainCanvasLoad,
    onNavCanvasLoad,
    activeVersionNumber,
    selectedVersionNumber,
    isHold,
    compareVersionsNumbersSelected,
    viewType,
    loading,
    togglingBetweenCompareVersionsIndex,
    missingPage,
    onUpdateTilesInViewport,
    onChangeBoxesSelected,
    renderCanvasImage,
    hiresNavigatorImage,
    onChangeGuidelineSelected,
    gridCells,
    guidelines,
    guides,
    onGuideVisible,
    onRemoveGuide,
    showGuidelines,
    showPageLabels,
    measurementUnit,
    showVersions,
    overlayBoxes,
    viewportPoints,
    resolution,
    lengthUnit,
    mouseDownPoint,
    mouseMovePoint,
    rulerMousePoint,
    rulerOriginPoint,
    rulerVisible,
    isNavigatorMouseDown,
    windowRef,
  }) {

  const canvasRef = useRef();

  const viewportPointsJson = JSON.stringify(viewportPoints);

  useEffect(() => {
    // console.log('=== viewportPoints=', viewportPoints);
    onUpdateTilesInViewport(viewportPoints);
  }, [viewportPointsJson]);

  const setVersionBorderColor = (versionClassNameObj = {}, colorName) => {
    versionClassNameObj.topPageDiv = `topPageDiv ${colorName}Background`;
    versionClassNameObj.main = `pgViewMain ${colorName}Border`;
  };

  const getVersionBorderClassName = () => {
    let versionClassName = {
      topPageDiv: 'topPageDiv grayBackground',
      main: 'pgViewMain grayBorder',
      labelText: `${labels.versionInformation}`,
      key: undefined
    };

    if (viewType === VIEW_TYPE_PAGE_VIEW || viewType === VIEW_TYPE_FORM_VIEW || viewType === VIEW_TYPE_HIRES_VIEW) {
      let selectedVersion = versions[selectedVersionNumber];
      versionClassName.key = !isUndefined(selectedVersion) ? selectedVersion.key : undefined;

      if (!isUndefined(selectedVersion) && !isUndefined(selectedVersion.versionData)) {
        const isActive = activeVersionNumber === selectedVersion.key;

        versionClassName.labelText = `${labels.version}: ${selectedVersion.versionData.versionNumber}
        ${labels.input}: ${localization.toLocaleShortDateTime(fromServerDate(selectedVersion.versionData.time), true)} 
        ${selectedVersion.versionData.inputFileName}
         **${isActive ? labels.active : labels.notActive}**`;

        if (isActive) {
          if (isHold) {
            setVersionBorderColor(versionClassName, 'yellow');
          } else {
            setVersionBorderColor(versionClassName, 'gray');
          }
        } else {
          setVersionBorderColor(versionClassName, 'red');
        }
      }
    } else if (viewType === VIEW_TYPE_COMPARE) {
      if (togglingBetweenCompareVersionsIndex === -1) {
        versionClassName.labelText = compareVersionsNumbersSelected.length === 0 ? labels.pleaseSelect2VersionsToCompare : `${labels.comparingVersions}: ${compareVersionsNumbersSelected.join(' : ')}`;
      } else {
        const versionToShow = versions[compareVersionsNumbersSelected[togglingBetweenCompareVersionsIndex]];
        versionClassName.labelText = !isUndefined(versionToShow) ? `${labels.version}: ${versionToShow.key}` : labels.pleaseSelect2VersionsToCompare;
      }
      setVersionBorderColor(versionClassName, 'blue');
    }

    return versionClassName;
  };

  const handleMainStageLoad = (instance) => {
    onMainStageLoad(instance);
  };

  const handleMainCanvasLoad = () => {
    onMainCanvasLoad(canvasRef.current);
  };

  const handleNavCanvasLoad = (instance) => {
    onNavCanvasLoad(instance);
  };

  const renderRuler = () => {
    if (missingPage || !image || !rulerVisible) {
      return;
    }

    return <Ruler
      image={image}
      resolution={resolution}
      lengthUnit={lengthUnit}
      zoom={zoom}
      mouseDownPoint={mouseDownPoint}
      mouseMovePoint={mouseMovePoint}
      rulerOriginPoint={rulerOriginPoint}
    />;
  };

  const renderImage = () => {
    return renderCanvasImage();
  };

  const renderDistanceLine = () => {
    const { point, pointTo } = distanceToolPoints;
    if (missingPage || !image || !isDistanceTool || !point || !pointTo) {
      return;
    }

    return <Line
      point={point}
      pointTo={pointTo}
      strokeStyle='red'
      lineWidth={1 / zoom}
    />;
  };

  const renderDensityMark = () => {
    if (missingPage || !image || !isDensityTool || !densityData?.pointOnStage) {
      return;
    }

    const { x, y } = densityData.pointOnStage;

    return <Cross
      point={{ x, y }}
      pointTo={{ x, y }}
      strokeStyle='red'
      lineWidth={1 / zoom}
    />;
  };

  const renderOverlay = (box, boxType, index) => {
    if (!box.isSelected) {
      return;
    }

    return <Rect
      key={boxType + index}
      point={{ x: box.calcLeft, y: box.calcTop }}
      width={box.calWidth} height={box.calcHeight}
      label={boxType === 'pdfOverlayBoxes' ? box.label : undefined}
      strokeStyle={box.color} fillStyle={box.fill ? box.color : undefined}
      fillAlpha={0.5} strokeAlpha={1} lineWidth={2}
    />;
  };

  //This renders the rectangle(s) on the stage
  const renderOverlays = () => {
    if (missingPage || !image) {
      return;
    }

    let mediaOverlayBoxes = [];
    let pdfOverlayBoxes = [];

    if (!isUndefined(overlayBoxes)) {
      if (!isUndefined(overlayBoxes.mediaOverlayBoxes) && showMediaBoxes) {
        mediaOverlayBoxes = overlayBoxes.mediaOverlayBoxes.map((box, index) => renderOverlay(box, 'mediaOverlayBoxes', index));
      }
      if (!isUndefined(overlayBoxes.pdfOverlayBoxes) && showPDFBoxes) {
        pdfOverlayBoxes = overlayBoxes.pdfOverlayBoxes.map((box, index) => renderOverlay(box, 'pdfOverlayBoxes', index));
      }
    }
    return mediaOverlayBoxes.concat(pdfOverlayBoxes);
  };

  const renderGuideline = (guideline, zoom, index) => {
    const { point, pointTo, isSelected } = guideline;
    if (!isSelected) {
      return;
    }

    return <Line key={index} point={point} pointTo={pointTo} strokeStyle={guideline.color} lineWidth={1 / zoom} />;
  };

  const renderGuidelines = () => {
    if (missingPage || !image) {
      return;
    }

    let guidelineArr = [];

    if (guidelines && showGuidelines) {
      guidelineArr = guidelines.map((guideline, index) => renderGuideline(guideline, zoom, index));
    }
    return guidelineArr;
  };

  const renderGuides = () => {
    if (missingPage || !image || !rulerVisible) {
      return;
    }

    return <Guide
      guides={guides}
      mouseDownPoint={mouseDownPoint}
      mouseMovePoint={mouseMovePoint}
      rotation={rotation}
    />;
  };

  const renderPageLabel = (cell, index) => {
    return <Text key={index} {...cell} />;
  };

  const renderPageLabels = () => {
    if (missingPage || !image || !showPageLabels) {
      return;
    }

    return gridCells.map(renderPageLabel);
  };

  const handleCanvasResize = ({ width, height }) => {
    onCanvasResize(width, height);
  };

  const versionStyleData = getVersionBorderClassName();
  const mouseDownArea = canvasRef.current?.canvasHelper.detectCanvasArea(mouseDownPoint);
  const mouseMoveArea = canvasRef.current?.canvasHelper.detectCanvasArea(mouseMovePoint);
  const canvasStyle = selectCursorStyle({
    loading,
    missingPage,
    isDistanceTool,
    isDensityTool,
    isMoveTool,
    mouseDownArea,
    mouseMoveArea
  });

  return <div className='crtx-new-page-view'>
    <div className={versionStyleData.main}
    >
      <div className={versionStyleData.topPageDiv}>
        <Loader loading={loading} />
        <label
          htmlFor='topPageDiv'
          id={versionStyleData.key}
          className='topPageLabel'
        >
          {versionStyleData.labelText}
        </label>
      </div>
      <div className='canvas-container'>
        <AutoSizer onResize={handleCanvasResize}>
          {({ width, height }) => (
            <Canvas
              className={'pgViewCanvas'}
              id='page-view-canvas'
              ref={canvasRef}
              width={mainCanvasWidth}
              height={mainCanvasHeight}
              style={canvasStyle}
              rulerVisible={rulerVisible}
              onLoad={handleMainCanvasLoad}
              onMouseDown={onCanvasMouseDown}
              onMouseWheel={onCanvasMouseWheel}
              onDoubleClick={onCanvasDoubleClick}
            >
              <Stage
                point={offsetPoint}
                rotation={rotation}
                zoom={zoom}
                flipHorizontal={flipHorizontal}
                flipVertical={flipVertical}
                onLoad={handleMainStageLoad}
              >
                {renderImage()}
                {renderDistanceLine()}
                {renderDensityMark()}
                {renderOverlays()}
                {renderGuidelines()}
                {renderGuides()}
                {renderPageLabels()}
                {renderRuler()}
              </Stage>
            </Canvas>
          )}
        </AutoSizer>
      </div>
    </div>

    {showNavigator &&
      <div style={{ position: 'relative', width: CANVAS_NAV_WIDTH }}>
        <NavigationPanel
          windowRef={windowRef}
          image={missingPage ? null : image}
          images={images}
          imagePoint={imagePoint}
          resolution={resolution}
          distanceToolPoints={distanceToolPoints}
          isDistanceTool={isDistanceTool}
          isMoveTool={isMoveTool}
          isDensityTool={isDensityTool}
          densityData={densityData}
          mainCanvasHeight={mainCanvasHeight}
          mainCanvasWidth={mainCanvasWidth}
          offsetPoint={offsetPoint}
          rotation={rotation}
          zoom={zoom}
          flipHorizontal={flipHorizontal}
          flipVertical={flipVertical}
          measurementUnit={measurementUnit}
          mainCanvasInstance={canvasRef.current}
          onNavCanvasMouseDown={onNavCanvasMouseDown}
          onChangeMeasurementUnit={onChangeMeasurementUnit}
          onChangeDensityAreaSize={onChangeDensityAreaSize}
          onVersionClick={onVersionClick}
          versions={versions}
          selectedVersionNumber={selectedVersionNumber}
          activeVersionNumber={activeVersionNumber}
          compareVersionsNumbersSelected={compareVersionsNumbersSelected}
          viewType={viewType}
          showMediaBoxes={showMediaBoxes}
          showGuidelines={showGuidelines}
          showPDFBoxes={showPDFBoxes}
          showVersions={showVersions}
          overlayBoxes={overlayBoxes}
          guidelines={guidelines}
          guides={guides}
          onGuideVisible={onGuideVisible}
          onRemoveGuide={onRemoveGuide}
          onLoad={handleNavCanvasLoad}
          onChangeBoxesSelected={onChangeBoxesSelected}
          onChangeGuidelineSelected={onChangeGuidelineSelected}
          viewportPoints={viewportPoints}
          rulerMousePoint={rulerMousePoint}
          rulerVisible={rulerVisible}
          isNavigatorMouseDown={isNavigatorMouseDown}
          hiresNavigatorImage={hiresNavigatorImage}
          loading={loading}
        />
      </div>
    }

  </div>;
}

PageView.propTypes = {
  image: PropTypes.object,
  images: PropTypes.object,
  mainCanvasHeight: PropTypes.number,
  mainCanvasWidth: PropTypes.number,
  showNavigator: PropTypes.bool,
  versions: PropTypes.object,
  rotation: PropTypes.number,
  zoom: PropTypes.number,
  flipHorizontal: PropTypes.bool,
  flipVertical: PropTypes.bool,
  isDistanceTool: PropTypes.bool,
  isMoveTool: PropTypes.bool,
  isDensityTool: PropTypes.bool,
  densityData: PropTypes.object,
  showMediaBoxes: PropTypes.bool,
  showPDFBoxes: PropTypes.bool,
  offsetPoint: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }),
  imagePoint: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }),
  distanceToolPoints: PropTypes.shape({
    point: PropTypes.object,
    pointTo: PropTypes.object,
  }),
  onCanvasResize: PropTypes.func,
  onVersionClick: PropTypes.func,
  onCanvasMouseDown: PropTypes.func,
  onNavCanvasMouseDown: PropTypes.func,
  onCanvasMouseWheel: PropTypes.func,
  onCanvasDoubleClick: PropTypes.func,
  onChangeMeasurementUnit: PropTypes.func,
  onChangeDensityAreaSize: PropTypes.func,
  onMainStageLoad: PropTypes.func,
  onMainCanvasLoad: PropTypes.func,
  onNavCanvasLoad: PropTypes.func,
  compareData: PropTypes.object,

  activeVersionNumber: PropTypes.number,
  selectedVersionNumber: PropTypes.number,
  isHold: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  compareVersionsNumbersSelected: PropTypes.array,
  viewType: PropTypes.string,
  loading: PropTypes.bool,
  togglingBetweenCompareVersionsIndex: PropTypes.number,
  onUpdateTilesInViewport: PropTypes.func,
  onChangeBoxesSelected: PropTypes.func,
  renderCanvasImage: PropTypes.func,
  hiresNavigatorImage: PropTypes.object,
  onChangeGuidelineSelected: PropTypes.func,
  onGuideVisible: PropTypes.func,
  onRemoveGuide: PropTypes.func,
  gridCells: PropTypes.array,
  guidelines: PropTypes.array,
  guides: PropTypes.array,
  showGuidelines: PropTypes.bool,
  showPageLabels: PropTypes.bool,
  missingPage: PropTypes.bool,
  showVersions: PropTypes.bool,
  overlayBoxes: PropTypes.object,
  viewportPoints: PropTypes.object,
  measurementUnit: PropTypes.string,
  resolution: PropTypes.object,
  lengthUnit: PropTypes.string,
  mouseDownPoint: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }),
  mouseMovePoint: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }),
  rulerMousePoint: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }),
  rulerOriginPoint: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }),
  rulerVisible: PropTypes.bool,
  isNavigatorMouseDown: PropTypes.bool,
  windowRef: PropTypes.object,
};