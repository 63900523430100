/**
 * Created by moshemal on 9/4/14.
 */

define(['sandbox', 'ember', './../../../utils/wizardUtils'], function (sandbox, Ember, wizardUtils) {
  'use strict';

  function resolveImpositions(groups, impositions) {
    var ret = {};
    var impositionNames = [];
    for (var i = 0; i < groups.length; i++) {
      var groupName = groups[i].name;
      var layoutLinks = groups[i].layoutLinks;
      for (var j = 0; j < layoutLinks.length; j++) {
        var imposition = impositions[layoutLinks[j].nwid];
        if (!imposition) {
          continue;
        }
        var impositionName = layoutLinks[j].name;
        var impositionNwid = layoutLinks[j].nwid;
        // TODO need to valid layout
        if (!ret.hasOwnProperty(groupName)) {
          ret[groupName] = [];
        }
        ret[groupName].addObject({ value: impositionNwid, text: impositionName, cellGrid: imposition.cellGrid });
      }
    }
    return ret;
  }

  function resolvePmiTemplate(templates, pmiTemplate) {
    for (var i = 0; i < templates.length; i++) {
      if (templates[i].name === pmiTemplate) {
        return templates[i];
      }
    }
  }

  function mapNumberPerNumberInSection(sections, mappedModel) {
    var ret = {};
    sections.forEach(function (section) {
      var modelSection = mappedModel[section.sourceId];
      var pages = modelSection.pages;
      var map = {};
      ret[modelSection.sourceId] = map;
      pages.forEach(function (page) {
        map["page" + page.number] = page.numberInSection;
      });
    });
    return ret;
  }

  function resolveLayoutType(groups, name) {
    for (var i = 0; i < groups.length; i++) {
      if (groups[i].name === name) {
        return groups[i].impositionType;
      }
    }
    return null;
  }

  function resolveLayoutNwId(groups, name) {
    for (var i = 0; i < groups.length; i++) {
      if (groups[i].name === name) {
        return groups[i].nwid;
      }
    }
    return null;
  }

  function resolveImpositionNwId(groups, name, impositionName) {
    for (var i = 0; i < groups.length; i++) {
      if (groups[i].name === name) {
        var group = groups[i];
        var layoutLinks = group.layoutLinks;
        for (var j = 0; j < layoutLinks.length; j++) {
          if (layoutLinks[j].name === impositionName) {
            return layoutLinks[j].nwid;
          }
        }
      }
    }
    return null;
  }

  function clone(model) {
    var ret = {};
    for (var propertyName in model) {
      ret[propertyName] = model[propertyName];
    }
    return ret;
  }

  function copySections(targetZone, srcSections) {
    var targetSections = [];
    srcSections.forEach(function (srcSection) {
      var targetSection = wizardUtils.matchByName(targetZone.sections, srcSection.displayName);
      if (targetSection === null) {
        return;
      }
      var section = { displayName: targetSection.displayName, range: srcSection.range, sectionSourceId: targetSection.sourceId };
      if (srcSection.isLastSection) {
        section.isLastSection = true;
      }
      targetSections.push(section);
    });
    if (targetSections.length === 0) {
      targetSections.push({});
    }
    return targetSections;
  }

  function buildZonePages(modelZone) {
    var ret = {};
    modelZone.sections.forEach(function (section) {
      if (!ret.hasOwnProperty(section.sourceId)) {
        ret[section.sourceId] = {};
      }
      section.pages.forEach(function (page) {
        var n = parseInt(page.number, 10);
        var pages = ret[section.sourceId][n - 1];
        if (pages === null || typeof pages === 'undefined') {
          pages = [];
          ret[section.sourceId][n - 1] = pages;
        }
        pages.push(page.sourceId);
      });
    });
    return ret;
  }

  function mapZoneBooks(mappedBooks, zone) {
    for (var i = 0; i < zone.books.length; i++) {
      mappedBooks[zone.displayName + zone.books[i].displayName] = zone.books[i].sourceId;
    }
    return mappedBooks;
  }

  function translateBookSections(bookSections) {
    var ret = [];
    Object.keys(bookSections).forEach(function (key) {
      var section = bookSections[key];
      var bookSection = { displayName: key, sectionSourceId: section.sectionSourceId };
      var range = "";
      for (var i = 0; i < section.pages.length; i++) {
        if (i === 0) {
          range += section.pages[i].number + "-";
        } else if (i === section.pages.length - 1) {
          range += section.pages[i].number;
        } else {
          var x1 = section.pages[i - 1].number;
          var x2 = section.pages[i].number;
          if (x2 - x1 > 1) {
            range += x1 + ";" + x2 + "-";
          }
        }
      }
      bookSection.range = range;
      ret.push(bookSection);
    });
    ret[ret.length - 1].isLastSection = true;
    return ret;
  }

  function validModel(mappedModel, zones, continueNumbers) {
    for (var i = 0; i < zones.length; i++) {
      var books = zones[i].books;
      var sections = zones[i].sections;

      var numberPerNumberInSection = mapNumberPerNumberInSection(sections, mappedModel);

      if (books.length === 0) {
        //alert("Please enter book name in zone " + zones[i].displayName);
        sandbox.dialog.alert("Please enter book name in zone " + zones[i].displayName);
        return false;
      }
      var assignPages = {};
      for (var j = 0; j < books.length; j++) {
        var displayName = books[j].displayName;
        if (!displayName || displayName.length == 0) {
          sandbox.dialog.alert("Book name is empty in zone " + zones[i].displayName);
          return false;
        }
        var defaultLayout = books[j].defaultLayout;

        if (defaultLayout === null || typeof defaultLayout === "undefined" || defaultLayout.length === 0) {
          sandbox.dialog.alert("Please select layout in zone " + zones[i].displayName);
          return false;
        }

        var layoutType = resolveLayoutType(this.model.groups, defaultLayout);

        var existsPmi = books[j].pmiTemplate && books[j].pmiTemplate !== "---";
        if (layoutType === "custom" && (!existsPmi || existsPmi === "---" || existsPmi.length === 0)) {
          sandbox.dialog.alert("Please select pmi template in zone " + zones[i].displayName);
          return false;
        }
        //console.log ("####################################### " + layoutType + ":" + existsPmi);
        if (displayName === null || typeof displayName === "undefined") {
          sandbox.dialog.alert("Please enter books name in zone " + zones[i].displayName);
          return false;
        }
        var bookPages = [];
        var ttlNumPages = 0;
        var bookSections = books[j].sections;
        bookSections.forEach(function (bookSection) {
          var modelSection = mappedModel[bookSection.sectionSourceId];
          if (!modelSection) {
            sandbox.dialog.alert("Please select section name " + zones[i].displayName);
            return false;
          }
          var range = bookSection.range;//translateNumbers([modelSection], bookSection.displayName, bookSection.range, continueNumbers, 1);////bookSection.range;

          if (range === null || typeof range === 'undefined' || range.length === 0) {
            var a = books[j].sections;
            if (a.length > 1) {
              a.removeObject(bookSection);
              Ember.set(a[a.length - 1], 'isLastSection', true);
            } else {
              sandbox.dialog.alert("Information missing for sections");
              return false;
            }
            return;
          }
          var params = range.split(";");
          for (var p = 0; p < params.length; p++) {
            var rangeValue = params[p];
            if (rangeValue.indexOf("-") === -1) {
              var num = numberPerNumberInSection[modelSection.sourceId]["page" + rangeValue];
              if (assignPages.hasOwnProperty(modelSection.sourceId + num)) {
                sandbox.dialog.alert("Page was defined twice in zone " + zones[i].displayName);
                return false;
              }
              bookPages.push(0);
              assignPages[modelSection.sourceId + num] = books[j].sourceId;
            } else {
              var rangeValues = rangeValue.split("-");
              var first = parseInt(rangeValues[0], 10);
              var last = parseInt(rangeValues[1], 10);
              first = numberPerNumberInSection[modelSection.sourceId]["page" + first];
              last = numberPerNumberInSection[modelSection.sourceId]["page" + last];
              for (var n = first; n <= last; n++) {
                if (assignPages.hasOwnProperty(modelSection.sourceId + n)) {
                  sandbox.dialog.alert("Page was defined twice in zone " + zones[i].displayName);
                  return false;
                }
                bookPages.push(0);
                assignPages[modelSection.sourceId + n] = books[j].sourceId;
              }
            }
          }
          ttlNumPages += bookPages.length;
        });
        if (this.model.pmiTemplates && this.model.pmiTemplates.length > 0 && books[j].pmiTemplate
          && books[j].pmiTemplate !== "---") {
          var pmiTemplate = resolvePmiTemplate(this.model.pmiTemplates, books[j].pmiTemplate);
          var pmiPages = parseInt(pmiTemplate.numPages, 10);
          if (pmiPages != bookPages.length) {
            sandbox.dialog.alert("Book " + books[i].displayName + " has " +
              bookPages.length + " pages with template for " + pmiPages + " pages.");
            return false;
          }
        }

      }
      var modelZone = mappedModel[zones[i].sourceId];
      //modelZone.sections.forEach(function (section){
      for (var s = 0; s < modelZone.sections.length; s++) {
        var section = modelZone.sections[s];
        for (var p = 0; p < section.pages.length; p++) {
          if (!assignPages.hasOwnProperty(section.sourceId + section.pages[p].numberInSection)) {
            sandbox.dialog.alert("Page " + section.displayName + "/" + section.pages[p].number + " in zone " + zones[i].displayName + " is not associated to any book.");
            return false;
          }
        }

      }
      //var firstPage = parseInt(zones[i].firstPage, 10);
      //var lastPage = parseInt(zones[i].lastPage, 10);
      //
      //for (var p=firstPage;p<=lastPage;p++){
      //  if (!assignPages.hasOwnProperty(p + "")){
      //    alert("Page " + p + " in zone " + zones[i].displayName + " missing book.");
      //    return false;
      //  }
      //}

    }
    return true;
  }

  //function translateNumbers(sections, name, range, continueNumbers, mode){
  //  if (!continueNumbers){
  //    return range;
  //  }
  //  var section = null;
  //  for (var i=0;i<sections.length;i++){
  //    if (sections[i].displayName === name){
  //      section = sections[i];
  //      break;
  //    }
  //  }
  //  if (section === null){
  //    return range;
  //  }
  //  var map = {};
  //  section.pages.forEach(function (page){
  //    if (mode === 0){
  //      map[page.numberInSection + ""] = page.paginationInZone;
  //    } else {
  //      map[page.paginationInZone + ""] = page.numberInSection;
  //    }
  //  });
  //  var params = range.split(";");
  //  var ret = "";
  //  for (var p=0;p<params.length;p++){
  //    var rangeValue = params[p];
  //    if (rangeValue.indexOf("-") === -1) {
  //      ret +=  map[rangeValue];
  //    } else {
  //      var rangeValues = rangeValue.split("-");
  //      var first = map[rangeValues[0]];
  //      var last = map[rangeValues[1]];
  //      ret += first + "-" + last;
  //    }
  //    if (p < params.length - 1){
  //      ret += ";";
  //    }
  //  }
  //  return ret;
  //}

  return {

    selectedZone: null,

    selectedEdition: null,

    mappedModel: {},

    convertToStepModel: function (model, settings) {
      var stepModel = { editions: [] };
      var publication = Ember.get(model, "publication");
      var editions = Ember.get(publication, "editions");
      var mappedModel = wizardUtils.mapSourceIdToObject(model);

      var customFields = settings.customFields || [];
      var fields = [];
      customFields.forEach(function (h) {
        fields.push(h.name);
      });

      var k, i, zones;
      for (i = 0; i < editions.length; i++) {
        var stepEdition = { type: 'edition', displayName: editions[i].displayName, sourceId: editions[i].sourceId, zones: [] };
        zones = Ember.get(editions[i], "zones");
        for (var j = 0; j < zones.length; j++) {
          var stepZone = { type: 'zone', parent: editions[i].sourceId, displayName: zones[j].displayName, sourceId: zones[j].sourceId, books: [] };
          var books = Ember.get(zones[j], "books");
          var sections = Ember.get(zones[j], "sections");

          for (k = 0; k < books.length; k++) {

            var stepBookSections = [];

            for (var p = 0; p < books[k].sections.length; p++) {
              var bookSection = books[k].sections[p];
              var stepBookSection = {
                code: bookSection.code,
                displayName: bookSection.displayName,
                sectionSourceId: bookSection.sectionSourceId,
                range: bookSection.range
              };
              stepBookSections.push(stepBookSection);
            }
            if (stepBookSections.length > 0) {
              stepBookSections[stepBookSections.length - 1].isLastSection = true;
            }

            var stepBook = {
              displayName: books[k].displayName,
              code: books[k].code,
              range: books[k].range,
              skip: books[k].skip,
              layoutType: books[k].layoutType,
              pmiTemplate: books[k].pmiTemplate,
              paperType: books[k].paperType,
              defaultLayout: books[k].defaultLayout,
              sourceId: books[k].sourceId,
              sections: stepBookSections
            };

            fields.forEach(function (field) {
              stepBook[field] = books[k][field];
            });

            stepZone.books.addObject(stepBook);
          }




          stepZone.sections = [];
          sections.forEach(function (section) {
            var stepSection = { displayName: section.displayName, sourceId: section.sourceId };
            stepZone.sections.push(stepSection);
          });
          stepEdition.zones.addObject(stepZone);
        }
        stepModel.editions.addObject(stepEdition);
      }
      stepModel.optionalImpositions = resolveImpositions(model.groups, model.impositions);
      Ember.set(this, "model", stepModel);
      return stepModel;
    },

    convertFromStepModel: function (model, settings) {
      var zones = this.zones();
      var that = this;
      var mappedModel = wizardUtils.mapSourceIdToObject(model);
      if (!validModel.call(this, mappedModel, zones, this.continueNumbers)) {
        return false;
      }

      var customFields = settings.customFields || [];
      var fields = [];
      customFields.forEach(function (h) {
        fields.push(h.name);
      });

      for (var i = 0; i < zones.length; i++) {
        var modelZone = mappedModel[zones[i].sourceId];
        var existBooks = {};
        modelZone.books.forEach(function (book) {
          existBooks[book.sourceId] = 0;
        });
        var stepBooks = zones[i].books;
        var stepBooksCodes = {};
        var booksNamesCounter = {};
        for (var j = 0; j < stepBooks.length; j++) {
          var stepBook = stepBooks[j];
          booksNamesCounter[stepBook.displayName] = (booksNamesCounter[stepBook.displayName] || 0) + 1;
          var bookCode = stepBook.code ? stepBook.code : stepBook.displayName + (booksNamesCounter[stepBook.displayName] > 1 ? booksNamesCounter[stepBook.displayName] : "");
          stepBook.code = bookCode;
        }

        var zonePages = buildZonePages(modelZone);
        for (var j = 0; j < stepBooks.length; j++) {
          var stepBook = stepBooks[j];
          // var key = stepBook.displayName;
          // var bookCode = stepBook.displayName;
          // for (var property in stepBooksCodes) {
          // 	if (stepBooksCodes.hasOwnProperty(property)){
          // 		if (stepBooksCodes[property] === key){
          // 			bookCode = property;
          // 		}
          // 	}
          // }




          var sourceId = (modelZone.sourceId + "/books/" + stepBook.code).toLowerCase();
          stepBook.sourceId = sourceId;
          var book = mappedModel[sourceId];
          if (book === null || typeof book === 'undefined') {
            book = {};
            modelZone.books.push(book);
          } else {
            existBooks[book.sourceId] = 1;
          }


          var prevPmiTemplate = book.pmiTemplate;
          delete book.pmiTemplate;

          book.customFields = fields
          for (var propertyName in stepBook) {
            if (propertyName === "defaultLayout") {
              var pmtMode = this.model.pmtMode;
              var layoutType = pmtMode ? this.model.pmtInfo[stepBook[propertyName]] :
                resolveLayoutType(this.model.groups, stepBook[propertyName]);
              var layoutNwId = pmtMode ? null :
                resolveLayoutNwId(this.model.groups, stepBook[propertyName]);
              var prevLayoutType = book.layoutType;
              book.layoutType = layoutType;
              book.layoutNwId = layoutNwId;
              if (prevLayoutType !== book.layoutType) {
                book.forms = [];
              } else {
                var layout = stepBook[propertyName];
                var prevLayout = book[propertyName];
                if (prevLayout && layout !== prevLayout) {
                  book.rebuildForms = true;
                  book.forms.forEach(function (form) {
                    form.layoutGroup = layout;
                    var layoutNwId = resolveImpositionNwId(that.model.groups, layout, form.layout)
                    if (layoutNwId) {
                      form.layoutNwId = layoutNwId;
                    } else {
                      delete form.layoutNwId;
                    }
                  });
                }
              }
            } else if (propertyName === "pmiTemplate") {

              if (prevPmiTemplate !== stepBook[propertyName]) {
                book.forms = [];
              }

            } else if (propertyName === "pmiTemplateSaved" && stepBook[propertyName]) {
              book.forms = [];
            }
            book[propertyName] = stepBook[propertyName];
          }

          var bookSections = stepBook.sections;
          var bookPages = [];
          bookSections.forEach(function (bookSection) {
            var bookSectionSourceId = bookSection.sectionSourceId;
            var modelSection = mappedModel[bookSection.sectionSourceId];
            var range = bookSection.range;//translateNumbers([modelSection], bookSection.displayName, bookSection.range, that.continueNumbers, 1);//
            bookSection.range = range;
            // bookSection.range;
            var params = range.split(";");
            for (var p = 0; p < params.length; p++) {
              var rangeValue = params[p];
              if (rangeValue.indexOf("-") === -1) {
                var n = parseInt(rangeValue, 10);
                bookPages.push(zonePages[bookSectionSourceId][n - 1]);
              } else {
                var rangeValues = rangeValue.split("-");
                var first = parseInt(rangeValues[0], 10);
                var last = parseInt(rangeValues[1], 10);
                for (var n = first; n <= last; n++) {
                  bookPages.push(zonePages[bookSectionSourceId][n - 1]);
                }
              }
            }
          });
          book.pages = bookPages;
          if (book.pmiTemplate) {
            var pmiTemplate = resolvePmiTemplate(that.model.pmiTemplates, book.pmiTemplate);
            book.pmiTemplateDescription = pmiTemplate;
          }

          console.log("bookPages.length=" + bookPages.length);
          console.log("book.pmiTemplate=" + book.pmiTemplate);
        }
        Object.keys(existBooks).forEach(function (key) {
          if (existBooks[key] === 0) {
            var book = wizardUtils.matchBySourceId(key, modelZone.books);
            if (book !== null) {
              modelZone.books.removeObject(book);
            }
          }
        });
      }

      model.pmiTemplates = this.model.pmiTemplates;
      return true;

    },

    setModel: function (model) {
      Ember.set(this, 'model', model);
      this.continueNumbers = this.model.continueNumbers;
    },

    isValid: function () {

      return true;
    },

    zones: function () {
      var ret = [];
      var editions = Ember.get(this, 'model.editions');
      for (var i = 0; i < editions.length; i++) {
        var zones = editions[i].zones;
        ret.pushObjects(zones);
      }
      return ret;
    },


    notifyPropertyChange: function (section, property, value) {

    },



    applyAll: function () {
      var zones = this.zones();
      var prevBooksSourceIds = {};
      var i, j, key;

      var mappedBooks = {};
      for (j = 0; j < zones.length; j++) {
        if (zones[j].sourceId === this.selectedZone.sourceId) {
          continue;
        }
        mappedBooks = mapZoneBooks(mappedBooks, zones[j]);
        zones[j].books.clear();
      }

      for (i = 0; i < this.selectedZone.books.length; i++) {
        var sourceBook = this.selectedZone.books[i];
        for (j = 0; j < zones.length; j++) {
          if (zones[j].sourceId === this.selectedZone.sourceId) {
            continue;
          }

          key = zones[j].displayName + sourceBook.displayName;
          var targetBook = {
            displayName: sourceBook.displayName,
            code: sourceBook.code,
            range: sourceBook.range,
            skip: sourceBook.skip,
            layoutType: sourceBook.layoutType,
            pmiTemplate: sourceBook.pmiTemplate,
            paperType: sourceBook.paperType,
            defaultLayout: sourceBook.defaultLayout,
            pmiTemplateSaved: sourceBook.pmiTemplateSaved,
            sections: copySections(zones[j], sourceBook.sections),
            hidden: sourceBook.hidden
          };


          targetBook.sourceId = mappedBooks.hasOwnProperty(key) ? mappedBooks[key] : wizardUtils.getSourceId();

          zones[j].books.addObject(targetBook);
        }
      }

    },

    calculatePagination: function (object) {
      var zone = object.type === 'zone' ? object : wizardUtils.matchBySourceId(object.parent, this.zones());
      //var edition = wizardUtils.matchBySourceId(zone.parent, Ember.get(this.model, 'editions'));
      var n = 0;
      for (var i = 0; i < zone.sections.length; i++) {
        var thisSectionNumPages = isNaN(zone.sections[i].numPages) ? 0 : parseInt(zone.sections[i].numPages, 10);
        if (thisSectionNumPages === 0) {
          continue;
        }
        var start = n + 1;
        n += thisSectionNumPages;
        Ember.set(zone.sections[i], "pagination", start + "-" + n);
      }
    }


  };
});
