const isUndefined = o => typeof o === 'undefined';
import settingsManager from 'core/managers/settings';

const clientConfiguration = (key) => {
  const configuration = settingsManager.get('clientConfiguration') || {};

  if (isUndefined(key)) return configuration;

  return configuration[key];
};

export default clientConfiguration;