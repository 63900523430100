/**
 * @name alignments
 * @file Detects and draws common alignments between target shape and other shapes on canvas
 *
 * @author Boris
 * @since: 2016-09-22
 */

import Fabric from 'fabric';
import utils from '../utils/utils';
import { groupElementsByType } from '../redux/reducersCommon';
import ElementBase from './elementBase';

export default (editor) => {

  const elementBase = ElementBase(editor);
  const canvas = editor.getMainCanvas();

  const SNAPPING_PROXIMITY = 10;
  const PROTRUDING_EDGE = 20;
  const H_ALIGNMENT_KEYS = ['ht', 'hm', 'hb']; // 'ht' - horizontal-top, 'hm' - horizontal-middle, 'hb' - horizontal-bottom
  const V_ALIGNMENT_KEYS = ['vl', 'vm', 'vr']; // 'vl' - vertical-left, 'vm' - vertical-middle, 'vr' - vertical-right
  const ALIGNMENT_KEYS = H_ALIGNMENT_KEYS.concat(V_ALIGNMENT_KEYS);
  const H_CONNECTOR_KEYS = {
    ht: ['ht', 'hb'],
    hm: ['hm'],
    hb: ['hb', 'ht'],
  };
  const V_CONNECTOR_KEYS = {
    vl: ['vl', 'vr'],
    vm: ['vm'],
    vr: ['vr', 'vl'],
  };
  const EPS = 0.1;

  var alignmentsInfo;
  var alignmentLines;

  const createAlignmentLine = () => {
    var line = new Fabric.Line(null, {
      stroke: 'red',
      strokeDashArray: [12, 5],
      strokeWidth: 1,
      selectable: false,
      evented: false,
      visible: false
    });

    return line;
  };

  const registerAlignments = (state, element) => {
    if (alignmentsInfo) {
      return true;
    }

    const guidelineGroup = state.elementGroups.find(g => g.elementType === 'guideline');
    if (!state.showAlignments && !(guidelineGroup && guidelineGroup.children.length > 0 && element.elementType !== 'guideline')) {
      return false;
    }

    let elementsByType = groupElementsByType(state);
    if (!state.showAlignments) {
      elementsByType = { guideline: elementsByType['guideline'] || [] };
    }

    alignmentsInfo = [];
    for (var key in elementsByType) {
      var elements = elementsByType[key];
      for (var i = 0; i < elements.length; i++) {
        if (elements[i] != element) {
          var alignInfo = elementBase.getAlignmentInfo(state, elements[i]);
          if (alignInfo) {
            alignmentsInfo.push(alignInfo);
          }
        }
      }
    }

    if (!alignmentLines) {
      alignmentLines = {};
      for (var i = 0; i < ALIGNMENT_KEYS.length; i++) {
        alignmentLines[ALIGNMENT_KEYS[i]] = createAlignmentLine();
      }
    }

    for (var key in alignmentLines) {
      var line = alignmentLines[key];
      canvas.add(line);
    }

    return true;
  };

  const unregisterAlignments = () => {
    if (!alignmentsInfo) {
      return;
    }

    alignmentsInfo = null;
    for (var key in alignmentLines) {
      var line = alignmentLines[key];
      canvas.remove(line);
    }
  };

  const hideAlignmentLines = () => {
    if (alignmentLines) {
      for (var key in alignmentLines) {
        var line = alignmentLines[key];
        line.visible = false;
      }
    }
  };

  const findProximateAlignments = (state, targetInfo, connectorKeys) => {
    if (!targetInfo || !targetInfo.alignments) {
      return [];
    }

    var result = [];
    var targetAlignments = targetInfo.alignments;
    for (var i = 0; i < alignmentsInfo.length; i++) {
      var otherAlignments = alignmentsInfo[i].alignments;
      for (var targetKey in connectorKeys) {
        if (typeof targetAlignments[targetKey] !== 'undefined') {
          var otherKeys = connectorKeys[targetKey];
          for (var j = 0; j < otherKeys.length; j++) {
            var otherKey = otherKeys[j];
            if (typeof otherAlignments[otherKey] !== 'undefined') {
              var delta = otherAlignments[otherKey] - targetAlignments[targetKey];
              if (Math.abs(delta) <= SNAPPING_PROXIMITY) {
                result.push({
                  targetKey,
                  otherKey,
                  delta,
                  index: i
                });
              }
            }
          }
        }
      }
    }

    return result;
  };

  const findNearestAlignments = (state, targetInfo, connectorKeys, snapDisabled) => {
    var proximities = findProximateAlignments(state, targetInfo, connectorKeys);
    if (snapDisabled) {
      return proximities;
    }

    var result = [];
    var idx = -1;
    var min = Infinity;
    for (var i = 0; i < proximities.length; i++) {
      var proximity = proximities[i];
      if (Math.abs(proximity.delta) < Math.abs(min)) {
        min = proximity.delta;
        idx = i;
      }
    }

    if (idx >= 0) {
      var nearest = proximities[idx];
      result.push(nearest);
      for (var i = 0; i < proximities.length; i++) {
        if (i !== idx) {
          var proximity = proximities[i];
          if (Math.abs(proximity.delta) < Math.abs(min) + EPS) {
            result.push(proximity);
          }
        }
      }
    }

    return result;
  };

  const snapCanvasShapeByMoving = (state, canvasShape, nearestHAlignment, nearestVAlignment) => {
    let snapped = false;

    if (nearestHAlignment) {
      canvasShape.top += nearestHAlignment.delta;
      snapped = true;
    }

    if (nearestVAlignment) {
      canvasShape.left += nearestVAlignment.delta;
      snapped = true;
    }

    return snapped;
  };

  const isExcessAlignmentKey = (alignmentKeys, key) => {
    var result = false;
    if (key === 'hm') {
      result = alignmentKeys.hasOwnProperty('ht') || alignmentKeys.hasOwnProperty('hb');
    } else if (key === 'vm') {
      result = alignmentKeys.hasOwnProperty('vl') || alignmentKeys.hasOwnProperty('vr');
    }

    return result;
  };

  const getBoundingBoxes = (targetInfo, nearestAlignments, targetKey) => {
    var result = [targetInfo.boundingBox];

    for (var i = 0; i < nearestAlignments.length; i++) {
      var nearest = nearestAlignments[i];
      if (nearest.targetKey === targetKey) {
        var nearestInfo = alignmentsInfo[nearest.index];
        result.push(nearestInfo.boundingBox);
      }
    }

    return result;
  };

  const getAlignmentValues = (nearestAlignments) => {
    var result = {};

    for (var i = 0; i < nearestAlignments.length; i++) {
      var nearest = nearestAlignments[i];
      var nearestInfo = alignmentsInfo[nearest.index];
      var targetKey = nearest.targetKey;
      if (typeof result[targetKey] === 'undefined') {
        result[targetKey] = nearestInfo.alignments[nearest.otherKey];
      }
    }

    return result;
  };

  const getHEndings = (boundingBoxes) => {
    var x1 = Infinity;
    var x2 = -Infinity;
    var width;
    for (var i = 0; i < boundingBoxes.length; i++) {
      var bb = boundingBoxes[i];
      if (x1 > bb.left) {
        x1 = bb.left;
      }

      if (x2 < bb.left) {
        x2 = bb.left;
        width = bb.width;
      }
    }

    x1 -= PROTRUDING_EDGE;
    x2 += width + PROTRUDING_EDGE;

    return [x1, x2];
  };

  const getVEndings = (boundingBoxes) => {
    var y1 = Infinity;
    var y2 = -Infinity;
    var height;
    for (var i = 0; i < boundingBoxes.length; i++) {
      var bb = boundingBoxes[i];
      if (y1 > bb.top) {
        y1 = bb.top;
      }

      if (y2 < bb.top) {
        y2 = bb.top;
        height = bb.height;
      }
    }

    y1 -= PROTRUDING_EDGE;
    y2 += height + PROTRUDING_EDGE;

    return [y1, y2];
  };

  const drawHAlignmentLines = (state, targetInfo, nearestHAlignments) => {
    if (nearestHAlignments.length <= 0) {
      return;
    }

    var alignmentValues = getAlignmentValues(nearestHAlignments);
    for (var targetKey in alignmentValues) {
      if (!isExcessAlignmentKey(alignmentValues, targetKey)) {
        var boundingBoxes = getBoundingBoxes(targetInfo, nearestHAlignments, targetKey);
        var endings = getHEndings(boundingBoxes);
        var y = alignmentValues[targetKey];
        alignmentLines[targetKey].set({
          x1: endings[0],
          y1: y,
          x2: endings[1],
          y2: y,
          visible: true
        });
      }
    }
  };

  const drawVAlignmentLines = (state, targetInfo, nearestVAlignments) => {
    if (nearestVAlignments.length <= 0) {
      return;
    }

    var alignmentValues = getAlignmentValues(nearestVAlignments);
    for (var targetKey in alignmentValues) {
      if (!isExcessAlignmentKey(alignmentValues, targetKey)) {
        var boundingBoxes = getBoundingBoxes(targetInfo, nearestVAlignments, targetKey);
        var endings = getVEndings(boundingBoxes);
        var x = alignmentValues[targetKey];
        alignmentLines[targetKey].set({
          x1: x,
          y1: endings[0],
          x2: x,
          y2: endings[1],
          visible: true
        });
      }
    }
  };

  const snapAndDrawAlignments = (state, element, canvasShape, event) => {
    let targetInfo = elementBase.getAlignmentInfo(state, element);
    if (!targetInfo) {
      return;
    }

    // disable snap when shift key is pressed or when resizing the shape (snapping can work only when moving shape)
    let snapDisabled = event && event.shiftKey || utils.isScaledShape(canvasShape);
    let nearestHAlignments = findNearestAlignments(state, targetInfo, H_CONNECTOR_KEYS, snapDisabled);
    let nearestVAlignments = findNearestAlignments(state, targetInfo, V_CONNECTOR_KEYS, snapDisabled);
    if (!snapDisabled) {
      let snapped = snapCanvasShapeByMoving(state, canvasShape, nearestHAlignments[0], nearestVAlignments[0]);
      if (snapped) {
        targetInfo = elementBase.getAlignmentInfo(state, element);
        if (targetInfo) {
          nearestHAlignments = findNearestAlignments(state, targetInfo, H_CONNECTOR_KEYS, snapDisabled);
          nearestVAlignments = findNearestAlignments(state, targetInfo, V_CONNECTOR_KEYS, snapDisabled);
        }
      }
    }

    hideAlignmentLines();
    drawHAlignmentLines(state, targetInfo, nearestHAlignments);
    drawVAlignmentLines(state, targetInfo, nearestVAlignments);
  };

  return {
    snapAndDrawAlignments,
    registerAlignments,
    unregisterAlignments
  }
};
