import PropTypes from 'prop-types';
import React, { Component } from 'react';

const isUndefined = o => typeof o === 'undefined';

export default class MultiApproversIndicator extends Component {
  static propTypes = {
    approvalIndication: PropTypes.string,
    title: PropTypes.string
  };

  static defaultProps = {
    approvalIndication: undefined,
    title: ''
  };

  render() {
    const { approvalIndication } = this.props;
    const title = this.props.title;

    let style = {
      float: 'left',
      textAlign: 'center',
      marginRight: '2px'
    };

    return isUndefined(approvalIndication) ? <span /> : <span style={style} title={title}>{approvalIndication}</span>;
  }
}