import viewManager from 'core/managers/views';
import { startModule } from 'core/managers/module';

/**
 * Manages data needed to open a view associated with a zone Aggregate.
 */
class AggregateViewLink {
  /**
   * @param {string} zoneNwid
   * @param {string} rootType Root type for the new view, e.g. 'sections' or 'books'.
   * @param {string} viewClass Class name of the view module, e.g. 'BirdEyeLayers'.
   * @param {string} moduleNwid Module nwid
   */
  constructor(zoneNwid, rootType, viewClass, moduleNwid, viewLinks) {
    this.nwid = zoneNwid;
    this.rootType = rootType;
    this.viewClass = viewClass;
    this.moduleNwid = moduleNwid;
    this.viewLinks = viewLinks;

    /**
     * After we find matching view link, we store view instance Nwid here for reuse.
     * @type {string}
     */
    this.viewNwid = null;

    /**
     * Context menu code uses 'type' to get breadcrumbs and to construct toolbar navigation.
     * @type {string}
     */
    this.type = this.rootType;

    //Process view links. We can't do this on demand because we
    // must already have the view link when user invokes context menu.


    this.processViewLinks(viewLinks);

  }
  /**
   * Opens the view in new window. ProductionRun calls this when user clicks on an Aggregate.
   */
  openView() {
    if (this.viewNwid) {
      startModule(this.viewNwid, null, {
        nwid: this.viewNwid, rootId: this.nwid, rootType: this.rootType, target: 'window', viewClass: this.viewClass
      });
    }
  }

  /**
   * Called when view links are received from server. Finds a link that matches our viewClass
   * and saves it and its view instance nwid. View link and nwid are used to open the view and
   * to show context menu.
   * @private
   */
  processViewLinks(viewLinks) {
    if (Array.isArray(viewLinks) && viewLinks.length > 0) {
      this.viewLinks = viewLinks;
      viewLinks.some(link => {
        let params = viewManager.getViewInfo(link);
        if (params.viewClass === this.viewClass && (this.viewClass !== 'StructuresTableView' || params.icon === 'PlatesTable')) {
          // We found the view link we want.
          this.viewNwid = params.nwid;
          if (this.viewClass === 'StructuresTableView') {
            this.viewLinks = [link];
          }
        }
        // Stop iteration after link is found.
        return this.viewNwid;
      });
    }
  }
}

export default AggregateViewLink;
