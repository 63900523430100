import React from 'react';
import Title from './title';
import Content from './content';

export default class CollapsiblePanel extends React.Component {
  static defaultProps = {
    onClickContext: undefined,
    onClickHandler: undefined,
    open: true
  };

  constructor(props){
    super(props);
    this.state = {
      open: this.props.open
    };
  }

  clickHandler = () => {
    var onClick = this.props.onClickHandler;
    //console.log(onClick, typeof onClick === 'function');
    if (typeof onClick === 'function') {
      onClick.apply(this.props.onClickContext, []);
      return;
    }
    this.setState({
      open: !this.state.open
    });
  };

  renderHandler = (open, handlerIcon) => {
    var style = {
      transform: open === true ? 'rotate(90deg)' : '',
      WebkitTransform: open === true ? 'rotate(90deg)' : ''
    },
      src = handlerIcon;

    return <div className="CollapsiblePanel-Handler" style={style} onClick={this.clickHandler}>
      <a><img src={src} /></a>
    </div>;
  };

  renderTitle = (title, handlerIcon, open) => {
    var handler = this.renderHandler(open, handlerIcon);
    return <div className="CollapsiblePanel-Title">
      {handler}
      <div className="CollapsiblePanel-Title-Content-Container">
        <Title show={open}>
          {title}
        </Title>
      </div>
    </div>;
  };

  renderContent = (content, open) => {
    var style = {
      display: open === true ? '' : 'none'
    };
    return <div className="CollapsiblePanel-Content-Container" style={style}>
      <Content show={open}>
        {content}
      </Content>
    </div>;
  };

  isOpen = () => {
    if (typeof this.props.onClickHandler === 'function') {
      return this.props.open;
    }
    return this.state.open;
  };

  render() {
    var { title, children, handlerIcon, onClickHandler, onClickContext, open, ...other } = this.props,
      open = this.isOpen();

    return <div className="CollapsiblePanel" {...other}>
      {this.renderTitle(title, handlerIcon, open)}
      {this.renderContent(children, open)}
    </div>;
  }
}