import React from 'react';
import View from './View';
import { createRoot } from 'react-dom/client';

const FOOTER_CONTAINER_CLASS_NAME = 'crtx-module-footer';

module.exports = class {
  constructor(module) {
    this.module = module;
    this.domElement = this.module.win.document.createElement('div');
    this.domElement.classList.add(FOOTER_CONTAINER_CLASS_NAME);
    this.module.domElement.parentNode.appendChild(this.domElement);
    this.reactRoot = createRoot(this.domElement);
  }

  show = () => {
    this.render();
  };

  addPlanImportInfo = (planImportFileName, planImportTime) => {
    this.model = { ...this.model, planImportFileName, planImportTime };
    this.render();
  };

  render() {
    if (!this.domElement) {
      return;
    }

    this.reactRoot.render(<View model={this.model} />);
  }

  destroy() {
    if (this.domElement) {
      this.reactRoot.unmount();
      if (this.domElement.parentNode) {
        this.domElement.parentNode.removeChild(this.domElement);
      }
      this.domElement = null;
    }
  }
};
