import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class extends Component {
  static propTypes = {
    backgroundColor: PropTypes.string,
  };

  shouldComponentUpdate(nextProps) {
    var { props } = this;
    return nextProps.rowIndex !== props.rowIndex || nextProps.backgroundColor !== props.backgroundColor;
  }

  render() {
    const { backgroundColor } = this.props;
    const cellStyle = {
      backgroundColor
    };

    return <div className="cell" style={cellStyle}>
      <div className="cell-content separator-cell">
        <div className="cell-content-padding disable-text-select">
        </div>
      </div>
    </div>;
  }
}