import { COMPARE_TYPE } from 'core/comparators';
import { isEqual } from 'base/jsUtils';
import { cells } from 'widgets/ReactDataGrid/';
import { toLocaleShortDateTime } from 'core/services/localization';
import { applyColumnPreferences, FILTER } from 'widgets/ReactDataGrid/utils';
import { translate } from 'core/services/localization';

const { Text, IconCell } = cells;

export const defaultShouldCellUpdate = (nextProps, props) => {
  return nextProps.columnData !== props.columnData || nextProps.backgroundColor !== props.backgroundColor ||
    nextProps.title !== props.title || nextProps.icon !== props.icon || nextProps.name !== props.name ||
    !isEqual(nextProps.style, props.style) || nextProps.iconUID !== props.iconUID;
};

export const makeSessionsTableColumns = (model, module) => {
  const columnPrefs = module.preferences.table?.sessionColumns || [];


  let columns = [
    {
      key: 'userName',
      caption: translate('Name'),
      width: 250,
      align: 'center',
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cellDataGetter: (rowIndex, key) => {
        const name = model.filteredSessions[rowIndex]?.userName || '';

        return {
          columnData: name,
        };
      }
    },
    {
      key: 'ip',
      width: 100,
      caption: translate('IP'),
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cellDataGetter: (rowIndex, key) => {
        const ip = model.filteredSessions[rowIndex]?.ip || '';

        return {
          columnData: ip,
        };
      }
    },
    {
      key: 'loginTime',
      caption: translate('Login Time'),
      width: 150,
      resizable: true,
      cell: Text,
      sortType: COMPARE_TYPE.DATES,
      filter: FILTER.DATE,

      cellDataGetter: (rowIndex, key) => {
        const date = model.filteredSessions[rowIndex]?.loginTime || '';

        return {
          columnData: toLocaleShortDateTime(date)
        };
      }
    },
    {
      key: 'userEmail',
      caption: translate('E-Mail'),
      width: 200,
      align: 'left',
      cell: Text,
      filter: FILTER.TEXT_TEXT,
      sortType: COMPARE_TYPE.CASE_INSENSITIVE,
      cellDataGetter: (rowIndex, key) => {
        const email = model.filteredSessions[rowIndex]?.userEmail || '';

        return {
          columnData: email,
        };
      }
    },
    {
      key: 'sessionId',
      caption: translate('Disconnect'),
      width: 100,
      cell: IconCell,
      align: 'center',

      cellDataGetter: (rowIndex, key) => {

        return {
          icon: 'close',
          title: translate('Disconnect session'),
          iconSprite: 'general',
          iconStyle: { color: '#0099FF' },
          onClick: (rowIndex, columnKey, e) => module.handleDisconnectSession(model.filteredSessions[rowIndex].sessionId)
        };
      }
    }
  ];

  columns = applyColumnPreferences(columns, columnPrefs);

  return columns;
};





