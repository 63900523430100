/**
 * @file Combobox component with keyboard navigation support, allowing users to
 * easily navigate through the options using the arrow keys
 *
 * The getOptionProps callback function should return an object with the following properties:
 *   text - option text
 *   group - group name the option belongs to
 *   disabled - indicates whether the option is disabled
 *
 * @author Boris
 * @created 2023-06-26
 */
import React, { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utilities/classNames';
import {
  FloatingPortal,
  FloatingFocusManager,
  autoUpdate,
  size,
  flip,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react';
import { noop } from 'base/jsUtils';
import { TextInput } from 'components/common/inputs';
import { useFloatingZIndex } from 'utilities/zindex';
import { getFloatingPortalNode } from '../utils';
import { Item } from '../components/Item';
import { SeparatorItem } from '../components/SeparatorItem';
import { DropdownArrow } from '../components/DropdownArrow';

export function Combobox(
  {
    value = '',
    options = [],
    className,
    style,
    popoverClassName,
    popoverStyle,
    disabled = false,
    placeholder,
    title,
    getOptionProps: getOptionPropsProp,
    renderOption: renderOptionProp,
    onChange = noop,
    onBlur = noop,
    onSelect = noop,
  }) {

  const [visible, setVisible] = useState(false);
  const [filterEnabled, setFilterEnabled] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  const setVisibleAndDisableFilter = (visible) => {
    setVisible(visible);
    setFilterEnabled(false);
  };

  const getOptionProps = (option) => {
    let result;
    if (typeof getOptionPropsProp === 'function') {
      result = getOptionPropsProp(option);
    } else if (typeof option === 'object' && option !== null) {
      result = option;
    } else {
      result = {
        text: option
      };
    }

    return result;
  };

  const getOptionText = (option) => {
    let { text } = getOptionProps(option);
    text = text ?? '';
    if (typeof text !== 'string') {
      text = String(text);
    }

    return text;
  };

  const renderOption = (option) => {
    let result;
    if (typeof renderOptionProp === 'function') {
      result = renderOptionProp(option);
    } else {
      const { text } = getOptionProps(option);
      result = <div className='option-text'>{text}</div>;
    }

    return result;
  };

  const findOptionIndex = (text) => {
    return options.findIndex(opt => getOptionText(opt) === text);
  };

  const findActiveIndexWhenOpened = (value) => {
    let newActiveIndex = -1;

    const selectedIndex = findOptionIndex(value);
    if (selectedIndex >= 0) {
      newActiveIndex = selectedIndex;
    } else if (value && options.length > 0) {
      newActiveIndex = 0;
    }

    return newActiveIndex;
  };

  const getFilteredOptions = () => {
    if (disabled || !visible) {
      return [];
    }

    if (!value || !filterEnabled) {
      return options;
    }

    return options.filter(opt => {
      return getOptionText(opt).toLowerCase().includes(value.toLowerCase());
    });
  };

  const items = getFilteredOptions();

  const listRef = useRef([]);
  const inputRef = useRef();

  const { x, y, strategy, refs, context } = useFloating({
    whileElementsMounted: autoUpdate,
    open: visible,
    onOpenChange: (opened) => {
      let newActiveIndex = -1;
      if (opened) {
        newActiveIndex = findActiveIndexWhenOpened(value);
      }

      setActiveIndex(newActiveIndex);
      setVisibleAndDisableFilter(opened);
    },
    middleware: [
      flip(),
      size({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`
          });
        },
        padding: 5
      })
    ]
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useRole(context, { role: 'listbox' }),
      useDismiss(context),
      useListNavigation(context, {
        listRef,
        activeIndex,
        onNavigate: setActiveIndex,
        virtual: true,
        loop: true,
      })
    ]
  );

  const handleChange = (event, newValue) => {
    onChange(event, newValue);

    if (newValue) {
      setVisible(true);
      setFilterEnabled(true);
      setActiveIndex(0);
    } else {
      setVisibleAndDisableFilter(false);
    }
  };

  const handleBlur = (event) => {
    onBlur(event);
  };

  const handleSelect = (event, item, index) => {
    if (disabled) {
      return;
    }

    const { text, disabled: optionDisabled } = getOptionProps(item);
    if (optionDisabled) {
      return;
    }

    onChange(event, text);
    onSelect(event, text, index);

    setVisibleAndDisableFilter(false);
    setActiveIndex(-1);
    inputRef.current?.focus();
  };

  const handleKeyDown = (event) => {
    if (disabled || !visible) {
      return;
    }

    if (event.code === 'Enter' && activeIndex >= 0 && activeIndex < items.length) {
      handleSelect(event, items[activeIndex], activeIndex);
    }
  };

  const handleMouseDown = (e) => {
    // Workaround for a Combobox component within the Kendo Window.
    // Refer to the explanations provided in the Dropdown component.
    e.stopPropagation();
  };

  const handleClick = () => {
    if (disabled) {
      return;
    }

    const newVisible = !visible;
    let newActiveIndex = -1;
    if (newVisible) {
      newActiveIndex = findActiveIndexWhenOpened(value);
    }

    setActiveIndex(newActiveIndex);
    setVisibleAndDisableFilter(newVisible);
    inputRef.current?.focus();
  };

  const portalNode = getFloatingPortalNode(context);

  const zIndex = useFloatingZIndex(portalNode, context.open);

  return (
    <>
      <div
        tabIndex={!disabled ? 0 : undefined}
        className={classNames('crtx-combobox', className, { disabled })}
        style={style}
        {...getReferenceProps({
          ref: refs.setReference,
          'aria-autocomplete': 'list',
          onMouseDown: handleMouseDown,
          onClick: handleClick,
          onKeyDown: handleKeyDown,
        })}

      >
        <TextInput
          ref={inputRef}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          title={title}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <DropdownArrow disabled={disabled} opened={visible} />
      </div>

      <FloatingPortal root={portalNode}>
        {visible && items.length > 0 && (
          <FloatingFocusManager
            context={context}
            initialFocus={-1}
          >
            <div
              {...getFloatingProps({
                ref: refs.setFloating,
                className: classNames('crtx-popover', 'crtx-dropdown-popover', popoverClassName, context.placement),
                style: {
                  ...popoverStyle,
                  position: strategy,
                  left: x ?? 0,
                  top: y ?? 0,
                  zIndex: zIndex,
                },
                onKeyDown: handleKeyDown
              })}
            >
              <ul className='crtx-dropdown-listbox'
              >
                {items.map((item, index, arr) => {
                  const { text, disabled: optionDisabled, group } = getOptionProps(item);
                  const active = activeIndex === index;
                  const selected = text === value;
                  let separator = false;
                  if (index > 0) {
                    const { group: prevGroup } = getOptionProps(arr[index - 1]);
                    separator = group !== prevGroup;
                  }

                  const itemElement = (
                    <Item
                      {...getItemProps({
                        key: `${index}${text}`,
                        disabled: optionDisabled,
                        title: text,
                        ref(node) {
                          listRef.current[index] = node;
                        },
                        onClick(event) {
                          handleSelect(event, item, index);
                        }
                      })}
                      active={active}
                      selected={selected}
                    >
                      {renderOption(item)}
                    </Item>
                  );

                  return separator ?
                    <Fragment key={`${index}sep${text}`}>
                      {<SeparatorItem />}
                      {itemElement}
                    </Fragment> :
                    itemElement;
                })}
              </ul>
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  );
}

Combobox.propTypes = {
  value: PropTypes.string,
  options: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.object,
  popoverClassName: PropTypes.string,
  popoverStyle: PropTypes.object,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  getOptionProps: PropTypes.func,
  renderOption: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onSelect: PropTypes.func,
};