import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HoldIndication from './holdIndication';
import PreflightIndication from './preflightIndication';
import LockIndicator from './lockIndicator';
import PreApprovedIndicator from './preApprovedIndicator';
import SkipStepIndicator from './skipStepIndicator';
import NewContentVersionIndicator from './newContentVersionIndicator';
import MultiApproversIndicator from './multiApproversIndicator';
import CustomIconIndicator from './customIconIndicator';
import { getHoldType, getEditionHoldType } from 'utilities/hold';
import { translate } from 'core/services/localization';

const labels = {
  preFlight: translate('Pre Flight'),
  lock: translate('Lock'),
  preApproved: translate('Pre Approved'),
  skipFlowstep: (flowStepsNames) => {
    return flowStepsNames.length === 0 ? '' : flowStepsNames.length > 1 ?
      translate('Skipped Flow Steps: {1}', flowStepsNames.join(', ')) :
      translate('Skipped Flow Step: {1}', flowStepsNames[0]);
  },
  'advertiesr-A': translate('Advertising'),
  'advertiesr-E': translate('Editorial')
};

const isUndefined = o => typeof o === 'undefined';

export default class Indicator extends Component {
  static propTypes = {
    model: PropTypes.object,
    definition: PropTypes.object,
    handlers: PropTypes.object,
    flow: PropTypes.string,
    style: PropTypes.object
  };

  static contextTypes = {
    controller: PropTypes.object,
    module: PropTypes.object
  };

  getIndication = (indication, model, handlers) => {
    const { controller, module } = this.context;
    const indicationName = indication.name;

    if (indicationName === 'hold') {
      return <HoldIndication holdType={module.viewModel.type === 'edition' ? getEditionHoldType(model) : getHoldType(model)} />;
    } else if (indicationName === 'preflight') {
      const reportType = model.pageContent && model.pageContent.preflightReportInfo ?
        model.pageContent.preflightReportInfo.preflightReportType :
        undefined;
      return <PreflightIndication reportType={reportType} title={labels.preFlight} />;
    } else if (indicationName === 'lock') {
      return <LockIndicator locked={controller.isLocked(model)} title={controller.lockedTitle(model)} />;
    } else if (indicationName === 'preapproved') {
      return <PreApprovedIndicator preApproved={controller.isPreApproved(model)} title={labels.preApproved} />;
    } else if (indicationName === 'skipFlowStep') {
      const skipSteps = model.skipSteps === true || model.skipSteps === 'true' ? true : false;
      const { skippedSteps = [] } = model.skipStepIds || {};

      return <SkipStepIndicator skipSteps={skipSteps} title={labels.skipFlowstep(skippedSteps.map(step => step.name))} />;
    } else if (indicationName === 'newContentVersionIndicator') {
      const notCompletedPagesLabels = model.aggregated && model.showNewContentVersionIndicator ? model.pagesLabelsArr : controller.pagesWithNewContentVersion(model);
      return <NewContentVersionIndicator notCompletedPagesLabels={notCompletedPagesLabels} onClick={handlers.handleIndicatorNewVersionClicked} />;
    } else if (indicationName === 'multiApprovers') {
      return isUndefined(model.approvalIndication) ? [] : model.approvalIndication.trim().split(',').sort()
        .map((indicator, index) => <MultiApproversIndicator key={`${indicator}${index}`} approvalIndication={indicator} title={indicator} />);
    } else if (indicationName === 'customIcon') {
      let customIconsObj;
      if (model.type === 'page') {
        customIconsObj = model.pageContent && model.pageContent.customIcons || {};
      } else {
        customIconsObj = model.formContent && model.formContent.customIcons || {};
      }

      return (Object.keys(customIconsObj) || []).map((key, index) => <CustomIconIndicator key={`${key}${index}`} tooltip={key} icon={customIconsObj[key]} moduleNwid={controller.module.nwid} />);
    }
  };

  getIndications = (definition, model, handlers, flow) => {
    var that = this,
      indications = definition.indications || [],
      indicationStyle = {
        float: flow,
        textAlign: 'center'
      };

    return indications.map(function (indication, index) {
      return <div style={indicationStyle} key={[model.id, 'Indication', index].join('.')}>
        {that.getIndication(indication, model, handlers)}
      </div>;
    });
  };

  render() {
    var { model, definition, handlers, flow, style } = this.props,
      indications = this.getIndications(definition, model, handlers, flow);

    return <div className="IndicatorStrip" style={style}>
      {indications}
    </div>;
  }
}