/* @flow weak */

function selectCategory (state, action) {
  return Object.assign({}, state, {selectedCategory: action.category});
}

function selectTable (state, action) {
  return Object.assign({}, state, {selectedTable: action.table});
}

module.exports = function (state = {}, action = {}) { 
  switch(action.type) {
    case 'SELECT_CATEGORY': 
      return selectCategory(state, action);
    case 'SELECT_TABLE': 
      return selectTable(state, action);
    default: 
      return state;
  }
};