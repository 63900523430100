import React from 'react';
import { startInternalModule } from 'core/managers/module';
import dialogService from 'core/services/dialogService';
import browser from 'base/browser';
import sandbox from 'sandbox';
import { makeUniqueName } from 'utilities/uniqueName';
import { createToastService } from 'core/services/toastService';
import settingsManager from 'core/managers/settings';
import toastService from 'core/services/toastService';
import { restPost, restPut } from 'core/managers/rest2';
import { includesSpecialChars, SPECIAL_CHARS } from 'utilities/regex';
import { DIALOG_TYPE } from "./constants";

const { translate } = sandbox.localization;

const labels = {
  getFolders: translate('Request folder list'),
  createFolder: translate('Create folder'),
  duplicateFolder: translate('Duplicate folder'),
  editFolder: translate('Edit folder'),
  deleteFolder: translate('Delete folder'),
  changeFolder: translate('Change active folder'),
  restartMessage: translate('You must restart Tomcat after import.'),
  continue: translate('Do you want to continue?'),
  importFolderTitle: translate('Import Folder')
};

export default class FolderMenu {
  constructor(module, dataAdapter) {
    this.module = module;
    this.dataAdapter = dataAdapter;

    this.toastService = createToastService(this.module.win);
  }

  getRootAction(actionName) {
    const rootActions = this.module.getRelevantActions(this.dataAdapter.getTickableModel());

    return rootActions.find(a => a.actionDefinitionName === actionName);
  }

  getRootNwid() {
    return this.dataAdapter.getTickableModel().nwid;
  }

  makeUniqueFolderName(nwid) {
    const folders = this.dataAdapter.foldersInfo;
    if (!Array.isArray(folders)) {
      return '';
    }

    const folderName = (folders.find(folder => folder.nwid === nwid) || {}).label;
    const allNames = folders.map(folder => folder.label);
    return makeUniqueName(allNames, folderName);
  }

  validateFolderName(folderName) {
    if (includesSpecialChars(folderName)) {
      sandbox.dialog.alert(translate('A folder name cannot contain any of the following characters:') +
        '<br>' + SPECIAL_CHARS, translate('Invalid Folder'));
      return false;
    }

    if (this.dataAdapter.foldersInfo.findIndex(folder => folder.label.toLowerCase() === folderName.toLowerCase()) >= 0) {
      sandbox.dialog.alert(translate('There is already folder with the same name:') +
        '<br>' + folderName, translate('Folder Already Exists'));
      return false;
    }

    return true;
  }

  exportFolder = (folder) => {

    this.module.exportFolderProps = {
      showExportDialog: true,
      exportAction: this.getRootAction('ExportFolderActionCR'),
      folderToExportName: folder.label,
    };

    this.module.rootNwid = folder.nwid;
    this.module.doRender();
  };

  importFolder = () => {

    let msg = labels.restartMessage + "<br>" + "<br>" + labels.continue;

    dialogService.openConfirmDialog(msg, labels.importFolderTitle)
      .then(() => {
        this.module.importFolderProps = {
          showImportDialog: true,
          importAction: this.getRootAction('ImportFolderActionCR')
        };

        this.module.rootNwid = this.getRootNwid();
        this.module.doRender();
      });
  };

  openCreateEditDuplicateFolderDialog = (event, dialogType, folder) => {
    event.stopPropagation();

    this.module.dialogType = dialogType;
    if (dialogType === DIALOG_TYPE[0]) {
      this.module.createFolderProps = {
        showCreateFolderDialog: true,
        suggestedFolderName: translate('New folder'),
        createAction: this.getRootAction('CreateFolderActionCR')
      };
    } else if (dialogType === DIALOG_TYPE[1]) {
      this.module.editFolderProps = {
        showEditFolderDialog: true,
        suggestedFolderName: folder.text,
        folderNwid: folder.id,
        editAction: this.getRootAction('RenameFolderActionCR')
      };
    } else {
      this.module.duplicateFolderProps = {
        folderNwid: folder.nwid,
        showDuplicateFolderDialog: true,
        suggestedFolderName: this.makeUniqueFolderName(folder.nwid),
        duplicateAction: this.getRootAction('DuplicateFolderActionCR')
      };
    }

    this.module.doRender();
  };

  duplicateFolder(folderName, duplicateAction, folderNwid) {
    restPost(duplicateAction.nwid, `folders/${folderNwid}/duplicateFolder`, { name: folderName })
      .then(res => {
        const msg = translate('The folder has been successfully duplicated.');
        toastService.successToast(translate('Duplicate Folder'), translate('The folder has been successfully duplicated.'));

      })
      .catch(err => {
        const msg = translate('Duplicate folder failed: {1}', err.statusText);
        toastService.errorToast(translate('Duplicate Folder'), msg);
      });
  }

  createFolder(folderName, createAction) {
    restPost(createAction.nwid, `folders/${this.getRootNwid()}/folders`, { name: folderName })
      .then(res => {
        const msg = translate('The new folder has been successfully created.');
        toastService.successToast(translate('Create New Folder'), msg);
      })
      .catch(err => {
        const msg = translate('Create folder failed: {1}', err.statusText);
        toastService.errorToast(translate('Create New Folder'), msg);
      });
  }

  editFolder(folderName, editAction, folderNwid) {
    restPost(editAction.nwid, `folders/${this.getRootNwid()}/folders/${folderNwid}`, { name: folderName })
      .then(res => {
        const msg = translate('The folder name has been successfully edited.');
        toastService.successToast(translate('Rename Folder'), msg);
      })
      .catch(err => {
        const msg = translate('Rename folder failed: {1}', err.statusText);
        toastService.errorToast(translate('Rename Folder'), msg);
      });
  }

  handleDeleteFolderClick = (event, item) => {
    event.stopPropagation();

    const deleteAction = this.getRootAction('DeleteFolderActionCR');
    startInternalModule('DeleteFolderSetup', {
      name: translate('Delete Folder'),
      target: 'dialog',
      actionInstanceNwId: deleteAction.nwid,
      moduleNwid: this.module.nwid,
      rootNwid: this.getRootNwid(),
      folderNwid: item.id,
      folderName: item.text,
      onSave: () => {
        console.log('### DeleteFolderSetup closed');
      }

    }, this);

  };

  getMenuItemButtons(folderNwid) {
    const buttons = [];

    const editAction = this.getRootAction('RenameFolderActionCR');
    if (editAction) {
      buttons.push({
        className: 'edit',
        iconName: 'edit',
        onClick: (event, folder) => this.openCreateEditDuplicateFolderDialog(event, DIALOG_TYPE[1], folder)
      });
    }

    if (folderNwid !== settingsManager.get('currentFolderNwid')) {
      const deleteAction = this.getRootAction('DeleteFolderActionCR');
      if (deleteAction) {
        buttons.push({
          className: 'delete',
          iconName: 'delete',
          onClick: this.handleDeleteFolderClick.bind(this)
        });
      }
    }

    return buttons;
  }

  buildMenuItems(foldersInfo) {
    if (!Array.isArray(foldersInfo)) {
      return [];
    }

    const predefinedItems = [];
    const createAction = this.getRootAction('CreateFolderActionCR');
    if (createAction) {
      predefinedItems.push({
        id: 'new',
        text: 'Create new folder'
      });
    }

    const duplicateAction = this.getRootAction('DuplicateFolderActionCR');
    if (duplicateAction) {
      predefinedItems.push({
        id: 'duplicate',
        text: 'Duplicate folder'
      });
    }

    const exportFolderAction = this.getRootAction('ExportFolderActionCR');
    if (exportFolderAction) {
      predefinedItems.push({
        id: 'exportFolder',
        text: 'Export folder'
      });
    }

    const importFolderAction = this.getRootAction('ImportFolderActionCR');
    if (importFolderAction) {
      predefinedItems.push({
        id: 'importFolder',
        text: 'Import folder'
      });
    }

    if (predefinedItems.length > 0) {
      predefinedItems.push({
        type: 'separator'
      });
    }

    let folderItems = [];
    if (this.getRootAction('RenameFolderActionCR') || foldersInfo.length > 1) {
      folderItems = foldersInfo.map(folder => ({
        id: folder.nwid,
        text: folder.label,
        buttons: this.getMenuItemButtons(folder.nwid)
      })
      );
    }

    return predefinedItems.concat(sandbox.sorting.sort(folderItems, 'caseinsensitive', 'text'));

  }

  editNameGetter(props) {
    return <EditName {...props} />;
  }

  changeActiveFolder(folderNwid) {
    if (folderNwid === settingsManager.get('currentFolderNwid')) {
      return;
    }

    restPut(this.module.nwid, 'folders/last-login-folder', { lastLoginFolderNwId: folderNwid })
      .then(res => {
        browser.reload();
      })
      .catch(err => {
        this.toastService.createToast('bottom-right', labels.changeFolder, 'Change folder failed: ' + err.statusText, 'error', undefined, undefined, false);
      });
  }
}
