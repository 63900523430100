import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utilities/classNames';
import IconButton from './IconButton';

function Expander(
  {
    expanded = false,
    className,
    style,
    iconClassName,
    iconStyle,
    disabled = false,
    tooltip,
    onClick
  }) {

  return (
    <IconButton
      iconName='chevron_right'
      className={classNames('crtx-size-24', { 'rotate-90': expanded }, className)}
      style={style}
      iconClassName={classNames('crtx-padding-2', iconClassName)}
      iconStyle={iconStyle}
      disabled={disabled}
      tooltip={tooltip}
      onClick={onClick}
    />
  )
}

Expander.propTypes = {
  expanded: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  iconClassName: PropTypes.string,
  iconStyle: PropTypes.object,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  onClick: PropTypes.func
};

export default Expander;
