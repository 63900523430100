import React, { Children } from 'react';
import PropTypes from 'prop-types';
import TabStripContent from './TabStripContent';

// import './tabStrip.css';

function TabStrip({ children, className = '', style, selected, onSelect }) {
  const tabStripCss = `crtx-tabstrip ${className}`;
  const childArray = Children.toArray(children);

  return (
    <div className={tabStripCss} style={style}>

      <ul className={'crtx-tabstrip-bar'}>
        {
          childArray.map((child, index) => {
            const { caption, disabled = false, className: tabClassName = '', style: tabStyle } = child.props;

            let handleClick;
            if (!disabled && typeof onSelect === 'function') {
              handleClick = e => onSelect(e, index);
            }

            const itemCss = `crtx-tabstrip-item ${tabClassName} ${index === selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`;

            return (
              <li key={`${index}`} className={itemCss} style={tabStyle} onClick={handleClick}>
                {caption}
              </li>
            );
          })
        }
      </ul>

      {
        childArray.map((child, index) => {
          const { disabled = false, contentClassName = '' } = child.props;

          if (disabled) {
            return null;
          }

          let style;
          if (index !== selected) {
            style = { display: 'none' };
          }

          return (
            <TabStripContent key={`${index}`} className={contentClassName} style={style}>
              {
                childArray[index].props.children
              }
            </TabStripContent>
          );
        })
      }

    </div>
  );

}

TabStrip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  selected: PropTypes.number,
  onSelect: PropTypes.func,
};

export default TabStrip;