/**
 * @name Toolbar
 * @file Main panel toolbar
 *
 * @author Boris
 * @since: 2017-09-07
 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import sandbox from 'sandbox';
import ToolbarComponent from './ToolbarComponent';
import { Placeholder } from 'core/placeholders';

const jsUtils = sandbox.jsUtils;

const TOOLBAR_CONTAINER_CLASS_NAME = 'crtx-module-toolbar';
const ACTION_PROPS_TO_COPY = ['name', 'actionClass', 'label', 'tooltip', 'icon', 'itemType', 'checked',
  'groupName', 'alignRight', 'className', '_isApplicable', 'tooltipOn', 'tooltipOff', 'labelOn', 'labelOff',
  'iconOn', 'iconOff', 'menuItems', 'menuWidth', 'menuHeight', 'menuAlign', 'iconStyle', 'iconSprite'];
const TOGGLE_PROPS_TO_COPY = ['labelOn', 'labelOff', 'iconOn', 'iconOff'];

function isToggleServerAction(action) {
  return action.parameters && action.parameters.type === 'toggle';
}

function copyActionProps(placeholder, action) {
  placeholder.action = action;
  ACTION_PROPS_TO_COPY.forEach(prop => {
    if (typeof action[prop] !== 'undefined') {
      placeholder[prop] = action[prop];
    }
  });

  if (isToggleServerAction(action)) {
    placeholder.itemType = 'toggle';
    TOGGLE_PROPS_TO_COPY.forEach(prop => {
      placeholder[prop] = action.parameters[prop];
    });
  }

  placeholder.tooltip = placeholder.tooltip || placeholder.label;
  placeholder.tooltipOn = placeholder.tooltipOn || placeholder.labelOn;
  placeholder.tooltipOff = placeholder.tooltipOff || placeholder.labelOff;
}

module.exports = class {
  constructor(module) {
    this.module = module;
    this.items = [];
    this.domElement = this.module.win.document.createElement('div');
    this.domElement.classList.add(TOOLBAR_CONTAINER_CLASS_NAME);
    this.module.domElement.parentNode.insertBefore(this.domElement, this.module.domElement);
    this.reactRoot = createRoot(this.domElement);
  }

  /**
   * Create a placeholder from the given action and assign action's UI properties to the placeholder
   * @param action
   */
  addItem(action) {
    if (action instanceof Placeholder) {
      this.items.push(action);
    } else {
      // Ensure backward compatibility: toolbar button should have an icon, but not always a name
      // If the icons are the same, compare by action name as well
      let placeholder = this.items.find(item => item.icon && action.icon && item.icon === action.icon &&
        item.name === action.name);

      if (!placeholder) {
        placeholder = new Placeholder({
          type: 'action'
        });

        // TODO: remove unshift property
        if (action.unshift === true) {
          this.items.unshift(placeholder);
        } else {
          this.items.push(placeholder);
        }
      }

      copyActionProps(placeholder, action);
    }

    this.renderDebounced();
  }

  /**
   * Remove toolbar item by action name
   * @param actionName
   */
  removeItem(actionName) {
    const idx = this.items.findIndex(item => item.name === actionName);
    if (idx >= 0) {
      this.items.splice(idx, 1);
    }
  }

  /**
   * Remove toolbar items that belong to the given group
   * @param groupName
   */
  removeGroup(groupName) {
    if (!groupName) {
      return;
    }

    const itemsToRemove = this.items.filter(item => item.groupName === groupName);
    itemsToRemove.forEach(item => this.removeItem(item.name));
  }

  /**
   * Set item disabled property to true or false
   * @param actionName - the action name
   * @param disabled - true | false
   */
  setItemDisabled(actionName, disabled) {
    const item = this.items.find(item => item.name === actionName);
    if (item) {
      item.disabled = disabled;
      this.renderDebounced();
    }
  }

  /**
   * Set item checked property to true or false
   * @param actionName
   * @param checked
   */
  setItemChecked(actionName, checked) {
    const item = this.items.find(item => item.name === actionName && (item.itemType === 'toggle' || item.itemType === 'push'));
    if (item) {
      item.checked = checked;
      this.renderDebounced();
    }
  }

  /**
   * Set item Class name property to CSS class name
   * @param actionName
   * @param className
   */
  setItemClassName(actionName, className) {
    const item = this.items.find(item => item.name === actionName);
    if (item) {
      item.className = className;
      this.renderDebounced();
    }
  }

  /**
   * Update action _isApplicable property by applying action isApplicable() method and re-render toolbar.
   * Note: setTimeout() function is used to ensure that the action.isApplicable() method is already attached in
   *       the action.initDone() method (module.createToolbar() is called in the module.initDone() that is invoked
   *       before the action.initDone() method)
   */
  refreshIsApplicableProperty() {
    // refresh toolbar after all actions are initialized
    setTimeout(() => {
      this.items.forEach(item => {
        item._isApplicable = item.isApplicable();
      });

      this.renderDebounced();
    }, 0);
  }

  updatePlaceholders(actionObjects, context) {
    this.items.forEach(item => {
      // reset Action Objects of the item that has the given action context name
      if (item.context && item.context === context) {
        item.setActionObjects([]);
      }

      const relevantActionObjects = actionObjects.filter(actionObj => item.label && actionObj.action.label === item.label);
      if (relevantActionObjects.length > 0) {
        item.context = context;
        item.setActionObjects(relevantActionObjects);
      }
    });

    this.refreshIsApplicableProperty();
  }

  /**
   * Programmatically click toolbar item with the given actionName
   * @param actionName
   */
  clickItem(actionName) {
    const item = this.items.find(item => item.name === actionName);
    if (item && !item.disabled && item._isApplicable) {
      if (item.itemType === 'toggle') {
        this.handleToggleButtonClick(item);
      } else if (item.itemType !== 'menu') {
        this.handleButtonClick(item);
      }

      this.refreshIsApplicableProperty();
    }
  }

  handleButtonClick = (item) => {
    item.execute();

    this.refreshIsApplicableProperty();
  };

  handleToggleButtonClick = (item) => {
    if (item.action) {
      const checked = !item.checked;
      item.checked = checked;
      item.action.execute(checked);
    } else {
      // You should NOT pass parameters to the execute() method of the server action.
      // In this case the execute() method will receive the selected object as an argument,
      // e.g. the Flow Step object for the ToggleSkipDeviceAction action
      item.execute();
    }

    this.refreshIsApplicableProperty();
  };

  handlePushButtonClick = (item) => {
    const checked = !item.checked;
    if (item.groupName) {
      this.items.forEach(i => {
        if (i.groupName === item.groupName) {
          i.checked = false;
        }
      });
    }

    item.checked = checked;
    item.action.execute(checked, item.name, item.groupName);

    this.refreshIsApplicableProperty();
  };

  handleMenuTriggerClick = (item, visible) => {
    const menuItems = visible ? item.action.execute() : [];
    if (Array.isArray(menuItems)) {
      item.menuItems = menuItems;
    }

    this.refreshIsApplicableProperty();
  };

  handleMenuItemSelect = (item, menuItem) => {
    menuItem.execute();
    this.refreshIsApplicableProperty();
  };

  renderDebounced = jsUtils.debounce(() => {
    this.render();
  }, 5, { leading: false, trailing: true, maxWait: 5 });

  render() {
    if (!this.domElement) {
      return;
    }

    this.reactRoot.render(
      <ToolbarComponent
        toolbarItems={this.items}
        onButtonClick={this.handleButtonClick}
        onToggleButtonClick={this.handleToggleButtonClick}
        onPushButtonClick={this.handlePushButtonClick}
        onMenuTriggerClick={this.handleMenuTriggerClick}
        onMenuItemSelect={this.handleMenuItemSelect}
      />);
  }

  destroy() {
    if (this.domElement) {
      this.reactRoot.unmount();
      this.domElement.remove();
      this.domElement = null;
    }
  }
};
