/**
 * @file PopoverTrigger, also known as reference or anchor,
 * is responsible for opening and closing floating controls
 *
 * @author Boris
 * @created 2023-02-20
 */

import { forwardRef, cloneElement } from 'react';
import { useMergeRefs } from '@floating-ui/react';
import { usePopoverContext } from './PopoverContext';
import PropTypes from 'prop-types';

export const PopoverTrigger = forwardRef(
  function PopoverTrigger({ children, ...props }, propRef) {
    const { open, refs, getReferenceProps } = usePopoverContext();
    const setRef = useMergeRefs([refs.setReference, propRef, children.ref]);

    return cloneElement(
      children,
      getReferenceProps({
        ref: setRef,
        ...props,
        ...children.props,
        'data-state': open ? 'open' : 'closed'
      })
    );
  });

PopoverTrigger.propTypes = {
  children: PropTypes.node,
};
