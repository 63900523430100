import { ActionContainer } from 'utilities/betaFeatures';
import sandbox from 'sandbox';
import AbstractAction from 'AbstractAction';

const isUndefined = o => typeof o === 'undefined';

const oldAction = AbstractAction.extend({

  execute: function () {
    var params = this.module.ctrl.getLoadedVersion();
    params.killEvents = 'false';
    sandbox.request.execServerAction(
      this.nwid,
      this.actionName,
      this.module.selected[0].nwid,
      this.module.selected[0].type,
      this.module.projectorId,
      this.parameters.scriptName,
      params
    );
  }
});;

const newAction = AbstractAction.extend({

  isApplicable: function () {
    let applicable = false;

    if (this.module.getActiveVersion) {
      const activeVersionKey = this.module.getActiveVersion().key;
      const selectedVersionKey = this.module.getSelectedVersion().key;
      if (isUndefined(activeVersionKey) || isUndefined(selectedVersionKey)) {
        return false;
      }
      applicable = activeVersionKey !== selectedVersionKey;
    }

    return applicable;
  },

  execute: function () {
    const selected = this.module.selected[0];
    const selectedVersion = this.module.getSelectedVersion();
    var params = selectedVersion.versionData;
    params.killEvents = 'false';
    sandbox.request.execServerAction(
      this.nwid,
      this.actionName,
      selected.nwid,
      selected.type,
      this.module.projectorId,
      this.parameters.scriptName,
      params
    );
  }
});;



export default class extends ActionContainer {

  featureFlag = 'PageView';

  defaultFeatureValue = 'new';

  features = {
    old: oldAction,
    new: newAction
  };

};
