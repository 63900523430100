/**
 * Created by tzvikas on 5/20/2015.
 */
define(['ember', './Page','text!../templates/form.hbs', 'kendo-ember', './../../../utils/wizardCommons', './DynamicTextField', './DynamicCheckBox', './DynamicSelect'],
  function (Ember, PageView, MainTemplate, kendoComponents, wizardCommons, DynamicText, DynamicCheckbox, DynamicSelect) {
    'use strict';

    function getInput(colDescriptor, controller, section){
      if (colDescriptor.type === "text" || colDescriptor.type === "numeric"){

        var dsbl = false;
        var readonly = colDescriptor['readonly'];
        if (colDescriptor.name === 'startPage'){
          if (section && section.sourceSection){
            dsbl = true;
            readonly = true;
          }
        }

        return DynamicText.create({
          controller: controller,
          attributeBindings: ['style', 'sourceSection'],
          numeric: (colDescriptor.type === "numeric"),
          model: section,
          key: colDescriptor.name,
          classNames: ['k-textbox'],
          style: colDescriptor['style'],
          disabled: dsbl,
          readonly: readonly
        });
      } else if (colDescriptor.type === "checkbox"){
        var dsbl = false;
        if (colDescriptor.name === 'split' || colDescriptor.name === 'skip'){
          if (section && section.sourceSection){
            dsbl = true;
          }
        }
        return DynamicCheckbox.create({
          controller: controller,
          model: section,
          disabled: dsbl,
          key: colDescriptor.name
        });
      } else if (colDescriptor.type === "select") {

        //return Ember.Select.create({
        //	content: colDescriptor.options,
        //	optionLabelPath: 'content.label',
        //	optionValuePath: 'content.name',
        //	//value: section[colDescriptor.name],
        //	selected: section[colDescriptor.name],
        //	model: section,
        //	onchange: 'alert(2)'
        //
        //});
        //section[colDescriptor.name] = section[colDescriptor.name] || colDescriptor.options[0].name;
        return DynamicSelect.create({
          content: colDescriptor.options,
          optionLabelPath: 'content.label',
          optionValuePath: 'content.name',
          controller: controller,
          model: section,
          key: colDescriptor.name,
          value: section[colDescriptor.name]
          //value: section[colDescriptor.name],


        });
      }
    }

    var InputView = Ember.ContainerView.extend({

      classNames: ["checkBox-container"],

      init: function() {
        this._super();
        var colDescriptor = this.get('colDescriptor');
        var controller = this.get('controller');
        var section = this.get('model');
        var inp = getInput(colDescriptor, controller, section);
        this.pushObject(
          inp
        );
      }});

    return Ember.View.extend(wizardCommons.viewCommons, {
      onActivate: function(){

      },

      template: Ember.Handlebars.compile(MainTemplate),

      pageView: PageView,
      inputView: InputView,

      comboWidth: function () {
        var layoutType = this.get('model.layoutType');

        if (layoutType === "tabs" || layoutType === "broadsheet"){
          return "singleComboBase";
        } else {
          return "doubleComboBase";
        }
      }.property ('model.layoutType'),

      layoutBase: function (){
        var layoutType = this.get('model.layoutType');

        if (layoutType === "broadsheet"){
          var pages = Ember.get(this.model, "pages");
          for (var i=0;i<pages.length;i++){
            if (pages[i].panorama){
              return "doubleFormBase";
            }
            return "singleFormBase";
          }
        } else if (layoutType === "tabs"){
          return "singleFormBase";
        } else {
          return "doubleFormBase";
        }
      }.property ('model.layoutType'),

      layoutOptions: function () {
        return Ember.get(this, "controller").getFormLayouts(Ember.get(this, "model"));
      }.property ('model.layoutType'),

      isFourUps: function () {
        var layoutType = this.get('model.layoutType');
        return layoutType === "4ups";
      }.property (),

      isEightUps: function () {
        var layoutType = this.get('model.layoutType');
        return layoutType === "8ups";
      }.property (),

      isFourOrEightUps : function () {
        return this.isFourUps || this.isEightUps;
      }.property (),

      sortedPages: function () {
        var pages = Ember.get(this.model, "pages");
        return pages.concat().sort(function (p1, p2){
          var i =  (p1.index === null || typeof p1.index === 'undefined') ? 0 : p1.index[0];
          var j =  (p2.index === null || typeof p2.index === 'undefined') ? 0 : p2.index[0];
          return i - j;
        });
      }.property(),

      layoutChanged: function () {
        Ember.get(this, "controller").rebuildForms(Ember.get(this, "model"));
      }.observes("model.layoutNwId")






    });
  });