function rowsJustLoaded(prevProps, props) {
  return prevProps.rows.length === 0 && props.rows.length > 0;
}

function filterableColumns(columns) {
  return function alwaysVisibleColumnsComparator(child) {
    return columns[child.props.columnKey].visible !== 'always';
  }
}

function isVisible(column) {
  return column.visible === true || column.visible === 'true' || column.visible === 'always';
}

function isVisibleFlexChild(columns) {
  return child => Number(child.props.flex) > 0 && isVisible(columns[child.props.columnKey]);
}

function isVisibleNonFlexChild(columns) {
  return child => (isNaN(child.props.flex) || Number(child.props.flex) <= 0) && isVisible(columns[child.props.columnKey]);
}

function getVisibleNotFlexChilds(columns) {
  return function isVisibleNonFlexChild(child) {
    return child.props.flex === undefined && isVisible(columns[child.props.columnKey]);
  }
}

function isLast(items, index) {
  return index === items.length - 1;
}

function getNextVisibleColumnIndex(children, columns, index) {
  var pIndex = index < 0 ? 0 :
    index >= columns.length ? columns.length :
      index + 1;

  while (pIndex < children.length && !isVisible(columns[children[pIndex].props.columnKey])) {
    pIndex++;
  }
  if (pIndex === children.length) return -1;

  return pIndex;
}

function getNextColumn(children, columns, index) {
  const nextVisibleColumnIndex = getNextVisibleColumnIndex(children, columns, index);

  return nextVisibleColumnIndex === -1 ? null : children[nextVisibleColumnIndex];
}

function isFixed(position) {
  return function (column) {
    return column.props.fixed === position;
  };
}

export default {
  rowsJustLoaded,
  filterableColumns,
  isVisible,
  isVisibleFlexChild,
  isVisibleNonFlexChild,
  getVisibleNotFlexChilds,
  isLast,
  getNextColumn,
  isFixed,
};