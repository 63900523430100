import PropTypes from 'prop-types';
import React, { Component, cloneElement } from 'react';

const CLASS_NAME = 'crtx-selectable-border';

const isUndefined = o => typeof o === 'undefined';
const isFunction = o => typeof o === 'function';

export default class SelectableBorder extends Component {
  static propTypes = {
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onContextMenu: PropTypes.func,
    children: PropTypes.element
  };

  static defaultProps = {
    selected: false
  };

  render() {
    const { selected, onClick, onDoubleClick, onContextMenu, children } = this.props;

    return cloneElement(children, {
      className: `${children.props.className}${selected ? ' ' + CLASS_NAME + ' selected' : ''}`,
      onClick: (...args) => {
        onClick(...args);
        if (isFunction(children.props.onClick)) children.props.onClick(...args);
      },
      onDoubleClick: (...args) => {
        onDoubleClick(...args);
        if (isFunction(children.props.onDoubleClick)) children.props.onDoubleClick(...args);
      },
      onContextMenu: (...args) => {
        onContextMenu(...args);
        if (isFunction(children.props.onContextMenu)) children.props.onContextMenu(...args);
      }
    });
  }
}
