import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'components/common/inputs/Checkbox';
import NumericInput from 'components/common/inputs/NumericInput';
import Textarea from 'components/common/inputs/TextareaInput';
import { Dropdown } from 'components/common/floating';
import { Validator, useValidator } from 'components/private/forms';
import { classNames } from 'utilities/classNames';
import Header from 'components/common/headers/Header';
import { translate } from 'core/services/localization';

function View({ modelHandler, catalogs, ignoreMps }) {

  const [stepModel, setStepModel] = useState(modelHandler.getStepModel());
  const [formatOptions, setFormatOptions] = useState([]);
  const [formatOptionsEnabled, setFormatOptionsEnabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const { register, validate } = useValidator();

  const updateStepModel = partialModel => {
    const newStepModel = { ...stepModel, ...partialModel };

    setStepModel(newStepModel);
    modelHandler.setStepModel(newStepModel);
  };

  const handleSelectCatalog = (event, value) => {
    let formatOptions = [];

    if (value !== '') {
      setLoading(true);
      setFormatOptionsEnabled(false);
      modelHandler.getFormatIds(value)
        .then(result => {
          formatOptions = result.formatIds;
          formatOptions.unshift('');
          setFormatOptions(formatOptions);
          updateStepModel({ mpsCatalog: value, mpsFormat: '' });
          setFormatOptionsEnabled(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      formatOptions.push('');
      setFormatOptions(formatOptions);
      updateStepModel({ mpsCatalog: value, mpsFormat: '' });
      setFormatOptionsEnabled(false);
    }
  };

  const handleSelectFormat = (event, value) => {
    if (value !== '') {
      setLoading(true);
      updateStepModel({ mpsFormat: value });
      modelHandler.getFormatDetails(value).
        then(result => {
          console.log('Loading details-', result);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      updateStepModel({ mpsFormat: value });
    }
  };

  const handleChangeKbaEnabled = (event, value) => {
    let newPressSystem = '';

    if (value) {
      newPressSystem = stepModel.mpsEnabled ? 'both' : 'kba';
    } else {
      newPressSystem = stepModel.mpsEnabled ? 'mps' : '';
    }

    updateStepModel({ kbaEnabled: value, pressSystem: newPressSystem });
  };

  const handleChangeKbaCircullation = (event, value) => {

    updateStepModel({ kbaCircullation: value });
  };

  const handleChangeKbaComments = (event, value) => {
    updateStepModel({ kbaComments: value });
  };

  const handleChangeMpsEnabled = (event, value) => {
    let newPressSystem = '';

    if (value) {
      newPressSystem = stepModel.kbaEnabled ? 'both' : 'mps';
    } else {
      newPressSystem = stepModel.kbaEnabled ? 'kba' : '';
    }

    updateStepModel({ mpsEnabled: value, pressSystem: newPressSystem });
  };

  const handleChangeMpsCircullation = (event, value) => {
    updateStepModel({ mpsCircullation: value });
  };

  const handleChangeMpsComments = (event, value) => {
    updateStepModel({ mpsComments: value });
  };

  return (
    <div className='crtx-mps-step-container'>
      <div className='crtx-form-section-container'>
        <div className='crtx-form-element-full-height ctrx-inner-grid-two-rows'>
          <div className='crtx-form-two-columns'>
            {!ignoreMps &&
              <React.Fragment>
                <div className='crtx-form-full-row'>
                  <label className='crtx-form-label-with-checkbox'>
                    <Checkbox checked={stepModel.mpsEnabled} onChange={handleChangeMpsEnabled} />
                    {translate('MPS')}</label>
                </div>

                <label
                  disabled={!stepModel.mpsEnabled}
                  className={classNames('crtx-form-margin-left-small', { disabled: !stepModel.mpsEnabled })}>
                  {translate('Catalog:')}
                </label>
                <Validator register={register} rules={['required']} disabled={!stepModel.mpsEnabled}>
                  <Dropdown className={classNames('crtx-form-element-xlarge', { disabled: !stepModel.mpsEnabled })}
                    value={stepModel.mpsCatalog}
                    options={catalogs}
                    disabled={!stepModel.mpsEnabled}
                    getOptionProps={o => {
                      if (o.id === '') {
                        return ({ value: '', text: translate('Select catalog') });
                      } else {
                        return ({ value: o?.id, text: o?.name });
                      }
                    }}
                    onSelect={handleSelectCatalog} />
                </Validator>

                <label
                  disabled={!stepModel.mpsEnabled || !formatOptionsEnabled}
                  className={classNames('crtx-form-margin-left-small', { disabled: !stepModel.mpsEnabled })}>
                  {translate('Format:')}
                </label>
                <div className='crtx-form-composed-element'>
                  <Validator register={register} rules={['required']} disabled={!stepModel.mpsEnabled || !formatOptionsEnabled}>
                    <Dropdown
                      className={classNames('crtx-form-element-xlarge', { disabled: !stepModel.mpsEnabled || !formatOptionsEnabled })}
                      value={stepModel.mpsFormat}
                      options={formatOptions}
                      disabled={!stepModel.mpsEnabled || !formatOptionsEnabled}
                      getOptionProps={o => {
                        if (o === '') {
                          return ({ value: '', text: translate('Select format') });
                        } else {
                          return ({ value: o, text: o });
                        }
                      }}
                      onSelect={handleSelectFormat} />
                  </Validator>
                  {loading &&
                    <img className='loading crtx-form-margin-left-xsmall ' src='../kernel/assets/img/sys/loading_data.gif' alt={translate('Waiting')} />}
                </div>
                <label
                  disabled={!stepModel.mpsEnabled}
                  className={classNames('crtx-form-margin-left-small', { disabled: !stepModel.mpsEnabled })}>
                  {translate('Circullation:')}
                </label>
                <NumericInput
                  className='crtx-form-element-xxsmall'
                  disabled={!stepModel.mpsEnabled}
                  value={stepModel.mpsCircullation}
                  allowEmptyValue={true}
                  onChange={handleChangeMpsCircullation} />

                <label
                  disabled={!stepModel.mpsEnabled}
                  className={classNames('crtx-form-margin-left-small crtx-form-label-top-alignment ', { disabled: !stepModel.mpsEnabled })}>
                  {translate('Comments:')}
                </label>
                <Textarea value={stepModel.mpsComments}
                  className='crtx-form-element-xlarge'
                  disabled={!stepModel.mpsEnabled}
                  rows={10}
                  onChange={handleChangeMpsComments} />
              </React.Fragment>
            }

            <div className='crtx-form-full-row'>
              {!ignoreMps ? <React.Fragment>
                <label className='crtx-form-label-with-checkbox'>
                  <Checkbox checked={stepModel.kbaEnabled} onChange={handleChangeKbaEnabled} />
                  {translate('KBA')}</label>
              </React.Fragment> :
                <Header>{translate('KBA')}</Header>
              }
            </div>



            <label
              disabled={!stepModel.kbaEnabled}
              className={classNames('crtx-form-margin-left-small', { disabled: !stepModel.kbaEnabled })}>
              {translate('Circullation:')}
            </label>
            <NumericInput
              className='crtx-form-element-xxsmall'
              disabled={!stepModel.kbaEnabled}
              value={stepModel.kbaCircullation}
              allowEmptyValue={true}
              onChange={handleChangeKbaCircullation} />

            <label
              disabled={!stepModel.kbaEnabled}
              className={classNames('crtx-form-margin-left-small crtx-form-label-top-alignment ', { disabled: !stepModel.kbaEnabled })}>
              {translate('Comments:')}
            </label>
            <Textarea
              value={stepModel.kbaComments}
              disabled={!stepModel.kbaEnabled}
              className='crtx-form-element-xlarge'
              rows={10}
              onChange={handleChangeKbaComments} />

          </div>
        </div>
      </div>
    </div>
  );
}

View.propTypes = {
  modelHandler: PropTypes.any,
  catalogs: PropTypes.array,
  ignoreMps: PropTypes.bool
};

export default View;
