import React, { Component } from 'react';
import PropTypes from 'prop-types';

// The radius of the progress in pixels
const RADIUS = 20;

function calcRatio(value, min, max) {
  const clampedValue = Math.min(Math.max(min, value), max);
  return clampedValue / (max - min);
}

function getArcLength(ratio, props) {
  return ratio * 2 * Math.PI * RADIUS;
}

function getCircleStyle(props) {
  const { max, min, value } = props;

  return {
    strokeDasharray: `${getArcLength(calcRatio(value, min, max), props)}, ${getArcLength(1, props)}`
  };
}

class CircularProgress extends Component {
  static propTypes = {
    /**
     * The value of progress
     */
    value: PropTypes.number,

    /**
     * The max value of progress
     */
    max: PropTypes.number,

    /**
     * The min value of progress
     */
    min: PropTypes.number,

    /**
     * CSS class of the outer element
     */
    className: PropTypes.string,

    /**
     * Override the inline-styles of the outer element
     */
    style: PropTypes.object,

    /**
     * Stroke width in pixels
     */
    strokeWidth: PropTypes.number,

    /**
     * Text inside
     */
    percentVisible: PropTypes.bool,

  };

  static defaultProps = {
    value: 0,
    min: 0,
    max: 100,
    strokeWidth: 4,
    percentVisible: false
  };

  render() {
    const { style, strokeWidth, value, percentVisible } = this.props;
    const className = `crtx-circular-progress ${this.props.className || ''}`;
    const diameter = RADIUS * 2 + strokeWidth;
    const viewBox = `0 0 ${diameter} ${diameter}`;
    const circleStyle = getCircleStyle(this.props);

    return (
      <svg className={className} style={style} viewBox={viewBox}>
        <circle style={circleStyle}
          cx={diameter / 2}
          cy={diameter / 2}
          r={RADIUS}
          fill='none'
          strokeWidth={strokeWidth}
          strokeMiterlimit='20'
        />
        {percentVisible &&
          <text x="50%" y="50%" alignmentBaseline="middle" textAnchor="middle" fill="black" fontSize="12px" fontFamily="Verdana" dy=".3em">
            {value}
          </text>}
      </svg>
    );
  }
}

export default CircularProgress;
