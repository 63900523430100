import React, {useEffect, useRef, useImperativeHandle, forwardRef} from 'react';
import PropTypes from 'prop-types';

function TextInput({
  id,
  name,
  inputType = 'text',
  value = '',
  placeholder,
  title,
  disabled = false,
  size,
  maxLength,
  readOnly = false,
  autoFocus = false,
  autoTrim = true,
  className,
  style,
  onChange,
  onClick,
  onBlur,
  onFocus,
  onKeyDown,
  onKeyPress,
  onPaste
}, ref) {

  const inputRef = useRef(null);
  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus();
    },
    select: () => {
      inputRef.current.select();
    },
  }));

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, []);

  const handleChange = (event) => {
    if (!readOnly && typeof onChange === 'function') {
      onChange(event, event.target.value);
    }
  };

  const handlePaste = (event) => {
    if (!readOnly && typeof onPaste === 'function') {
      onPaste(event);
    }
  };


  const handleBlur = (event) => {
    const {value} = event.target;

    if (!readOnly && value && autoTrim && typeof onChange === 'function') {
      const trimmedValue = value.trim();
      if (value.length !== trimmedValue.length) {
        onChange(event, trimmedValue);
      }
    }

    if (typeof onBlur === 'function') {
      onBlur(event);
    }
  };

  const cssClass = `crtx-text-input ${className || ''} ${disabled ? 'disabled' : ''}`;

  return (
    <input
      ref={inputRef}
      id={id}
      name={name}
      type={inputType}
      value={value}
      placeholder={placeholder}
      title={title || value}
      disabled={disabled}
      size={size}
      maxLength={maxLength}
      readOnly={readOnly}
      className={cssClass}
      style={style}
      onChange={handleChange}
      onClick={onClick}
      onPaste={handlePaste}
      onBlur={handleBlur}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onKeyPress={onKeyPress}
    />
  );
}

const TextInputWithRef = forwardRef(TextInput);

TextInputWithRef.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  inputType: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  autoTrim: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyPress: PropTypes.func,
};

export default TextInputWithRef;
