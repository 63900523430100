import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import FlatButton from 'components/common/buttons/FlatButton';

class List extends Component {
  static propTypes = {
    items: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.any
    ]),
    style: PropTypes.object,
    itemGetter: PropTypes.func,
    newItemGetter: PropTypes.func,
    onRemove: PropTypes.func,
    onAdd: PropTypes.func,
  }
  static defaultProps = {
    items: [],
    itemGetter: () => { },
    newItemGetter: () => { },
  }

  constructor(props) {
    super(props);
    console.warn('List is deprecated, please move to ListV2');
  }

  handleRemoveItem = (index) => {
    return function () {
      this.props.onRemove(index);
    }.bind(this)
  };

  renderListItem = (item, index) => {
    const { itemGetter, onRemove } = this.props;
    return <tr className='crtx-list-item' key={index}>
      <td>
        <div className='crtx-list-item-getter'>
          {itemGetter(item, index)}
        </div>
      </td>
      {typeof onRemove === 'function' ? <td className='crtx-list-componenet-delete-icon'><FlatButton onClick={this.handleRemoveItem(index)}><i className='material-icons'>clear</i></FlatButton></td> : undefined}
    </tr>;
  };

  handleAddItem = () => {
    this.props.onAdd();
  };

  render() {
    const { items, style, newItemGetter, onAdd, addIcon } = this.props;
    const addItemIcon = typeof onAdd === 'function' ? <FlatButton onClick={this.handleAddItem}><i className='material-icons'>add</i></FlatButton> : undefined;
    const newItemGetterContent = newItemGetter !== undefined ? <div className='crtx-new-item'>
      {newItemGetter()}
      {addItemIcon}
    </div> : undefined;
    return <div className='crtx-list-component' style={style}>
      <table className='crtx-list-items' cellPadding="0" cellSpacing="0">
        <tbody>
          {items.map(this.renderListItem)}
        </tbody>
      </table>
      {newItemGetterContent}
    </div>;
  }
}

module.exports = List;