import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ColorCheckbox from 'components/common/inputs/ColorCheckbox';

export default class extends Component {
  static propTypes = {
    columnData: PropTypes.array,
    colors: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      rgb: PropTypes.string
    })),
    onChange: PropTypes.func
  };

  static defaultProps = {
    columnData: [],
    colors: [],
  };

  handleChange = (color, checked) => {
    const {colors, columnData, rowIndex, columnKey, onChange} = this.props;

    if (typeof onChange === 'function') {
      const selected = colors.filter(c => c.name === color.name ? checked : columnData.indexOf(c.name) >= 0)
        .map(c => c.name);

      onChange(rowIndex, columnKey, selected);
    }
  };

  renderColorCheckbox = (color, index) => {
    const {columnData} = this.props;

    const checked = columnData.indexOf(color.name) >= 0;

    return (
      <ColorCheckbox key={index}
                     checked={checked}
                     color={color.rgb}
                     title={color.name}
                     onChange={(e, checked) => this.handleChange(color, checked)}
      />
    );
  };

  render() {
    const {colors} = this.props;

    return (
      <div className='cell'>
        <div className='cell-content cell-colors'>
          {colors.map(this.renderColorCheckbox)}
        </div>
      </div>
    );
  }
}
