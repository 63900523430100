define(['./Guid'], function (guid) {

  var OPTIONS_GROUPS_CONNECTION = [
    {group: 'broadsheet', option: 'ruleLayoutGroupBroadSheet'},
    {group: 'tabs', option: 'ruleLayoutGroupTabloid'},
    {group: '2ups', option: 'ruleLayoutGroup2UP'},
    {group: '4ups', option: 'ruleLayoutGroup4UP'}
  ];

  var RULE_PROPS_NOT_TO_SAVE = [
    'guid'
  ];

  function getValuesToConvert () {

    return OPTIONS_GROUPS_CONNECTION;

  }

  function groupToOption (group) {

    let connectionIndex = getValuesToConvert().findIndex((connection) => {
        return connection.group === group;
      });

    return connectionIndex < 0 ? null :
      getValuesToConvert()[connectionIndex].option;

  }

  function optionHasConnection (option) {
    return OPTIONS_GROUPS_CONNECTION.findIndex((connection) => {
      return connection.option === option;
    }) >= 0;
  }

  function toViewModelOptions (layoutGroups, defaultText) {

    return layoutGroups.reduce(function (options, layoutGroup) {
      let option = groupToOption(layoutGroup.impositionType);

      if (option === null) return options;

      let arrOption = options[option] || [{text: defaultText || '---', value: '*'}];

      return {
        ...options,
        [option]: [
          ...arrOption,
          {text: layoutGroup.name, value: layoutGroup.name}
        ]
      };
    }, {});

  }

  function toViewModelRules (availableRules) {

    return {
      rules: availableRules.rules.map(function (rule) {
        return {
          ...rule,
          ...rule.values,
          guid: guid()
        };
      })
    };

  }

  function toViewModel (model) {

    return {
      options: toViewModelOptions(model.availableLayoutGroups || []),
      availableRules: toViewModelRules(model.availableRules || {rules: []})
    };

  }

  function shouldSaveProp (prop) {
    return RULE_PROPS_NOT_TO_SAVE.indexOf(prop) === -1;
  }

  function toDataRules (rules) {

    return rules.map(function (rule) {
      return Object.keys(rule).reduce(function (aggRule, ruleProp) {
        if (!shouldSaveProp(ruleProp)) return aggRule;
        if (optionHasConnection(ruleProp)) {
          let values = aggRule.values || {};
          return {
            ...aggRule,
            values: {
              ...values,
              [ruleProp]: rule[ruleProp]
            }
          };
        }

        return {
          ...aggRule,
          [ruleProp]: rule[ruleProp]
        };
      }, {});
    });

  }

  function toData (model) {

    return {
      rules: toDataRules(model.availableRules.rules)
    };

  }

  return {
    toViewModel,
    toData
  };

});