import PropTypes from 'prop-types';
import CanvasComponent from './canvasComponent';

export class CanvasImage extends CanvasComponent {
  static propTypes = {
    image: PropTypes.any,
    point: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    })
  };

  static defaultProps = {
    point: {
      x: 0,
      y: 0
    }
  };

  draw = (ctx) => {
    const { image } = this.props;

    ctx.save();

    // move the center of the image to the center of coordinate system
    ctx.translate((-image.width / 2), (-image.height / 2));

    ctx.drawImage(image, 0, 0);

    ctx.restore();

  };
}