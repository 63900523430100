import React from 'react';
import { createRoot } from 'react-dom/client';
import AbstractModule from 'AbstractModule';
import pubsub from 'core/managers/pubsub';
import base from 'base';
import settingsManager from 'core/managers/settings';
import { createStore } from 'redux';
import reducer from './reducer';
import LoginUserName from './LoginUserName';
import LoginUserNameWithLabel from './LoginUserNameWithLabel';
import LoginPassword from './LoginPassword';
import LoginPasswordWithLabel from './LoginPasswordWithLabel';
import Footer from './Footer';
import LoginButton from './LoginButton';
import LoginWithLink from './LoginWithLink';
import LoginPoliciesLinks from './LoginPoliciesLinks';
import SiteName from './SiteName';
import ServerName from './ServerName';
import Version from './Version';
import Language from './Language';
import CurrentYear from './CurrentYear';
import { restGet } from 'core/managers/rest2';


const loginArkitexComponents = [
  { id: 'login-user-name', component: LoginUserName },
  { id: 'login-user-name-with-label', component: LoginUserNameWithLabel },
  { id: 'login-password', component: LoginPassword },
  { id: 'login-password-with-label', component: LoginPasswordWithLabel },
  { id: 'login-with-link', component: LoginWithLink },
  { id: 'login-policies-links', component: LoginPoliciesLinks },
  { id: 'login-button', component: LoginButton },
  { id: 'site-name', component: SiteName },
  { id: 'server-name', component: ServerName },
  { id: 'version', component: Version },
  { id: 'language', component: Language },
  { id: 'current-year', component: CurrentYear }
];

const loginNewswayComponents = [
  { id: 'login-user-name', component: LoginUserName },
  { id: 'login-user-name-with-label', component: LoginUserNameWithLabel },
  { id: 'login-password', component: LoginPassword },
  { id: 'login-password-with-label', component: LoginPasswordWithLabel },
  { id: 'login-button', component: LoginButton },
  { id: 'login-with-link', component: LoginWithLink },
  { id: 'site-name', component: SiteName },
  { id: 'server-name', component: ServerName },
  { id: 'current-year', component: CurrentYear }
];


export default AbstractModule.extend({

  init: function () {
    this.store = undefined;
    this.render = this.render.bind(this);
  },

  initDone: function () {
    this.store = createStore(reducer);
    this.store.subscribe(this.render);
    this.reactRootsById = {};

    this.element = 'auth';
    this.componentElement = document.getElementById(this.element);

    Promise.all([
      restGet('', 'global/localization/login-template'),
      restGet('', 'global/localization/login-links')
    ])
      .then(([template = '', policiesLinks = {}]) => {
        this.renderLoginTemplate(template);

        this.store.dispatch({
          type: 'ADD_POLICIES',
          value: policiesLinks.policy || []
        });

        pubsub.subscribe('language-changed', function () {
          this.render();
        }.bind(this));
      });
  },

  getFooterStyle: function (bgColor) {

    const footerStyle = {
      position: 'absolute !important',
      bottom: '0 !important',
      left: '0 !important',
      width: '100% !important',
      display: 'flex !important',
      'flex-direction': 'column !important'
    };

    footerStyle['background-color'] = `${bgColor} !important`;

    const footerStyleString = Object.entries(footerStyle).map(([key, value]) => [key, value].join(': ')).join('; ');

    return footerStyleString;
  },

  getContrastColor: function (hex) {

    if (hex.indexOf('#') === 0 && (hex.length === 4 || hex.length === 7)) {
      hex = hex.slice(1);
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      let r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);

      return (r * 0.299 + g * 0.587 + b * 0.114) > 186
        ? '#343a46'
        : '#FFFFFF';

    } else {
      throw new Error('Invalid background color, must be in HEX format (example: #fff or #ffffff).');
    }

  },

  renderLoginTemplate: function (template) {
    base.dom.html('#' + this.element, template);
    if (settingsManager.get('productSkinName') === 'newsway') {

      let elementsWithAttr = base.dom.find('[data-footer-background-color]', '#auth');
      let bgColor = base.dom.data(elementsWithAttr[0], 'footer-background-color') || '#fff';

      this.footerColor = this.getContrastColor(bgColor);
      base.dom.append('#' + this.element, `<div id='login-footer' class='nw_login-footer' style='${this.getFooterStyle(bgColor)}'></div>`);

    }

    base.dom.show('#' + this.element);
    //When the user press Login or Enter the form is refreshing the page. In order to prevent that the submit is disabled.    
    base.dom.find('form', this.componentElement).submit(() => {
      return false;
    });

    this.render();
  },

  renderLoginComponent: function (loginComponent) {
    const contentDomElement = document.getElementById(loginComponent.id);
    if (contentDomElement) {
      if (!this.reactRootsById[loginComponent.id]) {
        this.reactRootsById[loginComponent.id] = createRoot(contentDomElement);
      }
      this.reactRootsById[loginComponent.id].render(<loginComponent.component store={this.store} />);
    }
  },

  renderFooter: function () {
    const footerElement = document.getElementById('login-footer');

    if (footerElement) {
      if (!this.footerRoot) {
        this.footerRoot = createRoot(footerElement);
      }
      this.footerRoot.render(<Footer store={this.store} color={this.footerColor} />);
    }
  },

  render: function () {
    if (settingsManager.get('productSkinName') === 'newsway') {
      loginNewswayComponents.forEach(this.renderLoginComponent.bind(this));
      this.renderFooter();
    } else {
      loginArkitexComponents.forEach(this.renderLoginComponent.bind(this));
    }
  },

  destroy: function () {
    this._super();
    base.dom.empty('#' + this.element);
  }

});
