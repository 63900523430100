import React, { Component } from 'react';
import PropTypes from 'prop-types';

const sumVlaues = (values) => {
  return values.reduce((sum, value) => sum + value.value, 0);
};

class MultiProgressBars extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    values: PropTypes.arrayOf(PropTypes.shape({
      color: PropTypes.string,
      value: PropTypes.number
    })),
    style: PropTypes.object,
    className: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func
  }

  static defaultProps = {
    disabled: false,
    min: 0,
    max: 100,
    values: []
  }

  constructor(props) {
    super(props);
  }

  renderBar = (value, index) => {
    return <div key={index} className={`crtx-multiprogress-bar-progress`} style={{ backgroundColor: value.color, flex: value.value }}></div>;
  };

  renderBars = (values, max, min) => {
    return values.concat({ color: 'none', value: (max - min - sumVlaues(values)) }).map(this.renderBar);
  };

  render() {
    const { disabled, min, max, values, style, className, title, children, onClick } = this.props;
    const disabledClassName = disabled ? 'disabled' : '';
    let sumValues = 0;

    return <div onClick={onClick} className={`crtx-multiprogressbars ${className} ${disabledClassName}`} style={style} title={title}>
      {this.renderBars(values, max, min)}
      <div className='crtx-multiprogressbars-text'>
        {children}
      </div>
    </div>
  }
}

export default MultiProgressBars;