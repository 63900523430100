import utils from './utils';

export default function reducerCreator(customReducer) {

  return function reducers(state = {}, action) {

    if (utils.isFunction(customReducer)) {
      let returnedState = customReducer(state, action);
      if (!utils.isUndefined(returnedState) && state !== returnedState) {
        return returnedState;
      }
    }

    switch (action.type) {

      case 'UPDATE':
        if (action.immutable) {
          return utils.update(state, action.path || action.bind, action.value);
        }
        else {
          utils.set(state, [action.path], action.value);
          return state;
        }

      case 'ADD':
        if (action.immutable) {
          return utils.add(state, action.path || action.bind, action.index, action.value);
        }
        else {
          let path = action.path;
          let item = action.item;
          let index = action.index;
          let boundedObj = utils.get(state, path);

          if (Array.isArray(boundedObj)) {
            if (utils.isNumber(index)) {
              boundedObj.splice(index, 0, item);
            }
            else {
              boundedObj.push(item)
            }
          }

          if (utils.isObject(boundedObj) && !Array.isArray(boundedObj)) {
            utils.set(state, [path], value);
          }
          return state;
        }

      case 'REMOVE':
        if (action.immutable) {
          return utils.remove(state, action.path || action.bind, action.index);
        }
        else {
          let path = action.path;
          let index = action.index;
          let boundedObj = utils.get(state, path);

          if (Array.isArray(boundedObj)) {
            boundedObj.splice(index, 1);
          }
          return state;
        }

      default:
        return state;

    }
  };
};
