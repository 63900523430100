import $ from 'jquery';
import AbstractStep from 'widgets/Wizard/AbstractStep';
import modelUtils from './model/model';
import PreStepModel from './model/preStepModel';
import wizardUtils from './../../utils/wizardUtils';
import { restPost } from 'core/managers/rest2';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { toJS } from 'mobx';
import { Store, Form } from 'components/private/forms';
import Zone from './components/Zone';
import { BOOKS_MODE } from './components/Book';

function init(initObj) {
  this._super(initObj);

  this.rootType = initObj.startParameters && initObj.startParameters.rootType ?
    initObj.startParameters.rootType : initObj.properties.properties.startParameters.rootType;
  this.rootId = initObj.startParameters && initObj.startParameters.rootId ?
    initObj.startParameters.rootId : initObj.properties.properties.startParameters.rootId;
  this.nwid = initObj.startParameters && initObj.startParameters.nwid ?
    initObj.startParameters.nwid : initObj.properties.properties.startParameters.nwid;
  this.wizard = initObj.wizard;
  var stepsProperties = initObj.wizard.initObj.stepsProperties;
  this.settings = stepsProperties != null && stepsProperties.steps.books != null ? stepsProperties.steps.books : this.properties.properties;
  this.reactRootsById = {};
}

function back() {
  if (!validateZone(this)) {
    return;
  }
  
  let updatedModel = toJS(this.store.getState());

  let isValid = modelUtils.convertFromStepModel(this.model, updatedModel, this.settings);

  if (isValid){
    this.destroy();
  }
  return isValid;
}

function next() {

  if (!validateZone(this)) {
    return;
  }

  
  let updatedModel = toJS(this.store.getState());

  let isValid = modelUtils.convertFromStepModel(this.model, updatedModel, this.settings);
  if (isValid){
    this.destroy();
  }
  return isValid;
  
};

function validateZone(scope) {
  let activeZoneIndex = scope.tabStrip.select().index();

  return scope.mapFormByZone[activeZoneIndex].validate();
}

function destroy() {
  console.log("destroy");
  Object.values(this.reactRootsById).forEach(root => {
    root.unmount();
  });
  this.reactRootsById = {};
}

function buildModel(stepModel) {

  updateZoneConfiguration(stepModel);

  stepModel.editions[0].zones.forEach((zone, zoneIndex) => {

    zone.onApplyClick = (zoneModel) => {
      updateStepModel(stepModel, zoneModel, zoneIndex);
      modelUtils.applyAll(toJS(zoneModel), stepModel);
      this.renderStepModel(stepModel);
    };

    zone.onPmiClick = (book, bookIndex, zoneUpdated) => {
      updateZone(zoneUpdated, book, bookIndex);
      updateStepModel(stepModel, zoneUpdated, zoneIndex);
      updateZoneConfiguration(stepModel);

      const reactRootId = composeModuleContainerId(zone.displayName, zoneIndex);
      const newRender = (stepModel) => {
        this.renderStepModel(stepModel);
      };

      modelUtils.editBookPmi(toJS(book), bookIndex, stepModel, this.reactRootsById[reactRootId], zone, zoneIndex, newRender);
    };

    zone.updateZoneInStepModel = (zoneModel, shouldValidate) => {
      if (zoneModel.booksMode === BOOKS_MODE.saddleStitch) {
        if (!zoneModel.saddleStitchLastModel || !zoneModel.modeWasChanged) {
          modelUtils.getSaddleStitchModel(zoneModel);
        }
      } else if (zoneModel.booksMode === BOOKS_MODE.perfectBinding && zoneModel.isFirstPerfectBindingTransform) {
        modelUtils.getPerfectBindingModel(zoneModel);
      }
      zoneModel.modeWasChanged = false;
      if (shouldValidate) {
        validateZone(this);
      }
    };

    zone.handleSingleSectionNumPagesChange = (book) => {
      let numPages = 0;
      book.sections.forEach(function (bookSection) {
        var range = bookSection.range;
        if (range === null || typeof range === 'undefined' || range.length === 0) {
          return;
        }
        var params = range.split(";");
        for (var p = 0; p < params.length; p++) {
          var rangeValue = params[p];
          if (rangeValue.indexOf("-") === -1) {
            numPages++;
          } else {
            var rangeValues = rangeValue.split("-");
            var first = parseInt(rangeValues[0], 10);
            var last = parseInt(rangeValues[1], 10);
            for (var n = first; n <= last; n++) {
              numPages++;
            }
          }
        }
      });


      book.numPages = numPages;
    };

  });
}

function composeModuleContainerId(moduleName, index) {
  return moduleName + '-' + index;
}

function createContainer(z, index) {
  let containerId = composeModuleContainerId(z.displayName, index);
  let attributeHash = { id: containerId, class: 'crtx-wizard-step-tab-container' };
  let attributes = '';

  for (let name in attributeHash) {
    attributes += ` ${name}="${attributeHash[name]}"`;
  }

  return `<div${attributes}></div>`;
}

function getZoneContainer(z, index) {

  return document.getElementById(composeModuleContainerId(z.displayName, index));
}

function updateStepModel(stepModel, zone, index) {
  stepModel.editions[0].zones[index] = toJS(zone);
}

function updateZoneConfiguration(stepModel) {
  const configuration = {
    groups: stepModel.groups,
    pmiTemplates: stepModel.pmiTemplates,
    paperTypeOptions: stepModel.paperTypeOptions,
    customFields: stepModel.customFields,
    optionalImpositions: stepModel.optionalImpositions
  };

  stepModel.editions[0].zones.forEach((zone) => {
    zone.configuration = configuration;
  });
}

function updateZone(zone, book, index) {
  zone.books[index] = toJS(book);
}

function renderStepModel(stepModel) {

  this.store = new Store(stepModel);

  const FormHoc = Form(Zone);
  this.mapFormByZone = [];

  stepModel.editions[0].zones.forEach((z, index) => {
    let zoneContainer = getZoneContainer(z, index);
    const reactRootId = composeModuleContainerId(z.displayName, index);
    if (!this.reactRootsById[reactRootId]) {
      this.reactRootsById[reactRootId] = createRoot(zoneContainer);
    }
    this.reactRootsById[reactRootId].render(
      <FormHoc
        ref={formRef => {
          this.mapFormByZone[index] = formRef;
        }}
        path={`editions[0].zones[${index}]`}
        store={this.store} />);

  });
}

function render() {

  this.wizard.disableButton("cancel");

  let mappedModel = wizardUtils.mapSourceIdToObject(this.model);

  PreStepModel.validModel(this.model, mappedModel, this.defaultsLayouts);

  let stepModel = modelUtils.convertToStepModel(this.model, this.settings, this.rootId, this.nwid);

  $(this.container).empty();
  this.tabStrip = $(this.container).kendoTabStrip().data('kendoTabStrip');

  stepModel.editions[0].zones.forEach((z, index) => {
    this.tabStrip.append({
      text: z.displayName,
      content: createContainer(z, index)
    });
  });

  this.tabStrip.select(this.tabStrip.tabGroup.children("li").eq('0'));

  // let that = this;
  this.buildModel(stepModel);
  this.renderStepModel(stepModel);
}


function load() {
  console.log("load.................");

  let restParams = { name: this.model.publication.displayName, date: this.model.publication.issueDate };
  let editions = [];
  this.model.publication.editions.forEach(function (e) {
    let edition = { name: e.displayName };
    let zones = [];
    e.zones.forEach(function (z) {
      let zone = { name: z.displayName };
      let sections = [];
      z.sections.forEach(function (s) {
        let section = { name: s.displayName };
        sections.push(section);
      });
      zone.sections = sections;
      zones.push(zone);
    });
    edition.zones = zones;
    editions.push(edition);
  });
  restParams.editions = editions;

  return restPost(this.nwid, `folders/${this.rootId}/planning-actions/getDefaultLayout`, restParams)
    .then((result) => {
      this.defaultsLayouts = result;
    });
}

module.exports = AbstractStep.extend({
  init,
  render,
  back,
  next,
  destroy,
  load,
  buildModel,
  renderStepModel
}, "BooksReact");
