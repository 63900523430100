import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useBatchAddListener,
  useBatchCancelledListener,
  useItemFinalizeListener,
  useItemFinishListener,
  useItemProgressListener,
  useItemStartListener,
  useRequestPreSend
} from '@rpldy/uploady';
import UploadDropZone from '@rpldy/upload-drop-zone';

export const UploadDropZoneComponent = (
  {
    target,
    targetId,
    className,
    style,
    dragLeaveHandler,
    finalizeHandler,
    itemStartHandler,
    itemProgressHandler,
    fileDropHandler,
    permissionToUpload,
    batchCancelledHandler,
    itemFinishHandler,
  }) => {

  const [batchIdState, setBatchIdState] = useState('');

  useBatchAddListener((batch, options) => {
    if (batch.items.length !== 1) {
      return false;
    }
    if (options.params.targetId === targetId) {
      setBatchIdState(batch.id);
    }
  });

  useBatchCancelledListener(() => {
    if (typeof batchCancelledHandler === 'function') {
      batchCancelledHandler();
    }
  });

  useRequestPreSend(({ items, options }) => {
    if (options.params.targetId !== targetId) {
      return Promise.resolve(false);
    }
    const customParams = { ...options.params.customParams, ...target };
    const item = items[0];
    return {
      options: {
        params: {
          customParams: JSON.stringify(customParams),
          qqfilename: item.file.name,
          qqtotalfilesize: item.file.size,
          qquuid: item.id
        }
      }
    };
  });

  useItemStartListener((item, options) => {
    if (item.file.type !== 'application/pdf' || !permissionToUpload) {
      return false;
    }
    if (typeof itemStartHandler === 'function' && options.params.targetId === targetId && item.batchId === batchIdState) {
      itemStartHandler();
    }
  });

  useItemProgressListener(item => {
    if (typeof itemProgressHandler === 'function' && item.batchId === batchIdState) {
      itemProgressHandler(item);
    }
  });

  useItemFinishListener((item, options) => {
    if (typeof itemFinishHandler === 'function' && options.params.targetId === targetId && item.batchId === batchIdState) {
      itemFinishHandler(item);
    }
  });

  useItemFinalizeListener(() => {
    if (typeof finalizeHandler === 'function') {
      finalizeHandler();
      setBatchIdState('');
    }
  });

  const handleFileDrop = (e, getFiles) => {
    if (typeof fileDropHandler === 'function') {
      fileDropHandler();
    }
    return getFiles();
  };

  return <UploadDropZone className={className}
    dropHandler={handleFileDrop}
    params={{ targetId }}
    extraProps={{ onDragLeave: dragLeaveHandler, style }} />;
};

UploadDropZoneComponent.propTypes = {
  target: PropTypes.object,
  targetId: PropTypes.string,
  style: PropTypes.object,
  dragLeaveHandler: PropTypes.func,
  fileDropHandler: PropTypes.func,
  finalizeHandler: PropTypes.func,
  itemStartHandler: PropTypes.func,
  itemProgressHandler: PropTypes.func,
  className: PropTypes.string,
  permissionToUpload: PropTypes.bool,
  batchCancelledHandler: PropTypes.func,
  itemFinishHandler: PropTypes.func,
};