import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DOMMouseMoveHandler from './dom/DOMMouseMoveHandler';

export default class extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    headerHeight: PropTypes.number,
    headerFontSize: PropTypes.number,
    columnKey: PropTypes.any.isRequired,
    width: PropTypes.any,
    flex: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    resizable: PropTypes.bool,
    title: PropTypes.string,
    tableHeight: PropTypes.number.isRequired,
    resizableColumns: PropTypes.bool.isRequired,
    onResize: PropTypes.func,
    onResizeEnd: PropTypes.func,
    onResizeDoubleClick: PropTypes.func,
    onClick: PropTypes.func,
    sortOrder: PropTypes.string,
    HeaderComponent: PropTypes.any,
    win: PropTypes.object
  };

  static defaultProps = {
    width: 100,
    onResize: function () { },
    onResizeDoubleClick: function () { },
    onClick: function () { }
  };

  state = {
    resizing: false,
    startResizeWidth: null
  };

  columnResizeHandler = null;

  onResizeHandlerMouseDown = (ev) => {
    this.setState({
      resizing: true,
      startResizeWidth: this.props.width
    });
    this.columnResizeHandler.captureMouseMove(ev);
    ev.stopPropagation();
  };

  onResizeHandlerMouseMove = (currentX, currentY, startX, startY) => {
    var newWidth = Math.max(this.state.startResizeWidth + (currentX - startX), 1);
    this.props.onResize(this.props.index, this.props.columnKey, newWidth);
  };

  onResizeHandlerMouseUp = (currentX, currentY, startX, startY) => {
    var newWidth = Math.max(this.state.startResizeWidth + (currentX - startX), 1);
    this.columnResizeHandler.releaseMouseMove();
    this.props.onResize(this.props.index, this.props.columnKey, newWidth);
    this.props.onResizeEnd(this.props.index, this.props.columnKey, newWidth);
    this.setState({
      resizing: false,
      startResizeWidth: null
    });
  };

  onResizeHandlerDoubleClick = (ev) => {
    this.props.onResizeDoubleClick(this.props.index, this.props.columnKey);
  };

  onColumnClick = (ev) => {
    if (ev.target === this.refs.resizeHandler) return;
    this.props.onClick(this.props.index, this.props.columnKey, ev);
    ev.stopPropagation();
  };

  componentDidMount() {
    const { win } = this.props;

    this.columnResizeHandler = new DOMMouseMoveHandler(
      this.onResizeHandlerMouseMove,
      this.onResizeHandlerMouseUp,
      win
    );
  }

  componentWillUnMount = () => {
    this.columnResizeHandler = null;
  };

  headerComponent = (
    index,
    columnKey,
    headerHeight,
    headerFontSize,
    width,
    title,
    props,
    children,
  ) => {

    if (children === undefined || children === null) return null;

    if (typeof children === 'function') {
      let ColumnHeaderComponent = children;
      return <ColumnHeaderComponent headerHeight={headerHeight}
        headerFontSize={headerFontSize}
        columnIndex={index}
        columnKey={columnKey}
        width={width}
        title={title}
        {...props} />;
    }

    return React.cloneElement(children, {
      headerHeight: headerHeight,
      headerFontSize: headerFontSize,
      columnIndex: index,
      columnKey: columnKey,
      width: width,
      title: title,
      ...props
    });
  };

  render() {
    var {
      index,
      headerHeight,
      headerFontSize,
      columnKey,
      width,
      //flex,
      resizable,
      title,
      tableHeight,
      resizableColumns,
      children,
      ...props
    } = this.props;
    var { resizing } = this.state;
    var style = { width: width };//, height: headerHeight };
    //var contentStyle = {height: headerHeight, lineHeight: headerHeight + 'px'};
    var contentStyle = {};//height: headerHeight };
    var knobStyle = { height: tableHeight };
    var resizeHandlerClassName = resizing === true ? 'react-data-grid-column-resizeHandler resizing' : 'react-data-grid-column-resizeHandler';
    var resizeHandlerStyle = { display: resizableColumns === true && resizable === true ? '' : 'none' };
    var columnHeaderComponent = this.headerComponent(index, columnKey, headerHeight, headerFontSize, width, title, props, children);


    return <div className="react-data-grid-column" style={style} data-columnkey={columnKey} >

      <div className="react-data-grid-column-content" style={contentStyle} onClick={this.onColumnClick}>
        {columnHeaderComponent}
      </div>

      <div ref="resizeHandler"
        className={resizeHandlerClassName}
        style={resizeHandlerStyle}
        onMouseDown={this.onResizeHandlerMouseDown}
        onDoubleClick={this.onResizeHandlerDoubleClick}>
        <div className="react-data-grid-column-resizeHandler-knob" style={knobStyle}></div>
      </div>

    </div>;
  }
}