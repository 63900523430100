// @flow

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SimpleFormComponent from '../SimpleFormComponent';
import utils from '../utils';
import actions from '../actions';

export default SimpleFormComponent(class extends Component {

  static propTypes = {
    store: PropTypes.object,
    bind: PropTypes.string,
    placeholder: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    visible: PropTypes.bool,
    col: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onKeyDown: PropTypes.func,
    onChange: PropTypes.func,
    liveUpdate: PropTypes.bool,
    actionType: PropTypes.string,
    ignoreNewLine: PropTypes.bool
  };

  static defaultProps = {
    bind: '',
    liveUpdate: false,
    actionType: 'UPDATE'
  };

  constructor(props, context) {
    super(props, context);
    const storeValue = this.getStoreValue(props);

    this.state = {
      storeValue
    };
  }

  getStoreValue = (props) => {
    const { store, bind } = props;

    return store.get(bind);
  };

  dispatch = (event, storeValue) => {
    const { store, bind, onChange, actionType } = this.props;

    if (utils.isFunction(onChange)) {
      onChange(event, bind, storeValue);
    } else {
      store.dispatch(actions.update(bind, storeValue, actionType));
    }
  };

  handleKeyDown = (event) => {
    const { onKeyDown, ignoreNewLine } = this.props;
    const { storeValue } = this.state;

    if (utils.isFunction(onKeyDown)) {
      return onKeyDown(event);
    }

    if (event.key === 'Enter') {
      if (ignoreNewLine) {
        event.stopPropagation();
        event.preventDefault();
      }

      this.dispatch(event, storeValue);
    }
  };

  handleChange = (event) => {
    const { liveUpdate, ignoreNewLine } = this.props;
    var storeValue = event.target.value;

    if (ignoreNewLine) {
      storeValue = utils.replaceLineBreaks(storeValue, '');
    }

    this.setState({
      storeValue
    });

    if (liveUpdate) {
      this.dispatch(event, storeValue);
    }
  };

  handleBlur = (event) => {
    const { storeValue } = this.state;
    this.dispatch(event, storeValue);
  };

  componentWillReceiveProps(nextProps) {
    const { storeValue } = this.state;
    const newStoreValue = this.getStoreValue(nextProps);

    if (storeValue !== newStoreValue) {
      this.setState({
        storeValue: newStoreValue
      });
    }
  }

  render() {
    const { store, bind, style, visible, className, col, offset, flex, onChange, liveUpdate, actionType, ...props } = this.props;
    const storeValue = this.state.storeValue;

    return <textarea value={storeValue}
      className={utils.combineClassNames(['crtx-simpleform-textarea', className])}
      style={style}
      onKeyDown={this.handleKeyDown}
      onChange={this.handleChange}
      onBlur={this.handleBlur}
      {...props}
    >
    </textarea>;
  }
});