/**
 * Created by moshemal on 9/4/14.
 */

define(['base/jsUtils'],
  function (jsutils) {
    'use strict';

    const MAX_ORDER = 10000;

    return {

      /**
       * Create a map of models from hierarchical
       * model received from the server. This map
       * is used for updates which contain the ids
       * of updated model
       * @param {Object || Array} model
       * @param {Object} acc
       * @returns {Object}
       */
      flattenModel: function (model, acc) {
        if (jsutils.isObject(model)) {
          acc[model.id] = model;
        }
        for (var i in model) {
          if (Array.isArray(model[i]) || jsutils.isObject(model[i])) {
            this.flattenModel(model[i], acc);
          }
        }
        return acc;
      },

      /**
       * Creates map of objects by their nwid property from the hierarchical model received from the server.
       * Adds reference to the parent object via parentNwid property if it not exists.
       * @param {object | array} model The hierarchical model
       * @param {string} [parentNwid = ''] The optional parent's nwid property
       * @param {object} [acc = {}] The optional flatten model
       * @returns {object} The flatten model
       */
      flattenModelByNwid: function (model, parentNwid = '', acc = {}) {
        if (!model || typeof model !== 'object') {
          return acc;
        }

        if (model.nwid) {
          acc[model.nwid] = model;
          if (parentNwid && !model.parentNwid) {
            model.parentNwid = parentNwid;
          }
        }

        parentNwid = model.nwid || parentNwid;
        for (let key in model) {
          this.flattenModelByNwid(model[key], parentNwid, acc);
        }

        return acc;
      },

      /**
       * Finds ancestors of the object with the given nwid using the given map of objects.
       * @param {object} objMap The flatten model of objects by nwid
       * @param {string} nwid The nwid of the object in question
       * @param {array} [ancestors = []] The optional array of ancestors
       * @returns {array} The array of ancestors: [object with the given nwid, its parent, its parent of parent, ...]
       */
      findAncestorsByNwid: function (objMap, nwid, ancestors = []) {
        const obj = objMap[nwid];
        if (!obj || typeof obj !== 'object') {
          return ancestors;
        }

        ancestors.push(obj);

        if (obj.parentNwid) {
          this.findAncestorsByNwid(objMap, obj.parentNwid, ancestors);
        }

        return ancestors;
      },

      /**
       * Composes full name of the object with the given nwid using the given map of objects.
       * @param {object} objMap The flatten model of objects by nwid
       * @param {string} nwid The nwid of the object in question
       * @returns {string} The full object name: root name/.../name of the object with the given nwid
       */
      composeFullName: function (objMap, nwid) {
        return this.findAncestorsByNwid(objMap, nwid).reverse().map(obj => obj.name).join('/');
      },

      /**
       * Sort actions or views by order and label properties, e.g.
       * sorted order: 0, 0, 0, 5, 7, 10, NaN, -1, -2, -5
       *
       * @param {Array} items List of items to sort
       * @returns {Array} Sorted array
       */
      sortByOrderAndLabel: function (items) {
        items.forEach(item => {
          const order = isNaN(item.order) ? NaN : parseInt(item.order);
          item.ord = order >= 0 ? order : order < 0 ? MAX_ORDER - order : MAX_ORDER;
        });

        return items.sort((a, b) => a.ord !== b.ord ? a.ord - b.ord : a.label.localeCompare(b.label));
      }


    };
  });
