/**
 * Created with JetBrains WebStorm.
 * User: elad
 * Date: 3/9/14
 * Time: 10:07 AM
 * To change this template use File | Settings | File Templates.
 */
define(['sandbox', '../server/OpenModuleAction'], function (sandbox, OpenModuleAction) {
  'use strict';

  return OpenModuleAction.extend({

    isApplicable: function () {
      var objects = this.module.selected;
      if (typeof objects === 'undefined' || objects.length !==1) {
        return false;
      }
      var siteType = objects[0].siteType;
      if (typeof siteType === "undefined" || siteType.toUpperCase() !== "EXTERNAL") {
        return false;
      }
      return true;
    }
  });

});