import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';
import iconService from 'core/services/iconService';
import StyleConverter from '../styleConverter';
import pageStyleMap from '../models/pagestylemap';
import segmentStyleMap from '../models/segmentStyleMap';
import BirdeyeElement from './birdeyeelement';
import SelectedElement from './selectedElement';
import ClickableElement from './clickableElement';
import AlignmentPoint from './alignmentPoint';
import DroppableArea from './droppableArea';
import toastService from 'core/services/toastService';
import pubsub from 'core/managers/pubsub';
import { classNames } from 'utilities/classNames';
import { UploadButton, UploadDropZone } from 'components/private/upload';
import ProgressBar from 'components/common/progress/ProgressBar';
import IndeterminateProgressBar from 'components/common/progress/IndeterminateProgressBar';

const translate = sandbox.localization.translate;
const pageStyleConverter = new StyleConverter(pageStyleMap);
const segmentStyleConverter = new StyleConverter(segmentStyleMap);

/**
 * Upload flags description:
 * 
 * hideUploadButton => Toolbar button Flag to show or hide the upload button on mouse over the element
 * uploadButtonVisible => A state flag that indicates whether the upload button is rendered or not
 * dropAreaVisible => A state flag that indicates whether the upload drop zone (not the original (unplanned) drop zone) is rendered or not
 * progressBarVisible => A state flag that indicates an upload process has began and to show a regular progress bar
 * progressFinishedTime => A state flag that indicates an upload process has ended and to replace the regular progress bar with the indeterminate progress bar
 * itemProgress => A state flag that indicates the uploaded file's progress (used by the regular progress bar)
 */

export default class Page extends Component {
  static contextTypes = {
    controller: PropTypes.object,
    module: PropTypes.object
  };

  static defaultProps = {
    page: undefined,
    pageSize: undefined,
    gridAlign: undefined,
    pageDefinition: undefined,
    alignmentPoints: undefined,
    onClick: undefined,
    onContextMenu: undefined,
    onDoubleClick: undefined
  };

  state = {
    uploadButtonVisible: false,
    dropAreaVisible: false,
    progressBarVisible: false,
    progressFinishedTime: undefined,
    itemProgress: 0,
  };

  itemTotalSize = 0;

  getGeneralProperties = (pageDefinition) => {
    return pageDefinition.generalProperties || {};
  };

  getPageViewportDefinition = (pageDefinition, page) => {
    var isPanorama = page.pageContent.isPanorama,
      pageFormat = page.pageContent.format,
      defaultViewport = typeof pageDefinition.single === 'undefined' ? pageDefinition : pageDefinition.single;

    if (isPanorama) {
      if (pageFormat === 'Tabloid') {
        return typeof pageDefinition['double-vertical'] === 'undefined' ? defaultViewport : pageDefinition['double-vertical'];
      }
      return typeof pageDefinition['double'] === 'undefined' ? defaultViewport : pageDefinition['double'];
    }

    return defaultViewport;
  };

  getPageStyleDefinition = (page, generalProperties, controller) => {
    var styleHelperFunctions = controller.styleHelperFunctions || {};

    return pageStyleConverter.toStyle(generalProperties, page, styleHelperFunctions, controller);
  };

  getPageContentStyle = (size, align, ignore, styleDefinition) => {
    var style = styleDefinition;
    style.width = size[0];
    style.height = size[1];
    style.float = align;
    style.backgroundImage = ignore ? 'url(' + iconService.getModuleIcon('MyBirdeye', 'ignore_l') + ')' : null;
    style.backgroundPosition = ignore ? '50% 50%' : null;
    style.backgroundRepeat = ignore ? 'no-repeat' : null;

    return style;
  };

  getPreflightIndicationStyle = (size, align) => ({ width: size[0], height: size[1], float: align });

  getElements = (alignmentPointDefinition, pageContentStyle, page, segmentStyle) => {
    return alignmentPointDefinition.elements.map(function (elementDefinition, elementIndex) {
      return <BirdeyeElement key={[page.id, alignmentPointDefinition.location, elementIndex].join('.')}
        point={alignmentPointDefinition}
        model={page}
        definition={elementDefinition}
        parentsStyle={[segmentStyle, pageContentStyle]}>
      </BirdeyeElement>;
    });
  };

  getAlignedElements = (
    alignmentPointDefinition,
    pageContentStyle,
    page,
    segmentLocation,
    segmentStyle,
  ) => {
    var elements = this.getElements(alignmentPointDefinition, pageContentStyle, page, segmentStyle);

    return <AlignmentPoint key={[page.id, alignmentPointDefinition.location].join('.')} style={segmentStyle} location={segmentLocation} definition={alignmentPointDefinition}>
      {elements}
    </AlignmentPoint>;
  };

  getSegments = (pageViewportDefinition, alignmentPoints, page, pageContentStyle) => {
    var that = this,
      alignedSegments = [],
      notAlignedSegments = {};

    if (typeof pageViewportDefinition.segments === 'undefined') {
      return {
        alignedSegments,
        notAlignedSegments
      };
    }

    pageViewportDefinition.segments.forEach(function (segmentDefinition) {
      var segment = {},
        segmentLocation = segmentDefinition.location,
        alignmentPointDefinition = Object.assign({}, alignmentPoints[segmentLocation], segmentDefinition),
        alignmentPoint,
        segmentStyle = segmentStyleConverter.toStyle(alignmentPointDefinition, page);

      if (typeof alignmentPoints[segmentLocation] !== 'undefined') {
        alignmentPoint = that.getAlignedElements(alignmentPointDefinition, pageContentStyle, page, segmentLocation, segmentStyle);
        alignedSegments.push(alignmentPoint);
      }
      else {
        alignmentPoint = that.getElements(alignmentPointDefinition, pageContentStyle, page, {});
        notAlignedSegments[segmentLocation] = alignmentPoint;
      }
    });

    return {
      alignedSegments,
      notAlignedSegments
    };
  };

  onDrop = (ev) => {

    ev.preventDefault();
    const { page } = this.props;
    if (page.aggregated) {
      return;
    }
    const unplannedPages = JSON.parse(ev.dataTransfer.getData('Text'));
    if (unplannedPages[0].publicationName && unplannedPages[0].publicationName.toLowerCase() !== page.publication.toLowerCase()) {
      sandbox.dialog.alert(translate('The source and target publications do not match'), translate('Cannot drop file'));
    } else {
      this.context.controller.mapUnplannedPage(page, unplannedPages).then((result) => {
        if (!result.data.success) {
          pubsub.publish('drop-on-page', { unplannedPages, success: false });
          toastService.errorToast(result.data.subject, result.data.message);
        }
      });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      (nextProps.page.aggregated && nextProps.page.relatedPages.some(rp => rp.__isDirty === true)) ||
      nextProps.page.__isDirty === true ||
      this.state.progressBarVisible !== nextState.progressBarVisible ||
      this.state.progressFinishedTime !== nextState.progressFinishedTime ||
      this.state.itemProgress !== nextState.itemProgress ||
      this.state.uploadButtonVisible !== nextState.uploadButtonVisible ||
      this.state.dropAreaVisible !== nextState.dropAreaVisible
    );
  }

  // In this function there are flags and conditions whether to show the upload button on mouse over
  handleMouseEnter = () => {
    this.setState({ dropAreaVisible: false });
    if (!this.props.page.aggregated && this.hasUploadPermission() && !this.context.module.hideUploadButton) {
      this.setState({ uploadButtonVisible: true });
    }
  };

  handleMouseLeave = () => {
    if (this.props.page.aggregated) {
      return;
    }
    this.setState({ uploadButtonVisible: false });
  };

  handleDragEnter = e => {
    if (e.dataTransfer.types.some(type => type === 'text/plain') || this.props.page.aggregated) {
      this.setState({ dropAreaVisible: false });
    } else {
      this.setState({ dropAreaVisible: true });
    }
  };

  handleDragOver = e => {
    e.stopPropagation();
    if (e.dataTransfer.types.some(type => type === 'text/plain')) {
      e.dataTransfer.dropEffect = 'move';
    } else {
      e.dataTransfer.dropEffect = 'copy';
    }
  };

  handleDragLeave = () => this.setState({ dropAreaVisible: false });

  handleUploadFileFinalize = () => this.setState({ dropAreaVisible: false });

  // When the file has finished uploading, the progressFinishedTime flag is initialized to that time
  handleItemFinish = item => {
    const { uploadResponse: { data: { error, errorMessage } = {} } = {} } = item;
    this.setState({ progressBarVisible: false });
    if (error) {
      if (!this.errorMessageShown) {
        toastService.errorToast(translate('Upload Error'), translate(errorMessage));
        this.errorMessageShown = true;
      }
    } else {
      this.setState({ progressFinishedTime: Date.now() });
    }
  };

  handleItemStart = () => this.setState({ progressBarVisible: true, itemProgress: 0 });

  // Updates the upload file progress which reflects in the regular progress bar
  handleItemProgress = item => {
    this.itemTotalSize = item.total;
    this.setState({ itemProgress: item.loaded });
  };

  hasUploadPermission = () => typeof this.context.module.actionConfig !== 'undefined';

  // A callback function which shows an error toast message to indicate an upload process has been cancelled
  handleBatchCancelled = () => {
    toastService.errorToast(translate('Drop Error'), translate('Dropping multiple pages is not allowed'));
    this.setState({ dropAreaVisible: false });
  };

  handleFileDrop = () => {
    this.errorMessageShown = false;
    if (this.hasUploadPermission()) {
      this.setState({ progressFinishedTime: undefined });
    } else {
      toastService.errorToast(translate('Upload Files'), translate('You do not have permission to upload files'));
      this.setState({ progressBarVisible: false, dropAreaVisible: false });
    }
  };

  handleUploadButtonClick = () => {
    this.errorMessageShown = false;
    this.setState({ progressFinishedTime: undefined });
  };

  // A callbck function that checks whether to show the indeterminate progress bar
  // It is shown right after the upload process ended and before the tick updates begin
  isIndeterminateProgressBarVisible = () => {
    const { page } = this.props;
    if (page.aggregated) {
      return false;
    }

    return (typeof this.state.progressFinishedTime !== 'undefined' && typeof page.defaultStatus?.statusUpdateTime === 'undefined') ||
      (this.state.progressFinishedTime > page.defaultStatus?.statusUpdateTime);
  };

  render() {
    var {
      page,
      pageSize,
      gridAlign,
      pageDefinition,
      alignmentPoints,
      onClick,
      onContextMenu,
      onDoubleClick
    } = this.props,
      controller = this.context.controller,
      { enhancePreflightIndications } = this.context.module,
      pageViewportDefinition = this.getPageViewportDefinition(pageDefinition, page),
      generalProperties = this.getGeneralProperties(pageDefinition),
      pageContentStyleDefinition = this.getPageStyleDefinition(page, generalProperties, controller),
      pageContentStyle = this.getPageContentStyle(pageSize, gridAlign, page.ignore, pageContentStyleDefinition),
      preflightIndicationStyle = this.getPreflightIndicationStyle(pageSize, gridAlign),
      selectionColor = generalProperties.selectionColor,
      overColor = generalProperties.overColor,
      {
        alignedSegments,
        notAlignedSegments
      } = this.getSegments(pageViewportDefinition, alignmentPoints, page, pageContentStyle),
      preflightReportInfo = page?.pageContent?.preflightReportInfo,
      reportType = enhancePreflightIndications ? preflightReportInfo?.preflightReportType : undefined,
      shouldShowIndication = reportType === 1 || reportType === 2;

    return <div className='Page' id={page.id} key={page.id}
      onMouseEnter={this.handleMouseEnter}
      onDragOver={this.handleDragOver}
      onDragEnter={this.handleDragEnter}
      onMouseLeave={this.handleMouseLeave}>
      <div className={classNames('upload-button-container', { 'none': !this.state.uploadButtonVisible })}>
        <UploadButton
          target={{ targetNwid: page.nwid }}
          targetId={page.nwid}
          permissionToUpload={this.hasUploadPermission()}
          itemStartHandler={this.handleItemStart}
          itemProgressHandler={this.handleItemProgress}
          itemFinishHandler={this.handleItemFinish}
          finalizeHandler={this.handleUploadFileFinalize}
          buttonClickHandler={this.handleUploadButtonClick}
        />
      </div>
      {this.state.progressBarVisible ? <div className='upload-progress-bar-container'>
        <ProgressBar
          min={0}
          max={this.itemTotalSize}
          value={this.state.itemProgress}
          animated={true} />
      </div> :
        this.isIndeterminateProgressBarVisible() ? <div className='upload-progress-bar-container'>
          <IndeterminateProgressBar />
        </div>
          : null}
      <div className='PageHeader'>
        {notAlignedSegments['above']}
      </div>
      <div className='PageContent' style={pageContentStyle}>
        {shouldShowIndication && <div className='preflight-indication-container' style={preflightIndicationStyle}>
          <div className={classNames('preflight-indication', { warning: reportType === 1, error: reportType === 2 })}></div>
        </div>}

        <UploadDropZone
          target={{ targetNwid: page.nwid }}
          targetId={page.nwid}
          permissionToUpload={this.hasUploadPermission()}
          className={classNames('uploady-file-drop-area', { 'none': !this.state.dropAreaVisible })}
          style={preflightIndicationStyle}
          itemStartHandler={this.handleItemStart}
          itemProgressHandler={this.handleItemProgress}
          dragLeaveHandler={this.handleDragLeave}
          fileDropHandler={this.handleFileDrop}
          batchCancelledHandler={this.handleBatchCancelled}
          itemFinishHandler={this.handleItemFinish}
          finalizeHandler={this.handleUploadFileFinalize}
        />

        <ClickableElement onClick={onClick}
          onContextMenu={onContextMenu}
          onDoubleClick={onDoubleClick}
          overColor={overColor}>
          <SelectedElement model={page} style={{ borderColor: selectionColor }}>
          </SelectedElement>
          <DroppableArea onDrop={this.onDrop}>
            {alignedSegments}
          </DroppableArea>
        </ClickableElement>
      </div>
      <div className='PageFooter'>
        {notAlignedSegments['under']}
      </div>
    </div>;
  }
}

Page.propTypes = {
  page: PropTypes.object,
  pageSize: PropTypes.array,
  gridAlign: PropTypes.string,
  pageDefinition: PropTypes.object,
  alignmentPoints: PropTypes.object,
  onClick: PropTypes.func,
  onContextMenu: PropTypes.func,
  onDoubleClick: PropTypes.func
};