import { getMainStatus } from 'utilities/statuses';
import { COLUMN_FILTER_VALUES_TO_TOOLBAR_BUTTONS_MAP, TOOLBAR_BUTTONS_TO_COLUMN_KEYS_MAP } from './constants';
import { getHoldType } from 'utilities/hold';

const isStatusFinished = item => {
  const status = getMainStatus(item);
  if (status) {
    if (status.statusType === 'Success' && status.progress === '100') {
      return true;
    };
  };
  return false;
};

const isStatusHold = item => {
  const holdType = getHoldType(item);
  if (typeof holdType === 'undefined' || holdType === 'none') {
    return false;
  }
  return true;
};

const isStatusRejected = item => {
  const status = getMainStatus(item);
  if (status) {
    const statusType = status['statusType'];
    if (statusType === 'Error') {
      const type = status['flowStepType'];
      if (type === 'workflow/step/flow/approval') {
        return true;
      }
    }
  }
  return false;
};

const isNotVirtual = item => item.virtual !== true;

export const getCounters = (rows = []) => {

  let rejected = 0;
  let error = 0;
  let waitingForApproval = 0;
  let hold = 0;
  let finished = 0;

  rows.forEach(row => {
    if (isNotVirtual(row)) {
      rejected = isStatusRejected(row) ? rejected + 1 : rejected;
      error = isError(row) ? error + 1 : error;
      waitingForApproval = isWaitingForApproval(row) ? waitingForApproval + 1 : waitingForApproval;
      hold = isStatusHold(row) ? hold + 1 : hold;
      finished = isStatusFinished(row) ? finished + 1 : finished;
    };
  });

  return {
    rejected,
    error,
    waitingForApproval,
    hold,
    finished,
    total: rows.length
  };
};

const isLocalSeparationFilter = sep => sep.local && (typeof sep.baseSeparation === 'undefined' || sep.baseSeparation === null);

export const isLocalItemFilter = item => {
  const separations = item.type.includes('separation') ? [item] : item.separations;
  const localSeparations = separations.filter(sep => isLocalSeparationFilter(sep));
  return localSeparations.length > 0;
};

export const isItemWithActualPlates = item => {
  if (typeof item === 'undefined') {
    return false;
  }

  if (item.type === 'page') {
    return true;
  }

  const separations = item.type.includes('separation') ? [item] : item.separations;

  return separations.some(sep => (sep.plates || []).some(plate => plate.isActual === true));
};

export const isWaitingForApproval = item => {
  const status = getMainStatus(item);
  if (status) {
    const type = status['flowStepType'];
    if (type === 'workflow/step/flow/approval') {
      const statusType = status['statusType'];
      if (statusType === 'Waiting') {
        return true;
      }
    }
  }
  return false;
};

export const isError = item => {
  const status = getMainStatus(item);
  if (status) {
    const statusType = status['statusType'];
    if (statusType === 'Error') {
      const type = status['flowStepType'];
      if (type !== 'workflow/step/flow/approval') {
        return true;
      }
    }
  }
  return false;
};

export const isMissing = item => {
  const status = getMainStatus(item);
  if (status) {
    const percentage = status['progress'];
    if (percentage > -1) {
      return false;
    }
  }
  return true;
};

export const applyToolbarButtonsStatusFromColumnFilters = (module, columns) => {
  module.toolbar.items.forEach(item => {
    if (TOOLBAR_BUTTONS_TO_COLUMN_KEYS_MAP[item.name]) {
      let checked = false;
      if (columns.length > 0) {
        const column = columns.find(col => TOOLBAR_BUTTONS_TO_COLUMN_KEYS_MAP[item.name] === col.key);
        if (column?.filter.selected.length === 1) {
          if (item.name === COLUMN_FILTER_VALUES_TO_TOOLBAR_BUTTONS_MAP[column.filter.selected[0]]) {
            checked = true;
          }
        }
      }

      item.checked = checked;
    }
  });

  // we call refreshIsApplicableProperty function in order to re-render the toolbar with the updated statuses of the buttons
  module.toolbar.refreshIsApplicableProperty();
};
