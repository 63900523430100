/**
 * Round a given value to a specified number of decimal places
 * @param value {number} - the number to be rounded
 * @param decimalPlaces - the number of decimal places to round to
 * @returns {number} -  result of rounding
 */
export function round(value, decimalPlaces = 0) {
  const multiplier = Math.pow(10, decimalPlaces);
  return Math.round(value * multiplier) / multiplier;
}
