import React from 'react';
import PropTypes from 'prop-types';
import IconButton from "components/common/buttons/IconButton";

function LayoutsTreeNode({ node, nodeProps, trailingIcons, className }) {

  const renderLabel = () => {

    return (
      <div className='crtx-tree-node-label'>
        <div className='crtx-tree-node-label-content' title={nodeProps.text}>
          {nodeProps.text}
        </div>
        {renderTrailingIcons()}
      </div>
    );
  };

  const renderTrailingIcons = () => {

    const renderButton = (e, index) => {
      const { id, title, disabled, onClick } = trailingIcons[index];

      return (
        <IconButton
          key={id}
          iconName={id}
          tooltip={title}
          disabled={disabled}
          onClick={e => onClick(e, node, index)}
        />
      );
    };

    return (
      <div className="crtx-tree-node-trailing-icons">
        {trailingIcons.map(renderButton)}
      </div>
    );
  };

  const labelClassName = className ? `crtx-tree-node ${className || ''}` : 'crtx-tree-node';

  return (
    <div className={labelClassName}>
      {renderLabel()}
    </div>
  );
}

LayoutsTreeNode.propTypes = {
  node: PropTypes.object.isRequired,
  nodeProps: PropTypes.object.isRequired,
  className: PropTypes.string,
  trailingIcons: PropTypes.array
}

export default LayoutsTreeNode;
