import sandbox from 'sandbox';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utilities/classNames';
import { Popover, PopoverContent, PopoverTrigger, DropdownArrow } from 'components/common/floating';

const { translate } = sandbox.localization;

const NONE_VALUE = -1;

class CellMatrix extends Component {

  static propTypes = {
    value: PropTypes.string,
    rows: PropTypes.number,
    columns: PropTypes.number,
    className: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    onSelect: PropTypes.func
  };

  static defaultProps = {
    value: NONE_VALUE,
    rows: 0,
    columns: 0,
    className: ''
  };

  state = {
    visible: false
  };

  getOptionText(value) {
    return value < 0 ? translate('None') : value;
  }

  getSelectedOptionText() {
    const { value } = this.props;

    return value < 0 ? translate('None') : translate('Cell') + ' ' + value;
  }

  handleOptionClick(value) {
    this.setState({ visible: false });
    if (typeof this.props.onSelect === 'function') {
      this.props.onSelect(value);
    }
  }

  togglePopover = () => {
    if (!this.props.disabled) {
      this.setState({
        visible: !this.state.visible
      });
    }
  };

  closePopover = () => {
    this.setState({
      visible: false
    });
  };

  renderOption = (row, column) => {
    const { value, columns } = this.props;
    const cellIndex = row < 0 || column < 0 ? NONE_VALUE : row * columns + column;
    const colSpan = cellIndex < 0 ? columns : 1;
    const className = cellIndex === value ? 'selected' : '';

    return (
      <td
        className={className}
        key={column}
        colSpan={colSpan}
        onClick={() => this.handleOptionClick(cellIndex)}
      >
        {this.getOptionText(cellIndex)}
      </td>
    );
  };

  renderRow(row) {
    const { columns } = this.props;
    const matrixRow = Array.from({ length: columns });

    return (
      <tr key={row}>
        {matrixRow.map((item, column) => this.renderOption(row, column))}
      </tr>
    );
  }

  renderOptions() {
    const { rows } = this.props;
    const matrixRows = Array.from({ length: rows });

    return (
      <table className='crtx-cell-matrix-options'>
        <tbody>
        <tr key={NONE_VALUE}>
          {this.renderOption(0, NONE_VALUE)}
        </tr>
        {matrixRows.map((item, row) => this.renderRow(row))}
        </tbody>
      </table>
    );
  }

  render() {
    const { className, style, disabled } = this.props;
    const { visible } = this.state;

    return (
      <Popover
        placement={'bottom-start'}
        open={visible}
        onOpenChange={this.closePopover}
      >
        <PopoverTrigger onClick={this.togglePopover}>
          <div className={classNames('crtx-cell-matrix', className, { disabled })} style={style}>
            <div className='crtx-cell-matrix-content'>
              {this.getSelectedOptionText()}
            </div>
            <DropdownArrow opened={visible} disabled={disabled} />
          </div>
        </PopoverTrigger>

        <PopoverContent>
          {visible && this.renderOptions()}
        </PopoverContent>
      </Popover>
    );
  }
}

module.exports = CellMatrix;