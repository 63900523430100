define(['sandbox', 'AbstractAction'], function(sandbox, AbstractAction) {
  'use strict';

  return AbstractAction.extend({
    execute: function (objs) {
      if (objs === void 0){
        return;
      }

      var obj = objs[0];
      var that = this;

      var viewInstanceNwId = this.module.nwid;
      var deviceNwId = this.module.viewSettings.rootId;
      var type = this.module.viewSettings.rootType;
      var projectorId = this.module.projectorId;
      var request = sandbox.request.renameUnplanPage(viewInstanceNwId, this.nwid, deviceNwId, type, projectorId,
        {
          config: this.config,
          model: obj,
          parameters: this.parameters
        }
      );

      request.then(function (res) {
        var code = res.statusCode;
        if (code !== 200){
          var message = res.errorMessage;
          if (res !== null){
            sandbox.dialog.alert(message);
          }
          Ember.set(obj, 'name', obj.originalName);
        }
      });


    },
    response: function () {

    }
  });
});