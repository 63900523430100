import { startModule } from 'core/managers/module';
import { restGet } from 'core/managers/rest2';
import AbstractAction from 'AbstractAction';
import actionUtils from 'actions/actionUtils';

export default AbstractAction.extend({
  execute: function (args) {
    const obj = args[0];

    restGet(this.nwid, `templates/${obj.type}/${obj.nwid}/zones`)
      .then(({ zones }) => {
        const viewDefName = actionUtils.deduceViewDefinitionName(obj, this.parameters);
        const viewInfo = actionUtils.findViewInfoForAction(this.nwid, viewDefName);

        zones.forEach(zone => {
          startModule(viewInfo.nwid, this.module.id, {
            ...viewInfo,
            rootId: zone.nwid,
            rootType: zone.type,
            rootLabel: zone.name,
            rootName: zone.name,
            initiatingActionNwid: this.nwid,
            win: this.module.win
          });
        });
      })
      .catch(result => {
        console.error('OpenZoneOverviewsAction.execute() =>', `${result?.statusText} (${result?.status})`);
      });
  }
});
