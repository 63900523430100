/**
 * @name MatrixView Module
 * @author Liat
 */
define(['sandbox', 'ember', 'AbstractModule', './controllers/MatrixViewController', 'TableWidget', 'logger'],
  function (sandbox, Ember, AbstractModule, Controller, TableWidget, logger) {
    'use strict';

    var log = logger.getDefaultLogger();

    function createResorceEventsInfo(eventsInfo, r) {
      var maxEvents = r.maxInProgressEvents;
      for (var q = 0; q < maxEvents; q++) {
        var eventInfo = {};
        Ember.set(eventInfo, "progress", "height: 100%");
        Ember.set(eventInfo, 'processingEventId', 0);
        Ember.set(eventInfo, "inProcess", "0");
        eventsInfo.pushObject(eventInfo);
      }
      Ember.set( r, "eventsInfo", eventsInfo);
    }

    function getEventInfo(res, id) {
      var eventsInfo = res.eventsInfo;
      if (typeof  eventsInfo === "undefined") {
        return eventsInfo;
      }
      for (var i = 0; i < eventsInfo.length; i++) {
        if (eventsInfo[i].processingEventId === id) {
          return eventsInfo[i];
        }
      }
      for (i = 0; i < eventsInfo.length; i++) {
        if (typeof eventsInfo[i].processingEventId === "undefined" || eventsInfo[i].processingEventId === 0) {
          return eventsInfo[i];
        }
      }
      ////if from some reason a tick message to remove the event was not received
      for (i = 0; i < eventsInfo.length; i++) {
        Ember.set(eventsInfo[i], 'processingEventId', 0);
        Ember.set(eventsInfo[i], 'processingEventName', "");
        Ember.set(eventsInfo[i], "processingEventDetails", "");
        Ember.set(eventsInfo[i], "inProcess", "0");
      }
      return eventsInfo[0];
    }

    function parseColumns(preferences) {
      var output = [];
      for (var c in preferences) {
        if (preferences.hasOwnProperty(c)) {
          output[preferences[c].index] = {
            title: preferences[c].displayName,
            field: c
          };
        }
      }
      return output;
    }

    function isSkippableStep(model) {
      return model.type === 'workflow/step/flow/approval' ||
        model.type === 'workflow/step/image/preflight' ||
        model.type === 'workflow/step/image/optimization' ||
        model.type === 'workflow/step/output/cache';
    }

    function getDefaultColumns() {
      return [

        {title : "Name", field: "label", type: "textIcon", width: '25%', treeColumn: true, render: function (model) {
          return '<img src="' + sandbox.icons.getTemplateIcon(model.type, "tiny", model.iconName) + '"/><span>' + model.label + '</span>';
        }},

        {title : "State", field: "isOn", width: '5%', resizable: false, render: function (model) {
          var state, title;
          state = (model.isOn === true) ? 'on' : 'off';
          if (model.isOn) {
            if (model.objectType === 'flowStep') {
              title = 'Stop Workflow Step';
            }
            else {
              title = 'Turn Resource Off';
            }
          }
          else {
            if (model.objectType === 'flowStep') {
              title = 'Start Workflow Step';
            }
            else {
              title = 'Turn Resource On';
            }
          }
          if(model.objectType === 'flowStep' && model.type === 'workflow/step/flow/approval' || (model.type === 'workflow/step/input/status')) {
            return '';
          }
          return '<img class="content-centered" src="' + sandbox.icons.getGeneralIcon(model.objectType, state) + '" title="' + title + '"/>';
        }, handlers: [
          {
            event: 'click',
            execute: function (model) {
              if (model.objectType === 'flowStep') {
                this.ctrl.toggleDevice(model);
              } else {
                this.ctrl.toggleResource(model);
              }
            }.bind(this)
          }
        ]},

        {title : "Skip", field: "isSkipOn", width: '5%', resizable: false, render: function (model) {
          var skipState, title;
          skipState = (model.isSkipOn === true) ? 'skipon' : 'skipoff';
          if (model.objectType === 'flowStep' && isSkippableStep(model)) {
            title = model.isSkipOn ?  'Process Workflow Step' : 'Skip Workflow Step';
            return '<img class="content-centered" src="' + sandbox.icons.getGeneralIcon(model.objectType, skipState) + '" title="' + title + '"/>';
          }
          else {
            return ''; // resources || steps != approval or preflight
          }
        }, handlers: [
          {
            event: 'click',
            execute: function (model) {
              if (model.objectType === 'flowStep' && isSkippableStep(model)) {
                this.ctrl.toggleSkipDevice(model);
              }
            }.bind(this)
          }
        ]},

        {title : "Abort", field: "isOn", width: '5%', resizable: false, render: function (model) {
          if (model.objectType === 'resource') {
            return  '<img class="content-centered" src="' + sandbox.icons.getGeneralIcon(model.objectType, "abort_resource") + '" title="Abort"/>';
          }
          else {
            // it's a flowStep
            return '';
          }
        }, handlers: [
          {
            event: 'click',
            execute: function (model) {
              if (model.objectType === 'resource') {
                this.ctrl.abortResource([model]);
              }
            }.bind(this)
          }
        ]},

        {title : "Led", field: "humanStateDescription", width: '5%', resizable: false, render: function (model) {
          if (model.objectType === 'resource') {
            var state = (model.humanStateDescription === "Up") ? 'on' : (model.humanStateDescription === "Down") ? 'off' : 'unknown';
            return '<img class="content-centered" src="' + sandbox.icons.getGeneralIcon('resource', 'led_' + state) + '" />';
          }
          else {
            // it's a flowStep
            return '';
          }

        }.bind(this)},

        {title : "Lock", field: "lock", width: '5%'},

        {title : "Waiting", field: "queues.@each.size", width: '5%', render: eventsRenderer.bind(this, 'queWait')},

        {title : "Error", field: "queues.@each.size", width: '5%', render: eventsRenderer.bind(this, 'queError')},

        {title : "Finished", field: "queues.@each.size", width: '5%', render: eventsRenderer.bind(this, 'queFinished')},

        {title : "Hold", field: "queues.@each.size", width: '4%', render: eventsRenderer.bind(this, 'queHold')},

	      {title : "Rate", field: "rates", width: '4%', render: ratesRenderer.bind(this),
		      handlers: [{
			      event: 'click',
			      execute: graphRates.bind(this)}]
	      },

        {title : "Progress", field: "inProcessEvents.@each.progress", width: '7%', render: progressRenderer.bind(this)},

        {title : "Message", field: "message", width: '20%'}
      ];
    }

    function eventsRenderer(queType, model) {
      if (model.objectType === 'flowStep') {
        var queue = model.queues.findBy('queueType', queType);
        return queue.size;
      } else {
        return '';
      }
    }

    function progressRenderer(model) {
      if (model.objectType === 'resource') {
        var ret = '<table style="height: inherit" cellpadding="0" cellspacing="2"><tr style="height: inherit">';
        var eventsInfo = model.eventsInfo;
        for (var i = 0; i <eventsInfo.length; i++) {
          var progress = eventsInfo[i].progress;
          var inProcess = eventsInfo[i].inProcess;
          if (model.isProgressable) {
            ret += '<td style="height: inherit"><div class="progress-vertical" style="margin-left: 40%; height: 19px"><div class="progress-bar" style="' + progress + '"></div></div></td>';
          } else {
            ret += '<td style="margin-left: 40%;height: inherit"><div class="process-vertical process-stripes active" style="margin-left: 40%; width: 13px;"><div class="process-bar"  aria-valuenow="' + inProcess + '" role="progressbar" aria-valuemin="0" aria-valuemax="100" style="width: 100%"/></div></td>';
          }
        }
        ret += '</tr></table>';
        return ret;
      }
      //else {  // flowstep
      //  if (model.children && model.children.length) {
      //    for (var j = 0, lenj = model.children.length; j < lenj; j += 1) {
      //      progressRenderer(model.children[j]);
      //    }
      //  return '';
      //}
      return '';
    }

	  function ratesRenderer (model) {
		  var rate = 0;
		  if (model.objectType === 'resource' && model.hasOwnProperty ('rates') && model.hasOwnProperty ('rates')) {

			  if (model.rates.get('firstObject') != undefined && model.rates.get('firstObject').hasOwnProperty('rate')) {
			    rate = model.rates.get('firstObject').rate;
			  }

			  //model.rates.forEach (function (currRate) {
				 // rate += currRate.rate;
			  //});

			  //if (rate == 1){
				 // rate = rate + ' Unit/Hr';
			  //}
			  //else{
				 // rate = rate + ' Units/Hr';
			  //}
		  }
		  else{
			  return '';
		  }
		  var button = '<label style="width:95%; height:55%; color: #0000ff; text-decoration: underline; cursor: pointer; ">' + rate + '</label>'
		  return button;
	  }

	  function graphRates(model) {
		  this.ctrl.showRateGraph(model.rates);
	  }

    function getTableColumns(preferences, getDefaultTableConfig) {
      var columns = [];
      if (preferences !== undefined && preferences.table !== undefined && preferences.table.columns !== undefined) {
        columns = parseColumns(preferences.table.columns);
      }
      else if (getDefaultTableConfig) {
        columns = getDefaultTableConfig();
      }
      return columns;
    }

    function initialTickHandler(model, preferences) {

      // build map between id and model for updates
      this._map = sandbox.appUtils.flattenModel(model, {});

      // set content to the controller
      this.ctrl.set('content', model);
      this.ctrl.set('module', this);

      // append matrixView view container
      sandbox.dom.addClass(this.element, 'pro-modules-matrix');
      sandbox.dom.empty(this.element);

      // sort
      var sortingDescription = model['flowSteps' + ":sorting"];
      if (sortingDescription) {
        var sortingTokens = sortingDescription.split(":");
        if (sortingTokens.length === 2) {
          var flowStepsArray = this.ctrl.get('flowSteps');
          sandbox.sorting.sort(flowStepsArray, sortingTokens[1], sortingTokens[0]);
          flowStepsArray.forEach(function(e) {
            sandbox.sorting.sort(e["children"], sortingTokens[1], sortingTokens[0]);
          });
        }
        var steps = model.flowSteps;
        for (var i = 0; i < steps.length; i++) {
          var resources = steps[i].children;
          for (var j = 0; j < resources.length; j ++) {
            var r = resources[j];
            var eventsInfo = [];
            createResorceEventsInfo.apply(this, [eventsInfo, r]);
          }
        }
      }

      // create table widget
      this.flowStepTable = new TableWidget({
        element: this.element,
        data: this.ctrl.get('flowSteps'),
        columns: (preferences && preferences.table) ? parseColumns(preferences) : getDefaultColumns.call(this)
      });
      
      this.flowStepTable.bind('contextmenu', function (clicked, selected, ev) {
        this.showContextMenu(clicked, selected, ev);
      }, this);

      this.flowStepTable.bind('dblclick', function (selected) {
        this.navigateByViewLink(selected);
      }, this);

    }

    function handleAdd(model) {

      this._map[model.id] = model;

      if (model.hasOwnProperty("parentId")) {
        var parentId = model['parentId'];   // resource
        var parent  = this._map[parentId];
        if (typeof parent === 'undefined') {
          log.info("MatrixView.handleAdd: Parent entity not found - " + parentId);
          return;
        }

        //inProcessEvents
        var childProperty = model["childPropertyId"];
        var objToUpdate;
        if (parent.hasOwnProperty(childProperty)) {
          objToUpdate = parent[childProperty];
          var eventInfo = getEventInfo.apply(this, [parent, model.id]);
          if (childProperty === "inProcessEvents" ) {
            Ember.set(eventInfo, "progress", "height: " + (100 - model.progress) + '%');
            Ember.set(eventInfo, "inProcess", "100");
          } else if (typeof eventInfo !== "undefined") {
            Ember.set(eventInfo, 'processingEventId', 0);
            Ember.set(eventInfo, 'processingEventName', "");
            Ember.set(eventInfo, "processingEventDetails", "");
            Ember.set(eventInfo, "inProcess", "0");
          }
        } else {
          objToUpdate = [];
          Ember.set(parent, childProperty, objToUpdate); // flat
        }

        if (model.objectType === "resource" ) { //new resource is assigned
          var eventsInfo = [];
          createResorceEventsInfo.apply(this, [eventsInfo, model]);
        }

        objToUpdate.pushObject(model);
      }
    }

    function handleUpdate(update) {
      var progressInt = update.progress;
      var cached = this._map[update.id];
      if (typeof cached === 'undefined') {
        return;
      }
      for (var key in update) {
        if (update.hasOwnProperty(key) && key !== 'action') {
          Ember.set(cached, key, update[key]);
        }
      }
      if (cached.childPropertyId === "inProcessEvents" ) {
        var parent = this._map[cached["parentId"]];
        var eventInfo = getEventInfo.apply(this, [parent, cached.id]);
        Ember.set(eventInfo, "progress", "height: " + (100 - update.progress) + '%');
        Ember.set(eventInfo, "inProcess", "100");
      }
    }

    function handleRemove(model) {
      var idsToDelete = model["ids"];
      var parent = this._map[model["parentId"]];
      var childProperty = model["childPropertyId"];
      var objToUpdate = parent[childProperty];
      var updateArray = Array.isArray(objToUpdate);
      if (idsToDelete) {
        for (var i = 0; i < idsToDelete.length; i++) {
          var idToDelete =  idsToDelete[i];
          var o =  this._map[idToDelete];
          if (updateArray) {
            objToUpdate.removeObject(o);
            delete this._map[idToDelete];
          } else {
            this._map[idToDelete] = null;
            var existingObj = Ember.get(parent, childProperty);
            if (existingObj) {
              var existingId = existingObj["id"];
              if (existingId === idToDelete) {
                Ember.set(parent, childProperty, null);
              }
            }
          }

          if (childProperty === "inProcessEvents" ) {
            var eventInfo = getEventInfo.apply(this, [parent, idToDelete]);
            Ember.set(eventInfo, "progress", "height: 100%");
            Ember.set(eventInfo, 'processingEventId', 0);
            Ember.set(eventInfo, "inProcess", "0");
          }

        }

      }
    }

    return AbstractModule.extend({
      initDone: function () {
        this.ctrl = Controller.create();
        this.views = {};
        this.toolbar = this.createToolbar();
      },
      firstTickReceived: function (data) {
        initialTickHandler.call(this, data.model, data.preferences);
        this.selection.applyTableSelection(this, this.flowStepTable);
      },
      tickUpdate: function (data) {
        var models = Array.isArray(data.model) ? data.model : [data.model];
        for (var i = 0, leni = models.length; i < leni; i += 1) {
          switch (models[i].action) {
            case 'Add':
              handleAdd.call(this, models[i]);
              break;
            case 'Update':
              handleUpdate.call(this, models[i]);
              break;
            case 'Remove':
              handleRemove.call(this, models[i]);
              break;
          }
        }

      },
      basicActionArgs: function () {
        return [
          this.nwid,
          this.viewSettings.rootId,
          this.viewSettings.rootType,
          this.projectorId
        ];
      },
      destroy: function () {
        this._super();
        this.flowStepTable.destroy();
      }
    });
  });