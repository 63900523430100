import React from 'react';
import sys from 'core/sys';
import Button from 'components/common/buttons/Button';
import AuthManager from 'core/managers/authentication';
import { translate } from 'core/services/localization';


export default class LoginButton extends React.Component {

  constructor(props) {
    super(props);
  }

  handleLoginClick() {
    const userName = this.props.store.getState().userName;
    const password = this.props.store.getState().password;
    if (userName.length === 0) {
      sys.inform('Error', 'Please enter the username', 2);
      return;
    }
    AuthManager.login(userName, password);
  }


  render() {
    return <div>
      <Button type='submit' onClick={() => {
        this.handleLoginClick();
      }}>
        {translate('Login')}
      </Button>
    </div>;
  }
}
