import React, { Component } from 'react';
import Page from './Page';
import Header from 'src/components/common/headers/Header';
import PropTypes from 'prop-types';

module.exports = class Pages extends Component {
  constructor(props) {
    super(props);
    this.getPageComponent = this.getPageComponent.bind(this);
    this.getPages = this.getPages.bind(this);
  }

  static propTypes = {
    object: PropTypes.any,
    pages: PropTypes.any,
    title: PropTypes.string,
    advanceInfo: PropTypes.bool,
    openCustomInfoDialog: PropTypes.func,
    openerType: PropTypes.string//dialog or rightPanel
  }

  getPageComponent(page, index) {
    const { object, pages, advanceInfo, openCustomInfoDialog, openerType } = this.props;
    const fanout = object.fanout || {};
    const fanoutPages = fanout.pages || [];
    const fanoutPage = fanoutPages.find((fanoutPageElement, fanoutIndex) => {
      return fanoutPageElement.nwid === page.nwid
    });
    return <Page key={index} page={page} fanout={fanoutPage} object={object} advanceInfo={advanceInfo} openCustomInfoDialog={openCustomInfoDialog} openerType={openerType} />;
  }

  getPages() {
    const { object, pages, advanceInfo } = this.props;
    const pagesComponents = pages.map(this.getPageComponent);
    return pagesComponents;
  }

  render() {
    const { title } = this.props;
    return <div className='pages-custom-info'>
      <Header>{title}</Header>
      {this.getPages()}
    </div>;
  }

}