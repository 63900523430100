import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ProgressBar extends Component {

  static propTypes = {
    disabled: PropTypes.bool,
    vertical: PropTypes.bool,
    animated: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.number,
    color: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.node,
  };

  static defaultProps = {
    disabled: false,
    vertical: false,
    animated: false,
    min: 0,
    max: 100,
    value: 0,
  };

  constructor(props) {
    super(props);
  }

  getPercent = (min, max, value) => {
    let percent = 0;
    if (max - min !== 0) {
      percent = (((value - min) * 100) / (max - min));
    }

    return percent + '%';
  };

  render() {
    const { vertical, min, max, value, color, style, children, disabled, title, animated } = this.props;
    const percent = this.getPercent(min, max, value);
    let width = '100%';
    let height = '100%';
    if (vertical) {
      height = percent;
    } else {
      width = percent;
    }
    const className = `crtx-progress-bar ${this.props.className} ${disabled ? 'disabled' : ''}`;
    const barClassName = `crtx-progress-bar-progress ${vertical ? 'vertical' : ''} ${animated ? 'animated' : ''}`;
    let barStyle = {
      width,
      height,
      backgroundColor: color
    };

    return (
      <div className={className} style={style} title={title}>
        <div className={barClassName} style={barStyle} />
        <div className='crtx-progress-bar-progress-text'>
          {children}
        </div>
      </div>
    );
  }

}

export default ProgressBar;
