import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';
import utils from '../utils';
import SimpleFormContainer from '../SimpleFormContainer';
import actions from '../actions';

var ARROW_ICON_SRC = sandbox.icons.getSystemIcon('arrow');

export default SimpleFormContainer(class extends Component {
  static propTypes = {

    //store - The main store of the SimpleForm.
    store: PropTypes.object,

    bind: PropTypes.string,

    title: PropTypes.any,

    collapsible: PropTypes.bool,

    defaultCollapsed: PropTypes.bool,

    style: PropTypes.object,

    className: PropTypes.string,

    visible: PropTypes.bool,

    onCollapse: PropTypes.func,

    borderWidth: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),

    borderColor: PropTypes.string,

    borderStyle: PropTypes.string,

    padding: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),

    col: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),

    offset: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ])

  };

  static defaultProps = {
    defaultCollapsed: false,
    onCollapse: function () {
    }
  };

  state = {
    collapsed: this.props.defaultCollapsed
  };

  getBoundObject = () => {

    var { store, bind } = this.props;
    return utils.isString(bind) ? store.get(bind) : null;

  };

  isCollapsible = () => {

    var { collapsible } = this.props;
    var boundedObject = this.getBoundObject();

    return collapsible === true;

  };

  isCollapsed = () => {

    var { collapsed } = this.state;
    var boundedObject = this.getBoundObject();

    return utils.isBoolean(boundedObject) ? boundedObject === true : collapsed === true;

  };

  handleCollapsibleClick = (ev) => {

    var { store, bind, onCollapse } = this.props;
    var { collapsed } = this.state;
    var boundedObject = this.getBoundObject();

    if (!this.isCollapsible()) return;

    if (utils.isBoolean(boundedObject)) {
      //store.actions.update(bind, !boundedObject);

      store.dispatch(actions.update(bind, !boundedObject));

      //store.actions.update(bind, {
      //  ...boundedObject,
      //  collapsed: !boundedObject.collapsed
      //});
    }
    else {
      this.setState({
        collapsed: !collapsed
      });
    }

    onCollapse(bind, utils.isBoolean(boundedObject) ? !boundedObject : !collapsed);

    ev.preventDefault();
    ev.stopPropagation();

  };

  renderCollapsible = (isCollapsible) => {

    //var isCollapsible = this.isCollapsible();

    if (!isCollapsible) {
      return null;
    }

    return <div className="panel-title-collapse-handler" onClick={this.handleCollapsibleClick}>
      <img src={ARROW_ICON_SRC} />
    </div>;

  };

  renderTitleContent = (titleContent) => {

    return <div className="panel-title-content">
      {titleContent}
    </div>;

  };

  renderTitle = (boundedObject, isCollapsed, backgroundColor) => {

    var { title } = this.props;
    var isCollapsible = this.isCollapsible();
    var titleContent = typeof title !== 'undefined' ? title : null;
    var titleClassName = isCollapsed ? 'panel-title collapsed' : 'panel-title';
    var titleStyle = {
      backgroundColor: backgroundColor
    };
    titleClassName = isCollapsible ? titleClassName : titleClassName + ' not-collapsible';

    if (titleContent === null) {
      return null;
    }

    return <div className={titleClassName} style={titleStyle}>
      {this.renderCollapsible(isCollapsible)}
      {this.renderTitleContent(titleContent)}
    </div>

  };

  render() {


    var { store, bind, title, collapsible, style, col, offset, visible, className, borderWidth, borderColor, borderStyle, padding, children, ...props } = this.props;
    var { collapsed } = this.state;
    var boundedObject = this.getBoundObject();
    var isCollapsed = this.isCollapsed();
    var panelStyle = {
      borderWidth: borderWidth,
      borderColor: borderColor,
      borderStyle: borderStyle,
      ...style
    };
    var contentStyle = {
      display: isCollapsed ? 'none' : '',
      padding: padding
    };

    return <div className={utils.combineClassNames([className, 'panel'])}
      style={panelStyle}
      {...props}>

      {this.renderTitle(boundedObject, isCollapsed, borderColor)}
      <div ref="content" className="panel-content" style={contentStyle}>
        {children}
      </div>

    </div>;

  }
});
