import React from 'react';
import sandbox from 'sandbox';
import { createRoot } from 'react-dom/client';
import AbstractModule from 'AbstractModule';
import BirdeyeMain from './src/birdeyemain';
import Controller from './controllers/controller';
import { restGet } from 'core/managers/rest2';
import { translate } from 'core/services/localization';
import TickableModel from '../TickableModel';
import jsutils from 'base/jsUtils';
import { transformViewModel } from '../ThumbnailLayers/viewModelTransforms/viewModelTranforms';
import { arrayToObject } from 'utilities/array';
import settingsManager from 'core/managers/settings';

const THROTTLE_WAIT = 1000;

function birdeyeProps(module, model, alignmentPoints, pageDefinition, formDefinition) {
  return {
    module: module,
    model: model,
    pageDefinition: pageDefinition,
    formDefinition: formDefinition,
    //thumbnailDefinition: thumbnailDefinition,
    alignmentPoints: alignmentPoints
  };
}

const getUpdatedSelectedItems = (model, selectedItems) => {
  let updatedSelected = [];
  if (model.type === 'section') {
    model.pages.forEach(page => {
      if (selectedItems[page.id]) {
        updatedSelected.push(page);
      }
    });
  } else if (model.type === 'book') {
    model.forms.forEach(form => {
      if (selectedItems[form.id]) {
        updatedSelected.push(form);
      }
    });
  } else {
    model.sections?.forEach(section => {
      section.pages.forEach(page => {
        if (selectedItems[page.id]) {
          updatedSelected.push(page);
        }
      });
    });
    model.books?.forEach(book => {
      book.forms.forEach(form => {
        if (selectedItems[form.id]) {
          updatedSelected.push(form);
        }
      });
    });
  }

  return updatedSelected;
};

function showToolbar(data) {
  const { pushedFilterButton, pushedPreflightFilterButton = {} } = this.preferences;

  this.toolbar = this.createToolbar();
  this.toolbar.addItem({
    label: 'Expand All',
    name: 'Expand All',
    _isApplicable: true,
    icon: 'expand',
    tooltip: translate('Expand All'),
    execute: this.ctrl.expandAllViews.bind(this.ctrl)
  });
  this.toolbar.addItem({
    label: 'Collapse All',
    name: 'Collapse All',
    _isApplicable: true,
    icon: 'collapse',
    tooltip: translate('Collapse All'),
    execute: this.ctrl.collapseAllViews.bind(this.ctrl)
  });
  this.toolbar.addItem({
    label: 'Missing Pages',
    name: 'missing',
    checked: pushedFilterButton === 'missing',
    _isApplicable: true,
    icon: 'missing_pages',
    tooltip: this.ctrl.getMissingTooltip('pages'),
    itemType: 'push',
    groupName: 'pageState',
    execute: this.filterButtonClick.bind(this)
  });
  this.toolbar.addItem({
    label: 'Error Pages',
    name: 'error',
    checked: pushedFilterButton === 'error',
    _isApplicable: true,
    icon: 'error_pages',
    tooltip: this.ctrl.getErrorTooltip('pages'),
    itemType: 'push',
    groupName: 'pageState',
    execute: this.filterButtonClick.bind(this)
  });
  this.toolbar.addItem({
    label: 'Waiting For Approval',
    name: 'waiting_for_approval',
    checked: pushedFilterButton === 'waiting_for_approval',
    _isApplicable: true,
    icon: 'waiting_for_approval',
    tooltip: translate('Show Waiting For Approval'),
    itemType: 'push',
    groupName: 'pageState',
    execute: this.filterButtonClick.bind(this)
  });
  if (this.ctrl.hasBooks(data.model) || this.ctrl.isBook(data.model)) {
    this.toolbar.addItem({
      label: 'Expected',
      name: 'actual',
      checked: pushedFilterButton === 'actual',
      _isApplicable: true,
      icon: 'actual_plates',
      tooltip: translate('Show Formes With Expected Plates Only'),
      itemType: 'push',
      groupName: 'pageState',
      execute: this.filterButtonClick.bind(this)
    });
  }
  if (data.model.type !== 'edition') {
    this.toolbar.addItem({
      label: 'Local Pages',
      name: 'local_pages',
      checked: pushedFilterButton === 'local_pages',
      _isApplicable: true,
      icon: 'local_pages',
      tooltip: translate('Show Local Pages Only'),
      itemType: 'push',
      groupName: 'pageState',
      execute: this.filterButtonClick.bind(this)
    });
  }
  if (data.model.type === 'edition' || this.ctrl.hasSections(data.model)) {
    this.toolbar.addItem({
      label: 'Warning in preflight',
      name: 'warning_in_preflight',
      checked: pushedPreflightFilterButton.warning === 'warning_in_preflight',
      _isApplicable: true,
      icon: 'preflight_report_warning',
      iconSprite: 'general',
      tooltip: translate('Show Warning In Preflight'),
      itemType: 'push',
      execute: this.preflightFilterButtonClick.bind(this)
    });
    this.toolbar.addItem({
      label: 'Error in preflight',
      name: 'error_in_preflight',
      checked: pushedPreflightFilterButton.error === 'error_in_preflight',
      _isApplicable: true,
      icon: 'preflight_report_error',
      iconSprite: 'general',
      tooltip: translate('Show Error In Preflight'),
      itemType: 'push',
      execute: this.preflightFilterButtonClick.bind(this)
    });
    if (data.model.type === 'edition') {
      this.toolbar.addItem({
        label: translate('Aggregate Mode'),
        name: 'aggregateMode',
        _isApplicable: true,
        icon: 'aggregate',
        iconSprite: 'general',
        itemType: 'push',
        checked: data.preferences.aggregateMode,
        groupName: 'aggregate',
        execute: this.toggleAggregateMode.bind(this)
      });
    }
    this.toolbar.addItem({
      label: translate('Hide Upload Button'),
      name: 'hideUploadButton',
      _isApplicable: true,
      icon: 'upload_off',
      iconSprite: 'general',
      itemType: 'push',
      checked: data.preferences.hideUploadButton,
      execute: this.toggleHideUploadButton.bind(this)
    });
  }
}

function showToolbarGroupZoneViewType(model) {
  if (!this.ctrl.hasBooks(model) || !this.ctrl.hasSections(model)) {
    return;
  }

  const pushedButton = this.preferences.pushedViewButton;

  this.toolbar.addItem({
    label: 'Show sections',
    name: 'show_sections',
    checked: pushedButton === 'show_sections',
    _isApplicable: true,
    icon: 'sections',
    tooltip: translate('Show sections'),
    itemType: 'push',
    groupName: 'showZoneViewType',
    execute: this.viewButtonClick.bind(this)
  });
  this.toolbar.addItem({
    label: 'Show books',
    name: 'show_books',
    checked: pushedButton === 'show_books',
    _isApplicable: true,
    icon: 'books',
    tooltip: translate('Show books'),
    itemType: 'push',
    groupName: 'showZoneViewType',
    execute: this.viewButtonClick.bind(this)
  });
}

const addStatusChangeTime = updates => {
  updates.forEach(update => {
    if (update.childPropertyId === 'defaultStatus') {
      update.statusUpdateTime = Date.now();
    }
  });
};

export default AbstractModule.extend({
  allowStandbyMode: true,
  init: function () {
    var that = this;
    this._super();
    this.selectedItems = {};
    this.ctrl = new Controller(this);

    this.shouldShowRelatedItemsDialog = false;
    this.itemsToRenderInDialog = [];
    this.aggregatedItem = {};
    this.viewModel = {};
    this.enhancePreflightIndications = settingsManager.getFolderInfo().enhancePreflightIndications;
    this.handleClose = this.handleClose.bind(this);
    this.tickableModel = new TickableModel({
      markDirty: true,
      onAction: function (action) {
        if (action === 'add') {
          let model = arguments[2];
          if (model && model.type === 'page' || model.type === 'form') {
            let content = model.pageContent || model.pageContent || {};
            let backgroundColor = model.backGroundColor || content.backGroundColor;
            let textColor = model.textColor || content.textColor;

            if (backgroundColor === '#ff0000' || textColor === '#ff0000') {
              model.guiBackgroundColor = '#ff0000';
            } else {
              delete model.guiBackgroundColor;
            }
          }
          if (model && model.type === 'page/content') {
            let page = arguments[1];
            let content = model;

            let backgroundColor = page.backGroundColor || content.backGroundColor;
            let textColor = page.textColor || content.textColor;

            if (backgroundColor === '#ff0000' || textColor === '#ff0000') {
              page.guiBackgroundColor = '#ff0000';
            } else {
              delete page.guiBackgroundColor;
            }
          }
          if (model && model.type === 'form/content') {
            let form = arguments[1];
            let content = model;

            let backgroundColor = form.backGroundColor || content.backGroundColor;
            let textColor = form.textColor || content.textColor;

            if (backgroundColor === '#ff0000' || textColor === '#ff0000') {
              form.guiBackgroundColor = '#ff0000';
            } else {
              delete form.guiBackgroundColor;
            }
          }
        }
        if (action === 'update') {
          let model = arguments[1];
          if (model && model.type === 'page' || model.type === 'form') {
            let content = model.pageContent || model.pageContent || {};
            let backgroundColor = model.backGroundColor || content.backGroundColor;
            let textColor = model.textColor || content.textColor;

            if (backgroundColor === '#ff0000' || textColor === '#ff0000') {
              model.guiBackgroundColor = '#ff0000';
            } else {
              delete model.guiBackgroundColor;
            }
          }
          if (model && model.type === 'page/content') {
            let page = model.__parent;
            let content = model || {};

            let backgroundColor = page.backGroundColor || content.backGroundColor;
            let textColor = page.textColor || content.textColor;

            if (backgroundColor === '#ff0000' || textColor === '#ff0000') {
              page.guiBackgroundColor = '#ff0000';
            } else {
              delete page.guiBackgroundColor;
            }
          }
          if (model && model.type === 'form/content') {
            let form = model.__parent;
            let content = model || {};

            let backgroundColor = form.backGroundColor || content.backGroundColor;
            let textColor = form.textColor || content.textColor;

            if (backgroundColor === '#ff0000' || textColor === '#ff0000') {
              form.guiBackgroundColor = '#ff0000';
            } else {
              delete form.guiBackgroundColor;
            }
          }
        }
      },
      onObjectParsed: function (model) {
        if (model && model.type && (model.type === 'page' || model.type === 'form')) {
          let content = model.pageContent || model.formContent || {};
          let backgroundColor = model.backGroundColor || content.backGroundColor;
          let textColor = model.textColor || content.textColor;

          if (backgroundColor === '#ff0000' || textColor === '#ff0000') {
            model.guiBackgroundColor = '#ff0000';
          } else {
            delete model.guiBackgroundColor;
          }
        }
      }
    });
    this.alignmentPoints = {};
    this.pagesDefinition = {};
    this.formsDefinition = {};
  },

  initDone: function () {
    this.ctrl.keyDownHandler(this.element);
    this.reactRoot = createRoot(this.domElement);
    this.tab && this.tab.registerDeselectHandler(this.handleClose);
    this.updates = [];
    this.tickUpdateHandlerThrottled = jsutils.throttle(this.tickUpdateHandler, THROTTLE_WAIT, {
      leading: false,
      trailing: true
    });
  },

  destroy: function () {
    this._super();
    this.handleClose();
    this.ctrl.removeKeyDownHandler(this.element);
    this.reactRoot.unmount();
  },

  showFooter: function (model) {
    const { planImportFileName, planImportTime } = model;
    if (planImportFileName && planImportTime) {
      const footer = this.createFooter();

      footer.addPlanImportInfo(planImportFileName, planImportTime);
    }
  },

  getActionConfig: function () {
    const action = this.viewActions.find((a) => a.actionDefinitionName === 'UploadActionCR' && a.nwid);
    if (!action || !action.config) {
      console.warn('BirdEyeLayers.getActionConfig() => Action config section was not found');
      return;
    }

    return action.config;
  },

  firstTickReceived: function (data) {
    this.alignmentPoints = data.config.alignmentPoints;
    this.pagesDefinition = data.config.pageDefinition;
    this.formsDefinition = data.config.formDefinition;
    this.tickableModel.firstTickHandler(data.model);
    this.hideUploadButton = data.preferences.hideUploadButton || false;

    restGet(this.nwid, 'conversion-tables/Approvers/data')
      .then(approversData => this.approvers = approversData);

    if (data.model.type === 'edition') {
      this.aggregateMode = data.preferences.aggregateMode || false;
    }
    this.actionConfig = this.getActionConfig();
    const transformedModel = transformViewModel(this.tickableModel.model(), this.ctrl, this.aggregateMode, this.aggregatedItem, this.pagesDefinition, this.formsDefinition);
    this.viewModel = transformedModel;
    if (typeof this.viewModel.showLinksOnlyForChase === 'undefined') {
      this.viewModel.showLinksOnlyForChase = this.viewModel.sections?.[0]?.showLinksOnlyForChase;
    }
    this.itemsToRenderInDialog = transformedModel.itemsToRenderInDialog || [];
    this.preferences = data.preferences || {};

    showToolbar.call(this, data);
    this.showFooter(data.model);
    showToolbarGroupZoneViewType.call(this, this.viewModel);

    if (this.preferences.pushedFilterButton) {
      this.ctrl.applyFilter.call(this.ctrl, true, this.preferences.pushedFilterButton);
    }

    if (this.preferences.pushedViewButton) {
      this.ctrl.applyFilterZoneViewType.call(this.ctrl, true, this.preferences.pushedViewButton);
    }

    if (this.preferences.pushedPreflightFilterButton) {
      this.preferences.pushedPreflightFilterButton.warning && this.ctrl.applyPreflightFilter.call(this.ctrl, true, this.preferences.pushedPreflightFilterButton.warning);
      this.preferences.pushedPreflightFilterButton.error && this.ctrl.applyPreflightFilter.call(this.ctrl, true, this.preferences.pushedPreflightFilterButton.error);
    }

    if (this.preferences.hideThumbnails) {
      this.ctrl.applyFilterZoneViewType.call(this.ctrl, true);
    }
    this.render();
  },

  toggleAggregateMode: function (pushed) {

    this.aggregateMode = pushed;

    this.toolbar.setItemChecked('aggregateMode', pushed);

    const transformedModel = transformViewModel(this.tickableModel.model(), this.ctrl, this.aggregateMode, this.aggregatedItem, this.pagesDefinition, this.formsDefinition);
    this.viewModel = transformedModel;
    this.itemsToRenderInDialog = transformedModel.itemsToRenderInDialog || [];

    if (!pushed) {
      this.shouldShowRelatedItemsDialog = false;
      this.aggregatedItem = {};
      this.itemsToRenderInDialog = [];
    }
    this.render();

    this.savePreferences({ aggregateMode: pushed });
  },

  toggleHideUploadButton: function (pushed) {

    this.hideUploadButton = pushed;

    this.toolbar.setItemChecked('hideUploadButton', pushed);

    this.render();

    this.savePreferences({ hideUploadButton: pushed });
  },

  tickUpdate: function (data) {
    this.updates = this.updates.concat(data.model);
    this.tickUpdateHandlerThrottled();
    this.showFooter(data.model[0]);
  },

  tickUpdateHandler: function () {
    addStatusChangeTime(this.updates);
    this.tickableModel.tickUpdateHandler(this.updates);
    this.updates = [];
    const transformedModel = transformViewModel(this.tickableModel.model(), this.ctrl, this.aggregateMode, this.aggregatedItem, this.pagesDefinition, this.formsDefinition);
    this.viewModel = transformedModel;
    const updatedSelected = getUpdatedSelectedItems(this.viewModel, this.selectedItems);
    this.selectedItems = arrayToObject(updatedSelected, 'id');
    this.updateSelected(updatedSelected);
    this.itemsToRenderInDialog = transformedModel.itemsToRenderInDialog || [];
    this.render();
  },

  savePreferences: function (preferences) {
    if (!preferences) {
      return;
    }

    this.preferences = Object.assign(this.preferences, preferences);
    sandbox.preferences.savePreferences(this.getRequiredParameters(), this.preferences);
  },

  handleAggregatedItemClick: function (aggregatedItem) {
    this.shouldShowRelatedItemsDialog = this.aggregatedItem.relatedPages?.[0].nwid === aggregatedItem.relatedPages?.[0].nwid ? !this.shouldShowRelatedItemsDialog : true;
    this.aggregatedItem = aggregatedItem;
    this.itemsToRenderInDialog = aggregatedItem.relatedPages;
    this.render();
  },

  handleClose: function () {
    this.shouldShowRelatedItemsDialog = false;
    this.aggregatedItem = {};
    this.itemsToRenderInDialog = [];
    this.render();
  },

  filterButtonClick: function (pushed, buttonName) {
    this.ctrl.applyFilter.call(this.ctrl, pushed, buttonName);
    this.render();

    this.savePreferences({ pushedFilterButton: pushed ? buttonName : '' });
  },

  viewButtonClick: function (pushed, buttonName) {
    this.ctrl.applyFilterZoneViewType.call(this.ctrl, pushed, buttonName);
    this.render();

    this.savePreferences({ pushedViewButton: pushed ? buttonName : '' });
  },

  preflightFilterButtonClick: function (pushed, buttonName) {
    this.ctrl.applyPreflightFilter.call(this.ctrl, pushed, buttonName);
    this.render();

    const preflightPreferences = this.preferences.pushedPreflightFilterButton ?? {};
    if (buttonName === 'warning_in_preflight') {
      this.savePreferences({ pushedPreflightFilterButton: { ...preflightPreferences, warning: pushed ? buttonName : '' } });
    } else {
      this.savePreferences({ pushedPreflightFilterButton: { ...preflightPreferences, error: pushed ? buttonName : '' } });
    }
  },

  render: function () {
    var birdeyeMain = React.createElement(BirdeyeMain, birdeyeProps(this, this.viewModel,
      this.alignmentPoints,
      this.pagesDefinition,
      this.formsDefinition));

    this.reactRoot.render(birdeyeMain);
    setTimeout(() => this.tickableModel.clean(this.tickableModel.model()), 0);
  }
});