import AbstractAction from 'AbstractAction';
import sandbox from 'sandbox';
import toastService from 'core/services/toastService';

const translate = sandbox.localization.translate;
const labels = {
  comment: translate('Comment:'),  
  errorMessage: translate('Failed'),
};

export default AbstractAction.extend({

  isApplicable: function (selectedItems) {
    console.log ("TogglePropertyAction isApplicable.....");
    let ret = true;    
    const mode = this.parameters.mode;
    const propertyName = this.config.propertyName;
    for (let item of selectedItems){
      const value = item[propertyName] == "true";
      if ((mode == "clear" && !value) || (mode == "set" && value)){
        return false;
      }
    }
    return true;
  },

  execute: function (selectedItems) {
    const viewInstanceNwId = this.module.nwid;    

    const rest = sandbox.request.rest(viewInstanceNwId);
    const folderNwId = this.module.folderNwid;    
    const propertyName = this.config.propertyName;

    let items = [];
    for (let item of selectedItems){
      items.push({nwid:item.nwid, type:item.type});
    }
    return rest.post(`/folders/${folderNwId}/planning-actions/toggleProperty?propertyName=${propertyName}`, {
      data: JSON.stringify({items:items}),
      dataType: 'text',      
    }).fail(() => {
      toastService.createToast('top-right', labels.updateMniNumberTitle, labels.errorMessage, 'error', undefined, false);
    });



    // rest.post(`/folders/${folderNwId}/planning-actions?propertyName=${propertyName}`);
  }
});


