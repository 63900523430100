import PropTypes from 'prop-types';
import { round } from 'utilities/math';
import { loadImage } from 'utilities/image';
import CanvasComponent from './canvasComponent';
import {
  INCH,
  userUnitsToPixels,
  pixelsToUserUnits,
} from 'utilities/lengthUnits';
import {
  CANVAS_AREA,
  RULER_THICKNESS,
  transformPoint,
  toPixelCenter,
  getImageTopLeft,
} from './utilities';

const MIN_PIXELS_BETWEEN_LABELS = 96;
const TICKS_BETWEEN_INCH_LABELS = 16;
const TICKS_BETWEEN_CM_LABELS = 10;
const L1_TICK = 18; // tallest tick length in pixels
const L2_TICK = 12; // pixels
const L4_TICK = 8; // pixels
const L8_TICK = 4; // pixels
const L16_TICK = 2; // shortest tick length in pixels

const BACKGROUND_COLOR = '#fff';
const STROKE_COLOR = '#000';
const CORNER_COLOR = '#dadada';
const MOUSE_MARK_COLOR = '#ff0000';

const DIGITS_IMAGE_URL = '../kernel/assets/img/canvas/digits_7px.png';
const DIGIT_HEIGHT = 7;
const DIGIT_WIDTH = 4;
const DIGITS_GAP = 1;

let digitsImage;

const getRulerOrigin = (rulerOriginPoint, image, domMatrix) => {
  let origin;
  if (rulerOriginPoint) {
    origin = transformPoint(rulerOriginPoint, domMatrix);
  } else {
    origin = getImageTopLeft(image, domMatrix);
  }

  return origin;
};

const roundUserUnitsBetweenLabels = (userUnits) => {
  let result = 1;

  if (userUnits > 100) {
    result = round(userUnits, -2);
  } else if (userUnits > 10) {
    result = round(userUnits, -1);
  } else if (userUnits > 1) {
    result = round(userUnits, 0);
  }

  return result;
};

const getTicksBetweenLabels = (lengthUnit) => {
  return lengthUnit === INCH ? TICKS_BETWEEN_INCH_LABELS : TICKS_BETWEEN_CM_LABELS;
};

const getTickLength = (stepIndex, ticksBetweenLabels) => {
  let tickLength = L16_TICK;

  if (stepIndex % ticksBetweenLabels === 0) {
    tickLength = L1_TICK;
  } else if (ticksBetweenLabels === TICKS_BETWEEN_INCH_LABELS) {
    if (stepIndex % (ticksBetweenLabels / 2) === 0) {
      tickLength = L2_TICK;
    } else if (stepIndex % (ticksBetweenLabels / 4) === 0) {
      tickLength = L4_TICK;
    } else if (stepIndex % (ticksBetweenLabels / 8) === 0) {
      tickLength = L8_TICK;
    }
  } else if (stepIndex % (ticksBetweenLabels / 2) === 0) {
    tickLength = L2_TICK;
  } else {
    tickLength = L8_TICK;
  }

  return tickLength;
};

const computeRulerParams = (resolution, lengthUnit, zoom) => {
  const userUnits = pixelsToUserUnits(MIN_PIXELS_BETWEEN_LABELS, resolution, lengthUnit, zoom);
  const userUnitsBetweenLabels = roundUserUnitsBetweenLabels(userUnits);
  const ticksBetweenLabels = getTicksBetweenLabels(lengthUnit);
  const step = userUnitsToPixels(userUnitsBetweenLabels / ticksBetweenLabels, resolution, lengthUnit, zoom);

  return {
    userUnitsBetweenLabels,
    ticksBetweenLabels,
    step
  };
};

const createRulerMarks = ({ resolution, lengthUnit, zoom, start, rulerLength }) => {
  const ticks = [];
  const labels = [];

  const { ticksBetweenLabels, step } = computeRulerParams(resolution, lengthUnit, zoom);

  let stepIndex = 0;
  for (let coord = start; coord < rulerLength; coord += step) {
    if (coord > 0) {

      getTickLength(stepIndex, ticksBetweenLabels);

      if (stepIndex % ticksBetweenLabels === 0) {
        const value = pixelsToUserUnits(coord - start, resolution, lengthUnit, zoom);
        labels.push({ coord, value });
      }

      const tickLength = getTickLength(stepIndex, ticksBetweenLabels);
      ticks.push({ coord, tickLength });
    }

    stepIndex++;
  }

  stepIndex = 1;
  for (let coord = start - step; coord > 0; coord -= step) {
    getTickLength(stepIndex, ticksBetweenLabels);

    if (stepIndex % ticksBetweenLabels === 0) {
      const value = pixelsToUserUnits(coord - start, resolution, lengthUnit, zoom);
      labels.push({ coord, value });
    }

    const tickLength = getTickLength(stepIndex, ticksBetweenLabels);
    ticks.push({ coord, tickLength });

    stepIndex++;
  }

  return { ticks, labels };
};

export class Ruler extends CanvasComponent {
  static propTypes = {
    image: PropTypes.object,
    zoom: PropTypes.number,
    lengthUnit: PropTypes.string,
    resolution: PropTypes.shape({
      xResolution: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      yResolution: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    mouseDownPoint: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    }),
    mouseMovePoint: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    }),
    rulerOriginPoint: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    }),
    point: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    })
  };

  static defaultProps = {
    point: {
      x: 0,
      y: 0
    },
  };

  componentDidMount() {
    super.componentDidMount();

    if (!digitsImage) {
      loadImage(DIGITS_IMAGE_URL).then(image => digitsImage = image);
    }
  }

  drawDigit = (ctx, digit, x, y) => {
    if (!digitsImage || digit < 0 || digit > 9) {
      return;
    }

    const offset = digit * (DIGIT_WIDTH + DIGITS_GAP);
    ctx.drawImage(digitsImage, offset, 0, DIGIT_WIDTH, DIGIT_HEIGHT, x, y, DIGIT_WIDTH, DIGIT_HEIGHT);
  };

  drawHRulerBackground = (ctx, canvasWidth) => {
    // console.log('=== drawHRulerBackground()=');
    ctx.fillStyle = BACKGROUND_COLOR;
    ctx.fillRect(RULER_THICKNESS, 0, canvasWidth, RULER_THICKNESS);
  };

  drawHRulerBorder = (ctx, canvasWidth) => {
    ctx.fillStyle = STROKE_COLOR;
    ctx.fillRect(RULER_THICKNESS, RULER_THICKNESS - 1, canvasWidth, 1);
  };

  drawVRulerBackground = (ctx, canvasHeight) => {
    ctx.fillStyle = BACKGROUND_COLOR;
    ctx.fillRect(0, RULER_THICKNESS, RULER_THICKNESS, canvasHeight);
  };

  drawVRulerBorder = (ctx, canvasHeight) => {
    ctx.fillStyle = STROKE_COLOR;
    ctx.fillRect(RULER_THICKNESS - 1, RULER_THICKNESS, 1, canvasHeight);
  };

  drawHRulerTick = (ctx, coord, length) => {
    const x = toPixelCenter(coord);
    const y = RULER_THICKNESS - 1;

    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x, y - length);
    ctx.stroke();
  };

  drawHRulerLabel = (ctx, coord, value) => {
    const label = Math.abs(value).toFixed(0);

    const digits = label.split('');
    let x = Math.round(coord) + 3;
    const y = 3;
    for (let i = 0; i < digits.length; i++) {
      this.drawDigit(ctx, digits[i], x, y);
      x += DIGIT_WIDTH + 1;
    }
  };

  drawVRulerTick = (ctx, coord, length) => {
    const x = RULER_THICKNESS - 1;
    const y = toPixelCenter(coord);

    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x - length, y);
    ctx.stroke();
  };

  drawVRulerLabel = (ctx, coord, value) => {
    const label = Math.abs(value).toFixed(0);

    const digits = label.split('');
    const x = 3;
    let y = Math.round(coord) + 3;
    for (let i = 0; i < digits.length; i++) {
      this.drawDigit(ctx, digits[i], x, y);
      y += DIGIT_HEIGHT + 2;
    }
  };

  drawMarks = (ctx, canvasWidth, canvasHeight, origin) => {
    const { resolution: { xResolution, yResolution }, lengthUnit, zoom } = this.props;

    ctx.strokeStyle = STROKE_COLOR;
    ctx.fillStyle = STROKE_COLOR;

    const hrulerMarks = createRulerMarks({
      resolution: xResolution,
      lengthUnit,
      zoom,
      start: origin.x,
      rulerLength: canvasWidth
    });

    let ticks = hrulerMarks.ticks;
    let labels = hrulerMarks.labels;

    for (let i = 0; i < ticks.length; i++) {
      const { coord, tickLength } = ticks[i];
      this.drawHRulerTick(ctx, coord, tickLength);
    }

    for (let i = 0; i < labels.length; i++) {
      const { coord, value } = labels[i];
      this.drawHRulerLabel(ctx, coord, value);
    }

    const vrulerMarks = createRulerMarks({
      resolution: yResolution,
      lengthUnit,
      zoom,
      start: origin.y,
      rulerLength: canvasHeight
    });

    ticks = vrulerMarks.ticks;
    labels = vrulerMarks.labels;

    for (let i = 0; i < ticks.length; i++) {
      const { coord, tickLength } = ticks[i];
      this.drawVRulerTick(ctx, coord, tickLength);
    }

    for (let i = 0; i < labels.length; i++) {
      const { coord, value } = labels[i];
      this.drawVRulerLabel(ctx, coord, value);
    }
  };

  drawMousePosition = (ctx, canvasHelper) => {
    const { mouseMovePoint } = this.props;
    if (!mouseMovePoint) {
      return;
    }

    let { x, y } = canvasHelper.getCanvasByScreenPointFromTopLeft(mouseMovePoint);

    ctx.strokeStyle = MOUSE_MARK_COLOR;

    ctx.beginPath();

    x = toPixelCenter(x);
    ctx.moveTo(x, 0);
    ctx.lineTo(x, RULER_THICKNESS);

    y = toPixelCenter(y);
    ctx.moveTo(0, y);
    ctx.lineTo(RULER_THICKNESS, y);

    ctx.stroke();
  };

  drawCorner = (ctx) => {
    ctx.fillStyle = CORNER_COLOR;
    ctx.fillRect(0, 0, RULER_THICKNESS, RULER_THICKNESS);

    ctx.strokeStyle = STROKE_COLOR;
    ctx.setLineDash([1, 1]);

    ctx.beginPath();

    const d = 12.5;
    ctx.moveTo(d, 0);
    ctx.lineTo(d, RULER_THICKNESS);

    ctx.moveTo(0, d);
    ctx.lineTo(RULER_THICKNESS, d);

    ctx.stroke();

  };

  drawOriginLines = (ctx, canvasHelper) => {
    const { mouseDownPoint, mouseMovePoint } = this.props;
    const { canvasWidth, canvasHeight } = canvasHelper;

    if (!mouseDownPoint || !mouseMovePoint ||
      canvasHelper.detectCanvasArea(mouseDownPoint) !== CANVAS_AREA.RULER_CORNER ||
      canvasHelper.detectCanvasArea(mouseMovePoint) !== CANVAS_AREA.INSIDE) {
      return;
    }

    let { x, y } = canvasHelper.getCanvasByScreenPointFromTopLeft(mouseMovePoint);

    ctx.strokeStyle = STROKE_COLOR;
    ctx.setLineDash([1, 1]);

    ctx.beginPath();

    x = toPixelCenter(x);
    ctx.moveTo(x, RULER_THICKNESS);
    ctx.lineTo(x, canvasHeight);

    y = toPixelCenter(y);
    ctx.moveTo(RULER_THICKNESS, y);
    ctx.lineTo(canvasWidth, y);

    ctx.stroke();
  };

  draw = (ctx, canvasHelper) => {
    const { rulerOriginPoint, image } = this.props;
    if (!image) {
      return;
    }

    const domMatrix = ctx.getTransform();
    canvasHelper.setTransformMatrix(domMatrix);

    const origin = getRulerOrigin(rulerOriginPoint, image, domMatrix);
    // console.log('=== getRulerOrigin=', origin);

    ctx.save();

    // reset the current transformation to the identity matrix
    ctx.setTransform(1, 0, 0, 1, 0, 0);

    const { canvasWidth, canvasHeight } = canvasHelper;

    this.drawHRulerBackground(ctx, canvasWidth);
    this.drawHRulerBorder(ctx, canvasWidth);

    this.drawVRulerBackground(ctx, canvasHeight);
    this.drawVRulerBorder(ctx, canvasHeight);

    this.drawMarks(ctx, canvasWidth, canvasHeight, origin);

    this.drawMousePosition(ctx, canvasHelper);

    this.drawCorner(ctx);

    this.drawOriginLines(ctx, canvasHelper);

    ctx.restore();
  };
}