/**
 * @name cellGrid
 * @file Grid of cells defined by rows and columns
 *
 * @author Boris
 * @since: 2016-08-17
 */

import React from 'react';
import Fabric from 'fabric';
import settingsManager from 'core/managers/settings';
import sandbox from 'sandbox';
import SimpleForm from 'widgets/SimpleForm/src/index';
import TextInput from 'components/common/inputs/TextInput';
import utils from '../utils/utils';
import canvasUtils from '../utils/canvasUtils';
import propertiesCommon from './propertiesCommon';
import cellModule from './cell';
import actions from '../redux/actions';

const {translate} = sandbox.localization;
const futils = SimpleForm.utils;
const {Row, Label} = SimpleForm;

const BASE_PATH = 'layout.cellGrid';
const TITLE = translate('Cell Grid');

const GAP_INPUT_FONT_FAMILY = 'Verdana';
const GAP_INPUT_FONT_SIZE = 14;
const GAP_INPUT_FILL_COLOR = 'black';
const GAP_INPUT_BACKGROUND_COLOR = 'white';
const ROW_GAP_MARGIN_LEFT = 50;
const COLUMN_GAP_MARGIN_TOP = 20;
const EPS = 0.0001;

const FLOW_ORDER_TEXT_FILL_COLOR = '#333333';
const FLOW_ORDER_TEXT_STROKE = '#666666';
const FLOW_ORDER_TEXT_FILL_COLOR_GREYED = '#aaaaaa';
const FLOW_ORDER_TEXT_STROKE_GREYED = '#888888';
const FLOW_ORDER_TEXT_FONT_FAMILY = 'Verdana';
const FLOW_ORDER_TEXT_FONT_SIZE = 96;

const {CAPTION_COLUMNS} = propertiesCommon;

const _NAME = {
  key: '_name',
  caption: translate('Name'),
  type: 'string',
  disabled: true
};

const USE_GAPS = {
  key: 'useGaps',
  caption: translate('Uniform grid'),
  type: 'bool'
};

const ROWS = {
  key: 'rows',
  caption: translate('Rows'),
  type: 'options',
  options: [
    {value: 1, text: '1'},
    {value: 2, text: '2'},
    {value: 3, text: '3'},
    {value: 4, text: '4'},
    {value: 5, text: '5'},
    {value: 6, text: '6'},
    {value: 7, text: '7'},
    {value: 8, text: '8'}
  ]
};

const COLUMNS = {
  key: 'columns',
  caption: translate('Columns'),
  type: 'options',
  options: [
    {value: 1, text: '1'},
    {value: 2, text: '2'},
    {value: 3, text: '3'},
    {value: 4, text: '4'},
    {value: 5, text: '5'},
    {value: 6, text: '6'},
    {value: 7, text: '7'},
    {value: 8, text: '8'}
  ]
};

const CELL_WIDTH = {
  key: 'cellWidth',
  caption: translate('Cell width'),
  type: 'length'
};

const CELL_HEIGHT = {
  key: 'cellHeight',
  caption: translate('Cell height'),
  type: 'length'
};

const CROP_MODE = {
  key: 'cropMode',
  caption: translate('Crop mode'),
  type: 'options',
  options: [
    {value: 'cellBox', text: translate('Use cell box')},
    {value: 'imageTrimBox', text: translate('Use image trim box')}
  ]
};

const CELL_GRID_META = [
  _NAME,
  USE_GAPS,
  ROWS,
  COLUMNS,
  CELL_WIDTH,
  CELL_HEIGHT,
  CROP_MODE
];

export default (editor) => {

  const Cell = cellModule(editor);
  const canvas = editor.getMainCanvas();
  const cutils = canvasUtils(editor);

  const getMeta = (state, element) => {
    const custom = state.layout.role === 'custom' || state.layout.layoutGroup.impositionType === 'custom';

    ROWS.disabled = !element.useGaps || !custom;
    COLUMNS.disabled = !element.useGaps || !custom;
    CELL_WIDTH.disabled = !element.useGaps;
    CELL_HEIGHT.disabled = !element.useGaps;

    USE_GAPS.disabled = !canUseGaps(state, element);

    ROWS.hidden = USE_GAPS.disabled;
    COLUMNS.hidden = USE_GAPS.disabled;
    CELL_WIDTH.hidden = USE_GAPS.disabled;
    CELL_HEIGHT.hidden = USE_GAPS.disabled;

    if (useCellBoxOnly(state, element)) {
      CROP_MODE.disabled = true;
    }

    return CELL_GRID_META;
  };

  const formatCellGridName = (element) => {
    var result = translate('Cell Grid');
    if (typeof element.rows !== 'undefined' && typeof element.columns !== 'undefined') {
      result += ' (' + +element.rows + ' x ' + element.columns + ')';
    }

    return result;
  };

  const canUseGaps = (state, element) => {
    var result = false;
    if (element.rows > 0 && element.columns > 0 && element.rowGaps && element.columnGaps) {
      result = true;
    }

    return result;
  };

  const useCellBoxOnly = (state, element) => {
    return settingsManager.get('resourceFeatures').pdfImposeAlgorithmVersion === 1;
  };

  const buildDefaultElement = (state, elementType) => {
    const element = {
      elementType: elementType,
      locked: false
    };

    setDefaultElementValues(state, element);

    return element;
  };

  const mergePagesDataToCells = (state, element) => {
    var pages = state.layout.pages;
    if (!pages) {
      return;
    }

    for (var key in pages) {
      if (key !== 'otype') {
        var page = pages[key];
        var cell = element.cells[page.index];
        if (cell) {
          cell._contentNwid = page.contentNwid;
          cell.imageWidth = page.imageWidth;
          cell.imageHeight = page.imageHeight;
          cell.imageTrimX = 0;
          cell.imageTrimY = 0;
          cell.imageTrimWidth = page.imageWidth;
          cell.imageTrimHeight = page.imageHeight;
          if (page.trimBox) {
            cell.imageTrimX = page.trimBox.left;
            cell.imageTrimY = page.trimBox.top;
            cell.imageTrimWidth = page.trimBox.width;
            cell.imageTrimHeight = page.trimBox.height;
          }

          if (page.overrides && !isNaN(page.overrides.orientation)) {
            cell.rotation = page.overrides.orientation;
          }
        }
      }
    }
  };

  const setDefaultCellsValues = (state, element) => {
    for (var key in element.cells) {
      if (key !== 'otype') {
        var cell = element.cells[key];
        Cell.setDefaultElementValues(state, cell);
      }
    }
  };

  const initFlowOderMatrix = (state, element) => {
    element._flowOrderMatrix = [];
    for (var key in element.cells) {
      if (key !== 'otype') {
        var cell = element.cells[key];
        element._flowOrderMatrix.push(cell.flowOrder);
      }
    }
  };

  const setDefaultElementValues = (state, element, elementPath) => {
    propertiesCommon.setDefaultValues(state, element, getMeta(state, element));

    element._name = formatCellGridName(element);
    if (!canUseGaps(state, element)) {
      element.useGaps = false;
    }

    if (useCellBoxOnly(state, element)) {
      element.cropMode = 'cellBox';
    }

    adjustLastRowGapValue(state, element);
    adjustLastColumnGapValue(state, element);

    setDefaultCellsValues(state, element);
    initFlowOderMatrix(state, element);

    mergePagesDataToCells(state, element);

    assignCellsGroupChildren(state, element, elementPath)
  };

  const createCells = (state, element) => {
    var result = utils.createPseudoArray();
    for (var i = 0; i < element.rows; i++) {
      for (var j = 0; j < element.columns; j++) {
        var cell = Cell.buildDefaultElement(state, 'cell');
        cell.row = i;
        cell.column = j;
        cell.width = element.cellWidth;
        cell.height = element.cellHeight;
        Cell.setDefaultElementValues(state, cell); //TODO: think about better place
        result[i * element.columns + j] = cell;
      }
    }

    return result;
  };

  const createRowGaps = (state, element) => {
    var plateHeight = utils.canvasToSystemUnits(state.plateRectangle.height);
    var gaps = calcInitialGapValues(element.rows, element.cellHeight, plateHeight);
    return utils.toPseudoArray(gaps);
  };

  const createColumnGaps = (state, element) => {
    var plateWidth = utils.canvasToSystemUnits(state.plateRectangle.width);
    var gaps = calcInitialGapValues(element.columns, element.cellWidth, plateWidth);
    return utils.toPseudoArray(gaps);
  };

  const createChildren = (state, element) => {
    var rowGaps = createRowGaps(state, element);
    var columnGaps = createColumnGaps(state, element);
    var cells = createCells(state, element);
    var newElement = {...element, rowGaps, columnGaps, cells};
    newElement = updateCells(state, newElement);
    return newElement;
  };

  const createCellsGroupChildren = (element, elementPath) => {
    var children = [];
    for (var key in element.cells) {
      if (key !== 'otype') {
        var cell = element.cells[key];
        if (!cell.isSkipped) {
          var cellPath = futils.compose(elementPath, 'cells', key);
          children.push({
            elementPath: cellPath
          });
        }
      }
    }

    return children;
  };

  const assignCellsGroupChildren = (state, element, elementPath) => {
    if (!elementPath) {
      return;
    }

    var group = state.elementGroups.find(g => g.elementType === 'cell');
    if (!group) {
      return;
    }

    group.children = createCellsGroupChildren(element, elementPath);
  };

  const updateCellsGroup = (state, element, elementPath) => {
    var idx = state.elementGroups.findIndex(g => g.elementType === 'cell');
    if (idx < 0) {
      return state;
    }

    var children = createCellsGroupChildren(element, elementPath);
    var childrenPath = futils.compose('elementGroups', idx, 'children');
    var newState = futils.update(state, childrenPath, children);

    return newState;
  };

  const updateCellsCropMode = (state, element) => {
    let newElement = {...element, cells: {...element.cells}};

    for (let k in newElement.cells) {
      if (k !== 'otype') {
        let cell = newElement.cells[k];
        newElement.cells[k] = Cell.updateCropMode(state, cell);
      }
    }

    return newElement;
  };

  const calcInitialGapValues = (itemCount, itemLength, totalLength) => {
    if (itemCount <= 0) {
      return [];
    }

    var gaps = [];
    gaps.length = itemCount + 1;
    gaps.fill(0);
    var margin = (totalLength - itemLength * itemCount) / 2;
    gaps[0] = margin;
    gaps[gaps.length - 1] = margin;

    return gaps;
  };

  const resetGapValues = (gaps, itemLength, totalLength) => {
    if (gaps.length <= 1) {
      return gaps;
    }

    gaps.fill(0);
    var margin = (totalLength - itemLength * (gaps.length - 1)) / 2;
    gaps[0] = margin;
    gaps[gaps.length - 1] = margin;
  };

  const resetRowGapValues = (state, element) => {
    var plateHeight = utils.canvasToSystemUnits(state.plateRectangle.height);
    var rowGaps = utils.fromPseudoArray(element.rowGaps);
    resetGapValues(rowGaps, element.cellHeight, plateHeight);
  };

  const resetColumnGapValues = (state, element) => {
    var plateWidth = utils.canvasToSystemUnits(state.plateRectangle.width);
    var columnGaps = utils.fromPseudoArray(element.columnGaps);
    resetGapValues(columnGaps, element.cellWidth, plateWidth);
  };

  const updateProperty = (state, element, elementPath, propertyPath, propertyValue) => {
    let newState = state;
    let newElement = element;
    const key = futils.pathTail(propertyPath);
    const fullKey = propertyPath.substring(elementPath.length + 1);
    if (fullKey.startsWith('cells')) {
      // cell property changed
      if (key === 'rowSpan' || key === 'columnSpan') {
        let cell = futils.get(newState, futils.pathNoTail(propertyPath));
        newElement = updateCellAfterSpanChanged(newState, newElement, cell, key);
        newState = futils.update(newState, elementPath, newElement);
        newState = updateCellsGroup(newState, newElement, elementPath);
      } else if (key === 'rotation') {
        // nothing to do, only update the flow order number rotation
      }
    } else {
      newState = propertiesCommon.updateProperty(newState, newElement, elementPath, propertyPath, propertyValue);
      newElement = futils.get(newState, elementPath);

      if (!isNaN(key) && futils.pathTail(futils.pathNoTail(propertyPath)) === '_flowOrderMatrix') {
        newElement = updateCellFlowOrder(newState, newElement, Number(key));
        newState = futils.update(newState, elementPath, newElement);
      }

      if (newElement.useGaps) {
        if (key === 'rows' || key === 'columns') {
          removeShape(newState, newElement);
          const cellWidth = calcDefaultCellWidth(newState, newElement);
          const cellHeight = calcDefaultCellHeight(newState, newElement);
          newElement = {...newElement, cellWidth, cellHeight};
          newElement = createChildren(newState, newElement);
          newElement._name = formatCellGridName(newElement);
          newState = futils.update(newState, elementPath, newElement);
          initFlowOderMatrix(newState, newElement);
          newState = updateCellsGroup(newState, newElement, elementPath);
          createShape(newState, newElement, elementPath);
          newState.repopulate = true;
        } else if (key === 'cellWidth' || key === 'cellHeight' || key === 'useGaps') {
          if (Math.abs(newElement.cellWidth - element.cellWidth) >= EPS ||
            Math.abs(newElement.cellHeight - element.cellHeight) >= EPS) {
            const rowGaps = createRowGaps(newState, newElement);
            const columnGaps = createColumnGaps(newState, newElement);
            newElement = {...newElement, rowGaps, columnGaps};
          }
          newElement = updateCells(newState, newElement);
          newState = futils.update(newState, elementPath, newElement);
        }
      }

      if (key === 'cropMode') {
        newElement = updateCellsCropMode(newState, newElement);
        newState = futils.update(newState, elementPath, newElement);
      }
    }

    updateShape(newState, newElement);

    return newState;
  };

  const updateCellFlowOrder = (state, element, index) => {
    const path = futils.compose('cells', index, 'flowOrder');
    const newElement = futils.update(element, path, element._flowOrderMatrix[index]);

    return newElement;
  };

  const isIText = (canvasShape) => {
    return canvasShape && canvasShape.type === 'i-text';
  };

  const findIndexOfGapInput = (gapInputs, canvasShape) => {
    if (!isIText(canvasShape)) {
      return -1;
    }

    var index = -1;
    for (var i = 0; i < gapInputs.length; i++) {
      if (gapInputs[i] === canvasShape) {
        index = i;
        break;
      }
    }

    return index;
  };

  const findIndexOfRowGapInput = (element, canvasShape) => {
    return findIndexOfGapInput(element.shape.rowGapInputs || [], canvasShape);
  };

  const findIndexOfColumnGapInput = (element, canvasShape) => {
    return findIndexOfGapInput(element.shape.columnGapInputs || [], canvasShape);
  };

  const isGapInput = (element, canvasShape) => {
    return findIndexOfRowGapInput(element, canvasShape) >= 0 || findIndexOfColumnGapInput(element, canvasShape) >= 0;
  };

  const shapeMouseUp = (state, element, canvasShape, event) => {
    if (!isGapInput(element, canvasShape)) {
      return element;
    }

    var newElement = element;

    if (!canvasShape.isEditing) {
      canvasShape.enterEditing();
      canvasShape.selectAll();
    }

    return newElement;
  };

  const exitGapInputEditingOnEnter = (gapInput) => {
    var enterPressed = utils.containsLineBreak(gapInput.text);
    var text = utils.removeLineBreaks(gapInput.text).trim();
    gapInput.text = text === '' ? '  ' : text;
    if (enterPressed) {
      gapInput.exitEditing();
    }

    return enterPressed;
  };

  const recalcGapValues = (gaps, itemLength, totalLength, gapIndex, gapValue) => {
    if (gaps.length <= 1) {
      return gaps;
    }

    gaps[gapIndex] = gapValue;
    var gapSum = gaps.reduce((sum, gap) => {
      return sum + gap;
    }, 0);

    var delta = totalLength - itemLength * (gaps.length - 1) - gapSum;
    if (gapIndex === 0) {
      gaps[gaps.length - 1] += delta;
    } else if (gapIndex === gaps.length - 1) {
      gaps[0] += delta;
    } else {
      delta = delta / 2;
      gaps[gaps.length - 1] += delta;
      gaps[0] += delta;
    }
  };

  const adjustLastRowGapValue = (state, element) => {
    if (!element.useGaps || !state.plateRectangle.height) {
      return;
    }

    var plateHeight = utils.canvasToSystemUnits(state.plateRectangle.height);
    var rowGaps = utils.fromPseudoArray(element.rowGaps);
    recalcGapValues(rowGaps, element.cellHeight, plateHeight, 0, rowGaps[0]);
    var lastIndex = rowGaps.length - 1;
    element.rowGaps[lastIndex] = rowGaps[lastIndex];
  };

  const adjustLastColumnGapValue = (state, element) => {
    if (!element.useGaps || !state.plateRectangle.width) {
      return;
    }

    var plateWidth = utils.canvasToSystemUnits(state.plateRectangle.width);
    var columnGaps = utils.fromPseudoArray(element.columnGaps);
    recalcGapValues(columnGaps, element.cellWidth, plateWidth, 0, columnGaps[0]);
    var lastIndex = columnGaps.length - 1;
    element.columnGaps[lastIndex] = columnGaps[lastIndex];
  };

  const updateRowGapValues = (state, element, rowGapIndex, value) => {
    let newElement = element;
    if (element.rowGaps[rowGapIndex] !== value) {
      const plateHeight = utils.canvasToSystemUnits(state.plateRectangle.height);
      const rowGaps = utils.fromPseudoArray(element.rowGaps);
      recalcGapValues(rowGaps, element.cellHeight, plateHeight, rowGapIndex, value);
      newElement = {...element, rowGaps: utils.toPseudoArray(rowGaps)};
    }

    return newElement;
  };

  const updateColumnGapValues = (state, element, columnGapIndex, value) => {
    let newElement = element;
    if (element.columnGaps[columnGapIndex] !== value) {
      const plateWidth = utils.canvasToSystemUnits(state.plateRectangle.width);
      const columnGaps = utils.fromPseudoArray(element.columnGaps);
      recalcGapValues(columnGaps, element.cellWidth, plateWidth, columnGapIndex, value);
      newElement = {...element, columnGaps: utils.toPseudoArray(columnGaps)};
    }

    return newElement;
  };

  const gapInputTextToValue = (gapInput) => {
    var result = utils.userToSystemUnits(utils.stringToFloat(gapInput.text));
    return result;
  };

  const shapeTextEvent = (state, element, canvasShape, eventName) => {
    if (!isGapInput(element, canvasShape)) {
      return element;
    }

    let newElement = element;
    if (eventName === 'text:changed') {
      exitGapInputEditingOnEnter(canvasShape);
    } else if (eventName === 'text:editing:exited') {
      canvasShape.hoverCursor = 'text'; // restore hoverCursor
      const rowGapIndex = findIndexOfRowGapInput(element, canvasShape);
      const columnGapIndex = rowGapIndex >= 0 ? -1 : findIndexOfColumnGapInput(element, canvasShape);
      if (rowGapIndex >= 0) {
        const rowGapInput = element.shape.rowGapInputs[rowGapIndex];
        const rowGapValue = gapInputTextToValue(rowGapInput);
        newElement = updateRowGapValues(state, element, rowGapIndex, rowGapValue);
      } else if (columnGapIndex >= 0) {
        const columnGapInput = element.shape.columnGapInputs[columnGapIndex];
        const columnGapValue = gapInputTextToValue(columnGapInput);
        newElement = updateColumnGapValues(state, element, columnGapIndex, columnGapValue);
      }

      if (newElement !== element) {
        newElement = updateCells(state, newElement);
        updateShape(state, newElement);
      }
    }

    return newElement;
  };

  const createGapInput = (element) => {
    var result = new Fabric.IText('', {
      originX: 'center',
      originY: 'center',
      fontFamily: GAP_INPUT_FONT_FAMILY,
      fontSize: GAP_INPUT_FONT_SIZE,
      fill: GAP_INPUT_FILL_COLOR,
      textBackgroundColor: GAP_INPUT_BACKGROUND_COLOR,
      hasRotatingPoint: false,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
      hoverCursor: 'text',
      visible: false
    });

    return result;
  };

  const createRowGapInputs = (state, element) => {
    if (!canUseGaps(state, element)) {
      return [];
    }

    var result = [];
    for (var i = 0; i <= element.rows; i++) {
      var gapInput = createGapInput(element);
      gapInput.shape = element.shape;
      result.push(gapInput);
    }

    return result;
  };

  const createColumnGapInputs = (state, element) => {
    if (!canUseGaps(state, element)) {
      return [];
    }

    var result = [];
    for (var i = 0; i <= element.columns; i++) {
      var gapInput = createGapInput(element);
      gapInput.shape = element.shape;
      result.push(gapInput);
    }

    return result;
  };

  const createFlowOrderText = (state, element) => {
    const flowOrderText = new Fabric.Text('', {
      fontSize: FLOW_ORDER_TEXT_FONT_SIZE,
      textDecoration: 'underline',
      fontFamily: FLOW_ORDER_TEXT_FONT_FAMILY,
      fill: FLOW_ORDER_TEXT_FILL_COLOR,
      //stroke: FLOW_ORDER_TEXT_STROKE,
      //strokeWidth: 1,
      originX: 'center',
      originY: 'center',
      selectable: false,
      evented: false,
      visible: false
    });

    return flowOrderText;
  };

  const createFlowOrderTexts = (state, element) => {
    const result = [];
    element._flowOrderMatrix.forEach(() => {
      result.push(createFlowOrderText(state, element));
    });

    return result;
  };

  const createShape = (state, element, elementPath) => {
    const shape = {elementPath};
    element.shape = shape;
    shape.rowGapInputs = createRowGapInputs(state, element);
    shape.columnGapInputs = createColumnGapInputs(state, element);
    shape.flowOrderTexts = createFlowOrderTexts(state, element);

    for (var key in element.cells) {
      if (key !== 'otype') {
        var cell = element.cells[key];
        var cellPath = futils.compose(elementPath, 'cells', key);
        Cell.createShape(state, cell, cellPath);
      }
    }

    updateShape(state, element);

    addShape(state, element);

    return shape;
  };

  const setGapInputValue = (gapInput, value) => {
    gapInput.set('text', utils.systemToUserUnits(value).toString());
  };

  const calcGapInputFontSize = (zoom) => {
    return GAP_INPUT_FONT_SIZE / zoom;
  };

  const updateRowGapInputs = (state, element) => {
    var inputs = element.shape.rowGapInputs;
    if (inputs.length <= 0) {
      return;
    }

    inputs.forEach(input => input.visible = element.useGaps);
    if (!element.useGaps) {
      return;
    }

    var fontSize = calcGapInputFontSize(canvas.getZoom());
    var cellHeight = utils.systemToCanvasUnits(element.cellHeight);
    var x = state.plateRectangle.left - ROW_GAP_MARGIN_LEFT;
    var y1 = state.plateRectangle.top;
    for (var i = 0; i < inputs.length; i++) {
      var input = inputs[i];
      input.set('fontSize', fontSize);
      var value = element.rowGaps[i];
      setGapInputValue(input, value);
      var y2 = y1 + utils.systemToCanvasUnits(value);
      var hw = input.width / 2;
      var left = x - hw <= 0 ? hw + 1 : x;
      var top = y1 + (y2 - y1) / 2;
      y1 = y2 + cellHeight;
      input.set({
        left,
        top
      });

      input.setCoords();
    }
  };

  const updateColumnGapInputs = (state, element) => {
    var inputs = element.shape.columnGapInputs;
    if (inputs.length <= 0) {
      return;
    }

    inputs.forEach(input => input.visible = element.useGaps);
    if (!element.useGaps) {
      return;
    }

    var fontSize = calcGapInputFontSize(canvas.getZoom());
    var cellWidth = utils.systemToCanvasUnits(element.cellWidth);
    var x1 = state.plateRectangle.left;
    var y = state.plateRectangle.top - COLUMN_GAP_MARGIN_TOP;
    for (var i = 0; i < inputs.length; i++) {
      var input = inputs[i];
      input.set('fontSize', fontSize);
      var value = element.columnGaps[i];
      setGapInputValue(input, value);
      var x2 = x1 + utils.systemToCanvasUnits(value);
      var hh = input.height / 2;
      var left = x1 + (x2 - x1) / 2;
      var top = y - hh < 0 ? hh : y;
      x1 = x2 + cellWidth;
      input.set({
        left,
        top
      });

      input.setCoords();
    }
  };

  const calcRegularCellRectangle = (state, element, cell) => {
    let i;
    let x = element.columnGaps[0] || 0;
    for (i = 1; i <= cell.column; i++) {
      x += element.cellWidth + element.columnGaps[i];
    }

    let y = element.rowGaps[0] || 0;
    for (i = 1; i <= cell.row; i++) {
      y += element.cellHeight + element.rowGaps[i];
    }

    return utils.toCanvasRectangle(state.plateRectangle, x, y, element.cellWidth, element.cellHeight);
  };

  const updateFlowOrderTexts = (state, element) => {
    for (var i = 0; i < element.shape.flowOrderTexts.length; i++) {
      var fo = element._flowOrderMatrix[i];
      var text = element.shape.flowOrderTexts[i];
      var cell = element.cells[i];
      text.visible = fo > 0 || cell.rotation !== 0;
      if (text.visible) {
        var r = calcRegularCellRectangle(state, element, cell);
        text.set({
          text: (cell.flowOrder || 'R').toString(),
          left: r.left + r.width / 2,
          top: r.top + r.height / 2,
          angle: cell.rotation,
          fill: cell.isSkipped ? FLOW_ORDER_TEXT_FILL_COLOR_GREYED : FLOW_ORDER_TEXT_FILL_COLOR,
          //stroke: cell.isSkipped ? FLOW_ORDER_TEXT_STROKE_GREYED : FLOW_ORDER_TEXT_STROKE,
          textDecoration: cell.flowOrder > 0 ? 'underline' : ''
        });
      }
    }
  };

  const unmarkSkippedCells = (cells) => {
    for (var k in cells) {
      if (k !== 'otype') {
        cells[k].isSkipped = false;
      }
    }
  };

  const markCoveredCellsAsSkipped = (cells, rowCount, columnCount) => {
    unmarkSkippedCells(cells);

    for (var k in cells) {
      if (k !== 'otype' && !cells[k].isSkipped) {
        var rowSpan = cells[k].rowSpan;
        var columnSpan = cells[k].columnSpan;
        if (rowSpan > 1 || columnSpan > 1) {
          var row = cells[k].row;
          var column = cells[k].column;
          for (var i = row; i < row + rowSpan && i < rowCount; i++) {
            for (var j = column; j < column + columnSpan && j < columnCount; j++) {
              if (!(i == row && j == column)) {
                cells[i * columnCount + j].isSkipped = true;
              }
            }
          }
        }
      }
    }
  };

  const calcDefaultCellWidth = (state, element) => {
    const plateWidth = utils.canvasToSystemUnits(state.plateRectangle.width);

    return Math.max((plateWidth - 2) / element.columns, 1);
  };

  const calcDefaultCellHeight = (state, element) => {
    const plateHeight = utils.canvasToSystemUnits(state.plateRectangle.height);

    return Math.max((plateHeight - 2) / element.rows, 1);
  };

  const calcCellWidth = (element, cell) => {
    var result = element.cellWidth;
    for (var i = cell.column + 1; i < cell.column + cell.columnSpan && i < element.columns; i++) {
      result += element.cellWidth + element.columnGaps[i];
    }

    return result;
  };

  const calcCellHeight = (element, cell) => {
    var result = element.cellHeight;
    for (var i = cell.row + 1; i < cell.row + cell.rowSpan && i < element.rows; i++) {
      result += element.cellHeight + element.rowGaps[i];
    }

    return result;
  };

  const updateCells = (state, element) => {
    var cells = utils.clonePseudoArrayItems(element.cells);
    markCoveredCellsAsSkipped(cells, element.rows, element.columns);

    var y = 0;
    var k = 0;
    for (var i = 0; i < element.rows; i++) {
      y += element.rowGaps[i];
      var x = 0;
      for (var j = 0; j < element.columns; j++) {
        x += element.columnGaps[j];
        var cell = cells[k];
        if (cell.row == i && cell.column == j) {
          //console.log("updateCells -> *cell=" + i + "," + j + ", cell.isSkipped=" + cell.isSkipped);
          k++;
          var width = calcCellWidth(element, cell);
          var height = calcCellHeight(element, cell);
          cell.x = x;
          cell.y = y;
          cell.width = width;
          cell.height = height;
          //console.log("updateCells -> cell=" + i + "," + j + ", x=" + x + ", y=" + y + ", width=" + width + ", height=" + height);
        }

        x += element.cellWidth;
      }
      y += element.cellHeight;
    }

    var newElement = {...element, cells};
    return newElement;
  };

  const updateCellAfterSpanChanged = (state, element, cell, spanProperty) => {
    const cells = utils.clonePseudoArrayItems(element.cells);
    markCoveredCellsAsSkipped(cells, element.rows, element.columns);

    let k = cell.row * element.columns + cell.column;
    if (spanProperty === 'rowSpan') {
      cells[k].height = calcCellHeight(element, cell);
    } else if (spanProperty === 'columnSpan') {
      cells[k].width = calcCellWidth(element, cell);
    }

    const newElement = {...element, cells};

    return newElement;
  };

  const updateShape = (state, element) => {
    updateRowGapInputs(state, element);
    updateColumnGapInputs(state, element);
    updateFlowOrderTexts(state, element);

    for (var key in element.cells) {
      if (key !== 'otype') {
        Cell.updateShape(state, element.cells[key]);
      }
    }
  };

  const addFlowOrderTexts = (texts) => {
    texts.forEach(text => canvas.add(text));
  };

  const addGapInputs = (inputs) => {
    inputs.forEach(input => canvas.add(input));
  };

  const addShape = (state, element) => {
    if (!element || !element.shape) {
      return;
    }

    for (var key in element.cells) {
      if (key !== 'otype') {
        Cell.addShape(state, element.cells[key]);
      }
    }

    var shape = element.shape;
    addFlowOrderTexts(shape.flowOrderTexts);
    addGapInputs(shape.rowGapInputs);
    addGapInputs(shape.columnGapInputs);
  };


  const removeGapInputs = (inputs) => {
    inputs.forEach(input => canvas.remove(input));
  };


  const removeShape = (state, element) => {
    if (!element || !element.shape) {
      return;
    }

    var shape = element.shape;
    removeGapInputs(shape.rowGapInputs);
    removeGapInputs(shape.columnGapInputs);

    for (var key in element.cells) {
      if (key !== 'otype') {
        Cell.removeShape(state, element.cells[key]);
      }
    }
  };

  const activateShape = (state, element) => {
    cutils.setActiveObject(null);
  };

  const handleFlowOrderChange = (e, value, store, element, elementPath, index) => {
    const flowOrder = Number(value) || 0;
    if (flowOrder !== element._flowOrderMatrix[index]) {
      const path = futils.compose(elementPath, '_flowOrderMatrix', index);
      store.dispatch(actions.update(path, flowOrder));
    }
  };

  const renderFlowOrderRow = (store, element, elementPath, row) => {
    const locked = element.locked;
    const tableCells = [];
    for (let j = 0; j < element.columns; j++) {
      let index = row * element.columns + j;
      let flowOrder = element._flowOrderMatrix[index] || '';
      tableCells.push(
        <td key={j}>
          <TextInput className='flow-order-table-input'
                     value={flowOrder}
                     disabled={locked}
                     onChange={(e, value) => handleFlowOrderChange(e, value, store, element, elementPath, index)}
          />
        </td>
      );
    }

    return (
      <tr key={row} className='flow-order-table-row'>
        {tableCells}
      </tr>
    );
  };

  const renderFlowOrderTable = (store, element, elementPath) => {
    const rows = [];
    for (var i = 0; i < element.rows; i++) {
      rows.push(renderFlowOrderRow(store, element, elementPath, i));
    }

    return (
      <table className='flow-order-table'>
        <tbody>
        {rows}
        </tbody>
      </table>
    );
  };

  const renderCellsFlowOrder = (store, element, elementPath) => {
    return (
      <div>
        <Row>
          <Label col={CAPTION_COLUMNS}>{'Cells Flow Order'}</Label>
          <div>
            {renderFlowOrderTable(store, element, elementPath)}
          </div>
        </Row>
      </div>
    );
  };

  const renderProperties = (store, element, elementPath) => {
    const state = store.getState();

    return (
      <div>
        {propertiesCommon.renderProperties(store, element, elementPath, getMeta(state, element))}
        {renderCellsFlowOrder(store, element, elementPath)}
      </div>
    );

  };

  const getInfo = (state) => {
    return {
      basePath: BASE_PATH,
      title: TITLE
    };
  };

  const validate = (state, element) => {
    let msg = '';
    const fom = element._flowOrderMatrix;
    if (fom.some(i => i > 0)) {
      if (fom.some(i => i <= 0)) {
        msg = translate('Cell Grid: for some cells flow order is not defined.');
      } else if (fom.some(i => i > fom.length)) {
        msg = translate('Cell Grid: cell flow order cannot exceed the total number of cells.');
      } else {
        const nums = new Array(fom.length).fill(-1);
        fom.forEach((i, index) => {
          if (i > 0 && i <= fom.length) {
            nums[i - 1] = index;
          }
        });
        if (nums.some(i => i < 0)) {
          msg = translate('Cell Grid: found cells with the same flow order.');
        }
      }
    }

    return msg;
  };

  return {
    buildDefaultElement,
    setDefaultElementValues,
    updateProperty,
    shapeMouseUp,
    shapeTextEvent,
    createShape,
    updateShape,
    addShape,
    removeShape,
    activateShape,
    renderProperties,
    getInfo,
    validate
  }
};
