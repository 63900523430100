import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/common/loader/Loader';
import BreadcrumbsList from './breadcrumbsList';
import BreadcrumbsLink from './BreadcrumbsLink';

module.exports = class Breadcrumbs extends Component {
  static propTypes = {
    cursor: PropTypes.array,
    onClick: PropTypes.func,
    loading: PropTypes.bool
  };

  constructor(props) {
    super(props);
  }


  handleClick = (event, item, index) => {
    const cursor = this.props.cursor;
    if (item !== cursor[cursor.length - 1]) {
      this.props.onClick(event, item, 'remove', index);
    }
  };

  createBreadcrumbsLink = (item, index) => {
    const cursor = this.props.cursor;
    const cursorLength = cursor.length;
    const showArrow = index === cursorLength - 1 ? false : true;
    return <BreadcrumbsLink key={index} item={item} index={index} onClick={this.handleClick} showArrow={showArrow} />;
  };

  render() {
    const cursor = this.props.cursor;
    const loading = this.props.loading;
    const title = cursor.map(function (item) {
      return item.displayName; 
    }).join('/');

    return <div className='crtx-breadcrumbs' title={title}>
      <BreadcrumbsList cursor={cursor} onClick={this.handleClick} />
      <div className='crtx-separator' ></div>
      <div className='crtx-breadcrumbs-links'>
        <div className='crtx-inner-breadcrumbs-links'>
          <div className='crtx-text-breadcrumbs-links'>
            {cursor.map(this.createBreadcrumbsLink)}
          </div>
        </div>
      </div>
      <Loader loading={loading} />
    </div>;
  }
};