import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SimpleFormComponent from '../SimpleFormComponent';
import utils from '../utils';
import actions from '../actions';
import BrowserInput from 'components/common/inputs/BrowserInput';

export default SimpleFormComponent(class extends Component {
  static propTypes = {
    store: PropTypes.object,
    bind: PropTypes.string,
    liveUpdate: PropTypes.bool,
    actionType: PropTypes.string,
    root: PropTypes.string,
    restFileSystem: PropTypes.func,
    separator: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    bind: '',
    liveUpdate: false,
    actionType: 'UPDATE',
    root: '',
    separator: "\\",
    title: 'Browse',
    disabled: false
  };

  constructor(props, context) {
    super(props, context);
    const value = this.getStoreValue(props);

    this.state = {
      value
    };
  }

  getStoreValue = (props) => {
    var { store, bind } = props;

    return store.get(bind);
  };

  dispatch = (event, value) => {
    var { store, bind, onChange, actionType } = this.props;

    if (utils.isFunction(onChange)) {
      onChange(event, bind, value);
    } else {
      store.dispatch(actions.update(bind, value, actionType));
    }
  };

  handleChange = (event, value) => {
    const { store, bind, onChange, actionType, liveUpdate } = this.props;
    this.setState({ value: value });

    if (liveUpdate) {
      this.dispatch(event, value);
    }
  };

  handleSelect = (event, value) => {
    this.setState({ value: value });
    this.dispatch(event, value);
  };

  handleBlur = () => {
    const value = this.state.value;

    this.setState({ value: value });
    this.dispatch(event, value);
  };

  componentWillReceiveProps(nextProps) {
    var value = this.state.value;
    var storeValue = this.getStoreValue(nextProps);

    if (storeValue !== value) {
      this.setState({
        value: storeValue
      });
    }
  }

  render() {
    const value = this.state.value;
    const { className, restFileSystem, root, style, disabled } = this.props;

    return <BrowserInput className={className} value={value} onChange={this.handleChange} onBlur={this.handleBlur}
      onSelect={this.handleSelect} restFileSystem={restFileSystem} root={root} style={style} disabled={disabled} />
  }
});
