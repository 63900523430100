/**
 * @name rulerGuides
 * @file Draw ruler guides depending on mouse position
 *
 * @author Boris
 * @since: 2016-09-29
 */

import Fabric from 'fabric';

const RULER_HEIGHT = 18; // pixels
const RULER_BAR_COLOR = 'red';

export default (editor) => {

  const canvas = editor.getMainCanvas();

  var hRulerBar;
  var vRulerBar;

  const createRulerBar = () => {
    return new Fabric.Line(null, {
      stroke: RULER_BAR_COLOR,
      selectable: false,
      evented: false,
      visible: false
    });
  };

  const createRulerBarsIfNeeded = (state) => {
    if (!hRulerBar) {
      hRulerBar = createRulerBar();
      canvas.add(hRulerBar);
    }

    if (!vRulerBar) {
      vRulerBar = createRulerBar();
      canvas.add(vRulerBar);
    }
  };

  const hideHRulerBar = () => {
    if (hRulerBar) {
      hRulerBar.visible = false;
    }
  };

  const hideVRulerBar = () => {
    if (vRulerBar) {
      vRulerBar.visible = false;
    }
  };

  const drawHRulerBar = (state, x) => {
    var zoom = canvas.getZoom();
    var strokeWidth = 1 / zoom;
    var y2 = RULER_HEIGHT / zoom;

    hRulerBar.set({
      x1: x,
      y1: 0,
      x2: x,
      y2: y2,
      strokeWidth: strokeWidth,
      visible: true
    });
  };

  const drawVRulerBar = (state, y) => {
    var zoom = canvas.getZoom();
    var strokeWidth = 1 / zoom;
    var x2 = RULER_HEIGHT / zoom;

    vRulerBar.set({
      x1: 0,
      y1: y,
      x2: x2,
      y2: y,
      strokeWidth: strokeWidth,
      visible: true
    });
  };

  const drawRulerBars = (state, event) => {
    createRulerBarsIfNeeded(state);

    var p = canvas.getPointer(event);
    drawHRulerBar(state, p.x);
    drawVRulerBar(state, p.y);
  };

  return {
    drawRulerBars
  }
};
