/**
 * Created with JetBrains WebStorm.
 * User: elad
 * Date: 3/25/14
 * Time: 10:02 AM
 * To change this template use File | Settings | File Templates.
 */
define(['ember', 'sandbox', 'text!./validatedInputTemplate.hbs'], function ( Ember, sandbox, Template) {
  'use strict';

  function checkIfValid (element) {
    var ret = true;
    if(!checkRequiredValidation.call(this)) {
      ret = false;
    }
    if (!this.required && (this.value === "" || typeof  this.value === "undefined")) {
      return true;
    }
    if (ret) {
      var dataType = this.dataType;
      if (typeof dataType !== "undefined") {
        switch (dataType.toLowerCase()) {
          case "alpha":
            ret = checkAlphaValidation.call(this);
            break;
          case "numeric":
            ret = checkNumericValidation.call(this);
            break;
          case "numericpositive":
            ret = checkNumericPositiveValidation.call(this);
            break;
          case "integer":
            ret = checkIntegerValidation.call(this);
            break;
          case "integerpositive":
            ret = checkIntegerPositiveValidation.call(this);
            break;
          case "password":
            ret = checkPasswordValidation.call(this);
            break;
          case "passwordconfirmation":
            ret = checkPasswordConfirmationValidation.call(this);
            break;
          case "email":
            ret = checkEMailValidation.call(this);
            break;
        }
      }
    }
    if (ret) {
      ret = checkExcludeCharactersValidation.call(this);
    }
    if (ret) {
      if (typeof this.businessLogicValidation !== "undefined") {
        var businnesret = this.businessLogicValidation.call(this.get('viewContext'), this.value);
        if (businnesret !== true) {
          ret = false;
          this.set("invalidDescription", sandbox.localization.translate(businnesret));
        }
      }
    }
    if (!ret && typeof element !== "undefined") {
      var invalidLeft = element.offsetLeft + 20;
      var invalidTop = element.offsetTop + 30;
      this.set("invalidStyle", "top:" + invalidTop + "px; left:" + invalidLeft + "px");
      this.set("triangleBorderStyle", "top:" + (invalidTop-6) + "px; left:" + (invalidLeft + 10) + "px");
      this.set("triangleBackgroundStyle", "top:" + (invalidTop-5) + "px; left:" + (invalidLeft + 11) + "px");
    }
    return ret;
  }

  function checkRequiredValidation() {
    var ret = true;
    if (this.required) {
      if(typeof this.value === "undefined") {
        ret = false;
      } else if (this.value.length === 0) {
        ret = false;
      }
    }
    if (!ret) {
      this.set("invalidDescription", sandbox.localization.translate("Required Field"));
    }
    return ret;
  }

  function checkAlphaValidation() {
    for (var i = 0; i < this.value.length; i++) {
      var c = this.value.charAt(i);
      if (!isNaN(c) && c !== " ") {
        this.set("invalidDescription", sandbox.localization.translate("Numbers Are Not Allowed"));
        return false;
      }
    }
    return true;
  }

  function checkNumericValidation() {
    var ret = true;
    var nuemric = "";
    try {
      nuemric = parseFloat(this.value);
    } catch(e) {
      ret = false;
    }
    if (isNaN(nuemric) || nuemric + "" !== this.value + "") {
      ret = false;
    }
    if (!ret) {
      this.set("invalidDescription", sandbox.localization.translate("Only Numbers Allowed"));
    }
    return ret;
  }

  function checkNumericPositiveValidation() {
    var ret = checkNumericValidation.call(this);
    if (ret) {
      ret = (parseFloat(this.value) >= 0);
    }
    if (!ret) {
      this.set("invalidDescription", sandbox.localization.translate("Only Positive Numbers Allowed"));
    }
    return ret;
  }

  function checkIntegerValidation() {
    var ret = true;
    var nuemric = "";
    try {
      nuemric = parseInt(this.value);
    } catch(e) {
      ret = false;
    }
    if (isNaN(nuemric) || nuemric + "" !== this.value + "" ) {
      ret = false;
    }
    if (!ret) {
      this.set("invalidDescription", sandbox.localization.translate("Only Non Decimal Values Allowed"));
    }
    return ret;
  }

  function checkIntegerPositiveValidation() {
    var ret = checkIntegerValidation.call(this);
    if (ret) {
      ret = (parseInt(this.value) >= 0);
    }
    if (!ret) {
      this.set("invalidDescription", sandbox.localization.translate("Only Positive Non Decimal Values Allowed"));
    }
    return ret;
  }

  function checkPasswordValidation() {
//    if (typeof this.value === "undefined" || this.value.length === 0) {
//      return true;
//    }
//    if (this.value.length < 4) {
//      this.set("invalidDescription", sandbox.localization.translate("At Least Four Characters Required"));
//      return false;
//    }
//    if (this.value.toLowerCase() === this.value) {
//      this.set("invalidDescription", sandbox.localization.translate("At Least One Upper Case Character Required"));
//      return false;
//    }
    return true;
  }

  function checkPasswordConfirmationValidation() {
    if (typeof this.compareTo === "undefined") {
      return true;
    }
    var setupModel = this.get('viewContextModel');
    var value = this.value;
    if (typeof value === "undefined") {
      value = "";
    }
    var comparedValue = getModelProperty( setupModel, this.compareTo);
    if (typeof comparedValue === "undefined") {
      comparedValue = "";
    }
    if (value !== comparedValue) {
      this.set("invalidDescription", sandbox.localization.translate("passwords do not match"));
      return false;
    }
    return true;
  }

  function checkEMailValidation() {
    if (typeof this.value === "undefined" || this.value.length === 0 || this.value.indexOf('@') !== -1) {
      return true;
    }
    else {
      this.set("invalidDescription", sandbox.localization.translate("Bad E-Mail Format"));
      return false;
    }
  }

  function checkExcludeCharactersValidation() {
    var dataType = this.dataType;
    var ex = this.excludeCharacters;
    if (typeof ex === "undefined") {
      ex = "";
    }
    if (typeof dataType !== "undefined") {
      if (this.dataType.toLowerCase() === "path") {
        ex += '?<>*|”';
      }
    }
    if (typeof  ex === "undefined") {
      return true;
    }
    for (var i = 0; i < ex.length; i++) {
      if ((this.value+"").indexOf(ex.charAt(i)) !== -1) {
        this.set("invalidDescription", "\'" + ex.charAt(i) + "\' " + sandbox.localization.translate("Is not Allowed"));
        return false;
      }
    }
    return true;
  }

  function getModelProperty( model, propertyRef) {
    var names = propertyRef.split(".");
    var obj = model;
    for (var i = 0; i < names.length; i++) {
      if (typeof obj === "undefined") {
        return obj;
      }
      obj = obj[names[i]];
    }
    return obj;
  }

  Ember.TextField.reopen({
    classNameBindings:["invalid:invalid-input:", "isNumeric:number-input:", "isPath:path-input:"],
    attributeBindings: ["autocomplete"]
  });

  Ember.Select.reopen({
    classNameBindings:["invalid:invalid-input:", "isNumeric:number-input:"]
  });

  Ember.TextArea.reopen({
    classNameBindings:["invalid:invalid-input:"]
  });

  var _NUMERIC_TYPES = ["numeric", "numericpositive", "integer", "integerpositive"];

  var ValidatedInput = Ember.Component.extend({
    attributeBindings: ["name", "businessLogicValidation"],
    layout: Ember.Handlebars.compile(Template),
    tagName: "span",
    invalid: false,
    name: "validatedInputWidget",

    isValid: function () {
      this.set("invalid", !checkIfValid.call(this), this.$('input')[0]);
    }.property('value'),

    validate: function () {
      var valid = checkIfValid.call(this, this.$('input')[0]);
      this.set("invalid", !valid);
      return valid;
    },


    focusOut: function (event) {
      this.set("invalid", !checkIfValid.call(this,event.target ));
    },

    isText: function () {
      var ret =  (this.type.toLowerCase() === "text");
      if (ret) {
        var dataType = this.dataType;
        if (typeof dataType !== "undefined") {
          ret = !this.get("isPassword");
        }
      }
      return ret;
    }.property(),

    isTextArea: function () {
      return  (this.type.toLowerCase() === "textarea");
    }.property(),

    isPassword: function () {
      var dataType = this.dataType;
      if (typeof dataType === "undefined") {
        return false;
      }
      return (this.dataType.toLowerCase() === "password" || this.dataType.toLowerCase() === "passwordconfirmation");
    }.property(),

    isSelect: function () {
      return (this.type === "select");
    }.property(),

    isCheckBox: function () {
      return (this.type.toLowerCase() === "checkbox");
    }.property(),

    isNumeric: function () {
      var dataType = this.dataType;
      if (typeof dataType === "undefined") {
        return false;
      }
      if (_NUMERIC_TYPES.contains(dataType.toLowerCase())) {
        return true;
      }
      return false;
    }.property(),

    isPath: function () {
      var dataType = this.dataType;
      if (typeof dataType === "undefined") {
        return false;
      }
      return (this.dataType.toLowerCase() === "path");
    }.property(),

    placeHoder: function () {
      var str = this.placeholder;
      if (typeof str === "undefined") {
        str= "";
      }
      return str;
    }.property()
  });
  Ember.Handlebars.helper('validated-input', ValidatedInput);
  return ValidatedInput;
});