import AbstractAction from 'AbstractAction';
import { restPost } from 'core/managers/rest2';
import toastService from 'core/services/toastService';
import { translate } from 'core/services/localization';
import { openConfirmDialog } from 'core/services/dialogService';
import { getMainStatus } from 'utilities/statuses';

module.exports = AbstractAction.extend({

  isApplicable: function (treeObjs) {
    var objects = this.module.selected;

    if (typeof objects === 'undefined' || objects.length === 0) {
      return typeof treeObjs !== 'undefined';
    }

	if (objects.length !== 1) {
		return false;
	}

    for (var i = 0; i < objects.length; i++) {
      var obj = objects[i];
      if (obj.groupPlateType === 'Blanc') {
        return false;
      }
      var content = obj.formContent;
      if (typeof content === 'undefined') {
        content = obj.formSeparation?.separationContent;
      }
      if (typeof content === 'undefined') {
        content = obj.content;//this is for pageview context menu
        if (typeof content === 'undefined') {
          return false;
        }
      }

      var status = getMainStatus(obj);
      if (!status || status.statusType === 'NotAssigned') {
        let separations = obj.separations;
        if (typeof separations === 'undefined' || separations.length === 0) {
          return false;
        } else {
          for (var j = 0; j < separations.length; j++) {
            status = getMainStatus(separations[j]);
            if (status && status.statusType !== 'NotAssigned') {
              return true;
            }
          }
          return false;
        }
      }
    }
    return true;
  },

  execute: function (objects, params) {
    const { nwid, type } = objects[0];
    const data = {
      nwid,
      type,
      newModel: { ...params }
    };


    openConfirmDialog(translate('Before proceeding, please confirm that you want to re-output all plates.'),
      translate('Re-output all plates'))
      .then(() => {
        restPost(this.nwid, '/re-output-plates/reoutput', data)
          .then(res => {
            if (res?.errorMessage) {
              toastService.errorToast(translate('Re-output all plates failed'), res.errorMessage);
            }
          })
          .catch(err => {
            toastService.errorToast(translate('Re-output all plates failed'), err);
          });
      })
      .catch(() => console.log('Cancelled by user'));
  }

});

