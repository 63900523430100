import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class extends Component {
  static contextTypes = {
    controller: PropTypes.object
  };

  static defaultProps = {
    version: 0,
    thumbnail: undefined
  };

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.version !== this.props.version;
  }

  render() {
    var thumbnail = this.props.thumbnail;
    var controller = this.context.controller;
    var thumbnailContent = controller.getThumbnailContent(thumbnail);
    var imageUrl = controller.getThumbnailUrl(thumbnailContent);
    var style = {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: ['url(', imageUrl, ')'].join(' ')
    };
    return <div style={style}></div>;
  }
}