import settingsManager from 'core/managers/settings';

const isUndefined = o => typeof o === 'undefined';

window.betaFeaturesUtility = {
  setFetureFlag: function (flag, value) {
    try {
      const path = ['clientConfiguration', 'betaFeatures', flag]
      settingsManager.set(path, value);
    }
    catch (ex) {
      console.error('betaFeatures is not defined');
    }
  }
};

export const betaFeatures = () => !isUndefined(settingsManager) && !isUndefined(settingsManager.get('clientConfiguration')) && !isUndefined(settingsManager.get(['clientConfiguration', 'betaFeatures'])) ? settingsManager.get(['clientConfiguration', 'betaFeatures']) : {};

export const betaFeature = (featureFlagName) => {
  return betaFeatures()[featureFlagName];
};

export class ModuleContainer {
  featureFlag = undefined;

  features = {};

  defaultFeatureValue = undefined;

  loadFeture = () => {
    const betaFeatureName = betaFeature(this.featureFlag);

    return this.features[betaFeatureName] || this.features[this.defaultFeatureValue];
  };
}

export class ActionContainer {
  featureFlag = undefined;

  features = {};

  defaultFeatureValue = undefined;

  loadFeture = () => {
    const betaFeatureName = betaFeature(this.featureFlag);

    return this.features[betaFeatureName] || this.features[this.defaultFeatureValue];
  };
}