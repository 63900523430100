/**
 * @name
 * @fileOverview
 * @author sergey
 */
define(['sandbox', 'base/browser', 'ember', '../views/MenuView'], function (sandbox, browser, Ember, MenuView) {
  'use strict';
  var ContextMenuController = Ember.ArrayController.extend({

    calculatePosition: function (ev, amount) {
      var event;
      if (ev.originalEvent && ev.originalEvent.type.search('touch') !== -1) {
        event = ev.originalEvent.changedTouches[0];
      }
      else {
        event = ev;
      }

      var ITEM_HEIGHT = 22 + 5, // average height of an item in context menu + padding
        pageWidth = window.innerWidth,
        pageHeight = window.innerHeight,
        contextMenuWidth = 300,
        contextMenuHeight = ITEM_HEIGHT * amount,// > pageHeight ? pageHeight : ITEM_HEIGHT * amount,
        diffY = 0,
        diffX = 0,
        res = { x: event.pageX, y: event.pageY };
      // calculate page dimensions
      if (typeof pageWidth !== 'number') {
        if (document.compatMode === 'CSS1Compat') {
          pageWidth = document.documentElement.clientWidth;
          pageHeight = document.documentElement.clientHeight;
        }
        else {
          pageWidth = document.body.clientWidth;
          pageHeight = document.body.clientHeight;
        }
      }
      return res;
    },

    getFixedContextMenuSize: function (width, height) {
      var pageWidth = window.innerWidth;
      var pageHeight = window.innerHeight;

      return {
        width: width > pageWidth ? pageWidth : width,
        height: height > pageHeight ? pageHeight : height
      };
    },

    getFixedContextMenuPosition: function (top, left, width, height) {
      var pageWidth = window.innerWidth;
      var pageHeight = window.innerHeight;

      return {
        left: pageWidth < left + width + browser.getScrollbarWidth() ?
          pageWidth - width - browser.getScrollbarWidth() > 0 ?
            pageWidth - width - browser.getScrollbarWidth() :
            0 :
          left,
        top: pageHeight < top + height + browser.getScrollbarHeight() ?
          pageHeight - height - browser.getScrollbarHeight() > 0 ?
            pageHeight - height - browser.getScrollbarHeight() :
            0 :
          top
      };
    },

    actions: {
      openSub: function (ev, action, parentCtrl) {
        var subMenuCtrl, subMenuView,
          targetEl = ev.target,
          parent = sandbox.dom.find(targetEl).parent()[0],
          anchorView = Ember.View.views[targetEl.id],
          SubMenuController = ContextMenuController.extend();
        anchorView.set('selected', true);
        subMenuCtrl = SubMenuController.create({
          content: action.items.map(function (item) {
            Ember.set(item, '_isApplicable', true);
            return item;
          }),
          action: action,
          _actions: {
            execute: function (parsedViewLink) {
              const action = this.get('action');
              action.execute([parsedViewLink]);
              parentCtrl.close(action);
            }
          }

        });
        subMenuView = MenuView.create({
          controller: subMenuCtrl,
          top: targetEl.offsetTop + parent.offsetTop - 10,
          left: parent.offsetLeft + parent.offsetWidth - 7,
          level: 1,
          anchorView: anchorView
        });

        // add new submenu view into container view
        this.get('_view').pushObject(subMenuView);
      },
      closeSub: function (ev) {
        var subMenuView = this.get('_view').popObject();
        subMenuView.get('anchorView').set('selected', false);
      },
      execute: function (action, event) {
        action.execute(event);
        this.close(action);
      }
    },

    subIsOpen: function () {
      return this.get('_view.length') !== 2;
    }.property('_view.length'),
    close: function (action) {
      const containerView = this.get('_view');
      containerView.clear();
      containerView.destroy();
      this.destroy();

      if (this.deferred) {
        this.deferred.resolve(action);
        this.deferred = null;
      }
    }

  });

  return ContextMenuController;
});