
define(['sandbox', 'ember', './../../../utils/wizardUtils', "./../model/model", './../../../utils/wizardCommons', 'core/services/toastService'],
  function (sandbox, Ember, wizardUtils, ModelHandler, wizardCommons, toastService) {
    'use strict';
    

    return Ember.ObjectController.extend(wizardCommons.controllerCommons, {
      actions: {
        unWrap: function () {
          var sectionsCopy = [...this.selectedZone.sections];
          sectionsCopy.forEach(function (section){
            Ember.set(section, "split", false);
            // ModelHandler.unSplitSection(section);
          });
          // ModelHandler.unSplitSection(section);
        },

        // makeWrap: function () {
        //   var sectionsToAdd = [];
        //   var sections = this.selectedZone.sections;
        //   var sectionsCopy = [...this.selectedZone.sections];
        //   var n = sectionsCopy.length;
        //   for (var i=0;i<sectionsCopy.length-1;i++){
        //     Ember.set(sectionsCopy[i], "split", true);
        //   }
        //   // sectionsCopy.forEach(function (section){
        //   //   Ember.set(section, "split", true);
        //   //   // ModelHandler.splitSection(section);  
        //   // });
        //   sectionsCopy = [...this.selectedZone.sections];          
        //   var n1 = sectionsCopy.length;          
        //   for (var i=1;i<n1;i+=2){
        //     var section = sectionsCopy[i];            
        //     sections.removeObject(section);
        //     sectionsToAdd.unshift(section);
        //   }
        //   for (var i=0;i<sectionsToAdd.length;i++){
        //     sections.addObject(sectionsToAdd[i]);
        //     this.propertyChangedListener(sections[sections.length-1], "order");
        //   }
          
          
        // },

        addSectionAction: function() {
          if (this.selectedZone.wrapped){
            return;
          }
          ModelHandler.addSection(this.selectedZone, -1);
        },
        removeSection: function(section) {
          if (this.selectedZone.wrapped){
            return;
          }
          ModelHandler.removeSection(this.selectedZone, section);
        },
        applyAllAction: function() {
          var zones = ModelHandler.applyAll();
          if (zones.length === 0){
            toastService.createToast('top-right', this.selectedZone.displayName + ' has no inherited zones', '', 'error', undefined, undefined, 2000);
          } else {
            toastService.createToast('top-right', 'Zones ' + zones + ' were updated', '', 'success', undefined, undefined, 2000);
          }

        },
        applyLinkedZonesAction: function() {
          var zones = ModelHandler.applyAll(true);
          if (zones.length === 0){
            toastService.createToast('top-right', this.selectedZone.displayName + ' has no inherited zones', '', 'error', undefined, undefined, 2000);
          } else {
            toastService.createToast('top-right', 'Zones ' + zones + ' were updated', '', 'success', undefined, undefined, 2000);
          }
        }
      },

      unWrap: function () {
        var sectionsCopy = [...this.selectedZone.sections];
        sectionsCopy.forEach(function (section){
          Ember.set(section, "split", false);
          // ModelHandler.unSplitSection(section);
        });
        // ModelHandler.unSplitSection(section);
      },
      
      wrapAction: function (zone){
        var wrapped = zone.wrapped;
        this.selectedZone.wrapped = wrapped;

        
        if (wrapped){
         
          var sectionsToAdd = [];
          var sections = zone.sections;
          var sectionsCopy = [...zone.sections];
          var n = sectionsCopy.length;
          for (var i=0;i<sectionsCopy.length-1;i++){
            Ember.set(sectionsCopy[i], "split", true);
          }
          // sectionsCopy.forEach(function (section){
          //   Ember.set(section, "split", true);
          //   // ModelHandler.splitSection(section);  
          // });
          sectionsCopy = [...zone.sections];          
          var n1 = sectionsCopy.length;          
          for (var i=1;i<n1;i+=2){
            var section = sectionsCopy[i];            
            sections.removeObject(section);
            sectionsToAdd.unshift(section);
          }
          for (var i=0;i<sectionsToAdd.length;i++){
            sections.addObject(sectionsToAdd[i]);
            this.propertyChangedListener(sections[sections.length-1], "order");
          }
        } else {
          var sectionsCopy = [...zone.sections];
          sectionsCopy.forEach(function (section){
            Ember.set(section, "split", false);
            // ModelHandler.unSplitSection(section);
          });
          
        }

        zone.sections.forEach(function (section){
          // section.wrapped = wrapped;
          Ember.set(section, "wrapped", wrapped);
        });
      },

      selectedZoneChanged: function(model){
        try {
          this.selectedZone = model;
          this.selectedEdition = wizardUtils.matchBySourceId(model.parent, Ember.get(this.model, 'editions'));
          ModelHandler.selectedZone = this.selectedZone;
          ModelHandler.selectedEdition = this.selectedEdition;
        } catch (err){
          console.log("Error " + err);
        }

      },

      selectedZone: null,

      selectedEdition: null,
      nameHeaders: function (){
        return [
          {name:"displayName",label:sandbox.localization.translate("Name"), type:"text", style:"width:13em"},
          {name:"code", label:sandbox.localization.translate("Code"), type:"text", style:"width:4em"},
        ];
      }.property(),
      //headersTitles: [
      //  {name:"displayName",label:sandbox.localization.translate("Name"), type:"text", style:"width:10em"},
      //  {name:"code", label:sandbox.localization.translate("Code"), type:"text", style:"width:4em"},
      //  {name:"numPages", label:"#" + sandbox.localization.translate("Pages"), type:"numeric", style:"width:4em"},
      //  {name:"startPage", label:"#" + sandbox.localization.translate("Start with"), type:"numeric", style:"width:4em"},
      //  {name:"pagination", label:sandbox.localization.translate("Pagination"), type:"text", style:"width:10em;disabled:true;border:#fff;background-color: #fff;background: transparent;", readonly:true},
      //  {name:"split", label:sandbox.localization.translate("Split Section"), type:"checkbox"},
      //  {name:"skip", label:sandbox.localization.translate("Skip Approval"), type:"checkbox"}
      //],

      headers: function (){
        var ret = [
          //{name:"displayName",label:"Name", type:"text", style:"width:10em"},
          //{name:"code", label:"Code", type:"text", style:"width:4em"},
          {name:"numPages", label:"#" + sandbox.localization.translate("Pages"), type:"numeric", style:"width:4em"}
          //{name:"startPage", label:"#" + sandbox.localization.translate("Start with"), type:"numeric", style:"width:4em"},
          //{name:"pagination", label:sandbox.localization.translate("Pagination"), type:"text", style:"width:10em;disabled:true;border:#fff;background-color: #fff;background: transparent;", readonly:true}
        ];

        if (!Ember.get(this.settings, "hideStartWith", false)){
          ret.push({name:"startPage", label:"#" + sandbox.localization.translate("Start with"), type:"numeric", style:"width:4em"});
          ret.push({name:"pagination", label:sandbox.localization.translate("Pagination"), type:"text", style:"width:10em;disabled:true;border:#fff;background-color: #fff;background: transparent;", readonly:true});
        }

        if (!Ember.get(this.settings, "hideSplit", false)){
          ret.push({name:"split", label:sandbox.localization.translate("Split Section"), type:"checkbox"});
        }

        if (!Ember.get(this.settings, "hideSkip", false)){
          ret.push({name:"skip", label:sandbox.localization.translate("Skip Approval"), type:"checkbox"});
        }

        var customFields = Ember.get(this.settings, "customFields") || [];
        customFields.forEach(function (h){
          if (h.type === 'select'){
            const dfltOption =  h.options.find((element) => element.default);
            if (!dfltOption){
              h.options.unshift({label:'---', name:''})
            }    
          }
          ret.push(h);
        });

        return ret;

      }.property(),

      isAutoLinkZones: function (){
        return !this.settings.applyAllLinkZones;
      }.property(),

      propertyChangedListener: function (section, property){
        if (property === "displayName" && this.model.sourceIdBreadcrumb){
          section.sourceId = (section.parent + "/" + section.displayName).toLowerCase();  
        }
        ModelHandler.notifyPropertyChange(section, property, Ember.get(section, property));
        if (this.settings.applyAllLinkZones){
          ModelHandler.applyAll(true);
        }

      },

      propertyChange: function (section, property, newValue){
        Ember.set(section, property, newValue);
      },

      sectionsNamesOptions: function(){
        var ret = [];
        var sections = Ember.get(this.model, "sections");
        ret.addObject({text:"---", value:""});
        if (sections === null || typeof sections === 'undefined'){
          sections = [];
        }
        sections.forEach(function (section){
          ret.push({text:section.name, value:section.name});
        });
        return ret;
      }.property('model.sections.@each.name'),

      sectionsCodesOptions: function(){
        var ret = [];
        var sections = Ember.get(this.model, "sections");
        if (sections === null || typeof sections === 'undefined'){
          sections = [];
        }
        sections.forEach(function (section){
          ret.push({text:section.shortName, value:section.shortName});
        });
        return ret;
      }.property('model.sections.@each.shortName'),

      removeSplitSection: function(section){
        ModelHandler.unSplitSection(section);
      },

      splitSection: function (section){
        ModelHandler.splitSection(section);
      },


      updateModel: function (model) {
        // maybe need to backup model before update
        return ModelHandler.convertFromStepModel(model, this.settings);
      },

      zones: function(){
        return ModelHandler.zones();
      },

      isDisabled: function(colDescriptor, section){
        console.log ("hiiiiiiiiiiiiiiiiiiiiiiiii");
        if (this.selectedZone && this.selectedZone.wrapped){
          return true;
        }
      }

    });

  });

