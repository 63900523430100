/* @flow weak */

const idWorker = require('core/workers/idWorker');

const emptyDropdownValue = { text: '---', value: '' };
const emptyMatchingModeDropdownValue = { text: 'Text', value: 'text' };
const FIELD_TYPES_TO_SORT_MAP = {
  measurement: 'number',
  number: 'number',
  degrees: 'number',
  percentage: 'number',
  '': 'text',
  publication: 'text',
  edition: 'text',
  zone: 'text',
  section: 'text',
  allLayoutGroups: 'text',
  day: 'text',
  string: 'text',
  book: 'text',
  siteListNames: 'text',
  multiselect: 'text',
  matchlist: 'text',
  flowstep: 'flowstep',
  form: 'text',
  page: 'text',
  broadsheet: 'text',
  tabs: 'text',
  '2up': 'text',
  '4up': 'text',
  '8up': 'text',
  customLayoutGroup: 'text',
  pageSize: 'text',
  siteName: 'text',
  siteCode: 'text',
  allLayoutGroups: 'text',
  press: 'text',
  siteListNames: 'text',
  siteListCodes: 'text',
  date: 'date',
};

const FIELD_TYPES_TO_FILTER_MAP = {
  measurement: 'number',
  number: 'number',
  degrees: 'number',
  percentage: 'number',
  '': 'text',
  publication: 'text',
  edition: 'text',
  zone: 'text',
  section: 'text',
  allLayoutGroups: 'text',
  day: 'text',
  string: 'text',
  book: 'text',
  siteListNames: 'text',
  multiselect: 'text',
  matchlist: 'text',
  flowstep: 'flowstep',
  flowsteps: 'flowsteps',
  form: 'text',
  page: 'text',
  broadsheet: 'text',
  tabs: 'text',
  '2up': 'text',
  '4up': 'text',
  '8up': 'text',
  customLayoutGroup: 'text',
  pageSize: 'text',
  siteName: 'text',
  siteCode: 'text',
  allLayoutGroups: 'text',
  press: 'text',
  siteListNames: 'text',
  siteListCodes: 'text',
  resources: 'resources',
  securityGroup: 'securityGroup',
  date: 'date',
};

const getSortType = fieldType => FIELD_TYPES_TO_SORT_MAP[fieldType];

const getFilterType = fieldType => FIELD_TYPES_TO_FILTER_MAP[fieldType];

const updatePinnedColumns = (pinnedValue, currentColumnIndex, columns) => columns.map((col, index) => {
  if (pinnedValue) {
    if (index < currentColumnIndex) {
      col.pinColumnForScroll = pinnedValue;
    }
  } else {
    if (index > currentColumnIndex) {
      col.pinColumnForScroll = pinnedValue;
    };
  };

  return col;
});

const getFirstColumnType = (state, index = 0) => {
  return state.tables.length > 0 && state.tables[index].selectors.length > 0 ? 'selectors' :
    state.tables.length > 0 && state.tables[index].values.length > 0 ? 'values' : '';
};
const getFirstColumnIndex = (state, currentTableIndex, currentColumnType) => {
  return state.tables.length > 0 && currentColumnType !== '' && state.tables[currentTableIndex][currentColumnType].length > 0 ? 0 : -1;
};
const getTableFirstColumnType = (table = {}) => {
  return table.selectors.length > 0 ? 'selectors' :
    table.length > 0 && table.values.length > 0 ? 'values' : '';
};
const getTableFirstColumnIndex = (table = {}, currentColumnType) => {
  return currentColumnType !== '' && table[currentColumnType].length > 0 ? 0 : -1;
};
const emptyTable = (name = '') => {
  return {
    name,
    nwid: `c${idWorker.generateId()}`,
    targetType: '',
    oldName: name,
    rows: [],
    selectors: [],
    values: []
  };
};

const duplicateTable = (newTableName = '', currentDuplicateTable) => {
  return {
    name: newTableName,
    nwid: `c${idWorker.generateId()}`,
    targetType: currentDuplicateTable.targetType,
    rows: [],
    selectors: currentDuplicateTable.selectors,
    values: currentDuplicateTable.values,
    matchingMode: currentDuplicateTable.matchingMode,
    system: currentDuplicateTable.system,
    singleValue: currentDuplicateTable.singleValue
  };
};

const newTable = (definition, tableType, tableName) => {
  const tableRows = definition.rows || [];
  const tableNwid = definition.nwid;
  const additionInfo = { nwid: tableNwid, rows: tableRows, name: tableName };
  const table = Object.assign({}, definition, additionInfo);
  return table;
};
const emptySelector = (name = '', displayName = '') => {
  return {
    name,
    displayName,
    defaultValue: '',
    fieldType: '',
    freeText: true,
    values: []
  };
};
const emptyValue = (name = '', displayName = '') => {
  return {
    name,
    displayName,
    defaultValue: '',
    fieldType: '',
    freeText: true,
    editable: true,
    values: []
  };
};
const findItemBy = (items = [], itemName, propName) => {
  return items.filter((item) => {
    return item[propName] === itemName;
  });
};
const hasItemWithName = (items = [], itemName) => {
  return findItemBy(items, itemName, 'name').length > 0;
};
const lastColumnNumber = (columns = [], columnName) => {
  let i = 1;

  if (!hasItemWithName(columns, columnName)) return 0;

  while (hasItemWithName(columns, `${columnName} ${i}`)) {
    i++;
  }

  return i;
};
const lastTableNumber = (tables = [], tableName) => {
  let i = 1;

  if (!hasItemWithName(tables, tableName)) return 0;

  while (hasItemWithName(tables, `${tableName} ${i}`)) {
    i++;
  }

  return i;
};

module.exports = {
  emptyDropdownValue,
  getFirstColumnType,
  getFirstColumnIndex,
  getTableFirstColumnType,
  getTableFirstColumnIndex,
  emptyTable,
  duplicateTable,
  newTable,
  emptySelector,
  emptyValue,
  findItemBy,
  hasItemWithName,
  lastColumnNumber,
  lastTableNumber,
  getFilterType,
  getSortType,
  updatePinnedColumns
};
