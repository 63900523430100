  /*
   * Private functions
   */

  function isUndefined(o) {
    return typeof o === 'undefined';
  }

  function isFunction(o) {
    return typeof o === 'function';
  }

  function isNumber(o) {
    return typeof o === 'number';
  }

  function defaultFilterFunc() {
    return true;
  }

  function defaultSortFunc() {
    return -1;
  }

  function replaceItemInArray(arr, item, newItem) {
    var index;

    if (item === newItem) return;

    index = arr.indexOf(item);

    if (index === -1) return;

    arr.splice(index, 1, newItem);
  }

  function addItemToArray(arr, item) {
    arr.push(item);
  }

  function removeItemFromArray(arr, item) {
    var index;

    if (isUndefined(item)) return;

    index = arr.indexOf(item);

    if (index === -1) return;

    arr.splice(index, 1);
  }

  function updateIdMap(idMap, item) {
    idMap[item.id] = item;
  }

  function removeFromIdMap(idMap, id) {
    delete idMap[id];
  }

  /*
   * Public functions
   */

  function setFilter(filterFunc) {
    if (isFunction(filterFunc)) this._filterFunc = filterFunc;
    if (arguments.length === 1 && isUndefined(filterFunc)) this._filterFunc = this._defaultFilter;
  }


  function getFilter() {
    return this._filterFunc;
  }

  function setSort(sortFunc) {
    if (isFunction(sortFunc)) this._sortFunc = sortFunc;
    if (arguments.length === 1 && isUndefined(sortFunc)) this._sortFunc = undefined;
  }

  function getSort() {
    return this._sortFunc;
  }

  function hasId(id) {
    return !isUndefined(this._idMap[id]);
  }

  function getItemById(id) {
    return this._idMap[id];
  }

  function defaultArrayHasId(id) {
    return !isUndefined(this._defaultArrayIdMap[id]);
  }

  function defaultArrayGetItemById(id) {
    return this._defaultArrayIdMap[id];
  }

  function set(item) {
    var filterFunc = this.getFilter();
    var sortFunc = this.getSort();
    var itemPassedFilter;

    if (isUndefined(item) || isUndefined(item.id)) return;

    itemPassedFilter = filterFunc(item);

    if (itemPassedFilter) {
      if (this.hasId(item.id)) {
        replaceItemInArray.call(this, this, this.getItemById(item.id), item);
      }
      else {
        addItemToArray.call(this, this, item);
      }
      updateIdMap.call(this, this._idMap, item);
      //var t1, t2;
      //t1 = performance.now();
      if (isFunction(sortFunc)) this.sort(sortFunc);
      //t2 = performance.now();
      //console.log(t2 - t1);
    }
    else if (!itemPassedFilter && this.hasId(item.id)) {
      removeItemFromArray.call(this, this, this.getItemById(item.id));
      removeFromIdMap.call(this, this._idMap, item.id);
    }

    if (!this.defaultArrayHasId(item.id)) {
      addItemToArray.call(this, this._defaultArray, item);
    }
    else if (this.defaultArrayGetItemById(item.id) !== item) {
      replaceItemInArray.call(this, this._defaultArray, this.defaultArrayGetItemById(item.id), item);
    }
    updateIdMap.call(this, this._defaultArrayIdMap, item);

    return this.length;
  }

  function remove(id) {
    // if (!isNumber(id)) return;

    if (this.hasId(id)) {
      removeItemFromArray.call(this, this, this.getItemById(id));
      removeFromIdMap.call(this, this._idMap, id);
    }
    if (this.defaultArrayHasId(id)) {
      removeItemFromArray.call(this, this._defaultArray, this.defaultArrayGetItemById(id));
      removeFromIdMap.call(this, this._defaultArrayIdMap, id);
    }

    return this.length;
  }

  function filter() {
    var filteredArray = this._defaultArray.filter(this.getFilter());
    if (!isUndefined(this.getSort())) filteredArray.sort(this.getSort());
    while (this.length > 0) this.pop();
    this._idMap = {};
    for (var i = 0; i < filteredArray.length; i++) {
      this.push(filteredArray[i]);
      this._idMap[filteredArray[i].id] = filteredArray[i];
    }
  }

  function sort(sortFunc) {
    setSort.call(this, sortFunc);
    if (!isUndefined(this.getSort())) Array.prototype.sort.call(this, this.getSort());
  }

  //function concat() {
  //  var processArray = new ProcessArray();
  //
  //  processArray.setFilter(this.getFilter());
  //  processArray.setSort(this.getSort());
  //
  //  for (var i=0; i< this.length; i++) processArray.set(this[i]);
  //
  //  return processArray;
  //}


  function ProcessArray(options) {
    if (typeof options === 'undefined') options = {};

    var defaultFilter = options.defaultFilter || defaultFilterFunc;

    Array.apply(this, arguments);
    // Object.defineProperty(this, '_currentArray', {
    //   enumerable: false,
    //   writable: true,
    //   value: []
    // });



    Object.defineProperty(this, '_defaultFilter', {
      enumerable: false,
      writable: true,
      value: defaultFilter
    });

    Object.defineProperty(this, '_defaultArray', {
      enumerable: false,
      writable: true,
      value: []
    });
    Object.defineProperty(this, '_defaultArrayIdMap', {
      enumerable: false,
      writable: true,
      value: {}
    });
    Object.defineProperty(this, '_idMap', {
      enumerable: false,
      writable: true,
      value: {}
    });
    Object.defineProperty(this, '_sortFunc', {
      enumerable: false,
      writable: true,
      value: undefined
    });
    // Object.defineProperty(this, '_sortTriggerTypes', {
    //   enumerable: false,
    //   value: []
    // });
    Object.defineProperty(this, '_filterFunc', {
      enumerable: false,
      writable: true,
      value: defaultFilter
    });
    // Object.defineProperty(this, '_filterTriggerTypes', {
    //   enumerable: false,
    //   value: []
    // });
  }

  ProcessArray.prototype = Object.create(Array.prototype);

  ProcessArray.prototype.setFilter = setFilter;

  ProcessArray.prototype.getFilter = getFilter;

  ProcessArray.prototype.setSort = setSort;

  ProcessArray.prototype.getSort = getSort;

  ProcessArray.prototype.hasId = hasId;

  ProcessArray.prototype.getItemById = getItemById;

  ProcessArray.prototype.defaultArrayHasId = defaultArrayHasId;

  ProcessArray.prototype.defaultArrayGetItemById = defaultArrayGetItemById;

  ProcessArray.prototype.set = set;

  ProcessArray.prototype.remove = remove;

  ProcessArray.prototype.filter = filter;

  ProcessArray.prototype.sort = sort;

  //ProcessArray.prototype.concat = concat;

  export default ProcessArray;