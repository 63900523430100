import { startInternalModule } from 'core/managers/module';
import AbstractAction from 'AbstractAction';
import sandbox from 'sandbox';
import toastService from 'core/services/toastService';
import { getMainStatus } from 'utilities/statuses';

const ERROR_STATUS = 'Error';
const isEmpty = o => Object.keys(o).length === 0;
const isRejectedPage = page => {
  const status = getMainStatus(page) || {};
  return status.flowStepType === 'workflow/step/flow/approval' && status.statusType === 'Error';
};

const translate = sandbox.localization.translate;
const labels = {
  error: translate('Error')
};

export default AbstractAction.extend({

  isApplicable: function (selectedItems) {
    if (selectedItems.some(item => item.blockMail === 'true')) {
      return false;
    }
    return selectedItems.length === 1 && selectedItems[0].deprecated !== "true" && !isRejectedPage(selectedItems[0]);
  },

  execute: function (selectedItems) {
    const rest = sandbox.request.rest(this.nwid);
    rest.get(`cw/${selectedItems[0].nwid}/planned-meta-data`).then((metaData) => {
      const moduleName = this.parameters.moduleName;
      const name = this.label;
      const target = 'dialog';
      const windowWidth = this.parameters.windowWidth;
      const windowHeight = this.parameters.windowHeight;
      const win = this.module.win;

      startInternalModule(moduleName, {
        name,
        win,
        target,
        windowWidth,
        windowHeight,
        model: {
          ...metaData,
          disabledCWNumber: selectedItems && selectedItems[0] && !isEmpty(selectedItems[0].transmissionStatuses || {}),
          mode: 'update'
        },
        onSave: (model) => {
          const newModel = {
            ...model,
            config: this.config
          }
          const rest = sandbox.request.rest(this.nwid);
          return rest.post(`cw/${selectedItems[0].nwid}/edit-ad-data`, { data: JSON.stringify(newModel) }).then(response => {
            if (response.state === ERROR_STATUS) {
              toastService.createToast('bottom-right', labels.error, translate(response.message), 'error', undefined, undefined, false);
              return {
                error: response.message
              };
            }
          });
        }
      }, this);
    });
  }
});