/**
 * @name PropertiesForm
 * @file React component that defines element properties functionality
 *
 * @author Boris
 * @since: 2016-06-23
 */

import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import { getGeneralSvgIconHref } from 'core/services/iconService';
import { Form, utils as futils } from 'widgets/SimpleForm/src/index';
import elementBase from '../elements/elementBase';
import FlatButton from 'components/common/buttons/FlatButton';
import actions from '../redux/actions';

const isElementDisabled = (elementPath) => {
  return futils.pathContains(elementPath, 'plate');
};

const PropertiesForm = ({ store, editor }) => {

  const elementBaseRef = useRef(null);

  elementBaseRef.current = useMemo(() => {
    return editor ? elementBase(editor) : null;
  }, [editor]);

  const handleLockButtonClick = (element, elementPath) => {
    const locked = !element.locked;
    const path = futils.compose(elementPath, 'locked');
    store.dispatch(actions.update(path, locked));
  };

  const renderHeader = (element, elementPath) => {
    if (!element || !elementBaseRef.current) {
      return null;
    }

    const state = store.getState();

    const disabled = isElementDisabled(elementPath);
    const locked = element.locked;
    let tooltip = locked ? translate('Unlock element') : translate('Lock element');

    const title = translate('{1} Properties', elementBaseRef.current.getInfo(state, element.elementType).title);
    const iconName = locked ? 'lock' : 'lock_open';

    return (
      <div className='properties-header'>
        <FlatButton
          tooltip={tooltip}
          disabled={disabled}
          onClick={e => handleLockButtonClick(element, elementPath)}
        >
          <svg className={'svg-icon'}>
            <use xlinkHref={getGeneralSvgIconHref(iconName)} />
          </svg>
        </FlatButton>
        <div className='properties-title'>{title}</div>
      </div>
    );
  };

  const renderProperties = (element, elementPath) => {
    if (!element || !elementBaseRef.current) {
      return null;
    }

    return elementBaseRef.current.renderProperties(store, element, elementPath);
  };

    const renderForm = () => {
    if (!store) {
      return null;
    }

    const state = store.getState();
    const elementPath = state.selectedElementPath;
    const element = !elementPath ? null : store.get(elementPath);

    if (!element) {
      return null;
    }

    return (
      <div className='properties-container'>
        <Form store={store} className='properties-form'>
          {renderHeader(element, elementPath)}
          {renderProperties(element, elementPath)}
        </Form>
      </div>
    );
  };

  return (
    <div>
      {renderForm()}
    </div>
  );

};

PropertiesForm.propTypes = {
  store: PropTypes.any,
  editor: PropTypes.any,
};

export default PropertiesForm;
