/**
 * Created by elad on 3/28/2017.
 */
define(['ember', 'text!../templates/productionRunsMain.hbs', './ZoneView', 'kendo-ember', './../../../utils/wizardCommons'],
  function (Ember, MainTemplate, ZoneView, kendoComponents, wizardCommons) {
    'use strict';

    return Ember.View.extend(wizardCommons.viewCommons, {
      onActivate: function(){

      },
      template: Ember.Handlebars.compile(MainTemplate),

      classNames: ['structure-container'],

      zoneView: ZoneView
    });
  });