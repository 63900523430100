import {
  createObjectComparator,
  composeComparators,
} from 'core/comparators';
import { ROW_HEIGHT, ROW_HEIGHT_INCLUDING_THUMBNAIL } from 'widgets/ReactDataGrid/utils';

const filterer = filter => {
  return page => {
    const propComparator = prop =>
      filter[prop] === "" ||
        (page[prop] || '').toLowerCase().indexOf(filter[prop].toLowerCase()) >= 0
        ? true
        : false;

    const stitchComparator = () => (filter.stitched !== 'stitched') ? filter.stitched === 'all' ? filter.stitched : !page.stitched : page.stitched || page.stitched;
    const pickupComparator = () => (filter.pickup !== 'pickup') ? filter.pickup === 'all' ? filter.pickup : !page.pickup : page.pickup || page.pickup;
    const adStateComparator = () => (filter.adState === 'all') ? filter.adState : filter.adState === 'pre' ? page.adType === filter.adState : page.adType === 'final' || page.adState;

    return propComparator("advertiser") && propComparator("publication") && stitchComparator() && pickupComparator() && adStateComparator();
  };
};

const getSortingSortType = (type, direction) => {
  if (type === "cmyk") return "cmyk";

  return direction === "desc" ? `${type}decending` : type;
};

const getSortingProp = (sortByType, prop) => {
  switch (sortByType) {
    case "date":
      return `time`;
    default:
      return prop;
  }
};

const sorter = columnsToSortBy => items => {
  if (columnsToSortBy && columnsToSortBy.length > 0) {
    const comparator = composeComparators(columnsToSortBy.map(col => { 
        return createObjectComparator(col.valueGetter || col.key, col.sortType, col.ascending)
    }));
    return items.sort(comparator);
  }
  return items;
};

const addSites = (state, { sites }) => {
  return {
    ...state,
    loaded: true,
    sites
  };
};

const addItem = (state, { item }) => {
  const oldItems = state.items;
  const passedFilter = filterer(state.filterBy)(item);
  const newItems = passedFilter ? oldItems.concat(item) : oldItems;
  const allItems = state.allItems.concat(item);
  const sortedNewItems = passedFilter
    ? sorter(state.columnsToSortBy)(newItems)
    : newItems;
  return {
    ...state,
    items: sortedNewItems,
    allItems
  };
};

const updateItem = (state, { item, index }) => {
  const itemIndex =
    index >= 0 ? index : state.items.findIndex(i => i.nwid === item.nwid);
  if (itemIndex < 0) return state;
  return {
    ...state,
    items: [
      ...state.items.slice(0, itemIndex),
      item,
      ...state.items.slice(itemIndex + 1)
    ]
  };
};

const removeItem = (state, { item }) => {
  return {
    ...state,
    items: state.items.filter(i => i.nwid !== item.nwid)
  };
};

const selectItems = (state, { selectedItems }) => {
  return {
    ...state,
    selectedItems
  };
};

const updateFilter = (state, { prop, value }) => {
  return {
    ...state,
    filterBy: {
      ...state.filterBy,
      [prop]: value
    }
  };
};

const filterItems = (state, action) => {
  const filter = filterer(state.filterBy);
  const filteredItems = state.allItems.filter(filter);
  const sortedItems = sorter(state.columnsToSortBy)(filteredItems);
  return {
    ...state,
    items: sortedItems
  };
};

const sortItems = (state, action) => {
  const { items, preferences } = state;
  const { columnsToSortBy } = action;
  const sortedItems = sorter(columnsToSortBy)(items);

  return {
    ...state,
    items: sortedItems,
    columnsToSortBy,
    preferences: {
      ...preferences,
      columnsToSortBy: columnsToSortBy.map(col => ({ key: col.key, ascending: col.ascending }))
    }
  };
};

const toggleFilters = (state, { show }) => {
  return {
    ...state,
    config: {
      ...state.config,
      showFilters: show
    }
  };
};

const columnResize = (state, { columnKey, columnWidth }) => {
  return {
    ...state,
    preferences: {
      ...state.preferences,
      columnsWidth: {
        ...state.preferences.columnsWidth,
        [columnKey]: columnWidth,
      }
    }
  };
};

const updatePreferences = (state, { newPreferences }) => {
  return {
    ...state,
    preferences: {
      ...state.preferences,
      ...newPreferences
    }
  };
};

const updateRowHeight = (state, { rowHeight }) => {
  return {
    ...state,
    rowHeight
  };
};

export default function (state = {}, action) {
  switch (action.type) {
    case "ADD_SITES":
      return addSites(state, action);
    case "ADD_ITEM":
      return addItem(state, action);
    case "UPDATE_ITEM":
      return updateItem(state, action);
    case "REMOVE_ITEM":
      return removeItem(state, action);
    case "SELECT_ITEMS":
      return selectItems(state, action);
    case "UPDATE_FILTER":
      return updateFilter(state, action);
    case "FILTER_ITEMS":
      return filterItems(state, action);
    case "SORT_ITEMS":
      return sortItems(state, action);
    case "TOGGLE_FILTERS":
      return toggleFilters(state, action);
    case "COLUMN_RESIZE":
      return columnResize(state, action);
    case 'UPDATE_PREFERENCES':
      return updatePreferences(state, action);
    case 'UPDATE_ROW_HEIGHT':
      return updateRowHeight(state, action);

    default:
      return {
        multiple: state.multiple,
        loaded: false,
        sites: [],
        items: [],
        allItems: [],
        selectedItems: [],
        columnsToSortBy: state.preferences && state.preferences.columnsToSortBy ? [...state.preferences.columnsToSortBy] : [],
        filterBy: {
          advertiser: "",
          publication: "",
          stitched: 'all',
          pickup: 'all',
          adState: 'all'
        },
        config: {
          showFilters: true
        },
        rowHeight: state.preferences.filteredColumnsKeys.some(key => key === 'pageContentNwid') ? ROW_HEIGHT : ROW_HEIGHT_INCLUDING_THUMBNAIL,
        preferences: state.preferences
      };
  }
}
