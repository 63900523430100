/**
 * @name PanelToolbar
 * @file Toolbar React component used for adding elements into element group
 *
 * @author Boris
 * @since: 2016-06-23
 */

import React from 'react';
import PropTypes from 'prop-types';
import {noop} from 'base/jsUtils';
import ReactComponents from 'widgets/ReactComponents/src/index';

const {Button, SplitButton} = ReactComponents;

const PanelToolbar = ({group, onButtonClick = noop, onOptionChange = noop}) => {

  const handleButtonClick = buttonId => {
    onButtonClick(group, buttonId);
  };

  const handleOptionSelect = (buttonId, optionId) => {
    onOptionChange(group, buttonId, optionId);
  };

  const renderButton = button => {
    if (Array.isArray(button.options)) {
      return (
        <SplitButton
          key={button.id}
          text={button.text}
          className={button.className}
          iconClassName={button.iconClassName}
          tooltip={button.tooltip}
          iconName={button.iconName}
          onClick={(id) => handleButtonClick(button.id)}
          options={button.options}
          disabled={button.options.length <= 0}
          optionContentGetter={button.optionContentGetter}
          value={button.value}
          onSelect={(event, value) => handleOptionSelect(button.id, value)}
        />
      );
    } else {
      return (
        <Button
          key={button.id}
          text={button.text}
          className={button.className}
          iconClassName={button.iconClassName}
          tooltip={button.tooltip}
          iconName={button.iconName}
          onClick={() => handleButtonClick(button.id)}
        />
      );
    }
  };

  const renderButtons = () => {
    if (!group.buttons) {
      return null;
    }

    return (
      <div className="panel-toolbar-buttons">
        {group.buttons.map(renderButton)}
      </div>
    );
  };

  return (
    <div>
      {renderButtons()}
    </div>
  );

};

PanelToolbar.propTypes = {
  group: PropTypes.object,
  onButtonClick: PropTypes.func
};

export default PanelToolbar;
