import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import jQuery from 'jquery';

const style = { display: 'none' };
const extraBorderSizes = 2;
const isUndefined = o => o === undefined;
const createElement = (tagName, doc, className) => {
  let domNode = doc.createElement(tagName);
  domNode.className = className;
  return domNode;
};
const addPx = (value) => {
  return isNaN(value) ? value : value + 'px';
};
const WIDTH_ADJUSTMENT_TIMEOUT = 10;

const createPopup = (popupRef, openerNode, className, top, left, width, height, align, topOffset, filterEnabled) => {
  const doc = openerNode ? openerNode.ownerDocument : popupRef.ownerDocument;
  const popupClassName = ['crtx-popup', className].join(' ');
  const popup = createElement('DIV', doc, popupClassName);
  const openerRect = openerNode ? openerNode.getBoundingClientRect() : { left, top, width, height };
  const cssTop = !isNaN(top) ? top : openerRect.top + openerRect.height;
  const cssWidth = width > 0 || width === 'auto' ? width : openerRect.width;
  const cssMaxHeight = height;
  let cssLeft, cssRight;
  if (!isNaN(left)) {
    cssLeft = left;
  } else if (align === 'right') {
    const bodyRect = doc.body.getBoundingClientRect();
    cssRight = bodyRect.width - openerRect.left - openerRect.width;
  } else {
    cssLeft = openerRect.left;
  }

  jQuery(popup).css({
    position: 'absolute',
    top: addPx(cssTop + topOffset),
    left: addPx(cssLeft),
    right: addPx(cssRight),
    width: addPx(cssWidth),
    maxHeight: addPx(cssMaxHeight),
    display: filterEnabled ? 'flex' : 'block',
  });

  return popup;
};

const appendChild = (container, child) => {
  container.appendChild(child);
};

const removeChild = (container, child) => {
  container.removeChild(child);
};

const contains = (container, target) => {
  return container === target || jQuery.contains(container, target);
};

const onWindowResize = (win, cb, toggle = true) => {
  toggle ? win.addEventListener('resize', cb) : win.removeEventListener('resize', cb);
};

const documentClickHandler = (win, cb, toggle = true) => {
  toggle ? win.addEventListener('mousedown', cb) : win.removeEventListener('mousedown', cb);
};

const parentsScrollHandler = (dropdownNode, cb, toggle = true) => {
  if (dropdownNode === undefined) return;

  let parents = jQuery(dropdownNode).parentsUntil('body');
  let command = toggle ? 'on' : 'off';

  parents[command]('scroll', cb);
};

const addHandlers = (domNode, cb) => {
  let win = domNode.ownerDocument.defaultView;
  onWindowResize(win, cb, true);
  parentsScrollHandler(domNode, cb, true);
  documentClickHandler(win, cb, true);
};

const removeHandlers = (domNode, cb) => {
  let win = domNode.ownerDocument.defaultView;
  onWindowResize(win, cb, false);
  parentsScrollHandler(domNode, cb, false);
  documentClickHandler(win, cb, false);
};

const renderContent = (popup, content, clbk) => {
  if (content === undefined || content.length === 0) return;
  ReactDOM.render(content, popup, clbk);
};

const setPopupPosition = (openerNode, popup, top, left, width, height, initialWidth, filterEnabled) => {
  if (openerNode) {
    setPopupPositionOpener(openerNode, popup, initialWidth, filterEnabled);
  } else {
    setPopupPositionFloat(popup, top, left, width, height, filterEnabled);
  }
};

const setPopupPositionFloat = (popup, top, left, width, height) => {
  adjustCSSVertical(popup, top, height);
  adjustCSSHorizontal(popup, left, width);
};

const setPopupWidth = (popup, width) => {
  popup.css({
    width: addPx(width),
  });
}

const setPopupScroll = (popup, scrollType) => {
  popup.css({
    overflowY: scrollType,
  });
}

const setPopupPositionOpener = (openerNode, popup, initialWidth, filterEnabled) => {
  let top = jQuery(openerNode).offset().top;
  let left = jQuery(openerNode).offset().left;
  let openerNodeWidth = openerNode.offsetWidth;
  adjustCSSVertical(popup, top, popup.height(), openerNode.offsetHeight);
  if (initialWidth === 'auto' && !filterEnabled) {
    setPopupWidth(popup, 0);
    // first the overflow-y of popup is set to scroll so it will also calculate the scroll width because if not and later the popup will
    // need scroll the popup width won't be enough and the scroll will hide part of the popup items
    setPopupScroll(popup, 'scroll');
    // set time out is used here in case of 'width = auto' to allow popup to render all of its content and calculate the popup width correctly 
    setTimeout(() => {
      setPopupWidth(popup, 'auto');
      // checking if the popup actually needs the scroll and if not it is set back to auto (which is the default)
      if (popup[0].scrollHeight <= popup.height()) {
        setPopupScroll(popup, 'hidden');
      } else {
        setPopupScroll(popup, 'auto');
      }
      adjustCSSHorizontal(popup, left, popup.width(), openerNodeWidth);
      // the timeout delay is set to 10 to give enough time to render all popup items so the popup will be able to calculate its width correctly
    }, WIDTH_ADJUSTMENT_TIMEOUT);
  } else {
    adjustCSSHorizontal(popup, left, popup.width(), openerNodeWidth);
  }
};

const adjustCSSVertical = (popup, offsetTop, popupHeight, openerNodeHeight = 0) => {
  let doc = popup[0].ownerDocument;
  let win = doc.defaultView;
  let windowHeight = win.innerHeight;
  let documenttScrollTop = doc.documentElement.scrollTop;
  let popupBottom = popupHeight + offsetTop + openerNodeHeight + extraBorderSizes - documenttScrollTop;

  if (
    (popupBottom > windowHeight) &&
    (offsetTop - popupHeight - extraBorderSizes > 0)
  ) {
    popup.css({
      top: offsetTop - popupHeight - extraBorderSizes
    });
  }
};

const adjustCSSHorizontal = (popup, offsetLeft, popupWidth, openerNodeWidth = 0) => {
  let doc = popup[0].ownerDocument;
  let win = doc.defaultView;
  let windowWidth = win.innerWidth;
  let documenttScrollLeft = doc.documentElement.scrollLeft;
  let popupRight = popupWidth + offsetLeft + extraBorderSizes - documenttScrollLeft;

  if (
    (popupRight > windowWidth) &&
    (offsetLeft - popupWidth - extraBorderSizes > 0)
  ) {
    popup.css({
      left: offsetLeft - popupWidth - extraBorderSizes + openerNodeWidth,
    });
  }
};

const getOpenerNode = (opener) => {
  return !opener || opener instanceof HTMLElement ? opener : ReactDOM.findDOMNode(opener);
};

module.exports = class Popup extends Component {
  constructor(props) {
    super(props);
    this.handleClickedOutside = this.handleClickedOutside.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.handleComponentRemoved = this.handleComponentRemoved.bind(this);
  }

  static propTypes = {
    /*visible - boolean - is the popup open or close*/
    visible: PropTypes.bool,
    className: PropTypes.string,
    /*opener - object - the opener element. either domNode or component*/
    opener: PropTypes.any,
    height: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    width: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    top: PropTypes.number,
    left: PropTypes.number,
    onClickedOutside: PropTypes.func,
    topOffset: PropTypes.number,
    filterEnabled: PropTypes.bool
  };

  static defaultProps = {
    visible: false,
    height: 202,
    onClickedOutside: function () {
    },
    topOffset: 0,
  };

  handleClickedOutside(event) {
    var opener = this.props.opener;
    var openerNode = getOpenerNode(opener);
    var target = event.target;

    if (
      (openerNode && !contains(openerNode, target) && !contains(this.popup, target)) ||
      (!openerNode && !contains(this.popup, target))
    ) {
      this.props.onClickedOutside(event);
    }
  }

  open() {
    const { opener, className, top, left, width, height, align, children, topOffset, filterEnabled } = this.props;
    const openerNode = getOpenerNode(opener);
    const doc = openerNode ? openerNode.ownerDocument : this.popupRef.ownerDocument;
    this.popup = createPopup(this.popupRef, openerNode, className, top, left, width, height, align, topOffset, filterEnabled);
    appendChild(doc.body, this.popup);
    addHandlers(this.popupRef, this.handleClickedOutside);
    renderContent(this.popup, children, () => {
      const popupRect = this.popup.getBoundingClientRect();
      setPopupPosition(openerNode, jQuery(this.popup), top, left, popupRect.width, popupRect.height, width, filterEnabled);
    });
  }

  close() {
    if (!this.popup) {
      return;
    }

    const doc = this.popup.ownerDocument;
    removeHandlers(this.popupRef, this.handleClickedOutside);
    ReactDOM.unmountComponentAtNode(this.popup);
    removeChild(doc.body, this.popup);
    this.popup = undefined;
  }

  handleComponentRemoved() {
    jQuery(this.refs.dropdown).on('remove', this.handleComponentRemoved);

    this.close();
  }

  componentDidMount() {
    jQuery(this.refs.dropdown).off('remove', this.handleComponentRemoved);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible && this.props.visible === true) {
      this.open();
    } else if (prevProps.visible !== this.props.visible && this.props.visible === false) {
      this.close();
    } else if (prevProps.visible === this.props.visible && this.props.visible === true) {
      renderContent(this.popup, this.props.children);
    }
  }

  componentWillUnmount() {
    this.close();
  }

  popupRefCallback = (node) => {
    this.popupRef = node;
  };

  render() {
    return <div ref={this.popupRefCallback} style={style} />;
  }

};
