import PropTypes from 'prop-types';
import CanvasComponent from './canvasComponent';

export class Line extends CanvasComponent {
  static propTypes = {
    strokeStyle: PropTypes.string,
    lineWidth: PropTypes.number,
    point: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    }).isRequired,
    pointTo: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    }).isRequired
  };

  static defaultProps = {
    lineWidth: 1
  };


  draw = (ctx) => {
    const { strokeStyle, lineWidth, point, pointTo } = this.props;

    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(pointTo.x - point.x, pointTo.y - point.y);
    ctx.strokeStyle = strokeStyle;
    ctx.lineWidth = lineWidth;
    ctx.stroke();
    ctx.closePath();
  };
}