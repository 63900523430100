/**
 * @name EventError
 * @file EventError
 * @author Yael
 * @since: 2020-12-22
 */
import React from 'react';
import { translate } from 'core/services/localization';

const EventError = ({ errorIcon, title, time, details, description }) => {
	return (
		<div className="crtx-event-error">
			<div>
				<img src={errorIcon} alt="error" />
			</div>
			<div className="ctrx-ee-content">
				<span className="crtx-ee-title">{title}</span>
				<div className="crtx-ee-two-columns">
					<span>{translate('Time:')}</span>
					<span>{time}</span>
					<span>{translate('Caused By:')}</span>
					<span>{details}</span>
					<span>{translate('Description:')}</span>
					<span>{description}</span>
				</div>
			</div>
		</div>
	);
};

export default EventError;
