
define(['sandbox', 'AbstractAction', 'core/services/dialogService'], function(sandbox, AbstractAction, dialogService) {
  'use strict';
  function submitCallback(values){
    var viewInstanceNwId = this.module.nwid;
    var deviceNwId = this.module.viewSettings.rootId;
    var projectorId = this.module.projectorId;
    var obj = this.module.selected[0];
    var type = obj["type"];
    var nwid = obj["nwid"];
    var request = sandbox.request.changeDynProperties(viewInstanceNwId, this.nwid, deviceNwId, type, projectorId,
      {
        config: this.config,
        nwid: nwid,
	      items: this.module.selected.map(function (e) {
		      return {nwid: e.nwid};
	      }),
        type: type,
        dynProperties: values
      }
    );

    request.then(function (res) {
      var code = res.statusCode;
      if (code !== 200){
        var message = res.errorMessage;
        if (res !== null){
          sandbox.dialog.alert(message);
        }
      } else {
        sandbox.dialog.alert(res.data.message);
      }
    });
  }

  return AbstractAction.extend({

    isApplicable: function () {
      var objects = this.module.selected;
      if (typeof objects === 'undefined') {
        return false;
      }
      return (objects.length >= 1);
    },
    execute: function (objs) {
      var nwid, type;
      var viewInstanceNwId = this.module.nwid;
      var deviceNwId = this.module.viewSettings.rootId;
      var projectorId = this.module.projectorId;
      var obj = this.module.selected[0];
      type = obj["type"];
      nwid = obj["nwid"];
      var request = sandbox.request.getDynPropOptions(viewInstanceNwId, this.nwid, deviceNwId, type, projectorId,
        {
          config: this.config,
	        items: objs.map(function (e) {
		        return {nwid: e.nwid};
	        }),
          nwid: nwid,
          type: type
        }
      );
      var that = this;
      request.then(function (res) {
        var code = res.statusCode;
        if (code === 200){
	        var options = res.data.props;
	        var str = sandbox.localization.translate("Change Properties");
	        var sNames = objs.map(function (e) {
		        return (e.name);
	        });
	        sandbox.dialog.customDialog(options, submitCallback, that, str + ' - ' + sNames, sandbox.localization.translate('Dynamic Properties'), '');
        }
      });
    }
  });
});