define(['react', 'sandbox'], function (React, sandbox) {
  'use strict';

  var Component = React.Component;
  var FULL_RECT = 0;
  var CENTER_SINGLE_RECT = 1;
  var OBLIQUE_RECTS = 2;
  var VERTICAL_CENTER = 3;
  var HORIZONTAL_CENTER = 4;

  var LOCAL = 0;
  var COMMON = 1;
  var COLOR_SHARE = 2;
  var MONO_SHARE = 3;


  var FALSE_PANORAMA = 0;
  var REAL_PANORAMA = 1;
  var REVERT_PANORAMA = 2;
  var translate = sandbox.localization.translate;

  function getColorPaintMode(separations, colorObjects) {
    var numColors = Object.keys(separations).length;
    if (numColors >= 3) {
      return FULL_RECT;
    }
    if (numColors === 1) {
      return CENTER_SINGLE_RECT;
    }
    var isCyan = separations.hasOwnProperty(colorObjects.cyan);
    var isMagenta = separations.hasOwnProperty(colorObjects.magenta);
    var isYellow = separations.hasOwnProperty(colorObjects.yellow);
    var isBlack = separations.hasOwnProperty(colorObjects.black);

    if (isCyan && isMagenta) {
      return HORIZONTAL_CENTER;
    }
    if (isYellow && isBlack) {
      return HORIZONTAL_CENTER;
    }
    if (isCyan && isYellow) {
      return VERTICAL_CENTER;
    }
    if (isMagenta && isBlack) {
      return VERTICAL_CENTER;
    }
    return OBLIQUE_RECTS;
  }

  function getSingleColor(separations, colorObjects) {
    var list = Object.keys(separations);
    if (list.length > 0) {
      for (var key in colorObjects) {
        var value = colorObjects[key];
        if (value === list[0]) {
          return key;
        }
      }
    }
    return "";
  }



  class Main extends Component {
    state = {};

    hideDialog = () => {
      this.props.actionController({}, "hideAllDialogs", []);
      //this.props.actionController({}, "hideCustomColorDialog", []);
      //this.props.actionController({}, "hideAdvanceSharingDialog", []);
    };

    render() {
      var style = {height:'100%'};

      return (
        <div style={style} className='pwpsContainer' onClick={this.hideDialog} onMouseLeave={this.hideDialog}>          
          <PropertiesDialog actionController={this.props.actionController} main={this} mapPages={this.props.mapPages}
            chaseMode={this.props.chaseMode}/>

          

          <Sections main={this} sections={this.props.sections} chaseMode={this.props.chaseMode}
            actionController={this.props.actionController} colorObjects={this.props.colorObjects} />
        </div>
      );
    };
  }

  

  class PropertiesDialog extends Component {
    //$$$$$ this.modelHandler.stepModel.sections
    selectedZone = null;
    selectedSection = null;
    selectedPage = null;
    
    waitingForMasterPageAssignment = false;
    state = {
      display: 'none',
      x: '0px',
      y: '0px',
      customProps: []
    };

    constructor(props) {
      super(props);
      props.actionController({}, "NukDialogInit", [this]);
    }

    colorShareAction = (event) => {
      var mode = parseInt(event.target.value, 10);        
      if (this.props.chaseMode){
        var actionResult = this.props.actionController(event, "chaseColorShare", [mode]);
        this.render();
        this.props.main.setState({ renderFlag: !this.state.renderFlag });
        return;
      } 
      //var hasDefMaster = true;
      if (mode !== LOCAL){
        // if (!this.selectedPage || !this.selectedZone){
        //   hasDefMaster = this.props.actionController(event, "hasDefaultMaster", []);
        // }
        if (!this.selectedPage){
          this.waitingForMasterPageAssignment = true;
          this.setState({ mode: event.target.value });
          this.props.main.setState({ renderFlag: !this.state.renderFlag });
          return;
        }
      } else {
        this.selectedPage = null;
        this.selectedSection = null;
        this.selectedZone = null;
      }
      // if (!hasDefMaster){
      //   // TODO add error
      //   this.setState({ mode: event.target.value });
      //   this.props.main.setState({ renderFlag: !this.state.renderFlag });
      //   return;
      // }

      //var actionResult = this.props.actionController(event, "colorShareAction", [this.props.page, mode]);
      var actionResult = this.props.actionController(event, "jumpShareActionNew", [mode, this.selectedZone, this.selectedSection, this.selectedPage]);
      for (var i = 0; i < actionResult.cells.length; i++) {
        if (actionResult.cells[i] && actionResult.cells[i].props) {
          actionResult.cells[i].props.stateChangeListener(actionResult);
        }
      }
      //this.hideDialog(event);
      this.setState({ mode: event.target.value });
      this.props.main.setState({ renderFlag: !this.state.renderFlag });
    };

    shareZoneChanged = (event) => {
      var selectedZoneName = event.target.value;
      var selectedSectionName = this.selectedSection ? this.selectedSection.title : "";      
      var selectedPageName = this.selectedPage ? this.selectedPage.title : "";
      this.selectedZone = this.props.actionController(event, "getPotentialSelectedObject", ["zone", selectedZoneName, selectedSectionName, selectedPageName]);
      console.log ("@@@@@@@ shareZoneChanged selectedPageName=" + selectedPageName);
      console.log ("@@@@@@@@@@@@@@@ " + this.waitingForMasterPageAssignment);
      //if (this.waitingForMasterPageAssignment){
        this.waitingForMasterPageAssignment = false;
        this.selectedSection = this.props.actionController(event, "getPotentialSelectedObject", ["section", selectedZoneName, selectedSectionName, selectedPageName]);
        selectedSectionName = this.selectedSection ? this.selectedSection.title : "";
        this.selectedPage = this.props.actionController(event, "getPotentialSelectedObject", ["page", selectedZoneName, selectedSectionName, selectedPageName]);
        selectedPageName = this.selectedPage ? this.selectedPage.title : "";
        console.log ("@@@@@@@1111111 shareZoneChanged selectedPageName=" + selectedPageName);
      //}
      var actionResult = this.props.actionController(event, "jumpShareActionNew", [this.state.mode, this.selectedZone, this.selectedSection, this.selectedPage]);
     
      this.render();
      this.props.main.setState({ renderFlag: !this.state.renderFlag });

    };

    shareSectionChanged = (event) => {

      var selectedZoneName = this.selectedZone ? this.selectedZone.title : "";
      var selectedSectionName = event.target.value;
      var selectedPageName = this.selectedPage ? this.selectedPage.title : "";
      this.selectedSection = this.props.actionController(event, "getPotentialSelectedObject", ["section", selectedZoneName, selectedSectionName, selectedPageName]);
      selectedSectionName = this.selectedSection ? this.selectedSection.title : "";
      this.selectedPage = this.props.actionController(event, "getPotentialSelectedObject", ["page", selectedZoneName, selectedSectionName, selectedPageName]);
      selectedPageName = this.selectedPage ? this.selectedPage.title : "";
      console.log ("@@@@@@@ shareSectionChanged selectedPageName=" + selectedPageName);
      var actionResult = this.props.actionController(event, "jumpShareActionNew", [this.state.mode, this.selectedZone, this.selectedSection, this.selectedPage]);

      this.render();
      this.props.main.setState({ renderFlag: !this.state.renderFlag });

    };

    sharePageChanged = (event) => {
      var selectedZoneName = this.selectedZone ? this.selectedZone.title : "";
      var selectedSectionName = this.selectedSection ? this.selectedSection.title : "";
      var selectedPageName = event.target.value;
      this.selectedPage = this.props.actionController(event, "getPotentialSelectedObject", ["page", selectedZoneName, selectedSectionName, selectedPageName]);
      console.log ("@@@@@@@ sharePageChanged selectedPageName=" + selectedPageName);
      var actionResult = this.props.actionController(event, "jumpShareActionNew", [this.state.mode, this.selectedZone, this.selectedSection, this.selectedPage]);
      //var actionResult = this.props.actionController(event, "jumpShareAction", [this.state.mode, this.selectedZone, this.selectedPage]);
      this.render();
      this.props.main.setState({ renderFlag: !this.state.renderFlag });
    };

    handlePanorama = (event) => {
      this.props.actionController(event, "panoramaAction", [this, event.target.checked ? REAL_PANORAMA : REVERT_PANORAMA]);
      this.setState({ isPanorama: event.target.checked });
      this.props.main.setState({ renderFlag: !this.state.renderFlag });
    };
    

    splitAction = (event) => {
      this.props.actionController(event, "splitPage", [event.target.checked]);
      this.setState({ isSplit: event.target.checked });
      this.props.main.setState({ renderFlag: !this.state.renderFlag });
      this.hideDialog();
    };

    nwxLocalChange = (event) => {
      var actionSrc = event.target.dataset.value;
      this.props.actionController(event, "doException", [actionSrc, event.target.checked, this.state]);
      // this.props.actionController(event, "splitPage", [event.target.checked]);
      // this.setState({ isSplit: event.target.checked });
      // this.props.main.setState({ renderFlag: !this.state.renderFlag });
      this.hideDialog();
    };
    exceptionAction = (event) => {
      var actionSrc = event.target.dataset.value;
      this.props.actionController(event, "doException", [actionSrc, event.target.checked, this.state]);
      // this.setState({ isSplit: event.target.checked });      
      this.props.main.setState({ renderFlag: !this.state.renderFlag });
      var exceptionPages = this.state.killInfo.exceptionPages || 0;
      var killInfo = this.state.killInfo || {};
      if (exceptionPages === 0){
        killInfo.exceptionType = "";                 
      } else {
        killInfo.exceptionType = actionSrc === 'slip' || actionSrc === 'slipText' ? "slip" : "kill";          
      }
      this.setState({killInfo: killInfo}); 
      this.hideDialog();
      console.log (event);
    };

    dinkyAction = (event) => {
      this.props.actionController(event, "dinkyPage", [event.target.checked]);
      this.setState({ isDinky: event.target.checked });
      this.props.main.setState({ renderFlag: !this.state.renderFlag });
      this.hideDialog();
    };

    propChangeAction = (event) => {
      var actionResult = this.props.actionController(event, "customPropertyChange", [event.target.dataset.value, event.target.checked]);
      //for (var i=0;i<actionResult.cells.length;i++){
      //  actionResult.cells[i].props.stateChangeListener(actionResult);
      //}
      var state = {};
      state[event.target.dataset.value] = event.target.checked;
      this.props.main.setState({ renderFlag: !this.state.renderFlag });
      this.setState(state);
    };

    clickHandler = (event) => {
      event.stopPropagation();
    };

    hideDialog = (event) => {
      this.selectedPage = null;
      this.selectedSection = null;
      this.selectedZone = null;
      this.props.actionController(event, "hideAllDialogs", []);
    };


    

    render() {
      /*

       {display : 'inline', x: x, y: y, props: props,
       panoramaState:panoramaState, singleSelection:this.selectedPages.length === 1});
       */


      if (this.state.clearSelected){
        this.state.clearSelected = false;
        this.selectedPage = null;
        this.selectedSection = null;
        this.selectedZone = null;
      }
      function panoramaFieldStyle(obj) {
        if (obj.state.panoramaApplicable) {
          //return {display:'inline'};

        } else {
          //return {display:'none'};
        }
      }

      var options = [0, 2, 4, 6, 8, 10, 12].map(function (numInserts) {
        return <option value={numInserts}>{numInserts}</option>
      }, this);


      

      function dinkyFieldStyle(obj) {
        if (!obj.state.showDinky) {
          return { display: 'none' };
        }
      }

      
      function getSectionsList(list){
        for (var l=0;l<list.length;l++){
          if(list[l].selected){
            return list[l].sections;
          }
        }
        return [];
      }

      function getPagesList(list){
        for (var l=0;l<list.length;l++){
          if(list[l].selected){
            return list[l].pages;
          }
        }
        return [];
      }

      var style = {
        width: '190px', height: '340px', display: this.state.display, backgroundColor: 'white',
        position: 'absolute', top: this.state.y + "px", left: this.state.x + "px", zIndex: 2, outline: '1px ridge gray'
      };

      var buttonStyle = {
        width: '10px', height: '10px', position: 'absolute', top: '0px', right: '0px',
        margin: '0px 0px 0px 0px', padding: '0px 0px 0px 0px', fontSize: '6px'
      };
      var tableStyle = { width: '100%', paddingTop: '12px' };
      var tdStyle = { width: '70%' };

      
      var showDinky = this.state.showDinky;
      var customProps = this.state.customProps.map(function (prop) {

        return (
          <tr>
            <td>{prop.title}:</td>
            <td style={tableStyle}><input data-value={prop.name} type='checkbox' checked={this.state[prop.name]} onChange={this.propChangeAction} /></td>
          </tr>
        )


      }, this);



      

      //////////////////////////////////////////////////////////-----------
      //$$$
      var disabled = !this.state.singleSelection;
      var singleSelectedDisabled = !this.state.singleSelection;
      var csDisabled = !this.state.singleSelection;
      if (!disabled){
        disabled = this.state.mode === LOCAL;
      }
      
      
///////////////$#%$#^$%^^$%^ nwhy selectedSection is null ? check after making it local
      var zoneOptionsList = this.state.display === 'none' ? [] : this.props.actionController(null, "getZonesOptionsForSelectedPageNew", [this.selectedZone, this.selectedSection]);      
      var sectionOptionsList = getSectionsList(zoneOptionsList);
      var pageOptionsList = getPagesList(sectionOptionsList);
      // this.selectedZone = null, this.selectedSection = null, this.selectedPage = null;
      
      var zoneOptions = zoneOptionsList.map(function (zone) {
        if (zone.virtual){
          return "";
        }  
        if (zone.selected){
          this.selectedZone = zone;
        }
        return <option value={zone.title} selected={zone.selected} >{zone.title}</option>        
      }, this);

      this.selectedSection = null;
      var sectionOptions  = sectionOptionsList.map(function (section) {  
        if (section.selected){
          this.selectedSection = section;
        }        
        return <option value={section.title} selected={section.selected} >{section.title}</option>        
      }, this);

      this.selectedPage = null;
      var pageOptions = pageOptionsList.map(function (page) {             
        if (page.selected){          
          this.selectedPage = page;
        }   
        return <option value={page.title} selected={page.selected} disabled={page.disabled}>{page.title}</option>
      }, this);

      function jumpShareZoneUI(obj) {
        if (obj.props.chaseMode) {
          return "";
        }

        return (
          <tr>
            <td>Zone:</td>

            <td>
              <select onChange={obj.shareZoneChanged} onClick={obj.clickHandler}
                value={obj.state.selectedZone} disabled={disabled}>
                {zoneOptions}
              </select>
            </td>
          </tr>
        )
      }

      function jumpShareSectionUI(obj){
        if (obj.props.chaseMode) {
          return "";
        }

        return (
          <tr>
            <td>Section:</td>

            <td>
              <select onChange={obj.shareSectionChanged} onClick={obj.clickHandler}
                value={obj.state.selectedSection} disabled={disabled}>
                {sectionOptions}
              </select>
            </td>
          </tr>
        )
      }

      function jumpSharePageUI(obj) {
        if (obj.props.chaseMode) {
          return "";
        }

        return (
          <tr>
            <td>Page:</td>

            <td>
              <select onChange={obj.sharePageChanged} onClick={obj.clickHandler}
                value={obj.state.selectedPage} disabled={disabled}>
                {pageOptions}
              </select>
            </td>
          </tr>
        )
      }

      


        var killInfo = this.state.killInfo || {};
        var number = killInfo && killInfo.exceptionNumber ? killInfo.exceptionNumber : 0;
        var isMaster = this.state.isMaster;

        var exceptionType = killInfo.exceptionType;
        var process5 = killInfo.process5;
        var killDisabled = exceptionType === "slip" || number === 0 || !isMaster;
        var slipDisabled = exceptionType === "kill" || number === 0 || !isMaster;
        var textStyle = process5 ? { visibility: 'visible' } : { visibility: 'hidden' };
        var exctitle = process5 ? "Both" : "";

        var npxLocalDisabled = false;
        var nwxLocal = this.state.nwxLocal || false;

      return (
        <div style={style} onClick={this.clickHandler} className="myDialog">

          <input type='button' style={buttonStyle} value='x' onClick={this.hideDialog} />

          <table style={tableStyle} cellPadding="5" cellSpacing="5">
            <tbody>
              <tr disabled={!this.state.panoramaApplicable}>
                <td style={tdStyle}>{translate('Spread')}</td><td  ><input type='checkbox' checked={this.state.isPanorama} onChange={this.handlePanorama}
                  disabled={!this.state.panoramaApplicable} /></td>
              </tr>
              <tr disabled={!this.state.splitApplicable}>
                <td style={tdStyle}>{translate('A/B split')}</td><td  ><input type='checkbox' checked={this.state.isSplit} onChange={this.splitAction}
                  disabled={!this.state.splitApplicable} /></td>
              </tr>
              <tr disabled={singleSelectedDisabled} style={dinkyFieldStyle(this)}>
                <td style={tdStyle}>{translate('Fly Sheet')}</td><td  ><input type='checkbox' checked={this.state.isDinky} onChange={this.dinkyAction}
                  disabled={singleSelectedDisabled} /></td>
              </tr>
              {customProps}
              <tr>
                <td colSpan='2'><hr/></td>
                </tr>
              <tr>
              <td>Type:</td><td>
                <select onChange={this.colorShareAction} onClick={this.clickHandler}
                  value={parseInt(this.state.mode, 10)}>
                  <option value={COLOR_SHARE} disabled={csDisabled}>Color Share</option>                  
                  <option value={COMMON} disabled={csDisabled}>Common</option>
                  <option value={LOCAL}>Local</option>
                </select>
              </td>
            </tr>
            {jumpShareZoneUI(this)}
            {jumpShareSectionUI(this)}
            {jumpSharePageUI(this)}
              
              <tr>
              <td colSpan='2'><hr/></td>
              </tr>

              <tr disabled={npxLocalDisabled}>
              <td style={tdStyle}>{translate('NWX Local' )}</td><td  ><input data-value='nwxLocal' type='checkbox' checked={nwxLocal} onChange={this.nwxLocalChange}
                disabled={npxLocalDisabled} /></td>
              </tr>

              <tr>
              <td colSpan='2'><hr/></td>
              </tr>

              <tr disabled={slipDisabled}>
              <td style={tdStyle}>{translate('Slip {1}', exctitle)}</td><td  ><input data-value='slip' type='checkbox' checked={!slipDisabled && this.state.slip  === true} onChange={this.exceptionAction}
                disabled={slipDisabled} /></td>
              </tr>
              <tr disabled={slipDisabled} style={textStyle}>
              <td style={tdStyle}>{translate('Slip Text')}</td><td  ><input data-value='slipText' type='checkbox' checked={!slipDisabled &&this.state.slipText  === true} onChange={this.exceptionAction}
                disabled={slipDisabled} /></td>
              </tr>

              <tr disabled={killDisabled}>
              <td style={tdStyle}>{translate('Kill {1}', exctitle)}</td><td  ><input data-value='kill' type='checkbox' checked={!killDisabled && this.state.kill  === true} onChange={this.exceptionAction}
                disabled={killDisabled} /></td>
              </tr>
              <tr disabled={killDisabled} style={textStyle}>
              <td style={tdStyle}>{translate('Kill Text')}</td><td  ><input data-value='killText' type='checkbox' checked={!killDisabled && this.state.killText  === true} onChange={this.exceptionAction}
                disabled={killDisabled} /></td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    };
  }

  class ColorIcon extends Component {

    state = {
      cellHOver: false
    };

    getSepVisibilityStyle = (colorObjects, sepValue) => {
      var sepDisplayName = colorObjects[sepValue];
      if (this.props.page.virtual)
        return { visibility: 'hidden' };
      return this.props.page.separations.hasOwnProperty(sepDisplayName) ? { visibility: 'visible' } : { visibility: 'hidden' };
    };

    getSingleSepStyle = () => {
      return { position: 'relative', top: '6px', left: '6px', width: '12px', height: '12px' };
    };

    getColorRectStyle = (colorObjects, sepValue) => {
      var sepDisplayName = colorObjects[sepValue];
      if (this.props.page.separations.hasOwnProperty(sepDisplayName)) {
        var sep = this.props.page.separations[sepDisplayName];
        if (sep.masterSeparation === null || typeof sep.masterSeparation === "undefined") {
          if (sep.baseSeparation === null || typeof sep.baseSeparation === "undefined") {
            return sepValue;
          }
          return sepValue + "Share";
        } else {
          return sepValue + "Share";
        }
      } else if (typeof this.props.page.masterPage !== 'undefined' && this.props.page.masterPage !== null) {
        return sepValue + "Share";
      }
      return ""
    };

    mouseOverEvent = () => {
      if (this.props.chaseMode) {
        return;
      }
      this.setState({ cellHOver: true });
      this.props.cellStateHandle({ advanceColorVisibility: true });
    };

    mouseOutEvent = () => {
      if (this.props.chaseMode) {
        return;
      }
      this.setState({ cellHOver: false });
      this.props.cellStateHandle({ advanceColorVisibility: true });
    };

    render() {
      var singleSepContainerStyle = { width: '24px !important', height: '24px !important' };
      var containerStyle = this.state.cellHOver ? { outline: "1px outset white" } : {};
      var isLink = (typeof this.props.page.masterPage !== 'undefined' && this.props.page.masterPage !== null)
        || (this.props.page.basePage !== null || typeof this.props.page.basePage === "undefined");
      var colorObjects = this.props.colorObjects;
      containerStyle.cursor = isLink ? "default" : "pointer";
      switch (getColorPaintMode(this.props.page.separations, colorObjects)) {
        case OBLIQUE_RECTS:
        case FULL_RECT:
          return (

            <div style={containerStyle} className='colorIcon' onClick={this.handleColorIconClick} onMouseOver={this.mouseOverEvent} onMouseOut={this.mouseOutEvent}>

              <div className='colorIconRow'>
                <div className={this.getColorRectStyle(colorObjects, 'cyan')} style={this.getSepVisibilityStyle(colorObjects, 'cyan')}>&nbsp;</div>
                <div className={this.getColorRectStyle(colorObjects, 'magenta')} style={this.getSepVisibilityStyle(colorObjects, 'magenta')} >&nbsp;</div>
              </div>
              <div className='colorIconRow'>
                <div className={this.getColorRectStyle(colorObjects, 'yellow')} style={this.getSepVisibilityStyle(colorObjects, 'yellow')} >&nbsp;</div>
                <div className={this.getColorRectStyle(colorObjects, 'black')} style={this.getSepVisibilityStyle(colorObjects, 'black')} >&nbsp;</div>
              </div>
            </div>

          );
          break;
        case CENTER_SINGLE_RECT:
          return (
            <div className='colorIcon' style={containerStyle} onClick={this.handleColorIconClick} onMouseOver={this.mouseOverEvent} onMouseOut={this.mouseOutEvent} >
              <div className='colorIconRow'>
                <div className='singleSepContainerStyle'>
                  <div className={this.getColorRectStyle(colorObjects, getSingleColor(this.props.page.separations, colorObjects))} style={this.getSingleSepStyle()}>&nbsp;</div>
                </div>
              </div>
            </div>

          );
          break;
        default:
          return (

            <div className='colorIcon' style={containerStyle} onClick={this.handleColorIconClick} onMouseOver={this.mouseOverEvent} onMouseOut={this.mouseOutEvent}>
              <div className='colorIconRow'>
                <div className={this.getColorRectStyle(colorObjects, 'cyan')} style={this.getSepVisibilityStyle(colorObjects, 'cyan')}>&nbsp;</div>
                <div className={this.getColorRectStyle(colorObjects, 'magenta')} style={this.getSepVisibilityStyle(colorObjects, 'magenta')} >&nbsp;</div>
              </div>
              <div className='colorIconRow'>
                <div className={this.getColorRectStyle(colorObjects, 'yellow')} style={this.getSepVisibilityStyle(colorObjects, 'yellow')} >&nbsp;</div>
                <div className={this.getColorRectStyle(colorObjects, 'black')} style={this.getSepVisibilityStyle(colorObjects, 'black')} >&nbsp;</div>
              </div>
            </div>

          );

      }

    };

    handleColorIconClick = (event) => {
      if (this.props.chaseMode) {
        var page = this.props.page;
        if (page.isChase)
          return;
      }
      this.props.actionController(event, "hideAllDialogs", []);
      this.props.selectionHandler(event);
      if (event.ctrlKey || event.metaKey || event.shiftKey) {
        return;
      }
      var actionResult = this.props.actionController(event, "colorChangeAction", [this.props.page]);
      if (typeof actionResult.cells === 'undefined') {
        return;
      }
      //for (var i=0;i<actionResult.cells.length;i++){
      //  actionResult.cells[i].props.stateChangeListener(actionResult);
      //}
      this.props.cell.fireSectionSizeChange({});
    };

  }

  class Cell extends Component {

    state = {
      spHOver: false,
      customHOver: false,
      cellHOver: false,
      hover: false,
      selected: false,
      advanceColorVisibility: false
    };

    mouseOver = () => {
      this.setState({ cellHOver: true });
    };

    mouseOut = () => {
      this.setState({ cellHOver: false });
    };

    selectCell = (event) => {
      var actionResult = this.props.actionController(event, "selectionDone", [this.props.page, this]);
      this.props.actionController(event, "hideAllDialogs", []);
      this.fireSectionSizeChange({});
    };

    fireSectionSizeChange = (changeEvent) => {
      this.props.sectionChangeListener(changeEvent);
    };

    imgMouseOverEvent = (event) => {
      this.handleMouseEvent(event, true);
    };

    imgMouseOutEvent = (event) => {
      this.handleMouseEvent(event, false);
    };

    stateHandle = (stateObject) => {
      this.setState(stateObject);
    }

    handleMouseEvent = (event, isOver) => {
      var className = event.currentTarget.className;
      if (className === "customPropsRightSectionNuk") {
        this.setState({ customHOver: isOver })
      } else if (className === "sharingSection") {
        this.setState({ hover: isOver });
      } else if (className === "customShareIcon") {
        this.setState({ ccHOver: isOver });
      } else if (className === "customColorIcon") {
        this.setState({ spHOver: isOver });
      }
    };

    render() {
      var killValue = function(context){
        var k = context.props.page.kill;
        var kt = context.props.page.killText;
        var s = context.props.page.slip;
        var st = context.props.page.slipText;
        var process5 = context.props.killInfo.process5;
        if (process5){
          if (k && kt){
            return "K:Both";
          } else if (k){
            return "K:C"
          } else if (kt){
            return "K:T"
          } else if (s && st){
            return "S:Both";
          } else if (s){
            return "S:C"
          } else if (st){
            return "S:T"
          } else {
            if (k){
              return "K";
            } else if (s){
              return "S";
            }
          }
        } else {
          if (k){
            return "K";
          } else if (s){
            return "S";
          }
        }
        return "";
        
      }(this);
      var customProps = Object.keys(this.props.page.customProperties).map(function (prop) {

        if (this.props.page.customProperties[prop]) {
          return prop + " ";
        }
        return "";


      }, this);

      function getDinkyProp(obj) {
        var isDinky = obj.props.page.dinky;
        if (!isDinky) {
          return "";
        }
        return <div>
          F
            </div>
      }

      
      function getCustomPropertiesSection(obj) {
        var src = obj.state.customHOver ? sandbox.icons.getModuleIcon("PlanningWizard", "menu_hover") :
          sandbox.icons.getModuleIcon("PlanningWizard", "menu");
        var rightStyle = { visibility: obj.state.cellHOver ? 'visible' : 'hidden' };
        // if (obj.props.chaseMode) {
        //   return (
        //     <div className='customPropsContainer'>

        //     </div>);
        // }
        return (
          <div className='customPropsContainer'>
            <div className='customPropsLeftSide'>{killValue}</div>
            <div style={rightStyle} className='customPropsRightSectionNuk' onMouseOver={obj.imgMouseOverEvent} onMouseOut={obj.imgMouseOutEvent}>
              <div><img src={src} onClick={obj.showDialog} /></div>
            </div>
          </div>);
      }

      function getSharingSection(obj) {
        //var jumpShareWidth = obj.props.page.jumpShare ? {width:'50px', minWidth:'50'} : {};
        var cursor = { cursor: 'pointer' };        
        var masterTitle = obj.props.page.jumpShare && 
          (obj.props.page.masterPageName !== obj.props.page.title || obj.props.page.masterSection !== obj.props.page.mySection )?
          "(" + obj.props.page.masterPageName + ")" : "";

        if (masterTitle != "" && obj.props.page.masterSection !== obj.props.page.mySection)  {
          masterTitle = "(" + obj.props.page.masterSection + "/" + obj.props.page.masterPageName + ")"
        }

        //if (obj.props.page.masterPageZone !== obj.props.page.masterZone) {
        //  masterTitle = "";
        //}
        
        if (obj.props.chaseMode) {
          /*
           options are :
            page is master but not local
            page is master and local
          */
          var localSrc = sandbox.icons.getModuleIcon("PlanningWizard", "local")
          var masterButNotLocal = sandbox.icons.getModuleIcon("PlanningWizard", "localGrey")
          var hasBase = typeof obj.props.page.basePage !== "undefined" && obj.props.page.basePage !== null
          var hasMaster = typeof obj.props.page.masterPage !== "undefined" && obj.props.page.masterPage !== null
          if (!hasBase && !hasMaster) {
            // i'm local
            return (<div className='sharingSectionNuk' onClick={obj.handleShareIconClick} >
              <span style={cursor}><img src={localSrc} style={cursor} /></span>
            </div>);
          } else if (!hasMaster) {
            return (<div className='sharingSectionNuk' onClick={obj.handleShareIconClick} >
              <span style={cursor}><img src={masterButNotLocal} style={cursor} /></span>
            </div>);
          } else {
            return (<div className='sharingSectionNuk' onClick={obj.handleShareIconClick} >
              <span style={cursor}>{obj.props.page.masterZone}</span>
            </div>);
          }

        } else {
          if (!obj.props.page.isMasterOptional && obj.props.chaseMode) {
            if (typeof obj.props.page.basePage === "undefined" || obj.props.page.basePage === null) {
              var style = { visibility: obj.state.cellHOver ? 'visible' : 'hidden' };

              //var style = {visibility:obj.state.cellHOver ? 'visible' : 'hidden', float:'right'};
              var src = obj.state.hover ? sandbox.icons.getModuleIcon("PlanningWizard", "local_hover") :
                sandbox.icons.getModuleIcon("PlanningWizard", "local");
              return (<div className='sharingSectionNuk' style={style} onMouseOver={obj.imgMouseOverEvent} onMouseOut={obj.imgMouseOutEvent}>
                <div><img src={src} onClick={obj.handleShareIconClick} style={cursor} /></div>
              </div>);

            } else {
              return (<div className='sharingSectionNuk' onClick={obj.handleShareIconClick}>
                <span style={cursor}></span>
              </div>);
            }
          } else if (!obj.props.page.isMasterOptional) {
            var src = obj.state.hover ? sandbox.icons.getModuleIcon("PlanningWizard", "local_hover") :
              sandbox.icons.getModuleIcon("PlanningWizard", "local");
            return (<div className='sharingSectionNuk'><img src={src} /></div>);
          } else if ((typeof obj.props.page.masterZone === "undefined" || obj.props.page.masterZone === null) && !obj.props.page.virtual) {
            var style = { visibility: obj.state.cellHOver ? 'visible' : 'visible' };

            //var style = {visibility:obj.state.cellHOver ? 'visible' : 'hidden', float:'right'};
            var src = obj.state.hover ? sandbox.icons.getModuleIcon("PlanningWizard", "local_hover") :
              sandbox.icons.getModuleIcon("PlanningWizard", "local");
            return (<div className='sharingSectionNuk' style={style} onMouseOver={obj.imgMouseOverEvent} onMouseOut={obj.imgMouseOutEvent}>
              <div><img src={src} onClick={obj.handleShareIconClick} style={cursor} /></div>
            </div>);

          } else {
            //var jumpShareWidth = obj.props.page.jumpShare ? {width:'50px', minWidth:'50'} : {};
            return (<div className='sharingSectionNuk' onClick={obj.handleShareIconClick} >
              <span style={cursor}>{obj.props.page.masterZone + masterTitle}</span>
            </div>);
          }
        }

      }
      

      function getCustomShareIcon(obj) {
        var singleSelection = obj.props.actionController({}, "isSingleSelection", [])
        if (!obj.props.page.isMasterOptional) {
          return (<div className='customColorIcon'></div>);
        }
        var style = { visibility: obj.state.cellHOver && singleSelection ? 'visible' : 'hidden' };
        var src = obj.state.spHOver ? sandbox.icons.getModuleIcon("PlanningWizard", "dropdown_arrow_hover") :
          sandbox.icons.getModuleIcon("PlanningWizard", "dropdown_arrow");
        return <div><img className='customColorIcon' style={style} src={src} onMouseOver={obj.imgMouseOverEvent} onMouseOut={obj.imgMouseOutEvent} onClick={obj.showDialog} /></div>
      }

      function getSelectionStyle(obj) {
        var style = {};
        //if (obj.state.selected) {
        if (obj.props.page.selectedState) {
          style.border = 'solid 3px #83b5ff';
        } else if (obj.state.cellHOver) {
          style.border = 'solid 3px #bdd8ff';
        } else {
          //style = {border: ''};
        }
        var key = obj.props.page.masterPageZone !== null && typeof obj.props.page.masterPageZone !== "undefined" ?
          obj.props.page.masterPageZone : obj.props.actionController({}, "findZone", [obj.props.page]);

        var value = obj.props.page.isChase ? "white" : obj.props.zonesColorMap[key];// zonesBgColors[key];
        style.backgroundColor = value;

        style.visibility = obj.props.page.virtual ? 'hidden' : 'visible';

        return style;
      }


      return (
        <div className='recordCell' onClick={this.selectCell} ref={'cell' + this.props.page.sourceId}
          style={getSelectionStyle(this)} onMouseOver={this.mouseOver} onMouseOut={this.mouseOut}>
          <div className='cellContentContainer'>
            <div className='cellContentContainerLabel' >{this.props.page.title}</div>

            <ColorIcon page={this.props.page} actionController={this.props.actionController}
              stateChangeListener={this.props.stateChangeListener}
              selectionHandler={this.selectCellController}
              cellStateHandle={this.stateHandle}
              chaseMode={this.props.chaseMode}
              colorObjects={this.props.colorObjects}
              cell={this} />

            

            {getSharingSection(this)}            
            {getDinkyProp(this)}
            {getCustomPropertiesSection(this)}
          </div>


        </div>
      );
    };

    selectCellController = (event) => {
      event.stopPropagation();
      if (!this.props.page.selectedState)
        this.selectCell(event);
    };

    handleShareIconClick = (event) => {
      this.selectCellController(event);
      if (event.ctrlKey || event.metaKey || event.shiftKey) {
        return;
      }
      var actionResult = this.props.actionController(event, "shareAction", [this.props.page]);
      this.props.stateChangeListener(actionResult);
      this.fireSectionSizeChange({});
    };    

    showDialog = (event) => {
      this.selectCellController(event);
      var actionResult = this.props.actionController(event, "ShowNukAdvanceSharingDialog", [this.props.page]);
      this.props.stateChangeListener(actionResult);
    };

  }

  class Row extends Component {

    state = {
      rowChangeFlag: false,
      rowTitleHOver: false
    };

    stateChangeListener = (actionResult) => {
      this.setState({ rowChangeFlag: !this.state.rowChangeFlag });
    };

    imgMouseOverEvent = (event) => {
      this.setState({ rowTitleHOver: true })
    };

    imgMouseOutEvent = (event) => {
      this.setState({ rowTitleHOver: false })
    };

    getStyle = () => {
      for (var i = 0; i < this.props.zones.length; i++) {      
        if (this.props.zonedPage.hidden) {
          return { display: 'none' };    
        }
        var page = this.props.zonedPage.zoneMap[this.props.zones[i].displayName];        
        if (page !== null && typeof page !== "undefined") {          
          if (!page.virtual) {
            return { display: 'table-row' };
          }
        }
      }
      return { display: 'none' };
    };

    render() {

      var cells = this.props.zones.map(function (zone) {
        if (zone.hidden){
          return null;
        }
        var zoneName = zone.displayName;
        if (!this.props.zonedPage.zoneMap.hasOwnProperty(zoneName)) {
          return (<div className='recordCell'></div>);
        }
        var page = this.props.zonedPage.zoneMap[zoneName];
        var sourceId = page.sourceId;
        return (<Cell rowIndex={this.props.index} page={page} sourceId={sourceId}
          stateChangeListener={this.stateChangeListener}
          actionController={this.props.actionController}
          sectionChangeListener={this.props.sectionChangeListener} zonesColorMap={this.props.zonesColorMap}
          ref={"cell" + page.sourceId}
          colorObjects={this.props.colorObjects}
          chaseMode={this.props.chaseMode}
          zoneName={zoneName}
          sectionsChangeListener={this.props.sectionsChangeListener} killInfo={this.props.killInfo}/>);


      }, this);

      var src = sandbox.icons.getModuleIcon("PlanningWizard", "row_arrow");
      var style = { visibility: this.state.rowTitleHOver ? 'visible' : 'hidden' };
      //var src = this.state.rowTitleHOver ? sandbox.icons.getModuleIcon("PlanningWizard", "row_arrow") :
      //  sandbox.icons.getModuleIcon("PlanningWizard", "row_arrow");
      return (
        <div className='recordRow' style={this.getStyle()}>
          <div className='recordCellTitle' onClick={this.handleClick} onMouseOver={this.imgMouseOverEvent} onMouseOut={this.imgMouseOutEvent}>
            <img className='recordCellTitleImg' src={src} style={style} /></div>
          {cells}
        </div>
      );
    };

    handleClick = (event) => {
      var that = this;
      if (event.ctrlKey || event.metaKey) {
        this.props.actionController(event, "rowAction", [true]);
      }

      for (var ref in this.refs) {
        var obj = React.findDOMNode(that.refs[ref]);
        obj.click();
        this.props.actionController(event, "rowAction", [true]);
      }
      this.props.actionController(event, "rowAction", [false]);
    };
  }
 
  class HeaderRow extends Component {
    render() {

      var cols = this.props.zones.map(function (zone) {
        if (zone.hidden){
          return null;
        }
        var zoneName = zone.displayName;
        var zoneFullName = zone.title;
        var style = { backgroundColor: zone.color };//{position: '-webkit-sticky', position: 'sticky', top: '0px'};
        // var backgroundColor = zone.color;//zonesBgColors[zoneName];

        // style.backgroundColor = backgroundColor;
        return (<div className='headerCell' style={style}>{zoneFullName} ({zoneName})</div>);
      });
      // var style1 = { position: '-webkit-sticky', position: 'sticky', top: '0px' };
      // var style = { backgroundColor: '#a9a9a9', minWidth: '60px', position: '-webkit-sticky', position: 'sticky', top: '0px'};

      return (
        <div className='headerRow' >
          <div className='headerCell'>{this.props.sectionName}</div>
          {cols}
        </div>
      );
    };
  }

  class Section extends Component {

    state = {
      sectionChangeFlag: false
    };

    sectionChangeListener = (changeEvent) => {
      this.setState({ sectionChangeFlag: !this.state.sectionChangeFlag });
    };

    render() {
      var zonesColorMap = {};
      this.props.section.zones.map(function (zone) {
        zonesColorMap[zone.displayName] = zone.color;
      });
      var rows = this.props.section.zonedPages.map(function (zonedPage, index) {
        return (<Row sectionsChangeListener={this.props.sectionsChangeListener} index={index} colorObjects={this.props.colorObjects} zonedPage={zonedPage} zones={this.props.section.zones} actionController={this.props.actionController}
          sectionChangeListener={this.sectionChangeListener} zonesColorMap={zonesColorMap}
          chaseMode={this.props.chaseMode} killInfo={this.props.section.killInfo}/>);
      }, this);
      var style = { display: this.props.section.dataSection ? "none" : "table" };
      return (
        <div className='pagesTable' style={style}>
          <HeaderRow colorObjects={this.props.colorObjects} zones={this.props.section.zones}
            actionController={this.props.actionController}
            sectionName={this.props.section.displayName} />
          {rows}
        </div>

      );
    };
  }

  class Sections extends Component {

    state = {
      sectionsChangeFlag: false
    };

    sectionsChangeListener = () => {
      this.setState({ sectionsChangeFlag: !this.state.sectionsChangeFlag });
    };

    render() {
      var that = this;
      var sectionTables = this.props.sections.map(function (section) {        
        return (<Section sectionsChangeListener={that.sectionsChangeListener} colorObjects={that.props.colorObjects} section={section} actionController={that.props.actionController} chaseMode={that.props.chaseMode}></Section>);
      });
      return (
        <div>
          {sectionTables}
        </div>
      );
      //return (<h1> Hello, world! I am Ilan.</h1>);
    }
  }

  //var zonesBgColors = {};

  class PagesTable extends Component {

    render() {
      return (
        <Main sections={this.props.sections} actionController={this.props.actionController}
          colorMode={this.props.colorMode} chaseMode={this.props.chaseMode} mapPages={this.props.mapPages}
          colorObjects={this.props.colorObjects} />
      );
    };
  }


  return {
    PagesTable: PagesTable,
    contents: null
  };
});
