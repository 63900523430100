import React from 'react';
import PropTypes from 'prop-types';

function FlatButton(
  {
    children,
    className = '',
    style,
    disabled = false,
    tooltip,
    onClick
  }) {

  const handleClick = event => {
    if (disabled) {
      return;
    }

    if (typeof onClick === 'function') {
      onClick(event);
    }
  };

  const cssClass = `crtx-flat-button ${className} ${disabled ? 'disabled' : ''}`;

  return (
    <div
      className={cssClass}
      style={style}
      title={tooltip}
      onClick={handleClick}
    >
      {children}
    </div>
  );
}

FlatButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  onClick: PropTypes.func
};

export default FlatButton;
