import AbstractAction from 'AbstractAction';
import sandbox from 'sandbox';
import toastService from 'core/services/toastService';
import TogglePropertyAction from './TogglePropertyAction';

const translate = sandbox.localization.translate;
const labels = {
  comment: translate('Comment:'),  
  errorMessage: translate('Failed'),
};

export default TogglePropertyAction.extend({

  

  execute: function (selectedItems) {
    const viewInstanceNwId = this.module.nwid;    

    const rest = sandbox.request.rest(viewInstanceNwId);
    const folderNwId = this.module.folderNwid;    
    const propertyName = this.config.propertyName;

    let items = [];
    for (let item of selectedItems){
      items.push({nwid:item.nwid, type:item.type});
    }
    return rest.post(`/folders/${folderNwId}/planning-actions/cwToggleProperty?propertyName=${propertyName}`, {
      data: JSON.stringify({items:items}),
      dataType: 'text',      
    }).fail(() => {
      toastService.createToast('top-right', labels.updateMniNumberTitle, labels.errorMessage, 'error', undefined, false);
    });

  }
});


