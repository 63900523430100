import React from "react";
import iconService from "core/services/iconService";

var DIFF_STATUS_TYPES_ICONS_SUCCESS = {
  success: { moduleName: "MyBirdeye", icon: "success" },
  waiting: { moduleName: "MyBirdeye", icon: "wait" },
  hold: { moduleName: "MyBirdeye", icon: "hold" },
  error: { moduleName: "Thumbnail", icon: "reject-small" },
  processing: { moduleName: "MyBirdeye", icon: "in_progress" }
};

export default class extends React.Component {
  static defaultProps = {
    status: {},
    approvalIndication: undefined,
    approvalIndicationComplete: undefined,
    approvalIndicationReject: undefined,
    separations: undefined,
    style: undefined
  };

  getStatusIcon = (flowStep, flowStepIconName, statusType) => {
    var statusTypeLowerCase =
      typeof statusType !== "undefined"
        ? statusType.toLowerCase().trim()
        : undefined;

    if (!flowStep || typeof statusType === "undefined") {
      return iconService.getGeneralIcon(null, "empty");
    }

    return iconService.getTemplateIcon(flowStep, "tiny", flowStepIconName);
  };

  getStatusTypeIcon = (flowStep, flowStepIconName, statusType) => {
    var statusTypeLowerCase =
      typeof statusType !== "undefined"
        ? statusType.toLowerCase().trim()
        : undefined;

    if (!flowStep || typeof statusType === "undefined") {
      return undefined;
    }

    if (
      typeof DIFF_STATUS_TYPES_ICONS_SUCCESS[statusTypeLowerCase] !==
      "undefined"
    ) {
      return iconService.getModuleIcon(
        DIFF_STATUS_TYPES_ICONS_SUCCESS[statusTypeLowerCase].moduleName,
        DIFF_STATUS_TYPES_ICONS_SUCCESS[statusTypeLowerCase].icon
      );
    }

    return undefined;
  };

  getIsApprovalSeparationIndicationCompleted = indicaion => separation => {
    const separationContent = separation.separationContent;
    const externalVersion = separation.separationContent.externalVersion;
    const approvalCompleteVersion = separation.approvalCompleteVersion || "0";
    const approvalIndicationComplete = approvalCompleteVersion == externalVersion ? separation.approvalIndicationComplete || "" : "";
    const isCompletedApprovalIndication = approvalIndicationComplete.indexOf(indicaion) >= 0;

    return isCompletedApprovalIndication;
  };

  getIsApprovalSeparationsIndicationCompleted = (separations, indicaion) => {
    return separations.every(
      this.getIsApprovalSeparationIndicationCompleted(indicaion)
    );
  };

  getIsApprovalSeparationIndicationRejected = indicaion => separation => {
    const separationContent = separation.separationContent;
    const externalVersion = separation.separationContent.externalVersion;
    const approvalCompleteVersion = separation.approvalCompleteVersion || "0";
    const approvalIndicationReject = approvalCompleteVersion == externalVersion ? separation.approvalIndicationReject || '' : '';
    const isRejectedApprovalIndication = approvalIndicationReject.indexOf(indicaion) >= 0;

    return isRejectedApprovalIndication;
  };

  getIsApprovalSeparationsIndicationRejected = (separations, indicaion) => {
    return separations.every(this.getIsApprovalSeparationIndicationRejected(indicaion));
  };

  getIsApprovalIndicationCompleted = (
    approvalIndicationComplete,
    separations,
    indicaion
  ) => {
    if (approvalIndicationComplete.indexOf(indicaion) >= 0) return true;

    return this.getIsApprovalSeparationsIndicationCompleted(separations, indicaion);
  };

  getIsApprovalIndicationRejected = (
    approvalIndicationReject,
    separations,
    indicaion
  ) => {
    if (approvalIndicationReject.indexOf(indicaion) >= 0) return true;

    return this.getIsApprovalSeparationsIndicationRejected(separations, indicaion);
  };

  renderIndicationStatus = (
    approvalIndicationComplete,
    approvalIndicationReject,
    separations
  ) => indicaion => {
    if (this.getIsApprovalIndicationCompleted(approvalIndicationComplete, separations, indicaion)) {
      return (
        <img
          title={indicaion}
          src={iconService.getModuleIcon(DIFF_STATUS_TYPES_ICONS_SUCCESS.success.moduleName, DIFF_STATUS_TYPES_ICONS_SUCCESS.success.icon)}
        />
      );
    } else if (
      this.getIsApprovalIndicationRejected(approvalIndicationReject, separations, indicaion)
    ) {
      return (
        <img
          title={indicaion}
          src={iconService.getModuleIcon(
            DIFF_STATUS_TYPES_ICONS_SUCCESS.error.moduleName,
            DIFF_STATUS_TYPES_ICONS_SUCCESS.error.icon
          )}
        />
      );
    }
    return (
      <img
        title={indicaion}
        src={iconService.getModuleIcon(DIFF_STATUS_TYPES_ICONS_SUCCESS.waiting.moduleName, DIFF_STATUS_TYPES_ICONS_SUCCESS.waiting.icon)}
      />
    );
  };

  renderMultipleApprovalStatusTypes = (approvalIndication, approvalIndicationComplete, approvalIndicationReject, separations) => {
    return approvalIndication
      .split(",")
      .sort()
      .map(this.renderIndicationStatus(approvalIndicationComplete, approvalIndicationReject, separations));
  };

  renderStatusType = (flowStep, flowStepIconName, statusType) => {
    const statusTypeIcon = this.getStatusTypeIcon(flowStep, flowStepIconName, statusType);

    return <img src={statusTypeIcon} />;
  };

  render() {
    var style = this.props.style,
      status = this.props.status,
      approvalIndication = this.props.approvalIndication,
      approvalIndicationComplete = this.props.approvalIndicationComplete,
      approvalIndicationReject = this.props.approvalIndicationReject,
      separations = this.props.separations,
      statusIcon = this.getStatusIcon(
        status.flowStepType,
        status.flowStepIconName,
        status.statusType
      ),
      itemStatusclassName = "item-status tiny";

    return (
      <div className={itemStatusclassName} style={style}>
        <img className="item-status-icon" src={statusIcon} />
        <div className="item-status-type">
          {
            typeof approvalIndication !== "undefined"
              ? this.renderMultipleApprovalStatusTypes(approvalIndication, approvalIndicationComplete, approvalIndicationReject, separations)
              : this.renderStatusType(status.flowStepType, status.flowStepIconName, status.statusType)
          }
        </div>
      </div>
    );
  }
}
