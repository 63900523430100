/**
 * Created by moshemal on 8/31/14.
 */


define(['core'],function(core){
	'use strict'
	return {
		getColorByName: function (name) {
			return core.service.colorTable.getColorByName.apply(core, arguments);
		},
		getColorByCode: function (code) {
			return core.service.colorTable.getColorByCode.apply(core, arguments);
		},
		getColorsByColorType: function (colorType) {
			return core.service.colorTable.getColorsByColorType.apply(core, arguments);
		},
		getProcessColorsDefaultNames: function () {
			return core.service.colorTable.getProcessColorsDefaultNames.apply(core, arguments);
		},
		getProcessColors: function () {
			return core.service.colorTable.getProcessColors.apply(core, arguments);
		},
		getAllColors: function () {
			return core.service.colorTable.getAllColors.apply(core, arguments);
		},
		getUIColorRGB: function (color) {
			return core.service.colorTable.getUIColorRGB.apply(core, arguments);
		}
	};
});