import sandbox from 'sandbox';
const { translate } = sandbox.localization;

export default {
  all: translate('All'),
  tableName: translate("Unplanned"),
  thumbnail: translate('Thumbnail'),
  filename: translate('Filename'),
  step: translate('Step'),
  status: translate('Status'),
  mniNumber: translate('MNI Number'),
  category: translate('Category'),
  categoryDate: translate('Category Date'),
  inputTime: translate('Input Time'),
  magazines: translate('Magazines'),
  advertiser: translate('Advertiser'),
  adInstructions: translate('Ad Instructions'),
  otherInformation: translate('Other Information'),
  manageTableColumns: translate('Manage Table Columns'),
  reset: translate('Reset')
};