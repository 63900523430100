import { useState, useRef, useCallback, useReducer } from 'react';

export function useClientRect() {
  const [rect, setRect] = useState(null);

  const ref = useCallback(node => {
    if (node !== null) {
      // console.log('useClientRect => node.getBoundingClientRect()==>', node.getBoundingClientRect());
      setRect(node.getBoundingClientRect());
    }
  }, []);

  return [rect, ref];
}

export function useIsFirstRender() {
  const firstRenderRef = useRef(true);

  const firstRender = firstRenderRef.current;
  if (firstRenderRef.current === true) {
    firstRenderRef.current = false;
  }

  return firstRender;
}

export function useForceUpdate() {
  const [, forceUpdate] = useReducer(count => count + 1, 0);

  return forceUpdate;
}