/**
 * Created by moshemal on 1/8/15.
 */

define(['ember', 'jquery', 'sandbox', 'widgets/Wizard/AbstractStep', 'sandbox/jsUtils', 'sandbox/planSetup', './views/structure',
  './controllers/StructureController', "./model/model", './../../utils/wizardUtils' ],

  function (Ember, $, sandbox, AbstractStep, utils, PlanSetup, StructureView, Controller, ModelHandler, WizardUtils){
    'use strict';


    

    function init(initObj) {
      console.log("init Called");

      this._super(initObj);
      this.wizard = initObj.wizard;
      this.properties.projectorId = initObj.projectorId;
      this.rootType = initObj.startParameters.rootType;
      this.rootId = initObj.startParameters.rootId;
      this.instance = initObj.startParameters.nwid;
      
    }

    function back(){
      console.log("back Called");
    }

    function next(){
      var ret = this.controller.validData();
      
      var chaseSteps = this.model.clientSettings.hideChaseSteps ||  [1,3,4];
      try {
        if (ret && this.model["chaseMode"]){
          this.wizard.hideSteps(chaseSteps);
        }
      } catch (e) {
        console.log("failed to hide/show steps");
      }

      return ret;
    }

    function destroy(){
      console.log("next Called");
    }

    function render(){
      this.wizard.disableButton("cancel");
      if (typeof this.controller !== "undefined" && this.controller !== null){
        this.controller.reload(this.model);
      } else {
        this.controller = Controller.create({
          model: this.model,
          properties: this.properties,
          projectorId: this.id,
          wizard: this.wizard,
          rootType: this.rootType,
          instance: this.instance,
          rootId: this.rootId
        });
      }  
            
      this.controller.setPlanSetup(this.model.planSetup);

      // if (!(this._view instanceof Ember.View)){
      //   $(this.container).empty();
      //   this._view =  StructureView.create({
      //     controller: this.controller

      //   }).appendTo(this.container);
      // }
      $(this.container).empty();
      var that = this;
      this._view =  StructureView.create({
        controller: that.controller
      }).appendTo(this.container);

      

    }



    return AbstractStep.extend({
      init:       init,
      render:     render,
      //back:				back,
      next:       next,
      destroy:    destroy
    }, "Structure");
  }
);