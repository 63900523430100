import { toServerDate, toServerDateOnly, formatDate, MSEC_IN_MINUTE } from 'core/dates';
import settingsManager from 'core/managers/settings';
import requestManager from 'core/managers/request';
import toastService from 'core/services/toastService';
import { translate } from 'core/services/localization';
import { restGet, restPost } from 'core/managers/rest2';
import { fromServerDate } from 'core/dates';
import { appendParameters } from 'utilities/url';

function updateSection(zone, section, pagesMap) {
  section.sourceId = (zone.sourceId + "/" + section.displayName).toLowerCase();
  section.parent = zone.sourceId;
  section.pageSize = section.impositionType;
  section.startWith = 1;
  for (let i = 0; i < section.pages.length; i++) {
    updatePage(section, section.pages[i], pagesMap, zone.displayName + ":" + section.displayName);
  }
  zone.sections.push(section);
}

function updatePage(section, page, pagesMap, baseKey) {
  var pageSourceId = section.sourceId + "/" + page.number;
  pageSourceId = pageSourceId.toLowerCase();
  page.type = 'page';
  page.sourceId = pageSourceId;
  page.pagination = page.number;
  page.parent = section.sourceId;
  page.title = page.number;
  page.numberInSection = page.number;
  page.folioNumber = page.number;

  page.customProperties = {};

  for (let i = 0; i < page.separations.length; i++) {
    const separation = page.separations[i];
    updatePageSeparation(page, separation);
    pagesMap[baseKey + ":" + page.number] = page;
  }

  page.allowedSeparations = page.separations.map(function (s) { return s.displayName; });
}

function updatePageSeparation(page, separation) {
  separation.sourceId = page.sourceId + "/" + separation.displayName;
  separation.parent = page.sourceId;
  //hack
  separation.displayName = separation.displayName.toLowerCase();
}

function updatePageShare(zone, section, page, pagesMap, masterZone, masterContentZone) {
  const directKey = zone.masterZoneName + ":" + section.displayName + ":" + page.number;
  const contentKey = masterContentZone.displayName + ":" + section.displayName + ":" + page.number;
  console.log(directKey + ":" + contentKey);
  const masterPage = pagesMap[directKey];
  const masterContent = pagesMap[contentKey];
  page.masterPage = masterContent.sourceId;
  page.masterPageZone = masterContentZone.code;
  page.masterZone = masterZone.code;
  page.masterPageName = masterPage.displayName;
  page.masterSection = section.displayName;
  page.directMaster = masterPage.sourceId;
  page.jumpShareDirectMaster = masterPage.sourceId;
  // page.masterPage = masterPage.sourceId;
  if (!masterPage.followers) {
    masterPage.followers = [];
  }
  masterPage.followers.push(page.sourceId);

  for (let i = 0; i < page.separations.length; i++) {
    const separation = page.separations[i];
    separation.masterSeparation = masterContent.sourceId + "/" + separation.displayName.toLowerCase();
    separation.masterPageZone = masterContentZone.code;
    separation.masterZone = masterZone.code;
    separation.masterSection = section.displayName;
  }
}

export default class ModelHandler {
  #model;
  #settings;
  #stepModel = {};

  constructor(model, settings) {
    this.#model = model;
    this.#settings = settings;
    this.#stepModel = {
      pressSystem: settings.ignoreMps ? 'kba' : '',
      kbaEnabled: settings.ignoreMps,
      mpsEnabled: false,
      kbaCircullation: '',
      mpsCircullation: '',
      kbaComments: '',
      mpsComments: '',
      mpsCatalog: '',
      mpsFormat: '',
      format: { sections: [] }
    };
  }

  get model() {
    return this.#model;
  }

  get settings() {
    return this.#settings;
  }


  getStepModel(mode) {
    return this.#stepModel;
  }

  setStepModel(stepModel) {
    this.#stepModel = stepModel;
  }

  async getFormatIds(catalogueId) {
    let formats = await this.getFormatIdsInternal(catalogueId);
    return formats;
  }

  /* get list fo formats for specific catalog */
  getFormatIdsInternal(catalogueId) {
    const params = {
      model: this.model.publication
    };
    //catalogueId = 
    const url = appendParameters(`planning-wizard/custom/dnex/formats/${catalogueId}`);
    return restGet(this.settings.viewNwid, url)
      .then((result) => {
        return result;
      })
      .catch(args => {
        toastService.errorToast('', 'Failed');
        return {};
      });
  }

  /* get publication from format */
  async getFormatDetails(formatid) {
    this.getStepModel().format = await this.getFormatDetailsInternal(formatid);
    return true;
  }

  getFormatDetailsInternal(formatid) {
    this.settings.wizard.disableButton('next');

    const url = appendParameters(`planning-wizard/custom/dnex/structure/${formatid}`);

    return restPost(this.settings.viewNwid, url, this.model.publication)
      .then((result) => {
        this.settings.wizard.enableButton('next');
        return result;
      })
      .catch(args => {
        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!ERROR " + args);
        return {};
      });
  }

  convertToStepModel(model) {
    const edition = model.publication.editions[0];
    const pressSystem = edition.pressSystem ?
      edition.pressSystem === 'MPS;KBA' ? 'both' : edition.pressSystem.toLowerCase()
      : '';
    const kbaEnabled = pressSystem === 'both' || 'kba',
      mpsEnabled = pressSystem === 'both' || 'mps';
    this.setStepModel({
      pressSystem: pressSystem,
      kbaEnabled: kbaEnabled,
      mpsEnabled: mpsEnabled,
      kbaCircullation: edition.kbaCircullation || '',
      mpsCircullation: edition.mpsCircullation || '',
      kbaComments: edition.kbaComments || '',
      mpsComments: edition.mpsComments || '',
      mpsCatalog: edition.mpsCatalog || '',
      mpsFormat: edition.mpsFormat || '',
      format: { sections: [] }
    });
  }

  convertFromStepModel() {
    console.log("convertFromStepModel:" + this.getStepModel());
    let pagesMap = {}, masterMaps = {};
    let model = this.model;
    const publication = this.model.publication;
    const zones = {};
    const edition = publication.editions[0];

    let stepModel = this.getStepModel();
    edition.customFields = ["pressSystem", "kbaCircullation", "mpsCircullation", "mpsCatalog", "mpsFormat", "mpsComments", "kbaComments"];
    for (let f = 0; f < edition.customFields.length; f++) {
      const key = edition.customFields[f];
      edition[key] = (key === "pressSystem") ?
        stepModel.pressSystem === "both" ? "MPS;KBA" : stepModel.pressSystem.toUpperCase() :
        edition[key] = stepModel[key];
    }

    for (let i = 0; i < edition.zones.length; i++) {
      const zone = edition.zones[i];
      zones[zone.displayName] = zone;
      zone.sections = [];
      let psZone = model.planSetup.getZone({ displayName: publication.displayName }, zone.displayName);
      zone.code = psZone.shortName;
      for (let j = 0; j < stepModel.format.sections.length; j++) {
        const section = stepModel.format.sections[j];
        const cloneSection = JSON.parse(JSON.stringify(section));
        updateSection(zone, cloneSection, pagesMap);
      }
    }


    // set sharing information
    for (let i = 0; i < edition.zones.length; i++) {
      const zone = edition.zones[i];
      for (let j = 0; j < zone.sections.length; j++) {
        const section = zone.sections[j];
        if (zone.masterZoneName && zone.masterZoneName != "") {
          let contentMasterName = zone.masterZoneName;
          let masterContentZone = zones[contentMasterName];
          let masterZone = masterContentZone;
          while (contentMasterName) {
            masterContentZone = zones[contentMasterName];
            if (!masterContentZone || !masterContentZone.masterZoneName || masterContentZone.masterZoneName == "") {
              break;
            }
            contentMasterName = masterContentZone.masterZoneName;
          }

          for (let n = 0; n < section.pages.length; n++) {
            const page = section.pages[n];
            updatePageShare(zone, section, page, pagesMap, masterZone, masterContentZone);
          }
        }
      }
    }
    return { isValid: true };
  }
}