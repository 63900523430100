export const ADD_TEMPLATE = "addTemplate";
export const ADD_TEMPLATES = "addTemplates";
export const REMOVE_TEMPLATES = "removeTemplates";
export const RENAME_TEMPLATE = "renameTemplates";
export const UID = "uid";
export const GROUP_ID ="groupId";
export const TEMPLATE_GROUP ="templateGroup";
export const IS_TEMPLATE = "isTemplate";
export const TEMPLATE_NAME = "templateName";
export const PUBLICATION_NAME = "publicationName";
export const CONFIG_TYPE = "configType";
export const FORMAT = "format";
export const SECTION_NAME = "sectionName";
export const NUMBER_OF_HEAT_PAGES = "NOfHeatsetPages";
export const NUM_PAGES = "numPages";
export const PRINT_RUN_MODE = "printRunType";
export const CHANGE_SELECTION = "changeSelection";
export const STRING = "string";
export const BOOLEAN = "boolean";
export const SELECTED = "selected";
export const FILTER = "filter";
export const FILTERED = "filtered";
export const TYPE = "type";
export const TEMPLATES = "templates";
export const VALUE = "value";
export const NAME = "name";
export const TITLE = "title";
export const SECTIONS = "sections";
export const ACTION_NWID = "actionNwId";
export const FOLDER_NWID = "folderNwId";
export const OLD_NAME = "oldName";
export const NEW_NAME = "newName";
export const SORT_COLUMN = "sortColumn";
export const SORT_DIRECTION = "sortDirection";
export const COLUMNS_DEFINITIONS = "columnsDefiniton";
export const SORTABLES = "sortables";
export const CREATE_TEMPLATE_LINK = "createTemplateLink";
export const MODULE_NWID = "moduleNwid";
export const EDIT = "edit";
export const IMAGE = "image";
export const START_PARAMETERS = "startParameters";


