/**
 * @name Abort Resources Action
 * @fileOverview
 * @author sergey
 */

define(['sandbox', 'AbstractAction', 'logger'], function(sandbox, AbstractAction, logger) {
  'use strict';
  var log = logger.getDefaultLogger();
  return AbstractAction.extend({
    execute: function (objs) {
      var deviceNwId = this.module.viewSettings.rootId;
      var deviceType = this.module.viewSettings.rootType;
      var projectorId = this.module.projectorId;
      for (var i = 0, leni = objs.length; i < leni ; i++){
        var nwid = objs[i].nwid || deviceNwId,
            type = objs[i].type || deviceType;
        sandbox.request.resource.abortAll(this.nwid, nwid, type, projectorId, {items: null});
      }
    }
  });
});