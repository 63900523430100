import React, { Component } from 'react';
import sandbox from 'sandbox';
import SeperationPreview from 'src/components/private/separations/SeperationPreview';
import PropTypes from 'prop-types';
import settingsManager from 'core/managers/settings';

const translate = sandbox.localization.translate;
const labels = {
  offsetX: translate('Offset X'),
  offsetY: translate('Offset Y'),
  scaleX: translate('Scale X'),
  scaleY: translate('Scale Y'),
};
const mmOpt = { factor: 25.4, precision: 3 };
const inchOpt = { factor: 1, precision: 4 };
const converterOptions = {
  'mm': mmOpt,
  'in': inchOpt,
  'inch': inchOpt
};

module.exports = class Fanout extends Component {
  constructor(props) {
    super(props);
    this.getSeperationFanoutRow = this.getSeperationFanoutRow.bind(this);
    this.getFanoutTable = this.getFanoutTable.bind(this);
  }

  static propTypes = {
    fanout: PropTypes.any
  }

  getSeperationFanoutRow(separation, index) {
    const lengthUnit = settingsManager.getLengthUnit();
    const converterOption = converterOptions[lengthUnit];
    const seperationPreview = <SeperationPreview colorName={separation.colorName} />;
    const seperationFanoutRow = <tr key={index} className='fanout-tbody-row'>
      <td className='fanout-tbody-col'>{seperationPreview}</td>
      <td className='fanout-tbody-col'>{Number(separation.offsetX * converterOption.factor).toFixed(converterOption.precision)}</td>
      <td className='fanout-tbody-col'>{Number(separation.offsetY * converterOption.factor).toFixed(converterOption.precision)}</td>
      <td className='fanout-tbody-col'>{Number(separation.scaleX * converterOption.factor).toFixed(converterOption.precision)}</td>
      <td className='fanout-tbody-col'>{Number(separation.scaleY * converterOption.factor).toFixed(converterOption.precision)}</td>
    </tr>;
    return seperationFanoutRow;
  }

  getFanoutTable() {
    const { fanout } = this.props;
    fanout.separations.forEach(function (separation) {
      const colorNameObj = sandbox.colors.getColorByName(separation.colorName);
      separation['type'] = colorNameObj.colorType;
    });
    sandbox.sorting.sort(fanout.separations, 'cmyk', 'colorName');
    const tableRows = fanout.separations.map(this.getSeperationFanoutRow);
    const fanoutTabel = <table className='fanout-table'>
      <thead className='fanout-thead'>
        <tr className='fanout-thead-row'>
          <th className='fanout-thead-col'></th>
          <th className='fanout-thead-col'>{labels.offsetX}</th>
          <th className='fanout-thead-col'>{labels.offsetY}</th>
          <th className='fanout-thead-col'>{labels.scaleX}</th>
          <th className='fanout-thead-col'>{labels.scaleY}</th>
        </tr>
      </thead>
      <tbody className='fanout-tbody'>
        {tableRows}
      </tbody>
    </table>;
    return fanoutTabel;
  }

  render() {
    return <div className='fanout'>
      {this.getFanoutTable()}
    </div>;
  }
}

