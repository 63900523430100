import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ThumbnailImage from './thumbnailImage';

export default class PagesThumbnail extends Component {
  static contextTypes = {
    controller: PropTypes.object,
    module: PropTypes.object
  };

  static defaultProps = {
    thumbnail: undefined,
    thumbnailState: {},
    thumbnailSize: undefined,
    definition: undefined
  };

  getPages = thumbnail => {
    const { controller, module } = this.context;
    let pages = [];
    if (thumbnail.type === 'form') {
      pages = thumbnail.pages || [];

      return pages.map(page => {
        let updatedPage = page;
        if (module.viewModel.type === 'edition') {
          if (page.pageContent.link === 'true') {
            const pageContentFromModel = controller.getByNwid(page.pageContent.nwid);
            updatedPage = {
              ...page,
              pageContent: pageContentFromModel,
              completionState: pageContentFromModel.completionState,
            };
          }
        } else if (page.link === 'true') {
          updatedPage = controller.getByNwid(page.nwid);
        }
        return updatedPage;
      });

      //return thumbnail.pages;
    }
    return [thumbnail];
  };

  getPageCoords = (page, singlePageSize, matrix, indexInForm, pageOrietation, orietationDelta) => {
    var pageRow = (indexInForm / matrix.horizontalImageCount) | 0,
      pageCol = (indexInForm % matrix.horizontalImageCount);

    return [
      (pageCol * singlePageSize[0]),
      (pageRow * singlePageSize[1])
    ];
  };

  singlePage = (pageSize) => {
    return [pageSize[0] - 3, pageSize[1] - 3];
  };

  doublePage = (pageSize) => {
    return [pageSize[0] * 2 - 3, pageSize[1] - 3];
  };

  doubleVerticalPage = (pageSize) => {
    return [pageSize[0] - 3, pageSize[1] * 2 - 3];
  };

  getPageSize = (page, thumbnail, singlePageSize, matrix, pageOrietation, orietationDelta) => {
    const thumbnailType = thumbnail.type;
    const isPanorama = thumbnailType === 'form' ? page.pageContent.isPanoramaOnForm : page.pageContent.isPanorama;
    if (!isPanorama) {
      return this.singlePage(singlePageSize);
    }

    if (thumbnail.type === 'page') {
      return this.doublePage(singlePageSize);
    }
    if (matrix.horizontalImageCount * matrix.verticalImageCount === 1) {
      return this.singlePage(singlePageSize);
    }
    if (pageOrietation === 'vertical') {
      return this.doubleVerticalPage(singlePageSize);
    }
    if (pageOrietation === 'horizontal') {
      return this.doublePage(singlePageSize);
    }
    //if (page.pageContent.format === 'Tabloid') return this.doubleVerticalPage(singlePageSize);

    return this.doublePage(singlePageSize);
  };

  textRotate = (rotate) => {
    if (rotate === 0) {
      return '0deg';
    }
    if (rotate === 1) {
      return '90deg';
    }
    if (rotate === 2) {
      return '180deg';
    }
    if (rotate === 3) {
      return '270deg';
    }
  };

  getPageName = (thumbnail, page) => {
    const { module: { viewModel } } = this.context;
    let name = page.name;

    if (viewModel.type === 'edition') {
      let pageFromViewModel;
      const pages = viewModel.sections.flatMap(section => section.pages);
      for (const p of pages) {
        if (p.aggregated) {
          let nwidToCompare = page?.pageContent?.significantPage?.nwid;
          if (thumbnail.type === 'page') {
            nwidToCompare = page.aggregated ? page.rootId : page.nwid;
          }
          const relatedPage = p.relatedPages.find(rp => rp.nwid === nwidToCompare);
          if (relatedPage) {
            pageFromViewModel = relatedPage;
          }
        } else if (thumbnail.type === 'page') {
          if (p.nwid === page.nwid) {
            pageFromViewModel = p;
          }
        } else if (p.nwid === page.pageContent.significantPage.nwid) {
          pageFromViewModel = p;
        }
        if (pageFromViewModel) {
          break;
        }
      }

      if (pageFromViewModel) {
        name = pageFromViewModel.variant ? pageFromViewModel.variantName : pageFromViewModel.name;
      }
    }

    return name;
  };

  renderPage = (
    page,
    thumbnail,
    thumbnailSize,
    matrix,
    indexInForm,
    pageOrietation,
    rotate,
    viewConfiguration,
    index,
  ) => {
    var controller = this.context.controller,
      singlePageSize = [
        thumbnailSize[0] / matrix.horizontalImageCount,
        thumbnailSize[1] / matrix.verticalImageCount
      ],
      orietationDelta = (singlePageSize[0] / 7) | 0,
      pageSize = this.getPageSize(page, thumbnail, singlePageSize, matrix, pageOrietation, orietationDelta),
      pageCoords = this.getPageCoords(page, singlePageSize, matrix, indexInForm, pageOrietation, orietationDelta),
      pageStatusDescription = controller.getPageStatusDescription(page),
      style = {
        position: 'absolute',
        left: pageCoords[0],
        top: pageCoords[1],
        width: pageSize[0],
        height: pageSize[1]
      },
      textRotate = ['rotate(', this.textRotate(rotate), ')'].join(''),
      /**
       * The maxHeight and maxWidth props were added to the style of the text element (span) because when rotated
       * to 90 or 270 degrees its width and height is not aligned correctly with its parent width and height (side effect of the rotation)
       * which can result with the text being cut (when the text is long).
       * So the maxHeight prop of the text element is set with the width of its parent and the maxWidth with the height of its parent.
      */
      textStyle = {
        transform: textRotate,
        WebkitTransform: textRotate,
        textDecoration: rotate > 0 & (page.numberInSection === 6 || page.numberInSection === 9) ? 'underline' : 'none',
        maxWidth: rotate === 1 || rotate === 3 ? style.height : 'auto',
        maxHeight: rotate === 1 || rotate === 3 ? style.width : 'auto',
      },
      name = this.getPageName(thumbnail, page);
    var is8UpOrMore = matrix.horizontalImageCount >= 4 && matrix.verticalImageCount >= 2;
    var trimmedName = is8UpOrMore && name.length > 3 ? '...' + name.substr(3) : name;
    var pageTextClass = is8UpOrMore ? ['thumbnail-page-text', 'small'].join(' ') : 'thumbnail-page-text';
    return <div title={name} className={['thumbnail-page', pageStatusDescription].join(' ')} style={style} key={`${page.id}-${index}`}>
      <span className={pageTextClass} style={textStyle} >
        {trimmedName}
      </span>
    </div>;
  };

  renderPages = (pages, thumbnail, thumbnailSize, viewConfiguration) => {
    var that = this,
      controller = this.context.controller,
      matrix = controller.getMatrix(thumbnail),
      formIndexes = controller.getFormIndexes(thumbnail),
      orietations = controller.getOrietations(thumbnail),
      rotations = controller.getRotations(thumbnail);
    return pages.filter(page => !!page).map(page => {
      var nwid = thumbnail.type === 'page' ? thumbnail.nwid : page.pageContent.nwid,
        indexesInForm = formIndexes[nwid] || [],
        pageOrietation = orietations[nwid],
        rotate = rotations[nwid];
      return indexesInForm.map((pageIndexInForm, index) => {
        return that.renderPage(page, thumbnail, thumbnailSize, matrix, pageIndexInForm, pageOrietation[index], rotate[index], viewConfiguration, index);
      });
    });
  };

  renderThumbnailContent = (pages, thumbnail, thumbnailState, thumbnailSize, viewConfiguration) => {
    var controller = this.context.controller;
    var selectedSeparationIndex = this.props.selectedSeparationIndex;
    var thumbnailContentNode = (
      (thumbnail.separations.length === 0) || (
        (selectedSeparationIndex === null || selectedSeparationIndex === undefined) &&
        (thumbnail.separations.length > selectedSeparationIndex)
      )
    ) ?
      thumbnail :
      thumbnail.separations[selectedSeparationIndex];
    var thumbnailContent = controller.getThumbnailContent(thumbnailContentNode);
    var version = thumbnailContent.versionNwid;
    var contentVersionMajorIndex = thumbnailContent.contentVersionMajorIndex;
    //var thumbnailImage = controller.getThumbnailUrl(thumbnailContent);

    if (controller.showThumbnailImage(thumbnailContent) && !thumbnailState.showOnlyPagesInfo) {
      return <ThumbnailImage version={version}
        contentVersionMajorIndex={contentVersionMajorIndex}
        thumbnail={thumbnailContentNode}
        selectedSeparationIndex={selectedSeparationIndex}
      />;
    }

    return this.renderPages(pages, thumbnail, thumbnailSize, viewConfiguration);
  };

  render() {
    var { thumbnail, thumbnailState, definition, viewConfiguration } = this.props,
      controller = this.context.controller,
      thumbnailSingleSize = [definition.singleWidth, definition.singleHeight],
      thumbnailSize = controller.getThumbnailElementSize(thumbnail, thumbnailSingleSize),
      pages = this.getPages(thumbnail),
      style = { width: thumbnailSize[0], height: thumbnailSize[1] };

    return <div className="thumbnail" style={style}>
      {this.renderThumbnailContent(pages, thumbnail, thumbnailState, thumbnailSize, viewConfiguration)}
    </div>;
  }
}

PagesThumbnail.propTypes = {
  thumbnail: PropTypes.object,
  thumbnailState: PropTypes.object,
  thumbnailSize: PropTypes.array,
  definition: PropTypes.object,
  viewConfiguration: PropTypes.object,
  selectedSeparationIndex: PropTypes.number,
};