import React from 'react';
import PropTypes from 'prop-types';
import colorService from 'core/services/colorService';
import iconService from 'core/services/iconService';
import SeparationVersion from './separationVersion';
import SeparationStatusIcon from './separationStatusIcon';
import SeparationStatusType from './separationStatusType';
import SeparationPlates from './separationPlates';
import SeparationPlatesProgress from './separationPlatesProgress';
import { getMainStatus } from 'utilities/statuses';

export default class Separation extends React.Component {

  static contextTypes = {
    module: PropTypes.object
  };

  static defaultProps = {
    index: undefined,
    separation: undefined,
    definition: undefined,
    approvalIndication: undefined,
    model: {}
  };

  getSeparation = () => {
    return this.props.separation;
  };

  getDefinition = () => {
    return this.props.definition;
  };

  isLocal = (separation) => {
    return separation.local;
  };

  isLink = (separation) => {
    return !separation.local;
  };

  isPlanned = (separation) => {
    return separation.isPlanned;
  };

  isChase = (separation) => {
    return (separation.baseSeparation !== null);
  };

  getBaseSeparation = (separation) => {
    return separation.baseSeparation;
  };

  getSeparationProgress = (separation) => {
    const status = getMainStatus(separation);
    return typeof status?.progress !== 'undefined' ? status.progress : -1;
  };

  getSeparationVersion = (separation) => {
    const { externalVersion, versionNumber } = separation?.separationContent || {};
    return externalVersion ?? versionNumber;
  };

  getSeparationStyle = (rgb) => {
    return {
      border: 'solid 2px ' + rgb,
      width: '8px',
      height: '12px'//,
      //marginBottom: '6px'
    };
  };

  getSeparationContentWidth = () => {
    const { model: { relatedPages }, separation } = this.props;
    const max = relatedPages.filter(page => page.separations.some(sep => sep.name === separation.name)).length;
    let existingSepCount = 0;
    relatedPages.forEach(page => {
      const relatedPageSep = page.separations.find(sep => sep.name === separation.name);
      if (relatedPageSep) {
        const relatedPageSepProgress = this.getSeparationProgress(relatedPageSep);
        const relatedPageSepVersion = this.getSeparationVersion(relatedPageSep);
        if (!((relatedPageSepProgress === -1 || relatedPageSepProgress === '-1') && relatedPageSepVersion === 0)) {
          existingSepCount++;
        }
      }
    });
    return existingSepCount === max ? '100%' : '0%';
  };

  getSeparationContentStyle = (progress, version, rgb) => {
    const { model: { aggregated } } = this.props;
    const width = aggregated ? this.getSeparationContentWidth() : '100%';
    return {
      width,
      height: '100%',
      backgroundColor: (progress === -1 || progress === '-1') && version === 0 ? '#eeeeee' : rgb
    };
  };

  getSeparationPlates = (separation) => {
    return separation !== undefined && Array.isArray(separation.plates) ? separation.plates : [];
  };

  getPlatesInProgress = (separationStatus, plates) => {
    return plates.filter(function (plate) {
      const status = getMainStatus(plate);
      return (
        separationStatus && status && status.statusType !== 'NotAssigned' &&
        status.statusType !== 'Error' &&
        separationStatus.statusType === status.statusType &&
        separationStatus.flowStepType === status.flowStepType
      );
    });
  };

  unplannedIcon = (separation) => {
    var isPlanned = this.isPlanned(separation);

    if (!isPlanned) {
      return <img key={separation.id} className="UnplannedSeparationIcon" src={iconService.getModuleIcon('MyBirdeye', 'unplanned_separation')} />;
    }
    return undefined;
  };

  linkIcon = (separation) => {
    var isLink = this.isLink(separation);
    var baseSeparation = this.getBaseSeparation(separation);
    let shouldShowLinkIcon;
    if (this.context.module.viewModel.showLinksOnlyForChase) {
      shouldShowLinkIcon = !!baseSeparation;
    } else {
      shouldShowLinkIcon = isLink || !!baseSeparation;
    }

    if (shouldShowLinkIcon) {
      return <img key={separation.id} className="LinkSeparationIcon" src={iconService.getModuleIcon('MyBirdeye', 'link')} />;
    }
    return undefined;
  };

  separationContent = (separation) => {
    return [
      this.unplannedIcon(separation),
      this.linkIcon(separation)
    ];
  };

  handleSeparationClick = (ev) => {
    this.props.onSeparationClick(ev, this.props.index);
  };

  renderSeparation = (definition, separation) => {//function (separationName, separationStyle, separationContentStyle, content) {
    var selected = this.props.selected,
      separationName = separation.name,
      separationColor = colorService.getColorByName(separationName),
      separationRGB = colorService.getUIColorRGB(separationColor),
      separationVersion = this.getSeparationVersion(separation),
      separationProgress = this.getSeparationProgress(separation),
      separationStyle = this.getSeparationStyle(separationRGB),
      separationContentStyle = this.getSeparationContentStyle(separationProgress, separationVersion, separationRGB),
      className = 'Separation ThumbnailSeparation Separation-' + separationName;

    if (selected === true) {
      className = className + ' ThumbnailSeparation-Selected';
    }

    return <div key={separation.id} className={className} style={separationStyle}>
      <div className="Separation-Content" style={separationContentStyle} onClick={this.handleSeparationClick}>
        {this.separationContent(separation)}
      </div>
    </div>;
  };

  renderVersion = (definition, separation) => {
    var version = separation?.separationContent?.externalVersion;

    return <SeparationVersion definition={definition} version={version} />;
  };

  renderStatus = (definition, separation) => {
    return <SeparationStatusIcon definition={definition} separation={separation} />;
  };

  renderStatusType = (definition, separation) => {
    const status = getMainStatus(separation) || {};

    return <SeparationStatusType definition={definition} status={status} separation={separation} approvalIndication={this.props.approvalIndication} />;
  };

  renderPlates = (definition, separation) => {
    var plates = separation.plates;

    return <SeparationPlates definition={definition} plates={plates} />;
  };

  renderPlatesProgress = (definition, separation) => {
    var plates = separation.plates,
      separationName = separation.name,
      separationColor = colorService.getColorByName(separationName),
      separationRGB = colorService.getUIColorRGB(separationColor);

    return <SeparationPlatesProgress definition={definition} plates={plates} color={separationRGB} separation={separation} />;
  };

  renderSeparationElement = (elementDefinition, separation) => {
    var type = elementDefinition.type,
      status = getMainStatus(separation) || {},
      platesInProgress = this.getPlatesInProgress(status, this.getSeparationPlates(separation)),
      numberOfPlatesInProgress = platesInProgress.length;

    switch (type) {
      case 'separation':
        return this.renderSeparation(elementDefinition, separation);
      case 'separation-version':
        return this.renderVersion(elementDefinition, separation);
      case 'separation-status':
        return this.renderStatus(elementDefinition, separation);
      case 'separation-status-type':
        if (numberOfPlatesInProgress > 0) {
          //if (DIFF_INVISIBLE_FLOW_STEPS.indexOf(flowStepType) >= 0)
          return null;
        }
        return this.renderStatusType(elementDefinition, separation);
      case 'separation-plates':
        if (numberOfPlatesInProgress > 0) {
          //if (DIFF_INVISIBLE_FLOW_STEPS.indexOf(flowStepType) >= 0)
          return null;
        }
        return this.renderPlates(elementDefinition, separation);
      case 'separation-plates-progress':
        if (numberOfPlatesInProgress <= 0) {
          //if (DIFF_INVISIBLE_FLOW_STEPS.indexOf(flowStepType) < 0)
          return null;
        }
        return this.renderPlatesProgress(elementDefinition, separation);
      default:
        return <div></div>;
    }
  };

  renderSeparationElements = (definition, separation) => {
    var that = this,
      elements = definition.elements || [];
    return elements.map(function (elementDefinition, index) {
      return <div className="SeparationProgressItemElement" key={index}>
        {that.renderSeparationElement(elementDefinition, separation)}
      </div>;
    });
  };

  render() {
    var separation = this.getSeparation(),
      definition = this.getDefinition();

    return <li className="separation-progress-item" key={this.props.index}>
      {this.renderSeparationElements(definition, separation)}
    </li>;
  }
}

Separation.propTypes = {
  approvalIndication: PropTypes.string,
  definition: PropTypes.object,
  separation: PropTypes.object,
  model: PropTypes.object,
  index: PropTypes.number,
  onSeparationClick: PropTypes.func,
  selected: PropTypes.bool,
};