
define(['sandbox', './OpenModuleAction'], function(sandbox, OpenModuleAction) {
	'use strict';

	return OpenModuleAction.extend({

		isApplicable: function () {
			var objects = this.module.selected;
			if (typeof objects === 'undefined' || objects.length !== 1) {
				return false;
			}
			return (objects[0].isManualPlanChangeEnabled);
		}
	});
});
