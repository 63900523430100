/**
 * @name Shortcut Manager
 *
 * @fileOverview Shortcut manager intercept
 * keyboard events and calls the appropriate
 * event handler according to currently active
 * context
 *
 * @author sergey
 */

define(['base', 'base/jsUtils', 'core/managers/desktop'], function (base, jsutils, desktop) {
  "use strict";

  /**
   * @private
   * @namespace
   */

  var shortcuts = {},
      special = {
        8: 'backspace',
        9: 'tab',
        13: 'enter',
        19: 'pause',
        20: 'capslock',
        27: 'esc',
        32: 'space',
        33: 'pageup',
        34: 'pagedown',
        35: 'end',
        36: 'home',
        37: 'left',
        38: 'up',
        39: 'right',
        40: 'down',
        43: '+',
        45: 'ins',
        46: 'del',
        61: '+',
        107: '+',
        109: '-',
        112: 'f1',
        113: 'f2',
        114: 'f3',
        115: 'f4',
        116: 'f5',
        117: 'f6',
        118: 'f7',
        119: 'f8',
        120: 'f9',
        121: 'f10',
        122: 'f11',
        123: 'f12',
        187: '+',
        189: '-',
        191: '?'
      };

  /**
   * Decoding implements translation from
   * event keys ASCII codes with modifiers
   * to regular words combination,
   * e.g. 27 -> 'esc'
   * @param  {Object} ev Event object
   * @return {string}    Shortcut combination lowercase
   */
  function decode (ev) {
    var key = '', maskObj;

    maskObj = {
      ctrl: ev.ctrlKey,
      alt: ev.altKey,
      shift: ev.shiftKey,
      meta: ev.metaKey,
      which: ev.which
    };

    if (maskObj.ctrl) { key += 'ctrl'; }
    if (maskObj.meta) { key += 'meta'; }
    if (maskObj.alt) { key += 'alt'; }
    if (maskObj.shift) { key += 'shift'; }

    if (special[ev.which]) {
      key += special[ev.which];
    }
    else {
      key += String.fromCharCode(ev.which);
    }
    return key.toLowerCase();
  }

  /**
   * Calls the callback function according to
   * registered shortcut passing the shortcut
   * @param  {Object} ev Event object
   */
  function handle (ev) {
    var combo = decode(ev),
        desktop = require('core/managers/desktop'),
        selector = desktop.getActive();
    if (shortcuts[combo] && shortcuts[combo][selector]) {
      shortcuts[combo][selector].fn.call(shortcuts[combo][selector].ctx, combo);
    }
  }

  /**
   * External function provided for the rest of the application
   * to be able to register shortcuts
   * @param  {string}   combo    Shortcut combination without any delimiters
   * @param  {string}   selector    Owner of the shortcut - module's element property
   * @param  {Function} callback Function to be called when shortcut is identified
   * @param  {Object}   context
   */
  function register (combo, selector, callback, context) {
    // type checks for arguments
    if (!combo || !selector || !callback) {
      throw new Error('Shortcut manager: combination, selector, and callback should be defined');
    }
    if (typeof combo !== 'string') {
      throw new Error('Shortcut manager: combination must be a string');
    }
    if (typeof selector !== 'string') {
      throw new Error('Shortcut manager: selector must be a string');
    }
    if (!jsutils.isFunction(callback)) {
      throw new Error('Shortcut manager: callback must be a function');
    }
    if(context && !jsutils.isObject(context)) {
      throw new Error('Shortcut manager: context must be an object');
    }
    // it's better to resolve name by lowercase to
    // avoid stupid misspelled mistakes
    var comboU = combo.toLowerCase(),
        selectorU = selector.toLowerCase();
    if (!shortcuts[comboU]) { shortcuts[comboU] = {}; }
    if (!shortcuts[comboU][selectorU]) { shortcuts[comboU][selectorU] = {}; }
    shortcuts[comboU][selectorU] = {
      fn: callback,
      ctx: context || null
    };
  }

  function unregister (combo, selector) {
    if (combo === undefined || selector === undefined) {
      throw new Error('Shortcut manager: combination and selector should be defined');
    }
    if (typeof combo !== 'string') {
      throw new Error('Shortcut manager: combination should be string');
    }
    if (typeof selector !== 'string') {
      throw new Error('Shortcut manager: selector should be string');
    }
    var comboU = combo.toLowerCase(),
        selectorU = selector.toLowerCase();
    delete shortcuts[comboU][selectorU];
  }

  function unregisterAll (selector) {
    var key;
    for (key in shortcuts) {
      if (shortcuts.hasOwnProperty(key)) {
        delete shortcuts[key][selector];
      }
    }
    return true;
  }

  /** Subscribe to event listener on document */
  base.events.listen('body', 'keydown', handle);

  /**
   * @public
   */
  return {
    _name: 'shortcut',
    _type: 'manager',
    _ds: shortcuts,
    register: register,
    unregister: unregister,
    unregisterAll: unregisterAll
  };
});