import { restPut } from 'core/managers/rest2';

export function setChannelMessagesAreRead(selectedChannel, viewInstanceNwId, currentUser) { 
 
  let unreadMessages = [];
  const isMessageUnread = (message) => !message.readBy.some(user => user.userName === currentUser.name);

  if (selectedChannel) {
    unreadMessages = selectedChannel.messages.filter(message => isMessageUnread(message));
  }
  if (unreadMessages.length > 0) {

    let restData = {
      userId: currentUser.nwid,
      readMessagesNwids: unreadMessages.map(message => message.nwid)
    };

    return restPut(viewInstanceNwId, `chat/channels/${selectedChannel.nwid}/read`, restData);    
  }
}