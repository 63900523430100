/**
 * Create a name which is not used in the given array based on the given proposed name
 *
 * @param {Array} existingNames - array of existing names
 * @param {String} proposedName - proposed name
 * @returns {string} - unique name
 */
export const makeUniqueName = (existingNames, proposedName) => {
  let uniqueName = proposedName;
  let namesLower = existingNames.map(name => name.toLowerCase());
  let count = 1;
  do {
    uniqueName = proposedName + ' ' + count++;
  } while (namesLower.some(name => name === uniqueName.toLowerCase()));

  return uniqueName;
};

export const makeUniqueNumber = (existingNumbers = []) => {
  let count = 0;
  do {
    count++;
  } while (existingNumbers.some(num => Number(num) === count));

  return count;
};
