import sandbox from 'sandbox';
import {createObjectComparator} from 'core/comparators';

const isString = str => typeof str === 'string';

const DEFAULT_STATE = {
  channelsByNwid: {},
  messagesByNwid: {},
  userChannelsNwids: [],
  filteredUserChannels: [],
  allChannelsNwids: [],
  chatContent: [],
  selectedId: undefined,
  selectedContact: undefined,
  isAllChecked: true,
  sortedBy: 'O',
  newMessage: undefined,
  isFiltersAvailable: false,
  isChatsView: true,
  isNewChatView: false,
  isParticipantsView: false,
  participants: [],
  goToChannelSubject: false,
  isChannelSubjectView: false,
  channelSubject: undefined,
  contactListToView: undefined,
  notReceivedMessageNwids: [],
  textForSearching: undefined,
  searchValue: undefined,
  draftsByChannelNwid: {},
  users: [],
  groups: [],
  sendAvailability: true,
  newChannel: undefined,
  newMessagesByNwid: [],  
  usersByNwid: {},
  groupsByNwid: {}
};

const filterByRead = (mails) => {
  return mails.filter(item => !item.isRead);
}

const toggleUserChannels = (state) => {
  return {
    ...state,
    filteredUserChannels: state.userChannelsNwids,
    isAllChecked: true,
    isUnreadChecked: false,
    selectedId: undefined
  };
};

const setChatContent = (state, action) => {

  return {
    ...state,
    chatContent: action.selectedId && state.channelsByNwid[action.selectedId] ? state.channelsByNwid[action.selectedId].messages : [],
    selectedId: action.selectedId
  };
};

const setChatContentAfterUpdate = (state) => {
  const chatContent = isUndefined(state.channelsByNwid[state.selectedId]) ? [] : state.channelsByNwid[state.selectedId].messages;

  return {
    ...state,
    chatContent: chatContent
  };
};

const setEmptyContent = (state) => {
  return {
    ...state,
    chatContent: [],
    selectedId: undefined
  };
};

const setEmptyChat = (state) => {
  return {
    ...state,
    chatContent: []

  };
};

const toggleFilteredUserChannels = (state) => {
  return {
    ...state,
    filteredUserChannels: state.userChannelsNwids.filter(item => !item.isRead),
    isAllChecked: false,
    isUnreadChecked: true,
    selectedId: undefined
  };
};

const setSelectedId = (state, action) => {
  return {
    ...state,
    selectedId: action.selectedId ? action.selectedId : undefined
  };
};

const setSelectedContact = (state, action) => {
  return {
    ...state,
    selectedContact: action.selectedContact ? action.selectedContact : undefined
  };
};

const updateInputValue = (state, action) => {

  return {
    ...state,
    newMessage: action.newMessage,
    draftsByChannelNwid: {
      ...state.draftsByChannelNwid,
      [state.selectedId]: action.draftMessage

    }
  };
};

const updateChannelWithDraftMessage = (state, action) => {
  state.draftsByChannelNwid[action.channel.nwid] = action.draftMessage;

  return {
    ...state,
    draftsByChannelNwid: state.draftsByChannelNwid
  };
};

const getAllContacts = (state) => {
  
  let users = [...state.users].sort(createObjectComparator('userName'));
  let groups = [...state.groups].sort(createObjectComparator('groupFullName')); 
  let contactListToView = { "users": [...users], "groups": [...groups] };

  return {
    ...state,

    contactListToView: contactListToView,
    users: users,
    groups: groups,
    participants: []
  };
};


const setContactsView = (state, action) => {
  return {
    ...state,
    isNewChatView: true,
    isParticipantsView: false,
    isChatsView: false
  };
};

const setChatsView = (state, action) => {
  return {
    ...state,
    isNewChatView: false,
    isParticipantsView: false,
    isChatsView: true,
    participants: [],
    isChannelSubjectView: false,
  };
};

const setChatsViewBack = (state, action) => {
  return {
    ...state,
    isNewChatView: false,
    isParticipantsView: false,
    isChatsView: true,
    isChannelSubjectView: false
  };
};

const setChannelSubjectView = (state, action) => {

  return {
    ...state,
    isNewChatView: false,
    isChannelSubjectView: true,
    isParticipantsView: false,
    isChatsView: false,
  };
};

const setParticipantsView = (state, action) => {

  return {
    ...state,
    isNewChatView: false,
    isParticipantsView: true,
    isChannelSubjectView: false,
    goToChannelSubject: state.participants.length > 0
  };
};

const setNewChatView = (state, action) => {
  return {
    ...state,
    isNewChatView: true,
    isParticipantsView: false,
    isChatsView: false,
    isChannelSubjectView: false
  };
};

const updateChannelSubject = (state, action) => {
  return {
    ...state,
    channelSubject: action.subject ? action.subject : undefined
  };
};


const updateParticipantsAndContacts = (state, action) => {  
  let users = [...action.contacts.users].sort(createObjectComparator('userName'));
  let groups = [...action.contacts.groups].sort(createObjectComparator('groupFullName'));
  let contactListToView = { "users": users, "groups": groups };

  return {
    ...state,
    participants: action.participants,
    contactListToView: contactListToView,
    goToChannelSubject: action.participants.length > 0

  };
};

const updateChannelsList = (state, action) => {
  return {
    ...state,
    selectedId: action.channel,
    isNewChatView: false,
    isParticipantsView: false,
    isChatsView: true,
  };
};

const setNotReceivedMessages = (state, action) => {
  return {
    ...state,
    notReceivedMessageNwids: action.notReceivedMessageNwids

  };
};

const searchingInSubjectAndMessagesAndParticipants = (state) => (channelNwid) => {
  const foundInMessages = state.channelsByNwid[channelNwid].messages.filter(message => {
    return (isString(message.content) && message.content.toLowerCase().indexOf(state.searchValue.toLowerCase()) !== -1)
  });

  const participants = state.channelsByNwid[channelNwid].userNames.concat(state.channelsByNwid[channelNwid].groups.map(group => group.groupFullName));

  const foundInRecipients = participants.filter(participant => {
    return (isString(participant) && participant.toLowerCase().indexOf(state.searchValue.toLowerCase()) !== -1)
  });

  return (isString(state.channelsByNwid[channelNwid].subject) && state.channelsByNwid[channelNwid].subject.toLowerCase().indexOf(state.searchValue.toLowerCase()) !== -1) || foundInMessages.length > 0 || foundInRecipients.length > 0;
};

const updateSearchValue = (state, action) => {

  return {
    ...state,
    searchValue: action.searchValue ? action.searchValue : undefined
  };
};

const getFilteredChannels = (state) => {

  return {
    ...state,
    userChannelsNwids: state.searchValue ? state.allChannelsNwids.filter(searchingInSubjectAndMessagesAndParticipants(state)) : state.allChannelsNwids

  };
};

const setEmptySearchArea = (state) => {
  return {
    ...state,
    searchValue: undefined
  };
};

const setEmptyDraft = (state) => {

  return {
    ...state,
    newMessage: undefined,
    draftsByChannelNwid: {
      ...state.draftsByChannelNwid,
      [state.selectedId]: undefined
    }

  };
};

const addNewChannel = (state, action) => {

  return {
    ...state,
    newChannel: action.newChannel
  };
};

const addNewMessage = (state, action) => {
  state.newMessagesByNwid[action.newMessageRestData.messageNwid] = action.newMessageRestData;

  return {
    ...state

  };
};

const updateNewMessage = (state, action) => {
  delete state.newMessagesByNwid[action.newMessageRestData.messageNwid];
  state.newMessagesByNwid[action.newMessageNwid] = action.newMessageRestData;

  return {
    ...state,
    newMessagesByNwid: state.newMessagesByNwid
  };
};

const updateNewChannelWithNwid = (state, action) => {

  return {
    ...state,
    newChannel: {
      ...state.newChannel,
      nwid: action.newChannelNwid
    }
  };
};


const updateModel = (state, action) => {

  const channelsByNwid = action.model.channelsByNwid;
  const messagesByNwid = action.model.messagesByNwid;
  const allChannelsNwids = Object.keys(action.model.channelsByNwid);
  const chatContent = state.selectedId ? (action.model.channelsByNwid[state.selectedId] ? action.model.channelsByNwid[state.selectedId].messages : []) : [];
 
  let users = [...action.model.users].sort(createObjectComparator('userName'));
  let groups = [...action.model.groups].sort(createObjectComparator('groupFullName'));
  let newMessagesByNwid = Object.keys(state.newMessagesByNwid);
  
  newMessagesByNwid.forEach(messageNwid => {
    if(messagesByNwid[messageNwid]) {
      delete state.newMessagesByNwid[messageNwid];
    }
  })
  
  allChannelsNwids.map(channelNwid => {
    if (state.draftsByChannelNwid[channelNwid]) {
      channelsByNwid[channelNwid].draftMessage = state.draftsByChannelNwid[channelNwid];
    }
  })  

  return {
    ...state,
    channelsByNwid: channelsByNwid,
    messagesByNwid: messagesByNwid,
    usersByNwid: action.model.usersByNwid,
    groupsByNwid: action.model.groupsByNwid,
    allChannelsNwids: allChannelsNwids,
    chatContent: chatContent,
    contactListToView: { "users": [...users], "groups": [...groups] },
    userChannelsNwids: state.searchValue ? allChannelsNwids.filter(searchingInSubjectAndMessagesAndParticipants(state)) : allChannelsNwids,
    users: users,
    groups: groups,
    participants: [],
    newChannel: state.newChannel && channelsByNwid[state.newChannel.nwid] ? undefined : state.newChannel
  };
};

const updateSendAvailability = (state, action) => {

  return {
    ...state,
    sendAvailability: action.sendAvailability
  };
};

export default (state = DEFAULT_STATE, action) => {

  switch (action.type) {

    case 'SET_EMPTY_CONTENT':
      return setEmptyContent(state);

    case 'SET_CHAT_CONTENT':
      return setChatContent(state, action);

    case 'SET_CHAT_CONTENT_AFTER_UPDATE':
      return setChatContentAfterUpdate(state);

    case 'TOGGLE_USER_SYSTEM_MAILS':
      return toggleUserChannels(state);

    case 'TOGGLE_FILTERED_USER_SYSTEM_MAILS':
      return toggleFilteredUserChannels(state);

    case 'SET_SELECTED_ID':
      return setSelectedId(state, action);

    case 'SET_SELECTED_CONTACT':
      return setSelectedContact(state, action);

    case 'UPDATE_INPUT_VALUE':
      return updateInputValue(state, action);

    case 'UPDATE_CHANNEL_WITH_DRAFT_MESSAGE':
      return updateChannelWithDraftMessage(state, action);

    case 'SET_PARTICIPANTS_VIEW':
      return setParticipantsView(state, action);

    case 'SET_CHATS_VIEW':
      return setChatsView(state, action);

    case 'SET_CHATS_VIEW_BACK':
      return setChatsViewBack(state, action);

    case 'SET_NEW_CHAT_VIEW':
      return setNewChatView(state, action);

    case 'UPDATE_PARTICIPANTS_AND_CONTACTS':
      return updateParticipantsAndContacts(state, action);

    case 'GET_ALL_CONTACTS':
      return getAllContacts(state);

    case 'SET_CHANNEL_SUBJECT_VIEW':
      return setChannelSubjectView(state, action);

    case 'UPDATE_CHANNEL_SUBJECT':
      return updateChannelSubject(state, action);

    case 'SET_CONTACTS_VIEW':
      return setContactsView(state, action);

    case 'UPDATE_CHANNELS_LIST':
      return updateChannelsList(state, action);

    case 'SET_NOT_RECEIVED_MESSAGES':
      return setNotReceivedMessages(state, action);

    case 'UPDATE_SEARCH_VALUE':
      return updateSearchValue(state, action);

    case 'GET_FILTERED_CHANNELS':
      return getFilteredChannels(state);

    case 'SET_EMPTY_SEARCHAREA':
      return setEmptySearchArea(state, action);

    case 'SET_EMPTY_DRAFT':
      return setEmptyDraft(state);

    case 'UPDATE_MODEL':
      return updateModel(state, action);

    case 'UPDATE_SEND_AVAILABILITY':
      return updateSendAvailability(state, action);

    case 'SET_EMPTY_CHAT':
      return setEmptyChat(state);

    case 'ADD_NEW_CHANNEL':
      return addNewChannel(state, action);

    case 'UPDATE_NEW_CHANNEL_WITH_NWID':
      return updateNewChannelWithNwid(state, action);

    case 'ADD_NEW_MESSAGE':
      return addNewMessage(state, action);

    case 'UPDATE_NEW_MESSAGE':
      return updateNewMessage(state, action);

  }

  return state;
}