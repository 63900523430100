/**
 * @fileOverview
 * @author sergey
 */

define(['sandbox', 'AbstractAction'], function(sandbox, AbstractAction) {
  'use strict';

  return AbstractAction.extend({
    isApplicable: function () {
      ///After the selection is implemented this should change for selected items instead of the clicked item
      var managers = this.module.selected;
      if (typeof managers === 'undefined') {
        return false;
      } else if (managers.length !== 1){
        return false;
      } else if (managers[0].type === "controlpanel/site/manager" && managers[0].current){
        return false;
      }
      return true;
    },
    execute: function (args) {
      var objects = Array.prototype.map.call(args, function (item) {
        return {
          nwid: item.nwid,
          type: item.type
        };
      });
      var scriptName = objects[0].type === "controlpanel/site/manager" ? "DeleteManager" : this.parameters.scriptName;
      var actionArgs = [
        this.nwid,
        this.actionName,
        this.module.nwid,
        this.module.type,
        this.module.projectorId,
        scriptName,
        {objects: objects}
      ];

      if (this.parameters.confirmation) {
        var msg = "You are about to delete this item. \nIt cannot be restored at later time! Continue?";
        var request = sandbox.dialog.confirm(msg, 'Confirmation').then(function () {
          return sandbox.request.execServerAction.apply(this, actionArgs);
        }.bind(this));

        request.then(function (res) {

          var code = res.statusCode;
          if (code !== 200){
            var message = res.errorMessage;
            if (res !== null && message !== null){
              if (message.indexOf(":") > 0){
                message = message.split(":")[1];
              }
              sandbox.dialog.alert(message);
            }

          }
        });
      }
      else {
        return sandbox.request.execServerAction.apply(this, actionArgs);
      }
    }





  });
});