import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table, { Column, headers, filters } from 'widgets/ReactDataGrid';
import { FILTER_TYPE, ROW_HEIGHT, HEADER_HEIGHT, HEADER_HEIGHT_WITH_FILTER, ROW_HEIGHT_INCLUDING_THUMBNAIL } from 'widgets/ReactDataGrid/utils';
import { defaultShouldCellUpdate } from './columnsCreator';
const { GenericHeader, HeaderCaption } = headers;
const { TextInputFilter, DateFilter, MultiselectFilter } = filters;

const MIN_COLUMN_WIDTH = 20;



export default function View({ module, viewModel, sessionsTableColumns = [] }) {

  const renderSessionColumnFilter = column => {

    const { filter } = column;

    if (!filter) {
      return;
    }

    switch (filter.type) {
      case FILTER_TYPE.MULTISELECT: {
        const selectedValues = Array.isArray(filter.selected) && filter.selected || [];

        return (
          <MultiselectFilter
            value={selectedValues}
            options={filter.options}
            dataType={filter.dataType}
            onSelect={(event, values) => module.handleSessionColumnFilterChange(column, { selected: values })}
          />
        );
      }
      case FILTER_TYPE.TEXT:
        return (
          <TextInputFilter
            value={filter.textValue}
            onChange={(e, textValue) => module.handleSessionColumnFilterChange(column, { textValue })}
          />
        );
      case FILTER_TYPE.DATE:
        return (
          <DateFilter
            filter={filter}
            onApply={filter => module.handleSessionColumnFilterChange(column, filter)}
          />
        );
    }
  };

  const handleSessionColumnHeaderFilter = column => {

    if (!module.filtersEnabled) {
      return;
    }

    return (
      <div className='column-header-filter'>
        {renderSessionColumnFilter(column)}
      </div>
    );
  };

  const handleSessionColumnHeaderCaption = (column, handleClick, sortDirection, index) => {
    return (
      <HeaderCaption sortDirection={sortDirection} sortOrder={index + 1} tooltip={column.caption}
        onSort={multiSort => handleClick(column.key, column.sortValueGetter, multiSort)} sortable={typeof column.sortType !== 'undefined'}
      >
        {column.caption}
      </HeaderCaption>
    );
  };

  const handleSessionColumnHeader = (column, handleClick, sortDirection, index) => {
    return (
      <GenericHeader
        captionRenderer={handleSessionColumnHeaderCaption(column, handleClick, sortDirection, index)}
        filterRenderer={handleSessionColumnHeaderFilter(column)}
      />
    );
  };

  const renderSessionsTable = () => {

    const { columnsToSortBy, filtersEnabled } = module;

    const headerHeight = filtersEnabled ? HEADER_HEIGHT_WITH_FILTER : HEADER_HEIGHT;

    return (
      <Table
        rows={viewModel.filteredSessions}
        columnKey='sessionId'
        virtualScroll={true}
        selectableRows={true}
        fixed={false}
        minColumnWidth={MIN_COLUMN_WIDTH}
        headerHeight={headerHeight}
        rowHeight={ROW_HEIGHT}
        onColumnsFilter={(columns, columnKey) => module.handleSessionsTableColumnsFilter(columns)}
        onSelect={selectedRows => module.handleTableSelect(selectedRows)}
        onRowContextMenu={(rowIndex, rowContent, selectedRows, e) => module.handleRowContextMenu(rowContent, selectedRows, e)}
        onColumnsOrder={(columns, oldIndex, newIndex) => module.handleSessionsTableColumnOrder(columns, oldIndex, newIndex)}
        onColumnResize={(columns, columnKey) => module.handleSessionsTableColumnResize(columns)}
        showFilterOptionInHeaderMenu={true}
        filtersEnabled={filtersEnabled}
        onToggleFiltersClick={(e, newValue) => module.toggleFilters(newValue)}
        onDoubleClickRow={(rowIndex, rowContent, e) => module.handleDoubleClickRow(rowIndex, rowContent, e)}
      >
        {sessionsTableColumns.map(col => {
          const index = columnsToSortBy.findIndex(column => column.key === col.key);
          const sortDirection =
            col.sortType && index !== -1
              ? columnsToSortBy[index].ascending ? 'asc' : 'desc'
              : undefined;
          const handleClick = col.sortType
            ? module.handleSessionColumnClick(col.sortType)
            : undefined;
          return <Column
            key={col.key}
            title={col.caption}
            columnKey={col.key}
            width={col.width}
            visible={col.visible}
            header={handleSessionColumnHeader(col, handleClick, sortDirection, index)}
            cell={col.cell}
            cellDataGetter={col.cellDataGetter}
            shouldCellUpdate={col.shouldCellUpdate || defaultShouldCellUpdate}
          />;
        })}
      </Table>
    );
  };

  return (
    <div className='crtx-sessions-view' >
      {renderSessionsTable()}
    </div>
  );
}

View.propTypes = {
  module: PropTypes.object,
  viewModel: PropTypes.object,
  sessionsTableColumns: PropTypes.array,
};
