import toastService from 'core/services/toastService';
import AbstractAction from 'AbstractAction';
import { restPost } from 'core/managers/rest2';
import { makeUniqueName } from 'utilities/uniqueName';
import { translate } from 'core/services/localization';

export default AbstractAction.extend({

  execute: function (selected) {
    const { groups } = this.module.store.getState();
    const name = makeUniqueName(groups.map(g => g.name), `${selected[0].name} - Copy`);

    const restModel = {
      name: name,
      copySecurityFromNwId: selected[0].groupNwId,
    };
    restPost(this.module.nwid, `/folders/${this.module.folderNwid}/security-groups`, restModel, { version: 'v1' })
      .then(res => {
        if (res.errorMessage) {
          toastService.errorToast(translate('Duplicate security group failed'), res.errorMessage);
        }
      })
      .catch(err => {
        console.log('err=========', err);
      });
  }
});