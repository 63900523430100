import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';
import SeperationPreview from 'src/components/private/separations/SeperationPreview';
import Fanout from './Fanout';
import AnchorPoint from 'src/components/private/anchorPoint/AnchorPoint';
import StringBreadcrumbs from 'src/components/private/info/StringBreadcrumbs';
import {formatBreadcrumbs} from 'utilities/breadcrumbs';

const infolink = sandbox.icons.getModuleIcon('InfoDialog', 'infolink');
const translate = sandbox.localization.translate;

const labels = {
  fanoutParameters: translate('Fanout parameters'),
  nwid: translate('Nwid'),
  path: translate('Path'),
  pageName: translate('Pages Name'),
  version: translate('Version'),
  none: translate('none'),
  breadcrumbs: translate('Breadcrumbs'),
  colors: translate('Colors'),
}

module.exports = class Page extends Component {
  constructor(props) {
    super(props);
    this.getFanout = this.getFanout.bind(this);
    this.openCustomInfoDialog = this.openCustomInfoDialog.bind(this);
    this.isColors = this.isColors.bind(this);
    this.getPreview = this.getPreview.bind(this);
    this.getColorsPreview = this.getColorsPreview.bind(this);
    this.getSpotsPreview = this.getSpotsPreview.bind(this);

  }
  static propTypes = {
    fanout: PropTypes.any,
    page: PropTypes.any,
    object: PropTypes.any,
    advanceInfo: PropTypes.bool,
    openCustomInfoDialog: PropTypes.func,
    openerType: PropTypes.string//dialog or rightPanel
  }

  getFanout() {
    const { fanout, page, openerType } = this.props;
    if (fanout != undefined) {
      const fanoutComponent = <Fanout fanout={fanout} />
      const AnchorPointRadio = [fanout.anchorPoint]
      const anchorPoint = <AnchorPoint disabled={true} points={AnchorPointRadio} value={fanout.anchorPoint} />;

      return openerType === 'dialog' ? <div className='custom-info-row'><span className='custom-info-row-title'>{labels.fanoutParameters}: </span><span className='custom-info-row-content'>{fanoutComponent}{anchorPoint}</span></div> : <div className='custom-info-row'><div className='custom-info-row-title-rightPanel-fanout'>{labels.fanoutParameters}: </div><div className='custom-info-row-content-rightPanel-fanout'>{fanoutComponent}{anchorPoint}</div></div>;
    }
    return undefined;
  }

  openCustomInfoDialog() {
    const { page, openCustomInfoDialog } = this.props;
    openCustomInfoDialog(page.nwid);
  }

  isColors(color, index) {
    return color === 'Black' || color === 'black' ||color === 'Magenta' || color === 'magenta' ||color === 'Cyan' || color === 'cyan' || color === 'Yellow' || color === 'yellow';
  }

  getPreview(seperationPreview, index) {
    const seperationPreviewComp = <SeperationPreview key={index} colorName={seperationPreview} shared={false} />;
    return seperationPreviewComp;
  }

  getColorsPreview() {
    const { page } = this.props;
    const colors = page.separations.filter(this.isColors);
    const colorsPreview = colors.map(this.getPreview);
    return colorsPreview;
  }

  getSpotsPreview() {
    const { page } = this.props;
    const spots = page.separations.reject(this.isColors);
    const spotsPreview = spots.map(this.getPreview);
    return spotsPreview.length != 0 ? <span>Spots: {spotsPreview}</span> : undefined;
  }

  render() {
    const { page, object, advanceInfo } = this.props;
    const nwid = advanceInfo ? <div className='custom-info-row'><span className='custom-info-row-title'>{labels.nwid}: </span><span className='custom-info-row-content ellipsis' title={page.nwid}>{page.nwid || ''}</span></div> : "";
    const path = advanceInfo ? <div className='custom-info-row'><span className='custom-info-row-title'>{labels.path}: </span><span className='custom-info-row-content ellipsis-before' title={page.actualPath}>{page.actualPath || ''}</span></div> : "";

    return <div className='page'>
      <div className='custom-info-row'><span className='custom-info-row-title'>{labels.pageName}: </span><span className='custom-info-row-content ellipsis' title={page.name}>{page.name}</span></div>
      <div className='custom-info-row'><span className='custom-info-row-title'>{labels.version}: </span><span className='custom-info-row-content ellipsis' title={page.activeVersion || labels.none}>{page.activeVersion || labels.none}</span></div>
      <div className='custom-info-row'><span className='custom-info-row-title'>{labels.colors}: </span><span className='custom-info-row-content'><span className='regular-colors-content'>{this.getColorsPreview()}</span><span className="spot-colors-content">{this.getSpotsPreview()}</span></span></div>
      <div className='custom-info-row'><span className='custom-info-row-title'>{labels.breadcrumbs}: </span>
        <span className='custom-info-row-content' onClick={this.openCustomInfoDialog}>
          <span className='custom-info-breadcrumbs-and-icon'>
            <span className='custom-info-breadcrumbs ellipsis-before' title={formatBreadcrumbs(page.breadcrumbs)}>
              <StringBreadcrumbs currentBreadcrumbs={page.breadcrumbs}
                                 compareTo={object.breadcrumbs} />
            </span>
            <img className="custom-info-infolink-icon" src={infolink} />
          </span>
        </span></div>
      {this.getFanout()}
      {nwid}
      {path}
    </div>;
  }
}