/**
 * @name
 * @fileOverview
 * @author sergey & Elad
 */
import { fromServerDate } from 'core/dates';
import Ember from 'ember';
import ZoneTemplate from 'text!../templates/zone.hbs';
import sandbox from 'sandbox';

module.exports = Ember.View.extend({

  init: function () {
    this._super();
    var that = this;
    this.addObserver("model.deadline", function () {
      that.controller.updateDeadline(this.get('model'));
    });
  },

  template: Ember.Handlebars.compile(ZoneTemplate),

  isDeadLineOption: function () {
    if (this.model.selected) {
      return "visibility:visible;width:120px;font-size:10px";
    }
    return "visibility:hidden;width:120px";
  }.property('this.model.selected'),

  datePickerClassName: function () {

  }.property('this.model.selected'),

  dateTimeValue: function () {
    return this.model.deadline ? fromServerDate(this.model.deadline) : "";
  }.property('this.model.deadline'),

  okIconState: function () {
    return this.model.selected ? sandbox.icons.getModuleIcon("PlanningWizard", "ok_selected") :
      sandbox.icons.getModuleIcon("PlanningWizard", "ok");
  }.property('this.model.selected'),

  isLoadOption: function () {
    if (this.model.loadOption) {
      return "visibility:visible;padding-right: 5px";
    }
    return "visibility:hidden;padding-right: 5px";
  }.property('this.model.loadOption'),

  disabledStyle: function () {
    if (this.model.disabled) {
      return "height: 40px;color:silver;pointer-events: none";
    }
    return "height: 40px;";
  }.property(),

  disabledDivStyle: function () {
    if (this.model.disabled) {
      return "pointer-events: none";
    }
    return "";
  }.property(),

  click: function (evt) {
    var checkBox = evt.target;
    if (checkBox.tagName !== "INPUT" || checkBox.type.toLowerCase() !== "checkbox") {
      return;
    }
    var controller = this.get("controller");
    controller.zoneActionDone(this.get('model'), checkBox.checked);
  }
});