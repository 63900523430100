/**
 * @name
 * @fileOverview
 * @author liat
 */
define(['ember', 'text!../templates/commcenter.hbs'], function (Ember, CommCenterTemplate) {
  'use strict';
  return Ember.View.extend({
    template: Ember.Handlebars.compile(CommCenterTemplate),
    classNames: ['pro-modules-commcenter'],
    didInsertElement: function () {
      setTimeout(function () {
        this.$('.ember-table-tables-container').focus();
      }.bind(this), 150);

    }
  });
});