import React from 'react';

/*
 * @param DecoratedComponent - component from the 'components' folder
 * @param Converter - converter function from the 'converters' folder
 * @param getOptions - callback function to obtain options argument for the Converter
 * @param propName - property name that used to obtain argument value for getOptions callback
 */
export default (DecoratedComponent, Converter, getOptions, propName) => class extends React.Component {
  render() {
    var {children, ...props} = this.props;
    var propValue = this.props[propName];

    var options = getOptions(propValue);

    return (
      <DecoratedComponent
        {...props}
        converter={Converter(options)}
      >
        {children}
      </DecoratedComponent>
    );
  }
};
