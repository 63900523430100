/**
 * @fileOverview Pass through the required jQuery methods to the core.
 * @author sergey
 * */
/*src/utils/utils for testing*/
define(['./dom', './events', './data', './browser', './jsUtils'],
function (dom, events, data, browser, jsUtils) {
  "use strict";
  return {
    _name:    'base',
    _type:    'manager',
    dom:      dom,
    events:   events,
    data:     data,
    browser:  browser,
    jsUtils:  jsUtils
  };

});
