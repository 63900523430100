import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utilities/classNames';
import { Popover, PopoverContent, PopoverTrigger, DropdownArrow } from 'components/common/floating';
import NumericInput from '../inputs/NumericInput';
import ToggleFlatButton from '../buttons/ToggleFlatButton';

const SORTING_OPTIONS = ['day', 'hour', 'minute', 'second'];

const convertUnitMap = {
  day: 24,
  hour: 60,
  minute: 60,
  second: 1000
};

const optionsShortcuts = {
  day: 'd',
  hour: 'h',
  minute: 'm',
  second: 's'
};

const isUndefined = o => typeof o === 'undefined';

const sortFunc = (a, b) => {
  const sortingOptions = SORTING_OPTIONS;
  return sortingOptions.indexOf(a) - sortingOptions.indexOf(b);
};

class Duration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  static propTypes = {
    value: PropTypes.number,
    options: PropTypes.arrayOf(PropTypes.oneOf(['day', 'hour', 'minute', 'second'])),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    allowNegative: PropTypes.bool,
    style: PropTypes.object,
    min: PropTypes.number,
    max: PropTypes.number,
    onChange: PropTypes.func
  };

  static defaultProps = {
    value: 0,
    options: ['day', 'hour', 'minute'],
    disabled: false,
    allowNegative: true,
    onChange: function () {
    },
  };

  togglePopover = () => {
    if (!this.props.disabled) {
      this.setState({
        visible: !this.state.visible
      });
    }
  };

  closePopover = () => {
    this.setState({
      visible: false
    });
  };

  objectToValue = (obj, sortedOptions) => {
    const value = sortedOptions.reduce((acc, unit, index, arr) => {
      const mapUnit = index === arr.length - 1 ? 1 : convertUnitMap[unit];
      return (Number(obj[unit]) + acc) * mapUnit;
    }, 0);
    return obj['isNegative'] ? value * (-1) : value;
  };


  getInputText = (valueObj, sortedOptions) => {
    let text = valueObj['isNegative'] ? '-' : '';
    sortedOptions.forEach((sortedOption) => {
      text += `${valueObj[sortedOption]}${optionsShortcuts[sortedOption]} `;
    });
    return text;
  };

  converter = (map) => {
    return (acc, unit, index, arr) => {
      if (index === 0) {
        return acc;
      }

      const prevUnitValue = acc[arr[index - 1]];
      const unitValue = Math.floor(prevUnitValue / map[unit]);
      return {
        ...acc,
        [arr[index - 1]]: prevUnitValue - (unitValue * map[unit]),
        [unit]: unitValue
      };
    };
  };

  valueToObject = (value, sortedOptions) => {
    const { allowNegative } = this.props;
    const absValue = Math.abs(value);
    const reverseSortedOptions = [...sortedOptions].reverse();
    const accObj = reverseSortedOptions.reduce((acc, option, index) => {
      return {
        ...acc,
        [option]: index === 0 ? absValue : 0
      };
    }, {});

    return reverseSortedOptions.reduce(this.converter(convertUnitMap), {
      isNegative: !allowNegative ? false : value < 0,
      ...accObj
    });
  };

  handleChangeOption = (unit, oldObj, sortedOptions) => {
    const { onChange, allowNegative, min, max } = this.props;
    return (event, newValue) => {
      newValue = !allowNegative && newValue < 0 ? 0 : newValue;
      const newObj = { ...oldObj, [unit]: newValue };
      let valueToSave = this.objectToValue(newObj, sortedOptions);
      if (!isUndefined(min) && valueToSave < min) {
        valueToSave = min;
      }
      if (!isUndefined(max) && valueToSave > max) {
        valueToSave = max;
      }
      onChange(event, valueToSave);
    };
  };

  createOption = (valueObj) => {
    return (sortedOption, index, sortedOptions) => {
      return <div key={index} className='crtx-duration-option'>
        <NumericInput
          className='crtx-duration-option-numeric-input'
          value={valueObj[sortedOption]}
          onChange={this.handleChangeOption(sortedOption, valueObj, sortedOptions)}
        />
        <span className='crtx-duration-option-text'>{sortedOption}</span>
      </div>;
    };
  };

  handleClickPlusMinusSign = (oldObj, sortedOptions) => {
    return (event) => {
      const { onChange, min, max } = this.props;
      const newObj = { ...oldObj, isNegative: !oldObj.isNegative };
      let newValue = this.objectToValue(newObj, sortedOptions);
      if (!isUndefined(min) && newValue < min) {
        newValue = min;
      }
      if (!isUndefined(max) && newValue > max) {
        newValue = max;
      }
      onChange(event, newValue);
    };
  };

  renderOptions = (sortedOptions, valueObj) => {
    const { allowNegative } = this.props;
    return <div className='crtx-duration-options'>
      {
        allowNegative ? <div className='crtx-negative-positive'>
          <ToggleFlatButton
            checked={!valueObj['isNegative']}
            onClick={this.handleClickPlusMinusSign(valueObj, sortedOptions)}>
            <i className="material-icons">add</i>
          </ToggleFlatButton>
          <ToggleFlatButton
            checked={valueObj['isNegative']}
            onClick={this.handleClickPlusMinusSign(valueObj, sortedOptions)}>
            <i className="material-icons">remove</i>
          </ToggleFlatButton>
        </div> : undefined
      }
      {sortedOptions.map(this.createOption(valueObj))}
    </div>;
  };

  render() {
    const { value, options, className, style, disabled } = this.props;
    const { visible } = this.state;
    const sortedOptions = [...options].sort(sortFunc);
    const valueObj = this.valueToObject(value, sortedOptions);

    let text = this.getInputText(valueObj, sortedOptions);

    return (
      <Popover
        open={visible}
        placement='bottom-start'
        onOpenChange={this.closePopover}
      >
        <PopoverTrigger>
          <div
            className={classNames('crtx-duration', className, {disabled})}
            style={style}
            title={text}
            onClick={this.togglePopover}
          >
            <div className='crtx-duration-content'>{text}</div>
            <DropdownArrow opened={visible} disabled={disabled}/>
          </div>
        </PopoverTrigger>

        <PopoverContent>
          {visible && this.renderOptions(sortedOptions, valueObj)}
        </PopoverContent>
      </Popover>

    );
  }
}

export default Duration;