import React from 'react';
import PropTypes from 'prop-types';

function DragHandleCell({
  columnData,
  rowIndex,
  rowContent,
  onDragHandleDragStart,
}) {

  const handleDragStart = e => {
    if (typeof onDragHandleDragStart === 'function') {
      onDragHandleDragStart(rowIndex, rowContent, e);
    };
  };

  return (
    <div className='cell drag-handle-cell' onDragStart={handleDragStart} draggable={true}>
      <div className='cell-content'>
        {columnData}
      </div>
    </div>
  );
}

DragHandleCell.propTypes = {
  columnData: PropTypes.any,
  rowIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDragHandleDragStart: PropTypes.func,
};

export default DragHandleCell;
