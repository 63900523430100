/* @flow weak */

import React from 'react';
import { createRoot } from 'react-dom/client';
import sandbox from 'sandbox';
import dialogService from 'core/services/dialogService';
import labels from './labels';
import NewTable from './components/NewTable';
import { createStore } from 'redux';
import reducer from './newTableReducer';

const dom = sandbox.dom;
const contentDom = '<div class="conversion-tables-new-table-dialog"></div>';

function handleAddClick(onAddClick, store) {
  return function () {
    const state = store.getState();
    onAddClick(state.selectedCategory, state.selectedTable);
  };
}

function onCancel() {

}

function render(reactRoot, store) {
  return function () {
    reactRoot.render(<NewTable store={store} />);
  };
}

function initialState(categories, sampleTables, systemTables) {
  return {
    categories,
    sampleTables,
    systemTables,
    selectedCategory: 'samples',
    selectedTable: undefined
  };
}

function show(categories, sampleTables, systemTables, onAddClick) {
  const content = dom.createNode(contentDom);
  this.reactRoot = createRoot(content[0]);
  const store = createStore(reducer, initialState(categories, sampleTables, systemTables));
  const renderer = render(this.reactRoot, store);
  store.subscribe(renderer);
  renderer();

  dialogService.show({
    title: labels.addTable,
    content: content,
    height: 300,
    width: 600,
    buttons: [
      {
        title: 'Ok',
        onClick: handleAddClick(onAddClick, store),
      },
      {
        title: 'Cancel',
        onClick: onCancel
      }
    ]
  });
}


module.exports = {
  show
};