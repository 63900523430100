import React from 'react';
import settingsManager from 'core/managers/settings';

export default class extends React.Component {


  render() {
    const {style} = this.props;

    return <span className="crtx-login-version" style={style}>{settingsManager.get('version')}</span>;
  }
}
