/**
 * @file Combobox component with remove button
 *
 * @author Boris
 * @created 2023-07-23
 */
import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import { Combobox } from './Combobox';
import { IconButton } from 'components/common/buttons';

export function ComboboxWithButtons(
  {
    onRemove,
    getOptionProps: getOptionPropsProp,
    renderOption: renderOptionProp,
    ...props
  }) {

  const getOptionProps = (option) => {
    let result;
    if (typeof getOptionPropsProp === 'function') {
      result = getOptionPropsProp(option);
    } else if (typeof option === 'object' && option !== null) {
      result = option;
    } else {
      result = {
        text: option
      };
    }

    return result;
  };

  const renderOption = (option) => {
    let result;
    if (typeof renderOptionProp === 'function') {
      result = renderOptionProp(option);
    } else {
      const { text } = getOptionProps(option);
      result = text;
    }

    return result;
  };

  const renderButtons = (option) => {
    const buttons = [];
    if (typeof onRemove === 'function') {
      buttons.push(
        <IconButton
          key='remove'
          className='crtx-size-16'
          iconName='close'
          tooltip={translate('Close')}
          onClick={e => onRemove(e, option)}
        />
      );
    }

    if (buttons.length <= 0) {
      return null;
    }

    return (
      <div className='option-buttons'>
        {buttons}
      </div>
    );
  };

  let renderComposedOption = renderOption;

  renderComposedOption = (option) => {
    return (
      <>
        {renderOption(option)}
        {renderButtons(option)}
      </>
    );
  };

  return <Combobox {...props} getOptionProps={getOptionProps} renderOption={renderComposedOption} />;
}

ComboboxWithButtons.propTypes = {
  ...Combobox.propTypes,
  onRemove: PropTypes.func,
};