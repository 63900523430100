/**
 * @fileOverview
 * @author sergey
 */

import AbstractAction from 'AbstractAction';
import logger from 'logger';
import sandbox from 'sandbox';
import dialogService from 'core/services/dialogService';

const { localization: { translate } } = sandbox;

var log = logger.getDefaultLogger();

export default AbstractAction.extend({
  isApplicable: function (events) {
    if (typeof events === 'undefined') { return false; }
    var i = 0, len = events.length;
    if (len === 0) { return true; }
    for (i; i < len; i += 1) {
      var type = events[i].type;
      if (type.indexOf("physical/") === 0) {
        continue;
      }
      if (events[i].lockedTo === void 0 || events[i].lockedTo === "") {
        return false;
      }
      var state = events[i].state;
      if (state !== "queWait" && state !== "queHold") {
        return false;
      }
      //if (events[i].type !== 'workflow/event') { return false; }
    }
    return true;
  },

  execute: function (events) {
    var viewInstanceNwId = this.module.nwid;
    var len = events.length;
    if (len === 0) { return; }
    var type = events[0].type;
    var deviceNwId;
    if (type.indexOf("physical/") === 0) {
      deviceNwId = events[0].nwid;
    } else {
      deviceNwId = this.module.viewSettings.rootId;
      type = this.module.viewSettings.rootType;
    }

    var projectorId = this.module.projectorId;
    dialogService.openConfirmDialog(translate('Are you sure you want to unlock events locked to this resource?'), translate('Unlock Events'))
      .then(() => {
        return sandbox.request.unlockEvents(viewInstanceNwId, this.nwid, deviceNwId, type, projectorId,
          {
            config: this.config,
            items: events.map(function (e) {
              return { nwid: e.nwid };
            }),
            parameters: this.parameters
          }
        )
      });
  }
});