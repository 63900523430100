import React, { useState, useEffect, useRef } from "react";
import { translate } from 'core/services/localization';
import { restGet } from 'core/managers/rest2';
import FilesList from './FilesList';
import FileContent from './FileContent';
import dom from 'base/dom';

const FILE_LIST_REFRESH_INTERVAL = 5000;

const FileViewerMain = ({ actionNwid, module }) => {
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [failedFetchFiles, setFailedFetchFiles] = useState(false);
  const intervalIdRef = useRef(null);
  const prevPanesRef = useRef(null);

  function initSplitter() {
    const splitterContainer = dom.find('#file-viewer', module.domElement);

    splitterContainer.kendoSplitter({
      orientation: 'horizontal',
      panes: module.preferences.panes || [
        { // file list
          size: '200px',
          collapsible: true,
          resizable: true
        },
        { // file content
          collapsible: false,
          resizable: true
        }
      ],

      resize: e => {
        const splitter = dom.data(splitterContainer, 'kendoSplitter');
        const panes = splitter && splitter.options && splitter.options.panes ? splitter.options.panes : [];
        if (prevPanesRef.current) {
          const changedPanes = panes.some((pane, index) =>
            pane.size !== prevPanesRef.current[index].size || pane.collapsed !== prevPanesRef.current[index].collapsed);
          if (changedPanes) {
            module.savePreferences({ panes: [...panes] });
          }
        }
        prevPanesRef.current = panes ? panes.map(pane => ({ ...pane })) : [];
      }
    });
  }

  useEffect(() => {
    initSplitter();
  }, []);

  function fetchFiles() {
    restGet(actionNwid, '/file-viewer/files').then(data => {
      if (data && data.files) {

        setFiles(data.files);
        if (data.files.length === 1) {
          setSelectedFile(data.files[0]);

        } else if (data.files.length > 1) {
          setSelectedFile((prevSelected) => {
            return prevSelected ? data.files.find(item => item.fileName === prevSelected.fileName) : null;
          });
        }

      }
    }).catch(err => {
      setFailedFetchFiles(true);
      clearInterval(intervalIdRef.current);
    });
  }

  useEffect(() => {
    fetchFiles();

    intervalIdRef.current = setInterval(() => {
      fetchFiles();
    }, FILE_LIST_REFRESH_INTERVAL);

    return () => {
      clearInterval(intervalIdRef.current);
    }

  }, [actionNwid]);


  function renderFileContent() {
    if (files.length > 0 && !selectedFile) {
      return (
        <div className="file-viewer-message">
          {translate('Please select a file to view its content.')}
        </div>
      );
    } else if (selectedFile) {
      const { fileName, modified } = selectedFile || {};
      return (
        <FileContent
          fileName={fileName}
          modified={modified}
          actionNwid={actionNwid}
        />
      );
    }
  }

  function getStyle(id) {
    if (failedFetchFiles && id === 'file-viewer' || !failedFetchFiles && id === 'msg-container') {
      return { 'display': 'none' };
    }
    return {};
  }

  return (
    <div className='file-viewer-container crtx-text-selectable'>
      <div id='msg-container' className="file-viewer-msg-container" style={getStyle('msg-container')}>
        <div className="file-viewer-err-msg">
          {translate('Failed to fetch files.')}
        </div>
      </div>
      <div id='file-viewer' className='file-viewer' style={getStyle('file-viewer')}>
        <FilesList items={files} handleSelectFile={setSelectedFile}
          selectedFile={selectedFile} />
        <div className="file-viewer-file-content">
          {renderFileContent()}
        </div>
      </div>
    </div>
  );

};

export default FileViewerMain;