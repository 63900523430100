const separators = new RegExp(['\\\\', '/'].join('|'), 'g');

function getParentFolderPath(path, separator) {
  const cursor = path.split(separators).filter(function (item) { return item !== '' });
  return cursor.length === 0 ? '' : cursor.slice(0, cursor.length - 1).join(separator);
}

function noEmptyNames(name) {
  return name !== '';
}

function createCursorFromPath(rootName, path, separator) {
  if (path === '') {
    return [{ name: rootName, displayName: rootName }];
  }
  const cursor = path.split(separators).filter(noEmptyNames);

  const objCursor = cursor.map(function (item) {
    const indexof = item.indexOf(':');
    return {
      displayName: indexof != -1 ? item.substring(0, indexof) : item,
      name: item
    }
  });
  return [{ name: rootName, displayName: rootName }].concat(objCursor);
}

function getSelectedItem(path, separator) {
  const cursor = path.split(separators).filter(function (item) { return item !== '' });
  const lastCursorItem = cursor.length === 0 ? '' : cursor.slice(-1);
  return {
    displayName: lastCursorItem[0],
    name: lastCursorItem[0]
  };
}

module.exports = {
  getParentFolderPath,
  createCursorFromPath,
  getSelectedItem
};