/**
 * @name
 * @fileOverview
 * @author sergey
 */
define(['ember', '../views/TreeTableRow', './defineHelpers', 'ember_table'], function (Ember, TreeTableRowView, defineHelpers) {
  'use strict';

  return {
    create: function (columnDefinitions) {
      return Ember.Mixin.create({
        content: null,
        bodyContent: Ember.computed(function () {
          var rows;
          rows = this.get('rows');
          if (!rows) {
            return Ember.A();
          }
          rows = rows.slice(1, rows.get('length'));
          return rows.filterProperty('isShowing');
        }).property('rows'),
        columns: Ember.computed(function () {
          var columns, data;
          data = this.get('content');
          if (!data) {
            return;
          }
          columns = columnDefinitions.slice(1).map(function (columnDef) {
            return defineHelpers.defineColumn(columnDef);
          });
          columns.unshiftObject(this.get('groupingColumn'));
          return columns;
        }).property(),
        groupingColumn: Ember.computed(function () {
          return defineHelpers.defineColumn(columnDefinitions[0]);
        }).property(),
        rows: Ember.computed(function () {
          var root;
          root = this.get('root');
          if (!root) {
            return Ember.A();
          }
          return this.flattenTree(null, root, Ember.A());
        }).property('root'),
        root: Ember.computed(function () {
          var data;
          data = this.get('content');
          if (!data) {
            return;
          }
          return this.createTree(null, data.root);
        }).property('content'),
        flattenTree: function (parent, node, rows) {
          var _this = this;
          rows.pushObject(node);
          (node.children || []).forEach(function (child) {
            return _this.flattenTree(node, child, rows);
          });
          return rows;
        },
        createTree: function (parent, node) {
          var children, row,
            _this = this;
          row = TreeTableRowView.create({
            content: node,
            parentController: _this
          });
          children = (node.children || []).map(function (child) {
            return _this.createTree(row, child);
          });

          row.set('parent', parent);
          row.set('children', children);

          Ember.addObserver(row, 'content.children.@each', this, function() {
            return Ember.run.next(this, function() {
              return Ember.run.once(this, this.updateRowChildren);
            });
          });
          return row;
        },

        updateRowChildren: function() {
          return this.notifyPropertyChange('content');
        },

        toggleCollapse: function (row) {
          row.toggleProperty('isCollapsed');
          return Ember.run.next(this, function () {
            return this.notifyPropertyChange('rows');
          });
        }
      });
    }
  };
});