/**
 * @name
 * @fileOverview
 * @author ofer
 */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';

import AbstractModule from 'AbstractModule';
import PlanHistory from './PlanHistory';
import reducer from './reducer';
import { restGet } from 'core/managers/rest2';

module.exports = AbstractModule.extend({
  initiateStore: function (plans, initialSelection = []) {
    this.store = createStore(reducer,
      {
        plans: plans.sort((planA, planB) => planA.nwid - planB.nwid),
        selected: [...initialSelection],
        diffs: undefined
      },
      applyMiddleware(ReduxThunk)
    );
    this.store.subscribe(this.render.bind(this));
    this.render();
  },
  initDone: function () {
    // this.initiateStore(plans);
    this.reactRoot = createRoot(this.domElement);
  },
  firstTickReceived: function (data) {
    const nwid = data.model.nwid;
    const type = data.model.type;
    restGet(this.nwid, `containers/${nwid}/plans?type=${type}`)
      .then(data => {
        if (type === 'workflow/event') {
          const event = data.event;
          const lastActiveDate = Math.max.apply(Math, data.plans.map(function (p) {
            return p.nwid === event ? 0 : p['import-time'] ? new Date(p['import-time']).getTime() : 0;
          }));

          if (lastActiveDate !== 0) {
            const lastActive = data.plans.find(function (p) { return new Date(p['import-time']).getTime() == lastActiveDate; });
            this.initiateStore(data.plans || [], [].concat(lastActive.nwid, event));
          } else {
            this.initiateStore(data.plans || [], [].concat(event));
          }
        } else {
          this.initiateStore(data.plans || [], []);
        }
      });
  },
  tickUpdate: function (data) { },
  tickCommit: function () { },
  render: function () {
    this.reactRoot.render(<PlanHistory store={this.store} />);
  }
});