/**
 * Created by tzvikas on 5/20/2015.
 */
define(['jquery', 'ember', 'text!../templates/page.hbs'],
  function ($, Ember, MainTemplate) {
    'use strict';

    /////// write emty layer for halfweb
    ////////////////////////////////////////

    return Ember.View.extend ({

      //actions: {
      //  setDinky: function(event){
      //    Ember.get (this, "controller").setDinky(this, event, this.model);
      //  }
      //},

      isDinkyProp:function (){
        return this.model.isDinky || this.model.halfWeb || this.model.eightUpDinky;
      }.property(),
      watchDinky: function(){
        var layoutType = this.get('form.layoutType');
        if (layoutType === "2ups") {
          Ember.set(this.model, "isDinky", !Ember.get(this.model, "isDinky"));
        } else if (layoutType === "8ups") {
          Ember.set(this.model, "eightUpDinky", !Ember.get(this.model, "eightUpDinky"));
        } else {
          Ember.set(this.model, "halfWeb", !Ember.get(this.model, "halfWeb"));
        }

        Ember.get (this, "controller").setDinky(this.model, this.get('form'));
      }.observes('isDinkyProp'),

      template: Ember.Handlebars.compile(MainTemplate),

      pageStyle : null,

      isDinkyOption: function () {
        var layoutType = this.get('form.layoutType');
        var numPages = parseInt(this.get('form').numPages, 10);
        if (layoutType === "2ups" || layoutType === "4ups"){
          var ret = Ember.get (this, "controller").isDinkyOption(this.model, layoutType, numPages);
          //if (ret && this.model.isDinky){
          //  return false;
          //}
          return ret;
        } else if (layoutType === "8ups") {
          var ret = Ember.get (this, "controller").isDinkyOption(this.model, layoutType, numPages);
          if (ret && this.model.isDinky){
            return false;
          }
          return ret;
        }
        return false;
      }.property(),

      pageClass: function (){
        //Ember.get (this, "controller").matrixLayout(this.form);
        var sourceId = this.model.sourceId;
        var visibility = sourceId === null || typeof sourceId === 'undefined' || sourceId === "$empty$" ?
           "visibility:hidden" : "visibility:visible";
        var layoutType = this.get('form.layoutType');
        var panorama = Ember.get(this.model, "panorama");
        if (layoutType === "2ups"){
          var isFormPano = Ember.get (this, "controller").isFormPanorama(this.get('form'));
          if ((sourceId === null || typeof sourceId === 'undefined' || sourceId === "$empty$") && isFormPano){
            visibility = "display:none";
          }
        }

        var style = "position:relative;";
        var className;
        if (layoutType === "2ups" || layoutType === "broadsheet"){
          if (layoutType === "broadsheet" && !panorama){
            style += "top:20px;left:8px;";
          }
          className = panorama ? "panoramaBsPage" : "bsPage";
        } else {
          if (layoutType === "tabs"){
            var i = Ember.get(this.model, "index");
            //var top = 20 + (i*30);
            //style += "top:20px;left:8px;";
            className = panorama ? "bsPage" : "tabPage";
          } else if (layoutType === "4ups"){
            var index = this.model.index;
            if (index === null || typeof index === 'undefined'){
              className = 'tabPage';
            }else if (index.length === 1){
              className = panorama ? "bsPage" : "tabPage";
            } else {
              if (index[1] - index[0] === 1){
                className = "tabHorizontalPano";
              } else if (index[0] === 1){
                className = "tabLeftPano";
              } else {
                className = panorama ? "tabRightPano" : "tabPage";
              }
            }

          } else if (layoutType === "8ups") {
            var index = this.model.index;
            if (index === null || typeof index === 'undefined') {
              className = panorama ? "smallTabHorizontalPano" : "smallTabPage";
            } else if (index.length === 1) {
              className = panorama ? "smallTabHorizontalPano" : "smallTabPage";
            } else {
              if (index[1] - index[0] === 1) {
                className = "smallTabHorizontalPano";
              } else if (index[0] === 1) {
                className = "smallTabHorizontalPano";
              } else {
                className = panorama ? "smallTabHorizontalPano" : "smallTabPage";
              }
            }
          } else if (layoutType === "pmi") {
            var index = this.model.index;
            var rows = parseInt(this.get('form.rows'), 10);
            var columns = parseInt(this.get('form.columns'), 10);
            var marginX = (columns + 1) * 2;
            var marginY = (rows + 1) * 2;
            var w = 1, h = 1;
            var mw = 0, mh = 0;
            if (index === null || typeof index === 'undefined') {
              w = 1;
              h = 1;
            } else if (index.length === 1) {
              w = panorama ? 2 : 1;
              marginX = panorama ? 0 : marginX;
            } else {
              if (index[1] - index[0] === 1) {
                w = 2;
                marginX = 0;
              } else {
                h = 2;
                marginY = 0;
              }
            }

            style += "width:" + ((140-marginX)/columns)*w + "px;height:" + ((90-marginY)/rows)*h +"px;";
            style += "outline: solid gray 1px;float: left;margin:2px 2px 2px 2px;";
            console.log ("indexes " + index + ":" + this.model + ":" + w + ":" + h);
          }
            //className = panorama ? "bsPage" : "tabPage";
        }
        style += visibility;
        Ember.set(this, "pageStyle", style);
        return className;
      }.property ()

    });






  });