/**
 * @name ListItem
 * @file ListItem component used as default in List component
 *
 * @author Boris
 * @since: 2017-02-19
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    idField: PropTypes.string,
    nameField: PropTypes.string
  };

  static defaultProps = {
    idField: 'id',
    nameField: 'name',
    countField: 'count'
  };

  handleClick = (e) => {
    const { onItemClick, item } = this.props;

    if (typeof onItemClick == 'function') {
      onItemClick(e, item);
    }
  };

  handleMouseDown = (e) => {
    const { onItemMouseDown, item } = this.props;

    if (typeof onItemMouseDown == 'function') {
      onItemMouseDown(e, item);
    }
  };

  renderIcon() {
    const { item } = this.props;
    if (!item.icon) {
      return null;
    }

    const clsName = `crtx-list-item-icon ${item.icon.className || ''}`;

    return (
      <div className={clsName} style={item.icon.style} />
    );
  }

  renderName() {
    const { item, nameField } = this.props;
    const name = item[nameField];

    return (
      <div className='crtx-list-item-name'>
        {name}
      </div>
    );
  }

  renderCount() {
    const { item, countField } = this.props;
    const count = item[countField];

    if (!(count > 0)) {
      return;
    }

    return (
      <div className='crtx-list-item-count'>
        {count}
      </div>
    );
  }

  render() {
    const { item } = this.props;
    const className = `crtx-list-item ${item.selected ? 'selected' : ''}`;

    return (
      <div className={className}
        onClick={this.handleClick}
        onMouseDown={this.handleMouseDown}
      >
        {this.renderIcon()}
        {this.renderName()}
        {this.renderCount()}
      </div>
    );
  }

};
