/**
 * Created by moshemal on 12/23/14.
 */


define(['sandbox/request', 'sandbox/jsUtils', 'sandbox', 'modules/WorkflowView/WorkflowView',
	'widgets/EditableGraph/EditableGraph', 'text!./editTemplate.html'],
function (request, jsUtils, sandbox, WorkflowView, EditableGraph, editTemplate) {
	'use strict';
	function editWorkflow() {
    var oldModel = this.view.graph.model;
    var graphModel  = {
        nodeKeyProperty: 			oldModel.nodeKeyProperty,
        linkFromKeyProperty:	oldModel.linkFromKeyProperty,
        linkToKeyProperty:    oldModel.linkToKeyProperty,
        nodeDataArray:        this.view.exportNodesData(),
        linkDataArray: 				this.view.exportTransitions(),
        preferences:          this.view.getPreferences()
      },
      elementId 	= this.element.slice(1),
      prms = request.simpleGenericCommand(this.getRequiredParameters().rootId, this.getRequiredParameters().rootType, 'getWorkflowPalette');

    var scale = this.view.graph.scale;
    this.view ? this.view.destroy() : "";
		sandbox.dom.html(this.element, jsUtils.format(editTemplate, elementId));

    var module = this;
    var editableGraph = new EditableGraph({
			element: elementId + "-graph",
      group: {
        labelProperty: "label",
        editableLabel: true
      },
			node: {
				labelProperty: "label",
				editableLabel: true,
        doubleClick: function(event, node) {
          module.navigateByViewClass(node, "BinderView", {target: "window"});
        }
			}
		});

    editableGraph.insertData(graphModel);
    editableGraph.graph.scale = scale;
    this.view = editableGraph;

		prms.then(function(res){
			var palettesArr = [];
      for (var p in res){
        res[p].packedFlowSteps.forEach(function (e){
          var step = {
            category: p,
            label: e.name,
            type: res[p].template
          };
          if(e.fileName){
            step.fileName = e.fileName;
          }
          palettesArr.push(step);
        });
			}
			editableGraph.createPalettes({
				element: elementId + "-palettes",
				palettes: palettesArr,
				closeFunction: function(){
          module._exitEditWorkflow();
				}
			});
		});
    this.toolbar.addItem({
      label: 'group',
      name: 'group',
      _isApplicable: true,
      icon: 'group',
      execute: function(){
        this.view.groupNodes();
      }.bind(this)
    });
    this.toolbar.addItem({
      label: 'ungroup',
      name: 'ungroup',
      _isApplicable: true,
      icon: 'ungroup',
      execute: function(){
        this.view.unGroupNodes();
      }.bind(this)
    });
	}

  function exitEditWorkflow(){
    var moduleId = this.id;
    sandbox.reloadModule(moduleId);
  }


	return WorkflowView.extend({
		_editWorkflow:	    editWorkflow,
    _exitEditWorkflow:  exitEditWorkflow,
    exitEditMode: exitEditWorkflow
	}, "EditableWorkflowView");
});