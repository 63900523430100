/**
 * Created by elad on 6/8/2015.
 */
define(['AbstractAction', 'sandbox/request', 'utilities/statuses'], function (AbstractAction, request, statuses) {
  'use strict';
  return AbstractAction.extend({

    isApplicable: function() {
      var objects = this.module.selected;
      if (typeof objects === 'undefined') {
        return false;
      }
      for (var i = 0; i < objects.length; i++) {
        var object = objects[i];
        var status = statuses.getMainStatus(object);
        if (typeof status === 'undefined' || status === null) {
          return false;
        }
        if (status.statusType === 'Error') {
          var includedTypes = ['workflow/step/image/pdfpreflight', 'workflow/step/image/preflight', 'workflow/step/image/optimization'];
          var flowstepType = status.flowStepType;
          //var flowstepType = status.flowStep.type;
          if (!includedTypes.contains(flowstepType)) {
            return false;
          }
        } else {
          return false;
        }

      }
      return true;
    },

    execute: function() {
      var objects = this.module.selected;
      if (objects.length === 0) {
        return false;
      }
      var object = objects[0];
      var nwids = objects.map(function(obj) {
        return obj.nwid;
      });
      var params = {
          nwids: nwids
        };
      return request.execServerAction(
        this.nwid,
        "SkipErrorAction",
        object.nwid,
        object.type,
        this.module.projectorId,
        this.parameters.scriptName,
        params
      );

	    if (this.module.name.toLowerCase() === 'pageview') {
		    setTimeout (function () {
			    window.close ();
		    }, 1500);
	    }
    }
  });
});