import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/common/buttons/Button';

export default class PrintButton extends React.Component {

    static contextTypes = {
        onClick: PropTypes.func
    }

    static propTypes = {
        style: PropTypes.object,
        className: PropTypes.string
    }

    render() {
        return (
            <Button disabled={this.props.disabled} className={this.props.className} style={this.props.style} onClick={() => this.context.onClick()}>
                Print
            </Button>
        )
    }
}