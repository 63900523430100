import React from 'react';
import { createRoot } from 'react-dom/client';
import AbstractModule from 'AbstractModule';
import { arrayToObject, moveItem } from 'utilities/array';
import View from './src/View';
import TickableModel from '../TickableModel';
import { makeMonitorProfilesTableColumns } from './src/columnsCreator';
import { createObjectComparator, composeComparators } from 'core/comparators';
import { savePreferences } from 'core/managers/preferences';
import jsutils from 'base/jsUtils';
import {
  checkTextCondition,
  extractColumnPreferences,
  reduceColumnsToFilterBy,
  FILTER_DATA_TYPE,
  FILTER_TYPE,
  reduceColumnsToSortBy,
  applyColumnSortPreferences,
  extractColumnSortPreferences
} from 'widgets/ReactDataGrid/utils';

import { translate } from 'core/services/localization';

const DEFAULT_SORT = [
  {
    ascending: true,
    key: 'monitorName'
  }
];

const THROTTLE_WAIT = 1000;

export default AbstractModule.extend({

  initDone: function () {
    this.toolbar = this.createToolbar();
    this.updates = [];
    this.tickUpdateHandlerThrottled = jsutils.throttle(this.tickUpdateHandler, THROTTLE_WAIT, {
      leading: false,
      trailing: true
    });
    this.reactRoot = createRoot(this.domElement);
  },

  firstTickReceived: function (data) {
    this.preferences = data.preferences || {};
    this.columnsToSortBy = data.preferences.columnsToSortBy || DEFAULT_SORT;
    this.filtersEnabled = this.preferences.table?.filtersEnabled || false;
    this.initToolbar();
    this.tickableModel = new TickableModel();

    this.tickableModel.firstTickHandler(data.model);
    this.buildViewModel(data.model);

  },

  tickUpdate: function (data) {
    this.updates = this.updates.concat(data.model);
    this.tickUpdateHandlerThrottled();
  },

  tickUpdateHandler: function () {
    this.tickableModel.tickUpdateHandler(this.updates);
    this.updates = [];
    this.buildViewModel();
  },


  initToolbar: function () {
    this.toolbar.addItem({
      label: translate('Toggle Filters'),
      name: 'toggleFilters',
      _isApplicable: true,
      icon: 'filter_list',
      iconSprite: 'general',
      itemType: 'push',
      checked: this.filtersEnabled,
      execute: this.toggleFilters.bind(this)
    });
  },

  buildViewModel: function () {
    const model = this.tickableModel.model();

    this.viewModel = model;
    this.viewModel.sortedMonitorProfiles = [...this.viewModel.monitorprofiles];
    this.viewModel.filteredMonitorProfiles = [...this.viewModel.sortedMonitorProfiles];
    this.monitorProfilesTableColumns = makeMonitorProfilesTableColumns(this.viewModel, this);
    this.sortMonitorProfiles();
    this.filterMonitorProfiles();
    this.updateSelectedRows();
    this.render();
  },

  sortMonitorProfiles: function () {
    if (this.columnsToSortBy.length > 0) {
      const preferencesColumnsToSortBy = applyColumnSortPreferences(this.monitorProfilesTableColumns, this.columnsToSortBy);

      const comparator = composeComparators(preferencesColumnsToSortBy.map(col => {
        return createObjectComparator(col.sortValueGetter || col.key, col.sortType, col.ascending);
      }));
      this.viewModel.sortedMonitorProfiles = this.viewModel.sortedMonitorProfiles.sort(comparator);
    }
  },

  filterMonitorProfiles: function () {
    this.toolbar.items.forEach(item => {
      if (item.name === 'toggleFilters') {
        item.checked = this.filtersEnabled;
      }
    });

    if (!this.filtersEnabled) {
      this.viewModel.filteredMonitorProfiles = this.viewModel.sortedMonitorProfiles;

    } else {
      const columnsToFilterBy = reduceColumnsToFilterBy(this.monitorProfilesTableColumns);

      this.viewModel.filteredMonitorProfiles = this.viewModel.sortedMonitorProfiles.filter(monitorProfile => {

        let match = true;
        for (const col of columnsToFilterBy) {
          const filter = col.filter;

          if (filter.type === FILTER_TYPE.TEXT && filter.textValue) {
            if (filter.dataType === FILTER_DATA_TYPE.TEXT) {
              const text = typeof col.filterValueGetter === 'function' ? col.filterValueGetter(monitorProfile) : monitorProfile[col.key];
              match = checkTextCondition(text, filter);
            }
          }
          if (!match) {
            break;
          }
        }
        return match;
      });
    }
  },

  toggleFilters: function (pushed) {

    this.filtersEnabled = pushed;

    this.toolbar.setItemChecked('toggleFilters', pushed);

    this.filterMonitorProfiles();

    this.saveMonitorProfilesColumnPreferences();

    this.buildViewModel();
  },

  handleToolbarButtonsClick: function (checked) {

    if (checked) {
      this.toolbar.setItemChecked('toggleFilters', checked);
      this.filtersEnabled = checked;
    }

    this.filterMonitorProfiles();

    const columns = extractColumnPreferences(this.monitorProfilesTableColumns);

    this.savePreferences({
      ...this.preferences,

      table: {
        ...this.preferences.table,
        filtersEnabled: this.filtersEnabled,
        monitorProfileColumns: columns
      }
    });
    this.render();
  },

  handleMonitorProfileColumnClick: function (sortType) {
    return (columnKey, sortValueGetter, multiSort) => {
      this.columnsToSortBy = reduceColumnsToSortBy(this.monitorProfilesTableColumns, this.columnsToSortBy, columnKey, multiSort);
      this.sortMonitorProfiles();
      this.filterMonitorProfiles();
      this.savePreferences({
        ...this.preferences,
        columnsToSortBy: extractColumnSortPreferences(this.columnsToSortBy)
      });
      this.render();
    };
  },

  handleDoubleClickRow: function (selectedRow) {
    const actionToNavigate = 'EditMonitorProfileActionCR';
    const editProfileAction = this.viewActions.find(va => va.actionDefinitionName === actionToNavigate);

    if (editProfileAction) {
      this.navigateByAction(this.tickableModel.getByNwid(selectedRow.nwid), actionToNavigate);
    }
  },



  saveMonitorProfilesColumnPreferences: function () {
    const monitorProfileColumns = extractColumnPreferences(this.monitorProfilesTableColumns);

    this.savePreferences({
      table: {
        ...this.preferences.table,
        filtersEnabled: this.filtersEnabled,
        monitorProfileColumns,
      }
    });
  },

  savePreferences: function (preferences) {
    this.preferences = Object.assign(this.preferences, preferences);
    savePreferences(this.getRequiredParameters(), this.preferences);
  },

  handleMonitorProfilesTableColumnsFilter: function (columns) {
    this.monitorProfilesTableColumns.forEach(col => {
      if (columns[col.key]) {
        col.visible = columns[col.key].visible;
      }
    });

    this.saveMonitorProfilesColumnPreferences();
  },

  handleMonitorProfilesTableColumnOrder: function (columns, oldIndex, newIndex) {
    moveItem(this.monitorProfilesTableColumns, oldIndex, newIndex);
    this.saveMonitorProfilesColumnPreferences();
    this.render();
  },

  handleMonitorProfilesTableColumnResize: function (columns) {
    this.monitorProfilesTableColumns.forEach(col => {
      if (columns[col.key]) {
        col.width = columns[col.key].width;
      }
    });

    this.saveMonitorProfilesColumnPreferences();
  },

  handleMonitorProfileColumnFilterChange: function (column, columnFilter) {
    if (!column || !column.filter || !column.filter.type) {
      return;
    }

    column.filter = {
      ...column.filter,
      ...columnFilter
    };

    this.saveMonitorProfilesColumnPreferences();
    this.filterMonitorProfiles();
    this.render();
  },

  updateSelectedRows: function () {
    const rowsByNwid = arrayToObject(this.viewModel.monitorprofiles, 'nwid');

    const selectedRows = this.selected.reduce((acc, row) => {
      const updatedRow = rowsByNwid[row.nwid];
      if (updatedRow) {
        acc.push(updatedRow);
      }

      return acc;
    }, []);

    this.updateSelected(selectedRows);
  },

  handleTableSelect: function (selectedRows) {

    this.updateSelected(selectedRows);
  },

  handleRowContextMenu: function (clickedRow, selectedRows, e) {
    this.showContextMenu(clickedRow, selectedRows, e);
  },


  destroy: function () {
    this._super();
    this.reactRoot?.unmount();
    this.reactRoot = null;
  },

  render: function () {
    this.reactRoot.render(<View module={this}
      viewModel={this.viewModel}
      monitorProfilesTableColumns={this.monitorProfilesTableColumns}
    />);
  },
});
