define(['sandbox', 'AbstractAction'], function(sandbox, AbstractAction) {
    'use strict';

    return AbstractAction.extend({

        isApplicable: function(objs) {
            if (typeof objs === 'undefined') {
                return false;
            }
            for (var ix = 0; ix < objs.length; ix++) {
                var obj = objs[ix];
                if (typeof obj !== 'undefined') {
                    if (!obj.userLocked) {
                        return false;
                    }
                }
            }
            return true;
        },

        execute: function(objs) {

            var viewInstanceNwId = this.module.nwid;
            var projectorId = this.module.projectorId;

            for (var ix = 0; ix < objs.length; ix++) {
                var obj = objs[ix];
                if (typeof obj !== 'undefined') {
                    sandbox.request.unlockContainer(
                        viewInstanceNwId,
                        this.nwid,
                        obj["nwid"],
                        obj["type"],
                        projectorId, {
                            config: this.config,
                            type: obj["type"],
                            nwid: obj["nwid"],
                            parameters: this.parameters
                        });
                }
            }
        }
    });
});