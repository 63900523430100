const getMultipleSelectedRows = (
  selectedRows, /*object - containing columnKeys has keys and true if the row with the key is selected*/
  selectedRowsObj, // object which contains the selected rows with each row's id as the key
  rows, /*array - the table rows*/
  clickedIndex, /*number - the index of the row that was clicked*/
  lastClickIndex, /*number - the index of the row that was previously selected*/
  columnKey /*string - the columnKey of the table representing a uid for each row*/
) => {
  const startIndex = lastClickIndex,
    endIndex = clickedIndex,
    hasColumnKey = typeof columnKey === 'string',
    newSelectedRows = [...selectedRows];
  let i = startIndex;

  if (endIndex > startIndex) {
    for (; i <= endIndex; i++) {
      if (hasColumnKey && !selectedRowsObj[rows[i][columnKey]]) {
        newSelectedRows.push(rows[i]);
      };
    };
  } else {
    for (; i >= endIndex; i--) {
      if (hasColumnKey && !selectedRowsObj[rows[i][columnKey]]) {
        newSelectedRows.push(rows[i]);
      };
    };
  }

  return newSelectedRows;
};

const getSelectedRowsIndex = (
  selectedRows,
  rows,
  columnKey
) => {
  return Object.keys(selectedRows).map(function (selectedRowKey) {
    let rowIndex = rows.findIndex(function (row) {
      return row[columnKey].toString() === selectedRowKey
    });
    return rowIndex;
  }).sort((a, b) => {
    return a - b;
  });
};

const getSelectedRows = (
  selectedRowsObj,
  rows,
  columnKey
) => {
  return getSelectedRowsIndex(selectedRowsObj, rows, columnKey)
    .map(rowIndex => rows[rowIndex]);
};

const getRowsColumnKeys = (
  rows,
  columnKey
) => {
  return rows.reduce((rowsColumnKeys, row) => {
    if (typeof columnKey === 'undefined' || typeof row === 'undefined' || typeof row[columnKey] === 'undefined') return rowsColumnKeys;

    return {
      ...rowsColumnKeys,
      [row[columnKey]]: row
    }
  }, {});
};

export default {
  getMultipleSelectedRows,
  getSelectedRowsIndex,
  getSelectedRows,
  getRowsColumnKeys
};