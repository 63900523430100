function setItems(state, action) {
	state.loading = false;
	state.items = action.items.length > 0 ? action.items : [];
  state.selectedItem = action.selectedItem;
	if (action.changeHistory) {
		state.history = state.history.slice(0, state.currnetHistoryIndex + 1);
		state.history = state.history.concat([action.cursor]);
		state.currnetHistoryIndex = state.history.length - 1;
	}
	return state;
}

function setHistoryIndex(state, action) {
	state.loading = true;
	state.currnetHistoryIndex = action.currentHistoryIndex;
  state.selectedItem = action.selectedItem;
	return state;
}

function changeViewStyle(state, action) {
	state.viewStyle = action.viewStyle;
	return state;
}

function showLoader(state, action) {
	state.loading = !state.loading;
	return state;
}

function setSelectedItem(state, action) {
	state.selectedItem = action.selectedItem;
	return state;
}

module.exports = function (state, action) {
  switch (action.type) {
    case 'SET_ITEMES':
      return setItems(state, action);
    case 'SET_HISTORY_INDEX':
      return setHistoryIndex(state, action);
    case 'CHANGE_VIWESTYLE':
      return changeViewStyle(state, action);
    case 'SHOW_LOADER':
      return showLoader(state, action);
    case 'SET_SELECTED_ITEM':
      return setSelectedItem(state, action);
    default:
      return state;
  }
};