import React from 'react';
import PropTypes from 'prop-types';
import colorService from 'core/services/colorService';
import { getMainStatus } from 'utilities/statuses';

export default class CircleSeparation extends React.Component {
  static defaultProps = {
    separation: undefined,
    model: {}
  };

  getSeparation = () => {
    return this.props.separation;
  };

  getSeparationProgress = (separation) => {
    const status = getMainStatus(separation);
    return typeof status?.progress !== 'undefined' ? status.progress : -1;
  };

  getSeparationVersion = (separation) => {
    return typeof separation !== 'undefined' && typeof separation.separationContent !== 'undefined' && typeof separation.separationContent.externalVersion !== 'undefined' ?
      separation.separationContent.externalVersion :
      undefined;
  };

  getSeparationStyle = (rgb) => {
    return {
      border: 'solid 2px ' + rgb,
      borderRadius: '50%',
      width: '6px',
      height: '6px',
      marginBottom: '3px'
    };
  };

  getSeparationContentWidth = () => {
    const { model: { relatedPages }, separation } = this.props;
    const max = relatedPages.filter(page => page.separations.some(sep => sep.name === separation.name)).length;
    let existingSepCount = 0;
    relatedPages.forEach(page => {
      const relatedPageSep = page.separations.find(sep => sep.name === separation.name);
      if (relatedPageSep) {
        const relatedPageSepProgress = this.getSeparationProgress(relatedPageSep);
        const relatedPageSepVersion = this.getSeparationVersion(relatedPageSep);
        if (!((relatedPageSepProgress === -1 || relatedPageSepProgress === '-1') && relatedPageSepVersion === 0)) {
          existingSepCount++;
        }
      }
    });
    return existingSepCount === max ? '100%' : '0%';
  };

  getSeparationContentStyle = (progress, version, rgb) => {
    const { model: { aggregated } } = this.props;
    const width = aggregated ? this.getSeparationContentWidth() : '100%';
    return {
      borderRadius: '50%',
      width,
      height: '100%',
      backgroundColor: (progress === -1 || progress === '-1') && version === 0 ? '#eeeeee' : rgb
    };
  };

  render() {
    var separation = this.getSeparation();

    if (typeof separation === 'undefined') {
      return undefined;
    }

    var separationName = separation.name,
      separationColor = colorService.getColorByName(separationName),
      separationRGB = colorService.getUIColorRGB(separationColor),
      separationVersion = this.getSeparationVersion(separation),
      separationProgress = this.getSeparationProgress(separation),
      separationStyle = this.getSeparationStyle(separationRGB),
      separationContentStyle = this.getSeparationContentStyle(separationProgress, separationVersion, separationRGB);

    return <div key={separation.id} className={'Separation Separation-' + separationName} style={separationStyle}>
      <div className="Separation-Content" style={separationContentStyle}>
        {this.props.children}
      </div>
    </div>;
  }
}

CircleSeparation.propTypes = {
  separation: PropTypes.object,
  model: PropTypes.object,
  children: PropTypes.node,
};