/**
 * @name storeCreator
 * @file Creates Redux store
 *
 * @author Boris
 * @since: 2022-02-14
 */

import {createStore, applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk';
import setupReducers from "../redux/reducers";

export const createReduxStore = (editor) => {
  const reducers = setupReducers(editor);

  const reduxStore = createStore(reducers.elementsReducer, null, applyMiddleware(ReduxThunk));

  return reduxStore;
};
