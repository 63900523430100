
import React from 'react';
import { createRoot } from 'react-dom/client';
import jsutils from 'base/jsUtils';
import FlowStepOverviewComponent from './components/FlowStepOverviewComponent';
import TickableModel from '../TickableModel';
import TitleComponent from './components/TitleComponent';
import AbstractModule from 'AbstractModule';
import { createObjectComparator } from 'core/comparators';
import StatusComponent from './components/StatusComponent';

const THROTTLE_WAIT = 1000;
const EMPTY_EVENT = {
  progress: 0,
  empty: true
};

const FLOW_STEP_SKIPPABLE_TYPES = [
  'workflow/step/flow/approval',
  'workflow/step/image/preflight',
  'workflow/step/image/optimization',
  'workflow/step/output/cache'
];

const getResourceStatus = (resource) => {
  const { isOn, led } = resource;
  let status = 'off';

  if (isOn === true && led === true) {
    status = 'on';
  }
  if (isOn === true && led === false) {
    status = 'error';
  }
  return status;
};

const hasResourceWithError = (resources) => {
  return resources.some(resource => {
    return getResourceStatus(resource) === 'error';
  });
};

const getQueue = (queues, name) => {
  return queues.filter((q) => {
    return (q.queueType === name);
  })[0];
};

const getFinished = (model) => {
  return getQueue(model.queues, 'queFinished').size;
};

const getWait = (model) => {
  return getQueue(model.queues, 'queWait').size;
};

const getHold = (model) => {
  return getQueue(model.queues, 'queHold').size;
};

const getError = (model) => {
  return getQueue(model.queues, 'queError').size;
};

const isSkippableStep = (model) => {
  const { type } = model;
  return FLOW_STEP_SKIPPABLE_TYPES.includes(type);

};

const getResources = (model) => {

  model.resources.map((resource) => {
    const maxEvents = resource.maxInProgressEvents;
    const { isProgressable } = resource;

    if (resource.eventsInfo && resource.eventsInfo.length !== maxEvents) {
      resource.eventsInfo = [];
    }

    for (let i = 0; i < maxEvents; i++) {
      if (!resource.eventsInfo) {
        resource.eventsInfo = [];
      }
      if (!resource.eventsInfo[i]) {
        resource.eventsInfo[i] = EMPTY_EVENT;
      }
    }

    resource.eventsInfo.forEach((event, index) => {
      const foundInProcessEvents = resource.inProcessEvents.find(e => e.id === event.id);

      if (foundInProcessEvents) {
        resource.eventsInfo[index] = foundInProcessEvents;
      } else if (event.empty !== true) {

        const updated = isProgressable ? {
          ...resource.eventsInfo[index],
          progress: 100,
          finished: true
        } : EMPTY_EVENT;
        resource.eventsInfo[index] = updated;
      }
    });

    resource.inProcessEvents.forEach((event) => {
      const indexInSlot = resource.eventsInfo.findIndex(e => e.id === event.id);

      if (indexInSlot >= 0) {
        resource.eventsInfo[indexInSlot] = event;
      } else {
        const indexEmptyInSlot = resource.eventsInfo.findIndex(e => !e || e.empty);

        if (indexEmptyInSlot >= 0) {
          resource.eventsInfo[indexEmptyInSlot] = event;
        }
      }
    });

    return resource;
  });

  return model.resources;
};

const cleanupProgress = (model) => {

  let finished = 0;

  model.resources.forEach(r => {
    if (!r.isProgressable) {
      return false;
    }

    r.eventsInfo.forEach((e, index) => {
      if (e.finished) {
        r.eventsInfo[index] = EMPTY_EVENT;
        finished++;
      }
    });

  });


  return finished > 0;
};

module.exports = AbstractModule.extend({

  initDone: function () {
    this.domElement.classList.add('crtx-flowstep-overview');
    const title = this.domElement.querySelector('.item-title');
    const content = this.domElement.querySelector('.item-content');
    const status = this.domElement.querySelector('.item-statusbar');

    this.reactRootTitle = createRoot(title);
    this.reactRootContent = createRoot(content);
    this.reactRootStatus = createRoot(status);

    this.updates = [];
    this.tickUpdateHandlerThrottled = jsutils.throttle(this.tickUpdateHandler, THROTTLE_WAIT, {
      leading: false,
      trailing: true
    });

  },

  firstTickReceived: function (data) {
    let model = data.model;

    this.viewLinks = model.viewLinks;
    this.tickableModel = new TickableModel();
    this.tickableModel.firstTickHandler(model);
    this.buildViewModel();
  },

  buildViewModel: function () {
    const model = this.tickableModel.model();

    this.viewModel = {
      ...model,
      finished: getFinished(model),
      wait: getWait(model),
      error: getError(model),
      hold: getHold(model),
      isSkippableStep: isSkippableStep(model),
      resources: getResources(model).sort(createObjectComparator('resourceName')),
      hasResourceWithError: hasResourceWithError(model.resources)
    };

    this.render();
    setTimeout(() => {
      if (cleanupProgress(this.viewModel)) {
        this.render();
      }
    }, 500);
  },

  tickUpdate: function (data) {
    this.updates = this.updates.concat(data.model);
    this.tickUpdateHandlerThrottled();
  },

  tickUpdateHandler: function () {
    this.tickableModel.tickUpdateHandler(this.updates);
    this.updates = [];
    this.buildViewModel();
  },

  updateUploadTabTitle: function (fileCount) {
    this.tab.setState({
      itemCount: fileCount
    });
  },

  destroy: function () {
    this._super();
    this.reactRootTitle.unmount();
    this.reactRootContent.unmount();
    this.reactRootStatus.unmount();
  },

  render: function () {

    if (this.viewModel.hasResourceWithError) {
      this.domElement.classList.add('error-overview');
    } else {
      this.domElement.classList.remove('error-overview');
    }

    this.reactRootTitle.render(<TitleComponent module={this} model={this.viewModel}></TitleComponent>);

    this.reactRootContent.render(
      <FlowStepOverviewComponent
        module={this}
        model={this.viewModel}
      />);

    this.reactRootStatus.render(
      <StatusComponent
        module={this}
        model={this.viewModel}
      />);
  }
});
