import React, {Component} from 'react';
import sandbox from 'sandbox';
import TextInput from 'components/common/inputs/TextInput';
import PropTypes from 'prop-types'

const translate = sandbox.localization.translate;

export default class extends Component {

  static propTypes = {
    store: PropTypes.any
  };

  handleUserNameChange = (event, newValue) => {
    this.props.store.dispatch({
      type: 'UPDATE_USER_NAME',
      value: newValue
    });
  };

  render() {
    return <TextInput
      id="username"
      name="username"
      value={this.props.store.getState().userName}
      onChange={this.handleUserNameChange}
      placeholder={translate("Enter Username")}
      autoFocus={true}
    />;
  }
}

