/**
 * @name ZoneTxTableView
 * @file ZoneTxTableView module
 *
 * @author Boris
 * @since: 2019-12-10
 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import sandbox, { jsUtils } from 'sandbox';
import AbstractModule from 'AbstractModule';
import TickableModel from '../TickableModel';
import {
  createObjectComparator,
  composeComparators,
} from 'core/comparators';
import ZoneTxTable from './ZoneTxTable';
import { makeTableColumns, getDefaultColumnsToSortBy } from './columnsCreator';
import { arrayToObject, moveItem } from 'utilities/array';
import {
  FILTER_TYPE,
  FILTER_DATA_TYPE,
  reduceColumnsToSortBy,
  reduceColumnsToFilterBy,
  checkDateCondition,
  checkTextCondition,
  checkNumberCondition,
  extractColumnPreferences,
  extractColumnSortPreferences,
  applyColumnSortPreferences
} from 'widgets/ReactDataGrid/utils';
import { getCounters, applyToolbarButtonsStatusFromColumnFilters } from './utils';
import { translate } from 'core/services/localization';
import {
  THROTTLE_WAIT,
  TOOLBAR_BUTTONS_TO_COLUMN_FILTER_VALUES_MAP,
  TOOLBAR_BUTTONS_TO_COLUMN_KEYS_MAP,
  VIEW_MODEL_LABELS
} from './constants';
import pubsub from 'core/managers/pubsub';
import toastService from 'core/services/toastService';

function areSitesChanged(sites, prevSites) {
  let changed = sites.length !== prevSites.length;
  if (!changed) {
    for (let i = 0; i < sites.length; i++) {
      if (sites[i].nwid !== prevSites[i].nwid) {
        changed = true;
        break;
      }
    }
  }

  return changed;
}

const getRowsByPhysicalNumber = rows => rows.reduce((acc, row) => {
  if (acc[row.physicalNumber]) {
    acc[row.physicalNumber].push(row);
  } else {
    acc[row.physicalNumber] = [row];
  }

  return acc;
}, {});

module.exports = AbstractModule.extend({
  init: function () {
    this._super();
    this.render = this.render.bind(this);
  },

  initDone: function () {
    this.toolbar = this.createToolbar();
    this.counters = {
      finished: 0,
      rejected: 0,
      error: 0,
      waitingForApproval: 0,
      hold: 0,
      total: 0,
    };

    this.updates = [];
    this.tickUpdateHandlerThrottled = jsUtils.throttle(this.tickUpdateHandler, THROTTLE_WAIT, {
      leading: false,
      trailing: true
    });
    this.reactRoot = createRoot(this.domElement);
  },

  firstTickReceived: function (data) {
    this.preferences = data.preferences || {};
    this.preferences.filtersEnabled = this.preferences.filtersEnabled || false;

    this.rowType = data.model.rowType;
    this.initToolbar(data);

    this.tickableModel = new TickableModel({});
    this.tickableModel.firstTickHandler(data.model);
    this.showFooter(data.model);

    this.tableKey = 1;
    this.tableColumns = [];

    this.buildViewModel();
  },

  tickUpdate: function (data) {
    this.updates = this.updates.concat(data.model);
    this.tickUpdateHandlerThrottled();
    this.showFooter(data.model[0]);
  },

  tickUpdateHandler: function () {
    this.tickableModel.tickUpdateHandler(this.updates);
    this.updates = [];
    this.buildViewModel();
  },

  initToolbar: function (data) {
    this.toolbar.addItem({
      label: translate('Toggle Filters'),
      name: 'toggleFilters',
      _isApplicable: true,
      icon: 'filter_list.svg',
      itemType: 'push',
      checked: this.preferences.filtersEnabled,
      execute: this.handleToggleFilters.bind(this)
    });
    if (data.model.type !== 'edition') {
      this.toolbar.addItem({
        label: translate('Local Pages'),
        name: 'localPages',
        checked: false,
        _isApplicable: true,
        icon: 'local_pages',
        tooltip: translate('Show Local Pages Only'),
        itemType: 'push',
        execute: this.handleToolbarButtonsClick.bind(this)
      });
    }
    this.toolbar.addItem({
      label: translate('Missing Pages'),
      name: 'missingPages',
      checked: false,
      _isApplicable: true,
      icon: 'missing_pages',
      tooltip: translate('Show Missing Pages'),
      itemType: 'push',
      groupName: 'pageState',
      execute: this.handleToolbarButtonsClick.bind(this)
    });
    this.toolbar.addItem({
      label: translate('Error Pages'),
      name: 'errorPages',
      checked: false,
      _isApplicable: true,
      icon: 'error_pages',
      tooltip: translate('Show Error Pages'),
      itemType: 'push',
      groupName: 'pageState',
      execute: this.handleToolbarButtonsClick.bind(this)
    });
    this.toolbar.addItem({
      label: translate('Waiting For Approval'),
      name: 'waitingForApprovalPages',
      checked: false,
      _isApplicable: true,
      icon: 'waiting_for_approval',
      tooltip: translate('Show Waiting For Approval'),
      itemType: 'push',
      groupName: 'pageState',
      execute: this.handleToolbarButtonsClick.bind(this)
    });
    if (this.rowType === 'form' || this.rowType === 'form/separation') {
      this.toolbar.addItem({
        label: translate('Expected'),
        name: 'expectedPlatesPages',
        checked: false,
        _isApplicable: true,
        icon: 'actual_plates',
        tooltip: translate('Show Formes With Expected Plates Only'),
        itemType: 'push',
        execute: this.handleToolbarButtonsClick.bind(this)
      });
    }
  },

  showFooter: function (model) {
    const { planImportFileName, planImportTime } = model;
    if (planImportFileName && planImportTime) {
      const footer = this.createFooter();

      footer.addPlanImportInfo(planImportFileName, planImportTime);
    }
  },

  handleToolbarButtonsClick: function (checked, buttonName) {

    if (checked) {
      this.toolbar.setItemChecked('toggleFilters', checked);
      this.preferences.filtersEnabled = checked;
    }

    const column = this.tableColumns.find(col => col.key === TOOLBAR_BUTTONS_TO_COLUMN_KEYS_MAP[buttonName]);
    if (checked) {
      column.filter.selected = [TOOLBAR_BUTTONS_TO_COLUMN_FILTER_VALUES_MAP[buttonName]];

    } else {
      column.filter.selected = [];
    }

    this.filterRows();

    const columns = extractColumnPreferences(this.tableColumns);

    this.savePreferences({ filtersEnabled: this.preferences.filtersEnabled, columns });
  },

  handleToggleFilters: function (checked) {

    this.savePreferences({ filtersEnabled: checked });

    this.buildViewModel();
  },

  savePreferences: function (preferences) {
    if (!preferences) {
      return;
    }

    this.preferences = Object.assign(this.preferences, preferences);
    sandbox.preferences.savePreferences(this.getRequiredParameters(), this.preferences);
  },

  saveColumnPreferences: function () {
    const columns = extractColumnPreferences(this.tableColumns);
    this.savePreferences({
      columns
    });
  },

  buildViewModel: function () {
    const model = this.tickableModel.model();

    const rows = this.buildRows(model);
    const siteMap = this.buildSiteMap(rows);
    const sites = Object.values(siteMap).sort(createObjectComparator('name'));
    const prevSites = (this.viewModel || {}).sites || [];

    this.viewModel = {
      type: model.type,
      rowType: model.rowType,
      rows,
      sites,
      siteMap,
    };

    if (areSitesChanged(sites, prevSites)) {
      this.tableKey++;
      console.log('=== ZoneTxTableView.buildViewModel() => List of sites has beed changed - re-render table!');
    }

    this.viewModel.totalRows = this.viewModel.rows;
    this.viewModel.rowsByPhysicalNumber = getRowsByPhysicalNumber(this.viewModel.rows);

    this.tableColumns = makeTableColumns(this.viewModel, this.preferences);

    if (!Array.isArray(this.preferences.columnsToSortBy) || this.preferences.columnsToSortBy.length <= 0) {
      this.preferences.columnsToSortBy = getDefaultColumnsToSortBy(this.viewModel);
    }

    this.viewModel.columnsToSortBy = applyColumnSortPreferences(this.tableColumns, this.preferences.columnsToSortBy);

    this.sortRows();
    this.filterRows();

    this.updateSelectedRows();
  },

  buildSiteMap: function (rows) {
    const siteMap = rows.reduce((acc, row) => {
      const sites = Object.values(row.transmissionStatuses || {});
      for (const site of sites) {
        const { name, code, siteNwid: nwid } = site;
        if (!acc[nwid]) {
          acc[nwid] = { name, code, nwid };
        }
      }

      return acc;
    }, {});

    return siteMap;
  },

  buildRows: function (model) {
    let rows = [];

    if (model.type === 'zone') {
      rows = this.buildZonePages(model);
    } else if (model.type === 'edition') {
      switch (model.rowType) {
        case 'page':
          rows = this.buildEditionPages(model);
          break;
        case 'page/separation':
          rows = this.buildEditionPageSeparations(model);
          break;
        case 'form':
          rows = this.buildEditionForms(model);
          break;
        case 'form/separation':
          rows = this.buildEditionFormSeparations(model);
          break;
      }
    }

    return rows;
  },

  buildZonePages: function (model) {
    return model.sections.reduce((acc, section) => {
      section.pages.reduce((acc2, page) => {
        if (!page.virtual) {
          acc2.push({
            ...page,
            section: section.name,
            content: { ...page.pageContent },
          });
        }

        return acc2;
      }, acc);

      return acc;
    }, []);
  },

  buildEditionPages: function (model) {
    const editionNameLower = model.name.toLowerCase();

    return model.publication.pageContents.reduce((acc, content) => {
      const page = content.significantPage;
      if (page && !page.virtual && page.edition.toLowerCase() === editionNameLower) {
        acc.push({
          ...page,
          section: page.section.sectionName,
          content: this.getContentInfo(content),
          ...this.getContentProps(content),
        });
      }

      return acc;
    }, []);
  },

  buildEditionPageSeparations: function (model) {
    const editionNameLower = model.name.toLowerCase();

    return model.publication.pageSeparationContents.reduce((acc, content) => {
      const separation = content.significantPageSeparation;
      if (separation && !separation.virtual && separation.edition.toLowerCase() === editionNameLower) {
        acc.push({
          ...separation,
          pageName: separation.page.name,
          section: separation.page.section.sectionName,
          physicalNumber: separation.page.physicalNumber,
          content: this.getContentInfo(content),
          ...this.getContentProps(content),
        });
      }

      return acc;
    }, []);
  },

  buildEditionForms: function (model) {
    const editionNameLower = model.name.toLowerCase();

    return model.publication.formContents.reduce((acc, content) => {
      const form = content.significantForm;
      if (form && !form.virtual && form.edition.toLowerCase() === editionNameLower) {
        acc.push({
          ...form,
          book: form.book.bookName,
          content: this.getContentInfo(content),
          ...this.getContentProps(content),
        });
      }

      return acc;
    }, []);
  },

  buildEditionFormSeparations: function (model) {
    const editionNameLower = model.name.toLowerCase();

    return model.publication.formSeparationContents.reduce((acc, content) => {
      const separation = content.significantFormSeparation;
      if (separation && !separation.virtual && separation.edition.toLowerCase() === editionNameLower) {
        acc.push({
          ...separation,
          book: separation.form.book.bookName,
          physicalNumber: separation.form.physicalNumber,
          content: this.getContentInfo(content),
          ...this.getContentProps(content),
        });
      }

      return acc;
    }, []);
  },

  // Returns content properties that used in actions via the dot notation, e.g. content.isSetComplete
  getContentInfo: function (content) {
    const {
      nwid, type, externalVersion, contentVersionMajorIndex, mainVersionInputFileName, isSetComplete,
      isPreApproved, versionNwid, preflightReportInfo
    } = content || {};

    return {
      nwid,
      type,
      externalVersion,
      contentVersionMajorIndex,
      mainVersionInputFileName,
      isSetComplete,
      isPreApproved,
      versionNwid,
      preflightReportInfo
    };
  },

  // Returns content properties that should be copied to the structured element (page, form, separation)
  getContentProps: function (content) {
    const { defaultStatus, transmissionStatuses, holdSites, aggregatedHoldType } = content || {};
    return {
      defaultStatus,
      transmissionStatuses,
      holdSites,
      extraInfo: { significant: true },
      aggregatedHoldType
    };
  },

  sortRows: function () {
    // const t1 = performance.now(); //***TEST

    this.viewModel.sortedRows = this.viewModel.sortedRows || this.viewModel.rows;
    if (this.viewModel.columnsToSortBy.length > 0) {
      const comparator = composeComparators(this.viewModel.columnsToSortBy.map(col =>
        createObjectComparator(col.sortValueGetter || col.key, col.sortType, col.ascending)
      ));

      this.viewModel.sortedRows = this.viewModel.sortedRows.sort(comparator);
    }

    //***TEST BEGIN
    // const t2 = performance.now();
    // console.log('### ZoneTxTableView.sortRows() time = ', t2 - t1, 'ms');
    //***TEST END
  },

  filterRows: function () {

    this.toolbar.items.forEach(item => {
      if (typeof TOOLBAR_BUTTONS_TO_COLUMN_KEYS_MAP[item.name] !== 'undefined') {
        item.checked = false;
      } else if (item.name === 'toggleFilters') {
        item.checked = this.preferences.filtersEnabled;
      };
    });

    if (!this.preferences.filtersEnabled) {
      this.viewModel.rows = this.viewModel.sortedRows;
      this.counters = getCounters(this.viewModel.rows);
    } else {
      //***TEST BEGIN
      // const t1 = performance.now();
      //***TEST END

      const columnsToFilterBy = reduceColumnsToFilterBy(this.tableColumns);

      applyToolbarButtonsStatusFromColumnFilters(this, columnsToFilterBy);

      this.viewModel.rows = this.viewModel.sortedRows.filter(row => {
        let match = true;

        for (const col of columnsToFilterBy) {
          const filter = col.filter;
          if (filter.type === FILTER_TYPE.MULTISELECT) {
            if (filter.selected && filter.selected.length > 0) {
              const filterValue = col.filterValueGetter(row);
              match = filter.selected.some(s => s === filterValue);
            }
          } else if (filter.type === FILTER_TYPE.DATE) {
            match = checkDateCondition(row[col.key], filter);
          } else if (filter.type === FILTER_TYPE.TEXT) {
            if (filter.dataType === FILTER_DATA_TYPE.TEXT) {
              match = checkTextCondition(row[col.key], filter);
            } else if (filter.dataType === FILTER_DATA_TYPE.NUMBER) {
              match = checkNumberCondition(row[col.key], filter);
            }
          }

          if (!match) {
            break;
          }
        }

        return match;
      });

      let totalRows = [];

      if (this.toolbar.items.find(item => item.name === 'expectedPlatesPages')?.checked || this.toolbar.items.find(item => item.name === 'localPages')?.checked) {
        totalRows = this.viewModel.rows;
      } else {
        totalRows = this.viewModel.totalRows;
      }

      this.counters = { ...this.counters, ...getCounters(totalRows) };

      //***TEST BEGIN
      // const t2 = performance.now();
      // console.log('### FlowStepView.filterRows() time = ', t2 - t1, 'ms');
      //***TEST END
    }

    setTimeout(() => {
      // const t1 = performance.now(); //***TEST

      this.render();

      //***TEST BEGIN
      // const t2 = performance.now();
      // console.log('### ZoneTxTableView.render() time = ', t2 - t1, 'ms');
      //***TEST END
    }, 0);
  },

  updateSelectedRows: function () {
    const rowsByNwid = arrayToObject(this.viewModel.rows, 'nwid');

    const selectedRows = this.selected.reduce((acc, row) => {
      const updatedRow = rowsByNwid[row.nwid];
      if (updatedRow) {
        acc.push(updatedRow);
      }

      return acc;
    }, []);

    this.updateSelected(selectedRows);
  },

  handleTableSelect: function (selectedRows) {
    this.updateSelected(selectedRows);
  },

  handleRowContextMenu: function (clickedRow, selectedRows, e) {
    this.showContextMenu(clickedRow, this.selected, e);
  },

  handleColumnResize: function (columns, columnKey) {
    this.tableColumns.forEach(col => {
      if (columns[col.key]) {
        col.width = columns[col.key].width;
      }
    });

    this.saveColumnPreferences();
  },

  handleColumnFilterChange: function (column, columnFilter) {
    //console.log('### handleColumnFilterChange()', column, column);
    if (!column || !column.filter || !column.filter.type) {
      return;
    }

    column.filter = {
      ...column.filter,
      ...columnFilter
    };

    this.filterRows();

    this.saveColumnPreferences();
  },

  handleColumnsFilter: function (columns) {
    this.tableColumns.forEach(col => {
      if (columns[col.key]) {
        col.visible = columns[col.key].visible;
      }
    });

    this.saveColumnPreferences();
  },

  handleColumnsOrder: function (columns, oldIndex, newIndex) {
    moveItem(this.tableColumns, oldIndex, newIndex);

    this.saveColumnPreferences();

    this.render();
  },

  handleTableSort: function (columnKey, multiSort) {
    this.viewModel.columnsToSortBy = reduceColumnsToSortBy(this.tableColumns, this.viewModel.columnsToSortBy, columnKey, multiSort);

    this.savePreferences({
      columnsToSortBy: extractColumnSortPreferences(this.viewModel.columnsToSortBy)
    });


    this.sortRows();
    this.filterRows();
  },

  handleTableDoubleClick: function (row) {
    this.navigateByViewLink(row);
  },

  handleTableDrop: function (rowIndex, rowContent, e) {
    const unplannedPages = JSON.parse(e.dataTransfer.getData('Text'));
    let actionDefinitionName = '';
    let message = '';
    if (unplannedPages[0].uploaded) {
      actionDefinitionName = 'MapUploadPageActionCR';
      message = translate('You do not have permission to upload pages');
    } else {
      actionDefinitionName = 'MapUnplannedPageActionCR';
      message = translate('You do not have permission to drop unplanned pages');
    }
    const action = this.viewActions.find(a => a.actionDefinitionName === actionDefinitionName);
    if (action) {
      pubsub.publish('drop-on-page', { unplannedPages, success: true });
      action.execute(rowContent, unplannedPages).then(result => {
        if (!result.data.success) {
          pubsub.publish('drop-on-page', { unplannedPages, success: false });
          toastService.errorToast(result.data.subject, result.data.message);
        }
      });
    } else {
      toastService.errorToast('', message);
    }
  },

  handleTableViewKeyDown: function (e) {
    if (this.selected.length <= 0 || this.viewModel.rowType !== 'page') {
      return;
    }

    if ((e.ctrlKey || e.metaKey) && e.code === 'KeyC') { // CTRL + C
      const actions = this.getRelevantActions(this.selected[0]);
      const copyAction = actions.find(a => a.actionDefinitionName === 'CopyActionCR');
      if (copyAction && copyAction.isApplicable(this.selected)) {
        copyAction.execute(this.selected);
      }
    } else if ((e.ctrlKey || e.metaKey) && e.code === 'KeyV') { // CTRL + V
      const actions = this.getRelevantActions(this.selected[0]);
      const copyAction = actions.find(a => a.actionDefinitionName === 'PasteActionCR');
      if (copyAction && copyAction.isApplicable(this.selected)) {
        copyAction.execute(this.selected);
      }
    }
  },

  render: function () {
    this.reactRoot.render(
      <ZoneTxTable
        module={this}
        viewModel={this.viewModel}
        filtersEnabled={this.preferences.filtersEnabled}
        tableColumns={this.tableColumns}
        tableKey={this.tableKey}
        tableName={VIEW_MODEL_LABELS[this.viewModel.rowType]}
      />
    );
  }

});