const addToast = (state, action) => {
  let toast = action.toast;
  let container = action.container;
  let toasts = state.containers[container].toasts;
  if (action.direction === 'up') {    
    toasts = toasts.concat(toast); 
  }
  else if (action.direction === 'down') {
    toasts = [].concat(toast).concat(toasts);
  }

  return {
    ...state,
    containers: {
      ...state.containers,
      [container]: {
        ...state.containers[container],
        toasts
      }
    }
  };
};

const updateToast = (state, action) => {
  let toastId = action.id;
  let container = action.container;
  let toastIndex = state.containers[container].toasts.findIndex((t) => {
    return t.id === toastId;
  });
  if (toastIndex === -1) return state;

  let toasts = [
    ...state.containers[container].toasts.slice(0, toastIndex),
    Object.assign({}, state.containers[container].toasts[toastIndex], action.toast),
    ...state.containers[container].toasts.slice(toastIndex + 1)
  ];

  return {
    ...state,
    containers: {
      ...state.containers,
      [container]: {
        ...state.containers[action.container],
        toasts: [
          ...toasts
        ]
      }
    }
  };
};

const hideToast = (state, action) => {
  let toastId = action.id;
  let container = action.container;
  let toastIndex = state.containers[container].toasts.findIndex((t) => {
    return t.id === toastId;
  });
  if (toastIndex === -1) return state;

  let toasts = [
    ...state.containers[container].toasts.slice(0, toastIndex),
    Object.assign({}, state.containers[container].toasts[toastIndex], {visible: false}),
    ...state.containers[container].toasts.slice(toastIndex + 1)
  ];

  return {
    ...state,
    containers: {
      ...state.containers,
      [container]: {
        ...state.containers[action.container],
        toasts: [
          ...toasts
        ]
      }
    }
  };
};

const removeToast = (state, action) => {
  let toastId = action.id;
  let container = action.container;
  let toastIndex = state.containers[container].toasts.findIndex((t) => {
    return t.id === toastId;
  });
  let toasts = [
    ...state.containers[container].toasts.slice(0, toastIndex),
    ...state.containers[container].toasts.slice(toastIndex + 1)
  ];

  return {
    ...state,
    containers: {
      ...state.containers,
      [container]: {
        ...state.containers[action.container],
        toasts: [
          ...toasts
        ]
      }
    }
  };
};

module.exports = function reducer (state = {}, action = {}) {

  switch (action.type) {

    case 'ADD_TOAST': 
      return addToast(state, action);
    case 'UPDATE_TOAST': 
      return updateToast(state, action);
    case 'HIDE_TOAST': 
      return hideToast(state, action);
    case 'REMOVE_TOAST': 
      return removeToast(state, action);
    default: 
      return state;

  };

};