import React from 'react';
import PropTypes from 'prop-types';
import iconService from 'core/services/iconService';

const isUndefined = o => typeof o === 'undefined';

var DIFF_STATUS_TYPES_ICONS_SUCCESS = {
  'success': { moduleName: 'MyBirdeye', icon: 'success' },
  'waiting': { moduleName: 'MyBirdeye', icon: 'wait' },
  'hold': { moduleName: 'MyBirdeye', icon: 'hold' },
  'error': { moduleName: 'Thumbnail', icon: 'reject-small' }
};

export default class SeparationStatusType extends React.Component {
  static defaultProps = {
    status: undefined,
    definition: undefined,
    separation: undefined,
    approvalIndication: undefined
  };

  getStyle = () => {
    return {
      width: 12,
      height: 12
    };
  };

  getStatusTypeIcon = (flowStep, flowStepIconName, statusType) => {
    var statusTypeLowerCase = typeof statusType !== 'undefined' ? statusType.toLowerCase().trim() : undefined;

    if (!flowStep || typeof statusType === 'undefined') {
      return undefined;
    }

    if (typeof DIFF_STATUS_TYPES_ICONS_SUCCESS[statusTypeLowerCase] !== 'undefined') {
      return iconService.getModuleIcon(DIFF_STATUS_TYPES_ICONS_SUCCESS[statusTypeLowerCase].moduleName, DIFF_STATUS_TYPES_ICONS_SUCCESS[statusTypeLowerCase].icon);
    }

    return undefined;
  };

  getSingleStatusTypeIcon = (status) => {
    const statusTypeIcon = this.getStatusTypeIcon(status.flowStepType, status.flowStepIconName, status.statusType);

    return [statusTypeIcon];
  };

  getApprovalStatusTypeIcon = separation => indicaion => {
    const externalVersion = separation.separationContent.externalVersion;
    const approvalCompleteVersion = separation.approvalCompleteVersion || '0';
    const approvalIndicationComplete = approvalCompleteVersion === externalVersion ? separation.approvalIndicationComplete || '' : '';
    const approvalIndicationReject = approvalCompleteVersion === externalVersion ? separation.approvalIndicationReject || '' : '';
    const isCompletedApprovalIndication = approvalIndicationComplete.indexOf(indicaion) >= 0;
    const isRejectedApprovalIndication = approvalIndicationReject.indexOf(indicaion) >= 0;

    if (isCompletedApprovalIndication) {
      if (typeof DIFF_STATUS_TYPES_ICONS_SUCCESS.success !== 'undefined') {
        return iconService.getModuleIcon(DIFF_STATUS_TYPES_ICONS_SUCCESS.success.moduleName, DIFF_STATUS_TYPES_ICONS_SUCCESS.success.icon);
      }
    }

    if (isRejectedApprovalIndication) {
      if (typeof DIFF_STATUS_TYPES_ICONS_SUCCESS.error !== 'undefined') {
        return iconService.getModuleIcon(DIFF_STATUS_TYPES_ICONS_SUCCESS.error.moduleName, DIFF_STATUS_TYPES_ICONS_SUCCESS.error.icon);
      }
    }

    return iconService.getModuleIcon(DIFF_STATUS_TYPES_ICONS_SUCCESS.waiting.moduleName, DIFF_STATUS_TYPES_ICONS_SUCCESS.waiting.icon);
  };

  getMultipleApprovalStatusTypeIcons = (approvalIndications) => {
    return approvalIndications.sort().map(this.getApprovalStatusTypeIcon(this.props.separation));
  };

  getStatusTypeIcons = (status) => {
    const approvalIndications = isUndefined(this.props.approvalIndication) ? [] : this.props.approvalIndication.split(',');
    return status.flowStepType === 'workflow/step/flow/approval' && approvalIndications.length > 1 ? this.getMultipleApprovalStatusTypeIcons(approvalIndications) : this.getSingleStatusTypeIcon(status);
  };

  renderStatusTypeIcon = (statusTypeIcon, index) => {
    return <img key={index} src={statusTypeIcon} />;
  };

  render() {
    const { status } = this.props;
    const statusTypeIcons = this.getStatusTypeIcons(status);
    const style = statusTypeIcons.length > 1 ? { width: 26, marginLeft: 0 } : undefined;
    // statusTypeIcon = this.getStatusTypeIcon(status.flowStepType, status.flowStepIconName, status.statusType),
    // style = this.getStyle();
    return <div className="separation-item-status-type" style={style}>
      {statusTypeIcons.map(this.renderStatusTypeIcon)}
    </div>;
  }
}

SeparationStatusType.propTypes = {
  approvalIndication: PropTypes.string,
  definition: PropTypes.object,
  separation: PropTypes.object,
  status: PropTypes.object,
};