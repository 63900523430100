/**
 * Created by elad on 8/11/2015.
 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import AbstractModule from 'AbstractModule';
import { savePreferences } from 'core/managers/preferences';
import jsutils from 'base/jsUtils';
import TickableModel from '../TickableModel';
import { moveItem } from '../../utilities/array';
import { makeCustomLogsTableColumns } from './columnsCreator';
import { extractColumnPreferences } from 'widgets/ReactDataGrid/utils';
import { View } from './View';
import { createObjectComparator, COMPARE_TYPE } from 'core/comparators';
import dialogService from 'core/services/dialogService';
import { translate } from 'core/services/localization';
import toastService from 'core/services/toastService';

const THROTTLE_WAIT = 1000;

const labels = {
  aboutToDelete: translate('You are about to delete the following log:'),
  continue: translate('You will not be able to restore it at a later time! Continue?'),
  deleteLog: translate('Delete Log'),
  deleteLogFailed: translate('Delete Log Failed')
};

export default AbstractModule.extend({
  initDone: function () {
    this.updates = [];
    this.reactRoot = createRoot(this.domElement);
    this.tickUpdateHandlerThrottled = jsutils.throttle(this.tickUpdateHandler, THROTTLE_WAIT, {
      leading: false,
      trailing: true
    });
    this.toolbar = this.createToolbar();
  },

  destroy: function () {
    this._super();
    this.reactRoot.unmount();
  },

  firstTickReceived: function (data) {
    this.preferences = data.preferences || {};

    this.tickableModel = new TickableModel();

    this.tickableModel.firstTickHandler(data.model);

    this.buildViewModel();
  },

  tickUpdate: function (data) {
    this.updates = this.updates.concat(data.model);
    this.tickUpdateHandlerThrottled();
  },

  tickUpdateHandler: function () {
    this.tickableModel.tickUpdateHandler(this.updates);
    this.updates = [];
    this.buildViewModel();
  },

  buildViewModel: function () {
    const model = this.tickableModel.model();
    this.viewModel = model;
    this.viewModel.logs = this.viewModel.logs.sort(createObjectComparator('name', COMPARE_TYPE.CASE_INSENSITIVE));
    this.customLogsTableColumns = makeCustomLogsTableColumns(this.viewModel, this);

    this.render();
  },

  savePreferences: function (preferences) {
    if (!preferences) {
      return;
    }

    this.preferences = Object.assign(this.preferences, preferences);
    savePreferences(this.getRequiredParameters(), this.preferences);
  },

  saveCustomLogsColumnPreferences: function () {
    const customLogsColumns = extractColumnPreferences(this.customLogsTableColumns);
    this.savePreferences({
      table: {
        ...this.preferences.table,
        customLogsColumns
      }
    });
  },

  handleCustomLogsTableContextMenu: function (log, selected, e) {
    this.showContextMenu(log, selected, e);
  },

  handleLogSizeChange: function (logSize) {
    this.viewModel.logSize = logSize;
  },

  handlePathSelect: function (path) {
    this.viewModel.saveAt = path;
  },

  handleCustomLogsTableEdit: function (log) {
    const editAction = this.getRelevantActions(log).find(a => a.actionDefinitionName === 'EditCustomLogActionCR');
    if (editAction) {
      editAction.execute([log]);
    }
  },

  handleSaveCustomLogsCommonSettings: function () {
    const saveAction = this.viewActions.find(a => a.actionDefinitionName === 'SaveCustomLogsSetupCR');
    if (saveAction) {
      saveAction.execute();
    }
  },

  handleCustomLogsTableColumnResize: function (columns) {
    this.customLogsTableColumns.forEach(col => {
      if (columns[col.key]) {
        col.width = columns[col.key].width;
      }
    });

    this.saveCustomLogsColumnPreferences();
  },

  handleCustomLogsTableDeleteButtonClick: function (log) {
    const actions = this.getRelevantActions(log);
    const deleteAction = actions.find(a => a.actionClass === 'DeleteCustomLogAction');
    if (deleteAction) {
      dialogService.openConfirmDialog(`${labels.aboutToDelete} ${log.name}\n${labels.continue}`, labels.deleteLog)
        .then(() => {
          deleteAction.execute({ nwid: log.nwid })
            .catch(err => toastService.errorToast(labels.deleteLogFailed, translate(err.statusText)));
        });
    }
  },

  handleCustomLogsTableSelect: function (selectedRows) {
    const logs = selectedRows.map(row => row && this.tickableModel.getByNwid(row.nwid));
    this.updateSelected(logs.filter(e => !!e));
  },

  handleCustomLogsTableColumnsFilter: function (columns) {
    this.customLogsTableColumns.forEach(col => {
      if (columns[col.key]) {
        col.visible = columns[col.key].visible;
      }
    });

    this.saveCustomLogsColumnPreferences();
  },

  handleCustomLogsTableColumnOrder: function (columns, oldIndex, newIndex) {
    moveItem(this.customLogsTableColumns, oldIndex, newIndex);

    this.saveCustomLogsColumnPreferences();

    this.render();
  },

  render: function () {
    this.reactRoot.render(<View module={this}
      viewModel={this.viewModel}
      customLogsTableColumns={this.customLogsTableColumns}
    />);
  },
});
