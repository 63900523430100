/**
 * Created by moshemal on 9/4/14.
 */

import _cloneDeep from 'lodash/cloneDeep';
import _clone from 'lodash/clone';
import _debounce from 'lodash/debounce';
import _throttle from 'lodash/throttle';
import _merge from 'lodash/merge';
import _reduce from 'lodash/reduce';
import _transform from 'lodash/transform';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _isEqual from 'lodash/isEqual';
import _isPlainObject from 'lodash/isPlainObject';

const FILE_SIZE_SYMBOLS = ["KB", "MB", "GB", "TB"];

/**
 *  equivalent of the C printf() or python format method
 * @param {string} template a string template to be formatted
 * @returns {string} the formatted string
 */
function format(template) {
  let args = Array.prototype.slice.call(arguments, 1);
  if (args.length === 1 && typeof args[0] === 'object') {
    args = args[0]
  }

  return template.replace(/{(\w+)}/g, function (match, number) {
    return typeof args[number] !== 'undefined'
      ? args[number]
      : match
      ;
  });
}

function round(value, decimalPlaces = 0) {
  const multiplier = Math.pow(10, decimalPlaces);
  return Math.round(value * multiplier) / multiplier;
}

function formatFileSize(bytes) {
  if (bytes <= 0) {
    return bytes + FILE_SIZE_SYMBOLS[0];
  }

  var i = -1;
  do {
    bytes = bytes / 1024;
    i++;
  } while (bytes >= 1024);

  const roundedBytes = i === 0 ? Math.ceil(bytes) : round(bytes, 1);
  return `${roundedBytes} ${FILE_SIZE_SYMBOLS[i]}`;
}

/**
 * Set the first char of the string into capital letter
 * @param {string} str
 * @returns {string}
 */
function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Removes everything from the object
 * without removing the object itself
 * @param  {Object} ds Object to be cleaned
 */
function clear(ds) {
  if (this.isObject(ds)) {
    for (var i in ds) {
      if (ds.hasOwnProperty(i)) {
        delete ds[i];
      }
    }
  }
}

/**
 * Filters the given array and returns new array
 * of items which contain property equals to the given value
 * @param {Array}   arr         Given array to filter
 * @param {String}  key         Name of the property
 * @param {String}  value       Value of the property
 * @param {Boolean} [caseSense] Case sensitive or not
 * @return {Array}
 */
function filterProperty(arr, key, value, caseSense) {
  if (caseSense || typeof 'caseSense' === 'undefined') {
    return arr.filter(function (item) {
      if (item[key] === value) {
        return item;
      }
    });
  }
  else {
    return arr.filter(function (item) {
      if (item[key].toLowerCase() === value.toLowerCase()) {
        return item;
      }
    });
  }
}

/**
 * Returns whether the given argument is an array
 */
function isArray(vArg) {
  console.log("deprecated. use Array.isArray")
  if (Array && Array.isArray) {
    return Array.isArray(vArg);
  }
  return Object.prototype.toString.call(vArg) === '[object Array]';
}

/**
 * Returns whether the given argument is a function
 */
function isFunction(vArg) {
  return Object.prototype.toString.call(vArg) === '[object Function]';
}

/**
 * Return whether the given string contains HTML code
 * @param str
 * @returns {*|Array|{index: number, input: string}}
 */
function isHTML(str) {
  return str.match(/<(\w+)((?:\s+\w+(?:\s*=\s*(?:(?:"[^"]*")|(?:'[^']*')|[^>\s]+))?)*)\s*(\/?)>/);
}

/**
 * Return whether the given argument is an object.
 * Note: returns false if the argument is array.
 */
function isObject(vArg) {
  return Object.prototype.toString.call(vArg) === '[object Object]';
}

/**
 * Recursively merges own and inherited enumerable string keyed properties of source objects into the destination object.
 * Source properties that resolve to undefined are skipped if a destination value exists.
 * Array and plain object properties are merged recursively.
 * Other objects and value types are overridden by assignment.
 * Source objects are applied from left to right.
 * Subsequent sources overwrite property assignments of previous sources.
 * @param   {Object}   object   The destination object.
 * @param   {Object[]} sources  The source objects.
 * @return  {Object}            Merged destination object.
 */
function merge(object, ...sources) {
  return _merge(object, ...sources);
}

/**
 * Reduces a collection to a value which is the accumulated result of running each element in the collection
 * through the callback, where each successive callback execution consumes the return value of the previous
 * execution. If accumulator is not provided the first element of the collection will be used as the initial
 * accumulator value. The callback is bound to thisArg and invoked with four arguments;
 * (accumulator, value, index|key, collection).
 * @param collection
 * @param callback
 * @param accumulator
 * @param thisArg
 */
function reduce(collection, callback, accumulator, thisArg) {
  return _reduce(collection, callback, accumulator, thisArg);
}

/**
 *
 * @param {Array|Object}  collection  The collection to iterate over
 * @param {Function}      callback    The function called per iteration
 * @param {*}             acc         The custom accumulator value
 * @param {Object}        thisArg     The this binding of callback
 * @returns {*}
 */
function transform(collection, callback, acc, thisArg) {
  return _transform.apply(null, arguments);
}

function json2xml(o, tab) {
  var toXml = function (v, name, ind) {
    var xml = "";
    if (v instanceof Array) {
      for (var i = 0, n = v.length; i < n; i++)
        xml += ind + toXml(v[i], name, ind + "\t") + "\n";
    }
    else if (typeof (v) == "object") {
      var hasChild = false;
      xml += ind + "<" + name;
      for (var m in v) {
        if (m.charAt(0) === "@") {
          xml += " " + m.substr(1) + "=\"" + v[m].toString() + "\"";
        } else {
          hasChild = true;
        }
      }
      xml += hasChild ? ">" : "/>";
      if (hasChild) {
        for (var m in v) {
          if (m === "#text") {
            xml += v[m];
          } else if (m === "#cdata") {
            xml += "<![CDATA[" + v[m] + "]]>";
          } else if (m.charAt(0) !== "@") {
            xml += toXml(v[m], m, ind + "\t");
          }
        }
        xml += (xml.charAt(xml.length - 1) == "\n" ? ind : "") + "</" + name + ">";
      }
    }
    else {
      xml += ind + "<" + name + ">" + v.toString() + "</" + name + ">";
    }
    return xml;
  }, xml = "";
  for (var m in o)
    xml += toXml(o[m], m, "");
  return tab ? xml.replace(/\t/g, tab) : xml.replace(/\t|\n/g, "");
}

/*	This work is licensed under Creative Commons GNU LGPL License.
 License: http://creativecommons.org/licenses/LGPL/2.1/
 Version: 0.9
 Author:  Stefan Goessner/2006
 Web:     http://goessner.net/
 */
function xml2json(xml, tab) {
  var X = {
    toObj: function (xml) {
      var o = {};
      if (xml.nodeType == 1) {   // element node ..
        if (xml.attributes.length)   // element with attributes  ..
          for (var i = 0; i < xml.attributes.length; i++)
            o["@" + xml.attributes[i].nodeName] = (xml.attributes[i].nodeValue || "").toString();
        if (xml.firstChild) { // element has child nodes ..
          var textChild = 0, cdataChild = 0, hasElementChild = false;
          for (var n = xml.firstChild; n; n = n.nextSibling) {
            if (n.nodeType == 1) hasElementChild = true;
            else if (n.nodeType == 3 && n.nodeValue.match(/[^ \f\n\r\t\v]/)) textChild++; // non-whitespace text
            else if (n.nodeType == 4) cdataChild++; // cdata section node
          }
          if (hasElementChild) {
            if (textChild < 2 && cdataChild < 2) { // structured element with evtl. a single text or/and cdata node ..
              X.removeWhite(xml);
              for (var n = xml.firstChild; n; n = n.nextSibling) {
                if (n.nodeType == 3)  // text node
                  o["#text"] = X.escape(n.nodeValue);
                else if (n.nodeType == 4)  // cdata node
                  o["#cdata"] = X.escape(n.nodeValue);
                else if (o[n.nodeName]) {  // multiple occurence of element ..
                  if (o[n.nodeName] instanceof Array)
                    o[n.nodeName][o[n.nodeName].length] = X.toObj(n);
                  else
                    o[n.nodeName] = [o[n.nodeName], X.toObj(n)];
                }
                else  // first occurence of element..
                  o[n.nodeName] = X.toObj(n);
              }
            }
            else { // mixed content
              if (!xml.attributes.length)
                o = X.escape(X.innerXml(xml));
              else
                o["#text"] = X.escape(X.innerXml(xml));
            }
          }
          else if (textChild) { // pure text
            if (!xml.attributes.length)
              o = X.escape(X.innerXml(xml));
            else
              o["#text"] = X.escape(X.innerXml(xml));
          }
          else if (cdataChild) { // cdata
            if (cdataChild > 1)
              o = X.escape(X.innerXml(xml));
            else
              for (var n = xml.firstChild; n; n = n.nextSibling)
                o["#cdata"] = X.escape(n.nodeValue);
          }
        }
        if (!xml.attributes.length && !xml.firstChild) o = null;
      }
      else if (xml.nodeType == 9) { // document.node
        o = X.toObj(xml.documentElement);
      }
      else
        alert("unhandled node type: " + xml.nodeType);
      return o;
    },
    toJson: function (o, name, ind) {
      var json = name ? ("\"" + name + "\"") : "";
      if (o instanceof Array) {
        for (var i = 0, n = o.length; i < n; i++)
          o[i] = X.toJson(o[i], "", ind + "\t");
        json += (name ? ":[" : "[") + (o.length > 1 ? ("\n" + ind + "\t" + o.join(",\n" + ind + "\t") + "\n" + ind) : o.join("")) + "]";
      }
      else if (o == null)
        json += (name && ":") + "null";
      else if (typeof (o) == "object") {
        var arr = [];
        for (var m in o)
          arr[arr.length] = X.toJson(o[m], m, ind + "\t");
        json += (name ? ":{" : "{") + (arr.length > 1 ? ("\n" + ind + "\t" + arr.join(",\n" + ind + "\t") + "\n" + ind) : arr.join("")) + "}";
      }
      else if (typeof (o) == "string")
        json += (name && ":") + "\"" + o.toString() + "\"";
      else
        json += (name && ":") + o.toString();
      return json;
    },
    innerXml: function (node) {
      var s = ""
      if ("innerHTML" in node)
        s = node.innerHTML;
      else {
        var asXml = function (n) {
          var s = "";
          if (n.nodeType == 1) {
            s += "<" + n.nodeName;
            for (var i = 0; i < n.attributes.length; i++)
              s += " " + n.attributes[i].nodeName + "=\"" + (n.attributes[i].nodeValue || "").toString() + "\"";
            if (n.firstChild) {
              s += ">";
              for (var c = n.firstChild; c; c = c.nextSibling)
                s += asXml(c);
              s += "</" + n.nodeName + ">";
            }
            else
              s += "/>";
          }
          else if (n.nodeType == 3)
            s += n.nodeValue;
          else if (n.nodeType == 4)
            s += "<![CDATA[" + n.nodeValue + "]]>";
          return s;
        };
        for (var c = node.firstChild; c; c = c.nextSibling)
          s += asXml(c);
      }
      return s;
    },
    escape: function (txt) {
      return txt.replace(/[\\]/g, "\\\\")
        .replace(/[\"]/g, '\\"')
        .replace(/[\n]/g, '\\n')
        .replace(/[\r]/g, '\\r');
    },
    removeWhite: function (e) {
      e.normalize();
      for (var n = e.firstChild; n;) {
        if (n.nodeType == 3) {  // text node
          if (!n.nodeValue.match(/[^ \f\n\r\t\v]/)) { // pure whitespace text node
            var nxt = n.nextSibling;
            e.removeChild(n);
            n = nxt;
          }
          else
            n = n.nextSibling;
        }
        else if (n.nodeType == 1) {  // element node
          X.removeWhite(n);
          n = n.nextSibling;
        }
        else                      // any other node
          n = n.nextSibling;
      }
      return e;
    }
  };
  if (xml.nodeType === 9) { // document node
    xml = xml.documentElement;
  }
  var json = X.toJson(X.toObj(X.removeWhite(xml)), xml.nodeName, "\t");
  return "{\n" + tab + (tab ? json.replace(/\t/g, tab) : json.replace(/\t|\n/g, "")) + "\n}";
}

function parseXml(xml) {
  var dom = null;
  if (window.DOMParser) {
    try {
      dom = (new DOMParser()).parseFromString(xml, "text/xml");
    }
    catch (e) {
      dom = null;
    }
  }
  else if (window.ActiveXObject) {
    try {
      dom = new ActiveXObject('Microsoft.XMLDOM');
      dom.async = false;
      if (!dom.loadXML(xml)) { // parse error ..
        window.alert(dom.parseError.reason + dom.parseError.srcText);
      }
    }
    catch (e) {
      dom = null;
    }
  }
  else {
    alert("cannot parse xml string!");
  }
  return dom;
}

function paths(obj, path, lastKey, nextKey) {
  var o, key;
  path = path ? path : {};
  lastKey = lastKey ? lastKey : "";
  nextKey = nextKey ? nextKey : "";

  for (o in obj) {

    // Push path onto stack
    path[o] = (nextKey + "." + lastKey + "." + o).replace(/^[.]+/g, "");

    // Pass updated "nextKey" along with next recurse
    key = nextKey + "." + lastKey;

    // Call again on all nested objects
    if (isPlainObject(obj[o])) {
      this.paths(obj[o], path, o, key);
    }
  }
  return Object.keys(path).length ? path : undefined;
}

function get(object, path) {
  return _get(object, path);
}

function set(object, path, value) {
  _set(object, path, value);
}

/**
 * Creates a shallow clone of value.
 * Note: This method is loosely based on the structured clone algorithm and supports cloning arrays, array buffers,
 * booleans, date objects, maps, numbers, Object objects, regexes, sets, strings, symbols, and typed arrays.
 * The own enumerable properties of arguments objects are cloned as plain objects.
 * An empty object is returned for uncloneable values such as error objects, functions, DOM nodes, and WeakMaps.
 * @param {*} value: The value to clone.
 * @return {*}: The cloned value.
 */
function clone(value) {
  return _clone(value);
}

/**
 * This method is like _.clone except that it recursively clones value.
 * Note: This method is loosely based on the structured clone algorithm and supports cloning arrays, array buffers,
 * booleans, date objects, maps, numbers, Object objects, regexes, sets, strings, symbols, and typed arrays.
 * The own enumerable properties of arguments objects are cloned as plain objects.
 * An empty object is returned for uncloneable values such as error objects, functions, DOM nodes, and WeakMaps.
 * @param {*} value: The value to recursively clone.
 * @return {*}: The deep cloned value.
 */
export function cloneDeep(value) {
  return _cloneDeep(value);
}

/**
 * Performs a deep comparison between two values to determine if they are equivalent.
 * Note: This method supports comparing arrays, array buffers, booleans, date objects, error objects, maps, numbers,
 * Object objects, regexes, sets, strings, symbols, and typed arrays.
 * Object objects are compared by their own, not inherited, enumerable properties.
 * Functions and DOM nodes are compared by strict equality, i.e. ===.
 * @param value - The value to compare.
 * @param other - The other value to compare.
 */
export function isEqual(value, other) {
  return _isEqual(value, other);
}

/**
 * Checks if value is a plain object, that is, an object created by the Object constructor
 * or one with a [[Prototype]] of null.
 * @param value     - The value to check.
 * @returns boolean - Returns true if value is a plain object, else false.
 */

export function isPlainObject(value) {
  return _isPlainObject(value);
}

/**
 * Creates a function that will delay the execution of `func` until after
 * `wait` milliseconds have elapsed since the last time it was invoked.
 * Provide an options object to indicate that `func` should be invoked on
 * the leading and/or trailing edge of the `wait` timeout. Subsequent calls
 * to the debounced function will return the result of the last `func` call.
 *
 * Note: If `leading` and `trailing` options are `true` `func` will be called
 * on the trailing edge of the timeout only if the the debounced function is
 * invoked more than once during the `wait` timeout.
 *
 * @static
 * @category Functions
 * @param {Function} func The function to debounce.
 * @param {number} wait The number of milliseconds to delay.
 * @param {Object} [options] The options object.
 * @param {boolean} [options.leading=false] Specify execution on the leading edge of the timeout.
 * @param {number} [options.maxWait] The maximum time `func` is allowed to be delayed before it's called.
 * @param {boolean} [options.trailing=true] Specify execution on the trailing edge of the timeout.
 * @returns {Function} Returns the new debounced function.
 * @example
 *
 * // avoid costly calculations while the window size is in flux
 * var lazyLayout = utils.debounce(calculateLayout, 150);
 * jQuery(window).on('resize', lazyLayout);
 *
 * // execute `sendMail` when the click event is fired, debouncing subsequent calls
 * jQuery('#postbox').on('click', utils.debounce(sendMail, 300, {
     *   'leading': true,
     *   'trailing': false
     * });
 *
 * // ensure `batchLog` is executed once after 1 second of debounced calls
 * var source = new EventSource('/stream');
 * source.addEventListener('message', utils.debounce(batchLog, 250, {
     *   'maxWait': 1000
     * }, false);
 */
export function debounce(func, wait, options) {
  return _debounce(func, wait, options);
}

/**
 * Creates a throttled function that only invokes func at most once per every wait milliseconds.
 * The throttled function comes with a cancel method to cancel delayed func invocations and a flush method to immediately invoke them.
 * Provide an options object to indicate whether func should be invoked on the leading and/or trailing edge of the wait timeout.
 * The func is invoked with the last arguments provided to the throttled function.
 * Subsequent calls to the throttled function return the result of the last func invocation.

 * Note:
 * If leading and trailing options are true, func is invoked on the trailing edge of the timeout only if the
 * throttled function is invoked more than once during the wait timeout.

 * See David Corbacho’s article for details over the differences between _.throttle and _.debounce.

 * Arguments
 * func (Function): The function to throttle.
 * [wait=0] (number): The number of milliseconds to throttle invocations to.
 * [options] (Object): The options object.
 * [options.leading=true] (boolean): Specify invoking on the leading edge of the timeout.
 * [options.trailing=true] (boolean): Specify invoking on the trailing edge of the timeout.
 * Returns
 * (Function): Returns the new throttled function.
 */

export function throttle(func, wait, options) {
  return _throttle(func, wait, options);
}

export function noop() {
}

module.exports = {
  round,
  format,
  formatFileSize,
  capitalize,
  clear,
  filterProperty,
  isArray,
  isFunction,
  isHTML,
  isObject,
  merge,
  reduce,
  transform,
  xml2json,
  parseXml,
  paths,
  get,
  set,
  clone,
  cloneDeep,
  isEqual,
  isPlainObject,
  debounce,
  throttle,
  noop,
};
