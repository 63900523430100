import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ScrollablePanels from 'src/components/common/ScrollablePanels/ScrollablePanels';
import Panel from 'src/components/common/ScrollablePanels/Panel';
import Button from 'src/components/common/buttons/Button';
import ToggleButton from 'src/components/common/buttons/ToggleButton';
import Loader from 'src/components/common/loader/Loader';
import sandbox from 'sandbox';
import { getFolderCurrentDate } from 'core/dates';
import { formatBreadcrumbs } from 'utilities/breadcrumbs';
import { toLocaleShortTime } from 'core/services/localization';

const refreshIcon = sandbox.icons.getModuleIcon('InfoDialog', 'refresh');
const translate = sandbox.localization.translate;

const labels = {
  page: translate('Page'),
  form: translate('Form'),
  ad: translate('AD'),
  editorial: translate('EDITORIAL'),
};

const getElementType = model => {
  let elementType = '';

  if (model.contentType === 'AD') {
    elementType = labels.ad;
  } else if (model.contentType === 'EDITORIAL') {
    elementType = labels.editorial;
  } else if (model.object.elementType === 'page') {
    elementType = labels.page;
  } else {
    elementType = labels.form;
  }

  return elementType;
};

class PanelInfo extends Component {

  static propTypes = {
    model: PropTypes.any,
    className: PropTypes.string,
    panels: PropTypes.array,
    selectedPanelIndex: PropTypes.number,
    refreshData: PropTypes.func,
    openCustomInfoDialog: PropTypes.func,
    headerBreadcrumbs: PropTypes.string,
    module: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      toggleChecked: false,
    };
  }

  getPanel = (panel, index) => {
    const { className, openCustomInfoDialog, module } = this.props;
    const customInfoComponent = <panel.PanelComp {...panel.props} module={module} title={panel.title} advanceInfo={this.state.toggleChecked} openCustomInfoDialog={openCustomInfoDialog} openerType='rightPanel' />;

    return <Panel key={index} module={module} className={`crtx-custom-info-panel ${className}`} icon={panel.icon} title={panel.title} menuTitle={panel.menuTitle}>
      {customInfoComponent}
    </Panel>;
  };

  getPanelsComponents = () => {
    const { panels } = this.props;
    const panelsComponents = panels.map(this.getPanel);//.filter((panel) => { return panel != undefined });
    return panelsComponents;
  };

  handleRefresh = (event) => {
    this.props.refreshData();
  };

  handleClick = () => {
    this.setState({
      toggleChecked: !this.state.toggleChecked
    });
  };

  render() {
    const { model, selectedPanelIndex, className, headerBreadcrumbs, loading, onSelectedPanelChange } = this.props;
    const date = getFolderCurrentDate();
    const shortTime = toLocaleShortTime(date);
    const transElementType = getElementType(model);
    const breadcrumbs = model.contentType === 'AD' || model.contentType === 'EDITORIAL' ? model.name : model.object.breadcrumbs;

    return <div className='crtx-custom-info' style={{ width: '100%', height: '100%' }}>
      <Loader loading={loading} />
      <div className='crtx-custom-info-header'>
        <div className='crtx-advance-and-refresh'>
          <div className='crtx-buffer'></div>
          <ToggleButton className='crtx-advance-toggle-button' onClick={this.handleClick} checked={this.state.toggleChecked}>Advanced</ToggleButton>
          <div className='crtx-buffer'></div>
          <Button className='crtx-refresh-button' onClick={this.handleRefresh}><img src={refreshIcon} /></Button>
          <span className='crtx-date'>Last update {shortTime}</span>
        </div>
        <div className='crtx-outer-info-breadcrumbs' title={formatBreadcrumbs(breadcrumbs)}>
          <span className='crtx-inner-info-breadcrumbs ellipsis'>{`${transElementType}: ${headerBreadcrumbs}`}</span>
        </div>
      </div>
      <ScrollablePanels className='custom-info-content' style={{ width: '100%' }} selectedIndex={selectedPanelIndex} onSelectedChange={onSelectedPanelChange}>
        {this.getPanelsComponents()}
      </ScrollablePanels>
    </div>;

  }

}

export default PanelInfo;