import sandbox from 'sandbox';
import ApproveRejectAll from './ApproveRejectAll';
import {isInApprovalFlowStep, isSeparationWaitingForUserApprovalIndications, isItemWaitingForApproval, getItemApprovalIndications, getItemWaitingIndicationsPerUser, getSeparationWaitingIndicationsPerUser} from '../../utilities/approvals';

const isUndefined = o => typeof o === 'undefined';
const APPROVAL_FLOW_STEP_TYPE = 'workflow/step/flow/approval';

function getApprovalFlowStepNwid(module) {
  if (module.startParameters && module.startParameters.rootType === APPROVAL_FLOW_STEP_TYPE) 
    return module.startParameters.rootId;
  
  if (module.parentModule && module.parentModule.startParameters && module.parentModule.startParameters.rootType === APPROVAL_FLOW_STEP_TYPE) 
    return module.parentModule.startParameters.rootId;

  return undefined;
}

function getIsViewDescendentOfApprovalView(actionInstance) {
  if (actionInstance.module.startParameters && actionInstance.module.startParameters.rootType === APPROVAL_FLOW_STEP_TYPE) return true;
  if (actionInstance.module.parentModule && actionInstance.module.parentModule.startParameters && actionInstance.module.parentModule.startParameters.rootType === APPROVAL_FLOW_STEP_TYPE) return true;

  return false;
}

export default ApproveRejectAll.extend({
  isApplicable: function (objs) {
    
    const config = this.config || {};
    const allowedFlowstepIds = config.flowStepIds || [];
    const isViewDescendentOfApprovalView = getIsViewDescendentOfApprovalView(this);
    const approvalFlowStepNwid = getApprovalFlowStepNwid(this.module);
    let superResult = (isViewDescendentOfApprovalView && allowedFlowstepIds.length > 0 && !allowedFlowstepIds.includes(approvalFlowStepNwid));
    if (!superResult){
      superResult = objs.every(selectedItem => {
        if (selectedItem.type === 'page/separation') {
          return isInApprovalFlowStep(selectedItem, allowedFlowstepIds) && isSeparationWaitingForUserApprovalIndications(selectedItem.parent, selectedItem);
        }
        return isItemWaitingForApproval(selectedItem, allowedFlowstepIds)
      });
    }
    

    return superResult && objs.every(selectedItem => selectedItem.deprecated !== "true");
  },

  execute: function (selectedItems) {            
    var promiseRet = this._super(selectedItems);    

    const viewInstanceNwId = this.module.nwid;
    const model = {
      pages: Array.isArray(selectedItems) ? selectedItems.map(selectedItem => selectedItem.nwid ): []
    };
    
    if (promiseRet){
      promiseRet.then(() => {        
        const rest = sandbox.request.rest(viewInstanceNwId);
        //pages        
        rest.post(`cw/deprecatedMultiple`, {
          data: JSON.stringify(model),
          dataType: 'text',
        });
      });
    }
  }
});


