import { createObjectComparator } from 'core/comparators';
import { COMPARE_TYPE } from 'core/comparators';
import { fromServerDate } from 'core/dates';


export function getAllPages(model) {
  let ret = [];
  const children = model.children;
  if (typeof children !== 'undefined') {
    for (let i = 0; i < children.length; i++) {
      const unplanContainers = children[i].unplanContainers;
      if (typeof unplanContainers !== 'undefined') {
        for (let j = 0; j < unplanContainers.length; j++) {
          const pages = unplanContainers[j].pages;
          const notUploadedPages = [];
          for (let k = 0; k < pages.length; k++) {

            if (pages[k].isUploaded)
              continue; // we do not show uploaded files for now

            const seps = pages[k].separations;
            pages[k].versionIndex = pages[k].pageContent.versionIndex;
            pages[k].pageContent.pageId = pages[k].id;
            seps.sort(createObjectComparator('separation', COMPARE_TYPE.CASE_INSENSITIVE));

            notUploadedPages.push(pages[k]);
          }

          ret = ret.concat(notUploadedPages);
        }
      }
    }
  }

  return ret;
}

export const getPageInputTime = page => {

  const mainVersions = page && page.pageContent && page.pageContent.mainVersions || [];

  const inputTimes = mainVersions.map(version => fromServerDate(version.time));
  const time = getLatestDate(inputTimes);

  return time;
}

const getLatestDate = dates => {

  let latestDate = dates[0];

  for (let index = 1; index < dates.length; index++) {
    if (dates[index] > latestDate) {
      latestDate = dates[index];
    };
  }

  return latestDate;
}

export const getPageInputFileName = page => {
  if (!page) {
    return '';
  }

  let inputFileName;
  if (page.contentType === 'pdf') {
    inputFileName = page.pageContent && page.pageContent.version && page.pageContent.version.inputFileName || '';
  } else {
    inputFileName = page.separations && page.separations.length > 0 && getPageInputFileNameInTifFiles(page.separations) || '';
  }

  return inputFileName;
}

const getPageInputFileNameInTifFiles = separations => {

  let ret = '';

  const inputFileName = separations[0].pageSeparationContent && separations[0].pageSeparationContent.version &&
    separations[0].pageSeparationContent.version.inputFileName || '';

  if (inputFileName) {
    const inputFileNameArr = Array.from(inputFileName);
    for (let i = 0; i < inputFileNameArr.length; i++) {
      const char = inputFileNameArr[i];

      for (let index = 1; index < separations.length; index++) {
        const sep = separations[index];
        const name = getSepInputFileName(sep);

        if (name.charAt(i) !== char) {
          inputFileNameArr[i] = '*';
          break;
        };
      };
    };
    
    ret = inputFileNameArr.join('');
  };

  return ret;
}

const getSepInputFileName = sep => sep && sep.pageSeparationContent && sep.pageSeparationContent.version &&
  sep.pageSeparationContent.version.inputFileName || '';