import React from 'react';
import PropTypes from 'prop-types';
import Menu from 'components/common/menu/Menu';
import { DropdownArrow } from 'components/common/floating';

export default function BreadcrumbsList({ cursor, onClick }) {

  const renderItem = (item) => {
    return (
      <div className='crtx-menu-item' title={item.displayName}>
        {item.displayName}
      </div>
    );
  };

  return (
    <Menu
      className='crtx-breadcrumbs-list'
      items={cursor}
      onSelect={onClick}
      menuItemGetter={renderItem}
    >
      <DropdownArrow />
    </Menu>
  );
}

BreadcrumbsList.propTypes = {
  cursor: PropTypes.array.isRequired,
  onClick: PropTypes.func,
};
