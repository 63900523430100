/**
 * Created with JetBrains WebStorm.
 * User: elad
 * Date: 11/20/13
 * Time: 11:16 AM
 * To change this template use File | Settings | File Templates.
 */
define(['sandbox', 'AbstractAction', 'logger'], function(sandbox, AbstractAction, logger) {
  'use strict';
  var log = logger.getDefaultLogger();
  return AbstractAction.extend({
    execute: function () {
      var projectorId = this.module.projectorId;
      var actionInstanceNwId = this.nwid;
      var model = this.module.ctrl.get("content");
      sandbox.request.saveFlowStepSetup(actionInstanceNwId, projectorId, model);
    }
  });
});