import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utilities/classNames';

class Header extends Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    buttons: PropTypes.array, //[{name: string, title: string, icon: string, onClick: function},...]
    renderButtons: PropTypes.func
  };

  static defaultProps = {
    renderButtons: () => { }
  }

  render() {
    const { style, className, buttons, renderButtons, children } = this.props;
    let headerButtons = undefined;

    if (Array.isArray(buttons) && buttons.length > 0) {
      headerButtons = buttons.map((button, index) => {
        return <img key={index} className='header-button' title={button.title} src={button.icon}
          onClick={button.onClick} />;
      });
    }

    return (
      <div className={classNames('panel-header', className)} style={style}>
        <div className='panel-name'>{children}</div>
        <div className='panel-line'></div>
        {headerButtons}
        {renderButtons()}
      </div>
    );
  }
}

module.exports = Header;