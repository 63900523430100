import React from 'react';
import LengthInput from 'components/common/inputs/LengthInput';
import InputCell from './InputCell';

function LengthInputCell({allowEmptyValue = true, size = 4, ...props}) {
  return (
    <InputCell InputComponent={LengthInput} allowEmptyValue={allowEmptyValue} size={size} {...props}/>
  );
}

export default LengthInputCell;
