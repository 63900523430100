/**
 * @name UploadHistory
 * @file Upload History component
 *
 * @author Boris
 * @since: 2017-07-05
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';
import jsUtils from 'sandbox/jsUtils';
import { formatBreadcrumbs } from 'utilities/breadcrumbs';
import { translate, toLocaleShortDateTime } from 'core/services/localization';
import { COMPARE_TYPE, createObjectComparator } from 'core/comparators';

export default function UploadHistory({ model, onContextMenu }) {

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [lastClickedFile, setLastClickedFile] = useState(null);

  useEffect(() => {
    setUploadedFiles(model.uploadedFiles);
  }, []);

  useEffect(() => {
    const { filesFromTickUpdate } = model;
    if (filesFromTickUpdate.length <= 0) {
      return;
    };

    const updatedUploadedFiles = uploadedFiles;

    filesFromTickUpdate.forEach(file => {
      const idx = updatedUploadedFiles.findIndex(f => f.fileId === file.fileId);
      if (idx >= 0) {
        updatedUploadedFiles[idx] = { ...updatedUploadedFiles[idx], ...file };
      } else if (file.action === 'Add') {
        updatedUploadedFiles.push(file);
      }
    });

    updatedUploadedFiles.sort(createObjectComparator('uploadDate', COMPARE_TYPE.DATES, false));

    setUploadedFiles(updatedUploadedFiles);
  });

  const removeItemById = (array, id) => {
    const idx = array.findIndex(item => item.id === id);
    if (idx >= 0) {
      array.splice(idx, 1);
    }
  };

  const handleFileMouseDown = (e, file) => {

    if (e.button === 1 || !file || !file.objectNwId) {
      return;
    }

    if (e.ctrlKey) {
      if (e.button === 0) {
        setLastClickedFile(file);
        file.selected = !file.selected;
      }
    } else if (e.shiftKey) {
      let idx1 = 0;
      if (lastClickedFile) {
        idx1 = uploadedFiles.findIndex(f => f.fileId === lastClickedFile.fileId);
        if (idx1 < 0) {
          idx1 = 0;
        }
      }

      const idx2 = uploadedFiles.findIndex(f => f.fileId === file.fileId);
      if (idx2 >= 0 && idx2 < idx1) {
        [idx1, idx2] = [idx2, idx1];
      }

      uploadedFiles.forEach((f, idx) => {
        f.selected = idx >= idx1 && idx <= idx2;
      });
    } else {
      setLastClickedFile(file);
      if (e.button === 0 || !file.selected) {
        uploadedFiles.forEach(f => {
          f.selected = f === file;
        });
      }
    }

    setUploadedFiles(uploadedFiles);

  };

  const handleFileContextMenu = (e, file) => {

    if (!file.selected || !file.objectNwId) {
      return;
    }

    if (onContextMenu) {
      const files = [];
      uploadedFiles.forEach(f => {
        if (f.selected) {
          files.push(f);
        }
      });

      onContextMenu(e, file, files);
    }
  };

  const getThumbnailUrl = file => {

    if (!file || !file.objectContentNwId || !file.objectContentTemplate) {
      return '';
    }

    const { module } = model;

    const params = {
      template: file.objectContentTemplate,
      action: "icon",
      viewId: module.id,
      nwid: file.objectContentNwId,
      iconUrlCounter: 1,
      projectorId: module.projectorId
    };

    return sandbox.request.getImageUrl(params, true);
  };


  const renderEmptyLabel = () => <div className='upload-history-empty=label'>{translate('The uploaded file table is empty')}</div>;

  const renderFileTableRow = (file, index) => {
    const thumbnailUrl = getThumbnailUrl(file);
    const className = file.selected ? 'selected' : null;
    const breadcrumbs = formatBreadcrumbs(file.breadCrumbs);

    return (
      <tr key={file.id}
        className={className}
        onMouseDown={e => handleFileMouseDown(e, file)}
        onContextMenu={e => handleFileContextMenu(e, file)}
      >
        <td className='thumbnail'>{thumbnailUrl ? <img src={thumbnailUrl} alt="" /> : ''}</td>
        <td title={file.name}>{file.name}</td>
        <td>{file.publicationName}</td>
        <td>{file.uploadUser}</td>
        <td>{toLocaleShortDateTime(file.uploadDate)}</td>
        <td>{file.associationUser}</td>
        <td>{toLocaleShortDateTime(file.assignDate)}</td>
        <td title={breadcrumbs}>{breadcrumbs}</td>
        <td>{jsUtils.formatFileSize(file.size)}</td>
        <td>{file.status}</td>
      </tr>
    );
  };

  // ***TODO: allow to open page view and page history
  const renderFileTable = files => {
    return (
      <table className='upload-history-table' cellSpacing={0} cellPadding={0}>
        <thead className='upload-history-table-header'>
          <tr>
            <th className='thumbnail'>{translate('Icon')}</th>
            <th className='file-name'>{translate('File name')}</th>
            <th className='publication'>{translate('Publication')}</th>
            <th className='upload-user'>{translate('Upload user')}</th>
            <th className='upload-date'>{translate('Upload date')}</th>
            <th className='assign-user'>{translate('Assign user')}</th>
            <th className='assign-date'>{translate('Assign date')}</th>
            <th className='page-breadcrumbs'>{translate('Page breadcrumbs')}</th>
            <th className='file-size'>{translate('File size')}</th>
            <th className='status'>{translate('Status')}</th>
          </tr>
        </thead>
        <tbody className='upload-history-table-body'>
          {files.map(renderFileTableRow)}
        </tbody>
      </table>
    );
  };

  return (
    <div className='upload-history-view'>
      {uploadedFiles.length > 0 ? renderFileTable(uploadedFiles) : renderEmptyLabel()}
    </div>
  );
}

UploadHistory.propTypes = {
  model: PropTypes.object,
  onContextMenu: PropTypes.func
};