/**
 * @name printArea
 * @file Defines area on the plate that is printed on the paper
 *
 * @author Boris
 * @since: 2017-06-13
 */

import React from 'react';
import Fabric from 'fabric';
import sandbox from 'sandbox';
import SimpleForm from 'widgets/SimpleForm/src/index';
import utils from '../utils/utils';
import canvasUtils from '../utils/canvasUtils';
import propertiesCommon from './propertiesCommon';

const {translate} = sandbox.localization;
const futils = SimpleForm.utils;

const BASE_PATH = 'layout.printArea';
const TITLE = translate('Print Area');

const FILL_COLOR = '#FFFFFF';
const STROKE = 'grey';

const _NAME = {
  key: '_name',
  caption: translate('Name'),
  type: 'string',
  disabled: true
};

const MARGIN_LEFT = {
  key: 'marginLeft',
  caption: translate('Margin left'),
  type: 'length'
};

const MARGIN_RIGHT = {
  key: 'marginRight',
  caption: translate('Margin right'),
  type: 'length'
};

const MARGIN_TOP = {
  key: 'marginTop',
  caption: translate('Margin top'),
  type: 'length'
};

const MARGIN_BOTTOM = {
  key: 'marginBottom',
  caption: translate('Margin bottom'),
  type: 'length'
};

const PRINT_AREA_META = [
  _NAME,
  MARGIN_LEFT,
  MARGIN_RIGHT,
  MARGIN_TOP,
  MARGIN_BOTTOM
];

export default (editor) => {

  const cutils = canvasUtils(editor);
  const canvas = editor.getMainCanvas();

  const getMeta = (state, element) => {
    return PRINT_AREA_META;
  };

  const setDefaultElementValues = (state, element) => {
    element._name = translate('Print Area');
    propertiesCommon.setDefaultValues(state, element, getMeta(state, element));
  };

  const updateProperty = (state, element, elementPath, propertyPath, propertyValue) => {
    const newState = propertiesCommon.updateProperty(state, element, elementPath, propertyPath, propertyValue);
    const newElement = futils.get(newState, elementPath);

    updateShape(newState, newElement);

    return newState;
  };

  const createPrintAreaRect = (element) => {
    const printArea = new Fabric.Rect({
      hasRotatingPoint: false,
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
      fill: FILL_COLOR,
      stroke: STROKE,
      originX: 'left',
      originY: 'top',
      hoverCursor: 'default',
      moveCursor: 'default'
    });

    return printArea;
  };

  const updatePrintAreaRect = (state, element) => {
    const width = utils.canvasToSystemUnits(state.plateRectangle.width) - element.marginLeft - element.marginRight;
    const height = utils.canvasToSystemUnits(state.plateRectangle.height) - element.marginTop - element.marginBottom;
    const r = utils.toCanvasRectangle(state.plateRectangle, element.marginLeft, element.marginTop, width, height);
    const printAreaRect = element.shape.printAreaRect;
    printAreaRect.set({
      left: r.left,
      top: r.top,
      width: r.width,
      height: r.height
    });
    printAreaRect.setCoords();
  };

  const createShape = (state, element, elementPath) => {
    const printAreaRect = createPrintAreaRect(element);

    const shape = {
      elementPath,
      printAreaRect
    };

    printAreaRect.shape = shape;
    element.shape = shape;

    updateShape(state, element);
    addShape(state, element);

    return shape;
  };

  const addShape = (state, element) => {
    if (!element || !element.shape) {
      return;
    }

    canvas.add(element.shape.printAreaRect);
  };

  const removeShape = (state, element) => {
    if (!element || !element.shape) {
      return;
    }

    const shape = element.shape;
    canvas.remove(shape.printAreaRect);
  };


  const updateShape = (state, element) => {
    updatePrintAreaRect(state, element);
  };

  const activateShape = (state, element) => {
    cutils.setActiveObject(element.shape.printAreaRect);
  };

  const renderProperties = (store, element, elementPath) => {
    const state = store.getState();

    return (
      <div>
        {propertiesCommon.renderProperties(store, element, elementPath, getMeta(state, element))}
      </div>
    );
  };

  const getInfo = (state) => {
    return {
      basePath: BASE_PATH,
      title: TITLE
    };
  };

  const getAlignmentInfo = (state, element) => {
    return utils.getAlignmentInfo(element.shape.printAreaRect);
  };

  return {
    setDefaultElementValues,
    updateProperty,
    createShape,
    updateShape,
    addShape,
    removeShape,
    activateShape,
    renderProperties,
    getInfo,
    getAlignmentInfo
  }

};
