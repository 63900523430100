/**
 * Created by moshemal on 6/28/15.
 */

define(['AbstractAction', 'sandbox/dialog', 'sandbox/request'], function(AbstractAction, dialog, request){
  'use strict';

  function submitCallback(a, b){
    console.log(a,b);
  }

  function createSubmitCallback(events){
    return function submit (value){
      var params = {
        objects: events.map(function(event){
          return {
            nwid: event.nwid,
            type: event.type
          }
        }),
        resourceNwid: value
      }
     request.execServerAction(this.nwid, this.actionDefinitionName, null, null,
        this.module.projectorId, this.parameters.scriptName, params);
    }
  }
  return AbstractAction.extend({
    isApplicable: function (){
      var nResources = this.module.ctrl.get('model.resources.length') || 0;
      var nEvents = this.module.selected.length ;
      return nEvents >= 1 && nResources > 1;
    },
    execute: function (events) {
      var model = this.module.ctrl.get('model');
      var resources = model.resources.map(function(resource){
        return {
          label:  resource.name,
          name: "resourceNwid",
          value: resource.nwid
        }
      });

      dialog.createSelectionDialog(this.label, resources, createSubmitCallback(events), this, false, "Available Resources");
    }

  }, "OverrideLock");
});