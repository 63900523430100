const ServerAction = require('./ServerAction');

module.exports = ServerAction.extend({
  isApplicable: function (objs) {
    if (typeof objs === 'undefined') {
      return false;
    }
    let applicable = true;
    for (let i = 0; i < objs.length; i++) {
      const element = objs[i];
      if (element.aggregated) {
        applicable = false;
        break;
      }
      let obj;
      if (element.type === 'page') {
        obj = element.pageContent || element.content;
      } else if (element.type === 'form') {
        obj = element.formContent || element.content;
      } else if (element.type === 'plate') {
        obj = element;
      }
      if (typeof obj === 'undefined') {
        applicable = false;
        return;
      }
      if (typeof obj.isSetComplete !== 'undefined' && obj.isSetComplete === 'true') {
        applicable = false;
        return;
      }
    }
    return applicable;
  }
});
