/**
 *
 * @fileOverview Channel Manager is in charge of opening the channel
 * for the client via Web Socket or Polling (Long Polling) to receive updates from the server
 * The socket behavior might be described by the following cases:
 * Request to open socket has been sent and got response successfully (onopen method)
 * Request to open socket has been sent but got rejected (onerror method)
 * Request has been sent but no response has been received (no method for that).
 * So for the last case, we'll use timeout
 *
 * @name channelManager
 * @namespace
 * @author sergey
 */

import base from 'base';
import tickWorker from 'core/workers/tickWorker';
import sys from 'core/sys';
import appPromises from 'core/managers/appPromises';
import requestManager from 'core/managers/request';
import settingsManager from 'core/managers/settings';

const POLLING_TIME_GAP = 1000; // Time gap in milliseconds used to polling
const MAX_POLLING_CONSECUTIVE_ERRORS = 3;
const SOCKET_TIMEOUT = 5000;
const SOCKET_CHECK_INTERVAL = 50;
const SOCKET_CLOSE_CODE = 4000;
const SOCKET_CLOSE_MESSAGE_TIMEOUT = 100;
const KEEP_ALIVE_TIMEOUT = 60 * 1000;

// eslint-disable-next-line no-unused-vars
const SOCKET_READY_STATE = ['CONNECTING', 'OPEN', 'CLOSING', 'CLOSED'];

let pollingConsecutiveErrors = 0;
let socket;
let channelIsOpened = false;
let pollingStarted = false;
let keepAliveTimerId;
let dfd = base.data.deferred().done(function () {
  appPromises.resolve(appPromises.states.CHANNEL_OPENED);
});


/**
 * Whenever message from the server is received wither via
 * Web Socket or Polling, this function decides what to do
 * depending on the action in the received model.
 *
 * @param  {Object} data Message containing viewId (module id) and its model
 */
function messageHandling(data) {
  console.log('Server response on Tick Service', data);
  if (data.command) {
    tickWorker.executeCommand(data);
  } else {
    tickWorker.callTickUpdate(data);
  }
}

/**
 * Whenever all the messages from the server received
 * this function will notify the modules that where effected
 * by the tick to trigger tickCommit function via tickWorker
 *
 * @param {Object} tickedViewsIds contain all the effected viewIds (module id)
 */
function commitTicks(tickedViewsIds) {
  Object.keys(tickedViewsIds).forEach(function (viewId) {
    tickWorker.callTickCommit(viewId);
  });
}

/**
 * pollCont is supposed to query the server every specified
 * amount of time for the availability of update.
 */
function pollCont() {
  if (!pollingStarted) {
    return;
  }

  base.data.ajax({
    url: '../../servlet/TickLongPollingServlet',
    data: { clientId: settingsManager.get('id'), requestType: 1 },
    dataType: 'json',
    timeout: 5000,
    cache: false,
    beforeSend: function () {
      this.startTime = Date.now();
    },
    success: function (res) {
      // success callback
      var responseGap = Date.now() - this.startTime;
      var pollingGap = (responseGap < POLLING_TIME_GAP) ? POLLING_TIME_GAP - responseGap : POLLING_TIME_GAP;
      var tickedViewsIds = {};
      setTimeout(pollCont, pollingGap);
      pollingConsecutiveErrors = 0;
      if (typeof res.messages !== 'undefined') {
        for (var i = 0, leni = res.messages.length; i < leni; i += 1) {
          var msg = JSON.parse(res.messages[i]);
          messageHandling(msg);
          tickedViewsIds[msg.viewId] = true;
        }
        commitTicks(tickedViewsIds);
      }
    },
    error: function () {
      // error callback
      // if 3 failed requests in a row, we assume that channel is closed
      if (pollingConsecutiveErrors < MAX_POLLING_CONSECUTIVE_ERRORS) {
        pollingConsecutiveErrors += 1;
        setTimeout(pollCont, 10);
      } else {
        sys.inform('Error', 'Connection with the server has been lost', 2, true);
      }
    }
  });
}

/**
 * Polling mechanism consists of two main functions:
 * pollStart and pollCont. The first one is intended to register the
 * client at the server side and initialize the polling by calling
 * pollCont. In order to differentiate the polling requests at the
 * server side, requestType: 0 is used for pollStart
 * and requestType: 1 is to continue.
 */
function pollStart() {
  pollingStarted = true;

  base.data.ajax({
    url: '../../servlet/TickLongPollingServlet',
    data: { clientId: settingsManager.get('id'), requestType: 0 },
    //the response is empty and 'dataType: "json"' lead to error in jquery > 1.9
    dataType: 'text',
    timeout: 5000,
    cache: false,
    success: function () {
      // success callback
      channelIsOpened = true;
      console.log('pollStart(): Polling started');
      dfd.resolve();
      pollCont();
    },
    error: function () {
      // error callback
      console.error('pollStart(): Channel opening failed');
      dfd.reject();
    }
  });
}

function keepAlive() {
  requestManager.rest().get('global/status/ping', {
    dataType: 'text'
  }).fail(function () {
    // console.error('keep alive error', arguments);
    sys.inform('Error', 'Connection with the server has been lost', 2, true);
  });

  if (socket) {
    socket.send('keep-alive');
  }
}

function startKeepAlive() {
  if (!keepAliveTimerId) {
    keepAliveTimerId = setInterval(keepAlive, KEEP_ALIVE_TIMEOUT);
  }
}

function stopKeepAlive() {
  clearInterval(keepAliveTimerId);
  keepAliveTimerId = 0;
}

/**
 * Starting web socket
 * @see <a href="http://www.html5rocks.com/en/tutorials/websockets/basics/">Web Sockets basics</a>
 */
function socketStart() {
  const protocol = location.protocol === 'https:' ? 'wss:' : 'ws:';
  socket = new WebSocket(protocol + '//' + location.host + settingsManager.get('webAppPath') + '/websocket/tick/');

  socket.onopen = function () {
    // this case is required when after 3 attempts to open socket it's still
    // unresponsive, polling is started; when polling is up, and suddenly we get
    // response from socket, we need to close it.
    if (pollingStarted) {
      socket.close();
    }
  };
  socket.onmessage = function (ev) {
    const data = JSON.parse(ev.data);
    if (data.tickerStatus === 'ready') {
      dfd.resolve();
    } else if (data.tickerStatus === 'error') {
      sys.inform('Error', data.errorMsg);
      console.error('### WebSocket onmessage', data.errorMsg);
    } else {
      messageHandling(data);
      const tickedViewsIds = {};
      tickedViewsIds[data.viewId] = true;
      commitTicks(tickedViewsIds);
    }
  };
  socket.onclose = function (event) {
    console.log('### WebSocket is closed => code & reason:', event.code, event.reason);
    if (event.reason !== 'logout') {
      setTimeout(() => {
        // To prevent Firefox from displaying the following error message
        // when a WebSocket is closed due to a browser reload,
        // wait briefly for the unload process to finish.
        sys.inform('Error', 'Connection with the server has been lost', 2, true);
      }, SOCKET_CLOSE_MESSAGE_TIMEOUT);
    }

    stopKeepAlive();
  };
  socket.onerror = function () {
    sys.inform('Error', 'Connection with the server has been lost');
    stopKeepAlive();
  };
}

function startChannel(useWebsocket) {
  if (useWebsocket && window.WebSocket) { // If browser supports Web Sockets(Chrome, FF, Safari)
    let attempts = 1;
    new Promise((resolve, reject) => {
      socketStart();
      (function waitForConnection() {
        setTimeout(() => {
          if (socket.readyState === 1) {
            resolve();
          } else {
            //console.log('### WebSocket readyState=', SOCKET_READY_STATE[socket.readyState]);
            if (attempts * SOCKET_CHECK_INTERVAL <= SOCKET_TIMEOUT) {
              attempts += 1;
              waitForConnection();
            } else {
              reject();
            }
          }
        }, SOCKET_CHECK_INTERVAL);
      })();
    })
      .then(() => {
        channelIsOpened = true;
        console.log('### WebSocket opened => client ID =', settingsManager.get('id'));
        socket.send(settingsManager.get('id'));
        startKeepAlive();
      })
      .catch(() => {
        pollStart();
      });
  } else {
    pollStart();  // Else initiate polling
  }

  return dfd.promise();

}

function stopChannel(reason) {
  if (channelIsOpened) {
    channelIsOpened = false;
    pollingStarted = false;
    if (typeof socket !== 'undefined') {
      reason ? socket.close(SOCKET_CLOSE_CODE, reason) : socket.close();
    }
    stopKeepAlive();
  }
}


module.exports = {
  startChannel,
  stopChannel
};

