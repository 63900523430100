/**
 * @name
 * @fileOverview
 * @author Moshe Malka
 */
import React from 'react';
import PropTypes from 'prop-types';
import iconService from 'core/services/iconService';

export const TitleView = ({ model: { name } }) => {
  return <>
    <div className='centered left'><img src={iconService.getTemplateIcon('workflow')} /></div>
    <div className='title-text'>{name}</div>
  </>;
};

TitleView.propTypes = {
  model: PropTypes.object
};