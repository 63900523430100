import React from 'react';
import { createRoot } from 'react-dom/client';
import AbstractModule from 'AbstractModule';
import { arrayToObject, moveItem } from 'utilities/array';
import View from './src/View';
import TickableModel from '../TickableModel';
import { makeSessionsTableColumns } from './src/columnsCreator';
import { createObjectComparator, composeComparators } from 'core/comparators';
import { fromServerDate } from 'core/dates';
import { savePreferences } from 'core/managers/preferences';
import dialogService from 'core/services/dialogService';
import request from 'core/managers/request';
import jsutils from 'base/jsUtils';
import {
  checkDateCondition,
  checkTextCondition,
  extractColumnPreferences,
  reduceColumnsToFilterBy,
  FILTER_DATA_TYPE,
  FILTER_TYPE,
  reduceColumnsToSortBy,
  applyColumnSortPreferences,
  extractColumnSortPreferences
} from 'widgets/ReactDataGrid/utils';

import { translate } from 'core/services/localization';

const DEFAULT_SORT = [
  {
    ascending: true,
    key: 'userName'
  }
];

const THROTTLE_WAIT = 1000;

export default AbstractModule.extend({

  initDone: function () {
    this.toolbar = this.createToolbar();
    this.updates = [];
    this.tickUpdateHandlerThrottled = jsutils.throttle(this.tickUpdateHandler, THROTTLE_WAIT, {
      leading: false,
      trailing: true
    });
    this.reactRoot = createRoot(this.domElement);
  },

  firstTickReceived: function (data) {
    this.preferences = data.preferences || {};
    this.columnsToSortBy = data.preferences.columnsToSortBy || DEFAULT_SORT;
    this.filtersEnabled = this.preferences.table?.filtersEnabled || false;
    this.initToolbar();
    this.tickableModel = new TickableModel();

    this.tickableModel.firstTickHandler(data.model);
    this.buildViewModel(data.model);

  },

  tickUpdate: function (data) {
    this.updates = this.updates.concat(data.model);
    this.tickUpdateHandlerThrottled();
  },

  tickUpdateHandler: function () {
    this.tickableModel.tickUpdateHandler(this.updates);
    this.updates = [];
    this.buildViewModel();
  },


  initToolbar: function () {
    this.toolbar.addItem({
      label: translate('Toggle Filters'),
      name: 'toggleFilters',
      _isApplicable: true,
      icon: 'filter_list',
      iconSprite: 'general',
      itemType: 'push',
      checked: this.filtersEnabled,
      execute: this.toggleFilters.bind(this)
    });
  },

  buildViewModel: function () {
    const model = this.tickableModel.model();

    this.viewModel = {
      sessions: model.sessions.map((session, index) => {
        return {
          ...session,
          loginTime: fromServerDate(session.loginTime),
          userName: session.user.name,
          userEmail: session.user.email
        };
      }),
    };

    this.viewModel.sortedSessions = [...this.viewModel.sessions];
    this.viewModel.filteredSessions = [...this.viewModel.sortedSessions];

    this.sessionsTableColumns = makeSessionsTableColumns(this.viewModel, this);
    this.sortSessions();
    this.filterSessions();
    this.updateSelectedRows();
    this.render();
  },

  sortSessions: function () {
    if (this.columnsToSortBy.length > 0) {
      const preferencesColumnsToSortBy = applyColumnSortPreferences(this.sessionsTableColumns, this.columnsToSortBy);

      const comparator = composeComparators(preferencesColumnsToSortBy.map(col => {
        return createObjectComparator(col.sortValueGetter || col.key, col.sortType, col.ascending);
      }));
      this.viewModel.sortedSessions = this.viewModel.sortedSessions.sort(comparator);
    }
  },

  filterSessions: function () {
    this.toolbar.items.forEach(item => {
      if (item.name === 'toggleFilters') {
        item.checked = this.filtersEnabled;
      }
    });

    if (!this.filtersEnabled) {
      this.viewModel.filteredSessions = this.viewModel.sortedSessions;

    } else {
      const columnsToFilterBy = reduceColumnsToFilterBy(this.sessionsTableColumns);

      this.viewModel.filteredSessions = this.viewModel.sortedSessions.filter(session => {

        let match = true;
        for (const col of columnsToFilterBy) {
          const filter = col.filter;

          if (filter.type === FILTER_TYPE.DATE) {
            const time = typeof col.filterValueGetter === 'function' ? col.filterValueGetter(session) : session[col.key];
            if (time) {
              match = checkDateCondition(time, filter);
            }
          } else if (filter.type === FILTER_TYPE.MULTISELECT) {
            if (Array.isArray(filter.selected) && filter.selected.length > 0) {
              const filterValue = typeof col.filterValueGetter === 'function' ? col.filterValueGetter(session) : session[col.key];
              match = filter.selected.some(s => s === filterValue);
            }
          } else if (filter.type === FILTER_TYPE.TEXT && filter.textValue) {
            if (filter.dataType === FILTER_DATA_TYPE.TEXT) {
              const text = typeof col.filterValueGetter === 'function' ? col.filterValueGetter(session) : session[col.key];
              match = checkTextCondition(text, filter);

            }
          }
          if (!match) {
            break;
          }
        }
        return match;
      });
    }
  },

  toggleFilters: function (pushed) {

    this.filtersEnabled = pushed;

    this.toolbar.setItemChecked('toggleFilters', pushed);

    this.filterSessions();

    this.saveSessionsColumnPreferences();

    this.buildViewModel();
  },

  handleToolbarButtonsClick: function (checked) {

    if (checked) {
      this.toolbar.setItemChecked('toggleFilters', checked);
      this.filtersEnabled = checked;
    }

    this.filterSessions();

    const columns = extractColumnPreferences(this.sessionsTableColumns);

    this.savePreferences({
      ...this.preferences,

      table: {
        ...this.preferences.table,
        filtersEnabled: this.filtersEnabled,
        sessionColumns: columns
      }
    });
    this.render();
  },

  handleSessionColumnClick: function (sortType) {
    return (columnKey, sortValueGetter, multiSort) => {
      this.columnsToSortBy = reduceColumnsToSortBy(this.sessionsTableColumns, this.columnsToSortBy, columnKey, multiSort);
      this.sortSessions();
      this.filterSessions();
      this.savePreferences({
        ...this.preferences,
        columnsToSortBy: extractColumnSortPreferences(this.columnsToSortBy)
      });
      this.render();
    };
  },

  handleDoubleClickRow: function (rowIndex, rowContent, e) {
    this.navigateByViewLink(rowContent);
  },


  saveSessionsColumnPreferences: function () {
    const sessionColumns = extractColumnPreferences(this.sessionsTableColumns);
    this.savePreferences({
      table: {
        ...this.preferences.table,
        filtersEnabled: this.filtersEnabled,
        sessionColumns,
      }
    });
  },

  savePreferences: function (preferences) {
    this.preferences = Object.assign(this.preferences, preferences);
    savePreferences(this.getRequiredParameters(), this.preferences);
  },

  handleSessionsTableColumnsFilter: function (columns) {
    this.sessionsTableColumns.forEach(col => {
      if (columns[col.key]) {
        col.visible = columns[col.key].visible;
      }
    });

    this.saveSessionsColumnPreferences();
  },

  handleSessionsTableColumnOrder: function (columns, oldIndex, newIndex) {
    moveItem(this.sessionsTableColumns, oldIndex, newIndex);
    this.saveSessionsColumnPreferences();
    this.render();
  },

  handleSessionsTableColumnResize: function (columns) {
    this.sessionsTableColumns.forEach(col => {
      if (columns[col.key]) {
        col.width = columns[col.key].width;
      }
    });

    this.saveSessionsColumnPreferences();
  },

  handleSessionColumnFilterChange: function (column, columnFilter) {
    if (!column || !column.filter || !column.filter.type) {
      return;
    }

    column.filter = {
      ...column.filter,
      ...columnFilter
    };

    this.saveSessionsColumnPreferences();
    this.filterSessions();
    this.render();
  },

  updateSelectedRows: function () {
    const rowsByNwid = arrayToObject(this.viewModel.sessions, 'nwid');

    const selectedRows = this.selected.reduce((acc, row) => {
      const updatedRow = rowsByNwid[row.nwid];
      if (updatedRow) {
        acc.push(updatedRow);
      }

      return acc;
    }, []);

    this.updateSelected(selectedRows);
  },

  handleTableSelect: function (selectedRows) {

    this.updateSelected(selectedRows);
  },

  handleRowContextMenu: function (clickedRow, selectedRows, e) {
    this.showContextMenu(clickedRow, selectedRows, e);
  },

  handleDisconnectSession: function (sessionId) {
    dialogService.openConfirmDialog('You are about to disconnect a session')
      .then(() => {
        let params = {
          command: 'logoutSession',
          rootId: this.viewSettings.nwid,
          nwid: this.viewSettings.nwid,
          template: this.viewSettings.rootType,
          sessionId: sessionId
        };

        request.sendGenericRequest(params, null);
      });

  },

  destroy: function () {
    this._super();
    this.reactRoot?.unmount();
    this.reactRoot = null;
  },

  render: function () {
    this.reactRoot.render(<View module={this}
      viewModel={this.viewModel}
      sessionsTableColumns={this.sessionsTableColumns}
    />);
  },
});
