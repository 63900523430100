/**
 * @name
 * @fileOverview
 * @author sergey
 */
define(['ember'], function (Ember) {
  'use strict';
  return Ember.Controller.extend({
    isEditing: false,
    isMultiSelect: false,
    onEditingDidChange: function () {
      var hostingController = this.get('hostingController'),
        actionName = this.get('afterEditHandler');
      if (!this.get('isEditing') && this.get('content') !== this.get('cache')) {
        hostingController.send(actionName, this.get('content'), this.get('_view'));
      }
    }.observes('isEditing'),
    actions: {
      edit: function () {
        if (!this.get('isEditing')) {
          this.set('isEditing', true);
          // should copy the content in case it's object
          this.set('cache', Ember.copy(this.get('content')));
        }
      },
      finish: function () {
        this.set('isEditing', false);
        this.set('isMultiSelect', false);
      },
      cancel: function () {
        this.set('content', this.get('cache'));
        this.set('isEditing', false);
        this.set('isMultiSelect', false);
      },
      multiSelect: function () {
        this.set('isMultiSelect', true);
      }
    }
  });
});