import React from 'react';
import { translate } from 'core/services/localization';
import { cells } from 'widgets/ReactDataGrid';

const { Text, GenericCell } = cells;
import { applyColumnPreferences } from 'widgets/ReactDataGrid/utils';
import IconButton from 'components/common/buttons/IconButton';
import { getHoldIconName, getHoldIconTooltip, getHoldType } from 'utilities/hold';
import { getPlateClassName } from '../BinsView/utils';

export const makeTableColumns = (viewModel, preferences) => {
  let columns = [
    {
      key: 'pressLocation',
      width: 100,
      caption: translate('Press location'),
      cell: Text,
      cellDataGetter: (rowIndex, key) => {
        const plate = viewModel.plates[rowIndex];
        const columnData = plate.pressLocation;
        const className = getPlateClassName(plate.status);

        return {
          columnData,
          className,
        };
      },
      shouldCellUpdate: (nextProps, props) => nextProps.columnData !== props.columnData || nextProps.className !== props.className
    },
    {
      key: 'breadcrumbs',
      width: 100,
      caption: translate('Separation'),
      cell: Text,
      cellDataGetter: (rowIndex, key) => {
        const { breadcrumbs, repeat } = viewModel.plates[rowIndex];
        const columnData = repeat > 1 ? `${breadcrumbs} (${repeat})` : breadcrumbs;

        return {
          columnData,
        };
      },
    },
    {
      key: 'hold',
      width: 20,
      align: 'center',
      fixed: 'right',
      resizable: false,
      cell: GenericCell,
      cellDataGetter: (rowIndex, key) => {
        const holdType = getHoldType(viewModel.plates[rowIndex]);

        const iconName = getHoldIconName(holdType);

        if (!iconName) {
          return '';
        }

        return {
          columnData: <IconButton
            iconName={iconName}
            className='crtx-size-12'
            tooltip={getHoldIconTooltip(holdType)}
          />,
          title: translate('Hold'),
          className: 'crtx-align-content-center'
        };
      },
    },
  ];

  columns = applyColumnPreferences(columns, preferences.columns);

  return columns;
};

