const ServerAction = require('./ServerAction');

module.exports = ServerAction.extend({
  isApplicable: function (objs) {
    if (!objs || objs.length !== 1) {
      return false;
    }

    return objs[0].needAllRunPlates !== 'true';
  }
});
