import React from 'react';
import { createRoot } from 'react-dom/client';
const AbstractModule = require('AbstractModule');
const sandbox = require('sandbox');
const SimpleForm = require('widgets/SimpleForm/src/index');
const OverridesTables = require('./components/OverridesTables');
const reducer = require('./reducer');
const idWorker = require('core/workers/idWorker');
const labels = require('./labels')();
import { restGet } from 'core/managers/rest2';


const { Store } = SimpleForm;

const breadcrumbsHierarchy = (type, hierarchy) => {
  const common = ['Folder', 'Date', 'Publication', 'Edition', 'Zone'];
  const breadcrumbs = {
    'common': common,
    'pages': common.concat(['Section', 'Page', 'Separation']),
    'forms': common.concat(['Book', 'Form', 'Separation', 'Plate']),
    'productionrun': ['Folder', 'Date', 'Publication', 'Zone', 'Productionrun']
  };

  if (typeof hierarchy === 'undefined') return breadcrumbs[type];

  return breadcrumbs[type].slice(0, hierarchy);
};

const breadcrumbsColumnsMap = {
  'folder': breadcrumbsHierarchy('common', 1),
  'publicationdate': breadcrumbsHierarchy('common', 2),
  'publication': breadcrumbsHierarchy('common', 3),
  'edition': breadcrumbsHierarchy('common', 4),
  'zone': breadcrumbsHierarchy('common', 5),
  'section': breadcrumbsHierarchy('pages', 6),
  'page': breadcrumbsHierarchy('pages', 7),
  'page/separation': breadcrumbsHierarchy('pages'),
  'book': breadcrumbsHierarchy('forms', 6),
  'form': breadcrumbsHierarchy('forms', 7),
  'form/separation': breadcrumbsHierarchy('forms', 8),
  'plate': breadcrumbsHierarchy('forms'),
  'productionrun': breadcrumbsHierarchy('productionrun', 5)
};

const breadcrumbsColumnDefinition = (column) => {
  return {
    displayName: column,
    editable: false,
    fieldType: 'text',
    freeText: true,
    name: column.toLowerCase()
  };
};

const breadcrumbsColumns = (targetType) => {
  const columns = breadcrumbsColumnsMap[targetType] || [];
  return columns.map(breadcrumbsColumnDefinition);
};

const removePagesOrFormsLevel = (breadcrumbsArray) => {
  return breadcrumbsArray.length < 6 ? breadcrumbsArray : [
    ...(breadcrumbsArray.slice(0, 5)),
    ...(breadcrumbsArray.slice(6))
  ];
};

const objectBreadcrumbsColumns = (breadcrumbs, strObjectBreadcrumbs) => {
  const breadcrumbsArray = strObjectBreadcrumbs.split('/').filter(breadcrumb => breadcrumb !== '');
  const breadcrumbsColumns = removePagesOrFormsLevel(breadcrumbsArray);
  return breadcrumbs.reduce((acc, breadcrumb, index) => {
    return Object.assign(acc, {
      [breadcrumb.name]: breadcrumbsColumns[index]
    });
  }, {});
};

const initTabs = (tables) => {
  return tables.map((table) => {
    return {
      nwid: table.nwid,
      name: table.name
    };
  });
};

const getObjectsToModel = (table, breadcrumbs) => {
  return table.objects.map((obj) => {
    // const newValues = convertDeadlineValuesToLocalaizedDateTime(table, obj);
    return {
      ...obj,
      breadcrumbsColumns: objectBreadcrumbsColumns(breadcrumbs, obj.breadcrumbs),
      values: obj.values// newValues
    };
  });
};

const initTables = (tables) => {
  return tables.map((table) => {
    const breadcrumbs = breadcrumbsColumns(table.targetType);
    let objects = getObjectsToModel(table, breadcrumbs);
    return {
      ...table,
      objects: objects,
      breadcrumbs: breadcrumbs,
    };
  });
};

const initState = (state) => {
  return {
    ...state,
    tabs: initTabs(state.tables),
    modifiedTabs: {},
    currentTableIndex: 0,
    tables: initTables(state.tables),
    loaded: false
  };
};

const getObjectsToSave = (table) => {
  return table.objects.map((obj) => {
    let retObject = Object.assign({}, obj);
    delete retObject.breadcrumbsColumns;
    // const newValues = convertDeadlineValuesToNWDateTime(table, obj);

    return {
      ...retObject,
      values: obj.values// newValues
    };
  });
};

const saveState = (state) => {
  let ret = Object.assign({}, state);
  delete ret.tabs;
  delete ret.currentTableIndex;
  delete ret.modifiedTabs;
  ret.tables = ret.tables.map((table) => {
    let retTable = Object.assign({}, table);
    delete retTable.breadcrumbs;
    retTable.objects = getObjectsToSave(retTable);
    return retTable;
  });
  return ret;
};

module.exports = AbstractModule.extend({

  allowStandbyMode: true,

  shouldRenderOnFirstTime: true,

  store: undefined,

  selected: [],


  initDone: function () {
    this.store = undefined;
    this.oldModel = undefined;
    this.reactRoot = createRoot(this.domElement);

    this.render = this.render.bind(this);
    this.toolbar = this.createToolbar();
  },

  firstTickReceived: function (data) {
    let model = data.model;
    let state = model.tables === undefined ? initState({ tables: [] }) : initState(model);
    this.store = new Store(state, reducer, this.render);
    this.store.subscribe(this.handleDispatch.bind(this));
    this.oldModel = this.store.getState();

    Promise.all([
      restGet(this.nwid, `planning/product-setup?nwid=${this.folderNwid}`, {}, { version: 'v1' }),
      restGet(this.nwid, 'village/sites'),
      restGet(this.nwid, `profiles/groups/${this.folderNwid}`)
    ])
      .then(res => {
        const [productSetup, sites, folderSecurityGroups] = res;

        sandbox.sorting.sort(sites.sites, 'caseinsensitive', 'name');
        sandbox.sorting.sort(productSetup.publications, 'caseinsensitive', 'name');
        let allEditionsOptions = [{ value: '', name: '---' }];
        let allSectionsOptions = [{ value: '', name: '---' }];
        let allZoneOptions = [{ value: '', name: '---' }];

        productSetup.publications.forEach(function (publication) {
          publication.value = publication.name;
          for (let item in publication) {
            if (item === 'editions') {
              sandbox.sorting.sort(publication[item], 'caseinsensitive', 'name');
              publication[item].forEach(function (edition) {
                edition.value = edition.name;
                allEditionsOptions.push({
                  name: edition.name,
                  value: edition.name
                });
              });
            }
            if (item === 'sections') {
              sandbox.sorting.sort(publication[item], 'caseinsensitive', 'name');
              publication[item].forEach(function (section) {
                section.value = section.name;
                allSectionsOptions.push({
                  name: section.name,
                  value: section.name
                });
              });
            }
            if (item === 'zones') {
              sandbox.sorting.sort(publication[item], 'caseinsensitive', 'name');
              publication[item].forEach(function (zone) {
                zone.value = zone.name;
                allZoneOptions.push({
                  name: zone.name,
                  value: zone.name
                });
              });
            }
          }
        });
        sandbox.sorting.sort(allEditionsOptions, 'caseinsensitive', 'name');
        sandbox.sorting.sort(allSectionsOptions, 'caseinsensitive', 'name');
        sandbox.sorting.sort(allZoneOptions, 'caseinsensitive', 'name');
        let valuesPublications = [{ name: labels.all, value: '*', editions: allEditionsOptions, sections: allSectionsOptions, zones: allZoneOptions }];
        const valuesPublicationsOptions = valuesPublications.concat(productSetup.publications);

        this.store.dispatch({
          type: 'SET_PROPERTIES',
          publications: valuesPublicationsOptions,
          sites: sites.sites,
          loaded: true,
          folderSecurityGroups: folderSecurityGroups
        });
      });
  },

  tickUpdate: function () {

  },

  tickCommit: function () {
    this.render();
  },

  handleDispatch: function () {
    const state = this.store.getState();
    if (Object.keys(state.modifiedTabs).length > 0 && this.selected.length === 0) {
      this.updateSelected([true]);
    }
  },

  apply: function () {
    let oldModel = this.oldModel;
    let newModel = saveState(this.store.getState());
    this.oldModel = newModel;
    this.store.dispatch({
      type: 'CLEAN_CHANGED'
    });

    return {
      oldModel,
      newModel
    }
  },

  onSaveActionResponse: function (response = {}) {
    this.updateSelected([]);
  },

  render: function () {
    if (this.store && this.store.getState().loaded) {
      this.reactRoot.render(<OverridesTables store={this.store} />);
    }
  }

});
