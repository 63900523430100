/**
 * @fileOverview
 * @author elad
 */

define(['sandbox', 'AbstractAction', './ApproveRejectAll'], function (sandbox, AbstractAction, ApproveRejectAll) {
  'use strict';



  return AbstractAction.extend({

    isApplicable: function (objs) {
      var objects = this.module.selected;
      if (typeof objects === 'undefined' || objects.length !== 1) {
        return false;
      }

      for (var i=0;i<objects.length;i++){
        var flowStepType = objects[i].flowStepType;
        var state = objects[i].state;
        return state === "queWait" && flowStepType === "workflow/step/flow/approval";
      }
      return false;
      
    },

    execute: function () {

      var objects = this.module.selected;
      if (typeof objects === 'undefined') {
        return;
      }

      if (objects.length === 0){
        return;
      }

      var items = [];
      for (var i=0;i<objects.length;i++){
        var obj = objects[i];
        items.push({nwid: obj.nwid, type: obj.type});
      }

      var actionArgs = [
        this.nwid,
        this.actionName,
        this.module.nwid,
        this.module.type,
        this.module.projectorId,
        "exec",
        {
          operation: this.actionDefinitionName === "ApprovePlanCR" ? "approve" : "reject",
          partialType: this.parameters.partialType,
          items: items
        }
      ];

      sandbox.request.execServerAction.apply(this, actionArgs);



      

    }
  });
});