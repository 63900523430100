/**
 * @name views
 * @file Views manager is in charge of loading and managing views
 *
 * @author Boris
 * @since: 2019-06-25
 */

import appPromises from 'core/managers/appPromises';

const PAGE_VIEW_CATEGORIES = ['PageView', 'FormView','FragmentView'];

let viewInfoMap;

/**
 * Initialize view map from the data received from the server.
 * View map provides quick access to the view info by view nwid. 
 */
appPromises.getPromise(appPromises.states.LOGGED_IN).then(data => {
  viewInfoMap = Object.keys(data.folders).reduce((acc, folderNwid) => {
    data.folders[folderNwid].views.reduce((acc2, view) => {
      view.folderNwid = folderNwid;
      acc2[view.nwid] = view;

      return acc2;
    }, acc);

    return acc;
  }, {});
});

/**
 * Get view class name that is defined as viewClass attribute of the view definition.
 * This class name is used to load corresponding module.
 *
 * @param  {string} nwid - view nwid
 * @return {string} - View class name
 */
function getViewClass(nwid) {
  if (!viewInfoMap[nwid]) {
    throw new Error('views.getViewClass(): no View Info found for nwid ' + nwid);
  }

  return viewInfoMap[nwid].viewClass;
}

/**
 * Get view info by the given view nwid.
 * Returns cloned view info to ensure that the original data won't be changed.
 *
 * @param  {string} nwid - view nwid
 * @return {object} - cloned view info or null if the view was not found
 */
function getViewInfo(nwid) {
  const viewInfo = viewInfoMap ? viewInfoMap[nwid] : undefined;

  return viewInfo ? { ...viewInfo } : null;
}

/**
 * Find view info in the given folder by view definition name.
 * Returns the first matched view (namely cloned view info to ensure that the original data won't be changed)
 * or null if no view was found.
 *
 * @param  {string} folderNwid - folder nwid
 * @param  {string} viewDefinitionName - view definition name
 * @param  {string} configuredClique - configured clique name
 * @return {object} - cloned view info or null if no view was found
 */
function findViewInfoByDefinitionName(folderNwid, viewDefinitionName, configuredClique) {
  let viewInfoArray = Object.values(viewInfoMap).reduce((acc, view) => {
    if (view.folderNwid === folderNwid && view.viewDefinitionName === viewDefinitionName) {
      acc.push(view);
    }

    return acc;
  }, []);

  if (configuredClique && viewInfoArray.length > 1) {
    viewInfoArray = viewInfoArray.filter(view => view.configuredClique === configuredClique);
  }

  const viewInfo = viewInfoArray[0];

  if (viewInfoArray.length > 1) {
    console.error('More than one view found with the same definition name:', viewDefinitionName);
  }

  return viewInfo ? { ...viewInfo } : null;
}

/**
 * Check whether the given view belongs to the one of the Page View categories
 *
 * @param  {string} nwid - view nwid
 * @return {boolean} - true if the view is Page View
 */
function isPageView(nwid) {
  const { category } = viewInfoMap[nwid] || {};

  return !!category && PAGE_VIEW_CATEGORIES.includes(category);
}

module.exports = {
  _name: 'views',
  _type: 'manager',
  getViewClass,
  getViewInfo,
  findViewInfoByDefinitionName,
  isPageView
};