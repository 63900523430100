import sandbox from 'sandbox';
import { getColumnsWidth } from '../utils';

const PREFERENCES_ACTIONS = ['SET_COLUMNS'];

const shouldSavePreferences = ({ type }) => PREFERENCES_ACTIONS.includes(type);

const savePreferences = module => preferences => {
  if (!preferences) {
    return;
  }

  module.preferences = Object.assign({}, module.preferences, preferences);
  return sandbox.preferences.savePreferences(module.getRequiredParameters(), module.preferences);
}

const getColumnsPreferences = ({ columns }) => {
  const columnsOrder = columns.map(column => column.columnKey);
  const fixToLeftColumnsCount = columns.filter(column => column.fixed === 'left').length;
  const hiddenColumns = columns.reduce((hiddenColumns, column) => {
    if (column.visible === false) {
      hiddenColumns[column.columnKey] = true;
    }
    return hiddenColumns;
  }, {});

  return {
    columnsOrder,
    fixToLeftColumnsCount,
    hiddenColumns
  };
};

const getColumnsWidthPreferences = ({ columns }) => {
  return {
    columnsWidth: getColumnsWidth(columns)
  };
};

export default (module, defaultPreferences = {}) => {
  module.preferences = defaultPreferences;

  return store => next => action => {

    switch (action.type) {
      case 'SET_COLUMNS':
        savePreferences(module)(getColumnsPreferences(action));
        break;
      case 'SET_COLUMNS_WIDTH':
        savePreferences(module)(getColumnsWidthPreferences(action));
        break;
    }

    return next(action);
  }
};