
import React from 'react';
import PropTypes from 'prop-types';
import PlanImportInfo from './PlanImportInfo';


function View({ model }) {

  const { planImportFileName, planImportTime } = model;

  return (
    <PlanImportInfo planImportFileName={planImportFileName} planImportTime={planImportTime} />
  );
}

View.propTypes = {
  module: PropTypes.object,
  viewModel: PropTypes.object,
};

export default View;
