/**
 * @file TextInputFilter component used in the ReactDataGrid column header
 * allows users to filter column data based on their input
 *
 * @author Boris
 * @created 2023-09-14
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import { classNames } from 'utilities/classNames';
import { SearchInput } from 'components/common/inputs';
import { SvgIcon, IconButton } from 'components/common/buttons';

export default function TextInputFilter(
  {
    value = '',
    onChange,
  }) {

  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    setVisible(true);
  };

  const handleBlur = () => {
    setVisible(false);
  };

  const handleKeyDown = (event) => {
    if (event.code === 'Enter' || event.code === 'Escape') {
      setVisible(false);
    }
  };

  const handleChange = (event, newValue) => {
    onChange(event, newValue);
  };

  return (
    <div
      className={classNames('crtx-filter-text-input', { active: visible, 'filter-applied': !!value })}
      onClick={handleClick}
    >
      {visible ?
        <SearchInput
          value={value}
          autoFocus={true}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
        /> :
        <>
          <span className='filter-content'>{value}</span>
          {value ?
            <IconButton
              className='clear-button'
              iconName={'close'}
              scale={false}
              tooltip={translate('Clear')}
              onClick={e => onChange(e, '')}
            /> :
            <SvgIcon
              name='filter_list'
              className='filter-button'
            />}
        </>
      }
    </div>
  );
}

TextInputFilter.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};