/**
 * @name Toggle Resource Action
 * @fileOverview Makes request to the server to change
 * the state of a given resource: turn on, turn off
 * @author sergey
 */

define(['sandbox', 'AbstractAction', 'logger'], function(sandbox, AbstractAction, logger) {
  'use strict';
  var log = logger.getDefaultLogger();
  return AbstractAction.extend({
    execute: function (objs) {
      // check passed arguments
      /*if (!this.assertArguments(arguments, this.execute.length)) {
        log.error('Toggle resource action: resource should be defined');
        return;
      }*/
      //TODO: same as toggle device
      var viewInstanceNwId = this.module.nwid;
      var projectorId = this.module.projectorId;
      for (var i= 0, leni = objs.length; i<leni; i++){
        sandbox.request.resource.toggle(viewInstanceNwId, this.nwid, objs[i].nwid,
            objs[i].type, projectorId, {state: null});
      }
    }
  });
});