/**
 * Created by moshemal on 5/21/16.
 */
define(['logger'],
  function (logger) {
    'use strict'
    var log = logger.getDefaultLogger();

    function objToHash(obj) {
      return JSON.stringify(obj);
    }

    function hashToObj(hashString) {
      if (!hashString) {
        return {};
      }

      if (hashString === '#viewer' || hashString === '#test') {
        return {
          runningMode: hashString.slice(1)
        };
      }

      let decodedHashString = decodeURIComponent(hashString);
      if (decodedHashString[0] === "#") {
        decodedHashString = decodedHashString.slice(1);
      }

      try {
        return JSON.parse(decodedHashString);
      } catch (err) {
        log.warn(err);
        return {};
      }
    }

    function getHashObj() {
      var hash = window.location.hash;
      return hashToObj(hash);
    }

    return {
      objToHash: objToHash,
      hashToObj: hashToObj,
      getHashObj: getHashObj
    }
  });
