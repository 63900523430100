/**
 * Created by moshem on 12/16/13.
 */
define([], function () {
  'use strict';

  function observe(O, callback){
    Object.observe(O, callback);
  }

  function unobserve(O, callback){
    Object.unobserve(O, callback);
  }

  return {
    _name: 'observer',
    _type: 'worker',

    observe:    observe,
    unobserve:  unobserve
  };
});