import { translate } from 'core/services/localization';
import { getGeneralIcon, getModuleIcon } from 'core/services/iconService';

export const WAITING = 'Waiting';
export const SUCCESS = 'Success';
export const ERROR = 'Error';
export const NOT_ASSIGNED = 'NotAssigned';
export const PROCESSING = 'Processing';
export const UNPLANNED = 'Unplanned';
export const WAITING_FOR_APPROVAL = 'waiting_for_approval';
export const HOLD = 'Hold';

export const COLORS_BY_STATUS = {
  [WAITING]: '#ffcd35',
  [SUCCESS]: '#3aea4b',
  [ERROR]: '#f84545',
  [NOT_ASSIGNED]: '#fff',
  [UNPLANNED]: '#aaa',
  [WAITING_FOR_APPROVAL]: '#5eced5',
  [PROCESSING]: '#ffcd35'
  //[HOLD]: '#ccc'
};

export const STATUS_FILTER_OPTIONS = [
  {
    value: ERROR,
    data: { icon: getGeneralIcon('status', 'error'), title: translate('Error') }
  },
  {
    value: WAITING,
    data: { icon: getGeneralIcon('status', 'wait'), title: translate('Waiting') }
  },
  {
    value: HOLD,
    data: { icon: getGeneralIcon('status', 'hold'), title: translate('Hold') }
  },
  {
    value: SUCCESS,
    data: { icon: getGeneralIcon('status', 'finished'), title: translate('Finished') }
  },
  {
    value: PROCESSING,
    data: { icon: getGeneralIcon('status', 'in_progress'), title: translate('In progress') }
  },
];

export const PREFLIGHT_INDICATION_OPTIONS = [
  {
    value: 1,
    data: { icon: getModuleIcon('MyBirdeye', 'preflight_report_warning', '.svg'), title: translate('Warning') }
  },
  {
    value: 2,
    data: { icon: getModuleIcon('MyBirdeye', 'preflight_report_error', '.svg'), title: translate('Error') }
  },
];


const ERROR_OPT = {
  value: ERROR,
  data: { icon: getGeneralIcon('status', 'error'), title: translate('Error') }
};

const WAITING_OPT = {
  value: WAITING,
  data: { icon: getGeneralIcon('status', 'in_progress'), title: translate('In progress') }
};

const WAITING_FOR_APPROVAL_OPT = {
  value: WAITING_FOR_APPROVAL,
  data: { icon: getGeneralIcon('status', 'waiting_for_approval'), title: translate('Waiting for approval') }
};

const NOT_ASSIGNED_OPT = {
  value: NOT_ASSIGNED,
  data: { icon: getGeneralIcon('status', 'wait'), title: translate('Not in system') }
};

const NOT_ASSIGNED_SITE_OPT = {
  value: NOT_ASSIGNED,
  data: { icon: getGeneralIcon('status', 'wait'), title: translate('Not in Tx') }
};

const SUCCESS_OPT = {
  value: SUCCESS,
  data: { icon: getGeneralIcon('status', 'finished'), title: translate('Finished') }
};

export const STATUS_TIME_FILTER_OPTIONS = [
  ERROR_OPT,
  WAITING_OPT,
  WAITING_FOR_APPROVAL_OPT,
  NOT_ASSIGNED_OPT,
  SUCCESS_OPT
];

export const SITE_FILTER_OPTIONS = [
  ERROR_OPT,
  WAITING_OPT,
  NOT_ASSIGNED_SITE_OPT,
  SUCCESS_OPT,
];

export const BW_OPT = {
  value: 'bw',
  data: { icon: getModuleIcon('TableView', 'bw'), title: translate('Black and white') }
};

export const COLOR_OPT = {
  value: 'color',
  data: { icon: getModuleIcon('TableView', 'color'), title: translate('Color') }
};

export const PAGE_COLOR_OPTIONS = [
  BW_OPT,
  COLOR_OPT,
];

export const LOCAL_OPTIONS = [
  {
    value: 'local',
    data: { columnData: translate('Local') }
  }
];

export const HAS_PLATES_OPTIONS = [
  {
    value: 'hasPlates',
    data: { columnData: translate('Has Plates') }
  }
];

export const TOOLBAR_BUTTONS_TO_COLUMN_KEYS_MAP = {
  'localPages': 'local',
  'missingPages': 'statustime',
  'errorPages': 'statustime',
  'waitingForApprovalPages': 'statustime',
  'expectedPlatesPages': 'hasPlates',
};

export const COLUMN_KEYS_TO_TOOLBAR_BUTTONS_MAP = {
  'local': 'localPages',
  'statustime': ['missingPages', 'errorPages', 'waitingForApprovalPages'],
  'hasPlates': 'expectedPlatesPages',
};

export const TOOLBAR_BUTTONS_TO_COLUMN_FILTER_VALUES_MAP = {
  'localPages': 'local',
  'missingPages': 'NotAssigned',
  'errorPages': 'Error',
  'waitingForApprovalPages': 'waiting_for_approval',
  'expectedPlatesPages': 'hasPlates'
};

export const COLUMN_FILTER_VALUES_TO_TOOLBAR_BUTTONS_MAP = {
  'local': 'localPages',
  'NotAssigned': 'missingPages',
  'Error': 'errorPages',
  'waiting_for_approval': 'waitingForApprovalPages',
  'hasPlates': 'expectedPlatesPages'
};

export const THROTTLE_WAIT = 1000;

export const VIEW_MODEL_LABELS = {
  'page': translate('Pages'),
  'page/separation': translate('Page Separations'),
  'form': translate('Formes'),
  'form/separation': translate('Forme Separations'),
};
