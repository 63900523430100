'use strict';
require('jquery');
require('handlebars');
const router = require('core/services/router');

function isV_1_8_1 () {
  let viewsWithThisVersion = ["LayoutManager", "PlanningWizard"];
  let hashObj = router.getHashObj();
  return (hashObj &&
    hashObj.main && hashObj.main[0] &&
    viewsWithThisVersion.indexOf(hashObj.main[0].viewClass) >= 0);
}

var promise = new Promise(function(resolve/*, resolve*/){
  var waitForChunk;
  if (isV_1_8_1()) {
    waitForChunk = require('bundle-loader!ember-1.8.1');
  } else {
    waitForChunk = require('bundle-loader!ember-1.6.1');
  } 
  waitForChunk((ember) => {
    module.exports = ember;
    resolve (ember);
  });
});


module.exports = promise;

