/**
 * @name TreeDataAdapter
 * @file Inherits from TreeDataAdapter of BaseTreeView and adds specific to the Production Runs functionality.
 *
 * @author Boris
 * @since: 2019-08-12
 */

import {toLocaleDate} from 'core/services/localization';
import TreeDataAdapterBase from "../BaseTreeView/TreeDataAdapter";
import {ERROR_STATE, FINISHED_STATE, IN_PROGRESS_STATE} from "../BaseTreeView/constants";
import settingsManager from 'core/managers/settings';
import {getFolderCurrentDate} from 'core/dates';

const MAX_HOURS = 100000;
const MSEC_IN_MINUTE = 60 * 1000;
const MSEC_IN_HOUR = 60 * MSEC_IN_MINUTE;

export default class TreeDataAdapter extends TreeDataAdapterBase {
  buildNode(item) {
    const node = super.buildNode(item);

    if (node.type === 'productionrun') {
      node.leadingIcons = this.getRunLeadingIcons(item);
      node.presetDate = item.presetDate;
      node.trailingText = toLocaleDate(node.presetDate, settingsManager.getProductionRunDateFormat());
    }

    return node;
  }

  isWaitingForApproval(item) {
    let waitingForApproval = false;

    if (item.type === 'productionrun') {
      const zoneAggregate = (item.zone || {}).aggregate || {};
      waitingForApproval = Number(zoneAggregate.totalWaitingForApprovalPages) > 0 || Number(zoneAggregate.totalWaitingForApprovalForms) > 0;
    } else {
      waitingForApproval = super.isWaitingForApproval(item);
    }

    return waitingForApproval;
  }

  getRunLeadingIcons(item) {
    const runAggregate = item.aggregate;
    const zoneAggregate = (item.zone || {}).aggregate || {};

    const leadingIcons = [
      this.getSvgIcon(this.getRunAggregationState(zoneAggregate, 'pages'), 'pages', undefined, Number(zoneAggregate.totalWaitingForApprovalPages) > 0),
      this.getSvgIcon(this.getRunAggregationState(zoneAggregate, 'forms'), 'forms', undefined, Number(zoneAggregate.totalWaitingForApprovalForms) > 0),
      this.getSvgIcon(this.getRunAggregationState(runAggregate, 'plates'), 'plates'),
    ];

    return leadingIcons;
  }

  getRunAggregationState(aggregate, type) {
    let aggState;

    const agg = aggregate || {};
    if (type === 'pages') {
      aggState = this.getAggregationState({
        total: agg.totalPages,
        finished: agg.totalFinishedPages,
        error: agg.totalErrorPages,
        inProgress: agg.totalInProcessPages
      });
    } else if (type === 'forms') {
      aggState = this.getAggregationState({
        total: agg.totalForms,
        finished: agg.totalFinishedForms,
        error: agg.totalErrorForms,
        inProgress: agg.totalInProcessForms
      });
    } else if (type === 'plates') {
      aggState = this.getAggregationState({
        total: agg.totalPlates,
        finished: agg.totalFinishedPlates,
        error: agg.totalErrorPlates,
        inProgress: agg.totalPlatesInProgress
      });
    }

    return aggState;
  }

  getAggregationState(aggregate) {
    let state = 'none';

    if (Number(aggregate.error) > 0) {
      state = ERROR_STATE;
    } else if (Number(aggregate.finished) >= Number(aggregate.total)) {
      state = FINISHED_STATE;
    } else if (Number(aggregate.inProgress) > 0 || Number(aggregate.finished) > 0) {
      state = IN_PROGRESS_STATE;
    }

    return {...aggregate, state};
  }

  getInitialFilterOptions(data) {
    if (data && data.preferences) {
      return {hoursBefore: data.preferences.hoursBefore, hoursAfter: data.preferences.hoursAfter};
    }
  }

  filterChildNodes(node) {
    if (!node.children || node.children.length <= 0) {
      return node;
    }

    node = {...node}; // we do not want to change the source node
    const filterOptions = this.getFilterOptions();
    const children = node.children.filter(node => {
      if (node.type !== 'productionrun') {
        return true;
      }

      const strings = (filterOptions.name || '').trim().toLowerCase().split(/\s+/);
      const nodeText = node.text.toLowerCase();

      const now = getFolderCurrentDate();
      const timeBefore = now.getTime() - (filterOptions.hoursBefore || MAX_HOURS) * MSEC_IN_HOUR;
      const timeAfter = now.getTime() + (filterOptions.hoursAfter || MAX_HOURS) * MSEC_IN_HOUR;
      const time = node.presetDate.getTime();

      return strings.every(s => nodeText.indexOf(s) >= 0) && timeBefore <= time && timeAfter >= time;
    });

    node.children = children.map(child => this.filterChildNodes(child));

    return node;
  }

  isFilterActive() {
    const filterOptions = this.getFilterOptions();

    return !!(filterOptions.name || filterOptions.hoursBefore || filterOptions.hoursAfter);
  }
}
