import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SimpleFormContainer from './SimpleFormContainer';

export default SimpleFormContainer(class extends Component {
  static propTypes = {
    //store - The main store of the SimpleForm.
    store: PropTypes.object,
    className: PropTypes.string
  };

  render() {
    const { style, className, children } = this.props;
    const formClassName = ['simple-form', className].join(' ');

    return (
      <div className={formClassName} style={style}>
        {children}
      </div>
    );
  }
});

