/**
 * @name
 * @fileOverview
 * @author sergey
 */
define(['ember', 'ember_table'], function (Ember) {
  'use strict';
  return Ember.Table.Row.extend({
    children: null,
    parent: null,
    isCollapsed: false,
    indentationSpacing: 20,
    isLeaf: Ember.computed(function () {
      return this.get('children.length') === 0;
    }).property('children'),
    isShowing: Ember.computed(function () {
      var parent;
      parent = this.get('parent');
      if (!parent) {
        return true;
      }
      return this.get('parent.isShowing') && !this.get('parent.isCollapsed');
    }).property('parent.isShowing', 'parent.isCollapsed'),
    indentation: Ember.computed(function () {
      var parent, spacing;
      parent = this.get('parent');
      spacing = this.get('indentationSpacing');
      if (!(parent && spacing)) {
        return 0;
      }
      return parent.get('indentation') + spacing;
    }).property('indentationSpacing', 'parent.indentation')
  });
});