import { startInternalModule } from 'core/managers/module';
import AbstractAction from 'AbstractAction';

function openCodeEditorSetup(scriptData, model, flowStepModule, scriptName, afterSavingCallback, isViewOnly) {
  const {moduleName, windowWidth, windowHeight} = this.parameters;
  const startParameters = {
    rootId: flowStepModule.model.nwid,
    rootType: 'flowstep',
    name: 'Script Editor',
    target: 'dialog',
    windowWidth,
    windowHeight,
    win: this.module.win,
    model,
    scriptName,
    scriptData,
    actionNwid: this.nwid,
    actionParameters: this.parameters,
    afterSavingCallback,
    isViewOnly
  }
  startInternalModule(moduleName, startParameters, this);
}

export default AbstractAction.extend({

  execute: function (model, flowStepModule, afterSavingCallback, scriptName, isViewOnly) {
    let origin;
    let scriptData;
    if (!scriptName) {
      if (model.className === 'JavascriptAdapter' || model.className === 'flow.SwitchSelectAdapter') {
        origin = 'flowStep';
      }
      scriptData = {
        scriptType: 'javascript',
        origin
      };
    }
    openCodeEditorSetup.call(this, scriptData, model, flowStepModule, scriptName, afterSavingCallback, isViewOnly);
  }
});