define (['sandbox', 'AbstractAction', 'logger'], function (sandbox, AbstractAction, logger) {
	'use strict';
	var log = logger.getDefaultLogger ();
	return AbstractAction.extend ({

		isApplicable: function () {
			return true;
		},

		execute: function (data, submitCallback, callbackContext) {
			if (data == undefined) {
				log.error ('Upload ICC Action: No Data');
				return;
			}
			var viewInstanceNwId = this.module.nwid;
			var projectorId = this.module.projectorId;
			var deviceNwId = this.module.viewSettings.rootId;
			var type = this.module.viewSettings.rootType;
			var request = sandbox.request.uploadICCProfile (viewInstanceNwId, this.nwid, deviceNwId, type, projectorId,
				{
					iccFileUploadName: data[0].iccFileUploadName,
					iccFileUploadData: data[0].iccFileUploadData,
					iccFileUploadType: data[0].iccFileUploadType
				}
			);



			request.then (function (res) {
				//var code = res.statusCode;
				//if (code !== 200) {
				//	var message = res.errorMessage;
				//	if (res !== null) {
				//		sandbox.dialog.alert (message);
				//	}
				//} else {
				//	sandbox.dialog.alert (res.data.result);
				//}
				//if we have a callback call it now.
				if (typeof submitCallback === 'function'){
					return submitCallback.apply(callbackContext, [res]);
				}
			});
		}
	});
});