import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { moveItem } from 'utilities/array';
import sandbox, { jsUtils, events } from 'sandbox';
import vars from './core/vars';
import utils from './core/utils';
import Header from './Header';
// import HeaderColumn from './HeaderColumn';
import Body from './Body';
import requestAnimationFrame from './dom/requestAnimationFrame';
import ScrollableArea from './ScrollableArea';
import Rows from './Rows';
import Spinner from './Spinner';
import ResizeHandler from './ResizeHandler';
import SelectionHelper from './SelectionHelper';
import ColumnsHelper from './ColumnsHelper';
import domUtils from './dom/utils';
import scrollbars from './dom/ScrollBars';
import KEYS from './core/keys';
import DragHandleColumnHoc from './DragHandleColumnHoc';

const BUFFRED_ROWS = vars.BUFFRED_ROWS;
const RESIZE_DELAY = 50;

export default class Table extends Component {
  static propTypes = {
    headerHeight: PropTypes.number,
    headerFontSize: PropTypes.number,
    height: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    width: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    style: PropTypes.any,
    /* autoHeight - (true/false) if true the table will grow with content if false the table will be in fixed height */
    autoHeight: PropTypes.bool,
    showSpinner: PropTypes.bool,
    spinnerMessage: PropTypes.string,
    rowHeight: PropTypes.number,
    rows: PropTypes.any,
    columns: PropTypes.array,
    columnKey: PropTypes.any,
    minColumnWidth: PropTypes.number,
    fixed: PropTypes.bool,
    resizableColumns: PropTypes.bool,
    virtualScroll: PropTypes.bool,
    selectableRows: PropTypes.bool,
    multiSelect: PropTypes.bool,
    selectedRows: PropTypes.array,
    isImmutable: PropTypes.bool,
    win: PropTypes.object,
    onColumnResize: PropTypes.func,
    onRowClick: PropTypes.func,
    onDoubleClickRow: PropTypes.func,
    onRowContextMenu: PropTypes.func,
    onDragOver: PropTypes.func,
    onDragLeave: PropTypes.func,
    onDragStart: PropTypes.func,
    onDragHandleDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onSelect: PropTypes.func,
    shouldRowUpdate: PropTypes.func,
    getRowClassName: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.array
    ]),
    onColumnsFilter: PropTypes.func,
    onColumnsOrder: PropTypes.func,
    filtersEnabled: PropTypes.bool,
    onToggleFiltersClick: PropTypes.func,
    showFilterOptionInHeaderMenu: PropTypes.bool,
    allowRowsReorder: PropTypes.bool,
    borderSelection: PropTypes.bool,
  };

  static defaultProps = {
    // headerHeight: 24,
    headerFontSize: 14,
    height: 'auto',
    width: 'auto',
    style: {},
    autoHeight: false,
    showSpinner: false,
    spinnerMessage: '',
    rowHeight: 20,
    rows: [],
    columnKey: null,
    minColumnWidth: 1,
    fixed: false,
    resizableColumns: true,
    virtualScroll: false,
    selectableRows: true,
    multiSelect: true,
    isImmutable: false,
    win: window,
    onColumnResize: () => {
    },
    onRowClick: () => {
    },
    onDoubleClickRow: () => {
    },
    onRowContextMenu: () => {
    },
    onSelect: () => {
    },
    onDeleteKey: () => {
    },
    shouldRowUpdate: () => false,
    showFilterOptionInHeaderMenu: false,
    allowRowsReorder: false,
    borderSelection: false,
  };

  addDragHandleColumn = () => {
    const { children, onDragHandleDragStart } = this.props;
    children.unshift(DragHandleColumnHoc(onDragHandleDragStart));
  };

  constructor(props) {
    super(props);
    var { width, height, children, allowRowsReorder } = props;
    var currentWidth = width === 'auto' ? 0 : width;
    var currentHeight = height === 'auto' ? 0 : height;
    if (allowRowsReorder) {
      this.addDragHandleColumn();
    };
    var columns = ColumnsHelper.getInitialColumns(children);

    this.state = {
      currentWidth: currentWidth,
      currentHeight: currentHeight,
      bodyHeight: 0,
      visibleRows: 0,
      rowsInPage: 1,
      scrollTop: 0,
      scrollLeft: 0,
      selectedRows: [],
      selectedRowsObj: {},
      hoverRowIndex: -1,
      dragOverRowIndex: false,
      lastClickIndex: -1,
      columns: columns,
      filterableColumns: children.filter(utils.filterableColumns(columns)),
      headerScrollRightPadding: 0,
      textSelectable: true,
    };
  }

  setVisibleColumnsState = () => {
    const children = React.Children.toArray(this.props.children);
    var columns = this.state.columns;

    children.forEach((child) => {
      columns = ColumnsHelper.updateColumn(
        columns,
        child.props.columnKey,
        'visible',
        child.props.visible
      );
      //columns[child.props.columnKey].visible = child.props.visible
    });

    this.setState({
      columns
    });
  };

  setDataGridStateSize = () => {
    const { autoHeight, width, height, rowHeight, minColumnWidth, fixed, virtualScroll, rows, children } = this.props;

    const domNode = ReactDOM.findDOMNode(this);
    const currentWidth = width === 'auto' ? domNode.clientWidth : width;
    const currentHeight = height === 'auto' ? domNode.clientHeight : height;

    if (currentWidth <= 0 || currentHeight <= 0) {
      return;
    }

    const columns = { ...this.state.columns };
    if (fixed) {
      ColumnsHelper.adjustColumnsWidth(columns, children, currentWidth, minColumnWidth);
    }

    const bodyNode = ReactDOM.findDOMNode(this.refs.tableBody);
    const rowsContainerNode = ReactDOM.findDOMNode(this.refs.rows);
    const bodyHeight = bodyNode.clientHeight;
    const visibleRows = Math.ceil(bodyHeight / rowHeight);
    const rowsContainerWidth = rowsContainerNode.clientWidth;

    this.setState({
      currentWidth: currentWidth,
      currentHeight: currentHeight,
      bodyHeight: bodyHeight,
      visibleRows: visibleRows,
      rowsInPage: virtualScroll || !autoHeight ? visibleRows + BUFFRED_ROWS : rows.length,
      columns,
      headerScrollRightPadding: currentWidth > rowsContainerWidth ? scrollbars.getWidth() : 0
    });
  };

  setDataGridStateSizeOnResize = jsUtils.throttle(this.setDataGridStateSize, RESIZE_DELAY, { leading: false, trailing: true });

  _onBodyScroll = (scrollTop, scrollLeft) => {
    this.refs.rightFixedRowsContainer.scrollTop = scrollTop;
    this.refs.leftFixedRowsContainer.scrollTop = scrollTop;
    ReactDOM.findDOMNode(this.refs.header).scrollLeft = scrollLeft;

    if (scrollTop !== this.state.scrollTop || scrollLeft !== this.state.scrollLeft) {
      this.setState({
        scrollTop,
        scrollLeft
      });
    }
  };

  resizeColumns = (index, columnKey, width) => {
    const { fixed, minColumnWidth, children } = this.props;
    const { columns, currentWidth } = this.state;

    const nextColumn = utils.getNextColumn(children, columns, index);
    const nextColumnKey = nextColumn ? nextColumn.props.columnKey : undefined;
    const minWidth = ColumnsHelper.calcRealMinColumnWidth(columns, currentWidth, minColumnWidth);
    const adjustedColumns = ColumnsHelper.adjustNextColumnWidth(
      columns,
      columnKey,
      nextColumnKey,
      width,
      minWidth,
      fixed
    );

    return {
      ...columns,
      ...adjustedColumns
    };
  };

  columnResizeHandler = (index, columnKey, width) => {
    const columns = this.resizeColumns(index, columnKey, width);

    if (columns) {
      this.setState({ columns });
    }
  };

  columnResizeEndHandler = (index, columnKey, width) => {
    const { onColumnResize } = this.props;

    const columns = this.resizeColumns(index, columnKey, width);

    if (columns) {
      this.setState({ columns });
      onColumnResize(columns, columnKey);
    }

  };

  columnResizeHandlerDoubleClick = (columnIndex, columnKey) => {
    const { children, minColumnWidth, fixed, onColumnResize } = this.props;
    const { columns, currentWidth } = this.state;

    const nextColumn = utils.getNextColumn(children, columns, columnIndex);
    if (fixed && !nextColumn) {
      return;
    }

    const minWidth = ColumnsHelper.calcRealMinColumnWidth(columns, currentWidth, minColumnWidth);

    const resizedColumns = ColumnsHelper.columnFitContentWidth(
      children,
      columns,
      columnIndex,
      columnKey,
      ReactDOM.findDOMNode(this),
      minWidth,
      fixed
    );

    this.setState({
      columns: resizedColumns
    });

    onColumnResize(resizedColumns, columnKey);
  };

  scrollToIndex = (index) => {
    var {
      rows,
      rowHeight,
      headerHeight
    } = this.props;
    var {
      scrollTop,
      scrollLeft,
      bodyHeight,
      visibleRows
    } = this.state;
    var scrollableAreaNode = this.refs.ScrollableArea;
    var contentHeight = rows.length * rowHeight;
    var topVisibleIndex = Math.ceil(scrollTop / rowHeight);
    var bottomVisibleIndex = Math.floor((topVisibleIndex - 1) + (bodyHeight / rowHeight));
    var top = scrollTop;

    if (index <= topVisibleIndex) {
      top = index * rowHeight;
    }
    else if (index >= bottomVisibleIndex) {
      top = (index * rowHeight) - ((visibleRows - 2) * rowHeight);
    }

    top = Math.min(top, contentHeight);
    top = Math.max(top, 0);

    scrollableAreaNode.scrollTo(top, scrollLeft);
  };

  setSelectedRows = (selectedRows) => {
    this.setState({
      selectedRowsObj: SelectionHelper.getRowsColumnKeys(selectedRows, this.props.columnKey),
      selectedRows
    });
  };

  selectRowAt = (index, shouldRowAddToSelection) => {
    const { onSelect, rows, columnKey } = this.props;
    const { selectedRows } = this.state;
    const newSelectedRows = shouldRowAddToSelection === true ?
      (rows.length > 0 ?
        [...selectedRows, rows[index]] :
        [...selectedRows]) :
      (rows.length > 0 ?
        [rows[index]] :
        []);

    let selectedRowsObj = SelectionHelper.getRowsColumnKeys(newSelectedRows, this.props.columnKey);

    this.setState({
      selectedRows: newSelectedRows,
      selectedRowsObj: selectedRowsObj,
      lastClickIndex: index
    });


    onSelect(newSelectedRows, SelectionHelper.getSelectedRows(
      selectedRowsObj,
      rows,
      columnKey
    ));
  };

  unselectAllRows = () => {
    var { onSelect } = this.props;

    this.setState({
      selectedRows: [],
      selectedRowsObj: {},
      lastClickIndex: -1
    });

    onSelect([]);
  };

  getSelectedRowsIndex = () => {
    return SelectionHelper.getSelectedRowsIndex(
      this.state.selectedRowsObj,
      this.props.rows,
      this.props.columnKey
    );
  };

  getSelectedRows = () => {
    return SelectionHelper.getSelectedRows(
      this.state.selectedRowsObj,
      this.props.rows,
      this.props.columnKey
    );
  };

  handleMouseEnterRow = (rowIndex, rowContent, ev) => {
    this.setState({
      hoverRowIndex: rowIndex
    });
  };

  handleMouseMoveRow = (rowIndex, rowContent, ev) => {
    if (this.state.hoverRowIndex === rowIndex) return;

    this.setState({
      hoverRowIndex: rowIndex
    });
  };

  handleMouseLeaveRow = (rowIndex, rowContent, ev) => {
    this.setState({
      hoverRowIndex: -1
    });
  };

  handleRowSelection = (rowIndex, rowContent, ev) => {
    var { columnKey, rows, onSelect, multiSelect } = this.props,
      { selectedRows, lastClickIndex, selectedRowsObj } = this.state,
      newSelectedRows = [],
      selectedKey = typeof columnKey === 'string' ? columnKey : rowIndex,
      isShiftKey = ev.shiftKey,
      isCtrlKey = ev.ctrlKey || ev.metaKey,
      selectedRowsArray;

    if (multiSelect && isShiftKey && lastClickIndex >= 0 && ev.button !== 2) {
      newSelectedRows = SelectionHelper.getMultipleSelectedRows(
        selectedRows,
        selectedRowsObj,
        rows,
        rowIndex,
        lastClickIndex,
        columnKey
      );
    }

    else if (multiSelect && isCtrlKey && ev.button !== 2) {
      if (selectedRowsObj[rowContent[selectedKey]]) {
        newSelectedRows = selectedRows.filter(row => row[selectedKey] !== rowContent[selectedKey]);
      }
      else {
        newSelectedRows = [...selectedRows, rowContent];
      }
    }
    else if (ev.button === 2 && selectedRowsObj[rowContent[selectedKey]]) {
      newSelectedRows = selectedRows;
    }
    else {
      newSelectedRows = [rowContent];
    }

    if (newSelectedRows === selectedRows) {
      return newSelectedRows;
    }

    this.setState({
      selectedRows: newSelectedRows,
      selectedRowsObj: SelectionHelper.getRowsColumnKeys(newSelectedRows, this.props.columnKey),
      lastClickIndex: rowIndex
    });

    onSelect(newSelectedRows);

    return newSelectedRows;
  };

  handleColumnFilter = (columnKey, value) => {
    const { children, fixed, minColumnWidth } = this.props;
    const { currentWidth } = this.state;

    let columns = ColumnsHelper.updateColumn(
      this.state.columns,
      columnKey,
      'visible',
      value
    );

    if (fixed) {
      ColumnsHelper.resetColumnsWidth(columns, children);
      ColumnsHelper.adjustColumnsWidth(columns, children, currentWidth, minColumnWidth);
    } else {
      const flexColumnsNewWidth = ColumnsHelper.getFlexColumnsWidth(
        currentWidth,
        columns,
        children
      );

      columns = {
        ...columns,
        ...flexColumnsNewWidth
      };
    }

    this.setState({
      columns,
      filterableColumns: children.filter(utils.filterableColumns(columns)),
    });

    this.props.onColumnsFilter(columnKey, value, columns);
  };

  handleColumnOrder = (oldIndex, newIndex) => {
    const { onColumnsOrder, children } = this.props;
    const columns = React.Children.toArray(children).map(child => child.props.columnKey);
    moveItem(columns, oldIndex, newIndex);

    onColumnsOrder(columns, oldIndex, newIndex);
  };

  onClickRow = (rowIndex, rowContent, ev) => {
    var { selectableRows, onRowClick } = this.props;

    onRowClick(rowIndex, rowContent, ev);

    if (!selectableRows) return;

    this.handleRowSelection(rowIndex, rowContent, ev);

    ev.stopPropagation();

    if (domUtils.hasClass(ev.target, 'react-data-grid-body-row') || ev.shiftKey) {
      ev.preventDefault();
    }
  };

  onDoubleClickRow = (rowIndex, rowContent, ev) => {
    var { selectableRows, onDoubleClickRow } = this.props;

    onDoubleClickRow(rowIndex, rowContent, ev);
    if (!selectableRows) return;
    this.handleRowSelection(rowIndex, rowContent, ev);

    ev.stopPropagation();
    if (domUtils.hasClass(ev.target, 'react-data-grid-body-row') || ev.shiftKey) {
      ev.preventDefault();
    }
  };

  onRowContextMenu = (rowIndex, rowContent, ev) => {
    var { selectableRows, onRowContextMenu } = this.props;

    if (!selectableRows) return;

    onRowContextMenu(
      rowIndex,
      rowContent,
      this.handleRowSelection(rowIndex, rowContent, ev),
      ev
    );

    ev.stopPropagation();
    if (domUtils.hasClass(ev.target, 'react-data-grid-body-row') || ev.shiftKey) {
      ev.preventDefault();
    }
  };

  handleDragOver = (rowIndex, rowContent, ev) => {
    var { onDragOver } = this.props;

    if (typeof onDragOver === 'function') {
      onDragOver(rowIndex, rowContent, event);
    }
  };

  handleDragLeave = (rowIndex, rowContent, ev) => {
    var { onDragLeave } = this.props;

    if (typeof onDragLeave === 'function') {
      onDragLeave(rowIndex, rowContent, ev);
    }
  };

  handleDrop = (rowIndex, rowContent, event) => {
    var { onDrop } = this.props;

    if (typeof onDrop !== 'function') return;

    onDrop(rowIndex, rowContent, event);
  };

  selectAllRows = () => {
    const { rows, columnKey, onSelect, multiSelect } = this.props;

    const { selectedRows, selectedRowsObj } = this.state;

    if (!multiSelect) return;

    const newSelectedRows = SelectionHelper.getMultipleSelectedRows(
      selectedRows,
      selectedRowsObj,
      rows,
      0,
      rows.length - 1,
      columnKey
    );

    this.setState({

      selectedRows: newSelectedRows,
      selectedRowsObj: SelectionHelper.getRowsColumnKeys(newSelectedRows, this.props.columnKey)

    });

    onSelect(newSelectedRows, rows);
  };

  onArrowKey = (keyCode, shiftKey, ev) => {
    const { lastClickIndex, selectedRows, selectedRowsObj } = this.state,
      { onSelect, columnKey, rows, multiSelect } = this.props;
    let selectedIndex,
      newSelectedRows = [],
      selectedKey;

    if (keyCode === vars.ARROW_KEYS.UP && lastClickIndex > 0) {
      selectedIndex = lastClickIndex - 1;
    }
    else if (keyCode === vars.ARROW_KEYS.DOWN && lastClickIndex < rows.length - 1) {
      selectedIndex = lastClickIndex + 1;
    }
    else {
      return;
    }

    selectedKey = typeof columnKey === 'string' ? rows[selectedIndex][columnKey] : selectedIndex;

    if (multiSelect && shiftKey) {
      if (typeof selectedRowsObj[rows[selectedIndex][columnKey]] === 'undefined') {
        newSelectedRows = [...selectedRows, rows[selectedIndex]];
      } else {
        newSelectedRows = selectedRows;
      }
    }
    else {
      newSelectedRows.push(rows[selectedIndex]);
    };

    this.setState({
      selectedRows: newSelectedRows,
      selectedRowsObj: SelectionHelper.getRowsColumnKeys(newSelectedRows, this.props.columnKey),
      lastClickIndex: selectedIndex
    });

    this.scrollToIndex(selectedIndex);

    onSelect(newSelectedRows);
  };

  onPageKey = (e) => {
    const { onSelect, columnKey, rows } = this.props;
    const { lastClickIndex, visibleRows, selectedRows } = this.state;
    let selectedIndex, selectedKey;

    if (e.keyCode === KEYS.PAGE_UP) {
      selectedIndex = Math.max(Math.max(lastClickIndex, 0) - visibleRows, 0);
    } else if (e.keyCode === KEYS.PAGE_DOWN) {
      selectedIndex = Math.min(Math.max(lastClickIndex, 0) + visibleRows, rows.length - 1);
    } else {
      return;
    }

    selectedKey = typeof columnKey === 'string' ? rows[selectedIndex][columnKey] : selectedIndex;

    const newSelectedRows = [rows[selectedIndex]];

    this.setState({
      selectedRows: newSelectedRows,
      selectedRowsObj: SelectionHelper.getRowsColumnKeys(newSelectedRows, this.props.columnKey),
      lastClickIndex: selectedIndex
    });

    this.scrollToIndex(selectedIndex);

    onSelect(newSelectedRows);
  };

  onKeyDown = (e) => {
    const { selectableRows, onDeleteKey } = this.props;
    const { textSelectable } = this.state;
    if (e.keyCode === vars.KEYS.A && (e.metaKey || e.ctrlKey) && selectableRows) {
      e.preventDefault();
      this.selectAllRows();
    }
    if ((e.keyCode === vars.ARROW_KEYS.UP || e.keyCode === vars.ARROW_KEYS.DOWN) && selectableRows) {
      this.refs.dataGridTable.focus();
      this.onArrowKey(e.keyCode, e.shiftKey, e);
    }
    if (e.keyCode === KEYS.PAGE_UP || e.keyCode === KEYS.PAGE_DOWN) {
      this.refs.dataGridTable.focus();
      this.onPageKey(e);
    } else if (e.keyCode === KEYS.DELETE && selectableRows) {
      onDeleteKey(this.state.selectedRows, e);
    }

    if (selectableRows && textSelectable && e.shiftKey) {
      this.setState({
        textSelectable: false
      });
    }
  };

  onKeyUp = (e) => {
    const { selectableRows, onDeleteKey } = this.props;
    const { textSelectable } = this.state;

    if (selectableRows && !textSelectable) {
      this.setState({
        textSelectable: true
      });
    }
  };

  handleMouseWheelScroll = (event) => {

    var node = ReactDOM.findDOMNode(this.refs.ScrollableArea);
    if (event.deltaY || event.deltaX) {
      node.scrollTop += (event.deltaY / 5) | 0;
      node.scrollLeft += (event.deltaX / 5) | 0;
    }
    else if (event.wheelDelta) {
      node.scrollTop -= event.wheelDelta > 15 ? 15 : event.wheelDelta < -15 ? -15 : (event.wheelDelta | 0);
    }
    else if (event.detail) {
      node.scrollTop += event.detail;
    }
    event.stopPropagation();
    event.preventDefault();
    event.cancelBubble = true;
    return false;

  };

  setMouseWheelHandler = () => {

    var {

      leftFixedRowsContainer,

      rightFixedRowsContainer

    } = this.refs;

    leftFixedRowsContainer.addEventListener('mousewheel', this.handleMouseWheelScroll);
    leftFixedRowsContainer.addEventListener('DOMMouseScroll', this.handleMouseWheelScroll);

    rightFixedRowsContainer.addEventListener('mousewheel', this.handleMouseWheelScroll);
    rightFixedRowsContainer.addEventListener('DOMMouseScroll', this.handleMouseWheelScroll);

  };

  removeMouseWheelHandler = () => {

    var {
      leftFixedRowsContainer,
      rightFixedRowsContainer
    } = this.refs;

    leftFixedRowsContainer.removeEventListener('mousewheel', this.handleMouseWheelScroll);
    leftFixedRowsContainer.removeEventListener('DOMMouseScroll', this.handleMouseWheelScroll);

    rightFixedRowsContainer.removeEventListener('mousewheel', this.handleMouseWheelScroll);
    rightFixedRowsContainer.removeEventListener('DOMMouseScroll', this.handleMouseWheelScroll);

  };

  autoWidthColumnsOnStart = () => {
    const { fixed, minColumnWidth, children } = this.props;
    const { columns, currentWidth } = this.state;

    let adjustedColumns = columns;

    const minWidth = ColumnsHelper.calcRealMinColumnWidth(columns, currentWidth, minColumnWidth);

    React.Children.forEach(this.props.children,
      (column, columnIndex) => {
        const { autoWidth, columnKey } = column.props;
        if (autoWidth === true)
          adjustedColumns = ColumnsHelper.columnFitContentWidth(
            children,
            columns,
            columnIndex,
            columnKey,
            ReactDOM.findDOMNode(this),
            minWidth,
            fixed
          );
      }
    );

    this.setState({
      columns: adjustedColumns
    });
  };

  scrollHandler = (event) => {
    var { target } = event;

    if (target.scrollTop !== this.refs.ScrollableArea.getScrollPosition().top) {
      target.scrollTop = this.refs.ScrollableArea.getScrollPosition().top;
    }
  };

  getTotalColumnsWidth = (columns) => {
    return Object.keys(columns).reduce(function (prevValue, currentValue) {
      return prevValue += columns[currentValue].width;
    }, 0);
  };

  getColumnsByGroups = (children, columns) => {
    var visibleColumnsByGroup = {
      left: [],
      center: [],
      right: []
    };

    if (this.props.allowRowsReorder && !children.some(child => child.key === 'dragHandle')) {
      this.addDragHandleColumn();
    };

    React.Children.forEach(children, (child, index) => {
      var group = typeof child.props.fixed === 'string' ? child.props.fixed : 'center';
      var column = columns[child.props.columnKey];
      // if (utils.isVisible(column)) {
      visibleColumnsByGroup[group].push({
        column: child,
        visible: utils.isVisible(column),
        index
      });
      // }
    });

    return visibleColumnsByGroup;
  };

  hasVerticalScrollbar = (totalColumnsWidth, totalRowsHeight, currentWidth, currentHeight, autoHeight) => {
    if (autoHeight) return false;

    var extras = 0;

    if (totalColumnsWidth > currentWidth) extras = extras + scrollbars.getWidth();

    if (totalRowsHeight + extras > currentHeight) return true;

    return false;

  };

  hasHorizontalScrollbar = (totalColumnsWidth, totalRowsHeight, currentWidth, currentHeight, autoHeight) => {

    var extras = 0;

    if (totalRowsHeight + extras > currentHeight && !autoHeight) extras = extras + scrollbars.getWidth();

    if (totalColumnsWidth + extras > currentWidth) return true;

    return false;

  };

  componentDidMount() {
    var { autoHeight, width, height, rowHeight, minColumnWidth, fixed, virtualScroll, rows, children } = this.props;
    let columns = { ...this.state.columns };
    var domNode = ReactDOM.findDOMNode(this);
    var rowsContainerNode = ReactDOM.findDOMNode(this.refs.rows);
    var bodyNode = ReactDOM.findDOMNode(this.refs.tableBody);
    var currentWidth = width === 'auto' ? domNode.clientWidth : width;
    var currentHeight = height === 'auto' ? domNode.clientHeight : height;
    var bodyHeight = bodyNode.clientHeight;
    var visibleRows = Math.ceil(bodyHeight / rowHeight);
    var rowsContainerWidth = rowsContainerNode.clientWidth;
    var flexColumns;

    if (fixed) {
      ColumnsHelper.adjustColumnsWidth(columns, children, currentWidth, minColumnWidth);
    }

    const minWidth = ColumnsHelper.calcRealMinColumnWidth(columns, currentWidth, minColumnWidth);

    React.Children.forEach(this.props.children,
      (column, columnIndex) => {
        const { autoWidth, columnKey } = column.props;
        if (autoWidth === true)
          columns = ColumnsHelper.columnFitContentWidth(
            children,
            columns,
            columnIndex,
            columnKey,
            ReactDOM.findDOMNode(this),
            minWidth,
            fixed
          );
      }
    );

    this.setState({
      currentWidth,
      currentHeight,
      bodyHeight,
      visibleRows,
      rowsInPage: virtualScroll || !autoHeight ? visibleRows + BUFFRED_ROWS : rows.length,
      columns,
      headerScrollRightPadding: currentWidth > rowsContainerWidth ? scrollbars.getWidth() : 0
    });

    //this.setVisibleColumnsState();
    //this.setDataGridStateSize();
    //this.autoWidthColumnsOnStart();
    this.setMouseWheelHandler();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedRows !== this.props.selectedRows) {
      this.setSelectedRows(nextProps.selectedRows);
    }
  }

  componentDidUpdate(prevProps) {
    if (utils.rowsJustLoaded(prevProps, this.props)) {
      this.autoWidthColumnsOnStart();
    }
  }

  componentWillUnmount() {
    this.removeMouseWheelHandler();
  }

  render() {
    const {
      headerHeight,
      headerFontSize,
      width,
      height,
      style,
      autoHeight,
      showSpinner,
      spinnerMessage,
      rows,
      rowHeight,
      virtualScroll,
      columnKey,
      fixed,
      shouldRowUpdate,
      getRowClassName,
      resizableColumns,
      isImmutable,
      win,
      onColumnsFilter,
      onColumnsOrder,
      onDrop,
      children,
      filtersEnabled,
      onToggleFiltersClick,
      showFilterOptionInHeaderMenu,
      onDragStart,
      borderSelection,
    } = this.props;
    const {
      currentWidth,
      currentHeight,
      bodyHeight,
      scrollTop,
      scrollLeft,
      visibleRows,
      rowsInPage,
      selectedRows,
      hoverRowIndex,
      columns,
      filterableColumns,
      headerScrollRightPadding,
      textSelectable
    } = this.state;

    const dataGridStyle = autoHeight ? { ...style, height: undefined } : { ...style, height: style.height };
    const gridStyle = { width: fixed ? '100%' : currentWidth };
    const headerWrapperStyle = { height: headerHeight };
    const dataGridClassName = `react-data-grid ${autoHeight ? '' : 'fixed-height'}`;
    const tableClassName = `react-data-grid-table ${textSelectable ? 'crtx-text-selectable' : 'crtx-text-unselectable'}`;
    const totalColumnsWidth = ColumnsHelper.getTotalColumnsWidth(columns);
    const columnsByGroups = this.getColumnsByGroups(children, columns);
    const hasVerticalScrollbar = this.hasVerticalScrollbar(totalColumnsWidth, rows.length * rowHeight, currentWidth, bodyHeight, autoHeight);
    const hasHorizontalScrollbar = this.hasHorizontalScrollbar(totalColumnsWidth, rows.length * rowHeight, currentWidth, bodyHeight, autoHeight);
    const groupsWidth = ColumnsHelper.getGroupsWidth(children, columns);
    const fixedLeftGroupStyle = {
      width: groupsWidth.left,
      maxHeight: 'calc(100% - ' + ((hasHorizontalScrollbar ? scrollbars.getHeight() : 0)) + 'px)',
      display: columnsByGroups.left.filter(column => column.visible).length === 0 || rows.length === 0 ? 'none' : ''
    };
    const fixedRightGroupStyle = {
      right: hasVerticalScrollbar ? scrollbars.getWidth() : 0,
      width: groupsWidth.right,
      maxHeight: 'calc(100% - ' + ((hasHorizontalScrollbar ? scrollbars.getHeight() : 0)) + 'px)',
      display: columnsByGroups.right.filter(column => column.visible).length === 0 || rows.length === 0 ? 'none' : ''
    };
    const allowDragOver = typeof onDrop === 'function';

    return <div className={dataGridClassName} style={dataGridStyle}>
      <div className={tableClassName} style={gridStyle} tabIndex="0" ref="dataGridTable" onKeyDown={this.onKeyDown} onKeyUp={this.onKeyUp}>
        <div className="react-data-grid-header-wrapper" style={headerWrapperStyle}>
          <Header
            ref="header"
            height={headerHeight}
            totalColumnsWidth={totalColumnsWidth}
            groupsWidth={groupsWidth}
            columns={columns}
            columnsByGroups={columnsByGroups}
            filterableColumns={filterableColumns}
            allColumns={React.Children.toArray(children)}
            resizableColumns={resizableColumns}
            fixed={fixed}
            fontSize={headerFontSize}
            currentHeight={currentHeight}
            isTableVerticalScrollbarVisible={hasVerticalScrollbar}
            isImmutable={isImmutable}
            win={win}
            onColumnsFilter={typeof onColumnsFilter === 'function' ? this.handleColumnFilter : undefined}
            onColumnsOrder={typeof onColumnsOrder === 'function' ? this.handleColumnOrder : undefined}
            columnResizeHandler={this.columnResizeHandler}
            columnResizeEndHandler={this.columnResizeEndHandler}
            columnResizeHandlerDoubleClick={this.columnResizeHandlerDoubleClick}
            filtersEnabled={filtersEnabled}
            onToggleFiltersClick={onToggleFiltersClick}
            showFilterOptionInHeaderMenu={showFilterOptionInHeaderMenu}
          />
        </div>
        <Body ref="tableBody" /*width={bodySize.width} height={bodySize.height}*/ onDragOver={this.handleDragOver}
          onDragLeave={this.handleDragLeave}>
          <ScrollableArea ref="ScrollableArea" hasVerticalScrollbar={hasVerticalScrollbar} onScroll={this._onBodyScroll} win={win}>
            <Rows ref="rows"
              rows={rows}
              rowsLength={rows.length}
              rowHeight={rowHeight}
              scrollTop={scrollTop}
              scrollLeft={scrollLeft}
              rowsInPage={virtualScroll || !autoHeight ? rowsInPage : rows.length}
              columnsByGroups={columnsByGroups}
              columnKey={columnKey}
              columns={columns}
              totalColumnsWidth={totalColumnsWidth}
              groupsWidth={groupsWidth}
              currentWidth={currentWidth}
              hasVerticalScrollbar={hasVerticalScrollbar}
              hoverRowIndex={hoverRowIndex}
              fixed={fixed}
              virtualScroll={virtualScroll}
              selectedRows={selectedRows}
              shouldRowUpdate={shouldRowUpdate}
              getRowClassName={getRowClassName}
              isImmutable={isImmutable}
              allowDragOver={allowDragOver}
              win={win}
              onMouseEnter={this.handleMouseEnterRow}
              onMouseMove={this.handleMouseMoveRow}
              onMouseLeave={this.handleMouseLeaveRow}
              onClickRow={this.onClickRow}
              onDoubleClickRow={this.onDoubleClickRow}
              onRowContextMenu={this.onRowContextMenu}
              onDragOver={this.handleDragOver}
              onDragLeave={this.handleDragLeave}
              onDragStart={onDragStart}
              onDrop={this.handleDrop}
              borderSelection={borderSelection}
            />
            <ResizeHandler onResize={this.setDataGridStateSizeOnResize} />
          </ScrollableArea>

          <div ref="leftFixedRowsContainer" className="react-data-grid-table-body-fixed-left" style={fixedLeftGroupStyle}
            onScroll={this.scrollHandler}>
            <Rows ref="rows"
              rows={rows}
              rowsLength={rows.length}
              rowHeight={rowHeight}
              scrollTop={scrollTop}
              scrollLeft={scrollLeft}
              rowsInPage={virtualScroll || !autoHeight ? rowsInPage : rows.length}
              columnsByGroups={columnsByGroups}
              columnKey={columnKey}
              columns={columns}
              totalColumnsWidth={groupsWidth.left}
              group="left"
              groupsWidth={groupsWidth}
              currentWidth={currentWidth}
              hasVerticalScrollbar={hasVerticalScrollbar}
              hoverRowIndex={hoverRowIndex}
              fixed={fixed}
              virtualScroll={virtualScroll}
              selectedRows={selectedRows}
              shouldRowUpdate={shouldRowUpdate}
              getRowClassName={getRowClassName}
              isImmutable={isImmutable}
              allowDragOver={allowDragOver}
              onMouseEnter={this.handleMouseEnterRow}
              onMouseMove={this.handleMouseMoveRow}
              onMouseLeave={this.handleMouseLeaveRow}
              onClickRow={this.onClickRow}
              onDoubleClickRow={this.onDoubleClickRow}
              onRowContextMenu={this.onRowContextMenu}
              onDragOver={this.handleDragOver}
              onDragLeave={this.handleDragLeave}
              onDragStart={onDragStart}
              onDrop={this.handleDrop}
              borderSelection={borderSelection}
            />
          </div>

          <div ref="rightFixedRowsContainer" className="react-data-grid-table-body-fixed-right"
            style={fixedRightGroupStyle} onScroll={this.scrollHandler}>
            <Rows ref="rows"
              rows={rows}
              rowsLength={rows.length}
              rowHeight={rowHeight}
              scrollTop={scrollTop}
              scrollLeft={scrollLeft}
              rowsInPage={virtualScroll || !autoHeight ? rowsInPage : rows.length}
              columnsByGroups={columnsByGroups}
              columnKey={columnKey}
              columns={columns}
              totalColumnsWidth={groupsWidth.right}
              group="right"
              groupsWidth={groupsWidth}
              currentWidth={currentWidth}
              hasVerticalScrollbar={hasVerticalScrollbar}
              hoverRowIndex={hoverRowIndex}
              fixed={fixed}
              virtualScroll={virtualScroll}
              selectedRows={selectedRows}
              shouldRowUpdate={shouldRowUpdate}
              getRowClassName={getRowClassName}
              isImmutable={isImmutable}
              allowDragOver={allowDragOver}
              onMouseEnter={this.handleMouseEnterRow}
              onMouseMove={this.handleMouseMoveRow}
              onMouseLeave={this.handleMouseLeaveRow}
              onClickRow={this.onClickRow}
              onDoubleClickRow={this.onDoubleClickRow}
              onRowContextMenu={this.onRowContextMenu}
              onDragOver={this.handleDragOver}
              onDragLeave={this.handleDragLeave}
              onDragStart={onDragStart}
              onDrop={this.handleDrop}
              borderSelection={borderSelection}
            />
          </div>
          <Spinner show={showSpinner} message={spinnerMessage} />
        </Body>

      </div>
      <ResizeHandler onResize={this.setDataGridStateSizeOnResize} />
    </div>;
  }
}

