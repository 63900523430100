/**
 * @fileOverview
 * @author sergey
 */


import AbstractAction from 'AbstractAction';
import { restPost } from 'core/managers/rest2';
import toastService from 'core/services/toastService';
import { translate } from 'core/services/localization';

function allowChase(page) {
  let basePage = page.basePage;
  if (basePage) { //was not chased yet
    return true;
  }
  let separations = page.separations;
  if (separations) {
    for (let i = 0; i < separations.length; i++) {
      if (separations[i].baseSeparation) { //currently color chased
        return true;
      }
    }
  }
  return false;
}

function allowBlackChase(page) {
  let basePage = page.basePage;
  if (basePage) { //was not chased yet
    return true;
  }
  let separations = page.separations;
  if (separations) {
    for (let i = 0; i < separations.length; i++) {
      if (separations[i].baseSeparation) { //currently black chased
        return false;
      }
    }
  }
  return true;
}

function allowUnChase(page) {
  let basePage = page.basePage;
  if (basePage) { //was not chased yet
    return false;
  }
  return true;
}

export default AbstractAction.extend({
  isApplicable: function (objs) {
    let chasedEdition = false;
    for (let i = 0; i < objs.length; i++) {
      let chaeable = objs[i].chaseable;
      if (chaeable) {
        chasedEdition = true;
        break;
      }
    }
    if (chasedEdition) {
      let mode = this.config.mode;
      for (let i = 0; i < objs.length; i++) {
        switch (mode) {
          case 'chase':
            if (allowChase(objs[i])) {
              return true;
            }
            break;
          case 'chaseColor':
            if (allowBlackChase(objs[i])) {
              return true;
            }
            break;
          case 'unchase':
            if (allowUnChase(objs[i])) {
              return true;
            }
            break;
        }
      }
      return false;
    }

    return false;
  },

  execute: function (objs) {
    let json = { config: this.config };
    let objects = [];
    const pageNames = [];
    objs.forEach(function (o) {
      objects.push({ nwid: o.nwid, type: o.type });
      pageNames.push(o.name);
    });
    json.objects = objects;
    const toast = toastService.createToast('bottom-right', translate('Chasing pages started'), translate('Chasing the following pages: {1}', pageNames.join(', ')), 'regular', undefined, undefined, false);
    restPost(this.nwid, `/folders/${this.folderNwid}/planning-actions/chasePage`, json)
      .then(res => {
        const names = res.pages || '';
        toast.delay(true);
        toast.type('success');
        toast.title(translate('Chasing pages finished'));
        toast.message(translate('The following pages were chased: {1}', names));
      })
      .catch(err => {
        toast.delay(false);
        toast.type('error');
        toast.title(translate('Chase Pages'));
        toast.message(translate('Error chasing pages'));
      });
  }
});
