import PropTypes from 'prop-types';
import React, { Component } from 'react';

const isUndefined = o => typeof o === 'undefined';
const SEPARATION_CLASS_NAME = 'crtx-separation';

export default class Separation extends Component {
  static propTypes = {
    color: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    filled: PropTypes.bool,
    style: PropTypes.object,
    className: PropTypes.string,
    onClick: PropTypes.func
  };

  static defaultProps = {
    filled: true
  };

  render() {
    const { color, name, filled, style, className, onClick } = this.props;
    const backgroundColor = filled ? color : undefined;
    const borderColor = color;
    const separationStyle = { ...style, backgroundColor, borderColor };
    const separationClassName = isUndefined(className) ? SEPARATION_CLASS_NAME : `${SEPARATION_CLASS_NAME} ${className}`;

    return <div className={separationClassName} title={name}>
      <div className={`${SEPARATION_CLASS_NAME}-content`} style={separationStyle}></div>
    </div>;
  }
}