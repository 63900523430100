const sandbox = require('sandbox');
const ReactComponents = require('widgets/ReactComponents/src/index');
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const { List } = ReactComponents;
const { translate } = sandbox.localization;

module.exports = class DialogContent extends Component {
  static propTypes = {
    imageItems: PropTypes.array,
    selectedImageItem: PropTypes.object,
    onSelectImageItem: PropTypes.func
  };

  static defaultProps = {
    imageItems: []
  };

  constructor(props) {
    super(props);
    const { imageItems, selectedImageItem } = this.props;
    var items = imageItems.map(item => ({
      id: item.nwid,
      title: item.name
    }));
    var firstItem = { id: '', title: '--- None ---' };
    items.unshift(firstItem);
    var selectedItemId = selectedImageItem ? selectedImageItem.nwid : '';
    var imageUrl = this.getImageUrl(selectedImageItem);
    this.state = {
      items,
      selectedItemId,
      imageUrl
    }
  }

  getImageUrl = (imageItem) => {
    //console.log("getImageUrl() imageItem=", imageItem);
    var imageUrl = '#';
    if (imageItem) {
      var params = {
        nwid: imageItem.nwid,
        template: imageItem.template || 'page/content',
        action: 'full'
      };
      imageUrl = sandbox.request.getImageUrl(params, true);
    }

    //console.log("getImageUrl() imageUrl=" + imageUrl);
    return imageUrl;
  };

  handleSelectItem = (id) => {
    const imageItem = this.findImageItem(id);
    const imageUrl = this.getImageUrl(imageItem);
    this.setState({
      selectedItemId: id,
      imageUrl
    });

    this.props.onSelectImageItem(imageItem);
  };

  findImageItem = (id) => {
    const { imageItems } = this.props;

    return imageItems.find(item => item.nwid === id);
  };

  render() {
    var imagePreviewStyle = { display: this.state.imageUrl == '' || this.state.imageUrl == '#' ? 'none' : 'block' };

    return (
      <div className="crtx-select-image-dialog">
        <div className="crtx-image-list-panel">
          <List items={this.state.items}
            selectedItem={this.state.selectedItemId}
            onClick={this.handleSelectItem}
          />
        </div>
        <div className="crtx-image-preview-panel">
          <img className="crtx-image-preview"
            style={imagePreviewStyle}
            src={this.state.imageUrl}
          />
        </div>
      </div>
    );
  }
}