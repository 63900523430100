import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

class ToggleButton extends Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  static propTypes = {
    className: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    onClick: PropTypes.func
  };

  static defaultProps = {
    disabled: false
  };

  handleClick(event) {
    this.props.onClick(event);
  }

  render() {
    const { className, checked, disabled, style, onClick, children, ...props } = this.props;
    const checkedClassName = checked ? 'checked' : '';

    return <Button
      className={`crtx-toggle ${className} ${checkedClassName}`}
      style={style}
      disabled={disabled}
      onClick={this.handleClick}
    >
      {children}
    </Button>;
  }

}

export default ToggleButton;