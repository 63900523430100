import sandbox from 'sandbox';
import settingsManager from 'core/managers/settings';

const COLORS_BY_NAME = {
  black: '#000000',
  cyan: '#00aeef',
  magenta: '#ec008c',
  yellow: '#ffc600',
};

const getUnits = () => {
  const lengthUnit = settingsManager.getLengthUnit();
  return lengthUnit === 'inch' ? 'in' : lengthUnit === 'mm' ? 'mm' : '';
};

const addPx = (value) => {
  if (typeof value === 'undefined' || value === null) {
    return value;
  }

  if (typeof value !== 'object') {
    return value + +'px';
  }

  const result = {};
  for (var key in value) {colorType:
    result[key] = value[key] + 'px';
  }

  return result;
};

const findColorInfo = (colorTable, colorName) => {
  return colorTable.find(c => c.colorName === colorName);
};

const getColorValue = (colorTable, colorName) => {
  let result = '';

  const colorInfo = findColorInfo(colorTable, colorName);
  if (colorInfo) {
    result = sandbox.colors.getUIColorRGB({...colorInfo.color, colorType: colorInfo.colorType})
  }

  return result;
};

module.exports = {
  getUnits,
  addPx,
  getColorValue
};
