/**
 * Created by moshemal on 8/26/14.
 */

define(['jquery'],function(){
	'use strict';

	return {
		find: function (selector, context) {
			context = context || document;
			return $(context).find(selector);
		},
		data: function (selector, attribute) {
			return $(selector).data(attribute);
		},
		text: function (selector) {
			return $(selector).text();
		},
		before: function (selector, html) {
			$(selector).before(html);
		},
		after: function (selector, html) {
			$(selector).after(html);
		},
		append: function (selector, html) {
			return $(selector).append(html);
		},
		html: function (selector, html) {
			$(selector).html(html);
		},
		hide: function (selector) {
			$(selector).hide();
		},
		show: function (selector) {
			$(selector).show();
		},
		toggle: function (selector) {
			$(selector).toggle();
		},
		css: function (selector, key, val) {
			if (typeof val === 'undefined') {
				return $(selector).css(key);
			}
			else {
				return $(selector).css(key, val);
			}
		},
		attr: function (selector, key, val) {
			if (typeof val === 'undefined') {
				return $(selector).attr(key);
			}
			else {
				return $(selector).attr(key, val);
			}
		},
		empty: function (selector) {
			$(selector).empty();
		},
		remove: function (selector) {
			$(selector).remove();
		},
		hasClass: function (selector, className) {
			var $selector = $(selector);
			return $selector.hasClass.apply($selector, Array.prototype.slice.call(arguments, 1, arguments.length));
		},
		addClass: function (selector, cssClass) {
			$(selector).addClass(cssClass);
		},
		removeClass: function (selector, cssClass) {
			$(selector).removeClass(cssClass);
		},
		parent: function(selector) {
			return $(selector).parent();
		},
		parentsUntil: function (selector, element, filter) {
			var $selector = $(selector);
			return $selector.parentsUntil.apply($selector, Array.prototype.slice.call(arguments, 1, arguments.length));
		},
		createNode: function (html) {
			return $(html);
		},
		index: function (selector, element) {
			return $(selector).index(element);
		},
		closest: function (selector, element) {
			return $(selector).closest(element);
		},
		children: function(selector, element){
			return $(selector).children(element);
		},
		replaceWith: function(selector, newContent){
			return $(selector).replaceWith(newContent);
		},
		animate: function(selector, properties, options){
			return $(selector).animate.apply($(selector), Array.prototype.slice.call(arguments, 1, arguments.length));
		},
		contains: function(container, contained) {
			return $.contains(container, contained);
		},
		transitionend: function (selector, callback) {
			return $(selector).one('transitionend', callback);
		}
	};
});
