/**
 * @name
 * @fileOverview
 * @author sergey
 */
define(['ember', 'text!./templates/multi-selection.hbs', 'text!./templates/dropdown-item.hbs'],
function (Ember, MultiSelectionTemplate, DropdownItemTemplate) {

  'use strict';

  var MultiSelection = Ember.Component.extend({

    layout: Ember.Handlebars.compile(MultiSelectionTemplate),

    classNames: ['multi-selection-container'],

    showCounter: function () {
      return this.get('values.length') > 1;
    }.property('values.length'),

    isOpened: false,

    SelectionContainer: Ember.View.extend({
      classNames: ['selection-container'],
      valuesRepresentation: function () {
        return this.get('controller.values').join(', ');
      }.property('controller.values.@each'),
      click: function () {
				var elem = this.get('element'),
						box = elem.getBoundingClientRect()
				this.set('controller.positionBottom', box.bottom );
        this.toggleProperty('controller.isOpened');
      }
    }),
    DropdownMenu: Ember.CollectionView.extend({
      tagName: 'ul',
			attributeBindings: ['positionTop:style'],
			positionTop: function(){
				var parentTop = this.get('controller.positionBottom');
						if (parentTop){
							var viewTop = this.get('element').getBoundingClientRect().top,
									delta = parentTop - viewTop;
							return "top: " + delta + "px";
						}
				return "top: 0px";
			}.property('controller.positionBottom'),
			classNames: ['dropdown-menu'],
			classNameBindings: ['hidden'],
      contentBinding: 'controller.content',
      valuesBinding: 'controller.values',
			hidden: function () {
				return !(this.get('controller.isOpened'));
			}.property('controller.isOpened'),
      /*isVisible: function () {
        return this.get('controller.isOpened');
      }.property('controller.isOpened'),*/
      itemViewClass: Ember.View.extend({
        template: Ember.Handlebars.compile(DropdownItemTemplate),
        isSelected: function () {
          return this.get('parentView.values').contains(this.content);
        }.property('parentView.values.@each'),
        click: function () {
          if (!this.get('parentView.values').contains(this.content)) {
            this.get('parentView.values').pushObject(this.content);
            this.set('isSelected', true);
          }
          else {
            this.get('parentView.values').removeObject(this.content);
            this.set('isSelected', false);
          }
        }
      })
    }),

    Layer: Ember.View.extend({
      classNames: ['layer'],
      isVisible: function () {
        return this.get('controller.isOpened');
      }.property('controller.isOpened'),
      click: function () {
        this.set('controller.isOpened', false);
      }
    })
  });

  Ember.Handlebars.helper('multi-selection', MultiSelection);

  return MultiSelection;
});