import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import request from 'core/managers/request';
import { translate } from 'core/services/localization';
import dialogService from 'core/services/dialogService';
import dom from 'base/dom';
import LayoutEditor from 'widgets/LayoutEditor/LayoutEditor';
import LeftPanel from './LeftPanel';
import { createObjectComparator, composeComparators } from 'core/comparators';
import { createRoleComparator } from './constants';



function View({ initialModel, module, settings }) {

  const [selectedItem, setSelectedItem] = useState(null);
  const [layoutData, setLayoutData] = useState(null);
  const [layoutChanged, setLayoutChanged] = useState(false);
  const [model, setModel] = useState(initialModel);

  const comparator = composeComparators([createRoleComparator, createObjectComparator('name')]);

  const [loading, setLoading] = useState(false);

  const splitterRef = useRef(null);
  const layoutEditorRef = useRef(null);


  const handleAddOrDuplicateItem = (item, layoutGroup) => {


    if (item.elementType === 'layout') {
      const layoutGroupIndex = model.layoutGroups.findIndex(group => group.nwid === layoutGroup.nwid);
      model.layoutGroups[layoutGroupIndex].layoutLinks.push(item);
      model.layoutGroups[layoutGroupIndex].layoutLinks.sort(comparator);
    }
    else if (item.elementType === 'plate') {
      model.plates.push(item);
      model.plates.sort(createObjectComparator('name'));
    }
    else if (item.elementType === 'ink') {
      model.inks.push(item);
      model.inks.sort(createObjectComparator('name'));
    }
    else if (item.elementType === 'layoutGroup') {
      model.layoutGroups.push(item);
      model.layoutGroups.sort(createObjectComparator('name'));
    }
    setModel({ ...model });

  };

  const handleDeleteItem = (item, index, layoutGroup) => {

    if (item.elementType === 'layout') {
      const layoutGroupIndex = model.layoutGroups.findIndex(group => group.nwid === layoutGroup.nwid);

      model.layoutGroups[layoutGroupIndex].layoutLinks.splice(index, 1);

    } else if (item.elementType === 'plate') {
      model.plates.splice(index, 1);
    }
    else if (item.elementType === 'ink') {
      model.inks.splice(index, 1);
    }
    else if (item.elementType === 'layoutGroup') {
      model.layoutGroups.splice(index, 1);
    }
    setModel({ ...model });

  };

  const handleEditItem = (item, index, layoutGroup) => {

    if (item.elementType === 'layout') {
      const layoutGroupIndex = model.layoutGroups.findIndex(group => group.nwid === layoutGroup.nwid);
      model.layoutGroups[layoutGroupIndex].layoutLinks[index] = item;
      model.layoutGroups[layoutGroupIndex].layoutLinks.sort(comparator);

    } else if (item.elementType === 'plate') {
      model.plates[index] = item;
      model.plates.sort(createObjectComparator('name'));

    } else if (item.elementType === 'ink') {
      model.inks[index] = item;
      model.inks.sort(createObjectComparator('name'));

    } else if (item.elementType === 'layoutGroup') {
      model.layoutGroups[index] = item;
      model.layoutGroups.sort(createObjectComparator('name'));
    }
    setModel({ ...model });

  };

  useLayoutEffect(() => {
    if (model.formLayoutOnly) {
      return;
    }

    const $splitter = dom.find(splitterRef.current);
    $splitter.kendoSplitter({
      panes: [{ size: '300px', collapsible: true }],
    });

    return () => $splitter.data('kendoSplitter')?.destroy();
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, []);

  useEffect(() => {
    if (model.formLayoutOnly) {
      setSelectedItem({ elementType: 'form' });
      setLoading(true);

      request.loadFormLayout(model.nwid, 'form', layoutDataReceived);
    }
  }, []);

  const onUnload = useCallback(e => {
    if (layoutEditorRef.current?.isModelChanged()) {
      e.preventDefault();

      // Note: All major browsers ignore the return message and just showing their own.
      return (e.returnValue = 'Unsaved data');
    }
  }, []);

  const isModelChanged = () => {
    return layoutEditorRef.current?.isModelChanged();
  };

  const saveChanges = () => {
    return layoutEditorRef.current?.save();
  };

  const saveChangesAfterConfirm = () => {
    return new Promise((resolve, reject) => {
      if (isModelChanged()) {
        const name = selectedItem?.name || '';
        const msg = !name ? translate('Do you want to save your changes?') :
          translate('Do you want to save changes to {1}?', name);
        const title = translate('Save changes');
        dialogService.openConfirmSaveDialog(msg, title)
          .then(answer => {
            if (answer === 'yes') {
              saveChanges();
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch(() => reject());
      } else {
        resolve(false);
      }
    });
  };

  const layoutDataReceived = (data) => {
    setLayoutData(data);
    setLoading(false);
  };

  const handleChangeLayout = (layoutNwid) => {
    setLoading(true);
    setLayoutData(null);
    setLayoutChanged(true);

    request.loadLayout(model.folderNwid, layoutNwid, layoutDataReceived);
  };

  const handleSelect = (item) => {

    if (item) {
      saveChangesAfterConfirm().then(() => {
        setLayoutData(null);
        setSelectedItem(item);

        if (item.elementType === 'layout') {
          setLoading(true);
          request.loadLayout(module.viewSettings.rootId, item.nwid, layoutDataReceived);
        } else if (item.elementType === 'plate') {
          setLoading(true);
          request.loadPlate(module.viewSettings.rootId, item.nwid, layoutDataReceived);
        } else if (item.elementType === 'ink') {
          setLoading(true);
          request.loadInk(module.viewSettings.rootId, item.nwid, layoutDataReceived);
        }
      });
    } else {
      saveChangesAfterConfirm().then(() => { setSelectedItem(null); });
    }
  };

  return (
    <div ref={splitterRef} className='crtx-layout-manager crtx-form-element-full-height'>
      {!model.formLayoutOnly ?
        <div className='crtx-layout-manager-left-side'>
          <LeftPanel model={model}
            module={module}
            onSelect={handleSelect}
            selectedItem={selectedItem}
            onAddOrDuplicateItem={handleAddOrDuplicateItem}
            onDeleteItem={handleDeleteItem}
            onEditItem={handleEditItem}
          />
        </div> : null}
      <div id='layout-editor-container' className='crtx-layout-manager-main-part'>
        {loading ? 'Loading...' : null}
        {!loading && layoutData && selectedItem ?
          <LayoutEditor
            ref={layoutEditorRef}
            data={layoutData}
            settings={{
              ...settings,
              module,
              layoutType: selectedItem.elementType || 'layout',
              layoutChanged
            }}
            onChangeLayout={handleChangeLayout}
          />
          : null}
      </div>
    </div>
  );
}

View.propTypes = {
  initialModel: PropTypes.any,
  module: PropTypes.any,
  settings: PropTypes.object,
};

export default View;
