/**
 * Created by elad on 8/29/2016.
 */
const React = require('react');
const ReactDOM = require('react-dom');
const ReactSimpleSetup = require('setups/ReactSimpleSetup');
const LockEventsSetup = require('./components/LockEventsSetup');


module.exports = ReactSimpleSetup.extend({

  model: undefined,

  selected: undefined,

  onChange: function (selected) {
    this.selected = selected;
    this.render(<LockEventsSetup model={this.model} selected={this.selected} onChange={this.onChange.bind(this)} />);
  },

  dataReceived: function (model) {
    this.model = model;
    this.selected = undefined;
    this.render(<LockEventsSetup model={this.model} selected={this.selected} onChange={this.onChange.bind(this)} />);
  },

  apply: function () {
    var selected = this.selected;
    return this.model.availableResources.find(function (availableResource) {
      return availableResource.name === selected;
    });
  }

});