import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { transitionend } from 'base/dom';
import iconService from 'core/services/iconService';
import { translate } from 'core/services/localization';

const toastIcon = iconService.getGeneralIcon('toast', 'expoint');

function ToastItem({
  container,
  id,
  title = '',
  message = '',
  type = 'regular',
  imageUrl,
  visible = true,
  delay,
  defaultHeight,
  onClick,
  onClose,
  onHideComplete
}) {

  const [height, setHeight] = useState(0);
  const [opacity, setOpacity] = useState(0);
  const toastItemRef = useRef(null);
  const delayTimeoutRef = useRef(null);

  useEffect(() => {
    setTimeout(showToast, 1000 / 30);

    return () => clearHideDelay();
  }, []);

  useEffect(() => {
    if (!visible) {
      hideToast()
    }
  }, [visible]);

  useEffect(() => {
    setHideDelay(delay);
  }, [delay]);

  const transitionHeight = (height, callback) => {
    transitionend(toastItemRef.current, callback);
    setHeight(height);
  }

  const transitionOpacity = (opacity, callback) => {
    transitionend(toastItemRef.current, callback);
    setOpacity(opacity);
  }

  const clearHideDelay = () => clearTimeout(delayTimeoutRef.current);

  const setHideDelay = delay => {
    clearHideDelay();

    if (delay === false) {
      clearHideDelay();
      return;
    }
    delayTimeoutRef.current = setTimeout(() => {
      clearHideDelay();
      hideToast();
    }, delay);
  }

  const showToast = () => {
    transitionHeight(defaultHeight, () => {
      transitionOpacity(1);
      if (typeof delay === 'number') {
        setHideDelay(delay);
      }
    });
  }

  const hideToast = () => transitionOpacity(0, () => transitionHeight(0, () => onHideComplete(container, id)));

  const handleClick = e => {
    if (typeof onClick !== 'function') return;

    onClick();
    handleClose(e);
  }

  const handleClose = e => {
    onClose(container, id);
    e.preventDefault();
    e.stopPropagation();
  }

  const className = typeof onClick === 'function' ? 'toast-item-clickable' : '';
  const toastTypeClassName = `toast-type ${type}-toast`;
  const toastContentClassName = `toast-content ${type}-toast`;
  const style = { height, opacity };
  const toastTypeIconStyle = typeof imageUrl === 'undefined' || type === 'chat' || type === 'commcenter' ? {} : {
    backgroundImage: `url(${imageUrl})`
  };

  return <div ref={toastItemRef} className={`toast-item ${className}`} style={style} onClick={handleClick}>
    <div className={toastTypeClassName} style={toastTypeIconStyle}>
      {typeof imageUrl === 'undefined' && type !== 'chat' && type !== 'commcenter' ? <img className="toast-type-icon" src={toastIcon} /> : undefined}
      {typeof imageUrl === 'undefined' && type === 'chat' ? <i className='material-icons'>chat</i> : undefined}
      {typeof imageUrl === 'undefined' && type === 'commcenter' ? <i className='material-icons'>mail</i> : undefined}
    </div>
    <div className={toastContentClassName}>
      <h1 className="toast-content-header" title={translate(title)}>{translate(title)}</h1>

      <p className="toast-content-message" title={message}>{message}</p>
    </div>
    <div className="toast-close-button" onClick={handleClose}>
      <i className='material-icons'>close</i>
    </div>
  </div>;
}

ToastItem.propTypes = {
  container: PropTypes.string,
  id: PropTypes.number,
  title: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
  imageUrl: PropTypes.string,
  visible: PropTypes.bool,
  delay: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool
  ]),
  defaultHeight: PropTypes.number,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  onHideComplete: PropTypes.func
}

export default ToastItem;