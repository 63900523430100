/**
 * @file Dropdown component with search/filter capabilities.
 * The component also supports keyboard navigation, allowing users to easily navigate
 * through the options using the arrow keys.
 *
 * The getOptionProps callback function should return an object with the following properties:
 *   value - option value
 *   text - option text
 *   group - group name the option belongs to
 *   disabled - indicates whether the option is disabled
 *
 * @author Boris
 * @created 2023-07-30
 */
import React, { Fragment, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  FloatingPortal,
  FloatingFocusManager,
  autoUpdate,
  size,
  flip,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useTypeahead,
} from '@floating-ui/react';
import { classNames } from 'utilities/classNames';
import { SearchInput } from 'components/common/inputs';
import { useFloatingZIndex } from 'utilities/zindex';
import { getFloatingPortalNode } from '../utils';
import { Item } from '../components/Item';
import { SeparatorItem } from '../components/SeparatorItem';
import { DropdownArrow } from '../components/DropdownArrow';

const SEARCH_VISIBILITY_THRESHOLD = 10;

const PLACEMENT_TOP = ['top', 'top-start', 'top-end'];

export function Dropdown(
  {
    value,
    options = [],
    className,
    style,
    popoverClassName,
    popoverStyle,
    placement,
    placeholder,
    disabled = false,
    title,
    withArrow = true,
    withSearch,
    multiple = false,
    selectedAtTop = false,
    autoWidth = false,
    valueProp,
    textProp,
    getOptionProps: getOptionPropsProp,
    itemGetter,
    renderOption: renderOptionProp,
    renderContent: renderContentProp,
    onSelect,
    onOpenChange,
  }) {

  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [searchValue, setSearchValue] = useState('');
  const searchInputRef = useRef();

  const valueDict = useMemo(() => {
    if (!multiple) {
      return;
    }

    return value.reduce((acc, v) => {
      acc[v] = true;

      return acc;
    }, {});

  }, [value]);

  const getOptionProps = (option) => {
    let result;
    if (typeof getOptionPropsProp === 'function') {
      result = getOptionPropsProp(option);
    } else if (typeof option === 'object' && option !== null) {
      if (valueProp || textProp) {
        // for backward compatibility
        result = {
          value: option[valueProp || 'value'],
          text: option[textProp || 'text']
        };
      } else {
        result = option;
      }
    } else {
      result = {
        value: option,
        text: option
      };
    }

    return result;
  };

  const textToString = (text) => {
    let result = text ?? '';
    if (typeof result !== 'string') {
      result = String(result);
    }

    return result;
  };

  const getOptionText = (option) => {
    const { text } = getOptionProps(option);

    return textToString(text);
  };

  const renderOption = (option) => {
    let result;
    if (typeof renderOptionProp === 'function') {
      result = renderOptionProp(option);
    } else if (typeof itemGetter === 'function') {
      result = itemGetter(option);
    } else {
      const { text } = getOptionProps(option);
      result = <div className='option-text'>{text}</div>;
    }

    return result;
  };

  const renderContent = (selectedOptions) => {
    if (typeof renderContentProp === 'function') {
      return renderContentProp(selectedOptions);
    }

    if (!multiple) {
      return renderOption(selectedOptions[0]);
    }

    const content = selectedOptions.map(option => {
      const { text = '' } = getOptionProps(option);

      return text;
    }).join(', ');

    return (
      <div className={classNames('content-text', { placeholder: selectedOptions.length <= 0 })}>
        {selectedOptions.length > 0 ? content : placeholder}
      </div>
    );
  };

  const isOptionSelected = (optionValue) => {
    return multiple ? valueDict[optionValue] : optionValue === value;
  };

  const getSelectedOptions = () => {
    return options.filter(option => {
      const { value: optionValue } = getOptionProps(option);

      return isOptionSelected(optionValue);
    });
  };

  const getContentTitle = (selectedOptions) => {
    return title ?? selectedOptions.map(option => {
      const { text = '' } = getOptionProps(option);

      return text;
    }).join(', ');
  };

  const isSearchVisible = () => {
    if (disabled || !visible) {
      return false;
    }

    return withSearch ?? options.length > SEARCH_VISIBILITY_THRESHOLD;
  };

  const isFilterEnabled = () => {
    return isSearchVisible() && !!searchValue;
  };

  const getListContent = () => {
    if (disabled || !visible || isSearchVisible()) {
      return [];
    }

    return options.map(opt => {
      let { text, disabled: optionDisabled } = getOptionProps(opt);

      return optionDisabled ? null : textToString(text);
    });
  };

  const findOptionIndex = (optionValue) => {
    return options.findIndex(opt => getOptionProps(opt).value === optionValue);
  };

  const findActiveIndexWhenOpened = () => {
    let newActiveIndex = -1;

    if (searchValue && options.length > 0) {
      newActiveIndex = 0;
    } else if (!multiple) {
      const selectedIndex = findOptionIndex(value);
      if (selectedIndex >= 0) {
        newActiveIndex = selectedIndex;
      }
    }

    return newActiveIndex;
  };

  const getListItems = () => {
    let items = options;

    if (multiple && selectedAtTop) {
      const topOptions = [], otherOptions = [];
      for (const option of options) {
        isOptionSelected(getOptionProps(option).value) ? topOptions.push(option) : otherOptions.push(option);
      }

      items = topOptions.concat(otherOptions);
    }

    if (isFilterEnabled()) {
      items = options.filter(opt => getOptionText(opt).toLowerCase().includes(searchValue.toLowerCase()));
    }

    return items;
  };

  const items = getListItems();

  const listRef = useRef([]);
  const listContentRef = React.useRef();
  listContentRef.current = getListContent();

  const { x, y, strategy, refs, context } = useFloating({
    placement,
    whileElementsMounted: autoUpdate,
    open: visible,
    onOpenChange: (opened) => {
      let newActiveIndex = -1;
      if (opened) {
        newActiveIndex = findActiveIndexWhenOpened();
      } else {
        setSearchValue('');
      }

      setActiveIndex(newActiveIndex);
      updateVisible(opened);
    },
    middleware: [
      flip(),
      size({
        apply({ rects, availableHeight, elements }) {
          const maxHeight = `${availableHeight}px`;
          const dropdownWidth = `${rects.reference.width}px`;

          const { style } = elements.floating;
          style.maxHeight = maxHeight;
          if (!autoWidth) {
            style.width = dropdownWidth;
          }
        },
        padding: 5
      })
    ]
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useRole(context, { role: 'listbox' }),
      useDismiss(context),
      useListNavigation(context, {
        listRef,
        activeIndex,
        onNavigate: setActiveIndex,
        virtual: true,
        loop: true,
      }),
      useTypeahead(context, {
        enabled: !disabled && visible && !isSearchVisible(),
        listRef: listContentRef,
        activeIndex,
        onMatch: setActiveIndex,
      })
    ]);

  const updateVisible = (visible) => {
    setVisible(visible);

    if (!visible) {
      // Set focus back to the reference element when dropdown closes
      refs.domReference?.current?.focus();
    }

    if (typeof onOpenChange === 'function') {
      onOpenChange(visible);
    }
  };

  /**
   * Handle focus restoration when React destroys a focused dropdown option.
   * React component lifecycle can sometimes lead to focus loss when a
   * previously focused dropdown option is removed from the DOM.
   * This behavior occurs when both 'multiple' and 'selectedAtTop' flags are enabled.
   * To ensure consistent focus behavior and prevent unexpected focus loss,
   * explicitly return focus to either the Popover element or the search input (if present).
   */
  const restoreFocus = () => {
    if (isSearchVisible()) {
      searchInputRef.current?.focus();
    } else {
      refs.floating?.current?.focus();
    }
  };

  const handleChangeSearchValue = (event, newSearchValue) => {
    setSearchValue(newSearchValue);

    if (newSearchValue) {
      setActiveIndex(0);
    }
  };

  const handleSelect = (event, item, index) => {
    if (disabled) {
      return;
    }

    const { value: optionValue, disabled: optionDisabled } = getOptionProps(item);
    if (optionDisabled) {
      return;
    }

    let newValue = optionValue;
    if (multiple) {
      const selIndex = value.indexOf(optionValue);
      if (selIndex < 0) {
        newValue = value.concat(optionValue);
      } else {
        newValue = value.slice(0, selIndex).concat(value.slice(selIndex + 1));
      }

      restoreFocus();
    } else {
      setSearchValue('');
      updateVisible(false);
      setActiveIndex(-1);
    }

    onSelect(event, newValue, index);
  };

  const handleKeyDown = (event) => {
    if (disabled) {
      return;
    }

    if (event.code === 'Enter') {
      if (!visible) {
        const selectedIndex = multiple ? -1 : findOptionIndex(value);
        setSearchValue('');
        updateVisible(true);
        setActiveIndex(selectedIndex);
      } else if (activeIndex >= 0 && activeIndex < items.length) {
        handleSelect(event, items[activeIndex], activeIndex);
      }
    }
  };

  const handleMouseDown = (e) => {
    // Workaround for a Dropdown component within the Kendo Window.
    // When the Dropdown is in an open state, attempting to close the options list
    // by clicking the dropdown handler fails.
    // This situation arises because an unexpected invocation of the onOpenChange()
    // callback function causes the list to close initially.
    // Consequently, the handleClick() function unintentionally triggers
    // a reopening of the list.
    e.stopPropagation();
  };

  const handleClick = () => {
    if (disabled) {
      return;
    }

    const newVisible = !visible;
    let newActiveIndex = -1;
    if (newVisible) {
      newActiveIndex = findActiveIndexWhenOpened();
    }

    setActiveIndex(newActiveIndex);
    updateVisible(newVisible);
  };

  const portalNode = getFloatingPortalNode(context);
  const zIndex = useFloatingZIndex(portalNode, context.open);

  const selectedOptions = getSelectedOptions();

  return (
    <>
      <div
        tabIndex={!disabled ? 0 : undefined}
        className={classNames('crtx-dropdown', className, { disabled })}
        style={style}
        title={getContentTitle(selectedOptions)}
        {...getReferenceProps({
          ref: refs.setReference,
          'aria-autocomplete': 'none',
          onMouseDown: handleMouseDown,
          onClick: handleClick,
          onKeyDown: handleKeyDown,
        })}
      >
        <div className='crtx-dropdown-content'>
          {renderContent(selectedOptions)}
        </div>
        {withArrow && <DropdownArrow disabled={disabled} opened={visible} />}
      </div>
      <FloatingPortal root={portalNode}>
        {visible && (
          <FloatingFocusManager
            context={context}
          >
            <div
              {...getFloatingProps({
                ref: refs.setFloating,
                className: classNames('crtx-popover', 'crtx-dropdown-popover', popoverClassName, context.placement),
                style: {
                  ...popoverStyle,
                  position: strategy,
                  left: x ?? 0,
                  top: y ?? 0,
                  zIndex: zIndex,
                },
                onKeyDown: handleKeyDown
              })}
            >
              {isSearchVisible() &&
                <SearchInput
                  ref={searchInputRef}
                  value={searchValue}
                  style={{ order: PLACEMENT_TOP.includes(context.placement) ? '1' : '0' }}
                  onChange={handleChangeSearchValue}
                />
              }
              <ul className='crtx-dropdown-listbox'>
                {items.map((item, index, arr) => {
                  const { value: optionValue, text, disabled: optionDisabled, group } = getOptionProps(item);
                  const selected = isOptionSelected(optionValue);
                  const active = activeIndex === index;
                  let separator = false;
                  if (index > 0) {
                    const { value: prevValue, group: prevGroup } = getOptionProps(arr[index - 1]);
                    const prevSelected = isOptionSelected(prevValue);
                    if (multiple && selectedAtTop) {
                      separator = !selected && prevSelected;
                    } else {
                      separator = group !== prevGroup;
                    }
                  }

                  const itemElement = (
                    <Item
                      {...getItemProps({
                        key: `${index}${optionValue}`,
                        disabled: optionDisabled,
                        title: text,
                        ref(node) {
                          listRef.current[index] = node;
                        },
                        onClick(event) {
                          handleSelect(event, item, index);
                        }
                      })}
                      active={active}
                      selected={selected}
                    >
                      {renderOption(item)}
                    </Item>
                  );

                  return separator ?
                    <Fragment key={`${index}sep${optionValue}`}>
                      {<SeparatorItem />}
                      {itemElement}
                    </Fragment> :
                    itemElement;
                })}
              </ul>
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  );
}

Dropdown.propTypes = {
  value: PropTypes.any,
  options: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.object,
  popoverClassName: PropTypes.string,
  popoverStyle: PropTypes.object,
  placement: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  multiple: PropTypes.bool,
  selectedAtTop: PropTypes.bool,
  autoWidth: PropTypes.bool,
  withArrow: PropTypes.bool,
  withSearch: PropTypes.bool,
  // 'valueProp' is deprecated, use 'getOptionProps' function instead
  valueProp: PropTypes.string,
  // 'textProp' is deprecated, use 'getOptionProps' function instead
  textProp: PropTypes.string,
  getOptionProps: PropTypes.func,
  renderOption: PropTypes.func,
  renderContent: PropTypes.func,
  // 'itemGetter' is deprecated, use 'renderOption' function instead
  itemGetter: PropTypes.func,
  onSelect: PropTypes.func,
  onOpenChange: PropTypes.func,
};