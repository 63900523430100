import React, { Component } from 'react';
import Header from 'src/components/common/headers/Header';
import Ad from './Ad';
import PropTypes from 'prop-types';

const labels = {}

module.exports = class Colors extends Component {
  constructor(props) {
    super(props);
    this.createAdsComponents = this.createAdsComponents.bind(this);
  }

  static propTypes = {
    object: PropTypes.any,
    ads: PropTypes.any,
    openCustomInfoDialog: PropTypes.func,
  }

  createAdsComponents() {
    const { object, ads, openCustomInfoDialog } = this.props;
    const adsComponents = ads.map(function (item, index) {
      return <Ad key={index} adInfo={item} object={object} openCustomInfoDialog={openCustomInfoDialog} />
    });
    return adsComponents;
  }


  render() {
    const { object, ads, title } = this.props;
    return <div className='ads-custom-info'>
      <Header>{title}</Header>
      {this.createAdsComponents()}
    </div>;
  }

}