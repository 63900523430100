import React, { forwardRef } from 'react';
import ConversionInput from './ConversionInput';

const DEGREE_SYMBOL = '°';

function DegreesInput({ ...props }, ref) {

  return (
    <ConversionInput
      {...props}
      ref={ref}
      unitSymbol={DEGREE_SYMBOL}
    />
  );
}

const DegreesInputWithRef = forwardRef(DegreesInput);


DegreesInputWithRef.propTypes = {
  ...ConversionInput.propTypes,
};

export default DegreesInputWithRef;
