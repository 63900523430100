import React, { useState } from 'react';
import PropTypes from 'prop-types';
import List from 'components/common/list';
import { translate } from 'core/services/localization';
import request from 'core/managers/request';
import { AddEditPlateDialog } from './AddEditPlateDialog';
import { Toolbar } from './Toolbar';
import IconButton from "components/common/buttons/IconButton";
import dialogService from 'core/services/dialogService';


const labels = {
  duplicate: translate('Duplicate'),
  edit: translate('Edit'),
  delete: translate('Delete'),
  deletePlate: translate('Are you sure you want to delete this Plate? It may be used in Layouts and Ink Settings.'),
  deletePlateTitle: translate('Delete Plate'),
};

function PlatesTab({
  model,
  module,
  onSelect,
  selectedItem,
  buttonTitle,
  onAddOrDuplicateItem,
  onDeleteItem,
  onEditItem }) {

  const [visibleAddEditPlateDialog, setVisibleAddEditPlateDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [targetPlate, setTargetPlate] = useState({});




  const getPlateIndex = (plateId) => {
    return model.plates.findIndex(p => p.nwid === plateId);
  };

  const handleDeletePlateClick = (e, plate, index) => {
    e.stopPropagation();

    let msg = labels.deletePlate + "<br>" + plate.name;

    dialogService.openConfirmDialog(msg, labels.deletePlateTitle)
      .then(() => {
        request.deletePlate(module.viewSettings.rootId, plate.nwid, deletePlateResult);
      });

  };

  const deletePlateResult = (data, params) => {
    if (!data || !params) {
      return;
    }
    const plateIndex = getPlateIndex(params.nwid);

    onDeleteItem({ elementType: 'plate' }, plateIndex);

    let selectedPlateIndex = plateIndex - 1 >= 0 ? plateIndex - 1 : 0;
    onSelect(model.plates[selectedPlateIndex]);

  }

  const handleSetSelectedClick = (e, plate, index) => {
    onSelect(plate);
  };

  const handleEditPlateClick = (e, plate, index) => {
    e.stopPropagation();
    setTargetPlate(plate);
    setVisibleAddEditPlateDialog(true);
    setEditMode(true);
  };

  const handleDuplicatePlateClick = (e, plate, index) => {
    e.stopPropagation();
    request.duplicatePlate(module.viewSettings.rootId, plate.nwid, createOrDuplicatePlateResult);
  };

  const createOrDuplicatePlateResult = (plate) => {

    if (!plate) {
      return console.error("*** ERROR: cannot duplicate plate");
    }

    const plateItem = { elementType: 'plate', nwid: plate.nwid, name: plate.name };

    onAddOrDuplicateItem(plateItem);
    onSelect(plateItem);
  };

  const editPlateResult = (plate, params) => {

    if (!plate || !params) {
      return console.error("*** ERROR: cannot edit plate");
    }

    const plateIndex = getPlateIndex(plate.nwid);

    onEditItem(plate, plateIndex);
    onSelect(plate);
  };


  const handleCloseAddEditPlateDialog = () => {
    setVisibleAddEditPlateDialog(false);
    setEditMode(false);
  };

  const handleAddEditPlate = (plate) => {
    let jsonPlate = JSON.stringify(plate);

    if (!editMode) {
      request.createPlate(module.viewSettings.rootId, jsonPlate, createOrDuplicatePlateResult);
    } else {
      request.savePlateInfo(module.viewSettings.rootId, jsonPlate, editPlateResult);
    }
  };

  const handleCreateNewPlate = () => {
    setEditMode(false);
    setVisibleAddEditPlateDialog(true);
  }

  const trailingIcons = [
    {
      id: 'edit',
      title: labels.edit,
      onClick: handleEditPlateClick,
    },
    {
      id: 'content_duplicate',
      title: labels.duplicate,
      onClick: handleDuplicatePlateClick,
    },
    {
      id: 'delete_outline',
      title: labels.delete,
      onClick: handleDeletePlateClick,
    },
  ];

  const renderTrailingButtons = (node) => {
    const renderButton = (e, index) => {
      const { id, title, disabled, onClick } = trailingIcons[index];

      return (
        <IconButton
          key={id}
          iconName={id}
          tooltip={title}
          disabled={disabled}
          onClick={e => onClick(e, node, index)}
        />
      );
    };

    return (
      <div className="crtx-layout-manager-list-item-buttons">
        {trailingIcons.map(renderButton)}
      </div>
    );
  };

  const renderPlate = (plate, index) => {
    const itemClassName = selectedItem ?
      (selectedItem.nwid === plate.nwid ? 'crtx-layout-manager-list-item selected' : 'crtx-layout-manager-list-item') :
      'crtx-layout-manager-list-item';

    return (<div className={itemClassName} key={plate.nwid} onClick={(e) => handleSetSelectedClick(e, plate, index)}>
      <div className="crtx-layout-manager-list-item-content" title={plate.name}>{plate.name}</div>
      {renderTrailingButtons(plate)}
    </div>);
  };

  return (<div className='crtx-form-section-container crtx-layout-manager-list-side'>
    <Toolbar title={buttonTitle} onClick={handleCreateNewPlate}></Toolbar>
    <div className='crtx-layout-manager-list-container'>
      <div className='crtx-layout-manager-list'>
        <div>
          <List
            items={model.plates}
            renderItem={renderPlate}
          />
          {visibleAddEditPlateDialog && <AddEditPlateDialog
            title={!editMode ? translate('New Plate') : translate('Edit Plate')}
            targetPlate={targetPlate}
            onClose={handleCloseAddEditPlateDialog}
            onAddEditPlate={handleAddEditPlate}
            editMode={editMode}
          />}
        </div>
      </div>
    </div>

  </div>);
}

PlatesTab.propTypes = {
  model: PropTypes.any,
  module: PropTypes.any,
  onSelect: PropTypes.func,
  selectedItem: PropTypes.any,
  buttonTitle: PropTypes.string,
  onAddOrDuplicateItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  onEditItem: PropTypes.func,
}

export default PlatesTab;