import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import CustomPasswordInput from 'components/common/inputs/CustomPasswordInput';
import { Validator } from 'components/private/forms';
import { PASSWORD_RULE_MESSAGES, checkEmptyRule, checkMinLengthRule, checkLowerCaseRule, checkUpperCaseRule, checkNumberRule, confirmNewPassword } from 'utilities/password';
import { translate } from 'core/services/localization';
import { classNames } from 'utilities/classNames';
import settingsManager from 'core/managers/settings';

const labels = {
  password: translate('Password:'),
  oldPassword: translate('Old password:'),
  newPassword: translate('New password:'),
  confirmPassword: translate('Confirm password:'),
  confirmErrorMessage: translate("Passwords don't match"),
  authFailedMessage: translate("Old password is incorrect")
};

function PasswordReset({ model, isNewUser, disabled }, ref) {

  const policy = settingsManager.get('passwordPolicy');
  const validationMessagesCssClass = isNewUser ? 'crtx-form-full-row' : 'crtx-form-full-row crtx-form-margin-top-small';
  const emptyPasswordAllowed = policy.AllowEmptyPassword;

  const renderMessage = (visible, valid, message) => {
    return visible ?
      <div className='crtx-form-composed-element'>
        <i className={!model.isTouched ? 'material-icons material-icons-reset-password' :
          (valid ? 'material-icons material-icons-reset-password green' : 'material-icons material-icons-reset-password warning')}>
          {!model.isTouched ? 'horizontal_rule' : (valid ? 'check' : 'close')}</i>
        {message}
      </div>
      : undefined;
  }

  const handleChangePassword = (event, value) => {
    model.newPassword = value;
    model.isTouched = true;
  };

  const handleChangeConfirmedPassword = (event, value) => {
    model.confirmedPassword = value;
  }

  return (
    <React.Fragment>

      {!isNewUser && <React.Fragment>
        <label className={classNames({ disabled: disabled })} title={labels.oldPassword}>{labels.oldPassword}</label>
        <div className="crtx-form-composed-element">
          <CustomPasswordInput className={model.authFailed ? 'crtx-form-invalid-value' : ''}
            value={model.oldPassword}
            disabled={disabled}
            onChange={(event, value) => { model.oldPassword = value; }} />
          {!!model.authFailedReason && <label className='crtx-reset-password-failure-reason crtx-form-margin-left-xsmall'>{translate(model.authFailedReason)}</label>}

        </div>
      </React.Fragment>
      }

      <label className={classNames({ disabled: disabled })} title={labels.newPassword}>{isNewUser ? labels.password : labels.newPassword}</label>
      <Validator rules={!emptyPasswordAllowed && !disabled ?
        ['required',
          () => checkEmptyRule(model.newPassword),
          () => checkMinLengthRule(model.newPassword),
          () => checkLowerCaseRule(model.newPassword),
          () => checkUpperCaseRule(model.newPassword),
          () => checkNumberRule(model.newPassword)] : []} validatorIcon={model.newPassword || !emptyPasswordAllowed ? '*' : ''}>

        <CustomPasswordInput value={model.newPassword}
          onChange={handleChangePassword}
          disabled={disabled}
        />
      </Validator>

      <label className={classNames({ disabled: disabled })} title={labels.confirmPassword}>{labels.confirmPassword}</label>
      <Validator rules={model.newPassword ? ['required',
        () => model.newPassword === model.confirmedPassword] :
        [() => model.newPassword === model.confirmedPassword]}
        message={model.newPassword ? ['', labels.confirmErrorMessage] : [labels.confirmErrorMessage]}
        validatorIcon={model.newPassword || !emptyPasswordAllowed ? '*' : ''}>
        <CustomPasswordInput value={model.confirmedPassword}
          onChange={handleChangeConfirmedPassword}
          disabled={disabled}
        />
      </Validator>

      {!emptyPasswordAllowed && <div className={validationMessagesCssClass}>
        <div>
          {renderMessage(!policy.AllowEmptyPassword, checkEmptyRule(model.newPassword), PASSWORD_RULE_MESSAGES.emptyPasswordCase)}
          {renderMessage(policy.MinimumNumberOfCharacters, checkMinLengthRule(model.newPassword), PASSWORD_RULE_MESSAGES.minLengthCase())}
          {renderMessage(policy.MustContainUpperCase, checkUpperCaseRule(model.newPassword), PASSWORD_RULE_MESSAGES.upperCharacterCase)}
          {renderMessage(policy.MustContainLowerCase, checkLowerCaseRule(model.newPassword), PASSWORD_RULE_MESSAGES.lowerCharacterCase)}
          {renderMessage(policy.MustContainNumber, checkNumberRule(model.newPassword), PASSWORD_RULE_MESSAGES.numberCase)}
        </div>
      </div>
      }
    </React.Fragment >
  );
}

const PasswordResetWithRef = forwardRef(PasswordReset);

PasswordResetWithRef.propTypes = {
  model: PropTypes.any,
};

export default PasswordResetWithRef;