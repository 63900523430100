import { startInternalModule } from 'core/managers/module';
import AbstractAction from 'AbstractAction';
import {translate} from 'core/services/localization';

const setupTitle = {
  'input': translate('Input Naming Pattern Editor'),
  'output': translate('Output Naming Pattern Editor'),
  'approval': translate('Approval Naming Pattern Editor'),
  'productionrun': translate('Production Run Naming Pattern Editor'),
  'default': translate('Naming Pattern Editor')
};

function openCodeEditorSetup() {
  const {moduleName, windowWidth, windowHeight} = this.parameters;
  const saveAction = this.module.viewActions.find(a => a.actionDefinitionName === 'SaveNamingPatternActionCR');

  const startParameters = {
    name: this.selectedItem ? setupTitle[this.selectedItem.usage || 'default'] : setupTitle['default'],
    target: 'dialog',
    windowWidth,
    windowHeight,
    win: this.module.win,
    model: this.selectedItem,
    saveAction
  }

  startInternalModule(moduleName, startParameters, this);
}

export default AbstractAction.extend({

  execute: function (objs, scriptName) {
    const item = objs[0];
    if (!item || item.type === 'folder') {
      // New
      this.selectedItem = {
        nwid: null,
        type: 'folder',
        folderNwId: this.module.folderNwid,
        usage: this.module.filterName,
        scriptType: 'macros',
        namingPatterns: item.namingPatterns
      };
    } else {
      // Edit
      this.selectedItem = {
        ...item,
        namingPatterns: this.module.viewModel.namingPatterns
      };
    }

    if (!this.selectedItem.usage) {
      this.selectedItem.usage = 'output';
    }
    openCodeEditorSetup.call(this);
  }
});