/**
 * @name pubsub
 * @file Implementation of publish–subscribe messaging pattern
 *
 * @author Boris
 * @since: 2017-01-19
 */

const channels = {};
let lastId = 0;

function createChannelIfNeeded(channelName) {
  // channel is not registered yet
  if (!channels[channelName]) {
    channels[channelName] = {
      published: false,
      subscribers: {}
    };
  }

  return channels[channelName];
}

/**
 * Subscribes the callback function to the specified channel
 * Returns function to unsubscribe
 * @param {string} channelName - The channel name to subscribe to
 * @param {function} callback - The function to call when a new message is published
 * @param {bool} deliverLastData - Defines whether to deliver the last published data
 */
function subscribe(channelName, callback, deliverLastData) {
  if (typeof channelName !== 'string' || !channelName) {
    throw new Error('The channelName must be a non-empty string');
  }

  if (typeof callback !== 'function') {
    throw new Error('The callback must be a function');
  }

  const channel = createChannelIfNeeded(channelName);
  const token = 'token_' + (++lastId);
  channel.subscribers[token] = callback;

  if (deliverLastData && channel.published) {
    setTimeout(() => callback(channel.data), 0);
  }

  //console.log('pubsub.subscribe() => channelName=', channelName);

  return function unsubscribe() {
    delete channel.subscribers[token];
    //console.log('pubsub.unsubscribe() => channelName=', channelName, token);
  }.bind(this);
}

/**
 * Deletes all channel subscribes and deletes the channel itself
 * @param {string} channelName - The channel name to delete
 */
function deleteChannel(channelName) {
  if (typeof channelName !== 'string' || !channelName) {
    throw new Error('The channelName must be a non-empty string');
  }

  if (!channels[channelName]) {
    return;
  }

  const channel = channels[channelName];
  for (var token in channel.subscribers) {
    delete channel.subscribers[token];
  }
  channel.data = undefined;
  channel.published = false;
  delete channels[channelName];
}

/**
 * Deletes all channels with their subscribes
 */
function deleteAllChannels() {
  const channelNames = Object.keys(channels);
  for (const channelName of channelNames) {
    deleteChannel(channelName);
  }
}

/**
 * Publishes the channel, passing the data to it's subscribers
 * @param {string} channelName - The channel name to publish to
 * @param {any} data - The data to pass to subscribers
 */
function publish(channelName, data) {
  if (typeof channelName !== 'string' || !channelName) {
    throw new Error('Channel must be a non-empty string');
  }

  const result = [];
  const channel = createChannelIfNeeded(channelName);
  channel.data = data;
  channel.published = true;

  for (var token in channel.subscribers) {
    try {
      result.push(channel.subscribers[token](data));
    } catch (e) {
      console.error('pubsub.publish() => channel=', channelName, e.message);
      result.push(e.message);
    }
  }

  return result;
}

module.exports = {
  _name: 'pubsub',
  _type: 'manager',
  publish,
  subscribe,
  deleteAllChannels
};