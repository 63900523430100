const React = require('react');
const ReactDOM = require('react-dom');
import * as labels from './labels';
const sandbox = require('sandbox');
const PropTypes = require('prop-types');
const { Component } = React;
import { restGet } from 'core/managers/rest2';

const styles = {
	main: { height: '90%' },
};

function PlanList(props) {
	const plans = props.plans;
	const selected = props.selected;
	const onClick = props.onClick;

	const handleClick = (nwid) => {
		return (event) => {
			onClick(event, nwid);
		};
	};

	const listItems = plans.map((plan) =>
		<li key={plan.nwid}
			title={
				plan['isApproved'] ? labels.btn_tooltip_plan_approved(plan) :
					plan['isCommitted'] ? labels.btn_tooltip_plan_commited(plan) :
						labels.btn_tooltip_plan_uploaded(plan)
			}
			className={selected.contains(plan.nwid) ? 'crtx-plan-history-selected' : 'crtx-plan-history-not-selected'}
			onClick={handleClick(plan.nwid)}
		>
			<div><b>{plan['file-path'].split('\\').pop().split('/').pop()}</b></div>
			<div>
				<img src='../kernel/assets/img/module/PlanCompare/date.png' />
				&nbsp; {plan['received-time'] ? sandbox.localization.toLocaleShortDate(new Date(plan['received-time'])) : 'N/A'} &nbsp;&nbsp;
				{plan['isCommitted'] ? <img alt='committed' src='../kernel/assets/img/module/PlanCompare/commit.png' /> : ' '}
				&nbsp; {plan['isCommitted'] ? plan['import-time'] ? sandbox.localization.toLocaleShortDate(new Date(plan['import-time'])) : 'N/A' : '----------'}   &nbsp;&nbsp;
				{plan['isApproved'] ? <img alt='approved' src='../kernel/assets/img/module/PlanCompare/approved.png' /> : ''}
			</div>
		</li>
	);
	return (
		<ul className='crtx-plan-history-list' >{listItems}</ul>
	);
}

function renderChange(change, itemName) {
	let context = { 'itemName': itemName, 'change.oldValue': change.oldValue, 'change.newValue': change.newValue, 'change.changeType': change.changeType };
	switch (change.changeType) {
		case 'CHILD_NODELIST_LENGTH':
			if (/\[.+\]$/.test(itemName)) /*properties_number_change*/
				return <span className='crtx-plan-neutral' title={labels.property_number_change_title(context)}><b>{labels.property_number_change_text(context)}</b></span>;
			else /*entity_number_change*/
				return <span className='crtx-plan-neutral' title={labels.entity_number_change_title(context)}><b>{labels.entity_number_change_text(context)}</b></span>;
		case 'ENTITY_ADDED': /*entity_added*/
			return <span className='crtx-plan-positive' title={labels.entity_added_title}>+ {labels.entity_added_text} {/*{itemName} {change.newValue}*/}</span>;
		case 'ENTITY_REMOVED': /*entity_removed*/
			return <span className='crtx-plan-negative' title={labels.entity_removed_title}>- {labels.entity_removed_text} {/*{itemName} {change.oldValue}*/}</span>;
		case 'CHILD_NODELIST_SEQUENCE': /*sequence_change*/
			return <span className='crtx-plan-neutral' title={labels.sequence_change_title(context)}>? {labels.sequence_change_text(context)}</span>;
		case 'PROPERTY_ADDED': /*property_added*/
			return <span className='crtx-plan-positive' title={labels.property_added_title}>+ {labels.property_added_text}</span>;
		case 'PROPERTY_REMOVED': /*property_removed*/
			return <span className='crtx-plan-negative' title={labels.property_removed_title}>- {labels.property_removed_text}</span>;
		case 'PANORAMA_ADDED': /*to_panorama*/
			return <span className='crtx-plan-positive' title={labels.to_panorama_title(context)}> {labels.to_panorama_text(context)} </span>;
		case 'PANORAMA_REMOVED': /*to_single*/
			return <span className='crtx-plan-negative' title={labels.to_single_title(context)}> {labels.to_single_text(context)} </span>;
		// case 'panorama': 
		// 	if (change.newValue == 'panoramaType' && /\[PANORAMA\]$/.test(itemName))
		// 		return <span className='crtx-plan-neutral' title='Changed to Panorama'> <b>Changed to Panorama</b></span>;
		// 	else
		// 		return <span className='crtx-plan-neutral' title='Is no longer Panorama'> <b>Is no longer Panorama</b></span>;
		case 'TEXT_VALUE':
			if (/^page|formSeparation|pageSeparation\[/.test(itemName)) /*content_change*/
				return <span className='crtx-plan-neutral' title={labels.content_change_title}> {labels.content_change_text}</span>
			else if (/[**]?x\[/.test(itemName)) {
				return <span className='crtx-plan-neutral' title={labels.position_x_change_title(context)}> {labels.position_x_change_text(context)}</span>
			} else if (/[**]?y\[/.test(itemName)) {
				return <span className='crtx-plan-neutral' title={labels.position_y_change_title(context)}> {labels.position_y_change_text(context)}</span>
			} else if (/[**]?width\[/.test(itemName)) {
				return <span className='crtx-plan-neutral' title={labels.position_width_change_title(context)}> {labels.position_width_change_text(context)}</span>
			} else if (/[**]?height\[/.test(itemName)) {
				return <span className='crtx-plan-neutral' title={labels.position_height_change_title(context)}> {labels.position_height_change_text(context)}</span>
			}
			else /*content_change_2*/
				return <span className='crtx-plan-neutral' title={labels.content_change_2_title(context)}> {labels.content_change_2_title(context)}</span>
		case undefined:
			return undefined;
		default: /*default*/
			return <span className='crtx-plan-neutral' title={labels.default_change_title(context)}>{labels.default_change_text(context)}</span>;
	};
}

function renderChanges(changes, itemName) {

	if (!Array.isArray(changes) || changes.length == 0) return undefined;
	if (changes.length >= 2 &&
		changes.filter(change => change.changeType === 'ENTITY_ADDED').length > 0 &&
		changes.filter(change => change.changeType === 'ENTITY_REMOVED').length > 0)
		return <span className='crtx-plan-neutral' title={labels.replaced_title}> : {labels.replaced_text} </span>
	return <span>
		:
		{changes.map(c => renderChange(c, itemName))}
	</span>;
}

function renderNode(level, node) {
	const name = node.name;
	const children = node.children || [];
	const changes = node.changes;
	if (name && name.includes('productionRuns')) return undefined;
	if (name && !name.includes(']') && changes.length == 0) return children.map(n => renderNode(level + 1, n));
	return <li style={{ textIndent: 10 * level + 'px' }} className={'crtx-plan-' + node.positivity}>
		{name}
		{renderChanges(changes, name)}
		<ul className='crtx-plan-diff-node'>{children.map(n => renderNode(level + 1, n))}</ul>
	</li>;
}

function PlanDiffs(props) {
	const diffs = props.diffs;
	const source = props.source;
	const destination = props.destination;

	if (typeof diffs === 'undefined')
		if (!source || !destination) return <div>
			<div className='crtx-plan-history-info'><b>{labels.msg_select_files}</b></div>
		</div>;
		else return <div className='crtx-plan-diff-container'>
			<div className='crtx-plan-history-header'>{labels.title_changes}</div>
			<div className='crtx-plan-diff-file'><div className='crtx-plan-diff-file-label'>{labels.lbl_from}</div> {source}</div>
			<div className='crtx-plan-diff-file'><div className='crtx-plan-diff-file-label'>{labels.lbl_to}</div> {destination} </div>
			<div className='crtx-plan-history-info'><b>{labels.msg_no_change}</b></div>
		</div>;
	return <div className='crtx-plan-diff-container'>
		<div className='crtx-plan-history-header'>{labels.title_changes}</div>
		<div className='crtx-plan-diff-file'><div className='crtx-plan-diff-file-label'>{labels.lbl_from}</div> {source}</div>
		<div className='crtx-plan-diff-file'><div className='crtx-plan-diff-file-label'>{labels.lbl_to}</div> {destination} </div>
		<ul className='crtx-plan-diff-node'> {renderNode(0, diffs || {})} </ul>
	</div>;
}

module.exports = class PlanHistory extends Component {

	static propTypes = {
		store: PropTypes.any,
	}

	constructor(props) {
		super(props);
		this.handlePlanListClick = this.handlePlanListClick.bind(this);
	}

	componentDidMount() {
		this.initSplitter();
		this.initSelection();
	}


	initSplitter() {
		$('.main').kendoSplitter({
			panes: [{ size: '400px', collapsible: true }, {}]
		});
	}

	initSelection() {
		const store = this.props.store;
		const selectedItemsAfterDispatch = store.getState().selected;
		if (selectedItemsAfterDispatch.length === 2) {

			restGet('', `containers/differences/${selectedItemsAfterDispatch[0]}/${selectedItemsAfterDispatch[1]}`)
				.then(data => {
					if (data.differences.length > 0) {
						store.dispatch({
							type: 'SET_DIFFS',
							diffs: data.differences.map(item => JSON.parse(item)) || [],
							source: data.source,
							destination: data.destination
						});
					} else {
						store.dispatch({
							type: 'NO_DIFFS',
							diffs: [],
							source: data.source,
							destination: data.destination
						});
					}
				});

		}
	}

	handlePlanListClick(event, nwid) {
		const store = this.props.store;
		const selected = store.getState().selected;

		if (selected.length === 2 && !selected.contains(nwid)) return;

		store.dispatch((dispatch, getState) => {
			dispatch({ type: 'TOGGLE_PALN_SELECTED', nwid });

			const selectedItemsAfterDispatch = getState().selected;
			if (selectedItemsAfterDispatch.length === 2) {

				restGet(nwid, `containers/differences/${selectedItemsAfterDispatch[0]}/${selectedItemsAfterDispatch[1]}`)
					.then(data => {
						if (data.differences.length > 0) {
							dispatch({
								type: 'SET_DIFFS',
								diffs: data.differences.map(item => JSON.parse(item)) || [],
								source: data.source,
								destination: data.destination
							});
						} else {
							dispatch({
								type: 'NO_DIFFS',
								diffs: [],
								source: data.source,
								destination: data.destination
							});
						}
					});

			} else {
				dispatch({ type: 'EMPTY_DIFFS' });
			}
		});
	}

	render() {
		const store = this.props.store;
		const storeState = store.getState();
		const plans = storeState.plans;
		const selected = storeState.selected;
		const diffs = storeState.diffs;
		const source = storeState.source;
		const destination = storeState.destination;

		return <div className="main" style={styles.main}>
			<div className='crtx-plan-history-left'>
				<div className="crtx-plan-history-header">{labels.title_plans}</div>
				<PlanList plans={plans} selected={selected} onClick={this.handlePlanListClick} />
			</div>

			<div className='crtx-plan-history-right'>
				<PlanDiffs diffs={diffs} source={source} destination={destination} />
			</div>
		</div>
	}

};