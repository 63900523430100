/**
 * Created by tzvikas on 4/30/2015.
 */

define(['sandbox/planSetup', './../../../utils/wizardUtils', "./pairing"],
  function(PlanSetup, wizardUtils, pairing){
    'use strict';
    //wizardUtils.getSourceId()
    function getBookPages(book, mappedModel){
      var ret = [];
      var i = 0;      
      book.pages.forEach(function(sourceIdList){
        var pagesList = [];
        i++;
        sourceIdList.forEach(function(sourceId){
          var page = mappedModel[sourceId];
          page.numberInBook = i;
          pagesList.push(page);
        });
        ret.push(pagesList);
      });
      return ret;
    }

    function resolveGroups(groups){
      var ret = {};
      for (var i=0;i<groups.length;i++){
        var groupName = groups[i].name;
        ret[groupName] = groups[i];
      }
      return ret;
    }

    function validModel (model, mappedModel) {
      var that = this;
      var publication = model.publication;
      var editions = publication.editions;
      editions.forEach(function(edition){
        var zones = edition.zones;
        zones.forEach(function(zone){
          var books = zone.books;
          books.forEach(function(book){
            var rebuildForms = book.rebuildForms || false;
            delete book.rebuildForms;
            var key = "PmiForms-" + book.pmiTemplate;
            var pmiForms = zone[key] || [];
            book.pmiForms = pmiForms;

            var pages = getBookPages(book, mappedModel);
            var forms = book.forms;
            if (forms === null || typeof forms === 'undefined' || forms.length === 0){
              rebuildForms = true;
            } else {

              // make sure book pages were not changed
              var mapPagesOnBook = {};
              for (var i=0;i<book.pages.length;i++){
                for (var j=0;j<book.pages[i].length;j++){
                  mapPagesOnBook[book.pages[i][j]] = 0;
                }
              }
              for (var i=0;i<forms.length;i++){
                if (forms[i].virtual){
                  continue;
                }
                var modelPages = [];
                for (var j=0;j<forms[i].pages.length;j++){                  
                  var pageOnFormSourceId = forms[i].pages[j];
                  if (pageOnFormSourceId !== "$empty$" && !mapPagesOnBook.hasOwnProperty(pageOnFormSourceId)){

                    // form contains a page that missing from the book
                    rebuildForms = true;
                    break;
                  } else if (pageOnFormSourceId !== "$empty$" && (mappedModel[pageOnFormSourceId].virtual || mappedModel[pageOnFormSourceId].panoramaChanged)){
                    // this is new panorama page
                    rebuildForms = true;
                    break;
                  }
                  mapPagesOnBook[pageOnFormSourceId] = 1;
                  modelPages.push(mappedModel[pageOnFormSourceId]);
                  //forms[i].separations.forEach(function(sep){
                  //  neededSeps[sep.displayName] = 1;
                  //});

                  
                }
                if (rebuildForms){
                  break;
                }
                pairing.updateFormSeparations(forms[i], modelPages);
                //for (var sep in neededSeps){
                //  var found = false;
                //  for (var j=0;j<forms[i].separations.length;j++){
                //    if (forms[i].separations[j].displayName === sep){
                //      found = true;
                //      break;
                //    }
                //  }
                //  if (!found){
                //
                //  }
                //}
              }
              if (!rebuildForms){
                for (var property in mapPagesOnBook){
                  if (mapPagesOnBook[property] === 0 && !(mappedModel[property].virtual)){
                    rebuildForms = true;
                    break;
                  }
                }
              }
            }
            if (rebuildForms){
              var layoutType = book.layoutType;
              var pages = getBookPages(book, mappedModel);
              if (layoutType === '2ups'){
                var dinkyIdx = pairing.validDinkies(pages);
                if (dinkyIdx > 0){
                  pairing.createDinky(dinkyIdx, pages);
                } else if (dinkyIdx === -1 || dinkyIdx === -3){
                  for (var i=0;i<pages.length;i++) {
                    if (pages[i][0].isDinky) {
                      pages[i][0].isDinky = false;
                    }
                  }
                  dinkyIdx = pairing.validDinkies(pages);
                  pairing.createDinky(dinkyIdx, pages);
                }
              }
              var clientSettings = model.clientSettings || {formNameMode:"number"};
              var layoutInfo = model.pmtMode ? {pmtDescription: model.pmtDescription, pmtMode:true} :
                {pmtMode:false, impositions:model.impositions, groups:resolveGroups(model.groups), formNameMode:clientSettings.formNameMode};
              pairing.defaultForms(layoutInfo, book, pages, true);
            }
          });

        });
      });

    }

    return {
      validModel: validModel
    };

  })
;
