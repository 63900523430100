/**
 * Core
 * @fileOverview Assembles different managers in one core
 * @see <a href="http://addyosmani.com/largescalejavascript/">Patterns For Large-Scale JavaScript Application Architecture</a>
 * @see <a href="http://speakerdeck.com/u/addyosmani/p/large-scale-javascript-application-architecture">Large-scale JavaScript Application Architecture Slides</a>
 * @see <a href="http://addyosmani.com/blog/large-scale-jquery/">Building Large-Scale jQuery Applications</a>
 * @see <a href="http://www.youtube.com/watch?v=vXjVFPosQHw&feature=youtube_gdata_player">Nicholas Zakas: Scalable JavaScript Application Architecture</a>
 * @see <a href="http://net.tutsplus.com/tutorials/javascript-ajax/writing-modular-javascript-new-premium-tutorial/">Writing Modular JavaScript: New Premium Tutorial</a>
 * @author sergey
 */

define([
  'base',
  './managers/module',
  './managers/desktop',
  './managers/authentication',
  './managers/request',
  './managers/shortcut',
  './managers/pubsub',
  './managers/preferences',
  './facades/tick',
  './workers/observerWorker',
  './services/colorService',
  './services/commCenterService',
  './services/iconService',
  './services/dialogService',
	'./services/helpService',
  './services/localization',
  './services/clipboardService',
  './services/chatService'
],
function () {
  "use strict";

  /** @namespace core */

  var core = {
    manager: {},
    facade: {},
    worker: {},
    service: {}
  };

  for (var i = 0, leni = arguments.length; i < leni; i += 1) {
    var service = arguments[i].default || arguments[i],
        type = service._type,
        name = service._name;
    if (typeof type !== 'undefined' && typeof name !== 'undefined') {
      core[type][name] = arguments[i];
    }
  }


  return core;


});
