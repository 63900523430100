/**
 * @name
 * @fileOverview
 * @author elad
 */

import {fromServerDate} from 'core/dates';

function multiComparators(comparators) {
  return function compareMultiComparators(item1, item2) {
    return comparators.reduce(function (accResult, comparator) {
      if (typeof comparator === 'function') return accResult || comparator(item1, item2);
      return accResult || getComparatorBySortingType(comparator.type, comparator.prop)(item1, item2);
    }, 0);
  }
}

function getComparatorBySortingType(sortingType, propertyName) {
  function alphaComparator(obj1, obj2) {
    var str1 = getObjToCompare(obj1);
    var str2 = getObjToCompare(obj2);
    return compareObjects(str1, str2, true);
  }

  function alphaDecendingComparator(obj1, obj2) {
    var str1 = getObjToCompare(obj1);
    var str2 = getObjToCompare(obj2);
    return compareObjects(str1, str2, false);
  }

  function caseInsensitiveComparator(obj1, obj2) {
    var str1 = getObjToCompare(obj1).toLowerCase();
    var str2 = getObjToCompare(obj2).toLowerCase();
    return compareObjects(str1, str2, true);
  }

  function caseInsensitiveDecendingComparator(obj1, obj2) {
    var str1 = getObjToCompare(obj1).toLowerCase();
    var str2 = getObjToCompare(obj2).toLowerCase();
    return compareObjects(str1, str2, false);
  }

  function numericComparator(obj1, obj2) {
    var num1 = parseFloat(getObjToCompare(obj1));
    var num2 = parseFloat(getObjToCompare(obj2));
    if (isNaN(num1) || isNaN(num2)) {
      return 0;
    }
    return compareObjects(num1, num2, true);
  }

  function numericDecendingComparator(obj1, obj2) {
    var num1 = parseFloat(getObjToCompare(obj1));
    var num2 = parseFloat(getObjToCompare(obj2));
    if (isNaN(num1) || isNaN(num2)) {
      return 0;
    }
    return compareObjects(num1, num2, false);
  }

  function dateComparator(obj1, obj2) {
    const date1 = fromServerDate(getObjToCompare(obj1));
    const date2 = fromServerDate(getObjToCompare(obj2));
    return compareObjects(date1, date2, true);
  }

  function dateDecendingComparator(obj1, obj2) {
    const date1 = fromServerDate(getObjToCompare(obj1));
    const date2 = fromServerDate(getObjToCompare(obj2));
    return compareObjects(date1, date2, false);
  }

  function labelComparator(obj1, obj2) {
    var num1 = getFirstNumber(getObjToCompare(obj1));
    var num2 = getFirstNumber(getObjToCompare(obj2));
    return compareObjects(num1, num2, true);
  }

  function labelDecendingComparator(obj1, obj2) {
    var num1 = getFirstNumber(getObjToCompare(obj1));
    var num2 = getFirstNumber(getObjToCompare(obj2));
    return compareObjects(num1, num2, false);
  }

  function CMYKComparator(obj1, obj2) {
    var num1 = getColorSortingNumericRepresentation(getObjToCompare(obj1));
    var num2 = getColorSortingNumericRepresentation(getObjToCompare(obj2));
    return compareObjects(num1, num2, false);
  }

  function getPropertyRecursive(obj, propertyName) {
    if (typeof obj[propertyName] !== "undefined") {
      return obj[propertyName];
    }
    for (var key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        var ret = getPropertyRecursive(obj[key], propertyName);
        if (typeof ret !== "undefined") {
          return ret;
        }
      }
    }
  }

  function getObjToCompare(obj) {
    if (!propertyName) {
      return obj;
    } else {
      return getPropertyRecursive(obj, propertyName);
    }
  }

  switch (sortingType.toLowerCase()) {
    case "alpha":
      return alphaComparator;
    case "alphadecending":
      return alphaDecendingComparator;
    case "caseinsensitive":
      return caseInsensitiveComparator;
    case "caseinsensitivedecending":
      return caseInsensitiveDecendingComparator;
    case "numeric":
      return numericComparator;
    case "numericdecending":
      return numericDecendingComparator;
    case "date":
      return dateComparator;
    case "datedecending":
      return dateDecendingComparator;
    case "label":
      return labelComparator;
    case "labeldecending":
      return labelDecendingComparator;
    case "cmyk":
      return CMYKComparator;
    default :
      return alphaComparator;
  }
}

function getFirstNumber(str) {
  if (!str) {
    return 0;
  }
  str = str.trim();
  var result = "";
  var inANumber = false;
  for (var i = 0; i < str.length; i++) {
    var ch = str.charAt(i);
    if (!isNaN(ch)) {
      result += ch;
      inANumber = true;
    } else if (inANumber) {
      break;
    }
  }
  if (result.length > 0) {
    return parseInt(result, 10);
  }
  return 0;
}

function getColorSortingNumericRepresentation(colorName) {
  var colorTable = require('core/services/colorService');

  var comparable;
  try {
    var colorObject = colorTable.getColorByName(colorName);
    comparable = colorObject.colorType.toLowerCase();
  } catch (ex) {
    comparable = colorName.toLowerCase();
  }

  switch (comparable) {
    case "black":
      return 5;
    case "cyan":
      return 4;
    case "magenta":
      return 3;
    case "yellow":
      return 2;


    default:
      return 1;
  }
}

function compareObjects(obj1, obj2, ascending) {
  if (obj1 === obj2) {
    return 0;
  }
  if (ascending) {
    if (obj1 < obj2) {
      return -1;
    } else {
      return 1;
    }

  } else {
    if (obj1 < obj2) {
      return 1;
    } else {
      return -1;
    }
  }
}

module.exports = {
  sort: function (arrayToSort, sortingType, propertyName) {
    var comp = Array.isArray(arguments[1]) ? multiComparators(arguments[1]) :
      getComparatorBySortingType(sortingType, propertyName);
    return arrayToSort.sort(comp);
  },

  insert: function (arrayToUpdate, sortingType, propertyName, objectToInsert) {
    var low = 0, high = arrayToUpdate.length - 1, i, comparison, idx = 0;
    var comparator = getComparatorBySortingType(sortingType, propertyName);
    while (low <= high) {
      i = Math.floor((low + high) / 2);
      comparison = comparator(arrayToUpdate[i], objectToInsert);
      if (comparison < 0) {
        low = i + 1;
        idx = low;
        continue;
      }
      if (comparison > 0) {
        high = i - 1;
        continue;
      }
      idx = i;
      break;
    }
    if (typeof arrayToUpdate.insertAt === "function") {
      arrayToUpdate.insertAt(idx, objectToInsert);
    }
    else {
      arrayToUpdate.splice(idx, 0, objectToInsert);
    }
  },

  findIndex: function (arr, sortingType, propertyName, objectToInsert) {
    var low = 0, hight = arr.length - 1, i, comparison, idx = 0;
    var comparator = this.getComparator(sortingType, propertyName);
    while (low <= high) {
      i = Math.floor((low + high) / 2);
      comparison = comparator(arrayToUpdate[i], objectToInsert);
      if (comparison < 0) {
        low = i + 1;
        idx = low;
        continue;
      }
      if (comparison > 0) {
        high = i - 1;
        continue;
      }
      idx = i;
      break;
    }
    return idx;
  },

  getComparator: function (sortingType, propertyName) {
    if (Array.isArray(arguments[0])) return multiComparators(arguments[0]);

    return getComparatorBySortingType(sortingType, propertyName);
  },

  getColorSortingNumericRepresentation: function (colorName) {
    return getColorSortingNumericRepresentation.call(this, colorName)
  }
};

