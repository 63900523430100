/**
 * Created by elad on 10/13/2015.
 */
define(['AbstractAction', 'sandbox'], function (AbstractAction, sandbox) {
  'use strict';

  return AbstractAction.extend({
    execute: function (content) {
      return sandbox.request.saveSetup(
        this.nwid,
        this.module.nwid,
        this.module.type,
        this.module.projectorId,
        content,
        undefined,
        this.parameters.scriptName
      );
    }
  });
});