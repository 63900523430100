import React, { useState, useRef } from 'react';
import { Dialog, DialogButtonsBar } from 'components/common/dialogs';
import Radio from 'components/common/inputs/Radio';
import { translate } from 'core/services/localization';
import { restPost, restGet } from 'core/managers/rest2';
import CircularProgress from 'components/common/progress/CircularProgress';
import Button from 'components/common/buttons/Button';


const labels = {
  includeProductionData: translate('Include production data'),
  skipProductionData: translate('Skip production data'),
  export: translate('Export'),
  exportFolder: translate('Export Folder')
};

const INCLUDE = 'include';
const SKIP = 'skip';

export const ExportFolderDialog = ({ onClose, folderName, exportAction, rootNwid }) => {

  const [includeProductionData, setIncludeProductionData] = useState(INCLUDE);
  const [progress, setProgress] = useState({});
  const [exportFolderTaskNwId, setExportFolderTaskNwId] = useState();
  const [isSpecificStepProblem, setIsSpecificStepProblem] = useState(false);
  const intervalIdRef = useRef(null);

  const exportFolder = (includeProductionData) => {
    setProgress({});
    restPost(exportAction.nwid, `folders/${rootNwid}/export`, { includeProductionData: includeProductionData })
      .then(res => {
        setProgress({ isCancelled: false });
        setExportFolderTaskNwId(res.exportFolderTaskNwId);

        intervalIdRef.current = setInterval(() => restGet(exportAction.nwid, `folders/exportProgress/${res.exportFolderTaskNwId}`)
          .then(p => {
            if (p) {
              setProgress({ ...progress, ...p });
              setIsSpecificStepProblem(!!p.Steps.find(step => step.Progress === 1) && !!p.failedMessage);

              if (p.exportZipPath || p.Status === 'Failed') {
                clearInterval(intervalIdRef.current);
              }
            }
          }), 500);
      })
      .catch(err => {
        console.error('Export folder failed');
      });

  };

  const handleExportFolder = () => {
    exportFolder(includeProductionData === INCLUDE);
  }

  const handleCancel = () => {
    clearInterval(intervalIdRef.current);
    restPost(exportAction.nwid, `folders/cancelExport/${exportFolderTaskNwId} `)
      .then(res => {
        setProgress({ ...progress, isCancelled: true });
      });
  }

  const handleChangeIncludeProductionData = (event, value) => {
    setIncludeProductionData(value);
  };

  const handleClose = () => {
    clearInterval(intervalIdRef.current);
    onClose();
  }

  const renderSteps = () => {

    return <div className='crtx-progress-steps'>{progress.Steps.map(renderStep)}</div>;
  }

  const renderStep = (step, index) => {

    return <div className='crtx-export-import-step' key={index}>
      <div className='crtx-status-indicator'>
        {step.Progress === 0 ?
          (isSpecificStepProblem ? <i className="material-icons warning md-24 ">warning_amber</i> : undefined) :

          (step.Progress < 1 ?
            <CircularProgress
              min={0}
              max={100}
              strokeWidth={4}
              value={Math.round(step.Progress * 100)}
              percentVisible={true}
            ></CircularProgress>
            :
            <i className="material-icons success md-24 ">check_circle</i>)
        }
      </div>
      <label className='crtx-export-import-step-name'>{step.Step}</label>
    </div>
  }

  const renderExportFolderProgressStatus = () => {
    return <React.Fragment>
      {progress.Status === 'Started' && !progress.isCancelled &&
        <div className='crtx-progress-status'>
          <div className='icon-cont' />
          <div className='regular-message'>{translate('Exporting folder...')}</div>
          <button className='inner-button' onClick={handleCancel}>{translate('Cancel Export')}</button>
        </div>
      }
      {progress.Status === 'Failed' && progress.failedMessage !== '' &&
        <div className='crtx-progress-status'>
          <div className='icon-cont'>
            <i className='material-icons warning md-24 '>warning_amber</i>
          </div>
          <div className='warning-message'>{progress.failedMessage}</div>
        </div>
      }
      {progress.Status === 'Complete' && progress?.exportZipPath !== '' &&
        <div className='crtx-progress-status'>
          <div className='icon-cont'>
            <i className='material-icons success md-24 '>check_circle</i>
          </div>
          <div className='message'>{translate('Zipped To: ')}<br />
            {progress.exportZipPath}</div>
        </div>
      }
    </React.Fragment>;
  }

  return <Dialog
    onClose={handleClose}
    title={translate('Export Folder - {1}', folderName)}
    autoFocus={false}
    initialWidth={550}
    modal={true}
  >
    {progress.Steps ?
      <div className='crtx-export-import-dialog'>
        <div className='crtx-steps-container'>
          {!(progress.Status === 'Failed' && !isSpecificStepProblem && progress.failedMessage) && renderSteps()}
          {renderExportFolderProgressStatus()}
          {progress.isCancelled && <div className='crtx-progress-status'>
            <div className='icon-cont'>
              <i className='material-icons warning md-24 '>warning_amber</i>
            </div>
            <div className='warning-message'>{translate('Export canceled by user')}</div>
          </div>}
        </div>
      </div>
      :
      <div className='crtx-form-section-container'>
        <div className='crtx-form-section'>
          <div className='crtx-form-one-column'>
            <div className='crtx-form-full-row'>
              <label className='crtx-form-label-with-checkbox'>
                <Radio name='radioGroup' value={INCLUDE} checked={includeProductionData === INCLUDE} onChange={handleChangeIncludeProductionData} />
                {labels.includeProductionData}</label>
            </div>
            <div className='crtx-form-full-row'>
              <label className='crtx-form-label-with-checkbox'>
                <Radio name='radioGroup' value={SKIP} checked={includeProductionData === SKIP} onChange={handleChangeIncludeProductionData} />
                {labels.skipProductionData} </label>
            </div>
          </div>
        </div>

      </div>
    }
    <DialogButtonsBar >
      {progress.Steps ?

        <Button className='primary' onClick={handleClose}>
          {translate('OK')}
        </Button>
        :
        <Button className='primary' onClick={handleExportFolder}>
          {translate('Export')}
        </Button>}

    </DialogButtonsBar>
  </Dialog>
}