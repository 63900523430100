import React, { Component } from 'react';
import LinksGroup from './LinksGroup';
import Header from 'src/components/common/headers/Header';
import PropTypes from 'prop-types';

const orderLinksGroup = ['masters', 'contents', 'children', 'siblings', 'remoteRelatives', 'copies', 'pages'];

module.exports = class Links extends Component {
  constructor(props) {
    super(props);
    this.getLinksGroups = this.getLinksGroups.bind(this);
  }

  static propTypes = {
    links: PropTypes.any,
    model: PropTypes.any,
    title: PropTypes.string,
    advanceInfo: PropTypes.bool,
    openCustomInfoDialog: PropTypes.func,
    openerType: PropTypes.string//dialog or rightPanel
  };

  getLinksGroups() {
    const { links, model, advanceInfo, openCustomInfoDialog } = this.props;
    const object = model.object || model;
    let linksGroups = [];
    orderLinksGroup.forEach(function (orderLink) {
      for (var linksGroup in links) {
        if (linksGroup === orderLink && links[linksGroup].length !== 0) {
          const linksGroupComp = <LinksGroup key={linksGroup} name={linksGroup} links={links[linksGroup]} object={object} advanceInfo={advanceInfo} openCustomInfoDialog={openCustomInfoDialog} />;
          linksGroups.push(linksGroupComp);
        }
      }
    });
    return linksGroups;
  }

  render() {
    const { title } = this.props;

    return <div className='links-custom-info'>
      <Header>{title}</Header>
      {this.getLinksGroups()}

    </div>;
  }
};