import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import LengthInput from 'components/common/inputs/LengthInput';

const EDIT_MODE = {
  VALUE: 'VALUE',
  TOLERANCE: 'TOLERANCE',
  NONE: 'NONE'
};

function ToleranceCell({
                         columnData = {},
                         rowIndex,
                         columnKey,
                         disabled = false,
                         allowEmptyValue = true,
                         size = 4,
                         onChange,
                         ...props
                       }) {
  const [value, setValue] = useState(columnData.value);
  const [tolerance, setTolerance] = useState(columnData.tolerance);
  const [dataChanged, setDataChanged] = useState(false);
  const [editMode, setEditMode] = useState(EDIT_MODE.NONE);

  const valueRef = useRef(null);
  const toleranceRef = useRef(null);


  useEffect(() => {
    return () => {
      // console.log('=== destroy ===');
      if (!disabled) {
        endEditing();
      }
    }
  }, []);

  useEffect(() => {
    // console.log('=== useEffect() rowIndex=', rowIndex);
    endEditing();
  }, [rowIndex]);

  const beginValueEditing = () => {
    // console.log('=== beginValueEditing() editMode=', editMode);
    if (editMode === EDIT_MODE.VALUE) {
      return;
    }

    setEditMode(EDIT_MODE.VALUE);

    if (valueRef.current) {
      valueRef.current.focus();
      valueRef.current.select();
    }
  };

  const beginToleranceEditing = () => {
    // console.log('=== beginToleranceEditing() editMode=', editMode);
    if (editMode === EDIT_MODE.TOLERANCE) {
      return;
    }

    setEditMode(EDIT_MODE.TOLERANCE);

    if (toleranceRef.current) {
      toleranceRef.current.focus();
      toleranceRef.current.select();
    }
  };

  const endEditing = () => {
    // console.log('=== endEditing() editMode=', editMode);
    if (editMode === EDIT_MODE.NONE) {
      return;
    }

    setEditMode(EDIT_MODE.NONE);

    if (dataChanged) {
      setDataChanged(false);

      if (typeof onChange === 'function') {
        onChange(rowIndex, columnKey, {value, tolerance});
      }
    }
  };

  const handleValueClick = (e) => {
    // console.log('=== handleValueClick() event=', e);
    if (!disabled) {
      beginValueEditing();
    }
  };

  const handleValueFocus = (e) => {
    // console.log('=== handleValueFocus() event=', e);
    if (!disabled) {
      beginValueEditing();
    }
  };

  const handleValueChange = (e, newValue) => {
    // console.log('=== handleValueChange() newValue=', newValue);
    setValue(newValue);
    setDataChanged(true);
  };

  const handleValueBlur = () => {
    // console.log('=== handleValueBlur()');
    endEditing();
  };

  const handleToleranceClick = (e) => {
    // console.log('=== handleToleranceClick() event=', e);
    if (!disabled) {
      beginToleranceEditing();
    }
  };

  const handleToleranceFocus = (e) => {
    // console.log('=== handleToleranceFocus() event=', e);
    if (!disabled) {
      beginToleranceEditing();
    }
  };

  const handleToleranceChange = (e, newValue) => {
    // console.log('=== handleToleranceChange() newValue=', newValue);
    setTolerance(newValue);
    setDataChanged(true);
  };

  const handleToleranceBlur = () => {
    // console.log('=== handleToleranceBlur()');
    endEditing();
  };

  return (
    <div className='cell'>
      <div className='cell-content cell-tolerance'>
        <LengthInput ref={valueRef}
                     className='value-part'
                     value={value}
                     noUnitSymbol={true}
                     disabled={disabled}
                     allowEmptyValue={allowEmptyValue}
                     size={size}
                     onClick={handleValueClick}
                     onFocus={handleValueFocus}
                     onChange={handleValueChange}
                     onBlur={handleValueBlur}
                     {...props}
        />
        <div className="plus-minus-label">±</div>
        <LengthInput ref={toleranceRef}
                     className='tolerance-part'
                     value={tolerance}
                     disabled={disabled}
                     allowEmptyValue={allowEmptyValue}
                     size={size}
                     onClick={handleToleranceClick}
                     onFocus={handleToleranceFocus}
                     onChange={handleToleranceChange}
                     onBlur={handleToleranceBlur}
                     {...props}
        />
      </div>
    </div>
  );
}

ToleranceCell.propTypes = {
  columnData: PropTypes.any,
  rowIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default ToleranceCell;
