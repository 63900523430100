import React, { Component } from 'react';
import Radio from '../../common/inputs/Radio';
import PropTypes from 'prop-types';

const styles = {
  'top-left': { left: '0%', top: '0%' },
  'top-center': { left: '50%', top: '0%', transform: 'translateX(-50%)' },
  'top-right': { right: '0%', top: '0%' },
  'center-left': { left: '0%', top: '50%', transform: 'translateY(-50%)' },
  'center-center': { left: '50%', top: '50%', transform: 'translate(-50%,-50%)' },
  'center-right': { right: '0%', top: '50%', transform: 'translateY(-50%)' },
  'bottom-left': { left: '0%', bottom: '0%' },
  'bottom-center': { left: '50%', bottom: '0%', transform: 'translateX(-50%)' },
  'bottom-right': { right: '0%', bottom: '0%' }
};


class AnchorPoint extends Component {

  constructor(props) {
    super(props);
    this.binders.call(this);
  }

  binders() {
    this.handleChangeRadio = this.handleChangeRadio.bind(this);
    this.createAnchorPoint = this.createAnchorPoint.bind(this);
  }

  static propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
    text: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    points: PropTypes.array
  }
  static defaultProps = {
    className: '',
    style: {},
    text: '',
    value: '',
    onChange: () => {
    },
    points: ['top-left', 'top-center', 'top-right', 'center-left', 'center-center', 'center-right', 'bottom-left', 'bottom-center', 'bottom-right']
  }

  handleChangeRadio(event, radioChecked) {
    this.props.onChange(event, radioChecked);
  }

  createAnchorPoint(item, index) {
    const { style, className, text, value, onChange } = this.props;

    return <Radio key={item} checked={value === item} value={item} onChange={this.handleChangeRadio} style={styles[item]} />
  }

  render() {
    const { style, className, text, value, onChange, points } = this.props;
    return <div className={`crtx-anchorPoint ${className}`} style={style}>
      <span title={text}>{text}</span>
      {points.map(this.createAnchorPoint)}
    </div>;
  }
}
module.exports = AnchorPoint;