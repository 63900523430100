/* @flow weak */

import React from 'react';
import { createRoot } from 'react-dom/client';
import AbstractModule from 'AbstractModule';
import TickableModel from '../TickableModel';
import sandbox from 'sandbox';
import Preflight from './components/Preflight';
import settingsManager from 'core/managers/settings';
import { retriveAndSetEditionNavigatorItems, retriveAndSetSectionNavigatorItems } from '../NewPageView/navigatorControls';
import { fetchAndSetContentBreadcrumbs } from 'utilities/breadcrumbs';
import { MISSING_PAGE_URL, getContent } from '../NewPageView/utilities';
import { getParentViewRoot } from 'utilities/view';

const pageActions = {
  pdf: '4',
  html: '16'
};

module.exports = AbstractModule.extend({

  init: function () {
    this._super();

    for (var key in this) {
      if (key !== '_super' && typeof this[key] === 'function') {
        this[key] = this[key].bind(this);
      }
    }
  },

  initDone: function () {
    // used in the derived classes
    this.tickableModel = new TickableModel();
    this.tickableModel.register(['page'], this.handlePageTick);
    this.tickableModel.register(['form'], this.handlePageTick);
    this.reactRoot = createRoot(this.domElement);

    this.pdfSrc = undefined;
    this.htmlSrc = undefined;
  },

  getSource: function (viewId, projectorId, model, version, pageAction, type) {
    const params = {
      viewId: viewId,
      projectorId: projectorId,
      imageType: type,
      version: version,
      rootType: model.type,
      pageAction: pageAction,
      nwid: model.content.nwid
    };

    return sandbox.request.getImageUrl(params);
  },

  setBreadcrumbs: function (model) {
    fetchAndSetContentBreadcrumbs(this, getContent(model));
  },

  firstTickReceived: function (data) {
    this.preferences = data.preferences || {};
    this.preferences.filters = data.preferences.filters || [];
    this.tickableModel.firstTickHandler(data.model);

    if (typeof data.model.content.version !== 'undefined' && data.model.content.version !== '-1') {
      this.pdfSrc = this.getSource(this.id, this.projectorId, data.model, data.model.content.version, data.model.pageAction, 'pdf');
    } else {
      this.pdfSrc = MISSING_PAGE_URL;
    }
    if (typeof data.model.content.htmlVersion !== 'undefined' && data.model.content.htmlVersion !== '-1') {
      this.htmlSrc = this.getSource(this.id, this.projectorId, data.model, data.model.content.htmlVersion, pageActions.html, 'txt');
    }

    let bShowAllSections = settingsManager.get('softproofShowSections');
    if (data.model.section && data.model.section.zone && data.model.section.zone.sections) {

      const parentRoot = getParentViewRoot(this);
      if (parentRoot.type === 'edition') {
        retriveAndSetEditionNavigatorItems(this, this.preferences.filters, data.model, parentRoot);

      } else {
        retriveAndSetSectionNavigatorItems(this, this.preferences.filters, data.model, bShowAllSections, data.model.section.currentSectionName);
      }
    }

    this.render();
  },

  tickUpdate: function (data) {
    this.tickableModel.tickUpdateHandler(data.model);
  },

  tickCommit: function () {
  },

  handlePageTick: function (action, item) {
    if (item !== item.getRoot()) {
      return;
    }

    this.updateSelected([item]);
  },

  render: function () {
    console.log(this.htmlSrc);
    this.reactRoot.render(<Preflight pdfSrc={this.pdfSrc} htmlSrc={this.htmlSrc} />);
  }

});