/**
 * Created by moshemal on 7/15/15.
 */

define([], function(){
  'use strict';
  var console = window.console || {},
    defaultLogger,
    methods = ['debug', 'info', 'warn', 'error', 'log', 'time', 'timeEnd', 'trace', 'timeStamp'];

  function decorate (method) {
    return function () {
      if (typeof console[method] === 'function'){
        console[method].apply(console, arguments);
      }
    }
  }

  function turnOn() {
    defaultLogger = (function(){
      if (typeof console !== 'undefined') {
        return methods.reduce(function (logger, method) {
          logger[method] = decorate(method);
          return logger;
        }, {});
      } else {
        return methods.reduce(function (logger, method) {
          logger[method] = function(){};
          return logger;
        }, {});
      }
    })();
  }

  function turnOff() {
    var emptyFunc = function(){};
    Object.keys(defaultLogger).forEach(function(method){
      defaultLogger[method] = emptyFunc;
    });
  }

  //By default the logger is on
  turnOn();
  return {
    getDefaultLogger: function () {return defaultLogger},
    turnOff: turnOff,
    turnOn: turnOn
  }
});