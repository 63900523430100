import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table, { Column, headers } from 'widgets/ReactDataGrid';
import { ROW_HEIGHT } from 'widgets/ReactDataGrid/utils';
import { translate } from 'core/services/localization';
import { IconButton } from 'components/common/buttons';
import { EditCommonSettingsDialog } from './EditCommonSettingsDialog/EditCommonSettingsDialog';

const { GenericHeader, HeaderCaption } = headers;

const MIN_COLUMN_WIDTH = 20;
const HEADER_HEIGHT = 31;

export const View = ({ module, viewModel, customLogsTableColumns = [] }) => {

  const [saveAt, setSaveAt] = useState(viewModel.saveAt);

  const [logSize, setLogSize] = useState(viewModel.logSize);

  const [showDialog, setShowDialog] = useState(false);

  const handlePathSelect = value => {
    setSaveAt(value);
    module.handlePathSelect(value);
  };

  const handleLogSizeChange = value => {
    setLogSize(value);
    module.handleLogSizeChange(value);
  };

  const renderCustomLogColumnHeaderCaption = column => {
    return (
      <HeaderCaption tooltip={column.caption} sortable={typeof column.sortType !== 'undefined'}>
        {column.caption}
      </HeaderCaption>
    );
  };

  const renderCustomLogColumnHeader = column => {
    return (
      <GenericHeader
        captionRenderer={renderCustomLogColumnHeaderCaption(column)}
      />
    );
  };

  const handleEditCommonSettings = ({ logSize, saveAt }) => {
    handlePathSelect(saveAt);
    handleLogSizeChange(logSize);
    module.handleSaveCustomLogsCommonSettings();
  };

  return (
    <div className='crtx-form-section-container crtx-form-full-height'>
      <div className='custom-log-common-settings-container crtx-form-margin-bottom-xsmall'>
        {showDialog && <EditCommonSettingsDialog
          onClose={() => setShowDialog(false)}
          title={translate('Edit Logs common settings')}
          moduleNwid={module.nwid}
          logSize={logSize}
          saveAt={saveAt}
          onEditCommonSettings={handleEditCommonSettings}
        />}
        <div className='custom-log-common-settings-item'>
          <label className='custom-log-common-settings-item-label'>{translate('Path to save log files:')}</label>
          <label>{saveAt}</label>
        </div>
        <div className='custom-log-common-settings-item'>
          <label className='custom-log-common-settings-item-label'>{translate('Log file size:')}</label>
          <label>{logSize} KB</label>
        </div>
        <IconButton
          key='edit'
          iconName='edit'
          tooltip={translate('Edit Logs common settings')}
          onClick={() => setShowDialog(true)}
        />
      </div>
      <Table
        rows={viewModel.logs}
        columnKey='id'
        virtualScroll={true}
        fixed={true}
        multiSelect={false}
        minColumnWidth={MIN_COLUMN_WIDTH}
        headerHeight={HEADER_HEIGHT}
        rowHeight={ROW_HEIGHT}
        onColumnsFilter={(columnKey, visible, columns) => module.handleCustomLogsTableColumnsFilter(columns)}
        onColumnsOrder={(columns, oldIndex, newIndex) => module.handleCustomLogsTableColumnOrder(columns, oldIndex, newIndex)}
        onSelect={selectedRows => module.handleCustomLogsTableSelect(selectedRows)}
        onRowContextMenu={(rowIndex, rowContent, selectedRows, e) => module.handleCustomLogsTableContextMenu(rowContent, selectedRows, e)}
        onColumnResize={(columns, columnKey) => module.handleCustomLogsTableColumnResize(columns)}
        onDoubleClickRow={(rowIndex, log, e) => module.handleCustomLogsTableEdit(log)}
      >
        {
          customLogsTableColumns.map(col => {
            return <Column
              key={col.key}
              title={col.caption}
              columnKey={col.key}
              resizable={col.resizable}
              width={col.width}
              align={col.align}
              visible={col.visible}
              header={renderCustomLogColumnHeader(col)}
              cell={col.cell}
              cellDataGetter={col.cellDataGetter}
            />;
          })
        }
      </Table>
    </div>
  );
};

View.propTypes = {
  module: PropTypes.object,
  viewModel: PropTypes.object,
  customLogsTableColumns: PropTypes.array
};