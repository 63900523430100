/* @flow weak */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const styles = {
  container: {
    width: 'calc(100% - 20px)',
    height: 'calc(100% - 10px)',
    margin: '0px 10px 0 10px',
    overflow: 'hidden'
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none'
  },
  iframeHalfSize: {
    float: 'left',
    width: '50%',
    height: '100%',
    border: 'none'
  }
};
class Preflight extends Component {
  constructor(props) {
    super(props);
    this.renderIframe = this.renderIframe.bind(this);
  }
  static propTypes = {
    pdfSrc: PropTypes.string,
    htmlSrc: PropTypes.string
  }

  renderIframe(src, isHalfSize, scrolling) {
    if (typeof src === 'undefined') return null;

    const style = isHalfSize ? styles.iframeHalfSize : styles.iframe;
    return <iframe style={style} src={src} frameBorder="0" scrolling={scrolling}>
    </iframe>;
  }

  render() {
    const { pdfSrc, htmlSrc } = this.props;

    return <div style={styles.container}>
      {this.renderIframe(htmlSrc, typeof pdfSrc !== 'undefined')}
      {this.renderIframe(pdfSrc, typeof htmlSrc !== 'undefined', 'no')}
    </div>;
  }
}

module.exports = Preflight;