/**
 * Created with JetBrains WebStorm.
 * User: elad
 * Date: 3/9/14
 * Time: 10:07 AM
 * To change this template use File | Settings | File Templates.
 */
define(['sandbox', './OpenModuleAction'], function (sandbox, OpenModuleAction) {
  'use strict';

  return OpenModuleAction.extend({

    isApplicable: function () {
      var rootType = this.module.name;
      if (rootType === "WorkflowView"){
        var objects = this.module.selected;
        if (typeof objects === 'undefined') {
          return false;
        }
        return objects.length === 1;
      }
      return true;
    },
    execute: function (args) {
      var rootType = this.module.viewSettings.rootType;
      if (rootType.indexOf("workflow/step/") === 0 || rootType.indexOf("demons") === 0){
        //will be called in a flowsetp view
        this._super();
      } else {
        //will be called when the setup of the selected must be opened
        this._super(args);
      }
    }
  });

});