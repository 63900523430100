/**
 * Created by moshemal on 8/31/14.
 */


define(['core', 'sandbox', 'ember', './jsUtils', 'core/managers/settings'], function (core, sandbox, Ember, jsUtils, settingsManagerModule) {
  'use strict';
  const settingsManager = settingsManagerModule.default;

  function findByName(objsArr, value) {
    for (var i = 0; i < objsArr.length; i++) {
      if (objsArr[i].name.toLowerCase() === value.toLowerCase()) {
        return objsArr[i];
      }
    }
    return null;
  }

  function collectByName(objsArr) {
    var ret = [];
    for (var i = 0; i < objsArr.length; i++) {
      ret.push(objsArr[i].name);
    }
    return ret;
  }

  function findChildArray(obj) {

    if (jsUtils.isArray(obj)) {
      return obj;
    }
    for (var prop in obj) {
      if (jsUtils.isArray(obj[prop])) {
        return obj[prop];
      }
    }
    return null;
  }

  function findParentByType(model, type) {
    if (model === void 0) {
      return null;
    }
    var currType = model.type;
    if (currType === type) {
      return model;
    }
    return findParentByType(model.parent, type);
  }


  return {

    planSetup: {},
    editionSorting: "byPlan",
    sectionSorting: "byPlan",
    zoneSorting: "byPlan",

    isDefaultColor: function (publication) {
      return true;
    },

    isDefaultShare: function (publication) {
      return true;
    },

    setPlanSetup: function (model) {
      this.planSetup = model;
      this.editionSorting = settingsManager.get('productSorting').edition;
      this.sectionSorting = settingsManager.get('productSorting').section;
      this.zoneSorting = settingsManager.get('productSorting').zone;


      console.log("@@@@@@@@@@@@@ " + this.zoneSorting);
    },

    getPublications: function () {
      var ret = [];
      if (this.planSetup.hasOwnProperty("Publications")) {
        if (!(this.planSetup.Publications instanceof Array)) {
          this.planSetup.Publications = this.planSetup.Publications.Publication;
          if (!(this.planSetup.Publications instanceof Array)) {
            this.planSetup.Publications = [this.planSetup.Publications];

          }
        }
        var a = this.planSetup.Publications;
        for (var i = a.length - 1; i >= 0; i--) {
          if (a[i].Editions === null || typeof a[i].Editions === 'undefined') {
            a.splice(i, 1);
          }
          if (a[i].Zones === null || typeof a[i].Zones === 'undefined') {
            a.splice(i, 1);
          }
        }
        ret = collectByName(findChildArray(a));
      }
      return ret;
    },

    getPublication: function (publicationName) {
      var myPublications = [];
      if (this.planSetup.hasOwnProperty("Publications")) {
        if (!(this.planSetup.Publications instanceof Array)) {
          this.planSetup.Publications = this.planSetup.Publications.Publication;
          if (!(this.planSetup.Publications instanceof Array)) {
            this.planSetup.Publications = [this.planSetup.Publications];

          }
        }
        myPublications = this.planSetup.Publications;
      }
      //var myPublications = this.getPublications();
      for (var i = 0; i < myPublications.length; i++) {
        if (myPublications[i].name.toLowerCase() === publicationName.toLowerCase()) {
          return myPublications[i];
        }
      }
      return null;
    },

    getEdition: function (publicationName, editionName) {
      var myEditions = this.getEditions(publicationName);
      for (var i = 0; i < myEditions.length; i++) {
        if (myEditions[i].name.toLowerCase() === editionName.toLowerCase()) {
          return myEditions[i];
        }
      }
      return null;
    },

    getEditionIndex: function (publicationName, editionName) {
      var myEditions = this.getEditions(publicationName);
      for (var i = 0; i < myEditions.length; i++) {
        if (myEditions[i].name.toLowerCase() === editionName.toLowerCase()) {
          return i;
        }
      }
      return -1;
    },

    getEditionByIndex: function (publicationName, index) {
      var myEditions = this.getEditions(publicationName);
      if (myEditions.length >= index) {
        return myEditions[index];
      }
      return null;
    },

    getEditions: function (publicationName) {
      var ret = [];
      var standalon = false;
      if (this.planSetup.hasOwnProperty("Publications")) {
        var publications = findChildArray(this.planSetup.Publications);
        var publication = findByName(publications, publicationName);
        if (publication !== null) {
          standalon = publication.standAloneEditions;
          if (!(publication.Editions instanceof Array)) {
            publication.Editions = publication.Editions.Edition;
            if (!(publication.Editions instanceof Array)) {
              publication.Editions = [publication.Editions];
            }
          }
          var editions = findChildArray(publication.Editions);
          ret = editions;
        }
      }
      if (this.editionSorting === "alphanumeric" && standalon) {
        ret.sort(function (a, b) { return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0); });
      }
      return ret;
    },

    getMainEdition: function (publicationDescriptor) {
      var publicationName = publicationDescriptor.name;
      if (this.planSetup.hasOwnProperty("Publications")) {
        var publications = findChildArray(this.planSetup.Publications);
        var publication = findByName(publications, publicationName);
        if (publication !== null) {
          if (!(publication.Editions instanceof Array)) {
            publication.Editions = publication.Editions.Edition;
            if (!(publication.Editions instanceof Array)) {
              publication.Editions = [publication.Editions];
            }
          }
          var editions = findChildArray(publication.Editions);
          for (var i = 0; i < editions.length; i++) {
            if (editions[i].main === 'true') {
              return editions[i].name;
            }
          }
        }
      }
      return '';
    },

    getZones: function (publicationDescriptor, editionDescriptor = {}) {
      var publicationName = publicationDescriptor.displayName;
      var ret = [];
      //var editionName = editionDescriptor.displayName;
      if (this.planSetup.hasOwnProperty("Publications")) {
        var publication = findByName(findChildArray(this.planSetup.Publications), publicationName);
        if (publication !== null) {
          //if (!(publication.Editions instanceof Array)){
          //  publication.Editions = [publication.Editions.Edition];
          //}
          //var edition = findByName(findChildArray(publication.Editions), editionName);
          //if (edition !== null){
          //return edition.ZoneRefs.ZoneRef;
          //}
          if (!(publication.Zones instanceof Array)) {
            publication.Zones = publication.Zones.Zone;
            if (!(publication.Zones instanceof Array)) {
              publication.Zones = [publication.Zones];
            }
          }
          ret = publication.Zones;
        }
      }
      if (this.zoneSorting === "alphanumeric") {
        ret.sort(function (a, b) { return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0); });
      }
      return ret;
    },

    getZone: function (publicationDescriptor, zoneName) {
      var myZones = this.getZones(publicationDescriptor);
      for (var i = 0; i < myZones.length; i++) {
        if (myZones[i].name.toLowerCase() === zoneName.toLowerCase()) {
          return myZones[i];
        }
      }
      return null;
    },

    isEnableDeadlines: function (model, type) {
      type = (type === void 0) ? model.type : type;
      var publication = findParentByType(model, 'publication');
      if (publication === null) {
        return true;
      }
      var deadLineLevel = publication.deadLineLevel;
      if (deadLineLevel === void 0 || deadLineLevel === "all") {
        return true;
      }
      return deadLineLevel === type;
    },

    getCustomProperties: function () {
      var customProps = this.planSetup.hasOwnProperty("customProperties") ?
        this.planSetup["customProperties"] : [{ name: "ED", title: "Editorial(Ed)" },
        { name: "AD", title: "Ad" }, { name: "CO", title: "Cover(CO)" },
        { name: "HP", title: "Hard Proof(HP)" },
        { name: "CA", title: "customA(CA)" },
        { name: "CB", title: "customB(CB)" }];

      return customProps;
    }

  };
});