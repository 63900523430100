
import settingsManager from 'core/managers/settings';

const mmOpt = {factor: 25.4, precision: 3};
const inchOpt = {factor: 1, precision: 4};
const
  converterOptions = {
    'mm': mmOpt,
    'in': inchOpt,
    'inch': inchOpt
  };

const lengthUnit = settingsManager.getLengthUnit();
const converterOption = converterOptions[lengthUnit];

const floatMask = {
  regex: /^-?\d*\.?\d*$/
};
const formatNumber = (val) => {

  let t = Number(val * converterOption.factor).toFixed(converterOption.precision);
  return Number(t).toString();
}

module.exports = {
  formatNumber,
};