/**
 * @name
 * @fileOverview
 * @author sergey
 */
define(['ember', 'text!../templates/TreeTableCell.hbs', 'ember_table'], function (Ember, TreeCellTemplate) {
  'use strict';
  return Ember.Table.TableCell.extend({
    init: function () {
      this._super.apply(this, arguments);
      var contentProperty = this.get('column.contentPath');
      return this.addObserver("rowContent." + contentProperty, this, this.contentDidChange);
    },
    contentDidChange: function () {
      return this.notifyPropertyChange('cellContent');
    },
    template: Ember.Handlebars.compile(TreeCellTemplate),
    styleBindings: ['indentation:padding-left'],
    indentation: Ember.computed(function () {
      var indentation;
      indentation = this.get('row.indentation');
      if (indentation) {
        return indentation - 15;
      } else {
        return 0;
      }
    }).property('row.indentation')
  });
});