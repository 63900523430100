import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import Radio from 'components/common/inputs/Radio';
import { MODE } from './../model/constants';
import CreateEdition from './CreateEdition';
import DuplicateEdition from './DuplicateEdition';

const labels = {
  [MODE.CREATE]: translate('Create / Edit'),
  [MODE.DUPLICATE]: translate('Duplicate'),
};

function ModeSelector({ modelHandler }) {

  const [currentMode, setCurrentMode] = useState(modelHandler.getMode());

  const handleChangeMode = (mode) => {
    setCurrentMode(mode);
    modelHandler.setMode(mode);
  };

  const handleUpdate = (stepModel) => {
    modelHandler.setStepModel(currentMode, stepModel);
  };

  return (
    <div className='crtx-mode-selector'>
      <div className='crtx-mode-bar'>
        {Object.values(MODE).map(mode =>
          <label key={mode} className='crtx-mode-item'>
            <Radio checked={mode === currentMode} value={mode} onChange={() => handleChangeMode(mode)} />
            {labels[mode]}
          </label>
        )}
      </div>

      <React.Fragment>
        <CreateEdition
          visible={currentMode === MODE.CREATE}
          modelHandler={modelHandler}
          onUpdate={handleUpdate}
        />
        <DuplicateEdition
          visible={currentMode === MODE.DUPLICATE}
          modelHandler={modelHandler}
          onUpdate={handleUpdate}
        />
      </React.Fragment>
    </div>
  );
}

ModeSelector.propTypes = {
  modelHandler: PropTypes.any,
};

export default ModeSelector;
