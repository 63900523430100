import React from 'react';
import settingsManager from 'core/managers/settings';
import { translate } from 'core/services/localization';
import { PROVIDERS_OPTIONS } from 'components/private/OpenIdSettings/constants';

const getOpenIdProviderName = (providerId) => {
  return PROVIDERS_OPTIONS.find(o => o.value === providerId)?.text || providerId;
};

const getOpenIdLink = () => {
  const { supportOpenId = 'notSupported', activeProvider } = settingsManager.get('openIdSettings') || {};
  if (supportOpenId !== 'notSupported' && activeProvider) {
    const { protocol, host } = window.location;

    return {
      url: `${protocol}//${host}/coretex/authenticationService?actionType=${activeProvider}`,
      text: translate('Sign in with {1}', getOpenIdProviderName(activeProvider))
    };
  }
};

export default function LoginWithLink(props) {
  const link = getOpenIdLink();

  if (!link) {
    return null;
  }

  return (
    <div>
      <a href={link.url}>
        {link.text}
      </a>
    </div>
  );
}
