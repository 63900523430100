import React, { useState } from 'react';
import { Dialog, DialogButtonsBar } from 'components/common/dialogs';
import TextInput from 'components/common/inputs/TextInput';
import Button from 'components/common/buttons/Button';
import { translate } from 'core/services/localization';
import settingsManager from 'core/managers/settings';
import LengthInput from 'components/common/inputs/LengthInput';

const DEFAULT_PLATE_WIDTH = 12; //inch
const DEFAULT_PLATE_HEIGHT = 19; //inch

export const AddEditPlateDialog = (
  { title, onClose, onAddEditPlate, editMode, targetPlate }) => {

  const [plateName, setPlateName] = useState(editMode ? targetPlate.name : translate('New Plate'));
  const [plateWidth, setPlateWidth] = useState(DEFAULT_PLATE_WIDTH);
  const [plateHeight, setPlateHeight] = useState(DEFAULT_PLATE_HEIGHT);

  const handleCreateEditPlate = () => {

    let plate = {};

    if (editMode) {
      plate = {
        ...targetPlate,
        name: plateName,
      }
    } else {
      plate = {
        elementType: 'plate',
        name: plateName,
        width: Number(plateWidth),
        height: Number(plateHeight)
      };
    }

    onAddEditPlate(plate);
    onClose();
  }

  return <Dialog
    onClose={onClose}
    title={title}
    modal={true}>

    <div className='crtx-form-section-container'>
      <div className='crtx-form-section'>
        <div className='crtx-form-two-columns'>

          <label>{translate('Name')}:</label>
          <TextInput className='crtx-form-element-full-width' value={plateName} onChange={(e, value) => {
            setPlateName(value)
          }} />

          {!editMode ? <React.Fragment>
            <label>{translate('Width')}:</label>
            <LengthInput
              className='crtx-form-element-full-width'
              unit={settingsManager.getLengthUnit()}
              value={plateWidth}
              allowEmptyValue={true}
              onChange={(event, value) => {
                setPlateWidth(value)
              }}
            />

            <label>{translate('Height')}:</label>
            <LengthInput
              className='crtx-form-element-full-width'
              unit={settingsManager.getLengthUnit()}
              value={plateHeight}
              allowEmptyValue={true}
              onChange={(event, value) => {
                setPlateHeight(value)
              }}
            />
          </React.Fragment> : undefined}
        </div>
      </div>
    </div>
    <DialogButtonsBar>
      <Button className='primary' onClick={handleCreateEditPlate}>
        {editMode ? translate('Save Plate') : translate('Create Plate')}
      </Button>
    </DialogButtonsBar>
  </Dialog>
}