import React from 'react';
import FragmentsListItemComponent from './FragmentsListItemComponent';
import PropTypes from 'prop-types';

export default function FragmentsListComponent({ fragments, status, onDoubleClick, onContextMenu }) {

  const renderFragment = (fragment, index) => {
    return <FragmentsListItemComponent key={index} fragment={fragment}></FragmentsListItemComponent>;

  };

  return (
    <div onDoubleClick={onDoubleClick} className='fragment-list-container' onContextMenu={onContextMenu}>
      <ul className='fragment-list'>
        {fragments.map(renderFragment)}
      </ul>
      <div className='fragment-list-status'>{status}</div>
    </div>
  );
}

FragmentsListComponent.propTypes = {
  fragments: PropTypes.array,
  status: PropTypes.string,
  onDoubleClick: PropTypes.func,
  onContextMenu: PropTypes.func

};
