import { CANVAS_AREA } from 'components/common/canvas/utilities';

export const selectCursorStyle = (
  {
    loading,
    missingPage,
    isDistanceTool,
    isDensityTool,
    isMoveTool,
    mouseDownArea,
    mouseMoveArea
  }) => {
  let name = 'default';

  if (loading) {
    name = 'wait';
  } else if (!missingPage) {
    if (mouseDownArea === CANVAS_AREA.RULER_CORNER) {
      name = 'crosshair';
    } else if (mouseDownArea === CANVAS_AREA.LEFT_RULER) {
      name = 'col-resize';
    } else if (mouseDownArea === CANVAS_AREA.TOP_RULER) {
      name = 'row-resize';
    } else if (mouseMoveArea === CANVAS_AREA.INSIDE) {
      if (isDistanceTool || isDensityTool) {
        name = 'crosshair';
      } else if (isMoveTool) {
        name = 'all-scroll';
      }
    }
  }

  // console.log('=== selectCursorStyle() mouseMoveArea, name => ', mouseMoveArea, name);

  return { cursor: name };
};