/**
 * @fileOverview  Utility file containing
 * system behavior method
 * 
 * @name sys
 * @namespace
 * @author sergey
 */

import dom from 'base/dom';
import settingsManager from 'core/managers/settings';

const sys = {

  /**
   * Shows up information dialog containing useful 
   * user information regarding the state of the system
   *   
   * @param  {string} type    One of possible values: Error/Info
   * @param  {string} message Information to be displayed to a user
   * @param  {number} timeout After given amount of time *in seconds* the dialog disappears [Optional]
   */
  inform: (type, message, timeout, reload) => {
    const localization = require('core/services/localization');

    switch (type) {
      case 'Error':
        dom.removeClass('#info');
        dom.addClass('#info', 'k-block k-error-colored');
        dom.html('#info h4', 'Error!');
        if (reload) {
          dom.html('#info div', message + '. The application will reload in ' + timeout + ' seconds.');
        }
        else {
          dom.html('#info div', message);
        }
        dom.show('#info');
        if (timeout) {
          setTimeout(function () {
            dom.hide('#info');
            if (reload) location.reload();
          }, timeout * 1000);
        }
        break;
      case 'Info':
        dom.removeClass('#info');
        dom.addClass('#info', 'k-block k-info-colored');
        dom.html('#info h4', 'Status:');
        dom.html('#info div', message);
        dom.show('#info');
        if (timeout) {
          setTimeout(function () {
            dom.hide('#info');
          }, timeout * 1000);
        }
        break;
      case 'InfoView':

        const { name, site } = settingsManager.settings.user || {};
        const userName = localization.translate('Logged in User: {1}', name + ' (' + site + ')');

        dom.removeClass('#info', 'k-block');
        dom.addClass('#info', 'info-view ');
        dom.html('#info h4', userName);
        dom.html('#info div', message);

        dom.show('#info');
        if (timeout) {
          setTimeout(function () {
            dom.hide('#info');
          }, timeout * 1000);
        }
        break;
      case 'Success':
        dom.removeClass('#info');
        dom.addClass('#info', 'k-block k-success-colored');
        dom.html('#info h4', 'Success!');
        dom.html('#info div', message);
        dom.show('#info');
        if (timeout) {
          setTimeout(function () {
            dom.hide('#info');
          }, timeout * 1000);
        }
        break;
    }
  }
}

module.exports = sys;

