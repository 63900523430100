import settingsManager from 'core/managers/settings';
import {translate} from 'core/services/localization';

export const PASSWORD_RULE_MESSAGES = {
  emptyPasswordCase: translate('Empty password not allowed'),
  minLengthCase: () => translate('Minimum password length: {1} characters', settingsManager.get('passwordPolicy').minimumPasswordLength),
  lowerCharacterCase: translate('The password must contain at least one lower case character'),
  upperCharacterCase: translate('The password must contain at least one upper case character'),
  numberCase: translate('The password must contain at least one number'),
  confirmationCase: translate('New password and confirm password do not match')
}

export const checkEmptyRule = (newPassword = '') => {

  return settingsManager.get('passwordPolicy').AllowEmptyPassword || (newPassword ? newPassword.length > 0 : false);
};

export const checkMinLengthRule = (newPassword = '') => {

  const passwordPolicy = settingsManager.get('passwordPolicy');
  const minimumPasswordLength = passwordPolicy.minimumPasswordLength || 0;

  return settingsManager.get('passwordPolicy').AllowEmptyPassword || (passwordPolicy.MinimumNumberOfCharacters ? newPassword.length >= minimumPasswordLength : true);
};

export const checkLowerCaseRule = (newPassword = '') => {
  const passwordPolicy = settingsManager.get('passwordPolicy');

  return settingsManager.get('passwordPolicy').AllowEmptyPassword || (passwordPolicy.MustContainLowerCase ? newPassword.match(/^.*[a-z]/) !== null : true);
};

export const checkUpperCaseRule = (newPassword = '') => {
  const passwordPolicy = settingsManager.get('passwordPolicy');

  return settingsManager.get('passwordPolicy').AllowEmptyPassword || (passwordPolicy.MustContainUpperCase ? newPassword.match(/^.*[A-Z]/) !== null : true);
};

export const checkNumberRule = (newPassword = '') => {
  const passwordPolicy = settingsManager.get('passwordPolicy');

  return settingsManager.get('passwordPolicy').AllowEmptyPassword || (passwordPolicy.MustContainNumber ? newPassword.match("(.*[0-9].*)") !== null : true);
};
