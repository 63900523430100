import { ROW_HEIGHT, ROW_HEIGHT_INCLUDING_THUMBNAIL } from 'widgets/ReactDataGrid/utils';
import { columnsDefaultOrder } from '../columnsCreator';
import { magazineComparator, categoryDateComparator, getFilterOptionValue, getFilterOptionText, sorterCreator, getSortComparator, SORT_ORDER } from '../utils';

const defaultSorter = sorterCreator('mniNumber', { [SORT_ORDER.ASC]: 'caseinsensitive', [SORT_ORDER.DESC]: 'caseinsensitivedecending' }, 'mniNumber');

export default preferences => ({
  pages: [],
  sorters: [defaultSorter],
  sortComparator: getSortComparator([defaultSorter]),
  filteredPages: [],
  filters: [
    { name: 'magazines', label: 'Magazines', comparator: magazineComparator, getOptionValue: getFilterOptionValue(['category']), getOptionText: getFilterOptionText(['category']), options: [{ text: 'All', value: '', count: 1 }], value: '' },
    { name: 'categoryDate', label: 'Category Date', comparator: categoryDateComparator, getOptionValue: getFilterOptionValue(['categoryDate', 'time']), getOptionText: getFilterOptionText(['categoryDate', 'localizedDate']), options: [{ text: 'All', value: '', count: 1 }], value: '' }
  ],
  selectedPages: [],
  tablePreferencesTimeStamp: Date.now(),
  fixToLeftColumnsCount: preferences.fixToLeftColumnsCount || 0,
  columnsOrder: preferences.columnsOrder || columnsDefaultOrder,
  hiddenColumns: preferences.hiddenColumns || {},
  columnsWidth: preferences.columnsWidth || {},
  currentRowHeight: typeof (preferences.hiddenColumns || {}).pageContentNwid !== 'undefined' ? ROW_HEIGHT : ROW_HEIGHT_INCLUDING_THUMBNAIL
});