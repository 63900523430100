import React, { Component } from 'react';
import PropTypes from 'prop-types';
import localization, { translate } from 'core/services/localization';
import SeperationPreview from 'src/components/private/separations/SeperationPreview';
import settingsManager from 'core/managers/settings';
import { fromServerDate } from 'core/dates';

const labels = {
  press: translate('Press'),
  tower: translate('Tower'),
  cylinder: translate('Cylinder'),
  sector: translate('Sector'),
  location: translate('Location'),
  productionRun: translate('Production Run'),
  deadline: translate('Deadline'),
  expected: translate('Expected'),
  yes: translate('Yes'),
  no: translate('No'),
  prodRun: translate('Prod Run'),
  webReduction: translate('Web Reduction')
}
const roundTo = (value, decimalPlaces, defaultValue) => {
  var defValue = typeof defaultValue === 'undefined' ? value : defaultValue;
  return isNaN(value) ? defValue : +Number(value).toFixed(decimalPlaces);
};

module.exports = class PlateTable extends Component {
  constructor(props) {
    super(props);
    this.getPlateRow = this.getPlateRow.bind(this);
  }

  static propTypes = {
    plateTableStore: PropTypes.any,
    openerType: PropTypes.string//dialog or rightPanel
  }
  getPlateRow(plate, index) {
    const { openerType } = this.props;
    const seperationPreview = <SeperationPreview colorName={plate.separationName} shared={plate.separationShared} />;
    const classNameVirtualPlate = plate.expected ? 'virtual-plate' : undefined;    
    const webReduction = settingsManager.measurUnit === 'inch' ? roundTo(plate.webReduction, 4, 0) : roundTo(plate.webReduction * 25.4, 3, 0);
    const expectedPlate = plate.expected ? labels.no : labels.yes;
    const expectedCol = openerType === 'dialog' ? <td className='plates-tbody-col' title={expectedPlate}>{expectedPlate}</td> : undefined;
    const plateRow = <tr key={index} className={`plates-tbody-row ${classNameVirtualPlate}`}>
      <td className='plates-tbody-col'>{seperationPreview}</td>
      <td className='plates-tbody-col' title={plate.press}>{plate.press}</td>
      <td className='plates-tbody-col' title={plate.tower}>{plate.tower}</td>
      <td className='plates-tbody-col' title={plate.cylinder}>{plate.cylinder}</td>
      <td className='plates-tbody-col' title={plate.cylinderSector}>{plate.cylinderSector}</td>
      <td className='plates-tbody-col' title={plate.cylinderLocation}>{plate.cylinderLocation}</td>
      <td className='plates-tbody-col' title={webReduction}>{webReduction}</td>
      {expectedCol}
    </tr>;
    return plateRow;
  }

  render() {

    const { plateTableStore, openerType } = this.props;
    const expectedCol = openerType === 'dialog' ? <th className='plates-thead-col'>{labels.expected}</th> : undefined;
    const productionRun = openerType === 'dialog' ? labels.productionRun : labels.prodRun;
    const tableRows = plateTableStore.plates.map(this.getPlateRow);
    const deadline = localization.toLocaleShortDateTime(fromServerDate(plateTableStore.deadline));

    const plateTabel = <table className='plates-table'>
      <thead className='plates-thead'>
        <tr className='plates-thead-row'>
          <th className='plates-thead-col'></th>
          <th className='plates-thead-col'>{labels.press}</th>
          <th className='plates-thead-col'>{labels.tower}</th>
          <th className='plates-thead-col'>{labels.cylinder}</th>
          <th className='plates-thead-col'>{labels.sector}</th>
          <th className='plates-thead-col'>{labels.location}</th>
          <th className='plates-thead-col'>{labels.webReduction}</th>
          {expectedCol}
        </tr>
      </thead>
      <tbody className='plates-tbody'>
        {tableRows}
      </tbody>
    </table>;

    return <div className='plates-table-panel'>
      <div className='custom-info-row'><span className='custom-info-row-title' title={labels.productionRun}>{productionRun}: </span><span className='custom-info-row-content ellipsis' title={plateTableStore.productionRun}>{plateTableStore.productionRun}</span></div>
      <div className='custom-info-row'><span className='custom-info-row-title'>{labels.deadline}: </span><span className='custom-info-row-content ellipsis' title={deadline}>{deadline}</span></div>
      {plateTabel}
    </div>;
  }

}