import React from 'react';
import SeparationHeader from './separationHeader';
import Separation from './separation';

export default class extends React.Component {
  static defaultProps = {
    separations: [],
    status: undefined,
    definition: undefined,
    style: undefined
  };

  renderHeader = (separations, status, definition) => {
    return <SeparationHeader separations={separations} status={status} definition={definition} />;
  };

  renderSeparations = (separations, definition) => {
    return separations.map(function (separation, index) {
      return <Separation key={index} separation={separation} definition={definition}></Separation>;
    });
  };

  render() {
    var component = this,
      {
          definition,
        separations,
        status
        } = this.props,
      style = this.props.style;
    return <ul className="SeparationsList" style={style}>
      {this.renderHeader(separations, status, definition)}
      {this.renderSeparations(separations, definition)}
    </ul>;
  }
}