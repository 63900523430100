import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactComponents from 'widgets/ReactComponents/src/index';
import SimpleFormComponent from '../SimpleFormComponent';
import actions from '../actions';

var { Accordion } = ReactComponents;

export default SimpleFormComponent(class extends Component {
  static propTypes = {
    store: PropTypes.object,
    bind: PropTypes.string, // expanded panel ID or path
    style: PropTypes.object,
    className: PropTypes.string,
    onToggleCollapse: PropTypes.func
  };

  static defaultProps = {};

  handleToggleCollapse = (id) => {
    var { store, bind, onToggleCollapse } = this.props;

    if (typeof onToggleCollapse === 'function') {
      onToggleCollapse(bind, id);
    }
    else {
      var expandedId = store.get(bind);
      expandedId = expandedId === id ? '' : id;
      store.dispatch(actions.update(bind, expandedId));
    }
  };

  getExpandedId = () => {
    var { store, bind } = this.props;

    var expandedId = store.get(bind);

    return expandedId;
  };

  render() {
    var { style, children, onToggleCollapse, ...props } = this.props;
    var accordionStyle = {
      width: '100%',
      ...style
    };


    return (
      <Accordion
        style={accordionStyle}
        expandedId={this.getExpandedId()}
        {...props }
        onToggleCollapse={this.handleToggleCollapse}
      >
        {children}
      </Accordion>
    );
  }
});
