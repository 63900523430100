import React from 'react';
import iconService from 'core/services/iconService';

const DIFF_STATUS_TYPES_ICONS_SUCCESS = {
  'success': { icon: 'success' },
  'waiting': { icon: 'wait' },
  'hold': { icon: 'hold' },
  'processing': { icon: 'in_progress' }
};

export default class extends React.Component {
  static defaultProps = {
    status: undefined,
    definition: undefined
  };

  getStyle = () => {
    return {
      width: 12,
      height: 12
    };
  };

  getStatusTypeIcon = (flowStep, flowStepIconName, statusType) => {
    var statusTypeLowerCase = typeof statusType !== 'undefined' ? statusType.toLowerCase().trim() : undefined;

    if (!flowStep || typeof statusType === 'undefined') {
      return undefined;
    }

    if (statusTypeLowerCase === 'error') {
      return iconService.getTemplateIcon(flowStep, 'tiny', flowStepIconName);
    }

    if (typeof DIFF_STATUS_TYPES_ICONS_SUCCESS[statusTypeLowerCase] !== 'undefined') {
      return iconService.getModuleIcon('MyBirdeye', DIFF_STATUS_TYPES_ICONS_SUCCESS[statusTypeLowerCase].icon);
    }

    return undefined;
  };

  render() {
    var {
      status,
      definition
    } = this.props,
      statusTypeIcon = this.getStatusTypeIcon(status.flowStepType, status.flowStepIconName, status.statusType),
      style = this.getStyle();

    return <div className="separation-item-status-type">
      <img src={statusTypeIcon} />
    </div>;
  }
}