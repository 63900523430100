/**
 * @name MNIAdsTable
 * @file MNIAdsTable component
 *
 * @author Boris
 * @since: 2019-11-21
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { localization } from 'sandbox';
import Table, { Column, cells, headers } from 'widgets/ReactDataGrid';
import Button from 'components/common/buttons/Button';
import Dropdown from 'components/common/dropdown/Dropdown';
import TableLayout from 'components/private/mni/TableLayout';
import { FILTER_COMMON_OPTIONS, STATUS_MAP } from './constants';
import { defaultCellDataGetter, defaultShouldCellUpdate } from './columnsCreator';
import { toLocaleShortDateTime } from 'core/services/localization';

const { Text } = cells;
const { MultiSortHeader } = headers;

const { translate } = localization;

export default class MNIAdsTable extends Component {
  static propTypes = {
    model: PropTypes.object
  };

  renderFilters() {
    const { module, viewModel } = this.props;

    return (
      <div className='mni-ads-filters'>
        <div>{translate('Filter By:')}</div>
        {viewModel.filterBy.map((item, index) => {
          const options = FILTER_COMMON_OPTIONS.concat(item.values.map(val => ({ value: val, text: val })));
          const selectedValue = item.selected || options[0].value;

          return (
            <div key={index} className='mni-ads-filters-item'>
              <div>{item.caption}</div>
              <Dropdown className='mni-ads-filters-dropdown'
                value={selectedValue} options={options}
                onSelect={(event, option, index) => module.handleFilterSelect(item.key, option)}
              />
            </div>
          );
        })}

        <Button className="mni-ads-filters-button" onClick={e => module.handleResetFiltersClick()}>
          {translate('Reset')}
        </Button>
      </div>
    );
  }

  renderLegend() {
    return (
      <div className='mni-ads-legend'>
        <div>{translate('Legend:')}</div>
        {Object.values(STATUS_MAP).map((status, index) => {
          return (
            <div key={index} className={'mni-ads-legend-item'}>
              <div className={'mni-ads-legend-item-color'} style={{ backgroundColor: status.color }} />
              <div>{status.displayName}</div>
            </div>
          );
        })}
      </div>
    );
  }

  renderFooter() {
    const { viewModel } = this.props;
    const publication = viewModel.publication;

    const inputTime = toLocaleShortDateTime(publication.inputTime);

    return (
      <div className='mni-ads-footer'>
        <div className={'mni-ads-footer-item'}>
          <div className={'mni-ads-legend-item-caption'}>{translate('Plan Version:')}</div>
          <div>{publication.planVersion}</div>
        </div>
        <div className={'mni-ads-footer-item'}>
          <div className={'mni-ads-legend-item-caption'}>{translate('Input Time:')}</div>
          <div>{inputTime}</div>
        </div>
      </div>
    );
  }

  renderAdsTable() {
    const { module, viewModel, adsTableColumns, tableKey, currentRowHeight } = this.props;

    const height = '100%';

    return (
      <div className='mni-ads-table' style={{ height }}>
        <Table
          key={tableKey}
          rows={viewModel.pages}
          columnKey='id'
          rowHeight={currentRowHeight}
          virtualScroll={true}
          onSelect={selectedRows => module.handleAdsTableSelect(selectedRows)}
          onRowContextMenu={(rowIndex, rowContent, selectedRows, e) => module.handleAdsTableContextMenu(rowContent, selectedRows, e)}
          onColumnResize={(columns, columnKey) => module.handleAdsTableColumnResize(columns, columnKey)}
        >

          {adsTableColumns.map(col => {
            const columnKey = col.key || col.displayName;
            const idx = viewModel.columnsToSort.findIndex(col => col.key === columnKey);
            const sortDirection = idx < 0 ? '' : viewModel.columnsToSort[idx].ascending ? 'asc' : 'desc';

            return <Column
              key={columnKey}
              columnKey={columnKey}
              visible={col.visible}
              fixed={col.fixed}
              width={col.width || 100}
              resizable={col.resizable !== false}
              align={col.align || 'left'}
              header={<MultiSortHeader tooltip={col.tooltip || col.displayName}
                sortable={col.sortable}
                sortDirection={sortDirection}
                sortOrder={idx + 1}
                onSort={(multiSort) => module.handleSort(columnKey, multiSort)}>
                {col.displayName}
              </MultiSortHeader>}
              cell={col.cell || Text}
              cellDataGetter={col.cellDataGetter || defaultCellDataGetter(viewModel)}
              shouldCellUpdate={col.shouldCellUpdate || defaultShouldCellUpdate}
            />
          }
          )}
        </Table>

      </div>
    );
  }

  render() {
    return (
      <div className='mni-ads-table-view'>
        <TableLayout
          renderFilters={this.renderFilters()}
          renderLegend={this.renderLegend()}
          renderFooter={this.renderFooter()}
        >
          {this.renderAdsTable()}
        </TableLayout>
      </div>
    );
  }
}