import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class TableLayout extends Component {

    static propTypes = {
        className: PropTypes.string,
        renderHeader: PropTypes.oneOf([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element)
        ]),
        renderFilters: PropTypes.oneOf([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element)
        ]),
        renderLegend: PropTypes.oneOf([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element)
        ]),
        children: PropTypes.oneOf([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element)
        ]),
        renderFooter: PropTypes.oneOf([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element)
        ])
    };

    renderHeader = () => {
        const { renderHeader } = this.props;
        return (
            <div className="crtx-mni-table-layout-section"> {renderHeader}
            </div>
        );
    };

    renderFilters = () => {
        const { renderFilters } = this.props;
        return (
            <div className="crtx-mni-table-layout-section"> {renderFilters}
            </div>
        );
    };

    renderLegend = () => {
        const { renderLegend } = this.props;

        return (
            <div className="crtx-mni-table-layout-section"> {renderLegend}
            </div>
        );
    };

    renderFooter = () => {
        const { renderFooter } = this.props;

        return (
            <div className="crtx-mni-table-layout-section"> {renderFooter}
            </div>
        );
    };


    render() {
        const { className, renderHeader, renderFilters, renderLegend, children, renderFooter } = this.props;

        return <div className={`crtx-mni-table-layout-container ${className}`}>
            <div className="crtx-mni-table-layout-panel">
                {renderHeader && this.renderHeader()}
                {renderFilters && this.renderFilters()}
                {renderLegend && this.renderLegend()}
                <div className='crtx-mni-table-layout-table-container'>{children}</div>
                {renderFooter && this.renderFooter()}
            </div>
        </div >
    }
}