import React from 'react';
import { createRoot } from 'react-dom/client';
import { createStore } from 'redux';
import sandbox from 'sandbox';
import AbstractModule from 'AbstractModule';
import reducer from './reducers/reducer';
import Flipbook from './components/flipbook';


const isFunction = o => typeof o === 'function';
const isUndefined = o => typeof o === 'undefined';

const translate = sandbox.localization.translate;

export default AbstractModule.extend({

  init: function () {
    this._super();

    for (var key in this) {
      if (key !== '_super' && isFunction(this[key])) {
        this[key] = this[key].bind(this);
      }
    }
  },

  initDone: function () {

    this.store = createStore(reducer, {
      projectorId: this.projectorId,
      windowNwid: this.nwid,
      viewId: this.id,
      windowRef: this.win,
      isInColorManagementMode: false
    });
    this.reactRoot = createRoot(this.domElement);
    this.store.subscribe(this.render);
    this.render();
  },

  flipbookGetUrls: function (data) {
    const imageUrls = [];
    var pageNumber = 0;

    if (data.model.section !== null || data.model.section !== undefined) {
      var items = (data.model.section !== null) ? data.model.section.neighborItems : [];//these don't come in sorted
      items.sort(function (a, b) {
        return (a.index - b.index);
      });
      if (items !== undefined || items !== null) {
        var bPano = items[0].isPanoPage;//first page
        for (var i = 0; i < items.length; i++) {
          var lastUrl = urlParams;
          var urlParams = {
            rootType: data.model.type,
            imageType: data.model.imageType,
            template: items[i].content.type,
            action: "full",
            nwid: items[i].content.nwid,
            iconUrlCounter: items[i].content.version,
            projectorId: this.projectorId,
            isPano: items[i].isPanoPage,
            pageAction: data.model.pageAction
          };
          if (bPano) {//the view definition has changed so the second pano page does not arrive to the client anymore (//if last page was pano load the last image twice)
            // aPageURLs.push(sandbox.request.getImageUrl(lastUrl));
            imageUrls.push({
              url: sandbox.request.getImageUrl(lastUrl),
              isPano: bPano
            });
          }
          const url = sandbox.request.getImageUrl(urlParams, true);
          const cmUrl = this.getColorManageUrl(url);

          imageUrls.push({
            url,
            cmUrl,
            isPano: items[i].isPanoPage
          });

          bPano = items[i].isPanoPage;//current page pano?

          if (items[i].nwid === data.model.nwid) {
            pageNumber = i + 1;
          }
        }
      }
    }
    return { urls: imageUrls, currentPageNumber: pageNumber };

  },

  firstTickReceived: function (data) {
    // const { windowRef } = this.store.getState();

    const imageUrls = this.flipbookGetUrls(data);

    const navigatorData = {
      items: [],
      filters: []
    };

    this.navigator.setControls(navigatorData);

    this.store.dispatch({
      type: 'ADD_URLS',
      imageUrls
    });

    this.win.document.addEventListener('keydown', this.handleDocumentKeyDown);
  },

  tickUpdate: function (data) {
    const navigatorData = {
      items: [],
      filters: []
    };

    this.navigator.setControls(navigatorData);
    console.log('tick update page pdf view');
  },

  getColorManageUrl: function (url) {
    const { windowRef } = this.store.getState();

    let newUrl = url;
    const monitorName = windowRef.localStorage.getItem("monitorProfile"); //TODO: change localstorage name from monitorProfile to monitorName, same with press...;
    const pressName = windowRef.localStorage.getItem("pressProfile");

    if (monitorName !== '' && pressName !== '') {
      newUrl = newUrl.replace("action=full", "action=full-cmyk");
      newUrl = newUrl.replace("action=icon", "action=full-cmyk");
      newUrl = newUrl + "&colorManage=true";
      newUrl = newUrl + "&monitorName=" + monitorName;
      newUrl = newUrl + "&pressName=" + pressName + "&";
    }
    else {
      //get the full image always
      newUrl = newUrl.replace("action=full-cmyk", "action=full");
      newUrl = newUrl.replace("action=icon", "action=full");
      newUrl = newUrl.slice(0, newUrl.search('&colorManage'));
    }
    return newUrl;
  },

  handleColorManagementButtonClick: function (isInColorManagementMode, currentPage) {
    this.store.dispatch({
      type: 'UPDATE_COLOR_MANAGEMENT_MODE',
      isInColorManagementMode,
      currentPage
    });
  },

  handleDocumentKeyDown: function (e) {
    switch (e.code) {
      case 'F5':
        e.preventDefault();
        break;
    }
  },

  destroy: function () {
    this._super();
    this.win.document.removeEventListener('keydown', this.handleDocumentKeyDown);
    this.reactRoot.unmount();
  },

  render: function () {
    if (isUndefined(this)) return;
    const { imageUrls, windowRef, isInColorManagementMode } = this.store.getState();

    this.reactRoot.render(
      <Flipbook imageUrls={imageUrls}
        windowRef={windowRef}
        isInColorManagementMode={isInColorManagementMode}
        onColorManagementButtonClick={this.handleColorManagementButtonClick}
      />);
  }
});

