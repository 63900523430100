import React from "react";
import TMIBaseTableView from "../TMIBaseTableView/TMIBaseTableView.js";
import columnsCreator from "./columnsCreator";
import sandbox from "sandbox";
import { fromServerDate, getMaxDate } from 'core/dates';
import { updateItem, addItem, removeItem } from "./actionsCreator";
import { Pages } from './Pages';
import TextInput from "components/common/inputs/TextInput";
import Dropdown from "components/common/dropdown/Dropdown";
import { getMainStatus } from 'utilities/statuses.js';


const { translate } = sandbox.localization;
const labels = {
  'publication': translate('Publication'),
  'advertiser': translate('Advertiser'),
  'stitched': translate('Stitched'),
  'nonStitched': translate('Non-Stitched'),
  'pickup': translate('Pickup'),
  'nonPickup': translate('Non-Pickup'),
  'preliminary': translate('Preliminary'),
  'final': translate('Final'),
  'adState': translate('AD State'),
  'all': translate('All')
};


const STITCHED_OPTIONS = [
  { value: 'stitched', text: labels.stitched },
  { value: 'non-stitched', text: labels.nonStitched },
  { value: 'all', text: labels.all }
];

const PICKUP_OPTIONS = [
  { value: 'pickup', text: labels.pickup },
  { value: 'non-pickup', text: labels.nonPickup },
  { value: 'all', text: labels.all }
];

const ADSTATE_OPTIONS = [
  { value: 'pre', text: labels.preliminary },
  { value: 'final', text: labels.final },
  { value: 'all', text: labels.all }
];

const isUndefined = o => typeof o === "undefined" || o === null;
const isNull = o => o === null;
const isString = o => typeof o === "string";
const isMultiplePagesPDF = page => !isUndefined(page) && page.multiple === "1";

const isRejectedPage = status => status.flowStepType === 'workflow/step/flow/approval' && status.statusType === 'Error';

const legendColors = {
  gray: "#ebebeb",
  green: "#85ec85",
  red: "#ff4437",
  white: "#ffffff"
};

const flowStepIconNameGetter = status => {
  return status.time &&
    status.statusType !== 'NotAssigned' &&
    status.flowStepType
    ? sandbox.icons.getTemplateIcon(
      status.flowStepType,
      'tiny',
      status.flowStepIconName
    )
    : '';
};

const statusIconNameGetter = status => {
  if (!status.time || status.statusType === 'NotAssigned') {
    return '';
  };

  const statusType = status.statusType.toLowerCase();

  if (statusType === 'waiting')
    return sandbox.icons.getGeneralIcon('status', 'wait');
  if (statusType === 'success')
    return sandbox.icons.getGeneralIcon('status', 'finished');
  if (
    statusType === 'error' &&
    status.flowStepType === 'workflow/step/flow/approval'
  )
    return sandbox.icons.getModuleIcon('Thumbnail', 'reject-small');

  return sandbox.icons.getGeneralIcon('status', statusType);
};

const transmitionsStatusTimeGetter = (transmitionStatus) => {
  const time = (transmitionStatus || {}).time;
  const localizedTime =
    !isUndefined(time) && time !== ""
      ? sandbox.localization.toLocaleShortDateTime(time)
      : "";

  if (isUndefined(transmitionStatus) || isNull(transmitionStatus))
    return { status: "waiting", time, localizedTime }; // legendColors.white;
  if (
    transmitionStatus.progress === "100" &&
    isString(transmitionStatus.statusType) &&
    transmitionStatus.statusType.toLowerCase() === "success"
  )
    return { status: "completed", time, localizedTime }; // legendColors.green;
  if (
    isString(transmitionStatus.statusType) &&
    transmitionStatus.statusType.toLowerCase() === "error"
  )
    return { status: "error", time, localizedTime }; // legendColors.red;
};

const transimitionStatusTime = (transmissionStatuses) => {
  return (transimitionStatuses, nwid) => {
    const transmissionStatus = transmissionStatuses[nwid];

    return {
      ...transimitionStatuses,
      [nwid]: transmitionsStatusTimeGetter(
        transmissionStatus
      )
    };
  };
};

const singlePDFTransmitionStatusTime = (page) => {
  return Object.keys(page.transmissionStatuses).reduce(
    transimitionStatusTime(page.transmissionStatuses),
    {}
  );
};

const pageIssueDate = (issueDate) => {
  if (issueDate) {
    const time = fromServerDate(issueDate);
    return {
      time,
      localizedTime: sandbox.localization.toLocaleShortDateTime(time)
    }
  }
}

const stitchedStatusTime = (page, property) => {
  const pageContent = page.pageContent || {};
  const status = pageContent[`${property}:status`];
  const time = pageContent[`${property}:time`] || "";
  const schedualed = pageContent[`${property}:schedualed`];
  const date = time ? new Date(time) : undefined;
  const localizedTime = date ? sandbox.localization.toLocaleShortDateTime(date) : '';

  if (schedualed === "0")
    return { status: "notPlanned", time: getMaxDate(), localizedTime: "" };
  if (isUndefined(status) || isUndefined(time))
    return { status: "waiting", time: getMaxDate(), localizedTime: "" };
  if (status === "complete")
    return { status: "completed", time: date, localizedTime };
  if (status === "error") return { status: "error", time: date, localizedTime };
};

const stitchedPages = (tmiPages, deprecatedSources) => {
  const pagesArr = tmiPages.split(';');
  const deprecatedPagesArr = deprecatedSources.split(';');
  return <Pages pages={pagesArr} deprecatedPages={deprecatedPagesArr} />;
}

const pageCreator = (page = {}) => {
  const plannedSites = Object.keys(page.transmissionStatuses).reduce((plannedSitesMap, site) => {
    return {
      ...plannedSitesMap,
      [site]: true
    };
  }, {});
  const status = getMainStatus(page) || {};

  return {
    nwid: page.nwid,
    tmiNumber: page.label,
    label: page.label,
    inputFileName: page.inputFileName,
    pageContentNwid: page.pageContent.nwid,
    version: page.pageContent
      ? page.pageContent.contentVersionMajorIndex ||
      page.pageContent.externalVersion
      : "1",
    advertiser: page.advertiser,
    publication: page.publication,
    flowStepIconName: flowStepIconNameGetter(status),
    statusIconName: statusIconNameGetter(status),
    iconUID: Date.now(),
    inputTime: stitchedStatusTime(page, "TMI Input"),
    transmissionStatuses: singlePDFTransmitionStatusTime(page),
    plannedSites,
    deprecated: page.deprecated === 'true',
    materialsId: page.materialsId,
    adType: page.adType,
    issueDate: pageIssueDate(page.issueDate),
    stitched: page.stitched === 'true',
    rejected: isRejectedPage(status),
    pickup: page.pickup === 'true',
    blockMail: page.blockMail === 'true',
    adSpecification: page.adSpecification
  };
};

const stichedPDFSCreator = (page = {}) => {
  const hasDeprecatedSources = (page.deprecatedSources || '').length > 0;
  const content = (page.tmiPages || '').replace(/;/g, ", ");
  return {
    nwid: page.nwid,
    pages: hasDeprecatedSources && page.tmiPages ? stitchedPages(page.tmiPages, page.deprecatedSources) : content,
    title: content,
    name: page.label,
    advertiser: page.advertiser,
    publication: page.publication,
    adType: page.adType,
    issueDate: pageIssueDate(page.issueDate),
    stitchStatusTime: stitchedStatusTime(page, "Stitch pdf"),
    archiveStatusTime: stitchedStatusTime(page, "Tmi Archive"),
    mailStatusTime: stitchedStatusTime(page, "MAIL"),
    version: page.pageContent.contentVersionMajorIndex,
    inputFileName: page.label + '.pdf',
    blockMail: page.blockMail === 'true',
    adSpecification: page.adSpecification,
    hasDeprecatedSources
  };
};

export default TMIBaseTableView.extend({
  legend: {
    waiting: legendColors.white,
    completed: legendColors.green,
    error: legendColors.red,
    notPlanned: legendColors.gray
  },

  initDone: function () {
    this.tickableModel.register(["page"], this.handlePage);
    this.tickableModel.register(
      ["page", "page/content"],
      this.handlePageContent
    );
    this.tickableModel.register(
      ["page", "status_queue/value"],
      this.handlePageStatus
    );
  },

  getPageIndexInState: function (page) {
    return this.store.getState().items.findIndex(p => p.nwid === page.nwid);
  },

  renderFilters: function () {
    const { filterBy } = this.store.getState();

    return (
      <div className="title-content">
        {labels.publication}:
        <TextInput className='crtx-Publication-filter'
          value={filterBy.publication}
          onChange={this.handleFilterChange('publication')}
        />
        {labels.advertiser}:
        <TextInput className='crtx-Advertiser-filte'
          value={filterBy.advertiser}
          onChange={this.handleFilterChange('advertiser')}
        />
        <div className='crtx-stitched-filter'> {labels.stitched}: <Dropdown options={STITCHED_OPTIONS} value={filterBy.stitched} onSelect={this.handleFilterChange('stitched')} /></div>
        <div className='crtx-pickup-filter'> {labels.pickup}: <Dropdown options={PICKUP_OPTIONS} value={filterBy.pickup} onSelect={this.handleFilterChange('pickup')} /></div>
        <div className='crtx-adState-filter'> {labels.adState}: <Dropdown options={ADSTATE_OPTIONS} value={filterBy.adState} onSelect={this.handleFilterChange('adState')} /></div>
      </div>
    );
  },

  handlePage: function (action, page) {
    const multiple = this.store.getState().multiple;

    if (
      (isMultiplePagesPDF(page) && multiple === "1") ||
      (!isMultiplePagesPDF(page) && multiple === "0")
    ) {
      const item =
        multiple === "0" ? pageCreator(page) : stichedPDFSCreator(page);
      if (action === "add") this.store.dispatch(addItem(item));
      if (action === "update")
        this.store.dispatch(updateItem(item, this.getPageIndexInState(item)));
      if (action === "remove") this.store.dispatch(removeItem(item));
    }
  },

  handlePageContent: function (action, pageContent) {
    if (action === "remove") return;

    const page = pageContent.getParent("page");
    const multiple = this.store.getState().multiple;

    if (
      (isMultiplePagesPDF(page) && multiple === "1") ||
      (!isMultiplePagesPDF(page) && multiple === "0")
    ) {
      const itemIndex = this.getPageIndexInState(page);

      if (itemIndex < 0) return;

      const item =
        multiple === "0" ? pageCreator(page) : stichedPDFSCreator(page);
      this.store.dispatch(updateItem(item, itemIndex));
    }
  },

  handlePageStatus: function (action, status) {
    if (action === "remove") return;

    const page = status.getParent("page");
    const multiple = this.store.getState().multiple;

    if (
      (isMultiplePagesPDF(page) && multiple === "1") ||
      (!isMultiplePagesPDF(page) && multiple === "0")
    ) {
      const itemIndex = this.getPageIndexInState(page);

      if (itemIndex < 0) return;

      const item =
        multiple === "0" ? pageCreator(page) : stichedPDFSCreator(page);
      this.store.dispatch(updateItem(item, itemIndex));
    }
  },

  tableName: function ({ multiple }) {
    return multiple === "0"
      ? translate("CW ADs Table")
      : translate("Stitched PDFs Table");
  },

  columnsCreator: function ({ multiple, items, sites, columnsToSortBy }) {
    return columnsCreator(
      multiple,
      items,
      sites,
      columnsToSortBy,
      this.legend,
      this.handleColumnClick
    );
  },

  renderLegnedItem: function (legendItem) {
    return (
      <div className="crtx-tmi-ads-table-view-legend-item">
        {legendItem.name}
        <div
          className="crtx-tmi-ads-table-view-legend-item-color"
          style={{ backgroundColor: legendItem.color }}
        />
      </div>
    );
  },

  renderLegend: function () {
    const legendItems = [
      { name: "Not Planned", color: this.legend.notPlanned },
      { name: "Error", color: this.legend.error },
      { name: "Planned", color: this.legend.waiting },
      { name: "Completed", color: this.legend.completed }
    ];
    return <div>{legendItems.map(this.renderLegnedItem)}</div>;
  }
});
