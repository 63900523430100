/**
 * @fileOverview
 * @author sergey
 */

define(['sandbox', 'AbstractAction', 'logger'], function(sandbox, AbstractAction, logger) {
  'use strict';

  var log = logger.getDefaultLogger();

  return AbstractAction.extend({
    isApplicable: function () {
      var selected = this.module.selected;
      if (typeof selected === 'undefined') { return false; }
      var i = 0, len = selected.length;
      if (len === 0) { return false; }
      for (i; i < len; i += 1) {
        if (selected[i].type !== 'workflow/event') { return false; }
      }
      return true;
    },

    execute: function (viewInstanceNwId, deviceNwId, type, projectorId) {
      var events = this.module.selected;
      if (!this.assertArguments(arguments, this.execute.length)) {
        log.error('Lock Events Action: device nwid, type, projector id should be defined');
        return;
      }
      sandbox.request.lockEvents(viewInstanceNwId, this.nwid, deviceNwId, type, projectorId,
        {
          config: this.config,
          items: events.map(function (e) {
            return {nwid: e.nwid};
          }),
          parameters: this.parameters
        }
      );
    }
  });
});