import React, { Component } from 'react';
import Button from 'components/common/buttons/Button';
import TextInput from 'components/common/inputs/TextInput';
import BrowserDialog from 'widgets/BrowserDialog';
import PropTypes from 'prop-types';

class BrowserInput extends Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onSelect: PropTypes.func,
    root: PropTypes.string,
    viewOrActionNwid: PropTypes.string,
    rootUrl: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    separator: PropTypes.string,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    inputStyle: PropTypes.object
  };

  static defaultProps = {
    value: '',
    onChange: () => { },
    onBlur: () => { },
    onSelect: () => { },
    root: '',
    className: '',
    separator: "\\",
    title: 'Browse',
    disabled: false
  };

  handleSelect(event, currentCursor, selectedItem) {
    const separator = this.props.separator;
    const path = currentCursor.map(function (item) {
      return item.name.replace(separator, '');
    }).join(separator);
    this.props.onChange(event, path);
    this.props.onSelect(event, path);
  }

  handleInputChange(event, value) {
    this.props.onChange(event, value);
  }

  handleButtonClick(event) {
    const viewOrActionNwid = this.props.viewOrActionNwid;
    const rootUrl = this.props.rootUrl;
    const root = this.props.root;
    const separator = this.props.separator;
    const value = this.props.value;
    const title = this.props.title;
    BrowserDialog.show(title, viewOrActionNwid, rootUrl, root, this.handleSelect, undefined, value, separator);
  }

  render() {
    const { value, onChange, onBlur, root, viewOrActionNwid, rootUrl, className, style, separator, disabled, inputStyle } = this.props;
    return <div className={`crtx-browser-input ${className}`} style={style}>
      <TextInput value={value} onChange={this.handleInputChange} onBlur={onBlur} disabled={disabled} style={inputStyle} />
      <Button onClick={this.handleButtonClick} disabled={disabled}>...</Button>
    </div>;
  }
}

module.exports = BrowserInput;