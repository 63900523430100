import PageView from '../NewPageView/NewPageView';
import ApprovalNeighborItems from './ApprovalNeighborItems';

export default PageView.extend({

  initDone: function () {
    this._super();

    this.neighborItems = new ApprovalNeighborItems(this);
  },

  firstTickReceived: function (data) {
    this.neighborItems.firstTickReceived(data);

    this._super(data);
  },

  tickUpdate: function (data) {
    this._super(data);

    this.neighborItems.tickUpdate(data);
  },

});