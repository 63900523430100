import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import ConversionInput from './ConversionInput';

const PERCENT_SYMBOL = '%';

function PercentInput({
                        noConversion = false,
                        ...props
                      }, ref) {

  return (
    <ConversionInput
      {...props}
      ref={ref}
      unitSymbol={PERCENT_SYMBOL}
      factor={noConversion ? 1 : 100}
    />
  );
}

const PercentInputWithRef = forwardRef(PercentInput);


PercentInputWithRef.propTypes = {
  ...ConversionInput.propTypes,
  noConversion: PropTypes.bool,
};

export default PercentInputWithRef;
