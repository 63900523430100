/**
 * @name TickableModel
 * @fileOverview
 * @author  guyb
 * @date    08/02/2017
 */

const EXCLUDE_REMOVE_PROPERTIES = ['status'];
const EMPTY_FUNCTION = function () { };
const isObject = (o) => typeof o === 'object' && o !== null;
const isArray = (o) => Array.isArray(o);
const isFunction = (o) => typeof o === 'function';
const isUndefined = (o) => typeof o === 'undefined';
const isNull = (o) => typeof o === null;
const isBoolean = (o) => typeof o === 'boolean';
const isDirty = (o) => o.__isDirty === true;
const isLink = (o) => o.link === 'true';
const splitter = '/';
const defineDirty = (o, value) => {
  Object.defineProperty(o, '__isDirty', {
    enumerable: false,
    writable: true,
    value: value
  });
};
const defineParent = (o, parent) => {
  Object.defineProperty(o, '__parent', {
    enumerable: false,
    writable: false,
    configurable: false,
    value: parent
  });
  Object.defineProperty(o, 'getParent', {
    enumerable: false,
    writable: false,
    configurable: false,
    value: (type) => {
      return getParent(parent, type);
    }
  });
  Object.defineProperty(o, 'getRoot', {
    enumerable: false,
    writable: false,
    configurable: false,
    value: (type) => {
      return getRoot(o);
    }
  });
};
const defineRelativeType = (o, parent) => {
  if (isArray(o)) return;

  const parentWithType = getParent(parent);
  const parentRelativeType = !isUndefined(parentWithType) ? parentWithType.__relativeType : undefined;

  Object.defineProperty(o, '__relativeType', {
    enumerable: false,
    writable: false,
    configurable: false,
    value: isUndefined(parentRelativeType) ? o.type : `${parentRelativeType}\\${o.type}`
  });
};

const getItemsByType = (model, type) => {
  return Object.keys(model).reduce((resolvedItems, key) => {
    if (key === 'type' && model[key] === type) return resolvedItems.concat(model);

    if (isArray(model[key])) return model[key].reduce((resolvedItemsWithNested, childItem) => {
      return resolvedItemsWithNested.concat(getItemsByType(childItem, type));
    }, resolvedItems);

    if (isObject(model[key])) return resolvedItems.concat(getItemsByType(model[key], type));

    return resolvedItems;
  }, []);
};
const getParent = (item, type) => {
  if (isUndefined(item)) return undefined;
  if (isObject(item) && !isArray(item) && isUndefined(type) && !isUndefined(item.type)) return item;
  if (isObject(item) && !isArray(item) && item.type === type) return item;

  return getParent(item.__parent, type);
};

const getRoot = (item) => {
  if (isUndefined(item.__parent)) return item;

  return getRoot(item.__parent);
};

const matchRuleShort = (str, rule) => {
  return new RegExp("^" + rule.split("*").join(".*") + "$").test(str);
};

class HandlersNode {
  constructor(type) {
    this.type = type;
    this.parents = {};
    this.handlers = [];
  }
  getParents = () => {
    return this.parents;
  };
  getParent = (type) => {
    if (!isObject(this.parents) || isUndefined(type)) return undefined;

    const foundKey = Object.keys(this.parents).find(key => matchRuleShort(type, key));

    return !isUndefined(foundKey) ? this.parents[foundKey] : undefined;
    // return isObject(this.parents) ? this.parents[type] : undefined;
  };
  hasParents = () => {
    return Object.keys(this.parents).length > 0;
  };
  hasParent = (type) => {
    return !isUndefined(this.getParent(type));
  };
  addParent = (parentType, handlers = []) => {
    this.parents[parentType] = this.parents[parentType] || new HandlersNode(parentType);
    this.parents[parentType].addHandlers(handlers);
    return this.parents[parentType];
  };
  addHandlers = (handlers = []) => {
    this.handlers = this.handlers.concat(handlers);
  };
  addHandler = (handler) => {
    this.addHandlers([handler]);
  };
  removeHandler = (handler) => {
    this.handlers = this.handlers.filter(h => h === handler);
  };
  getHandlers = (relativePath = '') => {
    if (relativePath === '') return this.handlers || [];

    const relativePathArray = relativePath.split(splitter).reverse();
    const topPathParent = relativePathArray.reduce((node, type) => {
      return node.getParent(type);
    }, this);
    return isUndefined(topPathParent) ? [] : topPathParent.getHandlers();
  };
};

class Handlers {
  constructor() {
    this.root = new HandlersNode('__tickable_model__handlers__root');
  }
  addHandler = (path, handler) => {
    const types = path.reverse();
    const topParent = this.addParents(this.root, types);

    topParent.addHandler(handler);

    return topParent;
  };
  addParents = (node, types) => {
    if (types.length === 0) return node;

    if (!node.hasParent(types[0])) node.addParent(types[0]);

    return this.addParents(node.getParent(types[0]), types.slice(1));
  };
  getParentModelWithType = (model) => {
    if (isUndefined(model)) return undefined;
    if (!isUndefined(model.type)) return model;

    return this.getParentModelWithType(model.__parent);
  };
  triggerHandlersForItem = (model, startModel, action, node) => {
    if (isUndefined(model) || isUndefined(node)) return;

    node.getHandlers().forEach(h => { h(action, startModel); });

    const parentModel = this.getParentModelWithType(model.__parent);
    const parentNode = isUndefined(parentModel) ? undefined : node.getParent(parentModel.type);

    this.triggerHandlersForItem(parentModel, startModel, action, parentNode);
  };
  trigger = (model, action) => {
    const node = this.root;

    node.getHandlers().forEach(h => { h(action, model); });
    this.triggerHandlersForItem(model, model, action, node.getParent(model.type));
  };
};

export default class TickableModel {
  constructor(options = {}) {
    var { tick, scope, onBeforeUpdate, onUpdate, onAction, onObjectParsed, markDirty } = options;
    this._model = {};
    this._objectsMap = {};
    this._nwidsToIdsMap = {};
    this._nwidLinks = {};
    this._addedObjects = {};
    this._tickHandler = tick;
    this._tickHandlerScope = scope;
    this._handlers = new Handlers();
    this.shouldMarkDirty = isBoolean(markDirty) ? markDirty : false;
    this.beforeUpdateHandler = isFunction(onBeforeUpdate) ? onBeforeUpdate : EMPTY_FUNCTION;
    this.updateHandler = isFunction(onUpdate) ? onUpdate : EMPTY_FUNCTION;
    this.actionHandler = isFunction(onAction) ? onAction : EMPTY_FUNCTION;
    this.objectParsedHandler = isFunction(onObjectParsed) ? onObjectParsed : EMPTY_FUNCTION;
  }

  register = (path, handler) => {
    const parent = this._handlers.addHandler(path, handler);
    return () => {
      parent.removeHandler(handler);
    };
  };

  parse = (model, parent, innerParseRunning = false) => {
    var acc = this._objectsMap;

    if (isObject(model)) {
      defineParent(model, parent);
      defineDirty(model, false);
      defineRelativeType(model, parent);
    }

    if (isObject(model) && !isArray(model) && model.id !== undefined) {
      acc[model.id] = model;
      if (model.link === 'true') {
        if (this._nwidLinks[model.nwid] === undefined) this._nwidLinks[model.nwid] = [];

        this._nwidLinks[model.nwid].push(model.id);
      }
      else if (model.link !== true) {
        this._nwidsToIdsMap[model.nwid] = model.id;
      }
    }

    for (var i in model) {
      if (isObject(model[i])) {
        this.parse(model[i], model, true);
      }
    }

    if (!isUndefined(model)) {
      this.objectParsedHandler.call(this, model);
      if (innerParseRunning) this._handlers.trigger(model, 'add');
    }

    return acc;
  };

  markDirty = (model, value) => {
    //If there is no need to mark the items as dirty on every change then return.
    if (!this.shouldMarkDirty) return;

    var obj = model;
    while (obj && obj.__isDirty !== value) {
      defineDirty(obj, value);
      if (!isUndefined(obj.nwid)) {
        this.getLinks(obj.nwid).forEach((link) => {
          this.markDirty(this.get(link), value);
        });
      }
      obj = obj.__parent;
    }
  };

  set = (model, property, value) => {
    model[property] = value;
    this.markDirty(model, true);
  };

  get = (id) => {
    return this._objectsMap[id];
  };
  
  getById = (id) => {
    return this._objectsMap[id];
  };

  getByNwid = (nwid) => {
    return this.get(this._nwidsToIdsMap[nwid]);
  };

  getItemsByType = (type) => {
    return getItemsByType(this._model, type);
  };

  getLinks = (nwid) => {
    if (isUndefined(nwid)) return this._nwidLinks;

    return this._nwidLinks[nwid] || [];
  };

  addChild = (model, child) => {
    if (!isArray(model)) return;

    model.push(child);
    this.markDirty(child, true);
  };

  addChildren = (model, children) => {
    children.forEach((child) => {
      this.addChild(model, child);
      // this.markDirty(child, true); //Doesn't need to be marked because it was marked in addChild function
    });

    return model;
  };

  addChildAt = (model, child, index) => {
    // this.parse(child, model);
    model.splice(index, 0, child);
    this.markDirty(child, true);
  };

  removeChild = (model, child) => {
    var index = model.indexOf(child);

    if (index < 0) return;

    model.splice(index, 1);
    this.markDirty(model, true);
  };

  clean = (model) => {
    if (model === null || !model.__isDirty) return;

    defineDirty(model, false);

    for (var i in model) {
      if (Array.isArray(model[i]) || typeof model[i] === 'object') {
        this.clean(model[i]);
      }
    }
  };

  firstTickHandler = (model) => {
    this._model = model;
    this._objectsMap = {};
    this._nwidLinks = {};
    this._addedObjects = {};
    this._dirtyIds = {};
    this.flatten(this._model, undefined);

    this.actionHandler('add', undefined, this._model);
    this._handlers.trigger(this._model, 'add');
  };

  tickUpdateHandler = (model) => {
    model.forEach((modelItem) => {
      if (!isObject(modelItem)) return;
      if (!modelItem.action) return;

      const actionHandler = this[modelItem.action.toLowerCase()];
      if (isFunction(actionHandler)) actionHandler(modelItem);
      this.merge();
    });
  };

  flatten = (model, parent) => {
    if (!isObject(model)) return this;

    this.parse(model, parent);
    return this;
  };

  checkAddParams = (model) => {
    var id = model["id"];
    var parentId = model['parentId'];
    var parent = parentId === undefined ? undefined : this._objectsMap[parentId];

    if (id === undefined) return false;
    if (parent === undefined) return false;
    if (!isUndefined(this._objectsMap[id]) && this._objectsMap[id] !== null) return false;

    return true;
  };

  add = (model) => {
    var parentId = model.parentId,
      parent = parentId === undefined ? undefined : this._objectsMap[parentId],
      childProperty = model.childPropertyId;

    if (!this.checkAddParams(model)) return this;

    if (Array.isArray(parent[childProperty])) {
      this.flatten(model, parent[childProperty]);
    }

    if (!Array.isArray(parent[childProperty])) {
      this.flatten(model, parent);
      this.set(parent, childProperty, model);
      this.actionHandler('add', parent, model);
      this._handlers.trigger(model, 'add');
      return this;
    }

    if (!this._addedObjects[parentId]) this._addedObjects[parentId] = {};
    if (!this._addedObjects[parentId][childProperty]) this._addedObjects[parentId][childProperty] = [];
    this._addedObjects[parentId][childProperty].push(model);
    return this;
  };

  update = (model) => {
    var id = model['id'];

    if (!id || !this._objectsMap[id]) return this;
    if (this.beforeUpdateHandler(this._objectsMap[id], model) === false) return this;

    this.updateWithoutHandlers(model);
    this.updateHandler(this._objectsMap[id], model);
    this.actionHandler('update', this._objectsMap[id]);
    this._handlers.trigger(this._objectsMap[id], 'update');

    if (model.childrenNames && model.childrenNames.length > 0) {
      model.childrenNames.forEach(childName => {
        if (Array.isArray(model[childName])) {
          this.flatten(model[childName], model);
        }
      });
    }

    return this;
  };

  updateWithoutHandlers = (model) => {
    var id = model.id;
    Object.keys(model).forEach((key) => {
      this.set(this._objectsMap[id], key, model[key]);
    });
  };

  removeLink = (item) => {
    if (!isLink(item)) return;

    let id = item.id;
    let nwid = item.nwid;
    let linkIndex = this._nwidLinks && isArray(this._nwidLinks[nwid]) ? this._nwidLinks[nwid].indexOf(id) : -1;
    if (linkIndex >= 0) {
      this._nwidLinks[nwid].splice(linkIndex, 1);
    }
  };

  removeItemMapping = (item) => {
    if (!isObject(item) || isArray(item) || isUndefined(item.id) || isUndefined(item.nwid) || !this._objectsMap[item.id]) {
      return;
    }

    if (isLink(this._objectsMap[item.id])) {
      this.removeLink(item);
    } else {
      delete this._nwidsToIdsMap[item.nwid];
      delete this._objectsMap[item.id];
    }
    
  };

  triggerAndRemoveAllChildObjects = (item) => {
    for (var i in item) {
      if (isObject(item[i])) {
        this.triggerAndRemoveAllChildObjects(item[i]);

        if (!isArray(item[i]) && !isUndefined(item[i].id) && !isUndefined(item[i].nwid)) {
          this.removeItemMapping(item[i]);
        }
      }
    }

    if (isObject(item) && !isArray(item) && !isUndefined(item.id) && !isUndefined(item.type)) {
      this._handlers.trigger(item, 'remove');
    }
  };

  remove = (model) => {
    var parentId = model['parentId'];
    var parent = isUndefined(parentId) ? undefined : this._objectsMap[parentId];
    var idsToRemove = model['ids'];
    var childProperty = model['childPropertyId'];

    if (EXCLUDE_REMOVE_PROPERTIES.indexOf(childProperty) !== -1 || isUndefined(idsToRemove) || isUndefined(parent)) {
      return this;
    }

    idsToRemove.forEach((id) => {
      if (isUndefined(this._objectsMap[id]) || isNull(this._objectsMap[id])) {
        return;
      }

      this.triggerAndRemoveAllChildObjects(this._objectsMap[id]);

      if (isArray(parent[childProperty])) {
        this.removeChild(parent[childProperty], this._objectsMap[id]);
      } else if (isObject(parent[childProperty])) {
        if (!isUndefined(parent[childProperty].id) && parent[childProperty].id === id) {
          this.set(parent, childProperty, undefined);
        }
      } else {
        this.set(parent, childProperty, undefined);
      }

      this.removeItemMapping(this._objectsMap[id]);
    });

    this.actionHandler('remove', parent, childProperty, idsToRemove);

    return this;
  };

  merge = () => {
    var parentIds = isUndefined(this._addedObjects) ? [] : Object.keys(this._addedObjects);

    parentIds.forEach((parentId) => {
      var parent = this._objectsMap[parentId];
      var propertiesToAdd = this._addedObjects[parentId];

      Object.keys(propertiesToAdd).forEach((propertyName) => {
        this.mergeToModel(parent, propertyName, propertiesToAdd[propertyName]);
        this.actionHandler('add', parent, propertiesToAdd[propertyName]);
        if (Array.isArray(propertiesToAdd[propertyName])) {
          propertiesToAdd[propertyName].forEach(modelItem => {
            this._handlers.trigger(modelItem, 'add');
          });
        } else {
          this._handlers.trigger(propertiesToAdd[propertyName], 'add');
        }
      });
    });
    this._addedObjects = {};

    return this;
  };

  mergeToModel = (parent, propertyName, toAdd) => {
    var model = parent[propertyName];
    this.addChildren(model, toAdd);

    return this;
  };

  model = () => {
    return this._model;
  };

  tickHandler = (fnc, fncScope) => {
    if (isFunction(fnc)) {
      this._tickHandler = fnc;
      this._tickHandlerScope = fncScope;
    }
  };

  triggerTickHandler = () => {
    if (isFunction(this._tickHandler)) {
      this._tickHandler(this._tickHandlerScope, this._model);
    }
  };

};