/**
 * @name unplannedPages
 * @file Select image (page content) from unplanned pages
 *
 * @author Boris
 * @since: 2016-08-15
 */

import requestManager from 'core/managers/request';
import SelectImageDialog from 'widgets/selectImageDialog';

const selectPageImage = (viewInstanceNwid) => {
  //console.log('selectPageImage()=');
  return requestUnplannedPages(viewInstanceNwid)
    .then((data) => {
      var pages = data ? data.pages : [];
      return mapUnplannedToImageItems(pages);
    })
    .then(imageItems => selectImage(imageItems));
};

const requestUnplannedPages = (viewInstanceNwid) => {
  var params = {
    command: 'getUnplannedPages',
    viewInstanceNwid
  };

  return requestManager.genericRequestPromise(params);
};

const selectImage = (imageItems) => {
  return SelectImageDialog.show(imageItems);
};

const mapUnplannedToImageItems = (unplannedPages) => {
  var pages = Array.isArray(unplannedPages) ? unplannedPages : [];
  var imageItems = pages.map((page) => {
    return {
      nwid: page.content.nwid,
      name: page.name,
      width: page.content.width,
      height: page.content.height,
      trimBox: page.content.trimBox,
      template: 'page/content'
    };
  });

  return imageItems;
};

export default {
  selectPageImage
};
