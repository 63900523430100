import React, { useEffect, useRef } from "react";
import { useVirtual } from "react-virtual";

const LINE_HEIGHT = 13;

const VirtualLines = ({ lines, onScrollList, autoScroll }) => {
  const parentRef = useRef();

  const rowVirtualizer = useVirtual({
    size: lines.length,
    parentRef,
    estimateSize: React.useCallback(() => LINE_HEIGHT, []),
  });

  const handleScroll = () => {
    let isScrollBarDown = false;
    const { scrollHeight, scrollTop, clientHeight } = parentRef.current;
    if (scrollHeight - (clientHeight + scrollTop) < 5) { // user scrolled to the end up to 3px
      isScrollBarDown = true;
    }
    onScrollList(isScrollBarDown);
  };

  useEffect(() => {
    if (autoScroll) {
      rowVirtualizer.scrollToIndex(lines.length, { align: 'end' });
    }
  }, [lines, autoScroll]);


  return (
    <div onScroll={handleScroll} ref={parentRef} className="file-viewer-virtual-list-parent">
      <div className="file-viewer-virtual-list" style={{
        height: `${rowVirtualizer.totalSize}px`,
      }}>
        {rowVirtualizer.virtualItems.map((virtualRow) => (
          <div
            key={virtualRow.index}
            ref={virtualRow.measureRef}
            className={'file-viewer-virtual-line'}
            style={{
              transform: `translateY(${virtualRow.start}px)`
            }}
          >
            {lines[virtualRow.index]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VirtualLines;