/**
 * @name iconService
 *
 * @fileOverview Icon service provides 4 types of icons:
 * 1. template icons: icon per type of the object (specified in the model)
 * 2. module icons: icons used per modules
 * 3. general icons: icons used among different modules, e.g. events' statuses, resources' states
 * 4. system icons: icons of the application, e.g. arrows, spinning wheels, etc.
 * Icon service also contains the definitions of ember custom helpers for each type of the icon,
 * which can be used in ember view and handlebars templates
 *
 * @author sergey
 */

import Ember from 'ember';
import base from 'base';
import {coretexPath, appendParameters} from 'utilities/url';
import settingsManager from 'core/managers/settings';

//TODO: move this to a client config file
const BASE_IMAGE_PATH = 'html/kernel/assets/img';
const BASE_SVG_SPRITE_PATH = 'html/kernel/assets/img';


// register helpers
(function () {
  if (typeof Ember === 'undefined') {
    return;
  }
  Ember.Handlebars.registerHelper('systemIcon', function (iconName) {
    return getSystemIcon(iconName);
  });
  Ember.Handlebars.registerHelper('generalIcon', function (type, iconName) {
    const iconType = type === 'null' ? '' : type;

    return getGeneralIcon(iconType, iconName);
  });
  Ember.Handlebars.registerHelper('moduleIcon', function (moduleName, iconName) {
    return getModuleIcon(moduleName, iconName);
  });
  Ember.Handlebars.registerHelper('templateIcon', function (type, size) {
    if (typeof size === 'string') {
      return getTemplateIcon(type, size);
    }
    else {
      return getTemplateIcon(type);
    }
  });


  Ember.Handlebars.registerBoundHelper('boundTemplateIcon', function (type, size) {
    var src;
    if (typeof size === 'string') {
      src = getTemplateIcon(type, size);
    }
    else {
      src = getTemplateIcon(type);
    }
    var result = '<img src="' + src + '"/>';
    return new Ember.Handlebars.SafeString(result);
  });


})();

export function getSystemIcon(iconName) {
  var EXT = '.gif';
  var args = [
    settingsManager.get('webAppPath'),
    BASE_IMAGE_PATH,
    'sys',
    iconName + EXT
  ];
  return args.join('/');
}

export function getGeneralIcon(type, iconName, extension = '.png') {
  const imageFileName = iconName.lastIndexOf('.') > 0 ? iconName : iconName + extension;

  return coretexPath(BASE_IMAGE_PATH, 'general', type, imageFileName);
}

export function getActionIcon(iconName) {
  const ext = (iconName && iconName.indexOf('.')) < 0 ? '.png' : '';

  return coretexPath(BASE_IMAGE_PATH, 'action', `${iconName}${ext}`);
}

export function getModuleIcon(moduleName, iconName, extension = '.png') {
  const args = [
    settingsManager.get('webAppPath'),
    BASE_IMAGE_PATH,
    'module',
    moduleName,
    iconName + extension
  ];

  return args.join('/');
}

/**
 *
 * @param type
 * @param size {string} "tiny", "small", "medium", "medium", "large" stand for:
 *                      16x16,   22x22,  24x24,     20x24,    48x48 respectively
 * @returns {string}
 */
export function getTemplateIcon(type, size, iconName) {
  var prefix = "icon_";
  var suffix = (size) ? size + '.png' : 'tiny.png';
  var args = (iconName && iconName !== 'void') ? [
    settingsManager.get('webAppPath'),
    BASE_IMAGE_PATH,
    'template',
    type,
    iconName,
    prefix + suffix
  ] : [
    settingsManager.get('webAppPath'),
    BASE_IMAGE_PATH,
    'template',
    type,
    prefix + suffix
  ];
  return args.join('/');
}

function getSVGIconPath(type) {
  return settingsManager.get('webAppPath') + "/html/kernel/assets/img/template/" + type + "/icon_48x48.svg";
}

function getSVGIcons(types, callback, ctx) {
  var promises = types.map(function (type) {
    var dfd = base.data.deferred();
    base.data.ajax({
      url: getSVGIconPath(type),
      dataType: 'text'
    }).then(function (svg) {
        dfd.resolve(svg);
      },
      function () {
        dfd.resolve();
      });
    return dfd.promise();
  });
  base.data.when.apply(null, promises)
    .then(function () {
      return callback.apply(ctx, arguments);
    });
}

export function getGeneralSvgIconHref(type) {
  return coretexPath(BASE_SVG_SPRITE_PATH, `general.svg?${settingsManager.get('version')}#${type}`);
}

export function getTemplateSvgIconHref(type) {
  return coretexPath(BASE_SVG_SPRITE_PATH, `template.svg?${settingsManager.get('version')}#${type}`);
}

export function getModuleSvgIconHref(moduleName, iconName) {
  return coretexPath(BASE_SVG_SPRITE_PATH, 'module', `${moduleName}.svg?${settingsManager.get('version')}#${iconName}`);
}

export function getCustomIconUrl(viewOrActionNwid, iconName) {
  let url = coretexPath('rest', 'v2', 'image-service', 'custom-icon');
  url = appendParameters(url, {
    viewOrActionNwId: viewOrActionNwid,  // viewOrActionNwId key is case sensitive!
    name: iconName
  });

  return url;
}


module.exports = {
  _name: 'icons',
  _type: 'service',
  getSystemIcon,
  getGeneralIcon,
  getActionIcon,
  getModuleIcon,
  getTemplateIcon,
  getSVGIconPath,
  getSVGIcons,
  getGeneralSvgIconHref,
  getTemplateSvgIconHref,
  getModuleSvgIconHref,
  getCustomIconUrl,
};
