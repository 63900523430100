import React, { useState, useEffect, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import TextInput from './TextInput';

const FLOAT_MASK = /^-?\d*\.?\d*$/;
const NOT_NEGATIVE_FLOAT_MASK = /^\d*\.?\d*$/;
const INT_MASK = /^-?\d*$/;
const NOT_NEGATIVE_INT_MASK = /^\d*$/;

const toNumberOrZero = (value) => {
  return Number(value) || 0;
};

const round = (value, decimalPlaces) => {
  return Number(Number(value).toFixed(decimalPlaces));
};

const getDefaultValue = (allowEmptyValue) => {
  return allowEmptyValue ? '' : 0;
};

const getDefaultDisplayValue = (allowEmptyValue) => {
  return allowEmptyValue ? '' : '0';
};

const toDisplayValue = (value, factor, decimalPlaces, allowEmptyValue) => {
  if (isNaN(value) || value === '') {
    return getDefaultDisplayValue(allowEmptyValue)
  }

  return String(round(Number(value) * factor, decimalPlaces) || 0);
};

const fromDisplayValue = (displayValue, factor, allowEmptyValue) => {
  if (displayValue === '') {
    return getDefaultValue(allowEmptyValue);
  }

  let value = Number(displayValue) / factor;
  if (isNaN(value)) {
    value = getDefaultValue(allowEmptyValue);
  } else {
    value = allowEmptyValue ? String(value) : value;
  }

  return value;
};

const matchesFloatMask = (value, notNegative, decimalPlaces) => {
  let mask;
  if (decimalPlaces === 0) {
    mask = notNegative ? NOT_NEGATIVE_INT_MASK : INT_MASK;
  } else {
    mask = notNegative ? NOT_NEGATIVE_FLOAT_MASK : FLOAT_MASK;
  }

  return mask.test(value);
};

const removeInsignificantDecimals = (value, decimalPlaces) => {
  const parts = value.split('.');
  if (parts.length === 2 && parts[1].length > decimalPlaces) {
    value = parts[0] + '.' + parts[1].substr(0, decimalPlaces);
  }

  return value;
};

function ConversionInput({
                           value = 0,
                           unitSymbol = '',
                           noUnitSymbol = false,
                           factor = 1,
                           decimalPlaces = 4,
                           notNegative = false,
                           allowEmptyValue = false,
                           onChange,
                           onBlur,
                           className,
                           style,
                           ...props
                         }, ref) {

  const [displayValue, setDisplayValue] = useState(toDisplayValue(value, factor, decimalPlaces, allowEmptyValue));

  const prevValueRef = useRef(value);

  useEffect(() => {
    if (toNumberOrZero(value) !== toNumberOrZero(prevValueRef.current)) {
      setDisplayValue(toDisplayValue(value, factor, decimalPlaces, allowEmptyValue));

      prevValueRef.current = value;
    }
  }, [value, factor, decimalPlaces]);

  const handleChange = (event, textValue) => {
    if (matchesFloatMask(textValue, notNegative, decimalPlaces)) {
      const newDisplayValue = removeInsignificantDecimals(textValue, decimalPlaces);
      setDisplayValue(newDisplayValue);

      const newValue = fromDisplayValue(newDisplayValue, factor, allowEmptyValue);
      prevValueRef.current = newValue;

      if (typeof onChange === 'function') {
        onChange(event, newValue);
      }
    }
  };

  const handleBlur = (event) => {
    if (displayValue === '' || isNaN(displayValue)) {
      setDisplayValue(getDefaultDisplayValue(allowEmptyValue));
    }

    if (typeof onBlur === 'function') {
      onBlur(event);
    }
  };

  const cssClass = `crtx-conversion-input ${className || ''} ${props.disabled ? 'disabled' : ''}`;

  return (
    <div className={cssClass} style={style}>
      <TextInput
        {...props}
        ref={ref}
        inputType={'text'}
        value={displayValue}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {unitSymbol && !noUnitSymbol ? <div className='crtx-conversion-input-unit-symbol'>{unitSymbol}</div> : undefined}
    </div>
  );
}

const ConversionInputWithRef = forwardRef(ConversionInput);

ConversionInputWithRef.propTypes = {
  ...TextInput.propTypes,
  unitSymbol: PropTypes.string,
  factor: PropTypes.number,
  decimalPlaces: PropTypes.number,
  notNegative: PropTypes.bool,
  allowEmptyValue: PropTypes.bool,
};

export default ConversionInputWithRef;
