define(['sandbox', 'AbstractAction', '../../utilities/approvals', 'utilities/statuses'], function(sandbox, AbstractAction, approvals, statuses) {
  'use strict';
  function isObjectApplicable(obj, config) {
    
    const waitingForApproval = approvals.isItemWaitingForApproval(obj);
    if (config.approveMode && !waitingForApproval){
      return false;
    } else if (!config.approveMode && waitingForApproval){
      return false;
    };

    const status = statuses.getMainStatus(obj);

    if (status === null || typeof status === 'undefined' || typeof status.progress === 'undefined') {
      return '';
    }
    var statusType = status.statusType;
    var enableErrorObjects = (config.enableErrorObjects === null || typeof config.enableErrorObjects === 'undefined' || config.enableErrorObjects === true);
    if (!enableErrorObjects &&  statusType === "Error"){
      return false;
    }
    var progress = parseInt(status.progress, 10);
    var requiredProgress = config.progress;
    if (requiredProgress === null || typeof requiredProgress === 'undefined'){
      requiredProgress = 1;
    } else {
      requiredProgress = parseInt(requiredProgress*100, 10);
    }

    return progress >= requiredProgress;
  }

  return AbstractAction.extend({

    isApplicable: function (objs) {
      if (typeof objs === 'undefined') {
        return false;
      }
      for (var index = 0; index < objs.length ; index++) {
        var obj = objs[index];
        if (typeof obj !== 'undefined') {
          var result = isObjectApplicable(obj, this.config);
          if (result === false) {
            return false;
          }
        } else {
          return false;
        }
      }
      return objs.length === 0 ? false : true;
    },

    execute: function (objs) {
      var nwid, type;
      var viewInstanceNwId = this.module.nwid;
      var deviceNwId = this.module.viewSettings.rootId;
      var projectorId = this.module.projectorId;

      var request = sandbox.request.sendMail(viewInstanceNwId, this.nwid, null, null, projectorId,
        {
          config: this.config,
          parameters: this.parameters,
          name: this.name,
          objects: objs
        }
      );
      return request.then(function (res) {
        var code = res.statusCode;
        if (code !== 200){
          var message = res.errorMessage;
          if (res !== null){
            sandbox.dialog.alert(message);
          }
        }
      });
    }
  });
});