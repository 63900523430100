define([], function () {
  'use strict';

  function collectNameValues(objects) {
    var arr = [];

    for (var i = 0; i < objects.length; i++) {
      arr.push(objects[i].name);
    }
    return arr;
  }

  function getNextNameIndex (arr, name) {
    var i = 0;
    var names = collectNameValues(arr);
    var _name = name;

    while (names.indexOf(_name) >= 0) {
      _name = [name, ' ', i + 1].join('');
      i++;
    }

    return i;
  }

  return {
    getNextNameIndex
  };

});