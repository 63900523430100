/**
 * @name cropMarks
 * @file Defines functionality of automatic crop marks that are added together to the whole layout
 *
 * @author Boris
 * @since: 2018-06-25
 */

import React from 'react';
import sandbox from 'sandbox';
import SimpleForm from 'widgets/SimpleForm/src/index';
import utils from '../utils/utils';
import propertiesCommon from './propertiesCommon';
import regmarkModule from './regmark';
import canvasUtils from "../utils/canvasUtils";

const {translate} = sandbox.localization;
const {utils: futils} = SimpleForm;

const BASE_PATH = 'layout.cropMarks';
const TITLE = translate('Crop Marks');
const MARK_ROLE = 'cropMark';

const APPLY_ON = {...propertiesCommon.APPLY_ON};
const BLACK_ONLY = {...propertiesCommon.BLACK_ONLY};

const _NAME = {
  key: '_name',
  caption: translate('Name'),
  type: 'string',
  disabled: true,
};

const ENABLED = {
  key: 'enabled',
  caption: translate('Enabled'),
  type: 'bool',
};

const LINE_LENGTH = {
  key: 'lineLength',
  caption: translate('Line length'),
  type: 'length',
  mask: 'posFloat',
  defaultValue: 0.2,
};

const LINE_WIDTH = {
  key: 'lineWidth',
  caption: translate('Line width'),
  type: 'length',
  mask: 'posFloat',
  defaultValue: 0.02,
};

const OFFSET = {
  key: 'offset',
  caption: translate('Offset'),
  type: 'length',
  mask: 'posFloat',
  defaultValue: 0.125,
};


const CROP_MARKS_META = [
  _NAME,
  ENABLED,
  LINE_LENGTH,
  LINE_WIDTH,
  OFFSET,
  APPLY_ON,
  BLACK_ONLY,
];

const getCells = (state) => {
  return state.layout.cellGrid.cells;
};

const calcMarkRectangle = (mark) => {
  const {type, x, y, lineWidth, lineLength} = mark;

  return type === 'vline' ?
    {x: x - lineWidth / 2, y: y - lineLength / 2, width: lineWidth, height: lineLength} :
    {x: x - lineLength / 2, y: y - lineWidth / 2, width: lineLength, height: lineWidth};
};

export default (editor) => {

  const Regmark = regmarkModule(editor);
  const cutils = canvasUtils(editor);

  const getMeta = (state, element) => {
    CROP_MARKS_META.forEach(item => item.disabled = !element.enabled);
    _NAME.disabled = true;
    ENABLED.disabled = false;

    return CROP_MARKS_META;
  };

  const buildDefaultElement = (state, elementType) => {
    const element = {
      elementType: elementType,
      locked: false,
    };

    setDefaultElementValues(state, element);

    return element;
  };

  const setDefaultElementValues = (state, element, elementPath) => {
    propertiesCommon.setDefaultValues(state, element, getMeta(state, element));

    element._name = translate('Crop Marks');
  };

  const updateCropMarks = (state, element) => {
    let newState = state;
    newState = Regmark.removeAutoMarks(newState, MARK_ROLE);
    if (element.enabled) {
      const marks = calcMarksInfo(state, element);
      newState = Regmark.addAutoMarks(newState, marks);
    }

    return newState;

  };

  const calcMarksInfo = (state, element) => {
    const {offset, lineWidth, lineLength} = element;
    const cells = utils.fromPseudoArray(getCells(state));
    const ofs = offset + lineLength / 2; // adjusted offset - distance from the mark center to the cell edge

    const marks = [];
    for (let i = 0; i < cells.length; i++) {
      const c = cells[i];
      if (!c.isSkipped) {
        // calculate mark positions for each none skipped cell
        let cellMarks = [
          {x: c.x - ofs, y: c.y, type: 'hline'}, // (0, 0)
          {x: c.x - ofs, y: c.y + c.height, type: 'hline'},  // (0, 1)
          {x: c.x + c.width + ofs, y: c.y, type: 'hline'}, // (1, 0)
          {x: c.x + c.width + ofs, y: c.y + c.height, type: 'hline'}, // (1, 1)
          {x: c.x, y: c.y - ofs, type: 'vline'}, // (0, 0)
          {x: c.x, y: c.y + c.height + ofs, type: 'vline'},  // (0, 1)
          {x: c.x + c.width, y: c.y - ofs, type: 'vline'}, // (1, 0)
          {x: c.x + c.width, y: c.y + c.height + ofs, type: 'vline'}, // (1, 1)
        ];

        cellMarks.forEach(mark => {
          // exclude marks that overlap with some cell
          mark = {...mark, lineWidth, lineLength, role: MARK_ROLE};
          let fits = true;
          for (let j = 0; j < cells.length; j++) {
            const cell = cells[j];
            if (i !== j && !cell.isSkipped) {
              const rect = calcMarkRectangle(mark);
              if (utils.checkRectanglesOverlap(rect, cell, offset)) {
                fits = false;
                break;
              }
            }
          }

          // exclude equal marks, namely marks that have the same rectangle
          if (fits) {
            const idx = marks.findIndex(m => utils.areRectanglesEqual(calcMarkRectangle(m), calcMarkRectangle(mark)));
            if (idx < 0) {
              marks.push(mark);
            }
          }
        });
      }
    }

    return marks;
  };

  const updateProperty = (state, element, elementPath, propertyPath, propertyValue) => {
    let newState = state;
    let newElement = element;
    newState = propertiesCommon.updateProperty(newState, newElement, elementPath, propertyPath, propertyValue);
    newElement = futils.get(newState, elementPath);
    if (!utils.areValuesEqual(element, newElement, ['enabled', 'lineWidth', 'lineLength', 'offset'])) {
      newState = updateCropMarks(newState, newElement);
      newState.repopulate = true;
    }

    return newState;
  };

  const dependencyChanged = (state, element, elementPath, oldSource, newSource) => {
    if (!element.enabled) {
      return state;
    }

    const newState = updateCropMarks(state, element);
    if (newState !== state) {
      newState.repopulate = true;
    }

    return newState;
  };

  const activateShape = (state, element) => {
    cutils.setActiveObject(null);
  };

  const renderProperties = (store, element, elementPath) => {
    const state = store.getState();

    return (
      <div>
        {propertiesCommon.renderProperties(store, element, elementPath, getMeta(state, element))}
      </div>
    );

  };

  const getInfo = (state) => {
    return {
      basePath: BASE_PATH,
      title: TITLE
    };
  };

  return {
    buildDefaultElement,
    setDefaultElementValues,
    updateProperty,
    dependencyChanged,
    activateShape,
    renderProperties,
    getInfo,
  }
};
