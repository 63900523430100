/**
 * @name ElementsForm
 * @file React component that defines functionality of element groups and element lists inside each group
 *
 * @author Boris
 * @since: 2016-06-23
 */

import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'core/services/localization';
import { getGeneralSvgIconHref } from 'core/services/iconService';
import { Dropdown } from 'components/common/floating';
import ReactComponents from 'widgets/ReactComponents/src/index';
import SimpleForm from 'widgets/SimpleForm/src/index';
import PanelToolbar from './panelToolbar';
import actions from '../redux/actions';
import { ManageFurnituresDialog } from './ManageFurnituresDialog';

const { AccordionPanel } = ReactComponents;
const { Form, Accordion, List } = SimpleForm;

const TYPES_WITHOUT_BUTTONS = ['plate', 'printArea', 'cellGrid', 'cell', 'cropMarks', 'centerMarks'];

const ElementsForm = ({ store, editor, onChangeLayout }) => {

  const getItemData = item => {
    const state = store.getState();
    const element = store.get(item.elementPath);
    const title = element.name || element._name;
    const locked = element.locked;
    const iconType = locked ? 'svg' : 'none';
    const iconClassName = 'svg-icon';
    const iconUrl = getGeneralSvgIconHref('lock');
    const allButtons = state.sidebar.buttons;
    let buttons = [];
    if (!TYPES_WITHOUT_BUTTONS.some(t => t === element.elementType)) {
      buttons = locked ? [allButtons.duplicate] : [allButtons.duplicate, allButtons.delete];
      for (let i = 0; i < buttons.length; i++) {
        buttons[i].onClick = handleButtonClick;
      }
    }

    return {
      locked: locked,
      id: item.elementPath,
      title: title,
      buttons: buttons,
      iconType,
      iconClassName,
      iconUrl
    };
  };

  const handleButtonClick = (elementPath, buttonId) => {
    //console.log("handleButtonClick() elementPath=", elementPath, "buttonId=", buttonId);
    switch (buttonId) {
      case 'delete':
        store.dispatch(actions.deleteElement(elementPath));
        break;
      case 'duplicate':
        store.dispatch(actions.duplicateElement(elementPath));
        break;
    }
  };

  const handleGroupButtonClick = (group, buttonId) => {
    //console.log("handleGroupButtonClick() group=", group, "buttonId=", buttonId);
    switch (buttonId) {
      case 'add':
        store.dispatch(actions.addNewElement(group));
        break;
      case 'manage':
        if (group.elementType === 'furniture') {
          store.dispatch(actions.setDialogVisible(true));
        }
        break;
    }
  };

  const handleGroupOptionChange = (group, buttonId, optionId) => {
    //console.log("handleGroupOptionChange() group=", group, "buttonId=", buttonId, "optionId=", optionId);
    switch (buttonId) {
      case 'add':
        store.dispatch(actions.selectAddOption(group.path, optionId));
        break;
    }
  };

  const handleToggleCollapse = (bind, id) => {
    //console.log("handleToggleCollapse() bind=", bind, "id=", id);
    store.dispatch(actions.toggleCollapse(id));
  };

  const handleSelectElement = (bind, id) => {
    //console.log("handleSelectElement() bind=", bind, "id=", id);
    store.dispatch(actions.selectElement(id));
  };

  const handleCloseDialog = () => {
    store.dispatch(actions.setDialogVisible(false));
  };


  const getLayoutLinks = layoutGroup => {
    if (!layoutGroup) {
      return [];
    }

    const result = layoutGroup.layoutLinks.map(layout => ({ value: layout.nwid, text: layout.name }));

    return result;
  };

  const renderLayoutGroupTitle = layoutGroup => {
    if (!layoutGroup) {
      return null;
    }

    return (
      <div className='header-line'>
        <div>{translate('Layout group:')}</div>
        <div>{layoutGroup.name}</div>
      </div>
    );
  };

  const isFormLayout = state => {
    return state.rootType === 'form';
  };

  const calcHeaderHeight = state => {
    return isFormLayout(state) ? 45 : 0;
  };

  const renderLayoutTitle = (caption, layout) => {
    return (
      <div className='header-line'>
        <div>{caption}</div>
        <Dropdown
          className='layout-name-dropdown'
          value={layout.nwid}
          options={getLayoutLinks(layout.layoutGroup)}
          onSelect={(e, layoutNwid) => onChangeLayout(layoutNwid)}
        />
      </div>
    );
  };

  const renderHeader = () => {
    const state = store.getState();
    const layout = state.layout;

    let layoutGroup = null;
    let caption = '';
    switch (layout.elementType) {
      case 'layout':
        caption = translate('Layout:');
        layoutGroup = layout.layoutGroup;
        break;
      case 'plate':
        caption = translate('Plate:');
        break;
      case 'ink':
        caption = translate('Ink settings:');
        break;
    }

    return (
      <div>
        {renderLayoutGroupTitle(layoutGroup)}
        {renderLayoutTitle(caption, layout)}
      </div>
    );
  };

  const renderAccordionPanel = (group) => {
    const items = group.children || [];

    const bind = 'selectedElementPath';

    return (
      <AccordionPanel key={group.path} id={group.path} title={group.title}>
        <PanelToolbar
          group={group}
          onButtonClick={handleGroupButtonClick}
          onOptionChange={handleGroupOptionChange}
        />
        <List
          bind={bind}
          items={items}
          itemDataGetter={item => getItemData(item, group)}
          onSelectRow={handleSelectElement}
        />
      </AccordionPanel>
    );
  };

  const render = () => {
    if (!store) {
      return null;
    }

    const state = store.getState();
    const elementGroups = state.elementGroups || [];
    const title = translate('Elements');
    const bind = 'expandedGroupPath';
    const headerHeight = calcHeaderHeight(state);
    const titleStyle = headerHeight <= 0 ? null : { marginTop: '2px' };

    return (
      <div>
        {headerHeight <= 0 ? null : renderHeader()}
        <div style={{ height: `calc(100% - ${headerHeight}px)` }}>
          <div className='elements-title' style={titleStyle}>{title}</div>
          <Form store={store} className='elements-form'>
            <Accordion bind={bind} onToggleCollapse={handleToggleCollapse}>
              {elementGroups.map(renderAccordionPanel)}
            </Accordion>
          </Form>
        </div>
        {state.dialogVisible &&
          <ManageFurnituresDialog
            store={store}
            editor={editor}
            onClose={handleCloseDialog}
          />
        }
      </div>
    );
  };

  return render();

};

ElementsForm.propTypes = {
  store: PropTypes.any,
};

export default ElementsForm;
