import { createRoot } from 'react-dom/client';
/**
 * Private Functions
 */
const createContainerNode = function (containerName) {
  let containerNode = document.createElement('div');
  containerNode.className = `toast-container toast-container-${containerName}`;
  return containerNode;
};

/**
 * Public Functions
 */

const createContainersRoots = function (containers = [], parent = document.body) {
  return containers.reduce((accContainers, container = {}) => {
    let containerName = container.name || '';
    let containerNode = createContainerNode(containerName, parent);
    parent.appendChild(containerNode);
    return Object.assign(accContainers, { [containerName]: createRoot(containerNode) });
  }, {});
};

module.exports = {

  createContainersRoots

};