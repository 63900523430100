import Input from './Input';
import ConverterDecorator from '../decorators/Converter';
import UnitsConverter from '../converters/UnitsConverter';
import MaskerDecorator from '../decorators/Masker';
import NumericMasker from '../maskers/NumericMasker';

const intType = {
  factor: 100,
  precision: 0
};
const floatType = {
  factor: 100,
  precision: 6
};
const converterOptions = {
  'int': intType,
  'float': floatType
};
const getConverterOptions = type => {
  return converterOptions[type] || floatType;
};

var intMask = {
  regex: /^-?\d*$/
};
var posIntMask = {
  regex: /^\d*$/
};
var floatMask = {
  regex: /^-?\d*\.?\d*$/
};
const maskerOptions = {
  'int': intMask,
  'posInt': posIntMask,
  'float': floatMask
};
const getMaskerOptions = mask => {
  return maskerOptions[mask] || floatMask;
};

export default MaskerDecorator(
  ConverterDecorator(
    Input,
    UnitsConverter,
    getConverterOptions,
    'valueType'
  ),
  NumericMasker,
  getMaskerOptions
);
