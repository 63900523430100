import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class extends Component {
  static propTypes = {
    headerHeight: PropTypes.any,
    columnIndex: PropTypes.number,
    columnKey: PropTypes.any,
    width: PropTypes.number,
    title: PropTypes.string
  };

  render() {
    return <div className="column separator-column">
      <div className="column-content">
      </div>
    </div>;
  }
}