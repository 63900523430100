import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Separation from '../separations/Separation';
import ColorsIndicator, { BLACK_AND_WHITE, COLORS } from '../indicators/ColorsIndicator';
import MultiStatusIndicator from '../indicators/MultiStatusIndicator';
import { classNames } from 'utilities/classNames';

const isUndefined = o => typeof o === 'undefined';
const THUMBNAIL_CLASS_NAME = 'crtx-approval-thumbnail';

export default class Approval extends Component {
  static propTypes = {
    stepImageUrl: PropTypes.string,
    stepStatuses: PropTypes.arrayOf(PropTypes.oneOf([-1, 0, 1])).isRequired,
    separations: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      version: PropTypes.number,
      filled: PropTypes.bool,
      stepImageUrl: PropTypes.string,
      stepStatuses: PropTypes.arrayOf(PropTypes.oneOf([-1, 0, 1])).isRequired
    })),
    indicators: PropTypes.arrayOf(PropTypes.element),
    title: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onContextMenu: PropTypes.func,
    reportType: PropTypes.number,
  };

  static defaultProps = {
    separations: [],
    indicators: [],
    reportType: 0
  };

  renderTop = () => {
    const { stepImageUrl, stepStatuses, separations, indicators } = this.props;
    const colors = separations.length > 1 ? COLORS : BLACK_AND_WHITE;

    return <div className="crtx-approval-header">
      <div className="crtx-approval-status">
        <ColorsIndicator colors={colors} />
        <div className="crtx-approval-version"></div>
        <div style={{ width: 16, height: 16 }}>
          {!isUndefined(stepImageUrl) ? <img src={stepImageUrl} /> : undefined}
        </div>
        {!isUndefined(stepImageUrl) ? <MultiStatusIndicator values={stepStatuses} style={{ marginLeft: 2 }} /> : undefined}
      </div>
      <div className="crtx-approval-indicators">
        {indicators}
      </div>
    </div>;
  };

  renderSeparation = (separation, index) => {
    const { version, color, name, filled, stepImageUrl, stepStatuses } = separation;
    const displayVersion = version > 1 ? version : '';

    return <div key={index} className="crtx-approval-separation">
      <Separation color={color} name={name} filled={filled} className="crtx-approval-separation-content" />
      <div className="crtx-approval-separation-version">{displayVersion}</div>
      <div style={{ width: 16, height: 16 }}>
        {!isUndefined(stepImageUrl) ? <img src={stepImageUrl} /> : undefined}
      </div>
      {!isUndefined(stepImageUrl) ? <MultiStatusIndicator values={stepStatuses} style={{ marginLeft: 2 }} /> : undefined}
    </div>;
  };

  renderSeparations = () => {
    const { separations } = this.props;

    return <div className="crtx-approval-separations">
      {separations.map(this.renderSeparation)}
    </div>;
  };

  renderImage = () => {
    const { imageUrl } = this.props;

    return <div className="crtx-approval-image-container">
      <div className="crtx-approval-image-content" style={{
        backgroundImage: !isUndefined(imageUrl) ? `url(${imageUrl})` : undefined
      }}>
      </div>
    </div>;
  };

  renderBottom = () => {
    const { title, reportType } = this.props;

    return <div className={classNames('crtx-approval-title', { warning: reportType === 1, error: reportType === 2 })} title={title}>
      {title}
    </div>;
  };

  render() {
    const { style, className, onClick, onDoubleClick, onContextMenu } = this.props;
    const thumbnailClassName = isUndefined(className) ? THUMBNAIL_CLASS_NAME : `${THUMBNAIL_CLASS_NAME} ${className}`;

    return <div style={style} className={thumbnailClassName} onClick={onClick} onDoubleClick={onDoubleClick} onContextMenu={onContextMenu}>
      {this.renderTop()}
      {this.renderSeparations()}
      {this.renderImage()}
      {this.renderBottom()}
    </div>;
  }
}