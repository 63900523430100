/* @flow weak */

import React, { Component } from 'react';
import sandbox from 'sandbox';
import SimpleForm from 'widgets/SimpleForm/src/index';
import ReactComponents from 'widgets/ReactComponents/src/index';
import List from './List';
import labels from '../labels';
import helpers from '../helpers';
import dialogService from 'core/services/dialogService';
import DropdownOptions from './DropdownOptions';
import PropTypes from 'prop-types';
import IllegalCharactersMasker from 'widgets/SimpleForm/src/maskers/IllegalCharactersMasker';
import SvgIcon from 'components/common/buttons/SvgIcon';

const { Form, Container, Row, Input, Label, Dropdown, Checkbox } = SimpleForm;
const { Icon } = ReactComponents;

const CAPTION_WIDTH = 340;

const targetTypeOptions = [
  { value: 'publication', text: labels.publication },
  { value: 'edition', text: labels.edition },
  { value: 'zone', text: labels.zone },
  { value: 'section', text: labels.section },
  { value: 'book', text: labels.book },
  { value: 'form', text: labels.form },
  { value: 'page', text: labels.page },
  { value: 'plate', text: labels.plate },
  { value: 'productionrun', text: labels.productionRun }
];
const selectorsFieldTypeOptions = [
  { value: 'string', text: labels.string },
  { value: 'multiselect', text: labels.multiselect },
  { value: 'matchlist', text: labels.matchlist },
  { value: 'checkbox', text: labels.checkbox },
  { value: 'number', text: labels.number },
  { value: 'measurement', text: labels.measurement },
  { value: 'percentage', text: labels.percentage },
  { value: 'degrees', text: labels.degrees },
  { value: 'day', text: labels.dayInWeek },
  { value: 'date', text: labels.date },
  { value: 'flowstep', text: labels.flowstep },
  { value: 'flowsteps', text: labels.flowsteps },
  { value: 'resources', text: labels.resources },
  { value: 'publication', text: labels.publication },
  { value: 'edition', text: labels.edition },
  { value: 'zone', text: labels.zone },
  { value: 'section', text: labels.section },
  { value: 'book', text: labels.book },
  { value: 'form', text: labels.form },
  { value: 'page', text: labels.page },
  { value: 'broadsheet', text: labels.broadsheet },
  { value: 'tabs', text: labels.tabs },
  { value: '2ups', text: labels.twoUps },
  { value: '4ups', text: labels.fourUps },
  { value: '8ups', text: labels.eightUps },
  { value: 'customLayoutGroup', text: labels.customLayoutGroup },
  { value: 'pageSize', text: labels.pageSize },
  { value: 'siteName', text: labels.siteName },
  { value: 'siteCode', text: labels.siteCode },
  { value: 'tolerance', text: labels.tolerance },
  { value: 'allLayoutGroups', text: labels.allLayoutGroups },
  { value: 'press', text: labels.press }
];

const resourceTypeOptions = [
  { value: 'physical/output/cache', text: 'cache' },
  { value: 'physical/image/composelowres', text: 'composelowres' },
  { value: 'physical/comm/externalprotocol', text: 'externalprotocol' },
  { value: 'physical/input/hotfolder', text: 'hotfolder' },
  { value: 'physical/image/inkc', text: 'inkc' },
  { value: 'physical/general/logic', text: 'logic' },
  { value: 'physical/image/optimization', text: 'optimization' },
  { value: 'physical/image/pdfprocessor', text: 'pdfprocessor' },
  { value: 'physical/image/preflight', text: 'preflight' },
  { value: 'physical/output/proofer', text: 'proofer' },
  { value: 'physical/output/proout', text: 'proout' },
  { value: 'physical/image/rip', text: 'rip' },
  { value: 'physical/rip2ctp', text: 'rip2ctp' },
  { value: 'physical/input/status', text: 'status' },
  { value: 'physical/image/tiffprocessor', text: 'tiffprocessor' },
  { value: 'physical/comm/tx', text: 'tx' },
];

const selectorsFieldTypeOptionsNoPublication = selectorsFieldTypeOptions.filter(fieldType => fieldType.value !== 'publication');

const valuesFieldTypeOptions = [
  { value: 'string', text: labels.string },
  { value: 'multiselect', text: labels.multiselect },
  { value: 'checkbox', text: labels.checkbox },
  { value: 'number', text: labels.number },
  { value: 'measurement', text: labels.measurement },
  { value: 'percentage', text: labels.percentage },
  { value: 'degrees', text: labels.degrees },
  { value: 'day', text: labels.dayInWeek },
  { value: 'date', text: labels.date },
  { value: 'flowstep', text: labels.flowstep },
  { value: 'flowsteps', text: labels.flowsteps },
  { value: 'resources', text: labels.resources },
  { value: 'publication', text: labels.publication },
  { value: 'edition', text: labels.edition },
  { value: 'zone', text: labels.zone },
  { value: 'section', text: labels.section },
  { value: 'book', text: labels.book },
  { value: 'form', text: labels.form },
  { value: 'page', text: labels.page },
  { value: 'broadsheet', text: labels.broadsheet },
  { value: 'tabs', text: labels.tabs },
  { value: '2ups', text: labels.twoUps },
  { value: '4ups', text: labels.fourUps },
  { value: '8ups', text: labels.eightUps },
  { value: 'customLayoutGroup', text: labels.customLayoutGroup },
  { value: 'pageSize', text: labels.pageSize },
  { value: 'siteName', text: labels.siteName },
  { value: 'siteCode', text: labels.siteCode },
  { value: 'siteListNames', text: labels.siteListNames },
  { value: 'siteListCodes', text: labels.siteListCodes },
  { value: 'deadline', text: labels.deadline },
  { value: 'securityGroup', text: labels.securityGroup },
  { value: 'tolerance', text: labels.tolerance },
  { value: 'releaseOffset', text: labels.releaseOffset },
  { value: 'hold', text: labels.hold },
  { value: 'allLayoutGroups', text: labels.allLayoutGroups },
];
const matchingModeOptions = [
  { value: 'text', text: 'Text' },
  { value: 'startWith', text: 'Start With' },
  { value: 'regEx', text: 'RegEx' },
];
const emptyDropdownValue = helpers.emptyDropdownValue;
const emptyMatchingModeDropdownValue = helpers.emptyMatchingModeDropdownValue;

module.exports = class CoretexTablesManager extends Component {
  constructor(props) {
    super(props);
    this.handleColumnSelect = this.handleColumnSelect.bind(this);
    this.handleColumnSortEnd = this.handleColumnSortEnd.bind(this);
    this.handleColumnNameChange = this.handleColumnNameChange.bind(this);
    this.handleRemoveCurrentTable = this.handleRemoveCurrentTable.bind(this);
    this.handleDuplicateCurrentTable = this.handleDuplicateCurrentTable.bind(this);
    this.handleAddColumn = this.handleAddColumn.bind(this);
    this.handleRemoveColumn = this.handleRemoveColumn.bind(this);
  }

  static propTypes = {
    store: PropTypes.object.isRequired
  }

  handleColumnSelect(type) {
    return function (ev, value, index, column) {
      const store = this.props.store;
      const currentTableIndex = store.get('currentTableIndex');
      const tablePath = `tables[${currentTableIndex}]`;
      store.dispatch({
        type: 'UPDATE_CURRENT_COLUMN',
        currentColumnType: type,
        currentColumnIndex: index
      });
    }.bind(this);
  }

  handleColumnSortEnd(type) {
    return function ({ oldIndex, newIndex }) {
      const store = this.props.store;
      const currentTableIndex = store.get('currentTableIndex');
      store.dispatch({
        type: 'UPDATE_COLUMN_POSITION',
        currentTableIndex,
        currentColumnType: type,
        oldIndex,
        newIndex
      });
    }.bind(this);
  }

  handleColumnNameChange(event, path, value) {
    const store = this.props.store;
    const currentTableIndex = store.get('currentTableIndex');
    const currentColumnType = store.get('currentColumnType');
    const currentColumnIndex = store.get('currentColumnIndex');
    const oldColumnName = store.get(path);
    const newColumnName = value;
    store.dispatch({
      type: 'UPDATE_CURRENT_COLUMN_NAME',
      path,
      currentTableIndex,
      currentColumnType,
      currentColumnIndex,
      oldColumnName,
      newColumnName
    });
  }

  handleRemoveCurrentTable() {
    const store = this.props.store;
    const currentTableIndex = store.get('currentTableIndex');
    const tableName = store.get(`tables[${currentTableIndex}]`).name;
    dialogService.openConfirmDialog(labels.removeTableMessage(tableName), labels.removeTableTitle(tableName))
      .then(() => {
        store.dispatch({
          type: 'REMOVE_TABLE',
          tableIndex: currentTableIndex
        });
      });
  }

  handleDuplicateCurrentTable() {
    const store = this.props.store;
    const currentTableIndex = store.get('currentTableIndex');

    store.dispatch({
      type: 'DUPLICATE_TABLE',
      'duplicateTableIndex': currentTableIndex
    });
  }

  handleAddColumn(columnType) {
    return function () {
      const store = this.props.store;
      const currentTableIndex = store.get('currentTableIndex');
      store.dispatch({
        type: 'ADD_COLUMN',
        columnType,
        currentTableIndex: currentTableIndex
      });
    }.bind(this);
  }

  handleRemoveColumn() {
    const store = this.props.store;
    const currentTableIndex = store.get('currentTableIndex');
    const currentColumnType = store.get('currentColumnType');
    const currentColumnIndex = store.get('currentColumnIndex');
    const currentColumnName = store.get(`tables[${currentTableIndex}].${currentColumnType}[${currentColumnIndex}]`).displayName;
    dialogService.openConfirmDialog(`${labels.removeColumnMessage} ${currentColumnName}?`, `${labels.removeColumnTitle} ${currentColumnName}`)
      .then(() => {
        store.dispatch({
          type: 'REMOVE_CURRENT_COLUMN',
          currentTableIndex,
          currentColumnType,
          currentColumnIndex
        });
      });
  }

  makeIsDisabled(table) {
    return fieldName => {
      let disabled = false;
      if (table.system === true) {
        disabled = !table.allowModifyFields || table.allowModifyFields.indexOf(fieldName) < 0;
      }

      if (!disabled && fieldName === 'publicationOptions') {
        disabled = !table.isPublicationTable;
      }

      return disabled;
    };
  }

  makeIsFieldDisabled(table, column) {
    return fieldName => {
      let disabled = false;
      if (table.system === true) {
        disabled = !column || !column.allowModifyFields || column.allowModifyFields.indexOf(fieldName) < 0;
      } else if (fieldName !== 'addColumn') {
        disabled = !column || column.fieldType === 'deadline' && fieldName === 'freeText';
      }

      return disabled;
    };
  }

  render() {
    const store = this.props.store;

    if (store.get('tables').length === 0) return <div></div>;

    const currentTableIndex = store.get('currentTableIndex');
    const tablePath = `tables[${currentTableIndex}]`;
    const tableNamePath = `${tablePath}.name`;
    const tableTargetTypePath = `${tablePath}.targetType`;
    const tableMatchingModePath = `${tablePath}.matchingMode`;
    const tableSingleValuePath = `${tablePath}.singleValue`;
    const tableOverridablePath = `${tablePath}.overridable`;
    const isPublicationTablePath = `${tablePath}.isPublicationTable`;
    const isPublicationTable = store.get(isPublicationTablePath);
    const publicationPath = `${tablePath}.publication`;
    const tableSystemPath = `${tablePath}.system`;
    const selectorsPath = `${tablePath}.selectors`;
    const valuesPath = `${tablePath}.values`;
    const table = store.get(tablePath);
    const currentColumnType = store.get('currentColumnType');
    const currentColumnIndex = store.get('currentColumnIndex');
    const currentColumnPath = `${tablePath}.${currentColumnType}[${currentColumnIndex}]`;
    const currentColumn = currentColumnType && currentColumnIndex >= 0 ? store.get(currentColumnPath) : null;
    const selectorsSelectedIndex = currentColumnType === 'selectors' ? currentColumnIndex : undefined;
    const valuesSelectedIndex = currentColumnType === 'values' ? currentColumnIndex : undefined;
    const columnNamePath = `${currentColumnPath}.name`;
    const columnDisplayNamePath = `${currentColumnPath}.displayName`;
    const columnDefaultValuePath = `${currentColumnPath}.defaultValue`;
    const columnFieldTypePath = `${currentColumnPath}.fieldType`;
    const columnResourceTypePath = `${currentColumnPath}.resourceType`;
    const columnFreeTextPath = `${currentColumnPath}.freeText`;
    const columnUniquePath = `${currentColumnPath}.unique`;
    const columnMaxCharactersPath = `${currentColumnPath}.maxCharacters`;
    const columnDisplayName = currentColumnIndex >= 0 ? store.get(columnDisplayNamePath) || '' : '';
    const columnType = currentColumnIndex >= 0 ? currentColumnType === 'selectors' ? 'Selector' : currentColumnType === 'values' ? 'Value' : '' : '';

    const hasPublicationSelector = store.get('tables')[currentTableIndex].selectors.filter(selector => selector.fieldType === "publication");
    let FieldTypeOptionsSelectors = selectorsFieldTypeOptions;
    if (isPublicationTable) {
      FieldTypeOptionsSelectors = hasPublicationSelector.length > 0 ? FieldTypeOptionsSelectors : selectorsFieldTypeOptionsNoPublication;
    }

    const fieldType = store.get(columnFieldTypePath);
    const fieldTypeOptions = columnType === 'Selector' ? FieldTypeOptionsSelectors : valuesFieldTypeOptions;

    const publicationOptions = isPublicationTable ? store.get('publications').filter(pub => pub.value !== '*') : [];

    const isDisabled = this.makeIsDisabled(table);
    const isFieldDisabled = this.makeIsFieldDisabled(table, currentColumn);

    return <Container className="table-simple-form-container">
      <Row>
        <h3 className="title table-title">{labels.table}</h3>
        <Icon icon={sandbox.icons.getModuleIcon('LayoutEditor', 'copy')} title={labels.douplicateTable}
          text={labels.douplicateTable} style={{ width: 115 }} onClick={this.handleDuplicateCurrentTable} />
        <Icon icon={sandbox.icons.getGeneralIcon(null, 'delete')} title={labels.removeTable} text={labels.removeTable}
          style={{ width: 100 }} onClick={this.handleRemoveCurrentTable} />
      </Row>
      <hr />
      <Row>
        <Container style={{ width: CAPTION_WIDTH }}>
          <Label col="12" disabled={isDisabled('name')}>{labels.name}</Label>
        </Container>
        <Container>
          <Input col="12" bind={tableNamePath} actionType="UPDATE_CURRENT_TABLE_NAME" liveUpdate={true}
            disabled={isDisabled('name')} masker={IllegalCharactersMasker()} />
        </Container>
      </Row>
      <Row>
        <Container style={{ width: CAPTION_WIDTH }}>
          <Label col="12" disabled={isDisabled('targetType')}>{labels.targetType}</Label>
        </Container>
        <Container>
          <Dropdown col="12" bind={tableTargetTypePath} options={targetTypeOptions} emptyValue={emptyDropdownValue}
            actionType="UPDATE_CURRENT_TABLE_VALUE" disabled={isDisabled('targetType')} />
        </Container>
      </Row>
      <Row>
        <Container style={{ width: CAPTION_WIDTH }}>
          <Label col="12" disabled={isDisabled('matchingMode')}>{labels.matchingMode}</Label>
        </Container>
        <Container>
          <Dropdown col="12" bind={tableMatchingModePath} options={matchingModeOptions}
            emptyValue={emptyMatchingModeDropdownValue} actionType="UPDATE_CURRENT_TABLE_VALUE"
            disabled={isDisabled('matchingMode')} />
        </Container>
      </Row>
      <Row>
        <Container style={{ width: CAPTION_WIDTH }}>
          <Label col="12" disabled={isDisabled('overridable')}>{labels.overridable}</Label>
        </Container>
        <Container>
          <Checkbox col="10" bind={tableOverridablePath} actionType="UPDATE_CURRENT_TABLE_VALUE"
            disabled={isDisabled('overridable')} />
        </Container>
      </Row>
      <Row>
        <Container style={{ width: CAPTION_WIDTH }}>
          <Label col="12" disabled={isDisabled('singleValue')}>{labels.singleValue}</Label>
        </Container>
        <Container>
          <Checkbox col="10" bind={tableSingleValuePath} actionType="UPDATE_CURRENT_TABLE_VALUE"
            disabled={isDisabled('singleValue')} />
        </Container>
      </Row>
      <Row>
        <Container style={{ width: CAPTION_WIDTH }}>
          <Label col="12" disabled={isDisabled('publication')}>{labels.publication}</Label>
        </Container>
        <Container style={{ flexDirection: 'row' }}>
          <Checkbox col="1" disabled={isDisabled('publication')} bind={isPublicationTablePath}
            actionType="UPDATE_CURRENT_TABLE_VALUE" />
          <Dropdown col="6" bind={publicationPath} options={publicationOptions} textProp='name' valueProp='value'
            actionType="UPDATE_CURRENT_TABLE_VALUE" disabled={isDisabled('publication')} />
        </Container>
      </Row>

      <br />
      <br />
      <Row>
        <h3 className="title columns-title">{labels.columns}</h3>
      </Row>
      <hr />
      <Row fullHeight={true}>
        <Container className="list-columns">
          <div style={{ width: '100%' }}>
            <Row>
              <Icon icon={sandbox.icons.getGeneralIcon(null, 'add')} title={labels.addSelector}
                onClick={this.handleAddColumn('selectors')} disabled={isFieldDisabled('addColumn')} />
              <h3 style={{ flex: 1, paddingLeft: 5 }} className={isFieldDisabled('addColumn') ? 'disabled' : ''}>
                {labels.selectors}
              </h3>
              <SvgIcon
                key='push_pin_16'
                name='push_pin_16'
                tooltip={labels.pinColumn}
                className='pin-column-icon'
              />
            </Row>
            <hr />
            <List bind={selectorsPath} textProp="displayName" valueProp="name" helperClass="conversion-tables-view-draggable-column-list-item"
              selectedIndex={selectorsSelectedIndex} onSelect={this.handleColumnSelect('selectors')}
              onSortEnd={this.handleColumnSortEnd('selectors')} disabled={isFieldDisabled('addColumn')}
              tablePath={tablePath} columnType={'selectors'} />
            <Row style={{ height: 20 }}>
            </Row>
            <Row>
              <Icon icon={sandbox.icons.getGeneralIcon(null, 'add')} title={labels.addValue}
                onClick={this.handleAddColumn('values')} disabled={isFieldDisabled('addColumn')} />
              <h3 style={{ flex: 1, paddingLeft: 5 }} className={isFieldDisabled('addColumn') ? 'disabled' : ''}>
                {labels.values}
              </h3>
              <SvgIcon
                key='push_pin_16'
                name='push_pin_16'
                tooltip={labels.pinColumn}
                className='pin-column-icon'
              />
            </Row>
            <hr />
            <List bind={valuesPath} textProp="displayName" valueProp="name" helperClass="conversion-tables-view-draggable-column-list-item"
              selectedIndex={valuesSelectedIndex} onSelect={this.handleColumnSelect('values')}
              onSortEnd={this.handleColumnSortEnd('values')} disabled={isFieldDisabled('addColumn')}
              tablePath={tablePath} columnType={'values'} />
          </div>
        </Container>
        <Container className="column-data">
          <Row>
            <h3 style={{ flex: 1 }}>{columnDisplayName + ' ' + columnType}</h3>
            <Icon icon={sandbox.icons.getGeneralIcon(null, 'delete')}
              title={labels.removeColumn} text={labels.removeColumn} onClick={this.handleRemoveColumn}
              disabled={isFieldDisabled('removeColumn')} />
          </Row>
          <hr />
          <Row>
            <Label col="2" disabled={isFieldDisabled('fieldType')}>{labels.fieldType}</Label>
            <Dropdown col="10" bind={columnFieldTypePath} disabled={isFieldDisabled('fieldType')}
              actionType="UPDATE_CURRENT_COLUMN_PROPERTY" options={fieldTypeOptions} />
          </Row>
          {fieldType === 'resources' ?
            <Row>
              <Label col="2" disabled={isFieldDisabled('resourceType')}>{labels.resourceType}</Label>
              <Dropdown col="10" bind={columnResourceTypePath} disabled={isFieldDisabled('resourceType')}
                actionType="UPDATE_CURRENT_COLUMN_PROPERTY" options={resourceTypeOptions} />
            </Row>
            : null}
          <Row>
            <Label col="2" disabled={isFieldDisabled('name')}>{labels.name}</Label>
            <Input col="10" bind={columnNamePath} disabled={isFieldDisabled('name')}
              onChange={this.handleColumnNameChange} />
          </Row>
          <Row>
            <Label col="2" disabled={isFieldDisabled('displayName')}>{labels.displayName}</Label>
            <Input col="10" bind={columnDisplayNamePath} disabled={isFieldDisabled('displayName')}
              actionType="UPDATE_CURRENT_COLUMN_PROPERTY" liveUpdate={true} />
          </Row>
          <Row>
            <Label col="2" disabled={isFieldDisabled('defaultValue')}>{labels.defaultValue}</Label>
            <Input col="10" bind={columnDefaultValuePath} disabled={isFieldDisabled('defaultValue')}
              actionType="UPDATE_CURRENT_COLUMN_PROPERTY" />
          </Row>
          <Row>
            <Label col="2" disabled={isFieldDisabled('freeText')}>{labels.freeText}</Label>
            <Checkbox col="10" bind={columnFreeTextPath} disabled={isFieldDisabled('freeText')}
              actionType="UPDATE_CURRENT_COLUMN_PROPERTY" />
          </Row>
          <Row>
            <Label col="2" disabled={isFieldDisabled('unique')}>{labels.unique}</Label>
            <Checkbox col="10" bind={columnUniquePath} disabled={isFieldDisabled('unique')}
              actionType="UPDATE_CURRENT_COLUMN_PROPERTY" />
          </Row>
          <Row>
            <Label col="2" disabled={isFieldDisabled('maxCharacters')}>{labels.maxCharacters}</Label>
            <Input col="10" bind={columnMaxCharactersPath} disabled={isFieldDisabled('maxCharacters')}
              actionType="UPDATE_CURRENT_COLUMN_PROPERTY" />
          </Row>
          <Row fullHeight={true}>
            <Label col="2" disabled={isFieldDisabled('dropdownOptions')}>{labels.dropdownOptions}</Label>
            <DropdownOptions store={store} disabled={isFieldDisabled('dropdownOptions')} />
          </Row>
        </Container>
      </Row>
    </Container>;
  }

}
