import sandbox from 'sandbox';
import {fromServerDate } from 'core/dates';

const columnsSortComparators = (sortKey, sortOrder) => {
  const emptyComparator = () => 0;
  const comparators = {
    receivedTime: {
      asc: sandbox.sorting.getComparator([{type: 'date', prop: 'recievedTime'}, {type: 'alpha', prop: 'sourceFile'}]),
      desc: sandbox.sorting.getComparator([{type: 'datedecending', prop: 'recievedTime'}, {type: 'alpha', prop: 'sourceFile'}])
    },
    sourceFile: {
      asc: sandbox.sorting.getComparator([{type: 'alpha', prop: 'sourceFile'}, {type: 'date', prop: 'recievedTime'}]),
      desc: sandbox.sorting.getComparator([{type: 'alphadecending', prop: 'sourceFile'}, {type: 'date', prop: 'recievedTime'}])
    },
    commonBreadcrumbs: {
      asc: sandbox.sorting.getComparator([{type: 'alpha', prop: 'commonBreadcrumbs'}, {type: 'date', prop: 'recievedTime'}]),
      desc: sandbox.sorting.getComparator([{type: 'alphadecending', prop: 'commonBreadcrumbs'}, {type: 'date', prop: 'recievedTime'}])
    }
  };
  return comparators[sortKey][sortOrder] || emptyComparator;
};
const usersAlphaNumericComarator = sandbox.sorting.getComparator('alpha', 'name');

const commonBreadcrumbsValue = (breadcrumbPaths = []) => {
  const breadcrumbs = breadcrumbPaths.map(breadcrumbPath => breadcrumbPath.split('/'));

  if (breadcrumbs.length === 0) return '';

  const commonBreadcrumbsLength = breadcrumbs[0].findIndex((breadcrumb, index) => {
    return !breadcrumbs.every(breadcrumbToCheckEqual => breadcrumbToCheckEqual[index] == breadcrumb);
  });

  return commonBreadcrumbsLength < 0 ? breadcrumbs[0].join('/') : breadcrumbs[0].slice(0, commonBreadcrumbsLength).join('/');
};

const removeFile = (state, { nwid }) => {
  return {
    ...state,
    files: state.files.filter(file => file.nwid !== nwid)
  };
};

const firstLoad = (state, { files = [], dbRoot, sortKey, sortOrder }) => {
  return {
    ...state,
    files: files.map(file => ({
      ...file, 
      sourceFile: file.sourceFile || '',
      recievedTime: file.recievedTime ? fromServerDate(file.recievedTime) : new Date(-8640000000000000),
      commonBreadcrumbs: commonBreadcrumbsValue(file.breadcrumbs)
    })).sort(columnsSortComparators(sortKey, sortOrder)),
    dbRoot,
    sortKey,
    sortOrder,
    loading: false
  };
};

const setLoading = (state, { loading }) => {
  return {
    ...state,
    loading
  };
};

const loadFiles = (state, { files = [], mode }) => {
  return {
    ...state,
    files: files.map(file => ({
      ...file, 
      sourceFile: file.sourceFile || '',
      recievedTime: file.recievedTime ? fromServerDate(file.recievedTime) : new Date(-8640000000000000),
      commonBreadcrumbs: commonBreadcrumbsValue(file.breadcrumbs)
    })).sort(columnsSortComparators(state.sortKey, state.sortOrder)),
    loading: false,
    mode
  };
};

const sortFiles = (state, { sortKey, sortOrder }) => {
  return {
    ...state,
    files: state.files.sort(columnsSortComparators(sortKey, sortOrder)),
    sortKey,
    sortOrder
  };
};

export default function (state = {}, action) {
  switch (action.type) {
    case 'REMOVE_FILE':
      return removeFile(state, action);

    case 'FIRST_LOAD':
      return firstLoad(state, action);

    case 'SET_LOADING':
      return setLoading(state, action);

    case 'LOAD_FILES':
      return loadFiles(state, action);

    case 'SORT_FILES':
      return sortFiles(state, action);

    default:
      return {
        ...state
      };
  }
}