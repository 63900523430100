import AbstractAction from 'AbstractAction';
import request from 'core/managers/request';
import openModule from 'actions/utilities/openModule';
import openDialog from 'actions/utilities/openDialog';
import { translate } from 'core/services/localization';

/*
  A new TemplateBasedFormFanout mode was added so the moduleName and title properties were removed from the OpenFanoutActionCR.xml file
  and now it is decided dynamically according to the fanoutMode
*/
module.exports = AbstractAction.extend({
  execute: function (objects) {
    const rest = request.rest(this.module.nwid);
    rest.get(`fanouts/runs/${objects[0].nwid}/mode`, { dataType: 'text' }, 'v2').then(fanoutMode => {
      if (fanoutMode === 'PageFanout') {
        openModule(this, objects);
      } else {
        if (fanoutMode === 'TemplateBasedFormFanout') {
          this.parameters.moduleName = 'TemplateBasedFormFanoutSetup';
          this.parameters.title = translate('Template Based Form Fanout');
          openDialog(this, objects);
        } else {
          this.parameters.moduleName = 'SimpleFormeFanoutsSetup';
          this.parameters.title = translate('Simple Forme Fanout');
          openDialog(this, objects);
        }
      }
    });
  }
});