/**
 * @name elementBase
 * @file Delegates functionality to the concrete element
 *
 * @author Boris
 * @since: 2016-06-23
 */

import Plate from './plate';
import PrintArea from './printArea';
import InkArea from './inkArea';
import Barcode from './barcode';
import Erase from './erase';
import Furniture from './furniture';
import Regmark from './regmark';
import TextBurn from './textBurn';
import CellGrid from './cellGrid';
import Cell from './cell';
import CropMarks from './cropMarks';
import CenterMarks from './centerMarks';
import Guideline from './guideline';

export default (editor) => {

  const CONCRETE_BY_TYPE = {
    plate: Plate(editor),
    printArea: PrintArea(editor),
    inkArea: InkArea(editor),
    barcode: Barcode(editor),
    erase: Erase(editor),
    furniture: Furniture(editor),
    regmark: Regmark(editor),
    textBurn: TextBurn(editor),
    cellGrid: CellGrid(editor),
    cell: Cell(editor),
    cropMarks: CropMarks(editor),
    centerMarks: CenterMarks(editor),
    guideline: Guideline(editor),
  };

  const getConcrete = (elementType) => {
    return CONCRETE_BY_TYPE[elementType];
  };

  const getElementType = (element) => {
    return !element ? '' : element.elementType;
  };

  const buildDefaultElement = (state, elementType, optionId) => {
    var concrete = getConcrete(elementType);
    if (concrete) {
      return concrete.buildDefaultElement(state, elementType, optionId);
    }
  };

  const setDefaultElementValues = (state, element, elementPath) => {
    var concrete = getConcrete(getElementType(element));
    if (concrete) {
      return concrete.setDefaultElementValues(state, element, elementPath);
    }
  };

  const moveShape = (state, element, canvasShape, event) => {
    var concrete = getConcrete(getElementType(element));
    return !concrete || typeof concrete.moveShape !== 'function' ? element :
      concrete.moveShape(state, element, canvasShape, event);
  };

  const shapeTransforming = (state, element, canvasShape, event) => {
    var concrete = getConcrete(getElementType(element));
    return !concrete || typeof concrete.shapeTransforming !== 'function' ? element :
      concrete.shapeTransforming(state, element, canvasShape, event);
  };

  const shapeMouseDown = (state, element, canvasShape, event) => {
    var concrete = getConcrete(getElementType(element));
    return !concrete || typeof concrete.shapeMouseDown !== 'function' ? element :
      concrete.shapeMouseDown(state, element, canvasShape, event);
  };

  const shapeMouseUp = (state, element, canvasShape, event) => {
    var concrete = getConcrete(getElementType(element));
    return !concrete || typeof concrete.shapeMouseUp !== 'function' ? element :
      concrete.shapeMouseUp(state, element, canvasShape, event);
  };

  const shapeTextEvent = (state, element, canvasShape, eventName) => {
    var concrete = getConcrete(getElementType(element));
    return !concrete || typeof concrete.shapeTextEvent !== 'function' ? element :
      concrete.shapeTextEvent(state, element, canvasShape, eventName);
  };

  const updateProperty = (state, element, elementPath, propertyPath, propertyValue) => {
    var concrete = getConcrete(getElementType(element));
    return !concrete ? state : concrete.updateProperty(state, element, elementPath, propertyPath, propertyValue);
  };

  const dependencyChanged = (state, element, elementPath, oldSource, newSource) => {
    var concrete = getConcrete(getElementType(element));
    return !concrete ? state : concrete.dependencyChanged(state, element, elementPath, oldSource, newSource);
  };

  const arrangeElements = (state, elementType) => {
    var concrete = getConcrete(elementType);
    return !concrete || typeof concrete.arrangeElements !== 'function' ? state : concrete.arrangeElements(state);
  };

  const createShape = (state, element, elementPath) => {
    var concrete = getConcrete(getElementType(element));
    if (concrete && typeof concrete.createShape === 'function') {
      return concrete.createShape(state, element, elementPath);
    }
  };

  const updateShape = (state, element) => {
    var concrete = getConcrete(getElementType(element));
    if (concrete && typeof concrete.updateShape === 'function') {
      return concrete.updateShape(state, element);
    }
  };

  const addShape = (state, element) => {
    var concrete = getConcrete(getElementType(element));
    if (concrete && typeof concrete.addShape === 'function') {
      return concrete.addShape(state, element);
    }
  };

  const removeShape = (state, element) => {
    var concrete = getConcrete(getElementType(element));
    if (concrete && typeof concrete.removeShape === 'function') {
      return concrete.removeShape(state, element);
    }
  };

  const activateShape = (state, element) => {
    var concrete = getConcrete(getElementType(element));
    if (concrete && typeof concrete.activateShape === 'function') {
      return concrete.activateShape(state, element);
    }
  };

  const nudgeShape = (state, element, direction) => {
    var concrete = getConcrete(getElementType(element));
    return !concrete || typeof concrete.nudgeShape !== 'function' ? element : concrete.nudgeShape(state, element, direction);
  };

  const getAlignmentInfo = (state, element) => {
    var concrete = getConcrete(getElementType(element));
    if (concrete && typeof concrete.getAlignmentInfo === 'function') {
      return concrete.getAlignmentInfo(state, element);
    }
  };

  const renderProperties = (store, element, elementPath) => {
    var concrete = getConcrete(getElementType(element));
    return !concrete ? null : concrete.renderProperties(store, element, elementPath);
  };

  const getInfo = (state, elementType) => {
    var concrete = getConcrete(elementType);
    return !concrete ? {} : concrete.getInfo(state);
  };

  const validate = (state, element) => {
    const concrete = getConcrete(getElementType(element));
    return !concrete || typeof concrete.validate !== 'function' ? '' : concrete.validate(state, element);
  };

  return {
    buildDefaultElement,
    setDefaultElementValues,
    updateProperty,
    dependencyChanged,
    arrangeElements,
    moveShape,
    shapeTransforming,
    shapeMouseDown,
    shapeMouseUp,
    shapeTextEvent,
    createShape,
    updateShape,
    addShape,
    removeShape,
    activateShape,
    nudgeShape,
    renderProperties,
    getInfo,
    getAlignmentInfo,
    validate
  };

};
