import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utilities/classNames';

function Button({
  children,
  className,
  style,
  disabled = false,
  onClick,
  type = 'button'
}) {

  const handleClick = event => {
    if (disabled) {
      return;
    }

    if (typeof onClick === 'function') {
      onClick(event);
    }
  };

  return (
    <button
      type={type}
      className={classNames('crtx-button', className, { disabled })}
      disabled={disabled}
      style={style}
      onClick={handleClick}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

export default Button;
