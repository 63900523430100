import React from 'react';

export default class extends React.Component {
  static defaultProps = {
    point: undefined,
    separations: []
  };

  render() {
    var {
            point,
      separations,
      style
          } = this.props;

    var separationsClassName = separations.length > 1 ?
      'SeparationColors' :
      'SeparationBlack';

    return <div className="Separation" style={style}>
      <div className={separationsClassName}>
      </div>
    </div>;
  }
}