import React from 'react';
import { createRoot } from 'react-dom/client';
import AbstractModule from 'AbstractModule';
import PasswordResetContainer from './components/PasswordResetContainer';
import dom from 'base/dom';

export default AbstractModule.extend({
  initDone: function () {
    this.domElement = document.getElementById('auth');
    this.reactRoot = createRoot(this.domElement);
    this.render();
  },

  destroy: function () {
    this._super();
    this.reactRoot.unmount();
  },

  render: function () {
    dom.empty(this.domElement);
    dom.show(this.domElement);
    dom.hide('#info');
    this.reactRoot.render(<PasswordResetContainer />);
  },

});
