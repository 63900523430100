
define(['ember', 'core/services/toastService'],
  function (Ember, toastService) {
    'use strict';
    function validWrapped(sections){
      for (var i=0;i<sections.length;i++){
        if (sections[i].numPages % 2 > 0){
          return false;
        }
      }
      return true;
    }

    return Ember.Checkbox.extend({
      //placeholderBinding: this.get('key'),
      _setRemoteValue: (function() {
        var val;
        val = this.get('checked');

        // if (this.get("model.numPages") % 2 > 0){
        //   this.set("disabled", true);
        // }

        if (val && this.get('key') == "split" && this.get("model.numPages") % 2 > 0){
          // this.set('checked', false)
          // toastService.createToast('top-right', 'Sections number of pages should be even number', '', 'error', undefined, undefined, 2000);
          // return;
        } else if ("wrapped" == this.get('key')){
          // var valid = validWrapped(this.get("model.sections"));
          // if (!valid){
          //   this.set('checked', false);
          //   toastService.createToast('top-right', 'Sections number of pages should be even number', '', 'error', undefined, undefined, 2000);
          //   return;
          // }          
        }

        if (val !== null) {
          return this.set("model." + (this.get('key')), val);
        }
      }).observes('checked'),
      didInsertElement: function() {
        var updateCurrentValue;
        updateCurrentValue = (function(_this) {
          return function() {
            var currentValue;
            currentValue = _this.get("model." + (_this.get('key')));
            if (currentValue !== null) {
              return _this.set('checked', currentValue);
            }
          };
        })(this);
        updateCurrentValue();
        return this.addObserver("model." + (this.get('key')), updateCurrentValue);
      }
    });
  });