import React from 'react';
import { Dialog } from 'components/common/dialogs';

export const SelectedPagesDialog = ({ selectedPages, initialLeft, initialTop, initialWidth, initialHeight, title, onChangeBounds }) => {
  return <Dialog initialLeft={initialLeft}
    initialTop={initialTop}
    initialWidth={initialWidth}
    initialHeight={initialHeight}
    title={title}
    autoFocus={false}
    onChangeBounds={onChangeBounds}
  >
    <div>
      {selectedPages.map((item, index) => <div key={`${item.nwid}-${index}`} className='unplan-table-view-selected-pages-dialog-item'>
        <label className='unplan-table-view-selected-pages-dialog-item-content'>
          <span title={item.name} className='unplan-table-view-selected-pages-dialog-item-content-text'>{item.name}</span>
          <span>{index + 1}</span>
        </label>
      </div>)}
    </div>
  </Dialog>
}