import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utilities/classNames';
import { getGeneralSvgIconHref, getTemplateSvgIconHref } from 'core/services/iconService';

export const ICON_SPRITE = {
  GENERAL: 'general',
  TEMPLATE: 'template'
};

function SvgIcon(
  {
    name,
    sprite = ICON_SPRITE.GENERAL,
    tooltip,
    className,
    style
  }) {

  const getSvgIconHref = () => {
    let href;
    if (sprite === ICON_SPRITE.GENERAL) {
      href = getGeneralSvgIconHref(name);
    } else if (sprite === ICON_SPRITE.TEMPLATE) {
      href = getTemplateSvgIconHref(name);
    }

    return href;
  };

  return (
    <svg className={classNames('crtx-svg-icon', className)} style={style}>
      {tooltip ? <title>{tooltip}</title> : null}
      <use xlinkHref={getSvgIconHref()} />
    </svg>
  );
}

SvgIcon.propTypes = {
  name: PropTypes.string.isRequired,
  sprite: PropTypes.string,
  tooltip: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.string,
};

export default SvgIcon;
