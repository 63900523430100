/**
 * @fileOverview
 * @author sergey
 */

import sandbox from 'sandbox';
import AbstractAction from 'AbstractAction';
import { canRoute } from 'utilities/retransmit';

export default AbstractAction.extend({

  isApplicable: function (objs) {
    if (typeof objs === 'undefined' || objs.length === 0) {
      return false;
    }
    let result = true;
    for (let obj of objs) {
      if (typeof obj === 'undefined' || !canRoute(obj, this.config)) {
        result = false;
        break;
      }
    }
    return result;
  },

  execute: function (objs) {
    var viewInstanceNwId = this.module.nwid;
    var projectorId = this.module.projectorId;

    var request = sandbox.request.route(viewInstanceNwId, this.nwid, null, null, projectorId,
      {
        config: this.config,
        parameters: this.parameters,
        name: this.name,
        objects: objs
      }
    );
    return request.then(function (res) {
      var code = res.statusCode;
      if (code !== 200) {
        var message = res.errorMessage;
        if (res !== null) {
          sandbox.dialog.alert(message);
        }
      }
    });
  }
});