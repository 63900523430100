import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Card extends Component {
  constructor(props) {
    super(oprops);
  }
  static propTypes = {
    name: PropTypes.string,
  }

  render() {
    return null
  }
};
module.exports = Card;