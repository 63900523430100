import PropTypes from 'prop-types';
import CanvasComponent from './canvasComponent';
import { deg2rad} from './utilities';

export class Text extends CanvasComponent {
  static propTypes = {
    point: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number
    }).isRequired,
    font: PropTypes.string,
    text: PropTypes.string,
    fillStyle: PropTypes.string,
    strokeStyle: PropTypes.string,
    lineWidth: PropTypes.number,
    rotation: PropTypes.number,
    centered: PropTypes.bool,
  };

  static defaultProps = {
    font: '72px serif',
    fillStyle: '#000',
    rotation: 0,
    centered: false,
  };

  draw = (ctx) => {
    const { font, text, fillStyle, strokeStyle, lineWidth, rotation, centered } = this.props;

    ctx.save();
    ctx.font = font;
    ctx.fillStyle = fillStyle;
    ctx.strokeStyle = strokeStyle;
    ctx.lineWidth = lineWidth;
    ctx.rotate(deg2rad(rotation));
    if (centered) {
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
    }
    ctx.fillText(text, 0, 0);
    strokeStyle && ctx.strokeText(text, 0, 0);
    ctx.restore();
  };
}