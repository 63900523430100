import React from 'react';
import iconsService from 'core/services/iconService';
import { startInternalModule } from 'core/managers/module';
import { translate } from 'core/services/localization';
import FlatButton from 'components/common/buttons/FlatButton';

export default function TextList({ items, onSelect, windowTitle, target, windowWidth, windowHeight, editButtonTooltip, className, disabled }) {

  const handleEditClick = () => {

    startInternalModule('EditTextListSetup', {
      name: windowTitle,
      target,
      windowWidth,
      windowHeight,
      items,
      onSave: onSelect, // saveItems method was changed to onSelect because Validator component uses the onSelect callback
      modal: true
    });
  };

  return <div className={className}>
    <div className='crtx-form-bordered-section'>
    <div className='crtx-form-two-columns-with-buttons'>
      {(typeof items === 'undefined' || items.length === 0) ?
        <label>{translate('This list is empty')}</label>
        :
        <div className='crtx-form-one-column'>
          {items.map((item, index) =>
            <label key={`${item} - ${index}`} className='text-list-label' title={item}>{item}</label>
          )}
        </div>}
      <div className='crtx-form-buttons-container'>
        <FlatButton className='crtx-form-button-link' onClick={handleEditClick} title={editButtonTooltip} disabled={disabled}>
          <img className='material-icons' src={iconsService.getGeneralIcon(null, 'edit')} />
        </FlatButton>
      </div>
    </div>
  </div>
  </div>
}