/**
 * Created by Elad on 10/7/2015.
 */
import AbstractAction from 'AbstractAction';
import sandbox from 'sandbox';

export default AbstractAction.extend({
  execute: function () {

    var params = {
      logSize: Number(this.module.viewModel.logSize),
      saveAt: this.module.viewModel.saveAt,
      logs: this.module.viewModel.logs,
      nwid: this.module.viewModel.nwid,
      selected: this.module.viewModel.selected,
      type: this.module.viewModel.type,
      action: this.module.viewModel.action,
      childrenNames: this.module.viewModel.childrenNames,
      id: this.module.viewModel.id

    };

    return sandbox.request.saveSetup(
      this.nwid,
      this.module.nwid,
      this.module.type,
      this.module.projectorId,
      params,
      undefined,
      this.parameters.scriptName
    );
  }
});