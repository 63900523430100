import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';
import ReactComponents from 'widgets/ReactComponents/src/index';
import utils from '../utils';
import SimpleFormComponent from '../SimpleFormComponent';
import Row from './Row';
import Container from './Container';
import actions from '../actions';

var { List, Icon } = ReactComponents;

var ADD_ROW_ICON_SRC = sandbox.icons.getGeneralIcon(null, 'add');
var REMOVE_ROW_ICON_SRC = sandbox.icons.getGeneralIcon(null, 'delete');

export default SimpleFormComponent(class extends Component {
  static propTypes = {

    //store - The main store of the SimpleForm that the input is child of.
    store: PropTypes.object,

    // selected item
    bind: PropTypes.string,

    // component specific props
    // items of list
    // array of { text , value }
    items: PropTypes.array.isRequired,
    itemDataGetter: PropTypes.func,
    buttons: PropTypes.array,
    onAddRow: PropTypes.func,
    onRemoveRow: PropTypes.func,
    onSelectRow: PropTypes.func,

    // simple form grid props
    size: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),

    style: PropTypes.object,

    className: PropTypes.string,

    disabled: PropTypes.bool,

    visible: PropTypes.bool,

    col: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),

    offset: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ])

  };

  static defaultProps = {};

  handleClick = (id) => {
    var { store, bind, onSelectRow } = this.props;

    if (utils.isFunction(onSelectRow)) {
      onSelectRow(bind, id);
    }
    else {
      store.dispatch(actions.update(bind, id));
    }
  };

  getSelected = () => {

    var { store, bind, multiSelect } = this.props;

    var selected = store.get(bind);

    if (multiSelect)
      return {
        selectedItem: null,
        selectedItems: selected
      };
    else {
      return {
        selectedItem: selected,
        selectedItems: []
      };
    }
  };

  addStandardButtons = () => {
    var { onRemoveRow, buttons } = this.props;

    if (!utils.isUndefined(buttons)) return buttons;

    var standardButtons = [];

    if (utils.isFunction(onRemoveRow)) {
      standardButtons.splice(1, 0, {
        id: 'remove',
        icon: REMOVE_ROW_ICON_SRC,
        title: 'Remove',
        iconClassName: "icon-remove",
        onClick: onRemoveRow
      });
    }
    return standardButtons;

  };

  addAddLine = () => {

    var { onAddRow, addRowTitle } = this.props;
    if (utils.isFunction(onAddRow)) {

      return <Row className="list-toolbar">
        <Icon id="add" icon={ADD_ROW_ICON_SRC} title="Add" text={addRowTitle} iconClassName="icon-add" onClick={onAddRow}></Icon>
      </Row>;
    }
  };

  render() {

    var { store, bind, visible, className, col, offset, onClick, buttons, onAddRow, ...props } = this.props;

    var { selectedItem, selectedItems } = this.getSelected();
    var listButtons = this.addStandardButtons();
    var addLine = this.addAddLine();
    const isToolbarVisible = utils.isFunction(onAddRow);

    return <Container className="list-container">
      {addLine}
      <List onClick={this.handleClick}
        buttons={listButtons}
        selectedItem={selectedItem}
        selectedItems={selectedItems}
        className={isToolbarVisible ? 'list-with-parent' : null}
        {...props } />
    </Container>;
  }
});
