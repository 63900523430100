/**
 * Created by elad on 12/9/2015.
 */
define(['sandbox', './ServerAction', 'actions/actionUtils', 'utilities/statuses'], function (sandbox, ServerAction, actionUtils, statuses) {
  'use strict';

  return ServerAction.extend({

    isApplicable: function () {
      var obj = this.module.selected;
      if (obj?.length === 0 || (statuses.getMainStatus(obj[0]) && statuses.getMainStatus(obj[0])?.progress != -1)) { ///it should work if the protgress is string ot int
        return false;
      };
      for (var i = 0; i < obj.length; i++) {
        if (obj[i].ignore) {
          return false;
        }
      }

      return actionUtils.isPlanned(this.module);
    }
  });
});
