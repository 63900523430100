import { canRetransmit } from '../../utilities/retransmit';
import OpenDialogAction from './OpenDialogAction';

export default OpenDialogAction.extend({

  isApplicable: function (objs) {
    if (typeof objs === 'undefined' || objs.length <= 0) {
      return false;
    }
    return objs.some(obj => canRetransmit(obj, this.config));
  }
});