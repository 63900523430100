/* @flow weak */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Book from './Book';
import SimpleForm from 'widgets/SimpleForm/src/index';

const futils = SimpleForm.utils;

const BOOKS_PATH = 'model.books';

module.exports = class Fanout extends Component {
  constructor(props) {
    super(props);
    this.renderBook = this.renderBook.bind(this);
  }

  static propTypes = {
    store: PropTypes.any
  };

  renderBook(book, index) {
    const { store } = this.props;
    const path = futils.compose(BOOKS_PATH, index);
    const key = `${book.nwid}_${index}`;

    return (
      <Book key={key} store={store} book={book} path={path} />
    );
  }

  render() {
    const { store } = this.props;
    const state = store.getState();
    const books = state.model.books;
    //console.log("state.model =>", state.model);

    return (
      <SimpleForm.Form store={store} className="fanout-view">
        {books.map(this.renderBook)}
      </SimpleForm.Form>);
  }

};
