import React from 'react';
import { createRoot } from 'react-dom/client';
import { translate } from 'core/services/localization';
import prefernecesManager from 'core/managers/preferences';
import toastService from 'core/services/toastService';
import { MSEC_IN_HOUR, fromServerDate, getMaxDate, getFolderCurrentDate } from 'core/dates';
import { throttle, round } from 'sandbox/jsUtils';
import { arrayToObject, sum } from 'utilities/array';
import { restGet, restPost } from 'core/managers/rest2';
import { createObjectComparator, COMPARE_TYPE } from 'core/comparators';
import { startModule } from 'core/managers/module';
import AbstractModule from 'AbstractModule';
import TickableModel from '../TickableModel';
import {
  STATUS,
  PLATE_SIZE,
  NO_SORTER,
  calcStartPosition,
  calcEndPosition,
  getPlateStatus,
  isValidPosition,
  isDoubleSizeBin,
  arrayTrimEnd,
} from './utils';
import View from './View';
import { getHoldType } from 'utilities/hold';

const THROTTLE_WAIT = 1000;

const MAX_HOURS = 100000;

const toBinsByNameAndSorter = (sorters) => {
  const binsByNameAndSorter = Object.entries(sorters).reduce((acc, [key, value]) => {
    const binsByName = arrayToObject(value.bins, 'name');
    acc[key] = { binsByName };

    return acc;
  }, {});

  return binsByNameAndSorter;
};

const distributePlatesByBins = (plates, binsBySorter, holdByBinId) => {
  const getBinDefinition = (sorterName, binName) => {
    let bin = binsBySorter[sorterName]?.binsByName[binName] || { notFound: true, name: binName };
    bin = { ...bin, expected: 0, inBinCount: 0, inProgressCount: 0, missingCount: 0, errorCount: 0 };
    // bin = { ...bin, expected: 10, inBinCount: 2, inProgressCount: 3, missingCount: 4, errorCount: 1 }; // ***TEST

    return bin;
  };

  const getBinInfo = (sorter, binName) => {
    let bin = sorter.binsByName[binName];
    if (!bin) {
      bin = getBinDefinition(sorter.name, binName);
    }

    return bin;
  };

  const getSpecialBinInfo = (sorter, binName) => {
    let bin = sorter.specialBinsByName[binName];
    if (!bin) {
      bin = getBinDefinition(sorter.name, binName);
    }

    return bin;
  };

  const calcSorterCounts = (platesByBinAndSorter) => {
    Object.values(platesByBinAndSorter).forEach(sorter => {
      const bins = Object.values(sorter.binsByName).concat(Object.values(sorter.specialBinsByName));
      sorter.inBinCount = sum(bins, 'inBinCount');
      sorter.inProgressCount = sum(bins, 'inProgressCount');
      sorter.missingCount = sum(bins, 'missingCount');
      sorter.errorCount = sum(bins, 'errorCount');
    });
  };

  const platesByBinAndSorter = plates.reduce((acc, plate) => {
    if (plate.isActual && plate.sorter) {
      const sorterName = plate.sorter;
      const sorter = acc[sorterName] || {
        name: sorterName,
        binsByName: {},
        specialBinsByName: {},
        totalCount: 0,
      };

      sorter.totalCount++;
      if (plate.bin) {
        const binName = plate.bin;
        const bin = getBinInfo(sorter, binName);
        bin.id = plate.binId;
        bin.hold = holdByBinId[plate.binId];
        const plateSize = plate.formSeparation?.formSeparationContent?.plateSize;
        if (plateSize && plateSize !== bin.plateSize) {
          bin.plateSize = !bin.plateSize ? plateSize : PLATE_SIZE.BOTH;
        }

        bin.expected++;
        const status = getPlateStatus(plate);
        bin.inBinCount += status === STATUS.IN_BIN ? 1 : 0;
        bin.inProgressCount += status === STATUS.IN_PROGRESS ? 1 : 0;
        bin.missingCount += status === STATUS.MISSING ? 1 : 0;
        bin.errorCount += status === STATUS.ERROR ? 1 : 0;

        sorter.binsByName[binName] = bin;

        let specialBin;
        const manulaBinName = bin.manualBin;
        if (manulaBinName) {
          specialBin = getSpecialBinInfo(sorter, manulaBinName);
          specialBin.isManualBin = true;
          specialBin.inBinCount += parseInt(plate.manualBinCounter || 0, 10);
          sorter.specialBinsByName[manulaBinName] = specialBin;
        }

        const revisionBinName = bin.revisionBin;
        if (revisionBinName) {
          specialBin = getSpecialBinInfo(sorter, revisionBinName);
          specialBin.isRevisionBin = true;
          specialBin.inBinCount += parseInt(plate.revisionBinCounter || 0, 10);
          sorter.specialBinsByName[revisionBinName] = specialBin;
        }
      }

      acc[sorterName] = sorter;
    }

    return acc;
  }, {});

  calcSorterCounts(platesByBinAndSorter);

  return platesByBinAndSorter;
};

const createGroups = (sorterName, binsBySorter) => {
  if (!binsBySorter[sorterName]) {
    return [];
  }

  const { binsByName = {} } = binsBySorter[sorterName];
  const bins = Object.values(binsByName);

  const groups = [];
  for (const level of ['top', 'bottom']) {
    for (const side of ['front', 'back']) {
      const assignedBins = bins.filter(bin => bin.level === level && bin.side === side);

      groups.push({
        assignedBins,
        level,
        side,
        start: calcStartPosition(assignedBins),
        end: calcEndPosition(assignedBins),
        bins: [],
        specialBins: []
      });
    }
  }

  return groups;
};

const toGroupsByLevelAndSide = (groups) => {
  return groups.reduce((acc, group) => {
    acc[group.level + group.side] = group;

    return acc;
  }, {});
};

const distributeBinsByGroups = (binsByName, specialBinsByName, sorterName, binsBySorter, plannedOnly) => {
  if (!binsBySorter[sorterName]) {
    return [];
  }

  const bins = Object.values(binsByName);
  const specialBins = Object.values(specialBinsByName);
  const groups = createGroups(sorterName, binsBySorter);
  const groupsByLevelAndSide = toGroupsByLevelAndSide(groups);

  for (const bin of bins) {
    const group = groupsByLevelAndSide[bin.level + bin.side];
    group && group.bins.push(bin);
  }

  for (const bin of specialBins) {
    const group = groupsByLevelAndSide[bin.level + bin.side];
    group && group.specialBins.push(bin);
  }

  for (let i = 0; i < 2; i++) {
    adjustStartPosition(groups[i], groups[i + 2]);
  }

  markGroupsSkipped(groups);

  for (const group of groups) {
    positionBins(group, plannedOnly);
  }

  if (plannedOnly) {
    removeUnusedBins(groups);
  }

  for (const group of groups) {
    removeCoveredBins(group);
  }

  return groups;
};

const removeCoveredBins = (group) => {
  if (group.skipped) {
    return;
  }

  group.positionedBins = group.positionedBins.reduce((acc, bin, index, bins) => {
    const prevBin = bins[index - 1];
    if (bin || !prevBin?.doubleSize) {
      acc.push(bin);
    }

    return acc;
  }, []);
};

const removeUnusedBins = (groups) => {
  const removeUnused = (topGroup, bottomGroup) => {
    if (topGroup.skipped && bottomGroup.skipped) {
      return;
    }

    let positionedTopBins = [];
    let positionedBottomBins = [];

    const topBins = topGroup.positionedBins || [];
    const bottomBins = bottomGroup.positionedBins || [];
    const length = Math.max(topBins.length, bottomBins.length);
    for (let i = 0; i < length; i++) {
      const topBin = topBins[i];
      const prevTopBin = topBins[i - 1];
      const bottomBin = bottomBins[i];
      const prevBottomBin = bottomBins[i - 1];

      if (topBin || bottomBin || prevTopBin?.doubleSize || prevBottomBin?.doubleSize) {
        i < topBins.length && positionedTopBins.push(topBin);
        i < bottomBins.length && positionedBottomBins.push(bottomBin);
      }
    }

    topGroup.positionedBins = arrayTrimEnd(positionedTopBins);
    bottomGroup.positionedBins = arrayTrimEnd(positionedBottomBins);
  };

  for (let i = 0; i < 2; i++) {
    removeUnused(groups[i], groups[i + 2]);
  }
};

const positionBins = (group, plannedOnly) => {
  if (group.skipped) {
    return;
  }

  const { bins, specialBins, start, end, assignedBins } = group;
  const plannedBins = bins.concat(specialBins);
  let positionedBins = [];
  let unpositionedBins = [];

  const isOverlappedBin = (bin, currentBin, previousBin, nextBin) => {
    return !!(currentBin || previousBin && previousBin.doubleSize || nextBin && bin.doubleSize);
  };

  const doPositionBins = (sourceBins, planned = true) => {
    for (let bin of sourceBins) {
      bin = {
        ...bin,
        planned,
        doubleSize: isDoubleSizeBin(bin.size, bin.plateSize),
      };

      if (!isValidPosition(bin.position)) {
        bin.positionNotDefined = true;
        unpositionedBins && unpositionedBins.push(bin);
      } else {
        const idx = bin.position - start;
        bin.overlapped = isOverlappedBin(bin, positionedBins[idx], positionedBins[idx - 1], positionedBins[idx + 1]);
        if (!bin.overlapped) {
          positionedBins[idx] = bin;
        } else if (unpositionedBins) {
          unpositionedBins.push(bin);
        }
      }
    }
  };

  const positionPlannedBins = () => {
    const length = (!isNaN(start) && !isNaN(end) && end >= start) ? end - start + 1 : 0;

    positionedBins = Array.from({ length });

    doPositionBins(plannedBins, true);
  };

  const positionUnplannedBins = () => {
    const plannedBinsByName = arrayToObject(plannedBins, 'name');
    const unplannedBins = assignedBins.filter(bin => !plannedBinsByName[bin.name]);

    doPositionBins(unplannedBins, false);
  };

  positionPlannedBins();
  if (!plannedOnly) {
    positionUnplannedBins();
  }

  group.positionedBins = positionedBins;
  group.unpositionedBins = unpositionedBins;
};

const markGroupsSkipped = (groups) => {
  const markSkipped = (group1, group2) => {
    if (group1.bins.length <= 0 && group2.bins.length <= 0 && group1.specialBins.length <= 0 && group2.specialBins.length <= 0) {
      group1.skipped = true;
      group2.skipped = true;
    }
  };

  groups.forEach(group => group.skipped = false);

  for (let i = 0; i < 2; i++) {
    markSkipped(groups[i], groups[i + 2]);
  }

  for (let i = 0; i < 4; i += 2) {
    markSkipped(groups[i], groups[i + 1]);
  }
};

const adjustStartPosition = (topGroup, bottomGroup) => {
  if (!isNaN(topGroup.start) && !isNaN(bottomGroup.start)) {
    const start = Math.min(topGroup.start, bottomGroup.start);
    topGroup.start = start;
    bottomGroup.start = start;
  }
};

const populateBinGroups = (platesByBinAndSorter, binsBySorter, plannedOnly) => {
  const sorters = Object.values(platesByBinAndSorter).map(sorter => {
    const {
      name,
      totalCount,
      inBinCount,
      inProgressCount,
      missingCount,
      errorCount,
      binsByName,
      specialBinsByName
    } = sorter;

    const groups = distributeBinsByGroups(binsByName, specialBinsByName, name, binsBySorter, plannedOnly);
    const notFoundBinNames = Object.values(binsByName).filter(bin => bin.notFound).map(bin => bin.name);

    return {
      name,
      totalCount,
      inBinCount,
      inProgressCount,
      missingCount,
      errorCount,
      groups,
      notFoundBinNames,
    };
  });

  return sorters;
};

const getUnassignedPlateCount = (plates) => {
  return sum(plates, plate => plate.sorter === NO_SORTER || !plate.sorter || !plate.bin);
};

export default AbstractModule.extend({
  initDone: function () {
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleFilterRefresh = this.handleFilterRefresh.bind(this);
    this.handleClickZoomIn = this.handleClickZoomIn.bind(this);
    this.handleClickZoomOut = this.handleClickZoomOut.bind(this);

    this.updates = [];

    this.reactRoot = createRoot(this.domElement);
    this.tickUpdateHandlerThrottled = throttle(this.tickUpdateHandler, THROTTLE_WAIT, {
      leading: false,
      trailing: true
    });
  },

  firstTickReceived: function (data) {
    this.preferences = data.preferences || {};
    this.guiSettings = data.GuiSettings || {};
    this.binsByNameAndSorter = {};
    const { hoursBefore = '', hoursAfter = '' } = this.preferences;
    this.filterOptions = { name: '', hoursBefore, hoursAfter };

    this.tickableModel = new TickableModel();

    this.tickableModel.firstTickHandler(data.model);

    restGet(this.nwid, 'sorters/get-sorters').then(res => {
      const { sorters = {} } = res;
      this.binsByNameAndSorter = toBinsByNameAndSorter(sorters);

      this.buildViewModel();
    });
  },

  tickUpdate(data) {
    this.updates = this.updates.concat(data.model);
    this.tickUpdateHandlerThrottled();
  },

  tickUpdateHandler: function () {
    this.tickableModel.tickUpdateHandler(this.updates);
    this.updates = [];
    this.buildViewModel();
  },

  savePreferences: function (preferences) {
    if (!preferences) {
      return;
    }

    this.preferences = Object.assign(this.preferences, preferences);
    prefernecesManager.savePreferences(this.getRequiredParameters(), this.preferences);
  },

  buildViewModel: function () {
    this.model = this.tickableModel.model();

    const { plannedOnly = false, scale = 1 } = this.preferences;
    const { rootType, rootName } = this.viewSettings;

    let { productionruns = [], publications = [] } = this.model;
    if (rootType === 'publicationdate') {
      productionruns = publications.reduce((acc, pub) => pub.date === rootName ? acc.concat(pub.productionruns) : acc, []);
    }

    let runs = productionruns.map(productionRun => {
      const {
        label, nwid, type, breadcrumbs, deadline, deadlinePassed, structureHoldUntil,
        releaseOffset, plates, needAllRunPlates, binsIdState: holdByBinId, actionLinks, viewLinks, holdByExternalGrpoup
      } = productionRun;
      const platesByBinAndSorter = distributePlatesByBins(plates, this.binsByNameAndSorter, holdByBinId);
      const sorters = populateBinGroups(platesByBinAndSorter, this.binsByNameAndSorter, plannedOnly);
      const holdType = getHoldType(productionRun);

      const run = {
        nwid,
        type,
        actionLinks,
        viewLinks,
        breadcrumbs,
        label,
        plates,
        needAllRunPlates,
        deadline,
        deadlineDate: fromServerDate(deadline),
        deadlinePassed,
        holdType,
        structureHoldUntil,
        holdUntilDate: fromServerDate(structureHoldUntil),
        releaseOffset,
        sorters,
        totalCount: sum(sorters, 'totalCount'),
        inBinCount: sum(sorters, 'inBinCount'),
        inProgressCount: sum(sorters, 'inProgressCount'),
        missingCount: sum(sorters, 'missingCount'),
        errorCount: sum(sorters, 'errorCount'),
        holdByExternalGrpoup
      };

      this.attachHandlers(run, actionLinks, viewLinks);

      return run;
    });

    //***TEST
    // const run0 = runs[0];
    // const run1 = cloneDeep(run0);
    //
    // run0.holdUntilDate = new Date();
    // // run0.deadlineDate = null;
    //
    // run1.nwid = '2';
    // run1.label = 'Run 2';
    // run1.deadlineDate.setDate(run1.deadlineDate.getDate() - 5);
    // // run1.deadlineDate = null;
    // run1.deadlinePassed = true;
    // run1.holdType = 'structure';
    // // run1.holdUntilDate = new Date();
    // run1.releaseOffset = '20';
    //
    // runs = [run0, run1];
    // // runs = [run0];

    runs = this.filterRuns(runs);

    const deadlineComparator = createObjectComparator(run => run.deadlineDate || getMaxDate(), COMPARE_TYPE.DATES);
    runs = runs.sort(deadlineComparator);

    this.viewModel = {
      plannedOnly,
      scale,
      runs
    };

    this.render();
  },

  filterRuns(runs) {
    const { name, hoursBefore, hoursAfter } = this.filterOptions;
    if (!name && !hoursBefore && !hoursAfter) {
      return runs;
    }

    const substrings = name.trim().toLowerCase().split(/\s+/);
    const now = getFolderCurrentDate();
    const timeBefore = now.getTime() - (hoursBefore || MAX_HOURS) * MSEC_IN_HOUR;
    const timeAfter = now.getTime() + (hoursAfter || MAX_HOURS) * MSEC_IN_HOUR;

    const filteredRuns = runs.filter(run => {
      const label = run.label.toLowerCase();
      const time = run.deadlineDate ? run.deadlineDate.getTime() : 0;

      return substrings.every(s => label.indexOf(s) >= 0) && timeBefore <= time && timeAfter >= time;
    });

    return filteredRuns;
  },

  handleFilterChange: function (event, filterOptions, shouldPersistChanges) {
    this.filterOptions = { ...this.filterOptions, ...filterOptions };

    if (shouldPersistChanges) {
      this.savePreferences(filterOptions);
    }

    this.buildViewModel();
  },

  handleFilterRefresh: function () {
    this.buildViewModel();
  },

  handleClickPlannedOnly: function () {
    let { plannedOnly = false } = this.preferences;

    this.savePreferences({ plannedOnly: !plannedOnly });

    this.buildViewModel();
  },

  handleClickZoomIn: function () {
    let { scale = 1 } = this.preferences;
    scale = round(scale + 0.1, 1);

    this.savePreferences({ scale });

    this.buildViewModel();
  },

  handleClickZoomOut: function () {
    let { scale = 1 } = this.preferences;
    scale = round(scale - 0.1, 1);

    this.savePreferences({ scale });

    this.buildViewModel();
  },

  attachHandlers: function (run, actionLinks, viewLinks) {
    const holdReleaseAction = this.getRelevantActions({ actionLinks }).find(
      a => a.actionDefinitionName === 'HoldReleaseExternalGroupsActionCR');
    const { flowStepNwIds = [] } = holdReleaseAction?.config || {};

    const reoutputPlatesAction = this.getRelevantActions({ actionLinks }).find(
      a => a.actionDefinitionName === 'EditOutputPlatesCR');

    const reoutputAllPlatesAction = this.getRelevantActions({ actionLinks }).find(
      a => a.actionDefinitionName === 'OutputAllPlatesCR');

    const platesDetailsView = this.getRelevantViews({ viewLinks }).find(
      v => v.viewDefinitionName === 'BinPlatesDetailsViewCR');

    let rootParams = {
      rootId: run.nwid,
      rootType: run.type,
      rootLabel: run.label,
      sorterName: NO_SORTER,
    };

    if (platesDetailsView && getUnassignedPlateCount(run.plates) > 0) {
      run.onOpenPlatesDetails = this.handleOpenPlatesDetails.bind(this, platesDetailsView, rootParams);
    }

    run.sorters.forEach(sorter => {
      sorter.groups.forEach(group => {
        if (group.skipped) {
          return;
        }

        const plannedBins = group.positionedBins.filter(bin => bin?.planned)
          .concat(group.unpositionedBins.filter(bin => bin?.planned));
        plannedBins.forEach(bin => {
          if (flowStepNwIds.length > 0 && bin.id) {
            bin.onToggleHold = this.handleToggleHold.bind(this, flowStepNwIds, run.nwid, bin.id, bin.hold);
          }

          if (platesDetailsView && bin.name) {
            rootParams = { ...rootParams, sorterName: sorter.name, binName: bin.name };
            bin.onOpenPlatesDetails = this.handleOpenPlatesDetails.bind(this, platesDetailsView, rootParams);
          }

          if (reoutputPlatesAction && bin.id) {
            bin.onReoutputPlates = this.handleReoutputPlatesAction.bind(this, reoutputPlatesAction, run, bin.id);
            bin.onReoutputPlates.label = reoutputPlatesAction.label;
          }

          if (reoutputAllPlatesAction && bin.id) {
            bin.onReoutputAllPlates = this.handleReoutputAllPlatesAction.bind(this, reoutputAllPlatesAction, run, bin.id);
            bin.onReoutputAllPlates.label = reoutputAllPlatesAction.label;
          }
        });
      });
    });
  },

  handleToggleHold: function (flowStepNwIds, runNwid, binId, hold) {
    restPost(this.nwid, `hold_release_external_groups/containers/${runNwid}`, {
      flowStepNwIds,
      state: { [binId]: !hold }
    })
      .catch(() => {
        toastService.errorToast('', translate('Cannot change the Bin Hold state'));
      });
  },

  handleOpenPlatesDetails: function (view, rootParams) {
    startModule(view.nwid, null, { ...view, ...rootParams });
  },

  handleReoutputPlatesAction: function (action, run, binId) {
    action.execute([run], { bins: [binId] });
  },

  handleReoutputAllPlatesAction: function (action, run, binId) {
    action.execute([run], { bins: [binId] });
  },

  destroy: function () {
    this._super();
    this.reactRoot.unmount();
  },

  render: function () {
    this.reactRoot.render(
      <View
        module={this}
        viewModel={this.viewModel}
      />);
  }

});
