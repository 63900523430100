import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sandbox from 'sandbox';

const isUndefined = o => typeof o === 'undefined';

export default class LockIndicator extends Component {
  static propTypes = {
    locked: PropTypes.bool,
    title: PropTypes.string
  };

  static defaultProps = {
    locked: false,
    title: ''
  };

  getSrcByLockType = (locked) => {
    return locked === true ? sandbox.icons.getModuleIcon('MyBirdeye', 'lock', '.svg') : undefined;
  };

  render() {
    var locked = this.props.locked,
      title = this.props.title,
      src = this.getSrcByLockType(locked);

    return isUndefined(src) ? <span></span> : <img src={src} title={title} />;
  }
}