/**
 * @name commCenterService
 * @fileOverview
 */

import base from 'base';
import jsutils from 'base/jsUtils';
import sorting from 'core/sorting';
import { fromServerDate } from 'core/dates';
import viewManager from 'core/managers/views';
import tickFacade from 'core/facades/tick';
import appPromises from 'core/managers/appPromises';
import idWorker from 'core/workers/idWorker';
import logger from 'logger';
import pubsub from 'core/managers/pubsub';
import settingsManager from 'core/managers/settings';

const SERVICE_NAME = 'commCenterService';

var model = {
  userSystemMails: []
};

var log = logger.getDefaultLogger(),
  commCenterViewLink = {},
  commCenterServiceLink = {};

function handleAdd(add) {

  if (add.hasOwnProperty("childrenNames")) {
    var childrenNames = add['childrenNames'];
    if (Array.isArray(childrenNames) && childrenNames.contains("userSystemMails") && add.hasOwnProperty("userSystemMails")) {

      // Sort
      // Before sorting convert each mail object's key named time from String to Date
      add.userSystemMails.forEach(function (item) {
        item.time = fromServerDate(item.time);
      });
      var sortingDescription = add['userSystemMails' + ":sorting"];
      if (sortingDescription) {
        var sortingTokens = sortingDescription.split(":");
        if (sortingTokens.length === 2) {
          sorting.sort(add.userSystemMails, sortingTokens[1], sortingTokens[0]);
        }
      }

      // Add To Model
      add.userSystemMails.forEach(function (mail) {
        var message = buildMessage(mail);
        model.userSystemMails.pushObject(message);
      });
    }
  }

  else if (add.hasOwnProperty("parentId") && add.hasOwnProperty("childPropertyId") && add["childPropertyId"] === "userSystemMails") {
    var message = buildMessage(add);
    //model.userSystemMails.splice(0,0,message);
    model.userSystemMails.insertAt(0, message);
  }
}

function handleUpdate(update) {
  var idToUpdate = update.id;
  if (idToUpdate) {
    var source = model.userSystemMails;
    var objToUpdate = findById(source, idToUpdate);
    if (typeof objToUpdate !== 'undefined') {
      for (var key in update) {
        if (update.hasOwnProperty(key) && key !== 'action') {
          // Tick bug - Not always return the right "isRead" value
          if (key === "isRead") {
            Ember.set(objToUpdate, key, true);
          } else {
            Ember.set(objToUpdate, key, update[key]);
          }
          //objToUpdate[key] = update[key];
        }
      }
    }
  }
}

function handleRemove(remove) {
  var idsToDelete = remove["ids"];
  var childProperty = remove["childPropertyId"];
  if (idsToDelete && model.hasOwnProperty(childProperty)) {
    var source = model[childProperty];
    for (var i = 0; i < idsToDelete.length; i++) {
      var idToDelete = idsToDelete[i];
      var objToDelete = findById(source, idToDelete);
      if (typeof objToDelete !== 'undefined') {
        source.removeObject(objToDelete);
        //var index = source.indexOf(objToDelete);
        //if (index > -1) {
        //  source.splice(index, 1);
        //}
      }
    }
  }
}

function findById(source, id) {
  var compareProperty = "id";
  return source.find(function (item) {
    return item[compareProperty] === id;
  });
}

function buildMessage(data) {
  var message = {};
  var messageProperties = ["id", "nwid", "isRead", "type", "from", "subject", "time", "actionLinks"];
  var len = messageProperties.length;
  for (var i = 0; i < len; ++i) {
    var prop = messageProperties[i];
    if (data.hasOwnProperty(prop)) {
      message[prop] = prop === 'time' ? fromServerDate(data[prop]) : data[prop];
    }
  }
  return message;
}

var CommCenter = {

  start: function () {
    if (typeof commCenterServiceLink === 'undefined') {
      log.info("CommCenterService.startService: CommCenterService instance not found, couldn't start CommCenterService");
      return;
    }
    this.nwid = commCenterServiceLink.nwid;
    this.id = idWorker.generateId();
    var requestParams = {
      rootId: settingsManager.get('user').nwid,
      rootType: 'controlpanel/profiles/users/user'
    };

    tickFacade.addProjector(this, undefined, this.tickUpdate, this.tickCommit, requestParams);
  },

  tickUpdate: function (data) {
    var models = Array.isArray(data.model) ? data.model : [data.model];
    for (var i = 0, leni = models.length; i < leni; i += 1) {
      switch (models[i].action) {
        case 'Add':
          handleAdd.call(this, models[i]);
          break;
        case 'Update':
          handleUpdate.call(this, models[i]);
          break;
        case 'Remove':
          handleRemove.call(this, models[i]);
          break;
      }
    }

    pubsub.publish(SERVICE_NAME, model.userSystemMails);
  }
};

function registerModule(module) {
  var temp = {
    userSystemMails: []
  };

  module.model = model;

  module.projectorId = CommCenter.projectorId;
}


// ===================== Event Subscription ===========================
appPromises.getPromise(appPromises.states.CHANNEL_OPENED).then(function () {
  CommCenter.start();
});

appPromises.getPromise(appPromises.states.LOGGED_IN).then(function (res) {
  var viewLink = res.serviceLinks['CommCenterServiceCR'];
  if (typeof viewLink !== 'undefined') {
    commCenterServiceLink = viewManager.getViewInfo(viewLink);
  }

  viewLink = res.serviceLinks['CommCenterViewCR'];
  if (typeof viewLink !== 'undefined') {
    commCenterViewLink = viewManager.getViewInfo(viewLink);
  }
});

function register(callback) {
  return pubsub.subscribe(SERVICE_NAME, callback, true)
}

module.exports = {
  _name: 'commCenter',
  _type: 'service',
  registerModule: registerModule,
  register
};

