import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CollapsiblePanel from '../components/collapsiblePanel/index';

export default class CollapsiblePanelContainer extends Component {
  static contextTypes = {
    controller: PropTypes.object
  };

  static defaultProps = {
    defaultOpenState: true
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      open: this.props.defaultOpenState
    };
  }

  update = (open) => {
    this.setState({
      open: open === undefined ? !this.state.open : open
    });
  };

  render() {
    var { children, defaultOpenState, ...other } = this.props;

    return <CollapsiblePanel {...other} open={this.state.open} onClickContext={this} onClickHandler={this.update}>
      {children}
    </CollapsiblePanel>;
  }

  componentDidMount() {
    this.context.controller.registerCollapsiblePanelsHandlers(this.update, this);
  }

  componentWillUnmount() {
    this.context.controller.removeCollapsiblePanelsHandler(this.update);
  }
}