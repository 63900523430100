import React, { useState } from 'react';
import PropTypes from 'prop-types';
import List from 'components/common/list';
import { translate } from 'core/services/localization';
import request from 'core/managers/request';
import { AddEditInkDialog } from './AddEditInkDialog';
import { Toolbar } from './Toolbar';
import IconButton from "components/common/buttons/IconButton";
import dialogService from 'core/services/dialogService';

const labels = {
  duplicate: translate('Duplicate'),
  edit: translate('Edit'),
  delete: translate('Delete'),
  deleteInk: translate('Are you sure you want to delete this Ink Settings?'),
  deleteInkTitle: translate('Delete Ink'),
};

function InksTab(
  {
    model,
    module,
    onSelect,
    selectedItem,
    buttonTitle,
    onAddOrDuplicateItem,
    onDeleteItem,
    onEditItem
  }) {

  const [inks, setInks] = useState(model.inks);
  const [targetInk, setTargetInk] = useState({});
  const [visibleAddEditInkDialog, setVisibleAddEditInkDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const getInkIndex = (inkId) => {
    return inks.findIndex(ink => ink.nwid === inkId);
  };

  const handleDeleteInkClick = (e, ink, index) => {
    e.stopPropagation();
    let msg = labels.deleteInk + "<br>" + ink.name;

    dialogService.openConfirmDialog(msg, labels.deleteInkTitle)
      .then(() => {
        request.deleteInk(module.viewSettings.rootId, ink.nwid, deleteInkResult);
      });

  };

  const deleteInkResult = (data, params) => {
    if (!data || !params) {
      return;
    }
    const inkIndex = getInkIndex(params.nwid);

    onDeleteItem({ elementType: 'ink' }, inkIndex);

    let selectedInkIndex = inkIndex - 1 >= 0 ? inkIndex - 1 : 0;

    onSelect(model.inks[selectedInkIndex]);
  }

  const handleSetSelectedClick = (e, ink, index) => {
    onSelect(ink);
  };

  const handleEditInkClick = (e, ink, index) => {
    e.stopPropagation();
    setTargetInk(ink);
    setVisibleAddEditInkDialog(true);
    setEditMode(true);

  };

  const handleAddEditInk = (ink) => {
    var jsonInk = JSON.stringify(ink);
    if (!editMode) {
      request.createInk(module.viewSettings.rootId, jsonInk, createOrDuplicateInkResult);
    } else {
      request.saveInkInfo(module.viewSettings.rootId, jsonInk, editInkResult);
    }
  };

  const editInkResult = (ink, params) => {
    if (!ink || !params) {
      return console.error("*** ERROR: cannot edit ink");
    }

    const inkIndex = getInkIndex(ink.nwid);

    onEditItem(ink, inkIndex);
    onSelect(ink);
  };

  const handleDuplicateInkClick = (e, ink, index) => {
    request.duplicateInk(module.viewSettings.rootId, ink.nwid, createOrDuplicateInkResult);
  };

  const handleCreateNewInk = () => {
    setVisibleAddEditInkDialog(true);
  }

  const createOrDuplicateInkResult = (ink) => {

    if (!ink) {
      return console.error("*** ERROR: cannot duplicate ink");
    }

    const newInk = {
      elementType: 'ink',
      nwid: ink.nwid,
      name: ink.name,
      plateNwid: ink.plateNwid,
      inkModel: ink.inkModel,
      outputMode: ink.outputMode
    };

    onAddOrDuplicateItem(newInk);
    onSelect(newInk);
  };

  const handleCloseAddEditInkDialog = () => {
    setVisibleAddEditInkDialog(false);
    setEditMode(false);
  };

  const trailingIcons = [
    {
      id: 'edit',
      title: labels.edit,
      onClick: handleEditInkClick,
    },
    {
      id: 'content_duplicate',
      title: labels.duplicate,
      onClick: handleDuplicateInkClick,
    },
    {
      id: 'delete_outline',
      title: labels.delete,
      onClick: handleDeleteInkClick,
    },
  ];

  const renderTrailingButtons = (node) => {
    const renderButton = (e, index) => {
      const { id, title, disabled, onClick } = trailingIcons[index];

      return (
        <IconButton
          key={id}
          iconName={id}
          tooltip={title}
          disabled={disabled}
          onClick={e => onClick(e, node, index)}
        />
      );
    };

    return (
      <div className="crtx-layout-manager-list-item-buttons">
        {trailingIcons.map(renderButton)}
      </div>
    );
  };


  const renderInk = (ink, index) => {

    const itemClassName = selectedItem ?
      (selectedItem.nwid === ink.nwid ? 'crtx-layout-manager-list-item selected' : 'crtx-layout-manager-list-item') :
      'crtx-layout-manager-list-item';

    return (
      <div className={itemClassName} key={ink.nwid} onClick={(e) => handleSetSelectedClick(e, ink, index)}>
        <div className="crtx-layout-manager-list-item-content" title={ink.name}>{ink.name}</div>
        {renderTrailingButtons(ink)}
      </div>
    )
  };

  return (
    <div className='crtx-form-section-container crtx-layout-manager-list-side'>
      <Toolbar title={buttonTitle} onClick={handleCreateNewInk}></Toolbar>
      <div className='crtx-layout-manager-list-container'>
        <div className='crtx-layout-manager-list'>
          <div>
            <List
              items={inks}
              renderItem={renderInk}
            />
            {(visibleAddEditInkDialog) && <AddEditInkDialog
              title={!editMode ? translate('New Ink Settings') : translate('Edit Ink Settings')}
              targetInk={targetInk}
              onClose={handleCloseAddEditInkDialog}
              onAddEditInk={handleAddEditInk}
              editMode={editMode}
              plates={model.plates}

            />}
          </div>
        </div>
      </div>
    </div>);
}

InksTab.propTypes = {
  model: PropTypes.any,
  module: PropTypes.any,
  onSelect: PropTypes.func,
  selectedItem: PropTypes.any,
  buttonTitle: PropTypes.string,
  onAddOrDuplicateItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  onEditItem: PropTypes.func,
}

export default InksTab;