import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from 'components/common/buttons/SvgIcon';
import IconButton from 'components/common/buttons/IconButton';

export default class IconCell extends Component {
  static propTypes = {
    columnData: PropTypes.any,
    icon: PropTypes.string,
    title: PropTypes.string,
    iconClassName: PropTypes.string,
    iconStyle: PropTypes.object,
    onClick: PropTypes.func,
    columnKey: PropTypes.string,
    rowIndex: PropTypes.number,
    iconSprite: PropTypes.string,
  };

  static defaultProps = {
    title: '',
    iconSprite: '',
  };

  handleClick = (ev) => {
    var { rowIndex, columnKey, onClick } = this.props;

    if (typeof onClick === 'function') {
      onClick(rowIndex, columnKey, ev);
    }
  };

  shouldComponentUpdate(nextProps) {
    var { props } = this;
    return nextProps.rowIndex !== props.rowIndex || nextProps.columnData !== props.columnData || nextProps.icon !== props.icon || nextProps.title !== props.title;
  }

  renderSvgIcon = () => {
    const { icon, title, iconClassName, onClick, iconSprite, iconStyle } = this.props;
    return (
      typeof onClick === 'function'
        ?
        <IconButton
          iconName={icon}
          tooltip={title}
          iconClassName={iconClassName}
          onClick={this.handleClick} />
        :
        <SvgIcon name={icon} className={iconClassName} sprite={iconSprite} tooltip={title} style={iconStyle} />
    );
  };

  renderImageIcon = () => {
    const { icon, title, iconClassName, iconStyle, onClick } = this.props;
    const cursor = typeof onClick === 'function' ? 'pointer' : 'default';
    return <img src={icon}
      title={title}
      className={iconClassName}
      style={{ ...iconStyle, cursor }}
      onClick={this.handleClick} />;
  };

  renderIcon = () => {
    const { iconSprite } = this.props;
    return iconSprite ? this.renderSvgIcon() : this.renderImageIcon();
  };

  render() {
    return <div className="cell icon-cell">
      <div className="cell-content">
        {this.renderIcon()}
      </div>
    </div>;
  }
}